import React, { useCallback } from 'react';
import { Modal, Button } from 'antd';
import styles from './NewUserWithoutAeModal.less';

const setUpGuide =
  'https://www.blog.dserspro.com/post/how-to-set-up-your-dsers-pro-account';
const aeRegister = 'https://login.aliexpress.com/';
const goUrlInBlank = url => window.open(url, '_blank');
const goAeRegister = () => goUrlInBlank(aeRegister);
const goSetUpGuide = () => goUrlInBlank(setUpGuide);

function NewUserWithoutAeModal(props) {
  const { visible, closeModal } = props;

  return (
    <Modal
      visible={visible}
      title={null}
      footer={null}
      width={680}
      maskClosable={false}
      keyboard={false}
      bodyStyle={{ padding: '50px 30px 50px' }}
      destroyOnClose
      closable={false}
    >
      <div className={styles.modalContentContainer}>
        <p>
          Please
          <span className={styles.bold}>
            &nbsp;link your DSers account to AliExpress account
          </span>
          &nbsp;before processing orders. Click
          <span className={styles.link} onClick={goSetUpGuide}> here </span>
          to get more information.
        </p>
        <p>
          <span className={styles.link} onClick={goAeRegister}> Don't have an Ali account?</span>
          <Button type="primary" onClick={closeModal}>
            &nbsp;OK&nbsp;
          </Button>
        </p>
      </div>
    </Modal>
  );
}
export default React.memo(NewUserWithoutAeModal);
