export default {
    migration: {
        progress_1:'Creación de la cuenta',
        progress_2:'Preferencia de ajustes',
        progress_3:'Migración de datos',
        title:'Bienvenido a la migración de DSers',
        title_2:'Hemos preparado 2 soluciones de migración de datos. ¿Cuál prefieres?',
        scenario_1:'Si gestionas tu negocio con Oberlo, te recomendamos:',
        scenario_1_title:'Reemplazar los datos de DSers por los de Oberlo',
        scenario_1_text:'Toma en cuenta que los datos de DSers serán eliminados.',
        scenario_2:'Si gestionas tu negocio con Oberlo y DSers, te recomendamos:',
        scenario_2_title:'Fusionar los datos de Oberlo y los de DSers',
        scenario_2_text:'Por favor, confirma la preferencia de migración de datos.',
        button:'APLICAR LAS PREFERENCIAS ELEGIDAS',
        blog:'Guía rápida: Proceso de migración | Comparación de funciones | Comparación de planes',
        contact_1:'Soporte de chat en vivo 24/7',
        contact_2:'Soporte por correo electrónico',
        option_1_title:'En caso de problemas de pedidos y productos entre DSers y Oberlo, quisieras:',
        option_1_text:`Puede haber algunos problemas si un producto de Shopify está configurado con diferentes proveedores de AliExpress en DSers y Oberlo, o si se realiza un pedido a AliExpress con Oberlo, pero no en DSers`,
        option_2_title:'Para el problema de ajustes entre DSers y Oberlo, quisieras que:',
        option_2_text:`Por ejemplo, tanto DSers como Oberlo tienen la función de regulación de precios, por lo que tu migración de datos podría encontrarse con algunos problemas debido a las diferentes plantillas de ajustes`,
        option_1_1:'DSers como referencia',
        option_1_2:'Oberlo como referencia',
        setting_title:'En caso de problemas de datos entre DSers y Oberlo, quisieras:',
        setting_text:'Por ejemplo, tanto DSers como Oberlo tienen la función de regulación de precios, por lo que tu migración de datos puede entrar en conflicto debido a que tienen diferentes plantillas de configuración, por lo que necesitas confirmar las preferencias de antemano.',
        data_title:'Haz clic para comenzar',
        data_title_2:`Durante la migración, tus cuentas Oberlo y DSers no estarán disponibles.
        Volverán a estar disponibles una vez finalizada.`,
        data_button:'INICIAR LA MIGRACIÓN',
        data_button_wait:'MIGRACIÓN PENDIENTE',
        data_button_text:`Al hacer clic en Iniciar Migración, tu cuenta Oberlo se configurará como de sólo lectura
        y aceptarás nuestras condiciones de uso. La migración puede tardar hasta {time} hora.`,
        data_change:'Modificar preferencias',
        data_process_title:'Migración en proceso...',
        data_process_text:'Por favor, mantén la aplicación DSers & Oberlo instalada y la tienda Shopify activa',
        data_setting:'Ajustes',
        data_order:'Pedidos',
        data_product:'Productos',
        data_plan:'Plan',
        optionstext:{
            setting:['Migración de ajustes en proceso...','Reducir la tasa de disputas sincronizando los números de seguimiento con PayPal'],
            order:['Migración de datos de pedidos en proceso...','Realizar más de 100 pedidos en segundos','Sincronizar automáticamente el estado de los pedidos.'],
            product:['Migración de datos de productos en proceso...','Creación de paquetes de productos con mapeo de variantes'],
            plan:['Comparación de planes de suscripción...','Acceso a múltiples y potentes funciones.']
        },
        waitmigration_title:'La cuenta está siendo migrada, por favor espera.',
        waitmigration_text:'Ya existe una cuenta para la migración de Oberlo. Por favor, espera a que esta cuenta sea migrada.',
        timeout_title:'La solicitud ha caducado',
        timeout_text:'La solicitud ha caducado, por favor actualiza la página o vuelve a autorizar desde Oberlo. Si tienes alguna pregunta, por favor contacta con el soporte técnico de DSers. ',
        timeout_contact:'¿Cómo quieres gestionar tus datos?',
        childAccount:'La cuenta actual es una subcuenta de DSers y no puede utilizarse para la migración. Por favor, inicia sesión en la cuenta principal o crea una nueva cuenta DSers.',
        blackAccount:'La cuenta actual no está disponible. Por favor, cambia la dirección de correo electrónico e inténtalo de nuevo.',
        otherplatform:'La cuenta actual ha sido vinculada a otras plataformas, por favor cambia la cuenta de DSers e inténtalo de nuevo.',
        card_1_title:'¿Cómo quieres gestionar tus datos?',
        question_1:'1. Para los datos de los productos en dos cuentas, quisieras:',
        question_1_select1:'Migrar los datos de Oberlo',
        question_1_select2:'Mantener los datos de DSers también',
        question_2:'2. Para los datos de los pedidos en dos cuentas, quisieras:',
        card_2_title:'¿Cómo quieres lidiar con el conflicto de datos?',
        question_3:`1. Para los conflictos de pedidos y productos entre DSers y Oberlo, prefieres:`,
        question_3_select1:'Oberlo',
        question_3_select2:'DSers',
        question_4:'2. Para los ajustes de conflictos entre DSers y Oberlo, prefieres:',
        childAccountawait:'Migración en proceso...',
        childAccountawaittext:'Cuenta de DSers en proceso de migración iniciada por tu cuenta de administrador.',
        code_500:'Excepción de migración, por favor contactar con el departamento de soporte técnico de DSers para resolver este problema.',
        code_426:'El servicio se está actualizando, por favor inténtalo de nuevo más tarde. Si tienes alguna duda, ponte en contacto con el soporte técnico de DSers.',
        exitmigrationtext:'Después de salir de la migración, puedes dirigirte a Oberlo para reiniciar la migración',
        shoperror:'Por favor, asegúrate de que tu tienda de Shopify está preparada y de que tienes DSers autorizado',
        shopcontinue:`CONTINUAR LA MIGRACIÓN`,
        dataerror:'Se produjeron algunos problemas con los datos durante el proceso de migración, haz clic en el botón "Intentar de nuevo" para volver a hacerlo, o haz clic en "Contactar con el Soporte Técnico" para ponerte en contacto con nosotros.',
        datacontinue:'INTENTAR DE NUEVO',
        refresh:'Por favor, actualiza la página',
        customerservice:'Contactar con el servicio técnico',
        oberloError:'El acceso a Oberlo ha fallado. Por favor, contacta con el servicio de atención al cliente.',
        requestid:'Por favor, informa de este ID al equipo de soporte técnico de DSers para solucionar el problema:' ,
        startButton:`Aviso: <br/>
        1. Después de iniciar la migración, tu cuenta de Oberlo se establecerá como de solo lectura. <br/> 2. La migración tarda unas X horas`,
        exitmigration:'SALIR DE LA MIGRACIÓN',
        according:'¿Qué datos se encontrarán finalmente en tu cuenta DSers?',
        cover:`Según tu configuración.DSers mantendrá los datos de Productos & Pedidos & Ajustes de tu cuenta Oberlo`,
        oberlo_oberlo:'Según tu configuración.DSers mantendrá los datos de Productos & Pedidos & Ajustes de las cuentas DSers y Oberlo por ti. En caso de que haya algún conflicto de datos durante la sincronización, prevalecerán los datos de Oberlo.',
        dsers_dsers:'Según tu configuración.DSers mantendrá los datos de Productos & Pedidos & Ajustes de las cuentas DSers y Oberlo por ti. En caso de que haya algún conflicto de datos durante la sincronización, prevalecerán los datos de DSers.',
        dsers_oberlo:'Según tu configuración.DSers mantendrá los datos de Productos & Pedidos & Ajustes de las cuentas DSers y Oberlo por ti. En caso de que haya algún conflicto de datos durante la sincronización, prevalecerán los datos de Productos y Pedidos de DSers, y los datos de Ajustes de Oberlo.',
        oberlo_dsers:'Según tu configuración.DSers mantendrá los datos de Productos & Pedidos & Ajustes de las cuentas DSers y Oberlo por ti. En caso de que haya algún conflicto de datos durante la sincronización, prevalecerán los datos de Productos y Pedidos de Oberlo, y los datos de Ajustes de DSers',
        havetask:'Se está realizando una operación en la cuenta actual. Por favor, inténtalo de nuevo más tarde.',
        timeout:'The server request timed out, please click the "Start Migration" button to restart the migration',
        data_wait_text:'La migración comenzará dentro de {time} hora. Puedes operar tu cuenta Oberlo mientras esperas, pero la migración podrá comenzar en cualquier momento.',
        appointTip:`Después de ajustar la hora, no se podrá modificar. Por favor, selecciona una hora adecuada para la migración. Después de que se inicie la migración, no podrás operar con Oberlo y DSers. Si tienes alguna duda, ponte en contacto con nosotros.`,
        appointConfirm:'Confirmar el ajuste de la hora',
        appointSuccess:'Hora ajustada con éxito',
        startNow:'Inicia la migración ahora',
        timefull:'El período de tiempo seleccionado está completo, puedes elegir iniciar la migración inmediatamente.',
        nowTimeFull:'Este período de tiempo no está disponible, por favor seleccione otro período.',
        tooltiptext:`Tienes información de datos tanto en Oberlo como en DSers, hemos elegido mantener los datos en ambas plataformas por defecto. Si no quieres conservar los datos de DSers, puedes desmarcar la casilla junto a "Mantener también los datos de DSers".`,
        tooltiptext_2:`Por ejemplo, si tienes el mismo producto en Oberlo y DSers, pero tienes diferentes descripciones y especificaciones de precios para el producto, la migración de datos podría encontrar algunos conflictos.`,
        migrationText:'Hora de inicio de la migración'
    }
}