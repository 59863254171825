export default {
  import_list: {
    title: 'Import list',
    dec: 'You can import and manage your AliExpress products here before publishing them to your Shopify store. You can publish a product to different stores and add multiple tags to each products. Click <a href={href} target="_blank">here</a> to leam more. After you import products to DSers, only the price and AliExpress inventory of the product will be automatically updated. If you have imported a product for a long time, in order to ensure the consistency of the product information and the supplier, it is recommended that you check the supplier information before pushing this product.',
    dec_change:`You can import and manage your suppliers' products here before publishing them to your Shopify store.`,
    dec_change_tit:`You can publish a product to different stores and add multiple tags to each products. After you import products to DSers, only the price and inventory of the supplier products will be automatically updated on DSers. If you have imported a product for a long time, in order to ensure the consistency of the product information and the supplier, it is recommended that you check the supplier information before pushing this product.`,
    search_ali_placeholder: 'Import AliExpress products by URL or search products here',
    search_tm_placeholder:'Import Tmall products by URL or search products here',
    add_tags: 'Apply DSers Tags',
    filter: 'Filter',
    filter_product:'Filter Products',
    filter_by_stores: 'Filter by stores',
    filter_by_tags: 'Filter by tags',
    tag_management: 'Create DSers Tags',
    shopping_info: 'Shipping info',
    pagefly:
      'Want to get more sales? Try PageFly to build high-converting product sales pages.',
    pushed_to: 'Pushed to',
    store_s: 'store(s)',
    add_manage_tags: 'Create and manage DSers tags',
    add_manage_tags2: 'Apply DSers Tags',
    add_management_tags:'You can apply tags to group your products.',
    add_manage_tags_tip: 'You can create or delete tags to group your products. Click <a href={ href } target="_blank">here</a> to get more information.',
    add_manage_tags_tip2: 'You can add or delete tags to group your products.',
    add_manage_tags_tip3:`You can filter your products by stores, tags and push status.`,
    add_new_tags: 'Add New Tags',
    push_modal_title: 'Push Products',
    push_modal_desc:'Select the store(s) you want to push your products to and check the boxes below to select the rules to apply to your products.',
    no_store: 'No store',
    from_oberlo_product_tip:'<text>We detected that you are about to push a product to Shopify that was migrated from Oberlo to DSers.</text> As a result, the ',
    from_oberlo_product_tip_1:' pricing rule',
    from_oberlo_product_tip_2:' will not apply to this product. If you want to use the pricing rule instead of the edited prices, please check this box.',
    pricing_rule_tip_2: ' applied to this product.If you have manually edited the product price on DSers, please uncheck this box.',
    pricing_rule_tip_1:'Pricing rule',
    pricing_rule_close_tip: 'Our system detected that you have manually edited the price of this product on DSers. As a result , the ',
    pricing_rule_close_tip_1:'pricing rule',
    pricing_rule_close_tip_2:' will not apply to this product. If you want to use the pricing rule instead of the edited prices, please check this box.',
    select_pricing_rule_mean_tip:'Please note: Pricing Rule includes Pricing templates, Currency and Assign cents. If you check this box, the Pricing Rule will be applied to your products prices according to the Pricing Rules you set in Setting.',
    unselect_pricing_rule_mean_tip:'Please note: Pricing Rule includes Pricing templates, Currency and Assign cents. If you uncheck this box, none of these three features will be applied to your products.',
    product_s: 'product(s)',
    link_to_shopify: 'Link to Shopify',
    no_product_tip: 'Import your first item',
    no_product_tip_1: `1. You need to <a href='/app/find_suppliers'>go to Find Supplier</a> and copy the item URL`,
    no_product_tip_2: '2. Paste the URL you copied into the input box of the Import List',
    search_no_product_tip:'No search results, please change the search criteria and search again',
    tag_manage_delete_tip:
      'Do you want to delete this tag? <br/> It will disappear from all the tagged products.',
    add_product_tip: 'Please link DSers to an AliExpress account or you won\'t be able to import products.',
    fetch_ae_prod_error: `The quantity of products you imported to DSers has reached the "Product limit" of your current subscription plan. Please upgrade your subscription to import more products`,
    fetch_ae_prod_error1: `The quantity of AliExpress products you imported to DSers has reached the daily limit, please try again tomorrow`,
    fetch_tm_prod_error1: `The quantity of Tmall products you imported to DSers has reached the daily limit, please try again tomorrow`,
    ae_issue: 'AliExpress is facing some issues, please try again later',
    delete_product_tip:
      'Are you sure you want to delete the product(s)? It will be deleted from the import list but will not be deleted from your Shopify store',
    ae_prod_not_found: 'The product is unavailable on AliExpress, please import another product.',
    service_unavaliable: 'The operation failed due to network issue, please try again.',
    pushing: 'Sending product(s)',
    push_success: 'Product(s) sent',
    push_success1: 'Product(s) sent',
    push_fail:'Failed to be pushed',
    push_desc: 'products being pushed',
    push_desc_ok: 'Successfully pushed',
    import_review: 'Import Reviews',
    push_desc_fail: 'Failed to be pushed',
    push_error_0: 'The product contains more than 100 SKUs',
    push_error_1: 'Maximum number of SKU creation on Shopify reached',
    push_error_2: 'Shopify store unavailable',
    push_error_3: 'Store token expired',
    push_error_4: 'Your product information exceeds 256KB',
    push_error_4_tip: `Product information includes Title, Description, Images, SKU, etc. It is recommended that you delete some product information before pushing the product`,
    push_error_5: 'Request timed out, please try again later',
    check_push: 'Check Status',
    check_detail:`Check Product Detail`,
    edit_detail:`Edit Product Detail`,
    delete_product:`Delete Product`,
    filter:`Filter`,
    addTag:'Create a new tag',
    confim:'Confirm',
    variants_limit:"Shopify daily variant creation limit reached. Please try again later & click <a href='{url}' target='_blank'>here</a> for more details.",
    pushTitle_1:'Store Selection',
    pushTitle_2:'Preferences',
    pushTitle_3:'Pushing Details',
    repeated_tag:'This tag already existed',
    search_or_import:'Import or Search',
    filter_by_market_place:'Filter by market places',
  },
  product_split: {
    split: 'Split',
    title: 'Split product',
    split_by_option: 'Split by option',
    split_by_option_dec:
      'Select the option that will be split to another product',
    option_tip:
      'We will create { length } product(s), each product will only contain { optionName } variant(s).',
    or: 'or',
    split_by_variant: 'Split by variant',
    split_by_variant_dec:
      'Select the variants that will be split to another product',
    tip:
      'The selected variants will be used to create a new product and the current product will still have all the original variants.',
    select_split_mothod: 'This product cannot be splitted again',
    confirm_split: 'Split Product',
    confirm_split_desc: 'Are you sure you want to split the product? It will be split into different products.',
    product_not_found: 'The product you entered was not found',
    network_issue: 'The operation failed due to network issue, please try again',
  },
};
