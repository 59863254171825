export default {
  home: {
    welcome: 'Welcome to DSers！',
    orders_to_ae: 'orders to be placed on AliExpress', // d
    order_to_ae: 'order to be placed on AliExpress', // d
    move: 'Move along nicely:',
    close: 'Close',
    open: 'Open',
    tutorial2:'Tutorial',
    learn_more: 'LEARN MORE',
    modal_title: {
      title: `New UI & New Beginning`,
      wenhou: `Dear DSers users,`,
      container_one: `First of all, thank you for your ongoing support to DSers. In order to provide users with a better experience, we specially worked with a professional design team to upgrade the UI of our DSers platform.`,
      container_two: `
      After six months of preparation, the new version of UI has been officially launched today, you can now start your new UI experience journey by clicking the button below.`,
      container_three: `
      Of course, we will keep the access to the old version of UI for you in case that you have any doubts or other considerations. After entering the page, you are free to switch between these two versions at any time as well.
       Both version will remain accessible for a certain period of time, depending on the user's feedback. We will notify you in advance of any further changes.`,
      container_four: `
      Thank you again for your constant support to DSers, which will be the motivation for us to keep optimizing and become a better DSers platform.
      `,
      go_new_btn: `START EXPERIENCING THE NEW UI`,
      use_old: `Continue using legacy UI`
    },
    tab_title: {
      add_shopify_store: 'Add Shopify store',
      aliExpress_account: 'AliExpress account',
      white_list: 'Whitelist',
      setting_shipping: 'Setting Shipping',
      mapping_product: 'Mapping Product',
      chrome_extension: 'Chrome extension',
      bulk_order_function: 'Bulk Order functioin'
    },
    add_shopify_store: {
      title: 'Link to your Shopify store:',
      p1: 'Import products to Shopify store quickly',
      p2: 'Sync orders for last 7 days from Shopify',
      p3: 'Go to',
      p4: 'Setting>Other>Sync Shopify orders',
      p5: 'to sync more previous orders',
      btn: 'Link for free'
    },
    aliExpress_account: {
      title: 'Link to AliExpress <br/> account to:',
      p1: 'Place multiple orders automatically in one click',
      p2: 'Improve efficiency by 97%',
      p3: 'Track shipping information',
      p4: 'Make sure your AliExpress account linking like below:',
      btn: 'Link'
    },
    white_list: {
      title: 'Apply for AliExpress whitelist <br/> account to:',
      p1: 'Place order using AliExpress official API',
      p2: 'Unlock order placing and payment limitation',
      p3: 'Get special drop shipping service from AliExpress',
      btn: 'Apply'
    },
    chrome_extension: {
      title: 'Download DSers Chrome <br/> extension to:',
      p1: 'Migration with one click from Oberlo',
      p2: 'Import products from AliExpress',
      p3: 'Bulk payments on AliExpress',
      btn: 'Download'
    },
    more_about: 'Learn more about DSers',
    video_title_mapping: 'How to map AliExpress product before multiple order?',
    video_title_place_order: 'How to place 100+ orders within minutes?',
    other_features: 'A quick view of other features',
    video_title_add_store: 'How to add Shopify store?',
    video_title_bind_ae: 'How to bind and upgrade AliExpress account?',
    video_title_import_products: 'How to import products to Shopify store?',
    video_title_fulfill_manually: 'How to fulfill orders manually?',
    bottom_line: 'We have the bottom line',
    // 新版home
    home_title: 'Welcome to DSers, AliExpress official app',
    setup_account: 'Set up your DSers account',
    tutorial: 'TUTORIAL',
    product_importer: 'Product Importer',
    chrome_extension_title: 'CHROME EXTENSION',
    report: 'REPORT',
    check_report: 'Check your sales, spending and product ROI',
    feedback: 'Feedback',
    feedback_share: 'Share your feedback',
    feedback_useful: 'This information is very useful',
    feedback_not_useful: ' This information is not useful',
    feedback_what_to_know: 'Let us know what you would like to know',
    comments: 'Comments',
    share_feedback: 'Share feedback',
    new: 'New',
    dismiss: 'Dismiss',
    home_normal_card_learn_more_link: 'https://www.blog.dserspro.com/',
    go_modify:'Go modify',
    vat_error:`Dear user, the system has detected that the VAT ID you currently set contains non-numeric characters, which will cause your French order to fail (please note that the VAT ID you set is for the French order, other countries currently don't require it). To process the order smoothly, please go to Setting - Other - VAT to modify or clear it`,
    var_update:`If you do not modify it, we will automatically delete the VAT ID on the French orders`,
    modify_btn:`Modify`,
    var_update_setting:`The current VAT ID for French orders has been discontinued. To process the order smoothly, please modify it in time`,
    change_UI: `Change to old UI`,
    oldUI_out: {
      notion_title: 'New UI Notification',
      notion_con:'Access to the old UI will not be possible after <br /> October 15th',
      title: 'Brand new UI,<br /> brand new experience',
      modal_text: `After nearly a month of user feedback and technical adjustments, the current new version of the UI has been improved. To unify the platform features, 
      we will remove the access to the old UI on October 15th. Please be aware in advance. If you have any questions, feel free to <a style="color:blue" href='https://www.messenger.com/t/DSersOfficial' dd="src">contact our customer service</a> team.`
    },
    downloadguide:{
      guide:'Get the guide',
      text:'Click to download the<br /> dropshipping guide'
    },
    ShopifyOrders:'Shopify Orders',
    guidance:{
      steps:'Step',
      btn_complete:'COMPLETED',
      title:'Get to know DSers',
      desc:'4 steps to automate your dropshipping business',
      add_product: 'ADD PRODUCT',
      push_to_shopify: 'PUSH TO SHOPIFY',
      suppiler_mapping: 'SUPPLIER MAPPING',
      place_order: 'PLACE ORDER',
      add_product_title: 'Add your first product',
      push_to_shopify_title: 'Push products to your Shopify store',
      suppiler_mapping_title: 'Mapping: Set up the products’ suppliers',
      place_order_title: 'Process your first order',
      add_product_desc: 'Add the products you want to sell in your store. You can search in Find Supplier for products provided by AliExpress or DSers.Click <a href="https://help.dsers.com/import-product-from-aliexpress-for-shopify/" target="_blank" >here</a> for more details.',
      push_to_shopify_desc: 'When you have your products in DSers, edit them and send them to your Shopify store.Click <a href="https://help.dsers.com/push-a-product-to-your-shopify-store/" target="_blank" >here</a> for more details.',
      suppiler_mapping_desc: 'Set up AliExpress suppliers of your products to easily process future orders.Click <a href="https://help.dsers.com/basic-mapping-advanced-mapping-bogo-bundle-introduction-for-shopify/" target="_blank" >here</a> for more details.',
      place_order_desc: 'You can place orders in groups very quickly, and DSers will automatically manage the orders for you after the order is successfully placed.Click <a href="https://help.dsers.com/fulfill-an-order-from-start-to-finish-for-shopify/" target="_blank" >here</a> for more details.',
      tip_to_pc:'To ensure a better user experience, log in using a computer to operate DSers with the right conditions'
    },
    oberlo:{
      card_title_1:'Almost there',
      card_title_des_1:'Please complete the following settings before you process orders and use the advanced features.',
      card_title_2:'Tutorial',
      card_title_des_2:'Please check this quick guide to help you process orders and import products.',
      step_title_1:'Link your AliExpress account',
      step_title_2:'Activate DSers subscription',
      step_title_3:'Install DSers Chrome Extension',
      step_des_1:'Link your AliExpress account immediately to update the order statuses.Click <a href="https://help.dsers.com/link-to-aliexpress/" target="_blank">here</a> for more details.',
      step_des_2:'Enjoy Oberlo-like features with new advanced possibilities from DSers. Click <a href="https://help.dsers.com/oberlo-migration-compare-plans/" target="_blank">here</a> for more details.',
      step_des_3:'One-click product import from AliExpress to DSers to save time and find new best sellers! Click  <a href="https://help.dsers.com/import-product-from-aliexpress-for-shopify/" target="_blank">here</a> for more details.',
      // obPlan:'Activate DSers',
      // dsPlan:' to enjoy your current features in Oberlo',
      obPlan:'Activate DSers <i>{ dsers }</i> plan to enjoy your current features in Oberlo <i>{oberlo}</i> plan.',
      btntext1:'LINK ALIEXPRESS',
      btntext2:'ACTIVATE PLAN',
      btntext3:'I WILL DO IT LATER',
      btntext4:'DOWNLOAD',
      btntext5:'VIEW MORE',
      billing_error:'You can’t activate the plan because your billing store is unavailable. Please switch billing store on DSers - <a href="/app/select">Manage Subscription</a> page or activate the billing store on',
      billing_error1:'You can’t activate the plan because your billing store is unavailable. Please switch billing store on DSers - <a href="/app/select">Manage Subscription</a> page.',
      billing_error_link:'Shopify admin.',
      stepsdes:'Step(s) left',
      finish:'Finished',
      year_error:'If you activate a new plan but previously paid for DSers Standard plan annual fee, please contact customer service to get refund of unused part.',
      step_title_4:'Migrate Oberlo data',
      step_des_4:'Link your Oberlo account and sync data to DSers.',
      stopProduct:`The product data was not updated correctly, you can click 'Try again' or contact the <a href='https://www.messenger.com/t/DSersOfficial' target="_blank">DSers support team</a>`,
      stopOrder:`The order status was not updated correctly, you can click 'Try again' or contact the <a href='https://www.messenger.com/t/DSersOfficial' target="_blank">DSers support team</a>`,
      successProduct:`The product data update has been completed.`,
      successOrder:`The order status update has been completed, please go to the <a href='/app/order/list'>Open Orders</a> page for details`,
      syncProduct:'We are still updating product data from Shopify…',
      syncOrder:`We are still updating the order status from AliExpress. DSers migrated orders from the past two months. If you need to synchronize more orders please <a href='https://www.messenger.com/t/DSersOfficial' target="_blank">contact us</a>.`,
      migrated:'MIGRATED',
      linked:'LINKED'
    },
    ShopifyOrders:'Shopify Orders',
    newGuide:{
      primary_title:`Dropshipping Guide`,
      primary_descriptions:`We will show you the most suitable content according to your selection.`,
      second_title_1:`No products, no orders`,
      second_description_1:`I'm new and I don't know how to use DSers to start my dropshipping business.`,
      second_title_2:`I have products, but not orders`,
      second_description_2:`I already have items on Shopify, but I don't know how to start my business on DSers.`,
      second_title_3:`I have products and have orders`,
      second_description_3:`I have used solutions like DSers, but I need to know how to use the main features of DSers.`,
      second_more_1_title_1:`What Is Dropshipping?`,
      second_more_1_title_2:`How Can You Start a Dropshipping Store with DSers?`,
      second_more_1_title_3:`How to import products in Find Supplier?`,
      second_more_1_title_4:`How to import products in Import List?`,
      second_more_1_title_5:`How to send products to Shopify after you imported them?`,
      second_more_2_title_1:`Ultimate Guide to 15 Evergreen Products to Dropship in 2023`,
      second_more_2_title_2:`Top 10 Trending Dropshipping Products for 2023`,
      second_more_2_title_3:`How to import items from Shopify to DSers?`,
      second_more_2_title_4:`How to match the right supplier for the product?`,
      second_more_3_title_1:`How to Set a Proper and Lucrative Dropshipping Profit Margin?`,
      second_more_3_title_2:`A Step-by-Step Guide to Deal with Your First Dropshipping Order`,
      second_more_3_title_3:`How to place an order on DSers?`,
      second_more_3_title_4:`How to set up the shipping template?`,
      Expand:`EXPAND`,
      Leave:`COLLAPSE`,
      more_videos:'More videos'
    }
  }
};
