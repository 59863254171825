import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Button } from 'antd';
import intl from 'react-intl-universal';

import styles from './AgencyColdStartup.less';
import useOnlineNotice from '../../hooks/useOnlineNotice';
import { DSERS_IMG_HOST } from 'utils/constants';
import classNames from 'classnames';
import { CommonDataCollect } from 'utils/utils';

const onlineDate = new Date(2022, 9, 17); // 上线日期

const modalRoot = document.getElementById('root');

function AgencyColdStartUp({ lang }) {
  const [visible, closeNotice] = useOnlineNotice(
    'agency_cold_startup',
    onlineDate,
    99
  );

  const calculateVisible = React.useMemo(() => {
    return visible && window.CAINIAO_USER
  }, [visible]);

  const handleClose = () => {
    typeof closeNotice === 'function' && closeNotice();
    CommonDataCollect({
        action: 'tmall_order_cold_startup_popup_close',
        event_type:'tmall_order_cold_startup'
    });
  }

  const handleClickBtn = () => {
    CommonDataCollect({
        action: 'tmall_order_cold_startup_popup_btn_click',
        event_type:'tmall_order_cold_startup'
    });
  }

  useEffect(() => {
    if(calculateVisible) {
        CommonDataCollect({
            action: 'tmall_order_cold_startup_popup_show',
            event_type:'tmall_order_cold_startup'
        });

    }
  }, [calculateVisible]);

  if (!calculateVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.notice}>
      <div className={styles.content}>
        <div className={styles.contentBg}>
          <img
            className={styles.bgImg1}
            width="349"
            src={`${DSERS_IMG_HOST}/shopify/activity/agency_cold_startup/221017/agency_startup_img_1.png`}
          />
          <img
            className={styles.bgImg2}
            width="58"
            src={`${DSERS_IMG_HOST}/shopify/activity/agency_cold_startup/221017/agency_startup_img_3.png`}
          />
          <img
            className={styles.bgImg3}
            width="52"
            src={`${DSERS_IMG_HOST}/shopify/activity/agency_cold_startup/221017/agency_startup_img_4.png`}
          />
          <img
            className={styles.bgStar}
            width="26"
            src={`${DSERS_IMG_HOST}/shopify/activity/agency_cold_startup/221017/agency_startup_img_6.png`}
            />
        </div>
        <div className={styles.contentInfo}>
          <span
            onClick={handleClose}
            className={classNames('material-icons', styles.closeIcon)}
            title="close"
          >
            close
          </span>
          <div className={styles.title}>&nbsp;{intl.get('banner.agency_cold_startup.banner_title_1')}&nbsp;</div><br />
          <div className={styles.title}>
            &nbsp;{intl.get('banner.agency_cold_startup.banner_title_2')}&nbsp;
          </div>
          <div className={styles.margin} style={{ height: 12 }}></div>
          <div className={styles.subTitle}>
            {intl.get('banner.agency_cold_startup.popup_desc_1')}
          </div>
          <div className={styles.margin} style={{ height: 32 }}></div>
          <div className={styles.subTitle}>
            {intl.get('banner.agency_cold_startup.popup_desc_2')}
          </div>
          <div className={styles.margin} style={{ height: 32 }}>
            <img
              className={styles.lineStar}
              width="24"
              src={`${DSERS_IMG_HOST}/shopify/activity/agency_cold_startup/221017/agency_startup_img_6.png`}
            />
          </div>
          <div className={styles.subTitle} style={{ width: 350 }}>
            {intl.get('banner.agency_cold_startup.popup_desc_3')}
          </div>
          <div className={styles.margin} style={{ height: 32 }}></div>
          <a
            href="https://www.dsers.com/blog/product-update-dropship-with-agencies/"
            target="_blank"
            onClick={handleClickBtn}
          >
            <div className={styles.button}>
              {intl.get('banner.agency_cold_startup.learn_more_btn')}
              <img
                className={styles.buttonStar}
                width="14"
                src={`${DSERS_IMG_HOST}/shopify/activity/agency_cold_startup/221017/agency_startup_img_5.png`}
              />
            </div>
          </a>
        </div>
      </div>
    </div>,
    modalRoot
  );
}

export default AgencyColdStartUp;
