
    export default 
    {
    "fulfillment_center": {
        "activate": "Sind Sie sicher, dass Sie das FBD-Produkt aktivieren wollen?",
        "activate_dec": "Ihr Standardlieferant wird nach 48 Stunden auf das FBD-Produkt umgestellt.",
        "input_confirm": "Bitte geben Sie BESTÄTIGEN ein, um zu aktivieren.",
        "deactivate": "Sind Sie sicher, dass Sie die FBD-Produkte deaktivieren möchten?",
        "deactivate_dec": "Ihr Standardlieferant wird nach 48 Stunden auf den AliExpress-Lieferanten auf Ihrer Zuordnungsseite umgestellt.",
        "using_tip": "Sie nutzen den FBD-Service für dieses Produkt. Lieferanten aus der Mapping-Seite werden verwendet, wenn sie deaktiviert sind.",
        "close_request": "Ihre Anfrage wird derzeit geprüft. Sie wird nach 48 Stunden aktiviert. Während dieser 48 Stunden. Der aktuelle Lieferant wird verwendet",
        "closeing": "Der FBD-Dienst wird verwendet, wenn er eingeschaltet ist.",
        "activate_request": "Ihre Anfrage wird derzeit geprüft. Der Lieferant auf der Zuordnungsseite wird nach 48 Stunden verwendet.",
        "go_setting": "Der Shop muss neu autorisiert werden. Bitte gehen Sie zu <a href=\"/app/settings?id=4\">Einstellungen - Konto</a>, um die Autorisierung zu aktualisieren. Andernfalls können Sie den Shop nicht ordnungsgemäß mit DSers betreiben.",
        "title": "Erfüllungszentrum (FBD)",
        "dec": "Wir werden keine Produkte über FBD abwickeln, wenn Sie mehrere Produkte von demselben Ali-Lieferanten bestellen, um doppelte Versandkosten zu vermeiden.",
        "pruchese": "Pruchese",
        "saved": "Gespeicherte",
        "placeholder": "Geschäft auswählen"
    }
}