export default class MaskSplit {
  doms = {};

  constructor() {
    this.fragment = document.createDocumentFragment();
    this.target = null;
    this.init();
  }

  init() {
    const elements = ['left', 'top', 'right', 'bottom'];

    elements.forEach(ele => {
      const item = document.createElement('div');
      // item.setAttribute(
      //   'style',
      //   'display: none; position: absolute; z-index: 9999; background: rgba(0, 0, 0, 0.55)'
      // );

      item.classList.add('mask-column');
      item.setAttribute('data-label', `mask-column-${ele}`);

      if (ele === 'left') {
        this.doms.left = item;
        item.setAttribute(
          'style',
          'top: 0px; left: 0px; width: 0px; height: 100%; display: none'
        );
      } else if (ele === 'top') {
        this.doms.top = item;
        item.setAttribute(
          'style',
          'top: 0px; left: 0px; width: 0px; height: 0px; display: none'
        );
      } else if (ele === 'right') {
        this.doms.right = item;
        item.setAttribute(
          'style',
          'top: 0px; left: 0px; width: 100%; height: 100%; display: block'
        );
      } else if (ele === 'bottom') {
        this.doms.bottom = item;
        item.setAttribute(
          'style',
          'top: 0px; left: 0px; width: 0px; height: 100%; display: block'
        );
      }

      this.fragment.appendChild(item);
    });
  }

  update(calc) {
    if (!calc) {
      throw new Error('getBoundingClientRect value is required');
    }

    this.doms.left.style.width = `${calc.left}px`;
    this.doms.left.style.display = 'block';

    this.doms.top.style.left = `${calc.left}px`;
    this.doms.top.style.width = `${calc.width}px`;
    this.doms.top.style.height = `${calc.top}px`;
    this.doms.top.style.display = 'block';

    this.doms.right.style.left = `${calc.left + calc.width}px`;
    this.doms.right.style.display = 'block';

    // this.doms.bottom.style.top = `${calc.top + calc.height}px`;
    this.doms.bottom.style.top = `${calc.top + calc.height + 30}px`; // 底部下拉选项益处被遮挡
    this.doms.bottom.style.left = `${calc.left}px`;
    this.doms.bottom.style.width = `${calc.width}px`;
    this.doms.bottom.style.display = 'block';
  }

  setHighlightElement(element) {
    if (!element) {
      throw new Error('highlight element is required');
    }
    this.visible = true;
    this.target = element;

    const calc = element.getBoundingClientRect();
    this.update(calc);
  }

  resize() {
    if (!this.target || !this.visible) {
      return;
    }

    const calc = this.target.getBoundingClientRect();
    this.update(calc);
  }

  setVisible(visible) {
    this.visible = visible;
    const value = visible ? 'block' : 'none';
    this.doms.left.style.display = value;
    this.doms.top.style.display = value;
    this.doms.right.style.display = value;
    this.doms.bottom.style.display = value;
  }

  destroy() {
    this.fragment = null;
    this.doms = null;
    this.target = null;
  }
}
