
    export default 
    {
    "login": {
        "title": "Effettua il login al tuo Dsers",
        "email": "E-mail",
        "password": "Password",
        "cap_placeholder": "Codice captcha",
        "log_in": "Log in",
        "forgot_password": "HAI DIMENTICATO LA PASSWORD?",
        "enter_username": "Si prega di inserire lo username!",
        "enter_password": "Si prega di inserire la password!",
        "email_password_error": "Email o password errata",
        "empty": "Il campo della password non può restare vuoto",
        "text_title": "5.0 <br/> <span class={titlek}>Valutazione stelle con</span>",
        "text_desc": "10000+ <br/> <span class={titlek}>Recensioni sull'App <br/> Store Shopify</span>",
        "text_title_2": "Sostituzione Oberlo <br/> <span class={titlek}>consigliata ufficiale da Shopify </span>",
        "text_desc_2": "10000+ <br/> <span class={titlek}>Recensioni 5 stelle <br/> sull'app store di Shopify </span>",
        "noHaveText": "Non hai ancora un account?",
        "createAccount": "CREA UN ACCOUNT",
        "emailrequired": "e-mail richiesta",
        "error": "E-mail o password errate"
    }
}