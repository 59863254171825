export default {
  support: {
    title: 'Support',
    description:
      'Bem vindo a página de contato do DSers. Nós estamos aqui para ajudar você a impulsionar seu negócio de Dropshipping com coração e alma!',
    email: 'EMAIL',
    email_dec:
      'Deixe um e-mail com suas questões ou inspirações! <br/> Nós responderemos o mais rápido possível.',
    email_btn: 'Suporte por Email',
    live_chat: 'CHAT AO VIVO',
    live_chat_dec:
      'Nos mande uma mensagem para solucionar sues problemas. Nos estaremos lá! <br /> para um bate-papo ao vivo.',
    live_chat_btn: 'Nos mande uma mensagem',
    facebook: 'FACEBOOK',
    facebook_dec:
      'Siga nossa página no Facebook para ver as últimas informações sobre o DSers e Dropshipping.',
    facebook_btn: 'Facebook',
    next_level: 'Leve seus negócios ao próximo nível',
    try_now: 'Experimente Agora! Livre de Riscos.',
    get_started: 'Começar',
    partnership: 'Parceiros'
  }
};
