import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Icon, Button } from 'antd';
import intl from 'react-intl-universal';

import money from '../../assets/banner/money.png';
import qrCode2 from '../../assets/banner/qrCode2.png';
import icon4 from '../../assets/banner/email.svg';
import icon3 from '../../assets/banner/account_circle.svg';
import styles from './IossNotice.less';
import { bannerListMap, bannerList } from '../../utils/bannerWhiteList';
import useOnlineNotice from '../../hooks/useOnlineNotice';
import DsQRCode from '../DsQRCode';

const onlineDate = new Date(2021, 6, 15); // 上线日期
const modalRoot = document.getElementById('root');

function IossNotice({ userId }) {
  const [visible, closeNotice] = useOnlineNotice('ioss', onlineDate, 300);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [href, setHref] = useState('');
  const isBannerUser = bannerList.includes(userId);
  const [text, setText] = useState('');

  useEffect(() => {
    if (isBannerUser && visible) {
      setText('https://wa.me/' + bannerListMap[userId][1]);
      setName(bannerListMap[userId][0]);
      setEmail(bannerListMap[userId][2]);
      setHref('mailto:' + email);
    }
  }, [visible]);

  if (!visible || !isBannerUser) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.notice}>
      <div className={styles.body}>
        <Icon
          type="close"
          className={styles.close}
          // onClick={() => closeBanner()}
          onClick={() => closeNotice()}
        />
        <img src={money} className={styles.money} />
        <div className={styles.textCon}>
          <div className={styles.title}>
            {intl.getHTML('banner.ioss.title')}
          </div>
          <div className={styles.subTitle}>
            {intl.getHTML('banner.ioss.sub_title')}
          </div>
          <div className={styles.text}>{intl.getHTML('banner.ioss.text')}</div>
        </div>
        <div className={styles.footer}>
          <div className={styles.qrCode}>
            <img src={qrCode2} className={styles.qrCode2} />
            <DsQRCode text={text} width="100" name="noticeCode" />
          </div>
          <div className={styles.msg}>
            <div className={styles.msgCon}>
              <div className={styles.name}>
                <img lassName={styles.icon3} src={icon3} />
                {name}
              </div>
              <div className={styles.email}>
                <img lassName={styles.icon4} src={icon4} />
                <a href={href}>{email}</a>
              </div>
            </div>
            <div className={styles.buttonCon}>
              <Button
                type="primary"
                style={{ marginLeft: 16 }}
                // onClick={() => closeBanner()}
                onClick={() => closeNotice()}
              >
                OK
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  window.location.href = href;
                }}
              >
                {intl.getHTML('banner.ioss.contact_us')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>,
    modalRoot
  );
}

export default IossNotice;
