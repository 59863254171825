import React,{useState} from 'react'
import { Modal } from 'antd'

import CountDownTime from '../../common/countDownTime'

import intl from 'react-intl-universal';
import styles from  './FixedModal.less'
import './index.css'

const FixedModal = ({ type, visible, hideModal, yesHandele, noHandele, cancelHandele, changeHandle, showTime}) => {

  return <div className={styles.fixedModal} >
    <Modal
      visible={visible}
      onCancel={hideModal}
      header={null}
      footer={null}
      bodyStyle={{padding:0,overflow:'hidden'}}
      wrapClassName={'web'}
      style={{backgroundColor:'tr'}}
      >
          <div className={styles.box}>
            <p className={styles.diff}>
                  {intl.getHTML('banner.content', {
                    title: styles.titled,
                  })}
                </p>
            {type != 2  ?
              <>
                <p className={styles.today}>{intl.getHTML('banner.today')}</p>
                <p className={styles.will}>
                  {intl.getHTML('banner.will')}
                </p>
                <p className={styles.content}>
                  {intl.getHTML('banner.support', {
                    title: styles.title_blod,
                  })}
                </p>
              </> : null
            }

            {type == 2 && showTime ? <div className={styles.time}><CountDownTime /></div> : null}
            {
              type == 1 ?
              <div className={styles.btn}>
                <div className={styles.btn_go_active} id="onboarding_no" onClick={yesHandele}>{intl.getHTML('banner.yes')}</div>
                <div className={styles.btn_go} id="onboarding_yes" onClick={noHandele}>{intl.getHTML('banner.no')}</div>
              </div> :
            <div className={styles.btn}>
              {/* <div className={styles.btn_go} id="onboarding_letme" onClick={cancelHandele}>{intl.getHTML('banner.btn2')}</div> */}
              <div className={styles.btn_go_active} id="onboarding_change" onClick={changeHandle}>{intl.getHTML('banner.btn1')}</div>
              <div className={styles.btn_desc}>{intl.getHTML('banner.btn3')}</div>
            </div>
            }

          </div>
    </Modal>
  </div>
}


export default FixedModal
