export default {
  guide: {
    // 升级弹窗文案
    CommonGuide: [
      {
        type: 1,
        title: 'Upgrade do plano',
        content:'Seu plano atual é o Básico. Faça o upgrade para o plano Avançado se quiser ter acesso a este recurso, mapeamento avançado e atendimento ao cliente 24 horas.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'UPGRADE',
        otherBtn: 'Cancelar'
      },
      {
        type: 2,
        title: 'Upgrade do plano',
        content:'Seu plano atual é o Avançado. Faça o upgrade para o plano Pro se quiser ter acesso a este recurso e gerenciar mais de 70 mil produtos.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'UPGRADE',
        otherBtn: 'Cancelar'
      },
      {
        type: 3,
        title: 'Personalização',
        content:'Seu plano atual é o mais alto disponível. Se quiser aumentar seus limites, podemos oferecer serviços personalizados.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Contate-nos',
        otherBtn: 'Cancelar'
      },
      {
        type: 4,
        title: 'Upgrade do plano',
        content:'Seu plano atual é Padrão. Faça o upgrade para o plano Avançado se quiser ter acesso a esse recurso, mapeamento avançado e atendimento ao cliente 24 horas por dia.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'UPGRADE',
        otherBtn: 'Cancelar'
      },
      {
        type: 5,
        title: 'Personalização',
        content:'Seu plano atual é o mais alto disponível. Se quiser aumentar seus limites, podemos oferecer serviços personalizados.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Contate-nos',
        otherBtn: 'Cancelar'
      },
      {
        type: 6,
        title: 'Personalização',
        content:'Seu plano atual é o mais alto disponível. Se quiser aumentar seus limites, podemos oferecer serviços personalizados.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Contate-nos',
        otherBtn: 'Cancelar'
      },
      {
        type: 7,
        title: 'Upgrade do plano',
        content:'Seu plano atual é o Avançado. Faça o upgrade para o plano Pro se quiser ter acesso a este recurso e gerenciar mais de 70 mil produtos.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'UPGRADE',
        otherBtn: 'Cancelar'
      },
      {
        type: 8,
        title: 'Upgrade do plano',
        content:'Seu plano atual é Padrão. Faça o upgrade para o plano Avançado se quiser ter acesso a esse recurso, mapeamento avançado e atendimento ao cliente 24 horas por dia.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'UPGRADE',
        otherBtn: 'Cancelar'
      },
      {
        type: 9,
        title: 'Personalização',
        content:'Seu plano atual é o mais alto disponível. Se quiser aumentar seus limites, podemos oferecer serviços personalizados.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Contate-nos',
        otherBtn: 'Cancelar'
      },
      {
        type: 10,
        title: 'Upgrade do plano',
        content:'Seu plano atual é o Básico personalizado. Faça o upgrade para o plano Avançado se quiser ter acesso a este recurso, mapeamento avançado e atendimento ao cliente 24 horas.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'UPGRADE',
        otherBtn: 'Cancelar'
      },
    ],
    // 开屏弹窗文案
    OBDialog: {
      bindAE: {
        top:
          'Ficamos felizes em tê-lo conosco! Migramos todos os seus dados, pedidos, produtos e configurações com sucesso.',
        topTitle: 'Bem-vindo ao DSers!',
        centerTitle: 'Migração bem-sucedida!',
        btn: 'ENTENDI'
      },
      noBindAE: {
        top:
          'Ficamos felizes em tê-lo conosco! Migramos todos os seus dados, pedidos, produtos e configurações com sucesso.',
        centerTitle: 'Migração bem-sucedida!',
        topTitle: 'Bem-vindo ao DSers!',
        bottom: [
          'Para usar o DSers, vincule sua conta do AliExpress.'
        ],
        btn: 'Vincular ao AliExpress'
      }
    },
    //底部banner文案
    OBanberWords: [
      {
        // 绑定AE
        step: 1,
        title: 'Vincule sua conta AliExpress',
        text:
          "Vincular sua conta AliExpress ao DSers permite que você faça mais de 100 pedidos em segundos! Clique <a target='_blank' href='https://help.dsers.com/link-your-shopify-store/'>aqui</a> para saber mais.",
        btn: 'VINCULAR AO ALIEXPRESS'
      },
      {
        // 确认套餐
        step: 2,
        title: 'Mantenha seus hábitos do Oberlo',
        textArr: [
          {
            type: 1,
            text:
              "Acesse todos os recursos do Oberlo e aproveite soluções mais avançadas, como gerenciamento de lojas múltiplas. Clique <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>aqui</a> para saber mais."
          },
          {
            type: 2,
            text:
              "Acesse todos os recursos similares aos do Oberlo e aproveite soluções ainda mais sofisticadas, como mapeamento avançado e atendimento ao cliente 24 horas por dia. Clique <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>aqui</a> para mais detalhes."
          },
          {
            type: 3,
            text:
              "Acesse todos os recursos similares aos do Oberlo e aproveite ainda mais soluções, como gerenciamento de mais de 70 mil produtos. Clique <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>aqui</a> para mais detalhes."
          }
        ],
        btn: 'Ativar plano'
      },
      {
        // 安装插件
        step: 3,
        title: 'Instale a extensão DSers para Chrome',
        text:
          "Importação de produtos com um clique do AliExpress ao DSers para ganhar tempo e encontrar os itens mais vendidos! Clique <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>aqui</a> para saber mais.",
        btn: 'Download'
      }
    ],
    // 确认弹窗文案
    ComfirmPop: [
      {
        kind: 'AE',
        title: 'Vincule sua conta AliExpress',
        content:
          "Vincular sua conta AliExpress ao DSers permite que você faça mais de 100 pedidos em segundos! Clique <a target='_blank' href='https://help.dsers.com/link-your-shopify-store/'>aqui</a> para saber mais.",
        mainBtn: 'VINCULAR AO ALIEXPRESS'
      },
      {
        kind: 'PLAN',
        title: 'Mantenha seus hábitos do Oberlo',
        contentArr: [
          {
            type: 1,
            content:
              "Acesse todos os recursos do Oberlo e aproveite soluções mais avançadas, como gerenciamento de lojas múltiplas. Clique <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>aqui</a> para saber mais."
          },
          {
            type: 2,
            content:
              "Acesse todos os recursos similares aos do Oberlo e aproveite soluções ainda mais sofisticadas, como mapeamento avançado e atendimento ao cliente 24 horas por dia. Clique <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>aqui</a> para mais detalhes."
          },
          {
            type: 3,
            content:
              "Acesse todos os recursos similares aos do Oberlo e aproveite ainda mais soluções, como gerenciamento de mais de 70 mil produtos. Clique <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>aqui</a> para mais detalhes."
          }
        ],
        errorBillStore:
          "Não foi possível ativar o plano, porque sua loja de faturamento está indisponível. Altere a loja de faturamento em DSers – <a target='_blank' href='https://www.dsers.com/app/select'>“Gerenciar assinatura”</a> ou ative a loja de faturamento no ",
        errorBillStoreNotId: "Não foi possível ativar o plano, porque sua loja de faturamento está indisponível. Altere a loja de faturamento em DSers – <a target='_blank' href='https://www.dsers.com/app/select'>“Gerenciar assinatura”</a>",
        errorBillName: 'painel do Shopify',
        mainBtn: 'Ativar plano'
      },
      {
        kind: 'CHROME',
        title: 'Instale a extensão DSers para Chrome',
        content:
          "Importação de produtos com um clique do AliExpress ao DSers para ganhar tempo e encontrar os itens mais vendidos! Clique <a target='_blank' href='https://help.dsers.com/import-product-from-aliexpress-for-shopify/#Use DSers Chrome Extension'>aqui</a> para saber mais.",
        mainBtn: 'Download'
      }
    ],
    // 刷新弹窗文案
    RefreshPopText: {
      title: 'Atualizar página',
      content:
        'Atualize a página para que possamos confirmar se você instalou o plugin com sucesso.',
      btn: 'ATUALIZAR'
    },
    // 提示用户必要信息文案
    OberloTopTipOneBindAE:"Your data is being corrected, you can migrate other Oberlo accounts once the data correction is completed.",
    OberloTopTipOneNoBindAE:'Please link your AliExpress account to DSers so that we can correct your orders status, you can continue to migrate other Oberlo accounts once the correction is completed.',
    OberloTopTipTwo:"Please note that DSers displays all the Shopify orders, while Oberlo displayed only the orders that contained products created through Oberlo.</br>" +
    "O DSers migrou os pedidos dos últimos dois meses. Caso você precise sincronizar mais pedidos, <a style='color:#3E5BFE' href='https://www.messenger.com/t/DSersOfficial' target='_blank'>entre em contato conosco.</a>"
  }
};
