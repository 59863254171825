import React from 'react';
import { Modal, Button } from 'antd';

import styles from './CheckAeModal.less';

const CheckAeModal = ({ visible, type, onCancel, onDismiss }) => {
  if (!type) {
    return null;
  }

  return (
    <>
      <Modal
        className={styles.ModalTest}
        visible={visible}
        footer={null}
        width={560}
        maskClosable={false}
        keyboard={false}
        onCancel={onCancel}
        bodyStyle={{ padding: '36px 24px' }}
      >
        {type === 'notMatch' ? (
          <>
            <h2 className={styles.title}>Affiliate optimization</h2>
            <p className={styles.desc}>
              We detected that you activated Admitad affiliate program. Please
              stay logged in the AliExpress account you linked to DSers to make
              sure the cashback is generated properly by DSers.
              <span className={styles.highlight}>
                The current logged in account is not the one you linked to
                DSers.
              </span>
            </p>
            <div className={styles.footer}>
              <Button onClick={onDismiss} data="dx">Dismiss</Button>
              <a
                href="https://login.aliexpress.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button onClick={onCancel} type="primary" data="dx">
                  Go to AliExpress
                </Button>
              </a>
            </div>
          </>
        ) : null}
        {type === 'notLogin' ? (
          <>
            <h2 className={styles.title}>Affiliate optimization</h2>
            <p className={styles.desc}>
              We detected that you activated Admitad affiliate program. Please
              keep the correct AliExpress account logged in to make sure the
              cashback is generated properly by DSers.
            </p>
            <div className={styles.footer}>
              <Button onClick={onDismiss} data="dx">Dismiss</Button>
              <a
                href="https://login.aliexpress.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button onClick={onCancel} type="primary" data="dx">
                  Go to AliExpress
                </Button>
              </a>
            </div>
          </>
        ) : null}
      </Modal>
    </>
  );
};

export default CheckAeModal;
