// import { routerRedux } from "dva/router";
import {
  createShopfiyStore,
  AliExpressAuthStatus,
  postGuidance,
  sendMessage,
  setResetPassWord,
  getIsSnyc,
  getSyncTask,
  getAliAuthUrl,
  getOrderTask,
  getShopifyStoreStatus,
  updateShopfiy,
  unBindAli,
  getNotificationUnreadLength,
  getSyncFulfillment,
  // 跳转woo
  goWoo,
  // 支付计划
  disbursementSchedule,
  sendEmptyOrders,
  setUserStatus,
  getOldOrderTask,
  checkPlan, //oberlo迁移获取套餐状态
  checkProductLimit //product商品数量和限制
} from '../services/topBtn';

export default {
  namespace: 'topBtn',

  state: {
    shopfiy: {},
    messageCountData: {},
    fromOberloPlan:{},
    productLimit:false
  },

  effects: {
    *createShopfiy({ payload }, { call, put }) {
      const data = payload;
      const response = yield call(createShopfiyStore, data);
      yield put({
        type: 'setShopfiy',
        payload: response
      });
    },

    *aliexpressStatus({ payload }, { call, put }) {
      yield call(AliExpressAuthStatus, payload);
    },

    *postGuidance({ payload }, { call, put }) {
      const response = yield call(postGuidance, payload);
      yield put({
        type: 'setPostGuidance',
        payload: response
      });
    },

    *sendUserFeedback({ payload }, { call }) {
      yield call(sendMessage, payload);
    },

    // 跳转woo
    *goWoo({ payload }, { call }) {
      yield call(goWoo, payload);
    },

    // 支付计划
    *disbursementSchedule({ payload }, { call }) {
      yield call(disbursementSchedule, payload);
    },

    *resetPassword({ payload }, { call }) {
      yield call(setResetPassWord, payload);
    },

    *isSync({ payload }, { call }) {
      yield call(getIsSnyc, payload);
    },

    *syncTask({ payload }, { call }) {
      yield call(getSyncTask, payload);
    },

    *aliauth({ payload }, { call }) {
      yield call(getAliAuthUrl, payload);
    },

    *task({ payload }, { call }) {
      yield call(getOrderTask, payload);
    },

    *shopifyStatus({ payload }, { call }) {
      yield call(getShopifyStoreStatus, payload);
    },

    *updateShopfiy({ payload }, { call }) {
      yield call(updateShopfiy, payload);
    },

    *unbind({ payload }, { call }) {
      yield call(unBindAli, payload);
    },

    *notificationUnreadLength({ payload }, { call, put }) {
      try {
        const response = yield call(getNotificationUnreadLength, payload);

        if (response && response.code === 2000 && response.data) {
          yield put({
            type: 'updateMessageCountData',
            payload: response.data
          });
        }
      } catch (error) {
        console.log('notificationUnreadLength error:', error);
      }
    },

    *syncFulfillmentStatus({ payload }, { call }) {
      yield call(getSyncFulfillment, payload);
    },

    *emptyorders({ payload }, { call }) {
      yield call(sendEmptyOrders, payload);
    },

    *userStatus({ payload }, { call }) {
      yield call(setUserStatus, payload);
    },

    *taskOld({ payload }, { call }) {
      yield call(getOldOrderTask, payload);
    },
    *checkPlan({ payload }, { call,put }){
      const res = yield call(checkPlan,payload)
      if(res.data){
      yield put({
          type:'fromOberloPlan',
          payload: res.data
        })
      }
    },
    *getProductLimitStatus({payload},{call,put}){
      const res = yield call(checkProductLimit)
      const shopifyLimit = Math.round(res[0]?.value?.data?.count / res[0]?.value?.data?.limit * 10000) / 100.00
      const aeLimit = Math.round(res[1]?.value?.data?.count / res[1]?.value?.data?.limit * 10000) / 100.00
      if(shopifyLimit >= 90 || aeLimit >= 90){
        yield put({
          type:'setCheckProductLimit',
          payload:true
        })
      }else{
        yield put({
          type:'setCheckProductLimit',
          payload:false
        })
      }
    }
  },
  
  reducers: {
    setShopfiy(state, { payload = {} }) {
      return {
        ...state,
        shopfiy: payload
      };
    },
    setPostGuidance(state, { payload = {} }) {
      return {
        ...state
      };
    },
    updateMessageCountData(state, { payload }) {
      return {
        ...state,
        messageCountData: payload
      };
    },
    fromOberloPlan(state, { payload }){
      return {
        ...state,
        fromOberloPlan: payload
      };
    },
    setCheckProductLimit(state,{payload}){
      return {
        ...state,
        productLimit:payload
      }
    }
  }
};
