import React, { useState, useEffect, useCallback, useRef } from 'react';
import { connect } from 'dva';
import { withRouter } from 'dva/router';
import { Button, Icon } from 'antd';

import className from 'classnames';

import SendEmail from './SendEmail';

import { Get } from '../../../utils/request';

import styles from './ReceiveMessage.less';
import successIco from '../../../assets/bigUserMessage/success.svg';

let unListen;

function ReceiveMessage(props) {
  const [showEmail, setShowEmail] = useState(false);
  const [sendEmailSuccess, setSendEmailSuccess] = useState(false);
  const [bigUserMsg, setBigUserMsg] = useState(undefined);
  const { userInfo } = props;
  const timer = useRef(null);

  const closeEmail = useCallback(isSend => {
    setShowEmail(false);
    setSendEmailSuccess(isSend);
  }, []);

  const closeMsg = useCallback(() => {
    const user = userInfo || JSON.parse(localStorage.getItem('store'));

    window.dataLayer.push({
      event: 'bigUserEvent',
      type: 'close'
    });

    props.dispatch({
      type: 'global/delBigUserMsg',
      payload: {
        cancelMessage: true,
        callback: d => {
          if (d.code === 2010) {
            localStorage.removeItem(`bigUserMsg_${user.user_id}`);
            setBigUserMsg(undefined);
          }
        }
      }
    });
  }, [userInfo]);

  async function getMsg(user) {
    try {
      const res = await Get('/inbox_notification/get', { cancelMessage: true });
      if (res && res.code === 2000) {
        const data = res.data || undefined;
        localStorage.setItem(`bigUserMsg_${user.user_id}`, data);
        setBigUserMsg(data);
        setSendEmailSuccess(false);
      }
      if (!res && unListen) {
        unListen();
      }
    } catch (error) {
      console.log('fetch error:', error);
    }
  }

  useEffect(() => {
    const user = userInfo || JSON.parse(localStorage.getItem('store'));

    if (user && localStorage.getItem(`bigUserMsg_${user.user_id}`)) {
      setBigUserMsg(localStorage.getItem(`bigUserMsg_${user.user_id}`));
    }

    timer.current = setInterval(() => {
      getMsg(user);
    }, 1000 * 60);

    unListen = props.history.listen(() => {
      getMsg(user);
    });

    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, []);

  function handleEmail() {
    window.dataLayer.push({
      event: 'bigUserEvent',
      type: 'email'
    });

    setShowEmail(true);
  }

  if (!bigUserMsg) {
    return null;
  }

  return (
    <>
      <div className={className(styles.wrap, { [styles.wrapHide]: showEmail })}>
        <Icon type="close" className={styles.close} onClick={closeMsg} />
        {sendEmailSuccess ? (
          <p className={styles.sendEmailSuccess}>
            Thank you for your reply.
            <br />
            We will get back to you very soon.
            <embed
              src={successIco}
              width="62"
              height="62"
              type="image/svg+xml"
              pluginspage="http://www.adobe.com/svg/viewer/install/"
            />
          </p>
        ) : (
          <div
            className={styles.desc}
            style={{ maxHeight: 'calc( 100vh - 270px )' }}
            dangerouslySetInnerHTML={{ __html: bigUserMsg }}
          />
        )}
        <div className={styles.btnLine}>
          <Button className={styles.emailBtn} onClick={handleEmail}>
            Email
          </Button>
        </div>
      </div>
      {showEmail ? (
        <SendEmail
          closeEmail={closeEmail}
          closeMsg={closeMsg}
          bigUserMsg={bigUserMsg}
        />
      ) : null}
    </>
  );
}

export default withRouter(
  connect(({ login, loading }) => ({
    userInfo: login.userInfo,
    sendLoading: loading.effects['global/sendEmail']
  }))(ReceiveMessage)
);
