import React, { Component } from 'react';
import { connect } from 'dva';
import { Icon } from 'antd';

import fail from '../../assets/setting/fail.svg';
import success from '../../assets/setting/success.svg';

import styles from './ActivationResult.less';

@connect(({ register, loading }) => ({
  register,
  submitting: loading.effects['register/activation']
}))
export default class ActivationResult extends Component {
  constructor(props){
    super(props);
    this.state = {
      result: 'Loading',
      tip: null
    };
    this.timer = null;
  }

  componentDidMount() {
    this.setActivation();
  }

  componentWillReceiveProps(nextProps) {
    const { activation } = nextProps.register;
    if (activation && this.props.register.activation !== activation) {
      let result;
      let tip;

      if (activation.code === 2000 || activation.code === 2010) {
        result = 'Success';
        tip = 'Automatically jump to new page after 3 seconds';
        this.timer = setTimeout(() => {
          window.location.href = '/app/settings?id=1';
        }, 3000);
      } else {
        result = 'Activation failure';
        if (activation.msg === 'time expired') {
          tip = 'Please log in.';
        } else if (activation.msg == 'user verified') {
          tip = 'Your email has been verified.';
        } else if (
          activation.msg == 'code error' ||
          activation.msg == 'get user error'
        ) {
          tip = 'The link is expired.';
        } else {
          this.tip = 'activation failure';
        }
      }

      this.setState({
        result,
        tip
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  setActivation = () => {
    const { code } = this.props.match.params;
    this.props.dispatch({
      type: 'register/activation',
      payload: {
        data: {
          code
        },
        cancelMessage: true
      }
    });
  };

  render() {
    const { result, tip } = this.state;

    return (
      <div className={styles.wrap}>
        <div className={styles.icon}>
          {result === 'Loading' ? (
            <Icon type="loading" className={styles.loading} />
          ) : (
            <embed
              src={result === 'Success' ? success : fail}
              width="91"
              height="91"
              type="image/svg+xml"
              pluginspage="http://www.adobe.com/svg/viewer/install/"
            />
          )}
        </div>
        <h3
          className={styles.title}
          style={{ color: result !== 'Success' ? '#333' : '#4EC364' }}
        >
          {result}
        </h3>
        {result !== 'Loading' ? <p className={styles.desc}>{tip}</p> : null}
      </div>
    );
  }
}
