import React, { useState, useEffect } from 'react';
import { connect } from 'dva';
import { Alert, Icon, Button } from 'antd';
import intl from 'react-intl-universal';

import { defaultSelected } from '../../until';

import styles from './PartnerHome.less';

const Home = ({ dispatch }) => {
  const [data, sedate] = useState({});
  // const [btnLading, setBtnLading] = useState(false);

  useEffect(() => {
    dispatch({
      type: 'partner/GetDashBoardData',
      payload: {
        callback: d => {
          // console.log(d);
          if (d.code === 2000 || d.code === 5000) {
            const newData = d.data;
            if (newData.commission_on_hold === 0) {
              newData.commission_on_hold = '0.00';
            }
            if (newData.commission_confirmed === 0) {
              newData.commission_confirmed = '0.00';
            }
            defaultSelected(d.data);
            sedate(newData);
          } else {
            window.localStorage.removeItem('partner-store');
            window.localStorage.removeItem('partner-authority');
            window.location.href = '/app/partner/login';
          }
        }
      }
    });
  }, []);
  return (
    <div
      className={styles.connect}
      style={{ margin: '0 auto', marginTop: '1.3rem' }}
    >
      {data.user_prompt ? (
        <Alert
          // message="We got your request. Team will review it and get back to you in 7 working days. Thank you for your patience."
          message={intl.get('partner.home.seven_days_tips')}
          type="warning"
          showIcon
        />
      ) : null}

      <div className={styles.homeMain}>
        <h1>{intl.get('partner.home.title')}</h1>
        <main>
          <div className={styles.mainList}>
            <div className={styles.line}>
              {/* <div className={styles.amount}>
                <header>
                  <Icon type="wallet" />{' '}
                  {intl.get('partner.home.commission_on_hold')}
                </header>
                <div>{`$ ${
                  data.commission_on_hold ? data.commission_on_hold : '0.00'
                }`}</div>
              </div> */}
              <div className={`${styles.amount} ${styles.available_amount}`}>
                <header>
                  <Icon type="dollar" />{' '}
                  {intl.get('partner.home.commission_confirmed')}
                </header>
                <div>{`$ ${
                  data.commission_confirmed ? data.commission_confirmed : '0.00'
                }`}</div>
              </div>
            </div>
            <br />
            {
              !data.on_hold_list?.some(item => {
                return item.partner_id.match(/(@\$!)$/)
              }) ? <div className={styles.line}>
              <table className={styles.table}>
                <tbody>
                  {data.on_hold_list?.map((item, index) => (
                    <tr key={index}>
                      {/* <td>
                        <div className={styles.partnerId}>
                          {item.partner_id}
                        </div>
                      </td>
                      <td>
                        <div className={styles.amount_number}>
                          ${item.amount.toFixed(2)}
                        </div>
                      </td> */}
                      <td>
                        <div className={styles.confirm_partnerId}>
                          {data.confirmed_list[index].partner_id}
                        </div>
                      </td>
                      <td>
                        <div className={styles.amount_number}>
                          ${data.confirmed_list[index].amount.toFixed(2)}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div> : null
            }
            
          </div>
        </main>
        <footer>
          <Button
            type="primary"
            // loading={btnLading}
            disabled={data.commission_confirmed < 50}
          >
            {intl.get('partner.home.request_commission')}
          </Button>
        </footer>
      </div>
    </div>
  );
};
export default connect()(Home);
