
    export default 
    {
    "supplier_optimizer": {
        "title": "Optimizador de proveedores",
        "title_test": "Otro proveedor",
        "sub_title": "Basado en tus necesidades",
        "intro": "Puedes encontrar el mismo producto utilizando el link de la URL del producto de AliExpress. Haz clic  <a href=\"https://help.dsers.com/supplier-optimizer-for-shopify/\" target=\"_blank\">aquí para</a> obtener más información.",
        "placeholder": "Busca productos similares utilizando la URL de AliExpress",
        "product_list": "Lista de productos",
        "ship_from": "Enviar desde",
        "ship_to": "Enviar a",
        "sort_by": "ordenar por",
        "product_title": "Producto",
        "price": "Precio",
        "sale_number": "Número de venta",
        "product_rating": "Valoración del producto",
        "store_rating": "Valoración de la tienda",
        "import_to_dsers": "Importar a Dsers",
        "not_found": "No hemos podido encontrar un producto similar",
        "import_picture": "Importar imagen",
        "upload_tip": "Por favor, sube fotos propias y buscaremos el mismo producto en AliExpress para ti.",
        "click_to_upload": "Haz clic para subir la foto",
        "click_or_drag": "Haz clic en cargar o arrastra la imagen directamente",
        "only_img": "Sólo se pueden subir imágenes en formato PNG y JPG (tamaño de imagen máximo 8MB)",
        "delivery": "Fecha de entrega",
        "delivery_info": "Detalles de envío",
        "url_error": "El formato de la URL es incorrecto, por favor ingresa la URL del producto de AliExpress",
        "sys_error": "El sistema está actualmente en mantenimiento, por favor inténtalo de nuevo en 2 minutos",
        "ship_tip": "Haz clic en esta casilla si quieres importar la información del tiempo de envío de los productos",
        "search_tip": "Haz clic en buscar para encontrar proveedores similares",
        "shipping_price": "Precio de envío",
        "searched_product": "Producto comparado",
        "ship_method": "Método de envío",
        "loading_state_1": "Cargando imagen...",
        "loading_state_2": "Analizando imagen...",
        "loading_state_3": "Buscando productos...",
        "have_try": "Probar",
        "msg": "Estamos ajustando la función Optimizador de proveedores y algunos datos pueden ser incorrectos. Estamos trabajando duro para solucionarlo. Gracias por su comprensión y paciencia.",
        "uploadimage": "CARGAR IMAGEN",
        "agency": "Agencia",
        "agency_2": "Agencia",
        "agencySub": "¿No encuentras un agente fiable? <br/>\n\t\tDSers te ayuda a conectar con un agente verificado para tus necesidades de abastecimiento.",
        "learnmore": "MÁS INFORMACIÓN",
        "Laura": "Esta es Laura. Siempre está en línea, es seria, rápida, con un servicio sincero para ti.",
        "Ciel": "Confiable y digno de confianza. Este es Ciel. Tu tiempo y tu esfuerzo merecen ser correspondidos con la destreza de Ciel.",
        "Nora": "Tiene el objetivo de prometer un precio económico y un envío fiable . Esperamos que podamos cooperar mutuamente.",
        "Michael": "Dominio de todo tipo de búsqueda de productos. Servicio preciso y eficiente para los clientes.",
        "Rebekah": "Hola. Soy Rebekah. Puedo ofrecerte productos económicos pero cualificados y resolverte los problemas a tiempo.",
        "Chloe": "Familiarizado con el proceso de negocio del dropshipping. Propósito del servicio: encontrar y satisfacer tus demandas cuidadosamente.",
        "Kevin": "Centrarse en el suministro de productos para Brasil, Europa, EE.UU.. Precio de fábrica con envío rápido.",
        "Bess": "Proveedor chino, enfocada en dropshipping con 3 años de experiencia, 24 horas en línea.",
        "Anchor": "DSers Mass Supplier, equipo de agentes profesionales. Sed bienvenidos a consultar cualquier pregunta relacionada con DSers y sus productos.",
        "David": "¡Aquí David, puedo resolver todos tus problemas! Mientras tanto, podemos hablar acerca de China o de algún otro tema.",
        "Tony": "La satisfacción del cliente está sujeta a factores integrales, incluyendo el precio. DSers siempre estará comprometido con esto.",
        "Alisa": "Alisa de Dsers Supply ofreciendo servicios de suministro personalizados. No dudes en dejar tus solicitudes.",
        "Keith": "¡24 horas de servicio! ¡Soy una profesional con cuatro años de experiencia en el negocio de suministros masivos dropshipping!",
        "Cohen": "¡Aquí Cohen con ganas de hacer amigos! ¡Satisfacer tu demanda y solventar cualquier molestia son mi mejor trabajo!",
        "April": "Soy April. Te proporcionaré productos de alta calidad a precios bajos y te daré el mejor servicio.",
        "Stone": "Soy Stone, competente en la búsqueda de un buen precio para los clientes y muy paciente en la resolución de sus problemas.",
        "Jessica": "Soy Jessica. Te serviré de todo corazón, espero que podamos colaborar juntos, ¡mi amigo!",
        "Duan": "¡El mejor, el más rápido, más sincero, y siempre listo para cualquier solicitud",
        "Sophia": "Hola, mis amigos de dropshipping, dadme vuestras peticiones, Dsers hará lo mejor para que estéis satisfechos.",
        "agenct": "Agente",
        "agencytext": "Los agentes de suministro se dedican al negocio del dropshopping.\n\t\tLos equipos se basan principalmente en China, donde el servicio de suministro es el más fuerte.",
        "search_product": "Buscar producto",
        optimizerTitle:`Buscar Proveedores de Calidad`,
        optimizerDesc_1:'1. Introduce la URL que desees en el campo de búsqueda o utiliza una imagen',
        optimizerDesc_2:`
        2. En los resultados de la búsqueda, puedes elegir el mejor proveedor según tus necesidades`,
        "noSearch":`No search results, please change the URL or image to search again`
    }
}