export default {
    migration: {
        progress_1:'Account creation',
        progress_2:'Settings Preference',
        progress_3:'Data migration',
        title:'Welcome to DSers migration',
        title_2:'We prepared 2 data migration solutions. Which one do you prefer?',
        scenario_1:'If you manage your business with Oberlo, we recommend:',
        scenario_1_title:'Replace DSers data with Oberlo data',
        scenario_1_text:'Please note that the DSers data will be deleted.',
        scenario_2:'If you manage your business with Oberlo and DSers, we recommend:',
        scenario_2_title:'Merge Oberlo data and DSers data',
        scenario_2_text:'Please confirm the data migration preference.',
        button:'APPLY THE CHOSEN PREFERENCES',
        blog:'Quick Guide : Migration Process | Feature comparison | Plan comparison',
        contact_1:'24/7 Live chat support',
        contact_2:'Email Support',
        option_1_title:'For orders & products conflict between DSers and Oberlo, you want :',
        option_1_text:`There may be some conflicts if a Shopify product is set with different AliExpress
        suppliers in DSers and Oberlo, or if you placed an order to AliExpress with Oberlo, but not in DSers`,
        option_2_title:'For settings conflict between DSers and Oberlo, you want:',
        option_2_text:`For example, DSers and Oberlo both have the pricing rule feature, your
        data migration may meet some conflicts due to different settings templates`,
        option_1_1:'DSers as reference',
        option_1_2:'Oberlo as reference',
        setting_title:'For data conflicts between DSers and Oberlo, you want:',
        setting_text:'For example, DSers and Oberlo both have Pricing Rule feature, your data migration may get into conflict because they have different setting templates, so you need to confirm the preference in advance.',
        data_title:'Click to start',
        data_title_2:`During the migration, your Oberlo and DSers accounts will be unavailable.
        They will be available again after completion.`,
        data_button:'START MIGRATION',
        data_button_wait:'Pending Migration',
        data_button_text:'By clicking Start Migration, your Oberlo account will be set to read-only and you agree to our terms of use. Migration takes up to {time} hour.',
        data_change:'Modify preferences',
        data_process_title:'Migration in progress...',
        data_process_text:'Please keep DSers & Oberlo app installed and Shopify store active.',
        data_setting:'Settings',
        data_order:'Orders',
        data_product:'Products',
        data_plan:'Plan',
        optionstext:{
            setting:['Settings migration in progress...','Reduce dispute rate by syncing tracking numbers to PayPal'],
            order:['Order data migration in progress...','Place 100+orders in seconds','Automatically synchronize orders status.'],
            product:['Product data migration in progress...','Create products bundle with variants mapping'],
            plan:['Subscription plan matching...','Get access to multiple powerful features.']
        },
        waitmigration_title:'Wait until the current migration is completed',
        waitmigration_text:'The Oberlo account migration already started. Please wait for this account to be migrated. If you have entered DSers, please link your AliExpress account to complete your migration.',
        timeout_title:'Request timed out',
        timeout_text:'The request timed out, please refresh the page or reauthorize from Oberlo.If you have any question, please contact DSers support.',
        timeout_contact:'Contact Support',
        childAccount:'The current account is a DSers sub-account and cannot be used for migration. Please log in to the main account or create a new DSers account.',
        blackAccount:'The current account is unavailable. Please change the email address and try again.',
        otherplatform:'The current account has been bound to other platforms, please change DSers account and try again.',
        card_1_title:'How do you want to deal with your data?',
        question_1:'1. For products data in two accounts, you want to：',
        question_1_select1:'Migrate Oberlo data',
        question_1_select2:'Keep DSers data too',
        question_2:'2. For orders data in two accounts, you want to:',
        card_2_title:'How do you want to deal with data conflict?',
        question_3:`1. For orders & products conflict between DSers and Oberlo, you prefer:`,
        question_3_select1:'Oberlo',
        question_3_select2:'DSers',
        question_4:'2. For settings conflict between DSers and Oberlo, you prefer:',
        childAccountawait:'Migration in process...',
        childAccountawaittext:'DSers account undergoing migration started by your Admin account.',
        code_500:'Migration exception, please contact DSers support to resolve this issue.',
        code_426:'The service is being upgraded, please try again later. If you have any questions, please contact DSers support.',
        exitmigrationtext:'After exiting the migration, you can go to Oberlo to restart the migration',
        shoperror:'Please ensure that your Shopify store is ready and has authorized DSers',
        shopcontinue:`CONTINUE MIGRATION`,
        dataerror:'Some problems with the data occurred during the migration process, click the "Try Again" button to retry, or click "Contact Customer Service" to contact us',
        datacontinue:'TRY AGAIN',
        refresh:'Please refresh the page',
        customerservice:'CONTACT SUPPORT',
        oberloError:'Access to Oberlo failed. Please contact the customer service.',
        requestid:'Please report this ID to DSers support team for trouble shooting Request ID:' ,
        startButton:`Note：<br/>
        1.After you start migration, your Oberlo account will be set to read-only. <br/> 2.Migration takes about 1 hours`  ,
        exitmigration:'Exit migration',
        according:'What data will be in your DSers account finally?',
        cover:`According to your configuration.DSers will keep your Oberlo account's Products & Orders & Settings data`,
        oberlo_oberlo:'According to your configuration.DSers will keep the Products & Orders & Settings data from DSers and Oberlo accounts for you. In case of data conflicts during synchronization, the Oberlo data shall prevail.',
        dsers_dsers:'According to your configuration.DSers will keep the Products & Orders & Settings data from DSers and Oberlo accounts for you. In case of data conflicts during synchronization, the DSers data shall prevail.',
        dsers_oberlo:'According to your configuration.DSers will keep the Products & Orders & Settings data from DSers and Oberlo accounts for you. In case of data conflicts during synchronization, the Products & Orders data from DSers shall prevail, and the Settings  data from Oberlo shall prevail',
        oberlo_dsers:'According to your configuration.DSers will keep the Products & Orders & Settings data from DSers and Oberlo accounts for you. In case of data conflicts during synchronization, the Products & Orders data from Oberlo shall prevail, and the Settings data from DSers shall prevail',
        havetask:'A task is being performed on the current account. Please try again later.',
        timeout:'The server request timed out, please click the "Start Migration" button to restart the migration',
        data_wait_text:'Migration will start within {time} hour. You can operate your Oberlo account while you wait, but migration may start at anytime.',
        appointTip:'After setting the time, you cannot modify it. Please select a suitable time to migrate. After the migration starts, you won’t be able to operate Oberlo and DSers. If you have any questions, please contact us.',
        appointConfirm:'Confirm time setting',
        appointSuccess:'Time set successfully',
        startNow:'Start migration now',
        timefull:'The current selected time slot is full, you can choose to start the migration immediately',
        nowTimeFull:'This time slot is unavailable, please select another time slot',
        tooltiptext:`You have data information on both Oberlo and DSers, we chose to keep data on both platforms for you by default. If you don't want to keep DSers data you can uncheck the box next to 'Keep DSers data too’.`,
        tooltiptext_2:`For example, if you have the same item on Oberlo and DSers, but you have different descriptions and pricing specifications for the item, data migration may encounter some conflicts.`,
        migrationText:'Migration Start Time'
    }
}