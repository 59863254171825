import React, { Fragment } from 'react';
import { connect } from 'dva';
import { Input, Drawer, Row, Tag, Spin, Button,Checkbox,Radio } from 'antd';
import intl from 'react-intl-universal';
import qs from 'querystring';

import { getAliExpressUrl, replaceUrlToHttps } from 'utils/utils';
import { getProductList, setProductList, deletePorductList } from 'services/productmy';
import MoreAction2 from './MoreAction2';
import SkuSelect from './SkuSelect';
import styles from './index.less';
import './mapping.css';
import { object } from 'prop-types';

const { CheckableTag } = Tag;
const { Search } = Input;

@connect(({ productmy, loading }) => ({
  productmy,
  loading: loading.models.productmy,
  getAeLoading: loading.effects['productmy/aeProductDetail'],
  historyLoading: loading.effects['productmy/importHistory']
}))
export default class ImportAliExpressMapping extends React.PureComponent {
  cacheUrlFromDsers = {};

  constructor(props) {
    super(props);
    this.state = {
      prod: [],
      select: [],
      selectedProd: null,
      skuImg: '',
      searchUrl: '',
      historyList: [],
      prodList: [],
      showHistory: false,
      changeSelect: [],
    };
    this.shopifyProductId = props.shopifyProductId;
    this.changeSelect = [];
  }

  componentDidMount() {
    this.getStandardProduct();
  }

  componentDidUpdate(prevProps) {
    const {selectData} = this.props;
    const { sp } = this.props;
    console.log(sp)
    if(sp !== prevProps.sp) {
      const selectedSkus = sp.split(';');
      this.optionLength = selectedSkus.length;
      if(selectedSkus.length === 1 && selectedSkus[0] === '<none>') {
        selectedSkus[0] = ':';
      }
      this.setState({
        selectedProd: sp.platform_product_id,
        select: selectedSkus,
      })
    }
    if(selectData,length !== 0) this.changeSelect = selectData;

    console.log(this.changeSelect,'this.changeselect');

    // this.setState({
    //   changeSelect: selectData
    // })
    // const { importProductId, sp } = this.props;
    // if (prevProps.importProductId !== importProductId && importProductId) {
    //   const url = getAliExpressUrl(importProductId);
    //   this.getAeProduct(url);
    //   this.setState({
    //     searchUrl: url
    //   });
    // }
  }

  shopifyProductId = '';
  skuMap = {}; //
  select = []; // 选中的sku
  optionLength = 99;
  importData = {
    platform_product_id: '',
    platform_sale_id: '',
    sku: '',
    sku_image: '',
    title: '',
    sku_desc: ''
  };


  // 获取商品池接口
  getStandardProduct = () => {
    getProductList({
      data: {
        dsers_product_id: this.props.productId,
      }
    }).then(res => {
      if(res.code == 2000){
          this.setState({
              prod: res.data
          })
      }
    })
  }

  // 获取ae商品详情
  getAeProduct = url => {
    console.log(111);
    const { sku } = this.props.sp || '';

    if (!url) {
      return;
    }

    // 统计用户mapping使用的url是否来自dsers的商品池或optimizer
    this.cacheUrlFromDsers = {};
    const urlSearchIndex = url.indexOf('?');
    if (urlSearchIndex > -1) {
      const urlSearch = url.slice(urlSearchIndex + 1);
      const searchObj = qs.parse(urlSearch);
      if (searchObj.dsref) {
        const aeProductId = (url.match(/\/([1-9][0-9]*)\.htm/) || [])[1];
        if (aeProductId) {
          this.cacheUrlFromDsers[aeProductId] = searchObj.dsref;
        }
      }
    }

    this.setState({
      showHistory: false
    });

    this.props.dispatch({
      type: 'productmy/aeProductDetail',
      payload: {
        data: {
          url
        },
        callback: d => {
          if (d.code == 2000) {
            const img = GetProductDefaultImage(d.data);

            // 导入商品池
            this.setProduct(d.data, url);

            if(sku){
              this.getSkuDefaultData(d.data, sku);
            }

            if(d.data.product_id !== this.props.importProductId) {
              this.select = [];
            }

            this.setState({
              prodList: d.data,
              skuImg: this.props.sp?.sku_image || img,
            });

            const [opts, skuMap] = GetProductSkuOptionFromDetail(d.data);

            this.optionLength = opts.length;
            this.skuMap = skuMap;

            this.importData.platform_product_id = `${d.data.product_id}`;
            this.importData.platform_sale_id = d.data.owner_member_id;
            this.importData.title = d.data.subject;
            this.importData.sku_image = img;
          }
        }
      }
    });
  };


  // 导入商品池
  setProduct = (val, url) => {
    const { productId,  } = this.props;
    setProductList({data: {
        dsers_product_id: productId,
        supplier: url,
      }}).then(res => {
        if(res.code == 2000){
            this.getStandardProduct();
        }
      })
  }

  // 获取mapping过的商品
  getHistory = () => {
    const data = {
      id: this.shopifyProductId
    };

    if (this.props.storeId) {
      data.store_id = this.props.storeId;
    }

    this.props.dispatch({
      type: 'productmy/importHistory',
      payload: {
        data,
        callback: d => {
          if (d.code == 2000) {
            this.setState({
              historyList: d.data
            });
          }
        }
      }
    });
  };

  // 加载sku默认数据
  getSkuDefaultData = (prod, sku) => {
    const res = [];
    if (!prod.aeop_ae_product_s_k_us) {
      return [];
    }

    const { aeop_ae_product_s_k_us: { aeop_ae_product_sku = [] } } = prod;

    let defaultSelect = [];

    aeop_ae_product_sku.forEach((item) => {
      if(item.id === sku){
        item?.aeop_s_k_u_propertys?.aeop_sku_property?.forEach((v,i) => {
            res.push(`${v.sku_property_id}:${v.property_value_id_long}#${v.sku_property_value}`);

            defaultSelect.push(Object.assign(v, {index: i}))
        })
      }
    })

    this.select = defaultSelect;

    this.setState({
      select: res,
    })
  }

  searchFocus = e => {
    const { searchUrl, historyList } = this.state;
    const { historyLoading } = this.props;

    if (historyLoading || searchUrl) {
      return;
    }

    if (historyList.length) {
      this.setState({
        showHistory: true
      });
      return;
    }

    this.setState({
      showHistory: true
    });
    this.getHistory();
  };


  changeTag = (e, tag, changeSelect, prodId, length) => {
    let checked = e.target?.checked;
    let { select, skuImg } = this.state;
    console.log(this.props.selectData, 'this.props.selectData');
    this.optionLength = length;
    if (checked) {
      // select[tag.index] = GetSkuPropertyID(tag);

      // this.select[tag.index] = tag;

      if (tag.sku_image) {
        skuImg = tag.sku_image;
      }

    } else {
      select[tag.index] = false;
      this.select[tag.index] = null;
    }

    this.importData.sku_image = skuImg;

    this.setState({
      select: [...select],
      selectedProd: prodId,
      skuImg
    }, () => {
      console.log('change🌞🌞🌞', this.state.select, this.select, this.state);
    });
  };

  // 保存
  handleSave = () => {
    const { select } = this.state;
    this.importData.platform_product_id = this.state.selectedProd;
    if (this.optionLength == 0 && select[0] == ':') {
      this.importData.sku_desc = 'Default Title';
      this.importData.sku = '<none>';
      this.importData.platform_product_id = this.state.selectedProd;
      const refStastics = this.cacheUrlFromDsers[
        this.importData.platform_product_id
      ];
      this.props.importCallback(this.importData, refStastics);
      return;
    }

    if (
      select.length != this.optionLength ||
      select.includes(false) ||
      select.includes(undefined)
    ) {
      return;
    }

    console.log(this.props.selectData,'this.props.selectData')

    if(this.props.selectData.length){
      console.log(111);
      this.select = this.props.selectData;
    }

    this.importData.sku_desc = GetSKUDesc(this.select);
    this.importData.sku = GetSKUID(this.select, this.skuMap);
    const refStastics = this.cacheUrlFromDsers[
      this.importData.platform_product_id
    ];

    console.log('change🌞太阳', this.importData, refStastics, this.select);
    
    this.props.importCallback(this.importData, refStastics, this.select);
  };

  // 更改搜索链接
  changeValue = e => {
    this.setState({
      searchUrl: e.target.value
    });
  };

  // 清除搜索链接
  clearSearch = e => {
    this.setState({
      searchUrl: ''
    });
  };


  // 点击mapping历史条目
  handleHistory = (e, pid) => {
    e.stopPropagation();

    const url = getAliExpressUrl(pid);

    if (this.importData.platform_product_id == pid) {
      this.setState({
        showHistory: false,
        searchUrl: url
      });
      return;
    }

    this.setState({
      searchUrl: url,
      showHistory: false
    });
    this.getAeProduct(url);
  };

  // 搜索输入框变化的时候
  keyUpSearch = e => {
    this.setState({
      showHistory: !e.target.value
    });
  };

  // 隐藏历史弹窗
  hideHistory = e => {
    if (e.target.tagName == 'INPUT') {
      return;
    }
    e.nativeEvent.stopImmediatePropagation();
    this.setState({
      showHistory: false
    });
  };

  handleMoreActionChange = type => {
    const { prod } = this.state;
    if (type === 'open_shipping') {
      this.props.dispatch({
        type: 'dropshippersetting/showModal',
        payload: {
          data: {
            title: prod.subject,
            product_id: prod.product_id
          }
        }
      });
    }
  };

  onSku = ref => {
    this.onSku = ref;
  } 

  render() {
    const {
      getAeLoading,
      visible,
      hideImportSupply,
      historyLoading
    } = this.props;

    const {
      prod,
      select,
      skuImg,
      searchUrl,
      historyList,
      showHistory,
      changeSelect
    } = this.state;

    console.log(this.onSku.aeDetail,'this.sku-=-=')

    return (
      <Drawer
        closable={false}
        visible={visible}
        maskClosable
        width={868}
        title={"Import Supplier & Select Variant"}
        onClose={hideImportSupply}
        className={styles.importDrawer}
      >
        <div style={{ paddingBottom: 56 }} onClick={this.hideHistory}>
          <div className={styles.searchWrap}>
            <div className={styles.searchLine}>
              <Search
                value={searchUrl}
                placeholder={intl.get('mapping.import_title')}
                onSearch={this.getAeProduct}
                onChange={this.changeValue}
                onFocus={this.searchFocus}
                onKeyUp={this.keyUpSearch}
                enterButton
                allowClear
              />
            </div>
          </div>

          <div className={styles.spinContainer}>
            <p className={styles.importTitle}>
              {intl.get('mapping.select_a_variant')}
            </p>
            {prod.length > 0
            ? prod.map(item => {
                return (
                <Fragment>
                    <SkuSelect
                        prod={item}
                        changeTag={this.changeTag}
                        skuImg={skuImg}
                        GetProductSkuOptionFromDetail={GetProductSkuOptionFromDetail}
                        GetSkuPropertyID={GetSkuPropertyID}
                        GetSkuPropertyName={GetSkuPropertyName}
                        prodList={this.state.prodList}
                        getStandardProduct={this.getStandardProduct}
                        handleMoreActionChange={this.handleMoreActionChange}
                        showChangeSelect={select}
                        selectedProd={this.state.selectedProd}
                        sp={this.props.sp}
                        // onRef={this.onSku}
                    />
                </Fragment>)
            }) 
            : (
              <div className={styles.empty}>
                {intl.get('mapping.import_ae_first')}
              </div>
            )}
            {getAeLoading ? (
              <div className={styles.loadingbox}>
                <Spin size="large" />
              </div>
            ) : null}
          </div>
          {prod ? (
            <div className={styles.moreAction2}>
              <MoreAction2
                style={{ border: '1px solid #e3e3e3', padding: '12px 24px' }}
                onChange={this.handleMoreActionChange}
              />
            </div>
          ) : null}
          <div className={styles.bottomSave}>
            <Button
              style={{
                marginRight: 8
              }}
              data="bk"
              onClick={hideImportSupply}
            >
              {intl.get('public.cancel')}
            </Button>
            <Button data="dx" loading={false} type="primary" onClick={this.handleSave}>
              {intl.get('public.submit')}
            </Button>
          </div>
        </div>
      </Drawer>
    );
  }
}

// GetProductSkuOptionFromDetail
function GetProductSkuOptionFromDetail(prod) {
  const map = new Map();
  const skuMap = new Map(); // DSers自己拼接SKU ID和速卖通SKU ID map
  const opts = [];
  if (!prod?.aeop_ae_product_s_k_us) {
    return opts;
  }
  const { aeop_ae_product_s_k_us: { aeop_ae_product_sku = [] } } = prod;
  aeop_ae_product_sku.forEach(e => {
    const dsersSkuProdIds = [];
    if (!e.aeop_s_k_u_propertys) {
      return;
    }
    const { aeop_s_k_u_propertys: { aeop_sku_property = [] } = {} } = e;
    aeop_sku_property.forEach((v, i) => {
      const id = GetSkuPropertyID(v);
      dsersSkuProdIds.push(id);
      if (!map.has(id)) {
        // Init
        if (opts.length <= i) {
          opts.push({
            prop_id: v.sku_property_id,
            prop_name: v.sku_property_name,
            values: []
          });
        }
        v.index = i;
        opts[i].values.push(v);
        map.set(id, true);
      }
    });
    if (!skuMap.has(e.id)) {
      skuMap.set(dsersSkuProdIds.join(';'), e.id);
    }
  });

  console.log(opts, 'opts-=-=');
  console.log(skuMap, 'skuMap-=-=-=');
  return [opts, skuMap];
}

// GetSkuPropertyID 获取SKU属性的ID
function GetSkuPropertyID(prop) {
  let res = `${prop.sku_property_id}:${prop.property_value_id_long}`;
  if (prop.property_value_definition_name) {
    res = `${res}#${prop.property_value_definition_name}`;
  }
  return res;
}

// 获取用户选则的 sku展示的名字 拼起来
function GetSKUDesc(props) {
  const res = [];
  props = props.sort((a, b) => a.index - b.index);
  props.forEach(p => {
    res.push(GetSkuPropertyName(p));
  });
  return res.join(' / ');
}

// GetSkuPropertyName 获取SKU属性展示的名字
function GetSkuPropertyName(prop) {
  if (prop.property_value_definition_name) {
    return prop.property_value_definition_name;
  }
  if (prop.sku_property_value && prop.sku_property_value.length > 0) {
    return prop.sku_property_value;
  }
  return prop.property_value_id_long;
}

// GetProductDefaultImage 获取商品默认图片
function GetProductDefaultImage(prod) {
  if (prod.image_u_r_ls && prod.image_u_r_ls.length > 0) {
    return prod.image_u_r_ls.split(';')[0];
  }
  return '';
}

// GetSKUID 根据属性列表，返回对应AE SKU ID
function GetSKUID(props, skuMap) {
  const res = [];
  props = props.sort((a, b) => a.index - b.index);
  props.forEach(p => {
    res.push(GetSkuPropertyID(p));
  });

  const dsersId = res.join(';');
  return dsersId;
  if (skuMap.has(dsersId)) {
    return skuMap.get(dsersId);
  }
  return '';
}

// CheckShipfromSku 如果有ShipFrom数据项则返回对应Shipfrom信息
function GetShipfromSku(prod) {
  const map = new Map();
  const opts = [];

  if (!prod.aeop_ae_product_s_k_us) {
    return opts;
  }

  const { aeop_ae_product_s_k_us: { aeop_ae_product_sku = [] } } = prod;

  aeop_ae_product_sku.forEach(e => {
    e.aeop_s_k_u_propertys.aeop_sku_property.forEach((v, i) => {
      if (v.sku_property_id == '200007763') {
        const id = GetSkuPropertyID(v);
        if (!map.has(id)) {
          opts.push(v);
          map.set(id, true);
        }
      }
    });
  });

  return opts;
}
