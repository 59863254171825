import React from 'react';
import { Modal, Button } from 'antd';
import intl from 'react-intl-universal';

import styles from './CheckSaveModal.less';

export default function CheckSaveModal(props) {
  const {
    type,
    visible,
    closeCkeckModalHanlder,
    saveAndNextHandler,
    onlycloseCkeckModalHanlder,
    closeCkeckErrModalHanlder,
    toCheckHandler,
    errorMapType,
    errorText,
    restsProps
  } = props;
  const getErrTexthandler = ()=>{
    switch (errorText) {
      case 'advance':
        return intl.getHTML('public.checkmodaladvanceerrtext');
      case 'basicRepeat':
        return intl.getHTML('public.checkmodalbasicrepeaterrtext');
      case 'basicNull':
        return intl.getHTML('public.checkmodalbasicnullerrtext');
      default:
        break;
    }
  }
  if (type === 'notSave') {
    return (
      <Modal
        visible={visible}
        className={styles.modalcontainer}
        footer={null}
        onCancel={onlycloseCkeckModalHanlder}
        {...restsProps}
      >
        <div className={styles.container}>
          <div className={styles.title}>{intl.get('public.unsave')}</div>
          <div className={styles.text}>{intl.get('public.checkmodaltext')}</div>
          <div className={styles.footer}>
            <Button
              className={styles.discardbtn}
              onClick={closeCkeckModalHanlder}
            >
              {intl.get('public.discard')}
            </Button>
            <Button className={styles.savebtn} onClick={saveAndNextHandler}>
              {intl.get('public.newsave')}
            </Button>
          </div>
        </div>
      </Modal>
    );
  } else if (type === 'error') {
    return (
      <Modal
        visible={visible}
        className={styles.modalcontainer}
        footer={null}
        onCancel={closeCkeckErrModalHanlder}
      >
        <div className={styles.container}>
          <div className={styles.title}>{intl.get('public.unsaveErr')}</div>
          <div className={styles.text}>
            <span className={styles.normalStyle}>{intl.getHTML('public.checkmodalerrtext1')}</span>
            <span className={styles.weightStyle}>{getErrTexthandler()}</span>
            <span className={styles.normalStyle}>{intl.getHTML('public.checkmodalerrtext2')}</span>
            </div>
          <div className={styles.footer}>
            {/* <Button
              className={styles.discardbtn}
              onClick={closeCkeckModalHanlder}
            >
              {intl.get('public.discard')}
            </Button> */}
            <Button className={styles.savebtn} onClick={toCheckHandler}>
              {intl.get('public.gocheck')}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}
