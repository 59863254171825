import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'dva';
import { Alert } from 'antd';

import styles from './AELocalAccountAlert.less';
import { getLanguage } from 'utils/utils';
import { InfoAlert } from 'components/Banner/OptimizerInfoTip';

const AELocalAccountAlert = React.memo(props => {
  const { localAEInfo, handleLocalAECheckTip, visible } = props;
  const handleLoginClick = (type) => {
    handleLocalAECheckTip(false);
    DSERS_CUSTOM_COLLECT({
        action: 'LOCAL_LOGIN_AE_ALERT_CLICK',
        custom_info: [
            { name: 'status', value: type },
        ]
    });
  };
  const tipMsg = useMemo(() => {
    let tip = '';
    if ([105, 103].includes(localAEInfo?.affiliateStatus)) {
      tip = (
        <span>
          We have detected that you are not logged in to your AliExpress
          account. Please{' '}
          <a
            className={styles.link}
            href="https://www.aliexpress.com"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => handleLoginClick('NOT_LOGIN')}
          >
            login to the AliExpress account
          </a>{' '}
          that already bound to DSers to ensure timely and accurate
          synchronization of order information.
        </span>
      );
    }
    if (localAEInfo?.affiliateStatus === 102) {
      tip = (
        <span>
          We have detected that the AliExpress account you have logged in is
          inconsistent with the bound account. Please{' '}
          <a
            className={styles.link}
            href="https://www.aliexpress.com"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => handleLoginClick('ACCOUNT_NOT_MATCH')}
          >
            login to the right AliExpress account
          </a>{' '}
          that already bound to DSers to ensure timely and accurate
          synchronization of order information.
        </span>
      );
    }
    return tip;
  }, [localAEInfo]);
  useEffect(() => {
    if ([102, 103, 105].includes(localAEInfo?.affiliateStatus)) {
      handleLocalAECheckTip(true);
    }
  }, [localAEInfo?.affiliateStatus]);
  return visible && tipMsg ? <InfoAlert text={tipMsg} /> : null;
});

export default AELocalAccountAlert;
