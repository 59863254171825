
    export default 
    {
    "admitad": {
        "title": "DSers Admitad ist live!",
        "p1": "Die offizielle Zusammenarbeit zwischen DSers und Admitad ist jetzt online.",
        "p2": "Bitte lesen Sie den folgenden Artikel, um alles einzurichten:",
        "p3": "https://www.blog.dserspro.com/post/how-to-use-admitad-cashback-on-dsers-pro",
        "p4": "Sobald dies geschehen ist, erhalten Sie einen neuen Code im AliExpress WW Angebot.",
        "p5": "Ändern Sie den Code, den Sie derzeit verwenden, und ersetzen Sie ihn durch den neuen Code.",
        "p6": "Hinweis: Die Codes, die nicht mit DSers verbunden sind, werden nächsten Monat eingestellt."
    }
}