
    export default 
    {
    "complete_passWord": {
        "title": "Por favor, registra la información de tu cuenta",
        "tip": "Nos hemos percatado de que los datos de tu cuenta no están registrados.<br /> Por favor, configura tu correo electrónico y contraseña",
        "email_tip": "Puedes cambiar el correo electrónico más tarde yendo a Dsers - Ajustes - Menú de la cuenta.",
        "fill_email": "Por favor, introduce una dirección de correo electrónico",
        "password_tip": "Por favor, usa una contraseña con al menos 6 caracteres.",
        "email_not_valid": "¡La información introducida no es un correo electrónico válido!",
        "password_request": "Por favor, establece una contraseña",
        "astrict": "Por favor, introduce de 6 a 20 caracteres."
    }
}