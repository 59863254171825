
    export default 
    {
    "login": {
        "title": "Loggen Sie sich bei DSers ein",
        "email": "E-Mail",
        "password": "Kennwort",
        "cap_placeholder": "Captcha",
        "log_in": "ANMELDEN",
        "forgot_password": "Passwort vergessen?",
        "enter_username": "Bitte Benutzernamen eingeben!",
        "enter_password": "Bitte geben Sie Ihr Passwort ein!",
        "email_password_error": "E-Mail- oder Passwort-Fehler",
        "empty": "Das Passwort darf nicht leer sein",
        "text_title": "5.0 <br/> <span class={titlek}>Sterne Bewertung mit</span>",
        "text_desc": "10000+ <br/> <span class={titlek}>Bewertungen im Shopify App Store</span>",
        "text_title_2": "Oberlo-Ersatz <br/> <span class={titlek}>offiziell von Shopify empfohlen </span>",
        "text_desc_2": "10000+ <br/> <span class={titlek}>5 Sterne Bewertungen <br/>im Shopify App Store</span>",
        "noHaveText": "Sie haben noch kein Konto?",
        "createAccount": "KONTO ERSTELLEN",
        "emailrequired": "E-Mail ist erforderlich",
        "error": "E-Mail- oder Passwort-Fehler.\t"
    }
}