export default {
  reward: {
    title: 'DSers Reward Season',
    intro:
      'Obtenez plus de $100 de récompense en utilisant des produits du programme DSers Mass Supply. Cliquez <a href="https://www.blog.fr.dsers.com/post/règles-de-la-saison-des-récompenses-dsers" target="_blank" rel="noopener noreferrer">ici</a> pour en savoir plus. Contactez le service client si vous avez des questions.',
    dollar_100: 'Gagnez $100 et plus du 15 Oct. au 15 Jan.',
    dollar_100_intro: `Pour obtenir la récompense de 100$, <br />vous devez placer 1000 commandes valides pendant les 10 jours de l'événement.<br />Le nombre deviendra vert une fois atteint.`,
    get_more_title:
      'Gagnez plus en plaçant plus de commande avec des produit du Marché des Négoces du 15 Oct. au 15 Jan.',
    total_title: `Combien vous avez gagné au total pendant l'événement`,
    request_btn: 'Récupérer Récompense',
    requested_btn: 'Demandé',
    requested_intro:
      'Vous pourrez cliquer ce bouton le 5 Février, GMT +8, si vous avez gagné quelque chose.',
    form_title:
      'Veuillez entrer vos informations ci-dessous, notre équipe vous contactera bientôt.',
    feedback:
      'Notre équipe vous répondra rapidement. <br />Merci de votre patience.'
  }
};
