
    export default 
    {
    "home": {
        "welcome": "¡Bienvenido(a) a Dsers!",
        "orders_to_ae": "pedidos a realizar en AliExpress",
        "order_to_ae": "pedido a realizar en AliExpress",
        "move": "Dirígete hacia:",
        "close": "Cerrar",
        "open": "Abrir",
        "learn_more": "Más información",
        "tab_title": {
            "add_shopify_store": "Añadir tienda Shopify",
            "aliExpress_account": "Cuenta de AliExpress",
            "white_list": "Lista blanca",
            "setting_shipping": "Ajustes del envío",
            "mapping_product": "Mapeo del producto",
            "chrome_extension": "Extensión de Chrome",
            "bulk_order_function": "Función de pedidos masivos"
        },
        "add_shopify_store": {
            "title": "Link a tu tienda de Shopify:",
            "p1": "Importar productos a la tienda de Shopify rápidamente",
            "p2": "Sincronizar los pedidos de los últimos 7 días desde Shopify",
            "p3": "Ir a",
            "p4": "Ajustes>Otros>Sincronizar pedidos de Shopify",
            "p5": "sincronizar más pedidos anteriores",
            "btn": "Vincular de forma gratuita"
        },
        "aliExpress_account": {
            "title": "Vincula la cuenta de <br/> AliExpress para:",
            "p1": "Realizar pedidos automáticamente con un solo clic",
            "p2": "Mejora la eficiencia en un 97%",
            "p3": "Hacer seguimiento de la información de envío",
            "p4": "Asegúrate de que tu cuenta de AliExpress está vinculada como se indica a continuación:",
            "btn": "Link"
        },
        "white_list": {
            "title": "Solicitar una cuenta de lista blanca <br/> de AliExpress para:",
            "p1": "Hacer un pedido utilizando la API oficial de AliExpress",
            "p2": "Desbloquear el procesamiento de pedidos y la limitación de pagos",
            "p3": "Disfrutar del servicio especial de envío de AliExpress",
            "btn": "Aplicar"
        },
        "chrome_extension": {
            "title": "Descargar la extensión  de <br/> Dsers para Chrome:",
            "p1": "Migrar con un clic desde Oberlo",
            "p2": "Importar productos de AliExpress",
            "p3": "Pagos masivos en AliExpress",
            "btn": "Descargar"
        },
        "more_about": "Más información sobre Dsers",
        "video_title_mapping": "¿Cómo mapear el producto de AliExpress antes de hacer un pedido múltiple?",
        "video_title_place_order": "¿Cómo realizar más de 100 pedidos en pocos minutos?",
        "other_features": "Una vista previa a otras características",
        "video_title_add_store": "¿Cómo añadir una tienda de Shopify?",
        "video_title_bind_ae": "¿Cómo vincular y actualizar la cuenta de AliExpress?",
        "video_title_import_products": "¿Cómo importar productos a la tienda de Shopify?",
        "video_title_fulfill_manually": "¿Cómo procesar los pedidos manualmente?",
        "bottom_line": "Tenemos el balance final",
        "home_title": "Bienvenido(a) a Dsers, la aplicación oficial de AliExpress",
        "setup_account": "Configurar tu cuenta Dsers",
        "tutorial": "Tutorial",
        "product_importer": "Importador de productos",
        "chrome_extension_title": "Extensión de Chrome",
        "report": "Informe",
        "check_report": "Verificar las ventas, el gasto y el ROI de los productos",
        "feedback": "Feedback",
        "feedback_share": "Comparte el feedback de tus productos",
        "feedback_useful": "Esta información es muy útil",
        "feedback_not_useful": "Esta información no es útil",
        "feedback_what_to_know": "Indícanos lo que te gustaría saber",
        "comments": "Comentarios",
        "share_feedback": "Compartir feedback",
        "new": "Nuevo",
        "dismiss": "Descartar",
        "tutorial2": "Tutorial",
        "modal_title": {
            "title": "New UI & New Beginning",
            "wenhou": "Dear DSers users,",
            "container_one": "First of all, thank you for your ongoing support to DSers. In order to provide users with a better experience, we specially worked with a professional design team to upgrade the UI of our DSers platform.",
            "container_two": "\n      After six months of preparation, the new version of UI has been officially launched today, you can now start your new UI experience journey by clicking the button below.",
            "container_three": "\n      Of course, we will keep the access to the old version of UI for you in case that you have any doubts or other considerations. After entering the page, you are free to switch between these two versions at any time as well.\n       Both version will remain accessible for a certain period of time, depending on the user's feedback. We will notify you in advance of any further changes.",
            "container_four": "\n      Thank you again for your constant support to DSers, which will be the motivation for us to keep optimizing and become a better DSers platform.\n      ",
            "go_new_btn": "START EXPERIENCING THE NEW UI",
            "use_old": "Continue using legacy UI"
        },
        "home_normal_card_learn_more_link": "https://www.blog.dserspro.com/",
        "go_modify": "Go modify",
        "vat_error": "Dear user, the system has detected that the VAT ID you currently set contains non-numeric characters, which will cause your French order to fail (please note that the VAT ID you set is for the French order, other countries currently don't require it). To process the order smoothly, please go to Setting - Other - VAT to modify or clear it",
        "var_update": "If you do not modify it, we will automatically delete the VAT ID on the French orders",
        "modify_btn": "Modify",
        "var_update_setting": "The current VAT ID for French orders has been discontinued. To process the order smoothly, please modify it in time",
        "change_UI": "Change to old UI",
        "oldUI_out": {
            "notion_title": "New UI Notification",
            "notion_con": "Access to the old UI will not be possible after <br /> October 15th",
            "title": "Brand new UI,<br /> brand new experience",
            "modal_text": "After nearly a month of user feedback and technical adjustments, the current new version of the UI has been improved. To unify the platform features, \n      we will remove the access to the old UI on October 15th. Please be aware in advance. If you have any questions, feel free to <a style=\"color:blue\" href='https://www.messenger.com/t/DSersOfficial' dd=\"src\">contact our customer service</a> team."
        },
        "downloadguide": {
            "guide": "Get the guide",
            "text": "Click to download the<br /> dropshipping guide"
        },
        "guidance": {
            "steps": "Paso",
            "btn_complete": "COMPLETADO",
            "title": "Conoce DSers",
            "desc": "4 pasos para automatizar tu negocio de dropshipping",
            "add_product": "AÑADIR PRODUCTO",
            "push_to_shopify": "EMPUJAR A SHOPIFY",
            "suppiler_mapping": "MAPEO DE PROVEEDORES",
            "place_order": "HACER PEDIDO",
            "add_product_title": "Añade tu primer producto",
            "push_to_shopify_title": "Empujar productos a tu tienda de Shopify",
            "suppiler_mapping_title": "Mapeo: Configurar proveedores de productos",
            "place_order_title": "Procesar tu primer pedido",
            "add_product_desc": "Añade los productos que quieras vender en tu tienda. Puedes buscar en Buscar Proveedor los productos proporcionados por AliExpress o DSers. Haz clic <a href=\"https://help.dsers.com/import-product-from-aliexpress-for-shopify/\" target=\"_blank\" >aquí</a> para más detalles.",
            "push_to_shopify_desc": "Cuando tengas tus productos en DSers, edítalos y envíalos a tu tienda de Shopify. Haz clic <a href=\"https://help.dsers.com/push-a-product-to-your-shopify-store/\" target=\"_blank\" >aquí</a> para más detalles.",
            "suppiler_mapping_desc": "Configura los proveedores de AliExpress de tus productos para procesar fácilmente futuros pedidos. Haz clic <a href=\"https://help.dsers.com/basic-mapping-advanced-mapping-bogo-bundle-introduction-for-shopify/\" target=\"_blank\" >aquí</a> para más detalles.",
            "place_order_desc": "Puedes hacer pedidos en grupos muy rápidamente, y DSers gestionará automáticamente los pedidos por ti después de que este se haya procesado con éxito. Haz clic <a href=\"https://help.dsers.com/fulfill-an-order-from-start-to-finish-for-shopify/\" target=\"_blank\" >aquí</a> para más detalles.",
            "tip_to_pc": "To ensure a better user experience, log in using a computer to operate DSers with the right conditions"
        },
        "oberlo": {
            "card_title_1": "Ya casi está",
            "card_title_des_1": "Por favor, completa los siguientes ajustes antes de procesar los pedidos y utilizar las funciones avanzadas.",
            "card_title_2": "Tutorial",
            "card_title_des_2": "Consulta esta guía rápida para ayudarte a procesar los pedidos y a importar productos.",
            "step_title_1": "Vincula tu cuenta de AliExpress",
            "step_title_2": "Activar la suscripción de DSers",
            "step_title_3": "Instalar la extensión de Chrome de DSers",
            "step_des_1": "Vincula tu cuenta AliExpress enseguida para actualizar los estados de los pedidos. Haz clic <a href=\"https://help.dsers.com/link-to-aliexpress/\" target=\"_blank\">aquí</a> para obtener más detalles.",
            "step_des_2": "Gozar de características similares a las de Oberlo con nuevas posibilidades avanzadas de DSers. Haz clic <a href=\"https://help.dsers.com/oberlo-migration-compare-plans/\" target=\"_blank\">aquí</a> para obtener más detalles.",
            "step_des_3": "¡Importación de productos de AliExpress a DSers con un solo clic para ahorrar tiempo y encontrar nuevos best sellers! Haz clic  <a href=\"https://help.dsers.com/import-product-from-aliexpress-for-shopify/\" target=\"_blank\">aquí</a> para más detalles.",
            "obPlan": "Activar el plan <i>{ dsers }</i> de DSers para disfrutar de tus funciones actuales en el plan <i>{oberlo}</i> de Oberlo.",
            "btntext1": "VINCULAR ALIEXPRESS",
            "btntext2": "Activar plan",
            "btntext3": "Lo haré más tarde",
            "btntext4": "Descargar",
            "btntext5": "Ver más",
            "billing_error": "No puedes activar el plan porque tu tienda de facturación no está disponible. Por favor, cambia la tienda de facturación en la página DSers - <a href=\"/app/select\">Gestión de Suscripción</a> o activa la tienda de facturación en el",
            "billing_error1": "No puedes activar el plan porque tu tienda de facturación no está disponible. Por favor, cambia la tienda de facturación en la página DSers - <a href=\"/app/select\">Gestión de Suscripción</a>",
            "billing_error_link": "administrador de Shopify.",
            "stepsdes": "Quedan pasos",
            "finish": "Terminado",
            "year_error": "Si activas un nuevo plan pero ya has pagado la cuota anual del plan DSers Standard, ponte en contacto con el servicio de atención al cliente para que te devuelvan la parte no utilizada.",
            "step_title_4": "Migrar datos de Oberlo",
            "step_des_4": "Vincula tu cuenta Oberlo y sincroniza los datos con DSers.",
            "stopProduct": "Los datos del producto no se actualizaron correctamente, puedes hacer clic en 'Intentar de nuevo' o contactar con el equipo de <a href='https://www.messenger.com/t/DSersOfficial' target=\"_blank\">soporte técnico de DSers</a>",
            "stopOrder": "El estado del pedido no se ha actualizado correctamente, puedes hacer clic en \"Intentar de nuevo\" o contactar con el equipo de <a href='https://www.messenger.com/t/DSersOfficial' target=\"_blank\">soporte técnico de DSers</a>",
            "successProduct": "La actualización de los datos del producto se ha completado..",
            "successOrder": "La actualización del estado del pedido se ha completado, por favor dirígete a la página de <a href='/app/order/list'>Pedidos Abiertos</a> para más detalles",
            "syncProduct": "Todavía estamos actualizando los datos de los productos desde Shopify...",
            "syncOrder": "Todavía estamos actualizando el estado de los pedidos desde AliExpress. DSers migró los pedidos de los últimos dos meses. Si necesitas sincronizar más pedidos ponte <a href='https://www.messenger.com/t/DSersOfficial' target=\"_blank\">en contacto con nosotros</a>.",
            "migrated": "MIGRATED",
            "linked": "LINKED"
        },
        "testA": {
            "haha": "<a <a target=\"_blank\" href=\"http://www.baidu.com\">ceshia</a>"
        },
        "ShopifyOrders": "pedidos de Shopify",
        newGuide:{
            primary_title:`Manual de Dropshipping`,
            primary_descriptions:`Te mostraremos el contenido más acorde a tu selección.`,
            second_title_1:`No hay productos, ni pedidos`,
            second_description_1:`Soy nuevo y no sé cómo usar DSers para empezar mi negocio de Dropshipping.`,
            second_title_2:`Tengo productos, pero no pedidos`,
            second_description_2:`Ya tengo artículos en Shopify, pero no sé cómo empezar mi negocio en DSers.`,
            second_title_3:`Tengo productos y tengo pedidos`,
            second_description_3:`He utilizado soluciones como DSers, pero necesito saber cómo utilizar las principales características de DSers.`,
            second_more_1_title_1:`What Is Dropshipping?`,
            second_more_1_title_2:`How Can You Start a Dropshipping Store with DSers?`,
            second_more_1_title_3:`¿Cómo importar productos en Buscar Proveedores?`,
            second_more_1_title_4:`¿Cómo importar productos en Lista de Importación?`,
            second_more_1_title_5:`¿Cómo enviar productos a Shopify después de importarlos?`,
            second_more_2_title_1:`Ultimate Guide to 15 Evergreen Products to Dropship in 2023`,
            second_more_2_title_2:`Top 10 Trending Dropshipping Products for 2023`,
            second_more_2_title_3:`¿Cómo importar productos de Shopify a DSers?`,
            second_more_2_title_4:`¿Cómo encontrar el proveedor correcto para el producto?`,
            second_more_3_title_1:`How to Set a Proper and Lucrative Dropshipping Profit Margin?`,
            second_more_3_title_2:`A Step-by-Step Guide to Deal with Your First Dropshipping Order`,
            second_more_3_title_3:`¿Cómo realizar un pedido en DSers?`,
            second_more_3_title_4:`How to set up the shipping template?`,
            Expand:`Expandir`,
            Leave:`COLAPSO`,
            more_videos:'más vídeos'
          }
    }
}