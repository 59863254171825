export default {
  bargain: {
    bargain: 'Bargain',
    counter_limit: 'You can Bargain 10 times every 24 hours.',
    submission_tip:
      'Your personal account manager will reply to you in 1 working day after submission',
    banner_title: 'PRODUCT BARGAIN',
    banner_content:
      'Are you interested in cheaper Wholesale prices? <br/> Submit a request with the Bargain feature! <br/> Your personal account manager will reply within 1 working day.',
    not_store:
      'Before you submit a bargain, please make sure to link to a valid store.',
    link_store: 'Link to store',
    product_name: 'Product name',
    supplier_price: 'Supplier Price',
    email_field: 'Your contact email (We will reply to this email)',
    email_field_p: 'Please fill in your often used email address',
    message_field: 'Leave us a message',
    message_field_p: 'Let us know more details about your business',
    price_field: 'Bargaining Price',
    price_field_p: 'Please fill in a reasonable bargaining price',
    submit: 'Submit a Bargain',
    title: 'Find Suppliers',
    sub_title: 'Hot products',
    intro: `A large selection of products with high selling potential with featured products providing 10 days delivery!
            <a href="mailto:supply-support@dserspro.com">Contact</a> us if you want to learn more.`,
    tag_bargain: 'Bargain',
    tag_time: '24h Processing Time',
    tag_delivery: 'Guaranteed Delivery',
    tag_24: 'Support within 24 hours',
    tag_country: 'Fast Shipping to Specific Countries',
    tag_recommend: 'High Profit Product Recommendations',
    btn_import: 'Import',
    btn_bargain: 'Bargain',
    search_p: 'Enter keywords to search',
    feedback_title: 'You have submitted a bargain !',
    feedback_con:
      'The DSers supply team will get back to you <br/> in one working day. <br/> Thank you for your patience.',
    copied: 'Link copied!',
    link_copy: 'Copy link',
    m_title: 'Product imported !',
    m_reminder: `Don't show again`,
    reminder_help:
      'If you check this box, this window will not appear next time you import a product. If you want to edit a product you imported, please go to the Import List and click Edit on the product card.',
    m_con:
      'The product imported successfully. Please go to import list to check.',
    m_btn_stay: 'Stay',
    m_btn_go: 'Go to import list to check',
    m_fail_title: 'Product failed to import !',
    m_fail_con: 'The product failed to import somehow. Please try again later.',
    m_fail_go: 'Got it',
    cost: 'Cost',
    piece: 'piece',
    rating: 'Rating',
    product_rating: 'Rating',
    evaluation: 'Evaluation',
    sold: 'orders',
    trendy: 'Trendy',
    found_cheaper: 'We found cheaper!',
    cheaper: 'Find Cheaper',
    reward: 'AliExpress Reward',
    alternative: 'Need an alternative?',
    try_this_product: 'Want to try this product?',
    powered_by: 'Powered by',
    mass_supply: 'DSers Mass Supply',
    input_email: 'Please input an email address.',
    fill_max: 'Please fill in maximum {max} characters.',
    coming_soon: 'Coming soon...',
    ten_days_delivery: '10 days delivery',
    delivery_tip:
      '10 Days to the US!\n10 days delivery available soon to Brazil and France!',
    reward_tip: 'Mass Supplier products eligible for cash reward',
    tip_learn_more: 'Learn more',
    delivery_tip_url:
      'https://www.blog.dserspro.com/post/10-days-delivery-to-specific-countries-with-dsers',
    reward_tip_url: 'https://www.blog.dserspro.com/post/dsers-reward-season',
    banner2: {
      anchor: 'anchor',
      thank_you: 'Thank you for selecting DSers Mass Supply program!',
      delete_product_tip:
        'It seems that you have deleted the Shopify product. <br /> We can‘t open mapping page for you.',
      copied_tip:
        'The link of DSers Mass Supply product has been copied, please select a Shopify product to map.',
      go_mapping: 'Go Mapping'
    },
    banner_item: {
      save_percent: 'Save {percent} and stable',
      provided_by: 'Provided by',
      current_sp: 'Your Current Supplier',
      unstable: 'Cost more and unstable'
    },
    found_cheaper_one: 'We found a cheaper one!',
    need_alternative: 'Do you need alternative?',
    alternative_shipping: 'Alternative with reliable shipping!',
    alternative_stock: 'Check alternative product with stable stock',
    alternative_price: 'Check alternative product with stable price',
    // 一键 mapping
    one_click: {
      title: 'Would you like to try our selected supplier?',
      title_1: 'Increase your profit margin',
      title_1_3: 'VIP wholesale price link',
      sub_title:
        '<strong>Switch</strong> to an authorized reliable supplier with wholesale prices with <strong>DSers Mass Supply</strong>.',
      sub_title_3:
        '<strong>Get your own VIP wholesale price link like other bigger dropshippers.</strong>',
      sub_title_d: 'Congratulations, you have reached a high sale volume',
      intro_detail: 'Apply via the Switch button below to enjoy<br/> <strong>wholesale price</strong> and <strong>reliable service</strong> today.',
      intro_detail_d: 'Apply to <strong>DSers Mass Supply program</strong> to enjoy <br/><strong>Reliable service</strong> and <strong>Wholesale prices</strong> today.',
      intro1: 'Wholesale prices',
      intro2: 'Stable inventory',
      intro3: 'Reliable shipping service',
      intro4: 'Dedicated customer service',
      intro5: 'Privacy policy upgrade',
      detail:
        'Click the Switch button to automatically replace the supplier of this product in the Shopify store(s) below',
      check: 'Check product on AliExpress',
      switch: 'SWITCH',
      switch_now: 'SWITCH RIGHT NOW',
      apply_today: 'APPLY today',
      feedback_title: 'Mapping has been replaced automatically',
      feedback_subtitle:
        'Please <a href="mailto:supply-support@dserspro.com">contact</a> us if you have any questions',
      product_cost: 'Product Cost',
      shipping_cost: 'Shipping Fee',
      support_tip:
        'Contact us to get more information and talk to your Account Manager: <br /><a href="mailto:supply-support@dserspro.com">supply-support@dserspro.com</a>',
      support_tip_2:
        'Your own supply chain will be activated, once after apply "<a href="javascript:void(0);">SWITCH</a>". All orders can be processed as normal.<br/>Your personal accout manager will contact you soon from<br/><a href="mailto:supply-support@dserspro.com">supply-support@dserspro.com</a>',
      support_tip_3:
        'You can apply to Switch today, your personal SAM will contact you immediately.<br /> Or feel free to talk to your personal SAM anytime via email <a href="mailto:supply-support@dserspro.com">supply-support@dserspro.com</a>',
      support_tip_4:
        'Apply via the Switch button today, your personal supply account manager (SAM) will contact you immediately. <a href="mailto:supply-support@dserspro.com">supply-support@dserspro.com</a>',
      support_tip_d_1:
        'Apply today or contact us at <a href="mailto:supply-support@dserspro.com">supply-support@dserspro.com</a><br>',
      support_tip_d_2:
        'Learn more from <a href="https://www.dsers.com/blog/dsers-mass-supplier-program/" target="_blank">DSers Mass supply program</a>',
      support_tip_e: 'Your personal Supply Account Manager (SAM) will contact you immediately after you apply. <a href="mailto:supply-support@dserspro.com">supply-support@dserspro.com</a>',
      supply_chain_title: 'Build your own Supply Chain',
      supply_chain_1:
        'Personal supply Account Manager (AM) will be fully assigned for you online',
      supply_chain_2:
        'Reliable Supplier is selected for you, via AliExpress big data analysis (data studio)',
      supply_chain_3: 'New product link is created at Wholesale/Stable price',
      benefits_a_1: 'Save ${number} by switching to a wholesale price verified product',
      benefits_a_2: 'New AliExpress product link created only for you',
      benefits_a_3: 'Personal Supply Account Manager (SAM) to assist you',
      benefits_c_1: 'DSers Mass Supply Program takes responsibilities (e.g. 100% guaranteed returned policy) ',
      benefits_c_2: 'Stable wholesale price, enough inventory and fast shipping (AliExpress Standard shipping) are promised',
      benefits_c_3: 'The VIP link is considered to be created from authorized suppliers based on big data analysis',
      benefits_d_1: '<strong>3S fulfillment center</strong> provides Standard, Stable & Safe professional fulfillment service',
      benefits_d_2: '<strong>The fulfillment service</strong> lets you save <strong>${number}</strong> over large volumes of orders',
      benefits_d_3: '<strong>Long term cooperation</strong> is possible to negotiate with your Personal Account manager',
      benefits_e_1: 'Activate the Selected Supplier by clicking CONFIRM to join the reward event',
      benefits_e_2: 'Use the Selected Supplier and complete over {number1} orders per day (or {number2} orders in 30 days) to get ${number3} Reward',
      benefits_e_3: 'Reward will be given after the orders have been completed on AliExpress with a 2 months delay',
      benefits_e_2_1: 'The Selected Supplier will activate to replace your current supplier',
      benefits_e_2_2: 'Reward will be given with the Selected Supplier and complete over {number1} orders per day (or {number2} orders in 30 days)',
      benefits_e_2_3: 'Reward will be given after the orders have been completed on AliExpress with a 2 months delay',
      support_tip_e_2: 'Your personal Supply Account Manager (SAM) will contact you immediately after you apply.<br/><a href="mailto:supply-support@dserspro.com">supply-support@dserspro.com</a>',
      benefits_f_1: 'The Selected Supplier will activate to replace your current supplier',
      benefits_f_2: 'You will save ${num_1} for a month ({num_2} for each order)',
      support_tip_f: 'Your personal Supply Account Manager (SAM) will contact you immediately after you apply.<br/><a href="mailto:supply-support@dserspro.com">supply-support@dserspro.com</a>',
      supply_chain_example:
        '(e.g. in the above case, you will save $ {number}/month)',
        v5: {
          title_1: 'Wholesale Price',
          subTitle_1: 'Reliable supply service for you',
          subTitle_2_1: 'Your Account Manager is',
          subTitle_2_2: 'pleased to help you with',
          contact: 'CONTACT',
          list_1_title: 'RELIABILITY',
          list_1_text: 'Only selected manufacturers verified by AliExpress officially will be proposed',
          list_2_title: 'WHOLESALE PRICES',
          list_2_text: 'Wholesale and stable prices are promised for dropshipping businesses',
          list_3_title: 'FAST SHIPPING',
          list_3_text: 'AliExpress standard shipping method is highly suggested by the AliExpress team',
          list_4_title: 'POLICY',
          list_4_text: '100% REFUND policy in case  of unsuccessful delivery',
          miss: 'Miss',
          service_text_1: 'Is senior account manager from DSers Mass Supply program.',
          service_text_2: 'She has been involved in supply service over 5 years. ',
          service_text_3: 'And she has the expertise to manage and give you access to multiple manufacturers according to your business needs.',
          service_text_4: 'Talk to her at',
          banner_title: 'Market trends analysis, 1st version',
          check: 'CHECK'
        }
    },
    tag: {
      reward: 'Reward',
      delivery: '10 days delivery'
    },
    filter: {
      country_filter_title: 'Hot suppliers in specific countries',
      category_filter_title: 'Category',
      free_return: 'Free Return',
      processing: '24h processing',
      ship_from: 'Ship from',
      shop_import: 'Import',
      shop_orders: 'Orders'
    },
    sorter: {
      default: 'Default',
      new: 'Newest',
      price: 'Price',
      orders: 'Orders'
    },
    btn_optimizer: 'Supplier Optimizer',
    btn_optimizer_test: 'Find similar',
    btn_optimizer_ab_test: 'More supplier',
    logo_text: 'DSers Premium Selection',
    product: 'Product',
    ae_product_tip: 'AliExpress Quality Control',
    dsers_product_tip: 'DSers Premium Selection',
    search_empty_tip:
      'Sorry, your search "{keyword}" did not match any products. Please try again.',
    product_button_tip: 'Click "Product" to view the list of all products',
    dont_show_again: `Don't show again`,
    bargain_disabled_by_order:
      'The Bargain feature is only available for users with at least 10 orders Fulfilled with a Tracking Number.',
    bargain_disabled_by_bargained:
      'You have already bargained the price of this product today. Please try again tomorrow if you need to negotiate again.',
    img_search_failed_tip:
      'The Search by image feature can not be used at the moment due to an unexpected issue from the AliExpress API. We are working with the AliExpress team to fix this. Sorry for the inconvenience and thank you for your understanding.',
    img_search_success_tip_1:
      'The search by picture can show you to 60 identical products on AliExpress. Click',
    here: 'here',
    img_search_success_tip_2: 'to exit the search by picture results.',
    install_ext_title: 'Install DSers Plugin',
    install_ext_description:
      'One click to get the best selection amongst the millions of AliExpress products and save time to import them',
    install_ext_description_test:
      "Woops, can't find more? Install DSers Chrome extension to find more qualified suppliers today",
    refresh_page: 'Refresh page',
    install_ext_btn: 'Install DSers Plugin',
    select_mask: 'Please select at least one store to push the product',
    shipCost:{
      reminder:'New feature online!',
      text:`You are now able to import products with purchase price for different countries when you import with DSers Chrome Extension!
      Download DSers <br/> Chrome extension to discover this feature`,
      learnmore:"LEARN MORE",
      install:'INSTALL'
    },
    search_no_data_tip:'No results, please try other keywords.',
    filter_no_data_tip:'No results, please change the filter.',
    onlyFor:'For US & Brazil'
  }
};
