export default {
    onBoarding: {
        selectPlatfrom:"Wählen Sie Ihre Plattform",
        selectPlatfromTitle:'Bitte wählen Sie die Plattform des Shops, den Sie verlinken möchten.',
        linkShopify:'Verknüpfen Sie einen Shopify-Shop',
        linkWoo:'Verknüpfen Sie einen WooCommerce-Shop',
        linkWix:'Einen Wix-Shop verlinken',
        linkCsv:'CSV-Dateien von anderen Plattformen verwenden',
        logout:'Abmelden',
        linkAeTitle:'Verknüpfen Sie Ihren Shop und AliExpress'
    }
}