import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'antd';

import styles from './NewUIbanner.less';
import useTopBanner from '../../hooks/useTopBanner';
import ui from '../../assets/banner/ui.png';

const allowPath = [
  '/home',
  '/order/list',
  '/storemanage/import',
  '/storemanage/myproduct'
];

const NewUIBanner = ({ path }) => {
  const [visible] = useTopBanner(path, allowPath);

  if (!visible) {
    return null;
  }
  return (
    <div className={`${styles.container}`}>
      <a
        href="https://www.dsers.com/blog/dsers-new-ui/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={styles.banner}>
          <div className={styles.content}>
            <div className={styles.title}>
              {intl.getHTML('banner.newUI.title')}
            </div>
            <div className={styles.subTitle}>
              {intl.getHTML('banner.newUI.title2')}
            </div>
            <Button type="primary" className={styles.button}>
              {intl.getHTML('banner.newUI.learn')}
            </Button>
          </div>
          <div className={styles.bannerBg}>
            <img src={ui} className={styles.ui} />
          </div>
        </div>
      </a>
    </div>
  );
};

NewUIBanner.allowPath = allowPath;

export default NewUIBanner;
