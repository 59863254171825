export default {
  beforeMigration: {
    shopify: 'Mantener solo las configuraciones del sitio Shopify (o CSV).',
    wooCommerce: 'Mantener solo las configuraciones del sitio WooCommerce.',
    wix: 'Mantener solo las configuraciones del sitio Wix',

    title: '¡El sistema se actualizará por completo pronto!',
    title1: 'Actualización del sistema en proceso',
    one: 'El DSers se actualizará el ',
    two: 'y estará no disponible durante hasta 24 horas',
    there: 'Por favor, organice su horario en consecuencia',

    four:
      'Para brindarle una mejor experiencia de usuario, DSers actualizará el sistema durante un período de',
    five: '24 horas a partir de',
    six:
      'El DSers no estará disponible durante el período. Nos disculpamos por las molestias.',
    seven: 'clic ',
    seven1: 'Haga ',

    //--------
    eight: 'aquí  para saber más.',

    nine: 'aquí para obtener más información.',


    ten:
      'Para brindarle una mejor experiencia de usuario, DSers actualizará el sistema durante un período de',
    eleven:
      'El DSers no estará disponible durante el período. Nos disculpamos por las molestias.',

    twelve:
      'La actualización tiene como objetivo simplificar la gestión multiplataforma. Esto puede causar conflictos de configuración si está gestionando tiendas en múltiples plataformas. Se ha tenido en cuenta plenamente la compatibilidad de las diferencias entre cada plataforma. Aún así, por favor, háganos saber su preferencia en caso necesario.',

    thirteen:
      '¿Cómo te gustaría fusionar las configuraciones potencialmente conflictivas en Configuración después de la actualización?',

    fourteen:
      'Después de la fusión, las configuraciones a nivel de cuenta entre los dos sitios entrarán en conflicto, por lo que debes elegir qué sitio mantener. Las configuraciones que entrarán en conflicto son:',
    fifteen: 'Notificación',
    sixteen: 'Cuenta del personal',
    seventeen: 'Envío',
    eighteen: 'Agregar método de envío global basado en el producto',
    nineteen: 'Dejar un mensaje a los proveedores',
    twenty: 'Optimización del número de teléfono',
    twentyOne: 'Optimización de pedidos cancelados',
    twentyTwo: 'Y otras funciones',

    twentyThere: "Pero no te preocupes, seguiremos manteniendo las configuraciones a nivel de tienda entre los dos sitios para ti, como: ",
    twentyFour: 'Regla de Precios',
    twentyFive: 'Seguimiento de Paquetes',
    twentySix: 'Monedas',

    twentySeven: 'El sistema se está actualizando, lo cual',
    twentyEight: 'tomará aproximadamente 24 horas',
    twentyNine:
      'El DSers estará suspendido durante el período de actualización, y nos disculpamos por cualquier inconveniente.',

    twentyTen:
      'Lo sentimos, el sistema está siendo actualizado en este momento y no se puede vincular, por favor, inténtalo de nuevo más tarde.',
    notification: 'Notificación',

    submit: 'Confirmar'
  }
};
