import React,{ memo } from 'react';
import intl from 'react-intl-universal';
import './SplitBanner.css';

const allowPath = [
  '/order/list'
];

const SplitBanner = memo(() => {
  return <div className="splitBanner">
    <div className="split-text">
      <div className="xh1">{intl.get('setting.order.banner_title')}</div>
      <div className='xh-text'>{intl.get('setting.order.banner_text')}</div>
      <div className="xh2">
        <a href="https://help.dsers.com/split-an-order-automatically-for-shopify" target="_blank">{intl.get('setting.order.banner_btn')}</a>
      </div>
    </div>
    <div className="split-img">
      <img src={require('../../assets/order/split.png')}></img>
    </div>
  </div>
})

SplitBanner.allowPath = allowPath

export default SplitBanner