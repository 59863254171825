export default {
  register: {
    title: 'Créer un compte DSers', //  m
    email: 'Email',
    password: 'Mot de passe',
    get_started: 'CRÉER UN COMPTE',
    // 文案被改动
    input_your_email: `Veuillez saisir l'adresse e-mail dans le format correct.`,
    input_password: 'Please enter 6 to 20 characters.',

    wrong_email_format: 'format email incorrect',
    password_tip:
      'Veuillez utiliser au moins 6 caractères. Ne choisissez pas un mot de passe trop simple.',
    contact_us: 'CONTACTEZ-NOUS',
    hello_shopify: 'Bonjour, Shopify',
    install_dsers_to_shopify: 'Installer DSers sur Shopify',
    password_ok: 'Sécurité: Forte',
    password_warning: 'Sécurité: Moyenne',
    password_error: 'Sécurité: Faible',
    privacy:
      'En cliquant sur Créer un compte, vous acceptez notre <a href= "/privacy" target="_blank">POLITIQUE DE CONFIDENTIALITÉ</a>', // m
    result: {
      resend_successed: 'renvoi réussi',
      resend_failed: 'éched du renvoi',
      title: 'Traiter de multiple commandes en groupe avec DSers',
      your_account: 'Votre compte:',
      register_succ: 'a été enregistré avec succès.',
      activation_email: `Nous vous avons envoyé un email d'activation<br /> à votre adresse.
        Veuillez vérifier et cliquer sur <br />le lien pour activer votre compte dans les 10 prochaines minutes.`,
      check_email: 'Vérifier email',
      resend_email: 'Renvoyer email',
      go_sign: 'Allez vous connecter ou vous enregistrer',
      about_email: `Vous n'avez pas reçu d'email?
        Cliques <a href="https://help.dsers.com/confirmation-email-not-received-for-shopify/" target="_blank">ici</a> pour obtenir plus d'informations!`
    },
    password_error_long: 'Le mot de passe doit comporter entre 8 et 16 caractères',
    password_error_format: 'le mot de passe contient au moins 1 lettre majuscule, 1 lettre minuscule et 1 chiffre',
    password_not_match_twice: 'Les mots de passe ne correspondent pas deux fois',
    haveAccount:`Vous avez un compte ? `,
    signIn:`S'IDENTIFIER`
  }
};
