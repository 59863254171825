export default {
  product: {
    dsers_verified_banner:
      '<h2>DSers <span>Verified</span> Ali Suppliers Project</h2><p>With Payment Solution. No more credit card locked up</p>', // d
    dsers_verified_banner_btn: 'SAIBA MAIS', // d
    search_placeholder: 'Pesquisar meus produtos aqui',
    search_btn: 'OK',
    tab_all: 'Tudo <i>({all_num})</i>',
    tab_aliexpress: 'AliExpress <i>({ae_num})</i>', // m  v1 Unpushed
    tab_tmall: "Tmall <i>({tm_num})</i>",
    tab_other: 'Produtos não mapeados <i>({map_num})</i>', // m  v1 Pushed
    tab_verified: 'Verified', // d
    btn_import_shopify_products: 'Importar Produtos do Shopify',
    btn_import_shopify_products_tip:
      'No momento o DSers apenas sincroniza produtos do Shopify. Você pode importar outros produtos clicando no botão Gerenciar produtos.',
    btn_import_shopify_products_tip_link: 'Veja este artigo',
    select_source: {
      all: 'Tudo',
      unPushed: 'Não enviados ao Shopify', // m
      pushed: 'Enviados ao Shopify' // m
    },
    push_btn: 'Enviar ao Shopify',
    delete_btn: 'Deletar',
    delete_selected_products: 'Remover produtos selecionados',
    hide_selected_products: 'Ocultar produtos selecionados',
    selectAll: 'Selecionar tudo',
    hide: 'Hide',
    del: 'Delete',
    myProducts: 'Meus Produtos',
    product: {
      remove_from_shopify: 'Removido do Shopify',
      tip_deleted_on_Shopify: `Este produto foi deletado no Shopify.
                              Mas pedidos deste produto ainda podem ser processadas no DSers.
                              Você pode deletar este produto clicando no ícone do Lixo se quiser deletá-lo permanentemente.`,
      hidden: 'Oculto', // a
      tip_hidden: `Este produto foi ocultado.
                  Você não poderá ver este produto em seus pedidos e ele não será processado no AliExpress ao fazer pedidos.
                  Você pode clicar no botão "Olho" para exibi-lo.`,
      verified_btn_apply: 'APPLY', // d
      verified_btn_edit: 'EDIT', // d
      verified_btn_enable: 'ENABLE', // d
      sample_on_way: 'Your sample is on the way', // d
      piece_tip: `O custo é o preço mostrado na página do fornecedor AliExpress.
                  Isso pode ser diferente do que você está vendo no AliExpress, pois o DSers mostra o preço básico, independentemente do país para o qual você está enviando.`,
      piece: 'peça',
      processing: '24h processing',
      cost: 'Custo',
      price: 'Preço',
      orders: 'Pedidos',
      stock: 'Estoque',
      delete: 'Deletar',
      check_details: 'Ver detalhes',
      edit_details: 'Editar produto',
      open_in_shopify: 'Abrir no Shopify',
      publish_firsh: 'Publique primeiro, depois abra no Shopify',
      split: 'Split product', // a
      hidden_tip:
        'Clique para remover da lista negra. Você verá o produto na página de pedidos.', //  add
      hidden_open_tip:
        'Clique para Ocultar este produto e não processá-lo com seus pedidos.', //  add
      mapping_Page: 'Página de mapeamento',
      mapping_tip: 'Publish first then add suppliers', //  d
      push_to_shopify: 'Enviar ao Shopify',
      import_to_store: 'Import to store', // d
      no_out_stock: 'Variante sem estoque',
      multiple: 'Multiplicar'
    },
    manage_product: {
      title: 'Select product and set up shipping method',
      title1: "Import Shopify Products",
      nombre:`If you have a large number of items to import, you can <a href="https://www.messenger.com/t/DSersOfficial" dd="src" target="_blank">contact customer service</a> and we will help you.`,
      search_placeholder: 'Encontrar produtos...',
      no_data: 'Sem dados',
      syncing: 'Sincronizando',
      import_btn: 'Importar',
      select_max_tip: 'Você só pode importar no máximo 10 produtos por vez',
      import_max_tip: 'Importação em massa máxima de 10 produtos por vez.',
      import_shopify_Copywriting:
        'Oops, it seems like the loading took too long, please try again',
      bulk_import:
        'Se você tem um grande número de itens para importar, entre em <a href="https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2FDSersOfficial" target="_blank" rel="noopener noreferrer">contato com o atendimento</a>  ao cliente e nós lhe ajudaremos'
    },
    visible_succ: 'Operação bem sucedida',
    already_imported:
      'Você já importou este produto e não pode importá-lo novamente',
    copy_first_pushed:
      'Opening failed, the product needs to be Push/Published to Shopify first',
    succ_copy: 'Copiado com sucesso',
    copy_failed: 'Cópia falhou',
    update_succ: 'Atualizado com sucesso',
    delete_tip: 'Tem certeza que deseja excluir este produto?',
    delete_pushed_tip:
      '<span>Produtos excluidos <strong>NÃO PODEM SER RECUPERADOS </strong> e serãp <strong>EXCLUÍDOS DA SUA LOJA SHOPIFY</strong> Você quer continuar?</span>',
    delete_confirm:
      'Tem certeza de que deseja executar esta ação? É DEFINITIVO e seus produtos serão EXCLUÍDOS também na sua loja Shopify!',
    input_confirm_tip: 'Digite CONFIRMAR para deletar.',
    open_mapping_tip: 'Publique antes de adicionar fornecedores',
    no_data: {
      add_shopify_store: {
        title: 'Adicione uma loja Shopify',
        description: `Conecte lojas do Shopify clicando em Conectar ao Shopify para
                      gerenciar seus produtos.<br />Mapeie um produto do Aliexpress antes de realizar múltiplos pedidos`
      },
      is_sync: 'Sincronizando informações do Shopify, por favor aguarde.',
      no_data: 'Comece seu negócio com DSers, clique em',
      no_data_end: '¡consulta cómo importar productos de AliExpress a Dsers!',
      has_store: {
        title: `O DSers só irá mostrar e sincronizar automaticamente os produtos e pedidos feitos pelo Shopify.<br />
                Mas não se preocupe, você ainda pode importar os produtos que deseja mostrar no DSers da sua lista de produtos do Shopify clicando em <br />`,
        btn: 'Importar produtos do Shopify'
      },
      no_product_tip_1:`1. Clique em “Importar produto da Shopify” no canto superior direito para importar produtos da Shopify até o DSers`,
      no_product_tip_2:`
      2. Mapeie os produtos importados para processar os pedidos mais rapidamente`
    },
    visible_progress_title1: 'Produtos visíveis na sua loja do Shopify',
    visible_progress_title2: 'Realizando pedido no AliExpress...',
    show_supply_chain: {
      // d
      btn: 'LEARN MORE',
      description: `No More Credit Card Locked Up <br/>
                    Delivery 100% Guaranteed <br/>
                    Ship Out Within 3 Days <br/>
                    Real Time Customer Service <br/>`,
      title: 'DSers <i>Verified</i> Ali Suppliers Project'
    }, // d
    show_balance_no: {
      //  d
      btn: 'MAKE PAYMET',
      tip: 'Available DSers Balance',
      title: 'There is not available balance <br/> in your account'
    }, //  d
    show_address_no: {
      //  d
      btn: 'Go to Setting',
      tip:
        'Please set your address information and ensure that the balance is greater than 0.'
    }, //  d
    show_set_visible: {
      tip: 'Também publique na Loja Online',
      continue_selling: 'Continue selling when out of stock',
      description:
        'Produto(s) serão enviados para a loja do Shopify e não poderão mais ser editados no DSers. Mas você ainda poderá gerenciar os fornecedores',
      cancel: 'Cancelar',
      yes: 'Sim'
    },
    hide_prod_tip:
      'Tem certeza que deseja ocultar o produto? Pedidos com este produto não serão mostrados no DSers e você não poderá mais sincronizar os itens escondidos no DSers.',
    confirm_prod_tip: 'Por favor digite CONFIRMAR para esconder o produto.',
    possible_cause: 'Causa possível:',
    search_notice_for_migrate: 'Please do not input url here. If you want to import products, please go to Import list',
    search_msg_for_migrate: 'Please go to the Import list to import products. Click <a href="https://help.dsers.com/import-list-for-shopify/" target="_blank"><strong>here</strong></a> to get more information.',
    filter_push_title: 'Filter by push',
    to_push_shopify: 'Already pushed to Shopify',
    no_push_shopify: 'Not pushed to Shopify',
    introduce_con1: 'You are about to delete the selected product(s) from DSers. But the products will be kept on Shopify.',
    introduce_con2: 'You are about to delete the selected product(s) from DSers. And the products will also be deleted from Shopify store.',
    introduce_checkbox: 'Delete this product from my Shopify store too.',
    introduce_ok: 'CONFIRMAR',
    introduce_cancle: 'Cancel',
    cost_ible: 'Cost $ ——',
    loading: 'Replacing',
    error: 'Failed to replace',
    success: 'Successfully replaced',
    collections:`Search the full collection's title.(Case insensitive)`,
    product_type:'Select or create a Shopify type',
    tags:'Select or create a Shopify tag',
    vendor:'Select or create vendor',
    filterByMapping:'Filter by Mapping',
    basic_mapping:'Basic',
    standard_mapping:'Standard',
    advance_mapping:'Advanced',
    bogo_mapping:'BOGO',
    bundle_mapping:'Bundle',
    productOutOfStock:'Product out of stock',
    productNotFound:'Product not found',
    filterByNotification:'Filtrar por notificação',
    filterByHidden:'Filtrar por status',
    filterByVariants:'Variants out of stock',
    filterBySku:'SKU alterada no AliExpress',
    filterByGone:'Produtos removidos do fornecedor',
    filterByHid:'Produtos ocultos',
    filterByShopifyDelete:'Removido na Shopify',
    is_main_product:'Original unsplit product',
    updatePrice:`While checking the data, we found that the price of the product you migrated from Oberlo was originally a non-US price, but due to an internal error, the price was migrated as a US price. We are very sorry for this.
    In order to correct this error in time and not cause trouble and loss to your subsequent operations, we now provide a dedicated feature to modify the products in your account to the price of the destination country you want. (Only items with U.S. prices can be modified, and each item can only be modified once. If you do not need this service, you can ignore this message. This feature will only be available for one month)`,
    update:"Update price",
    chooseCountry:'Choose a country',
    chooseSub:'Select the country for which you want to update prices',
    update_desc_wait:'products are being updated',
    update_desc_ok:'updates successful',
    update_desc_fail:'updates failure',
    error_one:'Your product is being updated, as your account is being accessed by someone else and the update operation has been performed',
    error_two_title:'None of the products you selected can be updated. ',
    error_two_sub:'1. The price of the product is not set for the United States. 2. Your product is being updated, as your account is being accessed by someone else and the update operation has been performed 3. The product has been updated already.',
    selectCountry:'Select country',
    price_for:'Preço por',
    collection_title:'Coleções',
    type_title:'Tipo',
    tags_title:'Tags',
    vendor_title:'Fornecedor',
    productLimitTitle:'Limite de produtos Shopify',
    productLimitSub:'O número de produtos que podem ser importados em “Meus produtos.',
    aeLimitTitle:'Limite de produtos do fornecedor',
    aeLimitSub:'Soma dos produtos importados na “Lista de importação” e o número de produtos no mapeamento “Meus produtos”.',
    productCostToShopify:'Enviar custo do produto à Shopify',
    productCostAndShippingCostToShopify:'Envie o custo do produto e de envio à Shopify',
    productCostAe:'Envia o custo por item do produto do fornecedor à loja Shopify quando o produto é publicado.',
    remove_all_imgs:'remove all images'
  }
};
