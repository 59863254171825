export default {
  product_edit: {
    repeated_tip: 'Les couleurs/ou tailles sont répétées, veuillez les changer',
    push_description: 'Cliquez <a style="color:#3D5AFE" target="_blank" href="/app/settings?id=4">ici</a> pour définir le contenu de la description lorsque vous envoyez le produit.',
    picture: 'Image',
    specifications: 'SPÉCIFICATIONS',
    overview: 'APERÇU',
    compare_price: 'Prix Comparé',
    delete_variant_tip: 'Êtes-vous sûr de vouloir supprimer cette variante?',
    delete_selected_variant_tip:
      'Êtes-vous sûr de vouloir supprimer les variantes sélectionnées?',
    new_compare_price: 'Nouveau prix comparé',
    new_price: 'Nouveau prix',
    new_stock: 'Nouveau stock',
    open_on_supplier: 'CLIQUE PARA SABER MAIS',
    from_other: 'autre',
    seller_id: 'ID Fournisseur',
    input_title: 'Veuillez entrer un titre',
    product_type: 'Type de produit',
    collection: 'Collection',
    vendor: 'Vendeur',
    tags: 'Tags',
    no_edit:
      'Les produits publiés sur Shopify ne peuvent pas être édité, veuillez les éditer sur Shopify directement.',
    select_variant: 'Choisir variante',
    none: 'Aucun(e)',
    description: 'Description',
    images: 'Images <i>({selectImages}/{allImages})</i>',
    shipping: 'Info Livraison',
    shipping_info: `Vous pouvez vérifier les informations du coût de livraison, la durée estimé de livraison et la disponibilité du suivi d'un produit {platform} en sélectionnant un pas de destination (et d'envoi). Les informations d'envoi ne seront PAS envoyé vers Shopify lors de la publication. Cela sert uniquement à vous donner des estimations.`,
    cannot_recover:
      'Vous ne pourrez pas annuler la suppression  de variante d\'un produit, voulez-vous continuer?',
    download_pic: 'Cliquez ici pour télécharger toutes les images',
    delete_pic_tip:
      "Do you want to delete the image(s)? The deleted image(s) will not be uploaded to the Media section in the Shopify product.",
    delete_picss_tip:'Do you want to delete all the images? No images will be uploaded to the Media section in the Shopify product.',
    back: 'Retour',
    product: 'Produit',
    from_aliexpress: 'Fournisseur AliExpress',
    from_tmall:'De Tmall',
    title: 'Titre',
    variants: 'Variantes <i>({allvariants})</i>',
    all: 'Tous',
    action: 'Action',
    sku: 'Sku',
    cost: 'Coût',
    price: 'Prix',
    shopify_stock: 'Stock to Shopify',
    stock: 'Stock on fournisseur',
    replace_stock:'Stock on <br /> {platform}',
    stock_on_shopify: 'Stock on <br /> Shopify',
    change_price: 'Changer Prix',
    change_compare_at_price: 'Changer Prix Comparé',
    remove_sku: 'Supprimer SKU(s)',
    change_stock: 'Changer Stock',
    option_repeated_tip: 'Les couleurs/tailles du produits se répètent, veuillez modifier',
    sku_repeated_tip: 'Le SKU doit être différent pour chaque variante. Veuillez modifier.',
    option_name_invalid: "Le nom de l'option ne peut pas être vide",
    option_value_invalid: "La valeur de l'option ne peut pas être vide",
    option_name_repeat: "Le nom de l'option ne peut pas être répété",
    option_name_slash: `Le nom de l'option ne peut pas contenir " / "`,
    option_sku_invalid: "La valeur du SKU ne peut pas être vide",
    sku_count_invalid: "Veuillez conserver au moins un SKU",
    overrun_warning: `Vous avez dépassé la limite journalière d'accès aux données du produit`,
    change_image: 'CHANGER IMAGER',
    organization: 'Organisation',
    download_all_img: 'Télécharger',
    delete_img:'Supprimer',
    img_des:'Les images sélectionnées ici apparaitront dans le Media des produits Shopify',
    not_select_main_img_des:'If you don’t select the cover picture here, the first picture you check here will be used as the cover picture for this Shopify product.',
    deleteImage: 'Supprimer Image',
    products:'Produit',
    shipping_cost:`Frais <br /> d'expédition`,
    uncheckedAlert:'Si vous ne sélectionnez aucune image ici, aucune image ne sera chargée vers Media pour le produit Shopify.',
    try_again_collections_text:`Nous n'avons pas pu obtenir les données de vos collections car le délai de la demande a expiré, veuillez cliquer `,
    try_again_collections_fun:'sur Actualiser',
    checkImages:'Images <i>({allImages})</i>',
    select_province_pleaseholder:'Veuillez sélectionner la province/ville',
    ship_from_text:'Expédier depuis:',
    ship_to_text:'Expédier à:',
    product_edit_price_tip:'Le symbole de la devise varie en fonction des paramètres de votre boutique Shopify après la publication sur Shopify. Veuillez définir manuellement le prix/la valeur du prix de comparaison ici si vous en avez besoin.',
    shopify_stock_tip:'Vous pouvez configurer la quantité de stock que vous souhaitez transférer vers Shopify ici. Il ne sera pas mis à jour lorsque le stock du fournisseur change sur {platform}.',
    aliexpress_stock_tip:`DSers met automatiquement à jour le stock d'AliExpress pour les produits de la liste d'importation toutes les deux heures. Le stock ici ne peut pas être modifié manuellement`,
    tmall_stock_tip: "DSers mettra automatiquement à jour le stock de Tmall pour les produits sur la liste des imports. Le stock ici ne peut pas être modifié manuellement.",
    product_stock_tip:'DSers actualise le stock fournisseur des produits sur Mon produit. Le stock ne peut pas être modifié manuellement. Nous ne mettrons pas à jour le stock de produits du mapping Advanced/BOGO/Bundle.',
    shopify_product_stock_tip:'Le stock sera synchronisé via Shopify et ne sera pas mis à jour en fonction du stock fournisseur, sauf si vous avez activé la fonction MAJ automatique dans le pack payant.',
    download_img_fail_tip:`Le téléchargement de l'image a échoué, veuillez réessayer plus tard`,
  }
};
