
    export default 
    {
    "about": {
        "title": "Benvenuto",
        "description": "A DSers, crediamo che ognuno meriti di effettuare un ordine in modo veloce ed efficiente.Efficienza e Innovazione sono i nostri obiettivi principali e ci sforziamo di rimuovere ogni ostacolo tecnico o difficoltà che possa impedire ai distributori di vendere beni o portare a termine gli ordini.Saremo felici di aiutarti nel tuo viaggio!",
        "our_story": "La nostra Storia",
        "our_story_1": "DSers è nato nel 2018. Dopo aver provato diversi software di gestione degli ordini, abbiamo realizzato che la velocità di lavorazione delle esistenti soluzioni di gestione degli ordini non era sufficiente per alleviare il lavoro ripetitivo dei distributori.I tradizionali servizi di lavorazioni degli ordini sono troppo complessi, fanno sprecare troppo tempo, e sono costosi da gestire.",
        "our_story_2": "Abbiamo creato il sito DSers.com e preso in considerazione il punto di vista dell'utente. Vogliamo fornire una piattaforma che non ti richieda di passare ore a processare ordini. La manteniamo semplice così che gli utenti possano concentrarsi sul migliorare il loro marchio sotto l'aspetto del marketing e del branding. Ma la cosa più importante è la seguente: è gratis. Puoi configurare la selezione del costo della logistica per ridurre le spese, gestire la tua merce, o iniziare l'elaborazione sequenziale del tuo ordine. ",
        "our_story_3": "Dopo aver notato un aumento della domanda per soluzioni di e-commerce, abbiamo sviluppato l'unica piattaforma online di gestione completamente funzionale, priva di commissioni, che permetta ai Distributori di gestire i propri ordini e articoli in vendita.",
        "our_story_4": "Oggi, siamo orgogliosi di supportare persone indipendenti e team da tutto il mondo. Ogni Distributore dovrebbe avere accesso a una piattaforma che semplifichi le cose e permetta di avere le mani libere per processare gli ordini, e noi siamo felici di vedere tutti voi poter passare sempre più tempo ad aumentare il vostro giro di affari.",
        "our_values": "I nostri Valori",
        "our_values_1": "Tutti dovrebbero avere un software di gestione degli ordini che sia davvero in grado di far risparmiare tempo e soldi. Noi crediamo che tutti abbiano diritto a un flusso di lavoro tranquillo e armonioso. Se puoi puntare e fare click, puoi usare il nostro software di gestione di elaborazione sequenziale gratuito e intuitivo.",
        "our_values_2": "I nostri clienti significano tutto",
        "our_values_3": "A Dsers, ci impegnamo per fornire i prodotti di cui i nostri clienti hanno bisogno. Gran parte del nostro processo di brainstorming consiste nel rivedere il feedback dei nostri clienti per assicurarci che siano gestiti nella maniera corretta e per essere certi di sviluppare le nuove funzionalità di cui avete bisogno.",
        "our_values_4": "Manifattura interna",
        "our_values_5": "Ci impegnamo personalmente per fornire il migliore dei servizi. Dal servizio clienti al design e allo sviluppo del prodotto, tutto viene fornito dal nostro team di (adorabili) professionisti."
    }
}