import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Spin, Modal, Tooltip, Icon } from 'antd';
import intl from 'react-intl-universal';

import qs from 'qs';


import { Post, Get } from 'utils/request';
import { setAuthority } from '../../utils/authority';
import moment from 'moment-timezone'
import styles from './callback.less'

const langMap = {
    'en': 'en-US',
    'fr': 'fr-FR',
    'pt-BR': 'pt-PT',
    'de': 'de-DE',
    'es': 'es-ES',
    'it': 'it-IT'
}

export default function ObeloRedirect() {

    const [showLoading, setShowLoading] = useState(true)
    const [requestId, setRequestId] = useState('')

    const dispatch = useDispatch()

    useEffect(() => {
        const parms = qs.parse(location.search.slice(1));
        window.localStorage.removeItem('authority_token');
        localStorage.removeItem('migration_stores');
        if (parms) {
            //拿token请求
            GetToken(parms.token)
        }
    }, [])


    const GetToken = async (token) => {


        const res = await Post("/mgnoberlo-core-api/dsers-api/migration/request", {
            data: {
                oberlo_token: token
            }
        }, true)
        if (res.oberlo_local && res.oberlo_local in langMap) {
            localStorage.setItem('lang', langMap[res.oberlo_local])
        }
  
        if (res.dsers_user_id) {
            window.localStorage.removeItem(`${res.dsers_user_id}-reducePlan`);
            window.localStorage.removeItem(`${res.dsers_user_id}_OBERLO_READ_ORIDER_TIP`);
            
        } else {
            for (var i = 0; i < localStorage.length; i++) {
                var key = localStorage.key(i);
                if (key.endsWith('reducePlan')) {
                   localStorage.removeItem(key)
                }
                if (key.endsWith('OBERLO_READ_ORIDER_TIP')) {
                    localStorage.removeItem(key)
                 }
            }
        }
        if (res.dsers_token) {
            window.localStorage.removeItem('ifMigration')
            setAuthority(res.dsers_token)
            window.location.href = '/app/';
        } else {
            if (res.code == 500) {
                Modal.confirm({
                    title: <div>{intl.get('migration.timeout_title')} <i style={{ float: "right" }} onClick={() => { window.location.href = '/' }} class="material-icons notranslate">close</i> </div>,
                    content: <div>{intl.get('migration.timeout_text')} <Tooltip placement='top' title={`${intl.get('migration.requestid')}${res?.metadata?.trace_id}`}><Icon style={{ fontSize: "15px", marginLeft: '5px' }} type="question-circle" /></Tooltip></div>,
                    onOk() {
                        window.location.href = 'https://www.messenger.com/t/DSersOfficial'
                    },
                    okText: intl.get('migration.timeout_contact'),
                    onCancel() {
                        window.location.href = '/'
                    },
                    className: styles.modalStyle,
                    icon: <div></div>
                });
            } else if (res.code == 403 && res.reason == 'STORE_IN_MIGRATING') {
                Modal.confirm({
                    title: <div>{intl.get('migration.waitmigration_title')} </div>,
                    content: <div>{intl.get('migration.waitmigration_text')} <Tooltip placement='top' title={`${intl.get('migration.requestid')}${res?.metadata?.trace_id}`}><Icon style={{ fontSize: "15px", marginLeft: '5px' }} type="question-circle" /></Tooltip></div>,
                    okText: 'Ok',
                    onOk: () => {
                        window.location.href = '/'
                    },
                    className: styles.modalStyle_2,
                    icon: <div></div>
                });
            } else if (res.code == 403 && res.reason == 'STORE_IN_QUEUE') {
                const response = await Get(`/mgnoberlo-core-api/dsers-api/migration/durations/status?shopify_domain=${res.message}`, {}, true)
                Modal.confirm({
                    title: <div>Migration time setting tip  </div>,
                    content: <div>{`You have reserved the time slot of [${moment(Number(response?.durations?.start) * 1000).tz(response?.durations?.booking_timezone)?.format('DD MMM HH')}:00~${moment(Number(response?.durations?.end) * 1000).add(1, 'H').tz(response?.durations?.booking_timezone)?.format('HH')}:00] for migration, please wait patiently until then`} <Tooltip placement='top' title={`${intl.get('migration.requestid')}${res?.metadata?.trace_id}`}><Icon style={{ fontSize: "15px", marginLeft: '5px' }} type="question-circle" /></Tooltip></div>,
                    okText: 'Ok',
                    onOk: () => {
                        window.location.href = '/'
                    },
                    className: styles.modalStyle_2,
                    icon: <div></div>
                });
            }
            else {
                const shopname = res.shopify_domain?.split('.myshopify.com')[0]
                const response = await Post("/mgnoberlo-core-api/dsers-api/migration/auth/shopify/bind", {
                    data: {
                        shop_name: shopname
                    }
                }, true)

                localStorage.setItem('ifMigration', true)
                window.location.href = response.redirect_url;
            }


        }

    }

    return (
        <>
            {showLoading ? (
                <div
                    style={{
                        marginTop: 120,
                        textAlign: 'center'
                    }}
                >
                    <Spin size="large" />
                </div>
            ) : null}
        </>
    )
}
