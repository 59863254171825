/* eslint @typescript-eslint/camelcase: 0 */

import React, { useCallback, useEffect, useReducer, useMemo, useRef, useState } from 'react';
import { Button, Modal, Checkbox, Spin, Empty, Icon, Tooltip } from 'antd';
import intl from 'react-intl-universal';
import { connect } from 'dva';

import styles from './index.less';
import SuccessFeedback from 'components/SuccessFeedback';
import { Get, Post } from 'utils/request';
import dollorImg from '../../../../assets/bargain/dollor_2.svg';
import arrow from '../../../../assets/bargain/arrow_2.png';
import blueArrow from '../../../../assets/bargain/blueArrow.png';
import bgImg from '../../../../assets/bargain/bgImg.png';
import vipImg from '../../../../assets/bargain/vip.jpg';

import highLight from '../../../../assets/bargain/highLight.png';
import highLight2 from '../../../../assets/bargain/highLight2.png';
import blue from '../../../../assets/bargain/blue.png';
import purple from '../../../../assets/bargain/purple.png';
import check1 from '../../../../assets/bargain/check_1.svg';
import check2 from '../../../../assets/bargain/check_2.svg';
import {
  list_V1_1,
  list_V1_2,
  list_V1_3,
  list_V2_1,
  list_V2_2,
  list_V2_3,
  list_V2_4,
  list_V2_5,
  list5Map,
  list_V3,
  v3Map,
  list_V4,
  list_V5,
  getVersion,
  v4Map
} from '../../../../utils/bannerWhiteList';
import SwitchV5 from './SwitchV5';
import SwitchV4 from './SwitchV4';

const initialState = {
  loading: false,
  prodData: [],
  submitted: false,
  switchLoading: false,
  checkedList: []
};

let isV3;
let isV4;
let isV5;

function reducer(state, action) {
  switch (action.type) {
    case 'loading':
      return {
        ...state,
        loading: action.payload
      };
    case 'setProdData':
      return {
        ...state,
        prodData: action.payload
      };
    case 'setSubmitted':
      return {
        ...state,
        submitted: action.payload
      };
    case 'setCheckedList':
      return {
        ...state,
        checkedList: action.payload
      };
    case 'setSwitchLoading':
      return {
        ...state,
        switchLoading: action.payload
      };
    case 'reset':
      return { ...initialState };
    default:
      throw new Error();
  }
}

function SaveMoneyArrow({ percent, compareItem, dsersItem = {} }) {
  // const formatedPercent = parseFloat(percent);
  const totalPrice =
    parseFloat(compareItem.price) + parseFloat(compareItem.shippingPrice);
  const dsersTotalPrice =
    parseFloat(dsersItem.price) + parseFloat(dsersItem.shippingPrice);
  const saveMoney = parseInt((totalPrice - dsersTotalPrice) * 600);
  return (
    <>
      <img className={styles.arrow} width="157" height="90" src={arrow} />
      <span className={styles.context}>SAVE {percent}</span>
      <div className={styles.saveMoneyArrow}>
        SAVE &nbsp;
        {/* <img width="26" height="26" src={dollorImg} /> */}
        <span className={styles.saveUnit}>{'$'}{saveMoney}</span>&nbsp;/month{' '}
      </div>
    </>
  );
}

function Arrow({ percent, compareItem, dsersItem = {}, userId }) {
  const totalPrice =
    parseFloat(compareItem.price) + parseFloat(compareItem.shippingPrice);
  const dsersTotalPrice =
    parseFloat(dsersItem.price) + parseFloat(dsersItem.shippingPrice);
  const saveMoney = parseInt((totalPrice - dsersTotalPrice) * 600);
  const isV2_3 = list_V2_3.includes(userId);
  const info = v4Map[userId];
  let userPrecent = 0;
  if (info) {
    userPrecent = info[0];
  }

  return (
    <>
      <img
        className={styles.blueArrow}
        width="157"
        height="90"
        src={blueArrow}
      />
      {!isV2_3 && (
        <>
          {/* <div className={styles.percent}>SAVE {percent}</div> */}
          {!isV4 ? (
            <>
              <div className={styles.percent}>SAVE {percent}</div>
              <span className={styles.number}>{`($${saveMoney} /month)`}</span>
            </>
          ) : (
            <>
              <div className={styles.percent}>SAVE {userPrecent} %</div>
              <span className={styles.number}>(Limited offer)</span>
            </>
          )}
        </>
      )}
    </>
  );
}

function HighLightComponent({ percent, compareItem, dsersItem = {} }) {
  const totalPrice =
    parseFloat(compareItem.price) + parseFloat(compareItem.shippingPrice);
  const dsersTotalPrice =
    parseFloat(dsersItem.price) + parseFloat(dsersItem.shippingPrice);
  const saveMoney = parseInt((totalPrice - dsersTotalPrice) * 600);
  return (
    <>
      <img
        className={styles.blueArrow}
        width="157"
        height="90"
        src={blueArrow}
      />
      <img className={styles.highLight2} src={highLight2} />
      <span className={styles.context}>SAVE {percent}</span>
      <span className={styles.saveUnit}>{`( $${saveMoney} /month )`}</span>
    </>
  );
}

function SaveNumber({ compareItem, dsersItem = {} }) {
  const totalPrice =
    parseFloat(compareItem.price) + parseFloat(compareItem.shippingPrice);
  const dsersTotalPrice =
    parseFloat(dsersItem.price) + parseFloat(dsersItem.shippingPrice);
  const saveMoney = parseInt((totalPrice - dsersTotalPrice) * 600);
  return (
    <>
      <span className={styles.saveUnit}>{`$ ${saveMoney}`}</span>
    </>
  );
}

function CompareProduct({
  img = '',
  title,
  supplier,
  price,
  shippingPrice,
  url,
  country,
  shipMethod,
  userId
}) {
  const isV2_3 = list_V2_3.includes(userId);
  const isDsers = supplier === 'dsers';
  const totalPrice = (parseFloat(price) + parseFloat(shippingPrice)).toFixed(2);
  const overlay = (
    <div>
      <div>Ship To: {country}</div>
      <div>Shipping Method: {shipMethod}</div>
    </div>
  );
  const imgArr = img.split(';');

  return (
    <div
      className={`${
        isDsers ? styles.dsersCompareProduct : styles.compareProduct
      }`}
    >
      {/* <div className={styles.compareProduct}> */}
      <div
        className={`${styles.compareProductTag} ${isDsers ? styles.dsers : ''}`}
      >
        {supplier !== 'dsers'
          ? intl.get('bargain.banner_item.current_sp')
          : intl.get('bargain.mass_supply')}
      </div>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          if (supplier !== 'dsers') {
            window.dataLayer.push({
              event: 'compareProductCurrentEvent',
              type: 'clickIssue'
            });
          } else {
            window.dataLayer.push({
              event: 'compareProductMassEvent',
              type: 'clickIssue'
            });
          }
        }}
      >
        <img src={isDsers && isV2_3 ? vipImg : imgArr[0]} />
      </a>
      <div className={styles.infoCon}>
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#000' }}
        >
          <div className={styles.compareProductTitle}>{title}</div>
        </a>
        <div className={styles.totalPrice}>US ${totalPrice}</div>
        <div className={styles.cost}>
          <span className={styles.costName}>{intl.get('bargain.one_click.product_cost')}</span>
          <span className={styles.costNumber}>${price}</span>
        </div>
        <div className={styles.cost}>
          <span className={styles.costName}>{intl.get('bargain.one_click.shipping_cost')}</span>
          <span className={styles.costNumber}>${shippingPrice}</span>
        </div>
      </div>
    </div>
  );
}

function TwoCompareProduct({
  img = '',
  title,
  supplier,
  price,
  shippingPrice,
  url,
  country,
  shipMethod,
  userId
}) {
  const isCurrent = supplier === 'current';
  const isDsers = supplier === 'dsers';
  const isDsers2 = supplier === 'dsers2';
  const totalPrice = (parseFloat(price) + parseFloat(shippingPrice)).toFixed(2);
  const overlay = (
    <div>
      <div>Ship To: {country}</div>
      <div>Shipping Method: {shipMethod}</div>
    </div>
  );
  return (
    <div
      className={`${isDsers ? styles.dsersCompareProduct : ''} ${isDsers2 ? styles.dsersCompareProduct2 : ''} ${isCurrent ? styles.compareProduct : ''}`}
    >
      {supplier == 'current' && (
        <div className={`${styles.compareProductTag}`}>
          {intl.get('bargain.banner_item.current_sp')}
        </div>
      )}
      {supplier == 'dsers' && (
        <div>
          <img src={highLight} className={styles.blueBg} />
        </div>
      )}
      {supplier == 'dsers2' && (
        <div>
          <img src={highLight} className={styles.purpleBg} />
        </div>
      )}
      <a
        href={ supplier == 'dsers2'? null :url}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          if (supplier !== 'dsers') {
            window.dataLayer.push({
              event: 'compareProductCurrentEvent',
              type: 'clickIssue'
            });
          } else {
            window.dataLayer.push({
              event: 'compareProductMassEvent',
              type: 'clickIssue'
            });
          }
        }}
      >
        <img src={img} />
      </a>
      <div className={styles.infoCon}>
        <a
          href={ supplier == 'dsers2'? null : url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#000' }}
        >
          <div className={styles.compareProductTitle}>{title}</div>
        </a>
        <div className={styles.totalPrice}>US ${totalPrice}</div>
        <div className={styles.cost}>
          <div className={styles.costName}>{intl.get('bargain.one_click.product_cost')}</div>
          <div className={styles.costNumber}>${price}</div>
        </div>
        <div className={styles.cost}>
          <span className={styles.costName}>{intl.get('bargain.one_click.shipping_cost')}</span>
          <span className={styles.costNumber}>${shippingPrice}</span>
        </div>
      </div>
    </div>
  );
}

function OneClickMapping({
  oneClickMapping,
  dispatch,
  logisticsServices,
  countries,
  userInfo
}) {
  console.log('--------- OneClickMapping ----------', oneClickMapping);
  const [state, selfDispatch] = useReducer(reducer, initialState);
  // console.log(state)
  const [show, setShow] = useState(false);
  const [showApply, setShowApply] = useState(false);
  const num1 = useRef(0);
  const num2 = useRef(0);

  useEffect(() => {
    async function fetchData() {
      selfDispatch({ type: 'loading', payload: true });

      try {
        const res = await Get(
          `/prod/propool/spread/shopify?ae_product_id=${oneClickMapping.extraData.ae_product_id}`
        );

        if (res && res.code === 2000 && res.data) {
          selfDispatch({ type: 'setProdData', payload: res.data });

          selfDispatch({
            type: 'setCheckedList',
            payload: res.data.map(i => i.shopify_product_id)
          });
        }
      } catch (error) {
        console.log('fetch error:', error);
      }

      selfDispatch({ type: 'loading', payload: false });
    }

    if (oneClickMapping.extraData && oneClickMapping.extraData.ae_product_id) {
      fetchData();
    }
  }, [oneClickMapping.extraData]);

  const compareProducts = useMemo(() => {
    if (!oneClickMapping.extraData) {
      return [];
    }
    const country = countries?.find(
      item => item.Code === oneClickMapping.extraData.country
    )?.Name;
    const shipMethod = logisticsServices?.find(
      item => item.ServiceName === oneClickMapping.extraData.dsers_ship_method
    )?.Name;

    if (isV3) {
      return [
        {
          key: oneClickMapping.extraData.dsers_product_id,
          supplier: 'dsers',
          img: oneClickMapping.extraData.dsers_img,
          title: oneClickMapping.extraData.dsers_product_title,
          url: oneClickMapping.extraData.dsers_url,
          price: oneClickMapping.extraData.dsers_price,
          shippingPrice: oneClickMapping.extraData.dsers_ship_price,
          shipMethod,
          country,
          userId: userInfo.user_id
        },
        {
          key: oneClickMapping.extraData.ae_product_id,
          supplier: 'current',
          img: oneClickMapping.extraData.ae_img,
          title: oneClickMapping.extraData.ae_product_title,
          url: oneClickMapping.extraData.ae_url,
          price: oneClickMapping.extraData.ae_price,
          shippingPrice: oneClickMapping.extraData.ae_ship_price,
          userId: userInfo.user_id
        },
        {
          key: oneClickMapping.extraData.dsers_product_id,
          supplier: 'dsers2',
          img: oneClickMapping.extraData.dsers_img,
          title: oneClickMapping.extraData.dsers_product_title,
          url: oneClickMapping.extraData.dsers_url,
          price: oneClickMapping.extraData.ae_price,
          shippingPrice: oneClickMapping.extraData.dsers_ship_price,
          shipMethod,
          country,
          userId: userInfo.user_id
        }
      ];
    } else if (isV5) {
      return [
        {
          key: oneClickMapping.extraData.ae_product_id,
          supplier: 'current',
          img: oneClickMapping.extraData.ae_img,
          title: oneClickMapping.extraData.ae_product_title,
          url: oneClickMapping.extraData.ae_url,
          price: oneClickMapping.extraData.ae_price,
          shippingPrice: oneClickMapping.extraData.ae_ship_price,
          userId: userInfo.user_id
        }
      ];
    } else {
      return [
        {
          key: oneClickMapping.extraData.dsers_product_id,
          supplier: 'dsers',
          img: oneClickMapping.extraData.dsers_img,
          title: oneClickMapping.extraData.dsers_product_title,
          url: oneClickMapping.extraData.dsers_url,
          price: oneClickMapping.extraData.dsers_price,
          shippingPrice: oneClickMapping.extraData.dsers_ship_price,
          shipMethod,
          country,
          userId: userInfo.user_id
        },
        {
          key: oneClickMapping.extraData.ae_product_id,
          supplier: 'current',
          img: oneClickMapping.extraData.ae_img,
          title: oneClickMapping.extraData.ae_product_title,
          url: oneClickMapping.extraData.ae_url,
          price: oneClickMapping.extraData.ae_price,
          shippingPrice: oneClickMapping.extraData.ae_ship_price,
          userId: userInfo.user_id
        }
      ];
    }

    // return !isV3
    //   ? [
    //       {
    //         key: oneClickMapping.extraData.dsers_product_id,
    //         supplier: 'dsers',
    //         img: oneClickMapping.extraData.dsers_img,
    //         title: oneClickMapping.extraData.dsers_product_title,
    //         url: oneClickMapping.extraData.dsers_url,
    //         price: oneClickMapping.extraData.dsers_price,
    //         shippingPrice: oneClickMapping.extraData.dsers_ship_price,
    //         shipMethod,
    //         country,
    //         userId: userInfo.user_id
    //       },
    //       {
    //         key: oneClickMapping.extraData.ae_product_id,
    //         supplier: 'current',
    //         img: oneClickMapping.extraData.ae_img,
    //         title: oneClickMapping.extraData.ae_product_title,
    //         url: oneClickMapping.extraData.ae_url,
    //         price: oneClickMapping.extraData.ae_price,
    //         shippingPrice: oneClickMapping.extraData.ae_ship_price,
    //         userId: userInfo.user_id
    //       }
    //     ]
    //   : [
    //       {
    //         key: oneClickMapping.extraData.dsers_product_id,
    //         supplier: 'dsers',
    //         img: oneClickMapping.extraData.dsers_img,
    //         title: oneClickMapping.extraData.dsers_product_title,
    //         url: oneClickMapping.extraData.dsers_url,
    //         price: oneClickMapping.extraData.dsers_price,
    //         shippingPrice: oneClickMapping.extraData.dsers_ship_price,
    //         shipMethod,
    //         country,
    //         userId: userInfo.user_id
    //       },
    //       {
    //         key: oneClickMapping.extraData.ae_product_id,
    //         supplier: 'current',
    //         img: oneClickMapping.extraData.ae_img,
    //         title: oneClickMapping.extraData.ae_product_title,
    //         url: oneClickMapping.extraData.ae_url,
    //         price: oneClickMapping.extraData.ae_price,
    //         shippingPrice: oneClickMapping.extraData.ae_ship_price,
    //         userId: userInfo.user_id
    //       },
    //       {
    //         key: oneClickMapping.extraData.dsers_product_id,
    //         supplier: 'dsers2',
    //         img: oneClickMapping.extraData.dsers_img,
    //         title: oneClickMapping.extraData.dsers_product_title,
    //         url: oneClickMapping.extraData.dsers_url,
    //         price: oneClickMapping.extraData.ae_price,
    //         shippingPrice: oneClickMapping.extraData.dsers_ship_price,
    //         shipMethod,
    //         country,
    //         userId: userInfo.user_id
    //       }
    //     ];
  }, [oneClickMapping, logisticsServices, countries]);

  const handleModalCancel = useCallback(() => {
    dispatch({ type: 'global/closeOneClickMapping' });
    selfDispatch({ type: 'reset' });
    // eslint-disable-next-line
  }, []);
  // console.log(oneClickMapping)
  function handleSelectedChange(value) {
    if (value.length === 0) {
      return;
    }

    selfDispatch({ type: 'setCheckedList', payload: value });
  }

  async function handleSwitch() {
    const tmp = state.checkedList.map(i => {
      const target = state.prodData.find(ii => ii.shopify_product_id === i);
      return {
        store_id: target.store_id,
        sp_product_id: target.shopify_product_id
      };
    });

    window.dataLayer.push({
      category: 'productBargainEvent',
      action: 'submit one-click Mapping',
      label: tmp
    });

    selfDispatch({ type: 'setSwitchLoading', payload: true });
    try {
      const res = await Post('/prod/up_bind', {
        data: tmp
      });
      selfDispatch({ type: 'setSubmitted', payload: true });
      if (res && res.code === 2000) {
        if (oneClickMapping.successCallback) {
          oneClickMapping.successCallback(res);
        }
      }
    } catch (error) {
      console.log('fetch error:', error);
    }

    selfDispatch({ type: 'setSwitchLoading', payload: false });
    setShow(false);
  }

  let number = 0;
  if (compareProducts.length && !isV5) {
    const totalPrice =
      parseFloat(compareProducts[1].price) + parseFloat(compareProducts[1].shippingPrice);
    const dsersTotalPrice =
      parseFloat(compareProducts[0].price) + parseFloat(compareProducts[0].shippingPrice);
    number = parseInt((totalPrice - dsersTotalPrice) * 600);
  }
  const userId = userInfo.user_id;
  const mappingType = oneClickMapping?.extraData?.mapping_type;
  let isAdvancedMapping = true;
  if (mappingType) {
    isAdvancedMapping = [2, 3, 4].includes(mappingType);
  }

  const isV1_1 = list_V1_1.includes(userId);
  const isV1_2 = list_V1_2.includes(userId);
  const isV1_3 = list_V1_3.includes(userId);

  const isV1 = isV1_1 || isV1_2 || isV1_3;

  const isV2_1 = list_V2_1.includes(userId);
  const isV2_2 = list_V2_2.includes(userId);
  const isV2_3 = list_V2_3.includes(userId);
  const isV2_4 = list_V2_4.includes(userId);
  const isV2_5 = list_V2_5.includes(userId);

  const isV2 = isV2_1 || isV2_2 || isV2_3 || isV2_4 || isV2_5;

  isV3 = list_V3.includes(userId);
  isV4 = list_V4.includes(userId);
  isV5 = list_V5.includes(userId);

  let number1 = 0;
  let number2 = 0;
  let number3 = 0;
  if (compareProducts.length > 1 && !isV5) {
    const totalPrice =
      parseFloat(compareProducts[1].price) + parseFloat(compareProducts[1].shippingPrice);
    const dsersTotalPrice =
      parseFloat(compareProducts[0].price) + parseFloat(compareProducts[0].shippingPrice);
    num1.current = parseInt((totalPrice - dsersTotalPrice) * 600);
    num2.current = oneClickMapping?.extraData?.percent || 0;
  }

  const num_1 = num1.current || 0;
  const num_2 = num2.current || 0;

  if (isV2_5 && list5Map) {
    const fiveNumber = list5Map[userId];
    if (fiveNumber && fiveNumber.length) {
      number1 = fiveNumber[0];
      number2 = number1 * 30;
      number3 = fiveNumber[1];
    }
  }

  const cancel = () => {
    setShow(false);
  };

  const cancelApplyModal = () => {
    setShowApply(false);
  };

  return (
    <>
      {/* 原来版本 
      {/* 
      <Modal
        visible={oneClickMapping.visible}
        footer={null}
        width={600}
        maskClosable={false}
        keyboard={false}
        onCancel={handleModalCancel}
        bodyStyle={{ padding: '36px 36px' }}
        destroyOnClose
        className={styles.onClickMappingModal_v1}
        zIndex={1100}
      >
        <Spin spinning={state.loading}>
          {state.submitted ? (
            <SuccessFeedback>
              <h2>{intl.get('bargain.one_click.feedback_title')}</h2>
              <p className={styles.content}>
                {intl.getHTML('bargain.one_click.feedback_subtitle')}
              </p>
            </SuccessFeedback>
          ) : (
            <>
              <h3>{intl.get('bargain.one_click.title')}</h3>
              <div className={styles.compareContainer}>
                {compareProducts.map((item, index, arr) => {
                  return (
                    <React.Fragment key={item.key}>
                      {index === 1 ? (
                        <SaveMoneyArrow
                          percent={oneClickMapping.extraData.percent}
                          compareItem={arr[1]}
                          dsersItem={arr[0]}
                        />
                      ) : null}
                      <CompareProduct {...item} />
                    </React.Fragment>
                  );
                })}
              </div>
              <ul>
                <li>{intl.get('bargain.one_click.intro1')}</li>
                <li>{intl.get('bargain.one_click.intro2')}</li>
                <li>{intl.get('bargain.one_click.intro3')}</li>
                <li>{intl.get('bargain.one_click.intro4')}</li>
                <li>{intl.get('bargain.one_click.intro5')}</li>
              </ul> 
              {isV1_1 && (
                <>
                  <h3>{intl.get('bargain.one_click.detail')}</h3>
                  <div className={styles.selectedList}>
                    <Checkbox.Group
                      onChange={handleSelectedChange}
                      value={state.checkedList}
                    >
                      {state.prodData.map(i => (
                        <div
                          className={styles.selectedItem}
                          key={i.shopify_product_id}
                          title={i.product_title}
                        >
                          <Checkbox value={i.shopify_product_id}>
                            {i.product_title}
                          </Checkbox>
                        </div>
                      ))}
                    </Checkbox.Group>
                  </div>
                  <div className={styles.supportTip}>
                    {intl.getHTML('bargain.one_click.support_tip')}
                  </div>
                </>
              )}
              {isV1_2 && (
                <>
                  <h3>{intl.get('bargain.one_click.supply_chain_title')}</h3>
                  <div className={styles.declareList}>
                    <ul style={{ paddingLeft: 17, marginBottom: 3 }}>
                      <li style={{ color: 'red' }}>
                        <span style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
                          {intl.get('bargain.one_click.supply_chain_1')}
                        </span>
                      </li>
                      <li style={{ color: 'red' }}>
                        <span style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
                          {intl.get('bargain.one_click.supply_chain_2')}
                        </span>
                      </li>
                      <li style={{ color: 'red' }}>
                        <span style={{ color: 'rgba(0, 0, 0, 0.5)' }}>
                          {intl.get('bargain.one_click.supply_chain_3')}
                        </span>
                      </li>
                    </ul>
                    <span
                      style={{
                        color: '#FF8F00',
                        fontWeight: 'bold',
                        fontSize: 14,
                        marginLeft: 12
                      }}
                    >
                      {intl.get('bargain.one_click.supply_chain_example', {
                        number
                      })}
                    </span>
                  </div>
                  <div className={styles.supportTip}>
                    {intl.getHTML('bargain.one_click.support_tip_2')}
                  </div>
                </>
              )}
              {!state.loading && state.checkedList.length === 0 ? (
                <Empty
                  description={intl.get('bargain.one_click.feedback_title')}
                  style={{ marginBottom: 50 }}
                />
              ) : null}
              <div style={{ textAlign: 'right' }}>
                <a
                  href={
                    (oneClickMapping.extraData &&
                      oneClickMapping.extraData.dsers_url) ||
                    '/app/find_suppliers'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  data-category="productBargain"
                  data-action="Check product on Ali"
                  onClick={() => {
                    window.dataLayer.push({
                      event: 'compareProductMassEvent',
                      type: 'clickIssue'
                    });
                  }}
                >
                  <Button>{intl.get('bargain.one_click.check')}</Button>
                </a>
                <Button
                  type="primary"
                  style={{ marginLeft: 12 }}
                  onClick={handleSwitch}
                  loading={state.switchLoading}
                  disabled={state.checkedList.length === 0}
                >
                  {intl.get('bargain.one_click.switch')}
                </Button>
              </div>
            </>
          )}
        </Spin>
      </Modal>
    */}

      {/* version1 */}
      {isV1 && (
        <Modal
          visible={oneClickMapping.visible}
          footer={null}
          width={560}
          height={656}
          maskClosable={false}
          keyboard={false}
          onCancel={handleModalCancel}
          bodyStyle={{ padding: '36px 36px' }}
          destroyOnClose
          className={styles.onClickMappingModal_v1}
          zIndex={1100}
        >
          <Spin spinning={state.loading}>
            <div className={styles.body}>
              <div className={styles.compareContainer}>
                <div className={styles.mainTitle}>
                  {isV1_3 ? (
                    <span className={styles.vipTitle}>{intl.get('bargain.one_click.title_1_3')}</span>
                  ) : (
                    <h3>{intl.get('bargain.one_click.title_1')}</h3>
                  )}
                  {!isV1_3 ? (
                    <span className={styles.subTitle}>
                      {intl.getHTML('bargain.one_click.sub_title')}
                    </span>
                  ) : null}
                </div>
                <div className={styles.cardCon}>
                  <HighLightComponent
                    percent={oneClickMapping.extraData?.percent}
                    compareItem={compareProducts[1]}
                    dsersItem={compareProducts[0]}
                  />
                  {compareProducts.map(item => {
                    return (
                      <>
                        <CompareProduct {...item} />
                      </>
                    );
                  })}
                </div>
              </div>
              {isV1_3 && (
                <div className={styles.textCon}>
                  <h3>{intl.getHTML('bargain.one_click.sub_title_3')}</h3>
                  <span className={styles.text}>
                    {intl.getHTML('bargain.one_click.intro_detail')}
                  </span>
                </div>
              )}
              {!state.loading && state.checkedList.length === 0 ? (
                <Empty
                  description={intl.get('bargain.one_click.feedback_title')}
                  style={{ marginBottom: 50 }}
                />
              ) : null}
            </div>
            <div className={styles.slideContainer}>
              <div className={styles.contentInfo}>
                {!isV1_3 ? (
                  <div className={styles.semicircle}>
                    <div className={styles.circleButton}>
                      <span className={styles.arrow}>
                        <Icon type="up" className={styles.up} />
                        <Icon type="up" className={styles.down} />
                      </span>
                    </div>
                  </div>
                ) : null}
                <div className={styles.contentList}>
                  <div className={styles.titleList}>
                    {!isV1_3 ? (
                      <div className={styles.subContent}>
                        Click the Switch button below to enjoy wholesale price and higher profit margin
                      </div>
                    ) : null}
                    {isV1_3 ? <h3>Rules & Regulations</h3> : <h3>Benefits & advantages</h3>}
                    {isV1_3 ? (
                      <>
                        <div
                          className={`${styles.declareList} ${styles.isVIP}`}
                        >
                          <ul style={{ paddingLeft: 17, marginBottom: 3 }}>
                            <li>
                              <span>
                                {intl.get('bargain.one_click.benefits_c_1')}
                              </span>
                            </li>
                            <li>
                              <span>
                                {intl.get('bargain.one_click.benefits_c_2')}
                              </span>
                            </li>
                            <li>
                              <span>
                                {intl.get('bargain.one_click.benefits_c_3')}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className={styles.supportTip}>
                          {intl.getHTML('bargain.one_click.support_tip_4')}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className={styles.declareList}>
                          <ul style={{ paddingLeft: 17, marginBottom: 3 }}>
                            <li>
                              <span>
                                {intl.get('bargain.one_click.benefits_a_1', {
                                  number
                                })}
                              </span>
                            </li>
                            <li>
                              <span>
                                {intl.get('bargain.one_click.benefits_a_2')}
                              </span>
                            </li>
                            <li>
                              <span>
                                {intl.get('bargain.one_click.benefits_a_3')}
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className={styles.supportTip}>
                          {intl.getHTML('bargain.one_click.support_tip_3')}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.footer}>
              {!isAdvancedMapping ? (
                <Button
                  className={styles.button}
                  type="primary"
                  onClick={() => {
                    setShow(true);
                    dispatch({
                      type: 'global/closeOneClickMapping',
                      callback: oneClickMapping.callback,
                      payload: oneClickMapping.extraData
                    });
                  }}
                  loading={state.switchLoading}
                  disabled={state.checkedList.length === 0}
                >
                  {intl.get('bargain.one_click.switch_now')}
                </Button>
              ) : (
                <div style={{ textAlign: 'right' }}>
                  <a
                    href={
                      (oneClickMapping.extraData &&
                        oneClickMapping.extraData.dsers_url) ||
                      '/app/find_suppliers'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    data-category="productBargain"
                    data-action="Check product on Ali"
                    onClick={() => {
                      window.dataLayer.push({
                        event: 'compareProductMassEvent',
                        type: 'clickIssue'
                      });
                    }}
                  >
                    <Button className={styles.button}>
                      {intl.get('bargain.one_click.check')}
                    </Button>
                  </a>
                </div>
              )}
            </div>
          </Spin>
        </Modal>
      )}

      {/* version 2 */}
      {isV2 && (
        <Modal
          visible={oneClickMapping.visible}
          footer={null}
          width={560}
          maskClosable={false}
          keyboard={false}
          onCancel={handleModalCancel}
          bodyStyle={{ padding: '36px 36px' }}
          destroyOnClose
          className={styles.onClickMappingModal_v2}
          zIndex={1100}
        >
          <Spin spinning={state.loading}>
            <>
              <div className={styles.body}>
                <div className={styles.compareContainer}>
                  <div className={`${styles.mainTitle} ${isV2_5? styles.isFive : ''}`}>
                    {isV2_1 && (
                      <>
                        <h3>{intl.get('bargain.one_click.title_1')}</h3>
                        <span className={styles.subTitle}>
                          {intl.getHTML('bargain.one_click.sub_title')}
                        </span>
                      </>
                    )}
                    {isV2_2 && (
                      <span >第二组显示标题</span>
                    )}
                    {isV2_3 && (
                      <span className={styles.vipTitle}>{intl.get('bargain.one_click.title_1_3')}</span>
                    )}
                    {isV2_4 && (
                      <span className={styles.fourthTitle}>Join DSers Mass Supply program</span>
                    )}
                    {isV2_5 && <span className={styles.fiveTitle}>Reward</span>}
                  </div>
                  <div
                    className={`${isV2_3 ? styles.isVIP : ''} ${isV2_4 ? styles.isFourth : ''}${isV2_5 ? styles.isFive : ''} ${styles.cardCon}`}
                  >
                    {isV2_5
                      ? compareProducts.map((item, index) => {
                          return (
                            <React.Fragment key={item.key}>
                              {index === 0 && <CompareProduct {...item} />}
                            </React.Fragment>
                          );
                        })
                      : compareProducts.map((item, index, arr) => {
                          return (
                            <React.Fragment key={item.key}>
                              {index === 0 ? (
                                <Arrow
                                  percent={oneClickMapping.extraData.percent}
                                  compareItem={arr[1]}
                                  dsersItem={arr[0]}
                                  userId={userId}
                                />
                              ) : null}
                              <CompareProduct {...item} />
                            </React.Fragment>
                          );
                        })}
                  </div>
                  {isV2_5 && (
                    <div className={styles.textContainer}>
                      <div className={styles.text1}>Apply to Mass Supply Reward event</div>
                      {/* <SaveNumber
                        compareItem={compareProducts[1]}
                        dsersItem={compareProducts[0]}
                      /> */}
                      <span className={styles.saveUnit}>{`$ ${number3}`}</span>
                      <div className={styles.text2}>
                        Limited offer until Jun 10
                        <span className={styles.anniversary}>th</span>
                      </div>
                      <div className={styles.text3}>
                        <div>Contact DSers customer service to confirm your application</div>
                        <div>Only DSers loyal clients have the opportunity to apply for a Reward</div>
                        <div>The Reward event is here to help you increase your profits</div>
                      </div>
                    </div>
                  )}
                </div>
                {isV2_3 && (
                  <div
                    className={`${isV2_3 ? styles.isVIP : ''} ${styles.textCon}`}
                  >
                    <h3>{intl.getHTML('bargain.one_click.sub_title_3')}</h3>
                    <span className={styles.text}>
                      {intl.getHTML('bargain.one_click.intro_detail')}
                    </span>
                  </div>
                )}
                {isV2_4 && (
                  <div
                    className={`${isV2_4 ? styles.isFourth : ''} ${styles.textCon}`}
                  >
                    <div className={styles.text1}>
                      {intl.getHTML('bargain.one_click.sub_title_d')}
                    </div>
                    <div className={styles.text2}>
                      {intl.getHTML('bargain.one_click.intro_detail_d')}
                    </div>
                  </div>
                )}
                {!state.loading && state.checkedList.length === 0 ? (
                  <Empty
                    description={intl.get('bargain.one_click.feedback_title')}
                    style={{ marginBottom: 50 }}
                  />
                ) : null}
              </div>
              <div className={styles.slideContainer}>
                <div className={styles.contentInfo}>
                  {!(isV2_3 || isV2_4 || isV2_5) ? (
                    <div className={styles.semicircle}>
                      <div className={styles.circleButton}>
                        <span className={styles.arrow}>
                          <Icon type="up" className={styles.up} />
                          <Icon type="up" className={styles.down} />
                        </span>
                      </div>
                    </div>
                  ) : null}
                  <div className={styles.contentList}>
                    <div className={styles.titleList}>
                      {isV2_3 && (
                        <>
                          <h3>Rules & Regulations</h3>
                          <div
                            className={`${styles.declareList} ${styles.isVIP}`}
                          >
                            <div className={styles.list}>
                              <div className={styles.circle} />
                              <div className={styles.listOne}>{intl.get('bargain.one_click.benefits_c_1')}</div>
                            </div>
                            <div className={styles.list}>
                              <div className={styles.circle} />
                              <div className={styles.listOne}>{intl.get('bargain.one_click.benefits_c_2')}</div>
                            </div>
                            <div className={styles.list}>
                              <div className={styles.circle} />
                              <div className={styles.listOne}>{intl.get('bargain.one_click.benefits_c_3')}</div>
                            </div>
                          </div>
                          <div className={styles.supportTip}>
                            {intl.getHTML('bargain.one_click.support_tip_4')}
                          </div>
                        </>
                      )}
                      {(isV2_1 || isV2_2) && (
                        <>
                          <div className={styles.subContent}>
                            Click the Switch button below to enjoy wholesale price and higher profit margin
                          </div>
                          <h3>Benefits & advantages</h3>
                          <div className={styles.declareList}>
                            <div className={styles.list}>
                              <div className={styles.circle} />
                              <div className={styles.listOne}>{intl.get('bargain.one_click.benefits_a_1', {
                                  number
                                })}
                              </div>
                            </div>
                            <div className={styles.list}>
                              <div className={styles.circle} />
                              <div className={styles.listOne}>{intl.get('bargain.one_click.benefits_a_2')}</div>
                            </div>
                            <div className={styles.list}>
                              <div className={styles.circle} />
                              <div className={styles.listOne}>{intl.get('bargain.one_click.benefits_a_3')}</div>
                            </div>
                          </div>
                          <div className={styles.supportTip}>
                            {intl.getHTML('bargain.one_click.support_tip_3')}
                          </div>
                        </>
                      )}
                      {isV2_4 && (
                        <>
                          <h3>Benefits:</h3>
                          <div className={styles.declareList}>
                            <div className={styles.list}>
                              <div className={styles.circle} />
                              <div className={styles.fourthListOne}>{intl.getHTML('bargain.one_click.benefits_d_1')}</div>
                            </div>
                            <div className={styles.list}>
                              <div className={styles.circle} />
                              <div className={styles.fourthListOne}>{intl.getHTML('bargain.one_click.benefits_d_2', {
                                number
                              })}
                              </div>
                            </div>
                            <div className={styles.list}>
                              <div className={styles.circle} />
                              <div className={styles.fourthListOne}>{intl.getHTML('bargain.one_click.benefits_d_3')}</div>
                            </div>
                          </div>
                          <div className={styles.supportTip_1}>
                            {intl.getHTML('bargain.one_click.support_tip_d_1')}
                          </div>
                          <div className={styles.supportTip_2}>
                            {intl.getHTML('bargain.one_click.support_tip_d_2')}
                          </div>
                        </>
                      )}
                      {isV2_5 && (
                        <>
                          <h3>Rules & Regulations</h3>
                          <div className={styles.declareList}>
                            <div className={styles.list}>
                              <div className={styles.circle} />
                              <div className={styles.fourthListOne}>{intl.getHTML('bargain.one_click.benefits_e_1')}</div>
                            </div>
                            <div className={styles.list}>
                              <div className={styles.circle} />
                              <div className={styles.fourthListOne}>
                                {intl.getHTML(
                                  'bargain.one_click.benefits_e_2',
                                  {
                                    number1,
                                    number2,
                                    number3
                                  }
                                )}
                              </div>
                            </div>
                            <div className={styles.list}>
                              <div className={styles.circle} />
                              <div className={styles.fourthListOne}>{intl.getHTML('bargain.one_click.benefits_e_3')}</div>
                            </div>
                          </div>
                          <div className={styles.supportTip_5}>
                            {intl.getHTML('bargain.one_click.support_tip_e')}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.footer}>
                {!isAdvancedMapping ? (
                  <Button
                    className={styles.button}
                    type="primary"
                    onClick={() => {
                      setShow(true);
                      dispatch({
                        type: 'global/closeOneClickMapping',
                        callback: oneClickMapping.callback,
                        payload: oneClickMapping.extraData
                      });
                    }}
                    loading={state.switchLoading}
                    disabled={state.checkedList.length === 0}
                  >
                    {isV2_4
                        ?
                      intl.get('bargain.one_click.apply_today')
                      :
                      (isV2_5? 'APPLY now' : intl.get('bargain.one_click.switch_now'))
                    }
                  </Button>
                ) : (
                  <div style={{ textAlign: 'right' }}>
                    <a
                      href={
                        (oneClickMapping.extraData &&
                          oneClickMapping.extraData.dsers_url) ||
                        '/app/find_suppliers'
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      data-category="productBargain"
                      data-action="Check product on Ali"
                      onClick={() => {
                        window.dataLayer.push({
                          event: 'compareProductMassEvent',
                          type: 'clickIssue'
                        });
                      }}
                    >
                      <Button className={styles.button}>
                        {intl.get('bargain.one_click.check')}
                      </Button>
                    </a>
                  </div>
                )}
              </div>
            </>
          </Spin>
        </Modal>
      )}

      {/* version 3 */}
      {isV3 && (
        <Modal
          visible={oneClickMapping.visible}
          footer={null}
          width={732}
          maskClosable={false}
          keyboard={false}
          onCancel={handleModalCancel}
          bodyStyle={{ padding: '36px 36px' }}
          destroyOnClose
          className={styles.onClickMappingModal_v3}
          zIndex={1100}
        >
          <Spin spinning={state.loading}>
            <>
              <div className={styles.body}>
                <div className={styles.compareContainer}>
                  <div className={styles.mainTitle}>
                    <span className={styles.title1}>
                      Jun 18<span className={styles.anniversary}>th</span>
                    </span>
                    <span className={styles.title2}>AliExpress Dropshipping Reward</span>
                  </div>
                  <div className={styles.cardCon}>
                    {compareProducts.map((item, index, arr) => (
                      <React.Fragment key={item.key}>
                        <img
                          className={styles.blueArrow_left}
                          width="157"
                          height="90"
                          src={blueArrow}
                        />
                        <img
                          className={styles.blueArrow_right}
                          width="157"
                          height="90"
                          src={blueArrow}
                        />
                        <span className={styles.percent}>
                          SAVE {oneClickMapping.extraData.percent}
                        </span>
                        <div className={styles.subsidyCon}>
                          <div className={styles.text1}>CHALLENGE REWARD</div>
                          <div className={styles.text2}>$5000</div>
                        </div>
                        <TwoCompareProduct {...item} />
                      </React.Fragment>
                    ))}
                  </div>
                  <div className={styles.buttonCon}>
                    {!isAdvancedMapping ? (
                      <Button
                        className={styles.switchButton}
                        type="primary"
                        onClick={() => {
                          setShow(true);
                          dispatch({
                            type: 'global/closeOneClickMapping',
                            callback: oneClickMapping.callback,
                            payload: oneClickMapping.extraData
                          });
                          dispatch({
                            type: 'global/dataTrack',
                            payload: {
                              data: {
                                user_id: userInfo.user_id,
                                store_id: oneClickMapping.extraData.store_id.toString(),
                                platform: 'shopify',
                                page: 'oneClickMapping',
                                module: 'oneClickMapping',
                                action: 'apply-button-click',
                                page_url: window.location.href,
                                product_id_list: [''],
                                order_id_list: [''],
                                custom_info: [
                                  {
                                    name: 'switch_version',
                                    value: getVersion(userInfo.user_id)
                                  },
                                  {
                                    name: 'ae_product_id',
                                    value: oneClickMapping.extraData.ae_product_id.toString()
                                  },
                                  {
                                    name: 'dsers_product_id',
                                    value: oneClickMapping.extraData.dsers_product_id.toString()
                                  },
                                  {
                                    name: 'dsers_product_id',
                                    value: oneClickMapping.extraData.dsers_product_id.toString()
                                  },
                                  {
                                    name: 'shopify_product_id',
                                    value: oneClickMapping.extraData.shopify_product_id.toString()
                                  },
                                  {
                                    name: 'mapping_type',
                                    value: oneClickMapping.extraData.mapping_type.toString()
                                  }
                                ]
                              }
                            }
                          });
                        }}
                        loading={state.switchLoading}
                        disabled={state.checkedList.length === 0}
                      >
                        APPLY
                      </Button>
                    ) : (
                      <div style={{ textAlign: 'right' }}>
                        <a
                          href={
                            (oneClickMapping.extraData &&
                              oneClickMapping.extraData.dsers_url) ||
                            '/app/find_suppliers'
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          data-category="productBargain"
                          data-action="Check product on Ali"
                          onClick={() => {
                            // window.dataLayer.push({
                            //   event: 'compareProductMassEvent',
                            //   type: 'clickIssue'
                            // });
                          }}
                        >
                          <Button className={styles.checkButton}>
                            {/* {intl.get('bargain.one_click.check')} */}
                            CHECK NOW
                          </Button>
                        </a>
                      </div>
                    )}

                    <Button
                      className={styles.applyButton}
                      type="primary"
                      onClick={() => {
                        setShowApply(true);
                        dispatch({
                          type: 'global/closeOneClickMapping',
                          callback: oneClickMapping.callback,
                          payload: oneClickMapping.extraData
                        });
                      }}
                    >LEARN MORE</Button>
                  </div>
                </div>
              </div>
            </>
          </Spin>
        </Modal>
      )}

      {/* version4 */}
      {isV4 && (
        <SwitchV4
          oneClickMapping={oneClickMapping}
          handleModalCancel={handleModalCancel}
          state={state}
          compareProducts={compareProducts}
          dispatch={dispatch}
          setShow={setShow}
          show={show}
          cancel={cancel}
          handleSwitch={handleSwitch}
          userId={userInfo.user_id}
          Arrow={Arrow}
          CompareProduct={CompareProduct}
          isAdvancedMapping={isAdvancedMapping}
          userInfo={userInfo}
          number={number}
        />
      )}
      {isV5 && (
        <SwitchV5
          oneClickMapping={oneClickMapping}
          handleModalCancel={handleModalCancel}
          state={state}
          compareProducts={compareProducts}
          dispatch={dispatch}
          setShow={setShow}
          show={show}
          cancel={cancel}
          handleSwitch={handleSwitch}
          userId={userInfo.user_id}
        />
      )}

      {/* apply弹窗 */}
      <Modal
        visible={showApply}
        footer={null}
        width={480}
        maskClosable={false}
        keyboard={false}
        onCancel={cancelApplyModal}
        bodyStyle={{ padding: '26px 24px 24px 22px' }}
        destroyOnClose
        className={styles.applyModal}
        zIndex={1100}
      >
        <div className={styles.confirmCon}>
          <div className={styles.title}>Reward $100~10,000</div>
          <div className={styles.subTitle}>
            <strong>Talk to your Account Manager<br/>(</strong><a href="mailto:supply-support@dserspro.com">supply-support@dserspro.com</a><strong>) to get Reward</strong><br/>
          </div>
          <div className={styles.subTitle2}>
            Jun 18th AliExpress Dropshipping Reward offers total package 1,000,000 USD to dropshipping clients ONLY.
          </div>
          <div className={styles.declareList}>
            <div className={styles.list}>
              <img className={styles.circle} src={check1} />
              <div className={styles.listOne}><strong>REWARD</strong><br/> Earn $100~10,000 from total reward package 1,000,000 USD</div>
            </div>
            <div className={styles.list}>
              <img className={styles.circle} src={check1} />
              <div className={styles.listOne}>
                <strong>RULES</strong><br/> Monthly purchase reach a certain volume within a month
              </div>
            </div>
            <div className={styles.list}>
              <img className={styles.circle} src={check1} />
              <div className={styles.listOne}><strong>WITHDRAW</strong><br/> Reward withdraw from August</div>
            </div>
            <div className={styles.list}>
              <img className={styles.circle} src={check1} />
              <div className={styles.listOne}><strong>MANUFACTURE</strong><br/> Verified manufacture from AliExpress</div>
            </div>
            <div className={styles.list}>
              <img className={styles.circle} src={check1} />
              <div className={styles.listOne}><strong>ACCOUNT MANAGER</strong><br/> Personal Supply Account Manager (SAM) to assist you</div>
            </div>
          </div>
          <div className={styles.supportTip}>
            Learn all details, please contact DSers Mass Supply team <br/><a href="mailto:supply-support@dserspro.com">supply-support@dserspro.com</a>
          </div>
          <div className={styles.confirmButton}>
            <Button onClick={cancelApplyModal}>OK</Button>
          </div>
        </div>
      </Modal>
      {/* 二次确认弹窗 && switch成功弹窗 */}
      <Modal
        visible={state.submitted}
        footer={null}
        width={560}
        maskClosable={false}
        keyboard={false}
        onCancel={handleModalCancel}
        destroyOnClose
        className={styles.onClickMappingModal}
        zIndex={1100}
      >
        <SuccessFeedback>
          <h2>{intl.get('bargain.one_click.feedback_title')}</h2>
          <p className={styles.content}>
            {intl.getHTML('bargain.one_click.feedback_subtitle')}
          </p>
        </SuccessFeedback>
      </Modal>
      {isV3 && (
        <Modal
          visible={show}
          footer={null}
          width={480}
          maskClosable={false}
          keyboard={false}
          onCancel={cancel}
          bodyStyle={{ padding: '24px 24px' }}
          destroyOnClose
          className={styles.confirmWindow2}
          zIndex={1101}
          centered
        >
          <div className={styles.confirmCon}>
            <div className={styles.title}>
              You will get Reward rate { oneClickMapping.extraData?.percent || 0},while<br/> saving ${number}
            </div>
            <div className={styles.subTitle}>Jun 18th AliExpress Dropshipping Reward offers total package 1,000,000 USD to dropshipping clients ONLY.</div>
            <div className={styles.subTitle2}>Enjoy the following advantages when click CONFIRM</div>
            <div className={styles.declareList}>
              <div className={styles.list}>
                <img className={styles.circle} src={check2} />
                <div className={styles.listOne}><strong>REWARD</strong><br/> Reward rate {oneClickMapping.extraData?.percent || 0} is calculated based on your DSers account level</div>
              </div>
              <div className={styles.list}>
                <img className={styles.circle} src={check2} />
                <div className={styles.listOne}><strong>SAVING</strong><br/> Save ${number} from total reward package 1,000,000 USD</div>
              </div>
              <div className={styles.list}>
                <img className={styles.circle} src={check2} />
                <div className={styles.listOne}><strong>MANUFACTURE</strong><br/> Activate verified manufacture from AliExpress</div>
              </div>
              <div className={styles.list}>
                <img className={styles.circle} src={check2} />
                <div className={styles.listOne}><strong>PRODUCT LINK</strong><br/>  New product link created for you</div>
              </div>
              <div className={styles.list}>
                <img className={styles.circle} src={check2} />
                <div className={styles.listOne}><strong>MAPPING</strong><br/> Product link from verified manufacture will auto replace the mapping</div>
              </div>
              <div className={styles.list}>
                <img className={styles.circle} src={check2} />
                <div className={styles.listOne}><strong>3S FULFILLMENT CENTER</strong><br/> All packages will be processed in 3S fulfillment center</div>
              </div>
              <div className={styles.list}>
                <img className={styles.circle} src={check2} />
                <div className={styles.listOne}><strong>POLICY</strong><br/> 100% refund policy in case unsuccessfully delivery</div>
              </div>
              <div className={styles.list}>
                <img className={styles.circle} src={check2} />
                <div className={styles.listOne}><strong>ACCOUNT MANAGER</strong><br/> Personal Supply Account Manager (SAM) to assist you</div>
              </div>
            </div>
            <div className={styles.supportTip}>
              DSers Mass Supply team <a href="mailto:supply-support@dserspro.com">supply-support@dserspro.com</a>
            </div>
            <div className={styles.confirmButton}>
              <Button onClick={handleSwitch}>CONFIRM</Button>
            </div>
          </div>
        </Modal>
      )}
      {isV2 && (
        <Modal
          visible={show}
          footer={null}
          width={480}
          maskClosable={false}
          keyboard={false}
          onCancel={cancel}
          bodyStyle={{ padding: '24px 24px' }}
          destroyOnClose
          className={styles.confirmWindow}
          zIndex={1101}
          centered
        >
          <div className={styles.confirmCon}>
            {isV2_5 && (
              <>
                <div className={styles.title}>{`$ ${number3} Reward confirm`}</div>
                <div className={styles.declareList}>
                  <div className={styles.list}>
                    <div className={styles.circle} />
                    <div className={styles.listOne}>{intl.getHTML('bargain.one_click.benefits_e_2_1')}</div>
                  </div>
                  <div className={styles.list}>
                    <div className={styles.circle} />
                    <div className={styles.listOne}>
                      {intl.getHTML('bargain.one_click.benefits_e_2_2', {
                        number1,
                        number2
                      })}
                    </div>
                  </div>
                  <div className={styles.list}>
                    <div className={styles.circle} />
                    <div className={styles.listOne}>{intl.getHTML('bargain.one_click.benefits_e_2_3')}</div>
                  </div>
                </div>
                <div className={styles.supportTip}>
                  {intl.getHTML('bargain.one_click.support_tip_e_2')}
                </div>
              </>
            )}
            {isV1 && (
              <>
                <div className={styles.title}>Increase your profit margin</div>
                <div className={styles.declareList}>
                  <div className={styles.list}>
                    <div className={styles.circle} />
                    <div className={styles.listOne}>{intl.getHTML('bargain.one_click.benefits_f_1')}</div>
                  </div>
                </div>
                <div className={styles.supportTip}>
                  {intl.getHTML('bargain.one_click.support_tip_f')}
                </div>
              </>
            )}
            <div className={styles.confirmButton}>
              <Button onClick={handleSwitch}>CONFIRM</Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default connect(({ global, dropshippersetting, login }) => ({
  oneClickMapping: global.oneClickMapping,
  logisticsServices: dropshippersetting.logistics_services,
  countries: dropshippersetting.countrys,
  userInfo: login.userInfo
}))(React.memo(OneClickMapping));
