
    export default 
    {
    "top": {
        "synchronizing_store": "Sincronizzando il negozio…",
        "top_select_store": "Tutti i negozi",
        "select_store": "Seleziona un negozio",
        "link_to_aliExpress": "Collegati ad AliExpress",
        "bind_ali_tip": "Clicca qui per collegarti ad AliExpress",
        "over_bind": "L'account AliExpress è stato inserito nell'elenco dei contatti autorizzati",
        "over_bind_tip": "Hai già collegato il tuo account AliExpress a DSers ed eseguito l'upgrade ad account inserito nell'elenco dei contatti autorizzati",
        "apply_whitelist": "Fai domanda per entrare nell'elenco dei contatti autorizzati",
        "timeout_tip": "Clicca per ottenere un account AliExpress inserito nell'elenco dei contatti autorizzati",
        "link_shopify_tip": "Clicca per collegarti a molteplici negozi Shopify.",
        "link_to_more_stores": "Collegati a più negozi",
        "link_to_shopify_store": "Collegati a un negozio Shopify",
        "bind_ae_title": "Re-authorize AliExpress",
        "bind_ae_content": "The AliExpress whitelisted account you have linked to DSers is about to expire. Please re-authorize AliExpress account. If you have any questions, please  <a href='https://www.messenger.com/t/DSersOfficial' target=\"_blank\" >contact us</a>",
        "bind_ae_expire": "L'account AliExpress inserito nell'elenco dei contatti autorizzati che hai collegato a DSers sta per scadere. Si prega di collegare di nuovo il tuo account Aliexpress.",
        "whitelist_expire": "L'account AliExpress inserito nell'elenco dei contatti autorizzati che hai collegato a DSers sta per scadere. Si prega di collegare di nuovo il tuo account Aliexpress.",
        "update_title": "Aggiornamenti sulle Autorizzazioni",
        "update_desc": "DSers ha rilevato che i tuoi account sottostanti necessitano un aggiornamento delle autorizzazioni per DSers. In caso contrario, non sarai in grado di utilizzare DSers correttamente.",
        "update_desc_is_white": "DSers ha rilevato che i tuoi account sottostanti devono fare domanda per essere inseriti nela lista dei contatti autorizzati. Si prega di cliccare su Fai domanda per entrare nell'elenco dei contatti autorizzati in cima alla pagina per <a href={href} target=\"_blank\" >aggiornare l'autorizzazione.In caso contrario, </a>non sarai in grado di utilizzare DSers correttamente.",
        "update_btn": "Aggiorna",
        "contact_by_email": "Se hai qualche domanda, sei pregato di contattare <span>support@dsers.com</span>",
        "ae_account_linked": "Account di AliExpress collegato",
        "bind_ae_intro": "Sei solo a un passo di distanza dal poter gestire i tuoi ordini e prodotti con Dsers",
        "bind_ae_outdated_intro": "L'autorizzazione dell'account AliExpress scade ogni 3 mesi. Si prega di cliccare Collega ad AliExpress per rinnovare l'autorizzazione.",
        "bindaeTip": "Stato ordine Aliexpress non aggiornato",
        "bindaeTipDetail": "DSers non riesce a ottenere lo stato dell'ordine di AliExpress perché non hai collegato il tuo account AliExpress, quindi collega il tuo account AliExpress il prima possibile.",
        "binaeBtn": "COLLEGA AIEXPRESS",
        "ChristmasTheme":'Tema Tmall',
        "RegularTheme":'Tema Normale'
    }
}