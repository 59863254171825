
    export default 
    {
    "bargain": {
        "bargain": "Regatear",
        "counter_limit": "Puedes regatear 10 veces cada 24 horas.",
        "submission_tip": "Tu gestor de cuenta personal te responderá en 1 día laborable después del envío",
        "banner_title": "CONTRAOFERTA DE PRODUCTOS",
        "banner_content": "¿Estás interesado en precios más económicos al por mayor?<br/> Envía una solicitud con la función de contraoferta. Tu gestor de cuenta personal te responderá en el plazo de 1 día laborable.",
        "not_store": "Antes de enviar una oferta, asegúrate de vincularla a una tienda válida.",
        "link_store": "Link a la tienda",
        "product_name": "Nombre del producto",
        "supplier_price": "Precio del proveedor",
        "email_field": "Tu correo electrónico de contacto (Te responderemos a este correo)",
        "email_field_p": "Por favor, introduce la dirección de correo electrónico que más utilizas",
        "message_field": "Déjanos un mensaje",
        "message_field_p": "Cuéntanos más detalles sobre tu negocio",
        "price_field": "Precio de contraoferta",
        "price_field_p": "Por favor, introduce un precio de contraoferta razonable",
        "submit": "Enviar una oferta",
        "title": "Buscar proveedores",
        "sub_title": "Productos destacados",
        "intro": "¡Una gran selección de productos con alto potencial de venta con productos destacados que proporcionan una entrega en 10 días! <a href=\"mailto:supply-support@dserspro.com\">Contáctanos</a> si quieres saber más.",
        "tag_bargain": "Contraoferta",
        "tag_time": "Tiempo de procesamiento de 24 horas",
        "tag_delivery": "Entrega garantizada",
        "tag_24": "Soporte técnico dentro de las 24 horas",
        "tag_country": "Envío rápido a países específicos",
        "tag_recommend": "Recomendaciones de productos de alta rentabilidad",
        "btn_import": "Importación",
        "btn_bargain": "Contraoferta",
        "search_p": "Introduce las palabras clave para buscar",
        "feedback_title": "¡Has enviado una contraoferta!",
        "feedback_con": "El equipo de suministros de Dsers se pondrá <br/> en contacto contigo en un día laborable. <br/>Gracias por tu paciencia.",
        "copied": "¡Link copiado!",
        "link_copy": "Copiar link",
        "m_title": "¡Producto importado!",
        "m_reminder": "No mostrar de nuevo",
        "reminder_help": "Si marcas esta casilla, esta ventana no aparecerá la próxima vez que importes un producto. Si quieres editar un producto que has importado, ve a la Lista de Importación y haz clic en Editar en la ficha del producto.",
        "m_con": "El producto se ha importado con éxito. Por favor, dirígete a la lista de importación para verificar.",
        "m_btn_stay": "Permanecer",
        "m_btn_go": "Dirígete a la lista de importación para verificar.",
        "m_fail_title": "El producto no se ha importado.",
        "m_fail_con": "El producto no pudo importarse por algún motivo. Por favor, inténtalo de nuevo más tarde.",
        "m_fail_go": "Ya lo tengo",
        "cost": "Coste",
        "piece": "pieza",
        "rating": "Valoración",
        "product_rating": "Valoración del producto",
        "evaluation": "Evaluación",
        "sold": "pedidos",
        "trendy": "Tendencia",
        "found_cheaper": "¡Lo encontramos más económico!",
        "cheaper": "Encontrar uno más económico",
        "reward": "Recompensa AliExpress",
        "alternative": "¿Necesitas una alternativa?",
        "try_this_product": "¿Quieres probar este producto?",
        "powered_by": "Desarrollado por",
        "mass_supply": "Suministro masivo de Dsers",
        "input_email": "Por favor, introduce una dirección de correo electrónico.",
        "fill_max": "Por favor, introduce un máximo de {max} caracteres.",
        "coming_soon": "Próximamente...",
        "ten_days_delivery": "Entrega en 10 días",
        "delivery_tip": "¡Entrega en 10 días a Estados Unidos! Pronto disponible la entrega en 10 días a Brasil y Francia!",
        "reward_tip": "Productos de Suministro Masivo elegibles para recompensa en efectivo",
        "tip_learn_more": "Más información",
        "delivery_tip_url": "https://www.blog.dserspro.com/post/10-days-delivery-to-specific-countries-with-dsers",
        "reward_tip_url": "https://www.blog.dserspro.com/post/dsers-reward-season",
        "banner2": {
            "anchor": "ancla",
            "thank_you": "¡Gracias por elegir el programa Suministro Masivo de Dsers!",
            "delete_product_tip": "Parece que has eliminado el producto de Shopify.<br /> No podemos abrir la página de mapeo.",
            "copied_tip": "El link del producto de Suministro Masivo de Dsers ha sido copiado, por favor selecciona un producto de Shopify para mapear.",
            "go_mapping": "Ir al mapeo"
        },
        "banner_item": {
            "save_percent": "Guardar {percent} y fijar",
            "provided_by": "Proporcionado por",
            "current_sp": "Tu proveedor actual",
            "unstable": "Cuesta más y es inestable"
        },
        "found_cheaper_one": "Hemos encontrado uno más económico",
        "need_alternative": "¿Necesitas una alternativa?",
        "alternative_shipping": "¡Alternativa con envío confiable!",
        "alternative_stock": "Verificar producto alternativo con stock constante",
        "alternative_price": "Verificar producto alternativo con precio fijo",
        "one_click": {
            "title": "¿Quieres probar nuestro proveedor seleccionado?",
            "title_1": "Aumenta tu margen de ganancia",
            "title_1_3": "Link de precios al por mayor VIP",
            "sub_title": "Cambia a un proveedor autorizado de confianza con precios al por mayor con el programa de Suministro Masivo de Dsers.",
            "sub_title_3": "Obtén tu propio link VIP de precios al por mayor como otros grandes dropshippers.",
            "sub_title_d": "Enhorabuena, has alcanzado un alto volumen de ventas",
            "intro_detail": "Solicítalo a través del botón Switch que <br/>se muestra abajo para disfrutar de precios al por mayor y un servicio confiable hoy mismo.",
            "intro_detail_d": "Ingresa al Programa Suministro Masivo de Dsers para <br/> disfrutar de un servicio confiable y precios al por mayor hoy mismo. ",
            "intro1": "Precios al por mayor",
            "intro2": "Inventario constante",
            "intro3": "Servicio de envío confiable",
            "intro4": "Servicio de atención al cliente dedicado",
            "intro5": "Actualización de la política de privacidad",
            "detail": "Haz clic en el botón Switch para reemplazar automáticamente al proveedor de este producto en la(s) tienda(s) de Shopify a continuación",
            "check": "Verificar el producto en AliExpress",
            "switch": "SWITCH",
            "switch_now": "Solicitar SWITCH AHORA MISMO",
            "apply_today": "APLICAR hoy",
            "feedback_title": "El mapeo ha sido reemplazado automáticamente",
            "feedback_subtitle": "Por favor, ponte en <a href=\"mailto:supply-support@dserspro.com\">contacto</a> con nosotros si tienes alguna pregunta",
            "product_cost": "Coste del producto",
            "shipping_cost": "Gastos de envío",
            "support_tip": "Ponte en contacto con nosotros para obtener más información y hablar con tu gestor de cuentas: <br /><a href=\"mailto:supply-support@dserspro.com\">supply-support@dserspro.com</a>",
            "support_tip_2": "Tu propia cadena de suministro se activará una vez aplicado el \"<a href=\"javascript:void(0);\">SWITCH</a>\". Tu gestor de cuenta personal se pondrá en contacto contigo en breve a través del <br/><a href=\"mailto:supply-support@dserspro.com\">supply-support@dserspro.com</a>",
            "support_tip_3": "Puedes aplicar a SWITCH hoy mismo, tu SAM personal se pondrá en contacto contigo inmediatamente. <br />También puedes hablar con tu gestor personal en cualquier momento a través del correo electrónico  <a href=\"mailto:supply-support@dserspro.com\">supply-support@dserspro.com</a>",
            "support_tip_4": "Solicita el botón SWITCH hoy mismo, tu gestor personal de cuentas de suministro (SAM) se pondrá en contacto contigo inmediatamente. <a href=\"mailto:supply-support@dserspro.com\">supply-support@dserspro.com</a>",
            "support_tip_d_1": "Solicítalo hoy mismo o ponte en contacto con nosotros al <a href=\"mailto:supply-support@dserspro.com\">supply-support@dserspro.com</a><br>",
            "support_tip_d_2": "Más información sobre el <a href=\"https://www.dsers.com/blog/dsers-mass-supplier-program/\" target=\"_blank\">programa Suministro Masivo de Dsers</a>",
            "support_tip_e": "Tu gestor personal de cadenas de suministro (SAM) se pondrá en contacto contigo inmediatamente después de que lo solicite.  <a href=\"mailto:supply-support@dserspro.com\">supply-support@dserspro.com</a>",
            "supply_chain_title": "Construye tu propia cadena de suministro",
            "supply_chain_1": "Se te asignará un gestor de cadena de suministro personal (SAM) en línea",
            "supply_chain_2": "Se seleccionará un proveedor fiable para ti, a través del análisis de big data de AliExpress (estudio de datos)",
            "supply_chain_3": "Se creará un nuevo link de producto a precio mayorista/fijo",
            "benefits_a_1": "Ahorra ${number} cambiando a un producto verificado a precio de mayorista",
            "benefits_a_2": "Se creará un nuevo link de producto en AliExpress sólo para ti",
            "benefits_a_3": "El gestor de cadenas de suministro personal (SAM) está aquí para ayudarte.",
            "benefits_c_1": "El programa Suministro Masivo de Dsers asume diferentes responsabilidades (por ejemplo, política de devolución 100% garantizada)",
            "benefits_c_2": "Se garantiza un precio de venta al por mayor constante, un inventario suficiente y un envío rápido (envío estándar de AliExpress)",
            "benefits_c_3": "El link VIP se considera creado a partir de proveedores autorizados en base al análisis de big data",
            "benefits_d_1": "El centro de procesamiento 3S proporciona un servicio profesional, estable y seguro.",
            "benefits_d_2": "El servicio de procesamiento te permite ahorrar ${number} en grandes volúmenes de pedidos",
            "benefits_d_3": "Es posible negociar una cooperación a largo plazo con tu gestor de cuenta personal",
            "benefits_e_1": "Activa el proveedor seleccionado haciendo clic en CONFIRMAR para participar en el evento de recompensa",
            "benefits_e_2": "Utiliza el proveedor seleccionado y completa más de {number1} pedidos al día (o {number2} pedidos en 30 días) para obtener ${number3} recompensas",
            "benefits_e_3": "La recompensa se otorgará una vez completados los pedidos en AliExpress con un retraso de 2 meses",
            "benefits_e_2_1": "El proveedor seleccionado se activará para sustituir a tu proveedor actual",
            "benefits_e_2_2": "La recompensa se otorgará con el Proveedor Seleccionado y al completar más de {number1} pedidos por día (o {number2} pedidos en 30 días).",
            "benefits_e_2_3": "La recompensa se otorgará una vez completados los pedidos en AliExpress con un retraso de 2 meses",
            "support_tip_e_2": "Tu gestor personal de cadena de suministro (SAM) se pondrá en contacto contigo inmediatamente después de que apliques al programa a través del <br/><a href=\"mailto:supply-support@dserspro.com\">supply-support@dserspro.com</a>",
            "benefits_f_1": "El proveedor seleccionado se activará para sustituir a tu actual proveedor",
            "benefits_f_2": "Te ahorrarás ${num_1} durante un mes ({num_2} por cada pedido",
            "support_tip_f": "Tu gestor personal de cadena de suministro (SAM) se pondrá en contacto contigo inmediatamente después de que apliques al programa a través del <br/><a href=\"mailto:supply-support@dserspro.com\">supply-support@dserspro.com</a>",
            "supply_chain_example": "(por ejemplo, en el caso anterior, te ahorrarás {number}/month)",
            "v5": {
                "title_1": "Wholesale Price",
                "subTitle_1": "Reliable supply service for you",
                "subTitle_2_1": "Your Account Manager is",
                "subTitle_2_2": "pleased to help you with",
                "contact": "CONTACT",
                "list_1_title": "RELIABILITY",
                "list_1_text": "Only selected manufacturers verified by AliExpress officially will be proposed",
                "list_2_title": "WHOLESALE PRICES",
                "list_2_text": "Wholesale and stable prices are promised for dropshipping businesses",
                "list_3_title": "FAST SHIPPING",
                "list_3_text": "AliExpress standard shipping method is highly suggested by the AliExpress team",
                "list_4_title": "POLICY",
                "list_4_text": "100% REFUND policy in case  of unsuccessful delivery",
                "miss": "Miss",
                "service_text_1": "Is senior account manager from DSers Mass Supply program.",
                "service_text_2": "She has been involved in supply service over 5 years. ",
                "service_text_3": "And she has the expertise to manage and give you access to multiple manufacturers according to your business needs.",
                "service_text_4": "Talk to her at",
                "banner_title": "Market trends analysis, 1st version",
                "check": "CHECK"
            }
        },
        "tag": {
            "reward": "Recompensa",
            "delivery": "Entrega en 10 días"
        },
        "filter": {
            "country_filter_title": "Proveedores en tendencia en determinados países",
            "category_filter_title": "Categoría",
            "free_return": "Devolución gratuita",
            "processing": "Procesamiento en 24 horas",
            "ship_from": "Enviar desde",
            "shop_import": "Import",
            "shop_orders": "Orders"
        },
        "sorter": {
            "default": "Por defecto",
            "new": "Más reciente",
            "price": "Precio",
            "orders": "Pedidos"
        },
        "btn_optimizer": "Optimizador de proveedores",
        "btn_optimizer_test": "Buscar similares",
        "btn_optimizer_ab_test": "Más proveedores",
        "logo_text": "Selección Premium de Dsers",
        "product": "Producto",
        "ae_product_tip": "Control de calidad de AliExpress",
        "dsers_product_tip": "Selección Premium de Dsers",
        "search_empty_tip": "Lo sentimos, tu búsqueda {keyword} no ha encontrado ningún producto. Por favor, inténtalo de nuevo.",
        "product_button_tip": "Haz clic en Producto para ver la lista de todos los productos",
        "dont_show_again": "No mostrar de nuevo",
        "bargain_disabled_by_order": "La función de Contraoferta sólo está disponible para usuarios con al menos 10 pedidos completados con un número de seguimiento.",
        "bargain_disabled_by_bargained": "Ya has regateado el precio de este producto en el día de hoy. Por favor, inténtalo de nuevo mañana si necesitas hacer una nueva contraoferta.",
        "img_search_failed_tip": "La función de búsqueda por imagen no se puede utilizar en este momento debido a un problema inesperado de la API de AliExpress. Estamos trabajando con el equipo de AliExpress para solucionarlo. Discúlpanos las molestias y gracias por la comprensión.",
        "img_search_success_tip_1": "La búsqueda por imagen puede mostrarte hasta 60 productos idénticos en AliExpress. Haz clic",
        "here": "aquí",
        "img_search_success_tip_2": "para salir de la búsqueda por imagen.",
        "install_ext_title": "Instalar el plugin Dsers",
        "install_ext_description": "Sólo estás a un clic para obtener la mejor selección entre los millones de productos de AliExpress y ahorrar tiempo para importarlos",
        "install_ext_description_test": "¿Guau, al parecer no hay más? Instala la extensión de Dsers para Chrome para encontrar más proveedores calificados hoy mismo",
        "refresh_page": "Actualizar la página",
        "install_ext_btn": "Instalar el plugin Dsers",
        "select_mask": "Por favor, selecciona al menos una tienda para empujar el producto",
        "shipCost": {
            "reminder": "New feature online!",
            "text": "You are now able to import products with purchase price for different countries when you import with DSers Chrome Extension!\n\t\t\tDownload DSers <br/> Chrome extension to discover this feature",
            "learnmore": "LEARN MORE",
            "install": "INSTALL"
        },
        search_no_data_tip:'No hay resultados, prueba con otras palabras clave',
    filter_no_data_tip:'No hay resultados, por favor cambia el filtro',
    onlyFor:'For US & Brazil'
    }
}