export default {
  fulfillment_center: {
    activate: 'Êtes-vous sûr de vouloir activer le produit FBD?',
    activate_dec:
      'Votre fournisseur par défaut sera remplacé par le produit FDB après 48h.',
    input_confirm: 'Entrez CONFIRMER pour activer.',
    deactivate: 'Êtes-vous sûr de vouloir désactiver le produit FDB?',
    deactivate_dec:
      'Votre fournisseur par défaut sera remplacé par le fournisseur AliExpress dans la page de mapping après 48h.',
    using_tip:
      'Vous utilsez le service FBD pour ce produit. Les fournisseurs de la page de Mapping seront utilisé après désactivation.',
    close_request:
      'Votre demande est prise en compte. Cela sera activé après 48h. Pendant ces 48h, le fournisseur actuel sera utilisé.',
    closeing: 'Le service FBD sera utilisé après l\'activation.',
    activate_request:
      'Votre demande est prise en compte. Les fournisseurs de la page de Mapping seront utilisé après 48h.',
    go_setting: 'La boutique doit être autorisé de nouveau. Rendez-vous dans <a href="/app/settings?id=3" >Réglage - Boutiques</a> pour mettre à jour l\'autorisation. Autrement vous ne pourrez pas opérer la boutique correctement avec DSers.',
    title: 'Fulfillment Center (FBD)',
    dec:
      'Nous ne traiterons pas les commandes avec le FBD si vos commandes contiennent de mulptiple produits fournis par le même fournisseur AliExpress pour éviter de multiple frais d\'envoi.',
    pruchese: 'Pruchese',
    saved: 'Saved',
    placeholder: 'Choisir boutique'
  }
};
