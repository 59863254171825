
import getAeUserId from '../../utils/getAeUserId';

const checkAeAccount = () => {
  const data = getPreData();
  const aeUserId = getAeUserId();

  if (!data) {
    return 'notCheck';
  }

  // 新DSers上有AE nick_name字段+开启了Cashback功能并有有效的code+安装了DSers chrome插件的用户
  if (data.store && data.store.code_type === 1 && aeUserId && data.hasExtension === '1') {

    // 1. 没有登陆AE账号
    if (!data.aeCookie || data.aeCookie === 'null') {
      return 'notLogin';
    }

    // 登陆的AE账号的nick_name跟系统绑定的AE账号的nick_name 相同
    if (data.aeCookie.indexOf(aeUserId) > -1) {
      return 'ok';
    } else {
      return 'notMatch';
    }
  }

  return 'notCheck';
}

function getPreData() {
  let data = {
    store: null,
    aeCookie: null,
    hasExtension: null
  };

  try {
    const storeStr = localStorage.getItem('store');
    const aeCookie = sessionStorage.getItem('ae_ck');
    const hasExtension = sessionStorage.getItem('h_d_extension');

    if (storeStr) {
      data.store = JSON.parse(storeStr);
    }

    data.aeCookie = aeCookie;

    data.hasExtension = hasExtension;

  } catch (error) {
    data = null;
    console.log('get getPreData error', error);
  }

  return data;
}

export default checkAeAccount;
