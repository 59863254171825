import React from 'react';
import { Modal, Switch,Popover, Button, Tooltip } from 'antd';

import intl from 'react-intl-universal';
import { connect } from 'dva';
import styles from './index.less';
import './Status.css';
import { CommonDataCollect } from 'utils/utils';
import { GuildeAction, LinkStoreAction } from 'actions'


import RefreshDataPrompt from '../RefreshDataPrompt';
@connect(({ productmy, loading,user }) => ({
  productmy,
  loading: loading.models.productmy,
  saveLoading: loading.effects['productmy/ext'],
  getAliProductLoading: loading.effects['productmy/aeProductDetail'],
  noviceGuidanceInfo:user?.noviceGuidanceInfo,
  plan: user?.plan,
  user: user?.newUserInfo
}))
export default class Status extends React.PureComponent {
  switchInteractiveActive = false; // 标记是否手动切换 status

  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      visibleSwitchMapping:false,
      visibleCloseAllMapping:false,
    };
  }

  componentWillMount() {
    const { map_type, self_type } = this.props;
    // may_type激活的mapping   self_type：base mapping = 1  advance mapping = 2  bogo mapping = 3  bundle mapping = 4
    // 激活mapping等于所在tab的mapping就激活
    if (map_type == self_type) {
      this.setState({
        checked: true
      });
    }
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { checked } = this.state;

    const { self_type } = this.props;
    if (checked !== prevState.checked && this.switchInteractiveActive) {
      this.switchInteractiveActive = false;

      window.dataLayer.push({
        category: 'mapping',
        action: `${checked ? 'turn on' : 'turn off'} switch mapping method`,
        label: this.types[self_type]
      });
    }
  }

  types = {
    '1': 'Basic',
    '2': 'Advanced',
    '3': 'BOGO',
    '4': 'Bundle',
    '5': 'Standard',
  };
  new_types = {
    '1': 'Basic',
    '2': 'Advanced',
    '3': 'BOGO(Buy one get one)',
    '4': 'Bundle',
    '5': 'Standard',
  };

  changeTab = 0; // 0 不操作 1 调接口关闭

  changeChecked = v => {
    const { map_type, self_type } = this.props;
    //  let productMapType = JSON.parse( localStorage.getItem( 'productMapType' ) );
    

    this.setState({
      checked: v
    });

    let t = 0;

    if (map_type == self_type && !v) {
      t = 0;
    }

    if (map_type == self_type && v) {
      t = self_type;
    }

    if (map_type != self_type && v) {
      t = self_type;
    }

    if (map_type != self_type && !v) {
      t = map_type;
    }

    // if( v ){
    //   productMapType[ self_type-1 ] = 1;
    // }else{
    //   productMapType[ self_type-1 ] = 0;
    // }


    // localStorage.setItem( 'productMapType', JSON.stringify( productMapType ) );
    this.props.changeStatus(v, t, this.changeTab);
    this.setState({
      visibleSwitchMapping:false,
      visibleCloseAllMapping:false
    })
  };

  changeStatus = v => {
    // v = true || false
    const { Mapping } = this.props;

    console.log('Mapping', Mapping)

    //订单切换mapping导致数据无法对应  --- bug为默认展示成other单
    window.MAPPING_STATUS = true

    this.switchInteractiveActive = true;

    if (Mapping) {
      const { map_type, self_type } = this.props;
      // [1,0,0,0,0] 1代表开启 0关闭 4位分别代表4个tab

      CommonDataCollect({
        action: this.props.self_type == 2 ? 'Advanced-M-top' : this.props.self_type == 3 ? "BOGO-M-top" : "Bundle-M-top",
        event_type:'subscribe_cam_01'
      });

      const productMapType = JSON.parse(localStorage.getItem('productMapType'));
      const tt = this.getMaptypeIndex(productMapType, self_type); // == 1 商品mapping是当前tab下的mapping  ==2 商品mapping不是当前tab下的mapping
      if (map_type == self_type && !v) {
        if (tt == 2) {
          this.changeChecked(false);
        }
        if (tt == 1 || tt == 0) {
          this.closeConfirm();
        }
      }

      if (map_type == self_type && v) {
        // this.changeChecked(true);
        if (tt == 2) {
          this.openConfirm();
        } else {
          this.changeChecked(true);
        }
      }

      if (map_type != self_type && v) {
        if (tt == 2) {
          this.openConfirm();
        }
        if (tt == 1) {
          this.changeChecked(true);
        }

        if (tt == 0) {
          this.changeChecked(true);
        }
      }

      if (map_type != self_type && !v) {
        if (tt == 2) {
          this.changeChecked(false);
        }
        if (tt == 1 || tt == 0) {
          this.closeConfirm();
        }
      }
    } else {
      localStorage.setItem('passivity', 'true');
      CommonDataCollect({
        action: this.props.self_type == 2 ? 'Advanced-M-top' : this.props.self_type == 3 ? "BOGO-M-top" : "Bundle-M-top",
        event_type:'subscribe_cam_01'
      });
      // My product-Advanced/BOGO/Bundle 点击时
      //  跳转订阅 && 弹窗拦截
      // console.log('camType', this.props)
      //   switch(this.props.self_type){
      //   case 2:
      //   window.EVENT.emit(GuildeAction.TurnPlan_Pop, `Advanced-M&${Date.now()}`, true);
      //   break;
      //   case 3:
      //   window.EVENT.emit(GuildeAction.TurnPlan_Pop, `BOGO-M&${Date.now()}`, true);
      //   break;
      //   case 4:
      //   window.EVENT.emit(GuildeAction.TurnPlan_Pop, `Bundle-M&${Date.now()}`, true);
      //   break;
      //   default:
      //   window.EVENT.emit(GuildeAction.TurnPlan_Pop, `j-&${Date.now()}`, true);
      //   break
      // }
     
      //  self.location.href = '/app/pricing?select=1';

      const { plan, self_type } = this.props

      console.log('camType', self_type)

      if([1,10, 4, 8,12,13,14,15,16,17].includes(plan?.type) && self_type == 3){
        window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'BogoMapping')
      }

      if([1,10, 4, 8,12,13,14,15,16,17].includes(plan?.type) && self_type == 4){
        window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'BundleMapping')
      }

      if([1,10, 4, 8,12,13,14,15,16,17].includes(plan?.type) && self_type == 2){
        window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'AdvancedMapping')
      }
    }
  };

  getMaptypeIndex = (productMapType, self_type) => {
    let t = 0;
    let tt = 0;
    productMapType?.forEach((v, i) => {
      if (v == 1 && i != self_type - 1) {
        t = 2; // 商品mapping不是当前tab下的mapping
      }
      if (v == 1 && i == self_type - 1) {
        tt = 1; // // 商品mapping是当前tab下的mapping
      }
    });
    return t || tt;
  };

  closeConfirm = () => {
    this.setState({
      visibleCloseAllMapping:true
    })
    // Modal.confirm({
    //   content: (
    //     <div>
    //       <p style={{ marginTop: -7 }}>
    //         {intl.get('mapping.close_all_mapping')}
    //       </p>
    //     </div>
    //   ),
    //   icon:'',
    //   okText: intl.get('public.ok'),
    //   cancelText: intl.get('public.cancel'),
    //   className: 'ModalTitlip',
    //   onOk: () => {
    //     this.changeTab = 1;
    //     this.changeChecked(false);
    //   }
    // });
  };

  openConfirm = () => {
    this.setState({
      visibleSwitchMapping:true
    })
    // Modal.confirm({
    //   content: (
    //     <div>
    //       <p style={{ marginTop: -7 }}>
    //         {intl.get('mapping.open_new_mapping', {
    //           switch: this.types[this.props.self_type]
    //         })}
    //       </p>
    //     </div>
    //   ),
    //   icon:'',
    //   okText: intl.get('public.ok'),
    //   cancelText: intl.get('public.cancel'),
    //   className: 'ModalTitlip',
    //   onOk: () => {
    //     this.changeTab = 0;
    //     this.closeStatus();
    //     this.changeChecked(true);
    //   }
    // });
  };

  closeStatus = () => {
    const productMapType = JSON.parse(localStorage.getItem('productMapType')) || [0,0,0,0,0];
    const ind = this.props.self_type - 1;

    if(window.SWITCH_MAP_TYPE){
      return
    }

    const type = productMapType?.map((item, index) => {
      if (ind === index) {
        return (item = 1);
      }
      return (item = 0);
    });
    localStorage.setItem('productMapType', JSON.stringify(type));
  };

  render() {
    const { isFBD, btnDispath, loading,isClickSaveBtn, switchoverMapping, switchoverMappingId, user, plan} = this.props;
    const { checked } = this.state;
    console.log(switchoverMapping);
    console.log(switchoverMappingId);
    console.log('我就是来测试的', this.props.isClickSaveBtn)
    let showAdvancedDetailsFlags = false;
    let showBogoDetailsFlags = false;
    let showBundleDetailsFlags = false;
    let widthFlags = false;

    let endTime = parseInt(+new Date() / 1000);
    let day = parseInt((endTime - user?.created_at) / (24*60*60));

    if(day > 2 && day <= 14){
      widthFlags = true
    }

    if(widthFlags && [1,12,13,14,15,16,17,18,19,20].includes(plan?.type) && this.props.self_type == 2 && [0].includes(plan?.trial_7_days_mapping_advanced_status)){
      showAdvancedDetailsFlags = true;
    }

    if(widthFlags && [1,12,13,14,15,16,17,18,19,20].includes(plan?.type) && this.props.self_type == 3 && [0].includes(plan?.trial_7_days_mapping_bogo_status)){
      showAdvancedDetailsFlags = true;
    }

    if(widthFlags && [1,12,13,14,15,16,17,18,19,20].includes(plan?.type) && this.props.self_type == 4 && [0].includes(plan?.trial_7_days_mapping_bundle_status)){
      showAdvancedDetailsFlags = true;
    }

    return (
      <>
      <div className={styles.switchStatus}>
        <div className={styles.switchStatusL}>
          {intl.get('mapping.status')} <br />
          <span className={styles.tip}>
            {
              this.props.self_type === 6 ? (
                intl.get('setting.agency.statusExplain')
              ) : (
                intl.getHTML('mapping.turn_status', {
                  method: this.new_types[this.props.self_type]
                })
              )
            }
          </span>
        </div>


        <RefreshDataPrompt
          placement="bottomRight"
          visible={switchoverMapping}
          id={switchoverMappingId}
          
        >
          <div style={{display:'flex', alignItems:'center',justifyContent:'center'}}>
          {showAdvancedDetailsFlags ? 
            <span style={{display:'flex', alignItems:'center',justifyContent:'center'}}> 
              <img width={89} style={{display:'inline-block'}} src="https://img.dsers.com/img/chenjiantou.png">
              </img>
            </span> : null}
          {showBogoDetailsFlags ? 
          <span style={{display:'flex', alignItems:'center',justifyContent:'center'}}>
            <img width={89} style={{display:'inline-block'}} src="https://img.dsers.com/img/chenjiantou.png" />
          </span> : null}
          {showBundleDetailsFlags ? 
          <span style={{display:'flex', alignItems:'center',justifyContent:'center'}}> 
            <img width={89} style={{display:'inline-block'}} src="https://img.dsers.com/img/chenjiantou.png"></img>
          </span> : null}
          <Popover 
              placement="left" 
              overlayClassName={styles.popoverContentSwitch} 
              // content={'After completing the mapping, check if the basic mapping method has been applied. If not, remember to turn it on. If it has been turned on, simply click the Save button.'} 
                content={<img src={require("../../assets/mapping/4.gif")} />}
                visible={this.props.isStatus && isClickSaveBtn && !checked && this.props.self_type === 1}
              destroyTooltipOnHide
              getPopupContainer={trigger => trigger.parentNode}
            >
              <Switch
                disabled={btnDispath || isFBD || loading}
                checked={checked}
                onChange={this.changeStatus}
              />
          </Popover>
          </div>
        </RefreshDataPrompt>
        <Modal 
          visible={this.state.visibleSwitchMapping}
          title={intl.getHTML('mapping.open_new_mapping_title')}
          className={styles.deleteTipModal}
          onCancel={()=>{
            this.setState({
              visibleSwitchMapping:false
            })
          }}
          footer={
            <>
              <Button
                className={styles.btn}
                onClick={()=>{
                  this.changeTab = 0;
                  this.closeStatus();
                  this.changeChecked(true);
                }}
              >
                {intl.getHTML('public.newconfirm')}
              </Button>
            </>
          }
          >
          <div>
            {intl.get('mapping.open_new_mapping', {
              switch: this.types[this.props.self_type]
            })}
          </div>
        </Modal>
        <Modal 
          visible={this.state.visibleCloseAllMapping}
          title={intl.getHTML('mapping.close_all_mapping_title')}
          className={styles.deleteTipModal}
          onCancel={()=>{
            this.setState({
              visibleCloseAllMapping:false
            })
          }}
          footer={
            <>
              <Button
                className={styles.btn}
                onClick={()=>{
                  this.changeTab = 1;
                  this.changeChecked(false);
                }}
              >
                {intl.getHTML('public.newconfirm')}
              </Button>
            </>
          }
          >
          <div>
          {intl.get('mapping.close_all_mapping')}
          </div>
        </Modal>
      </div>
      </>
    );
  }
}
