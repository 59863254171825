
    export default 
    {
    "order": {
        "order_language":{
            "pay" : "Por favor, pague sus pedidos dentro de 24h, de lo contrario serán cancelados por Tmall."
        },
        "tab_tip": {
            "pending": "Los pedidos en Pendientes son pedidos cuyo pago por parte de tus clientes aún no ha sido registrado en Shopify. No puedes procesar o editar pedidos Pendientes en Dsers hasta que el pago haya sido registrado. Si un pedido sigue en Pendiente después de haber registrado el pago, por favor, verifica <a\n\t\t\thref='https://help.dsers.com/pending-orders-introduction-for-shopify/'\n\t\t\ttarget=\"_blank\"\n\t\t\tdata-ga=\"Pending - here\"\n\t\t \t >aquí para</a> actualizar manualmente el estado del pedido. Ponte en contacto con nosotros si esto sigue ocurriendo.",
            "awaiting_order": "Los pedidos en Pedido Pendiente son pedidos cuyo pago de tus clientes ha sido registrado en Shopify y están a la espera de ser procesado a AliExpress. Si no puedes localizar tus nuevos pedidos aquí, por favor revisa la pestaña Pendiente y Completado. Consulta cómo procesar los pedidos a AliExpress  <a\n\t\t\thref='https://help.dsers.com/fulfill-multiple-orders-from-start-to-finish-for-shopify/'\n\t\t\ttarget=\"_blank\"\n\t\t\tdata-ga=\"Awaiting order - here\" >aquí</a>.",
            "awaiting_payment": "Los pedidos en Pendiente de Pago son pedidos que se han realizado a AliExpress pero que aún no se han pagado. El número de pedido de AliExpress se genera instantáneamente al realizar el pedido. Consulta  <a\n\t\t\thref='https://help.dsers.com/pay-multiple-orders-on-aliexpress-with-dsers-for-shopify/'\n\t\t\ttarget=\"_blank\"\n\t\t\tdata-ga=\"Awaiting payment - here1\"\n\t\t\t>aquí</a> cómo realizar el pago de forma masiva. También puedes consultar <a\n\t\t\thref='https://help.dsers.com/re-order-awaiting-payment-orders-for-shopify/'\n\t\t\ttarget=\"_blank\"\n\t\t\tdata-ga=\"Awaiting payment - here2\"\n\t\t  \t>aquí</a> cómo cancelar y volver a hacer un pedido. Si encuentras que el estado del pedido no ha cambiado después de hacer el pago, por favor, haz clic  <a\n\t\t\thref='https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/'\n\t\t\ttarget=\"_blank\"\n\t\t\tdata-ga=\"Awaiting payment - here3\"\n\t\t \t >aquí</a>.",
            "awaiting_shipment": "Los pedidos en En espera de Envío han sido pagados en AliExpress pero aún no han sido enviados.Si encuentras que el número de seguimiento no se sincroniza de nuevo después del envío, consulta <a\n\t\t\thref='https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/'\n\t\t\ttarget=\"_blank\"\n\t\t\tdata-ga=\"Awaiting shipment - here\"\n\t\t\t>aquí</a>. Por favor, NO solicites el procesamiento del pedido en Shopify y NO los proceses manualmente en Shopify o Dsers.",
            "fulfilled": "Los pedidos en Completado son pedidos que han sido enviados por tu proveedor de AliExpress y pedidos que fueron completados automática o manualmente. Una vez que un pedido se haya completado, ya no sincronizaremos el estado del pedido o el precio del producto desde AliExpress. Haz clic <a\n\t\t\thref='https://help.dsers.com/re-order-fulfilled-orders-for-shopify/'\n\t\t\ttarget=\"_blank\"\n\t\t\tdata-ga=\"Fulfilled - here1\"\n\t\t  \t>aquí</a> para ver cómo gestionar los pedidos que se completaron por error. También puedes comprobar <a\n\t\t\t  href='https://help.dsers.com/fulfill-multiple-orders-from-start-to-finish-for-shopify/'\n\t\t\t  target=\"_blank\"\n\t\t\t  data-ga=\"Fulfilled - here2\"\n\t\t\t>aquí</a> cómo Dsers completa los pedidos automáticamente.<br> Si deseas sincronizar el último número de seguimiento de los pedidos de AliExpress, puedes hacer clic en el botón Sincronizar número de seguimiento de los últimos 30 días. Sincronizaremos el último número de seguimiento de los pedidos de AliExpress de los últimos 30 días.",
            "canceled": "Los pedidos en Cancelados son pedidos que son (parcialmente) Cancelados / (parcialmente) Reembolsados en AliExpress o Shopify. Puedes hacer pedidos masivos de los pedidos cancelados en AliExpress, pero no puedes solicitar los pedidos cancelados o reembolsados en Shopify. Si encuentras que el estado del pedido no se actualiza después de la cancelación, por favor, consulta <a\n\t\t\thref='https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/'\n\t\t\ttarget=\"_blank\"\n\t\t\tdata-ga=\"Canceled - here1\"\n\t\t\t>aquí</a>. También puedes consultar <a\n\t\t\thref='https://help.dsers.com/re-order-aliexpress-canceled-orders-for-shopify/'\n\t\t\ttarget=\"_blank\"\n\t\t\tdata-ga=\"Canceled - here2\"\n\t\t  \t>aquí</a> para saber cómo Dsers puede ayudarte con los pedidos que siguen siendo cancelados por AliExpress.",
            "failed_orders": "Los pedidos en Pedidos fallidos son aquellos que no se han podido procesar con éxito en AliExpress debido a errores o restricciones. Verifica la información de los clientes y soluciona los errores para que puedas volver a procesar un pedido masivo de los pedidos fallidos. Hay algunas restricciones y reglas de pedidos para algunos países, por favor, haz clic aquí para obtener más información.",
            "awaiting_fulfillment": "Los pedidos en En espera para ser Procesados sólo se gestionan parcialmente. Los pedidos se actualizarán y pasarán a la pestaña Procesado más adelante. Luego, sincronizaremos el número de seguimiento con Shopify y haremos que Shopify envíe sólo un correo electrónico a tu cliente. Haz clic  <a\n\t\t\thref='https://help.dsers.com/awaiting-fulfillment-introduction-for-shopify/'\n\t\t\ttarget=\"_blank\"\n\t\t\tdata-ga=\"Awaiting fulfillment - here\"\n\t\t\t>aquí</a> para obtener más información. Por favor, NO solicites el procesamiento de pedidos en Shopify y NO los proceses manualmente en Shopify o Dsers.",
            "fulfilledFirstChange": "Orders in \"Fulfilled\" are orders that have been shipped out by your AliExpress supplier and orders that were automatically or manually fulfilled.",
            "fulfilledFirstChange_tit": "Una vez que un pedido se haya completado, ya no sincronizaremos el estado del pedido o el precio del producto desde AliExpress. Haz <a target=\"_blank\" href=\"https://help.dsers.com/re-order-fulfilled-orders-for-shopify/\">clic</a> aquí para ver cómo gestionar los pedidos que se completaron por error. <a target=\"_blank\" href=\"https://help.dsers.com/fulfill-multiple-orders-from-start-to-finish-for-shopify/\">clic</a> aquí para ver cómo gestionar los pedidos que se completaron por error. También puedes comprobar aquí cómo Dsers completa los pedidos automáticamente.",
            "fulfilledDays": "También puedes comprobar aquí cómo Dsers completa los pedidos automáticamente.",
            "fulfilledDays_tit": "Si deseas sincronizar el último número de seguimiento de los pedidos de AliExpress, puedes hacer clic en el botón Sincronizar número de seguimiento de los últimos 30 días. Sincronizaremos el último número de seguimiento de los pedidos de AliExpress de los últimos 30 días.",
            "canceledChange": "Bestellungen unter \"Storniert\" sind Bestellungen, die auf AliExpress oder Shopify (teilweise) storniert / (teilweise) erstattet werden.",
            "canceledChange_tit": "You can bulk order the Canceled orders from AliExpress, but you cannot order the orders canceled of refunded on Shopify. If you find that the order status is not updating after cancellation, please check <a target=\"_blank\" href=\"https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/\">here</a>. And you can also check <a target=\"_blank\" href=\"https://help.dsers.com/re-order-aliexpress-canceled-orders-for-shopify/\">here</a> to learn how DSers can help you with orders that keep getting canceled by AliExpress."
        },
        "alert_fulfilled_by_other": {
            "w1": "Nota importante.",
            "w2": "Por favor, verifica si hay pedidos ya procesados en plataformas como Oberlo o Dropified."
        },
        "alert_sync_by_extend": {
            "w1": "Nota: ¡Ahora podemos sincronizar el estado de los pedidos de AliExpress en",
            "w2": "tiempo real",
            "w3": "en dos pasos!",
            "w4": "Haz clic aquí",
            "w5": "para ver los detalles."
        },
        "search_type": {
            "order_no": "Número de pedido",
            "note": "Nota",
            "ali_order_no": "Nº de pedido Ali",
            "tm_order_no":"Tmall Order No",
            "product": "Producto",
            "email": "Correo electrónico",
            "customer_name": "Nombre del cliente",
            "tracking_no": "Número de seguimiento",
            "searchOrder": "Buscar pedido",
            agency_order_no: 'Número de Pedido de la Agencia',
        },
        "search_input_placeholder": "Puedes elegir una categoría para buscar con mayor precisión",
        "syncing": "Sincronizando...",
        "sync_ali_order": "Sincronizar el pedido Ali",
        "sync_via_api": "Sincronización vía API (Puedes sincronizar manualmente desde AliExpress {length} veces más hoy)",
        "sync_tn": "Sincronizar el número de seguimiento de los últimos 30 días (Puedes sincronizar manualmente desde AliExpress {length} veces más hoy)",
        "sync_tn_2": "Sincronizar el último número de seguimiento con la extensión de Chrome",
        "sync_via_extension": "Sincronizar a través de la extensión de Chrome de Dsers",
        "syncing_ali": "Los pedidos se están sincronizando",
        "sync_ali_completed": "Sincronización de pedidos completada",
        "select_sync_range": "Selecciona el rango de fechas para actualizar los Números de Seguimiento",
        "sync_task_count": "Número de pedidos que deben sincronizarse",
        "sync_except_time": "Tiempo aproximado requerido",
        "sync_ali_failed_result": {
            "title": "Las siguientes situaciones pueden causar el fallo:",
            "reason1": "1. La cuenta de AliExpress con la que te conectas actualmente no coincide con la vinculada a Dsers.",
            "reason2": "2. Has cerrado la sesión de tu cuenta de AliExpress durante la sincronización.",
            "reason3": "3. Hay algún problema con tu conexión a la red.",
            "contact": "Si has verificado lo anterior pero sigue arrojando error, no dudes en ponerte en <a rel=\"noopener noreferrer\" target=\"_blank\" href=\"https://www.messenger.com/t/DSersOfficial\" dd=\"src\">contacto con nosotros</a>."
        },
        "start_time": "Hora de inicio",
        "end_time": "Hora de finalización",
        "tab": {
            "title": "{key, plural, =1 {Pending} =2 {Awaiting order} =3 {Awaiting payment} =4 {Awaiting shipment} =5 {Fulfilled} =6 {Canceled} =7 {Failed orders} =9 {Awaiting fulfillment} }",
            "all_tip": "El importe total del pedido puede no ser igual a la suma de Pedido en Espera, Pedido en Espera de Pago, Pedido en Espera de Envío y Pedido Procesado, ya que algunos pedidos podrían fallar.",
            "canceled_tip": "Los pedidos cancelados, ya sea por AliExpress/Shopify o manualmente por los usuarios, se enumeran aquí. Los pedidos cancelados en AliExpress pueden ser solicitados de nuevo."
        },
        "place_order": {
            "btn": "{key, plural, =1 {Place order to AliExpress} =4 {Place order again} }",
            "tm_btn":"{key, plural, =1 {Colocar al por mayor} =4 {Place order again} }",
            "tip": "Es necesario mapear el producto para que Dsers sepa con qué proveedor estás trabajando antes de hacer un pedido masivo. <br /> <a\n\t\t\thref=\"https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/\"\n\t\t\ttarget=\"_blank\"\n\t\t\t>Consultar este artículo</a>",
            agency_btn: '{key, plural, =1 {Pago Masivo} =4 {Place order again} }',
        },
        "more_action": {
            "title": "Más opciones",
            "action_menu": "Procesar pedidos manualmente",
            "action_menu_tip": "No se procesará a AliExpress, sólo se marcará como Procesado.",
            "tip": "Si NO quieres procesar algunos pedidos con Dsers, puedes marcarlos manualmente como Procesados aquí. Esto no afectará al estado de tu pedido en Shopify.",
            "tip_link": "Consultar este artículo"
        },
        "canceled_by": {
            "placeholder": "Cancelado por",
            "shopify": "Shopify",
            "ae": "AliExpress",
            "tm":"Tmall",
            agency_in_dsers: 'You on DSers'
        },
        "fulfilled_by": {
            "placeholder": "Completado por",
            "shopify": "Shopify",
            "ae": "Dsers",
            "self": "Procesado manualmente"
        },
        "country": "País",
        "make_payment": {
            "btn": "Realizar Pago",
            "h3": "Haz tus pagos en la web oficial <br/> de (No hay sugerencias) para procesar tus pedidos.",
            "h4": "¿Qué pasa con los pagos atrasados?",
            "p1": "1. Tus pedidos podrían ser cancelados",
            "p2": "2. Tu cuenta podría experimentar un fallo en el pago",
            "h5": "Por favor, completa tu pago lo antes posible para <br/> cumplir con tus clientes."
        },
        agency_orders: {
            upload_tn_title: 'Cargar manualmente el número de seguimiento',
            upload_tn_sub_title: 'Después de subir el Número de Seguimiento, DSers volverá a Shopify y marcará el pedido correspondiente con el estado "Pagado".',
            upload_file_tip: 'Haz clic aquí para subir el archivo adjunto',
            uplaod_match_column: 'Por favor, selecciona los campos correspondientes en el archivo excel y nosotros los emparejaremos automáticamente por ti, y luego los subiremos.',
            upload_agency_order_id: 'ID de Pedido de la Agencia',
            upload_product_id: 'ID de Producto del Artículo',
            upload_sku_id: 'ID de SKU del Artículo',
            upload_shipping_carrier: 'Empresa de transporte',
            upload_custom_shipping_url: 'Ural de seguimiento personalizada',
            upload_tracking_number: 'Número de seguimiento',
            upload_select_placeholder: 'Por favor, seleccionar',
            upload_confirm: 'CONFIRMAR',
            upload_payment_title: 'Importar detalles de pago',
            upload_payment_sub_title: 'Sube un archivo excel con los gastos de compra. DSers lo registrará por ti, lo que te ayudará en la gestión de los pedidos.',
            upload_payment_currency: 'Moneda de compra (por defecto USD)',
            upload_product_cost: 'Costo de la compra',
            upload_shipping_cost: 'Gastos de envío',
            download_order_info: 'Exportar información del pedido',
            download_by_select: 'Se han seleccionado {value} pedidos',
            download_by_date: 'Exportar pedidos por fecha',
            download_title: 'Exportar',
            download_as: 'Exportar como',
            export_to_file_type_excel: 'Archivo excel normal',
            download_btn: 'Exportar pedidos',
            export_orders_action: 'Exportar información del pedido',
            import_payment_details: 'Importar detalles de pago',
            import_tn_to_orders: 'Subir manualmente N° de Tracking',
            mark_as_cancel: 'Cancelar Pedidos Manualmente',
            mark_as_cancel_title: 'Confirmar cancelación de pedido',
            mark_as_cancel_desc: 'Por favor, ten en cuenta que el pedido se marcará como cancelado en DSers y que el estado del pedido no se sincronizará con Shopify.',
            please_input_confirm: 'Por favor, ingresa CONFIRMAR.',
            mark_as_paid_title: 'Marcar pedido como pagado',
            mark_as_paid_desc: 'Por favor, asegúrate de que el pedido ha sido pagado fuera de línea con tu proveedor. Después de marcar manualmente el pedido como pagado, el pedido se colocará en el estado "Esperando Envío".',
            cancel_order_failed: 'La cancelación del pedido ha fallado, por favor inténtalo de nuevo más tarde.',
            place_confirm_title: 'Confirmar la información del pedido',
            place_btn_tip: 'Haz clic en el botón "Pago Masivo" y DSers verificará la información de la dirección de tu pedido.',
            agency_canceled_tag: 'Cancelado en DSers',
            from_agency: 'Agency',
            agency_order_number_full: 'Agency order number',
            export_failed: 'La exportación ha fallado, puede ser debido a un error de red, por favor inténtalo de nuevo más tarde.',
            export_failed_empty: 'La exportación ha fallado porque no hay ningún pedido dentro del intervalo de tiempo que has seleccionado. Puedes escoger un rango de tiempo diferente e intentarlo de nuevo.',
            upload_success: 'Carga exitosa',
            upload_failed: 'Carga fallida',
            upload_failed_reason: 'Motivos de fallo en la carga',
            uploading: 'Cargando',
            export_download_link_title: 'Si la descarga no se inicia automáticamente, haz clic aquí',
            mark_as_paid_failed: 'Marcar manualmente el pedido como "Pago Fallido", por favor, inténtalo de nuevo más tarde.',
            exporting_orders: 'Exportando',
            agency_fulfill_tip: '¿Estás seguro de que quieres CUMPLIR manualmente estos pedidos?',
            agency_rollback_title: 'Revierte los pedidos seleccionados y los encontrarás en la pestaña de pedidos en espera. La información de los antiguos pedidos de Agency se perderá después de revertir.',
            agency_edit_tracking_no_tip: 'After you successfully modify a Tracking No, you may resend the mail to your buyer according to your Settings, please be careful',
            agency_auth_status_processing: 'Procesamiento ERP',
            agency_auth_status_offline: 'Pedido offline',
            agency_process_tag_tip: `La información del pedido ha sido enviada al ERP de tu proveedor, y el tiempo de creación es de 2 horas. Después de que la creación sea exitosa, puedes comunicar la cotización de compra a tu proveedor y pagar. Si el estado no se ha actualizado durante mucho tiempo, puedes intentar procesar el pedido manualmente.`,
            erp_payment_tip_title: 'Favor confirmar',
            erp_payment_tip_content: `Hemos detectado que el pedido pagado contiene una orden que debe ser creada en el ERP de tu proveedor. Por favor, confirma que tu proveedor ha sido informado de la cantidad total del pedido a procesar antes de pagar.`,
            erp_auth_type_filter: 'Tipo',
            payment: {
                make_payment: 'Make Payment',
                make_payment_tip: 'Haz tus pagos para completar los pedidos. ¡Por favor, completa tu pago lo antes posible para complacer a tus clientes!',
                currency_diff_title: 'Precaución',
                currency_diff_content: 'Por favor, asegúrate de que los pedidos seleccionados están en la misma moneda para el mismo proveedor. Puedes modificar la moneda y volver a cargar el archivo CSV o pagar en lotes basados en diferentes monedas.',
                currency_diff_btn: 'ENTENDIDO',
                payment_modal_title: 'Por favor, confirma la información del pedido y de la agencia antes de pagar.',
                payment_1: "Due to restrictions from {type},  each order must be over $1.00. Please contact your supplier to modify.",

                edit_agency_info: 'Editar información de la agencia',
                agency_info_name: 'Nombre de la Agencia',
                agency_info_paypal: 'PayPal de la Agencia',
                agency_info_amount: 'Importe estimado de pago',
                agency_info_amount_v2: 'Payment amount',
                agency_inpayment_tip: 'The payment is being paid, please complete the rest of the payment.',
                get_payment_detail_failed: 'El pago ha caducado.',
              },
              order_confirm_erp_title: 'ERP',
              order_confirm_erp_tip_1: `Has vinculado el ERP de este proveedor. Una vez realizado el pedido con éxito, enviaremos el pedido directamente a tu proveedor.`,
              order_confirm_erp_tip_2: `No has vinculado el ERP de este proveedor. Una vez realizado el pedido con éxito, deberás exportarlo manualmente y enviarlo a tu proveedor.`
          },
        "order_title": {
            "order_no": "Número de pedido",
            "date": "Fecha",
            "country": "País",
            "income": "Ingreso",
            "note": {
                "title": "Nota",
                "add": "añadir"
            },
            "customer_detail": "Detalle del cliente",
            "title": "Orden de filtrado"
        },
        "edit_address": {
            "contact_name": "Nombre de contacto:",
            "tel": "Telf.:",
            "email": "Correo electrónico:",
            "company": "Compañía:",
            "cpf": "CPF:",
            "country": "País:",
            "address": "Dirección:",
            "province": "Provincia:",
            "city": "Ciudad:",
            "post_code": "Código postal:",
            "clearance_info": "Código de Despacho de Aduanas Personal",
            "alien_clearance_info": "Número de pasaporte/tarjeta de registro de extranjeros:",
            "clearance_info_required": "El Código único de Despacho de Aduanas Persona consiste en un número de 12 dígitos que empieza por P. Completa este campo si el cliente es coreano.",
            "clearance_info_tip": "El Código único de Despacho de Aduanas Personal consiste en un número de 12 dígitos que empieza por P (por ejemplo, P682151173203). Haz clic <a href=\"https://help.dsers.com/orders-to-korea-specifications-for-shopify/\" target=\"_blank\" rel=\"noopener noreferrer\" >aquí</a> para obtener más información.",
            "alien_clearance_info_required": "Ingresa tu número de pasaporte o tarjeta de registro de extranjeros. Completa este campo si el cliente NO es coreano.",
            "alien_clearance_info_tip": "Formato incorrecto. Introduce entre 1 y 13 dígitos o letras.Click <a href=\"https://help.dsers.com/orders-to-korea-specifications-for-shopify/\" target=\"_blank\" rel=\"noopener noreferrer\" >here</a> to get more information.",
            "rut_required": "Por favor, introduce un número RUT",
            "rut_incorrect": "Parece que hay un error con el número RUT",
            "rut_tip": "Por favor, lee nuestro <a href=\"https://help.dsers.com/orders-to-chile-specifications-for-shopify/\" target=\"_blank\" rel=\"noopener noreferrer\" >blog</a> para saber más",
            "unknow_wrong": "Hay un error en la información de tu cliente. Por favor, revisa y corrige.",
            "enter_name": "Por favor, introduce un nombre de contacto",
            "enter_between": "Por favor, ingresa entre {start} - {end} caracteres",
            enter_write_tel:"Please fill in your real phone number here, enter between 5-16 numbers. Click <a href='https://help.dsers.com/fix-failed-orders-due-to-aliexpress-phone-number-regulations/' target='_blank'>here</a> to learn how to solve this problem",
            "korea_error": "Please enter {start} or {end} -digit phone number starting with 01 or 1 (e.g. 01012345678)",
            "italy_error": "Please enter {start} or {end} digits",
            "english_only": "Por favor, escribe sólo en inglés",
            "max128": "La suma de los caracteres del nombre de contacto y la longitud del nombre de la compañía no puede superar los 128 caracteres.",
            "only_num_hyphens_forward": "Por favor, ingresa sólo números y/o guiones '-' y/o barras diagonales '/'",
            "cpf_tip": "El CPF podría ser incorrecto. Si lo has verificado, por favor ignora este mensaje. Dirígete a AliExpress para verificar.",
            "enter_country": "Por favor, ingresa un país/región",
            "enter_address": "Por favor, ingresa una dirección",
            "enter_address_length": "Please ensure that your address is not less than 5 characters.",
            "select_province": "Por favor, selecciona un Estado/Provincia/Región",
            "enter_city": "Por favor, selecciona una ciudad",
            "city_not_match": "La ciudad no coincide. Por favor, selecciona una de la lista desplegable.",
            "enter_zip": "Por favor, introduce un código postal",
            "enter_zip_1234": "Introduce un código postal de {length} dígitos, como {ps}",
            "enter_zip_123456": "Por favor, introduce un código postal, como {ps}",
            "enter_zip_special": "Por favor, introduce tu código postal en el formato sugerido, como {ps}",
            "enter_zip_confirm": "Para envíos internacionales, se requiere un código postal de 7 dígitos. Si es necesario, puedes confirmarlo con tu oficina de correo local.",
            "only_num_spaces_hyphens": "Por favor, ingresa sólo letras/números/espacios/guiones latinos",
            "france_phone_setting_tip": "Para los pedidos enviados a Francia, se requieren números de teléfono móvil de 10 dígitos que empiecen por 06.",
            "france_phone_customers_tip": "Para los pedidos enviados a Francia, se requieren números de teléfono móvil de 10 dígitos."
        },
        "order_product": {
            "title_unarchive": "Desarchivar: Después de desarchivar el pedido, podrás encontrar el pedido en la pestaña 'Pedidos abiertos'",
            "variants": "Variantes:",
            "from": "Desde",
            "from_aliexpress": "(No hay sugerencias)",
            "from_other": "Otros",
            "cost": "Coste",
            "quantity": {
                "price": "precio",
                "ok": "OK",
                "max": "Máximo"
            },
            "dsers_shipping": "ePacket",
            "shipping_method": "Método de envío",
            "shipping_dialog": {
                "company": "Compañía de transporte",
                "cost": "Coste de envío",
                "time": "Tiempo estimado de entrega",
                "tracking": "Información de seguimiento",
                "price": "Envío gratuito",
                "days": "días",
                "available": "Disponible",
                "unavailable": "No disponible",
                "ok": "OK",
                "cancel": "Cancelar",
                "setting_link": "Configurar el método de envío en el panel de Ajustes",
                "overrun_warning": "Has superado el límite diario para acceder a los datos de los productos de AliExpress, pero aún puedes importar y enviar productos.",
                "async_frienght": "Update shipping method list",
                upcoming1:`El método de envío no se puede establecer, la razón puede ser:`,
                upcoming2:`1) El mapeo no está configurado para este producto.`,
                upcoming3:`2) El proveedor no puede realizar la entrega en el destino del pedido. Por favor, confirme la dirección de entrega con el usuario, o utilice "Editar" para cambiar a un proveedor que admita la entrega en este destino.`,
            },
            "shipping_cost_free": "gratis",
            "shipping_time": "días",
            "ali_order_no": "N° de pedido Ali",
            "tmall_order_no": "N° de pedido Tmall",
            "order_no": "Pedido N°",
            "tracking_no": "Número de seguimiento",
            "tracking_no_add": "añadir",
            "sync": "Sincronización",
            "ali_order_no_no_match": "No puedo encontrar el pedido con este número de pedido Ali. Por favor, verificar.",
            "mapping": "Mapeo",
            "mapping_tip": "Haz clic en Mapeo para gestionar el proveedor de este producto.",
            "customer_service": "Atención al cliente",
            "action": {
                "title": "Acción",
                "message": "Mensaje",
                "edit": "Editar",
                "mark_as_fulfilled": "Marcar como Procesado",
                "bulk_edit": "Editar masivamente"
            },
            "fulfilled_by_other_tip": "¿Se han procesado erróneamente los pedidos? Si quieres procesar el pedido a través de Dsers , no puedes hacerlo manualmente en Dsers o Shopify. Necesitarías desactivar la función de autoprocesamiento en Shopify. Si necesitas revertir los pedidos, por favor, haz clic en el botón de mensajería para obtener ayuda. Por favor, consulta <a\n\t\t\tstyle=\"color:#FF8F00\"\n\t\t\thref=\"https://help.dsers.com/re-order-fulfilled-orders-for-shopify/\"\n\t\t\ttarget=\"_blank\"\n\t\t\tdata-ga=\"wrongly fulfilled - here\"\n\t\t\t>aquí</a> para obtener el manual de operaciones paso a paso.",
            "ae_order_error_tip": {
                "click": "Clic",
                "message_us": "Mensaje",
                "why_see_this": "¿Por qué estoy viendo esto?",
                "ae_not_available": "Este producto no está disponible en AliExpress. Por favor, visita AliExpress para verificar si el producto está disponible.",
                "sku_not_found": "El SKU no se encuentra. Por favor, haz clic aquí",
                "sku_not_found_after": "para añadir un link de producto de AliExpress.",
                "no_shipped_country": "Este producto no puede ser enviado a este país. Haz clic aquí &nbsp;",
                "no_shipped_country_after": "&nbsp; para cambiar un producto de AliExpress.",
                "freight_error": "Por favor, completa la información de la dirección del pedido para seleccionar el método de envío",
                "set_shipping": {
                    "p1": "Selecciona un",
                    "p2": "método de envío",
                    "p3": "para activar la función de pedido.",
                    "p4": "Configurar el método de envío en el panel de Ajustes"
                },
                "add_ae_link": {
                    "p1": "¡Link de AliExpress añadido con éxito! Selecciona un",
                    "p2": "método de envío",
                    "p3": "para activar la función de pedido."
                },
                "add_ae_link_succ": "¡Link de AliExpress añadido con éxito!",
                "delete_by_shopify": "El/los producto(s) fue(ron) eliminado(s) de Shopify"
            }
        },
        "bind_product": {
            "ae_url": "URL de AliExpress",
            "ae_url_tip": "Ingresar la URL del producto de AliExpress",
            "tmall_url_tip": "Ingresar la URL del producto de Tmall",
            "from_aliexpress": "Desde AliExpress",
            "cost": "Coste",
            "select_varint": "Las variantes actuales son {num}. Por favor, selecciona la variante correspondiente:",
            "overrun_warning": "Has superado el límite diario para acceder a la información del producto"
        },
        "order_foot": {
            "cost": "Coste del producto",
            "shipping": "Envío",
            "total": "Coste total",
            "pending_authorized": "Autorizado",
            "pending_authorized_tip": "Este pedido necesita registrar el pago en Shopify.",
            "shopify_deleted": "Shopify eliminado",
            "is_shopify_delete": "Pedido eliminado en Shopify. Si todavía deseas procesar el pedido, por favor crea un nuevo pedido similar en Shopify.",
            "product_cost_tip": {
                "title": "Algo pudo haber causado la diferencia de coste:",
                "p1": "1. Dsers sólo muestra el coste estándar básico en AliExpress. Pero el coste real varía según el país al que se venda.",
                "p2": "2. Dsers no admite diferentes monedas por el momento, sólo USD. Por favor, asegúrate de que la moneda sea la misma en Dsers y AliExpress cuando cruces los costes de los productos.",
                "p3": "3. Dsers no actualiza el coste del producto para los pedidos Procesados o Pendientes, una vez que el estado del pedido se actualiza a En Espera, el coste del producto se actualizará automáticamente.",
                "p3_1": "Por favor, ten en cuenta que el coste del producto se actualizará cada 24 horas en Dsers.",
                "p4": "4. Se aplicarán impuestos a los pedidos que se envíen a algunos países, como Estados Unidos y Australia.",
                "p_end": "El coste correcto del producto aparecerá después de realizar el pedido a AliExpress."
            },
            "shipping_tip": "El coste final del envío que se muestra aquí será el mismo que en los pedidos de AliExpress y dependerá de tus proveedores. Cuando se aplique un descuento a tus pedidos de AliExpress, el importe del descuento se contabilizará en el coste de envío aquí. Por favor,  <a\n\t\t\thref=\"https://help.dsers.com/shipping-fees-on-dsers-for-shopify/\"\n\t\t\ttarget=\"_blank\"\n\t\t\t>consulta el blog para más detalles</a>.",
            "order_again": "Hacer el pedido de nuevo",
            "order": "Pedido",
            "order_tip": "Tienes que mapear el producto para que los Dsers sepan con qué proveedor estás trabajando antes de hacer un pedido masivo. <br />\n\t\t\t<a\n\t\t\t  href=\"https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/\"\n\t\t\t  target=\"_blank\"\n\t\t\t>Consultar este artículo</a>",
            "tax_title": "Tax",
            "other_title": "Other",
            "tax_tip": "If you want the tax displayed here, please use the \n\t\t\t<a\n\t\t\t\thref=\"https://www.dsers.com/dsers-chrome-extension/\"\n\t\t\t\ttarget=\"_blank\"\n\t\t\t>\n\t\t\t\tDSers Chrome Extension\n\t\t\t</a> to place the order.",
            "shipping_tip_ext": "The final shipping cost shown here will be the same as on the AliExpress orders and will depend on your suppliers.",
            "other_tip_ext": "When a discount applies to your AliExpress orders, the discount amount will be counted in here.",
            "order_displayed": "The order displayed price is not in USD. DSers converted to USD according to the exchange rate. This may be different from the actual price on AliExpress. DSers only displays it for information, it will not affect the amount to pay. Please check AliExpress to see the actual amount to pay"
        },
        "sync_order_alert": "Dsers se sincronizará automáticamente desde Shopify y AliExpress cuando inicies sesión en Dsers. La sincronización durará 10 minutos. Dsers ya no se sincronizará automáticamente cada 6h para asegurar una sincronización más estable. Por favor, asegúrate de iniciar sesión si quieres sincronizar el estado del pedido.",
        "cpf_alert": "Debido a la nueva normativa, todos los pedidos a Brasil deben contener el número de CPF, o los pedidos no se efectuarán de manera exitosa. Dsers hará que el número CPF aparezca en tus paquetes. Puedes añadir el número CPF de tu cliente en el campo Compañía en Detalles del cliente.",
        "time_zone_placeholder": "Zona horaria",
        "af_more_action": "Sincronizar el número de seguimiento con Shopify",
        "shopify_fulfilled": "Shopify procesado",
        "shopify_canceled": "Shopify cancelado",
        "shopify_partially_fulfilled": "Shopify parcialmente procesado",
        "shopify_partially_canceled": "Shopify parcialmente cancelado",
        "shopify_refunded": "Shopify reembolsado",
        "ali_canceled": "Ali cancelado",
        "tm_canceled": 'Tmall canceled',
        "zero_tip": "La solicitud manual para el procesamiento en Shopify causó el problema. Por favor, procesa esta orden manualmente en Dsers, y luego revierte el pedido. La cantidad se actualizará.",
        "err_deleted_in_shopify": "Este producto fue eliminado en Shopify",
        "err_may_be_blacklisted": "El pedido ha fallado por cuatro posibles razones.",
        "err_france": "Por favor, reemplaza Francia en el campo País por { Guadaloupe } debido a los nuevos términos de AliExpress",
        "err_out_of_stock": "El producto está agotado. Por favor, contacta con tu proveedor o cambia de proveedor.",
        awaiting_error_tip11:"Debido a la restricción de compra del proveedor principal, tu pedido se ha efectuado utilizando el proveedor secundario. Si quieres modificar el proveedor, cancela el pedido y modifica manualmente el mapeo del proveedor en la pestaña Cancelado.",
        awaiting_error_tip13:"Tu pedido se ha efectuado utilizando el proveedor secundario porque el proveedor principal no tiene existencias. Si quieres modificar el proveedor, cancela el pedido y modifica manualmente el mapeo del proveedor en la pestaña Cancelado.",
        awaiting_error_tip14:"La logística del proveedor principal no permite la entrega en el destino seleccionado, tu pedido se ha efectuado utilizando el proveedor secundario. Si quieres modificar el proveedor, cancela el pedido y modifica manualmente el mapeo del proveedor en la pestaña Cancelado.",
        awaiting_error_tip15:"El proveedor principal te ha puesto en la lista negra, tu pedido se ha efectuado utilizando el proveedor secundario. Si quieres modificar el proveedor, cancela el pedido y modifica manualmente el mapeo del proveedor en la pestaña Cancelado.",
        awaiting_error_tip21:"La SKU del proveedor principal no está disponible para su venta, tu pedido se ha efectuado a través del proveedor secundario. Si quieres modificar el proveedor, cancela el pedido y modifica manualmente el mapeo del proveedor en la pestaña Cancelado.",
        awaiting_error_tipOther:"Hay un problema con el proveedor principal y tu pedido se ha efectuado con el proveedor secundario. Si quieres modificar el proveedor, cancela el pedido y modifica manualmente el mapeo del proveedor en la pestaña Cancelado",
        tmall_error_code1:'El sistema está ocupado, vuelve a intentarlo más tarde o haz clic para ponerte en contacto con el servicio de atención al cliente', 
        tmall_error_code2: '',
        tmall_error_code3:'El método de envío de este producto no es válido, por favor intenta realizar el pedido de nuevo',
        tmall_error_code4:'La cantidad de compra no alcanza la cantidad mínima de compra, haga clic para ponerse en contacto con el servicio de atención al cliente o cambiar de proveedor.', 
        tmall_error_code5: 'Este producto no se puede comprar actualmente a través de PC, haz clic para ponerte en contacto con el servicio de atención al cliente o cambiar a un nuevo proveedor',
        tmall_error_code6: 'El inventario de este producto es insuficiente, haz clic para ponerte en contacto con el servicio de atención al cliente o cambiar de proveedor.',
        tmall_error_code7:'Este producto no está temporalmente a la venta en esta región, haz clic para ponerte en contacto con el servicio de atención al cliente o cambiar a un nuevo proveedor', 
        tmall_error_code8: 'Este producto no está disponible temporalmente para su compra, inténtalo de nuevo y vuelve a realizar el pedido',
        tmall_error_code9:'Este producto es un producto activo, y el evento aún no ha comenzado, haz clic para ponerte en contacto con el servicio de atención al cliente o cambiar a un nuevo proveedor',
        tmall_error_code10:'La cantidad de compra supera el límite de compra, haga clic para ponerse en contacto con el servicio de atención al cliente o cambiar de proveedor.', 
        tmall_error_code11: 'La deducción del inventario del producto ha fallado, por favor inténtalo de nuevo y realiza el pedido otra vez',
        tmall_error_code12: 'Este producto no está disponible temporalmente para la compra, haz clic para ponerte en contacto con el servicio de atención al cliente o cambiar a un nuevo proveedor',
        tmall_error_code13:'No se ha podido obtener el precio del producto, inténtalo de nuevo y vuelve a realizar el pedido',
        tmall_error_code14:'', 
        tmall_error_code15: 'Los cupones caducados hicieron que este pedido fallara. Por favor, elimine los cupones e inténtelo de nuevo.',
        tmall_error_code16: 'Actualmente no hay ningún método de envío disponible para este producto, haz clic para ponerte en contacto con el servicio de atención al cliente o cambiar a un nuevo proveedor',
        tmall_error_code17:'', 
        "err_may_be_out_of_stock": "Es posible que tu proveedor no tenga existencias para este pedido. Por favor, ingresa a AliExpress y verifica.",
        "err_limit": "Sólo puedes comprar { limit } pieza(s) para este producto como máximo, por favor contacta con el proveedor para verificar o cambia de proveedor para este producto.",
        "err_limit_blur": "Sólo puedes comprar una cantidad determinada de este producto, por favor, ponte en contacto con el proveedor para verificar, o cambia de proveedor para realizar el pedido.",
        "err_not_available": "Producto no disponible en AliExpress, por favor, cambia el proveedor de este producto.",
        "err_not_shipping": "The province selected in Shopify is set as a country on AliExpress, please manually change the destination in the Customer Detail section of DSers. If the product can't be shipped to the country of destination, we recommend you change the suppliers.",
        "err_blacklisted": "Tu proveedor te ha puesto en la lista negra. Por favor, ponte en contacto con él o busca otro.",
        "err_un_mapped": "Producto no mapeado o parcialmente mapeado, por favor mapea al proveedor en Dsers correctamente. Consulta <a href=\"https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/\" target=\"_blank\">aquí</a> para saber cómo.",
        "err_shipping_to_china": "Ten en cuenta que AliExpress no admite envíos a China.",
        "err_shipping_to_RU": "Debido a la limitación de la interfaz de AliExpress, Dsers no admite el envío a Rusia por el momento. Por favor, procesa el/los pedido/s manualmente en AliExpress e introduce el número de pedido de AliExpress en el pedido en Dsers.",
        err_shipping_address:"La dirección de Shopify o de tu pedido podría no coincidir con la dirección de AliExpress. El método de envío mostrado aquí será el utilizado en el país de destino de AliExpress.",
        "ae_account_disabled": "Tu cuenta de AliExpress ha sido desactivada por AliExpress. Por favor, crea una nueva cuenta de AliExpress y vincúlala.",
        "err_extra_address": "Detalle del cliente incorrecto o faltante, por favor verifica el mensaje de error y corrige adecuadamente.",
        "err_add_address_ukraine": "(If the destination is now a war zone, you will not be able to place the order)",
        "qty_zero_tip": "Se ha solicitado el procesamiento del pedido en Shopify. Dado que la función de solicitud de procesamiento no cumple con la forma en que Dsers gestiona los pedidos, por favor, modifica el pedido en base a la guía que aparece en el blog. Haz clic <a href=\"https://help.dsers.com/re-order-request-fulfillment-order-for-shopify/\" target=\"_blank\">aquí</a> para obtener más información.",
        "ae_tip_IN_error": "At the moment, orders for India cannot be placed successfully. Please click  <a href=\"https://customerservice.aliexpress.com/knowledgeDetail?categoryId=21039549&hcAppId=1248&hcFromCode=Xn2Tk1tb7&hcSessionId=26-1248-70544a7b-e1ed-41af-8a2d-1fa5a625173e&knowledgeId=627712&spm=service_hall.24300488.searchresult.627712）\" target=\"_blank\">here</a> to get more information.",
        "ae_code_11_error": "Debido a problemas con AliExpress, cuando compras productos de una única tienda para ser enviados a Francia, el valor total de los productos no puede superar los 150 EUR. Por favor, divide este pedido en varios y vuelve a intentarlo.Please click <a href=\"/app/settings?tabChangeSta=4\" >here</a> to jump to the settings. Click <a href=\"https://www.blog.dserspro.com/post/orders-to-france-specifications-with-shopify-dsers\" target=\"_blank\">here</a> to get more information.",
        "ae_code_11_error_1": "El precio de tu pedido a Francia supera los 150 Euros. De acuerdo con la normativa, es necesario que completes la información sobre el IVA. Por favor, haz clic <a href=\"/app/settings?tabChangeSta=4\">aquí</a> para ir a Ajustes. Haz clic <a href=\"https://help.dsers.com/orders-to-france-specifications-for-shopify/\" target=\"_blank\">aquí</a> para obtener más información.",
        "ae_code_11_error_btn": "Los cupones caducados han hecho que este pedido falle. Por favor, elimina los cupones e inténtalo de nuevo.",
        "ae_code_11_error_btn_ae":`These possible reasons made this order fail:<br/>
        1. Expired coupons made this order fail. Please remove the coupons and try again.<br/> 
        2. Your AliExpress account was under risk control, please <a href="https://www.messenger.com/t/DSersOfficial" target="_blank" >connect</a> another AliExpress account to try again or contact our support.`,
        "ae_code_11_error_2": "El sistema ha detectado que tu cuenta de AliExpress no puede procesar pedidos con normalidad, por favor, cambia la cuenta de AliExpress vinculada (haz clic <a href=\"/app/settings?tabChangeSta=8\">aquí</a> para cambiarla).",
        "ae_code_11_error_3": "Tu pedido ha encontrado un error desconocido, por favor <a href=\"https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2FDSersOfficial\" target=\"_blank\" >contacta con el servicio</a> de atención al cliente.",
        "err_sku_change": "Tu proveedor modificó el SKU, Dsers actualizará la información del producto cada 2 horas, por favor reasigna el producto e inténtalo más tarde.",
        "supplier_account_disabled": "Cuenta del proveedor desactivada",
        "err_ae_fake_failed": "Debido a la reciente actualización de la interfaz de AliExpress, es posible que este pedido ya se haya completado con éxito. Por favor, verifica en AliExpress para evitar cualquier pérdida. Si el pedido se ha completado con éxito, por favor, introduce el número de pedido manualmente. Si el pedido falla, por favor, realiza el pedido de nuevo.",
        "currency_not_supported": "Moneda no admitida",
        "cpt_tip": "Por favor, introduce un número CPF válido de 11 dígitos",
        "coupon_expired": "Promoción o cupón caducado",
        "duplicated_order": "Pedido duplicado",
        "err_varients_change": "Una variante de este producto ha sido modificada, por favor, vuelve a mapear el producto y luego realiza el pedido",
        "err_code_21": "Please note that the order placement failed because the product's SKU has changed, please re-map and place the order again.",
        "place_order_timeout": "El pedido ha caducado, por favor dirígete a AliExpress para verificar si el pedido se ha completado correctamente. Si el pedido se ha realizado con éxito, introduce manualmente el número de pedido de AliExpress, si el pedido falla, vuelve a hacerlo.",
        "order_processing": "Pedido procesándose ...",
        "order_reault_failed": "¡Tienes pedidos fallidos! Por favor, verifícalos.",
        "order_reault_succ": "¡Dirígete a Efectuar Pago en AliExpress!",
        "order_reault_await_length": "pedidos en curso",
        "order_reault_is_await_length":"pedidos en curso",
        "order_reault_succ_length": "Colocados con éxito",
        "order_reault_failed_length": "No se ha podido colocar",
        "order_reault_makepayment": "Efectuar pago",
        "order_reault_checkfailed": "Comprobar pedido fallido",
        "roll_back_btn": "Revertir pedidos",
        "roll_title": "Revierte los pedidos seleccionados y los encontrarás en la pestaña de pedidos en espera. La información de los antiguos pedidos de AliExpress se perderá después de revertir.",
        "roll_title_tm":"Revierte los pedidos seleccionados y los encontrarás en la pestaña de pedidos en espera. La información de los antiguos pedidos de Tmall se perderá después de revertir.",
        "roll_desc": "Revertir pedidos",
        "place_order_confirm_tooltip": "Please first select a shipping method for all orders before you confirm.",
        "archive_title": "Archived orders",
        "archive_desc": "Once an order is archived, the order will disappear from the current tab. If you want to view the archived order, please go to the Archived orders page.",
        "global_place_order": "Pedido",
        "global_place_order_tip": "Haz un pedido a AliExpress.",
        "confirm": {
            "tm_title":"Por favor, verifica la información de envío antes de realizar los pedidos a Tmall.",
            "title": "Por favor, verifica la información de envío antes de realizar los pedidos a AliExpress.",
            "title_succ": "¡Éxito!",
            "title_fail": "Haz clic en Revisar para comprobar los pedidos fallidos.",
            "table_title": {
                "order": "Pedido",
                "product": "Producto",
                "income": "Ingreso",
                "product_cost": "Coste del producto",
                "country": "País",
                "shipping_method": "Método de envío",
                "shipping_fee": "Gastos de envío",
                "shipping_time": "Tiempo de envío",
                "feedback": "Feedback"
            },
            "error_msg": {
                "err_101": "El/los producto(s) fue(ron) eliminado(s) de Shopify",
                "err_0": "Haz clic en Mapeo para añadir un link de producto de AliExpress.",
                "ae_has_product_no": "Este producto no está disponible en AliExpress. Por favor, consulta en AliExpress para verificar.",
                "child_cost_0": "El SKU no se encuentra. Por favor, haz clic en Mapeo para añadir un link de producto de AliExpress.",
                "express_length_0": "Este producto no puede ser enviado a este país. Haz clic en Mapeo para cambiar un producto de AliExpress.",
                "freight_math_info_no": "Selecciona un método de envío para activar la función de pedido.",
                "freight_math_info_no_bind": "¡Link de AliExpress añadido con éxito! Selecciona un método de envío para activar la función de pedido."
            },
            "item": "{ num, plural, =1 {Item} other {Items} }",
            "item_new":"Item(s)",
            "confirm": "Confirmar",
            "income_tip": "La cantidad de ingresos aquí no incluye el descuento del pedido y el cupón. Puede ser diferente al valor real.",
            "total_cost": "Coste total",
            "revise": "Revisar",
            "make_payment": "Efectuar Pago"
        },
        "fulfill_confirm": {
            "title": "Marcar los pedidos seleccionados como procesados (Esto no se sincronizará con tu tienda de Shopify)",
            "table_title": {
                "order": "Pedido",
                "product": "Producto",
                "ae_order_number": "Número de pedido de AliExpress",
                "feedback": "Feedback"
            },
            "item": "pedidos",
            "mark_as_fulfilled": "Marcar como Procesado",
            "confirm_title": "¿Estás seguro de procesar los pedidos manualmente?",
            "confirm_dec": "¿Estás seguro de procesar manualmente estos pedidos? Por favor, ten en cuenta que Dsers dejará de sincronizar la información de los pedidos que se hayan realizado en AliExpress.",
            "continue": "Continuar",
            "checkTitle": "Marcar este pedido como completado en Shopify también"
        },
        "bind_ae_dialog": {
            "title": "Vincula a AliExpress para realizar varios pedidos",
            "shopify": "Shopify",
            "dsers": "Dsers",
            "go_ae_tip": "Serás redirigido a AliExpress",
            "go_ae": "Link de AliExpress"
        },
        "message_dialog": {
            "title": "Deja un mensaje a los vendedores de AliExpress",
            "tm_title":"Deja un mensaje a los vendedores de Tmall",
            "desc": "Los vendedores de AliExpress recibirán este mensaje junto con la información de tu pedido.",
            "tm_desc":"Los vendedores de Tmall recibirán este mensaje junto con la información de tu pedido.",
            "placeholder": "Introduce como máximo 1000 caracteres en inglés (como máximo 250 chino)",
            "save": "Guardar",
            title_new: 'Dejar un mensaje a los proveedores',
            desc_new: 'Los proveedores recibirán este mensaje junto con la información de tu pedido.',
        },
        "go_ae_pay_dialog": {
            "h3": "Efectúa tus pagos en el sitio web <br/> oficial de (No hay sugerencias) para completar tus pedidos.",
            "h4": "¿Qué ocurriría en caso de algún retraso en el pago?",
            "p1": "1. Tus pedidos podrían ser cancelados",
            "p2": "2. Tu cuenta podría experimentar un fallo en el pago",
            "h5": "Por favor, efectúa tu pago lo antes posible para satisfacer<br/> a tus clientes.",
            "no_show": "No mostrar de nuevo",
            "make_payment": "Efectuar Pago",
            mark_as_paid: 'Mark as paid'
        },
        "product_error_msg": {
            "can_not_shipped_this_country": "El producto no puede ser enviado a este país. Añade otro link de AliExpress."
        },
        "options_different_tip": "Aviso: La opción de la variante no coincide.",
        "roll_back_confirm_title": "¿Estás completamente seguro de que quieres realizar esta acción?",
        "roll_back_confirm_tip": "Por favor, presiona CONFIRMAR para revertir los pedidos.",
        "roll_back_awaiting_shopify_hook_tip": "Dsers no está procesando el/los pedido/s de Shopify por ahora, así que el/los pedido/s será/n movido/s a la pestaña de Pedido en Espera dentro de unos segundos, por favor espera.",
        "no_data": {
            "no_data": "No hay datos",
            "title": "Tu búsqueda podría no arrojar resultados por una de las siguientes razones:",
            "p4": "1. Si has archivado el pedido en Shopify, por favor, comprueba el menú Archivado en Dsers para encontrar el pedido.",
            "p1": "2. La información que has introducido es inexacta.",
            "p2": "3. La búsqueda no está dentro del rango de tiempo establecido actualmente.",
            "p3": "4. La búsqueda puede incluir un producto oculto.",
            Pendings:{
                title:'Pending orders will be displayed here',
                detail:`Orders in the Pending tab are orders for which the payment from your customers has not yet been captured in Shopify.`
              },
              AwaitingOrders:{
                title:'Orders awaiting to be placed will be displayed here',
                detail:`Orders in the Awaiting order tab are orders for which the payment from your customers has been captured on Shopify and are waiting to be processed to Tmall.`
              },
              AwaitingPayments:{
                title:'Awaiting payment orders will be displayed here',
                detail:`Orders in the Awaiting payment tab are orders that have been placed on Tmall but not paid yet. `
              },
              AwaitingShipments:{
                title:'Awaiting shipment orders will be displayed here ',
                detail:`Orders in the Awaiting shipment tab are orders that have been paid on Tmall but have not been shipped yet.`
              },
              AwaitingFulfillments:{
                title:'Awaiting fulfillment orders will be displayed here',
                detail:'Orders in the Awaiting fulfillment tab are orders only partially fulfilled.'
              },
              Fulfilleds:{
                title:'Fulfilled orders will be displayed here',
                detail:`Orders in the Fulfilled tab are orders that have been shipped out by your Tmall supplier and orders that were automatically or manually fulfilled.`
              },
              Canceleds:{
                title:'Canceled orders will be displayed here',
                detail:'Orders in the Canceled tab are orders that have been (partially) canceled / (partially) refunded on Tmall or Shopify.'
              },
              FailedOrders:{
                title:'Failed orders will be displayed here',
                detail:'Orders in the Failed tab are orders which could not be placed to Tmall successfully due to errors or restrictions.'
              },
            "Pending": {
                "title": "Los pedidos pendientes se mostrarán aquí ",
                "detail": "Los pedidos en la pestaña de \"Pendientes\" son pedidos cuyos pagos de tus clientes aún no han sido registrados en Shopify. Haz clic <a href={href} target=\"_blank\">aquí</a> para saber más sobre los pedidos en la pestaña \"Pendiente\"."
            },
            "AwaitingOrder": {
                "title": "Pedidos en espera de ser colocados se mostrará aquí",
                "detail": "Los pedidos en la pestaña de \"Pedidos en Espera\" son pedidos cuyos pagos de tus clientes han sido registrados en Shopify y están a la espera de ser procesados en AliExpress. Haz clic <a href={href} target=\"_blank\">aquí</a> para saber más sobre los pedidos en la pestaña de \"Pedidos en Espera\"."
            },
            "AwaitingPayment": {
                "title": "Los pedidos en espera de pago se mostrarán aquí ",
                "detail": "Los pedidos en la pestaña En espera de pago\" son pedidos que se han realizado en AliExpress pero que aún no se han pagado. Haz clic <a href={href} target=\"_blank\">aquí</a> para obtener más información sobre los pedidos en la pestaña \"En espera de pago\"."
            },
            "AwaitingShipment": {
                "title": "Los pedidos en espera de envío se mostrarán aquí ",
                "detail": "Los pedidos en la pestaña \"En espera de envío\" son pedidos que han sido pagados en AliExpress pero que aún no han sido enviados. Haz clic <a href={href} target=\"_blank\">aquí</a> para obtener más información sobre los pedidos en la pestaña \"En espera de envío\"."
            },
            "AwaitingFulfillment": {
                "title": "Los pedidos en espera de cumplimiento se mostrarán aquí ",
                "detail": "Los pedidos en la pestaña \"En espera de cumplimiento\" son pedidos sólo parcialmente completados. Haz clic <a href={href} target=\"_blank\">aquí</a> para obtener más información sobre los pedidos en la pestaña \"En espera de cumplimiento\"."
            },
            "Fulfilled": {
                "title": "Los pedidos cumplidos se mostrarán aquí ",
                "detail": "Los pedidos en la pestaña \"Cumplidos\" son los pedidos que han sido enviados por tu proveedor de AliExpress y los pedidos que han sido completados automática o manualmente. Haz clic <a href={href} target=\"_blank\">aquí</a> para saber más sobre los pedidos en la pestaña \"Cumplidos\"."
            },
            "Canceled": {
                "title": "Los pedidos cancelados se muestran aquí ",
                "detail": "Los pedidos en la pestaña \"Cancelados\" son pedidos que han sido (parcialmente) cancelados / (parcialmente) reembolsados en AliExpress o Shopify. Haz clic <a href={href} target=\"_blank\">aquí</a> para saber más sobre los pedidos en la pestaña \"Cancelados\"."
            },
            "FailedOrder": {
                "title": "Los pedidos fallidos se muestran aquí ",
                "detail": "Los pedidos en la pestaña de \"Fallidos\" son pedidos que no se han podido realizar con éxito en AliExpress debido a errores o restricciones. Haz clic <a href={href} target=\"_blank\">aquí</a> para saber más sobre los pedidos en la pestaña de \"Fallidos\"."
            }
        },
        agency_open_no_data: {
            Pending:{
              title:'Pending orders will be displayed here',
              detail:`Orders in the Pending tab are orders for which the payment from your customers has not yet been captured in Shopify. `
            },
            AwaitingOrder:{
              title:'Orders awaiting to be placed will be displayed here',
              detail:`Orders in the Awaiting order tab are orders for which the payment from your customers has been captured on Shopify and are waiting to be processed to agencies. `
            },
            AwaitingPayment:{
              title:'Awaiting payment orders will be displayed here',
              detail:`Orders in the Awaiting payment tab are orders that you need to make payment to agencies.`
            },
            AwaitingShipment:{
              title:'Awaiting shipment orders will be displayed here ',
              detail:`Orders in the Awaiting shipment tab are orders that paid to suppliers through DSers, or marked as paid manually and awaiting for shipment.`
            },
            AwaitingFulfillment:{
              title:'Awaiting fulfillment orders will be displayed here',
              detail:'Orders in the Awaiting fulfillment tab are orders only partially fulfilled.'
            },
            Fulfilled:{
              title:'Fulfilled orders will be displayed here',
              detail:`Orders in the Fulfilled tab are orders that have been shipped out by your agencies and orders that were automatically or manually fulfilled. `
            },
            Canceled:{
              title:'Canceled orders will be displayed here',
              detail:'Orders in the Canceled tab are orders that have been (partially) canceled / (partially) refunded on Shopify or by agencies. '
            },
            FailedOrder:{
              title:'Failed orders will be displayed here',
              detail:'Orders in the Failed tab are orders which could not be placed to agencies successfully due to errors or restrictions.'
            }
          },
        "open_no_data": {
            "title": "¿No puedes localizar tu primer pedido de AliExpress?",
            "p1": "2. Por favor, consulta la pestaña Procesado para localizar el o los pedidos;",
            "p2": "3. Si localizas el/los pedido/s en la pestaña Procesado;",
            "p3": "4. Por favor, dirígete a Shopify - Ajustes - Pago, y desactiva la función de autoprocesamiento como se muestra a continuación;",
            "p4": "5. Por favor, asegúrate de hacer clic en Guardar para actualizar los Ajustes;",
            "p5": "6. Por favor, dirígete a la pestaña Procesado y haz clic en el botón Más opciones para revertir un pedido procesado seleccionado.",
            "p6": "1. Si has archivado el pedido en Shopify, por favor consulta el menú Archivado en Dsers para encontrar el pedido;",
            "Pending": "Haz clic <a href={href} target=\"_blank\">aquí</a> para saber más sobre los pedidos en la pestaña \"Pendiente\".",
            "AwaitingOrder": "Haz clic <a href={href} target=\"_blank\">aquí</a> para saber más sobre los pedidos en la pestaña de \"Pedidos en Espera\".",
            "AwaitingPayment": "Haz clic <a href={href} target=\"_blank\">aquí</a> para obtener más información sobre los pedidos en la pestaña \"En espera de pago\".",
            "AwaitingShipment": "Haz clic <a href={href} target=\"_blank\">aquí</a> para obtener más información sobre los pedidos en la pestaña \"En espera de envío\".",
            "AwaitingFulfillment": "Haz clic <a href={href} target=\"_blank\">aquí</a> para obtener más información sobre los pedidos en la pestaña \"En espera de cumplimiento\".",
            "Fulfilled": "Haz clic <a href={href} target=\"_blank\">aquí</a> para saber más sobre los pedidos en la pestaña \"Cumplidos\".",
            "Canceled": "Haz clic <a href={href} target=\"_blank\">aquí</a> para saber más sobre los pedidos en la pestaña \"Cancelados\".",
            "FailedOrder": "Haz clic <a href={href} target=\"_blank\">aquí</a> para saber más sobre los pedidos en la pestaña de \"Fallidos\"."
        },
        "dsers_product_deleted": "2. Producto eliminado en Dsers, por favor haz clic <a href={href} target=\"_blank\" >aquí</a> para saber cómo procesar el pedido en Dsers.",
        "variant_delete_tip": "Variante eliminada en Shopify. Haz clic en Mapeo para asignar un proveedor para la variante eliminada.",
        "YANWEN_JYT_shipping_tip": "El método de envío seleccionado ya no está disponible en AliExpress. Por favor, consulta con tu proveedor y selecciona otro método de envío.",
        "err_deleted_in_dsers": "Este producto ha sido eliminado en Dsers, por favor haz clic <a href={href} target=\"_blank\">aquí</a> para saber cómo procesar el pedido en Dsers",
        "pending_tip": "Por favor, asegúrate de que has registrado el pago de los pedidos en Shopify. Si un pedido sigue en Pendiente después de haber registrado el pago, verifícalo",
        "pending_tip_end": "para actualizar manualmente el estado del pedido.",
        "ae_sync_not_done": "La sincronización está en curso y aún no ha terminado.",
        "ae_sync_more_then_5": "No se puede sincronizar manualmente más de 5 veces al día.",
        "select_shipping_method_tip": "Por favor, selecciona un método de envío.",
        "ful_with_tracking": "Procesado con un número de seguimiento",
        "ful_by_shopify": "Procesado por Shopify",
        "ful_manually": "Procesado manualmente en Dsers",
        "partial_completion_of_order": "Tu proveedor ha enviado varios paquetes de este producto. Procesaremos el pedido cuando todos los números de seguimiento estén sincronizados.",
        "ae_splited_order": "El pedido está actualmente dividido en varios pedidos de AliExpress. Si hay una diferencia en los números de pedido, por favor, verifica y modifícalos manualmente",
        "freight_not_supported": "Este país no admite el método de envío actualmente seleccionado, por favor, selecciona otro. Haz clic <a target=\"_blank\" href=\"https://www.dsers.com/blog/aliexpress-shipping-methods-changes/\" rel=\"noopenner noreferrer\" >aquí</a> si necesitas ayuda.",
        "edit_sp_tooltip": "Si deseas cambiar el proveedor de este pedido, utiliza la función Acción - Editar.",
        "dsers_action_edit": "Dsers Acción Editar",
        "variant_delete_tag": "Variante eliminada de Shopify",
        "go_ae_check_order": "Ir a AliExpress",
        "may_be_fake_failed_order1": "Tu pedido",
        "may_be_fake_failed_order2": "pudo haber sido realizado con éxito en AliExpress. Para evitar cualquier pérdida, por favor, verifica en AliExpress",
        "order_fulfill": "El procesamiento del pedido ha fallado,",
        "token_authorize_info": "El token de la tienda actual ha caducado,",
        "order_pending": "El pedido se está procesando",
        "token_authorize_info_btn": "por favor, haz clic en ajustes para volver a autorizar",
        "order_onHold_info": "Manually fulfill orders",
        "order_onHold_info_tip": "Synchronizing",
		"order_onHold_snync_errorMess":'Your order is on hold in Shopify and cannot be automatically fulfilled. Please go to Shopify to change the on hold status and try again manually',
        "order_onHold_snync_tip":"Click this button to sync tracking numbers to your store and fulfill this order on Shopify",
        "order_pending_btn": "Por favor, espera el resultado del procesamiento del pedido",
        "archive": "Archivo",
        "test_order_tip": "Este pedido es un pedido de prueba, y el número de pedido generado es un número de pedido virtual",
        "conada_error": "Por favor, introduce tu nombre completo tal y como aparece en tu ID. Haz clic <a target=\"_blank\" href=\"https://help.dsers.com/orders-to-canada-specifications-for-shopify/\">aquí</a> para saber cómo resolver este problema.",
        "cl_rut_errtip": "El RUT puede estar incorrecto. Si lo has verificado, por favor ignora este mensaje. Dirígete a AliExpress para volver a verificar.",
        "cl_rut_errtipNo": "Por favor, ingresa de 2 a 12 dígitos o letras, como 12123234-K",
        "rut_banner": {
            "title": "Orders to Chile RUT number requirements",
            "btn_text": "learn more"
        },
        "err_may_be_listed_5": "\n      1.Sólo puedes comprar una cantidad determinada de este producto, por favor, ponte en contacto con el proveedor para verificar, o cambia de proveedor para realizar el pedido.<br/>\n      2. The order will ship to France and the supplier's product value exceeds 150 EURO, please change the supplier or apply to be a Business Buyer on your Aliexpress account.<br/>\n      3. The supplier's product link is unavailable.\n    4.El pedido contiene proveedores de AliExpress que requieren diferentes monedas para el pago, por favor, coloque los artículos por separado.      ",
        "err_address_yg": "It is recommended that you replace \"{country}\" with United Kingdom in the Country field. Fill in \"{countrys}\" in the Province field",
        "ae_code_11_error3": "The system detected that your AliExpress account cannot place orders normally, please change the linked AliExpress account (click <a href=\"/app/settings?tabChangeSta=14\">here</a> to change).",
        "ae_code_11_error4": "Tu pedido ha encontrado un error desconocido, por favor contacta con el servicio de atención  <a href=\"https://www.messenger.com/t/DSersOfficial\" dd=\"src\" target=\"_blank\">al cliente</a>.",
        ae_code_11_error4_extension:`Your order encountered an unknown error or you manually terminated the order, if you have any question, please <a href="https://www.messenger.com/t/DSersOfficial" dd="src" target="_blank">contact customer service</a>.`,
        "ae_code_14_error": "El método de envío que eligió no es compatible, por favor, cambia el método de envío",
        ae_code_27_error: `Due to AliExpress interface limitation, DSers doesn't support shipping to the destination at the moment. Please manually place the order(s) on AliExpress and fill in the AliExpress order number on the order on DSers.`,
        "order_reault_await_title": "Placing order(s)...",
        "order_reault_succ_title": "Order(s) placed",
        "order_reault_checkfailed1": "Comprobar pedido(s) fallido(s)",
        "order_reault_fail_title": "Some order(s) failed",
        "sync_last_chan": "Sincronización del pedido AE",
        "sync_time_last_chan": "Última hora de sincronización",
        "sync_time_next_chan": "Siguiente hora de sincronización",
        "flags": {
            "title": "Flags",
            "None": "None",
            "Grey": "Grey",
            "Blue": "Blue",
            "Green": "Green",
            "Yellow": "Yellow",
            "Red": "Red"
        },
        "shopify_partially_refunded": "Shopify parcialmente reembolsado",
        "place_via_extension": {
            "caution": "Caution",
            "ae_account_not_match": "Hemos detectado que la cuenta de AliExpress en la que ha iniciado sesión no coincide con la cuenta vinculada a DSers. Para asegurarnos de que el estado de su pedido en AliExpress se pueda sincronizar con DSers, vincule su cuenta de AliExpress actualmente conectada a DSers o realice un pedido en la cuenta de AliExpress actualmente vinculada a DSers.",
            "login_ae_account_disabled": "",
            "order_via_api_opt": "Order via API (Place the orders faster)",
            "order_via_ext_opt": "Order via Chrome Extension (Automatically use received coupons)",
            "ext_not_installed_title": "DSers Chrome Extension needs to be installed",
            "ext_not_installed_content": "Check if you have installed the DSers Chrome Extension installed and click Add DSers Chrome Extension below to go to the Chrome Web store page to install it, then return to this page to try again.",
            "add_dsers_ext": "Add DSers Chrome Extension",
            "order_via_api": "Order via API",
            "order_is_processing": "Ordering via extension, please do not close the page or the browser",
            "not_operate_title": "Do not perform the following operations",
            "not_operate_1": "Exit/switch DSers account;",
            "not_operate_2": "Close/uninstall DSers plug-in;",
            "not_operate_3": "Operate AliExpress shopping cart;",
            "not_operate_4": "Exit AliExpress account.",
            "failed_order_exsits": "The ordering process is over. Some may have failed. Please check on AliExpress to avoid any loss. If the order was successful, please fill in the order number manually. If the order fails, please place the order again.",
            "abort_task_btn": "Terminate all tasks",
            "abort_task_modal_title1": "Confirm to terminate all current order tasks?",
            "abort_task_modal_content1": "After confirming the termination of all orders, orders that have not been placed will appear in the Failed Order tab. You can order them again later.",
            "confirm_btn": "Confirm",
            "cancel_btn": "Cancel",
            "abort_task_modal_title2": "Order task terminated",
            "abort_task_modal_content2": "({value}) orders have been stopped, these orders will appear in the Failed Order tab",
            "abort_got_it": "Got it",
            "error_tip_ae_logout": "Your AliExpress account was logged out during the ordering process, please log in to your AliExpress account and try again while being logged in.",
            "error_tip_action_timeout": "La solicitud se ha agotado, por favor, inténtalo de nuevo.",
            "error_tip_cart_error": "El pedido ha fallado, por favor, inténtalo de nuevo.",
            "error_tip_unknown": "El pedido ha fallado, por favor, ponte en contacto con el servicio de atención al cliente para resolver este problema.",
            "error_tip_manually_close_1": "El pedido ha fallado porque ha cerrado manualmente la página de pedido, por favor, intenta hacer el pedido de nuevo.",
            "error_tip_manually_abort": "You have manually aborted the order task.",
            "error_tip_manually_close_2": "La página de pedido se cerró o la pestaña de la página de pedido se desplazó. Por favor, inténtalo de nuevo.",
            "on_hold_error": "No puedes colocar el pedido porque está en espera en Shopify. Por favor, cambia el estado del pedido en Shopify y luego colócalo en DSers.",
            "request_fulfill_error": "Se ha solicitado el cumplimiento del pedido en Shopify. La función \"Solicitar cumplimiento\" no cumple con la forma en que DSers procesa los pedidos, por favor, modifica el pedido en base a la guía en el blog. Haz clic <a href=\"https://help.dsers.com/re-order-request-fulfillment-order-for-shopify/\">aquí</a> para obtener más información",
            "shipping_error": "There is no shipping method applied to this order, please select manually. Or you can go to Settings--Shipping to set up your favorite shipping method, then DSers will select it automatically",
            "mapping_error": "Debes mapear los productos para que DSers sepa con qué proveedor estás trabajando antes de hacer un pedido masivo.",
            "shipping_error_set": "The shipping method you set based on the product in the settings is not supported by the supplier anymore, so the order cannot be placed without a default shipping method. You can modify the shipping method in the settings, or manually modify it in the order",
            "on_hold_error_item": "No puedes colocar el pedido porque está en espera en Shopify. Por favor, cambia el estado del pedido en Shopify y luego colócalo en DSers.",
            "request_fulfill_error_item": "Se ha solicitado el cumplimiento del pedido en Shopify. La función \"Solicitar cumplimiento\" no cumple con la forma en que DSers procesa los pedidos, por favor, modifica el pedido en base a la guía en el blog. Haz clic <a href=\"https://help.dsers.com/re-order-request-fulfillment-order-for-shopify/\">aquí</a> para obtener más información",
            "shipping_error_item": "No hay ningún método de envío aplicado a este pedido. Por favor, comprueba los <a href=\"/app/settings\">ajustes de envío</a> en DSers o comprueba la disponibilidad de los métodos de envío en AliExpress.",
            shipping_error_agency: 'There is no shipping method applied to this order, please select manually.',
            error_tip_product_captcha: 'Su red es anormal, por favor, deslice para verificar en AliExpress y el orden de nuevo.',
        },
        "disappear_order_tip": "There is a problem with some of your orders, if you need to retrieve them, please <a class=\"ds-link\" target=\"_blank\" href=\"https://www.messenger.com/t/DSersOfficial\"\">contact us.</a>",
        "order_code_24_error": "This order failed due to a network error, please try to place the order again",
         tmall_sku1_error: 'El SKU del producto ha cambiado, por favor compruebe los detalles de su mapeo',
         tmall_sku2_error:`El SKU original no existe, necesita volver a mapear o <a href=\"{url}\" target=\"_blank\">cambiar el proveedor </a>, de lo contrario no podrá realizar el pedido con éxito.`,
         tmall_sku3_error:`The original SKU does not exist or changed, you need to re-map or change the supplier, otherwise you can't place the order successfully.`,
        "note_title_y": "Añadir nota",
        "note_desc_y": "La nota sólo es visible en DSers",
        "note_tip_y": "El número de caracteres en el cuadro de entrada ha superado el límite, por favor, elimina el contenido y guarda.",
        "aeProcess":'Process Orders',
        "aeProcessSub":`Al cencelar los pedidos seleccionados, estos se moverán a la pestaña de Pedidos por realizar. La información del antiguo pedido de AliExpress se perderá después de la restauración.  <span style="color:red"> Ten en cuenta que estos pedidos se cambiarán a no procesados en Shopify. Nota: Debido a la política de Shopify, sólo podemos actuar sobre los pedidos de los últimos dos meses.</span>`,
        "confirm_modal_up": {
            "fulfill_title": "Procesar con los pedidos manualmente",
            "fulfill_confirm": "CONFIRMAR",
            "rollback_title": "Roll Back Orders",
            "rollback_input_confirm_value": "¿Está seguro de que deseas PROCESAR manualmente estos pedidos? ¿Está seguro de que deseas PROCESAR manualmente estos pedidos?",
            "note_cancel": "CANCEL",
            "node_save": "SAVE",
            "fulfill_desc": "¿Está seguro de que deseas PROCESAR manualmente estos pedidos? Por favor, ten en cuenta que DSers dejará de sincronizar la información de los pedidos que se hayan realizado en AliExpress.",
            "fulfill_desc_tm":"Are you sure you want to manually FULFILL these orders? Please note that DSers will stop syncing order information for the orders that have been placed on Tmall."
        },
        orderQuantityToolTip:{
            "title": "Todavía hay {items} artículos en los otros menús de pedidos",
            "aliexpress": "Pedidos de AliExpress",
            "agency": "Pedidos de Agencias",
            "unmapped": "Pedidos sin Mapeo"
          },
        "aeCancelTip":`Debido a la nueva normativa de AliExpress, cuando un pedido utiliza un número de seguimiento falso puede ser cancelado por AliExpress. Revierte este tipo de orden manualmente en la pestaña "Llenado".`,
        editActionTip:`Dado que has modificado el proveedor del pedido, el artículo o pedido se moverá a la página de pedidos de {platformType}`,
        edit_order_confirm_tip_1: `Has utilizado la función Editar para modificar el tipo de proveedor del artículo, por favor, dirígete a la pestaña {platform} - {tab} para ver el artículo.`,
        edit_order_confirm_tip_2: `Has utilizado la función Editar para modificar el tipo de proveedor de algunos artículos, dirígete a la pestaña {platform} - {tab} para ver el artículo.`,
        edit_order_confirm_tip_var: {
            aliexpress: 'AE',
            tmall: 'Tmall',
            both: 'AE / Tmall',
            awaiting_order: 'Pedido en espera',
            canceled_order: 'Cancelado',
            failed_order: 'Pedido fallido',
        },
        orderPayment:{
            timeout:'Pago fallido. El pago ha caducado, por favor reinicia el proceso de pago.',
            cancel:'Pago fallido. El pago ha sido cancelado, por favor reinicia el proceso de pago.',
            unknow:'El pago ha fallado. Motivo desconocido, por favor contacta con el soporte técnico de DSers para resolver este problema.',
            checkDetail:'Comprobar datos',
            link:'Enlace de pago',
            loading:'El pago que iniciaste a las {time} está siendo pagado.',
            success:'El pago que iniciaste a las {time} se ha realizado con éxito.',
            error:'The payment you started at {time} is failed.',
            pendingTip:'Si quieres cancelar el pago de este pedido, por favor, cancélalo a través del enlace de pago.',
            reason:'failed reason',
            buttonTip:'Make your payments to complete your orders. Please complete your payment asap to satisfy your customers!',
            orderOneButtonTip:'Realizar Pago'
        },
        paymentModalTitle:'Por favor, comprueba la información del pedido antes de pagar.',
        orderPageGuidance:'El pedido se ha sincronizado, por favor diríjase a la página de Pedidos Abiertos para procesar los pedidos pendientes.'
    }
}
