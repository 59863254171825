import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'antd';
import { Get } from 'utils/request';

import styles from './V5Banner.less';
import useTopBanner from '../../hooks/useTopBanner';
import bg1 from '../../assets/banner/v5_banner1.png';
import bg2 from '../../assets/banner/v5_banner2.png';
import { list_V5 } from '../../utils/bannerWhiteList';

const allowPath = [
  // '/home',
  // '/report',
  '/order/list',
  // '/storemanage/import',
  // '/storemanage/myproduct'
];

const V5Banner = ({ path, userId, dispatch, userInfo }) => {
  const [visible] = useTopBanner(path, allowPath);
  const [bannerData, setBannerData] = useState({});
  const isV5User = list_V5.includes(userId);

  useEffect(() => {
    const getBannerData = async () => {
      const stores = userInfo.stores || [];
      const storeIds = stores.map(item => {
        return item?.id;
      }) || [];
      const params = {
        data: {
          store_ids: storeIds
        }
      };

      const res = await Get('/prod/propool/ad/list', params);
      if (res && res.code === 2000) {
        setBannerData(res.data[0]);
      }
    };
    getBannerData();
  }, []);

  if (!visible || !isV5User) {
    return null;
  }
  return (
    <div className={`${styles.container}`}>
      <div className={styles.banner}>
        <div className={styles.content}>
          <div className={styles.text1}>
            {intl.get('bargain.one_click.v5.banner_title')}
          </div>
          <div className={styles.text2}>20% off</div>
          <Button type="primary" className={styles.learnButton} onClick={() => {
            window.open('https://blog.dsers.com/blog/ecommerce-statistics-dropshipping/');
          }}>
            {intl.getHTML('banner.newUI.learn')}
          </Button>
          <Button type="primary" className={styles.checkButton} onClick={() => {
              // dispatch({
              //   type: 'global/showOneClickMapping',
              //   payload: bannerData
              // });
              dispatch({  
                type:'global/drawerBannerVisible'     //展示侧边栏banner
              })
            }}
          >
            {intl.get('bargain.one_click.v5.check')}
          </Button>
        </div>
        <div className={styles.bannerBg}>
          <a
            href="https://blog.dsers.com/blog/ecommerce-statistics-dropshipping/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={bg1} className={styles.bg1} />
          </a>
          <a
            onClick={() => {
              // dispatch({
              //   type: 'global/showOneClickMapping',
              //   payload: bannerData
              // });
              dispatch({
                type:'global/drawerBannerVisible'    //展示侧边栏banner
              })
            }}
          >
            <img src={bg2} className={styles.bg2} />
          </a>
        </div>
      </div>
    </div>
  );
};

V5Banner.allowPath = allowPath;

export default V5Banner;
