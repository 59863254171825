
    export default 
    {
    "csv": {
        "csv_bulk_order": "CSV-Massenbestellung",
        "re_download_template": "Um die Stabilität der Plattform aufrechtzuerhalten, können CSV-Benutzer bis zu 2.000 Bestellungen pro Tag aufgeben, bei Erreichen dieser Grenze werden die Bestellungen fehlschlagen.",
        "products": "Produkte",
        "import": "importieren",
        "download_template": "Vorlage herunterladen",
        "product_tip": "Verwalten Sie Ihre Produktdatenbank, um Großbestellungen aufzugeben.",
        "orders": "Bestellungen",
        "order_tip": "Holen Sie sich die AliExpress-Bestellnummern und die Tracking-Nummer (die Generierung der Tracking-Nummern dauert in der Regel einige Tage), indem Sie die Datei in der Spalte Erfolgreiche Bestellungen herunterladen und die fehlgeschlagenen Bestellungen überarbeiten.",
        "upload_title_orders": "Bestellungen importieren",
        "upload_title_product": "Produktdatenbank importieren",
        "upload_btn": "importieren",
        "need_help": "Brauchen Sie Hilfe?",
        "choose_csv_file": "CSV-Datei auswählen",
        "no_file_chosen": "Keine Datei ausgewählt",
        "max_8mb": "Ihre CSV-Datei muss weniger als 8 MB groß sein.",
        "upload_download_tip": "um ein Beispiel für das erforderliche Format zu sehen",
        "upload_succ": "Upload erfolgreich.",
        "upload_fail": "Upload fehlgeschlagen",
        "upload_fail_tip": "Das hochgeladene Dateiformat ist falsch. Bitte überprüfen Sie die csv-Datei.",
        "click_here_to": "Klicken Sie hier zum",
        "download": "herunterladen.",
        "download_error": "die Fehlermeldung Excel.",
        "order_table": {
            "date": "Datum",
            "file_name": "Name der Datei",
            "successful_orders": "Erfolgreiche Aufträge",
            "failed_orders": "Fehlgeschlagene Aufträge",
            "action": "Aktion"
        },
        "pending": "Ausstehend",
        "place_order": "Bestellung aufgeben",
        "check_product": "AliExpress Produkt SKU prüfen",
        "check": "Prüfen",
        "price": "Preis",
        "token_error": "Bitte aktualisieren Sie die Seite, um die Datei erneut herunterzuladen."
    }
}