import { routerRedux } from 'dva/router';
import qs from 'qs';
import { captureMessage } from '@sentry/react';

import {
  fakeAccountLogin,
  getCaptcha,
  getChannel,
  googleStop2Verify,
  getResetPassword,
  postResetPassword,
  detectPlatform,
  updatePlatform,
  aliLogin,
  fakeMigraLogin
} from '../services/login';
import {
  queryUserInfo,
  queryCurrent,
  queryNewInfo,
  reportAccout
} from '../services/user';
import {
  postAuthWixStoreResult,
  getMigrationInfo,
  getNewPlatformProductUserInfo
} from 'services/auth';
// import getUserInfo from '../utils/getUserInfo';
import { goToWoo } from '../common/sysconfig';
import { getStoreStatus } from 'utils/utils';
import { getCurrenciesCustom } from '../services/customize';
import { recordLog, AliExpressAuthStatus } from '../services/global';
import { Modal } from 'antd';
import intl from 'react-intl-universal';
import {
  AGENCY_LOCAL_STORE_EXPORT_TASK_KEY,
  AGENCY_LOCAL_STORE_UPLOAD_TASK_KEY
} from 'routes/AgencyOrder/component/constants';
import {
  exchangeShopifyAuthParamsToAuthKey,
  getLoginInfo,
  getMVPUserInfo,
  getOldUserInfo,
  getOldUserPlatform,
  getOpenAPIShopifyAppKeyRedirectUrl,
  getOpenAPIShopifyRedirectUrl,
  loginToMvpAccount,
  logoutMvpAccount,
  mvpApplicationSite,
  mvpDeveloperSite,
  setOldUserPlatformToWix,
  syncMvpLoginStatusToDevSite
} from 'utils/justifyMVPAccountUtils';
import { getBannerStatus } from '../components/BeforeMigration/service';

const path = (() => {
  const { href, origin } = window.location;
  return href.replace(origin, '');
})();

const customizeUserMessage = {
  id: 'cbgjiainn1ng00b50n20',
  store_id: 'cbgjiahmilu000f5qnrg'
};
export default {
  namespace: 'login',

  state: {
    detectServiceActive: true,
    allServiceFail: false, // 所有服务不可用

    status: null,
    showLogin: false,
    loginInfo: null,
    userInfo: null,
    logindata: {},
    loadingUserInfo: false,
    customizeCurrency: {}
    // OberloAeInfo:false
  },

  subscriptions: {
    setup({ dispatch }) {
      // 这几个path上的key不是woo key不能作为woo跳转的判断
      const notWooKeyPath = [
        '/app/agency-cost-management',
        '/app/agency-auth-callback',
        '/app/auth/callback'
      ];
      const wooKey = !notWooKeyPath.find(i => location.pathname.includes(i))
        ? qs.parse(location.search.slice(1)).key
        : undefined;
      console.log('wooKey: ', wooKey);

      const token = window.localStorage.getItem('dser-pro-authority');
      const wixToken = window.localStorage.getItem('authority_token');
      // 如果存在 token，等待尝试自动登录，渲染一个 loading，避免加载 login page，造成闪烁
      if (token) {
        dispatch({ type: 'queryUserInfoLoading', payload: true }); // 自动登录时不让用户看到登录界面
      }

      // 自动登录
      const autoLogin = () => {
        // const userData = getUserInfo();
        localStorage.removeItem('migration_stores');
        localStorage.removeItem('haveImportModalVisible');
        localStorage.removeItem('pushCheckStoreId');
        // 登录状态存留
        if (token) {
          localStorage.removeItem('selectStore');
          dispatch({ type: 'auth/migrationInfo' });
          dispatch({
            type: 'global/getGuideInfo'
          });
          dispatch({
            type: 'auth/getMigrationStoreInfo'
          });
          const setMigrationInfo = localStorage.getItem('setMigrationInfo');
          if (
            ![
              'STEP_STAGE_PRE',
              'STEP_STAGE_QUEUE',
              'STEP_STAGE_STEP1'
            ].includes(setMigrationInfo)
          ) {
            dispatch({
              type: 'topBtn/checkPlan',
              payload: {
                data: {
                  manual: 0
                }
              }
            });
          }
          dispatch({ type: 'updateUserInfo' });
          dispatch({ type: 'global/AEInfo' });
          dispatch({ type: 'user/getProject' });
          dispatch({ type: 'global/getAgencyList' });
          dispatch({ type: 'global/getWorldTelephoneAreaCode' });
          dispatch({ type: 'user/getNewInfo' });
          dispatch({ type: 'user/getCsvOrderList' });
          // dispatch({ type:'user/getNoviceGuidance'})

          // if (userData) {
          //   Detection(userData);
          //   dispatch({ type: 'setUserInfo', payload: userData });
          //   dispatch({ type: 'setLoginInfo', payload: true });
          // } else {
          //   // 当跳转授权（自动登录）时，获取不到 userInfo，此时需要重新获取
          //   dispatch({ type: 'updateUserInfo' });
          // }
        }
      };

      // 从 woo 跳转到 dsers 走自动登录逻辑
      function wooLogin(key) {
        localStorage.setItem('keyStatus', key);

        const obj = {};
        obj.key = String(key);

        fetch(`${window.DSERS_API_CONFIG.HOST}/api/v1/woo/token`, {
          method: 'POST',
          body: JSON.stringify({
            key: String(key)
          })
        })
          .then(res => res.json())
          .then(res => {
            if (res.code === 2000) {
              localStorage.setItem('dser-pro-authority', res.data.token);
              window.location.href = '/app/';
            }

            return null;
          })
          .catch(err => {
            console.log('wooToken', err);
          });
      }

      const handleLoginV2 = async () => {
        // 当前是否是shopify授权链接
        const isShopifyAuth =
          location.pathname.includes('/auth/redirect') ||
          location.pathname.includes('/auth/callback');
        // 是否是woo登录链接
        const isWooLoginUrl = !!wooKey;
        // 带有是否是wix授权回调
        const isWixAuth = qs
          .parse(location.search.slice(1))
          ?.redirect_uri?.includes('/web/auth-callback');
        // 是否是登录页
        const isLoginPage = window.location.pathname.includes('/app/login');
        // 重定向url
        const redirectUrl = qs.parse(location.search.slice(1))?.redirect_uri;

        if (isWooLoginUrl) {
          // 退登所有平台
          window.localStorage.removeItem('authority_token');
          window.localStorage.removeItem('dser-pro-authority');
          await logoutMvpAccount();

          // 使用key登录woo
          wooLogin(wooKey);
          return;
        }

        const loginInfo = await getLoginInfo();

        if(loginInfo.mgrStatus === 'MIGRATION_STATUS_MIGRATING' && window.location.pathname !== '/app/migrationWaiting'){

          if(isShopifyAuth){
            window.location.href = '/app/migrationWaiting?isAuth=1';
          } else {
            window.location.href = '/app/migrationWaiting';
          }
          return;

        }

        if(loginInfo?.mgrStatus === 'MIGRATION_STATUS_COMPLETED'){
          localStorage.removeItem('dser-pro-authority');
          localStorage.removeItem('authority_token');
          window.location.href =
            process.env.BUILD_TARGET === 'prod'
              ? 'https://www.dsers.com/application/'
              : 'https://dev.dsers.com/application/';
          return;
        }





        // 要去shopify授权 允许mvp账号及除wix外的老平台账号
        if (isShopifyAuth) {
          // 平台不对退登所有平台
          if (loginInfo.oldWixUser) {
            window.localStorage.removeItem('authority_token');
            window.localStorage.removeItem('dser-pro-authority');
            await logoutMvpAccount();
            window.location.href = '/app/auth-error';
          }
          return;
        }

        // 要去wix授权 只允许wix账号
        if (isWixAuth) {
          if (
            loginInfo.oldCsvUser ||
            loginInfo.oldShopifyUser ||
            loginInfo.oldWooUser ||
            loginInfo.mvpUser
          ) {
            // 平台不对退登所有平台
            window.localStorage.removeItem('authority_token');
            window.localStorage.removeItem('dser-pro-authority');
            await logoutMvpAccount();
            window.location.href = '/app/auth-error?type=platform-conflict';
            return;
          }

          // 登录了老账号
          if (loginInfo.oldUser) {
            // 已选wix平台
            window.localStorage.setItem(
              'authority_token',
              window.localStorage.getItem('dser-pro-authority') ||
                window.localStorage.getItem('authority_token')
            );
            window.localStorage.removeItem('dser-pro-authority');

            if (loginInfo.oldWixUser) {
              window.location.href = redirectUrl;
            } else {
              // 当前老账号不是wix 说明是个新账号 将平台设置为wix
              await setOldUserPlatformToWix();
              window.location.href = redirectUrl;
            }
            return;
          }
          // 未登录
          return;
        }

        // 登录了OPEN API账号
        if (loginInfo.mvpUser) {
          // 退出老平台账号
          window.localStorage.removeItem('authority_token');
          window.localStorage.removeItem('dser-pro-authority');
          // 如果仅有开发者权限就走开发者站点 否则走应用站点
          if (loginInfo.mvpRoleDeveloper && !loginInfo.mvpRoleDropshiper) {
            // 尝试同步登录态至开发者站
            await syncMvpLoginStatusToDevSite();
            window.location.href = mvpDeveloperSite;
          } else {
            window.location.href = mvpApplicationSite;
          }
          return;
        }

        // 登录了Wix账号 去wix站点
        if (loginInfo.oldWixUser) {
          window.localStorage.setItem(
            'authority_token',
            window.localStorage.getItem('dser-pro-authority') ||
              window.localStorage.getItem('authority_token')
          );
          window.localStorage.removeItem('dser-pro-authority');
          window.location.href = '/web/';
          return;
        }

        if (loginInfo.oldWooUser) {
          console.log(
            `Woo user, don't do any thing, it will be redirected in Shopify Basic Layout Component`
          );
        }

        // 以上情况都不满足就走老平台自动登录逻辑
        autoLogin();
      };

      const handleLogin = async () => {
        handleLoginV2();
        return;

        // if (wooKey) {
        //   // woo
        //   wooLogin(wooKey);
        // } else if (wixToken) {
        //   // 去 wix 自动登录
        //   // shopify 来凑什么热闹 ？？
        //   if (
        //     location.pathname.includes('/auth/redirect') ||
        //     location.pathname.includes('/auth/callback')
        //   ) {
        //     window.localStorage.removeItem('authority_token');
        //     window.location.href = '/app/auth-error';
        //   } else {
        //     const parse = qs.parse(location.search.slice(1));
        //     if (parse?.redirect_uri?.includes('/web/auth-callback')) {
        //       window.location.href = parse?.redirect_uri;
        //     } else {
        //       if (window.location.href.includes('/oberlo-signup')) {
        //         return;
        //       } else {
        //         window.location.href = '/web/';
        //       }
        //     }
        //   }
        // } else {
        //   const parse = qs.parse(location.search.slice(1));
        //   // 如果来自 wix 绑定流程
        //   if (parse?.redirect_uri?.includes('/web/auth-callback') && token) {
        //     try {
        //       // 检查平台
        //       const detectRes = await detectPlatform({
        //         data: { action_type: 1 }
        //       });

        //       if (detectRes && detectRes.code === 2000) {
        //         // 如果是wix 就跳转wix绑定授权流程
        //         if (
        //           detectRes.data?.action_operation === 'wix' ||
        //           detectRes.data?.action_operation === 'ebay'
        //         ) {
        //           window.localStorage.removeItem('dser-pro-authority');
        //           window.localStorage.setItem('authority_token', token);
        //           window.location.href = parse.redirect_uri;
        //         } else {
        //           // 再次检查平台选择 这里是为了兼容老用户数据 没有使用新接口选平台
        //           const userInfoRes = await queryCurrent();
        //           const userInfo = userInfoRes?.data || {};

        //           if (
        //             detectRes.data?.action_operation === 'woocommerce' &&
        //             `${userInfo.status}`.substring(2, 3) == 1 &&
        //             `${userInfo.status}`.substring(3, 4) == 0 &&
        //             userInfo?.woo_stores?.length
        //           ) {
        //             window.location.href = goToWoo;
        //             return;
        //           }

        //           // 如果已经是shopify用户或woo用户就提示平台冲突
        //           if (
        //             userInfo &&
        //             (`${userInfo.status}`.slice(2, 3) === '1' ||
        //               userInfo.stores?.length ||
        //               `${userInfo.status}`.slice(3, 4) === '1')
        //           ) {
        //             try {
        //               await postAuthWixStoreResult({
        //                 data: { auth_result: 1, failed_reason: 6 },
        //                 isOutApi: true
        //               });
        //             } catch (error) {
        //               console.log(error);
        //             }
        //             window.localStorage.removeItem('dser-pro-authority');
        //             window.location.href =
        //               '/app/auth-error?type=platform-conflict';
        //           } else {
        //             // 不是shopify或woo用户 直接为用户选择wix平台然后进入wix授权流程
        //             const updateRes = await updatePlatform({
        //               data: {
        //                 action_operation: 'wix',
        //                 action_type: 1
        //               }
        //             });
        //             if (updateRes && updateRes.code === 2010) {
        //               window.localStorage.removeItem('dser-pro-authority');
        //               window.localStorage.setItem('authority_token', token);
        //               window.location.href = parse.redirect_uri;
        //             } else {
        //               throw updateRes;
        //             }
        //           }
        //         }
        //       }
        //     } catch (error) {
        //       console.log(error);
        //     }
        //   } else {
        //     if (
        //       window.location.href.includes('/oberlo-signup') ||
        //       location.pathname.includes('/auth/redirect') ||
        //       location.pathname.includes('/auth/callback')
        //     ) {
        //       return;
        //     } else {
        //       autoLogin();
        //     }
        //   }
        // }
      };

      // 阿里云服务不稳定，这里需要验证服务是否可用，取一个可用的 api 服务
      const detectionService = () => {
        console.log('开始检测 api service');

        const exceptionTask = [];

        const services = [
          'https://ac.dsers.com',
          'https://a.dsers.com',
          'https://ac-01.dsers.com',
          'https://ac-02.dsers.com'
          // 'https://ac-03.dsers.com'
        ];

        const logMsg = data => {
          if (typeof window.ga !== 'undefined') {
            window.ga('send', 'event', 'api-service-exception', data);
          }

          recordLog({
            data: {
              payload: data
            }
          }).catch(err => {
            console.log('logService exception:', err);
          });
        };

        const task = url => {
          return new Promise(function(resolve, reject) {
            let done = false;
            const xhr = new window.XMLHttpRequest();
            xhr.onreadystatechange = function() {
              if (this.readyState === 4 && !done) {
                done = true;
                if (xhr.response && xhr.response === 'pong') {
                  // console.log('请求结果', xhr.response);
                  resolve(url);
                } else {
                  reject(new Error(xhr.response));
                }
              }
            };

            xhr.onabort = function(e) {
              console.error('onabort', e);
              const msg = `${e.type}: ${e.loaded} bytes transferred. onabort. from ${url}`;
              exceptionTask.push(msg);

              if (!done) {
                done = true;
                reject(new Error(msg));
              }
            };

            xhr.onerror = function(e) {
              console.error('onerror', e);
              const msg = `${e.type}: ${e.loaded} bytes transferred. onerror. from ${url}`;
              exceptionTask.push(msg);

              if (!done) {
                done = true;
                reject(new Error(msg));
              }
            };

            xhr.ontimeout = function(e) {
              console.error('ontimeout', e);
              const msg = `${e.type}: ${e.loaded} bytes transferred. timeout. from ${url}`;
              exceptionTask.push(msg);

              if (!done) {
                done = true;
                reject(new Error(msg));
              }
            };

            try {
              // eslint-disable-next-line
              xhr.open('GET', url + '/ping');
              xhr.timeout = 30000; // time in milliseconds
              xhr.responseType = 'text';
              xhr.send();
            } catch (e) {
              console.error('XMLHttpRequest catch', e);
              logMsg(JSON.stringify(e));
              if (!done) {
                done = true;
                reject(e);
              }
            }
          });
        };

        const fetchTasks = services.map(i => task(i));

        // 取任意一个
        Promise.any(fetchTasks)
          .then(function(res) {
            if (res) {
              window.DSERS_API_CONFIG.HOST = res;

              if (typeof window.ga !== 'undefined') {
                window.ga('send', 'event', 'api-service', res);
              }

              // 留一个后门用于测试
              const test = window.localStorage.getItem('ds_host_test');
              if (test) {
                window.DSERS_API_CONFIG.HOST = test;
              }
              dispatch({ type: 'startDetectService', payload: false });
              console.log('HOST', window.DSERS_API_CONFIG.HOST);
              handleLogin();
            }
            return res;
          })
          .catch(error => {
            console.log('所有服务都不可用', error);
            dispatch({ type: 'detectServiceFail', payload: true });
            logMsg({ status: '没有任何一个服务可用', error });
          });

        // 待所有完成之后上报异常
        // eslint-disable-next-line
        Promise.allSettled(fetchTasks).then(results => {
          console.log('所有服务检测完成', results);
          setTimeout(() => {
            exceptionTask.forEach(exc => {
              logMsg(exc);
            });
          });
          return results;
        });
      };

      // 仅正式环境需要检测
      if (process.env.BUILD_TARGET === 'prod') {
        dispatch({ type: 'startDetectService', payload: true });
        detectionService();
      } else {
        dispatch({ type: 'startDetectService', payload: false });
        handleLogin();
      }
    }
  },

  effects: {
    *login({ payload: { resolve, reject, ...params } }, { call, put }) {
      localStorage.removeItem('ifMigration');
      localStorage.removeItem('logoutEmail');
      localStorage.removeItem('pushCheckStoreId');
      window.localStorage.removeItem('store_is_available');
      window.localStorage.removeItem('EnterNewHomeTime');
      window.localStorage.removeItem('setMigrationInfo');
      window.localStorage.removeItem('noMigra');
      window.localStorage.removeItem('keyStatus');
      window.localStorage.removeItem('close_tip');
      localStorage.removeItem('migration_stores');

      let newLoginFailed = null;

      try {
        const mvpLoginResponse = yield call(loginToMvpAccount, params.data);
        // console.log(mvpLoginResponse, params);
        if (!mvpLoginResponse.code && mvpLoginResponse.data) {
          yield call(syncMvpLoginStatusToDevSite);
          const isDeveloper = mvpLoginResponse.data?.roles?.includes?.(
            'ROLE_TYPE_PLATFORM_DEVELOPER'
          );
          const isDropshipper = mvpLoginResponse.data?.roles?.includes?.(
            'ROLE_TYPE_PLATFORM_DROP_SHIPPER'
          );
          if (isDeveloper && !isDropshipper) {
            window.location.href = mvpDeveloperSite;
          } else {
            window.location.href = mvpApplicationSite;
          }
          return;
        } else {
          newLoginFailed = mvpLoginResponse || {};
        }
      } catch (error) {}

      try {
        const response = yield call(fakeAccountLogin, params);
        yield put({
          type: 'global/AEInfo'
        });
        if (response && response.code === 2000 && response.data.access_token) {
          window.localStorage.setItem('woosync', true);
          localStorage.setItem(
            'dser-pro-authority',
            response.data.access_token
          );


          const mgrStatusRes = yield call(getBannerStatus);

          if(mgrStatusRes?.data?.status === 'MIGRATION_STATUS_MIGRATING'){
            window.location.href = '/app/migrationWaiting';
            return;
          }

          if(mgrStatusRes?.data?.status === 'MIGRATION_STATUS_COMPLETED'){
            localStorage.removeItem('dser-pro-authority');
            localStorage.removeItem('authority_token');
            window.location.href =
              process.env.BUILD_TARGET === 'prod'
                ? 'https://www.dsers.com/application/'
                : 'https://dev.dsers.com/application/';
            return;
          }


          const detectRes = yield call(detectPlatform, {
            data: { action_type: 1 }
          });

          yield put({
            type: 'auth/migrationInfo'
          });
          yield put({
            type: 'global/getGuideInfo'
          });
          yield put({
            type: 'auth/getMigrationStoreInfo'
          });
          yield put({
            type: 'user/getCsvOrderList'
          });

          yield put({
            type: 'global/getAgencyList'
          });

          yield put({
            type: 'global/getWorldTelephoneAreaCode'
          });

          // const res = yield call(queryUserInfo);
          const setMigrationInfo = localStorage.getItem('setMigrationInfo');
          if (
            ![
              'STEP_STAGE_PRE',
              'STEP_STAGE_QUEUE',
              'STEP_STAGE_STEP1'
            ].includes(setMigrationInfo)
          ) {
            yield put({
              type: 'topBtn/checkPlan',
              payload: {
                data: {
                  manual: 0
                }
              }
            });
          }
          // 登录成功之后涉及到检查用户状态，因此用 queryUserInfo
          const res = yield call(queryUserInfo);

          // 判断woo用户在shopify登录自动跳转到woo
          if (detectRes && detectRes.code === 2000) {
            if (
              detectRes.data?.action_operation === 'woocommerce' &&
              `${res.status}`.substring(2, 3) == 1 &&
              `${res.status}`.substring(3, 4) == 0 &&
              res?.woo_stores?.length
            ) {
              window.location.href = goToWoo;
              return;
            }

            if (
              detectRes.data?.action_operation === 'wix' ||
              detectRes.data?.action_operation === 'ebay'
            ) {
              window.localStorage.removeItem('dser-pro-authority');
              window.localStorage.setItem(
                'authority_token',
                response.data.access_token
              );

              const parse = qs.parse(location.search.slice(1));
              if (parse && parse.from) {
                window.location.href = parse.from;
              } else {
                window.location.href = '/web/';
              }
              return;
            }
          }

          const newUserRes = yield call(queryNewInfo);

          if (newUserRes) {
            yield put({
              type: 'user/setNewUserInfo',
              payload: newUserRes.data
            });
          }

          if (res) {
            yield put({
              type: 'setUserInfo',
              payload: res
            });

            yield put({
              type: 'global/AEInfo'
            });

            yield put({
              type: 'user/getProject'
            });

            resolve();
            yield put({
              type: 'setLoginInfo',
              payload: response.data
            });
            yield put(routerRedux.replace('/'));
          } else {
            reject('users/info 接口返回错误');
          }
        } else {

          const newPlatformNotFound = newLoginFailed?.reason === 'ACCOUNT_NOT_EXIST';
          if(response?.msg === 'user is migration' && !newPlatformNotFound) {
            try {
              response.msg = newLoginFailed?.reason || newLoginFailed?.message || 'UNKNOWN ERROR';
            } catch (error) {
              console.log(error);
            }
            
          }

          // 这个只是错误码
          yield put({
            type: 'changeLoginStatus',
            payload: response
          });
          reject(response);
          if (response?.data?.msg) {
            if (response.data.msg?.indexOf('password error') === -1) {
              if (typeof response.data.msg === 'object') {
                captureMessage(`[login] ${JSON.stringify(response.data.msg)}`);
              } else {
                captureMessage(`[login] ${response.data.msg}`);
              }
            }
          } else {
            captureMessage(
              `[login] [response] ${JSON.stringify(response) || 'exception'}`
            );
          }
        }
      } catch (error) {
        reject(error);
      }
    },

    // 授权回调登录，仅设置 token
    *pureLogin(
      { payload: { resolve, reject, redirectUrl, ...params } },
      { call, put }
    ) {
      localStorage.removeItem('ifMigration');
      localStorage.removeItem('logoutEmail');

      const isInWixAuth = redirectUrl?.redirect_uri?.includes?.(
        '/web/auth-callback'
      );

      const isInOldShopifyAuth = redirectUrl?.redirect_uri?.includes?.(
        'myshopify.com'
      );

      const isInOldShopifyAuthRedirect = !!redirectUrl?.shopify_auth_redirect_uri;
      
      let newLoginFailed = null;

      try {
        const mvpLoginResponse = yield call(loginToMvpAccount, params.data);
        // console.log(mvpLoginResponse, params);
        if (!mvpLoginResponse.code && mvpLoginResponse.data) {
          // wix授权或者老shopify授权流程 却登录了个mvp账号 不许继续流程并退登
          if (isInWixAuth) {
            yield call(logoutMvpAccount);
            const res = {
              code: 5000,
              msg: `Due to DSers system upgrade, this account cannot be linked to [${
                isInWixAuth ? 'Wix' : 'Shopify'
              }] for the time being, please register a new account.`
            }
            reject(res);
            yield put({
              type: 'changeLoginStatus',
              payload: res
            });
            return;
          }

          if(isInOldShopifyAuth) {
            // 是否登录老账号时从/app/auth/redirect页面选择切换账号
            // 如果不是这种情况的就是从callback页面来的
            if(!isInOldShopifyAuthRedirect) {
              const oldAuthParamsString = redirectUrl?.redirect_uri?.slice(redirectUrl?.redirect_uri?.indexOf('?') + 1);
              const oldAuthParamsObj = qs.parse(oldAuthParamsString);
              // url上有key 说明已经换过一次了 此情况应该极少出现
              const shopifyGeneralAuthKey = oldAuthParamsObj.key || (yield call(exchangeShopifyAuthParamsToAuthKey, oldAuthParamsString));
              // 在老授权流程中登录了一个新账号（Open API），此时将老平台授权参数转换为新老平台通用的key，并跳转到新平台去完成授权
              if(shopifyGeneralAuthKey) {
                yield call(syncMvpLoginStatusToDevSite);
                window.location.href = getOpenAPIShopifyAppKeyRedirectUrl(shopifyGeneralAuthKey);
              
              } else {
                yield call(logoutMvpAccount);
                const res = {
                  code: 5000,
                  msg: `Due to DSers system upgrade, this account cannot be linked to [${
                    isInWixAuth ? 'Wix' : 'Shopify'
                  }] for the time being, please register a new account.`
                }
                reject(res);
                yield put({
                  type: 'changeLoginStatus',
                  payload: res
                });
              }
            
            // 从redirect页面来的
            } else {
              const redirectParams = 
                redirectUrl?.shopify_auth_redirect_uri?.slice(
                  redirectUrl?.shopify_auth_redirect_uri?.indexOf('?') + 1
                );
                yield call(syncMvpLoginStatusToDevSite);
                window.location.href = getOpenAPIShopifyRedirectUrl(redirectParams);
              
            }

            return;
          }

          yield call(syncMvpLoginStatusToDevSite);
          const isDeveloper = mvpLoginResponse.data?.roles?.includes?.(
            'ROLE_TYPE_PLATFORM_DEVELOPER'
          );
          const isDropshipper = mvpLoginResponse.data?.roles?.includes?.(
            'ROLE_TYPE_PLATFORM_DROP_SHIPPER'
          );
          if (isDeveloper && !isDropshipper) {
            window.location.href = mvpDeveloperSite;
          } else {
            window.location.href = mvpApplicationSite;
          }
          return;
        } else {
          newLoginFailed = mvpLoginResponse || {};
        }
      } catch (error) {
        console.log(error);
      }

      try {
        const response = yield call(fakeAccountLogin, params);

        if (response && response.code === 2000 && response.data.access_token) {
          localStorage.setItem(
            'dser-pro-authority',
            response.data.access_token
          );

          const mgrStatusRes = yield call(getBannerStatus);

          if(mgrStatusRes?.data?.status === 'MIGRATION_STATUS_MIGRATING'){

            if(isInOldShopifyAuth){
              window.location.href = '/app/migrationWaiting?isAuth=1';
            }else {
              window.location.href = '/app/migrationWaiting';
            }
            return;
          }


          if(mgrStatusRes?.data?.status === 'MIGRATION_STATUS_COMPLETED'){
            localStorage.removeItem('dser-pro-authority');
            localStorage.removeItem('authority_token');
            window.location.href =
              process.env.BUILD_TARGET === 'prod'
                ? 'https://www.dsers.com/application/'
                : 'https://dev.dsers.com/application/';
            return;
          }



          const detectRes = yield call(detectPlatform, {
            data: { action_type: 1 }
          });
          // 登录了wix账号
          if (
            detectRes &&
            detectRes.code === 2000 &&
            detectRes.data &&
            (detectRes.data.action_operation === 'wix' ||
              detectRes.data.action_operation === 'ebay')
          ) {
            window.localStorage.removeItem('dser-pro-authority');
            // 有可能来自 shopify
            if (redirectUrl.redirect_uri.includes('myshopify.com')) {
              window.location.href = '/app/auth-error';
            } else {
              window.localStorage.setItem(
                'authority_token',
                response.data.access_token
              );
              window.location.href = redirectUrl.redirect_uri;
            }
          } else {
            // 登录的账号没有绑定平台或者是老账户
            // 如果不是woo/shopify/csv 并且登录来自wix绑定流程 就自动选择wix平台
            // eslint-disable-next-line
            const userInfo = (yield call(queryCurrent))?.data;
            if (redirectUrl.redirect_uri.includes('/web/auth-callback')) {
              if (
                userInfo &&
                `${userInfo.status}`.slice(2, 3) !== '1' &&
                `${userInfo.status}`.slice(3, 4) !== '1' &&
                !userInfo.stores?.length
              ) {
                updatePlatform({
                  data: {
                    action_operation: 'wix',
                    action_type: 1
                  }
                })
                  .then(res => {
                    if (res && res.code === 2010) {
                      window.localStorage.removeItem('dser-pro-authority');
                      window.localStorage.setItem(
                        'authority_token',
                        response.data.access_token
                      );
                      window.location.href = redirectUrl.redirect_uri;
                    }
                    return null;
                  })
                  .catch(err => {
                    console.log(err);
                  });
              } else if (userInfo) {
                try {
                  yield call(postAuthWixStoreResult, {
                    data: { auth_result: 1, failed_reason: 6 },
                    isOutApi: true
                  });
                } catch (error) {
                  console.log(error);
                }
                window.localStorage.removeItem('dser-pro-authority');
                window.location.href = '/app/auth-error?type=platform-conflict';
              }
            } else {
              window.location.href = redirectUrl.redirect_uri;
            }
          }
          resolve();
        } else {
          const newPlatformNotFound = newLoginFailed?.reason === 'ACCOUNT_NOT_EXIST';

          if(response?.msg === 'user is migration' && !newPlatformNotFound) {
            try{
              response.msg = newLoginFailed?.reason || newLoginFailed?.message || 'UNKNOWN ERROR';
            }catch(e) {

            }
          }

          yield put({
            type: 'changeLoginStatus',
            payload: response
          });
          reject(response);
        }
      } catch (error) {
        reject(error);
      }
    },

    *migrationLogin(
      { payload: { resolve, reject, ...params } },
      { call, put }
    ) {
      localStorage.removeItem('ifMigration');
      localStorage.removeItem('logoutEmail');
      window.localStorage.removeItem('store_is_available');
      window.localStorage.removeItem('EnterNewHomeTime');
      window.localStorage.removeItem('setMigrationInfo');
      window.localStorage.removeItem('noMigra');
      window.localStorage.removeItem('keyStatus');
      window.localStorage.removeItem('close_tip');
      localStorage.removeItem('migration_stores');
      try {
        const response = yield call(fakeMigraLogin, params);

        if (response && response.code === 2000 && response.data.access_token) {
          window.localStorage.setItem('woosync', true);
          localStorage.setItem(
            'dser-pro-authority',
            response.data.access_token
          );

          //从新登录进来的 需要上报
          const res = yield call(reportAccout, {
            data: {
              shopify_domain: params.shopname
            }
          });
          if (res.code == 200 || res.reason == 'STORE_IN_MIGRATING') {
            Modal.error({
              title: intl.get('migration.waitmigration_title'),
              content: intl.get('migration.waitmigration_text'),
              okText: 'GOT IT',
              onOk: () => {
                window.location.href = '/app/';
              }
            });
          } else {
            const detectRes = yield call(detectPlatform, {
              data: { action_type: 1 }
            });

            if (detectRes && detectRes.code === 2000) {
              if (
                detectRes.data?.action_operation === 'wix' ||
                detectRes.data?.action_operation === 'ebay'
              ) {
                window.localStorage.removeItem('dser-pro-authority');
                window.localStorage.setItem(
                  'authority_token',
                  response.data.access_token
                );

                const parse = qs.parse(location.search.slice(1));
                if (parse && parse.from) {
                  window.location.href = parse.from;
                } else {
                  window.location.href = '/web/';
                }
                return;
              }
            }

            // 登录成功之后涉及到检查用户状态，因此用 queryUserInfo

            yield put({
              type: 'auth/migrationInfo'
            });
            yield put({
              type: 'global/getGuideInfo'
            });
            yield put({
              type: 'auth/getMigrationStoreInfo'
            });
            const setMigrationInfo = localStorage.getItem('setMigrationInfo');
            if (
              ![
                'STEP_STAGE_PRE',
                'STEP_STAGE_QUEUE',
                'STEP_STAGE_STEP1'
              ].includes(setMigrationInfo)
            ) {
              yield put({
                type: 'topBtn/checkPlan',
                payload: {
                  data: {
                    manual: 0
                  }
                }
              });
            }
            const res = yield call(queryUserInfo);
            const newUserRes = yield call(queryNewInfo);

            if (newUserRes) {
              yield put({
                type: 'user/setNewUserInfo',
                payload: newUserRes.data
              });
            }

            if (res) {
              yield put({
                type: 'setUserInfo',
                payload: res
              });

              yield put({
                type: 'global/AEInfo'
              });

              yield put({
                type: 'user/getProject'
              });

              resolve();
              yield put({
                type: 'setLoginInfo',
                payload: response.data
              });
              yield put(routerRedux.replace('/'));
            } else {
              reject('users/info 接口返回错误');
            }
          }
        } else {
          // 这个只是错误码
          yield put({
            type: 'changeLoginStatus',
            payload: response
          });
          reject(response);
          if (response?.data?.msg) {
            if (response.data.msg?.indexOf('password error') === -1) {
              if (typeof response.data.msg === 'object') {
                captureMessage(`[login] ${JSON.stringify(response.data.msg)}`);
              } else {
                captureMessage(`[login] ${response.data.msg}`);
              }
            }
          } else {
            captureMessage(
              `[login] [response] ${JSON.stringify(response) || 'exception'}`
            );
          }
        }
      } catch (error) {
        reject(error);
      }
    },

    /**
     * 这里不做 Detection，在 BaseLayout 中做检查
     *
     * @param {*} _
     * @param {*} { call, put }
     * @returns
     */
    *updateUserInfo(_, { call, put }) {
      try {
        const res = yield call(queryUserInfo);
        if (res) {
          yield put({
            type: 'setUserInfo',
            payload: res
          });
          let chooseStore = res.stores.filter(item => {
            return item.id == customizeUserMessage.store_id;
          })[0];
          if (chooseStore) {
            let result = yield call(getCurrenciesCustom, {
              data: {
                store_id: chooseStore.id,
                currency: chooseStore?.ali_currency || 'KWD'
              }
            });
            yield put({
              type: 'setCustomizeCurrency',
              payload: {
                id: customizeUserMessage.id,
                storeId: customizeUserMessage.store_id,
                currencyType: chooseStore.ali_currency,
                currency: result?.data?.exchange_rate || 0
              }
            });
          }
          yield put({
            type: 'setLoginInfo',
            payload: true
          });
        }
      } catch (error) {
        console.log('updateUserInfo error', error);
      }

      yield put({ type: 'queryUserInfoLoading', payload: false });
    },

    *ali({ payload }, { call, put }) {
      const response = yield call(aliLogin, payload);
      if (response.code === 1) {
        localStorage.setItem('dser-pro-authority', response.data.token);
        window.location = '/app/';
      } else {
        yield put({
          type: 'aliLoginStatus',
          payload: response
        });
      }
    },

    *captcha({ payload }, { call, put }) {
      const response = yield call(getCaptcha, payload);
      yield put({
        type: 'changeCaptcha',
        payload: response
      });
    },

    // eslint-disable-next-line
    *logout(_, { call, put }) {
      // yield put({
      //   type: 'setLoginInfo',
      //   payload: null
      // });
      // yield put({
      //   type: 'setUserInfo',
      //   payload: null
      // });
      const res = yield call(getMigrationInfo);
      if (res.is_migrating) {
        window.localStorage.removeItem('close_tip');
      }
      window.localStorage.removeItem('closeAppointmentTip');
      window.localStorage.removeItem('noMigra');
      window.localStorage.removeItem('store_is_available');
      window.localStorage.removeItem('EnterNewHomeTime');
      localStorage.removeItem('migration_stores');

      window.localStorage.removeItem('ds_host');
      window.localStorage.removeItem('dser-pro-authority');
      window.localStorage.removeItem('store');
      window.localStorage.removeItem('storeId');
      window.localStorage.removeItem('payment');
      window.localStorage.removeItem('keyStatus');
      window.localStorage.removeItem('pushCheckStoreId');
      window.localStorage.removeItem('newuser_saw_pricing_mean');
      window.localStorage.removeItem('saw_pricing_mean');
      window.localStorage.removeItem('keyStatus');
      sessionStorage.removeItem('newUser');
      window.localStorage.removeItem('newUserInfo');
      window.localStorage.removeItem('ifMigration');
      window.localStorage.removeItem('logoutEmail');
      window.localStorage.removeItem('authAgain');
      window.localStorage.removeItem('AeCancelTipHidden');
      if (window.location.pathname.indexOf('/app/login') > -1) {
        return;
      }

      if (window.location.pathname.indexOf('/app/') > -1) {
        window.location.href = '/'; // 刷新一下，用于清除 redux store
      }

      window.localStorage.removeItem(AGENCY_LOCAL_STORE_EXPORT_TASK_KEY);
      window.localStorage.removeItem(AGENCY_LOCAL_STORE_UPLOAD_TASK_KEY);
    },

    *channel({ payload }, { call }) {
      yield call(getChannel, payload);
    },

    *googleStep2({ payload }, { call, put }) {
      const response = yield call(googleStop2Verify, payload);

      if (response.code === 1 && response.data.token) {
        localStorage.setItem('dser-pro-authority', response.data.token);
        window.location = '/app/';
      } else {
        yield put({
          type: 'setStep2',
          payload: response
        });
      }
    },
    *sendResetEmail({ payload }, { call }) {
      yield call(getResetPassword, payload);
    },
    *resetPassword({ payload }, { call }) {
      yield call(postResetPassword, payload);
    },
    *clearLoginEffect() {
      yield call(clearLoginData, {});
    }
  },

  reducers: {
    // 设置登录错误信息
    changeLoginStatus(state, { payload }) {
      return {
        ...state,
        logindata: payload,
        status: payload.code,
        msg: payload.msg,
        showVerification: payload.data
      };
    },
    changeCaptcha(state, { payload }) {
      return {
        ...state,
        captcha: payload
      };
    },
    changetestLoginStatus(state) {
      return {
        ...state,
        showLogin: true
      };
    },
    setStep2(state, { payload }) {
      return {
        ...state,
        step2Data: payload
      };
    },
    clearLoginData(state) {
      return {
        ...state,
        logindata: null
      };
    },
    setLoginInfo(state, { payload }) {
      return {
        ...state,
        loginInfo: payload
      };
    },
    setUserInfo(state, { payload }) {
      return {
        ...state,
        userInfo: payload
      };
    },
    setCustomizeCurrency(state, { payload }) {
      return {
        ...state,
        customizeCurrency: payload
      };
    },
    queryUserInfoLoading(state, { payload }) {
      return {
        ...state,
        loadingUserInfo: payload
      };
    },
    startDetectService(state, { payload }) {
      return {
        ...state,
        detectServiceActive: payload
      };
    },
    detectServiceFail(state, { payload }) {
      return {
        ...state,
        allServiceFail: payload
      };
    }
    // setOberloAeInfo(state, { payload }){
    //   return {
    //     ...state,
    //     OberloAeInfo: payload
    //   };
    // }
  }
};
