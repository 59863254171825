import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Icon } from 'antd';

import styles from './Anniversary.less';
import useOnlineNotice from '../../hooks/useOnlineNotice';
import banner1 from '../../assets/anniversary/AE-328-en.png';
import banner2 from '../../assets/anniversary/AE-328-fr.png';
import banner3 from '../../assets/anniversary/AE-328-p.png';

const onlineDate = new Date(2021, 2, 28); // 上线日期
const modalRoot = document.getElementById('root');

function Anniversary({ lang }) {
  const [visible, closeNotice] = useOnlineNotice('anniversary', onlineDate, 2);
  const [showText, setShowText] = useState(true);
  const [timing, setTiming] = useState(5);
  const timingRef = useRef(null);

  useEffect(() => {
    timingRef.current = setInterval(() => {
      setTiming(state => {
        if (state === 1) {
          setShowText(false);
          clearInterval(timingRef.current);
          closeNotice();
          return 0;
        }
        return state - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timingRef.current);
    };
  }, []);

  if (!visible) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.notice}>
      <div className={styles.body}>
        {lang === 'en-US' && <img className={styles.bg} src={banner1} />}
        {lang === 'fr-FR' && <img className={styles.bg} src={banner2} />}
        {lang === 'pt-PT' && <img className={styles.bg} src={banner3} />}
        <Icon
          type="close"
          className={styles.close}
          onClick={() => closeNotice()}
        />
        {showText && lang !== 'fr-FR' && (
          <div className={styles.content}>
            <span className={styles.text}>
              Close in {timing === 0 ? '' : `${timing}`} seconds
            </span>
          </div>
        )}
        {showText && lang === 'fr-FR' && (
          <div className={styles.content}>
            <span className={styles.text}>
              Ferme dans {timing === 0 ? '' : `${timing}`} secondes
            </span>
          </div>
        )}
      </div>
    </div>,
    modalRoot
  );
}

export default Anniversary;
