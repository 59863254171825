import React from 'react';
import { connect } from 'dva';
import { Icon, Input, message, Tooltip } from 'antd';

import className from 'classnames';
import intl from 'react-intl-universal';

import { OrderAction, GuildeAction } from 'actions';

import orderStyles from '../Order.less';
import '../Order.css';

import { notEditOrderStatus, getCanceled } from '../util.js'; // notEditOrderStatus不能修改订单的状态

import { CommonDataCollect } from 'utils/utils';
@connect(({ order, loading, login }) => ({
  order,
  loading: loading.models.order,
  userInfo:login.userInfo
}))
export default class No extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showEditOrderNo: props.showEditaddress,
      showSyncOrderNo:true,
      async: false
    };
    this.orderOneRef = React.createRef(null);
  }

  componentDidMount() {
    // this.props.onRef(this);
  }

  componentWillReceiveProps(nextsProps) {
    const {
      listData: { showSave }
    } = nextsProps;
    const { showEditOrderNo } = this.state;
    
    this.setState({
      showEditOrderNo: showEditOrderNo
    });
  }

  orderNo = ''; // 用户输入的订单号
  orderNoIsRight = true; // 没有返回订单号信息时设为false 说明用户输入的订单号不对 用户从新输入订单号设为true 可以从新获取ae订单号详情
  trackNo = [];
  trackNoByAE = []; // 获取ae订单详情里的物流单号
  getedOrderNo = '';
  cacheTrackNo = []; // [ 12, 34 ]
  cacheTrackNoByAE = []; // [ { logistics_no: 12 }, { logistics_no: 34 } ]

  showAddOrderAndTrack = type => {
    this.orderNoIsRight = true;
    setTimeout(() => { 
      this.orderOneRef.current.focus()
    })
   
   //检查是否属于不使用ae用户
   if(this.props.userInfo.ae_flag && !window.IS_BIND_AE){
    window.EVENT.emit(OrderAction.AE_BIND_MODAL,true)
    return
  }

    // blerlo  未绑定AE用户 - 点击Ali Order No （已经有逻辑不重复展示）
    if (!window.IS_BIND_AE) {
      window.EVENT.emit(
        GuildeAction.OblerloGuide_Pop,
        `AE-${Date.now()}`,
        true
      );
      return;
    }
    if (type == 'showEditOrderNo' && !window.IS_BIND_AE) {
      window.EVENT.emit(OrderAction.IS_BIND_AE_MODAL, true)
      return
    }
    this.setState({
      [type]: true
    });
  };

  // 改变ae订单号
  changeOrderNO = (e, type) => {
    let v = e.target.value;
    v = v.replace(/\s+/g, '');

    this[type] = v;
    this.orderNoIsRight = true;

    // 用户输入的订单号和ae返回的一样
    if (v == this.getedOrderNo && v) {
      this.trackNoByAE = this.cacheTrackNoByAE;
      this.trackNo = this.cacheTrackNo;
      this.saveOrderNoAndTrackNo('orderNo', v);
      this.saveOrderNoAndTrackNo('trackNo', this.cacheTrackNo);
      console.log('1')
    } 

    // else {
    //   this.trackNoByAE = [];
    //   this.trackNo = [];
    //   this.saveOrderNoAndTrackNo('orderNo', '');
    //   this.saveOrderNoAndTrackNo('trackNo', []);
    //   console.log('2')
    // }

    this.forceUpdate();
  };

  // 之前可以编辑物流单号 后来去掉了
  changeTrackNo = (e, type) => {
    let v = '';

    v = e.target.value.split('\n').filter(no => {
      return no.replace(/\s+/g, '');
    });

    this[type] = v;
    this.saveOrderNoAndTrackNo(type);
  };

  saveOrderNoAndTrackNo = (type, v) => {
    const { updataExt, listData, index, sonIndex } = this.props;
    updataExt({
      type,
      oid: listData.id,
      index,
      sonIndex,
      v: v != undefined ? v : this[type],
      trackNoByAE: this.trackNoByAE
    });
  };

  // 获取用户输入的ae订单号详情
  getAEOrderDetail = (sku, productId) => {

    console.log('获取详情', this.props)

    const { listData } = this.props;
    const { storeId, detail: { id } } = listData
    CommonDataCollect({
      event_type: 'subscription_analysis',
      action: 'update_ali_order_no',
      store_id: storeId,
      sale_platform: 'shopify',
      sale_platform_product_id: id
    })

    const v = this.orderNo;
    if (v.length < 12 || !Number(v) || v == this.getedOrderNo) {
      this.orderNoIsRight = false;
      this.orderOneRef.current.focus()
      message.error(
        'Ali Order No not found，please check again.'
      );
      return;
    }

    const lineItemId = this.props.child.id;
    window.SYNC_SHOW_BTN = true

    this.props.dispatch({
      type: 'order/orderNoDetail',
      payload: {
        data: {
          order_id: this.props.listData.id,
          order_no: v,
          store_id: this.props.listData.storeId
        },
        callback: d => {
          if (d.code == 2000) {
            this.useOrderNoDetail(v, d.data);
            this.props.changeAsync(sku, productId, lineItemId);
            
          }
        }
      }
    });
  };

  // 判断订单号是否正确 是否返回物流单号
  useOrderNoDetail = (orderNo, detail = {}) => {
    const data = detail[orderNo];
    if (!data) {
      message.error(
        'Ali Order No not found，please check again.'
      );
      this.orderNoIsRight = false; // 获取ae订单号失败
      this.orderOneRef.current.focus()
      this.props.changeOrderNoState();
      return;
    }

    this.getedOrderNo = orderNo;
    this.saveOrderNoAndTrackNo('orderNo');

    const {
      logistics_info_list: { aeop_order_logistics_info = [] }
    } = data;
    const arr = []; // [ 12, 34 ]
    const arrObj = []; // [ { logistics_no: 12 }, { logistics_no: 34 } ]

    aeop_order_logistics_info.forEach(item => {
      arr.push(item.logistics_no);
      arrObj.push(item);
    });


    this.orderNoIsRight = true;
    this.trackNo = arr;
    this.trackNoByAE = arrObj;

    if (arr.length) {
      this.cacheTrackNo = arr;
      this.cacheTrackNoByAE = arrObj;
      this.saveOrderNoAndTrackNo('trackNo');
    }

    const { listData } = this.props;
    this.saveOrderNoAndTrackNo('trackNoSave', listData.id);
  };

  // 获取物流单号
  getTrackNo = logistics_infos => {
    const ids = logistics_infos || this.trackNoByAE;

    if (!ids || ids.length == 0) {
      return undefined;
    }

    const arr = [];
    ids.forEach(item => {
      item.logistics_no && arr.push(item.logistics_no);
    });

    return arr.join('\n');
  };


  // 展示物流单单号
  editBtn = (id, detail, child, noAeNumber) => {

    const { status }  = this.props
    let ids = id || this.trackNoByAE;
    let flag = false;


    if(!ids.length){
      flag = true
    }

    ids?.map(KKitem => {
      if(!KKitem?.hasOwnProperty('logistics_no')){
        flag = true
      }
    })

    let newArr = []
    let toNew = []


    if(flag && status == 5){
      detail?.fulfillments?.map(fulItem => {
        fulItem.line_items?.map(lineI => {
          if(lineI.id == child.id){
            newArr.push({
              logistics_no: fulItem.tracking_numbers
            })
          }
        })
      })
      ids = newArr
    }


    //如果没有ae order number
    if(noAeNumber && status == 5){
      detail?.fulfillments?.map(fulItem => {
        fulItem.line_items?.map(lineI => {
          if(lineI.id == child.id){
            toNew.push({
              logistics_no: fulItem.tracking_numbers
            })
          }
        })
      })
      ids = toNew
    }


    return (
      <>
        <div className={orderStyles.trackNoList}>
          <div>
            {ids.map(item => {
              if(item?.logistics_no?.length < 20){
                return <p className={orderStyles.trackNoListp} key={item?.logistics_no}>{item?.logistics_no}</p>;
              }else{
                return <Tooltip title={item?.logistics_no}>
                  <p className={orderStyles.trackNoListTooltip} key={item?.logistics_no}>{item?.logistics_no}</p>
                </Tooltip>;
              }
            })}
          </div>
        </div>
      </>
    );
  };

  addBtn = type => {
    const { child = {}, useSp, status } = this.props;
    if (
      notEditOrderStatus.includes(status) ||
      getCanceled(child, status, useSp)
    ) {
      return null;
    }
    return (
      <span className="edit_u" onClick={e => this.showAddOrderAndTrack(type)}>
        <i class="material-icons">edit</i>
      </span>
    );
  };

  getAliOrderNoUrl = (id, error) => {
    if (id === 'AE0000000001') {
      return (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => message.info(intl.get('order.test_order_tip') || '')}
        >
          {id}
        </span>
      );
    }
    return (
      <a
        href={`https://trade.aliexpress.com/order_detail.htm?orderId=${id}`}
        target="_blank"
        className={orderStyles.value}
        style={{ color: error ? 'red' : 'inherit' }}
      >
        {id}
      </a>
    );
  };

  // 订单号所在的AE账号ID
  getAliAccountId = (sku, productId, lineItemId) => {
    const {
      listData: {
        detail: { note_attributes },
        ext: { lineitems }
      },
      useSp
    } = this.props;
    let id = '';
    if (lineitems) {
      lineitems.forEach(item => {
        if (item.lineitem_id == lineItemId) {
          item?.sp?.forEach(i => {
            if (
              i.account_id?.length &&
              i.platform_sku == sku &&
              i.platform_product_id == productId
            ) {
              id = i.account_id;
            }
          });
        }
      });
      if (id.length) {
        return id;
      }
    }
    if (note_attributes && useSp.platform_order_id) {
      note_attributes.forEach(item => {
        if (item.name.includes(useSp.platform_order_id)) {
          id = item.name.split('#')[2] && item.name.split('#')[2].trim();
        }
      });
    }
    return id;
  };

  showBtn = id => {
    const {
      listData: {
        detail,
        ext: { lineitems }
      },
      index,
      child = {},
      useSp,
      status
    } = this.props;
    if (!useSp?.TN.length) {
      return null;
    }

    if (useSp?.TN.length == 1) {
      return (
        <>
          <div className={orderStyles.trackNoListCY}>
            <div>
              {useSp?.TN?.map((item, index) => {
                return (
                  <Tooltip key={index} title={item}>
                    <p key={item}>{item}</p>
                  </Tooltip>
                );
              })}
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className={orderStyles.trackNoList}>
          <div className={orderStyles.hoverNoList}>
            <p className={orderStyles.hoverNoListP}>
              {useSp?.TN[0]?.toString()}
            </p>

            <p className={orderStyles.hoverNoListY}>
              ...
              <div className={orderStyles.hoverLw}>
                {useSp?.TN?.map((item, i) => {
                  if (i == 0) {
                    return null;
                  }
                  return (
                    <p key={item}>
                      <span>{item}</span>
                    </p>
                  );
                })}
              </div>
            </p>
          </div>
        </div>
      </>
    );
  };

  showTn = () => {
    const {
      listData: {
        detail,
        ext: { lineitems }
      },
      index,
      child = {},
      useSp,
      status
    } = this.props;
    const { showEditOrderNo } = this.state;

    if (status == 5) {
      //fulfulled 情况
      if (useSp.platform_order_id) {
        return this.editBtn(useSp.logistics_infos, detail, child);
      } else {
        if (useSp?.TN) {
          return this.showBtn();
        }else{
          return this.editBtn(useSp.logistics_infos, detail, child, true);
        }
      }

    } else {
      return this.editBtn(useSp.logistics_infos, detail);
    }
  };

  saveAliNumber = () => {
    let value = this['orderNo']
    this.saveOrderNoAndTrackNo('fuifillOrderNo', value);
  }


  render() {
    const {
      listData: {
        detail,
        ext: { lineitems }
      },
      index,
      child = {},
      useSp,
      status
    } = this.props;
    const { showEditOrderNo } = this.state;

    const aliAccountId = this.getAliAccountId(
      useSp.platform_sku,
      useSp.platform_product_id,
      child.id
    );
    const company = getFreightCompayName(useSp);

    return (
      <div
        className={className(orderStyles.productAliOrderNo, {
          [orderStyles.productAliOrderNoMoveLeft]: false
        })}
        style={{ display: child.platformType > 100 ? 'none' : '' }}
      >
        <div className={orderStyles.trackNoWrapX}>
        <p className="edit_p">
          {aliAccountId ? (
            <div className="aliIcon">
              <Tooltip
                title={`Ali account ID: ${aliAccountId}`}
                getPopupContainer={triggerNode => triggerNode.parentNode}
              >
                <span>
                  <i class="material-icons">account_circle</i>
                </span>
              </Tooltip>
            </div>
          ) : null}
          {intl.get('order.order_product.ali_order_no')}:
          {showEditOrderNo ? (
            <>
              <Input
                defaultValue={useSp.platform_order_id}
                ref={this.orderOneRef}
                  onBlur={() => {
                    setTimeout(() => {
                      if(this.state.showSyncOrderNo){
                        this.setState({ showEditOrderNo: false })
                      }
                    },300)
                    
                  }}
                onChange={e => this.changeOrderNO(e, 'orderNo')}
              />
              {this.orderNo &&
              this.orderNo != this.getedOrderNo &&
              this.orderNoIsRight ? (
                <Tooltip title="Sync">
                  <div
                    className="input_sync"
                        onClick={() => {
                          this.setState({
                            showSyncOrderNo:false
                          }, () => {
                            setTimeout(() => {
                              this.setState({
                                showSyncOrderNo: true
                              })
                            },500)
                          })
                           this.getAEOrderDetail(
                            useSp.platform_sku,
                            useSp.platform_product_id
                          )
                        }
                    }
                  >
                    <i class="material-icons notranslate">sync</i>
                  </div>
                </Tooltip>
              ) : null}
              
              {this.orderNoIsRight ? null : (
                <Icon type="close-circle" style={{ color: 'red',position: 'absolute',right: '1px'  }} />
              )}
            </>
          ) : useSp.platform_order_id ? (
            <>
              {this.getAliOrderNoUrl(useSp.platform_order_id, false)}
              {!notEditOrderStatus.includes(status) ||
              getCanceled(child, status, useSp) ? (
                <span
                  className="edit_u"
                  onClick={e => this.showAddOrderAndTrack('showEditOrderNo')}
                >
                  <i class="material-icons">edit</i>
                </span>
              ) : null}
            </>
          ) : (
            this.addBtn('showEditOrderNo')
          )}
        </p>
        </div>
        <div className={orderStyles.trackNoWrap}>
          <span style={{ letterSpacing: 0.1 }}>
            {intl.get('order.order_product.tracking_no')}:
          </span>

          {this.showTn()}
        </div>
        <div className={orderStyles.NoWrapFrom}>
            Shipping time: {company.time}{' '}
            {company?.time ? intl.get('order.order_product.shipping_time') : null}
        </div>

        {/* {aliAccountId ? (
          <p className={orderStyles.aliAccountId}>
            <span>Ali account ID: </span>
            {aliAccountId}
          </p>
        ) : null} */}
      </div>
    );
  }
}

function getFreightCompayName(useSp, name) {
  const freights = useSp.freights || [];
  let obj = {};
  const v = name || useSp.logistics_company;

  if (!v) {
    return obj;
  }

  freights.forEach(item => {
    if (item.serviceName == v) {
      obj = item;
    }
  });

  return obj;
}