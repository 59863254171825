export default {
  agency: {
    // global
    Agency: 'Agentur (Einzellieferant)',
    agency: 'agentur',
    // setting
    supplier: 'Lieferant',
    title: 'Agentur (Einzelhändler) Management',
    subtitle:
      'Sie können hier Lieferanteninformationen verwalten, direkt bei Lieferanten bestellen und nicht mehr über Drittmärkte (wie AliExpress) einkaufen. Klicken Sie hier, um mehr zu erfahren.',
    subtitle_1: 'Sie können hier Lieferanteninformationen verwalten, direkt bei Lieferanten bestellen und nicht mehr über Drittmärkte (wie AliExpress) einkaufen. Klicken Sie ',
    subtitle_2: ', um mehr zu erfahren.',
    subtitle_3: ' hier ',
    title_tip: 'Eine Agentur ist ein Produktlieferant, der es Ihnen ermöglicht, Bestellungen ohne einen Drittanbieter-Marktplatz wie AliExpress auszuführen. Sie können direkt bei Agenturen Bestellungen aufgeben, die dann die Waren an Ihre Kunden liefern.',
    name_tip: 'Der Name Ihrer Agentur, der verwendet und angezeigt wird, wenn Sie ein Produkt zuordnen oder eine Bestellung bearbeiten.',
    erp_tip: 'Die ERP-Vertreter, wie Dianxiaomi, Mabang und ECCANG, etc., die die Produktinventarverwaltung, die Beschaffungsüberwachung, die Logistikverteilung und die Paketverfolgung anbieten.',
    paypal_tip: `Das PayPal-Konto Ihrer Agentur. Nachdem Sie dieses PayPal-Konto eingerichtet haben, können Sie Zahlungen direkt an die Agentur veranlassen, während DSers Bestellungen bearbeitet`,
    phone_tip: 'Die Telefonnummer Ihrer Agentur, die verwendet wird, um Ihre Agentur zu veranlassen, Bestellungen per SMS auszuführen.',
    name: 'Name der Agentur',
    nameErr: 'Der Name der Agentur darf nicht leer sein.',
    phone: 'Telefon Nummer',
    phoneErr: 'Es können nur Zahlen eingegeben werden',
    erp: 'ERP in Gebrauch',
    erpPlaceholder: 'ERP auswählen',
    erpErr: 'Bitte wählen Sie ein ERP.',
    paypal: 'PayPal-Konto',
    paymentMethod: 'Payment method',
    agencyContactEmail: 'Agency contact email',
    stripeAccount: 'Stripe account',
    agencyStripe: 'Agency Stripe',
    stripeNoAuth: 'No authorization, please contact the supplier for authorization.',
    agencyPaymentTip: `Your Agency's Payment methods. After setting up this payment method, you can initiate payments directly to the Agency while DSers process orders.`,
    agencyNoAuthModalTitle: 'Authorization not completed',
    agencyNoAuthModalContent: `Your supplier has not completed the Stripe account authorization, please contact your supplier to complete the Stripe account authorization. If you have more questions, please contact the DSers support team.`,
    agencyNoAuthModalGetSupport: 'GET SUPPORT',
    paypalPlaceholder: 'Agentur PayPal-Konto',
    paypalErr: 'Das PayPal-Konto darf nicht leer sein.',
    paypalEmailErr: 'Bitte geben Sie eine korrekte PayPal-E-Mail ein',
    delError: 'Der Lieferant wurde zugewiesen und kann nicht gelöscht werden.',
    phonePlaceholder: 'Country',

    // setting > save text

    savetitle: 'Ein Agentur hinzufügen (Einzelanbieter)',
    savetext: `Nachdem Sie die Agenturinformationen erstellt haben, sendet DSers eine E-Mail an Ihren Lieferanten ({email}).Bitte kontaktieren Sie Ihren Lieferanten, der eine einfache Konfiguration durchführt, damit Sie über DSers weiterhin Produktmapping, automatische Auftragsabwicklung und andere Funktionen verwalten können.`,
    deletetitle: "Löschung fehlgeschlagen.",
    deletetext:"Sie haben derzeit Produkte, die diesem Lieferanten zugeordnet sind. Um bestehende Produkte und Bestellungen nicht zu beeinträchtigen, löschen Sie bitte die Zuordnung im Zusammenhang mit diesem Lieferanten, bevor Sie es erneut versuchen.",
    // my product
    Agencies: 'Agenturen <i>({ae_num})</i>',
    filterByAgency: 'Nach Agentur filtern',

    filterAgencyPlease: 'Bitte gehen Sie zu den',
    filterAgencyAddInformation: ', um eine Agentur hinzuzufügen.',

    supplierManagement: 'Lieferanten-Management',
    marketplaces: 'Marktplätze',
    agencyInformation: 'Informationen zur Agentur',
    selectAgency: 'Agentur auswählen',
    refreshPage: 'Seite aktualisieren',
    REFRESH: 'AKTUALISIEREN',
    mappingUpdating: 'Aktualisierung des Mappings',
    markeCardDescription:
      'Sie können die Produkte des Lieferanten für die Produkte Ihres Geschäfts zuordnen, und wenn die Verkaufsprodukte bestellt werden, können Sie mit nur einem Klick auf DSers eine Bestellung beim entsprechenden Lieferanten aufgeben.',
    markeCardDescriptionNoTianMao:
      'Bei Marktplätzen können Sie AliExpress-Produkte auswählen, um Ihre Shopify-Artikel zuzuordnen.',
    agencyCardDescription:
      'Agency repräsentiert einen einzelnen Lieferanten außerhalb von Marktplätzen (wie AliExpress). Sie können die Produkte Ihres Geschäfts der Agentur zuordnen und direkt über deren ERP bestellen, um die automatische Erfüllung zu ermöglichen.',
    marketplaceExplain:
      'Zuordnung ',
    marketplaceExplain1:
    ' von Marketplace-Lieferanten für Ihre Shopify-Produkte. Die Produktdetails von Shopify (wie Titel, Beschreibung, Bilder, Varianten usw.) bleiben nach dem Hinzufügen eines Anbieters unverändert.',
    agencyExplain:
      'Sie können den gewünschten Lieferanten nach Ihren Bedürfnissen auswählen. Die Produktdaten aus Shopify (wie Titel, Beschreibung, Bilder, Varianten usw.) bleiben nach dem Hinzufügen eines Lieferanten erhalten.',
    statusExplain:
      'Aktivieren Sie diese Option, um die Zuordnungsmethode für die Agentur zu aktivieren.',
    selectAgentPrompt:
      'Wählen Sie eine Agentur aus, der Sie Ihre Shopify-Produkte zuordnen möchten. Wenn Sie keine Agentur hinzugefügt haben, müssen Sie zu den',
    toSetting: 'Einstellungen',
    addAgency: 'gehen, um eine Agentur hinzuzufügen.',
    noSelectionPrompt:
      'Sie haben keine Agentur hinzugefügt. Bitte gehen Sie zu den Einstellungen, um eine Agentur hinzuzufügen.',
    noSelectionButton: 'ZU DEN EINSTELLUNGEN GEHEN, UM AGENTUREN HINZUZUFÜGEN',
    refreshPagePrompt:
      'Bitte aktualisieren Sie die Seite, damit wir überprüfen können, ob Sie erfolgreich eine Agentur hinzugefügt haben.',
    backendProcessesPrompt:
      'Die Zuordnungsmethode für das Produkt wurde geändert. Wir werden einige Zeit brauchen, um Ihnen zu helfen, die Zuordnung der Bestellung zu dem Produkt zu ersetzen.',
    TimeoutErr:
      'Die Anfrage wurde abgebrochen, bitte versuchen Sie es später noch einmal.'
  }
};
