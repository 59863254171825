import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'dva';
import { Alert } from 'antd';

import styles from './SyncAEPriceInfoToShopify.less';
import { Get } from 'utils/request';
import { checkExtVersion, EXT_VERSION, getAELoginStatusV2, getSyncAEPriceInfoToShopifyProcess } from 'utils/placeOrderViaExt';
import { getLanguage } from 'utils/utils';

const sleep = (seconds) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve();
        }, (Number(seconds) || 0) * 1000)
    })
}

const SyncAEPriceInfoToShopify = React.memo(props => {
    const [status, setStatus] = useState(0);
    const [visible, setVisible] = useState(false);
    const isFr = useMemo(() => {
        return getLanguage() === 'fr-FR';
    }, []); 
    useEffect(() => {
        // 卸载组件后需要中断死循环
        let unmounted = false;
        const runTask = async () => {
            // 是否安装了插件并且版本在2.8.37以上同时有权限使用这个功能
            const shouldSyncPrice =
                [EXT_VERSION.HIGHER, EXT_VERSION.EQUAL].includes(
                    checkExtVersion(2, 8, 37)
                ) &&
                (await Get('/plugin/sync/order/metafields/status'))?.data
                    ?.enabled;
            setVisible(shouldSyncPrice);
            if (shouldSyncPrice) {

                while (!unmounted) {
                    console.log(1);
                    const processingStatus = await getSyncAEPriceInfoToShopifyProcess();
                    console.log(processingStatus)
                    if (status !== processingStatus) {
                        setStatus(processingStatus);
                    }
                    await sleep(3);
                }
            }
        };
        runTask();
        return () => {
            unmounted = true;
        };
    }, []);

    const reloadPage = () => {
        window.location.reload();
    }

    const tipMsg = useMemo(() => {
        let msg = '';
        switch (status) {
            case 0: msg  = 'Initializing'
                break;
            case 1:
                msg = isFr ? <span>Veuillez vous <a className='ds-link' href="https://login.aliexpress.com" target="_blank">connecter à votre compte AliExpress</a> pour synchroniser le coût des commandes avec Shopify</span>: <span>Please <a className='ds-link' href="https://login.aliexpress.com" target="_blank">login your AliExpress account</a> to sync order cost to shopify</span>;
                break;
            case 2:
                msg = isFr ?<span>Aucun coût des commandes à synchroniser avec Shopify. <a onClick={reloadPage}>Rafraîchir</a></span> :<span>No orders need to sync cost to Shopify. <a onClick={reloadPage}>Refresh</a></span>;
                break;
            case 3:
                msg = isFr? 'Synchronisation du coût des commandes avec Shopify...': 'Syncing order cost to Shopify...';
                break;
            default: break;
        }
        return msg;
    }, [status]);

    return visible && status ?
        <Alert className={styles.dsersAlert} type="warning" closable onClose={() => setVisible(false)} message={tipMsg}></Alert>
        : null;
});

export default SyncAEPriceInfoToShopify;
