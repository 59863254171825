import product_1 from '../../../assets/guide/product/product_1.gif';
import product_2 from '../../../assets/guide/product/product_2.gif';
import product_3 from '../../../assets/guide/product/product_3.gif';
import product_4 from '../../../assets/guide/product/product_4.gif';
import product_5 from '../../../assets/guide/product/product_5.gif';

import order_1 from '../../../assets/guide/order/01.gif';
import order_2 from '../../../assets/guide/order/02.gif';
import order_3 from '../../../assets/guide/order/03.gif';
import order_4 from '../../../assets/guide/order/04.gif';
import order_5 from '../../../assets/guide/order/05.png';
import order_6 from '../../../assets/guide/order/06.gif';

const guideData = {
    import: [
        {
            title: 'Enter AliExpress supplier link',
            img: product_1,
            text: 'You can enter the AliExpress product link here and click OK to import the product',
        },
        {
            title: 'Product imported successfully',
            img: product_2,
            text: 'The product imported successfully will be displayed in the Import List menu',
        },
        {
            title: 'Push products to Shopify store',
            img: product_3,
            text: `1.Please click the "Push to Shopify" button to send the product to your Shopify store. 
            2.Please select a store and click the "Push to Shopify" button to send the product.`,
        },
        {
            title: 'Wait for the product to be pushed',
            img: product_4,
            text: 'The import process will take a few seconds, and the product sent successfully will be displayed in the My Products menu',
        },
        {
            title: 'The product is successfully pushed to the store',
            img: product_5,
            text: 'The product sent successfully has been displayed in the My Products tab for your subsequent view and operation.You have finished the tutorial for importing products. ',
            nextText: 'Would you like to learn how to process the order?'
        }
    ],
    order: [
        {
            title: 'Set a supplier for the order',
            img: order_1,
            text: "After your customer place an order, you will see it in the awaiting order tab. You can map the product when there's no supplier for it.",
        },
        {
            title: 'Enter AliExpress supplier link',
            img: order_2,
            text: 'You can enter the AliExpress product link here and click the Import button',
        },
        {
            title: 'Set up primary and secondary suppliers',
            img: order_3,
            text: 'After pasting a product link you can click the Set button to set it as your supplier',
        },
        {
            title: 'Set corresponding variants',
            img: order_4,
            text: "Now, it's time to select the variants of your AliExpress supplier that correspond to the variants of your Shopify product. Fill in all the boxes and do not leave empty space. Don't forget to Save",
        },
        {
            title: 'Click to place an order',
            img: order_5,
            text: 'We can see that test order #1001 is ready to go.We selected AliExpress standard shipping as the shipping method for testing,  but you can change this setting for next orders.Click the Order button to process the order to the AliExpress',
        },
        {
            title: 'Confirm order information',
            img: order_6,
            text: 'Before the order goes to AliExpress, you can double check all the information here.Click Confirm to continue.Hooray🎉🎉! The test order is processed to your AliExpress account. '
        }
    ]
}

export default guideData;