import { createElement } from 'react';
import { dynamic } from 'dva';
import pathToRegexp from 'path-to-regexp';
import { getMenuData } from './menu';

export const routerConfig = {
  // Home
  '/home': {
    models: [],
    component: () => import('../routes/Home')
  },
  '/accountoverview': {
    models: ['substat'],
    component: () => import('../routes/AccountOverview/SubOverview')
  },
  // Report
  '/report': {
    models: ['report'],
    component: () => import('../routes/Report')
  },
  '/storemanage/myproduct': {
    models: [],
    component: () => import('../routes/StoreManage/MyProdcut')
  },
  '/storemanage/import': {
    models: [],
    component: () => import('../routes/StoreManage/ImportList')
  },
  '/find_suppliers': {
    models: [],
    component: () => import('../routes/BargainMain')
  },
  '/supplier_optimizer': {
    models: [],
    component: () => import('../routes/SupplierOptimizer')
  },
  '/order/list': {
    models: ['order'],
    component: () => import('../routes/Order/Order')
  },
  '/order/tmall/list': {
    models: ['order'],
    component: () => import('../routes/TmallOrder/Order')
  },
  '/order/agency/list': {
    models: ['order'],
    component: () => import('../routes/AgencyOrder/Order')
  },
  '/order/unmapped/list': {
    models: ['order'],
    component: () => import('../routes/Order/Order')
  },
  '/order/archived': {
    models: ['order'],
    component: () => import('../routes/Order/Archived')
  },
  '/order/search/:status': {
    models: ['order'],
    component: () => import('../routes/Order/Order')
  },
  // affiliate 2021/03/16下线
  // '/affiliate/reward': {
  //   models: [],
  //   component: () => import('../routes/Reward')
  // },
  '/newfeature': {
    models: [],
    component: () => import('../routes/NewFeature/Index')
  },
  '/tracking': {
    models: [],
    component: () => import('../routes/Tracking')
  },
  '/csv': {
    models: ['newfeature'],
    component: () => import('../routes/NewFeature/Csv')
  },
  '/settings': {
    models: [],
    component: () => import('../routes/Setting/Dropshpper/List')
  },
  '/settings/aliexpress/callback': {
    models: [],
    component: () => import('../routes/Setting/Dropshpper/AliExpressCallback')
  },
  // Fulfillment Center
  '/fulfillment-center': {
    models: ['fulfillmentcenter'],
    component: () => import('../routes/FulfillmentCenter/Index')
  },
  '/agency':{
    models: [],
    component: () => import('../components/Agency')
  }
};

let routerDataCache;

const modelNotExisted = (app, model) =>
  // eslint-disable-next-line
  !app._models.some(({ namespace }) => {
    return namespace === model.substring(model.lastIndexOf('/') + 1);
  });

// wrapper of dynamic
const dynamicWrapperCache = {};
const dynamicWrapper = (app, models, component, key) => {
  // () => require('module')
  // transformed by babel-plugin-dynamic-import-node-sync
  if (dynamicWrapperCache[key]) {
    return dynamicWrapperCache[key];
  }

  if (component.toString().indexOf('.then(') < 0) {
    models.forEach(model => {
      if (modelNotExisted(app, model)) {
        // eslint-disable-next-line
        app.model(require(`../models/${model}`).default);
      }
    });
    dynamicWrapperCache[key] = props => {
      if (!routerDataCache) {
        routerDataCache = getRouterData(app);
      }
      return createElement(component().default, {
        ...props,
        routerData: routerDataCache
      });
    };
    return dynamicWrapperCache[key];
  }

  dynamicWrapperCache[key] = dynamic({
    app,
    models: () =>
      models
        .filter(model => modelNotExisted(app, model))
        .map(m => import(`../models/${m}.js`)),
    // add routerData prop
    component: () => {
      if (!routerDataCache) {
        routerDataCache = getRouterData(app);
      }
      return component().then(raw => {
        const Component = raw.default || raw;
        return props =>
          createElement(Component, {
            ...props,
            routerData: routerDataCache
          });
      });
    }
  });

  return dynamicWrapperCache[key];
};

function getFlatMenuData(menus) {
  let keys = {};
  menus.forEach(item => {
    if (item.children) {
      keys[item.path] = { ...item };
      keys = { ...keys, ...getFlatMenuData(item.children) };
    } else {
      keys[item.path] = { ...item };
    }
  });

  return keys;
}

export const getRouterData = app => {
  // Get name from ./menu.js or just set it in the router data.
  const menuData = getFlatMenuData(getMenuData());

  // Route configuration data
  // eg. {name,authority ...routerConfig }
  const routerData = {};
  // The route matches the menu
  Object.keys(routerConfig).forEach(path => {
    // Regular match item name
    // eg.  router /user/:id === /user/chen
    const pathRegexp = pathToRegexp(path);
    let router = routerConfig[path];
    const { component, models } = router;
    router.component = dynamicWrapper(app, models, component, path);
    const menuKey = Object.keys(menuData).find(key =>
      pathRegexp.test(`${key}`)
    );
    let menuItem = {};
    // If menuKey is not empty
    if (menuKey) {
      menuItem = menuData[menuKey];
    }

    // If you need to configure complex parameter routing,
    // https://github.com/ant-design/ant-design-pro-site/blob/master/docs/router-and-nav.md#%E5%B8%A6%E5%8F%82%E6%95%B0%E7%9A%84%E8%B7%AF%E7%94%B1%E8%8F%9C%E5%8D%95
    // eg . /list/:type/user/info/:id
    router = {
      ...router,
      name: router.name || menuItem.name,
      authority: router.authority || menuItem.authority,
      hideInBreadcrumb: router.hideInBreadcrumb || menuItem.hideInBreadcrumb
    };
    routerData[path] = router;
  });
  return routerData;
};
