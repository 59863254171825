import React from 'react';

import './Loading.css';

// reference: https://github.com/ConnorAtherton/loaders.css
const Loading = ({ className, ...props }) => (
  <div
    className={`ball-spin-fade-loader${className ? ` ${className}` : ''}`}
    {...props}
  >
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default Loading;
