import React, { useEffect, useState } from 'react';
import styles from './ShipCost.less';
import { Button } from 'antd';
import shipcost from 'assets/bargain/shipcost.png';
import intl from 'react-intl-universal';
import { connect } from 'react-redux';

const ShipCost = ({userInfo}) => {
    const [visible, setVisible] = useState(true)

    useEffect(() => {
        const date = new Date()
        let timeStatus = (1655890964000 - date.valueOf()) < 0
        if (timeStatus || localStorage.getItem('shipcost') || userInfo.status.toString().substring(1, 2) === '0') {
            setVisible(false)
        }
    }, [userInfo.status])

    if (!visible) {
        return null
    }

    const handleClose = () => {
        setVisible(false)
        localStorage.setItem('shipcost', true)
    }

    const handleLearnMore = () => {
        window.open('https://help.dsers.com/import-product-cost-for-shopify/')
        setVisible(false)
        localStorage.setItem('shipcost', true)
    }

    const handleInstall = ()=>{
        window.open('https://chrome.google.com/webstore/detail/dsers-aliexpresscom-produ/mmanaflgaempokjfbeeabkadnkoidjam')
        setVisible(false)
        localStorage.setItem('shipcost', true)
    }

    return (
        <>
            {
                visible ? <div className={styles.mask}></div> : null
            }
            <div className={`${styles.container} ${styles.animate}`}>
                <div className={styles.close} onClick={handleClose}><i class="material-icons">close</i></div>
                <div className={styles.main}>
                    <div className={styles.title}>{intl.get('bargain.shipCost.reminder')}</div>
                    <img src={shipcost} alt="" />
                    <div className={styles.text}>{intl.getHTML('bargain.shipCost.text')}</div>
                    <div><Button onClick={handleLearnMore}>{intl.get('bargain.shipCost.learnmore')}</Button>
                        <Button onClick={handleInstall}>{intl.get('bargain.shipCost.install')}</Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default connect(({ login })=>({
    userInfo: login.userInfo,
    }))(ShipCost)
