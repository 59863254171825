export default {
  partner: {
    public: {
      change: 'Change',
      cancel: 'Cancel',
      save: 'Save',
      reset: 'Reset'
    },
    signIn: {
      title: 'Welcome back',
      log_in: 'Log in',
      forgot_password: 'FORGOT PASSWORD?',
      no_account: "Don't have an account?",
      become_partner: 'Become partner',
      email: 'Email'
    },
    signUp: {
      title: 'Become DSers Partners !',
      register: 'Register',
      sign_in: ' Sign in',
      have_account: 'Have an account?',
      email: 'Contact email',
      email_format_tips: 'Please input an email address',
      email_is_value_tips: 'Please fill in contact email',
      email_placeholder_tips: 'Used to contact you and create account',
      pwd: 'Senha',
      pwd_is_value_tips: 'Please fill in Password',
      pwd_placeholder_tips: 'Please enter a password',
      pwd_font_num_tips: 'The password must be 8 - 16 characters long',
      pwd_format_tips:
        'The password contains at least 1 uppercase letter, 1 lowercase letter and 1 number',
      confirm_Password: 'Confirm Password',
      confirm_pwd_placeholder_tips: 'Please enter a Confirm password',
      confirm_pwd_is_value_tips: 'Please confirm your password!',
      confirm_pwd_contrast_tips:
        'Two passwords that you enter is inconsistent!',
      name: 'Full name',
      name_is_value_tips: 'Please fill in English characters in 1-100 long',
      name_placeholder_tips: 'Please tell us your name',
      country: 'Country',
      country_is_value_tips: 'Please select a main country',
      country_placeholder_tips: 'Please select your country',
      business_type: 'Business Type',
      business_type_is_value_tips: 'Please fill in business type',
      business_type_placeholder_tips: 'Please select your business type',
      partner_url: 'Your website/app/channel url',
      partner_url_is_value_tips:
        'Please enter an URL starting with Http or Https',
      partner_url_placeholder_tips: 'Please share the link of your business',
      partner_url_font_num_tips:
        'Too many characters, please enter 1000  characters maximum.',
      partner_url_format_tips:
        'Please enter an URL starting with Http or Https',
      partner_id: 'Channel ID',
      partner_id_tips: 'You can add multiple Channel IDs here, and the newly produced Channel ID will be generated according to the platform rules and cannot be modified',
      partner_id_is_value_tips: 'Please fill in your Channel ID',
      partner_id_is_value_format_tips: 'The Channel ID cannot end with a number',
      partner_id_placeholder_tips: 'Used to create your trackable link',
      partner_id_font_num_tips:
        'Too many characters, please enter 100  characters maximum.',
      paypal_email: 'PayPal email address(Recommended)',
      paypal_email_placeholder_tips:
        'You can fill in this later in the account',
      remark: 'Tell us more',
      remark_placeholder_tips:
        'Let us know more about how you want to work together',
      remark_font_num_max_tips:
        'Too many characters, please enter 1000  characters maximum.',
      remark_font_num_min_tips:
        'No enough characters, please enter 200 characters minimum.',
      remark_is_value_tips: 'Please tell us more about you'
    },
    account: {
      paypal_email: 'PayPal email address',
      paypal_email_placeholder_tips: 'Please tell us your Paypal email address'
    },
    home: {
      title: 'Dashboard',
      commission_on_hold: 'Commission on hold',
      commission_confirmed: 'Commission confirmed',
      request_commission: 'Request commission',
      seven_days_tips:
        'We got your request. Team will review it and get back to you in 7 working days. Thank you for your patience.'
    },
    Reset_email_module: {
      title: 'New email address',
      email_tips: 'Please input an email address!',
      code_tips: 'Wrong code. Please check again!',
      send_code: 'Send code',
      verify: 'Verify',
      send_prompt:
        'We will send a verification code to the new email address. Please copy it and enter below to verify your mailbox',
      code_title: 'Enter code to verify',
      get_code:
        'Get code from our verification email and enter here to get verified'
    },
    reset_pass_word: {
      title: 'Reset Password',
      new_pwd_placeholder_tips: 'New password',
      new_pwd_value_no_tips: 'The password cannot be empty',
      old_pwd_placeholder_tips: 'Old password',
      old_pwd_value_no_tips: 'Please input your Old password!',
      old_pwd_font_num_tips: 'The password must be 8 - 16 characters long',
      old_pwd_format_tips:
        'The password contains at least 1 uppercase letter, 1 lowercase letter and 1 number',
      confirm_new_pwd_value_no_tips: 'The password cannot be empty',
      confirm_new_pwd_contrast_tips: 'Passwords do not match twice!'
    },
    partner_layout: {
      account: 'Account',
      dashboard: 'Dashboard'
    },
    password_not_match_twice: 'Passwords do not match twice',
    password_error_long: 'The password must be 8 - 16 characters long',
    password_error_format:
      'The password contains at least 1 uppercase letter, 1 lowercase letter and 1 number'
  }
};
