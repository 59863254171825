export default {
    "order": {
        "order_language": {
            "pay": "Si prega di pagare gli ordini entro 24 ore, altrimenti saranno annullati da Tmall."
        },
        "tab_tip": {
            "pending": "Gli ordini In sospeso sono ordini per i quali il pagamento dei tuoi clienti non è ancora stato catturato in Shopify. Non puoi elaborare o modificare gli ordini in sospeso su DSers fino a quando il pagamento non è stato registrato, se un ordine è ancora in sospeso dopo aver registrato il pagamento, controlla <a href=\"https://help.dsers.com/pending-orders-introduction-for-shopify/\" target=\"_blank\">qui</a> per aggiornare manualmente lo stato dell'ordine. Contattaci se ciò continua a succedere.",
            "awaiting_order": "Gli ordini in In attesa di ordine sono ordini per i quali il pagamento dei tuoi clienti è stato catturato su Shopify e in attesa di essere processato su AliExpress. Se non riesci a trovare i tuoi nuovi ordini qui, controlla la scheda In attesa e Effettuato. Controlla come elaborare gli ordini su AliExpress <a href=\"https://help.dsers.com/fulfill-multiple-orders-from-start-to-finish-for-shopify/\" target=\"_blank\">qui.</a>",
            "awaiting_payment": "Gli ordini in In attesa di spedizione sono stati pagati su AliExpress ma non ancora spediti. Se trovi che il numero di tracciamento non si sincronizza dopo la spedizione, <a href=\"https://help.dsers.com/pay-multiple-orders-on-aliexpress-with-dsers-for-shopify/\" target=\"_blank\">qui</a>. NON richiedere l'evasione su Shopify e NON evadere manualmente gli ordini su Shopify o DSers.",
            "fulfilled": "Gli ordini in Realizzati sono ordini che sono stati spediti dal tuo fornitore AliExpress e ordini che sono stati soddisfatti automaticamente o manualmente. Una volta che un ordine è soddisfatto, non sincronizzeremo più lo stato dell'ordine o il prezzo del prodotto da AliExpress. Clicca <a href=\"https://help.dsers.com/re-order-fulfilled-orders-for-shopify/\" target=\"_blank\">qui</a> per sapere come gestire gli ordini che sono stati evasi per errore. Puoi anche controllare <a href=\"https://help.dsers.com/fulfill-multiple-orders-from-start-to-finish-for-shopify/\" target=\"_blank\">qui</a> per sapere come DSers evade gli ordini automaticamente. Se vuoi sincronizzare l'ultimo Numero di Tracking degli ordini AliExpress, puoi cliccare sul pulsante Sincronizza Numero di Tracking degli ultimi 30 giorni. Sincronizzeremo l'ultimo Numero di Tracking degli ordini AliExpress degli ultimi 30 giorni.",
            "awaiting_shipment": "Gli ordini in In attesa di spedizione sono stati pagati su AliExpress ma non ancora spediti. Se trovi che il numero di tracciamento non si sincronizza dopo la spedizione, <a href=\"https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/\" target=\"_blank\">qui</a>. NON richiedere l'evasione su Shopify e NON evadere manualmente gli ordini su Shopify o DSers.",
            "canceled": "Gli ordini in Annullati sono ordini che sono (parzialmente) annullati / (parzialmente) rimborsati su AliExpress o Shopify. Puoi ordinare in blocco gli ordini annullati da AliExpress, ma non puoi ordinare gli ordini annullati o rimborsati su Shopify. Se trovi che lo stato dell'ordine non si aggiorna dopo l'annullamento, controlla <a href=\"https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/\" target=\"_blank\">qui</a>. E puoi anche controllare <a href=\"https://help.dsers.com/re-order-aliexpress-canceled-orders-for-shopify/\" target=\"_blank\">qui</a> per sapere come DSers può aiutarti con gli ordini che continuano ad essere annullati da AliExpress.",
            "failed_orders": "Gli ordini in Ordini falliti sono ordini che non possono essere piazzati con successo su AliExpress a causa di errori o restrizioni. Controlla le informazioni dei clienti e risolvi gli errori in modo da poter ordinare di nuovo gli ordini falliti. Ci sono alcune restrizioni e regole per alcuni paesi, per favore clicca <a href=\"https://help.dsers.com/order-requirements-for-specific-countries-for-shopify/\" target=\"_blank\">qui</a> per avere maggiori informazioni.",
            "awaiting_fulfillment": "Gli ordini in In attesa di evasione sono solo parzialmente evasi. Gli ordini si aggiorneranno e passeranno alla scheda Effettuato più tardi. Poi, sincronizzeremo il numero di tracking a Shopify e attiveremo Shopify per inviare una sola e-mail al tuo cliente. Clicca <a href=\"https://help.dsers.com/awaiting-fulfillment-introduction-for-shopify/\" target=\"_blank\">qui</a> per avere maggiori informazioni. NON richiedere l'evasione su Shopify e NON evadere manualmente gli ordini su Shopify o DSers.",
            "fulfilledFirstChange": "Gli ordini in \"Realizzati\" sono ordini che sono stati spediti dal tuo fornitore AliExpress e ordini che sono stati soddisfatti automaticamente o manualmente.",
            "fulfilledFirstChange_tit": "Una volta che un ordine è soddisfatto, non sincronizzeremo più lo stato dell'ordine o il prezzo del prodotto da AliExpress. Clicca <a target=\"_blank\" href=\"https://help.dsers.com/re-order-fulfilled-orders-for-shopify/\">qui</a> Clicca qui per sapere come gestire gli ordini che sono stati evasi per errore.Puoi anche controllare<a target=\"_blank\" href=\"https://help.dsers.com/fulfill-multiple-orders-from-start-to-finish-for-shopify/\">qui</a> per sapere come DSers evade gli ordini automaticamente.",
            "fulfilledDays": "Puoi anche controllare qui per sapere come DSers evade gli ordini automaticamente.",
            "fulfilledDays_tit": "Se vuoi sincronizzare l'ultimo Numero di Tracking degli ordini AliExpress, puoi cliccare sul pulsante Sincronizza Numero di Tracking degli ultimi 30 giorni. Sincronizzeremo l'ultimo Numero di Tracking degli ordini AliExpress degli ultimi 30 giorni.",
            "canceledChange": "Gli ordini in \"Annullati\" sono ordini che sono (parzialmente) annullati / (parzialmente) rimborsati su AliExpress o Shopify.",
            "canceledChange_tit": "You can bulk order the Canceled orders from AliExpress, but you cannot order the orders canceled of refunded on Shopify. If you find that the order status is not updating after cancellation, please check <a target=\"_blank\" href=\"https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/\">here</a>. And you can also check <a target=\"_blank\" href=\"https://help.dsers.com/re-order-aliexpress-canceled-orders-for-shopify/\">here</a> to learn how DSers can help you with orders that keep getting canceled by AliExpress."
        },
        "alert_fulfilled_by_other": {
            "w1": "Nota bene.",
            "w2": "Controlla se ci sono ordini già evasi su piattaforme come Oberlo o Dropified."
        },
        "alert_sync_by_extend": {
            "w1": "Nota: Ora possiamo sincronizzare lo stato degli ordini AliExpress in",
            "w2": "tempo reale",
            "w3": "con due passaggi!",
            "w4": "Clicca qui",
            "w5": "per vedere i dettagli."
        },
        "search_type": {
            "order_no": "Numero Ordine",
            "note": "Nota",
            "ali_order_no": "Numero Ordine Ali",
            "tm_order_no": "Numero d'ordine Tmall",
            "product": "Prodotto",
            "email": "E-mail",
            "customer_name": "Nome del Cliente",
            "tracking_no": "Numero di Tracking",
            "searchOrder": "Cerca ordine",
            agency_order_no: 'Ordine Agenzia Nr.',
        },
        "search_input_placeholder": "Puoi scegliere una categoria per cercare con precisione",
        "syncing": "Sincronizzazione...",
        "sync_ali_order": "Sincronizzare l'ordine Ali",
        "sync_via_api": "Sincronizzazione tramite API (Puoi sincronizzare manualmente da AliExpress {lunghezza} più volte oggi)",
        "sync_tn": "Sincronizza il numero di tracciamento degli ultimi 30 giorni (puoi sincronizzare manualmente da AliExpress {lunghezza} più volte oggi）)",
        "sync_tn_2": "Sincronizza l'ultimo numero di tracciamento con l'estensione Chrome",
        "sync_via_extension": "Sincronizzazione tramite l'estensione Chrome di DSers",
        "syncing_ali": "Gli ordini si stanno sincronizzando",
        "sync_ali_completed": "Sincronizzazione degli ordini completata",
        "select_sync_range": "Seleziona l'intervallo di date per aggiornare i numeri di tracciamento",
        "sync_task_count": "Numero di ordini che devono essere sincronizzati",
        "sync_except_time": "Tempo approssimativo richiesto",
        "sync_ali_failed_result": {
            "title": "Le situazioni seguenti possono causare il fallimento:",
            "reason1": "1.L'account AliExpress a cui accedi attualmente non corrisponde a quello collegato a DSers.",
            "reason2": "2.Ti sei disconnesso dal tuo account AliExpress durante la sincronizzazione.",
            "reason3": "3.C'era qualcosa che non andava nella tua rete.",
            "contact": "Se hai controllato quanto sopra ma continua a non funzionare, non esitare a contattarci."
        },
        "start_time": "Ora di Inizio",
        "end_time": "Ora di Fine",
        "tab": {
            "title": "{key, plural, =1 {In Attesa} =2 {In Attesa dell'Ordine} =3 {In Attesa del Pagamento} =4 {In Attesa della Spedizione} =5 {Evaso} =6 {Annullato} =7 {Ordini falliti} =9 {In Attesa di Evasione} }",
            "all_tip": "L'importo totale dell'ordine potrebbe non essere uguale alla somma di Ordine in Attesa, Pagamento in Attesa, Spedizione in Attesa e Ordine Evaso poiché alcuni ordini potrebbero fallire.",
            "canceled_tip": "Gli ordini annullati, sia da AliExpress/Shopify che manualmente dagli utenti, sono elencati qui. Gli ordini annullati su AliExpress possono essere riordinati di nuovo."
        },
        "place_order": {
            "btn": "{key, plural, =1 {invia l'ordine su AliExpress} =4 {invia l'ordine di nuovo} }",
            "tm_btn": "{key, plural, =1 {Effettua ordine di massa} =4 {invia l'ordine di nuovo} }",
            "tip": "Devi mappare il prodotto per far sapere ai DSers con quale fornitore stai lavorando prima di fare un ordine all'ingrosso. <a href=\"https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/\" target=\"_blank\">Controlla questo articolo </a>",
            agency_btn: `{key, plural, =1 {Controllo massivo} =4 {invia l'ordine di nuovo} }`,
        },
        "more_action": {
            "title": "Più Azione",
            "action_menu": "Evadi Ordini Manualmente",
            "action_menu_tip": "Non saranno inviati ad AliExpress, ma solo segnati come evasi.",
            "tip": "Se NON vuoi elaborare alcuni ordini con DSers, puoi segnarli manualmente come soddisfatti qui. E non influenzerà lo stato del tuo ordine su Shopify.",
            "tip_link": "Controlla questo articolo"
        },
        "canceled_by": {
            "placeholder": "Annullato da",
            "shopify": "Shopify",
            "ae": "AliExpress",
            "tm": "Tmall",
            agency_in_dsers: 'You on DSers'
        },
        "fulfilled_by": {
            "placeholder": "Evaso da",
            "shopify": "Shopify",
            "ae": "DSers",
            "self": "Evaso manualmente"
        },
        "country": "Paese",
        "make_payment": {
            "btn": "Effettua il pagamento",
            "h3": "Effettua il pagamento sul sito ufficiale di Aliexpress<br/> per completare i tuoi ordini.",
            "h4": "Cosa succede in caso di ritardo nei pagamenti?",
            "p1": "1. I tuoi ordini potrebbero essere annullati.",
            "p2": "2. Il tuo account potrebbe riscontrare un fallimento del pagamento",
            "h5": "Completa il tuo pagamento il prima possibile per soddisfare i tuoi clienti!"
        },
        agency_orders: {
            upload_tn_title: 'Carica il numero di tracking manualmente',
            upload_tn_sub_title: 'Dopo aver caricato il tracking, DSers tornerà in Shopify e segnerà il rispettivo ordine come "Completato".',
            upload_file_tip: `Clicca qui per caricare l'allegato`,
            uplaod_match_column: 'Seleziona i rispettivi campi nel file excel e li abbineremo automaticamente per te, poi caricali.',
            upload_agency_order_id: 'ID Ordine Agenzia',
            upload_product_id: 'ID prodotto',
            upload_sku_id: 'SKU ID Articolo',
            upload_shipping_carrier: 'Corriere',
            upload_custom_shipping_url: 'Url di tracking personalizzato',
            upload_tracking_number: 'Numero di tracciamento',
            upload_select_placeholder: 'Seleziona',
            upload_confirm: 'CONFERMARE',
            upload_payment_title: 'Informazioni importazione pagamento',
            upload_payment_sub_title: 'Carica un file excel contenente le spese di acquisto, DSers lo registrerà per te, il che ti aiuterà a gestire gli ordini.',
            upload_payment_currency: 'Valuta di acquisto (default USD)',
            upload_product_cost: 'Costo di acquisto',
            upload_shipping_cost: 'Costi di spedizione',
            download_order_info: `Esportazione informazioni sull'ordine`,
            download_by_select: '{value} ordini selezionati',
            download_by_date: 'Esporta gli ordini per data',
            download_title: 'Esporta',
            download_as: 'Esporta come',
            export_to_file_type_excel: 'File excel normale',
            download_btn: 'Esporta gli ordini',
            export_orders_action: 'Esporta informazioni ordine',
            import_payment_details: 'Importa info pagamento',
            import_tn_to_orders: 'Carica nr. tracking manualmente',
            mark_as_cancel: 'Cancella gli ordini manualmente',
            mark_as_cancel_title: `Conferma la cancellazione dell'ordine`,
            mark_as_cancel_desc: `L'ordine sarà segnato come cancellato su DSers e lo stato dell'ordine non sarà sincronizzato su Shopify.`,
            please_input_confirm: 'Premi su CONFERMARE.',
            mark_as_paid_title: `Segna l'ordine come pagato`,
            mark_as_paid_desc: `Assicurati che l'ordine sia avvenuto offline con il rivenditore. Dopo aver segnato manualmente l'ordine come pagato, sarà cambiato nello stato In attesa di pagamento.`,
            cancel_order_failed: `L'ordine non è stato cancellato, riprova più tardi.`,
            place_confirm_title: `Conferma le informazioni dell'ordine`,
            place_btn_tip: `Clicca sul tasto "Controllo massivo" e DSers verificherà l'indirizzo del tuo ordine.`,
            agency_canceled_tag: 'Cancellazione DSers',
            from_agency: 'Agency',
            agency_order_number_full: 'Agency order number',
            export_failed: 'Esportazione fallita, probabilmente a causa di un errore di rete, riprova più tardi.',
            export_failed_empty: `L'esportazione è fallita perché non c'è un'ordine con l'intervallo di tempo selezionato. Puoi selezionare un altro intervallo di tempo e riprovare.`,
            upload_success: 'Caricamento riuscito',
            upload_failed: 'Caricamento fallito',
            upload_failed_reason: `Scarica i motivi dell'errore`,
            uploading: 'Caricamento',
            export_download_link_title: 'Se il download non dovesse partire automaticamente, clicca qui',
            mark_as_paid_failed: `L'ordine non è stato segnato come pagato, riprova più tardi.`,
            exporting_orders: 'Esportazione',
            agency_fulfill_tip: 'Sei sicuro di voler COMPLETARE manualmente questi ordini?',
            agency_rollback_title: `Seleziona gli ordini rimandati e troverai gli ordini nella sezione Ordini in attesa. Le vecchie informazioni sull'ordine di Agency andranno perse dopo aver rimandato.`,
            agency_edit_tracking_no_tip: 'After you successfully modify a Tracking No, you may resend the mail to your buyer according to your Settings, please be careful',
            agency_auth_status_processing: 'Elaborazione ERP',
            agency_auth_status_offline: 'Ordine offline',
            agency_process_tag_tip: `Le informazioni dell'ordine sono state inviate all'ERP del tuo rifornitore, e il tempo di creazione stimato è di 2 ore. Una volta effettuata la creazione, puoi comunicare la quota di acquisto con il tuo rifornitore e pagare. Se lo stato non viene aggiornato da tempo, puoi provare a elaborare l'ordine manualmente. `,
            erp_payment_tip_title: 'Conferma',
            erp_payment_tip_content: `Abbiamo notato che l'ordine pagato contiene un ordine che deve essere creato nell'ERP del tuo fornitore. Conferma che il fornitore è stato informato sulle quantità dell'ordine prima di effettuare il pagamento.`,
            erp_auth_type_filter: 'Tipo',
            payment: {
                make_payment: 'Make Payment',
                make_payment_tip: 'Effettua i pagamenti per completare i tuoi ordini. Completa il pagamento il prima possibile per soddisfare i tuoi clienti!',
                currency_diff_title: 'Attenzione',
                currency_diff_content: 'ssicurati che gli ordini selezionati siano impostati con la stessa valuta del rivenditore. Puoi modificare la valuta, ricaricare il file CSV o pagare in blocchi in base alle varie valute.',
                currency_diff_btn: 'ACCETTO',
                payment_modal_title: 'Conferma le informazioni ordine & agenzia prima di pagare.',
                payment_1: "Due to restrictions from {type},  each order must be over $1.00. Please contact your supplier to modify.",

                edit_agency_info: `Modifica le informazioni dell'agenzia`,
                agency_info_name: `Nome dell'Agenzia`,
                agency_info_paypal: `PayPal dell'Agenzia`,
                agency_info_amount: 'Somma del pagamento stimata',
                agency_info_amount_v2: 'Payment amount',
                agency_inpayment_tip: 'The payment is being paid, please complete the rest of the payment.',
                get_payment_detail_failed: 'Il pagamento è scaduto.',
            },
            order_confirm_erp_title: 'ERP',
            order_confirm_erp_tip_1: `Hai collegato l'ERP di questo rivenditore. Dopo aver effettuato l'ordine con successo, invieremo l'ordine direttamente al tuo rivenditore.`,
            order_confirm_erp_tip_2: `Non hai collegato l'ERP di questo rivenditore. Dopo aver effettuato l'ordine con successo, dovrai esportare manualmente l'ordine e inviarlo al tuo rivenditore.`
        },
        "order_title": {
            "order_no": "Numero Ordine",
            "date": "Data",
            "country": "Paese",
            "income": "Reddito",
            "note": {
                "title": "Nota",
                "add": "aggiungi"
            },
            "customer_detail": "Dettaglio Cliente",
            "title": "Filtra ordine"
        },
        "edit_address": {
            "contact_name": "Nome Contatto:",
            "tel": "Tel:",
            "email": "Email:",
            "company": "Azienda:",
            "cpf": "Cpf",
            "country": "Paese:",
            "address": "Indirizzo:",
            "province": "Provincia:",
            "city": "Città:",
            "post_code": "CAP:",
            "clearance_info": "ID Personale di Autorizzazione",
            "alien_clearance_info": "Numero di Passaporto/Carta di Registrazione Straniero:",
            "clearance_info_required": "Il codice unico di autorizzazione personale consiste in un numero di 12 cifre che inizia con P. Compila questo campo se il cliente è nativo coreano.",
            "clearance_info_tip": "Il codice unico di autorizzazione personale consiste in un numero di 12 cifre che inizia con P (ad esempio, P682151173203). Clicca qui per avere maggiori informazioni.",
            "alien_clearance_info_required": "Inserisci il numero del tuo passaporto o della tua carta di registrazione per stranieri. Compila questo campo se il cliente NON è nativo coreano.",
            "alien_clearance_info_tip": "Formato errato. Inserisci 1- 13 cifre o lettere.Clicca qui per avere maggiori informazioni.",
            "rut_required": "Inserisci un numero RUT",
            "rut_incorrect": "C'è un errore con il numero RUT",
            "rut_tip": "Leggi il nostro blog <a href=\"https://help.dsers.com/orders-to-chile-specifications-for-shopify/\" target=\"_blank\">per</a> saperne di più",
            "unknow_wrong": "C'è qualcosa di errato nelle informazioni del tuo cliente. Controlla e correggi.",
            "enter_name": "Inserisci un nome di contatto",
            "enter_between": "Inserisci tra i caratteri caratteri {start} e {end} ",
            enter_write_tel: "Please fill in your real phone number here, enter between 5-16 numbers. Click <a href='https://help.dsers.com/fix-failed-orders-due-to-aliexpress-phone-number-regulations/' target='_blank'>here</a> to learn how to solve this problem",
            "korea_error": "Please enter {start} or {end} -digit phone number starting with 01 or 1 (e.g. 01012345678)",
            "italy_error": "Please enter {start} or {end} digits",
            "english_only": "Inserisci solo l'inglese",
            "max128": "La somma del nome del contatto e la lunghezza della società non possono superare i 128 caratteri.",
            "only_num_hyphens_forward": "Inserisci solo numeri e/o trattini '-' e/o barre avanti '/'.",
            "cpf_tip": "Il CPF potrebbe essere sbagliato. Se l'hai controllato, ignora questo messaggio. Vai su AliExpress per ricontrollare.",
            "enter_country": "Inserisci un Paese/Regione",
            "enter_address": "Inserisci un indirizzo",
            "enter_address_length": "Please ensure that your address is not less than 5 characters.",
            "select_province": "Seleziona uno Stato/Provincia/Regione",
            "enter_city": "Digita una Città",
            "city_not_match": "La città non corrisponde. eleziona dall'elenco a discesa.",
            "enter_zip": "Inserisci un codice postale",
            "enter_zip_1234": "Inserisci un codice postale di {length} cifre, come {ps}",
            "enter_zip_123456": "Inserisci un codice postale, come {ps}",
            "enter_zip_special": "Inserisci il tuo codice postale nel formato suggerito, come {ps}",
            "enter_zip_confirm": "Per la consegna internazionale, è richiesto un codice postale di 7 cifre. Puoi confermare con il tuo ufficio postale locale se necessario.",
            "only_num_spaces_hyphens": "Inserisci solo lettere/numeri/spazi/ifini latini",
            "france_phone_setting_tip": "Per gli ordini inviati in Francia, è richiesto un numero di cellulare di 10 cifre che inizi con 06.",
            "france_phone_customers_tip": "Per ordini inviati in Francia, richiede numeri di cellulare a 10 cifre."
        },
        "order_product": {
            "title_unarchive": " Rimosso dall'archivio: Dopo aver rimosso un ordine dall'archivio, potrai trovarlo nella tab \"Apri ordini\"",
            "variants": "Varianti:",
            "from": "Da",
            "from_aliexpress": "Aliexpress",
            "from_other": "Altri",
            "cost": "Costo",
            "quantity": {
                "price": "prezzo",
                "ok": "Ok",
                "max": "Massimo"
            },
            "dsers_shipping": "ePacket",
            "shipping_method": "Metodo di spedizione",
            "shipping_dialog": {
                "company": "Compagnia di Spedizione",
                "cost": "Costo di Spedizione",
                "time": "Tempo di Consegna Stimato",
                "tracking": "Informazioni di Tracking",
                "price": "Spedizione Gratuita",
                "days": "giorni",
                "available": "Disponibile",
                "unavailable": "Non disponibile",
                "ok": "Ok",
                "cancel": "Annulla",
                "setting_link": "Imposta il metodo di spedizione nel pannello delle impostazioni",
                "overrun_warning": "Hai superato il limite giornaliero di accesso ai dati dei prodotti AliExpress, ma puoi comunque importare e inviare i prodotti.",
                "async_frienght": "Update shipping method list",
                upcoming1: `Il metodo di spedizione non può essere impostato, il motivo potrebbe essere:`,
                upcoming2: `1) La mappatura non è impostata per questo prodotto.`,
                upcoming3: `2) Il fornitore non può consegnare alla destinazione dell'ordine. Confermare l'indirizzo di consegna con l'utente o utilizzare "Modifica" per passare a un fornitore che supporta la consegna a questa destinazione.`,
            },
            "shipping_cost_free": "gratis",
            "shipping_time": "giorni",
            "ali_order_no": "Numero d'ordine Ali",
            "tmall_order_no": "Numero d'ordine Tmall",
            "order_no": "Ordine No",
            "tracking_no": "Numero di tracciamento",
            "tracking_no_add": "aggiungi",
            "sync": "Sync",
            "ali_order_no_no_match": "Non riesco a trovare questo ordine a questo numero d'ordine Ali. Controlla.",
            "mapping": "Mappatura",
            "mapping_tip": "Clicca su Mappatura per gestire il fornitore di questo prodotto.",
            "customer_service": "Servizio clienti",
            "action": {
                "title": "Azione",
                "message": "Messaggio",
                "edit": "Modifica",
                "mark_as_fulfilled": "Contrassegna come Evaso",
                "bulk_edit": "Modifica in Blocco"
            },
            "fulfilled_by_other_tip": "Hai sbagliato ad evadere gli ordini? Se vuoi elaborare l'ordine tramite DSers, non puoi evaderlo manualmente su DSers o Shopify. E devi disattivare la funzione di evasione automatica su Shopify. Se hai bisogno di annullare gli ordini, clicca sul pulsante messenger <a style=\"color:#FF8F00\" href=\"https://help.dsers.com/re-order-fulfilled-orders-for-shopify/\" target=\"_blank\">per</a> ottenere aiuto. Controlla qui per ottenere un manuale operativo passo dopo passo.",
            "ae_order_error_tip": {
                "click": "Clicca",
                "message_us": "Inviaci un Messaggio",
                "why_see_this": "Perché vedo questo?",
                "ae_not_available": "Questo prodotto non è disponibile su AliExpress. Controlla su AliExpress.",
                "sku_not_found": "Lo SKU non è stato trovato. Clicca",
                "sku_not_found_after": "per aggiungere un link al prodotto AliExpress.",
                "no_shipped_country": "Questo prodotto non può essere spedito in questo paese. Clicca&nbsp;",
                "no_shipped_country_after": "per cambiare un prodotto AliExpress.",
                "freight_error": "Inserisci le informazioni sull'indirizzo dell'ordine per scegliere il metodo di spedizione",
                "set_shipping": {
                    "p1": "Scegli un",
                    "p2": "metodo di spedizione",
                    "p3": "per attivare la funzione dell'ordine.",
                    "p4": "Imposta il metodo di spedizione nel pannello Impostazioni"
                },
                "add_ae_link": {
                    "p1": "Aggiungi il link AliExpress con successo! Scegli un",
                    "p2": "metodo di spedizione",
                    "p3": "alla funzione ordine attivo."
                },
                "add_ae_link_succ": "Aggiungi il link AliExpress con successo!",
                "delete_by_shopify": "Il prodotto(i) è stato eliminato da Shopify"
            }
        },
        "bind_product": {
            "ae_url": "URL AliExpress",
            "ae_url_tip": "Digita URL prodotto AliExpress",
            "tmall_url_tip": "Digita URL prodotto Tmall",
            "from_aliexpress": "Da AliExpress",
            "cost": "Costo",
            "select_varint": "Le varianti correnti sono {num} Seleziona la variante corrispondente:",
            "overrun_warning": "Hai superato il limite giornaliero per accedere alle informazioni sul prodotto"
        },
        "order_foot": {
            "cost": "Costo del prodotto",
            "shipping": "Spedizione",
            "total": "Costo totale",
            "pending_authorized": "Autorizzato",
            "pending_authorized_tip": "Questo ordine deve catturare il pagamento su Shopify.",
            "shopify_deleted": "Shopify cancellato",
            "is_shopify_delete": "Ordine cancellato su Shopify. Se vuoi ancora processare l'ordine, crea un nuovo ordine simile su Shopify.",
            "product_cost_tip": {
                "title": "Alcune ragioni possono aver causato la differenza di costo:",
                "p1": "1. DSers mostra solo il costo standard di base su AliExpress. Ma il costo reale varia a seconda del paese in cui stai vendendo.",
                "p2": "2. DSers non supporta diverse valute al momento, solo USD. Assicurati che la valuta sia la stessa su DSers e AliExpress quando controlli i costi dei prodotti.",
                "p3": "3. DSers non aggiorna il costo del prodotto per gli ordini evasi o in sospeso, una volta che lo stato dell'ordine è aggiornato a ordine in attesa, il costo del prodotto si aggiornerà automaticamente.",
                "p3_1": "Si prega di notare che il costo del prodotto verrà aggiornato ogni 24 ore su DSers.",
                "p4": "4. Le tasse saranno applicate agli ordini che vengono spediti in alcuni paesi, come gli USA e l'Australia.",
                "p_end": "Il costo corretto del prodotto apparirà dopo aver effettuato l'ordine su AliExpress."
            },
            "shipping_tip": "Il costo finale di spedizione mostrato qui sarà lo stesso degli ordini AliExpress e dipenderà dai tuoi fornitori. Quando si applica uno sconto ai tuoi ordini AliExpress, l'importo dello sconto sarà contato nel costo di spedizione qui. <a href=\"https://help.dsers.com/shipping-fees-on-dsers-for-shopify/\" target=\"_blank\">Consulta il blog per ulteriori dettagli</a>.",
            "order_again": "Ordina di nuovo",
            "order": "Ordina",
            "order_tip": "Devi mappare il prodotto per far sapere ai DSers con quale fornitore stai lavorando prima di fare un ordine all'ingrosso. <a href=\"https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/\" target=\"_blank\">Guarda questo articolo</a>",
            "tax_title": "Tax",
            "other_title": "Other",
            "tax_tip": "If you want the tax displayed here, please use the \n\t\t\t<a\n\t\t\t\thref=\"https://www.dsers.com/dsers-chrome-extension/\"\n\t\t\t\ttarget=\"_blank\"\n\t\t\t>\n\t\t\t\tDSers Chrome Extension\n\t\t\t</a> to place the order.",
            "shipping_tip_ext": "The final shipping cost shown here will be the same as on the AliExpress orders and will depend on your suppliers.",
            "other_tip_ext": "When a discount applies to your AliExpress orders, the discount amount will be counted in here.",
            "order_displayed": "The order displayed price is not in USD. DSers converted to USD according to the exchange rate. This may be different from the actual price on AliExpress. DSers only displays it for information, it will not affect the amount to pay. Please check AliExpress to see the actual amount to pay"
        },
        "sync_order_alert": "DSers si sincronizza automaticamente da Shopify e AliExpress quando accedi a DSers. La sincronizzazione durerà 10 minuti. DSers non si sincronizzerà più automaticamente ogni 6 ore per assicurare una sincronizzazione più stabile. Assicurati di effettuare il login se vuoi sincronizzare lo stato dell'ordine.",
        "cpf_alert": "Per via dei nuovi regolamenti, tutti gli ordini per il Brasile devono contenere il numero CPF, o gli ordini non andranno a buon fine. DSers farà apparire il numero CPF sui tuoi pacchetti. Puoi aggiungere il numero CPF del tuo cliente nel campo Azienda, in Dettagli Cliente.",
        "time_zone_placeholder": "Fuso Orario",
        "af_more_action": "Sincronizza il numero di tracking con Shopify",
        "shopify_fulfilled": "Shopify evaso",
        "shopify_canceled": "Shopify annullato",
        "shopify_partially_fulfilled": "Shopify parzialmente evaso",
        "shopify_partially_canceled": "Shopify parzialmente annullato",
        "shopify_refunded": "Shopify rimborsato",
        "ali_canceled": "Ali annullato",
        "tm_canceled": 'Tmall canceled',
        "zero_tip": "La richiesta di evasione manuale su Shopify ha causato il problema. Per favore evadi manualmente questo ordine su DSers, e poi annullare l'ordine. La quantità sarà aggiornata.",
        "err_deleted_in_shopify": "Questo prodotto è stato eliminato in Shopify",
        "err_may_be_blacklisted": "L'ordine è fallito per quattro possibili motivi.",
        "err_france": "Sostituisci France nel campo Country con { Guadeloupe } a causa dei nuovi termini di AliExpress",
        "err_out_of_stock": "Il prodotto è esaurito. Contatta il tuo fornitore o passa ad un nuovo fornitore.",
        awaiting_error_tip11: "A causa delle limitazioni all'acquisto imposte dal rivenditore principale, il tuo ordine è stato inserito usando il tuo secondo rivenditore. Se intendi modificare il rivenditore, sei pregato di cancellare l'ordine e di modificare manualmente il rivenditore sotto la sezione Cancellati",
        awaiting_error_tip13: "Il tuo ordine è stato inserito usando il secondo rivenditore poiché il primo rivenditore non ha abbastanza prodotti in magazzino. Se intendi modificare il rivenditore, sei pregato di cancellare l'ordine e di modificare manualmente il rivenditore sotto la sezione Cancellati",
        awaiting_error_tip14: "La logistica del rivenditore principale non supporta la consegna alla destinazione, il tuo ordine è stato inserito usando il tuo rivenditore secondario. Se intendi modificare il rivenditore, sei pregato di cancellare l'ordine e di modificare manualmente il rivenditore sotto la sezione Cancellati",
        awaiting_error_tip15: "Il rivenditore principale ti ha messo nella blacklist, il tuo ordine è stato inserito usando il tuo secondo rivenditore. Se intendi modificare il rivenditore, sei pregato di cancellare l'ordine e di modificare manualmente il rivenditore sotto la sezione Cancellati",
        awaiting_error_tip21: "Lo SKU del rivenditore principale non può essere venduto, il tuo ordine è stato inserito usando il tuo secondo rivenditore. Se intendi modificare il rivenditore, sei pregato di cancellare l'ordine e di modificare manualmente il rivenditore sotto la sezione Cancellati",
        awaiting_error_tipOther: "Si è riscontrato un problema con il primo rivenditore e il tuo ordine è stato inserito usando il secondo rivenditore. Se intendi modificare il rivenditore, sei pregato di cancellare l'ordine e di modificare manualmente il rivenditore sotto la sezione Cancellati",
        tmall_error_code1: `Il sistema è occupato, riprova più tardi o contatta l'assistenza clienti`,
        tmall_error_code2: '',
        tmall_error_code3: `Il metodo di spedizione di questo prodotto non è valido, riprova per inviare nuovamente l'ordine`,
        tmall_error_code4: `La quantità acquistata non raggiunge la quantità minima di acquisto, fare clic per contattare il servizio clienti o passare a un nuovo fornitore.`,
        tmall_error_code5: `Questo prodotto non può essere attualmente acquistato via PC, clicca per contattare l'assistenza clienti oppure passa a un altro fornitore`,
        tmall_error_code6: `L'inventario per questo prodotto non è sufficiente, clicca per contattare l'assistenza clienti oppure passa a un nuovo fornitore`,
        tmall_error_code7: `Questo prodotto non è temporaneamente in vendita in questa regione, clicca per contattare l'assistenza clienti oppure passa a un nuovo fornitore`,
        tmall_error_code8: `Questo prodotto non è temporaneamente disponibile per l'acquisto, riprova e invia di nuovo l'ordine`,
        tmall_error_code9: `Questo prodotto è attivo, e l'evento non è ancora iniziato, clicca per contattare l'assistenza clienti oppure passa a un nuovo fornitore`,
        tmall_error_code10: 'La quantità acquistata supera il limite di acquisto, fare clic per contattare il servizio clienti o passare a un nuovo fornitore.',
        tmall_error_code11: `Detrazione di inventario del prodotto fallita, riprova e invia nuovamente l'ordine`,
        tmall_error_code12: `Questo prodotto non è al momento disponibile per l'acquisto, clicca per contattare l'assistenza o passa a un nuovo fornitore`,
        tmall_error_code13: `Impossibile ottenere il prezzo del prodotto, riprova e invia nuovamente l'ordine`,
        tmall_error_code14: '',
        tmall_error_code15: `I coupon scaduti hanno fatto fallire l'ordine. Rimuovere i coupon e riprovare.`,
        tmall_error_code16: `Non esiste alcun metodo di spedizione disponibile per questo prodotto, clicca per contattare l'assistenza o passa a un nuovo fornitore`,
        tmall_error_code17: '',
        "err_may_be_out_of_stock": "Il tuo fornitore potrebbe essere esaurito per questo ordine. Per favore vai su AliExpress e controlla.",
        "err_limit": "Puoi acquistare solo { limite } pezzo(i) per questo prodotto al massimo, contatta il fornitore per ricontrollare o cambia il fornitore per questo prodotto.",
        "err_limit_blur": "Puoi acquistare solo una certa quantità di questo prodotto, contatta il fornitore per ricontrollare o cambia fornitore di questo prodotto per effettuare l'ordine.",
        "err_not_available": "Prodotto non disponibile su AliExpress, cambia il fornitore per questo prodotto.",
        "err_not_shipping": "The province selected in Shopify is set as a country on AliExpress, please manually change the destination in the Customer Detail section of DSers. If the product can't be shipped to the country of destination, we recommend you change the suppliers.",
        "err_blacklisted": "Il tuo fornitore ti ha inserito nella blacklist. Contatta il tuo fornitore o trova un altro fornitore.",
        "err_un_mapped": "Prodotto non mappato o parzialmente mappato, si prega di mappare correttamente il fornitore su DSers. Controlla <a href=\"https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/\" target=\"_blank\">qui</a> per sapere come fare.",
        "err_shipping_to_china": "Ricorda che AliExpress non supporta la spedizione in Cina.",
        "err_shipping_to_RU": "A causa di una limitazione dell'interfaccia di AliExpress, DSers non supporta la spedizione in Russia al momento. Effettuare manualmente l'ordine/i su AliExpress e di inserire il numero d'ordine AliExpress nell'ordine su DSers.",
        err_shipping_address: "Gli indirizzi Shopify o il tuo ordine potrebbero non coincidere con l'indirizzo AliExpress. Il metodo di spedizione qui mostrato sarà quello usato nel paese di destinazione di AliExpress",
        "ae_account_disabled": "Il tuo account AliExpress è stato disattivato da AliExpress. Per favore crea un nuovo account AliExpress e collegalo.",
        "err_extra_address": "Dettaglio cliente errato o mancante, controlla il messaggio di errore e correggi di conseguenza.",
        "err_add_address_ukraine": "(If the destination is now a war zone, you will not be able to place the order)",
        "qty_zero_tip": "L'evasione dell'ordine è stata richiesta su Shopify. Poiché la funzione di evasione della richiesta non è conforme a come DSers processa gli ordini, per favore modifica l'ordine in base alla guida nel blog. Clicca qui per avere maggiori informazioni",
        "ae_tip_IN_error": "At the moment, orders for India cannot be placed successfully. Please click  <a href=\"https://customerservice.aliexpress.com/knowledgeDetail?categoryId=21039549&hcAppId=1248&hcFromCode=Xn2Tk1tb7&hcSessionId=26-1248-70544a7b-e1ed-41af-8a2d-1fa5a625173e&knowledgeId=627712&spm=service_hall.24300488.searchresult.627712）\" target=\"_blank\">here</a> to get more information.",
        "ae_code_11_error": "A causa di alcuni problemi con AliExpress, quando acquisti merce da un unico negozio da spedire in Francia, il valore totale della merce non può superare i 150 EUR. Si prega di dividere questo ordine in più ordini e riprovare.",
        "ae_code_11_error_1": "Il prezzo del tuo ordine per la Francia supera i 150 euro. Secondo i regolamenti, devi fornire le informazioni sull'IVA. Clicca qui per passare alle impostazioni. Clicca qui per avere maggiori informazioni.",
        "ae_code_11_error_btn": "I coupon scaduti hanno fatto fallire questo ordine. Per favore rimuovi i coupon e riprova.",
        "ae_code_11_error_btn_ae": `These possible reasons made this order fail:<br/>
        1. Expired coupons made this order fail. Please remove the coupons and try again.<br/> 
        2. Your AliExpress account was under risk control, please <a href="https://www.messenger.com/t/DSersOfficial" target="_blank" >connect</a> another AliExpress account to try again or contact our support.`,
        "ae_code_11_error_2": "Il sistema ha rilevato che il tuo account AliExpress non può effettuare ordini normalmente, per favore cambia l'account AliExpress collegato (clicca <a href=\"/app/settings?tabChangeSta=8\" target=\"_blank\">qui per </a> cambiare).",
        "ae_code_11_error_3": "Il tuo ordine ha incontrato un errore sconosciuto, <a href=\"https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2FDSersOfficial\" target=\"_blank\">per favore contatta il servizio clienti</a>.",
        "err_sku_change": "Il tuo fornitore ha modificato lo SKU, DSers aggiornerà le informazioni sul prodotto ogni 2 ore, per favore rimappa il prodotto e riprova più tardi.",
        "supplier_account_disabled": "Account fornitore disabilitato",
        "err_ae_fake_failed": "A causa del recente aggiornamento dell'interfaccia di AliExpress, questo ordine potrebbe essere già stato effettuato con successo. Per favore controlla su AliExpress per evitare qualsiasi perdita. Se l'ordine è andato a buon fine, per favore inserisci il numero d'ordine manualmente. Se l'ordine non va a buon fine, si prega di effettuare nuovamente l'ordine.",
        "currency_not_supported": "Valuta non supportata",
        "cpt_tip": "Si prega di inserire un numero CPF valido a 11 cifre",
        "coupon_expired": "Promozione o coupon scaduto",
        "duplicated_order": "Ordine duplicato",
        "err_varients_change": "Una variante di questo prodotto è cambiata, per favore rimappa il prodotto, poi effettua l'ordine",
        "err_code_21": "Please note that the order placement failed because the product's SKU has changed, please re-map and place the order again.",
        "place_order_timeout": "L'ordine è scaduto, per favore vai su AliExpress per controllare se l'ordine è stato effettuato con successo. Se l'ordine è andato a buon fine, inserisci manualmente il numero d'ordine AliExpress, se l'ordine fallisce, effettua nuovamente l'ordine",
        "order_processing": "Elaborazione dell'ordine ...",
        "order_reault_failed": "Gli ordini non sono andati a buon fine! Per favore controlla .",
        "order_reault_succ": "Vai a fare il pagamento su AliExpress!",
        "order_reault_await_length": "ordini sono stati elaborati",
        "order_reault_is_await_length": "ordini sono stati elaborati",
        "order_reault_succ_length": "Elaborato con successo",
        "order_reault_failed_length": "Elaborazione fallita",
        "order_reault_makepayment": "Fai un pagamento",
        "order_reault_checkfailed": "Controlla l'ordine fallito",
        "roll_back_btn": "Roll back ordini",
        "roll_title": "Seleziona gli ordini rimandati e troverai gli ordini nella sezione Ordini in attesa. Le vecchie informazioni sull'ordine di AliExpress andranno perse dopo aver rimandato.",
        "roll_title_tm": "Seleziona gli ordini rimandati e troverai gli ordini nella sezione Ordini in attesa. Le vecchie informazioni sull'ordine di Tmall andranno perse dopo aver rimandato.",
        "roll_desc": "Ordini rimandati",
        "place_order_confirm_tooltip": "Please first select a shipping method for all orders before you confirm.",
        "archive_title": "Archived orders",
        "archive_desc": "Once an order is archived, the order will disappear from the current tab. If you want to view the archived order, please go to the Archived orders page.",
        "global_place_order": "Ordine",
        "global_place_order_tip": "Effettua un ordine su AliExpress.",
        "confirm": {
            "tm_title": "Per favore controlla le informazioni di spedizione prima di effettuare ordini su Tmall.",
            "title": "Per favore controlla le informazioni di spedizione prima di effettuare ordini su AliExpress.",
            "title_succ": "Successo!",
            "title_fail": "Clicca su Rivedi per controllare gli ordini falliti.",
            "table_title": {
                "order": "Ordine",
                "product": "Prodotto",
                "income": "Reddito",
                "product_cost": "Costo del Prodotto",
                "country": "Paese",
                "shipping_method": "Metodo di spedizione",
                "shipping_fee": "Spese di spedizione",
                "shipping_time": "Tempo di spedizione",
                "feedback": "Feedback"
            },
            "error_msg": {
                "err_101": "Il prodotto(i) è stato cancellato da shopify",
                "err_0": "Clicca su Mappatura per aggiungere un link al prodotto AliExpress.",
                "ae_has_product_no": "Questo prodotto non è disponibile su AliExpress. Controlla su AliExpress.",
                "child_cost_0": "Lo SKU non è stato trovato. licca su Mappatura per aggiungere un link al prodotto AliExpress.",
                "express_length_0": "Questo prodotto non può essere spedito in questo paese. Clicca su Mappatura per cambiare un prodotto AliExpress.",
                "freight_math_info_no": "Scegli un metodo di spedizione per attivare la funzione ordine.",
                "freight_math_info_no_bind": "Aggiungi il link AliExpress con successo! Scegli un metodo di spedizione per attivare la funzione ordine."
            },
            "item": "{ num, plural, =1 {Item} other {Items} }",
            "item_new": "Item(s)",
            "confirm": "Conferma",
            "income_tip": "L'importo del reddito qui non comprende lo sconto dell'ordine e il coupon. Potrebbe essere diverso dal valore effettivo.",
            "total_cost": "Costo totale",
            "revise": "Rivedere",
            "make_payment": "Effettua il pagamento"
        },
        "fulfill_confirm": {
            "title": "Segna gli ordini selezionati come soddisfatti (questo non sarà sincronizzato con il tuo negozio Shopify)",
            "table_title": {
                "order": "Ordine",
                "product": "Prodotto",
                "ae_order_number": "Numero d'ordine AliExpress",
                "feedback": "Feedback"
            },
            "item": "ordini",
            "mark_as_fulfilled": "Contrassegna come evaso",
            "confirm_title": "Sei sicuro di evadere gli ordini manualmente?",
            "confirm_dec": "Sei sicuro di voler evadere manualmente questi ordini? Si prega di notare che DSers smetterà di sincronizzare le informazioni dell'ordine per gli ordini che sono stati effettuati su AliExpress.",
            "continue": "Continua",
            "checkTitle": "Segna questo ordine come completato su Shopify"
        },
        "bind_ae_dialog": {
            "title": "Collegamento a AliExpress per effettuare ordini multipli",
            "shopify": "Shopify",
            "dsers": "DSers",
            "go_ae_tip": "Verrai reindirizzato su AliExpress",
            "go_ae": "Link AliExpress"
        },
        "message_dialog": {
            "title": "Lascia un messaggio ai venditori AliExpress",
            "tm_title": "Lascia un messaggio ai venditori Tmall",
            "desc": "I venditori AliExpress riceveranno questo messaggio insieme alle informazioni del tuo ordine.",
            "tm_desc": "I venditori Tmall riceveranno questo messaggio insieme alle informazioni del tuo ordine.",
            "placeholder": "Inserisci al massimo 1000 caratteri (al massimo 250 caratteri cinesi)",
            "save": "Salva",
            title_new: 'Lascia un messaggio ai rivenditori',
            desc_new: `I rivenditori riceveranno questo messaggio insieme alle tue informazioni dell'ordine.`,
        },
        "go_ae_pay_dialog": {
            "h3": "Effettua i tuoi pagamenti sul sito AliexpressOfficial <br/> per completare i tuoi ordini.",
            "h4": "Cosa succede in caso di ritardi nei pagamenti?",
            "p1": "1. I tuoi ordini potrebbero essere annullati.",
            "p2": "2. Il tuo account potrebbe riscontrare un fallimento del pagamento",
            "h5": "Completa il tuo pagamento il prima possibile per <br/> soddisfare i tuoi clienti!",
            "no_show": "Non mostrarlo più",
            "make_payment": "Effettua il pagamento",
            mark_as_paid: 'Mark as paid'
        },
        "product_error_msg": {
            "can_not_shipped_this_country": "Il prodotto non può essere spedito in questo paese. Aggiungi un altro link AliExpress."
        },
        "options_different_tip": "Ricorda: l'opzione variante non corrisponde, rcontrolla.",
        "roll_back_confirm_title": "Sei sicuro di voler eseguire questa azione?",
        "roll_back_confirm_tip": "Inserisci CONFERMARE per annullare gli ordini.",
        "roll_back_awaiting_shopify_hook_tip": "DSers sta annullando l'ordine(i) su Shopify ora, l'ordine(i) sarà spostato nella scheda Ordine in attesa tra pochi secondi, per favore aspetta.",
        "no_data": {
            "no_data": "Nessun dato",
            "title": "La tua ricerca potrebbe non avere risultati per una delle seguenti ragioni:",
            "p4": "1.Se hai archiviato l'ordine su Shopify, controlla il menu Archiviato in DSers per trovare l'ordine",
            "p1": "2.Le informazioni che hai inserito non sono accurate",
            "p2": "3. La ricerca non rientra nell'intervallo di tempo attualmente impostato",
            "p3": "4.La ricerca potrebbe includere un prodotto nascosto",
            Pendings: {
                title: 'Pending orders will be displayed here',
                detail: `Orders in the Pending tab are orders for which the payment from your customers has not yet been captured in Shopify.`
            },
            AwaitingOrders: {
                title: 'Orders awaiting to be placed will be displayed here',
                detail: `Orders in the Awaiting order tab are orders for which the payment from your customers has been captured on Shopify and are waiting to be processed to Tmall.`
            },
            AwaitingPayments: {
                title: 'Awaiting payment orders will be displayed here',
                detail: `Orders in the Awaiting payment tab are orders that have been placed on Tmall but not paid yet. `
            },
            AwaitingShipments: {
                title: 'Awaiting shipment orders will be displayed here ',
                detail: `Orders in the Awaiting shipment tab are orders that have been paid on Tmall but have not been shipped yet.`
            },
            AwaitingFulfillments: {
                title: 'Awaiting fulfillment orders will be displayed here',
                detail: 'Orders in the Awaiting fulfillment tab are orders only partially fulfilled.'
            },
            Fulfilleds: {
                title: 'Fulfilled orders will be displayed here',
                detail: `Orders in the Fulfilled tab are orders that have been shipped out by your Tmall supplier and orders that were automatically or manually fulfilled.`
            },
            Canceleds: {
                title: 'Canceled orders will be displayed here',
                detail: 'Orders in the Canceled tab are orders that have been (partially) canceled / (partially) refunded on Tmall or Shopify.'
            },
            FailedOrders: {
                title: 'Failed orders will be displayed here',
                detail: 'Orders in the Failed tab are orders which could not be placed to Tmall successfully due to errors or restrictions.'
            },
            "Pending": {
                "title": "Gli ordini in sospeso saranno mostrati qui ",
                "detail": "Gli ordini nella tab In Sospeso sono gli ordini per i quali non hai ancora ricevuto il pagamento su Shopify da parte del cliente. Clicca <a href={href} target=\"_blank\">qui</a> per scoprire di più sugli ordini nella tab In sospeso."
            },
            "AwaitingOrder": {
                "title": "Gli ordini in attesa di elaborazione saranno mostrati qui",
                "detail": "Gli ordini nella tab In attesa di ordine sono gli ordini per i quali hai ricevuto il pagamento su Shopify da parte del cliente e che sono in attesa di essere elaborati da AliExpress. Clicca <a href={href} target=\"_blank\">qui</a> per scoprire di più sugli ordini nella tab In attesa di ordine."
            },
            "AwaitingPayment": {
                "title": "Gli ordini in attesa di pagamento saranno mostrati qui",
                "detail": "Gli ordini nella tab In attesa di pagamento sono gli ordini elaborati su AliExpress ma ancora non pagati. Clicca <a href={href} target=\"_blank\">qui</a> per scoprire di più sugli ordini nella tab In attesa di pagamento."
            },
            "AwaitingShipment": {
                "title": "Gli ordini in attesa di spedizione saranno mostrati qui",
                "detail": "Gli ordini nella tab In attesa di spedizioni sono gli ordini pagati su AliExpress ma non ancora spediti. Clicca <a href={href} target=\"_blank\">qui</a> per scoprire di più sugli ordini nella tab In attesa di pagamento."
            },
            "AwaitingFulfillment": {
                "title": "Gli ordini in attesa di completamento sono mostrati qui ",
                "detail": "Gli ordini nella tab In attesa di completamento sono ordini solo parzialmente completati. Clicca <a href={href} target=\"_blank\">qui</a> per scoprire di più sugli ordini nella tab In attesa di completamento."
            },
            "Fulfilled": {
                "title": "Gli ordini completati sono mostrati qui ",
                "detail": "Gli ordini nella tab Completati sono ordini spediti dal tuo fornitore AliExpress e gli ordini che sono stati automaticamente o manualmente completati. Clicca <a href={href} target=\"_blank\">qui</a> per scoprire di più sugli ordini nella tab Completati."
            },
            "Canceled": {
                "title": "Gli ordini cancellati sono mostrati qui",
                "detail": "Gli ordini nella tab Cancellati sono ordini che sono stati (parzialmente) cancellati / (parzialmente) rimborsati su AliExpress o Shopify. Clicca <a href={href} target=\"_blank\">qui</a> per scoprire di più sugli ordini nella tab Cancellati."
            },
            "FailedOrder": {
                "title": "Gli ordini falliti sono mostrati qui ",
                "detail": "Gli ordini nella tab Falliti sono ordini non completati che non sono stati elaborati su AliExpress a causa di errori o restrizioni. Clicca <a href={href} target=\"_blank\">qui</a> per scoprire di più sugli ordini nella tab Falliti."
            }
        },
        agency_open_no_data: {
            Pending: {
                title: 'Pending orders will be displayed here',
                detail: `Orders in the Pending tab are orders for which the payment from your customers has not yet been captured in Shopify. `
            },
            AwaitingOrder: {
                title: 'Orders awaiting to be placed will be displayed here',
                detail: `Orders in the Awaiting order tab are orders for which the payment from your customers has been captured on Shopify and are waiting to be processed to agencies. `
            },
            AwaitingPayment: {
                title: 'Awaiting payment orders will be displayed here',
                detail: `Orders in the Awaiting payment tab are orders that you need to make payment to agencies.`
            },
            AwaitingShipment: {
                title: 'Awaiting shipment orders will be displayed here ',
                detail: `Orders in the Awaiting shipment tab are orders that paid to suppliers through DSers, or marked as paid manually and awaiting for shipment.`
            },
            AwaitingFulfillment: {
                title: 'Awaiting fulfillment orders will be displayed here',
                detail: 'Orders in the Awaiting fulfillment tab are orders only partially fulfilled.'
            },
            Fulfilled: {
                title: 'Fulfilled orders will be displayed here',
                detail: `Orders in the Fulfilled tab are orders that have been shipped out by your agencies and orders that were automatically or manually fulfilled. `
            },
            Canceled: {
                title: 'Canceled orders will be displayed here',
                detail: 'Orders in the Canceled tab are orders that have been (partially) canceled / (partially) refunded on Shopify or by agencies. '
            },
            FailedOrder: {
                title: 'Failed orders will be displayed here',
                detail: 'Orders in the Failed tab are orders which could not be placed to agencies successfully due to errors or restrictions.'
            }
        },
        "open_no_data": {
            "title": "1. Non riesci a trovare il tuo primo ordine da piazzare su AliExpress?",
            "p1": "2. Controlla due volte la scheda Fuflilled per localizzare l'ordine (o gli ordini);",
            "p2": "3. Se localizzi l'ordine(i) nella scheda Fulfilled;",
            "p3": "4. Vai su Shopify - Impostazioni - Check-out, disattiva la funzione di auto-empimento come mostrato qui sotto;",
            "p4": "5. Assicurati di cliccare su Salva per aggiornare le Impostazioni;",
            "p5": "6. Vai alla scheda Completato e clicca sul pulsante Altre azioni per annullare un ordine completato selezionato.",
            "p6": "1. Se hai archiviato l'ordine su Shopify, controlla il menu Archiviato in DSers per trovare l'ordine;",
            "Pending": "Clicca <a href={href} target=\"_blank\">qui</a> per scoprire di più sugli ordini nella tab In sospeso.",
            "AwaitingOrder": "Clicca <a href={href} target=\"_blank\">qui</a> per scoprire di più sugli ordini nella tab In attesa di ordine.",
            "AwaitingPayment": "Clicca <a href={href} target=\"_blank\">qui</a> per scoprire di più sugli ordini nella tab In attesa di pagamento.",
            "AwaitingShipment": "Clicca <a href={href} target=\"_blank\">qui</a> per scoprire di più sugli ordini nella tab In attesa di pagamento.",
            "AwaitingFulfillment": "Clicca <a href={href} target=\"_blank\">qui</a> per scoprire di più sugli ordini nella tab In attesa di completamento.",
            "Fulfilled": "Clicca <a href={href} target=\"_blank\">qui</a> per scoprire di più sugli ordini nella tab Completati.",
            "Canceled": "Clicca <a href={href} target=\"_blank\">qui</a> per scoprire di più sugli ordini nella tab Cancellati.",
            "FailedOrder": "Clicca <a href={href} target=\"_blank\">qui</a> per scoprire di più sugli ordini nella tab Falliti."
        },
        "dsers_product_deleted": "2. Prodotto cancellato su DSers, per favore clicca qui per controllare come processare l'ordine su DSers.",
        "variant_delete_tip": "Variante eliminata su Shopify. Clicca su Mappatura per mappare un fornitore per la variante eliminata.",
        "YANWEN_JYT_shipping_tip": "Il metodo di spedizione selezionato non è più disponibile su AliExpress. Controlla con il tuo fornitore e seleziona un altro metodo di spedizione.",
        "err_deleted_in_dsers": "Questo prodotto è stato eliminato su DSers, per favore clicca <a href={href} target=\"_blank\">qui per</a> controllare come elaborare l'ordine su DSers",
        "pending_tip": "Assicurati di aver catturato il pagamento dell'ordine(i) su Shopify. Se un ordine è ancora in sospeso dopo aver catturato il pagamento, controlla",
        "pending_tip_end": "per aggiornare manualmente lo stato dell'ordine.",
        "ae_sync_not_done": "È in corso una sincronizzazione e non è ancora finita.",
        "ae_sync_more_then_5": "Non puoi sincronizzare manualmente più di 5 volte al giorno.",
        "select_shipping_method_tip": "Seleziona un metodo di spedizione.",
        "ful_with_tracking": "Evaso con un numero di tracking",
        "ful_by_shopify": "Evaso da Shopify",
        "ful_manually": "Evaso manualmente su DSers",
        "partial_completion_of_order": "Il tuo fornitore ha inviato diversi pacchetti per questo prodotto. Evaderemo l'ordine quando tutti i numeri di tracking verranno sincronizzati.",
        "ae_splited_order": "L'ordine è diviso in più ordini AliExpress. Se c'è una differenza nei numeri d'ordine, controlla e cambia manualmente",
        "freight_not_supported": "Questo paese non supporta il metodo di spedizione attualmente selezionato, scegline un altro. Clicca qui se ti serve aiuto.",
        "edit_sp_tooltip": "Hai modificato l'ordine cliccando su Azione - Modifica.Se vuoi cambiare il fornitore di questo ordine, utilizza la funzione Azione - Modifica.",
        "dsers_action_edit": "DSers Azione Modifica",
        "variant_delete_tag": "Variante cancellata da Shopify",
        "go_ae_check_order": "Vai a AliExpress",
        "may_be_fake_failed_order1": "Il tuo ordine",
        "may_be_fake_failed_order2": "potrebbe essere stato piazzato con successo su AliExpress. Per evitare qualsiasi perdita, per favore controlla su AliExpress",
        "order_fulfill": "Evasione dell'ordine fallita,",
        "token_authorize_info": "Il token del negozio corrente è scaduto,",
        "order_pending": "L'ordine è in fase di evasione",
        "token_authorize_info_btn": "clicca sull'impostazione per riautorizzare",
        order_onHold_info: "Manually fulfill orders",
        order_onHold_info_tip: "Synchronizing",
        order_onHold_snync_errorMess: 'Your order is on hold in Shopify and cannot be automatically fulfilled. Please go to Shopify to change the on hold status and try again manually',
        order_onHold_snync_tip: "Click this button to sync tracking numbers to your store and fulfill this order on Shopify",
        "order_pending_btn": "Attendi il risultato dell'evasione",
        "archive": "Archivia",
        "test_order_tip": "Questo ordine è un ordine di prova e il numero d'ordine generato è un numero d'ordine virtuale",
        "conada_error": "Inserisci il tuo nome completo come appare sul tuo ID. Clicca <a target=\"_blank\" href=\"https://help.dsers.com/orders-to-canada-specifications-for-shopify/\">qui per</a> sapere come risolvere questo problema.",
        "cl_rut_errtip": "RUT potrebbe essere sbagliato. Se l'hai controllato, ignora questo messaggio. Vai su AliExpress per ricontrollare.",
        "cl_rut_errtipNo": "Per favore inserisci 2-12 cifre o lettere, come 12123234-K",
        "rut_banner": {
            "title": "Orders to Chile RUT number requirements",
            "btn_text": "learn more"
        },
        "err_may_be_listed_5": "\n      1. Puoi acquistare solo una certa quantità di questo prodotto, contatta il fornitore per ricontrollare o cambia fornitore di questo prodotto per effettuare l'ordine.<br/>\n      2. The order will ship to France and the supplier's product value exceeds 150 EURO, please change the supplier or apply to be a Business Buyer on your Aliexpress account.<br/>\n      3. The supplier's product link is unavailable.\n    4.L'ordine contiene fornitori AliExpress che richiedono diverse valute per il pagamento, inserisci gli articoli separatamente.\n    ",
        "err_address_yg": "It is recommended that you replace \"{country}\" with United Kingdom in the Country field. Fill in \"{countrys}\" in the Province field",
        "ae_code_11_error3": "The system detected that your AliExpress account cannot place orders normally, please change the linked AliExpress account (click <a href=\"/app/settings?tabChangeSta=14\">here</a> to change).",
        "ae_code_11_error4": "Il tuo ordine ha incontrato un errore sconosciuto, per favore contatta il <a href=\"https://www.messenger.com/t/DSersOfficial\" dd=\"src\" target=\"_blank\">servizio clienti</a>.",
        ae_code_11_error4_extension: `Your order encountered an unknown error or you manually terminated the order, if you have any question, please <a href="https://www.messenger.com/t/DSersOfficial" dd="src" target="_blank">contact customer service</a>.`,
        "ae_code_14_error": "Il metodo di spedizione scelto non è supportato, cambia il tuo metodo di spedizione",
        ae_code_27_error: `Due to AliExpress interface limitation, DSers doesn't support shipping to the destination at the moment. Please manually place the order(s) on AliExpress and fill in the AliExpress order number on the order on DSers.`,
        "order_reault_await_title": "Placing order(s)...",
        "order_reault_succ_title": "Order(s) placed",
        "order_reault_checkfailed1": "Verifica gli ordini falliti",
        "order_reault_fail_title": "Some order(s) failed",
        "sync_last_chan": "Sinc. Ordine AE",
        "sync_time_last_chan": "Ultima sincronizzazione",
        "sync_time_next_chan": "Prossima sincronizzazione",
        "flags": {
            "title": "Flags",
            "None": "Nessuno",
            "Grey": "Grigio",
            "Blue": "Blu",
            "Green": "Verde",
            "Yellow": "Giallo",
            "Red": "Rosso"
        },
        "shopify_partially_refunded": "Shopify parzialmente rimborsato",
        "place_via_extension": {
            "caution": "Caution",
            "ae_account_not_match": "Abbiamo rilevato che l'account AliExpress su cui hai effettuato l'accesso è incompatibile con l'account collegato a DSers. Per garantire che lo stato del tuo ordine AliExpress possa essere sincronizzato con DSers, collega il tuo account AliExpress attualmente connesso a DSers o effettua un ordine sull'account AliExpress attualmente collegato a DSers.",
            "login_ae_account_disabled": "",
            "order_via_api_opt": "Order via API (Place the orders faster)",
            "order_via_ext_opt": "Order via Chrome Extension (Automatically use received coupons)",
            "ext_not_installed_title": "DSers Chrome Extension needs to be installed",
            "ext_not_installed_content": "Check if you have installed the DSers Chrome Extension installed and click Add DSers Chrome Extension below to go to the Chrome Web store page to install it, then return to this page to try again.",
            "add_dsers_ext": "Add DSers Chrome Extension",
            "order_via_api": "Order via API",
            "order_is_processing": "Ordering via extension, please do not close the page or the browser",
            "not_operate_title": "Do not perform the following operations",
            "not_operate_1": "Exit/switch DSers account;",
            "not_operate_2": "Close/uninstall DSers plug-in;",
            "not_operate_3": "Operate AliExpress shopping cart;",
            "not_operate_4": "Exit AliExpress account.",
            "failed_order_exsits": "The ordering process is over. Some may have failed. Please check on AliExpress to avoid any loss. If the order was successful, please fill in the order number manually. If the order fails, please place the order again.",
            "abort_task_btn": "Terminate all tasks",
            "abort_task_modal_title1": "Confirm to terminate all current order tasks?",
            "abort_task_modal_content1": "After confirming the termination of all orders, orders that have not been placed will appear in the Failed Order tab. You can order them again later.",
            "confirm_btn": "Confirm",
            "cancel_btn": "Cancel",
            "abort_task_modal_title2": "Order task terminated",
            "abort_task_modal_content2": "({value}) orders have been stopped, these orders will appear in the Failed Order tab",
            "abort_got_it": "Got it",
            "error_tip_ae_logout": "Il tuo account AliExpress è stato disconnesso durante il processo di ordinazione, accedi al tuo account AliExpress e riprova mentre sei connesso.",
            "error_tip_action_timeout": "La richiesta è scaduta, riprova.",
            "error_tip_cart_error": "Aggiunta al carrello non riuscita, per favore riprova.",
            "error_tip_unknown": "Ordine fallito, si prega di contattare il servizio clienti per risolvere il problema.",
            "error_tip_manually_close_1": "L'ordine non è riuscito perché hai chiuso manualmente la pagina dell'ordine, per favore riprova ad effettuare l'ordine",
            "error_tip_manually_abort": "You have manually aborted the order task.",
            "error_tip_manually_close_2": "La pagina dell'ordine è stata chiusa o la scheda della pagina dell'ordine è stata trascinata. per favore riprova.",
            "on_hold_error": "Impossibile effettuare l'ordine perché si trova In sospeso su Shopify. Cambiare lo stato dell'ordine su Shopify e poi effettuare l'ordine su DSers.",
            "request_fulfill_error": "Richiesta di evasione dell'ordine su Shopify. La funzionalità di \"Richiesta di evasione\" non è conforme con l'elaborazione degli ordini di DSers, modifica l'ordine in base alla guida sul blog. Clicca <a href=\"https://help.dsers.com/re-order-request-fulfillment-order-for-shopify/\">qui </a> per ricevere maggiori informazioni",
            "shipping_error": "There is no shipping method applied to this order, please select manually. Or you can go to Settings--Shipping to set up your favorite shipping method, then DSers will select it automatically",
            "mapping_error": "Devi mappare i prodotti per fare in modo che DSers sappia con quale rifornitore tu stia lavorando prima di effettuare ordini grossi.",
            "shipping_error_set": "The shipping method you set based on the product in the settings is not supported by the supplier anymore, so the order cannot be placed without a default shipping method. You can modify the shipping method in the settings, or manually modify it in the order",
            "on_hold_error_item": "Impossibile effettuare l'ordine perché si trova In sospeso su Shopify. Cambiare lo stato dell'ordine su Shopify e poi effettuare l'ordine su DSers.",
            "request_fulfill_error_item": "Richiesta di evasione dell'ordine su Shopify. La funzionalità di \"Richiesta di evasione\" non è conforme con l'elaborazione degli ordini di DSers, modifica l'ordine in base alla guida sul blog. Clicca <a href=\"https://help.dsers.com/re-order-request-fulfillment-order-for-shopify/\">qui </a> per ricevere maggiori informazioni",
            "shipping_error_item": "Nessun metodo di spedizione applicato a questo ordine. Controllare le <a href=\"/app/settings\">impostazioni di spedizione </a> su DSers o controllare la disponibilità dei metodi di spedizione su AliExpress..",
            shipping_error_agency: 'There is no shipping method applied to this order, please select manually.',
            error_tip_product_captcha: 'La rete è anormale, si prega di scorrere per verificare su AliExpress e ordinare di nuovo.',
        },
        "disappear_order_tip": "There is a problem with some of your orders, if you need to retrieve them, please <a class=\"ds-link\" target=\"_blank\" href=\"https://www.messenger.com/t/DSersOfficial\"\">contact us.</a>",
        "order_code_24_error": "This order failed due to a network error, please try to place the order again",
        tmall_sku1_error: 'La SKU del prodotto è cambiata, controllare i dettagli della mappatura',
        tmall_sku2_error: `La SKU originale non esiste, è necessario rimappare o <a href=\"{url}\" target=\"_blank\">cambiare il fornitore </a>, altrimenti non è possibile effettuare l'ordine con successo.`,
        tmall_sku3_error: `The original SKU does not exist or changed, you need to re-map or change the supplier, otherwise you can't place the order successfully.`,
        "note_title_y": "Aggiungi nota",
        "note_desc_y": "Nota visibile solo su DSers",
        "note_tip_y": "Il numero di caratteri nel box di digitazione ha superato il limite, cancella il contenuto e salva.",
        "aeProcess": 'Process Orders',
        "aeProcessSub": `Torna indietro e gli ordini selezionati verranno spostati nella scheda "In attesa di ordine". Le informazioni del vecchio ordine di AliExpress verranno perse dopo il rollback.<span style="color:red"> Ricorda che questi ordini saranno modificati da Shopify in ordini non evasi. Nota: in base alle politiche di Shopify, possiamo elaborare solo gli ordini relativi agli ultimi due mesi.</span>`,
        "confirm_modal_up": {
            "fulfill_title": "Eseguire gli ordini manualmente",
            "fulfill_confirm": "CONFERMA",
            "rollback_title": "Roll Back Orders",
            "rollback_input_confirm_value": "Sei sicuro di voler eseguire manualmente questi ordini?",
            "note_cancel": "CANCEL",
            "node_save": "SAVE",
            "fulfill_desc": "Sei sicuro di voler eseguire manualmente questi ordini? Si prega di notare che DSers interromperà la sincronizzazione delle informazioni relative agli ordini effettuati su AliExpress.",
            "fulfill_desc_tm": "Are you sure you want to manually FULFILL these orders? Please note that DSers will stop syncing order information for the orders that have been placed on Tmall."
        },
        orderQuantityToolTip: {
            "title": "Ci sono ancora {items} articoli nel menu ordini",
            "aliexpress": "Ordini AliExpress",
            "agency": "Ordini agenzia",
            "unmapped": "Ordini non mappati"
        },
        "aeCancelTip": `Ai sensi della nuova normativa di AliExpress, se l'ordine utilizza un numero di tracking falso, esso potrebbe essere annullato da AliExpress. Ti invitiamo ad annullare manualmente questo tipo di ordine nella scheda "evaso".`,
        editActionTip: `Dal momento che hai modificato il rivenditore dell'ordine, l'articolo o l'ordine saranno trasferiti alla pagina ordini di {platformType}`,
        edit_order_confirm_tip_1: `Hai utilizzato la funzione Modifica per modificare il tipo di fornitore dell'articolo, vai alla scheda {platform} - {tab} per visualizzare l'articolo.`,
        edit_order_confirm_tip_2: `Hai utilizzato la funzione Modifica per modificare il tipo di fornitore di alcuni articoli, vai alla scheda {platform} - {tab} per visualizzare l'articolo.`,
        edit_order_confirm_tip_var: {
            aliexpress: 'AE',
            tmall: 'Tmall',
            both: 'AE / Tmall',
            awaiting_order: 'Ordine in attesa',
            canceled_order: 'Annullato',
            failed_order: 'Ordine non riuscito',
        },
        orderPayment: {
            timeout: 'Pagamento fallito. Il pagamento è scaduto, sei pregato di rinizializzare il processo.',
            cancel: 'Pagamento fallito. Il tuo pagamento è stato cancellato, sei pregato di rinizializzare il processo.',
            unknow: 'Pagamento fallito per ragioni sconosciute. Contatta il supporto DSers per risolvere questo problema. ',
            checkDetail: 'Controlla le informazioni',
            link: 'Link pagamento',
            loading: 'Il pagamento iniziato alle ore {time} è stato pagato.',
            success: 'Il pagamento iniziato alle ore {time} ha avuto successo.',
            error: 'The payment you started at {time} is failed.',
            pendingTip: 'Se vuoi cancellare un pagamento per questo ordine, usa il link di pagamento.',
            reason: 'failed reason',
            buttonTip: 'Make your payments to complete your orders. Please complete your payment asap to satisfy your customers!',
            orderOneButtonTip: 'Effettua un pagamento'
        },
        paymentModalTitle: `Controlla le informazioni dell'ordine prima di pagare.`,
        orderPageGuidance:"L'ordine è stato sincronizzato, per favore vai alla pagina degli Ordini Aperti per processare gli ordini in attesa."
    }
}