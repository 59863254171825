import {
  getOrderList,
  purchase,
  getBindProduct,
  getBindTMProduct,
  overrideOrder,
  updateMyProduct,
  getArea,
  saveAddress,
  getGuidance,
  postGuidance,
  getFreightList,
  addOrderNoAndTrackNo,
  getOrderGeneral,
  setOrderStatus,
  getJobAE,
  setFulfillment,
  getCountryList,
  getCount,
  postUpDataExt,
  getProvince,
  pushPlaceOrder,
  getOrderTask,
  getMapProd,
  createAESyncJob,
  getAeAddressRules,
  getOrderSetting,
  getOrderNoDetail,
  postSyncFulfillment,
  getOrderAliCount,
  getOtherImage,
  getArchivedList,
  putUnarchive,

  // 获取用户支付计划
  payment,
  pushOldPlaceOrder,
  pushTMPlaceOrder,
  setArchive,
  getAeCancelOrderTip,
  postUpDataExtNew,
  getTmallBindProduct,
  placeAgencyAndTmallOrder,
  markAgencyOrderAsPaid,
  markAgencyOrderAsCanceled,
  getAgencyFreightList,
  getAgencyShippingCost
} from '../services/order';

import { getStoreList } from '../services/store';

export default {
  namespace: 'order',

  state: {
    orderData: {},
    storeData: [],
    purchased: false,
    purchasedAE: false,
    purchaseOther: false,
    aeUn: {},
    otherUn: {},
    OrderState: {},
    codArchive: {},
    orderridelist: {},
    guidance: {},
    archivedData: {},
    orderNoDetail:{},
    aeCancelOrderTip:false
  },

  effects: {
    *addOrderNo({ payload }, { call }) {
      yield call(addOrderNoAndTrackNo, payload);
    },
    *list({ payload }, { call, put }) {
      const response = yield call(getOrderList, payload);
      yield put({
        type: 'queryOrder',
        payload: response
      });
    },
    *storeList({ payload }, { call, put }) {
      const data = payload;
      const response = yield call(getStoreList, data);
      yield put({
        type: 'queryOrderStoreList',
        payload: response
      });
    },
    *purchase({ payload }, { call, put }) {
      const response = yield call(purchase, payload);
      yield put({
        type: 'changePurchase',
        payload: response
      });
    },
    *bind({ payload }, { call, put }) {
      yield call(getBindProduct, payload);
    },
    *bind_tm({ payload }, { call, put }) {
      yield call(getBindTMProduct, payload);
    },
    *tmallbind({ payload }, { call, put }) {
      yield call(getTmallBindProduct, payload);
    },
    *override({ payload }, { call, put }) {
      yield call(overrideOrder, payload);
    },
    *update({ payload }, { call }) {
      yield call(updateMyProduct, payload);
    },
    *area({ payload }, { call }) {
      yield call(getArea, payload);
    },

    // 获取用户支付计划
    *payment({ payload }, { call }) {
      yield call(payment, payload);
    },

    *address({ payload }, { call, put }) {
      yield call(saveAddress, payload);
    },
    *guidance({ payload }, { call, put }) {
      const response = yield call(getGuidance, payload);
      yield put({
        type: 'setGuidance',
        payload: response
      });
    },
    *postGuidance({ payload }, { call, put }) {
      const response = yield call(postGuidance, payload);
      yield put({
        type: 'setPostGuidance',
        payload: response
      });
    },
    *freight({ payload }, { call }) {
      // 获取商品物流列表
      yield call(getFreightList, payload);
    },
    // Agency商品物流列表
    *agencyFreight({payload}, {call}) {
      yield call(getAgencyFreightList, payload);
    },
    // 获取分包后的agency订单物流总价格
    *wholeOrderAgencyFreightCost({payload}, {call}) {
      yield call(getAgencyShippingCost, payload);
    },
    *orderGeneral({ payload }, { call, put }) {
      const response = yield call(getOrderGeneral, payload);
      yield put({
        type: 'setOrderGeneral',
        payload: response
      });
    },
    *back({ payload }, { call }) {
      yield call(setOrderStatus, payload);
    },

    *jobAE({ payload }, { call }) {
      yield call(getJobAE, payload);
    },

    *fulfillment({ payload }, { call }) {
      yield call(setFulfillment, payload);
    },

    *countryList({ payload }, { call }) {
      yield call(getCountryList, payload);
    },

    *count({ payload }, { call }) {
      yield call(getCount, payload);
    },

    *updataExt({ payload }, { call }) {
      yield call(postUpDataExt, payload);
    },

    *updataExtNew({ payload }, { call }) {
      yield call(postUpDataExtNew, payload);
    },

    *province({ payload }, { call }) {
      yield call(getProvince, payload);
    },

    *place({ payload }, { call }) {
      yield call(pushPlaceOrder, payload);
    },

    *placeAgencyAndTmallAction({payload}, {call}) {
      try {
        let updateRes = {};
        if(payload.data.updateExtParams) {
          updateRes = yield call(postUpDataExt, { data: payload.data.updateExtParams });
        }

        if([2000, 2010].includes(updateRes.code) || !payload.data.updateExtParams) {
          yield call(placeAgencyAndTmallOrder, { data: payload.data.placeParams, callback: payload.callback });
        } else {
          payload.callback && payload.callback();
        }
      } catch (error) {
        
      }
    },

    *markAgencyOrderAsPaidAction({payload}, {call}) {
      yield call(markAgencyOrderAsPaid, payload);
    },

    *markAgencyOrderAsCanceledAction({payload}, {call}) {
      yield call(markAgencyOrderAsCanceled, payload);
    },

    *task({ payload }, { call }) {
      yield call(getOrderTask, payload);
    },

    *mapProd({ payload }, { call }) {
      yield call(getMapProd, payload);
    },

    *aeSyncJob({ payload }, { call }) {
      yield call(createAESyncJob, payload);
    },

    *aeAddressRules({ payload }, { call }) {
      yield call(getAeAddressRules, payload);
    },

    *orderSetting({ payload }, { call }) {
      yield call(getOrderSetting, payload);
    },

    *getOrderAliSum({ payload }, { call }) {
      yield call(getOrderAliCount, payload);
    },
    *getOtherImages({ payload }, { call }) {
      yield call(getOtherImage, payload);
    },
    *orderNoDetail({ payload }, { call, put }) {
      let response = yield call(getOrderNoDetail, payload);
      yield put({
        type: 'setOrderNoDetail',
        payload: response.data
      });
    },
    *syncFulfillment({ payload }, { call }) {
      yield call(postSyncFulfillment, payload);
    },

    *archived({ payload }, { call, put }) {
      const response = yield call(getArchivedList, payload);
      yield put({
        type: 'setArchivedList',
        payload: response
      });
    },

    *unarchive({ payload }, { call, put }) {
      const response = yield call(putUnarchive, payload);
      yield put({
        type: 'removeArchiveOne',
        payload: response
      });
    },

    *placeOld({ payload }, { call }) {
      yield call(pushOldPlaceOrder, payload);
    },

    *placeTM({ payload }, { call }) {
      yield call(pushTMPlaceOrder, payload);
    },

    *archive({ payload }, { call }) {
      yield call(setArchive, payload);
    },
    *getAeCancelOrderTip({payload},{call,put}){
     const res = yield call(getAeCancelOrderTip,payload)
     yield put({
       type:'setAeCancelOrderTip',
       payload:res.data
     })
    }
  },

  reducers: {
    resetNoDetail(state){
      return {
        ...state,
        orderNoDetail: {}
      }
    },
    setOrderNoDetail(state, { payload = {} }){
      return {
        ...state,
        orderNoDetail: payload
      }
    },
    queryOrder(state, { payload = {} }) {
      return {
        ...state,
        orderData: payload.data || {}
      };
    },
    queryOrderStoreList(state, { payload = {} }) {
      return {
        ...state,
        storeData: payload.data || []
      };
    },
    changePurchase(state, { payload = {} }) {
      return {
        ...state,
        purchased: payload.code === 0
      };
    },
    changePurchaseOnAE(state, { payload = {} }) {
      return {
        ...state,
        purchasedAE: payload.code === 0
      };
    },
    changePurchaseOnOther(state, { payload = {} }) {
      return {
        ...state,
        purchaseOther: payload.code === 0
      };
    },
    queryAeUn(state, { payload = {} }) {
      return {
        ...state,
        aeUn: payload
      };
    },
    queryOtherUn(state, { payload = {} }) {
      return {
        ...state,
        otherUn: payload
      };
    },
    setGuidance(state, { payload = {} }) {
      return {
        ...state,
        guidance: payload.data || {}
      };
    },
    setPostGuidance(state, { payload = {} }) {
      return {
        ...state
      };
    },
    setOrderGeneral(state, { payload = {} }) {
      const { data = {} } = payload;
      return {
        ...state,
        OrderGeneral: data
      };
    },
    setArchivedList(state, { payload = {} }) {
      const { data = {} } = payload;
      data.data = data.data || undefined;
      return {
        ...state,
        archivedData: data
      };
    },
    removeArchiveOne(state, { payload }) {
      if (![2000, 2010].includes(payload.code)) {
        return {
          ...state
        };
      }

      const { archivedData, archivedData: { data } } = state;
      const list = data.filter(item => item.order_id != payload.data.order_id);
      const obj = { ...archivedData };

      obj.data = list;

      return {
        ...state,
        archivedData: obj
      };
    },
    setAeCancelOrderTip(state,{payload}){
      return {
        ...state,
        aeCancelOrderTip:payload
      }
    }
  }
};
