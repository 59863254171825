
    export default 
    {
    "partner": {
        "public": {
            "change": "Cambia",
            "cancel": "Annulla",
            "save": "Salva",
            "reset": "Resetta"
        },
        "signIn": {
            "title": "Bentornato",
            "log_in": "Accedi",
            "forgot_password": "PASSWORD DIMENTICATA?",
            "no_account": "Non hai un account?",
            "become_partner": "Diventa un partner",
            "email": "Email"
        },
        "signUp": {
            "title": "Diventa partner DSers!",
            "register": "Registrati",
            "sign_in": "Registrati",
            "have_account": "Hai un account?",
            "email": "Email di contatto",
            "email_format_tips": "Si prega di inserire un indirizzo email",
            "email_is_value_tips": "Si prega di inserire un indirizzo email",
            "email_placeholder_tips": "Utilizzato per contattarti e per creare un account",
            "pwd": "Password",
            "pwd_is_value_tips": "Si prega di inserire una password",
            "pwd_placeholder_tips": "Si prega di inserire una password",
            "pwd_font_num_tips": "La password dev'essere lunga da 8 a 16 caratteri",
            "pwd_format_tips": "La password deve contenere almeno 1 lettera maiuscola, 1 lettera minuscola e 1 numero",
            "confirm_Password": "Conferma la password",
            "confirm_pwd_placeholder_tips": "Si prega di inserire una password confermata",
            "confirm_pwd_is_value_tips": "Si prega di confermare la password!",
            "confirm_pwd_contrast_tips": "Le due password che hai inserito non sono identiche!",
            "name": "Nome per intero",
            "name_is_value_tips": "Si prega di inserire fra 1 e 100 caratteri inglesi",
            "name_placeholder_tips": "Dicci il tuo nome",
            "country": "Stato",
            "country_is_value_tips": "Si prega di selezionare uno stato principale",
            "country_placeholder_tips": "Si prega di selezionare il tuo stato",
            "business_type": "Tipo di azienda",
            "business_type_is_value_tips": "Si prega di inserire il tipo di azienda",
            "business_type_placeholder_tips": "Si prega di selezionare il tipo di azienda",
            "partner_url": "L'url del tuo sito web/app/canale",
            "partner_url_is_value_tips": "Si prega di inserire un URL che inizi con http o Https",
            "partner_url_placeholder_tips": "Si prega di condividere il link della tua compagnia",
            "partner_url_font_num_tips": "I caratteri sono troppi, si prega di inserire massimo 1000 caratteri.",
            "partner_url_format_tips": "Si prega di inserire un URL che inizi con http o Https",
            "partner_id": "ID del canale",
            "partner_id_tips": "Puoi aggiungere molteplici ID di canali qui, e l'ID del canale appena prodotto sarà generato secondo le regole della piattaforma, e non potrà essere modificato",
            "partner_id_is_value_tips": "Si prega di inserire l'ID del tuo canale",
            "partner_id_is_value_format_tips": "L'ID del canale non può finire con un numero",
            "partner_id_placeholder_tips": "Utilizzato per creare il tuo link tracciabile",
            "partner_id_font_num_tips": "I caratteri sono troppi, si prega di inserire massimo 100 caratteri.",
            "paypal_email": "Indirizzo email PayPal (opzionale)",
            "paypal_email_placeholder_tips": "Puoi completare questa parte dell'account più avanti",
            "remark": "Dicci di più",
            "remark_placeholder_tips": "Dicci di più su come vuoi lavorare insieme a noi",
            "remark_font_num_max_tips": "I caratteri sono troppi, si prega di inserire massimo 1000 caratteri.",
            "remark_font_num_min_tips": "I caratteri sono nroppi, si prega di inserire massimo 200 caratteri.",
            "remark_is_value_tips": "Dicci qualcosa di più su di te"
        },
        "account": {
            "paypal_email": "Indirizzo email PayPal",
            "paypal_email_placeholder_tips": "Per favore dicci il tuo indirizzo email PayPal"
        },
        "home": {
            "title": "Dashboard",
            "commission_on_hold": "Commissione in sospeso",
            "commission_confirmed": "Commissione confermata",
            "request_commission": "Richiedi commissione",
            "seven_days_tips": "Abbiamo ricevuto la tua richiesta. Il team la esaminerà e ti ricontatterà entro 7 giorni lavorativi. Grazie per la pazienza."
        },
        "Reset_email_module": {
            "title": "nuobo indirizzo email",
            "email_tips": "Si prega di inserire un indirizzo email!",
            "code_tips": "Codice errato. Si prega di ricontrollare!",
            "send_code": "Invia codice",
            "verify": "Verifica",
            "send_prompt": "Invieremo un codice di veridica al nuovo indirizzo email. Copialo e inseriscilo qui sotto per verificare la tua email",
            "code_title": "inserisci il codice per la verifica",
            "get_code": "Ottieni il codice dalla nostra email di verifica e inseriscilo qui per essere verificato"
        },
        "reset_pass_word": {
            "title": "Resetta la password",
            "new_pwd_placeholder_tips": "Nuova password",
            "new_pwd_value_no_tips": "Il campo della password non può essere vuoto",
            "old_pwd_placeholder_tips": "Vecchia password",
            "old_pwd_value_no_tips": "Si prega di inserire la tua vecchia password!",
            "old_pwd_font_num_tips": "La password dev'essere lunga da 8 a 16 caratteri",
            "old_pwd_format_tips": "La password deve contenere almeno 1 lettera maiuscola, 1 lettera minuscola e 1 numero",
            "confirm_new_pwd_value_no_tips": "Il campo della password non può essere vuoto",
            "confirm_new_pwd_contrast_tips": "Le due password che hai inserito non sono identiche!"
        },
        "partner_layout": {
            "account": "Account",
            "dashboard": "Dashboard"
        },
        "password_not_match_twice": "Le due password che hai inserito non sono identiche!",
        "password_error_long": "La password dev'essere lunga da 8 a 16 caratteri",
        "password_error_format": "La password deve contenere almeno 1 lettera maiuscola, 1 lettera minuscola e 1 numero"
    }
}