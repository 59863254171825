import { Modal, Button } from 'antd';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect } from 'dva';
import qs from 'querystring';
import intl from 'react-intl-universal';

import styles from './CheckInterreptedExtTask.less';

import {
  getAELoginStatus,
  getExtOrderInterreptedTask,
  getExtDsersLoginStatus,
  processInterreptedTasks
} from 'utils/placeOrderViaExt';

import { AsyncConfirm } from '../AsyncConfirm';
import { sleep } from 'routes/Order/util';
import { Get } from 'utils/request';

const CheckInterreptedExtTask = React.memo(({ userInfo, currentAeInfo, startProcess }) => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showRefresh, setShowRefresh] = useState(false);
  const [queueTipVisible, setQueueTipVisible] = useState(false);
  const asyncConfirmRef = useRef(null);
  const unmountedRef = useRef(false);
  const rollingRef = useRef(false);
  const modalVisibleRef = useRef(false);
  const checkDisappearOrders = useCallback(async () => {
    if (userInfo?.user_id) {
      // 检查当前用户是否有异常中断的下单事件保存在插件本地
      let extInterreptedRecord = false;
      let extIsProcessing = false;
      let excuted = false;
      // 弹框已经打开，不再检查
      if(modalVisibleRef.current) return;
      await Promise.race([
        getExtOrderInterreptedTask({ userId: userInfo?.user_id }).then(
          checkRes => {
            console.log(checkRes, '---------check interrept res--------');
            if(excuted || unmountedRef.current) {
              return;
            }
            if (checkRes?.code === 2000) {
              // extInterreptedRecord = true;
              extIsProcessing = checkRes.data.isProcessing;
              const showModal =
                !checkRes.data.isProcessing && !!checkRes.data.tasks?.length && !modalVisibleRef.current;
              extInterreptedRecord = showModal;
              if(showModal) {
                modalVisibleRef.current = true;
                setVisible(showModal);
              }
              
            }
          }
        ),
        sleep(10000)
      ]);
      // 组件销毁或者存在插件记录的中断订单则不再请求接口
      if(unmountedRef.current || extInterreptedRecord || extIsProcessing) return;
      // 防止超时的时候重复执行逻辑
      excuted = true;
      // 请求接口获取队列中可能的消失单信息
      
      const serverQueueInterreptedRes = !!(
        await Promise.allSettled([
          Get('/ord/failed-orders', { data: { is_plugin: true } }),
          Get('/ord/failed-orders', { data: { is_plugin: false } })
        ])
      ).find(i => i?.value?.data === true);
      if(unmountedRef.current) return;
      if(serverQueueInterreptedRes) {
        modalVisibleRef.current = true;
        //留个后门  方便开发
        if(!localStorage.getItem('QueueTipVisible')){
          setQueueTipVisible(true);
        }
      }
      
      // console.log(serverQueueInterreptedRes.map(i => i?.value?.data === true));

      // getExtOrderInterreptedTask({ userId: userInfo?.user_id }).then(
      //   checkRes => {
      //     console.log(checkRes, '---------check interrept res--------');
      //     if (checkRes?.code === 2000) {
      //       const showModal =
      //         !checkRes.data.isProcessing && !!checkRes.data.tasks?.length;
      //       setVisible(showModal);
      //     }
      //   }
      // );
    }
  }, [userInfo?.user_id, visible, queueTipVisible]);

  const startRolling = useCallback(async() => {
    while(!unmountedRef.current) {
      try {
        await checkDisappearOrders();
      } catch (error) {
        console.log('rolling interrept error', error);
      }
      // 总共停留300s即5分钟 每10s检查一次是否unmount 避免多次切换tab时造成一段时间内内存占用上升
      for(let i = 0; i < 30; i++) {
        await sleep(10 * 1000);
        if(unmountedRef.current) {
          break;
        }
      }
      
    }
  }, [checkDisappearOrders]);

  useEffect(() => {
    console.log('check interrepted task');
    // console.log(userInfo)
    if (userInfo?.user_id) {
      if(!rollingRef.current) {
        rollingRef.current = true;
        startRolling();
      }
      // 检查当前用户是否有异常中断的下单事件保存在插件本地
      
    }
  }, [userInfo?.user_id, startRolling]);

  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);

  const handleContinue = async () => {
    if (loading) return;
    setLoading(true);
    try {
      // 检测当前ae登录状态
      const res = await getAELoginStatus();
      if (res.data && res.data.code === 200) {
        const aliAccount = qs.parse(res.data.aeAccountId?.value || '').x_lid;
        // ae登录账号与当前绑定账号不一致时需二次确认
        const confirmed =
          aliAccount === currentAeInfo?.user_nick ||
          (await asyncConfirmRef.current.showConfirm());
        if (confirmed) {
          // 检测当前插件环境dsers账号与dsers web是否同步 不同步需要刷新页面以刷新插件中的登录信息
          const checkAccountRes = await getExtDsersLoginStatus();
          console.log(checkAccountRes, 'dsers-ext-login-account');
          if (
            userInfo?.user_id &&
            checkAccountRes?.user_id === userInfo?.user_id
          ) {
            await processInterreptedTasks({
              userId: userInfo?.user_id,
              aeCaptchaSolveType: 2
            });
            startProcess();
            setVisible(false);
          } else {
            setShowRefresh(true);
          }
        }
      } else if (res.data && res.data.code === 403) {
        message.error(
          'The AliExpress account which you are current logged in is disabled by AliExpress',
          3
        );
      }
    } catch (error) {
      console.log(error, '-----continue processing task error-----');
    }
    setLoading(false);
  };

  return (
    <>
      <Modal
        maskClosable={false}
        visible={visible}
        onCancel={() => {setVisible(false); modalVisibleRef.current = false}}
        title="Interrepted order task in dsers chrome extension is detected"
        footer={null}
      >
        <div style={{ marginBottom: 24, textAlign: 'right' }}>
          <Button loading={loading} onClick={handleContinue} type="primary">
            CONTINUE PROCESSING ORDERS
          </Button>
        </div>
      </Modal>

      <Modal
        maskClosable={false}
        visible={queueTipVisible}
        onCancel={() => {setQueueTipVisible(false);modalVisibleRef.current = false}}
        title={intl.get('order.place_via_extension.caution')}
        footer={null}
        centered
      >
        <div className={styles.tip} style={{ paddingTop: 8, paddingBottom: 24 }}>
          {intl.getHTML('order.disappear_order_tip')}
        </div>
      </Modal>

      <AsyncConfirm title="Warning" ref={asyncConfirmRef}>
        <div>
          Your aliexpress account is not matched with which bound to dsers
        </div>
        <div>Sure to continue?</div>
      </AsyncConfirm>
      <Modal
        onCancel={() => setShowRefresh(false)}
        visible={showRefresh}
        footer={null}
        title="Chrome extension login status invalid"
      >
        <div style={{ textAlign: 'center', marginBottom: 24 }}>
          <Button type="primary" onClick={() => window.location.reload()}>
            Refresh extension login status
          </Button>
        </div>
      </Modal>
    </>
  );
});

export default connect(({ login, global }) => {
  return {
    userInfo: login.userInfo,
    AEInfo: global.AEInfo
  };
})(CheckInterreptedExtTask);
