const getAdminRole = () => {
  let role = null;

  try {
    role = localStorage.getItem('adminRole') || null;
  } catch (error) {
    console.log('getAdminRole error', error);
  }
  return role;
};

export default getAdminRole;
