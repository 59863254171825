import React from 'react';
import { connect } from 'dva';
import {
  Modal,
  Button,
  Select,
  Input,
  Radio,
  Icon,
  Popover,
  message,
  Spin,
  Tooltip,
  Collapse,
  Image,
  Empty
} from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import qs from 'querystring';

import Ellipsis from 'components/Ellipsis';
import intl from 'react-intl-universal';
import className from 'classnames';

import {
  getSupplierId,
  replaceUrlToHttps,
  webCustomCollect,
  sendNoviceGuidanceInfo,
  getSupplierUrl,
  CommonDataCollect,
  isUrl,
} from 'utils/utils';
import { SearchInput } from 'features';
import { LinkStoreAction, GuildeAction } from 'actions';
import { getAePid } from 'routes/StoreManage/util';
import noticeIcon from 'assets/mapping/notice.png';
import searchIcon from 'assets/newImportList/icon-search.png';
import { dispatchEventForGuide } from '../../routes/BeginnerGuide/util';
import { InfoAlert } from '../Banner/OptimizerInfoTip';
import {
  deleteBundle,
  deleteBogo,
  deleteAdvance,
  hasDeleteVariant,
  postMappingProductRef,
} from './util';

import Status from './Status';
import ImportAliExpressMapping from './Import';
import reactGaEvent from '../../utils/GAEvent';
import MoreAction from './MoreAction';

import styles from './index.less';
import './mapping.css';

const { Option } = Select;
const { Search } = Input;
const RadioGroup = Radio.Group;
const { confirm } = Modal;
const { Panel } = Collapse;

const popoverImg = {
  width: '260px',
  height: '260px',
  objectFit: 'contain',
};

function getSkuId(vItem, optionId, options_map) {
  let id = 'NOT_FOUND';
  options_map.forEach((item) => {
    if (item.value == vItem && item.option_id == optionId) {
      id = item.sku || 'UNSELECTED';
    }
  });
  return id;
}

function getRealSkuhandler(value) {
  const str = value.split(';').sort().join(';');
  return str;
}

function getSkuPropertyValue(id, options_map) {
  let obj = {};
  if (id == '') {
    return obj;
  }

  options_map.forEach((item) => {
    const flag = Object.values(item).find(
      (sku) => getRealSkuhandler(sku.id) === getRealSkuhandler(id)
    );
    // if (!flag) {
    //   Object.values(item).forEach(sku => {
    //     if (sku.id === id || sku.id.includes(id)) {
    //       obj = sku;
    //     }
    //   });
    // } else {
    //   obj = flag;
    // }
    if (!flag) return;
    obj = flag;
  });

  return obj || {};
}

//
function updataOptionMap(options_map) {
  const arr = [];
  options_map.forEach((item) => {
    let i = 1;
    const obj = {};

    Object.values(item).forEach((sku) => {
      const key = sku.value.split('----')[0];
      if (obj[key]) {
        obj[`${key}_${i}`] = sku;
        sku.value = `${key}_${i}`;
        i += 1;
      } else {
        obj[key] = sku;
        sku.value = key;
      }
    });

    arr.push(obj);
  });

  return arr;
}

// function getAEOnlyOptionPosition(shopifyOptions, sku_map){

//   const aeOptions = Object.values(sku_map)[0];
//   let index = 0;
//   if (aeOptions?.length === 1 && shopifyOptions?.length > 1) {
//     shopifyOptions.forEach((item, i) => {
//       if (item.name === aeOptions[0].sku_property_name) {
//         index = i;
//       }
//     });
//   }
//   return index;
// }

function getAEOnlyOptionPosition(shopifyOptions, sku_map) {
  let index = 0;
  if (sku_map) {
    const aeOptions = Object.values(sku_map);
    if (
      aeOptions &&
      aeOptions[0] &&
      aeOptions[0]?.length === 1 &&
      shopifyOptions?.length > 1
    ) {
      shopifyOptions.forEach((item, i) => {
        if (item.name === aeOptions[0][0].sku_property_name) {
          index = i;
        }
      });
    }
  }
  return index;
}

/**
 * 获取mapping数据
 * @param {*} data ae商品信息
 * @param {*} prod shopify商品信息
 * @param {*} index 0主 || 1副  供应商
 * @param {*} product_pool 商品池
 */
function getOptionsShowData(data, prod, index, product_pool = [], productId) {
  // data AE商品信息   prod shopify商品信息  index 0 || 1  主 || 副 供应商
  const optionsArr = [];
  let options_map = []; // size里又多少值 s m l
  const option_values = [];
  let default_category_values = []; // 大的分类的值

  const is_default = index == 0; // 0是主供应商
  let basic_has_ae = false;
  const {
    detail: { options, variants },
    ext = {},
  } = prod; // shopify 商品

  const { basic = {} } = ext;

  if (basic[productId]) {
    if (
      basic[productId].options_map &&
      basic[productId].options_map.length > 0
    ) {
      if (
        basic[productId].options_map[0].value === 'Default Title' &&
        !basic[productId].options_map[0].option_id
      ) {
        if (options[0]) {
          basic[productId].options_map[0].option_id = JSON.stringify(
            options[0].id
          );
        }
      }
    }
  }
  const {
    aeop_ae_product_s_k_us: { aeop_ae_product_sku = [] },
    product,
    product_id_str,
  } = data; // AE 商品

  // AE 商品没有sku 信息
  if (
    aeop_ae_product_sku[0].aeop_s_k_u_propertys == undefined ||
    aeop_ae_product_sku[0].aeop_s_k_u_propertys.aeop_sku_property == undefined
  ) {
    const skus_map = {
      'Default Title': {
        value: 'Default Title',
        id: '<none>',
        position: 1,
        category: 'Title',
        sku_property_id: 'none',
      },
    };
    const options_map = [
      {
        'Default Title': {
          value: 'Default Title',
          id: '<none>',
          position: 1,
          category: 'Title',
          sku_property_id: 'none',
        },
      },
    ];
    const sku_map = { '<none>': [] };
    const skuPureIdMap = { '<none>': aeop_ae_product_sku[0] };
    option_values.push([]);
    default_category_values[0] = 'none';
    optionsArr.push({
      name: 'Title',
      position: 1,
      sku_property_id: 'none',
      values: [
        {
          value: 'Default Title',
          id: '<none>',
          position: 1,
          category: 'Title',
          sku_property_id: 'none',
        },
      ],
    });
    Object.entries(basic).forEach((supArr) => {
      if (supArr[0] != product_id_str) {
        return;
      }
      const sup = supArr[1];
      if (sup.options_map) {
        const spOptionMap = sup.options_map;
        options.forEach((item, ii) => {
          // item { name: 'size', values: [ s,m,l,xl, xxl ]  }
          item.values.forEach((vItem, vi) => {
            const id = getSkuId(vItem, item.id, spOptionMap); // id AE 商品 某个属性的id  white -> '136:201447614#6000K Cold white'
            const skuObj = getSkuPropertyValue(id, options_map);
            const isSkuObj = JSON.stringify(skuObj) === '{}';
            if (option_values[ii]) {
              if (basic[productId]?.supply[0]?.variant_id === variants[0]?.id) {
                option_values[ii][vi] = isSkuObj
                  ? undefined
                  : skuObj.id || '<none>';
              } else {
                option_values[ii][vi] = undefined;
              }
              if (skuObj.category) {
                default_category_values[ii] = skuObj.sku_property_id;
              }
            }
          });
        });
      }
    });

    if (option_values[0].length === 0) {
      option_values[0].push('<none>');
    }

    return [
      optionsArr,
      option_values,
      default_category_values,
      skus_map,
      sku_map,
      skuPureIdMap,
    ];
  }

  // oneAeSku = [{property_value_definition_name: "Coffee", property_value_id_long: 771, sku_image: ".jpg", sku_property_id: 14, sku_property_name: "Color" sku_property_value: "Beige"}, {...}]
  const oneAeSku =
    aeop_ae_product_sku[0].aeop_s_k_u_propertys.aeop_sku_property;

  oneAeSku.forEach(() => {
    optionsArr.push({ name: '', position: 0, values: null });
    options_map.push({});
    option_values.push([]);
  });

  const sku_map = {}; // ae商品skuid(variantid) 对应 sku对应的各个属性数组
  const skuPureIdMap = {};
  /*
    sku_map
    {
      "14:771#Coffee;5:100014064": [
        {
          property_value_definition_name: "Coffee",
          property_value_id_long: 771,
          sku_image: "http://ae01.alicdn.com/kf/H02c6dcea8f1348c9be0e3086b4c5fecdk.jpg",
          sku_property_id: 14,
          sku_property_name: "Color",
          sku_property_value: "Beige"
        },
        {
          property_value_id_long: 100014064,
          sku_property_id: 5,
          sku_property_name: "Size",
          sku_property_value: "S"
        }
      ],
      "14:771#Coffee;5:361386": [...],
      ...
    }
  */
  const orderedSkuIdMap = {};
  aeop_ae_product_sku.forEach((item) => {
    const {
      id,
      aeop_s_k_u_propertys: { aeop_sku_property = [] },
    } = item;
    const skuId = id.split(';');
    try {
      // 对sku prop进行排序 用于判断是否sku顺序发生变化
      const skuIdSorted = [...skuId].sort().join(';');
      orderedSkuIdMap[skuIdSorted] = true;
    } catch (error) {
      console.log(error);
    }
    sku_map[id] = aeop_sku_property;
    skuPureIdMap[id?.replace(/(#[\s\S]+?)(;|$)/g, '$2')] = item;
    aeop_sku_property.forEach((sku, i) => {
      const {
        sku_property_name,
        sku_property_value,
        property_value_definition_name,
        sku_image,
        property_value_id_long,
        sku_property_id,
      } = sku;
      let propertyValue = property_value_definition_name || sku_property_value;
      const obj = {};

      propertyValue = `${propertyValue}----${property_value_id_long}`;

      obj.value = propertyValue;
      obj.id = skuId[i];
      obj.position = i + 1;
      obj.category = sku_property_name || 'option-0';
      obj.img = sku_image;
      obj.sku_property_id = sku_property_id;
      obj.property_value_id = property_value_id_long;

      if (optionsArr[i]) {
        optionsArr[i].position = i + 1;
        optionsArr[i].name = sku_property_name || 'option-0';
        optionsArr[i].sku_property_id = sku_property_id;
      }
      if (options_map[i]) {
        options_map[i][propertyValue] = obj;
      }
      // console.log(JSON.parse(JSON.stringify(options_map)),'???????????/');
    });
  });

  options_map = updataOptionMap(options_map);

  /*
    options_map
    [
      {
        Black: {category: "Color" id: "14:193", img: ".jpg", position: 1, sku_property_id: 14, value: "Black"},
        White: { ... }
      },
      {
        S: {...}
      }
    ]
  */

  /*
      optionsArr
      [
        {
          name: "Color",
          position: 1,
          sku_property_id: 14,
          values: [
            {category: "Color" id: "14:771#Coffee", img: ".jpg", position: 1, sku_property_id: 14, value: "Coffee"},
            ...
          ]
        },
        {
          name: "size",
          ...
        }
      ]
  */

  const skus_map = {};

  options_map.forEach((item, i) => {
    console.log(options_map, ']]]]]]]]]]]]]]');
    const s = Object.values(item);
    if (s.length > 0) {
      optionsArr[i].values = s;
    }
    for (const k in item) {
      skus_map[k] = item[k];
    }
  });

  /*
    skus_map
    {
      Black: {category: "Color" id: "14:193", img: ".jpg", position: 1, sku_property_id: 14, value: "Black"},
      S: {...}
    }
  */
  const new_sku_map = {};
  const isNewSupplier = !Object.entries(basic)
    .map(([id]) => id)
    .find((i) => i == product_id_str);
  Object.entries(basic).forEach((supArr) => {
    const sup = supArr[1];
    sup.supply = sup.supply || [];
    let shopifyOptionsValuesLength = 0;
    const flag = !!sup.is_default;
    let hasMapping = false;
    // 查找是有与ae sku已不匹配的mapping
    const supplySkuChanged = sup.supply.filter(
      (item) => !sku_map[item.sku] && item.variant_id
    );
    // console.log(sup,sku_map,supplySkuChanged,'========');
    // 是否仅sku prop的顺序颠倒
    let isOnlyAeSkuPropOrderChange = false;
    if (supplySkuChanged.length) {
      try {
        isOnlyAeSkuPropOrderChange = supplySkuChanged.find(
          (item) => orderedSkuIdMap[item.sku.split(';').sort().join(';')]
        );
      } catch (error) {
        console.log(error);
      }
      // if(window.costomArr){
      //   window.costomArr.push(prod);
      // }else{
      //   window.costomArr = [prod];
      // }
    }
    // [{option_id: "6237886119971" (shopify option id), sku: "14:771#Coffee" (ae sku id), value: 'Coffee' (shopify分类的值)}, {...}]
    let spOptionMap = [];

    // 从商品池中设置mapping 不等于当前的mapping数据
    if (flag != is_default || product_id_str != parseInt(supArr[0])) {
      let productPoolOptionsMap = null; // 之前有没有mapping过 options_map对应关系
      product_pool.forEach((item) => {
        if (item.product_id_str == product_id_str) {
          productPoolOptionsMap = item.options_map;
        }
      });

      // 如果有mapping对应关系
      if (productPoolOptionsMap) {
        basic_has_ae = true;
        hasMapping = true;
        spOptionMap = productPoolOptionsMap;
      } else {
        return;
      }
    }
    if (sup.options_map && !hasMapping) {
      basic_has_ae = true;
      hasMapping = true;
      spOptionMap = sup.options_map;
    }

    if (hasMapping) {
      options.forEach((item, ii) => {
        // item是{ name: 'size', values: [ s,m,l,xl, xxl ]  } shopify的option
        item.values.forEach((vItem, vi) => {
          // 根据shopify option 的某个值(例如Coffee), 找到对应的ae商品sku的id(例如14:771#Coffee)
          const id = getSkuId(vItem, item.id, spOptionMap);
          // console.log(options_map, '----------options map-----------', id)
          // 返回 {category: "Color" id: "14:193", img: ".jpg", position: 1, sku_property_id: 14, value: "Black"}
          const skuObj = getSkuPropertyValue(id, options_map);
          // console.log(skuObj,'--------skuObj---------');
          shopifyOptionsValuesLength += 1;

          if (option_values[ii]) {
            // ae商品第几个分类的第几个值应该选什么 例如 14:193
            option_values[ii][vi] = ['NOT_FOUND', 'UNSELECTED'].includes(id)
              ? id
              : skuObj.id || 'SKU_CHANGED';
            if (skuObj.category) {
              // ae商品第几个分类应该选什么 例如 14
              default_category_values[ii] = skuObj.sku_property_id
                ? `${skuObj.sku_property_id}`
                : undefined;
            }
          }
        });
      });
      // console.log(option_values, '------option values----------')
      // AE的属性分类(option_values)个数大于shopify的属性(options)分类个数
      // 两种情况造成这种mapping
      // 1 用户本身mapping是 shopify 少数option 对应多个 ae 供应商的 sku
      // 2 用户手动在shopify删除了option 这时有可能导致超出的mapping在前端无法正常对应 但服务端依然可以根据删除前保存的mapping进行下单
      // 情况1下 ae供应商侧超出的mapping option没有option_id属性 只有sku属性
      // 情况2下 ae供应商侧超出的mapping option存在option_id属性 此情况下前端将跳过这个属性的对应关系 展示空白 让用户重新选择mapping并保存
      if (option_values.length > options.length) {
        let moreMappingIndex = 0;
        option_values.forEach((item, ii) => {
          const spOption =
            spOptionMap[shopifyOptionsValuesLength + moreMappingIndex];
          if (ii >= options.length) {
            // 如果符合情况1 则展示mapping
            if (spOption && !spOption.option_id && spOption.sku) {
              const skuObj = getSkuPropertyValue(spOption.sku, options_map);
              option_values[ii][0] = skuObj.id;
              default_category_values[ii] = skuObj.sku_property_id
                ? `${skuObj.sku_property_id}`
                : undefined;
              moreMappingIndex += 1;
            }
          }
        });
      }
    }
    if (hasMapping) {
      // console.log(supplySkuChanged,'supplySkuChanged...');
      return;
    }

    // 没有保存过对应关系
    const optionsMap = sup.options_map;
    const aeOnlyOptionPosition = getAEOnlyOptionPosition(options, sku_map); // ae商品是不是只有一个分类

    /* 通过ae链接导入的商品后台会组合出
      sup.supply
      [
        {
          is_base: true, // true主供应商
          platform_product_id: "4001140406603", // ae商品id
          platform_sale_id: "cn1529190609rngm", // ae卖家id
          platform_type: 1, // 平台类型
          quantity: 1, // 默认1
          sku: "14:771#Coffee;5:100014064", // ae sku
          sku_image: "", // ae sku 图片
          title: "", // ae商品title
          variant_id: 32886787  // shopify variant_id
        },
        ...
      ]
    */

    if (Object.keys(basic))
      sup.supply.forEach((item) => {
        basic_has_ae = true;
        let shopify_variant = null; // 一条shop商品variant
        variants.forEach((v) => {
          // varaint_id是否对应 || sku是否对应 (sku_edit是import list中编辑商品sku保存的内容)
          if (
            v.id == item.variant_id
            // || item.sku_edit === v.sku ||
            // item.sku === v.sku
          ) {
            shopify_variant = v;
          }
        });

        if (shopify_variant == null) {
          return;
        }
        let skuId = item.sku;
        const new_skuId = getRealSkuhandler(item.sku); // 14:771#Coffee;5:100014064

        aeop_ae_product_sku.forEach((item1) => {
          const {
            id,
            aeop_s_k_u_propertys: { aeop_sku_property = [] },
          } = item1;
          const flag_id = getRealSkuhandler(id);
          new_sku_map[flag_id] = aeop_sku_property;
        });

        const sku = new_sku_map[new_skuId];
        if (sku) {
          skuId = aeop_ae_product_sku.filter((item2) => {
            return getRealSkuhandler(item2.id) === new_skuId;
          })[0].id;
        }
        // console.log(item,new_sku_map,skuId,'---------000000---------');
        /* sku
      [
        {
          property_value_definition_name: "Coffee",
          property_value_id_long: 771,
          sku_image: "http://ae01.alicdn.com/kf/H02c6dcea8f1348c9be0e3086b4c5fecdk.jpg",
          sku_property_id: 14,
          sku_property_name: "Color",
          sku_property_value: "Beige"
        },
        {
          property_value_id_long: 100014064,
          sku_property_id: 5,
          sku_property_name: "Size",
          sku_property_value: "S"
        }ae
      ]
      */
        try {
          options.forEach((o, i) => {
            if (aeOnlyOptionPosition !== 0 && i !== aeOnlyOptionPosition) {
              return;
            }
            const index = o.values.indexOf(shopify_variant[`option${i + 1}`]); // [s,m,L] 例如m的索引 1
            if (aeOnlyOptionPosition !== 0) {
              option_values[0][index] = skuId; // ae商品第几个分类的第几个值应该选什么
              default_category_values[0] = sku[0].sku_property_id
                ? `${sku[0].sku_property_id}`
                : 'option-0';
            }
            if (sku && sku[i] && aeOnlyOptionPosition === 0) {
              option_values[i][index] = skuId.split(';')[i]; // ae商品第几个分类的第几个值应该选什么
              default_category_values[i] = sku[i].sku_property_id
                ? `${sku[i].sku_property_id}`
                : 'option-0';
            } else if (!sku && !isNewSupplier) {
              option_values[i][index] = 'SKU_CHANGED';
            }
          });
        } catch (e) {
          default_category_values.fill(undefined);
          option_values.forEach((item) => {
            item.fill('SKU_CHANGED');
          });
        }
      });

    const option_values_length = option_values.length;
    const options_length = options.length;

    // ae属性分类个数大于shopify属性分类个数
    if (option_values_length > options_length && optionsMap) {
      const len = option_values_length - options_length;
      const dis = new Array(len).fill(undefined);

      const positionArr = optionsMap[0].option_id.split('-');
      const valueArr = optionsMap[0].value.split('-');
      const sku = sku_map[sup.supply[0].sku];
      dis.forEach((item, i) => {
        const po = positionArr[options_length + i - 1] * 1;
        option_values[po][0] = valueArr[po];
        default_category_values[po] = sku[po].sku_property_id
          ? `${sku[po].sku_property_id}`
          : undefined;
      });
    }
  });

  option_values.forEach((item, i) => {
    const shopify_option_Values = options[i] ? options[i].values : undefined;
    // ae分类属性下面没有选中任何值
    if (item.length == 0) {
      // 根据对应的shopify属性下面的值([s,m,L,XL]) 都填充undefined
      if (shopify_option_Values) {
        item.push(...new Array(shopify_option_Values.length).fill(undefined));
      } else {
        // shopify也没有填充一个undefined
        item.push(undefined);
      }
    }
  });

  if (!basic_has_ae) {
    default_category_values = new Array(option_values.length).fill(undefined); // 默认什么都没选
  }
  // console.log(optionsArr, JSON.parse(JSON.stringify(option_values)), '啦啦啦啦啦啦');
  option_values.forEach((category, i) => {
    category.forEach((selectedValue, index) => {
      // console.log(selectedValue);
      if (['NOT_FOUND', 'UNSELECTED'].includes(selectedValue)) {
        category[index] = undefined;
        return;
      }

      if (
        selectedValue &&
        !optionsArr.find((aeOption) =>
          aeOption?.values?.find(
            (newestAEOption) =>
              getRealSkuhandler(newestAEOption.id) ===
              getRealSkuhandler(selectedValue)
          )
        )
      ) {
        category[index] = 'SKU_CHANGED';
      }
    });
  });
  console.log(optionsArr, option_values, '-------option_values-------');
  return [
    optionsArr,
    option_values,
    default_category_values,
    skus_map,
    sku_map,
    skuPureIdMap,
  ];
}

/* eslint react/no-array-index-key: 0 */
@connect(({ productmy, loading, user }) => ({
  productmy,
  loading: loading.models.productmy,
  importFlag: user,
  saveLoading: loading.effects['productmy/ext'],
  getAliProductLoading: loading.effects['productmy/aeProductDetail'],
  noviceGuidanceInfo: user?.noviceGuidanceInfo,
}))
export default class Basic extends React.PureComponent {
  cacheUrlFromDsers = {};

  constructor(props) {
    super(props);
    this.state = {
      options: [],
      default_options: [],
      default_category_values: [],
      default_options_values: [],
      second_options: [],
      second_category_values: [],
      second_options_values: [],
      product_pool: [], // 商品池中的商品
      showOptionPush: false,
      showSave: false, // 是否显示保存按钮
      removedVariant: [], // 被删除的variant
      showImport: false,
      btnDispath: false,
      invalidMappingVariant: null,
      mapping_url: '',
      showOptions: false,
      setToStatus: false,
      repeatTip: false,
      select: [],
      canReplace: true, // 是否可以进行主副供应商替换
      deleteVariantIdMap: [],
      visibleDeletedAllVariant: false,
      showDeletePoolProduct: false,
      showDeletePoolProductId: '',
      showDeleteSupplierProduct: false,
      showDeleteSupplierProductId: 0,
      isThree: false,
      isTwo: false,
      isDeleteAll: false,
      selectValue: 'AliExpress',
      isNewAuth: {
        one: false,
        two: false,
        three: false,
      },
      isStatus: false,//当前按钮状态
      clearProductPoolTip: false,
      forbidden: false,
      defaultSelectValue: 'AliExpress',
      importProductBtnLoading: false,
    };
  }
  isSaveBtn = true


  componentWillMount() {
    const {
      types,
      originProductData: {
        prod: {
          detail: { variants = [], id },
          ext: { map_type = 0 } = {},
        },
      },
    } = this.props;

    const mapType = map_type;

    this.map_type = mapType;
    this.save_map_type = mapType;
    this.shopifyProductId = id;
  }

  componentDidMount() {

    // 进入页面打点
    window.dtag.set({
      event: 'init',
      module: 'mapping',
      action: 'entering_the_basic_page',
    });
    const { originProductData } = this.props;
    const {
      prod,
      prod: {
        detail: { options, variants },
        ext = {},
      },
      orderVaraintId,
    } = originProductData;

    // const productId = ext && ext.product_pool && ext.product_pool[0]?.product_id;
    // let supplys = ext?.basic && ext.basic[productId]?.supply;
    // supplys = supplys && supplys.filter(item => item.variant_id);
    // if (supplys && variants.length !== supplys?.length && variants.length < supplys?.length ) {
    //   this.delVariant(variants, supplys);
    // }


    const { product_pool = [], basic = {} } = ext; // product_pool 导入过的商品

    this.props.onRef(this);

    this.intlProductPool(basic, product_pool); // 从商品池找到主副供应商
    this.initCheckVariant();
    this.product_pool[0] && this.getAeProductOptions(0); // 获取ae商品信息
    this.product_pool[1] && this.getAeProductOptions(1); // 获取ae商品信息
    if (this.props.importFlag.newUserInfo.created_at > window.NEW_INFO_TIME && JSON.parse(localStorage.getItem('store'))?.import_flag == 0) {
      // if (true) {
      this.setState({
        isNewAuth: {
          one: true,
          two: false,
          three: false,
        },
      })
    }

    if (
      this.product_pool.length === 1 &&
      this.product_pool[0]?.platform_type === 4
    ) {
      this.setState({
        defaultSelectValue: 'Tmall',
      });
    }

    this.prod = prod;
    this.shopify_variants = variants;
    const orderVariant = variants.find((item) => item.id === orderVaraintId);
    // 查找order对应的variant option 用于提示用户当前订单所使用的variant
    const orderVariantOptionsIndex = [];
    if (orderVariant) {
      options.forEach((item, index) => {
        const varaintOptionValue = orderVariant[`option${item.position || 0}`];
        const resIndex = item.values?.findIndex(
          (optionValue) => optionValue === varaintOptionValue
        );
        if (resIndex > -1) {
          orderVariantOptionsIndex[index] = resIndex;
        }
      });
    }
    this.setRemovedVariant(originProductData);
    this.setState({
      options,
      product_pool,
      orderVariantOptionsIndex,
    });
  }

  componentDidUpdate() {
    // 主副供应商有一个没有option，就禁用切换按钮
    if (
      !this.state.default_options?.length ||
      !this.state.second_options?.length
    ) {
      this.setState({
        canReplace: false,
      });
    } else {
      this.setState({
        canReplace: true,
      });
    }
  }

  save_map_type = 0;

  map_type = 0;

  prod = null;

  skus_map = {
    default: null,
    second: null,
  };

  shopify_variants = null; // shopify商品的variants

  product_pool = []; // 主副供应商(已设置的供应商) 不是商品池商品

  product_pool_ids = []; // 主副供应商商品id

  ae_detail_map = {};

  set_to = 1; // 设置为主供应商还是副供应商

  set_to_prod = null; // 用商品池里的哪个商品设置供应商

  hasSave = false;

  delVariant = [];

  currentDeleteVariantId = '';

  sku_map = {
    default: null,
    second: null,
  };

  skuPureIdMap = {
    default: null,
    second: null,
  };

  initCheckVariant = () => {
    const { originProductData } = this.props;
    const {
      prod: {
        detail: { options, variants },
        ext = {},
      },
    } = originProductData;
    const { basic = {}, map_type } = ext;
    if (map_type === 1) {
      const invalidMappingVariant = [];
      Object.keys(basic).forEach((productId) => {
        const mapping = basic[productId];
        if (!mapping) return;
        const { options_map, supply } = mapping;
        if (!options_map?.length) {
          return;
        }
        variants.forEach((variant) => {
          const mappingExsitInOptionsMap = options.every(
            (shopifyOption, index) => {
              const optKey = `option${index + 1}`;
              const optValue = variant[optKey];
              const res = options_map.find((optionMap) => {
                return (
                  optionMap.value === optValue &&
                  optionMap.sku &&
                  optionMap.option_id === `${shopifyOption.id}`
                );
              });
              return res;
            }
          );

          if (
            mappingExsitInOptionsMap &&
            !supply?.find(
              (supplyItem) => supplyItem.variant_id === variant.id
            ) &&
            !invalidMappingVariant.find((item) => item.id === variant.id)
          ) {
            invalidMappingVariant.push(variant);
          }
        });
      });

      if (invalidMappingVariant.length) {
        this.props?.setInvalidVariants(invalidMappingVariant);
      }
    }
  };

  // 设置主副供应商
  intlProductPool = (basic, product_pool) => {
    const pool_map = {};
    product_pool.forEach((item) => {
      if (!item?.product_id_str) {
        item.product_id_str = `${item?.product_id}`;
      }
      if (item.platform_type === 1) {
        item.platform_type = 2;
      }
      pool_map[item?.product_id_str] = item;
    });

    // 形成如下对象
    // pool_map:{
    //   product_id: {
    //     image_src: xxxxx,
    //     oriduct_id: xxxx,
    //     title: xxx,
    //   }
    // }

    const hasDefaultSp = this.hasDefaultSp(basic);

    // if(product_pool.length) {
    //   Object.entries(basic).forEach(item => {
    //     // 是主供应商 或 没有主供应商
    //     if (item[1].is_default || !hasDefaultSp) {
    //       this.product_pool[0] = pool_map[item[0]];
    //       this.product_pool_ids[0] = item[0];
    //     } else {
    //       this.product_pool[1] = pool_map[item[0]] || { product_id: item[0], image_src: '', platform_type: '', title: 'Default title' };
    //       this.product_pool_ids[1] = item[0];
    //     }
    //   });
    // }
    Object.entries(basic).forEach((item) => {
      // 是主供应商 或 没有主供应商
      console.log(item[0], '----------');
      if (item[1].is_default || !hasDefaultSp) {
        this.product_pool[0] = pool_map[item[0]];
        this.product_pool_ids[0] = item[0];
      } else {
        this.product_pool[1] = pool_map[item[0]] || {
          product_id_str: `${item[0]}`,
          product_id: item[0],
          image_src: '',
          platform_type: '',
          title: 'Default title',
        };
        this.product_pool_ids[1] = item[0];
      }
    });
  };

  // 判断有没有主供应商
  hasDefaultSp = (basic) => {
    let flag = false;
    Object.values(basic).forEach((item) => {
      if (item.is_default) {
        flag = true;
      }
    });
    return flag;
  };

  getAeProductDetail = async (product) => {
    const productId = product?.product_id_str;
    return new Promise((resolve, reject) => {
      this.props.dispatch({
        type:
          product?.platform_type === 4
            ? 'productmy/tmProductDetail'
            : 'productmy/aeProductDetail',
        payload: {
          data: {
            url: getSupplierUrl(product.platform_type, productId),
            product_id: productId,
            supplier_type: product?.platform_type,
            country: this.props.originProductData.ship_to,
          },
          callback: (d) => {
            if (d.code == 2000) {
              this.props.publicBtnDispathOpen();
              resolve(d.data);
              return;
            }
            if (d.code === 4290) {
              this.props.publicBtnDispathShut();
              this.setState({ btnDispath: true });
            }
            reject();
          },
        },
      });
    });
  };

  // 获取速卖通产品详情
  getAeProductOptions = (index) => {
    this.props.changeLoading(true);
    const product_id = this.product_pool[index]?.product_id_str;
    this.props.dispatch({
      type:
        this.product_pool[index]?.platform_type === 4
          ? 'productmy/tmProductDetail'
          : 'productmy/aeProductDetail',
      payload: {
        data: {
          url: getSupplierUrl(
            this.product_pool[index]?.platform_type,
            product_id
          ),
          product_id,
          supplier_type: this.product_pool[index]?.platform_type,
          country: this.props.originProductData.ship_to,
        },
        callback: (d) => {
          try {
            if (d.code == 2000) {
              if (
                !d?.data?.aeop_ae_product_s_k_us?.aeop_ae_product_sku?.length &&
                d?.data?.product_status_type === 'offline'
              ) {
                this.props.changeLoading(false);
                message.error(intl.getHTML('mapping.offline_error_tip'));
                return;
              }
              this.props.publicBtnDispathOpen();
              this.setAeProductOptions(index, d.data);
            } else if (d.code === 4290) {
              this.props.publicBtnDispathShut();
              this.setState({ btnDispath: true });
            }
            const { options } = this.state;
            if (
              options?.length == 2 &&
              d.code == 5000 &&
              /get aliexpress product error/g.test(d.msg)
            ) {
              this.setState({
                showOptions: true,
              });
            }
          } catch (error) {
            message.error(intl.getHTML('mapping.mapping_info_error_tip'));
          }

          this.props.changeLoading(false);
        },
      },
    });
  };

  // 设置option数据
  setAeProductOptions = (index, data) => {
    if (!data) {
      this.getAeProductOptions(index);
      return;
    }

    const { product_pool } = this.state;

    this.ae_detail_map[data?.product_id_str] = data; // 存ae商品信息 有的话就不从新获取了

    const options = getOptionsShowData(
      data,
      this.prod,
      index,
      product_pool,
      data?.product_id_str
    );

    // 主供应商
    if (index == 0) {
      this.skus_map.default = options[3];
      this.sku_map.default = options[4];
      this.skuPureIdMap.default = options[5];
      this.setState({
        default_options: options[0],
        default_options_values: options[1],
        default_category_values: options[2],
      });
    } else {
      this.skus_map.second = options[3];
      this.sku_map.second = options[4];
      this.skuPureIdMap.second = options[5];
      this.setState({
        second_options: options[0],
        second_options_values: options[1],
        second_category_values: options[2],
      });
    }
  };

  /**
   * 主副供应商转换
   *
   */
  switchBasic = () => {
    const {
      default_options,
      default_options_values,
      default_category_values,
      second_options,
      second_options_values,
      second_category_values,
      canReplace,
    } = this.state;

    this.setState({
      isNewAuth: {
        one: false,
        two: false,
        three: false,
      },
    })

    if (!canReplace) {
      return;
    }

    if (this.sku_map.second) {
      const newBasic = cloneDeep(this.skus_map.default);
      this.skus_map.default = cloneDeep(this.skus_map.second);
      this.skus_map.second = cloneDeep(newBasic);
      this.state.product_pool.reverse();
      this.product_pool_ids.reverse();
      this.product_pool.reverse();
      const cacheDefaultPureMap = cloneDeep(this.skuPureIdMap.default);
      this.skuPureIdMap.default = cloneDeep(this.skuPureIdMap.second);
      this.skuPureIdMap.second = cacheDefaultPureMap;

      this.setState({
        default_options: second_options,
        default_options_values: second_options_values,
        default_category_values: second_category_values,
        second_options: default_options,
        second_options_values: default_options_values,
        second_category_values: default_category_values,
      });

      this.showSaveBtn();
    }
  };

  // 获取速卖通产品详情
  importAeProductOptions = (url) => {

    const { mapping_url, selectValue } = this.state;
    if (mapping_url) {
      this.setState({
        importProductBtnLoading: true,
      });
    }
    if (selectValue === 'Tmall') {
      CommonDataCollect({
        action: 'myproduct_basic_mapping_import_tmall',
        event_type: 'my_product_tmall',
      })
        .then((res) => {

        })
        .catch((err) => {
          console.log('打点', err);
          this.setState({
            importProductBtnLoading: false,
          });
        });
    }
    if (!mapping_url) {
      return;
    }
    // 统计用户mapping使用的url是否来自dsers的商品池或optimizer
    const urlSearchIndex = mapping_url.indexOf('?');
    if (urlSearchIndex > -1) {
      const urlSearch = mapping_url.slice(urlSearchIndex + 1);
      const searchObj = qs.parse(urlSearch);
      if (searchObj.dsref) {
        const aeProductId = (mapping_url.match(/\/([1-9][0-9]*)\.htm/) ||
          [])[1];
        if (aeProductId) {
          this.cacheUrlFromDsers[aeProductId] = searchObj.dsref;
        }
      }
    }

    this.props.dispatch({
      type:
        selectValue === 'Tmall'
          ? 'productmy/tmProductDetail'
          : 'productmy/aeProductDetailInfo',
      payload: {
        data: {
          url: mapping_url,
          product_id: getSupplierId(
            selectValue === 'Tmall' ? 4 : 2,
            mapping_url
          ),
          supplier_type: selectValue === 'Tmall' ? 4 : 2,
          country: this.props.originProductData.ship_to,
        },
        callback: (d) => {
          this.setState({
            importProductBtnLoading: false,
          });
          dispatchEventForGuide({
            type: 'mapping',
            payload: {
              category:
                d && d.code == 2000 ? 'IMPORT_SUCCESS' : 'IMPORT_FAILED',
            },
          });
          if (d.code == 2000) {
            this.setProductPool(d.data);
            this.props.publicBtnDispathOpen();
            this.props.dispatch({
              type: 'productmy/getImportFlag',
            });
            this.setState({
              isNewAuth: {
                one: false,
                two: true,
                three: false,
              },
            })

            this.setState({
              mapping_url: '',
            });
            window.dtag.set({
              event: 'click',
              module: 'mapping',
              action: 'product_imported_successfully',
            });
          } else if (d.code === 4290) {
            this.setState({ btnDispath: true });
            this.props.publicBtnDispathShut();
            //  跳转订阅 && 弹窗拦截
            //  window.EVENT.emit(GuildeAction.TurnPlan_Pop, `c-${Date.now()}`, true);
            //  window.location.href='/app/pricing?select=1'
            window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'ProductsLimit');
          }
        },
      },
    });
  };

  setProductPool = (data) => {
    const { product_pool } = this.state;
    let flag = false;
    product_pool.forEach((item) => {
      if (item.product_id_str == data?.product_id_str) {
        flag = true;
      }
    });

    if (flag) {
      // 已存在
      return;
    }

    const obj = { platform_type: data?.supplier_type };
    obj.image_src = data.image_u_r_ls.split(';')[0];
    obj.product_id = data.product_id;
    obj.title = data.subject;
    obj.product_id_str = data?.product_id_str;
    this.ae_detail_map[data?.product_id_str] = data;

    product_pool.push(obj);

    this.setState({
      product_pool: [...product_pool],
    });

    this.showSaveBtn();
  };

  // 设置shopify删除的variant
  setRemovedVariant = (originProductData) => {
    const {
      variant,
      prod: { ext },
    } = originProductData;

    const { del = [] } = ext || {};

    const deleteVariants = hasDeleteVariant(originProductData, 'basic');

    // console.log(originProductData,JSON.parse(JSON.stringify(deleteVariants) ) ,'-------deleteVariants--------');
    if (
      variant &&
      !deleteVariants.some((item) => item.id == variant.variant_id)
    ) {
      deleteVariants.push(variant);
    }
    deleteVariants.forEach((item) => {
      const flag = del.some((sp) => sp.variant_id == item.id);

      if (!flag) {
        del.push({
          variant_id: item.variant_id || item.id,
          variant_title: item.Title || item.title || '',
          platform_type: item.sku ? item?.platform_type : 1,
          platform_product_id: item.sku ? item?.platform_product_id : '',
          platform_sale_id: '',
          quantity: 1,
          is_base: true,
          sku: item.sku || '',
          supplier_sku_id: item.sku_id,
          sku_image: item.sku_image || '',
          title: '',
        });
      }
    });

    this.setState({
      removedVariant: [...del],
    });
  };

  // 改变大的分类
  changeCategory = (v, index, type) => {
    const {
      default_category_values,
      default_options_values,
      second_category_values,
      second_options_values,
    } = this.state;

    if (type == 'default') {
      default_category_values[index] = v;
      default_options_values[index] = new Array(
        default_options_values[index].length
      ).fill(undefined);

      this.setState({
        default_category_values: [...default_category_values],
        default_options_values: [...default_options_values],
      });
    } else {
      second_category_values[index] = v;
      second_options_values[index] = new Array(
        second_options_values[index].length
      ).fill(undefined);

      this.setState({
        second_category_values: [...second_category_values],
        second_options_values: [...second_options_values],
      });
    }

    this.showSaveBtn();
  };

  // 改变sku
  changeSku = (v, index, type, i) => {
    const { default_options_values, second_options_values } = this.state;

    if (type == 'default') {
      default_options_values[index][i] = v;
      this.setState({
        default_options_values: [...default_options_values],
      });
    } else {
      second_options_values[index][i] = v;
      this.setState({
        second_options_values: [...second_options_values],
      });
    }

    this.showSaveBtn();
  };

  // 判断一个option都没选值
  optionValuesHasVal = (arr) => {
    let flag = true;
    arr.forEach((item) => {
      let d = false;
      item.forEach((v) => {
        if (v !== undefined) {
          d = true;
        }
      });
      if (!d) {
        flag = false;
      }
    });
    return flag;
  };

  // 判断 color size有没有重复
  hasRepeat = (arr) => {
    const c = [];
    let flag = false;

    arr.forEach((v) => {
      if (c.includes(v) && v !== undefined) {
        flag = true;
      }
      c.push(v);
    });
    return flag;
  };

  /**
   * 组装mapping数据
   * @param {*} item ae商品
   * @param {*} index // 0主供应商 1副供应商
   */
  setNewSp = (item, index) => {
    const v = {
      0: 'default_options_values',
      1: 'second_options_values',
    };
    const cv = {
      0: 'default_category_values',
      1: 'second_category_values',
    };

    const options_values = this.state[v[index]]; // 选择的具体sku的值 s还xl

    // 重复分类
    if (this.hasRepeat(this.state[cv[index]])) {
      return 'repeat';
    }

    // 有分类没选
    if (!this.optionValuesHasVal(options_values)) {
      return 'hasEmpty';
    }

    const { options } = this.state;
    const supply_arr = [];
    const aeOnlyOptionPosition = getAEOnlyOptionPosition(
      options,
      index === 0 ? this.sku_map.default || {} : this.sku_map.second || {}
    );

    // 例如 shopify有2个分类 顺序是size color ae只有一个color
    if (aeOnlyOptionPosition !== 0) {
      this.shopify_variants.forEach((varItem) => {
        let n = 0;
        options.forEach((op, i) => {
          if (i === aeOnlyOptionPosition) {
            n = op.values.findIndex((v) => v == varItem[`option${i + 1}`]);
          }
        });

        if (!options_values[0][n]) {
          return;
        }

        const sp = this.getEasySp(
          options_values[0][n],
          index,
          item,
          varItem.id,
          varItem
        );
        supply_arr.push(sp);
      });

      return [supply_arr, []];
    }

    this.shopify_variants.forEach((varItem, varI) => {
      const arr = [];
      options.forEach((op, i) => {
        const n = op.values.findIndex((v) => v == varItem[`option${i + 1}`]);
        arr.push(n); // 一个shopify variant里包含s和red 对应在shopify options的values的位置
      });

      const aeArr = [];
      // 用shopify的位置 找到对应的ae的s和red的sku
      arr.forEach((optionIndex, optionCategoryIndex) => {
        if (options_values[optionCategoryIndex]) {
          aeArr.push(options_values[optionCategoryIndex][optionIndex]);
        }
      });

      // 如果ae的属性分类大于shopify的分类
      if (options_values.length > options.length) {
        options_values.forEach((aeOption, aeIndex) => {
          if (aeIndex >= options.length) {
            aeArr.push(aeOption[0]);
          }
        });
      }
      console.log(aeArr, options_values, 'spsppspsp_+_+_+spspspps');
      // 包含undefined说明有的没有选 这个variant不能组合成对应的ae sku
      if (
        aeArr.includes(undefined) ||
        aeArr.includes('SKU_CHANGED') ||
        !aeArr?.length
      ) {
        return;
      }

      const sp = this.getSp(aeArr, index, item, varItem.id, varItem); // 生成mapping数据
      supply_arr.push(sp);
    });

    return [supply_arr, this.getOptionsMap(options_values, index)];
  };

  // 生成options_map shopify的options对应ae的sku
  getOptionsMap = (options_values, index) => {
    const {
      detail: { options },
    } = this.prod;
    const d = {
      0: 'default',
      1: 'second',
    };
    const arr = [];
    const sku_map = this.skus_map[d[index]];

    const ae_options = this.state[`${d[index]}_options`];
    const category_values = this.state[`${d[index]}_category_values`];

    options_values.forEach((item, i) => {
      item.forEach((skuValue, idx) => {
        const obj = {
          option_id: '',
          sku: '',
          value: '',
        };

        const skuObj = this.getValueObj(
          skuValue,
          i,
          category_values,
          ae_options
        );

        if (options[i]) {
          obj.option_id = `${options[i].id}`;
          obj.value = options[i].values[idx];
          if (skuValue) {
            obj.sku = skuObj.id;
          } else {
            obj.sku = '';
          }
        } else {
          obj.sku = skuObj.id;
        }
        arr.push(obj);
      });
    });

    return arr;
  };

  // 获取
  getValueObj = (v, i, category_values, ae_options) => {
    let obj = {};
    ae_options.forEach((item) => {
      if (item.sku_property_id == category_values[i]) {
        item.values.forEach((sku) => {
          if (sku.id == v) {
            obj = sku;
          }
        });
      }
    });
    return obj;
  };

  getEasySp = (
    values,
    index,
    item,
    shopify_variants_id,
    shopifyVariantItem = {}
  ) => {
    // index默认供应商0 还副供应商1
    const d = {
      0: 'default',
      1: 'second',
    };

    const ae_options = this.state[`${d[index]}_options`];
    let sku = null;

    ae_options[0].values.forEach((skuItem) => {
      if (skuItem.id === values) {
        sku = skuItem;
      }
    });

    const skuId = values;
    const aeProduct = this.ae_detail_map[item?.product_id_str];
    let sku_desc = '';
    let sku_id = '';
    aeProduct?.aeop_ae_product_s_k_us?.aeop_ae_product_sku?.forEach((i) => {
      sku_id = i?.sku_id;
      if (getRealSkuhandler(skuId) === getRealSkuhandler(i.id)) {
        i?.aeop_s_k_u_propertys?.aeop_sku_property?.forEach((it, ind) => {
          if (ind < i.aeop_s_k_u_propertys.aeop_sku_property.length - 1) {
            sku_desc += `${it.sku_property_value} / `;
          } else {
            sku_desc += it.sku_property_value;
          }
        });
      }
    });
    const skuIamge =
      aeProduct?.aeop_ae_product_s_k_us?.aeop_ae_product_sku?.filter((i) => {
        if (i.id === skuId) {
          return i;
        }
      })[0]?.image_url;
    return {
      is_base: true,
      platform_product_id: aeProduct?.product_id_str
        ? `${aeProduct?.product_id_str}`
        : '',
      platform_sale_id: aeProduct.owner_member_id,
      platform_type: aeProduct?.supplier_type,
      quantity: 1,
      sku: skuId,
      supplier_sku_id: sku_id,
      sku_desc,
      sku_image: skuIamge,
      title: aeProduct.subject,
      variant_id: shopify_variants_id,
      variant_title: shopifyVariantItem.title || '',
    };
  };

  getSp = (
    values,
    index,
    item,
    shopify_variants_id,
    shopifyVariantItem = {}
  ) => {
    // index默认供应商0 还副供应商1
    const d = {
      0: 'default',
      1: 'second',
    };

    const ae_options = this.state[`${d[index]}_options`];
    const category_values = this.state[`${d[index]}_category_values`];

    const sku_map = this.skus_map[d[index]];
    const pureIdMap = this.skuPureIdMap[d[index]];
    let arr = [];

    values.forEach((v, i) => {
      const obj = this.getValueObj(v, i, category_values, ae_options); // 生成sku_map[v];
      arr.push(obj);
    });

    arr = arr.sort((a, b) => a.position - b.position); // 排序 ae的sku是red和s 用户选的是s和red

    const id = [];
    let img = '';
    arr.forEach((v) => {
      id.push(v.id);
      if (v.img) {
        img = v.img;
      }
    });

    const pureId = arr
      .reduce((pre, current) => {
        return `${pre}${current?.sku_property_id}:${current?.property_value_id};`;
      }, '')
      .slice(0, -1);

    const skuId = pureIdMap?.[pureId]?.id || id.join(';');
    const aeProduct = this.ae_detail_map[item?.product_id_str] || {};
    let sku_desc = '';
    aeProduct?.aeop_ae_product_s_k_us?.aeop_ae_product_sku?.forEach((i) => {
      if (getRealSkuhandler(skuId) === getRealSkuhandler(i.id)) {
        i?.aeop_s_k_u_propertys?.aeop_sku_property?.forEach((it, ind) => {
          if (ind < i.aeop_s_k_u_propertys.aeop_sku_property.length - 1) {
            sku_desc += `${it.sku_property_value} / `;
          } else {
            sku_desc += it.sku_property_value;
          }
        });
      }
    });
    const skuIamge =
      aeProduct?.aeop_ae_product_s_k_us?.aeop_ae_product_sku?.filter((i) => {
        if (i.id === skuId) {
          return i;
        }
      })[0]?.image_url;

    return {
      is_base: true,
      platform_product_id: aeProduct?.product_id_str
        ? `${aeProduct?.product_id_str}`
        : '',
      platform_sale_id: aeProduct.owner_member_id,
      platform_type: aeProduct?.supplier_type,
      quantity: 1,
      sku: skuId,
      sku_desc,
      sku_image: skuIamge,
      supplier_sku_id: pureIdMap?.[pureId]?.sku_id,
      title: aeProduct.subject,
      variant_id: shopify_variants_id,
      variant_title: shopifyVariantItem.title || '',
    };
  };

  basicExt = undefined; // 存储basicmapping的改动

  basicMappingRepeatErr = false;

  basicMappingNullErr = false;

  // 保存 父级调用
  handleSave = (type) => {
    const {
      originProductData: {
        prod: {
          detail: { variants = [] },
          ext = {},
          store_id,
        },
      },
      noviceGuidanceInfo,
    } = this.props;

    const copyExt = JSON.parse(JSON.stringify(ext));

    const { advance, bogo, bundle } = copyExt;

    const { product_pool, removedVariant } = this.state;

    const arr = [];
    const options_map = [];
    let flag = true;
    let errText = '';

    if (noviceGuidanceInfo.mapped == 0) {
      sendNoviceGuidanceInfo({ mapped: 1 }, () => {
        this.props.dispatch({ type: 'user/getNoviceGuidance' });
      });
    }
    this.product_pool.forEach((item, index) => {
      const sp = this.setNewSp(item, index); // 组装mapping数据
      // 选了相同的分类
      // if(this.state.showSave){
      if (sp == 'repeat') {
        flag = false;
        this.basicMappingRepeatErr = true;
        errText = intl.get('mapping.repeat_option');
      }
      // 有分类没有选
      if (sp == 'hasEmpty') {
        flag = false;
        this.basicMappingNullErr = true;
        errText = intl.get('mapping.select_variant');
      }
      if (sp.length == 0) {
        flag = false;
        return;
      }
      // }
      arr.push(sp[0]);
      options_map.push(sp[1]);
    });

    if (!flag) {
      // message.error(errText);
      // this.basicMappingErr = true
      return;
    }

    if (type === true) {
      copyExt.map_type = this.save_map_type;
    } else {
      copyExt.map_type = this.save_map_type || 1;
    }

    copyExt.product_pool = product_pool;
    copyExt.basic = {};

    arr.forEach((item, i) => {
      const flag = i == 0;
      const prod = {};
      if (item.length != 0) {
        prod.is_default = flag;
        prod.supply = item;
        prod.options_map = options_map[i];
        copyExt.basic[this.product_pool[i]?.product_id_str] = prod;
      }
    });

    const shouldPostRef = {};

    copyExt.product_pool.forEach((item) => {
      if (
        copyExt.basic[item?.product_id_str] &&
        copyExt.basic[item?.product_id_str].options_map
      ) {
        item.options_map = copyExt.basic[item?.product_id_str].options_map;
        if (this.cacheUrlFromDsers[item?.product_id_str]) {
          shouldPostRef[item?.product_id_str] =
            this.cacheUrlFromDsers[item?.product_id_str];
        }
      }
    });

    // this.props.setLoading(true);
    this.hasSave = true;
    this.props.setHasSave(this.hasSave);
    if (JSON.parse(localStorage.getItem('store'))?.import_flag == 0 && this.props.importFlag.newUserInfo.created_at > window.NEW_INFO_TIME) {
      this.setState({ isStatus: true });
    } else {
      this.setState({ isStatus: false });
    }

    console.log(11111, type && this.isSaveBtn)

    if (type && this.isSaveBtn) {
      this.setState({
        isNewAuth: {
          one: false,
          two: false,
          three: false,
        },
        isThree: true,
        isTwo: true,

        isStatus: JSON.parse(localStorage.getItem('store'))?.import_flag == 0 && this.props.importFlag.newUserInfo.created_at > window.NEW_INFO_TIME ? true : false
      })
      this.isSaveBtn = false
    } else {
      if (this.state.isNewAuth.three || this.state.isNewAuth.two) {
        this.setState({
          isStatus: false
        })
      } else if (this.state.isThree || this.state.isTwo) {
        this.setState({
          isStatus: false
        })
      } else {
        this.setState({
          isNewAuth: {
            one: true,
            two: false,
            three: false,
          },
          isThree: false,
          isTwo: false,
          isStatus: false
        })
      }

    }

    if (bundle) {
      deleteBundle(bundle, variants);
    }

    if (advance) {
      deleteAdvance(advance, variants);
    }

    if (bogo) {
      deleteBogo(bogo, variants);
      if (Object.values(bogo).length == 0) {
        delete copyExt.bogo;
      }
    }

    copyExt.del = [...removedVariant];
    this.basicExt = copyExt;

    // this.props.dispatch({
    //   type: 'productmy/ext',
    //   payload: {
    //     data: {
    //       product_id: this.prod.id,
    //       ext: copyExt,
    //       store_id,
    //       ref:localStorage.getItem('mapping_dot')
    //     },
    //     callback: d => {
    //       // this.props.setLoading(false);
    //       if (d.code == 2000 || d.code == 2010) {
    //         message.success(intl.get('mapping.succeed'));
    //         const { originProductData } = this.props;
    //         postMappingProductRef(shouldPostRef, 1, originProductData);
    //         webCustomCollect({
    //           action:"mapping_dot_1",
    //           custom_info:[
    //             {
    //               name:localStorage.getItem('mapping_dot'),
    //               value:'mappinged'
    //             }
    //           ]
    //         })
    //         this.props.hasSavedHandler('basic')
    //         if(window.location.pathname.substring(window.location.pathname.lastIndexOf("/")+1).match(/myproduct/)){
    //           window.IS_UPDATE_IMPORT_PRODUCT = true
    //           setTimeout(()=>{
    //             window.IS_UPDATE_IMPORT_PRODUCT = false
    //           },15*1000)
    //         }
    //       }

    //       this.props.setInvalidVariants(null);

    //       if (type === 'fromSaveButton') {
    //         dispatchEventForGuide({ type: 'mapping', payload: { category: d && (d.code == 2000 || d.code == 2010) ? 'MAPPING_SUCCESS' : 'MAPPING_FAILED' } });
    //       }
    //     }
    //   }
    // });
  };

  showOptionPush = (prod) => {
    this.set_to_prod = prod;
    this.setState({
      showOptionPush: true,
    });
    if (this.set_to === 1) {
      if (
        this.product_pool.findIndex(
          (i) => i.platform_type != this.set_to_prod.platform_type
        ) > -1
      ) {
        this.setState({
          clearProductPoolTip: true,
        });
      }
      // todo 初始化的时候改变状态
      // if (this.product_pool_ids[0] === this.set_to_prod.product_id_str) {
      //   this.setState({
      //     setToStatus: true,
      //     repeatTip: true,
      //   });
      // }
    }
    if (
      this.set_to === 2 &&
      this.product_pool.findIndex(
        (i) => i.platform_type != this.set_to_prod.platform_type
      ) > -1
    )
      this.setState({
        setToStatus: true,
        forbiddenTip: true,
      });
  };

  hideOptionPush = () => {
    this.set_to = 1;
    this.setState({
      showOptionPush: false,
      repeatTip: false,
      forbiddenTip: false,
      clearProductPoolTip: false,
      setToStatus: false,
    });
    this.showSaveBtn();
  };

  RadioValueChange = (e) => {
    this.set_to = e.target.value;
    this.setState({
      setToStatus: false,
      repeatTip: false,
    });
    if (this.set_to === 1) {
      if (
        this.product_pool.findIndex(
          (i) => i.platform_type != this.set_to_prod.platform_type
        ) > -1
      ) {
        this.setState({
          clearProductPoolTip: true,
          setToStatus: false,
          forbiddenTip: false,
        });
      }
      // todo rediochange的时候改变状态1
      // if (this.product_pool_ids[0] === this.set_to_prod.product_id_str) {
      //   this.setState({
      //     setToStatus: true,
      //     repeatTip: true,
      //   });
      // } else {
      //   this.setState({
      //     setToStatus: false,
      //     repeatTip: false,
      //   });
      // }
    }
    if (this.set_to === 2) {
      if (
        this.product_pool.findIndex(
          (i) => i.platform_type != this.set_to_prod.platform_type
        ) > -1
      ) {
        this.setState({
          setToStatus: true,
          forbiddenTip: true,
          clearProductPoolTip: false,
        });
      }
      // todo rediochange的时候改变状态2
      // if (
      //   this.product_pool.length >= 2 &&
      //   this.product_pool_ids[1] === this.set_to_prod.product_id_str
      // ) {
      //   this.setState({
      //     setToStatus: true,
      //     repeatTip: true,
      //   });
      // } else {
      //   this.setState({
      //     setToStatus: false,
      //     repeatTip: false,
      //   });
      // }
    }
  };

  subSetTo = async () => {
    const index = this.set_to - 1;
    let flag = true;
    console.log('this.set_to_prod', this.set_to);
    if (this.product_pool_ids[index] === this.set_to_prod.product_id_str) {
      this.setState({
        setToStatus: true,
        repeatTip: true,
      });
      return;
    }

    this.product_pool.forEach((item, i) => {
      if (
        item?.product_id_str == this.set_to_prod?.product_id_str &&
        index != i
      ) {
        // todo list 一个供应商同时设置主副时的处理
        flag = false;
      }
    });

    if (!flag) {
      // this.hideOptionPush();

      this.setState({
        setToStatus: true,
        repeatTip: true,
      });

      return;
    }

    let data = this.ae_detail_map[this.set_to_prod?.product_id_str];
    if (!data) {
      try {
        data = await this.getAeProductDetail(this.set_to_prod);

      } catch (error) {
        return;
      }
    }
    this.product_pool[index] = this.set_to_prod;
    if (this.state.clearProductPoolTip) {
      this.deleteMappingConfirm(1);
    }
    this.hideOptionPush();
    this.product_pool_ids[index] = `${this.set_to_prod?.product_id_str}`;
    this.setAeProductOptions(index, data);
    if (this.state.isThree) {
      this.setState({
        isNewAuth: {
          one: false,
          two: false,
          three: false,
        },
        isTwo: true
      })
    } else {
      this.setState({
        isNewAuth: {
          one: false,
          two: false,
          three: true,
        },
        isTwo: true
      })
    }

  };

  // 改变mapping状态
  changeStatus = (v, save_map_type, t) => {
    this.map_type = save_map_type;
    this.save_map_type = save_map_type;
    // this.showSaveBtn();
    if (v) {
      this.props.changeMappingStatus(save_map_type);
      // this.handleSave();
      reactGaEvent({
        category: 'Mapping',
        action: 'open base',
      });
      return;
    }
    if (t == 1) {
      this.props.changeMappingStatus(save_map_type);
      // this.handleSave(true);
      reactGaEvent({
        category: 'Mapping',
        action: 'close base',
      });
    }
  };

  // 删除商品池里的商品
  deleteGoods = (id) => {
    this.setState({
      showDeletePoolProduct: true,
      showDeletePoolProductId: id,
    });

  };

  deleteMapping = (index) => {
    this.setState({
      showDeleteSupplierProduct: true,
      showDeleteSupplierProductId: index,
      isThree: true
    });
  };

  // 删除主供应商或副供应商
  deleteMappingConfirm = (index) => {
    let {
      default_options,
      default_category_values,
      default_options_values,

      second_options,
      second_category_values,
      second_options_values,
    } = this.state;

    this.setState({
      isNewAuth: {
        one: false,
        two: false,
        three: false,
      },
    })

    // 删除主供应商 把副供应商数据给主供应商 因为必须先有主供应商
    if (index == 0) {
      default_options = cloneDeep(second_options);
      default_category_values = cloneDeep(second_category_values);
      default_options_values = cloneDeep(second_options_values);
      this.skus_map.default = cloneDeep(this.skus_map.second);
      this.sku_map.default = cloneDeep(this.sku_map.second);
      this.skuPureIdMap.default = cloneDeep(this.skuPureIdMap.second);
      if (this.product_pool_ids.length == 2) {
        this.product_pool_ids[0] = this.product_pool_ids[1];
      } else {
        this.product_pool_ids = [];
      }
    }

    this.skus_map.second = null;
    this.product_pool.splice(index, 1);
    this.product_pool_ids.splice(1, 1);

    this.setState({
      default_options,
      default_category_values,
      default_options_values,

      second_options: [],
      second_category_values: [],
      second_options_values: [],
    });

    this.showSaveBtn();
  };

  // 显示保存按钮
  showSaveBtn = () => {
    this.setState({
      showSave: true,
    });
    this.props.showSaveBtn();
    this.props.changemodalHidden('basic');
  };

  // 是否应用了dsers供应商
  getIsFBD = () => {
    const {
      fbdSupply,
      originProductData: {
        prod: {
          detail: { id },
        },
      },
    } = this.props;

    let flag = false;

    if (fbdSupply[id]) {
      flag = true;
    }

    return flag;
  };

  onBasicChild = (ref) => {
    this.basics = ref;
  };

  switchTabCheckBasic = (flag) => {
    this.basics.changeChecked(flag);
  };

  importProductId = '';

  importVariantId = '';

  sp = {};

  // 显示导入供应商
  showImportSupply = (removeItem) => {
    this.importProductId = removeItem.platform_product_id;
    this.importVariantId = removeItem.variant_id;
    this.sp = removeItem;
    this.setState({
      showImport: true,
    });
  };

  // 关闭导入供应商
  hideImportSupply = () => {
    this.setState({
      showImport: false,
    });
  };

  // 导入供应商
  importCallback = (data, refStastics, select) => {
    const { removedVariant } = this.state;

    const list = [...removedVariant];

    list.forEach((item) => {
      if (item.variant_id == this.importVariantId) {
        item.variant_title = data.sku_desc;
        Object.assign(item, data);
      }
    });

    this.setState({
      removedVariant: list,
      select,
    });
    this.hideImportSupply();
    this.showSaveBtn();
  };

  // 获取被删除variant的margin top
  getMarginTop = () => {
    const { default_options, second_options, options } = this.state;

    const d = default_options.length - options.length;
    const s = second_options.length - options.length;
    let t = 0;

    t = s > d && s > 0 ? s : d;
    t = t > 0 ? t : 0;

    return t * 2 * 48;
  };

  // 是否删除在shopify已经删除的variant
  deleteVariant = (variantId) => {
    this.currentDeleteVariantId = variantId;
    this.setState({
      isDeleteAll: false,
      visibleDeletedAllVariant: true,
    });
  };

  // 删除在shopify已经删除的variant
  deleteVariantTrue = () => {
    const { removedVariant } = this.state;
    const del = removedVariant.filter(
      (item) => item.variant_id != this.currentDeleteVariantId
    );

    this.setState({
      removedVariant: [...del],
      deleteVariantIdMap: [
        ...this.state.deleteVariantIdMap,
        this.currentDeleteVariantId,
      ],
      visibleDeletedAllVariant: false,
    });
    this.showSaveBtn();
  };

  deleteVariantSp = (variantId) => {
    const { removedVariant } = this.state;

    removedVariant.forEach((item) => {
      if (item.variant_id === variantId) {
        Object.assign(item, {
          platform_product_id: '',
          platform_sale_id: '',
          platform_type: 1,
          sku: '',
          sku_image: '',
          title: '',
          sku_desc: '',
        });
      }
    });
    this.setState({
      removedVariant: [...removedVariant],
    });

    this.showSaveBtn();
  };

  handleMoreActionChange = (type, data) => {
    if (type === 'open_shipping') {
      this.props.dispatch({
        type: 'dropshippersetting/showModal',
        payload: {
          data,
        },
      });
    }
  };

  deleteAllDelVariant = (e) => {
    const { removedVariant } = this.state;
    const del = removedVariant.map((item) => item.variant_id);

    this.setState({
      removedVariant: [],
      deleteVariantIdMap: del,
      visibleDeletedAllVariant: false,
      isDeleteAll: false,
    });
    this.showSaveBtn();
  };

  checkDeleteVariants = (v) => {
    this.props.checkVariantStatusHandler(v);
  };

  componentWillUnmount() {
    this.props.checkVariantStatusHandler(undefined);
  }

  confirmDeletePoolProduct = () => {
    const { product_pool, showDeletePoolProductId } = this.state;

    const arr = product_pool.filter((item) => {
      if (
        item?.product_id_str &&
        item?.product_id_str != showDeletePoolProductId
      ) {
        return item;
      }
    });
    const ind = this.product_pool_ids.findIndex(
      (v) => v == showDeletePoolProductId
    );

    if (ind != -1) {
      this.deleteMappingConfirm(ind);
    }
    this.setState({
      product_pool: [...arr],
      showDeletePoolProduct: false,
    });

    if (this.state.isThree) {
      this.setState({
        isNewAuth: {
          one: false,
          two: false,
          three: false,
        },
        isTwo: true
      })
    } else {
      this.setState({
        isNewAuth: {
          one: false,
          two: false,
          three: false,
        },
        isTwo: true
      })
    }


    this.showSaveBtn();
  };

  tmallSkuChangeTip = () => {
    let flag = false;
    if (
      this.props.originProductData?.prod?.ext?.basic &&
      Object.values(this.props.originProductData?.prod?.ext?.basic)[0]
        ?.platform_type === 2
    ) {
      return null;
    }
    if (this.product_pool[0]?.platform_type === 4) {
      this.product_pool?.forEach((item) => {
        this.props.originProductData?.prod?.ext?.basic &&
          this.props.originProductData?.prod?.ext?.basic[
            item?.product_id_str
          ]?.supply?.forEach((supply) => {
            if (
              this.ae_detail_map[
                item?.product_id_str
              ]?.aeop_ae_product_s_k_us?.aeop_ae_product_sku?.findIndex(
                (i) => i.sku_id === supply.supplier_sku_id
              ) < 0
            ) {
              flag = true;
            }
          });
      });
    }

    const paramObj = {
      url: `https://www.dsers.com/app/tmallproduct/${this.product_pool[0]?.product_id_str}?appId=159831084`,
      ref: 'find_suppliers',
      supplierType: 'tmall',
    };
    const paramStr = qs.stringify(paramObj);
    const link = `/app/supplier_optimizer?${paramStr}`;

    if (flag) {
      return (
        <div className={styles.imgNotice}>
          <i className="material-icons notranslate">error</i>
          <span>{intl.getHTML('order.tmall_sku2_error', { url: link })}</span>
        </div>
      );
    }
    return null;
  };

  // 渲染在shopify被删除的商品用于展示
  delVarianting = (data, default_length, type) => {
    const getCollapseStatus = () => {
      if (this.props.checkVariantStatus === true) {
        return ['1'];
      }
      if (this.props.checkVariantStatus === false) {
        return [];
      }
      if (data.length > 4) {
        return [];
      }
      return ['1'];
    };
    // console.log(data,default_length, type,'-----------type----------');
    // 从订单页打开mapping 禁用删除variant按钮
    const {
      originProductData: { deleteVariantButtonDisabled, orderVaraintId } = {},
    } = this.props;

    if (data.length !== 0) {
      return (
        <div style={{ marginTop: this.getMarginTop() }}>
          <div
            className={styles.deletedVariantCon}
            style={{
              width: this.state.second_options.length ? '300%' : '200%',
            }}
          >
            <Collapse
              activeKey={getCollapseStatus()}
              onChange={this.checkDeleteVariants}
              bordered={false}
              expandIconPosition="left"
            >
              <Panel
                header={
                  <div className={styles.description}>
                    <span>Variants deleted at Shopify</span>
                    <Tooltip
                      title={intl.getHTML(
                        'mapping.delete_variant_collapse_supplier_tip'
                      )}
                    >
                      <img src={noticeIcon} alt="" />
                    </Tooltip>
                  </div>
                }
                key="1"
                extra={
                  <i
                    className="material-icons"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.setState({
                        isDeleteAll: true,
                        visibleDeletedAllVariant: true,
                      });
                    }}
                  >
                    delete
                  </i>
                }
              >
                <div>
                  {data.map((item) => {
                    const isOrderVaraint = item.variant_id === orderVaraintId;
                    return (
                      <div
                        className={`${styles.removeItem} ${isOrderVaraint ? styles.orderVariant : ''
                          }`}
                        key={item.variant_id}
                      >
                        <div className={styles.removeVariant}>
                          <span title={item.variant_title}>
                            <Ellipsis lines={1}>
                              {item.variant_title || 'shopify deleted variant'}
                            </Ellipsis>
                          </span>
                          <span className={styles.deletedIcon}>Deleted</span>
                          {!deleteVariantButtonDisabled ? (
                            <i
                              style={{
                                position: 'absolute',
                                left: '270px',
                                top: '14px',
                              }}
                              className="material-icons"
                              onClick={() =>
                                this.deleteVariant(item.variant_id, type)
                              }
                            >
                              delete
                            </i>
                          ) : null}
                        </div>
                        {default_length > 0 ? (
                          item.sku_image || item.sku ? (
                            <div className="tag_vImagebox">
                              <div
                                onClick={() => {
                                  this.showImportSupply(item);
                                }}
                              >
                                <Popover
                                  placement="left"
                                  trigger="hover"
                                  content={
                                    <img
                                      src={replaceUrlToHttps(
                                        item.sku_image ||
                                        this.props?.originProductData.image
                                      )}
                                    />
                                  }
                                  overlayClassName={styles.zoomProductImg}
                                >
                                  <img
                                    src={replaceUrlToHttps(
                                      item.sku_image ||
                                      this.props?.originProductData.image
                                    )}
                                    className={styles.skuImg}
                                  />
                                </Popover>
                                <span className="yexy_text">
                                  {item.variant_title}
                                </span>
                              </div>
                              <span
                                className="consw_icon"
                                onClick={() =>
                                  this.deleteVariantSp(item.variant_id)
                                }
                              >
                                <i className="material-icons">close</i>
                              </span>
                            </div>
                          ) : (
                            <div
                              className={className(styles.removeImport, {
                                [styles.removeImportBigger]:
                                  this.product_pool.length == 2,
                                [styles.removeImportBiggest]:
                                  this.product_pool.length == 0,
                              })}
                            >
                              <Button
                                size="small"
                                onClick={() => {
                                  this.showImportSupply(item);

                                  const custom_info = [
                                    { name: 'mapping type', value: 'Basic' },
                                  ];

                                  window.DSERS_CUSTOM_COLLECT({
                                    module: 'product mapping',
                                    action: 'click supplier button',
                                    custom_info,
                                  });
                                }}
                              >
                                {intl.get('mapping.import_sp_select_var')}
                              </Button>
                            </div>
                          )
                        ) : null}
                      </div>
                    );
                  })}
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
      );
    }
    return null;
  };

  // originProductData  所要数据的字段名称
  render() {
    const {
      originProductData,
      copyLink,
      saveLoading,
      getAliProductLoading,
      hideSupplier,
      publicBtnDispath,
      switchoverMapping,
      isClickSaveBtn,
    } = this.props;

    const {
      options,
      default_options,
      default_category_values,
      default_options_values,
      second_options,
      second_category_values,
      second_options_values,
      product_pool,
      showOptionPush,
      showSave,
      removedVariant,
      showImport,
      orderVariantOptionsIndex,
      mapping_url,
      setToStatus,
      repeatTip,
      canReplace,
      showDeletePoolProduct,
      showDeleteSupplierProduct,
      selectValue,
      isNewAuth,
      isTwo,
      isStatus,
    } = this.state;
    const loading = saveLoading || getAliProductLoading;
    const isFBD = this.getIsFBD();

    this.getMarginTop();

    return (
      <>
        <div className={styles.mappingSatusLine} id="basicSwitchStatus">
          <div className={styles.skuChangeTips}>{this.tmallSkuChangeTip()}</div>
          <Status
            Mapping
            loading={saveLoading || getAliProductLoading}
            self_type={1} // base mapping的值
            map_type={this.map_type} // 当前的值
            changeStatus={this.changeStatus}
            isFBD={isFBD}
            isStatus={isStatus}
            isClickSaveBtn={isClickSaveBtn}
            onRef={this.onBasicChild}
            btnDispath={publicBtnDispath}
            switchoverMapping={switchoverMapping}
            switchoverMappingId="basicSwitchStatus"
          />
          <div className={styles.productsImpout}>
            <p className={styles.categoryNc}>
              <span>{intl.get('mapping.alternate_products')}</span>
            </p>
            <Popover
              arrowPointAtCenter
              placement="bottom"
              overlayClassName={styles.popoverContent}
              // content={'Enter the supplier link you found here and then click Import.'} 
              content={(
                <div>
                  <img src={require("../../assets/mapping/1.gif")} />
                  <span>Enter the supplier link you found here and then click Import.</span>
                </div>
              )}
              visible={isNewAuth.one && JSON.parse(localStorage.getItem('store'))?.import_flag == 0}
              destroyTooltipOnHide
              getPopupContainer={trigger => trigger.parentNode}
            >
              <div className={className(styles.Baseimport, {
                [styles.BaseimportAmi]: isNewAuth.one && JSON.parse(localStorage.getItem('store'))?.import_flag == 0
              })} data-label="mapping-input">
                {/* <Input
                placeholder={intl.get('mapping.import_placeholder')}
                onChange={e => {
                  this.setState({
                    mapping_url: e.target.value.trim()
                  });
                }}
                allowClear
                value={mapping_url}
                disabled={isFBD}
              />
              <span
                className={styles.search}
                onClick={this.importAeProductOptions}
              >
                {intl.get('mapping.import')}
              </span> */}
                <SearchInput
                  onChange={(e) => {
                    this.setState({
                      mapping_url: e?.target?.value?.trim(),
                    });
                  }}
                  getPlatform={(v) => {
                    this.setState({
                      selectValue: v,
                    });
                  }}
                  defaultSelectPlatform={this.state.defaultSelectValue}
                  placeholder={
                    selectValue === 'AliExpress'
                      ? intl.get('mapping.import_ae_title')
                      : intl.get('mapping.import_tm_title')
                  }
                  hiddenSelect={!window.TMALL_USER}
                  value={mapping_url}
                  onSubmit={this.importAeProductOptions}
                  selfSearchIcon={intl.get('mapping.import')}
                  onlySearch
                  loading={this.state.importProductBtnLoading}
                  checkUrl={'mappingSearch'}
                />
              </div>
            </Popover>
          </div>
          {/* {showSave ? (
            <div className={styles.mappingSave}>
              <Button
                loading={saveLoading}
                data="dx"
                type="primary"
                onClick={() => this.handleSave('fromSaveButton')}
                data-label="mapping-submit"
              >
                {intl.get('public.save')}
              </Button>
            </div>
          ) : null} */}
        </div>
        <div className={styles.basic}>
          {loading ? (
            <div className={styles.basicLoading}>
              <Spin />
            </div>
          ) : null}
          <div className={styles.wrap}>
            <div className={styles.mapping}>
              <p className={styles.category}>
                <span>{intl.get('mapping.shopify_product')}</span>
                {this.product_pool[0] ? (
                  <span>
                    {this.product_pool[0]?.platform_type === 4
                      ? intl.get('mapping.default_ali_product', {
                        platform: 'Tmall',
                      })
                      : intl.get('mapping.default_ali_product', {
                        platform: 'Ali',
                      })}
                  </span>
                ) : null}
                {this.product_pool[1] ? (
                  <span>
                    {this.product_pool[0]?.platform_type === 4
                      ? intl.get('mapping.substitute_ali_product', {
                        platform: 'Tmall',
                      })
                      : intl.get('mapping.substitute_ali_product', {
                        platform: 'Ali',
                      })}
                  </span>
                ) : null}
              </p>
              <div className={styles.productLine}>
                <Product prod={originProductData} copyLink={copyLink} />
                {this.product_pool?.map((item, i) => {
                  if (!item) return;
                  return (
                    <AeProduct
                      prod={item}
                      type="ae"
                      key={`${item.id}${i}`}
                      deleteMapping={this.deleteMapping}
                      index={i}
                      isFBD={isFBD}
                      onMoreActionChange={this.handleMoreActionChange}
                    />
                  );
                })}
                {this.product_pool.length === 2 && (
                  <Tooltip title={intl.get('mapping.switch_mapping')}>
                    <div
                      className={
                        canReplace ? styles.repeatBasic : styles.disrepeatBasic
                      }
                      onClick={this.switchBasic}
                    >
                      <i className="material-icons">repeat</i>
                    </div>
                  </Tooltip>
                )}
              </div>
              {/* style={{ height: 'calc( 100vh - 385px )' }} */}
              <div className={styles.optionsWrap}>
                <div className={styles.options}>
                  <div
                    className={className(styles.shopifyOption, {
                      [styles.shopifyOptionNoBorder]:
                        default_options.length == 0 &&
                        second_options.length == 0,
                    })}
                  >
                    {options.map((item, optionIndex) => {
                      return (
                        <div key={item.name} className={styles.baseicDiv}>
                          <h3>{item.name}</h3>
                          <ul>
                            {item.values.map((v, vIndex) => {
                              const isOrderVariantOption =
                                orderVariantOptionsIndex[optionIndex] ===
                                vIndex;
                              return (
                                <li
                                  className={`${isOrderVariantOption
                                      ? styles.orderVariantOption
                                      : ''
                                    }`}
                                  key={v}
                                  title={v}
                                >
                                  {v}
                                  {(default_options_values[optionIndex]
                                    ?.length &&
                                    default_options_values[optionIndex][
                                    vIndex
                                    ] === 'SKU_CHANGED') ||
                                    (second_options_values[optionIndex]?.length &&
                                      second_options_values[optionIndex][
                                      vIndex
                                      ] === 'SKU_CHANGED') ? (
                                    <Tooltip
                                      title={intl.get('mapping.sku_lose_tip')}
                                    >
                                      <i>
                                        <i className="material-icons">
                                          error_outline
                                        </i>
                                      </i>
                                    </Tooltip>
                                  ) : (
                                    ''
                                  )}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      );
                    })}
                    {this.delVarianting(removedVariant, default_options.length)}
                  </div>
                  {default_options.length > 0 ? (
                    <Popover
                      placement="top"
                      overlayClassName={className([styles.popoverContent, styles.popoverContentFixed])}
                      // content={'Choose the option that corresponds to the left side, then select the corresponding variant in order to complete the mapping of the product.'} 
                      content={<div>
                        <img src={require("../../assets/mapping/3.gif")} />
                        <span>Choose the option that corresponds to the left side, then select the corresponding variant in order to complete the mapping of the product.</span>
                      </div>}
                      visible={isNewAuth.three && JSON.parse(localStorage.getItem('store'))?.import_flag == 0 && default_options.length > 0 && second_options.length == 0}
                      destroyTooltipOnHide
                      getPopupContainer={trigger => trigger.parentNode}
                    >
                      <div
                        className={className(styles.supplierOption, {
                          [styles.supplierOptionAmi]: isNewAuth.three && JSON.parse(localStorage.getItem('store'))?.import_flag == 0 && default_options.length > 0 && second_options.length == 0
                        })}
                        data-label="mapping-default-option-item"
                      >
                        {default_options.map((item, i) => {
                          return (
                            <SkuSelect
                              index={i}
                              default_options_values={default_options_values}
                              default_options={default_options}
                              key={item.sku_property_id}
                              default_category_values={default_category_values}
                              type="default"
                              isAmi={isNewAuth.three}
                              changeCategory={this.changeCategory}
                              changeSku={this.changeSku}
                              originOption={options}
                              isFBD={isFBD}
                              skuMap={this.sku_map.default}
                            />
                          );
                        })}
                      </div>
                    </Popover>
                  ) : (
                    <div className={className(styles.supplierOption)} />
                  )}

                  {/* 下架商品补全mapping样式  上层判断 否 可能有问题 */}
                  {this.state.showOptions ? (
                    <div className={styles.shopifyOption} />
                  ) : null}

                  {second_options.length > 0 ? (
                    <Popover
                      placement="top"
                      destroyTooltipOnHide
                      getPopupContainer={trigger => trigger.parentNode}
                      overlayClassName={className([styles.popoverContent, styles.popoverContentFixed])}
                      //  content={'Choose the option that corresponds to the left side, then select the corresponding variant in order to complete the mapping of the product.'}
                      content={<div>
                        <img src={require("../../assets/mapping/3.gif")} />
                        <span>Choose the option that corresponds to the left side, then select the corresponding variant in order to complete the mapping of the product.</span>
                      </div>}
                      visible={isNewAuth.three && JSON.parse(localStorage.getItem('store'))?.import_flag == 0 && second_options.length > 0}
                    >

                      <div className={className(styles.supplierOption, {
                        [styles.supplierOptionAmi]: isNewAuth.three && JSON.parse(localStorage.getItem('store'))?.import_flag == 0 && second_options.length > 0
                      })}>
                        {second_options.map((item, i) => {
                          return (
                            <SkuSelect
                              index={i}
                              default_options_values={second_options_values}
                              default_options={second_options}
                              key={item.sku_property_id}
                              default_category_values={second_category_values}
                              type="second"
                              isAmi={isNewAuth.three}
                              changeCategory={this.changeCategory}
                              changeSku={this.changeSku}
                              originOption={options}
                              isFBD={isFBD}
                              skuMap={this.sku_map.second}
                            />
                          );
                        })}
                      </div>
                    </Popover>
                  ) : null}
                </div>
              </div>
            </div>
            {/* 最右侧的item */}
            {product_pool.length ? (
              <div className={styles.alternate}>
                <p className={styles.categoryNcK}>
                  <span>{intl.get('mapping.alternate_products1')}</span>
                </p>
                <ul className={styles.products}>
                  {product_pool.map((item, i) => {
                    return (
                      <Pool
                        index={i}
                        isAmi={isNewAuth}
                        isTwo={isTwo}
                        prod={item}
                        key={`p${i}`}
                        product_pool={product_pool}
                        product_pool_ids={this.product_pool_ids}
                        showOptionPush={this.showOptionPush}
                        deleteGoods={this.deleteGoods}
                        isFBD={isFBD}
                        onMoreActionChange={this.handleMoreActionChange}
                      />
                    );
                  })}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
        {showImport && (
          <ImportAliExpressMapping
            visible={showImport}
            from="basic"
            hideImportSupply={this.hideImportSupply}
            importCallback={this.importCallback}
            importProductId={this.importProductId}
            shopifyProductId={this.shopifyProductId}
            storeId={originProductData.prod.store_id}
            selectData={this.state.select}
            sp={this.sp}
          />
        )}
        <Modal
          visible={showDeletePoolProduct}
          title={intl.getHTML('mapping.delete_supplier_title')}
          className={styles.deleteTipModal}
          footer={
            <>
              <Button
                className={styles.btn}
                onClick={this.confirmDeletePoolProduct}
              >
                {intl.getHTML('public.newconfirm')}
              </Button>
            </>
          }
          onCancel={() => {
            this.setState({
              showDeletePoolProduct: false,
              showDeletePoolProductId: '',
            });
          }}
        >
          <div>{intl.getHTML('mapping.delete_supplier_description')}</div>
        </Modal>
        <Modal
          visible={showDeleteSupplierProduct}
          title={intl.getHTML('mapping.delete_options_title')}
          className={styles.deleteTipModal}
          footer={
            <>
              <Button
                className={styles.btn}
                onClick={() => {
                  this.deleteMappingConfirm(
                    this.state.showDeleteSupplierProductId
                  );
                  this.setState({
                    showDeleteSupplierProduct: false,
                  });
                }}
              >
                {intl.getHTML('public.newconfirm')}
              </Button>
            </>
          }
          onCancel={() => {
            this.setState({
              showDeleteSupplierProduct: false,
              showDeleteSupplierProductId: 0,
            });
          }}
        >
          <div>
            {intl.get('mapping.delete_mapping_product', {
              num: this.state.showDeleteSupplierProductId,
            })}
          </div>
        </Modal>
        <Modal
          visible={this.state.visibleDeletedAllVariant}
          width={560}
          title={intl.getHTML('mapping.confirm_delete_all_variant_title')}
          className={styles.deleteTipModal}
          onCancel={() => {
            this.setState({
              visibleDeletedAllVariant: false,
            });
          }}
          footer={
            <>
              <Button
                className={styles.btn}
                onClick={
                  this.state.isDeleteAll
                    ? this.deleteAllDelVariant
                    : this.deleteVariantTrue
                }
              >
                {intl.getHTML('public.newconfirm')}
              </Button>
            </>
          }
        >
          <div>{intl.getHTML('mapping.confirm_delete_all_variant')}</div>
        </Modal>
        {/* 副供应商商品池渲染option弹窗 */}
        {showOptionPush ? (
          <Modal
            title={intl.get('mapping.product_whic')}
            visible={showOptionPush}
            onCancel={this.hideOptionPush}
            className={styles.showOptionPush}
            width={560}
            footer={
              <div className={styles.showOptionPushBtn}>
                <Button
                  type="primary"
                  data="dx"
                  disabled={setToStatus}
                  data-label="mapping-set-confirm-ok"
                  className={styles.bbtn}
                  onClick={() => {
                    this.subSetTo();
                  }}
                >
                  {intl.get('public.newconfirm')}
                </Button>
                {/* <span onClick={this.hideOptionPush}>
              {intl.get('public.cancel')}
            </span> */}
              </div>
            }
            maskClosable={false}
            keyboard={false}
            destroyOnClose
          >
            {repeatTip && (
              <InfoAlert
                text={intl.get('mapping.repeat_alert')}
                className={styles.repeatAlert}
              />
            )}
            {this.state.clearProductPoolTip && (
              <InfoAlert
                text={intl.get('mapping.clear_tip_alert')}
                className={styles.clearProductPoolTip}
              />
            )}
            {this.state.forbiddenTip && (
              <InfoAlert
                text={intl.get('mapping.forbidden_alert')}
                className={styles.forbidden}
              />
            )}
            <RadioGroup onChange={this.RadioValueChange} defaultValue={1}>
              <Radio value={1}>{intl.get('mapping.default_option')}</Radio>
              <Radio value={2} disabled={default_category_values.length == 0}>
                {intl.get('mapping.second_option')}
              </Radio>
            </RadioGroup>
          </Modal>
        ) : null}
      </>
    );
  }
}

// 下拉框
function SkuSelect(props) {
  const {
    index, // 第几个分类 color还是szie
    default_options,
    default_options_values, // 分类下面的下拉选中的值 [['14:771#Coffee', ...],['5:100014064', ...]] 对应的是每个属性的id 例如 Coffee是14:771#Coffee  S是5:100014064
    default_category_values, // 分类的值 ['14', ...] 例如color的值是14
    changeCategory, // 更改分类
    changeSku, // 更改sku
    type, // 主副供应商
    isAmi,//是否显示动画效果
    originOption, // shopify的options
    isFBD, // 是不是dsers供应商
    skuMap,
  } = props;

  /*
      default_options
      [
        {
          name: "Color",
          position: 1,
          sku_property_id: 14,
          values: [
            {category: "Color" id: "14:771#Coffee", img: ".jpg", position: 1, sku_property_id: 14, value: "Coffee"},
            ...
          ]
        },
        {
          name: "size",
          ...
        }
      ]
  */

  const aeOnlyOptionPosition = getAEOnlyOptionPosition(originOption, skuMap);
  let skuValues = [1]; // sku都有哪些选项

  default_options.forEach((opt) => {
    if (opt.sku_property_id == default_category_values[index]) {
      skuValues = opt.values;
    }
  });

  let shopify_option_Values = originOption[index]
    ? originOption[index].values
    : [{ value: 'aa' }]; // 用shopify的分类下面的值 渲染ae分类下面的的值应该渲染多少个 例如 shopify size [s,m,L,XL] 对应的ae也要有4个下拉框来对应。如果ae分类大于shopify分类只渲染一个

  // 例如 shopify有2个分类 顺序是size color ae只有一个color
  if (aeOnlyOptionPosition !== 0) {
    shopify_option_Values = originOption[aeOnlyOptionPosition].values || [
      { value: 'aa' },
    ];
  }

  console.log(
    '🌞🌞🌞',
    default_options,
    default_category_values,
    skuValues,
    default_options_values
  );

  /**
   *
   *
   *
   *  default_category_values ['14','5']  对应 default_options 中 sku_property_id 所以 Select 能选中
   *  default_options_values [['14:691'],['5:100014065']] 对应 skuValues 当中的id 所以ul里的 Select 能选中
   *
   *  所以只需要知道
   *
   *    shopify_option_Values 暂时不知道干什么的
   *
   *    default_options
   *        --- 渲染mappding有几个 options 例如[color,size,Ships From]等
   *    default_category_values
   *        --- 主要是用来选中 default_options 的值
   *    skuValues
   *        --- 主要是用来渲染 ul li Select 下拉的值
   *    default_options_values
   *        --- 主要是用来选中 skuValues的值
   *
   *
   *  的值如何来的即可！
   *
   *
   *
   */

  return (
    <>
      {/* mapping第一层 */}

      <div className={styles.optionCategory}>
        <Select
          disabled={isFBD} // dsers供应商不让改
          size="small"
          placeholder="Select Option"
          value={default_category_values[index]}
          onChange={(v) => {
            changeCategory(v, index, type);
          }}
          getPopupContainer={(triggerNode) => triggerNode.parentNode}
          suffixIcon={
            <img
              className="clasSelImg"
              src={require('../../assets/newImportList/icon_expand_more-24px.png')}
            />
          }
        >
          {default_options.map((category) => {
            return (
              <Option key={category.sku_property_id}>{category.name}</Option>
            );
          })}
        </Select>
        {isFBD ? (
          <a className={styles.fbd} href="/app/fulfillment-center">
            FBD
          </a>
        ) : null}
      </div>
      <ul >
        {shopify_option_Values.map((option, i) => {
          return (
            <li key={option}>
              <Select
                allowClear
                placeholder="Select Variant"
                disabled={isFBD} // dsers供应商不让改
                size="small"
                data-label={`mapping-select-option-item ${option}`}
                dropdownClassName={styles.dropdownSkuSelect}
                notFoundContent={<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Please select an option first" />}
                value={
                  default_options_values[index][i] === 'SKU_CHANGED'
                    ? 'Select Variant'
                    : default_options_values[index][i]
                }
                onChange={(v) => {
                  changeSku(v, index, type, i);
                }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                suffixIcon={
                  <img
                    className="clasSelImg"
                    src={require('../../assets/newImportList/icon_expand_more-24px.png')}
                  />
                }
              >
                {skuValues.map((v) => {
                  if (v === 1) {
                    return null;
                  }
                  return (
                    <Option key={v.id}>
                      {v.img ? (
                        <Popover
                          placement="left"
                          trigger="hover"
                          content={
                            <img
                              src={replaceUrlToHttps(v.img)}
                              style={popoverImg}
                            />
                          }
                        >
                          <img
                            className={styles.sku_image}
                            src={replaceUrlToHttps(v.img)}
                          />
                        </Popover>
                      ) : null}
                      <span className={v.img ? null : styles.sku_value}>
                        {v.value}
                      </span>
                    </Option>
                  );
                })}
              </Select>
            </li>
          );
        })}
      </ul>
    </>
  );
}

// 商品池
function Pool(props) {
  const {
    index,
    prod,
    product_pool,
    product_pool_ids,
    showOptionPush,
    deleteGoods,
    isFBD,
    isAmi,
    isTwo,
    onMoreActionChange,
  } = props;

  // const {isTwo} = this.state

  const is_default = prod?.product_id_str == product_pool_ids[0];
  const products_dtag = (event) => {
    if (event.target.nodeName.trim() !== 'I') return;
    switch (event.target.innerText) {
      case 'build':
        window.dtag.set({
          event: 'click',
          module: 'mapping',
          action:
            'clicked_on_the_supplier_settings_button_for_a_product_in_the_product_pool',
        });
        break;
      case 'local_shipping':
        window.dtag.set({
          event: 'click',
          module: 'mapping',
          action:
            'clicked_on_the_logistics_settings_button_for_a_product_in_the_product_pool',
        });
        break;
      case 'delete':
        window.dtag.set({
          event: 'click',
          module: 'mapping',
          action:
            'clicked_on_the_delete_button_for_a_product_in_the_product_pool',
        });
        break;
    }
  };
  return (
    <Popover
      placement="left"
      overlayClassName={styles.popoverContent}
      // content={'Click on "Set" to set this product as your supplier.'}
      content={(
        <div>
          <img src={require("../../assets/mapping/2.gif")} />
          <span>Click on "Set" to set this product as your supplier.</span>
        </div>
      )}
      destroyTooltipOnHide
      // getPopupContainer={trigger => trigger}
      getPopupContainer={trigger => trigger.parentNode}
      visible={isAmi.two && JSON.parse(localStorage.getItem('store'))?.import_flag == 0 && product_pool.length === index + 1 && !isTwo}
    >
      <li key={prod.product_id} className={className({
        [styles.productsAmi]: isAmi.two && JSON.parse(localStorage.getItem('store'))?.import_flag == 0 && product_pool.length === index + 1 && !isTwo
      })} data-label="mapping-list-import-item">
        {/* <p className={styles.index}>{index + 1}</p> */}
        <Popover
          placement="left"
          trigger="hover"
          content={
            <img src={replaceUrlToHttps(prod.image_src)} style={popoverImg} />
          }
        >
          <img className={styles.pic} src={replaceUrlToHttps(prod.image_src)} />
        </Popover>
        <div className={styles.info}>
          <a
            href={getSupplierUrl(prod?.platform_type, prod?.product_id_str)}
            target="_blank"
            rel="noreferrer"
          >
            {prod.title}
          </a>

          <p
            className={`${styles.mapped} ${product_pool_ids.indexOf(prod?.product_id_str) < 0
                ? styles.notMapped
                : ''
              }`}
          >
            {product_pool_ids.indexOf(prod?.product_id_str) >= 0
              ? product_pool_ids.indexOf(prod?.product_id_str) === 0
                ? intl.get('mapping.default')
                : intl.get('mapping.mapped')
              : intl.get('mapping.to_be_set')}
          </p>

          <div className="cazou" onClick={products_dtag}>
            <Tooltip title="Set up default or second supplier option">
              <span onClick={() => showOptionPush(prod)} className={styles.setUp}>
                <i className="material-icons notranslate" style={{ color: isAmi.two && JSON.parse(localStorage.getItem('store'))?.import_flag == 0 && product_pool.length === index + 1 && !isTwo ? '#ff8f00' : 'rgba(0,0,0,.6)' }}>build</i>
                <span>Set</span>
              </span>
            </Tooltip>
            <div className="cazou_right_box">
              <Tooltip title="Set up shipping method">
                <div className={styles.moreAction}>
                  <MoreAction onChange={(e) => onMoreActionChange(e, prod)} />
                </div>
              </Tooltip>
              <Tooltip title="Delete">
                <span onClick={() => deleteGoods(prod?.product_id_str)}>
                  {!is_default || !isFBD ? (
                    <i className="material-icons notranslate">delete</i>
                  ) : null}
                </span>
              </Tooltip>
            </div>
          </div>

          {/* <Button
          disabled={isFBD}
          type="primary"
          onClick={() => showOptionPush(prod)}
        >
          {intl.get('mapping.set')}
        </Button> */}
        </div>
        {/* {!is_default || !isFBD ? (
        <Icon
          type="close"
          className={styles.deleteSupply}
          onClick={() => deleteGoods(prod.product_id)}
        />
      ) : null} */}
      </li>
    </Popover>
  );
}

function Product(props) {
  const { prod, copyLink } = props;
  const useSupplierStyle = !prod.image.includes('https:')
    ? 'not-allowed'
    : 'pointer';
  const openSupplyOptimizer = (prod, type) => {
    if (type) {
      return;
    }
    if (!prod?.prod?.suppliers?.length && prod?.prod?.supplier_type === 2) {
      window.dtag.set({
        event: 'click',
        module: 'Aliexpress',
        action: 'Aliexpress_figure_search',
        custom_data: {
          click_type: 'go_supplier_optimizer_mapping',
        },
      });
      CommonDataCollect({
        action: 'MP-0xx-Shopify product',
        event_type: 'MP_0xx_Shopify_product_list',
      });
      const paramObj = {
        url: prod.image,
        ref: 'my_product',
        id: prod.ae_pid,
        store_id: localStorage.getItem('storeId'),
      };
      const paramStr = qs.stringify(paramObj);
      const link = `/app/supplier_optimizer?${paramStr}`;
      window.open(link);
      return;
    }
    if (
      (!prod?.prod?.suppliers?.length && window.TMALL_USER) ||
      (prod?.prod?.suppliers?.includes(1) && window.TMALL_USER)
    ) {
      window.dtag.set({
        event: 'click',
        module: 'Aliexpress',
        action: 'Aliexpress_figure_search',
        custom_data: {
          click_type: 'go_supplier_optimizer_mapping',
        },
      });
      const paramObjTmall = {
        url: prod.image,
        ref: 'import_list',
        supplierType: 'unmap',
        store_id: localStorage.getItem('storeId'),
      };
      const paramStrTmall = qs.stringify(paramObjTmall);
      window.open(`/app/supplier_optimizer?${paramStrTmall}`);
      return;
    }
    if (prod?.prod?.suppliers?.includes(4)) {
      window.dtag.set({
        event: 'click',
        module: 'tmall',
        action: 'tmall_figure_search',
        custom_data: {
          click_type: 'go_supplier_optimizer_mapping',
        },
      });
      const paramObjTmall = {
        url: `https://www.dsers.com/app/tmallproduct/${getAePid(
          prod.prod
        )}?appId=159831084`,
        ref: 'import_list',
        supplierType: 'tmall',
        store_id: localStorage.getItem('storeId'),
      };
      const paramStrTmall = qs.stringify(paramObjTmall);
      window.open(`/app/supplier_optimizer?${paramStrTmall}`);
      return;
    }
    window.dtag.set({
      event: 'click',
      module: 'Aliexpress',
      action: 'Aliexpress_figure_search',
      custom_data: {
        click_type: 'go_supplier_optimizer_mapping',
      },
    });

    const paramObj = {
      url: prod.image,
      ref: 'my_product',
      id: prod.ae_pid,
      store_id: localStorage.getItem('storeId'),
    };
    const paramStr = qs.stringify(paramObj);
    const link = `/app/supplier_optimizer?${paramStr}`;
    window.open(link);
  };
  return (
    <div className={styles.product}>
      <Popover
        overlayClassName={styles.popover}
        placement="left"
        trigger="hover"
        content={<img src={replaceUrlToHttps(prod.image)} style={popoverImg} />}
      >
        {prod.prod?.ext?.map_type === 6 ? (
          <img src={replaceUrlToHttps(prod.image)} />
        ) : (
          <span className={styles.shopifyPic}>
            <img src={replaceUrlToHttps(prod.image)} />
            <Tooltip title={intl.get('mapping.Findsimilar')}>
              <span
                className={styles.findSupplier}
                style={{ cursor: useSupplierStyle }}
                onClick={() =>
                  openSupplyOptimizer(prod, !prod.image.includes('https:'))
                }
              >
                <i
                  className="material-icons"
                  style={{ cursor: useSupplierStyle }}
                >
                  search
                </i>
              </span>
            </Tooltip>
          </span>
        )}
      </Popover>
      <div className={styles.title}>
        <a
          role="button"
          tab-index="0"
          className={styles.link}
          onClick={() => {
            copyLink(prod);
          }}
        >
          <Ellipsis lines={4}>{prod.title}</Ellipsis>
        </a>
      </div>
    </div>
  );
}

function AeProduct(props) {
  const { prod, index, deleteMapping, isFBD, onMoreActionChange } = props;

  return (
    <div className={styles.product}>
      <Popover
        overlayClassName={styles.popover}
        placement="left"
        trigger="hover"
        content={
          <img src={replaceUrlToHttps(prod.image_src)} style={popoverImg} />
        }
      >
        <img src={replaceUrlToHttps(prod.image_src)} />
      </Popover>
      <div className={styles.title}>
        <a
          href={getSupplierUrl(prod?.platform_type, prod?.product_id_str)}
          target="_blank"
          className={styles.link}
          rel="noreferrer"
        >
          <Ellipsis lines={4}>{prod.title}</Ellipsis>
        </a>
      </div>
      {!isFBD ? (
        <span className="clione">
          <i className="material-icons" onClick={(e) => deleteMapping(index)}>
            close
          </i>
        </span>
      ) : null}
    </div>
  );
}
