import React from 'react';

import styles from './UserMessage.less';

const BeginnerGuide = ({ children, direction = 'left', style, guideColor }) => {
  return (
    <div
      className={`${styles.msg} ${direction === 'right' ? styles.right : ''} ${(direction === 'right' && guideColor) && styles.cover}`}
      style={{ background: (direction === 'right' && guideColor) ? '#FF8F00' : '' }}
    >
      <div className={`${styles.content} ${(direction === 'right' && guideColor) && styles.click} `} style={style}>
        {children}
      </div>
    </div>
  );
};

export default BeginnerGuide;
