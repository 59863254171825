import React from 'react';
import PropTypes from 'prop-types';
import { Layout, notification, Modal, Spin, Button } from 'antd';
import DocumentTitle from 'react-document-title';
import { connect } from 'dva';
import { Route, Redirect, Switch, routerRedux } from 'dva/router';
import { ContainerQuery } from 'react-container-query';
import classNames from 'classnames';
import ReactGA from 'react-ga';
import Media from 'react-media';
import Cookies from 'js-cookie';

import OldToNewModal from 'components/OldToNewModal/OldToNewModal';
import OldUiOutModal from 'components/OldUiOut/OldUiOutModal';
import { getStoreId } from 'utils/getUserInfo';
import ResetPasswordModal from 'routes/User/ResetPasswordModal';
import getAdminRole from 'utils/getAdminRole';
// import BannerV2 from 'components/AdBanner/BannerV2';
// import Banner914 from 'components/AdBanner/Banner914';
import RewardSeason from 'components/OnlineNotice/RewardSeason';
// import RewardSeasonBanner from 'components/Banner/RewardSeason';
import AliShipping from 'components/OnlineNotice/AliShipping';
import Anniversary from 'components/OnlineNotice/Anniversary';
import SwitchBanner from 'components/OnlineNotice/SwitchBanner';
import AliShippingBanner from 'components/Banner/AliShippingBanner';
import RutBanner from '../routes/Order/Order';
// import ChileRutNumberBanner from 'components/Banner/ChileRutNumber';
// import FreePeriodOverBanner from 'components/Banner/FreePeriodOver';
import IossBanner from 'components/Banner/IossBanner';
import IossNotice from 'components/OnlineNotice/IossNotice';
import BrazilBanner from 'components/Banner/BrazilBanner';
import ShipCost from 'components/OnlineNotice/ShipCost';
import BrazilNotice from 'components/OnlineNotice/BrazilNotice';
import BannerComment from 'components/OnlineNotice/BannerComment';
import CommentBanner from 'components/Banner/CommentBanner';
// import BargainNoticeBanner from '../routes/Bargain/component/NoticeBanner';
import ShopMasterBanner from 'components/Banner/ShopMasterBanner';
import PaypalPastDue from 'components/OnlineNotice/PaypalPastDue';
import SideAdContainer from 'components/SideSupplyChainAd/SideAdContainer';
import connectExtension from 'common/connectExtension';
import ExtraHeader from 'components/ExtraHeader';
import AdmitadModal from 'routes/Setting/component/AdmitadModal';
import Ad from 'components/Ad';
import ShopPush from 'components/ShopPush';
import NoticePlanBanner from 'components/NoticePlanBanner';
import OneClickMapping from 'routes/Bargain/component/OneClickMapping';
import SupplyNotice from 'routes/Setting/Dropshpper/Common/Supply/SupplyNotice';
import ReportNoticeOnline from 'routes/Report/component/NoticeOnline';
import SupplierOptmizerOnline from 'components/OnlineNotice/SupplierOptimizer';
import CheckBigUser from 'components/CheckBigUser/index';
import AgencyColdStartUpBanner from 'components/Banner/AgencyColdStartupBanner';
import TmallColdStartShow from 'components/OnlineNotice/TmallColdStartShow';
import TmallColdStartBanner from 'components/OnlineNotice/TmallColdStartBanner';
import {
  checkUserForInstallExt,
  getUserInstalledExt
} from 'common/checkUserForInstallExt';
import OptimizerInfoTip from 'components/Banner/OptimizerInfoTip';
import ADTopBannerContainer from 'components/Banner/ADTopBannerContainer';
import SupplierADBanner from 'components/Banner/SupplierADBanner';
import NewUIBanner from 'components/Banner/NewUIBanner';
import V5Banner from 'components/Banner/V5Banner';
import NewListBanner from 'components/Banner/NewListBanner';
import GuideSchedule from '../routes/BeginnerGuide/Schedule';
import NewShipOptions from 'components/OnlineNotice/NewShipOption.jsx';
import Notice from '../routes/Paypal/Notice';
import styles from './BasicLayout.less';
import { goToWoo } from '../common/sysconfig';
import { getMenuData } from '../common/menu';
import {
  getRoutes,
  goWWW,
  replaceUrlToHttps,
  oberloReport,
  getOberloReport,
  CommonDataCollect
} from '../utils/utils';
import NotFound from '../routes/Exception/404';
import SiderMenu from '../components/SiderMenu';
import {
  beforeMigrationLoop,
  BeforeMigration
} from '../components/BeforeMigration';
import GlobalHeader from '../components/GlobalHeader';
import checkUserForBargain from '../common/checkUserForBargain';
import AgencyColdStartUp from 'components/OnlineNotice/AgencyColdStartup';

import OpenModal from 'components/SideSupplyChainAd/Modal/index';

import { CAM001, CAM002, CAM003, CAM004, CAM005, CAM007 } from 'components/Cam';

import DownLoad from 'components/DownLoadDsersBook/DownLoad';
import OberloDataStatus from 'components/OldUiOut/OberloDataStatus';
import { getOberloGuidePop } from '../services/oberloGuilde';

import { LinkStoreAction } from 'actions';

import NoAccess from 'routes/Exception/NoAccess';
import NoPageAccess from 'routes/Tracking/noPageAccess';

import _ from 'lodash';

import '../routes/Setting/Dropshpper/setting.css';

import { Get, Post } from 'utils/request';
import syncproducts from 'assets/home/syncorder.png';

import {
  checkExtVersion,
  EXT_VERSION,
  getAELoginStatusV2
} from 'utils/placeOrderViaExt';
import { getAELoginStatus } from 'utils/localAEUtils';
import { shipMethod } from 'utils/bannerWhiteList';
import TmallColdStartupBanner from 'components/Banner/TmallColdStartupBanner';
import TmallColdStartup from 'components/OnlineNotice/TmallColdStartup';
import TmallStartImport from 'components/OnlineNotice/TmallStartImport';
import AdBrazilBanner from 'components/Banner/AdBrazilBanner';
import {
  bindDSersUidToFCMDeviceToken,
  onFrontMessageReceived
} from 'utils/initFirebase';
import headerBack from 'assets/img/christmas/headerBack.png';
import { tmallThemeUser } from 'utils/tmallColdStartUser';
import NewYearBanner from 'components/Banner/NewYearBanner';
import OberloTip from '../components/OblerloTip';
import OblerloGuide from '../components/OblerloGuide';
import loop from '../components/BeforeMigration/loop';
// import TmallTestExpressDialog from '../components/TmallTestExpressDialog';
const { Content, Header } = Layout;

// 用户登陆进来去哪
const getFirstUrl = (
  userInfo,
  plan,
  AEInfo,
  guideActive,
  normalViewport,
  goWoo,
  migrationInfo,
  migrationStep
) => {
  const {
    status = 0,
    stores,
    email_status,
    email,
    user_id: userId,
    woo_stores,
    ae_flag
  } = userInfo;

  /*  status = 00000000
        第一个0标识有没有看过定价服务(看过是1)
        第二个0标识有没有看过新手引导(看过是1)
        第三个0标识注册时选的是shopify还是woo(1是woo)
        第四个0标识是否是CSV用户
        倒数第二三位是针对特别用户弹不同banner用的
        最后一位是1说明cashback code有问题
  */
  // 激活邮箱
  // if (email_status == 0 && location.host.indexOf('dsers') != -1) {
  //   return `/user/register-result/${email}`;
  // }

  // 判断woo用户在shopify登录自动跳转到woo
  const keyStatus = localStorage.getItem('keyStatus');
  if (
    `${status}`.substring(2, 3) == 1 &&
    `${status}`.substring(3, 4) == 0 &&
    woo_stores &&
    woo_stores.length &&
    !keyStatus
  ) {
    goWoo();
    return;
  }

  // // 选平台 shopify 或 woocommerce
  if (status === 0) {
    return '/bind/platform';
  }

  if (`${status}`.substring(3, 4) === '0') {
    // 没店铺绑店铺
    if (!stores || stores.length == 0) {
      if (migrationStep?.step_stage == 'STEP_STAGE_QUEUE_RESERVATION') {
        return '/data-migrate';
      }
      return '/bind/platform';
    }
    // 看定价服务  判断
    if (
      stores &&
      stores.length != 0 &&
      JSON.stringify(plan) != 'null' &&
      plan.type == 0
    ) {
      return '/pricing?link=bind';
    }

    // 绑ae
    if (!(AEInfo && AEInfo.status === 1) && !ae_flag) {
      return '/bind/ae';
    }
  }
  // 先放这里，后面再优化，遵从 bind store => price plan => bind AE => tutorial
  // 这里的检测应该移步到 src/common/PrivateRoute.jsx，不应该在渲染的 layout 中检测，会造成闪烁
  const isCsvUser = status.toString().substring(3, 4) === '1';
  if (status.toString().substring(1, 2) === '0') {
    // 强制装插件的前置条件是用户未看过新手引导 此类用户定义为新用户
    // 命中灰度并且可以安装插件 并且 没有走过安装插件流程 并且不是csv用户 需要走安装插件流程
    if (
      checkUserForInstallExt() &&
      !getUserInstalledExt(userId) &&
      !isCsvUser
    ) {
      return '/bind/install_plugin';
    }

    if (getAdminRole() || window.DSERS_GUIDE) {
      // csv 用户依然展示旧的
      if (isCsvUser) {
        if (!AEInfo) {
          // 如果没有绑定ae，强制bind
          return '/bind/ae';
        }
        return '/guidance';
      }
      if (window.DSERS_SHOPIFY_GUIDE) {
        if (!migrationInfo) {
          if (IS_MOBILE) {
            return;
          }
          window.DSERS_SHOPIFY_GUIDE = false;
          // 用来限制一次
          // return '/tutorials';
        }
      }
      // 非csv直接展示新人引导
      // return './find_suppliers'
    } else {
      // return '/guidance';
    }
  }

  if (isCsvUser) {
    if (!(AEInfo && AEInfo.status === 1)) {
      // 如果没有绑定ae，强制bind
      return '/bind/ae';
    }
  }
};

/**
 * 迁移用户卡页面流程
 * @param {*} userInfo
 */
const getFirstOberloUrl = userInfo => {
  // 迁移用户由于没有新手引导所以取消新手引导标识
  if (localStorage.getItem('pushCheckStoreId')) {
    localStorage.removeItem('pushCheckStoreId');
  }
  const { status = 0, stores } = userInfo;
  if (`${status}`.substring(3, 4) === '0') {
    // 没店铺绑店铺
    if (!stores || stores.length == 0) {
      window.location.href = '/app/bind/platform';
    }
  }
};

/**
 * 获取面包屑映射
 * @param {Object} menuData 菜单配置
 * @param {Object} routerData 路由配置
 */
const getBreadcrumbNameMap = (menuData, routerData) => {
  const result = {};
  const childResult = {};
  for (const i of menuData) {
    if (!routerData[i.path]) {
      result[i.path] = i;
    }
    if (i.children) {
      Object.assign(childResult, getBreadcrumbNameMap(i.children, routerData));
    }
  }
  return { ...routerData, ...result, ...childResult };
};

const query = {
  'screen-xs': {
    maxWidth: 575
  },
  'screen-sm': {
    minWidth: 576,
    maxWidth: 767
  },
  'screen-md': {
    minWidth: 768,
    maxWidth: 991
  },
  'screen-lg': {
    minWidth: 992,
    maxWidth: 1199
  },
  'screen-xl': {
    minWidth: 1200,
    maxWidth: 1599
  },
  'screen-xxl': {
    minWidth: 1600
  }
};

const menusMap = {
  '/report': 1,
  '/bind/link-store': 7,
  '/storemanage/import': 2,
  '/storemanage/myproduct': 3,
  '/order/list': 4,
  '/order/archived': 6,
  '/tracking': 11,
  '/csv': 5,
  '/settings': 8,
  '//select': 10
};

class BasicLayout extends React.PureComponent {
  static childContextTypes = {
    location: PropTypes.object,
    breadcrumbNameMap: PropTypes.object
  };

  initAccessCheck = false; // 初始化检查一次用户状态

  constructor(props) {
    super(props);
    this.hasGuideAccess = window.DSERS_GUIDE || getAdminRole();
    this.hasPayPalAccess = window.DSERS_P_P || getAdminRole();
    // this.hasBargainAccess = window.DSERS_BARGAIN || getAdminRole();

    this.checkBargainStatus = checkUserForBargain(
      getMenuData(),
      props.routerData
    );

    this.state = {
      // isShowNewModel: true,
      isShowOldUiOutBanner: true,
      isShowOldUiOutModal: true,
      //
      aeStatus: false,
      aeHomeStatus: false,
      migrateGui: this.getMigrateGuiHandler(props),
      BrazilBannerNum: 0,
      loading: true,
      adData: null,
      isModalVisiable: false,
      camType: '',
      trial_7_days_mapping_advanced_status: false,
      trial_7_days_mapping_bogo_status: false,
      trial_7_days_mapping_bundle_status: false,
      menuData: getMenuData()
    };
  }

  getBrazilBannerDis() {
    const res = Get('/api/v1/banner/display', {}, true).then(res => {
      if (res.data.is_display && res.data.image_index) {
        this.setState({
          BrazilBannerNum: res.data.image_index
        });
      }
    });
  }

  getMigrateGuiHandler(props) {
    if (props.isoberlouser) {
      const res = Get(
        '/mgnoberlo-core-api/dsers-api/migration/action/customize',
        {
          data: {
            name: 'ae_plan_plug_status'
          }
        },
        true
      );
      if (res?.value == 'true') {
        return false;
      }
      return true;
    }
    false;
  }

  componentWillMount() {
    // 2021-09-06 11:00:00 ->  1630897200
    const isNewPerson = this.props.userInfo.created_at;
    // let nowDateTime = Date().now();
    // 1630897200
    if (isNewPerson > 1630897200 || localStorage.getItem('saw_old_out')) {
      this.setState({
        // isShowNewModel: false,
        isShowOldUiOutModal: false
      });
    } else {
      this.setState({
        // isShowNewModel: true,
        isShowOldUiOutModal: true
      });
    }

    const nowTime = parseInt(new Date().getTime() / 1000);
    const nextDayTime = parseInt(localStorage.getItem('nextDayTime')) || 0;
    if (nowTime > nextDayTime) {
      for (let index = 0; index < this.props.userInfo.stores.length; index++) {
        this.props.dispatch({
          type: 'topBtn/isSync',
          payload: {
            data: {
              store_id: this.props.userInfo.stores[index].id,
              start_at: nowTime - 172800,
              end_at: nowTime
            },
            cancelMessage: true,
            callback: res => {
              localStorage.setItem('nextDayTime', nowTime + 86400);
            }
          }
        });
      }
    }

    const XMLHttp = new window.XMLHttpRequest();

    XMLHttp.open(
      'GET',
      `https://img.dsers.com/shopify/global/advertising/${
        process.env.NODE_ENV === 'development' ? 'dev' : 'pro'
      }/userId.json?ID=${new Date().getTime()}`,
      true
    );

    XMLHttp.send();

    XMLHttp.onload = val => {
      if (XMLHttp.readyState === 4 && XMLHttp.status === 200) {
        window.advertisingUserId = JSON.parse(XMLHttp.responseText);
        this.setState({
          loading: false
        });
      }
    };
    this.getBrazilBannerDis();
  }

  getStoreStatus = () => {
    const obj = { shopify_domains: [] };
    for (let i = 0; i < this.props.userInfo.stores.length; i++) {
      obj.shopify_domains.push(this.props.userInfo.stores[i].domain);
    }
    return obj;
  };

  getChildContext() {
    const { location, routerData } = this.props;
    return {
      location,
      breadcrumbNameMap: getBreadcrumbNameMap(this.state.menuData, routerData)
    };
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    clearInterval(this.refreshLocalAETimer);
  }

  refreshLocalAEStatus = () => {
    this.getAELoginStatus();
    this.refreshLocalAETimer = setInterval(async () => {
      this.getAELoginStatus();
    }, 60 * 1000 * 2);
  };

  getAELoginStatus = async () => {
    const { AEInfo } = this.props;
    if (!AEInfo) return;
    const localAEInfo = await getAELoginStatus(AEInfo);
    this.props.dispatch({
      type: 'global/updateLocalAEInfo',
      payload: localAEInfo
    });
  };

  componentDidMount() {
    // oberloReport('ae_plan_plug_status','false')
    console.log('--------- BasicLayout DidMount --------', this.props);
    const {
      location,
      userInfo,
      plan,
      AEInfo,
      guideActive,
      normalViewport,
      dispatch,
      isBindAe,
      isInstallChromePlug,
      migrationInfo
    } = this.props;

    this.setState({
      menuData: getMenuData()
    });

    this.props.history.listen(route => {
      window.DSERS_COLLECT({
        action: 'global tracking',
        custom_info: []
      });
    });
    // 强制跳转新版UI（兼容老用户）
    if (Cookies.get('_ds_ver') == 1) {
      Cookies.remove('_ds_ver');
      window.location.reload();
    }

    const custom_info = [];

    // 注册完之后打点
    if (window.localStorage.getItem('firstRegister') == 'true') {
      if (Cookies.get('registerBtn')) {
        custom_info.push({
          name: 'siteFreeBtnReferrer',
          value: `${Cookies.get('registerBtn')}`
        });
      }
      window.localStorage.setItem('firstRegister', false);
    }

    if (window.localStorage.getItem('gaTest')) {
      custom_info.push({
        name: window.localStorage.getItem('gaTest'),
        value: 'ok'
      });
    }

    window.DSERS_COLLECT({
      action: 'register uerInfo',
      custom_info,
      user_id: this.props?.userInfo?.user_id
    });

    if (!userInfo.stores.length) {
      CommonDataCollect({
        action: 'nostore userInfo',
        event_category: 'web',
        event_type: 'nostoreinfo',
        user_id: userInfo?.user_id,
        infostatus: userInfo?.status,
        page_url: location.pathname
      });
    }

    this.refreshLocalAEStatus();

    connectExtension(
      result => {
        this.props.dispatch({
          type: 'global/updateExtensionResult',
          payload: result
        });
      },
      value => {
        this.props.dispatch({ type: 'global/updateAeCookie', payload: value });
      }
    );

    // 获取汇率
    dispatch({
      type: 'global/currencyState'
    });
    // 获取用户新手引导完成状态
    dispatch({ type: 'user/getNoviceGuidance' });

    if (this.props.isoberlouser) {
      if (localStorage.getItem('MIGRATION_PLUG_STATUS') == 'true') {
        CommonDataCollect({
          type: 'migration_extension_status',
          scene: 1,
          event_label: 'status',
          extension_status: this.props.isInstallChromePlug ? 1 : 2
        });
        localStorage.setItem('MIGRATION_PLUG_STATUS', false);
      }
      const setMigrationInfo = localStorage.getItem('setMigrationInfo');
      if (
        !['STEP_STAGE_PRE', 'STEP_STAGE_QUEUE', 'STEP_STAGE_STEP1'].includes(
          setMigrationInfo
        )
      ) {
        if (
          this.props?.plan?.status != 3 &&
          this.props?.plan?.default_store_id
        ) {
          dispatch({
            type: 'dropshippersetting/shopifyStatus',
            payload: {
              data: {
                store_id: this.props?.plan?.default_store_id
              },
              cancelMessage: true,
              callback: d => {
                if (!d?.data?.is_available) {
                  oberloReport('store_is_available', 'false').then(() => {
                    getOberloReport('store_is_available').then(res => {
                      if (res?.value == 'false') {
                        localStorage.setItem('store_is_available', false);
                      }
                    });
                  });
                } else {
                  localStorage.setItem('store_is_available', true);
                }
              }
            }
          });
        } else {
          localStorage.setItem('store_is_available', false);
        }

        // getOberloGuidePop({ data: { name: 'isBindPlan' } }).then(res=>{
        //   if(res == 'true'){
        //     localStorage.setItem('plan_is_bund',true)
        //   }else{
        //     localStorage.setItem('plan_is_bund',false)
        //   }
        // })

        getOberloReport('out_bind_ae').then(res => {
          if (res?.value == 'true') {
            this.setState({
              aeStatus: true
            });
          } else {
            this.setState({
              aeStatus: false
            });
          }
        });
      }
    }

    if (this.props.isInstallChromePlug && this.props.isoberlouser) {
      oberloReport('has_Plug', 'true');
    }

    setInterval(() => {
      // 更新汇率
      dispatch({
        type: 'global/currencyState'
      });
    }, 900000);

    (async () => {
      try {
        const res = await Get(
          '/mgnoberlo-core-api/dsers-api/migration/store/status',
          [],
          true
        );
        const migrationOverCheckAe =
          res?.store_stages[res?.store_stages.length - 1]?.step_stage ==
          'STEP_STAGE_COMPLETE';
        const aeSpecial = res?.store_stages[
          res?.store_stages.length - 1
        ]?.tips?.includes('TIP_REASON_ALIEXPRESS_UNAUTHORIZED');

        if (
          (!!res?.store_stages?.length ||
            (res?.code == 2000 && !!res?.msg.match(/proxy failed/gi)?.[0])) &&
          !this.state.aeStatus &&
          this.props.newUserInfo.type !== 2
        ) {
          // 是否为迁移账户
          const { status = 0, stores } = this.props.userInfo;
          if (`${status}`.substring(3, 4) === '0' && migrationOverCheckAe) {
            // 没店铺绑店铺
            if (stores.length === 1) {
              if (stores[0].status == 2 || stores[0].status == 4) {
                const response = await Post(
                  '/mgnoberlo-core-api/dsers-api/migration/auth/shopify/bind',
                  {
                    data: {
                      shop_name: stores[0]?.shop_name,
                      store_id: stores[0]?.id
                    }
                  },
                  true
                );
                window.location.href = response.redirect_url;
              }
            }
          }
          if ((migrationOverCheckAe || aeSpecial) && !this.props.isBindAe) {
            const checkAeRes = await Get('/auth/ali/status', {});
            if (checkAeRes?.data && checkAeRes.data.status !== 1) {
              window.location.href = '/app/bind/ae';
            }
          }
          if (
            !this.props.migrationInfo?.is_new_user &&
            (this.props.migrationInfo?.status ==
              'MIGRATION_STATUS_PRE_BINDACCOUNT' ||
              this.props.migrationInfo?.status ==
                'MIGRATION_STATUS_PRE_BINDSHOPIFY' ||
              this.props.migrationInfo?.status ==
                'MIGRATION_STATUS_PRE_REQUEST') &&
            this.props.migrationInfo?.step_stage !==
              'STEP_STAGE_QUEUE_RESERVATION'
          ) {
            window.location.href = '/app/pre-migrate';
          } else if (
            [
              'STEP_STAGE_PRE',
              'STEP_STAGE_QUEUE',
              'STEP_STAGE_STEP1',
              'STEP_STAGE_QUEUE_RESERVATION'
            ].includes(this.props.migrationInfo?.step_stage)
          ) {
            window.location.href = '/app/data-migrate'; // 走数据迁移
          } else {
            if (userInfo) {
              getFirstOberloUrl(userInfo);
            }
            //  this.props.history.push('/home')
          }
        } else {
          if (
            this.props.migrationInfo?.step_stage ==
              'STEP_STAGE_QUEUE_RESERVATION' &&
            this.props.migrationInfo?.is_new_user
          ) {
            window.location.href = '/app/data-migrate';
          }

          if (
            userInfo &&
            plan &&
            Object.keys(plan).length > 0 &&
            !this.initAccessCheck &&
            AEInfo !== undefined
          ) {
            this.initAccessCheck = true;
            const url = getFirstUrl(
              userInfo,
              plan,
              AEInfo,
              guideActive,
              normalViewport,
              this.goWoo,
              this.props.isoberlouser,
              this.props.migrationInfo
            );
            console.log('--------- init plan check --------');
            this.handlePushData();

            if (url) {
              console.log('init check url:', url);
              this.props.dispatch(routerRedux.push(url));
            }
          }
        }
      } catch (error) {
        // 容错，捕获到错误走正常逻辑
        if (
          userInfo &&
          plan &&
          Object.keys(plan).length > 0 &&
          !this.initAccessCheck &&
          AEInfo !== undefined
        ) {
          this.initAccessCheck = true;
          const url = getFirstUrl(
            userInfo,
            plan,
            AEInfo,
            guideActive,
            normalViewport,
            this.goWoo,
            this.props.isoberlouser,
            this.props.migrationInfo
          );
          console.log('--------- init plan check --------');
          this.handlePushData();

          if (url) {
            console.log('init check url:', url);
            this.props.dispatch(routerRedux.push(url));
          }
        }
      }
    })();

    if (location) {
      this.trackPage(location.pathname);
    }

    if(window.CAINIAO_USER) {
      dispatch({ type: 'global/getAgencyListFromMerchant' });
    }

    this.props.dispatch({
      type: 'dropshippersetting/country'
    });

    this.props.dispatch({
      type: 'dropshippersetting/logistics'
    });
    this.props.dispatch({
      type: 'dropshippersetting/logistics_tm'
    });
    // home页未读消息
    this.setHomeUnreadStatus(userInfo);

    // 获取不支持的物流列表
    this.getUnsupportedShipping();

    goWWW();

    this.getSettingTimeZone();
    this.getAllRates();

    const fbChat = document.querySelector('.fb-customerchat');

    if (fbChat) {
      fbChat.setAttribute('ref', this.props.userInfo.user_id);
    }

    this.getIsConversionUser();

    // 获取广告的时机
    setTimeout(() => {
      this.getAutoList();
    }, 800);

    window.EVENT.on(LinkStoreAction.GET_AUTO_LIST, params => {
      this.getEmitAutoList();
    });

    window.EVENT.on(LinkStoreAction.CAM_MODAL, params => {
      this.setState({
        isModalVisiable: true,
        camType: params
      });
    });

    // 记录用户是否安装谷歌插件
    setTimeout(() => {
      window.dtag.set({
        event: 'init',
        module: 'layouts',
        action: 'is_install_chrome_plugin',
        custom_data: {
          is_install: `${window.CHROME_PLUG_INSTALL}`
        }
      });
    }, 1000);
  }

  closeDetail = () => {
    this.setState({
      isModalVisiable: false
    });
  };

  getAutoList = async () => {
    const { userInfo, plan } = this.props;
    console.log('adData', userInfo, plan);

    // 新手引导 || 没有选平台
    // || userInfo?.status?.toString()?.substring(1, 2) === '0'
    if (userInfo?.status == 0) {
      return;
    }

    // 套餐到期
    if (JSON.stringify(plan) != 'null' && plan?.status == 3) {
      return;
    }

    // 是不是basic套餐
    // if(![0,1].includes(plan?.type)){
    //   return
    // }

    // 没有绑定AE
    let flags = false;
    if (!userInfo.ae_flag) {
      if (!window.IS_BIND_AE) {
        flags = true;
      }
    }
    if (flags) {
      return;
    }

    // 没有店铺
    if (!userInfo?.stores?.length) {
      return;
    }

    const response = await Get('/ad/auto');
    if (response.code === 2000) {
      this.setState({
        adData: response.data
      });
    }
  };

  getEmitAutoList = async () => {
    const response = await Get('/ad/auto');
    if (response.code === 2000) {
      this.setState({
        adData: response.data
      });
    }
  };

  isUpdate = true;

  componentDidUpdate(prevProps) {
    console.log('--------- BasicLayout DidUpdate --------');
    const {
      userInfo,
      plan,
      location,
      isMobile,
      collapsed,
      guideActive,
      normalViewport,
      AEInfo,
      isBindAe,
      isInstallChromePlug,
      migrationInfo
    } = this.props;

    if (plan?.trial_7_days_mapping_advanced_status && this.isUpdate) {
      if (plan?.trial_7_days_mapping_advanced_status) {
        // 判断plan状态
        if (
          plan?.trial_7_days_mapping_advanced_status == 2 ||
          plan?.trial_7_days_mapping_advanced_status == 3
        ) {
          // 判断是不是升级套餐了
          if ([1, 12, 13, 14].includes(plan?.type)) {
            this.setState({
              trial_7_days_mapping_advanced_status: true
            });
          }
        }

        if (
          plan?.trial_7_days_mapping_bogo_status == 2 ||
          plan?.trial_7_days_mapping_bogo_status == 3
        ) {
          if ([1, 12, 13, 14].includes(plan?.type)) {
            this.setState({
              trial_7_days_mapping_bogo_status: true
            });
          }
        }

        if (
          plan?.trial_7_days_mapping_bundle_status == 2 ||
          plan?.trial_7_days_mapping_bundle_status == 3
        ) {
          if ([1, 12, 13, 14].includes(plan?.type)) {
            this.setState({
              trial_7_days_mapping_bundle_status: true
            });
          }
        }
      }
      this.isUpdate = false;
    }

    if (isMobile && !prevProps.isMobile && !collapsed) {
      this.handleMenuCollapse(false);
    }

    if (AEInfo !== prevProps.AEInfo) {
      this.getAELoginStatus();
    }

    // 保证 plan 存在时只执行一次
    (async () => {
      try {
        const res = await Get(
          '/mgnoberlo-core-api/dsers-api/migration/store/status',
          [],
          true
        );
        const migrationOverCheckAe =
          res?.store_stages[res?.store_stages.length - 1]?.step_stage ==
          'STEP_STAGE_COMPLETE';

        const aeSpecial = res?.store_stages[
          res?.store_stages.length - 1
        ]?.tips?.includes('TIP_REASON_ALIEXPRESS_UNAUTHORIZED');
        if (
          (!!res?.store_stages?.length ||
            (res?.code == 2000 && !!res?.msg.match(/proxy failed/gi)?.[0])) &&
          !this.state.aeStatus &&
          this.props.newUserInfo.type !== 2
        ) {
          // 是否为迁移账户
          const { status = 0, stores } = this.props.userInfo;
          // console.log(this.props.userInfo,'this.props.userInfothis.props.userInfo');
          if (`${status}`.substring(3, 4) === '0' && migrationOverCheckAe) {
            // 没店铺绑店铺
            if (stores.length === 1) {
              if (stores[0].status == 2 || stores[0].status == 4) {
                const response = await Post(
                  '/mgnoberlo-core-api/dsers-api/migration/auth/shopify/bind',
                  {
                    data: {
                      shop_name: stores[0]?.shop_name,
                      store_id: stores[0]?.id
                    }
                  },
                  true
                );
                window.location.href = response.redirect_url;
              }
            }
          }
          if ((migrationOverCheckAe || aeSpecial) && !this.props.isBindAe) {
            const checkAeRes = await Get('/auth/ali/status', {});
            if (checkAeRes?.data && checkAeRes?.data?.status !== 1) {
              window.location.href = '/app/bind/ae';
            }
          }
          if (
            !this.props.migrationInfo?.is_new_user &&
            (this.props.migrationInfo?.status ==
              'MIGRATION_STATUS_PRE_BINDACCOUNT' ||
              this.props.migrationInfo?.status ==
                'MIGRATION_STATUS_PRE_BINDSHOPIFY' ||
              this.props.migrationInfo?.status ==
                'MIGRATION_STATUS_PRE_REQUEST') &&
            this.props.migrationInfo?.step_stage !==
              'STEP_STAGE_QUEUE_RESERVATION'
          ) {
            window.location.href = '/app/pre-migrate';
          } else if (
            [
              'STEP_STAGE_PRE',
              'STEP_STAGE_QUEUE',
              'STEP_STAGE_STEP1',
              'STEP_STAGE_QUEUE_RESERVATION'
            ].includes(this.props.migrationInfo?.step_stage)
          ) {
            window.location.href = '/app/data-migrate'; // 走数据迁移
          } else if (userInfo) {
            getFirstOberloUrl(userInfo);
          }
        } else {
          if (
            this.props.migrationInfo?.step_stage ==
              'STEP_STAGE_QUEUE_RESERVATION' &&
            this.props.migrationInfo?.is_new_user
          ) {
            window.location.href = '/app/data-migrate';
          }

          if (
            userInfo &&
            plan &&
            Object.keys(plan).length > 0 &&
            !this.initAccessCheck &&
            AEInfo !== undefined
          ) {
            this.initAccessCheck = true;
            const url = getFirstUrl(
              userInfo,
              plan,
              AEInfo,
              guideActive,
              normalViewport,
              this.goWoo,
              this.props.isoberlouser,
              this.props.migrationInfo
            );
            console.log('--------- init plan check --------');
            this.handlePushData();

            // 获取广告的时机

            if (url) {
              console.log('init check url:', url);
              this.props.dispatch(routerRedux.push(url));
            }
          }
        }
      } catch (error) {
        if (
          userInfo &&
          plan &&
          Object.keys(plan).length > 0 &&
          !this.initAccessCheck &&
          AEInfo !== undefined
        ) {
          this.initAccessCheck = true;
          const url = getFirstUrl(
            userInfo,
            plan,
            AEInfo,
            guideActive,
            normalViewport,
            this.goWoo,
            this.props.isoberlouser,
            this.props.migrationInfo
          );
          console.log('--------- init plan check --------');
          this.handlePushData();

          if (url) {
            console.log('init check url:', url);
            this.props.dispatch(routerRedux.push(url));
          }
        }
      }
    })();

    if (localStorage.getItem('hasUpdateCurrency')) {
      localStorage.removeItem('hasUpdateCurrency');
      this.getAllRates();
    }

    if (location !== prevProps.location) {
      this.trackPage(location.pathname);
    }

    if (!_.isEqual(migrationInfo, prevProps.migrationInfo)) {
      const setMigrationInfo = localStorage.getItem('setMigrationInfo');
      if (
        !['STEP_STAGE_PRE', 'STEP_STAGE_QUEUE', 'STEP_STAGE_STEP1'].includes(
          setMigrationInfo
        ) &&
        this.props.isoberlouser
      ) {
        if (
          this.props?.plan?.status != 3 &&
          this.props?.plan?.default_store_id
        ) {
          this.props.dispatch({
            type: 'dropshippersetting/shopifyStatus',
            payload: {
              data: {
                store_id: this.props?.plan?.default_store_id
              },
              cancelMessage: true,
              callback: d => {
                if (!d?.data?.is_available) {
                  oberloReport('store_is_available', 'false').then(() => {
                    getOberloReport('store_is_available').then(res => {
                      if (res?.value == 'false') {
                        localStorage.setItem('store_is_available', false);
                      }
                    });
                  });
                } else {
                  localStorage.setItem('store_is_available', true);
                }
              }
            }
          });
        } else {
          localStorage.setItem('store_is_available', false);
        }
        if (this.props.isoberlouser) {
          getOberloReport('out_bind_ae').then(res => {
            if (res?.value == 'true') {
              this.setState({
                aeStatus: true
              });
            } else {
              this.setState({
                aeStatus: false
              });
            }
          });

          // getOberloGuidePop({ data: { name: 'isBindPlan' } }).then(res=>{
          //   if(res == 'true'){
          //     localStorage.setItem('plan_is_bund',true)
          //   }else{
          //     localStorage.setItem('plan_is_bund',false)
          //   }
          // })
        }
      }
    }
    if (
      location.pathname.includes('find_suppliers') &&
      !localStorage.getItem('themeClose') &&
      window.TMALL_USER
    ) {
      this.props.dispatch({
        type: 'global/setThemeStatus',
        payload: true
      });
    } else {
      this.props.dispatch({
        type: 'global/setThemeStatus',
        payload: false
      });
    }
  }

  getIsConversionUser = async () => {
    const userRes = await Get('/ad/pushed/list');
    if (userRes && userRes.code === 2000) {
      userRes.data.forEach(async item => {
        if (item.user_id === this.props.userInfo.user_id) {
          const stores = this.props.userInfo.stores || [];
          const storeIds =
            stores.map(item => {
              return item?.id;
            }) || [];
          const params = {
            data: {
              store_ids: storeIds
            }
          };
          await Get('/prod/propool/ad/list', params).then(async res => {
            if (res?.data[0]) {
              this.props.dispatch({
                type: 'global/setConversionStatus',
                payload: false
              });
            }
          });
        }
      });
    }
  };

  getUnsupportedShipping = () => {
    const { dispatch } = this.props;
    dispatch({
      type: 'global/getUnsupportedShipping'
    });
  };

  getAllRates = async () => {
    const {
      userInfo: { stores = [] }
    } = this.props;
    const currencyMap = {};
    const storeCurrencyMap = {};

    for (let i = 0, len = stores.length; i < len; i++) {
      if (
        stores[i]?.ali_currency === undefined ||
        currencyMap[stores[i]?.ali_currency]
      ) {
        continue;
      }

      const res = await this.getRates(stores[i]?.ali_currency);

      if (res.code === 2000) {
        currencyMap[res.data.currency_code] = res.data;
      }
    }

    if (stores.length) {
      stores.forEach(item => {
        if (item?.ali_currency) {
          storeCurrencyMap[item.id] = currencyMap[item.ali_currency];
          return;
        }
        storeCurrencyMap[item?.id] = item;
      });
      this.props.dispatch({
        type: 'global/setCurrency',
        payload: storeCurrencyMap
      });
    }
  };

  setHomeUnreadStatus = (userInfo = {}) => {
    const { dispatch } = this.props;
    dispatch({
      type: 'global/getHomeUnreadMessageStatus',
      payload: userInfo
    });
  };

  goWoo = () => {
    window.location.href = goToWoo;
  };

  getRates = currency => {
    return new Promise((resolve, reject) => {
      this.props.dispatch({
        type: 'user/rates',
        payload: {
          data: {
            currency_code: currency
          },
          callback: d => {
            resolve(d);
          }
        }
      });
    });
  };

  // 获取setting -> report 中设置的 time zone
  getSettingTimeZone = () => {
    this.props.dispatch({
      type: 'global/timezone'
    });
  };

  timer = null;

  getPageTitle() {
    const { routerData, location } = this.props;
    const { pathname } = location;
    let title = 'DSers - Automation order management with tech solution';
    if (routerData[pathname] && routerData[pathname].name) {
      title = `${routerData[pathname].name} - ${title}`;
    }
    return title;
  }

  getHeadWidth = () => {
    const { isMobile, collapsed } = this.props;
    if (isMobile) {
      return '100%';
    }
    return collapsed ? 'calc(100% - 140px)' : 'calc(100% - 220px)';
  };

  trackPage = page => {
    ReactGA.set({
      page
    });
    ReactGA.pageview(page);
  };

  handlePushData = () => {
    const { userInfo, plan } = this.props;

    window.dataLayer.push({
      event: 'setUserId',
      userId: userInfo.user_id,
      storeId: getStoreId() || '',
      aliId: localStorage.getItem('aliAccountId') || '',
      plan: plan.type,
      platform: 'shopify',
      channel: localStorage.getItem('chanel_str')
      // clientId: window.ga && window.ga.getAll()[0].get('clientId') // ga 创建的 unique client id
    });

    ReactGA.set({ userId: userInfo.user_id });
    bindDSersUidToFCMDeviceToken(userInfo).then(() => {
      onFrontMessageReceived(message => {
        console.log(message);
        // alert(JSON.stringify(message));
      });
    });
  };

  handleMenuCollapse = collapsed => {
    this.props.dispatch({
      type: 'global/changeLayoutCollapsed',
      payload: collapsed
    });
  };

  // 消息提示（右上角弹出）
  showNotification = payload => {
    notification.open({
      key: 'fcmNotification',
      message: (
        <p>
          <a href="#/notification/index">{payload.data.title}</a>
        </p>
      ),
      description: (
        <p>
          <a href="#/notification/index">{payload.data.body}</a>
          {payload.data.data == 0 ? (
            <a href="#/notification/index">Click to check on DSers.</a>
          ) : (
            <a href="#/notification/index">
              and <b>{payload.data.data}</b>
{' '}
more products.
<br /> Click to check on DSers.
            </a>
          )}
        </p>
      ),
      icon: (
        <a href="#/notification/index">
          <img
            src={replaceUrlToHttps(payload.data.image)}
            style={{ width: 60, height: 60 }}
          />
        </a>
      ),
      duration: 4,
      className: styles.showNotification
    });
  };

  notificationToken = token => {
    // 给后台传token
    this.props.dispatch({
      type: 'user/notificationToken',
      payload: {
        data: {
          token
        }
      }
    });
  };

  getLeftMenuData = () => {
    const data = this.state.menuData;
    return data.filter(item => {
      let flag = true;
      if (item.path == '/fulfillment-center') {
        if (localStorage.getItem('showFulfillmentCenter') == 'true') {
          flag = true;
        } else {
          flag = false;
        }
      } else {
        flag = true;
      }
      return flag;
    });
  };

  closeOldUiOutModal = () => {
    this.setState({
      isShowOldUiOutModal: false
    });
    localStorage.setItem('saw_old_out', true);
  };

  checkBanner = path => {
    let allow = false;
    const allowPath = [
      'accountoverview',
      'storemanage',
      'order',
      'notification',
      'settings'
    ];

    for (const i of allowPath) {
      if (path.indexOf(i) > -1) {
        allow = true;
        break;
      }
    }

    return allow;
  };

  handleThemeButton = () => {
    if (localStorage.getItem('themeClose')) {
      localStorage.removeItem('themeClose');
    } else {
      localStorage.setItem('themeClose', true);
    }
    this.props.dispatch({
      type: 'global/setThemeStatus',
      payload: !this.props.isSaintsTheme
    });
  };

  render() {
    const {
      collapsed,
      routerData,
      match,
      location,
      isMobile,
      userInfo,
      plan,
      lang,
      normalViewport,
      selectStoreIsDisabled,
      isoberlouser,
      isBindAe,
      csvOrderCount
    } = this.props;

    const { BrazilBannerNum } = this.state;

    const showBeginnerGuide =
      userInfo &&
      userInfo.status.toString().substring(1, 2) === '0' &&
      userInfo.status.toString().substring(3, 4) !== '1' &&
      normalViewport;
    const hasBanner = this.checkBanner(location.pathname);
    const routes = getRoutes(match.path, routerData);
    const defaultUrl =
      IS_MOBILE || this.props.guideInfo.first
        ? '/home'
        : this.checkBargainStatus === -1
        ? this.state.migrateGui
          ? '/home'
          : '/find_suppliers'
        : '/storemanage/myproduct';

    const layout = (
      <Layout className={styles.layout}>
        {/* <OldToNewModal
          isshow={this.state.isShowNewModel}
          closeModal={this.closeModal}
        /> */}
        {/* <OldUiOutModal
          isshow={this.state.isShowOldUiOutModal}
          // isshow={this.state.isShowOldUiOutModal}
          closeModal={this.closeOldUiOutModal}
        /> */}
        <SiderMenu
          menuData={this.getLeftMenuData()}
          collapsed={collapsed}
          location={location}
          onCollapse={this.handleMenuCollapse}
          isMobile={isMobile}
          userId={userInfo.user_id}
          userinfo={userInfo}
          csvOrderCount={csvOrderCount}
          isSaintsTheme={this.props.isSaintsTheme}
        />
        <Layout
          style={{
            height: '100vh',
            overflow: 'hidden'
          }}
        >
          <Header
            className={styles.fixedHeader}
            style={{ padding: 0, width: this.getHeadWidth() }}
          >
            <GlobalHeader
              collapsed={collapsed}
              onCollapse={this.handleMenuCollapse}
              isMobile={isMobile}
              extraContent={
                <ExtraHeader
                  pathname={location.pathname}
                  selectStoreIsDisabled={selectStoreIsDisabled}
                />
              }
              pathname={location.pathname}
              isSaintsTheme={this.props.isSaintsTheme}
              handleThemeButton={this.handleThemeButton}
            />
          </Header>
          <Ad />
          <ResetPasswordModal />
          <OneClickMapping />
          <DownLoad className={styles.download} />
          <OblerloGuide />
          <Content
            className={styles.BasicLayoutContent}
            style={{ overflowY: 'scroll' }}
            id="BasicLayoutContentById"
          >
            <OberloTip />
            {/* 全局tooltip popover dropdown等元素的定位参照 */}
            <div
              id="base-layout-popover-refer"
              style={{ position: 'relative' }}
            />
            {/* oberlo用户迁移商品同步提示 */}
            <OberloDataStatus path={location.pathname} />
            <NoticePlanBanner path={location.pathname} userInfo={userInfo} />
            <RewardSeason lang={lang} />
            <AliShipping lang={lang} />
            <Anniversary lang={lang} />
            {/* <TmallColdStartShow lang={lang} />
            <TmallColdStartBanner lang={lang}  path={location.pathname} /> */}
            {/* <AgencyColdStartUp lang={lang} /> */}
            {/* <TmallColdStartup lang={lang} /> */}
            <TmallStartImport />
            {/* 测试Tmall物流链路的弹框 收集部分用户的信息 免费发商品 */}
            {/* <TmallTestExpressDialog /> */}
            {/* <IossNotice
              path={location.pathname}
              lang={lang}
              userId={userInfo?.user_id}
            /> */}
            {/* shipcost弹窗 */}
            <ShipCost path={location.pathname} />
            <NewShipOptions userId={userInfo?.user_id} />
            <BrazilNotice userId={userInfo?.user_id} />
            {/* 新功能弹窗 */}
            <BannerComment
              path={location.pathname}
              lang={lang}
              userId={userInfo?.user_id}
            />
            <OpenModal />
            <SwitchBanner userId={userInfo?.user_id} />
            <PaypalPastDue dispatch={this.props.dispatch} />
            <AliShippingBanner path={location.pathname} lang={lang} />
            {/* <NewYearBanner path={location.pathname}/> */}
            {/* <AgencyColdStartUpBanner path={location.pathname} lang={lang} BrazilBannerNum={BrazilBannerNum}/> */}
            {/* <TmallColdStartupBanner path={location.pathname} lang={lang} BrazilBannerNum={BrazilBannerNum}/> */}
            {/* <AdBrazilBanner  path={location.pathname} lang={lang} BrazilBannerNum={BrazilBannerNum} /> */}
            {/* <BargainNoticeBanner status={this.checkBargainStatus} lang={lang} /> */}
            {/* <BannerV2 path={location.pathname} lang={lang} /> */}
            {/* <Banner914 path={location.pathname} lang={lang} /> */}
            {/* <RewardSeasonBanner path={location.pathname} lang={lang} /> */}
            {/* <ChileRutNumberBanner path={location.pathname} lang={lang} /> */}
            {/* <ShopMasterBanner path={location.pathname} lang={lang} /> */}
            {/* <div className={styles.adBanner}>
              <ADTopBannerContainer
                CommonBanner={ShopMasterBanner}
                ADBanner={SupplierADBanner}
                IOSSBanner={IossBanner}
                CommentBanner={CommentBanner}
                dispatch={this.props.dispatch}
                userId={userInfo?.user_id}
                path={JSON.stringify(location.pathname)}
                lang={lang}
              />
            </div> */}
            <ADTopBannerContainer
              CommonBanner={NewUIBanner}
              // ADBanner={SupplierADBanner}
              ADBanner={V5Banner}
              RutBanner={RutBanner}
              // IOSSBanner={IossBanner}
              NEWBanner={NewListBanner}
              CommentBanner={CommentBanner}
              BrazilBanner={BrazilBanner}
              dispatch={this.props.dispatch}
              userId={userInfo?.user_id}
              path={location.pathname}
              lang={lang}
              userInfo={userInfo}
            />
            <BeforeMigration path={location.pathname} />
            {/* 侧边栏广告 */}
            <SideAdContainer
              path={location.pathname}
              userInfo={userInfo}
              userId={userInfo?.user_id}
            />
            <OptimizerInfoTip lang={lang} path={location.pathname} />
            {/* <FreePeriodOverBanner path={location.pathname} lang={lang} /> */}
            {/* onlineNotice Supplier Optimizer */}
            {/* <SupplierOptmizerOnline lang={lang} /> */}
            {userInfo ? (
              <Switch>
                {routes.map(item => {
                  if (
                    this.props.newUserInfo?.user_role === 2 &&
                    [
                      '/report',
                      '/bind/link-store',
                      '/storemanage/import',
                      '/storemanage/myproduct',
                      '/order/list',
                      '/order/archived',
                      '/tracking',
                      '/csv',
                      '/settings',
                      '//select'
                    ].includes(item.key) &&
                    !this.props.newUserInfo?.menu_permissions?.includes(
                      menusMap[item.key]
                    )
                  ) {
                    return (
                      <Route
                        exact={item.exact}
                        key={item.path}
                        path={`${item.path}`}
                        render={() => <NoAccess />}
                      />
                    );
                  }
                  return (
                    <Route
                      exact={item.exact}
                      key={item.path}
                      path={`${item.path}`}
                      component={item.component}
                    />
                  );
                })}
                <Redirect exact from="/" to={defaultUrl} />
                <Route render={() => <NotFound />} />
              </Switch>
            ) : (
              <Switch>
                <Route
                  exact
                  from="/"
                  render={() => (
                    <div style={{ textAlign: 'center', marginTop: 50 }}>
                      Loading...
                    </div>
                  )}
                />
              </Switch>
            )}
            {/* 618 商品推送 */}
            {hasBanner ? <ShopPush openBanner={userInfo.supply_hide} /> : null}
          </Content>
        </Layout>

        <ReportNoticeOnline />
        {/* 新手教程入口 */}
        {/* {this.hasGuideAccess && showBeginnerGuide ? (
          <GuideSchedule tutorialType={userInfo.tutorial_type} />
        ) : null} */}
        {userInfo && userInfo.code_type === 1 && <AdmitadModal />}
        {this.hasPayPalAccess ? <Notice /> : null}
        {userInfo && userInfo.product_agent ? <SupplyNotice /> : null}
        <CheckBigUser />
      </Layout>
    );

    return (
      <>
        <DocumentTitle title={this.getPageTitle()}>
          <ContainerQuery query={query}>
            {params => (
              <div className={classNames(params)}>
                {[
                  'STEP_STAGE_PRE',
                  'STEP_STAGE_QUEUE',
                  'STEP_STAGE_STEP1'
                ].includes(this.props.migrationInfo?.step_stage) &&
                this.props.isoberlouser &&
                this.props.newUserInfo.type !== 2 ? (
                  <Spin />
                ) : (
                  layout
                )}
              </div>
            )}
          </ContainerQuery>
        </DocumentTitle>
        {/* {!this.state.loading &&
        window.advertisingUserId.includes(userInfo.user_id) &&
        [0, 1].includes(this?.props?.plan?.type) ? (
          <CAM001 />
        ) : <CAM001 data={this.state.adData}/> } */}
        <CAM001 data={this.state.adData} />

        {this.state.isModalVisiable ? (
          <CAM002 camType={this.state.camType} closeDetail={this.closeDetail} />
        ) : null}
        <Modal
          title="Upgrade plane"
          visible={this.state.trial_7_days_mapping_advanced_status}
          onCancel={() => {
            this.setState({
              trial_7_days_mapping_advanced_status: false
            });
          }}
          footer={(
            <Button
              type="primary"
              data="dx"
              onClick={() => {
                window.location.href = '/app/pricing';
              }}
            >
              CHANGE PLAN
            </Button>
          )}
        >
          {plan?.trial_7_days_mapping_advanced_status == 2 ? (
            <>
              Your 7-day trial (
              <span style={{ color: '#ff8f00' }}>Advanced Mapping</span>
) will
              expire in 1 day. Please change your plan as soon as possible for a
              better experience.
</>
          ) : (
            <>
              Your 7-day trial (
              <span style={{ color: '#ff8f00' }}>Advanced Mapping</span>
) has
              expired; you can't continue to use that feature. Please change
              your plan as soon as possible for a better experience.
</>
          )}
        </Modal>
        <Modal
          title="Upgrade plane"
          visible={this.state.trial_7_days_mapping_bogo_status}
          onCancel={() => {
            this.setState({
              trial_7_days_mapping_bogo_status: false
            });
          }}
          footer={(
            <Button
              type="primary"
              data="dx"
              onClick={() => {
                window.location.href = '/app/pricing';
              }}
            >
              CHANGE PLAN
            </Button>
          )}
        >
          {plan?.trial_7_days_mapping_bogo_status == 2 ? (
            <>
              Your 7-day trial (
              <span style={{ color: '#ff8f00' }}>Bogo Mapping</span>
) will
              expire in 1 day. Please change your plan as soon as possible for a
              better experience.
</>
          ) : (
            <>
              Your 7-day trial (
              <span style={{ color: '#ff8f00' }}>Bogo Mapping</span>
) has
              expired; you can't continue to use that feature. Please change
              your plan as soon as possible for a better experience.
</>
          )}
        </Modal>
        <Modal
          title="Upgrade plane"
          visible={this.state.trial_7_days_mapping_bundle_status}
          onCancel={() => {
            this.setState({
              trial_7_days_mapping_bundle_status: false
            });
          }}
          footer={(
            <Button
              type="primary"
              data="dx"
              onClick={() => {
                window.location.href = '/app/pricing';
              }}
            >
              CHANGE PLAN
            </Button>
          )}
        >
          {plan?.trial_7_days_mapping_bundle_status == 2 ? (
            <>
              Your 7-day trial (
              <span style={{ color: '#ff8f00' }}>Bundle Mapping</span>
) will
              expire in 1 day. Please change your plan as soon as possible for a
              better experience.
</>
          ) : (
            <>
              Your 7-day trial (
              <span style={{ color: '#ff8f00' }}>Bundle Mapping</span>
) has
              expired; you can't continue to use that feature. Please change
              your plan as soon as possible for a better experience.
</>
          )}
        </Modal>
      </>
    );
  }
}

export default connect(({ user, global, login, auth, topBtn }) => ({
  plan: user.plan,
  userInfo: login.userInfo,
  lang: global.lang,
  collapsed: global.collapsed,
  selectStoreIsDisabled: global.selectStoreIsDisabled,
  guideActive: global.guideActive,
  AEInfo: global.AEInfo,
  newUserInfo: user.newUserInfo,
  migrationInfo: auth.migrationInfo,
  isBindAe: global.isBindAe,
  isInstallChromePlug: global.isInstallChromePlug,
  isoberlouser: auth?.isOberloUser,
  dsers_oberlo_plan: topBtn.fromOberloPlan,
  csvOrderCount: user.csvOrderCount,
  isSaintsTheme: global.themeStatus,
  guideInfo: global.guideInfo
}))(props => (
  <Media
    queries={{
      small: '(max-width: 599px)',
      large: '(min-width: 1300px)' // 小于这个宽度不展示引导教程，由于内容左右滚动
    }}
  >
    {matches => (
      <BasicLayout
        {...props}
        isMobile={matches.small}
        normalViewport={matches.large}
      />
    )}
  </Media>
));
