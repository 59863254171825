import React,{ useEffect, useState } from "react"
import { Modal, Button } from 'antd';
import intl from 'react-intl-universal';
/**
 * 下载插件统一弹窗
 * @param visiable 是否显示刷新弹窗 
 * @returns 
 */
const GuideRefresh = ({ visible }) => {
    const [vis, setVis] = useState(false);
    useEffect(() => {
        visible && setVis(visible)
        },[visible])
   return (
    <Modal
     visible={vis}
     zIndex={2000}
     width={560}
     maskClosable={false}
     footer={
       <div>
         <Button
                 type="primary"
                 onClick={() => {
                    window.location.reload()
                 }}
         >
           {intl.get('guide.RefreshPopText').btn}
         </Button>
       </div>
     }
     title={intl.get('guide.RefreshPopText').title}
     onCancel={() => {
        setVis(false)
     }}
   >
        <div>
        {intl.get('guide.RefreshPopText').content}  
        </div>
       </Modal>
    )
}
export default GuideRefresh