import { Post, Get } from '../utils/request.js';

export async function fakeRegister(payload) {
  return Post('/users/signup', {data:{
    ...payload.data
  }});
}

export async function getSendEmailResult(payload) {
  return Get('/users/mail/status', payload);
}

export async function childRegister(payload){
  return Post('/uaa/api/1.0/shopifyChildAccountRegister',payload,true)
}