export default {
  notification: {
    notifications: 'Notifications',
    mark_all_as_read: 'Marquer comme lu',
    tip:
      'Vous recevrez des notifications uniquement pour les produits déjà commandés dans le passé. Les notifications seront affichées dans DSers pendant 30 jours.',
    regarding_to_aliexpress_statement: 'Regarding to Aliexpress statement:', // d
    product_price_has_changed: 'Le coût du produit a changé', // m
    product_out_of_stock:
      "Le produit AliExpress est en rupture de stock ou n'est plus disponible", //  m
    order_canceled: 'The order has to be canceled:', // d
    order_no: 'Order No: ', // d
    ali_no: 'Ali No: ', // d
    income: 'Income：', // d
    check: 'Check', // d
    view: 'View', // d
    more: 'More', // d
    no_data: 'Your Notification List is Empty', // d
    no_data_tip:
      'We will notify you about changes to product(s) availability or price', // d
    at: 'le',
    not_available: 'Indisponible',
    sku: 'SKU',
    const_change: 'Changement Coût',
    got_it: "J'ai compris", //  m
    go_to_shopify: 'Go to shopify', // d
    stock: 'Stock',
    no_match: 'No Match', //  d
    check_product: 'Check product', // d
    copy_succ: 'Successfully copied', //  d
    copy_fail: 'copy failed', //  d

    ali_product: 'Produit AliExpress', //  add
    related_shopify_products: 'Produit(s) associé(s) dans la boutique Shopify :', //  add
    price: 'Prix', //  add
    compared_at_price: 'Prix comparé', //  add
    update_to_shopify: 'Mettre à jour sur Shopify', //  add
    variants_out_of_stock: 'Changement de SKU sur AliExpress', //  add
    variants_not_of_stock: 'Produit plus disponible',
    variants_out_zero_stock: `Le produit AliExpress est en rupture de stock ou n'est plus disponible`,
    related_shopify_products_length:
      'produit(s) Shopify mappé à ce produit AliExpress', //  add
    view_price: 'Voir prix', //  add
    view_variant: 'Voir variante', //  add
    offline_product:
      'Êtes-vous sûr de vouloir mettre ce produit hors-ligne? Si vous Confirmer, le produit ne sera plus visible sur votre boutique Shopify.', //  add
    store_not_available: `La boutique n'est plus disponible`,
    product_is_offline: 'Le produit est hors-ligne', //  add
    put_offline: 'Choisissez un produit à mettre hors ligne', //  add
    set_new_value: 'Définir Nouvelle Valeur', //  add
    multiply_by: 'Multiplier par', //  add
    enter_value: 'Entrer Valeur', //  add
    enter_multiplier: 'Entrer Multiplicateur', //  add
    change_all: 'Changer Tous', //  add
    mapping_changed:
      'Vous avez changé le mapping par défaut, le coût du produit a changé', //  add
    product_deleted:
      "Le produit Shopify de ce fournisseur a été supprimé, impossible d'afficher des informations détaillées.", //  add
    store_removed:
      "La boutique Shopify de ce produit a été déconnecté de Dsers, impossible d'afficher des informations détaillées.",
    focus_on_orders: {
      p1: 'Vous avez ',
      p2: ' commandes annulées, veuillez vérifier'
    },
    view_canceled_orders: 'Voir commandes annulées',
    noti_tit:'Notificação',
    shopPrice:'Notez que la devise indiquée ici est la devise que vous avez définie dans DSers, et non votre devise Shopify',
    modalTitle:"Variante(s) en rupture de stock",
    modalText:"Voulez-vous vraiment supprimer les notifications? Elles seront supprimés de votre liste de notification.",
    filter_variant_out_of_stock:'Variante(s) en rupture de stock',
    cancelOrder:'Commande annulée',
    clearFilter:'EFFACER LES FILTRES',
    filterTitle:'Filtrer Notification',
    defaultSupplier:'Fournisseur par Défaut',
    secondSupplier:'Fournisseur Suppléant',
    allNotification:'Voir toutes les notifications',
    mappingPlaceholder:'Choisissez un produit à re-cartographier',
    copyTitle:"Titre de la copie",
    copied:'Titre Copié',
    filter:"Filtrer",
    product_not_found:'Product not found',
    product_out_of_stock_tip:'Product out of stock',
    get_product_err: `Erreur lors de l'obtention d'informations sur le produit, si vous souhaitez en savoir plus, , veuillez <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">nous contacter</a>`,
    get_product_err_2: `Une erreur s'est produite lors de l'obtention des informations sur le produit, veuillez réessayer plus tard. Si vous souhaitez en savoir plus, veuillez <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">nous contacter</a>`,
    no_support_mapping: `La méthode de mapping du produit n'étant pas prise en charge, les informations détaillées ne peuvent pas être affichées. Si vous souhaitez en savoir plus,  veuillez <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">nous contacter</a>`,
    mapping_trouble: `Les informations détaillées ne peuvent pas être affichées en raison d'un problème avec le SKU ou le mapping du produit. Si vous souhaitez en savoir plus, veuillez <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">nous contacter</a>`,
    product_sku_change: `Les détails ne peuvent pas être affichés en raison d'un changement de SKU de l'article. Si vous souhaitez en savoir plus,  veuillez <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">nous contacter</a>`
  }
};
