import React from 'react';

import MessageCard from './MessageCard';
import styles from './BotMessage.less';

const BotMessage = ({ children, showAvatar = false }) => {
  return (
    <div className={styles.sessionLeft}>
      <div className={styles.main}>
        {showAvatar ? <span className={styles.avatar} /> : null}
      </div>
      <div className={styles.side}>
        <MessageCard>{children}</MessageCard>
      </div>
    </div>
  );
};

export default BotMessage;
