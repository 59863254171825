import React, {useState,useEffect} from 'react';

import styles from './MessengerButton.less';

import { Popover } from 'antd';
import faceLink from '../../../assets/faceLink.png';
import emailLink from '../../../assets/emailLink.png';
import { getSuperSetting } from '../../../services/global';
function MessengerButton() {
  const [settingInfo, setSettingInfo] = useState({
    isDisplayMessenger:true    // 默认展示 
  });
  const MessageContent = (
    <div className={styles.messContent}>
      {settingInfo && settingInfo.isDisplayMessenger && (<a
        href="https://www.messenger.com/t/DSersOfficial" dd="src"
        target="_blank"
        rel="noopener noreferrer"
        track-type="helpCenter"
        track-name="openFbMessenger"
      >
        <img src={faceLink} alt="" />
        <span>Live Chat</span>
      </a>)}
      <a
      href="mailto:support@dserspro.com"
      target="_blank"
      >
       <img src={emailLink} alt="" />  
        
        <span>Emall</span>
      </a>
    </div>
  );
  const getSettingInfo = async () => {
    const res = await getSuperSetting();
    if (res.settingMap) {
      setSettingInfo(res.settingMap);
    }
  }
  useEffect(() => {
    getSettingInfo();
  },[]);
  return (
    <div className={styles.messengerOuter}>
      <Popover
        placement="bottomRight"
        getPopupContainer={triggerNode => triggerNode.parentNode}
        overlayClassName={styles.messageEnter}
        content={MessageContent}
        trigger="click"
        arrowPointAtCenter
      >
        <span className={styles.messengerBtn} />
      </Popover>
    </div>
  );
}

export default MessengerButton;