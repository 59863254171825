export default {
  guidance: {
    welcome: 'Bienvenue sur DSers',
    need_steps: 'Complétez les <i>4</i> étapes pour être prêt',
    start_tutorial: 'Commencer tutorial',
    before_starting: `Avant de commencer,<br />
                      assurez-vous que la fonction de traitement automatique de Shopify est désactivé.<br />
                      Autrement, vous ne pourrez pas traiter vos commandes avec DSers.<br />
                      <i>Rendez-vous sur Shopify - Paramètres - Passage à la caisse</i>.`,
    previous: 'Précédent',
    next: 'Suivant',
    start: 'Commencer',
    link_to_more_stores: 'Lier plus de boutiques',
    bind_ali_over: 'Compte AliExpress sur Liste Blanche',
    link_to_ae: 'Lier à AliExpress',
    home: 'Accueil',
    my_product: 'Mes Produits',
    setting: 'Réglages',
    shipping: 'Livraison',
    orders: 'Commandes',
    new_feature: 'Nouvelle Fonctionnalité',
    notification: 'Notification',
    blog: 'BLOG',
    order: 'Comment placer plusieures commandes avec DSers?',
    import: 'Comment importer des produits avec DSers?',
    step1: {
      title: 'D\'abord, veuillez lier DSers à votre boutique Shopify store',
      step1_dec:
        'Cliquez sur le bouton <i>Lier plus de boutiques</i> en haut de la page.',
      step2_dec:
        'Sélectionnez Shopify comme plateforme, puis entrez le nom de votre boutique pour la lier à DSers.'
    },
    step2: {
      title: 'Puis, liez DSers à votre compte AliExpress', //  m
      step1_dec:
        'Cliquez sur le bouton <i>Lier à AliExpress</i> en haut de la page.',
      step3_dec:
        'Postuler à la Liste Blanche AliExpress. Vous obtiendrez des privilèges et pourrez placer des commandes groupées et réduire le taux d\'échec des commandes.'
    },
    step3: {
      title: 'Ensuite, mapper vos produits à un fournisseur AliExpress', // m
      dec:
        'Cliquez sur le bouton à droite de la carte du produit pour mapper le(s) fournisseur(s) AliExpress à votre produit Shopify. DSers pourra ensuite placer vos commandes.',
      map_multiple:
        'DSers vous permet de mapper plusieurs fournisseurs en fonction de:', // uuu
      map_from_to: '1. Pays de destination',
      advance: `Apprenez-en plus sur le Mapping <a
          href="https://help.dsers.com/advanced-mapping-for-shopify/"
          target="_blank"
        >Avancé</a>
        <a
          class={foreignLink}
          href="https://help.dsers.com/advanced-mapping-for-shopify/"
          target="_blank"
        >
        </a>`,
      upsell_promotion: '2. Mapping d\'offres',
      bogo_bundle: `Apprenez-en plus sur les <a
          href="https://help.dsers.com/create-buy-one-get-one-offers-for-shopify/"
          target="_blank"
        >BOGO</a>
        <a
          class={foreignLink}
          href="https://help.dsers.com/create-buy-one-get-one-offers-for-shopify/"
          target="_blank"
        >
        </a> et <a
          href="https://help.dsers.com/create-bundles-of-products-for-shopify/"
          target="_blank"
        >Bundle</a>
        <a
          class={foreignLink}
          href="https://help.dsers.com/create-bundles-of-products-for-shopify/"
          target="_blank"
        >
        </a>`
    },
    step4: {
      title: 'N\'oubliez pas de choisir vos méthodes de livraison', //  m
      dec: `Pour la dernière étape de la préparation, veuillez choisir vos méthodes de livraison par défaut dans Réglages - Livraison.
            Vous pouvez les sélectionner par Pays ou
            <a
              href="https://help.dsers.com/set-shipping-method-for-all-products-for-shopify/"
              target="_blank"
            >produit AliExpress.</a>
            <a
              class={foreignLink}
              href="https://help.dsers.com/set-shipping-method-for-all-products-for-shopify/"
              target="_blank"
            >
            </a> .`
    },
    step5: {
      title: 'Enfin, vous pouvez placer vos commande en groupe', //  m
      dec:
        'Sélectionnez toutes vos commandes dans l\'onglet Commande(s) à Placer puis cliquez le bouton Placer vers AliExpress.',
      tip:
        'Vérifiez les détails de vos commandes et méthode de livraison. Vous êtes prêt!'
    },
    after: {
      dec:
        'Après avoir réalisé le paiement, laissez DSers faire le reste.<br /> DSers <b>SYNCHRONISERA AUTOMATIQUEMENT</b> pour vous',
      p1:
        'Synchronisation automatique du statut des commandes et des numéros de suivi depuis ALiExpress vers DSers toutes les 12h.',
      p2:
        'Synchronisation automatique du statut des commandes et des numéros de suivi depuis DSers vers Shopify 1-2h après <br /> synchronisation depuis AliExpress.',
      p3:
        'Déclenchement automatique de l\'email de Confirmation d\'envoi de Shopify pour votre client.'
    },
    actions: {
      title:
        'Les actions suivantes empêcheront DSers de traiter ou <br /> synchroniser vos commandes. Veuillez:',
      do_not1:
        '<i>NE PAS</i> traiter manuellement vos commandes sur Shopify ou DSers si vous souhaitez les <br /> traiter avec DSers.',
      do_not2: '<i>NE PAS</i> activer la fonction de traitement automatique sur Shopify.',
      do_not3: '<i>NE DEMANDER PAS</i> le traitement sur Shopify'
    },
    get_ebook:
      'Obtenez votre Ebook gratuit ou <br/> contacter le service client ici', // m
    check_again: 'Regardez encore ce tutoriel ici', //  a
    confused: 'Si vous avez des questions sur<br /> l\'utilisation de DSers', // a
    guidance_again: 'Regardez encore les videos tutoriellles ici',
    total_sales:'Total Sales',
    shopify_sales:'Shopify Sales',
    ali_orders:'Ali Orders',
    supplier_cost:'Supplier Cost'
  }
};
