export default {
    onBoarding: {
        selectPlatfrom:"Seleziona la tua piattaforma",
        selectPlatfromTitle:'Seleziona la piattaforma del negozio che vuoi collegare.',
        linkShopify:'Collega un negozio Shopify',
        linkWoo:'Collega un negozio WooCommerce',
        linkWix:'Collega un negozio Wix',
        linkCsv:'Usa i file CSV da altre piattaforme',
        logout:'Esci',
        linkAeTitle:'Collega ad AliExpress'
    }
}