export default {
  tmallTestExpress: {
    title:"Receba amostras grátis da Tmall",
    desc:"Para receber amostras grátis do Tmall, preencha corretamente o endereço de entrega.",
    confirmText:"Receba grátis",
    cancelText:"Eu não preciso disso",
    form: {
      contact_name: 'Nome do contato',
      tel: 'Telefone',
      email: 'Email',
      company: 'Empresa',
      country: 'País',
      address: 'Endereço',
      province: 'Província',
      city: 'Cidade',
      post_code: 'CEP'
    }
  }
};
