import jsCookie from 'js-cookie';
import { getBannerStatus } from '../components/BeforeMigration/service';

const mvpBffHost =
  process.env.BUILD_TARGET === 'prod'
    ? 'https://bff-api-gw.dsers.com'
    : 'https://bff-api-gw-test.dsers.com';

const mvpDevSiteHost =
  process.env.BUILD_TARGET === 'prod'
    ? 'https://bff-api-gw.dsers.dev'
    : 'https://bff-api-gw-test.dsers.dev';

export const mvpApplicationSite =
  process.env.BUILD_TARGET === 'prod'
    ? 'https://www.dsers.com/application/'
    : 'https://dev.dsers.com/application/';

export const mvpAccountSite =
  process.env.BUILD_TARGET === 'prod'
    ? 'https://accounts.dsers.com/accounts/'
    : 'https://accounts_dev.dsers.com/accounts/';

export const mvpDeveloperSite =
  process.env.BUILD_TARGET === 'prod'
    ? 'https://www.dsers.dev/management/'
    : 'https://dev.dsers.dev/management/';

export const openAPIShopifyAppHost = process.env.BUILD_TARGET === 'prod'
  ? 'https://shopify-api-gw.dsers.com/'
  : 'https://shopify-api-gw-test.dsers.com/';

export const getSession = () => jsCookie.get('session_id');

export const getAuthHeaderValue = () => `Bearer ${getSession()}`;

export const getSessionState = () => jsCookie.get('state');

export const removeSessionInfo = () => {
  jsCookie.remove('session_id');
  jsCookie.remove('state');
};

export const getMVPUserInfo = async () => {
  if (!getSession()) return;
  try {
    const res = await (
      await fetch(mvpBffHost + `/account-user-bff/v1/users/info`, {
        headers: { Authorization: getAuthHeaderValue() }
      })
    ).json();
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const loginToMvpAccount = async ({
                                          email,
                                          password,
                                          ...restParams
                                        } = {}) => {
  console.log(email, password, restParams);
  try {
    let res = await (
      await fetch(mvpBffHost + `/account-user-bff/v1/users/login`, {
        body: JSON.stringify({ email, password }),
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        method: 'post',
        credentials: 'include'
      })
    ).json();
    return res;
  } catch (error) {
  }
};

export const sendNewPlatformResetPassEmail = async ({email}) => {
  try {
    let res = await (
      await fetch(mvpBffHost + `/account-user-bff/v1/users/email/password/reset/send`, {
        body: JSON.stringify({ email }),
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        method: 'post',
        credentials: 'include'
      })
    ).json();
    return res;
  } catch (error) {
  }
}

export const syncMvpLoginStatusToDevSite = async () => {
  try {
    let res = await (
      await fetch(
        mvpDevSiteHost + `/account-user-bff/v1/users/session/auto/login`,
        {
          body: JSON.stringify({
            session: getSession(),
            state: getSessionState()
          }),
          headers: {
            'Content-Type': 'application/json; charset=utf-8'
          },
          method: 'post',
          credentials: 'include'
        }
      )
    ).json();
    return res;
  } catch (error) {
  }
};

export const logoutMvpAccount = async () => {
  if (!getSession()) {
    return;
  }
  try {
    let res = await (
      await fetch(mvpBffHost + `/account-user-bff/v1/users/logout`, {
        headers: {
          Authorization: getAuthHeaderValue(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        method: 'post',
        credentials: 'include'
      })
    ).json();
    return res;
  } catch (error) {
    console.log(error);
  }

  try {
    removeSessionInfo();
  } catch (error) {
  }
};

const getShopifyOrWixToken = () =>
  localStorage.getItem('dser-pro-authority') ||
  localStorage.getItem('authority_token');

export const getOldUserInfo = async () => {
  if (!getShopifyOrWixToken()) return;
  try {
    let res = await (
      await fetch(window.DSERS_API_CONFIG.HOST + `/api/v1/users/info`, {
        headers: { Authorization: getShopifyOrWixToken() }
      })
    ).json();

    if (res && res.code === 205 && res.token) {
      res = await (
        await fetch(window.DSERS_API_CONFIG.HOST + `/api/v1/users/info`, {
          headers: { Authorization: res.token }
        })
      ).json();
    }

    if (res.code === 2000) return res;
    return;
  } catch (error) {
    console.log(error);
  }
};

export const getOldUserPlatform = async () => {
  if (!getShopifyOrWixToken()) return;
  try {
    let res = await (
      await fetch(
        window.DSERS_API_CONFIG.HOST + `/uaa/api/1.0/user/action?action_type=1`,
        {
          headers: { Authorization: getShopifyOrWixToken() }
        }
      )
    ).json();

    if (res && res.code === 205 && res.token) {
      res = await (
        await fetch(
          window.DSERS_API_CONFIG.HOST +
          `/uaa/api/1.0/user/action?action_type=1`,
          {
            headers: { Authorization: res.token }
          }
        )
      ).json();
    }
    if (res.code === 2000) return res?.data?.action_operation;
    return;
  } catch (error) {
    console.log(error);
  }
};

export const setOldUserPlatformToWix = async () => {
  if (!getShopifyOrWixToken()) return;
  try {
    let res = await (
      await fetch(window.DSERS_API_CONFIG.HOST + `/uaa/api/1.0/user/action`, {
        headers: {
          Authorization: getShopifyOrWixToken(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        method: 'put',
        body: JSON.stringify({ action_operation: 'wix', action_type: 1 })
      })
    ).json();

    if (res && res.code === 205 && res.token) {
      res = await (
        await fetch(window.DSERS_API_CONFIG.HOST + `/uaa/api/1.0/user/action`, {
          headers: {
            Authorization: res.token,
            'Content-Type': 'application/json; charset=utf-8'
          },
          method: 'put',
          body: JSON.stringify({ action_operation: 'wix', action_type: 1 })
        })
      ).json();
    }

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getLoginInfo = async () => {
  return Promise.allSettled([
    getMVPUserInfo(),
    getOldUserInfo(),
    getOldUserPlatform(),
    getBannerStatus()
  ]).then(([mvpUserInfoRes, oldUserInfoRes, oldUserPlatformRes, mgrStatusRes]) => {
    // console.log(mvpUserInfo, oldUserInfo, oldUserPlatform);
    const mvpUserInfo = mvpUserInfoRes?.value?.data || {};
    const oldUserInfo = oldUserInfoRes?.value?.data || {};
    const oldUserPlatformSelect = oldUserPlatformRes?.value || '';
    const mgrStatus = mgrStatusRes?.value?.data?.status || '';

    const loginInfo = {
      // 登录了mvp账号
      mvpUser: !!mvpUserInfoRes?.value?.data,
      // mvp账号开发者权限
      mvpRoleDeveloper: !!mvpUserInfo.roles?.includes?.(
        'ROLE_TYPE_PLATFORM_DEVELOPER'
      ),
      // mvp账号应用权限
      mvpRoleDropshiper: !!mvpUserInfo.roles?.includes?.(
        'ROLE_TYPE_PLATFORM_DROP_SHIPPER'
      ),
      // 登录了老平台账号
      oldUser: !!oldUserInfo.user_id,
      // 老平台账号是woo账号
      oldWooUser:
        (`${oldUserInfo.status}`.slice(2, 3) === '1' ||
          oldUserPlatformSelect === 'woocommerce') &&
        !!oldUserInfo?.woo_stores?.length,
      // 老平台账号是csv账号
      oldCsvUser:
        `${oldUserInfo.status}`.slice(3, 4) === '1' ||
        oldUserPlatformSelect === 'csv',
      // 老平台账号是shopify账号
      oldShopifyUser:
        !!oldUserInfo.stores?.length || oldUserPlatformSelect === 'shopify',
      // 老平台账号是wix账号
      oldWixUser: oldUserPlatformSelect === 'wix',
      mgrStatus: mgrStatus
    };
    return loginInfo;
  });
};

export const getRedirectToOpenAPIRegister = async () => {
  try {
    let res = await (
      await fetch(mvpBffHost + `/account-user-bff/switch/register`, {
        headers: {
          // Authorization: getAuthHeaderValue(),
          'Content-Type': 'application/json; charset=utf-8'
        },
        method: 'get',
        credentials: 'include'
      })
    ).json();
    return !!res?.data?.isOpen;
  } catch (error) {
    console.log(error);
  }
};

// 将shopify老平台授权参数置换为新老平台通用的授权key
export const exchangeShopifyAuthParamsToAuthKey = async paramsString => {
  try {
    let res = await (
      await fetch(
        window.DSERS_API_CONFIG.HOST +
        `/api/v1/auth/shopify/key?${paramsString}`,
        {
          headers: {
            'Content-Type': 'application/json; charset=utf-8'
          },
          method: 'get'
        }
      )
    ).json();

    if (res.code === 2000 && res.data && res.data.key) {
      return res.data.key;
    }
  } catch (e) {
    console.log(e);
  }
};

// 通过key走新平台shopify授权流程
export const getOpenAPIShopifyAppKeyRedirectUrl = (key) => {
  return `${openAPIShopifyAppHost}shopify-user-bff/v1/shopify/auth/code?key=${encodeURIComponent(key)}`;
};

// 通过shopify redirect参数走新平台授权redirect
export const getOpenAPIShopifyRedirectUrl = (params) => {
  return `${openAPIShopifyAppHost}shopify-user-bff/v1/shopify/auth/redirect?${params}`;
};