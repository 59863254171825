import getUserInfo from 'utils/getUserInfo';
import getAdminRole from 'utils/getAdminRole';

/**
 * 灰度测试相关，给定用户可见数据
 * return value: -1 可以访问，0 无权访问
 */
const checkUserForBargain = (menu, routerData) => {
  return -1;
  const userInfo = getUserInfo();
  const hasBargainAccess = getAdminRole();

  if (hasBargainAccess) {
    return -1;
  }

  if (userInfo && userInfo.spread) {
    return -1;
  }

  const bargainIndex = menu.findIndex(i => i.path === '/find_suppliers');
  if (bargainIndex > -1) {
    menu.splice(bargainIndex, 1);
  }
  // eslint-disable-next-line
  delete routerData['/find_suppliers'];

  return 0;
};

export default checkUserForBargain;
