import React, { useEffect, useState } from 'react';
import { connect } from 'dva';
import { Alert } from 'antd';
import style from './AeCancelTip.less';
import intl from 'react-intl-universal';

function AeCancelTip({ aeCancelOrderTip }) {

    const [visible, setVisible] = useState(true)

    useEffect(() => {
        if (aeCancelOrderTip) {
            if (localStorage.getItem('AeCancelTipHidden')) {
                setVisible(false)
            } else {
                setVisible(true)
            }
        } else {
            setVisible(false)
        }
    }, [aeCancelOrderTip])

    const handleClose = () => {
        setVisible(false)
        localStorage.setItem('AeCancelTipHidden', true)
    }

    return (
        visible ?
            <p className={style.productTip}>
                <i class="material-icons notranslate">error</i>{' '}
                {intl.get('order.aeCancelTip')}
                <i onClick={handleClose} class="material-icons notranslate">close</i>{' '}
            </p>
            : null
    )
}

export default connect(({ order }) => ({
    aeCancelOrderTip: order.aeCancelOrderTip
}))(React.memo(AeCancelTip));