
    export default 
    {
    "help_center": {
        "help_center": {
            "fixed_header": "Centro di Aiuto",
            "latest_news": "Ultime notizie",
            "load_more": "Carica di più",
            "online_chat": "Chat online su Messenger",
            "online_time": "Orario online: 7:00AM-0:00AM(UTC+8)",
            "work_days": "Giorni lavorativi: 7 giorni",
            "message_us": "Mandaci un messaggio",
            "email_report": "Email: riporta un problema",
            "email_report_desc": "La tua email riceverà una risposta entro <span style=\"font-weight: 700\">1 giorno lavorativo</span>. Se non hai ricevuto una risposta da noi, assicurati di aver controllato la casella Spam e che dsers.com non sia nel tuo elenco di contatti non autorizzati.",
            "product_sourcing": "Email: Provenienza del prodotto",
            "product_sourcing_desc": "La tua email riceverà risposta entro <span style=\"font-weight: 700\">1 giorno lavorativo</span>. Per favore facci sapere il tuo nome account DSers, l'url del negozio, il link del prodotto AliExpress come referenza, il metodo di spedizione preferito, e il volume di vendite stimato degli ultimi 7 giorni.",
            "blog_in_en": "Blog in inglese",
            "everything_about_ds": "Tutto su DSers",
            "blog_in_fr": "Blog in francese",
            "coming_soon": "Presto in arrivo…",
            "blog_in_pt": "Blog in portoghese",
            "youtube_channel": "Canale YouTube",
            "check_ds_youtube": "Dai un'occhiata al canale YouTube DSers"
        },
        "entrance": {
            "get_support": "Ottieni supporto",
            "online_chat": "Chat online"
        },
        "question_modal": {
            "title": "Come possiamo aiutarti?",
            "tell_tip": "Dicci che tipo di problema stai riscontrando",
            "tell_select_placeholer": "Seleziona dalla lista",
            "our_suggestions": "Ecco i nostri suggerimenti",
            "useful_tip": "Ecco alcune dritte che potrebbero esserti utili:",
            "suggesttions_default_tip": "Ti forniremo suggerimenti in base al problema che hai <br /> selezionato. Se non ti sono utili, puoi comunque ottenere aiuto online cliccando sul pulsante sottostante.",
            "get_online_help": "Ottieni aiuto online"
        },
        "detail": {
            "by_ds_team": "dal team DSers",
            "ds_users": "Salve cari utenti",
            "so_much": "Grazie mille per lavorare con Dsers.<br /> Speriamo che la vostra giornata stia andando bene.",
            "to_contact": "Se avete qualsiasi domanda a riguardo, <br />sentitevi liberi di contattarci",
            "happy_with_ds": "Buona distribuzione diretta con Dsers.",
            "best_regards": "Distinti saluti, <br /> il team di DSers"
        },
        "support_here": "Ottieni supporto online qui"
    }
}