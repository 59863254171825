
    export default 
    {
    "product_edit": {
        "repeated_tip": "Die Farben und/oder Größen des Produkts werden wiederholt, bitte ändern Sie sie",
        "picture": "Bild",
        "compare_price": "Preiswert<br /> vergleichen",
        "delete_variant_tip": "Sind Sie sicher, dass Sie diese Variante löschen möchten?",
        "delete_selected_variant_tip": "Sind Sie sicher, dass Sie die ausgewählten Varianten löschen möchten?",
        "new_compare_price": "Neuer Vergleichspreis",
        "new_price": "Neuer Preis",
        "new_stock": "Neuer Bestand",
        "open_on_supplier": "CLIQUE PARA SABER MAIS",
        "from_other": "von anderen",
        "seller_id": "Verkäufer-ID",
        "input_title": "Bitte Titel eingeben",
        "product_type": "Produkttyp",
        "collection": "Sammlung",
        "vendor": "Anbieter",
        "tags": "Schlagwörter",
        "no_edit": "Produkte, die in Shopify veröffentlicht sind, können nicht bearbeitet werden, bitte bearbeiten Sie sie direkt in Shopify.",
        "select_variant": "Variante auswählen",
        "none": "Keine",
        "description": "Beschreibung",
        "images": "Bilder <i>({selectImages}/{allImages})</i>",
        "shipping": "Versandinformationen",
        "shipping_info": "Sie können die {platform}-Versandkosten, die geschätzte Lieferzeit und die Verfügbarkeit der Sendungsverfolgung hier überprüfen, indem Sie ein Land auswählen, in das (und von dem) Sie versenden möchten. Die Versandinformationen werden NICHT an Shopify übermittelt, wenn Sie Ihren Artikel veröffentlichen. Dies dient nur dazu, Ihnen eine Schätzung der Versandkosten zu geben.",
        "cannot_recover": "Sie können gelöschte Produktvarianten nicht wiederherstellen, möchten Sie fortfahren?",
        "download_pic": "Klicken Sie hier, um alle Bilder herunterzuladen",
        "delete_pic_tip": "Do you want to delete the image(s)? The deleted image(s) will not be uploaded to the Media section in the Shopify product.",
        "delete_picss_tip": "Do you want to delete all the images? No images will be uploaded to the Media section in the Shopify product.",
        "back": "Zurück",
        "product": "Produkt",
        "from_aliexpress": "von AliExpress",
        from_tmall:'von Tmall',
        "title": "Titel",
        "variants": "Varianten <i>({allvariants})</i>",
        "all": "Alle",
        "action": "Aktion",
        "sku": "Sku",
        "cost": "Preis",
        "price": "Preis Wert",
        "shopify_stock": "Bestand auf<br /> Shopify",
        "stock": "Lagerbestand auf<br /> Lieferanten",
        replace_stock:'Lagerbestand auf <br /> {platform}',
        "change_price": "Preis ändern",
        "change_compare_at_price": "Vergleichen mit Preis ändern",
        "remove_sku": "SKU entfernen(s)",
        "change_stock": "Lagerbestand ändern",
        "option_repeated_tip": "Die Farben und/oder Größen des Produkts wiederholen sich, bitte ändern Sie sie",
        "sku_repeated_tip": "Die SKU muss für jede Variante unterschiedlich sein. Bitte ändern Sie sie.",
        "option_name_invalid": "Der Name der Option darf nicht leer sein",
        "option_value_invalid": "Der Optionswert darf nicht leer sein",
        "option_name_repeat": "Der Name der Option darf nicht wiederholt werden",
        "option_name_slash": "Der Optionsname darf kein  /  enthalten",
        "option_sku_invalid": "Der SKU-Wert darf nicht leer sein",
        "sku_count_invalid": "Bitte behalten Sie mindestens eine SKU",
        "overrun_warning": "Sie haben das Tageslimit für den Zugriff auf die Produktinformation überschritten",
        "push_description": "Klicken Sie <a target=\"_blank\" href=\"/app/settings\">hier</a> um den Inhalt der Beschreibung beim Schieben des Produkts einzustellen.",
        "specifications": "SPEZIFIKATIONEN",
        "overview": "ÜBERBLICK",
        "stock_on_shopify": "Stock on <br /> Shopify",
        "change_image": "BILD ÄNDERN",
        "organization": "Organisation",
        "download_all_img": "Downloaden",
        "delete_img": "Delete",
        "img_des": "Die hier angekreuzten Bilder werden in den Medien der Shopify-Produkte angezeigt",
        "not_select_main_img_des": "If you don’t select the cover picture here, the first picture you check here will be used as the cover picture for this Shopify product.",
        "deleteImage": "Delete Image",
        "products": "Produkte",
        "uncheckedAlert": "Wenn Sie hier keine Bilder auswählen, werden keine Bilder zu Media im Shopify-Produkt hochgeladen.",
        "try_again_collections_text": "We couldn't get your collections data because the request timeout, please click ",
        "try_again_collections_fun": "Refresh",
        "checkImages": "Bilder <i>({allImages})</i>",
        "shipping_cost": "Kosten für <br /> den Versand",
        "select_province_pleaseholder": "Bitte wählen Sie die Provinz/Stadt aus",
        "ship_from_text": "Versand von:",
        "ship_to_text": "Versand nach:",
        "product_edit_price_tip": "Das Währungssymbol hängt von den Einstellungen Ihres Shopify-Shops nach der Veröffentlichung in Shopify ab. Bitte stellen Sie den Preis/Vergleichspreis hier manuell ein, wenn Sie ihn benötigen.",
        "shopify_stock_tip": "Hier können Sie die Menge der Bestände einstellen, die Sie an Shopify weitergeben möchten. Sie wird nicht aktualisiert, wenn sich der Lieferantenbestand auf {platform} ändert.",
        "aliexpress_stock_tip": "DSers aktualisiert den AliExpress-Bestand für Produkte auf der Importliste automatisch alle 2 Stunden. Der Bestand kann hier nicht manuell geändert werden",
        "tmall_stock_tip": "DSers aktualisiert automatisch den Tmall-Bestand für Produkte auf der Importliste. Der Bestand kann hier nicht manuell geändert werden.",
        product_stock_tip:'DSers aktualisiert die Bestände für die Produkte auf Mein Produkt. Der Bestand kann nicht manuell geändert werden. Wir aktualisieren nicht die Bestände in der Kategorie "Erweitert/BOGO/Bundle".',
        shopify_product_stock_tip:'Lagerbestand wird nur mit Shopify synchronisiert und nicht entsprechend der Produktaktualisierungen, es sei denn, die Funktion "Automatische Aktualisierung" ist aktiviert.',
        download_img_fail_tip:'Image download failed, please try again later',
    }
}