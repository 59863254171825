/**
 * Content:升级套餐  && 定制化套餐 
 * Author:GaoHuan 
 */

import React, { useEffect, useState } from "react";
import { Modal, Button, Tooltip, Icon } from 'antd';
import { connect } from 'dva';
import intl from 'react-intl-universal';
import { CommonDataCollect } from 'utils/utils'
const CommonGuide = ({ plan, update }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentMessage, setCurrentMessage] = useState([])
  useEffect(() => {
    if (!["", undefined, null].includes(update)) {
      setCurrentMessage(intl.get('guide.CommonGuide').filter((item => item.type === plan))[0]);
      if (!currentMessage) {
        window.location.href = '/app/pricing?select=1';
      }
      setShowModal(true)
    }
  }, [update])
  return (
    currentMessage &&
    <Modal
      visible={showModal}
      width={560}
      zIndex={3000}
      footer={
        <div>
          <Button
            type="primary"
            onClick={() => {
              if(currentMessage.type === 3){
                window.open('https://www.messenger.com/t/DSersOfficial') 
              }else{
                if(update.indexOf('&') > 0){
                      let Custion_Name = update.split('&')[0];
                      CommonDataCollect({
                          action: Custion_Name,
                          event_type:'overlimit_pop'
                        });
                }
                setTimeout(()=>{
                  window.location.href = '/app/pricing?select=1'
                },800)
                
              }
            }}
          >
            {currentMessage.mainBtn}
          </Button>
        </div>
      }
      title={currentMessage.title}
      onCancel={() => {
        setShowModal(false)
      }}
    >
      <div>
        {currentMessage.content}
        <Tooltip
          zIndex='3001'
          title={currentMessage?.addContent}
        >
          <span style={{ marginLeft: '8px', opacity: '0.5', cursor: 'pointer' }}><Icon type="question-circle" /></span>
        </Tooltip>
      </div>
    </Modal >)
}
export default React.memo(CommonGuide)