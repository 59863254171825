
    export default 
    {
    "register": {
        "title": "Ein DSers-Konto erstellen",
        "email": "E-Mail",
        "password": "Kennwort",
        "get_started": "KONTO ERSTELLEN",
        "input_your_email": "Bitte geben Sie Ihre E-Mail-Adresse im richtigen Format ein.",
        "input_password": "Bitte geben Sie 6 bis 20 Zeichen ein.",
        "wrong_email_format": "falsches E-Mail-Format",
        "password_tip": "Bitte verwenden Sie ein Passwort mit mindestens 6 Zeichen. Verwenden Sie kein Passwort, das leicht geknackt werden kann.",
        "contact_us": "KONTAKT",
        "hello_shopify": "Hallo, Shopify",
        "install_dsers_to_shopify": "DSers zu Shopify installieren",
        "password_ok": "Sicherheit：Hoch",
        "password_warning": "Sicherheit：Mittel",
        "password_error": "Sicherheit：Niedrig",
        "privacy": "Indem Sie auf Konto erstellen klicken, erklären Sie sich mit unserer <a href= \"/privacy\" target=\"_blank\">DATENSCHUTZRICHTLINIE einverstanden</a>.",
        "result": {
            "resend_successed": "Erneut senden erfolgreich",
            "resend_failed": "Erneut senden fehlgeschlagen",
            "title": "Massen- und Mehrfachbestellungen mit DSers bearbeiten",
            "your_account": "Ihr Konto:",
            "register_succ": "wurde erfolgreich registriert.",
            "activation_email": "Bitte überprüfen Sie Ihr E-Mail-Postfach<br /> und klicken Sie auf den Link,<br /> um Ihr Konto innerhalb von 10 Minuten zu aktivieren.",
            "check_email": "E-Mail prüfen",
            "resend_email": "E-Mail erneut senden",
            "go_sign": "Zur Anmeldung gehen oder sich anmelden",
            "about_email": "Sie haben keine E-Mail erhalten? Klicken Sie <a href=\"https://help.dsers.com/confirmation-email-not-received-for-shopify/\" target=\"_blank\">hier</a>, um genauere Erklärungen zu erhalten!"
        },
        "password_error_long": "Das Passwort muss zwischen 8 und 16 Zeichen lang sein.",
        "password_error_format": "Das Passwort enthält mindestens 1 Großbuchstaben, 1 Kleinbuchstaben und 1 Zahl",
        "password_not_match_twice": "Passwörter dürfen nicht zweimal übereinstimmen",
        "accountSuccess": "Finish registration",
        "haveAccount": "Haben Sie ein Konto?",
        "signIn": "ANMELDEN"
    }
}