
    export default 
    {
    "import_list": {
        "title": "Lista de importación",
        "dec": "Aquí puedes importar y gestionar tus productos de AliExpress antes de publicarlos en tu tienda de Shopify. Puedes publicar un producto en diferentes tiendas y añadir múltiples etiquetas a cada producto. Haz clic <a href={href} target=\"_blank\">aquí</a> para saber más. Después de importar productos a Dsers, sólo el precio y el inventario de AliExpress del producto se actualizarán automáticamente. Si has importado un producto durante mucho tiempo, para asegurar la consistencia de la información del producto y del proveedor, se recomienda que verifiques la información del proveedor antes de empujar este producto.",
        "search_ali_placeholder": "Importar productos de AliExpress por URL o buscar productos aquí",
        search_tm_placeholder:'Importar productos de Tmall por URL o buscar productos aquí',
        "add_tags": "Apply DSers Tags",
        "filter": "Filtrar",
        "filter_product": "Filtrar productos",
        "filter_by_stores": "Filtrar por tiendas",
        "filter_by_tags": "Filtrar por etiquetas",
        "tag_management": "Create DSers Tags",
        "pagefly": "¿Quieres conseguir más ventas? Prueba PageFly para crear páginas de venta de productos de alta tasa de conversión.",
        "pushed_to": "Empujado a",
        "store_s": "tienda(s)",
        "add_manage_tags": "Create and manage DSers tags",
        "add_manage_tags_tip": "You can create or delete tags to group your products. Click <a href={ href } target=\"_blank\">here</a> to get more information.",
        "add_new_tags": "Añadir nuevas etiquetas",
        "push_modal_title": "Push Products",
        "push_modal_desc": "Select the store(s) you want to push your products to and check the boxes below to select the rules to apply to your products.",
        "no_store": "No hay tiendas",
        "pricing_rule_tip": "Si has editado ",
        "pricing_rule_tip_1": "manualmente el precio ",
        "pricing_rule_tip_2": "del producto en Dsers, por favor desmarca esta casilla.",
        "pricing_rule_close_tip": "Nuestro sistema ha detectado que has editado manualmente el precio de este producto en Dsers. Como resultado, el ",
        "pricing_rule_close_tip_1": " reglamento de precios ",
        "pricing_rule_close_tip_2": "no se aplicará a este producto. Si deseas utilizar el reglamento de precios en lugar de los precios editados, por favor marca esta casilla.",
        "from_oberlo_product_tip": "<text>Hemos detectado que has empujado un producto a Shopify que fue migrado de Oberlo a DSers.</text> Como resultado, la",
        "from_oberlo_product_tip_1": " regulación de precios ",
        "from_oberlo_product_tip_2": " no se aplicará a este producto. Si deseas utilizar la regulación de precios en lugar de los precios editados, marca esta casilla.",
        "select_pricing_rule_mean_tip": "Por favor, ten en cuenta que la normativa de precios incluye las plantillas de precios, la moneda y la asignación de céntimos. Si marcas esta casilla, la normativa de precios se aplicará a los precios de tus productos de acuerdo con las normas que hayas establecido en los ajustes.",
        "unselect_pricing_rule_mean_tip": "Nota: La normativa de precios incluye las plantillas de precios, la moneda y la asignación de centavos. Si desmarcas esta casilla, ninguna de estas tres funciones se aplicará a tus productos.",
        "product_s": "producto(s)",
        "link_to_shopify": "Link a Shopify",
        "no_product_tip": "Importar tu primer artículo",
        no_product_tip_1: `1. Tienes que ir a "<a href='/app/find_suppliers'>Buscar Proveedor</a>" y copiar la URL del artículo`,
        no_product_tip_2: '2. Pega la URL que copiaste en el campo de entrada de "Lista de Importación”',
        search_no_product_tip: 'No hay resultados de búsqueda, por favor, cambia los criterios de búsqueda y vuelva a intentarlo',
        "tag_manage_delete_tip": "¿Quieres eliminar esta etiqueta?<br/> Desaparecerá de todos los productos etiquetados.",
        "add_product_tip": "Por favor, vincula Dsers a una cuenta de AliExpress o no podrás importar productos.",
        "fetch_ae_prod_error": "La cantidad de productos que has importado a Dsers ha alcanzado el límite diario, por favor inténtalo de nuevo mañana",
        fetch_ae_prod_error1: `La cantidad de productos de AliExpress que has importado a DSers ha alcanzado el límite diario, por favor inténtalo de nuevo mañana.`,
        fetch_tm_prod_error1: `La cantidad de productos de Tmall que has importado a DSers ha alcanzado el límite diario, por favor, inténtalo de nuevo mañana.`,
        "ae_issue": "AliExpress está teniendo problemas, por favor inténtalo más tarde",
        "delete_product_tip": "¿Estás seguro de que quieres eliminar el(los) producto(s)? Se eliminará(n) de la lista de importaciones pero no de tu tienda de Shopify",
        "ae_prod_not_found": "El producto no está disponible en AliExpress, por favor importa otro producto.",
        "service_unavaliable": "La operación ha fallado debido a un problema de red, por favor inténtalo de nuevo.",
        "pushing": "El producto se está enviando",
        "push_success": "Producto enviado con éxito",
        "push_desc": "productos empujados",
        "push_desc_ok": "Empujado con éxito",
        "import_review": "Importar reseñas",
        "push_desc_fail": "No se ha podido enviar",
        "push_error_0": "El producto contiene más de 100 SKU",
        "push_error_1": "Se ha alcanzado el número máximo de creación de SKU en Shopify",
        "push_error_2": "La tienda de Shopify no está disponible",
        "push_error_3": "El token de la tienda ha caducado",
        "push_error_4": "La información de tu producto supera los 256KB",
        push_error_4_tip: `La información del producto incluye Título, Descripción, Imágenes, SKU, etc. Se recomienda que elimines parte de la información del producto antes de empujarlo`,
        "push_error_5": "El tiempo de espera de la solicitud ha expirado, por favor inténtalo de nuevo más tarde",
        "check_push": "Comprobar estado",
        "dec_change": "Aquí puedes importar y gestionar los productos de tus proveedores antes de publicarlos en tu tienda Shopify.",
        "dec_change_tit": "Puedes publicar un producto en diferentes tiendas y añadir múltiples etiquetas a cada producto. Después de importar productos a DSers, sólo el precio y el inventario de los productos del proveedor se actualizarán automáticamente en DSers. Si has importado un producto durante mucho tiempo, para asegurar la consistencia de la información y del proveedor, se recomienda comprobar la información del proveedor antes de empujar dicho producto.",
        "shopping_info": "Shipping info",
        "add_manage_tags2": "Apply DSers Tags",
        "add_management_tags": "You can apply tags to group your products.",
        "add_manage_tags_tip2": "You can add or delete tags to group your products.",
        "add_manage_tags_tip3": "You can filter your products by stores, tags and push status.",
        "push_success1": "Product(s) sent",
        "push_fail": "No se ha podido enviar",
        "check_detail": "Consultar el detalle del producto",
        "edit_detail": "Editar el detalle del producto",
        "delete_product": "Deleted Product",
        "addTag": "Create a new tag",
        "confim": "Confirm",
        "variants_limit": "Shopify daily variant creation limit reached. Please try again later & click <a href='{url}' target='_blank'>here</a> for more details.",
        "pushTitle_1": "Selección de tiendas",
        "pushTitle_2": "Preferencias",
        "pushTitle_3": "Empujar Detalles",
        "repeated_tag": "This tag already existed",
        "search_or_import": "Importar o buscar",
        filter_by_market_place:'Filtrar por Marketplace',
    }
}