import React, { useEffect } from 'react';

function DisableSearch(props) {
  // 取消输入搜索行为
  useEffect(() => {
    const selectInputDom = document.querySelector(
      '.hc_disable_input_select input'
    );
    if (selectInputDom) {
      selectInputDom.setAttribute('maxlength', 0);
    }
  }, []);

  return props.children;
}

export default DisableSearch;
