import React from 'react';
import { Layout, Modal } from 'antd';
import classes from './MigrationWaiting.less';
import contentPng from './images/content.png';
import useRequest from './useRequest';
import { getBannerStatus } from './service';
import GlobalHeader from '../GlobalHeader';
import intl from 'react-intl-universal';

export default function Index() {

  useRequest(getBannerStatus,(result)=>{
    // 迁移中
    if (result?.status === 'MIGRATION_STATUS_MIGRATING' && location.search?.includes('isAuth=1')) {
      handleOpen();
      return;
    }

    // 迁移完成
    if (result?.status === 'MIGRATION_STATUS_COMPLETED') {
      localStorage.removeItem('dser-pro-authority');
      localStorage.removeItem('authority_token');
      window.location.href =
        process.env.BUILD_TARGET === 'prod'
          ? 'https://www.dsers.com/application/'
          : 'https://dev.dsers.com/application/';
      return;
    }
  });

  function handleOpen() {
    Modal.info({
      icon: null,
      centered: true,
      title: intl.get('beforeMigration.notification'),
      content: intl.get('beforeMigration.twentyTen')
    });
  }

  return (
    <Layout className={classes.wrap}>
      <Layout.Header style={{ width: '100%', padding: 0 }}>
        <GlobalHeader showLogo showLanguage notNotice noSelectTags logoHref="/" />
      </Layout.Header>
      <Layout.Content className={classes.content}>
        <img src={contentPng} alt="" />
        <div className={classes.text}>
          {intl.get('beforeMigration.twentySeven')}
          <span className={classes.yellow}>  {intl.get('beforeMigration.twentyEight')}</span>
        </div>
        <div className={classes.text}>
          {intl.get('beforeMigration.twentyNine')}
        </div>
      </Layout.Content>
    </Layout>
  );
}
