import about from './it/about.js';
import archeved from './it/archeved.js';
import banner from './it/banner.js';
import bannerV2 from './it/bannerV2.js';
import bargain from './it/bargain.js';
import chore from './it/chore.js';
import combo from './it/combo.js';
import common from './it/common.js';
import completePassWord from './it/completePassWord.js';
import csv from './it/csv.js';
import duplicateOrderConfirm from './it/duplicateOrderConfirm.js';
import errorCashbackCode from './it/errorCashbackCode.js';
import footer from './it/footer.js';
import h5Callback from './it/h5Callback.js';
import fulfillmentCenter from './it/fulfillmentCenter.js';
import guidance from './it/guidance.js';
import h5LandModal from './it/h5LandModal.js';
import h5Redirect from './it/h5Redirect.js';
import header from './it/header.js';
import aeAccountDisconnect from './it/aeAccountDisconnect.js';
import home from './it/home.js';
import importList from './it/importList.js';
import impotList from './it/impotList.js';
import login from './it/login.js';
import linkStore from './it/linkStore.js';
import mapping from './it/mapping.js';
import market from './it/market.js';
import newFeature from './it/newFeature.js';
import noticePlanBanner from './it/noticePlanBanner.js';
import notification from './it/notification.js';
import order from './it/order.js';
import partner from './it/partner.js';
import paypal from './it/paypal.js';
import pricing from './it/pricing.js';
import product from './it/product.js';
import productEdit from './it/productEdit.js';
import menu from './it/menu.js';
import ad_1688 from './it/ad_1688.js';
import productSplit from './it/productSplit.js';
import publicIntl from './it/public.js';
import publicDocument from './it/publicDocument.js';
import rapport from './it/rapport.js';
import helpCenter from './it/helpCenter.js';
import register from './it/register.js';
import resetPassword from './it/resetPassword.js';
import reward from './it/reward.js';
import selectPlatform from './it/selectPlatform.js';
import sendResetEmail from './it/sendResetEmail.js';
import refreshMask from './it/refreshMask.js';
import settingSupply from './it/setting-supply.js';
import shopPush from './it/shopPush.js';
import supplierOptimizer from './it/supplierOptimizer.js';
import suppliers from './it/suppliers.js';
import support from './it/support.js';
import top from './it/top.js';
import topStores from './it/topStores.js';
import tracking from './it/tracking.js';
import whyDisconnect from './it/whyDisconnect.js';
import tutorialOverModal from './it/tutorialOverModal.js';
import productPool from './it/productPool.js';
import admitad from './it/admitad.js';
import setting from './it/setting.js';
import guide from './it/guide';
import migration from './it/migration';
import onBoarding from './it/onBoarding.js';
import tmallTestExpress from './it/tmallTestExpress';
import beforeMigration from './it/beforeMigration';


export default {
    ...beforeMigration,
    ...about,
    ...admitad,
    ...archeved,
    ...bannerV2,
    ...bargain,
    ...chore,
    ...combo,
    ...common,
    ...completePassWord,
    ...csv,
    ...duplicateOrderConfirm,
    ...errorCashbackCode,
    ...footer,
    ...fulfillmentCenter,
    ...guidance,
    ...h5Callback,
    ...h5LandModal,
    ...h5Redirect,
    ...header,
    ...helpCenter,
    ...home,
    ...importList,
    ...impotList,
    ...linkStore,
    ...login,
    ...mapping,
    ...market,
    ...menu,
    ...newFeature,
    ...noticePlanBanner,
    ...notification,
    ...order,
    ...partner,
    ...paypal,
    ...pricing,
    ...product,
    ...productEdit,
    ...productPool,
    ...productSplit,
    ...publicIntl,
    ...publicDocument,
    ...rapport,
    ...refreshMask,
    ...register,
    ...resetPassword,
    ...reward,
    ...selectPlatform,
    ...sendResetEmail,
    ...settingSupply,
    ...setting,
    ...shopPush,
    ...supplierOptimizer,
    ...suppliers,
    ...support,
    ...top,
    ...topStores,
    ...tracking,
    ...tutorialOverModal,
    ...whyDisconnect,
    ...banner,
    ...aeAccountDisconnect,
    ...ad_1688,
    ...guide,
    ...migration,
    ...onBoarding,
    ...tmallTestExpress
}
