
    export default 
    {
    "notification": {
        "notifications": "Notifiche",
        "mark_all_as_read": "Segna tutti come letti",
        "tip": "Riceverai solo le notifiche per i prodotti che sono stati ordinati in passato. Le notifiche su DSers saranno visualizzate 30 giorni",
        "regarding_to_aliexpress_statement": "Per quanto riguarda la dichiarazione di Aliexpress:",
        "product_price_has_changed": "Il prezzo del prodotto è cambiato",
        "product_out_of_stock": "Il prodotto AliExpress è esaurito o non più disponibile",
        "order_canceled": "L'ordine deve essere annullato:",
        "order_no": "Numero Ordine:",
        "ali_no": "Numero Ali:",
        "income": "Reddito:",
        "check": "Controlla",
        "view": "Vedi",
        "more": "Più",
        "no_data": "La tua lista di notifiche è vuota",
        "no_data_tip": "Ti informeremo sui cambiamenti della disponibilità o del prezzo del prodotto(i)",
        "at": "su",
        "not_available": "Non disponibile",
        "sku": "SKU",
        "const_change": "Cambiamento di costo",
        "got_it": "Ok, ho capito.",
        "go_to_shopify": "Vai a shopify",
        "stock": "Stock",
        "no_match": "Nessuna corrispondenza",
        "check_product": "Controlla il prodotto",
        "copy_succ": "Copiato con successo",
        "copy_fail": "copia non riuscita",
        "ali_product": "Ali prodotto",
        "related_shopify_products": "Prodotto/i correlato/i nel negozio Shopify :",
        "price": "Prezzo",
        "compared_at_price": "Confronta con il prezzo",
        "update_to_shopify": "Aggiorna a Shopify",
        "variants_out_of_stock": "Lo SKU di AliExpress è cambiato",
        "variants_not_of_stock": "Prodotto non più disponibile",
        "variants_out_zero_stock": "Variante(i) del prodotto AliExpress esaurita o non disponibile",
        "related_shopify_products_length": "Prodotto/i Shopify mappato/i a questo prodotto AliExpress",
        "view_price": "Vedi prezzo",
        "view_variant": "Vedi variante",
        "offline_product": "Sei sicuro di voler mettere questo prodotto offline? Se clicchi su Conferma, il prodotto non sarà più visibile sul tuo negozio Shopify.",
        "store_not_available": "Il negozio non è più disponibile",
        "product_is_offline": "Il prodotto è offline",
        "put_offline": "Scegli un prodotto da mettere offline",
        "set_new_value": "Imposta Nuovo Valore",
        "multiply_by": "Moltiplica Per",
        "enter_value": "Inserisci Valore",
        "enter_multiplier": "Inserisci Moltiplicatore",
        "change_all": "Cambia tutto",
        "mapping_changed": "Hai cambiato la mappatura di default, il costo del prodotto è cambiato",
        "product_deleted": "Il prodotto Shopify che utilizza questo fornitore è stato eliminato, non è possibile mostrare informazioni dettagliate.",
        "store_removed": "Il negozio Shopify a cui appartiene questo prodotto è stato disconnesso da DSers, non è possibile mostrare informazioni dettagliate.",
        "focus_on_orders": {
            "p1": "Hai",
            "p2": "ordini annullati, per favore controlla"
        },
        "view_canceled_orders": "Visualizza gli ordini annullati",
        "noti_tit": "Notifica",
        "shopPrice": "Note that the currency shown here is the currency you set in DSers, not your Shopify currency",
        "modalTitle": "Delete Notification(s)",
        "modalText": "Are you sure you want to delete the notification(s)? It will be removed from your notification list.",
        "allNotification": "Vedi tutte le notifiche",
        "notification_feature": "Funzione di notifica",
        "filter_variant_out_of_stock": "Variante/i esaurio/i",
        "cancelOrder": "Ordine cancellato",
        "clearFilter": "CANCELLA TUTTI I FILTRI",
        "filterTitle": "Filtra notifica",
        "defaultSupplier": "Fornitore di default",
        "secondSupplier": "Sostituisci fornitore",
        "mappingPlaceholder": "Scegli un prodotto da mappare nuovamente",
        "copyTitle": "Copia titolo",
        "copied": "Titolo copiato",
        "filter": "Filtra",
        "product_not_found": "Product not found",
        "product_out_of_stock_tip": "Product out of stock",
        get_product_err: `Error in obtaining product information, if you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`,
        get_product_err_2: `There was an error obtaining product information, please try again later. If you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`,
        no_support_mapping: `Because the mapping method of the product is not supported, the detailed information cannot be displayed. If you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`,
        mapping_trouble: `Detailed information cannot be displayed due to a problem with the SKU or mapping of the product. If you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`,
        product_sku_change: `Details cannot be displayed due to a change in the SKU of the item. If you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`
    }
}