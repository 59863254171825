import React, { useEffect, useState } from 'react'
import Style from '../index.module.less'
import intl from 'react-intl-universal';
const PlaceOne = ({ update }) => {
    const [state, setState] = useState(false)
    useEffect(() => {
        setState(update ? true : false)
    }, [update])
    return (
        state ?
            <div
                className={Style.OberloTopTipOne}>
                <img className={Style.OberloTipIcon} src={require('../../../assets/oberlo-guid/error.png')} />
                <div>
                    {window.IS_BIND_AE ?
                        intl.get('guide.OberloTopTipOneBindAE') :
                        intl.get('guide.OberloTopTipOneNoBindAE')
                    }
                </div>
            </div> :
            <></>
    )
}
export default PlaceOne