
    export default 
    {
    "h5_callback": {
        "bind_tip": "¿Estás seguro de que quieres vincular la tienda de Shopify { shop_name } usando la dirección de correo electrónico { bind_email } a la cuenta Dsers usando la dirección de correo electrónico { current_email }?",
        "login_tip": "Por favor, vuelve a conectarte a la tienda",
        "bing_auth": "Tu autorización ha caducado, por favor, actualiza la página para volver a ser autorizado",
        "authAgin": "Tu autorización actual ha caducado, por favor, haz clic en el botón de abajo para actualizar la autorización y volver a conectarte",
        "auth_log": "Cerrar Sesión",
        "auth_entry": "Reautorizar",
        "bind_time_out": "Lo sentimos, la vinculación de la tienda ha caducado, por favor, haz clic en el botón de abajo para volver a iniciar la vinculación",
        "relink": "Vincular nuevamente",
        "need_activate": "La tienda estaba previamente oculta debido a la limitación del plan, por favor dirígete a la página de suscripción para activar la tienda correspondiente.",
        "activate": "Activación"
    }
}