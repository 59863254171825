export default {
  reward: {
    title: 'DSers Reward Season',
    intro:
      'Get more than $100 reward by using products recommended by DSers Mass Supply Program. Click <a href="https://www.blog.dserspro.com/post/rules-of-dsers-reward-season" target="_blank" rel="noopener noreferrer">here</a> to learn how to win it. Please contact our support team if you have any questions.',
    dollar_100: 'Get $100USD and more from 15th Oct. to 15th Jan.',
    dollar_100_intro:
      'To get the $100.00USD reward, <br />you will need to place 1000 valid orders during the 100 days event. <br />The number will turn green after you reach it.',
    get_more_title:
      'Get more by placing more orders with products in Product Bargaiin page during 15th Otc, to 15th Jan.',
    total_title: 'How much you earned in total during the event',
    request_btn: 'Request Reward',
    requested_btn: 'Requested',
    requested_intro:
      'This button will be clickable on 15th, Feb, UTC +8, if you have earned some money',
    form_title:
      'Please fill in your information below, our team will contact you soon.',
    feedback: 'Team will get back to you soon. <br />Thank you for your patience.'
  }
};
