
    export default 
    {
    "reward": {
        "title": "DSers Belohnungssaison",
        "intro": "Holen Sie sich mehr als $100 Belohnung durch die Verwendung von Produkten empfohlen von DSers Mass Supply Program. Klicken Sie <a href=\"https://www.blog.dserspro.com/post/rules-of-dsers-reward-season\" target=\"_blank\" rel=\"noopener noreferrer\">hier</a>, um zu erfahren, wie Sie es gewinnen können. Bitte kontaktieren Sie unser Support-Team, wenn Sie Fragen haben.",
        "dollar_100": "Erhalten Sie $100USD und mehr vom 15. Oktober bis 15. Januar.",
        "dollar_100_intro": "Um die $100.00USD Belohnung zu erhalten,<br /> müssen Sie 1000 gültige Bestellungen während des 100 Tage Events aufgeben.<br /> Die Zahl wird grün, sobald Sie sie erreicht haben.",
        "get_more_title": "Sie erhalten mehr, wenn Sie während des 15. Oktobers bis zum 15. Januar mehr Bestellungen mit Produkten auf der Produkt-Bargaiin-Seite aufgeben.",
        "total_title": "Wie viel Sie insgesamt während des Events verdient haben",
        "request_btn": "Belohnung anfordern",
        "requested_btn": "Gewünscht",
        "requested_intro": "Diese Schaltfläche wird am 15. Februar, UTC +8, anklickbar sein, wenn Sie etwas Geld verdient haben.",
        "form_title": "Bitte geben Sie unten Ihre Daten ein, unser Team wird Sie bald kontaktieren.",
        "feedback": "Das Team wird sich bald bei Ihnen melden. <br /> Wir danken Ihnen für Ihre Geduld."
    }
}