import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Button } from 'antd';
import intl from 'react-intl-universal';

import styles from './TmallColdStartShow.less';
import useOnlineNotice from '../../hooks/useOnlineNotice';
import { DSERS_IMG_HOST } from 'utils/constants';
import classNames from 'classnames';
import { CommonDataCollect } from 'utils/utils';
import { Star } from 'components/SvgIcons';
import TmallColdTimer from './TmallColdTimer';
import Y_Top from '../../assets/coldStart/yellow/pop/top.png'
import Y_Left from '../../assets/coldStart/yellow/pop/left.png'
import Y_Right from '../../assets/coldStart/yellow/pop/right.png'
import Y_Up from '../../assets/coldStart/yellow/pop/up.png'
import Y_Btn from '../../assets/coldStart/yellow/pop/btn.png'

import B_Top from '../../assets/coldStart/black/pop/top.png'
import B_Left from '../../assets/coldStart/black/pop/left.png'
import B_Right from '../../assets/coldStart/black/pop/right.png'
import B_Up from '../../assets/coldStart/black/pop/up.png'
import B_Btn from '../../assets/coldStart/black/pop/btn.png'

import C_Date from '../../assets/coldStart/common/pop/date.png'
import C_Pic from '../../assets/coldStart/common/pop/pic.png'
import C_Text from '../../assets/coldStart/common/pop/text.png'
import C_Star1 from '../../assets/coldStart/common/whiteStar1.svg'
import C_Star2 from '../../assets/coldStart/common/blackStar2.svg'
// import C_up from '../../assets/coldStart/common/pop/up.png'


const modalRoot = document.getElementById('root');

function TmallColdStartShow({ lang }) {
  const [type, setType] = useState(window.TMALL_USER_BANNER1 ? "one" : "two");
  const [visible,setVisible] = useState(false)
  useEffect(() => {
    setVisible((window.TMALL_USER_BANNER1 || window.TMALL_USER_BANNER2) && window.location.href?.includes('find_suppliers') && !localStorage.getItem("close_tmall_cold"))
  }, [window.location.href])
  
  const handleClose = () => {
    window.dtag.set({
      event:'tmall_cold_start',
      module:'tmall_cold',
      action:type == "one" ? 'A_pop_c':'B_pop_c',
      custom_data:{}
    })
    setVisible(false);
    localStorage.setItem("close_tmall_cold", true)
  }

  const handleClickBtn = () => {
    window.dtag.set({
      event: 'tmall_cold_start',
      module: 'tmall_cold',
      action: type == "one" ? 'A_pop_CTA' : 'B_pop_CTA',
      custom_data: {}
    })
    window.open('https://www.dsers.com/blog/tmall-dropshipping/')
    setVisible(false);
    localStorage.setItem("close_tmall_cold",true)
    }

    if (!visible) {
      return null
    }
  return ReactDOM.createPortal(
    <div className={styles.notice}>
      <div className={type == 'one' ? styles.Y_notice : styles.B_notice}>
      <div className={styles.content}>
        <TmallColdTimer type={type} location={"pop"} />
        <div className={styles.contentInfo}>
          <span
            onClick={handleClose}
            className={classNames('material-icons', styles.closeIcon)}
          >
            close
          </span> 
         
          {/**左侧 */}
          <img className={styles.up} src={type == 'one' ? Y_Up : B_Up} />
          <img className={styles.star1} src={C_Star1} />
          <img className={styles.star2} src={C_Star1} />
          <img className={styles.date} src={C_Date} />
          <img className={styles.star4} src={C_Star2} />
          <img className={styles.star3} src={C_Star2} />
          <img className={styles.bj} src={C_Text} />
            <img className={styles.btn}
              onClick={ handleClickBtn}
            src={type == 'one' ? Y_Btn : B_Btn} />
     
            {/*  右侧 */}
            <div className={styles.colorBj}></div>
            <img src={C_Pic} className={styles.rightPic} />
             {/**边框 */}
          <img className={styles.topLine} src={type == 'one' ? Y_Top : B_Top} />
          <img className={styles.leftLine} src={type == 'one' ? Y_Left : B_Left} />
          <img className={styles.rightLine} src={type == 'one' ? Y_Right : B_Right} />
        </div>
        </div>
      </div>
    </div>,
    modalRoot
  );
}

export default TmallColdStartShow;
