export default {
  about: {
    title: 'Bienvenue',
    description: `Chez DSers, nous croyons que tout le monde mérite la méthode la plus rapide et la plus efficace pour place pour traiter ses commandes.
                  Efficacité et Innovation sont nos maîtres mots et notre but est de supprimer toutes les barrière techniques et difficultés qui pourraient empêcher les dropshippers de vendre et placer leurs commandes.
                  Nous sommes heureux de vous accompagner dans votre voyage vers le succès!`,
    our_story: 'Notre Histoire',
    our_story_1: `DSers a été lancé en 2018. Après que nous ayons essayé différentes solutions de traitement de commandes,
                  nous avons réalisé que la vitesse des solutions existantes nous faisait perdre beaucoup de temps, et n'aidait pas à éliminer le travail répétif de placer les commandes pour un dropshipper.
                  Les solutions de traitement de commandes sont trop complexes, lentes et n'ont d'automatique que le nom.`,
    our_story_2: `Nous avons donc créer le site DSers.com en prenant en compte le point de vue des utilisateurs.
                  Nous voulons fournir une platforme qui ne vous demande pas des heures pour placer vos commandes.
                  Nous gardons les choses simples pour que vous puissiez vous concentrez sur votre business et votre marketing.
                  La chose la plus importante: c'est complètement gratuit! Vous pouvez gérer les prix, les fournisseurs, ou placer vos commandes en un clic.`,
    our_story_3: `Après avoir vu la demande croissante pour les solutions e-commerce,
                  nous avons développé la seule solution automatique et gratuite en ligne qui permet aux dropshippers de gérer leurs commandes facilement.`,
    our_story_4: `Aujourd'hui, nous sommes fiers de soutenir des individus et équipes travaillant à travers le monde entier.
                  Chaque dropshipper devrait avoir accès à une plateforme qui simplifie les choses et leur fait gagner du temps pour commander,
                  nous sommes heureux de pouvoir dire que nous sommes ce type de plateforme.`,
    our_values: 'Nos Valeurs',
    our_values_1: `Internet est gratuit et les solutions de traitement de commandes doivent l'être également.
                  Une plateforme doit aider ses utilisateurs à leur fait gagner du temps et de l'argent.
                  Quelques clic en moins et une bonne automatisation permettent d'économiser des heures de travail.`,
    our_values_2: 'Nos clients sont notre inspiration',
    our_values_3: `Chez DSers, nous cherchons à fournir le meilleur à nos clients.
                  Une grande partie de notre temps est passé à analyser l'avis de nos clients pour nous assurer que nous developpons les fonctionnalités qui vous aideront à gagner plus de temps et à vous simplifier la vie.`,
    our_values_4: 'Fabrication Interne',
    our_values_5: 'Nous nous attachons à fournir le meilleur service possible. Du service client à la livraison en passant par le développement, tout est mis en place par notre (géniale) équipe.'
  }
};
