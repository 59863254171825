import React from 'react';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';
import { Radio, Button, Modal } from 'antd';
import debounce from 'lodash/debounce';

import { ChromeAction, OrderAction } from 'actions';
import { isInstallChromePlug } from 'utils/global';

const RadioGroup = Radio.Group;

import intl from 'react-intl-universal';

import orderStyles from '../Order.less';

@connect(({ order, loading, global, login }) => ({
  order,
  loading: loading.models.order,
  unsupportedShipping: global.unsupportedShipping,
  userInfo:login.userInfo
}))
export default class SelectShipp extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectValue:''
    };
  }
  componentWillMount() {
    this.setState({
      selectValue:this.props.freightDefault
    })
  }

  selectValue = '';

  onStopClick = e => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
  };

  changeChildCacheFreight = e => {
    window.UPDATE_EXT_SP = true;
    this.setState({
      selectValue: e.target.value
    })
  };

  goSettingSetShippingMethod = () => {
    this.props.dispatch(routerRedux.push('/settings?id=6'));
  };

  onOk = () => {
    if (!this.state.selectValue || this.props.freightDefault == this.state.selectValue) {
      this.props.onCancel();
      return;
    }
    this.props.onOk(this.state.selectValue);
  };

  charFreight = () => {
    const {bindData,updataObj} = this.props

    //检查是否属于不使用ae用户
    if(this.props.userInfo.ae_flag && !window.IS_BIND_AE){
      window.EVENT.emit(OrderAction.AE_BIND_MODAL,true)
      return
    }

    if (!window.CHROME_PLUG_INSTALL) {
      window.EVENT.emit(ChromeAction.NO_INSTALL_MODAL_RELOAD, true);
      isInstallChromePlug();
      return;
    }
    window.EVENT.emit(OrderAction.UPDATE_FRIENGHT, {
      oid: this.props.oid,
      id: bindData ?  updataObj?.platform_product_id : this.props.useSp?.platform_product_id,
      country: bindData ? this.props.countryCode :this.props.address.country_code,
      currency: 'USD',
      lang: 'en_US',
      isBindWay:bindData,
      sp: {
        ...this.props.address,
        ...this.props.useSp
      }
    });
  };

  handleShipItem = (item) => {
    return () => {
      this.setState({
        selectValue: item
      })
    }
  }

  render() {
    const {
      freights = [],
      freightDefault = undefined,
      onCancel,
      loading = false,
      unsupportedShipping,
      countryCode,
      bindData,
      useSp
    } = this.props;
    
    return (
      <div
        className={orderStyles.shippingMethodDialog}
        onClick={e => {
          this.onStopClick(e);
        }}
      >
        <div className={orderStyles.borderradius}>
          <div className={orderStyles.liHead}>
            <p />
            <p>
              <span>
                {intl.get('order.order_product.shipping_dialog.company')}
              </span>
            </p>
            <p>
              <b>{intl.get('order.order_product.shipping_dialog.cost')}</b>
            </p>
            <p>{intl.get('order.order_product.shipping_dialog.time')}</p>
            <p>{intl.get('order.order_product.shipping_dialog.tracking')}</p>
          </div>
          {freights.length ? (
            <RadioGroup
              defaultValue={freightDefault}
              value={this.state.selectValue}
              onChange={this.changeChildCacheFreight}
              className={orderStyles.group}
            >
              {freights.map(item => {
                // 如果物流方式不支持到该国家 就隐藏该物流选项
                const isUnsupport =
                  unsupportedShipping.Global?.[item.serviceName] ||
                  unsupportedShipping[countryCode]?.[item.serviceName];
                if (isUnsupport) {
                  return null;
                }
                const { freightAmount = item.standardFreightAmount } = item;
                const price = freightAmount.value || 0;
                return (
                  <div className={orderStyles.li} key={item.serviceName} onClick={this.handleShipItem(item.serviceName)}>
                    <p>
                      <Radio value={item.serviceName} />
                    </p>
                    <p>
                      <span>{item.company}</span>
                    </p>
                    <p>
                      <span>
                        {price == 0
                          ? intl.get(
                              'order.order_product.shipping_dialog.price'
                            )
                          : `US $${price}`}
                      </span>
                    </p>
                    <p>
                      {item.time}{' '}
                      {intl.get('order.order_product.shipping_dialog.days')}
                    </p>
                    <p>
                      <span>
                        {item.tracking
                          ? intl.get(
                              'order.order_product.shipping_dialog.available'
                            )
                          : intl.get(
                              'order.order_product.shipping_dialog.unavailable'
                            )}
                      </span>
                    </p>
                  </div>
                );
              })}
            </RadioGroup>
          ) : (
            <div className={orderStyles.noshipping}>
              <span className={orderStyles.upcoming}></span>
              <p>
                {intl.get(`order.order_product.shipping_dialog.upcoming1`)}
                <br />
                {intl.get(`order.order_product.shipping_dialog.upcoming2`)}
                <br />
                {intl.get(`order.order_product.shipping_dialog.upcoming3`)}
                <br />
              </p>
            </div>
          )}
        </div>
        <p className={orderStyles.shippingMethodDialogTool}>
          <b>
            <span
              onClick={e => {
                e.stopPropagation();
                this.goSettingSetShippingMethod();
              }}
            >
              {intl.get('order.order_product.shipping_dialog.setting_link')}
            </span>
            <br />
            {(!bindData  &&  !useSp?.platform_product_id) || this.props.platformOrigin == 'Tmall' ? (
              null
            ) : (
              <span
                className={orderStyles.shippingMethodSync}
                onClick={debounce(this.charFreight, 1000, {
                  leading: true
                })}
              >
                {intl.get('order.order_product.shipping_dialog.async_frienght')}
              </span>
            )}
          </b>
          <a role="button" tab-index="0" onClick={onCancel}>
            {intl.get('public.cancel')}
          </a>
          <Button
            type="primary"
            size="small"
            onClick={this.onOk}
            loading={loading}
            data="dx"
          >
            {intl.get('order.confirm_modal_up.fulfill_confirm')}
          </Button>
        </p>
      </div>
    );
  }
}
