export default {
  home: {
    welcome: 'Bienvenue sur DSers !',
    orders_to_ae: 'commandes à placer sur AliExpress', // d
    order_to_ae: 'commandes à placer sur AliExpress', // d
    move: 'Suivez les étapes:',
    close: 'Fermer',
    tutorial2:'Tutoriel',
    open: 'Ouvrir',
    learn_more: 'SAVOIR PLUS',
    modal_title: {
      title: `Chers utilisateurs de DSers,`,
      container_one: `
      Dans un premier temps, nous souhaitons vous remercier de votre soutien. Afin de continuer d'offrir à nos utilisateurs une meilleure expérience,
       nous avons engagé une équipe de spécialiste en design pour améliorer l'UI (Interface Utilisateur) de DSers.`,
      container_two: `  
        Après 6 mois de préparation, notre nouvelle UI est maintenant en ligne et vous pouvez profiter de celle-ci en cliquant le bouton ci-dessous.`,
      container_three: `   
Bien entendu, vous pourrez toujours accéder à l'ancienne UI si vous le préférez ou pour comparer. Après avoir accéder à la page, vous pourrez changer de version librement.
 Les deux versions de l'UI resteront disponibles pendant un certain temps, en fonction du retour des utilisateurs. Nous vous communiquerons tout changement en avance.`,
      container_four: `
Merci encore pour votre soutien à DSers, qui nous motive pour continuer d'optimiser et de faire de DSers une plateforme en constant progrès.
      `,
      go_new_btn: `Découvrir la nouvelle interface`,
      use_old: `Continue using legacy UI`
    },
    tab_title: {
      add_shopify_store: 'Ajouter Boutique Shopify',
      aliExpress_account: 'Compte AliExpress',
      white_list: 'Liste Blanche',
      setting_shipping: 'Réglages Livraison',
      mapping_product: 'Mapper Produit',
      chrome_extension: 'Extension Chrome',
      bulk_order_function: 'Fonction Commande Groupée'
    },
    add_shopify_store: {
      title: 'Lier votre boutique Shopify:',
      p1: 'Importez les produits de votre boutique Shopify rapidement',
      p2: 'Synchronisez les commandes des 7 derniers jours de Shopify',
      p3: 'Allez à',
      p4: 'Réglages > Autres > Synchroniser commandes Shopify',
      p5: 'pour synchroniser les commandes plus anciennes',
      btn: 'Lier gratuitement'
    },
    aliExpress_account: {
      title: 'Lier votre compte AliExpress pour:',
      p1: 'Placer des commandes groupées en un clic',
      p2: 'Gagner 97% de temps',
      p3: 'Suivre les informations de livraison',
      p4:
        'Assurez-vous que votre compte AliExpress lié apparait comme ci-dessous:',
      btn: 'Lier'
    },
    white_list: {
      title: 'Postuler pour la liste blanche <br/> AliExpress pour:',
      p1: "Placer les commandes avec l'API AliExpress officielle",
      p2: 'Débloquer la limitation de commande et de paiement',
      p3: "Obtenir le service spécial dropshipping d'AliExpress",
      btn: 'Postuler'
    },
    chrome_extension: {
      title: "Téléchargez l'extension Chrome <br/> de DSers pour:",
      p1: 'Migrer depuis Oberlo ou Dropified en un clic ',
      p2: 'Importer des produits depuis AliExpress',
      p3: 'Faire des paiements groupés sur AliExpress',
      btn: 'Télécharger'
    },
    more_about: 'En savoir plus sur DSers',
    video_title_mapping:
      'Comment mapper les produits AliExpress avant de placer une commande?',
    video_title_place_order:
      'Comment placer 100+ commandes en quelques minutes?',
    other_features: 'Un aperçu des autres fonctions',
    video_title_add_store: 'Comment ajouter une boutique Shopify?',
    video_title_bind_ae: 'Comment lier votre compte AliExpress?',
    video_title_import_products:
      'Comment importer des produits sur la boutique Shopify?',
    video_title_fulfill_manually: 'Comment traiter une commande manuellement?',
    bottom_line: 'Vous êtes arrivé en bas',
    // 新版home
    home_title: "Bienvenue sur DSers, l'application officielle d'AliExpress",
    setup_account: 'Réglez votre compte DSers',
    tutorial: 'TUTORIEL',
    product_importer: 'Import de Produit',
    chrome_extension_title: 'EXTENSION CHROME',
    report: 'RAPPORT',
    check_report: 'Suivez votre ventes, dépenses et RSI',
    feedback: 'Avis',
    feedback_share: 'Partagez votre avis',
    feedback_useful: 'Cette information est très utile',
    feedback_not_useful: "Cette information ne m'est pas utile",
    feedback_what_to_know: 'Dites-nous ce que vous voulez savoir',
    comments: 'Commentaires',
    share_feedback: 'Envoyer mon avis',
    new: 'Nouveau',
    dismiss: 'Cacher',
    home_normal_card_learn_more_link: 'https://www.blog.fr.dsers.com/',
    go_modify:'Aller modifier',
    vat_error:`Cher utilisateur, le systême a détecté que le numéro TVA actuellement réglée contient des caractères non numérique, ce qui fera échouer les commandes pour la France (veuillez noter que le numéro TVA que vous entrez est uniquement pour les commandes vers la France). Pour placer les commandes sans soucis, rendez-vous dans Réglages - Autres - TVA pour le modifier`,
    var_update:`Le numéro TVA actuel pour les commandes pour la France n'est plus valable. Pour placer les commandes normalement, veuillez le modifier`,
    modify_btn:`Modify`,
    var_update_setting:`Le numéro TVA actuel pour les commandes pour la France n'est plus valable. Pour placer les commandes normalement, veuillez le modifier`,
    change_UI: `Ancienne Interface`,
    oldUI_out: {
      notion_title: 'Notification Nouvelle Interface',
      notion_con:`L'accès à l'ancienne interface ne sera plus possible après le <br /> 15 Octobre`,
      title: 'Toute nouvelle interface,<br /> toute nouvelle expérience',
      modal_text:`
      Après un mois de retours de nos utilisateurs et certains ajustements, le nouvelle version actuelle de l'interface a été amélioré. Pour unifier les fonctions de la plateforme, nous supprimerons l'accès à l'ancienne interface le 15 Octobre. Si vous avez des questions, n'hésitez pas à
       <a style="color:blue" href='https://www.messenger.com/t/DSersOfficial' dd="src">contacter le service client.</a>`
    },
    downloadguide:{
      guide:'Obtenir le guide',
      text:'Cliquez pour télécharger le<br /> guide dropshipping'
    },
    ShopifyOrders:'Commandes Shopify',
    guidance:{
      steps:'étape',
      btn_complete:'Complété',
      title:'Découvrez DSers',
      desc:'4 étapes pour automatiser votre business de dropshipping',
      add_product: 'AJOUTER PRODUIT',
      push_to_shopify: 'ENVOYER VERS SHOPIFY',
      suppiler_mapping: 'MAPPING FOURNISSEUR',
      place_order: 'PLACER COMMANDE',
      add_product_title: 'Ajoutez votre premier produit',
      push_to_shopify_title: 'Envoyez les produits vers votre boutique Shopify',
      suppiler_mapping_title: 'Mapping: Configurez les fournisseurs des produits',
      place_order_title: 'Traitez votre première commande',
      add_product_desc: 'Ajoutez les produits que vous souhaitez vendre dans votre boutique. Vous pouvez rechercher dans Fournisseur des produits fournis par AliExpress ou DSers.Cliquez <a href="https://help.dsers.com/import-product-from-aliexpress-for-shopify/" target="_blank">ici</a> pour plus de détails.',
      push_to_shopify_desc: 'Lorsque vous avez vos produits dans DSers, modifiez-les et envoyez-les à votre boutique Shopify.Cliquez <a href="https://help.dsers.com/push-a-product-to-your-shopify-store/" target="_blank">ici</a> pour plus de détails.',
      suppiler_mapping_desc: 'Configurez les fournisseurs AliExpress de vos produits pour traiter facilement les commandes futures.Cliquez <a href="https://help.dsers.com/basic-mapping-advanced-mapping-bogo-bundle-introduction-for-shopify/" target="_blank">ici</a> pour plus de détails.',
      place_order_desc: 'Vous pouvez passer des commandes en groupe très rapidement et DSers gérera automatiquement les commandes pour vous une fois passées avec succès.Cliquez <a href="https://help.dsers.com/fulfill-an-order-from-start-to-finish-for-shopify/" target="_blank">ici</a> pour plus de détails.',
      tip_to_pc:`Pour assurer une meilleure expérience utilisateur, connectez-vous à l'aide d'un ordinateur pour faire fonctionner DSers dans les meilleures conditions`
    },
    oberlo:{
      card_title_1:'Presque terminé',
      card_title_des_1:`Veuillez régler les paramètres suivants avant de traiter les commandes et d'utiliser les fonctions avancées.`,
      card_title_2:'Tutorial',
      card_title_des_2:'Consultez ce guide rapide pour vous aider à traiter les commandes et à importer des produits.',
      step_title_1:'Lier compte AliExpress',
      step_title_2:'Activer plan DSers',
      step_title_3:'Installer Extension Chrome DSers',
      step_des_1:'Lier votre compte AliExpress immédiatement pour mettre à jour les statuts de commande. Cliquez <a href="https://help.dsers.com/link-to-aliexpress/" target="_blank">ici</a> pour plus de détails.',
      step_des_2:`Profitez des fonctions d’Oberlo en plus des nouvelles possibilités avancées de DSers. Cliquez <a href="https://help.dsers.com/oberlo-migration-compare-plans/" target="_blank">ici</a> pour plus de détails.`,
      step_des_3:`Importation de produits en un clic d'AliExpress vers DSers pour gagner du temps et trouver de nouveaux produits gagnants! Cliquez <a href="https://help.dsers.com/import-product-from-aliexpress-for-shopify/" target="_blank">ici</a> pour plus de détails.`,
      obPlan:'Activez le plan <i>{dsers}</i> de DSers pour profiter de vos fonctions actuelles dans le plan Oberlo <i>{oberlo}</i>.',
      // dsPlan:'de DSers pour profiter de vos fonctions actuelles dans le plan Oberlo',
      btntext1:'LIER ALIEXPRESS',
      btntext2:'Activer le plan',
      btntext3:'Plus tard',
      btntext4:'Télécharger',
      btntext5:'Voir plus',
      billing_error:`Vous ne pouvez pas activer le plan car votre boutique de facturation n'est pas disponible. Veuillez changer de boutique de facturation dans DSers - <a href="/app/select">Gérer souscription</a> ou activer le boutique de facturation `,
      billing_error1:`Vous ne pouvez pas activer le plan car votre boutique de facturation n'est pas disponible. Veuillez changer de boutique de facturation dans DSers - <a href="/app/select">Gérer souscription</a>.`,
      billing_error_link:'sur Shopify.',
      stepsdes:'étape(s) restante(s)',
      finish:'Terminé',
      year_error:'Si vous activez un nouveau plan mais avez déjà payé les frais annuels du plan DSers Standard, veuillez contacter le service client pour obtenir le remboursement de la partie inutilisée.',
      step_title_4:'Migrer les données Oberlo',
      step_des_4:`Liez votre compte Oberlo et synchronisez les données avec DSers.`,
      stopProduct:`Les données produit n'ont pas été mises à jour correctement, vous pouvez cliquer sur "Réessayer" ou contacter  <a href='https://www.messenger.com/t/DSersOfficial' target="_blank">l'équipe d'assistance DSers</a>`,
      stopOrder:`Le statut des commandes n'a pas été mis à jour correctement, vous pouvez cliquer sur "Réessayer" ou contacter <a href='https://www.messenger.com/t/DSersOfficial' target="_blank">l'équipe d'assistance DSers</a>`,
      successProduct:`La mise à jour des données produit est terminée.`,
      successOrder:`La mise à jour du statut des commandes est terminée, veuillez vous rendre <a href='/app/order/list'>sur la page Commandes ouvertes</a> pour plus de détails`,
      syncProduct:'Nous mettons toujours à jour les données produit de Shopify…',
      syncOrder:`Nous mettons à jour les statuts des commandes d'AliExpress. DSers a migré les commandes des deux derniers mois. Si vous avez besoin de synchroniser plus de commandes, <a href='https://www.messenger.com/t/DSersOfficial' target="_blank">veuillez nous contacter</a>`,
      migrated:'MIGRÉ',
      linked:'LIÉ'
    },
    ShopifyOrders:'Commandes Shopify',
    newGuide:{
      primary_title:`Guide du dropshipping`,
      primary_descriptions:`Nous vous montrerons le contenu le plus approprié en fonction de votre sélection.`,
      second_title_1:`Aucun produit, aucune commande`,
      second_description_1:`Je suis nouveau et je ne sais pas comment utiliser DSers pour démarrer mon activité de dropshipping.`,
      second_title_2:`J’ai des produits, mais pas de commandes`,
      second_description_2:`J'ai déjà des articles sur Shopify, mais je ne sais pas comment démarrer mon business sur DSers.`,
      second_title_3:`J’ai des produits et des commandes`,
      second_description_3:`J'ai utilisé des solutions comme DSers, mais j'ai besoin de savoir comment utiliser les principales fonctions de DSers.`,
      second_more_1_title_1:`What Is Dropshipping?`,
      second_more_1_title_2:`How Can You Start a Dropshipping Store with DSers?`,
      second_more_1_title_3:`Comment importer des produits dans Fournisseurs?`,
      second_more_1_title_4:`Comment importer des produits dans la lLste d'Import?`,
      second_more_1_title_5:`Comment envoyer un produit vers Shopify après l'avoir importé?`,
      second_more_2_title_1:`Ultimate Guide to 15 Evergreen Products to Dropship in 2023`,
      second_more_2_title_2:`Top 10 Trending Dropshipping Products for 2023`,
      second_more_2_title_3:`Comment importer des produits depuis Shopify vers DSers?`,
      second_more_2_title_4:`Comment trouver le bon fournisseur pour un produit?`,
      second_more_3_title_1:`How to Set a Proper and Lucrative Dropshipping Profit Margin?`,
      second_more_3_title_2:`A Step-by-Step Guide to Deal with Your First Dropshipping Order`,
      second_more_3_title_3:`Comment passer une commande sur DSers?`,
      second_more_3_title_4:`How to set up the shipping template?`,
      Expand:`Étendre`,
      Leave:`EFFONDRER`,
      more_videos:'autres vidéos'
    }
  }
};
