import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'antd';

import styles from './NewYearBanner.less';
import useTopBanner from '../../hooks/useTopBanner';
import bg from '../../assets/banner/brazil.png';
import { brazilBannerList } from '../../utils/bannerWhiteList';
import newYearNumber from '../../assets/banner/newYearNumber.png'
import newYearNumberFlower2 from '../../assets/banner/newYearNumberFlower2.png'
import newYearNumberFlower1 from '../../assets/banner/newYearNumberFlower1.png'
const allowPath = [
  '/order/list',
  '/order/agency/list',
  '/order/unmapped/list',
  '/order/tmall/list'
];

const NewYearBanner = ({ path, userId, dispatch }) => {
  const [visible] = useTopBanner(path, allowPath);
  if (!visible) {
    return null;
  }


  return (
    <div className={`${styles.container}`}>
    <div className={styles.banner}>
        <div className={styles.title}>{intl.get('banner.newUI.newYearTitle')}</div>
      <div className={styles.content}>
      <div>{intl.get('banner.newUI.newYearContent1')}</div>
      <div>{intl.get('banner.newUI.newYearContent2')}</div>
      </div>
        <img src={newYearNumberFlower2} className={styles.flower2} />
        <img src={newYearNumberFlower1} className={styles.flower1} />
        <img src={newYearNumber} className={styles.number} />
      </div>
      </div>
  );
};

NewYearBanner.allowPath = allowPath;

export default NewYearBanner;
