import React from 'react';
import { connect } from 'dva';
import { Modal, Form, Button, Input, message } from 'antd';

import intl from 'react-intl-universal';

import styles from './SendResetEmail.less';
import { sendNewPlatformResetPassEmail } from 'utils/justifyMVPAccountUtils';

const btnStyle = { textAlign: 'center' };
const formStyle = { width: '100%', margin: '30px auto 0' };

@connect(({ login, loading }) => ({
  login
}))
@Form.create()
export default class SendResetEmail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sendLoading: false,
      emailInvalid: false
    };
  }

  componentWillUnmount() {
    clearTimeout(this.timeId);
  }

  subEmail = () => {
    // 发送邮件
    this.props.form.validateFields({ force: true }, (err, values) => {
      if (!err) {
        this.setLoading();
        this.props.dispatch({
          type: 'login/sendResetEmail',
          payload: {
            cancelMessage: true,
            data: {
              ...values,
              refer: this.props.redirect
                ? `${this.props.type}@@${encodeURIComponent(
                    this.props.redirect.substr(1)
                  )}`
                : this.props.type
            },
            callback: async d => {
              console.log(d);
              if (d.code == 2000 || d.code == 2010) {
                this.timeId = setTimeout(() => {
                  message.success(intl.get('send_reset_email.send_succ'));
                  this.setState({ sendLoading: false });
                }, 5000);
              } else if (d.code === 4040) {

                try {
                  const newPlatRes = await sendNewPlatformResetPassEmail({
                    email: values?.email
                  });

                  if (!newPlatRes?.code) {
                    this.timeId = setTimeout(() => {
                      message.success(intl.get('send_reset_email.send_succ'));
                      this.setState({ sendLoading: false });
                    }, 5000);
                    return;
                  } else {
                    message.error(newPlatRes?.reason || newPlatRes?.message || 'UNKNOWUN ERROR');
                    this.setState({ sendLoading: false });
                  }
                } catch (e) {
                  
                }

                

                this.setState(
                  {
                    emailInvalid: true,
                    sendLoading: false
                  },
                  () => {
                    this.props.form.validateFields({ force: true });
                  }
                );
              } else {
                this.setState({ sendLoading: false });
              }
            }
          }
        });
      }
    });
  };

  setLoading = () => {
    this.setState({
      sendLoading: true
    });
    // this.timeId = setTimeout(() => {
    //   this.setState({ sendLoading: false });
    // }, 5000);
  };

  timeId = null;

  clearInvalMessage = () => {
    if (!this.state.emailInvalid) {
      return false;
    }
    this.setState({
      emailInvalid: false
    });
    return false;
  };

  render() {
    const { form, showSendResetEmail, hideSendResetEmail } = this.props;
    const { getFieldDecorator } = form;
    const { sendLoading } = this.state;

    return (
      <Modal
        title={intl.get('send_reset_email.title')}
        visible={showSendResetEmail}
        footer={null}
        onCancel={hideSendResetEmail}
      >
        <p className={styles.tip}>{intl.get('send_reset_email.tip')}</p>
        <Form style={formStyle}>
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [
                {
                  type: 'email',
                  message: intl.get('send_reset_email.email_not_valid')
                },
                {
                  required: true,
                  message: intl.get('send_reset_email.email_empty')
                },
                {
                  validator: (rule, value, callback) => {
                    if (this.state.emailInvalid) {
                      callback(intl.get('send_reset_email.email_unknown'));
                    } else {
                      callback();
                    }
                  }
                }
              ]
            })(
              <Input
                size="large"
                onChange={this.clearInvalMessage}
                placeholder={intl.get('send_reset_email.placeholder')}
              />
            )}
          </Form.Item>
        </Form>
        <p className={styles.tip}>
          {intl.get('send_reset_email.dec')}{' '}
          <a href="mailto:support@dsers.com" style={{ color: '#448AFF' }}>
            {intl.get('send_reset_email.contact_us')}
          </a>
          .
        </p>
        <p style={btnStyle} className={styles.p}>
          <Button
            type="primary"
            onClick={this.subEmail}
            loading={sendLoading}
            size="large"
            style={{ width: '100%', fontSize: 14 }}
          >
            {intl.get('send_reset_email.email_instructions')}
          </Button>
        </p>
      </Modal>
    );
  }
}
