
    export default 
    {
    "setting-supply": {
        "title": "Lieferdienst Entrust (SSE)",
        "content": "Wenn Sie Supply Service Entrust aktivieren, wird DSers automatisch den Lieferanten ersetzen, der derzeit einige Ihrer Produkte bezieht, um Ihre Einnahmen zu optimieren. Die ersetzten Lieferanten bieten je nach Produkt eine höhere Lieferstabilität, niedrigere Großhandelspreise, schnelleren Versand und mehr. Das Optimierungsverfahren wird von einem professionellen Team auf der Grundlage einer Big-Data-Analyse durchgeführt. <a href=\"https://www.blog.dserspro.com/post/introduction-to-supply-service-entrust\" target=\"_blank\">Mehr erfahren</a>",
        "activated": "SSE Aktiviert",
        "activate": "Aktivieren",
        "sse_agreement": "SSE Vereinbarung",
        "feedback_title": "Sie haben sich erfolgreich angemeldet!",
        "feedback_con": "Ihr zuständiger Kundenbetreuer wird sich innerhalb <br /> von 1 Arbeitstag bei Ihnen melden.",
        "notice_title": "Versorgungsservice Entrust ist online!",
        "supply_tab": "Massenversorgung",
        "modal_title": "Vielen Dank für Ihre Bewerbung für Supply Service Entrust",
        "modal_intro": "Bitte füllen Sie Ihre Kontakt-E-Mail aus. Ihr zuständiger Kundenbetreuer wird sich innerhalb von 1 Arbeitstag mit Ihnen in Verbindung setzen.",
        "modal_agreement": "Durch Klicken auf Absenden erklären Sie sich mit der <a href=\"https://www.blog.dserspro.com/post/supply-service-entrust-agreement\" target=\"_blank\">SSE-Vereinbarung</a> einverstanden"
    }
}