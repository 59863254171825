import { Get, Post, Put } from '../utils/request';

export async function fakeAccountLogin(payload) {
  return Post('/uaa/api/1.0/login', payload, true);
}

export async function fakeMigraLogin({data,is_mgr,mgr_store_id}){
  return Post('/uaa/api/1.0/login', {data:{
    ...data,
    is_mgr,
    mgr_store_id
  }}, true);
}

// export async function fakeAccountLogin(payload) {
//   return Post('/users/login', payload);
// }

export async function getCaptcha(payload) {
  return Get('/users/captcha', payload);
}

export async function fakeAccountLogout() {
  return Get('/logout');
}

export async function aliLogin(payload) {
  return Post('/ali/login', payload);
}

export async function fakeResendMail(payload) {
  return Post('/user/resendemail', payload);
}

export async function fakeActivation(payload) {
  return Get('/users/active', payload);
}

export async function getChannel(payload) {
  return Get('/actionlog', payload);
}

export async function googleStop2Verify(payload) {
  const data = payload;
  data.isOutApi = true;
  return Post('/login/opt', payload);
}

export async function getResetPassword(payload) {
  return Get('/users/reset', payload);
}

export async function postResetPassword(payload) {
  return Post('/users/reset', payload);
}

// action_type*	integer
// 操作类型
// 1 - 选择平台
// 2 - 定价套餐
export async function detectPlatform(payload) {
  return Get('/uaa/api/1.0/user/action', payload, true);
}

// 添加或修改当前账户行为
// action_operation*	string
// 行为操作
// action_type*	integer
// 操作类型
// 1 - 选择平台
// 2 - 定价套餐
export async function updatePlatform(payload) {
  return Put('/uaa/api/1.0/user/action', payload, true);
}
