import classNames from 'classnames';
import React, { useMemo } from 'react';
import { Alert } from 'antd';

import styles from './DSersAlert.less';

const defaultIcons = {
  success: 'check_circle',
  error: 'error',
  info: 'info',
  warning: 'error'
};

const defaultCloseIcon = 'close';

const DSersAlert = ({
  visible,
  type,
  icon,
  closeIcon,
  className,
  closable,
  style,
  ...restProps
}) => {
  const useIcon = useMemo(() => {
    if (icon) return icon;
    return (
      <span className="material-icons notranslate">
        {defaultIcons[type] || 'info'}
      </span>
    );
  }, [icon, type]);

  const useCloseIcon = useMemo(() => {
    if (!closable) {
      return undefined;
    }
    if (closeIcon) return closeIcon;
    return (
      <span className="material-icons notranslate">{defaultCloseIcon}</span>
    );
  }, [closeIcon, closable]);

  const calculateClassName = useMemo(() => {
    return classNames(styles.dsersAlert, `dsers-alert-${type}`, className);
  }, [type]);

  return visible ? (
    <Alert
      type={type}
      {...restProps}
      className={calculateClassName}
      icon={useIcon}
      closeText={useCloseIcon}
      style={style}
    />
  ) : null;
};

export default React.memo(DSersAlert);
