import React from 'react';
import { Progress } from 'antd';
import intl from 'react-intl-universal';

import checkPasswordStrength, { enumResult } from './checkPasswordStrength';
import styles from './CheckPasswordSafety.less';

const CheckPasswordSafety = ({ value }) => {
  const score = checkPasswordStrength(value);
  const status = enumResult[score];

  let percent = 0;
  if (score <= 2) {
    percent = 33;
  } else if (score === 3) {
    percent = 66;
  } else if (score >= 4) {
    percent = 100;
  }

  if (!value) {
    return null;
  }

  return (
    <div style={{ padding: '8px 0' }}>
      <div className={`safety-progress-${status}`}>
        {percent === 0 || percent === 33 ? (
          <div className={styles.error}>
            {intl.get('register.password_error')}
          </div>
        ) : null}
        {percent === 66 ? (
          <div className={styles.warning}>
            {intl.get('register.password_warning')}
          </div>
        ) : null}
        {percent === 100 ? (
          <div className={styles.success}>
            {intl.get('register.password_ok')}
          </div>
        ) : null}
        <Progress strokeWidth={6} percent={percent} showInfo={false} />
      </div>
      {/* <div style={{ marginTop: 10 }}>{intl.get('publicDocument.warn')}</div> */}
    </div>
  );
};

export default React.memo(CheckPasswordSafety);
