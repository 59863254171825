export default {
    migration: {
        progress_1:'Creazione account',
        progress_2:'Impostazioni preferenze',
        progress_3:'Migrazione dati',
        title:'Benvenuto/a alla migrazione DSers ',
        title_2:'Abbiamo formulato 2 soluzioni di migrazione dei dati. Quale preferisci?',
        scenario_1:'Se gestisci il tuo business con Oberlo, ti suggeriamo di:',
        scenario_1_title:'Sostituire i dati di DSers con quelli di Oberlo',
        scenario_1_text:'Nota bene: i dati di DSers saranno eliminati.',
        scenario_2:'Se gestisci il tuo business con Oberlo e DSers, noi consigliamo:',
        scenario_2_title:'Unire i dati di Oberlo con quelli di DSers',
        scenario_2_text:'Conferma la preferenza di migrazione dei dati.',
        button:'APPLICARE LE PREFERENZE SCELTE',
        blog:'Guida rapida: Processo di migrazione | Confronto funzionalità | Confronto piani',
        contact_1:'Supporto chat live 24/7',
        contact_2:'Supporto e-mail',
        option_1_title:'Per ordini e prodotti che entrano in conflitto con DSers e Oberlo, consigliamo:',
        option_1_text:`Potrebbero esserci dei conflitti se un prodotto Shopify viene impostato con diversi rivenditori AliExpress in DSers e Oberlo, o se hai fatto un ordine su AliExpress con Oberlo, ma non in DSers`,
        option_2_title:'Per le impostazioni dei conflitti tra DSers e Oberlo, ti suggeriamo:',
        option_2_text:`Per esempio, DSers e Oberlo hanno la funzione della regola dei prezzi, la migrazione dei tuoi dati trovano dei conflitti dovuti alle diverse impostazioni dei templates`,
        option_1_1:'DSers come riferimento',
        option_1_2:'Oberlo come riferimento',
        setting_title:'Per conflitti di dati tra DSers e Oberlo, dovresti:',
        setting_text:'Per esempio, DSers e Oberlo hanno entrambi la funzione della regola dei prezzi, pertanto la migrazione dei tuoi dati potrebbe entrare in conflitto perché hanno diverse impostazioni di template, quindi dovrai confermare la preferenza in anticipo.',
        data_title:'Clicca per avviare',
        data_title_2:`Durante la migrazione, i tuoi account Oberlo e DSers non saranno disponbili.
        Torneranno disponibili a migrazione effettuata.`,
        data_button:'AVVIA MIGRAZIONE',
        data_button_wait:'MIGRAZIONE IN CORSO',
        data_button_text:'Cliccando su Avvia migrazione, il tuo account Oberlo sarà impostato su sola lettura e accetterai i nostri termini di utilizzo. La migrazione richiede fino a {time} ora.',
        data_change:'Modifica preferenze',
        data_process_title:'Migrazione in corso…',
        data_process_text:'Tenere le app di DSers e Oberlo installate e attive sullo store Shopify',
        data_setting:'Impostazioni',
        data_order:'Ordini',
        data_product:'Prodotti',
        data_plan:'Piano',
        optionstext:{
            setting:['Migrazione impostazioni in corso…','Riduci il tasso di disputa sincronizzando i numeri di tracciamento su PayPal'],
            order:['Migrazione dei dati degli ordini in corso…','Fai 100+ ordini in qualche secondo','Sincronizza automaticamente lo stato degli ordini.'],
            product:['Migrazione dati prodotti in corso…','Crea un pacchetto di prodotti con mappatura varianti'],
            plan:['Abbinamento piano di abbonamento…',"Ottieni l'accesso a moltissime funzionalità.'"]
        },
        waitmigration_title:`L'account è stato migrato, attendere prego`,
        waitmigration_text:`Un account per la migrazione di Oberlo è già esistente. Attendi che l'account sia migrato.`,
        timeout_title:'Time out richiesta',
        timeout_text:'La richiesta è scaduta, ricarica la pagina e riautorizza da Oberlo. Se hai delle domande, contatta il supporto di DSers. ',
        timeout_contact:'CONTATTA IL SUPPORTO',
        childAccount:`L'account attuale è un sub-account Dsers e non può essere usato per la migrazione. Sei pregato di connetterti all'account principale o di creare un nuovo account DSers. `,
        blackAccount:`L'account non è disponibile. Prova a cambiare l'indirizzo e-mail e riprova.`,
        otherplatform:`Questo account è collegato ad altre piattaforme, sei pregato di cambiare l'account DSers e riprovare.`,
        card_1_title:'Come vuoi trattare i tuoi dati?',
        question_1:'1. Per i dati dei prodotti in tue account vuoi:',
        question_1_select1:'Migrare i dati di Oberlo',
        question_1_select2:'Mantenere anche i dati di DSers',
        question_2:'2. Per i dati degli ordini in due account vuoi:',
        card_2_title:'Come vuoi gestire il conflitto di dati?',
        question_3:`1. Per il conflitto di ordini e prodotti tra DSers e Oberlo, preferisci:`,
        question_3_select1:'Oberlo',
        question_3_select2:'DSers',
        question_4:'2. Per creare conflitti tra DSers e Oberlo, preferisci:',
        childAccountawait:'Migrazione in corso…',
        childAccountawaittext:`L'account DSers sottoposto a migrazione avviato nel tuo account Admin.`,
        code_500:'Eccezione migrazione, contatta il supporto clienti DSers per risolvere il problema.',
        code_426:'Il servizio è stato aggiornato, riprova più tardi. Se hai delle domande, contatta il supporto DSers.',
        exitmigrationtext:'Dopo aver abbandonato la migrazione, è possibile tornare su Oberlo per riavviare la migrazione',
        shoperror:'Assicurati che il tuo negozio Shopify sia pronto e che abbia autorizzato DSers',
        shopcontinue:`CONTINUA LA MIGRAZIONE`,
        dataerror:'Si sono verificati alcuni problemi durante il processo di migrazione, clicca il pulsante "Riprova" o "Contatta il servizio clienti" per contattarci',
        datacontinue:'RIPROVA',
        refresh:'Ricarica la pagina',
        customerservice:'Contatta il supporto',
        oberloError:'Accesso a Oberlo fallito. Contatta il servizio clienti.',
        requestid:'Segnala questo ID al team di supproto DSers per risolvere i problemi:' ,
        startButton:`Nota: <br/>
        1.Dopo aver iniziato la migrazione, il tuo account Oberlo diventerà di sola lettura. <br/> 2. La migrazione richiede circa 1 ore`,
        exitmigration:'ABBANDONA LA MIGRAZIONE',
        according:'Che dati saranno nel tuo account DSers?',
        cover:`In base alla tua configurazione.DSers conserverà i dati del tuo account Oberlo in merito ai prodotti, agli ordini e alle impostazioni`,
        oberlo_oberlo:'In base alla tua configurazione.DSers conserverà i dati di prodotti, ordini e impostazioni dagli account DSers e Oberlo. Nel caso di conflitto di dati durante la sincronizzazione, saranno i dati di Oberlo a prevalere.',
        dsers_dsers:'In base alla tua configurazione.DSers conserverà i dati di prodotti, ordini e impostazioni dagli account DSers e Oberlo. Nel caso di conflitto di dati durante la sincronizzazione, saranno i dati di DSers a prevalere.',
        dsers_oberlo:'In base alla tua configurazione.DSers conserverà i dati di prodotti, ordini e impostazioni dagli account DSers e Oberlo. Nel caso di conflitto di dati durante la sincronizzazione, si conserveranno i dati su prodotti e ordini da DSers, mentre quelli delle impostazioni da Oberlo.',
        oberlo_dsers:'In base alla tua configurazione.DSers conserverà i dati di prodotti, ordini e impostazioni dagli account DSers e Oberlo. Nel caso di conflitto di dati durante la sincronizzazione, si conserveranno i dati su prodotti e ordini da Oberlo, mentre quelli delle impostazioni da DSers.',
        havetask:`Sul presente account è in corso un'attività. Riprova più tardi.`,
        timeout:'The server request timed out, please click the "Start Migration" button to restart the migration',
        data_wait_text:'La Migrazione inizierà in {time} ora. Puoi usare il tuo account Oberlo mentre attendi, ma la migrazione potrebbe partire in qualunque momento.',
        appointTip:`Dopo aver impostato l'ora, non è possibile modificarla. Seleziona un orario adatto per la migrazione. Dopo l'inizio della migrazione, non sarai in grado di utilizzare Oberlo e DSers. Se hai delle domande, contattaci.`,
        appointConfirm:`Conferma l'impostazione dell'orario`,
        appointSuccess:'Ora impostata con successo',
        startNow:'Iniziare la migrazione ora',
        timefull:'La fascia oraria attualmente selezionata è piena, puoi scegliere di iniziare la migrazione immediatamente',
        nowTimeFull:`Questa fascia oraria non è disponibile, seleziona un'altra fascia oraria`,
        tooltiptext:`I dati sono sia su Oberlo che DSers. Abbiamo deciso di mantenere i dati su entrambe le piattaforme per la tua comodità. Se non desideri mantenere i dati DSers puoi togliere la spunta alla casella "Mantieni anche i dati DSers".`,
        tooltiptext_2:`Per esempio, se hai lo stesso articolo su Oberlo e DSers, ma con descrizioni e prezzi diversi, la migrazione dei dati potrebbe risultare in un conflitto.`,
        migrationText:'Avvio della migrazione'
    }
}