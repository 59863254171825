
    export default 
    {
    "whyDisconnect": {
        "need_help": "¿Necesitas ayuda para utilizar Dsers?",
        "need_help_dec": "No dudes en ponerte en contacto con nosotros si necesitas ayuda. Te responderemos lo antes posible.",
        "need_to_uninstall": "Si desinstalas Dsers de tu tienda, ten en cuenta:",
        "need_to_uninstall_dec": "Una vez que la tienda sea eliminada de la cuenta actual de Dsers, todos los datos de esa tienda se perderán y NO serán reversibles.",
        "leave_tip": "Por favor, indícanos el motivo por el que quieres marcharte. Esta información nos será muy útil para mejorar en el futuro.",
        "tell_more": "Por favor, dinos más",
        "select_reason": "Selecciona una razón...",
        "not_satisfied_with_product": "No estoy satisfecho con las funciones relacionadas con la gestión de productos.",
        "not_satisfied_with_order": "No estoy satisfecho con las funciones relacionadas con la gestión de pedidos.",
        "not_work": "La aplicación no funciona bien.",
        "better_app": "He encontrado una aplicación similar que es mejor.",
        "high_cost": "El coste de Dsers es demasiado alto.",
        "test_store": "Esta es una tienda de prueba.",
        "sold_store": "He vendido la tienda.",
        "closing_store": "Voy a cerrar la tienda.",
        "closing_business": "Estoy cerrando mi negocio de (No hay sugerencias).",
        "reinstall": "Volveré a instalarla más tarde.",
        "other": "Otros",
        "disconnect": "Desconectar",
        "stay": "Quedarme",
        "contact": "Contacto",
        "update": "Actualizar",
        "lose_feature": "Si te desconectas perderás los siguientes privilegios",
        "which_module": "Por favor, indícanos el motivo por el que te das de baja. Esto nos ayudará a mejorar en el futuro.",
        "suggestion_title": "¿Tienes alguna sugerencia? Háznosla saber a continuación",
        "other_platform_title": "¿Te mudas a otra plataforma? Dinos a cuál",
        "stay_for_free": "Enhorabuena, déjanos una dirección de correo electrónico para que podamos ponernos en contacto contigo.<br /> Nuestro equipo de profesionales se pondrá en contacto contigo en un plazo de 1 a 2 días para activar tu acceso gratuito de un mes.",
        "btn_get_free": "Obtener un mes gratis",
        "woo_free": "Dsers para WooCommerce es completamente gratis.",
        "reason": {
            "feature": {
                "btn": "Características",
                "ali_account": "No he podido vincular la cuenta de AliExpress",
                "order_process": "No estoy satisfecho con el procesamiento del pedido",
                "product_edit": "No estoy satisfecho con las funciones de edición de productos",
                "import": "No estoy satisfecho con las características de importación de productos",
                "mapping": "No estoy satisfecho con la función de mapeo."
            },
            "price": {
                "btn": "Precio",
                "expensive": "Dsers es demasiado costoso"
            },
            "other": {
                "btn": "Otros",
                "reinstall": "Hay un problema con la tienda, voy a reinstalar más tarde",
                "fullillment_center": "Quiero trabajar con un centro de procesamiento o con un agente",
                "close_store": "Voy a cerrar o vender la tienda / Voy a cerrar mi negocio de Shopify",
                "test_store": "Es una tienda de prueba"
            }
        },
        "dsers_feature": {
            "ali": "Sólo soy socio oficial de AliExpress con acceso a la lista blanca de AliExpress",
            "import": "Importa y edita productos de AliExpress de forma rápida y sencilla",
            "order": "Procesamiento de múltiples pedidos. <br /¡Procesa 100 pedidos en minutos!",
            "reward": "Trabaja con el Programa de Afiliados de Admitad para ganar más dinero"
        },
        "callback_title": "Gracias por los comentarios.<br / Espero que podamos volver a trabajar juntos pronto.",
        "failed_link_ae": "No he podido vincular con la cuenta de AliExpress.",
        "failed_add_shopify": "No he podido añadir mi tienda de Shopify.",
        "not_satisfied_with_import": "No estoy satisfecho con la función de importación de productos.",
        "not_satisfied_with_mapping": "No estoy satisfecho con la función de mapeo.",
        "not_satisfied_with_bulk_order": "No estoy satisfecho con la función de pedidos masivos.",
        "not_satisfied_with_setting": "No estoy satisfecho con las características de los ajustes.",
        "closing_store_or_business": "Voy a cerrar/vender la tienda o voy a cerrar mi negocio de Shopify o es una tienda de prueba."
    }
}