import {
  getMyProductList,
  updateMyProduct,
  removeMyProduct,
  syncProduct,
  productLink,
  addProduct,
  getGuidance,
  postGuidance,
  getDeputySupplierOption,
  getDeputySupplierHistory,
  getOptionHistory,
  getOptionDelete,
  getOptionSaveSku,
  getSupplierCountry,
  getFreights,
  getSaveSupplierShipping,
  getShopifyList,
  getWhiteListId,
  importToDsers,
  removeDsersManage,
  SupplyChain,
  postSupplyChain,
  getVerifiedList,
  getVerifiedSave,
  getProductOpen,
  getverifiedItem,
  getBalance,
  getAddress,
  getUserBlockStatus,
  getAeProductDetail,
  updataExt,
  getCountryList,
  getShopifyProductDetail,
  getCollections,
  getAllCost,
  subMappingInvite,
  getImportHistory,
  postSplitProduct,
  getBlackList,
  setMyProductStatus,
  postAEDataAuthorization,
  postFeedBack,
  postFeedBackRateUs,
  getProdocutPool,
  getProdocutCategory,
  postFeedBackStatus,
  productSelect,
  getSelectProductList,
  productEdit,
  productDelete,
  productSelectSplit,
  createTag,
  getProductTag,
  tagEdit,
  tagDelete,
  productPush,
  getDetailsPage,
  productSelectTags,
  productSelectAddTags,
  // 限制接口
  LimitTheInterface,
  // 支付计划
  disbursementSchedule,
  getStoreSettings,
  saveMoreOrganizationData,
  getAeData,
  getImportFlag,
  replaceProduct,
  downloadImgs,
  getReplaceTime,
  getAeProductDetailInfo,
  getTmProductDetail,

} from '../services/productmy';

export default {
  namespace: 'productmy',

  state: {
    data: [],
    updateStatus: false,
    removeStatus: false,
    changedStatus: false,
    addStatus: false,
    storeData: [],
    guidance: {},
    DeputySupplierOption: [],
    DeputySupplierHistory: [],
    OptionImport: {},
    dataList: [],
    updateStatus1: false,
    removeStatus2: false,
    changedStatus3: false,
    addStatus1: false,
    isLoadingProducts:[]
  },
  effects: {
    *checkAdBlockStatus({ payload }, { call, put }) {
      yield call(getUserBlockStatus, payload);
    },

    *list({ payload }, { call, put }) {
      const response = yield call(getMyProductList, payload);
      yield put({
        type: 'queryMyProduct',
        payload: response
      });
    },
    *listSelect({ payload }, { call, put }) {
      yield call(getSelectProductList, payload);
      // yield put({
      //   type: "querySelectMyProduct",
      //   payload: response
      // });
    },
    *detail({ payload }, { call, put }) {
      const response = yield call(getMyProductDetail, payload);
      yield put({
        type: 'queryMyProductDetail',
        payload: response
      });
    },
    *update({ payload }, { call, put }) {
      const response = yield call(updateMyProduct, payload);
      yield put({
        type: 'updateMyProduct',
        payload: response
      });
    },
    *remove({ payload }, { call, put }) {
      const response = yield call(removeMyProduct, payload);
      yield put({
        type: 'removeMyProduct',
        payload: response
      });
    },
    *link({ payload }, { call }) {
      yield call(productLink, payload);
    },
    *status({ payload }, { call, put }) {
      const response = yield call(syncProduct, payload);
      yield put({
        type: 'changeMyProductStatus',
        payload: response
      });
    },
    *productStatus({ payload }, { call }) {
      yield call(setMyProductStatus, payload);
    },
    *add({ payload }, { call, put }) {
      // 根据速卖通url抓取产品详情
      const response = yield call(addProduct, payload);
      yield put({
        type: 'addProduct',
        payload: response
      });
    },
    *guidance({ payload }, { call, put }) {
      const response = yield call(getGuidance, payload);
      yield put({
        type: 'setGuidance',
        payload: response
      });
    },
    *postGuidance({ payload }, { call, put }) {
      const response = yield call(postGuidance, payload);
      yield put({
        type: 'setPostGuidance',
        payload: response
      });
    },
    *deputySupplierOption({ payload }, { call, put }) {
      const data = payload;
      const response = yield call(getDeputySupplierOption, data);
      yield put({
        type: 'setDeputySupplierOption',
        payload: response
      });
    },
    *deputySupplierHistory({ payload }, { call, put }) {
      const data = payload;
      const response = yield call(getDeputySupplierHistory, data);
      yield put({
        type: 'setDeputySupplierHistory',
        payload: response
      });
    },
    *optionHistoryImport({ payload }, { call, put }) {
      const data = payload;
      const response = yield call(getOptionHistory, data);
      yield put({
        type: 'setOptionHistory',
        payload: response
      });
    },
    *optionHistoryDelete({ payload }, { call, put }) {
      const data = payload;
      const response = yield call(getOptionDelete, data);
      yield put({
        type: 'setOptionDelete',
        payload: response
      });
    },
    *blackList({ payload }, { call, put }) {
      const data = payload;
      const response = yield call(getBlackList, data);
      yield put({
        type: 'setBlackList',
        payload: response
      });
    },
    *optionSaveSku({ payload }, { call, put }) {
      const data = payload;
      const response = yield call(getOptionSaveSku, data);
      yield put({
        type: 'setOptionSaveSku',
        payload: response
      });
    },
    *supplierCountry({ payload }, { call, put }) {
      const data = payload;
      const response = yield call(getSupplierCountry, data);
      yield put({
        type: 'setSupplierCountry',
        payload: response
      });
    },
    *freights({ payload }, { call, put }) {
      const data = payload;
      const response = yield call(getFreights, data);
      yield put({
        type: 'setSupplierShipping',
        payload: response
      });
    },
    *saveSupplierShipping({ payload }, { call, put }) {
      const data = payload;
      const response = yield call(getSaveSupplierShipping, data);
      yield put({
        type: 'setSaveSupplierShipping',
        payload: response
      });
    },
    *shopifylist({ payload }, { call }) {
      yield call(getShopifyList, payload);
    },
    *whitelist({ payload }, { call }) {
      yield call(getWhiteListId, payload);
    },
    *importDsers({ payload }, { call }) {
      yield call(importToDsers, payload);
    },
    *removeDsers({ payload }, { call }) {
      yield call(removeDsersManage, payload);
    },
    *getSupplyChain({ payload }, { call, put }) {
      const response = yield call(SupplyChain, payload);
      yield put({
        type: 'setSupplyChain',
        payload: response
      });
    },
    *postSupplyChain({ payload }, { call, put }) {
      const response = yield call(postSupplyChain, payload);
      yield put({
        type: 'setPostSupplyChain',
        payload: response
      });
    },
    *verifiedList({ payload }, { call, put }) {
      const response = yield call(getVerifiedList, payload);
      yield put({
        type: 'setVerifiedList',
        payload: response
      });
    },
    *verifiedSave({ payload }, { call, put }) {
      yield call(getVerifiedSave, payload);
    },
    *clickProductOpen({ payload }, { call, put }) {
      const response = yield call(getProductOpen, payload);
      yield put({
        type: 'setProductOpen',
        payload: response
      });
    },
    *getverifiedItem({ payload }, { call }) {
      yield call(getverifiedItem, payload);
    },
    *getBalance({ payload }, { call }) {
      yield call(getBalance, payload);
    },
    *getAddress({ payload }, { call }) {
      yield call(getAddress, payload);
    },

    *replaceProduct({ payload }, { call }) {
      yield call(replaceProduct, payload);
    },

    *getReplaceTime({ payload }, { call }) {
      yield call(getReplaceTime, payload);
    },

    *aeProductDetail({ payload }, { call }) {
      yield call(getAeProductDetail, payload);
    },
    *tmProductDetail({ payload }, { call }) {
      yield call(getTmProductDetail, payload);
    },
    *aeProductDetailInfo({ payload }, { call }) {
      yield call(getAeProductDetailInfo, payload);
    },

    *ext({ payload }, { call }) {
      yield call(updataExt, payload);
    },

    *countryList({ payload }, { call }) {
      yield call(getCountryList, payload);
    },

    *shopifyProductDetail({ payload }, { call }) {
      yield call(getShopifyProductDetail, payload);
    },

    *getAeData({ payload }, { call }) {
      yield call(getAeData, payload);
    },
    *getImportFlag({ payload }, { call }) {
      yield call(getImportFlag, payload);
    },

    *collections({ payload }, { call }) {
      yield call(getCollections, payload);
    },

    *allCost({ payload }, { call }) {
      yield call(getAllCost, payload);
    },
    *mappingInvite({ payload }, { call }) {
      yield call(subMappingInvite, payload);
    },
    *importHistory({ payload }, { call }) {
      yield call(getImportHistory, payload);
    },
    *splitProduct({ payload }, { call }) {
      yield call(postSplitProduct, payload);
    },
    *aeDataAuthorization({ payload }, { call }) {
      yield call(postAEDataAuthorization, payload);
    },
    *feedBack({ payload }, { call }) {
      yield call(postFeedBack, payload);
    },

    // 限制接口
    *LimitTheInterface({ payload }, { call }) {
      yield call(LimitTheInterface, payload);
    },

    // 支付计划
    *disbursementSchedule({ payload }, { call }) {
      yield call(disbursementSchedule, payload);
    },

    *feedBackRateUs({ payload }, { call }) {
      yield call(postFeedBackRateUs, payload);
    },
    *feedBackStatus({ payload }, { call }) {
      yield call(postFeedBackStatus, payload);
    },
    *productPool({ payload }, { call, put }) {
      const response = yield call(getProdocutPool, payload);
      yield put({
        type: 'queryProductPool',
        payload: response
      });
    },
    *poolCategory({ payload }, { call }) {
      yield call(getProdocutCategory, payload);
    },
    *productSelectUrl({ payload }, { call, put }) {
      yield call(productSelect, payload);
      // yield put({
      //   type: "urlProductList",
      //   payload: response
      // });
    },
    *productEditImport({ payload }, { call }) {
      yield call(productEdit, payload);
    },
    *downloadimgs({payload},{call}){
     yield call(downloadImgs, payload);
    },
    *deleteProduct({ payload }, { call }) {
      yield call(productDelete, payload);
    },

    *productSplit({ payload }, { call }) {
      yield call(productSelectSplit, payload);
    },

    *tagCreate({ payload }, { call }) {
      yield call(createTag, payload);
    },
    *getProductTagList({ payload }, { call }) {
      yield call(getProductTag, payload);
    },
    *tagEditItem({ payload }, { call }) {
      yield call(tagEdit, payload);
    },
    *tagDeleteItem({ payload }, { call }) {
      yield call(tagDelete, payload);
    },
    *pushShopifyProduct({ payload }, { call }) {
      yield call(productPush, payload);
    },
    *getDetailsPageList({ payload }, { call }) {
      yield call(getDetailsPage, payload);
    },
    *productSelectTag({ payload }, { call }) {
      yield call(productSelectTags, payload);
    },
    *productSelectSeveralTag({ payload }, { call }) {
      yield call(productSelectAddTags, payload);
    },
    *storesSettings({ payload }, { call }) {
      yield call(getStoreSettings, payload);
    },
    *moreOrganizationData({ payload }, { call }) {
      yield call(saveMoreOrganizationData, payload);
    }
  },
  reducers: {
    queryMyProduct(state, { payload = {} }) {
      return {
        ...state,
        updateStatus: false,
        removeStatus: false,
        changedStatus: false,
        addStatus: false,
        data: payload.data || {}
      };
    },
    // querySelectMyProduct(state, { payload = {} }){
    //    return {
    //      ...state,
    //      updateStatus1: false,
    //      removeStatus2: false,
    //      changedStatus3: false,
    //      addStatus1:false,
    //      dataList:payload.data || {}
    //    }
    // },
    updateMyProduct(state, { payload = {} }) {
      return {
        ...state,
        updateStatus: payload.code === 2000 || payload.code === 2010
      };
    },
    removeMyProduct(state, { payload = {} }) {
      return {
        ...state,
        removeStatus: payload.code === 2000 || payload.code === 2010
      };
    },
    changeMyProductStatus(state, { payload = {} }) {
      return {
        ...state,
        changedStatus: payload.code === 2000 || payload.code === 2010
      };
    },
    addProduct(state, { payload = {} }) {
      return {
        ...state,
        addStatus: payload.code === 2000 || payload.code === 2010
      };
    },
    setGuidance(state, { payload = {} }) {
      return {
        ...state,
        guidance: payload.data || {}
      };
    },
    setPostGuidance(state, { payload = {} }) {
      return {
        ...state
      };
    },
    setDeputySupplierOption(state, { payload = {} }) {
      const { data = {} } = payload;
      return {
        ...state,
        DeputySupplierOption: data
      };
    },
    setDeputySupplierHistory(state, { payload = {} }) {
      const { data = {} } = payload;
      return {
        ...state,
        DeputySupplierHistory: data
      };
    },
    setOptionHistory(state, { payload = {} }) {
      const { data = {} } = payload;
      return {
        ...state,
        OptionHistory: data
      };
    },
    setOptionDelete(state, { payload = {} }) {
      const { data = {} } = payload;
      return {
        ...state,
        OptionDelete: data
      };
    },
    setBlackList(state, { payload = {} }) {
      const { data = {} } = payload;
      return {
        ...state,
        blackL: data
      };
    },
    setOptionSaveSku(state, { payload = {} }) {
      const { data = {} } = payload;
      return {
        ...state,
        OptionSaveSku: data
      };
    },
    setSupplierCountry(state, { payload = {} }) {
      const { data = {} } = payload;
      return {
        ...state,
        SupplierCountry: data
      };
    },
    setSupplierShipping(state, { payload = {} }) {
      const { data = {} } = payload;
      return {
        ...state,
        SupplierShipping: data
      };
    },
    setSaveSupplierShipping(state, { payload = {} }) {
      const { data = {} } = payload;
      return {
        ...state,
        SaveSupplierShipping: data
      };
    },
    setSupplyChain(state, { payload = {} }) {
      const { data = {} } = payload;
      return {
        ...state,
        SupplyChain: data
      };
    },
    setPostSupplyChain(state, { payload = {} }) {
      const { data = {} } = payload;
      return {
        ...state,
        setPostSupplyChain: data
      };
    },
    setVerifiedList(state, { payload = {} }) {
      const { data = {} } = payload;
      return {
        ...state,
        verifiedList: data
      };
    },
    setProductOpen(state, { payload = {} }) {
      const { data = {} } = payload;
      return {
        ...state,
        productOpen: data
      };
    },
    queryProductPool(state, { payload = {} }) {
      return {
        data: payload.data || {}
      };
    },
    // urlProductList(state, { payload = {} }) {
    //   return {
    //     ...state,
    //     addStatus1: payload.code === 2000 || payload.code === 2010
    //   };
    // },
    setIsLoadingProducts(state,{payload = {}}){
      const { data = {} } = payload;
      return {
        ...state,
        isLoadingProducts: data
      };
    },
  }
};
