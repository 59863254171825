import getAdminRole from 'utils/getAdminRole';

const dispatchEventForGuide = ({ type, payload }) => {
  console.log('dispatchEventForGuide', { type, payload });
  // 新手教程相关
  try {
    const target = document.querySelector('#beginner-mask');

    if (target) {
      setTimeout(() => {
        target.dispatchEvent(
          new CustomEvent('beginnerGuide', {
            detail: {
              type,
              payload
            }
          })
        );
      }, 500);
    }
  } catch (error) {
    console.log('dispatchEventForGuide error:', error);
  }
};

const hasBeginnerGuideAccess = () => getAdminRole() || window.DSERS_GUIDE;

const initMaskFragment = calc => {
  const fragment = document.createDocumentFragment();
  if (!calc) {
    throw new Error('initMaskFragment: params is required!');
  }
  const elements = ['left', 'top', 'right', 'bottom'];

  elements.forEach(ele => {
    const item = document.createElement('div');
    // item.setAttribute(
    //   'style',
    //   'display: none; position: absolute; z-index: 9999; background: rgba(0, 0, 0, 0.55)'
    // );

    item.classList.add('mask-column');
    item.setAttribute('data-label', `mask-column-${ele}`);

    if (ele === 'left') {
      item.setAttribute(
        'style',
        `top: 0px; left: 0px; width: ${calc.left}px; height: 100%; display: block`
      );
    } else if (ele === 'top') {
      item.setAttribute(
        'style',
        `top: 0px; left: ${calc.left}px; width: ${calc.width}px; height: ${calc.top}px; display: block`
      );
    } else if (ele === 'right') {
      item.setAttribute(
        'style',
        `top: 0px; left: ${calc.left + calc.width}px; width: 100%; height: 100%; display: block`
      );
    } else if (ele === 'bottom') {
      item.setAttribute(
        'style',
        `top: ${calc.top + calc.height}px; left: ${calc.left}px; width: ${
          calc.width
        }px; height: 100%; display: block`
      );
    }
    fragment.appendChild(item);
  });

  return fragment;
};

export { dispatchEventForGuide, hasBeginnerGuideAccess };
