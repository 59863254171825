import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import moment from 'moment-timezone';
import { connect, useDispatch } from 'dva';

import styles from './Appointment.less';
import tiger from 'assets/banner/tiger.png';
import lantern from 'assets/banner/lantern.png';
import error from 'assets/banner/errrortip.png';
import back1 from 'assets/banner/one-back.png';
import back2 from 'assets/banner/two-back.png';
import tip_icon from 'assets/banner/appointment_tip.png';
import tip_close from 'assets/banner/appointment_close.png';

import { Get } from 'utils/request';

function Appointment({ oberlouser }) {
  const dispatch = useDispatch();

  const [appointmentTime, setAppointmentTime] = useState('');
  const [countdown, setCountdown] = useState(false);
  const [nowTime, setNowTime] = useState(parseInt(new Date().getTime() / 1000));
  const [closeTip, setCloseTip] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [startTime, setStartTime] = useState(0);

  let interval = null;

  useEffect(() => {
    getAppointmentTime();
  }, [startTime]);

  useEffect(() => {
    getMigraTionProcess();
    setCloseTip(JSON.parse(localStorage.getItem('closeAppointmentTip')));
    if (
      oberlouser.step_stage == 'STEP_STAGE_QUEUE_RESERVATION' &&
      startTime - nowTime > 0
    ) {
      setIsHidden(true);
    }
    return function() {
      clearInterval(interval);
    };
  }, [nowTime, startTime]);

  const getAppointmentTime = async () => {
    if (oberlouser.step_stage == 'STEP_STAGE_QUEUE_RESERVATION') {
      const res = await Get(
        '/mgnoberlo-core-api/dsers-api/migration/durations/status',
        {
          data: {
            shopify_domain: oberlouser.migrating_shopify_domain
          }
        },
        true
      );
      setAppointmentTime(
        `${moment(Number(res?.durations?.start) * 1000)
          .tz(res.durations?.booking_timezone)
          ?.format('DD MMM HH')}:00~${moment(Number(res?.durations?.end) * 1000)
          .add(1, 'H')
          .tz(res?.durations?.booking_timezone)
          ?.format('HH')}:00`
      );
      setStartTime(res?.durations?.start);
    }
    // console.log(res,nowTime,'shopify_domainshopify_domainshopify_domainshopify_domain');
  };

  const getMigraTionProcess = () => {
    // console.log(startTime - nowTime,nowTime, 'nowTime nowTime nowTime nowTime nowTime');
    if (startTime - nowTime > -1 && !closeTip) {
      interval = setInterval(() => {
        setNowTime(nowTime + 1);
        if (startTime - nowTime <= 300 && startTime - nowTime > 0) {
          setCountdown(true);
        } else if (startTime - nowTime > 300) {
          setCountdown(false);
        } else {
          window.location.href = '/app/data-migrate';
        }
      }, 1000);
    }
  };

  const closeTipHandler = () => {
    localStorage.setItem('closeAppointmentTip', true);
    setCloseTip(true);
  };
  return (
    <>
      {isHidden && !closeTip ? (
        <div className={styles.container}>
          <img src={tip_icon} className={styles.tip_icon} alt="" />
          <div className={styles.txtcon}>
            <p className={styles.title}>
              {countdown
                ? intl.getHTML('banner.appointmenttext1', {
                    time: appointmentTime
                  })
                : intl.getHTML('banner.appointmenttext', {
                    time: appointmentTime
                  })}
            </p>
          </div>
          <div className={styles.tip_close}>
            <img src={tip_close} onClick={closeTipHandler} alt="" />
          </div>
        </div>
      ) : null}
    </>
  );
}
export default connect(({ login, global, user, auth, topBtn }) => ({
  userInfo: login.userInfo,
  lang: global.lang,
  plan_info: user.plan,
  oberlouser: auth?.migrationInfo,
  isoberlouser: auth.isOberloUser,
  dsers_oberlo_plan: topBtn.fromOberloPlan
}))(React.memo(Appointment));
