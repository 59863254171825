import {
  getList,
  getUpdate,
  getAllUpdate,
  productLink,
  getAEProduct,
  getShopifyProductDetail,
  updateShopifyPrice,
  deleteProductNt
} from '../services/notification';

export default {
  namespace: 'notification',

  state: {
    list: {},
    msgBoxData: {}
  },
  effects: {
    *list({ payload: { fromMsg, ...rest } }, { call, put }) {
      const response = yield call(getList, rest);

      if (fromMsg && response && response.code === 2000) {
        yield put({
          type: 'queryMsgSuccess',
          payload: response.data
        });
      }

      yield put({
        type: 'queryList',
        payload: response
      });
    },
    *update({ payload }, { call, put }) {
      const response = yield call(getUpdate, payload);
      yield put({
        type: 'queryUpdate',
        payload: response
      });
    },
    *allUpdate({ payload }, { call, put }) {
      const response = yield call(getAllUpdate, payload);
      yield put({
        type: 'queryAllUpdate',
        payload: response
      });
    },
    *link({ payload }, { call }) {
      yield call(productLink, payload);
    },
    *aeProd({ payload }, { call }) {
      yield call(getAEProduct, payload);
    },
    *shopifyProdDetail({ payload }, { call }) {
      yield call(getShopifyProductDetail, payload);
    },
    *updateShopifyPrice({ payload }, { call }) {
      yield call(updateShopifyPrice, payload);
    },
    *deleteProductNt({payload},{call,put}){
      const res = yield call(deleteProductNt,payload)
      yield put({
        type:'queryDelete',
        payload:res
      })
    }
  },

  reducers: {
    queryList(state, { payload = {} }) {
      return {
        ...state,
        list: payload.data
      };
    },
    queryUpdate(state, { payload = {} }) {
      return {
        ...state,
        update: payload.data
      };
    },
    queryAllUpdate(state, { payload = {} }) {
      return {
        ...state,
        allUpdate: payload.data
      };
    },
    queryMsgSuccess(state, { payload }) {
      return {
        ...state,
        msgBoxData: payload
      };
    },
    queryDelete(state,{ payload }){
      return {
        ...state,
        delete: payload.data
      }
    }
  }
};
