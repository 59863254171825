import React, { useState, useEffect, useCallback } from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'dva';
import qs from 'qs';
import intl from 'react-intl-universal';
import Cookies from 'js-cookie';

import Register from './Register';
import PartnerItem from '../../components/PartnerItem/index'
import Logo from '../../components/NewLogo'
import { pageTitle } from 'utils/constants';
import styles from './MigratSignUp.less';
import { updatePlatform } from 'services/login'
import logo from 'assets/svg/new_logo.png';
import message from 'assets/migration/Message.png'
import { Post } from 'utils/request';
import { CommonDataCollect } from 'utils/utils';
import SelectLanguage from 'components/GlobalHeader/SelectLanguage';

const MigratSignUp = ({ dispatch }) => {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [rawRedirectUrl, setRawRedirectUrl] = useState(null);

  useEffect(() => {

    window.localStorage.removeItem('authority_token');

    CommonDataCollect({
      action:'enter_signinpage',
      shop_name:qs.parse(location.search.slice(1))?.shopname,
      custom_data: []
    })

    const parse = qs.parse(location.search.slice(1));
    console.log('sign up 参数', parse);

    if(parse){
      setRawRedirectUrl(location.search);
    }

    if (parse && parse.redirect_uri) {
      setRedirectUrl(parse);
      setRawRedirectUrl(location.search);
    }
    window.localStorage.setItem('firstRegister', true)
  }, []);


  const handleSubmit = useCallback(
    data => {
      const parse = qs.parse(location.search.slice(1));
      if (redirectUrl) {
        dispatch({
          type: 'register/pureSubmit',
          payload: {
            ...data,
            successCallback: async (token, data) => {
              if (rawRedirectUrl.from === 'wix' || redirectUrl.redirect_uri.includes('/web/auth-callback')) {
                window.localStorage.setItem('dser-pro-authority', token);
                try {
                  const updateRes = await updatePlatform({
                    data: {
                      action_operation: 'wix',
                      action_type: 1
                    }
                  });
                  if (![2000, 2010].includes(updateRes.code)) {
                    return;
                  }
                } catch (error) {
                  console.log(error);
                }
                window.localStorage.removeItem('dser-pro-authority');
                window.localStorage.setItem('authority_token', token);
              } else {
                window.localStorage.setItem('dser-pro-authority', token);
              }
              window.location.href = `${redirectUrl.redirect_uri}&type=a`;
            }
          }
        });
      } else {
        dispatch({
          type: 'register/submit',
          payload: {data:{
            ...data.data,
            is_mgr:true,
            mgr_store_id:parse.shopid
          },
          shopname:parse.shopname
        }
        });
      }
    },
    [dispatch, redirectUrl]
  );


  return (
    <DocumentTitle title={`Sign up - ${pageTitle}`}>
      <div className={styles.signIn}>
      <div className={styles.top}>
          <img src={logo} alt="" />
          <div style={{ lineHeight: '72px',marginRight:'150px' }}><SelectLanguage /></div>
        </div>
        <div className={styles.process}>
        <div><span>1</span><span>{intl.get('migration.progress_1')}</span></div>
          <span className={styles.line}></span>
          <div> <span>2</span><span>{intl.get('migration.progress_2')}</span></div>
          <span className={styles.line}></span>
          <div> <span>3</span><span>{intl.get('migration.progress_3')}</span></div>
        </div>
        <div className={styles.migrateText}>
          <div className={styles.text}>{intl.get('migration.title')}</div>
          <div className={styles.mid_icon}><div className={styles.mid_obl}><div style={{ width: '38px', height: '38px', border: '14px solid #2F01FD', borderRadius: '19px' }}></div></div><i class="material-icons" style={{ color: "#7216DD", fontSize: '44px' }}>chevron_right</i><i class="material-icons" style={{color:"#AE497F", fontSize: '44px' }}>chevron_right</i><i class="material-icons" style={{ color: "#FF8F00", fontSize: '44px' }}>chevron_right</i><div className={styles.mid_dsers}><img src="https://img.dsers.com/images/logo.png" alt="" /></div></div>
        </div>
        <div className={styles.container}>
          <div className={styles.container_fix}>
          <div className={styles.text_box}>
              <p className={styles.text_title_2}>{intl.getHTML('login.text_title_2', {
                titlek: styles.titlek_2
              })}</p>
              <p className={styles.text_desc_2}>{intl.getHTML('login.text_desc_2', {
                titlek: styles.titlek_2
              })}</p>
            </div>
            <div className={styles.box}>
              <Register type='oberlo' onSubmit={handleSubmit} redirectUrl={rawRedirectUrl} />
            </div>
          </div>
          {/* <PartnerItem /> */}
        </div>
        <a className={styles.bottom} style={{ marginTop: '48px' }} target='_blank' href="https://help.dsers.com/oberlo-migration/">{intl.get('migration.blog')}</a>
        <div className={styles.end}>
          <div><img src={message} alt="" /><a href='https://www.messenger.com/t/DSersOfficial' target='_blank'>{intl.get('migration.contact_1')}</a></div>
          <div></div>
          <i className="material-icons">local_post_office</i> <a style={{ marginLeft: '5px' }} href='mailto: support@dserspro.com'>{intl.get('migration.contact_2')}</a>
        </div>
      </div>
    </DocumentTitle>
  );
};

export default connect()(MigratSignUp);
