export default {
  linkStore: {
    website_platform: 'Qual é a plataforma do seu site? *',
    website_domain: 'What is your website domain? *',
    domain_placeholder: 'seu-domínio',
    addonAfter: '.myshopify.com',
    add_store: 'Adicionar loja',
    go_account: 'Introducir la cuenta Dsers',
    add_store_err:
      'Esta loja está vinculada a uma conta DSers. Verifique novamente.',
    add_store_already:
      'Esta loja já está vinculada a outra conta do DSers. Verifique novamente.',
    valid_domain_msg:
      'Certifique-se de usar apenas letras, números e hifens no nome da loja.',
    install_plugin_title:
      'Please install the DSers plugin to experience a more stable service',
    bind_ae_tip_1: 'Not familiar with AlExpress dropshipping?',
    bind_ae_tip_url: 'https://www.dsers.com/blog/aliexpress-dropshipping-101/',
    bind_ae_tip_2: `Don't have an AlExpress  account?`,
    process: {
      link_store: 'Link Store',
      choose_plan: 'Choose Plan',
      link_ae: 'Link AliExpress',
      install_plugin: 'Install Plugin'
    },
    loading_Info: 'Do not refresh or close the page while linking',
    bindAeTitle:
      'Click the AliExpress icon below to go to AliExpress for account authorization.',
    bindAeTitleTwo: 'Select the option below to connect your suppliers.',
    required: 'Required',
    linkSt: `Vincular ao AliExpress`,
    modalTitle: 'Autorização',
    modalText: `Clique em "Adicionar app" na Shopify. Após conceder a autorização, clique em "Atualizar" e atualizaremos a página para você. Caso a opção seja exibida como "App instalado", significa que sua conta Shopify já está vinculada ao DSers. Caso queira vincular outra loja, faça login na loja Shopify que deseja vincular. Clique <a href="https://help.dsers.com/link-your-store-for-shopify/" style="color:#3d5afe">aqui</a>  para saber mais.`,
    modalButton: 'UPDATE',
    enterDsersTitle: 'Purchasing from other platform than AliExpress?',
    enterDsersTooltip:
      'Click the button below to go directly to your DSers account. You can choose from tens of thousands of Tmall items curated for you by DSers, or directly connect with your existing suppliers and let DSers manage your offline orders.',
    clickEnter: 'Click to enter DSers',
    aeModalTitle: 'Link your AliExpress account',
    aeModalText:
      'Linking your AliExpress account to DSers allows you to place 100+orders in seconds! Click here for more details.',
    aeModalBut: 'LINK ALIEXPRESS'
  }
};
