export default {
  login: {
    title: 'Connectez-vous à votre compte DSers',
    email: 'Email',
    password: 'Mot de passe',
    cap_placeholder: 'Captcha',
    log_in: 'CONNECTEZ-VOUS',
    forgot_password: 'Mot de passe oublié?',
    enter_username: 'Veuillez entrer nom d\'utilisateur!', //  a
    enter_password: 'Veuillez entrer le mot de passe!',
    email_password_error: 'Email ou mot de passe erroné', // a
    empty: 'Le mot de passe ne peut pas être vide',
    text_title:`5.0 <br/> <span class={titlek}>Étoiles avec</span>`,
    text_desc:`10000+ <br/> <span class={titlek}>Notes sur l'app store  <br/> Shopify</span>`, 
    text_title_2:`Remplacement officiel d’ <br/> <span class={titlek}>Oberlo recommandé par Shopify</span>`,
    text_desc_2:`10000+ <br/> <span class={titlek}>Notes 5 Étoiles<br/> sur l’app store Shopify</span>`,
    noHaveText:`Vous n'avez pas de compte ? `,
    createAccount:'CRÉER UN COMPTE',
    emailrequired:"L'adresse email est exigée",
    error:`Erreur sur l'adresse électronique ou le mot de passe.`
  }
};
