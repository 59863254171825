import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'dva';
import { message, Button, Input, Form, Tooltip, Icon } from 'antd';
import intl from 'react-intl-universal';

import styles from './Account.less';

import { emailRegExp } from '../../../../utils/constants';

import EmailModule from '../module/ResetEmailModule';
import PassModule from '../module/ResetPassword';

const Account = ({ dispatch, form }) => {
  const [data, setDate] = useState({});
  const [originalData, setOriginalDate] = useState({});
  const [btnType, setBtnType] = useState(true);
  const [visible, setVisible] = useState(false);
  const [passVisible, setPassVisible] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);

  const savedIds = useRef(0);
  const { getFieldDecorator } = form;
  // 修改邮箱
  const handleChangeEmail = () => {
    setVisible(true);
  };
  const handleChangePass = () => {
    setPassVisible(true);
  };
  // 请求账户数据
  const accountData = () => {
    dispatch({
      type: 'partner/GetPartnerAccountInfo',
      payload: {
        callback: d => {
          if (d.code === 2000) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            if (d.data) {
              savedIds.current = d.data.sub_partner_ids ? d.data.sub_partner_ids.length : 0;
              setDate(d.data);
              setOriginalDate(d.data);
            }
          } else {
            window.localStorage.removeItem('partner-store');
            window.localStorage.removeItem('partner-authority');
            window.location.href = '/app/partner/login';
          }
        }
      }
    });
  };
  // 关闭修改邮箱弹窗
  const handleCancelEmailModule = () => {
    setVisible(false);
    accountData();
  };
  // 修改名字与paypal邮箱
  const onChange = () => {
    const list = ['name', 'paypal_email'];
    const errorList = form.getFieldsError(list);
    const valueList = form.getFieldsValue(['name']);
    // eslint-disable-next-line compat/compat
    const newErrorList = Object.values(errorList).filter(
      item => item !== undefined
    );
    // eslint-disable-next-line compat/compat
    const newValueList = Object.values(valueList).filter(
      item => item === undefined
    );
    setBtnType(true);
    if (newErrorList.length === 0 && newValueList.length === 0) {
      const newValue = { ...data, ...form.getFieldsValue() };
      newValue.name = newValue?.name?.replace(/^\s+|\s+$/gm, '');
      setDate(newValue);
      setBtnType(false);
    }
  };
  // 效验名字长度
  const changeName = (rule, value, callback) => {
    const valueLength = value.replace(/^\s+|\s+$/gm, '');
    if (valueLength.length > 100 || valueLength.length < 1) {
      setBtnType(true);
      callback(intl.get('partner.signUp.name_is_value_tips'));
      // callback('Please fill in English characters in 1-100 long');
    }
    if (valueLength.length > 0 && valueLength === '') {
      callback(intl.get('partner.signUp.name_is_value_tips'));
      // callback('Please fill in English characters in 1-100 long');
    }
  };
  // 清楚修改
  const handleCancel = () => {
    form.setFieldsValue({ ...originalData });
    setDate(originalData);
    setBtnType(true);
  };
  // 提交修改
  const handleSave = () => {
    setSendLoading(true);
    const list = ['name', 'paypal_email'];
    const errorList = form.getFieldsError(list);
    // eslint-disable-next-line compat/compat
    const newErrorList = Object.values(errorList).filter(
      item => item !== undefined
    );
    if (newErrorList.length !== 0) {
      return;
    }
    dispatch({
      type: 'partner/UpdatePartnerAccount',
      payload: {
        data,
        callback: d => {
          setSendLoading(false);
          if (d.code === 2000) {
            setBtnType(true);
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            message.success(d.data);
            accountData();
          }
        }
      }
    });
  };
  const handleCancelPassModule = () => {
    setPassVisible(false);
    accountData();
  };
  useEffect(() => {
    accountData();
  }, []);

  //添加channelid
  // const addChannelId = () => {
  //   setBtnType(false);
  //   let arr = [];
  //   if (!data.sub_partner_ids) {
  //     data.sub_partner_ids = [];
  //   } else if (data.sub_partner_ids.length === 4) {
  //     return;
  //   } else if (
  //     data.sub_partner_ids.length > 0 &&
  //     data.sub_partner_ids.length < 5
  //   ) {
  //     data.sub_partner_ids.forEach(item => {
  //       arr = arr.concat(item.substr(item.length - 1, 1));
  //     });
  //   }
  //   const max = arr.length ? Math.max(...arr) : 0;
  //   const newId = `${data.partner_id}-${max + 1}`;
  //   const newValue = { ...data };
  //   newValue.sub_partner_ids = data.sub_partner_ids.concat(newId);
  //   setDate(newValue);
  // };
  
  //删除channelid
  // const removeChannelId = index => {
  //   setBtnType(false);
  //   const newValue = { ...data };
  //   if (newValue.sub_partner_ids.length === 1) {
  //     newValue.sub_partner_ids = [];
  //   } else {
  //     newValue.sub_partner_ids.splice(index, 1);
  //   }
  //   setDate(newValue);
  // };

  return (
    <div className={styles.account_contner}>
      <Form onChange={onChange} className="login-form">
        <div className={styles.use_pass}>
          <Form.Item label={intl.get('partner.signUp.email')}>
            <div className={styles.contact_email}>
              <div>
                <Tooltip title={data.email}>
                  <p>{data.email}</p>
                </Tooltip>
                <Button type="primary" onClick={handleChangeEmail}>
                  {intl.get('partner.public.change')}
                  {/* Change */}
                </Button>
              </div>
            </div>
          </Form.Item>
          <Form.Item label={intl.get('partner.signUp.pwd')}>
            <div className={styles.contact_pass}>
              <Button type="primary" onClick={handleChangePass}>
                {intl.get('partner.public.reset')}
                {/* Reset */}
              </Button>
            </div>
          </Form.Item>
        </div>
        <div className={styles.main}>
          <Form.Item label={intl.get('partner.signUp.name')}>
            {getFieldDecorator('name', {
              initialValue: data.name,
              rules: [
                {
                  validator: changeName
                }
              ]
            })(<Input value={data.name} />)}
          </Form.Item>
          <div className={styles.country_and_business}>
            <Form.Item label={intl.get('partner.signUp.country')}>
              <Tooltip title={data.country}>
                <div className={styles.contact_country}>
                  {data?.country}
                  <div className={styles.corner} />
                </div>
              </Tooltip>
            </Form.Item>
            {/* <Form.Item label={intl.get('partner.signUp.business_type')}>
              <Tooltip title={data.business_type}>
                <div className={styles.contact_country}>
                  {data?.business_type} <div className={styles.corner} />
                </div>
              </Tooltip>
            </Form.Item> */}
          </div>
          <Form.Item label={intl.get('partner.signUp.partner_url')}>
            <div className={styles.contact_name}>
              <Tooltip title={data.partner_url}>
                <p>{data.partner_url}</p>
              </Tooltip>
            </div>
          </Form.Item>
          <Form.Item
            label={
              <>
                <span>{intl.get('partner.signUp.partner_id')}</span>
                <Tooltip title={intl.get('partner.signUp.partner_id_tips')}>
                  <Icon
                    style={{ marginLeft: 5, verticalAlign: 'baseline' }}
                    type="question-circle-o"
                  />
                </Tooltip>
              </>
            }
          >
            <div className={styles.contact_name}>
              {!data.partner_id?.match(/(@\$!)$/) ? <Tooltip title={data.partner_id}>
                {getFieldDecorator('partner_id', {
                  initialValue: data.partner_id
                })(<p>{data.partner_id}</p>)}
              </Tooltip> : null}
            </div>
            {/* <div className={styles.iconCon}>
            {!data.partner_id?.match(/(@\$!)$/) 
            ? <Icon className={styles.plusIcon} onClick={addChannelId} type="plus" /> : null}
            </div> */}
          </Form.Item>
          {/* {data.sub_partner_ids &&
            data.sub_partner_ids.map((item, index) => (
              <Form.Item key={index}>
                <div className={styles.contact_name_sub}>
                  <Tooltip title={item}>
                    {getFieldDecorator('sub_partner_id', {
                      initialValue: data.sub_partner_ids || []
                    })(<p>{item}</p>)}
                  </Tooltip>
                  {index > savedIds.current - 1 ? (
                    <div className={styles.iconCon}>
                      <Icon className={styles.minusIcon} onClick={() => removeChannelId(index)} type="minus" />
                    </div>
                  ) : null}
                </div>
              </Form.Item>
            ))} */}
          <Form.Item label={intl.get('partner.account.paypal_email')}>
            {getFieldDecorator('paypal_email', {
              initialValue: data.paypal_email,
              rules: [
                {
                  pattern: emailRegExp,
                  message: intl.get('partner.signUp.email_format_tips')
                }
              ]
            })(
              <Input
                placeholder={intl.get(
                  'partner.account.paypal_email_placeholder_tips'
                )}
              />
            )}
          </Form.Item>
          <Form.Item>
            <div className={styles.btn}>
              <Button type="dashed" disabled={btnType} onClick={handleCancel}>
                {intl.get('partner.public.cancel')}
              </Button>
              <Button
                type="primary"
                disabled={btnType}
                onClick={handleSave}
                loading={sendLoading}
              >
                {intl.get('partner.public.save')}
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
      <EmailModule
        visible={visible}
        handleCancel={handleCancelEmailModule}
        accountData={accountData}
      />
      <PassModule visible={passVisible} handleCancel={handleCancelPassModule} />
    </div>
  );
};
export default connect()(Form.create()(Account));
