
    export default 
    {
    "product_split": {
        "split": "Teilen",
        "title": "Split product",
        "split_by_option": "Aufteilung nach Option",
        "split_by_option_dec": "Select the option that will be split to another product",
        "option_tip": "Wir erstellen { length } Produkt(e), jedes Produkt enthält nur { optionName } Variante(n) ( {values} )",
        "or": "oder",
        "split_by_variant": "Aufteilung nach Variante",
        "split_by_variant_dec": "Wählen Sie die Varianten, die in ein anderes Produkt aufgeteilt werden sollen",
        "tip": "Die ausgewählten Varianten werden verwendet, um ein neues Produkt zu erstellen, und das aktuelle Produkt wird weiterhin alle ursprünglichen Varianten enthalten.",
        "select_split_mothod": "Bitte wählen Sie einen Splitmodus",
        "confirm_split": "Split Product",
        "confirm_split_desc": "Are you sure you want to split the product? It will be split into different products.",
        "product_not_found": "The product you entered was not found",
        "network_issue": "The operation failed due to network issue, please try again"
    }
}