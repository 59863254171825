import React from 'react';
import ReactDOM from 'react-dom';
import { Icon } from 'antd';

import styles from './SwitchBanner.less';
import useOnlineNotice from '../../hooks/useOnlineNotice';
import { bannerList } from '../../utils/bannerWhiteList';
import stock1 from '../../assets/switch-banner/stock1.png';
import stock2 from '../../assets/switch-banner/stock2.png';

const onlineDate = new Date(2021, 5, 18); // 上线日期 6月18-24
const modalRoot = document.getElementById('root');

function SwitchBanner({ userId }) {
  const [visible, closeNotice] = useOnlineNotice('stock', onlineDate, 7);
  const show = bannerList.includes(userId);
  if (!visible || !show) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.notice}>
      <div className={styles.body}>
        <img src={stock1} className={styles.bg1} />
        <Icon
          type="close"
          className={styles.close}
          onClick={() => closeNotice()}
        />
        <div className={styles.textCon}>
          <div className={styles.text1}>
            Over <strong>600</strong> clients applied
          </div>
          <div className={styles.text2}>
            Jun 18 &nbsp;&nbsp;<span className={styles.anniversary}>th</span> AliExpress Dropshipping Summer sale Reward
          </div>
          <img src={stock2} className={styles.bg2} />
          <div className={styles.text3}>
            Contact us at
            <a href="mailto:supply-support@dserspro.com"> supply-support@dserspro.com
            </a>
          </div>
        </div>
      </div>
    </div>,
    modalRoot
  );
}

export default SwitchBanner;
