import { message } from 'antd';
import { emitCustomEvent } from 'utils/dsersCustomEvent';

// 删除bundle中空的mapping
export function deleteBundle(bundle = {}, variants) {
  variants.forEach(item => {
    const { variant_supply } = bundle[item.id] || {};

    if (!variant_supply) {
      return;
    }

    const supply = variant_supply[0].supply;

    for (let i = 0; i < supply.length; i++) {
      if (!supply[i].sku) {
        supply.splice(i, 1);
        i -= 1;
      }
    }
    if (supply.length == 0) {
      delete bundle[item.id];
    }
  });
}

// 删除bogo中空的mapping
export function deleteBogo(bogo = {}, variants) {
  variants.forEach(item => {
    const { variant_supply } = bogo[item.id] || {};

    if (!variant_supply) {
      return;
    }

    if (variant_supply[0].supply[1] && !variant_supply[0].supply[1].sku) {
      variant_supply[0].supply.splice(1, 1);
    }

    if (!variant_supply[0].supply[0].sku) {
      variant_supply[0].supply = [];
    }

    if (variant_supply[0].supply.length == 3) {
      variant_supply[0].supply.length = 2;
    }

    if (variant_supply[0].supply.length == 0) {
      delete bogo[item.id];
    }
  });
}

// 删除advance中的空mapping
export function deleteAdvance(advance = {}, variants) {
  variants.forEach(item => {
    const { variant_supply } = advance[item.id] || {};

    if (!variant_supply) {
      return;
    }

    for (let i = 0; i < variant_supply.length; i++) {
      if (
        variant_supply[i].countries.length == 0 ||
        !variant_supply[i].supply[0].sku
      ) {
        variant_supply.splice(i, 1);
        i -= 1;
      }
    }

    if (variant_supply.length == 0) {
      delete advance[item.id];
    }
  });
}

export function basicHasRemoveVariant(basic = {}, variants = []) {
  const result = [];
  const variantsMap = {};
  variants.forEach(variant => {
    variantsMap[variant.id] = variant;
  });
  Object.values(basic).forEach(({ supply = []}) => {
    supply.forEach(item => {
      if (item.variant_id && !variantsMap[item.variant_id]) {
        result.push({
          is_del: true,
          id: item.variant_id,
          title: item.variant_title || '',
          sku: item.sku,
          platform_product_id:item.sku ? item?.platform_product_id : undefined,
          platform_type:item?.platform_type,
          supplier_sku_id: item?.supplier_sku_id,
          sku_image: item.sku_image,
        });
      }
    });
  });
  console.log(basic,variantsMap,result,'result-=-=-=')
  return result;
}

// ext advance里有variant id 但 detail variants里没有
export function advanceHasRemoveVariant(advance = {}, variants) {
  const arr = [];
  const arr2 = [];
  if (advance) {
    Object.keys(advance).forEach(id => {
      const flag = variants.some(item => item.id == id);
      flag == false && arr.push(advance[id]);
    });

    arr.length &&
      arr.forEach(item => {
        const sp = item.variant_supply[0].supply[0];
        arr2.push({
          id: sp.variant_id,
          title: sp.variant_title || '',
          is_del: true,
          sku: sp.sku,
          sku_image: sp.sku_image,
        });
      });
  }
  return arr2;
}

// ext bogo里有variant id 但 detail variants里没有
export function bogoHasRemoveVariant(bogo = {}, variants) {
  const arr = [];
  const arr2 = [];
  if (bogo) {
    Object.keys(bogo).forEach(id => {
      const flag = variants.some(item => item.id == id);
      flag == false && arr.push(bogo[id]);
    });

    arr.length &&
      arr.forEach(item => {
        const sp = item.variant_supply[0].supply[0];
          arr2.push({
            id: sp.variant_id,
            title: sp.variant_title || '',
            is_del: true,
            sku: sp.sku,
            sku_image: sp.sku_image,
          });
      });
  }
  return arr2;
}

// bundle里有没有被删除的variant
export function bundelHasRemoveVariant(bundle = {}, variants) {
  const arr = [];
  const arr2 = [];
  if (bundle) {
    Object.keys(bundle).forEach(id => {
      const flag = variants.some(item => item.id == id);
      flag == false && arr.push(bundle[id]);
    });
    
    arr.length &&
    arr.forEach(item => {
      const sp = item.variant_supply[0].supply[0];
      arr2.push({
        id: sp.variant_id,
        title: sp.variant_title || '',
        is_del: true,
        sku: sp.sku,
        sku_image: sp.sku_image,
      });
    });
  }
  return arr2;
}

// standard里有没有被删除的variant
export function standardHasRemoveVariant(standard = {}, variants) {
  const arr = [];
  const arr2 = [];
  if (standard) {
    Object.keys(standard).forEach(id => {
      const flag = variants.some(item => item.id == id);
      flag == false && arr.push(standard[id]);
    });
    arr.length &&
      arr.forEach(item => {
        const sp = item.variant_supply[0].supply[0];
        arr2.push({
          id: sp.variant_id,
          title: sp.variant_title || '',
          sku_id:sp.sku_id,
          is_del: true,
          platform_product_id:sp?.sku ? sp?.platform_product_id : undefined,
          platform_type:sp?.platform_type,
          sku: sp.sku,
          sku_image: sp.sku_image,
        });
      });
  }
  return arr2;
}

// 有没有被删除的variant
export function hasDeleteVariant(originProductData, type) {
  const { prod: { detail: { variants = [] }, ext } } = originProductData;
  const { del = [], advance = {}, bogo = {}, bundle = {}, basic = {}, standard={} } = ext || {};

  let arr = [];
  const obj = {};

  console.log(del, 'utildel')

  del.forEach(sp => {
    arr.push({
      id: sp.variant_id,
      title: sp.variant_title || '',
      sku_id: sp.sku_id,
      is_del: true,
      platform_product_id:sp.sku ? sp?.platform_product_id : undefined,
      platform_type:sp?.platform_type,
      sku: sp.sku,
      sku_image: sp.sku_image,
    });
  });

  const basicDelete = basicHasRemoveVariant(basic, variants);
  const advanceDelete = advanceHasRemoveVariant(advance, variants);
  const bogoDelete = bogoHasRemoveVariant(bogo, variants);
  const bundleDelete = bundelHasRemoveVariant(bundle, variants);
  const standardDelete = standardHasRemoveVariant(standard, variants);
  
  arr = [
    ...arr,
    ...basicDelete,
    // ...advanceDelete,
    // ...bogoDelete,
    // ...bundleDelete,
    ...standardDelete
  ];
  
  arr.forEach(item => {
    obj[item.id] = item;
  });
  let Array = [] //存放去重过后的数据
  let newobj = {}   
  //去除data,里面title相同的对象
  for (var i = 0; i < Object.values(obj).length; i++) {
    if (!newobj[Object.values(obj)[i].id]) {
      Array.push(Object.values(obj)[i])
      newobj[Object.values(obj)[i].id] = true
    } 
  }
  return Array;
}

export const postMappingProductRef = (refObj = {}, mapType, productData = {}) => {
  try {
    const {
      id: dsersProductId,
      prod: { detail: { id: shopifyProductId } = {} } = {}
    } = productData;
    Object.entries(refObj).forEach(([productId, ref]) => {
      emitCustomEvent({
        event_action: 'mapping',
        event_category: 'product',
        event_label: 'dsref',
        paid: productId,
        ref,
        map_type: mapType,
        shopify_product_id: shopifyProductId,
        id: dsersProductId
      });
    });
  } catch (error) {
    console.log(error);
  }
  
};

export const getMappingAvaliableStatus = plan => {
  return {
    advanced_variant_mapping: ![0, 1, 4].includes(plan),
    affiliate: ![0, 1, 4].includes(plan),
    bogo: ![0, 1, 4].includes(plan),
    standard: [0, 1, 4].includes(plan),
  };
};
