import React from 'react';
import { AsyncConfirm } from '../AsyncConfirm';
import styles from './AELocalAccountAsyncModal.less';

class AELocalAccountAsyncModal extends React.PureComponent {
  state = {
    type: 0
  };

  showConfirm = async type => {
    if (this.refs.AsyncConfirm) {
      this.setState({ type });
      return this.refs.AsyncConfirm.showConfirm();
    }
    return true;
  };

  render() {
    const { type } = this.state;
    return (
      <AsyncConfirm
        centered
        width={560}
        closable
        className={styles.loginAEModal}
        okText="Place order directly"
        cancelText="Login AliExpress account"
        title="Login AliExpress account"
        ref="AsyncConfirm"
      >
        {type === 0 ? (
          <div>
            We have detected that you are not logged in to your AliExpress
            account. Please login to the AliExpress account that already bound
            to DSers to ensure timely and accurate synchronization of order
            information.
          </div>
        ) : (
          <div>
            We have detected that the AliExpress account you have logged in is
            inconsistent with the bound account. Please login to the right
            AliExpress account that already bound to DSers to ensure timely and
            accurate synchronization of order information.
          </div>
        )}
      </AsyncConfirm>
    );
  }
}

export default AELocalAccountAsyncModal;
