import React from 'react';
import { Tooltip } from 'antd';
import intl from 'react-intl-universal';

import className from 'classnames';

import styles from './ProductCardTitles.less';

import Ellipsis from 'components/Ellipsis';
import tmalllogo from 'assets/tmall_logo.svg';
import ae from 'assets/icon-ae.jpg';
import mainIcon from 'assets/newImportList/main_product.png';
import agency_icon from 'assets/agency_icon.svg';

import { CommonDataCollect } from 'utils/utils'

export function ProductCardTitles({
  type = 'importlist',
  is_main,
  supplier_type,
  product,
  openCostDetail
}) {

  const getMyProductUrlHandler = () => {
    let isSameSupplier = [];
    if (product?.prod?.ext?.standard && product?.prod?.ext?.map_type === 5) {
      Object.values(product?.prod?.ext?.standard).forEach(b => {
        const { is_default, variant_supply = [] } = b;
        isSameSupplier.push(variant_supply[0].supply[0].platform_product_id);
      });
    }
    const len = new Set(isSameSupplier).size;
    if ((product?.prod?.ext?.map_type == 1 || len === 1) && product.ae_pid) {
      if (product?.suppliers[0] === 4) {
        return `/app/tmallproduct/${product.ae_pid}?appId=159831084`;
      } else {
        return `https://www.aliexpress.com/item/${product.ae_pid}.html`;
      }
    } else {
      return 'javascript:void(0)';
    }
  };
  const getImportListUrlHandler = ()=>{
    if (product?.supplier_type === 4) {
      return `/app/tmallproduct/${product.ae_pid}?appId=159831084`;
    } else {
      return `https://www.aliexpress.com/item/${product.ae_pid}.html`;
    }
  }
  const dotHandler = ()=>{
    if (product?.suppliers[0] === 4){
      CommonDataCollect({
        action:'myproduct_card_basic_click_tmall_link',
        event_type:'my_product_tmall'
      })
    }
  }

  if (type === 'myproduct') {
    return (
      <a
        href={getMyProductUrlHandler()}
        onClick={getMyProductUrlHandler() === 'javascript:void(0)' ? openCostDetail : dotHandler}
        target={getMyProductUrlHandler() !== 'javascript:void(0)' ? '_blank' : '_self'}
        title={product.title}
        className={styles.titleContainer}
      >
        <TitleIcon type={type} product={product} />
        <span className={styles.title}>{product.title}</span>
      </a>
    );
  }
  if (type === 'importlist') {
    return (
      <a
        href={
          product.ae_pid
            ? getImportListUrlHandler()
            : 'javascript:void(0)'
        }
        target={product.ae_pid ? '_blank' : '_self'}
        title={product.title}
        className={styles.titleContainer}
      >
        <TitleIcon type={type} product={product} />
        <span className={styles.title}>{product.title}</span>
      </a>
    );
  }
}

export const TitleIcon = React.memo(({ type, product }) => {
  if (type === 'myproduct') {

    const { prod, suppliers = [] } = product;
    const { ext } = prod;
    const { map_type = 0, basic={}, agencies={}, advance={}, bogo={}, bundle={}, standard={} } = ext;

    let showAeIcon = true

    if (
      (!Object.keys(ext).length) ||
      (map_type === 0) ||
      (map_type === 1 && !Object.keys(basic).length) ||
      (map_type === 2 && !Object.keys(advance).length) ||
      (map_type === 3 && !Object.keys(bogo).length) ||
      (map_type === 4 && !Object.keys(bundle).length) ||
      (map_type === 5 && !Object.keys(standard).length) ||
      (map_type === 6 && !Object.keys(agencies).length)
    ) {
      return null
    }

    if (map_type === 6 && Object.keys(agencies).length) {
      return (
        <span className={styles.iconContainer}>
          <Tooltip title="Agency">
          <img className={styles.icon} src={agency_icon} />
          </Tooltip>
        </span>
      );
    }

    if (suppliers.length) {
      return (
        <span className={styles.iconContainer}>
          {product?.suppliers.map(item => (
            <img className={styles.icon} src={item == 4 ? tmalllogo : ae} />
          ))}
        </span> 
      ); 
    }
    

    if (showAeIcon) {
      return (
        <span className={styles.iconContainer}>
          <img className={styles.icon} src={ae} />
         </span>
      );
    }


  }
  if (type === 'importlist') {
    if (product?.supplier_type === 4) {
      return (
        <span className={styles.iconContainer}>
          {product?.is_main ? (
            <Tooltip title={intl.getHTML('product.is_main_product')}>
              <img className={styles.icon} src={mainIcon} alt="" />
            </Tooltip>
          ) : null}
          <img className={styles.icon} src={tmalllogo} />
        </span>
      );
    } else {
      return (
        <span className={styles.iconContainer}>
          {product?.is_main ? (
            <Tooltip title={intl.getHTML('product.is_main_product')}>
              <img className={styles.icon} src={mainIcon} alt="" />
            </Tooltip>
          ) : null}
          <img className={styles.icon} src={ae} />
        </span>
      );
    }
  }
});
