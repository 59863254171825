export default {
  'setting-supply': {
    title: 'Supply Service Entrust (SSE)',
    content: `Quand vous activez Supply Service Entrust, DSers remplacera automatiquement le fournisseur actuel mappé de certains de vos produits pour optimiser vos gains.
    Le fournisseur de remplacement fournira un stock plus stable, des prix de gros plus bas, une livraison plus rapide, et plus, en fonction des produits.
    La procédure d'optimisation sera effectué par une équipe professionnelle, suivant les données analysées. <a href="https://www.blog.dserspro.com/post/introduction-to-supply-service-entrust" target="_blank">Savoir plus</a>`,
    activated: 'SSE Activé',
    activate: 'Activé',
    sse_agreement: ' Conditions SSE',
    // feedback
    feedback_title: 'Vous avez postulé avec succès!',
    feedback_con: `Votre gérant de compte personnel vous contacter en moins d'une journée`,
    // notice
    notice_title: 'Le programme Supply Service Entrust est en ligne!',
    // menu
    supply_tab: 'Produit en Masse',
    // modal
    modal_title: `Merci d'avoir postuler au Supply Service Entrust`,
    modal_intro: `Veuillez entrer votre email de contact et votre gérant de compte personnel vous contactera en moins d'une journée`,
    modal_agreement:
      'En cliquant sur Postuler, vous acceptez les <a href="https://www.blog.dserspro.com/post/supply-service-entrust-agreement" target="_blank">conditions du SSE</a>'
  }
};
