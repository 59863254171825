
    export default 
    {
    "whyDisconnect": {
        "need_help": "Brauchen Sie Hilfe bei der Verwendung von DSers?",
        "need_help_dec": "Bitte zögern Sie nicht, uns zu kontaktieren, wenn Sie Hilfe benötigen. Wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen.",
        "need_to_uninstall": "Wenn Sie DSers aus Ihrem Geschäft deinstallieren, beachten Sie bitte:",
        "need_to_uninstall_dec": "Sobald der Shop aus dem aktuellen DSers-Konto entfernt wird, gehen alle Daten dieses Shops auf DSers verloren und können NICHT rückgängig gemacht werden.",
        "leave_tip": "Bitte teilen Sie uns mit, warum Sie gehen möchten. Das wäre sehr hilfreich für uns, um es in Zukunft besser zu machen.",
        "tell_more": "Bitte erzählen Sie uns mehr",
        "select_reason": "Wählen Sie einen Grund...",
        "not_satisfied_with_product": "Ich bin mit den Funktionen des Produktmanagements nicht zufrieden.",
        "not_satisfied_with_order": "Ich bin mit den Funktionen der Auftragsverwaltung nicht zufrieden.",
        "not_work": "Die App funktioniert nicht gut.",
        "better_app": "Ich habe eine ähnliche App gefunden, die besser ist.",
        "high_cost": "Die Kosten für DSers sind zu hoch.",
        "test_store": "Dies ist ein Testladen.",
        "sold_store": "Ich habe den Laden verkauft.",
        "closing_store": "Ich schließe den Laden.",
        "closing_business": "Ich schließe mein Dropshipping-Geschäft.",
        "reinstall": "Ich werde später neu installieren.",
        "other": "Andere",
        "disconnect": "Trennen Sie die Verbindung",
        "stay": "Bleiben Sie",
        "contact": "Kontakt",
        "update": "Aktualisieren",
        "lose_feature": "Wenn Sie die Verbindung unterbrechen, verlieren Sie die folgenden Privilegien",
        "which_module": "Bitte teilen Sie uns mit, aus welchem Grund Sie sich abmelden. Dies wird uns helfen, uns in Zukunft zu verbessern!",
        "suggestion_title": "Haben Sie Vorschläge? Bitte teilen Sie uns diese unten mit",
        "other_platform_title": "Ziehen Sie auf eine andere Plattform um? Nennen Sie uns die Plattform",
        "stay_for_free": "Unser professionelles Team wird sich innerhalb von 1-2 Tagen mit Ihnen in Verbindung setzen, <br /> um Ihren kostenlosen Zugang für einen Monat zu aktivieren.",
        "btn_get_free": "Freimonat erhalten",
        "woo_free": "DSers für WooCommerce ist völlig kostenlos.",
        "reason": {
            "feature": {
                "btn": "Funktion",
                "ali_account": "Ich konnte kein AliExpress-Konto verknüpfen",
                "order_process": "Ich bin mit der Bestellabwicklung unzufrieden",
                "product_edit": "Ich bin nicht zufrieden mit den Produkteditionsfunktionen",
                "import": "Ich bin mit den Produktimportfunktionen nicht zufrieden",
                "mapping": "Ich bin mit der Mapping-Funktion nicht zufrieden."
            },
            "price": {
                "btn": "Preis",
                "expensive": "DSers ist zu teuer"
            },
            "other": {
                "btn": "Andere",
                "reinstall": "Es gibt ein Problem mit dem Shop, ich werde ihn später neu installieren",
                "fullillment_center": "Ich möchte mit einem Fulfillment Center oder einem Agenten arbeiten",
                "close_store": "Ich schließe oder verkaufe den Shop / ich schließe mein Shopify-Geschäft",
                "test_store": "Es ist ein Testshop"
            }
        },
        "dsers_feature": {
            "ali": "Nur offizieller AliExpress-Partner mit Zugang zur AliExpress-Whitelist",
            "import": "Importieren und bearbeiten Sie Produkte von AliExpress schnell und einfach",
            "order": "Mehrere Bestellungen in <br /> Minutenschnelle aufgeben!",
            "reward": "Arbeiten Sie mit dem Admitad Partnerprogramm, um mehr Geld zu verdienen"
        },
        "callback_title": "Vielen Dank für Ihr Feedback! <br /> Hoffentlich können wir bald wieder zusammenarbeiten.",
        "failed_link_ae": "Ich konnte mich nicht mit dem AliExpress-Konto verbinden.",
        "failed_add_shopify": "Ich konnte meinen Shopify-Shop nicht hinzufügen.",
        "not_satisfied_with_import": "Ich bin mit der Produktimportfunktion nicht zufrieden.",
        "not_satisfied_with_mapping": "Ich bin mit der Mapping-Funktion nicht zufrieden.",
        "not_satisfied_with_bulk_order": "Ich bin mit der Funktion für Großbestellungen unzufrieden.",
        "not_satisfied_with_setting": "Ich bin mit den Funktionen in den Einstellungen nicht zufrieden.",
        "closing_store_or_business": "Ich schließe/verkaufe den Laden oder ich schließe mein Shopify-Geschäft oder es ist ein Testladen."
    }
}