
    export default 
    {
    "about": {
        "title": "Willkommen",
        "description": "Wir bei DSers glauben, dass jeder eine schnelle und effiziente Bestellmethode verdient. Effizienz und Innovation sind unser Hauptziel und wir bemühen uns, alle technischen Barrieren oder Schwierigkeiten zu beseitigen, die Dropshipper daran hindern können, Waren zu verkaufen und Bestellungen abzuschließen. Wir freuen uns darauf, Sie auf Ihrem Weg zu unterstützen!",
        "our_story": "Unsere Geschichte",
        "our_story_1": "DSers begann im Jahr 2018. Nach der Erfahrung mit mehreren Auftragsverwaltungssoftware, erkannten wir, dass die Verarbeitungsgeschwindigkeit der bestehenden Auftragsverwaltungslösungen nicht ausreichte, um die sich wiederholende Arbeit der Dropshipper zu erleichtern. Herkömmliche Auftragsabwicklungsdienste sind zu komplex, zeitaufwendig und kostspielig zu verwalten.",
        "our_story_2": "Wir haben die Website DSers.com entwickelt und dabei die Sichtweise der Nutzer berücksichtigt. Wir möchten eine Plattform anbieten, die es nicht erforderlich macht, dass Sie Stunden mit der Bearbeitung von Bestellungen verbringen. Wir halten es einfach, damit sich die Nutzer auf die Verbesserung ihres Marketings und ihrer Markenbildung konzentrieren können. Das Wichtigste ist - es ist kostenlos. Sie können ein logistisches Preisscreening einrichten, um Kosten zu senken, Ihre Waren verwalten oder mit der Stapelverarbeitung Ihrer Bestellungen beginnen.",
        "our_story_3": "Nachdem wir eine steigende Nachfrage nach E-Commerce-Lösungen festgestellt haben, haben wir die einzige voll funktionsfähige, provisionsfreie Online-Verwaltungsplattform entwickelt, die es Dropshippern ermöglicht, ihre eigenen Bestellungen und Verkaufsartikel zu verwalten.",
        "our_story_4": "Heute sind wir stolz darauf, unabhängige Einzelpersonen und Teams auf der ganzen Welt zu unterstützen. Jeder Dropshipper sollte eine Plattform haben, die die Dinge vereinfacht und die Hände frei macht, um Bestellungen abzuschließen. Wir freuen uns, wenn Sie mehr Zeit damit verbringen, Ihren Umsatz zu steigern.",
        "our_values": "Unsere Werte",
        "our_values_1": "Jeder sollte eine Auftragsverwaltungssoftware haben, die wirklich Zeit und Kosten sparen kann. Wir glauben, dass jeder einen reibungslosen Arbeitsablauf haben sollte. Wenn Sie zeigen und klicken können, können Sie unsere kostenlose und intuitive Software zur Verwaltung von Großaufträgen verwenden.",
        "our_values_2": "Unsere Kunden bedeuten die Welt",
        "our_values_3": "Bei DSers sind wir bestrebt, die Produkte anzubieten, die unsere Kunden brauchen. Ein großer Teil unseres Brainstorming-Prozesses besteht darin, das Feedback unserer Kunden zu prüfen, um sicherzustellen, dass Sie richtig behandelt werden und dass wir die neuen Funktionen entwickeln, die Sie brauchen.",
        "our_values_4": "Interne Fertigung",
        "our_values_5": "Wir setzen uns persönlich dafür ein, den besten Service zu bieten. Von der Kundenbetreuung bis hin zu Produktdesign und -entwicklung wird alles von unserem professionellen (und netten) Team geleistet."
    }
}