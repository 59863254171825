import { Get, Post } from '../utils/request';

export async function getProductList(payload) {
  return Get('/csv/product', payload);
}

export async function getOrderList(payload) {
  return Get('/csv/orders', payload);
}

export async function uploadproduct(payload) {
  return Post('/csv/product/import', payload);
}

export async function uploadorder(payload) {
  return Post('/csv/order/import', payload);
}

export async function subOrder(payload) {
  return Post('/csv/order/place', payload);
}

export async function getLoopOrder(payload) {
  return Get('/csv/order/status', payload);
}
export async function getAeProductDetails(payload) {
  return Get('/prod/ali', payload);
}
