import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Icon, Button } from 'antd';
import { connect } from 'dva';
import intl from 'react-intl-universal';

import bg from '../../assets/banner/brazilBg.png';
import styles from './BrazilNotice.less';
import service from '../../assets/bargain/service.png';
import icon1 from '../../assets/banner/icon_1.png';
import icon2 from '../../assets/banner/icon_2.png';
import icon3 from '../../assets/banner/icon_3.png';
import flag from '../../assets/banner/flag.png';

import { brazilBannerListMap, brazilBannerList } from '../../utils/bannerWhiteList';
import DsQRCode from '../DsQRCode';

const modalRoot = document.getElementById('root');
const getDuration = day => 24 * 60 * 60 * 1000 * day;

function BrazilNotice({ userId, comment }) {
  const [visible, setVisible] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [href, setHref] = useState('');
  const isBrazilBannerUser = brazilBannerList.includes(userId);
  const [text, setText] = useState('');

  useEffect(() => {
    if (isBrazilBannerUser && visible) {
      setText('https://wa.me/' + brazilBannerListMap[userId][2]);
      setName(brazilBannerListMap[userId][0]);
      setEmail(brazilBannerListMap[userId][1]);
      setHref('mailto:' + brazilBannerListMap[userId][1]);
    }
  });

  useEffect(() => {
    const lastShowTime = localStorage.getItem('commentLastShow');
    let timeGap = -1;
    if (lastShowTime) {
      const currentTime = Date.now();
      timeGap = currentTime - lastShowTime;
    }
    const commentFlag = localStorage.getItem('comment');
    if (commentFlag) {
      setVisible(false);
    } else {
      if (timeGap == -1) {
        setVisible(true);
      } else if (timeGap > getDuration(1)) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, []);

  useEffect(() => {
    if (comment.visible) {
      setVisible(true);
    }
  }, [comment]);

  if (!visible || !isBrazilBannerUser) {
    return null;
  }

  const closeBanner = () => {
    setVisible(false);
    if (!comment.visible) {
      localStorage.setItem('commentLastShow', Date.now()); // 记录时间戳 一天倒计时
    }
  };

  console.log('text-------', text);
  // console.log('visible-----', visible);
  // console.log('name-------', name);
  console.log('email-------', name);
  console.log('brazilBannerListMap[userId][1]', brazilBannerListMap[userId][1]);
  console.log('href-------', name);

  return ReactDOM.createPortal(
    <>
      <Icon
        type="close"
        className={styles.close}
        onClick={() => closeBanner()}
      />
      <div className={styles.buttonCon}>
        <button onClick={()=> {window.location.href= href;}} className={styles.golbalButton}>GET 40% SHIPPING DISCOUNT &gt;&gt;</button>
      </div>
      <div className={styles.supportCon}>
        <div className={styles.support}>
          <div className={styles.serviceCon}>
            <img className={styles.service} src={service} />
          </div>
          <div className={styles.title}>
            {intl.get('bargain.one_click.v5.miss')}
            <strong>{' '}{name}</strong>
          </div>
          <div className={styles.declareList}>
            <div className={styles.list}>
              <div className={styles.listOne}>
                {intl.get('bargain.one_click.v5.service_text_1')}
              </div>
            </div>
            <div className={styles.list}>
              <div className={styles.listOne}>
                {intl.get('bargain.one_click.v5.service_text_2')}
              </div>
            </div>
            <div className={styles.list}>
              <div className={styles.listOne}>
                {intl.get('bargain.one_click.v5.service_text_3')}
              </div>
            </div>
            <div className={styles.footer}>
              <div className={styles.text}>
                {intl.get('bargain.one_click.v5.service_text_4')}
                <br />
                <a className={styles.email} href={href}>
                  {email}
                </a>
              </div>
              <div className={styles.qrCode}>
                <DsQRCode text={text} width="70" name="qrcode" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.notice}>
        <div className={styles.body}>
          <img src={bg} alt="" />
          <div className={styles.titleCon}>
            <div className={styles.title1}>
              UP TO 40% SHIPPING FEES DISCOUNT
            </div>
            <div className={styles.title2}>AliExpress official shipping servcie</div>
            <div className={styles.title2}>AliExpress Saver Shipping</div>
          </div>
          <div className={styles.promotionCon}>
            <button className={styles.pro_title}>Promotion Plan</button>
            <div className={styles.pro_list}>
              <div className={styles.listOne}>
                <img src={icon1} className={styles.star} />
                <span className={styles.title}>Details</span>
                <div className={styles.text}>AliExpress officially promote a cheaper but standard shipping method with tracking number.<br/> Special discount for Brazil market only.</div>
              </div>
              <div className={styles.listOne}>
                <img src={icon1} className={styles.star} />
                <span className={styles.title}>Data</span>
                <div className={styles.text}>2021.9.01~2021.10.31</div>
              </div>
              <div className={styles.listOne}>
                <img src={icon1} className={styles.star} />
                <span className={styles.title}>Regulations</span>
                <div className={styles.text}>Product total cost under $10.<br/>Shipping cost comes with up to 40% discount.<br/>Talk to your account manager to apply.</div>
              </div>
            </div>
          </div>
          <div className={styles.reasonCon}>
            <button className={styles.reason_title}>Why AliExpress Saver Shipping</button>
            <div className={styles.list}>
              <div className={styles.leftList}>
                <div className={styles.title}>AliExpress Standard Shipping</div>
                <div className={styles.list}>
                  <div className={styles.listOne}>
                    <img src={icon2} className={styles.icon} />
                    <span className={styles.text}>15 business day shipping</span>
                  </div>
                  <div className={styles.listOne}>
                    <img src={icon2} className={styles.icon} />
                    <span className={styles.text}>Higher cost</span>
                  </div>
                  <div className={styles.listOne}>
                    <img src={icon2} className={styles.icon} />
                    <span className={styles.text}>Tracking available</span>
                  </div>
                  <div className={styles.listOne}>
                    <img src={icon2} className={styles.icon} />
                    <span className={styles.text}>
                      100% refund for lost package
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.rightList}>
                <div className={styles.title}>AliExpress Saver Shipping</div>
                <div className={styles.list}>
                  <div className={styles.listOne}>
                    <img src={icon3} className={styles.icon} />
                    <span className={styles.text}>15 business day shipping</span>
                  </div>
                  <div className={styles.listOne}>
                    <img src={icon3} className={styles.icon} />
                    <span className={styles.text}>Lower cost</span>
                  </div>
                  <div className={styles.listOne}>
                    <img src={icon3} className={styles.icon} />
                    <span className={styles.text}>Up to 40% shipping discount</span>
                  </div>
                  <div className={styles.listOne}>
                    <img src={icon3} className={styles.icon} />
                    <span className={styles.text}>Tracking available</span>
                  </div>
                  <div className={styles.listOne}>
                    <img src={icon3} className={styles.icon} />
                    <span className={styles.text}>100% refund in case lost</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src={flag} className={styles.flag} />
        </div>
      </div>
    </>,
    modalRoot
  );
}

export default connect(({ global }) => ({
  comment: global.comment
}))(BrazilNotice);
