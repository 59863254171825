import React, { ChangeEvent } from 'react';
import { Button, Input, Modal,  } from 'antd';
// import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import styles from './AsyncConfirm.module.less';
import { ModalProps } from 'antd/lib/modal';
import classNames from 'classnames';

interface AsyncConfirmModalProps extends ModalProps {
  inputValidateStr?: string;
  shouldInputConfirm?: boolean;
}

export class AsyncConfirmBase extends React.PureComponent<AsyncConfirmModalProps> {
  confirmCallback?: (res: boolean | null | PromiseLike<boolean | null>) => void = undefined;

  state = {
    visible: false,
    inputValue: '',
  };

  showConfirm = async () => {
    this.setState({
      visible: true,
      inputValue: '',
    });
    return new Promise<boolean | null>((resolve) => {
      this.confirmCallback = resolve;
    });
  };

  onInputValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ inputValue: e.target.value });
  };

  onCancel = () => {
    this.confirmCallback && this.confirmCallback(false);
    this.setState({ visible: false });
  };

  onClose = () => {
    this.confirmCallback && this.confirmCallback(null);
    this.setState({ visible: false });
  }

  onOk = () => {
    this.confirmCallback && this.confirmCallback(true);
    this.setState({ visible: false });
  };

  render() {
    const { visible, inputValue, } = this.state;
    const { children, shouldInputConfirm, okText, cancelText, closable, className } = this.props;
    const { inputValidateStr = 'CONFIRM' } = this.props;
    const okButtonDisabled = shouldInputConfirm && inputValue.trim().toLowerCase() !== inputValidateStr.trim().toLowerCase();
    return (
      <Modal
        {...this.props}
        zIndex={3000}
        closable={closable || false}
        maskClosable={false}
        className={classNames(styles.asyncConfirm, className || '')}
        onCancel={() => {
          this.onClose();
        }}
        visible={visible}
        footer={[
          <Button disabled={okButtonDisabled} onClick={this.onOk}>
            {okText || 'OK'}
          </Button>,
          <Button onClick={this.onCancel} type="primary">
            {cancelText || 'CANCEL'}
          </Button>,
        ]}
      >
        {children}
        {shouldInputConfirm ? <Input value={inputValue} onChange={this.onInputValueChange} /> : null}
      </Modal>
    );
  }
}

export const AsyncConfirm = AsyncConfirmBase;
