
    export default 
    {
    "pricing": {
        "title": "Más rendimiento con menos coste",
        "desc": "Plan gratuito disponible",
        "tipSelectPlan": "Por favor, selecciona un plan para continuar",
        "Basic": "Básico",
        "BasicText": "(Siempre gratis)",
        "Advanced": "Avanzado",
        "AdvancedText": " días de prueba gratis",
        "No_card_AdvancedText": "No se necesita tarjeta de crédito",
        "No_card_ProText": "No se necesita tarjeta de crédito",
        "No_card_StandardText": "No se necesita tarjeta de crédito",
        "Standard": "Estándar",
        "StandardText": " días de prueba gratis",
        "Pro": "Pro",
        "ProText": " días de prueba gratis",
        "Business": "Negocio",
        "Enterprise": "Empresa",
        "Admonth": "<span class=\"mount mounttop\">$</span><span class=\"mountP\">19.9</span><span class=\"mount\">/mes</span>",
        "Prmonth": "<span class=\"mount mounttop\">$</span>49.9<span class=\"mount\">/mes</span>",
        "Stmonth": "<span class=\"mount mounttop\">$</span>1.99<span class=\"mount\">/mes</span>",
        "Enmonth": "<span class=\"mount mounttop\">$</span>499<span class=\"mount\">/mes</span>",
        "NewStmonth": "<span class=\"mount mounttop\">$</span>9.9<span class=\"mount\">/mes</span>",
        "Adannually": "<span class=\"mount mounttop\">$<s class=\"deleter\">19.9</s></span><span class=\"mountP\">15.9</span><span class=\"mount\">/mes</span>",
        "Prannually": "<span class=\"mount mounttop\">$<s class=\"deleter\">49.9</s></span>39.9<span class=\"mount\">/mes</span>",
        "Stannually": "<span class=\"mount mounttop\">$</span>1.99<span class=\"mount\">/mes</span>",
        "Enannually": "<span class=\"mount mounttop\">$<span class=\"startat\">Empieza en</span><s class=\"deleterStart\">499</s></span>399<span class=\"mount\">/mes</span>",
        "NewStannually": "<span class=\"mount mounttop\">$<s class=\"deleter\">9.9</s></span>7.9<span class=\"mount\">/mes</span>",
        "AdannuallyText": "$15,9 por mes <br/> Facturado a $190,8 anualmente <br/> Ahorras $48 por año ",
        "AdannuallyText_2": "$15.9 por mes <br/> Facturado a $190.8 anualmente <br/> ¡2 meses GRATIS! ",
        "AdannuallyText_3": "Facturado a $190.8 anualmente <br/> ¡2 meses GRATIS! ",
        "PrannuallyText": "$39,9 por mes <br/> Facturado a $478,8 anualmente <br/> Ahorras $120 por año ",
        "PrannuallyText_2": "$39.9 por mes <br/> Facturado a $478.8 anualmente <br/> ¡2 meses GRATIS! ",
        "PrannuallyText_3": "Facturado a $478.8 anualmente <br/> ¡2 meses GRATIS! ",
        "StannuallyText": "Facturado a $23.88 anualmente",
        "EnannuallyText": "Facturado a $4,788 anualmente <br/> ¡2 meses GRATIS! ",
        "NewStannuallyText": "Facturado a $94.9 anualmente <br/> ¡2 meses GRATIS! ",
        "Free": "Gratis",
        "Monthlyfee": "Cuota mensual",
        "Annualfee": "Cuota anual",
        "Feature": "Característica",
        "enterprise_current_plan": "Tu plan de suscripción actual: {plan}",
        "EnDescribe": "Contáctanos para obtener más información",
        "circulationData": [
            {
                "Feature": "Atención al cliente",
                "explain": "Servicio de mensajería y chat en directo 24*7",
                "Basic": "Disponible",
                "Standard": "Disponible",
                "AdvancedL": "<span class=\"blone and\">Disponible y con máxima prioridad</span>",
                "Pro": "<span class=\"blone and\">Disponible y con máxima prioridad</span>",
                "Enterprise": "<span class=\"blone and\">Disponible y con máxima prioridad</span>",
                "NewStandard": "Disponible"
            },
            {
                "Feature": "Procesar pedidos masivamente",
                "explain": "Procesar varios pedidos a la vez a AliExpress",
                "Basic": "Disponible",
                "Standard": "Disponible",
                "AdvancedL": "<span class=\"blone and\">Disponible y con alta velocidad para procesar más de 100 pedidos</span>",
                "Pro": "<span class=\"blone and\">Disponible y con alta velocidad para procesar más de 100 pedidos</span>",
                "Enterprise": "<span class=\"blone and\">Disponible y con alta velocidad para procesar más de 100 pedidos</span>",
                "NewStandard": "Disponible"
            },
            {
                "Feature": "Límite de la tienda",
                "explain": "Administrar varias tiendas en una sola cuenta de DSers",
                "Basic": "<span class=\"blone\">3</span>",
                "BasicText": "",
                "Standard": "<span class=\"blone\">5</span>",
                "StandardText": "",
                "AdvancedL": "<span class=\"blone and\">10</span>",
                "AdvancedText": "(Popular para los comerciantes que venden en diferentes países)",
                "Pro": "<span class=\"blone and\">25</span>",
                "ProText": "(Popular para los comerciantes que venden en diferentes nichos y países)",
                "Enterprise": "<span class=\"blone and\">50</span>",
                "EnterpriseText": "",
                "NewStandard": "<span class=\"blone\">3</span>"
            },
            {
                "Feature": "Límite de productos",
                "Basic": "<span class=\"blone\">3000 </span> /cuenta",
                "BasicText": "",
                "Standard": "<span class=\"blone\">7500</span>  /cuenta",
                "StandardText": "",
                "AdvancedL": "<span class=\"blone and \">20000 </span> /cuenta",
                "AdvancedText": "",
                "Pro": "<span class=\"blone and \">75000 </span> /cuenta",
                "ProText": "",
                "Enterprise": "<span class=\"blone and \">100,000 </span> /cuenta",
                "EnterpriseText": "",
                "NewStandard": "<span class=\"blone\">3000 </span> /cuenta"
            },
            {
                "Feature": "Transformador de envío AliExpress Saver",
                "explain": "Aprovecha el método de envío más económico de AliExpress",
                "Basic": "<span class='line2'>—</span>",
                "BasicText": "",
                "Standard": "<span class='line2'>—</span>",
                "AdvancedL": "<span class=\"blone and \">Hasta 10 productos</span>",
                "Pro": "<span class=\"blone and \">Hasta 20 productos</span>",
                "ProText": "",
                "Enterprise": "<span class=\"blone and \">Productos Ilimitados</span> ",
                "EnterpriseText": "",
                "NewStandard": "<span class='line2'>—</span>"
            },
            {
                "Feature": "Change online products price",
                "explain": "",
                "Basic": "<span style=\"color:#FF8F00;font-weight:600\">1time/30days</span>",
                "BasicText": "",
                "Standard": "<span style=\"color:#FF8F00;font-weight:600\">1time/30days</span>",
                "StandardText": "",
                "AdvancedL": "<span style=\"color:#FF8F00;font-weight:600\">4times/30days</span>",
                "AdvancedText": "",
                "Pro": "<span style=\"color:#FF8F00;font-weight:600\">(10 veces / 30 días)</span>",
                "ProText": "",
                "Enterprise": "<span style=\"color:#FF8F00;font-weight:600\">(10 veces / 30 días)</span>",
                "EnterpriseText": "",
                "NewStandard": "<span style=\"color:#FF8F00;font-weight:600\">1time/30days</span>",
                "NewStandardText": "",
                "CustomBasic": "<span style=\"color:#FF8F00;font-weight:600\">1time/30days</span>"
            },
            {
                "Feature": "Gestión del inventario",
                "Basic": "Sólo notificaciones",
                "Standard": "<span style=\"color:#FF8F00;font-weight:600\">Actualización automática</span>",
                "AdvancedL": "<span style=\"color:#FF8F00;font-weight:600\">Actualización automática</span>",
                "Pro": "<span style=\"color:#FF8F00;font-weight:600\">Actualización automática</span>",
                "Enterprise": "<span style=\"color:#FF8F00;font-weight:600\">Actualización automática</span>",
                "NewStandard": "<span style=\"color:#FF8F00;font-weight:600\">Actualización automática</span>"
            },
            {
                "Feature": "Gestión de cambios en los costes",
                "Basic": "Sólo notificaciones",
                "Standard": "Sólo notificaciones",
                "AdvancedL": "<span style=\"color:#FF8F00;font-weight:600\">Actualización automática</span>",
                "Pro": "<span style=\"color:#FF8F00;font-weight:600\">Actualización automática</span>",
                "Enterprise": "<span style=\"color:#FF8F00;font-weight:600\">Actualización automática</span>",
                "NewStandard": "Sólo notificaciones"
            },
            {
                "Feature": "Regulación de precios automatizada",
                "Basic": "Sólo incremento fijo o porcentual",
                "Standard": "Sólo incremento fijo o porcentual",
                "AdvancedL": "<span style=\"color:#FF8F00;font-weight:600\">Reglamento basado en los márgenes de ganancia, los gastos de envío, etc.</span>",
                "Pro": "<span style=\"color:#FF8F00;font-weight:600\">Reglamento basado en los márgenes de ganancia, los gastos de envío, etc.</span>",
                "Enterprise": "<span style=\"color:#FF8F00;font-weight:600\">Reglamento basado en los márgenes de ganancia, los gastos de envío, etc.</span>",
                "NewStandard": "Aumento fijo o porcentual únicamente"
            },
            {
                "Feature": "Mapeo de variantes.",
                "explain": "Gestiona tantos proveedores como quieras sin modificar nada en tu tienda",
                "Basic": "Hasta 2 proveedores por producto y sin mapeo de región de destino.",
                "Standard": "Hasta 2 proveedores por producto y sin mapeo de región de destino.",
                "AdvancedL": "<span style=\"color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px\">Mapear cada variante según la región de destino</span>",
                "Pro": "<span style=\"color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px\">Mapear cada variante según la región de destino</span>",
                "Enterprise": "<span style=\"color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px\">Mapear cada variante según la región de destino</span>",
                "NewStandard": "Hasta 2 proveedores por producto y sin mapeo de regiones de destino."
            },
            {
                "Feature": "Mapeo de BOGO/Paquetes",
                "explain": "Crear ventas de productos adicionales para conseguir más ganancias.",
                "Basic": "<span class='line2'>—</span>",
                "BasicText": "",
                "Standard": "<span class='line2'>—</span>",
                "AdvancedL": "ok",
                "AdvancedText": "",
                "Pro": "ok",
                "ProText": "",
                "Enterprise": "ok",
                "EnterpriseText": "",
                "NewStandard": "<span class='line2'>—</span>"
            },
            {
                "Feature": "Seguimiento",
                "explain": "",
                "Basic": "<span class='line2'>—</span>",
                "BasicText": "",
                "Standard": "<span class='line2'>—</span>",
                "StandardText": "",
                "AdvancedL": "ok",
                "AdvancedText": "",
                "Pro": "ok",
                "ProText": "",
                "Enterprise": "ok",
                "EnterpriseText": "",
                "NewStandard": "<span class='line2'>—</span>",
                "NewStandardText": "",
                "CustomBasic": "<span class='line2'>—</span>"
            },
            {
                "Feature": "Cuenta de Personal",
                "explain": "",
                "Basic": "<span class='line2'>—</span>",
                "BasicText": "",
                "Standard": "<span class='line2'>—</span>",
                "StandardText": "",
                "AdvancedL": "<span style=\"color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px\">5</span>",
                "AdvancedText": "",
                "Pro": "<span style=\"color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px\">Unlimited</span>",
                "ProText": "",
                "Enterprise": "<span style=\"color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px\">Unlimited</span>",
                "EnterpriseText": "",
                "NewStandard": "<span class='line2'>—</span>",
                "NewStandardText": "",
                "CustomBasic": "<span class='line2'>—</span>"
            },
            {
                "Feature": "Afiliado",
                "Basic": "<span class='line2'>—</span>",
                "BasicText": "",
                "Standard": "<span class='line2'>—</span>",
                "StandardText": "",
                "AdvancedL": "ok",
                "AdvancedText": "",
                "Pro": "ok",
                "ProText": "",
                "Enterprise": "ok",
                "EnterpriseText": "",
                "NewStandard": "<span class='line2'>—</span>"
            },
            {
                "Feature": "Personalización",
                "explain": "Personaliza la función de DSers y consigue un mayor volumen de uso.",
                "Basic": "<span class='line2'>—</span>",
                "BasicText": "",
                "Standard": "<span class='line2'>—</span>",
                "StandardText": "",
                "AdvancedL": "<span class='line2'>—</span>",
                "AdvancedText": "",
                "Pro": "<span class='line2'>—</span>",
                "Enterprise": "<span style=\"color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px\">Se puede generar una tarifa adicional en función de la solicitud específica o del volumen de uso.</span>",
                "NewStandard": "<span class='line2'>—</span>"
            },
            {
                Feature: 'Envíe el producto y el coste de envío a Shopify',
                explain: '',
                Basic: `<span class='line2'>—</span>`,
                BasicText: '',
                Standard: `<span style="color:#FF8F00;font-weight:600">Cuando se empuja el producto, el coste de los productos del proveedor y el coste de envío en [Regla de Precios Avanzada] se devuelven juntos al coste por artículo de Shopify.</span>`,
                StandardText: '',
                AdvancedL: `<span style="color:#FF8F00;font-weight:600">Cuando se empuja el producto, el coste de los productos del proveedor y el coste de envío en [Regla de Precios Avanzada] se devuelven juntos al coste por artículo de Shopify.</span>`,
                AdvancedText: '',
                Pro: `<span style="color:#FF8F00;font-weight:600">Cuando se empuja el producto, el coste de los productos del proveedor y el coste de envío en [Regla de Precios Avanzada] se devuelven juntos al coste por artículo de Shopify.</span>`,
                ProText: '',
                Enterprise:`<span style="color:#FF8F00;font-weight:600">Cuando se empuja el producto, el coste de los productos del proveedor y el coste de envío en [Regla de Precios Avanzada] se devuelven juntos al coste por artículo de Shopify.</span>`,
                EnterpriseText:'',
                NewStandard:'Fixed or percentage increase only',
                NewStandardText:'',
                CustomBasic:`<span class='line2'>—</span>`
            },
            {
                Feature: 'Synchronize Shopify order customization information',
                explain: ``,
                Basic: "<span class='line2'>—</span>",
                BasicText: '',
                Standard: "<span class='line2'>—</span>",
                StandardText: '',
                AdvancedL: `<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Send the customization information from Shopify to suppliers along with the order.</span>`,
                AdvancedText: '',
                Pro: `<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Send the customization information from Shopify to suppliers along with the order.</span>`,
                ProText: '',
                Enterprise:`<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Send the customization information from Shopify to suppliers along with the order.</span>`,
                EnterpriseText:'',
                NewStandard:"<span class='line2'>—</span>",
                NewStandardText:'',
                CustomBasic:"<span class='line2'>—</span>"
              },
        ],
        "pricingIncludesTitle": "CARACTERÍSTICAS INCLUIDAS EN TODOS LOS PLANES",
        "pricingIncludesData": [
            {
                "title": "Preselección de métodos de envío.",
                "desc": "Configurar métodos de envío automatizados para los pedidos de AliExpress basados en países, productos o condiciones"
            },
            {
                "title": "Corrección automática de direcciones.",
                "desc": "Solución automática de la mayoría de los problemas de dirección para reducir el trabajo de los empleados."
            },
            {
                "title": "Actualización automática del estado de los pedidos.",
                "desc": "No más sincronización manual a través de la extensión de Chrome. Todo está automatizado."
            },
            {
                "title": "Sincronizar automáticamente los números de seguimiento con Shopify",
                "desc": "Sincronizar automáticamente los números de seguimiento de AliExpress y actualizarlos en Shopify"
            },
            {
                "title": "Sincronización automática de los números de seguimiento con PayPal",
                "desc": "La reputación se mejora enviando los números de seguimiento a la cuenta PayPal."
            },
            {
                "title": "Extensión para Chrome de DSers.",
                "desc": "Importador de productos de AliExpress y procesamiento de pedidos masivos con cupones recibidos"
            },
            {
                "title": "Mensaje automatizado a los proveedores.",
                "desc": "Envío de plantillas de mensajes para informar a los proveedores sobre sus pedidos."
            },
            {
                "title": "Envío de notas de pedido personalizadas de los clientes a los proveedores.",
                "desc": "Resulta especialmente popular entre los usuarios que tienen negocios POD."
            },
            {
                "title": "Configuración del seguimiento en el correo electrónico de confirmación de envío.",
                "desc": "Personalización de la información de seguimiento en el correo electrónico de Shopify."
            },
            {
                "title": "Búsqueda de proveedores en tendencia.",
                "desc": "Colección de proveedores calificados y en tendencia en AliExpress."
            },
            {
                "title": "Optimizador de proveedores.",
                "desc": "Búsqueda del mejor proveedor para productos específicos con un solo clic."
            },
            {
                "title": "Lista de importación.",
                "desc": "Gestión de productos importados a DSers y fácil migración de productos entre las tiendas. "
            },
            {
                "title": "División de productos.",
                "desc": "División de un producto en varios productos diferentes para personalizar los listados. "
            },
            {
                "title": "Publicación de productos a la tienda de forma masiva.",
                "desc": "Publicación de los productos seleccionados de AliExpress en tu tienda después de la edición."
            },
            {
                "title": "Configuración de la zona horaria y la moneda.",
                "desc": "Configuración de la zona horaria y las monedas para tu tienda."
            }
        ],
        "Unfinishedfront": "No ha completado la autorización en Shopify.<br />\r\n    Por favor, haz clic en",
        "Unfinishedcentre": "Suscribirse",
        "Unfinishedqueen": "para continuar.",
        "Unfinished": "Si quieres cambiar tu plan de DSers, por favor, primero cancela la actual<br />\r\n    suscripción en Shopify como se muestra a continuación, así podrás volver a seleccionar el plan.",
        "UnfinishedNote": "Aviso: <br />\r\n    1. El cobro no se realizará hasta el 31 de diciembre de 2020.\r <br />\n    2. Por favor, NO desinstales la aplicación DSers en Shopify, simplemente cancela la suscripción actual. <br />\r\n    si quieres cambiar de plan. La desinstalación causará la pérdida de datos en DSers.",
        "AlertText": "Por favor, actualiza para obtener más funciones y ventajas. Ver más detalles sobre el<a href=\"https://help.dsers.com/subscription-plans-presentation-for-shopify/\" target=\"_blank\" > Plan DSers </a> .",
        "pop_Up": "Por favor, selecciona la tienda que utilizarás para que DSers te facture",
        "display": "Recomendado",
        "HomeBasicButton": "Comenzar",
        "HomeAdvancedButton": "Comenzar la prueba gratuita",
        "HomeProButton": "Comenzar la prueba gratuita",
        "HomeStandardButton": "Comenzar la prueba gratuita",
        "Select": "Seleccionar",
        "Current": "Plan actual",
        "Confirm": "Confirmar",
        "ConfirmCancel": "Volver",
        "Access": "Acceder",
        "AccessText": "Gracias por tu suscripción. Por favor, ingresa a tu cuenta DSers, ¡disfrútala!",
        "Monthly": "Plan de pago mensual",
        "Annually": "Plan de pago anual",
        "working": "Gracias por trabajar con DSers.",
        "like": "¿Te gustó?",
        "over": "Tu prueba gratuita de 14 días ha terminado.",
        "confirm": "Por favor, haz clic en el botón de abajo para confirmar la suscripción anual en Shopify.",
        "Contnct": "Contacto",
        "Subscribe": "Suscribirse",
        "ChangePlan": "Cambiar de plan",
        "selectShop": "Por favor, selecciona lo que quieres mantener en el plan actual de DSers.",
        "keepShop": "Por favor, selecciona como máximo {quantity} tiendas que quieras mantener.",
        "PlatformAndStores": "Plataforma y tiendas",
        "ShopifyPlatform": "Plataforma Shopify",
        "Woocommerceplatform": "Plataforma WooCommerce",
        "errText": "La tienda de facturación por defecto { mistakeDomain } no está disponible. Por favor, verifica el estado de la tienda en Shopify o cambia la tienda de facturación por defecto en DSers.",
        "errTextTwo": "La tienda de facturación por defecto no está disponible. Por favor, verifica el estado de la tienda en Shopify o cambia la tienda de facturación por defecto en DSers.",
        "billing": "Tienda de facturación por defecto",
        "help": "La tienda de facturación es la que utilizas para pagar tu suscripción a DSers. Para una mejor experiencia, no cambies la tienda de facturación regularmente.",
        "StoreManagement": "Gestión de la tienda",
        "StoreManagementText": "Si decides degradar tu plan, DSers mantendrá tus tiendas desactivadas aquí para que puedas desconectar las tiendas no deseadas y activar otras de acuerdo a tu plan. Una vez desconectadas, no podrás añadir más tiendas de las que permite la restricción de los diferentes planes.",
        "ActiveStore": "Tienda activa",
        "InactiveStore": "Tienda inactiva",
        "Activate": "Activar",
        "Inactivated": "Desactivado",
        "modification": "Nuestro sistema ha detectado que has cambiado tu tienda de facturación por defecto a <span style=\"color:#00BFFF\"> {new}  </span>, suscripción bajo la tienda <span style=\"color:#00BFFF\"> {old} </span> ha caducado, Por favor, haz clic en el botón de abajo para confirmar la tienda de facturación por defecto en Shopify.",
        "go_to_shopify": "Ir a Shopify",
        "if_free_dec": "Si eres un usuario de prueba gratuita de Shopify o un usuario de una tienda en desarrollo de Shopify,<br />\n                  es posible que no puedas ver la página de arriba, pero verás la página de abajo.<br />\n                  Si no sabes cómo cancelar la suscripción actual<br />\n                  por favor, dirígete al administrador de Shopify para desinstalar DSers e instalarlo de nuevo, y así volver a seleccionar el plan.",
        "change_annual_plan_notion": "Si deseas cambiar tu plan anual actual, ponte en contacto con nuestro servicio de atención al cliente en <a href=\"https://www.messenger.com/t/DSersOfficial\" dd=\"src\" target=\"_blank\" rel=\"noopener noreferrer\">Facebook</a> o por <a href=\"https://www.messenger.com/t/DSersOfficial\">correo electrónico</a> para obtener el reembolso del coste restante de tu plan actual",
        "subscribe_tios": "La tienda de facturación por defecto está cerrada o desactivada. Por favor, dirígete a <a href=\"/app/select\">\n    Gestionar suscripción </a>\n    para cambiarla e intentar de nuevo",
        "subscription_expired": "Tu suscripción ha expirado, por favor renueva o cámbiala para continuar.",
        "subscription_network_tip": "If you encounter some network error or any other problems during the payment process, and the subscription is unsuccessful,please <a style=\"color:#00BFFF\" href={jumpUrl}>click here</a> to retry. You can also contact us and we will deal with it for you as soon as possible.",
        "subscription_network_tip_2": "Si durante el proceso de pago se produce algún error en la red o cualquier otro problema, y la suscripción no es exitosa, por favor, contáctanos y lo solucionaremos por ti lo antes posible.",
        "subscription_btn3": "El primer mes será",
        "subscription_btn4": "¡GRATIS!",
        "subscription_btn5": "¡Sólo por hoy!",
        "subscription_btn6": "Si actualizas a <span class=\"titlesd\">Estándar</span>  ahora, podrás: <br/> Añadir <span class=\"titlesd\">2</span> más tiendas, <br/> <span class=\"titlesd\">y más de 4,500</span> productos. <br/> El inventario de productos se actualizará automáticamente.",
        "subscription_btn7": "ACTUALIZAR AHORA",
        "subscription_btn8": "Mantener el Plan Básico",
        "subscription_btn9": "Actualiza a nuestro plan de pago anual",
        "subscription_btn10": "¡AHORRA $120!",
        "subscription_btn11": "Plan Mensual",
        "subscription_btn12": "<span class=\"blon\">49.9</span>/mes",
        "subscription_btn13": "Plan anual",
        "subscription_btn14": "<span class=\"blon\">478.8</span>/año",
        "subscription_btn15": "Costo mensual: <span class=\"blon_d\">$39.9</span>",
        "subscription_btn16": "14 días de prueba gratis",
        "subscription_btn17": "SELECCIONAR",
        "select_account": "Por favor, selecciona las  ",
        "select_account_2": "cuentas del personal que deseas conservar",
        "custom_pro": "Custom Pro",
        "Custom_EnterPrise": "Custom EnterPrise",
        "custom_basic": "Básico Personalizado",
        "custom_to_basic": "Cambiar al plan básico",
        "tobasictext": "Confirmar cambio",
        "oCancel": "Cancelar",
        "tobaiscnote": "Aviso",
        "tobaiscdes": "Después de pasarse al plan Básico, tendrás un límite de 3000 Productos, pero ya no disfrutarás de la sincronización automática de inventario y precios, y no podrás volver a cambiarte al actual Básico Personalizado. ¿Estás seguro de que quieres cambiar el plan?",
        "BasicTracking":"basic + tracking",
        "Basic500":"Basic+500",
        "Basic1000":"Basic+1000",
        "CustomBasicTracking":"Custom Basic+Tracking",
        "CustomBasic500":"Custom Basic+500",
        "CustomBasic1000":"Custom Basic+1000",
    }
}