export default {
  order: {
    "order_language":{
      "pay" : "Please pay your orders within 24h, otherwise they will be canceled by Tmall."
  },
    tab_tip: {
      pending: `"Pending" orders are orders for which the payment from your customers has not yet been captured in Shopify.
                You can not process or edit Pending orders on DSers until the payment has been captured.If an order is still in Pending after you captured the payment,
                please check <a
                  href='https://help.dsers.com/pending-orders-introduction-for-shopify/'
                  target="_blank"
                  data-ga="Pending - here"
                > here</a> to manually update the order status. Please contact us if this keeps happening.`,
      awaiting_order: `Orders in "Awaiting order" are orders for which the payment from your customers has been captured on Shopify and awaiting to be processed to AliExpress.
                      If you can't locate your new orders here, please check the "Pending" and "Fulfilled" tab. Please check how to process orders to AliExpress <a
                      href='https://help.dsers.com/fulfill-multiple-orders-from-start-to-finish-for-shopify/'
                      target="_blank"
                      data-ga="Awaiting order - here" > here</a>.`,
      awaiting_payment: `Orders in "Awaiting payment" are orders that have been placed to AliExpress but not paid yet.
                        The AliExpress order number is generated instantly when you place the order. Click <a
                          href='https://help.dsers.com/pay-multiple-orders-on-aliexpress-with-dsers-for-shopify/'
                          target="_blank"
                          data-ga="Awaiting payment - here1"
                        > here</a> to check how to make payment in bulk.
                        You can also check <a
                          href='https://help.dsers.com/re-order-awaiting-payment-orders-for-shopify/'
                          target="_blank"
                          data-ga="Awaiting payment - here2"
                        > here</a> about how to cancel and reorder. If you find that the order status not changed after making payment, please check <a
                          href='https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/'
                          target="_blank"
                          data-ga="Awaiting payment - here3"
                        > here</a>.`,
      awaiting_shipment: `Orders in "Awaiting shipment" have been paid on AliExpress but not yet shipped.
                          If you find that the tracking number is not syncing back after shipping, please check
                          <a
                            href='https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/'
                            target="_blank"
                            data-ga="Awaiting shipment - here"
                          >here</a>.
                          Please <b>DO NOT</b> request fulfillment on Shopify and <b>DO NOT</b> manually fulfill orders on Shopify or DSers.`,
      fulfilled: `Orders in "Fulfilled" are orders that have been shipped out by your AliExpress supplier  and orders that were automatically or manually fulfilled.
                  Once an order is fulfilled, we will no longer synchronize the order status or product price from AliExpress anymore.
                  Click <a
                    href='https://help.dsers.com/re-order-fulfilled-orders-for-shopify/'
                    target="_blank"
                    data-ga="Fulfilled - here1"
                  > here</a> to check how to deal with orders that were fulfilled by mistake.
                  You can also check <a
                    href='https://help.dsers.com/fulfill-multiple-orders-from-start-to-finish-for-shopify/'
                    target="_blank"
                    data-ga="Fulfilled - here2"
                  > here</a> to learn how DSers fulfill orders automatically.<br>
                  If you want to synchronize the latest Tracking Number of AliExpress orders, you can click the Sync Tracking Number from last 30 days button. We will synchronize the latest Tracking Number of AliExpress orders from the last 30 days.`,
      fulfilledFirstChange:`Orders in "Fulfilled" are orders that have been shipped out by your AliExpress supplier and orders that were automatically or manually fulfilled.`,
      fulfilledFirstChange_tit:`Once an order is fulfilled, we will no longer synchronize the order status or product price from AliExpress anymore. Click <a target="_blank" href="https://help.dsers.com/re-order-fulfilled-orders-for-shopify/">here</a> to check how to deal with orders that were fulfilled by mistake. You can also check <a target="_blank" href="https://help.dsers.com/fulfill-multiple-orders-from-start-to-finish-for-shopify/">here</a> to learn how DSers fulfill orders automatically.`,
      fulfilledDays:`You can synchronize the order status and tracking numbers`,
      fulfilledDays_tit:`If you want to synchronize the latest Tracking Number of AliExpress orders, you can click the Sync Tracking Number from last 30 days button. We will synchronize the latest Tracking Number of AliExpress orders from the last 30 days.`,
      canceled: `Orders in "Canceled" are orders that are (partially) Canceled / (partially) Refunded on AliExpress or Shopify.
                  You can bulk order the Canceled orders from AliExpress, but you cannot order the orders canceled of refunded on Shopify.
                  If you find that the order status is not updating after cancellation,
                  please check <a
                    href='https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/'
                    target="_blank"
                    data-ga="Canceled - here1"
                  >here</a>.
                  And you can also check <a
                    href='https://help.dsers.com/re-order-aliexpress-canceled-orders-for-shopify/'
                    target="_blank"
                    data-ga="Canceled - here2"
                  > here</a> to learn how DSers can help you with orders that keep getting canceled by AliExpress.`,
      canceledChange:`Orders in "Canceled" are orders that are (partially) Canceled / (partially) Refunded on AliExpress or Shopify.`,
      canceledChange_tit:`You can bulk order the Canceled orders from AliExpress, but you cannot order the orders canceled of refunded on Shopify. If you find that the order status is not updating after cancellation, please check <a target="_blank" href="https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/">here</a>. And you can also check <a target="_blank" href="https://help.dsers.com/re-order-aliexpress-canceled-orders-for-shopify/">here</a> to learn how DSers can help you with orders that keep getting canceled by AliExpress.`,
      failed_orders: `Orders in "Failed orders" are orders which could not be placed to AliExpress successfully due to errors or restrictions.
                      Check the customers' informations and deal with the errors so you can bulk order the Failed orders again.  There are some orders restrictions and rules for some countries, please click <a href='https://help.dsers.com/order-requirements-for-specific-countries-for-shopify/' target="_blank" data-ga="Failed orders - here">here</a> to get more information.`,
      awaiting_fulfillment: `Orders in "Awaiting fulfillment" are only partially fulfilled.
                            The orders will update and move to the "Fulfilled" tab later.
                            Then, we will synchronize the tracking number to Shopify and trigger Shopify to send only one email to your customer. Click
                            <a
                              href='https://help.dsers.com/awaiting-fulfillment-introduction-for-shopify/'
                              target="_blank"
                              data-ga="Awaiting fulfillment - here"
                            >
                              here
                            </a> to get more information.
                            Please <b>DO NOT</b> request fulfillment on Shopify and <b>DO NOT</b> manually fulfill orders on Shopify or DSers.`
    },
    //  d
    alert_fulfilled_by_other: {
      w1: 'Note please. ',
      w2:
        'Please check if there are any orders already fulfilled on platforms like Oberlo or Dropified.'
    }, //  d
    //  d
    rut_banner: {
      title: 'Orders to Chile RUT number requirements',
      btn_text: 'learn more'
    },
    alert_sync_by_extend: {
      w1: 'Please Note: We can now synchronize AliExpress order status in',
      w2: ' real time ',
      w3: 'with two steps !',
      w4: ' Click here ',
      w5: 'to view details.'
    }, //  d
    search_type: {
      order_no: 'Order No',
      note: 'Note',
      ali_order_no: 'Ali Order No',
      tm_order_no:"Tmall Order No",
      product: 'Product',
      email: 'E-mail',
      customer_name: 'Customer Name',
      tracking_no: 'Tracking No',
      searchOrder:'Search Order',
      agency_order_no: 'Agency Order No',
    },
    search_input_placeholder: 'You can choose a sort to search precisely',
    syncing: 'Syncing…',
    sync_ali_order: 'Sync Ali Order',
    sync_via_api:
      'Sync via API (You can manually sync from AliExpress {length} more time(s) today)', //  add
    sync_tn: 'Sync Tracking Number from last 30 days（You can manually sync from AliExpress {length} more time(s) today）',
    sync_tn_2:'Sync the latest Tracking Number with the Chrome Extension',
    sync_via_extension:
      'Sync via DSers Chrome Extension', //  add
    syncing_ali: 'Orders are synchronizing',
    sync_ali_completed: 'Orders synchronization completed',
    select_sync_range: 'Select the date range to update Tracking Numbers',
    sync_task_count: 'Number of orders that need to be synchronized',
    sync_except_time: 'Approximate time required',
    sync_ali_failed_result: {
      title: 'The situations below may cause the failure:',
      reason1: `1.The AliExpress account you currently log in doesn't match the one linked to DSers.`,
      reason2: '2.You logged out your AliExpress account during the synchronization.',
      reason3: '3.There was something wrong with your network.',
      contact: 'If you have checked the above but it still fails, please feel free to <a rel="noopener noreferrer" target="_blank" href="https://www.messenger.com/t/DSersOfficial" dd="src">contact us</a>.'
    },
    start_time: 'Start Time',
    end_time: 'End Time',
    tab: {
      title:
        '{key, plural, =1 {Pending} =2 {Awaiting order} =3 {Awaiting payment} =4 {Awaiting shipment} =5 {Fulfilled} =6 {Canceled} =7 {Failed orders} =9 {Awaiting fulfillment} }', // modify
      all_tip:
        'Total order amount might not be equal to the sum of Awaiting Order, Awaiting Payment, Awaiting Shipment and Fullfilled Order as some order may fail.', // d
      canceled_tip:
        'Orders that are canceled, either by AliExpress/Shopify or manually by users, are listed here. The orders canceled on AliExpress can be re-ordered again.'
    },
    place_order: {
      btn:
        '{key, plural, =1 {Place order to AliExpress} =4 {Place order again} }',
      tm_btn:
      '{key, plural, =1 {Check Out in bulk} =4 {Place order again} }',
      tip: `You need to map the product to let DSers know which provider you are working with before you bulk order.<br />
            <a
              href="https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/"
              target="_blank"
            >
              Check this article
            </a>`,
      agency_btn: '{key, plural, =1 {Check Out in bulk} =4 {Place order again} }',
    },
    more_action: {
      title: 'More Action',
      action_menu: 'Fulfill Orders Manually',
      action_menu_tip:
        'Will not be placed to AliExpress, just marked as Fulfilled.',
      tip:
        "If you DON'T want to process some orders with DSers, you can manually mark them as fulfilled here. And it won't affect your order status on Shopify.",
      tip_link: 'Check this article',
      cancel_manually: 'Cancel Orders Manually',
      upload_excel: 'Upload Excel',
      download_excel: 'Download Excel',
    },
    canceled_by: {
      placeholder: 'Canceled by',
      shopify: 'Shopify',
      ae: 'AliExpress',
      "tm":"Tmall",
      agency_in_dsers: 'You on DSers'
    },
    fulfilled_by: {
      placeholder: 'Fulfilled by',
      shopify: 'Shopify', // add
      ae: 'DSers',
      self: 'Manually fulfilled'
    },
    country: 'Country',
    make_payment: {
      btn: 'Make payment',
      h3:
        'Make your payments on AliExpress<br/>Official website to complete your orders.',
      h4: 'What happens for overdue payment ?',
      p1: '1. Your orders might get canceled',
      p2: '2. Your account might experience payment failure',
      h5: 'Please complete your payment asap to satisfy<br/>your customers!'
    },
    agency_orders: {
      upload_tn_title: 'Upload Tracking No. manually',
      upload_tn_sub_title: 'After uploading Tracking, DSers will return to Shopify and mark the corresponding order with the "Fulfilled" status.',
      upload_file_tip: 'Click here to upload the attachment',
      uplaod_match_column: 'Please select the corresponding fields in the excel file and we will automatically match them for you, then upload them.',
      upload_agency_order_id: 'Agency Order ID',
      upload_product_id: 'Item product ID',
      upload_sku_id: 'Item SKU ID',
      upload_shipping_carrier: 'Shipping carrier',
      upload_custom_shipping_url: 'Custom Tracking Url',
      upload_tracking_number: 'Tracking No',
      upload_select_placeholder: 'Please select',
      upload_confirm: 'CONFIRM',
      upload_payment_title: 'Import payment details',
      upload_payment_sub_title: 'Upload an excel file containing purchase expenses. DSers will record it for you, which will help you for orders management.',
      upload_payment_currency: 'Purchase currency (default USD)',
      upload_product_cost: 'Purchase cost',
      upload_shipping_cost: 'Shipping cost',
      download_order_info: 'Exporting order information',
      download_by_select: '{value} orders have been selected',
      download_by_date: 'Export orders by date',
      download_title: 'Export',
      download_as: 'Export as',
      export_to_file_type_excel: 'Normal excel file',
      download_btn: 'Export orders',
      export_orders_action: 'Exporting orders',
      import_payment_details: 'Import payment details',
      import_tn_to_orders: 'Upload Tracking No. manually',
      mark_as_cancel: 'Cancel Orders Manually',
      mark_as_cancel_title: 'Confirm order cancellation',
      mark_as_cancel_desc: 'Please note that the order will be marked as cancelled on DSers and that te order status will not be synced to Shopify.',
      please_input_confirm: 'Please input CONFIRM.',
      mark_as_paid_title: 'Mark order as paid',
      mark_as_paid_desc: 'Please make sure that the order has been cleared offline with your supplier. After manually marking the order as paid, the order will be placed in Awaiting shipment status.',
      cancel_order_failed: 'Order cancellation failed, please try again later.',
      place_confirm_title: 'Confirm Order Information',
      place_btn_tip: 'Click on the "Check Out in bulk" button and DSers will verify the address information for your order.',
      agency_canceled_tag: 'DSers Canceled',
      from_agency: 'Agency',
      agency_order_number_full: 'Agency order number',
      export_failed: 'The export failed, it may be due to a network error, please try again later.',
      export_failed_empty: 'The export failed as there is no order within the time range you selected. You can select a different time range and try again.',
      upload_success: 'Upload successful',
      upload_failed: 'Upload failed',
      upload_failed_reason: 'Download failure reasons',
      uploading: 'Uploading',
      export_download_link_title: 'If the download does not start automatically, please click here',
      mark_as_paid_failed: 'Manually marking the order as paid failed, please try again later.',
      exporting_orders: 'Exporting',
      agency_fulfill_tip: 'Are you sure you want to manually FULFILL these orders?',
      agency_rollback_title: 'Roll back selected orders and you will find orders in Awaiting order tab. Old Agency order information will be lost after rolling back.',
      agency_edit_tracking_no_tip: 'After you successfully modify a Tracking No, you may resend the mail to your buyer according to your Settings, please be careful',
      agency_auth_status_processing: 'ERP processing',
      agency_auth_status_offline: 'Offline order',
      agency_process_tag_tip: `The order information has been sent to your supplier's ERP, and the creation time is estimated to be 2 hours. After the creation is successful, you can communicate the purchase quotation with your supplier and pay. If the status has not been updated for a long time, you can also try to process the order manually.`,
      erp_payment_tip_title: 'Please confirm',
      erp_payment_tip_content: `We detected that the order you paid contains an order that needs to be created in your supplier's ERP. Please confirm that your supplier has been informed of the full order quantity to be processed before paying.`,
      erp_auth_type_filter: 'Type',
      payment: {
        make_payment: 'Make Payment',
        make_payment_tip: 'Make your payments to complete your orders. Please complete your payment asap to satisfy your customers!',
        currency_diff_title: 'Caution',
        currency_diff_content: 'Please make sure that the selected orders are in the same currency for the same supplier. You can modify currency and re-upload the CSV file or pay in batches based on different currencies.',
        currency_diff_btn: 'GOT IT',
        payment_modal_title: 'Please confirm order & agency information before you pay.',
        payment_1:"Due to restrictions from {type},  each order must be over $1.00. Please contact your supplier to modify.",
        edit_agency_info: 'Edit agency Information',
        agency_info_name: 'Agency Name',
        agency_info_paypal: 'Agency PayPal',
        agency_info_amount: 'Estimated payment amount',
        agency_info_amount_v2: 'Payment amount',
        agency_inpayment_tip: 'The payment is being paid, please complete the rest of the payment.',
        get_payment_detail_failed: 'The payment have expired.',
      },
      order_confirm_erp_title: 'ERP',
      order_confirm_erp_tip_1: `You have bound this supplier's ERP. After the order is placed successfully, we will send the order directly to your supplier.`,
      order_confirm_erp_tip_2: `You have not bound this supplier's ERP. After the order is placed successfully, you need to manually export the order and send it to your supplier.`
    },
    order_title: {
      title:'Filter Order',
      order_no: 'Order No',
      date: 'Date',
      country: 'Country',
      income: 'Income',
      note: {
        title: 'Note',
        add: 'add'
      },
      customer_detail: 'Customer Detail',
      type:'Order Type'
    },
    edit_address: {
      contact_name: 'Contact Name: ',
      tel: 'Tel: ',
      email: 'Email: ',
      company: 'Company: ',
      cpf: 'Cpf', // add
      country: 'Country: ',
      address: 'Address: ',
      province: 'Province: ',
      city: 'City: ',
      post_code: 'Post code: ',
      clearance_info: 'Personal Clearance ID',
      alien_clearance_info: 'Passport/ Alien registration Card Number:',
      clearance_info_required:
        'The personal clearance unique code consists of a 12-digit number starting with P. Fill in this field if the customer is a native Korean.',
      clearance_info_tip:
        'The personal clearance unique code consists of a 12-digit number starting with P (eg, P682151173203). Click <a href="https://help.dsers.com/orders-to-korea-specifications-for-shopify/" target="_blank" rel="noopener noreferrer" >here</a> to get more information.',
      alien_clearance_info_required:
        'Please enter your Passport or Alien registration card number. Fill in this field if the customer is NOT a native Korean.',
      alien_clearance_info_tip:
        'Incorrect Format. Enter 1- 13 digits or letters.Click <a href="https://help.dsers.com/orders-to-korea-specifications-for-shopify/" target="_blank" rel="noopener noreferrer" >here</a> to get more information.',
      rut_required: 'Please enter a RUT number',
      rut_incorrect: 'There seem to be an error with the RUT number',
      rut_tip:
        'Please read our <a href="https://help.dsers.com/orders-to-chile-specifications-for-shopify/" target="_blank" rel="noopener noreferrer" >blog</a> to learn more',
      unknow_wrong:
        'There is something wrong with your customer information. Please check and revise.',
      enter_name: 'Please enter a Contact Name',
      enter_between: 'Please enter between {start} - {end} characters',
      enter_write_tel:"Please fill in your real phone number here, enter between 5-16 numbers. Click <a href='https://help.dsers.com/fix-failed-orders-due-to-aliexpress-phone-number-regulations' target='_blank'>here</a> to learn how to solve this problem",
      korea_error:'Please enter {start} or {end} -digit phone number starting with 01 or 1 (e.g. 01012345678)',
      italy_error:'Please enter {start} or {end} digits',
      english_only: 'Please enter English only',
      max128:
        'The sum of the contact name and company length cannot exceed 128 characters.',
      only_num_hyphens_forward:
        "Please enter Numbers and/or hyphens '-' and/or forward slashes '/' only",
      cpf_tip:
        'CPF might be wrong. If you checked it, please ignore this message. Please go to AliExpress to double check.',
      enter_country: 'Please enter a Country/Region',
      enter_address: 'Please enter an Address',
      enter_address_length: "Please ensure that your address is not less than 5 characters.",
      select_province: 'Please select a State/Province/Region',
      enter_city: 'Please enter a City',
      city_not_match: 'City does not match. Please select from pull-down list.',
      enter_zip: 'Please enter a ZIP/Postal Code',
      enter_zip_1234: 'Please enter a {length}-digit zip code, such as {ps}',
      enter_zip_123456: 'Please Enter a zip code, such as {ps}',
      enter_zip_special:
        'Please enter your zip code in suggested format, such as {ps}',
      enter_zip_confirm:
        'For international delivery, zip code with 7 digits is required. You may confirm with your local post office on this if needed.',
      only_num_spaces_hyphens:
        'Please enter Latin letters/numbers/spaces/hyphens only',
      france_phone_setting_tip:
        'For orders sent to France, it requires 10 digits mobile phone numbers starts with 06.',
      france_phone_customers_tip:
        'For orders sent to France, it requires 10 digits mobile phone numbers.'
    },
    order_product: {
      title_unarchive:`Unarchive: After order unarchived, you can find the order in the 'Open orders' tab.`,
      variants: 'Variants:',
      from: 'From',
      from_aliexpress: 'AliExpress',
      from_other: 'Other',
      cost: 'Cost',
      quantity: {
        // d
        price: 'price',
        ok: 'Ok',
        cancel: '',
        max: 'Maxium'
      }, // d
      dsers_shipping: 'ePacket', //  d
      shipping_method: 'Shipping method',
      shipping_dialog: {
        company: 'Shipping Company',
        cost: 'Shipping Cost',
        time: 'Estimated Delivery Time',
        tracking: 'Tracking information',
        price: 'Free Shipping',
        days: 'days',
        available: 'Available',
        unavailable: 'Unavailable',
        ok: 'Ok',
        cancel: 'Cancel',
        setting_link: 'Set up shipping method in Setting panel',
        overrun_warning:
          'You exceeded the daily limitation to access AliExpress product data, but you can still import and push products.',
        upcoming1:`The shipping method cannot be set, the reason may be:`,
        upcoming2:`1) Mapping is not set for this product.`,
        upcoming3:`2) The supplier cannot deliver to the destination of the order. Please confirm the delivery address with the user, or use "Edit" to change to a supplier that supports delivery to this destination.`,
        async_frienght:`Update shipping method list`
      },
      shipping_cost_free: 'free',
      shipping_time: 'days',
      ali_order_no: 'Ali Order No',
      tmall_order_no: "Tmall Order No",
      order_no: 'Order No',
      tracking_no: 'Tracking No',
      tracking_no_add: 'add',
      sync: 'Sync',
      ali_order_no_no_match:
        "Can't find this order to this Ali order number. Please check it.", // d
      mapping: 'Mapping',
      mapping_tip: 'Click Mapping to manage supplier for this product.',
      customer_service: ' Customer Service',
      action: {
        title: 'Action',
        message: 'Message',
        edit: 'Edit',
        mark_as_fulfilled: 'Mark as Fulfilled',
        bulk_edit: 'Bulk Edit' // d
      },
      fulfilled_by_other_tip: `Did you wrongly fulfilled orders?
                               If you want to process the order via DSers,
                               you can't fulfill it manually on DSers or Shopify.
                               And you need to deactivate auto fulfillment feature on Shopify.
                               If you need to roll back the orders,
                               please click the messenger button to get help.
                               Please check <a
                                style="color:#FF8F00"
                                href="https://help.dsers.com/re-order-fulfilled-orders-for-shopify/"
                                target="_blank"
                                data-ga="wrongly fulfilled - here"
                              >here</a> to get step by step operation manual.`,
      ae_order_error_tip: {
        //  d
        click: 'Click',
        message_us: 'Message Us',
        why_see_this: 'Why am I seeing this?',
        ae_not_available:
          'This product is not available on AliExpress. Please go and check on AliExpress.',
        sku_not_found: 'SKU is not found. Please click',
        sku_not_found_after: 'to add an AliExpress product link.',
        no_shipped_country:
          "This product can't be shipped to to this country. Click&nbsp;",
        no_shipped_country_after: '&nbsp;to change an AliExpress product.',
        freight_error: 'Please fill in the address information of the order to choose the shipping method',
        set_shipping: {
          p1: 'Choose a',
          p2: 'shipping method',
          p3: 'to active order feature.',
          p4: 'Set up shipping method in Setting panel'
        },
        add_ae_link: {
          p1: 'Add AliExpress link successfully! Choose a',
          p2: 'shipping method',
          p3: 'to active order feature.'
        },
        add_ae_link_succ: 'Add AliExpress link successfully !',
        delete_by_shopify: 'The product(s) was deleted from shopify'
      }
    },
    bind_product: {
      ae_url: 'AliExpress URL ',
      ae_url_tip: "Enter AliExpress product URL",
      tmall_url_tip: "Enter Tmall product URL",
      from_aliexpress: 'From: AliExpress',
      cost: 'Cost ',
      select_varint:
        'Corrent variants are <b>{num}</b> Please select the corresponding variant:', // modify
      overrun_warning:
        'You exceeded the daily limitation to access the product information'
    },
    order_foot: {
      cost: 'Product cost',
      shipping: 'Shipping',
      total: 'Total cost',
      pending_authorized: 'Authorized',
      pending_authorized_tip: 'This order need to capture payment on Shopify.',
      shopify_deleted: 'Shopify deleted',
      is_shopify_delete:
        'Order deleted on Shopify. If you still want to process the order, please create a new similar order on Shopify.',
      product_cost_tip: {
        title: 'Some reasons may have caused the cost difference:',
        p1:
          '1. DSers only shows the basic standard cost on AliExpress. But the real cost varies according to the country you are selling to.',
        p2:
          '2. DSers does not support different currencies at the moment, only USD. Please make sure the currency is the same on DSers and AliExpress when cross-checking product costs.',
        p3:
          '3. DSers does not update product cost for Fulfilled or Pending orders, once the order status is updated to Awaiting order, the product cost will update automatically.',
        p3_1:
          'Please kindly note the product cost will be updated every 24 hours on DSers.',
        p4:
          '4. Taxes will be applied to orders shipping to some countries, like the USA and Australia.',
        p_end:
          'The correct cost of the product will appear after you place the order to AliExpress.'
      },
      shipping_tip: `The final shipping cost shown here will be the same as on the AliExpress orders and will be up to your suppliers. When a discount applies to your AliExpress orders, the discount amount will be counted in the Shipping cost here.
                    <a
                      href="https://help.dsers.com/shipping-fees-on-dsers-for-shopify/"
                      target="_blank"
                    >
                      Please check the blog for further details.
                    </a>`,
      order_again: 'Order again',
      order: 'Order',
      order_tip: `You need to map the product to let DSers know which provider you are working with before you bulk order.<br />
                  <a
                    href="https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/"
                    target="_blank"
                  >
                    Check this article
                  </a>`,
      tax_title: 'Tax',
      other_title: 'Other',
      tax_tip: `If you want the tax displayed here, please use the 
      <a
        href="https://www.dsers.com/dsers-chrome-extension/"
        target="_blank"
      >
        DSers Chrome Extension
      </a> to place the order.`,
      shipping_tip_ext: 'The final shipping cost shown here will be the same as on the AliExpress orders and will depend on your suppliers.',
      other_tip_ext: 'When a discount applies to your AliExpress orders, the discount amount will be counted in here.',
      order_displayed:`The order displayed price is not in USD. DSers converted to USD according to the exchange rate. This may be different from the actual price on AliExpress. DSers only displays it for information, it will not affect the amount to pay. Please check AliExpress to see the actual amount to pay`
    },
    sync_order_alert: `DSers will synchronize automatically from Shopify and AliExpress when you log in to DSers.
                      The syncing will last 10 mins.
                      DSers will not sync automatically every 6h anymore to ensure a more stable syncing.
                      Please make sure to log in if you want to sync the order status.`, //  add
    cpf_alert: `Due to new regulations,
                all orders to Brazil must contain the CPF number,
                or the orders will fail.
                DSers will make the CPF number appear on your packages.
                You can add the CPF number of your customer in the "Company" field,
                in Customer Details.`, //  add
    time_zone_placeholder: 'Time Zone', //  add
    af_more_action: 'Sync tracking number to Shopify', //  add
    shopify_fulfilled: 'Shopify fulfilled', //  add
    shopify_canceled: 'Shopify canceled', //  add
    shopify_partially_fulfilled: 'Shopify partially fulfilled', //  add
    shopify_partially_canceled: 'Shopify partially canceled', //  add
    shopify_refunded: 'Shopify refunded', //  add
    ali_canceled: 'AliExpress canceled',
    tm_canceled: 'Tmall canceled',
    zero_tip: `Manually request fulfillment on Shopify caused the issue.
              Please manually fulfill this order on DSers,
              then rollback the order.
              Quantity will be updated`, //  add
    err_deleted_in_shopify: 'This product had been deleted in Shopify', //  add
    err_may_be_blacklisted:
      'Four possible reasons made the order failed.', // add
    err_may_be_listed_5:`
      1. You can only purchase a certain amount of this product, please contact the supplier to double check, or change supplier of this product to place the order.<br/>
      2. The order will ship to France and the supplier's product value exceeds 150 EURO, please change the supplier or apply to be a Business Buyer on your Aliexpress account.<br/>
      3. The supplier's product link is unavailable.<br/>
      4.The order contains AliExpress suppliers requiring different currencies for payment, please place the items separately.
    `,
    err_france:
      'Please replace "France" in the "Country" field with "{ Guadeloupe }" due to AliExpress new terms', // add
    err_out_of_stock:
      'The product is out of stock. Please contact your supplier or switch to a new supplier.', // add
      awaiting_error_tip11:"Due to the purchase restriction of the main supplier, your order has been placed using the secondary supplier. If you want to modify the supplier, please cancel the order and manually modify the mapping supplier under the Canceled tab",
      awaiting_error_tip13:"Your order has been placed using the secondary supplier because the primary supplier has no stock. If you want to modify the supplier, please cancel the order and manually modify the mapping supplier under the Canceled tab",
      awaiting_error_tip14:"The logistics of the main supplier does not support delivery to the destination, your order has been placed using the secondary supplier. If you want to modify the supplier, please cancel the order and manually modify the mapping supplier under the Canceled tab",
      awaiting_error_tip15:"The main supplier blacklisted you, your order was placed using the secondary supplier. If you want to modify the supplier, please cancel the order and manually modify the mapping supplier under the Canceled tab",
      awaiting_error_tip21:"The SKU of the main supplier cannot be sold, your order was placed using the secondary supplier. If you want to modify the supplier, please cancel the order and manually modify the mapping supplier under the Canceled tab",
      awaiting_error_tipOther:"There is a problem with the primary supplier and your order has been placed with the secondary supplier.  If you want to modify the supplier, please cancel the order and manually modify the mapping supplier under the Canceled tab", 
      tmall_error_code1:'The system is busy, please try again later or click to contact customer service.', 
      tmall_error_code2: '',
      tmall_error_code3:'The shipping method of this product is invalid, please try to place the order again',
      tmall_error_code4:'The purchase quantity does not reach the minimum purchase quantity, click to contact customer service or switch to a new supplier.', 
      tmall_error_code5: 'This product can not currently be purchased via PC, click to contact customer service or switch to a new supplier',
      tmall_error_code6: 'The inventory of this product is insufficient, click to contact customer service or switch to a new supplier',
      tmall_error_code7:'This product is temporarily not for sale in this region, click to contact customer service or switch to a new supplier', 
      tmall_error_code8: 'This product is temporarily unavailable for purchase, please try again and place the order again',
      tmall_error_code9:'This product is an active product, and the event has not yet started, click to contact customer service or switch to a new supplier',
      tmall_error_code10:'The purchase quantity exceeds the purchase limit, click to contact customer service or switch to a new supplier', 
      tmall_error_code11: 'Product inventory deduction failed, please try again and place the order again',
      tmall_error_code12: 'This product is temporarily unavailable for purchase, click to contact customer service or switch to a new supplier',
      tmall_error_code13:'Failed to obtain product price, please try again and place the order again',
      tmall_error_code14:'', 
      tmall_error_code15: 'Expired coupons made this order fail. Please remove the coupons and try again.',
      tmall_error_code16: 'There is currently no shipping method available for this product, click to contact customer service or switch to a new supplier',
      tmall_error_code17:'', 
      err_may_be_out_of_stock:
      'Your supplier may be out of stock for this order. Please go to AliExpress and check.', // add
    err_limit:
      'You can only purchase { limit } piece(s) for this product at most, please contact the supplier to double check or change the supplier for this product.', // add
    err_limit_blur:
      'You can only purchase a certain amount of this product, please contact the supplier to double check, or change supplier of this product to place the order.',
    err_not_available:
      'Product unavailable on AliExpress, please change the supplier for this product.', // add
    err_not_shipping: `The province selected in Shopify is set as a country on AliExpress, please manually change the destination in the Customer Detail section of DSers. If the product can't be shipped to the country of destination, we recommend you change the suppliers.`,
    err_blacklisted:
      'Your supplier blacklisted you. Please contact your supplier or find another supplier.', // add
    err_un_mapped: `Product un-mapped or partially mapped, please map supplier on DSers correctly.
                    Check <a href="https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/" target="_blank">here</a> to learn how.`,
    err_shipping_to_china:
      'Please note that AliExpress does not support shipping to China.',
    err_shipping_to_RU: `Due to AliExpress interface limitation, DSers doesn't support shipping to Russia at the moment.
                            Please manually place the order(s) on AliExpress and fill in the AliExpress order number on the order on DSers.`,
    err_shipping_address:"The Shopify address or your order might be inconsistent with the AliExpress address. The shipping method displayed here will be the one used in the AliExpress destination country.",
    ae_account_disabled:
      'Your AliExpress account has been disabled by AliExpress. Please create a new AliExpress account and link it.',
    err_extra_address:
      'Incorrect or missing Customer Detail, please check the error message and correct accordingly.',
    err_add_address_ukraine:"(If the destination is now a war zone, you will not be able to place the order)",
    err_address_yg:`It is recommended that you replace "{country}" with United Kingdom in the Country field. Fill in "{countrys}" in the Province field`,
    qty_zero_tip: `Fulfillment of the order was requested on Shopify. Since Request fulfillment feature does not comply with how DSers process orders, please modify the order based on the guide in the blog. Click <a href="https://help.dsers.com/re-order-request-fulfillment-order-for-shopify/" target="_blank">here</a> to get more information`,
    ae_tip_IN_error:`At the moment, orders for India cannot be placed successfully. Please click  <a href="https://customerservice.aliexpress.com/knowledgeDetail?categoryId=21039549&hcAppId=1248&hcFromCode=Xn2Tk1tb7&hcSessionId=26-1248-70544a7b-e1ed-41af-8a2d-1fa5a625173e&knowledgeId=627712&spm=service_hall.24300488.searchresult.627712）" target="_blank">here</a> to get more information.`,
    ae_code_11_error: `The price of your order to France exceeds 150 Euros. According to regulations, you are required to fill in the VAT information. Please click <a href="/app/settings?tabChangeSta=7" >here</a> to jump to the settings. Click <a href="https://www.blog.dserspro.com/post/orders-to-france-specifications-with-shopify-dsers" target="_blank">here</a> to get more information.`,
    ae_code_11_error_1: 'The price of your order to France exceeds 150 Euros. According to regulations, you are required to fill in the VAT information. Please click <a href="/app/settings?tabChangeSta=7">here</a> to jump to the settings. Click <a href="https://help.dsers.com/orders-to-france-specifications-for-shopify/" target="_blank">here</a> to get more information.',
    ae_code_11_error_btn: `Expired coupons made this order fail. Please remove the coupons and try again.`,
    ae_code_11_error_btn_ae:`These possible reasons made this order fail:<br/>
    1. Expired coupons made this order fail. Please remove the coupons and try again.<br/> 
    2. Your AliExpress account was under risk control, please <a href="https://www.messenger.com/t/DSersOfficial" target="_blank" >connect</a> another AliExpress account to try again or contact our support.`,
    ae_code_11_error_2: 'The system detected that your AliExpress account cannot place orders normally, please change the linked AliExpress account (click <a href="/app/settings?tabChangeSta=1">here</a> to change).',
    ae_code_11_error_3: 'Your order encountered an unknown error, please <a href="https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2FDSersOfficial" target="_blank" >contact customer service</a>.',
    ae_code_11_error3:`The system detected that your AliExpress account cannot place orders normally, please change the linked AliExpress account (click <a href="/app/settings?tabChangeSta=14">here</a> to change).`,
    ae_code_11_error4:`Your order encountered an unknown error, please <a href="https://www.messenger.com/t/DSersOfficial" dd="src" target="_blank">contact customer service</a>.`,
    ae_code_11_error4_extension:`Your order encountered an unknown error or you manually terminated the order, if you have any question, please <a href="https://www.messenger.com/t/DSersOfficial" dd="src" target="_blank">contact customer service</a>.`,
    ae_code_14_error:`The shipping method you chose is not supported, please change the shipping method`,
    ae_code_27_error: `Due to AliExpress interface limitation, DSers doesn't support shipping to the destination at the moment. Please manually place the order(s) on AliExpress and fill in the AliExpress order number on the order on DSers.`,
    err_sku_change:
      'Your supplier modified the SKU, DSers will update the product information every 2 hours, please remap the product and try again later.',
    supplier_account_disabled: 'Supplier account disabled',
    err_ae_fake_failed:
      'Due to AliExpress recent interface upgrade, this order may have been already successfully placed. Please check on AliExpress to avoid any loss. If the order was successful, please fill in the order number manually. If the order fails, please place the order again.',
    currency_not_supported: 'Currency not supported',
    cpt_tip: 'Please enter a valid 11-digit CPF number',
    coupon_expired: 'Promotion or coupon expired',
    duplicated_order: 'Duplicated order',
    err_varients_change:
      'A variant of this product has changed, please re-map the product, then place the order',
    place_order_timeout:
      'Order timed-out, please go to AliExpress to check if the order was placed successfully. If the order was successful, please manually enter the AliExpress order number, If the order fails, please place the order again',
    order_processing: 'Order processing ...', //  add
    order_reault_failed: 'You have failed orders! Please check .', //  add
    order_reault_succ: 'Go make payment on AliExpress !', //  add
    order_reault_await_length: 'order(s) are being placed', //  add
    order_reault_is_await_length:'order(s) is being placed',
    order_reault_await_title: 'Placing order(s)...', 
    order_reault_succ_title: 'Order(s) placed', 
    order_reault_succ_length: 'Successfully placed', //  add
    order_reault_failed_length: 'Failed to be placed', //  add
    order_reault_makepayment: 'Make Payment', //  add
    order_reault_checkfailed: 'Check failed order', //  add
    order_reault_checkfailed1:'Check Failed Order(s)',
    order_reault_fail_title:'Some order(s) failed',
    roll_back_btn: 'Roll back orders', //  add
    roll_title:
      'Roll back selected orders and you will find orders in Awaiting order tab. Old AliExpress order information will be lost after rolling back.', //  add
    roll_title_tm:"Roll back selected orders and you will find orders in Awaiting order tab. Old Tmall order information will be lost after rolling back.",
    roll_desc: 'Roll back orders',
    place_order_confirm_tooltip:'Please first select a shipping method for all orders before you confirm.',
    archive_title:'Archived orders',
    archive_desc:'Once an order is archived, the order will disappear from the current tab. If you want to view the archived order, please go to the Archived orders page.',
    global_place_order: 'Order',
    global_place_order_tip: 'Place order to AliExpress.',
    confirm: {
      tm_title:"Please check shipping information before you place orders to Tmall.",
      title:
        'Please check shipping information before you place orders to AliExpress.',
      title_succ: 'Succeed !',
      title_fail: 'Click Revise to check failed orders.',
      table_title: {
        order: 'Order',
        product: 'Product',
        income: 'Income',
        product_cost: 'Product Cost',
        country: 'Country',
        shipping_method: 'Shipping method',
        shipping_fee: 'Shipping fee',
        shipping_time: 'Shipping time',
        feedback: 'Feedback' //  d
      },
      error_msg: {
        //  d
        err_101: 'The product(s) was deleted from shopify',
        err_0: 'Click Mapping to add an AliExpress product link.',
        ae_has_product_no:
          'This product is not available on AliExpress. Please go and check on AliExpress.',
        child_cost_0:
          'SKU is not found. Please click Mapping to add an AliExpress product link.',
        express_length_0:
          "This product can't be shipped to to this country. Click Mapping to change an AliExpress product.",
        freight_math_info_no:
          'Choose a shipping method to active order feature.',
        freight_math_info_no_bind:
          'Add AliExpress link successfully! Choose a shipping method to active order feature.'
      }, //  d
      item: '{ num, plural, =1 {Item} other {Items} }',
      item_new:"Item(s)",
      confirm: 'Confirm',
      income_tip:
        'The amount of income here does not include order discount and coupon. It might be different with actual value.',
      total_cost: 'Total cost',
      revise: 'Revise', //  d
      make_payment: 'Make payment' // d
    },
    fulfill_confirm: {
      title:
        "Mark selected orders as fulfilled",
      table_title: {
        order: 'Order',
        product: 'Product',
        ae_order_number: 'AliExpress order number	',
        feedback: 'Feedback'
      },
      item: 'orders',
      mark_as_fulfilled: 'Mark as Fulfilled',
      confirm_title: 'Are you sure to fulfill orders manually?',
      confirm_dec: `Are you sure to manually FULFILL these orders?
                    <b>Please note that DSers will stop syncing order information for the orders have been placed on AliExpress</b>.`,
      continue: 'Continue',
      checkTitle:'Mark this order as fulfilled on Shopify too'
    },
    bind_ae_dialog: {
      title: 'Link to AliExpress to place multiple orders',
      shopify: 'Shopify',
      dsers: 'DSers',
      go_ae_tip: 'You will be redirected to AliExpress',
      go_ae: 'Link AliExpress'
    },
    message_dialog: {
      title: 'Leave a message to AliExpress sellers',
      tm_title:"Leave a message to Tmall sellers",
      desc:
        'AliExpress sellers will get this message together with your order information.',
      tm_desc:"Tmall sellers will get this message together with your order information.",
      placeholder:
        'Input at most 1000 English characters ( at most 250 Chinese characters )',
      save: 'Save',
      title_new: 'Leave a message to Suppliers',
      desc_new: 'Suppliers will get this message together with your order information.',
      
    },
    go_ae_pay_dialog: {
      //  d
      h3:
        'Make your payments on AliExpress<br/>Official website to complete your orders.',
      h4: 'What happens for overdue payment?',
      p1: '1. Your orders might get canceled',
      p2: '2. Your account might experience payment failure',
      h5: 'Please complete your payment asap to satisfy<br/>your customers!',
      no_show: "Don't show this again",
      make_payment: 'Make payment',
      mark_as_paid: 'Mark as paid'
    }, //  d
    product_error_msg: {
      //  d
      can_not_shipped_this_country:
        "The product can't be shipped to this country. Add anther AliExpress link."
    }, //  d
    options_different_tip:
      'Remind : Variant option does not match up.Please double check.', //  d
    roll_back_confirm_title:
      'Are you completely sure you want to perform this action?',
    roll_back_confirm_tip: 'Please input CONFIRM to Roll back orders.',
    roll_back_awaiting_shopify_hook_tip:
      'DSers is unfulfilling the Shopify order(s) now, the order(s) will be moved to the Awaiting order tab within a few seconds, please wait.',
    no_data: {
      no_data: 'No data',
      title: 'Your search may have no result for one of the following reason:',
      p4:
        '1.If you Archived the order on Shopify, please check the Archived menu in DSers to find the order',
      p1: '2.The information you entered is inaccurate',
      p2: '3.The search is not within the currently set time range',
      p3: '4.The search may include a Hidden product',
      Pendings:{
        title:'Pending orders will be displayed here',
        detail:`Orders in the Pending tab are orders for which the payment from your customers has not yet been captured in Shopify.`
      },
      AwaitingOrders:{
        title:'Orders awaiting to be placed will be displayed here',
        detail:`Orders in the Awaiting order tab are orders for which the payment from your customers has been captured on Shopify and are waiting to be processed to Tmall.`
      },
      AwaitingPayments:{
        title:'Awaiting payment orders will be displayed here',
        detail:`Orders in the Awaiting payment tab are orders that have been placed on Tmall but not paid yet. `
      },
      AwaitingShipments:{
        title:'Awaiting shipment orders will be displayed here ',
        detail:`Orders in the Awaiting shipment tab are orders that have been paid on Tmall but have not been shipped yet.`
      },
      AwaitingFulfillments:{
        title:'Awaiting fulfillment orders will be displayed here',
        detail:'Orders in the Awaiting fulfillment tab are orders only partially fulfilled.'
      },
      Fulfilleds:{
        title:'Fulfilled orders will be displayed here',
        detail:`Orders in the Fulfilled tab are orders that have been shipped out by your Tmall supplier and orders that were automatically or manually fulfilled.`
      },
      Canceleds:{
        title:'Canceled orders will be displayed here',
        detail:'Orders in the Canceled tab are orders that have been (partially) canceled / (partially) refunded on Tmall or Shopify.'
      },
      FailedOrders:{
        title:'Failed orders will be displayed here',
        detail:'Orders in the Failed tab are orders which could not be placed to Tmall successfully due to errors or restrictions.'
      },
      Pending:{
        title:'Pending orders will be displayed here',
        detail:`Orders in the Pending tab are orders for which the payment from your customers has not yet been captured in Shopify. Click <a href={href} target="_blank">here</a> to learn more about orders in the Pending tab.`
      },
      AwaitingOrder:{
        title:'Orders awaiting to be placed will be displayed here',
        detail:`Orders in the Awaiting order tab are orders for which the payment from your customers has been captured on Shopify and are waiting to be processed to AliExpress. Click <a href={href} target="_blank">here</a> to learn more about orders in the Awaiting order tab.`
      },
      AwaitingPayment:{
        title:'Awaiting payment orders will be displayed here',
        detail:`Orders in the Awaiting payment tab are orders that have been placed on AliExpress but not paid yet. Click <a href={href} target="_blank">here</a> to learn more about orders in the Awaiting payment tab.`
      },
      AwaitingShipment:{
        title:'Awaiting shipment orders will be displayed here ',
        detail:`Orders in the Awaiting shipment tab are orders that have been paid on AliExpress but have not been shipped yet. Click <a href={href} target="_blank">here</a> to learn more about orders in the Awaiting shipment tab.`
      },
      AwaitingFulfillment:{
        title:'Awaiting fulfillment orders will be displayed here',
        detail:'Orders in the Awaiting fulfillment tab are orders only partially fulfilled. Click <a href={href} target="_blank">here</a> to learn more about orders in the Awaiting fulfillment tab.'
      },
      Fulfilled:{
        title:'Fulfilled orders will be displayed here',
        detail:`Orders in the Fulfilled tab are orders that have been shipped out by your AliExpress supplier and orders that were automatically or manually fulfilled. Click <a href={href} target="_blank">here</a> to learn more about orders in the Fulfilled tab.`
      },
      Canceled:{
        title:'Canceled orders will be displayed here',
        detail:'Orders in the Canceled tab are orders that have been (partially) canceled / (partially) refunded on AliExpress or Shopify. Click <a href={href} target="_blank">here</a> to learn more about orders in the Canceled tab.'
      },
      FailedOrder:{
        title:'Failed orders will be displayed here',
        detail:'Orders in the Failed tab are orders which could not be placed to AliExpress successfully due to errors or restrictions. Click <a href={href} target="_blank">here</a> to learn more about orders in the Failed tab.'
      }
    },
    agency_open_no_data: {
      Pending:{
        title:'Pending orders will be displayed here',
        detail:`Orders in the Pending tab are orders for which the payment from your customers has not yet been captured in Shopify. `
      },
      AwaitingOrder:{
        title:'Orders awaiting to be placed will be displayed here',
        detail:`Orders in the Awaiting order tab are orders for which the payment from your customers has been captured on Shopify and are waiting to be processed to agencies. `
      },
      AwaitingPayment:{
        title:'Awaiting payment orders will be displayed here',
        detail:`Orders in the Awaiting payment tab are orders that you need to make payment to agencies.`
      },
      AwaitingShipment:{
        title:'Awaiting shipment orders will be displayed here ',
        detail:`Orders in the Awaiting shipment tab are orders that paid to suppliers through DSers, or marked as paid manually and awaiting for shipment.`
      },
      AwaitingFulfillment:{
        title:'Awaiting fulfillment orders will be displayed here',
        detail:'Orders in the Awaiting fulfillment tab are orders only partially fulfilled.'
      },
      Fulfilled:{
        title:'Fulfilled orders will be displayed here',
        detail:`Orders in the Fulfilled tab are orders that have been shipped out by your agencies and orders that were automatically or manually fulfilled. `
      },
      Canceled:{
        title:'Canceled orders will be displayed here',
        detail:'Orders in the Canceled tab are orders that have been (partially) canceled / (partially) refunded on Shopify or by agencies. '
      },
      FailedOrder:{
        title:'Failed orders will be displayed here',
        detail:'Orders in the Failed tab are orders which could not be placed to agencies successfully due to errors or restrictions.'
      }
    },
    open_no_data: {
      title: 'Can’t locate your first order to place to AliExpress?',
      p1: '2. Please double check the "Fuflilled" tab to locate the order(s);',
      p2: '3. If you locate the order(s) in the "Fulfilled" tab;',
      p3:
        '4. Please go to Shopify - Settings - Check-out, deactivate the auto-fulfillment feature as shown below;',
      p4: '5. Please make sure to click Save to update the Settings;',
      p5:
        '6. Please go to the Fulfilled tab and click on the "More action" button to Roll back a selected fulfilled orders.',
      p6:
        '1. If you Archived the order on Shopify, please check the Archived menu in DSers to find the order;',
      Pending:`Click <a href={href} target="_blank">here</a> to learn more about orders in the Pending tab.`,
      AwaitingOrder:`Click <a href={href} target="_blank">here</a> to learn more about orders in the Awaiting order tab.`,
      AwaitingPayment:`Click <a href={href} target="_blank">here</a> to learn more about orders in the Awaiting payment tab.`,
      AwaitingShipment:`Click <a href={href} target="_blank">here</a> to learn more about orders in the Awaiting shipment tab.`,
      AwaitingFulfillment:`Click <a href={href} target="_blank">here</a> to learn more about orders in the Awaiting fulfillment tab.`,
      Fulfilled:`Click <a href={href} target="_blank">here</a> to learn more about orders in the Fulfilled tab.`,
      Canceled:`Click <a href={href} target="_blank">here</a> to learn more about orders in the Canceled tab.`,
      FailedOrder:`Click <a href={href} target="_blank">here</a> to learn more about orders in the Failed tab.`
    },
    dsers_product_deleted:
      'Product deleted on DSers, please click <a href={href} target="_blank" >here</a> to check how to process the order on DSers.',
    variant_delete_tip:
      'Variant deleted on Shopify. Click "Mapping" to map a supplier for the deleted variant.',
    YANWEN_JYT_shipping_tip:
      'The selected shipping method is not available on AliExpress anymore. Please check with your supplier and select another shipping method.',
    err_deleted_in_dsers:
      'This product has been deleted on DSers, please click <a href={href} target="_blank">here</a> to check how to process the order on DSers',
    pending_tip:
      'Please make sure you have captured payment the order(s) on Shopify. If an order is still in Pending after you captured the payment, please check',
    pending_tip_end: 'to manually update the order status.',
    ae_sync_not_done: 'A synchronization is being done and not over yet.',
    ae_sync_more_then_5: 'You can not manually sync more than 5 times per day.',
    select_shipping_method_tip: 'Please select a shipping method.',
    ful_with_tracking: 'Fulfilled with a tracking number',
    ful_by_shopify: 'Fulfilled by Shopify',
    ful_manually: 'Fulfilled manually on DSers',
    partial_completion_of_order:
      'Your supplier sent out several packages for this product. We will fulfill the order when all the tracking numbers are synced.',
    ae_splited_order:
      'The order is currently split into multiple AliExpress orders. If there is a difference in the order numbers, please check and change it manually',
    freight_not_supported:
      'This country does not support the shipping method currently selected, please choose another one. Click <a target="_blank" href="https://www.dsers.com/blog/aliexpress-shipping-methods-changes/" rel="noopenner noreferrer" >here</a> if you need help.',
    edit_sp_tooltip:
      'You edited the order by clicking Action - Edit.If you want to change the supplier of this order, please use the Action - Edit feature.',
    dsers_action_edit: 'Manually edited supplier',
    variant_delete_tag: 'Shopify Deleted Variant',
    go_ae_check_order: 'Go to AliExpress',
    may_be_fake_failed_order1: 'Your order ',
    may_be_fake_failed_order2:
      ' may have been successfully placed on AliExpress. In order to avoid any loss, please check on AliExpress',
    order_fulfill: `Order fulfillment failed, `,
    token_authorize_info: `The current shop token expired, `,
    order_onHold_info: "Manually fulfill orders",
    order_onHold_info_tip: "Synchronizing",
		order_onHold_snync_errorMess:'Your order is on hold in Shopify and cannot be automatically fulfilled. Please go to Shopify to change the on hold status and try again manually',
    order_onHold_snync_tip:"Click this button to sync tracking numbers to your store and fulfill this order on Shopify",
    order_pending: `The order is being fulfilled`,
    token_authorize_info_btn: `please click setting to re-authorize`,
    order_pending_btn: `please wait for the fulfillment result`,
    archive:'Archive',
    test_order_tip: 'This order is a test order, and the generated order number is a virtual order number',
    conada_error:`Please enter your full name as it appears on your ID. Click <a target="_blank" href="https://help.dsers.com/orders-to-canada-specifications-for-shopify/">here</a> to learn how to solve this issue.`,
    cl_rut_errtip:`Please enter the correct RUT number with the following format: XXXXXXX-X or XXXXXXXX-X, the first 7-8 places must be Arabic numbers, the last place must be an Arabic number or the letter K`,
    cl_rut_errtipNo:`Please enter 2-12 digits or letters, such as 12123234-K`,
    sync_last_chan:'AliExpress order sync',
    sync_time_last_chan:'Last syncing time',
    sync_time_next_chan:'Next syncing time',
    flags:{
      title:'Flags',
      None:'None',
      Grey:'Grey',
      Blue:'Blue',
      Green:'Green',
      Yellow:'Yellow',
      Red:'Red'
    },
    shopify_partially_refunded:'Shopify partially refunded',
    place_via_extension: {
      caution: 'Caution',
      ae_account_not_match: 'We have detected that the AliExpress account you are currently logged into is inconsistent with the account linked to DSers. In order to ensure that your AliExpress order status can be synchronized to DSers, please link your currently logged in AliExpress account to DSers or place an order to the AliExpress account currently linked to DSers.',
      login_ae_account_disabled: '',
      order_via_api_opt: 'Order via API (Place the orders faster)',
      order_via_ext_opt: 'Order via Chrome Extension (Automatically use received coupons)',
      ext_not_installed_title: 'DSers Chrome Extension needs to be installed',
      ext_not_installed_content: 'Check if you have installed the DSers Chrome Extension installed and click Add DSers Chrome Extension below to go to the Chrome Web store page to install it, then return to this page to try again.',
      add_dsers_ext: 'Add DSers Chrome Extension',
      order_via_api: 'Order via API',
      order_is_processing: 'Ordering via extension, please do not close the page or the browser',
      not_operate_title: 'Do not perform the following operations',
      not_operate_1: 'Exit/switch DSers account;',
      not_operate_2: 'Close/uninstall DSers plug-in;',
      not_operate_3: 'Operate AliExpress shopping cart;',
      not_operate_4: 'Exit AliExpress account.',
      failed_order_exsits: 'The ordering process is over. Some may have failed. Please check on AliExpress to avoid any loss. If the order was successful, please fill in the order number manually. If the order fails, please place the order again.',
      abort_task_btn: 'Terminate all tasks',
      abort_task_modal_title1: 'Confirm to terminate all current order tasks?',
      abort_task_modal_content1: 'After confirming the termination of all orders, orders that have not been placed will appear in the Failed Order tab. You can order them again later.',
      confirm_btn: 'Confirm',
      cancel_btn: 'Cancel',
      abort_task_modal_title2: 'Order task terminated',
      abort_task_modal_content2: '({value}) orders have been stopped, these orders will appear in the Failed Order tab',
      abort_got_it: 'Got it',
      error_tip_ae_logout: 'Your AliExpress account was logged out during the ordering process, please log in to your AliExpress account and try again while being logged in.',
      error_tip_action_timeout: 'Request timed out, please try again.',
      error_tip_cart_error: 'Add to cart failed, please try again.',
      error_tip_unknown: 'Order failed, please contact customer service to resolve this issue.',
      error_tip_manually_close_1: 'The order failed because you manually closed the order page, please try to place the order again.',
      error_tip_manually_abort: 'You have manually aborted the order task.',
      error_tip_manually_close_2: 'The order page was closed or the tab of the order page was dragged. please try again.',
      on_hold_error: 'You can’t place order as the order is set to On hold in Shopify. Please change the order status on Shopify and then place the order on DSers.',
	    request_fulfill_error: 'Fulfillment of the order was requested on Shopify. The "Request fulfillment" feature does not comply with how DSers process orders, please modify the order based on the guide in the blog. Click <a href="https://help.dsers.com/re-order-request-fulfillment-order-for-shopify/">here</a> to get more information',
      shipping_error: "There is no shipping method applied to this order, please select manually. Or you can go to Settings--Shipping to set up your favorite shipping method, then DSers will select it automatically",
	    shipping_error_set:'The shipping method you set based on the product in the settings is not supported by the supplier anymore, so the order cannot be placed without a default shipping method. You can modify the shipping method in the settings, or manually modify it in the order',
      mapping_error:'You need to map the products to let DSers know which supplier you are working with before you bulk order.',
	    on_hold_error_item: 'You can’t place order as the order is set to On hold in Shopify. Please change the order status on Shopify and then place the order on DSers.',
  	  request_fulfill_error_item:  'Fulfillment of the order was requested on Shopify. The "Request fulfillment" feature does not comply with how DSers process orders, please modify the order based on the guide in the blog. Click <a href="https://help.dsers.com/re-order-request-fulfillment-order-for-shopify/">here</a> to get more information',
  	  shipping_error_item: 'There is no shipping method applied to this order. Please check the <a href="/app/settings">shipping settings</a> on DSers or check the availability of shipping methods on AliExpress.',
      shipping_error_agency: 'There is no shipping method applied to this order, please select manually.',
      error_tip_product_captcha: 'Your network is abnormal, Please slide to verify on AliExpress and order again.',
    },
    disappear_order_tip: `There is a problem with some of your orders, if you need to retrieve them, please <a class="ds-link" target="_blank" href="https://www.messenger.com/t/DSersOfficial"">contact us.</a>`,
    archived_orders:'Orders that have not been processed for more than a year will automatically be placed in Archived orders menu.',
    ae_code_21_error:`The order couldn't be placed with your main supplier for some reason, we tried to place the order with the sub-supplier, but it failed too because a variant of this product has changed, please re-map the product, then place the order`,
    order_code_24_error:`This order failed due to a network error, please try to place the order again`,
    tmall_sku1_error: 'The product SKU has changed, please check your Mapping details.',
    tmall_sku2_error:`The original SKU does not exist, you need to re-map or  <a href=\"{url}\" target=\"_blank\">change the supplier</a>, otherwise you can't place the order successfully.`,
    tmall_sku3_error:`The original SKU does not exist or changed, you need to re-map or change the supplier, otherwise you can't place the order successfully.`,
    filter_mapping_default:'All orders',
    filter_mapped_order:'Mapped orders',
    filter_no_mapped_order: 'Unmapped orders',
    note_title_y:'Add note',
    note_desc_y:'Note is visible only in DSers',
    note_tip_y:'The number of characters in the input box has exceeded the limit, please delete the content and save.',
    aeProcess:'Process Orders',
    aeProcessSub:`Roll back the selected orders and they will be moved to the Awaiting order tab. Old AliExpress order information will be lost after rolling back.The order with products using BOGO and Bundle mapping can not be rolled back.<span style="color:red"> Please note that these orders will be changed to unfulfilled in Shopify. Note: Due to Shopify’s policy, we can only process orders from the last two months.</span> `,
    confirm_modal_up:{
      fulfill_title:'Fulfill Orders Manually',
      fulfill_confirm:'CONFIRM',
      rollback_title:'Roll Back Orders',
      rollback_input_confirm_value:'Please input CONFIRM to Roll back orders.',
      note_cancel:'CANCEL',
      node_save:'SAVE',
      fulfill_desc: 'Are you sure you want to manually FULFILL these orders? Please note that DSers will stop syncing order information for the orders that have been placed on AliExpress.',
      fulfill_desc_tm:"Are you sure you want to manually FULFILL these orders? Please note that DSers will stop syncing order information for the orders that have been placed on Tmall."
    },
    orderQuantityToolTip:{
      "title": "There are still {items} Items in the other Order menus ",
      "aliexpress": "AliExpress Orders",
      "agency": "Agency Orders",
      "unmapped": "Unmapped Orders"
    },
    aeCancelTip:'Due to AliExpress new regulation, when order is using a fake tracking number, it might be canceled by AliExpress. Please manually roll back this kind of order in the fulfilled tab.',
    editActionTip:`Since you have modified the supplier of the order, the item or order will be moved to the {platformType} Orders page`,
    edit_order_confirm_tip_1: `You used the Edit feature to modify the supplier type of the item, please go to {platform} - {tab} tab to view the item.`,
    edit_order_confirm_tip_2: `You used the Edit feature to modify the supplier type of some items, please go to {platform} - {tab} tab to view the item.`,
    edit_order_confirm_tip_var: {
      aliexpress: 'AE',
      tmall: 'Tmall',
      both: 'AE / Tmall',
      awaiting_order: 'Awaiting Order',
      canceled_order: 'Cancelled',
      failed_order: 'Failed Order',
    },
    orderPayment:{
      timeout:'Payment failed. The payment has timed out, please re-initiate the payment.',
      cancel:'Payment failed. Your payment has been cancelled, please re-initiate the payment.',
      unknow:'Payment failed. Unknown reason, please <a href="https://www.messenger.com/t/DSersOfficial" target="_blank">contact DSers support</a> to resolve this issue.',
      checkDetail:'Check details',
      link:'Payment link',
      loading:'The payment you started at {time} is being paid.',
      success:'The payment you started at {time} is successful.',
      error:'The payment you started at {time} is failed.',
      pendingTip:'If you want to cancel payment for this order, please cancel payment in the payment link.',
      reason:'failed reason',
      buttonTip:'Make your payments to complete your orders. Please complete your payment asap to satisfy your customers!',
      orderOneButtonTip:'MAKE PAYMENT'
    },
    paymentModalTitle:'Please check order information before you pay.',
    orderPageGuidance:'Order has been synchronized, please go to the Open Order page to process awaiting orders.'
  }
};
