import React, { useMemo } from 'react';
import { Link } from 'dva/router';
import intl from 'react-intl-universal';

import styles from './Article.less';

function Article({ data, pagination, onLoadMore, loading }) {
  const totalPage = useMemo(() => {
    const { pageSize, total } = pagination;
    return Math.ceil(total / pageSize);
  }, [pagination]);

  return (
    <div className={styles.article}>
      {data.map(item => (
        <div className={styles.qItem} key={item.date}>
          <h4>{item.date && item.date.replace(/-/g, '.')}</h4>
          <div>
            {item.value.map(i => {
              if (i.type === 2) {
                return (
                  <Link
                    key={i.id}
                    target="_blank"
                    to={{
                      pathname: `/help_detail/${i.id}`,
                      state: { fromHelpCenter: true }
                    }}
                  >
                    {i.title}
                  </Link>
                );
              }
              return (
                <a
                  href={i.value}
                  key={i.id}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {i.title}
                </a>
              );
            })}
          </div>
        </div>
      ))}
      {totalPage > pagination.current ? (
        <div className={styles.more}>
          {loading ? (
            'Loading...'
          ) : (
            <span onClick={onLoadMore}>
              {intl.get('help_center.help_center.load_more')}
            </span>
          )}
        </div>
      ) : null}
    </div>
  );
}

export default Article;
