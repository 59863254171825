import React, { useEffect, useState } from 'react';

function checkVisible(pathname, allowPath) {
  let allow = false;

  if (!pathname) {
    return allow;
  }

  // eslint-disable-next-line
  for (const i of allowPath) {
    if (pathname.indexOf(i) > -1) {
      allow = true;
      break;
    }
  }

  return allow;
}

/**
 * 当前路径
 *
 * @param {string} path 当前路径
 * @param {Array<string>} allowPath 允许的路径
 * @param {boolean} disableCheck 取消检查
 * @returns
 */
function useTopBanner(path, allowPath, disableCheck = false) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (disableCheck) {
      setVisible(true);
      return;
    }

    setVisible(checkVisible(path, allowPath));
  }, [path, disableCheck, allowPath]);

  return [visible];
}

export default useTopBanner;
