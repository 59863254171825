
    export default 
    {
    "suppliers": {
        "categary": "Tutte le categorie",
        "copyLink": "Copia link",
        "importBtn": "Aggiungi a lista importazioni",
        "importBtnEd": "Imported",
        "optimizer": "Supplier Optimizer"
    }
}