import React from 'react';
import styles from './ShippingMethodBanner.less'
import intl from 'react-intl-universal';

import { useDispatch } from 'react-redux';
import left from 'assets/supply/fly.png';
import right from 'assets/supply/baxi.png';
import cloud1 from 'assets/supply/cloud1.png'
import cloud2 from 'assets/supply/cloud2.png'
import cloud3 from 'assets/supply/cloud3.png'
import cloud4 from 'assets/supply/cloud4.png'
import person1 from 'assets/supply/person1.png'

import { CommonDataCollect } from 'utils/utils'


const allowPath = [
    '/order/list',
    '/storemanage/myproduct',
    '/home'
];


const ShippingMethodBanner = ({ agencyUserMap, shipMethod, userId}) => {

    const handleClick = ()=>{
        CommonDataCollect({
            event_type:'supply_banner',
            action:'blog_0905'
        })
    }

    return (
        <>
            {
                <a target='_blank' onClick={handleClick} href='https://www.dsers.com/blog/aliexpress-shipping-methods/' className={styles.bannerTwo}>
                    <img src={cloud1} alt="" className={styles.cloud1}/>
                    <img src={cloud2} alt="" className={styles.cloud2}/>
                    <img src={cloud3} alt="" className={styles.cloud3}/>
                    <img src={cloud4} alt="" className={styles.cloud4}/>
                    <img src={person1} alt="" className={styles.person1}/>
                    <div className={styles.title}>A Guide for Shipping in Q4, 2022: Common Shipping Methods for AliExpress Dropshipping.</div>
                    <div className={styles.button}><span>VIEW DETAILS</span><span>{'>>'}</span></div>
                </a>
            }
        </>
    )
}

ShippingMethodBanner.allowPath = allowPath;

export default ShippingMethodBanner;