import { getChromeExtUrl } from "./utils";

const EXTENSION_ID = 'mmanaflgaempokjfbeeabkadnkoidjam';

const getDsersExtensionId = () => {
  let testId = '';
  if(process.env.BUILD_TARGET !== 'prod') {
    testId = window.dsersExtId;
  }
  if(window.sessionStorage.getItem('happy_extension_test')){
    testId = window.sessionStorage.getItem('happy_extension_test');
  };
  return testId || EXTENSION_ID;
};

const { chrome } = window;

export const getChromeExtOptionsPage = () => {
  if(!window.dsersExtVersion) {
    return getChromeExtUrl();
  }
  const id = getDsersExtensionId();
  return `chrome-extension://${id}/options.html`
}

/**
 * 定义在插件中的方法
 * @param {'fetchProductInfo'|'fetchStoreRating'|'fetchFreights'|'fetchSearchList'|'fetchProductDesc'} action 与插件fetchUtil中定义的方法名对应
 * @param {object} actionParams 抓取参数
 * @param {number} timeout 超时时间
 */
const sendFetchRequestToExtension = (action, actionParams, timeout = 10000) => {
  if (!chrome || !action) {
    return Promise.reject();
  }
  const extId = getDsersExtensionId();
  const params = {
    action,
    actionParams,
    timeout: timeout - 500
  };
  return new Promise((resolve, reject) => {
    chrome.runtime.sendMessage(
      extId,
      { type: 'dsersFetchRequest', params },
      res => {
        console.log(res);
        if (chrome.runtime.lastError) {
          // reject(chrome.runtime.lastError);
          console.log(chrome.runtime.lastError);
        }
        if (!res) {
          reject();
        } else {
          resolve(res);
        }
      }
    );
  });
};

export const sendRequestToExtension = (
  action,
  methodName,
  params,
  timeout = 10000
) => {
  if (!chrome || !action) {
    return Promise.reject();
  }
  const extId = getDsersExtensionId();
  console.log(extId)
  return new Promise((resolve, reject) => {
    try {
      chrome.runtime.sendMessage(
        extId,
        { type: action, data: {
          methodName,
          params
        }},
        res => {
          console.log(res);
          if (chrome.runtime.lastError) {
            // reject(chrome.runtime.lastError);
            console.log(chrome.runtime.lastError);
            resolve({ code: 4999, msg: 'no dsers extension detected' });
          }
          if (!res) {
            reject();
          } else {
            resolve(res);
          }
        }
      );
    } catch (error) {
      resolve({ code: 4999, msg: 'no dsers extension detected' });
    }
  });
};


/**
 *@typedef ProductInfoParams
 * @property {string|number} id Ae商品Id
 * @property {string} country 收货国家
 * @property {string} currency 货币
 * @property {'pt_BR'|'en_US'|'fr_FR'} lang 语言，与ae语言参数对应
 */
/**
 *
 * @param {ProductInfoParams} params
 */

export const fetchProductInfo = async params => {
  try {
    const res = await sendFetchRequestToExtension('fetchProductInfo', params);
    return res;
  } catch (error) {
    return { error: 'extension_fetch_error' };
  }
};

/**
 *
 * @typedef FreightParams
 * @property {string|number} id Ae商品Id
 * @property {string} country 收货国家
 * @property {string} currency 货币
 * @property {number} count 商品数量
 * @property {string} sendGoodsCountry 发货地 部分商品支持此参数 来自sku：ship from
 * @property {number|string} minPrice 最小价格
 * @property {number|string} maxPrice 最大价格
 * @property {string|number} sellerAdminSeq 卖家管理id
 * @property {string} provinceCode 收货省份 部分国家支持此参数
 * @property {string} cityCode 收货城市 部分国家支持此参数
 * @property {JSON} ext 物流扩展参数
 */

/**
 *
 * @param {FreightParams} params
 */
export const fetchFreights = async params => {
  try {
    const res = await sendFetchRequestToExtension('fetchFreights', params);
    return res;
  } catch (error) {
    return { error: 'extension_fetch_error' };
  }
};

/**
 *
 * @typedef StoreRatingParams
 * @property {string|number} ownerAdminSeq 商家管理id
 */

/**
 *
 * @param {StoreRatingParams} params
 */

export const fetchStoreRating = async params => {
  try {
    const res = await sendFetchRequestToExtension('fetchStoreRating', params);
    return res;
  } catch (error) {
    return { error: 'extension_fetch_error' };
  }
};

export const fetchProductDesc = async params => {
  try {
    const res = await sendFetchRequestToExtension('fetchProductDesc', params);
    return res;
  } catch (error) {
    return { error: 'extension_fetch_error' };
  }
};

/**
 *@typedef AliSearchParams
 * @property {string} searchText 搜索关键字
 * @property {string} category 分类id
 * @property {string} country 收货国家
 * @property {string} currency 货币
 * @property {string} shipFrom 发货地
 * @property {string} shipSpeed 物流速度
 * @property {'n' | 'y'} isFastShipping 是否快速物流
 * @property {'n' | 'y'} freeShip 包邮
 * @property {'n' | 'y'} freeReturn 免费退货
 * @property {'n' | 'y'} fourStarAbove 只展示四星及以上商品
 * @property {string} maxPrice 最大价格
 * @property {string} minPrice 最小价格
 * @property {'pt_BR'|'en_US'|'fr_FR'} lang 语言，与ae语言参数对应
 * @property {string} sort 排序方式
 */

/**
 *
 * @param {AliSearchParams} params
 */
export const fetchSearchList = async params => {
  try {
    const res = await sendFetchRequestToExtension('fetchSearchList', params);
    return res;
  } catch (error) {
    return { error: 'extension_fetch_error' };
  }
};
