import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { Post, Get } from 'utils/request';
import { getQueryUrlParams } from 'utils/global';

const statusToCount = {
  '0': 'all',
  '1': 'pending',
  '2': 'awaiting_order',
  '3': 'awaiting_payment',
  '4': 'awaiting_shipment',
  '9': 'awaiting_fulfilled',
  '5': 'fulfilled',
  '6': 'canceled',
  '7': 'failed'
};

const OrderRedirect = () => {

  useEffect(() => {
    getRedirect()
  },[])

  const getRedirect = async () => {
    let response = await Post('/mgnoberlo-core-bbbbb/bbbb',{
      data:{
        a:2
      }
    })
    console.log('response', response)
  }

  return <>
    <div
        style={{
            marginTop: 120,
            textAlign: 'center'
        }}
    >
        <Spin size="large" />
    </div>
  </>
}

export default OrderRedirect