import agency from './agency';


export default {
  setting: {
    tab_account: 'Account', // add
    tab_package_tracking:"Package Tracking",
    tab_sub_account:'Staff Account',
    tab_sub_supplier:'Supplier',
    tab_stores:'Stores',
    tab_product:'Product',
    tab_pricing_rule: 'Pricing Rule',
    tab_shipping: 'Shipping',
    tab_order:'Order',
    tab_paypal:'PayPal',
    tab_tracking: 'Shipping Confirmation',
    tab_notification: 'Notification',
    tab_sync:'Auto Update',
    tab_aff:'Affiliate program',
    tab_billing_balance: 'Billing Balance', // d
    tab_verified_supplier: 'Verified Supplier', // d
    tab_report: 'Report',
    tab_other: 'Other',
    choose_country_placeholder: 'Choose a country',
    account: {
      // add
      account_info: 'Account info',
      account_new_info: 'Change Account name',
      account_name: 'Account name',
      account_email: 'Account email',
      change: 'Change',
      change_email_desc:
        'The email is your log in email. We will use the email to contact you about your account',
      new_email: 'New email address',
      new_email_desc:
        'We will send a verification code to the new email address. Please copy it and enter below to verify your mailbox',
      new_email_err: 'Please enter email in correct format',
      account_name_err: 'Please enter a username between 5 and 20 characters',
      verify_code: 'Enter code to verify',
      verify_code_desc:
        'Get code from our verification email and enter here to get verified',
      verify_code_err: 'Wrong code. Please check again',
      reset_password: 'Reset password',
      reset_password_desc: 'Change the password you use to log in.',
      change_password: 'Change password',
      shopify_stores: 'Shopify stores',
      shopify_stores_desc:
        'You can manage your Shopify stores here. If you want to uninstall DSers from, please don’t forget to unlink from here as well.',
      shopify_stores_re_authorized:
        'The store needs to be re-authorized. Please update the authorization. Otherwise you can’t operate the store properly with DSers',
      shopify_stores_tip:
        'We upgraded the Shopify authorization. Please click to renew it on the Shopify app page. Otherwise it will affect order fulfillment.',
      store_name: 'Store name',
      status: 'Status',
      connected: 'Connected',
      noData:'No linked store',
      disconnect: 'Disconnect',
      ae_account: 'AliExpress account',
      ae_account_desc: 'You can disconnect or switch AliExpress account here.',
      account_id: 'Account ID',
      change_account_title: 'Swith Billing Store',
      change_account_content:
        'You are about to set {choiceLabel} as the Billing Store. For the best experience on the DSers platform, please ensure that this store is always open',
      switch: 'Switch',
      ae_account_tip:
        'Please note that if you link to a different account,the information of orders placed in the current account won’t be updated to DSers anymore after disconnecting',
      click_to_renew: 'Click to Renew',
      disconnect_ae_tip1:
        'Are you sure you want to disconnect your AliExpress account? The information of the orders placed with the current account <i>won’t be updated</i> to DSers anymore after disconnecting',
      disconnect_ae_tip2:
        'After disconnecting, you will find that the <i>data in the account will disappear temporarily.</i> It will be recovered after you link to a new AliExpress account.',
      disconnect_ae_confirm: 'Please input CONFIRM to unlink.',
      reconnect_ae:
        'Please make sure to reconnect to the same account. Otherwise, the information of the orders placed with the current account <i>won’t be updated</i> to DSers anymore after disconnecting.',
      switch_ae:
        'Are you sure you want to switch AliExpress account? The information of the orders placed with the current account <i>won’t be updated</i> to DSers anymore after disconnecting.',
      open_mailbox: 'Open mailbox',
      send_again: 'Send again',
      send_code: 'Send code',
      verify: 'Verify',
      store_name_tip:
        'DSers will display the original store name when you registered on Shopify and we will not update the store name even if you have changed it on Shopify. Please kindly note the store name will not be revealed to customers and will not affect orders on DSers.',
      verify_email_title: 'Verify your email address',
      verify_email_title_succ: 'Verification email sent',
      verify_email_title_fail: 'Verification email delivery failed',
      verify_email_desc:
        'To help keep your account secure, please verify your email address.We will send a verification email to {email}',
      verify_email_desc_succ:
        'Please verify your email address by following the link in the email sent to {email}',
      verify_send: 'Send verification email',
      verify_re_send: 'Resend verification email',
      white_list: 'Whitelist',
      white_listed: 'Whitelisted',

      changeEmailName: `Change email`,
      code_verify: `Code is required`,
      jiabai_intro: `Add your AliExpress account to the whitelist`,
      authorize_name: 'Updates',
      authorize_tips:'Update Permissions From Shopify  It appears that the DSers Features Permissions are not up to date. Please update them with a few clicks to make sure that DSers can work properly.'
    }, //  add
    shipping: {
      title: 'Shipping',
      description: 'Set up your default shipping method for specific country.',
      turn_off: 'Turn off',
      turn_on: 'Turn on',
      save_succ: 'Save success',
      save_failure: 'Save failure',
      advanced: {
        title: 'Advanced',
        description:
          'Set up your default shipping method by price range and delivery date range.',
        tracking_information_available: 'Tracking information Available',
        delivery_period: 'Delivery period',
        delivery_period_tip: 'maximum acceptable delivery period',
        delivery_period_error: 'Please enter the number of days.',
        days: 'Days',
        shipping_cost: 'Shipping cost',
        shipping_cost_error: 'Please enter the price.',
        tip: 'When multiple options meet my conditions',
        i_need: 'I need',
        less_cost: 'less cost',
        less_day: 'less day'
      },
      general: {
        title: 'Add Global shipping method card',
        description:
          'Set up your default shipping method for global countries. Auto order system will choose shipping method according to your setting while placing orders.Option 1 will be your first choice.',
        placeholder: 'Add more shipping method',
        option: 'Option'
      },
      product: {
        title: 'Add Global shipping method based on product',
        dec:
          'Set up your default shipping method for specific AliExpress product. Product based setting has higher priority compared to setting based on country.',
        placeholder: 'Search by supplier product title',
        modal_placeholder: 'Search by keywords of supplier product title',
        tip: 'Only the products already mapped can be found here',
        shortcut_open:
          'You can have quick access to this feature by going to Mapping - More action'
      }
    },
    tracking: {
      title: 'Tracking',
      click_to_renew: 'Click to Renew',
      renew_reminder:
        'Your PayPal  account has expired. To not affect the syncing of your tracking numbers, please re-authorize your PayPal',
      description: 'Set fulfillment notification email by different store.',
      activate_email: 'Activate fulfillment notification email sent by Shopify',
      activate_email_description: `Activate this to send the Shopify Shipping Confirmation mail automatically to your customer once the tracking number has been generated.
                                  You can edit the email template`,
      here: 'here',
      activate_email_tip:
        'This feature will help to customize shipping carrier and tracking url in your Shopify fulfillment email.',
      shipping_carrier: 'Shipping carrier',
      custom_tracking_url: 'Custom Tracking Url',
      custom_tracking_url_tip:
        'Please note: We will dynamically add a tracking code to the end of this URL. If you want to use URL http://www.17track.net/en/track?nums=1234567 enter only http://www.17track.net/en/track?nums=',
      please_enter_a_url: 'Please enter tracking url.',
      add_a_shopify_store: 'Add a Shopify Store',
      save_succ: 'Save setting success.',
      save_fail: 'Save setting failure.',
      track_to_paypal_title: 'Sync tracking number to PayPal',
      track_to_paypal_title1: 'Manage PayPal account',
      track_to_paypal_desc:
        'Authorize DSers to add tracking numbers to your transaction on PayPal.',
      connect_to_paypal: 'Connect to Paypal',
      connect_to_paypal2: 'Click the button to connect to PayPal',
      connect_to_paypal_desc:
        'In order to ensure that your Tracking Number can be synced normally, please authorize the binding of your PayPal account to the Shopify store',
      not_succ: 'Authorization did not succeed. <br /> Please try again.',
      use_paypal_account: 'Or use an authorized PayPal account :',
      to_connect_paypal_account: 'Select a PayPal account to connect to',
      paypal_placeholder: 'Select an account',
      paypal_reminder: 'Please authorize your PayPal account above',
      linked_paypal: 'You are linked to the PayPal account:',
      disconnect: 'Disconnect',
      disconnect_tip:
        'Are you sure you want to disconnect your PayPal account? Please note the tracking number won’t be updated to PayPal account anymore after disconnecting.',
      update: 'Update',
      refresh_page: 'Please refresh the page to update',
      select_1: `Select a shipping carrier`,
      select_2: `Select a custom tracking URL`
    },
    notification: {
      title: 'Notification',
      description:
        'If you activate this feature, DSers will monitor out-of-stock products, price changes, AliExpress cancelled orders, product SKU changes and notifications will be sent to your DSers account.',
      inventory: 'Inventory',
      inventory_description:
        'Get notified when product is out of stock or not available anymore', // m
      price: 'Price',
      price_description:
        'Get notified when supplier price changed on AliExpress',
      canceled_order: 'Cancelled order',
      canceled_order_description:
        'Get notified when an order is cancelled on AliExpress',
      sku: 'SKU changes',
      sku_description: 'Get notified when a product SKU changed on AliExpress',
      order: 'Order', // d
      order_description: 'Get notified when orders are canceled on AliExpress', //  d
      tip: `DSers notifications will be activated if you check the corresponding boxes and save.`,
      auto_updates: 'Automatic Inventory Update', //  a
      auto_updates_tip:
        'The updates won’t be applied to multi-supplier products like Advanced, BOGO or Bundle mapping', //  a
      out_of_stock:
        'When a product/variant is out of stock or no longer available, you want DSers to', //  a
      product_out:'When a product is out of stock or no longer available, you want DSers to',
      variant_out:'When a variant is out of stock or no longer available, you want DSers to',
      do_nothing: 'Do Nothing', //  a
      set_as_draft:'Set as draft',
      set_quantity_to_zero_on_Shopify:'Set quantity to zero on Shopify',
      remove_Variant:'Remove Variant',
      set_quantity_to_zero_on_Shopify:'Set quantity to zero on Shopify',
      to_zero: 'Set quantity to zero on Shopify', //  a
      inventory_change: 'When inventory changes, you want DSers to', //  a
      update_to_shopify: 'Update to Shopify Automatically', //  a
      notification_feature: `Notification feature`
    },
     priceUpdate: {
      suppliers_price:`When your suppliers price`,
			increase: "increase",
			decrease:"decrease",
      pricing_change:`And the price's change exceeds`,
      anychange:`Any changes`,
      title: 'Automatic Price Update',
      auto_updates_tip: "After you turn on the automatic price update feature, DSers will track the changes in the supplier's product cost, and automatically synchronize the updated price to Shopify according to the pricing rule you set",
      note: 'Please note: You need to have the Pricing Rule feature activated to use this feature. If you change the price in Shopify, we will still update the price on Shopify according to the pricing rule you set on DSers. Update range: 10 days of ordered products & browsed products',
      supplier: 'When the supplier changes price, you want DSers to:',
      updateCon: 'Update price and Compared at price to Shopify store',
      do_nothing: 'Do Nothing',
      to_shopify: 'Update price to Shopify store',
      cost_change: 'Update price when the cost changes',
      to_setting: '<span>Automatic price update after cost change</span> <a style="text-align:end" href="/app/settings?id=11">You can manage this in Settings</a>',
      to_price: 'This store does not have pricing rules',
      check_error: 'Please check the price fluctuation method',
      range: 'The range cannot be 0',
      priceError: 'The store is disconnected, please go to Setting - Account to reconnect it',
    },
    pricing: {
      title: 'Pricing Rule',
      description:
        "Pricing rule will apply automatically when you push products from DSers to Shopify. Click <a href={href} target='_blank'>here</a> to get more information.",
      enabled: 'Enabled',
      disabled: 'Disabled',
      pricing_cost: 'Pricing Cost(USD)',
      price: 'Price',
      price_tip:
        'E.g. The price of product costing 10 USD will be set at 12 or 20 USD (if you add 2 to cost, or multiply it by 2)',
      compared_at_price: 'Compared at price',
      compared_at_price_tip: `E.g. The compared price of product costing 10 USD will be set at 15 or 50 USD (if you add 5 to cost, or multiply it by 5). <br/> If you do not check this option, the value of the compared price will be empty when you push products to your Shopify store.`,
      compared_ar: `If you do not check this option, the value of the compared price will be empty when you push products to your Shopify store.`,
      price_no: `If you apply the compared price when pushing products and then deactivate this option, the compared price will not be cleared when you manually update the price in DSers notification page.`,
      set_rules: 'Set your compared at pricing rules',
      basic: 'Basic',
      standard: 'Standard',
      advanced: 'Advanced',
      cost_range: 'Cost Range(USD)',
      compared_price: 'Compared at Price',
      rest_price: 'Rest of the price ranges',
      basic_more: 'Click <a href="https://help.dsers.com/basic-pricing-rule-for-shopify/" target="_blank">here</a> to get more information.',
      standard_more: 'Click <a href="https://help.dsers.com/standard-pricing-rule-for-shopify/" target="_blank">here</a> to get more information.',
      advanced_more: 'Click <a href="https://help.dsers.com/advanced-pricing-rule-for-shopify/" target="_blank">here</a> to get more information.',
      formula_text_1: 'Price value = [(Product Cost + Shipping Cost) × (1+ Profit %) + Fixed Profit] / (1 - Breakeven %)',
      formula_text_2: 'Compare Price = Price value × / ＋ your setting number',
      formula_text_reminder: 'PS: If the Final Profit ((Product Cost + Shipping Cost) × Profit % + Fixed Profit) is lower than the Minimum Profit you set, then the following formula will be applied:Price Value= [(Product Cost + Shipping Cost) + Minimum Profit] / (1 - Breakeven %)',
      advanced_rest_price: 'Rest of the ranges',
      advanced_cost_range: 'Product Cost Range(USD)',
      profit: 'profit%',
      fixed_profit: 'Fixed Profit',
      min_profit: 'Minimum Profit',
      breakeven: 'Breakeven%',
      shippingCostReminder:
        'The shipping cost will be calculated according to the shipping method selected by default in Shipping Cost Setting.',
      breakevenReminder:
        'Set a breakeven point to cover the Channel fees,payment fees, or other fees may have',
      shipping_cost: 'Shipping Cost',
      add_shipping_cost: 'Add Shipping Cost',
      shipping_title: 'Shipping Cost Setting',
      shipping_reminder:
        'We will calculate the shipping cost according to the shipping method you choose. You can also choose to enter a specific average shipping cost if you deliver to multiple countries.',
      product_cost_setting: 'Product Cost',
      product_cost_settingVal: 'The product cost will be set according to the national price corresponding to the "Ship to" destination of the product. For details, please <a href="https://help.dsers.com/import-product-cost-for-shopify/" style="color:#3D5AFE" >click here</a>',
      product_cost_reminder:
        'When you import the product and in the notifications, we will calculate the cost of the product and notify the changes according to the country you choose. This will only be in effect after you set this, and the previous data will not be changed (we will gradually support more countries).',
      fixed_cost: 'Specific shipping cost',
      ranges_overlap: 'Your ranges overlap.',
      ranges_start_greater_end:
        'Cost range end value must be greater than or equal to the starting value.',
      assign_cents: 'Assign cents',
      assign_cents_description:
        'You can set a specific cent value for your retail price. We will use this value when forming the final price for your items (e.g., if you want the cost of your product to be XX.99 then add 99 to the fields below).',
      assign_compared_at_cents: 'Assign compared at cents',
      need_link_store: 'You need link to a shopify store first.',
      save_succ: 'Saved succeed',
      currency:'Currency',
      apply_go:`Apply currency rate to the pricing rules when you publish products to Shopify. If you want to activate this feature, please go to`,
      apply_to:`to set up currency for your stores`,
			apply_to_mes: "Apply currency rate to the pricing rules when you publish products to Shopify. The Global Pricing Rules work differently if you are NOT selling in USD on Shopify and do not want to activate this feature. Please calculate the multiplers accordingly.",
      apply_btn:`Setting - Stores`,
      bigTit:`The Price you set is higher than the Compared at price, please check and save`,
      inputerr100:'Value range: 0% to 99%',
      basicpricerule:'Basic pricing rule',
      standardpricerule:"Standard pricing rule",
      advancedpricerule:"Advanced pricing rule",
      setpricing:'Set Pricing Rules Details',
      pricing_rule_new_title:'Pricing Templates',
      pricing_rule_new_dsec:'Pricing Templates let you set the prices for the products easily. Select the store to which you want to apply the templates. View and check the Pricing Rule details to further customize the prices of your products.'
    },
    billing: {
      // d
      activate_google_auth:
        'We strongly recommend you to Activate Google<br /> Auth to ensure your account security',
      activate: 'ACTIVATE',
      remaining_credit: 'Remaining credit',
      make_a_payment: 'MAKE A PAYMENT',
      make_a_payment_tip:
        'Please fill in your billing address before you can use it.',
      last_payment:
        '{key, plural, =q1 {Your last payment was on} =q2 {for USD} =q3 {and is pending on paypal.} =q4 {payment faild on paypal. please check and payment again.} }',
      transactions: 'Transactions',
      date: 'Date',
      description: 'Description',
      amount: 'Amount (USD)',
      purchase_record: 'Purchase record',
      download: 'Download',
      all_bills: 'All bills',
      save_succ: 'save success',
      save_error: 'save error',
      google_auth: {
        step1: 'Step 1 : Download Google Authenticator',
        step1_description:
          'The IOS user logs in to the AppStore to search for “Google Authenticator", and the Android user logs into the app store or uses the mobile browser to search for "Google Authenticator" download.',
        step2: 'Step 2 : Bind Google Authenticator',
        scan_qr_code: 'IOS/Andorid：Scan QR code',
        code:
          'If the scan code does not work, you can enter the verification code.',
        please_save: 'Used to retrieve google auth, please save.'
      },
      billing: {
        billing_information: 'Billing information',
        business_name: 'Business name',
        business_name_error: 'Please enter a Name',
        email: 'Email',
        email_not_valid: 'The input is not valid E-mail!',
        email_null_error: 'Please input E-mail!',
        phone: 'Phone',
        phone_error: 'Please enter a Name',
        city: 'City',
        city_error: 'Please enter a city',
        currency: 'Currency',
        currency_error: 'Please enter a Name',
        country: 'Country',
        country_error: 'Please enter a country',
        address: 'Address',
        address_error: 'Please enter a address',
        post_code: 'Post code',
        province: 'Province',
        province_error: 'Please enter a province',
        company: 'Company',
        send_this_address: 'Send sample to this address'
      },
      primary: {
        title: 'Primary contact',
        name: 'Name',
        name_error: 'Please enter a Name',
        use_billing_address: 'Use as billing address',
        send_sample_to_this_address: 'Send sample to this address'
      }
    }, // delete
    verified: {
      // delete
      title: 'Verified Supplier',
      img_max_1mb: 'Image must smaller than 1MB!',
      bag_default: 'Bag (default and free)',
      bag_box: 'Box ($0.2/pacakge)',
      bag_box_logo: 'Box with logo ($0.2/pacakge)',
      bag_box_card: 'Box with card ($0.2/pacakage)',
      bag_box_logo_card: 'Box with logo and card ($0.2/package)',
      upload: 'Upload',
      package_request: 'Package request',
      logo_file: 'Logo file',
      note: 'Note'
    }, // delete
    report: {
      timezone: {
        title: 'Time zone',
        desc: `Your default time zone is based on your browser settings. You can reselect a time zone here for your DSers account.
              BUT please note that you can only set it up one time and it can not be changed after saving.`,
        save_modal_desc:
          'You can <i>only set up time zone once</i>. Please make sure you selected the correct one.It can not be changed after setting.',
        save_modal_tip: 'Please input CONFIRM to save'
      },
      currencies: {
        title: 'Currencies',
        desc: `USD will be used as the default currency on DSers.
              You can set this up for each store and the currency that you selected will be applied to AliExpress products and orders on DSers accordingly.
              The data shown on DSers might be different from what you see on Shopify or AliExpress due to the changing of currency rates.
              <i>You can only set up your currency one time for each store</i>.
              If you need to change currency later, please  <a href="mailto:support@dserspro.com"> contact support</a>.`,
        store: 'Store',
        currency: 'Currency',
        no_store_tip: 'Please link to a store to set up.',
        save_modal_title: 'You can only change the currency once!',
        save_modal_desc:
          'Please note that <i>you can only change currency one time</i> . Please type CONFIRM below to save your setting.',
        input_confirm: 'Please input CONFIRM'
      }
    },
    other: {
      default_phone_number_placeholder: 'Choose a country',
      phone_rules_SA_AE_IL: 'it requires 9 digits numbers starts with 5',
      phone_rules_FR: 'it requires 10 digits mobile phone numbers.',
      phone_rules_PT: 'it requires 9 or 10 digits numbers',
      phone_rules_BR_JP: 'it requires 10 or 11 digits numbers',
      phone_rules_KR: 'Please enter 10 or 11-digit phone number starting with 01 or 1 (e.g. 01012345678)',
      phone_rules_IT: 'It requires 9 or 10 digits mobile phone numbers.',
      set_product_title: 'Set products as taxable',
      set_product_subtitle: 'Specifies whether or not a tax is charged when the product variant is sold.',
      sync_ali_order_number:
        'Sync Ali order number to Shopify additional details',
      sync_ali_order_number_tip:
        'If this setting is on, your Ali order number will be auto synced to Shopify order detail page.',
      canceled_orders_optimization: 'Canceled Orders Optimization.',
      canceled_orders_optimization_tip:
        'Activate this feature to automatically optimize an order cancelled by AliExpress. The optimization by DSers will greatly reduce the probability for the order to be cancelled again.',
      synchronize_shopify_order_notes: 'Synchronize Shopify order notes',
      synchronize_shopify_order_notes_tip:
        'Activate this feature to automatically synchronize Shopify order notes. DSers will help to send the notes of an order from Shopify to AliExpress along with orders.',
      synchronize_shopify_order_message: "Synchronize Shopify order customization information",
      synchronize_shopify_order_message_tip:"Activating this feature automatically synchronize Shopify order customization information. DSers will help you send the customization information from Shopify to suppliers along with the order.",
      leave_a_meaage: 'Leave a message to Suppliers',
      leave_a_meaage_tip:
        'Suppliers will get this message together with your order information.',
      message_placeholder:
        'Input a maximum of 1000 characters',
      ioss_message: 'IOSS',
      ioss_message_tip:
        'If you want to pay VAT yourself instead of paying through AliExpress, you need to add an IOSS ID to the buyer information of your AliExpress orders shipped to the EU.',
      ioss_message_tip_2: `Please ask the AliExpress sellers to add the IOSS ID to the order as well and provide the IOSS ID to the shipping provider, so that the packages can pass the customs normally. It is extremely important that you contact the sellers to ensure he will do so.`,
      ioss_message_info: `(Your AliExpress account needs to be verified as Business Buyer in order to add a VAT tax number on AliExpress, then declare and pay VAT by yourself.)<br/>The IOSS ID you set here will be automatically added to "Address 2" of the AliExpress orders when you place them to AliExpress. If you modify the IOSS ID here, the new ID will only be applied to new orders placed to AliExpress.`,
      ioss_message_more: `Click <a href="https://www.dsers.com/blog/eu-vat-reform/" target="_blank">here</a> to get more information.`,
      ioss_message_error: 'Please fill in a correct IOSS ID to avoid failing to place the orders to AliExpress or getting packages stopped by the customs.',
      default_phone_number_placeholder: 'Choose a country',
      default_phone_number: 'Set default phone number',
      default_phone_number_tip: `If an order doesn't include a phone number, the system will use the default phone number you set to fill it in`,
      override_phone_number: 'Override phone number',
      override_phone_number_uae:
        'For orders sent to Saudi Arabia, United Arab Emirates & Israel, it requires 9 digits numbers starts with 5.',
      override_phone_number_fr:
        'For orders sent to France, it requires 10 digits mobile phone numbers starts with 06.',
      override_phone_number_pt:
        'For orders sent to Portugal, it requires 9 or 10 digits numbers.',
      override_phone_number_br:
        'For orders sent to Brazil, it requires 10 or 11 digits numbers.',
      override_phone_number_jp:
        'For orders sent to Japan, it requires 10 or 11 digits numbers.',
      override_phone_number_ko:
        'For orders sent to Korea, it requires 10 or 11 digits numbers starts with 01 or 1.',
      override_phone_number_it:
        'For orders sent to Italy, it requires 9 or 10 digits numbers.',
      override_phone_number_intro_start: 'Please note that:',
      override_phone_number_intro_end: 'Please fill in accordingly.',
      override_phone_number_tip:
        "Override customer's phone number with your own number when placing order on Suppliers.",
      france_phone_number_tip: `Please note that for orders sent to Saudi Arabia, United Arab Emirates, it requires 9 digits numbers starts with 5.
                                For orders sent to France, it requires 10 digits mobile phone numbers starts with 06 or 07. Please fill in accordingly.`,
      sync_shopify_orders: 'Sync Shopify orders',
      sync_shopify_orders_tip:
        'DSers will sync orders from the last 7 days by default. Choose a date range to sync more orders you want .You can sync up to 30 days per time.',
      sync_shopify_orders_error: 'You can sync up to 30 days per time.',
      select_store_placeholder: 'Select store',
      sync: 'Sync',
      affiliate_program: 'Affiliate program', // add
      affiliate: 'Affiliate', // add
      affiliate_desc: 'You have activated your Admitad cashback', // add
      change_code: 'Change code', // add
      change_code_wraming: 'Are you sure to clear the current code ?', // add
      step: 'Step', // add
      new_affiliate_tip: 'Register a NEW Admitad account via the link below', // add
      why_affiliate: 'Why I need a new Admitad account?', // add
      get_affiliate_btn: 'Get Admitad account', // add
      set_affiliate: 'Set up your Admitad account.', // add
      how_to_set: 'Check how to set it up?', // add
      fill_affiliate: 'Fill in your affiliate code and account name..', // add
      where_code: 'Where to find the code and account name?', // add
      code: 'Code', // add
      err_tip: 'This is not a DSers Affiliate code.Please double check.', // add
      message_ae_sellers:
        'Message saved successfully.Suppliers will receive it when you place orders.',
      save_phone_succ: 'Phone Number saved successfully.',
      cash_back_code_error:
        'This is not a valid code. The code should be a mix of 30 letters and numbers. Please double check.',
      cost_error: 'Please enter a number in the format 5 or 5.99',
      create_success: 'Create success',
      admitad_stopping_now:
        'Your Admitad cashback is stopping now. Please change a new code assigned to DSers. <a href={href} target="_blank">Check why and how</a>',
      notice: `Our system detected that your Admitad account can’t be verified.
           Please make sure you created the Admitad account via&nbsp;
          <a
            href="https://store.admitad.com/en/promo/?ref=8e3xmmxnjp"
            target="_blank"
            rel="noopener noreferrer"
          >
            DSers referral link
          </a>
          &nbsp;and then change your code on DSers accordingly.
          <br />
          <div style="height:8px"></div>
          If your Admitad account was registered via&nbsp;
          <a
            href="https://store.admitad.com/en/promo/?ref=8e3xmmxnjp"
            target="_blank"
            rel="noopener noreferrer"
          >
            DSers referral link
          </a>
          , but still can’t be verified, please double check if you filled in the correct account name on DSers.`,
      notice_sub:
        'Please note the current admitad account will be disabled after 48 hours.',
      notice_add_title:"About Admitad Account",
      notice_2:
        'In order to make sure your Admitad account is working well with DSers, please go to Setting - Other - Affiliate program to finish the activation. Otherwise your Admitad code will be disabled after 48 hours.',
      not_account_name: 'Please fill in your Admitad account name.',
      notice_button_change: 'CHANGE ACCOUNT NAME',
      notice_button_register: 'REGISTER NOW',
      cancel_fulfillment_title: 'Request Fulfillment Cancelation',
      cancel_fulfillment_desc: `After activating this function of corresponding app below, the orders that cannot be placed from the app because you clicked "Request Fulfillment" on Shopify will be restored so you can place them normally to AliExpress.`,
      aliSku: 'Use the supplier ID as product SKU',
      aliSku_desc: 'After activation, when you import supplier products in the Import List, the SKU of the product will use the supplier product ID-variants name.',
      vat_title: `When the price of an order to France exceeds 150 Euros, you need to provide your VAT ID and your company name. Please fill it in the following order, so as not to affect the normal process. Click <a target="_blank" href="https://www.blog.dserspro.com/post/orders-to-france-specifications-with-shopify-dsers">here</a> to get more information.`,
      vat_id_verify: `Please enter the 8-12 digits VAT ID number. Don't add FR at the beginning.`,
      vat_input_verify: `Please enter VAT ID`,
      vat_company: `Please enter Company name`,
      vat_h3: 'VAT',
      plan_info_id: 'VAT ID',
      plan_info_name: 'Company name',
      override_phone_number_intro_start_new: 'Please note that:',
      override_phone_number_first: 'it requires a 9-digit number starting with 5.',
      override_phone_number_second: 'it requires a 9-digit number starting with 5 or a 10-digit number starting with 0.',
      override_phone_number_third:'it requires a 9 or 10 digits number.',
      override_phone_number_fourth: 'it requires a 10 or 11 digits number.',
      override_phone_number_fifth:'it requires a 10 or 11 digits number starting with 01 or 1.',
      phone_rules_SA_AE_IL_new:'It requires a 9-digit number starting with 5 or a 10-digit number starting with 0.',
      phone_rules_FR_new:'It requires a 9 or 10 digits phone number.',
      order_save:'SAVE',
      order_fulfillment_module:'Fulfillment Settings',
			order_fulfillment_first_title:'Fulfill items seperately',
			order_fulfillment_first_content:'After activating this feature, DSers will fulfill each product in the orders seperately and trigger Shopify shipping confirmation email with the following rules: When a product in a DSers order has a tracking number, we will fulfill that item in the order and then Shopify will send an email to your customer. Shopify will send email multiple times for the same order.',
			order_fulfillment_second_title:'Fulfill items in bulk',
			order_fulfillment_second_content:'After activating this feature, DSers will fulfill an order only after all products of the order got tracking numbers and trigger Shopify to send only one email to your customer.',
      order_split_pop_title:'Note',
      order_split_pop_content:'In order to keep improving DSers stability and our merchant’s experience, if you turn off this feature, it will no longer be accessible. Are you sure you want to stop using this feature?',
      order_split_pop_btn:'CONFIRM'
    },
    payment: {
      top_title: "Business Information",
      top_small_title: 'Used on Tmall order statements.',
      top_form_item1: 'First Name',
      top_form_item2: 'Last Name',
      top_form_item3: 'Business Name',
      top_form_item4: 'Email',
      top_form_item5: 'Country/region',
      top_form_item6: 'Address',
      top_form_item7: 'Apartment, suite, etc.',
      top_form_item8: 'City',
      top_form_item9: 'State',
      top_form_item10: 'ZIP code',
      bottom_title: "Tmall Statements",
      bottom_small_title:"Check and download monthly statement of Tmall orders. (Update the statement of the previous month on the x of each month)",
      bottom_state1: "Request",
      bottom_state2:"In the request"
    },
    order:{
      input_placeholder:`Enter the product's AliExpress link for which you want to split orders`,
      order_same:`The product link, quantity and country you configured are exactly the same, please check and save`,
      title:'Automatic Order Split',
      desc:`We will split the order for you according to the AliExpress product link and product quantity you set. Order splitting rules: when the product of the order is the supplier you set, and the product quantity is more than or equal to the quantity you set in the setting, which has to be less than 10, we will automatically split the order into multiple Shopify orders.`,
      inputNumber:`Please enter the amount you want to split`,
      incorrect:`The product link you entered is incorrect`,
      banner_title: 'Automatically split orders',
      banner_text:'Having issue when using cost-effective AliExpress Saver Shipping? Check this solution.',
      banner_btn:'learn more',
      titip:'Order',
      delivery_period_tip:'Minimum 2, maximum 10',
      delivery_period:'Quantity of products in orders to split',
      split_ending:'Splitting orders',
      delete_dsers: 'This product had been deleted in DSers',
    },
    subaccount:{
      add:'Add Staff Account',
      sendemail:'We will send an email to the email address you filled in to complete the authorization, and your employees can log in to DSers through this account',
      admintext:'All permissions including Admin permissions',
      fulltext:'All pages and features, but no Admin permissions',
      limittext:'Show to the owner according to the page and shop you configured',
      emailempty:'Please input the email address',
      emailformat:'Please input a correct email address',
      NoAccess:'No page access',
      active:'Active',
      NoAccessTitle:'You do not have access to the current page, please contact your administrator',
      report:"Report",
      ImportList:"Import List",
      MyProduct:"My Products",
      Openorder:"Open Order",
      ArchivedOrder:"Archived Order",
      Tracking:"Tracking",
      CSVBulkOrder:"CSV Bulk Order",
      LinktoMoreStores:"Link to More Stores",
      Setting:"Settings",
      Notification:"Notification",
      fullaccess:"Full access",
			admin:"Admin",
			Limitedaccess:"Limited access",
			Full:"Full",
			Storeaccess:"Store access",
			Featureaccess:"Feature access",
			invited:"invited",
			Notconnected:"Not connected"
    },
    product: {
      customize_title: 'Customize product description',
      customize_con: 'When pushing products, you want DSers to:',
      shipping_cost_title:'Send product information to Shopify',
      customize_specifications: "Only push products' SPECIFICATIONS",
      customize_overview: "Only push products' OVERVIEW",
      customize_all: "Push both SPECIFICATIONS & OVERVIEW",
			location_title:"Product fulfillment service management",
			location_content:"You can decide which fulfillment service for products and orders the DSers platform will help you manage through the fulfillment service switch. If you activate the switch, it will help you to automatically update the product information corresponding to the fulfillment service and display the orders corresponding to that fulfillment service normally. If you deactivate it, it will not automatically update the product information corresponding to the fulfillment service for you, and it will not display the orders corresponding to that fulfillment service.",
      shipping_cost_tip1:'Send supplier products cost per item to Shopify when you push product.',
      shipping_cost_tip2:`When the product is pushed, the supplier products cost and the shipping cost in [Advanced Pricing Rule] are returned to Shopify's cost per item together.
      There may be some reasons why the shipping cost is 0:<br />
      1. After applying the Advanced Pricing Rule, the shipping method matched according to the country is free, and the shipping cost returned to Shopify is 0 at this time.<br />
      2. After the Advanced Pricing Rule is applied, no shipping methods matched accordingly. At this time, the shipping cost returned to Shopify is 0.<br />
      3. After applying the Basic/Standard Pricing Rule, there is no shipping cost, and the shipping cost returned to Shopify is 0.<br />
      4. The pricing rule is not enabled, and there is no shipping cost. At this time, the shipping cost returned to Shopify is 0.<br />
      You need to upgrade your plan and apply the Advanced Pricing rule before DSers can help you return the product cost and shipping cost to Shopify.`
    },
    product_price:'Update product price',
    title_account:'Account',
    title_time_zone:'Time zone',
    time_zone_des:'Your default time zone is based on your browser settings. You can reselect a time zone here for your DSers account. BUT please note that you can only set it up one time and it can not be changed after saving.',
    title_ae_account:'AliExpress Account',
    ae_account_des:'Please note that if you link to a different account, the information of orders placed in the current account won’t be updated to DSers anymore after disconnecting.',
    title_synchronization:'Synchronization',
    title_automatic:'Automatic Price Update',
    automatic_des:'After you turn on the automatic price update feature, DSers will automatically synchronize the updated price to Shopify according to the pricing rule you set every 24 hours if the supplier changes the price for more than $1.',
    automatic_des2:'Please note: You need to have the Pricing Rule feature activated to use this feature. If you change the price in Shopify, we will update the price on Shopify according to the set pricing rule you on DSers.',
    title_update_pro_pr:'Manually change store products price',
    update_pro_pr_des:'Update the price of the products pushed to your Shopify store according to the pricing rules you currently set. The process may take some time. ',
    update_pro_pr_des_time_1:'Manual update up to',
    update_pro_pr_des_time_2:'times every 30 days',
    title_sync_shop_order:'Sync Shopify orders',
    sync_shop_order_des:'DSers will sync orders from the last 7 days by default. Choose a date range to sync more orders you want .You can sync up to 30 days per time.',
    title_order_details_sync:'Order Details Synchronization',
    title_affiliate_program:'Affiliate program',
    title_notification:'Notification',
    title_shipping_confirmation:'Shipping Confirmation',
		title_shipping_confirmation_des: "Send the Shopify Shipping Confirmation mail automatically to your customer once the tracking number has been generated.",
    title_paypal:'Send tracking number to PayPal',
		title_tracking: "Tracking",
		title_tracking_tip:"Activate this feature to trace the tracking status of orders that have been shipped.",
    title_leave_message:'Leave a message to Suppliers',
    "title_leave_message_ae":"AliExpress suppliers will get this message together with your order information.",
    "title_leave_message_tmall":"Tmall suppliers will get this message together with your order information, but the message edition is not supported now.",
    message_des:'Suppliers will get this message together with your order information.',
    title_phone_number_op:'Phone Number Optimization',
    title_automatic_order_split:'Automatic Order Split',
    automatic_order_split_des:'We will split the order for you according to the AliExpress product link and product quantity you set. Order splitting rules: when the product of the order is the supplier you set, and the product quantity is more than or equal to the quantity you set in the setting, which has to be less than 10, we will automatically split the order into multiple Shopify orders.',
    title_order_management:'Order Management',
    title_tax:'Tax',
    title_shipping:'Shipping',
    title_add_global_shipping:'Add Global shipping method based on product',
    add_global_shipping_des:'Set up your default shipping method for specific supplier products. Product based setting has higher priority compared to setting based on country. You can have quick access to this feature by going to Mapping - More action.',
    phone_number_des:"In order for your package to the Netherlands and Finland to be delivered properly, please fill in a real phone number in the order. The Netherlands and Finland do not support the use of the Phone Number Optimization feature for the time being. Click  <a href=\"https://help.dsers.com/fix-failed-orders-due-to-aliexpress-phone-number-regulations/\" target=\"_blank\">here</a> to learn about how to solve this problem",
    phone_number_des_tip:"This country is not currently supported",
    title_pricing_rule:'Pricing Rule',
    title_product:'Product',
    title_sp_store:'Shopify Stores',
    sp_stores_des:'You can manage your Shopify stores here. If you want to uninstall DSers from a store, please don’t forget to unlink from here as well.',
    title_currencies:'Currencies',
    disablestext: 'Select a store',
    password: 'Password',
    admitadAccount1: "Admitad account had been suspended due to verification failure. Click",
		admitadAccountLink: "here",
    admitadAccount2: "go get more detailed information.",
    refresh_store_tag: "Refresh connection",
		refresh_store_tag_tip: "If the Shopify store status is not up to date due to network reasons, click 'Refresh Connection' to obtain the store status",
		refresh_not_connected:"Not connected",
    refresh_store_icon_tip: "Refresh connect",
    affiliate_code:'Affiliate Code',
		affiliate_code_btn:'REACTIVATE',
    hasSetCurrency:`Apply currency rate to the pricing rules when you publish products to Shopify. The Global Pricing Rules work differently if you are NOT selling in USD on Shopify and do not want to activate this feature. Please calculate the multiplers accordingly `,
    pricing_rule_dsec:'Pricing Rule includes Currency, Pricing Templates and Assign Cents. You can create your Pricing Rules here and apply them to your products.',
    updateProductPrice:`Update the price of the products pushed to your Shopify store according to the pricing rules you currently set. The process may take some time.Click <a href="/app/pricing?select=1">here</a> to upgrade plan to increase the synchronization times. `,
    ...agency,
  }
};
