import React, { useEffect, useRef } from 'react';
import { Get, Put } from '../../utils/request';

function CheckBigUser() {
  const timer = useRef(null);

  function putUserLoginStatus() {
    Put('/users/update/login_status');
  }

  async function getIsBigUser() {
    try {
      const res = await Get('/users/check');

      if (res.data === true) {
        timer.current = setInterval(putUserLoginStatus, 1000 * 60 * 5);
      }
    } catch (error) {
      console.log('fetch error:', error);
    }
  }

  useEffect(() => {
    getIsBigUser();

    return () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    };
  }, []);

  return null;
}

export default CheckBigUser;
