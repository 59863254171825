export default {
  top: {
    bind_ae_expire:
      `Le compte AliExpress que vous avez lié à DSers est sur le point d'expirer. Veuillez lier votre compte AliExpress de nouveau.`, //  add  待确定
    whitelist_expire:
      `Le compte AliExpress que vous avez lié à DSers est sur le point d'expirer. Veuillez lier votre compte AliExpress de nouveau.`, //  add  待确定
    update_title: 'Mises à jour d\'autorisation', //  add
    update_desc: `DSers a détecté que les comptes ci-dessous nécessitent une mise à jour de l\'autorisation pour DSers.
                  Autrement vous ne pourrez pas utiliser DSers correctement.`, //  add
    update_desc_is_white: `DSers a détecté que votre compte doit postuler à la liste blanche AliExpress.
                          Veuillez cliquer le bouton <a>Postuler Liste Blanche</a> en haut de la page pour mettre à jour l'autorisation.
                          Sinon, vous ne pourrez plus utiliser DSers correctement.`,
    update_btn: 'Mettre à jour', //  add
    synchronizing_store: 'Synchronisation boutique...',
    top_select_store: 'Toutes les boutiques',
    select_store: 'Choisir boutique',
    link_to_aliExpress: 'Lier à AliExpress',
    bind_ali_tip: 'Cliquez ici pour lier à AliExpress', // m
    over_bind: 'Compte AliExpress Liste Blanche',
    over_bind_tip:
      'Vous avez déjà lié votre compte AliExpress et êtes sur la liste blanche',
    apply_whitelist: 'Postuler pour la liste blanche',
    timeout_tip: 'Cliquez pour être sur la liste blanche AliExpress',
    link_shopify_tip: 'Cliquez pour lier de multiples boutiques Shopify.',
    link_to_more_stores: 'Lier plus de boutiques',
    link_to_shopify_store: 'Lier à boutique Shopify',
    bind_ae_title: "Re-authorize AliExpress",
    bind_ae_content:`The AliExpress whitelisted account you have linked to DSers is about to expire. Please re-authorize AliExpress account. If you have any questions, please  <a href='https://www.messenger.com/t/DSersOfficial' target="_blank" >contact us</a>`,
    contact_by_email:
      'Si vous avez des questions, veuillez contacter <span> support@dsers.com </span>',
    ae_account_linked: 'Compte AliExpress lié',
    bind_ae_intro: 'À un pas de la gestion de vos produits et commandes avec DSers',
    bind_ae_outdated_intro: `L'autorisation de compte AliExpress expire tous les 3 mois. Veuillez cliquer sur Lien vers AliExpress pour renouveler l'autorisation.`,
    bind_ae_btn_modal:'Lier votre compte AliExpress',
    bind_ae_details:`Lier votre compte AliExpress à DSers vous permet de passer plus de 100 commandes en quelques secondes! Cliquez <a href="https://help.dsers.com/link-your-shopify-store/" target="_blank">ici</a> pour plus de détails.`,
    bind_ae_foo_btn:`LIER ALIEXPRESS`,
    chrome_dowm_tit:`Installer l'extension Chrome DSers`,
    chrome_dowm_detail:`Importation de produits en un clic d'AliExpress vers DSers pour gagner du temps et trouver de nouveaux produits gagants! Cliquez <a target="_blank" href="https://help.dsers.com/import-product-from-aliexpress-for-shopify/#Use%20DSers%20Chrome%20Extension">ici</a> pour plus de détails.`,
    chrome_foo_btn:'Télécharger',
    bindaeTip:`Statut de la commande AliExpress pas mis à jour`,
    bindaeTipDetail:`DSers ne peut pas obtenir le statut de la commande AliExpress car vous n'avez pas lié votre compte AliExpress, veuillez lier votre compte AliExpress dès que possible.`,
    binaeBtn:`LINK AIEXPRESS`,
    ChristmasTheme:'Thème Tmall',
    RegularTheme:'Thème Normal'
  }
};
