import React from 'react';
import { connect } from 'dva';
import { Icon, Tooltip } from 'antd';

import className from 'classnames';
import intl from 'react-intl-universal';
import round from 'lodash/round';

import { OrderAction } from 'actions'

import SelectShipp from './SelectShipp';

import orderStyles from '../Order.less';
import '../Order.css';

import { notEditOrderStatus, getCanceled } from '../util';
import { currencySymbolMap } from '../../../common/sysconfig';

@connect(({ order, global, loading,login,user }) => ({
  order,
  storeCurrency: global.storeCurrency,
  loading: loading.models.order,
  okLoading: loading.effects['order/express'],
  customizeCurrency: login.customizeCurrency,
  newUserInfo:user.newUserInfo,
}))
export default class Shipping extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showSelectFreight: false
    };
  }

  componentDidMount(){
    window.EVENT.on(OrderAction.CLOSE_FRIENGHT_MODAL, params => {
      this.setState({
        showSelectFreight: params
      })
    })
  }

  showSelectFreight = () => {
    const { status } = this.props;
    console.log(this.props)
    let Map = {
      2: 'Awaiting order',
      6: 'Canceled',
      7: 'Faild Orders'
    }

    window.EVENT.emit(OrderAction.INTERACTION_DOT, {
      action:'click_sp_modal',
      trans_from:`click ${Map[status] || null} shipping method`
    });

    this.setState({
      showSelectFreight: !this.state.showSelectFreight
    });
  };

  onOk = (v, type) => {
    const { updataExt, listData, index, useSp, sonIndex } = this.props;

    const company = getFreightCompayName(useSp, v);

    updataExt({
      type: 'fright',
      oid: listData.id,
      index,
      sonIndex,
      company,
      useSp
    });

    if (type !== 'onlySave') {
      this.showSelectFreight();
    }
  };

  render() {
    const {
      listData: { id, storeId, ext: { address = {} } = {} },
      child,
      status,
      child: { platformType = 2 },
      useSp, // 不是child.useSp 而是具体哪个订单
      okLoading,
      storeCurrency,
      customizeCurrency,
      newUserInfo
    } = this.props;

    const { showSelectFreight } = this.state;

    let currencyCode;
    let currencySymbol;
    let isNotUSD;
    let currencyRates;

    if (storeCurrency[storeId]) {
      currencyCode = storeCurrency[storeId].currency_code || 'USD';
      currencySymbol = currencySymbolMap[currencyCode];
      isNotUSD = currencyCode !== 'USD';
    }
    if(customizeCurrency?.storeId == storeId && 
      customizeCurrency?.currency &&
      customizeCurrency?.id == newUserInfo.org_id){
       currencyRates = customizeCurrency.currency;
   }else{
       currencyRates = this.state.currencyRatesY || storeCurrency[storeId]?.rates_value;
   }

    // 获取选中的物流信息
    const company = getFreightCompayName(useSp);

    let price;
    if (company.time) {
      const { freightAmount = company.standardFreightAmount } = company;
      price = freightAmount.value || intl.get('order.order_product.shipping_cost_free');
    }

    const isNumber = typeof price === 'number';
    // 用于判断物流是否支持到该国家
    const countryCode = address.country_code;


    return (
      <div
        className={orderStyles.productShippingMethod}
        style={{ display: platformType > 100 ? 'none' : '' }}
      >
        {/* 隐藏域，为了 guide */}
        <button
          onClick={() =>
            this.onOk(
              useSp.freights && useSp.freights[0] && useSp.freights[0].serviceName, 'onlySave'
            )
          }
          style={{ display: 'none' }}
          type="button"
          aria-label="save order shipping"
          data-label="save-item-shipping-hidden-button"
        />
        <div className={orderStyles.productShippingTool}>
          {renderShipping(
            useSp,
            this.showSelectFreight,
            showSelectFreight,
            status,
            child
          )}

          {showSelectFreight ? (
            <SelectShipp
              oid={id}
              freights={useSp.freights || []}
              freightDefault={useSp.logistics_company}
              onCancel={this.showSelectFreight}
              onOk={this.onOk}
              loading={okLoading}
              countryCode={countryCode}
              useSp={useSp}
              address={address}
            />
          ) : null}
        </div>
        <>
            <div style={{display:'flex'}}>
              <div style={{display:'flex'}}>
                <p className={orderStyles.emsPrice} style={{marginRight:'12px'}}>
                  {intl.get('order.order_product.cost')}:&nbsp;$ {(this.props.useSp.cost * 1).toFixed(2)} x {this.props.useSp.quantity}
                </p>
                <div className="shipingName">
                  <p className={orderStyles.emsPrice}>
                    {intl.get('setting.shipping.title')}: {isNumber ? `$ ${price}` : price}
                  </p>
                </div>
              </div>
            </div>
            <div style={{display:'flex',alignItems:'flex-end'}}>
              {isNotUSD ? (
              <span className={orderStyles.formX} style={{marginRight:'33px'}}>
                {`${currencySymbol} ${round(this.props.useSp.cost * currencyRates, 2)} x ${this.props.useSp.quantity}`}
              </span>
            ) : null}
              <p className={orderStyles.formX} style={isNotUSD && isNumber ? {} : {marginTop:16}}>
                {isNotUSD && isNumber ? (
                <span className={orderStyles.formX}>
                {`${currencySymbol} ${round(price * currencyRates, 2)}`}
                </span>
                  ) : null}
              </p>
             </div>
            {/* <p className="form">
              Shipping time: {company.time} {intl.get('order.order_product.shipping_time')}
            </p> */}
          </>
      </div>
    );
  }
}

function getFreightCompayName(useSp, name) {
  const freights = useSp.freights || [];
  let obj = {};
  const v = name || useSp.logistics_company;

  if (!v) {
    return obj;
  }

  freights.forEach(item => {
    if (item.serviceName == v) {
      obj = item;
    }
  });

  return obj;
}

function renderShipping(useSp, showSelectFreight, show, status, child) {

  const company = getFreightCompayName(useSp);
  let name = company.company ? company.company : undefined;

  // shopify取消单
  if (getCanceled(child, status, useSp)) {
    return <div className="aliButton">{name || intl.get('order.order_product.shipping_method')}</div>;
  }

  // 已下单 不可更改 只显示  排除ae cancel 
  if (
    (useSp.status > 2 && useSp.status != 6 && useSp.status != 7) ||
    useSp.platform_order_id && !child?.AECanceled[0] == 1
  ) {
    return (
      <div data-key="disabled" className="aliButton">
        {name || intl.get('order.order_product.shipping_method')}
      </div>
    );
  }

  // pending订单也不能改物流
  if (notEditOrderStatus.includes(status)) {
    return <div className="aliButton4">{name || intl.get('order.order_product.shipping_method')}</div>;
  }

    // if (company.serviceName == 'YANWEN_JYT') {
    //   name = undefined;
    // }

  return (
    <div
      className={orderStyles.productShippingMethodX}
      onClick={showSelectFreight}
    >
      <span
        className={className({
          [orderStyles.shippingMethodTip]: !name, // 没选物流 橙色提示
          [orderStyles.disabledExpress]: true
        })}
      >
        {
          name?.length > 25 ? <Tooltip title={name}>
              <span className={orderStyles.shipStyle}>{name || intl.get('order.order_product.shipping_method')}</span>
          </Tooltip> :
        
        <span className={orderStyles.shipStyle}>{name || intl.get('order.order_product.shipping_method')}</span>
       }
        <img src={require('../../../assets/newImportList/icon_expand_more-24px.png')}></img>
      </span>
    </div>
  );
}
