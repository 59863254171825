import React from 'react';
import { Modal, Button } from 'antd';

import intl from 'react-intl-universal';

import styles from './BindAeModal.less';
import { OrderAction } from 'actions';
import RefreshMask from '../../components/RefreshMask/index.js';

export default class BindAeModal extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            refreshVisible: false
        }
    }

    componentDidMount() {
        window.EVENT.on(OrderAction.AE_BIND_MODAL, (status) => {
            this.setState({
                visible: status
            })
        })
    }

    linkAe = () => {
        this.props.dispatch({
            type: 'topBtn/aliauth',
            payload: {
                cancelMessage: true,
                callback: d => {
                    if (d.code === 2000) {
                        window.open(d.data.url)
                        this.setState({
                            refreshVisible: true,
                            visible: false
                        })
                    } else if (d.code === 4090) {
                        window.location = '/app/';
                    } else {
                        message.error(d.msg);
                    }
                }
            }
        });
    }

    handleCloseBindAeModal = () => {
        this.setState({
            visible: false
        })
    }

    render() {
        const { visible, refreshVisible } = this.state;

        return (
            <>
                <Modal
                    visible={visible}
                    footer={false}
                    closable={true}
                    width={500}
                    maskClosable={false}
                    onCancel={this.handleCloseBindAeModal}
                    className={styles.refreshMaskWrap}
                >
                    <h3>{intl.get('linkStore.aeModalTitle')}</h3>
                    <p>{intl.getHTML('linkStore.aeModalText')}</p>
                    <div className={styles.refreshLine}>
                        <Button type="primary" onClick={this.linkAe}>
                            {intl.get('linkStore.aeModalBut')}
                        </Button>
                    </div>
                </Modal>
                <RefreshMask visible={refreshVisible} />
            </>
        );
    }
}
