export default {
  linkStore: {
    website_platform: 'Quelle est la plateforme de votre site? *',
    website_domain: 'Quel est le domaine de votre site? *',
    domain_placeholder: 'votre-domaine',
    addonAfter: '.myshopify.com',
    add_store: 'Ajouter boutique',
    go_account: 'Se connecter à DSers',
    add_store_err:
      'Cette boutique est liée à un compte DSers. Veuillez vérifier.',
    add_store_already:
      'Cette boutique est déjà liée à un autre compte DSers. Veuillez vérifier.',
    valid_domain_msg: `Veuillez n'utiliser que l'aphabet Anglais, des numéros et des traits d'union pour le nom de la boutique.`,
    install_plugin_title: `Veuillez installer le plugin DSers pour profiter d'un service plus stable`,
    bind_ae_tip_1: 'Vous ne connaissez pas le dropshipping avec AliExpress?',
    bind_ae_tip_url: 'https://www.dsers.com/blog/aliexpress-dropshipping-101/',
    bind_ae_tip_2: `Vous n'avez pas de compte AliExpress?`,
    process: {
      link_store: 'Lier Boutique',
      choose_plan: 'Choix du Plan',
      link_ae: 'Lier AliExpress',
      install_plugin: 'Installation Plugin'
    },
    loading_Info: 'Ne refraichissez pas et ne fermez pas la page',
    bindAeTitle: `Cliquez l'icône AliExpress ci-dessous pour vous rendre sur AliExpress et autoriser le compte.`,
    bindAeTitleTwo: 'Select the option below to connect your suppliers.',
    required: 'Nécessaire',
    linkSt: `Créez un lien avec AliExpress`,
    modalTitle: 'Autorisation',
    modalText: `Veuillez cliquer sur "Ajouter application" dans Shopify. Après avoir terminé l'autorisation, cliquez sur "Mettre à jour", et nous actualiserons la page pour vous. Si vous voyez "Application installée" dans Shopify, cela signifie que ce compte Shopify est déjà lié à DSers. Si vous souhaitez lier une autre boutique, veuillez vous connecter à la boutique Shopify que vous souhaitez lier. Cliquez <a href="https://help.dsers.com/link-your-store-for-shopify/" style="color:#3d5afe">ici </a>pour en savoir plus.`,
    modalButton: 'Mise à jour',
    enterDsersTitle: 'Purchasing from other platform than AliExpress?',
    enterDsersTooltip:
      'Click the button below to go directly to your DSers account. You can choose from tens of thousands of Tmall items curated for you by DSers, or directly connect with your existing suppliers and let DSers manage your offline orders.',
    clickEnter: 'Click to enter DSers',
    aeModalTitle: 'Link your AliExpress account',
    aeModalText:
      'Linking your AliExpress account to DSers allows you to place 100+orders in seconds! Click here for more details.',
    aeModalBut: 'LINK ALIEXPRESS'
  }
};
