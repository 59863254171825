// 销售人员
export const salesmanMap = {
  Keith: 'daihanzhao@dserspro.com',
  Duan: 'duanyanran@dsers.com',
  Nora: 'cherry@dserspro.com',
  Sophia: 'zhanglu@dserspro.com',
  ALisa: 'alisa@dserspro.com',
  April: 'april@dserspro.com',
  Shannon: 'shannon@dserspro.com',
  Laura: 'laura@dserspro.com',
  Bess: 'bess@dserspro.com',
  Jennovo: 'jennovo@dserspro.com',
  Jessica: 'jessica@dserspro.com',
  Chloe: 'zhouxiaoqing@dserspro.com',
  Sam: 'sam@dserspro.com'
};

// 更换自动点赞推广用户 2022/12/23
// 1600+用户硬编码 做个人吧（产品要求立刻上线 权宜之计 重构一定要把这些已经稳定的配置做到中台）
export const autoCommentUsers = {
  // 测试账号 - 1233@testdsers.com -dev
  c9gcpnjp2uv001ndmjgg: 'Keith',
  // 测试账号 - 生产环境
  c9lttou67o9g008vue70: 'Duan',
};
