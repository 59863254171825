/* eslint-disable react/destructuring-assignment */
import React, { PureComponent } from 'react';
import { connect } from 'dva';
import { Icon, Tooltip, Select, Dropdown, Menu } from 'antd';
import className from 'classnames';
import intl from 'react-intl-universal';
import qs from 'querystring';

import { getQueryUrlParams } from 'utils/global'

import TopBtn from 'components/TopBtn';

import styles from './index.less';

// header 可见操作按钮
const allowPage = [
  '/report',
  '/order/list',
  '/order/agency/list',
  '/order/unmapped/list',
  '/order/tmall/list',
  '/order/archived',
  '/storemanage/myproduct',
  '/storemanage/import',
  '/find_suppliers',
  '/accountoverview',
  '/fulfillment-center',
  '/notification',
  '/settings',
  '/newfeature',
  '/csv',
  '/home',
  '/supplier_optimizer',
  '/tracking',
  '/agency'
];

// 启用 store select
const enableStoreSelect = [
  '/report',
  '/order/list',
  '/order/agency/list',
  '/order/unmapped/list',
  '/order/tmall/list',
  '/order/archived',
  '/storemanage/myproduct',
  '/tracking'
];

const topStore = (
  <Menu>
    <Menu.Item key="0">All stores</Menu.Item>
  </Menu>
);

// header store select 显示 all 选项
const allowAllOption = ['/order/list', '/order/archived', '/tracking', '/order/agency/list','/order/tmall/list', '/order/unmapped/list'];

export class ExtraHeader extends PureComponent {
  selectedAll = true;

  constructor(props) {
    super(props);
    this.state = {
      isSync: false, // 订单是否正在更新
      isChangeStore:true
    };

    this.stores = JSON.parse(localStorage.getItem('storeStatus')) || [];
  }

  componentDidMount() {
    this.getStoreList();
  }
  componentDidUpdate(prevProps) {
    const { userInfo, pathname, storeIdWithOrder, storeId } = this.props;
    if (userInfo !== prevProps.userInfo) {
      this.getStoreList();
    }
    
    if (pathname !== prevProps.pathname) {
      const currentStoreId =
      allowAllOption.indexOf(pathname) > -1 ? storeIdWithOrder : storeId;
      localStorage.setItem(
        'storeId',
        currentStoreId === 'all' ? '' : currentStoreId
        );
      }
      
      if (storeIdWithOrder !== prevProps.storeIdWithOrder) {
        this.selectedAll = storeIdWithOrder === 'all';
      }
      
      if(qs.parse(location.search.slice(1)).shop){
        const stores = JSON.parse(localStorage.getItem('store')).stores;
        const currentFromStoreId = stores.filter(i=>i.shop_name === qs.parse(location.search.slice(1))?.shop.split('.')[0])[0]?.id;
        if(localStorage.getItem('storeId') !== currentFromStoreId){
          this.updateStoreAtState(currentFromStoreId)
        }
      }
  }

  updateStoreAtState = value => {
    const { pathname } = this.props;
    this.setState({
      isChangeStore: false
    })
    if(value == 'all'){
      localStorage.setItem('selectStore', 1)
    }else{
      localStorage.setItem('selectStore', value)
    }
    if (allowAllOption.indexOf(pathname) > -1) {
      localStorage.setItem('currentOrdersStoreId', value);
      this.props.dispatch({
        type: 'global/updateOrderStoreId',
        payload: value
      });

      // 没选中 all ,也同步全局 storeId
      if (!this.selectedAll) {
        this.props.dispatch({ type: 'global/updateStoreId', payload: value });
      }
    } else {
      
      this.props.dispatch({ type: 'global/updateStoreId', payload: value });

      if (!this.selectedAll) {
        localStorage.setItem('currentOrdersStoreId', value);
        this.props.dispatch({
          type: 'global/updateOrderStoreId',
          payload: value
        });
      }
    }
  };

  // 获取商店列表
  getStoreList = () => {
    const { pathname }= this.props
    const stores = this.props.userInfo.stores || [];
    const stashStoreId = localStorage.getItem('storeId') || '';

    const realStoreData = stores.filter(
      item =>
        item?.shop_name !== 'Importlist' && item?.shop_name !== 'Imported Orders'
    );

    const flag = realStoreData.find(store => stashStoreId === store?.id);
    let storeId = flag && flag.id || (realStoreData[0] && realStoreData[0].id);

    if(window.location.search && this.state.isChangeStore){
      let storeIdVal = getQueryUrlParams(window.location.href, 'storeId')
      if(storeIdVal) {
        storeId = storeIdVal
      }
      
    }
    localStorage.setItem('storeLength', stores.length);
    this.props.dispatch({ type: 'global/updateStoreId', payload: storeId });
    this.props.dispatch({ type: 'global/updateOrderStoreId', payload: storeId });
    if(allowAllOption.indexOf(pathname) > -1){
      this.props.dispatch({ type: 'global/updateOrderStoreId', payload: localStorage.getItem('currentOrdersStoreId') || storeId });
    }
  };

  setIsSync = status => {
    // 设置是否正在同步订单
    this.setState({
      isSync: !!status
    });
  };

  badgeIndex = {
    archive_order: 'Archived',
    notify_unread: 'Notification',
    open_order: 'Open',
    import_list:'importList',
    my_products:'myProduct',
    ae_orders: 'AEOpen',
    tmall_orders: 'TmallOpen',
    agency_orders: 'AgencyOpen',
    unmapping_orders: 'UnmappedOpen'
  };

  init = true;

  // 更换商店
  handleStoreChange = ({ key: value }) => {
    
    const { pathname, dispatch } = this.props;

    var arr = ['/order/archived', '/order/list', '/order/agency/list', '/order/unmapped/list', '/order/tmall/list']

    if(arr.includes(pathname)){
      dispatch({
        type: 'topBtn/notificationUnreadLength',
        payload:{
          storeId:value,
          callback: d => {
            if (![2000, 2010].includes(d.code)) {
              return;
            }
            let totalData = {}
          Object.entries(d.data).forEach(item => {
            const [key, count] = item;
            const menuName = this.badgeIndex[key];
            const dom = document.getElementById(`leftSideMenu${menuName}Badge`);

            localStorage.setItem(`${menuName}Badge`, count);

            totalData[menuName] = count

            if (!dom) {
              return;
            }

            if (count > 0) {
              dom.style.display = 'inline-block';
              dom.innerHTML = count;
            } else {
              dom.style.display = 'none';
              dom.removeAttribute('style');
            }
          });

          this.props.dispatch({
            type:'global/total',
            payload:{
              data:totalData
            }
          })
          }
        }
      })
    }

    if (allowAllOption.indexOf(pathname) > -1) {
      this.selectedAll = value === 'all';
    }

    this.updateStoreAtState(value);
  };

  render() {
    const { isSync } = this.state;
    const {
      userInfo,
      pathname,
      storeId,
      storeIdWithOrder,
      selectStoreIsDisabled,
      history
    } = this.props;



    if (!(pathname && allowPage.indexOf(pathname) > -1)) {
      return null;
    }

    let currentStoreId =
      allowAllOption.indexOf(pathname) > -1 ? storeIdWithOrder : storeId;


    const renderText = () => {

      if(window.location.search && this.state.isChangeStore){
        let storeIdVal = getQueryUrlParams(window.location.href, 'storeId')
        if(storeIdVal) {
          currentStoreId = storeIdVal
        }
        // currentStoreId = storeIdVal
      }

      const res = userInfo.stores.filter(i => i?.id === currentStoreId);
      console.log('currentStoreId 计算的', res)
      if (currentStoreId === 'all') {
        return <span>{intl.get('top.top_select_store')}</span>;
      }

      if (res.length) {
        return <span>{res[0]?.shop_name}</span>;
      }
    };

    const disabledSelect = !(
      pathname && enableStoreSelect.indexOf(pathname) > -1
    );

    const storeMenu = (
      <Menu onClick={this.handleStoreChange} className={styles.menu}>
        <Menu.Item
          value="all"
          key="all"
          disabled={allowAllOption.indexOf(pathname) === -1}
        >
          {intl.get('top.top_select_store')}
        </Menu.Item>
        {userInfo.stores.map(store => {
          const target = this.stores.find(i => i?.id === store?.id) || {};
          return store?.status !== 0 ? (
            <Menu.Item key={store?.id}>
              <span
                style={{ color: target.update ? 'red' : '' }}
                className={className({
                  [styles.updateStore]: target.update
                })}
              >
                {store?.shop_name}
              </span>
              &nbsp;
              {target.update ? (
                <Tooltip
                  className={styles.tooltip}
                  title={
                    <div>
                      &nbsp;
                      {intl.getHTML('top_stores.store_authorized_tip')}
                      &nbsp;
                    </div>
                  }
                >
                  <Icon
                    style={{ color: 'red', position: 'relative', top: '0px' }}
                    type="question-circle"
                  />
                </Tooltip>
              ) : null}
            </Menu.Item>
          ) : (
            ''
          );
        })}
      </Menu>
    );

    return (
      <div className={styles.headerCon}>
        <TopBtn
          setIsSync={this.setIsSync}
          storeLength={(userInfo.stores && userInfo.stores.length) || 0}
        />
        {userInfo && userInfo.stores ? (
          <div
            className={styles.storeSelect}
            style={{ cursor: disabledSelect ? 'not-allowed' : 'pointer' }}
          >
            {disabledSelect ? (
              <Dropdown
                overlay={topStore}
                disabled
                getPopupContainer={triggerNode => triggerNode.parentNode}
              >
                <span>
                  <i class="material-icons notranslate">storefront</i>
                  <span className={styles.topText}>
                    {intl.get('top.top_select_store')}
                  </span>
                  <i class="material-icons notranslate">arrow_drop_down</i>
                </span>
              </Dropdown>
            ) : (
              <Dropdown
                // disabled={disabledSelect || selectStoreIsDisabled}
                disabled={disabledSelect}
                overlay={storeMenu}
                getPopupContainer={triggerNode => triggerNode.parentNode}
                trigger={this.props.sider && ['click']}
              >
                <span className={styles.topTextX}>
                  <i class="material-icons notranslate">storefront</i>
                  <span className={styles.topTextY}>
                    {/* {currentStoreId} */}
                    {renderText()}
                  </span>
                  <i class="material-icons notranslate">arrow_drop_down</i>
                </span>
              </Dropdown>
              //   <Select
              //     placeholder="Select Store"
              //     style={{ width: 180 }}
              //     value={currentStoreId}
              //     onChange={this.handleStoreChange}
              //     disabled={disabledSelect || selectStoreIsDisabled}
              //     dropdownClassName={styles.storeDropdown}
              //     key="select2"
              //   >
              //     <Select.Option
              //       value="all"
              //       key="all"
              //       disabled={allowAllOption.indexOf(pathname) === -1}
              //     >
              //       {intl.get('top.top_select_store')}
              //     </Select.Option>
              //     {userInfo.stores.map(store => {
              //       const target = this.stores.find(i => i.id === store.id) || {};
              //       return store.status !== 0 ? (
              //         <Select.Option value={store.id} key={store.id}>
              //           <span
              //             className={className({
              //               [styles.updateStore]: target.update
              //             })}
              //           >
              //             {store.shop_name}
              //           </span>
              //           &nbsp;
              //           {target.update ? (
              //             <Tooltip
              //               title={
              //                 <div>
              //                   &nbsp;
              //                   {intl.getHTML('top_stores.store_authorized_tip')}
              //                   &nbsp;
              //                 </div>
              //               }
              //             >
              //               <Icon type="question-circle" />
              //             </Tooltip>
              //           ) : null}
              //         </Select.Option>
              //       ) : (
              //         ''
              //       );
              //     })}
              //   </Select>
            )}
            {isSync ? (
              <Tooltip title="Synchronizing store..." placement="bottom">
                <Icon
                  type="loading-3-quarters"
                  spin
                  style={{ fontSize: 20, color: '#fe8f24', marginLeft: 24 }}
                />
              </Tooltip>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

export default connect(({ login, global }) => ({
  userInfo: login.userInfo,
  storeId: global.storeId,
  storeIdWithOrder: global.storeIdWithOrder
}))(React.memo(ExtraHeader));
