import React, { useEffect, useState } from 'react';
import { Modal, Button, Radio, Tooltip,Icon } from 'antd';
import moment from 'moment/moment';
import styles from './MigrateButton.less';
import useRequest from './useRequest';
import { getBannerStatus, setUsersPlatform, previewPopup } from './service';
import backPng from './images/back.png';
import rocketPng from './images/rocket.png';
import { isEmpty } from 'lodash';
import intl from 'react-intl-universal';
const lang = localStorage.getItem('lang');

moment.locale('en');

if (lang === 'fr-FR') {
  moment.locale('fr');
} else if (lang === 'pt-PT') {
  moment.locale('pt');
}else if (lang === 'de-DE') {
  moment.locale('de');
}else if (lang === 'es-ES') {
  moment.locale('es');
}else if (lang === 'it-IT') {
  moment.locale('it');
}

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
  color: 'rgba(0,0,0,0.88)'
};

export default function MigrateButton() {
  const [visible, setVisible] = useState(false);
  const [data, init] = useRequest(getBannerStatus, result => {
    if (result?.beforePopup?.noticePopup || result?.beforePopup?.settingPopup) {
      setVisible(true);
    }
  });
  function handleOpen() {
    init();
    setVisible(true);
  }
  function handleClose() {
    setVisible(false);

  }
  return (
    <div className={styles.messengerOuter}>
      {
        data?.startTime && (Number(data?.startTime) - 3600 * 24 * 14)  < moment.utc().unix() && !isEmpty(data?.beforePopup?.platforms) &&
        <div className={styles.messengerBtn} onClick={handleOpen} />
      }

      {isEmpty(data?.beforePopup?.platforms) && visible && (
        <Content visible={visible} handleClose={handleClose} startTime={data?.startTime}/>
      )}

      {!isEmpty(data?.beforePopup?.platforms) && visible && (
        <ProContent
          settingPopup={data?.beforePopup?.settingPopup}
          visible={visible}
          handleClose={handleClose}
          startTime={data?.startTime}
          defaultValue={data?.beforePopup?.userSetPlatform}
          platforms={data?.beforePopup?.platforms || []}
        />
      )
      }
    </div>
  );
}
function Content({ visible, handleClose,startTime,noticePopup}) {
  useEffect(() => {
    previewPopup();
  }, []);
  return (
    <Modal
      destroyOnClose
      keyboard={false}
      centered
      onCancel={handleClose}
      closable={false}
      maskClosable={false}
      width={560}
      visible={visible}
      bodyStyle={{
        paddingTop: 95,
        backgroundImage: `url(${backPng})`,
        backgroundRepeat: 'no-repeat'
      }}
      footer={(
        <div style={{ textAlign: 'center' }}>
          <Button key='submit' type='primary' onClick={handleClose}>
            CONFIRM
          </Button>
        </div>
      )}
    >
      <img src={rocketPng} alt='' style={{
        position:'absolute',
        top:-76,
        left:-45
      }}/>
      <div className={styles.title} style={{marginBottom:18}}>
        {intl.get('beforeMigration.title')}
      </div>
      <div className={styles.text} style={{textAlign:'justify'}}>
        {intl.get('beforeMigration.four')}
        <span className={styles.yellow}> {intl.get('beforeMigration.five')} {moment.unix(Number(startTime)).utc().format('LL')}(UTC+0)</span>.
      </div>
      <div className={styles.text} style={{marginBottom:10}}>
        {intl.get('beforeMigration.six')}
      </div>
      <div className={styles.text}>
      {intl.get('beforeMigration.seven')}
        <a className={styles.link}
           target='_blank'
           href="https://www.dsers.com/blog/dsers-new-platform-migration/"> {intl.get('beforeMigration.seven1')}
        </a>{' '}
        {intl.get('beforeMigration.nine')}
      </div>
    </Modal>
  );
}


function ProContent({
                      visible,
                      handleClose,
                      platforms = [],
                      defaultValue = undefined,
                      startTime,
                      settingPopup
                    }) {

  const [settingsValue, setSettingsValue] = useState(undefined);
  const [disabled, setDisabled] = useState(true);
  useEffect(()=>{
    if(defaultValue){
      setSettingsValue(defaultValue);

    }
  },[defaultValue])
  const PLATFORMS = [
    {
      label: intl.get('beforeMigration.shopify'),
      value: 'SELLER_TYPE_SHOPIFY'
    },
    {
      label: intl.get('beforeMigration.wooCommerce'),
      value: 'SELLER_TYPE_WOO'
    },
    {
      label: intl.get('beforeMigration.wix'),
      value: 'SELLER_TYPE_WIX'
    }
  ];
  console.log(PLATFORMS,'PLATFORMS');
  function handleSettings(e) {
    setDisabled(false);
    setSettingsValue(e.target.value);
  }
  const title = (
    <div>
      <div style={{marginBottom:10}}>
        {intl.get('beforeMigration.fourteen')}
      </div>
      <div>
        - {intl.get('beforeMigration.fifteen')} <br />
        - {intl.get('beforeMigration.sixteen')} <br />
        - {intl.get('beforeMigration.seventeen')} <br />
        - {intl.get('beforeMigration.eighteen')} <br />
        - {intl.get('beforeMigration.nineteen')}{' '} <br />
        - {intl.get('beforeMigration.twenty')} <br />
        - {intl.get('beforeMigration.twentyOne')}. <br />
        {intl.get('beforeMigration.twentyTwo')}.
        <br />
      </div>
      <div style={{marginBottom:10,marginTop:10}}>
        {intl.get('beforeMigration.twentyThere')}
      </div>
      <div>
        - {intl.get('beforeMigration.twentyFour')} <br />
        - {intl.get('beforeMigration.twentyFive')} <br />
        - {intl.get('beforeMigration.twentySix')} <br />
        {intl.get('beforeMigration.twentyTwo')}.
        <br />
      </div>
    </div>
  );
  function handleSubmit() {
    setUsersPlatform({
      sellerType: settingsValue
    }).then((result) => {
      if (!result?.code) {
        handleClose();
      }
      return undefined;
    });
  }

  console.log(platforms,'platforms');
  return (
    <Modal
      keyboard={false}
      destroyOnClose
      title={(
        <div style={{ textAlign: 'center' }}>
          {intl.get('beforeMigration.title1')}
        </div>
      )}
      centered
      onCancel={handleClose}
      closable={!settingPopup}
      maskClosable={false}
      width={560}
      visible={visible}
      bodyStyle={{
        padding: 0
      }}
      footer={[
        <Button
          key='submit'
          type='primary'
          onClick={handleSubmit}
          disabled={disabled}
        >
          {intl.get('beforeMigration.submit')}
        </Button>
      ]}
    >
      <div className={styles.indentation}>
        <div className={styles.text}>
          <p style={{fontFamily:'OpenSans-SemiBold, OpenSans',marginBottom:10}}>
            {intl.get('beforeMigration.ten')}
            <span className={styles.yellow}> {intl.get('beforeMigration.five')} {moment.unix(Number(startTime)).utc().format('LL')}(UTC+0)</span>.
          </p>
          <p style={{fontFamily:'OpenSans-SemiBold, OpenSans'}}>
            {intl.get('beforeMigration.eleven')}
          </p>
          <p style={{textAlign:'justify'}}>
            {intl.get('beforeMigration.twelve')}
          </p>
        </div>
      </div>
      <div className={styles.selectItem}>
        <div className={styles.subTitle}>
          {intl.get('beforeMigration.thirteen')}
          <Tooltip title={title} overlayClassName={styles.tooltipWrap}>
            <Icon
              style={{ padding: 2, fontSize: '16px', cursor: 'pointer' }}
              type='question-circle'
            />
          </Tooltip>
        </div>
        <div>
          <Radio.Group defaultValue={settingsValue} value={settingsValue} onChange={handleSettings}>
            {PLATFORMS.filter(item => platforms.includes(item.value)).map(
              item => (
                <Radio style={radioStyle} value={item.value}>
                  {item.label}
                </Radio>
              )
            )}
          </Radio.Group>
        </div>
      </div>
      <div className={styles.indentation}>
        <div className={styles.text} style={{marginTop:10}}>
        {intl.get('beforeMigration.seven')}
          <a className={styles.link} target='_blank' href='https://www.dsers.com/blog/dsers-new-platform-migration/'>
            {intl.get('beforeMigration.seven1')}
          </a>
          {intl.get('beforeMigration.eight')}
        </div>
      </div>
    </Modal>
  );
}
