import { AGENCY_LOCAL_STORE_EXPORT_TASK_KEY, AGENCY_LOCAL_STORE_UPLOAD_TASK_KEY } from 'routes/AgencyOrder/component/constants';

/**
 * 是否安装了chrome插件
 */

export const isInstallChromePlug = () => {
  if(!window.CHROME_PLUG_INSTALL){
    window.open(`https://chrome.google.com/webstore/detail/dsers-aliexpresscom-produ/mmanaflgaempokjfbeeabkadnkoidjam?hl=en-US`)
  }
}


/**
 * 动态计算order外层staus状态
 * @param {*} listData 订单状态
 * @param {*} status 订单key状态
 * @returns {number} status 外层状态
 */
export const computerOrderStatus = (listData, status) => {
  let outerStatus = 0;
  listData?.childrens?.map(childrenItem => {
    childrenItem?.sp?.map(spItem => {
      if(spItem?.status == status){
      }else{
        outerStatus = childrenItem.status
      }
    })
  })
  return outerStatus
}

/**
 * 计算Url中带#号的参数值
 * @params url
 * @params key 获取的key
 */
export const getQueryUrlParams = (url, key) => {
  let obj = {}
  if(/\?(.*)/g.test(url)){
    let value = RegExp.$1;
    let str = value.split('&')
    str.forEach(item => {
      let ar = item.split('=');
      obj[ar[0]] = ar[1]
    })
  }
  return  obj[key] || localStorage.getItem('pushCheckStoreId') || localStorage.getItem('storeId');
}

export const logOut = () => {
  window.localStorage.removeItem('closeAppointmentTip');
  window.localStorage.removeItem('noMigra');
  window.localStorage.removeItem('store_is_available');
  window.localStorage.removeItem('EnterNewHomeTime');
  localStorage.removeItem('migration_stores');
  
  window.localStorage.removeItem('ds_host');
  window.localStorage.removeItem('dser-pro-authority');
  window.localStorage.removeItem('store');
  window.localStorage.removeItem('storeId');
  window.localStorage.removeItem('payment');
  window.localStorage.removeItem('keyStatus');
  window.localStorage.removeItem('pushCheckStoreId');
  window.localStorage.removeItem('newuser_saw_pricing_mean');
  window.localStorage.removeItem('saw_pricing_mean');
  window.localStorage.removeItem('keyStatus');
  sessionStorage.removeItem('newUser');
  window.localStorage.removeItem('newUserInfo');
  window.localStorage.removeItem('ifMigration');
  window.localStorage.removeItem('logoutEmail');
  window.localStorage.removeItem('authAgain');
  window.localStorage.removeItem('AeCancelTipHidden')
  if (window.location.pathname.indexOf('/app/login') > -1) {
    return;
  }

  if (window.location.pathname.indexOf('/app/') > -1) {
    window.location.href = '/'; 
  }

  window.localStorage.removeItem(AGENCY_LOCAL_STORE_EXPORT_TASK_KEY);
  window.localStorage.removeItem(AGENCY_LOCAL_STORE_UPLOAD_TASK_KEY);
}