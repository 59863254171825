
    export default 
    {
    "menu": {
        "Switch": "Cambia piattaforma",
        "blog": "BLOG",
        "facebook": "Facebook",
        "youtube": "Youtube",
        "email_support": "Supporto via email"
    }
}