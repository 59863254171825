import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { connect } from 'dva';
import styles from './AwaitMigration.less'
import logo from 'assets/svg/new_logo.png';
import setting from 'assets/migration/setting.png'
import ds from 'assets/migration/ds.png'
import shopify from 'assets/migration/shopify.png'
import message from 'assets/migration/Message.png'
import { Button, Dropdown, Menu } from 'antd';
import SelectLanguage from 'components/GlobalHeader/SelectLanguage';
import intl from 'react-intl-universal'

function AwaitMigration({userInfo, migrationInfo}) {

    const dispatch = useDispatch()

    const menu = (
        <Menu>
            <Menu.Item onClick={() => {
                dispatch({
                    type: 'login/logout'
                });
            }}>
                <div>Log Out</div>
            </Menu.Item>
        </Menu>
    )





    return (
        <div className={styles.container}>
            <div className={styles.top}>
                <img src={logo} alt="" />
                <div className={styles.user}>
                    <div style={{ lineHeight: '64px' }}><SelectLanguage /></div>
                    <div></div>
                    <div><img src={shopify} alt="" />{migrationInfo?.migrating_shopify_domain}</div>
                    <div></div>
                    <div className={styles.dropimg}><img src={ds} alt="" /></div>
                    <div><Dropdown overlay={menu} trigger={['hover']}>
                        <span className={styles.drop}>
                            <span>{userInfo.email}</span>
                            <i style={{ position: 'relative', top: '2px', marginLeft: '10px' }} className="material-icons">arrow_drop_down</i>
                        </span>
                    </Dropdown></div>
                </div>
            </div>
            <div className={styles.middle}>
                <div className={styles.welcome}>{intl.get('migration.childAccountawait')}</div>
                <div className={styles.text}>{intl.get('migration.childAccountawaittext')}</div>
                <div className={styles.mid_icon}><div className={styles.mid_obl}><div style={{ width: '66px', height: '66px', border: '24px solid #2F01FD', borderRadius: '33px' }}></div></div><i class="material-icons" style={{ color: "#7216DD", fontSize: '44px' }}>chevron_right</i><i class="material-icons" style={{ color: "#AE497F", fontSize: '44px' }}>chevron_right</i><i class="material-icons" style={{ color: "#FF8F00", fontSize: '44px' }}>chevron_right</i><div className={styles.mid_dsers}><img src="https://img.dsers.com/images/logo.png" alt="" /></div></div>
            </div>
            <a className={styles.bottom} style={{ marginTop: '48px' }} target="_blank" href="https://help.dsers.com/oberlo-migration/">{intl.get('migration.blog')}</a>
            <div className={styles.end}>
                <div><img src={message} alt="" /><a href='https://www.messenger.com/t/DSersOfficial' target='_blank'>{intl.get('migration.contact_1')}</a></div>
                <div></div>
                <i className="material-icons">local_post_office</i><a href='mailto: support@dserspro.com'>{intl.get('migration.contact_2')}</a>
            </div>
        </div>
    )
}

export default connect(({ login, auth }) => ({
    userInfo: login.userInfo,
    migrationInfo: auth.migrationInfo
}))(AwaitMigration)