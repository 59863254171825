import React from 'react';

import img from '../../assets/img/icon-business-partnership.png';

import styles from './index.less';

function SuccessFeedback({ children }) {
  return (
    <div className={styles.feedback}>
      <img src={img} alt="icon" />
      <div>{children}</div>
    </div>
  );
}

export default SuccessFeedback;
