export default {
  public: {
    cancel: 'Cancelar',
    yes: 'Si',
    no: 'No',
    ok: 'OK',
    save: 'Guardar',
    submit: 'Enviar',
    search: 'Buscar',
    confirm: 'Confirmar',
    newconfirm: 'CONFIRM',
    apply: 'Aplicar',
    log_out: 'Cerrar sesión',
    reset_password: 'Restablecer contraseña',
    delete: 'Borrar',
    got_it: 'Ya lo tengo',
    check_more: 'Buscar más',
    more: 'Más',
    page: 'página',
    access: 'Acceso',
    refresh: 'Actualizar',
    reconnect: 'Conectar de nuevo',
    on: 'Activado',
    off: 'Desactivado',
    here: 'aquí',
    Manage: 'Gestionar suscripción',
    check_it: 'Verificarlo',
    dismiss: 'Descartar',
    contact: 'Contacto',
    contact_us: 'Contáctanos',
    facebook: 'Facebook',
    youtube: 'YouTube',
    newsave: 'SAVE',
    updata_price:
      'Actualizar el precio de los productos empujado a tu tienda de Shopify de acuerdo con las reglas de fijación de precios que actualmente hayas establecido. El proceso puede llevar algún tiempo. Esta función se puede utilizar cada 30 días .',
    please_enter: 'Please enter CONFIRMAR to start the update',
    shopUnavailable:
      'This store is currently disconnected. Please reconnect it to use this feature',
    InterfaceTransfinite:
      'Actualizar el precio de los productos empujado a tu tienda de Shopify de acuerdo con las reglas de fijación de precios que actualmente hayas establecido. El proceso puede llevar algún tiempo. Esta función se puede utilizar cada 30 días . Due to interface restrictions, the feature will be opened after 24:00, please wait patiently',
    synchronous: "The store's product prices are being updated",
    useAgain: 'Can be used again in',
    day: 'days',
    pause:
      'Synchronization of the day complete,\n    The remaining products will be automatically synced tomorrow',
    goodsTooMuch:
      'Actualizar el precio de los productos empujado a tu tienda de Shopify de acuerdo con las reglas de fijación de precios que actualmente hayas establecido. El proceso puede llevar algún tiempo. Esta función se puede utilizar cada 30 días. Since your store has more than 100,000 items, we will synchronize all your items in multiple days (up to 100,000 per day), please be patient.',
    exceeds:
      'As your product synchronization exceeds 100,000, this function will be enabled after 24:00, please wait patiently',
    noproduct: 'The store has no product that can be updated',
    updateTitle: 'Update product price',
    updateText:
      'Actualizar el precio de los productos empujado a tu tienda de Shopify de acuerdo con las reglas de fijación de precios que actualmente hayas establecido. El proceso puede llevar algún tiempo. Esta función se puede utilizar cada 30 días.',
    start: 'Empezar a actualizar',
    unsave: 'Unsaved changes',
    checkmodaltext:
      'The information you modified has not been saved yet. If you want to save, please click "SAVE", if you don\'t want to save your modifications, click "DISCARD"',
    discard: 'DISCARD',
    choose: 'SELECT',
    gotodsers: 'Go to DSers Homepage',
    oberlo_data_pending: 'Data correction.',
    oberlo_data_success: 'Data correction completed.',
    oberlo_data_fail: 'Data verification Failed.',
    oberlo_data_try: 'INTENTAR DE NUEVO',
    priceruleDis:
      'Please activate and confirm your <a href="/app/settings?=5" > Pricing Templates </a> settings in Pricing Rule and start to update afterwards.',
    padding_title:
      'Estamos corrigiendo la información de los pedidos y productos, por favor espera un momento',
    migrate_order_tip:
      'La sincronización del estado de algún pedido ha fallado. Es posible que la cuenta AliExpress en la que se realizaron esos pedidos sea diferente de la cuenta AliExpress vinculada actualmente. Por favor, cambia la cuenta de AliExpress vinculada a DSers para actualizar el estado de los pedidos. Si tienes alguna duda, ponte en contacto con el equipo de soporte técnico de DSers.',
    oberlo_product_error_tip:
      'Verificación de datos de productos completada, pero { num } productos de AliExpress no estaban disponibles y no se pueden importar a DSers. Tendrás que asignar manualmente los nuevos proveedores. Ir a la pestaña de productos no mapeados para consultar.',
    add_report_user_tip:
      'Data is being created, the report will be generated within 24 hours. Thank you for your patience.',
    filter_confirm_des: 'CONFIRMAR',
    filter_clear_des: 'BORRAR UN FILTROS',
    unsaveErr: 'Unable to save changes',
    gocheck: 'Go check',
    checkmodalerrtext1: 'The reason you cannot save is ',
    checkmodalerrtext2:
      'To fix the issue, you need to re-map the part facing issues, or delete the mapping with problems.',
    checkmodaladvanceerrtext:
      'In Advanced Mapping, the Ship to selection cannot be repeated on the same variant, otherwise the mapping cannot be saved. ',
    checkmodalbasicrepeaterrtext:
      'In Basic Mapping, Options cannot be repeated, otherwise the mapping cannot be saved. ',
    checkmodalbasicnullerrtext:
      'In Basic mapping, you need to select the corresponding variant, otherwise the mapping cannot be saved. ',
    input_guide_text:
      'The Search or Import field, along with the Filter feature has been moved! You can now find them on the top right corner.',
    input_guide_btn: 'Got it',
    importlist_card_guide_text:
      "We have improved the product card and some features can now be found in 'More Action' in the lower right corner",
    product_card_guide_text:
      "We have improved the product card and the mapping function now has a floating display. Some features can now be found in 'More Action' in the lower right corner",
    search_input_url_error_match_ae_tip:
      'La URL que has introducido no es una URL de AliExpress, por favor, verifica y vuelve a intentar.',
    search_input_url_error_match_tm_tip:
      'La URL que has introducido no es una URL de Tmall, por favor, verifica y vuelve a intentar.',
    search_input_url_invalid_tip:'La URL que has introducido no es válida. Por favor, verifica y vuelve a intentarlo.',
  }
};
