import React, { useCallback } from 'react';
import intl from 'react-intl-universal';

import './OrderQuantityToolTip.css';

let Router = {
  1:'/app/order/unmapped/list',
  2:'/app/order/list',
  4:'/app/order/tmall/list',
  5:'/app/order/agency/list'
}

export const OrderQuantityToolTip = ({ Map, quantity, type, data }) => {

  let TextMap = {
    1:intl.get('order.orderQuantityToolTip.unmapped'),
    2:intl.get('order.orderQuantityToolTip.aliexpress'),
    4:'Tmall Orders',
    5:intl.get('order.orderQuantityToolTip.agency'),
  }

  const clickHandle = item => {
    let tabKey = 2;
    let arrMap = []
    let filterArr = data.ext.lineitems.filter(lineItem => {
      return item != 5 ? lineItem.platform_type == item && lineItem?.is_black_list !== 2 : lineItem.platform_type >= item && lineItem?.is_black_list !== 2
    })

    filterArr.forEach(lItem => {
      lItem?.sp.forEach(spItem => {
        arrMap.push(spItem?.status)
      })
    })

    if(arrMap.length > 0){
      arrMap = arrMap.sort()
    }

    if(filterArr.length > 0){
      //看当前的数组是不是包含8  内层包含不包含5
      let awaitFuifillment = filterArr.some((lineItem, index) => {
        return lineItem.status == 8 && lineItem.sp[0].status == 5
      })

      let Fulfilled = filterArr.some((lineItem, index) => {
        return lineItem.status == 2048 && lineItem.sp[0].status == 5
      })

      if(awaitFuifillment && Fulfilled){
        if(arrMap.some(item => item < 5)){
          tabKey = arrMap[0]
        }else{
          tabKey = 9
        }
      }else if(awaitFuifillment && !Fulfilled){
        //包含外层8 内层5 --- 再去看arrMap是不是有小于5的状态
        if(arrMap.some(item => item < 5)){
          tabKey = arrMap[0]
        }else{
          tabKey = 9
        }
        // 这个逻辑应该还有问题 少一种判断
      }else{
        tabKey = arrMap[0] || 2;
      }

    }
    
    window.location.href = `${Router[item]}?orderKey=${data.detail.name}&tabKey=${tabKey}`
  }

  return <div className='OrderQuantityToolTipBox'>
    <p>{intl.get('order.orderQuantityToolTip.title',{
      items: quantity
    })}</p>
    <div className='OrderQuantityToolTipQuantity'>
      {
        Object.keys(TextMap).map(item => {
          if(item == type){
            return null;
          }
          if(!Map[item]){
            return null;
          }
          return <p style={{cursor: 'pointer'}} onClick={() => { clickHandle(item) }}>{TextMap[item]}: {Map[item]}</p>
        })
      }
    </div>
  </div>
}