
    export default 
    {
    "mapping": {
        "title": "Gestione dei fornitori",
        "description": "I dettagli del prodotto da Shopify (come titolo, descrizione, immagini, varianti, ecc.) rimangono gli stessi dopo l'aggiunta di un fornitore.",
        "description_tip": "Nota bene: la mappatura sarà persa quando modifichi/elimini/aggiungi opzioni o varianti su Shopify.",
        "tab_btn_basic_mapping": "Mappatura di base",
        "tab_btn_advanced_mapping": "Mappatura avanzata",
        "tab_btn_bogo": "BOGO",
        "tab_btn_bundle": "Bundle",
        "tab_btn_verified": "Verificato",
        "shopify_product": "Prodotto Shopify",
        "default_ali_product": "Prodotto {platform} Predefinito",
        "edit_shipping": "Modifica Spedizione",
        "set_shipping": "Imposta Spedizione",
        "import_a_product": "Importa un Prodotto",
        "substitute_ali_product": "Sostituisci Prodotto {platform}",
        "second_product_tip": "La seconda opzione è una possibilità da utilizzare durante l'elaborazione automatica dell'ordine quando alcune varianti non sono disponibili per l'opzione predefinita.",
        "alternate_products": "Prodotti alternativi",
        "alternate_products1": "Add Products",
        "import_placeholder": "Inserisci l'url del prodotto aliexpress",
        "status": "Stato",
        "turn_status": "Attiva per attivare il metodo di {method} mappatura",
        "close_all_mapping": "Vuoi disattivare tutto il metodo di mappatura? Questo farà sì che il tuo prodotto non possa essere processato su AliExpress tramite DSers",
        "open_new_mapping": "Vuoi passare a {switch} Mapping? I tuoi ordini saranno elaborati secondo il metodo {switch} Mapping",
        "variant": "Variante",
        "ship_to": "Spedire a",
        "supplier_variant": "Fornitore e Variante",
        "import_sp_select_var": "Importazione fornitore & Selezione variante",
        "import_ae_title": "Importa un fornitore incollando un link AliExpress qui",
        "import_tm_title": "Importa un rivenditore incollando un link Tmall qui",
        "clear": "Cancella",
        "select_a_variant": "Seleziona una variante",
        "import_ae_first": "Importa prima un fornitore Aliexpress",
        "import_tm_first": "Importa prima un rivenditore Tmall",
        "submit": "Invia",
        "advanced_rule": "Imposta i fornitori secondo i paesi di destinazione del tuo ordine. Puoi impostare al massimo 10 fornitori per una variante.",
        "supplier_product": "Fornitore Prodotto",
        "more_action": "Più azione",
        "clear_all": "Cancella tutto",
        "advanced_more_action_copy": "Copia i paesi e i fornitori corrispondenti alle altre varianti dopo aver mappato la prima. Hai ancora bisogno di selezionare gli SKU corretti per le altre varianti",
        "buy": "ACQUISTA",
        "buy_desc": "Mappa un fornitore per il prodotto principale che stai vendendo",
        "buy_more_action_copy": "Copia lo SKU ad altre varianti dopo aver mappato il primo. Puoi modificare se hai bisogno di uno diverso.",
        "x": "X",
        "x_desc": "Quantità per il prodotto principale",
        "change_qty_to": "Cambia la quantità in",
        "get": "OTTIENI",
        "get_desc": "Mappa un fornitore per il prodotto regalo che stai offrendo",
        "get_more_action_copy": "Copia lo SKU su altre varianti GIFT dopo aver mappato la prima. Puoi modificare se hai bisogno di uno diverso.",
        "y": "Y",
        "y_desc": "Quantità per <br /> il prodotto regalo",
        "bundle_products": "Prodotti in bundle",
        "bundle_more_action_copy": "Copia lo SKU in altre varianti dopo aver mappato <br /> la prima. Puoi modificarlo se <br /> te ne serve uno diverso.",
        "import_btn_tip": "<span>Puoi impostare la mappatura in questo modo: <br /> 1. Incolla un link AliExpress qui. <br /> 2. Clicca su Importa <br />3. Imposta il prodotto come predefinito/seconda opzione <br />4. Compila TUTTE le opzioni e i valori per completare la mappatura <a href=\"https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/\" target=\"_blank\">Guarda questo articolo</a>.</span>",
        "import": "Importa",
        "set": "Imposta",
        "no_record_yet": "Ancora nessun dato",
        "ShopifyProduct": "Prodotto Shopify",
        "verified": {
            "title": "Informazioni sul prodotto",
            "cost": "Costo",
            "shipping": "Spedizione",
            "ePacket": "ePacket",
            "package_request": {
                "title": "Richiesta del pacchetto",
                "option1": "Borse (predefinito)",
                "option2": "Scatola ($0.2／Pacchetto)",
                "option3": "Scatola con logo($0.2／Pacchetto)",
                "option4": "Scatola con carta($0.2／Pacchetto)",
                "option5": "Scatola con logo e carta($0.2／Pacchetto)"
            },
            "upload_logo_file": "Carica il file del logo",
            "leave_a_meaage": "Lascia un messaggio",
            "go_to_setting": "Vai su Impostazione > Fornitore per impostare l'intero negozio",
            "sample_on_way": "Il campione è in arrivo.",
            "tracking_no": "N° Tracking",
            "request_sample": "Richiedi un campione",
            "save": "Salva",
            "disable": "Disabilita",
            "enable": "Abilita",
            "cancel": "Annulla"
        },
        "publish_first": "Prima pubblica e poi aggiungi i fornitori",
        "upload_img_tip": "L'immagine deve essere più piccola di 1MB!",
        "verified_save": "Salvato con successo.",
        "copy_link_tip": "La replica non riesce, le merci devono essere inviate prima",
        "copy_succ": "Copia riuscita",
        "copy_fail": "copia fallita",
        "delete_default_option_tip": "Vuoi eliminare l'opzione predefinita? Puoi aggiungerla di nuovo nel record?",
        "delete_second_option_tip": "Vuoi eliminare la seconda opzione? Puoi aggiungerla di nuovo nel record?",
        "delete_history_tip": "Vuoi rimuovere queste informazioni dal registro dei prodotti?",
        "yes": "Sì",
        "no": "No",
        "ok": "Ok",
        "cancel": "Annulla",
        "save_option_confirm": {
            "set_shipping_tip": "Configura il metodo di spedizione per il prodotto sostitutivo.",
            "save_option_two_tip": "Fai attenzione che l'attivazione del prodotto sostitutivo potrebbe causare spese di spedizione extra se hai due fornitori diversi che forniscono un ordine."
        },
        "clear_all_shipping": "Vuoi cancellare tutte le informazioni di configurazione?",
        "save_shippinp_succ": "Salva Successo.",
        "setting_activated": "Impostazione Attivata",
        "country_shipping": {
            "shipping_setting": "Impostazione di Spedizione",
            "ship_to": "Spedisci a",
            "clear_all": "Cancella Tutto",
            "search": "Cerca",
            "shipping_country": "Compagnia di Spedizione",
            "shipping_cost": "Costo di Spedizione",
            "time": "Tempo di Consegna Stimato",
            "tracking_information": "Informazioni sul Tracking",
            "free_shipping": "Spedizione Gratuita",
            "available": "Disponibile",
            "unavailable": "Non disponibile",
            "not_ship_this_country": "Questo prodotto non può essere spedito in questo paese.",
            "select_ship_country": "Seleziona il paese in cui vuoi spedire."
        },
        "product_whic": "Vuoi che questo prodotto sia:",
        "default_option": "Opzione predefinita",
        "second_option": "Seconda opzione",
        "mapped": "Mappato",
        "default": "Predefinito",
        "succeed": "Successo",
        "repeat_option": "Non puoi salvarlo a causa della ripetizione delle opzioni della variante",
        "select_variant": "Seleziona le varianti corrispondenti. Altrimenti non puoi salvare la mappatura",
        "delete_pool_product": "Se elimini questo fornitore dalla tua pagina di mappatura, la mappatura del tuo prodotto verrà cancellata",
        "delete_mapping_product": "Vuoi eliminare l'opzione { num, plural, =0 { default } other {second} } opzione? Puoi aggiungerla di nuovo più tardi dal record",
        "delete_del_variant_tip": "Are you sure you want to delete the variant(s) on DSers? Once deleted, orders for the variant(s) can no longer be processed.",
        "same_country_tip": "Non puoi selezionare nuovamente questo paese per questa variante",
        "same_sku_tip": "Se vuoi impostare lo stesso prodotto sia per il prodotto principale che per il prodotto regalo, modifica la quantità per i prodotti principali invece di importarla di nuovo per la colonna regalo.",
        "import_same_sku_tip": "Non puoi importare lo stesso SKU due volte per una variante",
        "Variant_delete_tip_one": "Hai eliminato la variante(i) qui sotto su Shopify. Per favore:",
        "Variant_delete_tip_two": "1. Rifai la mappatura per continuare a sincronizzare lo stock da AliExpress.",
        "Variant_delete_tip_three": "2. Aggiungi il fornitore alla variante(i) sottostante(i) per elaborare gli ordini per la variante(i) cancellata(e).",
        "overrun_warning": "Hai superato il limite giornaliero per accedere alle informazioni sul prodotto",
        "standProductNum": "Up to 5 AliExpress products can be imported",
        "repeat_alert": "This supplier is already set as default or substitute supplier and cannot be set again",
        clear_tip_alert: `Se imposti l'articolo come rivenditore primario, il rivenditore secondario sarà rimosso.`,
        forbidden_alert: `La mappatura base può essere usata unicamente per mappare con il rivenditore da una piattaforma rivenditore.`,
        "switch_mapping": "Switch between Default and Substitute supplier",
        "bundle_rule": "Set up a supplier for the product in your Bundle. You can set up to 10 suppliers for a variant",
        "getProduct_error": "The product you entered was not found",
        "standard": {
            "product_name": "Prodotto Shopify",
            "product_min_name": "Supplier Product",
            "product_title": "Set up a supplier for the product in your Standard Mapping",
            "remove_con": "Clicking 'CONFIRM' will remove this supplier form the list of available supplier for this product and remove the existing mapping of other SKUs using it. Do you want to confirm?",
            "remove_title": "Remove Supplier",
            "remove_confirm": "CONFIRM"
        },
        "replace_product": {
            "btn_title": "SOSTITUISCI PRODOTTO",
            "modal_tip": "Les variantes du produit Shopify seront supprimées et remplacées par les variantes d'un nouveau produit. Vous pouvez modifier les détails du produit à l'étape suivante.",
            "shopify": "Prodotto Shopify",
            "ali_express": "AliExpress product",
            "tm_express": "Tmall product",
            "price_rule": "Apply Pricing Rule",
            "cancle": "CANCEL",
            "url_error": "Please input valid AliExpress product URL.",
            "keep_description": "Keep the original Shopify description",
            "update_description": "Update description",
            "keep_image": "Keep the original Shopify images",
            "new_image": "Choose new images",
            "suucess": "Successfully replaced",
            "failed": "Failed to replace",
            replace_placeholder:`Incolla l'URL prodotto qui`,
        },
        "gift_product": "Regala prodotto",
        "main_product": "Prodotto principale",
        "sku_lose_tip": "The mapping was lost because your supplier changed the SKU. Please re-map your products according to the latest SKU on AliExpress or change supplier.",
        "replace_product_tip": "If you have any open orders with the old product that haven't been placed yet, please <a href='https://help.dsers.com/basic-mapping-advanced-mapping-bogo-bundle-introduction-for-shopify/' target='_blank'>map</a> or <a href='https://help.dsers.com/re-order-awaiting-payment-orders-for-shopify/#modify-canceled-order' target='_blank'>edit</a> the order with selected new variants that matches the desired product, then process orders {toAliExpress}. You can click REPLACE PRODUCT after.",
        "confirm_delete_all_variant_title": "Delete Variant(s)",
        "confirm_delete_all_variant": "Are you sure you want to delete the variant(s) on DSers? Once deleted, orders for the variant(s) can no longer be processed.",
        "delete_variant_collapse_tip": `Hai eliminato la/le variante/i sotto su Shopify. Sei pregato di:
        <br />Aggiungere un rivenditore alla/alle variante/i sotto per elaborare gli ordini per la/le variante/i eliminate/i.`,
        delete_variant_collapse_supplier_tip:`Hai eliminato la/le variante/i sotto su Shopify. Sei pregato di:
        <br />1. Rifare la mappatura per continuare a sincronizzare l'inventario da rivenditore.
        <br />2. Aggiungere un rivenditore alla/alle variante/i sotto per elaborare gli ordini per la/le variante/i eliminate/i.`,
        "delete_options_title": "Delete Option",
        "delete_supplier_title": "Delete Supplier",
        "open_new_mapping_title": "Switch Mapping",
        "close_all_mapping_title": "Deactivate Mapping",
        "delete_supplier_description": "If you delete this supplier from your mapping page, the mapping of your product will be deleted.",
        offline_error_tip:'Il prodotto è offline',
        mapping_info_error_tip:'I dati del prodotto mappati non sono corretti, si prega di rimapparli.',
        Findsimilar:'Trova simili',
        distoryImgTip:`L'immagine del prodotto attuale non può essere riconosciuta e la funzione Supplier Optimizer non può essere utilizzata per il momento. Ci scusiamo per l'inconveniente causato.`
    }
}