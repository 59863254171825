import React, { PureComponent, useEffect, useState } from 'react';
import { GuildeAction } from 'actions';
// 首屏弹窗
import OBDialog from './OB_Dialog';
// 底部banner指引
import OBanber from './OB_Banner';
// 底部card 指引
import OBCard from './OB_Card';
// 弹窗 pop 指引
import OBPop from './OB_Pop';
// 跳转订阅前弹窗
import CommonGuide from './Common_Pop';
import { getChromeExtUrl } from 'utils/utils';
import {
  getOberloGuidePop,
  setOberloGuidePop,
  getTurnPlan
} from '../../services/oberloGuilde';
import { connect } from 'dva';
import { getOberloReport,CommonDataCollect } from 'utils/utils'
import { message } from 'antd'
import { captureMessage, init } from '@sentry/react';

const logException = (res, identify) => {
  if (res?.msg) {
    captureMessage(`[pricing] ${res.msg}`);
  } else {
    captureMessage(`[pricing] ${identify}`);
  }
};
const OblerloGuide = ({ plan, isChildAcount, isOberlo, dispatch,confirmPlan,userInfo,OstoreInfo}) => {
  const [changeDialog, setChangeDialog] = useState(false);
  const [changeBanner, setBannerSate] = useState('');
  const [changeCard, setChangeCard] = useState('');
  const [changePop, setChangePop] = useState('');
  const [changePlanPop, setChangePlanPop] = useState('');
  const [isBilling, setIsBilling] = useState('withOut');  //billing是否可用
  const [onceBindAE, setOnceBindAE] = useState(false);     //是否绑定过AE
  const [onceDownloadChrome, setOnceDownChrome] = useState(false); //是否下载过插件

  /**
   * 针对 除了Oblerlo用户 （跳转订阅弹窗优化）
   */
  useEffect(() => {
    if (!isOberlo) {
      window.EVENT.on(GuildeAction.TurnPlan_Pop, params => {
        setTimeout(() => {
          // if (!Object.keys(window.EVENT.list).includes('OblerloGuide_Pop')) {
            setChangePlanPop(params);
          // }
        },100) 
      });
    }
  }, [isOberlo]);

  /**
   * 针对所有用户 观察判断
   */
  useEffect(() => {
    window.EVENT.on(GuildeAction.OblerloGuide_Banner, params => {
      setBannerSate(params);
    });
    window.EVENT.on(GuildeAction.OblerloGuide_Card, params => {
      setChangeCard(params);
    });
    window.EVENT.on(GuildeAction.OblerloGuide_Pop, params => {
      setChangePop(params);
    });
  }, ['']);

  /**
   * 针对Oberlo用户 子账户做判断处理
   */
  useEffect(() => {
    const setMigrationInfo = localStorage.getItem('setMigrationInfo')
    if ([null, undefined].includes(isChildAcount) || !isOberlo) {
      return;
    }
    isUsingBilling();
    if (Object.keys(window.EVENT.list).includes(GuildeAction.TurnPlan_Pop)) {
      window.EVENT.off(GuildeAction.TurnPlan_Pop)
    }
    window.EVENT.on(GuildeAction.TurnPlan_Pop, params => {
       //  未确认套餐
        if (isChildAcount === 1) {
           getTurnPlan().then(res => {
             if(res.data?.compare){
              window.EVENT.emit(
                GuildeAction.OblerloGuide_Pop,
                `PLAN-${Date.now()}`,
                true
              );
             }else{
              setChangePlanPop(params);
             }
           });
        } else {
            setChangePlanPop(params);
        }
    });
    // 后加的判断 迁移过程中接口会报错！！！！  哎~ 
    if (!['STEP_STAGE_PRE', 'STEP_STAGE_QUEUE', 'STEP_STAGE_STEP1'].includes(setMigrationInfo)) { 
      isVisiableDialog();
      getOberloGuidePop({ data: { name: 'out_bind_ae' } }).then(res => {
        if (res.value == 'true') {
          setOnceBindAE(true);
        }
      });
    }
    getOberloGuidePop({ data: { name: 'has_Plug' } }).then(res => {
      setOnceDownChrome(res?.value == 'true' ? true : false);
    });
  }, [isChildAcount, isOberlo,confirmPlan]);

  /**
   * 判断开屏弹窗是否显示
   */
  const isVisiableDialog = () => {
    getOberloGuidePop({ data: { name: 'oberloGuidePop' } }).then(res => {
      if (res.value === '' || (OstoreInfo?.code == 2000 && !!OstoreInfo?.msg.match(/proxy failed/ig)?.[0])) {
        setChangeDialog(true);
      }
    });
  };

  /**
   * 按钮提交统一处理
   * @param {Number} step step  需要绑定的事件 step === 1 绑定插件   step === 2 确认套餐   step === 3 下载插件
   * @param {Function} cb         回调函数  加载刷新弹窗
   */

  const guildBtnUnify = (step, cb) => {
    eventTracking(step)
    if (step === 1) {
      //  綁定AE
      dispatch({
        type: 'topBtn/aliauth',
        payload: {
          cancelMessage: true,
          callback: d => {
            if (d.code === 2000) {
              window.location = d.data.url;
            }else if(d.code === 4090){
              window.location = '/app/';
            } else {
              message.error(d.msg);
            }
          }
        }
      });
    }
    //确认套餐
    else if (step === 2) {
      dispatch({
        type: 'guidance/modificationProject',
        payload: {
          data: {
            plan_type: confirmPlan?.oberlo === 2 ? 2 : confirmPlan?.oberlo === 3 ? 3 : 10,
            plan_period: 1
          },
          callback: d => {
            if (d.code == 2000) {
              if (d.data.confirm_url) {
                window.location.href = d.data.confirm_url;
                return;
              }
            } else {
              logException(d, 'guidance/modificationProject');
            }
          }
        }
      });
    }
    //确认插件
    else {
      cb();
      window.open(getChromeExtUrl(), '_blank');
    }
  };
  /**
   * 判断billing账单是否可用
   */
  const isUsingBilling = () => {
    if (!plan.default_store_id) {
      return;
    }
    dispatch({
      type: 'guidance/shopifyStatus',
      payload: {
        data: {
          store_id: plan.default_store_id
        },
        cancelMessage: true,
        callback: d => {
          if (d.data && d.data.is_available) {
            setIsBilling('yes');
            return
          }
          if(!userInfo.stores.filter((i)=>{
            return i.id == plan?.default_store_id
          })[0]?.shop_name) {
            setIsBilling('withOut');
            return
          }
            setIsBilling('no')
        }
      }
    });
  }
  /**
   * billing 不可用时进行跳转
   */
  const errorBillName = () => {
    dispatch({
      type: 'auth/createShopfiy',
      payload: {
        data: {
          shop_name: userInfo.stores.filter((i)=>{
            return i.id == plan?.default_store_id
          })[0]?.shop_name
        },
        callback: d => {
          if (d.code === 2000 || d.code === 2010) {
            if (d.msg === 'bind store quota err') {
              localStorage.setItem('passivity', 'true');
              self.location.href = '/app/pricing';
            }else{
              window.location.href = d?.data?.url || '/';
            }
          }
        }
      }
    });
  };
  /**
   * 新增打点
   */
  const eventTracking = (step) => {
    if (!isOberlo) { return;}
    // 如果事件为下载插件事件 需要记录标识 初始化的时候需要使用(与明皓讨论很久的结果。。。)
    if (step == 3) {
       localStorage.setItem('MIGRATION_PLUG_STATUS',true)
    }
    CommonDataCollect({ 
      action: step == 1 ? 'migration_bind_ae' :
        step == 2 ? 'migration_choose_plan' :
          'migration_install_chrome',
      scene:1
     })
  }
  return (
    <>
      {isOberlo && (
        <>
          {changeDialog && (
            <OBDialog
              onceBindAE={onceBindAE}
              setHide={() => {
                setOberloGuidePop({
                  data: { name: 'oberloGuidePop', value: 'true' }
                });
                setTimeout(() => {
                  setChangeDialog(false);
                }, 1000);
              }}
              guildBtnUnify={guildBtnUnify}
            />
          )}
          <OBanber
            onceBindAE={onceBindAE}
            onceDownloadChrome = {onceDownloadChrome}
            update={changeBanner}
            guildBtnUnify={guildBtnUnify}
            plan={confirmPlan?.oberlo}
            onceBindPlan={confirmPlan?.compare}
            isBillStore={isBilling}
            isChildAcount={isChildAcount}
          />
          <OBCard update={changeCard} />
        </>
      )}
      <CommonGuide plan={plan.type} update={changePlanPop} />
      {(isOberlo || changePop.indexOf('CHROME') !== -1 ||  changePop.indexOf('AE') !== -1) &&
        <OBPop
            update={changePop}
            plan={confirmPlan?.oberlo}
            isBillStore={isBilling}
            guildBtnUnify={guildBtnUnify}
            errorBillName={errorBillName}
            onceBindAE={onceBindAE}
            onceDownloadChrome = {onceDownloadChrome}
          />}
    </>
  );
};

const mapStateToProps = ({ user, auth, topBtn, login }) => ({
  userInfo: login.userInfo,
  plan: user.plan, // 判断套餐类型
  isChildAcount: user.newUserInfo.type, //判断是否是子账户
  isOberlo: auth?.isOberloUser,
  confirmPlan:topBtn.fromOberloPlan,
  OstoreInfo:auth?.OstoreInfo
});
export default connect(mapStateToProps)(React.memo(OblerloGuide));
