import { Post, Get} from '../utils/request';
/**
 * Oberlo引导部分接口
 */
export async function getOberloGuidePop(payload) {
     return  Get('/mgnoberlo-core-api/dsers-api/migration/action/customize', payload,true);
}
  

export async function setOberloGuidePop(payload) {
     return Post('/mgnoberlo-core-api/dsers-api/migration/action/customize',payload,true);
  }

export async function getTurnPlan() {
     return Get('/api/v1/charge/plan_ex',{data:{manual:'0'}},true);
  }
