import {
  // 获取支付状态
  getplan
} from '../services/request';
import { getProject } from '../services/user';

export default {
  namespace: 'request',
  state: {
    unread: 0,
    plan: {}
  },

  reducers: {
    // 获取支付状态
    setPlan(state, active) {
      const currentProductList = deepClone(state);
      currentProductList.plan = active.payload;
      return currentProductList;
    }
  },

  effects: {
    // 获取支付状态
    *getPlan({ payload }, { call, put }) {
      const result = yield call(getProject, payload);
      console.log(this);
      const code = result.code;
      const data = result.data;
      if (code == '2000') {
        yield put({
          type: 'setPlan',
          payload: data
        });
      }
    }
  },

  subscriptions: {
    // 获取支付状态
    getPlan({ dispatch, history }) {
      console.log('sdfsdfsdfsd');
      dispatch({
        type: 'getPlan'
      });
    }
  }
};

function deepClone(arr) {
  return JSON.parse(JSON.stringify(arr));
}
