
    export default 
    {
    "error_cashback_code": {
        "title": "Tu cashback de Admitad se está deteniendo. Por favor, verifica esto.",
        "title_dec": "Nos hemos dado cuenta de que tu código no está en Dsers.",
        "p1": "Nos hemos dado cuenta de que estás usando un código de afiliado en un canal que no es Dsers.",
        "p2": "Ya no puedes obtener cashback con el código actual.",
        "p3": "Por favor, haz clic abajo para saber qué ha pasado y cómo solucionarlo."
    }
}