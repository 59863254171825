export default {
    migration: {
        progress_1:'Création de compte',
        progress_2:'Préférence des paramètres',
        progress_3:'Migration des données',
        title:'Bienvenue dans la migration DSers',
        title_2:'Nous avons préparé 2 solutions de migration des données. Laquelle préférez-vous?',
        scenario_1:'Si vous gérez votre boutique avec Oberlo, nous vous recommandons:',
        scenario_1_title:'Remplacer les données DSers par les données Oberlo',
        scenario_1_text:'Veuillez noter que les données DSers seront supprimées.',
        scenario_2:'Si vous gérez votre boutique avec Oberlo et DSers, nous vous recommandons :',
        scenario_2_title:'Fusionner les données Oberlo et les données DSers',
        scenario_2_text:'Veuillez confirmer vos préférences de migration des données.',
        button:'APPLIQUER LES PREFERENCES CHOISIES',
        blog:'Guide rapide : Processus de migration | Comparaison des fonctions | Comparaison des plans',
        contact_1:'Support via chat direct 24h/24 et 7j/7',
        contact_2:'Support via email',
        option_1_title:'Pour les conflits de commandes & produits entre DSers et Oberlo, vous voulez:',
        option_1_text:`Il peut y avoir des conflits si un produit Shopify est défini avec différents fournisseurs AliExpress dans DSers et Oberlo, ou si vous avez passé une commande vers AliExpress avec Oberlo, mais pas dans DSers`,
        option_2_title:'En cas de conflit de paramètres entre DSers et Oberlo, vous voulez:',
        option_2_text:`Par exemple, DSers et Oberlo ont tous deux la fonction de règle de prix, votre migration de données peut créer des conflits en raison de paramètres différents`,
        option_1_1:'DSers comme référence',
        option_1_2:'Oberlo comme référence',
        setting_title:'Pour les conflits de données entre DSers et Oberlo, vous souhaitez:',
        setting_text:`Par exemple, DSers et Oberlo ont tous deux une fonction de règle de prix, votre migration de données peut créer des conflits car ils ont des paramètres différents, vous devez donc confirmer vos préférences à l'avance.`,
        data_title:'Cliquez pour commencer',
        data_title_2:`During the migration, your Oberlo and DSers accounts will be unavailable.
        They will be available again after completion.`,
        data_button:'COMMENCER MIGRATION',
        data_button_wait:'Migration dans 1h',
        data_button_text:`En cliquant sur Démarrer Migration, votre compte Oberlo sera défini en lecture seule et vous acceptez nos conditions d'utilisation. La migration prend jusqu'à {time} heure.`,
        data_change:'Modifier les préférences',
        data_process_title:'Migration en cours...',
        data_process_text:'Veuillez garder les applications DSers & Oberlo installées et la boutique Shopify active.',
        data_setting:'Paramètres',
        data_order:'Commandes',
        data_product:'Produits',
        data_plan:'Plan',
        optionstext:{
            setting:['Migration des paramètres en cours...','Réduisez le taux de litige en synchronisant les numéros de suivi avec PayPal'],
            order:['Migration des données des commandes en cours...','Passez plus de 100 commandes en quelques secondes','Synchronisez automatiquement le statut des commandes.'],
            product:['Migration des données des produits en cours...','Créez des packs de produits avec un mapping des variantes'],
            plan:[`Correspondance du plan d'abonnement...`,'Accédez à plusieures nouvelles fonctions.']
        },
        waitmigration_title:'Veuillez attendre la fin de la migration',
        waitmigration_text:'La migration du compte Oberlo a déjà commencé. Veuillez attendre que ce compte soit migré. Si vous avez accédé à DSers, veuillez lier votre compte AliExpress pour terminer votre migration.',
        timeout_title:'La demande a expiré',
        timeout_text:'La demande a expire, veuillez rafraichir la page et ré-autorisé depuis Oberlo. Si vous avez des questions, veuillez contacter le service client DSers.',
        timeout_contact:'Contacter Service Client',
        childAccount:'La compte actuel est un compte employé DSers et ne peut pas être utilisé pour la migration. Veuillezvous connecter au compte principal ou créer un nouveau compte DSers.',
        blackAccount:`Le compte actuel est indisponible. Veuillez changer d'adresse email et réessayer.`,
        otherplatform:`Le compte actuel est lié à une autre plateforme, veuillez changer de compte DSers et réessayer.`,
        card_1_title:'Comment voulez-vous gérer vos données?',
        question_1:'1. Pour les données de produits dans deux comptes, vous souhaitez:',
        question_1_select1:'Migrer les données Oberlo',
        question_1_select2:'Conservez aussi les données de DSers',
        question_2:'2. Pour les données des commandes dans deux comptes, vous souhaitez:',
        card_2_title:'Comment voulez-vous gérer les conflits de données?',
        question_3:`1. Pour les conflits de commandes & produits entre DSers et Oberlo, vous préférez:`,
        question_3_select1:'Oberlo',
        question_3_select2:'DSers',
        question_4:'2. Pour les conflits de paramètres entre DSers et Oberlo, vous préférez:',
        childAccountawait:'Migration en cours...',
        childAccountawaittext:'Compte DSers en cours de migration démarrée par votre compte administrateur.',
        code_500:'Exception de migration, veuillez contacter le service client DSers pour résoudre ce problème.',
        code_426:'Le service est en cours de mise à jourm veuillez réessayer plus tard. Si vous avez des questions, veuillez contacter le service client de DSers.',
        exitmigrationtext:'Après avoir quitté la migration, vous pouvez vous rendre sur Oberlo pour redémarrer la migration',
        shoperror:'Veuillez vous assurer que votre boutique Shopify est prête et que DSers est autorisé',
        shopcontinue:`Continuer migration`,
        dataerror:'Un problème avec les données est survenu pendant la migration, cliquez sur le bouton "Réessayer" pour réessayer, ou cliquez sur "Contacter service client" pour nous contacter',
        datacontinue:'Réessayer',
        refresh:'Veuillez rafraichir la page',
        customerservice:'Contacter Service Client',
        oberloError:`L'accès à Oberlo a échoué. Veuillez contacter le service client.`,
        requestid:'Please report this ID to DSers support team for trouble shooting Request ID:' ,
        startButton:`Note: <br/>
        1.Après avoir commencé la migration, votre compte Oberlo sera en lecture seule. <br/> 2. La migration prend environ 1 heures`,
        exitmigration:'Quitter migration',
        according:'What data will be in your DSers account finally?',
        cover:`Selon votre configuration.DSers conservera les données Produits & Commandes & Réglages de votre compte Oberlo`,
        oberlo_oberlo:'Selon votre configuration.DSers conservera pour vous les données Produits & Commandes & Réglages des comptes DSers et Oberlo. En cas de conflits de données lors de la synchronisation, les données Oberlo prévaudront.',
        dsers_dsers:'Selon votre configuration.DSers conservera pour vous les données Produits & Commandes & Réglages des comptes DSers et Oberlo. En cas de conflits de données lors de la synchronisation, les données DSers prévaudront.',
        dsers_oberlo:'Selon votre configuration.DSers conservera pour vous les données Produits & Commandes & Réglages des comptes DSers et Oberlo. En cas de conflits de données lors de la synchronisation, les données Produits et Commandes de DSers prévaudront et les données Paramètres d’Oberlo prévaudront.',
        oberlo_dsers:'Selon votre configuration.DSers conservera pour vous les données Produits & Commandes & Réglages des comptes DSers et Oberlo. En cas de conflits de données lors de la synchronisation, les données Produits et Commandes d’Oberlo prévaudront et les données Paramètres de DSers prévaudront.',
        havetask:'Une tâche est en cours sur le compte actuel. Veuillez réessayer plus tard.',
        timeout:'La requête du serveur a expiré, veuillez cliquer sur le bouton "Démarrer Migration" pour redémarrer la migration',
        data_wait_text:'La migration commencera dans {time} heure. Vous pouvez utiliser votre compte Oberlo pendant que vous attendez, mais la migration peut commencer à tout moment.',
        appointTip:`Après avoir réglé l'heure, vous ne pouvez pas la modifier. Veuillez sélectionner un moment approprié pour migrer. Après le début de la migration, vous ne pourrez plus utiliser Oberlo et DSers. Si vous avez des questions, veuillez nous contacter.`,
        appointConfirm:'Confirmer créneau horaire',
        appointSuccess:'Réglage réussi',
        startNow:`Commencer migration`,
        timefull:'Le créneau horaire actuellement sélectionné est complet, vous pouvez choisir de démarrer la migration immédiatement',
        nowTimeFull:`Ce créneau n'est pas disponible, veuillez sélectionner un autre créneau`,
        tooltiptext:`Vous avez des données à la fois sur Oberlo et sur DSers, nous avons choisi de conserver les données des deux plates-formes pour vous par défaut. Si vous ne souhaitez pas conserver les données DSers, vous pouvez décocher la case à côté de "Conserver également les données DSers".`,
        tooltiptext_2:`Par exemple, si vous avez le même article sur Oberlo et DSers, mais que vous avez des descriptions et des spécifications de prix différentes pour l'article, la migration des données peut rencontrer des conflits `,
        migrationText:'Heure de Début de Migration'
    }
}