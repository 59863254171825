import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Button } from 'antd';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';

import reactGaEvent from 'utils/GAEvent';
import { CommonDataCollect } from 'utils/utils';

import banner from '../../../assets/payInto/banner_66.png';

import headPortrait from '../../../assets/payInto/headPortrait.png';
import money from '../../../assets/payInto/money.png';
import shopping from '../../../assets/payInto/shopping.png';
import smallBell from '../../../assets/payInto/smallBell.png';
import flame from '../../../assets/payInto/flame.png';
import heart from '../../../assets/payInto/heart.png';
import soon from '../../../assets/payInto/soon.png';
import house from '../../../assets/payInto/house.png';
import aircraft from '../../../assets/payInto/aircraft.png';
import box from '../../../assets/payInto/box.png';
import mailbox from '../../../assets/payInto/mailbox.png';

import eResize from '../../../assets/payInto/e-resize.svg';
import nResize from '../../../assets/payInto/n-resize.svg';
import explode from '../../../assets/payInto/explode.svg';
import computer from '../../../assets/payInto/computer.svg';

import styles from './index.less';

const modalRoot = document.getElementById('root');

function PayInto({ dispatch }) {
  localStorage.removeItem('PayIntoDetailsSix');

  let payInto = localStorage.getItem('PayIntoDetailsSeven');

  const [details, setDetails] = useState(!!payInto);

  function closeDetails() {
    localStorage.setItem('PayIntoDetailsSeven', true);
    setDetails(true);
  }

  function showDetails() {
    CommonDataCollect({
      event_type: 'subscription_launch',
      action: 'click_show_details_CAM006_button'
    });
    reactGaEvent({
      category: 'PayInto',
      action: 'click_show_details_CAM006_button'
    });
    localStorage.removeItem('PayIntoDetailsSeven');
    setDetails(false);
  }

  function gotoSelect() {
    CommonDataCollect({
      event_type: 'subscription_launch',
      action: 'click_upgrade_CAM006_button'
    });
    reactGaEvent({
      category: 'PayInto',
      action: 'click_upgrade_CAM006_button'
    });

    dispatch(routerRedux.push('/pricing?select=1'));
  }

  function showBlog() {
    CommonDataCollect({
      event_type: 'subscription_launch',
      action: 'click_show_blog_CAM006_button'
    });
    reactGaEvent({
      category: 'PayInto',
      action: 'click_show_blog_CAM006_button'
    });

    dispatch(routerRedux.push('/pricing?select=1'));
  }

  if (details) {
    return (
      <div className={styles.container}>
        <div className={styles.text}>
          <a href="javascript:void(0)" onClick={showBlog}>
            Upgrade to get Advanced Feature & Service for Q4.
          </a>
        </div>
        <div className={styles.operation}>
          <div className={styles.button} onClick={gotoSelect}>
            Try Now
          </div>
          <i class="material-icons notranslate" onClick={showDetails}>
            keyboard_arrow_up
          </i>
        </div>
      </div>
    );
  }

  return ReactDOM.createPortal(
    <div className={styles.mask}>
      <div className={styles.modal}>
        <div className={styles.header}>
          <div className={styles.left}>
            <div className={styles.headPortrait}>
              <img src={headPortrait} />
            </div>
            <div className={styles.text}>
              <div className={styles.title}>Merchant Growth Team</div>
              <div className={styles.subTitle}>
                DSers empowers your dropshipping journey
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <i class="material-icons notranslate" onClick={closeDetails}>
              keyboard_arrow_down
            </i>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.location}>
            <div className={styles.img}>
              <img src={explode} />
            </div>
            <p>Order explosion Q4 is coming.</p>
          </div>

          <div className={styles.location}>
            <div className={styles.img}>
              <img src={nResize} />
            </div>
            <p>Upgrade to prepare for high business growth with DSers.</p>
          </div>

          <div className={styles.location}>
            <div className={styles.img}>
              <img src={eResize} />
            </div>
            <p>More quickly, more steadily, and more efficiently.</p>
          </div>

          <div className={styles.location}>
            <div className={styles.img}>
              <img src={computer} />
            </div>
            <p>More Features: Tracking | Bundle | Staff Account | …</p>
          </div>

          {/* <p>Build trust for repeat purchases!</p> */}
        </div>

        <div className={styles.footer}>
          <div className={styles.image}>
            <img src={banner} />
          </div>

          <div className={styles.option}>
            <div className={styles.text}>
              <a href="javascript:void(0)" onClick={showBlog}>
                Upgrade to get Advanced Feature & Service for Q4.
              </a>
            </div>
            <div className={styles.button} onClick={gotoSelect}>
              Try Now
            </div>
          </div>
        </div>
      </div>
    </div>,
    modalRoot
  );
}

const CAM006 = connect()(PayInto);

export {
  CAM006
}

