import React from 'react';
import { Rate, Modal, Input, Button } from 'antd';

import { connect } from 'dva';
import styles from './index.less';

import Part01 from '../../assets/evaluate/Part01.png';
import Part02 from '../../assets/evaluate/Part02.png';
import Part03 from '../../assets/evaluate/Part03.png';
import shopify from '../../assets/evaluate/shopify.png';

const { TextArea } = Input;

@connect(({ productmy, loading, login }) => ({
  productmy,
  loading: loading.models.productmy,
  userInfo: login.userInfo,
  feedBackLoading: loading.effects['productmy/feedBack'],
  feedBackUsLoading: loading.effects['productmy/feedBackRateUs'],
  feedBackStatusLoading: loading.effects['productmy/feedBackStatus']
}))
export default class EvaluateComp extends React.PureComponent {
  state = {
    value: 0,
    isClick: true,
    visible: true,
    visibleModal: false,
    valueTextArea: '',
    visibleThanK: false,
    visibleShoify: false,
    visibleShop: false
  };
  componentDidMount() {}

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  timer = null;
  interval = 2000;

  handleChange = val => {
    this.setState({ value: val });
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.hideShowAlert(val);
    }, this.interval);
  };

  hideShowAlert = val => {
    if (val <= 3) {
      this.setState({
        visible: false,
        visibleModal: true,
        visibleThanK: false,
        visibleShoify: false
      });
      
    } else {
      this.setState({
        visible: false,
        visibleShoify: true,
        visibleShop: false,
        visibleModal: false
      });
      
    }
  };
  // (status/10)&2 ==0 弹 (status/10)&2 >0 不弹
  // 如果弹了，status|20 更新服务端status
  asyncUpadteStatus = flag => {
    this.props.dispatch({
      type: 'productmy/feedBackStatus',
      payload: {
        data: {
          status: this.props.userInfo.status | 20
        },
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            if (flag !== true) {
              window.location.reload();
            }
          }
        }
      }
    });
  };
  handleClose = () => {
    this.asyncUpadteStatus();
    this.setState({ visible: false });
  };

  handleClodeModal = () => {
    this.handleFormData();
    this.setState({ visibleModal: false });
  };

  handleCloseShoify = () => {
    this.handleFormData();
    this.setState({ visibleShoify: false });
  };

  handleFormData = () => {
    const { value, valueTextArea } = this.state;
    this.props.dispatch({
      type: 'productmy/feedBack',
      payload: {
        data: {
          star: value,
          content: valueTextArea
        },
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            this.asyncUpadteStatus();
            this.setState({
              visibleThanK: true
            });
          }
        }
      }
    });
  };

  onChange = ({ target: { value } }) => {
    this.setState({ valueTextArea: value });
  };

  handleShopIfyStore = () => {
    this.props.dispatch({
      type: 'productmy/feedBackRateUs',
      payload: {
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            this.setState({
              visibleShoify: true,
              visibleShop: true
            });
            this.asyncUpadteStatus(true);
          }
        }
      }
    });
  };

  render() {
    const {
      value,
      visible,
      visibleModal,
      valueTextArea,
      visibleThanK,
      visibleShoify,
      visibleShop
    } = this.state;
    const { userInfo } = this.props;
    return (
      <>
        <div
          className={styles.wrapComp}
          style={{ display: visible ? 'flex' : 'none' }}
        >
          <img src={Part01} className={styles.imgLeft} />
          <div className={styles.textArea}>
            <h3>Your opinion matters</h3>
            <p>Hello!</p>
            <p>
              Your feedback is what keeps us working hard to provide a BETTER
              service, please rate your experience
            </p>
            <p>
              <Rate
                onChange={this.handleChange}
                value={value}
                className={styles.rate}
              />
              <img src={Part03} className={styles.imgPart} />
            </p>
          </div>
          <img src={Part02} className={styles.imgPOSTION} />
          <span className={styles.closeWrap} onClick={this.handleClose}>
            X
          </span>
        </div>

        <Modal
          visible={visibleModal}
          footer={false}
          width={500}
          heigth={600}
          maskClosable
          closable={false}
          onCancel={this.handleClodeModal}
        >
          {visibleThanK == false ? (
            <div
              style={{
                padding: '10px 20px'
              }}
            >
              <h2>Your feedback matters to us</h2>
              <p>
                Your review and feedback keep us working hard to provide{' '}
                <span className={styles.blankColor}>FREE&BETTER</span> service.
                Please leave commet to us.
              </p>
              <TextArea
                rows={4}
                value={valueTextArea}
                onChange={this.onChange}
                maxLength="1000"
              />
              <p className={styles.maxLengthAR}>
                {1000 - valueTextArea.length * 1}/1000
              </p>
              <div className={styles.btnModal}>
                <Button onClick={this.handleClodeModal}>Cancel</Button>
                <Button
                  type="primary"
                  disabled={valueTextArea == ''}
                  onClick={this.handleFormData}
                >
                  Submit
                </Button>
              </div>
            </div>
          ) : (
            <h2 className={styles.titleH}>Thank you so much!</h2>
          )}
          <img src={Part02} className={styles.imgTop} />
        </Modal>

        <Modal
          visible={visibleShoify}
          footer={false}
          closable={false}
          width={500}
          heigth={600}
          maskClosable
          onCancel={this.handleCloseShoify}
        >
          {visibleShop == false ? (
            <div
              style={{
                padding: '10px 20px'
              }}
            >
              <h2>Your review matters to us</h2>
              <p>
                Your review keeps us working hard to provide{' '}
                <span className={styles.blankColor}>FREE&BETTER</span> service.
              </p>
              <p>
                Could you please rate us for your{' '}
                <span className={styles.userInfoColor}>
                  {userInfo.stores.length} stores
                </span>{' '}
                on Shopify app store as well?
              </p>
              <div className={styles.btnShopify}>
                <Button onClick={this.handleShopIfyStore}>
                  <a href="https://apps.shopify.com/dsers" target="_blank">
                    Rate us
                  </a>
                </Button>
              </div>
            </div>
          ) : (
            <h2 className={styles.titleH}>Thank you so much!</h2>
          )}
          <img src={shopify} className={styles.imgTopy} />
        </Modal>
      </>
    );
  }
}
