export default {
    migration: {
        progress_1:'Criação de conta',
        progress_2:'Preferências de configuração',
        progress_3:'Migração de dados',
        title:'Bem-vindo à migração do DSers',
        title_2:'Preparamos duas soluções para migração de dados. Qual você prefere?',
        scenario_1:'Se você gerencia o seu negócio com Oberlo, recomendamos:',
        scenario_1_title:'Substituir os dados do DSers com os dados do Oberlo',
        scenario_1_text:'Note que os dados do DSers serão excluídos.',
        scenario_2:'Se você gerencia o seu negócio com Oberlo e DSers, recomendamos:',
        scenario_2_title:'Mesclar os dados do Oberlo e do DSers',
        scenario_2_text:'Confirme sua preferência de migração de dados.',
        button:'APLICAR AS PREFERÊNCIAS ESCOLHIDAS',
        blog:'Guia rápido: Processo de migração | Comparação de recursos | Comparação de planos',
        contact_1:'Suporte por chat 24/7',
        contact_2:'Suporte por e-mail',
        option_1_title:'Em caso de conflito de pedidos e produtos entre DSers e Oberlo, você deseja:',
        option_1_text:`Pode haver conflitos se um produto do Shopify usar fornecedores do AliExpress diferentes no DSers e no Oberlo ou se você fez um pedido no AliExpress com o Oberlo, mas não no DSers`,
        option_2_title:'Em caso de conflito de configurações entre DSers e Oberlo, você deseja:',
        option_2_text:`Por exemplo, DSers e Oberlo têm o recurso de regra de preço, portanto, a migração de dados pode apresentar conflitos causados por modelos de configuração diferentes`,
        option_1_1:'DSers como referência',
        option_1_2:'Oberlo como referência',
        setting_title:'Para conflitos de dados entre DSers e Oberlo, você deseja:',
        setting_text:'Por exemplo, DSers e Oberlo têm o recurso de regra de preço, portanto, a migração de dados pode gerar conflitos, pois são modelos de configuração diferentes. Por isso, você precisa confirmar sua preferência.',
        data_title:'Clique para começar',
        data_title_2:`Durante a migração, suas contas do Oberlo e DSers ficarão indisponíveis.
        Elas estarão disponíveis de novo após a conclusão`,
        data_button:'INICIAR MIGRAÇÃO',
        data_button_wait:'MIGRAÇÃO PENDENTE',
        data_button_text:'Ao clicar em “Iniciar migração”, sua conta do Oberlo será definida como “somente leitura” e você concordará com nossos termos de uso. A migração leva até {time} hora.',
        data_change:'Modificar preferências',
        data_process_title:'Migração em andamento...',
        data_process_text:'Please keep DSers & Oberlo app installed and Shopify store active.',
        data_setting:'Configurações',
        data_order:'Pedidos',
        data_product:'Produtos',
        data_plan:'Plano',
        optionstext:{
            setting:['Migração de configurações em andamento...','Reduza as disputas, sincronizando os números de rastreamento com o PayPal'],
            order:['Migração de dados de pedidos em andamento...','Faça mais de 100 pedidos em segundos','Sincronize automaticamente o status dos pedidos.'],
            product:['Migração de dados de produtos em andamento...','Crie pacotes de produtos com mapeamento de variantes'],
            plan:['Plano de assinatura correspondente...','Tenha acesso a diversos recursos poderosos.']
        },
        waitmigration_title:'Aguarde até que a migração atual seja concluída.',
        waitmigration_text:'Uma conta para a migração do Oberlo já existe. Aguarde até que essa conta seja migrada.',
        timeout_title:'Solicitação expirada',
        timeout_text:'A solicitação expirou. Atualize a página ou autorize novamente no Oberlo. Em caso de dúvidas, entre em contato com o suporte DSers.',
        timeout_contact:'Contatar suporte',
        childAccount:'A conta atual é uma subconta DSers e não pode ser usada para efetuar a migração. Acesse a conta principal ou crie uma nova conta DSers.',
        blackAccount:`A conta atual está indisponível. Altere o endereço de e-mail e tente novamente.`,
        otherplatform:`A conta atual foi vinculada a outras plataformas. Altere a conta DSers e tente novamente.`,
        card_1_title:'Como você quer lidar com seus dados? ',
        question_1:'1. Para dados de produtos das duas contas, você quer:',
        question_1_select1:'Migrar os dados do Oberlo',
        question_1_select2:'Manter também os dados do DSers',
        question_2:'2. Para dados de pedidos das duas contas, você quer: ',
        card_2_title:'Como você deseja lidar com conflitos de dados?',
        question_3:`1. Para conflitos de pedidos e produtos entre DSers e Oberlo, você prefere:`,
        question_3_select1:'Oberlo',
        question_3_select2:'DSers',
        question_4:'2. Para conflitos de configurações entre DSers e Oberlo, você prefere:',
        childAccountawait:'Migração em andamento...',
        childAccountawaittext:'A migração de sua conta DSers foi iniciada pela conta do seu administrador.',
        code_500:'Falha na migração. Entre em contato com o suporte DSers para solucionar o problema.',
        code_426:'Este serviço está sendo atualizado. Tente novamente mais tarde. Em caso de dúvidas, entre em contato com o suporte DSers.',
        exitmigrationtext:'Após sair da migração, você poderá acessar o Oberlo para reiniciar a migração',
        shoperror:'Certifique-se de que sua loja Shopify esteja pronta e tenha autorizado o acesso do DSers',
        shopcontinue:`CONTINUAR MIGRAÇÃO`,
        dataerror:'Ocorreram alguns problemas com os dados durante o processo de migração. Clique no botão “TENTAR NOVAMENTE” para repetir o processo ou em “Contatar atendimento ao cliente” para falar conosco',
        datacontinue:'TENTAR NOVAMENTE',
        refresh:'Atualize a página',
        customerservice:'Contatar suporte',
        oberloError:'Falha ao acessar o Oberlo. Contate o atendimento ao cliente.',
        requestid:'Informe este ID à equipe de suporte DSers para solucionar o problema  Request ID:' ,
        startButton:`Observação: <br/>
        1. Após iniciar a migração, sua conta Oberlo será configurada como “apenas leitura”. <br/> 2. A migração leva cerca de 1 horas. `,
        exitmigration:'SAIR DA MIGRAÇÃO',
        according:'Ao final, quais dados permanecerão na sua conta DSers?',
        cover:`De acordo com a sua configuração.O DSers preservará os dados de produtos, pedidos e configurações da sua conta Oberlo `,
        oberlo_oberlo:'De acordo com a sua configuração.O DSers preservará os dados de produtos, pedidos e configurações das contas DSers e Oberlo para você. Em caso de conflitos de dados durante a sincronização, os dados do Oberlo prevalecerão.',
        dsers_dsers:'De acordo com a sua configuração.O DSers preservará os dados de produtos, pedidos e configurações das contas DSers e Oberlo para você. Em caso de conflitos de dados durante a sincronização, os dados do DSers prevalecerão.',
        dsers_oberlo:'De acordo com a sua configuração.O DSers preservará os dados de produtos, pedidos e configurações das contas DSers e Oberlo para você. Em caso de conflitos de dados durante a sincronização, os dados de produtos e pedidos do DSers e os dados de configurações do Oberlo prevalecerão.',
        oberlo_dsers:'De acordo com a sua configuração.O DSers preservará os dados de produtos, pedidos e configurações das contas DSers e Oberlo para você. Em caso de conflitos de dados durante a sincronização, os dados de produtos e pedidos do Oberlo e os dados de configurações do DSers prevalecerão.',
        havetask:'Uma tarefa está sendo executada na conta atual. Tente novamente mais tarde.',
        timeout:'The server request timed out, please click the "Start Migration" button to restart the migration',
        data_wait_text:'A migração começará dentro de {time} hora. Você pode operar sua conta do Oberlo enquanto aguarda, mas a migração pode começar a qualquer momento.',
        appointTip:`Após selecionar o horário, você não poderá modificá-lo. Selecione um intervalo adequado para migrar. Após a migração ser iniciada, você não conseguirá operar o Oberlo e o DSers. Em caso de dúvidas, entre em contato conosco.`,
        appointConfirm:'Confirmar definição de horário',
        appointSuccess:'Horário definido com sucesso',
        startNow:'Iniciar migração agora',
        timefull:'O horário selecionado está ocupado. Você pode escolher iniciar a migração imediatamente',
        nowTimeFull:'Este horário está indisponível. Selecione outro horário',
        tooltiptext:`Você tem informações de dados tanto no Oberlo quanto no DSers. Por padrão, escolhemos manter os dados das duas plataformas para você. Caso não queira manter os dados do DSers, desmarque a caixa ao lado de “Manter também os dados do DSers”.`,
        tooltiptext_2:`Por exemplo, se você tem o mesmo item no Oberlo e no DSers, mas ele possui diferentes descrições e preços, a migração de dados apresentará alguns conflitos.`,
        migrationText:'Horário de início da migração'
    }
}