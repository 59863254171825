import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { connect } from 'dva';
import { Drawer, Button, Progress, Spin, Modal, Popover } from 'antd';

import styles from './OrderSupplier.less'

import useTopBanner from 'hooks/useTopBanner';

import bannerimg from 'assets/banner/supplier/openarrow.png'
import headerIocn from 'assets/banner/supplier/header.png'
import backImg from 'assets/banner/supplier/backImg.png'
import arrow from 'assets/banner/supplier/arrow.png'
import { v5Map, list_V5 } from 'utils/bannerWhiteList';
import { Get, Post } from 'utils/request';
import moment from 'moment';

import DsQRCode from 'components/DsQRCode';

const allowPath = [
    '/order/list'
];

function OrderSupplier({ path, userInfo, userId, halfBanner, visible, bannerData, pushedList }) {
    const [hover, setHover] = useState(false)
    const [change, setChange] = useState(false)
    const [allshow, setAllShow] = useState(false)
    const [containerWidth, SetContainerWidth] = useState({})
    const [percentage, SetPercentage] = useState(0)
    const [progressvalue, SetProgressValue] = useState(0)
    const [finish, setFinish] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)

    const [text,setText] = useState()

    const dispatch = useDispatch()

    const codesData={
      'bgs6debcu2uif9ofthh0':{
        employee:'Ciel',
        email:'ciel@dserspro.com',
        whatsapp:'8617800783063'
      },
      'bjmnmr5d0c8u3ojpof20':{
        employee:'Laura',
        email:'laura@dserspro.com',
        whatsapp:'8615775883947'
      },
      'bniop6dqp3nd767bmfr0':{
        employee:'Cohen',
        email:'cohen@dserspro.com',
        whatsapp:'8613458963550'
      },
      'bohhmpik4th000c8ar70':{
        employee:'Laura',
        email:'laura@dserspro.com',
        whatsapp:'8615775883947'
      },
      'bpaosnlqp3n5vhmo9i6g':{
        employee:'Anchor',
        email:'anchor@dserspro.com',
        whatsapp:'8613065809221'
      },
      'bqerbbdqp3n90e83jnjg':{
        employee:'Chloe',
        email:'zhouxiaoqing@dserspro.com',
        whatsapp:'8618157360759'
      },
      'bramveid1qt000dd2qeg':{
        employee:'Jessica',
        email:'jessica@dserspro.com',
        whatsapp:'8615184475253'
      },
      'bsmdisg8t7g000fvgkbg':{
        employee:'Laura',
        email:'laura@dserspro.com',
        whatsapp:'8615775883947'
      },
      'bt88kphgmed000d1g74g':{
        employee:'Bess',
        email:'bess@dserspro.com',
        whatsapp:'8618328573251'
      },
      'c07coo62qr4g00be12n0':{
        employee:'Alisa',
        email:'alisa@dserspro.com',
        whatsapp:'8615310808994'
      },
      'c1c9mlrci9bg00cc7ubg':{
        employee:'June',
        email:'duanyanran@dserspro.com',
        whatsapp:'8618308195406'
      },
      'c2h90j6np9kg00djfj7g':{
        employee:'Anchor',
        email:'anchor@dserspro.com',
        whatsapp:'8613065809221'
      },
      'c47r9lsi5nq000ag74ig':{
        employee:'Bess',
        email:'bess@dserspro.com',
        whatsapp:'8618328573251'
      },
      'c4r5oh779ts000a84cp0':{
        employee:'Alisa',
        email:'alisa@dserspro.com',
        whatsapp:'8615310808994'
      },
      'c72cqtatcfhg00e3ke6g':{
        employee:'June',
        email:'duanyanran@dserspro.com',
        whatsapp:'8618308195406'
      },
      'c7an47hgn6j000fiiikg':{
        employee:'Ciel',
        email:'ciel@dserspro.com',
        whatsapp:'8617800783063'
      },
      'c7atj4kcqe2g00djfc10':{
        employee:'June',
        email:'duanyanran@dserspro.com',
        whatsapp:'8618308195406'
      },
      'c8s3ciehog6g01m1efn0':{
        employee:'June',
        email:'duanyanran@dserspro.com',
        whatsapp:'8618308195406'
      },
      'bvkkqb2q82pg0090d3r0':{
        employee:'June',
        email:'duanyanran@dserspro.com',
        whatsapp:'8618308195406'
      },
    }

    let discount = pushedList?.discount
    let index = bannerData?.ae_img?.indexOf(";")
    let img = bannerData?.ae_img?.substring(0, index)
    const price = (Number(bannerData?.dsers_price) + Number(bannerData?.dsers_ship_price)).toFixed(2)
    const dsersPrice = (Number(bannerData?.ae_price) + Number(bannerData?.ae_ship_price)).toFixed(2)

    useEffect(()=>{
      if(codesData[userId]){
        setText(`https://wa.me/${codesData[userId]?.whatsapp}`)
      }
      if(!JSON.parse(localStorage.getItem('closeOrderSupplier'))){
        dispatch({
          type: 'global/drawerBannerIfVisible'
        })
      }
    },[])

    const ifShowDrawer = () => {
        if (allshow) {
            SetContainerWidth({ width: '497px' })
        }
        dispatch({
            type: 'global/drawerBannerIfVisible'
        })
    }

    const closeShowDrawer = () => {
      if(!JSON.parse(localStorage.getItem('closeOrderSupplier'))){
        localStorage.setItem('closeOrderSupplier',true)
      }
        if (clickAllShow) {
            SetContainerWidth({})
        }
        dispatch({
            type: 'global/drawerBannerNoVisible'
        })
    }

    const clickAllShow = () => {
        setPercent()
        SetContainerWidth({ width: '497px' })
        setAllShow(true)

    }

    const setPercent = () => {
        let progressValues = 0
        let percentages = 0
        let timer = null;
        if (timer == null) {
            timer = setInterval(() => {
                progressValues += 10;
                // 这里的算法很重要，进度条容器的宽度为 214px 所以这里除以214再乘100就能得到 1-100的百分比了。
                percentages = Math.round(progressValues / 246 * 100);
                if (progressValues >= 246) {
                    percentages = 100;
                    progressValues = 246;
                    clearInterval(timer)
                }
                SetPercentage(percentages)
                SetProgressValue(progressValues)
            }, 60);
        }

    }

    return (
        <>
            <div className={styles.left} onClick={ifShowDrawer}>
                <img className={styles.img} src={bannerimg} alt="" />
            </div>
            {
                <div className={visible ? styles.container : styles.container1} >
                  <div className={styles.closeCon}>
                    <div className={styles.closeDrawer} onClick={closeShowDrawer}><img className={styles.img} src={bannerimg} alt="" /></div>
                  </div>
                  <div className={styles.inContainer}>
                    <div className={styles.headCon}>
                      <img src={headerIocn} alt="" />
                      <div className={styles.userInfo}>
                        <span className={styles.name}>{codesData[userId]?.employee}</span>
                        <span className={styles.email}>{codesData[userId]?.email}</span>
                      </div>
                    </div>
                    <div className={styles.talkCon}>
                      <div className={styles.dialogbox1}>
                        <div className={styles.triangle}></div>
                      Hi there, chat with your account manager for:
                      </div>
                      <div className={styles.dialogbox}>source and quotes</div>
                      <div className={styles.dialogbox}>Hot product recommendation</div>
                      <div className={styles.dialogbox}>Supply Service Optimization Suggestion.</div>
                    </div>
                    <div className={styles.codeCon}>
                      <div className={styles.codebox}>
                        <div className={styles.name}>
                          {codesData[userId]?.employee}
                        </div>
                        <div className={styles.img}>
                          <DsQRCode  text={text} width='60' name='qrcode'/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            }
        </>
    )
}


OrderSupplier.allowPath = allowPath

export default connect(({ global }) => ({
    halfBanner: global.halfBanner,
    visible: global.drawerBanner.visible
}))(OrderSupplier)
