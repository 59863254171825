
    export default 
    {
    "guidance": {
        "welcome": "Benvenuto su Dsers",
        "need_steps": "Puoi essere pronto in 4 step",
        "start_tutorial": "Inizia il tutorial",
        "before_starting": "Prima di iniziare, si prega di assicurarsi di aver DISATTIVATO la funzionalità di riempimento automatico di Shopify.In caso contrario, non sarai in grado di processare ordini su DSers. Vai su Shopify - Impostazioni - Pagina di pagamento , per disattivarlo.",
        "previous": "Precedente",
        "next": "Successivo",
        "start": "Inizia",
        "link_to_more_stores": "Collegati a più negozi",
        "bind_ali_over": "Account di AliExpress inserito nell'elenco dei contatti consentiti",
        "link_to_ae": "Collegati ad AliExpress",
        "home": "Home",
        "my_product": "I miei prodotti",
        "setting": "Impostazione",
        "shipping": "Spedizione",
        "orders": "Ordini",
        "new_feature": "Nuova funzionalità",
        "notification": "Notifica",
        "blog": "BLOG",
        "step1": {
            "title": "Per prima cosa, collega DSers al tuo negozio Shopify",
            "step1_dec": "Clicca il link ad altri negozi in cima alla pagina.",
            "step2_dec": "Seleziona Shopify come piattaforma e inserisci il nome del negozio per collegarlo a DSers."
        },
        "step2": {
            "title": "Poi, collega DSers al tuo account AliExpress.",
            "step1_dec": "Clicca il link ad AliExpress in cima alla pagina.",
            "step3_dec": "Candidati all'elenco dei contatti consentiti di AliExpress. Ti garantirà privilegi e ti permetterà di fare ordini all'ingrosso, riducendo la percentuale di ordini falliti."
        },
        "step3": {
            "title": "Poi, traccia i prodotti a un fornitore AliExpress",
            "dec": "Clicca il pulsante al lato destro della carta prodotto per tracciare i fornitori AliExpress dei tuoi prodotti Shopify. Dopodiché, DSers saprà come processare i tuoi ordini ad AliExpress.",
            "map_multiple": "DSers ti permette di tracciare molteplici fornitori secondo le seguenti caratteristiche:",
            "map_from_to": "1. Spedire da/a Stati",
            "advance": "Controlla i dettagli riguardo al piano di abbonamento <a href=\"https://help.dsers.com/advanced-mapping-for-shopify/\" target=\"_blank\">Avanzato</a> tracciamento",
            "upsell_promotion": "2. Promozione upsell",
            "bogo_bundle": "Controlla i dettagli riguardo a <a href=\"https://help.dsers.com/create-buy-one-get-one-offers-for-shopify/\" target=\"_blank\">BOGO</a> (Buy One Get One free) / PACCO tracciamento"
        },
        "step4": {
            "title": "Non dimenticare di configurare il metodo di spedizione",
            "dec": "Per l'ultimo step di preparazione, si prega di configurare il tuo metodo di spedizione predefinito in Impostazione - Spedizione.Puoi configurarlo in base al tuo Stato o al <a href=\"https://help.dsers.com/set-shipping-method-for-all-products-for-shopify/\" target=\"_blank\">prodotto AliExpress</a> in questione"
        },
        "step5": {
            "title": "Infine, puoi effettuare ordini all'ingrosso",
            "dec": "Seleziona tutti i tuoi ordini nella scheda Ordine in Attesa, poi clicca il pulsante Effettua ordine su AliExpress",
            "tip": "Rivedi i dettagli dell'ordine e il metodo di spedizione. Poi avrai finito!"
        },
        "after": {
            "dec": "Dopo aver eseguito il pagamento, lascia che DSers si occupi del resto. <br /> DSers SINCRONIZZERÀ AUTOMATICAMENTE per te ",
            "p1": "Sincronizza automaticamente lo stato dell'ordine e il numero di tracciamento da AliExpress a DSers ogni 12 ore",
            "p2": "Sincronizza automaticamente i numeri di tracciamento da DSers a Shopify 1-2 ore dopo averle sincronizzate da AliExpress",
            "p3": "Dai il via automatico alla Mail di Conferma di Spedizione Shopify ai tuoi clienti"
        },
        "actions": {
            "title": "Le azioni sottostanti impediranno a DSers di processare o <br /> sincronizzare ordini. Si prega di:",
            "do_not1": "NON evadere ordini manualmente su DSers o Shopify se li processi attraverso DSers.",
            "do_not2": "NON attivare la funzionalità di evasione automatica degli ordini su Shopify.",
            "do_not3": "NON richiedere l'evasione degli ordini su Shopify."
        },
        "get_ebook": "Ottieni un Ebook gratuito o <br />  contatta il servizio clienti qui",
        "check_again": "Dai un'altra occhiata a questo tutorial qui",
        "confused": "Se sei ancora confuso su come usare DSers",
        "guidance_again": "Guarda ancora i video tutorial",
        "order": "How to place multiple orders with DSers?",
        "import": "How to import products to DSers?",
        "total_sales": "Totale vendite",
        "shopify_sales": "Vendite Shopify",
        "ali_orders": "Tutti gli ordini",
        "supplier_cost": "Costi rifornitore"
    }
}