export default {
  'setting-supply': {
    title: 'Supply Service Entrust (SSE)',
    content: `When you activate Supply Service Entrust, DSers will automatically
    replace the supplier of some of your products to optimize your earnings.
    The replacing suppliers will provide higher stable supply,
    wholesale low prices, faster shipping, and more, depending on the product.
    The optimization procedure will be performed by a professional team,
    according to big data analysis. <a href="https://www.blog.dserspro.com/post/introduction-to-supply-service-entrust" target="_blank">Learn more</a>`,
    activated: 'SSE Activated',
    activate: 'Activate',
    sse_agreement: ' SSE Agreement',
    // feedback
    feedback_title: 'You have applied successfully!',
    feedback_con:
      'Your assigned account manager will get back to you <br /> in 1 working day',
    // notice
    notice_title: 'Supply Service Entrust is online !',
    // menu
    supply_tab: 'Mass Supply',
    // modal
    modal_title: 'Thank you for applying for Supply Service Entrust',
    modal_intro:
      'Please fill  in contact email. Your assigned account manager will reach out to you in 1 working day.',
    modal_agreement:
      'By clicking Submit you agree with the <a href="https://www.blog.dserspro.com/post/supply-service-entrust-agreement" target="_blank">SSE agreement</a>'
  }
};
