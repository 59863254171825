import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { connect } from 'dva';
import { Drawer, Button, Progress, Spin, Modal, Popover } from 'antd';

import styles from './less/AdDrawerBanner.less'

import useTopBanner from 'hooks/useTopBanner';

import bannerimg from 'assets/banner/supplier/openarrow.png'
import arrow from 'assets/banner/supplier/arrow.png'
import { v5Map, list_V5 } from 'utils/bannerWhiteList';
import { Get, Post } from 'utils/request';
import moment from 'moment';

const allowPath = [
    '/order/list',
    '/supplier_optimizer'
];

function AdDrawerBanner({ path, userInfo, userId, halfBanner, visible, bannerData, pushedList }) {
    const [hover, setHover] = useState(false)
    const [change, setChange] = useState(false)
    const [allshow, setAllShow] = useState(false)
    const [containerWidth, SetContainerWidth] = useState({})
    const [percentage, SetPercentage] = useState(0)
    const [progressvalue, SetProgressValue] = useState(0)
    const [modalVisible, SetModalVisible] = useState(false)
    const [finish, setFinish] = useState(false)
    const [confirmLoading, setConfirmLoading] = useState(false)

    const dispatch = useDispatch()

    let discount = pushedList?.discount
    let index = bannerData?.ae_img?.indexOf(";")
    let img = bannerData?.ae_img?.substring(0, index)
    const price = (Number(bannerData?.dsers_price) + Number(bannerData?.dsers_ship_price)).toFixed(2)
    const dsersPrice = (Number(bannerData?.ae_price) + Number(bannerData?.ae_ship_price)).toFixed(2)

    useEffect(() => {
        const getAdId = async () => {

            if (!bannerData?.ae_product_id) {
                setFinish(true)
            } else {
                try {
                    const res = await Get(
                        `/prod/propool/spread/shopify?ae_product_id=${bannerData?.ae_product_id}`
                    );
                    if (res && res.code === 2000 && res.data) {
                        setFinish(false)
                    } else {
                        setFinish(true)
                    }
                } catch (error) {
                    console.log('fetch error:', error);
                }
            }
        }

        getAdId()

    }, [bannerData])

    const ifShowDrawer = () => {
        if (allshow) {
            SetContainerWidth({ width: '497px' })
        }
        dispatch({
            type: 'global/drawerBannerIfVisible'
        })

        if (!finish) {
            setDot('spread')
        }
    }

    const closeShowDrawer = () => {
        if (clickAllShow) {
            SetContainerWidth({})
        }
        dispatch({
            type: 'global/drawerBannerNoVisible'
        })

        if (!finish) {
            setDot(allshow ? 'spreadClose' : 'halfClose')
        }
    }

    const clickAllShow = () => {
        setPercent()
        SetContainerWidth({ width: '497px' })
        setAllShow(true)

        setDot('apply')
    }

    const setWidthFalse = () => {
        setChange(false)
    }

    const showModal = () => {
        SetModalVisible(true)

        setDot('switch')
    }

    const modalYes = async () => {
        setConfirmLoading(true)
        try {
            const res = await Post('/prod/up_bind', {
                data: [{
                    store_id: bannerData?.store_id,
                    sp_product_id: bannerData?.shopify_product_id
                }]
            })
            if (res && res.code === 2000) {
                setConfirmLoading(false)
                SetModalVisible(false)
                // closeShowDrawer()
                setFinish(true)
            }
        } catch (error) {
            console.log('fetch error:', error)
        }

        setDot('yes')
    }

    const closeModal = () => {
        SetModalVisible(false)

        setDot('later')
    }

    const setPercent = () => {
        let progressValues = 0
        let percentages = 0
        let timer = null;
        if (timer == null) {
            timer = setInterval(() => {
                progressValues += 10;
                // 这里的算法很重要，进度条容器的宽度为 214px 所以这里除以214再乘100就能得到 1-100的百分比了。
                percentages = Math.round(progressValues / 246 * 100);
                if (progressValues >= 246) {
                    percentages = 100;
                    progressValues = 246;
                    clearInterval(timer)
                }
                SetPercentage(percentages)
                SetProgressValue(progressValues)
            }, 60);
        }

    }

    const content = (
        <div>
            <img src={img} alt="" style={{width:"100%"}}/>
        </div>
    )

    const setDot = (buttonType) => {
        window.DSERS_CUSTOM_COLLECT({
            action: 'onekeyMapping',
            custom_info: [{ name: 'ButtonType', value: buttonType }, { name: 'clickTime', value: moment(+new Date()).format('YYYY-MM-DD,h:mm:ss') }]
        })
    }

    return (
        <>
            <div className={styles.left} onClick={ifShowDrawer}>
                <img className={finish ? styles.img : styles.img1} src={bannerimg} alt="" />
            </div>
            {
                !finish ?
                    <div className={visible ? styles.container : styles.container1} style={containerWidth}>
                        <div className={styles.container_left}>
                            <div className={styles.top}>
                                <div className={styles.top_left}>
                                    <div>Business Intellingence (BI)</div>
                                </div>
                                {allshow ? null : <div onClick={closeShowDrawer} className={styles.top_right}><img src={arrow} alt="" /></div>}
                            </div>
                            <div className={styles.middle}>
                                <div className={styles.img}>
                                    <Popover content={content} trigger="hover" overlayStyle={{width:'200px',height:'200px'}} placement="left">
                                        <img src={img} alt="" />
                                    </Popover>
                                    <div className={styles.imgTitle}>
                                        {bannerData?.ae_product_title}
                                    </div>
                                </div>
                                <div className={styles.country}>
                                    <div>Country</div>
                                    <div>{bannerData?.country} <span className={styles.smallicon}></span></div>
                                </div>
                                <div className={styles.shipping} >
                                    <div>shipping</div>
                                    <div style={{ fontSize: '12px' }}>{bannerData?.ae_ship_method} <span className={styles.smallicon}></span></div>
                                </div>
                                <div className={styles.income}>
                                    <div>Total Cost</div>
                                    <div>US ${dsersPrice}</div>
                                </div>
                            </div>
                            <div className={styles.bottom}>
                                <div>
                                    {percentage == 0 ? 'Apply for Centralized Fulfillment Service' : percentage == 100 ? 'Service provided by Dsers' : 'Processing,it takes a couple seconds'}
                                </div>
                                {allshow ? <Progress percent={percentage} style={{ marginLeft: '20px', marginTop: "10px" }} strokeWidth={12} strokeColor={percentage == 100 ? null : '#FF8F00'} /> : (
                                    <div className={styles.button} onClick={clickAllShow}>
                                        <Button>APPLY</Button>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className={styles.container_right}>
                            <div className={styles.rightTop}>
                                <div onClick={closeShowDrawer}><img src={arrow} alt="" /></div>
                            </div>
                            <div className={styles.rightMiddle}>
                                {percentage == 100 ?
                                    (<>
                                        <div className={styles.img}></div>
                                        <div className={styles.country}>
                                            <div>{bannerData?.country}<span className={styles.smallicon}></span></div>
                                        </div>
                                        <div className={styles.shipping}>
                                            <div style={{ fontSize: '12px' }}>{bannerData?.ae_ship_method}<span className={styles.smallicon}></span></div>
                                        </div>
                                        <div className={styles.income} style={{ justifyContent: 'end' }}>
                                            <div>US ${price}</div>
                                        </div>
                                    </>) : <Spin size="large" className={styles.spin} />}
                            </div>
                            <div className={styles.rightBottom}>
                                {percentage == 100 ? (
                                    <div className={styles.title}>
                                        -{discount}% off
                                    </div>
                                ) : null}
                                <div className={styles.button}  >
                                    <Button style={percentage == 100 ? { width: '157px' } : { backgroundColor: '#E0E0E0', borderColor: '#E0E0E0', width: '157px' }} disabled={percentage == 100 ? null : true} onClick={showModal}>SWITCH</Button>
                                </div>
                            </div>
                        </div>
                        <div className={styles.line}></div>
                    </div>
                    :
                    <div className={visible ? styles.container : styles.container1} >
                        <div className={styles.inContainer}>
                            <div className={styles.finishImg}><img src={arrow} onClick={closeShowDrawer} alt="" /></div>
                            <div className={styles.finishTop}>Congratulations!</div>
                            <div className={styles.finishMiddle}>
                                <div style={{ marginBottom: '12px' }}>Your supply service is now processed by our Centralized Fulfillment Center.</div>
                                <div style={{ marginBottom: '12px' }}>Lower cost is applied.</div>
                                <div>  Thank you for your trust.
                                    Feel free to contact us anytime at</div>
                            </div>
                            <a className={styles.finishEmail} href={'mailto:issac@dserspro.com'}>issac@dserspro.com</a>
                            <div><i class="material-icons" style={{ color: '#4CAF50', fontSize: '50px', marginTop: "10px" }}>check_circle</i></div>
                        </div>
                    </div>
            }
            <Modal title="Centralized Fulfillment Center" visible={modalVisible} okText='YES' cancelText='LATER' style={{ top: '239px' }} onOk={modalYes} onCancel={closeModal} maskClosable={false} confirmLoading={confirmLoading}>
                <p>Ready to enjoy lower costs and better service?</p>
                <ul>
                    <li>Your orders will be shipped from the centralized fulfillment center </li>
                    <li>Item will be re-mapped on DSers with a new link automatically</li>
                    <li>A Supply Account Manager will be assigned to you</li>
                </ul>
            </Modal>
        </>
    )
}


AdDrawerBanner.allowPath = allowPath

export default connect(({ global }) => ({
    halfBanner: global.halfBanner,
    visible: global.drawerBanner.visible
}))(AdDrawerBanner)