import React, { useEffect, useRef } from 'react';
import { CKEditor } from 'ckeditor4-react';
import intl from 'react-intl-universal';


function EditorComponent({
  onChange,
  initContent,
  changeHiddenStatus,
  disabled
}) {
  const htmlRef = useRef(null);
  useEffect(() => {
    htmlRef.current = initContent;
  }, []);
  useEffect(() => {
    changeHiddenStatus();
  }, [onChange]);
  return (
    <CKEditor
      initData={initContent}
      readOnly={disabled}
      editorUrl={'https://img.dsers.com/global/js/ckeditor/ckeditor.js'}
      onBeforeLoad={CKEDITOR => {
        CKEDITOR.on('instanceReady', function(ev) {
          ev.editor.on('doubleclick', function(evt) {
            return false;
          });
        });
        if(CKEDITOR.plugins.registered.removeImg){
          return
        }
        CKEDITOR.plugins.add('removeImg', {
          init: function(editor) {
            editor.addCommand('insertRemoveImg', {
              exec: function(editor) {
                const ckHtml = editor.document
                Array.from(ckHtml.$.images).forEach(i=>{
                  i.remove()
                })
                console.log('editor',ckHtml);
              }
            });
            editor.ui.addButton('Timestamp', {
              label: intl.get('product.remove_all_imgs'),
              command: 'insertRemoveImg',
              toolbar: 'insert',
              icon:
                'https://img.dsers.com/icon/delete_img.png'
            });
          }
        });
      }}
      config={{
        width: 960,
        height: 600,
        toolbarGroups: [
          { name: 'document', groups: ['mode', 'document', 'doctools'] },
          { name: 'clipboard', groups: ['clipboard', 'undo'] },
          { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
          { name: 'forms' },
          { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
          {
            name: 'paragraph',
            groups: ['list', 'indent', 'blocks', 'align', 'bidi']
          },
          { name: 'links' },
          { name: 'insert' },
          { name: 'styles' },
          { name: 'colors' },
          { name: 'tools' },
          { name: 'others' },
          { name: 'Timestamp' }
        ],
        extraPlugins: 'removeImg',
        contentsCss:
          'https://img.dsers.com/woocommerce/CKEditor/CKEditorConfigContentsCss.css'
      }}
      // onAfterCommandExec={(e) => {
      //   console.log(e)
      //   if(e?.data?.name === "removeFormat") {
      //     e?.editor?.ui?.items?.Format?.onClick('p')
      //   }
      // }}
      onChange={({ editor }) => {
        const data = editor.getData();
        if (onChange) {
          onChange(data);
        }
      }}
    />
  );
}

export default EditorComponent;
