export default {
  beforeMigration: {
    shopify: 'Nur die Shopify (oder CSV) Standortseinstellungen behalten.',
    wooCommerce: 'Nur die WooCommerce Standortseinstellungen behalten.',
    wix: 'Nur die Wix Standortseinstellungen behalten.',

    title: 'Das System wird bald vollständig aktualisiert!',
    title1: 'System-Upgrade nähert sich',

    one: 'DSers wird am ',
    two: 'aktualisiert und ist für bis zu 24 Stunden nicht verfügbar',
    there: 'Bitte passen Sie Ihren Zeitplan entsprechend an',

    four:
      'Um Ihnen ein besseres Benutzererlebnis zu bieten, wird DSers das System für einen Zeitraum von',

    five: '24 Stunden ab dem',

    fiveDe: 'aktualisieren',

    six:
      'DSers wird während dieser Zeit nicht verfügbar sein. Wir entschuldigen uns für die Unannehmlichkeiten.',

    seven: 'Klicken Sie ',
    seven1: 'hier,',

    //---------
    eight: ' um mehr zu erfahren.',

    nine: 'um weitere Informationen zu erhalten.',



    ten:
      'Um Ihnen ein besseres Benutzererlebnis zu bieten, wird DSers das System für einen Zeitraum von',
    eleven:
      'DSers wird während dieser Zeit nicht verfügbar sein. Wir entschuldigen uns für die Unannehmlichkeiten.',
    twelve:
      'Das Upgrade dient dazu, das Multiplattform-Management zu vereinfachen. Dies kann zu Einstellungsproblemen führen, wenn Sie Geschäfte auf mehreren Plattformen verwalten. Die Kompatibilität der Unterschiede jeder Plattform wird vollständig berücksichtigt. Bitte teilen Sie uns trotzdem Ihre Präferenzen mit, nur für den Fall.',
    thirteen:
      'Wie möchten Sie potenziell konfliktierende Einstellungen in den Einstellungen nach dem Upgrade zusammenführen?',

    fourteen:
      'Nach der Fusion werden die Kontoeinstellungen zwischen den beiden Standorten konfliktieren, daher müssen Sie auswählen, welchen Standort Sie behalten möchten. Die Einstellungen, die konfliktieren werden, sind:',

    fifteen: 'Benachrichtigung',
    sixteen: 'Mitarbeiterkonto',
    seventeen: 'Versand',
    eighteen:
      'Hinzufügen einer globalen Versandmethode basierend auf dem Produkt',
    nineteen: 'Nachricht an Lieferanten hinterlassen',
    twenty: 'Telefonnummer',
    twentyOne: 'Optimierung stornierter Bestellungen',
    twentyTwo: 'Und andere Funktionen',

    twentyThere:
      'Aber keine Sorge, wir werden die Einstellungen auf Ladenebene zwischen den beiden Standorten weiterhin für Sie behalten, wie zum Beispiel:',

    twentyFour: 'Preisregel',

    twentyFive: 'Paketverfolgung',

    twentySix: 'Währungen',

    twentySeven: 'Das System wird gerade aktualisiert und dies',

    twentyEight: 'wird etwa 24 Stunden dauern',

    twentyNine:
      'DSers wird während des Upgrade-Zeitraums ausgesetzt sein, und wir entschuldigen uns für eventuelle Unannehmlichkeiten.',

    twentyTen:
      'Entschuldigung, das System wird derzeit aktualisiert und kann nicht gebunden werden, bitte versuchen Sie es später erneut.',

    notification: 'Benachrichtigung',

    submit: 'Bestätigen'
  }
};
