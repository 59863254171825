const getUserInfo = () => {
  let store = null;

  try {
    const storeStr = localStorage.getItem('store');
    store = storeStr && JSON.parse(storeStr);
  } catch (error) {
    console.log('getUserInfo error:', error);
  }

  return store;
};

export const getStoreId = () => {
  let id = null;

  try {
    id = localStorage.getItem('storeId');
  } catch (error) {
    console.log('getStoreId error:', error);
  }

  return id;
}

export default getUserInfo;
