import agency from './agency';


export default {
  setting: {
    tab_account: 'Compte', // add
    tab_package_tracking:"Package Tracking",
    tab_sub_account:'Compte Employé',
    tab_sub_supplier:'Fournisseur',
    tab_stores:'Boutiques',
    tab_product:'Produit',
    tab_pricing_rule: 'Règles de prix',
    tab_shipping: 'Livraison',
    tab_order:'Commandes',
    tab_paypal:'PayPal',
    tab_tracking: 'Confirmation d’envoi',
    tab_notification: 'Notification',
    tab_sync:'Auto Update',
    tab_aff:'Programme affilié',
    tab_billing_balance: 'Billing Balance', // d
    tab_verified_supplier: 'Verified Supplier', // d
    tab_report: 'Rapport',
    tab_other: 'Autre',
    choose_country_placeholder: 'Choisir un pays',
    save: 'Sauvegarder',
    account: {
      // add
      account_info: 'Info compte',
      account_new_info:'Changer Nom de Compte',
      account_name: 'Nom du compte',
      account_email: 'Email du compte',
      change: 'Changer',
      change_email_desc:
        'Cet email vous sert à vous connecter. Nous utiliserons cet email pour vous contacter à propos de votre compte',
      new_email: 'Nouvelle adresse email',
      new_email_desc:
        "Nous enverrons un code de vérification à cette nouvelle adresse email. Veuillez le copier et l'entrer ci-dessous pour vérifier votre email",
      new_email_err: 'Veuille entrer une adresse email avec le bon format',
      account_name_err: `Veuillez entrer un nom d'utilisateur compris entre 5 et 20 caractères`,
      verify_code: 'Entrer code pour vérifier',
      verify_code_desc:
        "Copier le code de l'email de vérification et entrez le ici pour vérifier",
      verify_code_err: 'Mauvais code, veuillez vérifier',
      reset_password: 'Réinitialiser mot de passe',
      reset_password_desc:
        'Change le mot de passe utilisé pour vous connecter.',
      change_password: 'Changer mot de passe',
      shopify_stores: 'Boutiques Shopify',
      shopify_stores_desc:
        "Vous pouvez gérer vos boutiques Shopify ici. Si vous souhaitez désinstaller DSers d'une boutique, n'oubliez pas de déconnecter ici également.",
      shopify_stores_re_authorized:
        "Cette boutique doit être ré-autorisé. Veuillez mettre à jour l'autorisation ou vous ne pourrez pas opérer votre boutique avec DSers",
      shopify_stores_tip:
        "Nous avons mis à jour l'autorisation Shopify. Veuillez cliquer pour la renouveller sur Shopify ou vous ne pourrez pas traiter vos commandes.",
      store_name: 'Nom de boutique',
      status: 'Statut',
      connected: 'Connecté',
      noData:'Pas de boutique liée',
      disconnect: 'Déconnecter',
      ae_account: 'Compte AliExpress',
      ae_account_desc:
        'You pouvez déconnecter ou changer le compte AliExpress lié ici.',
      account_id: 'ID Compte',
      change_account_title: 'Changer Boutique de Facturation',
      change_account_content:
        'Vous êtes sur le point de définir {choiceLabel} comme Boutique de Facturation. Pour une meilleure expérience sur la plate-forme DSers, veuillez vous assurer que la boutique est toujours ouverte',
      switch: 'Changer',
      ae_account_tip:
        'Veuillez noter que si vous liez à un compte différent, les informations des commandes placées via le compte actuel ne seront plus synchronisés vers DSers après la déconnection',
      click_to_renew: 'Renouveler',
      disconnect_ae_tip1:
        'Êtes-vous sûr de vouloir déconnecter votre compte AliExpress? Les informations des commandes placées sur le compte lié actuellement <i>ne seront plus mises à jour</i> sur DSers après déconnection.',
      disconnect_ae_tip2:
        "Après la déconnection, <i>les données de votre compte disparaitront temporairement.</i> Elles s'afficheront de nouveau lorsque que vous lierez votre nouveau compte AliExpress.",
      disconnect_ae_confirm: 'Entrez CONFIRMER pour déconnecter votre compte.',
      reconnect_ae:
        'Veuillez vous assurer de reconnecter le même compte. Sinon, les informations des commandes placées sur le compte lié actuellement <i>ne seront plus mises à jour</i> sur DSers après déconnection.',
      switch_ae:
        'Êtes-vous sûr de vouloir changer de compte AliExpress? Les informations des commandes placées sur le compte lié actuellement <i>ne seront plus mises à jour</i> sur DSers après déconnection.',
      open_mailbox: 'Ouvrir messagerie',
      send_again: 'Envoyer de nouveau',
      send_code: 'Envoyer cpde',
      verify: 'Vérifier',
      store_name_tip:
        'DSers affichera le nom original de la boutique quand vous avez lancé votre boutique sur Shopify et ne le mettre pas à jour, même si vous le modifier sur Shopify. Veuillez noter que le nom de la boutique ne sera pas communiqué à vos clients.',
      verify_email_title: 'Vérifier votre adresse email',
      verify_email_title_succ: 'Email de vérification envoyé',
      verify_email_title_fail: 'Verification email delivery failed',
      verify_email_desc:
        'Afin de sécuriser votre compte, veuillez vérifier votre adresse email. Nous avons envoyé un email de vérification à {email}',
      verify_email_desc_succ: `Veuillez vérifier votre adresse email en suivant le lien dans l'email qui vous a été envoyé à {email}`,
      verify_send: 'Envoyer email de vérification',
      verify_re_send: 'Renvoyer email de vérification',
      white_list: 'Liste blanche',
      white_listed: 'Listé',
      code_verify: `Code nécessaire`,
      jiabai_intro: `Ajoutez votre compte AliExpress à la liste blanche`,
      authorize_name: 'Mises à jour',
      authorize_tips:'Mise à jour des Autorisations de Shopify Il semble que les Autorisations de Fonctionnalités de DSers ne soient pas à jour. Veuillez les mettre à jour en quelques clics pour vous assurer que DSers peut fonctionner correctement.'
    }, //  add
    shipping: {
      title: 'Livraison',
      description:
        'Définissez vos méthodes de livraison pour un pays particulier.',
      turn_off: 'Désactiver',
      turn_on: 'Activer',
      save_succ: 'Sauvegarde réussie',
      save_failure: 'Échec de la sauvegarde',
      advanced: {
        title: 'Avancé',
        description:
          'Définissez vos méthodes de livraison par durée de livraison ou prix.',
        tracking_information_available: 'Information de suivi Disponible',
        delivery_period: 'Durée de livraison',
        delivery_period_tip: 'durée maximum acceptable de livraison',
        delivery_period_error: 'Veuillez entrer le nombre de jour.',
        days: 'Jours',
        shipping_cost: 'Coût de livraison',
        shipping_cost_error: 'Veuillez entrer le prix.',
        tip: 'Quand plusieurs options correspondent à mes conditions',
        i_need: 'je veux',
        less_cost: 'la moins chère',
        less_day: 'la plus rapide'
      },
      general: {
        title: 'Ajouter une autre méthode de livraison globale',
        description:
          "Définissez vos méthodes de livraison pour tous les pays. Le système choisira une méthode de livraison en fonction de vos choix quand vous placez une commande. L'option 1 sera prioritaire.",
        placeholder: "Ajouter d'autres méthodes de livraison",
        option: 'Option'
      },
      product: {
        title: 'Ajouter une méthode de livraison basé sur un produit',
        dec:
          'Définissez votre méthode de livraison par défaut pour un produit AliExpress spécifique. Le choix de méthode de livraison pour un produit a la priorité comparé au choix de méthode de livraison par pays.',
        placeholder: 'Recherche par titre de produit du fournisseur',
        modal_placeholder:
          'Recherche par mots-clés du titre du produit du fournisseur',
        tip: 'Seuls les produits déjà mappés peuvent être trouvé ici',
        shortcut_open: `Vous pouvez accéder à cette fonction dans Mapping - Plus d'action`
      }
    },
    tracking: {
      title: 'Suivi',
      click_to_renew: 'Click to Renew',
      renew_reminder:
        'Votre compte PayPal a expiré. Pour ne pas affecter la synchronisation des numéros de suivi, veuillez ré-autoriser votre PayPal',
      description:
        "Déclencher le mail de confirmation d'expédition pour les différentes boutiques.",
      activate_email:
        "Activer le déclenchement automatique du mail de confirmation d'expédition envoyé par Shopify",
      activate_email_description:
        "Activer cela pour envoyer le mail de confirmation d'envoi de Shopify à votre client lorsque le numéro de suivi a été généré. Vous pouvez éditer ce mail",
      here: 'ici',
      activate_email_tip:
        "Cette fonctionnalité vous permettra de personnaliser comment le transporteur et le numéro de suivi apparaitront dans le mail de confirmation d'expédition de Shopify.",
      shipping_carrier: 'Transporteur',
      custom_tracking_url: 'URL de Suivi Personnalisé',
      custom_tracking_url_tip:
        "Veuillez noter: Nous ajouterons le numéro de suivi à la fin de l'URL, si vous souhaitez utiliser une URL personalisée tel que http://www.17track.net/en/track?nums=1234567 ; n'entrez que http://www.17track.net/en/track?nums= , soit l'URL mais sans numéro de suivi à la fin",
      please_enter_a_url: `Veuillez entrer l'URL de suivi`,
      add_a_shopify_store: 'Ajouter une boutique Shopify',
      save_succ: 'Sauvegarde Réglage Réussi.',
      save_fail: 'Sauvegarde Réglage Échoué.',
      track_to_paypal_title: 'Synchroniser numéro de suivi vers PayPal',
      track_to_paypal_title1: 'Gestion compte PayPal',
      track_to_paypal_desc:
        'Autoriser DSers pour ajouter le numéro de suivi à vos transactions PayPal.',
      connect_to_paypal2: 'Appuyez sur le bouton pour vous connecter à PayPal',
      connect_to_paypal: 'Connecter à PayPal',
      connect_to_paypal_desc: `Pour s'assurer que le Numéro de Suivi sera synchronisé normalement, veuillez autoriser le lien de votre compte PayPal à votre boutique Shopify`,
      not_succ: `L'aurisation n'a pas réussie. <br /> Veuillez réessayer.`,
      use_paypal_account: 'Ou utiliser un compte PayPal autorisé:',
      to_connect_paypal_account: 'Sélectionnez un compte PayPal',
      paypal_placeholder: 'Choisir un compte',
      paypal_reminder: 'Veuillez autoriser le compte PayPal ci-dessus',
      linked_paypal: 'Vous êtes lié au compte PayPal:',
      disconnect: 'Déconnecter',
      disconnect_tip:
        'Êtes-vous sûr de vouloir déconnecter votre compte PayPal? Veuillez noter que les numéros de suivi ne seront plus mis à jour sur PayPal après déconnection.',
      update: 'Mettre à jour',
      refresh_page: 'Veuillez rafraichir la page pour mettre à jour',
      select_1: `Sélectionnez un transporteur`,
      select_2: `Sélectionnez un lien de suivi URL personalisé`
    },
    notification: {
      title: 'Notification',
      description:
        'Si vous activez cette fonction, DSers suivra les changements de stock des produits, le changements de prix, les commandes annulées par AliExpress, les changements de SKU et les notifications seront envoyées dans votre compte DSers.',
      inventory: 'Inventaire',
      inventory_description:
        'Être notifié quand un produit est en rupture de stock', // m
      price: 'Prix',
      price_description:
        'Être notifié quand un produit change de prix sur AliExpress',
      canceled_order: 'Commande annulée',
      canceled_order_description:
        'Être notifié quand une commande est annulée sur AliExpress',
      sku: 'Changement de SKU',
      sku_description: `Être notifié quand le SKU d'un produit change sur AliExpress`,
      order: 'Order', // d
      order_description: 'Get notified when orders are canceled on AliExpress', //  d
      tip: `Les notifications DSers seront activées si vous cochez les cases correspondantes.`,
      auto_updates: 'Mise à jour automatique du stock', //  a
      auto_updates_tip:
        'Les mises à jour ne seront pas appliquées aux produits avec de multiples fournisseurs utilisant le Mapping Avancé, BOGO ou Bundle', //  a
      out_of_stock:
        'Quand un produit ou une variante est en rupture de stock ou indisponible, vous voulez que DSers', //  a
      do_nothing: 'Ne fasse rien', //  a
      set_as_draft:'Définir comme brouillon',
      product_out:'Quand un produit est en rupture de stock ou indisponible, vous voulez que DSers',
      variant_out:'Quand une variante est en rupture de stock ou indisponible, vous voulez que DSers',
      set_quantity_to_zero_on_Shopify:'Remettre la quantité à zéro sur Shopify',
      remove_Variant:'Supprimer la variante',
      set_quantity_to_zero_on_Shopify:'Remettre la quantité à zéro sur Shopify',
      to_zero: 'Remettre la quantité à zéro sur Shopify', //  a
      inventory_change:
        "Quand l'inventaire du produit change, vous voulez que DSers", //  a
      update_to_shopify: 'Mettre à jour sur Shopify', //  a
      notification_feature: `Fonction de notification`
    },
    priceUpdate: {
      suppliers_price:`When your suppliers price`,
			increase: "augmenter",
			decrease:"diminuer",
      pricing_change:`And the price's change exceeds`,
      anychange:`Any changes`,
      title: 'Mise à jour automatique des prix',
      auto_updates_tip: "Après avoir activé la fonction de mise à jour automatique des prix, DSers suivra les modifications du coût du produit du fournisseur et synchronisera automatiquement le prix mis à jour avec Shopify en fonction des règles de prix que vous avez définies.",
      note: 'Veuillez noter: Vous devez activer la fonction Règle de prix pour utiliser cette fonction. Si vous modifiez le prix dans Shopify, nous mettrons toujours à jour le prix sur Shopify en fonction des règles de prix que vous avez définie sur DSers. Plage de mise à jour : 10 jours de produits commandés & produits consultés',
      supplier: 'Quand un fournisseur change de prix, vous voulez que DSers:',
      updateCon: 'Mette à jour le prix et le prix comparé sur Shopify',
      do_nothing: 'Ne fasse rien',
      to_shopify: 'Mette à jour le prix sur Shopify',
      cost_change: 'Mette à jour le prix quand le coût change',
      to_setting: `Mise à jour de prix automatique après changement de coût<a style="text-align:end"  href="/app/settings?id=11">Vous pouvez activer cela dans Réglages</a>`,
      to_price: "Cette boutique n'a pas de règles de prix",
      check_error: 'Veuillez vérifier la méthode de fluctuation des prix',
      range: 'La plage ne peut être 0',
      priceError: 'La boutique est déconnectée, rendez-vous dans Réglages - Compte pour la reconnecter',
    },
    product: {
      customize_title: 'Personnaliser la description du produit',
      customize_con: 'Lorsque vous envoyez des produits, vous souhaitez que les DSers :',
      shipping_cost_title:'Envoyer les informations des produits à Shopify',
      customize_specifications: "N'envoyez que le produit SPECIFICATIONS",
      customize_overview: "Veuillez envoyer uniquement l'aperçu du produit.",
      customize_all: "Envoyer SPÉCIFICATIONS & VUE D'ENSEMBLE",
			location_title:"Gestion service de traitement des produits",
			location_content:"Vous pouvez décider quel service de traitement pour les produits et les commandes la plate-forme DSers vous aidera à gérer via le bouton de service de traitement. Si vous activez le bouton, il vous aidera à mettre à jour automatiquement les informations sur le produit correspondant au service de traitement et à afficher normalement les commandes correspondant à ce service de traitement. Si vous le désactivez, il ne mettra pas automatiquement à jour les informations sur le produit correspondant au service de traitement pour vous, et il n'affichera pas les commandes correspondant à ce service de traitement.",
      shipping_cost_tip1:'Envoie le coût des produits du fournisseur par article à Shopify lorsque vous envoyez le produit.',
      shipping_cost_tip2:`Lorsque le produit est envoyé, le coût des produits du fournisseur et les frais de livraison dans [Règle de prix avancée] sont renvoyés ensemble au coût par article de Shopify.<br />
      Il peut y avoir certaines raisons pour lesquelles les frais de livraison sont de 0:<br />
      1. Après avoir appliqué la règle de prix avancée, la méthode d'expédition correspondant au pays est gratuite et les frais de livraison renvoyés à Shopify sont actuellement de 0.<br />
      2. Une fois la règle de prix avancée appliquée, aucune méthode de livraison ne correspond en conséquence. À l'heure actuelle, les frais de livraison retournés à Shopify sont de 0.<br />
      3. Après avoir appliqué la règle de prix de basique/standard, il n'y a pas de frais de livraison et les frais de livraison renvoyés à Shopify sont de 0.<br />
      4. La règle de prix n'est pas activée et il n'y a pas de frais de livraison. À l'heure actuelle, les frais de livraison retournés à Shopify sont de 0.<br />
      Vous devez mettre à niveau votre plan et appliquer la règle de prix avancée avant que DSers puisse vous aider à retourner le coût du produit et les frais de livraison à Shopify.`
    },
    pricing: {
      title: 'Règle des prix',
      description:
        "La tarification s'appliquera automatiquement lorsque vous envoyer un produit depuis Dsers vers Shopify. Cliquez <a href={href} target='_blank'>ici</a> pour en apprendre plus.",
      enabled: 'Activé',
      disabled: 'Désactivé',
      pricing_cost: 'Coût Tarification(USD)',
      price: 'Prix',
      price_tip:
        "Ex: Le prix d'un produit coutant 10 USD sera défini à 12 (si vous ajouter 2 au coût) ou 20 USD (si vous multipliez le coût par 2)",
      compared_at_price: 'Prix Comparé',
      compared_at_price_tip: `E.g. The compared price of product costing 10 USD will be set at 15 or 50 USD (if you add 5 to cost, or multiply it by 5). <br/> Si vous ne cochez pas cette option, la valeur du prix comparé sera vide quand vous envoyez les produits vers votre boutique Shopify.`,
      compared_ar: `Si vous ne cochez pas cette option, la valeur du prix comparé sera vide quand vous envoyez les produits vers votre boutique Shopify.`,
      price_no: `SI vous appliquez le prix comparé quand vous envoyez les produits puis désactivez cette option, le prix comparé ne sera pas supprimé quand vous changer manuellement le prix du produit dans la page de notification de DSers.`,
      set_rules: 'Définissez vos règles de tarification pour le prix comparé',
      basic: 'Basic',
      advanced: 'Avancé',
      standard: 'Standard',
      formula_text_1:
        "Prix = [(Coût du Produit + Frais d'Expédition) × (1+ % de Bénéfice) + Bénéfice Fixe] / (1 - % de seuil de rentabilité)",
      formula_text_2: 'Comparaison des prix = Valeur du prix × / ＋ votre numéro de réglage',
      formula_text_reminder:
        "PS : Si le Bénéfice Final ((Coût du Produit + Coût d'Expédition) × % de Bénéfice + Bénéfice Fixe) est inférieur au Bénéfice Minimum que vous avez défini, alors la formule suivante sera appliquée : Prix = [(Coût du Produit + Coût d'Expédition) + Bénéfice Minimum] / (1 - % de seuil de rentabilité)",
      advanced_rest_price: 'Rest of the ranges',
      advanced_cost_range: 'Produit Plage Coût(USD)',
      profit: 'profit%',
      fixed_profit: 'Profit Fixe',
      min_profit: 'Profit Minimum',
      breakeven: 'Rentabilité%',
      shippingCostReminder: `Le coût de livraison sera calculé en fonction de la méthode de livraison sélectionnée dans Réglages coût de livraison.`,
      breakevenReminder:
        'Choisissez un niveau de rentabilité pour couvrir les frais de publicité, de paiements, et autres',
      shipping_cost: 'Coût Livraison',
      add_shipping_cost: 'Coût Livraison',
      cost_range: 'Intervalle de Coût(USD)',
      compared_price: 'Prix Comparé',
      rest_price: 'Reste des intervalles de coût',
      basic_more: 'Cliquez <a href="https://help.dsers.com/basic-pricing-rule-for-shopify/" target="_blank">ici</a> pour en apprendre plus.',
      standard_more: 'Cliquez <a href="https://help.dsers.com/standard-pricing-rule-for-shopify/" target="_blank">ici</a> pour en apprendre plus.',
      advanced_more: 'Cliquez <a href="https://help.dsers.com/advanced-pricing-rule-for-shopify/" target="_blank">ici</a> pour en apprendre plus.',
      shipping_title: 'Réglages coût de livraison',
      shipping_reminder: `Nous calculerons le coût de livraison en fonction de la méthode de livraison choisie. Vous pouvez également entrer un coût de livraison moyen spécifique si vous livrer vers différents pays.`,
      product_cost_setting: 'Coût du produit',
      product_cost_settingVal: 'Le coût du produit sera fixé sur la base du prix national correspondant à la destination "Expédier vers" du produit. Pour plus de détails, veuillez <a href="https://help.dsers.com/import-product-cost-for-shopify/"  style="color:#3D5AFE" >cliquer ici</a>',
      product_cost_reminder: `Quand vous importez le produit et dans les notifications, nous calculerons le coût du produit et vous notifierons les changements en fonction du pays choisi. Cela ne sera pris en compte qu'après le réglage, et les données précédentes ne seront pas changés (nous supporterons plus de pays dans le futur).`,
      fixed_cost: 'Coût de livraison spécifique',
      ranges_overlap: 'Vos intervalles se chevauchent.',
      ranges_start_greater_end:
        "La valeur finale de l'intervalle de coût doit être supérieure ou égale à la valeur de départ.",
      assign_cents: 'Réglages Centimes',
      assign_cents_description:
        'Vous pouvez entrer le nombre de centimes désirés pour le prix de vente final. Nous utiliserons ce chiffre pour fixer le prix final de vos produits (ex: Si vous souhaitez que le prix de vos produits soit XX.99, entrez 99 dans le champs ci-dessous).',
      assign_compared_at_cents: 'Réglages Centimes Prix Comparés',
      need_link_store: "Vous devez d'abord lier une boutique Shopify.",
      save_succ: 'Sauvegarde réussie',
      currency:'Devise',
      apply_go:`Appliquer le taux de devise aux Règles de Prix quand vous envoyez des produits vers Shopify. Si vous souhaitez activer cette fonction, rendez-vous dans`,
      apply_to:`pour choisir la devise de vos boutiques`,
			apply_to_mes: "Appliquez le taux de change aux règles de tarification lorsque vous publiez des produits sur Shopify. Les Règles de Tarification Globales fonctionnent différemment si vous ne vendez PAS en USD sur Shopify et ne souhaitez pas activer cette fonctionnalité. Veuillez calculer les multiplicateurs correspondants.",
      apply_btn:`Réglages – Boutiques`,
      bigTit:`Le prix définit est supérieur au Prix Comparé au prix, veuillez vérifier et sauvegarder`,
      inputerr100:'Plage de valeurs: 0 % à 99 %',
      basicpricerule:'Basic pricing rule',
      standardpricerule:"Standard pricing rule",
      advancedpricerule:"Advanced pricing rule",
      setpricing:'Set Pricing Rules Details',
      pricing_rule_new_title:'Modèles de prix',
      pricing_rule_new_dsec:'Les modèles de prix vous permettent de fixer facilement les prix des produits. Sélectionnez la boutique à laquelle vous voulez appliquer les modèles. Affichez et vérifiez les détails des Règles de prix pour personnaliser davantage les prix de vos produits.'
    },
    billing: {
      // d
      activate_google_auth:
        "Nous vous recommandons fortement d'activer Google<br /> Authenticator pour assurer la sécurité de votre compte",
      activate: 'ACTIVER',
      remaining_credit: 'Crédit Restant',
      make_a_payment: 'RÉALISER UN PAIEMENT',
      make_a_payment_tip:
        "Vous devez d'abord compléter votre addresse de facturation avant de pouvoir l'utiliser.",
      last_payment:
        '{key, plural, =q1 {Votre dernier paiement date du} =q2 {for USD} =q3 {et est en attente sur PayPal.} =q4 {échec du paiement sur PayPal. Veuillez vérifier et réessayer le paiement.} }',
      transactions: 'Transactions',
      date: 'Date',
      description: 'Description',
      amount: 'Montant (USD)',
      purchase_record: "Historique d'achat",
      download: 'Télécharger',
      all_bills: 'Toutes les factures',
      save_succ: 'sauvegarde réussie',
      save_error: 'échec sauvegarde',
      google_auth: {
        step1: 'Étape 1: Télécharger Google Authenticator',
        step1_description:
          "Les utilisateurs d'IOS doivent rechercher “Google Authenticator\" dans l'AppStore, et les utilisateurs d'Android peuvent rechercher “Google Authenticator\" dans Google Play ou dans leur navigateur mobile.",
        step2: 'Étape 2: Lier Google Authenticator',
        scan_qr_code: 'IOS/Android：Scanner le code QR',
        code:
          'Si le scan ne fonctionne pas, vous pouvez entrer le code de vérification.',
        please_save:
          'Utilisé pour Google Authenticator, veuillez le sauvegarder.'
      },
      billing: {
        billing_information: 'Billing information',
        business_name: 'Business name',
        business_name_error: 'Please enter a Name',
        email: 'Email',
        email_not_valid: 'The input is not valid E-mail!',
        email_null_error: 'Please input E-mail!',
        phone: 'Phone',
        phone_error: 'Please enter a Name',
        city: 'City',
        city_error: 'Please enter a city',
        currency: 'Currency',
        currency_error: 'Please enter a Name',
        country: 'Country',
        country_error: 'Please enter a country',
        address: 'Address',
        address_error: 'Please enter a address',
        post_code: 'Post code',
        province: 'Province',
        province_error: 'Please enter a province',
        company: 'Company',
        send_this_address: 'Send sample to this address'
      },
      primary: {
        title: 'Primary contact',
        name: 'Name',
        name_error: 'Please enter a Name',
        use_billing_address: 'Use as billing address',
        send_sample_to_this_address: 'Send sample to this address'
      }
    }, // delete
    verified: {
      // delete
      title: 'Verified Supplier',
      img_max_1mb: 'Image must smaller than 1MB!',
      bag_default: 'Bag (default and free)',
      bag_box: 'Box ($0.2/pacakge)',
      bag_box_logo: 'Box with logo ($0.2/pacakge)',
      bag_box_card: 'Box with card ($0.2/pacakage)',
      bag_box_logo_card: 'Box with logo and card ($0.2/package)',
      upload: 'Upload',
      package_request: 'Package request',
      logo_file: 'Logo file',
      note: 'Note'
    }, // delete
    report: {
      timezone: {
        title: 'Fuseau Horaire',
        desc: `Votre fuseau horaire par défaut est basé sur les réglages de votre navigateur.
          Vous pouvez sélectionner un autre fuseau horaire ici, MAIS veuillez noter que vous ne pouvez le régler qu'une fois et qu'il ne pourra ensuite plus être modifié.`,
        save_modal_desc: `<i>Vous ne pouvez régler votre fuseau horaire qu'une fois</i>.
                          Veuillez vous assurez de sélectionner le bon. Il ne peut pas être changer plus tard.`,
        save_modal_tip: 'Veuillez entrer CONFIRMER pour sauvegarder'
      },
      currencies: {
        title: 'Devise',
        desc: `Les Dollars US seront utilisés comme devise par défaut sur DSers.
          Vous pouvez choisir la devise pour chaque boutique ici et elle sera affichée sur tous vos produits et commandes sur DSers.
          Les données affichées sur DSers peuvent varier de ce que vous voyez sur Shopify ou AliExpress en raison des changements de taux de change.
          <i>Vous ne pouvez choisir votre devise qu'une fois par boutique</i>.
          Si vous souhaitez changer de devise plus tard,
          veuillez contacter  <a href="https://www.messenger.com/t/DSersOfficial"> le support</a>.`,
        store: 'Boutique',
        currency: 'Devise',
        no_store_tip: 'Veuillez sélectionner une boutique',
        save_modal_title: `Vous ne pouver changer la devise qu'une fois!`,
        save_modal_desc: `Veuillez noter que <i>vous ne pouvez changer la devise qu'une fois</i> .
          Veuillez entrer CONFIRMER ci-dessous pour sauvegarder.`,
        input_confirm: 'Veuillez écrire CONFIRMER'
      }
    },
    other: {
      ioss_message: 'IOSS',
      ioss_message_tip: `Si vous souhaitez payer la TVA vous même au lieu de la payer via AliExpress, vous devez ajouter un numéro IOSS au information de l'acheteur des commandes que vous placez sur AliExpress à destination de l'UE.`,
      ioss_message_tip_2: `Veuillez demander aux fournisseurs AliExpress de fournir et s'assurer du transfert de l'ID IOSS aux transporteurs afin que les colis puissent passer les douanes normallement. Il est extremement important de contacter les fournisseurs individuellement pour s'en assurer.`,
      ioss_message_info: `(Votre compte AliExpress doit être un compte Business Buyer vérifié afin de pouvoir ajouter le numéro de taxe TVA sur AliExpress, puis vous devez déclaré et payer les taxes TVA vous-mêmes.)<br/>Le numéro IOSS que vous entrez ici sera ajouté automatiquement à "Adresse 2" dans les commandes AliExpress quand vous les placez. Si vous modifiez le numéro IOSS ici, le nouveau numéro sera ajouté uniquement aux nouvelles commandes placées sur AliExpress.`,
      ioss_message_more: `Cliquez <a href="https://www.dsers.com/blog/eu-vat-reform/" target="_blank">ici</a> pour en apprendre plus.`,
      ioss_message_error: `Veuillez entrer un numéro IOSS correct pour éviter l'échec des commandes sur AliExpress ou l'arrêt des colis par les douanes`,
      default_phone_number_placeholder: 'Choisir un pays',
      set_product_title: 'Définir les produits comme taxables',
      set_product_subtitle: 'Indiquez si une taxe est facturée ou non lorsque la variante du produit est vendue.',
      phone_rules_SA_AE_IL:
        'il faut un numéro de téléphone à 9 chiffres commençant par 5.',
      phone_rules_FR: 'il faut un numéro de téléphone à 10 chiffres.',
      phone_rules_PT: 'il faut un numéro de téléphone à 9 ou 10 chiffres.',
      phone_rules_BR_JP: 'il faut un numéro de téléphone à 10 ou 11 chiffres.',
      phone_rules_KR:'Veuillez entrer un numéro de téléphone à 10 ou 11 chiffres, commençant par 01 ou 1 (ex: 01012345678)',
      phone_rules_IT: 'Il faut un numéro de téléphone à 9 ou 10 chiffres.',

      sync_ali_order_number:
        'Synchroniser N° Commande Ali dans détails additionnels sur Shopify',
      sync_ali_order_number_tip:
        'Si ce réglage est activé, le numéro de commande AliExpress sera synchronisé automatiquement dans la partie détails additionnels sur Shopify.',
      canceled_orders_optimization: 'Optimisation des Commandes Annulées.',
      canceled_orders_optimization_tip:
        "Activer cette fonction optimisera automatiquement une commande annulée par AliExpress. L'optimisation permettra de grandement réduire les chances que la commande soit de nouveau annulée.",
      synchronize_shopify_order_notes: 'Synchroniser notes de commande Shopify',
      synchronize_shopify_order_notes_tip: `Activate this feature to automatically synchronize Shopify order notes. DSers will help to send the notes of an order from Shopify to AliExpress along with orders.`,
      synchronize_shopify_order_message: "Synchronize Shopify order customization information",
      synchronize_shopify_order_message_tip:"Activating this feature automatically synchronize Shopify order customization information. DSers will help you send the customization information from Shopify to suppliers along with the order.",
      leave_a_meaage: 'Laissez un message aux fournisseurs',
      leave_a_meaage_tip:
        'Les fournisseurs recevront ce message avec les informations relatives à votre commande.',
      message_placeholder: 'Entrez 1000 caractères au maximum',
      default_phone_number_placeholder_select: 'Choisir un pays',
      default_phone_number_placeholder_input: 'Choisir un pays',
      default_phone_number: 'Numéro de téléphone par défaut',
      default_phone_number_tip: `Si une commande ne contient pas de numéro de téléphone, le systême utilisera le numéro de téléphone par défaut que vous avez  réglé`,
      override_phone_number: 'Remplacement N° de Téléphone',
      override_phone_number_uae:
        "Pour les commandes vers l'Arabie Saoudite, les Émirats Arabes Unis & Israel, il faut un numéro de téléphone à 9 chiffres commençant par 5.",
      override_phone_number_fr: `Pour les commandes vers la France, il faut un numéro de téléphone à 10 chiffres. Pour les co commençant par 06.`,
      override_phone_number_pt:
        'Pour les commandes vers le Portugal, il faut un numéro de téléphone à 9 ou 10 chiffres.',
      override_phone_number_br:
        'Pour les commandes vers le Brésil, il faut un numéro de téléphone à 10 ou 11 chiffres.',
      override_phone_number_jp:
        'Pour les commandes vers le Japon, il faut un numéro de téléphone à 10 ou 11 chiffres.',
      override_phone_number_ko:
        'Pour les commandes vers la Corée du Sud, il faut un numéro de téléphone à 10 ou 11 chiffres commençant par 01 ou 1.',
      override_phone_number_it: `Pour les commandes vers l'Italie, il faut un numéro de téléphone à 9 ou 10 chiffres.`,
      override_phone_number_intro_start: 'Veuillez noter que:',
      override_phone_number_intro_end: 'Veuillez modifier en fonction.',
      override_phone_number_tip:
        'Remplacez le numéro de téléphone du client par votre propre numéro lorsque vous passez une commande aux fournisseurs.',
      france_phone_number_tip: `Veuillez noter que pour les commandes vers l'Arabie Saoudite,
                                les Emirats Arabes Unis, il faut un numéro de téléphone à 9 chiffres commençant par 5.
                                Pour les commandes vers la France, il faut 10 chiffres commençant par 06 ou 07.
                                Veuillez modifier en fonction.`,
      sync_shopify_orders: 'Synchronisation Commandes  Shopify',
      sync_shopify_orders_tip:
        "DSers ne synchronisera que les commandes de ces 7 derniers jours par défaut. Choisissez un intervalle de date pour synchroniser les commandes plus anciennes ou régler un problème de sync avec Shopify. Vous pouvez synchroniser jusqu'au 30 derniers jours.",
      sync_shopify_orders_error:
        'Vous ne pouvez synchroniser que les 30 derniers jours.',
      select_store_placeholder: 'Choisir boutique',
      sync: 'Sync',
      affiliate_program: 'Programme Affilié', // add
      affiliate: 'Affilié', // add
      affiliate_desc: 'Vous avez activé votre cashback Admitad', // add
      change_code: 'Changer code', // add
      change_code_wraming:
        'Êtes-vous sûr de vouloir supprimier le code actuel?', // add
      step: 'Étape', // add
      new_affiliate_tip:
        'Créer un NOUVEAU compte Admitad via le lien ci-dessous', // add
      why_affiliate: "Pourquoi ai-je besoin d'un nouveau compte Admitad?", // add
      get_affiliate_btn: 'Créer compte Admitad', // add
      set_affiliate: 'Configurer votre compte Admitad', // add
      how_to_set: 'Vérifier comment le régler?', // add
      fill_affiliate: 'Entrez votre code et nom de compte affilié.', // add
      where_code: 'Ou trouver le code?', // add
      code: 'Code', // add
      err_tip: "Ce n'est pas un code Affilié DSers. Veuillez vérifier", // add
      message_ae_sellers:
        'Le message a été enregistré avec succès et les fournisseurs le recevront lorsque vous passerez des commandes.',
      save_phone_succ: 'Numéro de téléphone sauvegardé.',
      cash_back_code_error:
        "Ceci n'est pas un code affilié DSers, veuillez vérifier.",
      cost_error: 'Veuillez entrer un nombre au format 5 ou 5.99',
      create_success: 'Créer le succès',
      admitad_stopping_now:
        'Votre cashback Admitad va s\'arrêter. Veuillez assigner un nouveau code à DSers. <a href={href} target="_blank">Vérifier pourquoi et comment</a>',
      notice: `Notre système a détecté que votre compte Admitad ne peut être vérifié.
              <br />
              Assurez-vous que vous avez créé votre compte Admitad avec le&nbsp;
              <a
                href="https://store.admitad.com/en/promo/?ref=8e3xmmxnjp"
                target="_blank"
                rel="noopener noreferrer"
              >
                lien affilié DSers
              </a>
              &nbsp;et changez le code dans DSers.
              <br />
              Si votre compte Admitad a été créé avec le&nbsp;
              <a
                href="https://store.admitad.com/en/promo/?ref=8e3xmmxnjp"
                target="_blank"
                rel="noopener noreferrer"
              >
                lien affilié DSers
              </a>
              , mais ne peut être vérifié, veuillez vérifier que vous avez entré le bon nom de compte dans DSers.`,
      notice_sub:
        'Veuillez notez que votre compte Admitad actuel sera désactivé dans 48h.',
      notice_add_title:"About Admitad Account",
      notice_2: `Afin d'assurer la bon fonctionnement de votre compte Admitad avec DSers, rendez-vous dans Réglages - Autres - Programme Affilié pour finir l'activation. Autrement, votre code sera désactivé après 48h.`,
      not_account_name: 'Veuillez entrer le nom de votre compte Admitad.',
      notice_button_change: 'Changer nom de compte',
      notice_button_register: `S'enregister`,
      cancel_fulfillment_title: 'Annulation de Demande de Traitement',
      cancel_fulfillment_desc: `After activating this function of corresponding app below, the orders that cannot be placed from the app because you clicked "Request Fulfillment" on Shopify will be restored so you can place them normally to AliExpress.`,
      aliSku: `Utilisez l'ID du fournisseur comme UGS (SKU) du produit`,
      aliSku_desc: `Après l'activation, lorsque vous importez des produits de fournisseurs dans la liste des imports, l'UGS du produit utilisera le nom de l'ID-variante du produit du fournisseur.`,
      vat_title: `Quand le coût d'une commande pour la France dépasse 150 Euros, vous devez fournir votre numéro TVA et le nom de votre entreprise. Veuillez l'entrer dans la commande suivante, afin de ne pas affecter les commandes. Cliquez <a href="https://www.blog.dserspro.com/post/orders-to-france-specifications-with-shopify-dsers" target="_blank">ici</a> pour obtenir plus d'information.`,
      vat_id_verify: `Veuillez entrer le numéro TVA de 8-12 chiffres`,
      vat_input_verify: `Veuillez entrer le numéro TVA de 8-12 chiffres. N'ajoutez pas "FR" au début.`,
      vat_company: `Veuillez entrer le nom de l'entreprise`,
      vat_h3: 'TVA',
      plan_info_id: 'numéro TVA',
      plan_info_name: `nom de l'entreprise`,
      override_phone_number_intro_start_new: 'Veuillez noter:',
      override_phone_number_first: "nécessitent un numéro à 9 chiffres commençant par 5.",
      override_phone_number_second: "nécessitent un numéro à 9 chiffres commençant par 5, ou un numéro à 10 chiffres commençant par 0.",
      override_phone_number_third:'nécessitent un numéro à 9 ou 10 chiffres.',
      override_phone_number_fourth: 'nécessitent un numéro à 10 ou 11 chiffres.',
      override_phone_number_fifth:'nécessitent un numéro à 10 ou 11 chiffres commençant par 01 ou 1.',
      phone_rules_SA_AE_IL_new:'Il faut un numéro de téléphone à 9 chiffres commençant par 5 ou un numéro à 10 chiffres commençant par 0.',
      phone_rules_FR_new:'Il faut un numéro de téléphone à 9 ou 10 chiffres.',
      order_save:'Sauvegarder',
      order_fulfillment_module:"Paramètres d'exécution",
                        order_fulfillment_first_title:'Traitement des articles séparément',
                        order_fulfillment_first_content:"Après avoir activé cette fonction, DSers traitera chaque produit des commandes séparément et enverra un mail de confirmation d'expédition Shopify selon les règles suivantes : Lorsqu'un produit d'une commande DSers a un numéro de suivi, nous traitons cet article dans la commande et Shopify envoie un mail à votre client. Shopify enverra un mail plusieurs fois pour la même commande.",
                        order_fulfillment_second_title:"Traitement d'articles en masse",
                        order_fulfillment_second_content:"Après l'activation de cette fonction, DSers ne traitera une commande que si tous les produits de la commande ont un numéro de suivi et Shopify enverra un seul mail à votre client.",
      order_split_pop_title:'Remarque',
      order_split_pop_content:"Afin de poursuivre l'amélioration de la stabilité de DSers et l'expérience de nos commerçants, si vous désactivez cette fonctionnalité, elle ne sera plus accessible. Êtes-vous sûr de vouloir arrêter d'utiliser cette fonctionnalité ?",
      order_split_pop_btn:'CONFIRMER'
    },
    payment: {
      top_title:"Informations sur l'entreprise",
      top_small_title: 'Utilisé sur les relevés de commande Tmall .',
      top_form_item1: 'Prénom',
      top_form_item2: 'nom de famille',
      top_form_item3: "Nom de l'entreprise",
      top_form_item4: 'Email',
      top_form_item5: 'Pays',
      top_form_item6: 'Adresse',
      top_form_item7: 'Appartement, suite, etc.',
      top_form_item8: 'Ville',
      top_form_item9: 'État',
      top_form_item10: 'Code postal',    
      bottom_title: "Déclarations Tmall",
      bottom_small_title:"Vérifier et télécharger le relevé mensuel des commandes Tmall. (Mise à jour du relevé du mois précédent le x de chaque mois)",
      bottom_state1: "Demande",
      bottom_state2:"Dans la demande"  
    },
    order:{
      input_placeholder:`Entrez le lien AliExpress du produit pour lequel vous souhaitez diviser les commandes`,
      order_same:`Le lien du produit, la quantité et le pays que vous avez configurés sont exactement les mêmes, veuillez vérifier et sauvegarder`,
      title:`Fractionnement Automatique de la Commande`,
      desc:`Nous fractionnerons la commande pour vous en fonction du lien du produit AliExpress et de la quantité de produits que vous avez définis. Règles de fractionnement des commandes : lorsque le produit de la commande est le fournisseur que vous avez défini, et que la quantité de produits est supérieure ou égale à la quantité définie dans le paramètre, qui doit être inférieure à 10, nous fractionnerons automatiquement la commande en plusieurs commandes Shopify.`,
      inputNumber:`Veuillez saisir le montant que vous souhaitez diviser`,
      incorrect:`Le lien de produit que vous avez entré est incorrect`,
      banner_title: 'Division automatique des commandes',
      banner_text:`Vous avez des problèmes pour utiliser la plus rentable des méthodes de livraison qu'est AliExpress Saver Shipping? Venez voir ça`,
      banner_btn:'EN SAVOIR PLUS',
      titip:'Ouvertes',
      delivery_period_tip:'Minimum 2, maximum 10',
      delivery_period:'Quantité de produits dans les commandes à fractionner',
      split_ending:'Division des commandes',
      delete_dsers: 'Ce produit a été supprimé sur DSers',
    },
    subaccount:{
      add:'Ajouter un compte employé',
      sendemail:`Nous enverrons un e-mail à l'adresse e-mail que vous avez entrée pour compléter l'autorisation, et vos employés pourront se connecter à DSers via ce compte`,
      admintext:`Toutes les autorisations, y compris les autorisations d'administrateur`,
      fulltext:`Toutes les pages et fonctionnalités, mais aucune autorisation d'administrateur`,
      limittext:`Montrer au propriétaire selon la page et la boutique que vous avez configurées`,
      emailempty:`Veuillez saisir l'adresse e-mail`,
      emailformat:`Veuillez saisir une adresse e-mail correcte`,
      active:'Active',
      NoAccess:`Pas d'accès à la page`,
      NoAccessTitle:`Vous n'avez pas accès à la page actuelle, veuillez contacter votre administrateur`,
      report:"Rapport",
      ImportList:"Liste d’Import",
      MyProduct:"Mes Produits",
      Openorder:"Com. Ouvertes",
      ArchivedOrder:"Archives",
      Tracking:"Tracking",
      CSVBulkOrder:"Nouvelles Fonctions",
      LinktoMoreStores:"Link to More Stores",
      Setting:"Réglages",
      Notification:"Notifications",
      fullaccess:"Full access",
			admin:"Admin",
			Limitedaccess:"Limited access",
			Full:"Full",
			Storeaccess:"Store access",
			Featureaccess:"Feature access",
			invited:"invited",
			Notconnected:" Not connected"
    },
    product_price:'Mise à jour du prix du produit',
    title_account:'Compte',
    title_time_zone:'Fuseau horaire',
    time_zone_des:'Votre fuseau horaire par défaut est basé sur les paramètres de votre navigateur. Vous pouvez choisir un autre fuseau horaire pour votre compte DSers. Veuillez noter que vous ne pouvez le choisir qu’une fois et qu’il ne pourra pas être modifié après la sauvegarder.',
    title_ae_account:'Compte AliExpress',
    ae_account_des:'Veuillez noter que si vous vous connectez à un compte différent, les informations des commandes placé avec le compte actuel ne seront plus mises à jour sur DSers après la déconnexion.',
    title_synchronization:'Synchronisation',
    title_automatic:'Mise à jour des prix automatique',
    automatic_des:'Après l’activation de la fonction Mise à jour des prix automatique, DSers synchronisera automatiquement les prix mis à jour vers Shopify en fonction des règles de prix choisies toutes les 24h si le fournisseur change le prix de plus d’1 dollar.',
    automatic_des2:'Veuillez noter: Vous devez avoir la fonction Règle de prix active pour utiliser cette fonction. Si vous changez le prix sur Shopify, nous mettrons à jour le prix sur Shopify en fonction des règles de prix sur DSers.',
    title_update_pro_pr:'Modification manuelle du prix des produits du magasin',
    update_pro_pr_des:'Mettre à jour le prix des produits envoyés sur votre boutique Shopify en fonction des règles de prix définies actuellement. Cela peut prendre un peu de temps.',
    update_pro_pr_des_time_1:`Mise à jour manuelle jusqu'à`,
    update_pro_pr_des_time_2:`fois tous les 30 jours`,
    title_sync_shop_order:'Sync commandes Shopify',
    sync_shop_order_des:'DSers synchronisera les commandes des 7 derniers jours par défaut. Choisissez une plage de date pour synchroniser plus de commandes. Vous pouvez synchroniser par tranches de 30 jours.',
    title_order_details_sync:'Synchronisation du Détail des commandes',
    title_affiliate_program:'Programme affilié',
    title_notification:'Notification',
    title_shipping_confirmation:"Confirmation d'expédition",
		title_shipping_confirmation_des: "Envoyez automatiquement l'e-mail de confirmation d'expédition Shopify à votre client une fois que le numéro de suivi a été généré.",
    title_paypal:'Envoyez le numéro de suivi à PayPal',
		title_tracking: "Suivi de la commande",
		title_tracking_tip:"Activez cette fonction pour suivre la progression des commandes qui ont été expédiées.",
    title_leave_message:'Laissez un message aux fournisseurs',
    "title_leave_message_ae":"Les fournisseurs AliExpress recevront ce message avec les informations de votre commande.",
    "title_leave_message_tmall":"Les fournisseurs Tmall recevront ce message avec les informations de votre commande, mais l'édition du message n'est pas prise en charge pour le moment.",
    message_des:'Les fournisseurs recevront ce message avec les informations relatives à votre commande.',
    title_phone_number_op:'Optimisation du numéro de téléphone',
    title_automatic_order_split:'Division automatique des commandes',
    automatic_order_split_des:`Nous diviserons les commandes en fonction du produit AliExpress et de la quantité définie. Règles: Si le produit d'une commande est identique au produit défini et que la quantité est supérieure ou égale à la quantité définie (tout en étant inférieure ou égale à 10), nous diviserons automatiquement la commande en plusieures commande Shopify.`,
    title_order_management:'Gestion Commandes',
    title_tax:'Taxes',
    title_shipping:'Livraison',
    title_add_global_shipping:'Ajouter méthode de livraison globale pour un produit',
    add_global_shipping_des:"Définissez votre mode d'expédition par défaut pour des produits de fournisseurs spécifiques. Le paramétrage par produit est plus prioritaire que le paramétrage par pays. Vous pouvez accéder rapidement à cette fonctionnalité en cliquant sur Mapping - Autres actions.",
    phone_number_des:"Pour que votre colis vers les Pays-Bas ou la Finlande soit livré correctement, veuillez indiquer un vrai numéro de téléphone dans la commande. Les Pays-Bas et la Finlande ne prennent pas en charge l'utilisation de la fonction d'optimisation des numéros de téléphone pour le moment. Cliquez <a href=\"https://help.dsers.com/fix-failed-orders-due-to-aliexpress-phone-number-regulations/\" target=\"_blank\">ici</a> pour savoir comment résoudre ce problème",
    phone_number_des_tip:"Ce pays n'est actuellement pas pris en charge",
    title_pricing_rule:'Règles de prix',
    title_product:'Produit',
    title_sp_store:'Boutiques Shopify',
    sp_stores_des:'Vous pouvez gérer vos boutiques Shopify ici. Si vous voulez désinstaller DSers d’une boutique, veuillez ne pas oublier de délier ici également.',
    title_currencies:'Devises',
    disablestext: 'Choisir une boutique',
    password: 'Mot de passe',
    admitadAccount1: "Le compte Admitad avait été suspendu en raison d'un échec de vérification. Cliquez ",
		admitadAccountLink: "ici ",
    admitadAccount2: "pour pour obtenir des informations plus détaillées.",
    refresh_store_tag: "Actualiser la connexion",
		refresh_store_tag_tip: `Si le statut de la boutique Shopify n'est pas à jour pour des raisons de réseau, cliquez sur "Actualiser la connexion" pour obtenir le statut de la boutique`,
		refresh_not_connected:"Pas connecté",
    refresh_store_icon_tip: "Refresh connect",
    affiliate_code:'Affiliate Code',
		affiliate_code_btn:'REACTIVER',
    hasSetCurrency:`Appliquez le taux de change aux règles de tarification lorsque vous publiez des produits sur Shopify. Les Règles de Tarification Globales fonctionnent différemment si vous ne vendez PAS en USD sur Shopify et ne souhaitez pas activer cette fonctionnalité. Veuillez calculer les multiplicateurs correspondants.`,
    pricing_rule_dsec:'Les règles de prix comprenne la Devise, les Modèles de prix et les Réglages centimes. Vous pouvez créer vos Règles de prix ici et les appliquer à vos produits.',
    updateProductPrice:`Mettez à jour le prix des produits envoyés à votre boutique Shopify en fonction des règles de tarification que vous avez définies. Le processus peut prendre un certain temps.Cliquez <a href="/app/pricing?select=1">ici</a> pour mettre à niveau le plan et augmenter les temps de synchronisation.`,
    ...agency
  }
};
