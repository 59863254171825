import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Col } from 'antd';

import map from './map';

const FormItem = Form.Item;
const InputGroup = Input.Group;

function generator({ defaultProps, defaultRules, type }) {
  return WrappedComponent => {
    return class BasicComponent extends Component {
      static contextTypes = {
        form: PropTypes.object,
        updateActive: PropTypes.func
      };
      constructor(props) {
        super(props);
        this.state = {
          count: 0
        };
      }
      componentDidMount() {
        if (this.context.updateActive) {
          this.context.updateActive(this.props.name);
        }
      }
      componentWillUnmount() {
        clearInterval(this.interval);
      }
      onGetCaptcha = () => {
        let count = 59;
        this.setState({ count });
        if (this.props.onGetCaptcha) {
          this.props.onGetCaptcha();
        }
        this.interval = setInterval(() => {
          count -= 1;
          this.setState({ count });
          if (count === 0) {
            clearInterval(this.interval);
          }
        }, 1000);
      };
      render() {
        const { getFieldDecorator } = this.context.form;
        const options = {};
        let otherProps = {};
        const {
          onChange,
          defaultValue,
          rules,
          name,
          ...restProps
        } = this.props;
        options.rules = rules || defaultRules;
        if (onChange) {
          options.onChange = onChange;
        }
        if (defaultValue) {
          options.initialValue = defaultValue;
        }
        otherProps = restProps || otherProps;
        if (type === 'Captcha') {
          const { captchaUrl, refreshCaptcha, ...ret } = this.props;
          return (
            <InputGroup size="large">
              <Col span={13}>
                <FormItem style={{ marginBottom: '16px' }}>
                  {getFieldDecorator(name, options)(
                    <WrappedComponent {...ret} />
                  )}
                </FormItem>
              </Col>
              <Col span={5}>
                <img
                  src={captchaUrl}
                  alt="captcha"
                  onClick={refreshCaptcha}
                  style={{ cursor: 'pointer', height: 30 }}
                />
              </Col>
            </InputGroup>
          );
        }
        return (
          <FormItem>
            {getFieldDecorator(name, options)(
              <WrappedComponent {...defaultProps} {...otherProps} />
            )}
          </FormItem>
        );
      }
    };
  };
}

const LoginItem = {};
Object.keys(map).forEach(item => {
  LoginItem[item] = generator({
    defaultProps: map[item].props,
    defaultRules: map[item].rules,
    type: item
  })(map[item].component);
});
export default LoginItem;
