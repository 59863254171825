import agency from './agency';


    export default 
    {
    "setting": {
        "tab_account": "Account",
        "tab_package_tracking": "Package Tracking",
        "tab_shipping": "Spedizione",
        "tab_tracking": "Rintracciare",
        "tab_notification": "Notifica",
        "tab_pricing_rule": "Regola dei Prezzi",
        "tab_billing_balance": "Saldo Fatturazione",
        "tab_verified_supplier": "Fornitore Verificato",
        "tab_report": "Segnala",
        "tab_other": "Altro",
        "choose_country_placeholder": "Scegli un paese",
        "account": {
            "account_info": "Informazioni account",
            "account_name": "Nome account",
            "account_email": "Email account",
            "change": "Modifica",
            "change_email_desc": "L'email è la tua email di accesso. Useremo l'email per contattarti in relazione al tuo account",
            "new_email": "Nuovo indirizzo email",
            "new_email_desc": "Invieremo un codice di verifica al nuovo indirizzo email. Copialo e inseriscilo qui sotto per verificare la tua casella di posta",
            "new_email_err": "formato e-mail errato",
            "account_name_err": "Inserisci un nome utente tra 5 e 20 caratteri",
            "verify_code": "Inserisci il codice per la verifica",
            "verify_code_desc": "Ricevi il codice dalla nostra e-mail di verifica e inseriscilo qui per essere verificato",
            "verify_code_err": "Codice sbagliato. Controlla di nuovo",
            "reset_password": "Ripristina la password",
            "reset_password_desc": "Cambia la password che usi per accedere.",
            "change_password": "Cambia la password",
            "shopify_stores": "Negozi Shopify",
            "shopify_stores_desc": "Puoi gestire i tuoi negozi Shopify qui. Se vuoi disinstallare DSers da, non dimenticare di disinstallare anche da qui.",
            "shopify_stores_re_authorized": "Il negozio ha bisogno di essere riautorizzato. Per favore aggiorna l'autorizzazione. Altrimenti non puoi far funzionare correttamente il negozio con DSers",
            "shopify_stores_tip": "Abbiamo aggiornato l'autorizzazione Shopify. Per favore clicca per rinnovarla nella pagina dell'applicazione Shopify. Altrimenti influirà sull'evasione degli ordini.",
            "store_name": "Nome del negozio",
            "status": "Stato",
            "connected": "Connesso",
            "disconnect": "Disconnesso",
            "ae_account": "Account AliExpress",
            "ae_account_desc": "Puoi disconnettere o cambiare account AliExpress qui.",
            "account_id": "ID account",
            "change_account_title": "Cambia Negozio Fatturazione",
            "change_account_content": "Stai per impostare {choiceLabel} come negozio di fatturazione. Per la migliore esperienza sulla piattaforma DSers, assicurati che questo negozio sia sempre aperto",
            "switch": "Cambia",
            "ae_account_tip": "Nota che se ti colleghi ad un altro account, le informazioni degli ordini effettuati nell'account corrente non saranno più aggiornate su DSers dopo la disconnessione",
            "click_to_renew": "Clicca per rinnovare",
            "disconnect_ae_tip1": "Sei sicuro di voler disconnettere il tuo account AliExpress? Le informazioni degli ordini effettuati con l'account corrente non saranno più aggiornate su DSers dopo la disconnessione",
            "disconnect_ae_tip2": "Dopo la disconnessione, scoprirai che i dati dell'account spariranno temporaneamente. Saranno recuperati dopo aver effettuato il collegamento ad un nuovo account AliExpress.",
            "disconnect_ae_confirm": "Inserisci CONFERMARE per scollegarti.",
            "reconnect_ae": "Assicurati di ricollegarti allo stesso account. Altrimenti, le informazioni degli ordini effettuati con l'account corrente non saranno più aggiornate su DSers dopo la disconnessione.",
            "switch_ae": "Sei sicuro di voler cambiare account AliExpress? Le informazioni degli ordini effettuati con l'account attuale non saranno più aggiornate su DSers dopo la disconnessione.",
            "open_mailbox": "Apri la casella di posta",
            "send_again": "Invia ancora",
            "send_code": "Invia codice",
            "verify": "Verifica",
            "store_name_tip": "DSers mostrerà il nome originale del negozio quando ti sei registrato su Shopify e non aggiorneremo il nome del negozio anche se lo hai cambiato su Shopify. Ricorda che il nome del negozio non sarà rivelato ai clienti e non influenzerà gli ordini su DSers.",
            "verify_email_title": "Verifica il tuo indirizzo e-mail",
            "verify_email_title_succ": "Email di verifica inviata",
            "verify_email_title_fail": "Consegna dell'e-mail di verifica non riuscita",
            "verify_email_desc": "Per aiutare a mantenere il tuo account sicuro, verifica il tuo indirizzo e-mail. Abbiamo inviato un'e-mail di verifica a {email}",
            "verify_email_desc_succ": "Per favore verifica il tuo indirizzo email seguendo il link nell'email inviata a {email}",
            "verify_send": "Invia email di verifica",
            "verify_re_send": "Invia nuovamente l'email di verifica",
            "white_list": "Whitelist",
            "white_listed": "In Whitelist",
            "account_new_info": "Cambia nome account",
            "noData": "No linked store",
            "changeEmailName": "Cambia e-mail",
            "code_verify": "Code is required",
            "jiabai_intro": "Add your AliExpress account to the whitelist",
            "authorize_name": "Aggiornamenti",
            "authorize_tips": "Aggiorna i consensi da Shopify. Sembrerebbe che le funzioni di aggiornamento concensi di DSers non siano aggiornate. Aggiornale in qualche clic per fare in modo che DSers funzioni correttamente."
        },
        "shipping": {
            "title": "Spedizione",
            "description": "Imposta il tuo metodo di spedizione predefinito per un determinato paese.",
            "turn_off": "Disattiva",
            "turn_on": "Attiva",
            "save_succ": "Salvato con successo",
            "save_failure": "Salvataggio fallito",
            "advanced": {
                "title": "Avanzate",
                "description": "Imposta il tuo metodo di spedizione predefinito per fascia di prezzo e intervallo di date di consegna.",
                "tracking_information_available": "Informazioni di monitoraggio Disponibili",
                "delivery_period": "Periodo di consegna",
                "delivery_period_tip": "periodo di consegna massimo accettabile",
                "delivery_period_error": "Inserisci il numero di giorni.",
                "days": "Giorni",
                "shipping_cost": "Costo di spedizione",
                "shipping_cost_error": "Per favore inserisci il prezzo.",
                "tip": "Quando più opzioni soddisfano le mie condizioni",
                "i_need": "Mi serve",
                "less_cost": "costo inferiore",
                "less_day": "meno giorni"
            },
            "general": {
                "title": "Aggiungi la scheda del metodo di spedizione globale",
                "description": "Imposta il tuo metodo di spedizione predefinito per i paesi globali. Il sistema d'ordine automatico sceglierà il metodo di spedizione in base alle tue impostazioni durante l'inserimento degli ordini.L'opzione 1 sarà la tua prima scelta.",
                "placeholder": "Aggiungi altro metodo di spedizione",
                "option": "Opzione"
            },
            "product": {
                "title": "Aggiungi metodo di spedizione globale basato sul prodotto",
                "dec": "Imposta il tuo metodo di spedizione predefinito per uno specifico prodotto AliExpress. L'impostazione basata sul prodotto ha una priorità maggiore rispetto all'impostazione basata sul paese.",
                "placeholder": "Cerca tramite titolo prodotto rivenditore",
                "modal_placeholder": "Cerca tramite parole chiave del titolo prodotto rivenditore",
                "tip": "Solo i prodotti già mappati possono essere trovati qui",
                "shortcut_open": "Puoi accedere rapidamente a questa funzione andando su Mappatura - Altre azioni"
            }
        },
        "tracking": {
            "title": "Rintracciare",
            "click_to_renew": "Clicca per rinnovare",
            "renew_reminder": "Il tuo conto PayPal è scaduto. Per non influenzare la sincronizzazione dei tuoi numeri di tracking, riautorizza il tuo PayPal",
            "description": "Imposta l'email di notifica dell'evasione da parte di un altro negozio.",
            "activate_email": "Attiva l'email di notifica dell'evasione inviata da Shopify",
            "activate_email_description": "Attiva questo per inviare la mail di conferma di spedizione di Shopify automaticamente al tuo cliente una volta che il numero di tracking è stato generato. Puoi modificare il modello dell'e-mail",
            "here": "qui",
            "activate_email_tip": "Questa funzione ti aiuterà a personalizzare il vettore di spedizione e l'url di tracciamento nella tua e-mail di adempimento Shopify.",
            "shipping_carrier": "Corriere",
            "custom_tracking_url": "Url di tracking personalizzato",
            "custom_tracking_url_tip": "Nota: aggiungeremo dinamicamente un codice di tracking alla fine di questo URL. Se vuoi utilizzare l'URL http://www.17track.net/en/track?nums=1234567 inserisci solo http://www.17track.net/en/track?nums=",
            "please_enter_a_url": "Inserisci l'URL di tracking.",
            "add_a_shopify_store": "Aggiungi un negozio Shopify",
            "save_succ": "Salvataggio delle impostazioni avvenuto con successo.",
            "save_fail": "Salvataggio impostazione fallita.",
            "track_to_paypal_title": "Sincronizza il numero di tracking su PayPal",
            "track_to_paypal_desc": "Autorizza DSers ad aggiungere il numero di tracciamento alla tua transazione su PayPal.",
            "connect_to_paypal": "Collegati a Paypal",
            "connect_to_paypal2": "Clicca sul pulsante per connettere PayPal",
            "connect_to_paypal_desc": "Per garantire che il tuo Numero di Tracking possa essere sincronizzato normalmente, autorizza il collegamento del tuo conto PayPal al negozio Shopify",
            "not_succ": "L'autorizzazione non è riuscita. <br /> Riprova.",
            "use_paypal_account": "Oppure utilizza un account PayPal autorizzato:",
            "to_connect_paypal_account": "Select a PayPal account to connect to",
            "paypal_placeholder": "Seleziona un account",
            "paypal_reminder": "Autorizza il tuo account PayPal qui sopra",
            "linked_paypal": "Sei collegato all'account PayPal:",
            "disconnect": "Disconnetti",
            "disconnect_tip": "Sei sicuro di voler disconnettere il tuo conto PayPal? Ricorda che il numero di tracking non verrà più aggiornato sull'account PayPal dopo la disconnessione.",
            "update": "Aggiorna",
            "refresh_page": "Aggiorna la pagina per aggiornare",
            "track_to_paypal_title1": "Gestisci account PayPal",
            "select_1": "Select a shipping carrier",
            "select_2": "Select a custom tracking URL"
        },
        "notification": {
            "title": "Notifica",
            "description": "Le notifiche che monitorano i prodotti esauriti, i cambiamenti di prezzo saranno inviati al tuo account DSers se attivi la funzione.",
            "inventory": "Inventario",
            "inventory_description": "Ricevi una notifica quando il prodotto è esaurito o non più disponibile",
            "price": "Prezzo",
            "price_description": "Ricevi una notifica quando il prezzo del fornitore è cambiato su AliExpress",
            "canceled_order": "Ordine annullato",
            "canceled_order_description": "Ricevi una notifica quando un ordine viene annullato su AliExpress",
            "sku": "Modifiche SKU",
            "sku_description": "Ricevi una notifica quando lo SKU di un prodotto è cambiato su AliExpress",
            "order": "Ordine",
            "order_description": "Ricevi una notifica quando gli ordini vengono annullati su AliExpress",
            "tip": "Le notifiche dei DSers saranno attivate se selezioni le caselle corrispondenti e salvi.",
            "auto_updates": "Aggiornamenti automatici",
            "auto_updates_tip": "Gli aggiornamenti non saranno applicati ai prodotti multifornitore come Advanced, BOGO o Bundle mapping",
            "out_of_stock": "Quando un prodotto/variante è esaurito o non più disponibile, vuoi che DSers",
            "do_nothing": "Non fare nulla",
            "to_zero": "Impostare la quantità a zero su Shopify",
            "inventory_change": "Quando l'inventario cambia, vuoi che DSers",
            "update_to_shopify": "Aggiornino automaticamente su Shopify",
            "product_out": "Quando un prodotto è esaurito o non disponibile, vuoi che DSers",
            "variant_out": "Quando una variante è esaurita o non disponibile, vuoi che Dsers",
            "set_as_draft": "Set as draft",
            "set_quantity_to_zero_on_Shopify": "Imposta la quantità su 0 su Shopify",
            "remove_Variant": "Rimuovi variante",
            "notification_feature": "Funzione di notifica",
            "filter_variant_out_of_stock": "Variante/i esaurio/i",
            "cancelOrder": "Ordine cancellato",
            "clearFilter": "CANCELLA TUTTI I FILTRI",
            "filterTitle": "Filtra notifica",
            "defaultSupplier": "Fornitore di default",
            "secondSupplier": "Sostituisci fornitore",
            "mappingPlaceholder": "Scegli un prodotto da mappare nuovamente"
        },
        "pricing": {
            "title": "Regola dei prezzi",
            "description": "La regola dei prezzi verrà applicata automaticamente quando spingi i prodotti da DSers a Shopify. Clicca <a href={href} target='_blank'>qui</a> per avere maggiori informazioni.",
            "enabled": "Abilitato",
            "disabled": "Disabilitato",
            "pricing_cost": "Prezzo (USD)",
            "price": "Prezzo",
            "price_tip": "Es. Il prezzo del prodotto che costa 10 USD sarà impostato a 12 o 20 USD (se aggiungi 2 al costo, o lo moltiplichi per 2)",
            "compared_at_price": "Comparato al prezzo",
            "compared_at_price_tip": "Es. Il prezzo comparato del prodotto che costa 10 USD verrà impostato a 15 o 50 USD (se aggiungi 5 al costo, o lo moltiplichi per 5). Se non selezioni questa opzione, il valore del prezzo comparato sarà vuoto quando spingi i prodotti nel tuo negozio Shopify.",
            "compared_ar": "Se non selezioni questa opzione, il valore del prezzo comparato sarà vuoto quando invii i prodotti al tuo negozio Shopify.",
            "price_no": "Se applichi il prezzo comparato quando invii i prodotti e poi disattivi questa opzione, il prezzo comparato non verrà cancellato quando aggiornerai manualmente il prezzo nella pagina di notifica di DSers.",
            "set_rules": "Imposta le tue regole di confronto dei prezzi",
            "basic": "Base",
            "standard": "Standard",
            "advanced": "Avanzato",
            "cost_range": "Fascia Prezzo (USD)",
            "compared_price": "Prezzo Confrontato",
            "rest_price": "Il resto delle fasce di prezzo",
            "basic_more": "Clicca <a href=\"https://help.dsers.com/basic-pricing-rule-for-shopify/\" target=\"_blank\">here</a>qui</a> per avere maggiori informazioni.",
            "formula_text_1": "Valore del prezzo = [(Costo del prodotto + Costo di spedizione) × (1+ Profitto %) + Profitto fisso] / (1 - Breakeven %)",
            "formula_text_2": "Confronta il prezzo = Valore del prezzo × / ＋ la cifra da te impostata",
            "formula_text_reminder": "PS: Se il profitto finale ((Costro del prodotto + Costi di spedizione) × Profitto % + Profitto fisso) è inferiore al Profitto minimo impostato, allora sarà applicata la seguente formula: Valore del prezzo = [(Costro del prodotto + Costi di spedizione) + Profitto minimo]/ (1 - Breakeven %)",
            "advanced_rest_price": "Il resto degli intervalli",
            "advanced_cost_range": "Intervallo del Costo del Prodotto (USD)",
            "profit": "Profitto%",
            "fixed_profit": "Profitto Fisso",
            "min_profit": "Profitto Minimo",
            "breakeven": "Breakeven%",
            "shippingCostReminder": "Il costo di spedizione verrà calcolato in base al metodo di spedizione selezionato di default in Impostazione costi di spedizione.",
            "breakevenReminder": "Imposta un punto di pareggio per coprire le spese del canale, le spese di pagamento o altre spese che possono avere",
            "shipping_cost": "Costo di Spedizione",
            "add_shipping_cost": "Aggiungi Costo di Spedizione",
            "shipping_title": "Impostazione delle Spese di Spedizione",
            "shipping_reminder": "Calcoleremo le spese di spedizione in base al metodo di spedizione scelto. Puoi anche scegliere di inserire un costo di spedizione medio specifico se consegni in più paesi.",
            "product_cost_setting": "Costo del prodotto",
            "product_cost_settingVal": "Il prezzo del prodotto sarà fissato in base al prezzo nazionale rispettivo alla destinazione di consegna del prodotto. Per informazioni, <a href=\"https://help.dsers.com/import-product-cost-for-shopify/\"  style=\"color:#3D5AFE\" >clicca qui</a>",
            "product_cost_reminder": "Quando importi il prodotto e nelle notifiche, calcoleremo il costo del prodotto e notificheremo i cambiamenti in base al paese che hai scelto. Questo sarà in vigore solo dopo che lo avrai impostato e i dati precedenti non saranno cambiati (supporteremo gradualmente più paesi).",
            "fixed_cost": "Costo di spedizione specifico",
            "ranges_overlap": "Le tue gamme si sovrappongono.",
            "ranges_start_greater_end": "Il valore finale dell'intervallo dei costi deve essere maggiore o uguale al valore iniziale.",
            "assign_cents": "Assegna centesimi",
            "assign_cents_description": "Puoi stabilire uno specifico valore in centesimi per il tuo prezzo al dettaglio. Utilizzeremo questo valore in fase di definizione del costo finale dei tuoi articoli (ad esempio, se vuoi che il prezzo del tuo prodotto sia XX.99, allora aggiungi 99 nei campi sottostanti).",
            "assign_compared_at_cents": "Assegna prezzo comparato al centesimo",
            "need_link_store": "Devi prima collegarti a uno store Shopify.",
            "save_succ": "Salvataggio avvenuto con successo",
            "standard_more": "Clicca <a href=\"https://help.dsers.com/standard-pricing-rule-for-shopify/\" target=\"_blank\">qui</a> per avere maggiori informazioni.",
            "advanced_more": "Clicca <a href=\"https://help.dsers.com/advanced-pricing-rule-for-shopify/\" target=\"_blank\">qui</a> per avere maggiori informazioni.",
            "currency": "Currency",
            "apply_go": "Applica tasso di cambio alle politiche di prezzo quando sponsorizzi i prodotti sul tuo Shopify.  If you want to activate this feature, please go to",
            "apply_to": "to set up currency for your stores",
            "apply_to_mes": "Applica il tasso di cambio ai prezzi quando pubblichi prodotti su Shopify. Le regole globali dei prezzi funzionano diversamente se NON stai vendendo in USD su Shopify e non vuoi attivare questa funzione. Calcola i moltiplicatori di conseguenza.",
            "apply_btn": "Setting - Stores",
            "bigTit": "The Price you set is higher than the Compared at price, please check and save",
            "inputerr100": "Value range: 0% to 99%",
            "basicpricerule": " Politica di prezzi base",
            "standardpricerule": "Politica di prezzi standard",
            "advancedpricerule": "Politica di prezzi avanzata",
            "setpricing": "Impostare dettagli politica dei prezzi",
            "pricing_rule_new_title": "Modelli dei prezzi",
            "pricing_rule_new_dsec": "I modelli dei prezzi ti consentono di impostare i prezzi facilmente. Seleziona il negozio a cui vuoi applicare i modelli. Visualizza e controlla i dettagli delle norme sui prezzi per personalizzare maggiormente i prezzi dei tuoi prodotti."
        },
        "billing": {
            "activate_google_auth": "Ti raccomandiamo caldamente di attivare Google <br /> Authenticator per assicurare la sicurezza del tuo account",
            "activate": "ATTIVA",
            "remaining_credit": "Credito restante",
            "make_a_payment": "EFFETTUA UN PAGAMENTO",
            "make_a_payment_tip": "Per poter utilizzare il tuo indirizzo di fatturazione, devi prima inserirlo.",
            "last_payment": "{key, plural, =q1 {Il tuo ultimo pagamento è stato effettuato il} =q2 {per USD} =q3 {ed è in sospeso su PayPal.} =q4 {pagamento fallito su PayPal. Si prega di controllare e di effettuare di nuovo il pagamento.} }",
            "transactions": "Transazioni",
            "date": "Data",
            "description": "Descrizione",
            "amount": "Importo (USD)",
            "purchase_record": "Registro degli acquisti",
            "download": "Scaricare",
            "all_bills": "Tutte le fatture",
            "save_succ": "Salvataggio avvenuto con successo",
            "save_error": "Salvataggio fallito",
            "google_auth": {
                "step1": "Step 1 : Scarica Google Authenticator",
                "step1_description": "Per gli utenti IOS : effettuare il log in nell'AppStore e cercare Google Authenticator; Per gli utenti Android : effettuare il log in nell'app store o usare il browser per scaricare Google Authenticator.",
                "step2": "Step 2 : Configura Google Authenticator",
                "scan_qr_code": "IOS/Android : scannerizza il codice QR",
                "code": "Se la scannerizzazione del codice non funziona, puoi inserire il codice di verifica.",
                "please_save": "Utilizzato per recuperare Google Authenticator, si prega di salvare."
            },
            "billing": {
                "billing_information": "Informazioni di fatturazione",
                "business_name": "Ragione sociale",
                "business_name_error": "Si prega di inserire una ragione sociale",
                "email": "Email",
                "email_not_valid": "L'email inserita non è valida!",
                "email_null_error": "Si prega di inserire un'email!",
                "phone": "Telefono",
                "phone_error": "Si prega di inserire una ragione sociale",
                "city": "Città",
                "city_error": "Si prega di inserire una città",
                "currency": "Valuta",
                "currency_error": "Si prega di inserire una ragione sociale",
                "country": "Stato",
                "country_error": "Si prega di inserire uno Stato",
                "address": "Indirizzo",
                "address_error": "Si prega di inserire un indirizzo",
                "post_code": "Codice postale",
                "province": "Provincia",
                "province_error": "Si prega di inserire una provincia",
                "company": "Compagnia",
                "send_this_address": "Inviare un campione a questo indirizzo"
            },
            "primary": {
                "title": "Contatto primario",
                "name": "Nome",
                "name_error": "Si prega di inserire un nome",
                "use_billing_address": "Utilizzare come indirizzo di fatturazione",
                "send_sample_to_this_address": "Inviare un campione a questo indirizzo"
            }
        },
        "verified": {
            "title": "Fornitore Verificato",
            "img_max_1mb": "Le dimensioni dell'immagine non possono superare 1MB!",
            "bag_default": "Borsa (predefinita e gratuita)",
            "bag_box": "Scatola ($0.2/pacchetto)",
            "bag_box_logo": "Scatola con logo ($0.2/pacchetto)",
            "bag_box_card": "Scatola con supporto rigido ($0.2/pacchetto)",
            "bag_box_logo_card": "Scatola con logo e supporto rigido ($0.2/pacchetto)",
            "upload": "Uploadare",
            "package_request": "Richiesta di pacchetto",
            "logo_file": "File per il logo",
            "note": "Appunto"
        },
        "report": {
            "timezone": {
                "title": "Fuso orario",
                "desc": "Il tuo fuso orario predefinito è basato sulle impostazioni del tuo browser. Puoi riselezionare un fuso orario qui per il tuo account Dsers. MA si prega di notare che si può impostare il fuso orario una sola volta, e dopo il salvataggio non potrà più essere modificato.",
                "save_modal_desc": "Puoi impostare il fuso orario una sola volta. Si prega di accertarsi di aver selezionato quello corretto. Non potrà più essere modificato.",
                "save_modal_tip": "Si prega di inserire CONFERMARE per salvare"
            },
            "currencies": {
                "title": "Valute",
                "desc": "Il dollaro è la valuta di default su DSers. Puoi impostarlo per qualunque negozio e la valuta selezionata sarà applicata ai prodotti AliExpress e ai prodotti su DSers. I dati mostrati su DSers potrebbero essere diversi da quelli che vedi su Shopify o AliExpress, a causa dei tassi di cambio fluttuanti. Puoi impostare la tua valuta soltanto una volta per negozio. Se decidi di cambiare la valuta, <a href=\"mailto:support@dserspro.com\">contatta il supporto.</a>",
                "store": "Negozio",
                "currency": "Valuta",
                "no_store_tip": "Si prega di collegarsi a un negozio per poter selezionare le impostazioni.",
                "save_modal_title": "Puoi cambiare la valuta una sola volta!",
                "save_modal_desc": "Si prega di notare che si può cambiare valuta una sola volta. Si prega di digitare CONFERMARE qui sotto per salvare le impostazioni.",
                "input_confirm": "Si prega di inserire CONFERMARE"
            }
        },
        "other": {
            "default_phone_number_placeholder": "Scegliere uno Stato",
            "phone_rules_SA_AE_IL": "Sono richiesti numeri a 9 cifre che inizino con 5",
            "phone_rules_FR": "Sono richiesti numeri di cellulare a 10 cifre.",
            "phone_rules_PT": "Sono richiesti numeri a 9 o 10 cifre",
            "phone_rules_BR_JP": "Sono richiesti numeri a 10 o 11 cifre",
            "phone_rules_KR": "Please enter 10 or 11-digit phone number starting with 01 or 1 (e.g. 01012345678)",
            "sync_ali_order_number": "Sincronizza il numero dell'ordine di AliExpress con i dettagli aggiuntivi di Shopify",
            "sync_ali_order_number_tip": "Se questa impostazione è attivata, il numero del tuo ordine AliExpress verrà sincronizzato automaticamente con la pagina dei dettagli degli ordini di Shopify.",
            "canceled_orders_optimization": "Ottimizzazione degli Ordini Cancellati.",
            "canceled_orders_optimization_tip": "Attiva questa impostazione per ottimizzare automaticamente un ordine cancellato da AliExpress. L'ottimizzazione da parte di Dsers ridurrà notevolmente la probabilità che l'ordine venga cancellato di nuovo.",
            "synchronize_shopify_order_notes": "Sincronizza le note sugli ordini di Shopify",
            "synchronize_shopify_order_notes_tip": "Activate this feature to automatically synchronize Shopify order notes. DSers will help to send the notes of an order from Shopify to AliExpress along with orders.",
            synchronize_shopify_order_message: "Synchronize Shopify order customization information",
            synchronize_shopify_order_message_tip:"Activating this feature automatically synchronize Shopify order customization information. DSers will help you send the customization information from Shopify to suppliers along with the order.",      
            "leave_a_meaage": "Lascia un messaggio ai rivenditori",
            "leave_a_meaage_tip": "I rivenditori riceveranno questo messaggio insieme alle tue informazioni dell'ordine.",
            "message_placeholder": "Inserisci un massimo di 1000 caratteri (o 250 caratteri cinesi)",
            "ioss_message": "IOSS",
            "ioss_message_tip": "Se si desidera pagare l'IVA direttamente anziché pagarla attraverso AliExpress, è necessario aggiungere uno IOSS ID alle informazioni del compratore dei tuoi ordini AliExpress spediti nell'Unione Europea.",
            "ioss_message_tip_2": "Si prega di chiedere ai venditori AliExpress di aggiungere lo IOSS ID all'ordine, e di fornire inoltre lo IOSS ID al gestore dei servizi di spedizione, così che i pacchetti possano passare le dogane normalmente. È estremamente importante che i venditori vengano contattati per assicurarsi che tutto venga fatto.",
            "ioss_message_info": "(Il tuo account AliExpress deve essere verificato come Acquirente Aziendale per poter aggiungere una Partita IVA su AliExpress, e poter poi dichiarare di pagare l'IVA per conto proprio.) Lo IOSS ID impostato qui sarà automaticamente aggiunto alla voce Indirizzo 2 degli ordini AliExpress nel momento in cui li effettui su AliExpress. Se modifichi lo IOSS ID qui, il nuovo ID verrà applicato solo ai nuovi ordini effettuati su AliExpress.",
            "ioss_message_error": "Si prega di inserire uno IOSS ID corretto per evitare un fallimento nel piazzare gli ordini su AliExpress o che i pacchetti vengano fermati alle dogane.",
            "default_phone_number": "Imposta il numero di telefono predefinito",
            "default_phone_number_tip": "Se un ordine non include un numero di telefono, il sistema utilizzerà il numero di telefono predefinito che hai impostato.",
            "override_phone_number": "Sostituisci il numero di telefono",
            "override_phone_number_uae": "Per ordini spediti in Arabia Saudita, Emirati Arabi Uniti e Israele, sono richiesti numeri di 9 cifre che inizino con 5.",
            "override_phone_number_fr": "Per ordini spediti in Francia, sono richiesti numeri di cellulare da 10 cifre che inizino con 06.",
            "override_phone_number_pt": "Per ordini spediti in Portogallo, sono richiesti numeri da 9 o 10 cifre.",
            "override_phone_number_br": "Per ordini spediti in Brasile, sono richiesti numeri da 10 o 11 cifre.",
            "override_phone_number_jp": "Per ordini spediti in Giappone, sono richiesti numeri da 10 o 11 cifre.",
            "override_phone_number_ko": "Per ordini spediti in Corea, sono richiesti numeri da 10 o 11 cifre che inizino con 01 o 1.",
            "override_phone_number_intro_start": "Si prega di notare che:",
            "override_phone_number_intro_end": "Si prega di compilare di conseguenza.",
            "override_phone_number_tip": "Sostituisci il numero di telefono del cliente con il tuo numero quando effettui un ordine presso i rivenditori.",
            "france_phone_number_tip": "Si prega di notare che per tutti gli ordini spediti in Arabia Saudita ed Emirati Arabi Uniti, sono richiesti numeri di 9 cifre che inizino con 5.",
            "sync_shopify_orders": "Sincronizza gli ordini di Shopify",
            "sync_shopify_orders_tip": "DSers sincronizzerà automaticamente gli ordini degli ultimi 7 giorni. Scegliere un intervallo di date per sincronizzare quanti ordini si desidera. È possibile sincronizzare fino a 30 giorni per volta.",
            "sync_shopify_orders_error": "È possibile sincronizzare fino a 30 giorni per volta.",
            "select_store_placeholder": "Seleziona un negozio",
            "sync": "Sincronizza",
            "affiliate_program": "Programma di affiliazione",
            "affiliate": "Affiliato",
            "affiliate_desc": "Hai attivato il tuo cashback Admitad",
            "change_code": "Cambia il codice",
            "change_code_wraming": "Sei sicuro di voler cancellare l'attuale codice?",
            "step": "Step",
            "new_affiliate_tip": "Registra un NUOVO account Admitad attraverso il link sottostante",
            "why_affiliate": "Perché ho bisogno di un nuovo account Admitad?",
            "get_affiliate_btn": "Ottieni un nuovo account Admitad",
            "set_affiliate": "Configura il tuo account Admitad.",
            "how_to_set": "Vuoi controllare come configurarlo?",
            "fill_affiliate": "Inserisci il tuo codice di affiliazione e il tuo nome account.",
            "where_code": "Dove trovare il codice e il nome account?",
            "code": "Codice",
            "err_tip": "Questo non è un codice di affiliazione DSers. Si prega di ricontrollare.",
            "message_ae_sellers": "Il messaggio è stato salvato con successo. I rivenditori lo riceveranno quando effettuerai un ordine.",
            "save_phone_succ": "Numero di telefono salvato con successo.",
            "cash_back_code_error": "Questo non è un codice valido. Il codice dovrebbe contenere 30 lettere e numeri. Si prega di ricontrollare.",
            "cost_error": "Si prega di inserire un numero nel formato di 5 o di 5.99",
            "create_success": "Creazione avvenuta con successo",
            "admitad_stopping_now": "Il tuo cashback Admitad si interrompe ora. Si prega di assegnare un nuovo codice a DSers. <a href={href} target=\"_blank\">Controlla perché e come</a>",
            "notice": "Il nostro sistema ha rilevato che il tuo account Admitad non può essere verificato. <br /> Si prega di assicurarsi di aver creato l'account Admitad attraverso&nbsp; <a href=\"https://store.admitad.com/en/promo/?ref=8e3xmmxnjp\" target=\"_blank\">Link affiliato DSers</a> &nbsp;poi cambia di conseguenza il tuo codice su DSers. <br /> Se il tuo account Admitad è stato registrato attraverso&nbsp; <a href=\"https://store.admitad.com/en/promo/?ref=8e3xmmxnjp\" target=\"_blank\">Link affiliato DSers</a> , ma ancora non può essere verificato, si prega di ricontrollare di aver inserito il nome account corretto su DSers.",
            "notice_sub": "Si prega di notare che l'attuale account Admitad verrà disabilitato fra 48 ore.",
            "notice_add_title": "About Admitad Account",
            "notice_2": "Per assicurarci che il tuo account Admitad funzioni bene insieme a DSers, si prega di andare su Impostazioni - Altro - Programma di affiliazione, per completare l'attivazione. In caso contrario il tuo codice Admitad verrà disabilitato entro 48 ore.",
            "not_account_name": "Si prega di inserire il tuo nome account Admitad.",
            "notice_button_change": "Cambia nome account",
            "notice_button_register": "Registrati adesso",
            "cancel_fulfillment_title": "Cancellazione della Richiesta di Completamento",
            "cancel_fulfillment_desc": "Dopo aver attivato la funzione dell'app corrispondente qua sotto, gli ordini che non potranno essere effettuati dall'app perché hai cliccato Richiesta di Completamento su Shopify, verranno ripristinati così che tu possa effettuarli normalmente su AliExpress.",
            "aliSku": "Usa l'ID del rivenditore come SKU del prodotto",
            "aliSku_desc": "Dopo l'attivazione, quando importi i prodotti del rivenditore nellal Lista Importazioni, lo SKU del prodotto userà le varianti di nome ID del rivenditore del prodotto.",
            "vat_title": "Quando il costo di un ordine effettuato in Francia eccede i 150 euro, dovrai fornire la tua Partita IVA e la tua ragione sociale. Si prega di completare le informazioni nel seguente ordine, così da non influenzare il normale processo.Clicca qui per avere maggiori informazioni.",
            "vat_id_verify": "Si prega di inserire una Partita IVA da 8-12 cifre",
            "vat_input_verify": "Si prega di inserire una Partita IVA",
            "vat_company": "Si prega di inserire una ragione sociale",
            "vat_h3": "IVA",
            "plan_info_id": "Partita IVA",
            "plan_info_name": "Ragione sociale",
            "phone_rules_IT": "It requires 9 or 10 digits mobile phone numbers.",
            "set_product_title": "Imposta prodotti come tassabili",
            "set_product_subtitle": "Specificare se verrà addebitata una tassa non appena la variante del prodotto sarà inviata.",
            "ioss_message_more": "Clicca <a href=\"https://www.dsers.com/blog/eu-vat-reform/\" target=\"_blank\">qui</a> per avere maggiori informazioni..",
            "override_phone_number_it": "For orders sent to Italy, it requires 9 or 10 digits numbers.",
            "override_phone_number_intro_start_new": "Si prega di notare che:",
            "override_phone_number_first": "richiede un numero di 9 cifre che inizia con 5.",
            "override_phone_number_second": "è necessario un numero di 9 cifre che inizia con 5 o un numero di 10 cifre che inizia con 0.",
            "override_phone_number_third": "richiede un numero di 9 o 10 cifre.",
            "override_phone_number_fourth": "richiede un numero di 10 o 11 cifre.",
            "override_phone_number_fifth": "richiede un numero di 10 o 11 cifre che inizia con 01 o 1.",
            "phone_rules_SA_AE_IL_new": "Richiede un numero di 9 cifre che inizi con 5 o un numero di 10 cifre che inizi con 0.",
            "phone_rules_FR_new": "Richiede un numero di telefono di 9 o 10 cifre.",
            "order_save": "SAVE",
            "order_fulfillment_module": "Impostazioni di completamento",
            "order_fulfillment_first_title": "Completa gli articoli separatamente",
            "order_fulfillment_first_content": "Dopo aver attivato questa funzione, DSers completerà ciascun prodotto negli ordini separatamente e azionerà l'e-mail di conferma di spedizione Shopify con le seguenti regole: Quando un prodotto in un ordine DSers ha un numero di tracking, completeremo quell'articolo nell'ordine e poi Shopify invierà un'e-mail al cliente. Shopify invierà diverse e-mail per lo stesso ordine.",
            "order_fulfillment_second_title": "Completa articoli in massa",
            "order_fulfillment_second_content": "Dopo aver attivato questa funzione, DSers completerà un ordine dopo che tutti i prodotti dell'ordine hanno un numero di tracking e azionerà Shopify per inviare una e-mail al cliente.",
            "order_split_pop_title": "Commento",
            "order_split_pop_content": "Per continuare a migliorare la stabilità di DSers e l'esperienza dei rivenditori, se disattivi questa funzionalità non sarà più accessibile. Sei sicuro di voler smettere di usare questa funzione?",
            "order_split_pop_btn": "CONFERMA"
            },
            "payment": {
                'top_title':"Informazioni commerciali",
                'top_small_title': 'Usato sui riepiloghi degli ordini Tmal.',
                'top_form_item1': 'Nome',
                'top_form_item2': 'cognome',
                'top_form_item3': 'Nome azienda',
                'top_form_item4': 'Email',
                'top_form_item5': 'Paese',
                'top_form_item6': 'Indirizzo',
                'top_form_item7': 'Appartamento, suite, ecc.',
                'top_form_item8': 'Città',
                'top_form_item9': 'Stato',
                'top_form_item10': 'Cap',
                'bottom_title': "Riepiloghi Tmall",
                'bottom_small_title':"Controlla e scarica riepiloghi mensili sugli ordini Tmall. (Aggiorna il riepilogo del mese precedente il x di ogni mese)",
                'bottom_state1': "Richiesta",
                'bottom_state2':"Nella richiesta"
            },
        "tab_sub_account": "Personale dell'account",
        "tab_sub_supplier":'Rivenditore',
        "tab_stores": "Negozi",
        "tab_product": "Prodotto",
        "tab_order": "Ordine",
        "tab_paypal": "PayPal",
        "tab_sync": "Auto Update ",
        "tab_aff": "Programma di affiliazione",
        "priceUpdate": {
            "suppliers_price": "Quando il prezzo del tuo rivenditore",
            "increase": "aumenta",
            "decrease": "diminuisci",
            "pricing_change": "E il cambiamento di prezzo supera",
            "anychange": "Nessun cambiamento",
            "title": "Aggiornamento prezzi automatico",
            "auto_updates_tip": "Dopo aver attivato l'aggiornamento automatico dei prezzi, DSers sincronizzerà automaticamente il prezzo aggiornato su Shopify in base alle politiche dei prezzi delle ultime 24 ore, se il rivenditore cambia il prezzo di più di $1",
            "note": "Please note: You need to have the Pricing Rule feature activated to use this feature. If you change the price in Shopify, we will still update the price on Shopify according to the pricing rule you set on DSers. Update range: 10 days of ordered products & browsed products",
            "supplier": " Quando il rivenditore cambia il prezzo, vuoi che Dsers:",
            "updateCon": "Aggiorna prezzo e confronta prezzi su negozio Shopify",
            "do_nothing": "Non fare nulla",
            "to_shopify": "Aggiorna prezzo nel negozio Shopify",
            "cost_change": "Update price when the cost changes",
            "to_setting": "Vai in impostazioni e attivalo",
            "to_price": "This store does not have pricing rules",
            "check_error": "Please check the price fluctuation method",
            "range": "The range cannot be 0",
            "priceError": "The store is disconnected, please go to Setting - Account to reconnect it"
        },
        "order": {
            "input_placeholder": "Enter the product's AliExpress link for which you want to split orders",
            "order_same": "The product link, quantity and country you configured are exactly the same, please check and save",
            "title": "Split automatico ordini",
            "desc": "Effettueremo lo split dell'ordine per te in base al link e alla quantità del prodotto AliExpress impostato. Regole di split degli ordini: quando il prodotto dell'ordine è il fornitore impostato, e la quantità del prodotto è superiore o uguale alla quantità impostata nelle impostazioni, che deve essere inferiore a 10, noi effettueremo automaticamente lo split dell'ordine in diversi ordini Shopify.",
            "inputNumber": "Please enter the amount you want to split",
            "incorrect": "The product link you entered is incorrect",
            "banner_title": "Automatically split orders",
            "banner_text": "Having issue when using cost-effective AliExpress Saver Shipping? Check this solution.",
            "banner_btn": "learn more",
            "titip": "Order",
            "delivery_period_tip": "Minimo 2, massimo 10",
            "delivery_period": "Quantità di prodotti per effettuare lo split",
            "split_ending": "Splitting orders",
            "delete_dsers": "This product had been deleted in DSers"
        },
        "subaccount": {
            "add": "Aggiungi account staff",
            "sendemail": "Invieremo una e-mail all'indirizzo che hai inserito per completare l'autorizzazione, e i tuoi dipendenti possono accedere a DSers con questo account",
            "admintext": "Tutti i permessi inclusi i permessi Admin",
            "fulltext": "Tutte le pagine e le funzionalità, ma nessun permesso Admin",
            "limittext": " Mostra il proprietario in base alla pagina e al negozio configurato",
            "emailempty": "Please input the email address",
            "emailformat": "Inserisci un indirizzo e-mail valido",
            "active": "Attivo",
            "NoAccess": "No page access",
            "NoAccessTitle": "You do not have access to the current page, please contact your administrator",
            "report": "Segnala",
            "ImportList": "Importa lista",
            "MyProduct": "I miei prodotti",
            "Openorder": "Apri Ordine",
            "ArchivedOrder": "Ordini archiviati",
            "Tracking": "N° Tracking",
            "CSVBulkOrder": "Ordine all'ingrosso CSV",
            "LinktoMoreStores": "Collegati a più negozi",
            "Setting": "Impostazione",
            "Notification": "Notifiche",
            "fullaccess": "Accesso completo",
            "admin": "Amministrazione",
            "Limitedaccess": "Accesso limitato",
            "Full": "Completo",
            "Storeaccess": "Accesso al negozio",
            "Featureaccess": "Accesso funzionalità",
            "invited": "invitato",
            "Notconnected": "Non connesso"
        },
        "product": {
            "customize_title": "Personalizza la descrizione del prodotto",
            "customize_con": " Quando sponsorizzi i prodotti, vuoi che Dsers:",
            shipping_cost_title:'Invia le informazioni del prodotto a Shopify',
            "customize_specifications": "Spingi solo le SPECIFICHE dei prodotti",
            "customize_overview": "Spingi solo le PANORAMICHE dei prodotti",
            "customize_all": "Push both SPECIFICATIONS & OVERVIEW",
            "location_title": "Product fulfillment service management",
            "location_content": "Puoi decidere quale servizio di completamento per i prodotti e gli ordini sulla piattaforma DSers ti aiuterà a gestire lo switch del servizio di completamento. Se attivi lo switch, potrai aggiornare automaticamente le informazioni del prodotto in base al servizio di completamento e a mostrare gli ordini corrispondenti a quel servizio di completamento. Se lo disattivi, non aggiornerà automaticamente le informazioni del prodotto corrispettivi al servizio di completamento per te, e non mostrerà gli ordini corrispettivi a quel metodo di completamento.",
            shipping_cost_tip1:'Invii il costo dei prodotti del rivenditore a Shopify.',
            shipping_cost_tip2:`Una volta che il prodotto viene messo in vendita, il costo dei prodotti del rivenditore e le spese di spedizione in [Polizza dei prezzi avanzata] vengono riuniti nel costo per articolo di Shopify. Ecco alcuni motivi per cui le spese di spedizione potrebbero essere a 0: <br />
            1. Dopo aver applicato le Norme avanzate in materia di prezzi, il metodo di spedizione rispetto al relativo paese è gratuito, e le spese riportate in Shopify sono a 0; <br />
            2. Dopo aver applicato le Norme avanzate in materia di prezzi, non è stato abbinato alcun metodo di spedizione. Le spese di spedizione riportate in Shopify sono a 0; <br />
            3. Dopo aver applicato le Norme Basic/Standard in materia di prezzi, non c'è alcuna spesa di spedizione, e le spese riportate in Shopify sono a 0; <br />
            4. Le Norme in materia di prezzi non sono consentite, e non c'è alcuna spesa di spedizione. Le spese riportate in Shopify sono a 0. <br />
            Devi abbonarti e applicare le Norme avanzate in materia di prezzi per fare in modo che DSers ti aiuti a riportare il costo del prodotto e le spese di spedizione in Shopify.`
        },
        "product_price": "Aggiornamento prezzo prodotto",
        "title_account": "Account",
        "title_time_zone": "Fuso Orario",
        "time_zone_des": "Il tuo fuso orario predefinito è basato sulle impostazioni del tuo browser. Puoi riselezionare un fuso orario qui per il tuo account Dsers. MA si prega di notare che si può impostare il fuso orario una sola volta, e dopo il salvataggio non potrà più essere modificato.",
        "title_ae_account": "Account AliExpress",
        "ae_account_des": "Nota che se ti colleghi ad un altro account, le informazioni degli ordini effettuati nell'account corrente non saranno più aggiornate su DSers dopo la disconnessione",
        "title_synchronization": "Sincronizzazione",
        "title_automatic": "Aggiornamento prezzi automatico",
        "automatic_des": "Dopo aver attivato la funzione di aggiornamento automatico del prezzo, DSers sincronizzerà automaticamente il prezzo aggiornato su Shopify secondo le regole di prezzo da te impostate ogni 24 ore se il fornitore cambia il prezzo per più di $1.",
        "automatic_des2": "Please note: You need to have the Pricing Rule feature activated to use this feature. If you change the price in Shopify, we will update the price on Shopify according to the set pricing rule you on DSers.",
        "title_update_pro_pr": "Cambia manualmente i prezzi dei prodotti del negozio ",
        "update_pro_pr_des": "Aggiorna il prezzo dei prodotti sponsorizzati sul tuo negozio Shopify in base alle politiche dei prezzi da te fissate. Il processo potrebbe richiedere del tempo. Si può usare questa funzione ogni 30 giorni..",
        "title_sync_shop_order": "Sincronizza gli ordini di Shopify",
        "sync_shop_order_des": "DSers sincronizzerà automaticamente gli ordini degli ultimi 7 giorni. Scegliere un intervallo di date per sincronizzare quanti ordini si desidera. È possibile sincronizzare fino a 30 giorni per volta.",
        "title_order_details_sync": "Sincronizzazione dettagli ordine",
        "title_affiliate_program": "Programma di affiliazione",
        "title_notification": "Notifica",
        "title_shipping_confirmation": "Conferma di spedizione",
        "title_shipping_confirmation_des": "Invia la mail di conferma di spedizione Shopify automaticamente al tuo cliente una volta che è stato generato il numero di tracking",
        "title_paypal": "Invia numero di tracking a PayPal",
        "title_tracking": "Tracking",
        "title_tracking_tip": "Attiva questa funzione per tracciare lo stato di tracking degli ordini spediti.",
        "title_leave_message": "Lascia un messaggio ai rivenditori",
        "title_leave_message_ae":"AliExpress suppliers will get this message together with your order information.",
        "title_leave_message_tmall":"Tmall suppliers will get this message together with your order information, but the message edition is not supported now.",
        "message_des": "I rivenditori riceveranno questo messaggio insieme alle tue informazioni dell'ordine.",
        "title_phone_number_op": "Ottimizzazione numero di telefono",
        "title_automatic_order_split": "Split automatico ordini",
        "automatic_order_split_des": "Effettueremo lo split dell'ordine per te in base al link e alla quantità del prodotto AliExpress impostato. Regole di split degli ordini: quando il prodotto dell'ordine è il fornitore impostato, e la quantità del prodotto è superiore o uguale alla quantità impostata nelle impostazioni, che deve essere inferiore a 10, noi effettueremo automaticamente lo split dell'ordine in diversi ordini Shopify.",
        "title_order_management": "Gestione ordini",
        "title_tax": "Tax",
        "title_shipping": "Spedizione",
        "title_add_global_shipping": "Aggiungi metodo di spedizione globale basato sul prodotto",
        "add_global_shipping_des": "Imposta il tuo metodo di spedizione standard per specifici rivenditori di prodotti. L'impostazione del prodotto ha una priorità più elevata rispetto a quella del tuo paese. Puoi avere un accesso rapido a questa funzione andando su Mappatura - Più azioni.",
        phone_number_des:"In order for your package to the Netherlands and Finland to be delivered properly, please fill in a real phone number in the order. The Netherlands and Finland do not support the use of the Phone Number Optimization feature for the time being. Click  <a href=\"https://help.dsers.com/fix-failed-orders-due-to-aliexpress-phone-number-regulations/\" target=\"_blank\">here</a> to learn about how to solve this problem",
        phone_number_des_tip:"This country is not currently supported",
        "title_pricing_rule": "Regola dei Prezzi",
        "title_product": "Prodotto",
        "title_sp_store": "Negozi Shopify",
        "sp_stores_des": "Puoi gestire i tuoi negozi Shopify qui. Se vuoi disinstallare DSers da, non dimenticare di disinstallare anche da qui.",
        "title_currencies": "Valute",
        "disablestext": "Select a store",
        "password": "Password",
        "admitadAccount1": "Account Admitad sospeso a causa di mancata verifica. ",
        "admitadAccountLink": "Clicca qui ",
        "admitadAccount2": "per ricevere maggiori informazioni.",
        "refresh_store_tag": "Riavvia connessione",
        "refresh_store_tag_tip": 'Se lo stato del negozio Shopify non viene aggiornato per motivi di rete, clicca su "Riavvia connessione" per ottenere lo stato del negozio',
        "refresh_not_connected":"Non connesso",
        "refresh_store_icon_tip": "Refresh connect",
        "affiliate_code": "Codice affiliazione",
        "affiliate_code_btn": "RIATTIVA",
        "hasSetCurrency": "Applica tasso di cambio alle politiche di prezzo quando sponsorizzi i prodotti sul tuo Shopify. Le politiche di prezzo globali funzionano diversamente se NON stai vendendo in dollari su Shopify e non vuoi attivare questa funzione. Calcolare i moltiplicatori di conseguenza.",
        "pricing_rule_dsec": "Le norme sui prezzi includono valuta, modelli dei prezzi e Assegna centesimi. Puoi creare qui le tue norme sui prezzi e applicarle ai tuoi prodotti.",
        "updateProductPrice": "Aggiorna il prezzo dei prodotti promossi sul tuo negozio Shopify secondo le regole dei prezzi da te impostate. Questa azione potrebbe richiedere del tempo. Clicca <a href=\"/app/pricing?select=1\"> qui </a> per aggiornare il piano per aumentare i tempi di sincronizzazione. ",
        ...agency
    }
}