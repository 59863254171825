export default {
  guide: {
    // 升级弹窗文案
    CommonGuide: [
      {
        type: 1,
        title: 'Actualizar Plan',
        content:'Tu plan actual es el Básico, por favor actualízalo al plan Avanzado si quieres tener acceso a esta función, al mapeo avanzado y al servicio de atención al cliente 24 horas.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Actualizar',
        otherBtn: 'Cancelar'
      },
      {
        type: 2,
        title: 'Actualizar Plan',
        content:'Tu plan actual es Avanzado, por favor, actualiza al plan Pro si quieres tener acceso a esta función y a la gestión de más de 70.000 productos.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Actualizar',
        otherBtn: 'Cancelar'
      },
      {
        type: 3,
        title: 'Personalización',
        content:'Tu plan actual es el más alto, si quieres aumentar los límites, podemos ofrecerte servicios personalizados.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Contáctanos',
        otherBtn: 'Cancelar'
      },
      {
        type: 4,
        title: 'Actualizar Plan',
        content:'Tu plan actual es el Estándar, por favor, actualiza al plan Avanzado si quieres tener acceso a esta función, al mapeo avanzado y al servicio de atención al cliente 24 horas.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Actualizar',
        otherBtn: 'Cancelar'
      },
      {
        type: 5,
        title: 'Personalización',
        content:'Tu plan actual es el más alto, si quieres aumentar los límites, podemos ofrecerte servicios personalizados.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Contáctanos',
        otherBtn: 'Cancelar'
      },
      {
        type: 6,
        title: 'Personalización',
        content:'Tu plan actual es el más alto, si quieres aumentar los límites, podemos ofrecerte servicios personalizados.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Contáctanos',
        otherBtn: 'Cancelar'
      },
      {
        type: 7,
        title: 'Actualizar Plan',
        content:'Tu plan actual es Avanzado, por favor, actualiza al plan Pro si quieres tener acceso a esta función y a la gestión de más de 70.000 productos.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Actualizar',
        otherBtn: 'Cancelar'
      },
      {
        type: 8,
        title: 'Actualizar Plan',
        content:'Tu plan actual es el Estándar, por favor, actualiza al plan Avanzado si quieres tener acceso a esta función, al mapeo avanzado y al servicio de atención al cliente 24 horas.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Actualizar',
        otherBtn: 'Cancelar'
      },
      {
        type: 9,
        title: 'Personalización',
        content:'Tu plan actual es el más alto, si quieres aumentar los límites, podemos ofrecerte servicios personalizados.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Contáctanos',
        otherBtn: 'Cancelar'
      },
      {
        type: 10,
        title: 'Actualizar Plan',
        content:'Tu plan actual es el Básico personalizado, por favor actualízalo al plan Avanzado si quieres tener acceso a esta función, al mapeo avanzado y al servicio de atención al cliente 24 horas.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Actualizar',
        otherBtn: 'Cancelar'
      },
    ],
    // 开屏弹窗文案
    OBDialog: {
      bindAE: {
        top:
          '¡Nos alegramos de que te unas a nosotros! Hemos migrado con éxito todos tus datos, pedidos, productos y ajustes!',
        topTitle: '¡Bienvenido(a) a DSers!',
        centerTitle: '¡Migración exitosa!',
        btn: 'LO TENGO'
      },
      noBindAE: {
        top:
          '¡Nos alegramos de que te unas a nosotros! Hemos migrado con éxito todos tus datos, pedidos, productos y ajustes!',
        centerTitle: '¡Migración exitosa!',
        topTitle: '¡Bienvenido(a) a DSers!',
        bottom: [
          'Para empezar a usar DSers, por favor, vincula tu cuenta AliExpress.'
        ],
        btn: 'Vincular AliExpress'
      }
    },
    //底部banner文案
    OBanberWords: [
      {
        // 绑定AE
        step: 1,
        title: 'Vincula tu cuenta de AliExpress',
        text:
          "Al vincular tu cuenta de AliExpress con DSers podrás procesar más de 100 pedidos en segundos. Haz clic <a target='_blank' href='https://help.dsers.com/link-your-shopify-store/'>aquí</a> para más detalles.",
        btn: 'VINCULAR ALIEXPRESS'
      },
      {
        // 确认套餐
        step: 2,
        title: 'Mantener las preferencias de Oberlo ',
        textArr: [
          {
            type: 1,
            text:
              "Accede a todas las funciones de Oberlo y disfruta de funciones más avanzadas como la gestión de multitiendas. Haz clic<a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>aquí</a> para más detalles."
          },
          {
            type: 2,
            text:
              "Accesar a todas las funciones similares a las de Oberlo, y disfruta de funciones más avanzadas como el mapeo avanzado y el servicio de atención al cliente 24 horas. Haz clic <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>aquí</a> para más información."
          },
          {
            type: 3,
            text:
              "Accesar a todas las funciones similares a las de Oberlo, y disfruta de más recursos como la gestión de más de 70.000 productos. Haz clic <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>aquí</a> para más detalles."
          }
        ],
        btn: 'Activar plan'
      },
      {
        // 安装插件
        step: 3,
        title: 'Instalar la extensión de Chrome de DSers',
        text:
          "¡Importación de productos de AliExpress a DSers con un solo clic para ahorrar tiempo y encontrar nuevos best sellers! Haz clic <a target='_blank' href='https://help.dsers.com/import-product-from-aliexpress-for-shopify/#Use DSers Chrome Extension'>aquí</a> para más detalles.",
        btn: 'Descargar'
      }
    ],
    // 确认弹窗文案
    ComfirmPop: [
      {
        kind: 'AE',
        title: 'Vincula tu cuenta de AliExpress',
        content:
          "Al vincular tu cuenta de AliExpress con DSers podrás procesar más de 100 pedidos en segundos. Haz clic <a target='_blank' href='https://help.dsers.com/link-your-shopify-store/'>aquí</a> para más detalles.",
        mainBtn: 'Vincular AliExpress'
      },
      {
        kind: 'PLAN',
        title: 'Mantener las preferencias de Oberlo ',
        contentArr: [
          {
            type: 1,
            content:
              "Accede a todas las funciones de Oberlo y disfruta de funciones más avanzadas como la gestión de multitiendas. Haz clic <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>aquí</a> para más detalles."
          },
          {
            type: 2,
            content:
              "Accesar a todas las funciones similares a las de Oberlo, y disfruta de funciones más avanzadas como el mapeo avanzado y el servicio de atención al cliente 24 horas. Haz clic <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>aquí</a> para más información."
          },
          {
            type: 3,
            content:
              "Accesar a todas las funciones similares a las de Oberlo, y disfruta de más recursos como la gestión de más de 70.000 productos. Haz clic <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>aquí</a> para más detalles."
          }
        ],
        errorBillStore:
          "No puedes activar el plan porque tu tienda de facturación no está disponible. Por favor, cambia la tienda de facturación en la página DSers - <a target='_blank' href='https://www.dsers.com/app/select'>Gestión de Suscripción</a> o activa la tienda de facturación en el administrador de ",
        errorBillStoreNotId: "No puedes activar el plan porque tu tienda de facturación no está disponible. Por favor, cambia la tienda de facturación en la página DSers -<a target='_blank' href='https://www.dsers.com/app/select'>Gestión de Suscripción</a>",
        errorBillName: 'Shopify',
        mainBtn: 'Activar plan'
      },
      {
        kind: 'CHROME',
        title: 'Instalar la extensión de Chrome de DSers',
        content:
          "¡Importación de productos de AliExpress a DSers con un solo clic para ahorrar tiempo y encontrar nuevos best sellers! Haz clic <a target='_blank' href='https://help.dsers.com/import-product-from-aliexpress-for-shopify/#Use DSers Chrome Extension'>aquí</a> para más detalles.",
        mainBtn: 'Descargar'
      }
    ],
    // 刷新弹窗文案
    RefreshPopText: {
      title: 'Actualizar página',
      content:
        'Por favor, actualiza la página para que podamos detectar si has instalado correctamente el plugin',
      btn: 'ACTUALIZAR'
    },
    // 提示用户必要信息文案
    OberloTopTipOneBindAE:"Your data is being corrected, you can migrate other Oberlo accounts once the data correction is completed.",
    OberloTopTipOneNoBindAE:'Please link your AliExpress account to DSers so that we can correct your orders status, you can continue to migrate other Oberlo accounts once the correction is completed.',
    OberloTopTipTwo:"Please note that DSers displays all the Shopify orders, while Oberlo displayed only the orders that contained products created through Oberlo.</br>" +
    "DSers migró los pedidos de los últimos dos meses. Si necesitas sincronizar más pedidos ponte <a style='color:#3E5BFE' href='https://www.messenger.com/t/DSersOfficial' target='_blank'>en contacto con nosotros.</a>"
  }
};
