export default {
  common: {
    // 基础按钮
    learn_more: 'Learn more',
    submit: 'Submit',
    or: 'Ou',
    other: 'De outros',
    contact_us: 'Contate-Nos',
    click_blog: `Click <a href="{url}" target="_blank" rel="noopener noreferrer">here</a> to learn how to solve this issue.`,
    click_blog2:`Please fill in your full name in English or French only and separate them with a space. Click <a href="{url}" target="_blank">here</a> to learn how to solve this issue.`
  }
};
