import about from './de/about.js';
import archeved from './de/archeved.js';
import banner from './de/banner.js';
import aeAccountDisconnect from './de/aeAccountDisconnect.js';
import bargain from './de/bargain.js';
import chore from './de/chore.js';
import combo from './de/combo.js';
import ad_1688 from './de/ad_1688.js';
import completePassWord from './de/completePassWord.js';
import csv from './de/csv.js';
import duplicateOrderConfirm from './de/duplicateOrderConfirm.js';
import errorCashbackCode from './de/errorCashbackCode.js';
import footer from './de/footer.js';
import fulfillmentCenter from './de/fulfillmentCenter.js';
import guidance from './de/guidance.js';
import h5Callback from './de/h5Callback.js';
import h5Redirect from './de/h5Redirect.js';
import h5LandModal from './de/h5LandModal.js';
import header from './de/header.js';
import helpCenter from './de/helpCenter.js';
import home from './de/home.js';
import importList from './de/importList.js';
import impotList from './de/impotList.js';
import admitad from './de/admitad.js';
import mapping from './de/mapping.js';
import market from './de/market.js';
import menu from './de/menu.js';
import newFeature from './de/newFeature.js';
import noticePlanBanner from './de/noticePlanBanner.js';
import login from './de/login.js';
import order from './de/order.js';
import partner from './de/partner.js';
import linkStore from './de/linkStore.js';
import paypal from './de/paypal.js';
import product from './de/product.js';
import productEdit from './de/productEdit.js';
import productPool from './de/productPool.js';
import productSplit from './de/productSplit.js';
import publicIntl from './de/public.js';
import pricing from './de/pricing.js';
import publicDocument from './de/publicDocument.js';
import rapport from './de/rapport.js';
import refreshMask from './de/refreshMask.js';
import register from './de/register.js';
import common from './de/common.js';
import selectPlatform from './de/selectPlatform.js';
import sendResetEmail from './de/sendResetEmail.js';
import settingSupply from './de/setting-supply.js';
import setting from './de/setting.js';
import bannerV2 from './de/bannerV2.js';
import supplierOptimizer from './de/supplierOptimizer.js';
import reward from './de/reward.js';
import resetPassword from './de/resetPassword.js';
import top from './de/top.js';
import shopPush from './de/shopPush.js';
import notification from './de/notification.js';
import tutorialOverModal from './de/tutorialOverModal.js';
import whyDisconnect from './de/whyDisconnect.js';
import suppliers from './de/suppliers.js';
import topStores from './de/topStores.js';
import tracking from './de/tracking.js';
import support from './de/support.js';
import guide from './de/guide';
import migration from './de/migration';
import onBoarding from './de/onBoarding.js';
import tmallTestExpress from './de/tmallTestExpress';
import beforeMigration from './de/beforeMigration';


export default {
    ...beforeMigration,
    ...about,
    ...aeAccountDisconnect,
    ...banner,
    ...bannerV2,
    ...bargain,
    ...chore,
    ...combo,
    ...common,
    ...csv,
    ...completePassWord,
    ...duplicateOrderConfirm,
    ...archeved,
    ...footer,
    ...fulfillmentCenter,
    ...guidance,
    ...h5Callback,
    ...h5LandModal,
    ...h5Redirect,
    ...header,
    ...helpCenter,
    ...ad_1688,
    ...importList,
    ...impotList,
    ...linkStore,
    ...home,
    ...mapping,
    ...menu,
    ...market,
    ...newFeature,
    ...notification,
    ...order,
    ...partner,
    ...login,
    ...pricing,
    ...product,
    ...productEdit,
    ...productPool,
    ...productSplit,
    ...publicIntl,
    ...publicDocument,
    ...noticePlanBanner,
    ...rapport,
    ...refreshMask,
    ...register,
    ...resetPassword,
    ...reward,
    ...selectPlatform,
    ...sendResetEmail,
    ...settingSupply,
    ...admitad,
    ...shopPush,
    ...errorCashbackCode,
    ...paypal,
    ...support,
    ...top,
    ...supplierOptimizer,
    ...tracking,
    ...tutorialOverModal,
    ...setting,
    ...whyDisconnect,
    ...topStores,
    ...suppliers,
    ...guide,
    ...migration,
    ...onBoarding,
    ...tmallTestExpress
}
