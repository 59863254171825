import useTopBanner from 'hooks/useTopBanner';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { DSERS_IMG_HOST } from 'utils/constants';
import { CommonDataCollect } from 'utils/utils';
import BrazilBannerLeft from '../../assets/banner/BrazilBannerLeft.png'
import BrazilBannerRight from '../../assets/banner/BrazilBannerRight.png'
import BrazilBannerCenter from '../../assets/banner/BrazilBannerCenter.png'
import styles from './AdBrazilBanner.less';

const allowPath = [
  '/order/list',
  '/order/agency/list',
  '/order/unmapped/list',
  '/order/tmall/list'
];

const AdBrazilBanner = ({ path, lang,BrazilBannerNum }) => {
  const [visible] = useTopBanner(path, allowPath);
  const handleClickBanner = () => {
    CommonDataCollect({
      event_type: 'bargin_banner_order',
       action: `banner${BrazilBannerNum}`
    });
  };
  const colorBg = {1:'#000000',2:'#697B98',3:'#DE8FFF',4:'#699893',5:'#6236FF',6:'#796998',7:'#7C9869'};
  if (!visible || !BrazilBannerNum) {
    return null;
  }
  return (
    <a
      href="https://blog.dsers.com/p/88cf9392-af7c-4a8b-86fd-a2bedc935a16/"
      target="_blank"
      onClick={handleClickBanner}
      // style={{backgroundColor:colorBg[BrazilBannerNum] || colorBg[1]}}
      className={styles.container}
    >
      <img className={styles.BrazilBannerLeft} src={BrazilBannerLeft} />
      <img className={styles.BrazilBannerRight} src={BrazilBannerRight} />
      <img className={styles.BrazilBannerCenter} src={BrazilBannerCenter} />
      <div className={styles.BrazilBannerText}>UP TO 25% OFF</div>
    </a>
  );
};

export default AdBrazilBanner;
