export default {
  supplier_optimizer: {
    title: 'Supplier Optimizer',
    title_test: 'Other supplier',
    sub_title: 'Based on your needs',
    msg:`We are adjusting the Supplier Optimizer feature and some data may be incorrect. We are working hard to fix it. Thank you for your understanding and patience.`,
    intro: `You can find the same product by using the AliExpress product URL link, and click
      <a href="https://help.dsers.com/supplier-optimizer-for-shopify/" target="_blank">here</a> to learn more.`,
    placeholder: 'Search for similar products using the AliExpress URL',
    product_list: 'Product List',
    ship_from: 'Ship from',
    ship_to: 'Ship to',
    sort_by: 'Sort by',
    product_title: 'Product',
    price: 'Price',
    sale_number: 'Sale Number',
    product_rating: 'Product Rating',
    store_rating: 'Store Rating',
    import_to_dsers: 'Import to DSers',
    not_found: `We couldn't find a similar product`,
    import_picture: 'Import Picture',
    upload_tip: 'Please upload local pictures and we will search for the same product in aliexpress for you.',
    click_to_upload: 'Click to Upload',
    click_or_drag: 'Click upload or drag in the picture directly',
    only_img: 'Only PNG and JNG format images can be uploaded.(Picture size within 8MB)',
    delivery: 'Delivery Date',
    delivery_info: 'Shipping Details',
    url_error: 'The URL format is wrong, please enter the AliExpress product URL',
    sys_error: 'The system is currently under maintenance, please try again in 2 minutes',
    ship_tip: 'Click this box if you want to import the products shipping time information',
    search_tip: 'Click search to find similar suppliers',
    shipping_price: 'Shipping Price',
    searched_product: 'Compared Product',
    ship_method: 'Shipping Method',
    loading_state_1: 'Uploading picture...',
    loading_state_2: 'Analyzing picture...',
    loading_state_3: 'Searching products...',
    have_try: 'Give It a Try',
    click_or_drag:"Click upload or drag in the picture directly",
    uploadimage:'UPLOAD IMAGE',
    agency:'AGENCY',
    agency_2:'Agency',
    agencySub:`Can't find a reliable agent? <br/>
    DSers helps you to connect with verified agent for your supplier fulfillment needs.`,
    learnmore:'LEARN MORE',
    Laura:'This is Laura.Always online, serious, fast, sincere service for you.',
    Ciel:`Reliable, trustworthy. This is Ciel. Your time and effort deserve to be matched with Ciel's prowess.`,
    Nora:'Aims to promise economical price, reliable shipping . Look forward to cooperating with you!',
    Michael:'Proficient in all types of products finding. Accurate and efficient service for customers.',
    Rebekah:"Hello.This is Rebekah.I can provide you with cheap but qualified products and solve problems for you in time.",
    Chloe:'Familiar with dropshipping business process. Service purposes: find and meet your demands carefully.',
    Kevin:'Focus on supply products for Brazil, Europe, US. Factory price with fast shipping.',
    Bess:'Chinese supplier, focusing on dropshipping 3 years of experience, 24 hours online.',
    Anchor:'DSers Mass Supplier,professional agent team, welcome to consult any DSers and products related questions.',
    David:'This is David, I can solve all your problems! In the meantime I can talk to you about China or other.',
    Tony:`Customer's satisfaction is subject to comprehensive factors including price. DSers, committed to this.`,
    Alisa:'Alisa from Dsers Supply providing supply and customized service. Feel free to leave your requests.',
    Keith:'24 hours on duty for you! I am a professional with four years of experience of dropshipping mass supply business!',
    Cohen:'Here is Cohen, eager to make friends. Meeting your demand and diffusing your hassle are my accountable work!',
    April:`I'm April.I will provide you with high quality products at low prices and give you the best service!`,
    Stone:'This is Stone, proficient in inquiring a good price for customers and patient in dealing with customers’ problems.',
    Jessica:'I am Jessica. I will serve you wholeheartedly , look forward to cooperating with you，my friend !',
    Duan:'Best, fastest, sincerest, and always ready for your request！',
    Sophia:'Hi,my dropshipping friends, give me your requests, Dsers will do the best to make you satisfied.',
    agenct:'Agent',
    agencytext:`Supply agents are dedicated to dropshopping business.
    The teams is mainly based in China where the supply service is the strongest.`,
    search_product:'Search Product',
    optimizerTitle:`Search for quality suppliers`,
    optimizerDesc_1:'1. Enter the URL you want in the search box or use an image',
    optimizerDesc_2:'2. In the search results, you can choose a better supplier according to your needs',
    noSearch:`No search results, please change the URL or image to search again`
  }
};
