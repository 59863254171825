import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo
} from 'react';
import ReactDOM from 'react-dom';
import debounce from 'lodash/debounce';
import { connect } from 'dva';
import { routerRedux, withRouter } from 'dva/router';
import { Modal, message } from 'antd';
import { captureMessage, captureException } from '@sentry/react';

import styles from './Schedule.less';
import Point from './component/Point';
import SessionOfBargain from './component/SessionOfBargain';
import SessionOfImportList from './component/SessionOfImportList';
import SessionOfProduct from './component/SessionOfProduct';
import SessionOfOrder from './component/SessionOfOrder';
import SessionOfMapping from './component/SessionOfMapping';
import Loading from './component/Loading';
import MaskSplit from './mask-split';
import { createTutorialOrder } from '../../services/global';

import intl from 'react-intl-universal';

const modalRoot = document.querySelector('#root');

let startAutoUpdateShipping = false; // 是否开始了自动更新物流

// 获取元素距离顶部的距离
const getScrollTopDistance = element => {
  let res = null;
  const calc = element && element.getBoundingClientRect();
  if (calc) {
    res = calc.top;
  }
  return res;
};

// 检查 item 是否可以下单
const checkItemsForPlaceOrder = items => {
  let pass = true;

  // eslint-disable-next-line
  for (const item of items) {
    if (!item.useSp) {
      continue;
    }
    // 不是 ae 平台的商品不用处理
    if (!item.useSp.platform_product_id) {
      continue;
    }
    // 来自 ae ,但是没有物流
    if (
      item.useSp.platform_product_id &&
      !item.useSp.logistics_company &&
      !(item.useSp.freights && item.useSp.freights.length > 0)
    ) {
      pass = false;
    }

    if (!pass) {
      break;
    }
  }

  return pass;
};

// 下一个可mapping的偏移量，note：不是 index
const getNextNeedMappingItemOffset = (items, index) => {
  let i = index;

  while (items[i] && items[i].useSp && items[i].useSp.platform_product_id) {
    i += 1;
  }

  return i + 1;
};

const updateShipping = (data, saveBtn, updateShippingBtn) => {
  console.log('调用了 updateShipping');
  let hasUpdate = false;
  // eslint-disable-next-line
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    if (!item.useSp) {
      continue;
    }
    // 不是 ae 平台的商品不用处理
    if (!item.useSp.platform_product_id) {
      continue;
    }

    // 有物流但是 freights 选项不匹配
    if (
      item.useSp.logistics_company &&
      item.useSp.freights &&
      item.useSp.freights.length > 0
    ) {
      const match = item.useSp.freights.find(
        ii => ii.serviceName === item.useSp.logistics_company
      );
      if (!match) {
        console.log('自动更新了物流');
        hasUpdate = true;
        if (updateShippingBtn[i]) updateShippingBtn[i].click();
        continue;
      }
    }

    if (
      !item.useSp.logistics_company &&
      item.useSp.freights &&
      item.useSp.freights.length > 0
    ) {
      hasUpdate = true;
      console.log('自动更新了物流');
      if (updateShippingBtn[i]) updateShippingBtn[i].click();
    }
  }

  if (hasUpdate) {
    console.log('点击了 item save');
    startAutoUpdateShipping = true;
    saveBtn.click();
  }
};

const initUtilsRef = {
  testProduct: null, // myProduct 页面获取的测试商品
  mappingItems: undefined, // order items 的数量
  currentMappingItem: undefined, // 当前 mapping items, 从 1 开始计数
  orderSuccess: false, // order 成功的标示
  highlightActive: true, // 高亮区域是否显示
  splitMask: null, // 拼接的 mask
  orderListIsRender: false, // 保证 order render 只执行一次
  mappingFinish: false, // mapping 成功完成
  multipleMappingRunning: false, // 多个 mapping item 启动
  removeHighlight: () => {},
  setMaskVisible: () => {},
  updateHighlight: () => {},
  removeMappingModal: () => {}
};

function GuideSchedule({ dispatch, location, tutorialType }) {
  console.log('========= GuideSchedule ===========', tutorialType);
  const utils = useRef(initUtilsRef);
  const rootRef = useRef(null);
  const maskBoxRef = useRef(null);
  const highlightNode = useRef(null);
  const pointRef = useRef(null);
  const confirmOrderRef = useRef(null);
  const mappingPointRef = useRef(null);
  const simulateImportButtonRef = useRef(null);

  const [rootRefRendered, setRootRefRendered] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(true);
  const [bargainImportStage, setBargainImportStage] = useState(undefined);
  const [importListStage, setImportListStage] = useState(undefined);
  const [productStage, setProductStage] = useState(undefined);
  const [orderStage, setOrderStage] = useState(undefined);
  const [mappingStage, setMappingStage] = useState(undefined);
  const [testOrderNumber, setTestOrderNumber] = useState(undefined);

  const stage = useMemo(() => {
    let tmp;
    if (location.pathname.indexOf('find_suppliers') > -1) {
      tmp = 'bargain';
    } else if (location.pathname.indexOf('storemanage/import') > -1) {
      tmp = 'importList';
    } else if (location.pathname.indexOf('storemanage/myproduct') > -1) {
      tmp = 'myProduct';
    } else if (location.pathname.indexOf('order/list') > -1) {
      tmp = 'order';
    }

    console.log('======== stage =========', tmp);
    return tmp;
  }, [location]);

  useEffect(() => {
    console.log('======== eventCore =========', rootRef.current);
    if (!(rootRef.current && rootRefRendered)) {
      return;
    }

    function resizeListener(forceUpdate) {
      if (!highlightNode.current) {
        return;
      }

      if (maskBoxRef.current) {
        if (!utils.current.highlightActive && forceUpdate !== true) {
          return;
        }
        const targetRect = highlightNode.current.getBoundingClientRect();
        utils.current.updateHighlight(targetRect);
      }
    }

    // 显/隐 mask
    utils.current.setMaskVisible = visible => {
      if (!maskBoxRef.current) {
        return;
      }

      utils.current.highlightActive = visible;
      maskBoxRef.current.style.display = visible ? 'block' : 'none';
    };

    // 移除高亮部分
    utils.current.removeHighlight = () => {
      if (!maskBoxRef.current) {
        return;
      }
      utils.current.highlightActive = false;
      maskBoxRef.current.style.width = '0px';
      maskBoxRef.current.style.height = '0px';
    };

    // 添加高亮
    utils.current.updateHighlight = calc => {
      if (!maskBoxRef.current) {
        return;
      }

      utils.current.highlightActive = true;
      maskBoxRef.current.style.width = `${calc.width}px`;
      maskBoxRef.current.style.height = `${calc.height}px`;
      maskBoxRef.current.style.borderTopWidth = `${calc.top}px`;
      maskBoxRef.current.style.borderLeftWidth = `${calc.left}px`;
      maskBoxRef.current.style.display = 'block';
    };

    // 移除 mapping
    utils.current.removeMappingModal = () => {
      mappingPointRef.current.style.display = 'none';
      utils.current.removeHighlight();
      utils.current.setMaskVisible(true);
      utils.current.splitMask.setVisible(false);
      rootRef.current.style.zIndex = 1009;

      const fakeBtn = document.querySelector('#simulate-save-btn');
      if (fakeBtn) fakeBtn.style.display = 'none';

      const close = document.querySelector(
        'div[data-label="mapping-board"] .ant-drawer-close'
      );
      if (close) close.click();
    };

    rootRef.current.addEventListener('beginnerGuide', e => {
      console.log('========== guide event ==========', e.detail);
      const container = document.querySelector('#BasicLayoutContentById');

      const { type, payload } = e.detail;

      console.log('我的事件为', type);

      if (tutorialType === 0) {
        if (type === 'bargain') {
          if (payload.category === 'rendered') {
            setGlobalLoading(false);
            setBargainImportStage('greeting');

            highlightNode.current = document.querySelector(
              '.bargain-good-stastics'
            );
            if (container) {
              const top = getScrollTopDistance(highlightNode.current);
              let bottomScrollAddon = 0;
              try {
                bottomScrollAddon =
                  document.documentElement.clientHeight -
                  highlightNode.current.clientHeight -
                  100;
              } catch (error) {
                console.log(error);
              }
              if (bottomScrollAddon < 0) {
                bottomScrollAddon = 0;
              }
              // console.log(bottomScrollAddon, top && top - bottomScrollAddon + 100)
              container.scrollTop = (top && top - bottomScrollAddon) || 250;
            }
            resizeListener(true);
          } else if (payload.category === 'IMPORT_SUCCESS') {
            setBargainImportStage(undefined);
            Modal.destroyAll();
            utils.current.removeHighlight();
            dispatch(routerRedux.push('/storemanage/import'));
          } else if (payload.category === 'IMPORT_FAILED') {
            setBargainImportStage('importFail');
          }
        } else if (type === 'importList') {
          if (payload.category === 'rendered') {
            setGlobalLoading(false);
            setImportListStage('greeting');

            const item = document.querySelector(
              'div[data-category="product-item-of-import"]'
            );
            highlightNode.current = item;

            if (item) item.classList.add('hoverActive');
            if (container) {
              const topDistance = getScrollTopDistance(highlightNode.current);
              container.scrollTop = (topDistance && topDistance - 125) || 250;
            }
            resizeListener(true);
          }

          if (payload.category === 'PUSH_TO_SP_SUCCESS') {
            Modal.destroyAll();
            utils.current.setMaskVisible(true);
            dispatch(routerRedux.push('/storemanage/myproduct'));
          } else if (payload.category === 'PUSH_TO_SP_FAILED') {
            setImportListStage('fail');
          }
        } else if (type === 'product') {
          if (payload.category === 'rendered') {
            setGlobalLoading(false);
            setProductStage('greeting');
            utils.current.testProduct = payload.data;

            highlightNode.current = document.querySelector(
              'div[data-label="product-item"]'
            );
            if (container) {
              const topDistance = getScrollTopDistance(highlightNode.current);
              container.scrollTop = (topDistance && topDistance - 240) || 250;
            }
            resizeListener(true);
          }
        }
      }

      // mapping 相关
      if (tutorialType === 1) {
        if (type === 'mapping') {
          if (payload.category === 'MAPPING_START') {
            setMappingStage('writing');

            // 异步打开的 mapping
            setTimeout(() => {
              const inputBox = document.querySelector(
                'div[data-label="mapping-input"]'
              );

              if (!inputBox) {
                captureMessage(
                  'mapping MAPPING_START Exception, missing inputBox'
                );
                return;
              }

              const cal = inputBox.getBoundingClientRect();
              highlightNode.current = inputBox;
              utils.current.updateHighlight(cal);

              const input = inputBox.querySelector('input');
              const button = inputBox.querySelector('button');
              if (input) input.focus();

              if (mappingPointRef.current) {
                mappingPointRef.current.style.left = `${cal.x + 85}px`;
                mappingPointRef.current.style.top = `${cal.y + 15}px`;
                mappingPointRef.current.style.display = 'block';
              }

              if (simulateImportButtonRef.current && button) {
                const tmp = button.getBoundingClientRect();
                simulateImportButtonRef.current.style.left = `${tmp.x}px`;
                simulateImportButtonRef.current.style.top = `${tmp.y}px`;
                simulateImportButtonRef.current.style.width = `${tmp.width}px`;
                simulateImportButtonRef.current.style.height = `${tmp.height}px`;
              }
            }, 800);
          } else if (payload.category === 'IMPORT_SUCCESS') {
            setMappingStage('importSuccess');
            const items = document.querySelectorAll(
              'li[data-label="mapping-list-import-item"]'
            );
            const item = items && items[items.length - 1]; // 取最后一个

            if (item) {
              const cal = item.getBoundingClientRect();
              utils.current.updateHighlight(cal);

              if (mappingPointRef.current) {
                mappingPointRef.current.style.display = 'none';
              }

              setTimeout(() => {
                // 需要重新计算，等待 button 渲染完成
                const button = item.querySelector('button');
                if (button) {
                  const tmp = button.getBoundingClientRect();
                  if (tmp && mappingPointRef.current) {
                    mappingPointRef.current.style.left = `${tmp.x + 37}px`;
                    mappingPointRef.current.style.top = `${tmp.y + 10}px`;
                    mappingPointRef.current.style.display = 'block';
                  }
                } else {
                  captureMessage(
                    'mapping IMPORT_SUCCESS Exception, missing button'
                  );
                }
              }, 500);
            } else {
              captureMessage('mapping IMPORT_SUCCESS Exception, missing item');
            }
          } else if (payload.category === 'IMPORT_FAILED') {
            setMappingStage('mappingImportFail');
          } else if (payload.category === 'MAPPING_SUCCESS') {
            console.log('MAPPING_SUCCESS');
            // 单个
            if (utils.current.mappingItems === 1) {
              console.log('单个 mapping');
              utils.current.removeMappingModal();
              setMappingStage(undefined);
              utils.current.mappingFinish = true;
            }

            // 多个
            if (utils.current.mappingItems > 1) {
              if (
                utils.current.currentMappingItem < utils.current.mappingItems
              ) {
                // 多个 mapping item 时，第一个 mapping 完成，如果接下来的 item 为 "from: aliExpress"，则跳过。为 "from: other",则继续 mapping
                utils.current.multipleMappingRunning = true;
                utils.current.currentMappingItem += 1;
                console.log(
                  '多个 mapping 开始',
                  utils.current.currentMappingItem
                );
                utils.current.removeMappingModal();

                highlightNode.current = document.querySelector(
                  'div[data-category="order-item"]'
                );

                resizeListener(true);
              } else if (
                utils.current.mappingItems === utils.current.currentMappingItem
              ) {
                // 多个结束
                console.log('多个 mapping 结束');
                utils.current.multipleMappingRunning = false;
                utils.current.removeMappingModal();
                setMappingStage(undefined);
                utils.current.mappingFinish = true;
              }
            }
          } else if (payload.category === 'MAPPING_FAILED') {
            setMappingStage('failMapping');
          }
        }
      }

      // order 是公共的
      if (type === 'order') {
        if (payload.category === 'rendered') {
          console.log('order 收到更新');

          if (startAutoUpdateShipping) {
            console.log('startAutoUpdateShipping 更新');
            startAutoUpdateShipping = false;

            if (!checkItemsForPlaceOrder(payload.data.childrens)) {
              setOrderStage('invalidOrder');
            } else {
              setOrderStage('awaitOrder');
            }
            return;
          }

          // 更新后验证后续 item 是否需要接着 mapping
          if (utils.current.multipleMappingRunning) {
            // 多个 mapping 更新喔
            console.log('多个 mapping 更新');
            const offset = getNextNeedMappingItemOffset(
              payload.data.childrens,
              utils.current.currentMappingItem - 1
            );
            console.log('>> 偏移量', offset);
            console.log('>> 当前 mappingItems', utils.current.mappingItems);

            if (offset > utils.current.mappingItems) {
              // 结束
              setMappingStage(undefined);
              utils.current.multipleMappingRunning = false;
              utils.current.mappingFinish = true;
            } else {
              // 继续 mapping
              utils.current.currentMappingItem = offset;
              setMappingStage('multiMappingConfirm');
            }
          }

          // mapping 成功时接着 order，需要根据最新的数据决定是否可以 order
          if (
            !(!utils.current.orderListIsRender || utils.current.mappingFinish)
          ) {
            return;
          }
          console.log('order 启动', payload.data);

          utils.current.orderSuccess = false; // 重置
          setGlobalLoading(false);

          if (payload.data) {
            setTestOrderNumber(payload.data.orderNumber);
          }

          const item = document.querySelector(
            'div[data-category="order-item"]'
          );
          highlightNode.current = item;

          if (container) {
            const top = getScrollTopDistance(highlightNode.current);
            container.scrollTop = (top && top - 360) || 250;
          }

          resizeListener(true);

          const startOrder = () => {
            if (!checkItemsForPlaceOrder(payload.data.childrens)) {
              setOrderStage('invalidOrder');
            } else {
              const saveBtn =
                item &&
                item.querySelector(
                  'button[data-label="save-order-item-hidden-button"]'
                );
              const updateShippingBtn =
                item &&
                item.querySelectorAll(
                  'button[data-label="save-item-shipping-hidden-button"]'
                );

              updateShipping(
                payload.data.childrens,
                saveBtn,
                updateShippingBtn
              );

              if (!startAutoUpdateShipping) {
                setOrderStage('awaitOrder');
              }
            }
          };

          if (tutorialType === 0) {
            startOrder();
          } else if (tutorialType === 1) {
            if (utils.current.mappingFinish) {
              startOrder();
            } else {
              setMappingStage('greeting');
            }
          }

          utils.current.orderListIsRender = true;
          if (utils.current.mappingFinish) {
            utils.current.mappingFinish = false;
          }
        } else if (payload.category === 'PLACE_ORDER_START') {
          setOrderStage('orderLoading');
        } else if (payload.category === 'PLACE_ORDER_OK') {
          utils.current.orderSuccess = true; // order 完成
        } else if (payload.category === 'PLACE_ORDER_STATUS_UPDATED') {
          if (utils.current.orderSuccess) {
            highlightNode.current = document.querySelector(
              'div[data-category="order-result-notice"]'
            );
            resizeListener(true);

            // 响应 order 结果
            if (payload.status === 'success') {
              setOrderStage('success');
            }
            if (payload.status === 'fail') {
              setOrderStage('fail');
            }
          }
        }
      }
    });

    const listener = debounce(() => {
      resizeListener();
      if (utils.current.splitMask && tutorialType === 1) {
        utils.current.splitMask.resize();
      }
    }, 800);
    window.addEventListener('resize', listener, false);

    // eslint-disable-next-line
    return () => {
      console.log('------------ unload ------------');
      highlightNode.current = null;
      utils.current = initUtilsRef;
      window.removeEventListener('resize', listener, false);
    };
  }, [dispatch, tutorialType, rootRef, rootRefRendered]);

  useEffect(() => {
    if (tutorialType === 1) {
      if (!utils.current.splitMask) utils.current.splitMask = new MaskSplit();
    }
  }, [tutorialType]);

  const handleBargainPointClick = useCallback(() => {
    window.dataLayer.push({
      category: 'tutorial',
      action: 'importing an product in bargain page'
    });
    const target = document.querySelector(
      '.bargain-good-stastics button[data-label="bargain-import"]'
    );
    if (target) {
      target.click();
    }
  }, []);

  const handleImportListPointClick = useCallback(() => {
    window.dataLayer.push({
      category: 'tutorial',
      action: 'click Push to Shopify in importList page'
    });
    const target = document.querySelector('button[data-label="push-to-sp"]');
    if (target) {
      target.click();
      setImportListStage('awaitConfirm');
      utils.current.setMaskVisible(false);

      //处理新手引导不能选择店铺问题
      setTimeout(() => {
        let beginnerMask = document.querySelector('#beginner-mask');
        beginnerMask.style.overflow = 'initial';
        beginnerMask.style.height = `${document.body.clientHeight}px`;
        beginnerMask.style.top = '379px';
        beginnerMask.children[1].style.top = '-379px';
      }, 1000);

      // modal 异步打开的
      setTimeout(() => {
        const submitBtn = document.querySelector(
          'button[data-label="push-to-sp-submit"]'
        );
        if (submitBtn && pointRef.current) {
          const cal = submitBtn.getBoundingClientRect();
          pointRef.current.style.left = `${cal.x + 54}px`;
          pointRef.current.style.top = `${cal.y + 7}px`;
          pointRef.current.style.display = 'block';
        } else {
          captureMessage(
            'handleImportListPointClick Exception, missing button, submitBtn && pointRef.current'
          );
        }
      }, 1000);
    } else {
      captureMessage('handleImportListPointClick Exception, missing target');
    }
  }, []);

  const handleWatchVideo = useCallback(
    id => {
      window.dataLayer.push({
        category: 'tutorial',
        action: 'goto watch a related video'
      });
      Modal.destroyAll();
      //清理缓存
      localStorage.removeItem('pushCheckStoreId');
      dispatch(
        routerRedux.push(
          id ? `/tutorials_video?s=raw&id=${id}` : '/tutorials_video?s=raw'
        )
      );
    },
    [dispatch]
  );

  const handleImportSubmit = useCallback(() => {
    //点击之前先判断是否有选中的店铺
    if (!JSON.parse(localStorage.getItem('storeCheck')).length) {
      message.error(intl.get('bargain.select_mask'));
      return;
    }

    //点击之后恢复默认状态
    let beginnerMask = document.querySelector('#beginner-mask');
    beginnerMask.style.overflow = 'hidden';
    beginnerMask.style.height = 'auto';
    beginnerMask.style.top = '0';
    beginnerMask.children[1].style.top = '0';

    window.dataLayer.push({
      category: 'tutorial',
      action: 'Push to Shopify submit in importList page'
    });
    const submitBtn = document.querySelector(
      'button[data-label="push-to-sp-submit"]'
    );
    if (submitBtn) {
      submitBtn.click();
      utils.current.removeHighlight();
      pointRef.current.style.display = 'none';
    } else {
      captureMessage('handleImportSubmit Exception, missing submitBtn');
    }
  }, []);

  const handleOrderPointClick = useCallback(() => {
    console.log('handleOrderPointClick');
    window.dataLayer.push({
      category: 'tutorial',
      action: 'Click the Order button'
    });
    const item = document.querySelector('div[data-category="order-item"]');
    const btn =
      item && item.querySelector('button[data-label="order-item-save"]');
    if (!btn) {
      captureMessage('handleOrderPointClick Exception, missing btn');
      return;
    }

    btn.click();
    setOrderStage('confirmOrder');
    utils.current.setMaskVisible(false);

    // modal 异步打开的
    setTimeout(() => {
      const submitBtn = document.querySelector(
        'button[data-label="order-item-confirm-submit"]'
      );
      if (submitBtn && confirmOrderRef.current) {
        const cal = submitBtn.getBoundingClientRect();
        confirmOrderRef.current.style.left = `${cal.x + 54}px`;
        confirmOrderRef.current.style.top = `${cal.y + 7}px`;
        confirmOrderRef.current.style.display = 'block';
      } else {
        setOrderStage('invalidOrder');
        captureMessage(
          'order Exception, missing button, order-item-confirm-submit'
        );
      }
    }, 1000);
  }, []);

  const handleConfirmPointClick = useCallback(() => {
    console.log('handleConfirmPointClick');
    window.dataLayer.push({
      category: 'tutorial',
      action: 'Click Confirm order'
    });
    const btn = document.querySelector(
      'button[data-label="order-item-confirm-submit"]'
    );
    if (btn) {
      btn.click();
      setOrderStage('orderLoading');
      utils.current.removeHighlight();
      utils.current.setMaskVisible(true);
    } else {
      captureMessage(
        'mapping handleConfirmPointClick Exception, missing button'
      );
    }
  }, []);

  const handleGotoTestByProduct = useCallback(() => {
    console.log('handleGotoTestByProduct');
    window.dataLayer.push({
      category: 'tutorial',
      action: 'Go ahead. I want to test'
    });
    utils.current.removeHighlight();
    setProductStage('createLoading');

    const data = utils.current.testProduct;
    const variant =
      data.detail && data.detail.variants && data.detail.variants[0];

    if (variant) {
      try {
        createTutorialOrder({
          data: {
            store_id:
              localStorage.getItem('pushCheckStoreId') ||
              localStorage.getItem('storeId'),
            variant_id: variant.id
          }
        })
          .then(res => {
            // 给一个 loading 状态
            setTimeout(() => {
              if (res && (res.code === 2000 || res.code === 2010)) {
                localStorage.removeItem('pushCheckStoreId');
                dispatch(routerRedux.push('/order/list'));
              } else {
                setProductStage('createFail');
              }
            }, 3000);

            return null;
          })
          .catch(error => {
            console.log('createTutorialOrder error:', error);
            setProductStage('createFail');
          });
      } catch (error) {
        captureException(error);
        setProductStage('createFail');
      }
    } else {
      captureMessage('createTutorialOrder Exception, missing variant');
      setProductStage('createFail');
    }
  }, [dispatch]);

  const handleGoAhead = useCallback(() => {
    console.log('handleGoAhead');
    window.dataLayer.push({
      category: 'tutorial',
      action: 'Go ahead. I want to test'
    });
    setMappingStage('awaitMapping');
    const target = document.querySelector('div[data-category="order-item"]');

    if (target) {
      const btn = target.querySelectorAll(
        'button[data-label="order-item-mapping-btn"]'
      );

      utils.current.mappingItems = btn.length;
      utils.current.currentMappingItem = 1;

      setTimeout(() => {
        if (btn && mappingPointRef.current) {
          const cal = btn[0].getBoundingClientRect();
          mappingPointRef.current.style.left = `${cal.x + 54}px`;
          mappingPointRef.current.style.top = `${cal.y + 7}px`;
          mappingPointRef.current.style.display = 'block';
        } else {
          captureMessage(
            'handleGoAhead Exception, missing button, btn && mappingPointRef.current'
          );
        }
      }, 500);
    }
  }, []);

  const handleMappingPointClick = useCallback(() => {
    console.log('handleMappingPointClick =>>>', mappingStage);
    const target = document.querySelector('div[data-category="order-item"]');

    if (
      mappingStage === 'awaitMapping' ||
      mappingStage === 'multiMappingConfirm'
    ) {
      if (target) {
        const btn = target.querySelectorAll(
          'button[data-label="order-item-mapping-btn"]'
        );
        window.dataLayer.push({
          category: 'tutorial',
          action: 'active mapping item'
        });
        if (btn) btn[(utils.current.currentMappingItem || 1) - 1].click();
        setMappingStage(undefined);
        utils.current.removeHighlight();
        mappingPointRef.current.style.display = 'none';
      }
    }

    if (mappingStage === 'importSuccess') {
      const items = document.querySelectorAll(
        'li[data-label="mapping-list-import-item"]'
      );

      window.dataLayer.push({
        category: 'tutorial',
        action: 'mapping set active'
      });

      mappingPointRef.current.style.display = 'none';
      const item = items && items[items.length - 1]; // 取最后一个
      const button = item && item.querySelector('button');
      if (!button) {
        captureMessage('mapping importSuccess Exception, missing button');
        return;
      }
      button.click();
      setMappingStage('confirmSet');
      utils.current.setMaskVisible(false);

      // modal 异步打开的
      setTimeout(() => {
        const submitBtn = document.querySelector(
          'button[data-label="mapping-set-confirm-ok"]'
        );
        if (submitBtn && mappingPointRef.current) {
          const cal = submitBtn.getBoundingClientRect();
          const offsetX = Math.ceil(cal.width * 0.8);
          const offsetY = Math.ceil(cal.height * 0.8);
          mappingPointRef.current.style.left = `${cal.x + offsetX}px`;
          mappingPointRef.current.style.top = `${cal.y + offsetY}px`;
          mappingPointRef.current.style.display = 'block';
        } else {
          captureMessage(
            'mapping importSuccess Exception, missing button, submitBtn && mappingPointRef.current'
          );
        }
      }, 1000);
    }

    if (mappingStage === 'confirmSet') {
      const submitBtn = document.querySelector(
        'button[data-label="mapping-set-confirm-ok"]'
      );
      if (!submitBtn) {
        captureMessage('mapping confirmSet Exception, missing submitBtn');
        return;
      }
      submitBtn.click();
      utils.current.setMaskVisible(false);
      setMappingStage('saveMapping');

      window.dataLayer.push({
        category: 'tutorial',
        action: 'mapping set confirm'
      });

      setTimeout(() => {
        const box = document.querySelector(
          'div[data-label="mapping-default-option-item"]'
        );
        if (!box) {
          captureMessage('mapping confirmSet Exception, missing box');
          return;
        }

        const saveBtn = document.querySelector(
          'button[data-label="mapping-submit"]'
        );
        const fakeBtn = document.querySelector('#simulate-save-btn');

        if (saveBtn && fakeBtn) {
          const saveBtnCal = saveBtn.getBoundingClientRect();
          fakeBtn.style.left = `${saveBtnCal.left - 5}px`;
          fakeBtn.style.top = `${saveBtnCal.top - 6}px`;
          fakeBtn.style.display = 'block';
        }

        const calc = box.getBoundingClientRect();
        utils.current.splitMask.setHighlightElement(box);

        rootRef.current.insertBefore(
          utils.current.splitMask.fragment,
          rootRef.current.firstChild
        );
        rootRef.current.style.zIndex = 'auto';
        // rootRef.current.appendChild(fragment);
        if (mappingPointRef.current) {
          const offsetX = calc.width + 4;
          const offsetY = 160;
          mappingPointRef.current.style.left = `${calc.x + offsetX}px`;
          mappingPointRef.current.style.top = `${calc.y + offsetY}px`;
          mappingPointRef.current.style.display = 'block';
        }
      }, 500);
    }
  }, [mappingStage]);

  const handleBorderBoxClick = () => {
    if (mappingStage === 'writing' || mappingStage === 'mappingImportFail') {
      console.log('handleBorderBoxClick');
      const input = document.querySelector(
        'div[data-label="mapping-input"] input'
      );
      if (input) input.focus();
    }
  };

  const handleSimulateImport = useCallback(() => {
    console.log('handleSimulateImport');
    window.dataLayer.push({
      category: 'tutorial',
      action: 'mapping import'
    });
    const button = document.querySelector(
      'div[data-label="mapping-input"] button'
    );

    if (button) button.click();
  }, []);

  const handleMappingSavePointClick = useCallback(() => {
    console.log('handleMappingSavePointClick');
    window.dataLayer.push({
      category: 'tutorial',
      action: 'click mapping save'
    });
    const saveBtn = document.querySelector(
      'button[data-label="mapping-submit"]'
    );

    if (!saveBtn) {
      captureMessage('handleMappingSavePointClick Exception');
      return;
    }
    saveBtn.click();
  }, []);

  const handleMultiMapping = useCallback(() => {
    console.log('handleMultiMapping 多个 mapping 启动');
    const target = document.querySelector('div[data-category="order-item"]');
    const buttons = target.querySelectorAll(
      'button[data-label="order-item-mapping-btn"]'
    );

    setTimeout(() => {
      if (buttons && mappingPointRef.current) {
        const btn = buttons[utils.current.currentMappingItem - 1];
        if (!btn) {
          captureMessage('handleMultiMapping Exception, not btn');
          return;
        }
        const cal = btn.getBoundingClientRect();
        mappingPointRef.current.style.left = `${cal.x + 54}px`;
        mappingPointRef.current.style.top = `${cal.y + 7}px`;
        mappingPointRef.current.style.display = 'block';
      }
    }, 500);
  }, []);

  const handleEndGuide = useCallback(() => {
    console.log('handleEndGuide');
    window.dataLayer.push({
      category: 'tutorial',
      action: 'tutorial over'
    });

    new Promise((resolve, reject) => {
      dispatch({
        type: 'guidance/finishGuide',
        payload: {
          resolve,
          reject
        }
      });
    })
      .then(res => {
        if (res && res.code === 2010) {
          dispatch(routerRedux.push('/find_suppliers'));
        }
        return null;
      })
      .catch(err => {
        console.log('handleFinish: ', err);
      });
  }, [dispatch]);

  console.log('state =>>', { mappingStage, orderStage });

  if (!stage) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className={styles.mask}
      id="beginner-mask"
      ref={el => {
        rootRef.current = el;
        // 让组件 rerender
        setRootRefRendered(!!el);
      }}
      style={{
        display: 'block'
        // zIndex: 'auto'
      }}
    >
      <div
        id="beginner-mask-box"
        ref={maskBoxRef}
        className={styles.box}
        role="doc-cover"
        onClick={handleBorderBoxClick}
      >
        {tutorialType === 0 &&
        stage === 'bargain' &&
        bargainImportStage === 'greeting' ? (
          <Point
            style={{ bottom: 20, left: '15%', position: 'absolute' }}
            onClick={handleBargainPointClick}
            data-category="tutorial"
            data-action="Import to DSers in bargain"
          />
        ) : null}
        {tutorialType === 0 &&
        stage === 'importList' &&
        importListStage === 'greeting' ? (
          <Point
            style={{ bottom: 12, right: '50%', position: 'absolute' }}
            onClick={handleImportListPointClick}
          />
        ) : null}
        {(tutorialType === 0 || tutorialType === 1) &&
        stage === 'order' &&
        orderStage === 'awaitOrder' ? (
          <Point
            style={{ bottom: 23, right: 14, position: 'absolute' }}
            onClick={handleOrderPointClick}
          />
        ) : null}
      </div>
      {globalLoading ? (
        <div className={styles.loading}>
          <Loading />
        </div>
      ) : (
        <>
          {/* session layer start */}
          {tutorialType === 0 && stage === 'bargain' ? (
            <SessionOfBargain
              status={bargainImportStage}
              onWatchVideo={handleWatchVideo}
            />
          ) : null}
          {tutorialType === 0 && stage === 'importList' ? (
            <SessionOfImportList
              status={importListStage}
              onSubmit={handleImportSubmit}
              onWatchVideo={handleWatchVideo}
              forwardedRef={pointRef}
            />
          ) : null}
          {tutorialType === 0 && stage === 'myProduct' ? (
            <SessionOfProduct
              onWatchVideo={handleWatchVideo}
              onGotoTest={handleGotoTestByProduct}
              status={productStage}
            />
          ) : null}
          {(tutorialType === 0 || tutorialType === 1) && stage === 'order' ? (
            <>
              {orderStage === 'confirmOrder' ? (
                <Point
                  style={{ display: 'none', position: 'absolute' }}
                  onClick={handleConfirmPointClick}
                  ref={confirmOrderRef}
                />
              ) : null}
              <SessionOfOrder
                status={orderStage}
                onWatchVideo={handleWatchVideo}
                testOrderNumber={testOrderNumber}
                onEnd={handleEndGuide}
                tutorialType={tutorialType}
              />
            </>
          ) : null}
          {tutorialType === 1 && stage === 'order' ? (
            <SessionOfMapping
              status={mappingStage}
              onWatchVideo={handleWatchVideo}
              onGoAhead={handleGoAhead}
              forwardedRef={mappingPointRef}
              onPointClick={handleMappingPointClick}
              onSimulateImport={handleSimulateImport}
              simulateImportButtonRef={simulateImportButtonRef}
              onMappingSavePointClick={handleMappingSavePointClick}
              onEnd={handleEndGuide}
              onMultiMapping={handleMultiMapping}
            />
          ) : null}
          {/* session layer end */}
        </>
      )}
    </div>,
    modalRoot
  );
}

export default withRouter(connect()(GuideSchedule));
