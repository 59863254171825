import {
  AliexpressAuthCallback,
  getCuntryAndTrack,
  getDefaultCountryAndTrack,
  saveCountryAndTrack,
  getGeneral,
  getGetGeneral,
  saveIoss,
  getIoss,
  postPhoneNo,
  saveCountryAdvanced,
  postShippingMethod,
  settingNotification,
  getTrackingUrl,
  getTracking,
  getTrackShipping,
  postTracking,
  getOtherSta,
  postOtherSta,
  getPricingList,
  saveAssignCents,
  savePricingItem,
  changePricingStatus,
  getTransactionsRecord,
  getBillsRecord,
  getPrimaryContact,
  savePrimaryContact,
  saveBillingInformation,
  getSupplier,
  postSupplier,
  getBalance,
  getGAuth,
  getPaypalStatus,
  getUseAddress,
  saveSampleAddress,
  setOtherNASta,
  getShowSupplierTab,
  getCountry,
  getLogistics,
  getTMLogistics,
  getTrackingCompany,
  getShopifyStoreStatus,
  updateShopfiy,
  unBindAli,
  reconnectAli,
  updateUserName,
  sendCode,
  comfirmEmailChange,
  getProductList,
  getAeProductDetail,
  getAeProductFreight,
  saveShip,
  sendCashBackCode,
  setUserStatus,
  getNotifyAutoSetting,
  postNotifyAutoSetting,
  admitadCheck,
  putSyncShopifyNotesStatus,
  postSaveCurrency,
  putPricingCurrency,
  getPricingCurrency,
  postUpdateStore,
  postTimeZone,
  getTaxRevenue,
  setTaxRevenue,
  putCancelFulfillment,
  GetUserAllPhoneSet,
  UpdateUserPhoneNumber,
  DeleteUserPhoneNumberSet,
  getUpdataStatus,
  upDataPricing,
  getChildAccount,
  getTrackingList,
  postTrackingList,
  getAgencySettings
} from '../services/dropshippersetting';

import { queryUserInfo, queryCurrent } from '../services/user';

export default {
  namespace: 'dropshippersetting',
  state: {
    countryandtrack: {},
    transactionsRecord: [],
    billsRecord: {},
    primaryData: [],
    supplier: [],
    balance: 0,
    gQRcode: '',
    lastpayment: {},
    countrys: [],
    logistics_services: [],
    logistics_services_tm:[],
    tracking_company: [],
    shippingMethodModal: {
      visible: false
    },
    admitadCheckCode: undefined,
    currencyChecked: false,
    storyPriceStatus: '',
    childAccount: '',
    syncStoresStatus:{},
    agencySettings: {}
  },

  effects: {
    *aliexpresscallback({ payload }, { call }) {
      yield call(AliexpressAuthCallback, payload);
    },
    *cuntryandtrack({ payload }, { call, put }) {
      const response = yield call(getCuntryAndTrack, payload);
      yield put({
        type: 'setCuntryAndTrack',
        payload: response
      });
    },
    *country({ payload }, { call, put }) {
      const response = yield call(getCountry, payload);
      if (response.code === 2000) {
        yield put({
          type: 'setCountry',
          payload: response
        });

        localStorage.setItem('countryList', JSON.stringify(response.data));
      }
    },
    *logistics({ payload }, { call, put }) {
      const response = yield call(getLogistics, payload);

      if (response.code === 2000 && response.data) {
        response.data.sort((a, b) => a.Name.localeCompare(b.Name, 'en'));
        response.data.forEach((item,index)=>{
          if(item.Name == "AliExpress Premium Shipping"){
            let addMess = response.data.splice(index,1)[0];
            response.data.unshift(addMess)
            return;
          }
        })
        yield put({
          type: 'setLogistics',
          payload: response
        });
      }
    },

    *logistics_tm({ payload }, { call, put }) {
      const response = yield call(getTMLogistics, payload);

      if (response.code === 2000 && response.data) {
        response.data.sort((a, b) => a.Name.localeCompare(b.Name, 'en'));

        yield put({
          type: 'setTMLogistics',
          payload: response
        });
      }
    },

    *tracking({ payload }, { call, put }) {
      const response = yield call(getTrackingCompany, payload);
      yield put({
        type: 'setTracking',
        payload: response
      });
    },
    *defaultCountryAndTrack({ payload }, { call }) {
      yield call(getDefaultCountryAndTrack, payload);
    },
    *saveTrack({ payload }, { call }) {
      yield call(saveCountryAndTrack, payload);
    },
    *general({ payload }, { call }) {
      yield call(getGeneral, payload);
    },

    *getGeneral({ payload }, { call }) {
      yield call(getGetGeneral, payload);
    },
    *saveIoss({ payload }, { call }) {
      yield call(saveIoss, payload);
    },
    *getIoss({ payload }, { call }) {
      yield call(getIoss, payload);
    },
    *postGeneral({ payload }, { call }) {
      yield call(postPhoneNo, payload);
    },
    *saveCountryAdvanced({ payload }, { call }) {
      yield call(saveCountryAdvanced, payload);
    },
    *settingNotification({ payload }, { call }) {
      yield call(settingNotification, payload);
    },
    *postShippingMethod({ payload }, { call }) {
      yield call(postShippingMethod, payload);
    },
    *getTrackingUrl({ payload }, { call }) {
      yield call(getTrackingUrl, payload);
    },
    *getTracking({ payload }, { call }) {
      yield call(getTracking, payload);
    },
    *getTrackShipping({ payload }, { call }) {
      yield call(getTrackShipping, payload);
    },
    *postTracking({ payload }, { call }) {
      yield call(postTracking, payload);
    },
    *getOtherSta({ payload }, { call }) {
      yield call(getOtherSta, payload);
    },
    *postOtherSta({ payload }, { call }) {
      yield call(postOtherSta, payload);
    },
    *getTaxRevenue({ payload }, { call }) {
      yield call(getTaxRevenue, payload);
    },
    *setTaxRevenue({ payload }, { call }) {
      yield call(setTaxRevenue, payload);
    },
    *getPricingList({ payload }, { call,put }) {
     const res = yield call(getPricingList, payload);
      yield put({
        type:'setStoresStatus',
        payload:res.data
      })
    },
    *saveAssignCents({ payload }, { call }) {
      yield call(saveAssignCents, payload);
    },
    *savePricingItem({ payload }, { call }) {
      yield call(savePricingItem, payload);
    },
    *changePricingStatus({ payload }, { call }) {
      yield call(changePricingStatus, payload);
    },
    *transactions({ payload }, { call, put }) {
      const response = yield call(getTransactionsRecord, payload);
      yield put({
        type: 'setTransactionsRecord',
        payload: response
      });
    },
    *bills({ payload }, { call, put }) {
      const response = yield call(getBillsRecord, payload);
      yield put({
        type: 'setBillsRecord',
        payload: response
      });
    },
    *primary({ payload }, { call, put }) {
      const response = yield call(getPrimaryContact, payload);
      yield put({
        type: 'setPrimary',
        payload: response
      });
    },
    *savePrimary({ payload }, { call }) {
      yield call(savePrimaryContact, payload);
    },
    *saveBilling({ payload }, { call }) {
      yield call(saveBillingInformation, payload);
    },
    *supplier({ payload }, { call, put }) {
      const response = yield call(getSupplier, payload);
      yield put({
        type: 'setSupplier',
        payload: response
      });
    },
    *savesupplier({ payload }, { call }) {
      yield call(postSupplier, payload);
    },
    *balance({ payload }, { call, put }) {
      const response = yield call(getBalance, payload);
      yield put({
        type: 'setBalance',
        payload: response
      });
    },
    *gauth({ payload }, { call, put }) {
      const response = yield call(getGAuth, payload);
      yield put({
        type: 'setGAuthQRcode',
        payload: response
      });
    },
    *paypalStatus({ payload }, { call, put }) {
      const response = yield call(getPaypalStatus, payload);
      yield put({
        type: 'setLastPayment',
        payload: response
      });
    },
    *useAddress({ payload }, { call }) {
      yield call(getUseAddress, payload);
    },
    *useSample({ payload }, { call }) {
      yield call(saveSampleAddress, payload);
    },
    *postOtherNASta({ payload }, { call }) {
      yield call(setOtherNASta, payload);
    },
    *showSupplierTab({ payload }, { call }) {
      yield call(getShowSupplierTab, payload);
    },
    *shopifyStatus({ payload }, { call }) {
      yield call(getShopifyStoreStatus, payload);
    },
    *updateShopfiy({ payload }, { call }) {
      yield call(updateShopfiy, payload);
    },
    *unBindAli({ payload }, { call }) {
      yield call(unBindAli, payload);
    },
    *reconnectAli({ payload }, { call }) {
      yield call(reconnectAli, payload);
    },
    *updateUserName({ payload }, { call }) {
      yield call(updateUserName, payload);
    },
    *sendCode({ payload }, { call }) {
      yield call(sendCode, payload);
    },
    *comfirmEmailChange({ payload }, { call }) {
      yield call(comfirmEmailChange, payload);
    },
    *productList({ payload }, { call }) {
      yield call(getProductList, payload);
    },
    *aeProdDetail({ payload }, { call }) {
      yield call(getAeProductDetail, payload);
    },
    *aeProdFreight({ payload }, { call }) {
      yield call(getAeProductFreight, payload);
    },
    *saveShip({ payload }, { call }) {
      yield call(saveShip, payload);
    },
    *cashback({ payload }, { call }) {
      yield call(sendCashBackCode, payload);
    },
    *reloadUserInfo({ payload }, { call }) {
      yield call(queryUserInfo, payload);
    },
    *userStatus({ payload }, { call }) {
      yield call(setUserStatus, payload);
    },
    *notifyAuto({ payload }, { call }) {
      yield call(getNotifyAutoSetting, payload);
    },
    *saveNotifyAuto({ payload }, { call }) {
      yield call(postNotifyAutoSetting, payload);
    },
    *admitadCheck(_, { call, put }) {
      const response = yield call(admitadCheck);

      if (response && response.code === 2000) {
        yield put({
          type: 'login/updateUserInfo'
        });

        yield put({
          type: 'updateAdmitadCode',
          payload: response.data
        });
      }
    },
    *syncShopifyNotesStatus({ payload }, { call }) {
      yield call(putSyncShopifyNotesStatus, payload);
    },
    *saveCurrency({ payload }, { call }) {
      yield call(postSaveCurrency, payload);
    },
    *pricingCurrency({ payload }, { call, put }) {
      const response = yield call(putPricingCurrency, payload);
      response.data = payload.data.apply_currency_price_rule;
      yield put({
        type: 'setPricingCurrencyChecked',
        payload: response
      });
    },
    *pricingCurrencyChecked({ payload }, { call, put }) {
      const response = yield call(getPricingCurrency, payload);
      yield put({
        type: 'setPricingCurrencyChecked',
        payload: response
      });
    },
    *updateStore({ payload }, { call }) {
      yield call(postUpdateStore, payload);
    },
    *timeZone({ payload }, { call }) {
      yield call(postTimeZone, payload);
    },
    *cancelFulfillment({ payload }, { call }) {
      yield call(putCancelFulfillment, payload);
    },
    *GetUserAllPhoneSet({ payload }, { call }) {
      yield call(GetUserAllPhoneSet, payload);
    },
    *updataUserAllPhone({ payload }, { call }) {
      yield call(UpdateUserPhoneNumber, payload);
    },
    *delUserAllPhone({ payload }, { call }) {
      yield call(DeleteUserPhoneNumberSet, payload);
    },
    *updataPricing({ payload }, { call, put }) {
      const response = yield call(getUpdataStatus, payload);
      yield put({
        type: 'setUpdataPrcing',
        payload: response
      });
    },
    *setUpdataPricing({ payload }, { call }) {
      yield call(upDataPricing, payload);
    },
    *getChildAccount({ payload }, { call, put }) {
      const res = yield call(getChildAccount, payload);
      yield put({
        type: 'setChildAccount',
        payload: res
      });
    },
    *setTrackingList({ payload }, { call }) {
      yield call(postTrackingList, payload);
    },
    *getTrackingList({ payload }, { call, put }){
      const res = yield call(getTrackingList, payload);
      yield put({
        type: 'setTrackList',
        payload: res?.data
      });
    },
    *getAgencySettings({payload}, {call, put}) {
      const res = yield call(getAgencySettings, payload);
      yield put({
        type: 'setAgencySettings',
        payload: res?.data
      });
    }
  },

  reducers: {
    setCuntryAndTrack(state, { payload = {} }) {
      const { data = {} } = payload;
      return {
        ...state,
        countryandtrack: data
      };
    },
    setCountry(state, { payload = {} }) {
      const { data = {} } = payload;
      return {
        ...state,
        countrys: data || []
      };
    },
    setLogistics(state, { payload = {} }) {
      const { data = {} } = payload;
      return {
        ...state,
        logistics_services: data
      };
    },

    setTMLogistics(state, { payload = {} }) {
      const { data = {} } = payload;
      return {
        ...state,
        logistics_services_tm: data
      };
    },

    setTracking(state, { payload = {} }) {
      const { data = {} } = payload;
      return {
        ...state,
        tracking_company: data
      };
    },
    setTransactionsRecord(state, { payload = {} }) {
      const { data = [] } = payload;
      return {
        ...state,
        transactionsRecord: data || {}
      };
    },
    setBillsRecord(state, { payload = {} }) {
      const { data } = payload;
      return {
        ...state,
        billsRecord: data || {}
      };
    },
    setPrimary(state, { payload = {} }) {
      const { data = {} } = payload;
      return {
        ...state,
        primaryData: data
      };
    },
    setSupplier(state, { payload = {} }) {
      const { data = [] } = payload;
      return {
        ...state,
        supplier: data
      };
    },
    setBalance(state, { payload = {} }) {
      const { data } = payload;
      return {
        ...state,
        balance: data
      };
    },
    setGAuthQRcode(state, { payload = {} }) {
      const { data } = payload;
      return {
        ...state,
        gQRcode: data
      };
    },
    setLastPayment(state, { payload = {} }) {
      const { data } = payload;
      return {
        ...state,
        lastpayment: data || {}
      };
    },
    showModal(state, { payload }) {
      return {
        ...state,
        shippingMethodModal: {
          ...state.shippingMethodModal,
          ...payload,
          visible: true
        }
      };
    },
    hideModal(state, { payload }) {
      return {
        ...state,
        shippingMethodModal: {
          ...payload,
          visible: false
        }
      };
    },
    updateAdmitadCode(state, { payload }) {
      return {
        ...state,
        admitadCheckCode: payload
      };
    },
    setPricingCurrencyChecked(state, { payload }) {
      return {
        ...state,
        currencyChecked: payload.data || false
      };
    },
    setUpdataPrcing(state, { payload }) {
      return {
        ...state,
        storyPriceStatus: payload
      };
    },
    setChildAccount(state, { payload }) {
      return {
        ...state,
        childAccount: payload
      };
    },
    setTrackList(state,{ payload }){
      return {
        ...state,
        trackingList: payload
      };
    },
    setStoresStatus(state,{ payload }){
      return {
        ...state,
        syncStoresStatus:payload
      }
    },
    setAgencySettings(state, { payload }) {
      return {
        ...state,
        agencySettings: payload
      }
    }
  }
};
