import React from 'react';

import styles from './noPageAccess.less';

const noPageAccess = () => {
  return (
    <div className={styles.content}>
      <div>
        <img
          src="https://img.dsers.com/shopify/tracking/global/noPageAccess.png"
          alt="noPageAccess"
        />
        <div className={styles.title}>No page access</div>
      </div>
    </div>
  );
};

export default noPageAccess;
