import { stringify } from 'qs';
import { getAuthorityHeader } from '../../utils/authority';
import Cookie from 'js-cookie';
import { message } from 'antd';
window.MIGRATION_STATUS_MIGRATING = true;

const mvpBffHost =
  process.env.BUILD_TARGET === 'prod'
    ? 'https://api-gw.dsers.com'
    : 'https://api-gw-test.dsers.com';

async function request(api, path, params, method = 'GET') {
  let url = api + path;
  const options = {
    method: method.toLocaleUpperCase()
  };
  options.headers = {
    'Content-Type': 'application/json',
    Authorization: getAuthorityHeader().Authorization
  };
  if (method.toLocaleUpperCase() === 'GET') {
    url += `?${stringify(params)}`;
  } else {
    options.body = JSON.stringify(params);
  }
  if(Cookie.get('MIGRATION_STATUS_MIGRATING') !== undefined){
    if( window.MIGRATION_STATUS_MIGRATING){
      //message.info('迁移后门专用，如果登录已迁移或者迁移中的账号，请不要操作任何数据，该功能只对开发测试开放',0);
      window.MIGRATION_STATUS_MIGRATING = false;
    }
    return null;
  }
  return fetch(url, options)
    .then(response => response.json())
    .catch(error => {
      return {
        code: 500,
        message: '请求错误!'
      }
    });
}

//
// /**
//  * user_set_platform 用户配置的平台
//  */
// export type UserSetPlatform = "SELLER_TYPE_WOO" | "SELLER_TYPE_SHOPIFY" | "SELLER_TYPE_WIX";
//
// /**
//  * status 迁移状态
//  */
// export type Status =  "MIGRATION_STATUS_NOT_START" | "MIGRATION_STATUS_MIGRATING" | "MIGRATION_STATUS_COMPLETED";

// 获取迁移状态
export async function getBannerStatus() {
  return request(
    mvpBffHost,
    '/dsers-mgn-bff/v1/users/old-platform-mgn-popup',
    {},
    'GET'
  );
}

// SetPlatformPopup 设置平台
export function setUsersPlatform(params = {}) {
  return request(
    mvpBffHost,
    '/dsers-mgn-bff/v1/users/platform',
    params,
    'POST'
  );
}

// SetPlatformPopup  已读预告弹窗
export function previewPopup(params = {}) {
  return request(
    mvpBffHost,
    '/dsers-mgn-bff/v1/users/preview-popup',
    params,
    'POST'
  );
}
