import React from 'react';
import { Button } from 'antd';

import styles from './MessengerButton2.less';

function MessengerButton2({ children, ...restProps }) {
  return (
    <a
      href="https://www.messenger.com/t/DSersOfficial" dd="src"
      target="_blank"
      rel="noopener noreferrer"
      track-type="helpCenter"
      track-name="openFbMessenger"
    >
      <Button className={styles.messageBtn} {...restProps}>
        <i />
        <span>{children}</span>
      </Button>
    </a>
  );
}

export default MessengerButton2;
