
    export default 
    {
    "csv": {
        "csv_bulk_order": "Ordine in blocco CSV",
        "re_download_template": "Per mantenere la stabilità della piattaforma, gli utenti CSV possono effettuare fino a 2.000 ordini ogni giorno, gli ordini falliranno quando si raggiunge il limite.",
        "products": "Prodotti",
        "import": "Importazione",
        "download_template": "Scarica modello",
        "product_tip": "Gestisci il tuo database di prodotti per effettuare ordini in blocco.",
        "orders": "Ordini",
        "order_tip": "Ottieni i numeri d'ordine AliExpress e il numero di tracciamento (i numeri di tracciamento di solito impiegano giorni per essere generati) scaricando il file nella colonna Ordini andati a buon fine.Rivedi gli ordini falliti scaricando il file nella colonna Ordini falliti e modifica le informazioni necessarie.",
        "upload_title_orders": "Importa ordini",
        "upload_title_product": "Importa Database Prodotti",
        "upload_btn": "Importa",
        "need_help": "Serve aiuto?",
        "choose_csv_file": "Scegli il file CSV",
        "no_file_chosen": "Nessun file scelto",
        "max_8mb": "Il tuo file CSV deve essere inferiore a 8MB",
        "upload_download_tip": "per vedere un esempio del formato richiesto",
        "upload_succ": "caricamento riuscito.",
        "upload_fail": "caricamento fallito",
        "upload_fail_tip": "Il formato del file caricato non è corretto. Controlla il file csv.",
        "click_here_to": "Clicca qui per",
        "download": "scaricare",
        "download_error": "il messaggio di errore Excel.",
        "order_table": {
            "date": "Data",
            "file_name": "Nome file",
            "successful_orders": "Ordini riusciti",
            "failed_orders": "Ordini falliti",
            "action": "Azione"
        },
        "pending": "In attesa",
        "place_order": "Effettua l'ordine",
        "check_product": "Controlla lo SKU del prodotto AliExpress",
        "check": "Controlla",
        "price": "Prezzo",
        "token_error": "Aggiorna la pagina per scaricare di nuovo il file."
    }
}