
    export default 
    {
    "shop_push": {
        "title": "Contatta per ottenere il 10%-30% di sconto sui prodotti!",
        "dsers_price": "Prezzo DSers:",
        "current_price": "Prezzo attuale:",
        "profit_margin": "Margine di profitto:",
        "supplier": "Fornitore:",
        "dsers_program": "Programma Fornitori di Massa DSers",
        "link_copied": "Link copiato!",
        "copy_link": "Copia il link del prodotto sulla mappa",
        "contact": "Contatta per ottenere lo sconto",
        "what_dec": "Cos'è il Programma per i Fornitori di Massa DSers?",
        "thank_you": "Grazie per la tua richiesta",
        "fill_email": "Inserisci la tua email per completare la richiesta.",
        "fill_email_placeholder": "Inserisci l'email",
        "send_request": "Invia richiesta",
        "reach_you": "DSers e il team di AliExpress Global Supply ti contatteranno presto. Ti auguriamo una buona giornata!"
    }
}