import React, { PureComponent } from 'react';
import { Menu, Icon, Spin, Dropdown, Avatar, Modal, Button, Tooltip } from 'antd';
import Debounce from 'lodash-decorators/debounce';
import intl from 'react-intl-universal';
import { Link } from 'dva/router';
import { connect } from 'dva';
import { ChromeAction, OrderAction, LinkStoreAction} from 'actions'

import styles from './index.less';
import logo from '../../assets/svg/new_logo.png';
import SelectLanguage from './SelectLanguage';
import SelectTags from './SelectTags';
import Notice from './Notice';

import IconMenu from 'assets/mobile/icon_menu.png'
import LogoImg from 'assets/mobile/Logo@2x.png'
import CountryFlags from 'assets/mobile/CountryFlags.png'

import { webCustomCollect, getChromeExtUrl, CommonDataCollect } from 'utils/utils'
import classNames from 'classnames';
import lujiao from 'assets/img/christmas/lujiao.png';
import closeBtn from 'assets/img/christmas/closeButton.png';
import openBtn from 'assets/img/tmallTheme/tmallThemeChange.svg';
import {tmallThemeUser} from 'utils/tmallColdStartUser';

class GlobalHeader extends PureComponent {
  constructor(props){
    super(props);
    this.state = {
      visible:false,
      bindAeVisible:false,
      chromeModal:false,
      refresh:false
    }
  }

  componentDidMount(){
    window.EVENT.on(ChromeAction.NO_INSTALL_MODAL_RELOAD, (params) => {
      this.setState({
        visible:params
      })
    })

    window.EVENT.on(OrderAction.IS_BIND_AE_MODAL, (params) => {
      this.setState({
        bindAeVisible:params
      })
    })

    window.EVENT.on(ChromeAction.NO_INSTALL_MODAL, (params) => {
      this.setState({
        chromeModal:params
      })
    })

    //订单打点
    window.EVENT.on(OrderAction.INTERACTION_DOT, args => {
      CommonDataCollect({
        ...args,
        event_type:'interactive'
      })
    })

    window.EVENT.on(LinkStoreAction.REFRESH_PAGE,(params) => {
      this.setState({
        refresh:params
      })
    })

  }

  componentWillUnmount() {
    this.triggerResizeEvent.cancel();
  }

  toggle = () => {
    const { collapsed, onCollapse } = this.props;
    onCollapse(!collapsed);
    this.triggerResizeEvent();
  };

  /* eslint-disable*/
  @Debounce(600)
  triggerResizeEvent() {
    const event = document.createEvent('HTMLEvents');
    event.initEvent('resize', true, false);
    window.dispatchEvent(event);
  }
  // onClose = () => {
  //   localStorage.setItem('visible', 1);
  //   if (localStorage.getItem('visible') == 1) {
  //     this.setState({
  //       visible: 1
  //     })
  //   }
  // }

  changeLang = ({ key }) => {
    localStorage.setItem('lang', key);
    window.location.reload();
  };

  handleClickDot(type){
    return ()=>{
      if(type == 'facebook'){
        webCustomCollect({
          action:"Menu clicks",
          custom_info:[
            {
              name:'menu_title',
              value:'facebook'
            }
          ]
        })
      }else if(type == 'youtube'){
        webCustomCollect({
          action:"Menu clicks",
          custom_info:[
            {
              name:'menu_title',
              value:'youtube'
            }
          ]
        })
      }
    }
  }

  handleMenuClick = ({ key }) => {
    if (window.localStorage.getItem('online_notice_ioss')) {
      window.localStorage.removeItem('online_notice_ioss');
    }
    if (key === 'triggerError') {
      this.props.dispatch(routerRedux.push('/exception/trigger'));
      return;
    }

    if (key === 'logout') {
      this.props.dispatch({
        type: 'login/logout'
      });
    }

    if (key === 'ResetPassword') {
      this.props.dispatch({
        type: 'user/setPasswordModalVisible',
        payload: true
      });
    }

    if (key === 'Select') {
      window.open('/app/select');
    }
  };

  handleThemeButton = () => {
    this.props.handleThemeButton()
  }

  render() {
    const { userInfo, collapsed, isMobile, showLogo, extraContent, showLanguage = true } = this.props;

    const menu = (
      <Menu className={styles.menu} selectedKeys={[]} onClick={this.handleMenuClick}>
        <Menu.Item className={styles.item} onClick={this.handleClickDot("facebook")} key="Facebook">
          <i class="material-icons">facebook</i>
          <a target="_blank" href="https://www.facebook.com/DSersOfficial" dd="src"> {intl.get('public.facebook')}
          </a>
        </Menu.Item>
        <Menu.Item className={styles.item} onClick={this.handleClickDot("youtube")} key="Youtube">
          <Icon theme='filled' style={{fontSize: '24px', color: '#757778' }} type="youtube" />
          <a target="_blank" href="https://www.youtube.com/channel/UCpiRdgB8VXvaht0AizSP9mA"> 
            {intl.get('public.youtube')}
          </a>
        </Menu.Item>
        <Menu.Item className={styles.item}>
          <i style={{transform:'rotate(180deg)'}} class="material-icons">wb_incandescent</i>
          <a
            href={'/app/tutorials_video'}
            target="_blank"
            onClick={() => {
              localStorage.setItem('guidanceAgain', 1);
            }}
          >
            <span>{intl.get('home.tutorial2')}</span>
          </a>
        </Menu.Item>
        <Menu.Item className={styles.item} key="ResetPassword">
          <i class="material-icons">vpn_key</i>
          {intl.get('public.reset_password')}
        </Menu.Item>
        <Menu.Item className={styles.item} key="logout">
          <i class="material-icons">undo</i>
          {intl.get('public.log_out')}
        </Menu.Item>
      </Menu>
    );
    const userName = userInfo.user_name || userInfo.email;

    return (
      <>
        {/* 又是安装插件弹层 */}
        <Modal
          title={intl.get("top.chrome_dowm_tit")}
          maskClosable={false}
          visible={this.state.chromeModal}
          onCancel={() => {
            this.setState({
              chromeModal:false
            })
          }}
          footer={
            <Button type='primary' data='dx' onClick={() => {
              window.EVENT.emit(ChromeAction.NO_INSTALL_MODAL, false)
              window.open(getChromeExtUrl(), '_blank');
              window.EVENT.emit(ChromeAction.NO_INSTALL_MODAL_RELOAD, true)
            }}>{intl.get("top.chrome_foo_btn")}</Button>
          }
        >
          <div>{intl.getHTML("top.chrome_dowm_detail")}</div>
        </Modal>
        {/* 绑定AE弹层 */}
        <Modal
          title={intl.get('top.bind_ae_btn_modal')}
          maskClosable={false}
          visible={this.state.bindAeVisible}
          onCancel={() => {
            this.setState({
              bindAeVisible:false
            })
          }}
          footer={
            <Button type='primary' onClick={() => {
              window.open('/app/bind/ae')
            }}>{intl.get('top.bind_ae_foo_btn')}</Button>
          }
        >
          <div>
          {intl.getHTML('top.bind_ae_details')}
          </div>
          
        </Modal>
        {/* 检测安装插件弹层 */}
        <Modal
          title={intl.get('guide.RefreshPopText').title}
          visible={this.state.visible}
          maskClosable={false}
          onCancel={() => {
            this.setState({
              visible:false
            })
          }}
          footer={
            <Button 
            type='primary'
            onClick={()=>{
              window.location.reload()
            }}
            >{intl.get('guide.RefreshPopText').btn}
            </Button>
          }
        >
          <div>{intl.get('guide.RefreshPopText').content}</div>
        </Modal>
        {/* 绑定店铺刷新弹窗 */}
        <Modal
          title={intl.get('linkStore.modalTitle')}
          visible={this.state.refresh}
          maskClosable={false}
          closable={false}
          footer={
            <Button 
            type='primary'
            onClick={()=>{
              window.location.reload()
            }}
            >{intl.get('linkStore.modalButton')}
            </Button>
          }
        >
          <div
             dangerouslySetInnerHTML={{
                __html:
                intl.get('linkStore.modalText')
              }}
            ></div>
        </Modal>
        <>
        {
          isMobile ? 
        <div className={styles.mheader}>
          <div className={styles.fun_logo_con}>
            <img src={IconMenu} onClick={this.toggle} alt="" />
            <img src={LogoImg} alt="" />
          </div>
          <div className={styles.user_menu}>
          {userName ? (
              <Dropdown className={styles.dropdown} overlay={menu}>
                <span className={`${styles.action} ${styles.account}`}>
                  <Avatar
                    size="small"
                    className={styles.avatar}
                    style={{ backgroundColor: '#fe8f24', color: 'white' }}
                  >{userName.slice(0, 1).toUpperCase()}</Avatar>
                  <span className={styles.name}><img src={CountryFlags} alt="" /></span>
                </span>
              </Dropdown>
            ) : (
              <Spin size="small" style={{ marginLeft: 8 }} />
            )}
          </div>
        </div> 
        : 
        <div className={styles.header}>
          {isMobile ? (
            <span className={styles.trigger} onClick={this.toggle}>
              <Icon type={collapsed ? 'menu-unfold' : 'menu-fold'} />
            </span>
          ) : null}
          {showLogo ? (
            <div className={styles.headerLogo} onClick={()=>{
              window.location.href = this.props.logoHref || '/app/';
            }}>
                <img src={logo} alt=""/>
            </div>
          ) : null}
          <div className={styles.extra} />

          <div className={styles.right}>
            {/*增加SelectTags不显示状态*/}
            {
              this.props.noSelectTags ? null : (
                <>
                  <SelectTags isSaintsTheme={this.props.isSaintsTheme}/>
                  <span className={styles.separate}>|</span>
                </>
              )
            }
            {extraContent}
            {extraContent ? <span className={styles.separate}>|</span> : null}
            {showLanguage 
            ? (
              <>
                <SelectLanguage isSaintsTheme={this.props.isSaintsTheme}/>
                <span className={styles.separate}>|</span>
              </>)
            : null
            }
            {
              this.props.pathname == '/find_suppliers' && window.TMALL_USER ? (
                <div style={{cursor:'pointer'}}>
                  <Tooltip
                    title={!this.props.isSaintsTheme ? intl.get('top.ChristmasTheme') : intl.get('top.RegularTheme')}
                  >
                      <img onClick={this.handleThemeButton} style={{width:'24px',height:'24px',margin:'0 24px'}} src={this.props.isSaintsTheme ? closeBtn : openBtn} alt="" />
                      <span className={styles.separate}>|</span>
                  </Tooltip>
                  </div>
              ) : null
            }

            {
              this.props.newUserInfo?.user_role === 2 && !this.props.newUserInfo?.menu_permissions.includes(9) ?  //是否为limit账户并且菜单权限包括Notice
              null
              :
                // 迁移中页面header需要隐藏通知
                ( this.props.notNotice ? null : <Notice />)
            }
            {
               this.props.notNotice ? null :       <span className={styles.separate}>|</span>
            }

            {userName ? (
              <Dropdown overlay={menu}>
                <span className={`${styles.action} ${styles.account}`}>
                  <Avatar
                    size="small"
                    className={styles.avatar}
                    style={{ backgroundColor: '#fe8f24', color: 'white' }}
                  >{userName.slice(0, 1).toUpperCase()}</Avatar>
                  {/* <Avatar size={40} icon="user" /> */}
                  <span className={styles.name}>{userName}</span>
                </span>
              </Dropdown>
            ) : (
              <Spin size="small" style={{ marginLeft: 8 }} />
            )}
          </div>
        </div>
        }
        </>
      </>
    );
  }
}

GlobalHeader.defaultProps = {
  extraContent: null,
  showLogo: false
};

const mapStateToProps = ({ login,user }) => ({
  userInfo: login.userInfo,
  newUserInfo:user.newUserInfo
});

export default connect(mapStateToProps)(GlobalHeader);
