
    export default 
    {
    "combo": {
        "headline": "Gestionar suscripción",
        "explain": "Gestiona tu suscripción y configura una tienda de facturación por defecto aquí. Haz clic <a href=\"https://help.dsers.com/change-subscription-plan-for-shopify/\" track-type=\"blog1\" track-name=\"subscription\" target=\"_blank\" style='color:#3D5AFE'>aquí para</a> ver cómo gestionar la suscripción. Y si quieres saber más sobre el Plan Dsers, haz clic <a href=\"https://help.dsers.com/subscription-plans-presentation-for-shopify/\" track-type=\"blog2\" track-name=\"subscription\" target=\"_blank\" style='color:#3D5AFE'>aquí </a> .",
        "change": "Cambiar de plan",
        "changEcard": "Plan actual:",
        "changEcardBu": "Cambiar de plan",
        "changEcardUrl": "¿Cómo cancelar la suscripción a un plan de pago?",
        "changEcardservice": "Contacto:",
        "paymentcard": "Tienda de facturación por defecto",
        "paymentcardEcard": "Por favor, establece una tienda como tienda de facturación por defecto. Si no está configurada, la cuenta de Dsers se cerrará hasta que la configures.",
        "paymentcardBu": "Activar"
    }
}