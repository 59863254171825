export default {
  whyDisconnect: {
    need_help: 'Precisa de ajuda para usar o DSers?',
    need_help_dec:
      'Sinta-se à vontade para entrar em contato conosco caso precise de ajuda. Responderemos o mais rápido possível.',
    need_to_uninstall: 'Ao desinstalar o DSers de sua loja, esteja ciente que:',
    need_to_uninstall_dec:
      'Uma vez que a loja for removida da conta DSers atual, todos os dados dela no DSers serão perdidos e o processo NÃO poderá ser revertido.',
    leave_tip:
      'Por favor, informe o motivo para nos deixar. Sua opinião é muito importante para que possamos melhorar no futuro.',
    tell_more: 'Conte-nos mais sobre sua decisão',
    select_reason: 'Selecione um motivo',
    not_satisfied_with_product: 'Eu não estou satisfeito com os recursos de gerenciamento de produtos.',
    not_satisfied_with_order: 'Eu não estou satisfeito com os recursos de gerenciamento de pedidos.',
    not_work: 'O aplicativo não funciona muito bem.',
    better_app: 'Encontrei um aplicativo similar e melhor.',
    high_cost: 'O custo do DSers é muito alto.',
    test_store: 'É uma loja de teste.',
    sold_store: 'Eu vendi a loja.',
    closing_store: 'Eu estou fechando a loja.',
    closing_business: 'Eu estou encerrando meu negócio de dropshipping.',
    reinstall: 'Eu vou reinstalar mais tarde.',
    other: 'Outro',
    disconnect: 'Desconectado',
    stay: 'Permanecer',
    contact: 'Contato',
    update: 'Atualizar',
    lose_feature: 'If you disconnect you will lose the following privileges',
    which_module: 'Por favor, informe o motivo para nos deixar. Isso nos ajudará a melhorar no futuro!',
    suggestion_title: 'Have some suggestions? Please let us know below',
    other_platform_title: 'Moving to another platform? Tell us which one',
    stay_for_free: 'Parabéns. Informe seu endereço de e-mail para que possamos entrar em contato com você.<br />Nossa equipe de atendimento entrará em contato dentro de 1-2 dias para ativar seu acesso gratuito por um mês.',
    btn_get_free: 'Obter um mês grátis',
    woo_free: 'O DSers para WooCommerce é completamente gratuito.',
    reason: {
      feature: {
        btn: 'Recurso',
        ali_account: 'Não consegui vincular a uma conta do AliExpress',
        order_process: `Não estou satisfeito com o processamento de pedidos`,
        product_edit: `Não estou satisfeito com os recursos de edição de produtos`,
        import: `Não estou satisfeito com os recursos de importação de produtos`,
        mapping: `Não estou satisfeito com o recurso de mapeamento`
      },
      price: {
        btn: 'Preço',
        expensive: 'O DSers é muito caro'
      },
      other: {
        btn: 'Outro',
        reinstall: 'Há um problema com a loja. Reinstalarei mais tarde',
        fullillment_center: 'Desejo trabalhar com um centro ou agente de processamento',
        close_store: 'Estou fechando ou vendendo a loja / Estou fechando meu negócio no Shopify',
        test_store: `É uma loja de testes`
      }
    },
    dsers_feature: {
      ali: 'AliExpress only official partner with access to AliExpress whitelist',
      import: 'Import & Edit products from AliExpress fast and easily',
      order: 'Multiple order processing.<br />Place 100s of orders in minutes!',
      reward: 'Work with Admitad Affiliate Program to make more money'
    },
    callback_title: 'Obrigado pelo seu feedback! <br />Esperamos trabalhar juntos novamente em breve!',
    failed_link_ae: 'Eu não consegui vincular minha conta do AliExpress.',
    failed_add_shopify: 'Eu não consegui adicionar minha loja do Shopify.',
    not_satisfied_with_import: 'Eu não estou satisfeito com o recurso de importação de produtos.',
    not_satisfied_with_mapping: 'Eu não estou satisfeito com o recurso de mapeamento.',
    not_satisfied_with_bulk_order: 'Eu não estou satisfeito com o recurso de pedidos em massa.',
    not_satisfied_with_setting: 'Eu não estou satisfeito com os recursos nas configurações.',
    closing_store_or_business: `Eu estou fechando/vendendo minha loja ou estou encerrando meu negócio no Shopify ou esta é uma loja de teste.`
  }
};
