/* eslint quotes: ["error", "single", { "allowTemplateLiterals": true }] */

export default {
  bargain: {
    bargain: 'Négociation',
    counter_limit: 'Vous pouvez Négocier 10 fois toutes les 24 heures.',
    submission_tip:
      'Votre gérant de compte personnel vous répondra dans la journée',
    banner_title: 'NEGOCIATION DE PRODUIT',
    banner_content:
      'Intéressé par des prix de grossiste plus bas? <br/> Envoyez une demande avec la fonction Négociation! <br/> Votre gérant de compte personnel vous répondra en une journée.',
    not_store: `Avant d'envoyer votre négociation, veuillez vous assurer de lier une boutique valable.`,
    link_store: 'Lier Boutique',
    product_name: 'Nom du produit',
    supplier_price: 'Prix Fournisseur',
    email_field: 'Votre addresse email (Nous vous contacterons là)',
    email_field_p: 'Veuillez entrer une address email souvent utilisé',
    message_field: 'Laissez-nous un message',
    message_field_p: 'Donnez-nous des détails à propos de votre boutique',
    price_field: 'Prix Négocié',
    price_field_p: 'Veuillez entrer un prix négocié raisonnable',
    submit: 'Négocier',
    title: 'Fournisseurs',
    sub_title: 'Produits tendances',
    intro: `Une large sélection de produits avec un potentiel de vente élevé et des produits vedettes livrables en 10 jours!
      <a href="mailto:supply-support@dserspro.com">Contactez</a> nous si vous souhaitez en apprendre plus.`,
    tag_bargain: 'Négocation',
    tag_time: 'Traitement en 24h',
    tag_delivery: 'Livraison Garanti',
    tag_24: 'Support en 24h',
    tag_country: 'Livraison Express vers Pays Spécifiques',
    tag_recommend: 'Recommandations de Produit à Haut Profit',
    btn_import: 'Importer',
    btn_bargain: 'Négocier',
    search_p: 'Entrez mots clés pour rechercher',
    feedback_title: 'Vous avez envoyé une négociation !',
    feedback_con: `L'équipe DSers vous repondra <br/> dans les prochaines 24h. <br/> Merci de votre patience.`,
    copied: 'Lien copié!',
    link_copy: 'Copier lien',
    m_title: 'Produit importé',
    m_reminder: 'Ne plus afficher',
    reminder_help: `Si vous cochez cette case, cette fenêtre n'apparaitra pas la prochaine fois que vous importez un produit. Si vous souhaitez éditer un produit que vous avez importé, rendez-vous dans la Liste d'Import et cliquez sur Éditer.`,
    m_con: `Produit importé avec succès. Rendez-vous dans la Liste d'Import pour vérifier.`,
    m_btn_stay: 'Rester',
    m_btn_go: `Aller à la Liste d'Import`,
    m_fail_title: `Échec de l'import du produit!`,
    m_fail_con: `Le produit n'a pas pu être importé. Veuillez réessayer plus tard.`,
    m_fail_go: 'OK',
    cost: 'Coût',
    piece: 'pièce',
    rating: 'Note',
    product_rating: 'Produit',
    evaluation: 'Évaluation',
    sold: 'ordres',
    trendy: 'Populaire',
    found_cheaper: 'Il y a moins cher!',
    cheaper: 'Find Cheaper',
    reward: 'AliExpress Reward',
    alternative: `Besoin d'une alternative?`,
    try_this_product: 'Essayez ce produit!',
    powered_by: 'Proposé par',
    mass_supply: 'DSers Mass Supply',
    input_email: 'Veuillez entrer une address email.',
    fill_max: 'Veuillez entrer {max} caractères maximum.',
    coming_soon: 'Bientôt…',
    ten_days_delivery: 'Livraison en 10j',
    delivery_tip:
      '10 jours vers les États-Unis! Livraison en 10 jours bientôt disponible pour la France et le Brésil!',
    reward_tip:
      'Produits Mass Supplier éligible pour la saison des récompenses',
    tip_learn_more: 'En savoir plus',
    delivery_tip_url:
      'https://www.blog.fr.dsers.com/post/livraison-en-10-jours-vers-certains-pays-avec-dsers',
    reward_tip_url:
      'https://www.blog.fr.dsers.com/post/saison-des-r%C3%A9compenses-dsers',
    banner2: {
      anchor: 'anchor',
      thank_you: `Merci d'avoir choisi le programme DSers Mass Supply!`,
      delete_product_tip:
        'Il semble que vous avez supprimé le produit Shopify. <br />Nous ne pouvons pas ouvrir la page de mapping du produit pour vous.',
      copied_tip:
        'Le lien du produit DSers Mass Supply a été copié, veuillez sélectionner un produit Shopify à mapper.',
      go_mapping: 'Voir le mapping'
    },
    banner_item: {
      save_percent: 'Économisez {percent} et stable',
      provided_by: 'Fournis par',
      current_sp: 'Votre Fournisseur Actuel',
      unstable: 'Coute plus cher et instable'
    },
    found_cheaper_one: 'We found a cheaper one!',
    need_alternative: 'Do you need alternative?',
    alternative_shipping: 'Alternative with reliable shipping!',
    alternative_stock: 'Voir produit alternatif avec un stock stable',
    alternative_price: 'Voir produit alternatif avec un prix stable',
    // 一键 mapping
    one_click: {
      title: 'Voulez-vous essayer notre fournisseur sélectionné?',
      title_1: 'Increase your profit margin',
      title_1_3: 'VIP wholesale price link',
      sub_title:
        '<strong>Switch</strong> to an authorized reliable supplier with wholesale prices with <strong>DSers Mass Supply</strong>.',
      sub_title_3:
        '<strong>Get your own VIP wholesale price link like other bigger dropshippers.</strong>',
      sub_title_d: 'Congratulations, you have reached a high sale volume',
      intro_detail: 'Apply via the Switch button below to enjoy<br/> <strong>wholesale price</strong> and <strong>reliable service</strong> today.',
      intro_detail_d: 'Apply to <strong>DSers Mass Supply program</strong> to enjoy <br/><strong>Reliable service</strong> and <strong>Wholesale prices</strong> today.',
      intro1: 'Prix de gros plus bas',
      intro2: 'Inventaire stable',
      intro3: 'Service de livraison fiable',
      intro4: 'Service client dédié',
      intro5: 'Politique de confidentialité améliorée',
      detail:
        'Cliquez sur le bouton Échanger pour remplacer automatiquement le fournisseur du produit dans la(les) boutique(s) ci-dessous',
      check: 'Voir produit sur AliExpress',
      switch: 'Changer',
      switch_now: 'SWITCH RIGHT NOW',
      apply_today: 'APPLY today',
      feedback_title: 'Mapping remplacé automatiquement.',
      feedback_subtitle:
        '<a href="mailto:supply-support@dserspro.com">Contactez-nous</a> si vous avez des question',
      product_cost: 'Coût du produit',
      shipping_cost: 'Frais de livraison',
      support_tip:
        'Contact us to get more information and talk to your Account Manager: <br /><a href="mailto:supply-support@dserspro.com">supply-support@dserspro.com</a>',
      support_tip_2:
        'Your own supply chain will be activated, once after apply "<a href="javascript:void(0);">SWITCH</a>". All orders can be processed as normal.<br/>Your personal accout manager will contact you soon from<br/><a href="mailto:supply-support@dserspro.com">supply-support@dserspro.com</a>',
      support_tip_3:
        'You can apply to Switch today, your personal SAM will contact you immediately.<br /> Or feel free to talk to your personal SAM anytime via email <a href="mailto:supply-support@dserspro.com">supply-support@dserspro.com</a>',
      support_tip_4:
        'Apply via the Switch button today, your personal supply account manager (SAM) will contact you immediately. <a href="mailto:supply-support@dserspro.com">supply-support@dserspro.com</a>',
      support_tip_d_1:
        'Apply today or contact us at <a href="mailto:supply-support@dserspro.com">supply-support@dserspro.com</a><br>',
      support_tip_d_2:
        'Learn more from <a href="https://www.dsers.com/blog/dsers-mass-supplier-program/" target="_blank">DSers Mass supply program</a>',
      support_tip_e: 'Your personal Supply Account Manager (SAM) will contact you immediately after you apply. <a href="mailto:supply-support@dserspro.com">supply-support@dserspro.com</a>',
      supply_chain_title: 'Build your own Supply Chain',
      supply_chain_1:
        'Personal supply Account Manager (AM) will be fully assigned for you online',
      supply_chain_2:
        'Reliable Supplier is selected for you, via AliExpress big data analysis (data studio)',
      supply_chain_3: 'New product link is created at Wholesale/Stable price',
      benefits_a_1: 'Save ${number} by switching to a wholesale price verified product',
      benefits_a_2: 'New AliExpress product link created only for you',
      benefits_a_3: 'Personal Supply Account Manager (SAM) to assist you',
      benefits_c_1: 'DSers Mass Supply Program takes responsibilities (e.g. 100% guaranteed returned policy) ',
      benefits_c_2: 'Stable wholesale price, enough inventory and fast shipping (AliExpress Standard shipping) are promised',
      benefits_c_3: 'The VIP link is considered to be created from authorized suppliers based on big data analysis',
      benefits_d_1: '<strong>3S fulfillment center</strong> provides Standard, Stable & Safe professional fulfillment service',
      benefits_d_2: '<strong>The fulfillment service</strong> lets you save <strong>${number}</strong> over large volumes of orders',
      benefits_d_3: '<strong>Long term cooperation</strong> is possible to negotiate with your Personal Account manager',
      benefits_e_1: 'Activate the Selected Supplier by clicking CONFIRM to join the reward event',
      benefits_e_2: 'Use the Selected Supplier and complete over {number1} orders per day (or {number2} orders in 30 days) to get ${number3} Reward',
      benefits_e_3: 'Reward will be given after the orders have been completed on AliExpress with a 2 months delay',
      benefits_e_2_1: 'The Selected Supplier will activate to replace your current supplier',
      benefits_e_2_2: 'Reward will be given with the Selected Supplier and complete over {number1} orders per day (or {number2} orders in 30 days)',
      benefits_e_2_2: 'Reward will be given after the orders have been completed on AliExpress with a 2 months delay',
      benefits_f_1: 'The Selected Supplier will activate to replace your current supplier',
      benefits_f_2: 'You will save ${num_1} for a month ({num_2} for each order)',
      support_tip_f: 'Your personal Supply Account Manager (SAM) will contact you immediately after you apply.<br/><a href="mailto:supply-support@dserspro.com">supply-support@dserspro.com</a>',
      supply_chain_example:
        '(e.g. in the above case, you will save $ {number}/month)',
        v5: {
          title_1: 'Prix de Gros',
          subTitle_1: 'Service de fournisseur fiable',
          subTitle_2_1: 'Votre gestionnaire de compte',
          subTitle_2_2: 'est heureux de vous aider avec',
          contact: 'CONTACT',
          list_1_title: 'FIABILITÉ',
          list_1_text: 'Seuls les fournisseurs sélectionnés officiellement par AliExpress seront proposés ',
          list_2_title: 'PRIX DE GROS',
          list_2_text: 'Des prix bas et stables sont promis pour les entreprises de dropshipping',
          list_3_title: 'LIVRAISON RAPIDE',
          list_3_text: 'Il est recommandé d’utiliser AliExpress standard shipping par l’équipe AliExpress',
          list_4_title: 'POLITIQUE',
          list_4_text: '100% DE REMBOURSEMENT en cas d’échec de livraison',
          miss: 'Mademoiselle',
          service_text_1: 'Est gestionnaire de compte senior pour le programme DSers Mass Supply.',
          service_text_2: 'Elle travaille depuis 5 ans avec les fournisseurs.',
          service_text_3: 'Avec son expertise, elle pourra gérer et vous proposer de multiples fournisseurs  en fonction de vos besoins.',
          service_text_4: 'Contactez-la à',
          banner_title: 'Analyse Tendance du Marché1ère version',
          check: 'Vérifier'
        }
    },
    tag: {
      reward: 'Récompenses',
      delivery: 'Livraison 10j'
    },
    filter: {
      country_filter_title: 'Fournisseurs tendances par pays',
      category_filter_title: 'Catégorie',
      free_return: 'Retour gratuit',
      processing: 'Traitement en 24h',
      ship_from: 'Expédier depuis'
    },
    sorter: {
      default: 'Défaut',
      new: 'Dates',
      price: 'Prix',
      orders: 'Commandes'
    },
    btn_optimizer: 'Optimiseur',
    btn_optimizer_test: 'Trouver similaires',
    btn_optimizer_ab_test: 'Plus de fournisseur',
    logo_text: 'Sélection Premium DSers',
    product: 'Produit',
    ae_product_tip: 'Contrôle Qualité AliExpresss',
    dsers_product_tip: 'Sélection Premium DSers',
    search_empty_tip: `Désolé, aucun produit de correspond à votre recherche "{keyword}". Veuillez essayer de rechercher quelque chose d'autre.`,
    product_button_tip:
      'Cliquez sur "Produit" pour voir la liste de tous les produits',
    dont_show_again: 'Ne plus afficher',
    bargain_disabled_by_order:
      'La fonction Négocier est uniquement disponible pour les utilisateurs avec au moins 10 commandes Traitées avec un Numéro de Suivi.',
    bargain_disabled_by_bargained: `Vous avez déjà négocié le prix de ce produit aujourd'hui. Veuillez réessayer demain si vous souhaitez négocier de nouveau.`,
    img_search_failed_tip: `La fonction Recherche par image ne peut pas être utilisé pour le moment à cause d'une erreur de l'API AliExpress. Nous travaillons avec l'équipe AliExpress pour régler cela. Désolé pour la gêne occasionnée et merci de votre compréhension.`,
    img_search_success_tip_1: `La recherche par image peut vous montrer jusqu'à 60 produits identiques sur AliExpress. Cliquez`,
    here: 'ici',
    img_search_success_tip_2:
      'pour fermer les résultats de la recherche par image.',
    install_ext_title: 'Installez le Plugin DSers',
    install_ext_description:
      'Un clic pour obtenir la meilleure sélection parmi les millions de produit AliExpress et gagner du temps pour les importer',
    install_ext_description_test:
      "Oops, pas assez de résultat? Installez l'extension Chome DSers pour trouver plus de fournisseurs qualifiés",
    refresh_page: 'Rafraichir la page',
    install_ext_btn: 'Installer Plugin DSers',
    select_mask:
      'Veuillez sélectionner au moins une boutique vers laquelle envoyer le produit',
    shipCost:{
      reminder:'Nouvelle fonction en ligne!',
      text:`Vous pouvez désormais importer des produits avec le prix d'achat pour différents pays lorsque vous importez avec l'extension DSers Chrome!
      Téléchargez l'extension DSers Chrome pour découvrir cette fonction`,
      learnmore:"En savoir plus",
      install:"Installer"
    }   ,
    search_no_data_tip:`Aucun résultat, veuillez essayer d'autres mots-clés`,
    filter_no_data_tip:`Aucun résultat, veuillez changer le filtre`,   
    onlyFor:'Pour USA & Brésil'
  }
};
