export default {
  beforeMigration: {
    shopify: 'Mantieni solo le impostazioni del sito Shopify (o CSV).',
    wooCommerce: 'Mantieni solo le impostazioni del sito WooCommerce.',
    wix: 'Mantieni solo le impostazioni del sito Wix.',

    title: 'Il sistema verrà completamente aggiornato presto!',
    title1: 'Aggiornamento del sistema imminente',

    one: 'Il DSers verrà aggiornato il  ',
    two: 'e non sarà disponibile per un massimo di 24 ore',
    there: 'Si prega di organizzare il proprio programma di conseguenza',

    four:
      'Per offrirti una migliore esperienza utente, il DSers aggiornerà il sistema per un periodo di',
    five: '24 ore a partire da',
    six:
      "Il DSers non sarà disponibile durante il periodo. Ci scusiamo per l'inconveniente. ",
    seven: 'Clicca ',
    seven1: 'qui',

    //-------
    eight: ' i per saperne di più.',

    nine: 'per ulteriori informazioni.',

    ten:
      'Per offrirti una migliore esperienza utente, il DSers aggiornerà il sistema per un periodo di',


    eleven:
      "Il DSers non sarà disponibile durante il periodo. Ci scusiamo per l'inconveniente.",
    twelve:
      "L'aggiornamento mira a semplificare la gestione multi-piattaforma. Questo potrebbe causare conflitti di impostazioni se si stanno gestendo negozi su più piattaforme. La compatibilità delle differenze tra ciascuna piattaforma è pienamente considerata. Tuttavia, per favore comunicaci la tua preferenza nel caso.",
    thirteen:
     "Come desideri unire le impostazioni potenzialmente conflittuali nell'area Impostazioni dopo l'aggiornamento?",

    fourteen:
      'Dopo la fusione, le impostazioni a livello di account tra i due siti entreranno in conflitto, quindi dovrai scegliere quale sito mantenere. Le impostazioni in conflitto sono:',
    fifteen: 'Notifica',
    sixteen: 'Account del personale',
    seventeen: 'Spedizione',
    eighteen: 'Aggiungi metodo di spedizione globale basato sul prodotto',
    nineteen: 'Lascia un messaggio ai fornitori',
    twenty: 'Ottimizzazione del numero di telefono',
    twentyOne: 'Ottimizzazione degli ordini cancellati',
    twentyTwo: ' E altre funzioni',

    twentyThere: "Ma non preoccuparti, continueremo comunque a conservare le impostazioni a livello di negozio tra i due siti per te, come ad esempio:",
    twentyFour: 'Regola di Prezzo',
    twentyFive: 'Tracciamento Pacchetti',
    twentySix: 'Valute',

    twentySeven: 'Il sistema è in fase di aggiornamento, il che ',
    twentyEight: 'richiederà circa 24 ore',
    twentyNine:
      'Il DSers verrà sospeso durante il periodo di aggiornamento, e ci scusiamo per eventuali disagi.',

    twentyTen:
      'Spiacenti, il sistema è attualmente in fase di aggiornamento e non può essere vincolato, per favore riprova più tardi.',
    notification: 'Notifica',

    submit: 'Conferma'
  }
};
