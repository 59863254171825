import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import styles from './index.less';

const endDate = Date.UTC(2021, 0, 1); // 到 2021 年 1月 1号就不提示了

function NoticePlanBanner({ style, path, userInfo }) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(checkAdBanner(path));
  }, [path]);

  // 展示在Home, Import list, My product, Open, Archived， Setting 页面
  function checkAdBanner(pathname) {
    let allow = false;
    const allowPath = [
      'accountoverview',
      'storemanage/myproduct',
      'storemanage/import',
      'order',
      'settings'
    ];

    for (const i of allowPath) {
      if (pathname.indexOf(i) > -1) {
        allow = true;
        break;
      }
    }

    return allow;
  }

  if (!visible || !(userInfo && userInfo.show_sync_stock_banner)) {
    return null;
  }

  if (endDate < Date.now()) {
    return null;
  }

  return (
    <div className={styles.bannerCon} style={style}>
      <span role="img" aria-label="info">
        ⚠️
      </span>
      <span>{intl.get('notice_plan_banner')}</span>
    </div>
  );
}

export default NoticePlanBanner;
