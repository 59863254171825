import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Button } from 'antd';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';

import reactGaEvent from 'utils/GAEvent';
import { CommonDataCollect } from 'utils/utils';

import banner from '../../../assets/payInto/banner.png';
import banner_2 from '../../../assets/payInto/banner_2.png';
import banner_3 from '../../../assets/payInto/banner_3.png';
import banner_4 from '../../../assets/payInto/banner_4.png'; 

import headPortrait from '../../../assets/payInto/headPortrait.png';
import money from '../../../assets/payInto/money.png';
import shopping from '../../../assets/payInto/shopping.png';
import smallBell from '../../../assets/payInto/smallBell.png';
import flame from '../../../assets/payInto/flame.png';
import heart from '../../../assets/payInto/heart.png';
import soon from '../../../assets/payInto/soon.png';
import house from '../../../assets/payInto/house.png';
import aircraft from '../../../assets/payInto/aircraft.png';
import box from '../../../assets/payInto/box.png';
import mailbox from '../../../assets/payInto/mailbox.png';

import styles from './index.less';

const modalRoot = document.getElementById('root');

function PayInto({ dispatch }) {
  localStorage.removeItem('PayIntoDetails');
  localStorage.removeItem('PayIntoDetailsTwo');
  localStorage.removeItem('PayIntoDetailsThree');

  let payInto = localStorage.getItem('PayIntoDetailsFive');

  const [details, setDetails] = useState(!!payInto);

  function closeDetails() {
    localStorage.setItem('PayIntoDetailsFive', true);
    setDetails(true);
  }

  function showDetails() {
    CommonDataCollect({
      event_type: 'subscription_launch',
      action: 'click_show_details_CAM004_button'
    });
    reactGaEvent({
      category: 'PayInto',
      action: 'click_show_details_CAM004_button'
    });
    localStorage.removeItem('PayIntoDetailsFive');
    setDetails(false);
  }

  function gotoSelect() {
    CommonDataCollect({
      event_type: 'subscription_launch',
      action: 'click_upgrade_CAM004_button'
    });
    reactGaEvent({
      category: 'PayInto',
      action: 'click_upgrade_CAM004_button'
    });

    dispatch(routerRedux.push('/pricing?select=1'));

  }

  function showBlog() {
    CommonDataCollect({
      event_type: 'subscription_launch',
      action: 'click_show_blog_CAM004_button'
    });
    reactGaEvent({
      category: 'PayInto',
      action: 'click_show_blog_CAM004_button'
    });
  }
 
  if (details) {
    return (
      <div className={styles.container}>
        <div className={styles.text}>
          <a href="https://www.dsers.com/blog/how-to-track-your-orders/" target="_black" onClick={showBlog}>
            Strengthen Customer Trust with Tracking Feature.
          </a>
        </div>
        <div className={styles.operation}>
          <div className={styles.button} onClick={gotoSelect}>
            Try Now
          </div>
          <i class="material-icons notranslate" onClick={showDetails}>
            keyboard_arrow_up
          </i>
        </div>
      </div>
    );
  }

  return ReactDOM.createPortal(
    <div className={styles.mask}>
      <div className={styles.modal}>
        <div className={styles.header}>
          <div className={styles.left}>
            <div className={styles.headPortrait}>
              <img src={headPortrait} />
            </div>
            <div className={styles.text}>
              <div className={styles.title}>Merchant Growth Team</div>
              <div className={styles.subTitle}>
                DSers empowers your dropshipping journey
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <i class="material-icons notranslate" onClick={closeDetails}>
              keyboard_arrow_down
            </i>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.location}>
            <div className={styles.img}>
              <img src={box} />
            </div>
            <p>Timely access to package status.</p>
          </div>

          <div className={styles.location}>
            <div className={styles.img}>
              <img src={aircraft} />
            </div>
            <p>Reduce delivery delays.</p>
          </div>

          <div className={styles.location}>
            <div className={styles.img}>
              <img src={mailbox} />
            </div>
            <p>Give feedback to customers in time.</p>
          </div>

          <p>Build trust for repeat purchases!</p>
        </div>

        <div className={styles.footer}>
          <div className={styles.image}>
            <img src={banner_4} />
          </div>

          <div className={styles.option}> 
            <div className={styles.text}>
              <a href="https://www.dsers.com/blog/how-to-track-your-orders/" target="_black" onClick={showBlog}>
                Strengthen Customer Trust with Tracking Feature.
              </a>
            </div>
            <div className={styles.button} onClick={gotoSelect}>
              Try Now
            </div>
          </div>
        </div>
      </div>
    </div>,
    modalRoot
  );
}
 
const CAM004 = connect()(PayInto);

export {
  CAM004
}