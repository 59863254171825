import React from 'react';
import logo from '../../../assets/svg/new_logo.png';
import styles from './header.less';

function DetailHeader() {
  return (
    <div className={styles.logo} onClick={()=>{window.location.href = '/app/'}}><img src={logo} alt="" /></div>
  )
}

export default DetailHeader