import { Post, Get, Put } from '../utils/request';
import { dsersBFFApiHost } from '../common/sysconfig';

export async function getOrderList(payload = {}) {
  return Post('/ord/list', payload);
}

export async function purchase(payload) {
  return Post('/order/placeorder/new', payload);
}

export async function getBindProduct(payload) {
  return Get('/prod/ali', payload);
}

export async function getBindTMProduct(payload) {
  return Get('/prod/tmall', payload);
}

export async function getTmallBindProduct(payload) {
  return Get('/prod/tmall', payload);
}

export async function overrideOrder(payload) {
  return Post('/order/override', payload);
}

export async function updateMyProduct(payload) {
  return Post('/product/updatesku', payload);
}

export async function getArea(payload) {
  return Get('/order/archive/area', payload);
}

export async function saveAddress(payload) {
  return Post('/order/archive/update', payload);
}

export async function getGuidance(payload) {
  return Get('/leadpagestatus', payload);
}

export async function postGuidance(payload) {
  return Post('/leadpagestatus', payload);
}

export async function getFreightList(payload) {
  return Post('/prod/freight', payload);
}

export async function getAgencyFreightList(payload) {
  return Post('/prod/freight/agency', payload);
}

export async function addOrderNoAndTrackNo(payload) {
  return Post('/order/update/ordernum', payload);
}

export async function getOrderGeneral(payload = {}) {
  return Post('/user/order/message/update', payload);
}

export async function setOrderStatus(payload = {}) {
  return Post('/order/cancelorder', payload);
}

export async function getJobAE(payload = {}) {
  return Get('/ord/job/aliexpress', payload);
}

export async function setFulfillment(payload = {}) {
  return Post('/order/fulfillment', payload);
}

export async function getCountryList(payload = {}) {
  return Get('/set/country', payload);
}

export async function getCount(payload = {}) {
  return Post('/ord/count', payload);
}

export async function postUpDataExt(payload) {
  return Post('/ord/ext', payload);
}

export async function postUpDataExtNew(payload) {
  return Post('/ord/fulfill/cancel/rollback', payload);
}

export async function getProvince(payload) {
  return Get('/set/prov', payload);
}

export async function pushPlaceOrder(payload) {
  return Post('/ord/place-new', payload);
}

export async function placeAgencyAndTmallOrder(payload) {
  return Post('/supply/order/place', payload)
}

export async function markAgencyOrderAsPaid(payload) {
  return Post('/supply/order/mark-as-paid', payload);
}

export async function markAgencyOrderAsCanceled(payload) {
  return Post('/supply/order/mark-as-cancel', payload);
}

export async function getOrderTask(payload) {
  return Get('/ord/place', payload);
}

export async function getMapProd(payload) {
  return Get('/prod/item', payload);
}

export async function createAESyncJob(payload) {
  return Post(
    payload.type === 1
      ? '/ord/job/aliexpress'
      : '/ord/job/aliexpress?refreshtn=1',
    payload
  );
}

export async function getAeAddressRules(payload) {
  return Get('/prod/vrules', payload);
}

export async function getOrderSetting(payload) {
  return Get('/set/order_setting', payload);
}

export async function getOrderNoDetail(payload) {
  return Get('/ord/aliexpress/detail', payload);
}

export async function postSyncFulfillment(payload) {
  return Post('/ord/fulfill', payload);
}

export async function getOrderAliCount(payload) {
  return Get('/ord/job/aliexpress/times', payload);
}

export async function getOtherImage(payload) {
  return Post('/prod/other_img', payload);
}

export async function getArchivedList(payload) {
  return Get('/ord/archive', payload);
}

export async function putUnarchive(payload) {
  return Put('/ord/archive', payload);
}
// 获取用户支付计划
export async function payment(payload) {
  return Get('/charge/plan', payload);
}

export async function pushOldPlaceOrder(payload) {
  return Post('/ord/place', payload);
}
export async function pushTMPlaceOrder(payload) {
  return Post('/supply/order/place', payload);
}

export async function setArchive(payload) {
  return Post('/ord/archive', payload);
}

export async function getSyncTnTaskList(payload) {
  return Get('/plugin/ordertn', payload);
}
// 获取插件下单任务
export async function getExtViaExtenionTask(payload) {
  return Get("/ord/place/params", payload);
}
// 提交下单任务的结果
export async function cancelExtTask(payload) {
  return Put("/ord/place", payload);
}
//店铺维度查询是否存在aecancel订单
export async function getAeCancelOrderTip(payload){
  return Get('/ord/fulfill/cancel', payload)
}

export async function getTmallCaiNiaoAddressValidateRules(payload) {
  return Get(dsersBFFApiHost + '/dsers-order-bff/v1/address/rules', {
    ...payload,
    isCustomApi: true
  });
}

export async function getAgencyShippingCost(payload) {
  return Post('/supply/order/agency/shipping-cost', payload);
}