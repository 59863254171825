
    export default 
    {
    "reward": {
        "title": "Periodo dei Premi DSers",
        "intro": "Ottieni un premio di più di $100 usando prodotti raccomandati dal Programma di Fornitura di Massa DSers. Clicca qui per scoprire come vincerlo. Per favore contatta il nostro team di supporto se hai qualche domanda.",
        "dollar_100": "Ottieni $100 e più dal 15 ottobre al 15 gennaio",
        "dollar_100_intro": "Per ottenere il premio da $100 USD, <br /> dovrai effettuare 1000 ordini validi durante i 100 giorni dell'evento. <br /> Il numero diventerà verde dopo che l'avrai raggiunto.",
        "get_more_title": "Ottieni di più effettuando più ordini con prodotti nella pagina Negozia Prodotti fra il 15 ottobre e il 15 gennaio",
        "total_title": "Quanto hai guadagnato in totale durante l'evento",
        "request_btn": "Richiedi un premio",
        "requested_btn": "Richiesto",
        "requested_intro": "Questo pulsante sarà cliccabile il 15 febbraio, UTC +8, se avrai guadagnato qualcosa",
        "form_title": "Inserisci le tue informazioni qui sotto, il nostro team ti contatterà presto.",
        "feedback": "Il team ti contatterà presto. Grazie per la pazienza."
    }
}