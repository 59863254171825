import React from 'react';

import styles from './Point.less';

const Point = React.forwardRef(
  ({ style, onClick, showPoint = true, sequence, ...props }, ref) => (
    <div className={styles.pointCon} style={style} ref={ref}>
      <a
        className={styles.pointer}
        aria-label="Learn about this new feature"
        tabIndex="-1"
        role="button"
        onClick={onClick}
        style={{ display: showPoint ? 'block' : 'none' }}
        {...props}
      >
        <span>
          <span className={styles.bottom} />
          <span className={styles.top} />
        </span>
      </a>
      <span className={styles.finger} />
      {sequence ? <i className={styles.sequence}>{sequence}</i> : null}
    </div>
  )
);

Point.displayName = 'Point(forwardRef)';

export default Point;
