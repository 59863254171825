import React from 'react';
import ReactDOM from 'react-dom';
// import { Icon } from 'antd';

import styles from './RewardSeason.less';
import useOnlineNotice from '../../hooks/useOnlineNotice';

const onlineDate = new Date(2020, 9, 15); // 上线日期

const modalRoot = document.getElementById('root');

function RewardSeason({ onClose, lang }) {
  const [visible, closeNotice] = useOnlineNotice('reward_season', onlineDate);

  function handleClose() {
    closeNotice();
  }

  function handleInnerClick(e) {
    e.stopPropagation();
  }

  if (!visible) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.notice} role="presentation" onClick={handleClose}>
      <div
        className={styles.body}
        role="presentation"
        onClick={handleInnerClick}
      >
        {/* <span className={styles.close}>
          <Icon type="close" onClick={onClose} />
        </span> */}
        <div className={styles.content}>
          {lang === 'fr-FR' ? (
            <>
              <div className={styles.title}>
                La Saison des Récompenses DSers <span>est là!</span>
              </div>
              <div className={styles.subTitle}>
                Livraison en <span>10 jours</span> vers les États-Unis.
              </div>
              <div className={styles.subTitle2}>
                Livraison en <span>10 jours</span> vers l'Europe et le Brésil bientôt disponible!
              </div>
              <div className={styles.intro}>
                Obtenez plus de 100$ de récompenses en utilisant des produits DSers.
              </div>
              <a
                href="https://www.blog.fr.dsers.com/post/saison-des-r%C3%A9compenses-dsers"
                target="_blank"
                rel="noopener noreferrer"
                data-track-category="reward-season-online-notice"
                data-track-action="open Reward Season"
                onClick={handleClose}
              >
                <span className={styles.link}>Savoir plus</span>
              </a>
            </>
          ) : (
            <>
              <div className={styles.title}>
                DSers Reward Season is <span>online now!</span>
              </div>
              <div className={styles.subTitle}>
                <span>10 days</span> delivery to USA.
              </div>
              <div className={styles.subTitle2}>
                <span>10 days</span> delivery to EU and Brazil coming soon.
              </div>
              <div className={styles.intro}>
                Get 100+USD reward by using DSers Mass Supplier products.
              </div>
              <a
                href="https://www.blog.dserspro.com/post/dsers-reward-season"
                target="_blank"
                rel="noopener noreferrer"
                data-track-category="reward-season-online-notice"
                data-track-action="open Reward Season"
                onClick={handleClose}
              >
                <span className={styles.link}>LEARN MORE</span>
              </a>
            </>
          )}
        </div>
      </div>
    </div>,
    modalRoot
  );
}

export default RewardSeason;
