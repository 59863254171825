
    export default 
    {
    "register": {
        "title": "Crear una cuenta DSers",
        "email": "Correo electrónico",
        "password": "Contraseña",
        "get_started": "CREAR CUENTA",
        "input_your_email": "Por favor, introduce el correo electrónico en el formato correcto.",
        "input_password": "Por favor, introduce de 6 a 20 caracteres.",
        "wrong_email_format": "Formato de correo electrónico incorrecto",
        "password_tip": "Por favor, utiliza una contraseña con al menos 6 caracteres. No uses una contraseña que pueda ser descifrada fácilmente.",
        "contact_us": "Contáctanos",
        "hello_shopify": "Hola, Shopify",
        "install_dsers_to_shopify": "Instalar Dsers a Shopify",
        "password_ok": "Seguridad: Alta",
        "password_warning": "Seguridad: Media",
        "password_error": "Seguridad: Baja",
        "privacy": "Al hacer clic en Crear cuenta, aceptas nuestra <a href= \"/privacy\" target=\"_blank\">POLÍTICA DE PRIVACIDAD</a>",
        "result": {
            "resend_successed": "reenvío exitoso",
            "resend_failed": "reenvío fallido",
            "title": "Procesar pedidos masivos y múltiples con Dsers",
            "your_account": "Tu cuenta:",
            "register_succ": "ha sido registrada con éxito.",
            "activation_email": "Por favor, verifica tu buzón de <br /> correo electrónico y haz clic en <br /> el enlace para activar tu cuenta dentro de los próximos 10 minutos.",
            "check_email": "Verificar el correo electrónico",
            "resend_email": "Reenviar correo electrónico",
            "go_sign": "Ir a registrarse o iniciar sesión",
            "about_email": "¿No has recibido ningún correo electrónico? Haz clic <a href=\"https://help.dsers.com/confirmation-email-not-received-for-shopify/\" target=\"_blank\">aquí para</a> saber más detalles."
        },
        "password_error_long": "La contraseña debe tener entre 8 y 16 caracteres",
        "password_error_format": "La contraseña debe contener al menos 1 letra mayúscula, 1 letra minúscula y 1 número",
        "password_not_match_twice": "Las contraseñas no coinciden",
        "accountSuccess": "Finish registration",
        "haveAccount": "¿Tienes una cuenta?",
        "signIn": "INICIAR SESIÓN"
    }
}