
    export default 
    {
    "guidance": {
        "welcome": "Willkommen bei DSers",
        "need_steps": "Sie brauchen nur <i>4</i> Schritte, um loszulegen",
        "start_tutorial": "Tutorial starten",
        "before_starting": "Bevor Sie beginnen, <br />stellen Sie bitte sicher, dass Sie die Shopify-Autofulfillment-Funktion DEAKTIVIEREN. <br />Andernfalls werden Sie nicht in der Lage sein, Bestellungen auf DSers zu verarbeiten. <br /><i>gehen Sie zu Shopify - Einstellung - Kasse, um sie zu deaktivieren.</i>",
        "previous": "Vorherige",
        "next": "Nächste",
        "start": "Start",
        "link_to_more_stores": "Link zu weiteren Geschäften",
        "bind_ali_over": "AliExpress-Konto auf der Whitelist",
        "link_to_ae": "Link zu AliExpress",
        "home": "Startseite",
        "my_product": "Meine Produkte",
        "setting": "Einstellung",
        "shipping": "Versand",
        "orders": "Bestellungen",
        "new_feature": "Neue Funktion",
        "notification": "Benachrichtigung",
        "blog": "BLOG",
        "step1": {
            "title": "Verknüpfen Sie zunächst DSers mit Ihrem Shopify-Shop",
            "step1_dec": "Klicken Sie oben auf der Seite auf die Schaltfläche <i>Link zu weiteren Shops.</i>",
            "step2_dec": "Wählen Sie Shopify als Plattform aus und geben Sie den Namen Ihres Shops ein, um ihn mit DSers zu verknüpfen."
        },
        "step2": {
            "title": "Verknüpfen Sie dann DSers mit Ihrem AliExpress-Konto.",
            "step1_dec": "Klicken Sie auf die Schaltfläche <i>Link to AliExpress</i> oben auf der Seite.",
            "step3_dec": "Bewerben Sie sich bei AliExpress Whitelist. Dadurch erhalten Sie Privilegien und können Großbestellungen aufgeben und die Fehlerquote bei Bestellungen reduzieren."
        },
        "step3": {
            "title": "Weiter, Produkte einem AliExpress-Lieferanten zuordnen",
            "dec": "Klicken Sie auf die Schaltfläche auf der rechten Seite einer Produktkarte, um Ihren Shopify-Produkten AliExpress-Lieferanten zuzuordnen. DSers weiß dann, wie Sie Ihre Bestellungen an AliExpress weiterleiten können.",
            "map_multiple": "DSers ermöglicht es Ihnen, mehrere Lieferanten zuzuordnen, je nach:",
            "map_from_to": "1. Versand von/nach Ländern",
            "advance": "Details über <a href=\"https://help.dsers.com/advanced-mapping-for-shopify/\" target=\"_blank\">erweiterte</a> Zuordnung",
            "upsell_promotion": "2. Upsell-Aktion",
            "bogo_bundle": "Prüfen Sie die Details zu <a href=\"https://help.dsers.com/create-buy-one-get-one-offers-for-shopify/\" target=\"_blank\">BOGO</a> / <a href=\"https://help.dsers.com/create-bundles-of-products-for-shopify/\" target=\"_blank\">BUNDLE</a> Zuordnung"
        },
        "step4": {
            "title": "Vergessen Sie nicht, die Versandart festzulegen",
            "dec": "Für den letzten Schritt der Vorbereitung richten Sie bitte Ihre Standardversandmethode unter Einstellungen - Versand ein. Sie können sie entweder nach Land oder nach <a href=\"https://help.dsers.com/set-shipping-method-for-all-products-for-shopify/\" target=\"_blank\" >AliExpress-Produkt</a>."
        },
        "step5": {
            "title": "Schließlich können Sie Bestellungen in großen Mengen aufgeben",
            "dec": "Wählen Sie alle Ihre Bestellungen auf der Registerkarte Wartende Bestellung aus und klicken Sie dann auf die Schaltfläche Bestellung an AliExpress senden",
            "tip": "Überprüfen Sie die Bestelldaten und die Versandart. Dann sind Sie fertig!"
        },
        "after": {
            "dec": "Nachdem Sie die Zahlung getätigt haben, überlassen Sie den Rest DSers.<br />DSers <b>SYNCHRONISIERT AUTOMATISCH</b> für Sie",
            "p1": "Automatischer Abgleich des Bestellstatus und der Tracking-Nummer von AliExpress zu DSers alle 12 Stunden",
            "p2": "Automatische Synchronisierung der Tracking-Nummern von DSers zu Shopify 1-2 Stunden nach der Synchronisierung von AliExpress",
            "p3": "Automatische Auslösung der Shopify Versandbestätigungsmail an Ihre Kunden"
        },
        "actions": {
            "title": "Die folgenden Aktionen verhindern, dass DSers Bestellungen verarbeitet oder <br /> synchronisiert. Bitte:",
            "do_not1": "Führen Sie Bestellungen <i>NICHT</i> manuell auf DSers oder Shopify aus, wenn Sie sie mit DSers bearbeiten möchten.",
            "do_not2": "Aktivieren Sie <i>NICHT</i> die Funktion zur automatischen Auftragsabwicklung auf Shopify.",
            "do_not3": "Beantragen Sie <i>NICHT</i> die Erfüllung auf Shopify."
        },
        "get_ebook": "Holen Sie sich das kostenlose Ebook oder <br /> kontaktieren Sie den Kundendienst hier",
        "check_again": "Sehen Sie sich diese Anleitung hier noch einmal an",
        "confused": "Wenn Sie immer noch verwirrt sind, <br /> wie Sie DSers benutzen",
        "guidance_again": "Sehen Sie sich die Tutorial-Videos noch einmal an",
        "order": "How to place multiple orders with DSers?",
        "import": "How to import products to DSers?",
        "total_sales": "Gesamtumsatz",
        "shopify_sales": "Shopify Verkäufe",
        "ali_orders": "Ali-Bestellungen",
        "supplier_cost": "Lieferantenkosten"
    }
}