import React, { useEffect, useState, useRef } from 'react';

import {
  Icon,
  Modal,
  Spin,
  Button
} from 'antd';
import intl from 'react-intl-universal';

import styles from './SwitchV5.less';
import percent1 from '../../../../assets/bargain/percent_5.png';
import percent2 from '../../../../assets/bargain/percent_10.png';
import percent3 from '../../../../assets/bargain/percent_15.png';
import percent4 from '../../../../assets/bargain/percent_20.png';
import color from '../../../../assets/bargain/color.png';
import color2 from '../../../../assets/bargain/color2.png';
import check3 from '../../../../assets/bargain/check3.png';
import service from '../../../../assets/bargain/service.png';
import { v5Map, getVersion } from '../../../../utils/bannerWhiteList';
import DsQRCode from '../../../../components/DsQRCode';

const percentMap = {
  '5': percent1,
  '10': percent2,
  '15': percent3,
  '20': percent4
};

const SwitchV5 = ({
  oneClickMapping,
  handleModalCancel,
  state,
  compareProducts,
  dispatch,
  setShow,
  show,
  cancel,
  userId
}) => {
  const info = v5Map[userId];
  let precentImg = percent1;
  let name = 'Shirley';
  let email = 'zhangling@dserspro.com';
  let text = '';
  if (info) {
    precentImg = percentMap[info[0]];
    name = info[1];
    email = info[2];
    text = 'https://wa.me/' + info[3];
  }
  const href = 'mailto:' + email;

  let imgArr = [];
  if (compareProducts && compareProducts[0] && compareProducts[0].img) {
    imgArr = compareProducts[0].img.split(';');
  }

  const [cardImg, setCardImg] = useState(imgArr[0]);

  const Product = ({
    img = '',
    title,
    supplier,
    price,
    shippingPrice,
    url,
    country,
    shipMethod,
    userId
  }) => {
    const totalPrice = (parseFloat(price) + parseFloat(shippingPrice)).toFixed(2);
    return (
      <div className={styles.compareProduct}>
        <img src={imgArr[0]} />
        <div className={styles.infoCon}>
          <div className={styles.compareProductTitle}>{title}</div>
          <div className={styles.totalPrice}>US ${totalPrice}</div>
          <div className={styles.cost}>
            <span className={styles.costName}>{intl.get('bargain.one_click.product_cost')}</span>
            <span className={styles.costNumber}>${price}</span>
          </div>
          <div className={styles.cost}>
            <span className={styles.costName}>{intl.get('bargain.one_click.shipping_cost')}</span>
            <span className={styles.costNumber}>${shippingPrice}</span>
          </div>
        </div>
      </div>
    );
  };

  const switchImg = img => {
    setCardImg(img);
  };

  // const text = 'https://wa.me/' + v5Map[userId][3];
  // console.log('v5Map-------', v5Map);
  // console.log('v5Map[userId]----------------', v5Map[userId]);
  // console.log('text----------------', text);
  const Product2 = ({
    img = '',
    title,
    supplier,
    price,
    shippingPrice,
    url,
    country,
    shipMethod,
    userId
  }) => {
    const totalPrice = (parseFloat(price) + parseFloat(shippingPrice)).toFixed(2);
    return (
      <>
        <div className={styles.compareProduct}>
          <img src={cardImg} />
          <div className={styles.infoCon}>
            <div className={styles.compareProductTitle}>{title}</div>
            <div className={styles.totalPrice}>US ${totalPrice}</div>
            <div className={styles.cost}>
              <span className={styles.costName}>{intl.get('bargain.one_click.product_cost')}</span>
              <span className={styles.costNumber}>${price}</span>
            </div>
            <div className={styles.cost}>
              <span className={styles.costName}>{intl.get('bargain.one_click.shipping_cost')}</span>
              <span className={styles.costNumber}>${shippingPrice}</span>
            </div>
          </div>
        </div>
        <div className={styles.imgsCon}>
          {imgArr.map((item, index) => {
            if (index > 0 && index < 6) {
              return (
                <div className={styles.img}>
                  <img
                    src={item}
                    className={styles.imgIcon}
                    onClick={() => {
                      switchImg(item);
                    }}
                  />
                </div>
              );
            }
          })}
        </div>
      </>
    );
  };

  return (
    <>
      <Modal
        visible={oneClickMapping.visible}
        footer={null}
        width={483}
        maskClosable={false}
        keyboard={false}
        onCancel={handleModalCancel}
        bodyStyle={{ padding: '36px 36px' }}
        destroyOnClose
        className={styles.onClickMappingModal_v5}
        zIndex={1100}
      >
        <Spin spinning={state.loading}>
          <>
            <div className={styles.body}>
              <img className={styles.bgImg} src={color} />
              <div className={styles.textCon}>
                <div className={styles.leftCon}>
                  <div className={styles.cardCon}>
                    {compareProducts.map((item, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Product {...item} />
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
                <div className={styles.rightCon}>
                  <div className={styles.mainTitle}>
                    <div className={styles.title1}>
                      {intl.get('bargain.one_click.v5.title_1')}
                    </div>
                    <div className={styles.title2}>
                      {intl.get('bargain.one_click.v5.subTitle_1')}
                    </div>
                  </div>
                  <div className={styles.imgCon}>
                    <img className={styles.percentImg} src={precentImg} />
                  </div>
                  <Button
                    className={styles.button}
                    type="primary"
                    onClick={() => {
                      setShow(true);
                      dispatch({
                        type: 'global/closeOneClickMapping',
                        callback: oneClickMapping.callback,
                        payload: oneClickMapping.extraData
                      });
                      dispatch({
                        type: 'global/dataTrack',
                        payload: {
                          data: {
                            user_id: userId,
                            store_id: oneClickMapping.extraData.store_id.toString(),
                            platform: 'shopify',
                            page: 'oneClickMapping',
                            page_url: window.location.href,
                            module: 'oneClickMapping',
                            action: 'apply-button-click',
                            product_id_list: [''],
                            order_id_list: [''],
                            custom_info: [
                              {
                                name: 'switch_version',
                                value: getVersion(userId)
                              },
                              {
                                name: 'ae_product_id',
                                value: oneClickMapping.extraData.ae_product_id.toString()
                              },
                              {
                                name: 'dsers_product_id',
                                value: oneClickMapping.extraData.dsers_product_id.toString()
                              },
                              {
                                name: 'dsers_product_id',
                                value: oneClickMapping.extraData.dsers_product_id.toString()
                              },
                              {
                                name: 'shopify_product_id',
                                value: oneClickMapping.extraData.shopify_product_id.toString()
                              },
                              {
                                name: 'mapping_type',
                                value: oneClickMapping.extraData.mapping_type.toString()
                              }
                            ]
                          }
                        }
                      });
                      setCardImg(imgArr[0]);
                    }}
                    loading={state.switchLoading}
                    disabled={state.checkedList.length === 0}
                  >
                    APPLY
                  </Button>
                </div>
              </div>
            </div>
          </>
        </Spin>
      </Modal>
      <Modal
        visible={show}
        footer={null}
        width={503}
        maskClosable={false}
        keyboard={false}
        onCancel={cancel}
        bodyStyle={{ padding: '24px 24px' }}
        destroyOnClose
        className={styles.confirmWindow}
        zIndex={1101}
        centered
      >
        <div className={styles.confirmCon}>
          {/* <img className={styles.bgImg} src={color2} /> */}
          <div className={styles.textContainer}>
            <div className={styles.textCon}>
              <div className={styles.leftCon}>
                <div className={styles.imgCon}>
                  <img className={styles.percentImg} src={precentImg} />
                </div>
                <a href="mailto:supply-support@dserspro.com">
                  <Button
                    className={styles.button}
                    type="primary"
                    onClick={() => {
                      dispatch({
                        type: 'global/dataTrack',
                        payload: {
                          data: {
                            user_id: userId,
                            store_id: oneClickMapping.extraData.store_id.toString(),
                            platform: 'shopify',
                            page: 'oneClickMapping',
                            page_url: window.location.href,
                            module: 'oneClickMapping',
                            action: 'contact-button-click',
                            product_id_list: [''],
                            order_id_list: [''],
                            custom_info: [
                              {
                                name: 'switch_version',
                                value: getVersion(userId)
                              },
                              {
                                name: 'ae_product_id',
                                value: oneClickMapping.extraData.ae_product_id.toString()
                              },
                              {
                                name: 'dsers_product_id',
                                value: oneClickMapping.extraData.dsers_product_id.toString()
                              },
                              {
                                name: 'dsers_product_id',
                                value: oneClickMapping.extraData.dsers_product_id.toString()
                              },
                              {
                                name: 'shopify_product_id',
                                value: oneClickMapping.extraData.shopify_product_id.toString()
                              },
                              {
                                name: 'mapping_type',
                                value: oneClickMapping.extraData.mapping_type.toString()
                              }
                            ]
                          }
                        }
                      });
                    }}
                  >
                    {intl.get('bargain.one_click.v5.contact')}
                  </Button>
                </a>
                <div className={styles.mainTitle}>
                  <div className={styles.title}>
                    {intl.get('bargain.one_click.v5.subTitle_2_1')}
                  </div>
                  <div className={styles.title}>
                    {intl.get('bargain.one_click.v5.subTitle_2_2')}
                  </div>
                </div>
              </div>
              <div className={styles.rightCon}>
                <div className={styles.cardCon}>
                  {compareProducts.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <Product2 {...item} />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className={styles.declareList}>
              <div className={styles.list}>
                <img className={styles.circle} src={check3} />
                <div className={styles.listOne}>
                  <strong>
                    {intl.get('bargain.one_click.v5.list_1_title')}
                  </strong>
                  <br/>
                  {intl.get('bargain.one_click.v5.list_1_text')}
                </div>
              </div>
              <div className={styles.list}>
                <img className={styles.circle} src={check3} />
                <div className={styles.listOne}>
                  <strong>
                    {intl.get('bargain.one_click.v5.list_2_title')}
                  </strong>
                  <br/>
                  {intl.get('bargain.one_click.v5.list_2_text')}
                </div>
              </div>
              <div className={styles.list}>
                <img className={styles.circle} src={check3} />
                <div className={styles.listOne}>
                  <strong>
                    {intl.get('bargain.one_click.v5.list_3_title')}
                  </strong>
                  <br/>
                  {intl.get('bargain.one_click.v5.list_3_text')}
                </div>
              </div>
              <div className={styles.list}>
                <img className={styles.circle} src={check3} />
                <div className={styles.listOne}>
                  <strong>
                    {intl.get('bargain.one_click.v5.list_4_title')}
                  </strong>
                  <br/>
                  {intl.get('bargain.one_click.v5.list_4_text')}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.supportCon}>
          <div className={styles.support}>
            <div className={styles.serviceCon}>
              <img className={styles.service} src={service} />
            </div>
            <div className={styles.title}>
              {intl.get('bargain.one_click.v5.miss')}
              <strong>{' '}{name}</strong>
            </div>
            <div className={styles.declareList}>
              <div className={styles.list}>
                <div className={styles.listOne}>
                  {intl.get('bargain.one_click.v5.service_text_1')}
                </div>
              </div>
              <div className={styles.list}>
                <div className={styles.listOne}>
                  {intl.get('bargain.one_click.v5.service_text_2')}
                </div>
              </div>
              <div className={styles.list}>
                <div className={styles.listOne}>
                  {intl.get('bargain.one_click.v5.service_text_3')}
                </div>
              </div>
              <div className={styles.footer}>
                <div className={styles.text}>
                  {intl.get('bargain.one_click.v5.service_text_4')}
                  <br />
                  <a className={styles.email} href={href}>
                    {email}
                  </a>
                </div>
                <div className={styles.qrCode}>
                  <DsQRCode text={text} width="70" name="qrcode" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SwitchV5;
