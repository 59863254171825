import intl from 'react-intl-universal';
import { passwordReg } from 'utils/constants';

export const formItemLayout = {
  layout: 'inline',
  labelCol: {
    xs: { span: 24 },
    sm: { span: 12 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

export const businessType = [
  {
    value: 'Bloggers',
    label: 'Bloggers'
  },
  {
    value: 'Store Experts',
    label: 'Store Experts'
  },
  {
    value: 'Youtube KOLs',
    label: 'Youtube KOLs'
  },
  {
    value: 'Website Owners',
    label: 'Website Owners'
  },
  {
    value: 'Agency',
    label: 'Agency'
  },
  {
    value: 'Other',
    label: 'Other'
  }
];

export function defaultSelected(data) {
  let partnerStore = window.localStorage.getItem('partner-store');
  if (partnerStore) {
    partnerStore = { ...JSON.parse(partnerStore), ...data };
    localStorage.setItem('partner-store', JSON.stringify(partnerStore));
  } else {
    localStorage.setItem('partner-store', JSON.stringify(data));
  }
}

export const checkPassword = (rule, value, callback, confirmValue) => {
  try {
    if (!value) {
      callback(intl.get('partner.reset_pass_word.new_pwd_value_no_tips'));
    } else if (confirmValue && value !== confirmValue) {
      callback(intl.get('partner.password_not_match_twice'));
    } else if (value.length < 8 || value.length > 16) {
      callback(intl.get('partner.password_error_long'));
    } else if (!passwordReg.test(value)) {
      callback(intl.get('partner.password_error_format'));
    } else {
      callback();
    }
  } catch (err) {
    callback(err);
  }
};
