import React, { useEffect, useState, useRef } from 'react';
import { Modal, Checkbox, Button } from 'antd';
import intl from 'react-intl-universal';

import styles from './PaypalPastDue.less';

const getDuration = day => 24 * 60 * 60 * 1000 * day;

function PaypalPastDue({ dispatch }) {
  const reminder = localStorage.getItem('paypalReminderhide') || false;
  const lastShowTime = localStorage.getItem('paypalLastShow');
  let timeGap = -1;
  if (lastShowTime) {
    const currentTime = Date.now();
    timeGap = currentTime - lastShowTime;
  }

  const [expired, setExpired] = useState(false);
  const [visible, setVisible] = useState(true);
  const timingRef = useRef(null);
  const checkbox = useRef(false);

  useEffect(() => {
    if (timeGap == -1) {
      setVisible(true);
    } else if (timeGap < getDuration(1)) {
      setVisible(true);
    }
  }, []);

  function getTracking() {
    dispatch({
      type: 'dropshippersetting/getTracking',
      payload: {
        callback: d => {
          if (d.code === 2000) {
            const storeList = d.data.store_list;
            storeList?.forEach(item => {
              if (item.paypal_expired) {
                setExpired(true);
              }
            });
          }
        }
      }
    });
  }

  useEffect(() => {
    getTracking();
  }, []);

  function cancel() {
    setVisible(false);
    localStorage.setItem('paypalLastShow', Date.now());
  }
  function paypalRenew() {
    localStorage.setItem('paypalReminderhide', true);
    setVisible(false);
    localStorage.setItem('paypalLastShow', Date.now());
    window.location.href = '/app/settings?id=8';
  }
  function paypalNoReminder(e) {
    const { checked } = e.target;
    if (checked) {
      clearInterval(timingRef.current);
      localStorage.setItem('paypalReminderhide', true);
    } else {
      localStorage.removeItem('paypalReminderhide');
    }
  }

  return (
    <>
      <Modal
        visible={visible && !reminder && expired}
        onCancel={() => {
          setVisible(false);
          localStorage.setItem('paypalLastShow', Date.now());
        }}
        height={211}
        title={intl.get('paypal.paypal_account')}
        footer={
          <Button
          type="primary"
          onClick={paypalRenew}
          className={styles.goBtn}
        >
          {intl.get('paypal.renew')}
        </Button>}
        className={styles.PaypalPastDue}
      >
        <div className={styles.alertWindow}>
          <p className={styles.dec}>{intl.get('paypal.paypal_reminder_content')}</p>
          <div className={styles.reminder}>
            <Checkbox
              ref={checkbox}
              className={styles.checkbox}
              onChange={e => {
                paypalNoReminder(e);
              }}
              defaultChecked={false}
            >
              {intl.get('paypal.no_reminder')}
            </Checkbox>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PaypalPastDue;
