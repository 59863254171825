export default {
  public: {
    cancel: 'Abbrechen',
    yes: 'Ja',
    no: 'Nein',
    ok: 'Ok',
    save: 'Speichern',
    submit: 'Abschicken',
    search: 'Suche',
    confirm: 'Bestätigen ',
    newconfirm: 'CONFIRM',
    apply: 'Anwenden',
    log_out: 'Abmelden',
    reset_password: 'Passwort zurücksetzen',
    delete: 'Löschen',
    got_it: 'Verstanden',
    check_more: 'Mehr anzeigen',
    more: 'Mehr',
    page: 'Seite',
    access: 'Zugang',
    refresh: 'Aktualisieren',
    reconnect: 'Wiederverbinden',
    on: 'Ein',
    off: 'Aus',
    here: 'hier',
    Manage: 'Abonnement verwalten',
    check_it: 'Überprüfen',
    dismiss: 'Verwerfen',
    contact: 'Kontakt',
    contact_us: 'Kontaktieren Sie uns',
    facebook: 'Facebook',
    youtube: 'YouTube',
    newsave: 'SAVE',
    updata_price:
      'Aktualisieren Sie die Preise der Produkte, die in Ihren Shopify-Shop übertragen werden, gemäß den Preisregeln, die Sie derzeit festlegen. Dieser Vorgang kann einige Zeit in Anspruch nehmen. Diese Funktion kann alle 30 Tage verwendet werden.',
    please_enter: 'Please enter BESTÄTIGEN to start the update',
    shopUnavailable:
      'This store is currently disconnected. Please reconnect it to use this feature',
    InterfaceTransfinite:
      'Update the price of the products pushed to your Shopify store according to the pricing rules you currently set. The process may take some time. This can be used every 30 days. Due to interface restrictions, the feature will be opened after 24:00, please wait patiently',
    synchronous: "The store's product prices are being updated",
    useAgain: 'Can be used again in',
    day: 'days',
    pause:
      'Synchronization of the day complete,\n    The remaining products will be automatically synced tomorrow',
    goodsTooMuch:
      'Update the price of the products pushed to your Shopify store according to the pricing rules you currently set. The process may take some time. This can be used every 30 days. Since your store has more than 100,000 items, we will synchronize all your items in multiple days (up to 100,000 per day), please be patient.',
    exceeds:
      'As your product synchronization exceeds 100,000, this function will be enabled after 24:00, please wait patiently',
    noproduct: 'The store has no product that can be updated',
    updateTitle: 'Produktpreis aktualisieren',
    updateText:
      'Aktualisieren Sie die Preise der Produkte, die in Ihren Shopify-Shop übertragen werden, gemäß den Preisregeln, die Sie derzeit festlegen. Dieser Vorgang kann einige Zeit in Anspruch nehmen. Diese Funktion kann alle 30 Tage verwendet werden.',
    start: 'Update starten',
    unsave: 'Unsaved changes',
    checkmodaltext:
      'The information you modified has not been saved yet. If you want to save, please click "SAVE", if you don\'t want to save your modifications, click "DISCARD"',
    discard: 'DISCARD',
    choose: 'SELECT',
    gotodsers: 'Go to DSers Homepage',
    oberlo_data_pending: 'Data correction.',
    oberlo_data_success: 'Data correction completed.',
    oberlo_data_fail: 'Data verification Failed.',
    oberlo_data_try: 'NOCH EINMAL VERSUCHEN',
    priceruleDis:
      'Please activate and confirm your <a href="/app/settings?=5" > Pricing Templates </a> settings in Pricing Rule and start to update afterwards.',
    padding_title:
      'Wir korrigieren die Informationen zu Bestellungen und Produkten, bitte warten Sie',
    migrate_order_tip:
      'Die Synchronisierung des Status einiger Bestellungen ist fehlgeschlagen. Es ist möglich, dass sich das AliExpress-Konto, über das diese Bestellungen aufgegeben wurden, von dem derzeit verknüpften AliExpress-Konto unterscheidet. Bitte ändern Sie das mit DSers verknüpfte AliExpress-Konto, um den Status der Bestellungen zu aktualisieren. Wenn Sie Fragen haben, wenden Sie sich bitte an das DSers Support-Team.',
    oberlo_product_error_tip:
      'Produktdatenüberprüfung abgeschlossen, aber { num } AliExpress-Produkte waren nicht verfügbar und können nicht in DSers importiert werden. Sie müssen die neuen Lieferanten manuell zuordnen. Gehen Sie zur Registerkarte "Nicht zugeordnete Produkte", um Folgendes anzuzeigen.',
    add_report_user_tip:
      'Data is being created, the report will be generated within 24 hours. Thank you for your patience.',
    filter_confirm_des: 'BESTÄTIGEN',
    filter_clear_des: 'ALLE FILTER LÖSCHEN',
    unsaveErr: 'Unable to save changes',
    gocheck: 'Go check',
    checkmodalerrtext1: 'The reason you cannot save is ',
    checkmodalerrtext2:
      'To fix the issue, you need to re-map the part facing issues, or delete the mapping with problems.',
    checkmodaladvanceerrtext:
      'In Advanced Mapping, the Ship to selection cannot be repeated on the same variant, otherwise the mapping cannot be saved. ',
    checkmodalbasicrepeaterrtext:
      'In Basic Mapping, Options cannot be repeated, otherwise the mapping cannot be saved. ',
    checkmodalbasicnullerrtext:
      'In Basic mapping, you need to select the corresponding variant, otherwise the mapping cannot be saved. ',
    input_guide_text:
      'The Search or Import field, along with the Filter feature has been moved! You can now find them on the top right corner.',
    input_guide_btn: 'Got it',
    importlist_card_guide_text:
      "We have improved the product card and some features can now be found in 'More Action' in the lower right corner",
    product_card_guide_text:
      "We have improved the product card and the mapping function now has a floating display. Some features can now be found in 'More Action' in the lower right corner",
    search_input_url_error_match_ae_tip:
      'Die von Ihnen eingegebene URL ist keine AliExpress-URL, bitte überprüfen Sie sie und geben Sie sie erneut ein.',
    search_input_url_error_match_tm_tip:
      'Die von Ihnen eingegebene URL ist keine Tmall-URL, bitte überprüfen Sie sie und geben Sie sie erneut ein.',
      search_input_url_invalid_tip:'Die von Ihnen eingegebene URL ist ungültig. Bitte überprüfen Sie sie und versuchen Sie es erneut.',
  }
};
