
    export default 
    {
    "help_center": {
        "help_center": {
            "fixed_header": "Centro de ayuda",
            "latest_news": "Últimas noticias",
            "load_more": "Cargar más",
            "online_chat": "Chat en línea en Messenger",
            "online_time": "Horario en línea: 7:00AM-00:00AM(UTC+8)",
            "work_days": "Días laborables: 7 días",
            "message_us": "Envíanos un mensaje",
            "email_report": "Correo electrónico: Informar de un problema",
            "email_report_desc": "Tu correo electrónico será respondido en <span style=\"font-weight: 700\">1 día laborable</span>. Si no recibes nuestra respuesta, asegúrate de revisar la bandeja de correo no deseado y de que dsers.com no esté en tu lista gris de correo electrónico.",
            "product_sourcing": "Correo electrónico: Búsqueda de productos",
            "product_sourcing_desc": "Tu correo electrónico será respondido en <span style=\"font-weight: 700\">1 día laborable</span>. Por favor, indícanos el nombre de tu cuenta Dsers, la URL de la tienda, el link del producto de AliExpress como referencia, método de envío preferido y volumen de ventas estimado en los últimos 7 días.",
            "blog_in_en": "Blog en inglés",
            "everything_about_ds": "Todo sobre Dsers",
            "blog_in_fr": "Blog en Francés",
            "coming_soon": "Próximamente...",
            "blog_in_pt": "Blog en Portugués",
            "youtube_channel": "Canal de YouTube",
            "check_ds_youtube": "Consultar el canal de YouTube de Dsers"
        },
        "entrance": {
            "get_support": "Obtener soporte técnico",
            "online_chat": "Chat en línea"
        },
        "question_modal": {
            "title": "¿Cómo podemos ayudarte?",
            "tell_tip": "Cuéntanos qué tipo de problema estás presentando",
            "tell_select_placeholer": "Seleccionar de la lista",
            "our_suggestions": "Estas son nuestras sugerencias",
            "useful_tip": "Estos son algunos consejos que podrían ser útiles para ti:",
            "suggesttions_default_tip": "Te daremos sugerencias de <br /> acuerdo al problema seleccionado. Si no te resulta útil,<br /> puedes obtener ayuda en línea haciendo <br />clic en el botón de abajo.",
            "get_online_help": "Obtener ayuda en línea"
        },
        "detail": {
            "by_ds_team": "por el equipo de Dsers",
            "ds_users": "Hola queridos usuarios",
            "so_much": "Muchas gracias por trabajar con Dsers.<br /> Esperamos que tengáis un buen día.",
            "to_contact": "Si tenéis alguna pregunta al respecto,<br /> no dudéis en poneros en contacto con nosotros.",
            "happy_with_ds": "Feliz (No hay sugerencias) con Dsers.",
            "best_regards": "Saludos cordiales,<br /> equipo de Dsers"
        },
        "support_here": "Obtén soporte en línea aquí"
    }
}