import React, { PureComponent } from 'react';
import { Spin, message } from 'antd';
import { connect } from 'dva';
import qs from 'qs';

import {Get , Post } from 'utils/request';

import { webCustomCollect,CommonDataCollect } from 'utils/utils';


@connect(({ auth, loading ,global}) => ({
  auth,
  loading: loading.models.auth,
  isoberlouser:auth?.migrationInfo?.migrating_shopify_domain,
  user_nick: global?.AEInfo?.user_nick,
}))
export default class ProductDetail extends PureComponent {
  componentDidMount() {
    this.getAuthCheck();
  }

  getAuthCheck = () => {
    const parms = qs.parse(location.search.slice(1));
    this.props.dispatch({
      type: 'auth/aecallback',
      payload: {
        data: {
          ...parms
        },
        cancelMessage: true,
        callback:async d => {
          // const res = await Get(
          //   '/mgnoberlo-core-api/dsers-api/migration/store/status',
          //   [],
          //   true
          // );
          // const response = await Get('/mgnoberlo-core-api/dsers-api/migration/user/status',{}, true)
          // if(res?.store_stages?.length){
          //   webCustomCollect({
          //     action:"ae_callback_status",
          //     custom_info:[
          //       {
          //         name:'migrating_shopify_domain',
          //         value:response?.migrating_shopify_domain
          //       },{
          //         name:'callback_code',
          //         value:d.code
          //       }
          //     ]
          //   })
          // }
          if (d.code === 2000) {
            window.dtag.set({
              event:'bind_ae',
              module:'ae',
              action:'bind_success',
              action_description:'',
              custom_data:{}
            })
            // if(response?.migrating_shopify_domain){
            //   await Post('/mgnoberlo-core-api/dsers-api/migration/action/bind-aliexpress',{
            //     data:{
            //       user_nick:this.props.user_nick,
            //       shopify_domain:response?.migrating_shopify_domain
            //     }
            //   },true)
            //   window.location.href = '/app/';
            // }
            if(window.TMALL_USER || window.CAINIAO_USER){
              const res = await Post('/auth/ali/flag?status=0',{})
              if(res.code === 2000){
                window.location.href = '/app/'
              }
              return
            }
            window.location.href = '/app/';
          } else {
            message.error(d.msg);
          }
        }
      }
    });
  };

  render() {
    return (
      <div
        style={{
          marginTop: 20,
          textAlign: 'center'
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
}
