export default {
  banner: {
    title: 'AliExpress Official Dropshipping Partner APP',
    quick_orders:
      'DSers is the best tool to process all your orders  within minutes without a limit (100+)',
    get_started_placeholder: 'Enter email address',
    get_started: 'Get started',
    order_management: 'Order management',
    order_management_description: `DSers is capable to deal with Bulk & Multiple orders.
                                  Process over 100 of orders per day within a couple minutes.
                                  All orders are synchronized from Shopify simultaneously.
                                  The dropshipper can then place his order on AliExpress with just ONE button.
                                  Save hours of your time!`,
    supplier_management: 'Supplier Management',
    supplier_management_description: `Set multiple suppliers for selected items.
                                      The substitute supplier will ONLY be activated in case the product is unavailable at your default supplier (ie out of stock).
                                      Millions of products means hundreds of suppliers and an endless stock on AliExpress.
                                      Never be sold out! (A new feature coming soon will allow you to automatically find the cheapest supplier!).`,
    csv_bulk_orders: 'CSV Bulk Orders',
    csv_bulk_orders_description: `Enjoy DSers and place multiple orders at once no matter the selling platform you are using.
                                  Create and upload your product's database,
                                  import your awaiting orders to DSers and place them all at once! As simple as that.
                                  Save more time than ever with DSers.`,
    save_97_ordering_time: 'Save 97% ordering time',
    how_does_work: {
      title: 'How does DSers work for dropshipper?',
      easy: 'Easy to start. Easy to master.',
      multiple_store: 'Multiple store',
      multiple_store_dec: 'Manage multiple shopify stores',
      supplier_management: 'Supplier management',
      supplier_management_dec: 'One product with multiple suppliers',
      multiple_orders: 'Multiple orders',
      multiple_orders_dec: 'Process multiple orders in one click',
      auto_sync: 'Auto sync',
      auto_sync_dec: 'Order status synced from AliExpress'
    },
    feedback: {
      title: 'Trusted by People like you',
      feedback: 'feedback from our core users',
      like: 'Like',
      comment: 'Comment',
      share: 'Share',
      comments: 'Comments',
      tomas: 'Tomas',
      tomas_time: 'Yesterday at 4:19 AM',
      tomas_dec:
        'We are using DSers as tool for order on Aliexpress. It\'s very powerful. Thanks a lot for that.',
      simon: 'Simon',
      simon_time: '2nd Nov 2018 at 11:09 AM',
      simon_dec:
        'DSers is a great app! I am very excited to use it as it will save me a lot of time and money. It\'s a very cool experience to fulfill hundreds of orders just in a couple of minutes.',
      wilson: 'Wilson',
      wilson_time: 'Yesterday at 9:22 PM',
      wilson_dec:
        'This app is litterly a dream !! It\'s really AMAZING when I saw that bulk orders are placed successfully within a minute ! It helps with saving labor work.',
      jonathan: 'Jonathan',
      jonathan_time: 'at 5:48 PM',
      jonathan_dec:
        'I am really glad that I found your website amazing work ! Order fulfillment became so much easier since I started using DSers.'
    },
    features: {
      title: 'DSers provides all features for dropshipping business',
      dec: 'Key features on DSers',
      multiple_order_processing: 'Multiple order processing',
      multiple_order_processing_dec: 'Multiple order processing',
      variants_mapping: 'Variants mapping',
      variants_mapping_dec:
        'Easily add variants to products by adding suppliers',
      shipment_setting: 'Shipment setting',
      shipment_setting_dec: 'Set shipping method by countries and fees',
      aliExpress_whitelist: 'AliExpress whitelist',
      aliExpress_whitelist_dec: 'No more order limitation',
      multiple_stores_manager: 'Multiple stores manager',
      multiple_stores_manager_dec: 'Manage multiple stores in one account',
      supplier_management: 'Supplier management',
      supplier_management_dec: 'Add multiple suppliers to one product',
      product_importer: 'Product importer',
      product_importer_dec: 'Import products with the Chrome extension',
      auto_messaging: 'Auto-Messaging',
      auto_messaging_dec: 'Create automatic messages for AliExpress sellers',
      email_template: 'Email template',
      email_template_dec: 'Shipping notifying email coming soon',
      tracking: 'Tracking',
      tracking_dec: 'Tracking shipping information accurately',
      price_monitor: 'Price monitor',
      price_monitor_dec: 'Be notified of prices changes on AliExpress',
      inventory_monitor: 'Inventory monitor',
      inventory_monitor_dec: 'Sync product inventory from AliExpress'
    },
    easy_started: 'Completely free, <br /> easy to get started',
    partnership: 'Partnership',
    advabced:'Advanced',
    content:'<span class={title}>20</span> <span class={title}>%</span> <span class={title}>OFF</span>',
    will:"If you change to Advanced plan now, you will get: <br/>",
    support:"Support of <span class={title}>5</span> additional stores, <br/> <span class={title}>10,000+</span> products limit, <br/> new marketing strategies ",
    today:'ONLY FOR TODAY!',
    btn1:'CHANGE NOW',
    btn2:'Let me think about it',
    yes:'CHANGE NOW',
    no:'I want to keep my Standard plan  >>',
    btn3:'Support of 5 additional stores | 10,000+ products limit | new marketing strategies',
    ioss: {
      contact_us: 'CONTACT US',
      title:
        'Contact your supply account manager to learn more information about IOSS',
      sub_title: '-----  Relevant notice about the EU tax reform',
      text:
        'The European Union will start implementing new VAT tax regulations from July, 1st, 2021.<br/>Dropshipping business can be run normally and the end customers won’t need to pay any additional taxes. <br/>If you have concerns about IOSS, local tax issue, tax refund, please feel free to contact your supply account manager (<a href="https://www.dsers.com/blog/eu-vat-reform/" target="_blank" rel="noopener noreferrer" >click here to learn more</a>).',
      title2: 'New EU Tax (IOSS) introduction',
      text2: 'Effects on Dropshipping business in EU countries from 1st July 2021'
    },
    newUI: {
      title: 'Brand new UI, brand new experience',
      title2: 'DSers will launch a new UI in early September',
      learn: 'LEARN MORE',
      newYearTitle: "Chinese New Year Holidays Notice",
      newYearContent1: "Chinese New Year is coming. Please contact your suppliers to check their delivery delays and resting period!",
      newYearContent2: "DSers suppliers will rest from 21st Jan 2023 to 23rd Jan 2023",
    },
    bannerFor11_11:'Discover massive promotions on 11.11',
    bannerButton:'LEARN MORE',
    newFeatureUp:'New feature upgrade',
    newFeatureTitle:'[New feature]',
    newFeatureText:'Follow and track the logistics information of your order, you can use Tracking.',
    newSettingTitle:'[New setting]',
    newSettingText:'New mapping method: Standard Mapping',
    newSettingText1:'Replace Product: Without changing the Shopify product ID, replace all variants of your Shopify product',
    learnMore:'Learn more',
    gotIt:"Got it",
    appointmenttext:'You have set the time to start the migration on [ {time} ], you will not be able to operate your DSers account when the migration starts',
    appointmenttext1:'You have set the time to start the migration on [ {time} ], and we will help you migrate in 5 minutes. You will not be able to operate your DSers account during the migration, so you can take a break.',
    servererrtext:'<text>We will be performing a system maintenance on</text> March 14th from 6:00 <br /> to 7:00 AM GMT.<br />',
    servererrtext2:'<text>Thanks for your understanding.</text>',
    agency_cold_startup: {
      banner_title_1: 'New Feature Online!',
      banner_title_2: 'Agency Order Management',
      popup_desc_1: `Are you switching between different tools for agency order fulfilment?`,
      popup_desc_2: `Try this alternative solution from DSers to avoid switching between Shopify stores, Mass Fulfill accounts and CSV files.`,
      popup_desc_3: `Free your hands to focus on marketing in this Q4!`,
      learn_more_btn: 'Learn more',
      banner_desc: 'Try this alternative solution from DSers to avoid switching between Shopify stores, MassFulfill accounts and CSV files.',
      order_awaiting_empty_after_title: 'After',
      order_awaiting_empty_before_title: 'Before',
      order_awaiting_empty_before: 'Without this feature, you would need multiple tools for agency orders',
      order_awaiting_empty_after: 'With this feature, you only need DSers!',
      order_awaiting_empty_link: 'Click <a data="text-link" style="color: blue" href="https://www.dsers.com/blog/product-update-dropship-with-agencies/" target="_blank" >here</a> to learn more about how to process agency orders',
      what_is_agency: 'What Is an Agency?',
      what_is_erp: 'What Is an ERP?',
      how_does_dsers_agency_works: 'How Does DSers Simplify Your Workflow with Dropshipping Agencies?',
    },
    tmall_cold_startup: {
      popup_title: 'New Marketplace',
      pupup_title_2: 'Tmall is online! Dig in!',
      popup_desc_1: 'Thousands of best seller products have just been added to listing.',
      popup_desc_2: 'Why wait to import them to your store?',
      popup_btn: 'Browse products',
      top_banner_title: 'New marketplace',
      top_banner_title_2: 'Tmall is online! Dig in!',
      find_supplier_banner_title: 'Best Sellers for Q4 2022!',
      find_supplier_banner_subtitle: 'Trendy and profitable hot items are live!',
      find_supplier_banner_subtitle_2: 'Add them to your store!'
    }
  }
};
