import React from 'react';
import { Tooltip } from 'antd';
import intl from 'react-intl-universal';

import styles from './GuidanceButton.less';

function GuidanceButton() {
  function handleClick() {
    localStorage.setItem('guidanceAgain', 1);
  }

  return (
    <a
      onClick={handleClick}
      href="/app/guidance"
      target="_blank"
      rel="noopener noreferrer"
    >
      <Tooltip
        placement="left"
        title={intl.get('guidance.guidance_again')}
        overlayClassName={styles.Txt}
      >
        <span className={styles.guidanceBtn} />
      </Tooltip>
    </a>
  );
}

export default GuidanceButton;
