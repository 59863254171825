import React from 'react';
import { connect } from 'dva';

import {
  Popover,
  Select,
  Button,
  Icon,
  message,
  Radio,
  Modal,
  Switch,
  Collapse,
  Tooltip
} from 'antd';

import qs from 'querystring';
import Ellipsis from 'components/Ellipsis';
import intl from 'react-intl-universal';
import className from 'classnames';
import cloneDeep from 'lodash/cloneDeep';

import ImportAliExpressMapping from './Import.js';
import Status from './Status.js';
import {
  replaceUrlToHttps,
  webCustomCollect,
  sendNoviceGuidanceInfo,
  CommonDataCollect
} from 'utils/utils';

import {
  deleteBundle,
  deleteBogo,
  hasDeleteVariant,
  postMappingProductRef,
  getMappingAvaliableStatus
} from './util.js';
import {getAePid} from 'routes/StoreManage/util'

import reactGaEvent from '../../utils/GAEvent';

import noticeIcon from 'assets/mapping/notice.png'
import searchIcon from 'assets/newImportList/icon-search.png';

import styles from './index.less';
import './mapping.css';
import { GuildeAction, LinkStoreAction } from 'actions'
const { Option } = Select;
const RadioGroup = Radio.Group;
const { confirm } = Modal;
const { Panel } = Collapse;

@connect(({ productmy, loading, user,login }) => ({
  productmy,
  loading: loading.models.productmy,
  saveLoading: loading.effects['productmy/ext'],
  noviceGuidanceInfo:user?.noviceGuidanceInfo,
  userInfo: login.userInfo,
  plan: user?.plan
}))
export default class Advance extends React.PureComponent {
  cacheUrlFromDsers = {};

  constructor(props) {
    super(props);
    this.state = {
      advance: {},
      showImport: false,
      moreActionChecked: undefined,
      showSave: false,
      select:[],
      advancedMapping: false,
      deleteVariantIdMap:[],
      visibleDeletedAllVariant:false,
      isDeleteAll:false
    };
  }

  componentWillMount() {
    const { types } = this.props;
    this.setState({
      advancedMapping: getMappingAvaliableStatus(types).advanced_variant_mapping
    });
    this.props.dispatch({
      type: 'productmy/LimitTheInterface',
      payload: {
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            this.setState({
              advancedMapping: d.data.confirm_url?.advanced_variant_mapping
            });
          }
        }
      }
    });

    const {
      // types,
      originProductData,
      originProductData: {
        prod: {
          detail: { variants = [], id },
          ext: { advance, map_type } = {}
        }
      }
    } = this.props;

    const deleteVariants = hasDeleteVariant(originProductData,'advance');
    this.variants = [...(variants.filter(i => !deleteVariants.find(d => d.id === i.id))), ...deleteVariants];
    this.setRemovedVariant(originProductData);

    let mapType = map_type;
    if ([1, 4].includes(types) && this.props?.plan?.trial_7_days_mapping_advanced_status !== 1) {
      mapType = 1;
    }

    this.map_type = mapType;
    this.save_map_type = mapType;
    this.shopifyProductId = id;

    const useAdvance = this.setSupply(advance);

    this.skuHasIsFbd(useAdvance);

    this.getCountryList();

    this.setState({
      advance: useAdvance
    });
  }

  componentDidMount() {
    const {plan} = this.props
    if(plan?.trial_7_days_mapping_advanced_status == 1){
      this.setState({
        advancedMapping: true
      })
    }
    this.props.onRef(this);
  }

  save_map_type = 0;
  map_type = 0;
  variants = [];
  countryLists = []; // 国家列表
  maxSupply = 10; // 一个variant 最多可设置多少个供应商
  importVariantId = '';
  importVariantSupplyIndex = 0;
  importProductId = '';
  variantIndex = 0;
  hasSave = false;
  currentDeleteVariantId = '';
  sp = "";

  // 有没有被删除的variant
  setRemovedVariant = originProductData => {
    const { variant } = originProductData;

    if (!variant) {
      return;
    }

    const flag = this.variants.some(item => item.id == variant.variant_id);

    flag == false &&
      this.variants.push({
        id: variant.variant_id,
        title: variant.Title,
        is_del: true
      });
  };

  showImportSupply = (variantId, spIndex, platform_product_id, i, sp) => {
    if (this.state.advancedMapping) {
      this.importVariantId = variantId;
      this.importVariantSupplyIndex = spIndex;
      this.importProductId = platform_product_id;
      this.variantIndex = i;
      this.sp = sp;

      this.setState({
        showImport: true
      });
    } else {
      localStorage.setItem('passivity', 'true');
      //  跳转订阅 && 弹窗拦截
      CommonDataCollect({
        action: 'Advanced-M-top',
        event_type:'overlimit_pop'
      });
      // window.EVENT.emit(GuildeAction.TurnPlan_Pop, `Advanced-M&${Date.now()}`, true);
      //  self.location.href = '/app/pricing?select=1';
      const { plan } = this.props

      if([1,10, 4, 8,12,13,14,15,16,17].includes(plan?.type)){
        window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'AdvancedMapping')
      }
    }
  };

  hideImportSupply = () => {
    this.setState({
      showImport: false
    });
  };

  // 设置的供应商
  skuHasIsFbd = data => {
    const { fbdSupply } = this.props;

    if (!fbdSupply[this.shopifyProductId]) {
      return;
    }

    const sd = fbdSupply[this.shopifyProductId];

    Object.entries(data).forEach(item => {
      const variantId = item[0];
      const variant_supply = item[1].variant_supply;

      const sdList = sd[variantId];

      if (!sdList) {
        return;
      }

      variant_supply.forEach(vs => {
        const vsSp = vs.supply[0];
        let flag = false;

        sdList.forEach(FBD => {
          if (
            FBD.origin_product_id == vsSp.platform_product_id &&
            FBD.origin_sku == vsSp.sku
          ) {
            flag = true;
          }
        });

        if (flag) {
          vsSp.isFBD = true;
        } else {
          vsSp.isFBD = false;
        }
      });
    });
  };

  // 设置供应商
  setSupply = (advance = {}) => {
    console.log(this.variants,'this.variant==-------------');
    this.variants.forEach(item => {
      const sp = advance[item.id];
      if (sp) {
        this.hasSupply(sp.variant_supply, item);
      } else {
        advance[item.id] = { variant_supply: [] };
        this.hasSupply(advance[item.id].variant_supply, item);
      }
    });

    return advance;
  };

  // 要不要添加新的一个供应商
  hasSupply = (variant_supply, variant) => {
    if (variant_supply.length == 0) {
      this.addSupply(variant_supply, variant);
      return;
    }

    let flag = true; // 用不用添加新的supp
    let len = 0;

    variant_supply.forEach(item => {
      len += 1;
      if (item.countries.length == 0) {
        flag = false;
      }

      if (!item.supply[0].sku) {
        flag = false;
      }

      item.supply &&
        item.supply.forEach(sp => {
          sp.variant_title = variant.title || '';
        });
    });

    if (variant_supply.length === this.maxSupply) {
      flag = false;
    }

    if (!flag) {
      return;
    }
    if (len >= this.maxSupply) {
      return;
    }

    this.addSupply(variant_supply, variant);
  };

  // 添加一个供应商
  addSupply = (variant_supply, variant) => {
    variant_supply.push({
      countries: ['GLOBAL'],
      supply: [
        {
          platform_type: 2,
          platform_product_id: '',
          platform_sale_id: '',
          quantity: 1,
          sku: '',
          variant_id: variant.id,
          variant_title: variant.title,
          is_base: false,
          freights: [],
          sku_image: '',
          title: ''
        }
      ]
    });
  };

  // 获取国家列表
  getCountryList = () => {
    const countryList = localStorage.getItem('countryList');
    if (countryList) {
      this.countryLists = JSON.parse(countryList);
      this.countryLists.unshift({
        Name: 'Global',
        Code: 'GLOBAL'
      });
    }
  };

  // 删除一个供应商
  deleteSupply = (variantId, spIndex) => {
    const { advance } = this.state;

    advance[variantId].variant_supply.splice(spIndex, 1);

    const updataAdvance = this.setSupply(advance);

    this.setState({
      advance: { ...updataAdvance }
    });

    this.showSaveBtn();
  };

  // 改变供应商国家
  changeCountry = (variantId, spIndex, v, i) => {
    if (this.state.advancedMapping) {
      const { advance } = this.state;

      const { variant_supply } = advance[variantId];
      let hasSame = false;

      this.variantIndex = i;

      variant_supply.forEach((item, i) => {
        if (!hasSame) {
          hasSame = v.find(c => {
            if (i != spIndex) {
              return item.countries.includes(c) && item.supply[0].sku;
            }
          });
        }
      });

      if (hasSame) {
        message.error(intl.get('mapping.same_country_tip'));
        return;
      }
console.log(advance[variantId].variant_supply,v,'-0-0000000-0-');
      advance[variantId].variant_supply[spIndex].countries = v;

      const updataAdvance = this.setSupply(advance);

      this.setState({
        advance: { ...updataAdvance }
      });

      if (
        this.variantIndex == 0 &&
        advance[variantId].variant_supply[spIndex].sku
      ) {
        this.setFristToAll(advance[variantId]);
      }

      this.showSaveBtn();
    } else {
      localStorage.setItem('passivity', 'true');
      //  跳转订阅 && 弹窗拦截
      CommonDataCollect({
        action: 'Advanced-M-top',
        event_type:'overlimit_pop'
      });
      // window.EVENT.emit(GuildeAction.TurnPlan_Pop, `Advanced-M&${Date.now()}`, true);
      //  self.location.href = '/app/pricing?select=1';
      const { plan } = this.props

      if([1,10, 4, 8,12,13,14,15,16,17].includes(plan?.type)){
        window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'AdvancedMapping')
      }
    }
  };

  // 导入供应商
  importCallback = (data, refFromDsers, select) => {
    console.log(data, 'advance import');
    if (refFromDsers) {
      this.cacheUrlFromDsers[data.platform_product_id] = refFromDsers;
    }

    this.hideImportSupply();

    const { advance } = this.state;

    const sp =
      advance[this.importVariantId].variant_supply[
        this.importVariantSupplyIndex
      ].supply[0];
    Object.assign(sp, data);

    const updataAdvance = this.setSupply(advance);

    this.setState({
      advance: { ...updataAdvance },
      select,
    });

    if (this.variantIndex == 0 && sp.sku) {
      this.setFristToAll(advance[this.importVariantId]);
    }

    this.showSaveBtn();
  };

  changeStatus = (v, save_map_type, t) => {
    this.map_type = save_map_type;
    this.save_map_type = save_map_type;

    if (v) {
      this.props.changeMappingStatus(save_map_type);
      // this.handleSave();
      reactGaEvent({
        category: 'Mapping',
        action: 'open advanced'
      });
      return;
    }
    if (t == 1) {
      this.props.changeMappingStatus(save_map_type);
      // this.handleSave();
      reactGaEvent({
        category: 'Mapping',
        action: 'close advanced'
      });
    }
  };

  advanceExt = undefined
  advanceMappingErr = false
  
  // 点击保存
  handleSave = () => {
    const { advance } = this.state;
    
    const copyAdvance = cloneDeep(advance);
    let hasSame = false; // 同一个variant有没有选择相同国家
    
    const {
      originProductData,
      originProductData: { prod: { ext,store_id },storeId },
      noviceGuidanceInfo
    } = this.props;
    
    const copyExt = cloneDeep(ext);
    const { bundle, bogo } = copyExt;
    
    
    if (bogo) {
      deleteBogo(bogo, this.variants);
      if (Object.values(bogo).length == 0) {
        delete copyExt.bogo;
      }
    }
    
    if(noviceGuidanceInfo.mapped == 0){
      sendNoviceGuidanceInfo({mapped : 1},()=>{
        this.props.dispatch({ type:'user/getNoviceGuidance'})
      })
    }

    if (bundle) {
      deleteBundle(bundle, this.variants);
    }

    const shouldPostRef = {};

    this.variants.forEach(item => {
      const { variant_supply } = copyAdvance[item.id];

      for (let i = 0; i < variant_supply.length; i++) {
        if (
          variant_supply[i].countries.length == 0 ||
          !variant_supply[i].supply[0].sku
        ) {
          variant_supply.splice(i, 1);
          i -= 1;
        } else {
          const aeProductId = variant_supply[i].supply[0].platform_product_id;
          if (this.cacheUrlFromDsers[aeProductId]) {
            shouldPostRef[aeProductId] = this.cacheUrlFromDsers[aeProductId];
          }
        }
      }

      if (variant_supply.length == 0) {
        delete copyAdvance[item.id];
      }
    });

    this.variants.forEach(item => {
      const { variant_supply } = copyAdvance[item.id] || {};

      if (!variant_supply) {
        return;
      }

      let arr = [];
      variant_supply.forEach(item => {
        if (!hasSame) {
          hasSame = item.countries.find(v => arr.includes(v));
        }
        arr = arr.concat(item.countries);
      });
    });

    if (hasSame) {
      // message.error(intl.get('mapping.same_country_tip'));
      this.advanceMappingErr = true
      return;
    }

    copyExt.advance = copyAdvance;
    copyExt.map_type = this.save_map_type;
    // this.props.setLoading(true);

    this.hasSave = true;
    this.props.setHasSave(this.hasSave);

    for(let i in copyExt){
      if(i !== 'advance'){
        delete copyExt[i]
      }
    }

    this.advanceExt = copyExt

    // this.props.dispatch({
    //   type: 'productmy/ext',
    //   payload: {
    //     data: {
    //       product_id: originProductData.id,
    //       ext: copyExt,
    //       store_id,
    //       ref:localStorage.getItem('mapping_dot')
    //     },
    //     callback: d => {
    //       // this.props.setLoading(false);
    //       if (d.code == 2000 || d.code == 2010) {
    //         message.success(intl.get('mapping.succeed'));
    //         postMappingProductRef(shouldPostRef, 2, originProductData);
    //         webCustomCollect({
    //           action:"mapping_dot_1",
    //           custom_info:[
    //             {
    //               name:localStorage.getItem('mapping_dot'),
    //               value:'mappinged'
    //             }
    //           ]
    //         })
    //         this.props.hasSavedHandler('advance')
    //         if(window.location.pathname.substring(window.location.pathname.lastIndexOf("/")+1).match(/myproduct/)){
    //           window.IS_UPDATE_IMPORT_PRODUCT = true
    //           setTimeout(()=>{
    //             window.IS_UPDATE_IMPORT_PRODUCT = false
    //           },15*1000)
    //         }
    //       }
    //     }
    //   }
    // });
  };

  // 清除所有mapping
  clearAll = () => {
    const advance = {};

    this.variants.forEach(item => {
      const obj = {
        variant_supply: [
          {
            countries: ['GLOBAL'],
            supply: [
              {
                platform_type: 2,
                platform_product_id: '',
                platform_sale_id: '',
                quantity: 1,
                sku: '',
                variant_id: item.id,
                is_base: false,
                freights: [],
                sku_image: '',
                title: ''
              }
            ]
          }
        ]
      };

      advance[item.id] = obj;
    });

    this.setState({
      advance
    });

    message.success('success')

    this.showSaveBtn();
  };

  // 把第一个mapping数据复制给
  setFristToAll = sp => {
    const { advance, moreActionChecked } = this.state;

    if (!moreActionChecked) {
      return;
    }
    const sampleSp = cloneDeep(sp);
    const firstSp = JSON.parse(JSON.stringify(sp))

    this.variants.forEach((item, i) => {
      if (i == 0) {
        return;
      }
      let newSp = firstSp
      newSp.variant_supply?.forEach(it=>{
        it.supply[0].variant_title = item.title;
        it.supply[0].variant_id = item.id;
      })
      advance[item.id] = cloneDeep(newSp);
    });
    this.setState({
      advance: { ...advance }
    });
  };

  // Mapping第一个Variant之后，复制Ship to和相应供应商到所有Varaint
  mappingOneToAll = e => {
    const { advance } = this.state;
    const sp = advance[this.variants[0].id];

    // 交互打点
    CommonDataCollect({
      user_id: this.props.userInfo.user_id,
      sale_platform: 'shopify',
      supply_platform: 'aliexpress',
      trans_from: 'advance mapping click copy button',
      action: 'click_advance_copy_button',
      event_type: 'interactive'
    });

    this.setState(
      {
        moreActionChecked: e
      },
      () => {
        if (e) {
          this.setFristToAll(sp);
        }
      }
    );

    this.showSaveBtn();
  };

  // 删除快捷操作选中状态
  handleRadio = e => {
    const { moreActionChecked } = this.state;

    if (moreActionChecked == e) {
      this.setState({
        moreActionChecked: undefined
      });
    }
  };

  // more action 内容
  getComponent = moreActionChecked => {
    return (
      <div className={styles.getTool}>
        <a
          className={styles.clearSp}
          role="button"
          tab-index="0"
          onClick={e => this.clearAll()}
        >
          <Icon type="delete" />
          {intl.get('mapping.clear_all')}
        </a>
        <RadioGroup value={moreActionChecked} onChange={this.mappingOneToAll}>
          <Radio value="2" onClick={this.handleRadio}>
            {intl.getHTML('mapping.advanced_more_action_copy')}
          </Radio>
        </RadioGroup>
      </div>
    );
  };

  // 显示保存按钮
  showSaveBtn = () => {
    this.setState({
      showSave: true
    });
    this.props.showSaveBtn()
    this.props.changemodalHidden('advance')
  };

  // 是否应用了dsers供应商
  getIsFBD = () => {
    const {
      fbdSupply,
      originProductData: { prod: { detail: { id } } }
    } = this.props;

    let flag = false;

    if (fbdSupply[id]) {
      flag = true;
    }

    return flag;
  };

  onAdvanceChild = ref => {
    this.advances = ref;
  };

  switchTabCheck = flag => {
    this.advances.changeChecked(flag);
  };

  // 是否删除在shopify已经删除的variant
  deleteVariant = variantId => {
    this.currentDeleteVariantId = variantId;
    this.setState({
      visibleDeletedAllVariant:true,
      isDeleteAll:false
    })
  };

  // 删除在shopify已经删除的variant
  deleteVariantTrue = () => {
    const { advance } = this.state;

    this.variants = this.variants.filter(
      item => item.id != this.currentDeleteVariantId
    );
    delete advance[this.currentDeleteVariantId];

    this.setState({
      advance: { ...advance },
      deleteVariantIdMap:[...this.state.deleteVariantIdMap,this.currentDeleteVariantId],
      visibleDeletedAllVariant:false
    });
    this.showSaveBtn();
  };

  deleteAllDelVariant = (e)=>{
    
    const { advance } = this.state;
    const del = []
    this.variants.forEach(item => {
        if(item.is_del){
         del.push(item.id)
         delete advance[item.id]
        }
      }
    );

    this.variants = this.variants.filter(
      item => !del.includes(item.id)
    );

    this.setState({
      advance: { ...advance },
      deleteVariantIdMap:del,
      visibleDeletedAllVariant:false
    });
    this.showSaveBtn();
  }

  checkDeleteVariants = (v)=>{
    this.props.checkVariantStatusHandler(v)
  }

  getCollapseStatus = ()=>{
    if(this.props.checkVariantStatus === true){
      return ['1']
    }else if(this.props.checkVariantStatus === false){
      return []
    }else if(this.variants.filter(it => it.is_del).length > 4){
      return []
    }else{
      return ['1']
    }
  }

  openSupplyOptimizer=(prod,type)=>{
    if(type){
      return
    }
    if(!prod?.prod?.suppliers?.length && prod?.prod?.supplier_type === 2){
      window.dtag.set({
        event:'click',
        module:'Aliexpress',
        action:'Aliexpress_figure_search',
        custom_data:{
          'click_type': 'go_supplier_optimizer_mapping'
        }
      })
      const paramObj = {
        url: prod.image,
        ref: 'my_product',
        id: prod.ae_pid,
        store_id: localStorage.getItem('storeId')
      };
      const paramStr = qs.stringify(paramObj);
      const link = `/app/supplier_optimizer?${paramStr}`;
      window.open(link);
      return
    }
    if((!prod?.prod?.suppliers?.length && window.TMALL_USER) || (prod?.prod?.suppliers?.includes(1) && window.TMALL_USER)){
      window.dtag.set({
        event:'click',
        module:'Aliexpress',
        action:'Aliexpress_figure_search',
        custom_data:{
          'click_type': 'go_supplier_optimizer_mapping'
        }
      })
      const paramObjTmall ={
        url:  prod.image,
        ref: 'import_list',
        supplierType:"unmap",
        store_id: localStorage.getItem('storeId')
      }
      const paramStrTmall = qs.stringify(paramObjTmall)
      window.open(`/app/supplier_optimizer?${paramStrTmall}`)
      return
    }
    if(prod?.prod?.suppliers?.includes(4)){
      window.dtag.set({
        event:'click',
        module:'tmall',
        action:'tmall_figure_search',
        custom_data:{
          'click_type': 'go_supplier_optimizer_mapping'
        }
      })
      const paramObjTmall ={
        url:`https://www.dsers.com/app/tmallproduct/${getAePid(prod.prod)}?appId=159831084`,
        ref: 'import_list',
        supplierType:"tmall",
        store_id: localStorage.getItem('storeId')
      }
      const paramStrTmall = qs.stringify(paramObjTmall)
      window.open(`/app/supplier_optimizer?${paramStrTmall}`)
      return
    }
      window.dtag.set({
        event:'click',
        module:'Aliexpress',
        action:'Aliexpress_figure_search',
        custom_data:{
          'click_type': 'go_supplier_optimizer_mapping'
        }
      })
      
    const paramObj = {
      url: prod.image,
      ref: 'my_product',
      id: prod.ae_pid,
      store_id: localStorage.getItem('storeId')
    };
    const paramStr = qs.stringify(paramObj);
    const link = `/app/supplier_optimizer?${paramStr}`;
    window.open(link);
  }

  render() {
    const {
      originProductData = {},
      saveLoading,
      copyLink,
      hideSupplier,
      publicBtnDispath,
      switchoverMapping
    } = this.props;

    const {
      advance,
      showImport,
      moreActionChecked,
      showSave,
      advancedMapping
    } = this.state;
    // 从订单页打开mapping 禁用删除variant按钮
    const { deleteVariantButtonDisabled, orderVaraintId } = originProductData;

    const shopifyIsFBD = this.getIsFBD(); // 这个shopify商品有没有dsers供应商
    const useSupplierStyle = !originProductData.image.includes('https:') ?'not-allowed' :'pointer'
    console.log(this.sp,originProductData, 'spspsp-=-=-=')
    return (
      <>
        <div className={styles.mappingSatusLine} id="advanceSwitchStatus">
          <Status
            Mapping={advancedMapping}
            self_type={2}
            map_type={this.map_type}
            changeStatus={this.changeStatus}
            isFBD={shopifyIsFBD}
            onRef={this.onAdvanceChild}
            btnDispath={publicBtnDispath}
            switchoverMapping={switchoverMapping}
            switchoverMappingId="advanceSwitchStatus"
          />
        </div>

        <div className={styles.advance}>
          <div className={styles.productLine}>
            <div className={styles.product}>
              <h3 className="h3_line">{intl.get('mapping.ShopifyProduct')}</h3>
              <div className="topIc">
                <Popover
                  placement="left"
                  trigger="hover"
                  overlayClassName={styles.zoomProductImg}
                  content={
                    <img src={replaceUrlToHttps(originProductData.image)} />
                  }
                >
                  {
                  originProductData.prod?.ext?.map_type === 6 ? (
                    <img src={replaceUrlToHttps(originProductData.image)} />
                  ) : (
                    <span className={styles.shopifyPic}>
                      <img src={replaceUrlToHttps(originProductData.image)} />
                      <Tooltip title={intl.get('mapping.Findsimilar')}>
                        <span className={styles.findSupplier} style={{cursor:useSupplierStyle}} onClick={()=>this.openSupplyOptimizer(originProductData,!originProductData.image.includes('https:'))}>
                          <i class="material-icons" style={{cursor:useSupplierStyle}}>search</i>
                        </span>
                      </Tooltip>
                    </span>
                  )}
                </Popover>
                <a
                  role="button"
                  tab-index="0"
                  className={styles.productLinkC}
                  onClick={e => copyLink(originProductData)}
                >
                  <Ellipsis lines={4}>{originProductData.title}</Ellipsis>
                </a>
              </div>
            </div>
            <div className={styles.advTool}>
              <h3 className="h3_line">
                {intl.get('mapping.supplier_product')}
                <span
                  className="del_wQW"
                  onClick={() => {
                    this.clearAll();
                  }}
                >
                  <i class="material-icons">delete</i>
                </span>
              </h3>
              <div className="topb">
                <p className="topIcK">{intl.get('mapping.advanced_rule')}</p>
                <p className="topjw">
                  {intl.getHTML('mapping.advanced_more_action_copy')}
                </p>
                <div className="switch">
                  <Switch
                    onChange={checked => {
                      this.mappingOneToAll(checked);
                      this.handleRadio(checked);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={className(styles.variantLine, {
              [styles.variantLineTitle]: true
            })}
          >
            <p className={styles.variantName} style={{ height: 48 }}>
              {intl.get('mapping.variant')}
            </p>
            <div className={styles.supplyBox}>
              <div className={styles.supply}>
                <span
                  className={styles.shipTo}
                  style={{ height: 48, display: 'flex', alignItems: 'center' }}
                >
                  {intl.get('mapping.ship_to')}
                </span>
                <span className={styles.supplierVariant} style={{ height: 48 }}>
                  {intl.get('mapping.supplier_variant')}
                </span>
              </div>
            </div>
          </div>
          <div
            className={styles.variantWrap}
            style={{ height: 'calc( 100vh - 350px )' }}
          >
            <div>
              {this.variants
                .filter(it => !it.is_del)
                .map((variant, i) => {
                  const isOrderVaraint = variant.id === orderVaraintId;
                  return (
                    <div
                      className={className(styles.variantLine, {
                        [styles.removeVariantLine]: variant.is_del
                      })}
                      key={variant.id}
                    >
                      <p
                        className={className(styles.variantName, {
                          [styles.orderVariant]: isOrderVaraint
                        })}
                      >
                        {variant.title}
                      </p>
                      <div className={styles.supplyBox}>
                        {advance[variant.id].variant_supply.map(
                          (item, spIndex, variant_supply) => {
                            const isFBD = item.supply[0].isFBD;
                            return (
                              <div
                                className={styles.supply}
                                key={`${variant.id}${spIndex}`}
                              >
                                <div className={styles.shipTo}>
                                  <Select
                                    size="small"
                                    mode="multiple"
                                    showArrow={true}
                                    filterOption={(input, option) =>
                                      option.props.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    dropdownMatchSelectWidth={true}
                                    value={item.countries}
                                    suffixIcon={
                                      <img
                                        className="clasSelImgC"
                                        src={require('../../assets/newImportList/icon_expand_more-24px.png')}
                                      ></img>
                                    }
                                    optionFilterProp="children"
                                    getPopupContainer={triggerNode =>
                                      triggerNode.parentNode
                                    }
                                    onChange={v =>
                                      this.changeCountry(
                                        variant.id,
                                        spIndex,
                                        v,
                                        i
                                      )
                                    }
                                  >
                                    {this.countryLists.map(item => {
                                      return (
                                        <Option
                                          key={item.Code}
                                          value={item.Code}
                                        >
                                          {item.Name}
                                        </Option>
                                      );
                                    })}
                                  </Select>
                                </div>
                                <div
                                  className={className(styles.supplierVariant, {
                                    [styles.fbdVariant]: isFBD
                                  })}
                                >
                                  {item.supply.map(sp => {
                                    if (sp.sku_image) {
                                      return (
                                        <div className="tag_vbox">
                                          <div
                                            onClick={() => {
                                              this.showImportSupply(
                                                variant.id,
                                                spIndex,
                                                sp.platform_product_id,
                                                i,
                                                sp
                                              );
                                              const custom_info = [
                                                {
                                                  name: 'mapping type',
                                                  value: 'Advanced'
                                                }
                                              ];

                                              window.DSERS_CUSTOM_COLLECT({
                                                module: 'product mapping',
                                                action: 'click supplier button',
                                                custom_info
                                              });
                                            }}
                                          >
                                            <Popover
                                              placement="left"
                                              trigger="hover"
                                              content={
                                                <img
                                                  src={replaceUrlToHttps(
                                                    sp.sku_image
                                                  )}
                                                />
                                              }
                                              overlayClassName={
                                                styles.zoomProductImg
                                              }
                                            >
                                              <img
                                                src={replaceUrlToHttps(
                                                  sp.sku_image
                                                )}
                                                className={styles.skuImg}
                                              />
                                            </Popover>
                                            <span className="yexy_text">
                                              {sp.sku_desc}
                                            </span>
                                          </div>
                                          <span
                                            className="consw_icon"
                                            onClick={() => {
                                              this.deleteSupply(
                                                variant.id,
                                                spIndex
                                              );
                                            }}
                                          >
                                            <i class="material-icons">close</i>
                                          </span>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <Button
                                          disabled={isFBD}
                                          size="small"
                                          key={sp.variant_id}
                                          onClick={() => {
                                            this.showImportSupply(
                                              variant.id,
                                              spIndex,
                                              sp.platform_product_id,
                                              i
                                            );
                                          }}
                                        >
                                          {replaceUrlToHttps(sp.sku_image) ? (
                                            <Popover
                                              placement="left"
                                              trigger="hover"
                                              content={
                                                <img
                                                  src={replaceUrlToHttps(
                                                    sp.sku_image
                                                  )}
                                                />
                                              }
                                              overlayClassName={
                                                styles.zoomProductImg
                                              }
                                            >
                                              <img
                                                src={replaceUrlToHttps(
                                                  sp.sku_image
                                                )}
                                                className={styles.skuImg}
                                              />
                                            </Popover>
                                          ) : null}

                                          <span className="btnAdd">
                                            <i class="material-icons">add</i>
                                          </span>
                                          {intl.get(
                                            'mapping.import_sp_select_var'
                                          )}
                                        </Button>
                                      );
                                    }
                                  })}
                                </div>
                                {isFBD ? (
                                  <a
                                    className={styles.fbd}
                                    href="/app/fulfillment-center"
                                  >
                                    FBD
                                  </a>
                                ) : null}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
            {this.variants.filter(it => it.is_del).length ? (
              <div
                className={styles.deletedVariantCon}
                style={{ width: '100%' }}
              >
                <Collapse
                  activeKey={this.getCollapseStatus()}
                  onChange={this.checkDeleteVariants}
                  bordered={false}
                  expandIconPosition={'left'}
                >
                  <Panel
                    header={
                      <div className={styles.description}>
                        <span>Variants deleted at Shopify</span>
                        <Tooltip
                          title={intl.getHTML(
                            'mapping.delete_variant_collapse_supplier_tip'
                          )}
                        >
                          <img src={noticeIcon} alt="" />
                        </Tooltip>
                      </div>
                    }
                    key="1"
                    extra={
                      <i
                        class="material-icons"
                        onClick={e => {
                          e.stopPropagation();
                          this.setState({
                            isDeleteAll: true,
                            visibleDeletedAllVariant: true
                          });
                        }}
                      >
                        delete
                      </i>
                    }
                  >
                    {this.variants
                      .filter(it => it.is_del)
                      .map((variant, i) => {
                        const isOrderVaraint = variant.id === orderVaraintId;
                        return (
                          <div
                            className={className(styles.variantLine, {
                              [styles.removeVariantLine]: variant.is_del
                            })}
                            key={variant.id}
                          >
                            <p
                              className={className(styles.variantName, {
                                [styles.orderVariant]: isOrderVaraint
                              })}
                            >
                              {variant.title}
                              {variant.is_del && (
                                <div className={styles.variantDeleteIcon}>
                                  Deleted
                                </div>
                              )}
                              {variant.is_del &&
                              !deleteVariantButtonDisabled ? (
                                <i
                                  // style={{position: 'absolute', right: '5px', top: '5px'}}
                                  class="material-icons"
                                  onClick={() => this.deleteVariant(variant.id)}
                                >
                                  delete
                                </i>
                              ) : null}
                            </p>
                            <div className={styles.supplyBox}>
                              {advance[variant.id].variant_supply.map(
                                (item, spIndex, variant_supply) => {
                                  const isFBD = item.supply[0].isFBD;
                                  return (
                                    <div
                                      className={styles.supply}
                                      key={`${variant.id}${spIndex}`}
                                    >
                                      <div className={styles.shipTo}>
                                        <Select
                                          size="small"
                                          mode="multiple"
                                          showArrow={true}
                                          filterOption={(input, option) =>
                                            option.props.children
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          }
                                          dropdownMatchSelectWidth={true}
                                          value={item.countries}
                                          suffixIcon={
                                            <img
                                              className="clasSelImgC"
                                              src={require('../../assets/newImportList/icon_expand_more-24px.png')}
                                            ></img>
                                          }
                                          optionFilterProp="children"
                                          getPopupContainer={triggerNode =>
                                            triggerNode.parentNode
                                          }
                                          onChange={v =>
                                            this.changeCountry(
                                              variant.id,
                                              spIndex,
                                              v,
                                              i
                                            )
                                          }
                                        >
                                          {this.countryLists.map(item => {
                                            return (
                                              <Option
                                                key={item.Code}
                                                value={item.Code}
                                              >
                                                {item.Name}
                                              </Option>
                                            );
                                          })}
                                        </Select>
                                      </div>
                                      <div
                                        className={className(
                                          styles.supplierVariant,
                                          {
                                            [styles.fbdVariant]: isFBD
                                          }
                                        )}
                                      >
                                        {item.supply.map(sp => {
                                          if (sp.sku_image) {
                                            return (
                                              <div className="tag_vbox">
                                                <div
                                                  onClick={() => {
                                                    this.showImportSupply(
                                                      variant.id,
                                                      spIndex,
                                                      sp.platform_product_id,
                                                      i,
                                                      sp
                                                    );
                                                    const custom_info = [
                                                      {
                                                        name: 'mapping type',
                                                        value: 'Advanced'
                                                      }
                                                    ];

                                                    window.DSERS_CUSTOM_COLLECT(
                                                      {
                                                        module:
                                                          'product mapping',
                                                        action:
                                                          'click supplier button',
                                                        custom_info
                                                      }
                                                    );
                                                  }}
                                                >
                                                  <Popover
                                                    placement="left"
                                                    trigger="hover"
                                                    content={
                                                      <img
                                                        src={replaceUrlToHttps(
                                                          sp.sku_image
                                                        )}
                                                      />
                                                    }
                                                    overlayClassName={
                                                      styles.zoomProductImg
                                                    }
                                                  >
                                                    <img
                                                      src={replaceUrlToHttps(
                                                        sp.sku_image
                                                      )}
                                                      className={styles.skuImg}
                                                    />
                                                  </Popover>
                                                  <span className="yexy_text">
                                                    {sp.sku_desc}
                                                  </span>
                                                </div>
                                                <span
                                                  className="consw_icon"
                                                  onClick={() => {
                                                    this.deleteSupply(
                                                      variant.id,
                                                      spIndex
                                                    );
                                                  }}
                                                >
                                                  <i class="material-icons">
                                                    close
                                                  </i>
                                                </span>
                                              </div>
                                            );
                                          } else {
                                            return (
                                              <Button
                                                disabled={isFBD}
                                                size="small"
                                                key={sp.variant_id}
                                                onClick={() => {
                                                  this.showImportSupply(
                                                    variant.id,
                                                    spIndex,
                                                    sp.platform_product_id,
                                                    i
                                                  );
                                                }}
                                              >
                                                {replaceUrlToHttps(
                                                  sp.sku_image
                                                ) ? (
                                                  <Popover
                                                    placement="left"
                                                    trigger="hover"
                                                    content={
                                                      <img
                                                        src={replaceUrlToHttps(
                                                          sp.sku_image
                                                        )}
                                                      />
                                                    }
                                                    overlayClassName={
                                                      styles.zoomProductImg
                                                    }
                                                  >
                                                    <img
                                                      src={replaceUrlToHttps(
                                                        sp.sku_image
                                                      )}
                                                      className={styles.skuImg}
                                                    />
                                                  </Popover>
                                                ) : null}

                                                <span className="btnAdd">
                                                  <i class="material-icons">
                                                    add
                                                  </i>
                                                </span>
                                                {intl.get(
                                                  'mapping.import_sp_select_var'
                                                )}
                                              </Button>
                                            );
                                          }
                                        })}
                                      </div>
                                      {isFBD ? (
                                        <a
                                          className={styles.fbd}
                                          href="/app/fulfillment-center"
                                        >
                                          FBD
                                        </a>
                                      ) : null}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </Panel>
                </Collapse>
              </div>
            ) : null}
          </div>
          {showImport && (
            <ImportAliExpressMapping
              visible={showImport}
              hideImportSupply={this.hideImportSupply}
              importCallback={this.importCallback}
              importProductId={this.importProductId}
              shopifyProductId={this.shopifyProductId}
              storeId={originProductData.prod.store_id}
              selectData={this.state.select || []}
              sp={this.sp}
            />
          )}
        </div>
        <Modal
          visible={this.state.visibleDeletedAllVariant}
          width={560}
          title={intl.getHTML('mapping.confirm_delete_all_variant_title')}
          className={styles.deleteTipModal}
          onCancel={() => {
            this.setState({
              visibleDeletedAllVariant: false
            });
          }}
          footer={
            <>
              <Button
                className={styles.btn}
                onClick={
                  this.state.isDeleteAll
                    ? this.deleteAllDelVariant
                    : this.deleteVariantTrue
                }
              >
                {intl.getHTML('public.newconfirm')}
              </Button>
            </>
          }
        >
          <div>{intl.getHTML('mapping.confirm_delete_all_variant')}</div>
        </Modal>
      </>
    );
  }
}
