
    export default 
    {
    "help_center": {
        "help_center": {
            "fixed_header": "Hilfe-Center",
            "latest_news": "Letzte Nachrichten",
            "load_more": "Mehr laden",
            "online_chat": "Online-Chat über Messenger",
            "online_time": "Online-Zeit: 7:00-00:00 Uhr (UTC+8)",
            "work_days": "Arbeitstage: 7 Tage",
            "message_us": "Nachricht an uns",
            "email_report": "E-Mail: Ein Problem melden",
            "email_report_desc": "Ihre E-Mail wird innerhalb von <span style=\"font-weight: 700\">1 Arbeitstag</span> beantwortet. Wenn Sie keine Antwort von uns erhalten haben, überprüfen Sie bitte Ihre Junk-Mails und stellen Sie sicher, dass dsers.com nicht in Ihrem E-Mail-Greylisting enthalten ist.",
            "product_sourcing": "E-Mail: Produktbeschaffung",
            "product_sourcing_desc": "Ihre E-Mail wird innerhalb von <span style=\"font-weight: 700\">1 Arbeitstag</span>  beantwortet. Bitte teilen Sie uns Ihren DSers-Kontonamen, Ihre Shop-URL mit, AliExpress Produktlink als Referenz, bevorzugte Versandart, geschätztes Verkaufsvolumen der letzten 7 Tage.",
            "blog_in_en": "Blog auf Englisch",
            "everything_about_ds": "Alles über DSers",
            "blog_in_fr": "Blog in Französisch",
            "coming_soon": "Demnächst verfügbar...",
            "blog_in_pt": "Blog auf Portugiesisch",
            "youtube_channel": "Youtube-Kanal",
            "check_ds_youtube": "DSers Youtube-Kanal überprüfen"
        },
        "entrance": {
            "get_support": "Unterstützung erhalten",
            "online_chat": "Online-Chat"
        },
        "question_modal": {
            "title": "Wie können wir Ihnen helfen?",
            "tell_tip": "Bitte teilen Sie uns mit, welche Art von Problem Sie haben",
            "tell_select_placeholer": "Wählen Sie aus der Liste",
            "our_suggestions": "Hier sind unsere Vorschläge",
            "useful_tip": "Hier finden Sie einige Tipps, die für Sie nützlich sein könnten:",
            "suggesttions_default_tip": "Wir werden Ihnen Vorschläge zu <br />dem von Ihnen ausgewählten Problem unterbreiten. Wenn Ihnen das nicht weiterhilft, <br /> können Sie immer noch Online-Hilfe erhalten, < br />indem Sie auf die Schaltfläche unten klicken.",
            "get_online_help": "Online-Hilfe erhalten"
        },
        "detail": {
            "by_ds_team": "vom DSers-Team",
            "ds_users": "Hallo liebe Nutzer",
            "so_much": "Vielen Dank, dass Sie mit DSers arbeiten. <br /> Wir hoffen, dass Sie einen schönen Tag haben.",
            "to_contact": "Wenn Sie Fragen dazu haben, <br /> können Sie sich gerne an",
            "happy_with_ds": "Viel Spaß beim Dropshipping mit DSers.",
            "best_regards": "Mit freundlichen Grüßen, <br /> DSers Team"
        },
        "support_here": "Hier erhalten Sie Online-Support"
    }
}