
    export default 
    {
    "shop_push": {
        "title": "Kontakt, um 10%-30% Rabatt auf Produkte zu erhalten!",
        "dsers_price": "DSers Preis:",
        "current_price": "Aktueller Preis:",
        "profit_margin": "Gewinnspanne:",
        "supplier": "Lieferant:",
        "dsers_program": "DSers Massenlieferantenprogramm",
        "link_copied": "Link kopiert!",
        "copy_link": "Produktlink auf Karte kopieren",
        "contact": "Kontakt aufnehmen, um Rabatt zu erhalten",
        "what_dec": "Was ist das DSers Massenlieferantenprogramm?",
        "thank_you": "Vielen Dank für Ihre Anfrage",
        "fill_email": "Bitte geben Sie Ihre E-Mail-Adresse an, um die Anfrage zu vervollständigen.",
        "fill_email_placeholder": "Bitte füllen Sie Ihre E-Mail aus",
        "send_request": "Anfrage abschicken",
        "reach_you": "DSers und das AliExpress Global Supply Team werden sich bald mit Ihnen in Verbindung setzen. Einen schönen Tag noch!"
    }
}