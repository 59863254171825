import defaultImg from '../../assets/default-image.jpg';

export default function getProductShowData(data, storeId) {
  const alisMap = {};
  const list = [];
  const { alis = [] } = data;

  const prods = data.prods || [];
  const notify = data.notify || {};
  const sellInfo = data.sellInfo || {}

  alis.forEach(item => {
    alisMap[item.ProductID] = item;
  });

  prods.forEach(item => {
    if (item.detail === undefined) {
      return;
    }
    const ae_pid = getAePid(item);
    const ae = getAeCost(alis,item,ae_pid) || {};
    // const ae = alisMap[ae_pid] || {};
    const { Ext = {}, Simp } = ae;
    const stockAndCost = getStockAndCost(Simp, item.platform_type, Ext);
    const newStockAndCost = newGetMyproductStockAndCost(item.id,sellInfo)
    if (item.ext === undefined) {
      item.ext = {};
    }

    const obj = {
      id: item.id,
      ae_pid,
      is_bin: item.is_bin,
      is_black_list: item.ext.is_black_list || 1,
      is_del: item.is_del,
      is_pub: true, // item.is_pub || ( item.detail.id ? false : undefined ),
      platform_type: item.platform_type,
      title: item.detail.title,
      image: (item.detail.image && item.detail.image.src) || defaultImg,
      feedback: Ext.feedback,
      order_count: Ext.order_count,
      star: Ext.star,
      stock_count: newStockAndCost.stock,
      cost: newStockAndCost.cost,
      minCost: newStockAndCost.minCost,
      maxCost: newStockAndCost.maxCost,
      price: getPrice(item.detail.variants),
      prod: item,
      notify: notify[item.id],
      storeId,
      isReward: ae.IsReward,
      isTendays: ae.IsTenDays,
      isProductPool: ae.IsProPool,
      isDSers: ae.IsDsers,
      supplier_type:item?.supplier_type,
      ship_to:item.ship_to,
      suppliers:[...new Set(item?.suppliers || [])].sort()
    };

    list.push(obj);
  });

  return list;
}

export function getImportProductShowData(data) {
  console.log(data,'');
  const alisMap = {};
  const list = [];
  const { alis = [] } = data;

  const prods = data.prods || [];
  const notify = data.notify || {};
  const sellInfo = data.sellInfo || {}

  alis &&
    alis.length &&
    alis.forEach(item => {
      alisMap[item.ProductID] = item;
    });

  prods.forEach(item => {
    if (item.detail === undefined) {
      return;
    }

    const ae = alisMap[item.ae_product_id] || {};
    const { Ext = {}, Simp } = ae;
    const stockAndCost = getImportStockAndCost(Simp, Ext);
    const newStockAndCost = newGetImportStockAndCost(item.id, sellInfo);
    if (item.ext === undefined) {
      item.ext = {};
    }

    const obj = {
      id: item.id,
      ae_pid:item.supplier_type === 4 ? item.supplier_id : item.ae_product_id,
      unique_id: item.unique_id,
      // is_bin: item.is_bin,
      // is_del: item.is_del,
      is_pub: item.stores.length > 0, // stores有数据就是推送过的
      platform_type: 1,
      title: item.detail.title,
      image: (item.detail.image && item.detail.image.src) || defaultImg,
      feedback: Ext.feedback,
      order_count: Ext.order_count,
      star: Ext.star,
      stock_count: newStockAndCost.stock,
      cost: newStockAndCost.cost,
      price: getPrice(item.detail.variants),
      tag: item.tags || [],
      storesCheck: item.stores,
      spread: item.spread,
      prod: item,
      notify: notify[item.id],
      price_rule: item.price_rule,
      isReward: ae.IsReward,
      isTendays: ae.IsTenDays,
      isProductPool: ae.IsProPool,
      isDSers: ae.IsDsers,
      is_main: item.is_main,
      supplier_type: item.supplier_type === 4 ? 4 : 2
    };

    list.push(obj);
  });

  return list;
}

export function getImportStockAndCost(data, Ext) {
  const { skus: { skuPriceList = [] } = {} } = Ext;
  const c = [];
  let n = 0;
  let extHasPrice = false;
  let extHasStock = false;
  
  if (skuPriceList.length) {
    skuPriceList.forEach(item => {
      const { skuVal: { actSkuCalPrice, availQuantity } = {} } = item;
      if (actSkuCalPrice) {
        if (actSkuCalPrice.indexOf(',') !== -1) {
          const newStr = actSkuCalPrice.split(',').join('');
          extHasPrice = true;
          c.push(Number(newStr));
        } else {
          extHasPrice = true;
          c.push(Number(actSkuCalPrice));
        }
      }
      if (availQuantity) {
        extHasStock = true;
        n += availQuantity;
      }
    });
  }
  
  if (!data || !data.aeop_ae_product_s_k_us?.aeop_ae_product_sku) {
    return {
      stock: 0,
      cost: '--'
    };
  }
  
  if(data?.product_status_type === 'offline' || !data.aeop_ae_product_s_k_us?.aeop_ae_product_sku || !data?.product_status_type === '' || data?.product_status_type === 'editingRequired'){
    return{
      cost: '--'
    }
  }
  
  const {
    aeop_ae_product_s_k_us: { aeop_ae_product_sku = [] },
    item_offer_site_sale_price
  } = data;
  
  aeop_ae_product_sku.forEach(item => {
    const { s_k_u_available_stock, sku_stock, offer_sale_price, sku_price, id } = item;
    
    if (s_k_u_available_stock && !extHasStock) {
      n += s_k_u_available_stock
    }
    if (!s_k_u_available_stock && sku_stock && !extHasStock) {
      n += 999;
    }
    
    if (id === '<none>' && item_offer_site_sale_price) {
      c.push(Number(item_offer_site_sale_price));
      return;
    }
    
    if (offer_sale_price === undefined) {
      if (item_offer_site_sale_price && !extHasPrice) {
        c.push(Number(item_offer_site_sale_price));
        return;
      }
      if (sku_price && !extHasPrice) {
        c.push(Number(sku_price));
        return;
      }
    }
    
    if (!extHasPrice) {
      c.push(Number(offer_sale_price));
    }
  });
  
  const max = Math.max(...c);
  const min = Math.min(...c);
  
  return {
    stock: n,
    cost: max == min ? max : `${min}~${max}`
  };
}

function newGetMyproductStockAndCost(id,data){
  if(!data[id]){
    return {
      stock: 0,
      cost: `--`
    };
  }
  const max = data[id]?.cost_max;
  const min = data[id]?.cost_min;
  if(!max && !min && !data[id]?.stock){
    return {
      stock: 0,
      cost: `--`
    };
  }
  return {
    stock: data[id]?.stock,
    minCost:min,
    maxCost:max,
    cost: max == min ? max : `${min}~${max}`
  };
}

function newGetImportStockAndCost(id,data){
  if(!data[id]){
    return {
      stock: 0,
      cost: `--`
    };
  }
  const max = data[id]?.cost_max;
  const min = data[id]?.cost_min;
  if(!max && !min && !data[id]?.stock){
    return {
      stock: 0,
      cost: `--`
    };
  }
  return {
    stock: data[id]?.stock,
    cost: max == min ? max : `${min}~${max}`
  };
}

function getAeCost(data,item,ae_pid){
  let aa
  data?.forEach(ii=>{
    if(item?.ship_to == ii?.ShipTo && ii?.ProductID == ae_pid){
      aa = ii
    }
  })
  return aa
}

export function getAePid(item) {
  if (!item.ext) return undefined;
  
  const { ext: { basic = {}, standard = {} }, platform_type } = item;
  
  if (platform_type != 1) {
    return undefined;
  }
  
  let id = '';
  if(item?.ext?.map_type == 5){
    Object.values(standard).forEach(b => {
      const { is_default, variant_supply = [] } = b;
        id = variant_supply[0].supply[0].platform_product_id;
    });
  }else{
    Object.values(basic).forEach(b => {
      const { is_default, supply = [] } = b;
      if (supply.length == 0) {
        id = undefined;
        return;
      }
      if (is_default) {
        id = supply[0].platform_product_id;
      }
    });
  }
  
  return id;
}

// 获取库存 & 获取去ae下单的成本 实际是商品在ae上的折扣价
export function getStockAndCost(data, type, Ext) {
  if (type == 2) {
    return {
      stock: 0,
      cost: '--'
    };
  }
  
  const { skus: { skuPriceList = [] } = {} } = Ext || {};
  const c = [];
  let n = 0;
  let extHasPrice = false;
  let extHasStock = false;
  
  if (skuPriceList.length) {
    skuPriceList.forEach(item => {
      const { skuVal: { actSkuCalPrice, availQuantity } = {} } = item;
      if (actSkuCalPrice) {
        extHasPrice = true;
        c.push(Number(actSkuCalPrice));
      }
      if (availQuantity) {
        extHasStock = true;
        n += availQuantity;
      }
    });
  }

  if (!data || !data.aeop_ae_product_s_k_us?.aeop_ae_product_sku || data?.product_status_type !== 'onSelling') {
    return {
      stock: 0,
      cost: '--'
    };
  }
  const {
    aeop_ae_product_s_k_us: { aeop_ae_product_sku = [] },
    item_offer_site_sale_price
  } = data;
  // console.log(aeop_ae_product_sku, '------');
  aeop_ae_product_sku.forEach(item => {
    const { s_k_u_available_stock, sku_stock, offer_sale_price, sku_price, id } = item;

    if (s_k_u_available_stock && !extHasStock) {
      n += s_k_u_available_stock;
    }
    if (!s_k_u_available_stock && sku_stock && !extHasStock) {
      n += 999;
    }
    // console.log(id, '-------------------');

    if (id === '<none>' && item_offer_site_sale_price) {
      c.push(Number(item_offer_site_sale_price));
      return;
    }

    if (offer_sale_price === undefined) {
      if (item_offer_site_sale_price && !extHasPrice) {
        c.push(Number(item_offer_site_sale_price));
        return;
      }
      if (sku_price && !extHasPrice) {
        c.push(Number(sku_price));
        return;
      }
    }

    if (!extHasPrice) {
      c.push(Number(offer_sale_price));
    }
  });

  const max = Math.max(...c);
  const min = Math.min(...c);
  
  return {
    stock: n,
    cost: max == min ? max : `${min}~${max}`,
    minCost: min,
    maxCost: max
  };
}

// 获取price  商品在shopify的卖价
function getPrice(variants = []) {
  const n = [];

  if (variants.length == 0) {
    return 0;
  }

  variants.forEach(v => {
    n.push(Number(v.price));
  });

  const max = Math.max(...n);
  const min = Math.min(...n);

  return max == min ? max : `${min}~${max}`;
}

/**
 * 获取shopify店铺的domain 不包括 https:// 协议前缀
 * @param {Object} store 一个店铺对象
 */
export function getShopifyStoreDomain( store ){
  return `${store.shop_name}.myshopify.com`;
}
