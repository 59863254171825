import React from 'react';
import intl from 'react-intl-universal';

import styles from './SupplierADBanner.less';
import useTopBanner from '../../hooks/useTopBanner';
import bannerBg1 from '../../assets/banner/supplier/supplier_ad_1.png';
import bannerBg2 from '../../assets/banner/supplier/supplier_ad_2.svg';

const allowPath = [
  '/find_supplier',
  '/order/list',
  '/storemanage/import',
  '/storemanage/myproduct'
];

const SupplierADBanner = ({ path, lang }) => {
  const [visible] = useTopBanner(path, allowPath);

  if (!visible) {
    return null;
  }

  // find supplier页面需要白色背景
  const isWhiteBg = path.includes('/find_supplier');

  return (
    <div className={`${styles.container} ${isWhiteBg ? styles.whiteBg : ''}`}>
      <a
        href="https://www.blog.dserspro.com/post/dsers-mass-supplier-program"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={styles.banner}>
          <div className={styles.content}>
            <div className={styles.title}>
              {lang !== 'fr-FR'
                ? 'DSers Mass Supply Program Is Now Alive, Helping You Find Suppliers!'
                : 'Le programme DSers Mass Supply est là pour vous aider à trouver des fournisseurs!'}
            </div>
            <div className={styles.subTitle}>
              {lang !== 'fr-FR' ? 'POWERED BY DSERS' : 'PROPOSÉ PAR DSERS'}
            </div>
            <span className={styles.button}>
              {lang !== 'fr-FR' ? 'Learn more' : 'En savoir plus'}
            </span>
          </div>
          <div className={styles.bannerBg}>
            <img src={bannerBg1} />
            <img width="131" src={bannerBg2} />
          </div>
        </div>
      </a>
    </div>
  );
};

SupplierADBanner.allowPath = allowPath;

export default SupplierADBanner;
