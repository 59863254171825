export default {
  top: {
    synchronizing_store: 'Synchronizing store...',
    top_select_store: 'All stores',
    select_store: 'select store',
    link_to_aliExpress: 'Link to AliExpress',
    bind_ali_tip: 'Click here to link to AliExpress', // m
    over_bind: 'AliExpress Account Whitelisted',
    over_bind_tip:
      'You have already bound your AliExpress account to DSers and upgraded to whitelisted account',
    apply_whitelist: 'Apply for whitelist',
    timeout_tip: 'Click to get AliExpress whitelisted account',
    link_shopify_tip: 'Click to link to multiple Shopify stores.',
    link_to_more_stores: 'Link to more stores',
    link_to_shopify_store: 'Link to Shopify store',
    bind_ae_title: "Re-authorize AliExpress",
    bind_ae_content:`The AliExpress whitelisted account you have linked to DSers is about to expire. Please re-authorize AliExpress account. If you have any questions, please  <a href='https://www.messenger.com/t/DSersOfficial' target="_blank" >contact us</a>`,
    bind_ae_expire:
      'The AliExpress whitelisted account you have binded to DSers is about to expire. Please bind your AliExpress account again.', //  add
    whitelist_expire:
      'The AliExpress whitelisted account you have binded to DSers is about to expire. Please bind your AliExpress account again.', //  add
    update_title: 'Authorization Updates', //  add
    update_desc:
      'DSers detected that your accounts below need to update authorization for DSers. Otherwise you will not be able to use DSers properly', //  add
    update_desc_is_white: `DSers detected that your accounts below need to apply AliExpress whitelist.
                          Please click the button <a href={href} target="_blank" >Apply for whitelist</a> on the top of the page to update authorization.
                          Otherwise, you will not be able to use DSers properly.`,
    update_btn: 'Update', //  add
    contact_by_email:
      'If you have any questions, please contact <span>support@dsers.com</span>',
    ae_account_linked: 'AliExpress account Linked',
    bind_ae_intro: 'Just one step away from managing your products and orders with DSers',
    bind_ae_outdated_intro: 'AliExpress account authorization expires every 3 months. Please click Link to AliExpress to renew the authorization.',
    bind_ae_btn_modal:'Link your AliExpress account',
    bind_ae_details:`Linking your AliExpress account to DSers allows you to place 100+orders in seconds! Click <a href="https://help.dsers.com/link-your-shopify-store/" target="_blank">here</a> for more details.`,
    bind_ae_foo_btn:`LINK ALIEXPRESS`,
    chrome_dowm_tit:'Install DSers Chrome Extension',
    chrome_dowm_detail:`One-click product import from AliExpress to DSers to save time and find new best sellers! Click <a target="_blank" href="https://help.dsers.com/import-product-from-aliexpress-for-shopify/#Use%20DSers%20Chrome%20Extension">here</a> for more details.`,
    chrome_foo_btn:'Download',
    bindaeTip:`AliExpress order status not updated`,
    bindaeTipDetail:`DSers cannot get the AliExpress order status because you have not linked your AliExpress account, please link your AliExpress account as soon as possible.`,
    binaeBtn:`LINK AIEXPRESS`,
    ChristmasTheme:'Tmall Theme',
    RegularTheme:'Regular Theme'
  }
};
