export default {
  paypal: {
    auto_sync_track_to_paypal:
      'Sincronização automática de números de rastreamento com o PayPal! Acesse "Configurar" - "Rastreamento" e confira.',
    paypal_authorization_title: 'PayPal authorization',
    paypal_authorization_content: 'The system has detected that your authorized PayPal account is not the same as the account bound to Shopify.<br/>This will cause the Tracking Number of your order to fail to sync to PayPal.Please change it<br/>This will cause the Tracking Number of your order to fail to sync to PayPal.Please change it<br/>If you already changed, please ignore the reminder, click below to no longer see this',
    paypal_change: 'Go to change',
    disimiss: 'Disimiss',
    paypal_account: 'Sobre a conta do PayPal',
    paypal_reminder_content: 'O sistema detectou que a autorização de uso de sua conta do PayPal associada à plataforma expirou. Para uma melhor experiência, autorize novamente o uso de sua conta do PayPal',
    no_reminder: `Não me lembre`,
    renew: 'Autorizar novamente',
    cancel: 'Cancel'
  }
};
