import React from 'react';
import { Divider } from 'antd';

import styles from './SessionOfMapping.less';

import Point from './Point';
import BotMessage from './BotMessage';
import UserMessage from './UserMessage';

const SimulateImportButton = React.forwardRef((props, ref) => (
  // eslint-disable-next-line
  <span ref={ref} role="button" tabIndex="-1" {...props}>
    &nbsp;
  </span>
));

SimulateImportButton.displayName = 'Point(forwardRef)';

function SessionOfMapping({
  status,
  onWatchVideo,
  onGoAhead,
  forwardedRef,
  onPointClick,
  onSimulateImport,
  simulateImportButtonRef,
  onMappingSavePointClick,
  onMultiMapping,
  onEnd
}) {
  return (
    <>
      <Point
        ref={forwardedRef}
        style={{
          position: 'fixed',
          display: 'none',
          zIndex: 3000,
          marginLeft: status === 'saveMapping' ? -30 : 0
        }}
        onClick={onPointClick}
        sequence={status === 'saveMapping' ? 1 : null}
        showPoint={
          !(
            status === 'writing' ||
            status === 'mappingImportFail' ||
            status === 'saveMapping'
          )
        }
      />
      {/* 模拟一个 import button */}
      {status === 'writing' ? (
        <SimulateImportButton
          onClick={onSimulateImport}
          className={styles.fakeImportBtn}
          ref={simulateImportButtonRef}
        />
      ) : null}
      {status === 'saveMapping' ? (
        <span
          id="simulate-save-btn"
          className={styles.saveBtn}
          role="button"
          tabIndex="-1"
          aria-label="button"
        >
          <Point
            sequence={2}
            style={{ bottom: 20, right: 8, position: 'absolute' }}
            onClick={onMappingSavePointClick}
          />
        </span>
      ) : null}
      {/* 左侧布局 */}
      <div className={styles.leftChat}>
        <div className={styles.chatCon}>
          {status === 'writing' ? (
            <>
              <BotMessage showAvatar>
                <p>
                  Now we will need the{' '}
                  <b>corresponding AliExpress product link</b>.
                </p>
                <div>
                  Go to AliExpress to copy the link, then{' '}
                  <b>
                    paste this link in the highlighted column and click Import
                  </b>
                </div>
              </BotMessage>
              <UserMessage showAvatar>
                <a
                  href="https://best.aliexpress.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                  data-category="tutorial"
                  data-action="find on AliExpress"
                >
                  OK. I will find it on AliExpress.
                </a>
              </UserMessage>
            </>
          ) : null}
          {status === 'importSuccess' ? (
            <BotMessage showAvatar>
              Great! Now please click <b>Set</b> to continue
            </BotMessage>
          ) : null}
          {status === 'mappingImportFail' ? (
            <>
              <BotMessage showAvatar>
                Oops
                <span role="img" aria-label="icon">
                  😱
                </span>
                , it seems that there is an unexpected issue happened and we
                failed to import the product...
              </BotMessage>
              <UserMessage showAvatar>
                <p>
                  <a
                    href="https://best.aliexpress.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-category="tutorial"
                    data-action="find another supplier on AliExpress"
                  >
                    I will find another supplier on AliExpress and try again.
                  </a>
                </p>
                <Divider style={{ margin: '12px 0' }} />
                <div>
                  <span
                    data-category="tutorial"
                    data-action="watching a related video"
                    onClick={() => onWatchVideo('NjCtvCSdS5M')}
                    role="button"
                    tabIndex="-1"
                  >
                    I prefer watching a related video
                  </span>
                </div>
                <Point
                  showPoint={false}
                  style={{ bottom: 20, right: '30%', position: 'absolute' }}
                />
              </UserMessage>
            </>
          ) : null}
          {status === 'saveMapping' ? (
            <>
              <BotMessage showAvatar>
                <p>
                  Now, it's time to select the variants of your AliExpress
                  supplier that correspond to the variants of your Shopify
                  product.
                </p>
                <p>
                  <b>Fill in all the boxes</b> and{' '}
                  <b>do not leave empty space</b>
                </p>
                <div>
                  Don't forget to <b>Save</b>
                </div>
              </BotMessage>
              <UserMessage showAvatar>
                <p>
                  <a
                    href="https://www.facebook.com/DSersOfficial"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={onEnd}
                    data-category="tutorial"
                    data-action="need support"
                  >
                    I need support
                  </a>
                </p>
                <Divider style={{ margin: '12px 0' }} />
                <div>
                  <span
                    data-category="tutorial"
                    data-action="watching a related video"
                    onClick={() => onWatchVideo('NjCtvCSdS5M')}
                    role="button"
                    tabIndex="-1"
                  >
                    I prefer watching a related video
                  </span>
                </div>
              </UserMessage>
            </>
          ) : null}
          {status === 'failMapping' ? (
            <>
              <BotMessage showAvatar>
                Oops{' '}
                <span role="img" aria-label="icon">
                  😱
                </span>
                , it seems that <i>the mapping is not correct</i> …
              </BotMessage>
              <UserMessage showAvatar>
                <p>
                  <a
                    href="https://www.facebook.com/DSersOfficial"
                    target="_blank"
                    rel="noopener noreferrer"
                    data-category="tutorial"
                    data-action="need support"
                    onClick={onEnd}
                  >
                    I need support
                  </a>
                </p>
                <Divider style={{ margin: '12px 0' }} />
                <div>
                  <span
                    data-category="tutorial"
                    data-action="watching a related video"
                    onClick={onWatchVideo}
                    role="button"
                    tabIndex="-1"
                  >
                    I prefer watching a related video
                  </span>
                </div>
                <Point
                  showPoint={false}
                  style={{ bottom: 20, right: '30%', position: 'absolute' }}
                />
              </UserMessage>
            </>
          ) : null}
        </div>
      </div>
      {/* 居中布局 */}
      <div
        className={styles.centerChat}
        style={{ top: status === 'confirmSet' ? 450 : 80 }}
      >
        {status === 'greeting' ? (
          <>
            <BotMessage showAvatar>
              <p>
                Great job{' '}
                <span role="img" aria-label="icon">
                  👍
                </span>
                ! We found <b>order(s)</b> in your account.
              </p>
              <div>
                Do you mind if we <b>use one order as an example</b> and guide
                you to
                <b>process it to your AliExpress supplier</b>?
              </div>
            </BotMessage>
            <UserMessage showAvatar>
              <p>
                <span
                  data-category="tutorial"
                  data-action="want to test"
                  onClick={onGoAhead}
                  role="button"
                  tabIndex="-1"
                >
                  Go ahead. I want to test
                </span>
              </p>
              <Divider style={{ margin: '12px 0' }} />
              <div>
                <span
                  data-category="tutorial"
                  data-action="watching a related video"
                  onClick={() => onWatchVideo('VNJTOHdISPk')}
                  role="button"
                  tabIndex="-1"
                >
                  I prefer watching a related video
                </span>
              </div>
              <Point
                showPoint={false}
                style={{ bottom: 20, right: '30%', position: 'absolute' }}
              />
            </UserMessage>
          </>
        ) : null}
        {status === 'awaitMapping' ? (
          <>
            <BotMessage showAvatar>
              <p>
                Nice
                <span role="img" aria-label="icon">
                  😊
                </span>
                ! Let’s start!
              </p>
              <div>
                First, you need to{' '}
                <b>
                  let DSers know to which AliExpress supplier your Shopify
                  product is related to
                </b>
                . We call it <b>Mapping</b>.
              </div>
            </BotMessage>
            <BotMessage>
              <p>
                You only need to do it <b>once per product</b>, or if you want
                to change supplier.
              </p>
              <div>
                Click the <b>Mapping</b> button to continue
              </div>
            </BotMessage>
          </>
        ) : null}
        {status === 'confirmSet' ? (
          <>
            <BotMessage showAvatar>
              <p>
                We are almost there! You need to choose this as the{' '}
                <b>default Ali supplier</b>, later, you can change it, or add a
                secondary one.
              </p>
              <div>
                Click <b>OK</b> to continue.
              </div>
            </BotMessage>
          </>
        ) : null}
        {status === 'multiMappingConfirm' ? (
          <>
            <BotMessage showAvatar>
              It seems that you still have other product not mapped to an
              AliExpress supplier in this order
            </BotMessage>
            <UserMessage showAvatar>
              <p>
                <span
                  data-category="tutorial"
                  data-action="continue mapping next"
                  onClick={onMultiMapping}
                  role="button"
                  tabIndex="-1"
                >
                  OK. I am on it
                </span>
              </p>
              <Divider style={{ margin: '12px 0' }} />
              <div>
                <span
                  data-category="tutorial"
                  data-action="watching a related video"
                  onClick={() => onWatchVideo('NneJLfXtalU')}
                  role="button"
                  tabIndex="-1"
                >
                  I prefer watching a related video
                </span>
              </div>
            </UserMessage>
          </>
        ) : null}
      </div>
    </>
  );
}

export default React.memo(SessionOfMapping);
