
    export default 
    {
    "pricing": {
        "title": "Buon rapporto qualità-prezzo",
        "desc": "Piano gratuito disponibile",
        "tipSelectPlan": "Seleziona un piano per continuare",
        "Basic": "Base",
        "BasicText": "(Gratuito per sempre)",
        "Advanced": "Avanzato",
        "AdvancedText": "giorni di prova gratuita",
        "No_card_AdvancedText": "Carta di credito non richiesta",
        "No_card_ProText": "Carta di credito non richiesta",
        "No_card_StandardText": "Carta di credito non richiesta",
        "Standard": "Standard",
        "StandardText": " giorni di prova gratuita",
        "Pro": "Pro",
        "ProText": " giorni di prova gratuita",
        "Business": "Azienza",
        "Enterprise": "Impresa",
        "Admonth": "<span class=\"mount mounttop\">$</span><span class=\"mountP\">19.9</span><span class=\"mount\">/mese</span>",
        "Prmonth": "<span class=\"mount mounttop\">$</span>49.9<span class=\"mount\">/mese</span>",
        "Stmonth": "<span class=\"mount mounttop\">$</span>1.99<span class=\"mount\">/mese</span>",
        "Enmonth": "<span class=\"mount mounttop\">$</span>499<span class=\"mount\">/mese</span>",
        "NewStmonth": "<span class=\"mount mounttop\">$</span>9.9<span class=\"mount\">/mese</span>",
        "Adannually": "<span class=\"mount mounttop\">$<s class=\"deleter\">19.9</s></span><span class=\"mountP\">15.9</span><span class=\"mount\">/mese</span>",
        "Prannually": "<span class=\"mount mounttop\">$<s class=\"deleter\">49.9</s></span>39.9<span class=\"mount\">/mese</span>",
        "Stannually": "<span class=\"mount mounttop\">$</span>1.99<span class=\"mount\">/mese</span>",
        "Enannually": "<span class=\"mount mounttop\">$<span class=\"startat\">A partire da</span><s class=\"deleterStart\">499</s></span>399<span class=\"mount\">/mese</span>",
        "NewStannually": "<span class=\"mount mounttop\">$<s class=\"deleter\">9.9</s></span>7.9<span class=\"mount\">/mese</span>",
        "AdannuallyText": "$15.9/mese <br /> Addebito di $190.8 all'anno <br/> Risparmierai $48/anno",
        "AdannuallyText_2": "$15.9/mese <br /> Addebito di $190.8 all'anno <br/> 2 mesi GRATUITI!",
        "AdannuallyText_3": "Addebito annuale di $190.8 <br/> 2 mesi GRATIS!",
        "PrannuallyText": "$39.9/mese <br /> Addebito di $478.8 all'anno <br /> Risparmierai $120/anno ",
        "PrannuallyText_2": "$39.9/mese <br /> Addebito di $478.8 all'anno <br /> 2 mesi GRATIS!",
        "PrannuallyText_3": "Addebito annuale di $478.8 <br /> 2 mesi GRATIS!",
        "StannuallyText": "Addebito annuale di $23.88",
        "EnannuallyText": "Addebito annuale di $4,788 <br /> 2 mesi GRATIS!",
        "NewStannuallyText": "Addebito annuale di $94.9 <br /> 2 mesi GRATIS!",
        "Free": "Gratis",
        "Monthlyfee": "Tariffa mensile",
        "Annualfee": "Tariffa annuale",
        "Feature": "Funzionalità",
        "enterprise_current_plan": "Il tuo abbonamento attuale: {plan}",
        "EnDescribe": "Contattaci per ricevere maggiori informazioni",
        "circulationData": [
            {
                "Feature": "Supporto clienti",
                "explain": "24*7 chat live e servizio e-mail",
                "Basic": "Disponibile",
                "BasicText": "",
                "Standard": "Available",
                "StandardText": "Disponibile",
                "AdvancedL": "<span class=\"blone and\">Disponibilità e priorità assoluta</span>",
                "AdvancedText": "",
                "Pro": "<span class=\"blone and\">Disponibilità e priorità assoluta</span>",
                "ProText": "",
                "Enterprise": "<span class=\"blone and\">Disponibile e priorità assoluta</span>",
                "EnterpriseText": "",
                "NewStandard": "Disponibile",
                "NewStandardText": ""
            },
            {
                "Feature": "Elabora diversi ordini in gruppo",
                "explain": "Elabora diversi ordini simultaneamente su AliExpress",
                "Basic": "Disponibile",
                "BasicText": "",
                "Standard": "Disponibile",
                "StandardText": "",
                "AdvancedL": "<span class=\"blone and\">Disponibile e ad alta velocità per elaborare 100+ ordini</span>",
                "AdvancedText": "",
                "Pro": "<span class=\"blone and\">Disponibile e ad alta velocità per elaborare 100+ ordini</span>",
                "ProText": "",
                "Enterprise": "<span class=\"blone and\">Disponibile e ad alta velocità per elaborare 100+ ordini</span>",
                "EnterpriseText": "",
                "NewStandard": "Disponibile",
                "NewStandardText": ""
            },
            {
                "Feature": "Limite negozio",
                "explain": "Gestisci diversi negozi in un account Dsers",
                "Basic": "<span class=\"blone\">3</span>",
                "BasicText": "",
                "Standard": "<span class=\"blone\">5</span>",
                "StandardText": "",
                "AdvancedL": "<span class=\"blone and\">10</span>",
                "AdvancedText": "(Apprezzato dai mercanti che vendono in diversi paesi)",
                "Pro": "<span class=\"blone and\">25</span>",
                "ProText": "(Apprezzato dai mercanti che vendono in diverse nicchie e paesi)",
                "Enterprise": "<span class=\"blone and\">50</span>",
                "EnterpriseText": "",
                "NewStandard": "<span class=\"blone\">3</span>",
                "NewStandardText": ""
            },
            {
                "Feature": "Limite prodotto",
                "explain": "",
                "Basic": "<span class=\"blone\">3000 </span> /account",
                "BasicText": "",
                "Standard": "<span class=\"blone\">7500</span>  /account",
                "StandardText": "",
                "AdvancedL": "<span class=\"blone and \">20000 </span> /account",
                "AdvancedText": "",
                "Pro": "<span class=\"blone and \">75000 </span> /account",
                "ProText": "",
                "Enterprise": "<span class=\"blone and \">100,000 </span> /account",
                "EnterpriseText": "",
                "NewStandard": "<span class=\"blone\">3000 </span> /account",
                "NewStandardText": ""
            },
            {
                "Feature": "Adattatore di spedizione AliExpress",
                "explain": "Trai vantaggio dal metodo di spedizione dal miglior rapporto qualità-prezzo su AliExpress",
                "Basic": "<span class='line2'>—</span>",
                "BasicText": "",
                "Standard": "<span class='line2'>—</span>",
                "StandardText": "",
                "AdvancedL": "<span class=\"blone and \">Fino a 10 prodotti</span>",
                "AdvancedText": "",
                "Pro": "<span class=\"blone and \">Fino a 20 prodotti</span>",
                "ProText": "",
                "Enterprise": "<span class=\"blone and \">Prodotti illimitati</span> ",
                "EnterpriseText": "",
                "NewStandard": "<span class='line2'>—</span>",
                "NewStandardText": ""
            },
            {
                "Feature": "Change online products price",
                "explain": "",
                "Basic": "<span style=\"color:#FF8F00;font-weight:600\">1time/30days</span>",
                "BasicText": "",
                "Standard": "<span style=\"color:#FF8F00;font-weight:600\">1time/30days</span>",
                "StandardText": "",
                "AdvancedL": "<span style=\"color:#FF8F00;font-weight:600\">4times/30days</span>",
                "AdvancedText": "",
                "Pro": "<span style=\"color:#FF8F00;font-weight:600\">(10volte/30giorni)</span>",
                "ProText": "",
                "Enterprise": "<span style=\"color:#FF8F00;font-weight:600\">(10volte/30giorni)</span>",
                "EnterpriseText": "",
                "NewStandard": "<span style=\"color:#FF8F00;font-weight:600\">1time/30days</span>",
                "NewStandardText": "",
                "CustomBasic": "<span style=\"color:#FF8F00;font-weight:600\">1time/30days</span>"
            },
            {
                "Feature": "Gestione inventario",
                "explain": "",
                "Basic": "Solo notifiche",
                "BasicText": "",
                "Standard": "<span style=\"color:#FF8F00;font-weight:600\">Aggiornamento automatico</span>",
                "StandardText": "",
                "AdvancedL": "<span style=\"color:#FF8F00;font-weight:600\">Aggiornamento automatico</span>",
                "AdvancedText": "",
                "Pro": "<span style=\"color:#FF8F00;font-weight:600\">Aggiornamento automatico</span>",
                "ProText": "",
                "Enterprise": "<span style=\"color:#FF8F00;font-weight:600\">Aggiornamento automatico</span>",
                "EnterpriseText": "",
                "NewStandard": "<span style=\"color:#FF8F00;font-weight:600\">Aggiornamento automatico</span>",
                "NewStandardText": ""
            },
            {
                "Feature": "Gestione dei costi di cambio",
                "explain": "",
                "Basic": "Solo notifiche",
                "BasicText": "",
                "Standard": "Solo notifiche",
                "StandardText": "",
                "AdvancedL": "<span style=\"color:#FF8F00;font-weight:600\">Aggiornamento automatico</span>",
                "AdvancedText": "",
                "Pro": "<span style=\"color:#FF8F00;font-weight:600\">Aggiornamento automatico</span>",
                "ProText": "",
                "Enterprise": "<span style=\"color:#FF8F00;font-weight:600\">Aggiornamento automatico</span>",
                "EnterpriseText": "",
                "NewStandard": "Solo notifiche",
                "NewStandardText": ""
            },
            {
                "Feature": "Politica dei prezzi automatica",
                "explain": "",
                "Basic": "Solo percentuale fisso o in salita",
                "BasicText": "",
                "Standard": "Solo percentuale fisso o in salita",
                "StandardText": "",
                "AdvancedL": "<span style=\"color:#FF8F00;font-weight:600\">Regole basate su margini di profitto, costi di spedizione, ecc.</span>",
                "AdvancedText": "",
                "Pro": "<span style=\"color:#FF8F00;font-weight:600\">Regole basate su margini di profitto, costi di spedizione, ecc.</span>",
                "ProText": "",
                "Enterprise": "<span style=\"color:#FF8F00;font-weight:600\">Regole basate su margini di profitto, costi di spedizione, ecc.</span>",
                "EnterpriseText": "",
                "NewStandard": "Solo percentuale fisso o in salita",
                "NewStandardText": ""
            },
            {
                "Feature": "Mappatura varianti",
                "explain": "Gestisci diversi rivenditori alla volta senza cambiare nulla sul tuo negozio",
                "Basic": "Fino a 2 rivenditori per prodotto e nessuna mappatura regione.",
                "BasicText": "",
                "Standard": "Fino a 2 rivenditori per prodotto e nessuna mappatura regione.",
                "StandardText": "",
                "AdvancedL": "<span style=\"color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px\">Mappa ciascuna variante in base alla regione di destinazione</span>",
                "AdvancedText": "",
                "Pro": "<span style=\"color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px\">Mappa ciascuna variante in base alla regione di destinazione</span>",
                "ProText": "",
                "Enterprise": "<span style=\"color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px\">Mappa ciascuna variante in base alla regione di destinazione</span>",
                "EnterpriseText": "",
                "NewStandard": "Fino a 2 rivenditori per prodotto e nessuna mappatura regione.",
                "NewStandardText": ""
            },
            {
                "Feature": "BOGO/Mappatura in gruppo",
                "explain": "Crea l'upsell del prodotto per ricevere maggiori vendite",
                "Basic": "<span class='line2'>—</span>",
                "BasicText": "",
                "Standard": "<span class='line2'>—</span>",
                "StandardText": "",
                "AdvancedL": "ok",
                "AdvancedText": "",
                "Pro": "ok",
                "ProText": "",
                "Enterprise": "ok",
                "EnterpriseText": "",
                "NewStandard": "<span class='line2'>—</span>",
                "NewStandardText": ""
            },
            {
                "Feature": "N° Tracking",
                "explain": "",
                "Basic": "<span class='line2'>—</span>",
                "BasicText": "",
                "Standard": "<span class='line2'>—</span>",
                "StandardText": "",
                "AdvancedL": "ok",
                "AdvancedText": "",
                "Pro": "ok",
                "ProText": "",
                "Enterprise": "ok",
                "EnterpriseText": "",
                "NewStandard": "<span class='line2'>—</span>",
                "NewStandardText": "",
                "CustomBasic": "<span class='line2'>—</span>"
            },
            {
                "Feature": "Personale dell'account",
                "explain": "",
                "Basic": "<span class='line2'>—</span>",
                "BasicText": "",
                "Standard": "<span class='line2'>—</span>",
                "StandardText": "",
                "AdvancedL": "<span style=\"color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px\">5</span>",
                "AdvancedText": "",
                "Pro": "<span style=\"color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px\">Unlimited</span>",
                "ProText": "",
                "Enterprise": "<span style=\"color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px\">Unlimited</span>",
                "EnterpriseText": "",
                "NewStandard": "<span class='line2'>—</span>",
                "NewStandardText": "",
                "CustomBasic": "<span class='line2'>—</span>"
            },
            {
                "Feature": "Affilato",
                "explain": "",
                "Basic": "<span class='line2'>—</span>",
                "BasicText": "",
                "Standard": "<span class='line2'>—</span>",
                "StandardText": "",
                "AdvancedL": "ok",
                "AdvancedText": "",
                "Pro": "ok",
                "ProText": "",
                "Enterprise": "ok",
                "EnterpriseText": "",
                "NewStandard": "<span class='line2'>—</span>",
                "NewStandardText": ""
            },
            {
                "Feature": "Personalizzazione",
                "explain": "Personalizza le funzionalità di Dsers e ottieni un volume di utilizzo maggiore.",
                "Basic": "<span class='line2'>—</span>",
                "BasicText": "",
                "Standard": "<span class='line2'>—</span>",
                "StandardText": "",
                "AdvancedL": "<span class='line2'>—</span>",
                "AdvancedText": "",
                "Pro": "<span class='line2'>—</span>",
                "ProText": "",
                "Enterprise": "<span style=\"color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px\">Potrebbero essere generate delle tariffe aggiuntive in base alla specifica richiesta o al volume di utilizzo.</span>",
                "EnterpriseText": "",
                "NewStandard": "<span class='line2'>—</span>",
                "NewStandardText": ""
            },
            {
                Feature: 'Invii il prodotto e le spese di spedizione a Shopify',
                explain: '',
                Basic: `<span class='line2'>—</span>`,
                BasicText: '',
                Standard: `<span style="color:#FF8F00;font-weight:600">Una volta che il prodotto viene messo in vendita, il costo dei prodotti del rivenditore e le spese di spedizione in [Polizza dei prezzi avanzata] vengono riuniti nel costo per articolo di Shopify. </span>`,
                StandardText: '',
                AdvancedL: `<span style="color:#FF8F00;font-weight:600">Una volta che il prodotto viene messo in vendita, il costo dei prodotti del rivenditore e le spese di spedizione in [Polizza dei prezzi avanzata] vengono riuniti nel costo per articolo di Shopify. </span>`,
                AdvancedText: '',
                Pro: `<span style="color:#FF8F00;font-weight:600">Una volta che il prodotto viene messo in vendita, il costo dei prodotti del rivenditore e le spese di spedizione in [Polizza dei prezzi avanzata] vengono riuniti nel costo per articolo di Shopify. </span>`,
                ProText: '',
                Enterprise:`<span style="color:#FF8F00;font-weight:600">Una volta che il prodotto viene messo in vendita, il costo dei prodotti del rivenditore e le spese di spedizione in [Polizza dei prezzi avanzata] vengono riuniti nel costo per articolo di Shopify. </span>`,
                EnterpriseText:'',
                NewStandard:'Fixed or percentage increase only',
                NewStandardText:'',
                CustomBasic:`<span class='line2'>—</span>`
            },
            {
                Feature: 'Synchronize Shopify order customization information',
                explain: ``,
                Basic: "<span class='line2'>—</span>",
                BasicText: '',
                Standard: "<span class='line2'>—</span>",
                StandardText: '',
                AdvancedL: `<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Send the customization information from Shopify to suppliers along with the order.</span>`,
                AdvancedText: '',
                Pro: `<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Send the customization information from Shopify to suppliers along with the order.</span>`,
                ProText: '',
                Enterprise:`<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Send the customization information from Shopify to suppliers along with the order.</span>`,
                EnterpriseText:'',
                NewStandard:"<span class='line2'>—</span>",
                NewStandardText:'',
                CustomBasic:"<span class='line2'>—</span>"
              },
        ],
        "pricingIncludesTitle": "FUNZIONALITà INCLUSE IN TUTTI I PIANI",
        "pricingIncludesData": [
            {
                "title": "Metodo di spedizione di pre-selezione",
                "desc": "Imposta un metodo di spedizioni automatico per gli ordini AliExpress in base a paesi, prodotti o condizioni"
            },
            {
                "title": "Correzione automatica indirizzo",
                "desc": "Risolve gran parte degli errori degli indirizzi per ridurre il lavoro"
            },
            {
                "title": "Aggiornamenti automatici sullo stato dell'ordine",
                "desc": "Non dovrai più sincronizzarti manualmente con l'estensione di Chrome. Tutto sarà automatico"
            },
            {
                "title": "Sincronizza automaticamente i numeri di tracciamento su Shopify",
                "desc": "Sincronizza automaticamente i numeri di tracciamento da AliExpress e aggiornali su Shopify"
            },
            {
                "title": "Sincronizza automaticamente i numeri di tracciamento su PayPal",
                "desc": "Migliora la tua reputazione inviando i numeri di tracciamento al tuo account PayPal"
            },
            {
                "title": "Estensione Chrome Dsers",
                "desc": "Importatore di prodotti AliExpress ed elaborazioni di ordini raggruppati con i buoni ricevuti"
            },
            {
                "title": "Messaggio automatico ai rifornitori",
                "desc": "Invia un messaggio preimpostato per far sapere le tue richieste ai tuoi rifornitori "
            },
            {
                "title": "Invia i commenti sugli ordini personalizzati dei clienti ai rifornitori",
                "desc": "Molto apprezzato dagli utenti con business POD"
            },
            {
                "title": "Prepara il tracciamento nella e-mail di conferma di spedizione",
                "desc": "Personalizza le informazioni di tracciamento nella tua e-mail Shopify"
            },
            {
                "title": "Trova rifornitori di tendenza",
                "desc": "Collezione di rifornitori di tendenza e qualificati su AliExpress"
            },
            {
                "title": "Ottimizzazione rifornitore",
                "desc": "Trova il miglior rifornitore per il tuo prodotto con un solo clic"
            },
            {
                "title": "Lista import",
                "desc": "Gestisci i tuoi prodotti da importare su Dsers e migra tranquillamente i prodotti tra i tuoi negozi"
            },
            {
                "title": "Prodotto diviso",
                "desc": "Dividi un prodotto in diversi prodotti per personalizzare la tua descrizione del prodotto"
            },
            {
                "title": "Pubblica più prodotti alla volta nel negozio",
                "desc": "Pubblica i prodotti selezionati AliExpress sul tuo negozio dopo l'uscita"
            },
            {
                "title": "Fuso orario e impostazioni valuta",
                "desc": "Imposta un fuso orario e una valuta per la tua zona"
            }
        ],
        "Unfinishedfront": "Non hai ultimato l'autorizzazione su Shopify.<br />\n    Clicca",
        "Unfinishedcentre": "Registrati",
        "Unfinishedqueen": "per continuare.",
        "Unfinished": "Se vuoi cambiare i tuoi piani di Dsers, prima cancella l'attuale<br />\n    abbonamento su Shopify come mostrato sotto e così potrai riselezionare il piano.",
        "UnfinishedNote": "Nota bene: <br />\n    1. Non ti verrà addebitato nulla fino al 31 dicembre 2020.<br />\n    2. NON disinstallare l'app DSers su Shopify, cancella soltanto l'abbonamento. <br />\n    se vuoi cambiare piani. La disinstallazione potrebbe causare la perdita dei dati su DSers.",
        "AlertText": "Aggiorna per ricevere maggiori funzionalità e possibilità. Scopri ulteriori dettagli sul <a href=\"https://help.dsers.com/subscription-plans-presentation-for-shopify/\" target=\"_blank\" > piano DSers  </a> .",
        "pop_Up": "Seleziona il negozio che userai per ricevere i pagamenti da Dsers",
        "display": "Consigliato",
        "HomeBasicButton": "Inizia",
        "HomeAdvancedButton": "Inizia la prova gratuita",
        "HomeProButton": "Inizia la prova gratuita",
        "HomeStandardButton": "Inizia la prova gratuita",
        "Select": "Seleziona",
        "Current": "Piano attuale",
        "Confirm": "Conferma",
        "ConfirmCancel": "Indietro",
        "Access": "Accesso",
        "AccessText": "Grazie per la tua registrazione. Accedi al tuo account Dsers!",
        "Monthly": "Piano di pagamento mensile",
        "Annually": "Piano di pagamento annuale",
        "working": "Grazie per aver scelto di lavorare con Dsers.",
        "like": "Ti è piaciuto?",
        "over": "Il tuo periodo di prova di 14 giorni è terminato.",
        "confirm": "Clicca il pulsante sottostante per confermare l'abbonamento annuale su Shopify.",
        "Contnct": "Contatti",
        "Subscribe": "Abbonati",
        "ChangePlan": "Cambia piano",
        "selectShop": "Seleziona quello che vuoi mantenere nel tuo piano Dsers.",
        "keepShop": "Seleziona un massimo di {quantity} negozi che vuoi mantenere.",
        "PlatformAndStores": "Piattaforma e negozi",
        "ShopifyPlatform": "Piattaforma Shopify",
        "Woocommerceplatform": "Piattaforma WooCommerce",
        "errText": "Il negozio di fatturazione di default { mistakeDomain } non è disponibile. Controlla lo stato del negozio su Shopify o cambia il negozio di fatturazione di default su DSers.",
        "errTextTwo": "Il negozio di fatturazione di default non è disponibile. Controlla lo stato del negozio su Shopify o cambia il negozio di fatturazione di default su DSers.",
        "billing": "Negozio di fatturazione di default",
        "help": "Il negozio di fatturazione è il negozio che paghi per il tuo abbonamento a Dsers. Per un'esperienza migliore, non attivare regolarmente il negozio di fatturazione.",
        "StoreManagement": "Gestione del negozio",
        "StoreManagementText": "Se decidi di acquistare un piano inferiore, DSers manterrà i tuoi negozi disattivati, così potrai disconnettere i negozi indesiderati e attivare quelli che si addicono al tuo piano. Una volta disconnessi, non potrai più aggiungere altri negozi rispetto al numero fissato dal piano.",
        "ActiveStore": "Negozio attivo",
        "InactiveStore": "Negozio inattivo",
        "Activate": "Attivo",
        "Inactivated": "Inattivo",
        "modification": "Il nostro sistema ha rilevato che hai cambiato il tuo negozio di fatturazione di default in  <span style=\"color:#00BFFF\"> {new}  </span>, l'abbonamento del negozio <span style=\"color:#00BFFF\"> {old} </span> è ormai ultimato. Clicca il pulsante sottostante per confermare il tuo negozio di fatturazione su Shopify.",
        "go_to_shopify": "Vai su Shopify",
        "if_free_dec": "Se sei un utente Shopify in prova gratuita o un utente development store,<br />\n                  potresti non vedere la pagina sopra ma solo quella sotto.<br />\nSe non sapessi come cancellare l'attuale abbonamento,<br />\n                  vai su amministrazione Shopify, disinstalla DSers e installalo nuovamente per riselezionare il piano",
        "change_annual_plan_notion": "Se vuoi cambiare il tuo attuale piano annuale, contatta il nostro servizio clienti su on <a href=\"https://www.messenger.com/t/DSersOfficial\" dd=\"src\" target=\"_blank\" rel=\"noopener noreferrer\">Facebook</a> o via <a href=\"https://www.messenger.com/t/DSersOfficial\">e-mail</a> per ottenere il rimborso dei costi rimanenti nel tuo piano attuale",
        "subscribe_tios": "Il negozio di fatturazione di default è chiuso o disattivato. Vai su\n    <a href=\"/app/select\"> Gestione abbonamento </a>\n    per cambiarlo e riprova",
        "subscription_expired": "Il tuo abbonamento è scaduto, sei pregato di rinnovarlo o di cambiarlo per continuare.",
        "subscription_network_tip": "If you encounter some network error or any other problems during the payment process, and the subscription is unsuccessful,please <a style=\"color:#00BFFF\" href={jumpUrl}>click here</a> to retry. You can also contact us and we will deal with it for you as soon as possible.",
        "subscription_network_tip_2": "Dovessi riscontrare qualche errore di network durante il pagamento, è l'abbonamento dovesse fallire, sei pregato di contattarci e ce ne occuperemo il prima possibile.",
        "subscription_btn3": "Il primo mese sarà",
        "subscription_btn4": "GRATIS!",
        "subscription_btn5": "Solo per te oggi!",
        "subscription_btn6": "Se aggiorni a <span class=\"titlesd\">Standard</span>  ora, potrai: <br/> Aggiungere <span class=\"titlesd\">2</span> altri negozi <br/> <span class=\"titlesd\">4.500</span> altri prodotti <br/> L'inventario dei prodotti sarà automaticamente aggiornato",
        "subscription_btn7": "AGGIORNA ORA",
        "subscription_btn8": "Mantieni il piano Base",
        "subscription_btn9": "Aggiorna al nostro piano di pagamento annuale",
        "subscription_btn10": "RISPARMIA $120!",
        "subscription_btn11": "Piano mensile",
        "subscription_btn12": "<span class=\"blon\">49.9</span>/mese",
        "subscription_btn13": "Piano annuale",
        "subscription_btn14": "<span class=\"blon\">478.8</span>/anno",
        "subscription_btn15": "Costo mensile: <span class=\"blon_d\">$39.9</span>",
        "subscription_btn16": "14 giorni di prova gratuita",
        "subscription_btn17": "SELEZIONA",
        "select_account": "Sei pregato di selezionare",
        "select_account_2": "gli account dello staff che desideri mantenere",
        "custom_pro": "Custom Pro",
        "Custom_EnterPrise": "Custom EnterPrise",
        "custom_basic": "Personalizza basic",
        "custom_to_basic": "Passa al piano Basic",
        "tobasictext": "Conferma passaggio",
        "oCancel": "Annulla",
        "tobaiscnote": "Nota",
        "tobaiscdes": "Dopo essere sceso al piano Basic, avrai un limite di 3000 prodotti, ma non potrai più sincronizzare automaticamente l'inventario e i prezzi, e non potrai tornare al tuo Custom Basic. Sei sicuro di voler cambiare piano?",
        "BasicTracking":"basic + tracking",
        "Basic500":"Basic+500",
        "Basic1000":"Basic+1000",
        "CustomBasicTracking":"Custom Basic+Tracking",
        "CustomBasic500":"Custom Basic+500",
        "CustomBasic1000":"Custom Basic+1000",
    }
}