import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { connect } from 'dva';
import { Input, Drawer, Row, Tag, Spin, Button,Checkbox,Radio, message } from 'antd';
import intl from 'react-intl-universal';
import qs from 'querystring';

import { getSupplierUrl, replaceUrlToHttps } from 'utils/utils';
import { getProductList, setProductList, deletePorductList } from 'services/productmy';
import MoreAction2 from './MoreAction2';

import { SearchInput } from 'features'

import SkuSelect from './SkuSelect';
import styles from './index.less';
import './mapping.css';
import { object } from 'prop-types';
import { GuildeAction, LinkStoreAction } from 'actions'
const { CheckableTag } = Tag;
const { Search } = Input;
import { CommonDataCollect, getSupplierId } from 'utils/utils';
export function GetProductSkuOptionFromDetail(prod) {
  const map = new Map();
  const skuMap = new Map(); // DSers自己拼接SKU ID和速卖通SKU ID map
  const opts = [];
  if (!prod?.aeop_ae_product_s_k_us) {
    return opts;
  }
  const { aeop_ae_product_s_k_us: { aeop_ae_product_sku = [] } } = prod;
  aeop_ae_product_sku.forEach(e => {
    const dsersSkuProdIds = [];
    if (!e.aeop_s_k_u_propertys) {
      return;
    }
    const { aeop_s_k_u_propertys: { aeop_sku_property = [] } = {} } = e;
    aeop_sku_property.forEach((v, i) => {
      const id = GetSkuPropertyID(v);
      dsersSkuProdIds.push(id);
      if (!map.has(id)) {
        // Init
        if (opts.length <= i) {
          opts.push({
            prop_id: v.sku_property_id,
            prop_name: v.sku_property_name,
            values: []
          });
        }
        v.index = i;
        v.product_id = prod.product_id_str
        opts[i].values.push(v);
        map.set(id, true);
      }
    });
    if (!skuMap.has(e.id)) {
      skuMap.set(dsersSkuProdIds.join(';'), e.id);
    }
  });

  return [opts, skuMap];
}

// GetSkuPropertyID 获取SKU属性的ID
function GetSkuPropertyID(prop) {
  let res = `${prop.sku_property_id}:${prop.property_value_id_long}`;
  if (prop.property_value_definition_name) {
    res = `${res}#${prop.property_value_definition_name}`;
  }
  return res;
}

// 获取用户选则的 sku展示的名字 拼起来
function GetSKUDesc(props) {
  const res = [];
  props = props.sort((a, b) => a.index - b.index);
  props.forEach(p => {
    res.push(GetSkuPropertyName(p));
  });
  return res.join(' / ');
}

// GetSkuPropertyName 获取SKU属性展示的名字
function GetSkuPropertyName(prop) {
  if (prop.property_value_definition_name) {
    return prop.property_value_definition_name;
  }
  if (prop.sku_property_value && prop.sku_property_value.length > 0) {
    return prop.sku_property_value;
  }
  return prop.property_value_id_long;
}

// GetProductDefaultImage 获取商品默认图片
function GetProductDefaultImage(prod) {
  if (prod.image_u_r_ls && prod.image_u_r_ls.length > 0) {
    return prod.image_u_r_ls.split(';')[0];
  }
  return '';
}

// GetSKUID 根据属性列表，返回对应AE SKU ID
export function GetSKUID(props, skuMap) {
  const res = [];
  props = props.sort((a, b) => a.index - b.index);
  props.forEach(p => {
    res.push(GetSkuPropertyID(p));
  });
  const dsersId = res.join(';');
  // return dsersId;
  if (skuMap.has(dsersId)) {
    return skuMap.get(dsersId);
  }
  return '';
}

function getRealSkuhandler(value){
  let str = value.split(';').sort().join(';')
  return str
}

function getTmallSkuId(sku,data){
  let sku_id = '';
  data?.forEach(i=>{
    if(getRealSkuhandler(i.id) === getRealSkuhandler(sku)){
      sku_id = i.sku_id
    }
  })
  return sku_id
}

// CheckShipfromSku 如果有ShipFrom数据项则返回对应Shipfrom信息
function GetShipfromSku(prod) {
  const map = new Map();
  const opts = [];

  if (!prod.aeop_ae_product_s_k_us) {
    return opts;
  }

  const { aeop_ae_product_s_k_us: { aeop_ae_product_sku = [] } } = prod;

  aeop_ae_product_sku.forEach(e => {
    e.aeop_s_k_u_propertys.aeop_sku_property.forEach((v, i) => {
      if (v.sku_property_id == '200007763') {
        const id = GetSkuPropertyID(v);
        if (!map.has(id)) {
          opts.push(v);
          map.set(id, true);
        }
      }
    });
  });

  return opts;
}

const StandardImportV2 = React.memo(({ 
  hideImportSupply, 
  visible, 
  importCallback, 
  importProductId, 
  shopifyProductId,
  storeId,
  selectData,
  sp,
  productId,
  dispatch,
  standProductNum,
  handleDeleteSku,
  productShipTo
}) => {
  const [searchUrl, setSearchUrl] = useState('');
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [importDialog, setImportDialog] = useState(false);
  const [importLoading, setImportLoading] = useState(false);
  const [selectedSkuImg ,setSelectedSkuImg] = useState('');
  const [defaultImg ,setDefaultImg] = useState('');
  const [selected, setSelected] = useState({
    id: null,
    selectedOptions: [],
    data: null,
    options: [],
    skuImage: '',
  });
  const [initExtends, setInitExtends] = useState([]);
  const [changeKey,setChangeKey] = useState()
  const [selectValue,setSelectValue] = useState('AliExpress')
  const getAeProduct = async (sp) => {
    const type = sp.platform_type === 4 ? true : false
    try {
      return new Promise((resolve) => {
        dispatch({
          type: type ?'productmy/tmProductDetail':'productmy/aeProductDetail',
          payload: {
            data: {
              url:getSupplierUrl(sp.platform_type,sp.platform_product_id),
              country:productShipTo,
              product_id:sp.platform_product_id,
              supplier_type:sp.platform_type
            },
            callback: d => {
              resolve(d);
            }
          }
        })
      });
    } catch (error) {
      
    }
  }

  const importAEProduct = async (url) => {
    setImportLoading(true);
    if(selectValue === 'Tmall'){
      CommonDataCollect({
        action:'myproduct_standard_mapping_import_tmall',
        event_type:'my_product_tmall'
      })
    }
    if(!url) return;

    // 套餐限制
    if(products.length >= standProductNum){
      if(standProductNum == 2){
        CommonDataCollect({
          action: 'Standard-M-top',
          event_type:'overlimit_pop'
        });
        //  跳转订阅 && 弹窗拦截
        // window.EVENT.emit(GuildeAction.TurnPlan_Pop, `Standard-M&${Date.now()}`, true);
        //self.location.href = '/app/pricing'
        window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'StandardMapping')
      }else{
        message.error(intl.get('mapping.standProductNum'));
      }
      return
    }

    try {
      const importRes = await setProductList({
        data: {
          dsers_product_id: productId,
          supplier: url,
          product_id: getSupplierId(selectValue === 'Tmall' ? 4 : 2,url),
          supplier_type:selectValue === 'Tmall' ? 4 : 2,
          store_id: storeId,
        }
      });
      
      if(importRes.code === 2000) {
        getProducts();
        setInitExtends([importRes.data.product_id]);
      } else if (importRes.code === 4290) {
        CommonDataCollect({
          action: 'Standard-M-top',
          event_type:'overlimit_pop'
        });        
      //  跳转订阅 && 弹窗拦截
      // window.EVENT.emit(GuildeAction.TurnPlan_Pop, `Standard-M&${Date.now()}`, true);
      window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'ProductsLimit')
      //  window.location.href = '/app/pricing?select=1'
      }
    } catch (error) {
      
    }
    setImportLoading(false);
  }

  const searchValueChange = (e) => {
    setSearchUrl(e?.target?.value || '');
  }
  const searchFocus = () => {}
  const keyUpSearch = () => {}
  const getProducts = async () => {
    setLoading(true);
    try {
      const res = await getProductList({
        data: {
          dsers_product_id: productId,
        }
      });
      if(res.code === 2000) {
        setProducts(res.data || []);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  const refreshProducts = (data)=>{
    setProducts(data || [])
  }

  const handleSelectChange = (newValue) => {
    setSelected(newValue);
    setChangeKey(newValue.changeKey)
  }

  const handleSave = () => {
    let unsave_flag  = true
    products.forEach(it=>{
      if(selected.selectedOptions.findIndex(i=>i.product_id === it.supplier_id) > -1 || 
      selected.selectedOptions.findIndex(i=>i.sku_property_value === 'Default Title') > -1){
        unsave_flag = false
      }
    })
    if (
      selected.selectedOptions.length != selected.options.length ||
      selected.selectedOptions.includes(false) ||
      selected.selectedOptions.includes(undefined)||
      !selected.selectedOptions.length || unsave_flag
    ) {
      return;
    }
    
    const [opts, skuMap] = GetProductSkuOptionFromDetail(selected.data);

    const selectedSkuImg = selected.selectedOptions.find(i => {
      return typeof i.sku_image !== 'undefined';
    })?.sku_image;

    const firstSkuImg = selected?.data?.aeop_ae_product_s_k_us?.aeop_ae_product_sku?.filter(i=>{
      if(i.id === GetSKUID(selected.selectedOptions, skuMap)){
        return i
      }
    })[0]?.image_url
    console.log(selected,GetSKUID(selected.selectedOptions, skuMap),firstSkuImg,'selected-=-=');
    setSelectedSkuImg(selectedSkuImg);

    // console.log(selected.skuImage,selectedSkuImg,defaultImg, 'selected.skuImage-=-=');

    const importCallbackRes = {
      platform_product_id: `${selected.data?.product_id_str ||
        selected.data?.product_id}`,
      platform_sale_id: selected.data.owner_member_id,
      title: selected.data.subject,
      sku_image: firstSkuImg || selectedSkuImg || selected.skuImage || defaultImg,
      sku_desc: GetSKUDesc(selected.selectedOptions),
      sku: GetSKUID(selected.selectedOptions, skuMap) || '<none>',
      supplier_sku_id: getTmallSkuId(
        GetSKUID(selected.selectedOptions, skuMap),
        selected?.data?.aeop_ae_product_s_k_us?.aeop_ae_product_sku
      ),
      platform_type: selected?.supplier_type
    };

    console.log(importCallbackRes, selectData,selected.selectedOptions, skuMap,'-=-=-=save');
    importCallback(importCallbackRes, [], selectData);

  }
  const handleMoreActionChange = (prod) => {
    dispatch({
      type: 'dropshippersetting/showModal',
      payload: {
        data: {
          title: prod.subject,
          product_id: prod.product_id,
          product_id_str: prod?.product_id_str,
          platform_type: prod?.supplier_type
        }
      }
    });
  }

  const getDefaultImage = (img) => {
    setDefaultImg(img);
  }

  const initSelected = async (sp) => {
    try {
      const aeProductRes = await getAeProduct(sp);
      if(aeProductRes.code === 2000) {
        const data = aeProductRes.data;
        const skus = GetProductSkuOptionFromDetail(data)[0];
        const selectedOptions = [];
        if (skus.length == 0) {
          skus.push({
            prop_id: 1,
            prop_name: 'Title',
            values: [
              {
                  index: 0,
                  property_value_id_long: '',
                  sku_image: '',
                  sku_property_id: '',
                  product_id:data.product_id_str,
                  sku_property_name: 'Title',
                  sku_property_value: 'Default Title'
              }
            ]
          });
          
        } 

        if(sp.sku === '<none>' || sp.sku === '') {
          selectedOptions[0] = {
            index: 0,
            product_id: data?.product_id_str,
            property_value_id_long: "",
            sku_image: "",
            sku_property_id: "",
            sku_property_name: "Title",
            sku_property_value: "Default Title"
          }
        } else {
          const spSkus = sp.sku.split(';');
          skus.forEach((option, index) => {
            const propId = option.prop_id;
            const value = option.values?.find(value => {
              return spSkus.find(i => i.split('#')[0] === `${propId}:${value.property_value_id_long}`); 
            });
            if(value) {
              selectedOptions[index] = {
                index,
                ...value,
              }
            } else {
              console.log('not found option')
            }
          });
        }
        const newValue = {
          id: data.product_id_str,
          options: skus,
          selectedOptions,
          data,
          supplier_type:data.supplier_type
        }
        console.log(newValue,'------newValue-------');
        setTimeout(()=>{
          setSelected(newValue)
        },100)
        
      } else {
        console.log('product error')
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getProducts();
    if(sp?.platform_product_id) {
      initSelected(sp);
    }
  }, []);

  return (
    <Drawer
      closable={false}
      visible={visible}
      maskClosable
      width={868}
      title={"Import Supplier & Select Variant"}
      onClose={hideImportSupply}
      className={styles.importDrawer}
    >
      <div style={{ paddingBottom: 56 }}>
        <div className={styles.searchWrap}>
          <div className={styles.searchLine}>
            <SearchInput
              value={searchUrl}
              placeholder={
                selectValue === 'AliExpress' ? 
                intl.get('mapping.import_ae_title'):
                intl.get('mapping.import_tm_title')
              }
              onSubmit={importAEProduct}
              onlySearch={true}
              onChange={searchValueChange}
              onFocus={searchFocus}
              onKeyUp={keyUpSearch}
              autoFocus={false}
              getPlatform={(v)=>{
                setSelectValue(v)
              }}
              hiddenSelect={window.TMALL_USER ? false : true}
              allowClear
            />
          </div>
          <div className="dasuww"></div>
        </div>

        <div className={styles.spinContainer}>
          <p className={styles.importTitle}>
            {intl.get('mapping.select_a_variant')}
          </p>
          {products.length > 0
          ? products.map((item,index) => {
              return (
              <Fragment key={item.supplier_id}>
                  <SkuSelect
                  selectKey={index+1}
                  prod={item}
                  onChange={handleSelectChange}
                  GetProductSkuOptionFromDetail={GetProductSkuOptionFromDetail}
                  GetSkuPropertyID={GetSkuPropertyID}
                  GetSkuPropertyName={GetSkuPropertyName}
                  initExtends={initExtends}
                  // getStandardProduct={getProducts}
                  getStandardProduct={refreshProducts}
                  handleMoreActionChange={handleMoreActionChange}
                  selectData={selected}
                  sp={sp}
                  skuImage={selectedSkuImg}
                  handleDeleteSku={handleDeleteSku}
                  getDefaultImage={getDefaultImage}
                  changeKey={changeKey}
                  // onRef={this.onSku}
                />
              </Fragment>)
          }) 
          : (
            <div className={styles.empty}>
              {selectValue === 'AliExpress' ? 
              intl.get('mapping.import_ae_first') : 
              intl.get('mapping.import_tm_first')}
            </div>
          )}
          {loading ? (
            <div className={styles.loadingbox}>
              <Spin size="large" />
            </div>
          ) : null}
        </div>
        <div className={styles.bottomSave}>
          <Button
            style={{
              marginRight: 8
            }}
            data="bk"
            onClick={hideImportSupply}
          >
            {intl.get('public.cancel')}
          </Button>
          <Button data="dx" loading={false} className={styles.submit} type="primary" disabled={!products.length>0} onClick={handleSave}>
            {intl.get('public.submit')}
          </Button>
        </div>
      </div>
    </Drawer>
  );
});

export default connect(({ productmy, loading }) => ({
  productmy,
  loading: loading.models.productmy,
  getAeLoading: loading.effects['productmy/aeProductDetail'],
  historyLoading: loading.effects['productmy/importHistory']
}))(StandardImportV2);