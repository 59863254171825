
    export default 
    {
    "send_reset_email": {
        "title": "Passwort zurücksetzen",
        "tip": "Wir senden Ihnen einen Verifizierungscode an Ihre E-Mail-Adresse, um Ihre Identität zu überprüfen.",
        "dec": "Geben Sie die E-Mail-Adresse ein, die Sie für die Registrierung verwendet haben. Wenn Sie DSers über Shopify installiert haben, geben Sie bitte die E-Mail-Adresse Ihres Shopify-Kontos ein. Wenn Sie Ihre E-Mail-Adresse vergessen haben, geben Sie bitte",
        "contact_us": "Kontaktieren Sie uns",
        "placeholder": "E-Mail",
        "email_instructions": "E-Mail-Anweisungen",
        "send_succ": "Die Anweisungen zum Zurücksetzen Ihres Passworts wurden Ihnen per E-Mail zugesandt.",
        "email_not_valid": "Die Eingabe ist nicht gültig E-Mail!",
        "email_empty": "Bitte E-Mail eingeben!",
        "email_unknown": "Bitte geben Sie Ihre Anmeldungs-E-Mail ein."
    }
}