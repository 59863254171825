// reference: https://stackoverflow.com/questions/10306690/what-is-a-regular-expression-which-will-match-a-valid-domain-name-without-a-subd
export const domainRegexp = /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]$/;

// https://stackoverflow.com/a/32686261/8818057
export const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

/**
 * reference: https://regexr.com/3bfsi
 * - at least 8 characters
 * - must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number
 * - Can contain special characters
 */
export const passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,16}$/;

// paypal 授权地址
export const paypalAuthorizeRul =
  'https://www.paypal.com/connect/?flowEntry=static&client_id=AYBObuTBCPCZuvLeGssm1E9EMKvmTk3yCyqA110cWtZqxy82bxo9h51KUaRfvbxsOna3FHG4fiwnUNDc&response_type=code&scope=openid profile email https://uri.paypal.com/services/paypalattributes https://uri.paypal.com/services/shipping/trackers/readwrite&redirect_uri=https://www.dsers.com/app/paypal';

// /^(?:https?:\/\/)?(?:www.?)?aliexpress.com\/item\/\d+/;
/**
 * aliexpress 链接格式有多种
 * https://nl.aliexpress.com/item/4001292889478.html?
 * https://www.aliexpress.com/item/4001292889478.html?
 * https://aliexpress.ru/item/4000725986281.html?
 *
 */
export const aeUrlMatchReg = /^(?:https?:\/\/)?(?:[a-z]{1,10}.?)?aliexpress.[a-z]{2,10}(\/item|\/i|\/item\/product)\/\d+.html/;

// export const tmallUrlMatchReg = /^(?:https?:\/\/)?(?:[A-Za-z0-9.-]{1,10}.?)?dsers.[a-z]{2,10}\/app\/tmallproduct\/\d+./;
export const tmallUrlMatchReg = /\/app\/tmallproduct\/\d+./;

export const pageTitle =
  'DSers - Automation order management with tech solution';

export const languageLabels = {
  'en-US': 'English',
  'fr-FR': 'Français',
  'pt-PT': 'Português',
  'de-DE': 'Deutschland',
  'it-IT': 'Italia',
  'es-ES': 'España'
};

export const PAGE_SIZE = 24;
export const pageSizeOptions = ['60', '80', '100'];

// 上线一个月内
export const isFreshEntrust =
  Date.now() - new Date(2020, 8, 15) < 24 * 60 * 60 * 1000 * 30;

export const AGENCY_NAME_TO_PLATFORM = {
  DIANXIAOMI: 6,
  MABANG: 7,
  ECCANG: 8,
  TONGTOOL: 9,
  LINGXING: 10,
  MANGO: 11,
  SUPERSELLER: 12,
  // 后端订单枚举的类型 前端不应以这个对齐
  // AGENCYOTHER: 13,
  OTHER: 13
};

export const DSERS_IMG_HOST = 'https://img.dsers.com';

// firebase app初始化参数
// 参数来源: https://console.firebase.google.com/project/dsers-b1c47/settings/general/web:YmU3MWM4YjUtZmQwMS00ZGExLTlkMWQtZjE3ODVmYTgwNzVl?hl=zh-cn
// 进不去链接的请找赵老师获取访问权限
export const DSERS_FIREBASE_CONFIG = {
  apiKey: 'AIzaSyAs_Z463QmCxKh3WGesUgAQvlLibSxWhaQ',
  authDomain: 'dsers-b1c47.firebaseapp.com',
  databaseURL: 'https://dsers-b1c47.firebaseio.com',
  projectId: 'dsers-b1c47',
  storageBucket: 'dsers-b1c47.appspot.com',
  messagingSenderId: '290627965549',
  appId: '1:290627965549:web:d934cabf5dcb4f568ca564'
};

export const FCM_PUSHAPI_PUBKEY =
  'BHbnCdpxNPRBA62aBcGo2IP6C25m1qcOJGVswjowuxknb-MmOZAAE9GfyWT5y9Jnm2G48ZmEKRNqae-HwNewNdE';
