export default {
  public: {
    cancel: 'Annulla',
    yes: 'Sì',
    no: 'No',
    ok: 'Ok',
    save: 'Salva',
    submit: 'Invia',
    search: 'Cerca',
    confirm: 'CONFERMARE',
    newconfirm: 'CONFIRM',
    apply: 'Applica',
    log_out: 'Esci',
    reset_password: 'Reimposta la password',
    delete: 'Elimina',
    got_it: 'Capito',
    check_more: 'Scopri di più',
    more: 'Più',
    page: 'pagina',
    access: 'Accesso',
    refresh: 'Aggiorna',
    reconnect: 'Riconnetti',
    on: 'On',
    off: 'Off',
    here: 'qui',
    Manage: 'Gestisci abbonamento',
    check_it: 'Controlla',
    dismiss: 'Rimuovi',
    contact: 'Contatta',
    contact_us: 'Contattaci',
    facebook: 'Facebook',
    youtube: 'YouTube',
    newsave: 'Salva',
    updata_price:
      'Aggiorna il prezzo dei prodotti sponsorizzati sul tuo negozio Shopify in base alle politiche dei prezzi da te fissate. Il processo potrebbe richiedere del tempo. Si può usare questa funzione ogni 30 giorni..',
    please_enter: 'Please enter CONFERMARE to start the update',
    shopUnavailable:
      'This store is currently disconnected. Please reconnect it to use this feature',
    InterfaceTransfinite:
      'Aggiorna il prezzo dei prodotti sponsorizzati sul tuo negozio Shopify in base alle politiche dei prezzi da te fissate. Il processo potrebbe richiedere del tempo. Si può usare questa funzione ogni 30 giorni.. Due to interface restrictions, the feature will be opened after 24:00, please wait patiently',
    synchronous: "The store's product prices are being updated",
    useAgain: 'Can be used again in',
    day: 'days',
    pause:
      'Synchronization of the day complete,\n    The remaining products will be automatically synced tomorrow',
    goodsTooMuch:
      'Aggiorna il prezzo dei prodotti sponsorizzati sul tuo negozio Shopify in base alle politiche dei prezzi da te fissate. Il processo potrebbe richiedere del tempo. Si può usare questa funzione ogni 30 giorni.. Since your store has more than 100,000 items, we will synchronize all your items in multiple days (up to 100,000 per day), please be patient.',
    exceeds:
      'As your product synchronization exceeds 100,000, this function will be enabled after 24:00, please wait patiently',
    noproduct: 'The store has no product that can be updated',
    updateTitle: 'Aggiornamento prezzo prodotto',
    updateText:
      'Aggiorna il prezzo dei prodotti sponsorizzati sul tuo negozio Shopify in base alle politiche dei prezzi da te fissate. Il processo potrebbe richiedere del tempo. Si può usare questa funzione ogni 30 giorni..',
    start: 'Inizia aggiornamento',
    unsave: 'Unsaved changes',
    checkmodaltext:
      'The information you modified has not been saved yet. If you want to save, please click "Salva", if you don\'t want to save your modifications, click "DISCARD"',
    discard: 'DISCARD',
    choose: 'SELECT',
    gotodsers: 'Go to DSers Homepage',
    oberlo_data_pending: 'Data correction.',
    oberlo_data_success: 'Data correction completed.',
    oberlo_data_fail: 'Data verification Failed.',
    oberlo_data_try: 'RIPROVA',
    priceruleDis:
      'Please activate and confirm your <a href="/app/settings?=5" > Pricing Templates </a> settings in Pricing Rule and start to update afterwards.',
    padding_title:
      'Stiamo correggendo le informazioni degli ordini e dei prodotti, attendere',
    migrate_order_tip:
      "Sincronizzazione dello stato di uno degli ordini non riuscita. è possibile che l'account AliExpress su cui sono stati effettuati gli ordini è diverso dall'account AliExpress attualmente collegato. Cambia l'account AliExpress collegato a DSers per aggiornare lo stato degli ordini. In caso di dubbi, contatta il team di supporto DSers. ",
    oberlo_product_error_tip:
      'Verifica dei dati dei prodotti completata, ma { num } prodotti AliExpress non sono disponibili e non possono essere importati in DSers. Dovrai mappare manualmente i nuovi rivenditori. Vai alla sezione dei prodotti non mappati per visualizzare.',
    add_report_user_tip:
      'Data is being created, the report will be generated within 24 hours. Thank you for your patience.',
    filter_confirm_des: 'CONFERMA',
    filter_clear_des: 'CANCELLA TUTTI I FILTRI',
    unsaveErr: 'Unable to save changes',
    gocheck: 'Go check',
    checkmodalerrtext1: 'The reason you cannot save is ',
    checkmodalerrtext2:
      'To fix the issue, you need to re-map the part facing issues, or delete the mapping with problems.',
    checkmodaladvanceerrtext:
      'In Advanced Mapping, the Ship to selection cannot be repeated on the same variant, otherwise the mapping cannot be saved. ',
    checkmodalbasicrepeaterrtext:
      'In Basic Mapping, Options cannot be repeated, otherwise the mapping cannot be saved. ',
    checkmodalbasicnullerrtext:
      'In Basic mapping, you need to select the corresponding variant, otherwise the mapping cannot be saved. ',
    input_guide_text:
      'The Search or Import field, along with the Filter feature has been moved! You can now find them on the top right corner.',
    input_guide_btn: 'Got it',
    importlist_card_guide_text:
      "We have improved the product card and some features can now be found in 'More Action' in the lower right corner",
    product_card_guide_text:
      "We have improved the product card and the mapping function now has a floating display. Some features can now be found in 'More Action' in the lower right corner",
    search_input_url_error_match_ae_tip:
      `L'URL digitato non è un URL AliExpress, controlla e riprova.`,
    search_input_url_error_match_tm_tip:
      `L'URL digitato non è un URL Tmall, controlla e riprova.`,
    search_input_url_invalid_tip:`L'URL digitato non è valido. Controlla e riprova.`,
  }
};
