import {
  queryCurrent,
  getNotificationToken,
  getNotificationList,
  getProject,
  getRates,
  queryNewInfo,
  getNoviceGuidance,
  getCsvOrderList
} from '../services/user';

import moment from 'moment';

export default {
  namespace: 'user',

  state: {
    passwordModalVisible: false, // 重置密码 modal
    list: [],
    currentUser: {},
    plan: {},
    newUserInfo:{},
    noviceGuidanceInfo:{},
    csvOrderCount:0
  },

  reducers: {
    // 获取支付状态
    setPlan(state, { payload }) {
      return {
        ...state,
        plan: {
          ...payload.data,
          lastTime:
            moment(payload.data.created_at).valueOf() + 24 * 60 * 60 * 1000
        }
      };
    },
    setPasswordModalVisible(state, { payload }) {
      return {
        ...state,
        passwordModalVisible: payload
      };
    },
    saveCurrentUser(state, { payload }) {
      return {
        ...state,
        currentUser: payload.data
      };
    },
    setNotificationToken(state, { payload = {} }) {
      return {
        ...state
      };
    },
    setNotificationList(state, { payload = {} }) {
      return {
        ...state
      };
    },
    setNewUserInfo(state,{payload={}}){
      return {
        ...state,
        newUserInfo:payload
      }
    },
    setNoviceGuidance(state,{payload={}}){
      return {
        ...state,
        noviceGuidanceInfo:payload
      }
    },
    setCsvOrderListCount(state,{payload={}}){
      return {
        ...state,
        csvOrderCount:payload
      }
    }
  },

  effects: {
    *getProject({ payload }, { call, put }) {
      try {
        const result = yield call(getProject, payload);
        if (result && result.data) {
          localStorage.setItem('payment', JSON.stringify(result.data));
          localStorage.setItem(
            'lastTime',
            moment(result.data.created_at).valueOf() + 24 * 60 * 60 * 1000
          );
          yield put({
            type: 'setPlan',
            payload: result
          });
          if (localStorage.getItem('noMigra') == 'false') {
            // 判断是否升降级
            let planRelation = { '1': 1, '2': 3, '3': 4, '4': 2, '5': 5, '6': 7, '7': 3, '8': 2, '9': 4, '10': 1 };
            let planKey = JSON.parse(localStorage.getItem('store'))?.user_id;
            if (planRelation[localStorage.getItem(`${planKey}-planTypeChoose`)] > planRelation[result.data.type]) {
              localStorage.setItem(`${planKey}-reducePlan`, true)  //是否降级过套餐
            }
            localStorage.setItem(`${planKey}-planTypeChoose`, result.data.type ? result.data.type : 0)
          }
        }
      } catch (error) {
        console.log('saga/error getProject:', error);
      }
    },

    *fetchCurrent(_, { call, put }) {
      const response = yield call(queryCurrent);
      yield put({
        type: 'saveCurrentUser',
        payload: response
      });
    },
    *notificationToken({ payload }, { call, put }) {
      const response = yield call(getNotificationToken, payload);
      yield put({
        type: 'setNotificationToken',
        payload: response
      });
    },
    *notificationList({ payload }, { call, put }) {
      const response = yield call(getNotificationList, payload);
      yield put({
        type: 'setNotificationList',
        payload: response
      });
    },
    *rates({ payload }, { call, put }) {
      yield call(getRates, payload);
    },
    *getNewInfo({payload},{call,put}){
      const res = yield call(queryNewInfo)
      yield put({
        type:'setNewUserInfo',
        payload:res.data
      })
    },
    *getNoviceGuidance({ payload }, { call, put }){
      const res = yield call(getNoviceGuidance);
      yield put({
        type:'setNoviceGuidance',
        payload:res.data || {}
      })
    },
    *getCsvOrderList({payload},{call,put}){
      const res = yield call(getCsvOrderList)
      yield put({
        type:'setCsvOrderListCount',
        payload:res.data.count
      })
    }
  },

  subscriptions: {}
};

function deepClone(arr) {
  return JSON.parse(JSON.stringify(arr));
}
