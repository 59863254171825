import intl from 'react-intl-universal';
import { passwordReg } from 'utils/constants';

export const checkPassword = (rule, value, callback, confirmValue) => {
  try {
    if (!value) {
      callback(intl.get('login.empty'));
    } else if (confirmValue && value !== confirmValue) {
      callback(intl.get('register.password_not_match_twice'));
    } else if (value.length < 8 || value.length > 16) {
      callback(intl.get('register.password_error_long'));
    } else {
      callback();
    }
  } catch (err) {
    callback(err);
  }
};
