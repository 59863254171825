
    export default 
    {
    "common": {
        "learn_more": "Más información",
        "submit": "Enviar",
        "or": "O",
        "other": "Otros",
        "contact_us": "Contáctanos",
        "click_blog": "Haz clic <a href=\"{url}\" target=\"_blank\" rel=\"noopener noreferrer\">aquí</a> para saber cómo resolver este problema.",
        "click_blog2": "Please fill in your full name in English or French only and separate them with a space. Click <a href=\"{url}\" target=\"_blank\">here</a> to learn how to solve this issue."
    }
}