import React, { useState } from 'react';
import { connect } from 'dva';
import { Popover, Tooltip, Select } from 'antd';

import intl from 'react-intl-universal';
import className from 'classnames';
import round from 'lodash/round';

import { replaceUrlToHttps } from 'utils/utils';
import { currencySymbolMap } from '../../../common/sysconfig';

import defaultImg from '../../../assets/default-image.jpg';

import orderStyles from '../Order.less';

function Bundle(props) {

  const {
    child = {},
    getCanceledBy,
    status,
    moreStatus,
    index,
    listData,
    storeCurrency,
    listData: { storeId },
    bundle = 0,
    flag = false
  } = props;

  let currencyCode;
  let currencySymbol;
  let isNotUSD;
  let currencyRates;

  if (storeCurrency[storeId]) {
    currencyCode = storeCurrency[storeId].currency_code || 'USD';
    currencySymbol = currencySymbolMap[currencyCode];
    isNotUSD = currencyCode !== 'USD';
    currencyRates = storeCurrency[storeId].rates_value;
  }

  // 获取 AE canceled 标识
  const getAECanceled = (child, sp, sonIndex, tabStatus) => {
    if (tabStatus != 6) {
      return null;
    }

    if (sp?.status == 6 && child?.AECanceled[sonIndex]) {
      return (
        <div>
          <span className={orderStyles.canceledBy}>
            {/* {intl.get('order.ali_canceled')} */}
          </span>
        </div>
      );
    } else {
      return null;
    }
  };

  const getAeNumber = () => {
    if(status == 6){
      let transactions = listData?.ext?.transactions
      let arrOrderId = []
      transactions?.forEach(transactionsItem => {
        if(transactionsItem.hasOwnProperty('order_info')){
          for(const [key, value] of Object.entries(transactionsItem['order_info'])){
            arrOrderId.push(key)
          }
        }
      })
  
      arrOrderId = [...new Set(arrOrderId)]
  
      let newArr = arrOrderId?.filter(item => {
        return !child.sp.some(spItem => {
          return item == spItem.platform_order_id
        })
      })

      console.log('newArr', newArr)

      if(!newArr.length){
        return null;
      }

      return <>
        <p className={orderStyles.trackNoWrapX}>
          <p class="edit_pd">
          <div class="aliIcon">
            <span><i class="material-icons">account_circle</i></span>
          </div>
            Ali Order No:
            <div style={{marginLeft:5}}>
              <div className='selectDefault'>
                <div className='selectDefaultT'>
                  {newArr[0]}
                  <img
                    className="select_icon"
                    src={require('../../../assets/newImportList/icon_expand_more-24px.png')}
                  />
                </div>
                <div className='selectBox'>
                  <ul>
                  {
                    newArr.map(item => {
                      return <a  
                      key={item} 
                      href={`https://trade.aliexpress.com/order_detail.htm?orderId=${item}`} 
                      target="_blank" 
                      >
                        {item}
                      </a>
                    })
                  }
                  </ul>
                </div>
              </div>
            </div>
          </p>
        </p>
      </>

    }
  }
  

  return (
    <div
      className={className(orderStyles.product, {
        [orderStyles.productNoBorder]: child.sp.length > 0
      })}
    >
      <div className={orderStyles.productWrap}>
        <Popover
          placement="leftTop"
          trigger="click"
          content={
            <img
              src={replaceUrlToHttps(child.image)}
              onError={e => {
                e.target.onerror = null;
                e.target.src = defaultImg;
              }}
              style={{
                width: '260px',
                height: '260px',
                objectFit: 'contain'
              }}
            />
          }
          overlayClassName={orderStyles.popover}
        >
          <a className={orderStyles.productImg}>
            <img
              src={replaceUrlToHttps(child.image)}
              onError={e => {
                e.target.onerror = null;
                e.target.src = defaultImg;
              }}
            />
            {/* {bundle == 3 ? <div className="completed">Completed</div> : null} */}
            {bundle == 1 && !props.useSp.is_edit ? <div className="bundle">Bundle</div> : null}
          </a>
        </Popover>
        <div className={orderStyles.productInfo}>
          <span>{child.title}</span>
          <p>
            {intl.get('order.order_product.variants')} <b>{child.variant}</b>
          </p>
        </div>
        <div className={orderStyles.productShippingMethod}>
          <p>
            {intl.get('order.order_product.cost')}:&nbsp;
            <span>
              <b>$ {(child.cost * 1).toFixed(2)}</b>
            </span>
          </p>
          {isNotUSD ? (
            <p className={orderStyles.productCurrencyCost}>
              {`${currencySymbol} ${round(child.cost * 1 * currencyRates, 2)}`}
            </p>
          ) : null}
        </div>
        <div className={orderStyles.productAliOrderNo}>
          { getAeNumber() }
        </div>
      </div>
      { flag ?  getAECanceled(child, child.sp[1], 1, status) : null }
      <div>
        {getCanceledBy(child, status, 0, index, listData)}
        {moreStatus}
      </div>
    </div>
  );
}

export default connect(({ global }) => ({
  storeCurrency: global.storeCurrency
}))(Bundle);
