export default {
  register: {
    title: 'Crie uma conta DSers', //  m
    email: 'Email',
    password: 'Senha',
    get_started: 'CRIAR CONTA',
    // 文案被改动
    input_your_email: 'Insira um e-mail com formato válido.',
    input_password: 'Please enter 6 to 20 characters.',

    wrong_email_format: 'Formato errado de e-mail',
    password_tip:
      'Por favor utilize uma senha com no mínio 6 caracteres. Não utilize uma senha que possa ser facilmente descoberta.',
    contact_us: 'Contate-nos',
    hello_shopify: 'Olá, Shopify',
    install_dsers_to_shopify: 'Instale o DSers para Shopify',
    password_ok: 'Segurança: Alta',
    password_warning: 'Segurança: Média',
    password_error: 'Segurança: Baixa',
    privacy:
      'Ao clicar em “CRIAR CONTA”, você concorda com nossa <a href= "/privacy" target="_blank">POLÍTICA DE PRIVACIDADE</a>', // m
    result: {
      resend_successed: 'reenvio bem-sucedido',
      resend_failed: 'falha no reenvio',
      title: 'Processe pedidos múltiplos e em massa com DSers',
      your_account: 'Sua conta:',
      register_succ: 'foi registrada com sucesso.',
      activation_email: `Enviamos um e-mail de ativação para seu endereço <br />de e-mail.
        Verifique sua caixa de entrada e clique no <br />link para ativar sua conta em menos de 10 minutos.`,
      check_email: 'Verificar e-mail',
      resend_email: 'Reenviar e-mail',
      go_sign: 'Ir para o registro ou login',
      about_email: `Não recebeu o e-mail?
        Clique <a href="https://help.dsers.com/confirmation-email-not-received-for-shopify/" target="_blank">aqui</a> para acessar explicações específicas sobre o ocorrido!`
    },
    password_error_long: 'A senha deve ter entre 8 - 16 caracteres',
    password_error_format: 'The password contains at least 1 uppercase letter, 1 lowercase letter and 1 number',
    password_not_match_twice: 'Passwords do not match twice',
    haveAccount:`Já tem uma conta?`,
    signIn:`ENTRE`
  }
};
