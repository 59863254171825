function countDown(start, end) {
  let startTime = start;
  const endTime = end;

  function checkTime(i) {
    let flag = i;
    if (flag < 10) {
      flag = `0${i}`;
    }
    return flag;
  }

  function computationTime() {
    const ts = endTime - startTime; // 计算剩余的毫秒数
    let dd = parseInt(ts / 60 / 60 / 24, 10); // 计算剩余的天数
    let hh = parseInt((ts / 60 / 60) % 24, 10); // 计算剩余的小时数
    let mm = parseInt((ts / 60) % 60, 10); // 计算剩余的分钟数
    let ss = parseInt(ts % 60, 10); // 计算剩余的秒数
    dd = checkTime(dd);
    hh = checkTime(hh);
    mm = checkTime(mm);
    ss = checkTime(ss);
    return { ts, dd, hh, mm, ss };
  }

  // eslint-disable-next-line func-names
  (function() {
    const id = document.getElementById('advertisingOfflineDays');
    if (!id) {
      return;
    }
    const { dd, hh, mm, ss } = computationTime();
    document.getElementById('advertisingOfflineDays').innerHTML = dd;
    document.getElementById('advertisingOfflineHour').innerHTML = hh;
    document.getElementById('advertisingOfflineMinute').innerHTML = mm;
    document.getElementById('advertisingOfflineSecond').innerHTML = ss;
  })();

  let flag = setInterval(() => {
    const id = document.getElementById('advertisingOfflineDays');
    if (!id) {
      clearInterval(flag);
      flag = null;
      return;
    }

    const { ts, dd, hh, mm, ss } = computationTime();

    if (ts > 0) {
      document.getElementById('advertisingOfflineDays').innerHTML = dd;
      document.getElementById('advertisingOfflineHour').innerHTML = hh;
      document.getElementById('advertisingOfflineMinute').innerHTML = mm;
      document.getElementById('advertisingOfflineSecond').innerHTML = ss;
      startTime += 1;
    } else if (ts < 0) {
      clearInterval(flag);
      flag = null;
    }
  }, 1000);
}

export { countDown };
