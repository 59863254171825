export default {
  csv: {
    csv_bulk_order: 'Pedidos em Massa por CSV', //  d
    re_download_template:
      'O template foi atualizado. Por gentileza, faça o download novamente.',
    products: 'Produtos',
    import: 'Importar',
    download_template: 'Download Template',
    product_tip:
      'Gerencie seu banco de dados de produtos para fazer pedidos em massa.',
    orders: 'Pedidos',
    order_tip: `Obtenha os números de pedido do AliExpress e o número de rastreamento (os números de rastreamento geralmente levam dias para serem gerados) <br />
                ao fazer download do arquivo na coluna "Pedidos com Sucesso". <br />
                Revise os pedidos que falharam ao fazer download do arquivo na coluna "Ordens que falharam" e edite as informações necessárias.`,
    upload_title_orders: 'Importar pedidos',
    upload_title_product: 'Importar banco de dados de produtos',
    upload_btn: 'Importar',
    need_help: 'Precisa de ajuda?',
    choose_csv_file: 'Escolher arquivo CSV',
    no_file_chosen: 'Nenhum arquivo selecionado',
    max_8mb: 'Seu arquivo CSV precisa ter menos de 8MB',
    upload_download_tip: 'Para ver um exemplo de formato requerido',
    upload_succ: 'Upload com sucesso',
    upload_fail: 'Upload falhou',
    upload_fail_tip:
      'O formato do arquivo está incorreto. Por favor verifique o arquivo CSV.',
    click_here_to: 'Clique aqui para',
    download: 'download',
    download_error: 'Mensagem de erro no Excel.',
    order_table: {
      date: 'Data',
      file_name: 'Nome no arquivo',
      successful_orders: 'Pedidos bem-sucedidos',
      failed_orders: 'Pedidos com falha',
      action: 'Action'
    },
    pending: 'Pendente',
    place_order: 'Realizar pedido',
    check_product: 'Ver o SKU do produto AliExpress',
    check: 'Verificar',
    price: 'Preço',
    token_error:'Please refresh the page to download the file again.'
  }
};
