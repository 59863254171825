import React from 'react';

import { connect } from 'dva';
import intl from 'react-intl-universal';
import { Drawer } from 'antd';

import Marketplaces from './Marketplaces';
import Agency from './Agency';

// 后期改造原因
import styles from './mappingSelect.less';

import store from '../../assets/SupplierManagement/store.png';
import agencyMapping from '../../assets/SupplierManagement/agencyMapping.png';

const { useState, useEffect, useRef } = React;

function Mapping(props) {
  const [marketplacesState, setMarketplacesState] = useState(false);
  const [agencyState, setAgencyState] = useState(false);
  const [originProductData, setOriginProductData] = useState(null);

  const closeCallback = useRef();

  useEffect(() => {
    if (props.visible && props?.originProductData?.prod?.ext?.map_type) {
      if (
        [1, 2, 3, 4, 5].includes(props?.originProductData?.prod?.ext?.map_type)
      ) {
        setMarketplacesState(true);
      } else if ([6].includes(props?.originProductData?.prod?.ext?.map_type)) {
        setAgencyState(true);
      }
    }else if(props.visible && !window.CAINIAO_USER){
      setMarketplacesState(true);
    }

    setOriginProductData(null);
  }, [props.visible, props.originProductData]);

  function handleClose() {
    reloadUserInfo();
    props.hideSupplier(true);
    closeCallback.current = true;
  }

  const handleOnlyClose = (val)=>{
    reloadUserInfo();
    props.hideSupplier(true)
    closeCallback.current = true;
  }

  function handleCloseMarketplaces(val) {
    reloadUserInfo();
    closeCallback.current = val;
    setMarketplacesState(false);
  }

    // 更新userinfo
    function reloadUserInfo (){
      props.dispatch({
        type: 'dropshippersetting/reloadUserInfo'
      });
    };

  function handleCloseAgency(val) {
    closeCallback.current = val;
    setAgencyState(false);
  }

  // 在shopify打开商品详情
  function productOpenOnShopify(val) {
    const {
      userInfo: { stores }
    } = props;
    const {
      prod: {
        detail: { id },
        store_id
      },
      storeId
    } = val;

    const currenctStoreId = store_id || storeId;

    const productStore = stores.find(store => store.id === currenctStoreId);

    if (productStore && id) {
      window.open(
        `https://${productStore.shop_name}.myshopify.com/admin/products/${id}`,
        '_blank'
      );
    }
  }
  const CAINIAO_USER = localStorage.getItem('CAINIAO_USER');
  const TMALL_USER = localStorage.getItem('TMALL_USER');
  console.log(originProductData);
  return window.CAINIAO_USER || CAINIAO_USER ? (
    <Drawer
      visible={props.visible}
      maskClosable
      width={868}
      onClose={handleClose}
      className={styles.content}
      destroyOnClose
    >
      <div className={styles.header}>
        {intl.get('setting.agency.supplierManagement')}
      </div>
      <div className={styles.body}>
        <div
          className={styles.marketplaces}
          onClick={() => setMarketplacesState(true)}
        >
          <div className={styles.img}>
            <img src={store} alt="store" />
          </div>
          <div className={styles.text}>
            <div className={styles.title}>
              {intl.get('setting.agency.marketplaces')}
            </div>
            <div className={styles.subtitle}>
              {window.TMALL_USER || TMALL_USER
                ? intl.get('setting.agency.markeCardDescription')
                : intl.get('setting.agency.markeCardDescriptionNoTianMao')}
            </div>
          </div>
        </div>

        {window.CAINIAO_USER || CAINIAO_USER ? (
          <div className={styles.agency} onClick={() => setAgencyState(true)}>
            <div className={styles.img}>
              <img src={agencyMapping} alt="agencyMapping" />
            </div>
            <div className={styles.text}>
              <div className={styles.title}>
                {intl.get('setting.agency.Agency')}
              </div>
              <div className={styles.subtitle}>
                {intl.get('setting.agency.agencyCardDescription')}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <Marketplaces
        {...props}
        marketplacesVisible={marketplacesState}
        originProductDataMarketplaces={
          originProductData || props.originProductData
        }
        setOriginProductData={setOriginProductData}
        marketplacesHideSupplier={handleCloseMarketplaces}
        productOpenOnShopify={productOpenOnShopify}
      />
      {window.CAINIAO_USER || CAINIAO_USER ? (
        <Agency
          {...props}
          agencyVisible={agencyState}
          originProductDataAgency={originProductData || props.originProductData}
          setOriginProductData={setOriginProductData}
          agencyHideSupplier={handleCloseAgency}
          copyLink={productOpenOnShopify}
        />
      ) : null}
    </Drawer>
  ) : originProductData || props.originProductData ? (
    <Marketplaces
      {...props}
      marketplacesVisible={marketplacesState}
      originProductDataMarketplaces={
        originProductData || props.originProductData
      }
      onClose={handleOnlyClose}
      setOriginProductData={setOriginProductData}
      marketplacesHideSupplier={handleCloseMarketplaces}
      productOpenOnShopify={productOpenOnShopify}
    />
  ) : null;
}

export default connect(({ login }) => ({
  userInfo: login.userInfo
}))(Mapping);
