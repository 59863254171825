import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'core-js/modules/es.promise.any'; // promise.any 需要手动引入，否则不会被打包进去，可能是个 bug

import 'url-polyfill';
import React from 'react';
import dva from 'dva';
import ReactGA from 'react-ga';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import createLoading from 'dva-loading';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import CollectSDK from './utils/CollectSDK'

import './styles/index.less';
import RootRouter from './router';
import {
  getChannelString,
  webCommonCollect,
  webCustomCollect
} from 'utils/utils';

import { initFirebaseApp } from 'utils/initFirebase';

initFirebaseApp();

window.DSERS_GUIDE = true; // 新手引导
window.DSERS_SHOPIFY_GUIDE = true; // shopify 新手引导
window.DSERS_P_P = true; // paypal 灰度开关
window.DSERS_H_C = true; // help center
// window.DSERS_BARGAIN = true;
// window.DSERS_BANNER_V3 = true; // 广告位第三期
// window.DSERS_BARGAIN_LIST = {
//   open: false,
//   list: ['br7ikbv2drrg00e63lvg', 'bqa49860e4rllmptor3g']
//   // list: []
// };
// Supplier Optimizer灰度测试
window.DSER_SUPPLIER_OPTIMIZER = true;
window.DSERS_O_R = true; // 新老下单接口
window.DSERS_BIND_AE = true; // 必须绑ae
window.FORCE_INSTALL_EXT = false; // 强制装插件 目前需要下掉这个功能

window.DSERS_PRODUCT_CONTENT = false; // 部分用户 ‘Supplier Optimizer’显示为’Find similar‘
window.DSERS_INSTALL_REMAINDER = false; // 部分用户 提示安装插件文案

window.IS_UPDATE_PRODUCT = false;
window.IS_UPDATE_IMPORT_PRODUCT = false;

window.CHECK_AE_LOCAL_ACCOUNT_TEST = true // 下单时检测登录的AE账号是否匹配 

window.SWITCH_MAP_TYPE = false // 切换mapping方式检测mapping是否可以保存
window.NEW_INFO_TIME = 1683622800; //时间
// window.DSERS_OPTIMIZER_TEST = false; // 灰度测试 Supplier Optimizer 显示文案
// window.OPTIMIZE_GUIDE = false; // optimize新手引导

window.DSERS_COLLECT = webCommonCollect;
window.DSERS_CUSTOM_COLLECT = webCustomCollect;

//是否安装了插件
window.CHROME_PLUG_INSTALL = window.dsersExtVersion ? true : false;

// 判斷是不是移動端
const checkIsMobile = () => {
  if (
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i,
    )
  ) {
    window.IS_MOBILE = true;
  } else {
    window.IS_MOBILE = false;
  }
};
checkIsMobile();
//是否绑定了AE
window.IS_BIND_AE = null;

// https://85737b5f559a425ea14b5ae330a51b86@o470597.ingest.sentry.io/5501376

if (process.env.BUILD_TARGET === 'prod') {
  Sentry.init({
    dsn: 'https://52c5959b180a4545b054c3aff20d1c60@sentry.dsers.com/3',
    integrations: [new Integrations.BrowserTracing()],
    // eslint-disable-next-line
    release: 'dsers-web@' + process.env.SENTRY_RELEASE,

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      'Script error.',
      // Facebook borked
      'fb_xd_fragment',
      // Network errors such as going offline or being blocked by a proxy
      'Failed to fetch',
      'ResizeObserver loop limit exceeded',
      // firebase errors
      `FirebaseError`,
    ],
    denyUrls: [
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Ignore Google flakiness
      /\/(gtm|ga|analytics)\.js/i
    ],

    beforeSend(event, hint) {
      if (hint.originalException === 'Timeout') return null;
      return event;
    }
  });
}

//新增的打点
const config = {
  options:{
    baseUrl:`https://${process.env.BUILD_TARGET === 'prod' ? 'ac' : 'atc'}.dsers.com/api/v1/collect/web_normalize`,
    method:'POST'
  },
  app_info:{
    id:'dsers-app',
    version: '1.0.4',
  },
  debug:false,
  env:process.env.NODE_ENV,
};

window.dtag = new CollectSDK(config)


/**
 *  历史问题较多
 *  直接改用发布订阅模式传递数据，节省大量不必要代码
 */

let eventEmitter = {
  list: {},
  on (event, fn) {
      let _this = this;
      (_this.list[event] || (_this.list[event] = [])).push(fn);
      return _this;
  },
  once (event, fn) {
      let _this = this;
      function on () {
          _this.off(event, on);
          fn.apply(_this, arguments);
      }
      on.fn = fn;
      _this.on(event, on);
      return _this;
  },
  off (event, fn) {
      let _this = this;
      let fns = _this.list[event];
      if (!fns) return false;
      if (!fn) {
          fns && (fns.length = 0);
      } else {
          let cb;
          for (let i = 0, cbLen = fns.length; i < cbLen; i++) {
              cb = fns[i];
              if (cb === fn || cb.fn === fn) {
                  fns.splice(i, 1);
                  break
              }
          }
      }
      return _this;
  },
  emit () {
      let _this = this;
      let event = [].shift.call(arguments),
          fns = _this.list[event] || [];
      if (!fns || fns.length === 0) {
          return false;
      }
      fns.forEach(fn => {
          fn.apply(_this, arguments);
      });
      return _this;
  }
};

window.EVENT = eventEmitter;

// GA 统计配置
const DEFAULT_CONFIG = {
  trackingId: 'G-TV7D8SPFLE',
  debug: false,
  gaOptions: {
    cookieDomain: 'www.dsers.com'
  }
};

// 为了调试忽略线上环境
const dvaOption = {
  history: createBrowserHistory({ basename: '/app' }),
  state: {
    configs: [DEFAULT_CONFIG]
  }
};

// if (process.env.NODE_ENV === 'development') {
//   dvaOption.onAction = createLogger({
//     level: 'info',
//     collapsed: true
//   });
// }

const channelStr = getChannelString();
if (channelStr) {
  window.localStorage.setItem('chanel_str', JSON.stringify(channelStr));
} else {
  window.localStorage.removeItem('chanel_str');
}

window.addEventListener('load', () => {
  // 首次访问的用户 https://stackoverflow.com/questions/16892656/check-if-first-time-viewing-page
  if (
    typeof window.ga !== 'undefined' &&
    window.localStorage.getItem('FIRST_VISIT')
  ) {
    window.ga('set', 'dimension2', 'new');
    window.localStorage.removeItem('FIRST_VISIT');
  }
});

// https://stackoverflow.com/a/50855093/8818057
window.addEventListener(
  'error',
  e => {
    let message = e.message;
    if (e.error) {
      message = e.error.stack;
    }

    if (typeof window.ga !== 'undefined') {
      window.ga(
        'send',
        'event',
        'window.onerror',
        message,
        navigator.userAgent
      );
    }
    //&& perf.name.indexOf('dsers') == 0
    // 监控文件加载失败 
    // let result = window.performance.getEntries().filter(function(perf) {
    //   return (
    //     perf.initiatorType == 'script' &&
    //     /dsers/.test(perf.name) &&
    //     perf.decodedBodySize == 0
    //   );
    // });

    // if (result.length) {
    //   for (let i = 0; i < result.length; i++) {
    //     webCustomCollect({
    //       action: 'catch thunk error',
    //       custom_info: [
    //         {
    //           name: 'catch error',
    //           value: result[i].name
    //         }
    //       ]
    //     });
    //   }
    // }
  },
  true
);

var font = new FontFaceObserver('Material Icons', {
  weight: 400
});
font.load().then(
  function() {
    // 1. Initialize
    const app = dva(dvaOption);

    // ReactGA.initialize([DEFAULT_CONFIG]);

    if (!window.localStorage.getItem('_da')) {
      let da = uuidv4()
        .concat('_')
        .concat(window.localStorage.getItem('first_visit_time'));
      localStorage.setItem('_da', da);
    }

    window.DSERS_COLLECT({
      action: 'global tracking',
      custom_info: []
    });

    // 2. Plugins
    app.use(createLoading());

    // 3. Register global model
    app.model(require('./models/global').default);
    app.model(require('./models/user').default);
    app.model(require('./models/auth').default);
    app.model(require('./models/login').default);
    app.model(require('./models/register').default);
    app.model(require('./models/guidance').default);
    app.model(require('./models/topBtn').default);
    app.model(require('./models/dropshppersetting').default);
    app.model(require('./models/notification').default);
    app.model(require('./models/productmy').default);
    app.model(require('./models/partner').default);

    // 4. Router
    if (process.env.BUILD_TARGET === 'prod') {
      const WrapperRootRouter = Sentry.withErrorBoundary(RootRouter, {
        fallback: <div>an error has occurred</div>
      });
      app.router(props => <WrapperRootRouter {...props} />);
    } else {
      app.router(props => <RootRouter {...props} />);
    }

    // 5. Start
    app.start('#root');

    // when page is reloaded
    if (performance.navigation.type === 1) {
      console.log('reload_init');

      if (!Cookies.get('referrer')) {
        if (document.referrer) {
          Cookies.set('referrer', document.referrer, {
            expires: 3,
            path: '/'
          });
        }
      }

      sessionStorage.removeItem('shop_push_initial');
      sessionStorage.removeItem('ae_ck');
      sessionStorage.removeItem('h_d_extension');
    }

    let currentValue;
    // eslint-disable-next-line
    app._store.subscribe(() => {
      const previousValue = currentValue;
      // eslint-disable-next-line
      currentValue = app._store.getState().login.userInfo;

      if (previousValue !== currentValue) {
        if (currentValue && currentValue.user_id) {
          console.log('Sentry.setUser', currentValue.user_id);
          Sentry.setUser({ id: currentValue.user_id });
        } else {
          console.log('Sentry.setUser clear');
          Sentry.configureScope(scope => scope.setUser(null));
        }
      }
    });
  },
  function() {
    console.log('Font is not available');
  }
);

/**
 * 避免 hot 局部刷新导致数据清除失败  
 */
if(module.hot){
  module.hot.addStatusHandler(status => {
    // dispose	该进程正在调用将被替换模块的 dispose 处理函数
    if(status == 'dispose'){
      let subscribArr = Object.keys(window.EVENT.list)
      if(subscribArr.length){
        subscribArr.map(subscribeKey => {
          window.EVENT.off(subscribeKey)
        })
      }
    }
  })
}

export default {}; // eslint-disable-line
