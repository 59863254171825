
    export default 
    {
    "import_list": {
        "title": "Liste importieren",
        "dec": "Hier können Sie Ihre AliExpress-Produkte importieren und verwalten, bevor Sie sie in Ihrem Shopify-Shop veröffentlichen. Sie können ein Produkt in verschiedenen Shops veröffentlichen und mehrere Tags zu jedem Produkt hinzufügen. Klicken Sie <a href={href} target=\"_blank\">hier</a>, um mehr zu erfahren. Nachdem Sie Produkte in DSers importiert haben, werden nur der Preis und der AliExpress-Bestand des Produkts automatisch aktualisiert. Wenn Sie ein Produkt über einen längeren Zeitraum importiert haben, wird empfohlen, die Lieferanteninformationen zu überprüfen, bevor Sie das Produkt veröffentlichen, um die Konsistenz der Produktinformationen und des Lieferanten zu gewährleisten.",
        "search_ali_placeholder": "Importieren Sie AliExpress-Produkte per URL oder suchen Sie hier nach Produkten",
        search_tm_placeholder:'Importe produtos do Tmall pela URL ou pesquise produtos aqui',
        "add_tags": "Apply DSers Tags",
        "filter": "Filter",
        "filter_product": "Produkte filtern",
        "filter_by_stores": "Nach Geschäften filtern",
        "filter_by_tags": "Nach Tags filtern",
        "tag_management": "Create DSers Tags",
        "pagefly": "Möchten Sie mehr Verkäufe erzielen? Probieren Sie PageFly aus, um verkaufsstarke Produktseiten zu erstellen.",
        "pushed_to": "Gedrängt an",
        "store_s": "Speicher(n)",
        "add_manage_tags": "Create and manage DSers tags",
        "add_manage_tags_tip": "You can create or delete tags to group your products. Click <a href={ href } target=\"_blank\">here</a> to get more information.",
        "add_new_tags": "Neue Tags hinzufügen",
        "push_modal_title": "Push Products",
        "push_modal_desc": "Select the store(s) you want to push your products to and check the boxes below to select the rules to apply to your products.",
        "no_store": "Kein Geschäft",
        "pricing_rule_tip": "Wenn Sie den Produktpreis",
        "pricing_rule_tip_1": "Produktpreis ",
        "pricing_rule_tip_2": " auf DSers manuell bearbeitet haben, deaktivieren Sie bitte dieses Feld.",
        "pricing_rule_close_tip": "Unser System hat festgestellt, dass Sie den Preis für dieses Produkt auf DSers manuell bearbeitet haben. Daher wird die ",
        "pricing_rule_close_tip_1": " Preisregel",
        "pricing_rule_close_tip_2": " nicht auf dieses Produkt angewandt. Wenn Sie die Preisregel anstelle der bearbeiteten Preise verwenden möchten, aktivieren Sie bitte dieses Kästchen.",
        "from_oberlo_product_tip": "<text>Wir haben festgestellt, dass Sie ein Produkt zu Shopify übertragen haben, das von Oberlo zu DSers migriert wurde.</text> Infolgedessen wird die ",
        "from_oberlo_product_tip_1": " Preisregel ",
        "from_oberlo_product_tip_2": " nicht auf dieses Produkt angewendet. Wenn Sie die Preisregel anstelle der bearbeiteten Preise verwenden möchten, aktivieren Sie bitte dieses Feld.",
        "select_pricing_rule_mean_tip": "Bitte beachten Sie: Die Preisregel umfasst die Preisvorlagen, die Währung und die Zuweisung von Cents. Wenn Sie dieses Kontrollkästchen aktivieren, wird die Preisregel auf die Preise Ihrer Produkte gemäß den Preisregeln angewendet, die Sie in den Einstellungen festgelegt haben.",
        "unselect_pricing_rule_mean_tip": "Bitte beachten Sie: Die Preisregel umfasst Preisvorlagen, Währung und Cent-Zuordnung. Wenn Sie dieses Kontrollkästchen deaktivieren, wird keine dieser drei Funktionen auf Ihre Produkte angewendet.",
        "product_s": "Produkt(e)",
        "link_to_shopify": "Link zu Shopify",
        "no_product_tip": "Importieren Sie Ihren ersten Artikel",
        no_product_tip_1: `1. Gehen Sie zu <a href='/app/find_suppliers'>Lieferanten suchen</a> und kopieren Sie die URL des Artikels`,
        no_product_tip_2: '2. Fügen Sie die kopierte URL in das Eingabefeld der Importliste ein',
        search_no_product_tip: 'Keine Suchergebnisse, bitte ändern Sie die Suchkriterien und suchen Sie erneut',
        "tag_manage_delete_tip": "Möchten Sie dieses Tag löschen? <br /> Es wird von allen markierten Produkten verschwinden.",
        "add_product_tip": "Bitte verbinden Sie DSers mit einem AliExpress-Konto, sonst können Sie keine Produkte importieren.",
        "fetch_ae_prod_error": "Die Anzahl der Produkte, die Sie in DSers importiert haben, hat das Tageslimit erreicht. Bitte versuchen Sie es morgen noch einmal.",
        fetch_ae_prod_error1: `Die Anzahl der AliExpress-Produkte, die Sie auf DSers importiert haben, hat das Tageslimit erreicht. Bitte versuchen Sie es morgen erneut.`,
        fetch_tm_prod_error1: `Die Anzahl der Tmall-Produkte, die Sie zu DSers importiert haben, hat das tägliche Limit erreicht. Bitte versuchen Sie es morgen erneut.`,
        "ae_issue": "AliExpress hat einige Probleme, bitte versuchen Sie es später noch einmal",
        "delete_product_tip": "Sind Sie sicher, dass Sie das/die Produkt(e) löschen möchten? Es wird aus der Importliste gelöscht, aber nicht aus Ihrem Shopify-Shop.",
        "ae_prod_not_found": "Das Produkt ist auf AliExpress nicht verfügbar, bitte importieren Sie ein anderes Produkt.",
        "service_unavaliable": "Der Vorgang ist aufgrund eines Netzwerkproblems fehlgeschlagen. Bitte versuchen Sie es erneut.",
        "pushing": "Produkt wird versendet",
        "push_success": "Produkt erfolgreich versendet",
        "push_desc": "Produkte im Versand",
        "push_desc_ok": "Erfolgreich versendet",
        "import_review": "Bewertungen importieren",
        "push_desc_fail": "Der Versand ist fehlgeschlagen",
        "push_error_0": "Das Produkt enthält mehr als 100 SKUs",
        "push_error_1": "Maximale Anzahl der SKU-Erstellung auf Shopify erreicht",
        "push_error_2": "Shopify-Shop ist nicht verfügbar",
        "push_error_3": "Shopify-Token ist abgelaufen",
        "push_error_4": "Ihre Produktinformation überschreitet 256KB",
        push_error_4_tip: `Zu den Produktinformationen gehören Titel, Beschreibung, Bilder, SKU, usw. Es wird empfohlen, dass Sie einige Produktinformationen löschen, bevor Sie das Produkt verschieben`,
        "push_error_5": "Anfrage wurde abgebrochen, bitte versuchen Sie es später erneut",
        "check_push": "Überprüfen Sie den Status",
        "dec_change": "Hier können Sie die Produkte Ihrer Lieferanten importieren und verwalten, bevor Sie sie in Ihrem Shopify-Shop veröffentlichen.",
        "dec_change_tit": "Sie können ein Produkt in verschiedenen Shops veröffentlichen und zu jedem Produkt mehrere Tags hinzufügen. Nachdem Sie Produkte in DSers importiert haben, werden nur der Preis und der Bestand der Lieferantenprodukte automatisch in DSers aktualisiert. Wenn Sie ein Produkt über einen längeren Zeitraum importiert haben, sollten Sie die Lieferanteninformationen überprüfen, bevor Sie das Produkt veröffentlichen, um die Einheitlichkeit der Produktdaten und des Lieferanten zu gewährleisten.",
        "shopping_info": "Shipping info",
        "add_manage_tags2": "Apply DSers Tags",
        "add_management_tags": "You can apply tags to group your products.",
        "add_manage_tags_tip2": "You can add or delete tags to group your products.",
        "add_manage_tags_tip3": "You can filter your products by stores, tags and push status.",
        "push_success1": "Product(s) sent",
        "push_fail": "Der Versand ist fehlgeschlagen",
        "check_detail": "Produktdetail prüfen",
        "edit_detail": "Produktdetail bearbeiten",
        "delete_product": "Deleted Product",
        "addTag": "Create a new tag",
        "confim": "Confirm",
        "variants_limit": "Shopify daily variant creation limit reached. Please try again later & click <a href='{url}' target='_blank'>here</a> for more details.",
        "pushTitle_1": "Auswahl des Geschäfts",
        "pushTitle_2": "Präferenzen",
        "pushTitle_3": "Details verschieben",
        "repeated_tag": "This tag already existed",
        "search_or_import": "Importieren oder suchen",
        filter_by_market_place:'Nach Marktplätzen filtern',
    }
}