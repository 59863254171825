import React, { PureComponent } from 'react';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';
import { Spin, message, Modal, Button, Tooltip, Icon } from 'antd';
import intl from 'react-intl-universal';
import qs from 'qs';

import { detectPlatform } from 'services/login';
import { setAuthority } from '../../utils/authority';
import style from './callback.less';
import store from '../../assets/bind/store.png';
import wheel from '../../assets/bind/wheel.png';
import noPassword from '../../assets/img/noPassword.png';
import firstImg from '../../assets/img/first-group-img.png';
import secondImg from '../../assets/img/second-group-img.png';
import thirdImg from '../../assets/img/third-group-img.png';
import fourthImg from '../../assets/img/fourth-group-img.png';
import fifthImg from '../../assets/img/fifth-group-img.png';
import sixthImg from '../../assets/img/sixth-group-img.png';
import seventhImg from '../../assets/img/seventh-group-img.png';


import { webCommonCollect } from 'utils/utils';
import { Get, Post } from 'utils/request';
import { logoutMvpAccount } from 'utils/justifyMVPAccountUtils';

@connect()
export default class CallbackPage extends PureComponent {
  parms = null;

  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      shopName: '',
      timeOut: false,
      needActivate: false,
      showLand: false,
      noteModal: false,
      accountToken: '',
      callbackRes: {},
      overtimeModal: false,
      tryAgain:false,
      shopName_2:''
    };
  }

  componentDidMount() {

    // 到了callback页面 必定不是绑定到mvp 直接登出mvp（如果已登录的话）
    (async () => {
      await logoutMvpAccount();
      this.parms = qs.parse(location.search.slice(1));

      if (this.parms) {
        this.manageApp();

        if (localStorage.getItem('ifMigration')) {
          this.getShopifyCallback_2();
        } else {
          if (localStorage.getItem('dser-pro-authority')) {
            this.getShopifyCallback();
          } else {
            this.setState({
              showLand: true
            });
          }
        }
      }
    })(); 
  }

  /**
   *  install app 打点方法
   * @returns 
   */
  manageApp = async () => {
    const shop = this.getQueryString('shop') || '';
    let custom_info = [{name: 'shop_name',value: shop.substring(0, shop.indexOf(".myshopify.com"))}]

    const params = {
      action: 'install app',
      custom_info: custom_info
    }

    window.DSERS_CUSTOM_COLLECT && window.DSERS_CUSTOM_COLLECT(params);
  }


  /**
   * 
   * @param {*} name url处理方式
   * @returns 
   */
  getQueryString = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)",'i');
    var res = window.location.search.substr(1).match(reg);

    if(!res) return;

    if(res[2]){
      return res[2];
    }else{
        return null;
    }
  }

  handleCheckGa(){
    if(window.firstGroup){
      return '5.0'
    }else if(window.secondGroup){
      return "100s"
    }else if(window.thirdGroup){
      return "Optimizer"
    }else if(window.fourthGroup){
      return "MAPPING_1"
    }else if(window.fifthGroup){
      return "MAPPING_2"
    }else if(window.sixthGroup){
      return "Sync Price"
    }else if(window.seventhGroup){
      return "Find Supplier"
    }else if(window.noneGroup){
      return 'None'
    }
  }

  getShopifyCallback = () => {

    webCommonCollect({
      action:"createAccount",
      custom_info: [
        {
          name:'auto register',
          value: this.handleCheckGa()
        }
      ]
    })

    this.props.dispatch({
      type: 'auth/callback',
      payload: {
        data: {
          ...this.parms
        },
        cancelMessage: true,
        callback: d => {
          if (d.code === 2000 || d.code === 2010) {

            if(d.data){
              if(!d.data.is_success){
                this.setState({
                  overtimeModal: true
                })
                return;
              }
            }

            // 如果有登陆状态
            if (d.data?.login) {
              if (d.data?.token) setAuthority(d.data.token);
              // 需要检测平台
              detectPlatform({ data: { action_type: 1 } })
                .then(res => {
                  if (res && res.code === 2000) {
                    if (
                      res.data?.action_operation === 'wix' ||
                      res.data?.action_operation === 'ebay'
                    ) {
                      window.localStorage.removeItem('dser-pro-authority');
                      window.location.href = '/app/auth-error';
                    } else {
                      if (d.data?.msg.includes('DeadlineExceeded')) {
                        this.setState({
                          timeOut: true,
                          showLoading: false,
                          shopName: d.data.shop_name
                        });
                      } else {
                        this.setBackData(d.data);
                      }
                    }
                  } else {
                    if (d.data?.msg.includes('DeadlineExceeded')) {
                      this.setState({
                        timeOut: true,
                        showLoading: false,
                        shopName: d.data.shop_name
                      });
                    } else {
                      this.setBackData(d.data);
                    }
                  }
                  return res;
                })
                .catch(error => {
                  if (d.data?.msg.includes('DeadlineExceeded')) {
                    this.setState({
                      timeOut: true,
                      showLoading: false,
                      shopName: d.data.shop_name
                    });
                  } else {
                    this.setBackData(d.data);
                  }
                });
            } else {
              this.setState({
                showLoading: false,
                showLand: true,
                accountToken: d.data?.token,
                callbackRes: d
              },() => {
                this.createAccountCallBack();
              })
            }
          } else {
            // 接口错误判断
            if (
              d.msg ===
              'The store was previously hidden due to the plan limitation, please go to the susbcription page to activate the correspond store.'
            ) {
              this.setState({
                needActivate: true,
                showLoading: false
              });
              // message.error(d.msg, 4, () => {
              //   this.props.dispatch(routerRedux.push('/select'));
              // });
              // return;
            } else {
              this.setState({
                overtimeModal: true
              })
            }
          }
        }
      }
    });
  };

  getShopifyCallback_2 = async ()=>{
    const res = await Get('/auth/shopify/cb_mgr',{
        data:{
          ...this.parms
        }
      })
      if(res.code === 2000 || res.code === 2010){
        if(res.data){
          if(res.data.is_success){
            if(localStorage.getItem('dser-pro-authority')){
              //保存登出前email
              window.localStorage.setItem('logoutEmail',JSON.parse(localStorage.getItem('store'))?.email)
              //已登录 强制登出
              window.localStorage.removeItem('ds_host');
              window.localStorage.removeItem('dser-pro-authority');
              window.localStorage.removeItem('store');
              window.localStorage.removeItem('storeId');
              window.localStorage.removeItem('payment');
              window.localStorage.removeItem('keyStatus');
              window.localStorage.removeItem('pushCheckStoreId');
              window.localStorage.removeItem('keyStatus');
              sessionStorage.removeItem('newUser');
              window.localStorage.removeItem('newUserInfo')
              window.localStorage.removeItem('ifMigration')
    
              window.location.href = `/app/migrat-login?shopid=${res.data.mgr_store_id}&shopname=${res.data.shop_name}.myshopify.com`;
            }else{
              if(res.data.is_user_email == 1 && res.data.user_status == 1){
                window.localStorage.removeItem('ifMigration')
                window.location.href = `/app/migrat-login?shopemail=${res.data.sp_email}&shopid=${res.data.mgr_store_id}&shopname=${res.data.shop_name}.myshopify.com`;
              }else{
                window.localStorage.removeItem('ifMigration')
                window.location.href = `/app/migrat-register?shopemail=${res.data.sp_email}&shopid=${res.data.mgr_store_id}&shopname=${res.data.shop_name}.myshopify.com`;
              }

            }
          }else{
              this.setState({
                tryAgain:true,
                shopName_2:res.data.shop_name
              })
          }
        }
      }

  }

  setBackData = data => {
    let flag = true;
    if (data.bind_email !== data.current_user_email && !data.is_success) {
      flag = false;
      Modal.confirm({
        title: intl.get('h5_callback.bind_tip', {
          shop_name: data.shop_name,
          bind_email: data.bind_email,
          current_email: data.current_user_email
        }),
        onOk: () => {
          this.deleteBind();
        },
        onCancel: () => {
          window.location.href = '/app/';
        }
      });
    } else if (data.is_success) {
      window.location.href = '/app/';
      flag = false;
    }

    if (flag) {
      this.setState({
        authMsgFlag: true,
        showLoading: false,
        shopName: data.shop_name
      });
      // if (/shopify auth state is expired or not/g.test(data.msg)) {
      //   message.error(intl.get('h5_callback.bing_auth'));
      // } else {
      //   message.error(data.msg);
      // }
    }
  };


  // 删除绑定关系后redirect_url
  deleteBind = () => {
    this.props.dispatch({
      type: 'auth/unbind',
      payload: {
        data: {
          ...this.parms
        },
        cancelMessage: true,
        callback: d => {
          if (d.code === 2000) {
            this.getShopifyCallback();
          } else {
            message.error(d.msg);
          }
        }
      }
    });
  };

  createAccount = () => {
    this.getShopifyCallback();
  }

  createAccountCallBack = () => {
    const { accountToken, callbackRes } = this.state;
    
    // 已绑定账号
    if(callbackRes.msg === 'email has been registered, please sign in'){
      this.setState({
        noteModal: true
      })
      return;
    }

    // 未绑定账号通过token跳转后登陆
    if(accountToken){
      setAuthority(accountToken);
      window.location.href = '/app/';
    } else {
      message.error(callbackRes.data.msg)
    }
  }

  dispatch = action => {
    const { dispatch } = this.props;
    return dispatch(action);
  };

  // 再次验证商店
  authAgin = () => {
    const { shopName } = this.state;
    this.props.dispatch({
      type: 'auth/createShopfiy',
      payload: {
        data: {
          shop_name: shopName
        },
        callback: d => {
          this.setState({
            authMsgFlag: false
          });
          if (d.msg === 'bind store quota err') {
            localStorage.setItem('passivity', 'true');
            self.location.href = '/app/pricing';
          }
          if (d.code === 2000 || d.code === 2010) {
            window.location.href = d.data.url;
          }
          if (d.code == 4090 || d.msg == 'store has bound') {
            window.location.href = '/app/';
          }
        }
      }
    });
  };

  // 退出登录
  logout = () => {
    this.dispatch({
      type: 'login/logout'
    });
    this.setState({
      authMsgFlag: false
    });
  };

  register = () => {
    window.location.href = `/app/register?redirect_uri=${encodeURIComponent(window.location.href)}`;
  }

  windUp = (utiliserons) => {
    window.location.href = `/app/${utiliserons}`;
  }

  renderFooter = () => {
    return (
      <div>
        <Button className={style.signUp} onClick={() => this.windUp('register')}>{intl.get('h5_landModal.sign_up').toUpperCase()}</Button>
        <Button className={style.logIn} onClick={() => this.windUp('login')}>{intl.get('h5_landModal.log_in').toUpperCase()}</Button>
      </div>
    )
  }

  renderOvertimeFooter = () => {
    const shopUrlName = this.getQueryString('shop');

    if(!shopUrlName) {
      return (
        <Button
          className={style.logIn}
          onClick={() => {
            window.location.href = `https://apps.shopify.com/dsers`;
          }}
        >
          {intl.get('h5_landModal.return_shopify')}
        </Button>
      );
    }

    return (
      <Button className={style.logIn} onClick={() => {window.location.href=`https://${shopUrlName}/admin/apps`}}>{intl.get('h5_landModal.return_shopify')}</Button>
    )
  }

  handleTry = async ()=>{
    const response = await Post("/mgnoberlo-core-api/dsers-api/migration/auth/shopify/bind", {
      data: {
          shop_name: this.state.shopName_2
      }
  }, true)
  window.location.href = response.redirect_url;
  }

  renderTryAgainFooter = ()=>{
    return (
      <div>
        <Button className={style.signUp} onClick={()=>{ window.location.href = 'https://www.messenger.com/t/DSersOfficial'}}>{intl.get('migration.customerservice')}</Button>
        <Button className={style.logIn} onClick={this.handleTry}>{intl.get('migration.datacontinue')}</Button>
      </div>
    )
  }

  handleChangeImg(){
    if(window.firstGroup){
      return firstImg
    }else if(window.secondGroup){
      return secondImg
    }else if(window.thirdGroup){
      return thirdImg
    }else if(window.fourthGroup){
      return fourthImg
    }else if(window.fifthGroup){
      return fifthImg
    }else if(window.sixthGroup){
      return sixthImg
    }else if(window.seventhGroup){
      return seventhImg
    }else{
      return noPassword
    }
  }

  handleChangeText(){
    if(window.firstGroup){
      return intl.getHTML('h5_landModal.first_group')
    }else if(window.secondGroup){
      return intl.getHTML('h5_landModal.second_group')
    }else if(window.thirdGroup){
      return intl.getHTML('h5_landModal.third_group')
    }else if(window.fourthGroup){
      return intl.getHTML('h5_landModal.fourth_group')
    }else if(window.fifthGroup){
      return intl.getHTML('h5_landModal.fifth_group')
    }else if(window.sixthGroup){
      return intl.getHTML('h5_landModal.sixth_group')
    }else if(window.seventhGroup){
      return intl.getHTML('h5_landModal.seventh_group')
    }else{
      return null
    }
  }


  render() {
    const { showLoading, authMsgFlag, timeOut, needActivate, showLand, noteModal, overtimeModal, tryAgain } = this.state;
    const { dispatch } = this.props;
    return (
      <>
        {showLoading ? (
          <div className={style.loadingCon}>
            <div className={style.imgCon}>
              <div className={style.img}>
                <img src={store} className={style.store} />
                <img src={wheel} className={style.wheel} />
              </div>
            </div>
            <div className={style.text}>
              {intl.getHTML('linkStore.loading_Info')}
            </div>
          </div>
        ) : null}
        {
          showLand && (
            <div
              centered
              closable={false}
              footer={false}
              className={style.landModal}
            >
              {
                this.handleChangeText() ? (
                <>
                <div className={style.textModel}>
                  <p>{this.handleChangeText()}</p>
                </div>
                <div className={style.selfline}></div>
                </>
              ) : null
              }


              <div className={style.functionModel}>
                <img src={this.handleChangeImg()} />
                <div className={style.createBox}>
                  <div className={style.createAccount} onClick={this.createAccount}>
                    {intl.get('h5_landModal.create_account').toUpperCase()}
                  </div>
                  <div className={style.tooltip}>
                    <Tooltip placement="topLeft" title={intl.get('h5_landModal.welcome_con')}>
                      <Icon className={style.circle} type="question-circle" />
                    </Tooltip>
                  </div>
                </div>
                <div className={style.registerAccount} onClick={this.register}>{intl.get('h5_landModal.register').toUpperCase()}</div>
                <div className={style.textcontainer}>
                  <div>
                    {intl.get('h5_landModal.customer_service')}&nbsp;
                      <a href="https://www.messenger.com/t/DSersOfficial" dd="src">
                        {intl.get('h5_landModal.contact_us')}
                      </a>
                  </div>
                  <div>
                    By clicking Create Account, you agree to our&nbsp;
                    <a href="https://www.dsers.com/privacy/">Privacy Policy</a>
                  </div>
                  <div>
                    {intl.get('h5_landModal.existing_account')}&nbsp;
                    <a href={`/app/login?redirect_uri=${encodeURIComponent(window.location.href)}`}>
                      {intl.get('h5_landModal.log_in')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        {authMsgFlag && (
          <Modal visible footer={null} className={style.modal} closable={false}>
            <p>{intl.get('h5_callback.authAgin')}</p>
            <br />
            <div className={style.button}>
              <Button
                onClick={() => {
                  this.logout();
                }}
              >
                {intl.get('h5_callback.auth_log')}
              </Button>
              <Button
                onClick={() => {
                  this.authAgin();
                }}
                className={style.btn}
                type="primary"
              >
                {intl.get('h5_callback.auth_entry')}
              </Button>
            </div>
          </Modal>
        )}
        {timeOut && (
          <Modal visible footer={null} className={style.modal} closable={false}>
            <p>{intl.get('h5_callback.bind_time_out')}</p>
            <br />
            <div className={style.button}>
              <Button
                onClick={() => {
                  this.logout();
                }}
              >
                {intl.get('h5_callback.auth_log')}
              </Button>
              <Button
                onClick={() => {
                  this.authAgin();
                }}
                className={style.btn}
                type="primary"
              >
                {intl.get('h5_callback.relink')}
              </Button>
            </div>
          </Modal>
        )}
        {needActivate && (
          <Modal visible 
            footer={
              <div className={style.button}>
                <Button
                  onClick={() => {
                    this.logout();
                  }}
                >
                  {intl.get('h5_callback.auth_log')}
                </Button>
                <Button
                  onClick={() => {
                    dispatch(routerRedux.push('/select'));
                  }}
                  className={style.btn}
                  type="primary"
                >
                  {intl.get('h5_callback.activate')}
                </Button>
              </div>
            } 
          className={style.modal} closable={false}>
            <p style={{marginTop:24}}>{intl.get('h5_callback.need_activate')}</p>
            <br />
          </Modal>
        )}
        {noteModal && (
          <Modal 
            visible
            title="Note"
            closable={false}
            className={style.noteCon}
            footer={this.renderFooter()}
          >
            {intl.get('h5_landModal.note_con')}
          </Modal>
        )}
        {overtimeModal && (
          <Modal 
            visible
            title="Note"
            closable={false}
            className={style.noteCon}
            footer={this.renderOvertimeFooter()}
          >
            {intl.get('h5_landModal.overtime_con')}
          </Modal>
        )}
        {
          tryAgain && (
            <Modal
            visible
            closable={false}
            className={style.noteCon}
            bodyStyle={{minHeight:'100px',display:'flex',justifyContent:'center',alignItems:'center'}}
            footer={this.renderTryAgainFooter()}
            >
              {intl.get('migration.shoperror')}
            </Modal>
          )
        }
      </>
    );
  }
}
