import React, { useEffect } from 'react';
import { connect } from 'dva';
import { withRouter, routerRedux } from 'dva/router';

const AuthorizationListener = ({ dispatch, loginInfo }) => {
  useEffect(() => {
    if (loginInfo && dispatch) {
      const path = window.location.pathname;
      if (path === '/app/login') {
        dispatch(routerRedux.push('/'));
      }
    }
  }, [loginInfo, dispatch]);

  return null;
};

const mapStateToProps = ({ login }) => ({
  loginInfo: login.loginInfo
});

export default withRouter(connect(mapStateToProps)(AuthorizationListener));
