import agency from './agency';

    export default 
    {
    "setting": {
        "tab_account": "Cuenta",
        "tab_package_tracking": "Package Tracking",
        "tab_shipping": "Envío",
        "tab_tracking": "Seguimiento",
        "tab_notification": "Notificación",
        "tab_pricing_rule": "Reglamento de precios",
        "tab_billing_balance": "Saldo de facturación",
        "tab_verified_supplier": "Proveedor verificado",
        "tab_report": "Informe",
        "tab_other": "Otros",
        "choose_country_placeholder": "Seleccionar un país",
        "account": {
            "account_info": "Información de la cuenta",
            "account_name": "Nombre de la cuenta",
            "account_email": "Correo electrónico de la cuenta",
            "change": "Cambiar",
            "change_email_desc": "El correo electrónico es tu correo de inicio de sesión. Utilizaremos el correo electrónico para ponernos en contacto contigo sobre tu cuenta",
            "new_email": "Nueva dirección de correo electrónico",
            "new_email_desc": "Enviaremos un código de verificación a la nueva dirección de correo electrónico. Por favor, cópialo e introdúcelo a continuación para confirmar tu cuenta de correo",
            "new_email_err": "formato de correo electrónico incorrecto",
            "account_name_err": "Por favor, introduce un nombre de usuario de entre 5 y 20 caracteres",
            "verify_code": "Ingresa el código para verificar",
            "verify_code_desc": "Obtén el código de nuestro correo electrónico de verificación e introdúcelo aquí para que sea verificado",
            "verify_code_err": "Código incorrecto. Por favor, intenta de nuevo",
            "reset_password": "Restablecer contraseña",
            "reset_password_desc": "Cambia la contraseña que utilizas para iniciar sesión.",
            "change_password": "Cambiar contraseña",
            "shopify_stores": "Tiendas Shopify",
            "shopify_stores_desc": "Aquí puedes gestionar tus tiendas de Shopify. Si quieres desinstalar Dsers, por favor no olvides desvincularla desde aquí también.",
            "shopify_stores_re_authorized": "La tienda necesita ser reautorizada. Por favor, actualiza la autorización. De lo contrario, no podrás operar la tienda correctamente con Dsers",
            "shopify_stores_tip": "Hemos actualizado la autorización de Shopify. Por favor, haz clic para renovarla en la página de la aplicación de Shopify. De lo contrario, afectará al cumplimiento de los pedidos.",
            "store_name": "Nombre de la tienda",
            "status": "Estado",
            "connected": "Conectado",
            "disconnect": "Desconectar",
            "ae_account": "Cuenta de AliExpress",
            "ae_account_desc": "Aquí puede desconectar o cambiar la cuenta de AliExpress.",
            "account_id": "ID de la cuenta",
            "change_account_title": "Cambiar la tienda de facturación",
            "change_account_content": "Estás a punto de establecer {choiceLabel} como tienda de facturación. Para una mejor experiencia en la plataforma Dsers, asegúrate de que esta tienda esté siempre abierta.",
            "switch": "Cambiar",
            "ae_account_tip": "Ten en cuenta que si se vincula a una cuenta diferente, la información de los pedidos realizados en la cuenta actual no se actualizará en Dsers después de la desconexión.",
            "click_to_renew": "Haz clic para renovar",
            "disconnect_ae_tip1": "¿Estás seguro de que quieres desconectar tu cuenta de AliExpress? La información de los pedidos realizados con la cuenta actual ya no se actualizará en Dsers después de la desconexión.",
            "disconnect_ae_tip2": "Tras la desconexión, los datos de la cuenta desaparecerán temporalmente. Se recuperarán después de vincularla a una nueva cuenta de AliExpress.",
            "disconnect_ae_confirm": "Por favor, presiona CONFIRMAR para desvincular la cuenta.",
            "reconnect_ae": "Asegúrate de volver a conectarte a la misma cuenta. De lo contrario, la información de los pedidos realizados con la cuenta actual no se actualizará a Dsers después de la desconexión.",
            "switch_ae": "¿Estás seguro de que quieres cambiar de cuenta de AliExpress? La información de los pedidos realizados con la cuenta actual no se actualizará en Dsers después de la desconexión.",
            "open_mailbox": "Abrir buzón de mensajes",
            "send_again": "Enviar de nuevo",
            "send_code": "Enviar código",
            "verify": "Verificar",
            "store_name_tip": "Dsers mostrará el nombre original de la tienda cuando te registraste en Shopify y no actualizaremos el nombre de la tienda aunque lo hayas cambiado en Shopify. Por favor, ten en cuenta que el nombre de la tienda no será revelado a los clientes y no afectará a los pedidos en Dsers.",
            "verify_email_title": "Verifica tu dirección de correo electrónico",
            "verify_email_title_succ": "Correo electrónico de verificación enviado",
            "verify_email_title_fail": "Error en el envío del correo electrónico de verificación",
            "verify_email_desc": "Para ayudar a mantener tu cuenta segura, por favor verifica tu dirección de correo electrónico. Hemos enviado un correo electrónico de verificación al {email}",
            "verify_email_desc_succ": "Por favor, verifica tu dirección de correo electrónico siguiendo el link del correo enviado al {email}",
            "verify_send": "Enviar correo electrónico de verificación",
            "verify_re_send": "Reenviar correo electrónico de verificación",
            "white_list": "Lista blanca",
            "white_listed": "En lista blanca",
            "account_new_info": "Modificar nombre de cuenta",
            "noData": "No linked store",
            "changeEmailName": "Cambiar correo electrónico",
            "code_verify": "Code is required",
            "jiabai_intro": "Add your AliExpress account to the whitelist",
            "authorize_name": "Actualizaciones",
            "authorize_tips": "Actualizar permisos desde Shopify. Parece que los permisos de las funciones de DSers no están actualizados. Actualízalos en unos pocos clics para asegurarte de que DSers pueda funcionar correctamente."
        },
        "shipping": {
            "title": "Envío",
            "description": "Configura tu método de envío por defecto para un país específico.",
            "turn_off": "Desactivar",
            "turn_on": "Activar",
            "save_succ": "Guardado con éxito",
            "save_failure": "Guardado fallido",
            "advanced": {
                "title": "Avanzado",
                "description": "Configura tu método de envío predeterminado por rango de precios y rango de fechas de entrega.",
                "tracking_information_available": "Información de seguimiento Disponible",
                "delivery_period": "Plazo de entrega",
                "delivery_period_tip": "Período máximo de entrega aceptable",
                "delivery_period_error": "Por favor, introduce el número de días.",
                "days": "Días",
                "shipping_cost": "Coste de envío",
                "shipping_cost_error": "Por favor, ingresa el precio.",
                "tip": "Cuando varias opciones cumplen mis condiciones",
                "i_need": "Necesito",
                "less_cost": "menos coste",
                "less_day": "menos día"
            },
            "general": {
                "title": "Añadir tarjeta con método de envío global",
                "description": "Establece el método de envío por defecto para los países del mundo. El sistema de pedidos automático elegirá el método de envío de acuerdo con la configuración al realizar los pedidos. La opción 1 será tu primera opción.",
                "placeholder": "Añadir más métodos de envío",
                "option": "Opción"
            },
            "product": {
                "title": "Añadir método de envío global basado en el producto",
                "dec": "Configura tu método de envío por defecto para un producto específico de AliExpress. La configuración basada en el producto tiene mayor prioridad que la configuración basada en el país.",
                "placeholder": "Buscar por título del producto del proveedor",
                "modal_placeholder": "Buscar por palabras clave del título del producto del proveedor",
                "tip": "Sólo los productos ya asignados se pueden encontrar aquí",
                "shortcut_open": "Puedes tener un acceso rápido a esta función dirigiéndote a Mapeo - Más opciones"
            }
        },
        "tracking": {
            "title": "Seguimiento",
            "click_to_renew": "Haz clic para renovar",
            "renew_reminder": "Tu cuenta PayPal ha caducado. Para no afectar la sincronización de tus números de seguimiento, vuelve a autorizar tu cuenta PayPal",
            "description": "Activar el correo electrónico para la notificación de procesamiento por parte de una tienda diferente.",
            "activate_email": "Activar el correo electrónico para la notificación de procesamiento enviado por Shopify",
            "activate_email_description": " Activa esta opción para enviar el correo de confirmación de envío de Shopify automáticamente a tu cliente una vez que se haya generado el número de seguimiento. Puedes editar la plantilla del correo electrónico",
            "here": "aquí",
            "activate_email_tip": "Esta función ayudará a personalizar la compañía de envío y la uro de seguimiento en tu correo electrónico de procesamiento de Shopify.",
            "shipping_carrier": "Empresa de transporte",
            "custom_tracking_url": "Ural de seguimiento personalizada",
            "custom_tracking_url_tip": "Por favor, ten en cuenta que añadiremos dinámicamente un código de seguimiento al final de esta URL. Si deseas utilizar la URL http://www.17track.net/en/track?nums=1234567 introduce sólo http://www.17track.net/en/track?nums=",
            "please_enter_a_url": "Por favor, ingresa la uro de seguimiento.",
            "add_a_shopify_store": "Añadir una tienda de Shopify",
            "save_succ": "Guardar ajustes se completó con éxito.",
            "save_fail": "Guarda ajustes se completó de forma incorrecta.",
            "track_to_paypal_title": "Sincronizar el número de seguimiento con PayPal",
            "track_to_paypal_desc": "Autorizar a Dsers para añadir números de seguimiento a tu transacción en PayPal.",
            "connect_to_paypal": "Conectar con PayPal",
            "connect_to_paypal2": "Haz clic en el botón para conectarse con PayPal",
            "connect_to_paypal_desc": "Para garantizar que tu número de seguimiento se pueda sincronizar con normalidad, autoriza la vinculación de tu cuenta de PayPal con la tienda de Shopify",
            "not_succ": "La autorización no tuvo éxito.  <br /> Por favor, inténtalo de nuevo.",
            "use_paypal_account": "O utiliza una cuenta de PayPal válida:",
            "to_connect_paypal_account": "Select a PayPal account to connect to",
            "paypal_placeholder": "Selecciona una cuenta",
            "paypal_reminder": "Por favor, autoriza tu cuenta de PayPal arriba",
            "linked_paypal": "Estás vinculado a la cuenta de PayPal:",
            "disconnect": "Desconectar",
            "disconnect_tip": "¿Estás seguro de que quieres desconectar tu cuenta PayPal? Ten en cuenta que el número de seguimiento no se actualizará en la cuenta PayPal después de la desconexión.",
            "update": "Actualizar",
            "refresh_page": "Por favor, refresca la página para actualizar",
            "track_to_paypal_title1": "Gestionar cuenta PayPal",
            "select_1": "Select a shipping carrier",
            "select_2": "Select a custom tracking URL"
        },
        "notification": {
            "title": "Notificación",
            "description": "Las notificaciones que controlan los productos agotados y los cambios de precio se enviarán a tu cuenta Dsers si activas la función.",
            "inventory": "Inventario",
            "inventory_description": "Recibe una notificación cuando un producto esté agotado o ya no esté disponible",
            "price": "Precio",
            "price_description": "Recibe una notificación cuando el precio del proveedor haya cambiado en AliExpress",
            "canceled_order": "Pedido cancelado",
            "canceled_order_description": "Recibe una notificación cuando se cancele un pedido en AliExpress",
            "sku": "Cambios de SKU",
            "sku_description": "Recibe una notificación cuando un producto cambie el SKU en AliExpress",
            "order": "Pedido",
            "order_description": "Recibe una notificación cuando se cancele un pedido en AliExpress",
            "tip": "Las notificaciones de Dsers se activarán si marcas las casillas correspondientes y guardas después.",
            "auto_updates": "Actualizaciones automáticas",
            "auto_updates_tip": "Las actualizaciones no se aplicarán a los productos multiproveedor como los avanzados, los BOGO o el mapeo de paquetes",
            "out_of_stock": "Cuando un producto/variante esté agotado o ya no esté disponible, quieres que Dsers",
            "do_nothing": "No haga nada",
            "to_zero": "Establezca la cantidad a cero en Shopify",
            "inventory_change": "Cuando el inventario cambie, quieres que Dsers",
            "update_to_shopify": "Se actualice en Shopify automáticamente",
            "product_out": "Cuando un producto está fuera de stock o ya no está disponible, quieres que DSers",
            "variant_out": "Cuando una variante está fuera de stock o ya no está disponible, quieres que DSers",
            "set_as_draft": "Set as draft",
            "set_quantity_to_zero_on_Shopify": "Establecer cantidad a cero en Shopify",
            "remove_Variant": "Eliminar variante",
            "notification_feature": "Función de notificación",
            "filter_variant_out_of_stock": "Variante(s) fuera de stock",
            "cancelOrder": "Pedido cancelado",
            "clearFilter": "BORRAR UN FILTROS",
            "filterTitle": "Notificación de filtro",
            "defaultSupplier": "Proveedor por defecto",
            "secondSupplier": "Proveedor sustituto",
            "mappingPlaceholder": "Escoge un producto para volver a mapear"
        },
        "pricing": {
            "title": "Reglamento de precios",
            "description": "El reglamento de precios se aplicará automáticamente cuando empujes los productos de Dsers a Shopify. Haz clic <a href={href} target='_blank'>aquí para</a> obtener más información.",
            "enabled": "Habilitado",
            "disabled": "Deshabilitado",
            "pricing_cost": "Coste de los precios (USD)",
            "price": "Precio",
            "price_tip": "Por ejemplo, el precio de un producto que cuesta 10 USD se fijará en 12 o 20 USD (si añades 2 al coste, o lo multiplica por 2)",
            "compared_at_price": "Comparado con el precio",
            "compared_at_price_tip": "Por ejemplo, el precio comparado del producto que cuesta 10 USD se establecerá en 15 o 50 USD (si añades 5 al coste, o lo multiplica por 5).<br/> Si no seleccionas esta opción, el valor del precio comparado estará vacío cuando empujes los productos a tu tienda de Shopify.",
            "compared_ar": "Si no seleccionas esta opción, el valor del precio comparado estará vacío cuando empujes los productos a tu tienda de Shopify.",
            "price_no": "Si aplicas el precio comparado al empujar productos y luego desactivas esta opción, el precio comparado no se borrará cuando actualices manualmente el precio en la página de notificación de Dsers.",
            "set_rules": "Establece tus reglas de precios comparados",
            "basic": "Básico",
            "standard": "Estándar",
            "advanced": "Avanzado",
            "cost_range": "Rango de coste (USD)",
            "compared_price": "Precio comparado",
            "rest_price": "Resto de rangos de precios",
            "basic_more": "Haz clic <a href=\"https://help.dsers.com/basic-pricing-rule-for-shopify/\" target=\"_blank\">aquí para</a> obtener más información.",
            "formula_text_1": "Valor del precio = [(Coste del producto + Coste de envío) × (1+ % de ganancia) + Ganancia fija] / (1 - % de rentabilidad)",
            "formula_text_2": "Comparación de precios = Valor del precio × / ＋ tu número de ajuste",
            "formula_text_reminder": "PD: Si la Ganancia Final ((Coste del Producto + Coste de Envío) × Ganancia % + Ganancia Fija) es menor que la Ganancia Mínima establecida, entonces se aplicará la siguiente fórmula: Valor del Precio = [(Coste del Producto + Coste de Envío) + Ganancia Mínima] / (1 - % de Rentabilidad)",
            "advanced_rest_price": "Resto de rangos",
            "advanced_cost_range": "Rango de Coste del Producto (USD)",
            "profit": "beneficio%",
            "fixed_profit": "Beneficio fijo",
            "min_profit": "Beneficio mínimo",
            "breakeven": "Punto de equilibrio%.",
            "shippingCostReminder": "El coste de envío se calculará de acuerdo con el método de envío seleccionado por defecto en la configuración de costes de envío.",
            "breakevenReminder": "Establece un punto de equilibrio para cubrir las tasas del Canal, las tasas de pago u otras tasas que puedan tener",
            "shipping_cost": "Gastos de envío",
            "add_shipping_cost": "Añadir gastos de envío",
            "shipping_title": "Ajuste de los gastos de envío",
            "shipping_reminder": "Calcularemos el coste de envío según el método que selecciones. También se puede optar por introducir un promedio de gastos de envío específico si se envía a varios países.",
            "product_cost_setting": "Coste del Producto",
            "product_cost_settingVal": "El coste del producto se fijará de acuerdo con el precio nacional correspondiente al destino de \"Enviar a\" del producto. Para mayor información, por favor haz <a href=\"https://help.dsers.com/import-product-cost-for-shopify/\"  style=\"color:#3D5AFE\" >clic aquí.</a>",
            "product_cost_reminder": "Cuando importes el producto, calcularemos el coste del producto y notificaremos los cambios según el país que hayas seleccionado. Esto sólo tendrá efecto después de que lo hayas configurado, sin que los datos anteriores se modifiquen (poco a poco iremos dando soporte a más países).",
            "fixed_cost": "Coste de envío específico",
            "ranges_overlap": "Tus rangos se superponen.",
            "ranges_start_greater_end": "El valor final del rango de costes debe ser mayor o igual que el valor inicial.",
            "assign_cents": "Asignar céntimos",
            "assign_cents_description": "Puedes establecer un valor específico en céntimos para tu precio de venta al público. Utilizaremos este valor cuando preparemos el precio final de tus artículos (por ejemplo, si quieres que el coste de tu producto sea de XX.99 entonces añade 99 a los campos de abajo).",
            "assign_compared_at_cents": "Asignar comparaciones en céntimos",
            "need_link_store": "Necesitas vincularte a una tienda de Shopify primero.",
            "save_succ": "Guardado con éxito",
            "standard_more": "Haz clic <a href=\"https://help.dsers.com/standard-pricing-rule-for-shopify/\" target=\"_blank\">aquí</a> para obtener más información.",
            "advanced_more": "Haz clic <a href=\"https://help.dsers.com/advanced-pricing-rule-for-shopify/\" target=\"_blank\">aquí</a> para obtener más información.",
            "currency": "Monedas",
            "apply_go": "Apply currency rate to the pricing rules when you publish products to Shopify. If you want to activate this feature, please go to",
            "apply_to": "to set up currency for your stores",
            "apply_to_mes": "Aplicar tipo de cambio a las reglas de precios cuando se publiquen productos en Shopify. Las Reglas de Precios Globales funcionan de manera diferente si NO estás vendiendo en USD en Shopify y no quieres activar esta función. Calcula los multiplicadores correspondientes, por favor.",
            "apply_btn": "Setting - Stores",
            "bigTit": "The Price you set is higher than the Compared at price, please check and save",
            "inputerr100": "Value range: 0% to 99%",
            "basicpricerule": "Regla de precios básica",
            "standardpricerule": "Regla de precios estándar",
            "advancedpricerule": "Regla de precios avanzada",
            "setpricing": "Establecer detalles de las reglas de precios",
            "pricing_rule_new_title": "Plantillas de precios",
            "pricing_rule_new_dsec": "Las plantillas de precios permiten establecer fácilmente los precios de los productos. Selecciona la tienda a la que quieres aplicar las plantillas. Consulta y comprueba los detalles de la regla de precios para personalizar aún más los precios de tus productos."
        },
        "billing": {
            "activate_google_auth": "Te recomendamos encarecidamente que actives Google <br /> Auth para garantizar la seguridad de tu cuenta",
            "activate": "ACTIVAR",
            "remaining_credit": "Crédito restante",
            "make_a_payment": "EFECTUAR PAGO",
            "make_a_payment_tip": "Por favor, introduce tu dirección de facturación antes de poder utilizarla.",
            "last_payment": "{key, plural, =q1 {Your last payment was on} =q2 {for USD} =q3 {and is pending on PayPal.} =q4 {payment failed on PayPal. please check and payment again.} }",
            "transactions": "Transacciones",
            "date": "Fecha",
            "description": "Descripción",
            "amount": "Cantidad (USD)",
            "purchase_record": "Registro de compra",
            "download": "Descargar",
            "all_bills": "Todas las facturas",
            "save_succ": "guardado con éxito",
            "save_error": "error al guardar",
            "google_auth": {
                "step1": "Paso 1: Descargar Google Authenticator",
                "step1_description": "El usuario IOS debe acceder a la AppStore para buscar Google Authenticator, y el usuario Android debe acceder a la tienda de aplicaciones o utilizar el navegador del móvil para buscar la descarga de Google Authenticator.",
                "step2": "Paso 2: Vincular a Google Authenticator",
                "scan_qr_code": "IOS/Android: Escanea el código QR",
                "code": "Si el escaneo del código no funciona, puedes introducir el código de verificación.",
                "please_save": "Se utiliza para recuperar golpe auto, por favor, guarda la información."
            },
            "billing": {
                "billing_information": "Información de facturación",
                "business_name": "Nombre de la Compañía",
                "business_name_error": "Por favor, introduce un nombre",
                "email": "Correo electrónico",
                "email_not_valid": "¡La entrada no es un correo electrónico válido!",
                "email_null_error": "Por favor, introduce el correo electrónico.",
                "phone": "Teléfono",
                "phone_error": "Por favor, introduce un nombre",
                "city": "Ciudad",
                "city_error": "Por favor, introduce una ciudad",
                "currency": "Moneda",
                "currency_error": "Por favor, introduce un nombre",
                "country": "País",
                "country_error": "Por favor, introduce un país",
                "address": "Dirección",
                "address_error": "Por favor, introduce una dirección",
                "post_code": "Código postal",
                "province": "Provincia",
                "province_error": "Por favor, introduce una provincia",
                "company": "Compañía",
                "send_this_address": "Enviar el producto de muestra a esta dirección"
            },
            "primary": {
                "title": "Contacto principal",
                "name": "Nombre",
                "name_error": "Por favor, introduce un nombre",
                "use_billing_address": "Utilizar como dirección de facturación",
                "send_sample_to_this_address": "Enviar producto de muestra a esta dirección"
            }
        },
        "verified": {
            "title": "Proveedor verificado",
            "img_max_1mb": "¡La imagen debe ser menor de 1MB!",
            "bag_default": "Bolsa (por defecto y gratis)",
            "bag_box": "Caja ($0.2/paquete)",
            "bag_box_logo": "Caja con logotipo ($0.2/paquete)",
            "bag_box_card": "Caja con tarjeta ($0.2/paquete)",
            "bag_box_logo_card": "Caja con logotipo y tarjeta ($0.2/paquete)",
            "upload": "Cargar",
            "package_request": "Solicitud de paquete",
            "logo_file": "Archivo del logotipo",
            "note": "Nota"
        },
        "report": {
            "timezone": {
                "title": "Zona horaria",
                "desc": "Tu zona horaria por defecto se basa en la configuración de tu navegador. Puedes volver a seleccionar una zona horaria aquí para tu cuenta Dsers. PERO ten en cuenta que sólo puedes configurarla una vez y no se puede modificar después de guardarla.",
                "save_modal_desc": "Sólo puedes configurar la zona horaria una vez. Por favor, asegúrate de que hayas seleccionado la zona horaria correcta. No se puede cambiar después de haberla configurado.",
                "save_modal_tip": "Por favor, presiona CONFIRMAR para guardar."
            },
            "currencies": {
                "title": "Moneda",
                "desc": "Se utilizará el USD como moneda por defecto en Dsers. Puedes configurar esta opción para cada tienda y la moneda que hayas seleccionado se aplicará a los productos y pedidos de AliExpress en Dsers. Los datos que se muestran en Dsers pueden ser diferentes de los que se ven en Shopify o AliExpress debido a los cambios en los tipos de cambio. Sólo puedes configurar tu moneda una vez para cada tienda. Si necesitas modificar la moneda más adelante, ponte en <a href=\"mailto:support@dserspro.com\">contacto con el servicio</a> de atención al cliente.",
                "store": "Tienda",
                "currency": "Moneda",
                "no_store_tip": "Por favor, vincula a una tienda para configurarla.",
                "save_modal_title": "Sólo puedes modificar la moneda una vez.",
                "save_modal_desc": "Ten en cuenta que sólo puedes modificar la moneda una vez. Por favor, presiona CONFIRMAR a continuación para guardar los ajustes.",
                "input_confirm": "Por favor, presiona CONFIRMAR"
            }
        },
        "other": {
            "default_phone_number_placeholder": "Selecciona un país",
            "phone_rules_SA_AE_IL": "requiere números de 9 dígitos que empiecen por 5",
            "phone_rules_FR": "requiere números telefónicos de 10 dígitos.",
            "phone_rules_PT": "requiere números de 9 o 10 dígitos",
            "phone_rules_BR_JP": "requiere números de 10 u 11 dígitos",
            "phone_rules_KR": "Please enter 10 or 11-digit phone number starting with 01 or 1 (e.g. 01012345678)",
            "sync_ali_order_number": "Sincronizar el número de pedido Ali con los detalles adicionales de Shopify",
            "sync_ali_order_number_tip": "Si esta opción está activada, tu número de pedido Ali se sincronizará automáticamente con la página de detalles del pedido de Shopify.",
            "canceled_orders_optimization": "Optimización de pedidos cancelados.",
            "canceled_orders_optimization_tip": "Activa esta función para optimizar automáticamente un pedido cancelado por AliExpress. La optimización por parte de Dsers reducirá en gran medida la probabilidad de que el pedido sea cancelado de nuevo.",
            "synchronize_shopify_order_notes": "Sincronizar notas de pedido de Shopify",
            "synchronize_shopify_order_notes_tip": "Activate this feature to automatically synchronize Shopify order notes. DSers will help to send the notes of an order from Shopify to AliExpress along with orders.",
            synchronize_shopify_order_message: "Synchronize Shopify order customization information",
            synchronize_shopify_order_message_tip:"Activating this feature automatically synchronize Shopify order customization information. DSers will help you send the customization information from Shopify to suppliers along with the order.",      
            "leave_a_meaage": "Dejar un mensaje a los proveedores",
            "leave_a_meaage_tip": "Los proveedores recibirán este mensaje junto con la información de tu pedido.",
            "message_placeholder": "Introduce un máximo de 1000 caracteres (o 250 caracteres chinos)",
            "ioss_message": "IOSS",
            "ioss_message_tip": "Si quieres pagar el IVA tú mismo en lugar de hacerlo a través de AliExpress, tienes que añadir un ID IOSS a la información del comprador de tus pedidos de AliExpress enviados a la UE.",
            "ioss_message_tip_2": "Por favor, solicita a los vendedores de AliExpress que añadan también el IOSS ID al pedido y que proporcionen el IOSS ID al proveedor de envíos, para que los paquetes puedan pasar por la aduana con normalidad. Es muy importante que te pongas en contacto con los vendedores para asegurarte de que se encargue de cumplir con este paso.",
            "ioss_message_info": "(Tu cuenta de AliExpress necesita ser verificada como Comprador Comercial para poder añadir un número de IVA en AliExpress, y luego declarar y pagar el IVA por ti mismo.)<br/> El IOSS ID que establezcas se añadirá automáticamente a la Dirección 2 de los pedidos de AliExpress cuando los proceses a AliExpress. Si modificas el IOSS ID aquí, el nuevo ID sólo se aplicará a los nuevos pedidos procesados a AliExpress.",
            "ioss_message_error": "Por favor, introduce un ID de IOSS correcto para evitar que los pedidos a AliExpress no se procesen o que los paquetes sean detenidos por la aduana.",
            "default_phone_number": "Establecer número telefónico por defecto",
            "default_phone_number_tip": "Si un pedido no incluye un número telefónico, el sistema utilizará el número telefónico por defecto que hayas establecido anteriormente",
            "override_phone_number": "Anular número telefónico",
            "override_phone_number_uae": "Para los pedidos enviados a Arabia Saudita, Emiratos Árabes Unidos e Israel, se requieren números de 9 dígitos que comiencen por 5.",
            "override_phone_number_fr": "Para los pedidos enviados a Francia, se requieren números telefónicos de 10 dígitos que comiencen por 06.",
            "override_phone_number_pt": "Para los pedidos enviados a Portugal, se requieren números de 9 o 10 dígitos.",
            "override_phone_number_br": "Para los pedidos enviados a Brasil, se requieren números de 10 u 11 dígitos.",
            "override_phone_number_jp": "Para los pedidos enviados a Japón, se requieren números de 10 u 11 dígitos.",
            "override_phone_number_ko": "Para los pedidos enviados a Corea, se requieren números de 10 u 11 dígitos que empiecen por 01 o 1.",
            "override_phone_number_intro_start": "Por favor, ten en cuenta lo siguiente:",
            "override_phone_number_intro_end": "Por favor, completa los requerimientos como corresponde.",
            "override_phone_number_tip": "Sustituye el número de teléfono del cliente por su propio número al realizar el pedido a los proveedores.",
            "france_phone_number_tip": "Por favor, ten en cuenta que para los pedidos enviados a Arabia Saudita, Emiratos Árabes Unidos, se requieren números de 9 dígitos que empiecen por 5. Para los pedidos enviados a Francia, se requieren números telefónicos de 10 dígitos que empiecen por 06 o 07. Por favor, completa este campo como corresponde.",
            "sync_shopify_orders": "Sincronizar pedidos de Shopify",
            "sync_shopify_orders_tip": "Dsers sincronizará los pedidos de los últimos 7 días por defecto. Selecciona un rango de fechas para sincronizar los pedidos que desees. Puedes sincronizar hasta 30 días al mismo tiempo.",
            "sync_shopify_orders_error": "Puedes sincronizar hasta 30 días al mismo tiempo.",
            "select_store_placeholder": "Seleccionar tienda",
            "sync": "Sincronizar",
            "affiliate_program": "Programa de afiliados",
            "affiliate": "Afiliado",
            "affiliate_desc": "Has activado tu cashback de Admitad",
            "change_code": "Modificar código",
            "change_code_wraming": "¿Estás seguro de borrar el código actual?",
            "step": "Paso",
            "new_affiliate_tip": "Registra una NUEVA cuenta Admitad a través del siguiente enlace",
            "why_affiliate": "¿Por qué necesito una nueva cuenta Admitad?",
            "get_affiliate_btn": "Crear cuenta Admitad",
            "set_affiliate": "Configurar tu cuenta Admitad.",
            "how_to_set": "¿Cómo se configura?",
            "fill_affiliate": "Introduce tu código de afiliado y el nombre de la cuenta.",
            "where_code": "¿Dónde encontrar el código y el nombre de la cuenta?",
            "code": "Código",
            "err_tip": "Este no es un código de afiliación de Dsers.",
            "message_ae_sellers": "El mensaje se ha guardado correctamente y los proveedores lo recibirán cuando se realicen los pedidos.",
            "save_phone_succ": "Número telefónico guardado con éxito.",
            "cash_back_code_error": "Este no es un código válido. El código debe ser una combinación de 30 letras y números. Por favor, revisa el código.",
            "cost_error": "Por favor, introduce un número con el formato 5 o 5.99",
            "create_success": "Creado con éxito",
            "admitad_stopping_now": "Tu cashback de Admitad se está deteniendo ahora. Por favor, cambia el nuevo código asignado a Dsers. <a href={href} target=\"_blank\">Revisa por qué y cómo</a>",
            "notice": "Nuestro sistema ha detectado que tu cuenta Admitad no puede ser verificada.<br /> Por favor, asegúrate de haber creado la cuenta de Admitad a través de &nbsp; <a\n\t\t\thref=\"https://store.admitad.com/en/promo/?ref=8e3xmmxnjp\"\n\t\t\ttarget=\"_blank\"\n\t\t\trel=\"noopener noreferrer\"\n\t\t\t>Enlace de referencia Dsers</a> &nbsp; y luego cambia tu código en Dsers.<br /> Si su cuenta de Admitad fue registrada a través de &nbsp; <a\n\t\t\thref=\"https://store.admitad.com/en/promo/?ref=8e3xmmxnjp\"\n\t\t\ttarget=\"_blank\"\n\t\t\trel=\"noopener noreferrer\"\n\t\t\t>Dsers enlace de referencia</a> pero todavía no puede ser verificada, por favor, comprueba que hayas introducido el nombre correcto de la cuenta en Dsers.",
            "notice_sub": "Por favor, ten en cuenta que la cuenta actual Admitad se desactivará después de 48 horas.",
            "notice_add_title": "About Admitad Account",
            "notice_2": "Para asegurarse de que la cuenta Admitad funciona bien con Dsers, por favor dirígete a Ajustes - Otros - Programa de afiliación para terminar la activación. De lo contrario, tu código Admitad se desactivará después de 48 horas.",
            "not_account_name": "Por favor, introduce el nombre de tu cuenta Admitad.",
            "notice_button_change": "Modificar nombre de cuenta",
            "notice_button_register": "Registrarse ahora",
            "cancel_fulfillment_title": "Solicitar la cancelación del Procesamiento",
            "cancel_fulfillment_desc": "Después de activar esta función de la aplicación correspondiente a continuación, los pedidos que no se puedan efectuar desde la aplicación porque hayas hecho clic en Solicitar Procesamiento en Shopify se restaurarán para que puedas realizarlos normalmente a AliExpress.",
            "aliSku": "Utilizar ID del proveedor como SKU del producto",
            "aliSku_desc": "Después de la activación, cuando importes productos de proveedores en la Lista de Importación, el SKU del producto utilizará el nombre de las variantes-ID del producto del proveedor.",
            "vat_title": "Cuando el precio de un pedido a Francia supere los 150 euros, es necesario que proporciones tu ID de IVA y el nombre de tu compañía. Por favor, completa en el siguiente orden para no afectar el proceso normal. Haz clic <a target=\"_blank\" href=\"https://www.blog.dserspro.com/post/orders-to-france-specifications-with-shopify-dsers\">aquí para</a> obtener más información.",
            "vat_id_verify": "Por favor, introduce los 8-12 dígitos del CIF",
            "vat_input_verify": "Por favor, introduce el CIF",
            "vat_company": "Por favor, introduce el nombre de la compañía",
            "vat_h3": "IVA",
            "plan_info_id": "CIF",
            "plan_info_name": "Nombre de la compañía",
            "phone_rules_IT": "It requires 9 or 10 digits mobile phone numbers.",
            "set_product_title": "Establecer productos como sujetos a impuestos",
            "set_product_subtitle": "Especifica si se cobra o no un impuesto cuando se vende la variante del producto.",
            "ioss_message_more": "Haz clic <a href=\"https://www.dsers.com/blog/eu-vat-reform/\" target=\"_blank\">aquí</a> para obtener más información...",
            "override_phone_number_it": "For orders sent to Italy, it requires 9 or 10 digits numbers.",
            "override_phone_number_intro_start_new": "Ten en cuenta que:",
            "override_phone_number_first": "se requiere un número de 9 dígitos que empiece por 5.",
            "override_phone_number_second": "se requiere un número de 9 dígitos que empiece por 5 o un número de 10 dígitos que empiece por 0.",
            "override_phone_number_third": "se requiere un número de 9 o 10 dígitos.",
            "override_phone_number_fourth": "se requiere un número de 10 u 11 dígitos.",
            "override_phone_number_fifth": "se requiere un número de 10 u 11 dígitos que empiece por 01 o 1.",
            "phone_rules_SA_AE_IL_new": "Requiere un número de 9 dígitos que empiece por 5 o un número de 10 dígitos que empiece por 0.",
            "phone_rules_FR_new": "Requiere un número de teléfono de 9 o 10 dígitos.",
            "order_save": "SAVE",
            "order_fulfillment_module": "Ajustes de Cumplimiento",
            "order_fulfillment_first_title": "Procesar artículos por separado",
            "order_fulfillment_first_content": "Después de activar esta función, DSers procesará cada producto en los pedidos por separado y activará el correo electrónico de confirmación de envío de Shopify con las siguientes normas: Cuando un producto en un pedido de DSers tenga un número de seguimiento, procesaremos ese artículo en el pedido y luego Shopify enviará un correo electrónico a tu cliente. Shopify enviará correo electrónico varias veces para el mismo pedido.",
            "order_fulfillment_second_title": "Procesar artículos masivamente",
            "order_fulfillment_second_content": "Después de activar esta función, DSers procesará un pedido solo después de que todos los productos del pedido obtengan números de seguimiento y activen Shopify, con el fin de enviar solo un correo electrónico a tu cliente.",
            "order_split_pop_title": "Nota",
            "order_split_pop_content": "Para seguir mejorando la estabilidad de DSers y la experiencia de nuestros comerciantes, si desactivas esta función, dejará de ser accesible. ¿Estás seguro de que quieres dejar de usar esta función?",
            "order_split_pop_btn": "CONFIRMAR"
            },
        "payment": {
            'top_title':"Información Comercial",
            'top_small_title': 'Utilizado en los resúmenes de pedidos de Tmall .',
            'top_form_item1': 'Nombre',
            'top_form_item2': 'Apellido',
            'top_form_item3': 'Nombre de la empresa',
            'top_form_item4': 'Correo electrónico',
            'top_form_item5': 'País',
            'top_form_item6': 'Dirección',
            'top_form_item7': 'Apartamento, suite, etc.',
            'top_form_item8': 'Ciudad',
            'top_form_item9': 'Estado',
            'top_form_item10': 'Código postal',  
            'bottom_title': "Resúmenes de Tmall",
            'bottom_small_title':"Verifica y descarga el resumen mensual de los pedidos de Tmall. (Actualizar el resumen del mes anterior en la x de cada mes)",
            'bottom_state1': "Solicitud",
            'bottom_state2':"En la solicitud" 
        },
        "tab_sub_account": "Cuenta de Personal",
        "tab_sub_supplier":'Proveedor',
        "tab_stores": "Tiendas",
        "tab_product": "Product",
        "tab_order": "Order",
        "tab_paypal": "PayPal",
        "tab_sync": "Auto Update",
        "tab_aff": "Programa de afiliados",
        "priceUpdate": {
            "suppliers_price": "Cuando el precio de tu proveedor ",
            "increase": "aumentar",
            "decrease": "disminuir",
            "pricing_change": "Y el cambio de precio exceda",
            "anychange": "Cualquier cambio",
            "title": "Actualización automática de precios",
            "auto_updates_tip": "After you turn on the automatic price update feature, DSers will track the changes in the supplier's product cost, and automatically synchronize the updated price to Shopify according to the pricing rule you set",
            "note": "Please note: You need to have the Pricing Rule feature activated to use this feature. If you change the price in Shopify, we will still update the price on Shopify according to the pricing rule you set on DSers. Update range: 10 days of ordered products & browsed products",
            "supplier": "Cuando el proveedor modifique el precio, quieres que DSers:",
            "updateCon": "Actualice el precio y lo compare con la tienda de Shopify ",
            "do_nothing": "No haga nada",
            "to_shopify": "Actualizar el precio a la tienda de Shopify",
            "cost_change": "Update price when the cost changes",
            "to_setting": "Ir a configuración y activarlo",
            "to_price": "This store does not have pricing rules",
            "check_error": "Please check the price fluctuation method",
            "range": "The range cannot be 0",
            "priceError": "The store is disconnected, please go to Setting - Account to reconnect it"
        },
        "order": {
            "input_placeholder": "Enter the product's AliExpress link for which you want to split orders",
            "order_same": "The product link, quantity and country you configured are exactly the same, please check and save",
            "title": "Dividir pedido automático",
            "desc": "Dividiremos el pedido según el enlace del producto AliExpress y la cantidad de productos que hayas establecido. Reglas de división de pedidos: cuando el producto del pedido es del proveedor que estableciste, y la cantidad del producto es mayor o igual a la cantidad establecida en los ajustes, que debe ser menor que 10, dividiremos automáticamente el pedido en varios pedidos de Shopify.",
            "inputNumber": "Please enter the amount you want to split",
            "incorrect": "The product link you entered is incorrect",
            "banner_title": "Automatically split orders",
            "banner_text": "Having issue when using cost-effective AliExpress Saver Shipping? Check this solution.",
            "banner_btn": "learn more",
            "titip": "Order",
            "delivery_period_tip": "Mínimo 2, máximo 10",
            "delivery_period": "Cantidad de productos en pedidos para dividir",
            "split_ending": "Splitting orders",
            "delete_dsers": "This product had been deleted in DSers"
        },
        "subaccount": {
            "add": "Añadir cuenta de personal",
            "sendemail": "Te enviaremos un correo electrónico a la dirección que indicaste para completar la autorización, lo que permitirá a tus empleados iniciar sesión en DSers a través de esta cuenta ",
            "admintext": "Todos los permisos, incluidos los de administrador ",
            "fulltext": "Todas las páginas y funciones, pero sin permisos de administrador ",
            "limittext": "Mostrar al propietario según la página y la tienda que hayas configurado ",
            "emailempty": "Please input the email address",
            "emailformat": "Por favor, introduce una dirección de correo electrónico válida ",
            "active": "Activo",
            "NoAccess": "No page access",
            "NoAccessTitle": "You do not have access to the current page, please contact your administrator",
            "report": "Informe",
            "ImportList": "Lista de importación",
            "MyProduct": "Mis productos",
            "Openorder": "Abrir pedido",
            "ArchivedOrder": "Pedidos archivados",
            "Tracking": "Seguimiento",
            "CSVBulkOrder": "Pedido masivo CSV",
            "LinktoMoreStores": "Link a más tiendas",
            "Setting": "Ajustes",
            "Notification": "Notificación",
            "fullaccess": "Acceso completo",
            "admin": "Administrador",
            "Limitedaccess": "Acceso limitado",
            "Full": "Completo",
            "Storeaccess": "Acceso a la tienda",
            "Featureaccess": "Acceso a las características",
            "invited": "invitado",
            "Notconnected": "No conectado"
        },
        "product": {
            "customize_title": "Personalizar la descripción del producto",
            "customize_con": "Al empujar los productos, deseas que DSers:",
            shipping_cost_title:'Enviar información del producto a Shopify',
            "customize_specifications": "Envíar sólo las ESPECIFICACIONES de los productos",
            "customize_overview": "Envíar sólo la VISTA GENERAL del producto",
            "customize_all": "Push both SPECIFICATIONS & OVERVIEW",
            "location_title": "Product fulfillment service management",
            "location_content": "Puedes decidir qué servicio de cumplimiento de productos y pedidos te ayudará a gestionar la plataforma DSers a través del switch de servicio de cumplimiento. Si activas el switch, te ayudará a actualizar automáticamente la información del producto correspondiente al servicio de cumplimiento y a mostrar los pedidos correspondientes a ese servicio de cumplimiento normalmente. Si lo desactivas, no se actualizará automáticamente la información del producto correspondiente al servicio de cumplimiento para ti, y no se mostrarán los pedidos correspondientes a ese servicio de cumplimiento.",
            shipping_cost_tip1:'Envíe el coste de los productos del proveedor por artículo a Shopify al empujar el producto.',
            shipping_cost_tip2:`Cuando se empuja el producto, el coste de los productos del proveedor y el coste de envío en [Regla de Precios Avanzada] se devuelven juntos al coste por artículo de Shopify.
            Es posible que haya algunas razones por las que el coste de envío sea 0:<br />
            1. Después de aplicar la Regla de Precios Avanzada, el método de envío coincidente según el país es gratuito, y el coste de envío devuelto a Shopify es 0 en este momento.<br />
            2. Después de aplicar la Regla de Precios Avanzada, ningún método de envío coincide según el país. En este momento, el coste de envío devuelto a Shopify es 0.<br />
            3. Después de aplicar la Regla de Precios Básica/Estándar, no hay ningún coste de envío, y el coste de envío devuelto a Shopify es 0.<br />
            4. La regla de precios no está activada, y no hay gastos de envío. En este momento, el costo de envío devuelto a Shopify es 0.<br />
            Necesitas actualizar tu plan y aplicar la regla de precios avanzada antes de que DSers pueda ayudarte a devolver el coste del producto y el coste de envío a Shopify.`
        },
        "product_price": "Actualizar precio del producto",
        "title_account": "Account",
        "title_time_zone": "Zona horaria",
        "time_zone_des": "Tu zona horaria por defecto se basa en la configuración de tu navegador. Puedes volver a seleccionar una zona horaria aquí para tu cuenta DSers. SIN EMBARGO, ten en cuenta que sólo puedes configurarla una vez y que no se puede cambiar después de guardarla. ",
        "title_ae_account": "Cuenta de AliExpress",
        "ae_account_des": "Por favor, ten en cuenta que si te vinculas a una cuenta diferente, la información de los pedidos realizados en la cuenta actual ya no se actualizará en DSers después de desconectarse.",
        "title_synchronization": "Sincronización",
        "title_automatic": "Actualización automática de precios",
        "automatic_des": "Después de activar la función de actualización automática de precios, DSers sincronizará automáticamente el precio actualizado a Shopify, de acuerdo con el reglamento de precios que establezcas, cada 24 horas si el proveedor cambia el precio por más de $1.",
        "automatic_des2": "Please note: You need to have the Pricing Rule feature activated to use this feature. If you change the price in Shopify, we will update the price on Shopify according to the set pricing rule you on DSers.",
        "title_update_pro_pr": "Cambiar manualmente el precio de los productos de la tienda ",
        "update_pro_pr_des": "Actualiza el precio de los productos empujados a tu tienda de Shopify de acuerdo con las reglas de precios que actualmente hayas establecido. El proceso puede llevar algún tiempo. Esta función se puede utilizar cada 30 días.",
        "title_sync_shop_order": "Sincronizar pedidos de Shopify",
        "sync_shop_order_des": "DSers sincronizará los pedidos de los últimos 7 días por defecto. Selecciona un rango de fechas para sincronizar más pedidos. Puedes sincronizar hasta 30 días a la vez.",
        "title_order_details_sync": "Sincronización de detalles de pedidos",
        "title_affiliate_program": "Programa de afiliados",
        "title_notification": "Notificación",
        "title_shipping_confirmation": "Confirmación del Envío",
        "title_shipping_confirmation_des": "Enviar el correo de confirmación de envío de Shopify automáticamente a tu cliente una vez generado el número de seguimiento",
        "title_paypal": "Enviar número de seguimiento a PayPal",
        "title_tracking": "Seguimiento",
        "title_tracking_tip": "Activa esta función para rastrear el estado de seguimiento de los pedidos que se han enviado.",
        "title_leave_message": "Dejar un mensaje a los proveedores",
        "title_leave_message_ae":"AliExpress suppliers will get this message together with your order information.",
        "title_leave_message_tmall":"Tmall suppliers will get this message together with your order information, but the message edition is not supported now.",
        "message_des": "Los proveedores recibirán este mensaje junto con la información de tu pedido.",
        "title_phone_number_op": "Optimización del número de teléfono",
        "title_automatic_order_split": "Dividir pedido automático",
        "automatic_order_split_des": "Dividiremos el pedido según el enlace del producto AliExpress y la cantidad de productos que hayas establecido. Reglas de división de pedidos: cuando el producto del pedido es del proveedor que estableciste, y la cantidad del producto es mayor o igual a la cantidad establecida en los ajustes, que debe ser menor que 10, dividiremos automáticamente el pedido en varios pedidos de Shopify.",
        "title_order_management": "Gestión de pedidos",
        "title_tax": "Impuestos",
        "title_shipping": "Envío",
        "title_add_global_shipping": "Añadir método de envío global basado en el producto",
        "add_global_shipping_des": "Configura tu método de envío por defecto para productos de proveedores específicos. La configuración basada en el producto tiene mayor prioridad en comparación con la configuración basada en el país. Puedes tener acceso rápido a esta función yendo a Mapeo - Más acciones.",
        phone_number_des:"In order for your package to the Netherlands and Finland to be delivered properly, please fill in a real phone number in the order. The Netherlands and Finland do not support the use of the Phone Number Optimization feature for the time being. Click  <a href=\"https://help.dsers.com/fix-failed-orders-due-to-aliexpress-phone-number-regulations/\" target=\"_blank\">here</a> to learn about how to solve this problem",
        phone_number_des_tip:"This country is not currently supported",
        "title_pricing_rule": "Regla de precios",
        "title_product": "Product",
        "title_sp_store": "Tiendas Shopify",
        "sp_stores_des": "Aquí puedes gestionar tus tiendas de Shopify. Si quieres desinstalar Dsers, por favor no olvides desvincularla desde aquí también.",
        "title_currencies": "Monedas",
        "disablestext": "Select a store",
        "password": "Contraseña",
        "admitadAccount1": "La cuenta Admitad ha sido suspendida debido a un error de verificación. Haz clic ",
        "admitadAccountLink": "aquí",
        "admitadAccount2": " para obtener más información.",
        "refresh_store_tag": "Reiniciar la conexión",
        "refresh_store_tag_tip": 'Si el estado de la tienda de Shopify no está actualizado por motivos de red, haz clic en "Reiniciar Conexión" para obtener el estado de la tienda',
        "refresh_not_connected":"No conectado",
        "refresh_store_icon_tip": "Refresh connect",
        "affiliate_code": "Código de afiliación",
        "affiliate_code_btn": "REACTIVAR",
        "hasSetCurrency": "Aplica el tipo de moneda a las reglas de precios cuando publiques productos en Shopify. Las Reglas de Precios Globales funcionan de manera diferente si NO estás vendiendo en USD en Shopify y no quieres activar esta función. Por favor, calcula los multiplicadores como corresponda Aplica el tipo de moneda a las reglas de precios cuando publiques productos en Shopify. Las Reglas de Precios Globales funcionan de manera diferente si NO estás vendiendo en USD en Shopify y no quieres activar esta función. Por favor, calcula los multiplicadores como corresponda ",
        "pricing_rule_dsec": "La normativa de precios incluye la moneda, las plantillas de precios y la asignación de céntimos. Aquí puede crear sus reglas de precios y aplicarlas a sus productos.",
        "updateProductPrice": "Actualizar el precio de los productos enviados a tu tienda Shopify de acuerdo con las reglas de precios que tengas establecidas actualmente. El proceso podría tomar algún tiempo. Haz clic <a href=\"/app/pricing?select=1\">aquí </a> para actualizar el plan, aumentando los tiempos de sincronización.",
        ...agency
    }
}