import React, { Component } from 'react';
import { Button, Icon, Modal, Input } from 'antd';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import copy from 'copy-to-clipboard';
import intl from 'react-intl-universal';

import { Get, Post, Put } from '../../utils/request';
import StatusTag from './StatusTag';
import styles from './index.less';
import thumbImg from '../../assets/img/thumb-up.png';
import { emailRegExp } from '../../utils/constants';
import { queryUserInfo } from '../../services/user';
import { formatCurrency, replaceUrlToHttps } from '../../utils/utils';

// import mockData from './mockData.json';

const interval = 5 * 1000;

function SampleNextArrow({ onClick, className }) {
  if (className.indexOf('slick-disabled') > -1) {
    return null;
  }

  function handleClick() {
    window.dataLayer.push({
      'event': 'banner-click',
      'type': 'next-button'
    });
    onClick();
  }

  return (
    <Icon type="right" className={`${styles.arrow} ${styles.arrowLeft}`} onClick={handleClick} id="b_ctr_next" />
  );
}

function SamplePrevArrow({ onClick, className }) {
  if (className.indexOf('slick-disabled') > -1) {
    return null;
  }

  function handleClick() {
    window.dataLayer.push({
      'event': 'banner-click',
      'type': 'prev-button'
    });
    onClick();
  }

  return (
    <Icon type="left" className={`${styles.arrow} ${styles.arrowRight}`} onClick={handleClick} id="b_ctr_prev" />
  );
}

class ShopPush extends Component {
  listenOpenModalTimer = null;
  aeProductId = null;
  hasMouseEvent = false;

  constructor(props) {
    super(props);
    this.sliderRef = React.createRef();
    this.bannerRef = React.createRef();

    this.state = {
      open: false,
      initial: false, // 当检测不存在弹窗时初始化
      // slideData: mockData.data,
      slideData: [],
      visible: false,
      email: undefined,
      sendEmailSuccess: false,
      sendEmailLoading: false,
      isValidEmail: false,
      hasCopy: false
    };
  }

  componentDidMount() {
    // console.log('ShopPush componentDidMount');
    this.addMouseEvent();

    this.fetchOrder();

    this.initial();

  }

  componentDidUpdate(_, prevState) {
    if (!this.hasMouseEvent) {
      this.addMouseEvent();
    }

    if (prevState.visible !== this.state.visible) {
      // 当打开邮件弹窗时，暂停自动滚动
      if (this.sliderRef.current) {
        if (this.state.visible) {
          this.sliderRef.current.slickPause();
        } else {
          this.sliderRef.current.slickPlay();
        }
      }
    }
  }

  componentWillUnmount() {
    this.removeMouseEvent();

    if (this.listenOpenModalTimer) {
      clearInterval(this.listenOpenModalTimer);
    }
  }

  initial = () => {
    // 当初始化后，切换路由无需延时判断是否存在弹窗，直接展示 banner。切换路由 BaseLayout 会卸载并重新装载，所以这里需要维持状态
    const initialStatus = sessionStorage.getItem('shop_push_initial');

    if (initialStatus === '1') {
      this.setState({ initial: true });
      if (this.props.openBanner) {
        this.setState({ open: true });
      }
    } else {
      // 由于接口请求，弹窗会延迟出现
      setTimeout(() => {

        this.listenOpenModalTimer = setInterval(() => {
          const hasOpen = this.hasOpenModal();
          // console.log('listenOpenModalTimer: 存在打开的 modal ?', hasOpen);

          if (this.listenOpenModalTimer && !hasOpen) {
            clearInterval(this.listenOpenModalTimer);
            this.setState({ initial: true });
            sessionStorage.setItem('shop_push_initial', '1');

            // console.log('modal 已全部关闭');
            if (this.props.openBanner) {
              // console.log('banner 打开');
              this.setState({ open: true });
            }
          }
        }, 1000);
      }, 5000);
    }
  }

  addMouseEvent = () => {
    if (this.bannerRef.current) {
      this.bannerRef.current.addEventListener('mouseenter', this.handleMouseenter, false);
      this.bannerRef.current.addEventListener('mouseleave', this.handleMouseleave, false);
      this.hasMouseEvent = true;
    }
  }

  removeMouseEvent = () => {
    if (this.bannerRef.current) {
      this.bannerRef.current.removeEventListener('mouseenter', this.handleMouseenter, false);
      this.bannerRef.current.removeEventListener('mouseleave', this.handleMouseleave, false);
      this.hasMouseEvent = false;
    }
  }

  handleMouseenter = () => {
    setTimeout(() => {
      if (this.sliderRef.current && !this.state.visible) {
        this.sliderRef.current.slickPause();
      }
    });
  }

  handleMouseleave = () => {
    setTimeout(() => {
      if (this.sliderRef.current && !this.state.visible) {
        this.sliderRef.current.slickPlay();
      }
    });
  }

  fetchOrder = async () => {
    try {
      const res = await Get('/prod/supply/list');
      // console.log('fetchOrder', res);

      if (res.code === 2000 && res.data && res.data.length !== 0) {

        const versions = res.data.map(i => i.version);
        const maxVersion = Math.max(...versions);
        const result = res.data.map(i => ({
          ...i,
          isNew: i.version === maxVersion
        }));

        this.setState({
          slideData: result
        });
      }
    } catch (error) {
      console.log('fetchOrder', error);
    }
  }

  /**
   * 检测是否存在弹窗
   *
   * @memberof ShopPush
   */
  hasOpenModal = () => {
    const modals = document.querySelectorAll('.ant-modal-mask');

    if (modals.length === 0) {
      return false;
    }

    let hasOpen = false;

    for (const i of modals) {
      if (!i.classList.contains('ant-modal-mask-hidden')) {
        hasOpen = true;
        break;
      }
    }

    return hasOpen;
  }

  handleOpen = () => {
    this.setState({ open: true });
  }

  handleCloseBanner = async () => {
    window.dataLayer.push({
      'event': 'banner-click',
      'type': 'close-button'
    });

    this.setState({ open: false });
    try {
      const res = await Put('/prod/supply/hide');
      queryUserInfo();
      if (res.code === 2000) {
        // todo
      }
    } catch (error) {
      console.log('handleCloseBanner', error);
    }
  }

  handleEmailModal = (id) => {
    this.aeProductId = id;
    this.setState({
      visible: true
    });
  }

  handleCancel = () => {
    this.aeProductId = null;
    this.setState({
      visible: false,
      email: undefined,
      sendEmailSuccess: false
    });
  };

  handleEmailInput = (e) => {
    const val = e.target.value.trim();
    this.setState({
      email: val,
      isValidEmail: emailRegExp.test(val)
    });
  }

  handleSubmitEmail = async () => {
    const { email } = this.state;
    if (this.aeProductId === null) {
      return;
    }

    this.setState({ sendEmailLoading: true });

    try {
      const res = await Post('/prod/supply/request', { data: { product_id: this.aeProductId, email } });
      if (res.code === 2000) {
        this.aeProductId = null;
        this.setState({
          sendEmailSuccess: true
        });
        this.fetchOrder();
      }
    } catch (error) {
      console.log('handleSubmitEmail', error);
    } finally {
      this.setState({ sendEmailLoading: false });
    }
  }

  handleCopy = (url) => {
    copy(url);
    this.setState({ hasCopy: true });
    setTimeout(() => {
      this.setState({ hasCopy: false });
    }, 3000);
  }

  render() {
    const { open, slideData, visible, email, sendEmailSuccess, isValidEmail, sendEmailLoading, initial, hasCopy } = this.state;

    const setting = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      autoplaySpeed: interval,
      autoplay: true,
      pauseOnHover: false
    };

    if (!initial) {
      return null;
    }

    // 没有商品时
    if (slideData.length === 0) {
      return null;
    }

    return (
      <div className={styles.banner} ref={this.bannerRef}>
        <div className={`${styles.header} ${open ? styles.open : ''}`}>
          {/* <span className={styles.aliIcon} /> */}
          <span style={{ padding: '0 10px' }} />
          <p className={styles.title}>{intl.get('shop_push.title')}</p>
          {/* <a className={styles.linkIcon} href="https://www.blog.dserspro.com/post/aliexpress-global-supply-center-introduction-powered-by-dsers" target="_blank" rel="noopener noreferrer" >
            <span />
          </a> */}
          {
            open ? <Icon type="close" id="b_ctr_close" className={styles.close} onClick={this.handleCloseBanner} /> : <Button type="primary" onClick={this.handleOpen} >{intl.get('public.check_more')}</Button>
          }
        </div>
        {
          open ? (
            <div className={styles.body}>
              <Slider {...setting} ref={this.sliderRef}>
                {
                  slideData.map(i => (
                    <div key={i.ae_product_id}>
                      <div className={styles.content}>
                        <div className={styles.left}>
                          <img src={replaceUrlToHttps(i.img)} alt="AE-product" />
                          <StatusTag data={i} />
                        </div>
                        <div className={styles.center}>
                          <p className={styles.subTitle} style={{ WebkitBoxOrient: 'vertical' }} title={i.product_title}>{i.product_title}</p>
                          <div className={styles.item}>
                            <span>{intl.get('shop_push.dsers_price')}</span>
                            <span className={styles.highlight1}>US ${formatCurrency(i.price)}</span>
                          </div>
                          <div className={styles.item}>
                            <span>{intl.get('shop_push.current_price')}</span>
                            <span className={styles.throughLine}>US ${formatCurrency(i.current_price)}</span>
                          </div>
                          <div className={styles.item}>
                            <span>{intl.get('shop_push.profit_margin')}</span>
                            <span className={styles.discount}>{i.profit_margin} discount</span>
                          </div>
                          <div className={styles.item}>
                            <span>{intl.get('shop_push.supplier')}</span>
                            <span className={styles.supply}>{intl.get('shop_push.dsers_program')}</span>
                          </div>
                          {/* <div className={styles.item}>
                            <span>Available date:</span>
                            <span>Live now !</span>
                          </div> */}
                        </div>
                        {/* <div className={styles.right}>
                          <Button type="primary" size="large" disabled={i.request === 1} onClick={() => this.handleEmailModal(i.ae_product_id)} >Send request</Button>
                          <a className={styles.contact} href="mailto:supply-support@dserspro.com">Contact</a>
                        </div> */}
                        <div className={styles.right}>
                          {
                            i.request === 1 ? (
                              <div className={styles.copy}>
                                {
                                  hasCopy ? <span>{intl.get('shop_push.link_copied')}</span> : <span>&nbsp;</span>
                                }
                                <Button
                                  type="primary"
                                  onClick={() => this.handleCopy(i.dsers_url)}
                                  style={{ padding: '0 24px', fontWeight: 500, fontSize: 22, height: 50 }}
                                  size="large"
                                >
                                  {intl.get('shop_push.copy_link')}
                                </Button>
                              </div>
                            ) : (
                              <a href="mailto:supply-support@dserspro.com">
                                <Button
                                  type="primary"
                                  style={{ padding: '0 24px', fontWeight: 500, fontSize: 22, height: 50 }}
                                  size="large"
                                >
                                  {intl.get('shop_push.contact')}
                                </Button>
                              </a>
                            )
                          }
                          <a
                            className={styles.contact2}
                            href="https://www.blog.dserspro.com/post/aliexpress-global-supply-center-introduction-powered-by-dsers"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {intl.get('shop_push.what_dec')}
                          </a>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </Slider>
            </div>
          ) : null
        }
        <Modal
          title={null}
          visible={visible}
          footer={null}
          className={styles.emailModal}
          onCancel={this.handleCancel}
        >
          <h1>{intl.get('shop_push.thank_you')}</h1>
          {
            !sendEmailSuccess ? (
              <>
                <p className={styles.tip}>{intl.getHTML('shop_push.fill_email')}</p>
                <div>
                  <Input
                    style={{ width: 320 }}
                    value={email}
                    type="email"
                    size="large"
                    placeholder={intl.get('shop_push.fill_email_placeholder')}
                    onChange={this.handleEmailInput}
                  />
                </div>
                <div>
                  <Button
                    disabled={!isValidEmail}
                    style={{ margin: '20px 0' }}
                    loading={sendEmailLoading}
                    onClick={this.handleSubmitEmail}
                    size="large"
                    type="primary"
                  >
                    {intl.get('shop_push.send_request')}
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className={styles.success}>
                  <img src={thumbImg} alt="thumb-up" />
                </div>
                <p className={styles.tip}>
                  {intl.getHTML('shop_push.reach_you')}
                </p>
              </>
            )
          }
        </Modal>
      </div>
    );
  }
}

export default ShopPush;
