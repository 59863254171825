import React,{useEffect,useState} from 'react'
import intl from 'react-intl-universal';

import styles from './DownLoad.less'

import download from '../../assets/model/download.png'
import dimg from '../../assets/model/downloadimg.png'
import close from '../../assets/model/icon-close.png'
import Loading from 'routes/BeginnerGuide/component/Loading';
import { webCustomCollect } from 'utils/utils'

export default function DownLoad() {

  const [isfromdownload,setIsfromdownload] = useState('none')

  const [isLoading,setLoading] = useState(false)

  const registerTime = JSON.parse(localStorage.getItem('store'))?.created_at

  const sevenDay = parseInt(registerTime) + 7*24*60*60

  const nowDate = Date.parse(new Date())+''

  useEffect(()=>{
    const emailback = localStorage.hasOwnProperty('emailback')
    
    if(emailback && !(nowDate.substring(0,nowDate.length - 3) - sevenDay > 0)){
      setIsfromdownload('block')
    }else{
      setIsfromdownload('none')
    }

    if(nowDate.substring(0,nowDate.length - 3) - sevenDay > 0){
      localStorage.removeItem('emailback')
      localStorage.removeItem('emailtime')
    }
  },[])

  const closeHandler = ()=>{
    setIsfromdownload('none')
  }

  const downloadFileHandler = async(e)=>{
    setLoading(true)
    let response = await fetch('https://images.dsers.com/pdf/Dropshipping.pdf');
    let blob = await response.blob(); 
    setLoading(false)
    let objectUrl = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = objectUrl;
    a.download = 'Dropshipping.pdf';
    a.click()
    a.remove(); 
    webCustomCollect({
      action:"download_guide",
      custom_info:[]
    })
    setIsfromdownload('none')
    localStorage.removeItem('emailback')
    localStorage.removeItem('emailtime')
  }

  return (
    <div className={styles.container} style={{display:isfromdownload}}>
      <div className={styles.title}>
        <p>{intl.get('home.downloadguide.guide')}</p>
        <img onClick={closeHandler} src={close} alt="" />
      </div>
      <img src={dimg} alt="" />
      <div className={styles.download}>
        <img src={download} alt="" />
        <p  onClick={downloadFileHandler}>
          {
          !isLoading?
          <a>{intl.getHTML('home.downloadguide.text')}</a> : 
          <span style={{color:'blue',paddingLeft:"10px"}}>loading...</span>
          }
        </p>
      </div>
    </div>
  )
}
