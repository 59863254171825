export default {
  banner: {
    title: 'Aplicativo Parceiro Oficial de Dropshipping do AliExpress',
    quick_orders:
      'DSers is the best tool to process all your orders  within minutes without a limit (100+)',
    get_started_placeholder: 'Deixe seu e-mail',
    get_started: 'Iniciar',
    order_management: 'Gerenciamento de Pedidos',
    order_management_description: `O DSers é capaz de lidar com pedidos em massa ou múltiplos pedidos.
                                  Processa centenas de pedidos por dia em poucos minutos.
                                  Todos os pedidos são sincronizados do Shopify automaticamente.
                                  O Dropshipper então pode realizar seu pedido com o AliExpress com apenas UM clique.
                                  Salve horas do seu tempo!`,
    supplier_management: 'Gerenciamento de Fornecedores',
    supplier_management_description: `Seleione múltiplos fornecedores para os itens selecionados.
                                      O fornecedor substituto SÓ SERÁ ativado caso o produto não esteja disponível no seu fornecedor padrão (por ex. sem estoque).
                                      Milhões de produtos significam milhares de fornecedores e estoque infinito no Aliexpress.
                                      Nunca fique esgotado! (Em breve, um novo recurso permitirá que você encontre o fornecedor mais barato automaticamente!).`,
    csv_bulk_orders: 'Pedidos em massa via CSV',
    csv_bulk_orders_description: `Aproveite o DSers e faça múltiplos pedidos de uma só vez, não importa a plataforma de vendas que você está utilizando.
                                  Crie e faça upload do banco de dados do seus produtos,
                                  importe seus pedidos em espera para o DSers e coloque-os todos de uma vez! Tão simples quanto ler este texto.
                                  Economize mais tempo do que nunca com o DSers.`,
    save_97_ordering_time: 'Economize 97% de tempo de processamento',
    how_does_work: {
      title: 'Como o DSers funciona para o dropshipper?',
      easy: 'Fácil de começar. Fácil de dominar.',
      multiple_store: 'Múltiplas lojas',
      multiple_store_dec: 'Gerencie múltiplas lojas do Shopify',
      supplier_management: 'Gerenciamento de Fornecedores',
      supplier_management_dec: 'Um produto com múltiplos fornecedores',
      multiple_orders: 'Múltiplos pedidos',
      multiple_orders_dec: 'Processe múltiplos pedidos com um clique',
      auto_sync: 'Sincronização automática',
      auto_sync_dec: 'Status do pedido sincronizado com o AliExpress'
    },
    feedback: {
      title: 'Aprovado por pessoas como você',
      feedback: 'feedback dos nossos principais usuários',
      like: 'Curtir',
      comment: 'Comentar',
      share: 'Compartilhar',
      comments: 'comentários',
      tomas: 'Tomas',
      tomas_time: 'Ontem às 4:19 AM',
      tomas_dec:
        'Estamos usando o DSers como uma ferramenta para processar pedidos no AliExpress. A Ferramenta é muito poderosa. Agradecemos muito por isso.',
      simon: 'Simon',
      simon_time: '2 de novembro de 2018 às 11:09 AM',
      simon_dec:
        'DSers é um ótimo aplicativo! Estou muito feliz em usar pois está me poupando muito tempo e dinheiro. Preencher centenas de pedidos em poucos minutos é uma ótima experiência.',
      wilson: 'Wilson',
      wilson_time: 'Ontem às 9:22 PM',
      wilson_dec:
        'Esta ferramenta é um sonho!! Foi impressionante ver que múltiplos pedidos foram feitos em um minuto ! Ela ajuda a salvar muito tempo!',
      jonathan: 'Jonathan',
      jonathan_time: 'às 5:48 PM',
      jonathan_dec:
        'Estou muito feliz por ter encontrado seu site! O preenchimento de pedidos ficou muito mais fácil desde que comecei a usar o DSers'
    },
    features: {
      title: 'O DSers fornece todos os recursos para negócios de dropshipping',
      dec: 'Principais Recursos no DSers',
      multiple_order_processing: 'Processamento de múltiplos pedidos',
      multiple_order_processing_dec: 'Bulk order processing within minute',
      variants_mapping: 'Mapeamento de variantes',
      variants_mapping_dec:
        'Adiciona facilmente variantes dos produtos adicionando fornecedores',
      shipment_setting: 'Configuração de Frete',
      shipment_setting_dec: 'Defina métodos de frete por Países e taxas',
      aliExpress_whitelist: 'AliExpress Whitelist',
      aliExpress_whitelist_dec: 'Sem mais limitações de pedidos',
      multiple_stores_manager: 'Gerenciamento de múltiplas lojas',
      multiple_stores_manager_dec: 'Gerencie diversas lojas em uma única conta',
      supplier_management: 'Gerenciamento de Fornecedores',
      supplier_management_dec: 'Adicione diversos fornecedores a um produto',
      product_importer: 'Importador de Produtos',
      product_importer_dec:
        'Adicione produtos utilizando nossa extensão do Chrome',
      auto_messaging: 'Mensagens automáticas',
      auto_messaging_dec:
        'Crie mensagens automáticas para os vendedores do AliExpress',
      email_template: 'Template de e-mail',
      email_template_dec: 'E-mail de notificação de frete em breve',
      tracking: 'Rastreamento',
      tracking_dec: 'Informações de envio e de rastreamento precisas',
      price_monitor: 'Monitor de preço',
      price_monitor_dec:
        'Seja notificado sobre mudanças de preço no AliExpress',
      inventory_monitor: 'Monitor de estoque',
      inventory_monitor_dec:
        'Seja notificado sobre mudanças de estoque no AliExpress'
    },
    easy_started: 'Totalmente de Graça e <br /> fácil de usar',
    partnership: 'Parceiros',
    advabced:'Advanced',
    content:'<span class={title}>20</span> % <span class={title}>off</span>',
    will:"If you change to Advanced plan now, you will get",
    support:"Support of 5 additional stores, <span class={title}>10,000+</span> products limit, new marketing strategies Subscribe now and enjoy <span class={title}>20%</span> off the price!",
    today:'Only for today!',
    btn1:'Change plan now',
    btn2:'Let me think about it',
    yes:'Oui',
    no:'Non merci',
    ioss: {
      contact_us: 'CONTACT US',
      title:
        'Contact your supply account manager to learn more information about IOSS',
      sub_title: '-----  Relevant notice about the EU tax reform',
      text:
        'The European Union will start implementing new VAT tax regulations from July, 1st, 2021.<br/>Dropshipping business can be run normally and the end customers won’t need to pay any additional taxes. <br/>If you have concerns about IOSS, local tax issue, tax refund, please feel free to contact your supply account manager (<a href="https://www.dsers.com/blog/eu-vat-reform/" target="_blank" rel="noopener noreferrer">click here to learn more</a>).',
      title2: 'New EU Tax (IOSS) introduction',
      text2: 'Effects on Dropshipping business in EU countries from 1st July 2021'
    },
    newUI: {
      title: 'Brand new UI, brand new experience',
      title2: 'DSers will launch a new UI in early September',
      learn: 'LEARN MORE',
      newYearTitle: "Chinese New Year Holidays Notice",
      newYearContent1: "Chinese New Year is coming. Please contact your suppliers to check their delivery delays and resting period!",
      newYearContent2: "DSers suppliers will rest from 21st Jan 2023 to 23rd Jan 2023",
    },
    newFeatureUp:'New feature upgrade',
    newFeatureTitle:'[New feature]',
    newFeatureText:'Follow and track the logistics information of your order, you can use Tracking.',
    newSettingTitle:'[New setting]',
    newSettingText:'New mapping method: Standard Mapping',
    newSettingText1:'Replace Product: Without changing the Shopify product ID, replace all variants of your Shopify product',
    learnMore:'Learn more',
    gotIt:"Got it",
    appointmenttext:'Você definiu o intervalo de [ {time} ] para iniciar a migração. Você não conseguirá operar sua conta DSers quando a migração for iniciada.',
    appointmenttext1:'Você definiu o intervalo de [ {time} ] para iniciar a migração e ajudaremos a concluí-la em cinco minutos. Você não conseguirá operar sua conta DSers durante a migração. Aproveite e faça uma pausa.',
    servererrtext:'<text>We will be performing a system maintenance on</text> March 14th from 6:00 <br /> to 7:00 AM GMT.<br />',
    servererrtext2:'<text>Thanks for your understanding.</text>',
    agency_cold_startup: {
      banner_title_1: 'Novo recurso on-line!',
      banner_title_2: 'Gerenciamento de pedidos da agência',
      popup_desc_1: `Você alterna entre diferentes ferramentas para processar os pedidos da agência?`,
      popup_desc_2: `Experimente esta solução alternativa do DSers para evitar a alternância entre lojas Shopify, contas de processamento em massa e arquivos CSV.`,
      popup_desc_3: `Tenha a liberdade para se concentrar no marketing neste 4º trimestre!`,
      learn_more_btn: 'Saiba mais',
      banner_desc: 'Experimente esta solução alternativa do DSers para evitar a alternância entre lojas Shopify, contas de processamento em massa e arquivos CSV.',
      order_awaiting_empty_after_title: 'Antes',
      order_awaiting_empty_before_title: 'Depois',
      order_awaiting_empty_before: 'Sem este recurso, você precisaria de múltiplas ferramentas para os pedidos da agência',
      order_awaiting_empty_after: 'Com este recurso, você só precisa do DSers!',
      order_awaiting_empty_link: 'Clique <a data="text-link" style="color: blue" href="https://www.dsers.com/blog/product-update-dropship-with-agencies/" target="_blank" >aqui</a> para saber mais sobre como processar os pedidos da agência',
      what_is_agency: 'O que é uma agência?',
      what_is_erp: 'O que é um ERP?',
      how_does_dsers_agency_works: 'Como o DSers simplifica o seu fluxo de trabalho com agências de dropshipping?',
    },
    tmall_cold_startup: {
      popup_title: 'Novo marketplace',
      pupup_title_2: 'Tmall está on-line! Confira!',
      popup_desc_1: 'Milhares dos produtos mais vendidos foram adicionados à lista.',
      popup_desc_2: 'O que você está esperando para importá-los à sua loja?',
      popup_btn: 'Procurar produtos',
      top_banner_title: 'Novo marketplace',
      top_banner_title_2: 'Tmall está on-line! Confira!',
      find_supplier_banner_title: 'Os mais vendidos para o 4º trimestre de 2022!',
      find_supplier_banner_subtitle: 'Itens em tendência e lucrativos estão disponíveis!',
      find_supplier_banner_subtitle_2: 'Adicione-os à sua loja!'
    }
  }
};
