import React, { Component } from 'react';
import intl from 'react-intl-universal';

// common locale data
// require('intl/locale-data/jsonp/en.js');
// require('intl/locale-data/jsonp/pt-BR.js');
// require('intl/locale-data/jsonp/fr.js');

// app locale data
const locales = {
  'en-US': require('../locale/en-US').default,
  'fr-FR': require('../locale/fr-FR.js').default,
  'pt-PT': require('../locale/pt-PT.js').default,
  'es-ES': require('../locale/es_ES.js').default,
  'de-DE': require('../locale/de_DE.js').default,
  'it-IT': require('../locale/it_IT.js').default
};


export default class ReactIntl extends Component {
  state = { initDone: false };

  componentDidMount() {
    this.loadLocales();
  }

  loadLocales() {

    const langKeys = ['pt-PT', 'en-US', 'fr-FR', 'it-IT', 'de-DE', 'es-ES']

    // init method will load CLDR locale data according to currentLocale
    // react-intl-universal is singleton, so you should init it only once in your app
    intl
      .init({
        currentLocale: langKeys.includes(localStorage.getItem('lang')) ? localStorage.getItem('lang') : 'en-US',
        locales
      })
      .then(() => {
        // After loading CLDR locale data, start to render
        this.setState({ initDone: true });
      })
      .catch(error => {
        console.log('react-intl-universal', error);
      });
  }

  render() {
    return this.state.initDone ? this.props.children : 'Loading...';
  }
}
