import React, { Component } from 'react';
import { connect } from 'dva';
import { Input, Radio, Spin, Select, message } from 'antd';

import intl from 'react-intl-universal';
import { getSupplierUrl } from '../../../../../utils/utils.js';

import styles from './Shipping.less';
import './Shipping.css'
import clearIcon from '../../../../../assets/product/clearIcon.png';
import { getCity, getAeProductFreight, getIsTendaysDelivery } from 'services/dropshippersetting.js';

import _ from 'lodash'

const { Search } = Input;
const RadioGroup = Radio.Group;
const { Option } = Select;

const SearchCity = [
  'BR', 'RU', 'ES', 'ID', 'FR'
];

const SearchTown = [
  'BR', 'RU', 'ES', 'FR'
]

const setTendaysDelivery = (freights = [], needSet) => {
  if (!needSet) {
    return freights;
  }
  return freights.map(item => {
    if (item.serviceName === 'Other') {
      return {
        ...item,
        company: '(DS) Seller\'s Shipping Method',
        tracking: true,
        dsersShipping: true
      };
    }
    return item;
  });
};

@connect(({ loading, login,auth }) => ({
  userInfo: login.userInfo,
  // freightLoading: loading.effects['dropshippersetting/aeProdFreight'],
  aeProdLoading: loading.effects['dropshippersetting/aeProdDetail'],
  isOberlo: auth?.isOberloUser
}))
export default class SetShipping extends Component {
  constructor(props) {
    super(props);

    this.state = {
      freight: [],
      countryListData: [],
      checkedFreight: undefined,
      shipsFrom: [],
      selectedShipsFrom: undefined,
      selectedRegion: undefined,
      freightLoading: false,
      searchVal:'',
      showChooseCity: false,
      selectedCity: '',
      cityData: [],
      selectedTown: undefined,
      townShip: [],
      showChooseTown: false,
      firstShipOption:'',
      select_supplier_type:undefined
    };
  }

  componentDidMount() {
    const { prod } = this.props;
    if (prod) {
      this.updateBaseData();
    }

    if(this.props.prod?.supplier_type === 4){
      this.countryListData = this.countryListData?.filter(item=>{
        return item.Code === 'US' || item.Code === 'BR'
      })
    }

    this.setState({
      countryListData: this.sortCountry(this.countryListData)
    });
  }

  componentDidUpdate(prevProps) {
    const { prod } = this.props;
    if (!_.isEqual(prevProps.prod,prod) && prod) {
      this.updateBaseData();
    }
  }

  countryListData = JSON.parse(localStorage.getItem('countryList') || '[]');
  prod = null;
  aeSku = '';
  countryCode = '';
  countryName = '';
  shipObj = {};
  shipsFrom = '';

  updateBaseData = () => {
    this.resetData();

    this.prod = this.props.prod;
    this.getAEProdDetail();

    this.initProdShip(this.prod, this.props.ship);
    this.setShipToCountry();
  };

  // 需要重置的数据
  resetData = () => {
    this.aeSku = '';
    this.countryCode = '';
    this.countryName = '';
    this.shipObj = {};
    this.shipsFrom = '';

    this.setState({
      freight: [],
      checkedFreight: undefined,
      shipsFrom: [],
      selectedShipsFrom: undefined,
      selectedRegion: undefined
    });
  };

  // 初始化这个商品的物流设置
  initProdShip = (prod, ship) => {
    this.shipObj.user_id = this.props.userInfo.user_id;
    this.shipObj.aliexpress_product_id = Number(prod.aliexpress_product_id);
    this.shipObj.aliexpress_product_id_str = prod.aliexpress_product_id
    this.shipObj.image_src = prod.image_src;
    this.shipObj.title = prod.title;
    this.shipObj.settings = [];
    this.shipObj.supplier_type = prod?.supplier_type;
    this.shipObj.v2 = 'true'

    if (ship) {
      Object.assign(this.shipObj, ship);
    }
  };

  // 把设置好的物流标识在国家上
  setShipToCountry = () => {
    if (this.shipObj.settings.length == 0) {
      return;
    }

    this.shipObj.settings.forEach(ship => {
      this.countryListData.forEach(item => {
        if (item.Code == ship.ship_to) {
          item.freight = ship.company;
        }
      });
    });
  };

  // 刷选国家
  screenCountry = v => {
    if (!v) {
      this.setState({
        countryListData: this.countryListData
      });
      return;
    }

    let arr = null;
    const key = v.toLowerCase();

    arr = this.countryListData.filter(item => {
      return item.Name.toLowerCase().includes(key);
    });

    this.setState({
      countryListData: arr
    });
  };

  // 获取ae商品详情
  getAEProdDetail = () => {
    const { aliexpress_product_id, supplier_type } = this.prod;
    if (!aliexpress_product_id) {
      return;
    }

    this.props.dispatch({
      type: supplier_type === 4 ? 'productmy/tmProductDetail' : 'dropshippersetting/aeProdDetail',
      payload: {
        data: {
          url: getSupplierUrl(supplier_type,aliexpress_product_id),
          country:this.props.productShipTo,
          supplier_type: supplier_type,
          product_id : aliexpress_product_id
        },
        callback: d => {
          if (d.code == 2000) {
            console.log(d);
            this.setSkuId(d.data);
            this.setShipsFrom(d.data);
            this.setState({
              select_supplier_type:d?.data.supplier_type
            })
          }
        }
      }
    });
  };

  // 获取shipsfrom
  setShipsFrom = prod => {
    const {
      aeop_ae_product_s_k_us: { aeop_ae_product_sku = [] }
    } = prod;

    const obj = {};

    aeop_ae_product_sku.forEach(item => {
      const { aeop_s_k_u_propertys = {} } = item;

      if (!aeop_s_k_u_propertys.aeop_sku_property) {
        return;
      }

      aeop_s_k_u_propertys.aeop_sku_property.forEach(sku => {
        if (sku.sku_property_name == 'Ships From') {
          if (!obj[`${sku.sku_property_id}-${sku.property_value_id_long}`]) {
            obj[`${sku.sku_property_id}-${sku.property_value_id_long}`] = {
              sku_property_value: sku.sku_property_value,
              sku_property_id: sku.sku_property_id,
              property_value_id_long: sku.property_value_id_long,
              skuid: item.id,
              sku_id: item.sku_id
            };
          }
        }
      });
    });

    const shipsFrom = Object.values(obj);
    let selectedShipsFrom;

    if (Object.values(obj).length) {
      this.aeSku = shipsFrom[0].skuid;
      this.sku_id = shipsFrom[0].sku_id
      this.shipsFrom = `${shipsFrom[0].sku_property_id}:${shipsFrom[0].property_value_id_long}`;
      selectedShipsFrom = `${shipsFrom[0].sku_property_id}:${shipsFrom[0].property_value_id_long}`;
      // 此变量用于记录第一项数据  Oberlo迁移改动
      this.setState({
        firstShipOption:`${shipsFrom[0].sku_property_id}:${shipsFrom[0].property_value_id_long}`
      })
    }

    this.setState({
      shipsFrom,
      selectedShipsFrom
    });
  };

  // 获取ae商品的一个sku
  setSkuId = prod => {
    const {
      aeop_ae_product_s_k_us: { aeop_ae_product_sku = [] }
    } = prod;
    this.aeSku = aeop_ae_product_sku[0].id;
    this.sku_id = aeop_ae_product_sku[0].sku_id;
  };

  // 点击国家
  clickCountry = item => {

    if (item.Code == this.countryCode) {
      return;
    }

    this.setState({
      showChooseCity: false,
      showChooseTown: false,
      selectedTown: undefined
    })

    this.countryCode = item.Code;
    this.countryName = item.Name;

    this.setState({
      checkedFreight: this.getCheckedFreight(),
      selectedRegion: item
    });

    if(item.Code === 'BR' || item.Code === 'FR'){
      getCity({
        data: {
          country_code: item.Code
        }
      }).then(res => {
        if(SearchTown.includes(item.Code)){
          this.setState({
            showChooseTown: true
          })
        }
        this.setState({
          showChooseCity: true,
          cityData: res.data || [],
          townShip: res.data ? res.data[0].Citys : [],
          selectedCity: res.data ? res.data[0]?.Name : '-'
        }, () => {
          this.getAeFreight(this.state.cityData ? this.state.cityData[0]?.Name : '-');
          this.changeTwonFrom(res.data[0].Citys[0]?.Name)
        })
      })
    }

    if(SearchCity.includes(item.Code)){
      this.getCityData(item.Code);
      return;
    }

    this.getAeFreight();
    
  };

  // 获取城镇数据
  getCityData = (code) => {
    getCity({
      data: {
        country_code: code
      }
    }).then(res => {
      if(SearchTown.includes(code)){
        this.setState({
          showChooseTown: true
        })
      }
      this.setState({
        showChooseCity: true,
        cityData: res.data || [],
        townShip: res.data ? res.data[0].Citys : [],
        selectedCity: res.data ? res.data[0]?.Name : '-'
      }, () => {
        this.getAeFreight(this.state.cityData ? this.state.cityData[0]?.Name : '-');
      })
    })
  }

  // 获取选中的物流
  getCheckedFreight = () => {
    let v;
    this.shipObj.settings.forEach(item => {
       // 针对Oberlo用户做特殊处理
      if (this.props.isOberlo && item.ship_from == '') {
        item.ship_from = this.state.firstShipOption
      }
      if (
        item.ship_to === this.countryCode &&
        item.ship_from === this.shipsFrom
      ) {
        v = item.company;
      }
    });
    return v;
  };

  setFreightLoading = freightLoading => this.setState({ freightLoading });

  // 获取商品物流
  getAeFreight = async (city) => {
    const { prod = {} } = this.props;
    const { aliexpress_product_id: aeId } = prod;
    const { countryCode, aeSku } = this;
    let isTendays = false;
    this.setFreightLoading(true);

    if (countryCode === 'US') {
      const isTendaysRes = await getIsTendaysDelivery({
        data: {
          product_ids: prod?.aliexpress_product_id_str || prod?.aliexpress_product_id
        }
      });
      const prodLabelData = isTendaysRes?.data?.[prod?.aliexpress_product_id_str || prod?.aliexpress_product_id]?.om_label || {};
      isTendays =
        prodLabelData.is_ten_days && prodLabelData.supplier === 'dsers';
    }
    let freightParams = {
      prod_id: prod?.aliexpress_product_id_str || prod?.aliexpress_product_id + '',
      sku: aeSku,
      country_code: countryCode,
      quantity: 1,
      supplier_type:prod?.supplier_type || this.state.select_supplier_type,
      sku_id:this.sku_id
    };

    if(city){
      freightParams.province = city;
      if(this.state.selectedTown){
        freightParams.city = this.state.selectedTown;
      }
    }

    if ((prod?.aliexpress_product_id_str || prod?.aliexpress_product_id) && aeSku && countryCode) {
      const freightRes = await getAeProductFreight({
        data: freightParams
      });
      if (freightRes?.code === 2000) {
        this.setState({
          freight: setTendaysDelivery(freightRes.data.freight, isTendays)
        });
      }
      this.setFreightLoading(false);
    } else {
      return null;
    }
  };


  changeCityFrom = (val) => {
    this.setState({
      selectedCity: val,
      selectedTown: undefined,
    },() => {
      this.getNewTown(this.state.selectedCity)
      this.getAeFreight(this.state.selectedCity)
    })
  }

  /**
   * 
   * @param {*} val 选择的city
   */
  getNewTown = (val) => {
    const { cityData } = this.state;
    console.log(cityData,'cityData=-=-=');
    let res = [];
    cityData.forEach(item => {
      if(item.Name === val){
        res = item.Citys;
      }
    })

    this.setState({
      townShip: res || [],
    })
  }
  
  
  changeTwonFrom = (val) => {
    console.log(val,'_+_+_+_+_+val_+_+_+_');
    this.setState({
      selectedTown: val
    },() => {
      this.getAeFreight(this.state.selectedCity)
    })
  }

  // 选择物流
  changeFreight = e => {
    const v = e.target.value;
    const { countryListData } = this.state;

    countryListData.forEach(item => {
      if (item.Code == this.countryCode) {
        item.freight = v;
      }
    });

    this.countryListData.forEach(item => {
      if (item.Code == this.countryCode) {
        item.freight = v;
      }
    });

    let flag = false;
    this.shipObj.settings.forEach(item => {
      if (
        item.ship_to === this.countryCode &&
        item.ship_from === this.shipsFrom
      ) {
        item.company = v;
        flag = true;
        item.ship_from = this.shipsFrom;
      }
    });

    if (!flag) {
      this.shipObj.settings.push({
        ship_to: this.countryCode,
        ship_from: this.shipsFrom,
        company: v
      });
    }

    this.updateShip();

    this.setState({
      countryListData: [...countryListData],
      checkedFreight: v
    });
  };

  // 清楚这个国家的物流
  clearCountry = code => {
    const { countryListData } = this.state;

    countryListData.forEach(item => {
      if (item.Code == code) {
        delete item.freight;
      }
    });

    this.countryListData.forEach(item => {
      if (item.Code == code) {
        delete item.freight;
      }
    });

    let index = -1;
    this.shipObj.settings.forEach((item, i) => {
      if (item.ship_to == code) {
        index = i;
      }
    });

    if (index != -1) {
      this.shipObj.settings.splice(index, 1);
    }

    this.updateShip();

    this.setState({
      countryListData: [...countryListData],
      checkedFreight: undefined
    });
  };

  // 更改ships from
  changeShipsFrom = v => {
    const { shipsFrom } = this.state;

    shipsFrom.forEach(item => {
      if (`${item.sku_property_id}:${item.property_value_id_long}` == v) {
        this.aeSku = item.skuid;
        this.sku_id = item.sku_id
      }
    });

    this.shipsFrom = v;
    this.setState({
      selectedShipsFrom: v,
      checkedFreight: this.getCheckedFreight()
    });

    this.countryCode && this.getAeFreight();
  };

  // 清除所有国家设置的物流
  clearShip = () => {
    const { countryListData } = this.state;

    this.shipObj.settings = [];

    countryListData.forEach(item => {
      delete item.freight;
    });

    this.countryListData.forEach(item => {
      delete item.freight;
    });

    this.updateShip();

    this.setState({
      checkedFreight: undefined,
      countryListData: [...countryListData]
    });
  };

  // 把这个商品的物流设置传到父组件
  updateShip = () => {
    this.props.updateShip(this.shipObj);
  };

  // 把已经设置了物流的国家放到前面
  sortCountry = countryListData => {
    const a = countryListData.filter(item => item.freight);
    const b = countryListData.filter(item => !item.freight);
    return [...a, ...b];
  };

  renderTable = freight => {
    const { selectable } = this.props;
    return freight.map(ems => {
      const { freightAmount = ems.standardFreightAmount, dsersShipping } = ems;
      const price = freightAmount.value || 0;
      return (
        <div className={styles.li} key={ems.serviceName}>
          <p>
            {selectable ? <Radio value={ems.serviceName} /> : null}
            <span>{ems.company}</span>
          </p>
          <p>
            {price
              ? `US $${price}`
              : intl.getHTML('order.order_product.shipping_dialog.price')}
          </p>
          {dsersShipping ? (
            <p>{intl.get('bargain.ten_days_delivery')}</p>
          ) : (
            <p>
              {ems.time}
              &nbsp;
              {ems?.shippingTimeMode === 1 ? 'working':null}
              &nbsp;
              {intl.get('order.order_product.shipping_dialog.days')}
            </p>
          )}
          <p>
            {ems.tracking
              ? intl.get('order.order_product.shipping_dialog.available')
              : intl.get('order.order_product.shipping_dialog.unavailable')}
          </p>
        </div>
      );
    });
  };

  render() {
    const { aeProdLoading, selectable } = this.props;

    const {
      countryListData,
      freight,
      checkedFreight,
      shipsFrom,
      selectedShipsFrom,
      selectedRegion,
      freightLoading,
      showChooseCity,
      selectedCity,
      cityData,
      selectedTown,
      townShip,
      showChooseTown,
    } = this.state;

    // console.log(countryListData,'countryListData-=-=')
    return (
      <div className="shipping">
        <div className={styles.fuLogisticsCont}>
        <div className={styles.fuLogisticsLeft}>
          <div className={styles.fuLogisticsLeftTitle}>
            <h2>{intl.get('mapping.ship_to')}：</h2>
            {selectable ? (
              <a
                role="button"
                tab-index="0"
               onClick={this.clearShip}
              >
                {intl.get('mapping.clear_all')}
              </a>
            ) : null}
          </div>
          <div className="search_box">
            <Input
              placeholder={intl.get('public.search')}
              className={styles.fuLogisticsLeftInp}
              onChange={(e)=>{
                this.setState({
                  searchVal:e.target.value.trim()
                })
              }}
              onKeyDown={e => {
                if(e.keyCode == 13){
                  this.screenCountry(this.state.searchVal);
                }
              }}
            />
            <div className="btn_cs" onClick={()=>{
              this.screenCountry(this.state.searchVal);
            }}>
              <i class="material-icons notranslate" style={{color:'#fff'}}>search</i>
            </div>
          </div>
          <div className={styles.fuLogisticsLeftList}>
            <ul>
              {countryListData.map(item => {
                return (
                  <li
                    onClick={() => {
                      this.clickCountry(item);
                    }}
                    key={item.Code}
                  >
                    <p>{item.Name}</p>
                    <span>
                      {item.freight && selectable ? (
                        <img
                          src={clearIcon}
                          onClick={e => {
                            e.stopPropagation();
                            this.clearCountry(item.Code);
                          }}
                        />
                      ) : null}
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className={styles.fuLogisticsRight}>
          {aeProdLoading || freightLoading ? (
            <div className={styles.freightLoading}>
              <Spin />
            </div>
          ) : null}
          <div className={styles.fuLogisticsRightTitle}>
            <div className={styles.liTopD}>{this.countryName}</div>
            {showChooseCity && ( 
              <Select
                  placeholder=""
                  style={{ width: 100, marginRight: '10px' }}
                  className={styles.fuLogisticsSelect}
                  dropdownMatchSelectWidth={false}
                  value={selectedCity}
                  onChange={this.changeCityFrom}
                  suffixIcon={<img className="clasSelImg" src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                  dropdownMenuStyle={{ left: '100px' }}
                >
                  {cityData.map(item => {
                    return (
                      <Option
                        value={item.Name}
                      >
                        {item.Name}
                      </Option>
                    );
                  })}
                </Select>
              )}
              {showChooseTown && (
                <Select
                    style={{ width: 150 }}
                    placeholder="Select a city"
                    className={styles.fuLogisticsSelect}
                    value={selectedTown}
                    onChange={this.changeTwonFrom}
                    suffixIcon={<img className="clasSelImg" src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
                    getPopupContainer={triggerNode => triggerNode.parentNode}
                  >
                    {townShip?.map(item => {
                      return (
                        <Option
                          value={item.Name}
                        >
                          {item.Name}
                        </Option>
                      );
                    })}
                  </Select>
                )}
           
            {shipsFrom.length ? (
              <div className={styles.shipFrom}>
                <span>Ships From</span>
                <Select
                  placeholder=""
                  style={{ width: 160 }}
                  className={styles.fuLogisticsSelect}
                  dropdownMatchSelectWidth={false}
                  value={selectedShipsFrom}
                  onChange={this.changeShipsFrom}
                  suffixIcon={<img className="clasSelImg" src={require('../../../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                >
                  {shipsFrom.map(item => {
                    return (
                      <Option
                        value={`${item.sku_property_id}:${item.property_value_id_long}`}
                        key={item.skuid}
                      >
                        {item.sku_property_value}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            ) : null}
          </div>
          {freight.length > 0 ? (
            <div className={styles.fuLogisticsRightList}>

              <div className={styles.liHead}>
                <p>{intl.get('order.order_product.shipping_dialog.company')}</p>
                <p>{intl.get('order.order_product.shipping_dialog.cost')}</p>
                <p>{intl.get('order.order_product.shipping_dialog.time')}</p>
                <p>
                  {intl.getHTML('order.order_product.shipping_dialog.tracking')}
                </p>
              </div>
              {selectable ? (
                <RadioGroup
                  onChange={e => {
                    this.changeFreight(e);
                  }}
                  value={checkedFreight}
                >
                  {this.renderTable(freight)}
                </RadioGroup>
              ) : (
                this.renderTable(freight)
              )}
            </div>
          ) : null}
          {/* 物流不支持 */}
          {freight.length === 0 && selectedRegion ? (
            <div className={styles.fuLogisticsRightNoData}>
              <h3>
                {intl.get('mapping.country_shipping.not_ship_this_country')}
              </h3>
            </div>
          ) : null}
          {/* 未选中国家 */}
          {countryListData.length > 0 && !selectedRegion ? (
            <div className={styles.fuLogisticsRightNoData}>
              <h3>
                {intl.get('mapping.country_shipping.select_ship_country')}
              </h3>
            </div>
          ) : null}
        </div>
      </div>
      </div>
    );
  }
}

SetShipping.defaultProps = {
  selectable: true
};
