import React, { useState } from 'react';
import { Popover, Icon } from 'antd';

import styles from './MoreAction.less';

const options = [
  {
    key: 'open_shipping',
    label: 'Open in Add Global Shipping method based on product'
  }
];

function MoreAction({ onChange, style }) {
  const [visible, setVisible] = useState(false);

  function handleVisibleChange(value) {
    setVisible(value);
  }

  function handleClick(type) {
    setVisible(false);
    onChange(type);
  }

  const content = (
    <div className={styles.m_a_item}>
      {options.map(i => (
        <div key={i.key} onClick={() => handleClick(i.key)} role="presentation">
          {/* {i.label} */}
          <div style={style} className={styles.m_a_btn}>
              <span>
                <i class="material-icons notranslate">local_shipping</i>
              </span>
          </div>
        </div>
      ))}
    </div>
  );

  return content
}

export default MoreAction;
