
    export default 
    {
    "supplier_optimizer": {
        "title": "Anbieter-Optimierer",
        "title_test": "Anderer Anbieter",
        "sub_title": "Basierend auf Ihren Bedürfnissen",
        "intro": "Sie können das gleiche Produkt finden, indem Sie den AliExpress-Produkt-URL-Link verwenden, und klicken Sie <a href=\"https://help.dsers.com/supplier-optimizer-for-shopify/\" target=\"_blank\">hier</a>, um mehr zu erfahren.",
        "placeholder": "Suche nach ähnlichen Produkten unter Verwendung der AliExpress-URL",
        "product_list": "Produktliste",
        "ship_from": "Versand von",
        "ship_to": "Versand nach",
        "sort_by": "sortieren nach",
        "product_title": "Produkt",
        "price": "Preis",
        "sale_number": "Verkaufsnummer",
        "product_rating": "Produkt-Bewertung",
        "store_rating": "Shop-Bewertung",
        "import_to_dsers": "Nach DSers importieren",
        "not_found": "Wir konnten kein ähnliches Produkt finden",
        "import_picture": "Bild importieren",
        "upload_tip": "Bitte laden Sie lokale Bilder hoch und wir werden für Sie nach dem gleichen Produkt in aliexpress suchen.",
        "click_to_upload": "Klicken Sie zum Hochladen",
        "click_or_drag": "Klicken Sie auf Hochladen oder ziehen Sie das Bild direkt hinein",
        "only_img": "Es können nur Bilder im PNG- und JNG-Format hochgeladen werden (Bildgröße innerhalb von 8 MB)",
        "delivery": "Liefertermin",
        "delivery_info": "Versand Details",
        "url_error": "Das URL-Format ist falsch, bitte geben Sie die AliExpress-Produkt-URL ein",
        "sys_error": "Das System wird derzeit gewartet, bitte versuchen Sie es in 2 Minuten erneut",
        "ship_tip": "Klicken Sie auf dieses Kästchen, wenn Sie die Informationen zur Versandzeit der Produkte importieren möchten",
        "search_tip": "Klicken Sie auf Suchen, um ähnliche Lieferanten zu finden",
        "shipping_price": "Versand Preis",
        "searched_product": "Verglichenes Produkt",
        "ship_method": "Versandmethode",
        "loading_state_1": "Bild hochladen...",
        "loading_state_2": "Bild analysieren...",
        "loading_state_3": "Produkte durchsuchen...",
        "have_try": "Probieren Sie es aus",
        "msg": `Wir sind dabei, die Funktion "Lieferantenoptimierung" anzupassen, und einige Daten können falsch sein. Wir arbeiten hart daran, dies zu beheben. Wir danken Ihnen für Ihr Verständnis und Ihre Geduld.`,
        "uploadimage": "BILD HOCHLADEN",
        "agency": "Agentur",
        "agency_2": "Agentur",
        "agencySub": "Sie können keinen zuverlässigen Agenten finden? <br/>\n\t\tDSers hilft Ihnen, sich mit verifizierten Agenten für Ihren Bedarf an Liefererfüllung zu verbinden.",
        "learnmore": "MEHR ERFAHREN",
        "Laura": "Das ist Laura, immer online, seriös, schnell, aufrichtiger Service für Sie.",
        "Ciel": "Zuverlässig, vertrauenswürdig. Das ist Ciel. Ihre Zeit und Ihre Mühe verdienen es, mit Ciels Fähigkeiten in Einklang gebracht zu werden.",
        "Nora": "Ziel ist es, einen günstigen Preis und einen zuverlässigen Versand zu versprechen. Wir freuen uns auf die Zusammenarbeit mit Ihnen!",
        "Michael": "Kompetent in allen Arten von Produkten zu finden. Akkurater und effizienter Service für Kunden.",
        "Rebekah": "Hallo. Ich bin Rebekah. Ich kann Sie mit billigen, aber qualifizierte Produkte und lösen Probleme für Sie in der Zeit.",
        "Chloe": "Vertraut mit dem Dropshipping-Geschäftsprozess. Service Zwecke: finden und erfüllen Ihre Anforderungen sorgfältig.",
        "Kevin": "Schwerpunkt auf der Lieferung von Produkten für Brasilien, Europa und die USA. Fabrikpreis mit schnellem Versand.",
        "Bess": "Chinesische Anbieter, die sich auf Dropshipping 3 Jahre Erfahrung, 24 Stunden online.",
        "Anchor": "DSers Massenlieferant, professionelles Agententeam, willkommen, alle DSers und produktbezogenen Fragen zu konsultieren.",
        "David": "Dies ist David, ich kann alle Ihre Probleme lösen! In der Zwischenzeit kann ich mit Ihnen über China oder andere sprechen.",
        "Tony": "Die Zufriedenheit des Kunden unterliegt umfassenden Faktoren, einschließlich des Preises. DSers, verpflichtet zu diesem.",
        "Alisa": "Alisa von Dsers Supply bietet Lieferung und kundenspezifischen Service. Fühlen Sie sich frei, Ihre Anfragen zu hinterlassen.",
        "Keith": "24 Stunden im Dienst für Sie! Ich bin ein Profi mit vier Jahren Erfahrung im Dropshipping-Geschäft mit Massenware!",
        "Cohen": "Hier ist Cohen, eifrig, Freunde zu machen. Treffen Sie Ihre Nachfrage und zerstreuen Ihren Ärger sind meine verantwortliche Arbeit!",
        "April": "Ich bin April.Ich werde Sie mit hochwertigen Produkten zu niedrigen Preisen und geben Ihnen den besten Service!",
        "Stone": "Das ist Stone, kompetent in der Erkundung eines guten Preises für die Kunden und geduldig im Umgang mit den Problemen der Kunden.",
        "Jessica": "Ich bin Jessica. Ich werde Ihnen von ganzem Herzen dienen, freue mich auf die Zusammenarbeit mit Ihnen, mein Freund!",
        "Duan": "Beste, schnellste, aufrichtig, und immer bereit für Ihre Anfrage！",
        "Sophia": "Hallo, meine Dropshipping Freunde, geben Sie mir Ihre Anfragen, Dsers wird das Beste tun, um Sie zufrieden zu machen.",
        "agenct": "Agent",
        "agencytext": "Lieferagenten sind auf das Dropshopping-Geschäft spezialisiert.\n\t\tDie Teams sind hauptsächlich in China ansässig, wo der Lieferservice am stärksten ist.",
        "search_product": "Produkt suchen",
        optimizerTitle:`Suche nach hochwertigen Lieferanten`,
        optimizerDesc_1:'1. Geben Sie die gewünschte URL in das Suchfeld ein oder verwenden Sie ein Bild',
        optimizerDesc_2:'2. In den Suchergebnissen können Sie einen Anbieter auswählen, der Ihren Bedürfnissen besser entspricht',
        "noSearch":`No search results, please change the URL or image to search again`
    }
}