import React from 'react';
import { connect } from 'dva';
import { Icon, Modal, Button, Input } from 'antd';
import debounce from 'lodash/debounce';

import intl from 'react-intl-universal';
import { filterUpdateExtLineItems, statusMap } from './util.js';
import { getLanguage } from '../../utils/utils';
import { confirmLanguage } from '../../common/sysconfig';
import { placeOrderViaExt } from 'utils/placeOrderViaExt.js';

import orderStyles from './Order.less';

@connect(({ order, loading }) => ({
  order,
  loading: loading.models.order,
  confirmLoading: loading.effects['order/place']
}))
export default class DuplicateOrderConfirm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      hasInputConfirm: false
    };
  }

  componentWillMount() {
    const { data } = this.props;

    this.placeOrderList = data;

    this.setState({
      data
    });
  }

  placeOrderList = null;
  currentLanguage = getLanguage();

  inputConfirm = e => {
    this.setState({
      hasInputConfirm:
        e.target.value.toLocaleLowerCase().trim() ==
        confirmLanguage[this.currentLanguage]
    });
  };

  handleOrder = debounce(() => {
    this.placeOrder(0);
  }, 300);

  // 点下单
  placeOrder = i => {
    const { status, dupOrderType } = this.props;
    const data = this.placeOrderList;
    const oneOrder = data[i];

    if (!oneOrder.checked || oneOrder.canOrderDisabled) {
      if (data.length > i + 1) {
        this.placeOrder(i + 1);
      } else {
        this.props.hidePlaceOrder();
      }
      return;
    }

    const { originalOldOrderMap, supplyPlatform } = this.props;
    const originalExt = originalOldOrderMap[oneOrder.id];

    const placeOrderFilteredExt = filterUpdateExtLineItems(supplyPlatform, oneOrder.ext, originalExt.ext);

    this.props.dispatch({
      type: 'order/place',
      payload: {
        data: {
          order_id: oneOrder.id,
          ext: placeOrderFilteredExt,
          store_id: oneOrder.storeId,
          is_check: true,
          status: statusMap[status],
          is_plugin: dupOrderType === 2
        },
        callback: d => {
          if(dupOrderType === 2) {
            placeOrderViaExt({...oneOrder, aeCaptchaSolveType: 2}).then(res => {
              // console.log(res);
              this.props.getExtensionPlaceProcess();
              if (data.length > i + 1) {
                this.placeOrder(i + 1);
              } else {
                this.props.hidePlaceOrder();
              }
            });
          } else {
            if (data.length > i + 1) {
              this.placeOrder(i + 1);
            } else {
              this.props.hidePlaceOrder();
            }
          }

        }
      }
    });
  };

  render() {
    const { visible, hidePlaceOrder, confirmLoading } = this.props;

    const { data, hasInputConfirm } = this.state;

    return (
      <Modal
        visible={visible}
        width={560}
        footer={false}
        maskClosable={false}
        closable={false}
        wrapClassName={orderStyles.duplicateOrderConfirm}
      >
        <div className={orderStyles.main}>
          <div className={orderStyles.icon}>
            <Icon type="question-circle" />
          </div>
          <div className={orderStyles.content}>
            <p className={orderStyles.desc}>
              {intl.get('duplicate_order_confirm.dec1')}
              {data.map(order => {
                return <span>{order.detail.name}</span>;
              })}
              {intl.get('duplicate_order_confirm.dec2')}
            </p>
            <p className={orderStyles.confirmTip}>
              {intl.get('duplicate_order_confirm.input_confirm')}
            </p>
            <div className={orderStyles.input}>
              <Input size="large" onChange={this.inputConfirm} />
            </div>
            <div className={orderStyles.btns}>
              <Button
                size="large"
                disabled={hasInputConfirm == false}
                loading={confirmLoading}
                onClick={this.handleOrder}
              >
                {intl.get('public.yes')}
              </Button>
              <Button type="primary" size="large" onClick={hidePlaceOrder}>
                {intl.get('public.cancel')}
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
