
    export default 
    {
    "import_list": {
        "title": "Importa lista",
        "dec": "Puoi importare e gestire i tuoi prodotti AliExpress qui, prima di postarli sul tuo negozio Shopify. Puoi postare un prodotto su negozi diversi e aggiungere molteplici tag ad ogni prodotto. Clicca <a href={href} target=\"_blank\">qui</a> per saperne di più. Dopo aver importato prodotti su DSers, solo il prezzo e l'inventario del prodotto su AliExpress verranno aggiornati in modo automatico. Se hai importato un prodotto da molto tempo, per essere sicuro che le informazioni del prodotto e del fornitore siano coerenti, ci raccomandiamo di controllare le informazioni del fornitore prima di promuovere quel prodotto.",
        "search_ali_placeholder": "Importa i prodotti AliExpress on l'URL oppure cerca qui i prodotti",
        search_tm_placeholder:`Importa i prodotti Tmall con l'URL oppure cerca qui i prodotti`,
        "add_tags": "Apply DSers Tags",
        "filter": "Filtra",
        "filter_product": "Filtra i prodotti",
        "filter_by_stores": "Filtra per negozi",
        "filter_by_tags": "Filtra per tag",
        "tag_management": "Create DSers Tags",
        "pagefly": "Vuoi ottenere più sconti? Prova PageFly per costruire pagine di vendite di prodotti ad alta conversione.",
        "pushed_to": "Spinto a",
        "store_s": "Negozi",
        "add_manage_tags": "Create and manage DSers tags",
        "add_manage_tags_tip": "You can create or delete tags to group your products. Click <a href={ href } target=\"_blank\">here</a> to get more information.",
        "add_new_tags": "Aggiungi Nuovi Tag",
        "push_modal_title": "Push Products",
        "push_modal_desc": "Select the store(s) you want to push your products to and check the boxes below to select the rules to apply to your products.",
        "no_store": "Nessun negozio",
        "pricing_rule_tip": "Una ",
        "pricing_rule_tip_1": "regola di tariffazione ",
        "pricing_rule_tip_2": " si applica a questo prodotto. Se hai cambiato manualmente il prezzo del prodotto su DSers, si prega di togliere la spunta a questa casella.",
        "pricing_rule_close_tip": "Il nostro sistema ha rilevato che hai cambiato manualmente il prezzo di questo prodotto su DSers. Di conseguenza, la",
        "pricing_rule_close_tip_1": " regola di tariffazione ",
        "pricing_rule_close_tip_2": " non si applicherà a questo prodotto. Se desideri usare la regola di tariffazione anziché il prezzo cambiato, spunta questa casella.",
        "from_oberlo_product_tip": "<text>Abbiamo notato che hai spinto un prodotto su Shopify che è stato migrato da Oberlo a DSers.</text> Di conseguenza, la ",
        "from_oberlo_product_tip_1": " regola dei prezzi ",
        "from_oberlo_product_tip_2": "non si applicherà a questo prodotto. Se vuoi usare la regola dei prezzi invece di quelli modificati, controlla questa casella.",
        "select_pricing_rule_mean_tip": "Nota bene: Le norme sui prezzi includono Modelli prezzi, Valuta e Assegna centesimi. Se spunti questa casella, la norma sui prezzi sarà applicata ai tuoi prodotti in base alle normative impostate nelle Impostazioni.",
        "unselect_pricing_rule_mean_tip": "Nota bene: Le norme sui prezzi includono Modelli prezzi, Valuta e Assegna centesimi. Se non spunti questa casella, nessuna di queste caratteristiche sarà applicata ai tuoi prodotti.",
        "product_s": "Prodotti",
        "link_to_shopify": "Collegati a Shopify",
        "no_product_tip": "Importa il tuo primo articolo",
        no_product_tip_1: `1. Devi andare su <a href='/app/find_suppliers'>Trova Fornitore</a> e copiare l'URL dell'articolo.`,
        no_product_tip_2: `2. Incolla l'URL che hai copiato nella casella della Lista di Importazione.`,
        search_no_product_tip: `Nessun risultato, modifica i criteri di ricerca e ripeti la ricerca.`,
        "tag_manage_delete_tip": "Vuoi cancellare questo tag? Scomparirà da tutti i prodotti taggati.",
        "add_product_tip": "Si prega di collegare DSers a un account AliExpress o non sarai in grado di importare prodotti.",
        "fetch_ae_prod_error": "La quantità dei prodotti che hai importato in DSers ha raggiunto il limite giornaliero. Si prega di riprovare domani",
        fetch_ae_prod_error1: `La quantità di prodotti AliExpress importati in DSers ha raggiunto il limite giornaliero, riprova domani.`,
        fetch_tm_prod_error1: `La quantità di prodotti Tmall importati in DSers ha raggiunto il limite giornaliero, riprova domani.`,
        "ae_issue": "AliExpress sta avendo dei problemi, si prega di riprovare più tardi",
        "delete_product_tip": "Sei sicuro di voler cancellare questi prodotti? Saranno cancellati dalla lista di prodotti importati ma non dal tuo negozio Shopify.",
        "ae_prod_not_found": "Il prodotto non è disponibile su AliExpress, si prega di importare un altro prodotto.",
        "service_unavaliable": "L'operazione è fallita a causa di un problema di rete, si prega di riprovare.",
        "pushing": "Il prodotto è stato inviato",
        "push_success": "Prodotto inviato con successo",
        "push_desc": "prodotti inviati",
        "push_desc_ok": "Spedizione avvenuta con successo",
        "import_review": "Importa Recensioni",
        "push_desc_fail": "Spedizione fallita",
        "push_error_0": "Il prodotto contiene più di 100 Codici",
        "push_error_1": "Il limite massimo di creazione di Codici su Shopify è stato raggiunto",
        "push_error_2": "Negozio Shopify non disponibile",
        "push_error_3": "Il token del negozio è scaduto",
        "push_error_4": "Le informazioni sul prodotto superano i 256KB",
        push_error_4_tip: `Le informazioni sul prodotto sono comprensive di Titolo, Descrizione, Immagini, SKU, ecc. Si consiglia di eliminare alcune informazioni del prodotto prima di effettuare l'invio del prodotto stesso.`,
        "push_error_5": "La richiesta è scaduta, si prega di riprovare",
        "check_push": "Verifica lo stato",
        "dec_change": "Puoi importare e gestire qui i prodotti dei tuoi rivenditori prima di pubblicarli sul negozio Shopify.",
        "dec_change_tit": "Puoi pubblicare un prodotto in diversi negozi e aggiungere tag diversi a ciascun prodotto. Dopo aver importato i prodotti su DSers, solo il prezzo e l'inventario dei prodotti del rivenditore saranno automaticamente aggiornati su DSers. Se hai importato un prodotto da tempo, in modo da garantire la coerenza delle informazioni del prodotto e del rivenditore, si consiglia di controllare le informazioni del rivenditore prima di sponsorizzare tale prodotto.",
        "shopping_info": "Shipping info",
        "add_manage_tags2": "Apply DSers Tags",
        "add_management_tags": "You can apply tags to group your products.",
        "add_manage_tags_tip2": "You can add or delete tags to group your products.",
        "add_manage_tags_tip3": " You can filter your products by stores, tags and push status.",
        "push_success1": "Product(s) sent",
        "push_fail": "Spedizione fallita",
        "check_detail": "Controlla dettagli prodotto",
        "edit_detail": "Modifica dettagli prodotto",
        "delete_product": "Deleted Product",
        "addTag": "Create a new tag",
        "confim": "Confirm",
        "variants_limit": "Shopify daily variant creation limit reached. Please try again later & click <a href='{url}' target='_blank'>here</a> for more details.",
        "pushTitle_1": "Selezione del negozio",
        "pushTitle_2": "Preferenze",
        "pushTitle_3": "Informazioni sulla promozione",
        "repeated_tag": "This tag already existed",
        "search_or_import": "Importa o cerca",
        filter_by_market_place:'Filtra per marketplace',
    }
}