import React from 'react';

import styles from './SessionOfImportList.less';

import Point from './Point';
import BotMessage from './BotMessage';
import UserMessage from './UserMessage';

function SessionOfImportList({ status, onSubmit, forwardedRef, onWatchVideo }) {
  return (
    <div className={styles.rightChat}>
      {/* submit button */}
      <Point
        ref={forwardedRef}
        style={{ position: 'fixed', display: 'none' }}
        onClick={onSubmit}
      />
      <div className={styles.chatCon}>
        {status === 'greeting' ? (
          <>
            <BotMessage showAvatar>
              <p>
                The product has now been imported successfully to the{' '}
                <b>Import list</b> on DSers.
              </p>
              <div>
                You can <b>edit the product information</b> here before it goes
                to your store.
              </div>
            </BotMessage>
            <BotMessage>
              <p>
                Let’s click <b>Push to Shopify</b> to continue.
              </p>

              <div>
                The test product will <b>NOT be visible</b> to your customers
              </div>
            </BotMessage>
          </>
        ) : null}
        {status === 'awaitConfirm' ? (
          <>
            <BotMessage showAvatar>
              You can set things up here before sending the product to your
              store, but let’s leave that for later
            </BotMessage>
            <BotMessage>
              Click <b>Push to Shopify</b> to continue
            </BotMessage>
          </>
        ) : null}
        {status === 'fail' ? (
          <>
            <BotMessage showAvatar>
              Oops
              <span role="img" aria-label="icon">
                😱
              </span>
              , it seems that there was an unexpected issue and{' '}
              <b>the product failed to be pushed</b> to Shopify
            </BotMessage>
            <UserMessage showAvatar>
              <span
                onClick={() => onWatchVideo('UDDZ1ZTk6fc')}
                data-category="tutorial"
                data-action="watching a related video"
                role="button"
                tabIndex="-1"
              >
                It’s OK. I would like to watch a related video
              </span>
              <Point
                style={{ bottom: 20, left: '30%', position: 'absolute' }}
                showPoint={false}
              />
            </UserMessage>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default SessionOfImportList;
