import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import Style from './index.module.less';
import user from 'models/user';
import GuideRefresh from '../../GuideRefresh';
import { GuildeAction } from 'actions';
import intl from 'react-intl-universal';
import closeImg from 'assets/oberlo-guid/close.png'
const OBanber = ({
  update,
  plan,
  guildBtnUnify,
  isChildAcount,
  isBillStore,
  onceBindAE,
  onceBindPlan,
  onceDownloadChrome
}) => {
  const [currentMessage, setCurrentMessage] = useState(null);
  const [timeNum, setTimeNum] = useState(0);
  const [visible, setVisble] = useState(true);
  const [firstSign, setFirstSign] = useState(true);
  const [showRefresh, setShowRefresh] = useState(false);
  /**
   * 首次进入逻辑  还需加入是否是Oberlo 用户
   */
  const firstSignIn = () => {
    if (firstSign) {
      setTimeout(() => {
        if (!window.location.pathname.endsWith('/link-store' || '/home')) {
          window.EVENT.emit(GuildeAction.OblerloGuide_Banner, Date.now(), true);
        }
        setFirstSign(false);
      }, 100);
    }
  };
  /**
   * 判断所执行的banner
   * @param {*} cb 回调函数
   */
  const SwitchBanner = cb => {
    if (!window.IS_BIND_AE && !onceBindAE) {
      //未绑定AE
      cb(1);
      return;
    }
    if (onceBindPlan && isChildAcount === 1 && isBillStore === 'yes') {
      //未确认套餐 并且 不是子账户
      cb(2);
      return;
    }
    if (!window.CHROME_PLUG_INSTALL && !onceDownloadChrome && !localStorage.getItem('stopChromeBehaviors')) {
      //未确认插件
      cb(3);
      return;
    }
    cb(4);
  };
  useEffect(() => {
    firstSignIn();
    setVisble(false);
    clearTimeout(timeNum);
    // 页面停留3秒以上就会由下往上出现这个底部弹窗
    if (!['', null, undefined].includes(update)) {
      setTimeNum(
        setTimeout(() => {
          if (window.location.pathname.endsWith('/home')) {
            return
          }
          SwitchBanner(step => {
            if (
              intl.get('guide.OBanberWords').filter(item => item.step === step)
                .length
            ) {
              setCurrentMessage(intl.get('guide.OBanberWords')[step - 1]);
              setVisble(true);
            }
          });
        }, 3000)
      );
    }
  }, [update,onceBindAE,onceDownloadChrome,plan,isBillStore,onceBindPlan]);
  return (
    currentMessage && (
      <>
        <div
          className={
            visible ? Style.OberloGuideBanner : Style.OberloGuideBannerHide
          }
        >
          {
           currentMessage.step == 3 && 
          <img src={closeImg}  
               className={Style.closeImgBanner} 
               onClick={()=>{
                   localStorage.setItem('stopChromeBehaviors','true')
                   window.EVENT.emit(GuildeAction.OblerloGuide_Banner, Date.now(), true);
               }}
            />
          }
          <div className={Style.TextTip}>
            <div className={Style.BannerTitle}>{currentMessage.title}</div>
            <div
              className={Style.BannerContent}
              dangerouslySetInnerHTML={{
                __html:
                  currentMessage.step !== 2
                    ? currentMessage.text
                    : currentMessage.textArr.filter(
                        item => item.type === plan
                      )[0]?.text
              }}
            ></div>
          </div>
          <Button
            className={Style.BtnStyle}
            onClick={() => {
              setVisble(false);
              guildBtnUnify(currentMessage.step, () => {
                setShowRefresh(true);
              });
            }}
          >
            {currentMessage.btn}
          </Button>
        </div>
        <GuideRefresh visible={showRefresh} />
      </>
    )
  );
};
export default React.memo(OBanber);
