
    export default 
    {
    "mapping": {
        "title": "Verwaltung der Lieferanten",
        "description": "Die Produktdaten von Shopify (wie Titel, Beschreibung, Bilder, Varianten, etc.) bleiben nach dem Hinzufügen eines Lieferanten erhalten.",
        "description_tip": "Bitte beachten Sie: Die Zuordnung geht verloren, wenn Sie Optionen oder Varianten in Shopify bearbeiten/löschen/hinzufügen.",
        "tab_btn_basic_mapping": "Grundlegendes Mapping",
        "tab_btn_advanced_mapping": "Erweiterte Kartierung",
        "tab_btn_bogo": "BOGO",
        "tab_btn_bundle": "Bündel",
        "tab_btn_verified": "Geprüft",
        "shopify_product": "Shopify-Produkt",
        "default_ali_product": "Standard-{platform}-Produkt",
        "edit_shipping": "Versand bearbeiten",
        "set_shipping": "Versand einstellen",
        "import_a_product": "Ein Produkt importieren",
        "substitute_ali_product": "{platform}-Produkt ersetzen",
        "second_product_tip": "Die zweite Option ist eine Option, die während der automatischen Auftragsabwicklung verwendet wird, wenn einige Varianten für die Standardoption nicht verfügbar sind.",
        "alternate_products": "Alternative Produkte",
        "alternate_products1": "Add Products",
        "import_placeholder": "Eingabe aliexpress Produkt url",
        "status": "Status",
        "turn_status": "Aktivieren Sie die {method} Mapping-Methode",
        "close_all_mapping": "Möchten Sie alle Mapping-Methoden deaktivieren? Dann kann Ihr Produkt nicht über DSers an AliExpress weitergeleitet werden.",
        "open_new_mapping": "Möchten Sie zu {switch} wechseln? Mapping wechseln? Ihre Bestellungen werden dann nach {switch} verarbeitet. Mapping",
        "variant": "Variante",
        "ship_to": "Versand an",
        "supplier_variant": "Lieferant & Variante",
        "import_sp_select_var": "Lieferant importieren & Variante auswählen",
        "import_ae_title": "Importieren Sie einen Anbieter, indem Sie einen AliExpress-Link hier einfügen",
        "import_tm_title": "Importieren Sie einen Anbieter, indem Sie einen Tmall-Link hier einfügen",
        "clear": "löschen",
        "select_a_variant": "Wählen Sie eine Variante",
        "import_ae_first": "Zuerst einen Aliexpress-Lieferanten importieren",
        "import_tm_first": "Zuerst einen Tmall-Lieferanten importieren",
        "submit": "einreichen",
        "advanced_rule": "Legen Sie die Lieferanten entsprechend den Zielländern Ihrer Bestellung fest. Sie können maximal 10 Lieferanten für eine Variante einrichten.",
        "supplier_product": "Lieferant Produkt",
        "more_action": "Mehr Aktion",
        "clear_all": "Alle löschen",
        "advanced_more_action_copy": "Kopieren Sie die Länder und die entsprechenden Lieferanten in andere Varianten, nachdem Sie die erste Variante zugeordnet haben. Sie müssen noch die richtigen SKUs für andere Varianten auswählen.",
        "buy": "KAUFEN",
        "buy_desc": "Ordnen Sie einen Lieferanten für das Hauptprodukt zu, das Sie verkaufen",
        "buy_more_action_copy": "Kopieren Sie die SKU in andere Varianten, nachdem Sie die erste Variante zugeordnet haben. Sie können sie ändern, wenn Sie eine andere benötigen.",
        "x": "X",
        "x_desc": "Menge für das Hauptprodukt",
        "change_qty_to": "Menge ändern in",
        "get": "HIER",
        "get_desc": "Ordnen Sie einen Lieferanten für das von Ihnen angebotene Geschenkprodukt zu",
        "get_more_action_copy": "Kopieren Sie die SKU in andere GIFT-Varianten, nachdem Sie die erste Variante zugeordnet haben. Sie können sie ändern, wenn Sie eine andere Variante benötigen.",
        "y": "Y",
        "y_desc": "Menge für <br /> das Geschenkprodukt",
        "bundle_products": "Produkte bündeln",
        "bundle_more_action_copy": "Kopieren Sie die SKU in andere Varianten, <br />nachdem Sie die erste Variante zugeordnet haben.<br /> Sie können sie ändern, wenn Sie eine andere benötigen.",
        "import_btn_tip": "<span>Sie können das Mapping wie folgt einrichten: <br />\n\t\t\t\t\t\t\t\t\t\t\t1. Fügen Sie hier einen AliExpress-Link ein. <br />\n\t\t\t\t\t\t\t\t\t\t\t2. Klicken Sie auf Importieren <br />\n\t\t\t\t\t\t\t\t\t\t\t3. Legen Sie das Produkt als Standard/zweite Option fest <br />\n\t\t\t\t\t\t\t\t\t\t\t4. Füllen Sie ALLE Optionen und Werte aus, um das Mapping<br /> abzuschließen Lesen \n\t\t\t\t\t\t\t\t\t\t\t<a\n\t\t\t\t\t\t\t\t\t\t\thref=\"https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/\"\n\t\t\t\t\t\t\t\t\t\t\ttarget=\"_blank\"\n\t\t\t\t\t\t\t\t\t\t>Sie diesen Artikel</a></span>",
        "import": "importieren",
        "set": "einstellen",
        "no_record_yet": "Noch kein Eintrag",
        "ShopifyProduct": "Shopify-Produkt",
        "verified": {
            "title": "Produkt-Infos",
            "cost": "Kosten",
            "shipping": "Versand",
            "ePacket": "ePaket",
            "package_request": {
                "title": "Paket Anfrage",
                "option1": "Säcke（defualt)",
                "option2": "Schachtel ($0.2／Paket)",
                "option3": "Schachtel mit Logo ($0.2／Paket)",
                "option4": "Schachtel mit Karte($0.2／Paket)",
                "option5": "Box mit Logo und Karte($0.2／Package)"
            },
            "upload_logo_file": "Logodatei hochladen",
            "leave_a_meaage": "Hinterlassen Sie eine Nachricht",
            "go_to_setting": "Gehen Sie zu Einstellungen > Lieferant, um den gesamten Shop einzurichten.",
            "sample_on_way": "Muster ist auf dem Weg.",
            "tracking_no": "Nachverfolgung NO",
            "request_sample": "Muster anfordern",
            "save": "Speichern",
            "disable": "Deaktivieren",
            "enable": "Aktivieren",
            "cancel": "Abbrechen"
        },
        "publish_first": "Publish first then add suppliers",
        "upload_img_tip": "Image must smaller than 1MB!",
        "verified_save": "Saved succeed.",
        "copy_link_tip": "Replication fails, goods need to be pushed first",
        "copy_succ": "Successfully copied",
        "copy_fail": "copy failed",
        "delete_default_option_tip": "Do you want to delete the default option? You can add it again in the record?",
        "delete_second_option_tip": "Do you want to delete the second option? You can add it again in the record?",
        "delete_history_tip": "Do you want to remove this product information from product history?",
        "yes": "Ja",
        "no": "Nein",
        "ok": "Ok",
        "cancel": "Abbrechen",
        "save_option_confirm": {
            "set_shipping_tip": "Bitte richten Sie die Versandart für das Ersatzprodukt ein.",
            "save_option_two_tip": "Bitte beachten Sie, dass die Aktivierung von Ersatzprodukten zusätzliche Versandkosten verursachen kann, wenn Sie zwei verschiedene Lieferanten für eine Bestellung haben."
        },
        "clear_all_shipping": "Möchten Sie alle Konfigurationsinformationen löschen?",
        "save_shippinp_succ": "Erfolgreich gespeichert.",
        "setting_activated": "Einstellung aktiviert",
        "country_shipping": {
            "shipping_setting": "Versand-Einstellung",
            "ship_to": "Versenden an",
            "clear_all": "Alle löschen",
            "search": "Suche",
            "shipping_country": "Versandunternehmen",
            "shipping_cost": "Kosten für den Versand",
            "time": "Geschätzte Lieferfrist",
            "tracking_information": "Informationen zur Sendungsverfolgung",
            "free_shipping": "Kostenloser Versand",
            "available": "Verfügbar",
            "unavailable": "Nicht verfügbar",
            "not_ship_this_country": "Dieses Produkt kann nicht in dieses Land geliefert werden.",
            "select_ship_country": "Bitte wählen Sie das Land, in das Sie liefern möchten."
        },
        "product_whic": "Möchten Sie, dass dieses Produkt als:",
        "default_option": "Standardoption",
        "second_option": "Zweite Option",
        "mapped": "Abgebildet",
        "default": "Standard",
        "succeed": "Erfolgreich",
        "repeat_option": "Sie können es nicht speichern, weil sich die Variantenoptionen wiederholen",
        "select_variant": "Bitte wählen Sie entsprechende Varianten aus. Andernfalls können Sie das Mapping nicht speichern.",
        "delete_pool_product": "Wenn Sie diesen Lieferanten von Ihrer Zuordnungsseite löschen, wird auch die Zuordnung Ihres Produkts gelöscht",
        "delete_mapping_product": "Möchten Sie die Option { num, plural, =0 { default } other {second} } Option löschen? Sie können sie später im Datensatz wieder hinzufügen.",
        "delete_del_variant_tip": "Are you sure you want to delete the variant(s) on DSers? Once deleted, orders for the variant(s) can no longer be processed.",
        "same_country_tip": "Sie können dieses Land nicht erneut für diese Variante auswählen.",
        "same_sku_tip": "Wenn Sie dasselbe Produkt sowohl für das Hauptprodukt als auch für das Geschenkprodukt einrichten möchten, ändern Sie bitte die Menge für das Hauptprodukt, anstatt sie für die Geschenkspalte erneut zu importieren.",
        "import_same_sku_tip": "Sie können die gleiche SKU nicht zweimal für eine Variante importieren",
        "Variant_delete_tip_one": "Sie haben die untenstehende(n) Variante(n) in Shopify gelöscht. Bitte:",
        "Variant_delete_tip_two": "1. Führen Sie die Zuordnung erneut durch, um die Synchronisierung des Bestands von AliExpress fortzusetzen.",
        "Variant_delete_tip_three": "2. Fügen Sie den Lieferanten zu der/den unten stehenden Variante(n) hinzu, um Bestellungen für die gelöschte(n) Variante(n) zu bearbeiten.",
        "overrun_warning": "Sie haben das Tageslimit für den Zugriff auf die Produktinformation überschritten",
        "standProductNum": "Up to 5 AliExpress products can be imported",
        "repeat_alert": "This supplier is already set as default or substitute supplier and cannot be set again",
        clear_tip_alert: 'Wenn Sie den Artikel wechseln, um ihn als Hauptlieferant einzustellen, wird der Sekundärlieferant geleert.',
        forbidden_alert: 'Die Basiszuordnung kann nur für die Zuordnung zu Lieferanten aus einer Lieferantenplattform verwendet werden.',
        "switch_mapping": "Switch between Default and Substitute supplier",
        "bundle_rule": "Set up a supplier for the product in your Bundle. You can set up to 10 suppliers for a variant",
        "getProduct_error": "The product you entered was not found",
        "standard": {
            "product_name": "Shopify-Produkt",
            "product_min_name": "Supplier Product",
            "product_title": "Set up a supplier for the product in your Standard Mapping",
            "remove_con": "Clicking 'CONFIRM' will remove this supplier form the list of available supplier for this product and remove the existing mapping of other SKUs using it. Do you want to confirm?",
            "remove_title": "Remove Supplier",
            "remove_confirm": "CONFIRM"
        },
        "replace_product": {
            "btn_title": "PRODUKT ERSETZEN",
            "modal_tip": "Die Varianten des Shopify-Produkts werden gelöscht und durch Varianten eines neuen Produkts ersetzt. Im nächsten Schritt können Sie die Details des Produkts bearbeiten.",
            "shopify": "Shopify-Produkt",
            "ali_express": "AliExpress product",
            "tm_express": "Tmall product",
            "price_rule": "Apply Pricing Rule",
            "cancle": "CANCEL",
            "url_error": "Please input valid AliExpress product URL.",
            "keep_description": "Keep the original Shopify description",
            "update_description": "Update description",
            "keep_image": "Keep the original Shopify images",
            "new_image": "Choose new images",
            "suucess": "Successfully replaced",
            "failed": "Failed to replace",
            replace_placeholder:'Produkt-URL hier einfügen',
        },
        "gift_product": "Geschenk Produk",
        "main_product": "Hauptprodukt",
        "sku_lose_tip": "The mapping was lost because your supplier changed the SKU. Please re-map your products according to the latest SKU on AliExpress or change supplier.",
        "replace_product_tip": "If you have any open orders with the old product that haven't been placed yet, please <a href='https://help.dsers.com/basic-mapping-advanced-mapping-bogo-bundle-introduction-for-shopify/' target='_blank'>map</a> or <a href='https://help.dsers.com/re-order-awaiting-payment-orders-for-shopify/#modify-canceled-order' target='_blank'>edit</a> the order with selected new variants that matches the desired product, then process orders {toAliExpress}. You can click REPLACE PRODUCT after.",
        "confirm_delete_all_variant_title": "Delete Variant(s)",
        "confirm_delete_all_variant": "Are you sure you want to delete the variant(s) on DSers? Once deleted, orders for the variant(s) can no longer be processed.",
        "delete_variant_collapse_tip": `Sie haben die untenstehende(n) Variante(n) auf Shopify gelöscht. Bitte:
        <br />Fügen Sie der/den untenstehenden Variante(n) Lieferanten hinzu, um Bestellungen für die gelöschte(n) Variante(n) zu bearbeite`,
        delete_variant_collapse_supplier_tip:`Sie haben die untenstehende(n) Variante(n) auf Shopify gelöscht. Bitte:
        <br />1. Führen Sie die Zuordnung erneut durch, um die Synchronisierung des Bestands von Lieferanten fortzusetzen.
        <br />2. Fügen Sie der/den untenstehenden Variante(n) Lieferanten hinzu, um Bestellungen für die gelöschte(n) Variante(n) zu bearbeite`,
        "delete_options_title": "Delete Option",
        "delete_supplier_title": "Delete Supplier",
        "open_new_mapping_title": "Switch Mapping",
        "close_all_mapping_title": "Deactivate Mapping",
        "delete_supplier_description": "If you delete this supplier from your mapping page, the mapping of your product will be deleted.",
        offline_error_tip:'Das Produkt ist offline',
        mapping_info_error_tip:'Es gibt ein Problem mit den Produktdaten, die Sie zuordnen, bitte ordnen Sie sie neu zu',
        Findsimilar:'Ähnliches finden',
        distoryImgTip:`Das aktuelle Produktbild kann nicht erkannt werden, und die Funktion "Supplier Optimizer" kann hier vorerst nicht verwendet werden. Wir entschuldigen uns für die entstandenen Unannehmlichkeiten.`
    }
}