import {
  getProductList,
  getOrderList,
  uploadproduct,
  uploadorder,
  subOrder,
  getLoopOrder,
  getAeProductDetails
} from '../services/newfeature';

export default {
  namespace: 'newfeature',

  state: {
    list: [],
    productList: null
  },

  effects: {
    *record({ payload }, { call }) {
      yield call(getRecordList, payload);
    },
    *prouctlist({ payload }, { call, put }) {
      const response = yield call(getProductList, payload);
      yield put({
        type: 'setProduct',
        payload: response
      });
    },
    *orderlist({ payload }, { call }) {
      yield call(getOrderList, payload);
    },
    *productupload({ payload }, { call }) {
      yield call(uploadproduct, payload);
    },
    *orderupload({ payload }, { call }) {
      yield call(uploadorder, payload);
    },
    *order({ payload }, { call }) {
      yield call(subOrder, payload);
    },
    *looporder({ payload }, { call }) {
      yield call(getLoopOrder, payload);
    },
    *aeProductDetail({ payload }, { call }) {
      yield call(getAeProductDetails, payload);
    }
  },

  reducers: {
    setProduct(state, { payload }) {
      return {
        ...state,
        productList: payload.data
      };
    },
    setNotificationToken(state, { payload = {} }) {
      return {
        ...state
      };
    }
  }
};
