
    export default 
    {
    "login": {
        "title": "Inicia sesión en tu Dsers",
        "email": "Correo electrónico",
        "password": "Contraseña",
        "cap_placeholder": "Captcha",
        "log_in": "Iniciar sesión",
        "forgot_password": "¿OLVIDASTE LA CONTRASEÑA",
        "enter_username": "Por favor, introduce el nombre de usuario",
        "enter_password": "¡Por favor, introduce la contraseña!",
        "email_password_error": "error de correo electrónico o contraseña",
        "empty": "La contraseña no puede estar vacía",
        "text_title": "5.0<span class={titlek}> Clasificación por estrellas con</span>",
        "text_desc": "10000+ <br/> <span class={titlek}>Reseñas en Shopify App Store</span>",
        "text_title_2": "Sustitución de <br/> <span class={titlek}>Oberlo recomendada oficialmente por Shopify  </span>",
        "text_desc_2": "10000+ <br/> <span class={titlek}> Comentarios 5 estrellas <br/> en la tienda de aplicaciones de Shopify</span>",
        "noHaveText": "¿No tienes una cuenta?",
        "createAccount": "CREAR CUENTA",
        "emailrequired": "el correo electrónico es obligatorio",
        "error": "Error de correo electrónico o contraseña."
    }
}