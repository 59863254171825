
    export default 
    {
    "supplier_optimizer": {
        "title": "Ottimizzatore di Fornitori",
        "title_test": "Altri fornitori",
        "sub_title": "In base ai tuoi bisogni",
        "intro": "Puoi trovare lo stesso prodotto usando l'URL del link al prodotto AliExpress, e cliccando <a href=\"https://help.dsers.com/supplier-optimizer-for-shopify/\" target=\"_blank\">qui</a> per saperne di più.",
        "placeholder": "Cerca prodotti simili usando l'URL AliExpress",
        "product_list": "Lista Prodotti",
        "ship_from": "Spedisci da",
        "ship_to": "Spedisci a",
        "sort_by": "Ordina per",
        "product_title": "Prodotto",
        "price": "Prezzo",
        "sale_number": "Numero di vendita",
        "product_rating": "Valutazione prodotto",
        "store_rating": "Valutazione negozio",
        "import_to_dsers": "Importa in DSers",
        "not_found": "Non siamo riusciti a trovare un prodotto simile",
        "import_picture": "Importa Immagine",
        "upload_tip": "Esegui l'upload di immagini in locae e cercheremo per te lo stesso prodotto su AliExpress",
        "click_to_upload": "Clicca per uploadare",
        "click_or_drag": "Clicca Upload o trascina direttamente l'immagine qui",
        "only_img": "Possono essere caricate solo immagini in formati PNG e JNG (le dimensioni dell'immagine non possono superare gli 8MB)",
        "delivery": "Data di consegna",
        "delivery_info": "Dettagli sulla spedizione",
        "url_error": "Il formato dell'URL è sbagliato, si prega di inserire l'URL del prodotto AliExpress",
        "sys_error": "Il sistema è attualmente in manutenzione, si prega di riprovare fra 2 minuti",
        "ship_tip": "Spunta questa casella se desideri importare le informazioni sui tempi di spedizione del prodotto",
        "search_tip": "Clicca su Cerca per trovare fornitori simili",
        "shipping_price": "Costo di spedizione",
        "searched_product": "Prodotto confrontato",
        "ship_method": "Metodo di spedizione",
        "loading_state_1": "Caricamento immagine in corso…",
        "loading_state_2": "Analisi dell'immagine in corso…",
        "loading_state_3": "In cerca di prodotti…",
        "have_try": "Provalo",
        "msg": `Stiamo modificando la funzione Supplier Optimizer e alcuni dati potrebbero essere errati. Stiamo lavorando sodo per risolvere il problema. Grazie per la comprensione e la pazienza.`,
        "uploadimage": "CARICA IMMAGINE",
        "agency": "Agenzia",
        "agency_2": "Agenzia",
        "agencySub": "Non trovi un agente affidabile? <br/> DSers ti aiuta a metterti in contatto con un agente verificato per le esigenze del tuo rivenditore.",
        "learnmore": "SCOPRI DI PIÙ",
        "Laura": "Lei è Laura. Sempre online, seria, veloce, un servizio sincero per te.",
        "Ciel": "Affidabile, questa è Ciel. Il tuo tempo e i tuoi sforzi meritano di essere abbinati alla bravura di Ciel.",
        "Nora": "Il mioobiettivo è un prezzo economico e una spedizione affidabile. Non vede l'ora di iniziare a collaborare con te!",
        "Michael": "Esperta nella ricerca di tutti i tipi di prodotti. Servizio accurato ed efficiente per i clienti.",
        "Rebekah": "Ciao. Io sono Rebecca. Posso procurarti prodotti economici ma di qualità e risolvere problemi entro i tempi.",
        "Chloe": "Ho familiarità con i processi di dropshipping business. Scopo del servizio: trovare e rispettare appieno le tue esigenze.",
        "Kevin": "Interesse sui prodotti in Brasile, Europa, Stati Uniti. Prezzi di fabbrica con consegna rapida.",
        "Bess": "Rivenditore cinese, 3 anni di esperienza nel dropshipping, online 24/24.",
        "Anchor": "DSers, fornitore di massa, squadra di agenti professionali, sei pregato di fare domande su DSers o sui prodotti.",
        "David": "Io sono David e posso risolvere tutti i tuoi problemi! Nel frattempo posso parlarti della Cina o di qualcos'altro.",
        "Tony": "La soddisfazione del cliente è soggetto ai fattori complessivi, come il prezzo. DSers si impegna in questo.",
        "Alisa": "Alisa di DSers offre servizi di fornitura e personalizzati. Sentiti libero di fare qualunque richiesta.",
        "Keith": "24 ore al lavoro per te! Sono un professionista con quattro anni di esperienza in un business di rivendita di dropshipping di massa!",
        "Cohen": "Io sono Cohen, non vedo l'ora di fare amicizia. Il mio lavoro consiste nel soddisfare le tue esigenze e attenuare i tuoi fastidi!",
        "April": "Sono April. Ti fornirò prodotti di alta qualità a basso prezzo con un servizio eccezionale!",
        "Stone": "Ciao, sono Stone, esperto nel domandare prezzi equi per clienti e paziente nel gestire i problemi dei clienti.",
        "Jessica": "Sono Jessica. Ti servirò con tutto il cuore, non vedo l'ora di collaborare con te, amico!",
        "Duan": "Il migliore, il più veloce, il più sincero, sempre pronto per le tue richieste!",
        "Sophia": "Ciao, amici del dropshipping, fatemi qualche richiesta, DSers farà del suo meglio per soddisfarvi.",
        "agenct": "Agente",
        "agencytext": "Gli agenti sulla rifornitura si dedicano specificamente al business del dropshipping.",
        "search_product": "Cerca prodotto",
        optimizerTitle:`Cerca fornitori di qualità`,
        optimizerDesc_1:`1. Inserisci l'URL che desideri nella casella di ricerca o utilizza un'immagine.
        `,
        optimizerDesc_2:`2. Nei risultati, potrai scegliere il fornitore migliore secondo le tue esigenze.
        `,
        "noSearch":`No search results, please change the URL or image to search again`
    }
}