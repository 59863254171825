import React, { PureComponent } from 'react';
import { connect } from 'dva';
import { Spin, message, Modal } from 'antd';
import qs from 'qs';
import intl from 'react-intl-universal';

import { detectPlatform } from 'services/login';
import { logoutMvpAccount } from 'utils/justifyMVPAccountUtils';

@connect()
export default class RedirectPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: true
    };
  }

  componentDidMount() {
    window.localStorage.removeItem('ifMigration')
    this.parms = qs.parse(location.search.slice(1));
    if (this.parms) {

      this.manageApp(this.getShopifyRedirect);

      // this.getShopifyRedirect();
    } else {
      window.location.href = '/app/';
    }
  }

  parms = null; // url 参数
  rectBackData = null;
  bindEmail = '';

  /**
   *  add app 打点方法
   * @returns 
   */
  manageApp = async (callback) => {
    const shop = this.getQueryString('shop') || '';
    let custom_info = [{name: 'shop_name',value: shop.substring(0, shop.indexOf(".myshopify.com"))}]

    const params = {
      action: 'add app',
      custom_info: custom_info
    }

    window.DSERS_CUSTOM_COLLECT && window.DSERS_CUSTOM_COLLECT(params);
    callback();
  }


  /**
   * 
   * @param {*} name url处理方式
   * @returns 
   */
  getQueryString = (name) => {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)",'i');
    var res = window.location.search.substr(1).match(reg);

    if(!res) return;

    if(res[2]){
      return res[2];
    }else{
        return null;
    }
  }

  getShopifyRedirect = () => {
    this.props.dispatch({
      type: 'auth/redirect',
      payload: {
        data: {
          ...this.parms
        },
        cancelMessage: true,
        callback: async d => {
          this.rectBackData = d.data;

          if (d.code === 2000) {
            // 如果是mvp shopify授权
            if(d.data.redirect_url?.includes?.('/shopify-user-bff/v1/shopify/auth/redirect')) {
              localStorage.removeItem('dser-pro-authority');
              window.location.href = d.data.redirect_url;
              return;
            } else {
              // 退出mvp账号 如果已登录的话 防止无法进入shopify老平台
              await logoutMvpAccount();
            }
            
            if(d?.msg === 'user is migration') {
              window.location.href = '/app/migrationWaiting?isAuth=1'
              return;
            }

            if (d.data.token) {
              localStorage.setItem('dser-pro-authority', d.data.token);

              // 需要检测平台
              detectPlatform({ data: { action_type: 1 } })
                .then(res => {
                  if (res && res.code === 2000) {
                    if (
                      res.data?.action_operation === 'wix' ||
                      res.data?.action_operation === 'ebay'
                    ) {
                      window.localStorage.removeItem('dser-pro-authority');
                      window.location.href = '/app/auth-error';
                    } else {
                      window.location.href = '/app/';
                    }
                  } else {
                    window.location.href = '/app/';
                  }
                  return res;
                })
                .catch(error => {
                  window.location.href = '/app/';
                });

              return;
            }

            this.setRedirect(d.data);
          } else {
            if(d?.msg === 'user is migration') {
              window.location.href = '/app/migrationWaiting?isAuth=1'
            }
            message.error(d.msg);
          }
        }
      }
    });
  };

  // 处理返回数据
  setRedirect = data => {
    if (!data.exist) {
      // 绑定关系不存在
      if (!data.is_login) {
        // 没登陆
        window.location.href = data.redirect_url || '/app/';
      } else {
        // 已登录
        Modal.confirm({
          title: intl.get('h5_redirect.bind_tip', {
            email: data.current_user_email
          }),
          okText: 'Yes',
          cancelText: 'I want to change account',
          onOk: () => {
            // 选择直接绑定
            window.location.href = data.redirect_url || '/app/';
          },
          onCancel: async () => {
            // 退出当前账号
            localStorage.removeItem('dser-pro-authority');
            localStorage.removeItem('ZLstatus');
            localStorage.removeItem('payment');
            localStorage.removeItem('type');
            localStorage.removeItem('quantity');

            await logoutMvpAccount();

            window.location.href = `/app/login?redirect_uri=${encodeURIComponent(
              data.redirect_url
            )}&shopify_auth_redirect_uri=${encodeURIComponent(window.location.href)}`;
          }
        });
      }
    } else {
      // 绑定关系已存在
      // eslint-disable-next-line
      if (!data.is_login) {
        // 没登陆
        // 用bind_email登录 或 重新绑定新账号
        this.bindEmail = data.bind_email;

        // const str = 'code=0317152bfb96b9fe0afe07eaf28ccc23&hmac=d6922d0dcc7a7c821d8dad75400a9752f3a20e04b3091665a4b5d5b8c31fc76d&shop=dong-test-store.myshopify.com&state=bvlej1hkla4000c22ieg&timestamp=1609230726';
        window.location.href = `/app/login?redirect_uri=${encodeURIComponent(
          window.location.href
        )}&shopify_auth_redirect_uri=${encodeURIComponent(window.location.href)}`;
      } else {
        // 已登录
        // eslint-disable-next-line
        if (data.is_self_store) {
          // 是对应shop绑定账户
          if (data.token) {
            localStorage.setItem('dser-pro-authority', data.token);
          }
          window.location.href = '/app/';
        } else {
          // 不是对应shop绑定账户
          // eslint-disable-next-line
          if (data.bind_email) {
            // 对应绑定账户有效

            Modal.warning({
              title:
                'This store is linking with another DSers account. Please contact customer support for further help.',
              onOk: () => {
                window.location.href = '/app/';
              }
            });

            // Modal.confirm({
            //   title: intl.get( 'h5_redirect.bind_tip', { email: data.current_user_email, } ),
            //   onOk: () => {
            //     // 调接口删除店铺之前绑定关系然后redirect到url
            //     this.deleteBind();
            //   },
            //   onCancel: () => {
            //     //退出当前账号然后redirect到url
            //     localStorage.removeItem( 'dser-pro-authority' );
            //     window.location.href = data.redirect_url || '/app/';
            //   }
            // });
          } else {
            // 对应绑定账户无效
            // 删除店铺之前绑定关系然后redirect到url
            this.deleteBind();
          }
        }
      }
    }
  };

  // 删除绑定关系后redirect_url
  deleteBind = () => {
    this.props.dispatch({
      type: 'auth/unbind',
      payload: {
        data: {
          ...this.parms
        },
        cancelMessage: true,
        callback: d => {
          if (d.code === 2000) {
            window.location.href = this.rectBackData.redirect_url || '/app/';
          } else {
            message.error(d.msg);
          }
        }
      }
    });
  };

  render() {
    const { showLoading } = this.state;

    return (
      <>
        {showLoading ? (
          <div
            style={{
              marginTop: 120,
              textAlign: 'center'
            }}
          >
            <Spin size="large" />
          </div>
        ) : null}
      </>
    );
  }
}
