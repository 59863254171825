// import { routerRedux } from "dva/router";
import {
  getChart,
  getGuidance,
  postGuidance,
  SupplyChain
} from '../services/substat';
import { getStoreList } from '../services/store';

export default {
  namespace: 'substat',

  state: {
    storeData: [],
    chart: {},
    guide: {},
    guidance: {}
  },

  effects: {
    *storeList({ payload }, { call, put }) {
      const data = payload;
      const response = yield call(getStoreList, data);
      yield put({
        type: 'queryOrderStoreList',
        payload: response
      });
    },

    *chart({ payload }, { call, put }) {
      const data = payload;
      const response = yield call(getChart, data);
      yield put({
        type: 'setChart',
        payload: response
      });
    },
    *guidance({ payload }, { call, put }) {
      const response = yield call(getGuidance, payload);
      yield put({
        type: 'setGuidance',
        payload: response
      });
    },
    *postGuidance({ payload }, { call, put }) {
      const response = yield call(postGuidance, payload);
      yield put({
        type: 'setPostGuidance',
        payload: response
      });
    },
    *getSupplyChain({ payload }, { call, put }) {
      const response = yield call(SupplyChain, payload);
      yield put({
        type: 'setSupplyChain',
        payload: response
      });
    }
  },

  reducers: {
    queryOrderStoreList(state, { payload = {} }) {
      return {
        ...state,
        storeData: payload.data || []
      };
    },
    setChart(state, { payload = {} }) {
      return {
        ...state,
        chart: payload.data || []
      };
    },
    setGuidance(state, { payload = {} }) {
      return {
        ...state,
        guidance: payload.data || {}
      };
    },
    setPostGuidance(state, { payload = {} }) {
      return {
        ...state
      };
    },
    setSupplyChain(state, { payload = {} }) {
      const { data = {} } = payload;
      return {
        ...state,
        SupplyChain: data
      };
    }
  }
};
