import {
  getShopifyRedirect,
  getShopifyCallback,
  aegetShopifyCallback,
  aegetcheck,
  subLogin,
  subUnbind,
  subRegister,
  createShopfiyStore,
  postCompletePassWord,
  getMigrationInfo,
  getMigrationStoreInfo,
  getAllStoresList,
  getNewPlatformProductUserInfo
} from '../services/auth';

import { getOberloReport } from 'utils/utils'

export default {
  namespace: 'auth',

  state: {
    migrationInfo:{},
    OstoreInfo:{},
    isOberloUser:false,
  },

  effects: {
    *redirect({ payload }, { call }) {
      yield call(getShopifyRedirect, payload);
    },

    *callback({ payload }, { call }) {
      yield call(getShopifyCallback, payload);
    },

    *aecallback({ payload }, { call }) {
      yield call(aegetShopifyCallback, payload);
    },

    *aecheck({ payload }, { call }) {
      yield call(aegetcheck, payload);
    },

    *login({ payload }, { call, put }) {
      const response = yield call(subLogin, payload);
      yield put({
        type: 'changeLoginStatus',
        payload: response
      });
    },

    *unbind({ payload }, { call }) {
      yield call(subUnbind, payload);
    },

    *register({ payload }, { call }) {
      yield call(subRegister, payload);
    },

    *createShopfiy({ payload }, { call }) {
      yield call(createShopfiyStore, payload);
    },

    *completePassWord({ payload }, { call }) {
      yield call(postCompletePassWord, payload);
    },

    *migrationInfo({payload},{call,put}){
     const res = yield call(getMigrationInfo,payload)
     
     if(res){
      localStorage.setItem('setMigrationInfo',res?.step_stage)
      if(!['STEP_STAGE_PRE','STEP_STAGE_QUEUE','STEP_STAGE_STEP1'].includes(res?.step_stage)){
        yield put({
          type: 'topBtn/checkPlan',
          payload:{
            data:{
              manual:0
            }
          }
        });
      }
       yield put({
         type:'setMigration',
         payload:res
        })
     }
    },
    *getMigrationStoreInfo({payload},{call,put}){
      const res = yield call(getMigrationStoreInfo,payload)
      // const res = {"code":5000,"msg":"proxy failed: 123"}
      if(res?.store_stages?.length || (res?.code == 5000 && !!res?.msg.match(/proxy failed/ig)?.[0])){
        if(res.last_pre_complete_timestamp != 0){
          localStorage.setItem('EnterNewHomeTime',res.last_pre_complete_timestamp)
        }
        
        yield put({
          type:'setStoreInfo',
          payload:res
        })
      }else{
        localStorage.removeItem('noMigra')
      }
    },
  },
  
  reducers: {
    changeLoginStatus(state, { payload }) {
      // setAuthority(payload.data.currentAuthority);
      return {
        ...state,
        logindata: payload
      };
    },
    setMigration(state,{payload}){
      return {
        ...state,
        migrationInfo:payload
      }
    },
    setStoreInfo(state,{payload}){
      if(payload?.store_stages?.length || (payload?.code == 2000 && !!payload?.msg.match(/proxy failed/ig)?.[0])){
        const hasOtoD = payload?.store_stages?.length || (payload?.code == 2000 && !!payload?.msg.match(/proxy failed/ig)?.[0])
        localStorage.setItem('migration_stores',JSON.stringify(payload?.store_stages || []))
        if(hasOtoD){
          localStorage.setItem('noMigra',false)
        }else{
          localStorage.setItem('noMigra',true)
        }

        return {
          ...state,
          OstoreInfo:payload || {},
          isOberloUser:hasOtoD,
        }
      }else{
        return {
          ...state,
          OstoreInfo:{},
          isOberloUser:false
        }
      }
    }
  }
};
