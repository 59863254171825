export default {
  fulfillment_center: {
    activate: 'Tem certeza que deseja ativar o produto FDB?',
    activate_dec:
      'Seu fornecedor atual será trocado pelo produto FDB após 48 horas.',
    input_confirm: 'Por favor escreva CONFIRMAR para ativar',
    deactivate: 'Tem certeza que deseja desativar o produto FDB?',
    deactivate_dec:
      'Seu fornecedor padrão será substituido pelo fornecedor do AliEpress na sua página de mapeamento após 48 horas.',
    using_tip:
      'Você está usando o serviço FDB para este produto, Fornecedores da página de Mapeamento serão utilizados quando desligado',
    close_request:
      'Sua solicitção está em revisão. Ela será ativada após 48 horas. Durante as 48 horas. O fornecedor atual será usado',
    closeing: 'Serviço FDB será usado quando ligado',
    activate_request:
      'Sua solicitção está em revisão. Fornecedor na página de mapeamentos serão utilizados a´ós 48 horas',
    go_setting: `Vá em <a
                  href="/app/settings?id=3"
                >configurações - da loja</a> para atualizar a autorização. Caso contrário você não poderá administrar sua loja corretamente no DSers`,
    title: 'Centro de Preenchimento (FBD)',
    dec:
      'Iremos processar os produtos via FBD caso seus pedidos sejam múltiplos produtos fornecidos pelo mesmo fornecedor do AliExpress para evitar cobranças duplicadas por envio.',
    pruchese: 'Comprar',
    saved: 'Salvo',
    placeholder: 'Selecionar Loja'
  }
};
