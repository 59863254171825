import React from 'react';

import { Modal, Button } from 'antd';

import styles from './index.less';

import intl from 'react-intl-universal';

function RefreshBounced({
  visible,
  handleRefresh,
  title,
  subTitle,
  buttonText,
  buttonEvent
}) {
  return (
    <Modal
      visible={visible}
      footer={false}
      closable={true}
      width={560}
      maskClosable={false}
      onCancel={handleRefresh}
      className={styles.content}
    >
      <div className={styles.title}>
        {title || intl.get('setting.agency.refreshPage')}
      </div>
      <div className={styles.subTitle}>
        {subTitle || intl.get('setting.agency.refreshPagePrompt')}
      </div>
      <div className={styles.button}>
        <Button onClick={buttonEvent}>
          {buttonText || intl.get('setting.agency.REFRESH')}
        </Button>
      </div>
    </Modal>
  );
}

export default RefreshBounced;
