import React, { useState, useEffect } from 'react';
import styles from './NewShipOption.less';
import close from 'assets/mobile/close_icon.png';
import { newShipOptions } from 'utils/bannerWhiteList';
import { connect } from 'dva';
import { useDispatch } from 'react-redux';

const NewShipOption = ({ userId, status }) => {
    const [visible, setVisible] = useState(true)
    const isUser = newShipOptions.includes(userId);
    const dispatch = useDispatch()

    useEffect(() => {
        if (localStorage.getItem('newShipOptions') && !status) {
            setVisible(false);
        }
    }, [status]);

    useEffect(() => {
        if (status) {
            setVisible(true)
        }
    }, [status])


    if (!visible || !isUser) {
        return null;
    }

    const handleClose = () => {
        setVisible(false);
        localStorage.setItem('newShipOptions', false);
        dispatch({
            type: 'global/setNewShipOprionsStatus',
            payload: {
                status: false
            }
        })
    }

    return (
        <>
            {
                visible ? <div className={styles.mask} ></div> : null
            }
            <div className={styles.container}>
                <div>News Update For AliExpress Shipping Options <img src={close} onClick={handleClose} alt="" /></div>
                <div>AliExpress Saver Shipping & AliExpress Direct Shipping options were first initiated in 2020 as a "Shipping Constructive Build Plan" to optimize AliExpress shipping service in the Brazilian market for the AliExpress platform. Because of their service quality and lower cost, those two shipping options became the favorite choices in the last couple of years. However, some changes are coming.</div>
                <div>
                    <div>1:Shipping method adjustment April 30th</div>
                    <div>AliExpress platform will remove Aliexpress Saver Shipping & Aliexpress Direct Shipping methods while keeping Aliexpress Standard Shipping & Aliexpress Standard Shipping for Special Goods (for magnetic, electric, liquid, powder & cream products).</div>
                </div>
                <div>
                    <div>2: DSers Mass Supply adjustment</div>
                    <div>To keep providing quality service in the Brazilian market, the Dsers Mass Supply team will start a unified price adjustment in the afternoon of April 29th where Aliexpress Standard Shipping will be applied as a replacement. Please check with your sales account manager for more details. </div>
                </div>
                <div>
                    <div>3: Awaiting orders </div>
                    <div>We suggest placing all "Awaiting Orders" before April 28th (local time in Brazil) to have the last chance to benefit from the Aliexpress Saver Shipping methods. Please consider adjusting the price of your Shopify goods in time to maintain your profit margins.  </div>
                </div>
                <div>
                    <div>4: Other shipping options  </div>
                    <div>The basic principle of our supply service is to provide stable support. Other shipping options are considered for the near future. Due to failure rate & late delivery issues, economic shipping options are not yet considered at the moment, such as "ePacket" . The latest released shipping options will be considered to be applied after 2 to 3 months of testing. We first want to check the service quality.  </div>
                </div>
                <div>
                    Please feel free to contact your sale & supply account manager for more details. New updates will be shared with you as soon as they are available.
                </div>
            </div>
        </>
    )
}

export default connect(({ global }) => ({
    status: global.newShipOptionsStatus
}))(NewShipOption)