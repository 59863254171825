export default {
  csv: {
    csv_bulk_order: 'CSV Bulk Order', //  d
    re_download_template:
      'To maintain the stability of the platform, CSV users can place up to 2,000 orders everyday, orders will fail when you reach the limitation.',
    products: 'Products',
    import: 'Import',
    download_template: 'Download template',
    product_tip: 'Manage your products database to place bulk order.',
    orders: 'Orders',
    order_tip:
      'Get AliExpress order numbers and tracking number (the tracking numbers usually takes days to generate) by downloading the file in the “Successful orders” column. Revise the failed orders by downloading the file in the “Failed orders” column and edit the need informations.',
    upload_title_orders: 'Import orders',
    upload_title_product: 'Import Products Database',
    upload_btn: 'Import',
    need_help: 'Need help?',
    choose_csv_file: 'Choose CSV File',
    no_file_chosen: 'No file chosen',
    max_8mb: 'Your CSV file need to be under 8MB',
    upload_download_tip: 'to see an example of the format required',
    upload_succ: 'upload success.',
    upload_fail: 'upload Failed',
    upload_fail_tip:
      'The uploaded file format is incorrect. Please check the csv file.',
    click_here_to: 'Click here to',
    download: 'download',
    download_error: 'the error message Excel.',
    order_table: {
      date: 'Date',
      file_name: 'File name',
      successful_orders: 'Successful orders',
      failed_orders: 'Failed orders',
      action: 'Action'
    },
    pending: 'Pending',
    place_order: 'Place order',
    check_product: 'Check AliExpress Product SKU',
    check: 'Check',
    price: 'Price',
    token_error: 'Please refresh the page to download the file again.'
  }
};
