import { Get } from '../utils/request';

const platformMap = {
  shopify: 1,
  woo: 2,
  csv: 3
};
const langMap = {
  'en-US': 1,
  'fr-FR': 2,
  'pt-PT': 3
};
const lang = localStorage.getItem('lang');

export function getHomepageCards({ platform }) {
  return Get('/hpinfo/list', {
    data: {
      user_type: platformMap[platform] || 1,
      language_type: langMap[lang]
    }
  });
}
