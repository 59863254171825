import React from 'react';
import { Divider } from 'antd';

import styles from './SessionOfOrder.less';

import Point from './Point';
import BotMessage from './BotMessage';
import UserMessage from './UserMessage';
import Loading from './Loading';

function SessionOfOrder({
  status,
  onWatchVideo,
  testOrderNumber,
  onEnd,
  tutorialType
}) {
  return (
    <div className={styles.centerChat}>
      {status === 'awaitOrder' ? (
        <>
          <BotMessage showAvatar>
            <p>
              I can see that test order{' '}
              <b>{testOrderNumber ? `#${testOrderNumber}` : '--'}</b> is ready
              to go.
            </p>
            <div>
              We selected <b>AliExpress standard shipping</b> as the shipping
              method for testing but you can change this setting for next
              orders.
            </div>
          </BotMessage>
          <BotMessage>
            Click the <b>Order</b> button to process the order to the AliExpress
            supplier.{' '}
            {tutorialType === 0 ? (
              <span className={styles.alertText}>
                The test order will not be placed for real, this is just to teach
              you how to process an order
              </span>
            ) : null}
          </BotMessage>
        </>
      ) : null}
      {status === 'confirmOrder' ? (
        <div style={{ paddingTop: 360 }}>
          <BotMessage showAvatar>
            <p>
              Before the order goes to AliExpress, you can double check all the
              information here.
            </p>
            <div>
              Click <b>Confirm</b> to continue.{' '}
              {tutorialType === 0 ? (
                <span className={styles.alertText}>
                  The test order will not be placed for real, this is just to teach
                you how to process an order
                </span>
              ) : null}
            </div>
          </BotMessage>
        </div>
      ) : null}
      {status === 'orderLoading' ? (
        <div style={{ paddingTop: 360 }}>
          <BotMessage showAvatar>
            The order is being processed, please wait a moment…
          </BotMessage>
          <div className={styles.loading}>
            <Loading />
          </div>
        </div>
      ) : null}
      {status === 'success' ? (
        <div style={{ paddingTop: 150 }}>
          <BotMessage showAvatar>
            <p>
              Hooray{' '}
              <span role="img" aria-label="icon">
                🎉🎉
              </span>
              !{' '}
              {tutorialType === 1 ? (
                <>
                  The test order is <b>processed to your AliExpress account</b>.
                </>
              ) : (
                <span className={styles.alertText}>
                  The test order has been virtually placed to AliExpress.
                </span>
              )}
            </p>
            <div>
              Next step for you should be to go to AliExpress to{' '}
              <b>make payment</b>. But you don’t need to do that for this test
              order.
            </div>
          </BotMessage>
          <BotMessage>
            <p>
              Basically, <b>after making payment</b> on AliExpress, you{' '}
              <b>do not need to do anything</b>, just let DSers do the rest.
            </p>
            <div>
              We prepared a series of videos to help you review the whole
              process. Are you interested?
            </div>
          </BotMessage>
          <UserMessage showAvatar>
            <span
              onClick={() => {
                window.dataLayer.push({
                  category: 'tutorial',
                  action: 'Sure. Show me'
                });
                onWatchVideo('VNJTOHdISPk');
              }}
              data-category="tutorial"
              data-action="Sure. Show me"
              role="button"
              tabIndex="-1"
            >
              Sure. Show me!
            </span>
            <Point
              style={{ bottom: 20, left: '30%', position: 'absolute' }}
              showPoint={false}
            />
          </UserMessage>
        </div>
      ) : null}
      {status === 'fail' ? (
        <div style={{ paddingTop: 150 }}>
          <BotMessage showAvatar>
            Oops{' '}
            <span role="img" aria-label="icon">
              😱
            </span>
            , it seems that there was an unexpected issue and{' '}
            <i>the order couldn't be processed to AliExpress</i> …
          </BotMessage>
          <UserMessage showAvatar>
            <p>
              <a
                href="https://www.facebook.com/DSersOfficial"
                target="_blank"
                rel="noopener noreferrer"
                onClick={onEnd}
                data-category="tutorial"
                data-action="need support"
              >
                I need support
              </a>
            </p>
            <Divider style={{ margin: '12px 0' }} />
            <div>
              <span
                data-category="tutorial"
                data-action="watching a related video"
                onClick={() => onWatchVideo('NneJLfXtalU')}
                role="button"
                tabIndex="-1"
              >
                I prefer watching a related video
              </span>
            </div>
            <Point
              showPoint={false}
              style={{ bottom: 20, right: '30%', position: 'absolute' }}
            />
          </UserMessage>
        </div>
      ) : null}
      {status === 'invalidOrder' ? (
        <>
          <BotMessage showAvatar>
            Emmm, it seems that{' '}
            <i>
              there is no valid shipping method for this product on ALiExpress
            </i>{' '}
            at the moment...
          </BotMessage>
          <UserMessage showAvatar>
            <div>
              <span
                data-category="tutorial"
                data-action="watching a related video"
                onClick={() => onWatchVideo('NneJLfXtalU')}
                role="button"
                tabIndex="-1"
              >
                It's OK. I would like to watch related video.
              </span>
            </div>
            <Point
              showPoint={false}
              style={{ bottom: 20, right: '30%', position: 'absolute' }}
            />
          </UserMessage>
        </>
      ) : null}
    </div>
  );
}

export default SessionOfOrder;
