export default {
  import_list: {
    title: 'Liste d\'import',
    dec: `Vous pouvez importer et gérer vos produits AliExpress ici avant de les publier vers Shopify. Vous pouvez envoyer un produit vers différentes boutiques et ajouter des tags à chaque produit. Cliquez <a href={ href } target="_blank">ici</a> pour en apprendre plus. Après avoir importé des produits vers DSers, seuls le prix et l'inventaire AliExpress du produit seront automatiquement mis à jour. Si vous avez importé un produit depuis longtemps, afin d'assurer la cohérence des informations produit et fournisseur, il est recommandé de vérifier les informations fournisseur avant d'envoyer ce produit.`,
    dec_change:`Vous pouvez importer et gérer les produits de vos fournisseurs ici avant de les publier sur votre boutique Shopify.`,
    dec_change_tit:`Vous pouvez publier un produit dans différentes boutiques et ajouter plusieurs étiquettes à chaque produit. Après l'importation de produits sur DSers, le prix et l'inventaire des produits du fournisseur seront automatiquement mis à jour sur DSers. Si l'importation d'un produit date de longtemps, afin de garantir la cohérence des informations sur le produit et le fournisseur, nous recommandons de vérifier les informations du fournisseur avant de pousser ce produit.`,
    search_ali_placeholder:
      `Importez les produits AliExpress via l'URL ou recherchez des produits ici`,
    search_tm_placeholder:`Importez les produits Tmall via l'URL ou recherchez des produits ici`,
    add_tags: 'Apply DSers Tags',
    filter: 'Filtrer',
    filter_product:'Filtrer Produits',
    filter_by_stores: 'Filtrer par boutiques',
    filter_by_tags: 'Filtrer par tags',
    shopping_info: 'SInfo Livraison',
    tag_management: 'Create DSers Tags',
    pagefly:
      'Want to get more sales? Try PageFly to build high-converting product sales pages.',
    pushed_to: 'Envoyé vers',
    store_s: 'boutique(s)',
    add_manage_tags: 'Create and manage DSers tags',
    add_management_tags:'You can apply tags to group your products.',
    add_manage_tags_tip: 'You can create or delete tags to group your products. Click <a href={ href } target="_blank">here</a> to get more information.',
    add_manage_tags_tip3:`Vous pouvez filtrer vos produits par boutiques, tags et statut d'envoi.`,
    add_new_tags: 'Ajouter Nouveau Tag',
    push_modal_title: 'Push Products',
    push_modal_desc:'Sélectionnez les boutiques dans lequelles vous souhaitez pousser vos produits et cochez les cases ci-dessous pour sélectionner les règles à appliquer à vos produits.',
    no_store: 'Aucune boutique',
    from_oberlo_product_tip:`<text>Nous avons détecté que vous êtes sur le point d’envoyer un produit vers Shopify qui a été migré d'Oberlo vers DSers.</text> Par conséquent, la`,
    from_oberlo_product_tip_1:` règle de prix `,
    from_oberlo_product_tip_2:`ne s'appliquera pas à ce produit. Si vous souhaitez utiliser la règle de prix au lieu des prix modifiés, veuillez cocher cette case.`,
    pricing_rule_tip_2: ' appliquée sur ce produit. Si vous avez éditer manuellement le prix du produit sur Dsers, veuillez décocher cette case.',
    pricing_rule_tip_1:'Tarification',
    pricing_rule_close_tip: 'Notre systême a détecté que vous avez édité manuellement le prix de ce produit sur Dsers. Ainsi, la ',
    pricing_rule_close_tip_1:'tarification',
    pricing_rule_close_tip_2:' ne sera pas appliqué à ce produit. Si vous voulez utiliser la tarification au lieu des prix édités, veuillez cocher cetter case.',
    select_pricing_rule_mean_tip:'Remarque: les Règles de prix incluent les Modèles de prix, la Devise et les Réglages centimes Si vous cochez cette case, les Règles de prix seront appliquées aux prix de vos produits selon les Règles de prix que vous avez définies dans Réglages.',
    unselect_pricing_rule_mean_tip:'Remarque: les Règles de prix incluent les Modèles de prix, la Devise et les Réglages centimes. Si vous décochez cette case, aucune de ces trois fonctions ne sera appliquée à vos produits.',
    product_s: 'produit(s)',
    link_to_shopify: 'Lier à Shopify',
    no_product_tip: 'Importez votre premier article',
    no_product_tip_1: `1. Allez dans <a href='/app/find_suppliers'>Rechercher un fournisseur</a> et copiez l'URL de l'article`,
    no_product_tip_2: `2. Collez l'URL copiée dans le champ de saisie de la liste d'importation`,
    search_no_product_tip: `Aucun résultat de recherche, veuillez modifier les critères de recherche et recommencer`,
    tag_manage_delete_tip:
      'Voulez-vous supprimer ce tag? Il disparaitra des tous les produits tagués.',
    add_product_tip: 'Veuillez lier Dsers à un compte AliExpress où vous ne pourrez pas importer de produits.',
    fetch_ae_prod_error:
      `La quantité de produits que vous avez importés vers DSers a atteint la "Limite de produits" de votre plan actuel. Veuillez mettre à niveau votre plan pour importer plus de produits.`,
    fetch_ae_prod_error1: `Les articles AliExpress importés vers DSers ont atteint la limite quotidienne. Veuillez réessayer demain.`,
    fetch_tm_prod_error1: `Les produits Tmall que vous avez importés vers DSers ont atteint la limite quotidienne. Veuillez réessayer demain.`,
    ae_issue: 'AliExpress rencontre des problèmes, veuillez réessayer plus tard',
    delete_product_tip: `Êtes-vous sûr de vouloir supprimer les produits? Ils seront supprimés de la liste d'import mais ne seront pas supprimés de votre boutique Shopify`,
    ae_prod_not_found: 'Le produit est indisponible sur AliExpress, veuillez importer un autre produit.',
    service_unavaliable: `L'opération a échoué à cause d'une erreur de connexion, veuillez réessaye.`,
    pushing: "Envoi de produit(s)",
    push_success: "Produit envoyé avec succès",
    push_fail:`Échec de l'expédition`,
    push_desc: "produits en cours d'expédition",
    push_success1: 'Produit(s) envoyé(s)',
    push_error_0: "Le produit contient plus de 100 SKUs",
    push_error_1: "Nombre maximum de création de SKU sur Shopify atteint",
    push_error_2: "Boutique Shopify indisponible",
    push_error_3: "Token de la boutique expiré",
    push_error_4: "Les informations du produit dépassent 256 Ko",
    push_error_4_tip: `Les informations du produit incluent le Titre, Description, Images, SKU, etc. Il est recommandé de supprimer certaines informations sur le produit avant de publier le produit`,
    push_error_5: "Demande expiré, veuillez réessayer plus tard",
    push_desc_ok: "Expédié avec succès",
    import_review: 'Import Reviews',
    push_desc_fail: "Échec de l'expédition",
    check_push: "Vérifier le Statut",
    check_detail:`Vérifier Produit Détail`,
    edit_detail:'Éditer Produit',
    addTag:'Create a new tag',
    confim:'Confirmer',
    add_manage_tags2:'Apply DSers Tags',
    delete_product:`Supprimer le produit`,
    variants_limit:"Limite quotidienne de création de variantes Shopify atteinte. Veuillez réessayer demain et cliquez <a href='{url}' target='_blank'>ici</a> pour plus de détails.",
    pushTitle_1:'Sélection de magasin',
    pushTitle_2:'Préférences',
    pushTitle_3:'Détails de poussée',
    repeated_tag:'This tag already existed',
    search_or_import:'Importer ou Chercher',
    filter_by_market_place:'Filtrer par places de marché',
  },
  product_split: {
    split: 'Diviser',
    title:
      'Split product',
    split_by_option: 'Diviser par option',
    split_by_option_dec:
      'Select the option that will be split to another product',
    option_tip:
      'Cela créera  { length } produit(s), chaque produit contiendra uniquement la variante { optionName }.',
    or: 'ou',
    split_by_variant: 'Diviser par variante',
    split_by_variant_dec:
      'Sélectionnez les variantes que vous souhaitez diviser en un autre produit',
    tip:
      'Les variantes sélectionnées seront utilisées pour créer un nouveau produit et le produit actuel aura toujours les variantes originales.',
    select_split_mothod: 'Ce produit ne peut pas être encore divisé',
    confirm_split: 'Split Product',
    confirm_split_desc: 'Are you sure you want to split the product? It will be split into different products.',
    product_not_found: "Le produit entré n'a pas été trouvé",
    network_issue: "L'opération a échoué à cause d'une erreur de connexion, veuillez réessayer",
    
  },
};
