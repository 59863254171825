import agency from './agency';

    export default 
    {
    "setting": {
        "tab_account": "Konto",
        "tab_package_tracking": "Package Tracking",
        "tab_shipping": "Versand",
        "tab_tracking": "Sendungsverfolgung",
        "tab_notification": "Benachrichtigung",
        "tab_pricing_rule": "Preisregel",
        "tab_billing_balance": "Rechnungssaldo",
        "tab_verified_supplier": "Geprüfter Lieferant",
        "tab_report": "Bericht",
        "tab_other": "Andere",
        "choose_country_placeholder": "Wählen Sie ein Land",
        "account": {
            "account_info": "Konto-Informationen",
            "account_name": "Name des Kontos",
            "account_email": "E-Mail-Konto",
            "change": "ändern",
            "change_email_desc": "Die E-Mail ist Ihre Anmeldungs-E-Mail. Wir werden diese E-Mail verwenden, um Sie bezüglich Ihres Kontos zu kontaktieren.",
            "new_email": "Neue E-Mail-Adresse",
            "new_email_desc": "Wir senden Ihnen einen Verifizierungscode an die neue E-Mail-Adresse. Bitte kopieren Sie diesen und geben Sie ihn unten ein, um Ihre Mailbox zu verifizieren.",
            "new_email_err": "falsches E-Mail-Format",
            "account_name_err": "Bitte geben Sie einen Benutzernamen zwischen 5 und 20 Zeichen ein",
            "verify_code": "Code zur Verifizierung eingeben",
            "verify_code_desc": "Holen Sie sich den Code aus unserer Verifizierungs-E-Mail und geben Sie ihn hier ein, um sich zu verifizieren",
            "verify_code_err": "Falscher Code. Bitte noch einmal überprüfen",
            "reset_password": "Passwort zurücksetzen",
            "reset_password_desc": "Ändern Sie Ihr Passwort, mit dem Sie sich anmelden.",
            "change_password": "Passwort ändern",
            "shopify_stores": "Shopify-Geschäfte",
            "shopify_stores_desc": "Sie können Ihre Shopify-Shops hier verwalten. Wenn Sie DSers deinstallieren möchten, vergessen Sie bitte nicht, den Link auch hier zu entfernen.",
            "shopify_stores_re_authorized": "Der Shop muss neu autorisiert werden. Bitte aktualisieren Sie die Autorisierung. Andernfalls können Sie den Shop nicht richtig mit DSers betreiben.",
            "shopify_stores_tip": "Wir haben die Shopify-Autorisierung aktualisiert. Bitte klicken Sie auf der Shopify-App-Seite, um sie zu erneuern. Andernfalls wird die Bestellabwicklung beeinträchtigt.",
            "store_name": "Name des Geschäfts",
            "status": "Status",
            "connected": "Verbunden",
            "disconnect": "Trennen Sie die Verbindung",
            "ae_account": "AliExpress-Konto",
            "ae_account_desc": "Hier können Sie die Verbindung zum AliExpress-Konto trennen oder wechseln.",
            "account_id": "Konto-ID",
            "change_account_title": "Wechseln Sie den Abrechnungsshop",
            "change_account_content": "Sie sind dabei, {choiceLabel} als Rechnungsshop festzulegen. Für eine optimale Nutzung der DSers-Plattform stellen Sie bitte sicher, dass dieser Shop immer geöffnet ist",
            "switch": "wechseln.",
            "ae_account_tip": "Bitte beachten Sie, dass, wenn Sie sich mit einem anderen Konto verbinden, die Informationen der Bestellungen, die im aktuellen Konto aufgegeben wurden, nach dem Trennen der Verbindung nicht mehr auf DSers aktualisiert werden.",
            "click_to_renew": "Zum Verlängern klicken",
            "disconnect_ae_tip1": "Sind Sie sicher, dass Sie die Verbindung zu Ihrem AliExpress-Konto trennen möchten? Die Informationen zu den Bestellungen, die über das aktuelle <i>Konto getätigt wurden</i>, werden nach der Trennung nicht mehr auf DSers aktualisiert.",
            "disconnect_ae_tip2": "Nach dem Trennen der Verbindung werden Sie feststellen, dass die <i>Daten des Kontos vorübergehend verschwinden</i>. Sie werden wiederhergestellt, nachdem Sie ein neues AliExpress-Konto verknüpft haben.",
            "disconnect_ae_confirm": "Bitte geben Sie BESTÄTIGEN ein, um die Verknüpfung aufzuheben.",
            "reconnect_ae": "Bitte stellen Sie sicher, dass Sie sich wieder mit demselben Konto verbinden. Andernfalls werden die Informationen der Bestellungen, die mit dem aktuellen <i>Konto getätigt wurden</i>, nach dem Trennen der Verbindung nicht mehr auf DSers aktualisiert.",
            "switch_ae": "Sind Sie sicher, dass Sie das AliExpress-Konto wechseln möchten? Die Informationen der Bestellungen, die mit dem aktuellen <i>Konto aufgegeben wurden</i>, werden nach dem Trennen der Verbindung nicht mehr in DSers aktualisiert.",
            "open_mailbox": "Mailbox öffnen",
            "send_again": "Erneut senden",
            "send_code": "Code senden",
            "verify": "Überprüfen ",
            "store_name_tip": "DSers zeigt den ursprünglichen Shopnamen an, den Sie bei der Registrierung auf Shopify angegeben haben, und wir werden den Shopnamen nicht aktualisieren, auch wenn Sie ihn auf Shopify geändert haben. Bitte beachten Sie, dass der Shop-Name den Kunden nicht angezeigt wird und keine Auswirkungen auf die Bestellungen auf DSers hat.",
            "verify_email_title": "Überprüfen Sie Ihre E-Mail Adresse",
            "verify_email_title_succ": "Verifizierungs-E-Mail gesendet",
            "verify_email_title_fail": "Zustellung der Verifizierungs-E-Mail fehlgeschlagen",
            "verify_email_desc": "Um die Sicherheit Ihres Kontos zu gewährleisten, verifizieren Sie bitte Ihre E-Mail-Adresse. Wir haben eine Bestätigungs-E-Mail an {email} gesendet.",
            "verify_email_desc_succ": "Bitte verifizieren Sie Ihre E-Mail-Adresse, indem Sie dem Link in der E-Mail an {email} folgen.",
            "verify_send": "Bestätigungs-E-Mail senden",
            "verify_re_send": "Verifizierungs-E-Mail erneut senden",
            "white_list": "Whitelist",
            "white_listed": "Auf der Whitelist",
            "account_new_info": "Kontoname ändern",
            "noData": "No linked store",
            "changeEmailName": "E-Mail ändern",
            "code_verify": "Code is required",
            "jiabai_intro": "Add your AliExpress account to the whitelist",
            "authorize_name": "Aktualisierungen",
            "authorize_tips": "Berechtigungen von Shopify aktualisieren Es scheint, dass die Berechtigungen für die DSers-Funktionen nicht auf dem neuesten Stand sind. Bitte aktualisieren Sie diese mit ein paar Klicks, um sicherzustellen, dass DSers richtig funktioniert."
        },
        "shipping": {
            "title": "Versand",
            "description": "Legen Sie Ihre Standardversandmethode für ein bestimmtes Land fest.",
            "turn_off": "Ausschalten",
            "turn_on": "Einschalten",
            "save_succ": "Erfolg speichern",
            "save_failure": "Misserfolg speichern",
            "advanced": {
                "title": "Erweiterte",
                "description": "Legen Sie Ihre Standardversandmethode nach Preisklasse und Lieferdatum fest.",
                "tracking_information_available": "Verfügbare Tracking-Informationen",
                "delivery_period": "Lieferfrist",
                "delivery_period_tip": "maximal zulässige Lieferfrist",
                "delivery_period_error": "Bitte geben Sie die Anzahl der Tage ein.",
                "days": "Tage",
                "shipping_cost": "Kosten der Lieferung",
                "shipping_cost_error": "Bitte geben Sie den Preis ein.",
                "tip": "Wenn mehrere Optionen meine Bedingungen erfüllen",
                "i_need": "Ich brauche",
                "less_cost": "weniger Kosten",
                "less_day": "weniger Tag"
            },
            "general": {
                "title": "Globale Versandmethodenkarte hinzufügen",
                "description": "Legen Sie Ihre Standardversandmethode für globale Länder fest. Das automatische Bestellsystem wählt die Versandart entsprechend Ihrer Einstellung bei der Bestellung aus. Option 1 wird Ihre erste Wahl sein.",
                "placeholder": "Weitere Versandarten hinzufügen",
                "option": "Option"
            },
            "product": {
                "title": "Globale Versandmethode basierend auf dem Produkt hinzufügen",
                "dec": "Richten Sie Ihre Standardversandmethode für ein bestimmtes AliExpress-Produkt ein. Die produktbasierte Einstellung hat höhere Priorität als die länderbasierte Einstellung.",
                "placeholder": "Suche nach Produkttitel des Lieferanten",
                "modal_placeholder": "Suche nach Schlüsselwörtern im Produkttitel des Anbieters",
                "tip": "Nur die bereits zugeordneten Produkte können hier gefunden werden",
                "shortcut_open": "Sie können schnell auf diese Funktion zugreifen, indem Sie auf Mapping - More action gehen."
            }
        },
        "tracking": {
            "title": "Nachverfolgung",
            "click_to_renew": "Zum Verlängern klicken",
            "renew_reminder": "Ihr PayPal-Konto ist abgelaufen. Um die Synchronisierung Ihrer Tracking-Nummern nicht zu beeinträchtigen, autorisieren Sie bitte Ihr PayPal-Konto erneut.",
            "description": "Legen Sie die Erfüllungsbenachrichtigung per E-Mail für einen anderen Shop fest.",
            "activate_email": "Aktivieren Sie die von Shopify gesendete Erfüllungsbestätigungs-E-Mail",
            "activate_email_description": "Aktivieren Sie dies, um die Shopify-Versandbestätigungs-E-Mail automatisch an Ihren Kunden zu senden, sobald die Tracking-Nummer generiert wurde. Sie können die E-Mail-Vorlage bearbeiten",
            "here": "hier",
            "activate_email_tip": "Mit dieser Funktion können Sie den Versanddienstleister und die Tracking-Url in Ihrer Shopify-Fulfillment-E-Mail anpassen.",
            "shipping_carrier": "Versanddienstleister",
            "custom_tracking_url": "Benutzerdefinierte Tracking-URL",
            "custom_tracking_url_tip": "Bitte beachten Sie: Wir fügen dynamisch einen Tracking-Code an das Ende dieser URL an. Wenn Sie die URL http://www.17track.net/en/track?nums=1234567 verwenden möchten, geben Sie nur http://www.17track.net/en/track?nums= ein.",
            "please_enter_a_url": "Bitte geben Sie die Tracking-Url ein.",
            "add_a_shopify_store": "Einen Shopify-Shop hinzufügen",
            "save_succ": "Einstellung erfolgreich speichern.",
            "save_fail": "Einstellung speichern fehlgeschlagen.",
            "track_to_paypal_title": "Tracking-Nummer mit PayPal synchronisieren",
            "track_to_paypal_desc": "Erlauben Sie DSers, Tracking-Nummern zu Ihrer Transaktion auf PayPal hinzuzufügen.",
            "connect_to_paypal": "Mit Paypal verbinden",
            "connect_to_paypal2": "Klicken Sie auf die Schaltfläche zum Verbinden mit PayPal",
            "connect_to_paypal_desc": "Um sicherzustellen, dass Ihre Tracking-Nummer normal synchronisiert werden kann, autorisieren Sie bitte die Anbindung Ihres PayPal-Kontos an den Shopify-Shop",
            "not_succ": "Die Autorisierung war nicht erfolgreich.<br /> Bitte versuchen Sie es erneut.",
            "use_paypal_account": "Oder verwenden Sie ein autorisiertes PayPal-Konto:",
            "to_connect_paypal_account": "Select a PayPal account to connect to",
            "paypal_placeholder": "Wählen Sie ein Konto",
            "paypal_reminder": "Bitte autorisieren Sie Ihr PayPal-Konto oben",
            "linked_paypal": "Sie sind mit dem PayPal-Konto verbunden:",
            "disconnect": "Trennen Sie die Verbindung",
            "disconnect_tip": "Sind Sie sicher, dass Sie die Verbindung zu Ihrem PayPal-Konto trennen möchten? Bitte beachten Sie, dass die Tracking-Nummer nach der Trennung der Verbindung nicht mehr im PayPal-Konto aktualisiert wird.",
            "update": "aktualisieren",
            "refresh_page": "Bitte aktualisieren Sie die Seite zum Aktualisieren.",
            "track_to_paypal_title1": "PayPal-Konto verwalten",
            "select_1": "Select a shipping carrier",
            "select_2": "Select a custom tracking URL"
        },
        "notification": {
            "title": "Benachrichtigung",
            "description": "Benachrichtigungen zur Überwachung vergriffener Produkte und Preisänderungen werden an Ihr DSers-Konto gesendet, wenn Sie die Funktion aktivieren.",
            "inventory": "Warenbestand",
            "inventory_description": "Lassen Sie sich benachrichtigen, wenn ein Produkt nicht mehr auf Lager oder nicht mehr verfügbar ist.",
            "price": "Preis",
            "price_description": "Lassen Sie sich benachrichtigen, wenn der Preis des Lieferanten auf AliExpress geändert wurde.",
            "canceled_order": "Stornierte Bestellung",
            "canceled_order_description": "Lassen Sie sich benachrichtigen, wenn eine Bestellung auf AliExpress storniert wird",
            "sku": "SKU-Änderungen",
            "sku_description": "Lassen Sie sich benachrichtigen, wenn eine Produkt SKU auf AliExpress geändert wurde",
            "order": "Bestellung",
            "order_description": "Lassen Sie sich benachrichtigen, wenn Bestellungen bei AliExpress storniert werden",
            "tip": "Die DSers-Benachrichtigungen werden aktiviert, wenn Sie die entsprechenden Kästchen ankreuzen und speichern.",
            "auto_updates": "Automatische Aktualisierungen",
            "auto_updates_tip": "Die Aktualisierungen werden nicht auf Produkte mit mehreren Anbietern wie Advanced, BOGO oder Bundle Mapping angewendet.",
            "out_of_stock": "Wenn ein Produkt/eine Variante nicht mehr auf Lager oder nicht mehr verfügbar ist, soll DSers",
            "do_nothing": "Nichts tun",
            "to_zero": "Menge in Shopify auf Null setzen",
            "inventory_change": "Wenn sich der Bestand ändert, soll DSers",
            "update_to_shopify": "Automatisch in Shopify aktualisieren",
            "product_out": "Wenn ein Produkt nicht mehr auf Lager oder nicht mehr verfügbar ist, möchten Sie, dass die DSer",
            "variant_out": "Wenn eine Variante nicht mehr auf Lager oder nicht mehr verfügbar ist, möchten Sie, dass die DSer",
            "set_as_draft": "Set as draft",
            "set_quantity_to_zero_on_Shopify": "Menge bei Shopify auf Null setzen",
            "remove_Variant": "Variante entfernen",
            "notification_feature": "Benachrichtigungsfunktion",
            "filter_variant_out_of_stock": "Variante(n) nicht auf Lager",
            "cancelOrder": "Bestellung storniert",
            "clearFilter": "ALLE FILTER LÖSCHEN",
            "filterTitle": "Benachrichtigung filtern",
            "defaultSupplier": "Standard-Lieferant",
            "secondSupplier": "Ersatzlieferant",
            "mappingPlaceholder": "Wählen Sie ein Produkt für die Neuzuordnung"
        },
        "pricing": {
            "title": "Preisgestaltungsregel",
            "description": "Die Preisregel wird automatisch angewendet, wenn Sie Produkte von DSers zu Shopify übertragen. Klicken Sie <a href={href} target='_blank'>hier</a>, um weitere Informationen zu erhalten.",
            "enabled": "Aktiviert",
            "disabled": "Deaktiviert",
            "pricing_cost": "Preisgestaltung Kosten(USD)",
            "price": "Preis",
            "price_tip": "Z.B. wird der Preis eines Produkts, das 10 USD kostet, auf 12 oder 20 USD gesetzt (wenn Sie 2 zu den Kosten addieren oder mit 2 multiplizieren).",
            "compared_at_price": "Vergleich zum Preis",
            "compared_at_price_tip": "Z.B. wird der Vergleichspreis des Produkts, das 10 USD kostet, auf 15 oder 50 USD gesetzt (wenn Sie 5 zu den Kosten addieren oder mit 5 multiplizieren).<br /> Wenn Sie diese Option nicht aktivieren, wird der Wert des Vergleichspreises leer sein, wenn Sie Produkte in Ihren Shopify-Shop übertragen.",
            "compared_ar": "Wenn Sie diese Option nicht markieren, ist der Wert des verglichenen Preises leer, wenn Sie Produkte in Ihren Shopify-Shop einstellen.",
            "price_no": "Wenn Sie den verglichenen Preis beim Pushen von Produkten anwenden und dann diese Option deaktivieren, wird der verglichene Preis nicht gelöscht, wenn Sie den Preis auf der DSers-Benachrichtigungsseite manuell aktualisieren.",
            "set_rules": "Legen Sie die von Ihnen verglichenen Preisregeln fest",
            "basic": "Basis",
            "standard": "Standard",
            "advanced": "Erweitert",
            "cost_range": "Kostenbereich (USD)",
            "compared_price": "Verglichener Preis",
            "rest_price": "Rest der Preisspannen",
            "basic_more": "Klicken Sie <a href=\"https://help.dsers.com/basic-pricing-rule-for-shopify/\" target=\"_blank\"> hier</a>, um weitere Informationen zu erhalten.",
            "formula_text_1": "Preiswert = [(Produktkosten + Versandkosten) × (1+ Gewinn %) + Fixer Gewinn] / (1 - Breakeven %)",
            "formula_text_2": "Preis vergleichen = Preiswert × / ＋ Ihre Einstellungsnummer",
            "formula_text_reminder": "PS: Wenn der endgültige Gewinn ((Produktkosten + Versandkosten) × Gewinn % + fester Gewinn) niedriger ist als der von Ihnen festgelegte Mindestgewinn, wird die folgende Formel angewendet: Preiswert = [(Produktkosten + Versandkosten) + Mindestgewinn] / (1 - Breakeven %)",
            "advanced_rest_price": "Rest der Produktpalette",
            "advanced_cost_range": "Produktkostenbereich(USD)",
            "profit": "Gewinn%",
            "fixed_profit": "Fester Gewinn",
            "min_profit": "Minimaler Gewinn",
            "breakeven": "Gewinnschwelle%",
            "shippingCostReminder": "Die Versandkosten werden entsprechend der in der Versandkosteneinstellung standardmäßig ausgewählten Versandart berechnet.",
            "breakevenReminder": "Legen Sie eine Gewinnschwelle fest, um die Kanalgebühren, Zahlungsgebühren oder andere Gebühren zu decken.",
            "shipping_cost": "Versandgebühren",
            "add_shipping_cost": "Versandkosten hinzufügen",
            "shipping_title": "Einstellung der Versandkosten",
            "shipping_reminder": "Wir berechnen die Versandkosten je nach der von Ihnen gewählten Versandart. Wenn Sie in mehrere Länder liefern, können Sie auch einen bestimmten Durchschnittswert für die Versandkosten eingeben.",
            "product_cost_setting": "Produktkosten",
            "product_cost_settingVal": "Die Produktkosten werden nach dem nationalen Preis festgelegt, der dem Zielort des Produkts entspricht. Für Details, <a href=\"https://help.dsers.com/import-product-cost-for-shopify/\"  style=\"color:#3D5AFE\" >klicken Sie</a> bitte hier",
            "product_cost_reminder": "Wenn Sie das Produkt importieren und in den Benachrichtigungen, werden wir die Kosten für das Produkt berechnen und die Änderungen je nach dem von Ihnen gewählten Land mitteilen. Die vorherigen Daten werden nicht geändert (wir werden nach und nach mehr Länder unterstützen).",
            "fixed_cost": "Spezifische Versandkosten",
            "ranges_overlap": "Ihre Bereiche überschneiden sich.",
            "ranges_start_greater_end": "Der Endwert des Kostenbereichs muss größer oder gleich dem Anfangswert sein.",
            "assign_cents": "Cents zuweisen",
            "assign_cents_description": "Sie können einen bestimmten Cent-Wert für Ihren Verkaufspreis festlegen. Wir werden diesen Wert bei der Bildung des Endpreises für Ihre Artikel verwenden (wenn Sie z. B. möchten, dass Ihr Produkt XX,99 kostet, fügen Sie in den Feldern unten 99 hinzu).",
            "assign_compared_at_cents": "Verglichen bei Cent zuweisen",
            "need_link_store": "Sie müssen zuerst einen Link zu einem Shopify-Shop erstellen.",
            "save_succ": "Gespeicherter Erfolg",
            "standard_more": "Klicken Sie <a href=\"https://help.dsers.com/standard-pricing-rule-for-shopify/\" target=\"_blank\">hier</a> um weitere Informationen zu erhalten.",
            "advanced_more": "Klicken Sie <a href=\"https://help.dsers.com/standard-pricing-rule-for-shopify/\" target=\"_blank\">hier</a> um weitere Informationen zu erhalten.",
            "currency": "Currency",
            "apply_go": "Apply currency rate to the pricing rules when you publish products to Shopify. If you want to activate this feature, please go to",
            "apply_to": "to set up currency for your stores",
            "apply_to_mes": "Wenden Sie den Währungskurs auf die Preisregeln an, wenn Sie Produkte in Shopify veröffentlichen. Die globalen Preisregeln funktionieren anders, wenn Sie auf Shopify NICHT in USD verkaufen und diese Funktion nicht aktivieren möchten. Bitte berechnen Sie die Multiplikatoren entsprechend.",
            "apply_btn": "Setting - Stores",
            "bigTit": "The Price you set is higher than the Compared at price, please check and save",
            "inputerr100": "Value range: 0% to 99%",
            "basicpricerule": "Grundlegende Preisregel",
            "standardpricerule": "Standard-Preisregel",
            "advancedpricerule": "Erweiterte Preisbildungsregel",
            "setpricing": "Details zu den Preisregeln festlegen",
            "pricing_rule_new_title": "Preisvorlagen",
            "pricing_rule_new_dsec": "Mit Preisvorlagen können Sie die Preise für Ihre Produkte ganz einfach festlegen. Wählen Sie den Shop, auf den Sie die Vorlagen anwenden möchten. Sehen Sie sich die Details der Preisregel an und prüfen Sie sie, um die Preise für Ihre Produkte weiter anzupassen."
        },
        "billing": {
            "activate_google_auth": "Wir empfehlen Ihnen dringend, Google <br /> Auth zu aktivieren, um die Sicherheit Ihres Kontos zu gewährleisten",
            "activate": "AKTIVIEREN",
            "remaining_credit": "Verbleibendes Guthaben",
            "make_a_payment": "BEZAHLUNG DURCHFÜHREN",
            "make_a_payment_tip": "Bitte geben Sie Ihre Rechnungsadresse an, bevor Sie es benutzen können.",
            "last_payment": "{key, plural, =q1 {Your last payment was on} =q2 {for USD} =q3 {and is pending on paypal.} =q4 {payment faild on paypal. please check and payment again.} }",
            "transactions": "Transaktionen",
            "date": "Datum",
            "description": "Beschreibung",
            "amount": "Betrag (USD)",
            "purchase_record": "Kauf-Datensatz",
            "download": "Herunterladen",
            "all_bills": "Alle Rechnungen",
            "save_succ": "Erfolg speichern",
            "save_error": "Fehler speichern",
            "google_auth": {
                "step1": "Schritt 1: Google Authenticator herunterladen",
                "step1_description": "Der IOS-Benutzer meldet sich im AppStore an und sucht nach Google Authenticator, und der Android-Benutzer meldet sich im App-Store an oder verwendet den mobilen Browser, um nach dem Download von Google Authenticator zu suchen.",
                "step2": "Schritt 2: Google Authenticator binden",
                "scan_qr_code": "IOS/Andorid：Scannen Sie den QR-Code",
                "code": "Wenn der Scan-Code nicht funktioniert, können Sie den Verifizierungscode eingeben.",
                "please_save": "Wird verwendet, um die Google-Authentifizierung abzurufen, bitte speichern."
            },
            "billing": {
                "billing_information": "Informationen zur Rechnungsstellung",
                "business_name": "Name des Unternehmens",
                "business_name_error": "Bitte geben Sie einen Namen ein",
                "email": "E-Mail",
                "email_not_valid": "Die Eingabe ist nicht gültig E-Mail!",
                "email_null_error": "Bitte E-Mail eingeben!",
                "phone": "Telefon",
                "phone_error": "Bitte geben Sie einen Namen ein",
                "city": "Stadt",
                "city_error": "Bitte geben Sie eine Stadt ein",
                "currency": "Währung",
                "currency_error": "Bitte geben Sie einen Namen ein",
                "country": "Land",
                "country_error": "Bitte geben Sie ein Land ein",
                "address": "Adresse",
                "address_error": "Bitte geben Sie eine Adresse ein",
                "post_code": "Postleitzahl",
                "province": "Bundesland",
                "province_error": "Bitte geben Sie eine Provinz ein",
                "company": "Unternehmen",
                "send_this_address": "Muster an diese Adresse senden"
            },
            "primary": {
                "title": "Hauptansprechpartner",
                "name": "Name",
                "name_error": "Bitte geben Sie einen Namen ein",
                "use_billing_address": "Als Rechnungsadresse verwenden",
                "send_sample_to_this_address": "Muster an diese Adresse senden"
            }
        },
        "verified": {
            "title": "Geprüfter Lieferant",
            "img_max_1mb": "Das Bild muss kleiner als 1MB sein!",
            "bag_default": "Beutel (Standard und kostenlos)",
            "bag_box": "Schachtel ($0.2/Pakakge)",
            "bag_box_logo": "Schachtel mit Logo ($0.2/Paket)",
            "bag_box_card": "Schachtel mit Karte ($0.2/Paket)",
            "bag_box_logo_card": "Box mit Logo und Karte ($0.2/Paket)",
            "upload": "Hochladen",
            "package_request": "Paket Anfrage",
            "logo_file": "Logo-Datei",
            "note": "Hinweis"
        },
        "report": {
            "timezone": {
                "title": "Zeitzone",
                "desc": "Ihre Standardzeitzone basiert auf den Einstellungen Ihres Browsers. Sie können hier eine Zeitzone für Ihr DSers-Konto auswählen. ABER bitte beachten Sie, dass Sie die Zeitzone nur einmal einstellen können und sie nach dem Speichern nicht mehr geändert werden kann.",
                "save_modal_desc": "<i>Sie können die Zeitzone nur einmal einstellen</i>. Bitte vergewissern Sie sich, dass Sie die richtige Zeitzone ausgewählt haben, die nach dem Einstellen nicht mehr geändert werden kann.",
                "save_modal_tip": "Zum Speichern bitte BESTÄTIGEN eingeben"
            },
            "currencies": {
                "title": "Währungen",
                "desc": "USD wird als Standardwährung auf DSers verwendet. \n\t\t\t\t\t\t\t\tSie können dies für jeden Shop einrichten und die von Ihnen gewählte Währung wird entsprechend auf AliExpress-Produkte und -Bestellungen auf DSers angewendet. \n\t\t\t\t\t\t\t\tDie auf DSers angezeigten Daten können sich von denen auf Shopify oder AliExpress unterscheiden, da sich die Wechselkurse ändern. \n\t\t\t\t\t\t\t\t<i>Sie können Ihre Währung nur einmal für jeden Shop einstellen. </i>\n\t\t\t\t\t\t\t\tWenn Sie die Währung später ändern müssen, wenden Sie sich bitte an den <a href=\"mailto:support@dserspro.com\">Support</a>.",
                "store": "Shop",
                "currency": "Währung",
                "no_store_tip": "Bitte verlinken Sie auf einen Shop, um ihn einzurichten.",
                "save_modal_title": "Sie können die Währung nur einmal ändern!",
                "save_modal_desc": "Bitte beachten Sie, <i>dass Sie die Währung nur ein Mal ändern können</i>. Bitte geben Sie unten BESTÄTIGEN ein, um Ihre Einstellung zu speichern.",
                "input_confirm": "Bitte geben Sie BESTÄTIGEN ein"
            }
        },
        "other": {
            "default_phone_number_placeholder": "Wählen Sie ein Land",
            "phone_rules_SA_AE_IL": "es erfordert 9-stellige Nummern, die mit 5 beginnen",
            "phone_rules_FR": "es werden 10-stellige Handynummern benötigt.",
            "phone_rules_PT": "es werden 9- oder 10-stellige Zahlen benötigt",
            "phone_rules_BR_JP": "es werden 10- oder 11-stellige Zahlen benötigt",
            "phone_rules_KR": "Please enter 10 or 11-digit phone number starting with 01 or 1 (e.g. 01012345678)",
            "sync_ali_order_number": "Ali-Bestellnummer mit Shopify-Zusatzinformationen synchronisieren",
            "sync_ali_order_number_tip": "Wenn diese Einstellung aktiviert ist, wird Ihre Ali-Bestellnummer automatisch mit der Shopify-Bestelldetailseite synchronisiert.",
            "canceled_orders_optimization": "Stornierte Bestellungen optimieren.",
            "canceled_orders_optimization_tip": "Aktivieren Sie diese Funktion, um eine von AliExpress stornierte Bestellung automatisch zu optimieren. Die Optimierung durch DSers wird die Wahrscheinlichkeit, dass die Bestellung erneut storniert wird, erheblich verringern.",
            "synchronize_shopify_order_notes": "Bestellnotizen mit Shopify synchronisieren",
            "synchronize_shopify_order_notes_tip": "Activate this feature to automatically synchronize Shopify order notes. DSers will help to send the notes of an order from Shopify to AliExpress along with orders.",
            synchronize_shopify_order_message: "Synchronize Shopify order customization information",
            synchronize_shopify_order_message_tip:"Activating this feature automatically synchronize Shopify order customization information. DSers will help you send the customization information from Shopify to suppliers along with the order.",
            "leave_a_meaage": "Hinterlassen Sie eine Nachricht an Lieferanten",
            "leave_a_meaage_tip": "Die Lieferanten erhalten diese Nachricht zusammen mit Ihren Bestellinformationen.",
            "message_placeholder": "Geben Sie maximal 1000 Zeichen (oder 250 chinesische Zeichen) ein.",
            "ioss_message": "IOSS",
            "ioss_message_tip": "Wenn Sie die Mehrwertsteuer selbst zahlen möchten, anstatt sie über AliExpress zu bezahlen, müssen Sie eine IOSS-ID zu den Käuferinformationen Ihrer AliExpress-Bestellungen hinzufügen, die in die EU versandt werden.",
            "ioss_message_tip_2": "Bitten Sie die AliExpress-Verkäufer, der Bestellung ebenfalls eine IOSS-ID hinzuzufügen, und geben Sie die IOSS-ID an den Versanddienstleister weiter, damit die Pakete normal durch den Zoll gehen können. Es ist äußerst wichtig, dass Sie den Verkäufer kontaktieren, um sicherzustellen, dass er dies tun wird.",
            "ioss_message_info": "(Ihr AliExpress-Konto muss als gewerblicher Käufer verifiziert werden, damit Sie auf AliExpress eine Umsatzsteuer-Identifikationsnummer hinzufügen und die Umsatzsteuer selbst anmelden und abführen können.) <br />Die IOSS-ID, die Sie hier festlegen, wird automatisch zu Adresse 2 der AliExpress-Bestellungen hinzugefügt, wenn Sie diese bei AliExpress aufgeben. Wenn Sie die IOSS-ID hier ändern, wird die neue ID nur auf neue Bestellungen bei AliExpress angewendet.",
            "ioss_message_error": "Bitte geben Sie eine korrekte IOSS-ID ein, um zu vermeiden, dass Bestellungen bei AliExpress nicht aufgegeben werden können oder Pakete vom Zoll aufgehalten werden.",
            "default_phone_number": "Standard-Telefonnummer festlegen",
            "default_phone_number_tip": "Wenn eine Bestellung keine Telefonnummer enthält, verwendet das System die von Ihnen eingestellte Standard-Telefonnummer, um sie einzutragen.",
            "override_phone_number": "Telefonnummer außer Kraft setzen",
            "override_phone_number_uae": "Für Bestellungen, die nach Saudi-Arabien, in die Vereinigten Arabischen Emirate und nach Israel geschickt werden, sind 9-stellige Nummern erforderlich, die mit 5 beginnen.",
            "override_phone_number_fr": "Für Bestellungen, die nach Frankreich geschickt werden, sind 10-stellige Handynummern erforderlich, die mit 06 beginnen.",
            "override_phone_number_pt": "Für Bestellungen, die nach Portugal geschickt werden, sind 9- oder 10-stellige Nummern erforderlich.",
            "override_phone_number_br": "Für Bestellungen nach Brasilien sind 10- oder 11-stellige Nummern erforderlich.",
            "override_phone_number_jp": "Für Bestellungen nach Japan sind 10- oder 11-stellige Nummern erforderlich.",
            "override_phone_number_ko": "Für Bestellungen, die nach Korea geschickt werden, sind 10- oder 11-stellige Nummern erforderlich, die mit 01 oder 1 beginnen.",
            "override_phone_number_intro_start": "Bitte beachten Sie dies:",
            "override_phone_number_intro_end": "Please fill in accordingly.",
            "override_phone_number_tip": "Überschreiben Sie die Telefonnummer des Kunden mit Ihrer eigenen Nummer, wenn Sie eine Bestellung an Lieferanten aufgeben.",
            "france_phone_number_tip": "Please note that for orders sent to Saudi Arabia, United Arab Emirates, it requires 9 digits numbers starts with 5. For orders sent to France, it requires 10 digits mobile phone numbers starts with 06 or 07. Please fill in accordingly.",
            "sync_shopify_orders": "Shopify-Bestellungen synchronisieren",
            "sync_shopify_orders_tip": "DSers synchronisiert standardmäßig die Bestellungen der letzten 7 Tage. Wählen Sie einen Datumsbereich, um mehr Bestellungen zu synchronisieren, Sie können bis zu 30 Tage pro Zeit synchronisieren.",
            "sync_shopify_orders_error": "You can sync up to 30 days per time.",
            "select_store_placeholder": "Geschäft auswählen",
            "sync": "Sync",
            "affiliate_program": "Partnerprogramm",
            "affiliate": "Partner",
            "affiliate_desc": "Sie haben Ihr Admitad Cashback aktiviert",
            "change_code": "Code ändern",
            "change_code_wraming": "Sind Sie sicher, dass Sie den aktuellen Code löschen möchten?",
            "step": "Schritt",
            "new_affiliate_tip": "Registrieren Sie ein NEUES Admitad-Konto über den unten stehenden Link",
            "why_affiliate": "Warum brauche ich ein neues Admitad-Konto?",
            "get_affiliate_btn": "Admitad-Konto einrichten",
            "set_affiliate": "Richten Sie Ihr Admitad-Konto ein.",
            "how_to_set": "Prüfen Sie, wie man es einrichtet?",
            "fill_affiliate": "Geben Sie Ihren Partnercode und Kontonamen ein.",
            "where_code": "Wo finde ich den Code und den Kontonamen?",
            "code": "Code",
            "err_tip": "Dies ist kein DSers Affiliate Code, bitte überprüfen Sie dies noch einmal.",
            "message_ae_sellers": "Die Nachricht wurde erfolgreich gespeichert und die Lieferanten erhalten sie, wenn Sie Bestellungen aufgeben.",
            "save_phone_succ": "Rufnummer erfolgreich gespeichert.",
            "cash_back_code_error": "Dies ist kein gültiger Code. Der Code sollte eine Mischung aus 30 Buchstaben und Zahlen sein. Bitte überprüfen Sie dies noch einmal.",
            "cost_error": "Bitte geben Sie eine Zahl im Format 5 oder 5.99 ein.",
            "create_success": "Erfolg erstellen",
            "admitad_stopping_now": "Ihr Admitad Cashback wird jetzt gestoppt. Bitte ändern Sie einen neuen Code für DSers. Prüfen Sie, <a href={href} target=\"_blank\">warum und wie</a>",
            "notice": "Unser System hat festgestellt, dass Ihr Admitad-Konto nicht verifiziert werden kann.<br />\n\t\t\t \tBitte stellen Sie sicher, dass Sie das Admitad-Konto über&nbsp;\n\t\t\t\t <a\n\t\t\t\t href=\"https://store.admitad.com/en/promo/?ref=8e3xmmxnjp\"\n\t\t\t\t target=\"_blank\"\n\t\t\t\t rel=\"noopener noreferrer\"\n\t\t\t >DSers Empfehlungslink </a>\n\t\t\t\t&nbsp;und ändern Sie dann Ihren Code auf DSers entsprechend.<br> Wenn Ihr Admitad-Konto über&nbsp;\n\t\t\t\t<a\n            href=\"https://store.admitad.com/en/promo/?ref=8e3xmmxnjp\"\n            target=\"_blank\"\n            rel=\"noopener noreferrer\"\n          >DSers Empfehlungs-Link</a> registriert wurde, aber trotzdem nicht verifiziert werden kann, überprüfen Sie bitte, ob Sie den richtigen Kontonamen auf DSers eingegeben haben.",
            "notice_sub": "Bitte beachten Sie, dass das aktuelle Admitad-Konto nach 48 Stunden deaktiviert wird.",
            "notice_add_title": "About Admitad Account",
            "notice_2": "Um sicherzustellen, dass Ihr Admitad-Konto gut mit DSers funktioniert, gehen Sie bitte zu Einstellungen - Sonstiges - Partnerprogramm, um die Aktivierung abzuschließen. Andernfalls wird Ihr Admitad-Code nach 48 Stunden deaktiviert.",
            "not_account_name": "Bitte geben Sie den Namen Ihres Admitad-Kontos ein.",
            "notice_button_change": "Kontoname ändern",
            "notice_button_register": "Jetzt anmelden",
            "cancel_fulfillment_title": "Erfüllungsstornierung beantragen",
            "cancel_fulfillment_desc": "Nachdem Sie diese Funktion der entsprechenden App aktiviert haben, werden die Bestellungen, die nicht über die App aufgegeben werden können, weil Sie auf Shopify auf Request Fulfillment geklickt haben, wiederhergestellt, so dass Sie sie normal bei AliExpress aufgeben können.",
            "aliSku": "Verwenden Sie die Lieferanten-ID als Produkt-SKU",
            "aliSku_desc": "Wenn Sie nach der Aktivierung Lieferantenprodukte in die Importliste importieren, wird für die SKU des Produkts der Name der Lieferantenprodukt-ID-Variante verwendet.",
            "vat_title": "Wenn der Preis einer Bestellung nach Frankreich 150 Euro übersteigt, müssen Sie Ihre Umsatzsteuer-ID und Ihren Firmennamen angeben. Bitte füllen Sie diese in der folgenden Reihenfolge aus, um den normalen Prozess nicht zu beeinträchtigen. Klicken Sie <a target=\"_blank\" href=\"https://www.blog.dserspro.com/post/orders-to-france-specifications-with-shopify-dsers\">hier</a>, um weitere Informationen zu erhalten.",
            "vat_id_verify": "Bitte 8-12-stellige Umsatzsteuer-ID eingeben",
            "vat_input_verify": "Bitte Umsatzsteuer-ID eingeben",
            "vat_company": "Bitte geben Sie den Firmennamen ein",
            "vat_h3": "UMSATZSTEUER",
            "plan_info_id": "UMSATZSTEUER-ID",
            "plan_info_name": "Name des Unternehmens",
            "phone_rules_IT": "It requires 9 or 10 digits mobile phone numbers.",
            "set_product_title": "Produkte als steuerpflichtig festlegen",
            "set_product_subtitle": "Gibt an, ob beim Verkauf der Produktvariante eine Steuer erhoben wird oder nicht.",
            "ioss_message_more": "Klicken Sie <a href=\"https://www.dsers.com/blog/eu-vat-reform/\" target=\"_blank\">hier</a> um weitere Informationen zu erhalten..",
            "override_phone_number_it": "For orders sent to Italy, it requires 9 or 10 digits numbers.",
            "override_phone_number_intro_start_new": "Bitte beachten Sie das:",
            "override_phone_number_first": "ist eine 9-stellige Nummer erforderlich, die mit 5 beginnt.",
            "override_phone_number_second": "ist eine 9-stellige Nummer, die mit 5 beginnt, oder eine 10-stellige Nummer, die mit 0 beginnt, erforderlich.",
            "override_phone_number_third": "ist eine 9- oder 10-stellige Nummer erforderlich.",
            "override_phone_number_fourth": "ist eine 10- oder 11-stellige Nummer erforderlich.",
            "override_phone_number_fifth": "ist eine 10- oder 11-stellige Nummer erforderlich, die mit 01 oder 1 beginnt.",
            "phone_rules_SA_AE_IL_new": "Sie erfordert eine 9-stellige Zahl, die mit 5 beginnt, oder eine 10-stellige Zahl, die mit 0 beginnt.",
            "phone_rules_FR_new": "Dazu ist eine 9- oder 10-stellige Telefonnummer erforderlich.",
            "order_save": "SAVE",
            "order_fulfillment_module": "Einstellungen für die Auftragsabwicklung",
            "order_fulfillment_first_title": "Getrennte Erfüllung von Artikeln",
            "order_fulfillment_first_content": "Nach der Aktivierung dieser Funktion wird DSers jedes Produkt in den Bestellungen separat erfüllen und eine Versandbestätigung von Shopify mit den folgenden Regeln auslösen: Wenn ein Produkt in einer DSers-Bestellung eine Tracking-Nummer hat, werden wir diesen Artikel in der Bestellung erfüllen und Shopify wird dann eine E-Mail an Ihren Kunden senden. Shopify sendet die E-Mail mehrmals für dieselbe Bestellung.",
            "order_fulfillment_second_title": "Artikel in großen Mengen ausliefern",
            "order_fulfillment_second_content": "Nach der Aktivierung dieser Funktion wird DSers eine Bestellung erst dann erfüllen, wenn alle Produkte der Bestellung Tracking-Nummern erhalten haben und Shopify veranlassen, nur eine E-Mail an Ihren Kunden zu senden.",
            "order_split_pop_title": "Hinweis",
            "order_split_pop_content": "Um die Stabilität von DSers und die Erfahrung unserer Händler weiter zu verbessern, wird diese Funktion nicht mehr verfügbar sein, wenn Sie sie deaktivieren. Sind Sie sicher, dass Sie diese Funktion nicht mehr nutzen möchten?",
            "order_split_pop_btn": "BESTÄTIGEN"
            },
            "payment": {
                'top_title':"Geschäftliche Informationen",
                'top_small_title': 'Wird bei Tmall-Bestellungen verwendet.',
                'top_form_item1': 'Vorname',
                'top_form_item2': 'Nachname',
                'top_form_item3': 'Geschäftlicher Name',
                'top_form_item4': 'E-Mail',
                'top_form_item5': 'Land',
                'top_form_item6': 'Adresse',
                'top_form_item7': 'Wohnung, Appartement, etc.',
                'top_form_item8': 'Stadt',
                'top_form_item9': 'Bundesland',
                'top_form_item10': 'PLZ',
                'bottom_title': "Tmall Statements",
                'bottom_small_title': "Check and download monthly statement of Tmall orders. (Update the statement of the previous month on the x of each month)",
                'bottom_state1': "Bestätigen",
                'bottom_state2':"In der Anfrage"
            },
        "tab_sub_account": "Mitarbeiter-Konto",
        "tab_sub_supplier":'Lieferant',
        "tab_stores": "Filialen",
        "tab_product": "Produkt",
        "tab_order": "Bestellungen",
        "tab_paypal": "PayPal",
        "tab_sync": "Auto Update",
        "tab_aff": "Partnerprogramm",
        "priceUpdate": {
            "suppliers_price": "Wenn der Preis Ihres Lieferanten",
            "increase": "erhöhen",
            "decrease": "verringern",
            "pricing_change": "Und die Änderung des Preises übersteigt",
            "anychange": "Alle Änderungen",
            "title": "Automatische Preisaktualisierung",
            "auto_updates_tip": "After you turn on the automatic price update feature, DSers will track the changes in the supplier's product cost, and automatically synchronize the updated price to Shopify according to the pricing rule you set",
            "note": "Please note: You need to have the Pricing Rule feature activated to use this feature. If you change the price in Shopify, we will still update the price on Shopify according to the pricing rule you set on DSers. Update range: 10 days of ordered products & browsed products",
            "supplier": "Wenn der Lieferant den Preis ändert, soll DSers:",
            "updateCon": "Aktualisieren Sie den Preis und vergleichen Sie den Preis mit dem Shopify-Shop",
            "do_nothing": "Nichts tun",
            "to_shopify": "Preis in Shopify-Shop aktualisieren",
            "cost_change": "Update price when the cost changes",
            "to_setting": "Gehen Sie zu den Einstellungen und aktivieren Sie es",
            "to_price": "This store does not have pricing rules",
            "check_error": "Please check the price fluctuation method",
            "range": "The range cannot be 0",
            "priceError": "The store is disconnected, please go to Setting - Account to reconnect it"
        },
        "order": {
            "input_placeholder": "Enter the product's AliExpress link for which you want to split orders",
            "order_same": "The product link, quantity and country you configured are exactly the same, please check and save",
            "title": "Automatische Aufteilung der Bestellung",
            "desc": "Wir teilen die Bestellung für Sie gemäß dem AliExpress-Produktlink und der von Ihnen festgelegten Produktmenge auf. Regeln für die Aufteilung der Bestellung: Wenn das Produkt der Bestellung der von Ihnen eingestellte Lieferant ist und die Produktmenge größer oder gleich der von Ihnen eingestellten Menge ist, die kleiner als 10 sein muss, teilen wir die Bestellung automatisch in mehrere Shopify-Bestellungen auf.",
            "inputNumber": "Please enter the amount you want to split",
            "incorrect": "The product link you entered is incorrect",
            "banner_title": "Automatically split orders",
            "banner_text": "Having issue when using cost-effective AliExpress Saver Shipping? Check this solution.",
            "banner_btn": "learn more",
            "titip": "Order",
            "delivery_period_tip": "Mindestens 2, maximal 10",
            "delivery_period": "Anzahl der Produkte in den aufzuteilenden Bestellungen",
            "split_ending": "Splitting orders",
            "delete_dsers": "This product had been deleted in DSers"
        },
        "subaccount": {
            "add": "Mitarbeiterkonto hinzufügen",
            "sendemail": "Wir senden eine E-Mail an die von Ihnen angegebene E-Mail-Adresse, um die Autorisierung abzuschließen, und Ihre Mitarbeiter können sich mit diesem Konto bei DSers anmelden.",
            "admintext": "Alle Berechtigungen einschließlich Admin-Berechtigungen",
            "fulltext": "Alle Seiten und Funktionen, aber keine Admin-Berechtigungen",
            "limittext": "Dem Eigentümer entsprechend der von Ihnen konfigurierten Seite und des Shops anzeigen",
            "emailempty": "Please input the email address",
            "emailformat": "Bitte geben Sie eine korrekte E-Mail Adresse ein",
            "active": "Aktiv",
            "NoAccess": "No page access",
            "NoAccessTitle": "You do not have access to the current page, please contact your administrator",
            "report": "Bericht",
            "ImportList": "Liste importieren",
            "MyProduct": "Meine Produkte",
            "Openorder": "Öffnen Bestellungen",
            "ArchivedOrder": "Archivierte Bestellungen",
            "Tracking": "Sendungsverfolgung",
            "CSVBulkOrder": "CSV-Sammelbestellung",
            "LinktoMoreStores": "Link zu weiteren Shops",
            "Setting": "Einstellung",
            "Notification": "Benachrichtigung",
            "fullaccess": "Voller Zugang",
            "admin": "Verwaltung",
            "Limitedaccess": "Eingeschränkter Zugang",
            "Full": "Vollständig",
            "Storeaccess": "Zugang zum Shop",
            "Featureaccess": "Feature-Zugang",
            "invited": "eingeladen",
            "Notconnected": "Nicht verbunden"
        },
        "product": {
            "customize_title": "Anpassen der Produktbeschreibung",
            "customize_con": "Wenn Sie Produkte pushen, möchten Sie, dass DSers dies tut:",
            shipping_cost_title:'Senden Sie Produktinformationen an Shopify',
            "customize_specifications": "Nur die SPEZIFIKATIONEN der Produkte anzeigen",
            "customize_overview": "Nur die ÜBERSICHT der Produkte anzeigen",
            "customize_all": "Push both SPEZIFIKATIONEN & ÜBERBLICK",
            "location_title": "Product fulfillment service management",
            "location_content": "Sie können entscheiden, welcher Erledigungsdienst für Produkte und Aufträge auf der DSers-Plattform Ihnen bei der Verwaltung des Erledigungsdienst-Wechsels helfen soll. Wenn Sie den Schalter aktivieren, werden die Produktinformationen je nach Abschlussdienstleistung automatisch aktualisiert und die Aufträge angezeigt, die dieser Abschlussdienstleistung entsprechen. Wenn Sie diese Funktion deaktivieren, werden die Produktinformationen für den Abschlussservice nicht automatisch aktualisiert, und die Bestellungen für diesen Abschlussservice werden nicht angezeigt.",
            shipping_cost_tip1:'Senden Sie die Kosten der Lieferantenprodukte pro Artikel an Shopify, wenn Sie das Produkt pushen.',
            shipping_cost_tip2:`Wenn das Produkt gepusht wird, werden die Kosten für Lieferantenprodukte und die Versandkosten in [Advanced Pricing Rule] zusammen an die Kosten pro Artikel von Shopify zurückgegeben.Es kann einige Gründe geben, warum die Versandkosten 0 sind:<br />
            1. Nach der Anwendung der erweiterten Preisregel ist die dem Land angepasste Versandmethode kostenlos, und die an Shopify zurückgegebenen Versandkosten sind zu diesem Zeitpunkt 0.<br />
            2. Nachdem die erweiterte Preisregel angewendet wurde, wurden keine Versandmethoden entsprechend angepasst. Zu diesem Zeitpunkt betragen die an Shopify zurückgegebenen Versandkosten 0.<br />
            3. Nach der Anwendung der Grund-/Standardpreisregel fallen keine Versandkosten an, und die an Shopify zurückgegebenen Versandkosten betragen 0.<br />
            4. Die Preisregel ist nicht aktiviert, und es fallen keine Versandkosten an. Zu diesem Zeitpunkt betragen die an Shopify zurückgegebenen Versandkosten 0.<br />
            Sie müssen Ihren Plan aktualisieren und die erweiterte Preisregel anwenden, bevor DSers Ihnen dabei helfen kann, die Produktkosten und die Versandkosten an Shopify zurückzugeben.`
        },
        "product_price": "Produktpreis aktualisieren",
        "title_account": "Konto",
        "title_time_zone": "Zeitzone",
        "time_zone_des": "Ihre Standardzeitzone basiert auf den Einstellungen Ihres Browsers. Sie können hier eine Zeitzone für Ihr DSers-Konto auswählen.ABER bitte beachten Sie, dass Sie die Zeitzone nur einmal einstellen können und sie nach dem Speichern nicht mehr geändert werden kann.",
        "title_ae_account": "AliExpress-Konto",
        "ae_account_des": "Bitte beachten Sie, dass, wenn Sie sich mit einem anderen Konto verbinden, die Informationen der Bestellungen, die im aktuellen Konto aufgegeben wurden, nach dem Trennen der Verbindung nicht mehr auf DSers aktualisiert werden.",
        "title_synchronization": "Synchronisierung",
        "title_automatic": "Automatische Preisaktualisierung",
        "automatic_des": "Nachdem Sie die Funktion zur automatischen Preisaktualisierung aktiviert haben, wird DSers den aktualisierten Preis gemäß der von Ihnen festgelegten Preisregel alle 24 Stunden automatisch mit Shopify synchronisieren, wenn der Lieferant den Preis um mehr als $1 ändert.",
        "automatic_des2": "Please note: You need to have the Pricing Rule feature activated to use this feature. If you change the price in Shopify, we will update the price on Shopify according to the set pricing rule you on DSers.",
        "title_update_pro_pr": "Manuelles Ändern des Preises von Shopify-Produkten ",
        "update_pro_pr_des": "Aktualisieren Sie die Preise der Produkte, die in Ihren Shopify-Shop übertragen werden, gemäß den Preisregeln, die Sie derzeit festlegen. Dieser Vorgang kann einige Zeit in Anspruch nehmen. Diese Funktion kann alle 30 Tage verwendet werden.",
        "title_sync_shop_order": "Shopify-Bestellungen synchronisieren",
        "sync_shop_order_des": "DSers synchronisiert standardmäßig die Bestellungen der letzten 7 Tage. Wählen Sie einen Datumsbereich, um mehr Bestellungen zu synchronisieren, Sie können bis zu 30 Tage pro Zeit synchronisieren.",
        "title_order_details_sync": "Bestelldetails Synchronisation",
        "title_affiliate_program": "Partnerprogramm",
        "title_notification": "Benachrichtigungen",
        "title_shipping_confirmation": "Versand-Bestätigung",
        "title_shipping_confirmation_des": "Senden Sie die Shopify-Versandbestätigungs-E-Mail automatisch an Ihren Kunden, sobald die Tracking-Nummer generiert wurde",
        "title_paypal": "Senden Sie die Tracking-Nummer an PayPal",
        "title_tracking": "Nachverfolgung",
        "title_tracking_tip": "Aktivieren Sie diese Funktion, um den Tracking-Status von Bestellungen zu verfolgen, die versandt wurden.",
        "title_leave_message": "Hinterlassen Sie eine Nachricht an Lieferanten",
        "title_leave_message_ae":"AliExpress suppliers will get this message together with your order information.",
        "title_leave_message_tmall":"Tmall suppliers will get this message together with your order information, but the message edition is not supported now.",
        "message_des": "Die Lieferanten erhalten diese Nachricht zusammen mit Ihren Bestellinformationen.",
        "title_phone_number_op": "Optimierung der Telefonnummern",
        "title_automatic_order_split": "Automatische Aufteilung der Bestellung",
        "automatic_order_split_des": "Wir teilen die Bestellung für Sie gemäß dem AliExpress-Produktlink und der von Ihnen festgelegten Produktmenge auf. Regeln für die Aufteilung der Bestellung: Wenn das Produkt der Bestellung der von Ihnen eingestellte Lieferant ist und die Produktmenge größer oder gleich der von Ihnen eingestellten Menge ist, die kleiner als 10 sein muss, teilen wir die Bestellung automatisch in mehrere Shopify-Bestellungen auf.",
        "title_order_management": "Verwaltung von Aufträgen",
        "title_tax": "Steuer",
        "title_shipping": "Versand",
        "title_add_global_shipping": "Globale Versandmethode basierend auf dem Produkt hinzufügen",
        "add_global_shipping_des": "Legen Sie Ihre Standardversandmethode für bestimmte Lieferantenprodukte fest. Die produktbasierte Einstellung hat höhere Priorität als die länderbasierte Einstellung. Sie können schnell auf diese Funktion zugreifen, indem Sie auf Zuordnung - Weitere Aktionen gehen.",
         phone_number_des:"In order for your package to the Netherlands and Finland to be delivered properly, please fill in a real phone number in the order. The Netherlands and Finland do not support the use of the Phone Number Optimization feature for the time being. Click  <a href=\"https://help.dsers.com/fix-failed-orders-due-to-aliexpress-phone-number-regulations/\" target=\"_blank\">here</a> to learn about how to solve this problem",
         phone_number_des_tip:"This country is not currently supported",
         "title_pricing_rule": "Preisregel",
        "title_product": "Produkt",
        "title_sp_store": "Shopify-Geschäfte",
        "sp_stores_des": "Sie können Ihre Shopify-Shops hier verwalten. Wenn Sie DSers deinstallieren möchten, vergessen Sie bitte nicht, den Link auch hier zu entfernen.",
        "title_currencies": "Währungen",
        "disablestext": "Select a store",
        "password": "Kennwort",
        "admitadAccount1": "Das Admitad-Konto wurde aufgrund eines Verifizierungsfehlers gesperrt. Klicken ",
        "admitadAccountLink": "Sie hier",
        "admitadAccount2": " um genauere Informationen zu erhalten.",
        "affiliate_code": "Partner-Code",
        "affiliate_code_btn": "REAKTIVIEREN",
        "refresh_store_tag": "Verbindung aktualisieren",
        "refresh_store_tag_tip": 'Wenn der Status des Shopify-Shops aus Netzwerkgründen nicht aktuell ist, klicken Sie auf "Verbindung aktualisieren", um den Shop-Status zu erhalten',
        "refresh_not_connected":"Nicht verbunden",
        "refresh_store_icon_tip": "Refresh connect",
        "hasSetCurrency": "Wenden Sie den Währungskurs auf die Preisregeln an, wenn Sie Produkte in Shopify veröffentlichen. Die globalen Preisregeln funktionieren anders, wenn Sie NICHT in USD auf Shopify verkaufen und diese Funktion nicht aktivieren möchten. Bitte berechnen Sie die Multiplikatoren entsprechend",
        "pricing_rule_dsec": "Die Preisregel umfasst Währung, Preisschablonen und Cent-Zuweisung. Hier können Sie Ihre Preisregeln erstellen und sie auf Ihre Produkte anwenden.",
        "updateProductPrice": "Aktualisieren Sie den Preis der Produkte, die in Ihrem Shopify-Shop angeboten werden, gemäß den Preisregeln, die Sie derzeit festlegen. Der Prozess kann einige Zeit in Anspruch nehmen.klicken <a href=\"/app/pricing?select=1\"> Sie hier </a> um den Plan zu aktualisieren und die Synchronisierungszeiten zu erhöhen.",
        ...agency,
    
    }
}