export default {
  supplier_optimizer: {
    title: 'Otimização de fornecedores',
    title_test: 'Other supplier',
    sub_title: 'Based on your needs',
    msg:`Estamos ajustando o recurso de Supplier Optimizer e alguns dados podem estar incorretos. Estamos trabalhando arduamente para consertá-la. Obrigado por sua compreensão e paciência.`,
    intro: `Você pode encontrar o mesmo produto usando a URL do produto no AliExpress. Clique
      <a href="https://help.dsers.com/supplier-optimizer-for-shopify/" target="_blank">aqui</a> para saber mais.`,
    placeholder: 'Pesquise o mesmo produto com uma URL',
    product_list: 'Product List',
    ship_from: 'Enviado de',
    ship_to: 'Enviado para',
    sort_by: 'Sort by',
    product_title: 'Produtos',
    price: 'Price',
    sale_number: 'Número de vendas',
    product_rating: 'Avaliação do produto',
    store_rating: 'Store Rating',
    import_to_dsers: 'Import to DSers',
    not_found: `Não conseguimos encontrar um produto similar`,
    import_picture: 'Importar imagem',
    upload_tip: 'Carregue sua imagens locais e pesquisaremos pelo mesmo produto no AliExpress para você.',
    click_to_upload: 'Clique para carregar',
    click_or_drag: 'Clique para carregar ou arraste a imagem diretamente para cá',
    only_img: 'Somente imagens em formato PNG e JPG podem ser carregadas.(size within 8MB)',
    delivery: 'Delivery Date',
    delivery_info: 'Shipping Details',
    url_error: 'O formato de URL está incorreto. Insira a URL do produto no AliExpress',
    sys_error: 'O sistema está em manutenção no momento. Tente novamente em dois minutos',
    ship_tip: 'Clique nesta caixa caso queira importar as informações de envio do produto',
    search_tip: 'Clique em "pesquisar" para procurar fornecedores similares',
    shipping_price: 'Preço de envio',
    searched_product: 'Compared Product',
    ship_method: 'Método de envio',
    loading_state_1: 'Uploading picture...',
    loading_state_2: 'Analyzing picture...',
    loading_state_3: 'Searching products...',
    have_try: 'Give It a Try',
    click_or_drag:`Clique para carregar ou arraste a imagem diretamente para cá`,
    uploadimage:'UPLOAD IMAGE',
    agency:'Agência',
    agency_2:'Agência',
    agencySub:`Não consegue encontrar um agente de confiança? <br/>
    O DSers ajuda você a se conectar com um agente verificado capaz de atender às suas necessidades de fornecimento.`,
    learnmore:'SAIBA MAIS',
    Laura:'Esta é Laura. Sempre on-line e oferecendo um serviço sério, rápido e honesto a você.',
    Ciel:`Confiável e segura. Esta é Ciel. Seu tempo e esforço merecem ser combinados com a destreza de Ciel.`,
    Nora:'O objetivo é oferecer preços econômicos e envio confiável. Ansiosa para trabalharmos juntos!',
    Michael:'Especialista em encontrar todos os tipos de produtos. Serviço preciso e eficiente para os clientes.',
    Rebekah:"Olá. Meu nome é Rebekah. Ofereço produtos acessíveis com alta qualidade e resolvo seus problemas com pontualidade.",
    Chloe:'Familiarização com os processos de negócios de dropshipping. Objetivo do atendimento: encontrar e atender às suas demandas com todo o cuidado.',
    Kevin:'Foco em fornecimento de produtos para o Brasil, Europa e EUA. Preço de fábrica com envio rápido.',
    Bess:'Fornecedor chinês com foco em dropshipping, três anos de experiência e on-line 24 horas por dia.',
    Anchor:'A equipe de agentes profissionais do Fornecimento em Massa do DSers lhe convida a tirar suas dúvidas sobre o DSers e os produtos.',
    David:'Sou David. Consigo solucionar todos os seus problemas! Enquanto isso, podemos falar sobre a China ou outros países.',
    Tony:`A satisfação do cliente está sujeita a fatores abrangentes, incluindo o preço. O DSers está comprometido com isso.`,
    Alisa:'Alisa, da equipe de fornecimento do DSers, capaz de oferecer fornecimento e atendimento personalizado. Fique à vontade para compartilhar suas solicitações.',
    Keith:'24 horas ao seu dispor! Profissional com quatro anos de experiência nos negócios de fornecimento em massa para dropshipping!',
    Cohen:'Sou Cohen e adoro fazer amizades. Meu trabalho é atender às suas demandas e mitigar suas preocupações!',
    April:'Sou April. Disponibilizo produtos de alta qualidade a preços baixos e ofereço o melhor serviço!',
    Stone:'Meu nome é Stone. Sou especialista em conseguir bons preços para os clientes e tenho paciência para lidar com seus problemas.',
    Jessica:'Sou Jessica. Atenderei você de coração. Estou ansiosa para trabalharmos juntos, meu amigo!',
    Duan:'O melhor, mais rápido, mais honesto e sempre pronto para as suas solicitações!',
    Sophia:'Olá, amigos de dropshipping. Compartilhe suas solicitações e o DSers fará o melhor para satisfazê-las.',
    agenct:'Agente',
    agencytext:`Agentes de fornecimento são dedicados aos negócios de dropshipping.
    As equipes ficam baseadas principalmente na China, onde o serviço de fornecimento é muito sólido.`,
    search_product:'Pesquisar produto',
    optimizerTitle:`Pesquisa por fornecedores de qualidade`,
    optimizerDesc_1:'1. Insira a URL desejada na caixa de pesquisa ou use uma imagem',
    optimizerDesc_2:'2. Nos resultados de pesquisa, você pode escolher um fornecedor que atenda melhor às suas necessidades',
    noSearch:`No search results, please change the URL or image to search again`
  }
};
