import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Button,Spin } from 'antd';
import intl from 'react-intl-universal';
import { useHistory } from 'react-router-dom'
import styles from './TmallStartImport.less';
import { DSERS_IMG_HOST } from 'utils/constants';
import classNames from 'classnames';
import { CommonDataCollect, createAliItemUrl } from 'utils/utils';
import { getQueryUrlParams } from 'utils/global'
import { Post } from 'utils/request';
import close from '../../assets/coldStart/import_product/close.png'
import left from '../../assets/coldStart/import_product/left.png'
import right from '../../assets/coldStart/import_product/right.png'
import leftDown from '../../assets/coldStart/import_product/leftDown.png'

import pic1 from '../../assets/coldStart/import_product/1.png'
import pic2 from '../../assets/coldStart/import_product/2.png'
import pic3 from '../../assets/coldStart/import_product/3.png'

import rightDown from '../../assets/coldStart/import_product/rightDown.png'
import title from '../../assets/coldStart/import_product/title.png'
import pt_title from '../../assets/coldStart/import_product/pt_title.png'
import small_title from '../../assets/coldStart/import_product/small_title.png'
import pt_small_title from '../../assets/coldStart/import_product/pt_small_title.png'
import footBtn from '../../assets/coldStart/import_product/oneclick.png'
import pt_footBtn from '../../assets/coldStart/import_product/pt_oneclick.png'
import radio from '../../assets/coldStart/import_product/radio1.png'
import start from '../../assets/coldStart/import_product/start.png'
import flower from '../../assets/coldStart/import_product/flower.png'
import contentImg from '../../assets/coldStart/import_product/bj.png'
// import pt_contentImg from '../../assets/coldStart/import_product/pt_bj.png'
import className from 'classnames'
const modalRoot = document.getElementById('root');

function TmallStartImport({ lang }) {
  const history = useHistory()
  const currentLang = useRef(localStorage.getItem("lang") || 'en-US')
  const picArray = [pic1, pic2,pic3]
  const picId = ['2048033257179032', '2048040512029868', '2048024420785327'];
  const [slideStatus, setSlideStatus] = useState(null);
  const [visible, setVisible] = useState(false);
  const fromEmail = getQueryUrlParams(window.location.href, 'referrer')
  const [waitLoading,setWaitLoading] = useState(false);
 
  const footBtnImgs = {
    'en-US':footBtn,
    'de-DE':footBtn,
    'fr-FR':footBtn,
    'pt-PT':pt_footBtn,
    'it-IT':footBtn,
    'es-ES':footBtn,
  }

  const contentImgs = {
    'en-US':contentImg,
    'de-DE':contentImg,
    'fr-FR':contentImg,
    'pt-PT':contentImg,
    'it-IT':contentImg,
    'es-ES':contentImg,
  }

  const titleImgs = {
    'en-US':title,
    'de-DE':title,
    'fr-FR':title,
    'pt-PT':pt_title,
    'it-IT':title,
    'es-ES':title,
  }

  const titleTextImgs = {
    'en-US':small_title,
    'de-DE':small_title,
    'fr-FR':small_title,
    'pt-PT':pt_small_title,
    'it-IT':small_title,
    'es-ES':small_title,
  }

  useEffect(() => {
    // 需求让把之前的缓存清除掉
    if (localStorage.getItem("tmall_start_popDate90")) {
      localStorage.removeItem('tmall_start_popDate90')
    }
    if (!window.TMALL_USER || localStorage.getItem('tmall_start_popDate91')){
      return
    }

    // let record_popDate = localStorage.getItem("tmall_start_popDate");
    // if (record_popDate && ((new Date().getTime() - Number(record_popDate)) / (24 * 60 * 60 * 1000) < 7)) {
    //   return
    // }

    window.dtag.set({
      event:'tmall_import_pop',
      module:'tmall',
      action:'enter_page',
      custom_data:{from:window.location.href.includes('referrer=email')? 'email':'other'}
    })
    setVisible(true)
  },[])


  const handleClose = () => {
    // localStorage.setItem("tmall_start_popDate", new Date().getTime())
    localStorage.setItem("tmall_start_popDate91",true)
    setVisible(false)
    window.dtag.set({
      event:'tmall_import_pop',
      module:'tmall',
      action:'close_pop',
      custom_data:{from:window.location.href.includes('referrer=email')? 'email':'other'}
    })
  }

  const handleClickBtn = async () => {
    const id = []
     setWaitLoading(true)
     localStorage.setItem("tmall_start_popDate91",true)
    // localStorage.setItem('tmall_start_pop_import', true)
    let success_count = 0;
    //  这里只上传3个所以这边直接定死3原先为picId.length
    for (let i = 0; i < 3; i++){
      try {
        let result = await Post('/prod/select', {
          data: {
             product_id: picId[i],
             supplier_type: 4,
            scene: 'dsers_page',
            ref: 'similar',
          }
        });
        console.log(result,'result')
        if (result.code === 2000) {
          success_count++;
          id.push(result.data.unique_id)
        }
      } catch (error) {
        console.log('import product err', error);
      }
    }
    window.dtag.set({
      event:'tmall_import_pop',
      module:'tmall',
      action:'import_product',
      custom_data:{from:window.location.href.includes('referrer=email')? 'email':'other',success_count:success_count+""}
    })
    setWaitLoading(false)
    setVisible(false);
    // window.location.href = '/app/storemanage/import';
    history.push({pathname:'/storemanage/import',param:{'id':JSON.stringify(id)}})
  }
  if (!visible) {
    return null
  }
  return ReactDOM.createPortal(
    <div className={styles.notice}>
      <div
        className={styles.content}
        style={{ backgroundImage: `url(${contentImgs[currentLang.current]})` }}
      >
      {/* <img src={start} className={styles.start} /> */}
        {/* <img src={radio} className={styles.radio} /> */}
         {/* <img src={flower} className={styles.flower} /> */}
        
        <img src={close} className={styles.close} onClick={handleClose} />
        <img
          src={titleTextImgs[currentLang.current]}
          className={
            currentLang.current === 'pt-PT'
              ? styles.pt_title_text
              : styles.title_text
          }
        />

        <img
          src={titleImgs[currentLang.current]}
          className={
            currentLang.current === 'pt-PT' ? styles.pt_title : styles.title
          }
        />
        <div className={styles.scrollContent}>
          <div className={styles.enterContent}>
            {picArray.map(item => {
              return <img src={item} className={styles.item} />;
            })}
          </div>
        </div>
        <div className={styles.footArea}>
          {waitLoading ? (
            <div
              className={
                currentLang.current === 'pt-PT'
                  ? styles.footBgLoad
                  : styles.footBtnLoad
              }
            >
              <Spin
                spinning={true}
                style={{
                  position: 'absolute',
                  left: '50%',
                  top: '35%',
                  transform: 'translate(-50%)'
                }}
              ></Spin>
            </div>
          ) : (
            <img
              src={footBtnImgs[currentLang.current]}
              className={
                currentLang.current === 'pt-PT' ? styles.footBg : styles.footBtn
              }
              onClick={handleClickBtn}
            />
          )}
        </div>
      </div>
    </div>,
    modalRoot
  );
}

export default TmallStartImport;
