/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'dva';

import { routerRedux } from 'dva/router';
import { Icon } from 'antd';

import { pageTitle } from 'utils/constants';

import styles from './SignInPage.less';

import Login from './login';

import { defaultSelected } from './until';

const SignInPage = ({ dispatch, loginInfo }) => {
  const [btnLoading, setStnLoading] = useState(false);
  useEffect(() => {
    if (loginInfo && dispatch) {
      const path = window.location.pathname;
      if (path === '/app/partner/login') {
        dispatch(routerRedux.push('/partner/home'));
      }
    }
  }, [loginInfo, dispatch]);

  const handleSubmit = data => {
    setStnLoading(true);
    dispatch({
      type: 'partner/partnerLogin',
      payload: {
        ...data,
        callback: d => {
          setStnLoading(false);
          if (d.code === 2000) {
            defaultSelected(d.data);
          }
        }
      }
    });
  };

  const handlePreviousPage = () => {
    window.location.href = '/partner';
  };
  return (
    <DocumentTitle title={`Sign in - ${pageTitle}`}>
      <div className={styles.signIn}>
        <div className={styles.mantle}>
          <div className={styles.container}>
            <div className={styles.box}>
              <div className={styles.previous_page}>
                <div onClick={handlePreviousPage}>
                  <Icon type="arrow-left" />
                </div>
              </div>
              <div className={styles.boxCon}>
                <Login onSubmit={handleSubmit} btnLoading={btnLoading} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
};
export default connect(({ partner }) => ({ loginInfo: partner.loginInfo }))(
  SignInPage
);
