
    export default 
    {
    "complete_passWord": {
        "title": "Bitte registrieren Sie Ihre Kontodaten",
        "tip": "Wir haben festgestellt, dass Ihre Kontodaten nicht registriert sind. Bitte richten Sie Ihre E-Mail und Ihr Passwort ein.",
        "email_tip": "Sie können die E-Mail später ändern, indem Sie zu DSers - Einstellungen - Menü Konto gehen.",
        "fill_email": "Bitte geben Sie eine Email-Adresse ein",
        "password_tip": "Bitte verwenden Sie ein Passwort mit mindestens 6 Zeichen.",
        "email_not_valid": "Die eingegebene E-Mail ist nicht gültig!",
        "password_request": "Bitte geben Sie ein Passwort ein",
        "astrict": "Bitte geben Sie 6 bis 20 Zeichen ein."
    }
}