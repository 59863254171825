export default {
  linkStore: {
    website_platform: '¿Cuál es la plataforma de tu sitio web? *',
    website_domain: '¿Cuál es el dominio de tu sitio web? *',
    domain_placeholder: 'your-domain',
    addonAfter: '.myshopify.com',
    add_store: 'Añadir tienda',
    go_account: 'Introduce la cuenta Dsers',
    add_store_err:
      'Esta tienda está vinculada a una cuenta Dsers. Por favor, verifica de nuevo.',
    add_store_already:
      'La tienda ya está vinculada a otra cuenta Dsers. Por favor, verifica de nuevo.',
    valid_domain_msg:
      'Por favor, asegúrate de introducir sólo letras, números y guiones en inglés para el nombre de la tienda.',
    install_plugin_title:
      'Por favor, instala el plugin de Dsers para experimentar un servicio más estable',
    bind_ae_tip_1:
      '¿No estás familiarizado con el (No hay sugerencias) de AliExpress?',
    bind_ae_tip_url: 'https://www.dsers.com/blog/aliexpress-dropshipping-101/',
    bind_ae_tip_2: '¿No tienes cuenta en AliExpress?',
    process: {
      link_store: 'Link de la Tienda',
      choose_plan: 'Selecciona un plan',
      link_ae: 'Link de AliExpress',
      install_plugin: 'Instalar Plugin'
    },
    loading_Info:
      'No actualizar o cerrar la página mientras se establece el vínculo',
    bindAeTitle:
      'Click the AliExpress icon below to go to AliExpress for account authorization.',
    bindAeTitleTwo: 'Select the option below to connect your suppliers.',
    required: 'Required',
    linkSt: 'Vincular a AliExpress',
    modalTitle: 'Authorizing',
    modalText:
      'Please click on "Add App" in Shopify. After completing the authorization, click on "Update", and we will refresh the page for you. If you see "App Installed" in Shopify, it means that this Shopify account is already linked to DSers. If you want to link another store, please log in the Shopify store you want to link. Click <a href="https://help.dsers.com/link-your-store-for-shopify/" style="color:#3d5afe">here</a> to learn more.',
    modalButton: 'UPDATE',
    enterDsersTitle: 'Purchasing from other platform than AliExpress?',
    enterDsersTooltip:
      'Click the button below to go directly to your DSers account. You can choose from tens of thousands of Tmall items curated for you by DSers, or directly connect with your existing suppliers and let DSers manage your offline orders.',
    clickEnter: 'Click to enter DSers',
    aeModalTitle: 'Link your AliExpress account',
    aeModalText:
      'Linking your AliExpress account to DSers allows you to place 100+orders in seconds! Click here for more details.',
    aeModalBut: 'LINK ALIEXPRESS'
  }
};
