import React, { useEffect, useState, useRef } from 'react';
import { Button, Tooltip, Icon } from 'antd';
import { Post } from 'utils/request';
import intl from 'react-intl-universal';

import styles from './ProductCardMoreAction.less';

export function ProductCardMoreAction({
  openFunctionVisible, // 是否展示moreaction
  moreActionOptions, // moreaction options
  moreFunctionHandler, // 点击某个功能项
  closeFunctionHandler, // 关闭moreactions
  product,
  type
}) {
  const moreActionsDomRef = useRef();

  const [disReplaceProduct, setDisReplaceProduct] = useState(true);

  useEffect(()=>{
    let inDown = false;
    const handleMouseDown = e => {
      inDown = moreActionsDomRef.current?.contains(e.target);
    };
    const handleMouseUp = e => {
      if (inDown) {
        // openFunctionHandler(true);
      } else {
        closeFunctionHandler(false);
      }
    };

    document.addEventListener('mousedown', handleMouseDown, false);
    document.addEventListener('mouseup', handleMouseUp, false);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  },[])

  useEffect(() => {
    if (type === 'my_product' && openFunctionVisible) {
      getCount(product);
    }
  }, [openFunctionVisible]);
  // 获取该商品各个订单状态的数量
  const getCount = async product => {
    await Post('/ord/count', {
      data: {
        last: '',
        keywords: `${product?.prod?.detail?.id}`,
        search_type: 8,
        start_at: parseInt(new Date().getTime() / 1000) - 10368000,
        end_at: parseInt(new Date().getTime() / 1000),
        status: 2,
        fulfilled: 0,
        flags: [],
        source: 0,
        canceled: 3, //3:筛选只有aecancel的
        sort: 0,
        skip: '',
        sel_fields: ['_id', 'detail', 'ext', 'shopify_created_at', 'store_id'],
        key: '',
        country: '',
        untimeliness: false
      }
    }).then(res => {
      if (res) {
        if (
          res.data?.pending == 0 &&
          res.data?.awaiting_order == 0 &&
          !res.data.canceled &&
          res.data?.failed == 0
        ) {
          setDisReplaceProduct(false);
        } else {
          setDisReplaceProduct(true);
        }
      }
    });
  };
  if (type === 'importlist') {
    return (
      <div
        className={styles.moreAction}
        style={{
          height: openFunctionVisible
            ? `${moreActionOptions?.filter(i => !i.hidden).length * 48}px`
            : 0
        }}
        ref={moreActionsDomRef}
        // onMouseLeave={closeFunctionHandler}
      >
        <div className={styles.box}>
          {moreActionOptions.map(item => {
            if (!item.hidden) {
              if (item.id === 1) {
                return item.disabled ? (
                  <Tooltip placement="right" title={intl.get('mapping.distoryImgTip')}>
                    <Button
                      key={item.id}
                      onClick={() => moreFunctionHandler(item.id)}
                      disabled
                    >
                      <i class="material-icons">{item.icon}</i>
                      {item.name}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    key={item.id}
                    onClick={() => moreFunctionHandler(item.id)}
                  >
                    <i class="material-icons">{item.icon}</i>
                    {item.name}
                  </Button>
                );
              }
              return (
                <Button
                  className={styles.function}
                  key={item.id}
                  onClick={() => moreFunctionHandler(item.id)}
                >
                  <i class="material-icons">{item.icon}</i>
                  {item.name}
                </Button>
              );
            }
          })}
        </div>
      </div>
    );
  } else if (type === 'my_product') {
    return (
      <div
        className={styles.moreAction}
        style={{
          height: openFunctionVisible
            ? `${moreActionOptions?.filter(i => !i.hidden).length * 48}px`
            : 0
        }}
        ref={moreActionsDomRef}
        // onMouseLeave={closeFunctionHandler}
      >
        <div className={styles.box}>
          {moreActionOptions.map(item => {
            if (!item.hidden) {
              if (item.id === 1) {
                return item.disabled ? (
                  <Tooltip placement="right" title={intl.get('mapping.distoryImgTip')}>
                    <Button
                      key={item.id}
                      onClick={() => moreFunctionHandler(item.id)}
                      disabled
                    >
                      <i class="material-icons">{item.icon}</i>
                      {item.name}
                    </Button>
                  </Tooltip>
                ) : (
                  <Button
                    key={item.id}
                    onClick={() => moreFunctionHandler(item.id)}
                  >
                    <i class="material-icons">{item.icon}</i>
                    {item.name}
                  </Button>
                );
              } else if (item.id === 3) {
                return (
                  <Button
                    key={item.id}
                    onClick={() => moreFunctionHandler(item.id)}
                  >
                    <i class="material-icons">
                      {product.is_black_list !== 2 ? item.icon : item.icon2}
                    </i>
                    {product.is_black_list !== 2 ? item.name : item.name2}
                  </Button>
                );
              } else if (item.id === 4) {
                {
                  return (
                    <Tooltip
                      placement="right"
                      title={
                        disReplaceProduct
                          ? intl.getHTML(
                              'mapping.replace_product_tip',
                              product?.prod?.ext?.map_type === 6
                                ? { toAliExpress: '' }
                                : { toAliExpress: 'to AliExpress' }
                            )
                          : ''
                      }
                    >
                      <Button
                        key={item.id}
                        disabled={disReplaceProduct}
                        onClick={() => moreFunctionHandler(item.id)}
                      >
                        <i class="material-icons">{item.icon}</i>
                        {item.name}
                      </Button>
                    </Tooltip>
                  );
                }
              } else {
                return (
                  <Button
                    key={item.id}
                    onClick={() => moreFunctionHandler(item.id)}
                  >
                    <i class="material-icons">{item.icon}</i>
                    {item.name}
                  </Button>
                );
              }
            }
          })}
        </div>
      </div>
    );
  }
}
