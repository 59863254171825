
    export default 
    {
    "about": {
        "title": "Bienvenido(a)",
        "description": "En Dsers, creemos que todo el mundo se merece un método para efectuar pedidos rápido y eficiente. La eficiencia y la innovación son nuestro principal objetivo y nos esforzamos en eliminar cualquier barrera técnica o dificultad que pueda impedir a los dropshippers vender productos y completar pedidos. Estamos encantados de ayudarte en este viaje.",
        "our_story": "Nuestra historia",
        "our_story_1": "Dsers comenzó en 2018. Después de experimentar múltiples software de gestión de pedidos, nos dimos cuenta de que la velocidad de procesamiento de las soluciones de gestión en pedidos existentes no era suficiente para aliviar el trabajo repetitivo del Dropshipper. Los servicios tradicionales de procesamiento de pedidos son demasiado complejos, requieren mucho tiempo y son costosos de gestionar.",
        "our_story_2": "Creamos el sitio web DSers.com y tomamos en consideración el punto de vista del usuario. Queremos ofrecer una plataforma que no requiera pasar horas procesando pedidos. La mantenemos sencilla para que los usuarios puedan centrarse en mejorar su marketing y su marca. Lo más importante es que es gratis. Puedes establecer una selección de precios de logística para reducir costes, gestionar tu mercancía o empezar a procesar tus pedidos por lotes.",
        "our_story_3": "Después de ver un aumento en la demanda de soluciones e-commerce, desarrollamos la única plataforma de gestión online totalmente funcional y sin comisiones que permite a los Dropshippers gestionar sus propios pedidos y artículos en venta.",
        "our_story_4": "Hoy en día, estamos orgullosos de apoyar a personas y equipos independientes de todo el mundo. Todo Dropshipper debe tener una plataforma que simplifique las cosas y libere las manos para completar los pedidos, y nosotros nos alegramos de que puedas dedicar más tiempo a aumentar tu volumen de negocio.",
        "our_values": "Nuestros valores",
        "our_values_1": "Todo el mundo debería tener un software de gestión de pedidos que realmente permita ahorrar tiempo y costes. Creemos que todos deberían tener un flujo de trabajo fluido. Si puedes apuntar y hacer clic, puedes utilizar nuestro software de gestión de pedidos al por mayor, gratuito e intuitivo.",
        "our_values_2": "Nuestros clientes significan el mundo para nosotros",
        "our_values_3": "En Dsers, nos esforzamos por ofrecer los productos que nuestros clientes necesiten. Una gran parte de nuestro proceso de creación de ideas consiste en revisar los comentarios de nuestros clientes para asegurarnos de que se les atiende correctamente y de que desarrollamos las nuevas funciones que necesitan.",
        "our_values_4": "Fabricación interna",
        "our_values_5": "Nos comprometemos personalmente a ofrecer el mejor servicio. Desde la asistencia al cliente hasta el diseño y el desarrollo de productos, todo corre a cargo de nuestro profesional (y atractivo) equipo."
    }
}