export default {
  top: {
    bind_ae_expire:
      'A conta verificada do AliExpress que você vinculou ao DSers está prestes a expirar. Por favor, vincule sua conta do AliExpress novamente.', //  add
    whitelist_expire:
      'A conta verificada do AliExpress que você vinculou ao DSers está prestes a expirar. Por favor, vincule sua conta do AliExpress novamente.', //  add
    update_title: 'Atualizações de Autorizações', //  add
    update_desc:
      'O DSers detectou que suas contas abaixo precisam atualizar a autorização para o DSers. Caso contrário, você não poderá usar o DSers adequadamente', //  add
    update_desc_is_white: `O DSers detectou que as contas abaixo precisam solicitar a autorização para fazer parte da lista de verificação no AliExpress.
                          Clique no botão <a>Solicitar conta verificada</a> no topo da página para atualizar a autorização.
                          Do contrário, você não conseguirá usar o DSers adequadamente.`,
    update_btn: 'Atualizar', //  add
    synchronizing_store: 'Sincronizando loja...',
    top_select_store: 'Todas as Lojas',
    select_store: 'Selecionar Loja',
    link_to_aliExpress: 'Vincular ao AliExpress',
    bind_ali_tip: 'Clique aqui para vincular à conta do AliExpress', // m
    over_bind: 'Conta do Aliexpress Verificada',
    over_bind_tip:
      'Você já vinculou sua conta do AliExpress ao DSers e atualizou para a conta Verificada',
    apply_whitelist: 'Solicitar conta Verificada',
    timeout_tip: 'Clique para ter uma conta Verificada no AliExpress',
    link_shopify_tip: 'Clique para vincular múltiplas lojas do Shopify',
    link_to_more_stores: 'Vincular mais lojas',
    link_to_shopify_store: 'Vincular loja do Shopify',
    bind_ae_title: "Re-authorize AliExpress",
    bind_ae_content:`The AliExpress whitelisted account you have linked to DSers is about to expire. Please re-authorize AliExpress account. If you have any questions, please  <a href='https://www.messenger.com/t/DSersOfficial' target="_blank" >contact us</a>`,
    contact_by_email:
      'Se você tiver alguma dúvida, entre em contato com <span> support@dsers.com </span>',
    ae_account_linked: 'AliExpress account Linked',
    bind_ae_intro: 'A apenas um passo de gerenciar seus produtos e pedidos com DSers',
    bind_ae_outdated_intro: 'A autorização da conta do AliExpress expira a cada 3 meses. Clique em Link para AliExpress para renovar a autorização.',
    bind_ae_btn_modal:'Vincule sua conta do AliExpress',
    bind_ae_details:`Vincule sua conta do AliExpress para fazer mais de 100 pedidos em segundos! Clique <a href="https://help.dsers.com/link-your-shopify-store/" target="_blank">aqui</a> e saiba mais.`,
    bind_ae_foo_btn:`Vincular ao AliExpress`,
    chrome_dowm_tit:'Instale a extensão DSers para Chrome',
    chrome_dowm_detail:`Importação de produtos com um clique do AliExpress ao DSers para ganhar tempo e encontrar os itens mais vendidos! Clique <a target="_blank" href="https://help.dsers.com/import-product-from-aliexpress-for-shopify/#Use%20DSers%20Chrome%20Extension">aqui</a> e saiba mais.`,
    chrome_foo_btn:'Download',
    bindaeTip:`Status do pedido no AliExpress não atualizado`,
    bindaeTipDetail:`O DSers não foi capaz de acessar o status do pedido no AliExpress, pois você não vinculou sua conta do AliExpress. Vincule sua conta do AliExpress ao DSers o mais breve possível.`,
    binaeBtn:`VINCULAR AO ALIEXPRESS`,
    ChristmasTheme:'Tema Tmall',
    RegularTheme:'Tema Normal'
  }
};
