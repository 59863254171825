
    export default 
    {
    "notification": {
        "notifications": "Notificaciones",
        "mark_all_as_read": "Marcar todo como leído",
        "tip": "Sólo recibirás notificaciones de los productos que hayan sido solicitados en el pasado. Las notificaciones sobre Dsers se mostrarán durante 30 días",
        "regarding_to_aliexpress_statement": "Con respecto a la declaración de (No hay sugerencias):",
        "product_price_has_changed": "El coste del producto ha cambiado",
        "product_out_of_stock": "El producto de AliExpress está agotado o ya no está disponible",
        "order_canceled": "El pedido tiene que ser cancelado:",
        "order_no": "Nº de pedido",
        "ali_no": "Ali Nº:",
        "income": "Ingreso:",
        "check": "Comprobar",
        "view": "Ver",
        "more": "Más",
        "no_data": "Tu lista de notificaciones está vacía",
        "no_data_tip": "Te notificaremos los cambios en la disponibilidad o el precio de los productos",
        "at": "en",
        "not_available": "No disponible",
        "sku": "SKU",
        "const_change": "Cambio de precio",
        "got_it": "OK, entendido.",
        "go_to_shopify": "Ir a Shopify",
        "stock": "Stock",
        "no_match": "No hay coincidencia",
        "check_product": "Consultar producto",
        "copy_succ": "Copiado con éxito",
        "copy_fail": "copia fallida",
        "ali_product": "Producto Ali",
        "related_shopify_products": "Producto(s) relacionado(s) en la tienda de Shopify :",
        "price": "Precio",
        "compared_at_price": "Comparar precio",
        "update_to_shopify": "Actualización de Shopify",
        "variants_out_of_stock": "El SKU de AliExpress ha cambiado",
        "variants_not_of_stock": "El producto ya no está disponible",
        "variants_out_zero_stock": "La(s) variante(s) del producto de AliExpress está(n) agotada(s) o no está(n) disponible(s)",
        "related_shopify_products_length": "Producto(s) de Shopify mapeado(s) a este producto de AliExpress",
        "view_price": "Ver precio",
        "view_variant": "Ver variante",
        "offline_product": "¿Estás seguro de que quieres poner este producto fuera de línea? Si haces clic en Confirmar, el producto ya no será visible en tu tienda de Shopify.",
        "store_not_available": "La tienda ya no está disponible",
        "product_is_offline": "El producto está fuera de línea",
        "put_offline": "Escoge un producto para ponerlo fuera de línea",
        "set_new_value": "Establecer nuevo valor",
        "multiply_by": "Multiplicar por",
        "enter_value": "Introducir valor",
        "enter_multiplier": "Introducir multiplicador",
        "change_all": "Modificar todo",
        "mapping_changed": "Has cambiado el mapeo por defecto, el coste del producto ha sido modificado",
        "product_deleted": "El producto de Shopify que utiliza este proveedor fue eliminado, no se puede mostrar información detallada.",
        "store_removed": "La tienda de Shopify a la que pertenece este producto ha sido desconectada de Dsers, no se puede mostrar información detallada.",
        "focus_on_orders": {
            "p1": "Has",
            "p2": "cancelado pedidos, por favor, consulta"
        },
        "view_canceled_orders": "Ver pedidos cancelados",
        "noti_tit": "Notificación",
        "shopPrice": "Note that the currency shown here is the currency you set in DSers, not your Shopify currency",
        "modalTitle": "Delete Notification(s)",
        "modalText": "Are you sure you want to delete the notification(s)? It will be removed from your notification list.",
        "allNotification": "Ver todas las notificaciones",
        "mappingPlaceholder": "Escoge un producto para volver a mapear",
        "notification_feature": "Función de notificación",
        "filter_variant_out_of_stock": "Variante(s) fuera de stock",
        "cancelOrder": "Pedido cancelado",
        "clearFilter": "BORRAR UN FILTROS",
        "filterTitle": "Notificación de filtro",
        "defaultSupplier": "Proveedor por defecto",
        "secondSupplier": "Proveedor sustituto",
        "copyTitle": "Título de la copia",
        "copied": "Título Copiado",
        "filter": "Filtrar",
        "product_not_found": "Product not found",
        "product_out_of_stock_tip": "Product out of stock",
        get_product_err: `Error in obtaining product information, if you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`,
        get_product_err_2: `There was an error obtaining product information, please try again later. If you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`,
        no_support_mapping: `Because the mapping method of the product is not supported, the detailed information cannot be displayed. If you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`,
        mapping_trouble: `Detailed information cannot be displayed due to a problem with the SKU or mapping of the product. If you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`,
        product_sku_change: `Details cannot be displayed due to a change in the SKU of the item. If you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`
    }
}