import React, { useEffect, useState } from 'react';

import useTopBanner from 'hooks/useTopBanner';
import { list_V5 } from 'utils/bannerWhiteList';

import AdDrawerBanner from './OrderAd/AdDrawerBanner';
import OrderSupplier from './OrderSupplier/OrderSupplier';
import FindSupplierAd from './findSupplierAd/FindSupplierAd';
import { Get, Post } from 'utils/request';

const codesData=[
    'bgs6debcu2uif9ofthh0',
    'bjmnmr5d0c8u3ojpof20',
    'bniop6dqp3nd767bmfr0',
    'bohhmpik4th000c8ar70',
    'bpaosnlqp3n5vhmo9i6g',
    'bqerbbdqp3n90e83jnjg',
    'bramveid1qt000dd2qeg',
    'bsmdisg8t7g000fvgkbg',
    'bt88kphgmed000d1g74g',
    'c07coo62qr4g00be12n0',
    'c1c9mlrci9bg00cc7ubg',
    'c2h90j6np9kg00djfj7g',
    'c47r9lsi5nq000ag74ig',
    'c4r5oh779ts000a84cp0',
    'c72cqtatcfhg00e3ke6g',
    'c7an47hgn6j000fiiikg',
    'c7atj4kcqe2g00djfc10',
    'c8s3ciehog6g01m1efn0',
    'bvkkqb2q82pg0090d3r0'
]


const SideAdContainer = ({ path, userInfo, userId }) => {
    const [bannerData, setBannerData] = useState()
    const [adUser, setAdUser] = useState(false)
    const [pushedList, setPushedList] = useState()
    const [orderAdVisible] = useTopBanner(path, AdDrawerBanner.allowPath || []);
    const [orderAdVisible1] = useTopBanner(path, OrderSupplier.allowPath || []);
    const [findSupplierVisible] = useTopBanner(path,FindSupplierAd.allowPath || []);

    const [showCode,setShowCode] = useState(false)


    useEffect(()=>{
        const res = codesData.filter(i=>i===userId);
        if(res.length){
            setShowCode(true)
        }else{
            setShowCode(false)
        }
    },[userId])

    useEffect(() => {

        const getBannerData = async () => {
            const stores = userInfo.stores || []
            const storeIds = stores.map(item => {
                return item?.id
            }) || []
            const params = {
                data: {
                    store_ids: storeIds
                }
            }

            try {
                const res = await Get('/prod/propool/ad/list', params);
                if (res && res.code === 2000 && res.data) {
                    setBannerData(res.data[0])
                }
            } catch (error) {
                console.log('fetch error:', error)
            }
        }



        getBannerData()

    }, [])

    useEffect(() => {

        const getAdUser = async () => {
            try {
                const res = await Get('/ad/pushed/list')
                if (res && res.code === 2000) {
                    res.data.forEach((item) => {
                        if (item.user_id === userId) {
                            setAdUser(true)
                            setPushedList(item)
                        }
                    })
                }
            } catch (error) {
                console.log('fetch error:',error)
            }
        }

        getAdUser()
        
    }, [])
    // if(orderAdVisible1 &&showCode){
    //     return <OrderSupplier path={path} userInfo={userInfo} userId={userId} bannerData={bannerData} pushedList={pushedList} />
    // }
    // if (orderAdVisible && adUser) {
    //     return <AdDrawerBanner path={path} userInfo={userInfo} userId={userId} bannerData={bannerData} pushedList={pushedList} />
    // }
    return null
}

export default SideAdContainer;