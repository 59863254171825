export default {
  help_center: {
    help_center: {
      fixed_header: 'Central de ajuda',
      latest_news: 'Últimas notícias',
      load_more: 'Carregar mais',
      online_chat: 'Chat on-line no Messenger',
      online_time: 'Horário de atendimento: 7 AM - 12 AM (UTC+8)',
      work_days: 'Dias de atendimento: diariamente',
      message_us: 'Envie-nos uma mensagem',
      email_report: 'E-mail: Relatar um erro',
      email_report_desc: `Seu e-mail será respondido em <span style="font-weight: 700">um dia útil</span>.
        Caso não receba uma resposta, verifique sua pasta de lixo eletrônico e certifique-se que o domínio dsers.com não esteja em sua lista de bloqueio.`,
      product_sourcing: 'E-mail: Fornecimento de produtos',
      product_sourcing_desc: `Seu e-mail será respondido em <span style="font-weight: 700">um dia útil</span>.
        Informe na mensagem o nome da sua conta no DSers, URL de sua loja, link do produto no AliExpress,
        método de envio preferido e volume de vendas estimado nos últimos sete dias.`,
      blog_in_en: 'Blog em inglês',
      everything_about_ds: 'Tudo sobre o DSers',
      blog_in_fr: 'Blog em francês',
      coming_soon: 'Em breve...',
      blog_in_pt: 'Blog em português',
      youtube_channel: 'Canal no YouTube',
      check_ds_youtube: 'Veja o canal do DSers no YouTube'
    },
    entrance: {
      get_support: 'Acesso ao suporte',
      online_chat: 'Chat on-line'
    },
    question_modal: {
      title: 'Como podemos ajudá-lo?',
      tell_tip: 'Informe o tipo de problema que você está enfrentando',
      tell_select_placeholer: 'Selecione na lista',
      our_suggestions: 'Aqui estão nossas sugestões',
      useful_tip: 'Aqui estão algumas dicas que poderão ser úteis para você:',
      suggesttions_default_tip: `Vamos oferecer sugestões de acordo com  <br />o problema selecionado.
        Caso elas não sejam úteis para <br />você, será possível obter ajuda on-line <br />clicando no botão abaixo.`,
      get_online_help: 'Receber ajuda on-line'
    },
    detail: {
      by_ds_team: 'pela equipe DSers',
      ds_users: 'Caros usuários',
      so_much: 'Agradecemos por trabalharem com o DSers. <br /> Esperamos que tenham um ótimo dia.',
      to_contact: 'Em caso de dúvidas, <br />fiquem à vontade para entrar em contato.',
      happy_with_ds: 'Bom dropshipping com DSers.',
      best_regards: 'Atenciosamente, <br />Equipe DSers'
    },
    support_here: 'Receba suporte on-line aqui'
  }
};
