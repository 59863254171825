import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'dva';
import { Alert } from 'antd';

import { getLanguage } from 'utils/utils';
import { InfoAlert } from 'components/Banner/OptimizerInfoTip';

const InstallExtAlert = React.memo(props => {
    const [visible, setVisible] = useState(false);
    const handleClick = () => {
        setVisible(false);
        DSERS_CUSTOM_COLLECT({
            action: 'O_057_INSTALL_CHROME_EXTENSION',
            custom_info: []
        });
    };
    const tipMsg = useMemo(() => {
        let tip = (
            <span>
                We have detected that you are not installing the DSers Chrome extension. Please{' '}
                <a
                    style={{color: '#3d5afe'}}
                    // className={styles.link}
                    href="https://chrome.google.com/webstore/detail/dsers-aliexpresscom-produ/mmanaflgaempokjfbeeabkadnkoidjam?hl=en-US"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => handleClick()}
                >
                    install the DSers Chrome extension
                </a>{' '}
                to ensure timely and accurate synchronization of order information.
            </span>
        );
        return tip;
    }, []);
    useEffect(() => {
        if (!window.dsersExtVersion) {
            setVisible(true);
        }
    }, []);
    return visible && tipMsg ? <InfoAlert text={tipMsg} /> : null;
});

export default InstallExtAlert;
