import React, { Fragment } from 'react';
import { Icon, Modal, Checkbox, Button ,Alert, message} from 'antd';

import styles from './SelectImage.less';
import { replaceUrlToHttps } from 'utils/utils';
import { getAuthorityHeader } from '../../../utils/authority';
import intl from 'react-intl-universal';

import './ImageList.css';

import deleteimg from 'assets/alert/delete.png'
import downloadimg from 'assets/alert/download.png'
import noticeimg from 'assets/alert/notice.png'
import errorimg from 'assets/alert/pusherr.png'

export default class SelectImageManagement extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      previewVisible: false,
      previewImage: '',
      fileList: [],
      importStatus: true,
      deleteImageStatus: false,
      deleteImagesStatus: false,
      imageItem: '',
      isHiddenCheckBox:window.location.pathname.substring(window.location.pathname.lastIndexOf("/")+1).match(/import/),
      selectAll:false,
      deleteimgsList:undefined,
      product_unique_id:'',
      select_main_img:true,
      downloadimgloading:false
    };
  }

  componentWillMount() {
    this.props.producte_detail && this.props.data.forEach(item=>{
      if(item.src == this.props.producte_detail?.detail.image.src){
        item.is_main = true
      }
    })
    this.setState({
      fileList: this.props.data
    },()=>{
      this.state.fileList.forEach(item=>{
        if(item?.is_main && !item?.choose){
          this.setState({
            select_main_img:false
          })
        }else{
          this.setState({
            select_main_img:true
          })
        }
      })
    });
    const flag = this.props.data.every((i)=>{
      return i.choose == true
    })
    if(flag){
      this.setState({
        selectAll:true
      })
    }else{
      this.setState({
        selectAll:false
      })
    }
  }

  // shouldComponentUpdate(nextProps, nextState){
  //   if(this.state.fileList !== nextState.fileList && window.location.pathname.substring(window.location.pathname.lastIndexOf("/")+1) == 'import'){
      
  //   }
  //   return true
  // }
  
  componentWillReceiveProps(nextProps) {
    this.setState({
      fileList: nextProps.data
    });
  }
  
  handleCancel = () => this.setState({ previewVisible: false });
  
  handlePreview = file => {
    this.setState({
      previewImage: file.src,
      previewVisible: true
    });
  };
  
  // 删除图片
  deleteConfirm = item => {
    const { deleteImage } = this.props;
    this.setState({
      deleteImageStatus: true,
      imageItem: item
    })
    // Modal.confirm({
      //   title: intl.get('product_edit.delete_pic_tip'),
      //   onOk: () => deleteImage(item)
      // });
  };
    
  importChange = (e, index) => {
    const { fileList,selectAll } = this.state;
    fileList[index] && (fileList[index].choose = e.target?.checked);

    if(fileList[index]?.is_main && !fileList[index].choose){
      this.setState({
        select_main_img:false
      })
    }else{
      this.setState({
        select_main_img:true
      })
    }

    this.setState({
      fileList:[...fileList]
    },()=>{
      const flag = fileList.every((i)=>{
        return i.choose == true
      })
      if(flag){
        this.setState({
          selectAll:true
        })
      }else{
        this.setState({
          selectAll:false
        })
      }
      this.props.changeHiddenStatus()
    })
  }

  selectAllHandler = (e)=>{
    const { fileList } = this.state;
     fileList.map((i)=>{
      i.choose = !this.state.selectAll
    })
    this.setState({
      selectAll:!this.state.selectAll,
      fileList
    })
    this.props.changeHiddenStatus()
  }

  functionImgHandler= (type)=>{
    return async()=>{
      const { fileList } = this.state;
      const arr =[]
      const header = {
        ...getAuthorityHeader()
      };
      if(type == 'delete'){
        let dataList = this.props.producte_detail
        dataList.detail.images = fileList.filter((i)=>{
          if(!i.choose){
            return i
          }
        })
        
        this.setState({
          deleteImagesStatus:true,
          deleteimgsList:dataList,
          product_unique_id:this.props.producte_detail.product_unique_id
        })
      }else if(type == 'download'){
        this.setState({
          downloadimgloading:true
        })
        fileList.forEach((i)=>{
          if(i.choose){
            arr.push(i.src)
          }
        });
        try {
          let response = await fetch(`${window.DSERS_API_CONFIG.HOST}/api/v1/product/downloadimg`,{
            method: 'POST',
            headers: {
              'Content-Type': 'application/octet-stream',
              'authorization':header.Authorization
            },
            body:JSON.stringify({
              urls:arr
            })
          });
          
          let blob = await response.blob();
          let objectUrl = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = objectUrl;
          a.download='images.zip'
          a.click()
          a.remove(); 
          this.setState({
            downloadimgloading:false
          })
        } catch (error) {
          message.error(intl.get('product_edit.download_img_fail_tip'));
          this.setState({
            downloadimgloading:false
          })
        }
          
      }
    }
  }

  renderList = (list = [], is_pub) => {
    return (
      <div className="imageList">
        <div className={styles.imgaesList}>
          {window.location.pathname.substring(window.location.pathname.lastIndexOf("/")+1) == 'import' ?
            <>
              {
                !this.state.fileList.some(item=>{
                  return item.choose == true
                }) ? 
                <div className='img-error'>
                  <img src={errorimg} alt="" />
                  <span>{intl.get('product_edit.uncheckedAlert')}</span>
                </div> :               
                <div className='img-notice'>
                  <img src={noticeimg} alt="" />
                  <span>{this.state.select_main_img ? intl.get('product_edit.img_des'):intl.get('product_edit.not_select_main_img_des')}</span>
              </div>
              }
              <div className='fun-header'>
                <div className='check'>
                  <Checkbox checked={this.state.selectAll} onChange={this.selectAllHandler}>
                    <span style={{color:this.state.selectAll? '#FFA94D':''}}>
                      {intl.get('product_edit.action')}
                    </span>
                  </Checkbox>
                </div>
                <div className='fun-right'>
                  <Button className='box' loading={this.state.downloadimgloading} onClick={this.functionImgHandler('download')} disabled={!this.state.fileList.some((i)=>{
                    return i.choose == true
                  })}>
                    <i className='material-icons notranslate' >archive</i>
                    <span>{intl.get('product_edit.download_all_img')}</span>
                  </Button>
                  <div className='line'></div>
                  <Button className='box' onClick={this.functionImgHandler('delete')} disabled={!this.state.fileList.some((i)=>{
                    return i.choose == true
                  })}>
                    <i className='material-icons notranslate' >delete</i>
                    <span>{intl.get('product_edit.delete_img')}</span>
                  </Button>
                </div>
              </div>
            </>:null
          }
          <div className="bblist">
            {list.map((item, index) => {
              // const checked = item.image.variant_ids.length != 0 || index == 0 ? "checked" : "";
              return (
                <div className="ant-upload-list-item" key={item.src}>
                  <div className="checkImage"style={{display:this.state.isHiddenCheckBox? 'block':'none' }}/>
                  <Checkbox style={{position: 'absolute', left: '10px',display:this.state.isHiddenCheckBox? 'block':'none' }} checked={item.choose} onChange={(e) => this.importChange(e, index)}/>
                  <div className="info">
                    <span>
                      <a
                        className="ant-upload-list-item-thumbnail"
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span className={styles.imgCons}>
                          <img src={replaceUrlToHttps(item.src)} />
                          <span className={styles.imgLogoCon}>
                            {
                            item?.is_main ?
                            <span className={styles.imgLogo}>Cover picture</span>
                            :null}
                            {item.alt ? 
                            <span className={styles.imgLogo}>Variant picture</span>
                            :null}
                          </span>
                        </span>
                      </a>
                      {/* <a
                        className="ant-upload-list-item-name"
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      /> */}
                    </span>
                  </div>
                  <span className="ant-upload-list-item-actions">
                    {/* <a
                      onClick={e => {
                        e.stopPropagation();
                        this.handlePreview(item);
                      }}
                    >
                      <i class="material-icons notranslate">remove_red_eye</i>
                    </a> */}
                    <a onClick={() => this.deleteConfirm(item)}>
                    {!is_pub ? (
                      <>
                        <div className="deleteImage"/>
                        <i class="material-icons notranslate" style={{position: 'absolute', right: '0px', top: '0px'}}>delete</i>
                      </>
                    ) : null}
                    </a>
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { product_id, is_pub } = this.props;

    const { previewVisible, previewImage, fileList, deleteImageStatus,deleteImagesStatus, imageItem } = this.state;

    const headers = {
      ...getAuthorityHeader()
    };

    return (
      <>
        <Fragment>
          {/* <div className={styles.downloadBtn}>
            <a
              href={`${window.DSERS_API_CONFIG.HOST}/api/v1/product/downloadimg?product_id=${product_id}&token=${
                headers.Authorization
              }&store_id=${localStorage.getItem('storeId')}`}
              target="_blank"
            >
              {intl.get('product_edit.download_pic')}
            </a>
          </div> */}
          {this.renderList(fileList, is_pub)}
          <Modal
            visible={previewVisible}
            footer={null}
            onCancel={this.handleCancel}
          >
            <img alt="example" style={{ width: '100%' }} src={previewImage} />
          </Modal>
        </Fragment>
        <Modal
          title="Deleted Image"
          visible={deleteImageStatus}
          footer={null}
          width={560}
          className={styles.deleteModal}
          onCancel={() => {this.setState({
            deleteImageStatus: false
          })}}
        >
          {intl.get('product_edit.delete_pic_tip')}
          <div className={styles.deleteBtn}>
            <Button onClick={() => {this.props.deleteImage(imageItem); this.setState({deleteImageStatus: false})}} data="dk">CONFIRM</Button>
          </div>
        </Modal>

        <Modal
          title="Deleted Image"
          visible={deleteImagesStatus}
          footer={null}
          width={560}
          className={styles.deleteModal}
          onCancel={() => {this.setState({
            deleteImagesStatus: false
          })}}
        >
          {this.state.selectAll ? intl.get('product_edit.delete_picss_tip') : intl.get('product_edit.delete_pic_tip')}
          <div className={styles.deleteBtn}>
            <Button onClick={() => {this.props.deleteImages(this.state.deleteimgsList,this.state.product_unique_id); this.setState({deleteImagesStatus: false})}} data="dk">CONFIRM</Button>
          </div>
        </Modal>
      </>
    );
  }
}
