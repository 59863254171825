/* eslint quotes: ["error", "single", { "allowTemplateLiterals": true }] */

import whyDisconnect from './fr/whyDisconnect';
import bargain from './fr/bargain';
import reward from './fr/reward';
import settingSupply from './fr/setting-supply';
import common from './fr/common';
import chore from './fr/chore';
import publicIntl from './fr/public';
import home from './fr/home';
import products from './fr/products';
import productEdit from './fr/productEdit';
import mapping from './fr/mapping';
import order from './fr/order';
import csv from './fr/csv';
import notification from './fr/notification';
import setting from './fr/setting';
import top from './fr/top';
import linkStore from './fr/linkStore';
import pricing from './fr/pricing';
import banner from './fr/banner';
import about from './fr/about';
import support from './fr/support';
import guidance from './fr/guidance';
import login from './fr/login';
import register from './fr/register';
import fulfillmentCenter from './fr/fulfillmentCenter';
import importList from './fr/importList';
import paypal from './fr/paypal';
import helpCenter from './fr/helpCenter';
import bannerV2 from './fr/bannerV2';
import supplierOptimizer from './fr/supplierOptimizer';
import partner from './fr/partner';
import tracking from './fr/tracking';
import guide from './fr/guide';
import migration from './fr/migration';
import tmallTestExpress from './fr/tmallTestExpress';
import beforeMigration from './fr/beforeMigration';

export default {
  ...beforeMigration,
  ...publicIntl,
  ...home,
  ...products,
  ...productEdit,
  ...mapping,
  ...order,
  ...csv,
  ...notification,
  ...setting,
  ...top,
  ...linkStore,
  ...pricing,
  ...banner,
  ...about,
  ...support,
  ...guidance,
  ...login,
  ...register,
  ...fulfillmentCenter,
  ...importList,
  ...whyDisconnect,
  ...bargain,
  ...reward,
  ...chore,
  ...paypal,
  ...helpCenter,
  ...bannerV2,
  ...common,
  ...settingSupply,
  ...supplierOptimizer,
  ...partner,
  ...tracking,
  ...guide,
  ...migration,
  ...tmallTestExpress,
  rapport: {
    report: 'Rapport des Ventes',
    link: 'Vous n’avez pas de boutique liée à DSers. Veuillez ',
    more: 'lier une boutique.'
  },
  suppliers: {
    categary: 'Toutes Catégories',
    copyLink: 'Copier lien',
    importBtn: 'Ajouter à Liste d’Import',
    importBtnEd: 'Importé',
    optimizer: 'Optimiseur de Fournisseur'
  },
  impotList: {
    title: 'Liste d’Import'
  },
  combo: {
    headline: 'Gestion souscription',
    explain:
      'Gérez votre souscription et choisissez votre boutique de facturation par défaut. Cliquez <a href="https://help.dsers.com/change-subscription-plan-for-shopify/" track-type="blog1" track-name="subscription" target="_blank" style=\'color:#3D5AFE\'> ici </a> pour vérifier comment gérer la souscription. Si vous voulez en apprendre plus sur les plans Dsers, veuillez cliquer <a href="https://help.dsers.com/subscription-plans-presentation-for-shopify/" target="_blank" track-type="blog2" track-name="subscription" style=\'color:#3D5AFE\'> ici </a>.',
    change: 'Changer plan',
    changEcard: 'Plan actuel:',
    changEcardBu: 'Changer plan',
    changEcardUrl: 'Comment annuler la souscription pour un plan payé?',
    changEcardservice: 'Contact',
    paymentcard: 'Boutique de facturation par défaut',
    paymentcardEcard:
      "Veuillez choisir une boutique de facturation par défaut. Si vous n'en choisissez pas une, votre compte DSers sera fermé jusqu'à votre choix.",
    paymentcardBu: 'Activer'
  },
  menu: {
    Switch: 'Switch platform',
    blog: 'BLOG',
    facebook: 'Facebook',
    youtube: 'YouTube',
    email_support: 'Support Email'
  },
  duplicate_order_confirm: {
    dec1: 'Il semblerait que vous avez déjà placé les commandes',
    dec2:
      'vers AliExpress précédemment. Voulez-vous les placer de nouveau vers AliExpress? Cela peut engendrer des commandes en double.',
    input_confirm: 'Entrez CONFIRMER pour placer les commandes vers AliExpress.'
  },
  new_feature: {
    csv_bulk_order: 'Commande Groupée par CSV',
    describe: 'Pour les utilisateurs sans boutique Shopify'
  },
  header: {
    about: 'NOUS CONNAITRE',
    support: 'SUPPORT',
    blog: 'BLOG',
    sign_in: 'Se Connecter',
    sign_up: "S'inscrire"
  },
  footer: {
    about: 'Nous connaître',
    support: 'Contact',
    privacy_policy: 'Politique de Confidentialité'
  },
  send_reset_email: {
    title: 'Réinitialiser Mot de Passe',
    tip:
      'Nous enverrons un code de vérification à votre email pour vérifier votre identité.',
    dec: `Entrez l'adresse email utilisé lors de votre inscription.
          Si vous avez installé DSers depuis Shopify, veuillez entrer l'adresse email de votre compte Shopify. Si vous avez oublié votre email, veuillez`,
    contact_us: 'nous contacter',
    placeholder: 'E-mail',
    email_instructions: 'Instructions Email',
    send_succ:
      'Les instructions pour réinitialiser votre mot de passe vous ont été envoyé par email.',
    email_not_valid: "L'email entré est invalide!",
    email_empty: 'Veuillez entrer un email!',
    email_unknown: 'Please input your log in email.'
  },
  reset_password: {
    title: 'Réinitialiser Mot de Passe',
    input_old_password: 'Veuillez entrer votre ancien mot de passe!',
    old_password: 'Ancien Mot de Passe',
    new_password: 'Nouveau Mot de Passe',
    input_confirm_password:
      'Entrez de nouveau votre mot de passe pour confirmer!',
    confirm_password: 'Confirmer mot de passe',
    reset_password: 'Réinitialiser Mot de Passe',
    wrong_password: 'Mot de passe incorrect, veuillez confirmer de nouveau.',
    old_password_error: "Le mot de passe d'origine ne correspond pas"
  },
  admitad: {
    //  add
    title: 'Admitad pour DSers est là!',
    p1: 'La coopération officielle entre DSers et Admitad est en ligne.',
    p2: "Veuillez vérifier l'article suivant pour un guide d'utilisation:",
    p3: 'https://help.dsers.com/admitad-cashback-for-shopify/',
    p4: "Une fois terminé, obtenez un nouveau code dans l'offre AliExpress WW.",
    p5:
      'Changer le code que vous utilisez actuellement, et remplacer le par le nouveau code.',
    p6:
      'Note: Les codes que ne sont pas affiliés à DSers cesseront de fonctionner le mois prochain.'
  },
  product_pool: {
    title:
      '<i>Plus de 5000</i> produits Maison & Jardin gérés par AliExpress sont disponibles, dont des masques antivirus. Envoi garanti entre 24 et 48 heures.',
    title_long: `AliExpress fournit officiellement des produits avec une distribution sécurisée.
                Tous les produits sont pris en charge par AliExpress directement et peuvent être envoyé entre 24 et 48h.
                Si vous avez des produits en relation avec ces produits officiels,
                le service client est disponible à dropshipping@list.alibaba-inc.com.
                L'équipe de fournisseur de DSers peut également vous aider à supply-support@dsers.com.
                Merci à tous nos utilisateurs qui s'inquiètent, la situation en Chine s'améliore chaque jour.
                Nous espérons que vos business ne seront pas impactés et vous souhaitons beaucoup de réuissite.`
  },
  market: {
    placeholder: 'Tapez les mots clés à rechercher',
    import_to_dsers: 'Importer vers DSers',
    import_tip_title:
      'Veuillez choisir les boutiques pour lesquelles vous voulez importer les produits'
  },
  refresh_mask: {
    title: 'Mise à jour de statut',
    dec: 'Veuillez rafraichir la page pour mettre à jour le statut.'
  },
  select_platform: {
    title: 'À quelle plateforme voulez-vous accéder ?'
  },
  aeAccountDisconnect: {
    title:
      "Votre compte DSers n'est plus lié à AliExpress. <i>Vos commandes et produits sont temporairement indisponibles.</i> Ils seront disponibles de nouveau une fois que vous avez re-lier le même compte AliExpress."
  },
  archeved: {
    title: 'Commandes Archivées',
    blank_tips:
      'La commande a été archivé sur Shopify. Si vous souhaitez la modifier, rendez-vous sur Shopify.',
    dec:
      'Les commandes traitées depuis plus de 4 mois seront déplacées dans l\'Archive. Vous pourrez toujours désarchiver pour réaliser certaines actions. Veuillez cliquer <a href={href} target="_blank">ici</a> pour en apprendre plus.',
    customer: 'Client',
    email: 'Email',
    unarchive: 'Désarchiver',
    unarcheved_succ:
      'Commande désarchivée avec succès, vous pouvez trouver la commande dans "Ouverte".',
    unarcheved_fail:
      'Échec de la désarchivation, veuillez réessayer plus tard.',
    table_title_order: 'Commande',
    date_title: 'Date d’archivage:',
    processed:`Les commandes qui n'ont pas été traitées depuis plus d'un an seront automatiquement placées dans le menu Commandes Archivées. Vous pouvez toujours désarchiver les commandes pour effectuer plus d'opérations.`
  },
  top_stores: {
    store_authorized_tip: 'Cette boutique doit être réautorisé. Rendez-vous dans <a href="/app/settings?id=3">Réglages - Boutiques</a> pour mettre à jour l\'autorisation. Autrement vous ne pourrez plus gérer cette boutique avec Dsers'
  },
  tutorial_over_modal: {
    dec:
      "Rendez-vous dans Shopify - Paramètres - Paiement - Traitement des commandes, pour vérifier que vous n'utilisez pas la fonction de traitement automatique. Si c'est le cas, veuillez la désactiver et Enregistrer les nouveaux paramètres.",
    dec2:
      'Veuillez noter que la fonction de traitement automatique de Shopify traitera les commandes automatiquement, vous empêchant de les placer vers AliExpress. Si cette fonction est activée sur Shopify, <i>vous ne pourrez pas placer vos commandes avec Dsers</i>.'
  },
  h5_callback: {
    bind_tip:
      "Êtes-vous sûr de vouloir lier la boutique Shopify { shop_name } utilisant l'email { bind_email } au compte DSers utilisant l'email { current_email }?",
    login_tip: 'Veuillez reconnecter à la boutique',
    bing_auth: `Votre autorisation a expiré, veuillez rafraichir la page pour ré-autoriser`,
    authAgin: `Votre autorisation actuelle a expiré, veuillez cliquer le bouton ci-dessous pour mettre à jour l'autorisation et re-lier`,
    auth_log: 'Déconnection',
    auth_entry: 'Ré-autoriser',
    bind_time_out:
      'Désolé, la connexion a expiré, veuillez cliquez le bouton ci-dessous pour connecter de nouveau',
    relink: 'Connecter',
    need_activate: `La boutique était précédemment indisponible à cause d'une limitation du plan choisi, veuillez accéder à la page des souscriptions pour activer la boutique correspondante`,
    activate: 'Activation'
  },
  h5_landModal: {
    create_account: 'Créer un compte pour vous',
    register: 'Créer un compte pour vous',
    existing_account: 'Vous avez un compte?',
    customer_service: 'Si vous avez des questions, veuillez nous contacter',
    overtime_con:
      'La demande a expiré, veuillez retourner à votre boutique Shopify et réinitialiser la liaison',
    log_in: 'Connexion',
    sign_up: "S'inscrire",
    contact_us: 'Nous contacter',
    return_shopify: 'Retour vers Shopify',
    note_con:
      "Votre adresse e-mail Shopify a été utilisée pour créer un nom de connexion à votre compte DSers. S'il s'agit de votre compte enregistré, vous pouvez essayer de vous connecter. S'il ne s'agit pas de votre compte enregistré, veuillez vous inscrire manuellement",
    welcome_dsers: 'Bienvenue sur DSers',
    welcome_con:
      'Nous utiliserons votre adresse e-mail Shopify pour générer un compte DSers pour vous, le nom du compte sera votre adresse e-mail enregistrée sur Shopify'
  },
  h5_redirect: {
    bind_tip:
      "Êtes-vous sûr de vouloir lier au compte DSers utilisant l'email { email }?"
  },
  error_cashback_code: {
    title: "Votre cashback Admitad s'arrête. <br />Veuillez vérifier.",
    title_dec: "Il semblerait que vous n'utilisez pas un code sous DSers.",
    p1: 'Il semblerait que vous utilisez un code affilié non rattaché à DSers.',
    p2: 'Vous ne pouvez plus obtenir de cashback avec le code actuel.',
    p3:
      "Veuillez cliquer ci-dessous pour savoir ce qu'il s'est passé et comment gérer la situation."
  },
  // 文案新加
  publicDocument: {
    scope: '',
    warn:
      'Notre système a détecté que le mot de passe est trop simple. Rendez-vous dans Réglages - Comptes pour changer votre mot de passe après votre connexion.',
    newPass: 'Veuillez entrer en nouveau mot de passe.',
    safety: `Le mot de passe n'est pas sûr.`
  },
  ad_1688: {
    top_banner: `Le programme DSers Mass Supplier est disponible! En partenariat avec l'équipe de fournisseur d'AliExpress, 1688 et Dsers.
                Obtenez de 10% à 30% de promotion sur les produits!`,
    learn_more: 'En savoir plus'
  },
  shop_push: {
    title: 'Contactez nous pour 10% à 30% de promotion sur les produits!',
    dsers_price: 'Prix DSers:',
    current_price: 'Prix actuel:',
    profit_margin: 'Profit:',
    supplier: 'Fournisseur:',
    dsers_program: 'Programme DSers Mass Supplier',
    link_copied: 'Lien copié!',
    copy_link: 'Copier lien du produit pour mapper',
    contact: 'Contactez-nous pour des promotions',
    what_dec: `Qu'est-ce que le programme DSers Mass Supplier`,
    thank_you: 'Merci pour votre demande',
    fill_email: 'Veuillez entrer votre email <br /> pour compléter la demande.',
    fill_email_placeholder: 'Veuillez entrer votre email',
    send_request: 'Envoyer demande',
    reach_you: `L'équipe de fournisseur de Dsers et d'AliExpress <br /> vous contactera bientôt.<br /> Bonne journée!`
  },
  complete_passWord: {
    title: 'Veuillez entrer les informations de votre compte',
    tip:
      'Nous avons déctecté que les informations de votre compte ne sont pas enregistrés. <br /> Veuillez entrer votre Email et Mot de Passe',
    email_tip: `Vous pouvez changer d'email plus tard dans DSers - Réglages - Compte.`,
    fill_email: 'Veuillez entrer votre adresse email',
    password_tip: 'Veuillez utiliser au moins 6 caractères.',
    email_not_valid: `L'email entré est invalide!`,
    password_request: 'Veuillez entrer un mot de passe',
    astrict: 'Veuillez entrer 6 à 20 caractères'
  },
  notice_plan_banner: `Notre système a détecté que votre compte Dsers utilise la plan Basic,
    mais la fonction: Mise à jour automatique a été activé dans votre compte.
    Veuillez noter qu'il s'agit d'une fonction payante.
    La fonction: Mise à jour automatique restera gratuite jusqu'au 1er Janvier 2020.
    Après la période gratuite d'essai, veuillez choisir le plan Avancé ou Pro pour continuer d'utiliser cette fonction.
    Désolé de la gêne occasionnée. Merci de votre compréhension et bon dropshipping!`,
  onBoarding:{
    selectPlatfrom:"Select your Platform",
    selectPlatfromTitle:'Please select the platform of the store you want to link.',
    linkShopify:'Link a Shopify store',
    linkWoo:'Link a WooCommerce store',
    linkWix:'Link a Wix store',
    linkCsv:'Use CSV files from other platforms',
    logout:'Déconnexion',
    linkAeTitle:'Associez votre magasin à AliExpress'
  }
};
