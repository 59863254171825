import React, { Component } from 'react';
import { connect } from 'dva';
import { Form, Input, Button, Popover, Alert, message } from 'antd';
import TextField from '@material-ui/core/TextField';
import intl from 'react-intl-universal';

import styles from './Register.less';
import CheckPasswordSafety from './CheckPasswordSafety';
import { checkPassword } from './validator';


import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import IconButton from '@material-ui/core/IconButton';


const FormItem = Form.Item;

@connect(({ loading }) => ({
  loading: loading.effects['topBtn/resetPassword']
}))
@Form.create()
export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmDirty: false,
      visible: false,
      help: '',
      helpTwo: '',
      visibleTwo: false,
      oldPas: false
    };
  }

  handleSubmit = e => {
    e.preventDefault();
    const { help, helpTwo } = this.state;
    this.props.form.validateFields({ force: true }, (err, values) => {
      if (!err) {
        if (values.newpassword !== values.confirmpassword) {
          message.error(intl.get('reset_password.wrong_password'));
          return;
        }
        this.props.dispatch({
          type: 'topBtn/resetPassword',
          payload: {
            data: {
              pass: values.oldpassword,
              new: values.newpassword
            },
            callback: d => {
              if (d.code === 2010) {
                this.props.dispatch({
                  type: 'user/setPasswordModalVisible',
                  payload: false
                });
                message.success('Password changed successfully')
               setTimeout(() => {
                localStorage.removeItem('dser-pro-authority');
                window.location.href = '/';
               }, 1000);
              }
              if (d.code === 4030) {
                this.setState({
                  oldPas: true
                });
              }
            }
          }
        });
      }
    });
  };

  checkEveryWordIsSame = v => {
    if (!v) {
      return false;
    }
    const one = v.substring(0, 1);
    const len = v.length;
    const d = new Array(len).fill(one).join('');
    let flag = false;

    if (d == v && len > 5) {
      flag = true;
    }
    return flag;
  };

  renderMessage = content => {
    return (
      <Alert
        style={{ marginBottom: 24 }}
        message={content}
        type="error"
        showIcon
      />
    );
  };

  render() {
    const { form, loading } = this.props;
    const { getFieldDecorator, getFieldValue } = form;

    return (
      <div className={styles.resetPassbox}>
        {this.state.oldPas ? <Alert style={{ marginBottom: 24 }} type="error" message={intl.get('reset_password.old_password_error')} banner /> : null}
        <Form onSubmit={this.handleSubmit}>
          <FormItem labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
            {getFieldDecorator('oldpassword', {
              rules: [
                {
                  required: true,
                  message: intl.get('reset_password.input_old_password')
                }
              ]
            })(
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">{intl.get('reset_password.old_password')}</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={'password'}
                  labelWidth={70}
                />
              </FormControl>
            )}
          </FormItem>
          <FormItem
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 24 }}
          >
            <Popover
              content={<CheckPasswordSafety value={getFieldValue('newpassword')} />}
              overlayStyle={{ width: 240 }}
              placement="right"
              visible={this.state.visible}
            >
              {getFieldDecorator('newpassword', {
                rules: [
                  {
                    validator: (rule, value, callback) => checkPassword(rule, value, callback)
                  }
                ]
              })(
                <FormControl variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">{intl.get('reset_password.new_password')}</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={'password'}
                    labelWidth={70}
                  />
                </FormControl>
              )}
            </Popover>
          </FormItem>
          <FormItem labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
            {getFieldDecorator('confirmpassword', {
              rules: [
                {
                  validator: (rule, value, callback) => checkPassword(rule, value, callback, form.getFieldValue('newpassword'))
                }
              ]
            })(
              <FormControl variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">{intl.get('reset_password.confirm_password')}</InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={'password'}
                  labelWidth={70}
                />
              </FormControl>
            )}
          </FormItem>
          <FormItem
            style={{textAlign:'right'}}
          >
            <Button
              size="large"
              loading={loading}
              className={styles.submit}
              type="primary"
              htmlType="submit"
            >
              Save
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}
