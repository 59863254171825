export default {
  guide: {
    // 升级弹窗文案
    CommonGuide: [
      {
        type: 1,
        title: 'Aggiorna piano',
        content:'Il tuo piano attuale è Basic, aggiorna al piano Avanzato se vuoi accedere a questa funzionalità, la mappatura avanzata e il servizio clienti 24 ore.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Aggiorna',
        otherBtn: 'Annulla'
      },
      {
        type: 2,
        title: 'Aggiorna piano',
        content:'Il tuo piano attuale è Avanzato, aggiorna al piano Pro se vuoi accedere a questa funzione e gestire più di 70,000+ prodotti.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Aggiorna',
        otherBtn: 'Annulla'
      },
      {
        type: 3,
        title: 'Personalizzazione',
        content:'Il tuo piano attuale è il più alto, se vuoi superare i limiti, possiamo offrirti dei servizi personalizzati.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Contattaci',
        otherBtn: 'Annulla'
      },
      {
        type: 4,
        title: 'Aggiorna piano',
        content:'Il tuo piano attuale è Standard, passa a quello Avanzato se vuoi accedere a questa funzione, alla mappatura avanzata e al servizio clienti 24 ore su 24.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Aggiorna',
        otherBtn: 'Annulla'
      },
      {
        type: 5,
        title: 'Personalizzazione',
        content:'Il tuo piano attuale è il più alto, se vuoi superare i limiti, possiamo offrirti dei servizi personalizzati.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Contattaci',
        otherBtn: 'Annulla'
      },
      {
        type: 6,
        title: 'Personalizzazione',
        content:'Il tuo piano attuale è il più alto, se vuoi superare i limiti, possiamo offrirti dei servizi personalizzati.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Contattaci',
        otherBtn: 'Annulla'
      },
      {
        type: 7,
        title: 'Aggiorna piano',
        content:'Il tuo piano attuale è Avanzato, aggiorna al piano Pro se vuoi accedere a questa funzione e gestire più di 70,000+ prodotti.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Aggiorna',
        otherBtn: 'Annulla'
      },
      {
        type: 8,
        title: 'Aggiorna piano',
        content:'Il tuo piano attuale è Standard, passa a quello Avanzato se vuoi accedere a questa funzione, alla mappatura avanzata e al servizio clienti 24 ore su 24.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Aggiorna',
        otherBtn: 'Annulla'
      },
      {
        type: 9,
        title: 'Personalizzazione',
        content:'Il tuo piano attuale è il più alto, se vuoi superare i limiti, possiamo offrirti dei servizi personalizzati.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Contattaci',
        otherBtn: 'Annulla'
      },
      {
        type: 10,
        title: 'Aggiorna piano',
        content:'Il tuo piano attuale è Personalizza basic, aggiorna al piano Avanzato se vuoi accedere a questa funzionalità, la mappatura avanzata e il servizio clienti 24 ore.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Aggiorna',
        otherBtn: 'Annulla'
      }
    ],
    // 开屏弹窗文案
    OBDialog: {
      bindAE: {
        top:
          'Siamo felici di sapere che ti sei unito/a a noi! Abbiamo migrato con successo tutti i tuoi dati, ordini, prodotti e configurazioni.',
        topTitle: 'Benvenuto/a a DSers!',
        centerTitle: 'Migrazione riuscita!',
        btn: 'MCAPITO'
      },
      noBindAE: {
        top:
          'Siamo felici di sapere che ti sei unito/a a noi! Abbiamo migrato con successo tutti i tuoi dati, ordini, prodotti e configurazioni.',
        centerTitle: 'Migrazione riuscita!',
        topTitle: 'Benvenuto/a a DSers!',
        bottom: [
          'Per iniziare a usare DSers, sei pregato di collegare il tuo account AliExpress.'
        ],
        btn: 'Collega AliExpress'
      }
    },
    //底部banner文案
    OBanberWords: [
      {
        // 绑定AE
        step: 1,
        title: 'Collega il tuo account AliExpress ',
        text:
          "Collegando il tuo account AliExpress a DSers potrai effettuare 100+ ordini in qualche secondo!Clicca <a target='_blank' href='https://help.dsers.com/link-your-shopify-store/'>qui</a> per maggiori informazioni.",
        btn: 'COLLEGA ALIEXPRESS'
      },
      {
        // 确认套餐
        step: 2,
        title: 'Mantenere le abitudini di Oberlo',
        textArr: [
          {
            type: 1,
            text:
              "Accedi a tutte le funzioni di Oberlo, e goditi qualche nuova funzione avanzata come la gestione multi-store. Clicca<a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>qui</a> per maggiori informazioni."
          },
          {
            type: 2,
            text:
              "Accedi a tutte le funzioni di Oberlo e godi di maggiori funzionalità come mappatura avanzata e servizio clienti 24 ore su 24. Clicca <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>qui</a> per maggiori dettagli."
          },
          {
            type: 3,
            text:
              "Accedi a tutte le funzioni di Oberlo, e godi di maggiori risorse come la gestione di più di 70,000 prodotti. Clicca <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>qui</a> per maggiori informazioni."
          }
        ],
        btn: 'Attiva il piano'
      },
      {
        // 安装插件
        step: 3,
        title: "Installa l'estensione di Chrome di DSers",
        text:
          "Importo prodotto con un solo clic da AliExpress a DSers per risparmiare tempo e trovare i nuovi migliori rivenditori! Clicca <a target='_blank' href='https://help.dsers.com/import-product-from-aliexpress-for-shopify/#Use DSers Chrome Extension'>qui</a> per maggiori informazioni.",
        btn: 'Scarica'
      }
    ],
    // 确认弹窗文案
    ComfirmPop: [
      {
        kind: 'AE',
        title: 'Collega il tuo account AliExpress ',
        content:
          "Collegando il tuo account AliExpress a DSers potrai effettuare 100+ ordini in qualche secondo!Clicca <a target='_blank' href='https://help.dsers.com/link-your-shopify-store/'>qui</a> per maggiori informazioni.",
        mainBtn: 'Collega AliExpress'
      },
      {
        kind: 'PLAN',
        title: 'Mantenere le abitudini di Oberlo',
        contentArr: [
          {
            type: 1,
            content:
              "Accedi a tutte le funzioni di Oberlo, e goditi qualche nuova funzione avanzata come la gestione multi-store. Clicca <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>qui</a> per maggiori informazioni."
          },
          {
            type: 2,
            content:
              "Accedi a tutte le funzioni di Oberlo e godi di maggiori funzionalità come mappatura avanzata e servizio clienti 24 ore su 24. Clicca <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>qui</a> per maggiori dettagli."
          },
          {
            type: 3,
            content:
              "Accedi a tutte le funzioni di Oberlo, e godi di maggiori risorse come la gestione di più di 70,000 prodotti. Clicca <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>qui</a> per maggiori informazioni."
          }
        ],
        errorBillStore:
          "Non puoi attivare il piano perché il tuo negozio di fatturazione non è disponibile. Cambia il negozio di fatturazione su DSers, nella pagina della <a target='_blank' href='https://www.dsers.com/app/select'>Gestione abbonamento</a>, oppure attiva il tuo negozio di fatturazione nell'amministrazione di ",
        errorBillStoreNotId: "Non puoi attivare il piano perché il tuo negozio di fatturazione non è disponibile. Cambia il negozio di fatturazione su DSers, nella pagina della <a target='_blank' href='https://www.dsers.com/app/select'>Gestione abbonamento</a>",
        errorBillName: 'Shopify',
        mainBtn: 'Attiva il piano'
      },
      {
        kind: 'CHROME',
        title: "Installa l'estensione di Chrome di DSers",
        content:
          "Importo prodotto con un solo clic da AliExpress a DSers per risparmiare tempo e trovare i nuovi migliori rivenditori! Clicca <a target='_blank' href='https://help.dsers.com/import-product-from-aliexpress-for-shopify/#Use DSers Chrome Extension'>qui</a> per maggiori informazioni.",
        mainBtn: 'Scarica'
      }
    ],
    // 刷新弹窗文案
    RefreshPopText: {
      title: 'Ricarica la pagina',
      content:
        'Ricarica la pagina così da poter controllare se hai installato il plugin con successo',
      btn: 'RICARICA'
    },
    // 提示用户必要信息文案
    OberloTopTipOneBindAE:"Your data is being corrected, you can migrate other Oberlo accounts once the data correction is completed.",
    OberloTopTipOneNoBindAE:'Please link your AliExpress account to DSers so that we can correct your orders status, you can continue to migrate other Oberlo accounts once the correction is completed.',
    OberloTopTipTwo:"Please note that DSers displays all the Shopify orders, while Oberlo displayed only the orders that contained products created through Oberlo.</br>" +
    "Ordini migrati di DSers degli ultimi due mesi. Se hai bisogno di sincronizzare più ordini, <a style='color:#3E5BFE' href='https://www.messenger.com/t/DSersOfficial' target='_blank'>contattaci.</a>"
  }
};
