import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Radio, Select, Icon, Tooltip } from 'antd';
import { connect } from 'dva';
import { Post } from 'utils/request';
import { routerRedux } from 'dva/router';
import intl from 'react-intl-universal';
import debounce from 'lodash/debounce'

import reactGaEvent from 'utils/GAEvent';
import { CommonDataCollect } from 'utils/utils';

import styles from './index.less';

let widthFlags = false;

const modalRoot = document.getElementById('root');

const PayInto = ({ dispatch, camType, plan, closeDetail, stores, user }) => {

  
  console.log('camType', camType, plan)

  // ConfigMap[camType][plan?.type]

  //分开写吧 方便扩展
  let planTypeOptions = [
    {
      id:2,
      label:'Advanced（$19.9）',
      value:2
    },
    {
      id:3,
      label: 'Pro（$49.9）',
      value:3
    }
  ]

  //检测注册时间满足（2,14】天的新用户
  let endTime = parseInt(+new Date() / 1000);
  let day = parseInt((endTime - user?.created_at) / (24*60*60));

  let AdvancedPlanExpred = true;
  let BOGOPlanExpred = true;
  let BundlePlanExpred = true;
  //判断套餐是不是过期了
  if([3,4].includes(plan?.trial_7_days_mapping_advanced_status)){
    AdvancedPlanExpred = false;
  }
  if([3,4].includes(plan?.trial_7_days_mapping_bogo_status)){
    BOGOPlanExpred = false;
  }
  if([3,4].includes(plan?.trial_7_days_mapping_bundle_status)){
    BundlePlanExpred = false;
  }

  if((day > 2 && day <= 14)  && AdvancedPlanExpred && camType == 'AdvancedMapping'){
    planTypeOptions.push({
      id:4,
      label:'🔥7-day trial（＄1.99）',
      value:4
    })
    widthFlags = true
  }

  if((day > 2 && day <= 14)  && BOGOPlanExpred && camType == 'BogoMapping'){
    planTypeOptions.push({
      id:4,
      label:'🔥7-day trial（＄1.99）',
      value:4
    })
    widthFlags = true
  }

  if((day > 2 && day <= 14)  && BundlePlanExpred && camType == 'BundleMapping'){
    planTypeOptions.push({
      id:4,
      label:'🔥7-day trial（＄1.99）',
      value:4
    })
    widthFlags = true
  }

  let StandPlanOptions = [
    {
      id:3,
      label: 'Pro（$49.9）',
      value:3
    }
  ]

  let CusPlanOptions = [
    {
      id:6,
      label: 'Enterprise（Contact us）',
      value:6
    }
  ]
  let DotPlanMap = {
       "StandardMapping":"Standard-M",
       "AdvancedMapping":"Advanced-M",
       "BogoMapping":"BOGO-M",
       "BundleMapping":"Bundle-M",
       "AdvancedPricingRule":"Advanced-PR",
       "AutoupdateInventory":"Autoupdate-product",
       "AutoupdatePrice":"Autoupdate-price",
       "Tracking":"Setting-Tracking",
       "StaffAccount":"Staff-account",
       "ManuallyUpdatePrice":"Manual update price",
       "SynchronizeShopifyInformation":"Synchronize Shopify order customization information",
       "MultiStore":"more store",
       "ProductsLimit":"Products-limit",
       "PayPal":"PayPal"

  }

  let ConfigMap = {
    'MultiStore':{
      1:{
        features:{
          2: {
            title: 'Multi store（up to 10 stores)'
          },
          3:{
            title: 'Multi store（up to 25 stores)'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      10:{
        features:{
          2: {
            title: 'Multi store（up to 10 stores)'
          },
          3:{
            title: 'Multi store（up to 25 stores)'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      4:{
        features:{
          2: {
            title: 'Multi store（up to 10 stores)'
          },
          3:{
            title: 'Multi store（up to 25 stores)'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      2:{
        features:{
          2: {
            title: 'Multi store（up to 25 stores)'
          },
          3:{
            title: 'Multi store（up to 25 stores)'
          }
        },
        defautOptionsValue:3,
        planOptions: StandPlanOptions
      },
      7:{
        features:{
          2: {
            title: 'Multi store（up to 25 stores)'
          },
          3:{
            title: 'Multi store（up to 25 stores)'
          }
        },
        defautOptionsValue:3,
        planOptions: StandPlanOptions
      },
      3:{
        features:{
          6: {
            title: 'Multi store（up to 50 stores)'
          }
        },
        defautOptionsValue:6,
        planOptions: CusPlanOptions
      },
      6:{
        features:{
          6: {
            title: 'Multi store（up to 50 stores)'
          }
        },
        defautOptionsValue:6,
        planOptions: CusPlanOptions
      },
      8:{
        features:{
          2: {
            title: 'Multi store（up to 10 stores)'
          },
          3:{
            title: 'Multi store（up to 25 stores)'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      11:{
        features:{
          6: {
            title: 'Multi store（up to 50 stores)'
          }
        },
        defautOptionsValue:6,
        planOptions: CusPlanOptions
      },
      12:{
        features:{
          2: {
            title: 'Multi store（up to 10 stores)'
          },
          3:{
            title: 'Multi store（up to 25 stores)'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      13:{
        features:{
          2: {
            title: 'Multi store（up to 10 stores)'
          },
          3:{
            title: 'Multi store（up to 25 stores)'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      14:{
        features:{
          2: {
            title: 'Multi store（up to 10 stores)'
          },
          3:{
            title: 'Multi store（up to 25 stores)'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      15:{
        features:{
          2: {
            title: 'Multi store（up to 10 stores)'
          },
          3:{
            title: 'Multi store（up to 25 stores)'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      16:{
        features:{
          2: {
            title: 'Multi store（up to 10 stores)'
          },
          3:{
            title: 'Multi store（up to 25 stores)'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      17:{
        features:{
          2: {
            title: 'Multi store（up to 10 stores)'
          },
          3:{
            title: 'Multi store（up to 25 stores)'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      18:{
        features:{
          2: {
            title: 'Multi store（up to 10 stores)'
          },
          3:{
            title: 'Multi store（up to 25 stores)'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      19:{
        features:{
          2: {
            title: 'Multi store（up to 10 stores)'
          },
          3:{
            title: 'Multi store（up to 25 stores)'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      20:{
        features:{
          2: {
            title: 'Multi store（up to 10 stores)'
          },
          3:{
            title: 'Multi store（up to 25 stores)'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
    },
    'SynchronizeShopifyInformation':{
      1:{
        features:{
          2: {
            title: 'Synchronize Shopify order customization information'
          },
          3:{
            title: 'Synchronize Shopify order customization information'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      10:{
        features:{
          2: {
            title: 'Synchronize Shopify order customization information'
          },
          3:{
            title: 'Synchronize Shopify order customization information'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      4:{
        features:{
          2: {
            title: 'Synchronize Shopify order customization information'
          },
          3:{
            title: 'Synchronize Shopify order customization information'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      2:{
        features:{
          2: {
            title: 'Synchronize Shopify order customization information'
          },
          3:{
            title: 'Synchronize Shopify order customization information'
          }
        },
        defautOptionsValue:3,
        planOptions: StandPlanOptions
      },
      7:{
        features:{
          2: {
            title: 'Synchronize Shopify order customization information'
          },
          3:{
            title: 'Synchronize Shopify order customization information'
          }
        },
        defautOptionsValue:3,
        planOptions: StandPlanOptions
      },
      3:{
        features:{
          6: {
            title: 'Synchronize Shopify order customization information'
          }
        },
        defautOptionsValue:6,
        planOptions: CusPlanOptions
      },
      6:{
        features:{
          6: {
            title: 'Synchronize Shopify order customization information'
          }
        },
        defautOptionsValue:6,
        planOptions: CusPlanOptions
      },
      8:{
        features:{
          2: {
            title: 'Synchronize Shopify order customization information'
          },
          3:{
            title: 'Synchronize Shopify order customization information'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      11:{
        features:{
          6: {
            title: 'Synchronize Shopify order customization information'
          }
        },
        defautOptionsValue:6,
        planOptions: CusPlanOptions
      },
      12:{
        features:{
          2: {
            title: 'Synchronize Shopify order customization information'
          },
          3:{
            title: 'Synchronize Shopify order customization information'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      13:{
        features:{
          2: {
            title: 'Synchronize Shopify order customization information'
          },
          3:{
            title: 'Synchronize Shopify order customization information)'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      14:{
        features:{
          2: {
            title: 'Synchronize Shopify order customization information'
          },
          3:{
            title: 'Synchronize Shopify order customization information'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      15:{
        features:{
          2: {
            title: 'Synchronize Shopify order customization information'
          },
          3:{
            title: 'Synchronize Shopify order customization information'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      16:{
        features:{
          2: {
            title: 'Synchronize Shopify order customization information'
          },
          3:{
            title: 'Synchronize Shopify order customization information'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      17:{
        features:{
          2: {
            title: 'Synchronize Shopify order customization information'
          },
          3:{
            title: 'Synchronize Shopify order customization information'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      18:{
        features:{
          2: {
            title: 'Synchronize Shopify order customization information'
          },
          3:{
            title: 'Synchronize Shopify order customization information'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      19:{
        features:{
          2: {
            title: 'Synchronize Shopify order customization information)'
          },
          3:{
            title: 'Synchronize Shopify order customization information'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      20:{
        features:{
          2: {
            title: 'Synchronize Shopify order customization information'
          },
          3:{
            title: 'Synchronize Shopify order customization information'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
    },
    'BogoMapping': {
      1:{
        features:{
          2: {
            title: 'Bogo Mapping'
          },
          3:{
            title: 'Bogo Mapping'
          },
          4:{
            title: 'Bogo Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      10:{
        features:{
          2: {
            title: 'Bogo Mapping'
          },
          3:{
            title: 'Bogo Mapping'
          },
          4:{
            title: 'Bogo Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      4:{
        features:{
          2: {
            title: 'Bogo Mapping'
          },
          3:{
            title: 'Bogo Mapping'
          },
          4:{
            title: 'Bogo Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      8:{
        features:{
          2: {
            title: 'Bogo Mapping'
          },
          3:{
            title: 'Bogo Mapping'
          },
          4:{
            title: 'Bogo Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      12:{
        features:{
          2: {
            title: 'Bogo Mapping'
          },
          3:{
            title: 'Bogo Mapping'
          },
          4:{
            title: 'Bogo Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      13:{
        features:{
          2: {
            title: 'Bogo Mapping'
          },
          3:{
            title: 'Bogo Mapping'
          },
          4:{
            title: 'Bogo Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      14:{
        features:{
          2: {
            title: 'Bogo Mapping'
          },
          3:{
            title: 'Bogo Mapping'
          },
          4:{
            title: 'Bogo Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      15:{
        features:{
          2: {
            title: 'Bogo Mapping'
          },
          3:{
            title: 'Bogo Mapping'
          },
          4:{
            title: 'Bogo Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      16:{
        features:{
          2: {
            title: 'Bogo Mapping'
          },
          3:{
            title: 'Bogo Mapping'
          },
          4:{
            title: 'Bogo Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      17:{
        features:{
          2: {
            title: 'Bogo Mapping'
          },
          3:{
            title: 'Bogo Mapping'
          },
          4:{
            title: 'Bogo Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
    },
    'BundleMapping':{
      1:{
        features:{
          2: {
            title: 'Bundle Mapping'
          },
          3:{
            title: 'Bundle Mapping'
          },
          4:{
            title: 'Bundle Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      10:{
        features:{
          2: {
            title: 'Bundle Mapping'
          },
          3:{
            title: 'Bundle Mapping'
          },
          4:{
            title: 'Bundle Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      4:{
        features:{
          2: {
            title: 'Bundle Mapping'
          },
          3:{
            title: 'Bundle Mapping'
          },
          4:{
            title: 'Bundle Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      8:{
        features:{
          2: {
            title: 'Bundle Mapping'
          },
          3:{
            title: 'Bundle Mapping'
          },
          4:{
            title: 'Bundle Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      12:{
        features:{
          2: {
            title: 'Bundle Mapping'
          },
          3:{
            title: 'Bundle Mapping'
          },
          4:{
            title: 'Bundle Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      13:{
        features:{
          2: {
            title: 'Bundle Mapping'
          },
          3:{
            title: 'Bundle Mapping'
          },
          4:{
            title: 'Bundle Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      14:{
        features:{
          2: {
            title: 'Bundle Mapping'
          },
          3:{
            title: 'Bundle Mapping'
          },
          4:{
            title: 'Bundle Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      15:{
        features:{
          2: {
            title: 'Bundle Mapping'
          },
          3:{
            title: 'Bundle Mapping'
          },
          4:{
            title: 'Bundle Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      16:{
        features:{
          2: {
            title: 'Bundle Mapping'
          },
          3:{
            title: 'Bundle Mapping'
          },
          4:{
            title: 'Bundle Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      17:{
        features:{
          2: {
            title: 'Bundle Mapping'
          },
          3:{
            title: 'Bundle Mapping'
          },
          4:{
            title: 'Bundle Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },

    },
    'AdvancedMapping':{
      1:{
        features:{
          2: {
            title: 'Advanced Mapping'
          },
          3:{
            title: 'Advanced Mapping'
          },
          4:{
            title: 'Advanced Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      10:{
        features:{
          2: {
            title: 'Advanced Mapping'
          },
          3:{
            title: 'Advanced Mapping'
          },
          4:{
            title: 'Advanced Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      4:{
        features:{
          2: {
            title: 'Advanced Mapping'
          },
          3:{
            title: 'Advanced Mapping'
          },
          4:{
            title: 'Advanced Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      8:{
        features:{
          2: {
            title: 'Advanced Mapping'
          },
          3:{
            title: 'Advanced Mapping'
          },
          4:{
            title: 'Advanced Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      12:{
        features:{
          2: {
            title: 'Advanced Mapping'
          },
          3:{
            title: 'Advanced Mapping'
          },
          4:{
            title: 'Advanced Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      13:{
        features:{
          2: {
            title: 'Advanced Mapping'
          },
          3:{
            title: 'Advanced Mapping'
          },
          4:{
            title: 'Advanced Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      14:{
        features:{
          2: {
            title: 'Advanced Mapping'
          },
          3:{
            title: 'Advanced Mapping'
          },
          4:{
            title: 'Advanced Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      15:{
        features:{
          2: {
            title: 'Advanced Mapping'
          },
          3:{
            title: 'Advanced Mapping'
          },
          4:{
            title: 'Advanced Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      16:{
        features:{
          2: {
            title: 'Advanced Mapping'
          },
          3:{
            title: 'Advanced Mapping'
          },
          4:{
            title: 'Advanced Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      17:{
        features:{
          2: {
            title: 'Advanced Mapping'
          },
          3:{
            title: 'Advanced Mapping'
          },
          4:{
            title: 'Advanced Mapping'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
    },
    'Tracking':{
      1:{
        features:{
          2: {
            title: 'Tracking'
          },
          3:{
            title: 'Tracking'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      10:{
        features:{
          2: {
            title: 'Tracking'
          },
          3:{
            title: 'Tracking'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      4:{
        features:{
          2: {
            title: 'Tracking'
          },
          3:{
            title: 'Tracking'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      8:{
        features:{
          2: {
            title: 'Tracking'
          },
          3:{
            title: 'Tracking'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      12:{
        features:{
          2: {
            title: 'Tracking'
          },
          3:{
            title: 'Tracking'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      13:{
        features:{
          2: {
            title: 'Tracking'
          },
          3:{
            title: 'Tracking'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      14:{
        features:{
          2: {
            title: 'Tracking'
          },
          3:{
            title: 'Tracking'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      15:{
        features:{
          2: {
            title: 'Tracking'
          },
          3:{
            title: 'Tracking'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      16:{
        features:{
          2: {
            title: 'Tracking'
          },
          3:{
            title: 'Tracking'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      17:{
        features:{
          2: {
            title: 'Tracking'
          },
          3:{
            title: 'Tracking'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      18:{
        features:{
          2: {
            title: 'Tracking'
          },
          3:{
            title: 'Tracking'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      19:{
        features:{
          2: {
            title: 'Tracking'
          },
          3:{
            title: 'Tracking'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      20:{
        features:{
          2: {
            title: 'Tracking'
          },
          3:{
            title: 'Tracking'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
    },
    'StaffAccount':{
      1:{
        features:{
          2: {
            title: 'Staff account（up to 5）'
          },
          3:{
            title: 'Staff account（no limitation）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      10:{
        features:{
          2: {
            title: 'Staff account（up to 5）'
          },
          3:{
            title: 'Staff account（no limitation）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      4:{
        features:{
          2: {
            title: 'Staff account（up to 5）'
          },
          3:{
            title: 'Staff account（no limitation）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      2:{
        features:{
          2: {
            title: 'Staff account（no limitation）'
          },
          3:{
            title: 'Staff account（no limitation）'
          }
        },
        defautOptionsValue:3,
        planOptions: StandPlanOptions
      },
      7:{
        features:{
          2: {
            title: 'Staff account（no limitation）'
          },
          3:{
            title: 'Staff account（no limitation）'
          }
        },
        defautOptionsValue:3,
        planOptions: StandPlanOptions
      },
      8:{
        features:{
          2: {
            title: 'Staff account（up to 5）'
          },
          3:{
            title: 'Staff account（no limitation）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      12:{
        features:{
          2: {
            title: 'Staff account（up to 5）'
          },
          3:{
            title: 'Staff account（no limitation）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      13:{
        features:{
          2: {
            title: 'Staff account（up to 5）'
          },
          3:{
            title: 'Staff account（no limitation）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      14:{
        features:{
          2: {
            title: 'Staff account（up to 5）'
          },
          3:{
            title: 'Staff account（no limitation）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      15:{
        features:{
          2: {
            title: 'Staff account（up to 5）'
          },
          3:{
            title: 'Staff account（no limitation）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      16:{
        features:{
          2: {
            title: 'Staff account（up to 5）'
          },
          3:{
            title: 'Staff account（no limitation）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      17:{
        features:{
          2: {
            title: 'Staff account（up to 5）'
          },
          3:{
            title: 'Staff account（no limitation）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      18:{
        features:{
          2: {
            title: 'Staff account（up to 5）'
          },
          3:{
            title: 'Staff account（no limitation）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      19:{
        features:{
          2: {
            title: 'Staff account（up to 5）'
          },
          3:{
            title: 'Staff account（no limitation）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      20:{
        features:{
          2: {
            title: 'Staff account（up to 5）'
          },
          3:{
            title: 'Staff account（no limitation）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
    },
    'StandardMapping':{
      1:{
        features:{
          2: {
            title: 'Standard Mapping（up to 5 suppliers）'
          },
          3:{
            title: 'Standard Mapping（up to 5 suppliers）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      10:{
        features:{
          2: {
            title: 'Standard Mapping（up to 5 suppliers）'
          },
          3:{
            title: 'Standard Mapping（up to 5 suppliers）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      4:{
        features:{
          2: {
            title: 'Standard Mapping（up to 5 suppliers）'
          },
          3:{
            title: 'Standard Mapping（up to 5 suppliers）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      8:{
        features:{
          2: {
            title: 'Standard Mapping（up to 5 suppliers）'
          },
          3:{
            title: 'Standard Mapping（up to 5 suppliers）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      12:{
        features:{
          2: {
            title: 'Standard Mapping（up to 5 suppliers）'
          },
          3:{
            title: 'Standard Mapping（up to 5 suppliers）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      13:{
        features:{
          2: {
            title: 'Standard Mapping（up to 5 suppliers）'
          },
          3:{
            title: 'Standard Mapping（up to 5 suppliers）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      14:{
        features:{
          2: {
            title: 'Standard Mapping（up to 5 suppliers）'
          },
          3:{
            title: 'Standard Mapping（up to 5 suppliers）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      15:{
        features:{
          2: {
            title: 'Standard Mapping（up to 5 suppliers）'
          },
          3:{
            title: 'Standard Mapping（up to 5 suppliers）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      16:{
        features:{
          2: {
            title: 'Standard Mapping（up to 5 suppliers）'
          },
          3:{
            title: 'Standard Mapping（up to 5 suppliers）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      17:{
        features:{
          2: {
            title: 'Standard Mapping（up to 5 suppliers）'
          },
          3:{
            title: 'Standard Mapping（up to 5 suppliers）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      18:{
        features:{
          2: {
            title: 'Standard Mapping（up to 5 suppliers）'
          },
          3:{
            title: 'Standard Mapping（up to 5 suppliers）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      19:{
        features:{
          2: {
            title: 'Standard Mapping（up to 5 suppliers）'
          },
          3:{
            title: 'Standard Mapping（up to 5 suppliers）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      20:{
        features:{
          2: {
            title: 'Standard Mapping（up to 5 suppliers）'
          },
          3:{
            title: 'Standard Mapping（up to 5 suppliers）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
    },
    'AdvancedPricingRule':{
      1:{
        features:{
          2: {
            title: 'Advanced Pricing Rule'
          },
          3:{
            title: 'Advanced Pricing Rule'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      10:{
        features:{
          2: {
            title: 'Advanced Pricing Rule'
          },
          3:{
            title: 'Advanced Pricing Rule'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      4:{
        features:{
          2: {
            title: 'Advanced Pricing Rule'
          },
          3:{
            title: 'Advanced Pricing Rule'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      8:{
        features:{
          2: {
            title: 'Advanced Pricing Rule'
          },
          3:{
            title: 'Advanced Pricing Rule'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      12:{
        features:{
          2: {
            title: 'Advanced Pricing Rule'
          },
          3:{
            title: 'Advanced Pricing Rule'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      13:{
        features:{
          2: {
            title: 'Advanced Pricing Rule'
          },
          3:{
            title: 'Advanced Pricing Rule'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      14:{
        features:{
          2: {
            title: 'Advanced Pricing Rule'
          },
          3:{
            title: 'Advanced Pricing Rule'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      15:{
        features:{
          2: {
            title: 'Advanced Pricing Rule'
          },
          3:{
            title: 'Advanced Pricing Rule'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      16:{
        features:{
          2: {
            title: 'Advanced Pricing Rule'
          },
          3:{
            title: 'Advanced Pricing Rule'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      17:{
        features:{
          2: {
            title: 'Advanced Pricing Rule'
          },
          3:{
            title: 'Advanced Pricing Rule'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      18:{
        features:{
          2: {
            title: 'Advanced Pricing Rule'
          },
          3:{
            title: 'Advanced Pricing Rule'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      19:{
        features:{
          2: {
            title: 'Advanced Pricing Rule'
          },
          3:{
            title: 'Advanced Pricing Rule'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      20:{
        features:{
          2: {
            title: 'Advanced Pricing Rule'
          },
          3:{
            title: 'Advanced Pricing Rule'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
    },
    'ProductsLimit':{
      1:{
        features:{
          limit:{
            2: {
              1:{
                title:'Product limit（3000→20000）'
              },
              10:{
                title:'Product limit（3000→20000）'
              },
              4:{
                title:'Product limit（500→20000）'
              }
            },
            3:{
              1:{
                title:'Product limit（3000→75000）'
              },
              10:{
                title:'Product limit（3000→75000）'
              },
              4:{
                title:'Product limit（500→75000）'
              }
            },
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      10:{
        features:{
          limit:{
            2: {
              1:{
                title:'Product limit（3000→20000）'
              },
              10:{
                title:'Product limit（3000→20000）'
              },
              4:{
                title:'Product limit（500→20000）'
              }
            },
            3:{
              1:{
                title:'Product limit（3000→75000）'
              },
              10:{
                title:'Product limit（3000→75000）'
              },
              4:{
                title:'Product limit（500→75000）'
              }
            },
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      4:{
        features:{
          limit:{
            2: {
              1:{
                title:'Product limit（3000→20000）'
              },
              10:{
                title:'Product limit（3000→20000）'
              },
              4:{
                title:'Product limit（7500→20000）'
              }
            },
            3:{
              1:{
                title:'Product limit（3000→75000）'
              },
              10:{
                title:'Product limit（3000→75000）'
              },
              4:{
                title:'Product limit（7500→75000）'
              }
            },
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      2:{
        features:{
          2: {
            title: 'Product limit（20000→75000）'
          },
          3:{
            title: 'Product limit（20000→75000）'
          }
        },
        defautOptionsValue:3,
        planOptions: StandPlanOptions
      },
      7:{
        features:{
          2: {
            title: 'Product limit（20000→75000）'
          },
          3:{
            title: 'Product limit（20000→75000）'
          }
        },
        defautOptionsValue:3,
        planOptions: StandPlanOptions
      },
      3:{
        features:{
          6: {
            title: 'Product limit（75000→100000）'
          }
        },
        defautOptionsValue:6,
        planOptions: CusPlanOptions
      },
      6:{
        features:{
          6: {
            title: 'Multi store（up to 50 stores)'
          }
        },
        defautOptionsValue:6,
        planOptions: CusPlanOptions
      },
      8:{
        features:{
          limit:{
            2: {
              1:{
                title:'Product limit（3000→20000）'
              },
              10:{
                title:'Product limit（3000→20000）'
              },
              8:{
                title:'Product limit（7500→20000）'
              },
              4:{
                title:'Product limit（7500→20000）'
              }
            },
            3:{
              1:{
                title:'Product limit（3000→75000）'
              },
              10:{
                title:'Product limit（3000→75000）'
              },
              8:{
                title:'Product limit（7500→20000）'
              },
              4:{
                title:'Product limit（7500→75000）'
              }
            },
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      12:{
        features:{
          limit:{
            2: {
              1:{
                title:'Product limit（3000→20000）'
              },
              10:{
                title:'Product limit（3000→20000）'
              },
              12:{
                title:'Product limit（3000→20000）'
              },
              4:{
                title:'Product limit（500→20000）'
              }
            },
            3:{
              1:{
                title:'Product limit（3000→75000）'
              },
              12:{
                title:'Product limit（3000→20000）'
              },
              10:{
                title:'Product limit（3000→75000）'
              },
              4:{
                title:'Product limit（500→75000）'
              }
            },
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      13:{
        features:{
          limit:{
            2: {
              1:{
                title:'Product limit（3000→20000）'
              },
              10:{
                title:'Product limit（3000→20000）'
              },
              13:{
                title:'Product limit（3500→20000）'
              },
              4:{
                title:'Product limit（500→20000）'
              }
            },
            3:{
              1:{
                title:'Product limit（3000→75000）'
              },
              10:{
                title:'Product limit（3000→75000）'
              },
              13:{
                title:'Product limit（3500→20000）'
              },
              4:{
                title:'Product limit（500→75000）'
              }
            },
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      14:{
        features:{
          limit:{
            2: {
              1:{
                title:'Product limit（3000→20000）'
              },
              10:{
                title:'Product limit（3000→20000）'
              },
              14:{
                title:'Product limit（4000→20000）'
              },
              4:{
                title:'Product limit（500→20000）'
              }
            },
            3:{
              1:{
                title:'Product limit（3000→75000）'
              },
              10:{
                title:'Product limit（3000→75000）'
              },
              14:{
                title:'Product limit（4000→20000）'
              },
              4:{
                title:'Product limit（500→75000）'
              }
            },
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      15:{
        features:{
          limit:{
            2: {
              1:{
                title:'Product limit（3000→20000）'
              },
              10:{
                title:'Product limit（3000→20000）'
              },
              15:{
                title:'Product limit（3000→20000）'
              },
              4:{
                title:'Product limit（500→20000）'
              }
            },
            3:{
              1:{
                title:'Product limit（3000→75000）'
              },
              10:{
                title:'Product limit（3000→75000）'
              },
              15:{
                title:'Product limit（3000→20000）'
              },
              4:{
                title:'Product limit（500→75000）'
              }
            },
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      16:{
        features:{
          limit:{
            2: {
              1:{
                title:'Product limit（3000→20000）'
              },
              10:{
                title:'Product limit（3000→20000）'
              },
              16:{
                title:'Product limit（3500→20000）'
              },
              4:{
                title:'Product limit（500→20000）'
              }
            },
            3:{
              1:{
                title:'Product limit（3000→75000）'
              },
              10:{
                title:'Product limit（3000→75000）'
              },
              16:{
                title:'Product limit（3500→20000）'
              },
              4:{
                title:'Product limit（500→75000）'
              }
            },
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      17:{
        features:{
          limit:{
            2: {
              1:{
                title:'Product limit（3000→20000）'
              },
              10:{
                title:'Product limit（3000→20000）'
              },
              17:{
                title:'Product limit（4000→20000）'
              },
              4:{
                title:'Product limit（500→20000）'
              }
            },
            3:{
              1:{
                title:'Product limit（3000→75000）'
              },
              10:{
                title:'Product limit（3000→75000）'
              },
              17:{
                title:'Product limit（4000→20000）'
              },
              4:{
                title:'Product limit（500→75000）'
              }
            },
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      18:{
        features:{
          limit:{
            2: {
              1:{
                title:'Product limit（3000→20000）'
              },
              10:{
                title:'Product limit（3000→20000）'
              },
              17:{
                title:'Product limit（4000→20000）'
              },
              4:{
                title:'Product limit（500→20000）'
              }
            },
            3:{
              1:{
                title:'Product limit（3000→75000）'
              },
              10:{
                title:'Product limit（3000→75000）'
              },
              17:{
                title:'Product limit（4000→20000）'
              },
              4:{
                title:'Product limit（500→75000）'
              }
            },
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      19:{
        features:{
          limit:{
            2: {
              1:{
                title:'Product limit（3000→20000）'
              },
              10:{
                title:'Product limit（3000→20000）'
              },
              17:{
                title:'Product limit（4000→20000）'
              },
              4:{
                title:'Product limit（500→20000）'
              }
            },
            3:{
              1:{
                title:'Product limit（3000→75000）'
              },
              10:{
                title:'Product limit（3000→75000）'
              },
              17:{
                title:'Product limit（4000→20000）'
              },
              4:{
                title:'Product limit（500→75000）'
              }
            },
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      20:{
        features:{
          limit:{
            2: {
              1:{
                title:'Product limit（3000→20000）'
              },
              10:{
                title:'Product limit（3000→20000）'
              },
              17:{
                title:'Product limit（4000→20000）'
              },
              4:{
                title:'Product limit（500→20000）'
              }
            },
            3:{
              1:{
                title:'Product limit（3000→75000）'
              },
              10:{
                title:'Product limit（3000→75000）'
              },
              17:{
                title:'Product limit（4000→20000）'
              },
              4:{
                title:'Product limit（500→75000）'
              }
            },
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
    },
    'AutoupdatePrice':{
      1:{
        features:{
          2: {
            title: 'Automatic Price Update'
          },
          3:{
            title: 'Automatic Price Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      10:{
        features:{
          2: {
            title: 'Automatic Price Update'
          },
          3:{
            title: 'Automatic Price Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      4:{
        features:{
          2: {
            title: 'Automatic Price Update'
          },
          3:{
            title: 'Automatic Price Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      8:{
        features:{
          2: {
            title: 'Automatic Price Update'
          },
          3:{
            title: 'Automatic Price Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      12:{
        features:{
          2: {
            title: 'Automatic Price Update'
          },
          3:{
            title: 'Automatic Price Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      13:{
        features:{
          2: {
            title: 'Automatic Price Update'
          },
          3:{
            title: 'Automatic Price Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      14:{
        features:{
          2: {
            title: 'Automatic Price Update'
          },
          3:{
            title: 'Automatic Price Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      15:{
        features:{
          2: {
            title: 'Automatic Price Update'
          },
          3:{
            title: 'Automatic Price Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      16:{
        features:{
          2: {
            title: 'Automatic Price Update'
          },
          3:{
            title: 'Automatic Price Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      17:{
        features:{
          2: {
            title: 'Automatic Price Update'
          },
          3:{
            title: 'Automatic Price Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      18:{
        features:{
          2: {
            title: 'Automatic Price Update'
          },
          3:{
            title: 'Automatic Price Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      19:{
        features:{
          2: {
            title: 'Automatic Price Update'
          },
          3:{
            title: 'Automatic Price Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      20:{
        features:{
          2: {
            title: 'Automatic Price Update'
          },
          3:{
            title: 'Automatic Price Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
    },
    'AutoupdateInventory': {
      1:{
        features:{
          2: {
            title: 'Automatic Inventory Update'
          },
          3:{
            title: 'Automatic Inventory Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      10:{
        features:{
          2: {
            title: 'Automatic Inventory Update'
          },
          3:{
            title: 'Automatic Inventory Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      4:{
        features:{
          2: {
            title: 'Automatic Inventory Update'
          },
          3:{
            title: 'Automatic Inventory Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      8:{
        features:{
          2: {
            title: 'Automatic Inventory Update'
          },
          3:{
            title: 'Automatic Inventory Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      12:{
        features:{
          2: {
            title: 'Automatic Inventory Update'
          },
          3:{
            title: 'Automatic Inventory Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      13:{
        features:{
          2: {
            title: 'Automatic Inventory Update'
          },
          3:{
            title: 'Automatic Inventory Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      14:{
        features:{
          2: {
            title: 'Automatic Inventory Update'
          },
          3:{
            title: 'Automatic Inventory Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      15:{
        features:{
          2: {
            title: 'Automatic Inventory Update'
          },
          3:{
            title: 'Automatic Inventory Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      16:{
        features:{
          2: {
            title: 'Automatic Inventory Update'
          },
          3:{
            title: 'Automatic Inventory Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      17:{
        features:{
          2: {
            title: 'Automatic Inventory Update'
          },
          3:{
            title: 'Automatic Inventory Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      18:{
        features:{
          2: {
            title: 'Automatic Inventory Update'
          },
          3:{
            title: 'Automatic Inventory Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      19:{
        features:{
          2: {
            title: 'Automatic Inventory Update'
          },
          3:{
            title: 'Automatic Inventory Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      20:{
        features:{
          2: {
            title: 'Automatic Inventory Update'
          },
          3:{
            title: 'Automatic Inventory Update'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
    },
    'ManuallyUpdatePrice': {
      1:{
        features:{
          2: {
            title: 'Manually update price（4 times/30 days）'
          },
          3:{
            title: 'Manually update price（10 times/ 30 days）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      10:{
        features:{
          2: {
            title: 'Manually update price（4 times/30 days）'
          },
          3:{
            title: 'Manually update price（10 times/ 30 days）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      4:{
        features:{
          2: {
            title: 'Manually update price（4 times/30 days）'
          },
          3:{
            title: 'Manually update price（10 times/ 30 days）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      2:{
        features:{
          2: {
            title: 'Manually update price（10 times/ 30 days）'
          },
          3:{
            title: 'Manually update price（10 times/ 30 days）'
          }
        },
        defautOptionsValue:3,
        planOptions: StandPlanOptions
      },
      7:{
        features:{
          2: {
            title: 'Manually update price（10 times/ 30 days）'
          },
          3:{
            title: 'Manually update price（10 times/ 30 days）'
          }
        },
        defautOptionsValue:3,
        planOptions: StandPlanOptions
      },
      8:{
        features:{
          2: {
            title: 'Manually update price（4 times/30 days）'
          },
          3:{
            title: 'Manually update price（10 times/ 30 days）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      12:{
        features:{
          2: {
            title: 'Manually update price（4 times/30 days）'
          },
          3:{
            title: 'Manually update price（10 times/ 30 days）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      13:{
        features:{
          2: {
            title: 'Manually update price（4 times/30 days）'
          },
          3:{
            title: 'Manually update price（10 times/ 30 days）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      14:{
        features:{
          2: {
            title: 'Manually update price（4 times/30 days）'
          },
          3:{
            title: 'Manually update price（10 times/ 30 days）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      15:{
        features:{
          2: {
            title: 'Manually update price（4 times/30 days）'
          },
          3:{
            title: 'Manually update price（10 times/ 30 days）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      16:{
        features:{
          2: {
            title: 'Manually update price（4 times/30 days）'
          },
          3:{
            title: 'Manually update price（10 times/ 30 days）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      17:{
        features:{
          2: {
            title: 'Manually update price（4 times/30 days）'
          },
          3:{
            title: 'Manually update price（10 times/ 30 days）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      18:{
        features:{
          2: {
            title: 'Manually update price（4 times/30 days）'
          },
          3:{
            title: 'Manually update price（10 times/ 30 days）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      19:{
        features:{
          2: {
            title: 'Manually update price（4 times/30 days）'
          },
          3:{
            title: 'Manually update price（10 times/ 30 days）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      20:{
        features:{
          2: {
            title: 'Manually update price（4 times/30 days）'
          },
          3:{
            title: 'Manually update price（10 times/ 30 days）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
    },
    'PayPal':{
      1:{
        features:{
          2: {
            title: 'PayPal（up to 2）'
          },
          3:{
            title: 'PayPal（up to 5）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      10:{
        features:{
          2: {
            title: 'PayPal（up to 2）'
          },
          3:{
            title: 'PayPal（up to 5）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      4:{
        features:{
          2: {
            title: 'PayPal（up to 2）'
          },
          3:{
            title: 'PayPal（up to 5）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      2:{
        features:{
          2: {
            title: 'PayPal（up to 5）'
          },
          3:{
            title: 'PayPal（up to 5）'
          }
        },
        defautOptionsValue:3,
        planOptions: StandPlanOptions
      },
      7:{
        features:{
          2: {
            title: 'PayPal（up to 5）'
          },
          3:{
            title: 'PayPal（up to 5）'
          }
        },
        defautOptionsValue:3,
        planOptions: StandPlanOptions
      },
      6:{
        features:{
          6: {
            title: 'PayPal（up to 10）'
          }
        },
        defautOptionsValue:6,
        planOptions: CusPlanOptions
      },
      8:{
        features:{
          2: {
            title: 'PayPal（up to 2）'
          },
          3:{
            title: 'PayPal（up to 5）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      11:{
        features:{
          6: {
            title: 'PayPal（up to 10）'
          }
        },
        defautOptionsValue:6,
        planOptions: CusPlanOptions
      },
      12:{
        features:{
          2: {
            title: 'PayPal（up to 2）'
          },
          3:{
            title: 'PayPal（up to 5）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      13:{
        features:{
          2: {
            title: 'PayPal（up to 2）'
          },
          3:{
            title: 'PayPal（up to 5）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      14:{
        features:{
          2: {
            title: 'PayPal（up to 2）'
          },
          3:{
            title: 'PayPal（up to 5）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      15:{
        features:{
          2: {
            title: 'PayPal（up to 2）'
          },
          3:{
            title: 'PayPal（up to 5）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      16:{
        features:{
          2: {
            title: 'PayPal（up to 2）'
          },
          3:{
            title: 'PayPal（up to 5）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      17:{
        features:{
          2: {
            title: 'PayPal（up to 2）'
          },
          3:{
            title: 'PayPal（up to 5）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      18:{
        features:{
          2: {
            title: 'PayPal（up to 2）'
          },
          3:{
            title: 'PayPal（up to 5）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      19:{
        features:{
          2: {
            title: 'PayPal（up to 2）'
          },
          3:{
            title: 'PayPal（up to 5）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      20:{
        features:{
          2: {
            title: 'PayPal（up to 2）'
          },
          3:{
            title: 'PayPal（up to 5）'
          }
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
    },
    'SendProductCostToShopify':{
      1:{
        features:{
          2: {
            title: 'Send product and shipping cost to Shopify'
          },
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      4:{
        features:{
          2: {
            title: 'Send product and shipping cost to Shopify'
          },
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      8:{
        features:{
          2: {
            title: 'Send product and shipping cost to Shopify'
          },
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      10:{
        features:{
          2: {
            title: 'Send product and shipping cost to Shopify'
          },
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      12:{
        features:{
          2: {
            title: 'Send product and shipping cost to Shopify'
          },
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      13:{
        features:{
          2: {
            title: 'Send product and shipping cost to Shopify'
          },
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      14:{
        features:{
          2: {
            title: 'Send product and shipping cost to Shopify'
          },
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      15:{
        features:{
          2: {
            title: 'Send product and shipping cost to Shopify'
          },
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      16:{
        features:{
          2: {
            title: 'Send product and shipping cost to Shopify'
          },
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      17:{
        features:{
          2: {
            title: 'Send product and shipping cost to Shopify'
          },
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      18:{
        features:{
          2: {
            title: 'Send product and shipping cost to Shopify'
          },
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      19:{
        features:{
          2: {
            title: 'Send product and shipping cost to Shopify'
          },
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
      20:{
        features:{
          2: {
            title: 'Send product and shipping cost to Shopify'
          },
        },
        defautOptionsValue:2,
        planOptions: planTypeOptions
      },
    },
  }

  //初始化state
  const [data, setData] = useState(ConfigMap[camType][plan?.type])
  const [defaultValue, setDefaultValue] = useState(ConfigMap[camType][plan?.type]?.defautOptionsValue)
  
  let storeValue;
  let storeList = stores?.filter(item => item?.status == 1);

  if(plan?.default_store_id){
    if(!storeList.length){
      storeValue = ''
    }else{
      //如果有default_store_id  去检测他的ID能不能用
      let newList = stores.find(item => item.id == plan?.default_store_id)
      if(newList?.status != 1){
        storeValue = storeList[0]?.id
      }else{
        storeValue = plan?.default_store_id
      }
    }
  }else{
    if(stores.length > 0){
      storeValue = stores?.filter(item => item?.status == 1)[0]?.id
    }
  }

  console.log('camType', storeValue, defaultValue)


  const [selectedStoreId, setSelectedStoreId] = useState(storeValue)
  const [loading, setLoading] = useState(false)

  const closeDetails = () =>  {
    closeDetail()
  }

  const radioChangeHandle = (e) => {
    setDefaultValue(e.target.value)
  }

  const selectStoreHandle = (e) => {
    setSelectedStoreId(e)
    handleActivateStore(e)
  }

  const handleActivateStore = (storeValue, flag = false) => {
    dispatch({
      type: 'guidance/modificationShop',
      payload: {
        data: {
          store_id: storeValue
        },
        callback: d => {
          if (d.code === 2000 || d.code === 2010) {
            dispatch({
              type: 'user/getProject',
              payload:{
                callback: d => {
                  if(flag){
                    changePlan()
                  }
                }
              }
            });
          } 
        }
      }
    });
  }

  const changePlan = async () => {
    let typeData = {
      plan_type:defaultValue,
      plan_period:1
    }

    if(defaultValue === 4){
      if(camType == 'AdvancedMapping'){
        typeData = {
          plan_type:18,
          plan_period:2
        }
      }
      if(camType == 'BogoMapping'){
        typeData = {
          plan_type:19,
          plan_period:2
        }
      }
      if(camType == 'BundleMapping'){
        typeData = {
          plan_type:20,
          plan_period:2
        }
      }
    }

    const response = await Post('/charge/change_plan',{
      data:typeData
    })
    if(response.code === 2000){
      setLoading(false)
      closeDetail()
      window.location.href = response.data.confirm_url;
    }else{
      setLoading(false)
    }
  }

  const handleCtaClick = debounce((type) => {
    collectRecordHere(type)
    if(plan?.type == 6){
      window.open('https://www.messenger.com/t/DSersOfficial')
      return
    }
    setLoading(true)
    if([1,10].includes(plan?.type)){
      //先去选店铺
      handleActivateStore(selectedStoreId, true)
    }else{
      changePlan()
    }
  }, 500)

 const collectRecordHere = (type) =>{
      if(!DotPlanMap[camType]){
         return
      }
      CommonDataCollect({
        event_type:'overlimit_pop',
        action: DotPlanMap[camType],
        custom_data:[{name:'action_type',value:type}]
       })
 }
  const getTitle = () => {
    if(data?.features.hasOwnProperty('limit')){
      return <div className={styles.features}>
          <span className={styles.span1}>Feature</span> 
          <span className={styles.neirong}>{data?.features['limit'][defaultValue][plan?.type]?.title}</span>
      </div>
    }
    return <div className={styles.features}>
      <span className={styles.span1}>Feature</span> 
      <span className={styles.neirong}>{data?.features[defaultValue]?.title}</span>
    </div>
  }

  const getDom = () => {

    if(!selectedStoreId){
      return <span>No Shopify stores are available. Please try again after binding a store.</span>
    }
    
    if(plan?.type == 6){
      return <span>Your current plan is <b>Enterprise</b> , please contact customer service for a higher plan</span>
    }

    if(plan?.type == 11){
      return <span>Your current plan is <b>CustomEnterprise</b> , please contact customer service for a higher plan</span>
    }

    if(plan?.type == 5){
      return <span>Your current plan is <b>Business</b> , please contact customer service for a higher plan</span>
    }
    
    if(plan?.type !== 6 && plan?.type !== 5){
      return <span>Please UPGRADE to enjoy the paid feature and extend the resource limitation.</span>
    }
  }

  const getContent = () => {
    if(!selectedStoreId){
      return null
    }
    return <>
      {
        plan?.type == 6 || plan?.type == 5 || plan?.type == 11   ? null : 
        <div className={styles.content}>
          {getTitle()}
          <div className={styles.features}>
            <span className={styles.span1}>
              Plan
            </span>
            <span style={widthFlags ? {marginLeft:30} : null}>
              <Radio.Group defaultValue={defaultValue} onChange={radioChangeHandle}>
              {
                data?.planOptions.map(radioItem => {
                  return <>
                    <Radio key={radioItem.value} value={radioItem.value}>
                      {radioItem.label}
                      {radioItem.value == 4 ? <Tooltip title={
                        <span>This is a one-time paid trial that is only valid for 7 days and will automatically terminate upon expiration.</span>
                      }
                    >
                      <Icon
                        type="info-circle"
                        theme="outlined"
                        style={{
                          transform: 'scale(.9)',
                          fontSize:'15px'
                        }}
                      />
                    </Tooltip> : null}
                    </Radio>
                  </>
                })
              }
            </Radio.Group>
            </span>
          </div>
          <div className={styles.features}>
            <span className={styles.span1}>
              Billing store
            </span>
            <span className={styles.select}>
              <Select
                suffixIcon={<img  src={require('../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
                placeholder={intl.get(
                  'setting.other.select_store_placeholder'
                )}
                value={selectedStoreId} 
                onChange={selectStoreHandle} 
                getPopupContainer={triggerNode => triggerNode.parentNode}
                >
                {
                  stores?.map(items => {
                    return <Option key={items.id} value={items.id}>{`${items.shop_name}.myshopify.com`}</Option>
                  })
                }
              </Select>
            </span>
          </div>
        </div>
      }
      <div className={styles.footer}>
        {/* {
          plan?.type == 6 ? null : <div className={styles.ftl}>For more features in the plan, click 
          <a target="_blank" href="https://www.dsers.com/pricing/"> here</a>.</div>
        } */}
        <div className={styles.ftl}>
          {
            plan?.type == 6 || plan?.type == 5 || plan?.type == 11 ? null : 
          <>
            For more features in the plan, click 
            <a target="_blank" href="https://www.dsers.com/app/pricing?select=1" onClick={()=>{collectRecordHere("headline_click")}}> here</a>.
          </>
          }
        </div>
        <div className={styles.frl}>
          <Button type="primary"  data="dx" loading={loading} onClick={()=>{handleCtaClick("upgrade_click")}}>
            {plan?.type == 6 || plan?.type == 5 || plan?.type == 11 ? 'Contact us' : 
              (defaultValue == 4 ? 'ACTIVATE NOW' : 'Upgrade now')}
          </Button>
        </div>
      </div>
    </>
  }

  return ReactDOM.createPortal(
    <div className={styles.mask}>
      <div className={styles.modal}>
        <div className={styles.header}>
          <div className={styles.title}>Upgrade plan</div>
          <div className={styles.close} onClick={closeDetails}>
            <img src="https://img.dsers.com/icon/close2.png"></img>
          </div>
        </div>
        <div className={styles.desc}>
          {getDom()}
        </div>
        {getContent()}
      </div>
    </div>,
    modalRoot
  );
}

const mapStateToProps = ({ user, login }) => {
  return {
    plan: user?.plan,
    user: user?.newUserInfo,
    stores: login?.userInfo?.stores
  };
}


const CAM002 = connect(mapStateToProps)(PayInto);

export {
  CAM002
}