import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Modal, Radio } from 'antd';
import { connect } from 'dva';
import { Post } from 'utils/request';
import { routerRedux } from 'dva/router';
import debounce from 'lodash/debounce'

import styles from './index.less';

import { CommonDataCollect } from 'utils/utils';

let MockData = {
  "cam_id":1,
  "round":1,
  "id": 20, 
  "ads_id": "d", 
  "title": "d", 
  "ads_group": 3, 
  "logo_img": "https://img.dsers.com/shopify/ad/headPortrait.png", 
  "ads_title": "Merchant Growth Team", 
  "ads_subtitle": "DSers empower your dropshipping journey", 
  "primary_text": `
    <p>🔥 Make a niche store for your hot products.</p>
    <p>🎊 Build store brand awareness.</p>
    <p>🛒 Get more traffic.</p>
    <p>💰 Boost revenue & profit.</p>
    <p>Prepare for Q4.</p>
  `, 
  "main_img": "https://img.dsers.com/cam/cam_01.png", 
  "main_img_link": "d", 
  // "head_line": "Prepare your Q4 niche store now.", 
  "head_line": "  Strengthen Customer Trust wIth Tracking.", 
  "head_line_link": "d", 
  // "cta_text": "GET PREPARED", 
  "cta_text": "TRY NOW", 
  "cta_link": "https://www.dsers.com/blog/create-niche-stores-to-sell-hot-items/", 
  "status": 0, 
  "updated_at": 1665974251, 
  "created_at": 1665974251, 
  "deleted_at": null,
  "cta_options":1,
}

const modalRoot = document.getElementById('modal-custom');

const PayInto = (props) => {
  
  let { data, plan, stores, dispatch, user_id} = props

  console.log('adData为', props)

  if(localStorage.getItem('CAM_USER')){
    data = MockData
  }

  if(!data){
    return null
  }

  useEffect(()=>{
    window.addEventListener('resize', (e) => {
      if(e.target.innerWidth >= 1200){
        const byClass=document.getElementById('byClass')
        byClass && byClass?.style?(
          byClass.style.marginTop='48px',
          byClass.style.height='calc(100% - 64px - 48px)'
        ):null
      }
    })
  },[])
  const setTag = () => {
    
     if (document.getElementById('catBtn') && data?.cam_id) {
       CommonDataCollect({
         event_type: 'subscribe_cam_01',
         action: 'cta_button_loaded_success',
         custom_data: [data]
       });
    }
    const mainImg = document.getElementById('mainImg');
    if (mainImg && data?.cam_id) {
       CommonDataCollect({
         event_type: 'subscribe_cam_01',
         action: 'cta_image_loaded_success',
         custom_data: [data]
       });
      mainImg.onload = () => {
         CommonDataCollect({
           event_type: 'subscribe_cam_01',
           action: 'cta_image_loaded_success',
           custom_data: [data]
         });
       };
       mainImg.onerror = () => {
         CommonDataCollect({
           event_type: 'subscribe_cam_01',
           action: 'cta_image_loaded_error',
           custom_data: [data]
         });
       };
     }
  }
  if(data){
    if(data?.cam_id != 1){
      CommonDataCollect({
        user_id:user_id,
        event_type: 'subscribe_cam_01',
        action: 'reached_people_num',
        custom_data:[data]
      });
    }
    setTag();
    setTimeout(() => {
      const byId=document.getElementById('BasicLayoutContentById')
      const cateId=document.getElementById('categoryId')
      const byClass=document.getElementById('byClass')
      localStorage.setItem('key1',byId)
      localStorage.setItem('key2',byClass)
      // console.log(byClass,2033333);
      // 右侧
      cateId && cateId?.style?(
        cateId.style.marginTop='87px'
      ):null
      byId && byId?.style?(

        byId.style.marginTop='112px'
      ):null
      // 左侧
      byClass && byClass?.style?(
        byClass.style.marginTop='48px',
        byClass.style.height='calc(100% - 64px - 48px)'
      ) : null
      setTag();
    },300)
  }

  const [visible, setVisible] = useState(false);
  const [selectedStoreId, setSelectedStoreId] = useState('')
  let payInto = localStorage.getItem(`PayIntoDetails${data.cam_id}${data.round}`);
  const [details, setDetails] = useState(!!payInto);

  function closeDetails() {
    localStorage.setItem(`PayIntoDetails${data.cam_id}${data.round}`, true);
    setDetails(true);
  }

  function showDetails() {
    setDetails(false);
    localStorage.removeItem(`PayIntoDetails${data.cam_id}${data.round}`);
    if(data?.cam_id != 1){
      CommonDataCollect({
        event_type: 'subscribe_cam_01',
        action: 'click_show_details',
        custom_data:[data]
      });
    }
  }

  const handleClick = (value) => {
    if(value == 'image'){
      if(data?.cam_id != 1){
        CommonDataCollect({
          event_type: 'subscribe_cam_01',
          action: 'click_cam_image',
          custom_data:[data]
        });
      }
    }
    if(value == 'head_line'){
      if(data?.cam_id != 1){
        CommonDataCollect({
          event_type: 'subscribe_cam_01',
          action: 'click_head_line',
          custom_data:[data]
        });
      }
    }
    if(value == 'cta'){
      if(data?.cam_id != 1){
        CommonDataCollect({
          event_type: 'subscribe_cam_01',
          action: 'click_cta_button',
          custom_data:[data]
        });
      }
    }
  }

  const changePlan = async () => {
    const response = await Post('/charge/change_plan',{
      data:{
        plan_type:data.plan_type,
        plan_period:data.plan_period
      }
    })
    if(response.code === 2000){
      window.location.href = response.data.confirm_url;
    }
  }

  const handleCtaClick = debounce(() => {
    if (!plan?.default_store_id) {
      setVisible(true)
      closeDetails();
      return
    }
    if(data?.cam_id != 1){
      CommonDataCollect({
        event_type: 'subscribe_cam_01',
        action: 'click_function_button',
        custom_data:[data]
      });
    }
    changePlan()
  },500)

  const handleSelectStore = (e) => {
    setSelectedStoreId(e.target.value)
  }


  const handleActivateStore = () => {
    dispatch({
      type: 'guidance/modificationShop',
      payload: {
        data: {
          store_id: selectedStoreId
        },
        callback: d => {
          if (d.code === 2000 || d.code === 2010) {
            dispatch({
              type: 'user/getProject',
            });
            handleCancelSelectStore();
          } 
        }
      }
    });
  }

  const handleCancelSelectStore = () => {
    setVisible(false)
  }

  const footerDom = () => {
    return <>
      <Button type="primary" disabled={!selectedStoreId} data="dx" onClick={handleActivateStore}>
        Activate
      </Button>
    </>
  }

  useEffect(() => {
    if (data) {
      setTag();
    }
  }, [data]);

  return ReactDOM.createPortal(
    <div>
      <div className={styles.container} id="camtitleIds">
        <div className={styles.hotImg}>
          <img src="https://img.dsers.com/icon/cam_hot.png"></img>
        </div>
        {/* 标题 */}
        <div className={styles.text}>
          <a
            href={data.head_line_link}
            target="_blank"
            onClick={() => {
              handleClick('head_line');
            }}
          >
            {data.head_line}
          </a>
        </div>
        {/* 按钮 */}
        <div className={styles.operation}>
          <div className={styles.button}>
            {data.cta_options == 1 ? (
              <a
                href={data.cta_link}
                onClick={() => {
                  handleClick('cta');
                }}
                target="_black"
              >
                {data.cta_text}
              </a>
            ) : (
              <a onClick={handleCtaClick}>{data.cta_text}</a>
            )}
          </div>
          <img
            src="https://img.dsers.com/icon/select_down.png"
            onClick={showDetails}
          ></img>
        </div>
      </div>

      {details ? null : (
        <div className={styles.mask}>
          <div className={styles.modal}>
            <div className={styles.header}>
              <div className={styles.left}>
                <div className={styles.headPortrait}>
                  <img src={data.logo_img} />
                </div>
                <div className={styles.text}>
                  <div className={styles.title}>{data.ads_title}</div>
                  <div className={styles.subTitle}>{data.ads_subtitle}</div>
                </div>
              </div>
              <div className={styles.right}>
                {/* <i class="material-icons notranslate" onClick={closeDetails}>
                keyboard_arrow_down
              </i> */}
                <img
                  src="https://img.dsers.com/cam/zk_icon.svg"
                  onClick={closeDetails}
                ></img>
              </div>
            </div>
            <div className={styles.introduce}>
              <div
                dangerouslySetInnerHTML={{ __html: data.primary_text }}
              ></div>
            </div>
            {data.main_img ? (
              <div className={styles.image}>
                <a
                  href={data.main_img_link}
                  onClick={() => {
                    handleClick('image');
                  }}
                  target="_blank"
                >
                  <img src={`${data.main_img}`} id="mainImg" />
                </a>
              </div>
            ) : null}
            <div className={styles.footer}>
              <div className={styles.footerContainer}>
                <div className={styles.text}>
                  <a
                    href={data.head_line_link}
                    onClick={() => {
                      handleClick('head_line');
                    }}
                    target="_blank"
                  >
                    {data.head_line}
                  </a>
                </div>
                <div className={styles.button}>
                  {data.cta_options == 1 ? (
                    <a
                      href={data.cta_link}
                      onClick={() => {
                        handleClick('cta');
                      }}
                      target="_black"
                      id="catBtn"
                    >
                      {data.cta_text}
                    </a>
                  ) : (
                    <a id="catBtn" onClick={handleCtaClick}>
                      {data.cta_text}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        visible={visible}
        style={{ zIndex: 1000 }}
        title="Please select the store you will use to be billed for DSers"
        footer={footerDom()}
        onCancel={handleCancelSelectStore}
      >
        <Radio.Group onChange={handleSelectStore} value={selectedStoreId}>
          {stores.map(item => {
            if (item.status !== 1) {
              return null;
            }
            return (
              <Radio value={item.id} key={item.id} style={{ margin: '4px 0' }}>
                {`${item.shop_name}.myshopify.com`}
              </Radio>
            );
          })}
        </Radio.Group>
      </Modal>
    </div>,
    modalRoot
  );
}

const mapStateToProps = ({ user, login }) => {
  return {
    plan: user?.plan,
    stores: login?.userInfo?.stores,
    user_id: login?.userInfo?.user_id
  };
}

const CAM001 = connect(mapStateToProps)(PayInto)

export {
  CAM001
}
