import React, { useEffect, useState } from 'react'
import Style from '../index.module.less'
import intl from 'react-intl-universal';
const PlaceTwo = ({ update }) => {
    const [state, setState] = useState(false)
    useEffect(() => {
        setState(update ? true : false)
    }, [update])
    return (
        state ?
            <div className={Style.OberloTopTipTwo}>
                <img className={Style.OberloTipIcon} src={require('../../../assets/oberlo-guid/tip.png')} />
                <div
                    style={{ width: 'calc(100% - 50px)' }}
                    dangerouslySetInnerHTML={{
                        __html: intl.get('guide.OberloTopTipTwo')
                    }}>
                </div>
                <img
                    className={Style.OberloTipClose}
                    src={require('../../../assets/oberlo-guid/closeTip.png')}
                    onClick={() => {
                        let user_Key = JSON.parse(localStorage.getItem('store'))?.user_id;
                        localStorage.setItem(`${user_Key}_OBERLO_READ_ORIDER_TIP`, true)
                        setState(false)
                    }}
                />
            </div> :
            <></>
    )
}
export default PlaceTwo