
    export default 
    {
    "shop_push": {
        "title": "¡Contáctanos para obtener entre un 10% y un 30% de descuento en los productos!",
        "dsers_price": "Precio de Dsers:",
        "current_price": "Precio actual:",
        "profit_margin": "Margen de beneficio:",
        "supplier": "Proveedor:",
        "dsers_program": "Programa de proveedores masivos de Dsers",
        "link_copied": "¡Link copiado!",
        "copy_link": "Copiar el link del producto en el mapeo",
        "contact": "Contáctanos para obtener un descuento",
        "what_dec": "¿Qué es el Programa de Proveedores Masivo de Dsers?",
        "thank_you": "Gracias por tu solicitud",
        "fill_email": "Por favor, introduce tu correo electrónico <br /> para completar la solicitud.",
        "fill_email_placeholder": "Por favor, introduce tu correo electrónico",
        "send_request": "Enviar solicitud",
        "reach_you": "El equipo de Dsers y AliExpress Global Supply  se pondrá <br /> en contacto contigo en breve.<br /> ¡Feliz día!"
    }
}