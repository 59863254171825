import React, { useState, useEffect, useCallback } from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'dva';
import qs from 'qs';
import intl from 'react-intl-universal';

import Login from './Login';
import PartnerItem from '../../components/PartnerItem/index'
import Logo from '../../components/NewLogo'

import { pageTitle } from 'utils/constants';

import styles from './SignInPage.less';
import Media from 'react-media';
import SelectLanguage from 'components/GlobalHeader/SelectLanguage';
import { Spin } from 'antd';
import { mvpAccountSite } from 'utils/justifyMVPAccountUtils';

const SignInPage = ({ dispatch, isMobile }) => {

  useEffect(() => {
    window.location.href = `${mvpAccountSite}login`;
    // localStorage.removeItem('ZLstatus');
    // localStorage.removeItem('payment');
    // localStorage.removeItem('type');
    // localStorage.removeItem('quantity');

    // localStorage.removeItem('storeId');
    // localStorage.removeItem('guidanceAgain');
    // localStorage.removeItem('errorCashbackCodeTip');
    // localStorage.removeItem('storeStatus');
    // // localStorage.removeItem('hasCloseUpdateStore');
    // localStorage.removeItem('showFulfillmentCenter');
    // localStorage.removeItem('orderResult');
    // localStorage.removeItem('aliBeOverdueTime');
    // localStorage.removeItem('aliExpressAuthStatus1');
    // localStorage.removeItem('dsersSpBanner');
    // localStorage.removeItem('nCoVBanner');
    // localStorage.removeItem('DSersToProBanner');
    // localStorage.removeItem('DSersToProBannerClose');
    // localStorage.removeItem('unRead');
    // localStorage.removeItem('guonianbanner');
    // localStorage.removeItem('fixStatus');
    // localStorage.removeItem('settingOrderData');
    // localStorage.removeItem('ae_check_omit');
    // localStorage.removeItem('admitad_notice_check');
  }, []);

  const [redirectUrl, setRedirectUrl] = useState(null);
  const [rawRedirectUrl, setRawRedirectUrl] = useState(null);

  useEffect(() => {
    const parse = qs.parse(location.search.slice(1));
    console.log('sign in 参数', parse);

    if (parse && parse.redirect_uri) {
      setRedirectUrl(parse);
      setRawRedirectUrl(location.search);
    }
  }, []);

  const handleSubmit = useCallback(
    data => {
      if (redirectUrl) {
        dispatch({
          type: 'login/pureLogin',
          payload: {
            ...data,
            redirectUrl
          }
        });
      } else {
        dispatch({
          type: 'login/login',
          payload: data
        });
      }
    },
    [dispatch, redirectUrl]
  );

  return <Spin spinning />
  
  return (
    <DocumentTitle title={`Sign in - ${pageTitle}`}>
      {
      isMobile ? 
      <div className={styles.mobileSignin}>
        <div className={styles.container}>
          <div>
            <Login onSubmit={handleSubmit} redirectUrl={rawRedirectUrl} isMobile={isMobile}/>
          </div>
        </div>
      </div> 
      :        
      <>
      <div className={styles.signIn}>
        <div className={styles.top}>
          <a className={styles.logo} target="_blank" href='/'>
            <img src='https://img.dsers.com/images/logo.png' />
          </a>
          <div style={{ lineHeight: '72px',marginRight:'150px' }}><SelectLanguage itemClass={styles.itemStyle} cusClass={styles.loginSelect}/></div>
        </div>
        <div className={styles.containerBox}> 
          <div className={styles.container}>
            <div className={styles.container_fix}>
              <div className={styles.start_n}></div>
              <div className={styles.start_t}></div>
              <div className={styles.text_box}>
                <p className={styles.text_title}>{intl.getHTML('login.text_title',{
                  titlek:styles.titlek
                })}</p>
                <p className={styles.text_desc}>{intl.getHTML('login.text_desc',{
                  titlek:styles.titlek
                })}</p>
              </div>
              <div className={styles.box}>
                <Login onSubmit={handleSubmit} redirectUrl={rawRedirectUrl} />
              </div>
            </div>
            {/* <PartnerItem /> */}
          </div>
        </div>
      </div>
      </>
      }
    </DocumentTitle>
  );
};

export default connect()(props => (
  <Media
    queries={{
      small: '(max-width: 599px)'
    }}
  >
    {matches => (
      <SignInPage
        {...props}
        isMobile={matches.small}
      />
    )}
  </Media>
));
