import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { DSERS_FIREBASE_CONFIG, FCM_PUSHAPI_PUBKEY } from 'utils/constants';
import { Post } from './request';

let cachedGetTokenPromise = null;
let cachedFirebaseApp = null;
let cachedMessagingInstance = null;

export const initFirebaseApp = () => {
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  // Initialize Firebase
  try {
    cachedFirebaseApp = initializeApp(DSERS_FIREBASE_CONFIG);
    cachedMessagingInstance = getMessaging(cachedFirebaseApp);
    // 初始化时就请求权限浏览器通知权限获取FCM device token 并缓存该Promise
    cachedGetTokenPromise = getToken(cachedMessagingInstance, {
      vapidKey: FCM_PUSHAPI_PUBKEY
    }).catch(e => {
      return null
    });
  } catch (error) {
    console.log(error)
  }
  
};

export const getFCMDeviceToken = async () => {
  return await cachedGetTokenPromise;
};

export const bindDSersUidToFCMDeviceToken = async (userInfo = {}) => {
  try {
    const token = await getFCMDeviceToken();
    // console.log(token, userInfo);
    // let isAdminExtLogin = false;

    if(!token || window.fetch.name === 'prevFetch') return;
    Post('/send_notification/user_device', {
      data: { user_id: userInfo.uaa_org_id || userInfo.user_id, device: token },
      cancelMessage: true
    });
    // 上报dsers uid和FCM device token的对应关系
  } catch (error) {
    // 获取token出错 通常是用户拒绝dsers发送通知
    console.log(error);
  }
};

export const onFrontMessageReceived = callback => {
  if (!cachedMessagingInstance) {
    console.error('fcm not inited');
    return;
  }
  try {
    onMessage(cachedMessagingInstance, callback);
  } catch (error) {
    console.log(error)
  }
};

export const getFirebaseInstance = () => {
  return {
    app: cachedFirebaseApp,
    messaging: cachedMessagingInstance,
    deviceTokenPromise: cachedGetTokenPromise
  };
};
