export default {
  tracking: {
    title: 'Tracking',
    search_input_placeholder: 'You can choose a sort to search precisely',
    order_no: 'Order No',
    email: 'Email',
    tracking_no: 'Tracking No',
    fulfilled: 'Fulfilled',
    unfulfilled: 'Unfulfilled',
    search_type: {
      order_no: 'Order No: ',
      note: 'Note',
      ali_order_no: 'Ali Order No',
      tm_order_no:"Tmall Order No",
      date: 'Date: ',
      product: 'Product',
      email: 'Email: ',
      customer_name: 'Customer Name: ',
      tracking_no: 'Tracking No: ',
      address: 'Address: '
    },

    time_zone_placeholder: 'Time Zone',
    variants: 'Variants: ',
    pending: 'Pending',
    Intramsit: 'In tramsit',
    pickup: 'Pick up',
    delivered: 'Delivered',
    manually: 'Set tracking status manually',
    origin: 'Origin Country',
    destination: 'Destination Country',
    retrack: 'Retrack',
    trackingMap:
      '{key, plural, =10 {All} =1 {Pending} =2 {In transit} =4 {Pick up} =6 {Delivered} =3 {Expired} =9 {Attention} }',
    trackingTipFree:"This feature has been disabled, Please Upgrade plan to activate feature. Activate this feature to trace the tracking status of orders that have been shipped.",
    trackingTipFree2:"Tracking is a paid feature. Please upgrade your plan to activate it. Activate Tracking to trace the tracking status of orders that have been shipped.",
    trackingTipPay:"Please set up the Tracking for the store.",
		trackingTipFreeBtn:"UPGRADE",
		trackingTipPayBtn:"SET UP THE TRACKING",
      shop: 'Shop: ',
    unrecognizedErr: 'We were not able to track your number now.',
    tips: 'Please try again later.',
    noTrack: 'No tracking information at this time. Please try again later.',
    select: 'Select',
    setTracking: [
      { key: 2, value: 'In transit' },
      { key: 4, value: 'Pick up' },
      { key: 5, value: 'Undelivered' },
      { key: 6, value: 'Delivered' },
      { key: 7, value: 'Alert' },
      { key: 8, value: 'Unrecognized' }
    ],
    logisticsStatus: {
      1: 'Pending',
      2: 'In transit',
      4: 'Pick up',
      6: 'Delivered',
      3: 'Expired',
      7: 'Alert',
      5: 'Undelivered',
      8: 'Unrecognized',
      9: 'Attention'
    }
  }
};
