import React, { Component } from 'react';
import { Button, message, Icon } from 'antd';
import { connect } from 'dva';

import CountDown from 'components/CountDown';

import styles from './RegisterResult.less';

import reactGaEvent from '../../utils/GAEvent';
import intl from 'react-intl-universal';

@connect(({ register, loading }) => ({
  register,
  submitting: loading.effects['register/resendMail']
}))
export default class RegisterResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCountDown: false,
      targetTime: 0
    };
  }

  componentDidMount() {
    this.getSendEmailResult();
  }

  componentWillUnmount() {
    clearTimeout(this.emailResultTimer);
  }

  // 查询邮件发送结果
  getSendEmailResult = () => {
    const {
      match: {
        params: { email }
      }
    } = this.props;

    if (!email) {
      return;
    }

    this.props.dispatch({
      type: 'register/sendEmailResult',
      payload: {
        data: {
          email
        },
        cancelMessage: true,
        callback: d => {
          let tip = null;
          clearTimeout(this.emailResultTimer);
          if (d.msg === 'record not found') {
            this.emailResultTimer = setTimeout(this.getSendEmailResult, 2000);
            return;
          }
          if (d.code === 5000) {
            if (typeof window.ga !== 'undefined') {
              window.ga('send', 'event', 'Send email exception at register', d.msg, new Date().toISOString());
            }

            switch (d.msg) {
              case 'invalid format':
                // '邮箱格式错误';
                tip = `Email address doesn't exist or wrong, please double check the spelling of the email.`;
                break;
              case 'unresolvable host':
                // '邮箱host无法解析';
                tip = `Email domain can't be verified, please double check the spelling of the email.`;
                break;
              case 'email rejected per SPAM policy':
                // '垃圾邮件';
                tip = `The email was considered as spam email, please double check your spam folder to locate it.`;
                break;
              case 'white list error':
                // tip = '白名单';
                tip = `Email sending failure, please add @mail.dsers.com in the whitelist of your email.`;
                break;
              case 'send failed':
                // '发送失败';
                tip = `Email sending failure due to mail service rejection. Please double check setting configuration of your mail service.`;
                break;
              case 'user not found':
                // '用户不存在';
                tip = `Email address doesn't exist or wrong, please double check the spelling of the email.`;
                break;
              case 'domain not hosted':
                // 域名问题
                tip = `Email domain can't be verified, please double check the spelling of the email.`;
                break;
              default:
                tip = 'Unknown error of your email service caused the failure of sending emails. Please change mail and try again.';
            }
            message.error(tip, 10);
          }
        }
      }
    });
  };

  resendMail = () => {
    const { ivtToken } = this.props.match.params;
    clearTimeout(this.emailResultTimer);
    this.props.dispatch({
      type: 'register/resendMail',
      payload: {
        data: {
          token: ivtToken || ''
        },
        callback: d => {
          if (d.code === 2010 || d.code === 2000) {
            this.setState({
              showCountDown: true,
              targetTime: new Date().getTime() + 60 * 1000
            });
            this.getSendEmailResult();
            // message.success(intl.get('register.result.resend_successed'));
          } else {
            message.error(intl.get('register.result.resend_failed'));
          }
        }
      }
    });
  };

  formatCountDown = time => {
    return `${Math.floor(time / 1000)} S`;
  };

  countDownEnd = () => {
    this.setState({
      showCountDown: false
    });
  };

  render() {
    const { match = {}, submitting } = this.props;
    const { showCountDown, targetTime } = this.state;
    let mail = '';
    let mailHost = '';

    if (match.params.email) {
      mailHost = match.params.email.split('@')[1];
      mailHost == 'gmail.com'
        ? (mailHost = 'google.com')
        : (mailHost = mailHost);
      mail = `http://mail.${mailHost}`;
    }

    return (
      <div className={styles.registerSuccessBox}>
        <div className={styles.registerSuccess}>
          <h1>{intl.get('register.result.title')}</h1>
          <div className={styles.registerResultBox}>
            <div className={styles.registerResult}>
              <p className={styles.registerP}>
                {intl.get('register.result.your_account')} {match.params.email ? match.params.email : ''}
              </p>
              <p className={styles.registerP}>
                {intl.get('register.result.register_succ')}
              </p>
              <p className={styles.registerIcon}>
                <Icon type="check" theme="outlined" />
              </p>
              <p className={styles.registerTxt}>
                {intl.getHTML('register.result.activation_email')}
              </p>
              <div className={styles.registerBtn}>
                <a
                  href={mail}
                  target="_blank"
                  track-type="checkEmail"
                  track-name="register"
                >
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      reactGaEvent({
                        category: 'register-result',
                        action: 'CheckEmailBtn'
                      });
                    }}
                  >
                    {intl.get('register.result.check_email')}
                  </Button>
                </a>
                <a
                  track-type="resendEmail"
                  track-name="register"
                >
                  <Button
                    size="small"
                    style={{ marginRight: 0 }}
                    onClick={this.resendMail}
                    loading={submitting}
                    disabled={showCountDown}
                  >
                    {showCountDown ? (
                      <CountDown
                        target={targetTime}
                        format={this.formatCountDown}
                        onEnd={this.countDownEnd}
                      />
                    ) : (
                      intl.get('register.result.resend_email')
                    )}
                  </Button>
                </a>
                <br />
                <a
                  className={styles.loginBtn}
                  href="/app/login"
                  track-type="goToSignUpOrSignIn"
                  track-name="register"
                >
                  {intl.get('register.result.go_sign')}
                </a>
              </div>
            </div>
            <p className={styles.notGetEmail}>
              {intl.getHTML('register.result.about_email')}
            </p>
          </div>
        </div>
      </div>
    );
  }
}
