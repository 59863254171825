
    export default 
    {
    "support": {
        "title": "Unterstützung",
        "description": "Willkommen bei DSers Support. Wir sind hier, um Ihnen zu helfen, Ihr Dropshipping-Geschäft mit Herz und Seele zu fördern.",
        "email": "EMAIL",
        "email_dec": "Schicken Sie uns eine E-Mail mit Ihren Fragen oder Inspirationen! <br /> Wir werden so schnell wie möglich antworten.",
        "email_btn": "E-Mail-Unterstützung",
        "live_chat": "LIVE CHAT",
        "live_chat_dec": "Senden Sie uns eine Nachricht zur Fehlerbehebung. Wir sind gleich da, <br /> um einen Live-Chat zu starten.",
        "live_chat_btn": "Nachricht an uns",
        "facebook": "FACEBOOK",
        "facebook_dec": "Folgen Sie unserer Facebook-Seite, um die neuesten Informationen über DSers und Dropshipping zu erhalten.",
        "facebook_btn": "Facebook",
        "next_level": "Bringen Sie Ihr Unternehmen auf die nächste Stufe",
        "try_now": "Testen Sie es jetzt risikofrei.",
        "get_started": "Starten Sie",
        "partnership": "Partnerschaft"
    }
}