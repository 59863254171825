import {
  getEmailTemplateStatus,
  changeEmailTemplateStatus
} from '../services/emailtemplate';

export default {
  namespace: 'emailtemplate',
  state: {},
  effects: {
    *status({ payload }, { call }) {
      yield call(getEmailTemplateStatus, payload);
    },
    *update({ payload }, { call }) {
      yield call(changeEmailTemplateStatus, payload);
    }
  },
  reducers: {}
};
