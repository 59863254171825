
    export default 
    {
    "product_edit": {
        "repeated_tip": "I colori e/o le dimensioni del prodotto sono ripetuti, cambiali",
        "picture": "Immagine",
        "compare_price": "Confronta il <br/> valore del prezzo",
        "delete_variant_tip": "Sei sicuro di voler eliminare questa variante?",
        "delete_selected_variant_tip": "Sei sicuro di voler eliminare le varianti selezionate?",
        "new_compare_price": "Nuovo prezzo di confronto",
        "new_price": "Nuovo prezzo",
        "new_stock": "Nuovo stock",
        "open_on_supplier": "CLICCA PER INFORMAZIONI",
        "from_other": "da altri",
        "seller_id": "ID Venditore",
        "input_title": "Inserisci titolo",
        "product_type": "Tipo di prodotto",
        "collection": "Collezione",
        "vendor": "Venditore",
        "tags": "Tag",
        "no_edit": "I prodotti che sono pubblicati su Shopify non possono essere modificati, modifica direttamente su Shopify.",
        "select_variant": "Seleziona variante",
        "none": "Nessuno",
        "description": "Descrizione",
        "images": "Immagini <i>({selectImages}/{allImages})</i>",
        "shipping": "Informazioni spedizione",
        "shipping_info": "Puoi controllare le informazioni sui costi di spedizione {platform}, i tempi di consegna stimati e la disponibilità di tracciamento qui selezionando un paese di spedizione (e spedizione da). Le informazioni di spedizione NON saranno inviate a Shopify quando pubblichi. Questo è solo per darti una stima dei costi di spedizione.",
        "cannot_recover": "Non è possibile recuperare le varianti di prodotto eliminate, vuoi continuare?",
        "download_pic": "Clicca qui per scaricare tutte le immagini",
        "delete_pic_tip": "Do you want to delete the image(s)? The deleted image(s) will not be uploaded to the Media section in the Shopify product.",
        "delete_picss_tip": "Do you want to delete all the images? No images will be uploaded to the Media section in the Shopify product.",
        "back": "Indietro",
        "product": "Prodotto",
        "from_aliexpress": "da AliExpress",
        from_tmall:'da Tmall',
        "title": "Titolo",
        "variants": "Varianti <i>({allvariants})</i>",
        "all": "Tutti",
        "action": "Azione",
        "sku": "Sku",
        "cost": "Costo",
        "price": "Valore",
        "shopify_stock": "Stock su <br /> Shopify",
        "stock": "Stock su <br /> rivenditore",
        replace_stock:'Stock su <br /> {platform}',
        "change_price": "Cambia prezzo",
        "change_compare_at_price": "Cambia il confronto con il prezzo",
        "remove_sku": "Rimuovi SKU(s)",
        "change_stock": "Cambia stock",
        "option_repeated_tip": "I colori e/o le dimensioni del prodotto sono ripetuti, cambiali",
        "sku_repeated_tip": "Lo SKU deve essere diverso per ogni variante. Modificalo.",
        "option_name_invalid": "Il nome dell'opzione non può essere vuoto",
        "option_value_invalid": "Il valore dell'opzione non può essere vuoto",
        "option_name_repeat": "Il nome dell'opzione non può essere ripetuto",
        "option_name_slash": "Il nome dell'opzione non può contenere  / ",
        "option_sku_invalid": "Il valore SKU non può essere vuoto",
        "sku_count_invalid": "Tieni almeno uno SKU",
        "overrun_warning": "Hai superato il limite giornaliero per accedere alle informazioni sul prodotto",
        "push_description": "Clicca <a target=\"_blank\" href=\"/app/settings\">qui</a> per impostare il contenuto nella descrizione quando sponsorizzi il prodotto.",
        "specifications": "SPECIFICHE",
        "overview": "PANORAMICA",
        "stock_on_shopify": "Stock on <br /> Shopify",
        "change_image": "CAMBIA IMMAGINE",
        "organization": "Azienda",
        "download_all_img": "Scarica",
        "delete_img": "Delete",
        "img_des": "Le immagini qui spuntate appariranno nei Media dei prodotti di Shopify",
        "not_select_main_img_des": "If you don’t select the cover picture here, the first picture you check here will be used as the cover picture for this Shopify product.",
        "deleteImage": "Delete Image",
        "products": "Prodotti",
        "shipping_cost": "Costi di <br /> spedizione",
        "uncheckedAlert": "Se non selezioni alcuna immagine, non saranno caricate immagini nel prodotto Shopify.",
        "try_again_collections_text": "We couldn't get your collections data because the request timeout, please click ",
        "try_again_collections_fun": "Refresh",
        "checkImages": "Immagini <i>({allImages})</i>",
        "select_province_pleaseholder": "Seleziona una città/provincia",
        "ship_from_text": "Spedire da:",
        "ship_to_text": "Spedire a:",
        "product_edit_price_tip": "Il simbolo della valuta varia in base alle impostazioni del tuo negozio Shopify dopo aver pubblicato su Shopify. Imposta manualmente il prezzo/confronta il valore di prezzo qui quando ne hai bisogno.",
        "shopify_stock_tip": "Qui puoi impostare la quantità di scorte che vuoi inviare a Shopify. Non sarà aggiornato quando il fornitore cambia le scorte su {platform}.",
        "aliexpress_stock_tip": "DSers aggiornerà automaticamente le scorte AliExpress per i prodotti sulla lista importazioni ogni 2 ore. Qui le scorte non possono essere aggiornate manualmente.",
        tmall_stock_tip: "DSers aggiornerà automaticamente l'inventario di Tmall per i prodotti nella Lista Importazioni. L'inventario non può essere modificato manualmente.",
        product_stock_tip:'DSers aggiorna le scorte prodotti dei fornitori nella pagina Miei prodotti. Le scorte non possono essere modificate manualmente. Non aggiorneremo le scorte nella mappatura Avanzata/BOGO/Pacchetto.',
        shopify_product_stock_tip:`Le scorte saranno sincronizzate solo da Shopify e non saranno aggiornate in base ai fornitori a meno che tu non abbia attivato l'Auto aggiornamento nel piano a pagamento.`,
        download_img_fail_tip:'Image download failed, please try again later',
    }
}