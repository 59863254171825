import React, { Component } from 'react';
import { Col, Row, Card, Button, Divider, Icon } from 'antd';
import { routerRedux } from 'dva/router';
import { connect } from 'dva';
import intl from 'react-intl-universal';

import FixedHeaderLayout from 'layouts/FixedHeaderLayout';
import Article from './component/Article';
import MessengerButton2 from './component/MessengerButton2';

import { Get } from '../../utils/request';
import { getLanguage } from '../../utils/utils';
import styles from './index.less';

// import mockData from './mockData.json';

const formatData = data => {
  const result = [];
  let tmp = {};
  // eslint-disable-next-line
  for (const item of data) {
    if (item.push_date in tmp) {
      tmp[item.push_date].push(item);
    } else {
      tmp[item.push_date] = [item];
    }
  }

  Object.keys(tmp)
    .sort((a, b) => b.localeCompare(a, 'en'))
    .forEach(key => {
      result.push({ date: key, value: tmp[key] });
    });

  tmp = null;
  return result;
};

class HelpCenter extends Component {
  newsQueue = [];

  constructor(props) {
    super(props);
    this.state = {
      siblingHeight: undefined,
      newsData: [],
      loading: false,
      pagination: {
        pageSize: 20, // 后端固定 10 条
        current: 1,
        total: 0
      }
    };
  }

  componentDidMount() {
    const { location, dispatch } = this.props;

    if (!(location.state && location.state.fromIssueModal)) {
      dispatch(routerRedux.push('/')); // 首页
    }

    const sibling = document.querySelector('#hc-right-area');

    if (sibling) {
      this.setState({ siblingHeight: sibling.offsetHeight });
    }

    this.fetchNews();
  }

  fetchNews = async () => {
    const {
      pagination: { current }
    } = this.state;
    this.setState({ loading: true });

    try {
      const res = await Get(`/helpcenter/latestnews?page=${current}`);

      if (res.code === 2000 && res.data) {
        this.newsQueue.push(...res.data.data);
        this.setState(state => ({
          // newsData: { ...state.newsData, ...formatData(res.data.data) },
          // newsData: formatData(mockData.data),
          newsData: formatData(this.newsQueue),
          pagination: {
            ...state.pagination,
            total: res.data.count
          }
        }));
      }
    } catch (error) {
      console.log('fetchNews error:', error);
    } finally {
      this.setState({ loading: false });
    }
  };

  handleLoadMore = () => {
    this.setState(
      state => ({
        pagination: {
          ...state.pagination,
          current: state.pagination.current + 1
        }
      }),
      () => {
        this.fetchNews();
      }
    );
  };

  handleMessage = () => {
    window.dataLayer.push({
      event: 'helpCenterCustomEvent',
      type: 'messageUs'
    });

    if (window.FB) {
      window.FB.CustomerChat.showDialog();
    }
  };

  render() {
    const { siblingHeight, newsData, pagination, loading } = this.state;
    const { location } = this.props;

    if (!(location.state && location.state.fromIssueModal)) {
      return null;
    }

    let FAQLink = 'https://help.dsers.com/';
    if (getLanguage() === 'fr-FR') {
      FAQLink =
        'https://help.dsers.com/';
    }

    return (
      <FixedHeaderLayout
        extraContent={
          <div className={styles.title}>
            {intl.get('help_center.help_center.fixed_header')}
          </div>
        }
      >
        <div className={styles.hc_main}>
          <Row gutter={32}>
            <Col xs={{ span: 9 }}>
              <Card
                title={intl.get('help_center.help_center.latest_news')}
                bodyStyle={{
                  height: siblingHeight ? `${siblingHeight - 58}px` : '100%',
                  overflowY: 'auto'
                }}
              >
                <Article
                  data={newsData}
                  pagination={pagination}
                  onLoadMore={this.handleLoadMore}
                  loading={loading}
                />
              </Card>
            </Col>
            <Col xs={{ span: 15 }} id="hc-right-area">
              <Row gutter={32}>
                <Col xs={{ span: 12 }}>
                  <Card
                    title={intl.get('help_center.help_center.online_chat')}
                    style={{ marginBottom: 24 }}
                  >
                    <p>{intl.get('help_center.help_center.online_time')}</p>
                    <p>{intl.get('help_center.help_center.work_days')}</p>
                    <MessengerButton2
                      type="primary"
                      // onClick={this.handleMessage}
                    >
                      {intl.get('help_center.help_center.message_us')}
                    </MessengerButton2>
                    {/* <div className={styles.warnTip}>
                      In order to provide you better real time online service,
                      we are upgrading our online messenger service from 31st
                      Oct until 6th Nov. Please kindly contact us via&nbsp;
                      <a href="mailto:supply-support@dserspro.com">
                        support@dserspro.com
                      </a>
                    </div> */}
                  </Card>
                  <Card
                    title={intl.get('help_center.help_center.email_report')}
                    style={{ marginBottom: 24 }}
                  >
                    <p>
                      {intl.getHTML(
                        'help_center.help_center.email_report_desc'
                      )}
                    </p>
                    <a
                      href="mailto:support@dserspro.com"
                      track-type="helpCenter"
                      track-name="reportIssueContact"
                    >
                      <Button type="primary">
                        {intl.get('public.contact')}
                      </Button>
                    </a>
                  </Card>
                  <Card
                    title={intl.get('help_center.help_center.product_sourcing')}
                  >
                    <p>
                      {intl.getHTML(
                        'help_center.help_center.product_sourcing_desc'
                      )}
                    </p>
                    <a
                      href="mailto:supply-support@dserspro.com"
                      track-type="helpCenter"
                      track-name="productSourcingContact"
                    >
                      <Button className={styles.specialBtn}>
                        {intl.get('public.contact')}
                      </Button>
                    </a>
                  </Card>
                </Col>
                <Col xs={{ span: 12 }}>
                  <Card
                    title={intl.get('help_center.help_center.blog_in_en')}
                    style={{ marginBottom: 24 }}
                  >
                    <div className={styles.link}>
                      <a
                        href={FAQLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        track-type="helpCenter"
                        track-name="blogFaqLink"
                      >
                        FAQ
                      </a>
                      <Divider type="vertical" />
                      <a
                        href="https://www.dsers.com/blog/welcome-to-dsers-blog-let-s-dropship/"
                        target="_blank"
                        rel="noopener noreferrer"
                        track-type="helpCenter"
                        track-name="blogAboutLink"
                      >
                        {intl.get(
                          'help_center.help_center.everything_about_ds'
                        )}
                      </a>
                    </div>
                  </Card>
                  <Card
                    title={intl.get('help_center.help_center.blog_in_fr')}
                    style={{ marginBottom: 24 }}
                  >
                    <p>{intl.get('help_center.help_center.coming_soon')}</p>
                  </Card>
                  <Card
                    title={intl.get('help_center.help_center.blog_in_pt')}
                    style={{ marginBottom: 24 }}
                  >
                    <p>{intl.get('help_center.help_center.coming_soon')}</p>
                  </Card>
                  <Card
                    title={intl.get('help_center.help_center.youtube_channel')}
                    style={{ marginBottom: 24 }}
                  >
                    <div className={styles.link}>
                      {/* <Icon
                        type="youtube"
                        style={{
                          fontSize: 18,
                          marginRight: 8,
                          verticalAlign: 'middle'
                        }}
                      /> */}
                      <span />
                      <a
                        href="https://www.youtube.com/channel/UCpiRdgB8VXvaht0AizSP9mA"
                        target="_blank"
                        rel="noopener noreferrer"
                        track-type="helpCenter"
                        track-name="YoutubeLink"
                      >
                        {intl.get('help_center.help_center.check_ds_youtube')}
                      </a>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </FixedHeaderLayout>
    );
  }
}

export default connect(null)(HelpCenter);
