import { Get, Post, Put } from '../utils/request';
import { mvpBffHost } from '../common/sysconfig';

export async function createTutorialOrder(payload) {
  return Post('/ord/tutorial_order', payload);
}

export async function getTimeZone(payload) {
  return Get('/set/time_zone', payload);
}

export async function postBigUserSendEmail(payload) {
  return Post('/inbox_notification/send_email', payload);
}

export async function getBigUserMsg(payload) {
  return Get('/inbox_notification/get', payload);
}

export async function fetchAgencyList(payload) {
  return Get('/set/agencies', payload);
}

export async function fetchAgencyListFromMerchant(payload) {
  return Get('/merchant/agency', payload);
}

export async function worldTelephoneAreaCode(payload) {
  return Get('/set/agencies/phone/country', payload);
}

export async function putDelBigUserMsg(payload) {
  return Put('/inbox_notification/delete', payload);
}
export async function dataTrack(payload) {
  return Post('/collect/web_common', payload);
}

export async function recordLog(payload) {
  return Post('/collect/client_log', payload);
}

export async function fetchUnsupportedShipping(payload) {
  return Get('/prod/freight/unable', payload);
}

export async function fetchVatData(payload) {
  return Get('/set/vat', payload);
}

export async function fetchCurrencyState(payload) {
  return Get('/currency/all', payload);
}

export async function getGuideInfo(payload){
  return Get('/users/first_tag', payload);
}

// 向WooCommerce Alert发送错误通知
export async function WooCommerceAlert(status, position) {
  try {
    const store = JSON.parse(localStorage.getItem('store')) || '';
    if (
      store &&
      store.status !== status &&
      store?.woo_stores?.length &&
      [11010000].includes(status)
    ) {
      const obj = {
        user_id: `用户ID--${store.user_id}`,
        url: 'shopify 数据检测',
        message: JSON.stringify(store.woo_stores),
        method: position
      };
      const result = await Post('/woo/notify/send', { data: obj });
      console.log(result);
    }
  } catch (error) {
    console.log(error);
  }
}

export async function getSuperSetting(payload) {
  return Get(mvpBffHost + '/infra-setting-bff/setting/list', {
    ...payload,
    isCustomApi: true,
    cancelMessage: true
  });
}
