import { fakeResendMail, fakeActivation, getChannel } from '../services/login';
import { fakeRegister, getSendEmailResult, childRegister } from '../services/register';
import { queryCurrent, queryNewInfo, reportAccout, reportNewUser } from '../services/user';

import { webCommonCollect } from 'utils/utils';
export default {
  namespace: 'register',

  state: {
    activation: undefined
  },

  effects: {
    *submit({ payload }, { call,put }) {
      localStorage.removeItem('ifMigration')
      localStorage.removeItem('logoutEmail')
      window.localStorage.removeItem('store_is_available');
      window.localStorage.removeItem('EnterNewHomeTime');
      window.localStorage.removeItem('setMigrationInfo');
      window.localStorage.removeItem('noMigra');
      window.localStorage.removeItem('keyStatus');
      window.localStorage.removeItem('close_tip');
      localStorage.removeItem('migration_stores');
      try {
        const response = yield call(fakeRegister, payload);

        if (response && response.code === 2000) {
          localStorage.removeItem('newUserInfo')
          //首页官网banner打点
          if (sessionStorage.getItem('banner_ga')) {
            webCommonCollect({
              action:'from banner to signup',
              custom_info: [
                {
                  name: 'type',
                  value: sessionStorage.getItem('banner_ga')
                }
              ]
            }, () => {
              localStorage.setItem('dser-pro-authority', response.data.token);
              window.location.href = '/app/';
            });
          } else {
            localStorage.setItem('dser-pro-authority', response.data.token);
            if(payload.shopname){
              yield call(reportNewUser,{
                data:{
                  email:payload.data.email
                }
              })
              yield call(reportAccout,{
                data:{
                     shopify_domain:payload.shopname
                     }
              })
            }
            yield put({
              type:'auth/getMigrationStoreInfo',
            })
            window.location.href = '/app/';
          }

        } else {
          console.log('register error: ', response);
        }
      } catch (error) {
        console.log('register error: ', error);
      }
    },

    *submit_child({ payload }, { call }) {
      try {
        const response = yield call(childRegister, payload);
        if (response && response.code === 2000) {
            localStorage.setItem('dser-pro-authority', response.data.access_token);
            localStorage.removeItem('newUserInfo')
            const res = yield call(queryNewInfo)
            if(res.code === 2000){
              window.location.href = '/app/';
              yield put({
                type:'user/setNewUserInfo',
                payload:res.data
              })
            }
        } else {
          console.log('register error: ', response);
        }
      } catch (error) {
        console.log('register error: ', error);
      }
    },

    *pureSubmit({ payload: { successCallback, ...params } }, { call }) {
      localStorage.removeItem('ifMigration')
      localStorage.removeItem('logoutEmail')
      try {
        const response = yield call(fakeRegister, params);

        if (response && response.code === 2000) {
          localStorage.removeItem('newUserInfo')
          successCallback(response.data.token,response.data);
        } else {
          console.log('register error: ', response);
        }
      } catch (error) {
        console.log('register error: ', error);
      }
    },

    *resendMail({ payload }, { call }) {
      yield call(fakeResendMail, payload);
    },

    *activation({ payload }, { call, put }) {
      const response = yield call(fakeActivation, payload);

      // 请求成功时需要更新 userInfo
      if (response.code === 2000 || response.code === 2010) {
        const res = yield call(queryCurrent);

        if (res.code === 2000) {
          localStorage.setItem('store', JSON.stringify(res.data));

          if (
            res.data.stores &&
            res.data.stores[0] &&
            res.data.stores[0].id
          ) {
            localStorage.setItem('storeId', res.data.stores[0].id);
          }

          yield put({
            type: 'login/setUserInfo',
            payload: res.data
          });
        }
      }

      // 因为有错误消息，不管成功与否都 dispatch
      yield put({
        type: 'activationHandle',
        payload: response
      });
    },

    *channel({ payload }, { call }) {
      yield call(getChannel, payload);
    },

    *sendEmailResult({ payload }, { call }) {
      yield call(getSendEmailResult, payload);
    }
  },

  reducers: {
    activationHandle(state, { payload = {} }) {
      return {
        ...state,
        activation: payload
      };
    }
  }
};
