import React, { useImperativeHandle, useState, useEffect, } from 'react'
import { useHistory } from "react-router-dom";
import { Button, Radio, Dropdown, Menu, Row, Tooltip, Icon } from 'antd';
import styles from './PreMigration.less'
import { Post } from 'utils/request';
import intl from 'react-intl-universal';
function SettingContainer({setting_option,migrationInfo}) {


    const [select, setSelect] = useState()
    const history = useHistory()


    useEffect(() => {
        if (setting_option == 'PREFERENCES_VALUE_OBERLO') {
            setSelect('PREFERENCES_VALUE_OBERLO')
        } else if (setting_option == 'PREFERENCES_VALUE_DSERS') {
            setSelect('PREFERENCES_VALUE_DSERS')
        }
    }, [])

    const handleChange = (e) => {
        setSelect(e.target.value)
    }

    const handleClick = async () => {

        const res = await Post('/mgnoberlo-core-api/dsers-api/migration/preferences', {
            data: {
                setting_option: select,
                shopify_domain:migrationInfo?.migrating_shopify_domain
            }
        }, true)

        if (!res.code) {
            history.replace('/data-migrate')
        }
    }


    return (
        <div className={styles.select}>
            <div className={styles.settingcard}>
                <div>{intl.get('migration.setting_title')}
                    <Tooltip
                        title={intl.get('migration.setting_text')}
                        placement="top"
                    >
                        <Icon style={{ fontSize: "15px", marginLeft: '5px' }} type="question-circle" />
                    </Tooltip>
                </div>
                <Radio.Group onChange={handleChange} value={select}>
                    <Radio value='PREFERENCES_VALUE_DSERS'>{intl.get('migration.option_1_1')}</Radio>
                    <Radio value='PREFERENCES_VALUE_OBERLO'>{intl.get('migration.option_1_2')}</Radio>
                </Radio.Group>
            </div>
            <div className={styles.button} onClick={handleClick}><Button disabled={!select}>CONTINUE</Button></div>
        </div>
    )
}

export default SettingContainer