export default {
  guidance: {
    welcome: 'Welcome to DSers', //  m
    need_steps: 'You only need <i>4</i> steps to be ready',
    start_tutorial: 'Start tutorial', //  m
    before_starting: `Before starting,<br />
                      please make sure to DEACTIVATE Shopify auto fulfillment feature.<br />
                      Otherwise, you won't be able to process orders on DSers.<br /><i
                      >Go to Shopify - Setting - Checkout to deactivate it.</i>`,
    previous: 'Previous',
    next: 'Next',
    start: 'Start',
    link_to_more_stores: ' Link to more stores', // u
    bind_ali_over: 'AliExpress Account Whitelisted',
    link_to_ae: 'Link to AliExpress',
    home: 'Home',
    my_product: 'My Products',
    setting: 'Setting',
    shipping: 'Shipping',
    orders: 'Orders',
    new_feature: 'New Feature',
    notification: 'Notification',
    blog: 'BLOG',
    order: 'How to place multiple orders with DSers?',
    import: 'How to import products to DSers?',
    step1: {
      title: 'First, link DSers to your Shopify store', // uuuuu
      step1_dec:
        'Click the <i>link to more stores</i> button on the top of the page.', // uuuuu
      step2_dec:
        'Select Shopify as platform and fill in store name to link to DSers.' //  uuuuu
    },
    step2: {
      title: 'Then, link DSers to your AliExpress account.', //  m  // uuuu
      step1_dec:
        'Click the <i>Link to AliExpress</i> button on the top of the page.',
      step3_dec:
        'Apply to AliExpress Whitelist. It will provide privileges and allow you to place bulk orders and reduce the order failures rate.'
    },
    step3: {
      title: 'Next,map products to an AliExpress supplier', // m  // uuuu
      dec:
        'Click the button at the right side of a product card to to map AliExpress supplier(s) to your Shopify products. DSers will know how to process your orders to AliExpress after that.', //   uuu
      map_multiple: 'DSers allows you to map multiple suppliers according to:', // uuu
      map_from_to: '1. Ship from/to countries',
      advance: `Check details about <a
          href="https://help.dsers.com/advanced-mapping-for-shopify/"
          target="_blank"
        >Advanced</a>
        <a
          class={foreignLink}
          href="https://help.dsers.com/advanced-mapping-for-shopify/"
          target="_blank"
        >
        </a> mapping`,
      upsell_promotion: '2. Upsell promotion',
      bogo_bundle: `Check details about <a
          href="https://help.dsers.com/create-buy-one-get-one-offers-for-shopify/"
          target="_blank"
        >BOGO</a>
        <a
          class={foreignLink}
          href="https://help.dsers.com/create-buy-one-get-one-offers-for-shopify/"
          target="_blank"
        >
        </a> / <a
          href="https://help.dsers.com/create-bundles-of-products-for-shopify/"
          target="_blank"
        >BUNDLE</a>
        <a
          class={foreignLink}
          href="https://help.dsers.com/create-bundles-of-products-for-shopify/"
          target="_blank"
        >
        </a> mapping`
    },
    step4: {
      title: 'Don’t forget to set up shipping method', //  m  //  uuuu
      dec: `For the last step of preparation, please set up your default shipping method in Setting - Shipping.
            You can set it up based on either Country or
            <a
              href="https://help.dsers.com/set-shipping-method-for-all-products-for-shopify/"
              target="_blank"
            >AliExpress product</a>
            <a
              class={foreignLink}
              href="https://help.dsers.com/set-shipping-method-for-all-products-for-shopify/"
              target="_blank"
            >
            </a> .`
    },
    step5: {
      title: 'Finally, you can place orders in bulk', //  uuu
      dec:
        'Select all your orders in the Awaiting Order tab then click the Place order to AliExpress button', // uuu
      tip: 'Review order details and shipping method. Then you are done!'
    },
    after: {
      dec:
        'After making payment, leave the rest to DSers.<br />DSers will <b>SYNCHRONIZE AUTOMATICALLY</b> for you',
      p1:
        'Auto sync order status and tracking number from AliExpress to DSers every 12 hours',
      p2:
        'Auto sync tracking numbers from DSers to Shopify 1-2 hours after sync from AliExpress',
      p3:
        'Auto trigger the Shopify Shipping confirmation mail to your customers'
    },
    actions: {
      title:
        'The actions below will prevent DSers from processing or <br /> syncing orders. Please:',
      do_not1:
        '<i>DO NOT</i> manually fulfill orders on DSers or Shopify if you to process them with DSers.',
      do_not2: '<i>DO NOT</i> activate the auto fulfillment feature on Shopify.',
      do_not3: '<i>DO NOT</i> request fulfillment on Shopify.'
    },
    get_ebook: 'Get free Ebook or <br /> contact customer service here', // m  // uuuu
    check_again: 'Check this tutorial again here', //  a  //  uuu
    confused: 'If you are still confused about <br /> how to use DSers', // a  //  uuu
    guidance_again: 'Watch tutorial videos again',
    total_sales:'Total Sales',
    shopify_sales:'Shopify Sales',
    ali_orders:'Ali Orders',
    supplier_cost:'Supplier Cost'
  }
};
