import React, { useMemo } from 'react';
import { Dropdown, Menu } from 'antd';

import styles from './SelectLanguage.less';
import { languageLabels } from '../../utils/constants';
import usIcon from '../../assets/svg/america.svg';
import frIcon from '../../assets/svg/france.svg';
import brIcon from '../../assets/svg/brazil.svg';
import esIcon from 'assets/svg/es.svg';
import deIcon from 'assets/svg/de.svg';
import itIcon from 'assets/svg/it.svg';
import { getLanguage, CommonDataCollect } from 'utils/utils';
import { Post } from 'utils/request';

const localeIcons = {
  'en-US': usIcon,
  'fr-FR': frIcon,
  'pt-PT': brIcon,
  'de-DE': deIcon,
  'it-IT': itIcon,
  'es-ES': esIcon
};

const languageOptions = Object.keys(languageLabels).map(key => {
  return {
    value: key,
    label: languageLabels[key],
    icon: localeIcons[key]
  };
});

const SelectLanguage = ({sider, cusClass, itemClass}) => {
  const currentLang = useMemo(() => {
    return getLanguage();
  }, []);

  const handleLanguageChange = async ({ key: value }) => {

    try {


      let gatsbyLang = 'en-us';
      if (value === 'en-US') {
        gatsbyLang = 'en-us';
      } else if (value === 'fr-FR') {
        gatsbyLang = 'fr';
      } else if (value === 'pt-PT') {
        gatsbyLang = 'pt-br';
      }else if (value === 'de-DE') {
        gatsbyLang = 'de-de';
      }
      else if (value === 'it-IT') {
        gatsbyLang = 'it-it';
      }
      else if (value === 'es-ES') {
        gatsbyLang = 'es-es';
      }

      await Post('/users/update_country',{
        data:{
          country:value
        }
      })
      localStorage.setItem('gatsby-intl-language', gatsbyLang); // 官网的语言,保持同步
      localStorage.setItem('lang', value);

      await CommonDataCollect({
        event_type: 'subscription_analysis',
        action: 'click_multilingual',
      })

      window.location.reload();

    } catch (error) {
      console.log(error)
    }


  };


  {console.log('languageOptions', languageOptions)}

  const ContentMarkup = (
    <Menu selectedKeys={currentLang} onClick={handleLanguageChange}>
      {languageOptions.map(({ value, label, icon }) => (
        <Menu.Item key={value} className={itemClass}>
          <span className={styles.optionContent}>
            <span className={styles.optionIcon}>
              <img className={styles.localeIcon} src={icon} style={value == 'de-DE' || 'it-IT' || 'es-ES' ? {width:"20px",height:'20px'} : null}/>
            </span>
            {label}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={ContentMarkup} placement="bottomCenter" trigger={sider && ['click']}>
      <span className={`${styles.optionContent} ${styles.container} ${cusClass}`} style={sider && {width:'100%'}}>
        <div className={styles.displayIcon} style={sider && {marginRight:'26px'}}>
          <img className={styles.localeIcon} src={localeIcons[currentLang]} />
        </div>
        {languageLabels[currentLang]}
        <i style={{ position: 'relative', top: '6px', marginLeft: '10px' }} class="material-icons">arrow_drop_down</i>
      </span>
    </Dropdown>
  );
};

export default React.memo(SelectLanguage);
