import React from 'react';
import { Button } from 'antd';

import intl from 'react-intl-universal';

import FeatureNotice from 'components/FeatureNotice';
import useOnlineNotice from '../../hooks/useOnlineNotice';

const onlineDate = new Date(2020, 6, 13); // 上线日期

function Notice() {
  const [visible, closeNotice] = useOnlineNotice('paypal', onlineDate);

  const modalProps = {
    visible,
    onCancel: closeNotice
  };

  function footer() {
    return (
      <>
        <span onClick={() => closeNotice()} role="button" tabIndex="-1">
          {intl.get('public.got_it')}!
        </span>
        <a
          href="https://help.dsers.com/add-tracking-number-to-paypal-for-shopify/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button type="primary">{intl.get('public.check_more')}</Button>
        </a>
      </>
    );
  }

  return (
    <FeatureNotice
      modalProps={modalProps}
      title="New Feature online!"
      footer={footer}
    >
      {intl.get('paypal.auto_sync_track_to_paypal')}
    </FeatureNotice>
  );
}

export default Notice;
