export default {
  order: {
    "order_language":{
      "pay" : "Veuillez payer vos commandes dans les 24 heures, sinon elles seront annulées par Tmall."
  },
    tab_tip: {
      pending: `Les commandes "En Attente" sont des commandes pour lesquelles le paiement de vos clients n'est pas encore capturé sur Shopify.
                Vous ne pouvez pas traiter ou éditer les commandes En Attente sur DSers jusqu'à ce que le paiement soit capturé.
                Si une commande est toujours En Attente une fois le paiement capturé, veuillez vérifier <a
                href='https://help.dsers.com/pending-orders-introduction-for-shopify/'
                target="_blank"
                data-ga="Pending - here"
                >ici</a> pour mettre à jour manuellement le statut de la commande.
                Veuillez nous contacter si celà se produit de nouveau.`,
      awaiting_order: `Les "Commande(s) à Placer" sont des commandes pour lesquelles le paiement de vos clients a été capturé sur Shopify et qui attendent d'être placé vers AliExpress.
                      Si vous ne voyez pas vos nouvelles commandes ici, veuillez vérifier les onglets "En Attente" et "Traitée(s)".
                      Pour savoir commande placer vos commandes vers AliExpress, vous pouvez lire <a
                      href='https://help.dsers.com/fulfill-multiple-orders-from-start-to-finish-for-shopify/'
                      target="_blank"
                      data-ga="Awaiting order - here"
                      >ici</a>.`,
      awaiting_payment: `Les "Commande(s) à Payer" sont des commandes qui ont été placé vers AliExpress, mais pas encore payés.
                        Le numéro de commande AliExpress est généré quand vous placez la commande.
                        Cliquez <a
                        href='https://help.dsers.com/pay-multiple-orders-on-aliexpress-with-dsers-for-shopify/'
                        target="_blank"
                        data-ga="Awaiting payment - here1"
                        >ici</a> pour savoir comment payer vos commandes en groupe.
                        Vous pouvez aussi apprendre <a
                          href='https://help.dsers.com/re-order-awaiting-fulfillment-order-for-shopify/'
                          target="_blank"
                          data-ga="Awaiting payment - here2"
                        >ici</a> comment annuler et re-placer un commande.
                        Si le status de la commande n'a pas changé après que vous ayez fait votre paiement, vérifiez <a
                        href='https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/'
                        target="_blank"
                        data-ga="Awaiting payment - here3"
                        >cela</a>.`,
      awaiting_shipment: `Les "Commande(s) à Livrer" sont des commandes déjà payés sur AliExpress, mais pas encore envoyé par votre fournisseur.
                          Veuillez NE PAS traiter ces commandes manuellement sur DSers, autrement, DSers ne synchronisera pas les statut ou le numéro de suivi.
                          Si le numéro de suivi ne synchronise pas après l'envoi de votre commande, veuillez lire <a
                          href='https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/'
                          target="_blank"
                          data-ga="Awaiting shipment - here"
                          >cela</a>.`,
      fulfilled: `Les commandes "Traitée(s)" sont des commandes qui ont été envoyé par votre fournisseur AliExpress et les commandes qui ont été automatiquement ou manuellement traité.
                  Lorsqu'une commande est traité, DSers ne synchronisera plus le statut ou le prix du produit depuis AliExpress.
                  Cliquez <a
                  href='https://help.dsers.com/re-order-fulfilled-orders-for-shopify/'
                  target="_blank"
                  data-ga="Fulfilled - here1"
                  >ici</a> pour savoir comment gérer les commandes traitées par erreur. Vous pouvez aussi lire <a
                  href='https://help.dsers.com/fulfill-multiple-orders-from-start-to-finish-for-shopify/'
                  target="_blank"
                  data-ga="Fulfilled - here2"
                  >ici</a> pour apprendre comment DSers traite les commandes automatiquement.<br>
                  Si vous voulez synchroniser les Numéros de Suivis depuis AliExpress, vous pouvez cliquer sur le bouton Synchroniser Numéros de Suivi des 30 derniers jours. DSers synchronisera les derniers Numéros de Suivi des commandes AliExpress des 30 derniers jours.`,
      fulfilledFirstChange:`Les commandes "Traitée(s)" sont des commandes qui ont été envoyé par votre fournisseur AliExpress et qui ont été automatiquement ou manuellement traité. `,
      fulfilledFirstChange_tit:`Lorsqu'une commande est traité, DSers ne synchronisera plus le statut ou le prix du produit depuis AliExpress. Cliquez <a target="_blank" href="https://help.dsers.com/re-order-fulfilled-orders-for-shopify/">ici</a> pour savoir comment gérer les commandes traitées par erreur. Vous pouvez aussi lire <a target="_blank" href="https://help.dsers.com/fulfill-multiple-orders-from-start-to-finish-for-shopify/">ici</a> pour apprendre comment DSers traite les commandes automatiquement.`,
      fulfilledDays:`Vous pouvez synchroniser le statut et les numéros de suivi des commandes`,
      fulfilledDays_tit:`Si vous voulez synchroniser les Numéros de Suivis depuis AliExpress, vous pouvez cliquer sur le bouton Synchroniser Numéros de Suivi des 30 derniers jours. DSers synchronisera les derniers Numéros de Suivi des commandes AliExpress des 30 derniers jours.`,
      canceled: `Les commandes "Annulée(s)" sont des commandes qui ont été (partiellement) Annulé / (partiellement) Remboursé sur AliExpress ou Shopify.
                Vous pouvez commander en groupe les commandes annulées sur AliExpress.
                Vous ne pouvez pas commander les commandes annulées ou remboursées sur Shopify.
                Si le statut des commandes ne se met pas à jour après annulation, veuillez lire <a
                href='https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/'
                target="_blank"
                data-ga="Canceled - here1"
                >cela</a>.
                Vous pouvez aussi lire <a
                href='https://help.dsers.com/re-order-aliexpress-canceled-orders-for-shopify/'
                target="_blank"
                data-ga="Canceled - here2"
                >ici</a> pour savoir comment DSers peut vous aider avec les commandes annulées par AliExpress.`,
                canceledChange:`Les commandes "Annulée(s)" sont des commandes qui ont été (partiellement) Annulé / (partiellement) Remboursé sur AliExpress ou Shopify. `,
                canceledChange_tit:`Vous pouvez commander en groupe les commandes annulées sur AliExpress. Vous ne pouvez pas commander les commandes annulées ou remboursées sur Shopify. Si le statut des commandes ne se met pas à jour après annulation, veuillez lire <a target="_blank" href="https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/">cela</a>. Vous pouvez aussi lire <a target="_blank" href="https://help.dsers.com/re-order-aliexpress-canceled-orders-for-shopify/">ici</a> pour savoir comment DSers peut vous aider avec les commandes annulées par AliExpress.`,
      failed_orders: `Les "Commande(s) Échouée(s)" sont des commandes qui n'ont pas pu être placé vers AliExpress avec succès dû à des erreurs ou des restrictions.
                      Vérifier les informations du client et corriger les erreurs afin de placer les commandes Échouées  de nouveau. Il y a une nouvelle règle concernant les commandes certains pays, veuillez cliquer <a href='https://help.dsers.com/order-requirements-for-specific-countries-for-shopify/' target="_blank" data-ga="Failed orders - here">ici</a> pour obtenir plus d'information.`,
      awaiting_fulfillment: `Les commandes "En Attente de Traitement" sont des commandes qui contiennent plusieurs produits mais qui ne sont que partiellement traité.
                            Les produits traités  seront affichés ici et une fois que tous les produits de la commande seront traités, la commande sera déplacé dans "Traitée(s)".
                            Ensuite, nous synchroniserons le numéro de suivi vers Shopify et déclencherons qu'un seul mail de confirmation d'envoi de Shopify à votre client.
                            Cliquez <a
                            href='https://help.dsers.com/awaiting-fulfillment-introduction-for-shopify/'
                            target="_blank"
                            data-ga="Awaiting fulfillment - here"
                            >ici</a> pour plus d'information.`
    },
    //  d
    alert_fulfilled_by_other: {
      w1: 'Note please. ',
      w2:
        'Please check if there are any orders already fulfilled on platforms like Oberlo or Dropified.'
    }, //  d
    //  d

    rut_banner: {
      title: 'Conditions du numéro RUT pour les commandes vers le Chili',
      btn_text: 'EN SAVOIR PLUS'
    },

    alert_sync_by_extend: {
      w1: 'Please Note: We can now synchronize AliExpress order status in',
      w2: ' real time ',
      w3: 'with two steps !',
      w4: ' Click here ',
      w5: 'to view details.'
    }, //  d
    search_type: {
      order_no: 'N° Commande',
      note: 'Note',
      ali_order_no: 'N° Commande Ali',
      tm_order_no:"N° Commande Tmall",
      product: 'Produit',
      email: 'E-mail',
      customer_name: 'Nom du Client',
      tracking_no: 'N° de Suivi',
      searchOrder:'Chercher Commande',
      agency_order_no: `Nº commande d'agence`,
    },
    search_input_placeholder:
      'Vous pouvez choisir une catégorie pour rechercher plus précisement',
    syncing: 'Synchronisation…',
    sync_ali_order: 'Sync Commande',
    sync_via_api:
      'Synchroniser via API (Vous pouvez synchroniser manuellement {length} fois par jour)', //  add
    sync_tn: 'Synchroniser Numéros de Suivi des 30 derniers jours (Vous pouvez synchroniser manuellement {length} fois par jour)',
    sync_tn_2:'Synchroniser les derniers Numéros de Suivis des 30 derniers jours via le plug-in',
    sync_via_extension:
      "Synchroniser via l'extension Chrome DSers", //  add
    syncing_ali: 'Commandes en cours de synchronisation',
    sync_ali_completed: 'Synchronisation des commandes terminée',
    select_sync_range: 'Sélectionnez les dates pour mettre à jour les Numéros de Suivi',
    sync_task_count: 'Nombre de commandes à synchroniser',
    sync_except_time: 'Temps approximatif nécessaire',
    sync_ali_failed_result: {
      title: `Les situations suivantes peuvent expliquer l'échec:`,
      reason1: `1.Le compte AliExpress où vous êtes connecté ne correspond pas au compte AliExpress que vous avez lié à DSers.`,
      reason2: '2.Vous vous êtes déconnecté de votre AliExpress durant la synchronisation.',
      reason3: '3.Une erreur réseau est survenue.',
      contact: 'Si vous avez vérifié cela mais échouez toujours, veuillez <a rel="noopener noreferrer" target="_blank" href="https://www.messenger.com/t/DSersOfficial" dd="src">contacter notre</a> service client.'
    },
    start_time: 'Heure de Début',
    end_time: 'Heure de Fin',
    tab: {
      title:
        '{key, plural, =1 {Commande(s) <br/> En Attente} =2 {Commande(s) <br />à Placer} =3 {Commande(s) <br /> à Payer} =4 {Commande(s) <br /> à Livrer} =5 {Commande(s) <br/> Traitée(s)} =6 {Commande(s) <br/> Annulée(s)} =7 {Commande(s) <br /> Échouée(s)} =9 {En Attente <br /> de Traitement} }', // modify
      all_tip:
        'Total order amount might not be equal to the sum of Awaiting Order, Awaiting Payment, Awaiting Shipment and Fullfilled Order as some order may fail.', // d
      canceled_tip:
        'Les commandes annulées sur Shopify ou AliExpress; par AliExpress, votre client ou par vous même, seront listées ici. Les commandes annulées par AliExpress peuvent être commandées de nouveau.'
    },
    place_order: {
      btn:
        '{key, plural, =1 {Placer vers AliExpress } =4 {Placer de nouveau} }',
      tm_btn:
        '{key, plural, =1 {Placer en vrac} =4 {Place order again} }',
      tip: `Vous devez mapper le produit pour que DSers sachent quel fournisseur vous utilisez avant de pouvoir faire une Commande Groupée.<br />
            <a
              href="https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/"
              target="_blank"
            >
              :Lisez cet article,
            </a>`,
      agency_btn: '{key, plural, =1 {Règlement en masse} =4 {Placer de nouveau} }',
    },
    more_action: {
      title: "Plus d'Action",
      action_menu: 'Traiter Manuellement',
      action_menu_tip:
        'Ne sera pas placée vers AliExpress, simplement marquée comme Traitée.',
      tip:
        'Si vous ne voulez PAS traiter certaines commandes avec DSers, vous pouvez les traiter manuellement ici. Cela ne changera pas leur statut sur Shopify.',
      tip_link: 'Lisez cet article'
    },
    canceled_by: {
      placeholder: 'Annulée par',
      shopify: 'Shopify',
      ae: 'AliExpress',
      "tm":"Tmall",
      agency_in_dsers: 'You on DSers'
    },
    fulfilled_by: {
      placeholder: 'Traitée par',
      shopify: 'Shopify', // add
      ae: 'DSers',
      self: 'Traitée Manuellement'
    },
    country: 'Pays',
    make_payment: {
      btn: 'Réaliser Paiement',
      h3:
        'Réalisez vos paiements sur le site officiel<br/>AliExpress pour compléter vos commandes.',
      h4: "Qu'arrive-t-il aux commandes impayées?",
      p1: '1. Vos commandes peuvent être annulées',
      p2: '2. Votre compte peut rencontrer des refus de paiement',
      h5:
        'Veuillez compléter vos paiements au plus tôt pour<br/>satisfaire vos clients!'
    },
    orderQuantityToolTip:{
      "title": "Il y a encore {items} articles dans le menu Autres commandes",
      "aliexpress": "Commandes AliExpress",
      "agency": "Commandes de l'Agence",
      "unmapped": "Commandes non répertoriées"
    },
    agency_orders: {
      upload_tn_title: 'Télécharger le numéro de suivi manuellement',
      upload_tn_sub_title: 'Une fois le suivi téléchargé, DSers retournera à Shopify et marquera la commande respective avec le statut « Exécuté ».',
      upload_file_tip: 'Cliquez ici pour télécharger la pièce jointe',
      uplaod_match_column: 'Veuillez sélectionner les champs correspondants dans le fichier Excel et nous les associerons automatiquement pour vous, puis nous les téléchargerons.',
      upload_agency_order_id: `ID de la commande de l'agence`,
      upload_product_id: `ID produit de l'article`,
      upload_sku_id: `ID SKU de l'article`,
      upload_shipping_carrier: 'Transporteur',
      upload_custom_shipping_url: 'URL de Suivi Personnalisé',
      upload_tracking_number: 'N° Suivi',
      upload_select_placeholder: 'Faites la sélection',
      upload_confirm: 'Confirmer',
      upload_payment_title: 'Importer les détails du paiement',
      upload_payment_sub_title: `Téléchargez un fichier Excel qui contient les dépenses d'achat. DSers les enregistrera pour vous afin de vous aider dans la gestion des commandes.`,
      upload_payment_currency: `Devise d'achat (USD par défaut)`,
      upload_product_cost: `Coût d'achat`,
      upload_shipping_cost: `Coût d'expédition`,
      download_order_info: 'Exporter des informations sur les commandes',
      download_by_select: '{value} commandes ont été sélectionnées',
      download_by_date: 'Exporter les commandes par date',
      download_title: 'Exporter',
      download_as: 'Exporter sous',
      export_to_file_type_excel: 'Fichier Excel ordinaire',
      download_btn: 'Exporter les commandes',
      export_orders_action: 'Export des commandes',
      import_payment_details: 'Import détails de paiement',
      import_tn_to_orders: 'Téléchargement manuel N° suivi',
      mark_as_cancel: 'Annuler les commandes manuellement',
      mark_as_cancel_title: `Confirmer l'annulation de la commande`,
      mark_as_cancel_desc: 'Veuillez noter que la commande sera marquée comme annulée sur DSers et que le statut de la commande ne sera pas synchronisé avec Shopify.',
      please_input_confirm: 'Veuillez entrer CONFIRMER.',
      mark_as_paid_title: 'Marquer la commande comme payée',
      mark_as_paid_desc: `Veuillez vous assurer que la commande a été validée hors ligne par votre fournisseur. Une fois la commande marquée manuellement comme payée, elle sera considérée comme étant en attente d'expédition.`,
      cancel_order_failed: `L'annulation de la commande a échoué. Veuillez réessayer plus tard.`,
      place_confirm_title: 'Confirmer les informations sur la commande',
      place_btn_tip: `Cliquez sur le bouton « Règlement en masse » et DSers vérifiera les informations relatives à l'adresse de votre commande.`,
      agency_canceled_tag: 'DSers annulés',
      from_agency: 'Agency',
      agency_order_number_full: 'Agency order number',
      export_failed: `L'exportation a échoué, cela peut être dû à une erreur de réseau. Veuillez réessayer plus tard.`,
      export_failed_empty: `L'exportation a échoué car il n'y a pas de commande dans la période sélectionnée. Vous pouvez sélectionner une autre période et réessayer.`,
      upload_success: 'Téléchargement réussi',
      upload_failed: 'Téléchargement échoué',
      upload_failed_reason: `Télécharger les motifs d'échec`,
      uploading: 'Téléchargement en cours',
      export_download_link_title: 'Si le téléchargement ne démarre pas automatiquement, cliquez ici',
      mark_as_paid_failed: `La commande n'a pas été marquée manuellement comme payée. Veuillez réessayer plus tard.`,
      exporting_orders: 'Exportation en cours',
      agency_fulfill_tip: 'Êtes-vous sûr de vouloir EXECUTER ces commandes manuellement?',
      agency_rollback_title: `Annulez le traitement des commandes sélectionnées et vous trouverez les commandes dans l'onglet Commande en attente. Les anciennes informations Agency des commandes seront perdues après la restauration`,
      agency_edit_tracking_no_tip: `Après avoir modifié avec succès un numéro de suivi, vous pouvez renvoyer l'email à votre acheteur en fonction de vos Réglages, veuillez faire attention.`,
      agency_auth_status_processing: 'Traitement ERP',
      agency_auth_status_offline: 'Comm. hors-ligne',
      agency_process_tag_tip: `Les informations de la commande ont été envoyées à l'ERP du fournisseur. Le temps de création est estimé à 2h. Une fois la création réussie, vous pouvez transmettre le devis d'achat au fournisseur et payer. Si le statut n'a pas été mis à jour depuis longtemps, vous pouvez également traiter la commande manuellement.`,
      erp_payment_tip_title: 'Veuillez confirmer',
      erp_payment_tip_content: `La commande payée contient une commande qui doit être créée dans l'ERP du fournisseur. Veuillez confirmer que le fournisseur est au courant de la quantité totale de la commande à traiter avant de payer.`,
      erp_auth_type_filter: 'Type',
      payment: {
        make_payment: 'Make Payment',
        make_payment_tip: 'Exécutez vos paiements pour compléter vos commandes. Veuillez exécuter votre paiement le plus rapidement possible pour satisfaire vos clients !',
        currency_diff_title: 'Attention',
        currency_diff_content: 'Veuillez vous assurer que les commandes sélectionnées sont dans la même devise pour le même fournisseur. Vous pouvez modifier la devise et retélécharger le fichier CSV ou payer par lots en fonction de différentes devises.',
        currency_diff_btn: 'COMPRIS',
        payment_modal_title: `Veuillez confirmer les informations relatives à la commande et à l'agence avant de payer.`,
        payment_1: "Due to restrictions from {type},  each order must be over $1.00. Please contact your supplier to modify.",

        edit_agency_info: `Modifier les informations de l'agence`,
        agency_info_name: `Nom de l'agence`,
        agency_info_paypal: `PayPal de l'agence`,
        agency_info_amount: 'Montant estimé du paiement',
        agency_info_amount_v2: 'Payment amount',
        agency_inpayment_tip: 'The payment is being paid, please complete the rest of the payment.',
        get_payment_detail_failed: 'Le paiement a expiré.',
      },
      order_confirm_erp_title: 'ERP',
      order_confirm_erp_tip_1: `Vous avez lié l'ERP de ce fournisseur. Une fois la commande passée, nous l'enverrons directement au fournisseur.`,
      order_confirm_erp_tip_2: `Vous n'avez pas lié l'ERP de ce fournisseur. Une fois la commande passée, vous devez exporter manuellement la commande et l'envoyer au fournisseur.`
    },
    order_title: {
      title:'Filtrer Commande',
      order_no: 'N° Commande',
      date: 'Date',
      country: 'Pays',
      income: 'Revenu',
      note: {
        title: 'Note',
        add: 'ajouter'
      },
      customer_detail: 'Détail Client',
      type:'Type de Commande'
    },
    edit_address: {
      contact_name: 'Nom Client: ',
      tel: 'Tél: ',
      email: 'Email: ',
      company: 'Compagnie: ',
      cpf: 'CPF', // add
      country: 'Pays: ',
      address: 'Adresse: ',
      province: 'Province: ',
      city: 'Ville: ',
      post_code: 'Code Postal: ',
      clearance_info: `ID d'autorisation personnelle`,
      alien_clearance_info: `Passeport / Numéro de carte d'enregistrement des étrangers:`,
      clearance_info_required:
        `Le code unique de dédouanement personnel se compose d'un numéro à 12 chiffres commençant par P. Remplissez ce champ si le client est un Coréen natif.`,
      clearance_info_tip:
        `Le code unique de dédouanement personnel se compose d'un numéro à 12 chiffres commençant par P (par exemple, P682151173203). Cliquez <a href="https://help.dsers.com/orders-to-korea-specifications-for-shopify/" target="_blank" rel="noopener noreferrer" >ici</a> pour obtenir plus d'informations.`,
      alien_clearance_info_required: `Veuillez entrer votre numéro de passeport ou de carte d'enregistrement d'étranger. Remplissez ce champ si le client n'est PAS un Coréen natif.`,
      alien_clearance_info_tip: `Format incorrect. Saisissez 1 à 13 chiffres ou lettres. Cliquez <a href="https://help.dsers.com/orders-to-korea-specifications-for-shopify/" target="_blank" rel="noopener noreferrer" >ici</a> pour obtenir plus d'informations.`,
      rut_required: 'Veuillez entrer le numéro RUT',
      rut_incorrect: 'Le numéro RUT semble incorrect',
      rut_tip:
        'Lisez notre <a href="https://help.dsers.com/orders-to-chile-specifications-for-shopify/" target="_blank" rel="noopener noreferrer" >blog</a> pour en apprendre plus',
      unknow_wrong:
        'Il y a un problème avec les informations du client. Veuillez vérifier et corriger.',
      enter_name: 'Veuillez entrer un Nom Client.',
      enter_between: 'Veuillez entrer entre {start} et {end} caractères.',
      enter_write_tel:"Veuillez entrer votre vrai numéro de téléphone ici, entrez entre 5 et 16 chiffres. Cliquez <a href='https://help.dsers.com/fix-failed-orders-due-to-aliexpress-phone-number-regulations/' target='_blank'>ici</a> pour savoir comment résoudre ce problème",
      korea_error:'Veuillez entrer un numéro de téléphone à {start} ou {end} chiffres, commençant par 01 ou 1 (ex: 01012345678)',
      italy_error:'Veuillez entrer {start} ou {end} chiffres',
      english_only: "Veuillez entrer de l'anglais uniquement.",
      max128:
        'Le total du nom du client et de la compagnie ne peut pas excéder 128 caractères.',
      only_num_hyphens_forward:
        'Veuillez entrer des Numéros, des tirets "-" et des "/" uniquement.',
      cpf_tip: `Le numéro CPF peut être incorrect. Si vous l'avez vérifié, veuillez ignorer ce message. Veuillez allez sur AliExpress pour vérifier.`,
      enter_country: 'Veuillez entrer un Pays/Région.',
      enter_address: 'Veuillez entrer une Adresse.',
      enter_address_length: "Veuillez vous assurer que votre adresse ne comporte pas moins de 5 caractères.",
      select_province: 'Veuillez choisir un État/Province/Région.',
      enter_city: 'Veuillez entrer une Ville.',
      city_not_match:
        'La ville ne correspond pas. Veuillez choisir dans le menu déroulant.',
      enter_zip: 'Veuillez entrer un ZIP/Code Postal.',
      enter_zip_1234:
        'Veuillez entrer un code postal à {length} chiffres, tel que {ps}.',
      enter_zip_123456: 'Veuillez entrer un code postal, tel que {ps}.',
      enter_zip_special:
        'Veuillez entrer un code postal au format suggére, tel que {ps}',
      enter_zip_confirm:
        'Pour les livraisons internationales, un code postal à 7 chiffres est nécessaire. Veuillez confirmez avec votre poste local si nécessaire.',
      only_num_spaces_hyphens:
        'Veuillez entrer des chiffres/lettres/espaces/caractères Latin uniquement.',
      france_phone_setting_tip:
        `Pour les commandes vers la France, il faut un numéro de téléphone à 10 chiffres. Pour les co commençant par 06.`,
      france_phone_customers_tip:
        'Pour les commandes vers la France, il faut un numéro de téléphone à 10 chiffres.'
    },
    order_product: {
      title_unarchive:`Unarchive: After order unarchived, you can find the order in the 'Open orders' tab.`,
      variants: 'Variantes:',
      from: 'De',
      from_aliexpress: 'Aliexpress',
      from_other: 'Autre',
      cost: 'Coût',
      quantity: {
        // d
        price: 'price',
        ok: 'Ok',
        cancel: '',
        max: 'Maxium'
      }, // d
      dsers_shipping: 'ePacket', //  d
      shipping_method: 'Méthode Livraison',
      shipping_dialog: {
        company: 'Transporteur',
        cost: 'Coût Livraison',
        time: 'Temps de Livraison Estimé',
        tracking: 'Information de suivi',
        price: 'Livraison Gratuite',
        days: 'jours',
        available: 'Disponible',
        unavailable: 'Indisponible',
        ok: 'Ok',
        cancel: 'Annuler',
        setting_link: 'Définissez vos méthodes de livraison dans Réglages',
        overrun_warning: `Vous avez dépassé la limite quotidienne pour accéder aux données des produits AliExpress, mais vous pouvez toujours importer et pousser des produits.`,
        async_frienght:`Mettre à jour la liste des méthodes d'envoi`,
        upcoming1:`La méthode d'expédition ne peut pas être déterminée, la raison peut en être la suivante :`,
        upcoming2:`1) La cartographie n'est pas définie pour ce produit.`,
        upcoming3:`2) Le livreur ne peut pas livrer à la destination de la commande. Veuillez confirmer l'adresse de livraison avec l'utilisateur ou utiliser la fonction Modifier"" pour passer à un fournisseur qui prend en charge la livraison à cette destination.`,
      },
      shipping_cost_free: 'gratuit',
      shipping_time: 'jours',
      ali_order_no: 'N° Commande Ali',
      tmall_order_no: "N° Commande Tmall",
      order_no: 'N° Commande',
      tracking_no: 'N° Suivi',
      tracking_no_add: 'ajouter',
      sync: 'Sync',
      ali_order_no_no_match:
        "Can't find this order to this Ali order number. Please check it.", // d
      mapping: 'Mapping',
      mapping_tip:
        'Cliquer sur Mapping pour gérer les fournisseurs de ce produit.',
      customer_service: 'Service Client',
      action: {
        title: 'Action',
        message: 'Message',
        edit: 'Éditer',
        mark_as_fulfilled: 'Marquer comme Traitée',
        bulk_edit: 'Éditer en Groupe' // d
      },
      fulfilled_by_other_tip: `Avez-vous traité ces commandes par erreur?
                               Si vous souhaitez traiter les commandes avec DSers,
                               vous ne pouvez pas les marquer manuellement comme traitée sur DSers ou Shopify.
                               Et vous devez désactiver la fonction traitement automatique des commandes sur Shopify.
                               Si vous voulez annuler le traitement des commandes,
                               veuillez cliquer sur le bouton messenger et nous contacter.
                               Please check <a
                               style="color:#FF8F00"
                                href="https://help.dsers.com/re-order-fulfilled-orders-for-shopify/"
                                target="_blank"
                                data-ga="wrongly fulfilled - here"
                              >here</a> to get step by step operation manual.`,
      ae_order_error_tip: {
        //  d
        click: 'Click',
        message_us: 'Message Us',
        why_see_this: 'Why am I seeing this?',
        ae_not_available:
          'This product is not available on AliExpress. Please go and check on AliExpress.',
        sku_not_found: 'SKU is not found. Please click',
        sku_not_found_after: 'to add an AliExpress product link.',
        no_shipped_country:
          "This product can't be shipped to to this country. Click&nbsp;",
        no_shipped_country_after: '&nbsp;to change an AliExpress product.',
        freight_error: `Veuillez entrer les détails de l'adresse de la commande pour pouvoir choisir la méthode de livraison`,
        set_shipping: {
          p1: 'Choose a',
          p2: 'shipping method',
          p3: 'to active order feature.',
          p4: 'Set up shipping method in Setting panel'
        },
        add_ae_link: {
          p1: 'Add AliExpress link successfully! Choose a',
          p2: 'shipping method',
          p3: 'to active order feature.'
        },
        add_ae_link_succ: 'Add AliExpress link successfully !',
        delete_by_shopify: 'The product(s) was deleted from shopify'
      }
    },
    bind_product: {
      ae_url: 'URL AliExpress',
      ae_url_tip: "Entrez l'URL du produit AliExpress",
      tmall_url_tip: "Entrez l'URL du produit Tmall",
      from_aliexpress: "D'AliExpress",
      cost: 'Coût ',
      select_varint:
        'Les variantes actuelles sont <b>{num}</b> Veuillez choisir la variante correspondante:', // modify
      overrun_warning: `Vous avez dépassé la limite journalière d'accès aux données du produit`
    },
    order_foot: {
      cost: 'Coût Produit',
      shipping: 'Livraison',
      total: 'Coût Total',
      pending_authorized: 'Authorized',
      pending_authorized_tip: 'This order need to capture payment on Shopify.',
      shopify_deleted: 'Shopify deleted',
      is_shopify_delete:
        'Order deleted on Shopify. If you still want to process the order, please create a new similar order on Shopify.',
      product_cost_tip: {
        title: 'Certaines raisons peuvent expliquer la différence de coût:',
        p1:
          '1. DSers montre uniquement le coût standard basique sur AliExpress. Mais le coût réel peut varier en fonction du pays de destination.',
        p2:
          `2. DSers n'affiche pas d'autres devises pour le moment, uniquement USD. Veuillez vous assurez que la devise est la même sur DSers et AliExpress quand vous vérifiez le coût d'un produit.`,
        p3:
          `3. DSers ne met pas à jour le coût des produits pour les commandes Complétées et En Attente. Une fois que le statut d'une commande est Commande à Placer, le coût du produit sera mis à jour automatiquement.`,
        p3_1:
          'Veuillez noter que le coût du produit sera mis à jour toutes les 24h sur DSers.',
        p4:
          "4. Des taxes s'appliquent pour les commandes à destination de certains pays, comme les États-Unis et l'Australie",
        p_end:
          'Le coût correct du produit apparaitra une fois que vous avez placé la commande vers AliExpress.'
      },
      shipping_tip: `Le coût final de livraison affiché ici sera le même que sur les commandes AliExpress et dépendra de vos fournisseurs. Quand une promotion s'applique à vos commandes AliExpress, la promotion sera appliquée dans le coût de livraison ici.
                    <a
                      href="https://help.dsers.com/shipping-fees-on-dsers-for-shopify/"
                      target="_blank"
                    >
                      Veuillez lire le blog pour plus d'informations.
                    </a>`,
      order_again: 'Commander de nouveau',
      order: 'Commander',
      order_tip: `Vous devez mapper le produit pour que DSers sache avec quel fournisseur vous travaillez avant de placer des commandes groupées.<br />
                  <a
                    href="https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/"
                    target="_blank"
                  >
                    Lisez cet article
                  </a>`,
      tax_title: 'Taxes',
      other_title: 'Autre',
      tax_tip: `Si vous voulez que les taxes apparaissent ici, veuillez utiliser  
      <a
        href="https://www.dsers.com/dsers-chrome-extension/"
        target="_blank"
      >
        DSers Chrome Extension
      </a> pour placer la commande.`,
      shipping_tip_ext: `Les frais de port finaux indiqués ici seront les mêmes que sur les commandes AliExpress et dépendront de vos fournisseurs.`,
      other_tip_ext: `Lorsqu'une remise s'applique à vos commandes AliExpress, le montant de la remise sera compté ici.`,
      order_displayed:`Le prix de la commande affiché n'est pas en USD. DSers converti en USD en fonction du taux de change. Le montant peut être différent du véritable prix sur AliExpress. DSers affiche cela pour information uniquement, cela n'affectera pas le montant à payer. Veuillez vérifier sur AliExpress pour voir le véritable montant à payer`
    },
    sync_order_alert: `DSers synchronisera automatiquement depuis Shopify et AliExpress quand vous vous connectez à DSers.
                       La synchronisation durera 10min. DSers synchronisera aussi toutes les 12h pour assurer une meilleure synchronisation.`, //  add
    cpf_alert: `En vertu de nouvelles régulations,
                toutes les commandes pour le Brésil doivent contenir le numéro CPF, ou les commmandes échoueront.
                DSers fera apparaître le numéro CPF sur les paquets.
                Vous pouvez ajouter le numéro CPF de votre client dans le champ "Compagnie", dans Détails Client.`, //  add
    time_zone_placeholder: 'Fuseau Horaire', //  add
    af_more_action: 'Synchroniser le numéro de suivi vers Shopify', //  add
    shopify_fulfilled: 'Traité sur Shopify', //  add
    shopify_canceled: 'Annulé sur Shopify', //  add
    shopify_partially_fulfilled: 'Traité partiellement sur Shopify', //  add
    shopify_partially_canceled: 'Annulé partiellement sur Shopify', //  add
    shopify_refunded: 'Remboursé sur Shopify', //  add
    ali_canceled: 'Annulé sur AliExpress',
    tm_canceled: 'Tmall canceled',
    zero_tip: `La demande de traitement manuelle sur Shopify à causer le problème.
               Veuillez traitrer cette commande manuellement sur DSers,
               puis annuler le traitement de la commander.
               La quantité sera mise à jour.`, //  add
    err_deleted_in_shopify: 'Ce produit a été supprimé sur Shopify', //  add
    err_may_be_blacklisted:
      `La commande a échoué pour quatre raisons possibles.`, // add
    err_may_be_listed_5:`
      1. Vous ne pouvez acheter qu'un nombre limité de ce produit, veuillez contacter le fournisseur pour vérifier, ou changer le fournisseur de ce produit pour pouvoir placer la commande.<br/>
      2. La commande est pour la France et la valeur des produits du fournisseurs dépasse 150 EUROS, veuillez changer le fournisseur ou vous inscrire en tant que Business Buyer sur AliExpress.<br/>
      3. Le lien du produit du fournisseur n'est pas disponible.<br/>
      4.La commande contient des fournisseurs AliExpress nécessitant des devises différentes pour le paiement, veuillez commander les articles séparément.
    `,
    err_france:
      'Veuillez remplacer "France" dans le champ "Pays" par "{ Guadeloupe }" à cause des régles d\'AliExpress', // add
    err_out_of_stock:
      'Produit en rupture de stock. veuillez contacter votre fournisseur ou changer de fournisseur.', // add
    awaiting_error_tip11:"En raison de restriction d'achat du fournisseur principal, votre commande a été passée auprès du fournisseur secondaire. Si vous souhaitez modifier le fournisseur, veuillez annuler la commande et modifier manuellement le fournisseur mappé dans l'onglet Annulé",
    awaiting_error_tip13:"Votre commande a été passée via le fournisseur secondaire car le fournisseur principal n'a pas de stock. Si vous souhaitez modifier le fournisseur, veuillez annuler la commande et modifier manuellement le fournisseur mappé dans l'onglet Annulé",
    awaiting_error_tip14:"Le fournisseur principal ne prend pas en charge la livraison vers la destination, votre commande a été passée via le fournisseur secondaire. Si vous souhaitez modifier le fournisseur, veuillez annuler la commande et modifier manuellement le fournisseur mappé dans l'onglet Annulé.",
    awaiting_error_tip15:"Le fournisseur principal vous a blacklisté, votre commande a été passée via le fournisseur secondaire. Si vous souhaitez modifier le fournisseur, veuillez annuler la commande et modifier manuellement le fournisseur mappé dans l'onglet Annulé.",
    awaiting_error_tip21:"Le SKU du fournisseur principal ne peut pas être vendu, votre commande a été passée via le fournisseur secondaire. Si vous souhaitez modifier le fournisseur, veuillez annuler la commande et modifier manuellement le fournisseur mappé dans l'onglet Annulé.",
    awaiting_error_tipOther:"Il y a un problème avec le fournisseur principal et votre commande a été passée via le fournisseur secondaire. Si vous souhaitez modifier le fournisseur, veuillez annuler la commande et modifier manuellement le fournisseur mappé dans l'onglet Annulé.", 
    tmall_error_code1:'Le système est surchargé, veuillez réessayer plus tard ou cliquez pour contacter le service client.', 
      tmall_error_code2: '',
      tmall_error_code3:`Le mode de livraison de ce produit n'est pas valide, veuillez réessayer de passer la commande.`,
      tmall_error_code4:`La quantité achetée n'atteint pas la quantité minimale d'achat, cliquez pour contacter le service clientèle ou changer de fournisseur.`, 
      tmall_error_code5: 'Ce produit ne peut actuellement pas être acheté par PC, cliquez pour contacter le service client ou changer de fournisseur.',
      tmall_error_code6: 'Ce produit est insuffisant en stock, cliquez pour contacter le service client ou changer de fournisseur.',
      tmall_error_code7:'Ce produit est temporairement indisponible dans cette région, cliquez pour contacter le service client ou changer de fournisseur.', 
      tmall_error_code8: `Ce produit est temporairement indisponible pour l'achat, veuillez essayer à nouveau et repasser la commande`,
      tmall_error_code9:`Ce produit est un produit actif, et l'événement n'a pas encore commencé, cliquez pour contacter le service client ou changer de fournisseur.`,
      tmall_error_code10:`La quantité achetée dépasse la limite d'achat, cliquez pour contacter le service clientèle ou changer de fournisseur.`, 
      tmall_error_code11: `La déduction de l'inventaire du produit a échoué, veuillez réessayer et repasser la commande`,
      tmall_error_code12: `Ce produit est temporairement indisponible pour l'achat, cliquez pour contacter le service client ou changer de fournisseur.`,
      tmall_error_code13:`Impossible d'obtenir le prix du produit, veuillez réessayer et repasser la commande.`,
      tmall_error_code14:'', 
      tmall_error_code15: 'Des coupons expirés ont fait échouer cette commande. Veuillez supprimer les coupons et réessayer.',
      tmall_error_code16: `Aucune méthode d'expédition n'est actuellement disponible pour ce produit. Cliquez pour contacter le service client ou changer de fournisseur.`,
      tmall_error_code17:'', 
    err_may_be_out_of_stock:
      'Votre fournisseur peut être en rupture de stock pour cette commande. Veuillez aller sur AliExpress et vérifier.', // add
    err_limit: `Vous ne pouvez acheter que { limit } produit(s) de ce fournisseur, veuillez le contacter pour vérifier, ou changer le fournisseur de ce produit pour pouvoir placer la commande.`,
    err_limit_blur:
      `Vous ne pouvez acheter qu'un nombre limité de ce produit, veuillez contacter le fournisseur pour vérifier, ou changer le fournisseur de ce produit pour pouvoir placer la commande.`,
    err_not_available:
      'Produit indisponible sur AliExpress, veuillez changer de fournisseur pour ce produit.', // add
    err_not_shipping: `La province sélectionnée dans Shopify est définie comme un pays sur AliExpress, veuillez modifier manuellement la destination dans la section Détails Client de DSers. Si le produit ne peut pas être expédié vers le pays de destination, nous vous recommandons de changer de fournisseur.`, // add
    err_blacklisted:
      'Votre fournisseur vous a mis sur liste noire. Veuillez contacter votre fournisseur ou changer de fournisseur.', // add
    err_un_mapped: `Ce produit n'est plus mappé ou partiellement mappé, veuillez mapper le(s) fournisseur(s) sur DSers.
                    Cliquez <a href="https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/" target="_blank">ici</a> pour en apprendre plus.`,
    err_shipping_to_china: `Veuillez noter qu'AliExpress ne supporte pas l'envoi de produit vers la Chine.`,
    err_shipping_to_RU: `A cause de limitations d'AliExpress, DSers ne supporte pas le placement des commandes pour la Russie pour le moment.
                            Veuillez placez cette commande manuellement sur AliExpress puis copier le numéro de commande AliExpress sur la commande dans DSers.`,
    err_shipping_address:"L'adresse Shopify ou votre commande peut être incompatible avec l'adresse AliExpress. La méthode d'expédition affichée ici sera celle utilisée dans le pays de destination AliExpress.",
    ae_account_disabled:
      'Votre compte AliExpress a été désactivé par AliExpress. Veuillez créer un nouveau compte AliExpress et le lier.',
    err_extra_address: `Détails du Client incorrect ou manquant,
                        veuillez vérifier le message d'erreur et corriger en conséquence.`,
    err_add_address_ukraine:"(Si la destination est maintenant une zone de guerre, vous ne pourrez pas passer la commande)",
    err_address_yg:`Il est recommandé que vous remplaciez "{country}" par United Kingdom dans le champ Pays. Veuillez entrer "{countrys}" dans le champ Province`,
    qty_zero_tip: `Fulfillment of the order was requested on Shopify. Since Request fulfillment feature does not comply with how DSers process orders, please modify the order based on the guide in the blog. Click <a href="https://help.dsers.com/re-order-request-fulfillment-order-for-shopify/" target="_blank">here</a> to get more information`,
    ae_tip_IN_error:`Pour le moment, les commandes pour l'Inde ne peuvent pas être passées avec succès. Veuillez cliquer <a href="https://customerservice.aliexpress.com/knowledgeDetail?categoryId=21039549&hcAppId=1248&hcFromCode=Xn2Tk1tb7&hcSessionId=26-1248-70544a7b-e1ed-41af-8a2d-1fa5a625173e&knowledgeId=627712&spm=service_hall.24300488.searchresult.627712）" target="_blank">ici</a> pour plus d'informations.`,
    ae_code_11_error: `Le coût de la commande pour la France dépasse 150 Euros. Selon la réglementation, vous devez entrer les informations relatives à la TVA. Veuillez cliquez ici <a href="/app/settings?tabChangeSta=7">pour</a> accéder aux réglages. Cliquez ici <a href="https://www.blog.dserspro.com/post/orders-to-france-specifications-with-shopify-dsers" target="_blank">pour</a> obtenir plus d'information.`,
    ae_code_11_error_1: `Le coût de la commande pour la France dépasse 150 Euros. Selon la réglementation, vous devez entrer les informations relatives à la TVA. Veuillez cliquez <a href="/app/settings?tabChangeSta=7">ici</a> pour accéder aux réglages. Cliquez <a href="https://help.dsers.com/orders-to-france-specifications-for-shopify/" target="_blank">ici</a> pour obtenir plus d'information.`,
    ae_code_11_error_btn: `Des coupons expirés ont causé l'échec de la commande. Veuillez supprimer les coupons et réessayer.`,
    ae_code_11_error_btn_ae:`Ces raisons possibles ont fait échouer cette commande:<br/>
    1. Des coupons expirés ont fait échouer cette commande. Veuillez retirer les coupons et réessayer.<br/> 
    2. Votre compte AliExpress était sous contrôle de risques, veuillez connecter un autre compte AliExpress pour réessayer ou <a href="https://www.messenger.com/t/DSersOfficial" target="_blank" >contacter</a> notre support.`,
    ae_code_11_error_2: 'Le système a détecté que votre comptre AliExpress ne peut pas placer de commandes normalement, veuillez changer de compte AliExpress lié (cliquez <a href="/app/settings?tabChangeSta=1">ici</a> pour changer).',
    ae_code_11_error_3: `Votre commande a recontré une erreur inconnue, veuillez <a href="https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2FDSersOfficial" target="_blank" >contacter le service</a> client.`,
    ae_code_11_error3:`Le système a détecté que votre comptre AliExpress ne peut pas placer de commandes normalement, veuillez changer de compte AliExpress lié (cliquez <a href="/app/settings?tabChangeSta=14">ici</a> pour changer).`,
    ae_code_11_error4:`Votre commande a rencontré une erreur inconnue ou vous avez arrêté manuellement la commande, si vous avez des questions, veuillez <a href="https://www.messenger.com/t/DSersOfficial" dd="src" target="_blank">contacter le service client</a>.`,
    ae_code_11_error4_extension:`Your order encountered an unknown error or you manually terminated the order, if you have any question, please <a href="https://www.messenger.com/t/DSersOfficial" dd="src" target="_blank">contact customer service</a>.`,
    ae_code_14_error:`La méthode de livraison que vous avez choisi n'est pas pris en charge, veuillez modifier la méthode de livraison`,
    ae_code_27_error: `A cause de limitations d'AliExpress, DSers ne supporte pas le placement des commandes pour la Russie pour le moment. Veuillez placez cette commande manuellement sur AliExpress puis copier le numéro de commande AliExpress sur la commande dans DSers.`,
    err_sku_change: 
      'Votre fournisseur a modifié le SKU, DSers mettra à jour les informations du produit toutes les 2 heures, veuillez re-mapper le produit et réessayer plus tard.',
    supplier_account_disabled: 'Comple du fournisseur désactivé',
    err_ae_fake_failed: `En raison de changement récent de l'interface AliExpress, cette commande est peut-être déjà placé avec succès. Rendez-vous sur AliExpress pour vérifier. Si la commande a été placé avec succès, veuillez entrer le numéro de commande manuellement. Si la commande échoue, veuillez placer la commande de nouveau`,
    currency_not_supported: 'Devise choisie non supportée',
    cpt_tip: 'Veuillez entrer un numéro CPF à 11 chiffres valide',
    coupon_expired: 'Promotion ou coupon expiré',
    duplicated_order: 'Commande dupliquée',
    err_varients_change:
      'Une variante du produit a changé, veuillez re-mapper le produit, puis placer la commande',
    place_order_timeout:
      'Commande expiré, rendez-vous sur AliExpress pour vérifier si la commande a été placé avec succès. Si la commande a bien été placé, veuillez entre manuellement les numéros de la commande AliExpress, Si la commande échoue, veuillez placer la commande de nouveau',
    order_processing: 'Traitement de commande…', //  add
    order_reault_failed: 'Des commandes ont échoué! Veuillez vérifier.', //  add
    order_reault_succ: 'Allez payer sur AliExpress!', //  add
    order_reault_await_length: 'commandes en cours', //  add
    order_reault_is_await_length:"commandes en cours",
    order_reault_await_title: 'Passage de commande(s)...', 
    order_reault_succ_title: 'Commande(s) placée(s)', 
    order_reault_succ_length: 'Passées avec succès', //  add
    order_reault_failed_length: 'Échec de la commande', //  add
    order_reault_makepayment: 'Effectuer le Paiement', //  add
    order_reault_checkfailed: 'Contrôler les commandes échouées', //  add
    order_reault_checkfailed1:'Vérifier les commandes non exécutées',
    order_reault_fail_title:'Certaines commandes ont échoué',
    roll_back_btn: 'Annuler traitement des commandes', //  add
    roll_title: `Annulez le traitement des commandes sélectionnées et vous trouverez les commandes dans l'onglet Commande en attente. Les anciennes informations AliExpress des commandes seront perdues après la restauration`, //  add
    roll_title_tm:"Annulez le traitement des commandes sélectionnées et vous trouverez les commandes dans l'onglet Commande en attente. Les anciennes informations Tmall des commandes seront perdues après la restauration.",
    roll_desc: 'Annuler traitement de commandes',
    place_order_confirm_tooltip:`Veuillez d'abord sélectionner une méthode d'envoi pour toutes les commandes avant de confirmer.`,
    archive_title:'Commandes archivées',
    archive_desc:`Une fois qu'une commande est archivée, la commande disparaîtra de l'onglet actuel. Si vous souhaitez voir une commande archivée, veuillez vous rendre sur la page Commandes archivées.`,
    global_place_order: 'Commander',
    global_place_order_tip: 'Place les commandes vers AliExpress.',
    confirm: {
      tm_title:'Veuillez vérifier les infos de livraison avant de placer les commandes vers Tmall.',
      title:
        'Veuillez vérifier les infos de livraison avant de placer les commandes vers AliExpress.',
      title_succ: 'Réussi !',
      title_fail: 'Cliquez pour Réviser les commandes ayant échoué.',
      table_title: {
        order: 'Commande',
        product: 'Produit',
        income: 'Revenu',
        product_cost: 'Coût Produit',
        country: 'Pays',
        shipping_method: 'Méthode de livraison',
        shipping_fee: 'Coût de livraison',
        shipping_time: 'Durée de livraison',
        feedback: 'Revue' //  d
      },
      error_msg: {
        //  d
        err_101: 'The product(s) was deleted from shopify',
        err_0: 'Click Mapping to add an AliExpress product link.',
        ae_has_product_no:
          'This product is not available on AliExpress. Please go and check on AliExpress.',
        child_cost_0:
          'SKU is not found. Please click Mapping to add an AliExpress product link.',
        express_length_0:
          "This product can't be shipped to to this country. Click Mapping to change an AliExpress product.",
        freight_math_info_no:
          'Choose a shipping method to active order feature.',
        freight_math_info_no_bind:
          'Add AliExpress link successfully! Choose a shipping method to active order feature.'
      }, //  d
      item: '{ num, plural, =1 {Item} other {Items} }',
      item_new:"Item(s)",
      confirm: 'Confirmer',
      income_tip:
        "Le revenu indiqué ici n'inclut pas les promotions. Il peut varier du montant réel.",
      total_cost: 'Coût total',
      revise: 'Revise', //  d
      make_payment: 'Make payment' // d
    },
    fulfill_confirm: {
      title:
        'Marquer les commandes sélectionnées comme traitées',
      table_title: {
        order: 'Commande',
        product: 'Produit',
        ae_order_number: 'N° Commande AliExpress',
        feedback: 'Feedback'
      },
      item: 'orders',
      ok: 'Ok',
      mark_as_fulfilled: 'Marquer comme Traitée',
      confirm_title:
        'Êtes-vous sûr de vouloir traiter manuellement ces commandes?',
      confirm_dec: `Êtes-vous sûr de vouloir TRAITER ces commandes?
                    <b>Veuillez noter que Dsers ne synchronisera plus les informations des commandes qui ont été placé vers AliExpress</b>`,
      continue: 'Continuer',
      checkTitle: 'Marquer cette commande comme traitée sur Shopify également'
    },
    bind_ae_dialog: {
      title: 'Lier à AliExpress pour placer des commandes groupées',
      shopify: 'Shopify',
      dsers: 'DSers',
      go_ae_tip: 'Vous serez redirigé vers AliExpress',
      go_ae: 'Lier AliExpress'
    },
    message_dialog: {
      title: 'Laisser un message aux fournisseurs AliExpress',
      tm_title:"Laisser un message aux fournisseurs Tmall",
      desc:
        'Les Fournisseurs AliExpress verront ce message avec vos commandes.',
      tm_desc:"Les Fournisseurs Tmall verront ce message avec vos commandes.",
      placeholder: 'Entrez maximum 1000 caractères',
      save: 'Sauvegarder',
      title_new: 'Laissez un message aux fournisseurs',
      desc_new: 'Les fournisseurs recevront ce message avec les informations relatives à votre commande.',
    },
    go_ae_pay_dialog: {
      //  d
      h3:
        'Make your payments on AliExpress<br/>Official website to complete your orders.',
      h4: 'What happens for overdue payment?',
      p1: '1. Your orders might get canceled',
      p2: '2. Your account might experience payment failure',
      h5: 'Please complete your payment asap to satisfy<br/>your customers!',
      no_show: "Don't show this again",
      make_payment: 'Make payment',
      mark_as_paid: 'Mark as paid'
    }, //  d
    product_error_msg: {
      //  d
      can_not_shipped_this_country:
        "The product can't be shipped to this country. Add anther AliExpress link."
    }, //  d
    options_different_tip:
      'Remind : Variant option does not match up.Please double check.', //  d
    roll_back_confirm_title:
      'Êtes-vous complètement sûr de vouloir faire cela?',
    roll_back_confirm_tip:
      'Veuillez entrer CONFIRMER pour annuler le traitement des commandes.',
    roll_back_awaiting_shopify_hook_tip: `Dsers annule le traitement sur Shopify,
                                          les commande seront déplacées vers l'onglet "Commande(s) à Placer" dans quelques secondes, veuillez patienter.`,
    no_data: {
      no_data: 'Pas de donnée',
      title:
        "Votre recherche  n'a pas de résultat à cause d'une des raisons suivantes:",
      p4:
        '1. Si vous avez Archivé la commande dans Shopify, veuillez vous rendre dans le menu Archive de DSers pour trouver la commande',
      p1: "2. L'information entré n'est pas correcte",
      p2:
        "3. La recherche n'est pas dans la plage de date sélectionnée actuellement",
      p3: '4. La recherche concerne un produit Caché',
      Pendings:{
        title:'Pending orders will be displayed here',
        detail:`Orders in the Pending tab are orders for which the payment from your customers has not yet been captured in Shopify.`
      },
      AwaitingOrders:{
        title:'Orders awaiting to be placed will be displayed here',
        detail:`Orders in the Awaiting order tab are orders for which the payment from your customers has been captured on Shopify and are waiting to be processed to Tmall.`
      },
      AwaitingPayments:{
        title:'Awaiting payment orders will be displayed here',
        detail:`Orders in the Awaiting payment tab are orders that have been placed on Tmall but not paid yet. `
      },
      AwaitingShipments:{
        title:'Awaiting shipment orders will be displayed here ',
        detail:`Orders in the Awaiting shipment tab are orders that have been paid on Tmall but have not been shipped yet.`
      },
      AwaitingFulfillments:{
        title:'Awaiting fulfillment orders will be displayed here',
        detail:'Orders in the Awaiting fulfillment tab are orders only partially fulfilled.'
      },
      Fulfilleds:{
        title:'Fulfilled orders will be displayed here',
        detail:`Orders in the Fulfilled tab are orders that have been shipped out by your Tmall supplier and orders that were automatically or manually fulfilled.`
      },
      Canceleds:{
        title:'Canceled orders will be displayed here',
        detail:'Orders in the Canceled tab are orders that have been (partially) canceled / (partially) refunded on Tmall or Shopify.'
      },
      FailedOrders:{
        title:'Failed orders will be displayed here',
        detail:'Orders in the Failed tab are orders which could not be placed to Tmall successfully due to errors or restrictions.'
      },
      Pending:{
        title:'Les commandes en attente seront affichées ici',
        detail:`Les commandes dans l'onglet En attente sont des commandes pour lesquelles les paiements de vos clients n'ont pas encore été enregistrés dans Shopify. Cliquez <a href={href} target="_blank">ici</a> pour en savoir plus sur les commandes figurant dans l'onglet En attente.`
      },
      AwaitingOrder:{
        title:'Les commandes en cours de traitement apparaîtront ici',
        detail:`Les commandes de l'onglet Commandes en attente sont des commandes pour lesquelles le paiement de vos clients a été enregistré dans Shopify et qui attendent d'être traitées par AliExpress. Cliquez <a href={href} target="_blank">ici</a> pour en savoir plus sur les commandes figurant dans l'onglet Commande en attente.`
      },
      AwaitingPayment:{
        title:'Les commandes en attente de paiement apparaîtront ici',
        detail:`Les commandes de l'onglet En attente de paiement sont des commandes qui ont été passées sur AliExpress mais qui n'ont pas encore été payées. Cliquez <a href={href} target="_blank">ici</a> pour en savoir plus sur les commandes de l'onglet En attente de paiement.`
      },
      AwaitingShipment:{
        title:`Les commandes en attente d'expédition apparaîtront ici`,
        detail:`Les commandes de l'onglet En attente d'expédition sont des commandes qui ont été payées sur AliExpress mais qui n'ont pas encore été expédiées. Cliquez <a href={href} target="_blank">ici</a> pour en savoir plus sur les commandes en attente d'expédition.`
      },
      AwaitingFulfillment:{
        title:`Les commandes en attente d'exécution apparaîtront ici `,
        detail:`Les commandes de l'onglet En attente d'exécution sont des commandes partiellement exécutées. Cliquez <a href={href} target="_blank">ici</a> pour en savoir plus sur les commandes de l'onglet En attente d'exécution.`
      },
      Fulfilled:{
        title:`L'onglet Commandes exécutées apparaît ici `,
        detail:`Les commandes de l'onglet Exécutées sont des commandes qui ont été expédiées par votre fournisseur AliExpress et des commandes qui ont été exécutées automatiquement ou manuellement. Cliquez <a href={href} target="_blank">ici</a> pour en savoir plus sur les commandes de l'onglet Exécutées.`
      },
      Canceled:{
        title:'Les commandes annulées apparaîtront ici ',
        detail:`Les commandes de l'onglet Annulées sont des commandes qui ont été (partiellement) annulées / (partiellement) remboursées sur AliExpress ou Shopify. Cliquez <a href={href} target="_blank">ici</a> pour en savoir plus sur les commandes de l'onglet Annulées.`
      },
      FailedOrder:{
        title:'Les commandes échouées apparaîtront ici ',
        detail:`Les commandes de l'onglet Échec sont des commandes qui n'ont pas pu être envoyées à AliExpress en raison d'erreurs ou de restrictions. Cliquez <a href={href} target="_blank">ici</a> pour en savoir plus sur les commandes de l'onglet Échec.`
      }
    },
    agency_open_no_data: {
      Pending:{
        title:'Pending orders will be displayed here',
        detail:`Orders in the Pending tab are orders for which the payment from your customers has not yet been captured in Shopify. `
      },
      AwaitingOrder:{
        title:'Orders awaiting to be placed will be displayed here',
        detail:`Orders in the Awaiting order tab are orders for which the payment from your customers has been captured on Shopify and are waiting to be processed to agencies. `
      },
      AwaitingPayment:{
        title:'Awaiting payment orders will be displayed here',
        detail:`Orders in the Awaiting payment tab are orders that you need to make payment to agencies.`
      },
      AwaitingShipment:{
        title:'Awaiting shipment orders will be displayed here ',
        detail:`Orders in the Awaiting shipment tab are orders that paid to suppliers through DSers, or marked as paid manually and awaiting for shipment.`
      },
      AwaitingFulfillment:{
        title:'Awaiting fulfillment orders will be displayed here',
        detail:'Orders in the Awaiting fulfillment tab are orders only partially fulfilled.'
      },
      Fulfilled:{
        title:'Fulfilled orders will be displayed here',
        detail:`Orders in the Fulfilled tab are orders that have been shipped out by your agencies and orders that were automatically or manually fulfilled. `
      },
      Canceled:{
        title:'Canceled orders will be displayed here',
        detail:'Orders in the Canceled tab are orders that have been (partially) canceled / (partially) refunded on Shopify or by agencies. '
      },
      FailedOrder:{
        title:'Failed orders will be displayed here',
        detail:'Orders in the Failed tab are orders which could not be placed to agencies successfully due to errors or restrictions.'
      }
    },
    open_no_data: {
      title: 'Vous ne trouvez pas vos commandes à placer vers AliExpress?',
      p1: '2. Veuillez vérifier l\'onglet "Traitée(s)"',
      p2: '3. Si vos commandes sont dans "Traitée(s)"',
      p3:
        '4. Rendez-vous dans Shopify - Paramètres -Paiement - Traitement des commandes, et activez "Ne Traiter automatiquement aucune des rubriques de la commande" comme montré ci-dessous',
      p4: "5. Assurez-vous d'Enregistrer pour sauvegarder les paramètres",
      p5: `6. Rendez-vous dans l'onglet Traité(e)s et cliquez sur le bouton "Plus d'action" pour annuler le traitement d'une commande sélectionnée.`,
      p6:
        '1. Si vous avez Archivé la commande dans Shopify, veuillez vous rendre dans le menu Archive de DSers pour trouver la commande',
        Pending:`Cliquez <a href={href} target="_blank">ici</a> pour en savoir plus sur les commandes figurant dans l'onglet En attente.`,
        AwaitingOrder:`Cliquez <a href={href} target="_blank">ici</a> pour en savoir plus sur les commandes figurant dans l'onglet Commande en attente.`,
        AwaitingPayment:`Cliquez <a href={href} target="_blank">ici</a> pour en savoir plus sur les commandes de l'onglet En attente de.`,
        AwaitingShipment:`Cliquez <a href={href} target="_blank">ici</a> pour en savoir plus sur les commandes en attente d'expédition.`,
        AwaitingFulfillment:`Cliquez <a href={href} target="_blank">ici</a> pour en savoir plus sur les commandes de l'onglet En attente d'exécution.`,
        Fulfilled:`Cliquez <a href={href} target="_blank">ici</a> pour en savoir plus sur les commandes de l'onglet Exécutées.`,
        Canceled:`Cliquez <a href={href} target="_blank">ici</a> pour en savoir plus sur les commandes de l'onglet Annulées.`,
        FailedOrder:`Cliquez <a href={href} target="_blank">ici</a> pour en savoir plus sur les commandes de l'onglet Échec.`
    },
    dsers_product_deleted:
      'Produit supprimé sur Dsers, veuillez cliquer <a href={href} target="_blank">ici</a> pour savoir comment placer la commande avec Dsers. ',
    variant_delete_tip:
      'Variante supprimée sur Shopify. Cliquez sur "Mapping" pour ajouter un fournisseur à la variante supprimée.',
    YANWEN_JYT_shipping_tip:
      "La méthode de livraison sélectionnée n'est plus disponible sur AliExpress. Veuillez vérifier avec votre fournisseur et choisir une méthode de livraison.",
    err_deleted_in_dsers:
      'Ce produit a été supprimé sur Dsers, veuillez cliquer <a href={href} target="_blank">ici</a> pour savoir comment placer la commande avec Dsers.',
    pending_tip:
      'Veuillez vous assurer que vous avez capturé le paiement des commandes sur Shopify. Si une commande est toujours "En Attente" après la capture du paiement, veuillez cliquer',
    pending_tip_end:
      'pour savoir comment mettre à jour le statut manuellement.',
    ae_sync_not_done: `Une synchronisation en cours n'est pas terminée`,
    ae_sync_more_then_5:
      'Vous ne pouvez pas synchroniser manuellement plus de 5 fois par jour.',
    select_shipping_method_tip: 'Veuillez choisir une méthode de livraison.',
    ful_with_tracking: 'Traité avec un numéro de suivi',
    ful_by_shopify: 'Traité par Shopify',
    ful_manually: 'Traité manuellement sur Dsers',
    partial_completion_of_order: `Votre fournisseur a envoyé plusieurs colis pour ce produit. Nous traiterons cette commande quand tous les numéros de suivi seront synchronisés.`,
    ae_splited_order:
      'La commande est divisée en multiples commandes sur AliExpress. Si vous remarquez une différence dans les numéros de commande, veuillez vérifier et changer manuellement',
    freight_not_supported: `La méthode de livraison sélectionnée n'est pas disponible pour ce pays, veuillez en choisir une autre. Cliquez <a target="_blank" href="https://www.dsers.com/blog/aliexpress-shipping-methods-changes/" rel="noopenner noreferrer" >ici</a> si vous avez besoin d'aide.`,
    edit_sp_tooltip:
      'Vous avez édité la commande en cliquant sur Action - Éditer.Si vous souhaitez changer le fournisseur de cette commande, veuillez utiliser la fonction Action - Éditer.',
    dsers_action_edit: 'DSers Action Éditer',
    variant_delete_tag: 'Variante Shopify Supprimée',
    go_ae_check_order: 'Go to AliExpress',
    may_be_fake_failed_order1: 'Votre commande ',
    may_be_fake_failed_order2:
      ' a peut-être déjà été placé avec succès. Rendez-vous sur AliExpress pour vérifier.',
    order_fulfill: `Le traitement de la commande a échoué, `,
    token_authorize_info: `Le token de la boutique actuelle a expiré, `,
    order_onHold_info: "Traiter manuellement les commandes",
    order_onHold_info_tip: "Synchronisation",
		order_onHold_snync_errorMess:'Votre commande est en attente dans Shopify et ne peut pas être traitée automatiquement. Veuillez vous rendre sur Shopify pour modifier le statut en attente et réessayer manuellement',
    order_onHold_snync_tip:"Cliquez sur ce bouton pour synchroniser les numéros de suivi vers votre boutique et traiter cette commande sur Shopify",
    order_pending: `La commande est en cours de traitement`,
    token_authorize_info_btn: `<a>veuillez cliquer sur Réglages pour  le ré-autoriser</a>`,
    order_pending_btn: `veuillez attendre le résultat du traitement`,
    archive:'Archive',
    test_order_tip: 'Cette commande est une commande de test et le numéro de commande généré est virtuel',
    conada_error:`Please enter your full name as it appears on your ID. Cliquez <a target="_blank" href="https://help.dsers.com/orders-to-canada-specifications-for-shopify/">ici</a> pour savoir comment régler ce problème.`,
    cl_rut_errtip:`Veuillez saisir le bon numéro RUT au format suivant : XXXXXXX-X ou XXXXXXXX-X, les 7-8 premiers chiffres doivent être des chiffres arabes, le dernier doit être un chiffre arabe ou la lettre K`,
    cl_rut_errtipNo:`Veuillez entrer 2-15 chiffres ou lettres, tel que 12123234-K`,
    sync_last_chan:'Sync commande AliExpress',
    sync_time_last_chan:'Dernière synchronisation',
    sync_time_next_chan:'Prochaine synchronisation',
    flags:{
      title:'Drapeaux',
      None:'Aucun',
      Grey:'Gris',
      Blue:'Bleu',
      Green:'Vert',
      Yellow:'Jaune',
      Red:'Rouge'
    },
    shopify_partially_refunded:'Shopify partially refunded',
    place_via_extension: {
      caution: 'Attention',
      ae_account_not_match: `Nous avons détecté que le compte AliExpress sur lequel vous êtes actuellement connecté est incompatible avec le compte lié à DSers. Pour vous assurer que le statut de votre commande AliExpress peut être synchronisé avec DSers, veuillez lier votre compte AliExpress actuellement connecté à DSers ou passer une commande sur le compte AliExpress actuellement lié à DSers.`,
      login_ae_account_disabled: '',
      order_via_api_opt: 'Commander via API (Placer les commandes plus rapidement)',
      order_via_ext_opt: `Commander via l'extension Chrome (Utiliser automatiquement les coupons reçus)`,
      ext_not_installed_title: `L'extension Chrome de DSers doit être installé`,
      ext_not_installed_content: `Vérifiez si vous avez installé l'extension Chromecde DSers et cliquez sur Ajouter Extension Chrome DSers ci-dessous pour accéder à la page de la boutique Chrome pour l'installer, puis revenez sur cette page pour réessayer.`,
      add_dsers_ext: 'Ajouter Extension Chrome DSers',
      order_via_api: 'Commander via API ',
      order_is_processing: 'Commander via extension, veuillez ne pas fermer la page ou le navigateur',
      not_operate_title: `N'effectuez pas les opérations suivantes`,
      not_operate_1: 'Quittez/changez de compte DSers,',
      not_operate_2: 'Fermez/désinstallez le plug-in DSers,',
      not_operate_3: `Utilisez le panier d'achat AliExpress,`,
      not_operate_4: 'Quittez le compte AliExpress.',
      failed_order_exsits: 'Le placement des commandes est terminé. Certaines ont peut-être échoué. Veuillez vérifier sur AliExpress pour éviter toute perte. Si la commande a réussi, veuillez remplir le numéro de commande manuellement. Si la commande a échoué, veuillez placer la commande de nouveau.',
      abort_task_btn: 'Arrêter toutes les tâches',
      abort_task_modal_title1: 'Confirmer pour arrêter toutes les tâches de commande en cours?',
      abort_task_modal_content1: `Après avoir confirmé l'arrêt de toutes les commandes, les commandes qui n'ont pas été passées apparaîtront dans l'onglet Commande échouée. Vous pourrez les commander à nouveau plus tard.`,
      confirm_btn: 'Confirmer',
      cancel_btn: 'Annuler',
      abort_task_modal_title2: 'Tâche de commande arrêtée',
      abort_task_modal_content2: `({value}) les commandes ont été arrêtées, ces commandes apparaîtront dans l'onglet Commande échouée`,
      abort_got_it: `J'ai compris`,
      error_tip_ae_logout: 'Votre compte AliExpress était déconnecté pendant le placement des commandes, veuillez vous connecter à votre compte AliExpress et réessayer en étant connecté.',
      error_tip_action_timeout: 'La demande a expiré, veuillez réessayer.',
      error_tip_cart_error: `L'ajout au panier a échoué, veuillez réessayer.`,
      error_tip_unknown: 'La commande a échoué, veuillez contacter le service client pour résoudre ce problème.',
      error_tip_manually_close_1: 'La commande a échoué car vous avez fermé manuellement la page de commande, veuillez réessayer de passer la commande.',
      error_tip_manually_abort: 'You have manually aborted the order task.',
      error_tip_manually_close_2: `La page de commande a été fermée ou l'onglet de la page de commande a été déplacé. Veuillez réessayer.`,
      on_hold_error: 'Vous ne pouvez pas placer la commande car la commande est définie sur En attente dans Shopify. Veuillez modifier le statut de la commande sur Shopify, puis passer la commande sur DSers.',
      request_fulfill_error: `Le traitement de la commande a été demandé sur Shopify. La fonction "Demande de traitement" n'est pas conforme à la manière dont DSers traite les commandes, veuillez modifier la commande en fonction du guide du blog. Cliquez <a href="https://help.dsers.com/re-order-request-fulfillment-order-for-shopify/">ici</a> pour obtenir plus d'informations`,
      shipping_error: "There is no shipping method applied to this order, please select manually. Or you can go to Settings--Shipping to set up your favorite shipping method, then DSers will select it automatically",
      mapping_error:'Vous devez mapper les produits pour que DSers sache avec quel fournisseur vous travaillez avant de placer des commandes groupées.',
	    shipping_error_set:"La méthode de livraison que vous avez définie en fonction du produit dans les réglages n'est plus prise en charge par le fournisseur, la commande ne peut donc pas être passée sans méthode de livraison par défaut. Vous pouvez modifier la méthode de livraison dans les réglages, ou le modifier manuellement dans la commande",
      on_hold_error_item: 'Vous ne pouvez pas placer la commande car la commande est définie sur En attente dans Shopify. Veuillez modifier le statut de la commande sur Shopify, puis passer la commande sur DSers.',
      request_fulfill_error_item:  `Le traitement de la commande a été demandé sur Shopify. La fonction "Demande de traitement" n'est pas conforme à la manière dont DSers traite les commandes, veuillez modifier la commande en fonction du guide du blog. Cliquez <a href="https://help.dsers.com/re-order-request-fulfillment-order-for-shopify/">ici</a> pour obtenir plus d'informations`,
      shipping_error_item: `Il n'y a pas de méthode d'envoi appliquée à cette commande. Veuillez vérifier les <a href="https://help.dsers.com/re-order-request-fulfillment-order-for-shopify/">paramètres d'envoi</a> sur DSers ou vérifier la disponibilité des méthodes d'envoi sur <a href="https://help.dsers.com/re-order-request-fulfillment-order-for-shopify/">AliExpress.</a>`,
      shipping_error_agency: 'There is no shipping method applied to this order, please select manually.',
      error_tip_product_captcha: 'Votre réseau est anormal, veuillez glisser pour vérifier sur AliExpress et commander à nouveau.',
    },
    disappear_order_tip: `Il y a un problème avec certaines de vos commandes, si vous avez besoin de les récupérer, merci de nous <a class="ds-link" target="_blank" href="https://www.messenger.com/t/DSersOfficial"">contacter.</a>`,
    archived_orders:`Les commandes qui n'ont pas été traitées depuis plus d'un an seront automatiquement placées dans le menu Commandes Archivées.`,
    ae_code_21_error:`La commande n'a pas pu être passée avec votre fournisseur principal pour une raison quelconque, nous avons essayé de passer la commande avec du fournisseur alternatif, mais cela a également échoué. Veuillez changer de fournisseur et placer la commande de nouveau`,
    order_code_24_error:`Cette commande a échoué en raison d'une erreur de réseau, veuillez réessayer de passer la commande`,
    tmall_sku1_error: 'Le SKU du produit a changé, veuillez vérifier les détails de la cartographie.',
    tmall_sku2_error:`Le SKU original n'existe pas, vous devez modifier le mappage ou <a href=\"{url}\" target=\"_blank\">changer de fournisseur </a>, sinon vous ne pourrez pas passer la commande avec succès.`,
    tmall_sku3_error:`The original SKU does not exist or changed, you need to re-map or change the supplier, otherwise you can't place the order successfully.`,
    filter_mapping_default:'Toutes commandes',
    filter_mapped_order:'Commandes mappées',
    filter_no_mapped_order: 'Commandes non-mappées',
    note_title_y:'Ajouter une note',
    note_desc_y:'La note est visible uniquement dans DSers',
    note_tip_y:'Le nombre de caractères dans le champ de saisie a dépassé la limite, veuillez supprimer le contenu et enregistrer.',
    aeProcess:'Process Orders',
    aeProcessSub:`Annulez les commandes sélectionnées et elles seront déplacées vers l'onglet Commandes à Placer. Les anciennes informations de commande AliExpress seront perdues après la restauration.<span style="color:red">  Veuillez noter que ces commandes seront modifiées comme non traitées dans Shopify. Note: en raison de la politique de Shopify, nous ne pouvons agir que sur les commandes des deux derniers mois.</span>`,
    note_tip_y:'Le nombre de caractères dans le champ de saisie a dépassé la limite, veuillez supprimer le contenu et enregistrer.',
    confirm_modal_up: {
      fulfill_title:'Fulfill Orders Manually',
      fulfill_confirm:'CONFIRM',
      rollback_title:'Roll Back Orders',
      rollback_input_confirm_value:'Please input CONFIRM to Roll back orders.',
      note_cancel:'CANCEL',
      node_save:'SAVE',
      fulfill_desc:'Are you sure you want to manually FULFILL these orders? Please note that DSers will stop syncing order information for the orders that have been placed on AliExpress.'
    },
    orderPayment:{
      timeout:'Le paiement a échoué. Le paiement a expiré, veuillez relancer le paiement.',
      cancel:'Le paiement a échoué. Votre paiement a été annulé. Veuillez recommencer le paiement.',
      unknow:'Le paiement a échoué. Raison inconnue. Veuillez contacter le service clientèle DSers pour résoudre ce problème.',
      checkDetail:'Vérifier les détails',
      link:'Lien de paiement',
      loading:'Le paiement que vous avez commencé à {time} est en cours de paiement.',
      success:'Le paiement que vous avez commencé à {time} est validé.',
      error:'The payment you started at {time} is failed.',
      pendingTip:'Si vous souhaitez annuler le paiement de cette commande, veuillez annuler le paiement en cliquant sur le lien de paiement.',
      reason:'failed reason',
      buttonTip:'Make your payments to complete your orders. Please complete your payment asap to satisfy your customers!',
      orderOneButtonTip:'Effectuer un paiement'
    },
    confirm_modal_up:{
      fulfill_title:'Exécutez les commandes manuellement',
      fulfill_confirm:'CONFIRMER',
      rollback_title:'Roll Back Orders',
      rollback_input_confirm_value:'Voulez-vous exécuter ces commandes manuellement ?',
      note_cancel:'CANCEL',
      node_save:'SAVE',
      fulfill_desc:'Voulez-vous exécuter ces commandes manuellement? Notez que DSers cessera de synchroniser les informations relatives aux commandes passées sur AliExpress.',
      "fulfill_desc_tm":"Are you sure you want to manually FULFILL these orders? Please note that DSers will stop syncing order information for the orders that have been placed on Tmall."
    },
    orderQuantityToolTip:{
      "title": "Il y a encore {items} articles dans le menu Autres commandes",
      "aliexpress": "Commandes AliExpress",
      "agency": "Commandes de l'Agence",
      "unmapped": "Commandes non répertoriées"
    },
    aeCancelTip:`En raison de la nouvelle réglementation AliExpress, lorsque la commande utilise un faux numéro de suivi, elle peut être annulée par AliExpress. Veuillez annuler manuellement le traitement de ce type de commande dans l'onglet Traité.`,
    editActionTip:`Avec la modification du fournisseur de la commande, l'article ou la commande sera déplacé vers la page des commandes {platformType}.`,
    edit_order_confirm_tip_1: `Vous avez utilisé la fonction Éditer pour modifier le type de fournisseur de l'article, veuillez vous rendre sur l'onglet {platform} - {tab} pour afficher l'article.`,
    edit_order_confirm_tip_2: `Vous avez utilisé la fonction Éditer pour modifier le type de fournisseur de certains articles, veuillez vous rendre sur l'onglet {platform} - {tab} pour afficher l'article.`,
    edit_order_confirm_tip_var: {
      aliexpress: 'AE',
      tmall: 'Tmall',
      both: 'AE / Tmall',
      awaiting_order: 'Commande en attente',
      canceled_order: 'Annulée',
      failed_order: 'Commande échouée',
    },
    tmallOrderPayment:{
      timeout:'Le paiement a échoué. Le paiement a expiré, veuillez relancer le paiement.',
      cancel:'Le paiement a échoué. Votre paiement a été annulé. Veuillez recommencer le paiement.',
      unknow:'Le paiement a échoué. Raison inconnue. Veuillez contacter le service clientèle DSers pour résoudre ce problème.',
      checkDetail:'Check details',
      link:'Payment link',
      pending:'Le paiement que vous avez commencé à {time} est en cours de paiement.',
      success:'Le paiement que vous avez commencé à {time} est validé.',
      failed:'The payment you started at {time} is failed.',
      pendingTip:'Si vous souhaitez annuler le paiement de cette commande, veuillez annuler le paiement en cliquant sur le lien de paiement.',
      reason:'failed reason',
      buttonTip:'Make your payments to complete your orders. Please complete your payment asap to satisfy your customers!',
      orderOneButtonTip:'Effectuer un paiement'
  },
  paymentModalTitle:'Veuillez vérifier les informations relatives à la commande avant de payer.',
    orderPageGuidance:'La commande a été synchronisée, veuillez vous rendre sur la page des commandes ouvertes pour traiter les commandes en attente.'
  }
};
