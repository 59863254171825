import React, { useState, useEffect, useMemo } from 'react';
import { Dropdown, Badge, Icon, List, Card, Tooltip } from 'antd';
import classNames from 'classnames';
import { connect } from 'dva';
import moment from 'moment-timezone';
import intl from 'react-intl-universal';

import { getMsgByType, getShopifyProdItems } from 'routes/Notification/utils';
import { getAliExpressUrl } from 'utils/utils';

import styles from './Notice.less';

const getTime = (date, isShort = false, timezone) => {
  const time = timezone ? moment(date).tz(timezone) : moment(date);
  return isShort ? time.format('HH:mm') : time.format('YYYY-MM-DD');
};

const Notice = ({ dispatch, msgBoxData, timezone, unreadCount, newUserInfo }) => {
  const [visible, setVisible] = useState(false);
  const [searchStores,setSearchStores] = useState(JSON.parse(localStorage.getItem('store'))?.stores.map(item=>{
    return item?.id
  }))
  useEffect(() => {
    dispatch({
      type: 'notification/list',
      payload: {
        fromMsg: true,
        data: {
          cursor: '',
          limit: 5,
          type: '',
          isread: '',
          store_ids:newUserInfo?.user_role === 2 ? searchStores : []  //如果为limit账户则选择店铺 如果不是则传空
        }
      }
    });
    dispatch({
      type:'global/timezone'
    })
  }, [dispatch]);
  // 风控单跳转
  const handleRiskControlSheet = (data, type) => {
    setOneIsRead(data, type);
  };
  const setOneIsRead = (li, type) => {
    if (li.isread) {
      if (type) {
        window.location.href = '/app/order/list?id=6';
      }
      return;
    }
    li.isread = true;

    dispatch({
      type: 'notification/update',
      payload: {
        data: {
          id: li.id,
          isread: true
        },
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            // this.topbtn.getNotificationUnreadLength();
            if (type) {
              window.location.href = '/app/order/list?id=6';
            }
            // 更新 header 中的 notice
            dispatch({
              type: 'notification/list',
              payload: {
                fromMsg: true,
                data: {
                  cursor: '',
                  limit: 5,
                  type: '',
                  isread: ''
                }
              }
            });
          }
        }
      }
    });
  };
  const data = useMemo(() => {
    if (msgBoxData && msgBoxData.notis) {
      const result = {};
      msgBoxData.notis.forEach(i => {
        const time = getTime(i.createdat, false, timezone);
        if (result[time]) {
          result[time].push(i);
        } else {
          result[time] = [i];
        }
      });
      // eslint-disable-next-line
      return Object.entries(result);
    }

    return [];
  }, [msgBoxData, timezone]);

  const handleMessageItemClick = () => {
    window.open('/app/notification', '_blank');
  };

  const notificationBox = (
    <Card className={styles.noticecafdn}>
      <div className={styles.noticeTitle}>{intl.get('notification.noti_tit')}</div>
      <List
        className={styles.list}
        dataSource={data}
        footer={
          <div className={styles.noticeFooter} style={{ textAlign: 'center' }}>
            <a
              href="/app/notification"
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.get('notification.allNotification')}
            </a>
          </div>
        }
        renderItem={item => (
          <List.Item className={styles.item}>
            <div style={{ width: '100%' }}>
              <h4>{item[0]}</h4>
              {item[1].map(i => (
                <div className={styles.childItem} key={i.id}>
                  <div
                    className={`${styles.title} ${i.isread ? styles.unRead : ''}`}
                    onClick={handleMessageItemClick}
                  >
                    {getTime(i.createdat, true, timezone)}{' '}
                    {i.type !== 6
                      ? getMsgByType(i.type)
                      : `${intl.get('notification.focus_on_orders.p1')}${i.data?.ae_order_cancel_num
                        ? i.data?.ae_order_cancel_num
                        : 0
                      } ${intl.get('notification.focus_on_orders.p2')}`}
                  </div>
                  {i.type !== 6 ? (
                    <div className={styles.description} onClick={handleMessageItemClick}>
                      {getShopifyProdItems(i).length}{' '}
                      {intl.get('notification.related_shopify_products_length')}
                    </div>
                  ) : null}
                  <div className={styles.linkCon}>
                    {i.type !== 6 ? (
                      <a
                        className={styles.link}
                        href={getAliExpressUrl(
                          i.data?.aliexpressprod.productid
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                        title={i.data?.aliexpressprod.title}
                      >
                        {i.data?.aliexpressprod.title}
                      </a>
                    ) : (
                      <div
                        style={{ color: '#1890ff', cursor: 'pointer' }}
                        onClick={e => handleRiskControlSheet(i, true)}
                      >
                        {intl.get('notification.view_canceled_orders')}
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </List.Item>
        )}
      />
    </Card>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationBox}
      overlayClassName={styles.popover}
      trigger={['click']}
      visible={visible}
      onVisibleChange={value => setVisible(value)}
    >
      <span className={classNames(styles.noticeButton, { opened: visible })}>
        <Tooltip placement="bottom" title={intl.get('notification.noti_tit')}>
          <Badge count={unreadCount} style={{ backgroundColor: '#F44436' }} style={{ borderRadius: '8px', left: '5px', padding: '0px 4px 0px 5px' }}>
            <Icon type="bell" theme="filled" className={styles.icon} />
          </Badge>
        </Tooltip>
      </span>
    </Dropdown>
  );
};

export default connect(({ notification, loading, global, topBtn, user }) => ({
  msgBoxData: notification.msgBoxData,
  timezone: global.timezoneRegion,
  unreadCount: topBtn.messageCountData && topBtn.messageCountData.notify_unread,
  listLoading: loading.effects['notification/list'],
  newUserInfo:user.newUserInfo
}))(Notice);
