
    export default 
    {
    "suppliers": {
        "categary": "Todas las categorías",
        "copyLink": "Copiar link",
        "importBtn": "Añadir a la lista de importación",
        "importBtnEd": "Imported",
        "optimizer": "Supplier Optimizer"
    }
}