const config = {
  403: {
    title: '403',
    desc: 'Sorry, you do not have permission to access this page'
  },
  404: {
    title: '404',
    desc: 'Sorry, the page you visited does not exist'
  },
  500: {
    title: '500',
    desc: 'Sorry, the server has gone wrong'
  }
};

export default config;
