import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Icon } from 'antd';
import intl from 'react-intl-universal';

import styles from './NoticeOnline.less';
import arrowImg from '../../../assets/img/help-center-right-arrow.png';
import entryImg from '../../../assets/img/help-center-entry.png';
import messengerImg from '../../../assets/img/messenger-icon.png';

const modalRoot = document.getElementById('root');

function NoticeOnline({ onClose }) {
  const [visible, setVisible] = useState(true);

  function handleClose() {
    setVisible(false);
    onClose();
  }

  if (!visible) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.cpNotice}>
      <div className={styles.cpCon}>
        <div className={styles.title}>
          <div>{intl.get('help_center.support_here')}</div>
          <Icon type="close" className={styles.close} onClick={handleClose} />
        </div>
        <div className={styles.cpIntro}>
          <img
            src={messengerImg}
            className={styles.messengerImg}
            alt="help-center-guidance"
          />
          <img
            src={arrowImg}
            className={styles.arrowImg}
            alt="help-center-right-arrow"
          />
          <img
            src={entryImg}
            className={styles.entryImg}
            alt="help-center-entry"
          />
        </div>
      </div>
    </div>,
    modalRoot
  );
}

export default NoticeOnline;
