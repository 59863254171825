import { Post, Get, Put, syncGet, Delete } from '../utils/request';

export async function getList(payload) {
  return Post('/notify/list', payload);
}

export async function getUpdate(payload) {
  return Put('/notify/read', payload);
}

export async function getAllUpdate(payload) {
  return Put('/notify/mread', payload);
}

export async function productLink(payload) {
  return syncGet('/shopify/shopinfo', payload);
}

export async function getAEProduct(payload) {
  return Get('/prod/ali', payload);
}

export async function getShopifyProductDetail(payload) {
  return Get('/notify/product', payload);
}

export async function updateShopifyPrice(payload) {
  return Post('/prod/update_sp/price', payload);
}

export async function deleteProductNt(payload){
  return Delete('/notify/remove',payload)
}