
    export default 
    {
    "banner": {
        "title": "AliExpress Offizielle Dropshipping Partner APP",
        "quick_orders": "DSers ist das beste Tool, um alle Ihre Bestellungen innerhalb von Minuten ohne Limit zu bearbeiten (100+)",
        "get_started_placeholder": "E-Mail Adresse eingeben",
        "get_started": "Los geht's",
        "order_management": "Verwaltung von Aufträgen",
        "order_management_description": "DSers ist in der Lage, Massen- und Mehrfachbestellungen zu bearbeiten. Verarbeiten Sie über 100 Bestellungen pro Tag innerhalb weniger Minuten. Alle Bestellungen werden gleichzeitig von Shopify synchronisiert. Der Dropshipper kann dann mit nur EINEM Knopf seine Bestellung auf AliExpress aufgeben. Sparen Sie Stunden Ihrer Zeit!",
        "supplier_management": "Lieferanten-Management",
        "supplier_management_description": "Legen Sie mehrere Lieferanten für ausgewählte Artikel fest. Der Ersatzlieferant wird NUR aktiviert, wenn das Produkt bei Ihrem Standardlieferanten nicht verfügbar ist (d.h. nicht auf Lager). Millionen von Produkten bedeuten hunderte von Lieferanten und ein unendliches Lager auf AliExpress. Niemals ausverkauft sein! (Eine neue Funktion, die in Kürze verfügbar sein wird, wird es Ihnen ermöglichen, automatisch den günstigsten Lieferanten zu finden).",
        "csv_bulk_orders": "CSV-Massenbestellungen",
        "csv_bulk_orders_description": "Genießen Sie DSers und geben Sie mehrere Bestellungen auf einmal auf, egal welche Verkaufsplattform Sie verwenden. Erstellen und laden Sie die Datenbank Ihrer Produkte hoch, importieren Sie Ihre zu erwartenden Bestellungen in DSers und geben Sie sie alle auf einmal auf! So einfach ist das. Sparen Sie mehr Zeit als je zuvor mit DSers.",
        "save_97_ordering_time": "Sparen Sie 97% Bestellzeit",
        "how_does_work": {
            "title": "Wie funktioniert DSers für Dropshipper?",
            "easy": "Leicht zu beginnen. Leicht zu meistern.",
            "multiple_store": "Mehrere Shops",
            "multiple_store_dec": "Verwalten Sie mehrere Shopify-Shops",
            "supplier_management": "Verwaltung von Lieferanten",
            "supplier_management_dec": "Ein Produkt mit mehreren Lieferanten",
            "multiple_orders": "Mehrere Bestellungen",
            "multiple_orders_dec": "Verarbeiten Sie mehrere Bestellungen mit einem Klick",
            "auto_sync": "Automatische Synchronisierung",
            "auto_sync_dec": "Bestellstatus wird von AliExpress synchronisiert"
        },
        "feedback": {
            "title": "Vertraut von Menschen wie Ihnen",
            "feedback": "Feedback von unseren Hauptnutzern",
            "like": "Wie",
            "comment": "Kommentieren",
            "share": "Teilen",
            "comments": "Kommentare",
            "tomas": "Tomas",
            "tomas_time": "Gestern um 4:19 AM",
            "tomas_dec": "Wir verwenden DSers als Werkzeug für die Bestellung bei Aliexpress. Es ist sehr leistungsfähig. Vielen Dank dafür.",
            "simon": "Simon",
            "simon_time": "2nd Nov 2018 at 11:09 AM",
            "simon_dec": "DSers ist eine tolle App! Ich freue mich sehr darauf, sie zu nutzen, da sie mir eine Menge Zeit und Geld spart. Es ist eine sehr coole Erfahrung, hunderte von Bestellungen in nur ein paar Minuten zu erfüllen.",
            "wilson": "Wilson",
            "wilson_time": "Gestern um 9:22 PM",
            "wilson_dec": "Diese App ist ein wahrer Traum! Es ist wirklich AMAZING, wenn ich sehe, dass Großbestellungen innerhalb einer Minute erfolgreich platziert werden! Es hilft dabei, Arbeit zu sparen.",
            "jonathan": "Jonathan",
            "jonathan_time": "um 5:48 PM",
            "jonathan_dec": "Ich bin wirklich froh, dass ich Ihre Website gefunden habe - tolle Arbeit! Die Auftragsabwicklung ist so viel einfacher geworden, seit ich DSers benutze."
        },
        "features": {
            "title": "DSers bietet alle Funktionen für Dropshipping-Geschäfte",
            "dec": "Die wichtigsten Funktionen von DSers",
            "multiple_order_processing": "Mehrfache Auftragsabwicklung",
            "multiple_order_processing_dec": "Mehrfache Auftragsbearbeitung",
            "variants_mapping": "Varianten-Zuordnung",
            "variants_mapping_dec": "Einfaches Hinzufügen von Varianten zu Produkten durch Hinzufügen von Lieferanten",
            "shipment_setting": "Einstellung der Versandart",
            "shipment_setting_dec": "Versandart nach Ländern und Gebühren einstellen",
            "aliExpress_whitelist": "AliExpress-Whitelist",
            "aliExpress_whitelist_dec": "Keine Bestellbeschränkung mehr",
            "multiple_stores_manager": "Manager für mehrere Geschäfte",
            "multiple_stores_manager_dec": "Verwalten Sie mehrere Geschäfte in einem Konto",
            "supplier_management": "Verwaltung von Lieferanten",
            "supplier_management_dec": "Hinzufügen mehrerer Lieferanten zu einem Produkt",
            "product_importer": "Produkt Importeur",
            "product_importer_dec": "Importieren Sie Produkte mit der Chrome-Erweiterung",
            "auto_messaging": "Automatische Benachrichtigung",
            "auto_messaging_dec": "Erstellen Sie automatische Nachrichten für AliExpress-Verkäufer",
            "email_template": "E-Mail-Vorlage",
            "email_template_dec": "Versandbenachrichtigung per E-Mail in Kürze",
            "tracking": "Nachverfolgung",
            "tracking_dec": "Genaue Verfolgung von Versandinformationen",
            "price_monitor": "Preisüberwachung",
            "price_monitor_dec": "Lassen Sie sich über Preisänderungen auf AliExpress benachrichtigen",
            "inventory_monitor": "Inventar überwachen",
            "inventory_monitor_dec": "Synchronisieren Sie den Produktbestand von AliExpress"
        },
        "easy_started": "Völlig kostenlos,<br /> einfach zu starten",
        "partnership": "Partnerschaft",
        "advabced": "Erweiterte",
        "content": "<span class={title}>20</span> <span class={title}> % </span><span class={title}>RABATT</span>",
        "will": "Wenn Sie jetzt zum Advanced Plan wechseln, erhalten Sie:<br />",
        "support": "Unterstützung von <span class={title}>5</span> zusätzlichen Shops, <br /> <span class={title}>10.000+</span> Produkte Limit, <br />neue Marketingstrategien",
        "today": "NUR FÜR HEUTE!",
        "btn1": "WECHSELN SIE JETZT",
        "btn2": "Lassen Sie mich darüber nachdenken",
        "yes": "WECHSELN SIE JETZT",
        "no": "I want to keep my Standard plan >>",
        "btn3": "Support of 5 additional stores | 10,000+ products limit | new marketing strategies",
        "ioss": {
            "contact_us": "CONTACT US",
            "title": "Contact your supply account manager to learn more information about IOSS",
            "sub_title": "----- Relevant notice about the EU tax reform",
            "text": "The European Union will start implementing new VAT tax regulations from July, 1st, 2021.<br />Dropshipping business can be run normally and the end customers won’t need to pay any additional taxes. <br />If you have concerns about IOSS, local tax issue, tax refund, please feel free to contact your supply account manager (<a href=\"https://www.dsers.com/blog/eu-vat-reform/\" target=\"_blank\" rel=\"noopener noreferrer\" >click here to learn more</a>).",
            "title2": "Einführung der neuen EU-Steuer (IOSS)",
            "text2": "Auswirkungen auf Dropshipping-Geschäfte in EU-Ländern ab 1. Juli 2021"
        },
        "newUI": {
            "title": "Brand new UI, brand new experience",
            "title2": "DSers will launch a new UI in early September",
            "learn": "LEARN MORE",
            newYearTitle: "Chinese New Year Holidays Notice",
            newYearContent1: "Chinese New Year is coming. Please contact your suppliers to check their delivery delays and resting period!",
            newYearContent2: "DSers suppliers will rest from 21st Jan 2023 to 23rd Jan 2023",
        },
        "bannerFor11_11": "Discover massive promotions on 11.11",
        "bannerButton": "LEARN MORE",
        "newFeatureUp": "New feature upgrade",
        "newFeatureTitle": "[New feature]",
        "newFeatureText": "To help you better manage your store and team and assign permissions, you can use the staff account feature",
        "newSettingTitle": "[New setting]",
        "newSettingText": "The Setting page has been upgraded, it is clearer and more convenient",
        "learnMore": "Learn more",
        "gotIt": "Got it",
        "appointmenttext": "Sie haben die Zeit für den Beginn der Migration auf [ {time} ] festgelegt, Sie können Ihr DSers-Konto nicht bedienen, wenn die Migration beginnt",
        "appointmenttext1": "Sie haben die Zeit für den Beginn der Migration auf [ {time} ] festgelegt, und wir werden Ihnen in 5 Minuten bei der Migration helfen. Während der Migration können Sie Ihr DSers-Konto nicht bedienen, Sie können also eine Pause einlegen.",
        agency_cold_startup: {
            banner_title_1: 'Neue Funktion Online!',
            banner_title_2: 'Agentur Auftragsmanagement',
            popup_desc_1: `Wechseln Sie zwischen verschiedenen Tools für die Auftragserfüllung durch Agenturen? `,
            popup_desc_2: `Probieren Sie diese alternative Lösung von DSers aus, um das Wechseln zwischen Shopify-Shops, Mass Fulfill-Konten und CSV-Dateien zu vermeiden.`,
            popup_desc_3: `Machen Sie Ihre Hände frei, um sich in diesem 4. Quartal auf das Marketing zu konzentrieren!`,
            learn_more_btn: 'Mehr erfahren',
            banner_desc: 'Probieren Sie diese alternative Lösung von DSers aus, um das Wechseln zwischen Shopify-Shops, Mass Fulfill-Konten und CSV-Dateien zu vermeiden.',
            order_awaiting_empty_after_title: 'Vorher',
            order_awaiting_empty_before_title: 'Danach',
            order_awaiting_empty_before: 'Ohne diese Funktion bräuchten Sie mehrere Tools für Agenturaufträge',
            order_awaiting_empty_after: 'Mit dieser Funktion benötigen Sie nur DSers!',
            order_awaiting_empty_link: 'Klicken Sie <a data="text-link" style="color: blue" href="https://www.dsers.com/blog/product-update-dropship-with-agencies/" target="_blank" >hier</a>, um mehr darüber zu erfahren, wie Sie Agenturaufträge bearbeiten können.',
            what_is_agency: 'Was ist eine Agentur?',
            what_is_erp: 'Was ist ein ERP?',
            how_does_dsers_agency_works: 'Wie vereinfacht DSers Ihren Workflow mit Dropshipping-Agenturen?',
        },
        tmall_cold_startup: {
            popup_title: 'Neuer Marktplatz',
            pupup_title_2: 'Tmall ist online! Machen Sie mit!',
            popup_desc_1: 'Tausende von Bestsellern wurden soeben in das Angebot aufgenommen.',
            popup_desc_2: 'Warum warten, um sie in Ihren Shop zu importieren?',
            popup_btn: 'Produkte durchsuchen',
            top_banner_title: 'Neuer Marktplatz',
            top_banner_title_2: 'Tmall ist online! Machen Sie mit!',
            find_supplier_banner_title: 'Bestseller für Q4 2022!',
            find_supplier_banner_subtitle: 'Trendige und lukrative Artikel sind live!',
            find_supplier_banner_subtitle_2: 'Fügen Sie sie zu Ihrem Shop hinzu!'
          }
    }
}