import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Button } from 'antd';
import intl from 'react-intl-universal';

import styles from './TmallColdStartup.less';
import useOnlineNotice from '../../hooks/useOnlineNotice';
import { DSERS_IMG_HOST } from 'utils/constants';
import classNames from 'classnames';
import { CommonDataCollect } from 'utils/utils';
import { Star } from 'components/SvgIcons';

const onlineDate = new Date(2022, 9, 17); // 上线日期

const modalRoot = document.getElementById('root');

function TmallColdStartup({ lang }) {
    console.log('tmall popup')
  const [visible, closeNotice] = useOnlineNotice(
    'tmall_cold_startup',
    onlineDate,
    99
  );

  const calculateVisible = React.useMemo(() => {
    return visible && window.TMALL_USER && !window.location.href?.includes('find_suppliers?bestSeller=true')
  }, [visible]);

  const handleClose = () => {
    typeof closeNotice === 'function' && closeNotice();
    CommonDataCollect({
        action: 'agency_order_cold_startup_popup_close',
        event_type:'agency_order_cold_startup'
    });
  }

  const handleClickBtn = () => {
    CommonDataCollect({
        action: 'agency_order_cold_startup_popup_btn_click',
        event_type:'agency_order_cold_startup'
    });
    typeof closeNotice === 'function' && closeNotice();
  }

  useEffect(() => {
    if(calculateVisible) {
        CommonDataCollect({
            action: 'agency_order_cold_startup_popup_show',
            event_type:'agency_order_cold_startup'
        });

    }
  }, [calculateVisible]);

  if (!calculateVisible) {
    return null;
  }
  // return <Star className={styles.star1} />
  return ReactDOM.createPortal(
    <div className={styles.notice}>
      <div className={styles.content}>
        <div className={styles.contentBg}>
          <Star className={styles.star1} />
          <Star className={styles.star2} color="#32C5FF" />
          <Star className={styles.star3} color="#B620E0" />
          <Star className={styles.star4} color="#6236FF" />
          <Star className={styles.star5} color="#44D7B6" />
          <Star className={styles.star6} color="#F7B500" />
          <img
            className={styles.bgImg1}
            width="267"
            src={`${DSERS_IMG_HOST}/shopify/activity/tmall_cold_startup/221019/tmall_popup_img_1.png`}
          />
          <img
            className={styles.bgImg2}
            width="296"
            src={`${DSERS_IMG_HOST}/shopify/activity/tmall_cold_startup/221019/tmall_popup_img_2.png`}
          />
          <img
            className={styles.bgImg3}
            width="68"
            src={`${DSERS_IMG_HOST}/shopify/activity/tmall_cold_startup/221019/tmall_popup_img_4.png`}
            />
        </div>
        <div className={styles.contentInfo}>
          <span
            onClick={handleClose}
            className={classNames('material-icons', styles.closeIcon)}
            title="close"
          >
            close
          </span>
          <div className={styles.title}>
            {intl.get('banner.tmall_cold_startup.popup_title')}
            <div className={styles.topTitle}>{intl.get('banner.tmall_cold_startup.popup_title')}</div>
          </div><br />
          <div className={styles.title}>
            {intl.get('banner.tmall_cold_startup.pupup_title_2')}
            <div className={styles.topTitle}>{intl.get('banner.tmall_cold_startup.pupup_title_2')}</div>
          </div>
          <div className={styles.descBox}>
            <img width="280" src={`${DSERS_IMG_HOST}/shopify/activity/tmall_cold_startup/221019/tmall_popup_img_3.png`}/>
            <div className={styles.subTitle}>
              {intl.get('banner.tmall_cold_startup.popup_desc_1')}
            </div>
            <div className={styles.margin} style={{ height: 16 }}></div>
            <div className={styles.subTitle}>
              {intl.get('banner.tmall_cold_startup.popup_desc_2')}
            </div>
          </div>
          <div className={styles.margin} style={{ height: 21 }}></div>
          <a
            href={`${window.location.origin}/app/find_suppliers?bestSeller=true`}
            // target="_blank"
            onClick={handleClickBtn}
          >
            <div className={styles.button}>
              {intl.get('banner.tmall_cold_startup.popup_btn')}
              {/* <img
                className={styles.buttonStar}
                width="14"
                src={`${DSERS_IMG_HOST}/shopify/activity/agency_cold_startup/221017/agency_startup_img_5.png`}
              /> */}
            </div>
          </a>
        </div>
      </div>
    </div>,
    modalRoot
  );
}

export default TmallColdStartup;
