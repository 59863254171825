import React, { useEffect } from 'react';
import { Route, Redirect, routerRedux } from 'dva/router';
import { connect } from 'dva';
import { Spin } from 'antd';

function PrivateRoute({
  dispatch,
  children,
  loginInfo,
  loadingUserInfo,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        // 自动登录时的loading, 防止渲染 login page
        // if (loadingUserInfo) {
        //   return (
        // <Spin size="large" style={{ width: '100%', margin: '180px auto' }} />;
        //   );
        // }
        if (loginInfo) {
          return children;
        }
        return (
          <Redirect
            to={{
              pathname: '/partner/login',
              state: { from: location }
            }}
          />
        );
      }}
    />
  );
}

const mapStateToProps = ({ partner }) => ({
  loginInfo: partner.loginInfo
  // loadingUserInfo: login.loadingUserInfo
});

export default connect(mapStateToProps)(PrivateRoute);
