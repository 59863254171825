import classNames from 'classnames';
import React, { useMemo } from 'react';
import styles from './Star.less';

export default ({ className, color }) => {
  const calculateClassName = useMemo(() => {
    return classNames(styles.star, className);
  }, []);
  return (
    <span className={calculateClassName}>
      <svg
        width="39px"
        height="40px"
        viewBox="0 0 39 40"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <title>star</title>
        <desc>Created with Sketch.</desc>
        <g
          id="dsers-cold-startup-star"
          stroke="none"
          stroke-width="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="dsers-cold-startup-star-1"
            transform="translate(-457.000000, -239.000000)"
            fill={color || '#ffffff'}
          >
            <g
              id="dsers-cold-startup-star-2"
              transform="translate(100.000000, 92.000000)"
            >
              <path
                d="M398.999597,167.304446 C376.499597,167.304446 376.499597,167.304401 376.499597,189.804446 C376.499597,167.304401 376.499597,167.304446 353.999597,167.304446 C376.499597,167.304446 376.499597,167.304491 376.499597,144.804446 C376.499597,167.304491 376.499597,167.304446 398.999597,167.304446"
                id="Fill-1备份-10"
                transform="translate(376.499597, 167.304446) rotate(20.000000) translate(-376.499597, -167.304446) "
              ></path>
            </g>
          </g>
        </g>
      </svg>
    </span>
  );
};
