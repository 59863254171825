
    export default 
    {
    "h5_callback": {
        "bind_tip": "Sei sicuro di voler legare il negozio Shopify { shop_name } usando l'indirizzo email { bind_email } all'account DSers usando l'indirizzo email { current_email }?",
        "login_tip": "Riconnettiti al negozio",
        "bing_auth": "La tua autorizzazione è scaduta, aggiorna la pagina per riautorizzarla",
        "authAgin": "La tua autorizzazione attuale è scaduta, clicca il pulsante qui sotto per aggiornare l'autorizzazione e ricollegarti",
        "auth_log": "Esci",
        "auth_entry": "Riautorizza",
        "bind_time_out": "Spiacenti, il collegamento del negozio è scaduto, clicca il pulsante qui sotto per riavviare il collegamento",
        "relink": "Ricollega",
        "need_activate": "Il negozio è stato precedentemente nascosto a causa della limitazione del piano, vai alla pagina dell'abbonamento per attivare il negozio corrispondente.",
        "activate": "Attivazione"
    }
}