import React, { PureComponent } from 'react';
import { Layout } from 'antd';

import GlobalHeader from 'components/GlobalHeader';

export default class FixedHeaderLayout extends PureComponent {
  render() {
    const { extraContent } = this.props;
    return (
      <Layout>
        <Layout.Header
          style={{ position: 'fixed', zIndex: 1, width: '100%', padding: 0 }}
        >
          <GlobalHeader showLogo extraContent={extraContent} />
        </Layout.Header>
        <Layout.Content style={{ marginTop: 64 }}>
          <div
            style={{
              background: '#ECEFF1',
              padding: 24,
              overflow: 'auto',
              minHeight: 'calc(100vh - 64px)'
            }}
          >
            {this.props.children}
          </div>
        </Layout.Content>
      </Layout>
    );
  }
}
