import { useEffect, useState } from 'react';
/**
 *
 * @param service
 * @param onSuccess
 * @returns {[unknown]}
 */
export default function useRequest(service, onSuccess) {
  const [data, setData] = useState(null);
  function init() {
    return service().then(result => {
      if (result.data) {
        setData(result.data);
        return result.data;
      }
    });
  }
  useEffect(() => {
    init().then((data)=>{
      if(data){
        onSuccess && onSuccess(data);
      }
    });
  }, []);
  return [data, init];
}
