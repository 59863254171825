
    export default 
    {
    "fulfillment_center": {
        "activate": "¿Estás seguro de que quieres activar el producto FBD?",
        "activate_dec": "Tu proveedor por defecto se cambiará al producto FBD después de 48 horas.",
        "input_confirm": "Por favor, presiona CONFIRMAR para activarlo.",
        "deactivate": "¿Estás seguro de que quieres desactivar los productos FBD?",
        "deactivate_dec": "Tu proveedor por defecto será cambiado al proveedor de AliExpress en tu página de mapeo después de 48 horas.",
        "using_tip": "Estás utilizando el servicio FBD para este producto. Los proveedores de la página de mapeo se utilizarán cuando se desactive",
        "close_request": "Tu solicitud está en revisión. Se activará después de 48 horas. Durante las siguientes 48 horas se utilizará el proveedor actual",
        "closeing": "Se utilizará el servicio FBD cuando esté activado",
        "activate_request": "Tu solicitud está en revisión. El proveedor en la página de mapeo se utilizará después de 48 horas",
        "go_setting": "La tienda necesita ser reautorizada. Por favor, dirígete a <a\n\t\thref=\"/app/settings?id=4\"\n\t  >Ajustes - Cuenta</a> para actualizar la autorización. De lo contrario, no podrás operar la tienda correctamente con Dsers",
        "title": "Centro de Procesamiento (FBD)",
        "dec": "No procesaremos los productos a través del FBD si tus pedidos incluyen varios productos suministrados por el mismo proveedor Ali, así evitaremos duplicar los gastos de envío.",
        "pruchese": "Compra",
        "saved": "Guardado",
        "placeholder": "Seleccionar tienda"
    }
}