export default {
  agency: {
    // global
    Agency: 'Agencia (Proveedor Individual)',
    agency: 'agencia',
    // setting
    supplier: 'Proveedor',
    title: 'Agency (Gestión de Proveedores Individuales)',
    subtitle:
      'Puede administrar la información del proveedor aquí, hacer pedidos directamente al proveedor en lugar de comprar en el mercado de terceros (como AliExpress). Haga clic aquí para obtener más información.',
    subtitle_1: 'Puede administrar la información del proveedor aquí, hacer pedidos directamente al proveedor en lugar de comprar en el mercado de terceros (como AliExpress). Haga clic ',
    subtitle_2: ' para obtener más información.',
    subtitle_3: ' aquí ',
    title_tip: 'Una agencia representa un proveedor de productos que te permite cumplir con los pedidos sin un mercado de terceros como AliExpress. Específicamente, puedes hacer pedidos directamente a las agencias y ellas enviarán los productos a tus clientes.',
    name_tip: 'El nombre de tu agencia que se utilizará y mostrará cuando asignes un producto o proceses un pedido.',
    erp_tip: 'Los representantes del ERP, como Dianxiaomi, Mabang y ECCANG, etc., ofrecen la gestión del inventario de productos, el control de las compras, la distribución logística y el seguimiento de los paquetes.',
    paypal_tip: `La cuenta de PayPal de tu agencia. Después de configurar esta cuenta de PayPal, puedes iniciar los pagos directamente a la Agencia mientras DSers procesa los pedidos`,
    phone_tip: 'El número de teléfono de tu agencia, que se utilizará para empujar a tu agencia a cumplir con los pedidos a través de SMS.',
    name: 'Nombre de la Agencia',
    nameErr: 'El nombre de la agencia no puede estar vacío.',
    phone: 'Número de teléfono',
    phoneErr: 'Sólo se pueden introducir números',
    erp: 'ERP en uso',
    erpPlaceholder: 'Seleccionar ERP',
    erpErr: 'Por favor, seleccionar un ERP.',
    paypal: 'Cuenta PayPal',
    paymentMethod: 'Payment method',
    agencyContactEmail: 'Agency contact email',
    stripeAccount: 'Stripe account',
    agencyStripe: 'Agency Stripe',
    stripeNoAuth: 'No authorization, please contact the supplier for authorization.',
    agencyPaymentTip: `Your Agency's Payment methods. After setting up this payment method, you can initiate payments directly to the Agency while DSers process orders.`,
    agencyNoAuthModalTitle: 'Authorization not completed',
    agencyNoAuthModalContent: `Your supplier has not completed the Stripe account authorization, please contact your supplier to complete the Stripe account authorization. If you have more questions, please contact the DSers support team.`,
    agencyNoAuthModalGetSupport: 'GET SUPPORT',
    paypalPlaceholder: 'Cuenta PayPal de la Agencia',
    paypalErr: 'PayPal no puede estar vacío.',
    paypalEmailErr:
      'Por favor, ingresa un correo electrónico correcto de PayPal',
    delError: 'El proveedor ha sido asignado y no puede ser eliminado.',
    phonePlaceholder: 'Country',

    // setting > save text
    savetitle: 'Agregar una agencia (proveedor individual)',
    savetext: `Después de crear la información de la agencia, DSers enviará un correo electrónico a su proveedor ({email}).Póngase en contacto con su proveedor, quien realizará una configuración sencilla para que pueda continuar administrando el mapeo de productos, el cumplimiento automático de pedidos y otras funciones a través de DSers.`,
    deletetitle: "Eliminación fallida.",
    deletetext: "Actualmente tiene productos asignados a este proveedor. Para evitar afectar a los productos y pedidos existentes, borre el mapeo relacionado con este proveedor antes de intentarlo de nuevo.",
    // my product
    Agencies: 'Agencias <i>({ae_num})</i>',
    filterByAgency: 'Filtrar por Agencia',

    filterAgencyPlease: 'Si no has añadido una Agencia, tienes que ir a',
    filterAgencyAddInformation: 'para añadirla.',

    supplierManagement: 'Gestión de Proveedores',
    marketplaces: 'Marketplaces',
    agencyInformation: 'Información de la Agencia',
    selectAgency: 'Seleccionar Agencia',
    refreshPage: 'Actualizar página',
    REFRESH: 'ACTUALIZAR',
    mappingUpdating: 'Actualización del mapeo',
    markeCardDescription:
      'Puede asignar los productos del proveedor para los productos de su tienda, y cuando los productos de venta se ordenan, puede hacer un pedido con el proveedor correspondiente con solo un clic en DSers.',
    markeCardDescriptionNoTianMao:
      'Con los marketplaces, puedes seleccionar productos de AliExpress para mapear tus artículos de Shopify.',
    agencyCardDescription:
      'Agency representa a un proveedor individual fuera del marketplace (como AliExpress). Puede asignar sus productos de tienda a una agencia y hacer pedidos directamente a su ERP para su ejecución automática.',
    marketplaceExplain:
      'Mapeo ',
      marketplaceExplain1:
      ' de proveedores de marketplaces para tus productos de Shopify. Los detalles del producto desde Shopify (como el título, la descripción, las imágenes, las variantes, etc. ) siguen siendo los mismos después de añadir un proveedor.',
    agencyExplain:
      'Puedes elegir el proveedor que quieras según tus necesidades. Los detalles del producto de Shopify (como el título, la descripción, las imágenes, las variantes, etc.) siguen siendo los mismos después de añadir un proveedor.',
    statusExplain: 'Activar para habilitar el método de mapeo de la Agencia',
    selectAgentPrompt:
      'Seleccionar una Agencia para mapear tus productos de Shopify. Si no has añadido una Agencia, tienes que ir a',
    toSetting: 'Ajustes',
    addAgency: 'para añadirla.',
    noSelectionPrompt:
      'No has añadido ninguna Agencia, por favor entra en Ajustes para añadir una.',
    noSelectionButton: 'IR A AJUSTES PARA AÑADIR AGENCIAS',
    refreshPagePrompt:
      'Por favor, actualiza la página para que podamos detectar si has añadido correctamente una Agencia.',
    backendProcessesPrompt:
      'El método de mapeo del producto ha sido cambiado. Nos tomará algún tiempo ayudarte a reemplazar el mapeo del pedido relacionado con el producto.',
    TimeoutErr:
      'La solicitud ha caducado, por favor, inténtalo de nuevo más tarde.'
  }
};
