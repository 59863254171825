import React from 'react';
import { connect } from 'dva';

import intl from 'react-intl-universal';
import round from 'lodash/round';

import { currencySymbolMap } from '../../../common/sysconfig';

import orderStyles from '../Order.less';

@connect(({ global }) => ({
  storeCurrency: global.storeCurrency
}))
export default class Cost extends React.PureComponent {
  render() {
    const {
      child: { platformType = 2 },
      useSp: { cost = 0, quantity = 0 },
      storeCurrency,
      listData: { storeId }
    } = this.props;

    let currencyCode;
    let currencySymbol;
    let isNotUSD;
    let currencyRates;

    if (storeCurrency[storeId]) {
      currencyCode = storeCurrency[storeId].currency_code || 'USD';
      currencySymbol = currencySymbolMap[currencyCode];
      isNotUSD = currencyCode !== 'USD';
      currencyRates = storeCurrency[storeId].rates_value;
    }

    return (
      <div
        className={orderStyles.productCost}
        style={{ display: platformType > 100 ? 'none' : '' }}
      >
        <p>
          {intl.get('order.order_product.cost')}:&nbsp;
          <span>
            <b>$ {(cost * 1).toFixed(2)}</b> x <b>{quantity}</b>
          </span>
        </p>
        {isNotUSD ? (
          <p className={orderStyles.productCurrencyCost}>
            {`${currencySymbol} ${round(cost * currencyRates, 2)} x ${quantity}`}
          </p>
        ) : null}
      </div>
    );
  }
}
