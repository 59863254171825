
    export default 
    {
    "mapping": {
        "title": "Gestión de proveedores",
        "description": "Los detalles del producto de Shopify (como el título, la descripción, las imágenes, las variantes, etc.) permanecen igual después de añadir un proveedor.",
        "description_tip": "Por favor, ten en cuenta: el mapeo se perderá cuando edites/elimines/añadas opciones o variantes en Shopify.",
        "tab_btn_basic_mapping": "Mapeo básico",
        "tab_btn_advanced_mapping": "Mapeo avanzado",
        "tab_btn_bogo": "BOGO",
        "tab_btn_bundle": "Paquete",
        "tab_btn_verified": "Verificado",
        "shopify_product": "Producto de Shopify",
        "default_ali_product": "Producto {platform} por defecto",
        "edit_shipping": "Editar envío",
        "set_shipping": "Establecer envío",
        "import_a_product": "Importar un producto",
        "substitute_ali_product": "Producto de {platform} Alternativo",
        "second_product_tip": "La segunda opción se utiliza durante el procesamiento automático de pedidos cuando algunas variantes no están disponibles para la opción por defecto.",
        "alternate_products": "Productos alternativos",
        "alternate_products1": "Add Products",
        "import_placeholder": "Ingresa la uro del producto de (No hay sugerencias)",
        "status": "Estado",
        "turn_status": "Activar para habilitar el método de {method} mapeo",
        "close_all_mapping": "¿Quieres desactivar todo el método de mapeo? Esto hará que tu producto no pueda ser procesado en AliExpress a través de Dsers",
        "open_new_mapping": "¿Quieres cambiar a {switch} Mapeo? Tus pedidos serán procesados de acuerdo a {switch} Mapeo",
        "variant": "Variante",
        "ship_to": "Enviar a",
        "supplier_variant": "Proveedor & Variante",
        "import_sp_select_var": "Importar proveedor y seleccionar variante",
        "import_ae_title": "Importar proveedor pegando un enlace de AliExpress aquí",
        "import_tm_title": "Importar proveedor pegando un enlace de Tmall aquí",
        "clear": "Borrar",
        "select_a_variant": "Seleccionar una variante",
        "import_ae_first": "Importar proveedor de AliExpress primero",
        "import_tm_first": "Importar proveedor de Tmall primero",
        "submit": "Enviar",
        "advanced_rule": "Establece los proveedores según los países de destino de tu pedido. Puedes configurar un máximo de 10 proveedores para una variante.",
        "supplier_product": "Producto del proveedor",
        "more_action": "Más opciones",
        "clear_all": "Borrar todo",
        "advanced_more_action_copy": "Copia países y proveedores correspondientes a otras variantes después de mapear la primera. Todavía necesitas seleccionar los SKU correctos para otras variantes",
        "buy": "COMPRAR",
        "buy_desc": "Mapear un proveedor para el producto principal que estás vendiendo",
        "buy_more_action_copy": "Copia el SKU a otras variantes después de mapear la primera. Puedes modificarla si necesitas una diferente.",
        "x": "X",
        "x_desc": "Cantidad para el producto principal",
        "change_qty_to": "Cambiar la cantidad a",
        "get": "OBTENER",
        "get_desc": "Mapea un proveedor para el producto de regalo que estás ofreciendo",
        "get_more_action_copy": "Copia el SKU a otras variantes de REGALO después de mapear la primera. Puedes modificarla si necesitas una diferente.",
        "y": "Y",
        "y_desc": "Cantidad para el <br /> producto de regalo",
        "bundle_products": "Agrupar productos",
        "bundle_more_action_copy": "Copia el SKU a otras variantes después de mapear <br /> la primera. Puedes modificarla si necesitas <br /> una diferente.",
        "import_btn_tip": "<span>Puedes configurar el mapeo como se indica a continuación:<br /> 1. Pega un link de AliExpress aquí;<br /> 2. Haz clic en Importar;<br /> 3. Establece el producto como opción por defecto/segunda opción<br /> ; 4. Completa TODAS las opciones y valores para finalizar el mapeo.<br /> <a\n\t\thref=\"https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/\"\n\t\ttarget=\"_blank\"\n\t  \t>Consultar este artículo</a></span>",
        "import": "Importar",
        "set": "Establecer",
        "no_record_yet": "Todavía no hay registro",
        "ShopifyProduct": "Producto de Shopify",
        "verified": {
            "title": "Información del producto",
            "cost": "Coste",
            "shipping": "Envío",
            "ePacket": "ePacket",
            "package_request": {
                "title": "Solicitud de paquete",
                "option1": "Bolsas (por defecto)",
                "option2": "Caja ($0.2／Paquete)",
                "option3": "Caja con logotipo ($0.2／Paquete)",
                "option4": "Caja con tarjeta ($0.2／Paquete)",
                "option5": "Caja con logotipo y tarjeta ($0.2／Paquete)"
            },
            "upload_logo_file": "Subir archivo del logotipo",
            "leave_a_meaage": "Dejar un mensaje",
            "go_to_setting": "Ir a Ajustes > Establecer Proveedor para toda la tienda",
            "sample_on_way": "El producto de muestra está en camino.",
            "tracking_no": "SIN Seguimiento",
            "request_sample": "Solicitar producto de muestra",
            "save": "Guardar",
            "disable": "Deshabilitar",
            "enable": "Habilitar",
            "cancel": "Cancelar"
        },
        "publish_first": "Publicar primero y luego añadir proveedores",
        "upload_img_tip": "¡La imagen debe ser menor de 1MB!",
        "verified_save": "Guardado con éxito.",
        "copy_link_tip": "La replicación ha fallado, los productos deben ser empujados primero",
        "copy_succ": "Copiado con éxito",
        "copy_fail": "copia fallida",
        "delete_default_option_tip": "¿Quieres eliminar la opción por defecto? ¿Puedes añadirla de nuevo en el registro?",
        "delete_second_option_tip": "¿Deseas eliminar la segunda opción? ¿Puedes añadirla de nuevo en el registro?",
        "delete_history_tip": "¿Deseas eliminar la información de este producto del historial de productos?",
        "yes": "Sí",
        "no": "No",
        "ok": "OK",
        "cancel": "Cancelar",
        "save_option_confirm": {
            "set_shipping_tip": "Por favor, establece el método de envío para el producto alternativo.",
            "save_option_two_tip": "Por favor, presta atención a que la activación del producto alternativo podría causar gastos de envío adicionales si tienes dos proveedores diferentes para un mismo pedido."
        },
        "clear_all_shipping": "¿Deseas eliminar toda la información de los ajustes?",
        "save_shippinp_succ": "Guardado con éxito.",
        "setting_activated": "Configuración activada",
        "country_shipping": {
            "shipping_setting": "Ajustes de envío",
            "ship_to": "Enviar a",
            "clear_all": "Borrar todo",
            "search": "Buscar en",
            "shipping_country": "Compañía de envío",
            "shipping_cost": "Coste de envío",
            "time": "Tiempo estimado de entrega",
            "tracking_information": "Información de seguimiento",
            "free_shipping": "Envío gratuito",
            "available": "Disponible",
            "unavailable": "No disponible",
            "not_ship_this_country": "Este producto no puede ser enviado a este país.",
            "select_ship_country": "Por favor, selecciona el país de destino."
        },
        "product_whic": "¿Quieres que este proveedor sea:",
        "default_option": "Opción por defecto",
        "second_option": "Segunda opción",
        "mapped": "Mapeado",
        "default": "Por defecto",
        "succeed": "Exitoso",
        "repeat_option": "No se puede guardar debido a que hay opciones repetidas de las variantes",
        "select_variant": "Por favor, selecciona las variantes correspondientes. De lo contrario, no podrás guardar el mapeo",
        "delete_pool_product": "Si eliminas este proveedor de tu página de mapeo, el mapeo de tu producto será eliminado",
        "delete_mapping_product": "¿Deseas eliminar la opción { num, plural, =0 { default } por otra {second} } opción? Puedes añadirla nuevamente desde el registro",
        "delete_del_variant_tip": "Are you sure you want to delete the variant(s) on DSers? Once deleted, orders for the variant(s) can no longer be processed.",
        "same_country_tip": "No se puede volver a seleccionar este país para esta variante",
        "same_sku_tip": "Si deseas configurar el mismo producto tanto para el producto principal como para el producto de regalo, por favor, modifica la cantidad para los productos principales en lugar de importarla de nuevo para la columna de regalo.",
        "import_same_sku_tip": "No se puede importar el mismo SKU dos veces para una variante",
        "Variant_delete_tip_one": "Has eliminado la(s) variante(s) de abajo en Shopify. Por favor:",
        "Variant_delete_tip_two": "1. Vuelve a hacer el mapeo para seguir sincronizando el stock desde AliExpress.",
        "Variant_delete_tip_three": "2. Añade el proveedor a la(s) variante(s) de abajo para procesar los pedidos de la(s) variante(s) eliminada(s).",
        "overrun_warning": "Has superado el límite diario para acceder a la información del producto",
        "standProductNum": "Up to 5 AliExpress products can be imported",
        "repeat_alert": "This supplier is already set as default or substitute supplier and cannot be set again",
        clear_tip_alert: 'Si cambias el artículo para establecerlo como proveedor principal, el proveedor secundario se vaciará.',
        forbidden_alert: 'El mapeo básico sólo se puede utilizar para mapear con el proveedor de una plataforma de proveedores.',
        "switch_mapping": "Switch between Default and Substitute supplier",
        "bundle_rule": "Set up a supplier for the product in your Bundle. You can set up to 10 suppliers for a variant",
        "getProduct_error": "The product you entered was not found",
        "standard": {
            "product_name": "Producto de Shopify",
            "product_min_name": "Supplier Product",
            "product_title": "Set up a supplier for the product in your Standard Mapping",
            "remove_con": "Clicking 'CONFIRM' will remove this supplier form the list of available supplier for this product and remove the existing mapping of other SKUs using it. Do you want to confirm?",
            "remove_title": "Remove Supplier",
            "remove_confirm": "CONFIRM"
        },
        "replace_product": {
            "btn_title": "REEMPLAZAR PRODUCTO",
            "modal_tip": "Las variantes del producto de Shopify serán eliminadas y reemplazadas por variantes de un nuevo producto. Puedes editar los detalles del producto en el siguiente paso.",
            "shopify": "Producto de Shopify",
            "ali_express": "AliExpress product",
            "tm_express": "Tmall product",
            "price_rule": "Apply Pricing Rule",
            "cancle": "CANCEL",
            "url_error": "Please input valid AliExpress product URL.",
            "keep_description": "Keep the original Shopify description",
            "update_description": "Update description",
            "keep_image": "Keep the original Shopify images",
            "new_image": "Choose new images",
            "suucess": "Successfully replaced",
            "failed": "Failed to replace",
            replace_placeholder:'Pegar la URL del producto aquí',
        },
        "gift_product": "Producto de regalo",
        "main_product": "Producto principal",
        "sku_lose_tip": "The mapping was lost because your supplier changed the SKU. Please re-map your products according to the latest SKU on AliExpress or change supplier.",
        "replace_product_tip": "If you have any open orders with the old product that haven't been placed yet, please <a href='https://help.dsers.com/basic-mapping-advanced-mapping-bogo-bundle-introduction-for-shopify/' target='_blank'>map</a> or <a href='https://help.dsers.com/re-order-awaiting-payment-orders-for-shopify/#modify-canceled-order' target='_blank'>edit</a> the order with selected new variants that matches the desired product, then process orders {toAliExpress}. You can click REPLACE PRODUCT after.",
        "confirm_delete_all_variant_title": "Delete Variant(s)",
        "confirm_delete_all_variant": "Are you sure you want to delete the variant(s) on DSers? Once deleted, orders for the variant(s) can no longer be processed.",
        "delete_variant_collapse_tip": `Has eliminado la(s) variante(s) de abajo en Shopify. Por favor:
        <br />Añade el proveedor a la(s) variante(s) de abajo para procesar los pedidos de la(s) variante(s) eliminada(s).`,
        delete_variant_collapse_supplier_tip:`Has eliminado la(s) variante(s) de abajo en Shopify. Por favor:
        <br />1. Vuelve a hacer el mapeo para seguir sincronizando el stock desde proveedor.
        <br />2. Añade el proveedor a la(s) variante(s) de abajo para procesar los pedidos de la(s) variante(s) eliminada(s).`,
        "delete_options_title": "Delete Option",
        "delete_supplier_title": "Delete Supplier",
        "open_new_mapping_title": "Switch Mapping",
        "close_all_mapping_title": "Deactivate Mapping",
        "delete_supplier_description": "If you delete this supplier from your mapping page, the mapping of your product will be deleted.",
        offline_error_tip:'El producto está fuera de línea',
        mapping_info_error_tip:'Hay un problema con los datos del producto que estás mapeando, por favor, vuelve a mapear',
        Findsimilar:'Encuentre similares',
        distoryImgTip:`La imagen actual del producto no puede ser identificada, y la función de Optimizador de Proveedores no puede ser utilizada por el momento. Disculpe las molestias.`
    }
}