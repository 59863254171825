import about from './es/about.js';
import banner from './es/banner.js';
import archeved from './es/archeved.js';
import bannerV2 from './es/bannerV2.js';
import bargain from './es/bargain.js';
import chore from './es/chore.js';
import combo from './es/combo.js';
import common from './es/common.js';
import completePassWord from './es/completePassWord.js';
import csv from './es/csv.js';
import duplicateOrderConfirm from './es/duplicateOrderConfirm.js';
import errorCashbackCode from './es/errorCashbackCode.js';
import admitad from './es/admitad.js';
import fulfillmentCenter from './es/fulfillmentCenter.js';
import guidance from './es/guidance.js';
import h5Callback from './es/h5Callback.js';
import h5LandModal from './es/h5LandModal.js';
import ad_1688 from './es/ad_1688.js';
import header from './es/header.js';
import helpCenter from './es/helpCenter.js';
import home from './es/home.js';
import importList from './es/importList.js';
import impotList from './es/impotList.js';
import h5Redirect from './es/h5Redirect.js';
import mapping from './es/mapping.js';
import login from './es/login.js';
import market from './es/market.js';
import menu from './es/menu.js';
import newFeature from './es/newFeature.js';
import aeAccountDisconnect from './es/aeAccountDisconnect.js';
import notification from './es/notification.js';
import order from './es/order.js';
import partner from './es/partner.js';
import paypal from './es/paypal.js';
import pricing from './es/pricing.js';
import product from './es/product.js';
import productEdit from './es/productEdit.js';
import productPool from './es/productPool.js';
import footer from './es/footer.js';
import publicIntl from './es/public.js';
import publicDocument from './es/publicDocument.js';
import rapport from './es/rapport.js';
import refreshMask from './es/refreshMask.js';
import register from './es/register.js';
import noticePlanBanner from './es/noticePlanBanner.js';
import reward from './es/reward.js';
import selectPlatform from './es/selectPlatform.js';
import sendResetEmail from './es/sendResetEmail.js';
import settingSupply from './es/setting-supply.js';
import setting from './es/setting.js';
import shopPush from './es/shopPush.js';
import supplierOptimizer from './es/supplierOptimizer.js';
import resetPassword from './es/resetPassword.js';
import support from './es/support.js';
import top from './es/top.js';
import topStores from './es/topStores.js';
import tracking from './es/tracking.js';
import tutorialOverModal from './es/tutorialOverModal.js';
import productSplit from './es/productSplit.js';
import linkStore from './es/linkStore.js';
import suppliers from './es/suppliers.js';
import whyDisconnect from './es/whyDisconnect.js';
import guide from './es/guide';
import migration from './es/migration';
import onBoarding from './es/onBoarding.js';
import tmallTestExpress from './es/tmallTestExpress';
import beforeMigration from './es/beforeMigration';

export default {
    ...beforeMigration,
    ...about,
    ...admitad,
    ...banner,
    ...bannerV2,
    ...bargain,
    ...chore,
    ...combo,
    ...common,
    ...csv,
    ...completePassWord,
    ...duplicateOrderConfirm,
    ...archeved,
    ...footer,
    ...fulfillmentCenter,
    ...guidance,
    ...h5Callback,
    ...h5LandModal,
    ...errorCashbackCode,
    ...header,
    ...helpCenter,
    ...importList,
    ...home,
    ...impotList,
    ...linkStore,
    ...login,
    ...mapping,
    ...market,
    ...aeAccountDisconnect,
    ...newFeature,
    ...noticePlanBanner,
    ...notification,
    ...order,
    ...partner,
    ...paypal,
    ...pricing,
    ...menu,
    ...productEdit,
    ...productPool,
    ...productSplit,
    ...publicIntl,
    ...rapport,
    ...product,
    ...refreshMask,
    ...register,
    ...publicDocument,
    ...reward,
    ...sendResetEmail,
    ...settingSupply,
    ...setting,
    ...shopPush,
    ...supplierOptimizer,
    ...suppliers,
    ...support,
    ...top,
    ...topStores,
    ...selectPlatform,
    ...ad_1688,
    ...tutorialOverModal,
    ...tracking,
    ...h5Redirect,
    ...whyDisconnect,
    ...resetPassword,
    ...guide,
    ...migration,
    ...onBoarding,
    ...tmallTestExpress
}
