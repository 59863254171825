export default {
  pricing: {
    title: 'More Value with Less Cost',
    desc: 'Free plan available',
    tipSelectPlan: 'Please select a plan to continue',
    Basic: 'Basic',
    // BasicText: 'Improve efficiency to get a good start (Forever free)',
    BasicText:'(Forever free)',
    Advanced: 'Advanced',
    // AdvancedText:
    //   'Automate your business to the next level (14 days free trial)',
    AdvancedText: ' days free trial',
    No_card_AdvancedText: 'No credit card needed',
    No_card_ProText: 'No credit card needed',
    No_card_StandardText: 'No credit card needed',
    Standard: 'Standard',
    // StandardText: 'Grow your business to new heights (14 days free trial)',
    StandardText:' days free trial',
    Pro: 'Pro',
    // ProText:'Scale your business with more possibilities (14 days free trial)',
    ProText:' days free trial' ,
    Business: 'Business',
    Enterprise: 'Enterprise',
    Admonth: '<span class="mount mounttop">$</span><span class="mountP">19.9</span><span class="mount">/month</span>',
    Prmonth: '<span class="mount mounttop">$</span>49.9<span class="mount">/month</span>',
    Stmonth: `<span class="mount mounttop">$</span>1.99<span class="mount">/month</span>`,
    Enmonth:`<span class="mount mounttop">$</span>499<span class="mount">/month</span>`,
    NewStmonth:`<span class="mount mounttop">$</span>9.9<span class="mount">/month</span>`,
    // Adannually: '<span class="mount">$</span><span class="mountP">190.8</span><span class="mount">/year</span>',
    // Prannually: '<span class="mount">$</span>478.8<span class="mount">/year</span>',
    // Stannually: `<span class="mount">$</span>23.88<span class="mount">/year</span>`,
    Adannually: '<span class="mount mounttop">$<s class="deleter">19.9</s></span><span class="mountP">15.9</span><span class="mount">/month</span>',
    Prannually: '<span class="mount mounttop">$<s class="deleter">49.9</s></span>39.9<span class="mount">/month</span>',
    Stannually: `<span class="mount mounttop">$</span>1.99<span class="mount">/month</span>`,
    Enannually:`<span class="mount mounttop">$<span class="startat">Start at</span><s class="deleterStart">499</s></span>399<span class="mount">/month</span>`,
    NewStannually:`<span class="mount mounttop">$<s class="deleter">9.9</s></span>7.9<span class="mount">/month</span>`,
    AdannuallyText: '$15.9/month <br /> Billed at $190.8 annually <br/> You save $48/year ',
    AdannuallyText_2:'$15.9/month <br /> Billed at $190.8 annually <br/> 2 months OFF ! ', 
    AdannuallyText_3:'Billed anually at $190.8 <br/> 2 months OFF !',
    PrannuallyText:'$39.9/month <br /> Billed at $478.8 annually <br /> You save $120/year ',
    PrannuallyText_2:'$39.9/month <br /> Billed at $478.8 annually <br /> 2 months OFF ! ',
    PrannuallyText_3:' Billed anually at $478.8 <br /> 2 months OFF !',
    // StannuallyText: '$1.99/month <br /> Billed at $23.88 annually ',
    StannuallyText:'Billed anually at $23.88',
    EnannuallyText:'Billed anually at $4,788 <br /> 2 months OFF !',
    NewStannuallyText:'Billed anually at $94.9 <br /> 2 months OFF !',
    Free: 'Free',
    Monthlyfee: 'Monthly Fee',
    Annualfee: 'Annual fee',
    Feature: 'Feature',
    enterprise_current_plan: 'Your current subscription plan: {plan}',
    EnDescribe:'Contact us to get more information',
    circulationData: [
      {
        Feature: 'Process Orders in Bulk',
        explain: 'Process multiple orders at once to AliExpress',
        Basic: 'Available',
        BasicText: '',
        Standard: 'Available',
        StandardText: '',
        AdvancedL: '<span class="blone and">Available & With high speed to process 100+ orders</span>',
        AdvancedText: '',
        Pro: '<span class="blone and">Available & With high speed to process 100+ orders</span>',
        ProText: '',
        Enterprise:'<span class="blone and">Available & With high speed to process 100+ orders</span>',
        EnterpriseText:'',
        NewStandard:'Available',
        NewStandardText:'',
        CustomBasic:'Available'
      },
      {
        Feature: 'Tracking',
        explain: `Track the logistics status of each order`,
        Basic: "<span class='line2'>—</span>",
        BasicText: '',
        Standard: "<span class='line2'>—</span>",
        StandardText: '',
        AdvancedL: 'ok',
        AdvancedText: '',
        Pro: 'ok',
        ProText: '',
        Enterprise:'ok',
        EnterpriseText:'',
        NewStandard:"<span class='line2'>—</span>",
        NewStandardText:'',
        CustomBasic:"<span class='line2'>—</span>"
      },
      {
        Feature: 'Customer support',
        explain: '24*7 live chat &  email service',
        Basic: 'Available',
        BasicText: '',
        Standard: 'Available',
        StandardText: '',
        AdvancedL: '<span class="blone and">Available & First priority</span>',
        AdvancedText: '',
        Pro: '<span class="blone and">Available & First priority</span>',
        ProText: '',
        Enterprise:'<span class="blone and">Available & First priority</span>',
        EnterpriseText:'',
        NewStandard:'Available',
        NewStandardText:'',
        CustomBasic:'Available'
      },
      {
        Feature: 'Product limit',
        explain: 'Manage products coming from AliExpress and Shopify',
        Basic: `<span class="blone">3000 </span> /account`,
        BasicText: '',
        Standard: `<span class="blone">7500</span>  /account`,
        StandardText: '',
        AdvancedL: `<span class="blone and ">20000 </span> /account`,
        AdvancedText: '',
        Pro: `<span class="blone and ">75000 </span> /account`,
        ProText: '',
        Enterprise:'<span class="blone and ">100,000 </span> /account',
        EnterpriseText:'',
        NewStandard:'<span class="blone">3000 </span> /account',
        NewStandardText:'',
        CustomBasic:'<span class="blone">500 </span> /account'
      },
      {
        Feature: 'Store Limit',
        explain:
          'Manage multiple stores in one DSers account',
        Basic: `<span class="blone">3</span>`,
        BasicText: '',
        Standard: `<span class="blone">5</span>`,
        StandardText: '',
        AdvancedL: `<span class="blone and">10</span>`,
        AdvancedText: '(Popular for merchants selling in different countries)',
        Pro: `<span class="blone and">25</span>`,
        ProText: '(Popular for merchants selling in different niches and countries)',
        Enterprise:'<span class="blone and">50</span>',
        EnterpriseText:'',
        NewStandard:'<span class="blone">3</span>',
        NewStandardText:'',
        CustomBasic:'<span class="blone">3</span>'
      },
      {
        Feature: 'Paypal limit',
        explain: 'Manage multiple Paypal accounts in one DSers account',
        Basic: `<span class="blone">1 </span> /account`,
        BasicText: '',
        Standard: `<span class="blone">1</span>  /account`,
        StandardText: '',
        AdvancedL: `<span class="blone and ">2 </span> /account`,
        AdvancedText: '',
        Pro: `<span class="blone and ">5 </span> /account`,
        ProText: '',
        Enterprise:'<span class="blone and ">10</span> /account',
        EnterpriseText:'',
        NewStandard:'<span class="blone">1 </span> /account',
        NewStandardText:'',
        CustomBasic:'<span class="blone">1 </span> /account'
      },
      {
        Feature: 'Variants Mapping',
        explain: 'Manage as many suppliers as you want without changing anything on your store',
        Basic: 'Up to 2 suppliers per product and no target region mapping.',
        BasicText: '',
        Standard: 'Up to 2 suppliers per product and no target region mapping.',
        StandardText: '',
        AdvancedL: '<span class="blone and">Map each variant according to the target region</span>',
        AdvancedText: '',
        Pro: '<span class="blone and">Map each variant according to the target region</span>',
        ProText: '',
        Enterprise:'<span class="blone and">Map each variant according to the target region</span>',
        EnterpriseText:'',
        NewStandard:'Up to 2 suppliers per product and no target region mapping.',
        NewStandardText:'',
        CustomBasic:'Up to 2 suppliers per product and no target region mapping.'
      },
      {
        Feature: 'Advanced Mapping',
        explain: 'More flexible supplier shipping locations',
        Basic: `<span class='line2'>—</span>`,
        BasicText: '',
        Standard: `<span class='line2'>—</span>`,
        StandardText: '',
        AdvancedL: '<span class="blone and">Set up different suppliers according to the different destination countries</span>',
        AdvancedText: '',
        Pro: '<span class="blone and">Set up different suppliers according to the different destination countries</span>',
        ProText: '',
        Enterprise:'<span class="blone and">Set up different suppliers according to the different destination countries</span>',
        EnterpriseText:'',
        NewStandard:`<span class='line2'>—</span>`,
        NewStandardText:'',
        CustomBasic:`<span class='line2'>—</span>`
      },
      {
        Feature: 'BOGO/Bundle Mapping',
        explain: `Create product's upsell to get more sales`,
        Basic: "<span class='line2'>—</span>",
        BasicText: '',
        Standard: "<span class='line2'>—</span>",
        StandardText: '',
        AdvancedL: 'ok',
        AdvancedText: '',
        Pro: 'ok',
        ProText: '',
        Enterprise:'ok',
        EnterpriseText:'',
        NewStandard:"<span class='line2'>—</span>",
        NewStandardText:'',
        CustomBasic:"<span class='line2'>—</span>"
      },
      {
        Feature: 'Manually change products price in bulk',
        explain: 'Manually change products price in bulk according to the pricing rules you currently set.',
        Basic: '1 time/30 days',
        BasicText: '',
        Standard: '1 time/30 days',
        StandardText: '',
        AdvancedL: '<span class="blone and">4 times/30 days</span>',
        AdvancedText: '',
        Pro: '<span class="blone and">10 times/30 days</span>',
        ProText: '',
        Enterprise:'<span class="blone and">10 times/30 days</span>',
        EnterpriseText:'',
        NewStandard:'1 time/30 days',
        NewStandardText:'',
        CustomBasic:'1 time/30 days'
      },
      {
        Feature: 'Automated Pricing Rule',
        explain: 'Make sure you will be profitable in selling any products for which it is applied to.',
        Basic: `Fixed or percentage increase only`,
        BasicText: '',
        Standard: `Fixed or percentage increase only`,
        StandardText: '',
        AdvancedL: `<span style="color:#FF8F00;font-weight:600">Rules based on on profit margins, shipping costs, etc.</span>`,
        AdvancedText: '',
        Pro: `<span style="color:#FF8F00;font-weight:600">Rules based on on profit margins, shipping costs, etc.</span>`,
        ProText: '',
        Enterprise:'<span style="color:#FF8F00;font-weight:600">Rules based on on profit margins, shipping costs, etc.</span>',
        EnterpriseText:'',
        NewStandard:'Fixed or percentage increase only',
        NewStandardText:'',
        CustomBasic:`Fixed or percentage increase only`
      },
      {
        Feature: 'Inventory Management',
        explain: 'Automatically update the inventory to Shopify when inventory changes',
        Basic: `Notification only`,
        BasicText: '',
        Standard: `<span style="color:#FF8F00;font-weight:600">Automatic update</span>`,
        StandardText: '',
        AdvancedL: `<span style="color:#FF8F00;font-weight:600">Automatic update</span>`,
        AdvancedText: '',
        Pro: `<span style="color:#FF8F00;font-weight:600">Automatic update</span>`,
        ProText: '',
        Enterprise:'<span style="color:#FF8F00;font-weight:600">Automatic update</span>',
        EnterpriseText:'',
        NewStandard:`<span style="color:#FF8F00;font-weight:600">Automatic update</span>`,
        NewStandardText:'',
        CustomBasic:`<span style="color:#FF8F00;font-weight:600">Automatic update</span>`
      },
      {
        Feature: 'Automatic Price Update',
        explain: 'Automatically synchronize the updated price to Shopify',
        Basic: `Notification only`,
        BasicText: '',
        Standard: `<span style="color:#FF8F00;font-weight:600">Automatic update</span>`,
        StandardText: '',
        AdvancedL: `<span style="color:#FF8F00;font-weight:600">Automatic update</span>`,
        AdvancedText: '',
        Pro: `<span style="color:#FF8F00;font-weight:600">Automatic update</span>`,
        ProText: '',
        Enterprise:'<span style="color:#FF8F00;font-weight:600">Automatic update</span>',
        EnterpriseText:'',
        NewStandard:`<span style="color:#FF8F00;font-weight:600">Automatic update</span>`,
        NewStandardText:'',
        CustomBasic:`<span style="color:#FF8F00;font-weight:600">Automatic update</span>`
      },
      {
        Feature: 'Staff Account',
        explain: `Enable Your employees to log in to DSers through staff account`,
        Basic: "<span class='line2'>—</span>",
        BasicText: '',
        Standard: "<span class='line2'>—</span>",
        StandardText: '',
        AdvancedL: '<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">5</span>',
        AdvancedText: '',
        Pro: '<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Unlimited</span>',
        ProText: '',
        Enterprise:'<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Unlimited</span>',
        EnterpriseText:'',
        NewStandard:"<span class='line2'>—</span>",
        NewStandardText:'',
        CustomBasic:"<span class='line2'>—</span>"
      },
      {
        Feature:'Customization',
        explain:'Customize DSers feature & get higher usage volume.',
        Basic: "<span class='line2'>—</span>",
        BasicText: '',
        Standard: "<span class='line2'>—</span>",
        StandardText: '',
        AdvancedL: "<span class='line2'>—</span>",
        AdvancedText: '',
        Pro: "<span class='line2'>—</span>",
        ProText: '',
        Enterprise:'<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Extra fee may be generated based on specific request or usage volume.</span>',
        EnterpriseText:'',
        NewStandard:"<span class='line2'>—</span>",
        NewStandardText:'',
        CustomBasic:"<span class='line2'>—</span>"
      },
      {
        Feature: 'Send product and shipping cost to Shopify',
        explain: ``,
        Basic: "<span class='line2'>—</span>",
        BasicText: '',
        Standard: "<span class='line2'>—</span>",
        StandardText: '',
        AdvancedL: `<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">When the product is pushed, the supplier products cost and the shipping cost in [Advanced Pricing Rule] are returned to Shopify's cost per item together.</span>`,
        AdvancedText: '',
        Pro: `<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">When the product is pushed, the supplier products cost and the shipping cost in [Advanced Pricing Rule] are returned to Shopify's cost per item together.</span>`,
        ProText: '',
        Enterprise:`<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">When the product is pushed, the supplier products cost and the shipping cost in [Advanced Pricing Rule] are returned to Shopify's cost per item together.</span>`,
        EnterpriseText:'',
        NewStandard:"<span class='line2'>—</span>",
        NewStandardText:'',
        CustomBasic:"<span class='line2'>—</span>"
      },
      {
        Feature: 'Synchronize Shopify order customization information',
        explain: ``,
        Basic: "<span class='line2'>—</span>",
        BasicText: '',
        Standard: "<span class='line2'>—</span>",
        StandardText: '',
        AdvancedL: `<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Send the customization information from Shopify to suppliers along with the order.</span>`,
        AdvancedText: '',
        Pro: `<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Send the customization information from Shopify to suppliers along with the order.</span>`,
        ProText: '',
        Enterprise:`<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Send the customization information from Shopify to suppliers along with the order.</span>`,
        EnterpriseText:'',
        NewStandard:"<span class='line2'>—</span>",
        NewStandardText:'',
        CustomBasic:"<span class='line2'>—</span>"
      },
      {
        Feature: 'Affiliate',
        explain: 'Contact us',
        Basic: "<span class='line2'>—</span>",
        BasicText: '',
        Standard: "<span class='line2'>—</span>",
        StandardText: '',
        AdvancedL: 'ok',
        AdvancedText: '',
        Pro: 'ok',
        ProText: '',
        Enterprise:'ok',
        EnterpriseText:'',
        NewStandard:"<span class='line2'>—</span>",
        NewStandardText:'',
        CustomBasic:"<span class='line2'>—</span>"
      }
   
    ],
    // pricingIncludesTitle:`BASIC, STANDARD, ADVANCED, AND PRO PLANS INCLUDE`,
    pricingIncludesTitle:' FEATURES INCLUDED IN ALL PLANS',
    pricingIncludesData:[
      {
        "title":"Shipping Method Pre-selection ",
        "desc":"Set up automated shipping methods for AliExpress orders based on countries, products or conditions"
      },
      {
        "title":"Automated Address Fixing ",
        "desc":"Fix most of the address issues automatically to reduce labor work"
      },
      {
        "title":"Automated Order Status Updates",
        "desc":"No more manually sync via Chrome Extension. Everything is automated"
      },
      {
        "title":"Auto Sync Tracking Numbers to Shopify",
        "desc":"Auto-sync tracking numbers from AliExpress and update to Shopify"
      },
      {
        "title":"Auto Sync Tracking Numbers to PayPal",
        "desc":"Improve your reputation by sending tracking numbers to your PayPal account"
      },
      {
        "title":"DSers Chrome Extension",
        "desc":"AliExpress product importer and bulk order processing with received coupons"
      },
      {
        "title":"Automated Message to Suppliers",
        "desc":"Send message template to let your suppliers know about your requests"
      },
      {
        "title":"Send Customized Order Notes from Customers to Suppliers ",
        "desc":"Especially popular with users running POD business"
      },
      {
        "title":"Set up Tracking in Shipping Confirmation Email ",
        "desc":"Customize tracking information in our Shopify email "
      },
      {
        "title":"Find Trending Suppliers ",
        "desc":"Trending and qualified suppliers collection on AliExpress "
      },
      {
        "title":"Supplier Optimizer",
        "desc":"Find the best supplier for specific products in one click "
      },
      {
        "title":"Import List ",
        "desc":"Manage the products you import to DSers and easily migrate products between your stores "
      },
      {
        "title":"Split Product ",
        "desc":"Split a product into several different products to customize your product listings "
      },
      {
        "title":"Publish products to store in bulk",
        "desc":"Publish AliExpress selected products to your store after edition"
      },
      {
        "title":"Time Zone & Currency Settings ",
        "desc":"Set up time zone and currencies for your store "
      }
    ],
    Unfinishedfront: `You have not finished the authorization on Shopify.<br />
    Please click`,
    Unfinishedcentre: 'Subscribe',
    Unfinishedqueen: 'to continue.',
    Unfinished: `If you want to switch your plan of DSers, please first cancel the current<br />
    subsciption on Shopify as shown below and you will be able to reselect plan.`,
    UnfinishedNote: `Note: <br />
    1.You won't be charged until 31st Dec, 2020.<br />
    2.Please DO NOT uninstall the DSers app in Shopify, simply CANCEL the current subscription. <br />
    if you want to switch plans. Uninstallation will cause data loss on DSers.`,

    AlertText:
      'Please upgrade to get more features and possibilities. Check more details about <a href="https://help.dsers.com/subscription-plans-presentation-for-shopify/" target="_blank" > DSers plan </a> .',
    pop_Up: 'Please select the store you will use to be billed for DSers',
    display: 'Recommended',
    HomeBasicButton: 'Get Started',
    HomeAdvancedButton: 'Start free trial',
    HomeProButton: 'Start free trial',
    HomeStandardButton: 'Start free trial',
    Select: 'Select',
    Current: 'Current Plan',
    Confirm: 'Confirm',
    ConfirmCancel: 'Return',
    Access: 'Access',
    AccessText:
      'Thank you for your subscription. Please access your DSers account, enjoy!',
    Monthly: 'Monthly Payment Plan',
    Annually: 'Annually Payment Plan',
    working: 'Thank you for working with DSers.',
    like: 'Did you like it?',
    over: 'Your 14 days free trial is over.',
    confirm:
      'Please click the button below to confirm the annual subscription on Shopify.',
    Contnct: 'Contact',
    Subscribe: 'Subscribe',
    ChangePlan: 'Change plan',
    selectShop:
      'Please select what you want to keep in the current DSers plan.',
    keepShop: 'Please select at most {quantity} stores that you want to keep.',
    PlatformAndStores: 'Platform and stores',
    ShopifyPlatform: 'Shopify platform',
    Woocommerceplatform: 'WooCommerce platform',
    errText:
      "The default billing store { mistakeDomain } is unavailble. Please check the store's status on Shopify or change the default billing store on DSers.",
    errTextTwo:
      "The default billing store is unavailble. Please check the store's status on Shopify or change the default billing store on DSers.",
    billing: 'Default Billing Store',
    help:
      'The Billing Store is the store you use to pay your subscription to DSers. For a better experience, do not switch the Billing Store regularly.',
    StoreManagement: 'Store management',
    StoreManagementText:
      "If you decide to downgrade your plan, DSers will keep your deactivated stores here so you can disconnect the unwanted stores and activate other stores according to your plan. Once disconnected, you won't be able to add more stores than the restriction of different plans allows.",
    ActiveStore: 'Active store',
    InactiveStore: 'Inactive store',
    Activate: 'Activate',
    Inactivated: 'Inactivated',
    modification:
      'Our system detected that you have changed your default billing store to <span style="color:#00BFFF"> {new}  </span>, subscription under the store <span style="color:#00BFFF"> {old} </span> is expired now. Please click the button below to confirm the default billing store on Shopify.',
    go_to_shopify: 'Go to Shopify',
    if_free_dec: `If you are a Shopify free trial user or Shopify development store user,<br />
                  you may not be able to see the page above but will see the page below.<br />
                  If you do not know how to cancel the current subscription,<br />
                  please go to Shopify admin to uninstall DSers and install again to reselect plan`,
    change_annual_plan_notion:
      'If you want to change your current annual plan, please contact our customer service on <a href="https://www.messenger.com/t/DSersOfficial" dd="src" target="_blank" rel="noopener noreferrer">Facebook</a> or by <a href="https://www.messenger.com/t/DSersOfficial">email</a> to get refund for the remaining cost of your current plan',
    subscribe_tios: `The default billing store is closed or deactivated. Please go
    <a href="/app/select"> Manage subscriotion </a>
    to change it and try again`,
    subscription_expired:
      'Your subscription has expired, please renew or change it to continue.',
    subscription_network_tip:
      'If you encounter some network error or any other problems during the payment process, and the subscription is unsuccessful,please <a style="color:#00BFFF" href={jumpUrl}>click here</a> to retry. You can also contact us and we will deal with it for you as soon as possible.',
    subscription_network_tip_2:'If you encounter some network error or any other problems during the payment process, and the subscription is unsuccessful, please contact us and we will deal with it for you as soon as possible.',
    subscription_btn3:`The first month will be`,
    subscription_btn4:`FREE!`,
    subscription_btn5:`Only for today!`,
    subscription_btn6:`If you upgrade <span class="titlesd">Standard</span>  now, you will able to: <br/> Add <span class="titlesd">2</span> more stores <br/> <span class="titlesd">4,500</span> more products <br/> The product inventory will be automatically updated`,
    subscription_btn7:`UPGRADE NOW`,
    subscription_btn8:`Keep Basic Plan`,
    subscription_btn9:`Upgrade to our Annual payment plan`,
    subscription_btn10:`SAVE $120!`,
    subscription_btn11:`Monthly Plan`,
    subscription_btn12:`<span class="blon">49.9</span>/mo`,
    subscription_btn13:`Yearly Plan`,
    subscription_btn14:`<span class="blon">478.8</span>/year`,
    subscription_btn15:`Monthly cost: <span class="blon_d">$39.9</span>`,
    subscription_btn16:`14 days free trial`,
    subscription_btn17:`SELECT`,
    select_account:'Please select the  ',
    select_account_2:'staff accounts you want to keep',
    custom_pro:'Custom Pro',
    Custom_EnterPrise:'Custom EnterPrise',
    //oberlo
    custom_basic:'Custom Basic',
    custom_to_basic:'Switch to Basic plan',
    tobasictext:'CONFIRM SWITCH',
    oCancel:'CANCEL',
    tobaiscnote:'Note',
    tobaiscdes:'After you downgrade to the Basic plan, you will have a 3000 Product limit, but you will no longer enjoy the automatic synchronization of inventory and prices, and you will not be able to switch back to the current Custom Basic. Are you sure you want to switch?',
    "BasicTracking":"basic + tracking",
    "Basic500":"Basic+500",
    "Basic1000":"Basic+1000",
    "CustomBasicTracking":"Custom Basic+Tracking",
    "CustomBasic500":"Custom Basic+500",
    "CustomBasic1000":"Custom Basic+1000",
  }
};
