export default {
  guide: {
    // 升级弹窗文案
    CommonGuide: [
      {
        type: 1,
        title: 'Upgrade Plan',
        content:'Your current plan is Basic, please upgrade to Advanced plan if you want to get access to this feature, advanced mapping and 24 hours customer service.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'UPGRADE',
        otherBtn: 'Cancel'
      },
      {
        type: 2,
        title: 'Upgrade Plan',
        content:'Your current plan is Advanced, please upgrade to Pro plan if you want to get access to this feature and management of over 70,000+ products.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'UPGRADE',
        otherBtn: 'Cancel'
      },
      {
        type: 3,
        title: 'Customization',
        content:'Your current plan is the highest, if you want to increase limits, we can offer you customized services.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Contact us',
        otherBtn: 'Cancel'
      },
      {
        type: 4,
        title: 'Upgrade Plan',
        content:'Your current plan is Standard, please upgrade to Advanced plan if you want to get access to this feature, advanced mapping and 24 hours customer service.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'UPGRADE',
        otherBtn: 'Cancel'
      },
      {
        type: 5,
        title: 'Customization',
        content:'Your current plan is the highest, if you want to increase limits, we can offer you customized services.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Contact us',
        otherBtn: 'Cancel'
      },
      {
        type: 6,
        title: 'Customization',
        content:'Your current plan is the highest, if you want to increase limits, we can offer you customized services.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Contact us',
        otherBtn: 'Cancel'
      },
      {
        type: 7,
        title: 'Upgrade Plan',
        content:'Your current plan is Advanced, please upgrade to Pro plan if you want to get access to this feature and management of over 70,000+ products.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)', 
        mainBtn: 'UPGRADE',
        otherBtn: 'Cancel'
      },
      {
        type: 8,
        title: 'Upgrade Plan',
        content:'Your current plan is Standard, please upgrade to Advanced plan if you want to get access to this feature, advanced mapping and 24 hours customer service.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'UPGRADE',
        otherBtn: 'Cancel'
      },
      {
        type: 9,
        title: 'Customization',
        content:'Your current plan is the highest, if you want to increase limits, we can offer you customized services.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Contact us',
        otherBtn: 'Cancel'
      },
      {
        type: 10,
        title: 'Upgrade Plan',
        content:'Your current plan is Custom Basic, please upgrade to Advanced plan if you want to get access to this feature, advanced mapping and 24 hours customer service.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'UPGRADE',
        otherBtn: 'Cancel'
      },
    ],
    // 开屏弹窗文案
    OBDialog: {
      bindAE: {
        top:
          'We are happy to see you join us! We have migrated successfully all your data, orders, products and settings.',
        topTitle: 'Welcome to DSers!',
        centerTitle: 'Migration Successful!',
        btn: 'GOT IT'
      },
      noBindAE: {
        top:
          'We are happy to see you join us! We have migrated successfully all your data, orders, products and settings.',
        centerTitle: 'Migration Successful!',
        topTitle: 'Welcome to DSers!',
        bottom: [
          'To start using DSers, please link your AliExpress account.'
        ],
        btn: 'LINK ALIEXPRESS'
      }
    },
    //底部banner文案
    OBanberWords: [
      {
        // 绑定AE
        step: 1,
        title: 'Link your AliExpress account',
        text:
          "Linking your AliExpress account to DSers allows you to place 100+orders in seconds! Click <a target='_blank' href='https://help.dsers.com/link-your-shopify-store/'>here</a> for more details.",
        btn: 'LINK ALIEXPRESS'
      },
      {
        // 确认套餐
        step: 2,
        title: 'Keep your Oberlo habits',
        textArr: [
          {
            type: 1,
            text:
              "Access to all features of Oberlo, and enjoy more advanced features like muti-store management.Click <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>here</a> for more details."
          },
          {
            type: 2,
            text:
              "Access to all Oberlo-like features, and enjoy more advanced features like advanced mapping and 24 hours customer service. Click <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>here</a> for more details."
          },
          {
            type: 3,
            text:
              "Access to all Oberlo-like features, and enjoy more resources like management of over 70,000+ products. Click <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>here</a> for more details."
          }
        ],
        btn: 'ACTIVATE PLAN'
      },
      {
        // 安装插件
        step: 3,
        title: 'Install DSers Chrome Extension',
        text:
          "One-click product import from AliExpress to DSers to save time and find new best sellers! Click <a target='_blank' href='https://help.dsers.com/import-product-from-aliexpress-for-shopify/#Use DSers Chrome Extension'>here</a> for more details.",
        btn: 'DOWNLOAD'
      }
    ],
    // 确认弹窗文案
    ComfirmPop: [
      {
        kind: 'AE',
        title: 'Link your AliExpress account',
        content:
          "Linking your AliExpress account to DSers allows you to place 100+orders in seconds! Click <a target='_blank' href='https://help.dsers.com/link-your-shopify-store/'>here</a> for more details.",
        mainBtn: 'LINK ALIEXPRESS'
      },
      {
        kind: 'PLAN',
        title: 'Keep your Oberlo habits',
        contentArr: [
          {
            type: 1,
            content:
              "Access to all features of Oberlo, and enjoy more advanced features like muti-store management.Click <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>here</a> for more details."
          },
          {
            type: 2,
            content:
              "Access to all Oberlo-like features, and enjoy more advanced features like advanced mapping and 24 hours customer service. Click <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>here</a> for more details."
          },
          {
            type: 3,
            content:
              "Access to all Oberlo-like features, and enjoy more resources like management of over 70,000+ products. Click <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>here</a> for more details."
          }
        ],
        errorBillStore:
          "You can’t activate the plan because your billing store is unavailable. Please switch billing store on DSers - <a target='_blank' href='https://www.dsers.com/app/select'>Manage Subscription</a> page or activate the billing store on ",
        errorBillStoreNotId: "You can’t activate the plan because your billing store is unavailable. Please switch billing store on DSers - <a target='_blank' href='https://www.dsers.com/app/select'>Manage Subscription</a> page",
        errorBillName: 'Shopify admin',
        mainBtn: 'ACTIVATE PLAN'
      },
      {
        kind: 'CHROME',
        title: 'Install DSers Chrome Extension',
        content:
          "One-click product import from AliExpress to DSers to save time and find new best sellers! Click <a target='_blank' href='https://help.dsers.com/import-product-from-aliexpress-for-shopify/#Use DSers Chrome Extension'>here</a> for more details.",
        mainBtn: 'DOWNLOAD'
      }
    ],
    // 刷新弹窗文案
    RefreshPopText: {
      title: 'Refresh page',
      content:
        'Please refresh the page so that we can detect whether you have successfully installed the plugin',
      btn: 'REFRESH'
    },
    // 提示用户必要信息文案
    OberloTopTipOneBindAE:"Your data is being corrected, you can migrate other Oberlo accounts once the data correction is completed.",
    OberloTopTipOneNoBindAE:'Please link your AliExpress account to DSers so that we can correct your orders status, you can continue to migrate other Oberlo accounts once the correction is completed.',
    OberloTopTipTwo:"Please note that DSers displays all the Shopify orders, while Oberlo displayed only the orders that contained products created through Oberlo.</br>" +
    "DSers migrated orders from the past two months. If you need to synchronize more orders, please <a style='color:#3E5BFE' href='https://www.messenger.com/t/DSersOfficial' target='_blank'>contact us.</a>"
  }
};
