export default {
  import_list: {
    title: 'Lista de Importação',
    dec: 'Você pode importar e gerenciar seus produtos AliExpress aqui antes de publicá-los em sua loja Shopify. Você pode publicar um produto em diferentes lojas e adicionar várias tags em cada produto. Clique <a href={ href } target="_blank"> aqui </a> para aprender mais. After you import products to DSers, only the price and AliExpress inventory of the product will be automatically updated. If you have imported a product for a long time, in order to ensure the consistency of the product information and the supplier, it is recommended that you check the supplier information before pushing this product.',
    dec_change:`Você pode importar e gerenciar os produtos dos seus fornecedores aqui antes de enviá-los à sua loja Shopify.`,
    dec_change_tit:`Você pode publicar um produto em lojas diferentes e adicionar múltiplas tags a cada um deles. Após importar os produtos no DSers, somente o preço e o estoque do fornecedor serão atualizados automaticamente no DSers. Caso você tenha importado um produto há muito tempo, é recomendável que consulte as informações do fornecedor antes de publicá-lo para garantir a consistência das informações.`,
    search_ali_placeholder:
      'Importe produtos do AliExpress pela URL ou pesquise produtos aqui',
    search_tm_placeholder:`Importe produtos do Tmall pela URL ou pesquise produtos aqui`,
    add_tags: 'Apply DSers Tags',
    filter: 'Filtrar',
    filter_product:'Filtrar produtos',
    filter_by_stores: 'Filtrar por lojas',
    filter_by_tags: 'Filtrar por tags',
    shopping_info: 'Shipping info',
    tag_management: 'Create DSers Tags',
    pagefly:
      'Want to get more sales? Try PageFly to build high-converting product sales pages.',
    pushed_to: 'Enviado para',
    store_s: 'loja (s)',
    add_manage_tags: 'Create and manage DSers tags',
    add_manage_tags2: 'Apply DSers Tags',
    add_management_tags:'You can apply tags to group your products.',
    add_manage_tags_tip: 'You can create or delete tags to group your products. Click <a href={ href } target="_blank">here</a> to get more information.',
    add_manage_tags_tip3:`You can filter your products by stores, tags and push status.`,
    add_new_tags: 'Adicionar novas Tags',
    push_modal_title: 'Push Products',
    push_modal_desc:'Select the store(s) you want to push your products to and check the boxes below to select the rules to apply to your products.',
    no_store: 'Nenhuma loja',
    from_oberlo_product_tip:'<text>Detectamos que você enviou um produto ao Shopify que foi migrado do Oberlo ao DSers.</text> Como resultado, a ',
    from_oberlo_product_tip_1:' regra de preço',
    from_oberlo_product_tip_2:' não se aplica ao produto. Caso queira usar a regra de preço em vez dos preços editados, marque esta opção.',
    pricing_rule_tip_2: ' aplicada a este produto. Se você editou manualmente o preço do produto no DSers, desmarque esta caixa.',
    pricing_rule_tip_1:'Regra de preço',
    pricing_rule_close_tip: 'Nosso sistema detectou que você editou manualmente o preço deste produto no DSers. Como resultado, a ',
    pricing_rule_close_tip_1:'regra de preços',
    pricing_rule_close_tip_2:' não se aplicará a este produto. Se você deseja usar a regra de preços em vez dos preços editados, marque esta caixa.',
    select_pricing_rule_mean_tip:'Observação: a regra de preço inclui modelos de precificação, moeda e atribuição de centavos. Ao marcar esta opção, a regra de preço será aplicada aos preços de seus produtos de acordo com as regras de preço definidas na página “Configurar”.',
    unselect_pricing_rule_mean_tip:'Observação: a regra de preço inclui modelos de precificação, moeda e atribuição de centavos. Ao desmarcar esta opção, nenhum desses três recursos será aplicado aos seus produtos.',
    product_s: 'Produto(s)',
    link_to_shopify: 'Conectar ao Shopify',
    no_product_tip: 'Importe o seu primeiro item',
    no_product_tip_1: `1. Acesse “<a href='/app/find_suppliers'>Encontrar fornecedores</a> ” e copie a URL do item`,
    no_product_tip_2: '2. Cole a URL que você copiou na caixa de inserção na lista de importação',
    search_no_product_tip: `Nenhum resultado encontrado. Altere os critérios de pesquisa e tente novamente`,
    tag_manage_delete_tip:
      'Deseja excluir esta tag? Ele desaparecerá de todos os produtos marcados.',
    add_product_tip: 'Conecte o DSers a uma conta do AliExpress ou você não poderá importar produtos.',
    fetch_ae_prod_error: `The quantity of products you imported to DSers has reached the "Product limit" of your current subscription plan. Please upgrade your subscription to import more products`,
    fetch_ae_prod_error1: `A quantidade de produtos do AliExpress que você importou no DSers atingiu seu limite diário. Tente novamente amanhã.`,
    fetch_tm_prod_error1: `A quantidade de produtos Tmall que você importou no DSers atingiu seu limite diário. Tente novamente amanhã.`,
    ae_issue: 'AliExpress is facing some issues, please try again later',
    delete_product_tip:
      'Tem certeza que deseja remover os produtos? Eles serão removidos da lista de importação, mas permanecerão em sua loja Shopify.',
    ae_prod_not_found: 'The product is unavailable on AliExpress, please import another product.',
    service_unavaliable: 'The operation failed due to network issue, please try again.',
    pushing: 'Sending product(s)',
    push_success: 'Product(s) sent',
    push_success1: 'Product(s) sent',
    push_fail:'Falha na realização do envio',
    push_desc: 'produtos sendo enviados',
    push_error_0: 'O produto contém mais de 100 SKUs',
    push_error_1: 'Número máximo de criação de SKUs atingido no Shopify',
    push_error_2: 'Loja Shopify indisponível',
    push_error_3: 'Token da loja expirado',
    push_error_4: 'As informações do seu produto excedem 256 KB',
    push_error_4_tip: `As informações do produto incluem título, descrição, imagens, SKU etc. É recomendável que você exclua algumas informações do produto antes de enviá-lo à loja`,
    push_error_5: '9.Tempo de solicitação excedido, tente novamente mais tarde',
    push_desc_ok: 'Envio realizado com sucesso',
    import_review: 'Import Reviews',
    push_desc_fail: 'Falha na realização do envio',
    check_detail:`Check Product Detail`,
    check_push: 'Verificar status',
    addTag:'Create a new tag',
    confim:'Confirm',
    edit_detail:`Edit Product Detail`,
    delete_product:`Delete Product`,
    variants_limit:"Shopify daily variant creation limit reached. Please try again later & click <a href='{url}' target='_blank'>here</a> for more details.",
    pushTitle_1:'Seleção de loja',
    pushTitle_2:'Preferências',
    pushTitle_3:'Detalhes de envio',
    repeated_tag:'This tag already existed',
    search_or_import:'Importar ou pesquisar',
    filter_by_market_place:'Filtrar por marketplaces',
  },
  product_split: {
    split: 'Dividir',
    title: 'Split product',
    split_by_option: 'Dividir pela opção',
    split_by_option_dec:
      'Select the option that will be split to another product',
    option_tip:
      'Nós criaremos {length} produto(s), cada produto terá somente {optionName} variante(s).',
    or: 'ou',
    split_by_variant: 'Dividir por variante',
    split_by_variant_dec:
      'Escolha as variantes que serão divididas para outro produto',
    tip:
      'The selected variants will be used to create a new product and the current product will still have all the original variants.',
    select_split_mothod: 'This product cannot be splitted again',
    confirm_split: 'Split Product',
    confirm_split_desc: 'Are you sure you want to split the product? It will be split into different products.',
    product_not_found: 'The product you entered was not found',
    network_issue: 'The operation failed due to network issue, please try again',
    
  },
};
