export default {
  linkStore: {
    website_platform: 'What is your website platform? *',
    website_domain: 'What is your website domain? *',
    domain_placeholder: 'your-domain',
    addonAfter: '.myshopify.com',
    add_store: 'Add store',
    go_account: 'Enter DSers account',
    add_store_err:
      'This store is linked to a DSers account. Please double check.',
    add_store_already:
      'The store is already linked to another DSers account. Please double check.',
    valid_domain_msg:
      'Please make sure to fill in English letter, number and hyphen only for the store name.',
    install_plugin_title:
      'Please install the DSers plugin to experience a more stable service',
    bind_ae_tip_1: 'Not familiar with AliExpress dropshipping?',
    bind_ae_tip_url: 'https://www.dsers.com/blog/aliexpress-dropshipping-101/',
    bind_ae_tip_2: `Don't have an AliExpress  account?`,
    process: {
      link_store: 'Link Store',
      choose_plan: 'Choose Plan',
      link_ae: 'Link AliExpress',
      install_plugin: 'Install Plugin'
    },
    loading_Info: 'Do not refresh or close the page while linking',
    bindAeTitle:
      'Click the AliExpress icon below to go to AliExpress for account authorization.',
    bindAeTitleTwo: 'Select the option below to connect your suppliers.',
    required: 'Required',
    linkSt: `Collega ad AliExpress`
  },
  modalTitle: 'Authorizing',
  modalText:
    'Please click on "Add App" in Shopify. After completing the authorization, click on "Update", and we will refresh the page for you. If you see "App Installed" in Shopify, it means that this Shopify account is already linked to DSers. If you want to link another store, please log in the Shopify store you want to link. Click <a href="https://help.dsers.com/link-your-store-for-shopify/" style="color:#3d5afe">here</a> to learn more.',
  modalButton: 'UPDATE',
  enterDsersTitle: 'Purchasing from other platform than AliExpress?',
  enterDsersTooltip:
    'Click the button below to go directly to your DSers account. You can choose from tens of thousands of Tmall items curated for you by DSers, or directly connect with your existing suppliers and let DSers manage your offline orders.',
  clickEnter: 'Click to enter DSers',
  aeModalTitle: 'Link your AliExpress account',
  aeModalText:
    'Linking your AliExpress account to DSers allows you to place 100+orders in seconds! Click here for more details.',
  aeModalBut: 'LINK ALIEXPRESS'
};
