import { Post, Get, Put, syncGet, Delete } from '../utils/request';

export async function getMyProductList(payload) {
  return Get('/prod/list', payload);
}

export async function updateMyProduct(payload) {
  return Post('/prod/update', payload);
}

export async function removeMyProduct(payload) {
  return Post('/prod/remove', payload);
}

export async function setMyProductStatus(payload) {
  return Post('/prod/publish', payload);
}

export async function syncProduct(payload) {
  return Post('/prod/sync', payload);
}

export async function productLink(payload) {
  return syncGet('/shopify/shopinfo', payload);
}

export async function addProduct(payload) {
  return Post('/prod/import', payload);
}

export async function getGuidance(payload) {
  return Get('/leadpagestatus', payload);
}

export async function postGuidance(payload) {
  return Post('/leadpagestatus', payload);
}

export async function getDeputySupplierOption(payload) {
  return Get('/product/supplier_list', payload);
}

export async function getDeputySupplierHistory(payload) {
  return Get('/product/supplier_pool', payload);
}

export async function getOptionHistory(payload) {
  return Post('/product/supplier_pool', payload);
}

export async function getOptionDelete(payload) {
  return Put('/product/supplier_pool', payload);
}

export async function getOptionSaveSku(payload) {
  return Post('/product/supplier_sku', payload);
}

export async function getSupplierCountry(payload) {
  return Get('/product/supplier_country', payload);
}

export async function getFreights(payload) {
  return Post('/prod/freight', payload);
}

export async function getSaveSupplierShipping(payload) {
  return Post('/product/supplier_shipping', payload);
}

export async function getShopifyList(payload) {
  return Get('/prod/shopify', payload);
}

export async function getImportFlag(payload) {
  return Get('/users/import_flag', payload);
}

export async function getWhiteListId(payload) {
  return Post('/product/feature/ids', payload);
}

export async function importToDsers(payload) {
  return Post('/prod/fetch', payload);
}

export async function removeDsersManage(payload) {
  return Post('/product/feature/remove', payload);
}
export async function SupplyChain(payload = {}) {
  return Get('/supply/user/status', payload);
}

export async function postSupplyChain(payload = {}) {
  return Post('/supply/user/status', payload);
}

export async function getVerifiedList(payload = {}) {
  return Get('/supply/product/list', payload);
}

export async function getVerifiedSave(payload = {}) {
  return Post('/supply/product', payload);
}

export async function getProductOpen(payload = {}) {
  return Put('/supply/product/open', payload);
}

export async function getverifiedItem(payload = {}) {
  return Get('/supply/product', payload);
}

export async function getBalance(payload = {}) {
  return Get('/supply/setting/balance', payload);
}

export async function getAddress(payload = {}) {
  return Get('/supply/setting/address_info', payload);
}

export async function getUserBlockStatus(payload) {
  return Post('/user/blk/status', payload);
}

export async function getAeProductDetail(payload) {
  return Get('/prod/ali', payload);
}
export async function getTmProductDetail(payload) {
  return Get('/prod/tmall', payload);
}
export async function getAeProductDetailInfo(payload) {
  return Get('/prod/mapping_info', payload);
}

export async function updataExt(payload) {
  return Post('/prod/bind', payload);
}

export async function getCountryList(payload) {
  return Get('/set/country', payload);
}

export async function getShopifyProductDetail(payload) {
  return Get('/prod/detail', payload);
}

export async function getCollections(payload) {
  return Get('/prod/cols', payload);
}

export async function getAllCost(payload) {
  return Get('/prod/price/detail', payload);
}

export async function subMappingInvite(payload) {
  return Post('/invite/check', payload);
}

export async function getImportHistory(payload) {
  return Get('/prod/map/list', payload);
}

export async function postSplitProduct(payload) {
  return Post('/prod/split', payload);
}

export async function getBlackList(payload = {}) {
  return Put('/prod/black_list', payload);
}

export async function postAEDataAuthorization(payload = {}) {
  return Post('/users/aeback', payload);
}

export async function postFeedBack(payload = {}) {
  return Post('/users/feedback', payload);
}

export async function postFeedBackRateUs(payload = {}) {
  return Post('/users/feedback/rate_us', payload);
}
export async function getProdocutPool(payload) {
  return Get('/prod/propool', payload);
}

export async function getProdocutCategory(payload) {
  return Get('/prod/propool/types', payload);
}
export async function postFeedBackStatus(payload = {}) {
  return Post('/users/feedback/status', payload);
}

// 导入商品
export async function productSelect(payload = {}) {
  return Post('/prod/select', payload);
}

// 商品查询
export async function getSelectProductList(payload = {}) {
  return Post('/prod/select/list', payload);
}

// 商品修改
export async function productEdit(payload) {
  return Put('/prod/select', payload);
}

// 删除商品
export async function productDelete(payload = {}) {
  return Delete('/prod/select', payload);
}
// 批量下载图片
export async function downloadImgs(payload = {}) {
  return Post('/product/downloadimg', payload);
}

// 商品拆分
export async function productSelectSplit(payload = {}) {
  return Post('/prod/select/split', payload);
}

// tag创建
export async function createTag(payload = {}) {
  return Post('/prod/tag', payload);
}
// tag 查询
export async function getProductTag(payload) {
  return Get('/prod/tag', payload);
}

// tag修改
export async function tagEdit(payload) {
  return Put('/prod/tag', payload);
}
// tag 删除
export async function tagDelete(payload = {}) {
  return Delete('/prod/tag', payload);
}
// 商品push到shopify
export async function productPush(payload = {}) {
  return Post('/prod/select/push', payload);
}

// 获取商品详情
export async function getDetailsPage(payload) {
  return Get('/prod/select', payload);
}
// 修改tags
export async function productSelectTags(payload) {
  return Put('/prod/select/tags', payload);
}

export async function productSelectAddTags(payload) {
  return Put('/prod/select/add_tags', payload);
}

// 限制接口

export async function LimitTheInterface(payload) {
  return Get('/charge/limit', payload);
}

// 支付计划
export async function disbursementSchedule(payload) {
  return Get('/charge/plan', payload);
}

export async function getStoreSettings(payload) {
  return Get('/prod/select/element', payload);
}

export async function saveMoreOrganizationData(payload = {}) {
  return Post('/prod/select/element', payload);
}

export async function pushProduct(payload = {}) {
  return Post('/prod/select/push_list', payload);
}

export async function pushCheck(payload = {}) {
  return Post('/prod/select/push_product_status', payload);
}

// 获取自动更新价格开关状态
export async function getPriceCheck(payload = {}) {
  return Post('/set/auto_sync_price/get', payload);
}

// 提交自动更新价格状态
export async function setPriceCheck(payload = {}) {
  return Put('/prod/sync_price', payload);
}

// 提交自动更新内容
export async function setPriceSubmit(payload = {}) {
  return Post('/set/auto_sync_price/update', payload)
}

// 获取商品池
export async function getProductList(payload = {}) {
  return Get('/prod/mapping_pool', payload)
}

// 导入商品池
export async function setProductList(payload = {}) {
  return Post('/prod/mapping_pool', payload)
}

// 删除商品池
export async function deletePorductList(payload = {}) {
  return Delete('/prod/mapping_pool', payload)
}

// 定向获取商品ae
export async function getAeData(payload = {}) {
  return Get('/prod/replace', payload)
}

// replace商品
export async function replaceProduct(payload = {}) {
  return Post('/prod/replace', payload)
}

// 查询替换状态
export async function getReplaceTime(payload = {}) {
  return Post('/prod/get_replace_status', payload)
}

//轮询价格更新状态 -importlist
export async function getUpdatePrice(payload = {}){
  return Get('/prod/poll/import_list/price/refresh',payload)
}

//轮询价格更新状态 -myproduct
export async function getUpdatePrice_mp(payload = {}){
  return Get('/prod/poll/my_product/price/refresh',payload)
}