import React, { PureComponent } from 'react';
import { connect } from 'dva';
import { Link } from 'dva/router';
import { Form, Input, Button, Popover, Alert, Tooltip } from 'antd';
import intl from 'react-intl-universal';
import ReactGA from 'react-ga';
import classNames from 'classnames';
import Cookies from 'js-cookie';
import { Post } from 'utils/request';

import reactGaEvent from '../../utils/GAEvent';
import checkPasswordStrength from './checkPasswordStrength';
import CheckPasswordSafety from './CheckPasswordSafety';
import { passwordReg, emailRegExp } from 'utils/constants';
import { getCookie } from 'utils/utils';

import styles from './AccountSuccess.less';
import csv from 'locale/en/csv';

import { webCommonCollect } from 'utils/utils';

const FormItem = Form.Item;

@connect(({ login, loading }) => ({
  login,
  submitting:
    loading.effects['register/submit'] || loading.effects['register/pureSubmit']
}))
@Form.create()
export default class AccountSuccess extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      confirmDirty: false,
      visible: false,
      help: '',
      username: '',
      password: '',
      privacyPolicyChecked: false,
      ishidden:'none',
      childEmail:''
    };
  }

  componentDidMount = () => {
    const { dispatch } = this.props;
    this.getEmail()
    this.alicode();
    this.setAntModalMaskStyle();
    dispatch({
      type: 'login/clearLoginData'
    });

    ReactGA.modalview('/app/register');
    if (localStorage.getItem('emailback') && localStorage.hasOwnProperty('emailback')) {
      this.setState({
        username:localStorage.getItem('emailback')
      })
    }

    if (localStorage.getItem('emailback') && 
    !emailRegExp.test(localStorage.getItem('emailback'))&&
    localStorage.hasOwnProperty('emailback')
    ) {
      this.setState({
        ishidden:'block'
      })
    }

  };

  getEmail = ()=>{
    var url = location.search
    if (url.indexOf("?") != -1) {
      var str = url.substr(1);
      var type = str.split('=')[1]
      console.log(type)
      this.setState({
        childEmail:type
      })
    }
    
  }

  setAntModalMaskStyle = () => {
    const loginDialog = document.getElementsByClassName('loginDialog')[0];
    if (loginDialog) {
      loginDialog.previousElementSibling.id = 'loginDialogMask';
    }
  };

  getPasswordStatus = () => {
    const { form } = this.props;
    const value = form.getFieldValue('newpassword');
    if (this.checkEveryWordIsSame(value)) {
      return 'poor';
    }
    if (value && value.length > 9) {
      return 'ok';
    }
    if (value && value.length > 5) {
      return 'pass';
    }
    return 'poor';
  };

  getQueryString = name => {
    const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
    let hash = '';
    if (location.hash.split('?')[1]) {
      hash = `?${location.hash.split('?')[1]}`;
    } else if (location.search) {
      hash = location.search;
    }
    const r = hash.substr(1).match(reg);
    if (r != null) {
      return unescape(r[2]);
    }
    return null;
  };

  handleSubmit = e => {
    e.preventDefault();

    let custom_info = [];

    if (Cookies.get('registerBtn')) {
      custom_info.push({
        name: 'siteFreeBtnReferrer',
        value: `${Cookies.get('registerBtn')}`
      });
    }

    let referrer = Cookies.get('referrer');

    this.props.form.validateFields((err, values) => {
      if (!err) {
        custom_info.push({
          name: 'email',
          value: values.email
        });

        window.DSERS_COLLECT({
          custom_info: custom_info
        });
        this.props.onSubmit({
          data: {
            email:this.state.childEmail,
            ...values,
            refer: referrer || '',
            channel_id: this.getQueryString('channel_id'),
          }
        });
      }
    });

    reactGaEvent({
      category: 'Sign up',
      action: 'get started'
    });
  };

  checkEveryWordIsSame = v => {
    if (!v) {
      return false;
    }
    const one = v.substring(0, 1);
    const len = v.length;
    const d = new Array(len).fill(one).join('');
    let flag = false;

    if (d == v && len > 5) {
      flag = true;
    }
    return flag;
  };

  AliLogin = () => {
    this.props.dispatch({
      type: 'login/ali',
      payload: {
        data: {
          code: this.getQueryString('code'),
          channel_id: this.getQueryString('channel_id')
        },
        isOutApi: true
      }
    });
  };

  checkPassword = (rule, value, callback) => {
    try {
      const status = checkPasswordStrength(value);
      if (value) {
        if (status === 'Strong' || status === 'VeryStrong') {
          this.setState({ visible: false });
        } else {
          this.setState({ visible: true });
        }
      } else {
        this.setState({ visible: false });
      }

      if (!value) {
        callback(intl.get('login.empty'));
      } else if (value.length < 8 || value.length > 16) {
        this.setState({ infoOne: true });
        callback(intl.get('register.password_error_long'));
      } else {
        callback();
      }
      // if(checkPasswordStrength(value) > 4){
      //   this.setState({infoOne:false,infoTwo:false,})
      //   callback('');
      // }
    } catch (err) {
      callback(err);
    }
  };

  alicode = () => {
    if (location.hash.includes('code')) {
      this.AliLogin();
    }
  };

  goShopify = () => {
    reactGaEvent({
      category: 'Sign up',
      action: 'Shopify广告位'
    });
    window.open('https://apps.shopify.com/dsers', '_blank');
  };

  changeUsername = e => {
    this.setState({
      username: e.target.value
    });
    this.setState({
      ishidden:'none'
    })
  };

  changePassword = e => {
    this.setState({
      password: e.target.value
    });
  };

  changePrivacy = e => {
    this.setState({
      privacyPolicyChecked: e.target.checked
    });
  };

  renderMessage = content => {
    return (
      <Alert
        style={{ marginBottom: 24 }}
        message={content}
        type="error"
        showIcon
      />
    );
  };

  getStart = async () => {
    let name = '';
    // const url = location.search;
    // if (url.indexOf('?') !== -1) {
    //   const str = url.substr(1);
    //   const strs = str.split('=');
    //   name = strs[1];
    // }

    // window.localStorage.setItem('gaTest', name);

    if (window.localStorage.getItem('gaTest')) {
      name = window.localStorage.getItem('gaTest');
    }

    const custom_info = [{ name, value: 'OK' }];

    window.DSERS_COLLECT({
      action: 'register',
      custom_info
    });
  };

  inputName = null;
  inputPassword = null;

  disableInput = () => {
    let input = document.querySelector('#email');
    input?.setAttribute('type', 'email');
    input?.setAttribute('readonly', 'true');

    this.inputName = setTimeout(() => {
      input?.removeAttribute('readonly');
      input.focus();
    }, 300);
  };

  disableInputPassword = () => {
    let input = document.querySelector('#password');
    input?.setAttribute('readonly', 'true');

    this.inputPassword = setTimeout(() => {
      input?.removeAttribute('readonly');
      input.focus();
    }, 300);
  };

  render() {
    const { form, submitting } = this.props;
    const { getFieldDecorator } = form;
    const {
      username,
      password,
      privacyPolicyChecked,
      infoOne,
      infoTwo
    } = this.state;

    

    return (
      <div className={styles.RegWraper}>
        <div className={styles.Regmain}>
          <h1>{intl.get('register.accountSuccess')}</h1>
          <Form onSubmit={this.handleSubmit}>
            <div>
              <FormItem>
                  <Input
                    readOnly="true"
                    size="large"
                    // type="password"
                    value={this.state.childEmail}
                    disabled={true}
                  />
              </FormItem>

              <FormItem style={{ margin: 0 }}>
              <Tooltip
                  title={
                    <div className={styles.proverInfo}>
                      <div>{intl.get('register.password_error_long')}</div>
                      {/* <div>2.{intl.get('register.password_error_format')}</div> */}
                    </div>
                  }
                  placement="left"
                  visible={this.state.visible}
                >
                  {getFieldDecorator('password', {
                    // validateTrigger: 'onBlur',
                    rules: [
                      {
                        validator: this.checkPassword
                      }
                    ]
                  })(
                    <Input.Password
                      className={password ? styles.pwInput : styles.pwInputInit}
                      size="large"
                      readOnly="true"
                      type="password"
                      autoComplete="new-password"
                      placeholder={intl.get('register.password')}
                      onBlur={() => {
                        clearTimeout(this.inputPassword);
                      }}
                      onFocus={() => {
                        this.disableInputPassword();
                        reactGaEvent({
                          category: 'Sign up',
                          action: 'Password_inputbox'
                        });
                      }}
                      on
                      onChange={e => {
                        this.changePassword(e);
                      }}
                    />
                  )}
                  </Tooltip>
                <span
                  className={styles.placeholder}
                  style={{
                    opacity: password ? 1 : 0,
                    transform: password
                      ? 'translateY(-15px)'
                      : 'translateY(-7px)'
                  }}
                >
                  {intl.get('register.password')}
                </span>
              </FormItem>
            </div>
            <FormItem>
              <Button
                size="large"
                loading={submitting}
                className={styles.submit}
                type="primary"
                htmlType="submit"
                onClick={this.getStart}
              >
                {intl.get('register.get_started')}
              </Button>
            </FormItem>
          </Form>
        </div>
      </div>
    );
  }
}
