export default [
    {
      "label": "(GMT-12:00) International Date Line West",
      "value": "Etc/GMT+12"
    },
    {
      "label": "(GMT-11:00) Samoa",
      "value": "US/Samoa"
    },
    {
      "label": "(GMT-11:00) Niue",
      "value": "Pacific/Niue"
    },
    {
      "label": "(GMT-11:00) Midway Island",
      "value": "Pacific/Midway"
    },
    {
      "label": "(GMT-11:00) GMT+11",
      "value": "Etc/GMT+11"
    },
    {
      "label": "(GMT-11:00) American Samoa",
      "value": "Pacific/Pago_Pago"
    },
    {
      "label": "(GMT-10:00) Tahiti",
      "value": "Pacific/Tahiti"
    },
    {
      "label": "(GMT-10:00) Rarotonga",
      "value": "Pacific/Rarotonga"
    },
    {
      "label": "(GMT-10:00) Johnston",
      "value": "Pacific/Johnston"
    },
    {
      "label": "(GMT-10:00) Honolulu",
      "value": "Pacific/Honolulu"
    },
    {
      "label": "(GMT-10:00) Hawaii",
      "value": "US/Hawaii"
    },
    {
      "label": "(GMT-10:00) HST",
      "value": "HST"
    },
    {
      "label": "(GMT-10:00) GMT+10",
      "value": "Etc/GMT+10"
    },
    {
      "label": "(GMT-10:00) Atka",
      "value": "America/Atka"
    },
    {
      "label": "(GMT-10:00) Aleutian",
      "value": "US/Aleutian"
    },
    {
      "label": "(GMT-10:00) Adak",
      "value": "America/Adak"
    },
    {
      "label": "(GMT-09:30) Marquesas",
      "value": "Pacific/Marquesas"
    },
    {
      "label": "(GMT-09:00) Yakutat",
      "value": "America/Yakutat"
    },
    {
      "label": "(GMT-09:00) Sitka",
      "value": "America/Sitka"
    },
    {
      "label": "(GMT-09:00) Nome",
      "value": "America/Nome"
    },
    {
      "label": "(GMT-09:00) Metlakatla",
      "value": "America/Metlakatla"
    },
    {
      "label": "(GMT-09:00) Juneau",
      "value": "America/Juneau"
    },
    {
      "label": "(GMT-09:00) Gambier",
      "value": "Pacific/Gambier"
    },
    {
      "label": "(GMT-09:00) GMT+9",
      "value": "Etc/GMT+9"
    },
    {
      "label": "(GMT-09:00) Anchorage",
      "value": "America/Anchorage"
    },
    {
      "label": "(GMT-09:00) Alaska",
      "value": "US/Alaska"
    },
    {
      "label": "(GMT-08:00) Vancouver",
      "value": "America/Vancouver"
    },
    {
      "label": "(GMT-08:00) Tijuana",
      "value": "America/Tijuana"
    },
    {
      "label": "(GMT-08:00) Santa_Isabel",
      "value": "America/Santa_Isabel"
    },
    {
      "label": "(GMT-08:00) Pitcairn",
      "value": "Pacific/Pitcairn"
    },
    {
      "label": "(GMT-08:00) Pacific Time (US & Canada)",
      "value": "America/Los_Angeles"
    },
    {
      "label": "(GMT-08:00) Pacific",
      "value": "US/Pacific"
    },
    {
      "label": "(GMT-08:00) PST8PDT",
      "value": "PST8PDT"
    },
    {
      "label": "(GMT-08:00) GMT+8",
      "value": "Etc/GMT+8"
    },
    {
      "label": "(GMT-08:00) Ensenada",
      "value": "America/Ensenada"
    },
    {
      "label": "(GMT-08:00) BajaNorte",
      "value": "Mexico/BajaNorte"
    },
    {
      "label": "(GMT-07:00) Yukon",
      "value": "Canada/Yukon"
    },
    {
      "label": "(GMT-07:00) Yellowknife",
      "value": "America/Yellowknife"
    },
    {
      "label": "(GMT-07:00) Whitehorse",
      "value": "America/Whitehorse"
    },
    {
      "label": "(GMT-07:00) Shiprock",
      "value": "America/Shiprock"
    },
    {
      "label": "(GMT-07:00) Phoenix",
      "value": "America/Phoenix"
    },
    {
      "label": "(GMT-07:00) Ojinaga",
      "value": "America/Ojinaga"
    },
    {
      "label": "(GMT-07:00) Navajo",
      "value": "Navajo"
    },
    {
      "label": "(GMT-07:00) Mountain Time (US & Canada)",
      "value": "America/Denver"
    },
    {
      "label": "(GMT-07:00) Mountain",
      "value": "Canada/Mountain"
    },
    {
      "label": "(GMT-07:00) Mazatlan",
      "value": "America/Mazatlan"
    },
    {
      "label": "(GMT-07:00) MST7MDT",
      "value": "MST7MDT"
    },
    {
      "label": "(GMT-07:00) MST",
      "value": "MST"
    },
    {
      "label": "(GMT-07:00) Inuvik",
      "value": "America/Inuvik"
    },
    {
      "label": "(GMT-07:00) Hermosillo",
      "value": "America/Hermosillo"
    },
    {
      "label": "(GMT-07:00) GMT+7",
      "value": "Etc/GMT+7"
    },
    {
      "label": "(GMT-07:00) Fort_Nelson",
      "value": "America/Fort_Nelson"
    },
    {
      "label": "(GMT-07:00) Edmonton",
      "value": "America/Edmonton"
    },
    {
      "label": "(GMT-07:00) Dawson_Creek",
      "value": "America/Dawson_Creek"
    },
    {
      "label": "(GMT-07:00) Dawson",
      "value": "America/Dawson"
    },
    {
      "label": "(GMT-07:00) Creston",
      "value": "America/Creston"
    },
    {
      "label": "(GMT-07:00) Chihuahua",
      "value": "America/Chihuahua"
    },
    {
      "label": "(GMT-07:00) Cambridge_Bay",
      "value": "America/Cambridge_Bay"
    },
    {
      "label": "(GMT-07:00) Boise",
      "value": "America/Boise"
    },
    {
      "label": "(GMT-07:00) BajaSur",
      "value": "Mexico/BajaSur"
    },
    {
      "label": "(GMT-07:00) Arizona",
      "value": "US/Arizona"
    },
    {
      "label": "(GMT-06:00) Winnipeg",
      "value": "America/Winnipeg"
    },
    {
      "label": "(GMT-06:00) Tell_City",
      "value": "America/Indiana/Tell_City"
    },
    {
      "label": "(GMT-06:00) Tegucigalpa",
      "value": "America/Tegucigalpa"
    },
    {
      "label": "(GMT-06:00) Swift_Current",
      "value": "America/Swift_Current"
    },
    {
      "label": "(GMT-06:00) Saskatchewan",
      "value": "Canada/Saskatchewan"
    },
    {
      "label": "(GMT-06:00) Resolute",
      "value": "America/Resolute"
    },
    {
      "label": "(GMT-06:00) Regina",
      "value": "America/Regina"
    },
    {
      "label": "(GMT-06:00) Rankin_Inlet",
      "value": "America/Rankin_Inlet"
    },
    {
      "label": "(GMT-06:00) Rainy_River",
      "value": "America/Rainy_River"
    },
    {
      "label": "(GMT-06:00) New_Salem",
      "value": "America/North_Dakota/New_Salem"
    },
    {
      "label": "(GMT-06:00) Monterrey",
      "value": "America/Monterrey"
    },
    {
      "label": "(GMT-06:00) Merida",
      "value": "America/Merida"
    },
    {
      "label": "(GMT-06:00) Menominee",
      "value": "America/Menominee"
    },
    {
      "label": "(GMT-06:00) Matamoros",
      "value": "America/Matamoros"
    },
    {
      "label": "(GMT-06:00) Managua",
      "value": "America/Managua"
    },
    {
      "label": "(GMT-06:00) Knox_IN",
      "value": "America/Knox_IN"
    },
    {
      "label": "(GMT-06:00) Knox",
      "value": "America/Indiana/Knox"
    },
    {
      "label": "(GMT-06:00) Indiana-Starke",
      "value": "US/Indiana-Starke"
    },
    {
      "label": "(GMT-06:00) Guadalajara, Mexico City",
      "value": "America/Mexico_City"
    },
    {
      "label": "(GMT-06:00) General",
      "value": "Mexico/General"
    },
    {
      "label": "(GMT-06:00) Galapagos",
      "value": "Pacific/Galapagos"
    },
    {
      "label": "(GMT-06:00) GMT+6",
      "value": "Etc/GMT+6"
    },
    {
      "label": "(GMT-06:00) El_Salvador",
      "value": "America/El_Salvador"
    },
    {
      "label": "(GMT-06:00) EasterIsland",
      "value": "Chile/EasterIsland"
    },
    {
      "label": "(GMT-06:00) Easter",
      "value": "Pacific/Easter"
    },
    {
      "label": "(GMT-06:00) Costa_Rica",
      "value": "America/Costa_Rica"
    },
    {
      "label": "(GMT-06:00) Central Time (US & Canada)",
      "value": "America/Chicago"
    },
    {
      "label": "(GMT-06:00) Central America",
      "value": "America/Guatemala"
    },
    {
      "label": "(GMT-06:00) Central",
      "value": "US/Central"
    },
    {
      "label": "(GMT-06:00) Center",
      "value": "America/North_Dakota/Center"
    },
    {
      "label": "(GMT-06:00) CST6CDT",
      "value": "CST6CDT"
    },
    {
      "label": "(GMT-06:00) Beulah",
      "value": "America/North_Dakota/Beulah"
    },
    {
      "label": "(GMT-06:00) Belize",
      "value": "America/Belize"
    },
    {
      "label": "(GMT-06:00) Bahia_Banderas",
      "value": "America/Bahia_Banderas"
    },
    {
      "label": "(GMT-05:00) Winamac",
      "value": "America/Indiana/Winamac"
    },
    {
      "label": "(GMT-05:00) Vincennes",
      "value": "America/Indiana/Vincennes"
    },
    {
      "label": "(GMT-05:00) Vevay",
      "value": "America/Indiana/Vevay"
    },
    {
      "label": "(GMT-05:00) Toronto",
      "value": "America/Toronto"
    },
    {
      "label": "(GMT-05:00) Thunder_Bay",
      "value": "America/Thunder_Bay"
    },
    {
      "label": "(GMT-05:00) Rio_Branco",
      "value": "America/Rio_Branco"
    },
    {
      "label": "(GMT-05:00) Porto_Acre",
      "value": "America/Porto_Acre"
    },
    {
      "label": "(GMT-05:00) Port-au-Prince",
      "value": "America/Port-au-Prince"
    },
    {
      "label": "(GMT-05:00) Petersburg",
      "value": "America/Indiana/Petersburg"
    },
    {
      "label": "(GMT-05:00) Pangnirtung",
      "value": "America/Pangnirtung"
    },
    {
      "label": "(GMT-05:00) Panama",
      "value": "America/Panama"
    },
    {
      "label": "(GMT-05:00) Nipigon",
      "value": "America/Nipigon"
    },
    {
      "label": "(GMT-05:00) Nassau",
      "value": "America/Nassau"
    },
    {
      "label": "(GMT-05:00) Montreal",
      "value": "America/Montreal"
    },
    {
      "label": "(GMT-05:00) Monticello",
      "value": "America/Kentucky/Monticello"
    },
    {
      "label": "(GMT-05:00) Michigan",
      "value": "US/Michigan"
    },
    {
      "label": "(GMT-05:00) Marengo",
      "value": "America/Indiana/Marengo"
    },
    {
      "label": "(GMT-05:00) Louisville",
      "value": "America/Kentucky/Louisville"
    },
    {
      "label": "(GMT-05:00) Lima, Quito",
      "value": "America/Lima"
    },
    {
      "label": "(GMT-05:00) Jamaica",
      "value": "America/Jamaica"
    },
    {
      "label": "(GMT-05:00) Iqaluit",
      "value": "America/Iqaluit"
    },
    {
      "label": "(GMT-05:00) Indiana (East)",
      "value": "America/Indiana/Indianapolis"
    },
    {
      "label": "(GMT-05:00) Havana",
      "value": "America/Havana"
    },
    {
      "label": "(GMT-05:00) Guayaquil",
      "value": "America/Guayaquil"
    },
    {
      "label": "(GMT-05:00) Grand_Turk",
      "value": "America/Grand_Turk"
    },
    {
      "label": "(GMT-05:00) GMT+5",
      "value": "Etc/GMT+5"
    },
    {
      "label": "(GMT-05:00) Fort_Wayne",
      "value": "America/Fort_Wayne"
    },
    {
      "label": "(GMT-05:00) Eirunepe",
      "value": "America/Eirunepe"
    },
    {
      "label": "(GMT-05:00) Eastern Time (US & Canada)",
      "value": "America/New_York"
    },
    {
      "label": "(GMT-05:00) Eastern",
      "value": "US/Eastern"
    },
    {
      "label": "(GMT-05:00) East-Indiana",
      "value": "US/East-Indiana"
    },
    {
      "label": "(GMT-05:00) EST5EDT",
      "value": "EST5EDT"
    },
    {
      "label": "(GMT-05:00) EST",
      "value": "EST"
    },
    {
      "label": "(GMT-05:00) Detroit",
      "value": "America/Detroit"
    },
    {
      "label": "(GMT-05:00) Cuba",
      "value": "Cuba"
    },
    {
      "label": "(GMT-05:00) Coral_Harbour",
      "value": "America/Coral_Harbour"
    },
    {
      "label": "(GMT-05:00) Cayman",
      "value": "America/Cayman"
    },
    {
      "label": "(GMT-05:00) Cancun",
      "value": "America/Cancun"
    },
    {
      "label": "(GMT-05:00) Bogota",
      "value": "America/Bogota"
    },
    {
      "label": "(GMT-05:00) Atikokan",
      "value": "America/Atikokan"
    },
    {
      "label": "(GMT-05:00) Acre",
      "value": "Brazil/Acre"
    },
    {
      "label": "(GMT-04:00) West",
      "value": "Brazil/West"
    },
    {
      "label": "(GMT-04:00) Virgin",
      "value": "America/Virgin"
    },
    {
      "label": "(GMT-04:00) Tortola",
      "value": "America/Tortola"
    },
    {
      "label": "(GMT-04:00) Thule",
      "value": "America/Thule"
    },
    {
      "label": "(GMT-04:00) St_Vincent",
      "value": "America/St_Vincent"
    },
    {
      "label": "(GMT-04:00) St_Thomas",
      "value": "America/St_Thomas"
    },
    {
      "label": "(GMT-04:00) St_Lucia",
      "value": "America/St_Lucia"
    },
    {
      "label": "(GMT-04:00) St_Kitts",
      "value": "America/St_Kitts"
    },
    {
      "label": "(GMT-04:00) St_Barthelemy",
      "value": "America/St_Barthelemy"
    },
    {
      "label": "(GMT-04:00) Santo_Domingo",
      "value": "America/Santo_Domingo"
    },
    {
      "label": "(GMT-04:00) Santiago",
      "value": "America/Santiago"
    },
    {
      "label": "(GMT-04:00) Puerto Rico",
      "value": "America/Puerto_Rico"
    },
    {
      "label": "(GMT-04:00) Porto_Velho",
      "value": "America/Porto_Velho"
    },
    {
      "label": "(GMT-04:00) Port_of_Spain",
      "value": "America/Port_of_Spain"
    },
    {
      "label": "(GMT-04:00) Montserrat",
      "value": "America/Montserrat"
    },
    {
      "label": "(GMT-04:00) Moncton",
      "value": "America/Moncton"
    },
    {
      "label": "(GMT-04:00) Martinique",
      "value": "America/Martinique"
    },
    {
      "label": "(GMT-04:00) Marigot",
      "value": "America/Marigot"
    },
    {
      "label": "(GMT-04:00) Manaus",
      "value": "America/Manaus"
    },
    {
      "label": "(GMT-04:00) Lower_Princes",
      "value": "America/Lower_Princes"
    },
    {
      "label": "(GMT-04:00) La Paz",
      "value": "America/La_Paz"
    },
    {
      "label": "(GMT-04:00) Kralendijk",
      "value": "America/Kralendijk"
    },
    {
      "label": "(GMT-04:00) Guadeloupe",
      "value": "America/Guadeloupe"
    },
    {
      "label": "(GMT-04:00) Grenada",
      "value": "America/Grenada"
    },
    {
      "label": "(GMT-04:00) Goose_Bay",
      "value": "America/Goose_Bay"
    },
    {
      "label": "(GMT-04:00) Glace_Bay",
      "value": "America/Glace_Bay"
    },
    {
      "label": "(GMT-04:00) Georgetown",
      "value": "America/Guyana"
    },
    {
      "label": "(GMT-04:00) GMT+4",
      "value": "Etc/GMT+4"
    },
    {
      "label": "(GMT-04:00) Dominica",
      "value": "America/Dominica"
    },
    {
      "label": "(GMT-04:00) Curacao",
      "value": "America/Curacao"
    },
    {
      "label": "(GMT-04:00) Cuiaba",
      "value": "America/Cuiaba"
    },
    {
      "label": "(GMT-04:00) Continental",
      "value": "Chile/Continental"
    },
    {
      "label": "(GMT-04:00) Caracas",
      "value": "America/Caracas"
    },
    {
      "label": "(GMT-04:00) Campo_Grande",
      "value": "America/Campo_Grande"
    },
    {
      "label": "(GMT-04:00) Boa_Vista",
      "value": "America/Boa_Vista"
    },
    {
      "label": "(GMT-04:00) Blanc-Sablon",
      "value": "America/Blanc-Sablon"
    },
    {
      "label": "(GMT-04:00) Bermuda",
      "value": "Atlantic/Bermuda"
    },
    {
      "label": "(GMT-04:00) Barbados",
      "value": "America/Barbados"
    },
    {
      "label": "(GMT-04:00) Atlantic Time (Canada)",
      "value": "America/Halifax"
    },
    {
      "label": "(GMT-04:00) Atlantic",
      "value": "Canada/Atlantic"
    },
    {
      "label": "(GMT-04:00) Asuncion",
      "value": "America/Asuncion"
    },
    {
      "label": "(GMT-04:00) Aruba",
      "value": "America/Aruba"
    },
    {
      "label": "(GMT-04:00) Antigua",
      "value": "America/Antigua"
    },
    {
      "label": "(GMT-04:00) Anguilla",
      "value": "America/Anguilla"
    },
    {
      "label": "(GMT-03:30) St_Johns",
      "value": "America/St_Johns"
    },
    {
      "label": "(GMT-03:30) Newfoundland",
      "value": "Canada/Newfoundland"
    },
    {
      "label": "(GMT-03:00) Ushuaia",
      "value": "America/Argentina/Ushuaia"
    },
    {
      "label": "(GMT-03:00) Tucuman",
      "value": "America/Argentina/Tucuman"
    },
    {
      "label": "(GMT-03:00) Stanley",
      "value": "Atlantic/Stanley"
    },
    {
      "label": "(GMT-03:00) Santarem",
      "value": "America/Santarem"
    },
    {
      "label": "(GMT-03:00) San_Luis",
      "value": "America/Argentina/San_Luis"
    },
    {
      "label": "(GMT-03:00) San_Juan",
      "value": "America/Argentina/San_Juan"
    },
    {
      "label": "(GMT-03:00) Salta",
      "value": "America/Argentina/Salta"
    },
    {
      "label": "(GMT-03:00) Rothera",
      "value": "Antarctica/Rothera"
    },
    {
      "label": "(GMT-03:00) Rosario",
      "value": "America/Rosario"
    },
    {
      "label": "(GMT-03:00) Rio_Gallegos",
      "value": "America/Argentina/Rio_Gallegos"
    },
    {
      "label": "(GMT-03:00) Recife",
      "value": "America/Recife"
    },
    {
      "label": "(GMT-03:00) Punta_Arenas",
      "value": "America/Punta_Arenas"
    },
    {
      "label": "(GMT-03:00) Paramaribo",
      "value": "America/Paramaribo"
    },
    {
      "label": "(GMT-03:00) Palmer",
      "value": "Antarctica/Palmer"
    },
    {
      "label": "(GMT-03:00) Nuuk",
      "value": "America/Nuuk"
    },
    {
      "label": "(GMT-03:00) Montevideo",
      "value": "America/Montevideo"
    },
    {
      "label": "(GMT-03:00) Miquelon",
      "value": "America/Miquelon"
    },
    {
      "label": "(GMT-03:00) Mendoza",
      "value": "America/Argentina/Mendoza"
    },
    {
      "label": "(GMT-03:00) Maceio",
      "value": "America/Maceio"
    },
    {
      "label": "(GMT-03:00) La_Rioja",
      "value": "America/Argentina/La_Rioja"
    },
    {
      "label": "(GMT-03:00) Jujuy",
      "value": "America/Argentina/Jujuy"
    },
    {
      "label": "(GMT-03:00) Greenland",
      "value": "America/Godthab"
    },
    {
      "label": "(GMT-03:00) GMT+3",
      "value": "Etc/GMT+3"
    },
    {
      "label": "(GMT-03:00) Fortaleza",
      "value": "America/Fortaleza"
    },
    {
      "label": "(GMT-03:00) East",
      "value": "Brazil/East"
    },
    {
      "label": "(GMT-03:00) Cordoba",
      "value": "America/Argentina/Cordoba"
    },
    {
      "label": "(GMT-03:00) ComodRivadavia",
      "value": "America/Argentina/ComodRivadavia"
    },
    {
      "label": "(GMT-03:00) Cayenne",
      "value": "America/Cayenne"
    },
    {
      "label": "(GMT-03:00) Catamarca",
      "value": "America/Argentina/Catamarca"
    },
    {
      "label": "(GMT-03:00) Buenos Aires",
      "value": "America/Argentina/Buenos_Aires"
    },
    {
      "label": "(GMT-03:00) Brasilia",
      "value": "America/Sao_Paulo"
    },
    {
      "label": "(GMT-03:00) Sao_Paulo",
      "value": "America/SaoPaulo"
    },
    {
      "label": "(GMT-03:00) Belem",
      "value": "America/Belem"
    },
    {
      "label": "(GMT-03:00) Bahia",
      "value": "America/Bahia"
    },
    {
      "label": "(GMT-03:00) Araguaina",
      "value": "America/Araguaina"
    },
    {
      "label": "(GMT-02:00) Noronha",
      "value": "America/Noronha"
    },
    {
      "label": "(GMT-02:00) Mid-Atlantic",
      "value": "Atlantic/South_Georgia"
    },
    {
      "label": "(GMT-02:00) GMT+2",
      "value": "Etc/GMT+2"
    },
    {
      "label": "(GMT-02:00) DeNoronha",
      "value": "Brazil/DeNoronha"
    },
    {
      "label": "(GMT-01:00) Scoresbysund",
      "value": "America/Scoresbysund"
    },
    {
      "label": "(GMT-01:00) GMT+1",
      "value": "Etc/GMT+1"
    },
    {
      "label": "(GMT-01:00) Cape Verde Is.",
      "value": "Atlantic/Cape_Verde"
    },
    {
      "label": "(GMT-01:00) Azores",
      "value": "Atlantic/Azores"
    },
    {
      "label": "(GMT+00:00) Abidjan",
      "value": "Africa/Abidjan"
    },
    {
      "label": "(GMT+00:00) Accra",
      "value": "Africa/Accra"
    },
    {
      "label": "(GMT+00:00) Bamako",
      "value": "Africa/Bamako"
    },
    {
      "label": "(GMT+00:00) Banjul",
      "value": "Africa/Banjul"
    },
    {
      "label": "(GMT+00:00) Belfast",
      "value": "Europe/Belfast"
    },
    {
      "label": "(GMT+00:00) Bissau",
      "value": "Africa/Bissau"
    },
    {
      "label": "(GMT+00:00) Canary",
      "value": "Atlantic/Canary"
    },
    {
      "label": "(GMT+00:00) Conakry",
      "value": "Africa/Conakry"
    },
    {
      "label": "(GMT+00:00) Dakar",
      "value": "Africa/Dakar"
    },
    {
      "label": "(GMT+00:00) Danmarkshavn",
      "value": "America/Danmarkshavn"
    },
    {
      "label": "(GMT+00:00) Dublin",
      "value": "Europe/Dublin"
    },
    {
      "label": "(GMT+00:00) Edinburgh, London",
      "value": "Europe/London"
    },
    {
      "label": "(GMT+00:00) Factory",
      "value": "Factory"
    },
    {
      "label": "(GMT+00:00) Faeroe",
      "value": "Atlantic/Faeroe"
    },
    {
      "label": "(GMT+00:00) Faroe",
      "value": "Atlantic/Faroe"
    },
    {
      "label": "(GMT+00:00) Freetown",
      "value": "Africa/Freetown"
    },
    {
      "label": "(GMT+00:00) GB",
      "value": "GB"
    },
    {
      "label": "(GMT+00:00) GB-Eire",
      "value": "GB-Eire"
    },
    {
      "label": "(GMT+00:00) GMT",
      "value": "Etc/GMT"
    },
    {
      "label": "(GMT+00:00) GMT+0",
      "value": "GMT+0"
    },
    {
      "label": "(GMT+00:00) GMT-0",
      "value": "Etc/GMT-0"
    },
    {
      "label": "(GMT+00:00) GMT0",
      "value": "GMT0"
    },
    {
      "label": "(GMT+00:00) Greenwich",
      "value": "Greenwich"
    },
    {
      "label": "(GMT+00:00) Guernsey",
      "value": "Europe/Guernsey"
    },
    {
      "label": "(GMT+00:00) Iceland",
      "value": "Iceland"
    },
    {
      "label": "(GMT+00:00) Isle_of_Man",
      "value": "Europe/Isle_of_Man"
    },
    {
      "label": "(GMT+00:00) Jersey",
      "value": "Europe/Jersey"
    },
    {
      "label": "(GMT+00:00) Lisbon",
      "value": "Europe/Lisbon"
    },
    {
      "label": "(GMT+00:00) Lome",
      "value": "Africa/Lome"
    },
    {
      "label": "(GMT+00:00) Madeira",
      "value": "Atlantic/Madeira"
    },
    {
      "label": "(GMT+00:00) Monrovia",
      "value": "Africa/Monrovia"
    },
    {
      "label": "(GMT+00:00) Nouakchott",
      "value": "Africa/Nouakchott"
    },
    {
      "label": "(GMT+00:00) Ouagadougou",
      "value": "Africa/Ouagadougou"
    },
    {
      "label": "(GMT+00:00) Portugal",
      "value": "Portugal"
    },
    {
      "label": "(GMT+00:00) Reykjavik",
      "value": "Atlantic/Reykjavik"
    },
    {
      "label": "(GMT+00:00) Sao_Tome",
      "value": "Africa/Sao_Tome"
    },
    {
      "label": "(GMT+00:00) St_Helena",
      "value": "Atlantic/St_Helena"
    },
    {
      "label": "(GMT+00:00) Timbuktu",
      "value": "Africa/Timbuktu"
    },
    {
      "label": "(GMT+00:00) Troll",
      "value": "Antarctica/Troll"
    },
    {
      "label": "(GMT+00:00) UCT",
      "value": "Etc/UCT"
    },
    {
      "label": "(GMT+00:00) UTC",
      "value": "UTC"
    },
    {
      "label": "(GMT+00:00) Universal",
      "value": "Universal"
    },
    {
      "label": "(GMT+00:00) WET",
      "value": "WET"
    },
    {
      "label": "(GMT+00:00) Zulu",
      "value": "Etc/Zulu"
    },
    {
      "label": "(GMT+01:00) Amsterdam",
      "value": "Europe/Amsterdam"
    },
    {
      "label": "(GMT+01:00) Andorra",
      "value": "Europe/Andorra"
    },
    {
      "label": "(GMT+01:00) Bangui",
      "value": "Africa/Bangui"
    },
    {
      "label": "(GMT+01:00) Belgrade",
      "value": "Europe/Belgrade"
    },
    {
      "label": "(GMT+01:00) Berlin",
      "value": "Europe/Berlin"
    },
    {
      "label": "(GMT+01:00) Bern, Zurich",
      "value": "Europe/Zurich"
    },
    {
      "label": "(GMT+01:00) Bratislava",
      "value": "Europe/Bratislava"
    },
    {
      "label": "(GMT+01:00) Brazzaville",
      "value": "Africa/Brazzaville"
    },
    {
      "label": "(GMT+01:00) Brussels",
      "value": "Europe/Brussels"
    },
    {
      "label": "(GMT+01:00) Budapest",
      "value": "Europe/Budapest"
    },
    {
      "label": "(GMT+01:00) Busingen",
      "value": "Europe/Busingen"
    },
    {
      "label": "(GMT+01:00) CET",
      "value": "CET"
    },
    {
      "label": "(GMT+01:00) Casablanca",
      "value": "Africa/Casablanca"
    },
    {
      "label": "(GMT+01:00) Ceuta",
      "value": "Africa/Ceuta"
    },
    {
      "label": "(GMT+01:00) Copenhagen",
      "value": "Europe/Copenhagen"
    },
    {
      "label": "(GMT+01:00) Douala",
      "value": "Africa/Douala"
    },
    {
      "label": "(GMT+01:00) Eire",
      "value": "Eire"
    },
    {
      "label": "(GMT+01:00) El_Aaiun",
      "value": "Africa/El_Aaiun"
    },
    {
      "label": "(GMT+01:00) GMT-1",
      "value": "Etc/GMT-1"
    },
    {
      "label": "(GMT+01:00) Gibraltar",
      "value": "Europe/Gibraltar"
    },
    {
      "label": "(GMT+01:00) Jan_Mayen",
      "value": "Atlantic/Jan_Mayen"
    },
    {
      "label": "(GMT+01:00) Kinshasa",
      "value": "Africa/Kinshasa"
    },
    {
      "label": "(GMT+01:00) Lagos",
      "value": "Africa/Lagos"
    },
    {
      "label": "(GMT+01:00) Libreville",
      "value": "Africa/Libreville"
    },
    {
      "label": "(GMT+01:00) Ljubljana",
      "value": "Europe/Ljubljana"
    },
    {
      "label": "(GMT+01:00) Longyearbyen",
      "value": "Arctic/Longyearbyen"
    },
    {
      "label": "(GMT+01:00) Luanda",
      "value": "Africa/Luanda"
    },
    {
      "label": "(GMT+01:00) Luxembourg",
      "value": "Europe/Luxembourg"
    },
    {
      "label": "(GMT+01:00) MET",
      "value": "MET"
    },
    {
      "label": "(GMT+01:00) Madrid",
      "value": "Europe/Madrid"
    },
    {
      "label": "(GMT+01:00) Malabo",
      "value": "Africa/Malabo"
    },
    {
      "label": "(GMT+01:00) Malta",
      "value": "Europe/Malta"
    },
    {
      "label": "(GMT+01:00) Monaco",
      "value": "Europe/Monaco"
    },
    {
      "label": "(GMT+01:00) Ndjamena",
      "value": "Africa/Ndjamena"
    },
    {
      "label": "(GMT+01:00) Niamey",
      "value": "Africa/Niamey"
    },
    {
      "label": "(GMT+01:00) Oslo",
      "value": "Europe/Oslo"
    },
    {
      "label": "(GMT+01:00) Paris",
      "value": "Europe/Paris"
    },
    {
      "label": "(GMT+01:00) Podgorica",
      "value": "Europe/Podgorica"
    },
    {
      "label": "(GMT+01:00) Poland",
      "value": "Poland"
    },
    {
      "label": "(GMT+01:00) Porto-Novo",
      "value": "Africa/Porto-Novo"
    },
    {
      "label": "(GMT+01:00) Prague",
      "value": "Europe/Prague"
    },
    {
      "label": "(GMT+01:00) Rome",
      "value": "Europe/Rome"
    },
    {
      "label": "(GMT+01:00) San_Marino",
      "value": "Europe/San_Marino"
    },
    {
      "label": "(GMT+01:00) Sarajevo",
      "value": "Europe/Sarajevo"
    },
    {
      "label": "(GMT+01:00) Skopje",
      "value": "Europe/Skopje"
    },
    {
      "label": "(GMT+01:00) Stockholm",
      "value": "Europe/Stockholm"
    },
    {
      "label": "(GMT+01:00) Tirane",
      "value": "Europe/Tirane"
    },
    {
      "label": "(GMT+01:00) Tunis",
      "value": "Africa/Tunis"
    },
    {
      "label": "(GMT+01:00) Vaduz",
      "value": "Europe/Vaduz"
    },
    {
      "label": "(GMT+01:00) Vatican",
      "value": "Europe/Vatican"
    },
    {
      "label": "(GMT+01:00) Vienna",
      "value": "Europe/Vienna"
    },
    {
      "label": "(GMT+01:00) Warsaw",
      "value": "Europe/Warsaw"
    },
    {
      "label": "(GMT+01:00) West Central Africa",
      "value": "Africa/Algiers"
    },
    {
      "label": "(GMT+01:00) Zagreb",
      "value": "Europe/Zagreb"
    },
    {
      "label": "(GMT+02:00) Amman",
      "value": "Asia/Amman"
    },
    {
      "label": "(GMT+02:00) Athens",
      "value": "Europe/Athens"
    },
    {
      "label": "(GMT+02:00) Beirut",
      "value": "Asia/Beirut"
    },
    {
      "label": "(GMT+02:00) Blantyre",
      "value": "Africa/Blantyre"
    },
    {
      "label": "(GMT+02:00) Bucharest",
      "value": "Europe/Bucharest"
    },
    {
      "label": "(GMT+02:00) Bujumbura",
      "value": "Africa/Bujumbura"
    },
    {
      "label": "(GMT+02:00) Cairo",
      "value": "Africa/Cairo"
    },
    {
      "label": "(GMT+02:00) Chisinau",
      "value": "Europe/Chisinau"
    },
    {
      "label": "(GMT+02:00) Damascus",
      "value": "Asia/Damascus"
    },
    {
      "label": "(GMT+02:00) EET",
      "value": "EET"
    },
    {
      "label": "(GMT+02:00) Egypt",
      "value": "Egypt"
    },
    {
      "label": "(GMT+02:00) Famagusta",
      "value": "Asia/Famagusta"
    },
    {
      "label": "(GMT+02:00) GMT-2",
      "value": "Etc/GMT-2"
    },
    {
      "label": "(GMT+02:00) Gaborone",
      "value": "Africa/Gaborone"
    },
    {
      "label": "(GMT+02:00) Gaza",
      "value": "Asia/Gaza"
    },
    {
      "label": "(GMT+02:00) Harare",
      "value": "Africa/Harare"
    },
    {
      "label": "(GMT+02:00) Hebron",
      "value": "Asia/Hebron"
    },
    {
      "label": "(GMT+02:00) Helsinki",
      "value": "Europe/Helsinki"
    },
    {
      "label": "(GMT+02:00) Israel",
      "value": "Israel"
    },
    {
      "label": "(GMT+02:00) Jerusalem",
      "value": "Asia/Jerusalem"
    },
    {
      "label": "(GMT+02:00) Juba",
      "value": "Africa/Juba"
    },
    {
      "label": "(GMT+02:00) Kaliningrad",
      "value": "Europe/Kaliningrad"
    },
    {
      "label": "(GMT+02:00) Khartoum",
      "value": "Africa/Khartoum"
    },
    {
      "label": "(GMT+02:00) Kigali",
      "value": "Africa/Kigali"
    },
    {
      "label": "(GMT+02:00) Kyiv",
      "value": "Europe/Kiev"
    },
    {
      "label": "(GMT+02:00) Libya",
      "value": "Libya"
    },
    {
      "label": "(GMT+02:00) Lubumbashi",
      "value": "Africa/Lubumbashi"
    },
    {
      "label": "(GMT+02:00) Lusaka",
      "value": "Africa/Lusaka"
    },
    {
      "label": "(GMT+02:00) Maputo",
      "value": "Africa/Maputo"
    },
    {
      "label": "(GMT+02:00) Mariehamn",
      "value": "Europe/Mariehamn"
    },
    {
      "label": "(GMT+02:00) Maseru",
      "value": "Africa/Maseru"
    },
    {
      "label": "(GMT+02:00) Mbabane",
      "value": "Africa/Mbabane"
    },
    {
      "label": "(GMT+02:00) Nicosia",
      "value": "Asia/Nicosia"
    },
    {
      "label": "(GMT+02:00) Pretoria",
      "value": "Africa/Johannesburg"
    },
    {
      "label": "(GMT+02:00) Riga",
      "value": "Europe/Riga"
    },
    {
      "label": "(GMT+02:00) Sofia",
      "value": "Europe/Sofia"
    },
    {
      "label": "(GMT+02:00) Tallinn",
      "value": "Europe/Tallinn"
    },
    {
      "label": "(GMT+02:00) Tel_Aviv",
      "value": "Asia/Tel_Aviv"
    },
    {
      "label": "(GMT+02:00) Tiraspol",
      "value": "Europe/Tiraspol"
    },
    {
      "label": "(GMT+02:00) Tripoli",
      "value": "Africa/Tripoli"
    },
    {
      "label": "(GMT+02:00) Uzhgorod",
      "value": "Europe/Uzhgorod"
    },
    {
      "label": "(GMT+02:00) Vilnius",
      "value": "Europe/Vilnius"
    },
    {
      "label": "(GMT+02:00) Windhoek",
      "value": "Africa/Windhoek"
    },
    {
      "label": "(GMT+02:00) Zaporozhye",
      "value": "Europe/Zaporozhye"
    },
    {
      "label": "(GMT+03:00) Addis_Ababa",
      "value": "Africa/Addis_Ababa"
    },
    {
      "label": "(GMT+03:00) Aden",
      "value": "Asia/Aden"
    },
    {
      "label": "(GMT+03:00) Antananarivo",
      "value": "Indian/Antananarivo"
    },
    {
      "label": "(GMT+03:00) Asmara",
      "value": "Africa/Asmara"
    },
    {
      "label": "(GMT+03:00) Asmera",
      "value": "Africa/Asmera"
    },
    {
      "label": "(GMT+03:00) Baghdad",
      "value": "Asia/Baghdad"
    },
    {
      "label": "(GMT+03:00) Bahrain",
      "value": "Asia/Bahrain"
    },
    {
      "label": "(GMT+03:00) Comoro",
      "value": "Indian/Comoro"
    },
    {
      "label": "(GMT+03:00) Dar_es_Salaam",
      "value": "Africa/Dar_es_Salaam"
    },
    {
      "label": "(GMT+03:00) Djibouti",
      "value": "Africa/Djibouti"
    },
    {
      "label": "(GMT+03:00) GMT-3",
      "value": "Etc/GMT-3"
    },
    {
      "label": "(GMT+03:00) Istanbul",
      "value": "Europe/Istanbul"
    },
    {
      "label": "(GMT+03:00) Kampala",
      "value": "Africa/Kampala"
    },
    {
      "label": "(GMT+03:00) Kirov",
      "value": "Europe/Kirov"
    },
    {
      "label": "(GMT+03:00) Kuwait",
      "value": "Asia/Kuwait"
    },
    {
      "label": "(GMT+03:00) Mayotte",
      "value": "Indian/Mayotte"
    },
    {
      "label": "(GMT+03:00) Minsk",
      "value": "Europe/Minsk"
    },
    {
      "label": "(GMT+03:00) Mogadishu",
      "value": "Africa/Mogadishu"
    },
    {
      "label": "(GMT+03:00) Moscow, St. Petersburg",
      "value": "Europe/Moscow"
    },
    {
      "label": "(GMT+03:00) Nairobi",
      "value": "Africa/Nairobi"
    },
    {
      "label": "(GMT+03:00) Qatar",
      "value": "Asia/Qatar"
    },
    {
      "label": "(GMT+03:00) Riyadh",
      "value": "Asia/Riyadh"
    },
    {
      "label": "(GMT+03:00) Simferopol",
      "value": "Europe/Simferopol"
    },
    {
      "label": "(GMT+03:00) Syowa",
      "value": "Antarctica/Syowa"
    },
    {
      "label": "(GMT+03:00) Turkey",
      "value": "Turkey"
    },
    {
      "label": "(GMT+03:00) Volgograd",
      "value": "Europe/Volgograd"
    },
    {
      "label": "(GMT+03:00) W-SU",
      "value": "W-SU"
    },
    {
      "label": "(GMT+03:30) Iran",
      "value": "Iran"
    },
    {
      "label": "(GMT+03:30) Tehran",
      "value": "Asia/Tehran"
    },
    {
      "label": "(GMT+04:00) Abu Dhabi, Muscat",
      "value": "Asia/Muscat"
    },
    {
      "label": "(GMT+04:00) Astrakhan",
      "value": "Europe/Astrakhan"
    },
    {
      "label": "(GMT+04:00) Baku",
      "value": "Asia/Baku"
    },
    {
      "label": "(GMT+04:00) Dubai",
      "value": "Asia/Dubai"
    },
    {
      "label": "(GMT+04:00) GMT-4",
      "value": "Etc/GMT-4"
    },
    {
      "label": "(GMT+04:00) Mahe",
      "value": "Indian/Mahe"
    },
    {
      "label": "(GMT+04:00) Mauritius",
      "value": "Indian/Mauritius"
    },
    {
      "label": "(GMT+04:00) Reunion",
      "value": "Indian/Reunion"
    },
    {
      "label": "(GMT+04:00) Samara",
      "value": "Europe/Samara"
    },
    {
      "label": "(GMT+04:00) Saratov",
      "value": "Europe/Saratov"
    },
    {
      "label": "(GMT+04:00) Tbilisi",
      "value": "Asia/Tbilisi"
    },
    {
      "label": "(GMT+04:00) Ulyanovsk",
      "value": "Europe/Ulyanovsk"
    },
    {
      "label": "(GMT+04:00) Yerevan",
      "value": "Asia/Yerevan"
    },
    {
      "label": "(GMT+04:30) Kabul",
      "value": "Asia/Kabul"
    },
    {
      "label": "(GMT+05:00) Aqtau",
      "value": "Asia/Aqtau"
    },
    {
      "label": "(GMT+05:00) Aqtobe",
      "value": "Asia/Aqtobe"
    },
    {
      "label": "(GMT+05:00) Ashgabat",
      "value": "Asia/Ashgabat"
    },
    {
      "label": "(GMT+05:00) Ashkhabad",
      "value": "Asia/Ashkhabad"
    },
    {
      "label": "(GMT+05:00) Atyrau",
      "value": "Asia/Atyrau"
    },
    {
      "label": "(GMT+05:00) Dushanbe",
      "value": "Asia/Dushanbe"
    },
    {
      "label": "(GMT+05:00) Ekaterinburg",
      "value": "Asia/Yekaterinburg"
    },
    {
      "label": "(GMT+05:00) GMT-5",
      "value": "Etc/GMT-5"
    },
    {
      "label": "(GMT+05:00) Islamabad, Karachi",
      "value": "Asia/Karachi"
    },
    {
      "label": "(GMT+05:00) Kerguelen",
      "value": "Indian/Kerguelen"
    },
    {
      "label": "(GMT+05:00) Maldives",
      "value": "Indian/Maldives"
    },
    {
      "label": "(GMT+05:00) Mawson",
      "value": "Antarctica/Mawson"
    },
    {
      "label": "(GMT+05:00) Oral",
      "value": "Asia/Oral"
    },
    {
      "label": "(GMT+05:00) Qyzylorda",
      "value": "Asia/Qyzylorda"
    },
    {
      "label": "(GMT+05:00) Samarkand",
      "value": "Asia/Samarkand"
    },
    {
      "label": "(GMT+05:00) Tashkent",
      "value": "Asia/Tashkent"
    },
    {
      "label": "(GMT+05:30) Calcutta",
      "value": "Asia/Calcutta"
    },
    {
      "label": "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
      "value": "Asia/Kolkata"
    },
    {
      "label": "(GMT+05:30) Sri Jayawardenepura",
      "value": "Asia/Colombo"
    },
    {
      "label": "(GMT+05:45) Kathmandu",
      "value": "Asia/Kathmandu"
    },
    {
      "label": "(GMT+05:45) Katmandu",
      "value": "Asia/Katmandu"
    },
    {
      "label": "(GMT+06:00) Almaty",
      "value": "Asia/Almaty"
    },
    {
      "label": "(GMT+06:00) Astana, Dhaka",
      "value": "Asia/Dhaka"
    },
    {
      "label": "(GMT+06:00) Bishkek",
      "value": "Asia/Bishkek"
    },
    {
      "label": "(GMT+06:00) Chagos",
      "value": "Indian/Chagos"
    },
    {
      "label": "(GMT+06:00) Dacca",
      "value": "Asia/Dacca"
    },
    {
      "label": "(GMT+06:00) GMT-6",
      "value": "Etc/GMT-6"
    },
    {
      "label": "(GMT+06:00) Kashgar",
      "value": "Asia/Kashgar"
    },
    {
      "label": "(GMT+06:00) Omsk",
      "value": "Asia/Omsk"
    },
    {
      "label": "(GMT+06:00) Qostanay",
      "value": "Asia/Qostanay"
    },
    {
      "label": "(GMT+06:00) Thimbu",
      "value": "Asia/Thimbu"
    },
    {
      "label": "(GMT+06:00) Thimphu",
      "value": "Asia/Thimphu"
    },
    {
      "label": "(GMT+06:00) Urumqi",
      "value": "Asia/Urumqi"
    },
    {
      "label": "(GMT+06:00) Vostok",
      "value": "Antarctica/Vostok"
    },
    {
      "label": "(GMT+06:30) Cocos",
      "value": "Indian/Cocos"
    },
    {
      "label": "(GMT+06:30) Rangoon",
      "value": "Asia/Rangoon"
    },
    {
      "label": "(GMT+06:30) Yangon",
      "value": "Asia/Yangon"
    },
    {
      "label": "(GMT+07:00) Bangkok, Hanoi",
      "value": "Asia/Bangkok"
    },
    {
      "label": "(GMT+07:00) Barnaul",
      "value": "Asia/Barnaul"
    },
    {
      "label": "(GMT+07:00) Christmas",
      "value": "Indian/Christmas"
    },
    {
      "label": "(GMT+07:00) Davis",
      "value": "Antarctica/Davis"
    },
    {
      "label": "(GMT+07:00) GMT-7",
      "value": "Etc/GMT-7"
    },
    {
      "label": "(GMT+07:00) Ho_Chi_Minh",
      "value": "Asia/Ho_Chi_Minh"
    },
    {
      "label": "(GMT+07:00) Hovd",
      "value": "Asia/Hovd"
    },
    {
      "label": "(GMT+07:00) Jakarta",
      "value": "Asia/Jakarta"
    },
    {
      "label": "(GMT+07:00) Krasnoyarsk",
      "value": "Asia/Krasnoyarsk"
    },
    {
      "label": "(GMT+07:00) Novokuznetsk",
      "value": "Asia/Novokuznetsk"
    },
    {
      "label": "(GMT+07:00) Novosibirsk",
      "value": "Asia/Novosibirsk"
    },
    {
      "label": "(GMT+07:00) Phnom_Penh",
      "value": "Asia/Phnom_Penh"
    },
    {
      "label": "(GMT+07:00) Pontianak",
      "value": "Asia/Pontianak"
    },
    {
      "label": "(GMT+07:00) Saigon",
      "value": "Asia/Saigon"
    },
    {
      "label": "(GMT+07:00) Tomsk",
      "value": "Asia/Tomsk"
    },
    {
      "label": "(GMT+07:00) Vientiane",
      "value": "Asia/Vientiane"
    },
    {
      "label": "(GMT+08:00) Beijing",
      "value": "Asia/Shanghai"
    },
    {
      "label": "(GMT+08:00) Brunei",
      "value": "Asia/Brunei"
    },
    {
      "label": "(GMT+08:00) Choibalsan",
      "value": "Asia/Choibalsan"
    },
    {
      "label": "(GMT+08:00) Chongqing",
      "value": "Asia/Chongqing"
    },
    {
      "label": "(GMT+08:00) Chungking",
      "value": "Asia/Chungking"
    },
    {
      "label": "(GMT+08:00) GMT-8",
      "value": "Etc/GMT-8"
    },
    {
      "label": "(GMT+08:00) Harbin",
      "value": "Asia/Harbin"
    },
    {
      "label": "(GMT+08:00) Hong Kong",
      "value": "Asia/Hong_Kong"
    },
    {
      "label": "(GMT+08:00) Hongkong",
      "value": "Hongkong"
    },
    {
      "label": "(GMT+08:00) Irkutsk",
      "value": "Asia/Irkutsk"
    },
    {
      "label": "(GMT+08:00) Kuala Lumpur",
      "value": "Asia/Kuala_Lumpur"
    },
    {
      "label": "(GMT+08:00) Kuching",
      "value": "Asia/Kuching"
    },
    {
      "label": "(GMT+08:00) Macao",
      "value": "Asia/Macao"
    },
    {
      "label": "(GMT+08:00) Macau",
      "value": "Asia/Macau"
    },
    {
      "label": "(GMT+08:00) Makassar",
      "value": "Asia/Makassar"
    },
    {
      "label": "(GMT+08:00) Manila",
      "value": "Asia/Manila"
    },
    {
      "label": "(GMT+08:00) PRC",
      "value": "PRC"
    },
    {
      "label": "(GMT+08:00) Perth",
      "value": "Australia/Perth"
    },
    {
      "label": "(GMT+08:00) ROC",
      "value": "ROC"
    },
    {
      "label": "(GMT+08:00) Singapore",
      "value": "Asia/Singapore"
    },
    {
      "label": "(GMT+08:00) Taipei",
      "value": "Asia/Taipei"
    },
    {
      "label": "(GMT+08:00) Ujung_Pandang",
      "value": "Asia/Ujung_Pandang"
    },
    {
      "label": "(GMT+08:00) Ulaanbaatar",
      "value": "Asia/Ulaanbaatar"
    },
    {
      "label": "(GMT+08:00) Ulan_Bator",
      "value": "Asia/Ulan_Bator"
    },
    {
      "label": "(GMT+08:00) West",
      "value": "Australia/West"
    },
    {
      "label": "(GMT+08:45) Eucla",
      "value": "Australia/Eucla"
    },
    {
      "label": "(GMT+09:00) Chita",
      "value": "Asia/Chita"
    },
    {
      "label": "(GMT+09:00) Dili",
      "value": "Asia/Dili"
    },
    {
      "label": "(GMT+09:00) GMT-9",
      "value": "Etc/GMT-9"
    },
    {
      "label": "(GMT+09:00) Japan",
      "value": "Japan"
    },
    {
      "label": "(GMT+09:00) Jayapura",
      "value": "Asia/Jayapura"
    },
    {
      "label": "(GMT+09:00) Khandyga",
      "value": "Asia/Khandyga"
    },
    {
      "label": "(GMT+09:00) Osaka, Sapporo, Tokyo",
      "value": "Asia/Tokyo"
    },
    {
      "label": "(GMT+09:00) Palau",
      "value": "Pacific/Palau"
    },
    {
      "label": "(GMT+09:00) Pyongyang",
      "value": "Asia/Pyongyang"
    },
    {
      "label": "(GMT+09:00) ROK",
      "value": "ROK"
    },
    {
      "label": "(GMT+09:00) Seoul",
      "value": "Asia/Seoul"
    },
    {
      "label": "(GMT+09:00) Yakutsk",
      "value": "Asia/Yakutsk"
    },
    {
      "label": "(GMT+09:30) Adelaide",
      "value": "Australia/Adelaide"
    },
    {
      "label": "(GMT+09:30) Broken_Hill",
      "value": "Australia/Broken_Hill"
    },
    {
      "label": "(GMT+09:30) Darwin",
      "value": "Australia/Darwin"
    },
    {
      "label": "(GMT+09:30) North",
      "value": "Australia/North"
    },
    {
      "label": "(GMT+09:30) South",
      "value": "Australia/South"
    },
    {
      "label": "(GMT+09:30) Yancowinna",
      "value": "Australia/Yancowinna"
    },
    {
      "label": "(GMT+10:00) ACT",
      "value": "Australia/ACT"
    },
    {
      "label": "(GMT+10:00) Brisbane",
      "value": "Australia/Brisbane"
    },
    {
      "label": "(GMT+10:00) Canberra",
      "value": "Australia/Canberra"
    },
    {
      "label": "(GMT+10:00) Canberra, Melbourne",
      "value": "Australia/Melbourne"
    },
    {
      "label": "(GMT+10:00) Chuuk",
      "value": "Pacific/Chuuk"
    },
    {
      "label": "(GMT+10:00) Currie",
      "value": "Australia/Currie"
    },
    {
      "label": "(GMT+10:00) DumontDUrville",
      "value": "Antarctica/DumontDUrville"
    },
    {
      "label": "(GMT+10:00) GMT-10",
      "value": "Etc/GMT-10"
    },
    {
      "label": "(GMT+10:00) Guam",
      "value": "Pacific/Guam"
    },
    {
      "label": "(GMT+10:00) Hobart",
      "value": "Australia/Hobart"
    },
    {
      "label": "(GMT+10:00) Lindeman",
      "value": "Australia/Lindeman"
    },
    {
      "label": "(GMT+10:00) Macquarie",
      "value": "Antarctica/Macquarie"
    },
    {
      "label": "(GMT+10:00) NSW",
      "value": "Australia/NSW"
    },
    {
      "label": "(GMT+10:00) Port Moresby",
      "value": "Pacific/Port_Moresby"
    },
    {
      "label": "(GMT+10:00) Queensland",
      "value": "Australia/Queensland"
    },
    {
      "label": "(GMT+10:00) Saipan",
      "value": "Pacific/Saipan"
    },
    {
      "label": "(GMT+10:00) Sydney",
      "value": "Australia/Sydney"
    },
    {
      "label": "(GMT+10:00) Tasmania",
      "value": "Australia/Tasmania"
    },
    {
      "label": "(GMT+10:00) Truk",
      "value": "Pacific/Truk"
    },
    {
      "label": "(GMT+10:00) Ust-Nera",
      "value": "Asia/Ust-Nera"
    },
    {
      "label": "(GMT+10:00) Victoria",
      "value": "Australia/Victoria"
    },
    {
      "label": "(GMT+10:00) Vladivostok",
      "value": "Asia/Vladivostok"
    },
    {
      "label": "(GMT+10:00) Yap",
      "value": "Pacific/Yap"
    },
    {
      "label": "(GMT+10:30) LHI",
      "value": "Australia/LHI"
    },
    {
      "label": "(GMT+10:30) Lord_Howe",
      "value": "Australia/Lord_Howe"
    },
    {
      "label": "(GMT+11:00) Bougainville",
      "value": "Pacific/Bougainville"
    },
    {
      "label": "(GMT+11:00) Casey",
      "value": "Antarctica/Casey"
    },
    {
      "label": "(GMT+11:00) Efate",
      "value": "Pacific/Efate"
    },
    {
      "label": "(GMT+11:00) GMT-11",
      "value": "Etc/GMT-11"
    },
    {
      "label": "(GMT+11:00) Kosrae",
      "value": "Pacific/Kosrae"
    },
    {
      "label": "(GMT+11:00) Magadan",
      "value": "Asia/Magadan"
    },
    {
      "label": "(GMT+11:00) New Caledonia",
      "value": "Pacific/Noumea"
    },
    {
      "label": "(GMT+11:00) Norfolk",
      "value": "Pacific/Norfolk"
    },
    {
      "label": "(GMT+11:00) Pohnpei",
      "value": "Pacific/Pohnpei"
    },
    {
      "label": "(GMT+11:00) Ponape",
      "value": "Pacific/Ponape"
    },
    {
      "label": "(GMT+11:00) Sakhalin",
      "value": "Asia/Sakhalin"
    },
    {
      "label": "(GMT+11:00) Solomon Is.",
      "value": "Pacific/Guadalcanal"
    },
    {
      "label": "(GMT+11:00) Srednekolymsk",
      "value": "Asia/Srednekolymsk"
    },
    {
      "label": "(GMT+12:00) Anadyr",
      "value": "Asia/Anadyr"
    },
    {
      "label": "(GMT+12:00) Auckland, Wellington",
      "value": "Pacific/Auckland"
    },
    {
      "label": "(GMT+12:00) Fiji",
      "value": "Pacific/Fiji"
    },
    {
      "label": "(GMT+12:00) Funafuti",
      "value": "Pacific/Funafuti"
    },
    {
      "label": "(GMT+12:00) GMT-12",
      "value": "Etc/GMT-12"
    },
    {
      "label": "(GMT+12:00) Kamchatka",
      "value": "Asia/Kamchatka"
    },
    {
      "label": "(GMT+12:00) Kwajalein",
      "value": "Pacific/Kwajalein"
    },
    {
      "label": "(GMT+12:00) Marshall Is.",
      "value": "Pacific/Majuro"
    },
    {
      "label": "(GMT+12:00) McMurdo",
      "value": "Antarctica/McMurdo"
    },
    {
      "label": "(GMT+12:00) NZ",
      "value": "NZ"
    },
    {
      "label": "(GMT+12:00) Nauru",
      "value": "Pacific/Nauru"
    },
    {
      "label": "(GMT+12:00) South_Pole",
      "value": "Antarctica/South_Pole"
    },
    {
      "label": "(GMT+12:00) Tarawa",
      "value": "Pacific/Tarawa"
    },
    {
      "label": "(GMT+12:00) Wake",
      "value": "Pacific/Wake"
    },
    {
      "label": "(GMT+12:00) Wallis",
      "value": "Pacific/Wallis"
    },
    {
      "label": "(GMT+12:45) Chatham Is.",
      "value": "Pacific/Chatham"
    },
    {
      "label": "(GMT+12:45) NZ-CHAT",
      "value": "NZ-CHAT"
    },
    {
      "label": "(GMT+13:00) Enderbury",
      "value": "Pacific/Enderbury"
    },
    {
      "label": "(GMT+13:00) GMT-13",
      "value": "Etc/GMT-13"
    },
    {
      "label": "(GMT+13:00) Kanton",
      "value": "Pacific/Kanton"
    },
    {
      "label": "(GMT+13:00) Nuku'alofa",
      "value": "Pacific/Tongatapu"
    },
    {
      "label": "(GMT+13:00) Samoa",
      "value": "Pacific/Apia"
    },
    {
      "label": "(GMT+13:00) Tokelau Is.",
      "value": "Pacific/Fakaofo"
    },
    {
      "label": "(GMT+14:00) GMT-14",
      "value": "Etc/GMT-14"
    },
    {
      "label": "(GMT+14:00) Kiritimati",
      "value": "Pacific/Kiritimati"
    }
  ]
