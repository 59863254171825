import React from 'react';
import ReactDOM from 'react-dom';
import { Button } from 'antd';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';

import reactGaEvent from 'utils/GAEvent';
import { CommonDataCollect } from 'utils/utils';


import styles from './index.less';

const modalRoot = document.getElementById('root');

const { useEffect, useState } = React;

function PayInto({ dispatch }) {
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState();
  
  useEffect(() => { 

    let data = {
      "titleImg": "https://img.dsers.com/shopify/global/advertising/img/headPortrait.png",
      "title": "Merchant Growth Team",
      "subTitle": "DSers empowers your dropshipping journey",
      "iHide": "keyboard_arrow_down",
      "localStorage": "PayIntoDetailsSix",
      "delLocalStorage": "PayIntoDetailsFive",
      "detailsEventType": "subscription_launch",
      "detailsCategory": "PayInto",
      "event_type": "subscription_launch",
      "detailsAction": "click_show_details_CAM005_button",
      "pricingAction": "click_upgrade_CAM005_button",
      "blogAction": "click_show_blog_CAM005_button",
      "particulars": [
        {
          "img": "https://img.dsers.com/shopify/global/advertising/dev/img/computer.png",
          "title": "Grow your business with the help of VA."
        },
        {
          "img": "https://img.dsers.com/shopify/global/advertising/dev/img/storage.png",
          "title": "Grant appropriate permissions to your staffs."
        },
        {
          "img": "https://img.dsers.com/shopify/global/advertising/dev/img/helmet.png",
          "title": "Ensure the safety of operation process."
        }
      ],
      "contentBottomTitle": "Improve teamwork efficiency and focus on growth.",
      "image": "https://img.dsers.com/shopify/global/advertising/dev/img/banner.png",
      "blogName": "How to use Staff Account Feature.",
      "blogUrl": "https://www.dsers.com/blog/improve-teamwork-efficiency-with-staff-account/",
      "button": "Try Now",
      "iShow": "keyboard_arrow_up",
      "DetailsWidth": "616px",
      "CollapseContentWidth": "560px"
    }
    
    window.advertisingContent = data
    let payInto = localStorage.getItem(
      `${window.advertisingContent.localStorage}`
    );
    setLoading(false);
    setDetails(!!payInto)

  }, []);
 
  if (loading) {
    return null;
  }


  localStorage.removeItem(`${window.advertisingContent.delLocalStorage}`);

  function closeDetails() {
    localStorage.setItem(`${window.advertisingContent.localStorage}`, true);
    setDetails(true);
  }

  function showDetails() {
    CommonDataCollect({
      event_type: window.advertisingContent.event_type,
      action: window.advertisingContent.detailsAction
    });
    reactGaEvent({
      category: window.advertisingContent.category,
      action: window.advertisingContent.detailsAction
    });
    localStorage.removeItem(`${window.advertisingContent.localStorage}`);
    setDetails(false);
  }

  function gotoSelect() {
    CommonDataCollect({
      event_type: window.advertisingContent.event_type,
      action: window.advertisingContent.pricingAction
    });
    reactGaEvent({
      category: window.advertisingContent.category,
      action: window.advertisingContent.pricingAction
    });

    dispatch(routerRedux.push('/pricing?select=1'));
  }

  function showBlog() {
    CommonDataCollect({
      event_type: window.advertisingContent.event_type,
      action: window.advertisingContent.blogAction
    });
    reactGaEvent({
      category: window.advertisingContent.category,
      action: window.advertisingContent.blogAction
    });
  }

  if (details) {
    return (
      <div className={styles.container}>
        <div className={styles.text}>
          <a
            href={window.advertisingContent.blogUrl}
            target="_black"
            onClick={showBlog}
          >
            {window.advertisingContent.blogName}
          </a>
        </div>
        <div className={styles.operation}>
          <div className={styles.button} onClick={gotoSelect}>
            {window.advertisingContent.button}
          </div>
          <i class="material-icons notranslate" onClick={showDetails}>
            {window.advertisingContent.iShow}
          </i>
        </div>
      </div>
    );
  }

  return ReactDOM.createPortal(
    <div className={styles.mask}>
      <div className={styles.modal}>
        <div className={styles.header}>
          <div className={styles.left}>
            <div className={styles.headPortrait}>
              <img src={window.advertisingContent.titleImg} />
            </div>
            <div className={styles.text}>
              <div className={styles.title}>
                {window.advertisingContent.title}
              </div>
              <div className={styles.subTitle}>
                {window.advertisingContent.subTitle}
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <i class="material-icons notranslate" onClick={closeDetails}>
              {window.advertisingContent.iHide}
            </i>
          </div>
        </div>

        <div className={styles.content}>
          {window.advertisingContent.particulars.map(item => (
            <div className={styles.location}>
              <div className={styles.img}>
                <img src={item.img} />
              </div>
              <p>{item.title}</p>
            </div>
          ))}

          <p>{window.advertisingContent.contentBottomTitle}</p>
        </div>

        <div className={styles.footer}>
          <div className={styles.image}>
            <img src={window.advertisingContent.image} />
          </div>

          <div className={styles.option}>
            <div className={styles.text}>
              <a
                href={window.advertisingContent.blogUrl}
                target="_black"
                onClick={showBlog}
              >
                {window.advertisingContent.blogName}
              </a>
            </div>
            <div className={styles.button} onClick={gotoSelect}>
              {window.advertisingContent.button}
            </div>
          </div>
        </div>
      </div>
    </div>,
    modalRoot
  );
}

const CAM005 = connect()(PayInto);

export {
  CAM005
}
