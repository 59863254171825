import React from 'react';
import { Icon } from 'antd';

import styles from './RecommendTag.less';
import touchIcon from '../../../assets/svg/supplier_ad/one_click_mapping_touch.svg';
import point from 'assets/supply/point.gif';
/* eslint react/jsx-props-no-spreading: 0 */

function RecommendTag({
  title,
  onClose,
  showPoint,
  style,
  showClose,
  onLinkClick,
  trickConfig,
  data
}) {
  // const link = url || `/storemanage/storelist/bargain#${id}`;

  return (
    <div className={styles.recommendTag} style={style}>
      <a
        role="button"
        tab-index="0"
        onClick={() => onLinkClick(data)} {...trickConfig}
      >
        <span className={styles.title}>CHEAP!</span>
        <img src={point} alt="" />
      </a>
    </div>
  );
}

RecommendTag.defaultProps = {
  onLinkClick: () => {},
  trickConfig: null
};

export default RecommendTag;
