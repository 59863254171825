import React, { useEffect, useState } from 'react';

import useTopBanner from '../../hooks/useTopBanner';
import { Get } from 'utils/request';

import { newBannerList, brazilBannerList, banner_12_10, newShipOptions, shipMethod, agencyUserMap, shipMethodBannerUsers } from '../../utils/bannerWhiteList';
import RutBanner from 'routes/Order/RutBanner';
import SplitBanner from 'routes/Order/SplitBanner';
import Appointment from 'components/Banner/Appointment';
import ShippingMethodBanner from './ShippingMethodBanner'

import ShopifyUpdaPrompt from './ShopifyUpdaPrompt';

import styles from 'components/Banner/Appointment.less'

const ADTopBannerContainer = ({ CommonBanner, lang, path, ADBanner, NEWBanner, userId, CommentBanner, BrazilBanner, dispatch, userInfo }) => {
  const [bannerType, setBannerType] = useState(false);

  const [visible] = useTopBanner(path, ADBanner.allowPath || []);
  const [commonVisible] = useTopBanner(path, CommonBanner.allowPath || []);
  const [brazilBannerVisible] = useTopBanner(path, BrazilBanner.allowPath || []);
  const [rutBannerVisible] = useTopBanner(path, RutBanner.allowPath || []);
  const [splitBannerVisible] = useTopBanner(path, SplitBanner.allowPath || []);
  const isNewBannerUser = banner_12_10.includes(userId);
  const isBrazilBannerUser = brazilBannerList.includes(userId);
  const isNewShipOptions = shipMethod.includes(userId);
  const [shipMethodBannerVisible] = useTopBanner(path, ShippingMethodBanner.allowPath || []);
  const isshipMethodBannerUser = shipMethodBannerUsers.includes(userId)
  const [ShopifyUpdaPromptVisible] = useTopBanner(
    path,
    ShopifyUpdaPrompt.allowPath || []
  );

  useEffect(() => {
    // 计算高度
    dispatch({
      type: 'global/bannerShow',
      payload: {
        isBanner: true
      }
    })
  }, []);

  // if(splitBannerVisible){
  //   return <SplitBanner/>;
  // }
  // if (shipMethodBannerVisible && isshipMethodBannerUser){
  //   return <ShippingMethodBanner></ShippingMethodBanner>
  // }

  // if (rutBannerVisible && isNewShipOptions) {
  //   return <RutBanner agencyUserMap={agencyUserMap}></RutBanner>
  // }

  // if (ShopifyUpdaPromptVisible) {
  //   return <ShopifyUpdaPrompt />;
  // }


  return null;
};

export default ADTopBannerContainer;
