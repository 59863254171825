import { isUrl } from 'utils/utils';
import { getDisplaySupplierOptimizer } from './checkUserForSupplierOptimizer';
// import getAdminRole from 'utils/getAdminRole';

const menuDataLang = {
  'en-US': {
    home: 'Home',
    report: 'Report',
    products: 'Products',
    bargain: 'Find Suppliers',
    import_list: 'Import List',
    my_products: 'My Products',
    orders: 'Orders',
    orders_open: 'Open Orders',
    orders_archived: 'Archived Orders',
    new_feature: 'CSV Bulk Orders',
    affiliate: 'Affiliate',
    affiliate_reward: 'Reward Season',
    fulfillment_center: 'Fulfillment Center',
    notification: 'Notification',
    settings: 'Settings',
    contact_us: 'Contact us',
    subscription: 'Manage subscription',
    blog: 'BLOG',
    facebook: 'Facebook',
    youtube: 'YouTube',
    email_support: 'Email support',
    platform: 'Switch platform',
    supplier_optimizer: 'Supplier Optimizer',
    more_store: 'Link to More Stores',
    help_center: 'Help Center',
    tracking: 'Tracking',
    support:'Support',
    unmapped:'Unmapped'
  },
  'fr-FR': {
    home: 'Accueil',
    report: 'Rapport',
    products: 'Produits',
    bargain: 'Fournisseurs',
    import_list: 'Liste d’Import',
    my_products: 'Mes Produits',
    orders: 'Commandes',
    orders_open: 'Commandes Ouvertes',
    orders_archived: 'Commandes Archivées',
    new_feature: 'Nouvelles Fonctions',
    affiliate: 'Affiliate',
    affiliate_reward: 'Reward Season',
    fulfillment_center: 'Fulfillment Center',
    notification: 'Notifications',
    settings: 'Réglages',
    contact_us: 'Nous contacter',
    subscription: 'Gérer souscription',
    blog: 'BLOG',
    facebook: 'Facebook',
    youtube: 'YouTube',
    email_support: 'Support Email',
    platform: 'Changer de plateforme',
    supplier_optimizer: 'Optimiseur de Fournisseur',
    more_store: 'Link to More Stores',
    help_center: 'Help Center',
    tracking: 'Suivi de la commande',
    support:'SUPPORT',
    unmapped:'Non répertorié'
  },
  'pt-PT': {
    home: 'Início',
    report: 'Relatório',
    products: 'Produtos',
    bargain: 'Encontrar fornecedores',
    import_list: 'Lista de importação',
    my_products: 'Meus Produtos',
    orders: 'Pedidos',
    orders_open: 'Pedidos abertos',
    orders_archived: 'Pedidos arquivados',
    archived: 'Archived',
    new_feature: 'Novo recurso',
    affiliate: 'Affiliate',
    affiliate_reward: 'Reward Season',
    fulfillment_center: 'Centro de Preenchimento',
    notification: 'Notificações',
    settings: 'Configurações',
    contact_us: 'Contate-nos',
    subscription: 'Gerenciar assinatura',
    blog: 'BLOG',
    facebook: 'Facebook',
    youtube: 'YouTube',
    email_support: 'Suporte por e-mail',
    platform: 'Trocar plataforma',
    supplier_optimizer: 'Otimização de fornecedores',
    more_store: 'Vincular mais lojas',
    help_center: 'Help Center',
    tracking: 'Rastreamento',
    support:'Suporte',
    unmapped:'Não mapeado'
  },
  'de-DE': {
    home: 'Startseite',
    report: 'Bericht',
    products: 'Produkte',
    bargain: 'Lieferanten finden',
    import_list: 'Liste importieren',
    my_products: 'Meine Produkte',
    orders: 'Bestellungen',
    orders_open: 'Offene Aufträge',
    orders_archived: 'Archivierte Bestellungen',
    new_feature: 'CSV-Sammelbestellung',
    affiliate: 'Partnerprogramm',
    affiliate_reward: 'DSers Belohnungssaison',
    fulfillment_center: 'Erfüllungszentrum',
    notification: 'Benachrichtigung',
    settings: 'Einstellungen',
    contact_us: 'Kontaktieren Sie uns',
    subscription: 'Abonnement verwalten',
    blog: 'BLOG',
    facebook: 'Facebook',
    youtube: 'YouTube',
    email_support: 'E-Mail-Unterstützung',
    platform: 'Plattform wechseln',
    supplier_optimizer: 'Plattform wechseln',
    more_store: 'Link zu weiteren Shops',
    help_center: 'Hilfe-Center',
    tracking: 'Sendungsverfolgung',
    support:'Support',
    unmapped:'Nicht abgebildet'
  },
  'it-IT': {
    home: 'Home',
    report: 'Segnala',
    products: 'Prodotti',
    bargain: 'Trova Fornitori',
    import_list: 'Importa lista',
    my_products: 'I miei prodotti',
    orders: 'ordini',
    orders_open: 'Ordini aperti',
    orders_archived: 'Ordini archiviati',
    new_feature: "Ordine in gruppo CSV",
    affiliate: 'Partner',
    affiliate_reward: 'Periodo dei Premi DSers',
    fulfillment_center: 'Centro di Assisienza (FBD)',
    notification: 'Notifiche',
    settings: 'Impostazioni',
    contact_us: 'Contattaci',
    subscription: 'Gestisci abbonamento',
    blog: 'BLOG',
    facebook: 'Facebook',
    youtube: 'YouTube',
    email_support: 'Supporto via email',
    platform: 'Cambia piattaforma',
    supplier_optimizer: 'Regola di tariffazione automatizzata',
    more_store: 'Collegati a più negozi',
    help_center: 'Centro di Aiuto',
    tracking: 'N° Tracking',
    support:'Supporto',
    unmapped:'Non mappato'
  },
  'es-ES': {
    home: 'Inicio',
    report: 'Informe',
    products: 'Productos',
    bargain: 'Buscar proveedores',
    import_list: 'Lista de importación',
    my_products: 'Mis productos',
    orders: 'pedidos',
    orders_open: 'Pedidos Abiertos',
    orders_archived: 'Pedidos Archivados',
    new_feature: 'Pedido masivo CSV',
    affiliate: 'Afiliado',
    affiliate_reward: 'Temporada de recompensas',
    fulfillment_center: 'Centro de Procesamiento',
    notification: 'Notificación',
    settings: 'Ajustes',
    contact_us: 'Contáctanos',
    subscription: 'Gestionar suscripción',
    blog: 'BLOG',
    facebook: 'Facebook',
    youtube: 'YouTube',
    email_support: 'Soporte técnico por correo',
    platform: 'Cambiar de plataforma',
    supplier_optimizer: 'Reglamento de precios',
    more_store: 'Link a más tiendas',
    help_center: 'Centro de ayuda',
    tracking: 'Seguimiento',
    help_center: 'Help Center',
    tracking: 'Tracking',
    support:'Soporte',
    unmapped:'No mapeado'
    
  }
};



export const MENU_POSITION = {
  INFO: 'info',
  SUPPLIER: 'supplier',
  OPERATION: 'operation',
  ORDERS: 'orders',
  TRACKING: 'tracking',
  MORE: 'more',
  OTHER: 'other'
};

const langKeys = ['pt-PT', 'en-US', 'fr-FR', 'it-IT', 'de-DE', 'es-ES']

export const createMenuData = () => {
  const lang = langKeys.includes(localStorage.getItem('lang')) ? localStorage.getItem('lang') : 'en-US';
  const menuData = [
    {
      name: menuDataLang[lang].home,
      icon: 'store_mall_directory',
      path: 'home',
      position: MENU_POSITION.INFO
    },
    {
      name: menuDataLang[lang].report,
      icon: 'insert_chart',
      path: 'report',
      position: MENU_POSITION.INFO
    },
    {
      name: menuDataLang[lang].bargain,
      path: 'find_suppliers',
      icon: 'home',
      position: MENU_POSITION.SUPPLIER
    },
    {
      name: menuDataLang[lang].supplier_optimizer,
      icon: 'search',
      path: 'supplier_optimizer',
      position: MENU_POSITION.SUPPLIER
    },
    {
      name: menuDataLang[lang].import_list,
      icon: 'list_alt',
      path: 'storemanage/import',
      badge: 'importList',
      position: MENU_POSITION.OPERATION
    },
    {
      name: menuDataLang[lang].my_products,
      path: 'storemanage/myproduct',
      badge: 'myProduct',
      icon: 'local_mall',
      position: MENU_POSITION.OPERATION
    },
    window.CAINIAO_USER || window.TMALL_USER ? {
      name: menuDataLang[lang].orders_open,
      icon: 'assignment',
      badge: 'Open',
      position: MENU_POSITION.ORDERS,
      path:'order',
      children: [
        {
          name: 'AliExpress',
          icon: '',
          badge: 'AEOpen',
          target: '',
          path: 'list',
        },
        {
          name: 'Tmall',
          icon: '',
          badge: 'TmallOpen',
          path: 'tmall/list',
          target: '',
        },
        {
          name: 'Agency',
          icon: '',
          badge: 'AgencyOpen',
          path: 'agency/list',
          target: '',
        },
        {
          name: menuDataLang[lang].unmapped,
          icon: '',
          badge: 'UnmappedOpen',
          path: 'unmapped/list',
          target: '',
        }
      ],
    } : {
      name: menuDataLang[lang].orders_open,
      icon: 'assignment',
      badge: 'Open',
      position: MENU_POSITION.ORDERS,
      path:'order/list'
    },
    {
      name: menuDataLang[lang].orders_archived,
      path: 'order/archived',
      badge: 'Archived',
      icon: 'archive',
      position: MENU_POSITION.ORDERS
    },
    {
      name: menuDataLang[lang].tracking,
      icon: 'local_shipping',
      path: 'tracking',
      position: MENU_POSITION.TRACKING
    },
    {
      name: menuDataLang[lang].new_feature,
      icon: 'campaign',
      path: 'csv',
      position: MENU_POSITION.MORE
    },
    {
      name: menuDataLang[lang].more_store,
      icon: 'insert_link',
      path: 'bind/link-store',
      position: MENU_POSITION.MORE
    },
    {
      name: menuDataLang[lang].settings,
      icon: 'settings',
      path: 'settings',
      position: 'bottom'
    },
    {
      name: menuDataLang[lang].subscription,
      icon: 'tune',
      path: '/select',
      target: '_blank',
      position: 'bottom'
    },
    {
      name: menuDataLang[lang].support,
      icon: 'help_outline',
      children: [
        {
          name: menuDataLang[lang].help_center,
          icon: '',
          target: '_blank',
          path: 'https://help.dsers.com/',
        },
        {
          name: menuDataLang[lang].email_support,
          icon: '',
          path: 'mailto:support@dserspro.com',
          target: '',
        }
      ],
      position:'other'
    },
    {
      name:'Agency',
      icon:'people_alt',
      path:'agency',
      position: MENU_POSITION.SUPPLIER
    }
  ];
  return menuData;
}

// export const menuData = [
//   {
//     name: menuDataLang[lang].home,
//     icon: 'store_mall_directory',
//     path: 'home',
//     position: MENU_POSITION.INFO
//   },
//   {
//     name: menuDataLang[lang].report,
//     icon: 'insert_chart',
//     path: 'report',
//     position: MENU_POSITION.INFO
//   },
//   {
//     name: menuDataLang[lang].bargain,
//     path: 'find_suppliers',
//     icon: 'home',
//     position: MENU_POSITION.SUPPLIER
//   },
//   {
//     name: menuDataLang[lang].supplier_optimizer,
//     icon: 'search',
//     path: 'supplier_optimizer',
//     position: MENU_POSITION.SUPPLIER
//   },
//   {
//     name: menuDataLang[lang].import_list,
//     icon: 'list_alt',
//     path: 'storemanage/import',
//     badge: 'importList',
//     position: MENU_POSITION.OPERATION
//   },
//   {
//     name: menuDataLang[lang].my_products,
//     path: 'storemanage/myproduct',
//     badge: 'myProduct',
//     icon: 'local_mall',
//     position: MENU_POSITION.OPERATION
//   },
//   window.CAINIAO_USER ? {
//     name: menuDataLang[lang].orders_open,
//     icon: 'assignment',
//     badge: 'Open',
//     position: MENU_POSITION.ORDERS,
//     path:'order',
//     children: [
//       {
//         name: 'AliExpress',
//         icon: '',
//         badge: 'AEOpen',
//         target: '',
//         path: 'list',
//       },
//       {
//         name: 'Tmall',
//         icon: '',
//         badge: 'TmallOpen',
//         path: 'tmall/list',
//         target: '',
//       },
//       {
//         name: 'Agency',
//         icon: '',
//         badge: 'AgencyOpen',
//         path: 'agency/list',
//         target: '',
//       },
//       {
//         name: 'Unmapped',
//         icon: '',
//         badge: 'UnmappedOpen',
//         path: 'unmapped/list',
//         target: '',
//       }
//     ],
//   } : {
//     name: menuDataLang[lang].orders_open,
//     icon: 'assignment',
//     badge: 'Open',
//     position: MENU_POSITION.ORDERS,
//     path:'order/list'
//   },
//   {
//     name: menuDataLang[lang].orders_archived,
//     path: 'order/archived',
//     badge: 'Archived',
//     icon: 'archive',
//     position: MENU_POSITION.ORDERS
//   },
//   {
//     name: menuDataLang[lang].tracking,
//     icon: 'local_shipping',
//     path: 'tracking',
//     position: MENU_POSITION.TRACKING
//   },
//   {
//     name: menuDataLang[lang].new_feature,
//     icon: 'campaign',
//     path: 'csv',
//     position: MENU_POSITION.MORE
//   },
//   {
//     name: menuDataLang[lang].more_store,
//     icon: 'insert_link',
//     path: 'bind/link-store',
//     position: MENU_POSITION.MORE
//   },
//   {
//     name: menuDataLang[lang].settings,
//     icon: 'settings',
//     path: 'settings',
//     position: 'bottom'
//   },
//   {
//     name: menuDataLang[lang].subscription,
//     icon: 'tune',
//     path: '/select',
//     target: '_blank',
//     position: 'bottom'
//   },
//   {
//     name: menuDataLang[lang].support,
//     icon: 'help_outline',
//     children: [
//       {
//         name: menuDataLang[lang].help_center,
//         icon: '',
//         target: '_blank',
//         path: 'https://help.dsers.com/',
//       },
//       {
//         name: menuDataLang[lang].email_support,
//         icon: '',
//         path: 'mailto:support@dserspro.com',
//         target: '',
//       }
//     ],
//     position:'other'
//   },
//   {
//     name:'Agency',
//     icon:'people_alt',
//     path:'agency',
//     position:MENU_POSITION.SUPPLIER
//   }
// ];

function formatter(data, parentPath = '/') {
  const menus = data.map(item => {
    let { path } = item;
    if (!isUrl(path)) {
      path = parentPath + item.path;
    }
    const result = {
      ...item,
      path
    };
    if (item.children) {
      result.children = formatter(item.children, `${parentPath}${item.path}/`);
    }
    return result;
  });

  return menus;
}

export const getMenuData = () => {
  // const hasBargainAccess = window.DSERS_BARGAIN || getAdminRole();
  // 新版灰度测试不可以直接导出字面量了 这里改成方法调用 延迟生成menu data，否则menu生成早于灰度数据返回造成bug
  const data = formatter(createMenuData());

  const hasSupplierOptimizerAccess = getDisplaySupplierOptimizer();
  if (!hasSupplierOptimizerAccess) {
    try {
      const routeIndex = data.findIndex(i => i.path === '/supplier_optimizer');
      const bargainRouteIndex = data.findIndex(
        i => i.path === '/find_suppliers'
      );
      if (routeIndex > -1) {
        data.splice(routeIndex, 1);
      }
      data[bargainRouteIndex].position = MENU_POSITION.OPERATION;
    } catch (error) {
      console.log(error);
    }
  }

  return data;
};
