import { message } from 'antd';

// import * as reportApi from '../services/report';

export default {
  namespace: 'report',

  state: {
    //
  },

  effects: {
    //
  },

  reducers: {
    showModal(state, { payload }) {
      return {
        ...state,
        editModal: {
          ...state.editModal,
          ...payload,
          modalVisible: true
        }
      };
    },
    hideModal(state, { payload }) {
      return {
        ...state,
        editModal: {
          ...state.editModal,
          ...payload,
          modalVisible: false
        }
      };
    }
  }
};
