import React, { useCallback } from 'react';
import { Modal } from 'antd';
import { connect } from 'dva';
import intl from 'react-intl-universal';

import ResetPassword from 'routes/User/ResetPassword';
import styles from './ResetPasswordModal.less';

function ResetPasswordModal({ visible, dispatch }) {
  const handleCancel = useCallback(() => {
    dispatch({ type: 'user/setPasswordModalVisible', payload: false });
  }, []);

  return (
    <Modal
      visible={visible}
      title={intl.get('reset_password.title')}
      width={600}
      maskClosable={false}
      keyboard={false}
      footer={null}
      onCancel={handleCancel}
      className={styles.modal}
      destroyOnClose
      wrapClassName="loginDialog"
    >
      <ResetPassword />
    </Modal>
  );
}

const mapStateToProps = ({ user }) => ({
  visible: user.passwordModalVisible
});

export default connect(mapStateToProps)(ResetPasswordModal);
