export default {
  help_center: {
    help_center: {
      fixed_header: 'Help Center',
      latest_news: 'Latest news',
      load_more: 'Load More',
      online_chat: 'Online chat on Messenger',
      online_time: 'Online time: 7:00AM-00:00AM(UTC+8)',
      work_days: 'Working days: 7 days',
      message_us: 'Message Us',
      email_report: 'Email: Report an issue',
      email_report_desc: `Your email will be replied in <span style="font-weight: 700">1 working day</span>.
                          If you did not get reply from us, make sure to check junk mail and make sure dsers.com is not in your email greylisting.`,
      product_sourcing: 'Email: Product sourcing',
      product_sourcing_desc: `Your email will be replied in <span style="font-weight: 700">1 working day</span>.
                              Please let us know your DSers account name, store url,
                              AliExpress product link as reference,
                              preferred shipping method, estimated sale volume over last 7 days.`,
      blog_in_en: 'Blog in English',
      everything_about_ds: 'Everything about DSers',
      blog_in_fr: 'Blog en Français',
      coming_soon: 'Coming soon...',
      blog_in_pt: 'Blog em Português',
      youtube_channel: 'Youtube Channel',
      check_ds_youtube: 'Check DSers Youtube Channel'
    },
    entrance: {
      get_support: 'Get support',
      online_chat: 'Online chat'
    },
    question_modal: {
      title: 'How can we help you?',
      tell_tip: 'Please tell us what kind of issue you are having',
      tell_select_placeholer: 'Select from the list',
      our_suggestions: 'Here are our suggestions',
      useful_tip: 'Here are some tips that maybe useful to you:',
      suggesttions_default_tip:
        'We will provide suggestions according to <br /> your selected issue. If it’s not useful to <br /> you, you can still get online help by <br /> clicking the button below.',
      get_online_help: 'Get online help'
    },
    detail: {
      by_ds_team: 'by DSers team',
      ds_users: 'Hi dear users',
      so_much: 'Thank you so much for working with DSers. <br /> We hope that you are having a nice day.',
      to_contact: 'If you have any questions about it, <br /> please feel free to contact',
      happy_with_ds: 'Happy dropshipping with DSers.',
      best_regards: 'Best regards, <br /> DSers team'
    },
    support_here: 'Get online support here'
  }
};
