
    export default 
    {
    "home": {
        "welcome": "Willkommen bei DSers！",
        "orders_to_ae": "Bestellungen auf AliExpress zu platzieren",
        "order_to_ae": "Bestellung bei AliExpress",
        "move": "Machen Sie schön weiter:",
        "close": "Schließen",
        "open": "Öffnen",
        "learn_more": "Mehr erfahren",
        "tab_title": {
            "add_shopify_store": "Shopify-Shop hinzufügen",
            "aliExpress_account": "AliExpress-Konto",
            "white_list": "Whitelist",
            "setting_shipping": "Versand festlegen",
            "mapping_product": "Produkt zuordnen",
            "chrome_extension": "Chrome-Erweiterung",
            "bulk_order_function": "Sammelbestellung"
        },
        "add_shopify_store": {
            "title": "Verknüpfung mit Ihrem Shopify-Shop:",
            "p1": "Importieren Sie Produkte schnell in Ihren Shopify-Shop",
            "p2": "Synchronisierung der Bestellungen der letzten 7 Tage aus Shopify",
            "p3": "Wechseln zu",
            "p4": "Einstellungen>Andere>Synchronisierung von Shopify-Bestellungen",
            "p5": "um weitere frühere Bestellungen zu synchronisieren",
            "btn": "Kostenfreier Link"
        },
        "aliExpress_account": {
            "title": "Verknüpfen Sie sich mit Ihrem AliExpress-Konto, <br /> um:",
            "p1": "Mehrere Bestellungen automatisch mit einem Klick aufgeben",
            "p2": "Die Effizienz um 97% zu verbessern",
            "p3": "Versandinformationen zu verfolgen",
            "p4": "Stellen Sie sicher, dass Ihr AliExpress-Konto wie unten verlinkt ist:",
            "btn": "Link"
        },
        "white_list": {
            "title": "Beantragen Sie ein AliExpress-Whitelist-Konto, <br /> um:",
            "p1": "Bestellungen über die offizielle AliExpress-API aufgeben",
            "p2": "Bestell- und Zahlungsbeschränkungen aufheben",
            "p3": "speziellen Dropshipping-Service von AliExpress zu erhalten",
            "btn": "Anwenden"
        },
        "chrome_extension": {
            "title": "DSers Chrome-Erweiterung herunterladen, <br /> um:",
            "p1": "Migration mit einem Klick von Oberlo",
            "p2": "Produkte von AliExpress importieren",
            "p3": "Massenzahlungen auf AliExpress",
            "btn": "Downloaden "
        },
        "more_about": "Erfahren Sie mehr über DSers",
        "video_title_mapping": "Wie kann man AliExpress-Produkte vor einer Mehrfachbestellung zuordnen?",
        "video_title_place_order": "Wie kann man 100+ Bestellungen innerhalb weniger Minuten aufgeben?",
        "other_features": "Ein kurzer Überblick über andere Funktionen",
        "video_title_add_store": "Wie kann man einen Shopify-Shop hinzufügen?",
        "video_title_bind_ae": "Wie bindet und aktualisiert man ein AliExpress-Konto?",
        "video_title_import_products": "Wie importiert man Produkte in den Shopify-Shop?",
        "video_title_fulfill_manually": "Wie kann man Bestellungen manuell ausführen?",
        "bottom_line": "Wir haben das Ergebnis",
        "home_title": "Willkommen bei DSers, der offiziellen AliExpress-App",
        "setup_account": "Richten Sie Ihr DSers-Konto ein",
        "tutorial": "Anleitung",
        "product_importer": "Produkt-Importer",
        "chrome_extension_title": "Chrome-Erweiterung",
        "report": "Bericht",
        "check_report": "Überprüfen Sie Ihre Verkäufe, Ausgaben und Produkt-ROI",
        "feedback": "Rückmeldung",
        "feedback_share": "Teilen Sie Ihr Feedback",
        "feedback_useful": "Diese Informationen sind sehr nützlich",
        "feedback_not_useful": "Diese Informationen sind nicht nützlich",
        "feedback_what_to_know": "Teilen Sie uns mit, was Sie gerne wissen möchten",
        "comments": "Kommentare",
        "share_feedback": "Feedback teilen",
        "new": "Neu",
        "dismiss": "Ablehnen",
        "home_normal_card_learn_more_link": "https://www.blog.dserspro.com/",
        "tutorial2": "Tutorial",
        "modal_title": {
            "title": "New UI & New Beginning",
            "wenhou": "Dear DSers users,",
            "container_one": "First of all, thank you for your ongoing support to DSers. In order to provide users with a better experience, we specially worked with a professional design team to upgrade the UI of our DSers platform.",
            "container_two": "\n      After six months of preparation, the new version of UI has been officially launched today, you can now start your new UI experience journey by clicking the button below.",
            "container_three": "\n      Of course, we will keep the access to the old version of UI for you in case that you have any doubts or other considerations. After entering the page, you are free to switch between these two versions at any time as well.\n       Both version will remain accessible for a certain period of time, depending on the user's feedback. We will notify you in advance of any further changes.",
            "container_four": "\n      Thank you again for your constant support to DSers, which will be the motivation for us to keep optimizing and become a better DSers platform.\n      ",
            "go_new_btn": "START EXPERIENCING THE NEW UI",
            "use_old": "Continue using legacy UI"
        },
        "go_modify": "Go modify",
        "vat_error": "Dear user, the system has detected that the VAT ID you currently set contains non-numeric characters, which will cause your French order to fail (please note that the VAT ID you set is for the French order, other countries currently don't require it). To process the order smoothly, please go to Setting - Other - VAT to modify or clear it",
        "var_update": "If you do not modify it, we will automatically delete the VAT ID on the French orders",
        "modify_btn": "Modify",
        "var_update_setting": "The current VAT ID for French orders has been discontinued. To process the order smoothly, please modify it in time",
        "change_UI": "Change to old UI",
        "oldUI_out": {
            "notion_title": "New UI Notification",
            "notion_con": "Access to the old UI will not be possible after <br /> October 15th",
            "title": "Brand new UI,<br /> brand new experience",
            "modal_text": "After nearly a month of user feedback and technical adjustments, the current new version of the UI has been improved. To unify the platform features, \n      we will remove the access to the old UI on October 15th. Please be aware in advance. If you have any questions, feel free to <a style=\"color:blue\" href='https://www.messenger.com/t/DSersOfficial' dd=\"src\">contact our customer service</a> team."
        },
        "downloadguide": {
            "guide": "Get the guide",
            "text": "Click to download the<br /> dropshipping guide"
        },
        "guidance": {
            "steps": "Schritt",
            "btn_complete": "ABGESCHLOSSEN",
            "title": "Lernen Sie DSer kennen",
            "desc": "4 Schritte zur Automatisierung Ihres Dropshipping-Geschäfts",
            "add_product": "PRODUKT HINZUFÜGEN",
            "push_to_shopify": "ZU SHOPIFY SCHIEBEN",
            "suppiler_mapping": "LIEFERANTENZUORDNUNG",
            "place_order": "AUFTRAG ERTEILEN",
            "add_product_title": "Ihr erstes Produkt hinzufügen",
            "push_to_shopify_title": "Übertragen Sie Produkte in Ihren Shopify-Shop",
            "suppiler_mapping_title": "Kartierung: Einrichten der Lieferanten der Produkte",
            "place_order_title": "Bearbeiten Sie Ihre erste Bestellung",
            "add_product_desc": "Fügen Sie die Produkte hinzu, die Sie in Ihrem Shop verkaufen möchten. Sie können in Find Supplier nach Produkten suchen, die von AliExpress oder DSers angeboten werden. Klicken Sie <a href=\"https://help.dsers.com/import-product-from-aliexpress-for-shopify/\" target=\"_blank\" >hier</a> für weitere Details.",
            "push_to_shopify_desc": "Wenn Sie Ihre Produkte in DSers haben, bearbeiten Sie sie und senden sie an Ihren Shopify-Shop. Klicken Sie <a href=\"https://help.dsers.com/push-a-product-to-your-shopify-store/\" target=\"_blank\" >hier</a> für weitere Details.",
            "suppiler_mapping_desc": "Richten Sie AliExpress-Lieferanten für Ihre Produkte ein, um zukünftige Bestellungen einfach zu bearbeiten. Klicken Sie <a href=\"https://help.dsers.com/basic-mapping-advanced-mapping-bogo-bundle-introduction-for-shopify/\" target=\"_blank\" >hier</a> für weitere Details.",
            "place_order_desc": "Sie können Bestellungen in Gruppen sehr schnell aufgeben, und DSers verwaltet die Bestellungen automatisch für Sie, nachdem die Bestellung erfolgreich aufgegeben wurde - klicken Sie hier für weitere Details. Klicken Sie <a href=\"https://help.dsers.com/fulfill-an-order-from-start-to-finish-for-shopify/\" target=\"_blank\" >hier</a> für weitere Details.",
            "tip_to_pc": "To ensure a better user experience, log in using a computer to operate DSers with the right conditions"
        },
        "oberlo": {
            "card_title_1": "Fast geschafft",
            "card_title_des_1": "Bitte nehmen Sie die folgenden Einstellungen vor, bevor Sie Bestellungen bearbeiten und die erweiterten Funktionen nutzen.",
            "card_title_2": "Tutorial",
            "card_title_des_2": "Diese Kurzanleitung hilft Ihnen, Bestellungen zu bearbeiten und Produkte zu importieren.",
            "step_title_1": "Verknüpfen Sie Ihr AliExpress-Konto",
            "step_title_2": "DSers Abonnement aktivieren",
            "step_title_3": "DSers Chrome-Erweiterung installieren",
            "step_des_1": "Verknüpfen Sie Ihr AliExpress-Konto sofort, um den Bestellstatus zu aktualisieren! Klicken Sie <a href=\"https://help.dsers.com/link-to-aliexpress/\" target=\"_blank\">hier</a> für weitere Details.",
            "step_des_2": "Genießen Sie Oberlo-ähnliche Funktionen mit neuen erweiterten Möglichkeiten von DSers. Klicken Sie <a href=\"https://help.dsers.com/oberlo-migration-compare-plans/\" target=\"_blank\">hier</a> für weitere Details.",
            "step_des_3": "Ein-Klick-Produktimport von AliExpress zu DSers, um Zeit zu sparen und neue Verkaufsschlager zu finden! Klicken Sie  <a href=\"https://help.dsers.com/import-product-from-aliexpress-for-shopify/\" target=\"_blank\">hier</a> für weitere Details.",
            "obPlan": "Aktivieren Sie den DSers <i>{ dsers }</i> plan , um Ihre aktuellen Funktionen im Oberlo <i>{oberlo}</i> Plan zu nutzen.",
            "btntext1": "LINK ALIEXPRESS",
            "btntext2": "Plan aktivieren",
            "btntext3": "Ich werde es später tun",
            "btntext4": "Herunterladen",
            "btntext5": "Mehr sehen",
            "billing_error": "Sie können den Plan nicht aktivieren, weil Ihr Abrechnungsshop nicht verfügbar ist. Bitte wechseln Sie den Abrechnungsshop auf der Seite DSers - Abonnements verwalten oder <a href=\"/app/select\">aktivieren</a> Sie den Abrechnungsshop im",
            "billing_error1": "Sie können den Plan nicht aktivieren, weil Ihr Abrechnungsshop nicht verfügbar ist. Bitte wechseln Sie den Abrechnungsshop auf der Seite DSers - Abonnements verwalten oder <a href=\"/app/select\">aktivieren</a>.",
            "billing_error_link": "Shopify-admin.",
            "stepsdes": "Schritt(e) übrig",
            "finish": "Abgeschlossen",
            "year_error": "Wenn Sie einen neuen Tarif aktivieren, aber zuvor die Jahresgebühr für den DSers Standard-Tarif bezahlt haben, wenden Sie sich bitte an den Kundendienst, um den nicht genutzten Teil zurückerstattet zu bekommen.",
            "step_title_4": "Oberlo-Daten migrieren",
            "step_des_4": "Verknüpfen Sie Ihr Oberlo-Konto und synchronisieren Sie Daten mit DSers.",
            "stopProduct": "Die Produktdaten wurden nicht korrekt aktualisiert. Klicken Sie auf \"Erneut versuchen\" oder wenden Sie sich an das <a href='https://www.messenger.com/t/DSersOfficial' target=\"_blank\">DSers-Supportteam</a>",
            "stopOrder": "Der Bestellstatus wurde nicht korrekt aktualisiert. Klicken Sie auf 'Erneut versuchen' oder kontaktieren Sie das <a href='https://www.messenger.com/t/DSersOfficial' target=\"_blank\">DSers-Supportteam.</a>",
            "successProduct": "Die Aktualisierung der Produktdaten ist abgeschlossen.",
            "successOrder": "Die Aktualisierung des Auftragsstatus wurde abgeschlossen, bitte gehen Sie zur Seite <a href='/app/order/list'>Offene Aufträge</a> für weitere Informationen.",
            "syncProduct": "Wir sind immer noch dabei, die Produktdaten von Shopify zu aktualisieren...",
            "syncOrder": "Wir sind immer noch die Aktualisierung der Bestellung Status von AliExpress. DSers migriert Bestellungen aus den letzten zwei Monaten. Wenn Sie mehrere Aufträge synchronisieren möchten, <a href='https://www.messenger.com/t/DSersOfficial' target=\"_blank\">kontaktieren Sie uns</a> bitte.",
            "migrated": "MIGRATED",
            "linked": "LINKED"
        },
        "testA": {
            "haha": "<a <a target=\"_blank\" href=\"http://www.baidu.com\">ceshia</a>"
        },
        "ShopifyOrders": "Shopify orders",
        newGuide:{
            primary_title:`Dropshipping-Leitfaden`,
            primary_descriptions:`Wir zeigen Ihnen die am besten geeigneten Inhalte je nach Ihrer Auswahl.`,
            second_title_1:`Keine Produkte, keine Bestellungen`,
            second_description_1:`Ich bin neu und weiß nicht, wie ich DSers nutzen kann, um mein Dropshipping-Geschäft zu starten.`,
            second_title_2:`Ich habe Produkte, aber keine Bestellungen`,
            second_description_2:`Ich habe bereits Artikel auf Shopify, aber ich weiß nicht, wie ich mein Geschäft auf DSers starten kann.`,
            second_title_3:`Ich habe Produkte und habe Bestellungen`,
            second_description_3:`Ich habe Lösungen wie DSers verwendet, aber ich muss wissen, wie ich die Hauptfunktionen von DSers nutzen kann.`,
            second_more_1_title_1:`What Is Dropshipping?`,
            second_more_1_title_2:`How Can You Start a Dropshipping Store with DSers?`,
            second_more_1_title_3:`Wie importiere ich Produkte in Find Supplier?`,
            second_more_1_title_4:`Wie importiere ich Produkte in der Importliste?`,
            second_more_1_title_5:`Wie kann man Produkte an Shopify senden, nachdem man sie importiert hat?`,
            second_more_2_title_1:`Ultimate Guide to 15 Evergreen Products to Dropship in 2023`,
            second_more_2_title_2:`Top 10 Trending Dropshipping Products for 2023`,
            second_more_2_title_3:`Wie importiert man Artikel aus Shopify in DSers?`,
            second_more_2_title_4:`Wie findet man den richtigen Lieferanten für ein Produkt?`,
            second_more_3_title_1:`How to Set a Proper and Lucrative Dropshipping Profit Margin?`,
            second_more_3_title_2:`A Step-by-Step Guide to Deal with Your First Dropshipping Order`,
            second_more_3_title_3:`Wie kann ich eine Bestellung auf DSers aufgeben?`,
            second_more_3_title_4:`How to set up the shipping template?`,
            Expand:`Erweitern`,
            Leave:`ZUSAMMENBRUCH`,
            more_videos:'mehr Videos'
          }
    }
}