import React, { Fragment } from 'react';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';
import { Modal, Button, Icon } from 'antd';

import { replaceUrlToHttps } from 'utils/utils';

import styles from './index.less';

@connect(({ fulfillmentcenter, loading }) => ({
  fulfillmentcenter,
  loading: loading.models.fulfillmentcenter,
  switchLoading: loading.effects['fulfillmentcenter/status']
}))
export default class Ad extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      bottomVisible: false,
      prod: {}
    };
  }

  componentDidMount() {
    this.getList();
  }

  modalAd = localStorage.getItem('modalAd');

  // 获取广告商品
  getList = () => {
    this.props.dispatch({
      type: 'fulfillmentcenter/modallist',
      payload: {
        callback: d => {
          if (d.code == 2000) {
            localStorage.setItem('showFulfillmentCenter', d.data.activity); // 是否展示 Fulfillment Center 菜单

            if (!d.data.prods) {
              return;
            }

            const prod = d.data.prods[0];
            this.setState({
              prod,
              visible: this.modalAd != prod.supplier_product_id,
              bottomVisible: !!prod
            });
          }
        }
      }
    });
  };

  // 展示弹窗
  showModalAD = () => {
    this.setState({
      visible: true
    });
  };

  // 隐藏弹窗
  handleModalAD = () => {
    this.setState({
      visible: false
    });
    localStorage.setItem('modalAd', this.state.prod.supplier_product_id);
  };

  // 激活
  switchNow = () => {
    const supplier_product_id = this.state.prod.supplier_product_id;
    this.props.dispatch({
      type: 'fulfillmentcenter/status',
      payload: {
        data: {
          supplier_product_id,
          status: 2
        },
        callback: d => {
          const { success } = d.data || {};

          if (success) {
            localStorage.setItem('modalAd', supplier_product_id);
            this.setState({
              visible: false,
              bottomVisible: false
            });
            this.props.dispatch(routerRedux.push('/fulfillment-center'));
          }
        }
      }
    });
  };

  // 展示fackbook聊天窗
  showFBDialog = e => {
    e.stopPropagation();
    if (window.FB) {
      FB.CustomerChat.showDialog();
    }
  };

  // 关闭底部banner
  closeBottom = () => {
    this.setState({
      bottomVisible: false
    });

    this.props.dispatch({
      type: 'fulfillmentcenter/closeBottom',
      payload: {
        data: {
          supplier_product_id: this.state.prod.supplier_product_id,
          status: 4
        }
      }
    });
  };

  render() {
    const { switchLoading } = this.props;

    const { visible, bottomVisible, prod } = this.state;

    let saved = 0;
    if (prod.supplier_product_id) {
      saved = (prod.origin_cost - prod.supplier_cost).toFixed(2);
    }

    return (
      <>
        {bottomVisible ? (
          <div className={styles.adBottomWrap}>
            <Icon
              type="close"
              onClick={this.closeBottom}
              className={styles.closeIco}
            />
            <div className={styles.ad}>
              <img src={replaceUrlToHttps(prod.image)} />
              <span>DFC Product</span>
            </div>
            <p className={styles.slogan}>
              Save<span className={styles.saveMoney}>${saved}</span>USD{' '}
              <span className={styles.tip}>Per 100 orders</span>
            </p>
            <a
              className={styles.knowMore}
              role="button"
              tab-index="0"
              onClick={this.showModalAD}
            >
              Know more
            </a>
          </div>
        ) : null}

        <Modal
          visible={visible}
          className={styles.adModal}
          footer={false}
          width={580}
          maskClosable={false}
          onCancel={this.handleModalAD}
        >
          <h3 className={styles.adTitle}>Fulfillment By DSers(FBD)</h3>
          <div className={styles.adContent}>
            <div className={styles.imgBox}>
              <p className={styles.adTip}>Available in FBD Now !</p>
              <img src={replaceUrlToHttps(prod.image)} />
              <p className={styles.title}>{prod.title}</p>
            </div>
            <dl className={styles.dec}>
              <dt className={styles.saveMoney}>
                Save<span>${saved}</span>USD
              </dt>
              <dd>Per month</dd>
              <dt>12-48hours</dt>
              <dd>Processing time</dd>
              <dt>ePacket</dt>
              <dd>Available</dd>
              <dt>Customized Service</dt>
              <dd>Full package service</dd>
              <dt>Admitad Cash back</dt>
              <dd>Available</dd>
            </dl>
          </div>
          <div className={styles.btnLine}>
            <a
              href="https://www.blog.dserspro.com/post/how-to-use-fbd-what-is-it-on-dsers-pro"
              target="_blank"
            >
              What is FBD?
            </a>
            <a
              href="https://www.messenger.com/t/DSersOfficial" dd="src"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact for info
            </a>
            <Button
              type="primary"
              loading={switchLoading}
              onClick={this.switchNow}
              className={styles.switchNow}
            >
              Switch Now
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}
