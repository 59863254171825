import React, { useState, useEffect,useImperativeHandle,forwardRef } from 'react';
import { Select, Row, Col } from 'antd';

import SelectTool from './SelectTool';

import styles from './Store.less';

const { Option } = Select;
const mapArr = ['collections', 'product_type', 'tags', 'vendor'];

function Store(props,ref) {
  const [storeId, setStoreId] = useState(undefined);
  const { allStore = [] } = props;

  useEffect(() => {
    const { organizationValue, id } = allStore.find(
      item => item.id === props.indexByStoreId
    );

    if (organizationValue) {
      setStoreId(id);
    }
  }, [props.indexByStoreId]);

  useImperativeHandle(ref, () => ({
    // changeVal 就是暴露给父组件的方法
    changeStoreSS: changeStore
  }));

  useEffect(()=>{
    setStoreId(props.indexByStoreId)
  },[])


  const changeStore = (v) => {
    if (!v) {
      mapArr.forEach(item => {
        props.changeCategory({
          storeId,
          value: [],
          type: item
        });
      });
    }
    props.changeStore(v, props.i);
    setStoreId(v);
  }

  return (
    <div className={styles.box}>
      {/* <div className={styles.store}>
        <Select
          allowClear
          value={storeId}
          placeholder="Select a store"
          onChange={changeStore}
        >
          {allStore.map(item => {
            return (
              <Option
                key={item.id}
                disabled={props.selectStoreIds.includes(item.id)}
              >
                {item.shop_name}
              </Option>
            );
          })}
        </Select>
      </div> */}
      <Row >
        <div className="se_box">
          <SelectTool
            type="collections"
            mode="multiple"
            storeId={storeId}
            allStore={allStore}
            changeCategory={props.changeCategory}
            changeHiddenStatus={props.changeHiddenStatus}
            clickCategory={()=>{
              changeStore(props.indexByStoreId)
            }}
          />
          <SelectTool
            type="product_type"
            mode="tags"
            storeId={storeId}
            allStore={allStore}
            changeHiddenStatus={props.changeHiddenStatus}
            changeCategory={props.changeCategory}
            add
            single
          />
          <SelectTool
            type="tags"
            mode="tags"
            storeId={storeId}
            allStore={allStore}
            changeHiddenStatus={props.changeHiddenStatus}
            changeCategory={props.changeCategory}
            add
          />
          <SelectTool
            type="vendor"
            mode="tags"
            storeId={storeId}
            allStore={allStore}
            changeHiddenStatus={props.changeHiddenStatus}
            changeCategory={props.changeCategory}
            add
            single
          />
        </div>
      </Row>
    </div>
  );
}

export default forwardRef(Store);
