
    export default 
    {
    "reset_password": {
        "title": "Restablecer contraseña",
        "input_old_password": "Por favor, ingresa tu contraseña anterior.",
        "old_password": "Contraseña anterior",
        "new_password": "Nueva contraseña",
        "input_confirm_password": "Por favor, confirma tu nueva contraseña.",
        "confirm_password": "Confirmar contraseña",
        "reset_password": "Restablecer contraseña",
        "wrong_password": "Contraseña incorrecta. Intenta de nuevo.",
        "astrict": "Por favor, introduce de 6 a 20 caracteres.",
        "old_password_error": "La contraseña original es incorrecta"
    }
}