import React, { useEffect, useState } from 'react';
import { useDispatch } from 'dva' 

import { Drawer, Button, Collapse } from 'antd';
import intl from 'react-intl-universal';
import inputguide from 'assets/inputguide.gif'

import { GuildeAction } from 'actions';

import styles from './InputGuide.less'

const { Panel } = Collapse;
export default function InputGuide(props) {
  const [visible,setVisible] = useState(false)
  const dispatch = useDispatch()
  useEffect(()=>{
    if(Math.round(new Date() / 1000) > 1660191128 || localStorage.getItem('closeInputGuide')==='true'){
      dispatch({
        type:'global/actionGuide'
      })
      setVisible(false)
    }else{
      setVisible(true)
    }
  },[])
  const closehandler = (e)=>{
    localStorage.setItem('closeInputGuide',true)
    dispatch({
      type:'global/actionGuide'
    })
    setVisible(false)
  }
  return (
    visible ? <div className={styles.guideContainer}>
      <span className={styles.sanjiao}></span>
      <img width={438} height={184} src={inputguide} alt="" />
      <div className={styles.textContainer}>
        <p>{intl.getHTML('public.input_guide_text')}</p>
      </div>
      <div className={styles.disappearContainer}>
        <span onClick={closehandler}>{intl.getHTML('public.input_guide_btn')}</span>
      </div>
    </div> :null
  );
}
