import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.less';

function QuickLink({ value }) {
  return (
    <div className={styles.intro}>
      {value.map(i => (
        <a
          key={i.label}
          href={i.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {i.label}
        </a>
      ))}
    </div>
  );
}

QuickLink.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      link: PropTypes.string.isRequired
    })
  ).isRequired
};

export default QuickLink;
