
    export default 
    {
    "fulfillment_center": {
        "activate": "Sei sicuro di voler arrivare i prodotti FBD?",
        "activate_dec": "Il tuo fornitore predefinito deve essere cambiato con il prodotto FBD dopo 48 ore.",
        "input_confirm": "Si prega di inserire CONFERMARE per attivare.",
        "deactivate": "Sei sicuro di voler disattivare i prodotti FBD?",
        "deactivate_dec": "Il tuo fornitore predefinito sarà cambiato con il fornitore AliExpress nella tua pagina di tracciamento dopo 48 ore.",
        "using_tip": "Stai usando il servizio FBD per questo prodotto. I fornitori dalla pagina di Tracciamento saranno usati una volta che sarà disattivato.",
        "close_request": "La tua richiesta è sotto esame. Verrà attivata fra 48 ore. Durante le 48 ore, l'attuale fornitore verrà utilizzato",
        "closeing": "Il servizio FBD verrà utilizzato quando sarà attivato",
        "activate_request": "La tua richiesta è sotto esame. Il fornitore nella pagina di tracciamento verrà utilizzato dopo 48 ore",
        "go_setting": "Il negozio dev'essere autorizzato di nuovo. Si prega di andare a <a href=\"/app/settings?id=4\" target=\"_blank\">Impostazioni - Account</a> per aggornare l'autorizzazione. In caso contrario, non potrai utilizzare il negozio correttamente su DSers",
        "title": "Centro di Assisienza (FBD)",
        "dec": "Non processeremo prodotti tramite FBD se i tuoi ordini sono con molteplici prodotti forniti dal medesimo fornitore AliExpress, per evitare tariffe di spedizione doppie.",
        "pruchese": "Acquista",
        "saved": "Salvato",
        "placeholder": "Seleziona negozio"
    }
}