
    export default 
    {
    "product_split": {
        "split": "Dividir",
        "title": "Split product",
        "split_by_option": "Dividir por opción",
        "split_by_option_dec": "Select the option that will be split to another product",
        "option_tip": "Crearemos { length } producto(s), cada producto sólo contendrá { optionName } variante(s) ( {values} )",
        "or": "o",
        "split_by_variant": "Dividir por variante",
        "split_by_variant_dec": "Selecciona las variantes que se dividirán en otro producto",
        "tip": "Las variantes seleccionadas se utilizarán para crear un nuevo producto y el producto actual seguirá teniendo todas las variantes originales.",
        "select_split_mothod": "Por favor, selecciona un método para dividir",
        "confirm_split": "Split Product",
        "confirm_split_desc": "Are you sure you want to split the product? It will be split into different products.",
        "product_not_found": "The product you entered was not found",
        "network_issue": "The operation failed due to network issue, please try again"
    }
}