import React, { Component } from 'react';
import ReactGA from 'react-ga';

export default function withTracker(WrappedComponent, options = {}) {

  return class extends Component {
    componentDidMount() {
      const page = this.props.location.pathname;
      this.trackPage(page);
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;
      if (currentPage !== nextPage) {
        this.trackPage(nextPage);
      }
    }

    trackPage = page => {
      ReactGA.set({
        page,
        ...options
      });
      ReactGA.pageview(page);
    };

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}
