export default {
  beforeMigration: {
    shopify: 'Conserver uniquement les paramètres du site Shopify (ou CSV).',
    wooCommerce: 'Conserver uniquement les paramètres du site WooCommerce.',
    wix: 'Conserver uniquement les paramètres du site Wix.',

    title: 'Le système sera bientôt entièrement mis à niveau !',
    title1: 'Mise à niveau du système en approche',
    one: 'Le DSers sera mis à niveau le ',
    two: "et sera indisponible pendant jusqu'à 24 heures",
    there: 'Veuillez organiser votre emploi du temps en conséquence.',

    four:
      'Pour vous offrir une meilleure expérience utilisateur, DSers mettra à niveau le système pendant une période de',
    five: '24 heures à partir du',

    six:
      'DSers sera indisponible pendant la période. Nous nous excusons pour la gêne occasionnée.',

    seven: 'Cliquez ',
    seven1: 'ici ',

    //----------
    eight: ' pour en savoir plus.',

    nine: 'pour obtenir plus d\'informations.',

    ten:
      'Pour vous offrir une meilleure expérience utilisateur, DSers mettra à niveau le système pendant une période de',
    eleven:
      'DSers sera indisponible pendant la période. Nous nous excusons pour la gêne occasionnée.',
    twelve:
      'La mise à niveau vise à simplifier la gestion multiplateforme. Cela peut provoquer des conflits de paramètres si vous gérez des magasins sur plusieurs plateformes. La compatibilité des différences entre chaque plateforme est pleinement prise en compte. Néanmoins, veuillez nous faire part de vos préférences au cas où.',
    thirteen:
      'Comment souhaitez-vous fusionner les paramètres potentiellement conflictuels dans les Paramètres après la mise à niveau ?',

    fourteen:
      'Après la fusion, les paramètres au niveau du compte entre les deux sites seront en conflit, vous devez donc choisir quel site conserver. Les paramètres en conflit sont :',

    fifteen: 'Notification',
    sixteen: 'Compte du personnel',
    seventeen: 'Expédition',
    eighteen: "Ajouter une méthode d'expédition mondiale basée sur le produit",
    nineteen: 'Laisser un message aux fournisseurs',
    twenty: 'Optimisation du numéro de téléphone',
    twentyOne: 'Optimisation des commandes annulées',
    twentyTwo: "Et d'autres fonctions.",

    twentyThere: "Mais ne vous inquiétez pas, nous continuerons de conserver les paramètres au niveau du magasin entre les deux sites pour vous, tels que :",

    twentyFour: 'Règle de tarification',
    twentyFive: 'Suivi des colis',
    twentySix: 'Devises',

    twentySeven: 'Le système est en cours de mise à niveau, ce qui ',
    twentyEight: 'prendra environ 24 heures',

    twentyNine:
      'Le DSers sera suspendu pendant la période de mise à niveau, et nous nous excusons pour tout inconvénient.',

    twentyTen:
      'Désolé, le système est actuellement en cours de mise à niveau et ne peut pas être lié, veuillez réessayer ultérieurement',
    notification: 'Notification',

    submit: 'Confirmer'
  }
};
