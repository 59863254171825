
    export default 
    {
    "complete_passWord": {
        "title": "Registra le informazioni del tuo account",
        "tip": "Abbiamo scoperto che le informazioni del tuo account non sono registrate. Imposta la tua e-mail e la tua password",
        "email_tip": "Puoi cambiare l'e-mail in seguito andando su DSers - Impostazioni - Menu Account.",
        "fill_email": "Inserisci un indirizzo e-mail",
        "password_tip": "Usa una password con almeno 6 caratteri.",
        "email_not_valid": "L'input non è un'e-mail valida!",
        "password_request": "Imposta una password",
        "astrict": "Per favore inserisci da 6 a 20 caratteri."
    }
}