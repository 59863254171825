import React, { useState, useEffect, useCallback } from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'dva';
import qs from 'qs';
import intl from 'react-intl-universal';

import Login from './Login';
import PartnerItem from '../../components/PartnerItem/index'
import Logo from '../../components/NewLogo'
import logo from 'assets/svg/new_logo.png';
import setting from 'assets/migration/setting.png'
import ds from 'assets/migration/ds.png'
import shopify from 'assets/migration/shopify.png'

import { pageTitle } from 'utils/constants';

import styles from './MigratSignUp.less';
import message from 'assets/migration/Message.png'

import { Post, Get  } from 'utils/request';
import { Modal, message as mess } from 'antd';
import { CommonDataCollect } from 'utils/utils';
import SelectLanguage from 'components/GlobalHeader/SelectLanguage';

const MigraSignIn = ({ dispatch }) => {
  useEffect(() => {
    
    CommonDataCollect({
      action:'enter_signinpage',
      shop_name:qs.parse(location.search.slice(1))?.shopname,
      custom_data: []
    })

    localStorage.removeItem('authority_token');
    localStorage.removeItem('ZLstatus');
    localStorage.removeItem('payment');
    localStorage.removeItem('type');
    localStorage.removeItem('quantity');

    localStorage.removeItem('storeId');
    localStorage.removeItem('guidanceAgain');
    localStorage.removeItem('errorCashbackCodeTip');
    localStorage.removeItem('storeStatus');
    localStorage.removeItem('migration_stores');
    localStorage.removeItem('showFulfillmentCenter');
    localStorage.removeItem('orderResult');
    localStorage.removeItem('aliBeOverdueTime');
    localStorage.removeItem('aliExpressAuthStatus1');
    localStorage.removeItem('dsersSpBanner');
    localStorage.removeItem('nCoVBanner');
    localStorage.removeItem('DSersToProBanner');
    localStorage.removeItem('DSersToProBannerClose');
    localStorage.removeItem('unRead');
    localStorage.removeItem('guonianbanner');
    localStorage.removeItem('fixStatus');
    localStorage.removeItem('settingOrderData');
    localStorage.removeItem('ae_check_omit');
    localStorage.removeItem('admitad_notice_check');
  }, []);

  const [redirectUrl, setRedirectUrl] = useState(null);
  const [rawRedirectUrl, setRawRedirectUrl] = useState(null);
  const [subloading,setSubLoading] = useState(1)

  useEffect(() => {
    const parse = qs.parse(location.search.slice(1));
    console.log('sign in 参数', parse);

    if(parse){
      setRawRedirectUrl(location.search);
    }

    if (parse && parse.redirect_uri) {
      setRedirectUrl(parse);
      setRawRedirectUrl(location.search);
    }
  }, []);

  const handleSubmit = useCallback(
    async (data) => {
      setSubLoading(1)
      const parse = qs.parse(location.search?.slice(1));
      const res = await Get(`/mgnoberlo-core-api/dsers-api/migration/verify/account?login_email=${data.data.email}`,{},true)
      if(res.code == 401){
        CommonDataCollect({
          action: 'unavailable_account',
          shop_name:parse?.shopname,
          dsers_email:data.data.email,
          custom_data: []
        })
        setSubLoading(2)
        switch(res.message){
          case 'is child account':
            return mess.error(intl.get('migration.childAccount'))
            break;
          case 'is wix account':
            return mess.error(intl.get('migration.otherplatform'))
            break;
          case 'user not available':
            return mess.error(intl.get('migration.blackAccount'))
            break;
          case 'woo account insufficient permissions':
            return mess.error(intl.get('migration.otherplatform'))
            break;
        }
        return
      }
      if (redirectUrl) {
        dispatch({
          type: 'login/pureLogin',
          payload: {
            ...data,
            redirectUrl:{redirect_uri:`${redirectUrl.redirect_uri}&type=a`},
          }
        });
      } else {
        dispatch({
          type: 'login/migrationLogin',
          payload: {
              ...data,
              is_mgr:true,
              mgr_store_id:parse?.shopid,
              shopname:parse?.shopname
          }
        });
      }
    },
    [dispatch, redirectUrl]
  );


  return (
    <DocumentTitle title={`Sign in - ${pageTitle}`}>
      <div className={styles.signIn}>
        <div className={styles.top}>
          <img src={logo} alt="" />
          <div style={{ lineHeight: '72px',marginRight:'150px' }}><SelectLanguage /></div>
        </div>
        <div className={styles.process}>
          <div><span>1</span><span>{intl.get('migration.progress_1')}</span></div>
          <span className={styles.line}></span>
          <div> <span>2</span><span>{intl.get('migration.progress_2')}</span></div>
          <span className={styles.line}></span>
          <div> <span>3</span><span>{intl.get('migration.progress_3')}</span></div>
        </div>
        <div className={styles.migrateText}>
        <div className={styles.text}>{intl.get('migration.title')}</div>
          <div className={styles.mid_icon}><div className={styles.mid_obl}><div style={{ width: '38px', height: '38px', border: '14px solid #2F01FD', borderRadius: '19px' }}></div></div><i class="material-icons" style={{ color: "#7216DD", fontSize: '44px' }}>chevron_right</i><i class="material-icons" style={{color:"#AE497F", fontSize: '44px' }}>chevron_right</i><i class="material-icons" style={{ color: "#FF8F00", fontSize: '44px' }}>chevron_right</i><div className={styles.mid_dsers}><img src="https://img.dsers.com/images/logo.png" alt="" /></div></div>
        </div>
        <div className={styles.container}>
          <div className={styles.container_fix}>
            <div className={styles.text_box}>
              <p className={styles.text_title_2}>{intl.getHTML('login.text_title_2', {
                titlek: styles.titlek_2
              })}</p>
              <p className={styles.text_desc_2}>{intl.getHTML('login.text_desc_2', {
                titlek: styles.titlek_2
              })}</p>
            </div>
            <div className={styles.box}>
              <Login onSubmit={handleSubmit} type='oberlo' subloading={subloading} shopEmail={qs.parse(location.search.slice(1)).shopemail} redirectUrl={rawRedirectUrl} logoutEmail={localStorage.getItem('logoutEmail')}/>
            </div>
          </div>
          {/* <PartnerItem /> */}
        </div>
        <a className={styles.bottom} style={{ marginTop: '48px' }} target='_blank' href="https://help.dsers.com/oberlo-migration/">{intl.get('migration.blog')}</a>
        <div className={styles.end}>
        <div><img src={message} alt="" /><a href='https://www.messenger.com/t/DSersOfficial' target='_blank'>{intl.get('migration.contact_1')}</a></div>
          <div></div>
          <i className="material-icons">local_post_office</i> <a style={{ marginLeft: '5px' }} href='mailto: support@dserspro.com'>{intl.get('migration.contact_2')}</a>
        </div>
      </div>
    </DocumentTitle>
  );
};

export default connect()(MigraSignIn);
