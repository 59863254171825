import React, { useEffect, useState } from 'react';
import ModalOne from './OpenModal';
import ModalTwo from './openModal_2';
import { Get } from 'utils/request';
import { connect } from 'dva';

function index({ userInfo, modalVisible }) {

  const [adUser, setAdUser] = useState(false)
  const [pushedList, setPushedList] = useState()
  const [bannerData, setBannerData] = useState()
  const [visible,setVisible] = useState(true)

  useEffect(()=>{
    if(localStorage.getItem('conversionModal') && !modalVisible){
      setVisible(false)
    }else{
      setVisible(true)
    }
  },[modalVisible])

  useEffect(() => {

    const getAdUser = async () => {
      try {
        const res = await Get('/ad/pushed/list')
        if (res && res.code === 2000) {
          res.data.forEach((item) => {
            if (item.user_id === userInfo.user_id) {
              setAdUser(true)
              setPushedList(item)
            }
          })
        }
      } catch (error) {
        console.log('fetch error:', error)
      }
    }

    getAdUser()

  }, [])

  useEffect(() => {

    const getBannerData = async () => {
      const stores = userInfo.stores || []
      const storeIds = stores.map(item => {
        return item?.id
      }) || []
      const params = {
        data: {
          store_ids: storeIds
        }
      }

      try {
        const res = await Get('/prod/propool/ad/list', params);
        if (res && res.code === 2000 && res.data) {
          setBannerData(res.data[0])
        }
      } catch (error) {
        console.log('fetch error:', error)
      }
    }



    getBannerData()

  }, [])

  if(!bannerData){
    return null
  }


  return (
    <>
      {
       visible && adUser ? !pushedList?.user_group ? <ModalOne bannerData={bannerData} pushedList={pushedList} modalVisible={modalVisible}></ModalOne> : <ModalTwo bannerData={bannerData} pushedList={pushedList} modalVisible={modalVisible}></ModalTwo> : null
      }
    </>
  )
}

export default connect(({ login, global }) => ({
  userInfo: login.userInfo,
  modalVisible:global.conversionModal
}))(index)