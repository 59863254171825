import React from 'react';
import ReactDOM from 'react-dom';
import { Button } from 'antd';

import styles from './NoticeOnline.less';
import useOnlineNotice from '../../../hooks/useOnlineNotice';

const onlineDate = new Date(2020, 8, 24); // 上线日期

const modalRoot = document.getElementById('root');

function NoticeOnline() {
  const [visible, closeNotice] = useOnlineNotice('report', onlineDate);

  function handleClose() {
    closeNotice();
  }

  function handleInnerClick(e) {
    e.stopPropagation();
  }

  if (!visible) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.cpNotice} role="presentation" onClick={handleClose}>
      <div
        className={styles.cpCon}
        role="presentation"
        onClick={handleInnerClick}
      >
        <div className={styles.body}>
          <h3>Report feature is online!</h3>
          <p>Check how it works for you</p>
          <div className={styles.btn}>
            <a
              href="https://help.dsers.com/account-report-for-shopify/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button type="primary">Know more</Button>
            </a>
          </div>
          <div className={styles.footer}>
            <span onClick={handleClose} role="presentation">
              Got it
            </span>
          </div>
        </div>
      </div>
    </div>,
    modalRoot
  );
}

export default NoticeOnline;
