/**
 * Content:升级套餐  && 定制化套餐 
 * Author:GaoHuan 
 */

import React, { useEffect, useState } from "react";
import { Modal, Button } from 'antd';
import GuideRefresh from '../../GuideRefresh'
// import { guildBtnUnify } from '../../OblerloGuide'
import Style from './index.module.less'
import intl from 'react-intl-universal';
const OBPop = ({ update, plan, isBillStore, guildBtnUnify, errorBillName, onceBindAE, onceDownloadChrome }) => {
  const [showModal, setShowModal] = useState(false);
  const [currentMessage, setCurrentMessage] = useState(null);
  const [showRefresh, setShowRefresh] = useState(false)
  useEffect(() => {
    if (!["", undefined, null].includes(update)) {
      if (update.indexOf('CHROME') !== -1 && onceDownloadChrome) {
        return
      }
      if (update.indexOf('AE') !== -1 && onceBindAE) {
        return
      }
      setCurrentMessage(intl.get('guide.ComfirmPop').filter((item => update.indexOf(item.kind) > -1))[0])
      setShowModal(true)
    }
  }, [update, onceBindAE, onceDownloadChrome])

  /**
  * click 点击事件
  */
  const clickTurn = () => {
    setShowModal(false)
    guildBtnUnify(currentMessage.kind === 'AE' ? 1 :
      currentMessage.kind === 'PLAN' ? 2 : 3, () => {
        setShowRefresh(true)
      })
  }
  return (
    currentMessage && <>
      {/* 主弹窗 */}
      <Modal
        visible={showModal}
        zIndex={3000}
        width={560}
        footer={
          <div>
            <Button
              type="primary"
              className={
                currentMessage.kind === 'PLAN' && isBillStore !== 'yes' ?
                  Style.disabledBtnStatus :
                  Style.btnStatus}
              onClick={() => {
                if (currentMessage.kind === 'PLAN' && isBillStore !== 'yes') {
                  return
                }
                clickTurn()
              }}
            >
              {currentMessage.mainBtn}
            </Button>
          </div>
        }
        title={currentMessage.title}
        onCancel={() => {
          setShowModal(false)
        }}
      >
        <div
          className={Style.linkUrl}
          dangerouslySetInnerHTML={{
            __html:
              currentMessage.kind !== 'PLAN' ?
                currentMessage.content :
                currentMessage.contentArr.filter(item => item.type === plan)[0]?.content
          }}>
        </div>

        {/* 提示 */}
        {currentMessage.kind === 'PLAN' &&
          isBillStore !== 'yes' &&
          <div className={Style.billTip}>
            <img src={require('../../../assets/oberlo-guid/error.png')} alt="" />
            <div>
              <span
                dangerouslySetInnerHTML={{
                  __html: isBillStore != 'no' ? currentMessage.errorBillStoreNotId : currentMessage.errorBillStore
                }}>
              </span>
              {isBillStore == 'no' && <>
                <a onClick={() => {
                  errorBillName()
                }}>
                  {currentMessage.errorBillName}
                </a>
                <span>.</span>
              </>}

            </div>
          </div>
        }
      </Modal>
      <GuideRefresh visible={showRefresh} />
    </>)
}
export default React.memo(OBPop)