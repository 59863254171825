
    export default 
    {
    "header": {
        "about": "NOSOTROS",
        "support": "SOPORTE",
        "blog": "BLOG",
        "sign_in": "Iniciar sesión",
        "sign_up": "Registrarse"
    }
}