
    export default 
    {
    "setting-supply": {
        "title": "Servicio de Suministro Entrust",
        "content": "Al activar el Servicio de Suministro Entrust, Dsers sustituirá automáticamente al proveedor actualmente asignado de algunos de tus productos para optimizar tus ganancias. Los proveedores sustituidos proporcionarán un mayor suministro estable, precios bajos al por mayor, envíos más rápidos y mucho más, dependiendo del producto. El procedimiento de optimización será realizado por un equipo profesional, según el análisis de big data. <a href=\"https://www.blog.dserspro.com/post/introduction-to-supply-service-entrust\" target=\"_blank\">Más información aquí</a>",
        "activated": "SSE Activado",
        "activate": "Activar",
        "sse_agreement": "Convenio SSE",
        "feedback_title": "¡Has hecho la solicitud con éxito!",
        "feedback_con": "Tu gestor de cuentas asignado se pondrá en contacto contigo <br /> en 1 día laborable",
        "notice_title": "¡El Servicio de Suministro Entrust está en línea!",
        "supply_tab": "Suministro Masivo",
        "modal_title": "Gracias por solicitar el Servicio de Suministro Entrust",
        "modal_intro": "Por favor, ingresa el correo electrónico de contacto. Tu gestor de cuentas asignado se pondrá en contacto contigo en 1 día laborable.",
        "modal_agreement": "Al hacer clic en Enviar, <a href=\"https://www.blog.dserspro.com/post/supply-service-entrust-agreement\" target=\"_blank\">aceptas el convenio SSE</a>"
    }
}