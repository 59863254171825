import React from 'react';
import { connect,useDispatch } from 'dva';

import styles from './SelectTags.less';
import shopify from '../../assets/banner/shopify.png';
import whiteShopify from '../../assets/bargain/shopifyNew.svg';
import woo from '../../assets/banner/woo.png';
// import { goToWoo } from '../../common/sysconfig';
import { skipShopfiy } from '../../utils/utils';
import { Dropdown, Menu } from 'antd';

const SelectTags = ({dsers_oberlo_plan,plan, isSaintsTheme}) => {

  const { type = 0 } = JSON.parse(localStorage.getItem('payment')) || {};

  const menu = (
    <Menu style={{ width: '144px', position: 'relative', top: '-10px' }}>
      <Menu.Item>
        <div style={{cursor: 'pointer'}} onClick={skipShopfiy}>
          <img style={{ width: '100%' }} src={woo} alt="" />
        </div>
      </Menu.Item>
    </Menu>
  );

  const renderContainer = () => {
    return (plan?.type === 3 && JSON.parse(localStorage.getItem('newUserInfo'))?.type === 1 && !JSON.parse(localStorage.getItem('store'))?.is_pro_offline ) || ( !JSON.parse(localStorage.getItem('store'))?.is_pro_offline && JSON.parse(localStorage.getItem('store'))?.woo_stores)
      ? (
        <Dropdown overlay={menu}>
          <div>
            <img src={isSaintsTheme ? whiteShopify : shopify} alt="" />
            <i class="material-icons" style={{color:isSaintsTheme ? 'white' : null}}>arrow_drop_down</i>
          </div>
        </Dropdown>
      )
      : (
        <img src={isSaintsTheme ? whiteShopify : shopify} alt="" />
      )
  }

  return (
    <div className={styles.selectTags} style={{ cursor: plan?.type === 3 ? 'pointer' : 'default'}}>
      {renderContainer()}
    </div>
  )
};
export default connect(({ topBtn,user }) => ({
  dsers_oberlo_plan:topBtn?.fromOberloPlan,
  plan:user?.plan
}))(React.memo(SelectTags));
