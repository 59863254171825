import React, { useState, useEffect } from 'react';
import { connect } from 'dva';

import { Button, Icon, Input } from 'antd';

import { emailRegExp } from '../../../utils/constants';
import styles from './SendEmail.less';

const { TextArea } = Input;

function SendEmail(props) {
  const [disabled, setDisabled] = useState(true);
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const [emailErr, setEmailErr] = useState(true);
  const [msgErr, setMsgErr] = useState(true);
  const { sendLoading, closeEmail, closeMsg, bigUserMsg } = props;

  function handleChangeEmail(e) {
    setEmail(e.target.value);
    if (emailRegExp.test(e.target.value)) {
      setEmailErr(false);
    } else {
      setEmailErr(true);
    }
  }

  function handleChangeMsg(e) {
    const v = e.target.value;
    const { length } = v;

    setMsg(v);
    if (length < 50 || length > 5000) {
      setMsgErr(true);
    } else {
      setMsgErr(false);
    }
  }

  function handleSend() {
    props.dispatch({
      type: 'global/bigUserSendEmail',
      payload: {
        data: {
          email,
          reply_content: msg,
          inbox_noti_content: bigUserMsg
        },
        callback: d => {
          if (d.code === 2010) {
            closeEmail(true);
          }
        }
      }
    });
  }

  useEffect(() => {
    if (!msgErr && !emailErr) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [msgErr, emailErr]);

  return (
    <div className={styles.wrap}>
      <Icon type="close" className={styles.close} onClick={closeMsg} />
      <div className={styles.desc}>
        <div className={styles.emailLine}>
          <Input
            placeholder="Please input correct email"
            onChange={handleChangeEmail}
          />
          {emailErr && email ? (
            <p className={styles.errorTip}>* Please input correct email</p>
          ) : null}
        </div>
        <TextArea
          className={styles.inputContent}
          autoSize={{ minRows: 6, maxRows: 8 }}
          onChange={handleChangeMsg}
          placeholder="Please input your content"
        />
        {msgErr && msg ? (
          <p className={styles.errorTip}>
            {msg.length < 50
              ? '* Please input at least 50 characters'
              : '* Please input no more than 5000 characters'}
          </p>
        ) : null}
      </div>
      <div className={styles.btnLine}>
        <Button className={styles.emailBtn} onClick={() => closeEmail()}>
          Go back
        </Button>
        <Button
          className={styles.msgBtn}
          disabled={disabled}
          onClick={handleSend}
          loading={sendLoading}
        >
          Send
        </Button>
      </div>
    </div>
  );
}

export default connect(({ loading }) => ({
  sendLoading: loading.effects['global/sendEmail']
}))(SendEmail);
