import React, { Component } from 'react';
import { connect } from 'dva';
import { routerRedux, withRouter } from 'dva/router';
import { Modal, Button, Icon, Tooltip, message } from 'antd';

import UpdateStores from 'components/UpdateStores';
import RefreshMask from 'components/RefreshMask';
import EvaluateComp from 'components/EvaluateComp';
import reactGaEvent from '../../utils/GAEvent';
import AEAccountDisconnect from 'components/AEaccountDisconnect';
import styles from './TopBtn.less';

import intl from 'react-intl-universal';
import checkDocumentVisible from '../../utils/checkDocumentVisible';
import WhitelistProcessModal from './WhitelistProcessModal';
import getAeUserId from '../../utils/getAeUserId';
import { CommonDataCollect } from 'utils/utils'
const isAliWhitelistedStyle = {
  width: 210
};

const width163 = {
  width: 163
};

const documentVisibleCapability = checkDocumentVisible();

class TopBtn extends Component {
  activeApplyWhitelistProcess = false; // 是否开始处理加白步骤

  aeUserId = null;

  constructor(props) {
    super(props);
    this.state = {
      showExpirationReminder: false,
      ExpirationReminderTxt: '',
      aliExpressAuthStatus: false,
      BeOverdueTime: 100,
      aliauthLoading: false,
      showUpdateStore: false,
      showRefreshMask: false, // 新标签打开页面，在回来是否打开刷新遮罩
      showReview: false,
      addWhitelistModalVisible: true,
      addWhitelistModalType: undefined
    };

    this.aeUserId = getAeUserId();
  }

  componentDidMount() {
    console.log('TopBtn DidMount');

    if (documentVisibleCapability.hidden) {
      document.addEventListener(
        documentVisibleCapability.visibilityChange,
        this.handleVisibilityChange,
        false
      );
    }

    if (
      this.props.userInfo.status === 0 &&
      this.props.userInfo.email_status !== 0
    ) {
      this.props.dispatch(routerRedux.push('/bind/platform'));
      return;
    }

    if (this.props.onRef) {
      this.props.onRef(this);
    }

    this.getNotificationUnreadLength();
    this.fixStatus(this.props.userInfo.status);

    this.getIsSnyc();

    localStorage.setItem('aliExpressAuthStatus', false);
    localStorage.removeItem('aliBeOverdueTime');

    this.Alisetting();

    const stores = this.props.userInfo.stores || [];
    this.realStoreLength = stores.filter(
      item =>
        item.shop_name !== 'Importlist' && item.shop_name !== 'Imported Orders'
    ).length;

    this.storeData = stores;
    
    if (!localStorage.getItem('storeStatus') && stores.length) {
      this.getShopifyStoreStatus(0, stores);
    }

    this.props.dispatch({
      type:"topBtn/getProductLimitStatus"
    });
  }

  componentDidUpdate(prevProps) {
    const { aeCookie, loopSync, showReviewBanner, userInfo, AEInfo } = this.props;

    if (AEInfo !== prevProps.AEInfo) {
      this.Alisetting();
    }

    if (
      aeCookie &&
      aeCookie !== prevProps.aeCookie &&
      this.activeApplyWhitelistProcess
    ) {
      // 1. 如果没有 user_nick
      if (!this.aeUserId) {
        // eslint-disable-next-line
        this.setState({
          addWhitelistModalVisible: true,
          addWhitelistModalType: 'none'
        });
        this.activeApplyWhitelistProcess = false;
      } else {
        // 2. 有 user_nick
        this.activeApplyWhitelistProcess = false;
        // 3. user_nick 一致
        if (aeCookie.indexOf(this.aeUserId) > -1) {
          this.showRefreshMask();

          // eslint-disable-next-line
          // this.setState({
          //   addWhitelistModalVisible: true,
          //   addWhitelistModalType: 'match'
          // });
        } else {
          // 4. user_nick 不一致
          // eslint-disable-next-line
          this.setState({
            addWhitelistModalVisible: true,
            addWhitelistModalType: 'notMatch'
          });
        }
      }
    }

    if (prevProps.loopSync !== loopSync && loopSync) {
      this.loopSync();
    }

    if (
      this.isRangeIn(userInfo.login_days, 5, 45) &&
      showReviewBanner &&
      showReviewBanner !== prevProps.showReviewBanner &&
      Number((userInfo.status / 10) & 2) === 0
    ) {
      this.setState({
        showReview: true
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener(
      documentVisibleCapability.visibilityChange,
      this.handleVisibilityChange,
      false
    );

    clearInterval(this.syncTimer);
  }

  realStoreLength = 0;
  storeData = [];
  is_flag = false;
  badgeIndex = {
    archive_order: 'Archived',
    notify_unread: 'Notification',
    open_order: 'Open',
    import_list:'importList',
    my_products:'myProduct',
    ae_orders: 'AEOpen',
    tmall_orders: 'TmallOpen',
    agency_orders: 'AgencyOpen',
    unmapping_orders: 'UnmappedOpen'
  };

  handleVisibilityChange = () => {
    const { aeCookie, hasExtension } = this.props;

    if (!document[documentVisibleCapability.hidden]) {
      // aeCookie = null 说明没登录 AE
      if (aeCookie === null && this.activeApplyWhitelistProcess) {
        this.setState({
          addWhitelistModalVisible: true,
          addWhitelistModalType: 'notLogin'
        });
        this.activeApplyWhitelistProcess = false;
      }

      // aeCookie = undefined 或者 hasExtension = false 说明没有 插件
      if (!hasExtension && this.activeApplyWhitelistProcess) {
        this.setState({
          addWhitelistModalVisible: true,
          addWhitelistModalType: 'none'
        });
        this.activeApplyWhitelistProcess = false;
      }
    }
  };

  handleWhitelistModalCancel = () => {
    // 重置
    this.setState({
      addWhitelistModalVisible: false,
      addWhitelistModalType: undefined
    });
  };

  fixStatus = status => {
    if (localStorage.getItem('fixStatus')) {
      return;
    } else {
      localStorage.setItem('fixStatus', true);
    }

    let s = `${status}`;

    if (s.length !== 8) {
      s = '11000000';
    }

    const statusArr = s.split('').map(si => (si >= 1 ? '1' : '0'));

    this.props.dispatch({
      type: 'topBtn/userStatus',
      payload: {
        data: {
          status: Number(statusArr.join(''))
        }
      }
    });
  };

  // 区间范围
  isRangeIn = (str, m, n) => {
    const reg = /(\d+)/g;
    while (reg.exec(str)) {
      const int = parseInt(RegExp.$1);
      if (int < m || int > n) return false;
    }
    return true;
  };

  // 获取Notification未读数量
  getNotificationUnreadLength = () => {
    
    let storeId = localStorage.getItem('currentOrdersStoreId') || 'all'

    this.props.dispatch({
      type: 'topBtn/notificationUnreadLength',
      payload: {
        cancelMessage: true,
        storeId:storeId,
        callback: d => {
          if (![2000, 2010].includes(d.code)) {
            return;
          }
          let totalData = {}

          Object.entries(d.data).forEach(item => {
            const [key, count] = item;
            const menuName = this.badgeIndex[key];
            const dom = document.getElementById(`leftSideMenu${menuName}Badge`);

            localStorage.setItem(`${menuName}Badge`, count);

            totalData[menuName] = count

            if (!dom) {
              return;
            }

            if (count > 0) {
              dom.style.display = 'inline-block';
              dom.innerHTML = count;
            } else {
              dom.style.display = 'none';
              dom.removeAttribute('style');
            }
          });

          this.props.dispatch({
            type:'global/total',
            payload:{
              data:totalData
            }
          })

        }
      }
    });
  };

  // 检测 shopify store 授权状态是否可用
  getShopifyStoreStatus = (index, stores) => {
    this.props.dispatch({
      type: 'topBtn/shopifyStatus',
      payload: {
        data: {
          store_id: stores[index].id
        },
        cancelMessage: true,
        callback: d => {
          this.setStoreUpdate(d, index); // 设置店铺应不应该从新授权
          if (stores.length > index + 1) {
            this.getShopifyStoreStatus(index + 1, stores);
          } else {
            localStorage.setItem('storeStatus', JSON.stringify(this.storeData));
            this.setState({
              showUpdateStore: this.setStoreUpdateModal()
            });
          }
        }
      }
    });
  };

  // 设置店铺应不应该从新授权
  setStoreUpdate = (d, index) => {
    let flag = false;

    if (d.data) {
      if (d.data.is_available != true) {
        flag = true;
      }
    } else if (d.code != 4000) {
      flag = true;
    }

    this.storeData[index].update = flag;
  };

  // 应不应该展示
  setStoreUpdateModal = () => {
    let flag = false;
    const hasCloseUpdateStore = localStorage.getItem('hasCloseUpdateStore');

    this.storeData.forEach(item => {
      if (item.update === true && !hasCloseUpdateStore) {
        flag = true;
      }
    });
    if(localStorage.getItem('noMigra') == 'false'){
        flag = false
      }
    return flag;
  };

  // ae授权状态
  Alisetting = () => {
    const { AEInfo } = this.props;
    if (AEInfo === null) {
      localStorage.setItem('newUserWithoutAe', true);
    }

    if (AEInfo) {
      this.is_flag = true;
      const { expire_time, status, is_white } = AEInfo;
      const dateTime = new Date().getTime(); // 当前时间
      const BeOverdueTime = Number.parseInt(
        (expire_time - dateTime) / (24 * 60 * 60 * 1000)
      ); // 授权还有几天过期
      const lastTime = Number(localStorage.getItem('nowDate')) || 0;
      const localS = dateTime - lastTime; // 现在的时间，减去存储的时间  距离上次弹出过了多少毫秒

      localStorage.setItem('aliAccountId', AEInfo.user_nick);
      localStorage.setItem('aliBeOverdueTime', BeOverdueTime); // 小于等于0绑定AE过期
      this.updateUserInfoIsWhite(is_white);

      if (status == 1) {
        this.setState({
          aliExpressAuthStatus: true,
          BeOverdueTime
        });
        localStorage.setItem('aliExpressAuthStatus', true);
      } else {
        localStorage.removeItem('aliExpressAuthStatus');
      }

      if (
        expire_time != 0 &&
        (!lastTime || (lastTime && localS / (24 * 60 * 60 * 1000) >= 1))
      ) {
        if (BeOverdueTime > 0 && BeOverdueTime <= 5) {
          // 大于零，小于五，即将过期
          this.setState({
            showExpirationReminder: true,
            ExpirationReminderTxt: intl.get('top.bind_ae_expire')
          });
          localStorage.setItem('nowDate', dateTime);
        } else if (BeOverdueTime <= 0) {
          // 等于零，小于零，已经过期
          this.setState({
            showExpirationReminder: true,
            ExpirationReminderTxt: intl.get('top.bind_ae_expire')
          });
          localStorage.setItem('nowDate', dateTime);
        }
      }
      localStorage.removeItem('newUserWithoutAe');
    }
  };

  updateUserInfoIsWhite = isWhite => {
    if (this.props.userInfo.is_white === isWhite) {
      return;
    }
    this.props.dispatch({
      type: 'login/updateUserInfo'
    });
  };

  // 显示添加商店
  showAddModal = () => {
    reactGaEvent({
      category: 'header',
      action: 'Link to Shopify store'
    });

    this.props.dispatch(routerRedux.push('/bind/link-store'));
  };

  setAuthIco = or => {
    this.setState({
      aliauthLoading: or
    });
  };

  // 显示刷新遮罩
  showRefreshMask = () => {
    this.setState({
      showRefreshMask: true
    });
  };

  // 获取AE授权跳转地址
  LinkAliExpress = () => {
    if (localStorage.getItem('aliBeOverdueTime') == null) {
      // 没绑过ae或以解绑
      this.bindAE();
    } else {
      // 过期 先解绑在绑定
      this.unBindAE();
    }

    this.setAuthIco(true);
    
    //针对Oberlo用户进行打点
    if (sessionStorage.getItem('noMigra') == false) {
      CommonDataCollect({ 
        action: 'migration_bind_ae',
        scene:0
      })
    }
    reactGaEvent({
      category: 'header',
      action: 'Link to AliExpress'
    });
  };

  // 获取绑定ae链接
  bindAE = () => {
    const windowRef = window.open('', '_blank');
    this.props.dispatch({
      type: 'topBtn/aliauth',
      payload: {
        cancelMessage: true,
        callback: d => {
          this.setAuthIco(false);
          if (d.code === 2000) {
            this.showRefreshMask();
            windowRef.location = d.data.url;
          }else if(d.code === 4090){
            window.location = '/app/';
          } else {
            windowRef.close();
            message.error(d.msg);
          }
        }
      }
    });
  };

  // 解绑ae
  unBindAE = () => {
    this.props.dispatch({
      type: 'topBtn/unbind',
      payload: {
        cancelMessage: true,
        callback: d => {
          if (d.code == 2010 || d.code == 2000) {
            this.bindAE();
          }
        }
      }
    });
  };

  syncTimer = null;

  // 获取是否正在同步订单
  getIsSnyc = () => {
    clearInterval(this.syncTimer);
    const storeId = localStorage.getItem('syncStoreId'); // storeId 其实是后台创建同步任务的id
    if (!storeId || !Number(storeId)) {
      localStorage.removeItem('syncStoreId');
      return;
    }
    this.props.dispatch({
      type: 'topBtn/syncTask',
      payload: {
        data: {
          id: storeId || ''
        },
        cancelMessage: true,
        callback: d => {
          if (d.data > 1) {
            clearInterval(this.syncTimer);
            localStorage.removeItem('syncStoreId');
            this.props.setIsSync(0);
          } else {
            this.loopSync();
            this.props.setIsSync(1);
          }
        }
      }
    });
  };

  // 获取是否正在同步订单 6秒查一次
  loopSync = () => {
    if (this.syncTimer) {
      clearInterval(this.syncTimer);
    }

    this.syncTimer = setInterval(this.getIsSnyc, 6000);
  };

  // 隐藏过期提醒
  hideExpirationReminder = () => {
    this.setState({
      showExpirationReminder: false
    });
  };

  // 关闭通用弹窗banner
  closeGeneralBanner = () => {
    localStorage.setItem('generalBanner', 1);
    this.forceUpdate();
  };

  render() {
    const {
      showExpirationReminder,
      ExpirationReminderTxt,
      aliExpressAuthStatus,
      BeOverdueTime, // ae授权状态还有几天过期 负数已过期
      aliauthLoading,
      showUpdateStore,
      showRefreshMask,
      showReview,
      addWhitelistModalVisible,
      addWhitelistModalType
    } = this.state;

    const {
      location: { pathname }
    } = this.props;

    return (
      <div className={styles.topBtn}>
        {/* <div style={{ overflow: 'hidden' }}>
          {!aliExpressAuthStatus || BeOverdueTime <= 0 ? (
            <Tooltip
              placement="bottom"
              title={intl.get('top.bind_ali_tip')}
              overlayClassName={styles.Txt}
            >
              <Button
                className={styles.linkAliBtn}
                onClick={this.LinkAliExpress}
              >
                {aliauthLoading ? (
                  <Icon type="loading" />
                ) : (
                  <Icon type="plus-circle" theme="filled" />
                )}
                &nbsp;&nbsp;{intl.get('top.link_to_aliExpress')}
              </Button>
            </Tooltip>
          ) : (
            <div>
              {this.props.userInfo.is_white ? (
                <Tooltip
                  placement="bottom"
                  title={intl.get('top.over_bind_tip')}
                  overlayClassName={styles.Txt}
                >
                  <Button
                    className={styles.linkAliBtn}
                    style={isAliWhitelistedStyle}
                  >
                    <Icon
                      type="check-circle"
                      theme="filled"
                      className={styles.isAliWhitelistedIco}
                    />
                    &nbsp;&nbsp;{intl.get('top.over_bind')}
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  className={styles.linkAliBtn}
                  style={isAliWhitelistedStyle}
                >
                  <Icon
                    type="check-circle"
                    theme="filled"
                    className={styles.isAliWhitelistedIco}
                  />
                  &nbsp;&nbsp;
                  {intl.get('top.ae_account_linked')}
                </Button>
              )}
            </div>
          )}
        </div> */}
        {/* <WhitelistProcessModal
          visible={addWhitelistModalVisible}
          type={addWhitelistModalType}
          onCancel={this.handleWhitelistModalCancel}
        />
        <Tooltip
          placement="bottom"
          title={intl.get('top.link_shopify_tip')}
          overlayClassName={styles.Txt}
        >
          <Button
            className={styles.linkAliBtn}
            style={width163}
            onClick={this.showAddModal}
          >
            <Icon type="plus-circle" theme="filled" />
            &nbsp;&nbsp;
            {this.realStoreLength > 0
              ? intl.get('top.link_to_more_stores')
              : intl.get('top.link_to_shopify_store')}
          </Button>
        </Tooltip> */}

        {/** 绑定阿里白名单过期提醒 */}
        {showExpirationReminder ? (
          <Modal
            visible={showExpirationReminder}
            onCancel={this.hideExpirationReminder}
            className={styles.showExpirationReminder}
            width={500}
            title={ intl.get('top.bind_ae_title')}
            footer={
            <Button
              type="primary"
              onClick={() => {
                this.LinkAliExpress();
                this.hideExpirationReminder();
              }}
            >
              {intl.get('top.bind_ae_title')}
            </Button>}
            centered
          >
            <div className={styles.ExpirationReminderBox}>
              { intl.getHTML('top.bind_ae_content')}
              {/* <h3>{ intl.get('top.bind_ae_expire')}</h3> */}
              {/* {intl.getHTML('top.contact_by_email')} */}
            </div>
          </Modal>
        ) : null}

        {showUpdateStore ? (
          <UpdateStores showUpdateStore={showUpdateStore} />
        ) : null}

        <RefreshMask visible={showRefreshMask} />

        {/* {!aliExpressAuthStatus &&
        !!this.is_flag &&
        (pathname === '/storemanage/myproduct' ||
          pathname === '/order/list') ? (
          <AEAccountDisconnect />
        ) : null} */}

        {showReview && pathname === '/order/list' ? <EvaluateComp /> : null}
      </div>
    );
  }
}

const mapStateToProps = ({ loading, login, global }) => ({
  userInfo: login.userInfo,
  loading: loading.models.topBtn,
  aeCookie: global.aeCookie,
  hasExtension: global.hasExtension,
  AEInfo: global.AEInfo
});

export default withRouter(connect(mapStateToProps)(TopBtn));
