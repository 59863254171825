
    export default 
    {
    "support": {
        "title": "Supporto",
        "description": "Benvenuto nel supporto DSers. Siamo qui per aiutarti a sostenere il tuo business di consegna diretta con l'anima e il cuore.",
        "email": "EMAIL",
        "email_dec": "Mandaci una email con le tue domande o con i tuoi pensieri! <br/> Ti risponderemo il prima possibile.",
        "email_btn": "Supporto via email",
        "live_chat": "CHAT DAL VIVO",
        "live_chat_dec": "Mandaci un messaggio per risolvere i tuoi problemi. Siamo qui <br/> pronti a iniziare una chat dal vivo.",
        "live_chat_btn": "Mandaci un messaggio",
        "facebook": "FACEBOOK",
        "facebook_dec": "Segui la nostra pagina Facebook per ottenere le ultime informazioni su DSers e sulle consegne dirette.",
        "facebook_btn": "Facebook",
        "next_level": "Porta i tuoi affari al prossimo livello",
        "try_now": "Prova ora senza alcun rischio.",
        "get_started": "Inizia",
        "partnership": "Collaborazione"
    }
}