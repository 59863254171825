import React from 'react';
import { Divider } from 'antd';

import styles from './SessionOfProduct.less';

import Point from './Point';
import BotMessage from './BotMessage';
import UserMessage from './UserMessage';
import Loading from './Loading';

function SessionOfProduct({ status, onWatchVideo, onGotoTest }) {
  return (
    <>
      <div className={styles.rightChat}>
        <div className={styles.chatCon}>
          {status === 'greeting' ? (
            <>
              <BotMessage showAvatar>
                Congrats! The test product is now in your store and you can
                check it in the <b>My product</b> menu.
              </BotMessage>
              <BotMessage>
                <p>
                  Finally, time to learn to{' '}
                  <b>how to process orders via DSers to AliExpress.</b>
                </p>
                <div>
                  Do you mind if we <b>create a test order</b> using this
                  product in your store?
                </div>
              </BotMessage>
              <UserMessage showAvatar>
                <p>
                  <span onClick={onGotoTest} role="button" tabIndex="-1">
                    Go ahead. I want to test
                  </span>
                </p>
                <Divider style={{ margin: '12px 0' }} />
                <div>
                  <span
                    onClick={() => onWatchVideo('NneJLfXtalU')}
                    role="button"
                    tabIndex="-1"
                    data-category="tutorial"
                    data-action="watching a related video"
                  >
                    I prefer watching a related video
                  </span>
                </div>
                <Point
                  showPoint={false}
                  style={{ bottom: 20, right: '30%', position: 'absolute' }}
                />
              </UserMessage>
            </>
          ) : null}
        </div>
      </div>
      {/* 居中布局 */}
      <div
        className={styles.centerChat}
        style={{ top: status === 'confirmSet' ? 450 : 80 }}
      >
        {status === 'createLoading' ? (
          <>
            <BotMessage showAvatar>
              <p>
                We are now{' '}
                <b>creating an order with a fake contact and address</b> in your
                Shopify store.
              </p>
              <div>
                New orders will appear in the <b>Awaiting order tab</b> on DSers
                automatically and you need to process them to AliExpress from
                there.
              </div>
            </BotMessage>
            <div className={styles.loading}>
              <Loading />
            </div>
          </>
        ) : null}
        {status === 'createFail' ? (
          <>
            <BotMessage showAvatar>
              Oops
              <span role="img" aria-label="icon">
                😱
              </span>
              , it seems that there was an unexpected issue and{' '}
              <i>we failed to create a test order in your Shopify store</i> …
            </BotMessage>
            <UserMessage showAvatar>
              <div>
                <span
                  onClick={() => onWatchVideo('NneJLfXtalU')}
                  role="button"
                  tabIndex="-1"
                >
                  It’s OK. I would like to watch a related video
                </span>
              </div>
              <Point
                showPoint={false}
                style={{ bottom: 20, right: '30%', position: 'absolute' }}
              />
            </UserMessage>
          </>
        ) : null}
      </div>
    </>
  );
}

export default SessionOfProduct;
