import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'antd';

import styles from './BrazilBanner.less';
import useTopBanner from '../../hooks/useTopBanner';
import bg from '../../assets/banner/brazil.png';
import { brazilBannerList } from '../../utils/bannerWhiteList';

const allowPath = [
  '/home',
  '/report',
  '/order/list',
  '/storemanage/import',
  '/storemanage/myproduct'
];

const BrazilBanner = ({ path, userId, dispatch }) => {
  const [visible] = useTopBanner(path, allowPath);
  const isBrazilBannerUser = brazilBannerList.includes(userId);

  if (!visible || !isBrazilBannerUser) {
    return null;
  }

  const showNotice = () => {
    // 显示开屏弹窗
    dispatch({
      type: 'global/showComment'
    });
  };

  return (
    <div className={`${styles.container}`}>
      <div className={styles.banner}>
        <div className={styles.content}>
          <div className={styles.text1}>
            Brazil! Save up to 40% shipping cost!
          </div>
          <div className={styles.text2}>
            New shipping method comes online, promotion in two months!
          </div>
          <Button
            type="primary"
            className={styles.learnButton}
            onClick={showNotice}
          >
            CHECK
          </Button>
        </div>
        <div className={styles.bannerBg} onClick={showNotice}>
          <img src={bg} className={styles.bg1} />
        </div>
      </div>
    </div>
  );
};

BrazilBanner.allowPath = allowPath;

export default BrazilBanner;
