
    export default 
    {
    "home": {
        "welcome": "Benvenuto su DSers！",
        "orders_to_ae": "ordini da effettuare su AliExpress",
        "order_to_ae": "ordine da effettuare su AliExpress",
        "move": "Procedi con calma:",
        "close": "Chiudi",
        "open": "Apri",
        "learn_more": "Ulteriori Informazioni",
        "tab_title": {
            "add_shopify_store": "Aggiungi negozio Shopify",
            "aliExpress_account": "Account AliExpress",
            "white_list": "Whitelist",
            "setting_shipping": "Impostazione Spedizione",
            "mapping_product": "Mappatura Prodotto",
            "chrome_extension": "Estensione Chrome",
            "bulk_order_function": "Funzione Ordine in blocco"
        },
        "add_shopify_store": {
            "title": "Collegamento al tuo negozio Shopify:",
            "p1": "Importa i prodotti nel negozio Shopify velocemente",
            "p2": "Sincronizza gli ordini degli ultimi 7 giorni da Shopify",
            "p3": "Vai a",
            "p4": "Impostazioni>Altro>Sincronizza ordini Shopify",
            "p5": "per sincronizzare altri ordini precedenti",
            "btn": "Collega gratuitamente"
        },
        "aliExpress_account": {
            "title": "Collegati all'account AliExpress per:",
            "p1": "Effettuare più ordini automaticamente con un solo clic",
            "p2": "Migliorare l'efficienza del 97%.",
            "p3": "Tracciare le informazioni di spedizione",
            "p4": "Assicurarti che il tuo account AliExpress si colleghi come indicato di seguito:",
            "btn": "Link"
        },
        "white_list": {
            "title": "Richiedi un account AliExpress whitelist per:",
            "p1": "Effettua un ordine utilizzando l'API ufficiale di AliExpress",
            "p2": "Sblocca la limitazione dell'ordine e del pagamento",
            "p3": "Ottieni un servizio speciale di drop shipping da AliExpress",
            "btn": "Applica"
        },
        "chrome_extension": {
            "title": "Scarica l'estensione <br/> DSers Chrome per:",
            "p1": "Migrazione con un click da Oberlo",
            "p2": "Importa prodotti da AliExpress",
            "p3": "Pagamenti in blocco su AliExpress",
            "btn": "Scarica"
        },
        "more_about": "Maggiori informazioni su DSers",
        "video_title_mapping": "Come si mappa il prodotto AliExpress prima di un ordine multiplo?",
        "video_title_place_order": "Come si effettuano 100+ ordini in pochi minuti?",
        "other_features": "Una rapida panoramica delle altre caratteristiche",
        "video_title_add_store": "Come si aggiunge un negozio Shopify?",
        "video_title_bind_ae": "Come si collega e aggiorna l'account AliExpress?",
        "video_title_import_products": "Come si importano i prodotti nel negozio Shopify?",
        "video_title_fulfill_manually": "Come si evadono gli ordini manualmente?",
        "bottom_line": "Abbiamo tutto quello che ti serve",
        "home_title": "Benvenuto su DSers, l'applicazione ufficiale di AliExpress",
        "setup_account": "Imposta il tuo account DSers",
        "tutorial": "Tutorial",
        "product_importer": "Importatore Prodotti",
        "chrome_extension_title": "Estensione Chrome",
        "report": "Segnala",
        "check_report": "Controlla le tue vendite, le spese e il ROI del prodotto",
        "feedback": "Feedback",
        "feedback_share": "Condividi il tuo feedback",
        "feedback_useful": "Questa informazione è molto utile",
        "feedback_not_useful": "Questa informazione non è utile",
        "feedback_what_to_know": "Facci sapere cosa vorresti sapere",
        "comments": "Commenti",
        "share_feedback": "Condividi feedback",
        "new": "Nuovo",
        "dismiss": "Elimina",
        "home_normal_card_learn_more_link": "https://www.blog.dserspro.com/",
        "tutorial2": "Tutorial",
        "modal_title": {
            "title": "New UI & New Beginning",
            "wenhou": "Dear DSers users,",
            "container_one": "First of all, thank you for your ongoing support to DSers. In order to provide users with a better experience, we specially worked with a professional design team to upgrade the UI of our DSers platform.",
            "container_two": "\n      After six months of preparation, the new version of UI has been officially launched today, you can now start your new UI experience journey by clicking the button below.",
            "container_three": "\n      Of course, we will keep the access to the old version of UI for you in case that you have any doubts or other considerations. After entering the page, you are free to switch between these two versions at any time as well.\n       Both version will remain accessible for a certain period of time, depending on the user's feedback. We will notify you in advance of any further changes.",
            "container_four": "\n      Thank you again for your constant support to DSers, which will be the motivation for us to keep optimizing and become a better DSers platform.\n      ",
            "go_new_btn": "START EXPERIENCING THE NEW UI",
            "use_old": "Continue using legacy UI"
        },
        "go_modify": "Go modify",
        "vat_error": "Dear user, the system has detected that the VAT ID you currently set contains non-numeric characters, which will cause your French order to fail (please note that the VAT ID you set is for the French order, other countries currently don't require it). To process the order smoothly, please go to Setting - Other - VAT to modify or clear it",
        "var_update": "If you do not modify it, we will automatically delete the VAT ID on the French orders",
        "modify_btn": "Modify",
        "var_update_setting": "The current VAT ID for French orders has been discontinued. To process the order smoothly, please modify it in time",
        "change_UI": "Change to old UI",
        "oldUI_out": {
            "notion_title": "New UI Notification",
            "notion_con": "Access to the old UI will not be possible after <br /> October 15th",
            "title": "Brand new UI,<br /> brand new experience",
            "modal_text": "After nearly a month of user feedback and technical adjustments, the current new version of the UI has been improved. To unify the platform features, \n      we will remove the access to the old UI on October 15th. Please be aware in advance. If you have any questions, feel free to <a style=\"color:blue\" href='https://www.messenger.com/t/DSersOfficial' dd=\"src\">contact our customer service</a> team."
        },
        "downloadguide": {
            "guide": "Get the guide",
            "text": "Click to download the<br /> dropshipping guide"
        },
        "guidance": {
            "steps": "passo",
            "btn_complete": "COMPLETATO",
            "title": "Scopri DSers",
            "desc": "4 passaggi per automatizzare il tuo business di dropshipping",
            "add_product": "AGGIUNGI PRODOTTO",
            "push_to_shopify": "SPOSTA SU SHOPIFY",
            "suppiler_mapping": "MAPPATURA RIVENDITORE",
            "place_order": "ESEGUI ORDINE",
            "add_product_title": "Aggiungi il tuo primo prodotto",
            "push_to_shopify_title": "Sponsorizza i prodotti sul tuo store Shopify",
            "suppiler_mapping_title": "Mappatura: Imposta i rivenditori dei prodotti",
            "place_order_title": "Elabora il tuo primo ordine",
            "add_product_desc": "Aggiungi i prodotti che vuoi vendere nel tuo negozio. In Trova rivenditore puoi cercare i prodotti offerti da AliExpress o DSers. Clicca <a href=\"https://help.dsers.com/import-product-from-aliexpress-for-shopify/\" target=\"_blank\" >qui per</a> maggiori dettagli.",
            "push_to_shopify_desc": "Dopo aver aggiunto i prodotti su DSers, modificali e inviali al tuo negozio Shopify.Clicca <a href=\"https://help.dsers.com/push-a-product-to-your-shopify-store/\" target=\"_blank\" >qui per</a> maggiori dettagli.",
            "suppiler_mapping_desc": "Imposta i rivenditori AliExpress dei tuoi prodotti per elaborare più facilmente i prossimi ordini. Clicca <a href=\"https://help.dsers.com/basic-mapping-advanced-mapping-bogo-bundle-introduction-for-shopify/\" target=\"_blank\" >qui per</a> maggiori dettagli.",
            "place_order_desc": "Puoi eseguire velocemente gli ordini di massa, e DSers gestirà automaticamente gli ordini dopo che l'ordine è stato eseguito con successo. Clicca <a href=\"https://help.dsers.com/fulfill-an-order-from-start-to-finish-for-shopify/\" target=\"_blank\" >qui per</a> maggiori dettagli.",
            "tip_to_pc": "To ensure a better user experience, log in using a computer to operate DSers with the right conditions"
        },
        "oberlo": {
            "card_title_1": "Ci siamo quasi",
            "card_title_des_1": "Prima di avviare gli ordini e utilizzare le impostazioni avanzate, completa queste configurazioni.",
            "card_title_2": "Tutorial",
            "card_title_des_2": "Controlla questa guida rapida per aiutarti nel processo di elaborazione degli ordini e di importazione dei prodotti",
            "step_title_1": "Collega il tuo account AliExpress",
            "step_title_2": "Attiva l'iscrizione DSers",
            "step_title_3": "Installa l'estensione di Chrome di DSers",
            "step_des_1": "Collega subito il tuo account AliExpress per aggiornare gli stati degli ordini! Clicca <a href=\"https://help.dsers.com/link-to-aliexpress/\" target=\"_blank\">qui</a> per maggiori informazioni.",
            "step_des_2": "Usufruisci delle funzioni di Oberlo con nuove possibilità avanzate di DSers. Clicca <a href=\"https://help.dsers.com/oberlo-migration-compare-plans/\" target=\"_blank\">qui</a> per maggiori informazioni.",
            "step_des_3": "Importo prodotto con un solo clic da AliExpress a DSers per risparmiare tempo e trovare i nuovi migliori rivenditori! Clicca  <a href=\"https://help.dsers.com/import-product-from-aliexpress-for-shopify/\" target=\"_blank\">qui</a> per maggiori informazioni.",
            "obPlan": "Attiva il piano DSers <i>{ dsers }</i> per godere delle attuali funzioni nel piano Oberlo <i>{oberlo}</i>.",
            "btntext1": "Collega AliExpress",
            "btntext2": "Attiva il piano",
            "btntext3": "Lo farò più tardi",
            "btntext4": "Scarica",
            "btntext5": "Scopri di più",
            "billing_error": "Non puoi attivare il piano perché il tuo negozio di fatturazione non è disponibile. Cambia il negozio di fatturazione su DSers, nella pagina della <a href=\"/app/select\">Gestione abbonamento</a>, oppure attiva il tuo negozio di fatturazione nell’",
            "billing_error1": "Non puoi attivare il piano perché il tuo negozio di fatturazione non è disponibile. Cambia il negozio di fatturazione su DSers, nella pagina della <a href=\"/app/select\">Gestione abbonamento</a>,",
            "billing_error_link": "amministrazione di Shopify.",
            "stepsdes": "Passaggio/i rimasto/i",
            "finish": "Finito",
            "year_error": "Se attivi un nuovo piano, ma hai già pagato la spesa annuale per il piano Standard di DSers, contatta il servizio clienti per ottenere il rimborso parziale.",
            "step_title_4": "Migrare i dati Oberlo",
            "step_des_4": "Collega il tuo account Oberlo e sincronizza i dati in DSers..",
            "stopProduct": "I dati prodotto non sono stati aggiornati correttamente, puoi cliccare su \"Riprova\" o contattare il <a href='https://www.messenger.com/t/DSersOfficial' target=\"_blank\">team di assistenza Dsers</a>",
            "stopOrder": "Lo stato dell'ordine non è stato aggiornato correttamente, puoi cliccare su \"Riprova\" o contattare il <a href='https://www.messenger.com/t/DSersOfficial' target=\"_blank\">team assistenza di DSers</a>",
            "successProduct": "Aggiornamento dati prodotto completato.",
            "successOrder": "Aggiornamento dello stato dell'ordine completato, vai alla pagine <a href='/app/order/list'>Ordini aperti</a> per i dettagli",
            "syncProduct": "Stiamo aggiornando ancora i dati prodotto da Shopify…",
            "syncOrder": "Stiamo aggiornando ancora lo stato dell'ordine per AliExpress. Ordini migrati di DSers degli ultimi due mesi. Se hai bisogno di sincronizzare più ordini, <a href='https://www.messenger.com/t/DSersOfficial' target=\"_blank\">contattaci</a>.",
            "migrated": "MIGRATED",
            "linked": "LINKED"
        },
        "testA": {
            "haha": "<a <a target=\"_blank\" href=\"http://www.baidu.com\">ceshia</a>"
        },
        "ShopifyOrders": "gli ordini di Shopify",
        newGuide:{
            primary_title:`Guida al Dropshipping`,
            primary_descriptions:`Ti mostreremo i contenuti più adeguati in base alla selezione.`,
            second_title_1:`Nessun prodotto, nessun ordine`,
            second_description_1:`Sono nuovo e non so come usare DSers per iniziare il mio business di dropshipping.`,
            second_title_2:`Ho dei prodotti, ma non ho ordini`,
            second_description_2:`Ho già degli articoli su Shopify, ma non so come iniziare il mio business su DSers.`,
            second_title_3:`Ho dei prodotti e ho degli ordini`,
            second_description_3:`Ho usato delle soluzioni come DSers, ma mi serve sapere come usare le sue funzioni principali.`,
            second_more_1_title_1:`What Is Dropshipping?`,
            second_more_1_title_2:`How Can You Start a Dropshipping Store with DSers?`,
            second_more_1_title_3:`Come importare i prodotti in Trova rivenditore?`,
            second_more_1_title_4:`Come importare i prodotti nella Lista import?`,
            second_more_1_title_5:`Come inviare i prodotti su Shopify dopo averli importati?`,
            second_more_2_title_1:`Ultimate Guide to 15 Evergreen Products to Dropship in 2023`,
            second_more_2_title_2:`Top 10 Trending Dropshipping Products for 2023`,
            second_more_2_title_3:`Come importante gli articoli da Shopify su Dsers?`,
            second_more_2_title_4:`Come trovare il giusto rivenditore per un dato prodotto?`,
            second_more_3_title_1:`How to Set a Proper and Lucrative Dropshipping Profit Margin?`,
            second_more_3_title_2:`A Step-by-Step Guide to Deal with Your First Dropshipping Order`,
            second_more_3_title_3:`Come inserire un ordine su DSers?`,
            second_more_3_title_4:`How to set up the shipping template?`,
            Expand:`Espandi`,
            Leave:`COLLAPSE`,
            more_videos:'altri video'
          }
    }
}