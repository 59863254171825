import React from 'react';
import styles from './RutBanner.less';
import intl from 'react-intl-universal';

import { useDispatch } from 'react-redux';
import left from 'assets/supply/fly.png';
import right from 'assets/supply/baxi.png';


const allowPath = [
    '/order/list',
    '/storemanage/myproduct'
];


const RutBanner = ({ agencyUserMap, shipMethod, userId}) => {

    return (
        <>
            {
                agencyUserMap.includes(userId) ?
                    <a target='_blank' href='https://www.dsers.com/blog/german-new-packaging-law/ ' className={styles.banner}>
                        <div className={styles.title}>Aliexpress Links to Germany invalid?<br /> Don't worry!</div>
                        <div className={styles.button}><span>View details</span><span>{'>>'}</span></div>
                    </a>
                    :
                    <a target='_blank' href='https://www.dsers.com/blog/ds-vip-line-brazilian/' className={styles.bannerTwo}>
                        <img src={left} alt="" />
                        <img src={right} alt="" className={styles.right}/>
                        <div className={styles.title}>DS VIP LINE(Brazil) Launched</div>
                        <div className={styles.button}><span>VIEW DETAILS</span><span>{'>>'}</span></div>
                    </a>
            }
        </>
    )
}

RutBanner.allowPath = allowPath;

export default RutBanner;