import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Button } from 'antd';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';

import reactGaEvent from 'utils/GAEvent';
import { CommonDataCollect } from 'utils/utils';

import banner from '../../../assets/payInto/banner.png';

import { countDown } from '../../../utils/countDown';

import headPortrait from '../../../assets/payInto/headPortrait.png';
import money from '../../../assets/payInto/money.png';
import shopping from '../../../assets/payInto/shopping.png';
import flame from '../../../assets/payInto/flame.png';
import heart from '../../../assets/payInto/heart.png';
import soon from '../../../assets/payInto/soon.png';
import house from '../../../assets/payInto/house.png';
import aircraft from '../../../assets/payInto/aircraft.png';
import box from '../../../assets/payInto/box.png';
import mailbox from '../../../assets/payInto/mailbox.png';

import smallBell from '../../../assets/payInto/smallBell.png';
import funnel from '../../../assets/payInto/funnel.png';
import bulb from '../../../assets/payInto/bulb.png';
import exclamationMark from '../../../assets/payInto/exclamationMark.png';

import eResize from '../../../assets/payInto/e-resize.svg';
import nResize from '../../../assets/payInto/n-resize.svg';
import explode from '../../../assets/payInto/explode.svg';
import computer from '../../../assets/payInto/computer.svg';

import styles from './index.less';
import { Get } from 'utils/request';

const modalRoot = document.getElementById('root');

function PayInto({ dispatch }) {
  localStorage.removeItem('PayIntoDetailsSeven');

  const payInto = localStorage.getItem('PayIntoDetailsEight');

  const [startTime, setStartTime] = useState(Math.round(new Date() / 1000));
  const [endTime, setEndTime] = useState(1663570800);

  const [details, setDetails] = useState(!!payInto);

  async function getTimeline() {
    try {
      const result = await Get('/charge/activity_timeline');
      if (result.code === 2000) {
        setEndTime(result.data.end_at);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getTimeline();
  }, []);

  function closeDetails() {
    localStorage.setItem('PayIntoDetailsEight', true);
    setDetails(true);
  }

  function showDetails() {
    CommonDataCollect({
      event_type: 'subscription_launch',
      action: 'click_show_details_CAM007_button'
    });
    reactGaEvent({
      category: 'PayInto',
      action: 'click_show_details_CAM007_button'
    });
    localStorage.removeItem('PayIntoDetailsEight');
    setDetails(false);
  }

  function gotoSelect() {
    CommonDataCollect({
      event_type: 'subscription_launch',
      action: 'click_upgrade_CAM007_button'
    });
    reactGaEvent({
      category: 'PayInto',
      action: 'click_upgrade_CAM007_button'
    });

    dispatch(routerRedux.push('/pricing?select=1'));
  }

  function showBlog() {
    CommonDataCollect({
      event_type: 'subscription_launch',
      action: 'click_show_blog_CAM007_button'
    });
    reactGaEvent({
      category: 'PayInto',
      action: 'click_show_blog_CAM007_button'
    });

    dispatch(routerRedux.push('/pricing?select=1'));
  }

  if (details) {
    return (
      <div className={styles.container}>
        <div className={styles.text}>
          <a href="javascript:void(0)" onClick={showBlog}>
            Get more value for less cost.
          </a>
        </div>
        <div className={styles.operation}>
          <div className={styles.button} onClick={gotoSelect}>
            Try Now
          </div>
          <i class="material-icons notranslate" onClick={showDetails}>
            keyboard_arrow_up
          </i>
        </div>
      </div>
    );
  }

  return ReactDOM.createPortal(
    <div className={styles.mask}>
      <div className={styles.modal}>
        <div className={styles.header}>
          <div className={styles.left}>
            <div className={styles.headPortrait}>
              <img src={headPortrait} />
            </div>
            <div className={styles.text}>
              <div className={styles.title}>Merchant Growth Team</div>
              <div className={styles.subTitle}>
                DSers empowers your dropshipping journey
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <i className="material-icons notranslate" onClick={closeDetails}>
              keyboard_arrow_down
            </i>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.location}>
            <div className={styles.img}>
              <img src={smallBell} />
            </div>
            <p>Get 10% off for Paid Plans.</p>
          </div>

          <div className={styles.location}>
            <div className={styles.img}>
              <img src={funnel} />
            </div>
            <p>Only apply for subscriptions till 19th, Sep.</p>
          </div>

          <div className={styles.location}>
            <div className={styles.img}>
              <img src={bulb} />
            </div>
            <p>Enjoy discounted monthly payment for 12 months.</p>
          </div>

          <div className={styles.location}>
            <div className={styles.img}>
              <img src={exclamationMark} />
            </div>
            <p>It&apos;s NOW or NEVER.</p>
          </div>

          <p>Prepare for Q4.</p>
        </div>

        <div className={styles.footer}>
          <div className={styles.image}>
            <img src={banner} />
            <div className={styles.advertisingOffline}>
              <div id="advertisingOfflineDays">07</div>
              <div id="advertisingOfflineHour">00</div>
              <div id="advertisingOfflineMinute">00</div>
              <div id="advertisingOfflineSecond">00</div>
            </div>
          </div>

          <div className={styles.hint}>
            (10% of the discount will be refunded to your account in the next
            billing cycle)
          </div>

          {countDown(startTime, endTime)}

          <div className={styles.option}>
            <div className={styles.text}>
              <a href="javascript:void(0)" onClick={showBlog}>
                Get more value for less cost.
              </a>
            </div>
            <div className={styles.button} onClick={gotoSelect}>
              Try Now
            </div>
          </div>
        </div>
      </div>
    </div>,
    modalRoot
  );
}

const CAM007 = connect()(PayInto);

export {
  CAM007
}
