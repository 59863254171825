import React, { Suspense } from 'react';
import { Spin } from 'antd';

// react-router-dom 配合 React.lazy 会有问题，以下能正常工作
export default function (Component) {
  return props => (
    <Suspense fallback={<Spin size="large" style={{ width: '100%', margin: '180px auto' }} />}>
      <Component {...props} />
    </Suspense>
  );
}
