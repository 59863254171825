import whyDisconnect from './pt/whyDisconnect';
import bargain from './pt/bargain';
import reward from './pt/reward';
import settingSupply from './pt/setting-supply';
import common from './pt/common';
import chore from './pt/chore';
import publicIntl from './pt/public';
import home from './pt/home';
import products from './pt/products';
import productEdit from './pt/productEdit';
import mapping from './pt/mapping';
import order from './pt/order';
import csv from './pt/csv';
import notification from './pt/notification';
import setting from './pt/setting';
import top from './pt/top';
import linkStore from './pt/linkStore';
import pricing from './pt/pricing';
import banner from './pt/banner';
import about from './pt/about';
import support from './pt/support';
import guidance from './pt/guidance';
import login from './pt/login';
import register from './pt/register';
import fulfillmentCenter from './pt/fulfillmentCenter';
import importList from './pt/importList';
import paypal from './pt/paypal';
import helpCenter from './pt/helpCenter';
import bannerV2 from './pt/bannerV2';
import supplierOptimizer from './pt/supplierOptimizer';
import partner from './pt/partner';
import tracking from './pt/tracking';
import guide from './pt/guide';
import migration from './pt/migration';
import tmallTestExpress from './pt/tmallTestExpress';
import beforeMigration from './pt/beforeMigration';

export default {
  ...beforeMigration,
  ...publicIntl,
  ...home,
  ...products,
  ...productEdit,
  ...mapping,
  ...order,
  ...csv,
  ...notification,
  ...setting,
  ...top,
  ...linkStore,
  ...pricing,
  ...banner,
  ...about,
  ...support,
  ...guidance,
  ...login,
  ...register,
  ...fulfillmentCenter,
  ...importList,
  ...whyDisconnect,
  ...bargain,
  ...reward,
  ...chore,
  ...paypal,
  ...helpCenter,
  ...bannerV2,
  ...common,
  ...settingSupply,
  ...supplierOptimizer,
  ...partner,
  ...tracking,
  ...guide,
  ...migration,
  ...tmallTestExpress,
  rapport: {
    report: 'Sales Report',
    link: 'You do not have a store linked to DSers yet. Please',
    more: 'link a store.'
  },
  suppliers: {
    categary: 'All Categories',
    copyLink: 'Copy link',
    importBtn: 'Add to Import List',
    importBtnEd: 'Imported',
    optimizer: 'Supplier Optimizer'
  },
  impotList: {
    title: 'Import List'
  },
  combo: {
    headline: 'Gerenciar Assinatura',
    explain:
      'Gerencie sua assinatura e configure aqui uma loja de cobrança padrão. Clique <a href="https://help.dsers.com/change-subscription-plan-for-shopify/" target="_blank" track-type="blog1" track-name="subscription" style=\'color:#3D5AFE\'> aqui </a> para verificar como gerenciar a assinatura. E se você quiser saber mais sobre o plano do DSers, clique <a href="https://help.dsers.com/subscription-plans-presentation-for-shopify/" target="_blank" track-type="blog2" track-name="subscription" style=\'color:#3D5AFE\'> aqui </a> .',
    change: 'Mudar plano',
    changEcard: 'Plano atual:',
    changEcardBu: 'Mudar plano',
    changEcardUrl: 'Como cancelar a assinatura de um plano pago?',
    changEcardservice: 'Contato',
    paymentcard: 'Loja de cobrança padrão',
    paymentcardEcard:
      'Defina uma loja como a loja de cobrança padrão. Se não estiver configurado, sua conta Dsers será fechada até você configurá-la.',
    paymentcardBu: 'Ativar loja'
  },
  menu: {
    Switch: 'Switch platform',
    blog: 'BLOG',
    facebook: 'Facebook',
    youtube: 'YouTube',
    email_support: 'Suporte por e-mail'
  },
  duplicate_order_confirm: {
    dec1: 'Veificamos que você já colocou o pedido',
    dec2:
      'no AliExpress anteriormente. Vpcê deseja colocá-lo no AliExpress novamente? Isso fará com que você tenha pedidos duplicados.',
    input_confirm: 'Digite CONFIRMAR para fazer pedidos para o AliExpress.'
  },
  new_feature: {
    csv_bulk_order: 'Pedidos em Massa por CSV',
    describe: 'Para usuários que não utilizam serviços do Shopify'
  },
  header: {
    about: 'SOBRE NÓS',
    support: 'SUPORTE',
    blog: 'BLOG',
    sign_in: 'Login',
    sign_up: 'Criar conta'
  },
  footer: {
    about: 'Sobre nós',
    support: 'Suporte',
    privacy_policy: 'Política de Privacidade'
  },
  send_reset_email: {
    title: 'Recuperar Senha',
    tip:
      'Nós enviaremos um código de verificação ao seu e-mail para verificar sua identidade.',
    dec:
      'Digite o endereço de e-mail utilizado para o registro. Se você instalou o DSers diretamente pelo Shopify, por favor, digite o e-mail da sua conta do Shopify, se vcê esqueceu seu e-mail por favor',
    contact_us: 'Contate-nos',
    placeholder: 'E-mail',
    email_instructions: 'Instruções de e-mail',
    send_succ:
      'Instruções para recuperação de senha foram enviadas ao seu e-mail',
    email_not_valid: 'Este e-mail não é válido!',
    email_empty: 'Por favor digite um e-mail',
    email_unknown: 'Please input your log in email.'
  },
  reset_password: {
    title: 'Recuperar senha',
    input_old_password: 'Digite sua Senha antiga!',
    old_password: 'Senha antiga',
    new_password: 'Senha nova',
    input_confirm_password: 'Por favor confirme sua senha',
    confirm_password: 'Confirmação de senha',
    reset_password: 'Recuperar senha',
    wrong_password: 'Senha errada. Por favor, confirme novamente',
    old_password_error: 'The original password is incorrect'
  },
  admitad: {
    //  add
    title: 'DSers Admitad está em vigor!',
    p1: 'A cooperação oficial entre o DSers e Admitad agora está online.',
    p2: 'Por favor verifique este artigo para configurar tudo:',
    p3: 'https://help.dsers.com/admitad-cashback-for-shopify/',
    p4:
      'Depois de concluído, obtenha um novo código na oferta WW do AliExpress.',
    p5:
      'Altere o código que você está usando no momento e substitua pelo novo código.',
    p6:
      'Nota: Os códigos que não são afiliados ao DSers serão interrompidos no próximo mês'
  },
  product_pool: {
    title:
      '<i>Mais de 5000<i> produtos de Casa&Jardim manipulados pelo AliExpress são oficialmente lançados, incluindo máscara antivírus. Transporte garantido dentro de 24~48 horas.',
    title_long: `O AliExpress fornece oficialmente aos produtos um suporte de fornecimento seguro.
                Todos os itens são manipulados pelo AliExpress diretamente e podem ser enviados dentro de 24 a 48 horas.
                Se você tem algo relacionado a esses produtos oficiais, o serviço ao cliente está disponível em dropshipping@list.alibaba-inc.com.
                O time de fornecimento do DSers está fornecendo ajuda ajuda pelo e-mail supply-support@dsers.com Obrigado por todos os clientes que perguntam,
                a situação na China está melhorando a cada dia. Esperamos que seus negócios fluam e desejamos boa sorte para todos.`
  },
  market: {
    placeholder: 'Digite as palavras-chave para pesquisar',
    import_to_dsers: 'Importar ao DSers',
    import_tip_title: 'Por favor, selecione lojas para importar produtos'
  },
  refresh_mask: {
    title: 'Atualizações de status',
    dec: 'Atualize a página para atualizar o status.'
  },
  select_platform: {
    title: 'Qual plataforma você deseja acessar ?'
  },
  aeAccountDisconnect: {
    title:
      'Sua conta Dsers não está mais vinculada ao AliExpress. Isso fez os dados do <i> pedido e produto sumirem temporariamente.<i> Os Dados serão recuperados após você vincular uma nova conta do AliExpress.'
  },
  archeved: {
    title: 'Pedidos arquivados',
    blank_tips:
      'The order has been archived on Shopify. If you need to modify it, please go to Shopify to operate.',
    dec:
      'Pedidos preenchidos há mais de quatro meses serão transferidos para a guia Pedidos arquivados e você ainda pode desarquivar os pedidos para realizar mais ações. Por favor, clique em <a href={href} target="_blank"> aqui </a> para saber mais.',
    customer: 'Cliente',
    email: 'Email',
    unarchive: 'Desarquivar',
    unarcheved_succ:
      'Pedido arquivado com sucesso, você pode localizar o pedido na guia "Abrir" agora.',
    unarcheved_fail:
      'Falha ao desarquivar o pedido, tente novamente mais tarde.',
    table_title_order: 'Realizar pedido',
    date_title: 'Archived Date:',
    processed:`Orders that have not been processed for more than a year will automatically be placed in the Archived Orders menu. You can still un-archive orders to perform more operations.`
  },
  top_stores: {
    store_authorized_tip: 'A loja precisa ser autorizada novamente. Por favor, vá para <a href="/app/settings?id=3"> Configurações - da loja </a> para atualizar a autorização. Caso contrário, você não poderá operar a loja adequadamente com o DSers'
  },
  tutorial_over_modal: {
    dec:
      'Acesse Shopify - Configurações - Checkout para verificar novamente se você está usando o recurso de preenchimento automático. Se sim, desative-o e salve as novas configurações.',
    dec2:
      'Observe que o recurso de preenchimento automático do Shopify atenderá automaticamente os pedidos, impedindo que você os faça no AliExpress. Se você tiver esse recurso ativado no Shopify, <i>não poderá processar pedidos nos DSers</i>.'
  },
  h5_callback: {
    bind_tip:
      'Tem certeza de que deseja vincular a loja Shopify {shop_name} usando o endereço de email {bind_email} à conta do DSers usando o endereço de email {current_email}?',
    login_tip: 'Reconecte a loja',
    bing_auth: `Your authorization has expired, please refresh the page to reauthorize`,
    authAgin:
      'Your current authorization has expired, please click the button below to update the authorization and re-link',
    auth_log: 'Log out',
    auth_entry: 'Reauthorize',
    bind_time_out:
      'Sorry, the store linking timed out, please click the button below to re-start the linking',
    relink: 'Relink',
    need_activate:
      'The store was previously hidden due to the plan limitation, please go to the subscription page to activate the corresponding store.',
    activate: 'Activation'
  },
  h5_landModal: {
    create_account: 'Create an account for you',
    register: 'Register yourself',
    existing_account: 'Already have an account?',
    customer_service: 'If you have any question, please',
    overtime_con:
      'The request timed out, please return to your Shopify store and re-initiate the binding',
    log_in: 'Log in',
    sign_up: 'sign up',
    return_shopify: 'Return to Shopify',
    contact_us: 'Contact us',
    note_con:
      'Your Shopify email address has been used to create a DSers account login name. If this is your registered account, you can try to log in. If it is not your registered account, please register manually',
    welcome_dsers: 'Welcome To DSers'
  },
  h5_redirect: {
    bind_tip:
      'Tem certeza de que deseja vincular à conta do DSers usando o endereço de email {email}?'
  },
  error_cashback_code: {
    title: 'Seu Cashback do Admitad está sendo interrompido. <br /> Confira.',
    title_dec: 'Descobrimos que seu código não está no DSers.',
    p1:
      'Descobrimos que você está usando o código de afiliado que não é do DSers',
    p2: 'canal. Você não pode mais receber Cashback com o código atual.',
    p3: 'Clique abaixo para descobrir o que aconteceu e como lidar com isso.'
  },
  // 文案新加
  publicDocument: {
    scope: '',
    warn:
      'Nosso sistema detectou que a senha é muito simples de ser descoberta. Acesse "Configurar" - "Conta" para redefinir sua senha após fazer o login.',
    newPass: 'Insira a nova senha.',
    safety: 'A senha não é segura.'
  },
  ad_1688: {
    top_banner:
      'O Programa de Fornecedores em Massa do DSers está no ar! Ele conta com apoio do AliExpress, 1688 e equipe de fornecedores DSers. Receba de 10% a 30% de desconto nos produtos!',
    learn_more: 'Saiba mais'
  },
  shop_push: {
    title: 'Entre em contato para receber 10%-30% de desconto nos produtos!',
    dsers_price: 'Preço DSers:',
    current_price: 'Preço atual:',
    profit_margin: 'Margem de lucro:',
    supplier: 'Fornecedor:',
    dsers_program: 'Programa de Fornecedores em Massa do DSers',
    link_copied: 'Link copiado!',
    copy_link: 'Copiar link para mapeá-lo',
    contact: 'Entre em contato para obter o desconto',
    what_dec: 'O que é o Programa de Fornecedores em Massa do DSers?',
    thank_you: 'Obrigado por fazer sua solicitação',
    fill_email: 'Insira seu e-mail <br /> para concluir a solicitação.',
    fill_email_placeholder: 'Insira o e-mail',
    send_request: 'Enviar solicitação',
    reach_you:
      'O DSers e a equipe de Fornecedores Globais do AliExpress entrarão <br /> em contato com você em breve.<br /> Tenha um ótimo dia!'
  },
  complete_passWord: {
    title: 'Registre as informações de sua conta',
    tip:
      'Descobrimos que as informações de sua conta não foram registradas. <br />Defina seu e-mail e sua senha.',
    email_tip:
      'Você poderá alterar o e-mail mais tarde, acessando o menu DSers - Configurar - Conta.',
    fill_email: 'Insira um endereço de e-mail',
    password_tip: 'Por favor utilize uma senha com no mínio 6 caracteres.',
    email_not_valid: 'Este e-mail não é válido!',
    password_request: 'Insira uma senha',
    astrict: 'Utilize entre seis a 20 caracteres.'
  },
  notice_plan_banner: `Nosso sistema detectou que sua conta DSers está usando o plano Básico,
    porém o recurso pago de atualizações automáticas foi ativado.
    Note que o recurso pago de atualizações automáticas permanecerá gratuito até 1º de janeiro de 2021.
    Após o período de teste gratuito, faça o upgrade para o plano Avançado ou Pro para continuar usando esse recurso.
    Pedimos desculpas pela inconveniência e mal entendido causados. Obrigado por utilizar o DSers e bom dropshipping.`,
  onBoarding:{
    selectPlatfrom:"Selecione sua plataforma",
    selectPlatfromTitle:'Selecione a plataforma da loja que você deseja vincular.',
    linkShopify:'	Vincular loja Shopify',
    linkWoo:'	Vincular loja WooCommerce',
    linkWix:'Vincular loja Wix',
    linkCsv:'Usar arquivos CSV de outras plataformas',
    logout:'Sair',
    linkAeTitle:'Vincule sua loja e o AliExpress'
  }
};
