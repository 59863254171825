import React from 'react';
import { Icon, Button, Select } from 'antd';

const { Option } = Select;

import intl from 'react-intl-universal';

import styles from './index.less';

export default class OnlyPagination extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  onChange = flag => {
    this.props.changePager(flag);
  };

  renderOptions = pageSizeOptions => {
    let arr = null;
    arr = pageSizeOptions.map(v => {
      return (
        <Option key={v} value={v}>
          {' '}
          {`${v} / ${intl.get('public.page')}`}{' '}
        </Option>
      );
    });
    return arr;
  };

  render() {
    const {
      len = 0,
      pageSize = 40,
      currentPage = 1,
      changePageSize,
      pageSizeOptions = ['10', '20', '30', '40', '50', '100'],
      count
    } = this.props;
    let dis = len < pageSize; // 下一页是否可点
    let allPage = 0;

    if (count || count == 0) {
      if (count <= pageSize * currentPage) {
        dis = true;
      }
      allPage = Math.ceil(count / pageSize);
    }

    return (
      <div className={styles.pagination}>
        <div className={styles.btns}>
          <Button
            disabled={currentPage == 1}
            onClick={e => this.onChange('prev')}
          >
            <Icon type="left" />
          </Button>
          <Button disabled={dis} onClick={e => this.onChange('next')}>
            <Icon type="right" />
          </Button>
        </div>
        <div className={styles.pageSize} id="onlyPaginationPageSize">
          <Select
            defaultValue={`${pageSize}`}
            onChange={changePageSize}
            getPopupContainer={() => document.getElementById('onlyPaginationPageSize')}
          >
            {this.renderOptions(pageSizeOptions)}
          </Select>
        </div>
        {allPage ? (
          <div className={styles.allPage}>{`${currentPage} / ${allPage}`}</div>
        ) : null}
      </div>
    );
  }
}
