export default {
  public: {
    facebook: 'Facebook',
    youtube: 'YouTube',
    cancel: 'Cancel',
    yes: 'Yes',
    no: 'No',
    ok: 'Ok',
    save: 'Save',
    newsave:'SAVE',
    submit: 'Submit',
    search: 'Search',
    confirm: 'CONFIRM',
    newconfirm: 'CONFIRM',
    apply: 'Apply',
    log_out: 'Log out',
    reset_password: 'Reset Password',
    delete: 'Delete',
    got_it: 'Got it',
    check_more: 'Check more',
    more: 'More',
    page: 'page',
    access: 'Access',
    refresh: 'Refresh',
    reconnect: 'Reconnect',
    on: 'On',
    off: 'Off',
    here: 'here',
    Manage: 'Manage subscription',
    check_it: 'Check it',
    dismiss: 'Dismiss',
    contact: 'Contact',
    contact_us: 'Contact us',
    updata_price:'Update the price of the products pushed to your Shopify store according to the pricing rules you currently set. The process may take some time. This can be used every 30 days.',
    please_enter:'Please enter CONFIRM to start the update',
    shopUnavailable:'This store is currently disconnected. Please reconnect it to use this feature',
    InterfaceTransfinite:'Update the price of the products pushed to your Shopify store according to the pricing rules you currently set. The process may take some time. This can be used every 30 days. Due to interface restrictions, the feature will be opened after 24:00, please wait patiently',
    synchronous:`The store's product prices are being updated`,
    useAgain:'Can be used again in',
    day:'days',
    jumpPrice:`Click 
      <a
      href="/app/pricing"
      target="_blank"
      rel="noopener noreferrer"
    >
      here 
    </a>
    to upgrade your plan to be able to manually sync more times`,
    pause:`Synchronization of the day complete,
    The remaining products will be automatically synced tomorrow`,
    goodsTooMuch:'Update the price of the products pushed to your Shopify store according to the pricing rules you currently set. The process may take some time. This can be used every 30 days. Since your store has more than 100,000 items, we will synchronize all your items in multiple days (up to 100,000 per day), please be patient.',
    exceeds:'As your product synchronization exceeds 100,000, this function will be enabled after 24:00, please wait patiently',
    noproduct:'The store has no product that can be updated',
    updateTitle:'Update product price',
    updateText:'Update the price of the products pushed to your Shopify store according to the pricing rules you currently set. The process may take some time. This can be used every 30 days.',
    start:'Start Update',
    unsave:'Unsaved changes',
    checkmodaltext:`The information you modified has not been saved yet. If you want to save, please click "SAVE", if you don't want to save your modifications, click "DISCARD"`,
    discard:'DISCARD',
    choose:"SELECT",
    gotodsers:'Go to DSers Homepage',
    oberlo_data_pending:'Data correction.',
    oberlo_data_success:'Data correction completed.',
    oberlo_data_fail:'Data verification Failed.',
    oberlo_data_try:'TRY AGAIN',
    priceruleDis:'Please activate and confirm your <a href="/app/settings?=5" > Pricing Templates </a> settings in Pricing Rule and start to update afterwards.',
    padding_title:'We are correcting the orders and products information, please wait',
    migrate_order_tip:'The synchronization of the status of some order failed. It is possible the AliExpress account where those orders were placed is different from the currently linked AliExpress account. Please change the AliExpress account linked to DSers to update the status of the orders. If you have any questions, please contact DSers Support team.',
    oberlo_product_error_tip:'Product data verification completed, but { num } AliExpress products were not available and can’t be imported to DSers. You will need to manually map the new suppliers. Go to the Unmapped products tab to view',
    add_report_user_tip:"Data is being created, the report will be generated within 24 hours. Thank you for your patience.",
    filter_confirm_des:'CONFIRM',
    filter_clear_des:'CLEAR ALL FILTERS',
    unsaveErr:'Unable to save changes',
    gocheck:'Go check',
    checkmodalerrtext1:'The reason you cannot save is ',
    checkmodalerrtext2:'To fix the issue, you need to re-map the part facing issues, or delete the mapping with problems.',
    checkmodaladvanceerrtext:'In Advanced Mapping, the Ship to selection cannot be repeated on the same variant, otherwise the mapping cannot be saved. ',
    checkmodalbasicrepeaterrtext:'In Basic Mapping, Options cannot be repeated, otherwise the mapping cannot be saved. ',
    checkmodalbasicnullerrtext:'In Basic mapping, you need to select the corresponding variant, otherwise the mapping cannot be saved. ',
    input_guide_text:'The Search or Import field, along with the Filter feature has been moved! You can now find them on the top right corner.',
    input_guide_btn:'Got it',
    importlist_card_guide_text:`We have improved the product card and some features can now be found in 'More Action' in the lower right corner`,
    product_card_guide_text:`We have improved the product card and the mapping function now has a floating display. Some features can now be found in 'More Action' in the lower right corner`,
    search_input_url_error_match_ae_tip:'The URL you entered is not an AliExpress URL, please check and re-enter.',
    search_input_url_error_match_tm_tip:'The URL you entered is not a Tmall URL, please check and re-enter.',
    search_input_url_invalid_tip:'The URL you entered is invalid. Please check and try again.',
  }
};
