import React, { Component } from 'react';
import { routerRedux } from 'dva/router';
import { connect } from 'dva';
import { Form, Input, Button, message } from 'antd';

import { checkPassword } from './until';

import styles from './SignInPage.less';

const FormItem = Form.Item;

@Form.create()
class RegisterResult extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getMsg = msg => {
    let v = '';
    if (msg === 'time expired' || msg === 'password already changed') {
      v = 'The link has expired, please reapply.';
    } else {
      v = 'Failed to change password, please try again later or contact us.';
    }
    return v;
  };

  handleSubmit = e => {
    const { match, form, dispatch } = this.props;
    e.preventDefault();
    const { code } = match.params;
    form.validateFields((err, values) => {
      if (!err) {
        if (values.password !== values.password2) {
          message.error('Wrong password.Please confirm again.');
          return;
        }
        dispatch({
          type: 'partner/ResetPassword',
          payload: {
            data: {
              password: values.password,
              code
            },
            callback: d => {
              if (d.code === 2000 || d.code === 2010) {
                message.success('Your password has been reset', 3, () => {
                  dispatch(routerRedux.push('/partner/login'));
                });
              }
            }
          }
        });
      }
    });
  };

  render() {
    const { form, submitting } = this.props;
    const { getFieldDecorator } = form;

    return (
      <div className={styles.forgotpassword}>
        <h3>Reset Password</h3>
        <Form onSubmit={this.handleSubmit}>
          <FormItem>
            {getFieldDecorator('password', {
              rules: [
                {
                  validator: (rule, value, callback) =>
                    checkPassword(rule, value, callback)
                }
              ]
            })(
              <Input.Password
                size="large"
                type="password"
                autoComplete="new-password"
                placeholder="new password"
              />
            )}
          </FormItem>
          <FormItem>
            {getFieldDecorator('password2', {
              rules: [
                {
                  validator: (rule, value, callback) =>
                    checkPassword(
                      rule,
                      value,
                      callback,
                      form.getFieldValue('password')
                    )
                }
              ]
            })(
              <Input.Password
                size="large"
                type="password"
                autoComplete="new-password"
                placeholder="confirm new password"
              />
            )}
          </FormItem>
          <FormItem>
            <p className={styles.tip}>
              If you have any question , please{' '}
              <a href="mailto:support@dsers.com">contact us</a>.
            </p>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={submitting}
            >
              Reset
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}
export default connect()(RegisterResult);
