import React from 'react';
import { Modal, Button } from 'antd';

import intl from 'react-intl-universal';

import styles from './index.less';

export default class RefreshMask extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      visible: nextProps.visible || false
    });
  }

  handleRefresh = () => {
    const _this = this;

    fetch(`${window.DSERS_API_CONFIG.HOST}/api/v1/users/info`, {
      headers: {
        Authorization: localStorage.getItem('dser-pro-authority')
      }
    })
      .then((response) => {
        return response.json();
      })
      .then((stories) => {
        if (
          stories &&
          stories.data &&
          stories.data.stores &&
          stories.data.stores.length == 0 &&
          (`${stories.data.status  }`).length == '8' &&
          (`${stories.data.status  }`).substring(3, 4) == 0
        ) {
          window.location.href = '/app/bind/platform';
        } else {
          _this.setState({
            visible: false
          });
          window.location.reload();
        }
      });
  };

  render() {
    const { visible } = this.props;

    return (
      <Modal
        visible={visible}
        footer={false}
        closable={false}
        width={500}
        maskClosable={false}
        onCancel={this.handleRefresh}
        className={styles.refreshMaskWrap}
      >
        <h3>{intl.get('refresh_mask.title')}</h3>
        <p>{intl.get('refresh_mask.dec')}</p>
        <div className={styles.refreshLine}>
          <Button type="primary" onClick={this.handleRefresh}>
            {intl.get('public.refresh')}
          </Button>
        </div>
      </Modal>
    );
  }
}
