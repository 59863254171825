export default {
  guide: {
    // 升级弹窗文案
    CommonGuide: [
      {
        type: 1,
        title: 'Changer de plan',
        content:
          'Votre plan actuel est Basic, veuillez passer au plan Avancé si vous souhaitez accéder cette fonction, au mapping avancé et au service client 24h/24.',
        addContent:"(PS : si vous n'avez pas atteint la limite supérieure de votre plan actuel, il se peut que votre Boutique de Facturation ne soit pas disponible. Dans ce cas, vous devez d'abord activer la Boutique de Facturation, puis changer le plan)",
        mainBtn: 'Changer',
        otherBtn: 'Annuler'
      },
      {
        type: 2,
        title: 'Changer de plan',
        content:
          'Votre plan actuel est Avancé, veuillez passer au plan Pro si vous souhaitez accéder à cette fonction et à la gestion de plus de 70 000 produits.',
        addContent:"(PS : si vous n'avez pas atteint la limite supérieure de votre plan actuel, il se peut que votre Boutique de Facturation ne soit pas disponible. Dans ce cas, vous devez d'abord activer la Boutique de Facturation, puis changer le plan)",
        mainBtn: 'Changer',
        otherBtn: 'Annuler'
      },
      {
        type: 3,
        title: 'Personnalisation',
        content:
          'Votre plan actuel est le plus élevé, si vous souhaitez augmenter les limites, nous pouvons vous proposer des services sur mesure.',
        addContent:"(PS : si vous n'avez pas atteint la limite supérieure de votre plan actuel, il se peut que votre Boutique de Facturation ne soit pas disponible. Dans ce cas, vous devez d'abord activer la Boutique de Facturation, puis changer le plan)",
        mainBtn: 'Nous contacter',
        otherBtn: 'Annuler'
      },
      {
        type: 4,
        title: 'Changer de plan',
        content:'Votre plan actuel est Standard, veuillez passer au plan Avancé si vous souhaitez accéder cette fonction, au mapping avancé et au service client 24h/24.',
        addContent:"(PS : si vous n'avez pas atteint la limite supérieure de votre plan actuel, il se peut que votre Boutique de Facturation ne soit pas disponible. Dans ce cas, vous devez d'abord activer la Boutique de Facturation, puis changer le plan)", 
        mainBtn: 'Changer',
        otherBtn: 'Annuler'
      },
      {
        type: 5,
        title: 'Personnalisation',
        content:'Votre plan actuel est le plus élevé, si vous souhaitez augmenter les limites, nous pouvons vous proposer des services sur mesure.',
        addContent:"(PS : si vous n'avez pas atteint la limite supérieure de votre plan actuel, il se peut que votre Boutique de Facturation ne soit pas disponible. Dans ce cas, vous devez d'abord activer la Boutique de Facturation, puis changer le plan)",
        mainBtn: 'Nous contacter',
        otherBtn: 'Annuler'
      },
      {
        type: 6,
        title: 'Personnalisation',
        content:'Votre plan actuel est le plus élevé, si vous souhaitez augmenter les limites, nous pouvons vous proposer des services sur mesure.',
        addContent:"(PS : si vous n'avez pas atteint la limite supérieure de votre plan actuel, il se peut que votre Boutique de Facturation ne soit pas disponible. Dans ce cas, vous devez d'abord activer la Boutique de Facturation, puis changer le plan)",  
        mainBtn: 'Nous contacter',
        otherBtn: 'Annuler'
      },
      {
        type: 7,
        title: 'Changer de plan',
        content:
          'Votre plan actuel est Avancé, veuillez passer au plan Pro si vous souhaitez accéder à cette fonction et à la gestion de plus de 70 000 produits.',
        addContent:"(PS : si vous n'avez pas atteint la limite supérieure de votre plan actuel, il se peut que votre Boutique de Facturation ne soit pas disponible. Dans ce cas, vous devez d'abord activer la Boutique de Facturation, puis changer le plan)",
        mainBtn: 'Changer',
        otherBtn: 'Annuler'
      },
      {
        type: 8,
        title: 'Changer de plan',
        content:'Votre plan actuel est Standard, veuillez passer au plan Avancé si vous souhaitez accéder cette fonction, au mapping avancé et au service client 24h/24.',
        addContent:"(PS : si vous n'avez pas atteint la limite supérieure de votre plan actuel, il se peut que votre Boutique de Facturation ne soit pas disponible. Dans ce cas, vous devez d'abord activer la Boutique de Facturation, puis changer le plan)",
        mainBtn: 'Changer',
        otherBtn: 'Annuler'
      },
      {
        type: 9,
        title: 'Personnalisation',
        content:'Votre plan actuel est le plus élevé, si vous souhaitez augmenter les limites, nous pouvons vous proposer des services sur mesure.',
        addContent:"(PS : si vous n'avez pas atteint la limite supérieure de votre plan actuel, il se peut que votre Boutique de Facturation ne soit pas disponible. Dans ce cas, vous devez d'abord activer la Boutique de Facturation, puis changer le plan)",
        mainBtn: 'Nous contacter',
        otherBtn: 'Annuler'
      },
      {
        type: 10,
        title: 'Changer de plan',
        content:
          'Votre plan actuel est Basique Personalisé, veuillez passer au plan Avancé si vous souhaitez accéder cette fonction, au mapping avancé et au service client 24h/24.',
        addContent:"(PS : si vous n'avez pas atteint la limite supérieure de votre plan actuel, il se peut que votre Boutique de Facturation ne soit pas disponible. Dans ce cas, vous devez d'abord activer la Boutique de Facturation, puis changer le plan)",
        mainBtn: 'Changer',
        otherBtn: 'Annuler'
      },
    ],
    // 开屏弹窗文案
    OBDialog: {
      bindAE: {
        top:
          'Nous sommes heureux de vous voir nous rejoindre! Nous avons migré avec succès toutes vos données, commandes, produits et paramètres.',
        topTitle: 'Bienvenue sur DSers!',
        centerTitle: 'Migration réussie!',
        btn: 'D’ACCORD'
      },
      noBindAE: {
        top:
          'Nous sommes heureux de vous voir nous rejoindre! Nous avons migré avec succès toutes vos données, commandes, produits et paramètres.',
        centerTitle: 'Migration réussie!',
        topTitle: 'Bienvenue sur DSers!',
        bottom: [
          'Pour commencer à utiliser DSers, veuillez lier votre compte AliExpress.'
        ],
        btn: 'AliExpress'
      }
    },
    //底部banner文案
    OBanberWords: [
      {
        // 绑定AE
        step: 1,
        title: 'Lier votre compte AliExpress',
        text:
          "Lier votre compte AliExpress à DSers vous permet de passer plus de 100 commandes en quelques secondes! Cliquez <a target='_blank' href='https://help.dsers.com/link-your-shopify-store/'>ici</a> pour plus de détails.",
        btn: 'LIER ALIEXPRESS'
      },
      {
        // 确认套餐
        step: 2,
        title: 'Gardez vos habitudes d’Oberlo',
        textArr: [
          {
            type: 1,
            text:
              "Access to all features of Oberlo, and enjoy more advanced features like muti-store management.Click <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>here</a> for more details."
          },
          {
            type: 2,
            text:
              "Accédez à toutes les fonctions d’Oberlo et profitez de fonctions plus avancées comme le mapping avancé et le service client 24h/24. Cliquez <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>ici</a> pour plus de détails."
          },
          {
            type: 3,
            text:
              "Accédez à toutes les fonctions d’Oberlo et profitez de plus de ressources comme la gestion de plus de 70 000 produits. Cliquez <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>ici</a> pour plus de détails."
          }
        ],
        btn: 'Activer le plan'
      },
      {
        // 安装插件
        step: 3,
        title: "Installer l'extension Chrome DSers",
        text:
          "Importation de produits en un clic d'AliExpress vers DSers pour gagner du temps et trouver de nouveaux produits gagants! Cliquez <a target='_blank' href='https://help.dsers.com/import-product-from-aliexpress-for-shopify/#Use DSers Chrome Extension'>ici</a> pour plus de détails.",
        btn: 'Télécharger'
      }
    ],
    // 确认弹窗文案
    ComfirmPop: [
      {
        kind: 'AE',
        title: 'Lier votre compte AliExpress',
        content:
          "Lier votre compte AliExpress à DSers vous permet de passer plus de 100 commandes en quelques secondes! Cliquez <a target='_blank' href='https://help.dsers.com/link-your-shopify-store/'>ici</a> pour plus de détails.",
        mainBtn: 'LIER ALIEXPRESS'
      },
      {
        kind: 'PLAN',
        title: 'Gardez vos habitudes d’Oberlo',
        contentArr: [
          {
            type: 1,
            content:
              "Access to all features of Oberlo, and enjoy more advanced features like muti-store management.Click <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>here</a> for more details."
          },
          {
            type: 2,
            content:
              "Accédez à toutes les fonctions d’Oberlo et profitez de fonctions plus avancées comme le mapping avancé et le service client 24h/24. Cliquez <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>ici</a> pour plus de détails."
          },
          {
            type: 3,
            content:
              "Accédez à toutes les fonctions d’Oberlo et profitez de plus de ressources comme la gestion de plus de 70 000 produits. Cliquez <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>ici</a> pour plus de détails."
          }
        ],
        errorBillStore:
          "Vous ne pouvez pas activer le plan car votre boutique de facturation n'est pas disponible. Veuillez changer de boutique de facturation dans DSers - <a target='_blank' href='https://www.dsers.com/app/select'>Gérer souscription</a> ou activer le boutique de facturation ",
        errorBillStoreNotId: "Vous ne pouvez pas activer le plan car votre boutique de facturation n'est pas disponible. Veuillez changer de boutique de facturation dans DSers - <a target='_blank' href='https://www.dsers.com/app/select'>Gérer souscription</a>",
        errorBillName: 'sur Shopify',
        mainBtn: 'Activer le plan'
      },
      {
        kind: 'CHROME',
        title: "Installer l'extension Chrome DSers",
        content:
          "Importation de produits en un clic d'AliExpress vers DSers pour gagner du temps et trouver de nouveaux produits gagants! Cliquez <a target='_blank' href='https://help.dsers.com/import-product-from-aliexpress-for-shopify/#Use DSers Chrome Extension'>ici</a> pour plus de détails.",
        mainBtn: 'Télécharger'
      }
    ],
    // 刷新弹窗文案
    RefreshPopText: {
      title: 'Actualiser la page',
      content:
        'Veuillez actualiser la page afin que nous puissions détecter si vous avez installé le plugin avec succès',
      btn: 'ACTUALISER'
    },
    // 提示用户必要信息文案
    OberloTopTipOneBindAE:"Vos données sont en cours de correction, vous pourrez migrer d'autres comptes Oberlo une fois la correction des données terminée.",
    OberloTopTipOneNoBindAE:"Veuillez lier votre compte AliExpress à DSers afin que nous puissions corriger le statut de vos commandes, vous pourrez continuer à migrer d'autres comptes Oberlo une fois la correction terminée.",
    OberloTopTipTwo:"Veuillez noter que DSers affiche toutes les commandes Shopify, tandis qu'Oberlo n'affichait que les commandes contenant des produits créés via Oberlo.</br>" +
        "DSers a migré les commandes des deux derniers mois. Si vous avez besoin de synchroniser plus de commandes, <a style='color:#3E5BFE' href='https://www.messenger.com/t/DSersOfficial' target='_blank'>veuillez nous contacter.</a>"
  }
};
