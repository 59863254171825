import React from 'react'
import { Button } from 'antd'
import { useDispatch } from 'react-redux';
import { routerRedux } from 'dva/router';
import intl from 'react-intl-universal';

import styles from './NoAccess.less'

import NoAccessImage from 'assets/NoAccess.png'

export default function NoAccess() {
    const dispatch=useDispatch()

    const goToHomepage =()=>{
        dispatch(routerRedux.push('/')); // 首页
    }

    return (
        <div className={styles.container}> 
            <img src={NoAccessImage} alt="" />
           <div className={styles.title}>{intl.get('setting.subaccount.NoAccess')}</div>
            <div className={styles.text}>{intl.get('setting.subaccount.NoAccessTitle')}</div>
            <div className={styles.retunButton} onClick={goToHomepage}><Button>Return Home page</Button></div>
        </div>
    )
}
