import React from 'react';
import { Icon, Modal, Button } from 'antd';

import styles from './SelectImage.less';
import './SelectImage.css';
import { replaceUrlToHttps } from 'utils/utils';
import intl from 'react-intl-universal';

export default class SelectImageManagement extends React.PureComponent {
  selected = {};

  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: '',
      fileList: [],
      imgSelected: false
    };
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = file => {
    this.setState({
      previewImage: file.src,
      previewVisible: true
    });
  };

  changeSelect = item => {
    console.log(item);

    if (this.selected === item) {
      this.selected = {};
    } else {
      this.selected = item;
    }
    this.forceUpdate();
  };

  imgSelectedone = e => {
    return () => {
      console.log(e);

      this.setState({
        imgSelected: true
      });
    };
  };

  renderList = (list = []) => {
    return (
      <div className={styles.checkableCard}>
        {/* <div className="ant-upload-list ant-upload-list-picture-card"> */}
        <div className="ant-upload-list-picture-card">
          {list.map((item, index) => {
            const checked = item.src === this.selected.src ? 'checked' : '';
            return (
              <div
                className={`ant-select-list-item ant-select-list-item-${checked}`}
                style={{ margin:3,boxSizing:'border-box' }}
                key={item.src}
                onClick={() => {
                  this.changeSelect(item);
                }}
              >
                <div
                // className={
                //   this.state.imgSelected ? 'changedImgList' : 'changeImgList'
                // }
                // onClick={this.imgSelectedone(index)}
                >
                  <span>
                    <a
                      className=""
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        width={256}
                        height={256}
                        src={replaceUrlToHttps(item.src)}
                      />
                    </a>
                    <a
                      className="ant-upload-list-item-name"
                      href={item.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  </span>
                  {/* <div className="ant-upload-list-item-actions see_img">
                    <a
                      onClick={e => {
                        e.stopPropagation();
                        this.handlePreview(item);
                      }}
                    >
                      <Icon type="eye" />
                    </a>
                  </div> */}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const { data = [] } = this.props;

    if (fileList.length === 0) {
      fileList.push(...data);
    }

    return (
      <div className="listContainer">
        {this.renderList(fileList)}
        <div className={styles.btnsLine}>
          <Button
            data="bk"
            onClick={() => {
              const { onCancel } = this.props;
              if (onCancel) {
                onCancel();
              }
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!this.selected.src}
            onClick={() => {
              const { onOk } = this.props;
              if (onOk) {
                onOk(this.selected);
              }
            }}
            type="primary"
            style={{ marginLeft: 24 }}
            data="dx"
          >
            {intl.get('public.choose')}
          </Button>
        </div>

        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}
