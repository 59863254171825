export default {
  beforeMigration: {
    shopify: 'Keep only the Shopify (or CSV) site settings.',
    wooCommerce: 'Keep only the WooCommerce site settings.',
    wix: 'Keep only the Wix site settings.',

    title: 'The system will be fully upgraded soon!',
    title1: 'System upgrade approaching',
    one: 'DSers will be upgraded on ',
    two: 'and will be unavailable for up to 24 hours',
    there: 'Please arrange your schedule accordingly',
    four:
      'To bring you a better user experience, DSers will upgrade the system for a period of',
    five: '24 hours from',
    six:
      'DSers will be unavailable during the period. We apologize for the inconvenience.',



    seven: 'Click ',
    seven1: 'here ',

    eight: ' to learn more.',

    nine: 'to get more information.',
    ten:
      'To bring you a better user experience, DSers will upgrade the system for a period of',
    eleven:
      'DSers will be unavailable during the period. We apologize for the inconvenience.',
    twelve:
      'The upgrade means to simplify the multi-platform management. This may cause setting conflict if you are managing stores on multi-platforms. The compatibility of the difference of each platforms is fully considered. Still, please let us know your preference just in case.',
    thirteen:
      'How would you like to merge potentially conflicting settings in Setting after the upgrade?',

    fourteen:
      'After merging, the account level settings between the two sites will conflict, so you must choose which site to keep. The settings that will conflict are:',
    fifteen: 'Notification',
    sixteen: 'Staff Account',
    seventeen: 'Shipping',
    eighteen: 'Add Global shipping method based on product',
    nineteen: 'Leave a message to Suppliers',
    twenty: 'Phone Number Optimization',
    twentyOne: 'Canceled Orders Optimization',
    twentyTwo: 'And other functions',

    twentyThere: "But don't worry, we will still keep the settings of the store level between the two sites for you, such as:",
    twentyFour: 'Pricing Rule',
    twentyFive: 'Package Tracking',
    twentySix: 'Currencies',

    twentySeven: 'The system is being upgraded, which will',
    twentyEight: 'take about 24 hours',
    twentyNine:
      'DSers will be suspended during the upgrade period, and we apologize for any inconvenience.',

    twentyTen:
      'Sorry, the system is currently being upgraded and cannot be bound, please try again later.',
    notification: 'Notification',

    submit: 'CONFIRM'
  }
};
