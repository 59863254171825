export default {
  supplier_optimizer: {
    title: 'Optimiseur de Fournisseur',
    title_test: 'Autre fournisseur',
    msg: `Nous sommes en train d'ajuster la fonction d'optimisation des fournisseurs et certaines données peuvent être incorrectes. Nous nous efforçons de les corriger. Nous vous remercions de votre compréhension et de votre patience.`,
    sub_title: 'En fonction de vos besoins',
    intro: `Vous pouvez trouver des produits identiques avec le lien URL AliExpress du produit ici, et cliquez  
      <a href="https://help.dsers.com/supplier-optimizer-for-shopify/" target="_blank">ici</a> pour en apprendre plus.`,
    placeholder: `Cherchez des produits similaires en utilisant l'URL AliExpress`,
    product_list: 'Produit',
    ship_from: 'Expédier depuis',
    ship_to: 'Envoyé vers',
    sort_by: 'Sort by',
    product_title: 'Produit',
    price: 'Prix',
    sale_number: 'Nombre de Ventes',
    product_rating: 'Qualité Produit',
    store_rating: 'Note Fournisseur',
    import_to_dsers: 'Import to DSers',
    not_found: `Pas de produit similaire trouvé`,
    import_picture: 'Importer image',
    upload_tip: 'Veuillez télécharger une image du produit et nous recherchons des produits similaires sur AliExpress pour vous.',
    click_to_upload: 'Click to Upload',
    click_or_drag: 'Cliquez sur télécharger ou faîtes glisser une image directement',
    only_img: `Seuls les formats PNG et JNG peuvent être téléchargé.(Taille de l'image dans les 8 Mo)`,
    delivery: 'Date de Livraison',
    delivery_info: 'Détails Livraison',
    url_error: `Le format de l'URL est incorrect, veuillez entrer l'URL du produit AliExpress`,
    sys_error: 'Le système est actuellement en maintenance, veuillez réessayer dans 2 minutes',
    ship_tip: 'Cochez cette case si vous souhaitez importer les informations de durée de livraison',
    search_tip: 'Cliquez sur Rechercher pour trouver des fournisseurs similaires',
    shipping_price: 'Coût de Livraison',
    searched_product: 'Produit Comparé',
    ship_method: 'Méthode de Livraison',
    loading_state_1: `Mise en ligne de l'image...`,
    loading_state_2: `Analyse de l'image...`,
    loading_state_3: `Recherche de produits...`,
    have_try: 'Essayer',
    click_or_drag:'Cliquez sur télécharger ou faîtes glisser une image directement',
    uploadimage:'UPLOAD IMAGE',
    agency:'Agence',
    agency_2:'Agence',
    agencySub:`"Vous ne parvenez pas à trouver un agent fiable ? <br/>
    DSers vous permet d'entrer en contact avec un agent certifié pour vos besoins en matière de gestion des fournisseurs." `,
    learnmore:'POUR EN SAVOIR PLUS',
    Laura:'Voici Laura. Toujours en ligne, sérieuse, rapide, un service de qualité pour vous.',
    Ciel:`Fiable, digne de confiance. Voici Ciel. Votre temps et vos efforts méritent d'être associés aux prouesses de Ciel.`,
    Nora:'Vise à promettre un coût économique, une expédition fiable. Nous sommes impatients de coopérer avec vous !',
    Michael:'Compétent dans tout type de recherche de produits. Un service précis et efficace pour les clients.',
    Rebekah:"Bonjour, Ici Rebekah. Je peux vous proposer des produits de qualité mais à prix réduit et résoudre vos problèmes à temps.",
    Chloe:'Habituée au fonctionnement du dropshipping. Objectifs du service : identifier et répondre à vos attentes avec soin.',
    Kevin:`Nous nous concentrons sur la fourniture de produits pour le Brésil, l'Europe et les États-Unis. Prix d'usine avec expédition rapide.`,
    Bess:`Fournisseur chinois, spécialisé dans le dropshipping, 3 ans d'expérience, accessible 24 heures sur 24.`,
    Anchor:`Fournisseur de masse DSers, équipe d'agents professionnels, nous vous invitons à consulter toute question relative à DSers et aux différents produits asociés.`,
    David:'Je suis David, je peux résoudre tous vos problèmes ! En attendant, je peux vous parler de la Chine ou autre.',
    Tony:`La satisfaction du client dépend de nombreux facteurs, y compris le prix. DSers s'engage à le faire.`,
    Alisa:`Alisa de Dsers Supply assurant l'approvisionnement et un service personnalisé. N'hésitez pas à laisser vos demandes.`,
    Keith:`Disponible 24 heures sur 24 pour vous ! Je suis un professionnel avec quatre ans d'expérience dans l'approvisionnement de masse par le dropshipping !`,
    Cohen:'Voici Cohen, désirant se faire des amis. Répondre à vos attentes et atténuer vos difficultés sont mes priorités !',
    April:'Je suis April. Je vais vous fournir des produits de haute qualité à bas prix et vous offrir le meilleur service !',
    Stone:'Voici Stone, compétent dans la recherche du meilleur prix pour les clients et patient dans le traitement des problèmes des clients.',
    Jessica:`Je suis Jessica. Je vous servirai avec tout mon cœur, j'ai hâte de coopérer avec vous，mon ami !`,
    Duan:'Le meilleur, le plus rapide, le plus sincère, et toujours prêt pour votre demande！',
    Sophia:'Salut, mes amis du dropshipping, faites-moi part de vos demandes, Dsers fera de son mieux pour vous satisfaire.',
    agenct:'Agent',
    agencytext:`"Les agents d'approvisionnement sont dédiés aux activités de dropshopping.
    Les équipes sont principalement implantées en Chine où le service d'approvisionnement est des plus solides."
    `,
    search_product:'Chercher Produit',
    optimizerTitle:`Recherche de fournisseurs de qualité`,
    optimizerDesc_1:`1. Saisissez l'URL souhaitée dans le champ de recherche ou utilisez une image`,
    optimizerDesc_2:'2. Dans les résultats de la recherche, vous pouvez choisir un meilleur fournisseur en fonction de vos besoins',
    noSearch:`Aucun résultat de recherche, veuillez modifier l'URL ou l'image pour effectuer une nouvelle recherche`
  }
};
