
    export default 
    {
    "archeved": {
        "title": "Pedidos archivados",
        "blank_tips": "El pedido ha sido archivado en Shopify. Si necesitas modificarlo, por favor dirígete a Shopify para poder hacerlo.",
        "dec": "Los pedidos procesados hace más de 4 meses serán transferidos a la pestaña de pedidos archivados y aún podrás desarchivar el pedido para realizar más acciones. Por favor, haz clic <a href={href} target=\"_blank\" >aquí para</a> ver más.",
        "customer": "Cliente",
        "email": "Correo electrónico",
        "unarchive": "Desarchivar",
        "unarcheved_succ": "El pedido se ha desarchivado con éxito, ahora puedes localizar el pedido en la pestaña Abrir.",
        "unarcheved_fail": "No se ha podido desarchivar el pedido, inténtalo de nuevo más tarde.",
        "table_title_order": "Pedido",
        "date_title": "Fecha de archivo:"
    }
}