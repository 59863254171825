import React, { useState, useEffect, useRef, useReducer } from 'react'
import { useDispatch } from 'react-redux';
import { connect } from 'dva';
import { Drawer, Button, Progress, Spin, Modal, Select } from 'antd';

import styles from './less/FindSupplierAd.less'
import './less/FindSupplierAd.css'


import bannerimg from 'assets/banner/supplier/openarrow.png'
import arrow from 'assets/banner/supplier/arrow.png'
import { Get, Post } from 'utils/request';
import moment from 'moment';



const { Option } = Select;

function FindSupplierAd({ dispatch2, handleFilterChange, currentPartion }) {
    const [visible, setVisible] = useState(false)
    const [hover, setHover] = useState(false)
    const [finish, setFinish] = useState(false)
    const [category, setCategory] = useState([])
    const [secondCategory, setSecondCategory] = useState([])
    const [value, setValue] = useState('All')
    const [levelOne, setLevelOne] = useState('All')
    const [levelTwo, setLevelTwo] = useState()
    const [selectOne, setSelecrOne] = useState()
    const [selectTwo, setSelectTwo] = useState()

    const dispatch = useDispatch()

    const ref = useRef(null)



    useEffect(() => {
        try {
            const cacheData = JSON.parse(
                sessionStorage.getItem('category_bargain_all')
            );
            if (cacheData) {
                setCategory(cacheData);
            }
        } catch (error) {
            console.warn('get cache data error', error);
        }
    }, [])

    useEffect(() => {
        const getUserInfo = async () => {
            try {
                const infoData = await Get('/users/info')
                if (infoData && infoData.code === 2000) {
                    const userInfo = infoData.data
                    if ((userInfo.propool_category === 'all') ||  (userInfo.propool_category?.substring(0,userInfo.propool_category.indexOf(",")) === 'All')) {
                        setFinish(false)
                    } else {
                        const firstLevel = userInfo.propool_category?.substring(0,userInfo.propool_category.indexOf(","))
                        const secondLevel = userInfo.propool_category?.substring(userInfo.propool_category.indexOf(",")+1)
                        setFinish(true)
                        setSelecrOne(firstLevel)
                        setSelectTwo(secondLevel)
                        const data = JSON.parse(sessionStorage.getItem('category_bargain_all'))
                        if (firstLevel !== 'All' && secondLevel !== 'All') {
                            let foundOne = {}
                            data?.forEach(item => {
                                if (item.name_en === firstLevel) {
                                    foundOne = item
                                    return
                                }
                            })
                            let foundTwo = {}
                            foundOne?.children?.forEach(item => {
                                if (item.name_en === secondLevel) {
                                    foundTwo = item
                                    return
                                }
                            })
                            dispatch2({ type: 'setCategory', payload: [foundOne, foundTwo] });
                            handleFilterChange('type');
                        } else if (secondLevel === 'All' && firstLevel !== 'All') {
                            let foundOne = {}
                            data?.forEach(item => {
                                if (item.name_en === firstLevel) {
                                    foundOne = item
                                    return
                                }
                            })
                            dispatch2({ type: 'setCategory', payload: [foundOne] });
                            handleFilterChange('type');
                        }
                    }
                }
            } catch (error) {
                console.log('fetch error', error)
            }
        }
        getUserInfo()
    }, [])

    const ifShowDrawer = () => {
        setVisible(true)
        if(selectOne && selectOne !== "All"){
            setFinish(true)
        }

    }

    const closeShowDrawer = () => {
        setVisible(false)
    
    }



    const handleSelect = (value) => {
        setValue('All')
        if (value === 'All') {
            setLevelOne('All')
        } else {
            setLevelOne(value)
            category.forEach(item => {
                if (item.name_en === value) {
                    setSecondCategory(item.children)
                }
            })
            setLevelTwo('All')
        }
    }

    const handleSelectSecond = (value) => {
        setValue(value)
        setLevelTwo(value)
    }

    const handleSave = async () => {
        try {
            const res = await Post('/users/propool_category', {
                data: {
                    "category": `${levelOne},${levelTwo}`
                }
            })
            if (res && res.code === 2010) {
                setSelecrOne(levelOne)
                setSelectTwo(levelTwo)
                if (levelOne === 'All') {
                    dispatch2({ type: 'setCategory', payload: [] });
                    handleFilterChange('type');
                    setVisible(false)
                    if(currentPartion[0]?.type === 2){
                        dispatch2({ type: 'setPartition', payload: [] });
                      }
                    return
                } else if (levelTwo === 'All') {
                    let data = category.find(item => {
                        return item.name_en === levelOne
                    })
                    dispatch2({ type: 'setCategory', payload: [data] });
                    handleFilterChange('type');
                    setFinish(true)
                    if(currentPartion[0]?.type === 2){
                        dispatch2({ type: 'setPartition', payload: [] });
                      }
                } else {
                    let data = category.find(item => {
                        return item.name_en === levelOne
                    })
                    let data2 = secondCategory.find(item => {
                        return item.name_en === levelTwo
                    })
                    dispatch2({ type: 'setCategory', payload: [data, data2] });
                    handleFilterChange('type');
                    setFinish(true)
                    if(currentPartion[0]?.type === 2){
                        dispatch2({ type: 'setPartition', payload: [] });
                      }
                }

            }
        } catch (error) {
            console.log('fetch error:', error)
        }
    }

    const changeCategory = () => {
        setFinish(false)
        setValue('All')
        setSecondCategory([])
        setLevelOne('All')
        setLevelTwo('All')
    }

    return (
        <>
            <div id="findSupplier" className={styles.left} onClick={ifShowDrawer}>
                <img className={finish ? styles.img : styles.img1} src={bannerimg} alt="" />
            </div>
            {
                !finish ?

                    <div className={visible ? styles.container : styles.container1} >
                        <div className={styles.container_left}>
                            <div className={styles.top}>
                                <div className={styles.top_left}>
                                    <div>Business Intellingence (BI)</div>
                                </div>
                                <div onClick={closeShowDrawer} className={styles.top_right}><img src={arrow} alt="" /></div>
                            </div>
                            <div className={styles.middle}>
                                <div className={styles.img}>
                                    Trending products will be provided regarding to the category you selected
                                </div>
                                <div className={`${styles.country} country`}>
                                    <div style={{ marginBottom: '8px' }}>Main category</div>
                                    <Select style={{ width: 250 }} getPopupContainer={triggerNode => triggerNode.parentNode} onSelect={handleSelect} defaultValue='All'>
                                        <Option value='All'>All</Option>
                                        {category.map((item, index) => {
                                            return <Option value={item.name_en} key={item.name_en}>{item.name_en}</Option>
                                        })}
                                    </Select>
                                </div>
                                <div className={`${styles.shipping} shipping`} >
                                    <div style={{ marginBottom: '8px' }}>Sub Category</div>
                                    <Select style={{ width: 250 }} getPopupContainer={triggerNode => triggerNode.parentNode} value={value} onSelect={handleSelectSecond} disabled={levelOne === 'All' ? true : false}>
                                        <Option value='All'>All</Option>
                                        {secondCategory.map((item, index) => {
                                            return <Option value={item.name_en} key={item.name_en}>{item.name_en}</Option>
                                        })}
                                    </Select>
                                </div>
                            </div>
                            <div className={styles.bottom}>
                                <div className={styles.button} onClick={handleSave}>
                                    <Button className={styles.save}>SAVE</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className={visible ? styles.container : styles.container1} >
                        <div className={styles.inContainer}>
                            <div className={styles.top}>
                                <div className={styles.top_left}>
                                    <div>Congratulations</div>
                                </div>
                                <div onClick={closeShowDrawer} className={styles.top_right}><img src={arrow} alt="" /></div>
                            </div>
                            <div className={styles.finishMiddle}>
                                <div style={{ marginBottom: '5px' }}>The current category of concern is </div>
                                <div className={styles.select} style={{ marginBottom: '12px' }}>
                                    <div>{selectOne}</div>
                                    {
                                        selectTwo === 'All' ?
                                        null 
                                        :
                                        <div>{'>'}&nbsp;{selectTwo}</div>
                                    }
                                </div>
                                <div>you still can  <span className={styles.change} onClick={changeCategory}>Change Category</span></div>
                                <div className={styles.contact}>Feel free to contact us anytime at </div>
                                <a className={styles.finishEmail} href={'mailto:issac@dserspro.com'}>issac@dserspro.com</a>
                            </div>
                            <div><i class="material-icons" style={{ color: '#4CAF50', fontSize: '60px'}}>check_circle</i></div>
                        </div>
                    </div>
            }
        </>
    )
}

export default FindSupplierAd