
    export default 
    {
    "archeved": {
        "title": "Archivierte Bestellungen",
        "blank_tips": "Die Bestellung wurde auf Shopify archiviert. Wenn Sie sie ändern müssen, gehen Sie bitte zu Shopify, um sie zu bearbeiten.",
        "dec": "Bestellungen, die länger als 4 Monate zurückliegen, werden auf die Registerkarte Archivierte Bestellungen übertragen und Sie können die Archivierung der Bestellung aufheben, um weitere Aktionen durchzuführen. Bitte klicken Sie hier, um mehr zu erfahren.",
        "customer": "Kunde",
        "email": "E-Mail",
        "unarchive": "Archivierung aufheben",
        "unarcheved_succ": "Die Bestellung wurde erfolgreich aus dem Archiv entfernt. Sie können die Bestellung jetzt auf der Registerkarte Offene Bestellung finden.",
        "unarcheved_fail": "Die Bestellung konnte nicht archiviert werden, bitte versuchen Sie es später noch einmal.",
        "table_title_order": "Bestellung",
        "date_title": "Archiviertes Datum:"
    }
}