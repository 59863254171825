/**
 * 检测是否安装 dsers chrome extension
 *
 * @returns Promise
 */
const checkDsersExtension = () => {
  // 开发环境和生产环境 id
  let EXTENSION_ID =
    process.env.BUILD_TARGET !== 'prod'
      ? 'ncgifgjnnfmioaoegcebnfajjecmefha'
      : 'mmanaflgaempokjfbeeabkadnkoidjam';

  // 暴露一个修改 ID 的机会，便于测试
  const testId = window.sessionStorage.getItem('happy_extension_test');
  if (testId) {
    EXTENSION_ID = testId;
  }

  // eslint-disable-next-line
  return new Promise((resolve, reject) => {
    try {
      // eslint-disable-next-line
      chrome.runtime.sendMessage(EXTENSION_ID, 'hello_dsers', response => {
        // 检测chrome runtime报错
        if (chrome.runtime.lastError) {
          console.log(chrome.runtime.lastError.message);
        }
        if (!response) {
          console.log('No Dsers extension');
          reject();
        } else {
          console.log('Dsers Extension version: ', response.data.version);
          resolve(response.data.aeCookie);
        }
      });
    } catch (error) {
      console.log('check Dsers extension error:', error.message);
      reject(error);
    }
  });
};

export default checkDsersExtension;
