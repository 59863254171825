import React, { useEffect, useState, useRef } from 'react';

import {
  Icon,
  Modal,
  Spin,
  Button
} from 'antd';
import intl from 'react-intl-universal';

import check2 from '../../../../assets/bargain/check_2.svg';
import styles from './index.less';
import percent1 from '../../../../assets/bargain/percent_5.png';
import percent2 from '../../../../assets/bargain/percent_10.png';
import percent3 from '../../../../assets/bargain/percent_15.png';
import service from '../../../../assets/bargain/service.png';
import { v4Map, getVersion } from '../../../../utils/bannerWhiteList';

const percentMap = {
  '5': percent1,
  '10': percent2,
  '15': percent3
};

const SwitchV4 = ({
  oneClickMapping,
  handleModalCancel,
  state,
  compareProducts,
  dispatch,
  setShow,
  show,
  cancel,
  userId,
  handleSwitch,
  Arrow,
  CompareProduct,
  isAdvancedMapping,
  userInfo,
  number
}) => {
  const info = v4Map[userId];
  let precent = 0;
  let precentImg = percent1;
  let name = 'Shirley';
  let email = 'zhangling@dserspro.com';
  if (info) {
    precentImg = percentMap[info[0]];
    name = info[1];
    email = info[2];
    precent = info[0];
    console.log('name', name);
  }
  const href = 'mailto:' + email;

  return (
    <>
      <Modal
        visible={oneClickMapping.visible}
        footer={null}
        width={560}
        maskClosable={false}
        keyboard={false}
        onCancel={handleModalCancel}
        bodyStyle={{ padding: '36px 36px' }}
        destroyOnClose
        className={styles.onClickMappingModal_v4}
        zIndex={1100}
      >
        <Spin spinning={state.loading}>
          <>
            <div className={styles.body}>
              <div className={styles.compareContainer}>
                <div className={styles.mainTitle}>
                  <span className={styles.title2}>Wholesale Price offer</span>
                </div>
                <div className={styles.cardCon}>
                  {compareProducts.map((item, index, arr) => {
                    return (
                      <React.Fragment key={index}>
                        {index === 0 ? (
                          <Arrow
                            percent={oneClickMapping.extraData.percent}
                            compareItem={arr[1]}
                            dsersItem={arr[0]}
                            userId={userId}
                          />
                        ) : null}
                        <CompareProduct {...item} />
                      </React.Fragment>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={styles.footer}>
              {!isAdvancedMapping ? (
                <Button
                  className={styles.button}
                  type="primary"
                  onClick={() => {
                    setShow(true);
                    dispatch({
                      type: 'global/closeOneClickMapping',
                      callback: oneClickMapping.callback,
                      payload: oneClickMapping.extraData
                    });
                    dispatch({
                      type: 'global/dataTrack',
                      payload: {
                        data: {
                          user_id: userInfo.user_id,
                          store_id: oneClickMapping.extraData.store_id.toString(),
                          platform: 'shopify',
                          page: '',
                          page_url: window.location.href,
                          module: 'oneClickMapping',
                          action: 'apply-button-click',
                          product_id_list: [''],
                          order_id_list: [''],
                          custom_info: [
                            {
                              name: 'switch_version',
                              value: getVersion(userInfo.user_id)
                            },
                            {
                              name: 'ae_product_id',
                              value: oneClickMapping.extraData.ae_product_id.toString()
                            },
                            {
                              name: 'dsers_product_id',
                              value: oneClickMapping.extraData.dsers_product_id.toString()
                            },
                            {
                              name: 'dsers_product_id',
                              value: oneClickMapping.extraData.dsers_product_id.toString()
                            },
                            {
                              name: 'shopify_product_id',
                              value: oneClickMapping.extraData.shopify_product_id.toString()
                            },
                            {
                              name: 'mapping_type',
                              value: oneClickMapping.extraData.mapping_type.toString()
                            }
                          ]
                        }
                      }
                    });
                  }}
                  loading={state.switchLoading}
                  disabled={state.checkedList.length === 0}
                >
                  LEARN MORE
                </Button>
              ) : (
                <div style={{ textAlign: 'right' }}>
                  <a
                    href={
                      (oneClickMapping.extraData &&
                        oneClickMapping.extraData.dsers_url) ||
                      '/app/find_suppliers'
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    data-category="productBargain"
                    data-action="Check product on Ali"
                    onClick={() => {
                      window.dataLayer.push({
                        event: 'compareProductMassEvent',
                        type: 'clickIssue'
                      });
                    }}
                  >
                    <Button className={styles.button}>
                      {intl.get('bargain.one_click.check')}
                    </Button>
                  </a>
                </div>
              )}
            </div>
          </>
        </Spin>
      </Modal>

      <Modal
        visible={show}
        footer={null}
        width={480}
        maskClosable={false}
        keyboard={false}
        onCancel={cancel}
        bodyStyle={{ padding: '24px 24px' }}
        destroyOnClose
        className={styles.confirmWindowV4}
        zIndex={1101}
        centered
      >
        <div className={styles.confirmCon}>
          <div className={styles.title}>
            Reliable supplier at Wholesale price
          </div>
          {/* <div className={styles.subTitle}>Enjoy the following advantages when click CONFIRM</div> */}
          <div className={styles.subTitle2}>Enjoy the following advantages when click CONFIRM</div>
          <div className={styles.declareList}>
            <div className={styles.list}>
              <img className={styles.circle} src={check2} />
              <div className={styles.listOne}><strong>Saving</strong><br/> Save ${number} at {oneClickMapping.extraData?.percent || 0} regarding DSers ranking</div>
            </div>
            <div className={styles.list}>
              <img className={styles.circle} src={check2} />
              <div className={styles.listOne}><strong>Selected manufacture</strong><br/> Top manufactures verified by AliExpress</div>
            </div>
            <div className={styles.list}>
              <img className={styles.circle} src={check2} />
              <div className={styles.listOne}><strong>Policy</strong><br/> 100% refund policy in case unsuccessfully delivery</div>
            </div>
            <div className={styles.list}>
              <img className={styles.circle} src={check2} />
              <div className={styles.listOne}><strong>Mapping</strong><br/> Product link from verified manufacture will auto replace the mapping</div>
            </div>
            <div className={styles.list}>
              <img className={styles.circle} src={check2} />
              <div className={styles.listOne}><strong>Account manager</strong><br/> Personal account manager to assist you</div>
            </div>
          </div>
          <div className={styles.supportTip}>
            DSers Mass Supply team <a href="mailto:supply-support@dserspro.com">Supply-support@dserspro.com</a>
          </div>
          <div className={styles.confirmButton}>
            <Button onClick={handleSwitch}>CONFIRM</Button>
          </div>
        </div>
        <div className={styles.supportCon}>
          <div className={styles.support}>
            <div className={styles.serviceCon}>
              <img className={styles.service} src={service} />
            </div>
            <div className={styles.title}>Miss <strong>{name}</strong></div>
            <div className={styles.title}>is senior account manager</div>
            <div className={styles.declareList}>
              <div className={styles.list}>
                <div className={styles.listOne}>-<strong>5</strong> years supply service experience</div>
              </div>
              <div className={styles.list}>
                <div className={styles.listOne}>-<strong>Home</strong> category expert</div>
              </div>
              <div className={styles.list}>
                <div className={styles.listOne}>-<strong>Excellent</strong> communication</div>
              </div>
            </div>
            <div className={styles.text}>
              Talk to her today<br/>
              <a className={styles.email} href={href}>
                {email}
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SwitchV4;
