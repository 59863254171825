
    export default 
    {
    "product": {
        "dsers_verified_banner": "<h2>DSers <span>Verified</span> Ali Suppliers Project </h2><p>With Payment Solution. Keine gesperrte Kreditkarte mehr</p>",
        "dsers_verified_banner_btn": "MEHR LERNEN",
        "search_placeholder": "Mein Produkt hier suchen",
        "search_btn": "OK",
        "tab_all": "Alle <i>({all_num})</i>",
        "tab_aliexpress": "AliExpress <i>({ae_num})</i>",
        "tab_tmall": "Tmall <i>({tm_num})</i>",
        "tab_other": "Nicht zugeordnete Produkte <i>({map_num})</i>",
        "tab_verified": "Geprüft",
        "btn_import_shopify_products": "Shopify-Produkte importieren",
        "btn_import_shopify_products_tip": "DSers synchronisiert derzeit nur Produkte, die auf Shopify bestellt wurden, andere Produkte können Sie importieren, indem Sie auf die Schaltfläche Produkt verwalten auf DSers klicken.",
        "btn_import_shopify_products_tip_link": "Lesen Sie diesen Artikel",
        "select_source": {
            "all": "Alle",
            "unPushed": "Nicht gepusht",
            "pushed": "Gepusht"
        },
        "push_btn": "Pushen",
        "delete_btn": "Löschen",
        "delete_selected_products": "Ausgewählte Produkte löschen",
        "hide_selected_products": "Ausgewählte Produkte ausblenden",
        "product": {
            "remove_from_shopify": "Aus Shopify entfernt",
            "tip_deleted_on_Shopify": "Dieses Produkt wurde auf Shopify gelöscht. Bestellungen mit diesem Produkt können jedoch weiterhin auf DSers bearbeitet werden. Sie können dieses Produkt löschen, indem Sie auf das Papierkorbsymbol klicken, wenn Sie es dauerhaft löschen möchten.",
            "hidden": "Versteckt",
            "tip_hidden": "Dieses Produkt wurde versteckt. Sie können dieses Produkt nicht in Ihren Bestellungen sehen und es wird bei Bestellungen auf AliExpress nicht verarbeitet. Sie können auf die Schaltfläche Auge klicken, um es wieder einzublenden.",
            "verified_btn_apply": "ANWENDEN",
            "verified_btn_edit": "EDITIEREN",
            "verified_btn_enable": "AKTIVIEREN",
            "sample_on_way": "Ihr Muster ist auf dem Weg",
            "piece_tip": "Die Kosten sind der Preis, der auf der AliExpress-Lieferantenseite angezeigt wird. Dieser kann sich von dem auf AliExpress angezeigten Preis unterscheiden, da DSers den Grundpreis unabhängig vom Land, in das Sie versenden, anzeigt.",
            "piece": "Stück",
            "processing": "24h Bearbeitung",
            "cost": "Kosten",
            "price": "Preis",
            "orders": "Bestellungen",
            "stock": "Lagerbestand",
            "delete": "Löschen",
            "check_details": "Details prüfen",
            "edit_details": "Produkt bearbeiten",
            "open_in_shopify": "In Shopify öffnen",
            "publish_firsh": "Erst veröffentlichen, dann in Shopify öffnen",
            "split": "Split product",
            "hidden_tip": "Sie werden das Produkt auf der Bestellseite sehen.",
            "hidden_open_tip": "Klicken Sie, um dieses Produkt auszublenden und es nicht mit Ihren Bestellungen zu verarbeiten.",
            "mapping_Page": "Mapping Seite",
            "mapping_tip": "Erst veröffentlichen, dann Lieferanten hinzufügen",
            "push_to_shopify": "Zu Shopify übertragen",
            "import_to_store": "In den Shop importieren",
            "no_out_stock": "Variante nicht auf Lager",
            "multiple": "Mehrere",
            "download_all_img": "DOWNLOAD ALL"
        },
        "manage_product": {
            "title": "Select product and set up shipping method",
            "title1": "Import Shopify Products",
            "search_placeholder": "Produkte finden...",
            "no_data": "Keine Daten",
            "syncing": "Synchronisierung",
            "import_btn": "Importieren",
            "select_max_tip": "Sie können nur maximal 10 Produkte pro Mal importieren",
            "import_max_tip": "Sie können maximal 10 Produkte pro Zeiteinheit importieren.",
            "import_shopify_Copywriting": "Ups, das Laden hat wohl zu lange gedauert, bitte versuchen Sie es noch einmal",
            "bulk_import": "Wenn Sie eine große Anzahl von Produkten importieren möchten, können Sie sich an den <a href=\"https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2FDSersOfficial\" target=\"_blank\" rel=\"noopener noreferrer\">Kundenservice wenden</a> und wir werden Ihnen helfen.",
            "nombre": "If you have a large number of items to import, you can <a href=\"https://www.messenger.com/t/DSersOfficial\" dd=\"src\" target=\"_blank\">contact customer service</a> and we will help you."
        },
        "visible_succ": "Erfolgreicher Vorgang",
        "already_imported": "Sie haben dieses Produkt bereits importiert, Sie können es nicht erneut importieren.",
        "copy_first_pushed": "Öffnen fehlgeschlagen, das Produkt muss erst in Shopify gepusht/veröffentlicht werden",
        "succ_copy": "Erfolgreich kopiert",
        "copy_failed": "Kopie fehlgeschlagen",
        "update_succ": "Update erfolgreich!",
        "delete_tip": "Sind Sie sicher, dass Sie dieses Produkt löschen möchten?",
        "delete_pushed_tip": "<span className={ styles.deleteConfirm }>Gelöschte Produkte</span> können <strong>NICHT WIEDERHERGESTELLT</strong> werden und werden <strong>AUS IHREM SHOPIFY-STORE GELÖSCHT</strong>. Möchten Sie fortfahren?",
        "delete_confirm": "Sind Sie ganz sicher, dass Sie diese Aktion durchführen möchten? Es ist ENDGÜLTIG und Ihr(e) Produkt(e) wird (werden) auch aus Ihrem Shopify-Shop gelöscht!",
        "input_confirm_tip": "Zum Löschen bitte BESTÄTIGEN eingeben.",
        "open_mapping_tip": "Erst veröffentlichen, dann Lieferanten hinzufügen",
        "no_data": {
            "add_shopify_store": {
                "title": "Einen Shopify-Shop hinzufügen",
                "description": "Fügen Sie Shopify-Shop(s) zu DSers hinzu, indem Sie auf Link to Shopify klicken, um Ihre Produkte zu verwalten.<br /> Verwalten Sie Ihre Produkte und ordnen Sie ein AliExpress-Produkt zu, bevor Sie mehrere Bestellungen tätigen"
            },
            "is_sync": "Synchronisierung der Shopify-Informationen, bitte warten Sie, bis die Synchronisierung abgeschlossen ist.",
            "no_data": "Starten Sie Ihr Geschäft mit DSers, klicken Sie",
            "no_data_end": "um zu sehen, wie man Produkte von AliExpress in DSers importiert!",
            "has_store": {
                "title": "DSers zeigt nur die auf Shopify bestellten Produkte an und synchronisiert sie automatisch. <br />Aber keine Sorge, Sie können immer noch Produkte, die Sie auf DSers anzeigen möchten, aus Ihrer Shopify-Produktliste importieren, indem Sie auf <br />",
                "btn": "Produkt verwalten"
            },
            no_product_tip_1:`1. Klicken Sie auf "Shopify-Produkt importieren" in der oberen rechten Ecke, um Shopify-Produkte in DSers zu importieren.`,
            no_product_tip_2:`2. Ordnen Sie die importierten Produkte zu, damit Sie Bestellungen schneller bearbeiten können`
        },
        "visible_progress_title1": "Die Produkte sind in Ihrem Shopify-Shop sichtbar.",
        "visible_progress_title2": "Bestellung auf AliExpress...",
        "show_supply_chain": {
            "btn": "MEHR LERNEN",
            "description": "Keine gesperrte Kreditkarte mehr 100% <br />\n\t\t\tgarantierte Lieferung <br />\n\t\t\tAuslieferung innerhalb von 3 Tagen<br />\n\t\t\t Echtzeit-Kundenservice<br />",
            "title": "DSers <i>Verified<i> Ali Suppliers Project"
        },
        "show_balance_no": {
            "btn": "BEZAHLEN ",
            "tip": "Verfügbares DSers-Guthaben",
            "title": "Es ist kein Guthaben auf <br /> Ihrem Konto verfügbar"
        },
        "show_address_no": {
            "btn": "Zu den Einstellungen gehen",
            "tip": "Bitte geben Sie Ihre Adressdaten ein und stellen Sie sicher, dass der Saldo größer als 0 ist."
        },
        "show_set_visible": {
            "tip": "Auch im Online Store veröffentlichen",
            "continue_selling": "Verkauf fortsetzen, wenn nicht auf Lager",
            "description": "Das/die Produkt(e) werden in den Shopify-Shop übertragen und können nicht mehr auf DSers bearbeitet werden. Aber Sie können immer noch Lieferanten verwalten",
            "cancel": "Abbrechen",
            "yes": "Ja"
        },
        "hide_prod_tip": "Sind Sie sicher, dass Sie das Produkt ausblenden möchten? Bestellpositionen mit diesem Produkt werden auf DSers nicht mehr angezeigt und Sie können die ausgeblendeten Positionen nicht mehr mit DSers synchronisieren.",
        "confirm_prod_tip": "Bitte geben Sie BESTÄTIGEN ein, um das Produkt auszublenden.",
        "possible_cause": "Mögliche Ursache:",
        "search_notice_for_migrate": "Bitte geben Sie hier keine Url ein. Wenn Sie Produkte importieren möchten, gehen Sie bitte zur Importliste",
        "search_msg_for_migrate": "Bitte gehen Sie zur Importliste, um Produkte zu importieren. Klicken Sie <a href=\"https://help.dsers.com/import-list-for-shopify/\" target=\"_blank\"><strong>hier</strong></a>, um weitere Informationen zu erhalten.",
        "filter_push_title": "Nach Push filtern",
        "to_push_shopify": "Bereits zu Shopify übertragen",
        "no_push_shopify": "Nicht zu Shopify übertragen",
        "selectAll": "Wählen Sie alle",
        "hide": "ausblenden",
        "del": "Löschen",
        "myProducts": "Meine Produkte",
        "input_confirm_tip2": "Bitte geben Sie BESTÄTIGEN ein, um die Aktualisierung zu starten.",
        "introduce_con1": "You are about to delete the selected product(s) from DSers. But the products will be kept on Shopify.",
        "introduce_con2": "You are about to delete the selected product(s) from DSers. And the products will also be deleted from Shopify store.",
        "introduce_checkbox": "Delete this product from my Shopify store too.",
        "introduce_ok": "BESTÄTIGEN",
        "introduce_cancle": "Cancel",
        "cost_ible": "Cost $ ——",
        "loading": "Replacing",
        "error": "Failed to replace",
        "success": "Successfully replaced",
        "collections": "Search the full collection's title.(Case insensitive)",
        "product_type": "Typ auswählen oder a Shopify erstellen",
        "tags": "Tag auswählen oder a Shopify erstellen",
        "vendor": "Anbieter auswählen oder erstellen",
        "filterByMapping": "Filter by Mapping",
        "basic_mapping": "Basic",
        "standard_mapping": "Standard",
        "advance_mapping": "Advanced",
        "bogo_mapping": "BOGO",
        "bundle_mapping": "Bundle",
        "productOutOfStock": "Product out of stock",
        "productNotFound": "Product not found",
        "filterByNotification": "Nach Benachrichtigung filtern",
        "filterByHidden": "Nach Status filtern",
        "filterByVariants": "Variants out of stock",
        "filterBySku": "SKU auf AliExpress geändert",
        "filterByGone": "Produkte vom Anbieter verschwunden",
        "filterByHid": "Versteckte Produkte",
        "filterByShopifyDelete": "Entfernt von Shopify",
        "is_main_product": "Original unsplit product",
        "updatePrice": "While checking the data, we found that the price of the product you migrated from Oberlo was originally a non-US price, but due to an internal error, the price was migrated as a US price. We are very sorry for this.\n\tIn order to correct this error in time and not cause trouble and loss to your subsequent operations, we now provide a dedicated feature to modify the products in your account to the price of the destination country you want. (Only items with U.S. prices can be modified, and each item can only be modified once. If you do not need this service, you can ignore this message. This feature will only be available for one month)",
        "update": "Update price",
        "chooseCountry": "Choose a country",
        "chooseSub": "Select the country for which you want to update prices",
        "update_desc_wait": "products are being updated",
        "update_desc_ok": "updates successful",
        "update_desc_fail": "updates failure",
        "error_one": "Your product is being updated, as your account is being accessed by someone else and the update operation has been performed",
        "error_two_title": "None of the products you selected can be updated. ",
        "error_two_sub": "1. The price of the product is not set for the United States. 2. Your product is being updated, as your account is being accessed by someone else and the update operation has been performed 3. The product has been updated already.",
        "selectCountry": "Select country",
        "price_for": "Preis für",
        "collection_title": "Sammlungen",
        "type_title": "Typ",
        "tags_title": "Tags",
        "vendor_title": "Anbieter",
        "productLimitTitle": "Shopify Produkte begrenzen",
        "productLimitSub": "Die Anzahl der Produkte, die in Meine Produkte importiert werden können.",
        "aeLimitTitle": "Limit für Lieferantenprodukte",
        "aeLimitSub": "Summe der importierten Produkte in der Importliste und der Anzahl der Produkte in der Zuordnung \"Meine Produkte\".",
        "productCostToShopify": "Produktkosten an Shopify senden",
        'productCostAndShippingCostToShopify':'Produkt- und Lieferkosten an Shopify senden',
        "productCostAe": "Senden Sie die Lieferanten-Produktkosten pro Artikel an Shopify, wenn Sie das Produkt pushen.",
        remove_all_imgs:'remove all images'
    }
}