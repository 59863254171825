import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'



export default class CountDownTime extends React.Component {
  constructor(props){
    super(props)
    this.state={
      msg:""
    }

  }
  timeTransition = (ms) => {
      let maxtime = ms / 1000; //按秒计算
      let timer = null;
      let _this = this;
      setTimeout(function f(){
          if (maxtime >= 0) {
              let time = Math.floor(maxtime / 3600 % 24);
              let minutes = Math.floor(maxtime / 60 % 60);
              let seconds = Math.floor(maxtime % 60);
              time < 10 ? time = '0' + time : time = time;
              minutes < 10 ? minutes = '0' + minutes : minutes = minutes;
              seconds < 10 ? seconds = '0' + seconds : seconds = seconds;
              let msg = time + ':' + minutes + ':' + seconds
              _this.setState({
                  msg
              });
              --maxtime;
          } else{
              _this.props.sendEndTime(false)
              clearTimeout(timer);
              return;
          }
          timer = setTimeout(f,1000);
      });
  }

  componentDidMount(){
    setTimeout(()=>{
      let time = localStorage.getItem('lastTime')
      this.timeTransition(time - +new Date())
    })
  }

  render(){
    return <div>{this.state.msg}</div>
  }
}
