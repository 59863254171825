import React, { useState, useEffect } from 'react';
import { connect } from 'dva';
import { routerRedux, Link } from 'dva/router';
import { Layout, Menu, Icon, Dropdown, Avatar } from 'antd';
import intl from 'react-intl-universal';

import moneyORange from 'assets/svg/partner/money-orange.svg';
import moneyGrey from 'assets/svg/partner/money-grey.svg';
import peopleGrey from 'assets/svg/partner/people-grey.svg';
import peopleORange from 'assets/svg/partner/people-orange.svg';
import Logo from 'assets/svg/partner/partner-logo.svg';

import { defaultSelected as defaultNavigation } from '../../until';

import styles from './partnerLayout.less';
import TopBanner from '../topBanner/Banner'
const { Header, Content, Sider } = Layout;
const PartnerLayout = ({ children, loginInfo, dispatch, Partner }) => {
  const [icon, setIcon] = useState(true);

  useEffect(() => {
    if (!loginInfo && dispatch) {
      const path = window.location.pathname;
      if (path !== '/app/partner/login') {
        dispatch(routerRedux.push('/partner/login'));
      }
    }
  }, [loginInfo, dispatch]);
  const defaultSelected = type => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    type === '1' ? setIcon(true) : setIcon(false);
    defaultNavigation({ defaultDide: [type] });
  };
  const handleMenuClick = ({ key }) => {
    if (key === 'logout') {
      dispatch({
        type: 'partner/logout'
      });
    }
  };
  const partnerStore = JSON.parse(
    window.localStorage.getItem('partner-store') || '{}'
  );
  const { defaultDide = ['1'] } = partnerStore && partnerStore;
  const menu = (
    <Menu selectedKeys={[]} onClick={handleMenuClick}>
      <Menu.Item key="logout">
        <Icon type="poweroff" />
        Log out
      </Menu.Item>
    </Menu>
  );
  const headerName =
    JSON.parse(localStorage.getItem('partner-store')) ||
    Partner.partnerLoginInfo;
  return (
    <div className={styles.partner}>
      <Layout>
        <Header className={styles.header}>
          <div className="logo">
            <img src={Logo} alt="" />
          </div>
          <Dropdown overlay={menu}>
            <span className={`${styles.action} ${styles.account}`}>
              <Avatar
                size="small"
                className={styles.avatar}
                style={{
                  backgroundColor: '#fe8f24',
                  color: 'white',
                  marginRight: 7
                }}
              >
                {headerName?.name?.slice(0, 1).toUpperCase()}
              </Avatar>
              <span className={styles.name}>{headerName?.name}</span>
            </span>
          </Dropdown>
        </Header>
        <Content className={styles.partnerContent}>
          <Layout style={{ background: '#f4f4f4' }}>
            <Sider
              width={268}
              className={styles.sidebar}
              // collapsible
              // collapsed={this.state.collapsed}
            >
              <Menu
                // theme="dark"
                mode="inline"
                defaultSelectedKeys={defaultDide || ['1']}
                defaultOpenKeys={['sub1']}
                style={{ height: '100%' }}
                className={styles.links}
              >
                <Menu.Item key="1">
                  <Link
                    to="/partner/home"
                    onClick={() => defaultSelected('1')}
                    replace={location.pathname === '/partner/home'}
                    data-link="/partners/home"
                    style={{ fontSize: 18 }}
                    className={icon ? styles.linkSelect : ''}
                  >
                    {/* <Icon type="dollar" /> */}
                    <img
                      src={icon ? moneyORange : moneyGrey}
                      style={{ margin: '-4px 15px 0 0' }}
                      alt=""
                    />
                    <span>{intl.get('partner.partner_layout.dashboard')}</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link
                    to="/partner/account"
                    onClick={() => defaultSelected('2')}
                    replace={location.pathname === '/partner/account'}
                    data-link="/partner/account"
                    style={{ fontSize: 18 }}
                    className={!icon ? styles.linkSelect : ''}
                  >
                    {/* <Icon type="user" /> */}
                    <img
                      src={icon ? peopleGrey : peopleORange}
                      style={{ margin: '-4px 15px 0 0' }}
                      alt=""
                    />
                    <span>{intl.get('partner.partner_layout.account')}</span>
                  </Link>
                  {/* <Icon type="user" /> */}
                  {/* <img src={goldCoin} alt="goldCoin" /> */}
                  {/* <i className={styles.personalIcon} /> */}
                  {/* <span>Account</span> */}
                </Menu.Item>
              </Menu>
            </Sider>
            <Content className="partner-content">
              <TopBanner></TopBanner>
              {children}
            </Content>
          </Layout>
        </Content>
      </Layout>
    </div>
  );
};

// eslint-disable-next-line no-shadow
const mapStateToProps = ({ partner }) => ({
  loginInfo: partner.loginInfo,
  Partner: partner
  // loadingUserInfo: login.loadingUserInfo
});

export default connect(mapStateToProps)(PartnerLayout);
