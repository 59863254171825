export default {
  home: {
    welcome: 'Bem vindo ao DSers!',
    orders_to_ae: 'orders to be placed on AliExpress', // d
    order_to_ae: 'order to be placed on AliExpress', // d
    move: 'Siga em frente',
    close: 'Close',
    open: 'Abrir',
    learn_more: 'SAIBA MAIS',
    tutorial2:'Tutorial',
    modal_title: {
      title: `New UI & New Beginning`,
      wenhou: `Dear DSers users,`,
      container_one: `First of all, thank you for your ongoing support to DSers. In order to provide users with a better experience, we specially worked with a professional design team to upgrade the UI of our DSers platform.`,
      container_two: `
      After six months of preparation, the new version of UI has been officially launched today, you can now start your new UI experience journey by clicking the button below.`,
      container_three: `
      Of course, we will keep the access to the old version of UI for you in case that you have any doubts or other considerations. After entering the page, you are free to switch between these two versions at any time as well.
       Both version will remain accessible for a certain period of time, depending on the user's feedback. We will notify you in advance of any further changes.`,
      container_four: `
      Thank you again for your constant support to DSers, which will be the motivation for us to keep optimizing and become a better DSers platform.
      `,
      go_new_btn: `Start experiencing the new UI`,
      use_old: `Continue using legacy UI`
    },
    tab_title: {
      add_shopify_store: 'Adicionar loja do Shopify',
      aliExpress_account: 'Conta do Aliexpress',
      white_list: 'Whitelist',
      setting_shipping: 'Configurar envio',
      mapping_product: 'Mapear produtos',
      chrome_extension: 'Extensão do Chrome',
      bulk_order_function: 'Função Pedidos em Massa'
    },
    add_shopify_store: {
      title: 'Conecte sua loja do Shopify:',
      p1: 'Importe produtos para a loja do Shopify rapidamente',
      p2: 'Sincronize pedidos dos últimos 7 dias do Shopify',
      p3: 'Ir',
      p4: 'Configurações > Outro > Sincronizar pedidos do Shopify',
      p5: 'para sincronizar pedidos mais antigos',
      btn: 'Conectar'
    },
    aliExpress_account: {
      title: 'Vincule ao AliExpress <br/> para:!',
      p1: 'Fazer múltiplos pedidos automaticamente em 1 clique',
      p2: 'Melhorar a eficiência em 97%',
      p3: 'Rastrear informações de frete',
      p4: 'Make sure your AliExpress account linking like below:',
      btn: 'Link'
    },
    white_list: {
      title: 'Solicite uma conta verificada no AliExpress <br/> para:',
      p1: 'Fazer pedidos utilizando a API oficial do Aliexpress',
      p2: 'Remover o limite de pedidos e pagamentos',
      p3: 'Obter o serviço especial de dropshipping do AliExpress',
      btn: 'Solicitar'
    },
    chrome_extension: {
      title: 'Faça download da extensão <br/> do Chrome para:',
      p1: 'Migração da Oberlo com apenas um clique',
      p2: 'Importar produtos do AliExpress',
      p3: 'Múltiplos pagamentos no AliExpress',
      btn: 'Download'
    },
    more_about: 'Saiba mais sobre o DSers',
    video_title_mapping:
      'Como mapear produtos no Aliexpress antes de realizar múltiplos pedidos?',
    video_title_place_order:
      'Como realizar mais de 100 pedidos em poucos minutos',
    other_features: 'Uma visão rápida de outros recursos',
    video_title_add_store: 'Como adicionar uma loja do Shopify?',
    video_title_bind_ae: 'Como vincular e melhorar a conta do AliExpress?',
    video_title_import_products:
      'Como importar produtos para a loja do Shopify?',
    video_title_fulfill_manually: 'Como preencher pedidos manualmente?',
    bottom_line: 'Nós temos a linha de baixo',
    // 新版home
    home_title: 'Welcome to DSers, AliExpress official app',
    setup_account: 'Set up your DSers account',
    tutorial: 'TUTORIAL',
    product_importer: 'Product Importer',
    chrome_extension_title: 'CHROME EXTENSION',
    report: 'REPORT',
    check_report: 'Check your sales, spending and product ROI',
    feedback: 'Feedback',
    feedback_share: 'Share your feedback',
    feedback_useful: 'This information is very useful',
    feedback_not_useful: ' This information is not useful',
    feedback_what_to_know: 'Let us know what you would like to know',
    comments: 'Comments',
    share_feedback: 'Share feedback',
    new: 'New',
    dismiss: 'Dismiss',
    home_normal_card_learn_more_link: 'https://www.blog.dserspro.com/',
    go_modify:'Go modify',
    vat_error:`Dear user, the system has detected that the VAT ID you currently set contains non-numeric characters, which will cause your French order to fail (please note that the VAT ID you set is for the French order, other countries currently don't require it). To process the order smoothly, please go to Setting - Other - VAT to modify or clear it`,
    var_update:`If you do not modify it, we will automatically delete the VAT ID on the French orders`,
    modify_btn:`Modify`,
    var_update_setting:`The current VAT ID for French orders has been discontinued. To process the order smoothly, please modify it in time`,
    change_UI: `Change to old UI`,
    oldUI_out: {
      notion_title: 'New UI Notification',
      notion_con:'Access to the old UI will not be possible after <br /> October 15th',
      title: `Brand new UI,<br />brand new experience`,
      modal_text:`After nearly a month of user feedback and technical adjustments, the current new version of the UI has been improved. To unify the platform features, we will remove the access to the old UI on October 15th. Please be aware in advance. If you have any questions, feel free to <a style="color:blue" href='https://www.messenger.com/t/DSersOfficial' dd="src">contact our customer service</a> team.`
    },
    downloadguide:{
      guide:'Get the guide',
      text:'Click to download the<br /> dropshipping guide'
    },
    ShopifyOrders:'pedidos do Shopify',
    guidance:{
      steps:'passo',
      btn_complete:'CONCLUÍDO',
      title:'Conheça o DSers',
      desc:'4 passos para automatizar seu negócio de dropshipping',
      add_product: 'ADICIONAR PRODUTO',
      push_to_shopify: 'ENVIAR À SHOPIFY',
      suppiler_mapping: 'MAPEAMENTO',
      place_order: 'EFETUAR PEDIDO',
      add_product_title: 'Adicione seu primeiro produto',
      push_to_shopify_title: 'Envie produtos para sua loja Shopify',
      suppiler_mapping_title: 'Mapeamento: defina os fornecedores dos produtos',
      place_order_title: 'Processe seu primeiro pedido',
      add_product_desc: 'Adicione os produtos que você deseja vender em sua loja. É possível pesquisar produtos oferecidos pelo AliExpress ou DSers na aba “Encontrar fornecedores”. Clique <a href="https://help.dsers.com/import-product-from-aliexpress-for-shopify/" target="_blank" >aqui</a> para mais detalhes.',
      push_to_shopify_desc: 'Quando seus produtos estão no DSers, é possível editá-los e enviá-los à sua loja Shopify. Clique <a href="https://help.dsers.com/push-a-product-to-your-shopify-store/" target="_blank" >aqui</a> para mais detalhes.',
      suppiler_mapping_desc: 'Defina fornecedores do AliExpress dos seus produtos para processar pedidos futuros com facilidade. Clique <a href="https://help.dsers.com/basic-mapping-advanced-mapping-bogo-bundle-introduction-for-shopify/" target="_blank" >aqui</a> para mais detalhes.',
      place_order_desc: 'Você pode efetuar pedidos em grupo rapidamente e o DSers os gerenciará automaticamente após eles serem efetuados com sucesso.  Clique <a href="https://help.dsers.com/fulfill-an-order-from-start-to-finish-for-shopify/" target="_blank" >aqui</a> para mais detalhes.',
      tip_to_pc:'To ensure a better user experience, log in using a computer to operate DSers with the right conditions'
    },
    oberlo:{
      card_title_1:'Quase lá',
      card_title_des_1:'Finalize as configurações a seguir antes de processar pedidos e usar recursos avançados.',
      card_title_2:'Tutorial',
      card_title_des_2:'Veja este guia rápido para processar pedidos e importar produtos.',
      step_title_1:'Vincule sua conta do AliExpress',
      step_title_2:'Ative a assinatura DSers',
      step_title_3:'Instale a extensão DSers para Chrome',
      step_des_1:'Vincule sua conta do AliExpress agora para atualizar o status dos pedidos. Clique <a href="https://help.dsers.com/link-to-aliexpress/" target="_blank">aqui</a> e saiba mais.',
      step_des_2:'Aproveite recursos similares aos do Oberlo com as novas possibilidades avançadas do DSers. Clique <a href="https://help.dsers.com/oberlo-migration-compare-plans/" target="_blank">aqui</a> e saiba mais.',
      step_des_3:'Importação de produtos com um clique do AliExpress ao DSers para ganhar tempo e encontrar os itens mais vendidos! Clique  <a href="https://help.dsers.com/import-product-from-aliexpress-for-shopify/" target="_blank">aqui</a> e saiba mais.',
      // obPlan:'Activate DSers',
      // dsPlan:' to enjoy your current features in Oberlo',
      obPlan:'Ative o plano <i>{dsers}</i> do DSers e aproveite seus recursos atuais do plano <i>{oberlo}</i> do Oberlo.',
      btntext1:'VINCULAR AO ALIEXPRESS',
      btntext2:'Ativar plano',
      btntext3:'Ativarei mais tarde',
      btntext4:'DOWNLOAD',
      btntext5:'Ver mais',
      billing_error:'Não foi possível ativar o plano, porque sua loja de faturamento está indisponível. Altere a loja de faturamento em DSers - <a href="/app/select">“Gerenciar assinatura”</a> ou ative a loja de faturamento no',
      billing_error1:'Não foi possível ativar o plano, porque sua loja de faturamento está indisponível. Altere a loja de faturamento em DSers - <a href="/app/select">“Gerenciar assinatura”</a>.',
      billing_error_link:'painel do Shopify.',
      stepsdes:'passo(s) restante(s)',
      finish:'Concluído',
      year_error:'Se você ativou um novo plano, mas havia pago a taxa anual do plano DSers Standard, contate o atendimento ao cliente para receber o reembolso da parte não usada.',
      step_title_4:'Migrar dados do Oberlo',
      step_des_4:'Vincule sua conta do Oberlo e sincronize dados com o DSers.',
      stopProduct:`Os dados dos produtos não foram atualizados corretamente. Clique em “Tentar novamente” ou contate a equipe de <a href='https://www.messenger.com/t/DSersOfficial' target="_blank">suporte DSers</a>`,
      stopOrder:`O status do pedido não foi atualizado corretamente. Clique em “Tentar novamente” ou contate a equipe de <a href='https://www.messenger.com/t/DSersOfficial' target="_blank">suporte DSers</a>`,
      successProduct:`A atualização dos dados dos produtos foi concluída.`,
      successOrder:`Atualização de status do pedido concluída. Acesse a página de <a href='/app/order/list'>pedidos abertos</a> para ver os detalhes`,
      syncProduct:'Ainda estamos atualizando os dados dos produtos da Shopify...',
      syncOrder:`Ainda estamos atualizando o status dos pedidos do AliExpress. O DSers migrou os pedidos dos últimos dois meses. Caso você precise sincronizar mais pedidos, <a href='https://www.messenger.com/t/DSersOfficial' target="_blank">entre em contato conosco</a>.`,
      migrated:'MIGRATED',
      linked:'LINKED'
    },
    ShopifyOrders:'pedidos do Shopify',
    newGuide:{
      primary_title:`Guia de dropshipping`,
      primary_descriptions:`Exibiremos o conteúdo mais adequado de acordo com a sua seleção.`,
      second_title_1:`Nenhum produto, nenhum pedido`,
      second_description_1:`Sou um novato e não sei como usar o DSers para começar o meu negócio de dropshipping.`,
      second_title_2:`Eu tenho produtos, mas não tenho pedidos`,
      second_description_2:`Eu já tenho itens na Shopify, mas não sei como começar o meu negócio no DSers.`,
      second_title_3:`Eu tenho produtos e pedidos`,
      second_description_3:`Eu já usei soluções similares ao DSers, mas preciso aprender a usar os principais recursos do DSers.`,
      second_more_1_title_1:`What Is Dropshipping?`,
      second_more_1_title_2:`How Can You Start a Dropshipping Store with DSers?`,
      second_more_1_title_3:`Como importar produtos no recurso “Encontrar fornecedores”?`,
      second_more_1_title_4:`Como importar produtos na “Lista de importação”?`,
      second_more_1_title_5:`Como enviar produtos à Shopify após importá-los?`,
      second_more_2_title_1:`Ultimate Guide to 15 Evergreen Products to Dropship in 2023`,
      second_more_2_title_2:`Top 10 Trending Dropshipping Products for 2023`,
      second_more_2_title_3:`Como importar itens da Shopify para o DSers?`,
      second_more_2_title_4:`Como combinar o fornecedor certo a um produto?`,
      second_more_3_title_1:`How to Set a Proper and Lucrative Dropshipping Profit Margin?`,
      second_more_3_title_2:`A Step-by-Step Guide to Deal with Your First Dropshipping Order`,
      second_more_3_title_3:`Como efetuar um pedido no DSers?`,
      second_more_3_title_4:`How to set up the shipping template?`,
      Expand:`Expandir`,
      Leave:`COLAPSO`,
      more_videos:'mais vídeos'
    }
  }
};
