/*
 * Created Date: Tuesday May 18th 2021
 * Author: William (cweiliang.me@gmail.com)
 */

import React, { useEffect, useState } from 'react';
import qs from 'qs';
import { Button } from 'antd';

import bgImg from 'assets/img/auth-bg.png';
import styles from './ErrorPage.less';

import authFailedImg from 'assets/img/auth_failed.png';
import platformErrorImg from 'assets/img/platform_error.png';
import contactIcon from 'assets/img/contact_icon.png';
// import { postAuthWixStoreResult }  from 'services/auth';

const authImg = <img src={authFailedImg} style={{ width: 279 }} />;

const platformImg = <img src={platformErrorImg} style={{ width: 224 }} />;

export default function ErrorPage() {
  const [type, setType] = useState(0);

  useEffect(() => {
    const parse = qs.parse(location.search.slice(1));
    if (parse.type === 'platform-conflict') {
      setType(1);
      // postAuthWixStoreResult({ data:{ auth_result: 1, failed_reason: 6 }, isOutApi: true });
    }
  }, []);

  const handleLogout = () => {
    window.localStorage.removeItem('dser-pro-authority');
    window.localStorage.removeItem('store');
    window.localStorage.removeItem('storeId');
    window.location.href = '/app/login';
  };

  return (
    <div className={styles.page}>
      <div className={styles.content}>
        {type === 0 ? platformImg : authImg}
        <div className={styles.title}>Connection failed</div>
        {type === 0 ? (
          <p className={styles.info}>
            The currently logged in DSers account has been linked
            to the Wix platform, it currently cannot be linked to the
            Shopify platform. If you still need to link to the Shopify platform,
            you can <a href="/app/register">register</a> a new DSers account.
          </p>
        ) : null}
        {type === 1 ? (
          <p className={styles.info}>
            The currently logged in DSers account is already linked to
            Shopify/Woocommerce/CSV, it can not be linked to a Wix store at the
            moment. Please create a new DSers account to link your Wix store.
          </p>
        ) : null}
        <div className={styles.operation}>
          <Button
            onClick={() =>
              // 单独处理
              window.open('https://www.messenger.com/t/DSersOfficial', 'blank')
            }
            type="ghost"
          >
            <img src={contactIcon} style={{ width: 20, marginRight: 8 }} />
            CONTACT SUPPORT
          </Button>
          <Button onClick={handleLogout} type="primary">
            LOG OUT
          </Button>
        </div>
      </div>
    </div>
  );
}
