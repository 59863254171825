
    export default 
    {
    "archeved": {
        "title": "Ordini archiviati",
        "blank_tips": "L'ordine è stato archiviato su Shopify. Se vuoi modificarlo, vai su Shopify per poterlo operare.",
        "dec": "Gli ordini evasi da più di 4 mesi verranno trasferiti nella scheda Ordine archiviato e puoi ancora disarchiviare l'ordine per fare altre azioni. Clicca qui per saperne di più.",
        "customer": "Cliente",
        "email": "Email",
        "unarchive": "Disarchivia",
        "unarcheved_succ": "Ordine disarchiviato con successo, puoi trovare l'ordine nella scheda ordine Aperto ora.",
        "unarcheved_fail": "Non è stato possibile disarchiviare l'ordine, riprova più tardi.",
        "table_title_order": "Ordine",
        "date_title": "Data archiviata:"
    }
}