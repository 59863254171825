import React from 'react';
import { Route, Redirect } from 'dva/router';
import { connect } from 'dva';
import { Spin } from 'antd';

function PrivateRoute({ children, loginInfo, loadingUserInfo, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        // 自动登录时的loading, 防止渲染 login page
        if (loadingUserInfo) {
          return (
            <Spin
              size="large"
              style={{ width: '100%', margin: '180px auto' }}
            />
          );
        }
        if (loginInfo) {
          return children;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: location }
              }}
            />
          );
        }
      }}
    />
  );
}

const mapStateToProps = ({ login }) => ({
  loginInfo: login.loginInfo,
  loadingUserInfo: login.loadingUserInfo
});

export default connect(mapStateToProps)(PrivateRoute);
