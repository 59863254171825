import agency from './agency';

export default {
  setting: {
    tab_account: 'Conta', // add
    tab_package_tracking:"Rastreamento de pacote",
    tab_sub_account:'Conta de equipe',
    tab_sub_supplier:'Fornecedor',
    tab_stores:'Lojas',
    tab_product:'Produto',
    tab_pricing_rule: 'Regra de preço',
    tab_shipping: 'Envio',
    tab_order:'Pedido',
    tab_paypal:'PayPal',
    tab_tracking: 'Envio Confirmation',
    tab_notification: 'Notificação',
    tab_sync:'Auto Update',
    tab_aff:'Affiliate program',
    tab_billing_balance: 'Billing Balance', // d
    tab_verified_supplier: 'Verified Supplier', // d
    tab_report: 'Relatório',
    tab_other: 'Outro',
    choose_country_placeholder: 'Escolha um País',
    save: 'Salvar',
    account: {
      // add
      account_info: 'Informações da Conta',
      account_new_info: 'Alterar nome da conta',
      changeEmailName: `Alterar e-mail`,
      account_name: 'Nome da conta',
      account_email: 'E-mail da conta',
      change: 'Modificar',
      change_email_desc:
        'Este é o seu e-mail de login. Usaremos este e-mail para contatá-lo sobre sua conta',
      new_email: 'Novo endereço de e-mail',
      new_email_desc:
        'Enviaremos um código de verificação para o novo endereço de e-mail. Copie e digite abaixo para verificar sua caixa de entrada',
      new_email_err: 'Formato errado para e-mail',
      account_name_err: 'Please enter a username between 5 and 20 characters',
      verify_code: 'Entre o código para verificar',
      verify_code_desc:
        'Obtenha o código do nosso e-mail de verificação e digite aqui para ser verificado',
      verify_code_err: 'Código incorreto. Verifique novamente',
      reset_password: 'Resetar senha',
      reset_password_desc: 'Mude a senha que você usa para logar.',
      change_password: 'Mudar senha',
      shopify_stores: 'Lojas Shopify',
      shopify_stores_desc:
        'Você pode gerenciar suas lojas do Shopify aqui. Se você deseja desinstalar o DSers, não se esqueça de desvincular daqui também.',
      shopify_stores_re_authorized:
        'A loja precisa ser autorizada novamente. Favor atualizar a autorização. Caso contrário, você não poderá operar a loja corretamente com o DSers',
      shopify_stores_tip:
        'Atualizamos a autorização do Shopify. Clique para renová-la na página do aplicativo do Shopify. Caso contrário, afetará o preenchimento dos pedidos.',
      store_name: 'Nome da loja',
      status: 'Status',
      connected: 'Conectado',
      Connected:'Conectado',
      noData:'No linked store',
      disconnect: 'Desconectado',
      ae_account: 'Conta do AliExpress',
      ae_account_desc:
        'Você pode desconectar ou modificar a conta do AliExpress aqui.',
      account_id: 'ID da Conta',
      change_account_title: 'Trocar loja de faturamento',
      change_account_content:
        'Você está prestes a definir {choiceLabel} como a loja de faturamento. Para uma melhor experiência na plataforma DSers, mantenha essa loja sempre ativa',
      switch: 'Trocar',
      ae_account_tip:
        'Observe que, se você vincular a uma conta diferente, as informações dos pedidos feitos na conta atual não serão atualizadas para os DSers após desconectar.',
      click_to_renew: 'Click to Renew',
      disconnect_ae_tip1:
        'Tem certeza de que deseja desconectar sua conta do AliExpress? As informações dos pedidos feitos com a conta atual <i> não serão mais atualizadas </ no DSers após a desconexão',
      disconnect_ae_tip2:
        'Após desconectar, você descobrirá que os <i> dados da conta desaparecerão temporariamente. <i> Eles serão recuperados depois que você vincular a uma nova conta do AliExpress.',
      disconnect_ae_confirm: 'Por favor digite CONFIRMAR para desconectar.',
      reconnect_ae:
        'Certifique-se de reconectar-se à mesma conta. Caso contrário, as informações dos pedidos feitos com a conta atual não serão mais atualizadas para o DSers após a desconexão.',
      switch_ae:
        'Tem certeza de que deseja trocar de conta do AliExpress? As informações dos pedidos feitos com a conta atual <i> não serão mais atualizadas no DSers após a desconexão.',
      open_mailbox: 'Abrir caixa de e-mail',
      send_again: 'Enviar novamente',
      send_code: 'Enviar código',
      verify: 'Verificar',
      store_name_tip:
        'O DSers exibirá o nome original da loja quando você se registrou no Shopify e não atualizaremos o nome da loja, mesmo que você o tenha alterado no Shopify. Observe que o nome da loja não será revelado aos clientes e não afetará os pedidos do DSers.',
      verify_email_title: 'Verify your email address',
      verify_email_title_succ: 'Verification email sent',
      verify_email_title_fail: 'Verification email delivery failed',
      verify_email_desc:
        'To help keep your account secure, please verify your email address. We will send a verification email to {email}',
      verify_email_desc_succ:
        'Please verify your email address by following the link in the email sent to {email}',
      verify_send: 'Send verification email',
      verify_re_send: 'Resend verification email',
      white_list: 'Whitelist',
      white_listed: 'Whitelisted',
      code_verify: `Code is required`,
      jiabai_intro: `Add your AliExpress account to the whitelist`,
      ae_account: 'Conta do AliExpress',
      authorize_name: 'Atualizações',
      authorize_tips:'Atualize as permissões da Shopify. Parece que as permissões de recursos do DSers não estão atualizadas. Atualize-as com alguns cliques para garantir que o DSers funcione corretamente.'
    }, //  add
    shipping: {
      title: 'Envio',
      description:
        'Selecione seu método de envio padrão para o País específico.',
      turn_off: 'Desligar',
      turn_on: 'Ligar',
      save_succ: 'Salvo com Sucesso',
      save_failure: 'salvar falha',
      advanced: {
        title: 'Avançado',
        description:
          'Configure seu método de envio padrão por faixa de preço e prazo de entrega.',
        tracking_information_available: 'Informação de rastreamento disponível',
        delivery_period: 'Tempo de entrega',
        delivery_period_tip: 'período de entrega máximo aceitável',
        delivery_period_error: 'Por favor selecione o número de dias.',
        days: 'Days',
        shipping_cost: 'Custo de frete',
        shipping_cost_error: 'Por favor coloque o preço.',
        tip: 'Quando várias opções atendem às minhas condições',
        i_need: 'Eu preciso',
        less_cost: 'Custo menor',
        less_day: 'menos días'
      },
      general: {
        title: 'Adicionar método para envio Global',
        description: `Configure seu método de envio padrão para os países globais.
                      O sistema de pedidos automáticos escolherá o método de envio de acordo com sua configuração ao fazer pedidos.
                      A opção 1 será sua primeira escolha.`,
        placeholder: 'Adicionar mais métodos de envio',
        option: 'Opção'
      },
      product: {
        title: 'Adicione um método de frete global baseado em produto',
        dec:
          'Configure seu método de envio padrão para um produto AliExpress específico. A configuração com base no produto tem prioridade mais alta em comparação à configuração com base no país.',
        placeholder: 'Pesquisar por título do produto do fornecedor',
        modal_placeholder:
          'Pesquisar por palavras-chave do título do produto do fornecedor',
        tip: 'Somente os produtos já mapeados podem ser encontrados aqui',
        shortcut_open:
          'Você pode acessar este recurso rapidamente em “Mapeamento” – “Mais ações”.'
      }
    },
    tracking: {
      title: 'Rastreamento',
      description: 'Acione a confirmação de envio para uma loja diferente.',
      activate_email:
        'Acione o e-mail da notificação de processamento enviado pelo Shopify',
      activate_email_description: `Ative isto para enviar o e-mail de confirmação de envio do Shopify automaticamente para o seu cliente assim que o número de rastreamento for gerado.
                                  Você pode editar o template do e-mail`,
      here: 'aqui',
      activate_email_tip:
        'Este recurso ajudará a personalizar a transportadora e a URL de rastreamento no seu e-mail do Shopify.',
      shipping_carrier: 'Transportadora',
      custom_tracking_url: 'URL de rastreamento personalizada',
      custom_tracking_url_tip:
        'OBS: Adicionaremos um código de rastreamento ao final desta URL. Se você quiser usar a URL http://www.17track.net/en/track?nums=1234567 digite apenas http://www.17track.net/en/track?nums=',
      please_enter_a_url: 'Insira a URL de rastreamento.',
      add_a_shopify_store: 'Adicionar loja Shopify',
      save_succ: 'Configuração salva com sucesso.',
      save_fail: 'Falha ao salvar configuração.',
      track_to_paypal_title: 'Sincronizar número de rastreamento ao PayPal',
      track_to_paypal_title1: 'Gerenciar conta do PayPal',
      track_to_paypal_desc:
        'Autorize o DSers a adicionar números de rastreamento às suas transações no PayPal.',
      connect_to_paypal: 'Conectar ao PayPal',
      connect_to_paypal2: 'Clique no botão para conectar ao PayPal',
      connect_to_paypal_desc:'Para assegurar que seu número de rastreamento possa ser sincronizado normalmente, autorize a vinculação de sua conta do PayPal à loja Shopify',
      not_succ: 'Autorização malsucedida. <br />Tente novamente.',
      use_paypal_account: 'Ou use uma conta do PayPal autorizada:',
      to_connect_paypal_account: 'Select a PayPal account to connect to',
      paypal_placeholder: 'Selecione uma conta',
      paypal_reminder: 'Please authorize your PayPal account above',
      linked_paypal: 'Você está vinculado à seguinte conta do PayPal:',
      disconnect: 'Desconectado',
      disconnect_tip:
        'Você tem certeza que deseja desconectar sua conta do PayPal? Note que o número de rastreamento não será mais atualizado na conta do PayPal após desconectá-la.',
      update: 'Update',
      refresh_page: 'Atualize a página para atualizar',
      select_1: `Select a shipping carrier`,
      select_2: `Select a custom tracking URL`
    },
    notification: {
      title: 'Notificação',
      description:
        'Se você ativar este recurso, o DSers monitorará os produtos sem estoque, mudanças de preço, pedidos cancelados no AliExpress, alterações de SKU do produto e notificações serão enviadas à sua conta DSers.',
      inventory: 'Inventário',
      inventory_description:
        'Seja notificado quando o produto estiver esgotado ou não estiver mais disponível', // m
      price: 'Preço',
      price_description:
        'Seja notificado quando o preço do fornecedor mudar no Aliexpress',
      canceled_order: 'Cancelled order',
      canceled_order_description:
        'Get notified when an order is cancelled on AliExpress',
      sku: 'SKU changes',
      sku_description: 'Get notified when a product SKU changed on AliExpress',
      order: 'Pedido', // d
      order_description: 'Get notified when orders are canceled on AliExpress', //  d
      tip: `DSers notifications will be activated if you check the corresponding boxes and save.`,
      auto_updates: 'Atualizações Automáticos', //  a
      auto_updates_tip:
        'As atualizações não serão aplicadas a produtos de vários fornecedores, como mapeamento Avançado, BOGO (compre um e ganhe outro grátis) ou mapeamento de pacotes', //  a
      out_of_stock:
        'Quando um produto / variante está sem estoque ou não está mais disponível, você deseja que o DSers', //  a
      do_nothing: 'Não faça nada', //  a
      set_as_draft:'Set as draft',
      set_quantity_to_zero_on_Shopify:'Definir quantidade como zero na Shopify',
      remove_Variant:'Remover variante',
      product_out:'When a product is out of stock or no longer available, you want DSers to',
      variant_out:'When a variant is out of stock or no longer available, you want DSers to',
      set_quantity_to_zero_on_Shopify:'Definir quantidade como zero na Shopify',
      to_zero: 'Zere a quantidade no Shopify', //  a
      inventory_change: 'Quando o inventário mudar, você quer que o DSers', //  a
      update_to_shopify: 'Atualize para o Shopify Automaticamente', //  a
      notification_feature: `Recurso de notificação`
    },
    priceUpdate: {
      suppliers_price:`When your suppliers price`,
			increase: "aumentar",
			decrease:"diminuir",
      pricing_change:`And the price's change exceeds`,
      anychange:`Qualquer alteração`,
      title: 'Atualização automática de preço',
      auto_updates_tip: "After you turn on the automatic price update feature, DSers will track the changes in the supplier's product cost, and automatically synchronize the updated price to Shopify according to the pricing rule you set",
      note: 'Please note: You need to have the Pricing Rule feature activated to use this feature. If you change the price in Shopify, we will still update the price on Shopify according to the pricing rule you set on DSers. Update range: 10 days of ordered products & browsed products',
      supplier: 'When the supplier changes price, you want DSers to:',
      updateCon: 'Update price and Compared at price to Shopify store',
      do_nothing: 'No hacer nada',
      to_shopify: 'Update price to Shopify store',
      cost_change: 'Update price when the cost changes',
      to_setting: 'Automatic price update after cost change <a style="text-align:end"  href="/app/settings?id=11">You can manage this in Settings</a>',
      to_price: 'This store does not have pricing rules',
      check_error: 'Please check the price fluctuation method',
      range: 'The range cannot be 0',
      priceError: 'The store is disconnected, please go to Setting - Account to reconnect it',
    },
    pricing: {
      title: 'Regras de Preço',
      click_to_renew: 'Click to Renew',
      renew_reminder:
        'Your PayPal  account has expired. To not affect the syncing of your tracking numbers, please re-authorize your PayPal',
      description:
        "A regra de preços será aplicada automaticamente quando você enviar produtos dos DSers para o Shopify. Clique em <a href={href} target='_blank'>aqui</a> para obter mais informações.",
      enabled: 'Ativado',
      disabled: 'Desativado',
      pricing_cost: 'Custo de Preço(USD)',
      price: 'Preço',
      price_tip:
        'Ex.: o preço de um produto que custa 10 USD será definido para 12 USD ou 20 USD (caso você adicione 2 USD ao custo ou o multiplique por 2)',
      compared_at_price: 'Comparado ao preço',
      compared_at_price_tip: `E.g. The compared price of product costing 10 USD will be set at 15 or 50 USD (if you add 5 to cost, or multiply it by 5). <br/> If you do not check this option, the value of the compared price will be empty when you push products to your Shopify store.`,
      compared_ar: `Caso não marque esta opção, o valor do preço comparado ficará em branco ao enviar os produtos para sua loja Shopify.`,
      price_no: `If you apply the compared price when pushing products and then deactivate this option, the compared price will not be cleared when you manually update the price in DSers notification page.`,
      set_rules: 'Defina suas comparações em Regras de Preço',
      basic: 'Basic',
      advanced: 'Avançado',
      standard: 'Standard',
      cost_range: 'Faixa de Custo(USD)',
      basic_more: 'Clique <a href="https://help.dsers.com/basic-pricing-rule-for-shopify/" target="_blank">aqui</a> para mais informações.',
      standard_more: 'Clique <a href="https://help.dsers.com/standard-pricing-rule-for-shopify/" target="_blank">aqui</a> para mais informações.',
      advanced_more: 'Clique <a href="https://help.dsers.com/advanced-pricing-rule-for-shopify/" target="_blank">aqui</a> para mais informações.',
      compared_price: 'Preço Comparado',
      formula_text_1:
        'Preço = [(custo do produto + custo de envio) × (1 + % de lucro) + lucro fixo] / (1 - % de ponto de equilíbrio)',
      formula_text_2: 'Preço de comparação = preço × / + número configurado',
      formula_text_reminder:
        'Obs.: se o lucro final ((custo do produto + custo de envio) × % de lucro + lucro fixo) for menor que o lucro mínimo definido, a seguinte fórmula será aplicada: preço = [(custo do produto + custo de envio) + lucro mínimo] / (1 - % de ponto de equilíbrio)',
      advanced_rest_price: 'Rest of the ranges',
      advanced_cost_range: 'Product Cost Range(USD)',
      profit: '% de lucro',
      fixed_profit: 'Lucro fixo',
      min_profit: 'Lucro mínimo',
      breakeven: 'Breakeven%',
      shippingCostReminder:
        'O custo de envio será calculado de acordo com o método de envio selecionado como padrão, em Configurar (Setting) – Envio (Shipping).',
      breakevenReminder:
        'Defina um ponto de equilíbrio para cobrir os custos de distribuição, taxas de pagamento e demais taxas existentes',
      shipping_cost: 'Custo de envio',
      add_shipping_cost: 'Add Shipping Cost',
      rest_price: 'Resto das Faixas de Custo',
      shipping_title: 'Configurações de custo de envio',
      shipping_reminder:
        'Calcularemos o custoCusto do produto de envio de acordo com o método de envio escolhido. Você também pode optar por inserir um custo de envio médio fixo, caso queira fazer entregas em múltiplos países.',
      product_cost_setting: 'Custo do produto',
      product_cost_settingVal: 'O custo do produto será definido de acordo com o preço nacional correspondente ao destino “Envio para” do produto. Para mais detalhes, <a href="https://help.dsers.com/import-product-cost-for-shopify/"  style="color:#3D5AFE" >clique aqui</a>',
      product_cost_reminder:
        'When you import the product and in the notifications, we will calculate the cost of the product and notify the changes according to the country you choose. This will only be in effect after you set this, and the previous data will not be changed (we will gradually support more countries).',
      fixed_cost: 'Custo de envio específico',
      ranges_overlap: 'Suas faixas de sobrepõem',
      ranges_start_greater_end:
        'O valor final da faixa de custo deve ser maior ou igual ao valor inicial.',
      assign_cents: 'Atribuir centavos',
      assign_cents_description:
        'Você pode definir um valor em centavo específico para o seu preço de varejo. Usaremos esse valor ao formar o preço final para seus itens (por exemplo, se você quiser que o custo do seu produto seja XX,99, adicione 99 aos campos abaixo).',
      assign_compared_at_cents: 'Atribuir em comparação com centavos',
      need_link_store: 'Você precisa vincular uma loja do Shopify primeiro',
      save_succ: 'Salvo com Sucesso',
      currency:'Currency',
      apply_go:`Apply currency rate to the pricing rules when you publish products to Shopify. If you want to activate this feature, please go to`,
      apply_to:`to set up currency for your stores`,
			apply_to_mes: "Aplique a taxa de câmbio às regras de preço ao publicar produtos na Shopify. As regras de preço globais funcionam de forma diferente se você NÃO estiver vendendo em dólares (USD) na Shopify e se não deseja ativar este recurso. Calcule os multiplicadores de acordo.",
      apply_btn:`Setting - Stores`,
      bigTit:`The Price you set is higher than the Compared at price, please check and save`,
      inputerr100:'Value range: 0% to 99%',
      basicpricerule:'Regra de preço básica',
      standardpricerule:"Regra de preço padrão",
      advancedpricerule:"Regra de preço avançada",
      setpricing:'Definir detalhes das regras de preço',
      pricing_rule_new_title:'Modelos de precificação',
      pricing_rule_new_dsec:'Modelos de precificação permitem que você defina os preços de seus produtos com facilidade. Selecione a loja em que deseja aplicar os modelos. Veja os detalhes da regra de preço para personalizar ainda mais os preços de seus produtos.'
    },
    billing: {
      // d
      activate_google_auth:
        'We strongly recommend you to Activate Google<br /> Auth to ensure your account security',
      activate: 'ACTIVATE',
      remaining_credit: 'Remaining credit',
      make_a_payment: 'MAKE A PAYMENT',
      make_a_payment_tip:
        'Please fill in your billing address before you can use it.',
      last_payment:
        '{key, plural, =q1 {Your last payment was on} =q2 {for USD} =q3 {and is pending on paypal.} =q4 {payment faild on paypal. please check and payment again.} }',
      transactions: 'Transactions',
      date: 'Date',
      description: 'Description',
      amount: 'Amount (USD)',
      purchase_record: 'Purchase record',
      download: 'Download',
      all_bills: 'All bills',
      save_succ: 'save success',
      save_error: 'save error',
      google_auth: {
        step1: 'Step 1 : Download Google Authenticator',
        step1_description:
          'The IOS user logs in to the AppStore to search for “Google Authenticator", and the Android user logs into the app store or uses the mobile browser to search for "Google Authenticator" download.',
        step2: 'Step 2 : Bind Google Authenticator',
        scan_qr_code: 'IOS/Andorid：Scan QR code',
        code:
          'If the scan code does not work, you can enter the verification code.',
        please_save: 'Used to retrieve google auth, please save.'
      },
      billing: {
        billing_information: 'Billing information',
        business_name: 'Business name',
        business_name_error: 'Please enter a Name',
        email: 'Email',
        email_not_valid: 'The input is not valid E-mail!',
        email_null_error: 'Please input E-mail!',
        phone: 'Phone',
        phone_error: 'Please enter a Name',
        city: 'City',
        city_error: 'Please enter a city',
        currency: 'Currency',
        currency_error: 'Please enter a Name',
        country: 'Country',
        country_error: 'Please enter a country',
        address: 'Address',
        address_error: 'Please enter a address',
        post_code: 'Post code',
        province: 'Province',
        province_error: 'Please enter a province',
        company: 'Company',
        send_this_address: 'Send sample to this address'
      },
      primary: {
        title: 'Primary contact',
        name: 'Name',
        name_error: 'Please enter a Name',
        use_billing_address: 'Use as billing address',
        send_sample_to_this_address: 'Send sample to this address'
      }
    }, // delete
    verified: {
      // delete
      title: 'Verified Supplier',
      img_max_1mb: 'Image must smaller than 1MB!',
      bag_default: 'Bag (default and free)',
      bag_box: 'Box ($0.2/pacakge)',
      bag_box_logo: 'Box with logo ($0.2/pacakge)',
      bag_box_card: 'Box with card ($0.2/pacakage)',
      bag_box_logo_card: 'Box with logo and card ($0.2/package)',
      upload: 'Upload',
      package_request: 'Package request',
      logo_file: 'Logo file',
      note: 'Note'
    }, // delete
    report: {
      timezone: {
        title: 'Fuso horário',
        desc: `O fuso horário padrão é baseado nas configurações de seu navegador.
          Você pode definir um fuso horário diferente para sua conta DSers.
          Porém, note que essa alteração só poderá ser feita uma vez e ela não poderá ser revertida após salvar.`,
        save_modal_desc:
          'Você só pode <i>configurar o fuso horário uma vez</i>. Certifique-se de selecionar a opção correta. Ela não poderá ser alterada posteriormente.',
        save_modal_tip: 'Digite CONFIRMAR para salvar.'
      },
      currencies: {
        title: 'Moedas',
        desc: `O dólar americano (USD) será usado como a moeda padrão no DSers.
          Você pode alterar essa definição para cada uma de suas lojas e a moeda selecionada será aplicada aos produtos do AliExpress e pedidos no DSers de acordo com sua escolha.
          Os dados exibidos no DSers podem ser diferentes dos mostrados no Shopify ou AliExpress, devido às flutuações no câmbio.
          <i>Você só pode definir a moeda uma vez por loja</i>.
          Caso precise alterar a moeda posteriormente, entre em contato com nosso <a href="https://www.messenger.com/t/DSersOfficial">suporte</a>.`,
        store: 'Loja',
        currency: 'Moeda',
        no_store_tip: 'Vincule uma loja para fazer a configuração.',
        save_modal_title: 'Você só pode alterar a moeda uma vez!',
        save_modal_desc:
          'Note que <i>você só pode alterar a moeda uma vez</i>. Digite CONFIRMAR no campo abaixo para salvar suas configurações.',
        input_confirm: 'Digite CONFIRMAR'
      }
    },
    product: {
      customize_title: 'Personalizar descrição do produto',
      customize_con: 'Ao enviar produtos, você deseja que o DSers:',
      shipping_cost_title:'Enviar informações do produto à Shopify',
      customize_specifications: "Enviar apenas ESPECIFICAÇÕES dos produtos",
      customize_overview: "Enviar apenas VISÃO GERAL dos produtos",
      customize_all: "Enviar as ESPECIFICAÇÕES e a VISÃO GERAL",
      location_title:"Product fulfillment service management",
			location_content:"Você pode escolher qual serviço de processamento de produtos e pedidos a plataforma DSers poderá ajudá-lo a gerenciar usando o botão de serviço de processamento. Caso você ative o botão, ele o ajudará a atualizar as informações dos produtos correspondentes ao serviço de processamento e exibirá os pedidos correspondentes normalmente. Caso você o desative, ele não atualizará automaticamente as informações dos produtos correspondentes ao serviço de processamento e não exibirá os pedidos correspondentes.",
      shipping_cost_tip1:'Envie o custo por item dos produtos do fornecedor à Shopify, quando você enviar os produtos à loja.',
      shipping_cost_tip2:`Quando um produto é enviado à loja, o custo do produto do fornecedor e o custo de envio na [Regra de preço avançada] são retornados juntos para o custo por item na Shopify. Existem alguns motivos para o custo de envio ser 0: <br />
      1. Após aplicar a “Regra de preço avançada”, o método de envio correspondente para o país é gratuito e, nesse caso, o custo de envio retornado à Shopify é 0. <br />
      2. Após a “Regra de preço avançada” ser aplicada, nenhum método de envio correspondente é encontrado. Nesse caso, o custo de envio retornado à Shopify é 0. <br />
      3. Após aplicar a “Regra de preço padrão/básica”, não há custo de envio e o custo de envio retornado à Shopify é 0. <br />
      4. A regra de preço não está ativada e não há custo de envio. Nesse caso, o custo de envio retornado à Shopify é 0. <br />
      Você deve fazer o upgrade do seu plano e aplicar a “Regra de preço avançada” para que o DSers retorne o custo do produto e o custo de envio à Shopify.`
    },
    other: {
      default_phone_number_placeholder: 'Choose a country',
      phone_rules_SA_AE_IL: 'it requires 9 digits numbers starts with 5',
      phone_rules_FR: 'it requires 10 digits mobile phone numbers.',
      phone_rules_PT: 'it requires 9 or 10 digits numbers',
      phone_rules_BR_JP: 'it requires 10 or 11 digits numbers',
      phone_rules_KR: 'Please enter 10 or 11-digit phone number starting with 01 or 1 (e.g. 01012345678)',
      phone_rules_IT: 'It requires 9 or 10 digits mobile phone numbers.',
      set_product_title: 'Definir produtos como tributáveis',
      set_product_subtitle: 'Especifica se um imposto é cobrado ou não quando a variante do produto é vendida.',
      sync_ali_order_number:
        'Sincronizar número do Aliexpress para obter detalhes adicionais do Shopify',
      sync_ali_order_number_tip:
        'Se esta configuração estiver ativada, seu número do AliExpress será sincronizado automaticamente com a página de detalhes do pedido do Shopify',
      canceled_orders_optimization: 'Otimização de Pedidos Cancelados',
      canceled_orders_optimization_tip: `Ative esse recurso para otimizar automaticamente um pedido cancelado pelo AliExpress.
                                        A otimização dos DSers reduzirá consideravelmente a probabilidade do pedido ser cancelado novamente.`,
      synchronize_shopify_order_notes: 'Sincronizar notas de pedidos da Shopify',
      synchronize_shopify_order_notes_tip:
        'Activate this feature to automatically synchronize Shopify order notes. DSers will help to send the notes of an order from Shopify to AliExpress along with orders.',
      synchronize_shopify_order_message: "Synchronize Shopify order customization information",
      synchronize_shopify_order_message_tip:"Activating this feature automatically synchronize Shopify order customization information. DSers will help you send the customization information from Shopify to suppliers along with the order.",  
      leave_a_meaage: 'Deixe uma mensagem para os fornecedores',
      leave_a_meaage_tip:
        'Os fornecedores receberão esta mensagem juntamente com as informações do seu pedido.',
      message_placeholder:
        'Insira no máximo 1000 caracteres (no máximo 250 caracteres chineses)',
      ioss_message: 'IOSS',
      ioss_message_tip:
        'Caso você queira pagar o imposto VAT por conta própria em vez de efetuar o pagamento pelo AliExpress, será necessário adicionar seu ID IOSS no campo de informações do cliente nos seus pedidos do AliExpress que forem enviados à União Europeia.',
      ioss_message_tip_2: `Please ask the AliExpress sellers to add the IOSS ID to the order as well and provide the IOSS ID to the shipping provider, so that the packages can pass the customs normally. It is extremely important that you contact the sellers to ensure he will do so.`,
      ioss_message_info: `(Sua conta do AliExpress precisa ser verificada como uma conta comercial para que seja possível adicionar um número VAT no AliExpress e você possa fazer a declaração e o pagamento do imposto VAT por conta própria). O ID IOSS que você definir aqui será adicionado automaticamente ao “Endereço 2” nos seus pedidos quando eles forem processados no AliExpress. Caso você modifique seu ID IOSS aqui, o novo ID só será aplicado aos novos pedidos feitos no AliExpress.`,
      ioss_message_more: `Clique <a href="https://www.dsers.com/blog/eu-vat-reform/" target="_blank">aqui</a> para mais detalhes.`,
      ioss_message_error: 'Please fill in a correct IOSS ID to avoid failing to place the orders to AliExpress or getting packages stopped by the customs.',
      default_phone_number_placeholder: 'Choose a country',
      default_phone_number: 'Definir número de telefone padrão',
      default_phone_number_tip: `Se um pedido não tiver um número de telefone, o sistema usará o número de telefone padrão para realizar seu preenchimento`,
      override_phone_number: 'Anular número telefónico',
      override_phone_number_uae:
        'For orders sent to Saudi Arabia, United Arab Emirates & Israel, it requires 9 digits numbers starts with 5.',
      override_phone_number_fr:
        'For orders sent to France, it requires 10 digits mobile phone numbers starts with 06.',
      override_phone_number_pt:
        'For orders sent to Portugal, it requires 9 or 10 digits numbers.',
      override_phone_number_br:
        'For orders sent to Brazil, it requires 10 or 11 digits numbers.',
      override_phone_number_jp:
        'For orders sent to Japan, it requires 10 or 11 digits numbers.',
      override_phone_number_ko:
        'For orders sent to Korea, it requires 10 or 11 digits numbers starts with 01 or 1.',
      override_phone_number_intro_start: 'Please note that:',
      override_phone_number_intro_end: 'Please fill in accordingly.',
      override_phone_number_tip:
        'Substituir o número de telefone do cliente pelo seu próprio número ao efetuar pedidos com os fornecedores.',
      france_phone_number_tip: `Note que são necessários números de nove dígitos iniciados com 5 para pedidos enviados à Arábia Saudita e Emirados Árabes Unidos.
                                Para pedidos enviados à França, são necessários números telefônicos de 10 dígitos iniciados com 06 ou 07.
                                Preencha de acordo com seu país.`,
      sync_shopify_orders: 'Sincronizar pedidos do Shopify',
      sync_shopify_orders_tip:
        'DSers irá sincronizar pedidos dos últimos 7 dias por padrão. Selecione um intervalo de tempo para sincronizar pedidos se você quiser ou corrigir problemas de sincronização com o Shopify. Você pode sincronizar até 30 dias atrás.',
      sync_shopify_orders_error: 'Você só pode sincronizar até 30 dias atrás.',
      select_store_placeholder: 'Selecionar Loja',
      sync: 'Sincronizar',
      affiliate_program: 'Programa de afiliados', // add
      affiliate: 'Afiliar', // add
      affiliate_desc: 'Você ativou seu Cashback Admitad', // add
      change_code: 'Mudar código', // add
      change_code_wraming: 'Você tem certeza que deseja limpar o código atual?', // add
      step: 'Passo', // add
      new_affiliate_tip: 'Registre uma NOVA conta Admitad pelo link abaixo', // add
      why_affiliate: 'Por que eu preciso de uma nova conta Admitad?', // add
      get_affiliate_btn: 'Obter conta Admitad', // add
      set_affiliate: 'Configure sua conta Admitad.', // add
      how_to_set: 'Veja como Configurar', // add
      fill_affiliate: 'Preencha o código de afiliação abaixo', // add
      where_code: 'Onde encontrar o código?', // add
      code: 'Código', // add
      aliSku: 'Usar ID do fornecedor como SKU do produto',
      aliSku_desc: 'Após a ativação, quando você importar produtos do fornecedor na “Lista de importação”, a SKU do produto usará o nome da variante de ID do produto do fornecedor.',
      err_tip:
        'Este não é um código de Afiliado DSers. Por favor, verifique novamente.', // add
      message_ae_sellers:
        'Los proveedores recibirán este mensaje junto con la información de tu pedido.',
      save_phone_succ: 'Número de telefone salvo com sucesso.',
      cash_back_code_error:
        'Este não é um código de afiliado do DSers. Verifique novamente.',
      cost_error: 'Digite um número no formato 5 ou 5.99',
      create_success: 'Criar sucesso',
      admitad_stopping_now:
        'Seu reembolso do Admitad está sendo interrompido agora. Altere um novo código atribuído ao DSers. <a href={href} target="_blank"> Veja por que e como </a>',
      notice: `Our system detected that your Admitad account can’t be verified.
              <br />
              Please make sure you created the Admitad account via&nbsp;
              <a
                href="https://store.admitad.com/en/promo/?ref=8e3xmmxnjp"
                target="_blank"
                rel="noopener noreferrer"
              >
                DSers referral link
              </a>
              &nbsp;and then change your code on DSers accordingly.
              <br />
              If your Admitad account was registered via&nbsp;
              <a
                href="https://store.admitad.com/en/promo/?ref=8e3xmmxnjp"
                target="_blank"
                rel="noopener noreferrer"
              >
                DSers referral link
              </a>
              , but still can’t be verified, please double check if you filled in the correct account name on DSers.`,
      notice_sub:
        'Please note the current admitad account will be disabled after 48 hours.',
      notice_add_title:"About Admitad Account",
      notice_2:
        'In order to make sure your Admitad account is working well with DSers, please go to Setting - Other - Affiliate program to finish the activation. Otherwise your Admitad code will be disabled after 48 hours.',
      not_account_name: 'Please fill in your Admitad account name.',
      notice_button_change: 'Alterar nome da conta',
      notice_button_register: 'Register now',
      cancel_fulfillment_title: 'Solicitar cancelamento de processamento',
      cancel_fulfillment_desc: `Após ativar essa função no aplicativo correspondente abaixo, os pedidos que não puderam ser efetuados a partir do aplicativo, porque você clicou em “Solicitar processamento” no Shopify, serão restaurados para que possam ser feitos normalmente no AliExpress.`,
      vat_title: `When the price of an order to France exceeds 150 Euros, you need to provide your VAT ID and your company name. Please fill it in the following order, so as not to affect the normal process. Click <a target="_blank" href="https://www.blog.dserspro.com/post/orders-to-france-specifications-with-shopify-dsers">here</a> to get more information.`,
      vat_id_verify: `Please enter 8-12 digits VAT ID`,
      vat_input_verify: `Please enter the 8-12 digits VAT ID number. Don't add FR at the beginning.`,
      vat_company: `Please enter Company name`,
      vat_h3: 'VAT',
      plan_info_id: 'VAT ID',
      plan_info_name: 'Company name',
      override_phone_number_intro_start_new: 'Observe que:',
      override_phone_number_first: 'é necessário um número de nove dígitos iniciado com 5.',
      override_phone_number_second: 'é necessário um número de nove dígitos iniciado com 5 ou de 10 dígitos iniciado com 0.',
      override_phone_number_third:'é necessário um número de nove ou 10 dígitos.',
      override_phone_number_fourth: 'é necessário um número de 10 ou 11 dígitos.',
      override_phone_number_fifth:'é necessário um número de 10 ou 11 dígitos iniciado com 01 ou 1.',
      phone_rules_SA_AE_IL_new:'É necessário informar um número de nove dígitos iniciado com 5 ou de 10 dígitos iniciado com 0.',
      phone_rules_FR_new:'É necessário informar um número telefônico de nove ou 10 dígitos.',
      order_save:'SAVE',
      order_fulfillment_module:'Configurações de processamento',
			order_fulfillment_first_title:'Processar itens separadamente',
			order_fulfillment_first_content:"Após ativar este recurso, o DSers processará cada produto dos pedidos separadamente e acionará o e-mail de confirmação de envio da Shopify com as seguintes regras: quando um produto de um pedido do DSers receber um número de rastreamento, processaremos o item do pedido e a Shopify enviará um e-mail ao seu cliente. Múltiplos e-mails serão enviados pela Shopify para o mesmo pedido.",
			order_fulfillment_second_title:'Processar itens em grupo',
			order_fulfillment_second_content:'Após ativar este recurso, o DSers processará um pedido somente após todos os produtos que compõem o pedido receberem números de rastreamento e acionará a Shopify para que um único e-mail seja enviado ao seu cliente.',
      order_split_pop_title:'Observação',
      order_split_pop_content:'Para continuar melhorando a estabilidade do DSers e nossa experiência com os vendedores, se este recurso for desativado ele não poderá mais ser acessado. Tem certeza que deseja parar de usar este recurso?',
      order_split_pop_btn:'CONFIRMAR'
    },
    payment: {
      top_title:"Informações comerciais",
      top_small_title: 'Usado nos resumos dos pedidos do Tmall.',
      top_form_item1: 'Primeiro nome',
      top_form_item2: 'sobrenome',
      top_form_item3: 'Nome da empresa',
      top_form_item4: 'Email',
      top_form_item5: 'País',
      top_form_item6: 'Endereço',
      top_form_item7: 'Apartamento, suíte etc.',
      top_form_item8: 'Cidade',
      top_form_item9: 'Estado',
      top_form_item10: 'CEP',
      bottom_title: "Resumos do Tmall",
      bottom_small_title:"Consulte e faça o download do resumo mensal dos pedidos do Tmall. (Atualização do extrato do mês anterior no dia x de cada mês)",
      bottom_state1: "Solicitação",
      bottom_state2:"Na solicitação"        
    },
    order:{
      input_placeholder:`Enter the product's AliExpress link for which you want to split orders`,
      order_same:`The product link, quantity and country you configured are exactly the same, please check and save`,
      title:'Divisão automática de pedido',
      desc:`Dividiremos o pedido de acordo com o link do produto do AliExpress e a quantidade de produtos que você definir. Regras da divisão de pedidos: quando o produto do pedido vier do fornecedor definido e a quantidade do produto for maior ou igual à quantidade configurada, que deve ser inferior a 10, dividiremos automaticamente o pedido em múltiplos pedidos na Shopify.`,
      inputNumber:`Please enter the amount you want to split`,
      incorrect:`The product link you entered is incorrect`,
      banner_title: 'Automatically split orders',
      banner_text:'Having issue when using cost-effective AliExpress Saver Shipping? Check this solution.',
      banner_btn:'learn more',
      titip:'Order',
      delivery_period_tip:'Mínimo 2, máximo 10',
      delivery_period:'Quantidade de produtos no pedido para divisão',
      split_ending:'Splitting orders',
      delete_dsers: 'This product had been deleted in DSers',
    },
    subaccount:{
      add:'Adicionar conta de equipe',
      sendemail:'Enviaremos uma mensagem ao endereço de e-mail informado por você para concluir a autorização. Seus colaboradores poderão acessar o DSers usando esta conta',
      admintext:'Todas as permissões, incluindo de administrador',
      fulltext:'Todas as páginas e recursos, mas sem permissões de administrador',
      limittext:'Exibição ao proprietário de acordo com a página e a loja que você configurou',
      emailempty:'Please input the email address',
      emailformat:'Please input a correct email address',
      active:'Active',
      NoAccess:'No page access',
      NoAccessTitle:'You do not have access to the current page, please contact your administrator',
      report:"Relatório",
      ImportList:"Lista de importação",
      MyProduct:"Meus Produtos",
      Openorder:"Aberto Order",
      ArchivedOrder:"Arquivado Order",
      Tracking:"Rastreamento",
      CSVBulkOrder:"Novo recurso",
      LinktoMoreStores:"Link to More Stores",
      Setting:"Configurar",
      Notification:"Notificações",
      fullaccess:"Acesso total",
			admin:"Administrador",
			Limitedaccess:"Acesso limitado",
			Full:"Full",
			Storeaccess:"Acesso à loja",
			Featureaccess:"Acesso aos recursos",
			invited:"invited",
			Notconnected:" Not connected"
    },
    product_price:'Update product price',
    title_account:'Conta',
    title_time_zone:'Fuso Horário',
    time_zone_des:'O fuso horário padrão é baseado nas configurações de seu navegador. Você pode definir um fuso horário diferente para sua conta DSers. Porém, note que essa alteração só poderá ser feita uma vez e ela não poderá ser revertida após salvar.',
    title_ae_account:'Conta do AliExpress',
    ae_account_des:'Observe que se você vincular uma conta diferente, as informações dos pedidos realizados na conta atual não serão mais atualizadas no DSers após a desconexão.',
    title_synchronization:'Sincronização',
    title_automatic:'Atualização automática de preço',
    automatic_des:'After you turn on the automatic price update feature, DSers will automatically synchronize the updated price to Shopify according to the pricing rule you set every 24 hours if the supplier changes the price for more than $1.',
    automatic_des2:'Please note: You need to have the Pricing Rule feature activated to use this feature. If you change the price in Shopify, we will update the price on Shopify according to the set pricing rule you on DSers.',
    title_update_pro_pr:'Alterar manualmente o preço dos produtos da loja ',
    update_pro_pr_des:'Update the price of the products pushed to your Shopify store according to the pricing rules you currently set. The process may take some time.',
    update_pro_pr_des_time_1:'Manual update up to',
    update_pro_pr_des_time_2:'times every 30 days',
    title_sync_shop_order:'Sincronizar pedidos da Shopify',
    sync_shop_order_des:'DSers will sync orders from the last 7 days by default. Choose a date range to sync more orders you want .You can sync up to 30 days per time.',
    title_order_details_sync:'Order Details Synchronization',
    title_affiliate_program:'Programa de afiliados',
    title_notification:'Notificação',
    title_shipping_confirmation:'Confirmação de envio',
    title_shipping_confirmation_des: "Acione o e-mail da notificação de processamento enviado pelo Shopify",
    title_paypal:'Enviar número de rastreamento para o PayPal',
		title_tracking: "Rastreamento",
		title_tracking_tip:"Ative este recurso para monitorar o status de rastreamento dos pedidos que foram enviados.",
    title_leave_message:'Deixe uma mensagem para os fornecedores',
    "title_leave_message_ae":"AliExpress suppliers will get this message together with your order information.",
    "title_leave_message_tmall":"Tmall suppliers will get this message together with your order information, but the message edition is not supported now.",
    message_des:'Os fornecedores receberão esta mensagem juntamente com as informações do seu pedido',
    title_phone_number_op:'Otimização de número de telefone',
    title_automatic_order_split:'Divisão automática de pedido',
    automatic_order_split_des:'Dividiremos o pedido de acordo com o link do produto do AliExpress e a quantidade de produtos que você definir. Regras da divisão de pedidos: quando o produto do pedido vier do fornecedor definido e a quantidade do produto for maior ou igual à quantidade configurada, que deve ser inferior a 10, dividiremos automaticamente o pedido em múltiplos pedidos na Shopify.',
    title_order_management:'Gerenciamento de Pedidos',
    title_tax:'Imposto',
    title_shipping:'Envio',
    title_add_global_shipping:'Adicione um método de frete global baseado em produto',
    add_global_shipping_des:'Configure seu método de envio padrão para produtos de fornecedores específicos. A configuração baseada em produto tem maior prioridade quando comparada à configuração baseada em país. Você tem acesso rápido a esse recurso em "Mapeamento" – "Mais ações".',
    phone_number_des:"In order for your package to the Netherlands and Finland to be delivered properly, please fill in a real phone number in the order. The Netherlands and Finland do not support the use of the Phone Number Optimization feature for the time being. Click <a href=\"https://help.dsers.com/fix-failed-orders-due-to-aliexpress-phone-number-regulations/\" target=\"_blank\">here</a> to learn about how to solve this problem",
    phone_number_des_tip:"This country is not currently supported",
    title_pricing_rule:'Regra de preço',
    title_product:'Produto',
    title_sp_store:'Lojas Shopify',
    sp_stores_des:'Você pode gerenciar suas lojas Shopify aqui. Caso queira desinstalar o DSers em uma loja, não se esqueça de desvinculá-la aqui.',
    title_currencies:'Moedas',
    disablestext: 'Select a store',
    password: 'Senha',
    admitadAccount1: "A conta Admitad foi suspensa devido a uma falha na verificação. Clique ",
		admitadAccountLink: "aqui ",
    admitadAccount2: "para obter informações mais detalhadas.",
    refresh_store_tag: "Atualizar conexão",
		refresh_store_tag_tip: 'Se o status da loja Shopify não estiver atualizado por motivos de rede, clique em “Atualizar conexão” para obter o status da loja',
		refresh_not_connected:"Não conectado",
    refresh_store_icon_tip: "Refresh connect",
    affiliate_code:'Programa de afiliados',
		affiliate_code_btn:'REATIVAR',
    hasSetCurrency:`Aplique a taxa de câmbio às regras de preço ao publicar produtos na Shopify. As regras de preço globais funcionam de forma diferente se você NÃO estiver vendendo em dólares (USD) na Shopify e se não deseja ativar este recurso. Calcule os multiplicadores de acordo.`,
    pricing_rule_dsec:'Uma regra de preço inclui moeda, modelos de precificação e atribuição de centavos. Você pode criar suas regras de preço aqui e aplicá-las aos seus produtos.',
    updateProductPrice:`Atualiza o preço dos produtos enviados à sua loja Shopify de acordo com as regras de preço definidas por você. O processo pode levar algum tempo. Clique <a href="/app/pricing?select=1">aqui </a> para fazer o upgrade do plano e melhorar o tempo de sincronização.`,
    ...agency
  }
};
