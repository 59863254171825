import { Get, Post, Put } from '../utils/request';

import { WooCommerceAlert } from './global';

export async function createShopfiyStore(payload) {
  return Get('/auth/shopify/bind', payload);
}

export async function AliExpressAuthStatus(payload = {}) {
  return Get('/auth/ali/status', payload);
}

export async function postGuidance(payload) {
  return Post('/leadpagestatus', payload);
}

export async function sendMessage(payload = {}) {
  return Post('/user/messageboard', payload);
}

// 去woo
export async function goWoo(payload) {
  return Post('/woo/access', payload);
}

// 获取用户支付计划
export async function disbursementSchedule(payload) {
  return Get('/charge/plan', payload);
}

export async function setResetPassWord(payload) {
  return Post('/users/change/password', payload);
}

export async function getIsSnyc(payload = {}) {
  return Post('/ord/job/shopify', payload);
}

export async function getSyncTask(payload = {}) {
  return Get('/ord/job/shopify/status', payload);
}

export async function getAliAuthUrl(payload = {}) {
  return Get('/auth/ali/rect', payload);
}

export async function getOrderTask(payload) {
  return Get('/ord/place-new', payload);
}

export async function getShopifyStoreStatus(payload = {}) {
  return Get('/auth/shopify/status', payload);
}

export async function updateShopfiy(payload = {}) {
  return Get('/auth/shopify/bind', payload);
}

export async function unBindAli(payload = {}) {
  return Post('/auth/ali/status', payload);
}

export async function getNotificationUnreadLength(payload = {}) {
  var url = '/number/total';
  if(payload && payload.hasOwnProperty('storeId') && payload.storeId != 'all'){
    url = `/number/total?storeId=${payload.storeId}`
  }
  return Get(url, payload);
}

export async function getSyncFulfillment(payload) {
  return Get('/ord/fulfill/status', payload);
}

export async function sendEmptyOrders(payload) {
  return Post('/ord/empty_order', payload);
}

export async function setUserStatus(payload) {
  WooCommerceAlert(payload?.data?.status || '00000000', 'topBtn');
  return Post('/users/status', payload);
}

export async function getOldOrderTask(payload) {
  return Get('/ord/place', payload);
}

export async function checkPlan(payload) {
  return Get('/charge/plan_ex', payload);
}

export async function  checkProductLimit(payload){
  const request = [Get('/set/limit/shopify', {}), Get('/set/limit/ae', {})]
  return Promise.allSettled(request)
}
