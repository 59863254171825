import React, { useState, useEffect,useRef } from 'react';
import { Select } from 'antd';
import intl from 'react-intl-universal';

import styles from './Store.less';
import iconSearch from 'assets/myproduct/icon_search.png'

import { Get, Post } from 'utils/request';

import debounce from 'lodash/debounce';

const { Option } = Select;
const emptyDomStyle = { height: 65, lineHeight: '65px', textAlign: 'center' };
const emptyDom = <div style={emptyDomStyle}>Empty list</div>;

let flags = false

function SelectTool(props) {
  const nameMap = {
    collections: intl.get('product.collection_title'),
    product_type: intl.get('product.type_title'),
    tags: intl.get('product.tags_title'),
    vendor: intl.get('product.vendor_title')
  };

  const placeholerMap = {
    collections: intl.get('product.collections'),
    product_type: intl.get('product.product_type'),
    tags: intl.get('product.tags'),
    vendor: intl.get('product.vendor')
  };

  const [list, setList] = useState([]);
  const [value, setValue] = useState([]);
  const [searchValue,setSearchValue] = useState('')
  const [searchList,setSearchList] = useState([])
  const [loading,setLoading] = useState(true)
  const [disabled,setDisabled] = useState(true)
  const { allStore, type, mode } = props;
  const prevRef = useRef();


  function getSingleValue(v) {
    let vv = null;
    if (value.length > v.length) {
      vv = v;
    } else {
      vv = v.filter(item => !value.includes(item));
    }

    let maxLength255 = vv[0];

    if (maxLength255 && maxLength255.length > 255) {
      maxLength255 = maxLength255.substring(0, 255);
      vv = [maxLength255];
    }
    return vv;
  }

  function selectChange(v) {
    let vv = v;
    if (props.single) {
      vv = getSingleValue(v);
    }
    const { organization = {}, organizationValue = {} } = allStore.find(item => {
      return item.id === props.storeId;
    }) || {};

    let newList

    if (type === 'collections') {
      const newarr =[];
      const newarr1 = [...organization[type] || [],...organizationValue[type] || [],...list]
      // setList([...deWeight(newarr1)] || []);
      newList = [...deWeight(newarr1)]
    }

    setValue(vv);
    if (props.storeId) {
      props.changeCategory({
        storeId: props.storeId,
        value: vv,
        type,
        searchList:newList
      });
    }
  }

  function deWeight(arr) {
    for (let i = 0; i < arr.length - 1; i++) {
      for (let j = i + 1; j < arr.length; j++) {
        if (arr[i].id == arr[j].id) {
          arr.splice(j, 1);
          j--;
        }
      }
    }
    return arr;
  }


  useEffect(() => {

    const { organization = {}, organizationValue = {} } = allStore.find(item => {
        return item.id === props.storeId;
    }) || {};
    const v = organizationValue[type];
    const arr = [];
    let selectValue;

    // 去重一下
    if (type === 'tags' || type === 'product_type' || type === 'vendor') {
      const tmp = organization[type] && organization[type].filter(i => i !== '') || [];
      setList([...new Set(tmp)]);
    } else {
      const newarr =[];
      organization[type]?.forEach(i=>{
        newarr.push({id:i.id,title:i.title})
      });
      const newarr1 = [...newarr || [],...organizationValue[type] || []]
      setList([...deWeight(newarr1)] || []);
    }

    if (v === '') {
      selectValue = [];
    } else if (typeof v === 'string') {
      selectValue = v.split(',');
    } else {
      v?.forEach(item => {
        arr.push(`${item.id}`);
      });
      
      selectValue = arr;
    }
    setValue(selectValue);
    selectChange(selectValue);    
  
    prevRef.current = selectValue
  }, [props.storeId ]);

  useEffect(()=>{
    setTimeout(()=>{
      setLoading(false)
      setDisabled(false)
    },200)
  },[list,searchValue])
  

  useEffect(()=>{
    if(flags){
      getAAA(value)
    }
  },[flags])

  const scalarArrayEquals = (array1,array2)=> {
    return array1.length==array2.length && array1.every(function(v,i) { return v === array2[i]});
  }

  const getAAA = (value) => {      
    if(!scalarArrayEquals(prevRef.current,value)){
      props.changeHiddenStatus()
      flags=false
    }
  }

  const handleSearch = debounce(async(e)=>{
    setLoading(true)
    if(props.type === 'collections'){
      setSearchValue(e)
      const arr = list.filter(i=>{
        return i.title.toLowerCase().indexOf(e.toLowerCase()) > -1
      })
      if(arr.length){
        return
      }else{
        const params = {
          data:{
            title:e,
            store_id:props?.storeId
          }
        }
        const res = await Get('/prod/cols',params)
        if(res.data){
          const newarr1 = [...list,...res?.data]
          setList([...deWeight(newarr1)] || []);
          setSearchList(res?.data)
        }else{
          setList([])
        }
      }
    }
  },500) 

  const onBlurHandler=()=>{
    if(props.type === 'collections'){
      setSearchValue('')
  
      const { organization = {}, organizationValue = {} } = allStore.find(item => {
        return item.id === props.storeId;
      }) || {};
      const v = organizationValue[type];
      const arr = [];
      let selectValue;
  
      // 去重一下
      if (type === 'tags' || type === 'product_type' || type === 'vendor') {
        const tmp = organization[type] && organization[type].filter(i => i !== '') || [];
        setList([...new Set(tmp)]);
      } else {
        setList(organization[type] || []);
      }
    }
  }

  return (
    <>
      <div className="se_tpe">{nameMap[type]}:</div>
      <div className={styles.selectCon}>
        <Select
          showSearch
          allowClear={searchValue ? false : true}
          value={value}
          searchValue = {searchValue}
          mode={mode}
          notFoundContent={type === 'collections' ? undefined : emptyDom}
          maxLength={7}
          placeholder={placeholerMap[type]}
          getPopupContainer={triggerNode => triggerNode.parentNode}
          loading={loading}
          disabled={disabled}
          onChange={(props) => {
            flags = Date.now();
            selectChange(props);
            setSearchValue('')
          }}
          showArrow={searchValue ? false : true}
          onSearch={handleSearch}
          // onChange={selectChange}
          onFocus={()=>{
            if(type === 'collections'){
              props.clickCategory()
            }
          }}
          onBlur={onBlurHandler}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {list.map(item => {
            return (
              <Option key={item.id ? `${item.id}` : item}>
                {item.title || item}
              </Option>
            );
          })}
        </Select>
      </div>
    </>
  );
}

export default SelectTool;
