import React from 'react';
import ReactDOM from 'react-dom';
import { Button, Icon } from 'antd';
import intl from 'react-intl-universal';
import { Link } from 'dva/router';

import styles from './SupplyNotice.less';
import img from './tip.png';
import useOnlineNotice from '../../../../../hooks/useOnlineNotice';

const onlineDate = new Date(2020, 8, 15); // 上线日期

const modalRoot = document.getElementById('root');

function SupplyNotice() {
  const [visible, closeNotice] = useOnlineNotice('supply_entrust', onlineDate);

  function handleClose() {
    closeNotice();
  }

  if (!visible) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.cpNotice}>
      <div className={styles.cpCon}>
        <Icon type="close" className={styles.close} onClick={handleClose} />
        <h3>{intl.get('setting-supply.notice_title')}</h3>
        <div className={styles.body}>
          <img src={img} alt="" />
          <div className={styles.link}>
            <Link
              to="/settings?id=supply"
              data-category="supply entrust"
              data-action="open Mass Supply menu"
            >
              <Button onClick={handleClose} type="primary">
                {intl.get('common.learn_more')}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>,
    modalRoot
  );
}

export default SupplyNotice;
