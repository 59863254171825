import React from 'react';
import { Button } from 'antd';
import { connect } from 'dva';

import intl from 'react-intl-universal';

import img from '../../assets/support/platform01.png';

import styles from './index.less';

function ProductPoolBanner(props) {
  return null;

  const flag = (props.userInfo.status / 10) & (1 > 0);

  if (!flag) {
    return null;
  }

  return (
    <div className={styles.banner}>
      <div className={styles.main}>
        <img src={img} />
        {props.showBtn ? (
          <p>{intl.getHTML('product_pool.title')}</p>
        ) : (
          <p>{intl.get('product_pool.title_long')}</p>
        )}
      </div>
      {props.showBtn ? (
        <div className={styles.checkMore}>
          <Button
            type="primary"
            size="large"
            href="/storemanage/market"
            target="_blank"
          >
            {intl.get('public.check_more')}
          </Button>
        </div>
      ) : null}
    </div>
  );
}

const mapStateToProps = ({ login }) => ({
  userInfo: login.userInfo
});

export default connect(mapStateToProps)(ProductPoolBanner);
