import React from 'react';
import { connect } from 'dva';
import { getSupplierUrl, replaceUrlToHttps } from 'utils/utils';
import intl from 'react-intl-universal';
import { getProductList, setProductList, deletePorductList } from 'services/productmy';
import { Input, Drawer, Row, Tag, Spin, Button,Checkbox,Radio, message, Modal, Tooltip } from 'antd';
import MoreAction2 from './MoreAction2';

import { GetSKUID, GetProductSkuOptionFromDetail } from './StandardImportV2'

import styles from './index.less';
import './mapping.css';
import { transform } from '@babel/core';

import tmalllogo from 'assets/tmall_logo.svg';
import ae from 'assets/icon-ae.jpg';

const { CheckableTag } = Tag;


@connect(({ productmy, loading }) => ({
    productmy,
    loading: loading.models.productmy,
    getAeLoading: loading.effects['productmy/aeProductDetail'],
    historyLoading: loading.effects['productmy/importHistory']
}))
export default class SkuSelect extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            stretchStatus: false, // 控制商品池伸缩
            skus: [], // sku数据,
            changeSelect: [],
            skuImage: '',
        };

        this.agoProductId = '';
        this.aeDetail = {};
    }

    componentDidMount(){
        const { selectData , prod} = this.props;
        if(`${selectData.id}` === `${prod.supplier_id}`){
            this.setState({changeSelect: selectData.selectedOptions});
        }
        if(this.props?.prod){
            this.aeDetail = {
                subject:prod?.product_title,
                supplier_type:prod?.supplier_type,
                product_id_str:prod?.supplier_id
            }
        }
        this.updateProd();
    }

    componentDidUpdate(prevProps){
       const { selectData , prod} = this.props;
       if(`${selectData.id}` !== `${prevProps.selectData.id}`){
            if(`${selectData.id}` === `${prod.supplier_id}`){
                const [opts, skuMap] = GetProductSkuOptionFromDetail(selectData?.data);
                const firstSkuImg = selectData?.data?.aeop_ae_product_s_k_us?.aeop_ae_product_sku?.filter(i=>{
                    if(i.id === GetSKUID(selectData.selectedOptions, skuMap)){
                    return i
                    }
                })[0]?.image_url
                this.setState({
                    changeSelect: selectData.selectedOptions,
                    skuImage: firstSkuImg
                });
            }else{
                this.setState({ changeSelect: [] });
            }
           
       }
    }

    // 处理商品池响应逻辑
    updateProd = () => {
        const { prodList, prod, sp, initExtends } = this.props;
        if((prodList && (prodList.product_id == prod.supplier_id)) || (sp && sp.platform_product_id == prod.supplier_id) || initExtends.includes(prod.supplier_id)){
            this.setState({
                stretchStatus: true,
                skuImage: prod.main_img,
            });
            if(sp?.platform_product_id === prod.supplier_id) {
                // this.setState({changeSelect: sp.sku.split(';')})
            }
            if(this.state.skus.length == 0) this.getAeProduct();
        }
    }

    switchCollect = () => {
        const { stretchStatus, skus } = this.state;
        this.setState({
            stretchStatus: !stretchStatus
        }, () => {
            if(!stretchStatus && skus.length == 0) this.getAeProduct();
        })
    }

    // 获取详细sku
    getAeProduct = () => {
        const {prod} = this.props;
        this.props.dispatch({
          type: prod?.supplier_type === 4 ? 'productmy/tmProductDetail' : 'productmy/aeProductDetail',
          payload: {
            data: {
              url: getSupplierUrl(prod?.supplier_type,prod.supplier_id),
              product_id:prod.supplier_id,
              sypplier_type:prod?.supplier_type
            },
            callback: d => {
                this.handleSkus(d.data)
                this.aeDetail = d.data;
            }
          }
        });
    };

    changeTag = (e, tag) => {
        const { skus } = this.state;
        const { prod, selectData } = this.props;
        let checked = e.target?.checked;
        let changeSelect = selectData?.selectedOptions
        
        if (checked) {
            changeSelect[tag.index] = tag;
        } else {
            changeSelect[tag.index] = false;
        }
        const [opts, skuMap] = GetProductSkuOptionFromDetail(this.aeDetail);
        const firstSkuImg = selectData?.data?.aeop_ae_product_s_k_us?.aeop_ae_product_sku?.filter(i=>{
            if(i.id === GetSKUID(selectData.selectedOptions, skuMap)){
              return i
            }
        })[0]?.image_url

        if(tag.sku_image || firstSkuImg){
            this.setState({
                skuImage: firstSkuImg || tag.sku_image
            })
        }
        
        this.props.onChange({ 
            id: prod.supplier_id, 
            selectedOptions: changeSelect, 
            data: this.aeDetail,
            options: skus,
            skuImage: firstSkuImg || tag?.sku_image || '',
            supplier_type:prod?.supplier_type || 2
        });

        this.props.getDefaultImage(prod.main_img);
        
        this.setState({
          changeSelect: [...changeSelect],
        });
      };

    // 处理sku数据
    handleSkus = (val) => {
        const skus = this.props.GetProductSkuOptionFromDetail(val)[0];
        if (skus.length == 0) {
            skus.push({
                prop_id: 1,
                prop_name: 'Title',
                values: [
                {
                    index: 0,
                    property_value_id_long: '',
                    sku_image: '',
                    sku_property_id: '',
                    product_id:val.product_id_str,
                    sku_property_name: 'Title',
                    sku_property_value: 'Default Title'
                }
                ]
            });
        }
        this.setState({
            skus: skus,
        })
    }

    // 删除商品池
    removeProduct = () => {
        const { prod } = this.props;
        console.log(this.aeDetail,'skusss');
        console.log(prod, 'deleteprod-=-=')
        this.setState({
            removeStatus: true
        })
    }

    removeConfirm = async() => {
        const { prod } = this.props;
        // this.props.handleDeleteSku(prod.supplier_id);
        // this.setState({
        //     removeStatus: false
        // })
        const res = await deletePorductList({
            data:{
              dsers_product_id: prod.ds_product_id,
              supplier: prod.supplier_id,
              store_id: prod.store_id
            }
        })
        if(res.code === 2000){
            message.success('SUCCESS')
            this.props.handleDeleteSku(prod.supplier_id);
            this.props.getStandardProduct(res.data);
            this.setState({
                removeStatus: false
            })
        } 
    }

    render(){
        const { 
            prod, 
            changeTag, 
            skuImg, 
            GetProductSkuOptionFromDetail, 
            GetSkuPropertyID,
            GetSkuPropertyName,
            mappingType,
            selectData
        } = this.props;

        const {
            stretchStatus,
            skus,
            changeSelect,
            removeStatus,
            skuImage
        } = this.state;
        // console.log(this.props.sp, 'spspspspspprops');
        // console.log(prod,'this.props-=-=');
        // console.log(changeSelect,selectData?.selectedOptions,'==changeSelect=')
        return (
            <div className={ !stretchStatus ? styles.collectProduct : styles.product}>
                <div onClick={this.switchCollect} className={styles.chevron}>
                    <i class="material-icons" style={!stretchStatus? {}:{transform:'rotate(180deg)'}}>chevron_right</i>
                </div>
                <img src={ skuImage || prod.main_img} className={styles.product_img} />
                <div className={styles.options}>
                <p className={styles.productTitle}>
                    {stretchStatus 
                    ? (
                        <span>
                            <img className={styles.platformIcon} src={prod?.supplier_type === 4 ? tmalllogo : ae} alt="" />
                            <a href={getSupplierUrl(prod?.supplier_type,prod.supplier_id)} target="_blank">{prod.product_title}</a>
                        </span>
                    )
                    : (
                        <span className={styles.titleContainer}>
                            <img className={styles.platformIcon} src={prod?.supplier_type === 4 ? tmalllogo : ae} alt="" />
                            <div onClick = {this.switchCollect}>{prod.product_title}</div>
                        </span>
                    )}
                    <Tooltip title='Delete Supplier'>
                        <div className={styles.deleteIcon} onClick={this.removeProduct}>
                                <i class="material-icons">delete</i>
                        </div>
                    </Tooltip>
                    <Tooltip title='Open in Add Global Shipping method based on product'>
                        <div className={styles.shippingIcon}>
                            <MoreAction2
                                style={{ border: '1px solid #e3e3e3', padding: '12px 24px' }}
                                onChange={(type) => this.props.handleMoreActionChange(this.aeDetail)}
                                mappingType="standard"
                            />
                        </div>
                    </Tooltip>
                </p>
                {skus.map(sku => {
                return (
                    <Row className={styles.skusRow} key={sku.prop_name}>
                    <h5 className={styles.optionsCategory}>{sku.prop_name}:</h5>
                        <div className="flexWQs">
                        {sku.values.map(tag => (
                            <div className={changeSelect.find((i) => i && i.product_id === tag.product_id && `${i?.sku_property_id}` === `${tag.sku_property_id}` && `${i?.property_value_id_long}`=== `${tag.property_value_id_long}`) ? 'selct_s' : 'selct_d'}>
                            <Radio 
                                onChange={checked => {
                                this.changeTag(checked, tag, prod.supplier_id);
                                }} 
                                checked={changeSelect.find((i) => i && i.product_id === tag.product_id && `${i?.sku_property_id}` === `${tag.sku_property_id}` && `${i?.property_value_id_long}`=== `${tag.property_value_id_long}`)} >
                                    {GetSkuPropertyName(tag)}
                            </Radio>
                            </div>
                        ))}
                        </div>
                    </Row>
                    );
                })}
            </div>
            <div className={styles.removeProduct}>
                {removeStatus && (<Modal
                    title={intl.get('mapping.standard.remove_title')}
                    visible={true}
                    style={{ position: 'relative'}}
                    maskClosable
                    onCancel={() => {this.setState({removeStatus: false})}}
                    footer={<Button className={styles.removeProductBtn} onClick={this.removeConfirm}>{intl.get('mapping.standard.remove_confirm')}</Button>}
                >
                    {intl.get('mapping.standard.remove_con')}

                </Modal>)}
            </div>
        </div>
     )}
}