import { Post, Get } from '../utils/request';

import { WooCommerceAlert } from './global';

export async function postGuidance(payload) {
  WooCommerceAlert(payload?.data?.status || '00000000', 'guidance');
  return Post('/users/status', payload);
}

export async function getWooUrl(payload) {
  return Post('/woo/access', payload);
}

// 获取用户信息

export async function getInfo(payload) {
  return Get('/users/info', payload);
}

// 获取用户支付计划
export async function getPlan(payload) {
  return Get('/charge/plan', payload);
}

// 首次创建支付计划
export async function setProject(payload) {
  return Post('/charge/plan', payload);
}

// 修改计划

export async function modificationProject(payload) {
  return Post('/charge/change_plan', payload);
}

// 修改支付门店

export async function modificationShop(payload) {
  return Post('/charge/change_store', payload);
}

// 激活订单

export async function activate(payload) {
  return Post('/charge/activate', payload);
}

// 重新下单

export async function reorder(payload) {
  return Post('/charge/re_bill', payload);
}

// 保留店铺

export async function KeepTheStore(payload) {
  return Post('/charge/retain_store', payload);
}

// 检测店铺

export async function getShopifyStoreStatus(payload = {}) {
  return Get('/auth/shopify/status', payload);
}

// 门店列表

export async function storeList(payload = {}) {
  return Get('/charge/store_list', payload);
}

// 免费时间

export async function getFreeTime(payload = {}) {
  return Get('/partner/client/account/property', payload);
}
