
    export default 
    {
    "suppliers": {
        "categary": "Alle Kategorien",
        "copyLink": "Link kopieren",
        "importBtn": "Zur Importliste hinzufügen",
        "importBtnEd": "Imported",
        "optimizer": "Supplier Optimizer"
    }
}