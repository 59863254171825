export default {
  csv: {
    csv_bulk_order: 'Commande Groupée par CSV', //  d
    re_download_template:
      'Le modèle a été mis à jour. Veuillez télécharger le nouveau modèle.',
    products: 'Produits',
    import: 'Importer',
    download_template: 'Télécharger modèle',
    product_tip: 'Gérez la base de donnée des produits pour pouvoir commander.',
    orders: 'Commandes',
    order_tip: `Obtenez les N° de commandes et N° de suivi (les N° de suivi peuvent prendre plusieurs jours pour être générés) <br />
                en téléchargeant le fichier dans la colonne "Commandes réussies".<br />
                Révisez les commandes échouées en téléchargeant le fichier dans la colonne “Commandes Échouées” et éditer les informations nécessaires.`,
    upload_title_orders: 'Importer commandes',
    upload_title_product: 'Importer la base de données des commandes',
    upload_btn: 'Importer',
    need_help: 'Besoin d\'aide?',
    choose_csv_file: 'Choisir Fichier CSV',
    no_file_chosen: 'Aucun fichier choisi',
    max_8mb: 'Votre fichier CSV doit faire moins de 8Mo',
    upload_download_tip: 'pour voir un exemple du format requis',
    upload_succ: 'Téléchargement réussi.',
    upload_fail: 'Échec du téléchargement',
    upload_fail_tip:
      'Le format du fichier téléchargé est incorrect. Veuillez vérifier le fichier CSV.',
    click_here_to: 'Cliquez ici pour',
    download: 'Télécharger',
    download_error: 'le fichier Excel des erreurs.',
    order_table: {
      date: 'Date',
      file_name: 'Nom du fichier',
      successful_orders: 'Commandes réussies',
      failed_orders: 'Commandes Échouées',
      action: 'Action'
    },
    pending: 'En attente',
    place_order: 'Placer commande',
    check_product: 'Vérifier le SKU d’un produit AliExpress',
    check: 'Vérifier',
    price: 'Prix',
    token_error:'Veuillez rafraichir la page pour télécharger le fichier de nouveau.'
  }
};
