import React from 'react';
import styles from './noproduct.less';
import nopage from 'assets/DetailNoPage.png';
import DetailHeader from './DetailHeader';
import tmallLogo from '../../../assets/supply/tmallLogo.svg';

function NoProduct() {
  return (
    <>
      <DetailHeader></DetailHeader>
      <img className={styles.tmalllogo} src={tmallLogo} alt="" />
      <div className={styles.container}>
        <img src={nopage} alt="" />
        Sorry, the page you requested can not be found :(</div>
    </>
  )
}

export default NoProduct