import React from 'react';
import { Modal } from 'antd';

import styles from './index.less';

function FeatureNotice({ modalProps, children, title, footer }) {
  return (
    <Modal width={436} centered footer={false} {...modalProps}>
      <div className={styles.featureNotice}>
        <h3 className={styles.title}>{title}</h3>
        <div className={styles.content}>{children}</div>
        <div className={styles.footer}>{footer()}</div>
      </div>
    </Modal>
  );
}

export default FeatureNotice;
