import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Select, Spin } from 'antd';
import { routerRedux } from 'dva/router';
import { connect } from 'dva';
import intl from 'react-intl-universal';

import DisableSearch from './DisableSearch';
import MessengerButton2 from './MessengerButton2';

import { Get, Post, Put } from '../../../utils/request';
import styles from './QuestionModal.less';

// import mockData from './mockData.json';

const QuestionModal = ({ visible, type, onCancel, dispatch }) => {
  const [questionList, setQuestionList] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [selectData, setSelectData] = useState([]);
  const [loading, setLoading] = useState(false);

  const taskRef = useRef({
    // 请求中的，避免重复请求
    // eslint-disable-next-line
    pending: new Set(),
    // 去重
    // eslint-disable-next-line
    result: new Set()
  });

  const okButtonClick = useRef(false);

  useEffect(() => {
    if (visible) {
      // eslint-disable-next-line
      fetchQuestionList();
    } else {
      // 关闭弹窗
      okButtonClick.current = false;

      setQuestionList([]);
      setResultData([]);
      setSelectData([]);
    }
  }, [visible]);

  useEffect(() => {
    if (selectData.length === 0) {
      setResultData([]);
      taskRef.current.result.clear();
    }
  }, [selectData]);

  function handleChange(value) {
    if (value.length === 0) {
      okButtonClick.current = false;
    }
    setSelectData(value);
  }

  async function fetchQuestionList() {
    try {
      const res = await Get('/helpcenter/howcanwehelp/titles');

      if (res.code === 2000 && res.data) {
        setQuestionList(res.data);
      }
    } catch (error) {
      console.log('fetch error:', error);
    }
  }

  async function fetchQuestionItem(ids) {
    setLoading(true);
    try {
      const res = await Get(`/helpcenter/howcanwehelp?${ids}`);

      if (res.code === 2000 && res.data) {
        setResultData(res.data);
        // if (!taskRef.current.result.has(id)) {
        //   taskRef.current.result.add(id);
        //   setResultData(state => state.concat({ ...res.data, key: id }));
        // }
      }
    } catch (error) {
      console.log('fetch error:', error);
    } finally {
      // taskRef.current.pending.delete(id);
      setLoading(false);
    }
  }

  function handleCancel() {
    window.dataLayer.push({
      event: 'helpCenterCustomEvent',
      type: 'dialogClose'
    });

    onCancel();
  }

  function handleSelect(value) {
    window.dataLayer.push({
      event: 'helpCenterCustomEvent',
      type: 'selectedIssue',
      payload: value
    });

    if (taskRef.current.pending.has(value.key)) {
      return;
    }

    taskRef.current.pending.add(value.key);
    fetchQuestionItem(value.key);
  }

  function handleDeselect(value) {
    taskRef.current.result.delete(value.key);
    const index = resultData.findIndex(i => i.key === value.key);
    if (index !== -1) {
      const tmp = resultData.slice();
      tmp.splice(index, 1);
      setResultData(tmp);
    }
  }

  function handleSearch() {
    okButtonClick.current = true;

    window.dataLayer.push({
      event: 'helpCenterCustomEvent',
      type: 'searchIssue'
    });

    const ids = selectData.map(i => `id=${i.key}`).join('&');
    fetchQuestionItem(ids);
  }

  function handleGetHelp() {
    window.dataLayer.push({
      event: 'helpCenterCustomEvent',
      type: 'getOnlineHelp'
    });

    dispatch(
      routerRedux.push({
        pathname: '/smarthelp',
        state: { fromIssueModal: true }
      })
    );
  }

  const count = selectData.length - 2;

  return (
    <Modal
      visible={visible}
      footer={null}
      width={680}
      maskClosable={false}
      keyboard={false}
      onCancel={handleCancel}
      bodyStyle={{ padding: '36px 36px' }}
      destroyOnClose
    >
      <h1 className={styles.title}>
        {intl.get('help_center.question_modal.title')}
      </h1>
      <div className={styles.main}>
        <p className={`${styles.fieldName} ${styles.require}`}>
          {intl.get('help_center.question_modal.tell_tip')}
        </p>
        <div className={styles.select}>
          <div className={styles.rowSelect}>
            <div className={styles.leftInput}>
              <DisableSearch>
                <Select
                  mode="tags"
                  className="hc_disable_input_select"
                  style={{ width: '100%' }}
                  size="large"
                  placeholder={intl.get(
                    'help_center.question_modal.tell_select_placeholer'
                  )}
                  onChange={handleChange}
                  allowClear
                  labelInValue
                  showArrow
                  maxTagCount={2}
                  maxTagTextLength={20}
                  maxTagPlaceholder="..."
                  // onSelect={handleSelect}
                  // onDeselect={handleDeselect}
                >
                  {questionList.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.title}
                    </Select.Option>
                  ))}
                </Select>
              </DisableSearch>
            </div>
            <Button
              type="primary"
              size="large"
              onClick={handleSearch}
              disabled={selectData.length === 0}
              className={styles.leftBtn}
            >
              {intl.get('public.ok')}
            </Button>
          </div>
          <div className={styles.tips}>
            &nbsp;
            {count > 0 ? `and other ${count} issues` : ''}
          </div>
        </div>
        <p className={styles.fieldName}>
          {intl.get('help_center.question_modal.our_suggestions')}
        </p>
        <div className={styles.container}>
          <Spin spinning={loading}>
            {resultData.length > 0 ? (
              <>
                <div className={styles.intro}>
                  {intl.get('help_center.question_modal.useful_tip')}
                </div>
                {resultData.map(item => (
                  <div className={styles.qItem} key={item.id}>
                    {item.how_can_we_help.line_items.map((subItem, i) => (
                      <p key={`${item.id}_${i}`}>
                        <a
                          href={subItem.link}
                          track-type="helpCenter"
                          track-name="checkIssue"
                          track-metadata-issue-link={item.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {subItem.title}
                        </a>
                      </p>
                    ))}
                  </div>
                ))}
              </>
            ) : (
              <div className={styles.placeholder}>
                <span className={styles.p_icon} />
                <p>
                  {intl.getHTML(
                    'help_center.question_modal.suggesttions_default_tip'
                  )}
                </p>
              </div>
            )}
          </Spin>
        </div>
        <p className={styles.fieldName}>{intl.get('common.or')}</p>
        <p className={styles.fieldName}>{intl.get('common.contact_us')}</p>
        <div className={styles.footer}>
          <Button
            size="large"
            onClick={handleGetHelp}
            style={{ width: 157 }}
            // disabled={!(selectData.length > 0 && okButtonClick.current)}
          >
            {intl.get('common.other')}
          </Button>
          <MessengerButton2
            type="primary"
            size="large"
            // onClick={this.handleMessage}
          >
            {intl.get('help_center.help_center.message_us')}
          </MessengerButton2>
        </div>
      </div>
    </Modal>
  );
};

export default connect(null)(QuestionModal);
