import getAdminRole from 'utils/getAdminRole';
import getUserInfo from 'utils/getUserInfo';

// window.DSER_SUPPLIER_OPTIMIZER = true;
// Supplier Optimizer灰度测试
const whiteList = [
  // 'br7ikbv2drrg00e63lvg'
  // 测试账户
  // 'br7ikbv2drrg00e63lvg',
  // 用户账户
  'bugktcrhk93g00aahf60'
];
// 添加灰度测试白名单
const checkUserWhiteList = () => {
  const currentUserId = getUserInfo()?.user_id;
  if (!currentUserId) return false;
  return whiteList.includes(currentUserId);
};

export const getDisplaySupplierOptimizer = () =>
  window.DSER_SUPPLIER_OPTIMIZER || getAdminRole() || checkUserWhiteList();
