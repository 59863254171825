import React from 'react';
import { connect } from 'dva';
import cloneDeep from 'lodash/cloneDeep';

import {
  Popover,
  Button,
  Icon,
  message,
  InputNumber,
  Radio,
  Modal,
  Switch,
  Tooltip,
  Collapse,
} from 'antd';

import qs from 'querystring';
import Ellipsis from 'components/Ellipsis';
import className from 'classnames';
import intl from 'react-intl-universal';

import ImportAliExpressMapping from './Import.js';
import Status from './Status.js';
import { replaceUrlToHttps,sendNoviceGuidanceInfo, CommonDataCollect } from 'utils/utils';

import {
  deleteBundle,
  deleteAdvance,
  hasDeleteVariant,
  postMappingProductRef,
  getMappingAvaliableStatus
} from './util.js';

import { webCustomCollect } from 'utils/utils'
import {getAePid} from 'routes/StoreManage/util'

import noticeIcon from 'assets/mapping/notice.png'
import searchIcon from 'assets/newImportList/icon-search.png';

import reactGaEvent from '../../utils/GAEvent';
import styles from './index.less';
import './mapping.css';
import { GuildeAction, LinkStoreAction } from 'actions'
const RadioGroup = Radio.Group;
const { confirm } = Modal;
const { Panel } = Collapse;

@connect(({ productmy, loading, user,login }) => ({
  productmy,
  loading: loading.models.productmy,
  saveLoading: loading.effects['productmy/ext'],
  noviceGuidanceInfo:user?.noviceGuidanceInfo,
  userInfo: login.userInfo,
  plan: user?.plan
}))
export default class Bogo extends React.PureComponent {
  cacheUrlFromDsers = {};

  constructor(props) {
    super(props);
    this.state = {
      bogo: {},
      showImport: false,
      getToolValue: undefined,
      buyMoreActionChecked: undefined,
      showSave: false,
      select:[],
      BogoMapping: false,
      deleteVariantIdMap:[],
      visibleDeletedAllVariant:false,
      isDeleteAll:false
    };
  }

  componentWillMount() {
    const { types } = this.props;
    this.setState({
      BogoMapping: getMappingAvaliableStatus(types).bogo
    });
    this.props.dispatch({
      type: 'productmy/LimitTheInterface',
      payload: {
        callback: d => {
          // console.log(d);
          if (d.code == 2000 || d.code == 2010) {
            this.setState({
              BogoMapping: d.data.confirm_url?.bogo
            });
          }
        }
      }
    });

    const {
      // types,
      originProductData,
      originProductData: {
        prod: {
          detail: { variants = [], id },
          ext: { bogo, map_type } = {}
        }
      }
    } = this.props;

    const deleteVariants = hasDeleteVariant(originProductData,'bogo');

    let mapType = map_type;
    if ([1, 4].includes(types)  && this.props?.plan?.trial_7_days_mapping_bogo_status !== 1) {
      mapType = 1;
    }

    this.variants = [...(variants.filter(i => !deleteVariants.find(d => d.id === i.id))), ...deleteVariants];
    this.map_type = mapType;
    this.save_map_type = mapType;
    this.shopifyProductId = id;

    this.setRemovedVariant(originProductData);

    const useBogo = this.setSupply(bogo);

    this.skuHasIsFbd(useBogo);

    this.setState({
      bogo: useBogo
    });
  }

  componentDidMount() {
    const {plan} = this.props
    if(plan?.trial_7_days_mapping_bogo_status == 1){
      this.setState({
        BogoMapping: true
      })
    }
    this.props.onRef(this);
  }

  save_map_type = 0;
  map_type = 0;
  variants = [];
  importVariantId = '';
  importVariantSupplyIndex = 0; // 0 buy  1 get
  importProductId = ''; // 打开导入商品时原来的mapping的ae商品id
  platformProductId;
  cacheQtyNo = 1;
  callbackData = null; // 导入的赠品
  getVaniantIndex = 0; // 设置赠品的索引
  hasSave = false;
  currentDeleteVariantId = '';
  buySp = '';

  // 有没有被删除的variant
  setRemovedVariant = originProductData => {
    const { variant } = originProductData;

    if (!variant) {
      return;
    }

    let flag = false;

    this.variants.forEach(item => {
      if (item.id == variant.variant_id) {
        flag = true;
        item.title = variant.Title;
      }
    });

    flag == false &&
      this.variants.push({
        id: variant.variant_id,
        title: variant.Title,
        is_del: true
      });
  };

  // 设置的供应商
  skuHasIsFbd = data => {
    const { fbdSupply } = this.props;

    if (!fbdSupply[this.shopifyProductId]) {
      return;
    }

    const sd = fbdSupply[this.shopifyProductId];

    Object.entries(data).forEach(item => {
      const variantId = item[0];
      const variant_supply = item[1].variant_supply;
      const variant_supply_supply = variant_supply[0].supply;

      const sdList = sd[variantId];

      if (!sdList) {
        return;
      }

      variant_supply_supply.forEach(vs => {
        let flag = false;

        sdList.forEach(FBD => {
          if (
            FBD.origin_product_id == vs.platform_product_id &&
            FBD.origin_sku == vs.sku
          ) {
            flag = true;
          }
        });

        if (flag) {
          vs.isFBD = true;
        } else {
          vs.isFBD = false;
        }
      });
    });
  };

  // 设置供应商
  setSupply = (bogo = {}) => {
    this.variants.forEach(item => {
      const sp = bogo[item.id];
      if (sp) {
        this.hasSupply(sp.variant_supply, item);
      } else {
        bogo[item.id] = { variant_supply: [] };
        this.hasSupply(bogo[item.id].variant_supply, item);
      }
    });

    return bogo;
  };

  // 要不要添加新的一个供应商
  hasSupply = (variant_supply, variant) => {
    if (variant_supply.length == 0) {
      this.addSupply(variant_supply, variant);
    } else {
      variant_supply[0].supply.forEach((sp, i) => {
        if (i == 0) {
          sp.is_base = true;
        }
        sp.variant_title = variant.title;
      });

      if (variant_supply[0].supply.length == 1) {
        variant_supply[0].supply.push({
          platform_type: 2,
          platform_product_id: '',
          platform_sale_id: '',
          quantity: 0,
          sku: '',
          sku_desc: '',
          variant_id: variant.id,
          variant_title: variant.title,
          is_base: false,
          freights: [],
          sku_image: '',
          title: ''
        });
      }
    }
  };

  // 添加一个供应商
  addSupply = (variant_supply, variant) => {
    variant_supply.push({
      countries: ['GLOBAL'],
      supply: [
        {
          platform_type: 2,
          platform_product_id: '',
          platform_sale_id: '',
          quantity: 0,
          sku: '',
          sku_desc: '',
          variant_id: variant.id,
          variant_title: variant.title,
          is_base: true,
          freights: [],
          sku_image: '',
          title: ''
        },
        {
          platform_type: 2,
          platform_product_id: '',
          platform_sale_id: '',
          quantity: 0,
          sku: '',
          sku_desc: '',
          variant_id: variant.id,
          variant_title: variant.title,
          is_base: false,
          freights: [],
          sku_image: '',
          title: ''
        }
      ]
    });
  };

  // 点击sku button
  showImportSupply = (
    variantId,
    spIndex,
    platform_product_id,
    i,
    buySp
  ) => {
    if (this.state.BogoMapping) {
      console.log( spIndex, platform_product_id, i, buySp, 'arinatid');
      // spIndex  0 buy  1 get
      this.importVariantId = variantId;
      this.importVariantSupplyIndex = spIndex;
      this.importProductId = platform_product_id;
      this.buySp = buySp;
      // if (spIndex > 0) {
        this.getVaniantIndex = i;
      // }
      this.setState({
        showImport: true
      });
    } else {
      localStorage.setItem('passivity', 'true');
      //  跳转订阅 && 弹窗拦截
      CommonDataCollect({
        action: 'BOGO-M-top',
        event_type:'overlimit_pop'
      });
      // window.EVENT.emit(GuildeAction.TurnPlan_Pop, `BOGO-M&${Date.now()}`, true);
      //  self.location.href = '/app/pricing?select=1';

      const { plan } = this.props

      if([1,10, 4, 8,12,13,14,15,16,17].includes(plan?.type)){
        window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'BogoMapping')
      }

    }
  };

  hideImportSupply = () => {
    this.setState({
      showImport: false
    });
  };

  getIsSame = (data, callback) => {
    let flag = false;
    if (
      data.sku == callback.sku &&
      data.platform_product_id == callback.platform_product_id
    ) {
      message.error(intl.get('mapping.same_sku_tip'));
      flag = true;
    }
    return flag;
  };

  // 导入供应商
  importCallback = (data, refFromDsers, select) => {
    if (refFromDsers) {
      this.cacheUrlFromDsers[data.platform_product_id] = refFromDsers;
    }

    this.hideImportSupply();
    const { bogo, getToolValue } = this.state;

    const otherOne = this.importVariantSupplyIndex ? 0 : 1;
    const sp = bogo[this.importVariantId].variant_supply[0].supply;
    const oneSp = sp[this.importVariantSupplyIndex];
    const otherSp = sp[otherOne];

    if (this.importVariantSupplyIndex == 1 && this.getVaniantIndex == 1) {
      this.callbackData = { ...data };
    }

    // 赠品不能和主商品的sku一样
    if (!this.getIsSame(otherSp, data, oneSp)) {
      Object.assign(oneSp, data);
      if (oneSp.quantity == 0) {
        oneSp.quantity = 1;
      }
    }

    if (this.importVariantSupplyIndex == 0 && getToolValue == 2) {
      this.callbackDataToGetSp(oneSp, otherSp);
    }

    if (this.importVariantSupplyIndex == 1 && this.getVaniantIndex == 0){
      this.copyFirstToGet();
    }

    if (this.importVariantSupplyIndex == 0 && this.getVaniantIndex == 0) {
      console.log(this.getVaniantIndex,'this.getVaniantIndex');
      console.log('yes')
      this.setFirstBuyToMore(oneSp);
    }

    this.setState({
      select,
    })
    this.showSaveBtn();
  };

  callbackDataToGetSp = (buySp, getSp) => {
    if (!this.callbackData) {
      return;
    }

    if (this.getIsSame(buySp, this.callbackData)) {
      return;
    }

    Object.assign(getSp, this.callbackData);
  };

  // 改变数量
  changeQty = (variantId, spIndex, v) => {
    const { bogo } = this.state;
    const sp = bogo[variantId].variant_supply[0].supply;
    const other = spIndex ? 0 : 1;

    const nowSp = sp[spIndex];
    const otherSp = sp[other];

    if (
      nowSp.platform_product_id == otherSp.platform_product_id &&
      nowSp.sku == otherSp.sku
    ) {
      message.error(intl.get('mapping.same_sku_tip'));
      return;
    }

    nowSp.quantity = v;

    this.setState({
      bogo: { ...bogo }
    });

    this.showSaveBtn();
  };

  // 删除供应商
  deleteSp = (variantId, spIndex, variantIndex) => {
    if (this.state.BogoMapping) {
      const { bogo, buyMoreActionChecked } = this.state;

      const sp = bogo[variantId].variant_supply[0].supply[spIndex];
      const obj = {
        platform_product_id: '',
        platform_sale_id: '',
        sku: '',
        sku_image: '',
        title: '',
        sku_desc: '',
        quantity: 0
      };

      if (spIndex == 0) {
        const getSp = bogo[variantId].variant_supply[0].supply[1];
        Object.assign(getSp, obj);
      }

      Object.assign(sp, obj);

      if (buyMoreActionChecked && variantIndex == 0) {
        console.log(111);
        this.setFirstBuyToMore(sp);
      }

      this.setState({
        bogo: { ...bogo }
      });

      this.showSaveBtn();
    } else {
      localStorage.setItem('passivity', 'true');
      //  跳转订阅 && 弹窗拦截
      CommonDataCollect({
        action: 'BOGO-M-top',
        event_type:'overlimit_pop'
      });
      window.EVENT.emit(GuildeAction.TurnPlan_Pop, `BOGO-M&${Date.now()}`, true);
      //  self.location.href = '/app/pricing?select=1';
    }
  };

  cacheQty = v => {
    console.log(v,'v')
    this.cacheQtyNo = v;
  };

  qtyComponent = spIndex => {
    return (
      <div>
        {intl.get('mapping.change_qty_to')}
        <InputNumber
          defaultValue={1}
          size="small"
          min={1}
          className={styles.cacheQtyInput}
          onChange={this.cacheQty}
        />
        <Button
          size="small"
          type="primary"
          onClick={e => this.setAllQty(spIndex)}
        >
          {intl.get('public.save')}
        </Button>
      </div>
    );
  };


  // 把主商品设置成和第一个一样的
  setFirstBuyToMore = (originalSp = {}) => {

    const sampleSp = { ...originalSp };

    const { bogo, buyMoreActionChecked } = this.state;
    
    if (!buyMoreActionChecked) {
      return;
    }

    this.variants.forEach((item, i) => {
      // if (i == 0) {
      //   return;
      // }
      let sampleSpCopy = cloneDeep(sampleSp)
      const sp = bogo[item.id].variant_supply[0].supply;
      // const qty = sampleSp.sku ? sp[0].quantity || 1 : 0;

      // 赠品不能和主商品一样
      if (
        sampleSpCopy.sku == sp[1].sku &&
        sampleSpCopy.platform_product_id == sp[1].platform_product_id &&
        sampleSpCopy.sku
      ) {
        return;
      }

      // delete sampleSp.variant_id;
      sampleSpCopy.variant_id = item.id;
      sampleSpCopy.variant_title = item.title;


      Object.assign(sp[0], sampleSpCopy);
      // sp[0].quantity = qty;

      // 清空第一个sku的设置
      if (!sampleSp.sku) {
        Object.assign(sp[1], sampleSp);
        sp[1].is_base = false;
        sp[1].quantity = 0;
      }
    });
    this.setState({
      bogo: { ...bogo }
    });
  };

  /**
   * get 把赠品设置成和第一个一样
   * @returns 
   */
  copyFirstToGet = () => {
    const { bogo, getToolValue } = this.state;

    let errorStatus = false;

    if(!getToolValue){
      return;
    }
    
    let sampleSp =
      bogo[this.variants[0].id].variant_supply[0].supply[1];

    this.variants.forEach(item => {
      const sp = bogo[item.id].variant_supply[0].supply;
      const buySp = sp[0];
      const getSp = sp[1];
      let sampleSpCopy = cloneDeep(sampleSp)
      // 判断不能和主商品相同
      if (
        sampleSpCopy.sku == sp[0].sku &&
        sampleSpCopy.platform_product_id == sp[0].platform_product_id &&
        sampleSpCopy.sku
      ) {
        if (!errorStatus) {
          errorStatus = true;
          message.error(intl.get('mapping.same_sku_tip'));
        }
        return;
      }

      // delete sampleSp.variant_id;
      sampleSpCopy.variant_id = item.id;
      sampleSpCopy.variant_title = item.title;
      console.log(getSp,'getSp');
      if (buySp.sku) {
        Object.assign(getSp, { ...sampleSpCopy });
      }
    });

    errorStatus = false;
    this.setState({
      bogo: { ...bogo }
    }, () => {
      console.log(this.state.bogo,'-=-=-=bogo 赠品');
    });
  };

  changeGetTool = e => {
    const { getToolValue } = this.state;

    const v = e;
    if (getToolValue == v) {
      return;
    }

    // 交互打点
    CommonDataCollect({
      user_id: this.props.userInfo.user_id,
      sale_platform: 'shopify',
      supply_platform: 'aliexpress',
      trans_from: 'bogo mapping click get copy button',
      action: 'click_bogo_get_copy_button',
      event_type: 'interactive'
    });

    this.setState({
      getToolValue: v
    },() => {
      this.copyFirstToGet();
    });

    this.showSaveBtn();
  };

  handleRadio = e => {
    const { getToolValue } = this.state;

    if (getToolValue == e) {
      this.callbackData = null;
      this.setState({
        getToolValue: undefined
      });
    }
  };

  // getComponent = getToolValue => {
  //   return (
  //     <div className={styles.getTool}>
  //       <a
  //         className={styles.clearSp}
  //         role="button"
  //         tab-index="0"
  //         onClick={e => this.clearGetAll()}
  //       >
  //         <Icon type="delete" />
  //         {intl.get('mapping.clear_all')}
  //       </a>
  //       <RadioGroup value={getToolValue} onChange={this.changeGetTool}>
  //         {/* <Radio
  //                 value="1"
  //                 onClick={ this.handleRadio }
  //               >
  //                 Same with main product for each SKU
  //               </Radio> */}
  //         <Radio value="2" onClick={this.handleRadio}>
  //           {intl.getHTML('mapping.get_more_action_copy')}
  //         </Radio>
  //       </RadioGroup>
  //     </div>
  //   );
  // };

  // 取消 BUY more action 的选中状态
  canceledBuyMoreAction = e => {
    const { buyMoreActionChecked } = this.state;

    if (buyMoreActionChecked == e) {
      this.setState({
        buyMoreActionChecked: undefined
      });
    }
  };

  // 设置 buy more action快捷键为选中状态
  changeBuyMoreAction = e => {
    const { bogo } = this.state;
    const sampleSp =
      bogo[this.variants[0].id].variant_supply[0].supply[0];

       // 交互打点
    CommonDataCollect({
      user_id: this.props.userInfo.user_id,
      sale_platform: 'shopify',
      supply_platform: 'aliexpress',
      trans_from: 'bogo mapping click buy copy button',
      action: 'click_bogo_buy_copy_button',
      event_type: 'interactive'
    });

    this.setState(
      {
        buyMoreActionChecked: e
      },
      () => {
        this.setFirstBuyToMore(sampleSp);
      }
    );

    this.showSaveBtn();
  };

  buyMoreAction = buyMoreActionChecked => {
    return (
      <div className={styles.getTool}>
        <RadioGroup
          value={buyMoreActionChecked}
          onChange={this.changeBuyMoreAction}
        >
          <Radio value="1" onClick={this.canceledBuyMoreAction}>
            {intl.getHTML('mapping.buy_more_action_copy')}
          </Radio>
        </RadioGroup>
      </div>
    );
  };

  clearGetAll = () => {
    const { bogo } = this.state;

    this.variants.forEach(item => {
      const sp = bogo[item.id].variant_supply[0].supply;
      const getBuy = sp[0];
      const getSp = sp[1];
      const obj = {
        platform_product_id: '',
        platform_sale_id: '',
        sku: '',
        sku_image: '',
        title: '',
        sku_desc: '',
        quantity: 0
      };
      Object.assign(getBuy, obj);
      Object.assign(getSp, obj);
    });

    this.setState({
      bogo: { ...bogo }
    });
    this.showSaveBtn();
  };

  setAllQty = spIndex => {
    const { bogo } = this.state;

    console.log(this.cacheQtyNo,'cacheQtyNo-=-=')
    this.variants.forEach(item => {
      const sp = bogo[item.id].variant_supply[0].supply[spIndex];
      if (sp.sku) {
        sp.quantity = this.cacheQtyNo;
      }
    });

    this.setState({
      bogo: { ...bogo }
    },() => {
      console.log(this.state.bogo,'-=-=-=bogoset')
    });

    this.showSaveBtn();
  };

  changeStatus = (v, save_map_type, t) => {
    this.map_type = save_map_type;
    this.save_map_type = save_map_type;
    // this.showSaveBtn();

    if (v) {
      this.props.changeMappingStatus(save_map_type);
      // this.handleSave();
      reactGaEvent({
        category: 'Mapping',
        action: 'open bogo'
      });
      return;
    }
    if (t == 1) {
      this.props.changeMappingStatus(save_map_type);
      // this.handleSave();
      reactGaEvent({
        category: 'Mapping',
        action: 'close bogo'
      });
    }
  };

  bogoExt = undefined

  // 点击保存
  handleSave = () => {
    const { bogo } = this.state;
    const copybogo = cloneDeep(bogo);

    const {
      originProductData,
      originProductData: { prod: { ext,store_id},storeId },
      noviceGuidanceInfo
    } = this.props;
    
    const copyExt = cloneDeep(ext);
    const { bundle, advance } = copyExt;
    
    // 删除空数据 没有设置的数据
    if (bundle) {
      deleteBundle(bundle, this.variants);
    }
    
    // 删除空数据 没有设置的数据
    if (advance) {
      deleteAdvance(advance, this.variants);
    }
    
    if(noviceGuidanceInfo.mapped == 0){
      sendNoviceGuidanceInfo({mapped : 1},()=>{
        this.props.dispatch({ type:'user/getNoviceGuidance'})
      })
    }

    const shouldPostRef = {};

    this.variants.forEach(item => {
      const { variant_supply } = copybogo[item.id];

      if (!variant_supply[0].supply[1].sku) {
        variant_supply[0].supply.splice(1, 1);
      }

      if (!variant_supply[0].supply[0].sku) {
        variant_supply[0].supply = [];
      }

      if (variant_supply[0].supply.length == 3) {
        variant_supply[0].supply.length = 2;
      }

      if (variant_supply[0].supply.length == 0) {
        delete copybogo[item.id];
      } else {
        variant_supply[0].supply.forEach(supplyItem => {
          const aeProductId = supplyItem.platform_product_id;
          if (this.cacheUrlFromDsers[aeProductId]) {
            shouldPostRef[aeProductId] = this.cacheUrlFromDsers[aeProductId];
          }
        });
      }
    });

    if (Object.values(copybogo).length != 0) {
      copyExt.bogo = copybogo;
    } else {
      delete copyExt.bogo;
    }

    copyExt.map_type = this.save_map_type;

    // this.props.setLoading(true);

    this.hasSave = true;
    this.props.setHasSave(this.hasSave);
    for(let i in copyExt){
      if(i !== 'bogo'){
        delete copyExt[i]
      }
    }

    this.bogoExt = copyExt

    // this.props.dispatch({
    //   type: 'productmy/ext',
    //   payload: {
    //     data: {
    //       product_id: originProductData.id,
    //       ext: copyExt,
    //       store_id,
    //       ref:localStorage.getItem('mapping_dot')
    //     },
    //     callback: d => {
    //       // this.props.setLoading(false);
    //       if (d.code == 2000 || d.code == 2010) {
    //         message.success(intl.get('mapping.succeed'));
    //         postMappingProductRef(shouldPostRef, 3, originProductData);
    //         webCustomCollect({
    //           action:"mapping_dot_1",
    //           custom_info:[
    //             {
    //               name:localStorage.getItem('mapping_dot'),
    //               value:'mappinged'
    //             }
    //           ]
    //         })
    //         this.props.hasSavedHandler('bogo')
    //         if(window.location.pathname.substring(window.location.pathname.lastIndexOf("/")+1).match(/myproduct/)){
    //           window.IS_UPDATE_IMPORT_PRODUCT = true
    //           setTimeout(()=>{
    //             window.IS_UPDATE_IMPORT_PRODUCT = false
    //           },15*1000)
    //         }
    //       }
    //     }
    //   }
    // });
  };

  // 显示保存按钮
  showSaveBtn = () => {
    this.setState({
      showSave: true
    });
    this.props.showSaveBtn()
    this.props.changemodalHidden('bogo')
  };

  // 是否应用了dsers供应商
  getIsFBD = () => {
    const {
      fbdSupply,
      originProductData: { prod: { detail: { id } } }
    } = this.props;

    let flag = false;

    if (fbdSupply[id]) {
      flag = true;
    }

    return flag;
  };
  onBogoChild = ref => {
    this.bogos = ref;
  };
  switchTabCheckBogo = flag => {
    this.bogos.changeChecked(flag);
  };

  // 是否删除在shopify已经删除的variant
  deleteVariant = variantId => {
    this.currentDeleteVariantId = variantId;
    this.setState({
      visibleDeletedAllVariant:true,
      isDeleteAll:false
    })
  };

  // 删除在shopify已经删除的variant
  deleteVariantTrue = () => {
    const { bogo } = this.state;

    this.variants = this.variants.filter(
      item => item.id != this.currentDeleteVariantId
    );
    delete bogo[this.currentDeleteVariantId];

    this.setState({
      bogo: { ...bogo },
      deleteVariantIdMap:[...this.state.deleteVariantIdMap,this.currentDeleteVariantId],
      visibleDeletedAllVariant:false
    });
    this.showSaveBtn();
  };

  deleteAllDelVariant = (e)=>{
    
    const { bogo } = this.state;
    const del = []
    this.variants.forEach(item => {
        if(item.is_del){
         del.push(item.id)
         delete bogo[item.id]
        }
      }
    );

    this.variants = this.variants.filter(
      item => !del.includes(item.id)
    );

    this.setState({
      bogo: { ...bogo },
      deleteVariantIdMap:del,
      visibleDeletedAllVariant:false
    });
    this.showSaveBtn();
  }

  checkDeleteVariants = (v)=>{
    this.props.checkVariantStatusHandler(v)
  }

  getCollapseStatus = ()=>{
    if(this.props.checkVariantStatus === true){
      return ['1']
    }else if(this.props.checkVariantStatus === false){
      return []
    }else if(this.variants.filter(it => it.is_del).length > 4){
      return []
    }else{
      return ['1']
    }
  }

  openSupplyOptimizer=(prod,type)=>{
    if(type){
      return
    }
    if(!prod?.prod?.suppliers?.length && prod?.prod?.supplier_type === 2){
      window.dtag.set({
        event:'click',
        module:'Aliexpress',
        action:'Aliexpress_figure_search',
        custom_data:{
          'click_type': 'go_supplier_optimizer_mapping'
        }
      })
      const paramObj = {
        url: prod.image,
        ref: 'my_product',
        id: prod.ae_pid,
        store_id: localStorage.getItem('storeId')
      };
      const paramStr = qs.stringify(paramObj);
      const link = `/app/supplier_optimizer?${paramStr}`;
      window.open(link);
      return
    }
    if((!prod?.prod?.suppliers?.length && window.TMALL_USER) || (prod?.prod?.suppliers?.includes(1) && window.TMALL_USER)){
      window.dtag.set({
        event:'click',
        module:'Aliexpress',
        action:'Aliexpress_figure_search',
        custom_data:{
          'click_type': 'go_supplier_optimizer_mapping'
        }
      })
      const paramObjTmall ={
        url:  prod.image,
        ref: 'import_list',
        supplierType:"unmap",
        store_id: localStorage.getItem('storeId')
      }
      const paramStrTmall = qs.stringify(paramObjTmall)
      window.open(`/app/supplier_optimizer?${paramStrTmall}`)
      return
    }
    if(prod?.prod?.suppliers?.includes(4)){
      window.dtag.set({
        event:'click',
        module:'tmall',
        action:'tmall_figure_search',
        custom_data:{
          'click_type': 'go_supplier_optimizer_mapping'
        }
      })
      const paramObjTmall ={
        url:`https://www.dsers.com/app/tmallproduct/${getAePid(prod.prod)}?appId=159831084`,
        ref: 'import_list',
        supplierType:"tmall",
        store_id: localStorage.getItem('storeId')
      }
      const paramStrTmall = qs.stringify(paramObjTmall)
      window.open(`/app/supplier_optimizer?${paramStrTmall}`)
      return
    }
      window.dtag.set({
        event:'click',
        module:'Aliexpress',
        action:'Aliexpress_figure_search',
        custom_data:{
          'click_type': 'go_supplier_optimizer_mapping'
        }
      })
    
    const paramObj = {
      url: prod.image,
      ref: 'my_product',
      id: prod.ae_pid,
      store_id: localStorage.getItem('storeId')
    };
    const paramStr = qs.stringify(paramObj);
    const link = `/app/supplier_optimizer?${paramStr}`;
    window.open(link);
  }

  render() {
    const {
      originProductData = {},
      saveLoading,
      copyLink,
      hideSupplier,
      publicBtnDispath,
      switchoverMapping
    } = this.props;

    const {
      bogo,
      showImport,
      getToolValue,
      buyMoreActionChecked,
      showSave,
      BogoMapping
    } = this.state;
    // 从订单页打开mapping 禁用删除variant按钮
    const { deleteVariantButtonDisabled, orderVaraintId } = originProductData;
    const shopifyIsFBD = this.getIsFBD(); // 这个shopify商品有没有dsers供应商
    const useSupplierStyle = !originProductData.image.includes('https:') ?'not-allowed' :'pointer'
    return (
      <>
        <div className={styles.mappingSatusLine}  id='bogoSwitchStatus'>
          <Status
            Mapping={BogoMapping}
            self_type={3}
            map_type={this.map_type}
            changeStatus={this.changeStatus}
            isFBD={shopifyIsFBD}
            onRef={this.onBogoChild}
            btnDispath={publicBtnDispath}
            switchoverMapping={switchoverMapping}
            switchoverMappingId='bogoSwitchStatus'
          />
        </div>
        <div className={styles.bogo}>
          {/* 表头 */}
          <div className={styles.productLine}>
            <div className={styles.product}>
              <p className="h3_line">{intl.get('mapping.ShopifyProduct')}</p>
              <div className="topIc">
                <Popover
                  placement="left"
                  trigger="hover"
                  overlayClassName={styles.zoomProductImg}
                  content={
                    <img src={replaceUrlToHttps(originProductData.image)} />
                  }
                >
                  {
                  originProductData.prod?.ext?.map_type === 6 ? (
                    <img src={replaceUrlToHttps(originProductData.image)} />
                  ) : (
                    <span className={styles.shopifyPic}>
                      <img src={replaceUrlToHttps(originProductData.image)} />
                      <Tooltip title={intl.get('mapping.Findsimilar')}>
                        <span className={styles.findSupplier} style={{cursor:useSupplierStyle}} onClick={()=>this.openSupplyOptimizer(originProductData,!originProductData.image.includes('https:'))}>
                          <i class="material-icons" style={{cursor:useSupplierStyle}}>search</i>
                        </span>
                      </Tooltip>
                    </span>
                  )}
                </Popover>
                <a
                  role="button"
                  tab-index="0"
                  className={styles.productLinkC}
                  onClick={e => copyLink(originProductData)}
                >
                  <Ellipsis lines={4}>{originProductData.title}</Ellipsis>
                </a>
              </div>
            </div>
            <div className={className(styles.toolTitle, styles.buy)}>
              <p className="h3_line">{intl.get('mapping.buy')}</p>
              <div className="topb">
                <p className="topIcK">{intl.get('mapping.buy_desc')}</p>
                <p className="topjwd">
                  {intl.getHTML('mapping.buy_more_action_copy')}
                </p>
                <div className="switch">
                  <Switch
                    onChange={checked => {
                      this.canceledBuyMoreAction(checked);
                      this.changeBuyMoreAction(checked);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className={className(styles.toolTitle, styles.get)}>
              <p className="h3_line">
                {intl.get('mapping.get')}
                <span className="del-top" onClick={e => this.clearGetAll()}>
                  <i class="material-icons notranslate">delete</i>
                </span>
              </p>
              <div className="topb">
                <p className="topIcK">{intl.get('mapping.get_desc')}</p>
                <p className="topjwds">
                  {intl.get('mapping.get_more_action_copy')}
                </p>
                <div className="switch">
                  <Switch
                    onChange={checked => {
                      this.changeGetTool(checked);
                      this.handleRadio(checked);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={styles.variantWrap}
            style={{ height: 'calc( 100vh - 360px )' }}
          >
            <div className="line_title">
              <p className="one" style={{ height: 48 }}>
                {intl.get('mapping.variant')}
              </p>
              <p className="two" style={{ height: 48 }}>
                {intl.get('mapping.main_product')}
                <Popover
                  content={this.qtyComponent(0)}
                  trigger="click"
                  placement="bottom"
                  overlayClassName={styles.bogoTool}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                >
                  <a className="quanit">
                    Quantity{' '}
                    <img
                      src={require('../../assets/newImportList/icon_expand_more-24px.png')}
                    ></img>
                  </a>
                </Popover>
              </p>
              <p className="line_box" style={{ height: 48 }}>
                {intl.get('mapping.gift_product')}
                <Popover
                  content={this.qtyComponent(1)}
                  trigger="click"
                  placement="bottomRight"
                  overlayClassName={styles.bogoTool}
                  getPopupContainer={triggerNode => triggerNode.parentNode}
                >
                  <a className="quanit">
                    Quantity{' '}
                    <img
                      src={require('../../assets/newImportList/icon_expand_more-24px.png')}
                    ></img>
                  </a>
                </Popover>
              </p>
            </div>
            <div>
              {this.variants
                .filter(it => !it.is_del)
                .map((variant, i) => {
                  const isOrderVaraint = variant.id === orderVaraintId;
                  return (
                    <div
                      className={className(styles.variantLine, {
                        [styles.removeVariantLine]: variant.is_del
                      })}
                      key={variant.id}
                    >
                      <p
                        className={className(styles.variantName, {
                          [styles.orderVariant]: isOrderVaraint
                        })}
                      >
                        {variant.title}
                      </p>

                      {bogo[variant.id].variant_supply.map((sp, index) => {
                        const buySp = sp.supply[0];
                        const getSp = sp.supply[1] || {};
                        const disabled = !buySp.sku;
                        const getDisabled = !getSp.sku;
                        return (
                          <div
                            key={`${variant.id}Sps`}
                            className={styles.variantSps}
                          >
                            <div className={styles.buy}>
                              {buySp.sku_image ? (
                                <div class="tag_vbox">
                                  <div
                                    onClick={e => {
                                      this.showImportSupply(
                                        variant.id,
                                        index === 0 ? 0 : 1,
                                        buySp.platform_product_id,
                                        i,
                                        buySp
                                      );
                                      const custom_info = [
                                        { name: 'mapping type', value: 'Bogo' }
                                      ];

                                      window.DSERS_CUSTOM_COLLECT({
                                        module: 'product mapping',
                                        action: 'click supplier button',
                                        custom_info
                                      });
                                    }}
                                  >
                                    <Popover
                                      placement="left"
                                      trigger="hover"
                                      content={
                                        <img
                                          src={replaceUrlToHttps(
                                            buySp.sku_image
                                          )}
                                        />
                                      }
                                      overlayClassName={styles.zoomProductImg}
                                    >
                                      <img
                                        src={replaceUrlToHttps(buySp.sku_image)}
                                        className={styles.skuImg}
                                      />
                                    </Popover>
                                    <span className="yexy_textdw">
                                      {buySp.sku_desc}
                                    </span>
                                  </div>
                                  <span
                                    className="consw_icon"
                                    onClick={() => {
                                      this.deleteSp(variant.id, 0, i);
                                    }}
                                  >
                                    <i class="material-icons">close</i>
                                  </span>
                                </div>
                              ) : (
                                <Tooltip
                                  title={intl.get(
                                    'mapping.import_sp_select_var'
                                  )}
                                >
                                  <Button
                                    disabled={buySp.isFBD}
                                    size="small"
                                    className={className({
                                      [styles.fbdSku]: buySp.isFBD
                                    })}
                                    onClick={e => {
                                      this.showImportSupply(
                                        variant.id,
                                        index === 0 ? 0 : 1,
                                        buySp.platform_product_id,
                                        i,
                                        buySp
                                      );
                                      const custom_info = [
                                        { name: 'mapping type', value: 'Bogo' }
                                      ];

                                      window.DSERS_CUSTOM_COLLECT({
                                        module: 'product mapping',
                                        action: 'click supplier button',
                                        custom_info
                                      });
                                    }}
                                  >
                                    <span className="btnAdd">
                                      <i class="material-icons">add</i>
                                    </span>
                                    <span className="yexy_textdwqw">
                                      {intl.get('mapping.import_sp_select_var')}
                                    </span>
                                  </Button>
                                </Tooltip>
                              )}

                              {buySp.isFBD ? (
                                <a
                                  className={styles.fbd}
                                  href="/app/fulfillment-center"
                                >
                                  FBD
                                </a>
                              ) : null}
                            </div>
                            <div className={styles.buyQty}>
                              <InputNumber
                                size="small"
                                min={disabled ? 0 : 1}
                                disabled={disabled}
                                value={buySp.quantity}
                                precision={0}
                                onChange={v => {
                                  this.changeQty(variant.id, 0, v);
                                }}
                              />
                            </div>
                            <div className={styles.get}>
                              {getSp.sku_image ? (
                                <div class="tag_vbox">
                                  <div
                                    onClick={e =>
                                      this.showImportSupply(
                                        variant.id,
                                        1,
                                        getSp.platform_product_id,
                                        i,
                                        getSp
                                      )
                                    }
                                  >
                                    <Popover
                                      placement="left"
                                      trigger="hover"
                                      content={
                                        <img
                                          src={replaceUrlToHttps(
                                            getSp.sku_image
                                          )}
                                        />
                                      }
                                      overlayClassName={styles.zoomProductImg}
                                    >
                                      <img
                                        src={replaceUrlToHttps(getSp.sku_image)}
                                        className={styles.skuImg}
                                      />
                                    </Popover>
                                    <span className="yexy_textdw">
                                      {getSp.sku_desc}
                                    </span>
                                  </div>
                                  <span
                                    className="consw_icon"
                                    onClick={() => {
                                      this.deleteSp(variant.id, 1);
                                    }}
                                  >
                                    <i class="material-icons">close</i>
                                  </span>
                                </div>
                              ) : (
                                <Tooltip
                                  title={intl.get(
                                    'mapping.import_sp_select_var'
                                  )}
                                >
                                  <Button
                                    size="small"
                                    disabled={disabled || getSp.isFBD}
                                    onClick={e =>
                                      this.showImportSupply(
                                        variant.id,
                                        1,
                                        getSp.platform_product_id,
                                        i,
                                        getSp
                                      )
                                    }
                                    className={className({
                                      [styles.fbdSku]: getSp.isFBD
                                    })}
                                    title={
                                      getSp.sku_desc ||
                                      intl.get('mapping.import_sp_select_var')
                                    }
                                  >
                                    <span className="btnAdd">
                                      <i class="material-icons">add</i>
                                    </span>
                                    <span className="yexy_textdwqw">
                                      {intl.get('mapping.import_sp_select_var')}
                                    </span>
                                    {/* {intl.get('mapping.import_sp_select_var')} */}
                                  </Button>
                                </Tooltip>
                              )}
                              {getSp.isFBD ? (
                                <a
                                  className={styles.fbd}
                                  href="/app/fulfillment-center"
                                >
                                  FBD
                                </a>
                              ) : null}
                            </div>
                            <div className={styles.getQty}>
                              <InputNumber
                                size="small"
                                min={getDisabled ? 0 : 1}
                                disabled={getDisabled}
                                value={getSp.quantity}
                                precision={0}
                                onChange={v => {
                                  this.changeQty(variant.id, 1, v);
                                }}
                              />
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
            </div>
            {this.variants.filter(it => it.is_del).length ? (
              <div
                className={styles.deletedVariantCon}
                style={{ width: '100%' }}
              >
                <Collapse
                  activeKey={this.getCollapseStatus()}
                  onChange={this.checkDeleteVariants}
                  bordered={false}
                  expandIconPosition={'left'}
                >
                  <Panel
                    header={
                      <div className={styles.description}>
                        <span>Variants deleted at Shopify</span>
                        <Tooltip
                          title={intl.getHTML('mapping.delete_variant_collapse_supplier_tip')}
                        >
                          <img src={noticeIcon} alt="" />
                        </Tooltip>
                      </div>
                    }
                    key="1"
                    extra={
                      <i
                        class="material-icons"
                        onClick={e => {
                          e.stopPropagation();
                          this.setState({
                            isDeleteAll:true,
                            visibleDeletedAllVariant: true
                          });
                        }}
                      >
                        delete
                      </i>
                    }
                  >
                    {this.variants
                      .filter(it => it.is_del)
                      .map((variant, i) => {
                        const isOrderVaraint = variant.id === orderVaraintId;
                        return (
                          <div
                            className={className(styles.variantLine, {
                              [styles.removeVariantLine]: variant.is_del
                            })}
                            key={variant.id}
                          >
                            <p
                              className={className(styles.variantName, {
                                [styles.orderVariant]: isOrderVaraint
                              })}
                            >
                              {variant.title}
                              {variant.is_del && (
                                <div className={styles.variantDeleteIcon}>
                                  Deleted
                                </div>
                              )}
                              {variant.is_del &&
                              !deleteVariantButtonDisabled ? (
                                <i
                                  // style={{position: 'absolute', right: '5px', top: '5px'}}
                                  class="material-icons"
                                  onClick={() => this.deleteVariant(variant.id)}
                                >
                                  delete
                                </i>
                              ) : null}
                            </p>

                            {bogo[variant.id].variant_supply.map(
                              (sp, index) => {
                                const buySp = sp.supply[0];
                                const getSp = sp.supply[1] || {};
                                const disabled = !buySp.sku;
                                const getDisabled = !getSp.sku;
                                return (
                                  <div
                                    key={`${variant.id}Sps`}
                                    className={styles.variantSps}
                                  >
                                    <div className={styles.buy}>
                                      {buySp.sku_image ? (
                                        <div class="tag_vbox">
                                          <div
                                            onClick={e => {
                                              this.showImportSupply(
                                                variant.id,
                                                index === 0 ? 0 : 1,
                                                buySp.platform_product_id,
                                                i,
                                                buySp
                                              );
                                              const custom_info = [
                                                {
                                                  name: 'mapping type',
                                                  value: 'Bogo'
                                                }
                                              ];

                                              window.DSERS_CUSTOM_COLLECT({
                                                module: 'product mapping',
                                                action: 'click supplier button',
                                                custom_info
                                              });
                                            }}
                                          >
                                            <Popover
                                              placement="left"
                                              trigger="hover"
                                              content={
                                                <img
                                                  src={replaceUrlToHttps(
                                                    buySp.sku_image
                                                  )}
                                                />
                                              }
                                              overlayClassName={
                                                styles.zoomProductImg
                                              }
                                            >
                                              <img
                                                src={replaceUrlToHttps(
                                                  buySp.sku_image
                                                )}
                                                className={styles.skuImg}
                                              />
                                            </Popover>
                                            <span className="yexy_textdw">
                                              {buySp.sku_desc}
                                            </span>
                                          </div>
                                          <span
                                            className="consw_icon"
                                            onClick={() => {
                                              this.deleteSp(variant.id, 0, i);
                                            }}
                                          >
                                            <i class="material-icons">close</i>
                                          </span>
                                        </div>
                                      ) : (
                                        <Tooltip
                                          title={intl.get(
                                            'mapping.import_sp_select_var'
                                          )}
                                        >
                                          <Button
                                            disabled={buySp.isFBD}
                                            size="small"
                                            className={className({
                                              [styles.fbdSku]: buySp.isFBD
                                            })}
                                            onClick={e => {
                                              this.showImportSupply(
                                                variant.id,
                                                index === 0 ? 0 : 1,
                                                buySp.platform_product_id,
                                                i,
                                                buySp
                                              );
                                              const custom_info = [
                                                {
                                                  name: 'mapping type',
                                                  value: 'Bogo'
                                                }
                                              ];

                                              window.DSERS_CUSTOM_COLLECT({
                                                module: 'product mapping',
                                                action: 'click supplier button',
                                                custom_info
                                              });
                                            }}
                                          >
                                            <span className="btnAdd">
                                              <i class="material-icons">add</i>
                                            </span>
                                            <span className="yexy_textdwqw">
                                              {intl.get(
                                                'mapping.import_sp_select_var'
                                              )}
                                            </span>
                                          </Button>
                                        </Tooltip>
                                      )}

                                      {buySp.isFBD ? (
                                        <a
                                          className={styles.fbd}
                                          href="/app/fulfillment-center"
                                        >
                                          FBD
                                        </a>
                                      ) : null}
                                    </div>
                                    <div className={styles.buyQty}>
                                      <InputNumber
                                        size="small"
                                        min={disabled ? 0 : 1}
                                        disabled={disabled}
                                        value={buySp.quantity}
                                        precision={0}
                                        onChange={v => {
                                          this.changeQty(variant.id, 0, v);
                                        }}
                                      />
                                    </div>
                                    <div className={styles.get}>
                                      {getSp.sku_image ? (
                                        <div class="tag_vbox">
                                          <div
                                            onClick={e =>
                                              this.showImportSupply(
                                                variant.id,
                                                1,
                                                getSp.platform_product_id,
                                                i,
                                                getSp
                                              )
                                            }
                                          >
                                            <Popover
                                              placement="left"
                                              trigger="hover"
                                              content={
                                                <img
                                                  src={replaceUrlToHttps(
                                                    getSp.sku_image
                                                  )}
                                                />
                                              }
                                              overlayClassName={
                                                styles.zoomProductImg
                                              }
                                            >
                                              <img
                                                src={replaceUrlToHttps(
                                                  getSp.sku_image
                                                )}
                                                className={styles.skuImg}
                                              />
                                            </Popover>
                                            <span className="yexy_textdw">
                                              {getSp.sku_desc}
                                            </span>
                                          </div>
                                          <span
                                            className="consw_icon"
                                            onClick={() => {
                                              this.deleteSp(variant.id, 1);
                                            }}
                                          >
                                            <i class="material-icons">close</i>
                                          </span>
                                        </div>
                                      ) : (
                                        <Tooltip
                                          title={intl.get(
                                            'mapping.import_sp_select_var'
                                          )}
                                        >
                                          <Button
                                            size="small"
                                            disabled={disabled || getSp.isFBD}
                                            onClick={e =>
                                              this.showImportSupply(
                                                variant.id,
                                                1,
                                                getSp.platform_product_id,
                                                i,
                                                getSp
                                              )
                                            }
                                            className={className({
                                              [styles.fbdSku]: getSp.isFBD
                                            })}
                                            title={
                                              getSp.sku_desc ||
                                              intl.get(
                                                'mapping.import_sp_select_var'
                                              )
                                            }
                                          >
                                            <span className="btnAdd">
                                              <i class="material-icons">add</i>
                                            </span>
                                            <span className="yexy_textdwqw">
                                              {intl.get(
                                                'mapping.import_sp_select_var'
                                              )}
                                            </span>
                                            {/* {intl.get('mapping.import_sp_select_var')} */}
                                          </Button>
                                        </Tooltip>
                                      )}

                                      {getSp.isFBD ? (
                                        <a
                                          className={styles.fbd}
                                          href="/app/fulfillment-center"
                                        >
                                          FBD
                                        </a>
                                      ) : null}
                                    </div>
                                    <div className={styles.getQty}>
                                      <InputNumber
                                        size="small"
                                        min={getDisabled ? 0 : 1}
                                        disabled={getDisabled}
                                        value={getSp.quantity}
                                        precision={0}
                                        onChange={v => {
                                          this.changeQty(variant.id, 1, v);
                                        }}
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        );
                      })}
                  </Panel>
                </Collapse>
              </div>
            ) : null}
          </div>
          {showImport && (
            <ImportAliExpressMapping
              visible={showImport}
              hideImportSupply={this.hideImportSupply}
              importCallback={this.importCallback}
              importProductId={this.importProductId}
              shopifyProductId={this.shopifyProductId}
              storeId={originProductData.prod.store_id}
              selectData={this.state.select || []}
              sp={this.buySp}
            />
          )}
        </div>
        <Modal
          visible={this.state.visibleDeletedAllVariant}
          width={560}
          title={intl.getHTML('mapping.confirm_delete_all_variant_title')}
          className={styles.deleteTipModal}
          onCancel={() => {
            this.setState({
              visibleDeletedAllVariant: false
            });
          }}
          footer={
            <>
              <Button
                className={styles.btn}
                onClick={this.state.isDeleteAll ? this.deleteAllDelVariant : this.deleteVariantTrue}
              >
                {intl.getHTML('public.newconfirm')}
              </Button>
            </>
          }
        >
          <div>{intl.getHTML('mapping.confirm_delete_all_variant')}</div>
        </Modal>
      </>
    );
  }
}
