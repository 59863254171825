import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Button, Input, Rate, Tooltip } from 'antd';
import { connect } from 'dva';

import { Get, Post } from '../../utils/request';
import head from '../../assets/banner/head.png';
import end from '../../assets/banner/end.png';
import state1 from '../../assets/banner/state_1.png';
import state2 from '../../assets/banner/state_2.png';
import state3 from '../../assets/banner/state_3.png';
import state4 from '../../assets/banner/state_4.png';
import state5 from '../../assets/banner/state_5.png';
import styles from './BannerComment.less';
// import { bannerListMap, bannerList } from '../../utils/bannerWhiteList';
import { autoCommentUsers, salesmanMap } from 'utils/autoCommentUsers';

import useOnlineNotice from '../../hooks/useOnlineNotice';
import feedbackEntryImg from '../../assets/banner/auto_feedback_icon.png';

const modalRoot = document.getElementById('root');
const { TextArea } = Input;
const getDuration = day => 24 * 60 * 60 * 1000 * day;

const BannerComment = ({ userId, comment }) => {
  // const [visible, closeNotice] = useOnlineNotice('comment', onlineDate, 7);
  const [visible, setVisible] = useState(true);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [showResult, setShowResult] = useState(false);
  const [starValue, setStarValue] = useState(5);
  const [hoverValue, setHoverValue] = useState(0);
  const [textValue, setTextValue] = useState('');
  const isBannerUser = !!autoCommentUsers[userId];
  const [approved, setApproved] = useState(true);

  useEffect(() => {
    if (isBannerUser && visible) {
      if (autoCommentUsers[userId]) {
        setName(autoCommentUsers[userId]);
        setEmail(salesmanMap[autoCommentUsers[userId]] || '');
      }
    }
  }, [visible]);

  const getStatus = async () => {
    if(isBannerUser) {
      const res = await Get('/users/estimate_info');
      // console.log(res);
      if(res.code === 2000) {
        setApproved(res?.data?.estimate_info?.approve_status === 1);
      }
    }
    
  }

  useEffect(() => {
    const lastShowTime = localStorage.getItem('commentLastShow');
    let timeGap = -1;
    if (lastShowTime) {
      const currentTime = Date.now();
      timeGap = currentTime - lastShowTime;
    }
    const commentFlag = localStorage.getItem('comment');
    if (commentFlag) {
      setVisible(false);
    } else {
      if (timeGap == -1) {
        setVisible(true);
      } else if (timeGap > getDuration(1)) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
    getStatus();
  }, []);

  useEffect(() => {
    if (comment.visible) {
      setVisible(true);
    }
  }, [comment]);

  if (!isBannerUser || approved) {
    return null;
  }



  const submit = async () => {
    try {
      const res = await Post('/users/estimate_info', {
        data: {
          star_rating: starValue,
          comment: textValue,
          approve_status: 1,
          services: name
        }
      });
      if (res.code == 2010) {
        setShowResult(true);
        localStorage.setItem('comment', true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const cancel = () => {
    setVisible(false);
    if (!comment.visible) {
      localStorage.setItem('commentLastShow', Date.now()); // 记录时间戳 一天倒计时
    }
  };

  const ok = () => {
    setVisible(false); // 隐藏弹窗
    setApproved(true);
  };

  const handleStarChange = value => {
    if (value !== 0) {
      setStarValue(value);
    }
  };

  const handleStarHover = value => {
    setHoverValue(value);
  };

  const textAreaChange = e => {
    if (e.target.value !== '') {
      setTextValue(e.target?.value);
    }
  };

  return <div>
    <div>{visible && ReactDOM.createPortal(
      <div className={styles.notice}>
        {!showResult ? (
          <div className={styles.body}>
            <div className={styles.header}>
              <div className={styles.head}>
                <div className={styles.imgCon}>
                  <img src={head} className={styles.img} />
                </div>
                <div className={styles.name}>{name}</div>
                <div className={styles.email}>{email}</div>
              </div>
              <div className={styles.msg}>
                <div>
                  Your purchasing experience is very important to us.
                </div>
                <div>
                  Please leave your valuable comments regarding your experience. We will use them to improve our service as much as we can.
                </div>
                <div> Thank you very much for your support. </div>
                <div className={styles.msgCon}>
                  *Comments may appear on AliExpress
                </div>
              </div>
            </div>
            <div className={styles.star}>
              <div className={styles.starName}>Customer Service</div>
              <div className={styles.select}>
                <div className={styles.emoji}>
                  {hoverValue == 1 && <img src={state1} className={styles.state1} />}
                  {hoverValue == 2 && (
                    <>
                      <img src={state2} className={styles.state2} />
                    </>
                  )}
                  {hoverValue == 3 && (
                    <>
                      <img src={state3} className={styles.state3} />
                    </>
                  )}
                  {hoverValue == 4 && (
                    <>
                      <img src={state4} className={styles.state4} />
                    </>
                  )}
                  {hoverValue == 5 && (
                    <>
                      <img src={state5} className={styles.state5} />
                    </>
                  )}
                </div>
                <Rate
                  className={styles.rate}
                  defaultValue={5}
                  onChange={handleStarChange}
                  onHoverChange={handleStarHover}
                  value={starValue}
                  style={{ color: '#FF8F00' }}
                />
              </div>
            </div>
            <TextArea
              className={styles.text}
              rows={3}
              placeholder="Please enter your review content."
              maxLength={300}
              onChange={textAreaChange}
            />
            <div className={styles.buttonCon}>
              <Button className={styles.submit} onClick={submit}>
                SUBMIT
              </Button>
              <Button className={styles.cancel} onClick={cancel}>
                CANCEL
              </Button>
            </div>
          </div>
        ) : (
          <div className={styles.reminder}>
            <div className={styles.reminderHeader}>
              We really appreciate your review<br />Thank you and good dropshipping
            </div>
            <img src={end} />
            <div className={styles.buttonCon} onClick={ok}>
              <Button className={styles.ok}>OK</Button>
            </div>
          </div>
        )}
      </div>,
      modalRoot
    )}
    </div>
    <div className={styles.floatButton} onClick={() => setVisible(true)}>
      <Tooltip title="Rating"><img src={feedbackEntryImg} /></Tooltip>
    </div>
  </div>;
};

export default connect(({ global }) => ({
  comment: global.comment
}))(BannerComment);
