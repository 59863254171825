
    export default 
    {
    "header": {
        "about": "SU DI NOI",
        "support": "SUPPORTO",
        "blog": "BLOG",
        "sign_in": "Accedi",
        "sign_up": "Registrati"
    }
}