import React from 'react';
import { Progress, Icon, Spin, Button, Modal } from 'antd';

import styles from './ExtensionPlaceOrderProcess.less';
import { getPlaceOrderProcess } from 'utils/placeOrderViaExt.js';
import intl from 'react-intl-universal';
import { getExtViaExtenionTask, cancelExtTask } from 'services/order';
import { captureMessage } from '@sentry/react';
import CheckInterreptedExtTask from '../CheckInterreptedExtTask';

const retryAction = async (fn, params, count, shouldRetryFn) => {
  count = Number(count);
  let result = { code: 100 };
  if (count < 0) {
    count = 1;
  }
  for (let i = 0; i < count; i++) {
    try {
      result = await fn.apply(null, params);
    } catch (error) {
      console.log(error);
    }
    if (
      shouldRetryFn &&
      shouldRetryFn(typeof result === 'object' ? { ...result } : result)
    ) {
      continue;
    } else {
      return result;
    }
  }
  return result;
};

class ExtensionPlaceOrderProcess extends React.PureComponent {
  intervalId = null;
  inProcess = false;
  unmounted = false;
  abortLock = false;

  constructor() {
    super();
    this.state = {
      total: 0,
      success: 0,
      failed: 0,
      currentStep: 0,
      status: 0,
      visible: false,
      abortModalVisible: false,
      abortLoading: false,
      abortedCount: 0,
      abortFinished: false
    };
  }

  openAbortModal = () => {
    this.setState({
      abortModalVisible: true,
      abortedCount: 0,
      abortFinished: false
    });
  };

  onCloseAbortModal = () => {
    if (this.abortLock) return;
    this.setState({ abortModalVisible: false });
  };

  abortAllTask = async () => {
    if (this.abortLock) return;
    this.abortLock = true;
    this.setState({
      abortLoading: true
    });

    let finished = false;
    let count = 0;
    while (!finished) {
      try {
        const task = await getExtViaExtenionTask({ cancelMessage: true });
        if (task.code === 5000 && task.msg === 'record not found') {
          finished = true;
          break;
        }
        if (task?.data?.event_id) {
          const params = {
            type: 'OTHER_ERROR',
            msg: 'USER_ABORT_TASKS',
            event_id: task?.data?.event_id,
            status: 'failed'
          };
          const cancelRes = await retryAction(
            cancelExtTask,
            [
              {
                data: params
              }
            ],
            3,
            res => {
              return res.code !== 2010;
            }
          );
          if (cancelRes.code === 2010) {
            count++;
          } else {
            captureMessage(
              `[plugin_place_post_res_error] ${JSON.stringify(params)}`
            );
          }
        }
      } catch (error) {
        console.log(error);
      }
    }

    this.setState({
      abortLoading: false,
      abortedCount: count,
      abortFinished: true
    });
    this.abortLock = false;
  };

  startProcess = () => {
    if (this.inProcess) return;
    this.inProcess = true;
    this.setState({ visible: true, status: 0 });
    clearInterval(this.intervalId);
    if (this.unmounted) return;
    this.intervalId = setInterval(async () => {
      const process = await getPlaceOrderProcess();
      if (process) {
        this.setState({ ...process });
      }
      if (process.status === 2) {
        this.inProcess = false;
        this.setState({ visible: !!(process.failed || process.success) });
        clearInterval(this.intervalId);
      }
    }, 3000);
  };

  componentDidMount() {
    getPlaceOrderProcess().then(res => {
      console.log('check place task');
      if (res) {
        if (res.status === 1) {
          this.startProcess();
        }
      }
    });
    window.abortAllTask = this.abortAllTask
  }

  componentWillUnmount() {
    this.unmounted = true;
    clearInterval(this.intervalId);
  }

  render() {
    const { total, success, failed, visible, status } = this.state;

    return (
      <>
        {visible ? (
          <>
            <div className={styles.processContainer}>
              <div className={styles.header}>
                <Icon
                  onClick={() => this.setState({ visible: !visible })}
                  style={{ cursor: 'pointer' }}
                  type="close"
                />
              </div>
              {status === 0 ? (
                <div key="1" className={styles.loading}>
                  <Spin spinning={true} size="large" />
                </div>
              ) : null}
              {status === 1 ? (
                <div key="2">
                  <div>
                    {intl.get('order.place_via_extension.order_is_processing')}
                  </div>
                  <div>
                    {intl.get('order.place_via_extension.not_operate_title')}:
                  </div>
                  <div>
                    1. {intl.get('order.place_via_extension.not_operate_1')}
                  </div>
                  <div>
                    2. {intl.get('order.place_via_extension.not_operate_2')}
                  </div>
                  <div>
                    3. {intl.get('order.place_via_extension.not_operate_3')}
                  </div>
                  <div>
                    4. {intl.get('order.place_via_extension.not_operate_4')}
                  </div>
                  <div style={{ marginTop: 8, textAlign: 'right' }}>
                    <a
                      className={styles.abortBtn}
                      onClick={this.openAbortModal}
                      type="danger"
                    >
                      {intl.get('order.place_via_extension.abort_task_btn')}
                    </a>
                  </div>
                </div>
              ) : null}
              {status === 2 && (failed || this.state.abortedCount) ? (
                <div key="3">
                  {intl.get('order.place_via_extension.failed_order_exsits')}
                </div>
              ) : null}
              {status === 2 &&
              !(failed || this.state.abortedCount || !success) ? (
                <div key="4" className={styles.success}>
                  <div>
                    <Icon
                      style={{ fontSize: 36, color: '#4caf50' }}
                      type="check-circle"
                    />
                  </div>
                  <div>place order success</div>
                  <div className={styles.leftAlignOperation}>
                    <a
                      className={styles.abortBtn}
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.aliexpress.com/p/order/index.html"
                    >
                      Make payment
                    </a>
                  </div>
                </div>
              ) : null}
              {/* <div className={styles.process}>
          <Progress percent={((((success + failed) * 100) / total) || 0).toFixed(2)} />
        </div>
        <div>
          <div>total: {total}</div>
          <div>success: {success}</div>
          <div>failed: {failed}</div>
        </div>
        <div className={styles.current}>
          {status === 1 ? (
            <span>
              processing...({success + failed + 1}/{total})
            </span>
          ) : (
            <span>finished</span>
          )}{' '}
        </div> */}
            </div>
            <Modal
              onCancel={this.onCloseAbortModal}
              visible={this.state.abortModalVisible}
              footer={null}
              title={
                !this.state.abortFinished ? (
                  <span>
                    {intl.get(
                      'order.place_via_extension.abort_task_modal_title1'
                    )}
                  </span>
                ) : (
                  <span key="1">
                    {intl.get(
                      'order.place_via_extension.abort_task_modal_title2'
                    )}
                  </span>
                )
              }
            >
              <p>
                {!this.state.abortFinished ? (
                  intl.get(
                    'order.place_via_extension.abort_task_modal_content1'
                  )
                ) : this.state.abortedCount ? (
                  <div>
                    <p>
                      {intl.get(
                        'order.place_via_extension.abort_task_modal_content2',
                        { value: this.state.abortedCount }
                      )}
                    </p>
                  </div>
                ) : (
                  <p>There are no tasks to abort</p>
                )}
              </p>
              <div style={{ textAlign: 'right', paddingBottom: 16 }}>
                {!this.state.abortFinished ? (
                  <Button
                    onClick={this.abortAllTask}
                    loading={this.state.abortLoading}
                    type="danger"
                  >
                    <span>
                      {intl.get('order.place_via_extension.confirm_btn')}
                    </span>
                  </Button>
                ) : null}
                <Button
                  onClick={this.onCloseAbortModal}
                  style={{ marginLeft: 8 }}
                  type="primary"
                >
                  {!this.state.abortFinished ? (
                    <span>
                      {intl.get('order.place_via_extension.cancel_btn')}
                    </span>
                  ) : (
                    <span key="1">
                      {intl.get('order.place_via_extension.abort_got_it')}
                    </span>
                  )}
                </Button>
              </div>
            </Modal>
          </>
        ) : null}
        {/* 检测是否有意外中断的插件下单任务 */}
        <CheckInterreptedExtTask startProcess={this.startProcess} />
      </>
    );
  }
}

export default ExtensionPlaceOrderProcess;
