import { stringify } from 'qs';
import getUserInfo, { getStoreId } from './getUserInfo';
import { getApiUrl } from './utils';

/**
 *@typedef EventObject
 * @property {stirng} event_action 事件动作 eventAction
 * @property {string} event_category 事件分类 eventCategory
 * @property {string} event_label 事件标签 eventLabel
 * @property {number} event_value 事件值 eventValue
 * @property {string} en 事件名 eventName
 * @property {string|number} paid 商品ae id
 * @property {string|number} psid 商品shopify id
 * @property {string} extension 事件扩展参数 json字符串
 * @property {string} psku 商品sku
 * @property {string} oaeid 订单ae id
 * @property {string} osid 订单shopify id
 *
 */

/**
 *
 * @param {EventObject} params 事件参数对象
 */
export function emitCustomEvent(params = {}) {
  const useInfo = getUserInfo();
  // userId storeId 时间戳
  const uid = useInfo?.user_id || '';
  const registerTime = useInfo?.created_at || '';
  const sid = getStoreId() || '';
  const gmt = +new Date();
  const eventUrl = `${getApiUrl('/collect')}?${stringify({
    ...params,
    uid,
    sid,
    gmt_millisecond: gmt,
    created_at: registerTime
  })}`;
  return fetch(eventUrl, {
    method: 'post'
  }).catch(() => {});
}
