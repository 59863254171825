import React from 'react';
import { Link } from 'dva/router';
import Exception from 'components/Exception';

import img from '../../assets/404.svg';

export default () => (
  <Exception
    type="404"
    img={img}
    style={{ minHeight: 500, height: '80%' }}
    linkElement={Link}
  />
);
