import React, { useEffect, useState } from 'react';
import { Modal, Button, Select, Radio, Spin } from 'antd';
import intl from 'react-intl-universal';
import styles from './shipping.less';
import style from './shippingModal.less';
import { getCity } from 'services/dropshippersetting.js';
import { Get } from 'utils/request';
import { dsersBFFApiHost } from 'common/sysconfig';

const RadioGroup = Radio.Group
const { Option } = Select

const SearchCity = [
    'BR', 'RU', 'ES', 'ID', 'FR'
];

const SearchTown = [
    'BR', 'RU', 'ES', 'FR'
]

function ShippingModal({ visible, closeModal, nowVariant, appId, productId, changeCountry }) {

    const [showChooseTown, setShowChooseTown] = useState(false)
    const [showChooseCity, setShowChooseCity] = useState(false)
    const [cityData, setCityData] = useState()
    const [townShip, setTownShip] = useState()
    const [selectTown, setSelectTown] = useState()
    const [selectCity, setSelectCity] = useState()
    const [selectCountry, setSelectCountry] = useState()
    const [logistics, setLogistics] = useState()
    const [spinLoading, setSpinLoading] = useState(false)

    const countryListData = JSON.parse(localStorage.getItem('countryList') || '[]')?.filter(item=>{
        return item.Code === 'US' || item.Code === 'BR'
    });
    const defaultSelect = JSON.parse(localStorage.getItem('defaultSelect')) || { Code: "US", Name: "United States" }

    useEffect(() => {
        const fetchLogistics = async () => {
            if (!nowVariant) {
                return
            }
            setSpinLoading(true)
            const res = await Get(`${dsersBFFApiHost}/dsers-product-bff/product-pool/product/logistics`, {
                data: {
                    appId,
                    productId,
                    country: selectCountry ? selectCountry : defaultSelect.Code,
                    skuId: encodeURIComponent(nowVariant.variantId),
                },
                isCustomApi: true
            })
            if (res?.data?.length) {
                res.data.forEach((item, index) => {
                    if (!index) {
                        item.checked = true
                    } else {
                        item.checked = false
                    }
                })
            }
            setSpinLoading(false)
            setLogistics(res)
        }
        fetchLogistics()
    }, [selectCountry])

    const renderTable = () => {
        const renderLogisticsTime = (index) => {
            const map = {
                1: 'working days',
                2: 'days'
            }
            return logistics?.data && `${logistics?.data[index]?.time?.min}-${logistics?.data[index]?.time?.max} ${map[logistics?.data[index]?.time?.mode]}`
        }

        const renderLogisticsPrice = (index) => {
            const map = {
                USD: '$',
            }
            return logistics?.data && `${map[logistics?.data[index]?.currency]}${logistics?.data[index]?.amount / 100}`
        }
        return (
            <>
                {
                    logistics?.data?.map((item,index) => {
                        return (
                            <div className={styles.li} key={item.name}>
                                <p> <Radio checked={item.checked} />
                                    <span>{item.companyName}</span>
                                </p>
                                <p> {renderLogisticsPrice(index)}
                                </p>
                                <p>
                                    {renderLogisticsTime(index)}
                                </p>
                                <p> {intl.get('order.order_product.shipping_dialog.available')}
                                </p>
                            </div>
                        )
                    })
                }
            </>
        );
    };

    const handleCountry = (name, option) => {
        const value = countryListData.filter(item => {
            return item.Name === name
        })[0].Code
        if (value === selectCountry) {
            return
        }
        setSelectCountry(value)
        setLogistics('')
        if (SearchCity.includes(value)) {
            getCityData(value);
            return;
        } else {
            setShowChooseTown(false)
            setShowChooseCity(false)
        }

        // getAeFreight();
    }

    // 获取城镇数据
    const getCityData = (code) => {
        getCity({
            data: {
                country_code: code
            }
        }).then(res => {
            if (SearchTown.includes(code)) {
                setShowChooseTown(true)
            }
            setShowChooseCity(true)
            setCityData(res.data || [])
            setTownShip(res.data ? res.data[0].Citys : [])
            setSelectCity(res.data[0].Name)
            setSelectTown(res.data[0].Citys[0].Name)
        })
    }

    const handleChangeCity = (value) => {
        if (value === selectCity) {
            return
        }
        setSelectCity(value)
        cityData.forEach(item => {
            if (item.Name === value) {
                setTownShip(item.Citys)
                setSelectTown(item.Citys[0].Name)
                return
            }
        })
    }

    const handleClose = () => {
        closeModal()
    }

    const handleConfirm = () => {
        const value = countryListData.filter(item => {
            return item.Code === selectCountry
        })[0]
        if (value) {
            localStorage.setItem('defaultSelect', JSON.stringify(value))
        }
        changeCountry(selectCountry)
        closeModal()
    }

    return (
        <Modal
            visible={visible}
            style={{ width: '560px' }}
            width='560px'
            title='Check Shipping Information'
            maskClosable={false}
            footer={
                <div className={style.button}>
                    <Button disabled={!logistics?.data?.length} style={{ transition: 'none' }} onClick={handleConfirm}>CONFIRM</Button>
                </div>
            }
            onCancel={handleClose}
            destroyOnClose={true}
        >
            <>
                <div className={style.shipto}><span>Ship to</span>
                    <div>
                        <Select style={{ width: "120px", marginRight: '12px' }} onChange={handleCountry} showSearch={true} defaultValue={defaultSelect.Name}>
                            {
                                countryListData.map(item => {
                                    return (
                                        <Option key={item.Code} value={item.Name}>{item.Name}</Option>
                                    )
                                })
                            }
                        </Select>
                        {
                            showChooseCity ?
                                <Select style={{ width: "120px", marginRight: '12px' }} placeholder=' Please select province' showSearch={true} value={selectCity} onChange={handleChangeCity}>
                                    {
                                        cityData?.map(item => {
                                            return (
                                                <Option key={item.Name} value={item.Name}>{item.Name}</Option>
                                            )
                                        })
                                    }
                                </Select> : null
                        }
                        {
                            showChooseTown ?
                                <Select style={{ width: "120px" }} placeholder=' Please select city' showSearch={true} value={selectTown} onChange={(value) => { setSelectTown(value) }}>
                                    {
                                        townShip?.map(item => {
                                            return (
                                                <Option key={item.Name} value={item.Name}>{item.Name}</Option>
                                            )
                                        })
                                    }
                                </Select> : null
                        }

                    </div>
                </div>
                <div className={style.shipmethod}><span>Shipping Method</span>
                    <div className={styles.fuLogisticsRightList}>
                        <Spin wrapperClassName={style.spin} spinning={spinLoading}>
                            {
                                logistics?.data?.length ?
                                    <>
                                        <div className={styles.liHead}>
                                            <p>Shipping Company</p>
                                            <p>Shipping Cost</p>
                                            <p>Estimated Delivery Time</p>
                                            <p>
                                                Tracking Information
                                            </p>
                                        </div>
                                        {renderTable()}
                                    </> :
                                    <div> <p className={style.notrackingTip}><i className="material-icons notranslate">error</i>{' '}
                                        The shipping method cannot be displayed: <br/>
                                        1. You may have not selected all the variants, please check. <br/>
                                        2.This product does not support the destination country, please switch the country.
                                    </p></div>
                            }
                        </Spin>
                    </div>
                </div>
            </>
        </Modal>
    )
}

export default React.memo(ShippingModal)