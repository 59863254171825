import { Get, Post, Put, Delete } from '../utils/request';

export async function customizeUpdate(payload) {
    return Get('/set/product/update', payload);
}

export async function getCustomize(payload) {
    return Get('/set/product/get', payload);
}

export async function setCurrenciesCustom(payload){
    return  Post('/set/customization/exchange_rate', payload);
}

export async function getCurrenciesCustom(payload){
    return Get('/set/customization/exchange_rate', payload);
}