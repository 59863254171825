import React from 'react';
import { Drawer } from 'antd';

import SiderMenu from './SiderMenu';
import styles from './drawer.less';

const SideMenuWrapper = props => {
  const { isMobile, collapsed, onCollapse, isSaintsTheme } = props;
  return isMobile ? (
    <Drawer
      visible={!collapsed}
      placement="left"
      onClose={() => onCollapse(true)}
      className={styles.menuDrawer}
      closable={false}
    >
      <SiderMenu {...props} collapsed={isMobile ? false : collapsed} />
    </Drawer>
  ) : (
    <SiderMenu {...props} isSaintsTheme={isSaintsTheme}/>
  );
};

export default React.memo(SideMenuWrapper);
