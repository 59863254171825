import React, { useMemo, useEffect, useLayoutEffect, useState, useRef } from 'react';
import { connect } from 'dva';
import { withRouter } from 'dva/router';
import moment from 'moment';

import getAdminRole from 'utils/getAdminRole';
import MessengerButton from 'routes/HelpCenter/component/MessengerButton';
import HelpCenterEntrance from 'routes/HelpCenter/component/Entrance';
import GuidanceButton from 'routes/Guidance/component/GuidanceButton';
import thebell from 'assets/supply/theBell.gif';
import cut from 'assets/img/christmas/cut.png';
import FixedAdvanced from '../components/FixedAdvanced/FixedAdvanced';
import { beforeMigrationLoop, MigrateButton } from '../components/BeforeMigration';
import { Modal } from 'antd';

const routersArr = [
  '/find_suppliers',
  '/storemanage/import',
  '/storemanage/myproduct',
  '/settings'
];

const cutArr = ['/find_suppliers'];

const GlobalMessenger = props => {
  const {
    loginInfo,
    showTime,
    location,
    dispatch,
    routers = [],
    user,
    plan,
    conversionFinish,
    conversionModal,
    isSaintsTheme,
    history,
  } = props;
  const hasHelpCenterAccess = window.DSERS_H_C || getAdminRole();
  const [showFixed, setShowFixed] = useState(false);
  const [cutVisible, setCutVisible] = useState(false);
  const isLoadRef = useRef(false);
  const hasLand = useMemo(() => {
    if (location.pathname === '/') {
      return false;
    }

    return routers.some(i => i.indexOf(location.pathname) > -1);
  }, [location, routers]);

  useEffect(() => {
    if (routersArr.includes(location.pathname)) {
      setShowFixed(true);
      if (plan?.type && user?.status) {
        const time = plan.lastTime - +new Date();
        if (
          plan.type === 4 &&
          time > 0 &&
          String(user.status).substr(1, 1) == '1' &&
          plan.period == 1 &&
          !plan.change_num
        ) {
          dispatchStatus(true);
        } else {
          dispatchStatus(false);
        }
      }
    } else {
      setShowFixed(false);
    }

    if (cutArr.includes(location.pathname) && isSaintsTheme) {
      setCutVisible(true);
    } else {
      setCutVisible(false);
    }
  });

  console.log(location.pathname,'location.pathname');
  useEffect(() => {
    if (isLoadRef.current) {
      return null;
    }
    isLoadRef.current = true;
    // 迁移时轮询查询迁移状态做不同的任务
    beforeMigrationLoop(
      () => {

        if(location.pathname === '/migrationWaiting'){
          return;
        }
        if (
          ['/auth/callback', '/auth/redirect'].includes(
            location.pathname
          )
        ) {
          history.replace('/migrationWaiting?isAuth=1');
          return;
        }

        history.replace('/migrationWaiting');
      },
      () => {
        localStorage.removeItem('dser-pro-authority');
        localStorage.removeItem('authority_token');
        window.location.href =
          process.env.BUILD_TARGET === 'prod'
            ? 'https://www.dsers.com/application/'
            : 'https://dev.dsers.com/application/';
      }
    );
    return () => {
      isLoadRef.current = false;
    };
  }, []);


  const dispatchStatus = val => {
    dispatch({
      type: 'global/setDownCouny',
      payload: val
    });
  };

  const handleBell = () => {
    dispatch({
      type: 'global/setConversionModal',
      payload: true
    });
  };

  return (
    <>
      {/* 迁移前弹窗按钮 */}
      {hasLand && <MigrateButton />}

      {/* {showTime && showFixed ? <FixedAdvanced sendEndTime={dispatchStatus} /> : null} */}

      {/* 新版本且登陆了 */}
      {/* {hasHelpCenterAccess && hasLand && !window.DS_BEGINNER_GUIDE_ACTIVE ? <HelpCenterEntrance /> : null} */}

      {/* 新版本且没登陆 */}
      {/* {hasHelpCenterAccess && !hasLand ? <MessengerButton /> : null} */}

      {/* ---------- 以下对于旧版本 ---------- */}

      {/* 旧版本 */}
      {/* {!hasHelpCenterAccess ? <MessengerButton /> : null} */}
      {!conversionFinish && !conversionModal ? (
        <img
          onClick={handleBell}
          style={{
            position: 'fixed',
            bottom: '87px',
            right: '8px',
            zIndex: '199',
            width: '100px',
            cursor: 'pointer'
          }}
          src={thebell}
          alt=""
        />
      ) : null}
      <MessengerButton />

      {/* 旧版本且已登陆 */}
      {!hasHelpCenterAccess && hasLand ? (
        <GuidanceButton location={location} />
      ) : null}
    </>
  );
};

const mapStateToProps = ({ login, global, user }) => ({
  loginInfo: login.loginInfo,
  showTime: global.showTime,
  user: login.userInfo,
  plan: user.plan,
  conversionFinish: global.conversionFinish,
  conversionModal: global.conversionModal,
  isSaintsTheme: global.themeStatus
});

export default withRouter(
  connect(mapStateToProps, dispatch => ({ dispatch }))(GlobalMessenger)
);
