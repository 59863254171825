import React, { useEffect,useState} from 'react'
import { connect,useDispatch } from 'dva';

import { Get,Post } from 'utils/request';
import styles from './OrderAeError.less'

import {oberloReport,getOberloReport,getChromeExtUrl,CommonDataCollect} from 'utils/utils'

import closeicon from 'assets/close.png'
import errorimg from 'assets/home/iconerror.png'

import intl from 'react-intl-universal';

const OberloErrorStatus = ({dispatch,userInfo,lang,isBindAe,oberlouser,isoberlouser,OstoreInfo})=> {

  let interval = null
  const [closeTip,setCloseTip] = useState(false);
  const [errorTip,setErrorTip] = useState(false)

  useEffect(() => {
    if(isoberlouser){
      getOberloReport('is_close_order_error').then((res)=>{
        if(res.value == 'true'){
          setErrorTip(false)
        }else{
          OstoreInfo?.forEach((i)=>{
            if(i?.tips?.includes('TIP_REASON_ALIEXPRESS_UNAUTHORIZED')){
              setErrorTip(true)
            }
          })
        }
      })
      getOberloReport('is_close_tip').then((res)=>{
        if(res.value == 'true'){
          setCloseTip(true)
        }else{
          oberloReport('is_close_tip','true')
        }
      })
    }
  }, [OstoreInfo])
  const callUsHandler = ()=>{
    window.open('https://www.messenger.com/t/DSersOfficial')
  }

  const closeTiphandler = ()=>{
    setErrorTip(false)
    oberloReport('is_close_order_error','true')
  }

  return (
    <>
      {errorTip &&
      <div className={styles.errorcontainer}>
      <div className={styles.container}>
        <img src={errorimg} className={styles.erroricon} alt="" />
        <div className={styles.text}>
        { intl.getHTML('public.migrate_order_tip') }
        </div>
        <div className={styles.call} onClick={callUsHandler}>
        CONTACT SUPPORT
        </div>
      </div>
      {closeTip ? <img src={closeicon} alt="" onClick={closeTiphandler} className={styles.close} /> : null}
    </div>
      }
    </>
  )
}

export default connect(({ login, global,user,auth,topBtn }) => ({
  plan_info: user.plan,
  isChildAcount: user.newUserInfo.type, //判断是否是子账户
  userInfo: login.userInfo,
  lang: global.lang,
  isBindAe:global.isBindAe,
  oberlouser:auth?.migrationInfo,
  isoberlouser:auth?.isOberloUser,
  OstoreInfo:auth?.OstoreInfo?.store_stages
}))(React.memo(OberloErrorStatus));