import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import intl from 'react-intl-universal';

import { getChromeExtUrl } from 'utils/utils';

const InstallExtensionModal = React.memo(
  ({ visible, handlePlaceViaApi, handleInstallExt, type, handleCancel }) => {
    const [clickInstall, setClickInstall] = useState(false);

    const handleClickInstall = () => {
      handleInstallExt && handleInstallExt();
      window.open(getChromeExtUrl(), 'blank');
      setClickInstall(true);
    };

    const handleRefreshPage = () => {
      window.location.reload();
    };

    return (
      <Modal
        title={intl.get('order.place_via_extension.ext_not_installed_title')}
        visible={visible}
        onCancel={handleCancel}
        footer={
          !clickInstall
            ? [
                <Button onClick={handlePlaceViaApi}>{intl.get('order.place_via_extension.order_via_api')}</Button>,
                <Button type="primary" onClick={handleClickInstall}>
                  {type === 1 ? (
                    <span key="1">{intl.get('order.place_via_extension.add_dsers_ext')}</span>
                  ) : (
                    <span key="2">Update Dsers Extension</span>
                  )}
                </Button>
              ]
            : null
        }
      >
        {!clickInstall ? (
          <div>
            {type === 1 ? (
              <span>
                {intl.get('order.place_via_extension.ext_not_installed_content')}
              </span>
            ) : (
              <span>
                It is detected that the current version of dsers plug-in does not support placing orders. Click the button below to jump to the chrome online store to install the latest version of dsers plug-in, and then return to this page to try again.
              </span>
            )}
          </div>
        ) : (
          <div
            style={{
              height: 100,
              textAlign: 'center',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <Button type="primary" onClick={handleRefreshPage}>
              UPDATE
            </Button>
          </div>
        )}
      </Modal>
    );
  }
);

export default InstallExtensionModal;
