export default {
  pricing: {
    //  add
    title: 'Mais Valor com Menos Custo',
    desc: 'Free plan available',
    tipSelectPlan: 'Selecione um plano para continuar',
    Basic: 'Básico',
    BasicText: '(Forever free) <br /> Melhore a eficiência para começar bem',
    Standard: 'Standard',
    StandardText: ' days free trial',
    Advanced: 'Avançado',
    AdvancedText:
      ' days free trial',
    Pro: 'Pro',
    ProText:
      ' days free trial',
    No_card_AdvancedText: 'Automatize seus negócios para o próximo nível',
    No_card_ProText: 'Amplie seus negócios com mais possibilidades',
    No_card_StandardText: 'Grow your business to new heights',
    Business: 'Business',
    Enterprise: 'Enterprise',
    // Admonth: '$19.9/mês',
    // Prmonth: '$49.9/mês',
    // Stmonth: '$1.99/mês',
    Admonth: '<span class="mount mounttop">$</span><span class="mountP">19.9</span><span class="mount">/mês</span>',
    Prmonth: '<span class="mount mounttop">$</span>49.9<span class="mount">/mês</span>',
    Stmonth: `<span class="mount mounttop">$</span>1.99<span class="mount">/mês</span>`,
    Enmonth:`<span class="mount mounttop">$</span>499<span class="mount">/mês</span>`,
    NewStmonth:`<span class="mount mounttop">$</span>9.9<span class="mount">/mês</span>`,
    // Stannually: '$1.99',
    // Adannually: '$15.9',
    // Prannually: '$39.9',
    Adannually: '<span class="mount mounttop">$<s class="deleter">19.9</s></span><span class="mountP">15.9</span><span class="mount">/mês</span>',
    Prannually: '<span class="mount mounttop">$<s class="deleter">49.9</s></span>39.9<span class="mount">/mês</span>',
    Stannually: `<span class="mount mounttop">$</span>1.99<span class="mount">/mês</span>`,
    Enannually:`<span class="mount mounttop">$<s class="deleter">499</s></span>399<span class="mount">/mês</span>`,
    NewStannually:`<span class="mount mounttop">$<s class="deleter">9.9</s></span>7.9<span class="mount">/mês</span>`,
    StannuallyText: '/mês <br /> Faturado em US $ 23.88 por ano ',
    AdannuallyText:
      '/mês <br /> Faturado em US $ 190,8 por ano <br/> Você economiza US $ 48 / ano ',
    AdannuallyText_3:'Billed at $190.8 annually <br/> 2 months OFF !',
    PrannuallyText:
      '/mês <br /> Faturado em US $ 478,8 por ano <br/> Você economiza US $ 120 / ano ',
    PrannuallyText_3:' Billed at $478.8 annually <br /> 2 months OFF !',
    EnannuallyText:'Billed anually at $4,788 <br /> 2 months OFF !',
    NewStannuallyText:'Billed anually at $94.9 <br /> 2 months OFF !',
    Free: 'Grátis',
    Monthlyfee: 'Taxa mensal',

    Annualfee: 'Taxa anual',
    Feature: 'Recursos',
    enterprise_current_plan: 'Your current subscription plan: {plan}',
    EnDescribe:'Contact us to get more information',
    circulationData: [
      {
        Feature: 'Atendimento ao cliente',
        explain: 'Chat 24/7 e atendimento por e-mail',
        Basic: 'Disponível',
        BasicText: '',
        Standard: 'Disponível',
        StandardText: '',
        AdvancedL: '<span class="blone and">Disponível e prioritário</span>',
        AdvancedText: '',
        Pro: '<span class="blone and">Disponível e prioritário</span>',
        ProText: '',
        Enterprise:'<span class="blone and">Disponível e prioritário</span>',
        EnterpriseText:'',
        NewStandard:'Disponível',
        NewStandardText:'',
        CustomBasic:'Disponível'
      },
      {
        Feature: 'Processamento de pedidos em massa',
        explain: 'Processe múltiplos pedidos ao mesmo tempo no AliExpress',
        Basic: 'Disponível',
        BasicText: '',
        Standard: 'Disponível',
        StandardText: '',
        AdvancedL: '<span class="blone and">Disponível e com alta velocidade para processar mais de 100 pedidos</span>',
        AdvancedText: '',
        Pro: '<span class="blone and">Disponível e com alta velocidade para processar mais de 100 pedidos</span>',
        ProText: '',
        Enterprise:'<span class="blone and">Disponível e com alta velocidade para processar mais de 100 pedidos</span>',
        EnterpriseText:'',
        NewStandard:'Disponível',
        NewStandardText:'',
        CustomBasic:'Disponível'
      },
      {
        Feature: 'Limite de lojas',
        explain:
          'Gerencie múltiplas lojas com uma conta DSers',
        Basic: `<span class="blone">3</span>`,
        BasicText: '',
        Standard: `<span class="blone">5</span>`,
        StandardText: '',
        AdvancedL: `<span class="blone and">10</span>`,
        AdvancedText: '(Popular entre vendedores que atuam em diferentes países)',
        Pro: `<span class="blone and">25</span>`,
        ProText: '(Popular entre vendedores que atuam em diferentes nichos e países)',
        Enterprise:'<span class="blone and">50</span>',
        EnterpriseText:'',
        NewStandard:'<span class="blone">3</span>',
        NewStandardText:'',
        CustomBasic:'<span class="blone">3</span>'
      },
      {
        Feature: 'Limite de produtos',
        explain: '',
        Basic: `<span class="blone">3000 </span> /conta`,
        BasicText: '',
        Standard: `<span class="blone">7500</span>  /conta`,
        StandardText: '',
        AdvancedL: `<span class="blone and ">20000 </span> /conta`,
        AdvancedText: '',
        Pro: `<span class="blone and ">75000 </span> /conta`,
        ProText: '',
        Enterprise:'<span class="blone and ">100,000 </span> /conta',
        EnterpriseText:'',
        NewStandard:'<span class="blone">3000 </span> /conta',
        NewStandardText:'',
        CustomBasic:'<span class="blone">500 </span> /conta'
      },
      {
        Feature: 'Adaptador AliExpress Saver Shipping',
        explain: 'Aproveite as vantagens do método de envio com melhor custo-benefício do AliExpress',
        Basic: `<span class='line2'>—</span>`,
        BasicText: '',
        Standard: `<span class='line2'>—</span>`,
        StandardText: '',
        AdvancedL: `<span class="blone and ">Até 10 produtos</span>`,
        AdvancedText: '',
        Pro: `<span class="blone and ">Até 20 produtos</span>`,
        ProText: '',
        Enterprise:'<span class="blone and ">Produtos ilimitados</span> ',
        EnterpriseText:'',
        NewStandard:`<span class='line2'>—</span>`,
        NewStandardText:'',
        CustomBasic:`<span class='line2'>—</span>`
      },
      {
        Feature: 'Change online products price',
        explain: '',
        Basic: `<span style="color:#FF8F00;font-weight:600">1time/30days</span>`,
        BasicText: '',
        Standard: `<span style="color:#FF8F00;font-weight:600">1time/30days</span>`,
        StandardText: '',
        AdvancedL: `<span style="color:#FF8F00;font-weight:600">4times/30days</span>`,
        AdvancedText: '',
        Pro: `<span style="color:#FF8F00;font-weight:600">(10 vezes/30 dias)</span>`,
        ProText: '',
        Enterprise:'<span style="color:#FF8F00;font-weight:600">(10 vezes/30 dias)</span>',
        EnterpriseText:'',
        NewStandard:`<span style="color:#FF8F00;font-weight:600">1time/30days</span>`,
        NewStandardText:'',
        CustomBasic:`<span style="color:#FF8F00;font-weight:600">1time/30days</span>`
      },
      {
        Feature: 'Gerenciamento de inventário',
        explain: '',
        Basic: `Apenas notificação`,
        BasicText: '',
        Standard: `<span style="color:#FF8F00;font-weight:600">Atualização automática</span>`,
        StandardText: '',
        AdvancedL: `<span style="color:#FF8F00;font-weight:600">Atualização automática</span>`,
        AdvancedText: '',
        Pro: `<span style="color:#FF8F00;font-weight:600">Atualização automática</span>`,
        ProText: '',
        Enterprise:'<span style="color:#FF8F00;font-weight:600">Atualização automática</span>',
        EnterpriseText:'',
        NewStandard:`<span style="color:#FF8F00;font-weight:600">Atualização automática</span>`,
        NewStandardText:'',
        CustomBasic:`<span style="color:#FF8F00;font-weight:600">Atualização automática</span>`
      },
      {
        Feature: 'Gerenciamento de alteração de custo',
        explain: '',
        Basic: `Apenas notificação`,
        BasicText: '',
        Standard: `Apenas notificação`,
        StandardText: '',
        AdvancedL: `<span style="color:#FF8F00;font-weight:600">Atualização automática</span>`,
        AdvancedText: '',
        Pro: `<span style="color:#FF8F00;font-weight:600">Atualização automática</span>`,
        ProText: '',
        Enterprise:'<span style="color:#FF8F00;font-weight:600">Atualização automática</span>',
        EnterpriseText:'',
        NewStandard:`Apenas notificação`,
        NewStandardText:'',
        CustomBasic:`<span style="color:#FF8F00;font-weight:600">Atualização automática</span>`
      },
      {
        Feature: 'Regra de preço automatizada',
        explain: '',
        Basic: `Apenas aumento fixo ou percentual`,
        BasicText: '',
        Standard: `Apenas aumento fixo ou percentual`,
        StandardText: '',
        AdvancedL: `<span style="color:#FF8F00;font-weight:600">Regras baseadas em margens de lucro, custos de envio etc.</span>`,
        AdvancedText: '',
        Pro: `<span style="color:#FF8F00;font-weight:600">Regras baseadas em margens de lucro, custos de envio etc.</span>`,
        ProText: '',
        Enterprise:'<span style="color:#FF8F00;font-weight:600">Regras baseadas em margens de lucro, custos de envio etc.</span>',
        EnterpriseText:'',
        NewStandard:'Apenas aumento fixo ou percentual',
        NewStandardText:'',
        CustomBasic:`Apenas aumento fixo ou percentual`
      },
      {
        Feature: 'Mapeamento de variantes',
        explain: 'Gerencie quantos fornecedores você quiser sem alterar nada na sua loja',
        Basic: `Até 2 fornecedores por produto e sem mapeamento de região visada.`,
        BasicText: '',
        Standard: `Até 2 fornecedores por produto e sem mapeamento de região visada.`,
        StandardText: '',
        AdvancedL: `<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Mapeie cada variante de acordo com a região visada</span>`,
        AdvancedText: '',
        Pro: `<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Mapeie cada variante de acordo com a região visada</span>`,
        ProText: '',
        Enterprise:'<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Mapeie cada variante de acordo com a região visada</span>',
        EnterpriseText:'',
        NewStandard:'Até 2 fornecedores por produto e sem mapeamento de região visada.',
        NewStandardText:'',
        CustomBasic:`Até 2 fornecedores por produto e sem mapeamento de região visada.`
      },
      {
        Feature: 'Mapeamento “compre um e ganhe outro”/pacotes',
        explain: `Crie conjuntos de produtos para aumentar ainda mais as vendas`,
        Basic: "<span class='line2'>—</span>",
        BasicText: '',
        Standard: "<span class='line2'>—</span>",
        StandardText: '',
        AdvancedL: 'ok',
        AdvancedText: '',
        Pro: 'ok',
        ProText: '',
        Enterprise:'ok',
        EnterpriseText:'',
        NewStandard:"<span class='line2'>—</span>",
        NewStandardText:'',
        CustomBasic:"<span class='line2'>—</span>"
      },
      {
        Feature: 'Rastreamento',
        explain: ``,
        Basic: "<span class='line2'>—</span>",
        BasicText: '',
        Standard: "<span class='line2'>—</span>",
        StandardText: '',
        AdvancedL: 'ok',
        AdvancedText: '',
        Pro: 'ok',
        ProText: '',
        Enterprise:'ok',
        EnterpriseText:'',
        NewStandard:"<span class='line2'>—</span>",
        NewStandardText:'',
        CustomBasic:"<span class='line2'>—</span>"
      },
      {
        Feature: 'Conta de equipe',
        explain: ``,
        Basic: "<span class='line2'>—</span>",
        BasicText: '',
        Standard: "<span class='line2'>—</span>",
        StandardText: '',
        AdvancedL: '<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">5</span>',
        AdvancedText: '',
        Pro: '<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Unlimited</span>',
        ProText: '',
        Enterprise:'<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Unlimited</span>',
        EnterpriseText:'',
        NewStandard:"<span class='line2'>—</span>",
        NewStandardText:'',
        CustomBasic:"<span class='line2'>—</span>"
      },
      {
        Feature: 'Afiliado',
        explain: '',
        Basic: "<span class='line2'>—</span>",
        BasicText: '',
        Standard: "<span class='line2'>—</span>",
        StandardText: '',
        AdvancedL: 'ok',
        AdvancedText: '',
        Pro: 'ok',
        ProText: '',
        Enterprise:'ok',
        EnterpriseText:'',
        NewStandard:"<span class='line2'>—</span>",
        NewStandardText:'',
        CustomBasic:"<span class='line2'>—</span>"
      },
      {
        Feature:'Personalização',
        explain:'Personalize os recursos do DSers e tenha maior volume de uso.',
        Basic: "<span class='line2'>—</span>",
        BasicText: '',
        Standard: "<span class='line2'>—</span>",
        StandardText: '',
        AdvancedL: "<span class='line2'>—</span>",
        AdvancedText: '',
        Pro: "<span class='line2'>—</span>",
        ProText: '',
        Enterprise:'<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Taxas extras podem ser geradas com base em solicitações específicas ou volume de uso.</span>',
        EnterpriseText:'',
        NewStandard:"<span class='line2'>—</span>",
        NewStandardText:'',
        CustomBasic:"<span class='line2'>—</span>"
      },
      {
        Feature: 'Envie o custo do produto e de envio à Shopify',
        explain: '',
        Basic: `<span class='line2'>—</span>`,
        BasicText: '',
        Standard: `<span style="color:#FF8F00;font-weight:600">Quando um produto é enviado à loja, o custo do produto do fornecedor e o custo de envio na [Regra de preço avançada] são retornados juntos para o custo por item na Shopify.</span>`,
        StandardText: '',
        AdvancedL: `<span style="color:#FF8F00;font-weight:600">Quando um produto é enviado à loja, o custo do produto do fornecedor e o custo de envio na [Regra de preço avançada] são retornados juntos para o custo por item na Shopify.</span>`,
        AdvancedText: '',
        Pro: `<span style="color:#FF8F00;font-weight:600">Quando um produto é enviado à loja, o custo do produto do fornecedor e o custo de envio na [Regra de preço avançada] são retornados juntos para o custo por item na Shopify.</span>`,
        ProText: '',
        Enterprise:`<span style="color:#FF8F00;font-weight:600">Quando um produto é enviado à loja, o custo do produto do fornecedor e o custo de envio na [Regra de preço avançada] são retornados juntos para o custo por item na Shopify.</span>`,
        EnterpriseText:'',
        NewStandard:'Fixed or percentage increase only',
        NewStandardText:'',
        CustomBasic:`<span class='line2'>—</span>`
      },
      {
        Feature: 'Synchronize Shopify order customization information',
        explain: ``,
        Basic: "<span class='line2'>—</span>",
        BasicText: '',
        Standard: "<span class='line2'>—</span>",
        StandardText: '',
        AdvancedL: `<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Send the customization information from Shopify to suppliers along with the order.</span>`,
        AdvancedText: '',
        Pro: `<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Send the customization information from Shopify to suppliers along with the order.</span>`,
        ProText: '',
        Enterprise:`<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Send the customization information from Shopify to suppliers along with the order.</span>`,
        EnterpriseText:'',
        NewStandard:"<span class='line2'>—</span>",
        NewStandardText:'',
        CustomBasic:"<span class='line2'>—</span>"
      },
   
    ],
    pricingIncludesTitle:' RECURSOS INCLUSOS EM TODOS OS PLANOS',
    pricingIncludesData:[
      {
        "title":"Pré-seleção do método de envio",
        "desc":"Configurez des méthodes d'expédition automatisées pour les commandes AliExpress en fonction des pays, des produits ou des conditions."
      },
      {
        "title":"Correção automatizada de endereços",
        "desc":"Corrija automaticamente a maioria dos problemas nos endereços para reduzir a carga de trabalho"
      },
      {
        "title":"Atualizações automáticas do status do pedido",
        "desc":"Chega de sincronizar manualmente com uma extensão do Chrome. Tudo é automatizado"
      },
      {
        "title":"Sincronização automática de números de rastreamento para a Shopify",
        "desc":"Sincronize automaticamente números de rastreamento do AliExpress e atualize-os na Shopify"
      },
      {
        "title":"Sincronização automática de números de rastreamento para o PayPal",
        "desc":"Melhore sua reputação, enviando números de rastreamento para sua conta do PayPal"
      },
      {
        "title":"Extensão DSers para Chrome",
        "desc":"Importador de produtos e processamento em massa no AliExpress com os cupons recebidos"
      },
      {
        "title":"Mensagem automática para fornecedores",
        "desc":"Envie um modelo de mensagem para que seus fornecedores sejam informados sobre suas solicitações"
      },
      {
        "title":"Envio de notas personalizadas dos clientes nos pedidos para os fornecedores",
        "desc":"Especialmente popular entre usuários que administram um negócio de produção por demanda"
      },
      {
        "title":"Configuração de e-mail de confirmação de envio com rastreamento",
        "desc":"Personalize as informações de rastreamento em nosso e-mail no Shopify"
      },
      {
        "title":"Encontre fornecedores em tendência",
        "desc":"Coleção de fornecedores qualificados e em tendência no AliExpress"
      },
      {
        "title":"Otimização de fornecedores",
        "desc":"Encontre o melhor fornecedor para produtos específicos com um clique"
      },
      {
        "title":"Lista de importação",
        "desc":"Gerencie os produtos que você importa no DSers e migre-os entre suas lojas com facilidade"
      },
      {
        "title":"Divisão de produtos",
        "desc":"Divida um produto em diversos produtos diferentes, para personalizar sua exibição"
      },
      {
        "title":"Publicação em massa de produtos na loja",
        "desc":"Publique os produtos selecionados do AliExpress na sua loja após editá-los"
      },
      {
        "title":"Configurações de fuso horário e moeda",
        "desc":"Configure o fuso horário e as moedas usadas na sua loja"
      }
    ],
    Unfinishedfront: `Você não concluiu a autorização no Shopify.<br />
    Clique em`,
    Unfinishedcentre: 'Assinar',
    Unfinishedqueen: 'para continuar.',
    
    Unfinished:
      'Autorização inacabada no Shopify, clique em Inscrever-se para atualizar.',
    AlertText:
      'Atualize para obter mais recursos e possibilidades. Verifique mais detalhes sobre o <a href="https://help.dsers.com/subscription-plans-presentation-for-shopify/" target="_blank" > plano DSers </a>.',

    pop_Up: 'Selecione a loja que será usada para faturamento no DSers.',
    display: 'Recomendado',
    HomeBasicButton: 'Iniciar',
    HomeAdvancedButton: 'Começar teste Grátis',
    HomeProButton: 'Começar teste Grátis',
    Select: 'Selecionar',
    Current: 'Plano Atual',
    Confirm: 'Confirmar',
    ConfirmCancel: 'Voltar',
    Access: 'Acessar',
    AccessText:
      'Obrigado por sua assinatura. Acesse sua conta DSers divirta-se!',
    Monthly: 'MENSAL',
    Annually: 'ANUALMENTE',
    working: 'Obrigado por trabalhar com o DSers.',
    like: 'Você gostou?',
    over: 'Seu teste gratuito de 14 dias terminou.',
    confirm:
      'Clique no botão abaixo para confirmar a assinatura anual no Shopify.',
    Contnct: 'Contato',
    Subscribe: 'Inscrever',
    ChangePlan: 'Mudar plano',
    selectShop: 'Selecione o que você deseja manter no plano atual do DSers.',
    keepShop:
      'Selecione a quantidade de lojas {quantity} que você deseja manter.',
    PlatformAndStores: 'Plataforma e lojas',
    ShopifyPlatform: 'Plataforma Shopify',
    Woocommerceplatform: 'Plataforma WooCommerce',
    errText:
      'Selecione uma loja para efetuar o pagamento. Se você não possui uma loja disponível para seleção, verifique o status da loja no Shopify.',
    errTextTwo:
      "The default billing store is unavailble. Please check the store's status on Shopify or change the default billing store on DSers.",
    billing: 'Loja de cobrança padrão',
    help:
      'A loja de faturamento é a loja que você usa para pagar sua assinatura do DSers. Para uma melhor experiência, não troque a loja de faturamento constantemente.',
    StoreManagement: 'Gerenciamento de Loja',
    StoreManagementText:
      'Se você decidir fazer o downgrade de seu plano, o DSers manterá suas lojas desativadas aqui para que você possa desconectar as lojas indesejadas e ativar outras lojas de acordo com seu plano. Uma vez desconectado, você não poderá adicionar mais armazenamento do que a restrição dos diferentes planos permite.',
    ActiveStore: 'Ativar loja',
    InactiveStore: 'Loja inativa',
    Activate: 'Ativar',
    Inactivated: 'Inativada',
    modification:
      'Nosso sistema detectou que você alterou sua loja de cobrança padrão para <span style="color:#00BFFF"> {new} </span>, a inscrição na loja <span style="color:#00BFFF"> {old} </span> expirou agora. Clique no botão abaixo para confirmar a opção padrão. loja de cobrança no Shopify.',
    go_to_shopify: 'Ir para o Shopify',
    if_free_dec: `Se você é um usuário de teste gratuito ou desenvolvedor de loja no Shopify,
                  <br /> não será capaz de visualizar a página acima, mas verá a exibida abaixo.
                  <br /> Se você não sabe como cancelar a assinatura atual,
                  <br /> acesse o painel de administração do Shopify para desinstalar o DSers e o instale novamente para selecionar o plano.`,
    change_annual_plan_notion:
      'Se você deseja alterar seu plano atual de assinatura anual, entre em contato com nossa equipe de atendimento ao cliente no <a href="https://www.messenger.com/t/DSersOfficial" dd="src" target="_blank" rel="noopener noreferrer">Facebook</a> ou <a href="mailto:support@dserspro.com">e-mail</a> para receber o reembolso do valor remanescente pago em seu plano atual.',
    subscribe_tios: `The default billing store is closed or deactivated. Please go
    <a href="/app/select"> Manage subscriotion </a>
    to change it and try again`,
    subscription_expired:
      'Your subscription has expired, please renew or change it to continue.',
    subscription_network_tip:
      'If you encounter some network error or any other problems during the payment process, and the subscription is unsuccessful,please <a style="color:#00BFFF" href={jumpUrl}>click here</a> to retry. You can also contact us and we will deal with it for you as soon as possible.',
    subscription_network_tip_2:
      'Caso você se depare com um erro de rede ou qualquer outro problema durante o processo de pagamento e não seja possível realizar a assinatura, entre em contato conosco e solucionaremos a situação para você o mais rápido possível.',
      subscription_btn3:`The first month will be`,
      subscription_btn4:`FREE!`,
      subscription_btn5:`Only for today!`,
      subscription_btn6:`If you upgrade <span class="titlesd">Standard</span>  now, you will able to: <br/> Add <span class="titlesd">2</span> more stores <br/> <span class="titlesd">4,500</span> more products <br/> The product inventory will be automatically updated`,
      subscription_btn7:`UPGRADE NOW`,
      subscription_btn8:`Keep Basic Plan`,
      subscription_btn9:`Upgrade to our Annual payment plan`,
      subscription_btn10:`SAVE $120`,
      subscription_btn11:`Monthly Plan`,
      subscription_btn12:`<span class="blon">49.9</span>/mo`,
      subscription_btn13:`Yearly Plan`,
      subscription_btn14:`<span class="blon">478.8</span>/year`,
      subscription_btn15:`Monthly cost: <span class="blon_d">$39.9</span>`,
      subscription_btn16:`14 days free trial`,
      subscription_btn17:`SELECT`,
      select_account:'Please select the ',
      select_account_2:'staff accounts you want to keep',
      custom_pro:'Custom Pro',
      Custom_EnterPrise:'Custom EnterPrise',
      //oberlo
    custom_basic:'Básico Personalizado',
    custom_to_basic:'Trocar para plano Básico',
    tobasictext:'Confirmar troca',
    oCancel:'Cancelar',
    tobaiscnote:'Observação',
    tobaiscdes:'Ao fazer o downgrade para o plano Básico, você terá um limite de 3.000 produtos, perderá o acesso à sincronização automática de inventário e preços e não conseguirá retornar ao plano Básico personalizado atual. Tem certeza que deseja trocar?',
    "BasicTracking":"basic + tracking",
    "Basic500":"Basic+500",
    "Basic1000":"Basic+1000",
    "CustomBasicTracking":"Custom Basic+Tracking",
    "CustomBasic500":"Custom Basic+500",
    "CustomBasic1000":"Custom Basic+1000",
  }
};
