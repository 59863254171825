
    export default 
    {
    "top": {
        "synchronizing_store": "Sincronizando tienda...",
        "top_select_store": "Todas las tiendas",
        "select_store": "seleccionar tienda",
        "link_to_aliExpress": "Link a AliExpress",
        "bind_ali_tip": "Haz clic aquí para vincular a AliExpress",
        "over_bind": "Cuenta de AliExpress en la lista blanca",
        "over_bind_tip": "Ya has vinculado tu cuenta de AliExpress a Dsers y has pasado a la lista blanca",
        "apply_whitelist": "Solicitar lista blanca",
        "timeout_tip": "Haz clic para obtener una cuenta AliExpress de lista blanca",
        "link_shopify_tip": "Haz clic para vincular a varias tiendas de Shopify.",
        "link_to_more_stores": "Link a más tiendas",
        "link_to_shopify_store": "Link a la tienda de Shopify",
        "bind_ae_title": "Re-authorize AliExpress",
        "bind_ae_content": "The AliExpress whitelisted account you have linked to DSers is about to expire. Please re-authorize AliExpress account. If you have any questions, please  <a href='https://www.messenger.com/t/DSersOfficial' target=\"_blank\" >contact us</a>",
        "bind_ae_expire": "La cuenta de AliExpress de lista blanca que has vinculado a Dsers está a punto de expirar. Por favor, vincula tu cuenta de AliExpress de nuevo.",
        "whitelist_expire": "La cuenta de AliExpress de lista blanca que has vinculado a Dsers está a punto de expirar. Por favor, vincula tu cuenta de AliExpress de nuevo.",
        "update_title": "Actualizaciones de autorización",
        "update_desc": "Dsers ha detectado que las cuentas que aparecen a continuación necesitan actualizar la autorización para Dsers, de lo contrario no podrás utilizar Dsers correctamente",
        "update_desc_is_white": "Dsers detectó que las cuentas que aparecen a continuación necesitan aplicar la lista blanca de AliExpress. Por favor, haz clic en el botón <a href={href} target=\"_blank\" >Aplicar para la lista blanca</a> en la parte superior de la página con el fin de actualizar la autorización. De lo contrario, no podrás utilizar Dsers correctamente.",
        "update_btn": "Actualizar",
        "contact_by_email": "Si tienes alguna duda, ponte en contacto con <span>support@dsers.com</span>",
        "ae_account_linked": "Cuenta AliExpress Vinculada",
        "bind_ae_intro": "A un paso de gestionar tus productos y pedidos con Dsers",
        "bind_ae_outdated_intro": "La autorización de la cuenta de AliExpress caduca cada 3 meses. Por favor, haz clic en Vincular a AliExpress para renovar la autorización.",
        "bindaeTip": "El estado del pedido de Aliexpress no se está actualizado",
        "bindaeTipDetail": "DSers no pueden obtener el estado del pedido de AliExpress porque aún no se ha vinculado tu cuenta AliExpress, por favor, vincula tu cuenta AliExpress tan pronto como sea posible.",
        "binaeBtn": "ENLACE ALEXPRESS",
        "ChristmasTheme":'Tmall Tema',
        "RegularTheme":'Tema Normal'
    }
}