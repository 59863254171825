import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'antd';

import styles from './CommentBanner.less';
import useTopBanner from '../../hooks/useTopBanner';
import commentBg from '../../assets/banner/commentBg.png';
import commentBg2 from '../../assets/banner/commentBg2.png';
import { bannerList } from '../../utils/bannerWhiteList';

const allowPath = [
  '/order/list',
];

const CommentBanner = ({ path, lang, userId, dispatch }) => {
  const [visible] = useTopBanner(path, allowPath);
  const isBannerUser = bannerList.includes(userId);

  if (!visible || !isBannerUser) {
    return null;
  }
  const showComment = () => {
    // 显示comment弹窗
    dispatch({
      type: 'global/showComment'
    });
  };

  return (
    <div className={styles.container} onClick={showComment} style={{cursor:'pointer' }}>
      <div className={styles.banner}>
        <img width="1040" src={commentBg2} className={styles.bannerBg} />
        <div className={styles.content}>
          Let us know how we can improve your purchasing experience
          <br />
          and your contacts
        </div>
        <Button className={styles.button}>CHECK </Button>
      </div>
    </div>
  );
};

CommentBanner.allowPath = allowPath;

export default CommentBanner;
