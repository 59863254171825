import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Button, Collapse, Layout } from 'antd';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';
import intl from 'react-intl-universal';
import qs from 'qs';

import QuickLink from 'components/QuickLink';
import GuideModal from 'routes/Order/GuideModal';
import GlobalHeader from 'components/GlobalHeader';
import MessengerButton2 from '../HelpCenter/component/MessengerButton2';
import styles from './Video.less';
import Point from './component/Point';

const { Panel } = Collapse;

const links = [
  { label: 'Blog', link: 'https://www.blog.dserspro.com/' },
  {
    label: 'Youtube',
    link: 'https://www.youtube.com/channel/UCpiRdgB8VXvaht0AizSP9mA'
  },
  { label: 'Facebook', link: 'https://www.facebook.com/DSersOfficial' }
];

const VideoPage = ({ dispatch, updateLoading }) => {
  const [chooseType, setChooseType] = useState(1);

  useEffect(()=>{
    setChooseType(qs.parse(location.search.slice(1))?.id || 1)
  },[])

  const videoChange = (e) => {
    if(!e){
      setChooseType(null);
      return;
    }

    setChooseType(e);
  }

  const renderHeader = (type) => {
    return (
      type === 1 
      ? (
        <div className={chooseType == 1 ? styles.on : styles.off}>
          {intl.get('guidance.import')}
        </div>
      )
      : (
        <div className={chooseType == 2 ? styles.on : styles.off}>
          {intl.get('guidance.order')}
        </div>
      )
    )
  }

  return (
    <Layout>
      <Layout.Header
        style={{ position: 'fixed', zIndex: 1, width: '100%', padding: 0 }}
      >
        <GlobalHeader showLogo showLanguage={false}/>
      </Layout.Header>
      <Layout.Content style={{ marginTop: 64 }}>
      <div className={styles.collapse}>
        <Collapse 
          accordion 
          style={{width: '868px', margin: '20px auto auto auto'}}
          defaultActiveKey = {qs.parse(location.search.slice(1))?.id || 1}
          expandIcon={() => <span><i class="material-icons">movie</i></span>}
          className={styles.collapseCon}
          onChange={videoChange}
        >
          <Panel 
            header={renderHeader(1)} 
            key="1"
          >
            <GuideModal 
              status={true}
              guideStorage="import"
              video={true}
            />
          </Panel>
          <Panel 
            header={renderHeader(2)} 
            key="2">
            <GuideModal 
              status={true}
              guideStorage="order"
              video={true}
            />
          </Panel>
        </Collapse>
      </div>
      </Layout.Content>
    </Layout>
  );
};

export default connect(({ loading }) => ({
  updateLoading: loading.effects['guidance/postGuidance']
}))(React.memo(VideoPage));
