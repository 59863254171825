const getAeUserId = () => {
  let id = null;

  try {
    id = localStorage.getItem('aliAccountId') || null;
  } catch (error) {
    console.log('get aliAccountId error', error);
  }
  return id;
};

export default getAeUserId;
