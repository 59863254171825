import React, { memo } from 'react';

import styles from './FilterHeader.less'

const FilterHeader = ({title, options = [], renderInput = () => {}}) => {
  return <>
    <div className={styles.filterHeader}>
      <div className={styles.filterHeader_title}>
        {title}
      </div>
      {/* 中间还得新增input框 */}
      { renderInput }
      <div className={styles.filterHeader_box}>
        {
          options?.map(item => {
            return item.render
          })
        }
      </div>
    </div>
  </>
}

export default memo(FilterHeader)