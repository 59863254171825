import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';

import styles from './IossBanner.less';
import useTopBanner from '../../hooks/useTopBanner';
import iossBg from '../../assets/banner/iossBg.png';
import qrCode from '../../assets/banner/qrCode.png';
import icon1 from '../../assets/banner/icon1.png';
import icon2 from '../../assets/banner/icon2.png';
import { bannerListMap, bannerList } from '../../utils/bannerWhiteList';
import DsQRCode from '../DsQRCode';

const allowPath = [
  '/home',
  '/order/list',
  '/storemanage/import',
  '/storemanage/myproduct'
];

const IossBanner = ({ path, lang, userId }) => {
  const [visible] = useTopBanner(path, allowPath);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [href, setHref] = useState('');
  const [text, setText] = useState('');

  const isBannerUser = bannerList.includes(userId);

  useEffect(() => {
    if (isBannerUser && visible) {
      setText('https://wa.me/' + bannerListMap[userId][1]);
      setName(bannerListMap[userId][0]);
      setEmail(bannerListMap[userId][2]);
      setHref('mailto:' + email);
    }
  }, [visible]);

  if (!visible || !isBannerUser) {
    return null;
  }
  return (
    <div className={`${styles.container}`}>
      <a
        href="https://www.dsers.com/blog/eu-vat-reform/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div className={styles.banner}>
          <div className={styles.content}>
            <div className={styles.title}>
              {intl.getHTML('banner.ioss.title2')}
            </div>
            <div className={styles.subTitle}>
              {intl.getHTML('banner.ioss.text2')}
            </div>
            <span className={styles.button}>
              {lang !== 'fr-FR' ? 'LEARN MORE >>>' : 'En savoir plus >>>'}
            </span>
          </div>
          <div className={styles.bannerBg}>
            <img width="1040" src={iossBg} />
            <img src={qrCode} className={styles.qrCode} />
            <DsQRCode text={text} width="76" name="code" />
            <div className={styles.msg}>
              <div className={styles.msgCon}>
                <div className={styles.name}>
                  <img lassName={styles.icon1} src={icon1} />
                  {name}
                </div>
                <div className={styles.email}>
                  <img lassName={styles.icon1} src={icon2} />
                  <a href={href}>{email}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  );
};

IossBanner.allowPath = allowPath;

export default IossBanner;
