/**
 * 根据套餐处理Tracking菜单和路由是否展示
 * 2021 12 17 套餐对应关系
 * PlanTypeBasic  1
 * PlanTypeAdvanced  2
 * PlanTypePro  3
 * PlanTypeStandard 4
 * PlanTypeBusiness  5
 * PlanTypeEnterprise  6
 * PlanTypeDiscountAdvanced  7
 * PlanTypeStandardPrimary  8
 * custombasic  10
 *
 * 1 4 8 10不显示Tracking菜单和路由
 * 
 * ------------------------------------
 * 
 * 根据供应链关系映射表处理agency菜单是否展示
 * agencyUserMap:关系映射表
 */

import { agencyUserMap, shipMethod } from "./bannerWhiteList";

const spliceMap = (val, arr) => {
  arr.map(item => {
    const index = val.findIndex(e => e.name === item);
    if (index >= 0) {
      val.splice(index, 1);
    }
  });
  return val;
};
// 使用路由删除menu 使用name时将会导致误伤
const spliceMapWithPathName = (val, arr) => {
  // console.log(JSON.parse(JSON.stringify(val)), 'delete menu')
  arr.map(item => {
    const index = val.findIndex(e => e.path === item);
    if (index >= 0) {
      val.splice(index, 1);
    }
  });
  return val;
};

/**
 * // 删除菜单
 * @param {*} menusData 菜单
 * @param {*} plan 套餐
 * @returns
 */
export const menuJurisdiction = (menusData, plan, userinfo, csvOrderCount) => {
  // console.log(menusData, '------------menu data-----------');
  // if ([1, 4, 8, 10].includes(plan.type)) {
  //   spliceMap(menusData, ['Tracking']);
  // }
  if(userinfo.status.toString().substring(3, 4) !== '1' && csvOrderCount === 0){
    spliceMap(menusData, ['CSV Bulk Orders','Nouvelles Fonctions','Novo recurso','CSV-Sammelbestellung','Ordine in gruppo CSV','Pedido masivo CSV']);
  }
  let isAgencyUser = agencyUserMap.concat(shipMethod).some(item=>{
    return item === plan.user_id
  })
  // 供应链广告用户（并不是Agency order）
  if(!isAgencyUser){
    spliceMapWithPathName(menusData, ['/agency']);
  } 
  if(!window.CAINIAO_USER) {
    spliceMapWithPathName(menusData, ['/order/agency/list']);
  }
  if(!window.TMALL_USER) {
    spliceMapWithPathName(menusData, ['/order/tmall/list']);
  }
  return menusData;
};

/**
 * // 删除路由
 * @param {*} routes 路由
 * @param {*} plan 套餐
 * @returns
 */
export const routerJurisdiction = (routes, plan) => {
  if ([1, 4, 8, 10].includes(plan.type)) {
    spliceMap(routes, ['Tracking']);
  }
  let isAgencyUser = agencyUserMap.some(item=>{
    return item.UID === plan.user_id
  })
  if(!isAgencyUser){
    spliceMapWithPathName(routes, ['/agency']);
  } 
  return routes;
};
