import React, { memo } from 'react';
import intl from 'react-intl-universal';

import './ActionEditProductChangeItemTip.css';

export const ActionEditProductChangeItemTip = ({type = 2}) => {
  if(!window.TMALL_USER && !window.CAINIAO_USER) return null;
  return <div className='ActionEditProductChangeItemTip'>
        <span>{intl.get('order.editActionTip', {platformType: type === 2 ? 'AliExpress': 'Tmall'})}</span>
      </div>
}