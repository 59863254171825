import React, { useState } from 'react';
import { Popover, Icon } from 'antd';

import styles from './MoreAction.less';
import './mapping.css';

const options = [
  {
    key: 'open_shipping',
    label: 'Open in Add Global Shipping method based on product'
  }
];

function MoreAction({ onChange, style, mappingType }) {
  const [visible, setVisible] = useState(false);

  function handleVisibleChange(value) {
    setVisible(value);
  }

  function handleClick(type) {
    setVisible(false);
    onChange(type);
  }

  const content = mappingType == 'standard' 
  ? (
    <div 
      style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}
      onClick={() => handleClick('open_shipping')}
    >
      <i class="material-icons">local_shipping</i>
    </div>
  )
  : (
    <div className="btnsa">
      {options.map(i => (
        <div key={i.key} onClick={() => handleClick(i.key)} role="presentation">
          {i.label}
        </div>
      ))}
    </div>
  );

  return content;
}

export default MoreAction;
