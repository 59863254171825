import React,{useEffect,memo, useState} from 'react'
import styles from './style.less'


export default memo(() => {

  return <a className={styles.logo} target="_blank" href='/'>
    <img src='https://img.dsers.com/images/logo.png' />
    </a>
})
