export default {
  product: {
    dsers_verified_banner:
      '<h2>DSers <span>Verified</span> Ali Suppliers Project</h2><p>With Payment Solution. No more credit card locked up</p>', // d
    dsers_verified_banner_btn: 'LEARN MORE', // d
    search_placeholder: 'Search My product here',
    search_btn: 'OK',
    tab_all: 'All <i>({all_num})</i>',
    tab_aliexpress: 'AliExpress <i>({ae_num})</i>', // m  v1 Unpushed
    tab_tmall: "Tmall <i>({tm_num})</i>",
    tab_other: 'Unmapped products <i>({map_num})</i>', // m  v1 Pushed
    tab_verified: 'Verified', // d
    btn_import_shopify_products: 'Import Shopify Products',
    btn_import_shopify_products_tip:
      'DSers only sync products ordered on Shopify at the moment.You can import other products by clicking Manage product button on DSers.',
    btn_import_shopify_products_tip_link: 'Check this article',
    select_source: {
      all: 'All',
      unPushed: 'Unpushed', // m
      pushed: 'Pushed' // m
    },
    push_btn: 'Push to Shopify',
    delete_btn: 'Delete',
    delete_selected_products: 'Delete selected products',
    hide_selected_products: 'Hide selected products',
    selectAll: 'Select All',
    hide: 'Hide',
    del: 'Delete',
    myProducts: 'My Products',
    product: {
      remove_from_shopify: 'Removed from Shopify',
      tip_deleted_on_Shopify: `This product has been deleted on Shopify.
                              But orders with this product can still be processed on DSers.
                              You can delete this product by clicking the Trash icon if you want to delete it permanently.`,
      hidden: 'Hidden', // a
      tip_hidden: `This product has been hidden.
                  You will not be able to see this product in your orders and it won’t be processed to AliExpress when placing orders.
                  You can click the “Eye” button to unhide it.`,
      verified_btn_apply: 'APPLY', // d
      verified_btn_edit: 'EDIT', // d
      verified_btn_enable: 'ENABLE', // d
      sample_on_way: 'Your sample is on the way', // d
      piece_tip:
        'The cost is the price shown on AliExpress supplier page. This can be different from the one you are seeing on AliExpress as DSers shows the basic price regardless of the country you are shipping to.',
      piece: 'piece',
      processing: '24h processing',
      cost: 'Cost',
      price: 'Price',
      orders: 'orders',
      stock: 'Stock',
      delete: 'Delete',
      check_details: 'Check details',
      edit_details: 'Edit product',
      open_in_shopify: 'Open in Shopify',
      publish_firsh: 'Publish first then open in Shopify',
      split: 'Split product', // a
      hidden_tip:
        'Click to remove from blacklist.You will see the product in order page.', //  add
      hidden_open_tip:
        'Click to Hide this product and not process it with your orders.', //  add
      mapping_Page: 'Mapping Page',
      mapping_tip: 'Publish first then add suppliers', //  d
      push_to_shopify: 'Push to Shopify',
      download_all_img: 'DOWNLOAD ALL',
      import_to_store: 'Import to store', // d
      no_out_stock: 'Variant out of stock',
      multiple: 'Multiple'
    },
    manage_product: {
      title: 'Select product and set up shipping method',
      title1: "Import Shopify Products",
      nombre:`If you have a large number of items to import, you can <a href="https://www.messenger.com/t/DSersOfficial" dd="src" target="_blank">contact customer service</a> and we will help you.`,
      search_placeholder: 'Find products...',
      no_data: 'No data',
      syncing: 'Syncing',
      import_btn: 'Import',
      select_max_tip: 'You can only import at most 10 products per time',
      import_max_tip: 'At most import 10 products in bulk per time',
      import_shopify_Copywriting:
        'Oops, it seems like the loading took too long, please try again',
      bulk_import:
        'If you have a large number of items to import, you can <a href="https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2FDSersOfficial" target="_blank" rel="noopener noreferrer">contact customer service</a> and we will help you'
    },
    visible_succ: 'Successful operation',
    already_imported:
      'You already imported this product, you can\'t import it again',
    copy_first_pushed:
      'Opening failed, the product needs to be Push/Published to Shopify first',
    succ_copy: 'Successfully copied',
    copy_failed: 'copy failed',
    update_succ: 'Update success!',
    delete_tip: 'Are you sure you want to delete this product?',
    delete_pushed_tip:
      '<span className={ styles.deleteConfirm }>Deleted products <strong>CANNOT BE RECOVERED </strong> and will be <strong>DELETED FROM YOUR SHOPIFY STORE.</strong> Do you want to continue?</span>',
    delete_confirm:
      'Are you completely sure you want to perform this action? It is DEFINITIVE and your product(s) will be DELETED from your Shopify store as well!', // m
    input_confirm_tip: 'Please input CONFIRM to delete.',
    input_confirm_tip2: 'Please input CONFIRM to update.',
    open_mapping_tip: 'Publish first then add suppliers',
    no_data: {
      add_shopify_store: {
        title: 'Add a Shopify store',
        description: `Add Shopify store(s) to DSers by clicking Link to Shopify to
                      manage your products.<br />Map an AliExpress product before place
                      multiple orders`
      },
      is_sync:
        'Synchronizing Shopify information, please wait for sync to complete.',
      no_data: 'Start your business with DSers, click',
      no_data_end: 'to check how to import products from AliExpress to DSers !',
      has_store: {
        title: `DSers will only show and automatically sync products ordered on Shopify.<br />
                But don't worry, you can still import products you want to show on DSers from your Shopify product list by clicking <br />`,
        btn: 'Manage product'
      },
      no_product_tip_1:`1. Click 'Import Shopify product' in the upper right corner to import Shopify products into DSers`,
      no_product_tip_2:`2. Map the imported products so that you can process orders faster`
    },
    visible_progress_title1: 'Products are visible on your Shopify store.',
    visible_progress_title2: 'Making order on AliExpress...',
    show_supply_chain: {
      // d
      btn: 'LEARN MORE',
      description: `No More Credit Card Locked Up <br/>
                    Delivery 100% Guaranteed <br/>
                    Ship Out Within 3 Days <br/>
                    Real Time Customer Service <br/>`,
      title: 'DSers <i>Verified</i> Ali Suppliers Project'
    }, // d
    show_balance_no: {
      //  d
      btn: 'MAKE PAYMET',
      tip: 'Available DSers Balance',
      title: 'There is not available balance <br/> in your account'
    }, //  d
    show_address_no: {
      //  d
      btn: 'Go to Setting',
      tip:
        'Please set your address information and ensure that the balance is greater than 0.'
    }, //  d
    show_set_visible: {
      tip: 'Also publish to Online Store',
      continue_selling: 'Continue selling when out of stock',
      description:
        'Product(s) will be pushed to Shopify store and cannot be edited on DSers anymore. But you can still manage suppliers',
      cancel: 'Cancel',
      yes: 'Yes'
    },
    hide_prod_tip:
      'Are you sure to hide the product ? Order items with this product will not be shown on DSers and you can not sync the hidden items to DSers any more.',
    confirm_prod_tip: 'Please input CONFIRM to hide the product. ',
    possible_cause: 'Possible cause:',
    search_notice_for_migrate: 'Please do not input url here. If you want to import products, please go to Import list',
    search_msg_for_migrate: 'Please go to the Import list to import products. Click <a href="https://help.dsers.com/import-list-for-shopify/" target="_blank"><strong>here</strong></a> to get more information.',
    filter_push_title: 'Filter by push',
    to_push_shopify: 'Already pushed to Shopify',
    no_push_shopify: 'Not pushed to Shopify',
    introduce_con1: 'You are about to delete the selected product(s) from DSers. But the products will be kept on Shopify.',
    introduce_con2: 'You are about to delete the selected product(s) from DSers. And the products will also be deleted from Shopify store.',
    introduce_checkbox: 'Delete this product from my Shopify store too.',
    introduce_ok: 'CONFIRM',
    introduce_cancle: 'Cancel',
    cost_ible: 'Cost $ ——',
    loading: 'Replacing',
    error: 'Failed to replace',
    success: 'Successfully replaced',
    collections:`Search the full collection's title.(Case insensitive)`,
    product_type:'Select or create a Shopify type',
    tags:'Select or create a Shopify tag',
    vendor:'Select or create vendor',
    filterByNotification:'Filter by Supplier Status',
    filterByHidden:'Filter by Store Product Status',
    filterByMapping:'Filter by Mapping',
    filterByVariants:'Variants out of stock',
    filterByGone:'Products gone from supplier',
    basic_mapping:'Basic',
    standard_mapping:'Standard',
    advance_mapping:'Advanced',
    bogo_mapping:'BOGO',
    bundle_mapping:'Bundle',
    filterBySku:'SKU changed on AliExpress',
    productOutOfStock:'Product out of stock',
    productNotFound:'Product not found',
    filterByHid:'Hidden Products',
    filterByShopifyDelete:'Removed from Shopify',
    is_main_product:'Original unsplit product',
    updatePrice:`While checking the data, we found that the price of the product you migrated from Oberlo was originally a non-US price, but due to an internal error, the price was migrated as a US price. We are very sorry for this.
    In order to correct this error in time and not cause trouble and loss to your subsequent operations, we now provide a dedicated feature to modify the products in your account to the price of the destination country you want. (Only items with U.S. prices can be modified, and each item can only be modified once. If you do not need this service, you can ignore this message. This feature will only be available for one month)`,
    update:"Update price",
    chooseCountry:'Choose a country',
    chooseSub:'Select the country for which you want to update prices',
    update_desc_wait:'products are being updated',
    update_desc_ok:'updates successful',
    update_desc_fail:'updates failure',
    error_one:'Your product is being updated, as your account is being accessed by someone else and the update operation has been performed',
    error_two_title:'None of the products you selected can be updated. ',
    error_two_sub:'1. The price of the product is not set for the United States. 2. Your product is being updated, as your account is being accessed by someone else and the update operation has been performed 3. The product has been updated already.',
    selectCountry:'Select country',
    price_for:'Price for',
    collection_title:'Collections',
    type_title:'Type',
    tags_title:'Tags',
    vendor_title:'Vendor',
    productLimitTitle:'Shopify products limit',
    productLimitSub:'The number of products that can be imported in My Products.',
    aeLimitTitle:'Supplier products limit',
    aeLimitSub:'Sum of the imported products in Import List and the number of products in My Products mapping.',
    productCostToShopify:'Send product cost to Shopify',
    productCostAndShippingCostToShopify:'Send product and shipping cost to Shopify',
    productCostAe:'Send supplier products cost per item to Shopify when you push product.',
    remove_all_imgs:'remove all images'
  }
};
