// 临时的一些文案

export default {
  chore: {
    banner: {
      content: 'Discover the journey your packages follow!',
      learn_more: 'LEARN MORE'
    }
  }
};
