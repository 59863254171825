import React from 'react';
import { Input, Icon } from 'antd';
import styles from './index.less';
import intl from 'react-intl-universal';

const textMap = {
  'en-US': {
    emailrequired:"email is required",
    passwordrequired:'password is required',
    correct:'Please input a correct email address'
  },
  'fr-FR': {
    emailrequired:"L'adresse email est exigée",
    passwordrequired:'Le mot de passe est exigé',
    correct:'Veuillez saisir une adresse électronique valide'
  },
  'pt-PT': {
    emailrequired:"O e-mail é obrigatório",
    passwordrequired:'A senha é obrigatória',
    correct:'Insira um endereço de e-mail válido'
  },
  'de-DE': {
    emailrequired:"E-Mail ist erforderlich",
    passwordrequired:'Passwort ist erforderlich',
    correct:'Bitte geben Sie eine korrekte E-Mail Adresse ein'
  },
  'it-IT': {
    emailrequired:"e-mail richiesta",
    passwordrequired:'password richiesta',
    correct:'Inserire un indirizzo e-mail corretto'
  },
  'es-ES': {
    emailrequired:"el correo electrónico es obligatorio",
    passwordrequired:'la contraseña es necesaria',
    correct:'Por favor, introduce una dirección de correo electrónico válida'
  }
}

const map = {
  UserName: {
    component: Input,
    props: {
      size: 'large'
      // prefix: <Icon type="user" className={styles.prefixIcon} />,
    },
    rules: [
      {
        required: true,
        message: (textMap[localStorage.getItem('lang') ?  localStorage.getItem('lang') : 'en-US'] || textMap['en-US']).emailrequired
      },
      {
        pattern:/^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message:(textMap[localStorage.getItem('lang') ?  localStorage.getItem('lang') : 'en-US'] || textMap['en-US']).correct
      }
    ]
  },
  Password: {
    component: Input,
    props: {
      size: 'large',
      // prefix: <Icon type="lock" className={styles.prefixIcon} />,
      type: 'password'
    },
    rules: [
      {
        required: true,
        message: (textMap[localStorage.getItem('lang') ?  localStorage.getItem('lang') : 'en-US'] || textMap['en-US']).passwordrequired
      }
    ]
  },
  PartnerPassword: {
    component: Input.Password,
    props: {
      size: 'large',
      // prefix: <Icon type="lock" className={styles.prefixIcon} />,
      type: 'password'
    },
    rules: [
      {
        required: true,
        message: intl.get('login.empty')
      }
    ]
  },
  Mobile: {
    component: Input,
    props: {
      size: 'large',
      // prefix: <Icon type="mobile" className={styles.prefixIcon} />,
      placeholder: 'mobile number'
    },
    rules: [
      {
        required: true,
        message: 'Please enter mobile number!'
      },
      {
        pattern: /^1\d{10}$/,
        message: 'Wrong mobile number format!'
      }
    ]
  },
  Captcha: {
    component: Input,
    props: {
      size: 'large',
      placeholder: 'Captcha'
    },
    rules: [
      {
        required: true,
        message: 'Please enter Captcha!'
      }
    ]
  }
};

export default map;
