/* eslint no-plusplus: off */
export const enumResult = {
  0: 'Blank',
  1: 'VeryWeak',
  2: 'Weak',
  3: 'Medium',
  4: 'Strong',
  5: 'VeryStrong'
};

/**
 * reference: https://stackoverflow.com/questions/6151576/how-to-check-password-strength
 *
 * @param {*} password
 * @returns
 */
const checkPasswordStrength = password => {
  let score = 1;

  if (!password || password.length < 1) {
    return 0; // enumResult.Blank;
  }

  if (password.length < 4) {
    return 1; // enumResult.VeryWeak;
  }

  if (password.length >= 8) {
    score++;
  }

  if (password.length >= 12) {
    score++;
  }

  if (password.match(/\d+/)) {
    score++;
  }

  if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
    score++;
  }

  if (password.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,£,(,)]/)) {
    score++;
  }

  return score;
};

export default checkPasswordStrength;
