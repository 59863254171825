import { Get, Post } from '../utils/request';
import { skipShopfiy } from '../utils/utils';
import { getOberloGuidePop } from '../services/oberloGuilde'
import { getNewPlatformProductUserInfo } from './auth';
import { AGENCY_ORDER_USERS } from 'utils/agencyOrderUsers';
import {Tmall_Banner_Cold1,Tmall_Banner_Cold2} from 'utils/tmallColdStartUser'
const locales = {
  'en-US': require('../locale/en-US').default,
  'fr-FR': require('../locale/fr-FR.js').default,
  'pt-PT': require('../locale/pt-PT.js').default,
  'es-ES': require('../locale/es_ES.js').default,
  'de-DE': require('../locale/de_DE.js').default,
  'it-IT': require('../locale/it_IT.js').default
};
import intl from 'react-intl-universal';
export async function queryCurrent(userId) {
  const data = {};
  if (userId) {
    data.ower_bussiness_account_id = userId;
  }
  return Get('/users/info', { data });
}

export async function getNewUserinfo() {
  return Get('/uaa/api/1.0/userinfo', {}, true);
}

// 请求info
export async function queryUserInfo(userId) {

  const threeDays = parseInt(localStorage.getItem('EnterNewHomeTime')) + 259200
  const nowTime = parseInt(new Date().getTime() / 1000)
  const setMigrationInfo = localStorage.getItem('setMigrationInfo') // 获取迁移进度

  // 店铺超限是否需要卡到select页面
  const limitStoreNumHandler = (isOberlo)=>{
    if(!['STEP_STAGE_PRE','STEP_STAGE_QUEUE','STEP_STAGE_STEP1'].includes(setMigrationInfo)){

      if(!isOberlo){
        return true
      }
      let planKey = JSON.parse(localStorage.getItem('store'))?.user_id;
      if(localStorage.getItem(`${planKey}-reducePlan`) == 'true'){
        return true
      }else{
          const planStoreLimitMap = {
            1: 3,
            2: 10,
            3: 25,
            4: 5,
            5: 50,
            6: 9999,
            7: 10,
            8: 5,
            10: 3
          };
          const storesNum = JSON.parse(localStorage.getItem('storeLength'));
          const planType = JSON.parse(localStorage.getItem('payment'));
          if(planType?.type == 0){
            return false
          }
          if(planStoreLimitMap[planType?.type] < storesNum){
            return false
          }else{
            return true
          }
        }
    }else{
      return false
    }

  }


  // eslint-disable-next-line
  return new Promise((resolve, reject) => {
    try {
      queryCurrent(userId)
        .then(async (data = {}) => {
          localStorage.setItem('storeLength', data.data.stores.length);
          localStorage.setItem('lang',data.data.country)
          intl
            .init({
              currentLocale: data.data.country || 'en-US',
              locales
            })
          try {
            await getNewUserinfo().then((result = {}) => {
              if(result.code === 2000 && result?.data?.type === 2 && result?.data?.child_account_platform !== 1) {
                skipShopfiy();
                return;
              }
              if(result.code === 2000 && result?.data?.org_id) {
                // 在老userinfo数据中加入org_id 即主账号id 返点逻辑需要使用此id
                if(data?.data) {
                  data.data.uaa_org_id = result.data.org_id;
                }
              }
            });

          } catch (error) {
            console.log('query uua user info failed')
          }

          if (
            data &&
            data.data &&
            data.data.stores &&
            data.data.stores[0] &&
            data.data.stores[0].id
          ) {
            const storeId = localStorage.getItem('storeId');
            const store = data.data.stores.find(item => item.id === storeId);
            if (!store) {
              localStorage.setItem('storeId', data.data.stores[0].id);
            }
          }

          try {
            // 灰度测试 放这里在页面加载前执行
            const res = await getNewPlatformProductUserInfo();
            if(!res){
              // window.location.href = '/app/errPage.html'
              window.location.href = '/application/';
            }
            if(res?.data?.features?.length){
              res?.data?.features.forEach((i)=>{
                if(i.name === 'tmall' || !!localStorage.getItem('TMALL_USER')){
                  window.TMALL_USER = true
                }
                if(i.name === 'cainiao' || !!localStorage.getItem('CAINIAO_USER')){
                  window.CAINIAO_USER = true
                }
              })
            }else{
              window.TMALL_USER = false || !!localStorage.getItem('TMALL_USER')
              window.CAINIAO_USER = false || !!localStorage.getItem('CAINIAO_USER')
            }
          } catch (error) {
            // window.location.href = '/app/errPage.html'
            window.location.href = '/application/';
          }
          // 菜鸟仍然先写死 防止接口加载失败 导致用户UI发生异常变动 导致数据混乱
          window.CAINIAO_USER =
            window.CAINIAO_USER ||
            AGENCY_ORDER_USERS.includes(data?.data?.user_id);

          window.TMALL_USER_BANNER1 = Tmall_Banner_Cold1.includes(data?.data?.user_id) || !!localStorage.getItem("Tmall_Banner_Cold1")
          window.TMALL_USER_BANNER2 = Tmall_Banner_Cold2.includes(data?.data?.user_id) || !!localStorage.getItem("Tmall_Banner_Cold2")
          if (data.code === 2000 && data.data) {
            const res = await Get('/mgnoberlo-core-api/dsers-api/migration/store/status',[],true);
            const planKey_1 = JSON.parse(localStorage.getItem('store'))?.user_id;

            const migrateUserInfo = data.data

            if(res?.store_stages?.length && migrateUserInfo?.stores?.length){
              const migrateId = res?.store_stages[res?.store_stages?.length - 1]?.dsers_store_id
              let index = migrateUserInfo.stores.findIndex(i=>i.id == migrateId);
              if(index > -1){
                let newArr = migrateUserInfo.stores.splice(index,1);
                migrateUserInfo.stores.unshift(newArr[0]);
                localStorage.setItem('store', JSON.stringify(migrateUserInfo));
              }else{
                localStorage.setItem('store', JSON.stringify(data.data));
              }
            }else{
              localStorage.setItem('store', JSON.stringify(data.data));
            }

            if (data.data.code) {
              localStorage.setItem('userCode', data.data.code);
            }
            // 进入套餐，店铺异常的判断条件（老逻辑）
            // 1.不是迁移用户
            // 2.billing账单正常 && 店铺数量没超限
            // 3.迁移流程引入step2 && 在进入home页3天后
           if(!(res?.store_stages?.length) ||
           (JSON.parse(localStorage.getItem('store_is_available')) && limitStoreNumHandler(!!(res?.store_stages?.length))) ||
           (!['STEP_STAGE_PRE','STEP_STAGE_QUEUE','STEP_STAGE_STEP1'].includes(setMigrationInfo) && threeDays < nowTime) ||
           (localStorage.getItem(`${planKey_1}-reducePlan`) == 'true')){
            if(setMigrationInfo !== 'STEP_STAGE_QUEUE_RESERVATION'){
              Detection(data.data,threeDays, nowTime);
            }
           }
            // reloadAuthorized(data.data || {}, userId);
            resolve(data.data);
          } else {
            reject(data);
          }
          return null;
        })
        .catch(err => {
          reject(err);
        });
    } catch (error) {
      reject(error);
    }
  });
}

export function queryNewInfo (){
  return new Promise((resolve, reject) => {
    try {
      getNewUserinfo()
        .then((result = {})=>{
          if(result.code === 2000){
            localStorage.setItem('newUserInfo',JSON.stringify(result.data))
            if(!result.data.user_role){
               if(localStorage.getItem('noMigra') !== 'false'){
                ChildAccountJump(result.data)
               } else {
                // getOberloGuidePop({ data: { name: 'isBindPlan' } }).then(res => {
                //   if (res.value) {
                //     ChildAccountJump(result.data)
                //   }
                // });
                let planKey = JSON.parse(localStorage.getItem('store'))?.user_id;
                if(localStorage.getItem(`${planKey}-reducePlan`) == 'true'){
                  ChildAccountJump(result.data)
                }
               }
            }
            resolve(result);
          }else{
            reject(result);
          }
        })
        .catch(err=>{
          reject(err)
        })
    } catch (error) {
      reject(error)
    }
  })
}

// 控制跳转方式
export function Detection(val,threeDays,nowTime) {
  // eslint-disable-next-line
  getProject()
    .then(item => {
      const { pathname } = window.location;
      if (item.code === 2000 || item.code === 2010) {
        const dates = item.data;
        let amount = 0;
        let statuse = 0;
        val.stores.forEach(items => {
          if (items.id === dates.default_store_id) {
            statuse = items.status;
          }
          if (items.status === 1) {
            amount += items.status;
          }
        });

        const flag = val.stores?.filter((i)=>{
          if(dates.status == 3){
            return i.id !== dates.default_store_id
          }
        })

        if(flag?.length){
          amount = 1
          statuse = 1
        }

        localStorage.setItem('payment', JSON.stringify(dates));


        if(pathname === '/app/migrationWaiting'){
          return ;
        }

        if (
          ( amount > 0 &&
          `${val.status}`.length === 8 &&
          `${val.status}`.substring(3, 4) === '0') ||
          (
            !amount && val.stores.length &&
            `${val.status}`.length === 8 &&
            `${val.status}`.substring(3, 4) === '0'
          )
        ) {
          if (
            dates.status === 3 &&
            statuse !== 1 &&
            pathname !== '/app/select' &&
            pathname !== '/app/pricing' &&
            pathname !== '/app/bind/link-store' &&
            pathname !== '/app/reminder'
          ) {
            window.location.href = '/app/select';
          }

          if (dates.status === 6 && pathname !== '/app/pricing') {
            window.location.href = '/app/pricing?a=1';
          }

          if (
            dates.type === 0 &&
            amount > 0 &&
            pathname !== '/app/pricing' &&
            val.stores.length > 0 &&
            !(
              window.location.pathname === '/app/reminder' &&
              window.location.search
            )
          ) {
            // 逻辑不清楚 暂时再这里跳转 可能有问题
            window.location.href = '/app/pricing?a=2';
          }

          if (dates.status === 5 && pathname !== '/app/reminder') {
            window.location.href = '/app/reminder';
          }

          if(dates.status === 3 && pathname !== '/app/reminder'  && threeDays > nowTime && localStorage.getItem('noMigra') == 'false'){
            return
          }else if (dates.status === 3 && pathname !== '/app/reminder') {
            if (
              dates.period === 1 &&
              dates.old_billing_store_name &&
              statuse === 1 &&
              pathname !== '/app/Subscribe' &&
              pathname !== '/app/pricing'
            ) {
              if(pathname !== '/app/select'){
                window.location.href = '/app/Subscribe';
              }
            }

            if (
              dates.period === 1 &&
              !dates.old_billing_store_name &&
              pathname !== '/app/select' &&
              pathname !== '/app/pricing'
            ) {
              window.location.href = '/app/select';
            }

            if (
              dates.period === 2 &&
              statuse === 1 &&
              pathname !== '/app/Subscribe' &&
              pathname !== '/app/pricing'
            ) {
              if(pathname !== '/app/select'){
                window.location.href = '/app/Subscribe';
              }

            }
          }

          if (
            dates.status === 3 &&
            dates.period === 1 &&
            statuse === 1 &&
            !dates.old_billing_store_name &&
            pathname !== '/app/select' &&
            pathname !== '/app/pricing' &&
            pathname !== '/app/bind/link-store' &&
            pathname !== '/app/reminder'
          ) {
            window.location.href = '/app/select';
          }

          if (
            (dates.status === 2 || dates.status === 4) &&
            (dates.type === 1 || dates.type === 10)&&
            amount > 3 &&
            pathname !== '/app/select' &&
            !dates.old_user
          ) {
            window.location.href = '/app/select';
          }
          if (
            (dates.status === 2 || dates.status === 4) &&
            (dates.type === 2 || dates.type === 7) &&
            amount > 10 &&
            pathname !== '/app/select' &&
            pathname !== '/app/pricing' &&
            pathname !== '/app/bind/link-store' &&
            !dates.old_user
          ) {
            window.location.href = '/app/select';
          }
          if (
            (dates.status === 2 || dates.status === 4) &&
            dates.type === 3 &&
            amount > 25 &&
            pathname !== '/app/select' &&
            pathname !== '/app/pricing' &&
            pathname !== '/app/bind/link-store' &&
            !dates.old_user
          ) {
            window.location.href = '/app/select';
          }
          if (
            (dates.status === 2 || dates.status === 4) &&
            (dates.type === 4 || dates.type === 8) &&
            amount > 5 &&
            pathname !== '/app/select' &&
            pathname !== '/app/pricing' &&
            pathname !== '/app/bind/link-store' &&
            !dates.old_user
          ) {
            window.location.href = '/app/select';
          }
        }
      } else if (
        pathname !== '/app/select' &&
        pathname !== '/app/pricing' &&
        pathname !== '/app/bind/link-store'
      ) {
        window.location.href = '/app/select';
      }
      return null;
    })
    .catch(err => {
      console.log('getProject error:', err);
    });
}

//子账户超限控制跳转
export function ChildAccountJump(value){
  getProject().then(
    item=>{
      const {pathname} = window.location
      if(item.code === 2000 || item.code === 2010){
        getChildAccount().then(
          result=>{
            if(result && result.code === 2000){
              let usableAccount = result.data?.content.filter(item2=>{
                return item2.status !== 2
              })
              if(pathname === '/app/migrationWaiting'){
                return ;
              }

              if((item.data.status === 2 || item.data.status === 4) &&
                item.data.type === 2 &&
               usableAccount.length - 1 > 5 &&
               pathname !== '/app/select' &&
               pathname !== '/app/pricing'
              ){
                window.location.href = '/app/select';
              }
              return null
            }
          }
        )
        .catch(err=>{
          console.log('getChildAccount error:', err);
        })
      }
    }
  )
  .catch(err=>{
    console.log('getProject error:', err)
  })
}

export async function getNotificationToken(payload) {
  return Post('/notification/register', payload);
}

export async function getProject(payload) {
  return Get('/charge/plan', payload);
}

export async function getNotificationList(payload) {
  return Get('/notification/list_timer', payload);
}

export async function getRates(payload) {
  return Get('/currency/latest', payload);
}

export async function getChildAccount(payload={}){
  return Get('/uaa/api/1.0/user/child_accounts/shopify',payload,true)
}

export async function getNoviceGuidance(payload={}){
  return Get('/api/v1/tutorial/new_user',payload,true)
}

export async function reportAccout(payload){
  return  Post('/mgnoberlo-core-api/dsers-api/migration/action/bind-account',payload,true)
}

export async function reportNewUser(payload){
  return Post('/mgnoberlo-core-api/dsers-api/migration/user/register',payload,true)
}
export async function getCsvOrderList(payload){
  return Get('/csv/orders',{
    data:{
      current: 1,
      page_size: 10
    }
  })
}
