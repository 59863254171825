import React, { useEffect, useRef } from 'react';
import QRCode from 'qrcodejs2';
import styles from './index.less';

const DsQRCode = ({ text, width, name }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current && text) {
      const qrcode = new QRCode(ref.current, {
        width,
        height: width
      });
      console.log('text---------------------:', text);
      console.log('name---------------------:', name);
      qrcode.makeCode(text);
    }
  },[text]);

  return <div className={`${name}`} ref={ref} />;
};

export default DsQRCode;
