
    export default 
    {
    "menu": {
        "Switch": "Plattform wechseln",
        "blog": "BLOG",
        "facebook": "Facebook",
        "youtube": "YouTube",
        "email_support": "E-Mail-Unterstützung"
    }
}