export default {
    migration: {
        progress_1:'Konto eröffnen',
        progress_2:'Einstellungen',
        progress_3:'Datenmigration',
        title:'Willkommen bei DSers Migration',
        title_2:'Wir haben 2 Lösungen für die Datenmigration vorbereitet. Welche bevorzugen Sie?',
        scenario_1:'Wenn Sie Ihr Unternehmen mit Oberlo verwalten, empfehlen wir Ihnen:',
        scenario_1_title:'Ersetzen Sie DSers-Daten durch Oberlo-Daten',
        scenario_1_text:'Bitte beachten Sie, dass die Daten von DSer gelöscht werden.',
        scenario_2:'Wenn Sie Ihr Geschäft mit Oberlo und DSers verwalten, empfehlen wir:',
        scenario_2_title:'Zusammenführung von Oberlo-Daten und DSers-Daten',
        scenario_2_text:'Bitte bestätigen Sie die Datenmigrationseinstellungen.',
        button:'GEWÄHLTE PRÄFERENZEN ANWENDEN',
        blog:'Kurzanleitung: Migrationsprozess | Funktionsvergleich | Planvergleich',
        contact_1:'24/7 Live-Chat-Unterstützung',
        contact_2:'E-Mail-Unterstützung',
        option_1_title:'Für Bestellungen & Produktkonflikt zwischen DSers und Oberlo, sie können:',
        option_1_text:`Es kann zu Konflikten kommen, wenn ein Shopify-Produkt mit verschiedenen AliExpress
        Lieferanten in DSers und Oberlo zugeordnet ist, oder wenn Sie eine Bestellung bei AliExpress mit Oberlo, aber nicht in DSers aufgegeben haben.`,
        option_2_title:'Für Einstellungen Konflikte zwischen DSers und Oberlo, Sie können:',
        option_2_text:`Zum Beispiel haben DSers und Oberlo beide die Preisregel-Funktion, Ihre
        Datenmigration kann aufgrund unterschiedlicher Einstellungsvorlagen auf Konflikte stoßen`,
        option_1_1:'DSer als Referenz',
        option_1_2:'Oberlo als Referenz',
        setting_title:'Bei Datenkonflikten zwischen DSers und Oberlo, können sie:',
        setting_text:'Zum Beispiel haben DSers und Oberlo beide die Funktion Preisregel, Ihre Datenmigration kann in Konflikt geraten, weil sie unterschiedliche Einstellungsvorlagen haben, so dass Sie die Präferenz im Voraus bestätigen müssen.',
        data_title:'Zum Starten klicken',
        data_title_2:`Während der Migration werden Ihre Oberlo- und DSers-Konten nicht verfügbar sein.
        Sie werden nach Abschluss wieder verfügbar sein.`,
        data_button:'START MIGRATION',
        data_button_wait:'BEVORSTEHENDE MIGRATION',
        data_button_text:`Wenn Sie auf Migration starten klicken, wird Ihr Oberlo-Konto auf schreibgeschützt gesetzt
        und Sie stimmen unseren Nutzungsbedingungen zu. Die Migration dauert bis zu {time} Stunde.`,
        data_change:'Einstellungen ändern',
        data_process_title:'Migration läuft...',
        data_process_text:'Bitte lassen Sie DSers & Oberlo App installiert und Shopify Shop aktiv',
        data_setting:'Einstellungen',
        data_order:'Bestellungen',
        data_product:'Produkte',
        data_plan:'Plan',
        optionstext:{
            setting:['Migration der Einstellungen im Gange...','Verringern Sie die Anzahl der Streitfälle durch Synchronisierung der Kontrollnummern mit PayPal'],
            order:['Migration der Auftragsdaten im Gange...','100+ Bestellungen in Sekundenschnelle aufgeben','Automatischer Abgleich des Auftragsstatus.'],
            product:['Produktdatenmigration im Gange...','Produktbündel mit Variantenzuordnung erstellen'],
            plan:['Abo-Plan passend...','Sie erhalten Zugang zu zahlreichen leistungsstarken Funktionen.']
        },
        waitmigration_title:'Das Konto wird gerade migriert, bitte warten Sie',
        waitmigration_text:'Es existiert bereits ein Konto für die Oberlo-Migration. Bitte warten Sie darauf, dass dieses Konto migriert wird.',
        timeout_title:'Zeitüberschreitung der Anfrage',
        timeout_text:'Die Anfrage wurde abgebrochen, bitte aktualisieren Sie die Seite oder autorisieren Sie sich erneut von Oberlo aus Bei Fragen wenden Sie sich bitte an den DSers-Support. ',
        timeout_contact:'KONTAKT ZUM SUPPORT',
        childAccount:'Das aktuelle Konto ist ein DSers-Unterkonto und kann nicht für die Migration verwendet werden. Bitte melden Sie sich beim Hauptkonto an oder erstellen Sie ein neues DSers-Konto.',
        blackAccount:'Das aktuelle Konto ist nicht verfügbar. Bitte ändern Sie die E-Mail-Adresse und versuchen Sie es erneut.',
        otherplatform:'Das aktuelle Konto ist an andere Plattformen gebunden, bitte wechseln Sie das DSer-Konto und versuchen Sie es erneut.',
        card_1_title:'Wie wollen Sie mit Ihren Daten umgehen?',
        question_1:'1. Für Produktdaten in zwei Konten wollen Sie：',
        question_1_select1:'Oberlo-Daten migrieren',
        question_1_select2:'DSers Daten ebenfalls speichern',
        question_2:'2. Für Auftragsdaten in zwei Konten wollen Sie:',
        card_2_title:'Wie wollen Sie mit Datenkonflikten umgehen?',
        question_3:`1.Für Bestellungen & Produkte Konflikt zwischen DSers und Oberlo, Sie bevorzugen:`,
        question_3_select1:'Oberlo',
        question_3_select2:'DSers',
        question_4:'2. Für Einstellungskonflikte zwischen DSers und Oberlo, Sie bevorzugen:',
        childAccountawait:'Migration läuft...',
        childAccountawaittext:'DSers-Konto, das von Ihrem Admin-Konto aus migriert wird.',
        code_500:'Migrationsausnahme, wenden Sie sich bitte an den DSers-Support, um dieses Problem zu lösen.',
        code_426:'Der Dienst wird gerade aktualisiert, bitte versuchen Sie es später noch einmal. Wenn Sie Fragen haben, wenden Sie sich bitte an den DSers-Support.',
        exitmigrationtext:'Nach Beendigung der Migration können Sie Oberlo aufrufen, um die Migration erneut zu starten',
        shoperror:'Bitte stellen Sie sicher, dass Ihr Shopify-Shop bereit ist und DSers autorisiert hat',
        shopcontinue:`MIGRATION FORTSETZEN`,
        dataerror:'Während des Migrationsprozesses sind Probleme mit den Daten aufgetreten. Klicken Sie auf die Schaltfläche Erneut versuchen", um es erneut zu versuchen, oder klicken Sie auf "Kundenservice kontaktieren", um uns zu kontaktieren.',
        datacontinue:'NOCH EINMAL VERSUCHEN',
        refresh:'Bitte aktualisieren Sie die Seite',
        customerservice:'Kontakt zum Support',
        oberloError:'Access to Oberlo failed. Please contact the customer service.',
        requestid:'Bitte melden Sie diese ID dem DSers-Supportteam zur Fehlerbehebung:' ,
        startButton:`Hinweis：<br/>
        1. nachdem Sie die Migration gestartet haben, wird Ihr Oberlo-Konto auf schreibgeschützt gesetzt. <br/> 2. die Migration dauert etwa 1 Stunden.`,
        exitmigration:'MIGRATION BEENDEN',
        according:'Welche Daten werden letztendlich in Ihrem DSers-Konto sein?',
        cover:`Je nach Konfiguration.DSers will keep your Oberlo account's Products & Orders & Settings data`,
        oberlo_oberlo:'Je nach Konfiguration.DSers wird die Produkt-, Bestell- und Einstellungsdaten von DSers- und Oberlo-Konten für Sie verwalten. Im Falle von Datenkonflikten während der Synchronisierung haben die Oberlo-Daten Vorrang.',
        dsers_dsers:'Je nach Konfiguration.DSers wird die Produkt-, Bestell- und Einstellungsdaten von DSers- und Oberlo-Konten für Sie verwalten. Bei Datenkonflikten während der Synchronisierung haben die Daten von DSers Vorrang.',
        dsers_oberlo:'Je nach Konfiguration.DSers wird die Produkt-, Bestell- und Einstellungsdaten von DSers- und Oberlo-Konten für Sie verwalten. Im Falle von Datenkonflikten während der Synchronisierung haben die Produkte & Bestellungen von DSers Vorrang und die Einstellungen von Oberlo Vorrang',
        oberlo_dsers:'Je nach Konfiguration.DSers wird die Produkt-, Bestell- und Einstellungsdaten von DSers- und Oberlo-Konten für Sie verwalten. Bei Datenkonflikten während der Synchronisation haben die Produkte & Bestellungen von Oberlo und die Einstellungen von DSers Vorrang.',
        havetask:`Für das aktuelle Konto wird gerade eine Aufgabe ausgeführt. Bitte versuchen Sie es später noch einmal.`,
        timeout:'The server request timed out, please click the "Start Migration" button to restart the migration',
        appointTip:`Nachdem Sie die Zeit eingestellt haben, können Sie sie nicht mehr ändern. Bitte wählen Sie einen geeigneten Zeitpunkt für die Migration. Nach Beginn der Migration können Sie Oberlo und DSers nicht mehr nutzen. Wenn Sie Fragen haben, kontaktieren Sie uns bitte`,
        data_wait_text:'Die Migration wird innerhalb von {time} Stunde beginnen. Sie können Ihr Oberlo-Konto betreiben, während Sie warten, aber die Migration kann jederzeit beginnen.',
        appointConfirm:'Bestätigen der Zeiteinstellung',
        appointSuccess:'Zeit erfolgreich eingestellt',
        startNow:'Migration jetzt beginnen',
        timefull:'Das aktuell ausgewählte Zeitfenster ist voll. Sie können die Migration sofort starten.',
        nowTimeFull:'Dieses Zeitfenster ist nicht verfügbar, bitte wählen Sie ein anderes Zeitfenster',
        tooltiptext:`Sie haben Dateninformationen sowohl auf Oberlo als auch auf DSers. Wir haben uns entschieden, die Daten auf beiden Plattformen für Sie zu speichern. Wenn Sie die Daten von DSers nicht behalten möchten, können Sie das Kontrollkästchen neben "DSers-Daten ebenfalls behalten" deaktivieren.`,
        tooltiptext_2:`Wenn Sie z. B. denselben Artikel auf Oberlo und DSers haben, aber unterschiedliche Beschreibungen und Preisangaben für den Artikel, kann es bei der Datenmigration zu Konflikten kommen.`,
        migrationText:'Startzeitpunkt der Migration'
    }
}