import React from 'react';
import { connect } from 'dva';
import { Modal, Button, message } from 'antd';
import { routerRedux } from 'dva/router';

import RefreshMask from 'components/RefreshMask';

import intl from 'react-intl-universal';
import reactGaEvent from '../../utils/GAEvent';

import { getShopifyStoreStatus } from '../../utils/utils';
import aeIcon from '../../assets/ae-icon.png';
import Shopify from '../../assets/home/Shopify.png';

import styles from './index.less';

@connect(({ topBtn, loading, login }) => ({
  topBtn,
  loading: loading.models.topBtn,
  userInfo: login.userInfo
}))
export default class UpdateStore extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      showRefreshMask: false // 新标签打开页面，在回来是否打开刷新遮罩
    };
  }

  componentDidMount() {
    this.setState({
      visible: this.props.showUpdateStore
    });
  }

  // 更新shopify授权
  goShopify = shop_name => {
    this.props.dispatch({
      type: 'topBtn/updateShopfiy',
      payload: {
        data: {
          shop_name
        },
        cancelMessage: true,
        callback: d => {
          if (d.code == 2000) {
            window.location.href = d.data.url;
          }
        }
      }
    });
  };

  // 关闭弹窗
  closeUpdateStore = () => {
    this.setState({
      visible: false
    });

    localStorage.setItem('hasCloseUpdateStore', '1');
  };

  handleUpdate = () => {
    this.setState({
      visible: false
    });

    this.props.dispatch(routerRedux.push('/settings?id=3'));
  }

  // 获取AE授权跳转地址
  LinkAliExpress = () => {
    reactGaEvent({
      category: 'Authorization Updates Dialog',
      action: 'update AE'
    });
  };

  render() {
    const { visible, showRefreshMask } = this.state;

    return (
      <>
        <Modal
          visible={visible}
          className={styles.needUpdateStore}
          onCancel={() => {
            this.setState({
              visible: false
            });
          }}
          footer={
          <Button type="primary" style={{ marginLeft: 24 }} onClick={this.handleUpdate}>
          {intl.get('linkStore.modalButton')}
        </Button>}
          title={intl.get('top.update_title')}
          width={540}
          maskClosable={false}
        >
          <p className={styles.dec}>{intl.getHTML('top.update_desc')}</p>
          <ul className={styles.stores}>
            {this.props.userInfo.stores.map(item => {
              const update = getShopifyStoreStatus(item.id);
              return update ? (
                <li key={item.id}>
                  <p title={item.domain}>{item.domain}</p>
                  <div>
                    <a
                      role="button"
                      tab-index="0"
                      onClick={() => this.goShopify(item.shop_name)}
                    >
                      {intl.get('top.update_btn')}
                    </a>
                    <img src={Shopify} />
                  </div>
                </li>
              ) : null;
            })}
          </ul>
      </Modal>

        <RefreshMask visible={showRefreshMask} />
      </>
    );
  }
}
