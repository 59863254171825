
    export default 
    {
    "banner": {
        "title": "APP oficial de AliExpress para socios de Dropshipping",
        "quick_orders": "Dsers es la mejor herramienta para procesar todos tus pedidos en minutos, sin límite (100+)",
        "get_started_placeholder": "Ingresar dirección de correo electrónico",
        "get_started": "Comenzar",
        "order_management": "Gestión de pedidos",
        "order_management_description": "Dsers es capaz de gestionar pedidos masivos y múltiples. Procesa más de 100 pedidos al día en un par de minutos. Todos los pedidos se sincronizan desde Shopify simultáneamente. De esta manera, el dropshipper podrá realizar su pedido en AliExpress con un solo botón. ¡Ahórrate horas de tu tiempo!",
        "supplier_management": "Gestión de proveedores",
        "supplier_management_description": " Establece varios proveedores para los artículos seleccionados. El proveedor alternativo sólo se activará en caso de que el producto no esté disponible en tu proveedor por defecto (es decir, sin existencias). Millones de productos significan cientos de proveedores y un stock interminable en AliExpress. ¡Nunca te quedes sin existencias! (¡Una nueva función que llegará pronto te permitirá encontrar automáticamente el proveedor más barato!).",
        "csv_bulk_orders": "Pedidos masivos CSV",
        "csv_bulk_orders_description": "Disfruta de Dsers y realiza múltiples pedidos a la vez sin importar la plataforma de venta que estés utilizando. Crea y carga la base de datos de tus productos, ¡importa tus pedidos pendientes a Dsers y ejecútalos todos a la vez! Así de sencillo. Ahorra más tiempo que nunca con Dsers.",
        "save_97_ordering_time": "Ahorra un 97% de tiempo en los pedidos",
        "how_does_work": {
            "title": "¿Cómo funciona Dsers para el dropshipper?",
            "easy": "Fácil de empezar. Fácil de manejar.",
            "multiple_store": "Múltiples tiendas",
            "multiple_store_dec": "Gestionar múltiples tiendas de Shopify",
            "supplier_management": "Gestión de proveedores",
            "supplier_management_dec": "Un producto con múltiples proveedores",
            "multiple_orders": "Múltiples pedidos",
            "multiple_orders_dec": "Procesar múltiples pedidos con un solo clic",
            "auto_sync": "Sincronización automática",
            "auto_sync_dec": "Estado del pedido sincronizado desde AliExpress"
        },
        "feedback": {
            "title": "100% Confiable gracias a la confianza de personas como tu",
            "feedback": "Comentarios de nuestros principales usuarios",
            "like": "Me gusta",
            "comment": "Comentario",
            "share": "Compartir",
            "comments": "Comentarios",
            "tomas": "Tomas",
            "tomas_time": "Ayer a las 4:19 AM",
            "tomas_dec": "Estamos utilizando Dsers como herramienta para los pedidos en (No hay sugerencias). Es una herramienta muy potente. Muchas gracias por ello.",
            "simon": "Simón",
            "simon_time": "2 de noviembre de 2018 a las 11:09 AM",
            "simon_dec": "¡Dsers es una gran aplicación! Estoy muy emocionado de usarla, ya que me ahorrará mucho tiempo y dinero. Es una experiencia muy chula poder completar cientos de pedidos en tan solo un par de minutos.",
            "wilson": "Wilson",
            "wilson_time": "Ayer a las 9:22 PM",
            "wilson_dec": "¡Esta aplicación es literalmente un sueño! Es realmente asombroso cuando vi que los pedidos a granel se ejecutaban con éxito en un minuto. Ayuda a ahorrar trabajo.",
            "jonathan": "Jonathan",
            "jonathan_time": "a las 17:48",
            "jonathan_dec": "¡Estoy muy contento de haber encontrado su sitio web, increíble trabajo! El procesamiento de pedidos se hizo mucho más fácil desde que empecé a usar Dsers."
        },
        "features": {
            "title": "Dsers proporciona todas las características para el negocio de (No hay sugerencias)",
            "dec": "Características principales de Dsers",
            "multiple_order_processing": "Procesamiento de pedidos múltiples",
            "multiple_order_processing_dec": "Procesamiento de pedidos múltiples",
            "variants_mapping": "Mapeo de variantes",
            "variants_mapping_dec": "Añade fácilmente variantes a los productos incorporando proveedores",
            "shipment_setting": "Configuración del envío",
            "shipment_setting_dec": "Establecer el método de envío por países y tarifas",
            "aliExpress_whitelist": "Lista blanca de AliExpress",
            "aliExpress_whitelist_dec": "No más limitación de pedidos",
            "multiple_stores_manager": "Gestor de múltiples tiendas",
            "multiple_stores_manager_dec": "Gestiona varias tiendas en una sola cuenta",
            "supplier_management": "Gestión de proveedores",
            "supplier_management_dec": "Añade múltiples proveedores a un producto",
            "product_importer": "Importador de productos",
            "product_importer_dec": "Importar productos con la extensión de Chrome",
            "auto_messaging": "Mensajería automática",
            "auto_messaging_dec": "Crear mensajes automáticos para los vendedores de AliExpress",
            "email_template": "Plantilla de correo electrónico",
            "email_template_dec": "Correo electrónico de notificación de envío disponible próximamente",
            "tracking": "Seguimiento",
            "tracking_dec": "Seguimiento de la información de envío con precisión",
            "price_monitor": "Monitor de precios",
            "price_monitor_dec": "Recibe notificaciones de los cambios de precios en AliExpress",
            "inventory_monitor": "Monitor de inventario",
            "inventory_monitor_dec": "Sincronizar el inventario de productos de AliExpress"
        },
        "easy_started": "Completamente gratis, <br />fácil de empezar",
        "partnership": "Sociedad",
        "advabced": "Avanzado",
        "content": "<span class={title}>20</span> <span class={title}>%</span> <span class={title}>DE DESCUENTO</span>",
        "will": "Si te cambias al plan Avanzado ahora, obtendrás:<br/>",
        "support": "Soporte de <span class={title}>5</span> tiendas adicionales,<br/> límite de <span class={title}>10.000+</span> productos y <br/> nuevas estrategias de marketing",
        "today": "¡SÓLO POR HOY!",
        "btn1": "CAMBIAR AHORA",
        "btn2": "Déjame pensarlo",
        "yes": "CAMBIAR AHORA",
        "no": "Quiero mantener mi plan Estándar >>",
        "btn3": "Soporte de 5 tiendas adicionales | Límite de 10.000+ productos | Nuevas estrategias de marketing",
        "ioss": {
            "contact_us": "CONTÁCTANOS",
            "title": "Ponte en contacto con tu gestor de cuentas de suministros para obtener más información sobre IOSS",
            "sub_title": "=----- Aviso relevante sobre la reforma fiscal de la UE",
            "text": "La Unión Europea comenzará a aplicar la nueva normativa fiscal sobre el IVA a partir del 1 de julio de 2021. <br/>El negocio de (No hay sugerencias) podrá funcionar con normalidad y los clientes finales no tendrán que pagar ningún impuesto adicional. <br/>Si tienes dudas sobre el IOSS, el problema de los impuestos locales o la devolución de impuestos, no dudes en ponerte en contacto con tu gestor de cadena de suministro (<a href=\"https://www.dsers.com/blog/eu-vat-reform/\" target=\"_blank\" rel=\"noopener noreferrer\" >haz clic aquí para saber más</a>).",
            "title2": "Introducción del nuevo impuesto de la UE (IOSS)",
            "text2": "Efectos sobre el negocio de Dropshipping en los países de la UE a partir del 1 de julio de 2021"
        },
        "newUI": {
            "title": "Brand new UI, brand new experience",
            "title2": "DSers will launch a new UI in early September",
            "learn": "LEARN MORE",
            newYearTitle: "Chinese New Year Holidays Notice",
            newYearContent1: "Chinese New Year is coming. Please contact your suppliers to check their delivery delays and resting period!",
            newYearContent2: "DSers suppliers will rest from 21st Jan 2023 to 23rd Jan 2023",
        },
        "bannerFor11_11": "Discover massive promotions on 11.11",
        "bannerButton": "LEARN MORE",
        "newFeatureUp": "New feature upgrade",
        "newFeatureTitle": "[New feature]",
        "newFeatureText": "To help you better manage your store and team and assign permissions, you can use the staff account feature",
        "newSettingTitle": "[New setting]",
        "newSettingText": "The Setting page has been upgraded, it is clearer and more convenient",
        "learnMore": "Learn more",
        "gotIt": "Got it",
        "appointmenttext": "Has establecido la hora de inicio de la migración en [ {time} ], no podrás operar tu cuenta DSers cuando la migración comience",
        "appointmenttext1": "Has establecido la hora de inicio de la migración en [ {time} ], te ayudaremos a migrar en 5 minutos. No podrás operar tu cuenta de DSers durante la migración, así que puedes tomarte un descanso.",
        agency_cold_startup: {
            banner_title_1: '¡Nueva Función en Línea!',
            banner_title_2: 'Gestión de Pedidos de Agencias',
            popup_desc_1: `¿Estás cambiando entre diferentes herramientas para el procesamiento de pedidos de agencias?`,
            popup_desc_2: `Prueba esta solución alternativa de DSers para evitar cambiar entre tiendas de Shopify, cuentas de Mass Fulfill y archivos CSV.`,
            popup_desc_3: `¡Concéntrate solo en el marketing en este cuarto trimestre!`,
            learn_more_btn: 'Más información',
            banner_desc: 'Prueba esta solución alternativa de DSers para evitar cambiar entre tiendas de Shopify, cuentas de Mass Fulfill y archivos CSV.',
            order_awaiting_empty_after_title: 'Antes',
            order_awaiting_empty_before_title: 'Después',
            order_awaiting_empty_before: 'Sin esta función, necesitarías varias herramientas para los pedidos de agencias',
            order_awaiting_empty_after: 'Con esta característica, ¡sólo necesitas DSers!',
            order_awaiting_empty_link: '<a data="text-link" style="color: blue" href="https://www.dsers.com/blog/product-update-dropship-with-agencies/" target="_blank" >Haz</a> clic aquí para saber más sobre cómo procesar los pedidos de agencias',
            what_is_agency: '¿Qué es una agencia?',
            what_is_erp: '¿Qué es un ERP?',
            how_does_dsers_agency_works: 'Cómo simplifica DSers tu flujo de trabajo con las agencias de dropshipping？',
        },
        tmall_cold_startup: {
            popup_title: 'Nuevo Marketplace',
            pupup_title_2: '¡Tmall está en línea! ¡Échale un vistazo!',
            popup_desc_1: 'Miles de los productos más vendidos acaban de ser añadidos al listado.',
            popup_desc_2: '¿Por qué esperar para importarlos a tu tienda?',
            popup_btn: 'Buscar productos',
            top_banner_title: 'Nuevo Marketplace',
            top_banner_title_2: '¡Tmall está en línea! ¡Échale un vistazo!',
            find_supplier_banner_title: '¡Los más vendidos para el cuarto trimestre de 2022!',
            find_supplier_banner_subtitle: '¡Los artículos de moda y más rentables están en vivo!',
            find_supplier_banner_subtitle_2: '¡Añádelos a tu tienda!'
          }
    }
}