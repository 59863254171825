export default {
  fulfillment_center: {
    activate: 'Are you sure you want to activate the FBD product?',
    activate_dec:
      'Your default supplier will be switched to the FBD product after 48 hours.',
    input_confirm: 'Please input CONFIRM to activate.',
    deactivate: 'Are you sure you want to deactivate the FBD products?',
    deactivate_dec:
      'Your default supplier will be switched to the AliExpress supplier in your mapping page after 48 hours.',
    using_tip:
      'You are using FBD service for this product. Suppliers from the Mapping page will be used when turned off',
    close_request:
      'Your request is under review. It will be activated after 48 hours. During the 48hours. The current supplier will be used',
    closeing: 'FBD service will be used when turned on',
    activate_request:
      'Your request is under review. Supplier in mapping page will be used after 48 hours',
    go_setting: `The store needs to be re-authorized. Please go to <a
                  href="/app/settings?id=3"
                >Setting - Stores</a> to update the authorization. Otherwise you can’t operate the store properly with DSers`,
    title: 'Fulfillment Center (FBD)',
    dec:
      'We will not process products via FBD if your orders are with multiple products supplied by the same Ali supplier to avoid doubled shippig fees.',
    pruchese: 'Pruchese',
    saved: 'Saved',
    placeholder: 'Select store'
  }
};
