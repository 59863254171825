export default {
  public: {
    facebook: 'Facebook',
    youtube: 'YouTube',
    cancel: 'Annuler',
    yes: 'Oui',
    no: 'Non',
    ok: 'Ok',
    save: 'Sauvegarder',
    newsave:'SAUVEGARDER',
    submit: 'Soumettre',
    search: 'Chercher',
    confirm: 'Confirmer',
    newconfirm: 'CONFIRM',
    apply: 'Appliquer',
    log_out: 'Déconnexion',
    reset_password: 'Changer mot de passe',
    delete: 'Supprimer',
    got_it: 'Compris',
    check_more: 'En savoir plus',
    more: 'Plus',
    page: 'page',
    access: 'Accéder',
    refresh: 'Rafraichir',
    reconnect: 'Reconnecter',
    on: 'On',
    off: 'Off',
    here: 'ici',
    Manage: 'Gérer souscription',
    check_it: 'Vérifier',
    dismiss: 'Ignorer',
    contact: 'Contact',
    contact_us: 'Nous contacter',
    updata_price:'Mettre à jour le prix des produits envoyés sur votre boutique Shopify en fonction des règles de prix définies actuellement. Cela peut prendre un peu de temps. Vous pouvez faire cela tous les 30 jours.',
    please_enter:'Entrez CONFIRMER pour commencer la mise à jour',
    shopUnavailable:'La boutiaue est déconnecté, veuillez la reconnecter pour utiliser cette fonction',
    InterfaceTransfinite:"Mettre à jour le prix des produits envoyés sur votre boutique Shopify en fonction des règles de prix définies actuellement. Cela peut prendre un peu de temps. Vous pouvez faire cela tous les 30 jours. En raison de restrictions de l'interface, la fonction sera disponible après minuit, merci de patienter:",
    synchronous:`Les prix des produits de la boutique sont en cours de mise à jour`,
    useAgain:'Peut être utilisé de nouveau dans',
    day:'jours',
    jumpPrice:`Cliquez 
    <a
    href="/app/pricing"
    target="_blank"
    rel="noopener noreferrer"
  >
    ici  
  </a>
  pour changer de plan et pouvoir synchroniser manuellement plus de fois`,
    pause:'Synchronisation du jour terminée. Les produits restants seront synchronisés automatiquement demain.',
    goodsTooMuch:`Mettre à jour le prix des produits envoyés sur votre boutique Shopify en fonction des règles de prix définies actuellement. Cela peut prendre un peu de temps. Vous pouvez faire cela tous les 30 jours. Comme votre boutique contient plus de 100 000 produits, la synchronisation sera réalisée en plusieurs jours (jusqu'à 100 000 par jour), merci de votre patience.` ,
    exceeds:'Comme votre marchandise est synchronisée avec plus de 100 000, cette fonction sera activée pour une utilisation après 24:00. Merci de votre patience',
    noproduct:`Cette boutique n'a pas de produit qui peuvent être mis à jour`,
    updateTitle:'Mettre à jour le prix du produit',
    updateText:'Mettre à jour le prix des produits envoyés sur votre boutique Shopify en fonction des règles de prix définies actuellement. Cela peut prendre un peu de temps. Vous pouvez faire cela tous les 30 jours.',
    start:'Démarrer la mise a jour',
    unsave:'Modifications non enregistrées',
    checkmodaltext:`Les informations que vous avez modifiées n'ont pas encore été sauvegardées. Si vous souhaitez sauvegarder, veuillez cliquer sur "SAUVEGARDER", si vous ne souhaitez pas enregistrer vos modifications, cliquez sur "QUITTER"`,
    discard:'QUITTER',
    choose:"SÉLECTIONNER",
    gotodsers:`Aller à la page d'accueil DSers`,
    oberlo_data_pending:'Correction de Données.',
    oberlo_data_success:'Correction de données terminée.',
    oberlo_data_fail:'La vérification des données a échoué.',
    oberlo_data_try:'Réessayer',
    priceruleDis:'Veuillez activer et confirmer vos paramètres de <a href="/app/settings?=5" > Modèles de Prix </a> dans Règle de Prix et commencer la mise à jour par la suite.',
    padding_title:'Nous corrigeons les informations sur les commandes et les produits, veuillez patienter',
    migrate_order_tip:`La synchronisation du statut d'une commande a échoué. Il est possible que le compte AliExpress sur lequel ces commandes ont été passées soit différent du compte AliExpress actuellement lié. Veuillez modifier le compte AliExpress lié à DSers pour mettre à jour le statut des commandes. Si vous avez des questions, veuillez contacter le service client de DSers.`,
    oberlo_product_error_tip:`La vérification des données des produits est terminée, mais { num } produits AliExpress ne sont pas disponibles et ne peuvent pas être importés vers DSers. Vous devrez mapper manuellement les nouveaux fournisseurs.Accédez à l'onglet Produits non-mappés pour vérifier`,
    add_report_user_tip:"Données en cours de création, le rapport sera disponible dans les prochaines 24h. Merci pour votre patience. ",
    filter_confirm_des:'CONFIRMER',
    filter_clear_des:'EFFACER LES FILTRES',
    unsaveErr:'Unable to save changes',
    gocheck:'Go check',
    checkmodalerrtext1:'The reason you cannot save is ',
    checkmodalerrtext2:'To fix the issue, you need to re-map the part facing issues, or delete the mapping with problems.',
    checkmodaladvanceerrtext:'In Advanced Mapping, the Ship to selection cannot be repeated on the same variant, otherwise the mapping cannot be saved. ',
    checkmodalbasicrepeaterrtext:'In Basic Mapping, Options cannot be repeated, otherwise the mapping cannot be saved. ',
    checkmodalbasicnullerrtext:'In Basic mapping, you need to select the corresponding variant, otherwise the mapping cannot be saved. ',
    input_guide_text:`Le champ de Recherche ou d'Import de produit, ainsi que la fonction de Filtre ont été déplacé! Vos pouvez y accéder dans le coin supérieur droit.`,
    input_guide_btn:'J’ai compris',
    importlist_card_guide_text:`Nous avons amélioré la carte produit et certaines fonctions se trouvent désormais dans "Plus d'action" dans le coin inférieur droit`,
    product_card_guide_text:`Nous avons amélioré la carte produit et la fonction de mapping dispose désormais d'un affichage flottant. Certaines fonctions se trouvent désormais dans "Plus d'action" dans le coin inférieur droit`,
    search_input_url_error_match_ae_tip:
      `L'URL saisie n'est pas une URL AliExpress. Veuillez la vérifier et recommencer.`,
    search_input_url_error_match_tm_tip:
      `L'URL saisie n'est pas une URL Tmall. Veuillez la vérifier et recommencer.`,
    search_input_url_invalid_tip:`L'URL saisie n'est pas valide. Veuillez vérifier et réessayer.`,
  }
};
