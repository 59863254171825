
    export default 
    {
    "csv": {
        "csv_bulk_order": "Pedido masivo CSV",
        "re_download_template": "Para mantener la estabilidad de la plataforma, los usuarios CSV pueden realizar hasta 2.000 pedidos diarios, los pedidos fallarán cuando se alcance el límite.",
        "products": "Productos",
        "import": "Importar",
        "download_template": "Descargar plantilla",
        "product_tip": "Gestiona tu base de datos de productos para realizar pedidos masivos.",
        "orders": "Pedidos",
        "order_tip": "Obtén los números de pedido de AliExpress y el número de seguimiento (los números de seguimiento suelen tardar días en generarse) descargando el archivo en la columna Pedidos exitosos. Consulta los pedidos fallidos descargando el archivo en la columna Pedidos fallidos y edita la información necesaria.",
        "upload_title_orders": "Importar pedidos",
        "upload_title_product": "Importar la base de datos de productos",
        "upload_btn": "Importar",
        "need_help": "¿Necesitas ayuda?",
        "choose_csv_file": "Seleccionar archivo CSV",
        "no_file_chosen": "No se ha seleccionado ningún archivo",
        "max_8mb": "Tu archivo CSV debe tener menos de 8MB",
        "upload_download_tip": "para ver un ejemplo del formato requerido",
        "upload_succ": "Carga exitosa.",
        "upload_fail": "Carga fallida",
        "upload_fail_tip": "El formato del archivo subido es incorrecto. Por favor, verifica el archivo CSV.",
        "click_here_to": "Haz clic aquí para",
        "download": "descargar",
        "download_error": "el mensaje de error en Excel.",
        "order_table": {
            "date": "Fecha",
            "file_name": "Nombre del archivo",
            "successful_orders": "Pedidos exitosos",
            "failed_orders": "Pedidos fallidos",
            "action": "Acción"
        },
        "pending": "Pendiente",
        "place_order": "Realizar pedido",
        "check_product": "Comprobar el SKU del producto de AliExpress",
        "check": "Comprobar",
        "price": "Precio",
        "token_error": "Por favor, actualiza la página para descargar el archivo de nuevo."
    }
}