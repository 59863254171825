import { Star } from 'components/SvgIcons';
import useTopBanner from 'hooks/useTopBanner';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { DSERS_IMG_HOST } from 'utils/constants';
import { CommonDataCollect } from 'utils/utils';
import TmallColdTimer from './TmallColdTimer';

import Y_LeftLine from '../../assets/coldStart/yellow/banner/left.png'
import Y_RightLine from '../../assets/coldStart/yellow/banner/right.png'
import Y_Btn from '../../assets/coldStart/yellow/banner/btn.png'
import Y_Circle from '../../assets/coldStart/yellow/banner/circle.png'


import B_LeftLine from '../../assets/coldStart/black/banner/left.png'
import B_RightLine from '../../assets/coldStart/black/banner/right.png'
import B_Btn from '../../assets/coldStart/black/banner/btn.png'
import B_Circle from '../../assets/coldStart/black/banner/circle.png'

import C_Up from '../../assets/coldStart/common/banner/up.png'
import C_Date from '../../assets/coldStart/common/banner/date.png'
import C_Pic from '../../assets/coldStart/common/banner/pic.png'
import C_Star1 from '../../assets/coldStart/common/blackStar1.svg'
import C_Star2 from '../../assets/coldStart/common/yellowStar1.svg'
import C_Dot from '../../assets/coldStart/common/dot.png'
import styles from './TmallColdStartBanner.less';

const allowPath = [
  '/find_suppliers'
];


const TmallColdStartBanner = ({ path, lang, userId }) => {
  const [visible] = useTopBanner(path, allowPath);
  const [type, setType] = useState(window.TMALL_USER_BANNER1 ? "one" : "two");
  if (!visible) {
    return null;
  }
  if (!(window.TMALL_USER_BANNER1 || window.TMALL_USER_BANNER2)) {
    return null
  }
  const handleClickBtn = () => {
    window.dtag.set({
      event: 'tmall_cold_start',
      module: 'tmall_cold',
      action: type == "one" ? 'A_banner_CTA' : 'B_banner_CTA',
      custom_data: {}
    })
    window.open('https://www.dsers.com/blog/tmall-dropshipping/')
    }
  return (
    <div
      className={styles.bannerLink}
    >
     
      <div className={type == "one" ? styles.Ycontainer : styles.Bcontainer}>
        <TmallColdTimer type={type} location={"banner"} />
        <img className={styles.LeftLine} src={type == "one" ? Y_LeftLine : B_LeftLine} />
        <img className={styles.rightLine} src={ type == "one" ? Y_RightLine : B_RightLine}  />
        <img className={styles.date} src={C_Date}  />
        <img className={styles.up} src={C_Up} />
        <img className={styles.dot} src={C_Dot}  />
        <img className={styles.btn}
           onClick={ handleClickBtn}
          src={type == "one" ? Y_Btn : B_Btn} />
        <img className={styles.circle} src={type == "one" ? Y_Circle : B_Circle} />
        <img className={styles.pic} src={C_Pic} />
        <img className={styles.star} src={type == "one" ? C_Star1 : C_Star2} /> 
        <img className={styles.star2} src={C_Star2} />
      </div>
    </div>
  );
};

TmallColdStartBanner.allowPath = allowPath;

export default TmallColdStartBanner;
