import React, { useEffect, useState } from 'react';

const getDuration = day => 24 * 60 * 60 * 1000 * day;

/**
 * 用于功能线上通知
 *
 * @param {*} key 唯一 key，作为 localStorage.setItem 的第一个参数
 * @param {*} onlineDate 上线日期
 * @param {*} activeDays 展示的天数
 * @returns
 */
function useOnlineNotice(key, onlineDate, activeDays = 7) {
  const [visible, setVisible] = useState(false);

  const name = `online_notice_${key}`;

  if (!key) {
    throw new Error('key is required!');
  }

  if (isNaN(activeDays)) {
    throw new Error('activeDays must be number!');
  }

  if (Object.prototype.toString.call(onlineDate) !== '[object Date]') {
    throw new Error('onlineDate is invalid!');
  }

  useEffect(() => {
    const tmp = window.localStorage.getItem(name);
    const now = Date.now();
    const duration = getDuration(activeDays);

    if (window.DS_BEGINNER_GUIDE_ACTIVE) {
      return;
    }

    // 指定天数后不再提示弹窗
    if (now - onlineDate > duration || now < onlineDate) {
      if (tmp) {
        window.localStorage.removeItem(name);
      }
      return;
    }

    if (!tmp) {
      setVisible(true);
    }
    // eslint-disable-next-line
  }, []);

  function close() {
    setVisible(false);

    window.localStorage.setItem(name, Date.now());
  }

  return [visible, close];
}

export default useOnlineNotice;
