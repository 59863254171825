export const AGENCY_EVENT_CHECK_EXPORT_ORDER_TASK = 'AGENCY_EVENT_CHECK_EXPORT_ORDER_TASK';

export const AGENCY_LOCAL_STORE_EXPORT_TASK_KEY = 'AGENCY_EXPORT_TASK';

export const AGENCY_EVENT_CHECK_UPLOAD_ORDER_TASK = 'AGENCY_EVENT_CHECK_UPLOAD_ORDER_TASK';

export const AGENCY_LOCAL_STORE_UPLOAD_TASK_KEY = 'AGENCY_UPLOAD_TASK';

export const AGENCY_EVENT_ROLLING_PAYMENT_STATUS = 'AGENCY_EVENT_ROLLING_PAYMENT_STATUS'

export const AGENCY_LOCAL_STORE_PAYMENT_ROLLING_TASK = 'AGENCY_PAYMENT_ROLLING';