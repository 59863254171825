import React, { useState } from 'react'
import intl from 'react-intl-universal';
import { connect } from 'dva';
import { withRouter } from 'dva/router';

import CountDownTime from '../../common/countDownTime'
import FixedModal from '../../components/FixedAdvanced/FixedModal'

import styles from  './FixedAdvanced.less'

const FixedAdvanced = ({sendEndTime, dispatch, showTime}) => {

  const [visible,setVisible] = useState(false)

  const hideModal = () => {
    setVisible(false)
  }

  const dispatchChangeNew = () => {
    dispatch({
      type: 'guidance/modificationProject',
      payload: {
        data: {
          plan_type:7,
          plan_period: 1,
        },
        callback: d => {
          if (d.code == 2000) {
            if (d.data.confirm_url) {
              window.open(d.data.confirm_url);
              return;
            }

            self.location.href = '/app/'; // 首页
          } else {
            // captureMessage(`[pricing] ${d?.msg || 'guidance/setProject'}`);
          }
        }
      }
    });
  }

  return <>
    <div className={styles.fixedAdvanced} id="onboarding_open_modal" onClick={()=>{
            setVisible(true)
        }}>
      <p className={styles.diff}>
        {intl.getHTML('banner.content', {
          title: styles.titled,
        })}
      </p>
      <div className={styles.time}><CountDownTime sendEndTime={sendEndTime} /></div>
      <div className={styles.btn_box}>
        {intl.getHTML('banner.btn1')}
      </div>
    </div>
    <FixedModal
        type={2}
        visible={visible}
        hideModal={hideModal}
        changeHandle={dispatchChangeNew}
        cancelHandele={hideModal}
        showTime={showTime}
      />
  </>
}

const mapStateToProps = ({ login, global }) => ({
  loginInfo: login.loginInfo,
  showTime:global.showTime
});

export default withRouter(connect(mapStateToProps, (dispatch) => ({dispatch}))(FixedAdvanced));
