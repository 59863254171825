
    export default 
    {
    "paypal": {
        "auto_sync_track_to_paypal": "Automatische Synchronisierung der Tracking-Nummern mit PayPal! Gehen Sie zu Einstellung-Tracking, um dies zu überprüfen.",
        "paypal_authorization_title": "PayPal-Autorisierung",
        "paypal_authorization_content": "Das System hat festgestellt, dass Ihr autorisiertes PayPal-Konto nicht mit dem an Shopify<br /> gebundenen Konto übereinstimmt, was dazu führt, dass die<br /> Tracking-Nummer Ihrer Bestellung nicht mit PayPal synchronisiert werden kann.",
        "paypal_change": "Zum Ändern gehen",
        "disimiss": "Abmelden",
        "paypal_account": "Über das PayPal-Konto",
        "paypal_reminder_content": "Das System hat festgestellt, dass die Autorisierung Ihres zugehörigen PayPal-Kontos abgelaufen ist. Bitte autorisieren Sie Ihr PayPal-Konto erneut, um eine bessere Nutzung zu ermöglichen.",
        "no_reminder": "Erinnere mich nicht",
        "renew": "Zur erneuten Autorisierung gehen",
        "cancel": "Abbrechen"
    }
}