const getPaymentData = () => {
  let data = null;

  try {
    const str = localStorage.getItem('payment');
    if (str) {
      data = JSON.parse(str);
    }
  } catch (error) {
    console.log('getPaymentData error', error);
  }

  return data;
};

export default getPaymentData;
