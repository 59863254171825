import intl from 'react-intl-universal';

/**
 * 通过基础数据得到错误提示数据
 * @param {*} data 基础数据源
 * @param {*} tab  所属的tab
 * @returns        错误提示数据
 */
export const handleErrorTips = (value,status) => {
    var data = handleValue(value);
    const errorMessage = {
        'On_Hold_Error': intl.get('order.place_via_extension.on_hold_error'),
        'Request_Fulfill_Error':intl.get('order.place_via_extension.request_fulfill_error'),
        'Shipping_Error':intl.get('order.place_via_extension.shipping_error'),
        'Shipping_Set_Error':intl.get('order.place_via_extension.shipping_error_set'),
        'Mapping_Error': intl.get('order.place_via_extension.mapping_error'),
        'On_Hold_Error_Item':intl.get('order.place_via_extension.on_hold_error_item'),
        'Request_Fulfill_Error_Item':intl.get('order.place_via_extension.request_fulfill_error_item'),
        'Shipping_Error_Item':intl.get('order.place_via_extension.shipping_error'),
        'Mapping_Error_Item':intl.get('order.place_via_extension.mapping_error'),
    }
    var errorList = {};
    data.forEach((item, index) => {
        errorList[item.id] = {}
        errorList[item.id]['error'] = [];
        // 【两种情况 按钮不可点击  订单中 每个item 都为 Request fulfillment 状态 或者 存在on hold状态】
        errorList[item.id]['isOrder'] = true;

        // item 都为 OnHold 状态处理逻辑
        const AllOnHold = (errorList) => {
            if (item?.childrens.every(itemChild => (itemChild.isPendingFulfillments))) {
                errorList[item.id]['isOrder'] = false
                errorList[item.id]['error'].push(errorMessage.Request_Fulfill_Error)
            } else if (item?.childrens.every(itemChild => (!itemChild.isPendingFulfillments ))) {
                errorList[item.id]['isOrder'] = false
                errorList[item.id]['error'].push(errorMessage.On_Hold_Error)
                } else {
                errorList = handleErrorTipsItem(item,item?.detail.line_items,'hold',errorList,errorMessage,status)
            }
            return errorList
        }

        //  item都存在mapping处理逻辑
        const AllOnMapping = (errorList) => {
            if (item.childrens.every((itemChild => judgeChildShippingItem(itemChild,status) == 'ERROR_SHIPPING'))) {
                errorList[item.id]['isOrder'] = false;
                errorList[item.id]['error'].push(errorMessage.Shipping_Error)
                 }
            else if(item.childrens.every((itemChild => judgeChildShippingItem(itemChild,status) == 'ERROR_SET_SHIPPING'))) {
                errorList[item.id]['isOrder'] = false;
                errorList[item.id]['error'].push(errorMessage.Shipping_Set_Error)
                 }
            else {
                errorList = handleErrorTipsItem(item, item.childrens,'mapping',errorList,errorMessage,status)
            }
            return errorList
        }
        
        // request fulfillment 与 Onhold  判断 
        if (item?.detail.line_items.every(itemChild => !itemChild?.fulfillable_quantity)) {
            errorList = AllOnHold(errorList)
        } else {
            errorList = handleErrorTipsItem(item,item?.detail.line_items,'hold',errorList,errorMessage,status)
        }
        
        // mapping 与 shipping 判断 
          if (item.childrens.every(itemChild => judgeChildMappingItem(itemChild))) {
            errorList = AllOnMapping(errorList)
           }
        else if (item.childrens.every((itemChild) => !judgeChildMappingItem(itemChild))) {
              errorList[item.id]['error'].push(errorMessage.Mapping_Error)
           }  
        else{
             errorList = handleErrorTipsItem(item, item.childrens,'mapping',errorList,errorMessage,status)
         }         
       
    })
   return errorList
}

/**
 * item为维度进行数据处理
 * @param {*} item        order中的子项
 * @param {*} travelArr   需要遍历处理的数据
 * @param {*} type       【hold】 => on hold / request fulfillment   【mapping】 => mapping / shipping
 * @param {*} errorList   原始错误数据集 
 * @param {*} errorMessage
 * @returns              处理过的数据集
 */
const handleErrorTipsItem = (item, travelArr, type, errorList, errorMessage,status) => {
    if(!errorList[item.id]['childrens']){
        errorList[item.id]['childrens'] = {}
    }
    travelArr.forEach((itemChild, itemIndex) => {
        if(!errorList[item.id]['childrens'][itemChild.id]){
                errorList[item.id]['childrens'][itemChild.id] = []
            }
        if (type == 'hold') {
            if (!itemChild.fulfillable_quantity) {
                let FulfilErrorSign = false;
                item.childrens.forEach((minItem) => {
                        if(minItem.id == itemChild.id && minItem.isPendingFulfillments == true){
                            errorList[item.id]['childrens'][itemChild.id].push(errorMessage.Request_Fulfill_Error_Item)
                            FulfilErrorSign = true
                        }
                    })
                    if (!FulfilErrorSign) {
                        // errorList[item.id]['isOrder'] = false
                       errorList[item.id]['childrens'][itemChild.id].push(errorMessage.On_Hold_Error_Item)
                    }   
                }
            }
        if (type == 'mapping') {
            if (!judgeChildMappingItem(itemChild)) {
                     noMappingAndnoShipping(itemChild,errorList,item,status)
                    // errorList[item.id]['childrens'][itemChild.id].push(errorMessage.Mapping_Error_Item)
                } else {
                if (judgeChildShippingItem(itemChild,status) == 'ERROR_SHIPPING') {
                        errorList[item.id]['isOrder'] = false;
                        errorList[item.id]['childrens'][itemChild.id].push(errorMessage.Shipping_Error_Item)
                     }
                if (judgeChildShippingItem(itemChild,status) == 'ERROR_SET_SHIPPING') {
                        errorList[item.id]['isOrder'] = false;
                        errorList[item.id]['childrens'][itemChild.id].push(errorMessage.Shipping_Set_Error)
                     }                   
                 }
            }
           
    })
        return errorList
}
/**
 * 获取所选择物流
 * @param {*} useSp 原始数据
 * @returns   物流信息
 */
const getCompayError = (useSp,status) => {
     if(status !== useSp?.status){
            return false
    }
    const freights = useSp.freights || [];
    let obj = 'UNKOWN_ERROR';
    const v =  useSp.logistics_company;  
    if (!v && useSp?.already_set_freights) {
      return "ERROR_SET_SHIPPING";
    }
    if (!v) {
        return 'ERROR_SHIPPING'
      }
    freights.forEach(item => {
      if (item.serviceName == v) {
        obj = false;
      }
    });
    return obj;
  }
  

/**
 * 判断是否存在mapping （包含高級mapping）
 */
const judgeChildMappingItem = (data) => {
    let MappingSign = true
    if (!data.useSp?.platform_product_id && Array.isArray(data.sp)) {
        if (data.sp.some((item => !item.platform_product_id))) {
            MappingSign =  false
        }
    }
    else {
        MappingSign = data.useSp.platform_product_id  ? true :false
    } 
    return MappingSign
}

/**
 * 判断是否存在shpping （包含高級mapping）
 * @param {*} data 
 * @returns 
 */
const judgeChildShippingItem = (data,status) => {
    let ShippingSign = false
    if ( Array.isArray(data.sp)  &&  (data.supplierMapType != 1 || !data.useSp.logistics_company) ) {
        if (data.sp.some((item => (getCompayError(item,status) == 'ERROR_SET_SHIPPING')))) {
            ShippingSign = "ERROR_SET_SHIPPING"
        }
        if (data.sp.some((item => getCompayError(item,status) == 'ERROR_SHIPPING'))) {
            ShippingSign = "ERROR_SHIPPING"
        }
    }
    else {
         if(getCompayError(data.useSp,status) == 'ERROR_SET_SHIPPING'){
            ShippingSign = "ERROR_SET_SHIPPING"
        }else if(getCompayError(data.useSp,status) == 'ERROR_SHIPPING'){
            ShippingSign = "ERROR_SHIPPING"
        }
    }
    return ShippingSign
}

/**
 * 排除一些没有显示的children
 * @returns 获取已经确认已经显示的数据id
 */
function  getAlreadyId() {
    let getClassSign = document.querySelectorAll('div[data-label="order-item-box"]') || [];
    let returnAlreadyId = Array.from(getClassSign).map((item, index) => {
        if (!item.children?.length) {
            return ''
        }
        return item.getAttribute('data-key')
    })
    return returnAlreadyId
}

/**
 * 
 * @param {*} value 元数据 
 * @returns   在元数据的基础上面去掉不显示children数据
 */
export const handleValue = (value) => {
    let alreadyId = getAlreadyId()
    let data = JSON.parse(JSON.stringify(value))
    data.forEach((item, index) => {
        data[index]['childrens'] = item['childrens'].filter(itemChild => alreadyId.includes(String(itemChild.id)))
        data[index]['detail']['line_items'] = item['detail']['line_items'].filter(itemChild=>alreadyId.includes(String(itemChild.id)))
    })
    return data
}

/**
 * other单 不存在物流则无法点击
 */
export const noMappingAndnoShipping = (itemChild, errorList, item, status) => {
  
    itemChild?.sp.forEach((childItem => {
        if (getCompayError(childItem, status) == "ERROR_SHIPPING") {
            errorList[item.id]['isOrder'] = false;
            }    
    }))
}