import React from 'react';
import { Modal, Button } from 'antd';

import styles from './WhitelistProcessModal.less';

const WhitelistProcessModal = ({ visible, type, onCancel }) => {
  if (!type) {
    return null;
  }

  return (
    <>
      <Modal
        visible={visible}
        footer={null}
        width={560}
        maskClosable={false}
        keyboard={false}
        onCancel={onCancel}
        bodyStyle={{ padding: '36px 24px' }}
      >
        {
          type === 'none' ? (
            <>
              <p className={styles.title}>Please refresh the page to update the status. If you failed to whitelist your account, please check if you are logged in to the correct AliExpress account. Being logged in to the wrong AliExpress account will cause the failure. Please switch account and try again.</p>
              <div className={styles.footer}>
                <Button type="primary" onClick={() => window.location.reload()} >Refresh</Button>
              </div>
            </>
          ) : null
        }
        {
          type === 'notMatch' ? (
            <>
              <p className={styles.title}>We detected that you are not logged in to the AliExpress account you linked to DSers. This will cause the failure of whitelisting. Please go to AliExpress to log in to the correct account.</p>
              <div className={styles.footer}>
                <a href="https://login.aliexpress.com/" target="_blank" rel="noopener noreferrer" >
                  <Button onClick={onCancel} type="primary" >Go to AliExpress</Button>
                </a>
              </div>
            </>
          ) : null
        }
        {
          type === 'notLogin' ? (
            <>
              <p className={styles.title}>We detected that you are not logged in to any AliExpress account. This will cause the failure of whitelisting. Please go to AliExpress to log in to the correct account.</p>
              <div className={styles.footer}>
                <a href="https://login.aliexpress.com/" target="_blank" rel="noopener noreferrer" >
                  <Button  onClick={onCancel} type="primary" >Go to AliExpress</Button>
                </a>
              </div>
            </>
          ) : null
        }
      </Modal>
    </>
  )
}

export default WhitelistProcessModal;
