import React from 'react';
import intl from 'react-intl-universal';

import styles from './OptimizerInfoTip.less';
import useTopBanner from '../../hooks/useTopBanner';

const allowPath = ['/supplier_optimizer'];

const tipTextFr = 'Nous ajustons actuellement la fonction Optimiseur de fournisseur et certaines peuvent être incorrectes. Merci de votre compréhension et patience.'

export const InfoAlert = ({ text, className }) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <i class="material-icons">info_outline</i>
      {text}
    </div>
  );
};

const OptimizerInfoTip = ({ path, lang }) => {
  const [visible] = useTopBanner(path, allowPath);

  if (!visible) {
    return null;
  }

  return (
    <div>
      {/* <InfoAlert text={intl.get('supplier_optimizer.msg')}/> */}
    </div>
  );
};

export default OptimizerInfoTip;
