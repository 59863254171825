export default {
  login: {
    title: 'Log in to your DSers',
    email: 'Email',
    password: 'Password',
    cap_placeholder: 'Captcha',
    log_in: 'LOG IN',
    forgot_password: 'FORGOT PASSWORD?',
    enter_username: 'Please enter username!', //  a
    enter_password: 'Please enter password!',
    email_password_error: 'Email or password error', // a
    empty: 'The password cannot be empty',
    text_title: `5.0 <br/> <span class={titlek}>Star Rating with</span>`,
    text_desc: `10000+ <br/> <span class={titlek}>Reviews on Shopify App <br/> Store</span>`,
    text_title_2:`Oberlo replacement <br/> <span class={titlek}>officially recommended by Shopify </span>`,
    text_desc_2:`10000+ <br/> <span class={titlek}>5 Stars Reviews <br/> on Shopify app store</span>`,
    noHaveText:`Don't have an account?`,
    createAccount:'CREATE ACCOUNT',
    emailrequired:"email is required",
    error:'Email or password error.	'
  }
};
