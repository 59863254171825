import React, { useEffect, useMemo, useState } from 'react';
import DetailHeader from './components/DetailHeader';
import styles from './tmallDetail.less';
import { Button, Spin } from 'antd';
import pic from 'assets/IMG_1069.png';
import ShippingModal from './components/ShippingModal';
import intl from 'react-intl-universal';
import NoProduct from './components/NoProduct';
import { Get, Post } from 'utils/request';
import qs from 'qs';
import tmallLogo from '../../assets/supply/tmallLogo.svg';
import cloneDeep from 'lodash/cloneDeep';
import { dsersBFFApiHost } from 'common/sysconfig';
import { GuildeAction } from 'actions'
import OblerloGuide from '../../components/OblerloGuide'
import TmallStartImport from '../../components/OnlineNotice/TmallStartImport'
import { CommonDataCollect } from 'utils/utils';
import { useOnceCallback } from 'hooks/useOnceCallback';
import { useCountViewDeep } from 'hooks/useScrollEvent';


function TmallDetail() {

    const [mainImg, setMainImg] = useState();
    const [modalVisible, setModalVisible] = useState(false)
    const [copySuccess, setCopySuccess] = useState('')
    const [detailData, setDetailData] = useState({})
    const [selectOption, setselectOption] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [selectValue, setSelectValue] = useState('')
    const [buttonLoading, setButtonLoading] = useState(false)
    const [importSuccess, setImportSuccess] = useState('')
    const [logistics, setLogistics] = useState()
    const [nowVariant, setNowVariant] = useState('')
    const [modalCountry, setModalCountry] = useState('')
    const [spinText, setSpinText] = useState('')
    const reportFirstLoad = useOnceCallback();

    const selectCountry = JSON.parse(localStorage.getItem('defaultSelect')) || { Code: "US", Name: "United States" }

    const ref = React.createRef()

    

    if (!window.TMALL_USER) {
        window.location.href = '/app/'
    }
    
    const id = location.href.substring(location.href.lastIndexOf('/') + 1)
    const productId = id.split('?')[0]
    const appId = qs.parse(id.split('?')[1]).appId
    const searchValue = qs.parse(id.split('?')[1]).search
    const initCountViewDeep = useCountViewDeep('html', (data) => {
        CommonDataCollect({
            action: 'tmall_order_cold_startup_tmall_detail_view_process',
            event_type: 'tmall_order_cold_startup',
            supply_platform_product_id: productId,
            view_percent: parseFloat(data * 100).toFixed(2)
        });
    });
    
    useEffect(() => {
        let product_id  = window.location.href.match(/.*\/app\/tmallproduct\/(\d+)?/)?.[1] || ''; 
        window.dtag.set({
            event:'tmall_product_detail',
            module:'tmall',
            action:'page_show',
            custom_data:{from:window.location.href.includes('referrer=email')? 'email':'other','product_id':product_id}
          })
        const fetchData = async () => {
            setIsLoading(true)
            const res = await Get(`${dsersBFFApiHost}/dsers-product-bff/product-pool/product/detail`, {
                data: {
                    productId: productId,
                    shipTo: selectCountry.Code,
                    appId
                },
                isCustomApi: true
            })
            if (!res.data || JSON.stringify(res.data) === '{}') {
                setSpinText('There seem to be an internet connection issue, please try again later')
                return
            }
            if (res.data) {
                setDetailData(res.data)
                if(res?.data?.status !== 'NotAvailable') {
                    reportFirstLoad(() => {
                        CommonDataCollect({
                            action: 'tmall_order_cold_startup_tmall_detail_load_success',
                            event_type: 'tmall_order_cold_startup'
                        });
                    });
                }

                setTimeout(() => {
                    initCountViewDeep();
                }, 2500);
                
                setNowVariant(res.data.variants[0])
                setselectOption(res.data.variants[0]?.options)
                setMainImg(res.data?.mainImgUrl)

            }
            setIsLoading(false)
        }
        fetchData()
    }, [])
    
    useEffect(() => {
        const fetchLogistics = async () => {
            if (!nowVariant) {
                return
            }
            const res = await Get(`${dsersBFFApiHost}/dsers-product-bff/product-pool/product/logistics`, {
                data: {
                    appId,
                    productId,
                    country: modalCountry ? modalCountry : selectCountry.Code,
                    skuId: encodeURIComponent(nowVariant.variantId),
                },
                isCustomApi: true
            })
            if (res?.data?.length) {
                res.data.forEach((item, index) => {
                    if (!index) {
                        item.checked = true
                    } else {
                        item.checked = false
                    }
                })
            }
            setLogistics(res)
        }
        fetchLogistics()
    }, [nowVariant, modalCountry])


    useEffect(() => {
        window.addEventListener('scroll', (e) => {
            let main = document.querySelector('#main')
            let mainleft = document.querySelector('#mainleft')
            let mainleftBox = document.querySelector('#mainleftBox')
            let relativeTop = 207 + (main.getBoundingClientRect().height - mainleft.getBoundingClientRect().height - 34)
            if (document.documentElement.scrollTop >= 207 && document.documentElement.scrollTop < relativeTop) {
                mainleft.style.position = 'fixed';
                mainleft.style.top = 0;
            }
            else if (document.documentElement.scrollTop >= relativeTop) {
                mainleft.style = null;
                mainleftBox.style.position = 'relative';
                mainleftBox.style.top = `${main.getBoundingClientRect().height - mainleft.getBoundingClientRect().height - 34}px`;
            } else if (document.documentElement.scrollTop < 207) {
                mainleft.style = null;
                mainleftBox.style = null;
            }
        })

        return () => {
            window.removeEventListener('scroll', (e) => {
                let main = document.querySelector('#main')
                let mainleft = document.querySelector('#mainleft')
                let mainleftBox = document.querySelector('#mainleftBox')
                let relativeTop = 207 + (main.getBoundingClientRect().height - mainleft.getBoundingClientRect().height - 24)
                if (document.documentElement.scrollTop >= 207 && document.documentElement.scrollTop < relativeTop) {
                    mainleft.style.position = 'fixed';
                    mainleft.style.top = 0;
                }
                else if (document.documentElement.scrollTop >= relativeTop) {
                    mainleft.style = null;
                    mainleftBox.style.position = 'relative';
                    mainleftBox.style.top = `${main.getBoundingClientRect().height - mainleft.getBoundingClientRect().height - 24}px`;
                } else if (document.documentElement.scrollTop < 207) {
                    mainleft.style = null;
                    mainleftBox.style = null;
                }
            })
        }
    }, [])

    useEffect(() => {
        CommonDataCollect({
            event_type: 'tmall_product_detail',
            action: 'onload_page'
        })

        const takeTmallBannerDot = async () => {
            await Get(`/tmall/banner/${productId}`).then(res=>{
                if(res?.data?.code){
                  CommonDataCollect({
                    event_type:'tmall_banner_group',
                    action:'tmall_productdetail_load',
                    test_group:res.data.code,
                    custom_data:[
                      {
                        name:'tmall_url',
                        value:`https://www.dsers.com/app/tmallproduct/${productId}`
                      },
                      {
                        name:'product_test_group',
                        value:res.data.code
                      },
                      {
                        name:'tmall_product_id',
                        value:productId
                      }
                    ]
                  })
                }
              })
        }

        takeTmallBannerDot()
    }, [])

    const handleChangeMain = (index) => {
        return () => {
            setMainImg(detailData.medias[index])
        }
    }

    const handleMove = (e) => {
        let imgdiv = document.querySelector('#mainImg')
        let img = document.getElementById('img')

        if (e.clientX - imgdiv.getBoundingClientRect().left >= 0 && e.clientY - imgdiv.getBoundingClientRect().top >= 0) {
            img.style.width = '900px'
            img.style.height = '900px'
            img.style.transform = `translate(-${e.clientX - imgdiv.getBoundingClientRect().left}px,-${e.clientY - imgdiv.getBoundingClientRect().top}px)`
        }

    }

    const handleLeave = () => {
        let img = document.getElementById('img')
        img.style.width = '450px'
        img.style.height = '450px'
        img.style.transform = `translate(0px,0px)`
    }

    const handleOpenModal = () => {
        setModalVisible(true)
        CommonDataCollect({
            event_type: 'tmall_product_detail',
            action: 'open_logicstic_modal'
        })
    }

    const handleCloseModal = () => {
        setModalVisible(false)
    }

    const handleCopy = () => {
        CommonDataCollect({
            event_type: 'tmall_product_detail',
            action: 'copy_url'
        })
        if (ref.current) {
            clearTimeout(ref.current)
        }

        const text = window.location.href
        navigator.clipboard.writeText(text);

        setCopySuccess('success')
        ref.current = setTimeout(() => {
            setCopySuccess('')
        }, 1000);
    }

    const handleOptions = (optionsid, valueid, index) => {
        return () => {
            let copySelectOption = cloneDeep(selectOption)
            if (copySelectOption.length) {
                let index = -1
                copySelectOption?.forEach((item, opindex) => {
                    if (item.optionId === optionsid && item.valueId === valueid) {
                        index = opindex
                    }
                })
                if (index !== -1) {
                    copySelectOption.splice(index, 1)
                } else {
                    let arr = [{
                        optionId: optionsid,
                        valueId: valueid
                    }]
                    copySelectOption?.forEach((item, optionIndex) => {
                        if (item.optionId !== optionsid && item.valueId !== valueid) {
                            arr.push(item)
                        }
                    })
                    copySelectOption = arr
                }
            } else {
                copySelectOption = [{
                    optionId: optionsid,
                    valueId: valueid
                }]
            }
            setselectOption(copySelectOption)

            if (copySelectOption.length !== detailData.variants[0].options.length) {
                setNowVariant('')
                setMainImg('')
                setLogistics('')
            } else {
                detailData.variants.forEach(vaitem => {
                    let length = 0
                    vaitem.options.forEach(opitem => {
                        copySelectOption.forEach(selectitem => {
                            if (opitem.optionId === selectitem.optionId && opitem.valueId === selectitem.valueId) {
                                length += 1
                            }
                        })
                    })
                    if (length === copySelectOption.length) {
                        setNowVariant(vaitem)
                        setMainImg(vaitem.imgUrl)
                    }
                })
            }

        }
    }

    const handleImport = async () => {
        CommonDataCollect({
            event_type: 'tmall_product_detail',
            action: 'detail_import_product'
        })

        window.dtag.set({
            event: 'click',
            module: 'tmall_find_import_click',
            action: 'tmall_find_import_click',
            custom_data:{
              search:searchValue,
              supply_product_id:productId
            }
          })

        const params = {
            supplier_type: 4,
            product_id: productId,
            ship_to: selectCountry.Code.toUpperCase()
        }
        try {
            setButtonLoading(true)
            const res = await Post('/prod/select', { data: params });
            if (res.code === 2000 && res.data) {
                setButtonLoading(false)
                setImportSuccess('success')
                window.dtag.set({
                    event: 'click',
                    module: 'tmall_find_import',
                    action: 'tmall_find_import',
                    custom_data:{
                        search:searchValue,
                        supply_product_id:productId
                      }
                  })
                setTimeout(() => {
                    setImportSuccess('')
                }, 1000);
            } else if (res.code === 4290 || res.msg === 'The quantity of products you imported to DSers has reached the "Product limit" of your current subscription plan. Please upgrade your subscription to import more products.') {
                window.EVENT.emit(GuildeAction.TurnPlan_Pop, `z-${Date.now()}`, true);
            }
            setButtonLoading(false)
        } catch (error) {
            setButtonLoading(false)
        }
    }

    const renderStyle = (item, item_2) => {
        let style = null
        selectOption?.forEach(optionitem => {
            if (optionitem.optionId === item.optionId && optionitem.valueId === item_2.valueId) {
                style = styles.activeText
            }
        })
        return style
    }

    const calculatePrice = useMemo(() => {
        let list = detailData?.variants?.map(item => {
            return Number(item.price)
        })
        if (!list) {
            return '---'
        }
        if (Math.min(...list) / 100 == Math.max(...list) / 100) {
            return Math.min(...list) / 100
        }
        return `${Math.min(...list) / 100} ~ ${Math.max(...list) / 100}`
    }, [detailData])

    const calculateStoke = useMemo(() => {
        let count = 0
        detailData?.variants?.forEach(item => {
            count += item.stock
        })
        return count
    }, [detailData])

    const changeCountry = (countryCode) => {
        setModalCountry(countryCode)
    }

    const renderLogisticsTime = () => {
        const map = {
            1: 'working days',
            2: 'days'
        }
        return logistics?.data && `${logistics?.data[0]?.time.min}-${logistics?.data[0]?.time.max} ${map[logistics?.data[0]?.time.mode]}`
    }


    return (
        <>
            <Spin size="large" wrapperClassName={styles.spin} spinning={isLoading} tip={spinText}>
                {
                    detailData.status !== 'NotAvailable' ?
                        < div className={styles.container}>
                            <DetailHeader />
                            <div className={styles.detailbox}>
                                <div className={styles.main} id='main'>
                                    <img className={styles.tmalllogo} src={tmallLogo} alt="" />
                                    <div className={styles.mainleftBox} id='mainleftBox'>
                                        <div className={styles.mainleft} id='mainleft'>
                                            <div className={styles.mainImg} id='mainImg' onMouseMoveCapture={handleMove} onMouseLeave={handleLeave}>
                                                <img src={mainImg ? mainImg : detailData?.mainImgUrl} alt="" id='img' />
                                            </div>
                                            <div className={styles.imgList}>
                                                {detailData?.medias?.map((item, index) => {
                                                    return (
                                                        <div key={item} onClick={handleChangeMain(index)} className={mainImg === item ? styles.activeImg : null}> <img src={item} alt="" /> </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.mainright}>
                                        <div className={styles.protitle}>
                                            <span>Only exists on DSers</span>{detailData.title}
                                        </div>
                                        {
                                            detailData?.status !== 'OffLine' ?
                                                <>
                                                    <div className={styles.mainline}></div>
                                                    <div className={styles.mainprice}>
                                                        US ${!nowVariant ? calculatePrice : nowVariant?.price / 100}
                                                    </div>
                                                    <div className={styles.mainline}></div>
                                                    <div className={styles.mainsku}>
                                                        {
                                                            detailData.options?.map((item, index) => {
                                                                return (
                                                                    <div key={item.optionId} className={styles.skuitem}>
                                                                        <span>{item.name} </span><span></span>
                                                                        <div className={styles.textsku}>
                                                                            {item.values?.map((item_2, index_2) => {
                                                                                return (
                                                                                    <span className={renderStyle(item, item_2)} onClick={handleOptions(item.optionId, item_2.valueId, index)} title={item_2.name} key={item_2.valueId}>{item_2.name}</span>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        <span className={styles.stock}>{!nowVariant ? calculateStoke : nowVariant?.stock} Pieces available</span>
                                                    </div>
                                                    <div className={styles.shipping} style={{ minHeight: detailData?.status === 'NotShipTo' ? 'auto' : null }}>
                                                        <div>Ships to <span><i className="material-icons" style={{ fontSize: '16px', verticalAlign: 'bottom' }}>location_on</i></span><span onClick={handleOpenModal}>{selectCountry.Name}</span></div>
                                                        {
                                                            detailData?.status !== 'NotShipTo' && logistics?.data?.length ?
                                                                <>
                                                                    <div>{!nowVariant ? '---' : `$ ${logistics?.data && logistics.data[0]?.amount / 100}`}</div>
                                                                    <div>From <span>China</span> to <span>{selectCountry.Name}</span> via <span onClick={handleOpenModal}>{!nowVariant ? '---' : logistics?.data && logistics.data[0]?.companyName} </span></div>
                                                                    <div>Estimated delivery: <span>{!nowVariant ? '---' : renderLogisticsTime()}</span></div>
                                                                    <div><i className="material-icons" style={{ color: '#4CAF50', fontSize: '18px' }}>check_circle</i><span>Tracking Available</span> </div>
                                                                </>
                                                                :
                                                                <div> <p className={styles.notrackingTip}><i className="material-icons notranslate">error</i>{' '}  This product does not support the destination country, please switch the country</p></div>
                                                        }
                                                    </div>
                                                    <div className={styles.button}>
                                                        <Button onClick={(detailData?.status !== 'NotShipTo' && logistics?.data?.length)? handleCopy : null} style={(detailData?.status === 'NotShipTo' || !logistics?.data?.length) ? { background: '#ffffff', borderColor: '#dde0e2', color: '#dde0e2', cursor: 'not-allowed' } : null}>{!copySuccess ? 'COPY LINK' : 'LINK COPIED'}</Button>
                                                        <Button loading={buttonLoading} disabled={detailData?.status === 'NotShipTo' || !logistics?.data?.length} style={{ background: (detailData?.status === 'NotShipTo' || !logistics?.data?.length) ? '#E0E0E0' : null }} onClick={() => { !importSuccess && handleImport() }}>{importSuccess ? 'IMPORTED' : 'ADD TO IMPORT LIST'}</Button>
                                                    </div>
                                                </> :
                                                <p className={styles.productTip}>
                                                    <i className="material-icons notranslate">error</i>{' '}
                                                    Sorry,this item is no longer available!
                                                </p>
                                        }
                                    </div>
                                </div>
                                <div className={styles.specifications}>
                                    <div className={styles.title}>SPECIFICATIONS</div>
                                    <div className={styles.line}></div>
                                    <div className={styles.item}>
                                        <ul>
                                            {
                                                detailData.attributes?.map((item, index) => {
                                                    return <li key={item.name + index}><span title={item.name}>{item.name}</span> <span title={item.value}>{item.value}</span></li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className={styles.description}>
                                    <div className={styles.title}>DESCRIPTION</div>
                                    <div className={styles.line}></div>
                                    <div className={styles.descriptionimg} dangerouslySetInnerHTML={{ __html: detailData.description }}>
                                    </div>
                                </div>
                            </div>
                        </div> : <NoProduct />
                }
                {
                    modalVisible ? <ShippingModal nowVariant={nowVariant} appId={appId} productId={productId} visible={modalVisible} closeModal={handleCloseModal} changeCountry={changeCountry} /> : null
                }
                <OblerloGuide />
                <TmallStartImport/>
            </Spin>
        </>
    )
}

export default TmallDetail