/**
 * Content: Oblero首屏弹窗
 * Author:Gaohuan
 */
import React, { useEffect, useState } from "react"
import { Button } from 'antd';
import Style from './index.module.less'
import { connect } from 'dva';
import { ComposeApplicator } from "lodash-decorators/applicators";
import intl from 'react-intl-universal';
const OBDialog = ({ dispatch, setHide, guildBtnUnify, onceBindAE }) => {
    const [state, setState] = useState('show');
    const [couldClose, setCouldClose] = useState(false);
    useEffect(() => {
        setTimeout(() => {
            setCouldClose(true)
        }, 1300)
    }, [''])
    // changehide 渐变消失   show  出现   hide  消失
    const [link, setLink] = useState('https://www.baidu.com/');
    /**
     * 点击事件
     * @param state :是否绑定AE
    */
    const clickEvent = (state) => {
        setState('changehide');
        setHide()
        setTimeout(() => {
            state === 'noBindAE' && guildBtnUnify(1)
        }, 200)

    }
    /**
     * 弹窗内容
    */
    const DialogText = () => {
        // bindAE 已经绑定AE    noBindAE 没有绑定AE
        const [currentMessage, setCurrentMessage] = useState(null);
        useEffect(() => {
            setCurrentMessage(intl.get('guide.OBDialog')[window.IS_BIND_AE || onceBindAE ? 'bindAE' : 'noBindAE'])
        }, window.IS_BIND_AE, onceBindAE)
        return (
            currentMessage && <div className={Style.OberloGuideCenter}>
                {/* balloon */}
                <div className={Style.GuideLeftBalloon}></div>
                <div className={Style.GuideRightBalloon}></div>

                {/* 删除按钮 */}
                {couldClose && <div
                    className={Style.GuideClose}
                    onClick={() => {
                        setState('changehide')
                        setHide()
                    }} />}
                {/* ⚠中间内容⚠ */}
                <div className={Style.GuildeContentCenter}>
                    <div className={Style.OberloGuideTitle}>
                        {currentMessage.topTitle}
                    </div>
                    <div className={Style.centerTitle}>
                        {currentMessage.centerTitle}
                    </div>
                    <div className={Style.OberloGuideContent}>
                        {
                            currentMessage.top ?
                                <div>{currentMessage.top}</div> : null
                        }
                        {
                            currentMessage.bottom ?
                                <div>{currentMessage.bottom.map(item => {
                                    return <div>{item}</div>
                                })}</div> : <></>
                        }
                    </div>
                    <div className={Style.OberloBtnBox}>
                        {
                            <Button
                                className={window.IS_BIND_AE || onceBindAE ? Style.OberloGuideBtn : Style.OberloGuideBtnActive}
                                onClick={() => { clickEvent(window.IS_BIND_AE || onceBindAE ? 'bindAE' : 'noBindAE') }}
                            >
                                {currentMessage.btn}
                            </Button>
                        }
                    </div>
                </div>
            </div>
        )
    }
    /**
     * 弹窗上部分动画
     */
    const DialogPersonAction = () => {
        return (<>
            {/* 两人之间的烟花图 */}
            <div className={Style.OberloGuideCenterFloor}></div>
            {/* 左侧小人~ */}
            <div className={Style.OberloLeftPerson}></div>
            {/* 右侧小人~ */}
            <div className={Style.OberloRightPerson}></div>
        </>)
    }
    /**
     * 弹窗背景动画
     */
    const DialogBJAction = () => {
        return (<>
            <div className={Style.OberloGuideCenterBg}></div>
            {/* 左侧背景 */}
            <div className={Style.OberloLeftBgImg}></div>
            {/* 右侧背景 */}
            <div className={Style.OberloRightBgImg}></div>
        </>)
    }
    return (
        <div className={Style.OberloPopCenter}>
            {/* 蒙版 */}
            <div className={Style.OberloGuideMask}
                style={state === 'show' ? { display: 'block' } : { display: 'none' }}></div>
            <div className={
                state === 'show' ?
                    Style.OberloGuideModel :
                    state === 'hide' ?
                        Style.closeModel :
                        Style.closeModelAction}>
                {DialogBJAction()}
                {DialogPersonAction()}
                {DialogText()}
            </div>
        </div>

    )
}
export default React.memo(OBDialog)

