import React,{ useEffect,useState } from "react"
import {Alert, Button } from 'antd';
import Style from './index.module.less'
import { GuildeAction } from 'actions'
import zIndex from "@material-ui/core/styles/zIndex";
const OBCard = ({ update }) => {
   const [currentIndex,setCurrentIndex] = useState(0);
   const [firstIn, setFirstIn] = useState(true);
   const [saveName,setSaveName] = useState('')
  //  state => 0 渐变显示     state => 1 不显示   state => 2 渐变消失 
  const [state, setState] = useState(1)
  const firstSignIn = () => {
      setTimeout(() => {
        setSaveName(JSON.parse(localStorage.getItem('store'))?.user_id);
        let confirmArr = localStorage.getItem('guildCardAlreadyRead') || [];
        FirstEnterUrl.forEach((item, index) => {
          if (window.location.pathname?.endsWith(item.url) && !(confirmArr.includes(item.val))) {
            window.EVENT.emit(GuildeAction.OblerloGuide_Card, `${item.val}-${Date.now()}`, true);
          }
        })
        setFirstIn(false);
      }, 800)
    }   
  /**
  *   点击 关闭/确认按钮事件
  *   @params turn 是否跳转页面
  */
  const confirmOption = (turn) =>{
    let recordMess = localStorage.getItem(`${saveName}-guildCardRead`) ? 
    JSON.parse(localStorage.getItem(`${saveName}-guildCardRead`)) : [];
    recordMess.push(OBCardValue[currentIndex].key)
    localStorage.setItem(`${saveName}-guildCardRead`, JSON.stringify(Array.from(new Set(recordMess))));
    setState(2)
    if(turn){
      window.open(OBCardValue[currentIndex].url, '_blank')
    }
  }  
  useEffect(() => {
     // 初始化不渲染
    if (firstIn) { 
      firstSignIn();
      return;
    }
    if (![null, undefined, ''].includes(update)) {
        if(localStorage.getItem(`${saveName}-guildCardRead`) &&
        JSON.parse(localStorage.getItem(`${saveName}-guildCardRead`).includes(update.split('-')[0]))){
              setState(state === 0 ? 2 : 1);
              return;
       }
       OBCardValue.forEach((item,index)=>{
          if(item.key === update.split('-')[0]){
               setCurrentIndex(index)
                       }
                 })
          setState(0)    
      } else if ([''].includes(update) && state === 0) {
        setState(2)
      }else{
        setState(1)
    }
  },[update])
     return (
       <div
        style={{zIndex:OBCardValue[currentIndex].key === 'mapping' ? 3001:999}}
         className={state === 0 ?
                     Style.OberloGuideCard :
                     state === 1 ?
                     Style.OberloGuideCardHide :
                     Style.OberloGuideGradualHide
                    }
            onClick={()=>{
                confirmOption(true)
            }}>
       <div className={Style.GuideCardHeader}>
          <a target="_blank" 
            onClick={()=>{
              setState(2)
            }}
            href={OBCardValue[currentIndex].url}>
            {OBCardValue[currentIndex].name}
          </a>
         <img 
           src={require('../../../assets/oberlo-guid/close.png')}
             onClick={(event) => {
                event.stopPropagation()
                confirmOption(false)
           }}
          />
       </div>
       <img className={Style.GuideCardContent} 
            src={OBCardValue[currentIndex].image} />  
     </div>)
}
export const OBCardValue=[{
      key:'mapping',
      url: 'https://help.dsers.com/oberlo-migration-features-mapping-products/',
      name: 'Mapping products',
      image:require('../../../assets/oberlo-guid/Card/Mapping.png')
    },{
      key:'order',
      url: 'https://help.dsers.com/split-an-order-automatically-for-shopify/',
      name:"Split an order automatically",
      image:require('../../../assets/oberlo-guid/Card/Order.png')
    },{
      key:'pricingRule',
      url:'https://help.dsers.com/oberlo-migration-features-pricing-rule/',
      name:"Pricing rule",
      image:require('../../../assets/oberlo-guid/Card/PricingRule.png')  
    },{
      key:'tracking',
      url:'https://help.dsers.com/oberlo-migration-features-bulk-orders/',
      name:"Bulk orders",
      image:require('../../../assets/oberlo-guid/Card/Tracking.png')  
    },{
      key:'splitTracking',
      url:'https://help.dsers.com/track-your-orders-for-shopify/',
      name:"Track your orders",
      image:require('../../../assets/oberlo-guid/Card/SplitTracking.png')  
    },{
      key:'paypal',
      url:'https://help.dsers.com/add-tracking-number-to-paypal-for-shopify/',
      name:"Add tracking number to PayPal",
      image:require('../../../assets/oberlo-guid/Card/PayPal.png')  
    },{
      key:'shipping',
      url:'https://help.dsers.com/oberlo-migration-features-shipping-method/',
      name:"Set shipping methods",
      image:require('../../../assets/oberlo-guid/Card/Shipping.png')    
    },{
      key:'shipConfirm',
      url:'https://help.dsers.com/oberlo-migration-features-shipping-confirmation-email/',
      name:"Shipping confirmation",
      image:require('../../../assets/oberlo-guid/Card/ShipConfirm.png')    
    },{
      key:'autoUpdate',
      url:'https://help.dsers.com/oberlo-migration-features-auto-update-notification/',
      name:"Auto update & Notification",
      image:require('../../../assets/oberlo-guid/Card/AutoUpdate.png')  
  }]

/**
 * 页面初始化
 */
export const FirstEnterUrl = [
  { url: '/order/list', val: 'tracking' },
  { url: '/tracking', val: 'splitTracking' },
  { url: '/settings', val: 'shipping' }
]
export default React.memo(OBCard)