import checkDsersExtension from 'utils/checkDsersExtension';

export default (onConnectFinish, aeCookieChange) => {
  checkDsersExtension()
    .then(cookie => {
      window.sessionStorage.setItem('ae_ck', cookie);
      window.sessionStorage.setItem('h_d_extension', '1');
      onConnectFinish(true);

      // 监听来自 content_script message
      window.addEventListener(
        'message',
        e => {
          if (
            e.origin.includes('dsers.com') ||
            e.origin.includes('localhost')
          ) {
            if (e.data.type === 'AE_SITE_LOGIN_CK') {
              console.log('ae_ck', e);
              if (aeCookieChange) {
                aeCookieChange(e.data.value);
              }
              window.sessionStorage.setItem('ae_ck', e.data.value);
            }
          }
        },
        false
      );
      return null;
    })
    .catch(err => {
      onConnectFinish(false);

      console.log('checkDsersExtension error:', err);
      window.sessionStorage.removeItem('ae_ck');
      window.sessionStorage.removeItem('h_d_extension');
    });
};
