import React, { useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import { Link } from 'dva/router';
import intl from 'react-intl-universal';

import FixedHeaderLayout from 'layouts/FixedHeaderLayout';
import { Get } from '../../utils/request';

import styles from './Detail.less';

function Detail({ match }) {
  const [detailData, setDetailData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (match.params.id) {
      // eslint-disable-next-line
      fetchDetail(match.params.id);
    }
  }, [match]);

  async function fetchDetail(id) {
    setLoading(true);
    try {
      const res = await Get(`/helpcenter/latestnews/${id}`);

      if (res.code === 2000 && res.data) {
        setDetailData(res.data);
      }
    } catch (error) {
      console.log('fetchDetail error:', error);
    } finally {
      setLoading(false);
    }
  }

  if (loading) {
    return (
      <Spin size="large" style={{ width: '100%', margin: '180px auto' }} />
    );
  }

  if (!detailData) {
    return null;
  }

  return (
    <FixedHeaderLayout
      extraContent={<div className={styles.title}>Help Center</div>}
    >
      <Row type="flex" justify="center">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 18 }}
          lg={{ span: 14 }}
          xl={{ span: 12 }}
          xxl={{ span: 9 }}
        >
          <section className={styles.section}>
            <h2>{detailData.title}</h2>
            <div className={styles.time}>
              {detailData.push_date && detailData.push_date.replace(/-/g, '.')}&nbsp;&nbsp;&nbsp;{intl.get('help_center.detail.by_ds_team')}
            </div>
            <div className={styles.top}>
              <p>{intl.get('help_center.detail.ds_users')},</p>
              <p>{intl.getHTML('help_center.detail.so_much')}</p>
            </div>
            <div className={styles.content}>
              <p>{detailData.value}</p>
            </div>
            <div className={styles.bottom}>
              <p>
                {intl.getHTML('help_center.detail.to_contact')}&nbsp;
                <a href="mailto:support@dserspro.com">contact</a>.
              </p>
              <p>{intl.get('help_center.detail.happy_with_ds')}</p>
              <p>{intl.getHTML('help_center.detail.best_regards')}</p>
            </div>
            <div className={styles.footer}>
              <Link
                to={{
                  pathname: '/smarthelp',
                  state: { fromIssueModal: true }
                }}
              >
                {intl.get('help_center.help_center.fixed_header')}
              </Link>
              <a
                href="https://www.blog.dserspro.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Blog
              </a>
              <a
                href="https://www.youtube.com/channel/UCpiRdgB8VXvaht0AizSP9mA"
                target="_blank"
                rel="noopener noreferrer"
              >
                Youtube
              </a>
              <a
                href="https://www.facebook.com/DSersOfficial"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
            </div>
          </section>
        </Col>
      </Row>
    </FixedHeaderLayout>
  );
}

export default Detail;
