export default {
  tracking: {
    title: 'Suivi',
    search_input_placeholder:
      'Vous pouvez choisir une catégorie pour rechercher plus précisement',
    order_no: 'N° Commande',
    email: 'Email',
    tracking_no: 'N° de Suivi',
    fulfilled: 'Traité',
    unfulfilled: 'Non traité',
    search_type: {
      order_no: 'N° Commande: ',
      note: 'Note',
      ali_order_no: 'N° Commande Ali',
      tm_order_no:"N° Commande Tmall",
      date: 'Date: ',
      product: 'Produit',
      email: 'Email: ',
      customer_name: 'Nom du Client: ',
      tracking_no: 'N° de Suivi: ',
      address: 'Adresse: '
    },

    time_zone_placeholder: 'Fuseau Horaire',
    variants: 'Variantes: ',
    pending: 'En Attente',
    Intramsit: 'En transit',
    pickup: 'Récupérer',
    delivered: 'Livré',
    manually: 'Régler le statut de suivi manuellement',
    origin: "Pays d'origine",
    destination: 'Pays de destination',
    retrack: 'Suivre de nouveau',
    trackingMap:
      '{key, plural, =10 {Tous} =1 {En Attente} =2 {En transit} =4 {Récupérer} =6 {Livré} =3 {Expiré} =9 {Attention} }',
    trackingTipFree:"This feature has been disabled, Please Upgrade plan to activate feature. Activate this feature to trace the tracking status of orders that have been shipped.",
    trackingTipFree2:"Tracking is a paid feature. Please upgrade your plan to activate it. Activate Tracking to trace the tracking status of orders that have been shipped.",
    trackingTipPay:"Please set up the Tracking for the store.",
		trackingTipFreeBtn:"UPGRADE",
		trackingTipPayBtn:"SET UP THE TRACKING",
    shop: 'Boutique: ',
    unrecognizedErr:
      'Nous ne pouvons pas suivre pour le moment, veuillez réessayer plus tard.',
    tips: 'veuillez réessayer plus tard.',
    noTrack:
      "Pas d'information de suivi pour le moment. veuillez réessayer plus tard.",
    select: 'Choisir',
    setTracking: [
      { key: 2, value: 'En transit' },
      { key: 4, value: 'Récupérer' },
      { key: 5, value: 'Non livré' },
      { key: 6, value: 'Livré' },
      { key: 7, value: 'Alerte' },
      { key: 8, value: 'Inconnu' }
    ],
    logisticsStatus: {
      1: 'En Attente',
      2: 'En transit',
      4: 'Récupérer',
      6: 'Livré',
      3: 'Expiré',
      7: 'Alerte',
      5: 'Non livré',
      8: 'Inconnu',
      9: 'Attention'
    }
  }
};
