import React from 'react';

import { Drawer, Button, Collapse } from 'antd';
import intl from 'react-intl-universal';

import styles from './FilterDrawer.less';

const { Panel } = Collapse;
// 弹框组件
export default function FilterDrawer(props) {
 // debugger
  return (
    <Drawer {...props} bodyStyle={{ paddingBottom: '80px' }} width={395}>
      <Collapse
        className={styles.filterCollapse}
        ghost
        defaultActiveKey={props?.defaultActiveKey}
      >
        {props?.options?.map(item => {
          if (!item.hidden) {
            return (
              <Panel header={item?.title} key={item.key}>
                {item?.filterComponent}
              </Panel>
            );
          }
        })}
      </Collapse>
      <div className={styles.footer}>
        <Button className={styles.confirmBtn} onClick={props.onConfirm}>
          {intl.getHTML('public.filter_confirm_des')}
        </Button>

        <Button className={styles.clearBtn} onClick={props.onCancel}>
          {intl.getHTML('public.filter_clear_des')}
        </Button>
      </div>
    </Drawer>
  );
}
