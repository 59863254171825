import React from 'react';
import { connect } from 'dva';
import { Popover, Icon, Tooltip, Modal, Checkbox, Button, message, Radio, Tag } from 'antd';

import qs from 'querystring';
import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import round from 'lodash/round';
import intl from 'react-intl-universal';
import className from 'classnames';
import { getVersion, list_V5 } from 'utils/bannerWhiteList';

import SelectShipp from './Order/SelectShipp.js';
import DuplicateOrderConfirm from './DuplicateOrderConfirm.js';

import { hasCanceled, statusMap, checkChileTaxNumberValid, supplyPlatformEquals, filterUpdateExtLineItems } from './util';
import { CommonDataCollect } from 'utils/utils';
import {
  setLocalStorage,
  getLocalStorage,
  createIFrameForCB,
  replaceUrlToHttps,
  getSessionStorage,
  setSessionStorage
} from '../../utils/utils';
import getAdminRole from 'utils/getAdminRole';
import { currencySymbolMap } from '../../common/sysconfig';
import { Post } from 'utils/request';
import { dispatchEventForGuide } from '../BeginnerGuide/util';
import RecommendTag from './component/RecommendTag';
import { placeOrderViaExt, getAELoginStatus, getExtDsersLoginStatus } from 'utils/placeOrderViaExt.js';

import orderStyles from './Order.less';
import orderProductsStyles from './OrderProducts.less';
import './Order.css';

import defaultImg from '../../assets/default-image.jpg';
import bogo_flag from '../../assets/order/bogo_flag.png';
import bundle_flag from '../../assets/order/bundle_flag.png';
import { AsyncConfirm } from './component/AsyncConfirm/AsyncConfirm.tsx';
import InstallExtension from './component/InstallExtension';
import DSersAlert from 'routes/AgencyOrder/component/DSersAlert';

const bannerTrickConfig = {
  'track-type': 'productBargain',
  'track-name': 'bannerV2FromOrder',
  'track-metadata-position': 'awaitingOrder'
};
const getTestAccount = () => {
  let res = [];
  try {
    res = JSON.parse(localStorage.getItem('test-place-via-ext-account') || '[]')
  } catch (error) {
    return [];
  }
  return res.length ? res : [];
}
const EXT_PLACE_TEST_USER = process.env.BUILD_TARGET === 'prod' ? 
[
  // 线上报ticket用户： api下单价格变贵
  'bvkkqb2q82pg0090d3r0',
  'c60jljepctpg00ectss0',
  'c54qv79bopq0008sotr0',
  'bn6rja8unna000ccng1g',
  'bn9q93l3jgog00cr4dh0',
  'c600m1t1650g009a1hsg',
  'bmt11ttqp3nbnmmpfbi0',
  'c4ogi7g16aug00bm59pg',
  'c4mmc1sa9opg00clrk60',
  'c5q7lc30bp9g00a70n10',
  'bm5dvalqp3nbv2br1200',
  'c8darh8au14000arr1tg'
] : 
[
  // 测试环境测试账号
  'bqa49860e4rllmptor3g',
  'c71hmp6akpq001ol4ncg',
  'c6ev0d4toal001iefvpg',
  ...(getTestAccount())
]

const canUseExtPlace = userId => {
  return true;
  // return (
  //   // 针对用户的放量
  //   EXT_PLACE_TEST_USER.includes(userId) ||
  //   // 灰度放量
  //   !!window.PLACE_ORDER_VIA_EXTENSION_TEST ||
  //   // 测试环境全部放开
  //   process.env.BUILD_TARGET !== 'prod'
  // );
};


@connect(({ order, loading, login, dropshippersetting, global }) => ({
  order,
  storeCurrency: global.storeCurrency,
  userInfo: login.userInfo,
  admitadCheckCode: dropshippersetting.admitadCheckCode,
  loading: loading.models.order,
  confirmLoading: loading.effects['order/place'],
  okLoading: loading.effects['order/updataExt'],
  unsupportedShipping: global.unsupportedShipping,
  currentAeInfo: global.AEInfo,
  localAEInfo: global.localAEInfo,
  conversionFinish:global.conversionFinish
}))
export default class OrderConfirm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      // 重复单提示弹框
      showDuplicateOrderConfirm: false,
      // 广告数据
      bannerData: {},
      // 重复单使用插件下单还是api下单
      dupOrderType: 1,
      // 下单时使用插件下单还是api下单
      placeOrderViaType: 1,
      // 提示安装插件时是更新插件还是安装插件
      installExtType: 1,
      // 安装插件提示弹框
      showInstallExt: false,
      // 插件下单交互loading
      extPlaceLoading: false,
      // 插件内登录信息失效时的提示弹框
      showRefreshModal: false,
    };

    this.isNewOrderResultApi = window.DSERS_O_R || getAdminRole();
  }

  componentWillMount() {
    let { data, status } = this.props;

    this.getSettingMsg();

    data = this.setDataIsDisable(data, status);
    this.setState({
      data
    });
  }

  componentDidMount() {
    const { data, status } = this.props;

    //打开重置数据
    this.resetData()

    if (status === 2 || status === 7) {
      this.handleBannerCheck(data);
    }
  }

  componentWillUnmount() {
    this.hasSaveTimeStamp = false;
  }

  checkedIds = {}; // 选中订单id:id
  canOrderStaus = [2, 6, 7]; // 能下单的状态
  hasSubmitOrder = false; // 是否下过单
  orderSetting = {};
  duplicateOrderArr = []; // 保存下单时后台返回的重复单
  duplicateOrderDatas = null;
  currencyCode;
  currencySymbol;
  isNotUSD;
  currencyRates;
  shopifyCurrencyCode;

  handleBannerCheck = async data => {
    const result = {};
    console.log(data, '-------------------------')
    // eslint-disable-next-line
    for (const item of data) {
      const countryCode = item?.ext?.address?.country_code;
      if (!countryCode) {
        continue;
      }
      if (item.childrens) {
        if (!result[item.storeId]) {
          result[item.storeId] = [];
        }

        // eslint-disable-next-line
        for (const i of item.childrens) {
          const exsistProd = result[item.storeId].find(
            prod => prod.id === i.shopifyProductId
          );
          if (exsistProd) {
            if (!exsistProd.countries.includes(countryCode)) {
              exsistProd.countries.push(countryCode);
            }
            // eslint-disable-next-line
            continue;
          }
          result[item.storeId].push({
            id: i.shopifyProductId,
            countries: [countryCode]
          });
        }
      }
    }

    const storeIds = Object.keys(result);

    if (storeIds.length === 0) {
      return;
    }

    Promise.all(
      storeIds.map(i => {
        const params = {
          store_id: i,
          sp_product: result[i]
        };
        return Post('/prod/propool/check', {
          data: params
        });
      })
    )
      .then(res => {
        const goods = {};
        const exsistAliIds = {};
        res
          .filter(
            i => i.code === 2000 && i.data && Object.keys(i.data).length > 0
          )
          .map(i => i.data)
          .forEach(i => {
            // 每个ae供应商只展示一个广告
            Object.entries(i).forEach(([key, value]) => {
              if (!exsistAliIds[value?.ae_product_id]) {
                goods[key] = value;
                exsistAliIds[value?.ae_product_id] = true;
              }
            });
          });
        // console.log(goods)
        this.setState({ bannerData: goods });
        console.log('banner confirm =>>>>', goods);
        return null;
      })
      .catch(err => {
        console.log('handleBannerCheck fetch error:', err);
      });
  };

  handleBannerClick = item => {
    const { product } = this.props;

    if (item) {
      this.props.dispatch({
        type: 'global/showOneClickMapping',
        payload: item
      });
      this.props.dispatch({
        type: 'global/dataTrack',
        payload: {
          data: {
            user_id: this.props.userInfo.user_id,
            store_id: item.store_id.toString(),
            platform: 'shopify',
            page: 'order confirm',
            page_url: window.location.href,
            module: 'order',
            action: 'switch-button-click',
            product_id_list: [''],
            order_id_list: [''],
            custom_info: [
              {
                name: 'switch_version',
                value: getVersion(this.props.userInfo.user_id)
              },
              {
                name: 'ae_product_id',
                value: item.ae_product_id.toString()
              },
              {
                name: 'dsers_product_id',
                value: item.dsers_product_id.toString()
              },
              {
                name: 'dsers_product_id',
                value: item.dsers_product_id.toString()
              },
              {
                name: 'shopify_product_id',
                value: item.shopify_product_id.toString()
              },
              {
                name: 'mapping_type',
                value: item.mapping_type.toString()
              }
            ]
          }
        }
      });
    }
  };

  handleOpenModal = () => {
    this.props.dispatch({
      type:"global/setConversionModal",
      payload:true
    })
  }

  // 订单是否可选
  setDataIsDisable = (data, status) => {
    console.log('订单可选')
    data.forEach(item => {
      item.canOrderDisabled = this.oneOrderIsDisabled(item, status); // 返回false 标识可下单 不禁用
      item.checked = !item.canOrderDisabled; // 可下单就选中
    });
    return data;
  };

  // 一条大订单是否可以下单
  oneOrderIsDisabled = (oneOrder, status) => {
    console.log('oneOrder',oneOrder)
    let flag = true;
    // shopify 删除
    if (oneOrder.isShopifyDelete) {
      return flag;
    }
    // shopify 取消
    if (oneOrder.detail.cancel_reason && oneOrder.detail.cancelled_at) {
      return flag;
    }

    if(oneOrder.bStatus == 1 || oneOrder.bStatus == 2){
      return flag;
    }

    // 智利税号必填
    if (oneOrder.ext && oneOrder.ext.address && oneOrder.ext.address.country_code === 'CL') {
      if (!checkChileTaxNumberValid(oneOrder.ext.address.rut_no)) {
        return true;
      }
    }

    const countryCode = oneOrder.ext?.address?.country_code;
    const { unsupportedShipping, supplyPlatform } = this.props;
    oneOrder.childrens.forEach(child => {
      // 平台不对 
      if (!supplyPlatformEquals(child.platform_type, supplyPlatform)) return;
      // 商品加黑
      if (child.is_black_list === 2 || !child.sp) {
        return;
      }
      // pengding fulfillments
      if (child.isPendingFulfillments) {
        return;
      }
      // shopify取消
      if (
        child.canceledBy == 'shopify' &&
        status == 6 &&
        !child.AECanceled.includes(1)
      ) {
        return;
      }

      child.sp.forEach((sp, i) => {
        if (sp.status == status) {
          // 物流方式已不再支持该国家 禁止下单
          const isUnsupportShipping =
            unsupportedShipping.Global?.[sp.logistics_company] ||
            unsupportedShipping[countryCode]?.[sp.logistics_company];

          if (isUnsupportShipping) {
            return;
          }
          if (child.supplierMapType == 1) {
            const index = this.getWhichSpCanOrder(child, status); // base mapping 主副供应商用哪个
            if (i == index) {

              // 有物流列表 并且是ae取消
              if (
                sp.freights &&
                sp.freights.length > 0 &&
                status == 6 &&
                child.AECanceled[i]
              ) {
                flag = false;
              }
              // 有物流列表 并且不是取消单
              if (sp.freights && sp.freights.length > 0 && status != 6) {
                flag = false;
              }
            }
          }


          // 不是base mapping 有物流列表
          if (
            child.supplierMapType > 1 &&
            sp.freights &&
            sp.freights.length > 0
          ) {
            flag = false;
          }
        }
      });
    });
    return flag;
  };

  // 获取basMap 主副供应商哪个应该去下单
  getWhichSpCanOrder = (child, status) => {
    const { sp = [] } = child;
    let ii = 0;

    if (sp.length == 1) {
      return ii;
    }

    let flag = false;

    if (sp.length == 2) {
      sp.forEach((item, i) => {
        if ([4, 3, 7].includes(item.status) && item.is_default) {
          ii = i;
          flag = true;
        }
        if (item.status == status && item.is_default && !flag) {
          ii = i;
          flag = true;
        }
        if (item.status == status && !item.is_default && !flag) {
          ii = i;
        }
        if (!sp.is_default && item.platform_order_id) {
          flag = true;
          ii = i;
        }
      });
      if(status == 7 && child.sp.every((item=>item.status == 7))){
        let orderIndex =  child.sp.findIndex(item=> !item.is_default)
        if(orderIndex > -1){
          ii = orderIndex
        }
       }
    }

    return ii;
  };

  // 获取setting中的手机号和用户留言
  getSettingMsg = () => {
    this.orderSetting =
      JSON.parse(localStorage.getItem('settingOrderData')) || {};
  };

  // 选物流 点ok
  onOk = (k, i, s, v) => {
    
    // k 大订单索引  i 子订单索引  s sp索引  v 用户选择的物流
    const { data } = this.state;
    const sp = data[k].childrens[i].sp[s];
    const company = this.getTime(sp.freights, v);
    const { freightAmount = company.standardFreightAmount } = company;

    sp.logistics_company = v;
    sp.freight_cost = `${freightAmount.value}`;
    delete sp.showExpress;
    
    data[k].ext.lineitems[i].sp[s].logistics_company = v

    const obj = {
      order_id: data[k].id,
      ext: data[k].ext,
      store_id: data[k].storeId,
      type: 'shipping-select'
    };
    window.UPDATA_EXT = true
    window.UPDATA_EXT_TIME = +new Date()
    this.props.dispatch({
      type: 'order/updataExt',
      payload: {
        data: obj,
        callback: d => {
          if (d.code == 2010) {
            data[k].ext.updated_at = d.msg * 1;
          }
        }
      }
    });
  };

  // 根据物流名返回物流数据
  getTime = (freights, name) => {
    let n = {};
    freights.forEach(item => {
      if (item.serviceName == name) {
        n = item;
      }
    });
    return n;
  };

  // 切换展示物流选项
  showConfirmProductEms = (k, i, s) => {
    // k 大订单索引  i 子订单索引  s sp索引
    const { data } = this.state;
    const sp = data[k].childrens[i].sp[s];

    const flag = !sp.showExpress;

    if (flag) {
      sp.showExpress = flag;
    } else {
      delete sp.showExpress;
    }

    this.setState({
      data: [...data]
    });
  };

  setConfirmChecked = (e, k) => {
    const checked = e.target.checked;
    const { data } = this.state;

    data[k].checked = checked;

    this.setState({
      data: [...data]
    });

    // if( checked ){
    //   this.checkedIds[ id ] = id;
    // }else{
    //   delete this.checkedIds[id];
    // }
  };

  // 收货人姓名中加 NA
  setNA = data => {
    if (this.props.status != 6 || !this.orderSetting.IsNa) {
      return;
    }

    const {
      ext: {
        address,
        address: { full_name = '', contact_person = '' }
      }
    } = data;

    address.full_name = this.returnSetNA(full_name);
    address.contact_person = this.returnSetNA(contact_person);
  };

  returnSetNA = v => {
    const arr = v.split(' ');

    if (arr.length == 1) {
      arr.push('NA');
    } else {
      arr.splice(1, 0, 'NA');
    }

    return arr.join(' ');
  };

  // 替换收货人手机号
  setPhone = data => {
    const {
      detail: { shipping_address = {}, billing_address = {} } = {}
    } = data;
    const billingAddressPhone =
      shipping_address.phone || billing_address.phone || '';
    const tel = this.orderSetting.default_tel;
    const {
      ext: { address }
    } = data;

    // 用户没改过 并且设置了手机号就替换
    if (address.phone_number === billingAddressPhone && tel) {
      address.mobile_no = tel;
      // address.phone_number = tel;
    }
  };

  hasSaveTimeStamp = false;
  placeOrderList = null;

  // BunMap 一个子订单有3个子订单 下单成功被ae canceled  这时在添加新的子订单会被当成shopify canceled 导致不能下单
  isCanceledByShopify = (lineitem_id, oneOrder) => {
    const {
      detail: { refunds, financial_status, cancel_reason, cancelled_at }
    } = oneOrder;

    let s = '';

    if (hasCanceled(financial_status, cancel_reason, cancelled_at)) {
      return 'shopify';
    }

    if (!refunds) {
      return s;
    }

    refunds.forEach(re => {
      if (
        !re.refund_line_items &&
        hasCanceled(financial_status, cancel_reason, cancelled_at)
      ) {
        s = 'shopify';
      }
      if (!re.refund_line_items) {
        return;
      }
      re.refund_line_items.forEach(line => {
        if (line.line_item_id == lineitem_id) {
          s = 'shopify';
        }
      });
    });

    return s;
  };

  // 设置留言
  setMsg = (sp, oneOrder) => {
    const {
      detail: { note = '' }
    } = oneOrder;
    let msg = '';

    if (!sp.msg) {
      msg =
        note && this.orderSetting.SyncShopifyNotesStatus
          ? note
          : '';
      msg = this.orderSetting.desc ? `${msg} ${this.orderSetting.desc}` : msg;
    }

    sp.msg = sp.msg || msg;
  };

  // 点下单 type: 1:api下单 2:插件下单
  placeOrder = (i, type) => {
    const data = this.placeOrderList;
    const { status } = this.props; // tab status当前在哪个tab下

    const oneOrder = data[i];

    // 没选中 || 不能下单 || shopify删除
    if (!oneOrder.checked || oneOrder.canOrderDisabled || oneOrder.isShopifyDelete) {
      if (data.length > i + 1) {
        this.placeOrder(i + 1, type); // 走下一个
      } else {
        this.placeOrderOver(type); // 或结束
      }
      return;
    }

    this.hasSubmitOrder = true; // 有没有下单 关闭时刷新订单列表

    // 开始下单的时间 查询下单结果用到
    if (!this.hasSaveTimeStamp && !localStorage.getItem('orderTimeStamp')) {
      localStorage.setItem(
        'orderTimeStamp',
        parseInt(new Date().getTime() / 1000)
      );
      this.hasSaveTimeStamp = true;
    }

    this.setNA(oneOrder); // 收货人姓名中加 NA
    // this.setPhone(oneOrder); // 替换收货人手机号
    const { supplyPlatform } = this.props;

    oneOrder.ext.lineitems.forEach((item, ii) => {
      // 加黑商品
      if (item.is_black_list === 2 || !item.sp) {
        return;
      }

      if (!supplyPlatformEquals(item.platform_type, supplyPlatform)) {
        return;
      }

      const otherSps = [];

      item.sp.forEach((sp, si) => {
        // 状态是2的才会去下单 所以在failed order里下单 把7改成2
        if (status == 7 && sp.status == 7) {
          sp.status = 2;
        }

        // 状态是2的才会去下单 所以在canceled里下单 把6改成2  ae取消的才能下单
        if (
          status == 6 &&
          sp.status == 6 &&
          (oneOrder.childrens[ii].AECanceled[si] ||
            this.isCanceledByShopify(item.lineitem_id, oneOrder) == '')
        ) {
          sp.status = 2;
        }

        // 缓存other单
        if (sp.platform_product_id == '' && sp.status == 2) {
          otherSps.push(si);
        }

        if (sp.status == 2) {
          this.setMsg(sp, oneOrder); // 设置留言
        }

        // 能下单的订单 把ae订单号 物流号删除
        if (sp.status == 2) {
          sp.platform_order_id = '';
          sp.logistics_infos = null;
          sp.platform_sku =
            sp.platform_sku == 'Default' ? '<none>' : sp.platform_sku;
          // if (sp.logistics_company == 'YANWEN_JYT') {
          //   sp.logistics_company = '';
          // }
        }
      });

      // 过滤掉other单
      item.sp = item.sp.filter((sp, index) => {
        return !otherSps.includes(index)
      });

      // base mapping
      if (item.supplier_map_type == 1) {
        const arr = [];
        // 过滤sku一样的订单
        item.sp = item.sp.filter(sp => {
          const key = `${sp.platform_product_id}+_+${sp.platform_sku}`;
          let flag = true;
          if (arr.includes(key)) {
            flag = false;
          } else {
            arr.push(key);
          }
          return flag;
        });
      }


      // pengding fulfillments 单子不能下单
      if (oneOrder.childrens[ii]?.isPendingFulfillments) {
        item.sp = [];
      }

      const hasOrderSp = item.sp.some(sp => sp.status === 2);
      // 有能下单的订单 把类型改成ae
      if (hasOrderSp) {
        item.platform_type = 2;
      }
    });

    const orderPlaceCount = localStorage.getItem('orderPlaceCount') * 1 || 0;
    // console.log(JSON.parse(JSON.stringify(oneOrder)));
    this.cashbackProcess(oneOrder); // add cashback check
    // console.log(JSON.parse(JSON.stringify(oneOrder)), 'check cash back -----')
    let placeApi = 'order/placeOld';
    if (this.isNewOrderResultApi) {
      placeApi = 'order/place';
    }

    CommonDataCollect({
      action: 'place_order',
      event_type:'place_order',
      event_label:'place',
      event_category:'order',
      place_order_id: oneOrder.id,
      place_order_ext: JSON.stringify({
        order_id: oneOrder.id,
        ext: oneOrder.ext,
        store_id: oneOrder.storeId,
        status: statusMap[status],
        is_plugin: type === 2 
      })
    });

    const { originalOldOrderMap } = this.props;
    const originalExt = originalOldOrderMap[oneOrder.id];

    const placeOrderFilteredExt = filterUpdateExtLineItems(supplyPlatform, oneOrder.ext, originalExt.ext);

    this.props.dispatch({
      type: placeApi,
      payload: {
        data: {
          order_id: oneOrder.id,
          ext: placeOrderFilteredExt,
          store_id: oneOrder.storeId,
          status: statusMap[status], // 在哪个tab下下单
          is_plugin: type === 2
        },
        callback: d => {
          // 过50点重置
          localStorage.setItem(
            'orderPlaceCount',
            orderPlaceCount > 50 ? 1 : orderPlaceCount + 1
          );

          // 后台返回的是重复单
          if (d.data && d.data.is_dup === true && d.msg === 'ok') {
            this.duplicateOrderArr.push(oneOrder.orderNumber);
          }
          // 插件下单
          
          if(type === 2 && d.data && d.data.is_success && !d.data.is_dup){
            placeOrderViaExt({ ...oneOrder, eventId: d.data.event_id, aeCaptchaSolveType: 2 }).then(res => {
              // console.log(res);
              this.props.getExtensionPlaceProcess();
              if (data.length > i + 1) {
                this.placeOrder(i + 1, type);
              } else {
                this.placeOrderOver(type);
              }
            });
          } else {
            if (data.length > i + 1) {
              this.placeOrder(i + 1, type);
            } else {
              this.placeOrderOver(type);
            }
          }
        }
      }
    });
  };

  // 下完单了
  placeOrderOver = (type) => {
    // 没有重复单自动关闭
    if (this.duplicateOrderArr.length == 0) {
      this.props.hidePlaceOrder(this.hasSubmitOrder);
      return;
    }

    // 有重复单让用户确认
    this.duplicateOrderDatas = this.placeOrderList.filter(order =>
      this.duplicateOrderArr.includes(order.orderNumber)
    );
    this.setState({
      showDuplicateOrderConfirm: true,
      dupOrderType: type
    });
  };

  // 进行cashback 检测和逻辑处理
  cashbackProcess = data => {
    const _this = this;
    // console.log(data, data.childrens.length, 'cash back inner')
    const orderPlaceCount = localStorage.getItem('orderPlaceCount') * 1 || 0;
    const code = localStorage.getItem('userCode') || _this.props?.userInfo?.code || '';
    if (data.isShopifyDelete) {
      return;
    }
    const cashbackProcessAEProdReportMap = {};
    // 如果订单商品数量大于1
    if (data.childrens.length) {
      data.childrens.forEach(item => {
        if (item.is_black_list === 2 || !item.sp) {
          return;
        }
        const extItem = data?.ext?.lineitems?.find(i => i.lineitem_id === item.id);
        // console.log(item.sp)
        // 50单调一次返点接口
        extItem.sp &&
        extItem.sp.forEach(sp => {
            // console.log(sp, sp.status, sp.platform_product_id);
            if (sp.platform_product_id && sp.status == 2) {
              const affiliateCache = getSessionStorage(sp.platform_product_id);
              if (!affiliateCache || affiliateCache?.code !== code) {
                createIFrameForCB(
                  _this.props.userInfo,
                  'splitorder',
                  data.storeId,
                  data.id,
                  sp.platform_product_id,
                  '100002',
                  this.props.admitadCheckCode,
                  this.props.localAEInfo?.affiliateStatus
                );
                const timestamp = +new Date();
                const cacheInfo = {
                  code,
                  ae_login_status: this.props.localAEInfo?.affiliateStatus,
                  timestamp,
                  store_id: data.storeId,
                  order_id: data.id,
                  ae_product_id: sp.platform_product_id,
                  code_type: this.props.userInfo?.code_type || 0,
                  code_dead_at: this.props.userInfo?.code_dead_at || '',
                  code_account: this.props.userInfo?.code_account || '',
                  local_aff_pid: this.props.localAEInfo?.localAffPid || '',
                };
                cashbackProcessAEProdReportMap[sp.platform_product_id] = cacheInfo;
                setSessionStorage(
                  sp.platform_product_id,
                  cacheInfo,
                  300 * 1000
                );
              } else {
                cashbackProcessAEProdReportMap[sp.platform_product_id] = affiliateCache;
              }
            }
          });
      });
    }
    try {
      const reportArr = Object.keys(cashbackProcessAEProdReportMap).map(i => cashbackProcessAEProdReportMap[i]);
      if(reportArr.length) {
        const userInfo = this.props.userInfo;
        DSERS_COLLECT({
          action: 'REPORT_AFFILIATE',
          custom_info: [
            { name: 'dsers_ext_ver', value: window.dsersExtVersion || '0.0.0' },
            { name: 'ae_login_status', value: `${this.props.localAEInfo?.affiliateStatus}` },
            { name: 'code', value: code },
            { name: 'local_aff_pid', value: `${this.props.localAEInfo?.localAffPid}` },
            { name: 'code_account', value: userInfo?.code_account || '' },
            { name: 'code_dead_at', value: userInfo?.code_dead_at || '' },
            { name: 'code_type', value: `${userInfo?.code_type || 0}` },
            { name: 'order_id', value: data?.id },
            { name: 'store_id', value: data?.storeId },
            { name: 'child_ae_products', value: reportArr.map(i => i.ae_product_id).join() },
            { name: 'child_affi_info', value: JSON.stringify(reportArr) }
          ]
        });
      }
    } catch (error) {
      console.log(error, 'cashback process report error');
    }
    
  };

  // 获取下单产品返点的url type 1:api下单 2:插件下单
  getRebates = (index, type) => {
    if (this.props.userInfo.code || localStorage.getItem('userCode')) {
      createIFrameForCB(
        this.props.userInfo,
        'placeorder',
        '',
        '',
        '',
        '100001',
        this.props.admitadCheckCode,
        this.props.localAEInfo?.affiliateStatus
      );
    }

    dispatchEventForGuide({ type: 'order', payload: { category: 'PLACE_ORDER_START' } });

    this.placeOrderList = cloneDeep(this.state.data);
    this.placeOrder(index, type);
  };

  getConfirmPrice = () => {
    const { data } = this.state;
    const { status, supplyPlatform } = this.props;
    let n = 0;

    data.forEach(item => {
      if (!item.checked || item.isShopifyDelete) {
        return;
      }
      item.childrens.forEach(child => {
        if (child.is_black_list === 2 || !child.sp) {
          return;
        }

        if (!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
          return;
        }

        child.sp.forEach(sp => {
          if (
            child.supplierMapType == 1 &&
            sp.is_default &&
            sp.status == status &&
            sp.logistics_company
          ) {
            n += sp.cost * sp.quantity;
            return;
          }
          if (
            child.supplierMapType > 1 &&
            sp.status == status &&
            sp.logistics_company
          ) {
            n += sp.cost * sp.quantity;
          }
        });
      });
    });

    return n;
  };

  getCost = () => {
    const { data } = this.state;
    const { status, supplyPlatform } = this.props;
    let n = 0;

    data.forEach(item => {
      // 没选中 || shopify 删除
      if (!item.checked || item.isShopifyDelete) {
        return;
      }
      item.childrens.forEach(child => {
        // 加黑
        if (child.is_black_list === 2 || !child.sp) {
          return;
        }

        if (!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
          return;
        }

        // pending fulfillments
        if (child.isPendingFulfillments) {
          return;
        }
        // shopify 取消
        if (
          child.canceledBy == 'shopify' &&
          status == 6 &&
          !child.AECanceled.includes(1)
        ) {
          return;
        }
        child.sp.forEach((sp, si) => {
          // 不是ae取消
          if (status == 6 && child.AECanceled[si] === 0) {
            return;
          }
          if (child.supplierMapType == 1) {
            const index = this.getWhichSpCanOrder(child, status);
            if (si === index && sp.status == status && sp.logistics_company) {
              n += sp.cost * sp.quantity;
              return;
            }
          }

          if (
            child.supplierMapType > 1 &&
            sp.status == status &&
            sp.logistics_company
          ) {
            n += sp.cost * sp.quantity;
          }
        });
      });
    });

    return n;
  };

  getFee = () => {
    const { data } = this.state;
    const { status, supplyPlatform } = this.props;
    let n = 0;

    data.forEach(item => {
      if (!item.checked || item.isShopifyDelete) {
        return;
      }
      item.childrens.forEach(child => {
        if (child.is_black_list === 2 || !child.sp) {
          return;
        }

        if (!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
          return;
        }

        if (
          child.canceledBy == 'shopify' &&
          status == 6 &&
          !child.AECanceled.includes(1)
        ) {
          return;
        }
        child.sp.forEach((sp, i) => {
          if (status == 6 && child.AECanceled[i] === 0) {
            return;
          }
          if (child.supplierMapType == 1) {
            const index = this.getWhichSpCanOrder(child, status);
            if (i == index) {
              if (sp.status == status && sp.logistics_company) {
                const cost =
                  sp.freight_cost == 'undefined' ? 0 : sp.freight_cost;
                n += cost * 1;
                return;
              }
            }
          }

          if (
            child.supplierMapType > 1 &&
            sp.status == status &&
            sp.logistics_company
          ) {
            n += sp.freight_cost * 1;
          }
        });
      });
    });

    return n;
  };

  // 获取下单子订单quantity
  getConfirmNum = () => {
    const { data } = this.state;
    const { status, supplyPlatform } = this.props;
    let n = 0;

    data.forEach(item => {
      if (!item.checked || item.isShopifyDelete) {
        return;
      }
      item.childrens.forEach(child => {
        if (child.is_black_list === 2 || !child.sp) {
          return;
        }

        if (!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
          return;
        }

        if (
          child.canceledBy == 'shopify' &&
          status == 6 &&
          !child.AECanceled.includes(1)
        ) {
          return;
        }
        child.sp.forEach((sp, si) => {
          if (status == 6 && child.AECanceled[si] === 0) {
            return;
          }
          if (child.supplierMapType == 1) {
            const index = this.getWhichSpCanOrder(child, status);
            if (si === index && sp.status == status && sp?.freights?.length > 0) {
              n += sp.quantity;
              return;
            }
          }
          if (
            child.supplierMapType > 1 &&
            sp.status == status &&
            sp?.freights?.length > 0
          ) {
            n += sp.quantity;
          }
        });
      });
    });

    console.log('n为', n, data)

    return n;
  };

  // 获取订单的income
  getInCome = () => {
    const { data } = this.state;
    const { status, supplyPlatform } = this.props;
    let count = 0;
    data.forEach(item => {
      if (!item.checked || item.isShopifyDelete) {
        return;
      }
      item.childrens.forEach(child => {
        if (child.is_black_list === 2 || !child.sp) {
          return;
        }

        if (!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
          return;
        }

        if (child.isPendingFulfillments) {
          return;
        }
        if (
          child.canceledBy == 'shopify' &&
          status == 6 &&
          !child.AECanceled.includes(1)
        ) {
          return;
        }
        let flag = false;
        child.sp.forEach((sp, si) => {
          if (status == 6 && child.AECanceled[si] === 0) {
            return;
          }
          if (child.supplierMapType == 1) {
            const index = this.getWhichSpCanOrder(child, status);
            if (si === index && sp.status == status && sp.logistics_company) {
              flag = true;
            }
          }
          if (
            child.supplierMapType > 1 &&
            sp.status == status &&
            sp.logistics_company
          ) {
            flag = true;
          }
        });
        if (flag) {
          count += child.count * 1;
        }
      });
    });

    return count;
  };

  readerConfirm = okLoading => {
    // 渲染下单表格
    const arr = [];
    const { status, storeCurrency, userInfo, supplyPlatform } = this.props; // status tab status awaiting order || canceled || failed order
    const { data, bannerData } = this.state;

    const cacheRenderBanner = [];
    const userId = userInfo?.user_id || '';
    const isV5 = list_V5.includes(userId);
    const isBannerUser = getVersion(userId);

    data.forEach((item, k) => {
      const { childrens, storeId, ext: { address = {}, lineitems = {} } } = item;
      let len = 0; // 一条大订单可下单的子订单数量 用来合并单元格
      let childStartIndex = 0; // 从第几个子订单可以下单
      let spStartIndex = 0; // 从第几个子子订单可以下单
      let flag = true;

      let currencyCode;
      let currencySymbol;
      let isNotUSD;
      let currencyRates;

      if (storeCurrency[storeId]) {
        currencyCode = storeCurrency[storeId].currency_code || 'USD';
        currencySymbol = currencySymbolMap[currencyCode];
        isNotUSD = currencyCode !== 'USD';
        currencyRates = storeCurrency[storeId].rates_value;
      }

      const countryCode = address.country_code;

      childrens.forEach((child, i) => {
        // 加黑商品不能下单
        if (child.is_black_list === 2 || !child.sp) {
          return;
        }

        if (!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
          return;
        }

        // shopify 取消商品不能下单
        if (
          child.canceledBy == 'shopify' &&
          status == 6 &&
          !child.AECanceled.includes(1)
        ) {
          return;
        }
        child.sp.forEach((sp, si) => {
          // base mapping
          if (child.supplierMapType == 1) {
            const index = this.getWhichSpCanOrder(child, status); // 主副供应商用哪个
            if (si == index) {
              if (sp.status == status) {
                len += 1;
                if (flag) {
                  childStartIndex = i; // 这条大订单从这个子订单开始下单
                  spStartIndex = si; // 这条子订单从这个子子订单开始下单
                  flag = false;
                }
                return;
              }
            }
          }

          // 不是base mapping 并且符合当前状态
          if (child.supplierMapType != 1 && sp.status == status) {
            // ae取消的才能下单
            if (child.AECanceled[si] !== 1 && status == 6) {
              return;
            }
            len += 1;
            if (flag) {
              childStartIndex = i; // 这条大订单从这个子订单开始下单
              spStartIndex = si; // 这条子订单从这个子子订单开始下单
              flag = false;
            }
          }
        });
      });

      childrens.map((child, i) => {
        // 加黑商品不能下单
        if (child.is_black_list === 2 || !child.sp) {
          return null;
        }

        if (!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
          return null;
        }

        // shopify 取消商品不能下单
        if (
          child.canceledBy == 'shopify' &&
          status == 6 &&
          !child.AECanceled.includes(1)
        ) {
          return null;
        }
        const childSp = child.sp;
        let startSpIndex;
        childSp.forEach((sp, s) => {
          const company = this.getTime(sp.freights || [], sp.logistics_company); // 选中的物流公司信息

          // base mapping 主副供应商用哪个
          if (child.supplierMapType == 1) {
            const index = this.getWhichSpCanOrder(child, status);
            if (s !== index) {
              return;
            }
          }

          // 订单状态不是当前tab的状态不渲染
          if (sp.status !== status) {
            return;
          }
          // ae取消的才能下单
          if (child.AECanceled[s] !== 1 && status == 6) {
            return;
          }

          if (startSpIndex === undefined) {
            startSpIndex = s;
          }
          arr.push(
            <tr key={`${child.lineitem_id}${k}${i}${s}`}>
              {len === 1 ? (
                <>
                  <td>
                    <Checkbox
                      className={orderStyles.confirmCheckbox}
                      defaultChecked={item.checked}
                      disabled={item.canOrderDisabled}
                      onChange={e => {
                        this.setConfirmChecked(e, k);
                      }}
                    />
                  </td>
                  <td>{item.orderNumber}</td>
                </>
              ) : i == childStartIndex && s == spStartIndex ? (
                <>
                  <td rowSpan={len}>
                    <Checkbox
                      className={orderStyles.confirmCheckbox}
                      defaultChecked={item.checked}
                      disabled={item.canOrderDisabled}
                      onChange={e => {
                        this.setConfirmChecked(e, k);
                      }}
                    />
                  </td>
                  <td rowSpan={len}>{item.orderNumber}</td>
                </>
              ) : null}
              <td>
                {!cacheRenderBanner.includes(child.shopifyProductId) &&
                bannerData[child.shopifyProductId] &&
                bannerData[child.shopifyProductId].store_id === child.storeId &&
                this.props.conversionFinish === false ? (
                  <RecommendTag
                    data={bannerData[child.shopifyProductId]}
                    title={isV5 ? 'up to 20% off' : intl.get('bargain.found_cheaper')}
                    showPoint
                    trickConfig={bannerTrickConfig}
                    onLinkClick={this.handleOpenModal}
                  />
                ) : null}
                <div className={orderProductsStyles.mapTypeWrap}>
                  {child.supplierMapType == 3 && sp.is_base != true ? (
                    <img
                      className={orderProductsStyles.mapTypeFlag}
                      src={bogo_flag}
                      title="BOGO"
                    />
                  ) : null}
                  {/* {child.supplierMapType == 4 ? (
                    <img
                      className={orderProductsStyles.mapTypeFlag}
                      src={bundle_flag}
                      title="Bundle"
                    />
                  ) : null} */}
                  <Popover
                    placement="leftTop"
                    trigger="click"
                    content={
                      <img
                        src={replaceUrlToHttps(sp.sku_img) || defaultImg}
                        style={{
                          width: '260px',
                          height: '260px',
                          objectFit: 'contain'
                        }}
                      />
                    }
                    overlayClassName={orderStyles.popover}
                  >
                    <img src={replaceUrlToHttps(sp.sku_img) || defaultImg} />
                  </Popover>
                </div>
              </td>
              <td>
                {`${item.currency} ${
                  s == startSpIndex && !!sp.platform_product_id
                    ? child.count
                      ? child.count
                      : 0
                    : 0
                }`}
              </td>
              <td>
                {`$ ${(sp.cost * sp.quantity).toFixed(2)} * ${sp.quantity}`}
                {isNotUSD ? (
                  <>
                    <br />
                    <span className={orderProductsStyles.currencyValue}>
                      {`(${currencySymbol} ${round(
                        sp.cost * sp.quantity * currencyRates,
                        2
                      )})`} * {sp.quantity}
                    </span>
                  </>
                ) : null}
              </td>
              <td>{item.ext.address.country}</td>
              <td className={orderStyles.confirmFreight}>
                <div className={orderStyles.productShippingTool}>
                  <button
                    className={className(orderStyles.customBtn, {
                      [orderStyles.showExpress]: sp.showExpress
                    })}
                    onClick={e => {
                      this.showConfirmProductEms(k, i, s);
                    }}
                    type="button"
                  >
                    <span
                      className={className({
                        // [orderStyles.shippingMethodTip]: !sp.logistics_company || company.company == undefined || company.serviceName == 'YANWEN_JYT',
                        [orderStyles.shippingMethodTip]:
                          !sp.logistics_company || company.company == undefined,
                        [orderStyles.disabledExpress]: sp.cost == 0
                      })}
                    >
                      {/* { company.company ? company.company : intl.get("order.confirm.table_title.shipping_method") } */}
                      {this.getShippingName(company)}
                    </span>
                    <img
                    style={{width:24,height:24,verticalAlign:'-webkit-baseline-middle',marginTop:5}}
                    src={require('../../assets/newImportList/icon_expand_more-24px.png')}
                    ></img>
                  </button>
                  {sp.showExpress ? (
                    <SelectShipp
                      freights={sp.freights || []}
                      freightDefault={sp.logistics_company}
                      onCancel={() => {
                        this.showConfirmProductEms(k, i, s);
                      }}
                      onOk={v => {
                        this.onOk(k, i, s, v);
                      }}
                      loading={okLoading}
                      countryCode={countryCode}
                    />
                  ) : null}
                </div>
              </td>
              <td>
                {sp.freight_cost == 0 || sp.freight_cost == 'undefined'
                  ? intl.get('order.order_product.shipping_cost_free')
                  : `$ ${(sp.freight_cost * 1).toFixed(2)}`}
                {isNotUSD && Number(sp.freight_cost) ? (
                  <>
                    <br />
                    <span className={orderProductsStyles.currencyValue}>
                      {`(${currencySymbol} ${round(
                        sp.freight_cost * currencyRates,
                        2
                      )})`}
                    </span>
                  </>
                ) : null}
              </td>
              <td>
                {!company.time
                  ? '--'
                  : `${company.time} ${intl.get(
                      'order.order_product.shipping_time'
                    )}`}
              </td>
            </tr>
          );

          if (
            bannerData[child.shopifyProductId] &&
            bannerData[child.shopifyProductId].store_id === child.storeId
          ) {
            cacheRenderBanner.push(child.shopifyProductId);
          }
        });
      });
    });


    console.log('arr',arr)

    return arr;
  };

  getShippingName = company => {
    let v = '';
    // if (company.serviceName == 'YANWEN_JYT') {
    //   v = intl.get('order.confirm.table_title.shipping_method');
    //   return v;
    // }
    return (
      company.company || intl.get('order.confirm.table_title.shipping_method')
    );
  };

  // 渲染确认下单按钮
  readerPurchaseBtn = confirmLoading => {
    const { data, placeOrderViaType } = this.state;
    const { status, userInfo, supplyPlatform } = this.props;
    // 插件下单入口开放用户
    const extPlaceTest = canUseExtPlace(userInfo.user_id);
    let flag = false; // false 表示可以下单
    let flag2 = true;
    let len = 0;
    let num = 0;

    data.forEach(item => {
      // 没选中 || 不能下单 || shopify 删除
      if (!item.checked || item.canOrderDisabled || item.isShopifyDelete) {
        return;
      }
      
      item.childrens.forEach(child => {
        // 商品加黑
        if (child.is_black_list === 2 || !child.sp) {
          return;
        }

        if (!supplyPlatformEquals(child.platform_type, supplyPlatform)) {
          return;
        }

        // shopify取消
        if (
          child.canceledBy == 'shopify' &&
          status == 6 &&
          !child.AECanceled.includes(1)
        ) {
          return;
        }
        child.sp.forEach((sp, i) => {
          if (child.supplierMapType == 1) {
            const index = this.getWhichSpCanOrder(child, status);
            if (i == index) {
              const company = this.getTime(
                sp.freights || [],
                sp.logistics_company
              );
              if (
                sp.status == status &&
                sp.logistics_company &&
                flag2 &&
                company.company
              ) {
                len++;
              }
              // 可下单但没选物流
              if (
                sp.status == status &&
                !sp.logistics_company &&
                sp.platform_product_id
              ) {
                flag = true;
                flag2 = false;
              }
              // if (sp.logistics_company == 'YANWEN_JYT' && sp.status == status ) {
              //   flag = true;
              //   flag2 = false;
              // }
            }
          }

          if (child.supplierMapType > 1 && sp.status == status) {
            const company = this.getTime(
              sp.freights || [],
              sp.logistics_company
            );
            if (sp.logistics_company && flag2 && company.company) {
              len++;
            }
            // 可下单但没选物流
            if (!sp.logistics_company && sp.platform_product_id) {
              flag = true;
              flag2 = false;
            }
            // if (sp.logistics_company == 'YANWEN_JYT') {
            //   flag = true;
            //   flag2 = false;
            // }
          }
        });
      });
    });

    // 展示重复但确认弹窗
    if (this.state.showDuplicateOrderConfirm) {
      return null;
    }

    if (this.hasSubmitOrder) {
      return (
        <Button disabled={flag} loading data="dx">
          {intl.get('public.confirm')}
        </Button>
      );
    }



    // disabled={this.state.extPlaceLoading || confirmLoading || flag} 
    return <div style={{display: 'flex', justifyContent: 'space-between'}}>
        {extPlaceTest?<Radio.Group style={{textAlign: 'left' }} 
        onChange={(e) => this.setState({ placeOrderViaType: e.target.value })} 
        name="place-via-type" 
        value={this.state.placeOrderViaType}>
          <Radio style={{display: 'block', marginBottom: 8}} value={1}>{intl.get('order.place_via_extension.order_via_api_opt')}</Radio>
          <Radio style={{display: 'block'}} value={2}>{intl.get('order.place_via_extension.order_via_ext_opt')} <Tag color="#F44436">New</Tag></Radio>
        </Radio.Group>: <div></div>}
        { console.log('!flag && !len', !flag && !len, flag) }
        {  this.renderSelectButton(confirmLoading, !flag && !len, flag) }
      </div>;
  };

  renderSelectButton = (confirmLoading, disableStatus, flag) => {

    if(flag){
      return this.renderDisableToolTipButton()
    }

    const { data, placeOrderViaType } = this.state;
    const { status, userInfo } = this.props;
    return <>
      {placeOrderViaType === 2?
          <Button
            data='dx'
            disabled={flag}
            loading={this.state.extPlaceLoading || confirmLoading}
            onClick={this.handleOrderViaExt}
            data-label="order-item-confirm-via-ext-submit"
          >
            {intl.get('public.confirm')}
          </Button>:
          <Button
            data="dx"
            disabled={flag}
            loading={confirmLoading}
            onClick={this.handleOrder}
            data-label="order-item-confirm-submit"
          >
            {intl.get('public.confirm')}
          </Button>}
    </>
  }

  renderDisableToolTipButton = () => {
    return <>
      <Tooltip title={intl.get('order.place_order_confirm_tooltip')}>
        <div className="disableButtonConfirm">{intl.get('public.confirm')}</div>
      </Tooltip>
    </>
  }

  handleOrder = debounce(() => {
    this.getRebates(0);
  }, 300);

  hidePlaceOrder = () => {
    this.props.hidePlaceOrder(this.hasSubmitOrder);
  };

  handleOrderViaExt = debounce(() => {
    if (!window.dsersExtVersion || window.dsersExtVersion < '2.8.48') {
      const type = !window.dsersExtVersion ? 1 : 2;
      this.setState({
        showInstallExt: true,
        installExtType: type,
      });
      // message.error('extension version is not support, dsers chrome extension 2.8.18 is required', 2);
      return;
    }
    if (this.state.extPlaceLoading) return;
    this.setState({
      extPlaceLoading: true
    });
    getAELoginStatus().then(async res => {
      if (res.data && res.data.code === 200) {
        const aliAccount = qs.parse(res.data.aeAccountId?.value || '').x_lid;
        const confirmed = 
          aliAccount === this.props.currentAeInfo?.user_nick || 
          await this.refs.aeAccountNotMatchConfirm.showConfirm();
        if(confirmed) {
          const checkRes = await getExtDsersLoginStatus();
          console.log(checkRes, '---------ext user info--------');
          if(checkRes.user_id === this.props.userInfo.user_id) {
            this.getRebates(0, 2);
          } else {
            this.setState({ showRefreshModal: true });
          }
        }
      } else if (res.data && res.data.code === 403){
        message.error('The AliExpress account which you are current logged in is disabled by AliExpress', 3)
      }
      this.setState({
        extPlaceLoading: false
      });
    }).catch(() => {
      this.setState({
        extPlaceLoading: false
      });
    });

  }, 300);

  handleCloseInstallExt = () => {
    this.setState({ showInstallExt: false });
  }

  resetData = () => {
    let { data } = this.state;
    const { supplyPlatform } = this.props;
    const editedChangePlatformItems = { 2: 0, 4: 0 };
    let bb =data.map(children => {
      return {
        ...children,
        childrens: children.childrens.map(item1 => {
          let counted = false;
          return {
            ...item1,
            sp: item1.sp.map(item2 => {
              // 统计edit改变了供应商类型，并且没有刷新过列表的item
              if (
                !counted &&
                supplyPlatform &&
                item2.actionEditChangeProduct &&
                item2.actionEditChangeProduct !== supplyPlatform
              ) {
                editedChangePlatformItems[item2.actionEditChangeProduct]++;
                counted = true;
              }
              return {
                ...item2,
                showExpress:false
              }
            })
          }
        })
      }
    })


    this.setState({
      data: bb,
      editedChangePlatformItems
    })

  }
  // edit订单后不保存直接点击下单 可能会缺失一些item 这里做出提示
  renderEditTips = (type = 0) => {
    const platformTextMap = {
      1: 'order.edit_order_confirm_tip_var.aliexpress',
      2: 'order.edit_order_confirm_tip_var.tmall',
      3: 'order.edit_order_confirm_tip_var.both'
    }

    const tabMap = {
      2: 'order.edit_order_confirm_tip_var.awaiting_order',
      6: 'order.edit_order_confirm_tip_var.canceled_order',
      7: 'order.edit_order_confirm_tip_var.failed_order',
    }

    const { editedChangePlatformItems } = this.state;
    const aeEdit = editedChangePlatformItems?.[2];
    const tmallEdit = editedChangePlatformItems?.[4];
    const { status } = this.props;
    const visible = !!(aeEdit || tmallEdit);
    let platform = 0;
    if(aeEdit && !tmallEdit) {
      platform = 1;
    } 
    if(!aeEdit && tmallEdit) {
      platform = 2;
    }
    if(tmallEdit && aeEdit) {
      platform = 3;
    }

    return visible ? (
      <DSersAlert
        visible
        type="warning"
        showIcon
        style={{ marginBottom: 16 }}
        description={intl.get(type === 0 ? 'order.edit_order_confirm_tip_1' : 'order.edit_order_confirm_tip_2', {
          platform: intl.get(platformTextMap[platform]),
          tab: intl.get(tabMap[status]),
        })}
      />
    ) : null;
  };

  // 是不是同一种货币
  getIsSameCurrency = () => {
    const { storeCurrency, supplyPlatform, status } = this.props;
    const { data } = this.state;
    const {
      userInfo: { stores }
    } = this.props;
    const arr = []; // 存储货币种类
    const arr2 = [];
    const arr3 = new Array(stores.length).fill(0); // 存储对应货币种类的income
    let storeId;
    let sameShopifyStore;


    data.forEach(item => {
      if (!item.checked) {
        return;
      }
      const oneStore = stores.find(store => store.id === item.storeId);

      if (!arr.includes(oneStore.currency)) {
        arr.push(oneStore.currency);
        sameShopifyStore = oneStore;
      }

      // let startSpIndex;

      // if (startSpIndex === undefined) {
      //   startSpIndex = s;
      // }

      // const z = s == startSpIndex && !!sp.platform_product_id
      // ? child.count
      //   ? child.count
      //   : 0
      // : 0;
      arr.forEach((i, index) => {
        if (i === oneStore.currency) {
          item.childrens.forEach(child => {

            if(!supplyPlatformEquals(child.platform_type, supplyPlatform)) return;

            child.sp.forEach((sp, q) => {
              // 不把bogo 计算在内
              // child.supplierMapType == 4
              if (!(q > 0) && sp.platform_product_id && sp.status === status) {
                arr3[index] += child.count * 1; // 满足条件将income计算在内
              }
            });
          });
        }
      });

      if (oneStore.ali_currency && !arr2.includes(oneStore.ali_currency)) {
        arr2.push(oneStore.ali_currency);
        storeId = oneStore.id;
      }
    });

    if (arr2.length === 1) {
      if (storeCurrency[storeId] && storeCurrency[storeId].currency_code) {
        this.currencyCode = storeCurrency[storeId].currency_code;
        this.currencySymbol = currencySymbolMap[this.currencyCode];
        this.currencyRates = storeCurrency[storeId].rates_value;
      }
    }

    if (arr.length === 1) {
      this.shopifyCurrencyCode = sameShopifyStore.currency;
    }


    return {
      currencyArr: arr,
      countArr: arr3,
      isSameShopifyCurrency: arr.length === 1,
      isSameAECurrency: arr2.length === 1
    };
  };

  render() {
    const {
      onClose,
      visible,
      hidePlaceOrder,
      okLoading,
      confirmLoading,
      status,
      storeCurrency,
      supplyPlatform
    } = this.props;

    const { showDuplicateOrderConfirm, editedChangePlatformItems } = this.state;
    const cost = this.getCost(); // 商品成本
    const fee = this.getFee(); // 物流成本
    const allCost = (cost + fee).toFixed(2); // 所有成本
    const allQuantity = this.getConfirmNum(); // 所有数量
    const inCome = this.getInCome(); // 所有收入

    const isSameCurrency = this.getIsSameCurrency();

    const tableRows = this.readerConfirm(okLoading);

    return (
      <>
        <Modal
          visible={visible}
          width={1000}
          footer={false}
          destroyOnClose={true}
          title={this.confirmPurchase == 1
            ? this.hasFaildOrder
              ? intl.get('order.confirm.title_fail')
              : intl.get('order.confirm.title_succ')
            : intl.get('order.confirm.title')}
          maskClosable={false}
          closable={!this.hasSubmitOrder}
          wrapClassName={orderProductsStyles.abcd}
          onCancel={() => {
            if (onClose) {
              onClose();
            }
            this.resetData()
            // 存在edit商品被隐藏时 无论下过单与否都刷新list
            hidePlaceOrder(
              this.hasSubmitOrder ||
                editedChangePlatformItems?.[2] ||
                editedChangePlatformItems?.[4]
            );
          }}
   
        >
          <div className={orderProductsStyles.abcd_tableBox}>
            {this.renderEditTips(tableRows?.length ? 1 : 0)}
            <table>
              <thead>
                <tr>
                  <th />
                  <th>{intl.get('order.confirm.table_title.order')}</th>
                  <th>{intl.get('order.confirm.table_title.product')}</th>
                  <th>{intl.get('order.confirm.table_title.income')}</th>
                  <th>{intl.get('order.confirm.table_title.product_cost')}</th>
                  <th>{intl.get('order.confirm.table_title.country')}</th>
                  <th>
                    {intl.get('order.confirm.table_title.shipping_method')}
                  </th>
                  <th>{intl.get('order.confirm.table_title.shipping_fee')}</th>
                  <th>{intl.get('order.confirm.table_title.shipping_time')}</th>
                </tr>
              </thead>
              <tbody>{tableRows}</tbody>
              <tfoot>
                <tr>
                  <td />
                  <td />
                  <td>
                   {allQuantity} {intl.get('order.confirm.item_new')}
                  </td>
                  <td>
                    {/* {`${
                      isSameCurrency.isSameShopifyCurrency
                        ? this.shopifyCurrencyCode
                        : null
                    } ${inCome.toFixed(2)}`} */}
                    <span>
                      {isSameCurrency.currencyArr.map((item, index) => (
                        <div key={index} style={{display:'inline-block'}}>
                          {item} {isSameCurrency.countArr[index].toFixed(2)}
                        </div>
                      ))}
                      <Tooltip
                        placement="top"
                        title={intl.get('order.confirm.income_tip')}
                      >
                        <Icon
                          type="question-circle"
                          theme="outlined"
                          style={{
                            marginLeft: 2,
                            transform: 'scale(.9)'
                          }}
                        />
                      </Tooltip>
                    </span>
                  </td>
                  <td>
                    {`$ ${cost.toFixed(2)}`}
                    {isSameCurrency.isSameAECurrency ? (
                      <>
                        <br />
                        <span className={orderProductsStyles.currencyValue}>
                          {`(${this.currencySymbol} ${round(
                            cost * this.currencyRates,
                            2
                          )})`}
                        </span>
                      </>
                    ) : null}
                  </td>
                  <td />
                  <td />
                  <td>
                    {`$ ${fee.toFixed(2)}`}
                    {isSameCurrency.isSameAECurrency ? (
                      <>
                        <br />
                        <span className={orderProductsStyles.currencyValue}>
                          {`(${this.currencySymbol} ${round(
                            fee * this.currencyRates,
                            2
                          )})`}
                        </span>
                      </>
                    ) : null}
                  </td>
                  <td>
                    {intl.get('order.confirm.total_cost')}:
                    <b>
                      {`$ ${allCost}`}
                      {isSameCurrency.isSameAECurrency ? (
                        <>
                          <span className={orderProductsStyles.currencyValue}>
                            {`(${this.currencySymbol} ${round(
                              allCost * this.currencyRates,
                              2
                            )})`}
                          </span>
                        </>
                      ) : null}
                    </b>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
          {this.showProgress ? (
            <Progress
              percent={this.progressNum}
              status={progressStatus}
              style={{
                margin: '11px 0 -14px'
              }}
            />
          ) : null}
          <div className={orderProductsStyles.abcd_btnBox}>
            {this.readerPurchaseBtn(confirmLoading)}
          </div>
        </Modal>
        <AsyncConfirm title={intl.get('order.place_via_extension.caution')} ref="aeAccountNotMatchConfirm">
          <div>{intl.get('order.place_via_extension.ae_account_not_match')}</div>
        </AsyncConfirm>

        <InstallExtension 
          visible={this.state.showInstallExt} 
          type={this.state.installExtType}
          handleCancel={this.handleCloseInstallExt}
          handlePlaceViaApi={() => { 
            this.handleOrder(); 
            this.handleCloseInstallExt() 
          }}
          
        />
        <Modal
          onCancel={() => this.setState({ showRefreshModal: false })}
          visible={this.state.showRefreshModal}
          footer={null}
          title="Chrome extension login status invalid"
        >
          <div style={{textAlign: 'center', marginBottom: 24}}>
            <Button type="primary" onClick={() => window.location.reload()}>
              Refresh extension login status
            </Button>
          </div>
        </Modal>
        {/* 检测到重复单提示用户确认 */}
        {showDuplicateOrderConfirm ? (
          <DuplicateOrderConfirm
            visible={showDuplicateOrderConfirm}
            data={this.duplicateOrderDatas}
            status={status}
            hidePlaceOrder={this.hidePlaceOrder}
            getExtensionPlaceProcess={this.props.getExtensionPlaceProcess}
            dupOrderType={this.state.dupOrderType}
            originalOldOrderMap={this.props.originalOldOrderMap}
            supplyPlatform={supplyPlatform}
          />
        ) : null}
      </>
    );
  }
}
