import whyDisconnect from './en/whyDisconnect';
import bargain from './en/bargain';
import reward from './en/reward';
import settingSupply from './en/setting-supply';
import common from './en/common';
import chore from './en/chore';
import home from './en/home';
import products from './en/products';
import productEdit from './en/productEdit';
import mapping from './en/mapping';
import order from './en/order';
import csv from './en/csv';
import notification from './en/notification';
import setting from './en/setting';
import linkStore from './en/linkStore';
import top from './en/top';
import pricing from './en/pricing';
import banner from './en/banner';
import about from './en/about';
import support from './en/support';
import guidance from './en/guidance';
import login from './en/login';
import register from './en/register';
import publicIntl from './en/public';
import fulfillmentCenter from './en/fulfillmentCenter';
import importList from './en/importList';
import paypal from './en/paypal';
import helpCenter from './en/helpCenter';
import bannerV2 from './en/bannerV2';
import supplierOptimizer from './en/supplierOptimizer';
import partner from './en/partner';
import tracking from './en/tracking';
import guide from './en/guide';
import migration from './en/migration';
import tmallTestExpress from './en/tmallTestExpress';
import beforeMigration from './en/beforeMigration';


export default {
  ...beforeMigration,
  ...publicIntl,
  ...home,
  ...products,
  ...productEdit,
  ...mapping,
  ...order,
  ...csv,
  ...notification,
  ...setting,
  ...top,
  ...linkStore,
  ...pricing,
  ...banner,
  ...about,
  ...support,
  ...guidance,
  ...login,
  ...register,
  ...importList,
  ...whyDisconnect,
  ...bargain,
  ...reward,
  ...chore,
  ...paypal,
  ...helpCenter,
  ...bannerV2,
  ...common,
  ...settingSupply,
  ...supplierOptimizer,
  ...partner,
  ...tracking,
  ...guide,
  ...migration,
  ...tmallTestExpress,
  rapport: {
    report: 'Sales Report',
    link: 'You do not have a store linked to DSers yet. Please',
    more: 'link a store.'
  },
  suppliers: {
    categary: 'All Categories',
    copyLink: 'Copy link',
    importBtn: 'Add to Import List',
    importBtnEd: 'Imported',
    optimizer: 'Supplier Optimizer'
  },
  impotList: {
    title: 'Import List'
  },
  combo: {
    headline: 'Manage subscription',
    explain:
      'Manage your subscription and set up a default billing store here . Click <a href="https://help.dsers.com/change-subscription-plan-for-shopify/" track-type="blog1" track-name="subscription" target="_blank" style=\'color:#3D5AFE\'> here </a> to check how  to manage subscription. And if you want to know more about DSers Plan, please click <a href="https://help.dsers.com/subscription-plans-presentation-for-shopify/" track-type="blog2" track-name="subscription" target="_blank" style=\'color:#3D5AFE\'> here </a>. If you want cancel subscription, please click <a target="_blank" style="color:#3D5AFE" href="mailto:support@dsers.com">here</a> to contact DSers support team.',
    change: 'Change plan',
    changEcard: 'Current plan:',
    changEcardBu: 'Change plan',
    changEcardUrl: 'How to cancel subscription for a paid plan?',
    changEcardservice: 'Contact',
    paymentcard: 'Default billing store',
    paymentcardEcard:
      'Please set a store as default billing store. If it is not set up, DSers account will be closed until you set it up.',
    paymentcardBu: 'Activate'
  },
  menu: {
    Switch: 'Switch platform',
    blog: 'BLOG',
    facebook: 'Facebook',
    youtube: 'YouTube',
    email_support: 'Email support'
  },
  duplicate_order_confirm: {
    dec1: 'We found that you already placed the orders',
    dec2:
      'to AliExpress previously. Do you still want to place them to AliExpress again? This may create duplicated orders.',
    input_confirm: 'Input CONFIRM to place the orders to AliExpress.'
  },
  new_feature: {
    csv_bulk_order: 'CSV Bulk Order',
    describe: 'For users not using Shopify service'
  },
  header: {
    about: 'ABOUT US',
    support: 'SUPPORT',
    blog: 'BLOG',
    sign_in: 'Sign in',
    sign_up: 'Sign up'
  },
  footer: {
    about: 'About us',
    support: 'Support',
    privacy_policy: 'Privacy Policy'
  },
  send_reset_email: {
    title: 'Reset Password',
    tip:
      'We will send a verification code to your email to verify your identity.',
    dec:
      'Enter the email address you used for regestration. If you installed DSers from Shopify, please enter your Shopify account email address If you forget your email, please ',
    contact_us: 'Contact us',
    placeholder: 'E-mail',
    email_instructions: 'Email instructions',
    send_succ: 'Instructions to reset your password have been emailed to you.',
    email_not_valid: 'The input is not valid E-mail!',
    email_empty: 'Please enter email!',
    email_unknown: 'Please input your log in email.'
  },
  reset_password: {
    title: 'Reset Password',
    input_old_password: 'Please input your Old password!',
    old_password: 'Old password',
    new_password: 'New password',
    input_confirm_password: 'Please confirm new password.',
    confirm_password: 'Confirm password',
    reset_password: 'Reset Password',
    wrong_password: 'Wrong password.Please confirm again.',
    astrict: 'Please enter 6 to 20 characters.',
    old_password_error: 'The original password is incorrect'
  },
  admitad: {
    //  add
    title: 'DSers Admitad is live!',
    p1: 'The official cooperation between DSers and Admitad is now online.',
    p2: 'Please check the following article to set everything up:',
    p3: 'https://help.dsers.com/admitad-cashback-for-shopify/',
    p4: 'Once done, get a new code in the AliExpress WW offer.',
    p5:
      'Change the code you are currently using, and replace with the new code.',
    p6:
      'Note: The codes that are not affiliated to DSers will be stopped next month'
  }, //  add
  fulfillmentCenter,
  product_pool: {
    title:
      '<i>5000+more</i> Home&Garden products handled by AliExpress are officially released, including antivirus mask. Guaranteed shipping within 24~48 hours.',
    title_long: `AliExpress officially provides products with a secure supply support.
                All items are handled by AliExpress directly and capable of shipping out within 24~48 hours.
                If you have anything relate those official products, customer service is available at dropshipping@list.alibaba-inc.com.
                DSers supply team is also willing to provide help at supply-support@dsers.com Thank you for all the customers asking,
                the situation in China is getting better every day.
                We hope your business will go well and wish you good luck for everything.`
  },
  market: {
    placeholder: 'Enter keywords to search',
    import_to_dsers: 'Import to DSers',
    import_tip_title: 'Please select stores to import products'
  },
  refresh_mask: {
    title: 'Status Updates',
    dec: 'Please refresh the page to update status.'
  },
  select_platform: {
    title: 'What platform do you want to access ?'
  },
  aeAccountDisconnect: {
    title:
      'Your DSers account is not linked to AliExpress anymore. This made the <i> order and product data disappear temporarily.</i> Data will be recovered after you link to a new AliExpress account.'
  },
  archeved: {
    title: 'Archived orders',
    blank_tips:
      'The order has been archived on Shopify. If you need to modify it, please go to Shopify to operate.',
    dec:
      'Orders fulfilled more than 4 months will be transfered to Archived order tab and you can still unarchive the order to do more actions. Please click <a href={href} target="_blank" >here</a> to check more.',
    customer: 'Customer',
    email: 'Email',
    unarchive: 'Unarchive',
    unarcheved_succ:
      'Order unarchived successfully, you can locate the order in the "Open" order tab now.',
    unarcheved_fail: 'Failed to unarchive the order, please try again later.',
    table_title_order: 'Order',
    date_title: 'Archived Date:',
    processed:`Orders that have not been processed for more than a year will automatically be placed in the Archived Orders menu. You can still un-archive orders to perform more operations.`
  },
  top_stores: {
    store_authorized_tip:
      'The store needs to be re-authorized. Please go to <a href="/app/settings?id=3">Setting - Stores</a> to update the authorization. Otherwise you can’t operate the store properly with DSers'
  },
  tutorial_over_modal: {
    dec:
      'Please go to Shopify - Setting - Check-out to double check if you are using the auto-fulfillment feature, if yes, please deactivate it and Save the new settings.',
    dec2:
      'Kindly be noted that Shopify auto-fulfillment feature will auto fulfill the orders, preventing you to place them to AliExpress. If you have this feature activated on Shopify, <i>you will not be able to process orders on DSers</i>.'
  },
  h5_callback: {
    bind_tip:
      'Are you sure you want to bind the Shopify store { shop_name } using the { bind_email } email address to the DSers account using the { current_email } email address?',
    login_tip: 'Please reconnect to the store',
    bing_auth: `Your authorization has expired, please refresh the page to reauthorize`,
    authAgin: `Your current authorization has expired, please click the button below to update the authorization and re-link`,
    auth_log: 'Log out',
    auth_entry: 'Reauthorize',
    bind_time_out:
      'Sorry, the store linking timed out, please click the button below to re-start the linking',
    relink: 'Relink',
    need_activate:
      'The store was previously hidden due to the plan limitation, please go to the subscription page to activate the corresponding store.',
    activate: 'Activation'
  },
  h5_landModal: {
    create_account: 'Create an account for you',
    register: 'Register yourself',
    existing_account: 'Already have an account?',
    customer_service: 'If you have any question, please',
    log_in: 'Log in',
    sign_up: 'sign up',
    overtime_con:
      'The request timed out, please return to your Shopify store and re-initiate the binding',
    contact_us: 'Contact us',
    return_shopify: 'Return to Shopify',
    welcome_dsers: 'Welcome To DSers',
    note_con:
      'Your Shopify email address has been used to create a DSers account login name. If this is your registered account, you can try to log in. If it is not your registered account, please register manually',
    welcome_con:
      'We will use your Shopify email address to generate a DSers account for you, the account name will be your Shopify registered email address',
    first_group:
      "<p style='font-size:36px;color:#000;font-weight:500'><span style='font-size:72px;color:#FF8F00'>\"5.0</span><br />Star Rating with\"<br /><br /><span style='font-size:72px;color:#FF8F00'>\"4600+</span><br /> Reviews on Shopify App Store\"</p>",
    second_group:
      '<p style=\'font-size:36px;color:#000;font-weight:500\'>"Place <span style=\'font-size:72px;color:#FF8F00\'>100</span>s<br />of orders to AliExpress<br />in 1 mins" <br /><br />"Manage multiple <br />stores in one account"</p>',
    third_group:
      '<p style=\'font-size:36px;color:#000;font-weight:500\'>"Find <span style=\'font-size:72px;color:#FF8F00\'>50+</span>similar <br />productds in one click"<br /><br />"Switch to better <br />supplier easily"</p>',
    fourth_group:
      '<p style=\'font-size:36px;color:#000;font-weight:500\'>"Bundle mapping <br />feature. Upsell never <br />be so easy" <br /><br />"Auto sync AliExpress <br />product stock to store"</p>',
    fifth_group:
      '<p style=\'font-size:36px;color:#000;font-weight:500\'>"Manage multiple suppliers with local warehouse to one store product"<br /><br />"Unlimited order processing for all plans"</p>',
    sixth_group:
      '<p style=\'font-size:36px;color:#000;font-weight:500\'>"Auto sync supplier price change"<br /><br />"24*7 customer service. Support your remarkable work"</p>',
    seventh_group:
      '<p style=\'font-size:36px;color:#000;font-weight:500\'>"Never miss a package tracking with Dsers"<br /><br />"High qualified suppliers recommended by DSers"</p>'
  },
  h5_redirect: {
    bind_tip:
      'Are you sure you want to bind to the DSers account using the { email } email address?'
  },
  error_cashback_code: {
    title: 'Your Admitad cashback is stopping.<br />Please check it out.',
    title_dec: 'We found your code is not under DSers.',
    p1: 'We found that you are using affiliate code under non-DSers',
    p2: 'channel. You can’t get cashback with the current code any more.',
    p3: 'Please click below to find what happened and how to deal with it.'
  },
  // 文案新加
  publicDocument: {
    scope: '',
    warn:
      'Our system detected that the password is too simple to crack. Please go to Setting-Account to reset password after log in.',
    newPass: 'Please enter new password.',
    safety: 'The password is not safe.'
  },
  ad_1688: {
    top_banner:
      'DSers Mass Supplier Program is live! Supported by the AliExpress, 1688 and DSers supply team. Get 10% to 30% discount on products!',
    learn_more: 'Learn more'
  },
  shop_push: {
    title: 'Contact to get 10%-30% discount on products!',
    dsers_price: 'DSers price:',
    current_price: 'Current price:',
    profit_margin: 'Profit margin:',
    supplier: 'Supplier:',
    dsers_program: 'DSers Mass Supplier Program',
    link_copied: 'Link copied!',
    copy_link: 'Copy product link to map',
    contact: 'Contact to get discount',
    what_dec: 'What is DSers Mass Supplier Program?',
    thank_you: 'Thank you for your request',
    fill_email: 'Please fill in your email <br /> to complete request.',
    fill_email_placeholder: 'Please fill in email',
    send_request: 'Send request',
    reach_you:
      'DSers and AliExpress Global Supply team will <br /> reach out to you soon.<br /> Have a nice day!'
  },
  complete_passWord: {
    title: 'Please register your account info',
    tip:
      'We found that your account info are not registered. <br /> Please set up your Email and Password',
    email_tip:
      'You can change the email later by going to DSers - Setting - Account menu.',
    fill_email: 'Please fill in a email address',
    password_tip: 'Please use password with at least 6 characters.',
    email_not_valid: 'The input is not valid E-mail!',
    password_request: 'Please set up a password',
    astrict: 'Please enter 6 to 20 characters.'
  },
  notice_plan_banner: `Our system detected that your DSers account is using the Basic plan, but
    the paid feature: Auto updates has been activated in your account now.
    Please kindly note that the paid feature: Auto updates will stay free
    until 1st Jan, 2021. After the free trial, please upgrade your plan to
    Advanced or Pro plan to continue using the relevant feature. Sorry for
    the inconvenience and misunderstanding caused. Thank you for staying
    with DSers, and happy dropshipping.`,
  onBoarding:{
    selectPlatfrom:"Select your Platform",
    selectPlatfromTitle:'Please select the platform of the store you want to link.',
    linkShopify:'Link a Shopify store',
    linkWoo:'Link a WooCommerce store',
    linkWix:'Link a Wix store',
    linkCsv:'Use CSV files from other platforms',
    logout:'Log out',
    linkAeTitle:'Link your Supplier'
  }
};
