
    export default 
    {
    "paypal": {
        "auto_sync_track_to_paypal": "Sincronizza automaticamente i numeri di tracciamento su PayPal! Vai su Impostazioni-Tracciamento per controllare.",
        "paypal_authorization_title": "Autorizzazione PayPal",
        "paypal_authorization_content": "Il sistema ha rilevato che il tuo account autorizzato PayPal non è lo stesso che hai connesso a Shopify. Questo porterà il Numero di Tracciamento del tuo ordine a non riuscire a sincronizzarsi su PayPal. Si prega di modificarlo. Se lo si è già modificato, si prega di ignorare questo promemoria, clicca qui sotto per non vederlo più",
        "paypal_change": "vai a Cambia",
        "disimiss": "Ignora",
        "paypal_account": "Riguardo al tuo account PayPal",
        "paypal_reminder_content": "Il sistema ha rilevato che l'autorizzazione del tuo account PayPal associato è scaduta. Per godere di un'esperienza migliore, si prega di riautorizzare il tuo account PayPal.",
        "no_reminder": "Non ricordarmelo",
        "renew": "Vai a Riautorizza",
        "cancel": "Annulla"
    }
}