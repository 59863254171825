import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import Cookies from 'js-cookie';
import intl from 'react-intl-universal';

import styles from './AccountError.less'

export default function AccountError() {

  const [accountType, setAccountType] = useState('The incitation link has expired');
  const [childStatus, setChildStatus] = useState()

  const dispatch = useDispatch();
  const statusmap = {
    '1': 'UserFrozen',
    '2': 'The invitation link has expired',
    '3': 'The account has accepted the invitation',
    '4': 'The account has been registered as the main account',
    '5': 'The account has been disabled'
  }

  useEffect(() => {
    setChildStatus(getType())
  }, [])


  function getType() {
    var url = location.search
    if (url.indexOf("?") != -1) {
      var str = url.substr(1);
      var type = str.split('=')[1]
    }
    return type;
  }

  const RenderText = () => {
    return statusmap[childStatus]
  }

  const GoShopify = () => {
    window.location.href = 'https://www.dsers.com/'
  }

  return (
    <div className={styles.body}>
      <div className={styles.container}>
        <div>
          <p className={styles.text}>
            {RenderText()}
          </p>
          <div >
            <Button onClick={GoShopify} className={styles.btn}>{intl.get('public.gotodsers')}</Button>
          </div>
        </div>
      </div>
    </div>
  )
}
