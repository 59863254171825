import React from 'react';
import styles from './Banner.less';
import intl from 'react-intl-universal';

import { useDispatch } from 'react-redux';
import Ellipse1 from 'assets/banner/supplier/Ellipse1.png';
import Ellipse2 from 'assets/banner/supplier/Ellipse2.png';
import Ellipse3 from 'assets/banner/supplier/Ellipse3.png';
import Ellipse4 from 'assets/banner/supplier/Ellipse4.png';
import Ellipse5 from 'assets/banner/supplier/Ellipse5.png';
import Group from 'assets/banner/supplier/Group.png';

import { CommonDataCollect } from 'utils/utils';



const TopBanner = () => {
  return (
    <>
      <div className={styles.bBox}>
        <div className={styles.bannerBox}>
          <img src={Ellipse1} alt="" className={styles.Ellipse1} />
          <img src={Ellipse2} alt="" className={styles.Ellipse2} />
          <img src={Ellipse3} alt="" className={styles.Ellipse3} />
          <img src={Ellipse4} alt="" className={styles.Ellipse4} />
          <img src={Ellipse5} alt="" className={styles.Ellipse5} />
          <img src={Group} alt="" className={styles.Group} />
          {/* <div className={styles.bannerTitle}>
            Don't worry, DSers is here to stay!
          </div> */}
          <div className={styles.bannerText}>
            The system is being upgraded. During the upgrade, data will
            temporarily not be updated.
            <br />
            If you have any questions, please contact{' '}
            <span className={styles.mailbox}>jean-baptiste@dserspro.com</span>
            <br />
          </div>
        </div>
        <div className={styles.b20}>
          {''}
        </div>
      </div>
    </>
  );
};


export default TopBanner;
