import { useEffect, useRef, useMemo, useCallback } from 'react';
import debounce from 'lodash/debounce';

export function useScrollEvent(callback) {
    const callbackRef = useRef(callback);
    useEffect(() => {
        const eventCallback = (e) => {
            if(typeof callbackRef.current === 'function') {
                callbackRef.current(e)
            }
        }
        window.addEventListener('scroll', eventCallback, true);

        return () => {
            window.removeEventListener('scroll', eventCallback, true);
        }

    }, []);
    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);
}

export function useCountViewDeep(selector, reportCallback) {
    const maxScrollDeepRef = useRef(0);
    const initedRef = useRef(false);
    const detectDomRef = useRef(document.querySelector(selector));

    const reportCallbackRef = useRef(reportCallback);

    const innerReportCallback = useMemo(() => {
        return debounce((data) => {
            if(typeof reportCallbackRef.current === 'function') {
                reportCallbackRef.current(data);
            } 
        }, 1500, {trailing: true, leading: false});
    }, []);

    useEffect(() => {
        reportCallbackRef.current = reportCallback;
    }, [reportCallback]);

    const eventCallback = useCallback((e) => {
        // console.log(e);
        if(!initedRef.current) {
            return;
        }
        if(!detectDomRef.current) {
            detectDomRef.current = document.querySelector(selector);
        }
        if(!detectDomRef.current) {
            return;
        }
        // console.log(detectDomRef.current, e);
        const percent = (detectDomRef.current.scrollTop + detectDomRef.current.clientHeight) / detectDomRef.current.scrollHeight
        if(percent && maxScrollDeepRef.current < percent) {
            maxScrollDeepRef.current = percent;
            innerReportCallback(percent);
        }
    }, []);

    useScrollEvent(eventCallback);

    const initCount = useCallback(() => {
        if(!initedRef.current) {
            initedRef.current = true;
            eventCallback();
        }
    }, []);

    return initCount;
}
