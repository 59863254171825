import React from 'react';
import ReactDOM from 'react-dom';
import intl from 'react-intl-universal';
import { Button, Icon, Input } from 'antd';
import { routerRedux } from 'dva/router';
import { connect } from 'dva';

import styles from './SupplierOptimizer.less';
import useOnlineNotice from '../../hooks/useOnlineNotice';
import { getDisplaySupplierOptimizer } from 'common/checkUserForSupplierOptimizer';

// import store from '../../index';

// const { dispatch } = store;

const onlineDate = new Date(2020, 10, 19); // 上线日期

const modalRoot = document.getElementById('root');

function SupplierOptmizerOnline({ onClose, lang, dispatch }) {
  const [visible, closeNotice] = useOnlineNotice(
    'supplier_optimizer_online',
    onlineDate
  );

  const hasAccess = getDisplaySupplierOptimizer();
  // console.log(visible)
  function handleClose() {
    closeNotice();
    dispatch(
      routerRedux.push(
        '/supplier_optimizer?url=https://www.aliexpress.com/item/4000131634003.html&cancelSearch=true'
      )
    );
  }

  function handleInnerClick(e) {
    e.stopPropagation();
  }
  // console.log(lang, '--------------')
  function handleOk() {
    const url =
      lang === 'fr-FR'
        ? 'https://help.dsers.com/supplier-optimizer-for-shopify/'
        : 'https://help.dsers.com/supplier-optimizer-for-shopify/';
    window.open(url, '_blank');
    closeNotice();
  }

  if (!visible || !hasAccess) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.notice} role="presentation" onClick={handleClose}>
      <div
        className={styles.body}
        role="presentation"
        onClick={handleInnerClick}
      >
        {lang === 'fr-FR' ? (
          <div className={styles.innerBox}>
            <div className={styles.title}>
              "Trouver des fournisseurs similaires" est en ligne!
            </div>
            <div className={styles.input}>
              <Input.Search
                placeholder={intl.get('supplier_optimizer.title')}
                enterButton
                addonBefore={
                  <Icon className={styles.cameraIcon} type="camera" />
                }
              />
            </div>
            <div className={styles.textBox}>
              Inquiet de la fiabilité des vos fournisseurs? Nous avons la solution pour vous!
            </div>
            <div className={styles.textBox}>
              Nous pouvons chercher, pour vous, parmi les milliers de fournisseurs sur AliExpress pour remplacer et optimiser ceux de vos produits!
            </div>
            <div className={styles.buttons}>
              <Button onClick={handleOk}>En savoir plus</Button>
              <Button onClick={handleClose} type="primary">Montrez-moi</Button>
            </div>
          </div>
        ) : (
          <div className={styles.innerBox}>
            <div className={styles.title}>"Find similar supplier" is online!</div>
            <div className={styles.input}>
              <Input.Search
                placeholder={intl.get('supplier_optimizer.title')}
                enterButton
                addonBefore={
                  <Icon className={styles.cameraIcon} type="camera" />
                }
              />
            </div>
            <div className={styles.textBox}>
              Still worried about unstable suppliers? We have the solution for you!
            </div>
            <div className={styles.textBox}>
              We can help you to search through thousands of suppliers on AliExpress to replace and optimize your existing ones!
            </div>
            <div className={styles.buttons}>
              <Button onClick={handleOk}>Learn more</Button>
              <Button onClick={handleClose} type="primary">Have a look</Button>
            </div>
          </div>
        )}
      </div>
    </div>,
    modalRoot
  );
}

export default connect()(SupplierOptmizerOnline);
