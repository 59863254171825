import React from 'react';
import classname from 'classnames';
import intl from 'react-intl-universal';
import { Icon, Tooltip } from 'antd';

import styles from './MakePayMentComponents.less';

/**
 * 
 * @param {number} status 支付状态  1: 支付中  2:支付成功 3：支付失败;
 * @param {func} checkDetailHandle 查看详情回调
 * @param {string} paymentLink paypal跳转链接
 * @param {string} time 提示时间
 * @returns 
 */

let StatusMap = {
  1: 'loading',
  2: 'success',
  3: 'error'
}

let errorMap = {
  1: 'cancel',
  2: 'timeout',
  3: 'unknow'
}

export const MakePayMentComponents = ({ status = 1, paymentLink = '', checkDetailHandle = () => { }, time = '', handleClose = () => { }, paymentId = '', className, error }) => {

  let icon = `https://img.dsers.com/shopify/order/makepay/makepay-${StatusMap[status]}.png`;

  let clsStyle = classname({
    [styles.MakePayMentLoading]: true,
    [styles.MakePayMentSuccess]: status == 2 ? true : false,
    [styles.MakePayMentError]: status == 3 ? true : false,
  }, className);

  return <div className={clsStyle}>
    <img src={icon}></img>
    <p className={styles.descWrap}>{intl.getHTML(`order.orderPayment.${StatusMap[status]}`, {
      time
    })}</p>
    {
      status === 1 ?
        <Tooltip title={<div>{intl.get('order.orderPayment.pendingTip')}</div>}>
          <Icon type="question-circle" />
        </Tooltip> : null
    }
    <p className={styles.clickDetails}>
      <a onClick={() => checkDetailHandle(paymentId)}>{intl.get('order.orderPayment.checkDetail')}</a>
      {status == 2 || status == 3 ? null : <a href={paymentLink} target="_blank">{intl.get('order.orderPayment.link')}</a>}
    </p>
    {
      status === 3 ?
        <p>{intl.get(`order.orderPayment.reason`)} <Tooltip title={<span>{intl.getHTML(`order.orderPayment.${errorMap[error]}`)}</span>}>
          <Icon type="question-circle" />
        </Tooltip> </p> : null
    }
    {
      status !== 1 ? <Icon type="close" onClick={() => handleClose(paymentId)}/> : null
    }
  </div>
}