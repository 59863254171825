import React, { PureComponent } from 'react';
import { Layout, Menu, Icon, Button, Tooltip, Popover,Typography } from 'antd';
import { connect, fetch } from 'dva';
import { Link } from 'dva/router';
import pathToRegexp from 'path-to-regexp';
import className from 'classnames';
import intl from 'react-intl-universal';
import { isFreshEntrust } from 'utils/constants';
import getAdminRole from 'utils/getAdminRole';
import { MENU_POSITION } from 'common/menu';
import logo from 'assets/svg/new_logo.png';
import whiteLogo from 'assets/svg/whiteLogo.png';
import { urlToList } from '../_utils/pathTools';
import { getLanguage, skipShopfiy } from '../../utils/utils';
import reactGaEvent from '../../utils/GAEvent';
import { wooDomain } from '../../common/sysconfig';
import styles from './index.less';
import './index.css';
import youtube from '../../assets/svg/directory.svg';
import _ from 'lodash';

import Cookie from 'js-cookie';

import { webCustomCollect, CommonDataCollect } from 'utils/utils';
import { menuJurisdiction } from 'utils/trackingPackageRouting';
import { Get, Post } from 'utils/request';
import { GuildeAction, LinkStoreAction } from 'actions'
import SelectLanguage from 'components/GlobalHeader/SelectLanguage'
import ExtraHeader from 'components/ExtraHeader';
import logoCut from 'assets/img/christmas/dsersCut.png';

const { Sider } = Layout;
const { SubMenu } = Menu;

// Allow menu.js config icon as string or ReactNode
//   icon: 'setting',
//   icon: 'http://demo.com/icon.png',
//   icon: <Icon type="setting" />,

const getIcon = icon => {
  if (typeof icon === 'string' && icon.indexOf('http') === 0) {
    return (
      <img
        src={icon}
        alt="icon"
        className={`${styles.icon} sider-menu-item-img`}
      />
    );
  }
  if (icon === 'youtube') {
    // return <img style={{ width: '24px', height: '24px', marginRight: '24px', position: 'relative', top: '2px' }} src={youtube} />
    return (
      <Icon
        theme="filled"
        style={{ fontSize: '24px', marginRight: '25px', color: '#757778' }}
        type="youtube"
      />
    );
  }
  if (typeof icon === 'string') {
    return <i className="material-icons notranslate">{icon}</i>;
  }
  return icon;
};

const menusMap = {
  '/report': 1,
  '/bind/link-store': 7,
  '/storemanage/import': 2,
  '/storemanage/myproduct': 3,
  '/order/list': 4,
  '/order/archived': 6,
  '/tracking': 11,
  '/csv': 5,
  '/settings': 8,
  '//select': 10
};

export const getMeunMatcheys = (flatMenuKeys, path) => {
  return flatMenuKeys.filter(item => {
    return pathToRegexp(item).test(path);
  });
};

const getLocalStorageTimeLimit = (key) => {
  try {
    return parseInt(localStorage.getItem(key) || '0');
  } catch (error) {
    return 0;
  }

}

const checkLocalStorageTimeLimit = (key, duration) => {
  let value = getLocalStorageTimeLimit(key);
  // console.log(value, 'check time')
  const newDate = +new Date();
  return value && (newDate - value) < duration;
}

const setLocalStorageTimeLimit = (key, timestamp) => {
  localStorage.setItem(key, timestamp);
}

@connect(({ login, global, user, auth, topBtn }) => ({
  userInfo: login.userInfo,
  plan: user.plan,
  homeUnreadMessage: global.homeUnreadMessage,
  newUserInfo: user.newUserInfo,
  isOberlo: auth?.isOberloUser,
  selectStoreIsDisabled: global.selectStoreIsDisabled,
  messageCountData: topBtn.messageCountData,
  appTotal: global.appTotal,
  productLimitStatus: topBtn.productLimit,
  openOrdersMenuTooltip: global.openOrdersMenuTooltip,
}))
export default class SiderMenu extends PureComponent {
  constructor(props) {
    super(props);

    this.menus = props.menuData;
    this.flatMenuKeys = this.getFlatMenuKeys(props.menuData);
    this.state = {
      openKeys: this.getDefaultCollapsedSubMenus(props),
      isSwitch: true,
      awaitingOrderNumber: 0,
      // isNewUi:Cookie.get('_ds_ver')==2 ? true :false
    };

    this.hasHelpCenterAccess = window.DSERS_H_C || getAdminRole();
    this.hasGuideAccess = window.DSERS_GUIDE || getAdminRole();
  }

  componentDidMount() {
    this.getUaaUserInfo();
    fetch(`${window.DSERS_API_CONFIG.HOST}/api/v1/users/info`, {
      method: 'get',
      headers: {
        Authorization: localStorage.getItem('dser-pro-authority')
      }
    })
      .then(response => {
        return response.json();
      })
      .then(stories => {
        if (stories.code == 2000) {
          const { data } = stories;

          console.log('👀 👀 👀', data);

          //如果注册时间大于当前定义的时间，就不展示切换按钮
          // 2021-09-06 11:00:00 ->  1630897200

          if (data.created_at > 1630897200) {
            this.setState({
              isSwitch: false
            });
          }
          if (
            `${data.status}`.length == '8' &&
            `${data.status}`.substring(3, 4) == 0 &&
            data.stores.length == 0 &&
            data.email_status !== 0
          ) {
            if (
              data?.woo_stores?.length !== 0 &&
              `${data.status}`.substring(2, 3) == 1
            ) {
              return;
            }
            window.location.href = '/app/bind/platform';
          }
        }
      });
    if (window.CAINIAO_USER && !getLocalStorageTimeLimit('agency_menu_new_feature_tip')) {
      setLocalStorageTimeLimit('agency_menu_new_feature_tip', +new Date());
    }
    if (window.TMALL_USER && !getLocalStorageTimeLimit('tmall_menu_new_feature_tip')) {
      setLocalStorageTimeLimit('tmall_menu_new_feature_tip', +new Date());
    }
    Post('/ord/count', {
      headers: {
        DSID:""
      },
      data: {
        last: '',
        keywords: '',
        search_type: 1,
        start_at: parseInt(new Date().getTime() / 1000) - 86400 * 30,
        end_at: parseInt(new Date().getTime() / 1000),
        status: 2,
        fulfilled: 0,
        flags: [],
        source: 0,
        canceled: 0,
        sort: 0,
        skip: '',
        sel_fields: [
          '_id',
          'detail',
          'ext',
          'shopify_created_at',
          'store_id'
        ],
        key: '',
        country: '',
        untimeliness: true
      }
    }).then(({ data: { awaiting_order: awaitingOrderNumber } }) => this.setState({ awaitingOrderNumber }))
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.setState({
        openKeys: this.getDefaultCollapsedSubMenus(nextProps)
      });
    }
  }

  handleMenuClick = (e) => {
    console.log(e);
    if (e.key === '/order/agency/list') {
      CommonDataCollect({
        event_type: 'agency_order_cold_startup',
        action: 'agency_order_cold_startup_agency_menu_click'
      })
    }
  }

  componentDidUpdate() {
    this.getUaaUserInfo();
  }

  getUaaUserInfo = async () => {
    if (this.props.newUserInfo?.user_role === 2) {
      //如果是limit子账户 则从菜单表中删除没有权限的菜单
      _.remove(this.menus, item => {
        if (
          [
            '/report',
            '/bind/link-store',
            '/storemanage/import',
            '/storemanage/myproduct',
            '/order/list',
            '/order/archived',
            '/tracking',
            '/csv',
            '/settings',
            '//select'
          ].includes(item.path)
        ) {
          return !this.props.newUserInfo?.menu_permissions.includes(
            menusMap[item.path]
          );
        }
      });
    } else if (this.props.newUserInfo?.user_role === 1) {
      _.remove(this.menus, item => {
        return item.path === '//select';
      });
    } else if (
      this?.props?.userInfo?.status?.toString()?.substring(3, 4) === '1'
    ) {
      _.remove(this.menus, item => {
        return item.path === '/tracking';
      });
    }
  };

  /**
   * Convert pathname to openKeys
   * /list/search/articles = > ['list','/list/search']
   * @param  props
   */
  getDefaultCollapsedSubMenus(props) {
    const {
      location: { pathname }
    } = props || this.props;
    // 默认展开order二级menu
    const { openKeys } = (this.state || {});
    const orderExsits = openKeys ? openKeys?.includes('/order') : true;
    const res = urlToList(pathname).map(item => {
      return getMeunMatcheys(this.flatMenuKeys, item)[0];
    });
    if (orderExsits && !res.includes('/order')) {
      res.push('/order');
    }
    return res;
  }

  /**
   * Recursively flatten the data
   * [{path:string},{path:string}] => {path,path2}
   * @param  menus
   */
  getFlatMenuKeys(menus) {
    let keys = [];
    menus.forEach(item => {
      if (item.children) {
        keys = keys.concat(this.getFlatMenuKeys(item.children));
      }
      keys.push(item.path);
    });
    return keys;
  }

  menuGA = e => {
    const {
      target: {
        dataset: { ga }
      }
    } = e;
    ga &&
      ga == 'FAQ' &&
      reactGaEvent({
        category: 'leftSideMenu',
        action: ga
      });
  };

  handleLinkStore = async () => {
    const res = await Get('/auth/shopify/bind_quota', {})
    if (res.code == 2000 || res.code == 2010) {
      if (res.msg == "bind store quota err") {
        localStorage.setItem('passivity', 'true');
        CommonDataCollect({
          action: 'more store-top',
          event_type: 'overlimit_pop'
        });
        //  跳转订阅 && 弹窗拦截
        // window.EVENT.emit(GuildeAction.TurnPlan_Pop, `more store&${Date.now()}`, true);
        window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'MultiStore')
        return
      }

      //  const { userInfo, plan } = this.props;

      //  if([1,10, 4, 8,12,13,14,15,16,17].includes(plan?.type) && userInfo.stores.length === 3){
      //   window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'MultiStore')
      //   return
      //  }
      //  if([2,7].includes(plan?.type) && userInfo.stores.length === 10){
      //   window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'MultiStore')
      //   return
      //  }
      //  if([3].includes(plan?.type) && userInfo.stores.length === 25){
      //   window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'MultiStore')
      //   return
      //  }
      //  if([6,11].includes(plan?.type) && userInfo.stores.length === 50){
      //   window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'MultiStore')
      //   return
      //  }

      window.EVENT.emit(LinkStoreAction.REFRESH_PAGE, true)
      window.open("https://apps.shopify.com/dsers", "_blank");
    }
  }

  /**
   * 判断是否是http链接.返回 Link 或 a
   * Judge whether it is http link.return a or Link
   * @memberof SiderMenu
   */
  getMenuItemPath = item => {
    const { userInfo, collapsed, homeUnreadMessage, appTotal } = this.props;
    const itemPath = this.conversionPath(item.path);
    const icon = getIcon(item.icon);
    const { target, name, badge } = item;
    // 检测店铺跳转选择平台
    if (item.path == '/SwitchPlatform') {
      const { type = 0 } = JSON.parse(localStorage.getItem('payment')) || {};
      return type == '3' ? (
        <a role="button" tab-index="0" onClick={() => skipShopfiy()}>
          {icon}
          <span>{name}</span>
        </a>
      ) : null;
    }

    // Is it a http link
    if (/^(https?:\/\/|mailto:)/.test(itemPath)) {
      return (
        <a href={itemPath} target={target} onClick={this.menuGA} data-ga={name}>
          {icon}
          <span>{name}</span>
        </a>
      );
    }

    //link to more stores 菜单项 单独处理
    if (item.path == '/bind/link-store') {
      return (
        <Link
          onClick={this.handleLinkStore}
        >
          {icon}
          <span>{name}</span>
        </Link>
      )
    }

    const agencyFeatureTip = checkLocalStorageTimeLimit('agency_menu_new_feature_tip', 1000 * 60 * 60 * 24 * 3);
    const tmallFeatureTip = checkLocalStorageTimeLimit('tmall_menu_new_feature_tip', 1000 * 60 * 60 * 24 * 3)

    const badgeNumber = appTotal[badge]; // 'unRead'
    const tmp = window.localStorage.getItem('menu_trigger');
    const hasSupply = item.path === '/settings' && userInfo.product_agent;
    return (
      <Link
        to={itemPath}
        target={target}
        replace={itemPath === this.props.location.pathname}
        onClick={() => {
          this.getTurnUrlParam(itemPath);
          if (hasSupply && !tmp && isFreshEntrust) {
            window.localStorage.setItem('menu_trigger', Date.now());
          }
          if (this.props.isMobile) {
            this.props.onCollapse(true);
          }
          if (itemPath === '/select') {
            CommonDataCollect({
              event_type: 'subscription_analysis',
              action: 'browse_manage_subscription'
            });
          }
        }}
        data-link={item.path}
      >
        {/* 如果是tracking 不走icon */}
        {
          item.path == '/tracking' ? <img width="30px" style={{ marginRight: 24, display: 'inline-block', marginBottom: 8 }} src="https://img.dsers.com/img/tracking2.gif"></img> : icon
        }

        <span
          style={{
            color: this.props.location.pathname === itemPath ? '#ff8f00' : null
          }}
        >
          {name}
        </span>
        {[
          'Notification',
          'Open',
          'Archived',
          'AEOpen',
          'TmallOpen',
          'AgencyOpen',
          'UnmappedOpen'
        ].includes(badge) && !collapsed ? (
          <b
            id={`leftSideMenu${badge}Badge`}
            className={className(styles.leftSideMenuNotification, {
              [styles.leftSideMenuNotificationShow]: badgeNumber > 0
            })}
          >
            {badgeNumber > 0 ? badgeNumber : null}
          </b>
        ) : null}
        {'AgencyOpen' === badge && agencyFeatureTip ? (
          <i><img style={{ marginLeft: 4 }} width='40' height="40" src={'https://img.dsers.com/shopify/activity/agency_cold_startup/221017/agency_new_feature_icon.gif'} /></i>
        ) : null}
        {'TmallOpen' === badge && tmallFeatureTip ? (
          <i><img style={{ marginLeft: 4 }} width='40' height="40" src={'https://img.dsers.com/shopify/activity/agency_cold_startup/221017/agency_new_feature_icon.gif'} /></i>
        ) : null}
        {/* {homeUnreadMessage && item.path === '/home' && !collapsed ? (
          <i className={styles.newFeature}>{intl.get('home.new')}</i>
        ) : null} */}
        {/* 下掉下面这个new 把上面的打开 */}
        {item.path === '/home' ? (
          <b className={styles.newFeature}>{intl.get('home.new')}</b>
        ) : null}
        {hasSupply && !tmp && isFreshEntrust && !collapsed ? (
          <i className={styles.newFeature}>{intl.get('home.new')}</i>
        ) : null}
        {item.path == '/storemanage/import' &&
          this.props.messageCountData.import_list ? (
          <b className={styles.productNumber}>
            {this.props.messageCountData.import_list}
          </b>
        ) : null}
        {item.path == '/storemanage/myproduct' &&
          this.props.messageCountData.my_products ? (
          <b className={styles.productNumber}>
            {this.props.messageCountData.my_products}
          </b>
        ) : null}
        {item.path == '/agency' ? (
          <i className={styles.newFeature}>{intl.get('home.new')}</i>
        ) : null}
        {/* shopify或ali商品数量超过限制增加红点 */}
        {(item.path == '/settings' || item.path == '//select') &&
          this.props.productLimitStatus ? (
          <span className={styles.redDot}></span>
        ) : null}
      </Link>
    );
  };

  /**
   * @param url  跳转的路径
   * 针对Oberlo 用户 进行底部banner / card 指引
   */
  getTurnUrlParam(itemPath) {
    if (!this.props.isOberlo) { return }
    // 底部banner引导
    window.EVENT.emit(GuildeAction.OblerloGuide_Banner,
      !['/bind/link-store', '/home'].includes(itemPath) ? Date.now() : '', true);

    //  卡片引导
    window.EVENT.emit(GuildeAction.OblerloGuide_Card,
      itemPath === '/order/list' ? "tracking" :
        itemPath === '/tracking' ? "splitTracking" :
          itemPath === '/settings' ? 'shipping' : '', true);

    // 迁移顶部提示
    window.EVENT.emit(GuildeAction.OblerloTop_Tip,
      !['/bind/link-store', '/supplier_optimizer'].includes(itemPath) ? itemPath : '', true)
  }
  /**
   * get SubMenu or Item
   */
  getSubMenuOrItem = item => {
    const { userinfo, messageCountData, openOrdersMenuTooltip, dispatch } = this.props;
    const { awaitingOrderNumber } = this.state;

    if (item.children && item.children.some(child => child.name)) {
      const childrenItems = this.getNavMenuItems(item.children);
      // 当无子菜单时就不展示菜单
      if (childrenItems && childrenItems.length > 0) {
        return (
          <SubMenu
            className={`${getLanguage()} ${styles.dsersMenuLink}`}
            style={{ position: 'relative', top: '-2px' }}
            title={
              item.icon ? (
                <span>
                  {getIcon(item.icon)}
                  <span>{item.name}</span>
                </span>
              ) : (
                item.name
              )
            }
            key={item.path}
          >
            {childrenItems}
          </SubMenu>
        );
      }
      return null;
    }
    return (
      item.path === "/order/list"
        ?
        <Menu.Item
          className={`${getLanguage()} ${styles.dsersMenuLink}`}
          key={item.path}
        >
          {/* 当前用户注册时间七天内 && 有awaiting order */}
          <Tooltip
            visible={(userinfo?.created_at + 86400 * 7 > Date.now() / 1000 && awaitingOrderNumber > 0) && openOrdersMenuTooltip}
            title={
              <div style={{paddingBottom:14}}>
                <Icon type="close"
                  onClick={() => 
                    dispatch({
                      type: 'global/getOpenOrdersMenuTooltip',
                      payload: false
                    })
                  }
                  style={{ display: "block", textAlign: "right", cursor: "pointer" }} />

                {intl.get('order.orderPageGuidance')}
              </div>
            }
            placement="right"
            overlayClassName={styles.menuItemToolTip}
          >
            {this.getMenuItemPath(item)}
          </Tooltip>
        </Menu.Item>
        :
        <Menu.Item
          className={`${getLanguage()} ${styles.dsersMenuLink}`}
          key={item.path}
        >
          {this.getMenuItemPath(item)}
        </Menu.Item>
    );
  };

  /**
   * 获得菜单子节点
   * @memberof SiderMenu
   */
  getNavMenuItems = menusData => {
    const { plan, userinfo, csvOrderCount } = this.props;
    if (!menusData) {
      return [];
    }
    return menuJurisdiction(menusData, plan, userinfo, csvOrderCount)
      .filter(item => item.name && !item.hideInMenu)
      .map(item => this.getSubMenuOrItem(item));
  };

  // Get the currently selected menu
  getSelectedMenuKeys = () => {
    const {
      location: { pathname }
    } = this.props;
    return urlToList(pathname).map(itemPath =>
      getMeunMatcheys(this.flatMenuKeys, itemPath).pop()
    );
  };

  // conversion Path
  // 转化路径
  conversionPath = path => {
    if (path && /^(http|mailto:)/.test(path)) {
      return path;
    }
    return `/${path || ''}`.replace(/\/+/g, '/');
  };

  isMainMenu = key => {
    return this.menus.some(
      item => key && (item.key === key || item.path === key)
    );
  };

  handleOpenChange = openKeys => {
    console.log(openKeys, '--------------On open keys');
    const lastOpenKey = openKeys[openKeys.length - 1];
    const moreThanOne =
      openKeys.filter(openKey => this.isMainMenu(openKey)).length > 1;
    this.setState({
      openKeys: moreThanOne ? [lastOpenKey] : [...openKeys]
    });
  };

  handleClickHelp = e => {
    if (e.key == 'https://help.dsers.com/') {
      webCustomCollect({
        action: 'Menu clicks',
        custom_info: [
          {
            name: 'menu_title',
            value: 'helpcenter'
          }
        ]
      });
    }
  };

  changeVersion = () => {
    Cookie.set('_ds_ver', '1', { expires: 365, path: '/' });
    window.location.reload();
  };

  currentLanguage = getLanguage();

  render() {
    const { collapsed, onCollapse, userInfo, isMobile } = this.props;
    const { openKeys, isSwitch } = this.state;
    const menuProps = collapsed ? {} : { openKeys };
    let selectedKeys = this.getSelectedMenuKeys();
    if (!selectedKeys.length) {
      selectedKeys = [openKeys[openKeys.length - 1]];
    }
    // 最后一个filter是为了灰度测试
    const topMenus = [
      MENU_POSITION.INFO,
      MENU_POSITION.SUPPLIER,
      MENU_POSITION.OPERATION,
      MENU_POSITION.ORDERS,
      MENU_POSITION.TRACKING,
      MENU_POSITION.MORE
    ]
      .map(item => {
        return this.getNavMenuItems(
          this.menus.filter(i => i.position === item)
        );
      })
      .filter(item => !!item.length);
    // const topMenu = this.getNavMenuItems(
    //   this.menus.filter(
    //     i => !Object.prototype.hasOwnProperty.call(i, 'position')
    //   )
    // );
    // console.log(topMenus, 'top menus')
    const bottomMenu = this.getNavMenuItems(
      this.menus.filter(i => i.position === 'bottom')
    );

    const otherMenu = this.getNavMenuItems(
      this.menus.filter(i => i.position === 'other')
    );

    let FAQLink = 'https://help.dsers.com/';
    if (this.currentLanguage === 'fr-FR') {
      FAQLink = 'https://help.dsers.com/';
    }

    console.log(userInfo.user_type, 'userInfo');
    return (
      <>
        {isMobile ?
          <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            breakpoint="xl"
            onCollapse={onCollapse}
            className={
              userInfo.user_type == 'system_user'
                ? `${styles.sider} ${styles.systemSider}`
                : styles.sider
            }
            className={className(styles.sider, {
              [styles.siderPT]: this.currentLanguage == 'pt-PT'
            })}
          >
            <div style={{ height: '39px', lineHeight: '39px', paddingTop: '5px' }}>
              <ExtraHeader
                pathname={this.props.location.pathname}
                selectStoreIsDisabled={this.props.selectStoreIsDisabled}
                sider='sider'
              />
            </div>
            <Menu
              key="Menu"
              theme="dark"
              mode="inline"
              onClick={this.handleMenuClick}
              {...menuProps}
              onOpenChange={this.handleOpenChange}
              selectedKeys={selectedKeys}
              style={
                document.body.clientWidth <= 1028
                  ? {
                    padding: '0 24px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                  }
                  : {
                    padding: '16px 24px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                    alignItems: 'flex-start'
                  }
              }
            >
              {topMenus.map((item, index) => {
                return (
                  <Menu.ItemGroup className={styles.itemGroup} key={index}>
                    {item}
                  </Menu.ItemGroup>
                );
              })}
              <li style={{ flex: 1 }}>&nbsp;</li>
              <Menu.ItemGroup className={styles.bottomMenu}>
                {bottomMenu}
                {this.hasHelpCenterAccess ? null : (
                  <a
                    href={FAQLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.faqMenu}
                  >
                    <Button type="primary">FAQ</Button>
                  </a>
                )}
              </Menu.ItemGroup>
              <Menu.ItemGroup>{otherMenu}</Menu.ItemGroup>
            </Menu>
            <div style={{ height: '64px', lineHeight: '64px' }}><SelectLanguage sider='sider' /></div>
          </Sider>
          :
          <div className={styles.sider_Ite}>
            <Sider
              trigger={null}
              collapsible
              collapsed={collapsed}
              breakpoint="xl"
              onCollapse={onCollapse}
              width={272}
              className={
                userInfo.user_type == 'system_user'
                  ? `${styles.sider} ${styles.systemSider}`
                  : styles.sider
              }
              className={className(styles.sider, {
                [styles.siderPT]: this.currentLanguage == 'pt-PT'
              })}
            >
              <div
                className={`${styles.logo} ${collapsed ? styles.smallLogo : ''}`}
                key="logo"
                style={this.props.isSaintsTheme ? { background: 'transparent', transition: 'none' } : null}
              >
                <Link to="/">
                  <img src={this.props.isSaintsTheme ? whiteLogo : logo} alt="logo" />
                </Link>
                {/* 切换新旧版本UI */}
                {/* <div className={styles.btnCon} >
              <Button className={styles.toNewBtn} disabled={this.state.isNewUi } size="small">New</Button>
              <Button className={styles.toOldBtn} onClick={this.changeVersion}  size="small">Old</Button>
            </div> */}
                {/* {isSwitch ? <div className={styles.changeBtn} onClick={this.changeVersion}>
              <img width="118" height={20} src={require('../../assets/new_login_ui/new.png')}></img>
            </div> : null} */}
              </div>
              <Menu
                id="byClass"
                key="Menu"
                theme="dark"
                mode="inline"
                {...menuProps}
                onOpenChange={this.handleOpenChange}
                selectedKeys={selectedKeys}
                onClick={this.handleMenuClick}
                style={
                  document.body.clientWidth <= 1028
                    ? {
                      padding: '16px 24px',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      height: 'calc(100% - 64px)',
                      overflow: 'auto',
                      width: '72px'
                      // justifyContent:'center',
                      // alignItems:'center'
                    }
                    : {
                      padding: '0 24px',
                      paddingBottom: '16px',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      height: 'calc(100% - 64px)',
                      overflow: 'auto',
                      alignItems: 'flex-start'
                    }
                }
              >
                {topMenus.map((item, index) => {
                  return (
                    <Menu.ItemGroup className={styles.itemGroup} key={index}>
                      {item}
                    </Menu.ItemGroup>
                  );
                })}
                {/* <Menu.ItemGroup>{topMenu}</Menu.ItemGroup> */}
                <li style={{ flex: 1 }}>&nbsp;</li>
                <Menu.ItemGroup className={styles.bottomMenu}>
                  {bottomMenu}
                  {this.hasHelpCenterAccess ? null : (
                    <a
                      href={FAQLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.faqMenu}
                    >
                      <Button type="primary">FAQ</Button>
                    </a>
                  )}
                </Menu.ItemGroup>
                <Menu.ItemGroup className={styles.latestItemGroup}>{otherMenu}</Menu.ItemGroup>
              </Menu>
            </Sider>
          </div>
        }
      </>
    );
  }
}
