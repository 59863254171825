/**
 * 新授权逻辑弹窗
 */

 export const REFRESH_PAGE = 'REFRESH_PAGE'

 
 export const GET_AUTO_LIST = 'GET_AUTO_LIST'


 export const CAM_MODAL = 'CAM_MODAL'
