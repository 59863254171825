import { sendRequestToExtension } from './fetchFromExtension';

export const EXT_VERSION = {
  LOWER: 'LOWER',
  HIGHER: 'HIGHER',
  EQUAL: 'EQUAL',
  NOT_INSTALLED: 'NOT_INSTALLED'
};

export const checkExtVersion = (main = 2, middle = 0, small = 0) => {
  if (!window.dsersExtVersion) return EXT_VERSION.NOT_INSTALLED;
  try {
    const [cMain, cMiddle, cSmall] = window.dsersExtVersion
      .split('.')
      .map(i => parseInt(i));
    if (main < cMain) return EXT_VERSION.HIGHER;
    if (main > cMain) return EXT_VERSION.LOWER;
    if (middle < cMiddle) return EXT_VERSION.HIGHER;
    if (middle > cMiddle) return EXT_VERSION.LOWER;
    if (small < cSmall) return EXT_VERSION.HIGHER;
    if (small > cSmall) return EXT_VERSION.LOWER;
    return EXT_VERSION.EQUAL;
  } catch (error) {
    return EXT_VERSION.NOT_INSTALLED;
  }
};

export const fetchExtOrderStatus = async params => {
  try {
    const res = await sendRequestToExtension(
      'placeOrder',
      'fetchOrderStatus',
      params,
      10000
    );
    return res;
  } catch (error) {
    console.log(error);
    return { error: 'fetch_order_status_err' };
  }
};

export const placeOrderViaExt = async params => {
  try {
    const res = await sendRequestToExtension(
      'placeOrder',
      'placeOrder',
      params,
      60000
    );
    return res;
  } catch (error) {
    console.log(error);
    return { error: 'place_order_err' };
  }
};

export const getAELoginStatus = async () => {
  try {
    const res = await sendRequestToExtension(
      'placeOrder',
      'getAELoginStatus',
      undefined,
      60000
    );
    return res;
  } catch (error) {
    console.log(error);
    return { error: 'place_order_err' };
  }
};
// 插件2.8.34以上版本可用
export const getAELoginStatusV2 = async () => {
  try {
    const res = await sendRequestToExtension(
      'placeOrder',
      'getAELoginStatusV2',
      undefined,
      60000
    );
    return res;
  } catch (error) {
    console.log(error);
    return { error: 'place_order_err' };
  }
};
// 插件2.8.49以上可用
export const getAELoginStatusV3 = async (requestLoginWhenNotLogin = false) => {
  try {
    const res = await sendRequestToExtension(
      'placeOrder',
      'getAELoginStatusV3',
      requestLoginWhenNotLogin,
      60000
    );
    return res;
  } catch (error) {
    console.log(error);
    return { error: 'place_order_err' };
  }
};

export const getAEOrderInfo = async aeOrderId => {
  try {
    const res = await sendRequestToExtension(
      'placeOrder',
      'getOrderDetailByOrderNumberViaAENewApi',
      aeOrderId,
      60000
    );
    return res;
  } catch (error) {
    console.log(error);
    return { error: 'place_order_err' };
  }
};

export const getSyncAEPriceInfoToShopifyProcess = async () => {
  try {
    const res = await sendRequestToExtension(
      'placeOrder',
      'getSyncAEPriceInfoToShopifyProcess',
      undefined,
      60000
    );
    return res;
  } catch (error) {
    console.log(error);
    return { error: 'place_order_err' };
  }
};

export const getPlaceOrderProcess = async () => {
  try {
    const res = await sendRequestToExtension(
      'placeOrder',
      'fetchOrderStatus',
      undefined,
      60000
    );
    return res;
  } catch (error) {
    console.log(error);
    return { error: 'place_order_err' };
  }
};

export const getExtDsersLoginStatus = async () => {
  try {
    const res = await sendRequestToExtension(
      'placeOrder',
      'getDsersLoginInfo',
      undefined,
      60000
    );
    return res;
  } catch (error) {
    console.log(error);
    return { error: 'place_order_err' };
  }
};

export const getExtOrderInterreptedTask = async params => {
  try {
    const res = await sendRequestToExtension(
      'placeOrder',
      'getInterreptTasks',
      params,
      60000
    );
    return res;
  } catch (error) {
    console.log(error);
    return { error: 'place_order_err' };
  }
};

export const processInterreptedTasks = async params => {
  try {
    const res = await sendRequestToExtension(
      'placeOrder',
      'processInterreptTasks',
      params,
      60000
    );
    return res;
  } catch (error) {
    console.log(error);
    return { error: 'place_order_err' };
  }
};

export const clearInterreptedTasks = async params => {
  try {
    const res = await sendRequestToExtension(
      'placeOrder',
      'clearInterreptTasks',
      params,
      60000
    );
    return res;
  } catch (error) {
    console.log(error);
    return { error: 'place_order_err' };
  }
};
