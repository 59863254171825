
    export default 
    {
    "common": {
        "learn_more": "Scopri di più",
        "submit": "Invia",
        "or": "O",
        "other": "Altro",
        "contact_us": "Contattaci",
        "click_blog": "Clicca <a href=\"{url}\" target=\"_blank\">qui per</a> scoprire come risolvere il problema.",
        "click_blog2": "Please fill in your full name in English or French only and separate them with a space. Click <a href=\"{url}\" target=\"_blank\">here</a> to learn how to solve this issue."
    }
}