import React, { useEffect, useState } from "react";
import styles from './TmallColdStartShow.less';
import whiteClock from '../../assets/coldStart/common/whiteClock.svg'
import blackClock from '../../assets/coldStart/common/blackClock.svg'
import yellowClock from '../../assets/coldStart/common/yellowClock.svg'
import y_radius from '../../assets/coldStart/yellow/pop/radius.png'
import b_radius from '../../assets/coldStart/black/pop/radius.png'
import { yellow } from "@material-ui/core/colors";
const TmallColdTimer = ({type,location}) => {
    const [hour, setHour] = useState("00");
    const [minute, setMinute] = useState("00");
    const [second, setSecond] = useState("00")
    window.timer = null;
    useEffect(() => {   
        handleTimer("2023,7,20")
      return () => clearInterval(window.timer);
    },[])
    const handleTimer = (time) => {
      let end_time = new Date(time);
          var  addHour = end_time.setHours(end_time.getHours() + 17);
            let sys_second = new Date(addHour).getTime() - new Date().getTime();
         window.timer = setInterval(() => {
          if (sys_second > 1000) {
            sys_second -= 1000;
            let day = Math.floor((sys_second / 1000 / 3600) / 24);
            let hour = Math.floor((sys_second / 1000 / 3600) % 24) + (day * 24) ;
            let minute = Math.floor((sys_second / 1000 / 60) % 60);
            let second = Math.floor(sys_second / 1000 % 60);
            setHour(hour < 10 ? "0" + hour : hour);
            setMinute(minute < 10 ? "0" + minute : minute);
            setSecond(second < 10 ? "0" + second : second)
           } else {
            clearInterval(window.timer);
          }
        }, 1000);
  };
  const PopContainer = () => {
      
      return (
        <div className={Number(hour) > 100 ? styles.ap_timer : styles.p_timer}>
          <img src={type == 'one' ? whiteClock : yellowClock} alt="" />
          <div>
            {/** 时  */}
            <div className={Number(hour) > 100 ?styles.timeItemAdd : styles.timeItem}>
              <div>{hour}</div>
              <span>h</span>
              <label></label>
              <img className={styles.rad1} src={type == 'one' ? y_radius:b_radius}  />
              <img className={styles.rad2} src={type == 'one' ? y_radius:b_radius}  />
             {Number(hour) > 100 &&  <img className={styles.rad0}  src={type == 'one' ? y_radius:b_radius}   />}
              {Number(hour) > 100 && <i></i>}
            </div>
            {/** 分 */}
            <div className={styles.timeItem}>
              <div>{minute}</div>
              <span>m</span>
              <label></label>
              <img className={styles.rad3} src={type == 'one' ? y_radius:b_radius}   />
              <img className={styles.rad4} src={type == 'one' ? y_radius:b_radius}   />
            </div>
            {/** 秒 */}
            <div className={styles.timeItem}>
              <div>{second}</div>
              <span>s</span>
              <label></label>
              <img className={styles.rad5} src={type == 'one' ? y_radius:b_radius}   />
              <img className={styles.rad6} src={type == 'one' ? y_radius:b_radius}   />
            </div>
          </div>
        </div>
        )
  }
  const BannerContainer = () => {
    return (
      <div className={type == 'one' ? styles.by_timer : styles.bb_timer}>
      <div className={styles.b_timer}>
        <img src={type == 'one' ? blackClock : yellowClock} alt="" />
        <div className={styles.b_timer_insert}>
          {/** 时  */}
          <div className={styles.timeItem}>
            <div>{hour} </div>
            <span>h</span>
            <label>:</label>
          </div>
          {/** 分 */}
          <div className={styles.timeItem}>
            <div>{minute}</div>
            <span>m</span>
             <label>:</label>
          </div>
          {/** 秒 */}
          <div className={styles.timeItem}>
            <div>{second}</div>
            <span>s</span>
          </div>
        </div>
        </div>
      </div>
      )
  }
  return (
    <>
      {
        location == 'pop' && <PopContainer/>
      }
      {
        location == 'banner' && <BannerContainer />
      }
    </>)
}
export default TmallColdTimer