import React, { useEffect } from 'react';
import { Spin } from 'antd';
import styles from './payment.less';
import qs from 'qs';
import { Post } from 'utils/request';
import { dsersBFFApiHost } from 'common/sysconfig';

function PaymentSuccessCallback() {

    useEffect(()=>{
        const token = qs.parse(location.search?.slice(1)).token
        const cbtype = window.location.href.substring(location.href.lastIndexOf('/') +1 )
        const type = cbtype?.split('?')[0]
        
        const uploadReason = async () => {
            await Post(`${dsersBFFApiHost}/dsers-pay-bff/v1/paypal/success`,{
                data:{
                    token
                },
                isCustomApi:true
            }).then(res=>{
                window.location.href = `/app/order/${type}/list`
            })
        }

        uploadReason()
    },[])

  return (
    <Spin size='large' className={styles.spin}></Spin>
  )
}

export default PaymentSuccessCallback