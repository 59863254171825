
    export default 
    {
    "product": {
        "dsers_verified_banner": "<h2>DSers <span>Verified</span> Ali Suppliers Project</h2<p>> Con soluzione di pagamento. Basta carte di credito bloccate</p>",
        "dsers_verified_banner_btn": "MAGGIORI INFORMAZIONI",
        "search_placeholder": "Cerca il Mio prodotto qui",
        "search_btn": "OK",
        "tab_all": "Tutti <i>({all_num})</i>",
        "tab_aliexpress": "AliExpress <i>({ae_num})</i>",
        tab_tmall: "Tmall <i>({tm_num})</i>",
        "tab_other": "Prodotti non mappati <i>({map_num})</i>",
        "tab_verified": "Verificato",
        "btn_import_shopify_products": "Importa Prodotti Shopify",
        "btn_import_shopify_products_tip": "DSers sincronizza solo i prodotti ordinati su Shopify al momento. Puoi importare altri prodotti cliccando sul pulsante Gestisci prodotto su DSers.",
        "btn_import_shopify_products_tip_link": "Controlla questo articolo",
        "select_source": {
            "all": "Tutto",
            "unPushed": "Non inviato",
            "pushed": "Inviato"
        },
        "push_btn": "Invia",
        "delete_btn": "Elimina",
        "delete_selected_products": "Elimina i prodotti selezionati",
        "hide_selected_products": "Nascondi i prodotti selezionati",
        "product": {
            "remove_from_shopify": "Rimosso da Shopify",
            "tip_deleted_on_Shopify": "Questo prodotto è stato eliminato su Shopify. Tuttavia gli ordini con questo prodotto possono comunque essere elaborati su DSers. Puoi eliminare questo prodotto cliccando sull'icona del cestino se vuoi eliminarlo definitivamente.",
            "hidden": "Nascosto",
            "tip_hidden": "Questo prodotto è stato nascosto. Non potrai vedere questo prodotto nei tuoi ordini e non verrà elaborato su AliExpress quando si effettuano ordini. Puoi cliccare sul pulsante Occhio per nasconderlo.",
            "verified_btn_apply": "APPLICA",
            "verified_btn_edit": "MODIFICA",
            "verified_btn_enable": "ABILITA",
            "sample_on_way": "Il tuo campione è in arrivo",
            "piece_tip": "Il costo è il prezzo mostrato sulla pagina del fornitore di AliExpress. Questo può essere diverso da quello che vedi su AliExpress in quanto DSers mostra il prezzo base indipendentemente dal paese in cui stai spedendo.",
            "piece": "pezzo",
            "processing": "Elaborazione 24h",
            "cost": "Costo",
            "price": "Prezzo",
            "orders": "ordini",
            "stock": "Stock",
            "delete": "Cancella",
            "check_details": "Controlla i dettagli",
            "edit_details": "Modifica prodotto",
            "open_in_shopify": "Apri in Shopify",
            "publish_firsh": "Pubblica prima e apri in Shopify",
            "split": "Split product",
            "hidden_tip": "Clicca per rimuovere dalla lista nera e vedrai il prodotto nella pagina degli ordini.",
            "hidden_open_tip": "Clicca per nascondere questo prodotto e non elaborarlo con i tuoi ordini.",
            "mapping_Page": "Pagina di Mappatura",
            "mapping_tip": "Pubblica prima, poi aggiungi i fornitori",
            "push_to_shopify": "Invia a Shopify",
            "import_to_store": "Importa al negozio",
            "no_out_stock": "Variante esaurita",
            "multiple": "Multiplo",
            "download_all_img": "DOWNLOAD ALL"
        },
        "manage_product": {
            "title": "Select product and set up shipping method",
            "title1": "Import Shopify Products",
            "search_placeholder": "Trova prodotti...",
            "no_data": "Nessun dato",
            "syncing": "Sincronizzazione",
            "import_btn": "Importa",
            "select_max_tip": "Puoi importare al massimo 10 prodotti per volta",
            "import_max_tip": "Puoi importare al massimo 10 prodotti in blocco per volta",
            "import_shopify_Copywriting": "Oops, a quanto pare il caricamento ha richiesto troppo tempo, riprova",
            "bulk_import": "Se hai molti articoli da importare, puoi contattare il servizio <a href=\"https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2FDSersOfficial\" target=\"_blank\" >clienti e ti aiuteremo</a>",
            "nombre": "If you have a large number of items to import, you can <a href=\"https://www.messenger.com/t/DSersOfficial\" dd=\"src\" target=\"_blank\">contact customer service</a> and we will help you."
        },
        "visible_succ": "Operazione riuscita",
        "already_imported": "Hai già importato questo prodotto, non puoi importarlo di nuovo",
        "copy_first_pushed": "Apertura fallita, il prodotto deve essere prima inviato/pubblicato su Shopify",
        "succ_copy": "Copia riuscita",
        "copy_failed": "copia fallita",
        "update_succ": "Aggiornamento riuscito!",
        "delete_tip": "Sei sicuro di voler eliminare questo prodotto?",
        "delete_pushed_tip": "I prodotti eliminati NON POSSONO ESSERE RECUPERATI e saranno cancellati dal tuo negozio Shopify. Vuoi continuare?",
        "delete_confirm": "Sei completamente sicuro di voler eseguire questa azione? È DEFINITIVO e il tuo prodotto(i) sarà cancellato anche dal tuo negozio Shopify!",
        "input_confirm_tip": "Inserisci CONFERMARE per cancellare.",
        "open_mapping_tip": "Prima pubblica e poi aggiungi i fornitori",
        "no_data": {
            "add_shopify_store": {
                "title": "Aggiungere un negozio Shopify",
                "description": "Aggiungi il/i negozio/i Shopify a DSers cliccando su Link to Shopify per gestire i tuoi prodotti. <br />Mappa un prodotto AliExpress prima di effettuare ordini multipli."
            },
            "is_sync": "Sincronizza le informazioni di Shopify, aspetta che la sincronizzazione sia completata.",
            "no_data": "Inizia il tuo business con DSers, clicca",
            "no_data_end": "per vedere come importare prodotti da AliExpress a DSers!",
            "has_store": {
                "title": "DSers mostrerà e sincronizzerà automaticamente solo i prodotti ordinati su Shopify.<br /> Ma non ti preoccupare, puoi comunque importare i prodotti che vuoi mostrare su DSers dalla tua lista di prodotti Shopify cliccando",
                "btn": "Gestisci prodotto"
            },
            no_product_tip_1:`1. Clicca su "Importa prodotto Shopify" nell'angolo in alto a destra per importare i prodotti Shopify in DSers.`,
            no_product_tip_2:`2. Mappa i prodotti importati per poter elaborare gli ordini più velocemente.`
        },
        "visible_progress_title1": "I prodotti sono visibili sul tuo negozio Shopify.",
        "visible_progress_title2": "Effettuo un ordine su AliExpress...",
        "show_supply_chain": {
            "btn": "MAGGIORI INFORMAZIONI",
            "description": "Basta Carte di Credito Bloccate <br/> Consegna Garantita al <br/> 100% Spedizione Entro 3 <br/> Giorni Servizio Clienti in Tempo Reale<br/>",
            "title": "Progetto DSers <i>Verified</i> Ali Suppliers"
        },
        "show_balance_no": {
            "btn": "EFFETTUA PAGAMENTO",
            "tip": "Saldo DSers Disponibile",
            "title": "Non è disponibile alcun saldo <br/> nel tuo account"
        },
        "show_address_no": {
            "btn": "Vai a Impostazioni",
            "tip": "Imposta le informazioni del tuo indirizzo e assicurati che il saldo sia maggiore di 0."
        },
        "show_set_visible": {
            "tip": "Pubblica anche sul Negozio Online",
            "continue_selling": "Continua a vendere quando è esaurito",
            "description": "Il prodotto (o i prodotti) verranno inviati al negozio Shopify e non potranno più essere modificati su DSers. Ma puoi comunque gestire i fornitori",
            "cancel": "Annulla",
            "yes": "Sì"
        },
        "hide_prod_tip": "Sei sicuro di voler nascondere il prodotto? Gli articoli dell'ordine con questo prodotto non saranno mostrati su DSers e non potrai più sincronizzare gli articoli nascosti su DSers.",
        "confirm_prod_tip": "Inserisci CONFERMARE per nascondere il prodotto.",
        "possible_cause": "Possibile causa:",
        "search_notice_for_migrate": "Non inserire l'url qui. Se vuoi importare i prodotti, vai su Importa lista",
        "search_msg_for_migrate": "Vai alla lista di importazione per importare i prodotti. Clicca <a href=\"https://help.dsers.com/import-list-for-shopify/\" target=\"_blank\"><strong>qui</strong></a> per avere maggiori informazioni.",
        "filter_push_title": "Filtrare per invio",
        "to_push_shopify": "Già inviato a Shopify",
        "no_push_shopify": "Non inviato a Shopify",
        "selectAll": "Seleziona tutti",
        "hide": "Nascondi",
        "del": "Elimina",
        "myProducts": "I miei prodotti",
        "input_confirm_tip2": "Premere CONFERMARE per procedere.",
        "introduce_con1": "You are about to delete the selected product(s) from DSers. But the products will be kept on Shopify.",
        "introduce_con2": "You are about to delete the selected product(s) from DSers. And the products will also be deleted from Shopify store.",
        "introduce_checkbox": "Delete this product from my Shopify store too.",
        "introduce_ok": "CONFERMA",
        "introduce_cancle": "Cancel",
        "cost_ible": "Cost $ ——",
        "loading": "Replacing",
        "error": "Failed to replace",
        "success": "Successfully replaced",
        "collections": "Search the full collection's title.(Case insensitive)",
        "product_type": "Seleziona o crea a Shopify tipo",
        "tags": "Seleziona o crea a Shopify tag",
        "vendor": "Seleziona o crea venditore",
        "filterByMapping": "Filter by Mapping",
        "basic_mapping": "Basic",
        "standard_mapping": "Standard",
        "advance_mapping": "Advanced",
        "bogo_mapping": "BOGO",
        "bundle_mapping": "Bundle",
        "productOutOfStock": "Product out of stock",
        "productNotFound": "Product not found",
        "filterByNotification": "Filtra per notifica",
        "filterByHidden": "Filtra per stato",
        "filterByVariants": "Variants out of stock",
        "filterBySku": "SKU cambiato su AliExpress",
        "filterByGone": "Prodotti rimossi dal fornitore",
        "filterByHid": "Prodotti nascosti",
        "filterByShopifyDelete": "Rimosso da Shopify",
        "is_main_product": "Original unsplit product",
        "updatePrice": "While checking the data, we found that the price of the product you migrated from Oberlo was originally a non-US price, but due to an internal error, the price was migrated as a US price. We are very sorry for this.\n\tIn order to correct this error in time and not cause trouble and loss to your subsequent operations, we now provide a dedicated feature to modify the products in your account to the price of the destination country you want. (Only items with U.S. prices can be modified, and each item can only be modified once. If you do not need this service, you can ignore this message. This feature will only be available for one month)",
        "update": "Update price",
        "chooseCountry": "Choose a country",
        "chooseSub": "Select the country for which you want to update prices",
        "update_desc_wait": "products are being updated",
        "update_desc_ok": "updates successful",
        "update_desc_fail": "updates failure",
        "error_one": "Your product is being updated, as your account is being accessed by someone else and the update operation has been performed",
        "error_two_title": "None of the products you selected can be updated. ",
        "error_two_sub": "1. The price of the product is not set for the United States. 2. Your product is being updated, as your account is being accessed by someone else and the update operation has been performed 3. The product has been updated already.",
        "selectCountry": "Select country",
        "price_for": "Prezzo per",
        "collection_title": "Collezioni",
        "type_title": "Tipo",
        "tags_title": "Tag",
        "vendor_title": "Fornitore",
        "productLimitTitle": "Limite prodotti Shopify",
        "productLimitSub": "Il numero di prodotti che può essere importato in I miei prodotti.",
        "aeLimitTitle": "Limite prodotti rivenditore",
        "aeLimitSub": "Totale dei prodotti importati nella Lista importazioni e numero dei prodotti nel mapping I miei prodotti.",
        "productCostToShopify": "Invia i prezzi dei prodotti a Shopify",
        'productCostAndShippingCostToShopify':'Invii il prodotto e le spese di spedizione a Shopify',
        "productCostAe": "Invia i prezzi dei prodotti rivenditore a Shopify quando promuovi i prodotti.",
        remove_all_imgs:'remove all images'
    }
}