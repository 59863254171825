import React, { useState, useCallback } from 'react';

import intl from 'react-intl-universal';

import styles from './Entrance.less';
// eslint-disable-next-line
import QuestionModal from './QuestionModal';
import NoticeOnline from './NoticeOnline';
import useOnlineNotice from '../../../hooks/useOnlineNotice';
import ReceiveMessage from './ReceiveMessage';

const onlineDate = new Date(2020, 7, 6); // 上线日期

const christmasDate = new Date(2020, 11, 17);

function Entrance() {
  const [visible, setVisible] = useState(false);
  const [noticeVisible, closeNotice] = useOnlineNotice('h_c', onlineDate);

  function handleClose() {
    setVisible(false);
  }

  const handleNoticeClose = useCallback(() => {
    closeNotice();
  }, []);

  const [chrismasTheme] = useOnlineNotice(
    'christmast2020_theme',
    christmasDate,
    7
  );

  return (
    <>
      <a track-type="helpCenter" track-name="entry">
        <div
          className={`${styles.entrance} ${
            chrismasTheme ? styles.christmasTheme : ''
          }`}
          onClick={() => setVisible(true)}
          aria-hidden="true"
        >
          <div className={styles.christmasWrapper}>
            <div className={styles.title}>
              {intl.get('help_center.entrance.get_support')}
            </div>
            <div className={styles.intro}>
              <span>FAQ</span>
              <span>{intl.get('help_center.entrance.online_chat')}</span>
              <span>Email</span>
            </div>
          </div>
        </div>
        <QuestionModal visible={visible} onCancel={handleClose} />
        {noticeVisible ? <NoticeOnline onClose={handleNoticeClose} /> : null}
      </a>
      <ReceiveMessage />
    </>
  );
}

export default Entrance;
