export default {
  tmallTestExpress: {
    form: {
      contact_name: 'Contact Name',
      tel: 'Tel',
      email: 'Email',
      company: 'Company',
      country: 'Country',
      address: 'Address',
      province: 'Province',
      city: 'City',
      post_code: 'Post code'
    },
    title:"Get Free Tmall Samples",
    desc:"Fill in the correct shipping address, and you will get the to receive free Tmall samples.",
    confirmText:"Free Try",
    cancelText:"Not interested",
  }
};
