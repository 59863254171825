
    export default 
    {
    "bargain": {
        "bargain": "Schnäppchen",
        "counter_limit": "Sie können alle 24 Stunden 10 Mal ein Schnäppchen machen.",
        "submission_tip": "Ihr persönlicher Kundenbetreuer wird Ihnen innerhalb von 1 Werktag nach der Einreichung antworten.",
        "banner_title": "PRODUKT-SCHNÄPPCHEN",
        "banner_content": "Sind Sie an günstigeren Großhandelspreisen interessiert? <br />Senden Sie eine Anfrage mit der Schnäppchen-Funktion! <br />Ihr persönlicher Kundenbetreuer wird Ihnen innerhalb von 1 Werktag antworten.",
        "not_store": "Bevor Sie ein Schnäppchen einreichen, vergewissern Sie sich bitte, dass Sie einen gültigen Link zu einem Geschäft angeben.",
        "link_store": "Link zum Geschäft",
        "product_name": "Name des Produkts",
        "supplier_price": "Lieferant Preis",
        "email_field": "Ihre Kontakt-E-Mail (Wir werden auf diese E-Mail antworten)",
        "email_field_p": "Bitte geben Sie Ihre häufig verwendete E-Mail-Adresse an",
        "message_field": "Hinterlassen Sie uns eine Nachricht",
        "message_field_p": "Teilen Sie uns weitere Details über Ihr Unternehmen mit",
        "price_field": "Verhandlungspreis",
        "price_field_p": "Bitte geben Sie einen angemessenen Verhandlungspreis ein",
        "submit": "Schnäppchen vorschlagen",
        "title": "Lieferanten finden",
        "sub_title": "Heiße Produkte",
        "intro": "Eine große Auswahl an Produkten mit hohem Verkaufspotenzial mit vorgestellten Produkten mit 10 Tagen Lieferzeit! <a href=\"mailto:supply-support@dserspro.com\">Kontaktieren</a> Sie uns, wenn Sie mehr erfahren möchten.",
        "tag_bargain": "Schnäppchen",
        "tag_time": "24h Bearbeitungszeit",
        "tag_delivery": "Garantierte Lieferung",
        "tag_24": "Unterstützung innerhalb von 24 Stunden",
        "tag_country": "Schneller Versand in bestimmte Länder",
        "tag_recommend": "Hochprofitable Produktempfehlungen",
        "btn_import": "Einfuhr",
        "btn_bargain": "Schnäppchen",
        "search_p": "Geben Sie Stichworte zur Suche ein",
        "feedback_title": "Sie haben ein Schnäppchen gemacht!",
        "feedback_con": "Das Angebotsteam von DSers wird sich < br /> innerhalb eines Arbeitstages bei Ihnen melden. <br />Wir danken Ihnen für Ihre Geduld.",
        "copied": "Link kopiert!",
        "link_copy": "Link kopieren",
        "m_title": "Produkt importiert !",
        "m_reminder": "Nicht mehr anzeigen",
        "reminder_help": "Wenn Sie dieses Kontrollkästchen aktivieren, wird dieses Fenster beim nächsten Import eines Produkts nicht mehr angezeigt. Wenn Sie ein importiertes Produkt bearbeiten möchten, gehen Sie bitte zur Importliste und klicken Sie auf der Produktkarte auf Bearbeiten.",
        "m_con": "Das Produkt wurde erfolgreich importiert. Bitte gehen Sie zur Importliste, um dies zu überprüfen.",
        "m_btn_stay": "Aufenthalt",
        "m_btn_go": "Zur Importliste gehen, um zu prüfen",
        "m_fail_title": "Produkt konnte nicht importiert werden!",
        "m_fail_con": "Das Produkt konnte nicht importiert werden. Bitte versuchen Sie es später noch einmal.",
        "m_fail_go": "Habe es",
        "cost": "Kosten",
        "piece": "Stück",
        "rating": "Bewertung",
        "product_rating": "Produkt-Bewertung",
        "evaluation": "Bewertung",
        "sold": "Bestellungen",
        "trendy": "Trendy",
        "found_cheaper": "Wir haben billiger gefunden!",
        "cheaper": "Billiger finden",
        "reward": "AliExpress-Belohnung",
        "alternative": "Brauchen Sie eine Alternative?",
        "try_this_product": "Möchten Sie dieses Produkt ausprobieren?",
        "powered_by": "Angetrieben von",
        "mass_supply": "DSers Massenversorgung",
        "input_email": "Bitte geben Sie eine E-Mail Adresse ein.",
        "fill_max": "Bitte geben Sie maximal {max} Zeichen ein.",
        "coming_soon": "Demnächst verfügbar...",
        "ten_days_delivery": "10 Tage Lieferung",
        "delivery_tip": "10 Tage Lieferzeit in die USA! 10 Tage Lieferung bald auch nach Brasilien und Frankreich!",
        "reward_tip": "Produkte von Massenlieferanten, die für eine Bargeldprämie in Frage kommen",
        "tip_learn_more": "Mehr erfahren",
        "delivery_tip_url": "https://www.blog.dserspro.com/post/10-days-delivery-to-specific-countries-with-dsers",
        "reward_tip_url": "https://www.blog.dserspro.com/post/dsers-reward-season",
        "banner2": {
            "anchor": "Anker",
            "thank_you": "Vielen Dank, dass Sie sich für das Programm DSers Mass Supply entschieden haben!",
            "delete_product_tip": "Es scheint, dass Sie das Shopify-Produkt gelöscht haben. <br /> Wir können die Mapping-Seite nicht für Sie öffnen.",
            "copied_tip": "Der Link des Produkts DSers Mass Supply wurde kopiert. Bitte wählen Sie ein Shopify-Produkt aus, das Sie zuordnen möchten.",
            "go_mapping": "Mapping starten"
        },
        "banner_item": {
            "save_percent": "Speichern {percent} und stabil",
            "provided_by": "Bereitgestellt von",
            "current_sp": "Ihr derzeitiger Lieferant",
            "unstable": "Kostet mehr und ist instabil"
        },
        "found_cheaper_one": "Wir haben einen günstigeren Anbieter gefunden!",
        "need_alternative": "Brauchen Sie eine Alternative?",
        "alternative_shipping": "Alternative mit zuverlässigem Versand!",
        "alternative_stock": "Prüfen Sie alternative Produkte mit stabilem Lagerbestand",
        "alternative_price": "Prüfen Sie alternative Produkte mit stabilem Preis",
        "one_click": {
            "title": "Möchten Sie unseren ausgewählten Lieferanten ausprobieren?",
            "title_1": "Erhöhen Sie Ihre Gewinnspanne",
            "title_1_3": "VIP-Großhandelspreis-Link",
            "sub_title": "<strong>Wechseln</strong> Sie zu einem autorisierten, zuverlässigen Lieferanten mit Großhandelspreisen mit <strong>DSers Mass Supply</strong>.",
            "sub_title_3": "<strong>Erhalten Sie Ihren eigenen VIP-Großhandelspreis-Link wie andere größere Dropshipper.</strong>",
            "sub_title_d": "Glückwunsch, Sie haben ein hohes Verkaufsvolumen erreicht",
            "intro_detail": "Bewerben Sie sich über die Schaltfläche Wechseln unten, <br />um noch heute <strong>Großhandelspreise</strong> und <strong>zuverlässigen Service zu genießen</strong>.",
            "intro_detail_d": "Bewerben Sie sich beim <strong>DSers Mass Supply Programm</strong>, <br />um noch heute <strong>zuverlässigen Service</strong> und <strong>Großhandelspreise zu genießen</strong>.",
            "intro1": "Großhandelspreise",
            "intro2": "Stabiler Bestand",
            "intro3": "Zuverlässiger Versandservice",
            "intro4": "Engagierte Kundenbetreuung",
            "intro5": "Upgrade der Datenschutzrichtlinien",
            "detail": "Klicken Sie auf die Schaltfläche Wechseln, um den Anbieter dieses Produkts in dem/den unten stehenden Shopify-Shop(s) automatisch zu ersetzen",
            "check": "Produkt auf AliExpress prüfen",
            "switch": "WECHSELN",
            "switch_now": "JETZT WECHSELN",
            "apply_today": "Heute ANWENDEN",
            "feedback_title": "Mapping wurde automatisch ersetzt",
            "feedback_subtitle": "Bitte <a href=\"mailto:supply-support@dserspro.com\">kontaktieren</a> Sie uns, wenn Sie irgendwelche Fragen haben",
            "product_cost": "Produkt Kosten",
            "shipping_cost": "Versandgebühr",
            "support_tip": "Kontaktieren Sie uns, um weitere Informationen zu erhalten und mit Ihrem Kundenbetreuer zu sprechen: <br /><a href=\"mailto:supply-support@dserspro.com\">supply-support@dserspro.com</a>",
            "support_tip_2": "Ihre eigene Lieferkette wird aktiviert, sobald Sie \"<a href=\"javascript:void(0);\">WECHSELN</a>\" anwenden. <br />Ihr persönlicher Kundenbetreuer wird sich in Kürze mit Ihnen in Verbindung setzen from <a href=\"mailto:supply-support@dserspro.com\">supply-support@dserspro.com</a>",
            "support_tip_3": "Sie können Switch noch heute beantragen, Ihr persönlicher SAM wird sich umgehend mit Ihnen in Verbindung setzen. <br />Sie können sich auch jederzeit per E-Mail an Ihren persönlichen SAM wenden: <a href=\"mailto:supply-support@dserspro.com\">supply-support@dserspro.com</a>",
            "support_tip_4": "Bewerben Sie sich noch heute über die Schaltfläche Switch, Ihr persönlicher Supply Account Manager (SAM) wird sich umgehend mit Ihnen in Verbindung setzen. <a href=\"mailto:supply-support@dserspro.com\">supply-support@dserspro.com</a>",
            "support_tip_d_1": "Bewerben Sie sich noch heute oder kontaktieren Sie uns unter <a href=\"mailto:supply-support@dserspro.com\">supply-support@dserspro.com</a><br />",
            "support_tip_d_2": "Erfahren Sie mehr vom <a href=\"https://www.dsers.com/blog/dsers-mass-supplier-program/\" target=\"_blank\">DSers Massenversorgungsprogramm</a>",
            "support_tip_e": "Ihr persönlicher Supply Account Manager (SAM) wird sich unmittelbar nach Ihrer Anmeldung mit Ihnen in Verbindung setzen. <a href=\"mailto:supply-support@dserspro.com\">supply-support@dserspro.com</a>",
            "supply_chain_title": "Bauen Sie Ihre eigene Lieferkette auf",
            "supply_chain_1": "Persönliche Versorgung Account Manager (AM) wird vollständig für Sie online zugewiesen werden",
            "supply_chain_2": "Zuverlässiger Lieferant wird für Sie ausgewählt, über AliExpress Big Data Analyse (Datenstudio)",
            "supply_chain_3": "Neue Produktverknüpfung wird zum Großhandelspreis/Standardpreis erstellt",
            "benefits_a_1": "Sparen Sie ${number}, indem Sie zu einem Produkt mit verifiziertem Großhandelspreis wechseln",
            "benefits_a_2": "Neuer AliExpress-Produktlink wird nur für Sie erstellt",
            "benefits_a_3": "Persönlicher Supply Account Manager (SAM), der Sie unterstützt",
            "benefits_c_1": "DSers Mass Supply Program übernimmt die Verantwortung (z.B. 100% garantierte Rückgabepolitik)",
            "benefits_c_2": "Stabiler Großhandelspreis, ausreichender Lagerbestand und schneller Versand (AliExpress Standardversand) werden versprochen",
            "benefits_c_3": "Der VIP-Link wird auf der Grundlage einer Big-Data-Analyse von autorisierten Lieferanten erstellt",
            "benefits_d_1": "<strong>Das 3S Fulfillment Center</strong> bietet einen professionellen, stabilen und sicheren Fulfillment-Service",
            "benefits_d_2": "<strong>Mit dem Fulfillment-Service</strong> können Sie bei großen Bestellmengen <strong>${number}</strong> sparen",
            "benefits_d_3": "<strong>Langfristige Zusammenarbeit kann</strong> mit Ihrem persönlichen Kundenbetreuer ausgehandelt werden.",
            "benefits_e_1": "Aktivieren Sie den ausgewählten Lieferanten durch Klicken auf BESTÄTIGEN, um an der Prämienaktion teilzunehmen.",
            "benefits_e_2": "Verwenden Sie den ausgewählten Lieferanten und führen Sie mehr als {number1} Bestellungen pro Tag (oder {number2} Bestellungen in 30 Tagen) aus, um ${number3} zu erhalten Belohnung",
            "benefits_e_3": "Die Belohnung wird gewährt, nachdem die Bestellungen auf AliExpress mit einer Verzögerung von 2 Monaten abgeschlossen worden sind.",
            "benefits_e_2_1": "Der ausgewählte Lieferant wird aktiviert und ersetzt Ihren aktuellen Lieferanten",
            "benefits_e_2_2": "Sie erhalten eine Prämie, wenn Sie bei dem ausgewählten Lieferanten mehr als {number1} Bestellungen pro Tag (oder {number2} Bestellungen in 30 Tagen) abschließen.",
            "benefits_e_2_3": "Die Prämie wird gewährt, nachdem die Bestellungen auf AliExpress mit einer Verzögerung von 2 Monaten abgeschlossen wurden.",
            "support_tip_e_2": "Ihr persönlicher Supply Account Manager (SAM) wird Sie sofort nach der Anmeldung unter apply.<br /><a href=\"mailto:supply-support@dserspro.com\">supply-support@dserspro.com</a> kontaktieren.",
            "benefits_f_1": "Der ausgewählte Lieferant wird aktiviert und ersetzt Ihren derzeitigen Lieferanten",
            "benefits_f_2": "Sie werden einen Monat lang ${num_1} sparen ({num_2} für jede Bestellung)",
            "support_tip_f": "Ihr persönlicher Supply Account Manager (SAM) wird Sie unmittelbar nach der Aktivierung von apply.<br /><a href=\"mailto:supply-support@dserspro.com\">supply-support@dserspro.com</a> kontaktieren.",
            "supply_chain_example": "(z.B. im obigen Fall werden Sie $ {number}/Monat sparen)",
            "v5": {
                "title_1": "Wholesale Price",
                "subTitle_1": "Reliable supply service for you",
                "subTitle_2_1": "Your Account Manager is",
                "subTitle_2_2": "pleased to help you with",
                "contact": "CONTACT",
                "list_1_title": "RELIABILITY",
                "list_1_text": "Only selected manufacturers verified by AliExpress officially will be proposed",
                "list_2_title": "WHOLESALE PRICES",
                "list_2_text": "Wholesale and stable prices are promised for dropshipping businesses",
                "list_3_title": "FAST SHIPPING",
                "list_3_text": "AliExpress standard shipping method is highly suggested by the AliExpress team",
                "list_4_title": "POLICY",
                "list_4_text": "100% REFUND policy in case  of unsuccessful delivery",
                "miss": "Miss",
                "service_text_1": "Is senior account manager from DSers Mass Supply program.",
                "service_text_2": "She has been involved in supply service over 5 years. ",
                "service_text_3": "And she has the expertise to manage and give you access to multiple manufacturers according to your business needs.",
                "service_text_4": "Talk to her at",
                "banner_title": "Market trends analysis, 1st version",
                "check": "CHECK"
            }
        },
        "tag": {
            "reward": "Belohnung",
            "delivery": "10 Tage Lieferung"
        },
        "filter": {
            "country_filter_title": "Top-Lieferanten in bestimmten Ländern",
            "category_filter_title": "Kategorie",
            "free_return": "Kostenlose Rücksendung",
            "processing": "24h Bearbeitung",
            "ship_from": "Versand von",
            "shop_import": "Import",
            "shop_orders": "Orders"
        },
        "sorter": {
            "default": "Standardmäßig",
            "new": "Neuestes",
            "price": "Preis",
            "orders": "Bestellungen"
        },
        "btn_optimizer": "Lieferanten-Optimierer",
        "btn_optimizer_test": "Ähnliche finden",
        "btn_optimizer_ab_test": "Mehr Anbieter",
        "logo_text": "DSers Premium-Auswahl",
        "product": "Produkt",
        "ae_product_tip": "AliExpress Qualitätskontrolle",
        "dsers_product_tip": "DSers Premium-Auswahl",
        "search_empty_tip": "Ihre Suche nach {keyword} ergab leider keine Produkttreffer. Bitte versuchen Sie es erneut.",
        "product_button_tip": "Klicken Sie auf Produkt, um die Liste aller Produkte anzuzeigen",
        "dont_show_again": "Nicht mehr anzeigen",
        "bargain_disabled_by_order": "Die Schnäppchenfunktion ist nur für Benutzer mit mindestens 10 erfüllten Bestellungen mit einer Trackingnummer verfügbar.",
        "bargain_disabled_by_bargained": "Sie haben den Preis für dieses Produkt heute bereits ausgehandelt. Bitte versuchen Sie es morgen erneut, wenn Sie erneut verhandeln möchten.",
        "img_search_failed_tip": "Die Funktion Suche nach Bild kann derzeit aufgrund eines unerwarteten Problems der AliExpress-API nicht verwendet werden. Wir arbeiten mit dem AliExpress-Team zusammen, um dieses Problem zu beheben. Wir entschuldigen uns für die Unannehmlichkeiten und danken Ihnen für Ihr Verständnis.",
        "img_search_success_tip_1": "Die Suche nach Bild kann Ihnen bis zu 60 identische Produkte auf AliExpress anzeigen. Klicken Sie",
        "here": "hier",
        "img_search_success_tip_2": "um die Ergebnisse der Bildersuche zu verlassen.",
        "install_ext_title": "DSers Plugin installieren",
        "install_ext_description": "Mit einem Klick die beste Auswahl unter den Millionen von AliExpress-Produkten treffen und Zeit beim Importieren sparen",
        "install_ext_description_test": "Hoppla, Sie können nicht mehr finden? Installieren Sie die DSers Chrome-Erweiterung, um noch heute mehr qualifizierte Anbieter zu finden",
        "refresh_page": "Seite aktualisieren",
        "install_ext_btn": "DSers Plugin installieren",
        "select_mask": "Bitte wählen Sie mindestens einen Shop aus, um das Produkt zu pushen",
        "shipCost": {
            "reminder": "New feature online!",
            "text": "You are now able to import products with purchase price for different countries when you import with DSers Chrome Extension!\n\t\t\tDownload DSers <br/> Chrome extension to discover this feature",
            "learnmore": "LEARN MORE",
            "install": "INSTALL"
        },
        search_no_data_tip:'Keine Ergebnisse, bitte versuchen Sie andere Suchbegriffe.',
        filter_no_data_tip:'Keine Ergebnisse, bitte ändern Sie den Filter.',
        onlyFor:'For US & Brazil'
    }
}