import React, { useState, useEffect, useCallback } from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'dva';
import qs from 'qs';
import intl from 'react-intl-universal';
import Cookies from 'js-cookie';

import Register from './Register';
import PartnerItem from '../../components/PartnerItem/index'
import Logo from '../../components/NewLogo'
import { pageTitle } from 'utils/constants';
import styles from './SignInPage.less';
import { updatePlatform } from 'services/login'
import AccountSuccess from './AccountSuccess';
import Media from 'react-media';
import SelectLanguage from 'components/GlobalHeader/SelectLanguage';
import { Spin } from 'antd';
import { exchangeShopifyAuthParamsToAuthKey, getRedirectToOpenAPIRegister, mvpAccountSite } from 'utils/justifyMVPAccountUtils';

const SignInPage = ({ dispatch, isMobile }) => {
  const [redirectUrl, setRedirectUrl] = useState(null);
  const [rawRedirectUrl, setRawRedirectUrl] = useState(null);
  const [childAccount,setChildAccount] = useState(false)  //是否走子账户注册流程
  const [redirectToOpenApiLoading, setRedirectLoading] = useState(true);

  const checkRedirectToOpenAPI = async queryObj => {

    window.location.href = `${mvpAccountSite}register`;
    return;
    // 老平台子账号注册 不做跳转
    if (queryObj.email) {
      setRedirectLoading(false);
      return;
    }

    const shouldRedirect = await getRedirectToOpenAPIRegister();
    if (shouldRedirect) {
      let registerPageParams = {};
      
      if (queryObj.redirect_uri) {
        const redirectUrl = queryObj.redirect_uri;
        const shopifyRedirect = queryObj.shopify_auth_redirect_uri;

        // 在wix或者shopify授权流程中时携带授权回调url到新版注册页 否则忽略redirectUrl
        if (
          redirectUrl.includes('/web/auth-callback')
        ) {
          registerPageParams.old_auth_uri = redirectUrl;
        }

        if(redirectUrl.includes('/app/auth/callback')) {

          const oldAuthParams = redirectUrl.slice(redirectUrl.indexOf('?') + 1);
          const oldAuthParamsObj = qs.parse(oldAuthParams);
          // url上有key 说明已经换过一次了直接跳转 此情况应该极少出现
          if(oldAuthParamsObj.key) {
            registerPageParams.old_auth_uri = redirectUrl;
          } else {
            const key = await exchangeShopifyAuthParamsToAuthKey(oldAuthParams);
            if(key) {
              registerPageParams.old_auth_uri = redirectUrl + '&key=' + encodeURIComponent(key);
            }
          }
        }

        if(shopifyRedirect) {
          registerPageParams.old_auth_uri = redirectUrl;
          registerPageParams.old_auth_redirect = shopifyRedirect;
        }
      }

      // 带量注册渠道id
      if (queryObj.channel_id) {
        registerPageParams.channel_id = queryObj.channel_id;
      }

      const hasQuery = !!Object.keys(registerPageParams).length;

      const openAPIRegisterUrl = `${mvpAccountSite}register${
        hasQuery ? '?' + qs.stringify(registerPageParams) : ''
      }`;

      window.location.href = openAPIRegisterUrl;

      return;
    }

    setRedirectLoading(false);
  };

  useEffect(() => {
    const parse = qs.parse(location.search.slice(1));
    console.log('sign up 参数', parse);

    if (parse && parse.redirect_uri) {
      setRedirectUrl(parse);
      setRawRedirectUrl(location.search);
    }
    getEmail();

    // 检查是否应该去Open API注册页
    checkRedirectToOpenAPI(parse).catch(() => {
      setRedirectLoading(false);
    });

    window.localStorage.setItem('firstRegister',true)
  }, []);

  const getEmail = ()=>{
    var url = location.search
    if (url.indexOf("?") != -1) {
      var str = url.substr(1);
      var type = str.split('=')[0]
      if(type == 'email'){
        setChildAccount(true)
      }
    }else{
      setChildAccount(false)
    }
  
  }

  const handleSubmit = useCallback(
    data => {
      if (redirectUrl) {
        dispatch({
          type: 'register/pureSubmit',
          payload: {
            ...data,
            successCallback: async (token, data) => {
              if (rawRedirectUrl.from === 'wix' || redirectUrl.redirect_uri.includes('/web/auth-callback')) {
                window.localStorage.setItem('dser-pro-authority', token);
                try {
                  const updateRes = await updatePlatform({
                    data: {
                      action_operation: 'wix',
                      action_type: 1
                    }
                  });
                  if (![2000, 2010].includes(updateRes.code)) {
                    return;
                  }
                } catch (error) {
                  console.log(error);
                }
                window.localStorage.removeItem('dser-pro-authority');
                window.localStorage.setItem('authority_token', token);
              } else {
                window.localStorage.setItem('dser-pro-authority', token);
              }

              window.location.href = redirectUrl.redirect_uri;
            }
          }
        });
      } else {
        dispatch({
          type: 'register/submit',
          payload: data
        });
      }
    },
    [dispatch, redirectUrl]
  );

  const handleSubmit_child = useCallback(  //子账号注册方法
    data => {
      dispatch({
        type:'register/submit_child',
        payload:data
      })
    },
    [dispatch]
  )

  return !redirectToOpenApiLoading ? (
    <DocumentTitle title={`Sign up - ${pageTitle}`}>
      {
      isMobile ? 
      <div className={styles.mobileSignin}>
        <div className={styles.container}>
          <div>
            {
              !childAccount ? 
              <Register onSubmit={handleSubmit} redirectUrl={rawRedirectUrl} isMobile={isMobile}/>
              :
              <AccountSuccess onSubmit={handleSubmit_child} /> //子账号注册页面
            }
          </div>
        </div>
      </div> 
      :    
      <>
      <div className={styles.signIn}>
        <div className={styles.top}>
            <a className={styles.logo} target="_blank" href='/'>
              <img src='https://img.dsers.com/images/logo.png' />
            </a>
          <div style={{ lineHeight: '72px',marginRight:'150px' }}><SelectLanguage itemClass={styles.itemStyle} cusClass={styles.loginSelect}/></div>
        </div>
        <div className={styles.containerBox}>
          <div className={styles.container}>
          <div className={styles.container_fix}>
              <div className={styles.start_n}></div>
              <div className={styles.start_t}></div>
              <div className={styles.text_box}>
                <p className={styles.text_title}>{intl.getHTML('login.text_title',{
                  titlek:styles.titlek
                })}</p>
                <p className={styles.text_desc}>{intl.getHTML('login.text_desc',{
                  titlek:styles.titlek
                })}</p>
              </div>
              <div className={styles.box}>
                {
                  !childAccount ? 
                  <Register onSubmit={handleSubmit} redirectUrl={rawRedirectUrl} />
                  :
                  <AccountSuccess onSubmit={handleSubmit_child} /> //子账号注册页面
                }
              </div>
            </div>
            {/* <PartnerItem /> */}
          </div>
        </div>
      </div>
      </>
      }
    </DocumentTitle> 
  ) : <Spin />;
};

export default connect()(props => (
  <Media
    queries={{
      small: '(max-width: 599px)'
    }}
  >
    {matches => (
      <SignInPage
        {...props}
        isMobile={matches.small}
      />
    )}
  </Media>
));
