
    export default 
    {
    "whyDisconnect": {
        "need_help": "Hai bisogno di aiuto per usare DSers?",
        "need_help_dec": "Sentiti libero di contattarci se hai bisogno di aiuto. Ti risponderemo il prima possibile.",
        "need_to_uninstall": "Se disinstalli DSers dal tuo negozio, nota che: ",
        "need_to_uninstall_dec": "Una volta che il negozio sarà rimosso dall'attuale account DSers, tutti i dati di quel negozio su DSers saranno persi. Il processo NON è reversibile.",
        "leave_tip": "Per favore facci sapere perché vuoi andartene. Questo ci aiuterà notevolmente a fare meglio in futuro.",
        "tell_more": "Per favore dicci di più",
        "select_reason": "Seleziona una motivazione…",
        "not_satisfied_with_product": "Non sono soddisfatto delle funzionalità legate alla Gestione del prodotto.",
        "not_satisfied_with_order": "Non sono soddisfatto delle funzionalità legate alla Gestione dell'ordine.",
        "not_work": "L'app non funziona bene.",
        "better_app": "Ho trovato un'app simile, ma migliore.",
        "high_cost": "Il costo di DSers è troppo alto.",
        "test_store": "Questo negozio è un test.",
        "sold_store": "Ho venduto il negozio.",
        "closing_store": "Sto chiudendo il negozio.",
        "closing_business": "Sto chiudendo la mia compagnia di consegna diretta.",
        "reinstall": "Lo reinstallerò più avanti.",
        "other": "Altro",
        "disconnect": "Disconnetti",
        "stay": "Resta",
        "contact": "Contatta",
        "update": "Aggiorna",
        "lose_feature": "Se ti disconnetti perderai i seguenti privilegi",
        "which_module": "Per favore facci sapere perché vuoi andartene. Questo ci aiuterà a migliorare in futuro!",
        "suggestion_title": "Hai dei suggerimenti? Per favore facci sapere qui sotto",
        "other_platform_title": "Ti stai spostando su un'altra piattaforma? Facci sapere quale",
        "stay_for_free": "Congratulazioni, si prega di lasciare un indirizzo email a cui possiamo contattarti. Il nostro team di professionisti ti contatterà entro 1-2 giorni per attivare il tuo accesso gratuito della durata di un mese.",
        "btn_get_free": "Ottieni un mese gratuito",
        "woo_free": "DSers per WooCommerce è gratuito",
        "reason": {
            "feature": {
                "btn": "Funzionalità",
                "ali_account": "Non sono riuscito a collegare l'account AliExpress",
                "order_process": "Non sono soddisfatto della gestione degli ordini",
                "product_edit": "Non sono soddisfatto delle funzionalità di edizione del prodotto",
                "import": "Non sono soddisfatto delle funzionalità di importazione del prodotto",
                "mapping": "Non sono soddisfatto dellla funzionalità di tracciamento"
            },
            "price": {
                "btn": "Prezzo",
                "expensive": "DSers è troppo costoso"
            },
            "other": {
                "btn": "Altro",
                "reinstall": "C'è un problema con il negozio, reinstallerò più avanti",
                "fullillment_center": "Voglio lavorare con il centro assistenza o con un operatore",
                "close_store": "Sto chiudendo o vendendo il negozio / sto chiudendo il mio business su Shopify",
                "test_store": "È una negozio in fase di test "
            }
        },
        "dsers_feature": {
            "ali": "Il solo partner ufficiale AliExpress con accesso all'elenco di contatti autorizzati AliExpress",
            "import": "Importa & Edita prodotti da AliExpress velocemente e facilmente",
            "order": "Lavorazione di molteplici ordini in corso. <br /> Effettua centinaia di ordini in pochi minuti!",
            "reward": "Lavora con il Programma di Affilizazione Admitad per guadagnare di più."
        },
        "callback_title": "Grazie per il tuo feedback! <br /> Speriamo di poter lavorare ancora insieme presto.",
        "failed_link_ae": "Non sono riuscito a collegare il mio account AliExpress.",
        "failed_add_shopify": "Non sono riuscito ad aggiungere il mio negozio Shopify.",
        "not_satisfied_with_import": "Non sono soddisfatto della funzionalità di importazione dei prodotti.",
        "not_satisfied_with_mapping": "Non sono soddisfatto della funzionalità di tracciamento.",
        "not_satisfied_with_bulk_order": "Non sono soddisfatto della funzionalità di ordine all'ingrosso.",
        "not_satisfied_with_setting": "Non sono soddisfatto delle funzionalità in Impostazioni.",
        "closing_store_or_business": "Sto chiudendo/vendendo il negozio o sto chiudendo il mio business su Shopify o è un negozio in fase di test"
    }
}