import React from 'react';
import { Popover } from 'antd';
import intl from 'react-intl-universal';

import Ellipsis from 'components/Ellipsis';

import { getAliExpressUrl, getAliExpressUrlV2, replaceUrlToHttps } from 'utils/utils';

import defaultImg from '../../../assets/default-image.jpg';
import orderStyles from '../Order.less';
import '../Order.css';

function Product(props) {
  const { useSp = {}, status, bundle = 0,listData } = props;

  return (
    <>
      <Popover
        placement="leftTop"
        trigger="hover"
        content={
          <img
            src={replaceUrlToHttps(useSp.sku_img)}
            onError={e => {
              e.target.onerror = null;
              e.target.src = defaultImg;
            }}
            style={{
              width: '260px',
              height: '260px',
              objectFit: 'contain'
            }}
          />
        }
        overlayClassName={orderStyles.popover}
      >
        <a className={orderStyles.productImg}>
          <img
            src={replaceUrlToHttps(useSp.sku_img)}
            onError={e => {
              e.target.onerror = null;
              e.target.src = defaultImg;
            }}
          />
          {status != 1 && useSp.is_ver ? <span>FBD</span> : null}
          {bundle == 1 && !props.useSp.is_edit ? <div className="bound">Bundle</div> : null}
          {bundle == 2 ? <div className="bound">BOGO</div> : null}
        </a>
        {listData?.bStatus == 2 ? <div className="splitOrder">{intl.get('setting.order.split_ending')}</div> : null}
      </Popover>
      <div className={orderStyles.productInfo}>
        {useSp.platform_product_id ? (
          <a
            href={getAliExpressUrlV2(useSp.platform_product_id)}
            target="_blank"
            rel="noopener"
            title={useSp.title}
            className="line-title"
          >
            {useSp.title}
          </a>
        ) : (
          <span title={useSp.title} className="line-title">{useSp.title}</span>
        )}
        <div title={useSp.variant_title} className={orderStyles.productVariant}>
          <Ellipsis lines={2}>
            {intl.get('order.order_product.variants')}{' '}
            <div className="variants_title">{useSp.variant_title || 'Default Title'}</div>
          </Ellipsis>
        </div>
        <div className={orderStyles.productInfoForm}>
            {useSp.platform_product_id // 没有供应商商品就是other单
              ? (
                <div className={orderStyles.formAliexpress}>{intl.get('order.order_product.from')}:{' '}{intl.get('order.order_product.from_aliexpress')}</div>
                )
              : (
                <div className={orderStyles.fromOther}>{intl.get('order.order_product.from')}:{' '}{intl.get('order.order_product.from_other')}</div>
              )}
        </div>
      </div>
    </>
  );
}
export default Product;
