import React, { useEffect, useState } from 'react'
import { Cascader,message, Tooltip, Icon  } from 'antd'
import timezone from 'common/timezone'
import moment from 'moment-timezone'
import styles from './Appointment.less'
import { Get, Post } from 'utils/request'
import { connect } from 'dva';
import intl from 'react-intl-universal';


function Appointment({ changeSelectTime, migrationInfo, timeDis }) {

    const [selectTimezone, setSelectTimezone] = useState()
    const [showRight, setShowRight] = useState(false)
    const [showSelectBox, setShowSelectBox] = useState(false)
    const [selectTime, setSelectTime] = useState('Start migration now')
    const [timeData, setTimeData] = useState([])
    const [timeDataStatus, setTimeDataStatus] = useState()   //false:avaiable true:unavaiable

    useEffect(() => {


        timezone.forEach((item) => {
            if (item.value == Intl.DateTimeFormat().resolvedOptions().timeZone) {
                setSelectTimezone(item)
            }
        })

        window.addEventListener('click', (e) => {
            const con = document.getElementById('appointment')
            if (!con?.contains(e.target)) {
                setShowSelectBox(false)
            }
        })
    }, [])

    useEffect(()=>{

        getAppointTime()

        if(timeDis){
            setSelectTime('Start migration now')
            changeSelectTime('Start migration now', '')
        }

    },[timeDis])


    const getAppointTime = async () => {
        const requests = [Post('/mgnoberlo-core-api/dsers-api/migration/durations', {
            data: { shopify_domain: migrationInfo?.migrating_shopify_domain }
        }, true), Get(`/mgnoberlo-core-api/dsers-api/migration/durations/status?shopify_domain=${migrationInfo?.migrating_shopify_domain}`, {}, true)]

        const res = await Promise.allSettled(requests).then((res) => {
            if(res[1]?.value?.code || res[0]?.value?.code){
                message.error(<div style={{ float: 'right' }}>{res[1]?.value?.message || res[0]?.value?.message} <Tooltip placement='top' title={`${intl.get('migration.requestid')}${res[1]?.value?.metadata?.trace_id || res[0]?.value?.metadata?.trace_id}`}><Icon style={{ fontSize: "15px", marginLeft: '5px' }} type="question-circle" /></Tooltip></div>)
            }
            if (res[1]?.value?.durations?.start !== '0' && !res[1]?.value?.code) {
                setSelectTime(res[1]?.value?.durations)
            }else{
                setTimeData(res[0]?.value?.durations)
                setTimeDataStatus(res[0]?.value?.durations?.every(item => {
                    return item.available == '0'
                }))
            }
        })
    }

    const handleTimeZone = () => {
        setShowRight(!showRight)
    }


    const handleSelectTimezone = (item) => {
        return () => {
            setSelectTimezone(item)
            setSelectTime('Start migration now')
            changeSelectTime('Start migration now', '')
            setShowRight(false)
        }
    }

    const handleSelectTime = (item) => {
        return () => {
            setSelectTime(item)
            setShowSelectBox(false)
            changeSelectTime(item, selectTimezone.value)
        }
    }

    const renderErrorText = () => {
        if(timeDataStatus){
            return <div className={styles.errortext}>{intl.get('migration.timefull')}</div>
        }else if(timeDis){
            return <div className={styles.errortext}>{intl.get('migration.nowTimeFull')}</div>
        }
    }

    return (
        <>
            <div className={styles.container} id='appointment'>
                <div className={['STEP_STAGE_QUEUE_RESERVATION','STEP_STAGE_QUEUE'].includes(migrationInfo?.step_stage)  ? styles.selectButtonDis : styles.selectButton} onClick={['STEP_STAGE_QUEUE_RESERVATION','STEP_STAGE_QUEUE'].includes(migrationInfo?.step_stage) ? null : ()=>{setShowSelectBox(!showSelectBox)}}>
                    <div className={styles.topleft}>
                        <div>{intl.get('migration.migrationText')}</div>
                        <div>{selectTime.constructor == String ? intl.get('migration.startNow') : `${moment(Number(selectTime.start) * 1000).tz(selectTimezone?.value)?.format('DD MMM HH')}:00~${moment(Number(selectTime.end) * 1000).add(1, 'H').tz(selectTimezone?.value)?.format('HH')}:00`}</div>
                    </div>
                    <i class="material-icons">chevron_left</i>
                </div>
                <div className={styles.selectBox} style={!showSelectBox ? { display: 'none' } : null}>
                    <div className={styles.boxleft}>
                        <div className={styles.timezone} onClick={handleTimeZone}>
                            <div className={styles.timezoneleft}>
                                <div>Time zone</div>
                                <div>{selectTimezone?.label}</div>
                            </div>
                            <i class="material-icons" style={!showRight ? { transform: 'rotate(180deg)' } : null}>chevron_right</i>
                        </div>
                        <div className={styles.selectTime}>
                            <div className={`${styles.leftitem} ${selectTime == 'Start migration now' ? styles.timezoneSelect : null}`} onClick={handleSelectTime('Start migration now')}>
                                {intl.get('migration.startNow')} {selectTime == 'Start migration now' ? <i class="material-icons">done</i> : null}
                            </div>
                            {
                                timeData?.map((item, index) => {
                                    return <div key={index} className={`${item.available == '1' ? styles.leftitem : styles.leftItemDis} ${selectTime?.start == item.start ? styles.timezoneSelect : null}`} onClick={item.available == '1' && handleSelectTime(item)}>
                                        {`${moment(Number(item.start) * 1000).tz(selectTimezone?.value)?.format('DD MMM HH')}:00~${moment(Number(item.end) * 1000).add(1, 'H').tz(selectTimezone?.value)?.format('HH')}:00`} {selectTime?.start == item.start ? <i class="material-icons">done</i> : null}
                                    </div>
                                })
                            }
                        </div>
                    </div>
                    <div className={styles.boxright} style={!showRight ? { display: 'none' } : null}>
                        {
                            timezone.map((item, index) => {
                                return <div key={index} className={`${styles.rightitem} ${selectTimezone?.label == item.label ? styles.timezoneSelect : null}`} onClick={handleSelectTimezone(item)}>
                                    {item.label}  {selectTimezone?.label == item.label ? <i class="material-icons">done</i> : null}
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
            {migrationInfo?.step_stage == 'STEP_STAGE_PRE' ? renderErrorText() : null}
        </>
    )
}

export default connect(({ auth }) => ({
    migrationInfo: auth.migrationInfo,
}))(React.memo(Appointment))