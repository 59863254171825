import { getBannerStatus } from './service';

async function sleep(number) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(3);
    }, number);
  });
}
export default function loop(waiting, success, number = 10000) {
  (async function timer() {

    if(!['/app/login','/app/register'].includes(location.pathname)){


      const result = await getBannerStatus().then(result => result?.data);

      // 迁移中
      if (result?.status === 'MIGRATION_STATUS_MIGRATING') {
        waiting && waiting();
        return;
      }

      // 迁移完成
      if (result?.status === 'MIGRATION_STATUS_COMPLETED') {
        success && success();
        return;
      }
      await sleep(number);

      if (result?.beforePopup?.switch) {
        timer();
      }
    }
  })();
}
