import { fetch } from 'dva';
import { message } from 'antd';
import { stringify } from 'qs';

import { routerRedux } from 'dva/router';
import store from '../index';
import { getApiUrl } from './utils';
import getUserInfo from './getUserInfo';
import { errFn } from './request';

function checkStatus(response, options = {}, url) {
  const msg = options.message || {};
  if (response.status === 200) {
    return response.json().then(resData => {
      if (resData.code === 205 && resData.token) {
        localStorage.setItem('partner-authority', resData.token);
        return request(url, options);
      }

      if (options.callback) {
        try {
          options.callback(resData);
        } catch (error) {
          console.error('callback 出错了:', error);
          throw new Error(error);
        }
      }
      if (options.cancelMessage) {
        return resData;
      }
      if (resData.code === 2000 || resData.code === 2010) {
        const successText = msg.success;
        if (successText) {
          message.success(successText);
        }
      } else {
        const errortext =
          resData.msg || msg.error || codeMessage[response.status];
        if (errortext) {
          message.error(errortext);
        }
      }
      return resData;
    });
  }
  if (response.status >= 500) {
    return errFn(response.status, options);
  }
  const error = new Error();
  error.name = response.status;
  error.response = response;
  throw error;
}
/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url = '', options = {}) {
  const defaultOptions = {
    credentials: 'include'
  };
  const newOptions = { ...defaultOptions, ...options };
  newOptions.headers = {
    Authorization: window.localStorage.getItem('partner-authority') || '',
    // ...getAuthorityHeader(),
    ...options.customHeaders
  };
  if (newOptions.method === 'POST' || newOptions.method === 'PUT') {
    if (!(newOptions.body instanceof FormData)) {
      newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=utf-8',
        ...newOptions.headers,
        ...options.customHeaders
      };
      newOptions.body = JSON.stringify(newOptions.body);
    } else {
      newOptions.headers = {
        Authorization: localStorage.getItem('partner-authority') || '',
        ...options.customHeaders
      };
    }
  }

  return fetch(getApiUrl(url, !!options.isOutApi), newOptions)
    .then(response => {
      return checkStatus(response, options || {}, url);
    })
    .catch(e => {
      // const { dispatch } = store;
      const status = e.name;
      if (status === 401) {
        // dispatch({
        //   type: 'partner/logout'
        // });
        window.localStorage.removeItem('partner-store');
        window.localStorage.removeItem('partner-authority');
        window.location.href = '/partner';
      }

      if (status <= 504 && status >= 500) {
        const res = errFn(status, options);
        return res || e;
      }

      if (options.exceptionFn) {
        options.exceptionFn(e);
      }

      const msg = options.message || {};
      if (msg.error) {
        message.error(msg.error);
      }
      return e;
    });
}

export function Post(url, payload = {}) {
  const userInfo = getUserInfo();
  const { data = {}, headers = {} } = payload;
  if (userInfo && userInfo.secondLevelId) {
    data.ower_bussiness_account_id = userInfo.secondLevelId;
  }
  return request(url, {
    method: 'POST',
    body: data,
    message: payload.message,
    callback: payload.callback,
    isOutApi: !!payload.isOutApi,
    cancelMessage: !!payload.cancelMessage,
    customHeaders: headers
  });
}

export function Put(url, payload = {}) {
  const userInfo = getUserInfo();
  const { data = {} } = payload;
  if (userInfo && userInfo.secondLevelId) {
    data.ower_bussiness_account_id = userInfo.secondLevelId;
  }
  return request(url, {
    method: 'PUT',
    body: data,
    message: payload.message,
    callback: payload.callback,
    isOutApi: !!payload.isOutApi,
    cancelMessage: !!payload.cancelMessage
  });
}

export function Delete(url, payload = {}) {
  const path = payload.data
    ? `${url}?${stringify(payload.data, {
        encode: false,
        arrayFormat: 'comma'
      })}`
    : url;
  return request(path, {
    method: 'DELETE',
    message: payload.message,
    callback: payload.callback,
    isOutApi: !!payload.isOutApi,
    cancelMessage: !!payload.cancelMessage
  });
}

export function Get(url, payload = {}) {
  const path = payload.data
    ? `${url}?${stringify(payload.data, {
        encode: false,
        arrayFormat: 'comma'
      })}`
    : url;
  return request(path, {
    method: 'GET',
    message: payload.message,
    callback: payload.callback,
    isOutApi: !!payload.isOutApi,
    cancelMessage: !!payload.cancelMessage
  });
}
