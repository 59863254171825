
    export default 
    {
    "error_cashback_code": {
        "title": "Il tuo cashback Admitad si sta fermando. Controlla.",
        "title_dec": "Abbiamo scoperto che il tuo codice non è sotto DSers.",
        "p1": "Abbiamo scoperto che stai usando un codice affiliato sotto un canale non DSers.",
        "p2": "Non puoi più ottenere il cashback con il codice attuale.",
        "p3": "Clicca qui sotto per scoprire cosa è successo e come gestirlo."
    }
}