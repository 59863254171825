export default {
  beforeMigration: {
    shopify: 'Conserver uniquement les paramètres du site Shopify (ou CSV).',
    wooCommerce: 'Conserver uniquement les paramètres du site WooCommerce.',
    wix: 'Conserver uniquement les paramètres du site Wix.',

    title: 'O sistema será totalmente atualizado em breve!',
    title1: 'Atualização do sistema se aproximando',

    one: 'O DSers será atualizado em  ',
    two: 'e estará indisponível por até 24 horas',
    there: 'Por favor, ajuste sua agenda adequadamente.',
    four:
      'Para lhe proporcionar uma melhor experiência do usuário, o DSers irá atualizar o sistema por um período de',
    five: '24 horas a partir de',
    six:
      'DO DSers estará indisponível durante o período. Pedimos desculpas pelo incômodo. ',
    seven: 'Clique ',
    seven1: 'aqui',

    //---------
    eight: ' para saber mais.',

    nine: 'para obter mais informações.',

    ten:
      'Para lhe proporcionar uma melhor experiência do usuário, o DSers irá atualizar o sistema por um período de',
    eleven:
      'O DSers estará indisponível durante o período. Pedimos desculpas pelo incômodo.',
    twelve:
      'A atualização visa simplificar a gestão multiplataforma. Isso pode causar conflitos de configuração se você estiver gerenciando lojas em várias plataformas. A compatibilidade das diferenças entre cada plataforma é totalmente considerada. No entanto, informe-nos sobre sua preferência, caso necessário.',
    thirteen:
      'Comment souhaitez-vous fusionner les paramètres potentiellement conflictuels dans les Paramètres après la mise à niveau ?',

    fourteen:
      'Après la fusion, les paramètres au niveau du compte entre les deux sites seront en conflit, vous devez donc choisir quel site conserver. Les paramètres en conflit sont :',
    fifteen: 'Notification',
    sixteen: 'Compte du personnel',
    seventeen: 'Expédition',
    eighteen: "Ajouter une méthode d'expédition mondiale basée sur le produit",
    nineteen: 'Laisser un message aux fournisseurs',
    twenty: 'Optimisation du numéro de téléphone',
    twentyOne: 'Otimização de número de telefone - Otimização de pedidos cancelados',
    twentyTwo: 'E outras funções.',

    twentyThere: "Mas não se preocupe, ainda manteremos as configurações do nível da loja entre os dois sites para você, como:",
    twentyFour: 'Regra de Preços',
    twentyFive: 'Rastreamento de Pacotes',
    twentySix: 'Moedas',

    twentySeven: 'O sistema está sendo atualizado, o que levará ',
    twentyEight: 'cerca de 24 horas',
    twentyNine:
      'O DSers será suspenso durante o período de atualização, e pedimos desculpas por qualquer inconveniente.',

    twentyTen:
      'Desculpe, o sistema está sendo atualizado no momento e não pode ser vinculado, por favor, tente novamente mais tarde',
    notification: 'Notificação',

    submit: 'Confirmar'
  }
};
