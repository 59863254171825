import { Post, Get, Put } from '../utils/request';

export async function getList(payload = {}) {
  return Get('/fulfillment/list', payload);
}

export async function changeChecked(payload = {}) {
  return Put('/fulfillment/product/status', payload);
}

export async function postCloseBottom(payload = {}) {
  return Put('/fulfillment/product/status', payload);
}

export async function getModalList(payload = {}) {
  return Get('/fulfillment/adlist', payload);
}
