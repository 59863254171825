import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';
import { Link } from 'dva/router';
import intl from 'react-intl-universal';
import { connect } from 'dva';

import styles from './AdmitadModal.less';
import { Get } from '../../../utils/request';

/**
  CheckAdmitadResultDef
  // 0  默认值， 如果出现此值， 说明程序处理不够严谨， 某些地方需要优化
  CheckAdmitadResultNoCode
  // 1  用户没有任何 Admitad Code
  CheckAdmitadResultOldUserNoAccount
  // 2  老用户有 Admitad Code， 但是没有通过校验的 Admitad Account Name， 第一次返回此值时， 用户Code的有效期修改为 48小时之后
  CheckAdmitadResultAccountWaitingValid
  // 3 用户修改 Code 后， Admitad Account 没有通过校验， 且我们的 Admitad Account 列表没有发生变化， 此时 Code 可用
  CheckAdmitadResultAccountNoValid
  // 4 用户的 Admitad Account 没有通过校验， 第一次获取此值时， Code 有效期设置为48小时之后
  CheckAdmitadResultAccountValid
  // 5 用户的 Admitad Account 通过了校验， Code 永远可用
  CheckAdmitadResultNoValidAfter48hDeadline
  // 6 用户在48h有效期过后仍未通过校验，Code不可用
*/

const AdmitadModal = ({ userInfo, code, dispatch }) => {
  const [visible, setVisible] = useState(false);
  const [type, setType] = useState(undefined);

  useEffect(() => {
    if (!(userInfo && userInfo.code_type === 1)) {
      return;
    }

    if (window.localStorage.getItem('admitad_notice_check')) {
      return;
    }

    dispatch({ type: 'dropshippersetting/admitadCheck' });
  }, []);

  useEffect(() => {
    if (code === 2 || code === 4) {
      window.localStorage.setItem(
        'admitad_notice_check',
        Date.now().toString()
      );

      setType(code === 2 ? 2 : 1);
      setVisible(true);
    }
  }, [code]);

  function handleCancel() {
    setVisible(false);
  }

  if (!type) {
    return null;
  }

  return (
    <Modal
      visible={visible}
      footer={null}
      width={680}
      title={intl.get('setting.other.notice_add_title')}
      maskClosable={false}
      keyboard={false}
      onCancel={handleCancel}
    >
      {type === 1 ? (
        <>
          <p className={styles.desc}>
            {intl.getHTML('setting.other.notice')}
          </p>
          <p className={styles.descBottom}>
            {intl.getHTML('setting.other.notice_sub')}
          </p>
          <div className={styles.footer}>
            <Link to="/settings?id=6">
              <Button onClick={handleCancel}>
                {intl.getHTML('setting.other.notice_button_change')}
              </Button>
            </Link>
            <a
              href="https://store.admitad.com/en/promo/?ref=8e3xmmxnjp "
              target="_blank"
              rel="noopener noreferrer"
              style={{ marginLeft: 12 }}
            >
              <Button type="primary">
                {intl.getHTML('setting.other.notice_button_register')}
              </Button>
            </a>
          </div>
        </>
      ) : null}
      {type === 2 ? (
        <>
          <p className={styles.desc}>
            {intl.getHTML('setting.other.notice_2')}
          </p>
          <div className={styles.footer2}>
            <a
              href="https://login.aliexpress.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Link to="/settings?id=6">
                <Button onClick={handleCancel} type="primary">
                  Do it now
                </Button>
              </Link>
            </a>
          </div>
        </>
      ) : null}
    </Modal>
  );
};

export default connect(({ dropshippersetting, login }) => ({
  code: dropshippersetting.admitadCheckCode,
  userInfo: login.userInfo
}))(AdmitadModal);
