
    export default 
    {
    "send_reset_email": {
        "title": "Restablecer contraseña",
        "tip": "Te enviaremos un código de verificación a tu correo electrónico para comprobar tu identidad.",
        "dec": "Introduce la dirección de correo electrónico que utilizaste para registrarte. Si instalaste Dsers desde Shopify, introduce la dirección de correo electrónico de tu cuenta de Shopify. Si olvidaste tu correo electrónico, por favor",
        "contact_us": "Contáctanos",
        "placeholder": "Correo electrónico",
        "email_instructions": "Instrucciones por correo electrónico",
        "send_succ": "Las instrucciones para restablecer tu contraseña han sido enviadas por correo electrónico.",
        "email_not_valid": "¡La información introducida no es un correo electrónico válido!",
        "email_empty": "Por favor, introduce tu correo electrónico.",
        "email_unknown": "Por favor, introduce tu correo electrónico de acceso."
    }
}