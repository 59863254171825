
    export default 
    {
    "header": {
        "about": "ÜBER UNS",
        "support": "UNTERSTÜTZUNG",
        "blog": "BLOG",
        "sign_in": "Anmelden",
        "sign_up": "Anmelden"
    }
}