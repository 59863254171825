/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-self-assign */
/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import { connect } from 'dva';
import { Input, Modal, Form, message } from 'antd';
import intl from 'react-intl-universal';

import { emailRegExp } from 'utils/constants';

import styles from '../content/Account.less';

const { Search } = Input;
const newEmailModule = ({ visible, dispatch, handleCancel, accountData }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [email, setEmail] = useState('');
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [emailCode, setEmailCode] = useState('');
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [emailTip, setEmailTip] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [codeTip, setCodeTip] = useState(false);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [isMail, setIsMail] = useState(false);

  const sendCode = () => {
    const myreg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
    // 验证邮箱
    if (!myreg.test(email)) {
      setEmailTip(true);
    } else {
      dispatch({
        type: 'partner/SendChangeEmailMsg',
        payload: {
          data: { email },
          callback: d => {
            if (d.code === 2000) {
              message.success(d.msg);
              accountData();
              setEmailTip(false);
              setIsMail(true);
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              // d.data && setDate(d.data) && setOldData(d.data);
            } else {
              setIsMail(false);
            }
          }
        }
      });
    }
  };
  const changeNewEmail = e => {
    setEmail(e.target.value);
  };
  const changeCode = e => {
    setEmailCode(e.target.value);
  };
  const handleCancelModule = () => {
    setEmail('');
    setEmailCode('');
    handleCancel();
  };
  const sendEmail = () => {
    if (!emailRegExp.test(email)) {
      setEmailTip(true);
      return;
    }
    dispatch({
      type: 'partner/ChangeEmailConfirm',
      payload: {
        data: { email, code: emailCode },
        callback: d => {
          if (d.code === 2000) {
            setCodeTip(false);
            message.success(d.msg);
          } else {
            setCodeTip(true);
          }
        }
      }
    });
  };
  let mail = '';
  if (email) {
    let mailHost = email.split('@')[1];
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    mailHost === 'gmail.com'
      ? (mailHost = 'google.com')
      : (mailHost = mailHost);
    mail = `http://mail.${mailHost}`;
  }
  return (
    <Modal
      visible={visible}
      footer={null}
      width={600}
      maskClosable={false}
      className={styles.email_modal}
      onCancel={handleCancelModule}
    >
      <h3>{intl.get('partner.Reset_email_module.title')}</h3>
      <Search
        enterButton={intl.get('partner.Reset_email_module.send_code')}
        onChange={changeNewEmail}
        onSearch={sendCode}
        size="small"
        value={email}
      />
      {emailTip ? (
        <p style={{ color: 'red', margin: 0 }}>
          {intl.get('partner.Reset_email_module.email_tips')}
        </p>
      ) : null}
      {isMail ? (
        <p className={styles.sendCodeSuccTool}>
          <a href={mail} target="_blank">
            {intl.get('setting.account.open_mailbox')}
          </a>
          <a role="button" tab-index="0" onClick={sendCode}>
            {intl.get('setting.account.send_again')}
          </a>
        </p>
      ) : null}

      <p>{intl.get('partner.Reset_email_module.send_prompt')}</p>
      <h3>{intl.get('partner.Reset_email_module.code_title')}</h3>
      <Search
        enterButton={intl.get('partner.Reset_email_module.verify')}
        onChange={changeCode}
        onSearch={sendEmail}
        size="small"
      />
      {codeTip ? (
        <p style={{ color: 'red', margin: 0 }}>
          {intl.get('partner.Reset_email_module.code_tips')}
        </p>
      ) : null}
      <p>{intl.get('partner.Reset_email_module.get_code')}</p>
    </Modal>
  );
};
export default connect()(Form.create()(newEmailModule));
