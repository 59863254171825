export default {
  notification: {
    notifications: 'Notifications',
    mark_all_as_read: 'Mark as read',
    tip:
      'You will only receive notifications for the products that have been orders in the past. Notifications on DSers will be displayed 30 days',
    regarding_to_aliexpress_statement: 'Regarding to Aliexpress statement:', // d
    product_price_has_changed: 'Product cost has changed', // m
    product_out_of_stock:
      'The AliExpress product is out of stock or not available anymore', //  m
    order_canceled: 'The order has to be canceled:', // d
    order_no: 'Order No: ', // d
    ali_no: 'Ali No: ', // d
    income: 'Income：', // d
    check: 'Check', // d
    view: 'View', // d
    more: 'More', // d
    no_data: 'Your Notification List is Empty', // d
    no_data_tip:
      'We will notify you about changes to product(s) availability or price', // d
    at: 'at',
    not_available: 'Not available',
    sku: 'SKU',
    const_change: 'Cost Change',
    got_it: 'OK, got it.', //  m
    go_to_shopify: 'Go to shopify', // d
    stock: 'Stock',
    no_match: 'No Match', //  d
    check_product: 'Check product', // d
    copy_succ: 'Successfully copied', //  d
    copy_fail: 'copy failed', //  d

    ali_product: 'Ali product', //  add
    related_shopify_products: 'Related product(s) in Shopify store :', //  add
    price: 'Price', //  add
    compared_at_price: 'Compare at price', //  add
    update_to_shopify: 'Update to Shopify', //  add
    variants_out_of_stock: 'AliExpress SKU has changed', //  add
    variants_not_of_stock: 'Product not available anymore',
    variants_out_zero_stock:
      "AliExpress product's variant(s) out of stock or not available",
    related_shopify_products_length:
      'Shopify product(s) mapped to this AliExpress product', //  add
    view_price: 'View price', //  add
    view_variant: 'View variant', //  add
    offline_product:
      'Are you sure you want to put this product offline? If you click Confirm, the product will not be visible on your Shopify store anymore.', //  add
    store_not_available: 'The shop is no longer available',
    product_is_offline: 'Product is offline', //  add
    put_offline: 'Choose a product to put offline', //  add
    set_new_value: 'Set New Value', //  add
    multiply_by: 'Multiply By', //  add
    enter_value: 'Enter Value', //  add
    enter_multiplier: 'Enter Multiplier', //  add
    change_all: 'Change all', //  add
    mapping_changed:
      'You have changed the default mapping', //  add
    product_deleted:
      'The Shopify product using this supplier was deleted, no detailed information can be shown.', //  add
    store_removed:
      'The Shopify store to which this product belongs to has been disconnected from DSers, no detailed information can be shown.',
    focus_on_orders: {
      p1: `You have `,
      p2: ` canceled orders, please check`
    },
    view_canceled_orders: 'View canceled orders',
    noti_tit:'Notification',
    shopPrice:'Note that the currency shown here is the currency you set in DSers, not your Shopify currency',
    modalTitle:"Delete Notification(s)",
    modalText:"Are you sure you want to delete the notification(s)? It will be removed from your notification list.",
    filter_variant_out_of_stock:'Variant(s) out of stock',
    cancelOrder:'Canceled order',
    clearFilter:'CLEAR ALL FILTERS',
    filterTitle:'Filter Notification',
    defaultSupplier:'Default Supplier',
    secondSupplier:'Substitute Supplier',
    allNotification:'View all notification',
    mappingPlaceholder:'Choose a product to re-map',
    copyTitle:"Copy Title",
    copied:'Tiltle Copied',
    filter:"Filter",
    product_not_found:'Product not found',
    product_out_of_stock_tip:'Product out of stock',
    get_product_err: `Error in obtaining product information, if you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`,
    get_product_err_2: `There was an error obtaining product information, please try again later. If you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`,
    no_support_mapping: `Because the mapping method of the product is not supported, the detailed information cannot be displayed. If you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`,
    mapping_trouble: `Detailed information cannot be displayed due to a problem with the SKU or mapping of the product. If you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`,
    product_sku_change: `Details cannot be displayed due to a change in the SKU of the item. If you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`
  }
};
