export default {
  whyDisconnect: {
    need_help: 'Do you need help to use DSers?',
    need_help_dec:
      'Please feel free to contact us if you need any help. We will get back to you as soon as possible.',
    need_to_uninstall:
      'If you uninstall DSers from your store, please note:',
    need_to_uninstall_dec:
      'Once the store is removed from the current DSers account, all the data of that store on DSers will be lost and it is NOT reversible.',
    leave_tip:
      'Please let us know why you want to leave. This would be very helpful for us to do better in the future.',
    tell_more: 'Please tell us more',
    select_reason: 'Select a reason…',
    not_satisfied_with_product: 'I am not satisfied with Product management related features.',
    not_satisfied_with_order: 'I am not satisfied with Order management related features.',
    not_work: 'The app does not work well.',
    better_app: 'I found a similar app which is better.',
    high_cost: 'The cost of DSers is too high.',
    test_store: 'This is a test store.',
    sold_store: 'I sold the store.',
    closing_store: 'I am closing the store.',
    closing_business: 'I am closing my dropshipping business.',
    reinstall: 'I will reinstall later.',
    other: 'Other',
    disconnect: 'Disconnect',
    stay: 'Stay',
    contact: 'Contact',
    update: 'Update',
    lose_feature: 'If you disconnect you will lose the following privileges',
    which_module: 'Please let us know for which reason you are leaving. This will help us improve in the future!',
    suggestion_title: 'Have some suggestions? Please let us know below',
    other_platform_title: 'Moving to another platform? Tell us which one',
    stay_for_free: 'Congratulations🎉🎉, please leave an email address where we can contact you.<br />Our professional team will contact you within 1-2 days to activate your one month free access.',
    btn_get_free: 'Get free month',
    woo_free: 'DSers for WooCommerce is completely free>>',
    reason: {
      feature: {
        btn: 'Feature',
        ali_account: 'I failed to link to AliExpress account',
        order_process: `I'm not satisfied with the order processing`,
        product_edit: `I'm not satisfied with the product edition features`,
        import: `I'm not satisfied with the product import features`,
        mapping: `I'm not satisfied with the mapping feature.`
      },
      price: {
        btn: 'Price',
        expensive: 'DSers is too expensive'
      },
      other: {
        btn: 'Other',
        reinstall: 'There is a problem with the store, I will reinstall later',
        fullillment_center: 'I want to work with a fulfillment center or an agent',
        close_store: 'I am closing or selling the store / I am closing my Shopify business',
        test_store: `It's a testing store`
      }
    },
    dsers_feature: {
      ali: 'AliExpress only official partner with access to AliExpress whitelist',
      import: 'Import & Edit products from AliExpress fast and easily',
      order: 'Multiple order processing.<br />Place 100s of orders in minutes!',
      reward: 'Work with Admitad Affiliate Program to make more money'
    },
    callback_title: 'Thank you for your feedback! <br /> Hope we can work together again soon.',
    failed_link_ae: 'I failed to link to AliExpress account.',
    failed_add_shopify: 'I failed to add my Shopify store.',
    not_satisfied_with_import: 'I am not satisfied with product importer feature.',
    not_satisfied_with_mapping: 'I am not satisfied with mapping feature.',
    not_satisfied_with_bulk_order: 'I am not satisfied with bulk order feature.',
    not_satisfied_with_setting: 'I am not satisfied with features in Setting.',
    closing_store_or_business: `I am closing/selling the store or I am closing my Shopify business or it's a testing store.`
  }
};
