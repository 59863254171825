export default {
  login: {
    title: 'Acesse sua conta DSers',
    email: 'E-mail',
    password: 'Senha',
    cap_placeholder: 'Captcha',
    log_in: 'ENTRAR',
    forgot_password: 'Esqueceu a senha?',
    enter_username: 'Digitar nome de usuário!', //  a
    enter_password: 'Insira a senha',
    email_password_error: 'erro no e-mail ou senha', // a
    empty: 'O campo de senha não pode ficar vazio.',
    text_title:`5.0 <br/> <span class={titlek}>Estrelas de avaliação com</span>`,
    text_desc:`10000+ <br/> <span class={titlek}>Avaliações na App Store<br/> Shopify</span>`,
    text_title_2:`Substituto do<br/> <span class={titlek}>Oberlo recomendado oficialmente pelo Shopify </span>`,
    text_desc_2:`+ de 5.400<br/> <span class={titlek}>avaliações de 5 estrelas <br/>na app store Shopify</span>`,
    noHaveText:`Não tem uma conta?`,
    createAccount:'CRIAR CONTA',
    emailrequired:"O e-mail é obrigatório",
    error:`E-mail ou senha incorretos.`
  }
};
