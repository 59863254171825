
    export default 
    {
    "pricing": {
        "title": "Mehr Wert bei weniger Kosten",
        "desc": "Kostenloser Plan verfügbar",
        "tipSelectPlan": "Bitte wählen Sie einen Plan aus, um fortzufahren",
        "Basic": "Grundlegend",
        "BasicText": "(für immer kostenlos)",
        "Advanced": "Erweitert",
        "AdvancedText": " Tage kostenlos testen",
        "No_card_AdvancedText": "Keine Kreditkarte erforderlich",
        "No_card_ProText": "Keine Kreditkarte erforderlich",
        "No_card_StandardText": "Keine Kreditkarte erforderlich",
        "Standard": "Standard",
        "StandardText": " Tage kostenlose Testversion",
        "Pro": "Pro",
        "ProText": " Tage kostenlose Testversion",
        "Business": "Geschäftlich",
        "Enterprise": "Unternehmen",
        "Admonth": "<span class=\"mount mounttop\">$</span><span class=\"mountP\">19.9</span><span class=\"mount\">/monat</span>",
        "Prmonth": "<span class=\"mount mounttop\">$</span>49.9<span class=\"mount\">/monat</span>",
        "Stmonth": "<span class=\"mount mounttop\">$</span>1.99<span class=\"mount\">/monat</span>",
        "Enmonth": "<span class=\"mount mounttop\">$</span>499<span class=\"mount\">/monat</span>",
        "NewStmonth": "<span class=\"mount mounttop\">$</span>9.9<span class=\"mount\">/monat</span>",
        "Adannually": "<span class=\"mount mounttop\">$<s class=\"deleter\">19.9</s></span><span class=\"mountP\">15.9</span><span class=\"mount\">/monat</span>",
        "Prannually": "<span class=\"mount mounttop\">$<s class=\"deleter\">49.9</s></span>39.9<span class=\"mount\">/monat</span>",
        "Stannually": "<span class=\"mount mounttop\">$</span>1.99<span class=\"mount\">/monat</span>",
        "Enannually": "<span class=\"mount mounttop\">$<span class=\"startat\">Startet bei</span><s class=\"deleterStart\">499</s></span>399<span class=\"mount\">/monat</span>",
        "NewStannually": "<span class=\"mount mounttop\">$<s class=\"deleter\">9.9</s></span>7.9<span class=\"mount\">/monat</span>",
        "AdannuallyText": "$15.9/Monat <br /> Jährlich $190.8 <br/> Sie sparen $48/Jahr ",
        "AdannuallyText_2": "$15.9/Monat <br /> Jährlich $190.8 <br/> 2 Monate SPAREN ! ",
        "AdannuallyText_3": "Jährlich abgerechnet zu $190.8 <br/> 2 Monate SPAREN !",
        "PrannuallyText": "39,9 $/Monat <br /> Jährlich 478,8 $ in Rechnung gestellt <br /> Sie sparen 120 $/Jahr ",
        "PrannuallyText_2": "$39.9/Monat <br /> Jährlich $478.8 <br /> 2 Monate SPAREN ! ",
        "PrannuallyText_3": " Jährlich abgerechnet zu $478.8 <br /> 2 Monate AUS !",
        "StannuallyText": "Jährlich abgerechnet zu $23,88",
        "EnannuallyText": "Jährlich abgerechnet zu $4.788 <br /> 2 Monate SPAREN!",
        "NewStannuallyText": "Jährlich abgerechnet zu $94.9 <br /> 2 Monate SPAREN!",
        "Free": "Kostenlos",
        "Monthlyfee": "Monatliche Gebühr",
        "Annualfee": "Jährliche Gebühr",
        "Feature": "Funktion",
        "enterprise_current_plan": "Ihr aktueller Abonnementplan: {Plan}",
        "EnDescribe": "Kontaktieren Sie uns, um weitere Informationen zu erhalten",
        "circulationData": [
            {
                "Feature": "Kundenbetreuung",
                "explain": "24*7 Live-Chat und E-Mail-Service",
                "Basic": "Verfügbar",
                "Standard": "Verfügbar",
                "AdvancedL": "<span class=\"blone and\">Verfügbar & Vorrangig</span>",
                "AdvancedText": "",
                "Pro": "<span class=\"blone and\">Verfügbar & Vorrangig</span>",
                "ProText": "",
                "Enterprise": "<span class=\"blone and\">Verfügbar & Vorrangig</span>",
                "NewStandard": "Verfügbar"
            },
            {
                "Feature": "Bestellungen in großen Mengen verarbeiten",
                "explain": "Verarbeiten Sie mehrere Bestellungen auf einmal bei AliExpress",
                "Basic": "Verfügbar",
                "Standard": "Verfügbar",
                "AdvancedL": "<span class=\"blone and\">Verfügbar und mit hoher Geschwindigkeit zur Bearbeitung von 100+ Aufträgen</span>",
                "AdvancedText": "",
                "Pro": "<span class=\"blone and\">Verfügbar und mit hoher Geschwindigkeit zur Bearbeitung von 100+ Aufträgen</span>",
                "ProText": "",
                "Enterprise": "<span class=\"blone and\">Verfügbar und mit hoher Geschwindigkeit zur Bearbeitung von 100+ Aufträgen</span>",
                "NewStandard": "Verfügbar"
            },
            {
                "Feature": "Laden-Limit",
                "explain": "Verwalten Sie mehrere Geschäfte in einem DSers-Konto",
                "Basic": "<span class=\"blone\">3</span>",
                "Standard": "<span class=\"blone\">5</span>",
                "StandardText": "",
                "AdvancedL": "<span class=\"blone and\">10</span>",
                "AdvancedText": "(Beliebt bei Händlern, die in verschiedenen Ländern verkaufen)",
                "Pro": "<span class=\"blone and\">25</span>",
                "ProText": "(Beliebt bei Händlern, die in verschiedenen Nischen und Ländern verkaufen)",
                "Enterprise": "<span class=\"blone and\">50</span>",
                "EnterpriseText": "",
                "NewStandard": "<span class=\"blone\">3</span>"
            },
            {
                "Feature": "Produktgrenze",
                "Basic": "<span class=\"blone\">3000 </span> /account",
                "BasicText": "",
                "Standard": "<span class=\"blone\">7500</span>  /account",
                "StandardText": "",
                "AdvancedL": "<span class=\"blone and \">20000 </span> /account",
                "AdvancedText": "",
                "Pro": "<span class=\"blone and \">75000 </span> /account",
                "ProText": "",
                "Enterprise": "<span class=\"blone and \">100,000 </span> /account",
                "EnterpriseText": "",
                "NewStandard": "<span class=\"blone\">3000 </span> /account"
            },
            {
                "Feature": "AliExpress Saver Versandadapter",
                "explain": "Profitieren Sie von der kostengünstigsten Versandmethode auf AliExpress",
                "Basic": "<span class='line2'>—</span>",
                "Standard": "<span class='line2'>—</span>",
                "AdvancedL": "<span class=\"blone and \">Bis zu 10 Produkte</span>",
                "AdvancedText": "",
                "Pro": "<span class=\"blone and \">Bis zu 20 Produkte</span>",
                "ProText": "",
                "Enterprise": "<span class=\"blone and \">Unbegrenzte Produkte</span> ",
                "EnterpriseText": "",
                "NewStandard": "<span class='line2'>—</span>"
            },
            {
                "Feature": "Change online products price",
                "explain": "",
                "Basic": "1time/30days",
                "BasicText": "",
                "Standard": "<span style=\"color:#FF8F00;font-weight:600\">1time/30days</span>",
                "StandardText": "",
                "AdvancedL": "<span style=\"color:#FF8F00;font-weight:600\">4times/30days</span>",
                "AdvancedText": "",
                "Pro": "<span style=\"color:#FF8F00;font-weight:600\">(10-mal/30 Tage)</span>",
                "ProText": "",
                "Enterprise": "<span style=\"color:#FF8F00;font-weight:600\">(10-mal/30 Tage)</span>",
                "EnterpriseText": "",
                "NewStandard": "<span style=\"color:#FF8F00;font-weight:600\">1time/30days</span>",
                "NewStandardText": "",
                "CustomBasic": "<span style=\"color:#FF8F00;font-weight:600\">1time/30days</span>"
            },
            {
                "Feature": "Inventarverwaltung",
                "Basic": "Nur Benachrichtigung",
                "Standard": "<span style=\"color:#FF8F00;font-weight:600\">Automatische Aktualisierung</span>",
                "StandardText": "",
                "AdvancedL": "<span style=\"color:#FF8F00;font-weight:600\">Automatische Aktualisierung</span>",
                "AdvancedText": "",
                "Pro": "<span style=\"color:#FF8F00;font-weight:600\">Automatische Aktualisierung</span>",
                "ProText": "",
                "Enterprise": "<span style=\"color:#FF8F00;font-weight:600\">Automatische Aktualisierung</span>",
                "EnterpriseText": "",
                "NewStandard": "<span style=\"color:#FF8F00;font-weight:600\">Automatische Aktualisierung</span>"
            },
            {
                "Feature": "Kostenänderungsmanagement",
                "Basic": "Nur Benachrichtigung",
                "Standard": "Nur Benachrichtigung",
                "AdvancedL": "<span style=\"color:#FF8F00;font-weight:600\">Automatische Aktualisierung</span>",
                "AdvancedText": "",
                "Pro": "<span style=\"color:#FF8F00;font-weight:600\">Automatische Aktualisierung</span>",
                "ProText": "",
                "Enterprise": "<span style=\"color:#FF8F00;font-weight:600\">Automatische Aktualisierung</span>",
                "NewStandard": "Nur Benachrichtigung"
            },
            {
                "Feature": "Automatisierte Preisbildungsregel",
                "Basic": "Nur feste oder prozentuale Erhöhung",
                "Standard": "Feste oder nur prozentuale Erhöhung",
                "AdvancedL": "<span style=\"color:#FF8F00;font-weight:600\">Regeln auf der Grundlage von Gewinnspannen, Versandkosten usw.</span>",
                "AdvancedText": "",
                "Pro": "<span style=\"color:#FF8F00;font-weight:600\">Regeln auf der Grundlage von Gewinnspannen, Versandkosten usw.</span>",
                "ProText": "",
                "Enterprise": "<span style=\"color:#FF8F00;font-weight:600\">Regeln auf der Grundlage von Gewinnspannen, Versandkosten usw.</span>",
                "NewStandard": "Feste oder nur prozentuale Erhöhung"
            },
            {
                "Feature": "Varianten-Mapping",
                "explain": "Verwalten Sie so viele Lieferanten wie Sie wollen, ohne etwas an Ihrem Shop zu ändern",
                "Basic": "Bis zu 2 Lieferanten pro Produkt und kein Mapping der Zielregion.",
                "Standard": "Bis zu 2 Lieferanten pro Produkt und keine Zielgebietszuordnung.",
                "AdvancedL": "<span style=\"color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px\">Jede Variante entsprechend der Zielregion zuordnen</span>",
                "AdvancedText": "",
                "Pro": "<span style=\"color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px\">Jede Variante entsprechend der Zielregion zuordnen</span>",
                "ProText": "",
                "Enterprise": "<span style=\"color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px\">Jede Variante entsprechend der Zielregion zuordnen</span>",
                "NewStandard": "Bis zu 2 Lieferanten pro Produkt und kein Zielgebietsmapping."
            },
            {
                "Feature": "BOGO/Bundle-Zuordnung",
                "explain": "Erstellen Sie Upsells für Produkte, um mehr Verkäufe zu erzielen",
                "Basic": "<span class='line2'>—</span>",
                "Standard": "<span class='line2'>—</span>",
                "AdvancedL": "ok",
                "AdvancedText": "",
                "Pro": "ok",
                "ProText": "",
                "Enterprise": "ok",
                "NewStandard": "<span class='line2'>—</span>"
            },
            {
                "Feature": "Sendungsverfolgung",
                "explain": "",
                "Basic": "<span class='line2'>—</span>",
                "BasicText": "",
                "Standard": "<span class='line2'>—</span>",
                "StandardText": "",
                "AdvancedL": "ok",
                "AdvancedText": "",
                "Pro": "ok",
                "ProText": "",
                "Enterprise": "ok",
                "EnterpriseText": "",
                "NewStandard": "<span class='line2'>—</span>",
                "NewStandardText": "",
                "CustomBasic": "<span class='line2'>—</span>"
            },
            {
                "Feature": "Mitarbeiter-Konto",
                "explain": "",
                "Basic": "<span class='line2'>—</span>",
                "BasicText": "",
                "Standard": "<span class='line2'>—</span>",
                "StandardText": "",
                "AdvancedL": "<span style=\"color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px\">5</span>",
                "AdvancedText": "",
                "Pro": "<span style=\"color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px\">Unlimited</span>",
                "ProText": "",
                "Enterprise": "<span style=\"color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px\">Unlimited</span>",
                "EnterpriseText": "",
                "NewStandard": "<span class='line2'>—</span>",
                "NewStandardText": "",
                "CustomBasic": "<span class='line2'>—</span>"
            },
            {
                "Feature": "Partner",
                "Basic": "<span class='line2'>—</span>",
                "Standard": "<span class='line2'>—</span>",
                "AdvancedL": "ok",
                "AdvancedText": "",
                "Pro": "ok",
                "ProText": "",
                "Enterprise": "ok",
                "NewStandard": "<span class='line2'>—</span>"
            },
            {
                "Feature": "Anpassung",
                "explain": "Passen Sie die DSers-Funktion an und erzielen Sie ein höheres Nutzungsvolumen.",
                "Basic": "<span class='line2'>—</span>",
                "BasicText": "",
                "Standard": "<span class='line2'>—</span>",
                "StandardText": "",
                "AdvancedL": "<span class='line2'>—</span>",
                "AdvancedText": "",
                "Pro": "<span class='line2'>—</span>",
                "Enterprise": "<span style=\"color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px\">Je nach Anfrage oder Nutzungsvolumen kann eine zusätzliche Gebühr erhoben werden.</span>",
                "NewStandard": "<span class='line2'>—</span>"
            },
            {
                Feature: 'Produkt- und Lieferkosten an Shopify senden',
                explain: '',
                Basic: `<span class='line2'>—</span>`,
                BasicText: '',
                Standard: `<span style="color:#FF8F00;font-weight:600">Wenn das Produkt gepusht wird, werden die Kosten für Lieferantenprodukte und die Versandkosten in [Advanced Pricing Rule] zusammen an die Kosten pro Artikel von Shopify </span>`,
                StandardText: '',
                AdvancedL: `<span style="color:#FF8F00;font-weight:600">Wenn das Produkt gepusht wird, werden die Kosten für Lieferantenprodukte und die Versandkosten in [Advanced Pricing Rule] zusammen an die Kosten pro Artikel von Shopify </span>`,
                AdvancedText: '',
                Pro: `<span style="color:#FF8F00;font-weight:600">Wenn das Produkt gepusht wird, werden die Kosten für Lieferantenprodukte und die Versandkosten in [Advanced Pricing Rule] zusammen an die Kosten pro Artikel von Shopify </span>`,
                ProText: '',
                Enterprise:`<span style="color:#FF8F00;font-weight:600">Wenn das Produkt gepusht wird, werden die Kosten für Lieferantenprodukte und die Versandkosten in [Advanced Pricing Rule] zusammen an die Kosten pro Artikel von Shopify </span>`,
                EnterpriseText:'',
                NewStandard:'Fixed or percentage increase only',
                NewStandardText:'',
                CustomBasic:`<span class='line2'>—</span>`
            },
            {
                Feature: 'Synchronize Shopify order customization information',
                explain: ``,
                Basic: "<span class='line2'>—</span>",
                BasicText: '',
                Standard: "<span class='line2'>—</span>",
                StandardText: '',
                AdvancedL: `<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Send the customization information from Shopify to suppliers along with the order.</span>`,
                AdvancedText: '',
                Pro: `<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Send the customization information from Shopify to suppliers along with the order.</span>`,
                ProText: '',
                Enterprise:`<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Send the customization information from Shopify to suppliers along with the order.</span>`,
                EnterpriseText:'',
                NewStandard:"<span class='line2'>—</span>",
                NewStandardText:'',
                CustomBasic:"<span class='line2'>—</span>"
              },
        ],
        "pricingIncludesTitle": "FUNKTIONEN IN ALLEN PLÄNEN ENTHALTEN",
        "pricingIncludesData": [
            {
                "title": "Vorauswahl der Versandart ",
                "desc": "Richten Sie automatische Versandmethoden für AliExpress-Bestellungen basierend auf Ländern, Produkten oder Bedingungen ein."
            },
            {
                "title": "Automatisierte Adresskorrektur ",
                "desc": "Beheben Sie die meisten Adressprobleme automatisch, um den Arbeitsaufwand zu reduzieren."
            },
            {
                "title": "Automatisierte Aktualisierung des Bestellstatus",
                "desc": "Keine manuelle Synchronisierung über die Chrome-Erweiterung mehr. Alles ist automatisiert"
            },
            {
                "title": "Automatische Synchronisierung von Tracking-Nummern mit Shopify",
                "desc": "Automatische Synchronisierung der Sendungsnummern von AliExpress und Aktualisierung in Shopify"
            },
            {
                "title": "Automatische Synchronisierung von Tracking-Nummern mit PayPal",
                "desc": "Verbessern Sie Ihren Ruf, indem Sie Tracking-Nummern an Ihr PayPal-Konto senden"
            },
            {
                "title": "DSers Chrome-Erweiterung",
                "desc": "AliExpress-Produktimporteur und Bearbeitung von Großaufträgen mit erhaltenen Gutscheinen"
            },
            {
                "title": "Automatisierte Nachricht an Lieferanten",
                "desc": "Nachrichtenvorlage senden, um Ihre Lieferanten über Ihre Anfragen zu informieren"
            },
            {
                "title": "Senden Sie kundenspezifische Bestellnotizen von Kunden an Lieferanten ",
                "desc": "Besonders beliebt bei Benutzern, die POD-Geschäfte betreiben"
            },
            {
                "title": "Tracking in der Versandbestätigungs-E-Mail einrichten ",
                "desc": "Passen Sie die Tracking-Informationen in unserer Shopify-E-Mail an "
            },
            {
                "title": "Trending Suppliers finden ",
                "desc": "Trending und qualifizierte Lieferanten Sammlung auf AliExpress "
            },
            {
                "title": "Lieferanten-Optimierer",
                "desc": "Finden Sie den besten Lieferanten für bestimmte Produkte mit nur einem Klick "
            },
            {
                "title": "Liste importieren ",
                "desc": "Verwalten Sie die Produkte, die Sie in DSers importieren, und migrieren Sie einfach Produkte zwischen Ihren Shops."
            },
            {
                "title": "Produkt aufteilen ",
                "desc": "Teilen Sie ein Produkt in mehrere verschiedene Produkte auf, um Ihre Produktlisten individuell zu gestalten. "
            },
            {
                "title": "Produkte in großen Mengen im Shop veröffentlichen",
                "desc": "Veröffentlichen Sie ausgewählte AliExpress-Produkte nach der Bearbeitung in Ihrem Shop"
            },
            {
                "title": "Einstellungen für Zeitzone und Währung ",
                "desc": "Richten Sie Zeitzone und Währungen für Ihren Shop ein "
            }
        ],
        "Unfinishedfront": "Sie haben die Autorisierung auf Shopify nicht abgeschlossen.<br />\n    Bitte klicken ",
        "Unfinishedcentre": "Abonnieren Sie",
        "Unfinishedqueen": "um fortzufahren.",
        "Unfinished": "Wenn Sie Ihren Plan von DSers ändern möchten, kündigen Sie bitte zuerst den aktuellen<br />\n    Abonnement auf Shopify wie unten gezeigt und Sie können den Plan erneut auswählen.",
        "UnfinishedNote": "Anmerkung: <br />\n    1.Sie werden bis zum 31. Dezember 2020 nicht abgerechnet.<br />\n    2.Bitte deinstallieren Sie die DSers-App in Shopify NICHT, sondern kündigen Sie einfach das aktuelle Abonnement. <br />\n    wenn Sie den Tarif wechseln möchten. Die Deinstallation führt zu Datenverlust auf DSers.",
        "AlertText": "Bitte upgraden Sie, um mehr Funktionen und Möglichkeiten zu erhalten. Prüfen Sie mehr Details über <a href=\"https://help.dsers.com/subscription-plans-presentation-for-shopify/\" target=\"_blank\" > DSers Plan </a> .",
        "pop_Up": "Bitte wählen Sie das Geschäft aus, das Sie für die Abrechnung von DSers verwenden möchten",
        "display": "Empfohlen",
        "HomeBasicButton": "Starten Sie",
        "HomeAdvancedButton": "Kostenlose Testversion starten",
        "HomeProButton": "Kostenloses Probeabo starten",
        "HomeStandardButton": "Kostenloses Probeabo starten",
        "Select": "Wählen Sie",
        "Current": "Aktueller Plan",
        "Confirm": "Bestätigen Sie",
        "ConfirmCancel": "Rückgabe",
        "Access": "Zugang",
        "AccessText": "Vielen Dank für Ihr Abonnement. Bitte greifen Sie auf Ihr DSers-Konto zu, viel Spaß!",
        "Monthly": "Monatlicher Zahlungsplan",
        "Annually": "Jährlicher Zahlungsplan",
        "working": "Danke, dass Sie mit DSers arbeiten.",
        "like": "Hat es Ihnen gefallen?",
        "over": "Ihre 14-tägige kostenlose Testphase ist vorbei.",
        "confirm": "Bitte klicken Sie auf die Schaltfläche unten, um das Jahresabonnement auf Shopify zu bestätigen.",
        "Contnct": "Kontakt",
        "Subscribe": "Abonnieren",
        "ChangePlan": "Plan ändern",
        "selectShop": "Bitte wählen Sie aus, was Sie im aktuellen DSers-Plan behalten möchten.",
        "keepShop": "Bitte wählen Sie höchstens {quantity} Geschäfte aus, die Sie behalten möchten.",
        "PlatformAndStores": "Plattform und Shops",
        "ShopifyPlatform": "Shopify-Plattform",
        "Woocommerceplatform": "WooCommerce-Plattform",
        "errText": "Der Standard-Fakturierungsshop { mistakeDomain } ist nicht verfügbar. Bitte überprüfen Sie den Status des Shops auf Shopify oder ändern Sie den Standard-Fakturierungsshop auf DSers.",
        "errTextTwo": "Der Standard-Fakturierungsshop ist nicht verfügbar. Bitte überprüfen Sie den Status des Shops auf Shopify oder ändern Sie den Standard-Rechnungsshop auf DSers.",
        "billing": "Standard-Abrechnungsshop",
        "help": "Der Abrechnungsshop ist der Shop, den Sie für die Bezahlung Ihres Abonnements bei DSers verwenden. Für eine bessere Erfahrung sollten Sie den Billing Store nicht regelmäßig wechseln.",
        "StoreManagement": "Geschäftsleitung",
        "StoreManagementText": "Wenn Sie sich entscheiden, Ihren Tarif herabzustufen, bewahrt DSers Ihre deaktivierten Shops hier auf, so dass Sie die unerwünschten Shops abschalten und andere Shops entsprechend Ihrem Tarif aktivieren können. Sobald Sie die Verbindung getrennt haben, können Sie nicht mehr Geschäfte hinzufügen, als die Beschränkung der verschiedenen Tarife erlaubt.",
        "ActiveStore": "Aktive Filiale",
        "InactiveStore": "Inaktive Filiale",
        "Activate": "Aktivieren",
        "Inactivated": "Inaktiviert",
        "modification": "Unser System hat festgestellt, dass sie ihren Standardshop zu  <span style=\"color:#00BFFF\"> {new}  </span> geändert haben, Abonnement unter dem Shop <span style=\"color:#00BFFF\"> {old} </span> ist abgelaufen. Bitte klicken Sie auf die Schaltfläche unten, um den Standard-Rechnungsshop auf Shopify zu bestätigen.",
        "go_to_shopify": "Zu Shopify gehen",
        "if_free_dec": "Wenn Sie ein kostenloser Shopify-Testbenutzer oder ein Shopify-Entwicklungsshop-Benutzer sind,<br />\n                 können Sie die Seite oben möglicherweise nicht sehen, aber die Seite unten wird angezeigt.<br />\n                 Wenn Sie nicht wissen, wie Sie das laufende Abonnement kündigen können,<br />\n                  gehen Sie bitte zum Shopify-Admin, um DSers zu deinstallieren und erneut zu installieren, um den Plan erneut auszuwählen",
        "change_annual_plan_notion": "Wenn Sie Ihren aktuellen Jahresplan ändern möchten, wenden Sie sich bitte an unseren Kundendienst unter <a href=\"https://www.messenger.com/t/DSersOfficial\" dd=\"src\" target=\"_blank\" rel=\"noopener noreferrer\">Facebook</a> oder per <a href=\"https://www.messenger.com/t/DSersOfficial\">email</a> um die verbleibenden Kosten für Ihren aktuellen Tarif erstattet zu bekommen",
        "subscribe_tios": "Der Standard-Abrechnungsshop ist geschlossen oder deaktiviert. Bitte gehen Sie\n    <a href=\"/app/select\">Abonnemente verwalten </a>\n   um sie zu ändern und erneut zu versuchen",
        "subscription_expired": "Ihr Abonnement ist abgelaufen, bitte erneuern oder ändern Sie es, um fortzufahren.",
        "subscription_network_tip": "If you encounter some network error or any other problems during the payment process, and the subscription is unsuccessful,please <a style=\"color:#00BFFF\" href={jumpUrl}>click here</a> to retry. You can also contact us and we will deal with it for you as soon as possible.",
        "subscription_network_tip_2": "Sollten Sie während des Zahlungsvorgangs auf einen Netzwerkfehler oder andere Probleme stoßen und das Abonnement nicht erfolgreich abschließen können, kontaktieren Sie uns bitte und wir werden uns so schnell wie möglich darum kümmern.",
        "subscription_btn3": "Der erste Monat ist",
        "subscription_btn4": "KOSTENLOS!",
        "subscription_btn5": "Nur für heute!",
        "subscription_btn6": "Wenn Sie ein Upgrade durchführen <span class=\"titlesd\">auf Standard</span>  können Sie jetzt: <br/> 2 <span class=\"titlesd\">weitere</span> Shops hinzufügen <br/> <span class=\"titlesd\">4,500</span> mehr Produkte <br/> Der Produktbestand wird automatisch aktualisiert",
        "subscription_btn7": "JETZT UPGRADE DURCHFÜHREN!",
        "subscription_btn8": "Basisplan beibehalten",
        "subscription_btn9": "Upgrade auf unseren jährlichen Zahlungsplan",
        "subscription_btn10": "SPAREN SIE $120!",
        "subscription_btn11": "Monatlicher Plan",
        "subscription_btn12": "<span class=\"blon\">49.9</span>/mo",
        "subscription_btn13": "Jahresplan",
        "subscription_btn14": "<span class=\"blon\">478.8</span>/Jahr",
        "subscription_btn15": "Monthly cost: <span class=\"blon_d\">$39.9</span>",
        "subscription_btn16": "14 Tage kostenlos testen",
        "subscription_btn17": "AUSWÄHLEN",
        "select_account": "Bitte wählen Sie die  ",
        "select_account_2": "Personalkonten, die Sie behalten möchten",
        "custom_pro": "Custom Pro",
        "Custom_EnterPrise": "Custom EnterPrise",
        "custom_basic": "Benutzerdefiniert Standard",
        "custom_to_basic": "Zum Basic-Tarif wechseln",
        "tobasictext": "Wechsel bestätigen",
        "oCancel": "Abbrechen",
        "tobaiscnote": "Hinweis",
        "tobaiscdes": "Nach dem Downgrade auf den Basic-Tarif haben Sie zwar ein Limit von 3000 Produkten, aber Sie kommen nicht mehr in den Genuss der automatischen Synchronisierung von Beständen und Preisen und können nicht mehr auf den aktuellen Custom Basic-Tarif zurückwechseln. Sind Sie sicher, dass Sie wechseln möchten?",
        "BasicTracking":"basic + tracking",
        "Basic500":"Basic+500",
        "Basic1000":"Basic+1000",
        "CustomBasicTracking":"Custom Basic+Tracking",
        "CustomBasic500":"Custom Basic+500",
        "CustomBasic1000":"Custom Basic+1000",
    }
}