export default {
  guidance: {
    welcome: 'Bem vindo ao DSers', //  m
    need_steps: 'São só <i>4</i> passos para finalizar',
    start_tutorial: 'Começar Tutorial', //  m
    before_starting: `Antes de começar, <br /> tenha certeza que você DESATIVOU o Preenchimento automático do Shopify.<br />
                      caso contrário, você não poderá processar seus pedidos no DSers.<br/>
                      <i>Vá em Shopify - Configurações e Checkout para desativar</i>`,
    previous: 'Anterior',
    next: 'Próximo',
    start: 'Começar',
    link_to_more_stores: 'Vincular mais lojas',
    bind_ali_over: 'Conta do Aliexpress Verificada',
    link_to_ae: 'Vincular aoAliexpress',
    home: 'Home',
    my_product: 'Meus Produtos',
    setting: 'Configurações',
    shipping: 'Envio',
    orders: 'Pedidos',
    new_feature: 'Novo Recurso',
    notification: 'Notificação',
    blog: 'BLOG',
    order: 'How to place multiple orders with DSers?',
    import: 'How to import products to DSers?',
    step1: {
      title: 'Primeiro, conecte o DSers na sua loja Shopify',
      step1_dec:
        'Clique no botão <i>vincular mais lojas</i> no topo da página.',
      step2_dec:
        'Escolha Shopify como plataforma e preencha o nome para vincular ao DSers'
    },
    step2: {
      title: 'Em seguida, vincule o DSers à sua conta do AliExpress.', //  m
      step1_dec:
        'Clique no botão <i>Vincular ao AliExpress </i> na parte superior da página do DSers. Você será redirecionado para a página de login do AliExpress. Faça login na conta que deseja vincular',
      step3_dec:
        'Solicite sua conta Verificada no AliExpress e tenha benefícios como realizar pedidos em massa e reduzir a taxa de pedidos recusados.'
    },
    step3: {
      title: 'Em seguida, mapeie produtos para um fornecedor do AliExpress', // m
      dec:
        'Clique no botão no lado direito do cartão de um produto para mapear os fornecedores do AliExpress para seus produtos do Shopify. O DSers saberá como processar seus pedidos para o AliExpress depois disso.',
      map_multiple:
        'O DSers deixa você mapear múltiplos fornecedores de acordo com:', // uuu
      map_from_to: '1. Enviar de/para Países',
      advance: `Verificar detalhes sobre o mapeamento <a
                  href="https://help.dsers.com/advanced-mapping-for-shopify/"
                  target="_blank"
                >Avançado</a> <a
                  class={foreignLink}
                  href="https://help.dsers.com/advanced-mapping-for-shopify/"
                  target="_blank"
                >
                </a>`,
      upsell_promotion: '2. Promoção de Upsell',
      bogo_bundle: `Verificar detalhes sobre <a
                      href="https://help.dsers.com/create-buy-one-get-one-offers-for-shopify/"
                      target="_blank"
                    >BOGO</a> <a
                      class={foreignLink}
                      href="https://help.dsers.com/create-buy-one-get-one-offers-for-shopify/"
                      target="_blank"
                    >
                    </a> (compre um, ganhe um grátis) / Mapeamento de <a
                      href="https://help.dsers.com/create-bundles-of-products-for-shopify/"
                      target="_blank"
                    >pacotes</a> <a
                      class={foreignLink}
                      href="https://help.dsers.com/create-bundles-of-products-for-shopify/"
                      target="_blank"
                    >
                    </a>`
    },
    step4: {
      title: 'Não se esqueça de configurar o método de envio', //  m
      dec: `Para o último passo de preparação, por favor escolha seu método de envio padrão em Configurações - Envios.
            Você pode configurar baseado no País ou <a
              href="https://help.dsers.com/set-shipping-method-for-all-products-for-shopify/"
              target="_blank"
            >produto do AliExpress</a> <a
              class={foreignLink}
              href="https://help.dsers.com/set-shipping-method-for-all-products-for-shopify/"
              target="_blank"
            >
            </a> .`
    },
    step5: {
      title: 'Por fim, você poderá fazer pedidos em massa', //  m
      dec:
        'Selecione todos os seus pedidos na guia Aguardando pedidos e clique no botão Fazer pedido no AliExpress',
      tip:
        'Revise os detalhes do pedido e o método de envio. Então você está pronto!'
    },
    after: {
      dec:
        'Depois de efetuar o pagamento, deixe o resto para o DSers.<br /> O DSers <b>sincronizará automaticamente</b> para você',
      p1:
        'Sincronização automática do status do pedido e código de rastreamento do AliExpress para o DSers a cada 12 horas.',
      p2:
        'Sincronização automátia de códigos de rastreamento do o DSers para o Shopify leva entre 1 e2 horas após sincronizar do AliExpress',
      p3:
        'Acione automaticamente o e-mail de confirmação do Shopify Shipping para seus clientes'
    },
    actions: {
      title:
        'As ações abaixo irão previnir o DSers de processar ou <br /> sincronizar pedidos, Por favor:',
      do_not1:
        '<i>NÃO PREENCHA</i> pedidos manualmente no DSers ou Shopify se voc',
      do_not2: '<i>NÃO ATIVE</i> o recurso auto preenchimento no Shopify',
      do_not3: '<i>NÃO</i> solicite o processamento no Shopify.'
    },
    get_ebook:
      'Obtenha o Ebook grátis ou entre em contato com o serviço de atendimento ao cliente aqui', // m
    check_again: 'Veja este tutorial novamente aqui', //  a
    confused: 'caso ainda esteja confuso sobre como usar o DSers', // a
    guidance_again: 'Ver os tutoriais em vídeo novamente.',
    total_sales:'Total Sales',
    shopify_sales:'Shopify Sales',
    ali_orders:'Ali Orders',
    supplier_cost:'Supplier Cost'
  }
};
