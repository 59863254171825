import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { Input, Select, Button, Icon, message } from 'antd';
import intl from 'react-intl-universal';
import { aeUrlMatchReg, tmallUrlMatchReg } from 'utils/constants';
import { fixIsURL, isUrl } from 'utils/utils';

import styles from './SearchInput.less';

import searchIcon from 'assets/newImportList/icon-search.png';
import allow_clear from 'assets/allowClear.svg';

const InputGroup = Input.Group;
const { Option } = Select;

export function SearchInput({
  props,
  defaultSelectPlatform, // 默认选中的商品平台
  onChange,
  checkUrl, // 判断是否需要检测不是URL
  onSubmit,
  value,
  onKeyUp,
  onFocus,
  autoFocus,
  allowClear,
  loading,
  placeholder,
  openInputBtnText, // 开启input动画按钮文案
  defaultValue,
  onlySearch, // 动画
  hiddenSelect, // 隐藏select
  selfSearchIcon, // 自定义提交按钮
  getPlatform, // 获取选择店铺信息
  onError, // 输入的url与select不符回调
  isShowTmallOption = true, //是否显示tmall选项
  isShowAeOption = true, //是否显示ae选项
}) {
  const searchDomRef = useRef();

  const productValueRef = useRef(value);
  const defaultSelectValue = useRef(defaultSelectPlatform);

  const [searchStatus, setSearchStatus] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(true);
  const [selectValue, setSelectValue] = useState('AliExpress');
  const [urlError, setUrlErr] = useState(false);
  const [checkIsUrl, setCheckIsUrl] = useState(false);
  const [inputBlur, setInputBlur] = useState(autoFocus);
  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    productValueRef.current = value;
  }, [value]);

  useEffect(() => {
    if (defaultSelectPlatform) {
      defaultSelectValue.current = defaultSelectPlatform;
      setSelectValue(defaultSelectValue.current);
      getPlatform(defaultSelectValue.current);
    }
  }, [defaultSelectPlatform]);

  useEffect(() => {
    if (onlySearch) {
      setSearchStatus(true);
      setButtonStatus(false);
    }
  }, [onlySearch]);

  useLayoutEffect(() => {
    let inDown = false;
    const handleMouseDown = (e) => {
      inDown = searchDomRef.current?.contains(e.target);
    };
    const handleMouseUp = (e) => {
      if (productValueRef.current) {
        setInputBlur(false);
      }
      if (inDown || productValueRef.current) {
        setSearchStatus(true);
      } else {
        setSearchStatus(false);
        setUrlErr(false);
        setCheckIsUrl(false);
        setInvalid(false);
        setTimeout(() => {
          setButtonStatus(true);
        }, 600);
      }
    };

    document.addEventListener('mousedown', handleMouseDown, false);
    document.addEventListener('mouseup', handleMouseUp, false);
    if (onlySearch) {
      setSearchStatus(true);
      setButtonStatus(false);
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [onlySearch]);

  const openSearch = (e) => {
    e.preventDefault();
    setSearchStatus(true);
    setButtonStatus(false);
  };

  const changeSelectHandler = (value) => {
    setSelectValue(value);
    getPlatform(value);
    setUrlErr(false);
    setCheckIsUrl(false);
    setInvalid(false);
  };

  const checkUrlHandler = () => {
    if (checkUrl === 'mappingSearch') {
      if (!aeUrlMatchReg.test(value) && !tmallUrlMatchReg.test(value)) {
        // setCheckIsUrl(true);
        return;
      } else {
        setCheckIsUrl(false);
      }
    } else if (
      checkUrl &&
      (aeUrlMatchReg.test(value) || tmallUrlMatchReg.test(value))
    ) {
      setCheckIsUrl(true);
      setInvalid(false);
      setUrlErr(false);
      return;
    } else {
      setCheckIsUrl(false);
    }
    // 如果是天猫或者阿里的时候
    if (aeUrlMatchReg.test(value) || tmallUrlMatchReg.test(value)) {
      // 货源不对应的时候return
      if (
        (selectValue === 'AliExpress' && !aeUrlMatchReg.test(value)) ||
        (selectValue === 'Tmall' && !tmallUrlMatchReg.test(value))
      ) {
        setUrlErr(true);
        setInvalid(false);
        setCheckIsUrl(false);
        onError && onError(selectValue);
        return;
      } else {
        setUrlErr(false);
      }
    } else {
      // 如果不是天猫或者阿里平台
      setInvalid(false);
      setUrlErr(false);
    }
    onSubmit(value, selectValue);
  };

  const onChangeHandler = (e) => {
    if (e === '' || e?.target?.value === '') {
      setUrlErr(false);
      setCheckIsUrl(false);
      setInvalid(false);
      onChange('');
      return;
    }
    onChange(e);
  };

  return (
    <div className={styles.container}>
      <div className={styles.searchInputContainer}>
        <div
          className={styles.searchbtn}
          style={{ display: buttonStatus ? 'block' : 'none' }}
        >
          <Button onClick={openSearch}>
            <img src={searchIcon} alt="" />{' '}
            <span className={styles.span}>
              {openInputBtnText ? openInputBtnText : 'Search'}
            </span>
          </Button>
        </div>
        <div
          className={styles.searchinputbox}
          ref={searchDomRef}
          style={{
            width: searchStatus ? '100%' : '0px',
            position: onlySearch ? 'relative' : null,
          }}
        >
          <InputGroup compact>
            {hiddenSelect ? null : (
              <Select
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                value={selectValue}
                onChange={changeSelectHandler}
                style={{
                  margin: '0px',
                  overflow: 'hidden',
                }}
              >
                {isShowAeOption ? (
                  <Option value={'AliExpress'}>AliExpress</Option>
                ) : null}
                {isShowTmallOption ? (
                  <Option value={'Tmall'}>Tmall</Option>
                ) : null}
              </Select>
            )}
            <span
              className={styles.search_input}
              style={{
                width: hiddenSelect ? '100%' : null,
                borderBottomLeftRadius: hiddenSelect ? null : '0px',
                borderTopLeftRadius: hiddenSelect ? null : '0px',
                border: searchStatus ? null : 'none',
              }}
            >
              <Input
                placeholder={
                  placeholder || intl.get('product.search_placeholder')
                }
                onFocus={onFocus}
                onKeyUp={onKeyUp}
                className={styles.input}
                style={{
                  width: hiddenSelect ? '100%' : null,
                }}
                defaultValue={defaultValue}
                value={value}
                onChange={onChangeHandler}
                ref={(input) => {
                  if (input != null && searchStatus && inputBlur) {
                    input.focus({
                      cursor: 'end',
                    });
                  }
                }}
                onPressEnter={() => {
                  checkUrlHandler();
                }}
                allowClear={false}
                size="large"
              />
            </span>
            <span className={styles.functions}>
              {searchStatus && value && allowClear ? (
                <img
                  className={styles.allowClear}
                  src={allow_clear}
                  onClick={() => {
                    onChangeHandler('');
                  }}
                />
              ) : null}
              <Button
                className={styles.submitbtn}
                style={{
                  border: searchStatus ? '' : 'none',
                  display: searchStatus ? null : 'none',
                }}
                loading={loading}
                onClick={() => {
                  checkUrlHandler();
                }}
              >
                {selfSearchIcon ? (
                  selfSearchIcon
                ) : (
                  <i class="material-icons">search</i>
                )}
              </Button>
            </span>
          </InputGroup>
        </div>
      </div>
      <div
        className={styles.errorTip}
        style={{
          display: urlError ? 'block' : 'none',
        }}
      >
        {urlError
          ? selectValue === 'AliExpress'
            ? intl.get('public.search_input_url_error_match_ae_tip')
            : intl.get('public.search_input_url_error_match_tm_tip')
          : null}
      </div>
      <div
        className={styles.errorTip}
        style={{
          display: checkIsUrl ? 'block' : 'none',
        }}
      >
        {checkIsUrl ? intl.get('product.search_notice_for_migrate') : null}
      </div>
      <div
        className={styles.errorTip}
        style={{
          display: invalid ? 'block' : 'none',
        }}
      >
        {invalid ? intl.get('public.search_input_url_invalid_tip') : null}
      </div>
    </div>
  );
}
