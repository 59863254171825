
    export default 
    {
    "send_reset_email": {
        "title": "Resetta la password",
        "tip": "Invieremo un codice di veridica alla tua email per verificare la tua identità",
        "dec": "Inserisci l'indirizzo email che hai usato per la registrazione. Se hai installato DSers da Shopify, sei pregato di inserire l'indirizzo email del tuo account Shopify. Se hai dimenticato l'email, per favore",
        "contact_us": "Contattaci",
        "placeholder": "Email",
        "email_instructions": "Istruzioni via email",
        "send_succ": "Ti sono state inviate via email le istruzioni per resettare la tua password.",
        "email_not_valid": "L'email inserita non è valida!",
        "email_empty": "Si prega di inserire una email!",
        "email_unknown": "Si prega di inserire la tua email di registrazione."
    }
}