import { fetch } from 'dva';
import { BASE_URL, BASE_OUT_URL, goToWoo } from '../common/sysconfig';
import { Post, Get } from './request';
import getPaymentData from './getPaymentData';
import Cookies from 'js-cookie';
import moment from 'moment';

export function fixedZero(val) {
  return val * 1 < 10 ? `0${val}` : val;
}

/**
 * Get router routing configuration
 * { path:{name,...param}}=>Array<{name,path ...param}>
 * @param {string} path
 * @param {routerData} routerData
 */
export function getRoutes(path, routerData) {
  let routes = Object.keys(routerData).filter(
    (routePath) => routePath.indexOf(path) === 0 && routePath !== path
  );
  // Replace path to '' eg. path='user' /user/name => name
  routes = routes.map((item) => item.replace(path, ''));
  // Get the route to be rendered to remove the deep rendering
  // const renderArr = getRenderArr(routes);
  // Conversion and stitching parameters
  const renderRoutes = routes.map((item) => {
    // const exact = !routes.some(
    //   route => route !== item && getRelation(route, item) === 1
    // );
    return {
      exact: true,
      ...routerData[`${path}${item}`],
      key: `${path}${item}`,
      path: `${path}${item}`,
    };
  });
  return renderRoutes;
}

/* eslint no-useless-escape:0 */
const reg =
  /(((^https?:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)$/g;

export function isUrl(path) {
  reg.lastIndex = 0;
  return reg.test(path);
}

export function fixIsURL(str) {
  // 创建一个正则表达式
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // 协议
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // 域名
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // IP 地址
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // 端口号和路径
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // 查询字符串
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // 锚点

  // 使用正则表达式测试输入字符串
  return pattern.test(str);
}

export function getApiUrl(url, isOutApi = false, newEnv) {
  if (newEnv) {
    if (isOutApi) {
      return `${window.DSERS_API_CONFIG.HOST}${url}`;
    }
    return `${window.DSERS_API_CONFIG.HOST}${url}`;
  }
  if (isOutApi) {
    return `${window.DSERS_API_CONFIG.HOST}${BASE_OUT_URL}${url}`;
  }
  return `${window.DSERS_API_CONFIG.HOST}${BASE_URL}${url}`;
}

// setLocalStorage 设置LocalStorage 并设置过期时间
export function setLocalStorage(variable, value, ttl_ms) {
  const data = { value, expires_at: new Date().getTime() + ttl_ms / 1 };
  localStorage.setItem(variable.toString(), JSON.stringify(data));
}

// getLocalStorage 获取LocalStorage ,如果已经过期删除
export function getLocalStorage(variable) {
  const data = JSON.parse(localStorage.getItem(variable.toString()));
  if (data !== null) {
    if (data.expires_at !== null && data.expires_at < new Date().getTime()) {
      localStorage.removeItem(variable.toString());
    } else {
      return data.value;
    }
  }
  return null;
}

// setLocalStorage 设置LocalStorage 并设置过期时间
export function setSessionStorage(variable, value, ttl_ms) {
  const data = { value, expires_at: new Date().getTime() + ttl_ms / 1 };
  sessionStorage.setItem(variable.toString(), JSON.stringify(data));
}

// getLocalStorage 获取LocalStorage ,如果已经过期删除
export function getSessionStorage(variable) {
  const data = JSON.parse(sessionStorage.getItem(variable.toString()));
  if (data !== null) {
    if (data.expires_at !== null && data.expires_at < new Date().getTime()) {
      sessionStorage.removeItem(variable.toString());
    } else {
      return data.value;
    }
  }
  return null;
}

// getAliExpressUrl 根据速卖通商品ID拼装速卖通商品链接
export function getAliExpressUrl(productid) {
  return `https://www.aliexpress.com/item/product/${productid}.html`;
}

// tmall 链接
export function getTmallUrl(productid, pre) {
  if (pre) {
    return `/app/tmallproduct/${productid}?appId=159831084`;
  }
  return `https://www.dsers.com/app/tmallproduct/${productid}?appId=159831084`;
}

export function getAliExpressUrlV2(productid) {
  return `https://www.aliexpress.com/item/${productid}.html`;
}

export function getAliExpressId(url) {
  return url.match(/aliexpress\S*\/(\S*)\.html/)[1];
}

export function getTmallId(url) {
  return url.split('?')[0]?.match(/tmallproduct\S*\/(\S*)/)[1];
}

export function loadAliExpressPortals(
  user,
  admitadCheckCode,
  affiliateStatus = 104
) {
  const key = 'AE_PORTALS_INDEX_URL';

  // if ([1, 6].includes(admitadCheckCode)) {
  //   return;
  // }

  if (!getLocalStorage(key)) {
    createIFrameForCB(
      user,
      'myproduct',
      '',
      '',
      '',
      '100000',
      admitadCheckCode,
      affiliateStatus
    );
    setLocalStorage(key, true, 2 * 3600 * 1000);
  }
}

//
// user_id=123123&store_id=sdfasdfasdfasdf&is_adblock=0&code=sdfasdf&code_type=1&order_id&product_id&sub_id=10001
export function createIFrameForCB(
  user,
  actionType,
  storeid,
  orderid,
  product_id,
  subid,
  admitadCheckCode,
  affiliateStatus,
  callback
) {
  let blocked = 1;
  if (document.getElementById('2a074d356f827ecff5838d210848adf8')) {
    blocked = 0;
  }

  // if ([1, 6].includes(admitadCheckCode)) {
  //   if(typeof callback === 'function'){
  //     callback(product_id);
  //   }
  //   return;
  // }

  // 如果当前uid与uaa org id不一致说明是子账号 需要使用uua org id
  // 这里兼容uaa接口调用失败的情况
  const affUid =
    user?.uaa_org_id && user?.user_id !== user?.uaa_org_id
      ? user.uaa_org_id
      : user?.user_id;

  const code = localStorage.getItem('userCode') || user.code;
  // 返点的链接现在都是靠http状态码进行重定向 这里可以使用img标签进行请求
  const helperImg = document.createElement('img');

  let codeStr = '';
  const plan = getPaymentData();
  // 仅 Advanced 和 Pro 发送 code
  if (plan && [2, 3, 5, 6, 7].includes(plan.type)) {
    codeStr = `&code=${code}`;
  }

  helperImg.style.display = 'none';
  // 返点链接加载后可以移除img标签
  helperImg.addEventListener('load', () => {
    // console.log('affiliate loaded');
    document.body.removeChild(helperImg);
    if (typeof callback === 'function') {
      callback(product_id);
    }
  });
  helperImg.addEventListener('error', (e) => {
    // console.log('affiliate error', e);
    document.body.removeChild(helperImg);
    if (typeof callback === 'function') {
      callback(product_id);
    }
  });
  helperImg.src = `https://x.dsers.com/api/v1/logs/c?user_id=${affUid}&store_id=${storeid}&is_adblock=${blocked}${codeStr}&code_type=${affiliateStatus}&order_id=${orderid}&action_type=${actionType}&product_id=${product_id}&sub_id=${subid}&ver=1`;
  document.body.appendChild(helperImg);
}

// Promise 用于循环
export const createCashBackIframeAsync = (
  user,
  actionType,
  storeid,
  orderid,
  product_id,
  subid,
  admitadCheckCode,
  affiliateStatus = 104
) => {
  return new Promise((resolve) => {
    let finished = false;
    setTimeout(() => {
      if (!finished) {
        finished = true;
        resolve();
      }
    }, 5000);
    createIFrameForCB(
      user,
      actionType,
      storeid,
      orderid,
      product_id,
      subid,
      admitadCheckCode,
      affiliateStatus,
      () => {
        if (!finished) {
          finished = true;
          resolve(product_id);
        }
      }
    );
  });
};

export const createCashBackTask = async (
  userInfo,
  admitadCheckCode,
  orders,
  affiliateStatus,
  localAEInfo
) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const data of orders) {
    if (data.childrens.length) {
      const code = localStorage.getItem('userCode') || userInfo.code || '';
      // eslint-disable-next-line no-restricted-syntax
      for (const item of data.childrens) {
        if (item.is_black_list === 2 || !item.sp) {
          continue;
        }
        try {
          const spArr = item.sp;
          // eslint-disable-next-line no-restricted-syntax
          for (const sp of spArr) {
            if (sp.platform_product_id && sp.status === 2) {
              const affiliateCache = getSessionStorage(sp.platform_product_id);
              if (!affiliateCache || affiliateCache?.code !== code) {
                const timestamp = +new Date();
                setSessionStorage(
                  sp.platform_product_id,
                  {
                    store_id: data.storeId,
                    order_id: data.id,
                    code,
                    code_type: userInfo?.code_type || 0,
                    code_dead_at: userInfo?.code_dead_at || '',
                    code_account: userInfo?.code_account || '',
                    ae_login_status: affiliateStatus,
                    timestamp,
                    ae_product_id: sp.platform_product_id,
                    local_aff_pid: localAEInfo?.localAffPid || '',
                  },
                  300 * 1000
                );
                // eslint-disable-next-line no-await-in-loop
                await createCashBackIframeAsync(
                  userInfo,
                  'splitorder',
                  data.storeId,
                  data.id,
                  sp.platform_product_id,
                  '100002',
                  admitadCheckCode,
                  affiliateStatus
                );
              }
            }
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  }
};

export function getShopifyStoreStatus(storeid) {
  const stores = JSON.parse(localStorage.getItem('storeStatus')) || [];
  let update = false;

  stores.forEach((item) => {
    if (item.id == storeid) {
      update = item.update;
    }
  });

  return update;
}

export function getLanguage() {
  return localStorage.getItem('lang') || 'en-US';
}

export function goWWW() {
  const url = location.href;
  if (url.includes('https://dsers.com/')) {
    location.href = url.replace('https://dsers.com/', 'https://www.dsers.com/');
  }
}

// 也不知道干吗的，复制来自 login/register page 方法：getQueryString
export const getChannelString = () => {
  try {
    const hash = `?${location.hash.split('?')[1]}`;
    const r = hash.substr(1).match(/(^|&)channel_id=([^&]*)(&|$)/i);

    if (r != null) {
      return unescape(r[2]);
    }
  } catch (error) {
    console.log('getChannelString error:', error);
  }

  return '';
};

export const formatCurrency = (val) => {
  if (typeof val === 'number') {
    return val.toLocaleString('en');
  }
  if (typeof val === 'string') {
    return val.trim() === '' ? val : parseFloat(val).toLocaleString('en');
  }
  return val;
};

export const isInt = (value) => {
  return (
    !isNaN(value) &&
    parseInt(Number(value)) == value &&
    !isNaN(parseInt(value, 10))
  );
};

export const getUserPlatform = (status = 0, stores = []) => {
  if (status === 0) return 'shopify';
  const statusStr = `${status}`;
  const platStatus = statusStr.substring(2, 3);
  const csvStatus = statusStr.substring(3, 4);
  // 平台选择了woo
  if (platStatus === '1') {
    return 'woo';
  }
  // 平台选择了csv并且没有任何shopify店铺
  if (csvStatus === '1' && !stores.length) {
    return 'csv';
  }
  return 'shopify';
};

export const replaceUrlToHttps = (url) =>
  (url && url.replace(/^(http):/, '$1s:')) || url;

export const getBaseLayoutPopupContainer = () =>
  document.getElementById('base-layout-popover-refer') || document.body;

export const getChromeExtUrl = () =>
  `https://chrome.google.com/webstore/detail/dsers-aliexpresscom-produ/mmanaflgaempokjfbeeabkadnkoidjam?hl=${getLanguage()}`;

export const isChrome = () =>
  /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9\.]+)(:?\s|$)/.test(navigator.userAgent);

export const isWindows = () => /(Windows NT)/.test(navigator.userAgent);

export const isMacOS = () =>
  /(Mac_PowerPC)|(Macintosh)/.test(navigator.userAgent);

export const couldInstallChromeExt = () => {
  let res = false;
  try {
    res = isChrome() && (isMacOS() || isWindows());
  } catch (error) {
    console.log(error);
  }
  return res;
};

export async function skipShopfiy() {
  // fetch(`${window.DSERS_API_CONFIG.HOST}/api/v1/woo/access`, {
  //   method: 'POST',
  //   headers: {
  //     Authorization: localStorage.getItem('dser-pro-authority')
  //   },
  //   body: JSON.stringify({})
  // })
  //   .then(response => {
  //     return response.json();
  //   })
  //   .then(stories => {
  //     if (stories.code == 2000 || stories.code == 2010) {
  //       window.location.href = `${goToWoo}?key=${stories.data.key}`;
  //     }
  //   });
  window.location.href = goToWoo;
}

export const getCookie = (cookie_name) => {
  var allcookies = document.cookie;
  var cookie_pos = allcookies.indexOf(cookie_name);

  if (cookie_pos != -1) {
    cookie_pos = cookie_pos + cookie_name.length + 1;
    var cookie_end = allcookies.indexOf(';', cookie_pos);

    if (cookie_end == -1) {
      cookie_end = allcookies.length;
    }
    var value = unescape(allcookies.substring(cookie_pos, cookie_end));
  }
  return value;
};

export const CommonDataCollect = async (params, callback) => {
  let custom_data = [];
  const nowTime = parseInt(new Date().getTime() / 1000);
  //step_stage: "STEP_STAGE_"
  const migrationStore = JSON.parse(
    localStorage.getItem('migration_stores')
  )?.slice(-1)[0];

  if (params?.event_type == 'overlimit_pop') {
    params.custom_data = params?.custom_data || [];
  }

  try {
    const res = await Post('/collect/web_unify', {
      data: {
        log_category: 'data-collect',
        event_category: params?.event_category ? params?.event_category : 'web',
        event_type: params?.event_type ? params?.event_type : 'migration',
        event_label: params?.event_label ? params?.event_label : 'collect',
        version: 1.0,
        trace_id: '',
        log_create_time: nowTime,
        user_id: JSON.parse(localStorage.getItem('payment'))?.user_id,
        store_id: '',
        shop_name: '',
        sale_platform: '',
        sale_platform_product_id: '',
        sale_platform_order_id: '',
        supply_platform: '',
        supply_platform_product_id: '',
        supply_platform_order_id: '',
        trans_from: '',
        trans_to: '',
        ...params,
        // custom_data:[...custom_data, ...params.custom_data],
      },
    });
    if (callback) {
      callback();
    }
    return res;
  } catch {
    throw new Error('webMigrationCollect error');
  }
};

export const webCustomCollect = async (params) => {
  let custom_info = [];

  try {
    const res = await Post('/collect/web_custom', {
      data: {
        user_id: JSON.parse(localStorage.getItem('payment'))?.user_id,
        store_id: localStorage.getItem('storeId') || '',
        platform: 'Shopify',
        page: location.pathname,
        module: location.pathname,
        page_url: location.pathname,
        product_id_list: [],
        order_id_list: [],
        request_ip: '',
        ...params,
        custom_info: [...custom_info, ...params.custom_info],
      },
    });
    return res;
  } catch {
    throw new Error('webCustomCollect error');
  }
};

export const webCommonCollect = async (params, callback) => {
  let firstTime = localStorage.getItem('first_visit_time');
  let newUser = 1;

  let nowTime = moment(Number(firstTime)).format('YYYY-MM-DD');
  let endTime = moment(+new Date()).format('YYYY-MM-DD');

  //如果当前时间一致
  if (endTime == nowTime) {
    newUser = 0;
  } else {
    newUser = 1;
  }

  let custom_info = [];

  /**
   *  兼容 _ds_ver的值
   *
   *  0 为新平台
   *  1 为老平台
   */

  // custom_info.push({
  //   name:'platform',
  //   value:'0'
  // })

  try {
    const result = await Post('/collect/web_common', {
      data: {
        _ga: Cookies.get('_ga') || '',
        _da: localStorage.getItem('_da'),
        _clck: Cookies.get('_clck') || '',
        referrer: sessionStorage.getItem('referrer') || '',
        is_new_user: newUser,
        front_version: '1.0.4',
        first_visit_time: Number(firstTime),
        user_id: JSON.parse(localStorage.getItem('payment'))?.user_id,
        store_id: localStorage.getItem('storeId'),
        platform: 'shopify',
        page: location.pathname,
        module: location.pathname,
        page_url: location.pathname,
        product_id_list: [],
        order_id_list: [],
        ...params,
        custom_info: [...custom_info, ...params.custom_info],
      },
    });

    if (callback) {
      callback();
    }
  } catch (error) {
    throw new Error('webCommonCollect error');
  }
};

export const getAESkuWithoutDefineName = (skuAttr) =>
  typeof skuAttr === 'string'
    ? skuAttr?.replace(/(#[\s\S]+?)(;|$)/g, '$2')
    : '';

export const getAESkuMap = (skuItems) => {
  return skuItems?.reduce
    ? skuItems?.reduce((prev, current) => {
        if (current.id) {
          // eslint-disable-next-line no-param-reassign
          prev[getAESkuWithoutDefineName(current.id)] = current;
        } else {
          // eslint-disable-next-line no-param-reassign
          prev['<none>'] = current;
        }
        return prev;
      }, {})
    : {};
};

// 上报 新手引导完成步骤
export const sendNoviceGuidanceInfo = async (
  { imported, pushed, mapped, placed, display },
  callback
) => {
  // console.log(imported,pushed,mapped,placed,display,'\\\\\\\\\\\\\\\\');
  const res = await Post(
    '/api/v1/tutorial/new_user',
    {
      data: {
        imported: imported,
        pushed: pushed,
        mapped: mapped,
        placed: placed,
        display: display,
      },
    },
    true
  );
  if (res.code == 2010) {
    callback();
  }
};

export const oberloReport = async (report_name, report_value) => {
  return Post(
    '/mgnoberlo-core-api/dsers-api/migration/action/customize',
    {
      data: {
        name: report_name,
        value: report_value,
      },
    },
    true
  );
};

export const getOberloReport = async (report_name) => {
  return await Get(
    '/mgnoberlo-core-api/dsers-api/migration/action/customize',
    {
      data: {
        name: report_name,
      },
    },
    true
  );
};

export const getStoreStatus = () => {
  const stores = JSON.parse(localStorage.getItem('store'))?.stores;
  const obj = { shopify_domains: [] };
  for (let i = 0; i < stores?.length; i++) {
    obj.shopify_domains.push(stores[i].domain);
  }
  return obj;
};

export const sleep = (seconds) => {
  return new Promise((resolve) => setTimeout(resolve, seconds * 1000 || 1000));
};

export const getSupplierUrl = (type, id, pre) => {
  if (type === 4) {
    return getTmallUrl(id, pre);
  }
  if (type === 2) {
    return getAliExpressUrl(id);
  }
  return getAliExpressUrl(id);
};

export const getSupplierId = (type, url) => {
  if (type === 4) {
    return getTmallId(url);
  }
  if (type === 2) {
    return getAliExpressId(url);
  }
  return getAliExpressId(url);
};
