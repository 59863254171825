export default {
  notification: {
    notifications: 'Notificações',
    mark_all_as_read: 'Marcar como lido',
    tip:
      'Você só receberá notificações para os produtos que foram pedidos no passado. Notificações no DSers serão mostradas durante 30 dias',
    regarding_to_aliexpress_statement: 'Regarding to Aliexpress statement:', // d
    product_price_has_changed: 'O custo do produto foi alterado', // m
    product_out_of_stock:
      'The AliExpress product is out of stock or not available anymore', //  m
    order_canceled: 'The order has to be canceled:', // d
    order_no: 'Order No: ', // d
    ali_no: 'Ali No: ', // d
    income: 'Income：', // d
    check: 'Check', // d
    view: 'View', // d
    more: 'More', // d
    no_data: 'Your Notification List is Empty', // d
    no_data_tip:
      'We will notify you about changes to product(s) availability or price', // d
    at: 'em',
    not_available: 'Não disponível',
    sku: 'SKU',
    const_change: 'Mudança de custo',
    got_it: 'Ok, Entendido.', //  m
    go_to_shopify: 'Go to shopify', // d
    stock: 'Estoque',
    no_match: 'No Match', //  d
    check_product: 'Check product', // d
    copy_succ: 'Successfully copied', //  d
    copy_fail: 'copy failed', //  d

    ali_product: 'Produto do Aliexpress', //  add
    related_shopify_products: 'Produto(s) relacionado(s) na loja Shopify :', //  add
    price: 'Preço', //  add
    compared_at_price: 'Comparar preço', //  add
    update_to_shopify: 'Atualizar no Shopify', //  add
    variants_out_of_stock: 'A SKU do AliExpress foi alterada', //  add
    variants_not_of_stock: 'Product not available anymore',
    variants_out_zero_stock:
      "As variantes do produto no AliExpress foram esgotadas ou não estão disponíveis",
    related_shopify_products_length:
      'Produto no Shopify mapeado com este produto do AliExpress', //  add
    view_price: 'Ver preço', //  add
    view_variant: 'Ver variante', //  add
    offline_product:
      'Você tem certeza que quer por este produto offline? Clicando em Confirmar, este produto será mais visível na sua loja Shopify', //  add
    store_not_available: 'The shop is no longer available',
    product_is_offline: 'Produto está offline', //  add
    put_offline: 'Escolha um produto para colocar off-line', //  add
    set_new_value: 'Colocar Novo Valor', //  add
    multiply_by: 'Multiplicar por', //  add
    enter_value: 'Digite o Valor', //  add
    enter_multiplier: 'Digite o Multiplicador', //  add
    change_all: 'Modificar tudo', //  add
    mapping_changed:
      'Você modificou o mapeamento padrão, o custo do produto mudou', //  add
    product_deleted:
      'O produto do Shopify usando este fornecedor foi excluído, nenhuma informação detalhada pode ser exibida.', //  add
    store_removed:
      'A loja do Shopify à qual este produto pertence foi desconectada do DSers, nenhuma informação detalhada pode ser exibida.',
    focus_on_orders: {
      p1: `Você tem `,
      p2: `pedidos cancelados, verifique-os`
    },
    view_canceled_orders: 'Ver pedidos cancelados',
    noti_tit:'Notificação',
    shopPrice:'Note that the currency shown here is the currency you set in DSers, not your Shopify currency',
    modalTitle:"Delete Notification(s)",
    modalText:"Are you sure you want to delete the notification(s)? It will be removed from your notification list.",
    filter_variant_out_of_stock:'Variante(s) esgotada(s)',
    cancelOrder:'Pedido cancelado',
    clearFilter:'LIMPAR TODOS OS FILTROS',
    filterTitle:'Filtrar notificação',
    defaultSupplier:'Fornecedor padrão',
    secondSupplier:'Fornecedor substituto',
    allNotification:'Ver todas as notificações',
    mappingPlaceholder:'Escolha um produto para mapeá-lo novamente',
    copyTitle:"Copiar título",
    copied:'Título copiado',
    filter:"Filtrar",
    product_not_found:'Product not found',
    product_out_of_stock_tip:'Product out of stock',
    get_product_err: `Error in obtaining product information, if you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`,
    get_product_err_2: `There was an error obtaining product information, please try again later. If you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`,
    no_support_mapping: `Because the mapping method of the product is not supported, the detailed information cannot be displayed. If you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`,
    mapping_trouble: `Detailed information cannot be displayed due to a problem with the SKU or mapping of the product. If you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`,
    product_sku_change: `Details cannot be displayed due to a change in the SKU of the item. If you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`
  }
};
