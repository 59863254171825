import React from 'react';

import styles from './SessionOfBargain.less';

import Point from './Point';
import BotMessage from './BotMessage';
import UserMessage from './UserMessage';

function SessionOfBargain({ status, onWatchVideo }) {
  return (
    <div className={styles.rightChat}>
      <div className={styles.chatCon}>
        {status === 'importFail' ? (
          <>
            <BotMessage showAvatar>
              Oops
              <span role="img" aria-label="icon">
                😱
              </span>
              , it seems that there was an unexpected issue and the product{' '}
              <i>failed to be imported</i> to DSers
            </BotMessage>
            <UserMessage showAvatar>
              <span
                onClick={() => onWatchVideo('03kJr74CQzY')}
                data-category="tutorial"
                data-action="watching a related video"
                role="button"
                tabIndex="-1"
              >
                It’s OK. I would like to watch a related video
              </span>
              <Point
                style={{ bottom: 20, left: '30%', position: 'absolute' }}
                showPoint={false}
              />
            </UserMessage>
          </>
        ) : (
          <>
            <BotMessage showAvatar>
              We found no product or orders in your store, so let’s start with
              <b> importing an AliExpress product to DSers and Shopify</b>{' '}
              before we process the test order.
            </BotMessage>
            <BotMessage>
              <p>
                You are in DSers Marketplace. We only show products from
                selected suppliers here.
              </p>

              <div>
                Please click the <b>Import to DSers</b> button to see what will
                happen to this test product thread.
              </div>
            </BotMessage>
          </>
        )}
      </div>
    </div>
  );
}

export default SessionOfBargain;
