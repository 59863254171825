export default {
  order: {
    "order_language":{
      "pay" : "Efetue o pagamento dos pedidos em até 24 horas, do contrário eles serão cancelados pelo Tmall."
    },
    tab_tip: {
      pending: `Pedidos "Pendentes" são pedidos que o pagamento do seu cliente ainda não foi capturado no Shopify.
                Você não pode processar o editar pedidos pendentes no DSers até que o pagamento seja capturado.
                Se o pedido continua pendente após capturar o pagamento, por favor acesse <a
                  href='https://help.dsers.com/pending-orders-introduction-for-shopify/'
                  target="_blank"
                  data-ga="Pending - here"
                >aqui</a> para atualizar o status do pedido manualmente, Entre em contato conosco se isto continuar acontecendo.`,
      awaiting_order: `Los pedidos en "Pedido Pendiente" son pedidos cuyo pago de tus clientes ha sido registrado en Shopify y están a la espera de ser procesado a AliExpress.
      Si no puedes localizar tus nuevos pedidos aquí, por favor revisa la pestaña "Pendiente" y "Completado". Consulta cómo procesar los pedidos a AliExpress <a
                          href='https://help.dsers.com/fulfill-multiple-orders-from-start-to-finish-for-shopify/'
                          target="_blank"
                          data-ga="Awaiting order - here" >aquí </a>.`,
      awaiting_payment: `Pedidos "Aguardando Pagamento" são pedidos que foram colocados no AliExpress mas não ainda não foram pagos.
                        O número de pedido do AliExpress é gerado automaticamente quando você o realiza.
                        Clique <a
                          href='https://help.dsers.com/pay-multiple-orders-on-aliexpress-with-dsers-for-shopify/'
                          target="_blank"
                          data-ga="Awaiting payment - here1"
                        >aqui</a> para saber como fazer pagamentos em massa. Você também pode ver <a
                          href='https://help.dsers.com/re-order-awaiting-payment-orders-for-shopify/'
                          target="_blank"
                          data-ga="Awaiting payment - here2"
                        >aqui</a> sobre como cancelar e realizar o pedido novamente.
                        Caso note que o status do pedido não mudou após o pagamento, acesse <a
                          href='https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/'
                          target="_blank"
                          data-ga="Awaiting payment - here3"
                        >aqui</a>.`,
      awaiting_shipment: `Pedidos em "Aguardando envio" são pedidos que foram pagos no AliExpress porém ainda não foram enviados pelo seu fornecedor.
                          Por favor não preencha pedidos manualmente no DSers, caso faça, nós não iremos atualizar o status do pedido ou o número de rastreamento.
                          Caso acredite que o código de rastreamento não está sincronizando após o envio, acesse <a
                            href='https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/'
                            target="_blank"
                            data-ga="Awaiting shipment - here"
                          >aqui</a>`,
      fulfilled: `Pedidos em "Preenchido" são pedidos que foram enviados pelo seu fornecedor no AliExpress e pedidos que foram preenchidos manualmente ou automaticamente.
                  Quando um pedido é preenchido, nós não iremos mais sincronizar o status do pedido ou o preço do AliExpress. Clique <a
                    href='https://help.dsers.com/re-order-request-fulfillment-order-for-shopify/'
                    target="_blank"
                    data-ga="Fulfilled - here1"
                  >aqui</a> para verificar pedios que foram preenchidos por engano.
                  Você também pode verificar <a
                    href='https://help.dsers.com/pay-multiple-orders-on-aliexpress-with-dsers-for-shopify/'
                    target="_blank"
                    data-ga="Fulfilled - here2"
                  >aqui</a> para aprender como o DSers preenche pedidos automaticamente.<br>
                  If you want to synchronize the latest Tracking Number of AliExpress orders, you can click the Sync Tracking Number from last 30 days button. We will synchronize the latest Tracking Number of AliExpress orders from the last 30 days.`,
      fulfilledFirstChange:`Orders in "Fulfilled" are orders that have been shipped out by your AliExpress supplier and orders that were automatically or manually fulfilled.`,
      fulfilledFirstChange_tit:`Once an order is fulfilled, we will no longer synchronize the order status or product price from AliExpress anymore. Click <a target="_blank" href="https://help.dsers.com/re-order-fulfilled-orders-for-shopify/">here</a> to check how to deal with orders that were fulfilled by mistake. You can also check <a target="_blank" href="https://help.dsers.com/fulfill-multiple-orders-from-start-to-finish-for-shopify/">here</a> to learn how DSers fulfill orders automatically.`,
      fulfilledDays:`You can synchronize the order status and tracking numbers`,
      fulfilledDays_tit:`If you want to synchronize the latest Tracking Number of AliExpress orders, you can click the Sync Tracking Number from last 30 days button. We will synchronize the latest Tracking Number of AliExpress orders from the last 30 days.`,
      canceled: `Pedidos em "Cancelado" são pedidos que são (parcialmente) Cancelados / (parcialmente) Reembolsados no AliExpress ou Shopify.
                 Você pode realizar em massa os pedidos Cancelados do AliExpress, mas não pode solicitar os pedidos cancelados ou reembolsados no Shopify.
                 o status do pedido não é atualizado após o cancelamento, verifique <a
                  href='https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/'
                  target="_blank"
                  data-ga="Canceled - here1"
                 >aqui</a>. E você também pode verificar <a
                  href='https://help.dsers.com/re-order-aliexpress-canceled-orders-for-shopify/'
                  target="_blank"
                  data-ga="Canceled - here2"
                 >aqui</a> para saber como o DSers pode ajudá-lo com pedidos que continuam sendo cancelados pelo AliExpress.`,
                 canceledChange:`Orders in "Canceled" are orders that are (partially) Canceled / (partially) Refunded on AliExpress or Shopify.`,
                 canceledChange_tit:`You can bulk order the Canceled orders from AliExpress, but you cannot order the orders canceled of refunded on Shopify. If you find that the order status is not updating after cancellation, please check <a target="_blank" href="https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/">here</a>. And you can also check <a target="_blank" href="https://help.dsers.com/re-order-aliexpress-canceled-orders-for-shopify/">here</a> to learn how DSers can help you with orders that keep getting canceled by AliExpress.`,
                 failed_orders: `Pedidos em "Com Falha" são pedidos que não podem ser feitos com sucesso no AliExpress devido a erros ou restrições.
                      Verifique as informações dos clientes e resolva os erros para poder realizar os pedidos falhos novamente.
                      Existe uma nova regra para pedidos enviados para o Brasil, verifique <a
                        href='https://help.dsers.com/orders-to-brazil-specifications-for-shopify/'
                        target="_blank"
                        data-ga="Failed orders - here"
                      >aqui</a> para obter informações.`,
      awaiting_fulfillment: `Pedidos em "Aguardando Preenchimento" são pedidos que contém diversos produtos mas estão parcialmente preenchidos.
                            Os produtos preenchidos serão mostrados aqui e uma vez que todos os pedidos estejam preenchidos no DSers,
                            o pedido será atualizado e irá para a aba "Preenchidos", então,
                            iremos sincronizar o número de rastreamento para o Shopify e notificar o Shopify para enviar somente um o e-mail para o seu cliente.
                            Clique <a
                              href='https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/'
                              target="_blank"
                              data-ga="Awaiting fulfillment - here"
                            >aqui</a> para mais informações.`
    },
    //  d
    alert_fulfilled_by_other: {
      w1: 'Note please. ',
      w2:
        'Please check if there are any orders already fulfilled on platforms like Oberlo or Dropified.'
    }, //  d
    //  d

    rut_banner: {
      title: 'Orders to Chile RUT number requirements',
      btn_text: 'learn more'
    },
    
    alert_sync_by_extend: {
      w1: 'Please Note: We can now synchronize AliExpress order status in',
      w2: ' real time ',
      w3: 'with two steps !',
      w4: ' Click here ',
      w5: 'to view details.'
    }, //  d
    search_type: {
      order_no: 'Número do pedido',
      note: 'Nota',
      ali_order_no: 'Número do pedido Aliexpress',
      tm_order_no:"Número do Tmall",
      product: 'Produto',
      email: 'E-mail',
      customer_name: 'Nome do cliente',
      tracking_no: 'Número de rastreamento',
      searchOrder:'Pesquisar pedido',
      agency_order_no: 'Número do pedido da agência',
    },
    search_input_placeholder:
      'Você pode escolher uma categoria para pesquisar com precisão',
    syncing: 'Sincronizando...',
    sync_ali_order: 'Sincronizar pedido Aliexpress',
    sync_via_api:
      'Sincronizar via API (Você pode sincronizar manualmente {length} vezes por dia)', //  add
    sync_tn: 'Sync Tracking Number from last 30 days（You can manually sync from AliExpress {length} more time(s) today）',
    sync_tn_2:'Sync the latest Tracking Number with the Chrome Extension',
    sync_via_extension:
      'Sincronizar via Extensão DSers para Chrome', //  add
    syncing_ali: 'Orders are synchronizing',
    sync_ali_completed: 'Orders synchronization completed',
    select_sync_range: 'Select the date range to update Tracking Numbers',
    sync_task_count: 'Number of orders that need to be synchronized',
    sync_except_time: 'Approximate time required',
    sync_ali_failed_result: {
      title: 'The situations below may cause the failure:',
      reason1: `1.The AliExpress account you currently log in doesn't match the one linked to DSers.`,
      reason2: '2.You logged out your AliExpress account during the synchronization.',
      reason3: '3.There was something wrong with your network.',
      contact: 'If you have checked the above but it still fails, please feel free to <a rel="noopener noreferrer" target="_blank" href="https://www.messenger.com/t/DSersOfficial" dd="src">contact us</a>.'
    },
    start_time: 'Data do pedido:',
    end_time: 'Data finalizada',
    tab: {
      title:
        '{key, plural, =1 {Pendentes} =2 {Aguardando Pedido} =3 {Aguardando <br /> Pagamento} =4 {Aguardando <br /> envio} =5 {Preenchido} =6 {Cancelado} =7 {Com Falha} =9 {Aguardando <br /> Preenchimento} }', // modify
      all_tip:
        'Total order amount might not be equal to the sum of Awaiting Order, Awaiting Payment, Awaiting Shipment and Fullfilled Order as some order may fail.', // d
      canceled_tip: `pedidos cancelados no Shopify ou no AliExpress, pelo AliExpress, pelo seu cliente ou manualmente pelos usuários, estão listados aqui.
                    Os pedidos cancelados no AliExpress podem ser feitos novamente.`
    },
    place_order: {
      btn:
        '{key, plural, =1 {Place order to AliExpress} =4 {Place order again} }',
      tm_btn:
        '{key, plural, =1 {Efetuar pedido em massa} =4 {Place order again} }',
      tip: `Você precisa mapear o produto para que saibamos qual o seu fornecedor antes de realizar pedidos em massa.<br />
            <a
              href="https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/"
              target="_blank"
            >
              Veja este artigo
            </a>`,
      agency_btn: '{key, plural, =1 {Checkout em massa} =4 {Place order again} }',
    },
    more_action: {
      title: 'Mais ações',
      action_menu: 'Preencher pedidos manualmente',
      action_menu_tip:
        'Não será enviado ao Aliexpress, apenas marcado como processado',
      tip:
        'Se você NÃO quiser processar alguns pedidos com DSers, poderá marcá-los manualmente como processados aqui. E isso não afetará o status do seu pedido no Shopify.',
      tip_link: 'Veja este artigo'
    },
    canceled_by: {
      placeholder: 'Cancelado pelo',
      shopify: 'Shopify',
      ae: 'AliExpress',
      "tm":"Tmall",
      agency_in_dsers: 'You on DSers'
    },
    fulfilled_by: {
      placeholder: 'processado por',
      shopify: 'Shopify', // add
      ae: 'DSers',
      self: 'processado manualmente'
    },
    country: 'País',
    make_payment: {
      btn: 'Fazer pagamento',
      h3:
        'Faça seu pagamento no site oficial do Aliexpress<br/> para completar seu pedido',
      h4: 'O que acontece com pagamentos atrasados ?',
      p1: '1. Seus pedidos podem ser cancelados',
      p2: '2. Sua conta pode experimentar falha de pagamento',
      h5:
        'Por favor faça seu pagamento o mais rápido possível para satisfazer<br/> seus clientes!'
    },
    orderQuantityToolTip:{
      "title": "Ainda há {items} itens nos outros menus do pedido",
      "aliexpress": "Pedidos AliExpress",
      "agency": "Pedidos de agência",
      "unmapped": "Pedidos não mapeados"
    },
    agency_orders: {
      upload_tn_title: 'Carregar nº de rastreamento manualmente',
      upload_tn_sub_title: 'Após carregar o número de rastreamento, o DSers retornará à Shopify e marcará o pedido correspondente com o status “Processado”.',
      upload_file_tip: 'Clique aqui para carregar o arquivo',
      uplaod_match_column: 'Selecione os campos correspondentes no arquivo do Excel e nós os combinaremos automaticamente e os carregaremos.',
      upload_agency_order_id: 'ID do pedido da agência',
      upload_product_id: 'ID do produto do item',
      upload_sku_id: 'ID da SKU do item',
      upload_shipping_carrier: 'Transportadora',
      upload_custom_shipping_url: 'URL de rastreamento personalizada',
      upload_tracking_number: 'Número de rastreamento',
      upload_select_placeholder: 'Selecione',
      upload_confirm: 'Confirmar',
      upload_payment_title: 'Importar detalhes de pagamento',
      upload_payment_sub_title: 'Carregue um arquivo do Excel contendo as despesas de compra. O DSers fará o registro delas, o que ajudará você na gestão dos seus pedidos.',
      upload_payment_currency: 'Moeda de compra (padrão USD)',
      upload_product_cost: 'Custo de compra',
      upload_shipping_cost: 'Custo de envio',
      download_order_info: 'Exportando informações do pedido',
      download_by_select: '{value} pedidos foram selecionados',
      download_by_date: 'Exportar pedidos por data',
      download_title: 'Exportar',
      download_as: 'Exportar como',
      export_to_file_type_excel: 'Arquivo comum do Excel',
      download_btn: 'Exportar pedidos',
      export_orders_action: 'Exportar pedidos',
      import_payment_details: 'Importar info de pagamento',
      import_tn_to_orders: 'Envio manual de nº rastreamento',
      mark_as_cancel: 'Cancelar pedidos manualmente',
      mark_as_cancel_title: 'Confirmar cancelamento de pedido',
      mark_as_cancel_desc: 'Observe que o pedido será marcado como “Cancelado” no DSers e o seu status não será sincronizado com a Shopify.',
      please_input_confirm: 'Digite CONFIRMAR.',
      mark_as_paid_title: 'Marcar pedido como pago',
      mark_as_paid_desc: 'Certifique-se de que o pedido foi liberado off-line junto ao seu fornecedor. Após marcar o pedido manualmente como pago, ele receberá o status “Aguardando envio”.',
      cancel_order_failed: 'Falha no cancelamento do pedido, tente novamente mais tarde.',
      place_confirm_title: 'Confirmar informações do pedido',
      place_btn_tip: 'Clique no botão “Checkout em massa” e o DSers verificará os dados de endereço do seu pedido.',
      agency_canceled_tag: 'Cancelado no DSers',
      from_agency: 'Agency',
      agency_order_number_full: 'Agency order number',
      export_failed: 'A exportação falhou. Isso pode ter ocorrido devido a um erro de rede. Tente novamente mais tarde.',
      export_failed_empty: 'A exportação falhou, pois não há nenhum pedido no intervalo de tempo selecionado. Você pode selecionar um intervalo de tempo diferente e tentar de novo.',
      upload_success: 'Carregamento bem-sucedido',
      upload_failed: 'Falha no carregamento',
      upload_failed_reason: 'Motivos da falha do download',
      uploading: 'Carregando',
      export_download_link_title: 'Se o download não começar automaticamente, clique aqui',
      mark_as_paid_failed: 'Erro ao marcar o pedido como pago manualmente, tente novamente mais tarde.',
      exporting_orders: 'Exporting',
      agency_fulfill_tip: 'Tem certeza que deseja PROCESSAR manualmente esses pedidos?',
      agency_rollback_title: 'Reverta os pedidos selecionados e você encontrará pedidos na guia Pedidos em espera. As informações do pedido antigo serão perdidas após a reversão',
      agency_edit_tracking_no_tip: 'After you successfully modify a Tracking No, you may resend the mail to your buyer according to your Settings, please be careful',
      agency_auth_status_processing: 'Processando ERP',
      agency_auth_status_offline: 'Pedido off-line',
      agency_process_tag_tip: `As informações do pedido foram enviadas ao ERP do fornecedor e o tempo de criação estimado é de 2 horas. Após a criação bem-sucedida, você poderá comunicar a ordem de compra ao seu fornecedor e efetuar o pagamento. Se o status não for atualizado após um longo período, tente processar o pedido manualmente.`,
      erp_payment_tip_title: 'Confirme',
      erp_payment_tip_content: `Detectamos que o pedido pago contém um pedido que deve ser criado no ERP do fornecedor. Confirme que o seu fornecedor foi informado sobre a quantidade total do pedido a ser processado antes de efetuar o pagamento.`,
      erp_auth_type_filter: 'Tipo',
      payment: {
        make_payment: 'Make Payment',
        make_payment_tip: `Efetue os pagamentos para concluir os seus pedidos. Complete o pagamento o mais breve possível para deixar os seus clientes satisfeitos!`,
        currency_diff_title: 'Cuidado',
        currency_diff_content: 'Certifique-se de que os pedidos selecionados estejam na mesma moeda do mesmo fornecedor. Você pode modificar a moeda e carregar novamente o arquivo CSV em lotes baseados em diferentes moedas.',
        currency_diff_btn: 'ENTENDI',
        payment_modal_title: 'Confirme as informações do pedido e da agência antes de fazer o pagamento.',
        payment_1: "Due to restrictions from {type},  each order must be over $1.00. Please contact your supplier to modify.",

        edit_agency_info: 'Editar informações da agência',
        agency_info_name: 'Nome da agência',
        agency_info_paypal: 'PayPal da agência',
        agency_info_amount: 'Valor estimado do pagamento',
        agency_info_amount_v2: 'Payment amount',
        agency_inpayment_tip: 'The payment is being paid, please complete the rest of the payment.',
        get_payment_detail_failed: 'O pagamento expirou.',
      },
      order_confirm_erp_title: 'ERP',
      order_confirm_erp_tip_1: `Você vinculou o ERP deste fornecedor. Após o pedido ser efetuado com sucesso, enviaremos o pedido diretamente ao seu fornecedor.`,
      order_confirm_erp_tip_2: `Você não vinculou o ERP deste fornecedor. Após o pedido ser efetuado com sucesso, você terá que exportar manualmente o pedido e enviá-lo ao seu fornecedor.`
    },
    order_title: {
      order_no: 'Número do pedido',
      date: 'Data',
      country: 'País',
      income: 'Faturamento',
      note: {
        title: 'Nota',
        add: 'Adicionar'
      },
      customer_detail: 'Detalhes do cliente',
      title:'Filtrar pedido'
    },
    edit_address: {
      contact_name: 'Nome do contato:',
      tel: 'Telefone',
      email: 'Email: ',
      company: 'Empresa: ',
      cpf: 'CPF', // add
      country: 'País: ',
      address: 'Endereço: ',
      province: 'Província: ',
      city: 'Cidade: ',
      post_code: 'CEP:',
      clearance_info: 'Personal Clearance ID',
      alien_clearance_info: 'Passport/ Alien registration Card Number',
      clearance_info_required:
        'The personal clearance unique code consists of a 12-digit number starting with P. Fill in this field if the customer is a native Korean.',
      clearance_info_tip:
        'The personal clearance unique code consists of a 12-digit number starting with P (eg, P682151173203). Click <a href="https://help.dsers.com/orders-to-korea-specifications-for-shopify/" target="_blank" rel="noopener noreferrer" >here</a> to get more information.',
      alien_clearance_info_required:
        'Please enter your Passport or Alien registration card number. Fill in this field if the customer is NOT a native Korean.',
      alien_clearance_info_tip:
        'Incorrect Format. Enter 1- 13 digits or letters.Click <a href="https://help.dsers.com/orders-to-korea-specifications-for-shopify/" target="_blank" rel="noopener noreferrer" >here</a> to get more information.',
      rut_required: 'Insira um número RUT',
      rut_incorrect: 'Parece que houve um erro com o número RUT',
      rut_tip:
        'Please read our <a href="https://help.dsers.com/orders-to-chile-specifications-for-shopify/" target="_blank" rel="noopener noreferrer" >blog</a> to learn more',
      unknow_wrong:
        'Tem algo errado com as informações do seu cliente. Por favor verifique e revise.',
      enter_name: 'Coloque um nome de Contato',
      enter_between: 'Coloque entre {start} e {end} caracteres',
      enter_write_tel:"Please fill in your real phone number here, enter between 5-16 numbers. Click <a href='https://help.dsers.com/fix-failed-orders-due-to-aliexpress-phone-number-regulations/' target='_blank'>here</a> to learn how to solve this problem",
      korea_error:'Please enter {start} or {end} -digit phone number starting with 01 or 1 (e.g. 01012345678)',
      italy_error:'Please enter {start} or {end} digits',
      english_only: 'Escreva somente em inglês',
      max128:
        'A soma do nome do contato e nome da empresa não pode superar 128 caracteres',
      only_num_hyphens_forward:
        "Coloque somente os nomes e/ou ifens '-' e/ou barras '/'",
      cpf_tip:
        'CPF might be wrong. If you checked it, please ignore this message. Please go to AliExpress to double check.',
      enter_country: 'Escreva um País/Região',
      enter_address: 'Coloque um endereço',
      enter_address_length: "Please ensure that your address is not less than 5 characters.",
      select_province: 'Coloque um Estado/Província/Região',
      enter_city: 'Coloque uma cidade',
      city_not_match: 'Cidades não encontradas. Escolha na lista abaixo',
      enter_zip: 'Coloque um CEP/Código Postal',
      enter_zip_1234:
        'Coloque um endereço de CEP de {length} dígiyos, como {ps}',
      enter_zip_123456: 'Coloque um endereço de CEP, como {ps}',
      enter_zip_special:
        'Coloque um endereço de CEP no ormato sugerido, como {ps}',
      enter_zip_confirm:
        'Para entrega internacional, o cep com 7 dígitos é necessário. Você pode confirmar com seu correio local caso necessário',
      only_num_spaces_hyphens:
        'Digite somente letras/números/espaços/ífens latinos',
      france_phone_setting_tip:
        'For orders sent to France, it requires 10 digits mobile phone numbers starts with 06.',
      france_phone_customers_tip:
        'For orders sent to France, it requires 10 digits mobile phone numbers.'
    },
    order_product: {
      title_unarchive:`Unarchive: After order unarchived, you can find the order in the 'Open orders' tab.`,
      variants: 'Variantes:',
      from: 'De',
      from_aliexpress: 'Aliexpress',
      from_other: 'Outra',
      cost: 'Custo',
      quantity: {
        // d
        price: 'price',
        ok: 'Ok',
        cancel: '',
        max: 'Maxium'
      }, // d
      dsers_shipping: 'ePacket', //  d
      shipping_method: 'Método de Frete',
      shipping_dialog: {
        company: 'Transportadora',
        cost: 'Custo do Frete',
        time: 'Tempo estimado de entrega',
        tracking: 'Informações de frete',
        price: 'Frete Grátis',
        days: 'dias',
        available: 'Disponível',
        unavailable: 'Indisponível',
        ok: 'Ok',
        cancel: 'Cancelar',
        setting_link: 'Configurar método de envio no painel',
        overrun_warning:
          'Você excedeu o limite diário de acesso aos dados dos produtos do AliExpress, mas ainda pode importá-los e enviá-los à loja.',
        async_frienght:`Update shipping method list`,
        upcoming1:`Não foi possível definir o método de envio. Possíveis razões são:`,
        upcoming2:`1) O mapeamento não foi definido para este produto.`,
        upcoming3:`2) O fornecedor não realiza entregas no destino do pedido. Confirme o endereço de entrega com o usuário ou use a função “Editar” para alterar para um fornecedor que ofereça a entrega no destino.`,
      },
      shipping_cost_free: 'Grátis',
      shipping_time: 'Dias',
      ali_order_no: 'Número do Aliexpress',
      tmall_order_no: "Número do Tmall",
      order_no: 'Número do pedido',
      tracking_no: 'Número de rastreamento',
      tracking_no_add: 'adicionar',
      sync: 'Sincronizar',
      ali_order_no_no_match:
        "Can't find this order to this Ali order number. Please check it.", // d
      mapping: 'Mapeando',
      mapping_tip:
        'Clique em Mapeamento para gerenciar o fornecedor para este produto.',
      customer_service: 'Serviço ao cliente',
      action: {
        title: 'Ação',
        message: 'Mensagem',
        edit: 'Editar',
        mark_as_fulfilled: 'Marcar como processado',
        bulk_edit: 'Bulk Edit' // d
      },
      fulfilled_by_other_tip: `Você processou os pedidos errado?
                              Se você quiser processar as ordens via DSers,
                              Você não pode preenche-la manualemnte no DSers ou no Shopify.
                              E Você precisa desativar o processamento automático no Shopify.
                              Se você deseja reverter os pedidos,
                              Por favor clique no botão do messenger para receber ajuda.
                              Please check <a
                              style="color:#FF8F00"
                                href="https://help.dsers.com/re-order-fulfilled-orders-for-shopify/"
                                target="_blank"
                                data-ga="wrongly fulfilled - here"
                              >here</a> to get step by step operation manual.`,
      ae_order_error_tip: {
        //  d
        click: 'Click',
        message_us: 'Message Us',
        why_see_this: 'Why am I seeing this?',
        ae_not_available:
          'This product is not available on AliExpress. Please go and check on AliExpress.',
        sku_not_found: 'SKU is not found. Please click',
        sku_not_found_after: 'to add an AliExpress product link.',
        no_shipped_country:
          "This product can't be shipped to to this country. Click&nbsp;",
        no_shipped_country_after: '&nbsp;to change an AliExpress product.',
        freight_error: 'Please fill in the address information of the order to choose the shipping method',
        set_shipping: {
          p1: 'Choose a',
          p2: 'shipping method',
          p3: 'to active order feature.',
          p4: 'Set up shipping method in Setting panel'
        },
        add_ae_link: {
          p1: 'Add AliExpress link successfully! Choose a',
          p2: 'shipping method',
          p3: 'to active order feature.'
        },
        add_ae_link_succ: 'Add AliExpress link successfully !',
        delete_by_shopify: 'The product(s) was deleted from shopify'
      }
    },
    bind_product: {
      ae_url: 'URL do Aliexpress',
      ae_url_tip: "Insira a URL do produto do AliExpress",
      tmall_url_tip: "Insira a URL do produto do Tmall",
      from_aliexpress: 'Do Aliexpress',
      cost: 'Custo',
      select_varint:
        'Corrent variants are <b>{num}</b> Please select the corresponding variant:', // modify
      overrun_warning:
        'You exceeded the daily limitation to access the product information'
    },
    order_foot: {
      cost: 'Custo do produto',
      shipping: 'Frete',
      total: 'Custo total',
      pending_authorized: 'Autorizado',
      pending_authorized_tip:
        'O pagamento desse pedido precisa ser capturado no Shopify.',
      shopify_deleted: 'Shopify deleted',
      is_shopify_delete:
        'Order deleted on Shopify. If you still want to process the order, please create a new similar order on Shopify.',
      product_cost_tip: {
        title: 'Some reasons may have caused the cost difference:',
        p1:
          '1. DSers only shows the basic standard cost on AliExpress. But the real cost varies according to the country you are selling to.',
        p2:
          '2. DSers does not support different currencies at the moment, only USD. Please make sure the currency is the same on DSers and AliExpress when cross-checking product costs.',
        p3:
          '3. DSers does not update product cost for Fulfilled or Pending orders, once the order status is updated to Awaiting order, the product cost will update automatically.',
        p3_1:
          'Please kindly note the product cost will be updated every 24 hours on DSers.',
        p4:
          '4. Taxes will be applied to orders shipping to some countries, like the USA and Australia.',
        p_end:
          'The correct cost of the product will appear after you place the order to AliExpress.'
      },
      shipping_tip: `The final shipping cost shown here will be the same as on the AliExpress orders and will be up to your suppliers. When a discount applies to your AliExpress orders, the discount amount will be counted in the Shipping cost here.
                    <a
                      href="https://help.dsers.com/shipping-fees-on-dsers-for-shopify/"
                      target="_blank"
                    >
                      Please check the blog for further details.
                    </a>`,
      order_again: 'Realizar pedido novamente',
      order: 'Realizar pedido',
      order_tip: `Você precisa mepaar o produto para que o DSers saiba qual provedor você está trabalhando antes de fazer pedidos em massa.<br/>
                  <a
                    href="https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/"
                    target="_blank"
                  >
                    Veja este artigo
                  </a>`,
      tax_title: 'Tax',
      other_title: 'Other',
      tax_tip: `If you want the tax displayed here, please use the 
      <a
        href="https://www.dsers.com/dsers-chrome-extension/"
        target="_blank"
      >
        DSers Chrome Extension
      </a> to place the order.`,
      shipping_tip_ext: 'The final shipping cost shown here will be the same as on the AliExpress orders and will depend on your suppliers.',
      other_tip_ext: 'When a discount applies to your AliExpress orders, the discount amount will be counted in here.',
      order_displayed:`The order displayed price is not in USD. DSers converted to USD according to the exchange rate. This may be different from the actual price on AliExpress. DSers only displays it for information, it will not affect the amount to pay. Please check AliExpress to see the actual amount to pay`
    },
    sync_order_alert: `O DSers será sincronizado automaticamente no Shopify e no AliExpress quando você fizer login no DSers.
                      A sincronização durará 10 minutos.
                      O DSers não sincronizará automaticamente a cada 6h para garantir uma sincronização mais estável.
                      Certifique-se de fazer login se desejar sincronizar o status do pedido.`, //  add
    cpf_alert: `Devido a novos regulamentos,
                todos os pedidos para o Brasil devem conter o número do CPF, ou os pedidos falharão.
                s DSers fará com que o número do CPF apareça em seus pacotes.
                Você pode adicionar o número do CPF do seu cliente no campo "Empresa", em Detalhes do cliente.`, //  add
    time_zone_placeholder: 'Fuso Horário', //  add
    af_more_action: 'Sincronizar código de rastreamento para o Shopify', //  add
    shopify_fulfilled: 'Shopify preenchido', //  add
    shopify_canceled: 'Shopify cancelado', //  add
    shopify_partially_fulfilled: 'Shopfy parcialmente preenchido', //  add
    shopify_partially_canceled: 'Shopify parcialmente cancelado', //  add
    shopify_refunded: 'Shopfify reembolsado', //  add
    ali_canceled: 'AliExpress canceled',
    tm_canceled: 'Tmall canceled',
    zero_tip: `Solicitar manualmente o cumprimento no Shopify causou o problema.
              Preencha este pedido manualmente no DSers e depois reverta o pedido.
              A quantidade será atualizada`, //  add
    err_deleted_in_shopify: 'Este produto foi deletado no Shopify', //  add
    err_may_be_blacklisted:
      'Há quatro razões possíveis para o pedido falhar.', // add
    err_may_be_listed_5:`
      1. Você só pode adquirir uma quantidade limitada deste produto.Entre em contato com o fornecedor para confirmar a quantidade máxima ou altere o fornecedor desse produto para fazer seu pedido.<br/>
      2. The order will ship to France and the supplier's product value exceeds 150 EURO, please change the supplier or apply to be a Business Buyer on your Aliexpress account.<br/>
      3. The supplier's product link is unavailable..<br/>
      4.O pedido contém fornecedores do AliExpress que requerem o uso de moedas diferentes para o pagamento. Faça os pedidos dos itens separadamente.
    `,
    err_france:
      'Substitua "França" no campo "País" por "{ Guadeloupe }" devido a novos termos do AliExpress', // add
    err_out_of_stock:
      'O estoque do produto acabou. Entre em contato com seu fornecedor ou mude para um novo fornecedor.', // add
    awaiting_error_tip11:"Devido a uma restrição de compra do fornecedor principal, o seu pedido foi efetuado usando o fornecedor secundário. Caso você deseje trocar de fornecedor, cancele o pedido e altere manualmente o fornecedor mapeado na aba “Cancelado”.",
    awaiting_error_tip13:"Seu pedido foi efetuado usando o fornecedor secundário porque o fornecedor primário está sem estoque. Caso você deseje trocar de fornecedor, cancele o pedido e altere manualmente o fornecedor mapeado na aba “Cancelado”.",
    awaiting_error_tip14:"A logística do fornecedor principal não realiza entregas no local de destino. Seu pedido foi efetuado usando o fornecedor secundário. Caso você deseje trocar de fornecedor, cancele o pedido e altere manualmente o fornecedor mapeado na aba “Cancelado”.",
    awaiting_error_tip15:"O fornecedor principal bloqueou você. Seu pedido foi efetuado usando o fornecedor secundário. Caso você deseje trocar de fornecedor, cancele o pedido e altere manualmente o fornecedor mapeado na aba “Cancelado”.",
    awaiting_error_tip21:"A SKU do fornecedor principal não pode ser vendida. Seu pedido foi efetuado usando o fornecedor secundário. Caso você deseje trocar de fornecedor, cancele o pedido e altere manualmente o fornecedor mapeado na aba “Cancelado”.",
    awaiting_error_tipOther:"Houve um problema com o fornecedor primário e o seu pedido foi efetuado com o fornecedor secundário. Caso você deseje trocar de fornecedor, cancele o pedido e altere manualmente o fornecedor mapeado na aba “Cancelado”.", 
    tmall_error_code1:'O sistema está ocupado. Tente novamente mais tarde ou clique para entrar em contato com o atendimento ao cliente', 
    tmall_error_code2: '',
    tmall_error_code3:'O método de envio deste produto é inválido. Tente efetuar o pedido novamente',
    tmall_error_code4:'A quantidade do pedido não atingiu a quantidade mínima de compra. Clique para entrar em contato com o atendimento ao cliente ou altere para um novo fornecedor.', 
    tmall_error_code5: 'No momento, este produto não pode ser adquirido via PC. Clique para entrar em contato com o atendimento ao cliente ou altere para um novo fornecedor',
    tmall_error_code6: 'O estoque deste produto é insuficiente. Clique para entrar em contato com o atendimento ao cliente ou altere para um novo fornecedor',
    tmall_error_code7:'Este produto não está à venda nesta região temporariamente. Clique para entrar em contato com o atendimento ao cliente ou altere para um novo fornecedor', 
    tmall_error_code8: 'Este produto está temporariamente indisponível para compra. Tente efetuar o pedido novamente',
    tmall_error_code9:'Este é um produto ativo e o evento ainda não começou. Clique para entrar em contato com o atendimento ao cliente ou altere para um novo fornecedor',
    tmall_error_code10:'A quantidade do pedido excede o limite de compra. Clique para entrar em contato com o atendimento ao cliente ou altere para um novo fornecedor.', 
    tmall_error_code11: 'A dedução no estoque do produto falhou. Tente efetuar o pedido novamente',
    tmall_error_code12: 'Este produto está temporariamente indisponível para compra. Clique para entrar em contato com o atendimento ao cliente ou altere para um novo fornecedor',
    tmall_error_code13:'Falha ao obter o preço do produto. Tente efetuar o pedido novamente',
    tmall_error_code14:'', 
    tmall_error_code15: 'Cupons expirados fizeram com que o pedido falhasse. Remova os cupons e tente novamente.',
    tmall_error_code16:  "No momento, não há um método de envio disponível para este produto. Clique para entrar em contato com o atendimento ao cliente ou altere para um novo fornecedor",
    tmall_error_code17:'', 
    err_may_be_out_of_stock:
      'O seu fornecedor pode estar sem estoque para este pedido. Por favor, vá ao AliExpress e verifique.', // add
    err_limit:
      'Você pode comprar, no máximo, { limit } peças deste produto. Entre em contato com o fornecedor para confirmar essa informação ou altere o fornecedor deste produto.',
    err_limit_blur: `Você só pode adquirir uma quantidade limitada deste produto.
      Entre em contato com o fornecedor para confirmar a quantidade máxima ou altere o fornecedor desse produto para fazer seu pedido.`,
    err_not_available:
      'Produto indisponível no AliExpress. Altere o fornecedor deste produto.', // add
    err_not_shipping: `The province selected in Shopify is set as a country on AliExpress, please manually change the destination in the Customer Detail section of DSers. If the product can't be shipped to the country of destination, we recommend you change the suppliers.`, // add
    err_blacklisted:
      'Seu fornecedor colocou você na lista negra. Entre em contato com seu fornecedor ou encontre outro fornecedor.', // add
    err_un_mapped: `Produtos não mapeados ou parcialmente mapeados. Mapeie o fornecedor corretamente no DSers.
                    Veja <a href="https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/" target="_blank">aqui</a> como fazer isso.`,
    err_shipping_to_china:
      'Note que o AliExpress não oferece suporte de envio de produtos para a China.',
    err_shipping_to_RU: `Devido a uma limitação de interface do AliExpress, o DSers não oferece suporte para envios para Rússia no momento.
                            Faça os pedidos manualmente no AliExpress e preencha o número do AliExpress em seu pedido no DSers.`,
    err_shipping_address:"O endereço da Shopify ou do seu pedido pode estar inconsistente com o endereço do AliExpress. O método de envio exibido aqui será usado no país de destino definido no AliExpress.",
    ae_account_disabled:
      'Sua conta do AliExpress foi desativada pelo AliExpress. Por favor crie uma nova conta no AliExpress e a conecte.',
    err_extra_address:
      'Informação do cliente incorreta ou ausente. Verifique a mensagem de erro e faça a correção adequada.',
    err_add_address_ukraine:"(If the destination is now a war zone, you will not be able to place the order)",
    ae_code_14_error:`The shipping method you chose is not supported, please change the shipping method`,
    ae_code_27_error: `Due to AliExpress interface limitation, DSers doesn't support shipping to the destination at the moment. Please manually place the order(s) on AliExpress and fill in the AliExpress order number on the order on DSers.`,
    err_address_yg:`It is recommended that you replace "{country}" with United Kingdom in the Country field. Fill in "{countrys}" in the Province field`,
    qty_zero_tip: `Fulfillment of the order was requested on Shopify. Since Request fulfillment feature does not comply with how DSers process orders, please modify the order based on the guide in the blog. Click <a href="https://help.dsers.com/re-order-request-fulfillment-order-for-shopify/" target="_blank">here</a> to get more information`,
    ae_tip_IN_error:`At the moment, orders for India cannot be placed successfully. Please click  <a href="https://customerservice.aliexpress.com/knowledgeDetail?categoryId=21039549&hcAppId=1248&hcFromCode=Xn2Tk1tb7&hcSessionId=26-1248-70544a7b-e1ed-41af-8a2d-1fa5a625173e&knowledgeId=627712&spm=service_hall.24300488.searchresult.627712）" target="_blank">here</a> to get more information.`,
    ae_code_11_error: `The price of your order to France exceeds 150 Euros. According to regulations, you are required to fill in the VAT information. Please click <a href="/app/settings?tabChangeSta=7" >here</a> to jump to the settings. Click <a href="https://www.blog.dserspro.com/post/orders-to-france-specifications-with-shopify-dsers" target="_blank">here</a> to get more information.`,
    ae_code_11_error_1: 'The price of your order to France exceeds 150 Euros. According to regulations, you are required to fill in the VAT information. Please click <a href="/app/settings?tabChangeSta=7">here</a> to jump to the settings. Click <a href="https://help.dsers.com/orders-to-france-specifications-for-shopify/" target="_blank">here</a> to get more information.',
    ae_code_11_error_btn: `Expired coupons made this order fail. Please remove the coupons and try again.`,
    ae_code_11_error_btn_ae:`These possible reasons made this order fail:<br/>
    1. Expired coupons made this order fail. Please remove the coupons and try again.<br/> 
    2. Your AliExpress account was under risk control, please <a href="https://www.messenger.com/t/DSersOfficial" target="_blank" >connect</a> another AliExpress account to try again or contact our support.`,
    ae_code_11_error_2: 'The system detected that your AliExpress account cannot place orders normally, please change the linked AliExpress account (click <a href="/app/settings?tabChangeSta=1">here</a> to change).',
    ae_code_11_error_3: 'Your order encountered an unknown error, please <a href="https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2FDSersOfficial" target="_blank" >contact customer service</a>.',
    ae_code_11_error3:`The system detected that your AliExpress account cannot place orders normally, please change the linked AliExpress account (click <a href="/app/settings?tabChangeSta=14">here</a> to change).`,
    ae_code_11_error4:`Your order encountered an unknown error, please <a href="https://www.messenger.com/t/DSersOfficial" dd="src" target="_blank">contact customer service</a>.`,
    ae_code_11_error4_extension:`Your order encountered an unknown error or you manually terminated the order, if you have any question, please <a href="https://www.messenger.com/t/DSersOfficial" dd="src" target="_blank">contact customer service</a>.`,
    err_sku_change:
      'Seu fornecedor modificou a SKU. O DSers atualiza as informações do produto a cada duas horas. Faça o mapeamento do produto e tente novamente mais tarde.',
    supplier_account_disabled: 'Conta de fornecedor desabilitada',
    err_ae_fake_failed:
      'Due to AliExpress recent interface upgrade, this order may have been already successfully placed. Please check on AliExpress to avoid any loss. If the order was successful, please fill in the order number manually. If the order fails, please place the order again.',
    currency_not_supported: 'Moeda não suportada',
    cpt_tip: 'Por favor digite um CPF válido com 11 números',
    coupon_expired: 'Promoção ou cupom expirado',
    duplicated_order: 'Pedido duplicado',
    err_varients_change:
      'Uma variante deste produto foi alterada. Mapeie novamente o produto e refaça o pedido',
    place_order_timeout:
      'Order timed-out, please go to AliExpress to check if the order was placed successfully. If the order was successful, please manually enter the AliExpress order number, If the order fails, please place the order again',
    order_processing: 'processamento de pedidos ...', //  add
    order_reault_failed:
      'Você tem pedidos que falharam! Por favor, verifique .', //  add
    order_reault_succ: 'Faça o pagamento no AliExpress!', //  add
    order_reault_await_length: 'pedidos estão sendo processados', //  add
    order_reault_is_await_length:"pedidos estão sendo processados",
    order_reault_await_title: 'Placing order(s)...', 
    order_reault_succ_title: 'Order(s) placed', 
    order_reault_succ_length: 'Pedidos processados com sucesso', //  add
    order_reault_failed_length: 'Falha no processamento dos pedidos', //  add
    order_reault_makepayment: 'Fazer Pagamento', //  add
    order_reault_checkfailed: 'Olhar pedidos que falharam', //  add
    order_reault_checkfailed1:'Verificar pedido(s) malsucedido(s)',
    order_reault_fail_title:'Some order(s) failed',
    roll_back_btn: 'Reverter pedidos', //  add
    roll_title:
      'Reverta os pedidos selecionados e você encontrará pedidos na guia Pedidos em espera. As informações do pedido antigo serão perdidas após a reversão', //  add
    roll_title_tm:"Reverta os pedidos selecionados e você encontrará pedidos na guia Pedidos em espera. As informações do pedido antigo serão perdidas após a reversão.",
    roll_desc: 'Roll back orders',
    "place_order_confirm_tooltip":'Please first select a shipping method for all orders before you confirm.',
      archive_title:'Archived orders',
      archive_desc:'Once an order is archived, the order will disappear from the current tab. If you want to view the archived order, please go to the Archived orders page.',
      roll_desc:'Roll back orders',
    global_place_order: 'Pedido',
    global_place_order_tip: 'Realizar pedido no Aliexpress.',
    confirm: {
      tm_title:"Por favor verifique as informações de frete antes de realizar o pedido no Tmall.",
      title:
        'Por favor verifique as informações de frete antes de realizar o pedido no Aliexpress.',
      title_succ: 'Com Sucesso!',
      title_fail: 'Clique em Revisar para verificar os pedidos com falha.',
      table_title: {
        order: 'Pedido',
        product: 'Produto',
        income: 'Renda',
        product_cost: 'Custo do Produto',
        country: 'País',
        shipping_method: 'Método de Envio',
        shipping_fee: 'Custo do frete',
        shipping_time: 'Tempo de frete',
        feedback: 'Feedback' //  d
      },
      error_msg: {
        //  d
        err_101: 'The product(s) was deleted from shopify',
        err_0: 'Click Mapping to add an AliExpress product link.',
        ae_has_product_no:
          'This product is not available on AliExpress. Please go and check on AliExpress.',
        child_cost_0:
          'SKU is not found. Please click Mapping to add an AliExpress product link.',
        express_length_0:
          "This product can't be shipped to to this country. Click Mapping to change an AliExpress product.",
        freight_math_info_no:
          'Choose a shipping method to active order feature.',
        freight_math_info_no_bind:
          'Add AliExpress link successfully! Choose a shipping method to active order feature.'
      }, //  d
      item: '{ num, plural, =1 {Item} other {Items} }',
      item_new:"Item(s)",
      confirm: 'Confirmar',
      income_tip:
        'O valor da receita aqui não inclui desconto de pedido e cupom. Pode ser diferente do valor atual.',
      total_cost: 'Custo total',
      revise: 'Revise', //  d
      make_payment: 'Make payment' // d
    },
    fulfill_confirm: {
      title:
        'Marque os pedidos selecionados como processados',
      table_title: {
        order: 'Pedido',
        product: 'Produto',
        ae_order_number: 'Número do pedido Aliexpress',
        feedback: 'Feedback'
      },
      item: 'orders',
      ok: 'Ok',
      mark_as_fulfilled: 'Marcar como processado',
      confirm_title: 'Tem certeza que quer preencher pedidos manualmente?',
      confirm_dec: `Você deseja PROCESSAR manualmente esses pedidos? Note que pedidos processados manualmente são irreversíveis.
                    <b>O DSers não sincronizará mais as informações dos pedidos realizados no AliExpress</b>.`,
      continue: 'Continuar',
      checkTitle:'Mark this order as fulfilled on Shopify too'
    },
    bind_ae_dialog: {
      title: 'Link ao Aliexpress para realizar múltiplos pedidos',
      shopify: 'Shopify',
      dsers: 'DSers',
      go_ae_tip: 'Você será redirecionado ao Aliexpress',
      go_ae: 'Linkar Aliexpress'
    },
    message_dialog: {
      title: 'Deixe uma mensagem aos vendedores do Aliexpress',
      tm_title:"Deixe uma mensagem aos vendedores do Tmall",
      desc:
        'Os vendedores do AliExpress receberão esta mensagem juntamente com as informações do seu pedido.',
      tm_desc:"Os vendedores do Tmall receberão esta mensagem juntamente com as informações do seu pedido.",     
      placeholder:
        'Insira no máximo 1000 caracteres (no máximo 250 caracteres chineses)',
      save: 'Save',
      title_new: 'Deixe uma mensagem para os fornecedores',
      desc_new: 'Os fornecedores receberão esta mensagem juntamente com as informações do seu pedido.',
    },
    go_ae_pay_dialog: {
      //  d
      h3:
        'Make your payments on AliExpress<br/>Official website to complete your orders.',
      h4: 'What happens for overdue payment?',
      p1: '1. Your orders might get canceled',
      p2: '2. Your account might experience payment failure',
      h5: 'Please complete your payment asap to satisfy<br/>your customers!',
      no_show: "Don't show this again",
      make_payment: 'Make payment',
      mark_as_paid: 'Mark as paid'
    }, //  d
    product_error_msg: {
      //  d
      can_not_shipped_this_country:
        "The product can't be shipped to this country. Add anther AliExpress link."
    }, //  d
    options_different_tip:
      'Remind : Variant option does not match up.Please double check.', //  d
    roll_back_confirm_title: 'Tem certeza que deseja performar esta ação?',
    roll_back_confirm_tip: 'Por favor escreva CONFIRMAR para voltar',
    roll_back_awaiting_shopify_hook_tip: `O DSers não está mais processando os pedidos no Shopify.
                                          Os pedidos serão movidos para a aba "Aguardando pedido" em alguns segundos. Por favor, aguarde.`,
    no_data: {
      no_data: 'Sem dados',
      title:
        'Sua pesquisa talvez não tenha resultados por uma das questões a seguir:',
      p4:
        '1. If you Archived the order on Shopify, please check the Archived menu in DSers to find the order',
      p1: '2. A informação que você escreveu é imprecisa',
      p2:
        '3. A pesquisa não está dentro do intervalo de tempo definido atualmente',
      p3: '4. A pesquisa talvez inclua um produto Oculto.',
      Pendings:{
        title:'Pending orders will be displayed here',
        detail:`Orders in the Pending tab are orders for which the payment from your customers has not yet been captured in Shopify.`
      },
      AwaitingOrders:{
        title:'Orders awaiting to be placed will be displayed here',
        detail:`Orders in the Awaiting order tab are orders for which the payment from your customers has been captured on Shopify and are waiting to be processed to Tmall.`
      },
      AwaitingPayments:{
        title:'Awaiting payment orders will be displayed here',
        detail:`Orders in the Awaiting payment tab are orders that have been placed on Tmall but not paid yet. `
      },
      AwaitingShipments:{
        title:'Awaiting shipment orders will be displayed here ',
        detail:`Orders in the Awaiting shipment tab are orders that have been paid on Tmall but have not been shipped yet.`
      },
      AwaitingFulfillments:{
        title:'Awaiting fulfillment orders will be displayed here',
        detail:'Orders in the Awaiting fulfillment tab are orders only partially fulfilled.'
      },
      Fulfilleds:{
        title:'Fulfilled orders will be displayed here',
        detail:`Orders in the Fulfilled tab are orders that have been shipped out by your Tmall supplier and orders that were automatically or manually fulfilled.`
      },
      Canceleds:{
        title:'Canceled orders will be displayed here',
        detail:'Orders in the Canceled tab are orders that have been (partially) canceled / (partially) refunded on Tmall or Shopify.'
      },
      FailedOrders:{
        title:'Failed orders will be displayed here',
        detail:'Orders in the Failed tab are orders which could not be placed to Tmall successfully due to errors or restrictions.'
      },
      Pending:{
        title:'Pedidos pendentes serão exibidos aqui ',
        detail:`Pedidos na aba “Pendentes” são aqueles cujos clientes ainda não efetuaram o pagamento na Shopify. Clique <a href={href} target="_blank">aqui</a> para saber mais sobre os pedidos na aba “Pendentes”.`
      },
      AwaitingOrder:{
        title:'Pedidos aguardando para serem processados serão exibidos aqui ',
        detail:`Pedidos na aba “Aguardando pedido” são aqueles cujos clientes já efetuaram o pagamento na Shopify e que ainda estão esperando para serem processados no AliExpress. Clique <a href={href} target="_blank">aqui</a> para saber mais sobre os pedidos na aba “Aguardando pedido”.`
      },
      AwaitingPayment:{
        title:'Pedidos aguardando pagamento serão exibidos aqui ',
        detail:`Pedidos na aba “Aguardando pagamento” são aqueles que já foram processados no AliExpress, porém ainda não foram pagos. Clique <a href={href} target="_blank">aqui</a> para saber mais sobre os pedidos na aba “Aguardando pagamento”.`
      },
      AwaitingShipment:{
        title:'Pedidos aguardando envio serão exibidos aqui',
        detail:`Pedidos na aba “Aguardando envio” são aqueles que já foram pagos no AliExpress, porém ainda não foram enviados. Clique <a href={href} target="_blank">aqui</a> para saber mais sobre os pedidos na aba “Aguardando envio”.`
      },
      AwaitingFulfillment:{
        title:'Pedidos aguardando processamento serão exibidos aqui',
        detail:'Pedidos na aba “Aguardando preenchimento” são aqueles que foram processados apenas parcialmente. Clique <a href={href} target="_blank">aqui</a> para saber mais sobre os pedidos na aba “Aguardando preenchimento”.'
      },
      Fulfilled:{
        title:'Pedidos processados serão exibidos aqui ',
        detail:`Pedidos na aba “Preenchido” são aqueles que foram enviados pelo seu fornecedor do AliExpress ou que foram processados automaticamente ou manualmente. Clique <a href={href} target="_blank">aqui</a> para saber mais sobre os pedidos na aba “Preenchido”.`
      },
      Canceled:{
        title:'Pedidos cancelados serão exibidos aqui ',
        detail:'Pedidos na aba “Cancelado” são aqueles que foram (parcialmente) cancelados/reembolsados no AliExpress ou Shopify. Clique <a href={href} target="_blank">aqui</a> para saber mais sobre os pedidos na aba “Cancelado”.'
      },
      FailedOrder:{
        title:'Pedidos malsucedidos serão exibidos aqui ',
        detail:'Pedidos na aba “Com falha” são aqueles que não foram processados no AliExpress com sucesso devido a erros ou restrições. Clique <a href={href} target="_blank">aqui</a> para saber mais sobre os pedidos na aba “Com falha”.'
      }
    },
    agency_open_no_data: {
      Pending:{
        title:'Pending orders will be displayed here',
        detail:`Orders in the Pending tab are orders for which the payment from your customers has not yet been captured in Shopify. `
      },
      AwaitingOrder:{
        title:'Orders awaiting to be placed will be displayed here',
        detail:`Orders in the Awaiting order tab are orders for which the payment from your customers has been captured on Shopify and are waiting to be processed to agencies. `
      },
      AwaitingPayment:{
        title:'Awaiting payment orders will be displayed here',
        detail:`Orders in the Awaiting payment tab are orders that you need to make payment to agencies.`
      },
      AwaitingShipment:{
        title:'Awaiting shipment orders will be displayed here ',
        detail:`Orders in the Awaiting shipment tab are orders that paid to suppliers through DSers, or marked as paid manually and awaiting for shipment.`
      },
      AwaitingFulfillment:{
        title:'Awaiting fulfillment orders will be displayed here',
        detail:'Orders in the Awaiting fulfillment tab are orders only partially fulfilled.'
      },
      Fulfilled:{
        title:'Fulfilled orders will be displayed here',
        detail:`Orders in the Fulfilled tab are orders that have been shipped out by your agencies and orders that were automatically or manually fulfilled. `
      },
      Canceled:{
        title:'Canceled orders will be displayed here',
        detail:'Orders in the Canceled tab are orders that have been (partially) canceled / (partially) refunded on Shopify or by agencies. '
      },
      FailedOrder:{
        title:'Failed orders will be displayed here',
        detail:'Orders in the Failed tab are orders which could not be placed to agencies successfully due to errors or restrictions.'
      }
    },
    open_no_data: {
      title:
        'Não consegue localizar seu primeiro pedido para por  no AliExpress?',
      p1: '2. Verifique a guia "Preenchido" para localizar o(s) pedido(s);',
      p2: '3. Si localizaste el/los pedido/s en la pestaña "Procesado";',
      p3:
        '4. Ahora, dirígete a Shopify - Ajustes - Pago, y desactiva la función de autoprocesamiento como se muestra a continuación;',
      p4:
        '5. Asegúrate de hacer clic en Guardar para actualizar los Ajustes;',
      p5:
        '6. Por favor, dirígete a la pestaña Procesado y haz clic en el botón "Más opciones" para revertir un pedido procesado seleccionado.',
      p6:
        '1. If you Archived the order on Shopify, please check the Archived menu in DSers to find the order;',
        Pending:`Clique <a href={href} target="_blank">aqui</a> para saber mais sobre os pedidos na aba “Pendentes”.`,
      AwaitingOrder:`Clique <a href={href} target="_blank">aqui</a> para saber mais sobre os pedidos na aba “Aguardando pedido”.`,
      AwaitingPayment:`Clique <a href={href} target="_blank">aqui</a> para saber mais sobre os pedidos na aba “Aguardando pagamento”.`,
      AwaitingShipment:`Clique <a href={href} target="_blank">aqui</a> para saber mais sobre os pedidos na aba “Aguardando envio”.`,
      AwaitingFulfillment:`Clique <a href={href} target="_blank">aqui</a> para saber mais sobre os pedidos na aba “Aguardando preenchimento”.`,
      Fulfilled:`Clique <a href={href} target="_blank">aqui</a> para saber mais sobre os pedidos na aba “Preenchido”.`,
      Canceled:`Clique <a href={href} target="_blank">aqui</a> para saber mais sobre os pedidos na aba “Cancelado”.`,
      FailedOrder:`Clique <a href={href} target="_blank">aqui</a> para saber mais sobre os pedidos na aba “Com falha”.`
    },
    dsers_product_deleted:
      'Produto excluído nos DSers, clique em <a href={href} target="_blank"> aqui </a> para verificar como processar o pedido no DSers.',
    variant_delete_tip:
      'Variante excluída no Shopify. Clique em "Mapeamento" para mapear um fornecedor para a variante excluída.',
    YANWEN_JYT_shipping_tip:
      'O método de envio selecionado não está mais disponível no AliExpress. Por favor, verifique com seu fornecedor e selecione outro método de envio.',
    err_deleted_in_dsers:
      'Este produto foi excluído nos DSers, clique em <a href={href} target="_blank"> aqui </a> para verificar como processar o pedido no DSers',
    pending_tip:
      'Verifique se você capturou o pagamento do (s) pedido (s) no Shopify. Se um pedido ainda estiver em Pendente após a captura do pagamento, marque',
    pending_tip_end: 'para atualizar o status do pedido manualmente.',
    send_code: 'Enviar código',
    store_name_tip: `O DSers exibirá o nome original da loja quando você se registrou no Shopify e não atualizaremos o nome da loja,
                    mesmo que você o tenha alterado no Shopify.
                    Observe que o nome da loja não será revelado aos clientes e não afetará os pedidos do DSers.`,
    ae_sync_not_done: 'a sincronização não foi realizada',
    ae_sync_more_then_5:
      'Você não pode realizar a sincronização manual mais que cinco vezes por dia.',
    select_shipping_method_tip: 'Selecione um método de envio.',
    ful_with_tracking: 'Processado com número de rastreamento.',
    ful_by_shopify: 'Processado pelo Shopify.',
    ful_manually: 'Processado manualmente no DSers.',
    partial_completion_of_order:
      'Your supplier sent out several packages for this product. We will fulfill the order when all the tracking numbers are synced.',
    ae_splited_order:
      'The order is currently split into multiple AliExpress orders. If there is a difference in the order numbers, please check and change it manually',
    freight_not_supported:
      'This country does not support the shipping method currently selected, please choose another one. Click <a target="_blank" href="https://www.dsers.com/blog/aliexpress-shipping-methods-changes/" rel="noopenner noreferrer" >here</a> if you need help.',
    edit_sp_tooltip:
      'Você editou o pedido, clicando em Ação (Action) – Editar (Edit). Se deseja alterar o fornecedor deste pedido, use o recurso Ação (Action) – Editar (Edit) novamente.',
    dsers_action_edit: 'Manually edited supplier',
    variant_delete_tag: 'Shopify Deleted Variant',
    go_ae_check_order: 'Go to AliExpress',
    may_be_fake_failed_order1: 'Your order ',
    may_be_fake_failed_order2:
      ' may have been successfully placed on AliExpress. In order to avoid any loss, please check on AliExpress',
    order_fulfill:
      'O comando fullfailed, clique para tentar novamente manualmente ',
    token_authorize_info:'O token da loja expirou.',
    order_onHold_info: "Manually fulfill orders",
    order_onHold_info_tip: "Synchronizing",
		order_onHold_snync_errorMess:'Your order is on hold in Shopify and cannot be automatically fulfilled. Please go to Shopify to change the on hold status and try again manually',
    order_onHold_snync_tip:"Click this button to sync tracking numbers to your store and fulfill this order on Shopify",
    order_fulfill: `O preenchimento do pedido falhou.`,
    token_authorize_info_btn: `Clique nas configurações para habilitá-lo novamente`,
    order_pending: `O pedido está sendo preenchido.`,
    order_pending_btn: `Aguarde o resultado do preenchimento`,
    test_order_tip: 'This order is a test order, and the generated order number is a virtual order number',
    conada_error:`Please enter your full name as it appears on your ID. Click <a target="_blank" href="https://help.dsers.com/orders-to-canada-specifications-for-shopify/">here</a> to learn how to solve this issue.`,
    cl_rut_errtip:`Please enter the correct RUT number with the following format: XXXXXXX-X or XXXXXXXX-X, the first 7-8 places must be Arabic numbers, the last place must be an Arabic number or the letter K`,
    cl_rut_errtipNo:`Please enter 2-12 digits or letters, such as 12123234-K`,
    sync_last_chan:'AliExpress order sync',
    sync_time_last_chan:'Last syncing time',
    sync_time_next_chan:'Next syncing time',
    flags:{
      title:'Flags',
      None:'Nenhum',
      Grey:'Cinza',
      Blue:'Azul',
      Green:'Verde',
      Yellow:'Amarelo',
      Red:'Vermelho'
    },
    shopify_partially_refunded:'Shopify partially refunded',
    place_via_extension: {
      caution: 'Caution',
      ae_account_not_match: 'Detectamos que a conta AliExpress em que você está atualmente conectado é inconsistente com a conta vinculada ao DSers. Para garantir que o status do seu pedido no AliExpress possa ser sincronizado com o DSers, vincule sua conta AliExpress atualmente conectada ao DSers ou faça um pedido na conta do AliExpress atualmente vinculada ao DSers.',
      login_ae_account_disabled: '',
      order_via_api_opt: 'Order via API (Place the orders faster)',
      order_via_ext_opt: 'Order via Chrome Extension (Automatically use received coupons)',
      ext_not_installed_title: 'DSers Chrome Extension needs to be installed',
      ext_not_installed_content: 'Check if you have installed the DSers Chrome Extension installed and click Add DSers Chrome Extension below to go to the Chrome Web store page to install it, then return to this page to try again.',
      add_dsers_ext: 'Add DSers Chrome Extension',
      order_via_api: 'Order via API',
      order_is_processing: 'Ordering via extension, please do not close the page or the browser',
      not_operate_title: 'Do not perform the following operations',
      not_operate_1: 'Exit/switch DSers account;',
      not_operate_2: 'Close/uninstall DSers plug-in;',
      not_operate_3: 'Operate AliExpress shopping cart;',
      not_operate_4: 'Exit AliExpress account.',
      failed_order_exsits: 'The ordering process is over. Some may have failed. Please check on AliExpress to avoid any loss. If the order was successful, please fill in the order number manually. If the order fails, please place the order again.',
      abort_task_btn: 'Terminate all tasks',
      abort_task_modal_title1: 'Confirm to terminate all current order tasks?',
      abort_task_modal_content1: 'After confirming the termination of all orders, orders that have not been placed will appear in the Failed Order tab. You can order them again later.',
      confirm_btn: 'Confirm',
      cancel_btn: 'Cancel',
      abort_task_modal_title2: 'Order task terminated',
      abort_task_modal_content2: '({value}) orders have been stopped, these orders will appear in the Failed Order tab',
      abort_got_it: 'Got it',
      error_tip_ae_logout: 'Sua conta do AliExpress foi desconectada durante o processo de pedido, faça login na sua conta do AliExpress e tente novamente enquanto estiver conectado.',
      error_tip_action_timeout: 'Tempo de solicitação expirado. Tente novamente.',
      error_tip_cart_error: 'Falha ao adicionar ao carrinho. Tente novamente.',
      error_tip_unknown: 'O pedido falhou. Entre em contato com o atendimento ao cliente para solucionar o problema.',
      error_tip_manually_close_1: 'O pedido falhou porque você fechou manualmente a página do pedido. Tente efetuá-lo novamente.',
      error_tip_manually_abort: 'You have manually aborted the order task.',
      error_tip_manually_close_2: 'A página foi fechada ou a aba do pedido foi arrastada. Tente novamente.',
      on_hold_error: 'Você não pode fazer o pedido, pois ele está “Em espera” na Shopify. Altere o status na Shopify e efetue o pedido no DSers.',
	    request_fulfill_error: 'O processamento do pedido foi solicitado na Shopify. O recurso “Solicitar processamento” não condiz com a forma como o DSers processa seus pedidos. Modifique o pedido seguindo as orientações do guia em nosso blog. Clique <a href="https://help.dsers.com/re-order-request-fulfillment-order-for-shopify/">aqui </a> para obter mais informações.',
	    shipping_error: "There is no shipping method applied to this order, please select manually. Or you can go to Settings--Shipping to set up your favorite shipping method, then DSers will select it automatically",
	    mapping_error:'É preciso mapear os produtos para que o DSers saiba com qual fornecedor você está trabalhando antes de fazer um pedido em massa.',
	    shipping_error_set:'The shipping method you set based on the product in the settings is not supported by the supplier anymore, so the order cannot be placed without a default shipping method. You can modify the shipping method in the settings, or manually modify it in the order',
	    on_hold_error_item: 'Você não pode fazer o pedido, pois ele está “Em espera” na Shopify. Altere o status na Shopify e efetue o pedido no DSers.',
  	  request_fulfill_error_item:  'O processamento do pedido foi solicitado na Shopify. O recurso “Solicitar processamento” não condiz com a forma como o DSers processa seus pedidos. Modifique o pedido seguindo as orientações do guia em nosso blog. Clique <a href="https://help.dsers.com/re-order-request-fulfillment-order-for-shopify/">aqui </a> para obter mais informações.',
  	  shipping_error_item: 'Nenhum método de envio foi aplicado a este pedido. Verifique as <a href="/app/settings">configurações de envio</a> no DSers ou confira a disponibilidade dos métodos de envio no AliExpress.',
      shipping_error_agency: 'There is no shipping method applied to this order, please select manually.',
      error_tip_product_captcha: 'Sua rede está anormal. Deslize para verificar no AliExpress e faça o pedido novamente.',
    },
    disappear_order_tip: `There is a problem with some of your orders, if you need to retrieve them, please <a class="ds-link" target="_blank" href="https://www.messenger.com/t/DSersOfficial"">contact us.</a>`,
    archived_orders:'Orders that have not been processed for more than a year will automatically be placed in Archived orders menu.',
    ae_code_21_error:`The order couldn't be placed with your main supplier for some reason, we tried to place the order with the sub-supplier, but it failed too because a variant of this product has changed, please re-map the product, then place the order`,
    order_code_24_error:`This order failed due to a network error, please try to place the order again`,
    tmall_sku1_error: 'A SKU do produto foi alterada. Verifique os seus detalhes de mapeamento.',
    tmall_sku2_error:`A SKU original não existe. Você precisa fazer o mapeamento de novo ou <a href=\"{url}\" target=\"_blank\">alterar o fornecedor </a>, do contrário não será possível efetuar o pedido.`,
    tmall_sku3_error:`The original SKU does not exist or changed, you need to re-map or change the supplier, otherwise you can't place the order successfully.`,
    filter_mapping_default:'All orders',
    filter_mapped_order:'Mapped orders',
    filter_no_mapped_order: 'Unmapped orders',
    note_title_y:'Adicionar nota',
    note_desc_y:'A nota só é visível no DSers',
    note_tip_y:'O número de caracteres na caixa de texto excedeu o limite permitido. Remova o conteúdo e salve.',
    aeProcess:'Process Orders',
    aeProcessSub:`Reverta os pedidos selecionados e eles serão movidos de volta à aba “Aguardando pedido”. As informações do pedido antigo do AliExpress serão perdidas após a reversão. <span style="color:red"> Note que esse tipo de pedido será alterado para o status “Não processado” na Shopify. Observação: devido à política da Shopify, só podemos processar pedidos dos últimos dois meses.</span>`,
    note_tip_y:'O número de caracteres na caixa de texto excedeu o limite permitido. Remova o conteúdo e salve.',
    orderPayment:{
      timeout:'Falha no pagamento. O tempo para o pagamento expirou. Faça o pagamento novamente.',
      cancel:'Falha no pagamento. Seu pagamento foi cancelado. Faça o pagamento novamente.',
      unknow:'Falha no pagamento. Motivo desconhecido. Entre em contato com o suporte do DSers para solucionar o problema.',
      checkDetail:'Verificar detalhes',
      link:'Link de pagamento',
      loading:'O pagamento que você iniciou às {time} está sendo processado.',
      success:'O pagamento que você iniciou às {time} foi bem-sucedido.',
      error:'The payment you started at {time} is failed.',
      pendingTip:'Caso deseje cancelar o pagamento deste pedido, cancele-o no link de pagamento.',
      reason:'failed reason',
      buttonTip:'Make your payments to complete your orders. Please complete your payment asap to satisfy your customers!',
      orderOneButtonTip:'Efetuar pagamento'
    },
    confirm_modal_up:{
      fulfill_title:'Preencher pedidos manualmente',
      fulfill_confirm:'CONFIRMAR',
      rollback_title:'Roll Back Orders',
      rollback_input_confirm_value:'Você tem certeza que deseja PREENCHER manualmente esses pedidos?',
      note_cancel:'CANCEL',
      node_save:'SAVE',
      fulfill_desc:'Você tem certeza que deseja PREENCHER manualmente esses pedidos? Observe que o DSers deixará de sincronizar as informações dos pedidos efetuados no AliExpress.',
      fulfill_desc_tm:"Are you sure you want to manually FULFILL these orders? Please note that DSers will stop syncing order information for the orders that have been placed on Tmall."
    },
    orderQuantityToolTip:{
      "title": "Ainda há {items} itens nos outros menus do pedido",
      "aliexpress": "Pedidos AliExpress",
      "agency": "Pedidos de agência",
      "unmapped": "Pedidos não mapeados"
    },
    aeCancelTip:`Devido às novas regras do AliExpress, quando um pedido usa um número de rastreamento falso, ele pode ser cancelado pelo AliExpress. Reverta este tipo de pedido manualmente na aba “Preenchido”.`,
    editActionTip:`Como você modificou o fornecedor do pedido, o item ou o pedido será movido para a página “Pedidos {platformType}”`,
    edit_order_confirm_tip_1: `Você usou o recurso “Editar” para modificar o tipo de fornecedor do item. Acesse a aba {platform} – “{tab}” para visualizar o item.`,
    edit_order_confirm_tip_2: `Você usou o recurso “Editar” para modificar o tipo de fornecedor de alguns itens. Acesse a aba {platform} – “{tab}” para visualizar os itens.`,
    edit_order_confirm_tip_var: {
      aliexpress: 'AE',
      tmall: 'Tmall',
      both: 'AE / Tmall',
      awaiting_order: 'Aguardando pedido',
      canceled_order: 'Cancelado',
      failed_order: 'Com falha',
    },
    tmallOrderPayment:{
      timeout:'Falha no pagamento. O tempo para o pagamento expirou. Faça o pagamento novamente.',
      cancel:'Falha no pagamento. Seu pagamento foi cancelado. Faça o pagamento novamente.',
      unknow:'Falha no pagamento. Motivo desconhecido. Entre em contato com o suporte do DSers para solucionar o problema.',
      checkDetail:'Check details',
      link:'Payment link',
      pending:'O pagamento que você iniciou às {time} está sendo processado.',
      success:'O pagamento que você iniciou às {time} foi bem-sucedido.',
      failed:'The payment you started at {time} is failed.',
      pendingTip:'Caso deseje cancelar o pagamento deste pedido, cancele-o no link de pagamento.',
      reason:'failed reason',
      buttonTip:'Make your payments to complete your orders. Please complete your payment asap to satisfy your customers!',
      orderOneButtonTip:'Efetuar pagamento'
  },
  paymentModalTitle:'Verifique as informações do pedido antes de efetuar o pagamento.',
    orderPageGuidance:'A ordem foi sincronizada, por favor vá para a página de Ordens Abertas para processar as ordens pendentes.'
  }
};
