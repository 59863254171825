import React, { Suspense } from 'react';
import { routerRedux, Route, Switch, Redirect } from 'dva/router';
import { ConfigProvider, Spin, message } from 'antd';
import { dynamic,connect } from 'dva';
import enUS from 'antd/es/locale/en_US';
import frFR from 'antd/es/locale/fr_FR';
import ptBR from 'antd/es/locale/pt_BR';

import deDE from 'antd/es/locale/de_DE';
import esES from 'antd/es/locale/es_ES';
import itIT from 'antd/es/locale/it_IT';

import { hot } from 'react-hot-loader/root';

import { getRouterData } from './common/routers';
import withTracker from './common/tracker';
import PrivateRoute from './common/PrivateRoute';
import PrivateRoutePartner from './common/PrivateRoutePartner';
import PartnerLayout from './layouts/PartnerLayout';
import BasicLayout from './layouts/BasicLayout';
import ReactIntl from './common/ReactIntl';
import GlobalMessenger from './common/GlobalMessenger';
import AuthorizationListener from './common/AuthorizationListener';
import WaitingComponent from './common/WaitingComponent';
import DetectServiceAbility from './common/detectServiceAbility';
import { MigrationWaiting } from './components/BeforeMigration';

import SmartHelp from './routes/HelpCenter'; // 临时写法，为了 HOC
import HelpDetail from './routes/HelpCenter/Detail'; // 临时写法，为了 HOC

// 结合 React.lazy 会导致 hot update 失效，开发阶段直接引入
import BeginnerGuide from './routes/BeginnerGuide';
import VideoPage from './routes/BeginnerGuide/Video';

// 需鉴权
import AECallback from './routes/H5/AECallback';
import AEWooCallback from './routes/H5/AEWooCallback';

// 公开
// import Register from './routes/H5/Register';
import RedirectPage from './routes/H5/Redirect';
import Callback from './routes/H5/Callback';
import ErrorPage from './routes/H5/ErrorPage';
import ObeloRedirect from './routes/H5/ObeloRedirect'; //oberlo重定向页面
import OrderRedirect from './routes/H5/OrderRedirect'; 

import RegisterResult from './routes/User/RegisterResult';
import ActivationResult from './routes/User/ActivationResult';
import ForgotPassword from './routes/User/SignResetPwdPage';
import SignInPage from './routes/User/SignInPage';
import SignUpPage from './routes/User/SignUpPage';
import MigratSignUp from './routes/User/MigratSignUp'; //迁移注册
import MigraSignIn from 'routes/User/MigraSignIn';  //迁移登录

// partner
import PartnerSignInPage from './routes/Partner/SignInPage';
import PartnerSignUpPage from './routes/Partner/SignUpPage';

import PartnerForgotPassword from './routes/Partner/PartnerForgotPassword';

import AccountError from './routes/H5/AccountError'
import NoAccess from 'routes/Exception/NoAccess'; 

//迁移
import {PreMigration, DataMigration, AwaitMigration, ErrorMigration} from 'routes/Migrate' 

import PaymentSuccess from 'routes/H5/PaymentSuccessCallback';
import PaymentFail from 'routes/H5/PaymentFailCallback';
import TmallDetail from 'routes/TmallDetail/Index'; //tmall详情

// const WithBanner = withTracker(Banner);
const WithRegisterResult = withTracker(RegisterResult);
const WithActivationResult = withTracker(ActivationResult);
const WithForgotPassword = withTracker(ForgotPassword);

const WithAECallback = withTracker(AECallback);
const WithAEWooCallback = withTracker(AEWooCallback);
// const WithRegister = withTracker(Register);
const WithRedirect = withTracker(RedirectPage);
const WithCallback = withTracker(Callback);
const WithPreMigrate = withTracker(PreMigration)
const WithDataMigration = withTracker(DataMigration)
const WithOberloRedirect = withTracker(ObeloRedirect)
const WithOrderRedirect = withTracker(OrderRedirect)
const WithAwaitMigration = withTracker(AwaitMigration)
const WithErrorMigration = withTracker(ErrorMigration)

const ThankYou = React.lazy(() => import('./layouts/ThankYou'));
const Pricing = React.lazy(() => import('./routes/Pricing/Pricing'));
const EmailOne = React.lazy(() => import('./routes/Emails/emailOne'));
const EmailTwo = React.lazy(() => import('./routes/Emails/emailTwo'));
const EmailThree = React.lazy(() => import('./routes/Emails/emailThree'));
const EmailFour = React.lazy(() => import('./routes/Emails/emailFour'));
const EmailUnsubscribe = React.lazy(() => import('./routes/Emails/emailUnsubscribe'));
const SupplyDetails = React.lazy(() => import('./routes/SupplyDetails/Index'));
const Reminder = React.lazy(() => import('./routes/Pricing/reminder'));
const Guidance = React.lazy(() => import('./routes/Guidance/Index'));
const Select = React.lazy(() => import('./routes/Pricing/select'));
const NotificationPage = React.lazy(() => import('./routes/Notification/Notification'));
const Subscribe = React.lazy(() => import('./routes/Pricing/Subscribe'));
const EmailTemplate = React.lazy(() => import('./routes/EmailTemplate/Index'));
const Paypal = React.lazy(() => import('./routes/Paypal'));
// const SmartHelp = React.lazy(() => import('./routes/HelpCenter'));
const LinkStore = React.lazy(() => import('./routes/User/LinkStore'));
const BindLoading = React.lazy(() => import('./routes/User/BindLoading'));

// const BeginnerGuide = React.lazy(() => import('./routes/BeginnerGuide'));
const SelectPlatform = React.lazy(() => import('./routes/User/SelectPlatform'));
const BindAE = React.lazy(() => import('./routes/User/BindAE'));
const InstallChromePlugin = React.lazy(() =>
  import('./routes/User/InstallChromePlugin')
);

const AgencyCostManagement = React.lazy(() => import('./routes/AgencyCostManagement'));

const StripeAuthCallback = React.lazy(() => import('./routes/Stripe/StripeAuthCallback'));

const StripePaymentPage = React.lazy(() => import('./routes/Stripe/StripePaymentPage'));

const { ConnectedRouter } = routerRedux;

dynamic.setDefaultLoadingComponent(() => {
  return <Spin size="large" style={{ width: '100%', margin: "60px 0" }} />;
});

let locale = enUS;
const lang = localStorage.getItem('lang');

if (lang === 'fr-FR') {
  locale = frFR;
} else if (lang === 'pt-PT') {
  locale = ptBR;
}else if (lang === 'de-DE') {
  locale = deDE;
}else if (lang === 'es-ES') {
  locale = esES;
}else if (lang === 'it-IT') {
  locale = itIT;
}

function RouterConfig({ history, app, newUserInfo }) {
  message.config({
    top: 100,
    maxCount: 3
  });
  const routerData = getRouterData(app);
  const routers = Object.keys(routerData);

  return (
    <ConfigProvider locale={locale}>
      <ConnectedRouter history={history}>
        <ReactIntl>
          <DetectServiceAbility>
            <AuthorizationListener />
            <GlobalMessenger routers={routers} />
            <Switch>
              <Route path="/partner/login" component={PartnerSignInPage} />
              <Route path="/partner/register" component={PartnerSignUpPage} />
              <Route path="/user/accounterror" component={AccountError} />
              <Route
                path="/partner/reset-password/:code"
                component={PartnerForgotPassword}
              />

              <PrivateRoutePartner path="/partner">
                <PartnerLayout />
              </PrivateRoutePartner>

              <Route path="/login" component={SignInPage} />
              <Route path="/register" component={SignUpPage} />
              <Route
                path="/register-result/:email?"
                component={WithRegisterResult}
              />
              <Route
                path="/activation-result/:code"
                component={WithActivationResult}
              />
              <Route
                path="/reset-password/:code"
                component={WithForgotPassword}
              />

              <Route
                path="/agency-cost-management"
                exact
                component={WaitingComponent(withTracker(AgencyCostManagement))}
              />

              <Route
                path="/agency-auth-callback/stripe/:result"
                component={WaitingComponent(withTracker(StripeAuthCallback))}
              />

              <PrivateRoute path="/make-payment">
                <Route
                  exact
                  path="/make-payment/stripe"
                  component={WaitingComponent(withTracker(StripePaymentPage))}
                />
              </PrivateRoute>

              <PrivateRoute path="/bind">
                {newUserInfo?.user_role === 2 &&
                !newUserInfo?.menu_permissions?.includes(7) ? (
                  <Route exact path="/bind/link-store" component={NoAccess} />
                ) : (
                  <Route
                    exact
                    path="/bind/link-store"
                    component={WaitingComponent(withTracker(LinkStore))}
                  />
                )}
                <Route
                  exact
                  path="/bind/loading"
                  component={WaitingComponent(withTracker(BindLoading))}
                />
                <Route
                  exact
                  path="/bind/platform"
                  component={WaitingComponent(withTracker(SelectPlatform))}
                />
                <Route
                  exact
                  path="/bind/ae"
                  component={WaitingComponent(withTracker(BindAE))}
                />
                <Route
                  exact
                  path="/bind/install_plugin"
                  component={WaitingComponent(withTracker(InstallChromePlugin))}
                />
              </PrivateRoute>

              <Route path="/auth/callback" component={WithCallback} />
              <Route path="/auth/redirect" component={WithRedirect} />
              <Route path="/auth/aewoocallback" component={WithAEWooCallback} />
              <Route path="/auth-error" component={ErrorPage} />
              <Route
                path="/order-redirect"
                component={WithOrderRedirect}
              ></Route>

              <PrivateRoute path="/auth">
                <Route path="/auth/aeprocallback" component={WithAECallback} />
                <Route path="/auth/aecallback" component={WithAECallback} />
              </PrivateRoute>
              {newUserInfo?.user_role === 1 || newUserInfo?.user_role === 2 ? (
                <Route exact path="/pricing" component={NoAccess} />
              ) : (
                <Route
                  exact
                  path="/pricing"
                  component={WaitingComponent(withTracker(Pricing))}
                />
              )}
              <Route
                exact
                path="/emailOne"
                component={WaitingComponent(withTracker(EmailOne))}
              />
              <Route
                exact
                path="/emailTwo"
                component={WaitingComponent(withTracker(EmailTwo))}
              />
              <Route
                exact
                path="/emailThree"
                component={WaitingComponent(withTracker(EmailThree))}
              />
              <Route
                exact
                path="/emailFour"
                component={WaitingComponent(withTracker(EmailFour))}
              />
              <Route
                exact
                path="/supplyDetails"
                component={WaitingComponent(withTracker(SupplyDetails))}
              />
              <Route
                exact
                path="/thank-you"
                component={WaitingComponent(withTracker(ThankYou))}
              />
              <Route
                exact
                path="/emailUnsubscribe"
                component={WaitingComponent(withTracker(EmailUnsubscribe))}
              />
              <Route
                exact
                path="/emailtemplate"
                component={WaitingComponent(withTracker(EmailTemplate))}
              />
              <Route
                exact
                path="/paypal"
                component={WaitingComponent(withTracker(Paypal))}
              />

              {/* 注意 / 匹配路由需放到最后 */}
              <PrivateRoute path="/">
                <Switch>
                  <Route
                    exact
                    path="/payment-success-cb/:id"
                    component={WaitingComponent(withTracker(PaymentSuccess))}
                  />
                  <Route
                    exact
                    path="/payment-fail-cb/:id"
                    component={WaitingComponent(withTracker(PaymentFail))}
                  />
                  <Route exact path="/pre-migrate" component={WithPreMigrate} />
                  <Route
                    exact
                    path="/data-migrate"
                    component={WithDataMigration}
                  />
                  <Route
                    exact
                    path="/await-migrate"
                    component={WithAwaitMigration}
                  ></Route>
                  <Route
                    exact
                    path="/reminder"
                    component={WaitingComponent(withTracker(Reminder))}
                  />
                  <Route
                    exact
                    path="/guidance"
                    component={WaitingComponent(withTracker(Guidance))}
                  />
                  {newUserInfo?.user_role === 1 ||
                  newUserInfo?.user_role === 2 ? (
                    <Route exact path="/select" component={NoAccess} />
                  ) : (
                    <Route
                      exact
                      path="/select"
                      component={WaitingComponent(withTracker(Select))}
                    />
                  )}
                  {newUserInfo?.user_role === 2 &&
                  !newUserInfo?.menu_permissions?.includes(9) ? (
                    <Route exact path="/notification" component={NoAccess} />
                  ) : (
                    <Route
                      exact
                      path="/notification"
                      component={WaitingComponent(
                        withTracker(NotificationPage)
                      )}
                    />
                  )}
                  <Route
                    exact
                    path="/Subscribe"
                    component={WaitingComponent(withTracker(Subscribe))}
                  />
                  <Route exact path="/smarthelp" component={SmartHelp} />
                  <Route exact path="/help_detail/:id" component={HelpDetail} />
                  <Route
                    exact
                    path="/tutorials"
                    component={WaitingComponent(withTracker(BeginnerGuide))}
                  />
                  <Route
                    exact
                    path="/tutorials_video"
                    component={WaitingComponent(withTracker(VideoPage))}
                  />
                  <Route
                    exact
                    path="/tmallproduct/:id"
                    component={WaitingComponent(withTracker(TmallDetail))}
                  />

                  <Route
                    exact
                    path="/migrationWaiting"
                    component={WaitingComponent(MigrationWaiting)}
                  />

                  {/* BasicLayout */}
                  <Route
                    path="/"
                    render={props => (
                      <BasicLayout {...{ ...props, routerData }} />
                    )}
                  />
                </Switch>
              </PrivateRoute>
            </Switch>
          </DetectServiceAbility>
        </ReactIntl>
      </ConnectedRouter>
    </ConfigProvider>
  );
}

export default connect(({user})=>({
  newUserInfo : user.newUserInfo
}))(hot(RouterConfig));
