
    export default 
    {
    "h5_landModal": {
        "create_account": "Create an account for you",
        "register": "Register yourself",
        "existing_account": "Already have an account?",
        "customer_service": "If you have any question, please",
        "log_in": "Log in",
        "sign_up": "sign up",
        "overtime_con": "The request timed out, please return to your Shopify store and re-initiate the binding",
        "contact_us": "Contact us",
        "return_shopify": "Return to Shopify",
        "welcome_dsers": "Welcome To DSers",
        "note_con": "Your Shopify email address has been used to create a DSers account login name. If this is your registered account, you can try to log in. If it is not your registered account, please register manually",
        "welcome_con": "We will use your Shopify email address to generate a DSers account for you, the account name will be your Shopify registered email address",
        "first_group": "<p style='font-size:36px;color:#000;font-weight:500'><span style='font-size:72px;color:#FF8F00'>\"5.0</span><br />Star Rating with\"<br /><br /><span style='font-size:72px;color:#FF8F00'>\"4600+</span><br /> Reviews on Shopify App Store\"</p>",
        "second_group": "<p style='font-size:36px;color:#000;font-weight:500'>\"Place <span style='font-size:72px;color:#FF8F00'>100</span>s<br />of orders to AliExpress<br />in 1 mins\" <br /><br />\"Manage multiple <br />stores in one account\"</p>",
        "third_group": "<p style='font-size:36px;color:#000;font-weight:500'>\"Find <span style='font-size:72px;color:#FF8F00'>50+</span>similar <br />productds in one click\"<br /><br />\"Switch to better <br />supplier easily\"</p>",
        "fourth_group": "<p style='font-size:36px;color:#000;font-weight:500'>\"Bundle mapping <br />feature. Upsell never <br />be so easy\" <br /><br />\"Auto sync AliExpress <br />product stock to store\"</p>",
        "fifth_group": "<p style='font-size:36px;color:#000;font-weight:500'>\"Manage multiple suppliers with local warehouse to one store product\"<br /><br />\"Unlimited order processing for all plans\"</p>",
        "sixth_group": "<p style='font-size:36px;color:#000;font-weight:500'>\"Auto sync supplier price change\"<br /><br />\"24*7 customer service. Support your remarkable work\"</p>",
        "seventh_group": "<p style='font-size:36px;color:#000;font-weight:500'>\"Never miss a package tracking with Dsers\"<br /><br />\"High qualified suppliers recommended by DSers\"</p>"
    }
}