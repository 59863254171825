import React, { PureComponent, Fragment } from 'react';
import { Table, Alert, Icon } from 'antd';
import styles from './index.less';

import intl from 'react-intl-universal';

import { BASE_URL } from '../../common/sysconfig';
import { getAuthorityHeader } from '../../utils/authority';

import closeShopifyAutoFulfilled from '../../assets/closeShopifyAutoFulfilled.png';

import iconShop from '../../assets/myproduct/icon_shop.png';
import iconGood from '../../assets/myproduct/icon_good.png';
import iconWork from '../../assets/myproduct/icon_work.png';
import { DSERS_IMG_HOST } from 'utils/constants';
import { CommonDataCollect } from 'utils/utils';

function initTotalList(columns) {
  const totalList = [];
  columns.forEach(column => {
    if (column.needTotal) {
      totalList.push({ ...column, total: 0 });
    }
  });
  return totalList;
}


class StandardTable extends PureComponent {
  constructor(props) {
    super(props);
    const { columns } = props;
    const needTotalList = initTotalList(columns);

    this.state = {
      selectedRowKeys: [],
      needTotalList
    };
  }

  componentWillReceiveProps(nextProps) {
    // clean state
    if (nextProps.selectedRows.length === 0) {
      const needTotalList = initTotalList(nextProps.columns);
      this.setState({
        selectedRowKeys: [],
        needTotalList
      });
    } else {
      const arr = [];
      nextProps.selectedRows.forEach(item => {
        arr.push(item.id);
      });
      this.setState({
        selectedRowKeys: arr
      });
    }
  }

  handleRowSelectChange = (selectedRowKeys, selectedRows) => {
    let needTotalList = [...this.state.needTotalList];
    needTotalList = needTotalList.map(item => {
      return {
        ...item,
        total: selectedRows.reduce((sum, val) => {
          return sum + parseFloat(val[item.dataIndex], 10);
        }, 0)
      };
    });

    if (this.props.onSelectRow) {
      this.props.onSelectRow(selectedRows, selectedRowKeys);
    }

    this.setState({ selectedRowKeys, needTotalList });
  };

  handleTableChange = (pagination, filters, sorter) => {
    this.props.onChange(pagination, filters, sorter);
  };

  cleanSelectedKeys = () => {
    this.handleRowSelectChange([], []);
  };

  ApplyWhitelist = () => {
    window.open(
      'https://home.aliexpress.com/dropshipper/join_drop_shipper.htm',
      '_blank'
    );
  };

  LinkAliExpress = () => {
    const header = getAuthorityHeader();
    window.open(
      `${BASE_URL}/ali/auth/redirect?token=${header.Authorization}`,
      '_blank'
    );
  };

  order_noData = (orderType) => {
    const { orderStatus, orderStatusNum, storeData } = this.props;
    let tip = null;
    let NO_DATA_CONFIG = {};
    if(orderType == "tmall"){
      NO_DATA_CONFIG = {
        1:{
          title:intl.get('order.no_data.Pendings.title'),
          detail:intl.get('order.no_data.Pendings.detail',{
            href:'https://help.dsers.com/order-status-pending/'
          })
        },
        2:{
          title:intl.get('order.no_data.AwaitingOrders.title'),
          detail:intl.get('order.no_data.AwaitingOrders.detail',{
            href:'https://help.dsers.com/order-status-awaiting-orders/'
          })
        },
        3:{
          title:intl.get('order.no_data.AwaitingPayments.title'),
          detail:intl.get('order.no_data.AwaitingPayments.detail',{
            href:'https://help.dsers.com/order-status-awaiting-payment/'
          })
        },
        4:{
          title:intl.get('order.no_data.AwaitingShipments.title'),
          detail:intl.get('order.no_data.AwaitingShipments.detail',{
            href:'https://help.dsers.com/order-status-awaiting-shipment/'
          })
        },
        9:{
          title:intl.get('order.no_data.AwaitingFulfillments.title'),
          detail:intl.get('order.no_data.AwaitingFulfillments.detail',{
            href:'https://help.dsers.com/order-status-awaiting-fulfillment/'
          })
        },
        5:{
          title:intl.get('order.no_data.Fulfilleds.title'),
          detail:intl.get('order.no_data.Fulfilleds.detail',{
            href:'https://help.dsers.com/order-status-fulfilled/'
          })
        },
        6:{
          title:intl.get('order.no_data.Canceleds.title'),
          detail:intl.get('order.no_data.Canceleds.detail',{
            href:'https://help.dsers.com/order-status-canceled/'
          })
        },
        7:{
          title:intl.get('order.no_data.FailedOrders.title'),
          detail:intl.get('order.no_data.FailedOrders.detail',{
            href:'https://help.dsers.com/order-status-failed-orders/'
          })
        },
      }
    }else{
      NO_DATA_CONFIG = {
        1:{
          title:intl.get('order.no_data.Pending.title'),
          detail:intl.get('order.no_data.Pending.detail',{
            href:'https://help.dsers.com/order-status-pending/'
          })
        },
        2:{
          title:intl.get('order.no_data.AwaitingOrder.title'),
          detail:intl.get('order.no_data.AwaitingOrder.detail',{
            href:'https://help.dsers.com/order-status-awaiting-orders/'
          })
        },
        3:{
          title:intl.get('order.no_data.AwaitingPayment.title'),
          detail:intl.get('order.no_data.AwaitingPayment.detail',{
            href:'https://help.dsers.com/order-status-awaiting-payment/'
          })
        },
        4:{
          title:intl.get('order.no_data.AwaitingShipment.title'),
          detail:intl.get('order.no_data.AwaitingShipment.detail',{
            href:'https://help.dsers.com/order-status-awaiting-shipment/'
          })
        },
        9:{
          title:intl.get('order.no_data.AwaitingFulfillment.title'),
          detail:intl.get('order.no_data.AwaitingFulfillment.detail',{
            href:'https://help.dsers.com/order-status-awaiting-fulfillment/'
          })
        },
        5:{
          title:intl.get('order.no_data.Fulfilled.title'),
          detail:intl.get('order.no_data.Fulfilled.detail',{
            href:'https://help.dsers.com/order-status-fulfilled/'
          })
        },
        6:{
          title:intl.get('order.no_data.Canceled.title'),
          detail:intl.get('order.no_data.Canceled.detail',{
            href:'https://help.dsers.com/order-status-canceled/'
          })
        },
        7:{
          title:intl.get('order.no_data.FailedOrder.title'),
          detail:intl.get('order.no_data.FailedOrder.detail',{
            href:'https://help.dsers.com/order-status-failed-orders/'
          })
        },
      }      
    }


    tip = (
      <div className={styles.default_nodata}>
        <img src={`https://img.dsers.com/shopify/order/emptyOrder/${orderStatus}.png`}></img>
        <div className={styles.default_nodata_box}>
          <div className={styles.default_nodata_title}>{NO_DATA_CONFIG[orderStatus]?.title}</div>
          <div className={styles.default_nodata_detail} dangerouslySetInnerHTML={{__html:NO_DATA_CONFIG[orderStatus]?.detail}}></div>
        </div>
      </div>
    )


    return tip;
  };

  handleClickAgencyStartUpBlog = (e) => {
    // console.log(e.target);
    if(e.target && e.target.getAttribute('data') === 'text-link') {
      CommonDataCollect({
        action: 'agency_order_cold_startup_awaiting_order_here_click',
        event_type:'agency_order_cold_startup'
      });
    }
    
  }

  agencyOrderNoDataRender = () => {
    const { orderStatus, orderStatusNum, storeData } = this.props;
    let tip = null;

    const NO_DATA_CONFIG = {
      1:{
        title:intl.get('order.agency_open_no_data.Pending.title'),
        detail:intl.get('order.agency_open_no_data.Pending.detail',{
          href:'https://help.dsers.com/order-status-pending/'
        })
      },
      2:{
        title:intl.get('order.agency_open_no_data.AwaitingOrder.title'),
        detail:intl.get('order.agency_open_no_data.AwaitingOrder.detail',{
          href:'https://help.dsers.com/order-status-awaiting-orders/'
        })
      },
      3:{
        title:intl.get('order.agency_open_no_data.AwaitingPayment.title'),
        detail:intl.get('order.agency_open_no_data.AwaitingPayment.detail',{
          href:'https://help.dsers.com/order-status-awaiting-payment/'
        })
      },
      4:{
        title:intl.get('order.agency_open_no_data.AwaitingShipment.title'),
        detail:intl.get('order.agency_open_no_data.AwaitingShipment.detail',{
          href:'https://help.dsers.com/order-status-awaiting-shipment/'
        })
      },
      9:{
        title:intl.get('order.agency_open_no_data.AwaitingFulfillment.title'),
        detail:intl.get('order.agency_open_no_data.AwaitingFulfillment.detail',{
          href:'https://help.dsers.com/order-status-awaiting-fulfillment/'
        })
      },
      5:{
        title:intl.get('order.agency_open_no_data.Fulfilled.title'),
        detail:intl.get('order.agency_open_no_data.Fulfilled.detail',{
          href:'https://help.dsers.com/order-status-fulfilled/'
        })
      },
      6:{
        title:intl.get('order.agency_open_no_data.Canceled.title'),
        detail:intl.get('order.agency_open_no_data.Canceled.detail',{
          href:'https://help.dsers.com/order-status-canceled/'
        })
      },
      7:{
        title:intl.get('order.agency_open_no_data.FailedOrder.title'),
        detail:intl.get('order.agency_open_no_data.FailedOrder.detail',{
          href:'https://help.dsers.com/order-status-failed-orders/'
        })
      },
    }

    if(orderStatus == 2) {
      return (
        <div className={styles.agencyAwaitingOrderEmpty}>
          <div className={styles.agencyAwaitingTop}>
            <div className={styles.agencyAwaitingTopSectionTitle}>
              {intl.get(
                'banner.agency_cold_startup.order_awaiting_empty_before_title'
              )}
            </div>
            <div className={styles.agencyAwaitingTopSectionTitle}>
              {intl.get(
                'banner.agency_cold_startup.order_awaiting_empty_after_title'
              )}
            </div>
            <div className={styles.agencyAwaitingTopSectionDesc}>
              {intl.get(
                'banner.agency_cold_startup.order_awaiting_empty_before'
              )}
            </div>
            <div className={styles.agencyAwaitingTopSectionDesc}>
              {intl.get(
                'banner.agency_cold_startup.order_awaiting_empty_after'
              )}{' '}
              <span onClick={this.handleClickAgencyStartUpBlog}>
                {intl.getHTML(
                  'banner.agency_cold_startup.order_awaiting_empty_link'
                )}
              </span>
            </div>
            {/* <div className={styles.imgContainer}>
              <img
                src={`${DSERS_IMG_HOST}/shopify/activity/agency_cold_startup/221017/agency_awating_order_empty_2.gif`}
              />
            </div>
            <div className={styles.imgContainer}>
              <img
                src={`${DSERS_IMG_HOST}/shopify/activity/agency_cold_startup/221017/agency_awating_order_empty_1.gif`}
              />
            </div> */}
          </div>
          <div style={{position: 'relative', marginTop: 8}}>
            <div style={{background: '#eceff1', zIndex: 2, position: 'absolute', height: 2, width: '13.7%', left: '43.35%',top: 0}}></div>
            <div style={{background: '#eceff1', zIndex: 2, position: 'absolute', height: 2, width: '13.7%', left: '43.35%',bottom: 0}}></div>
            <img
              width="100%"
              style={{ outline: 'none', borderRadius: '12px' }}
              src={`${DSERS_IMG_HOST}/shopify/activity/agency_cold_startup/221017/awaiting_order_empty_221122.gif`}
            />
          </div>
          <div className={styles.agencyAwaitingBottom}>
            <p><a href="https://www.dsers.com/ecommerce-basics/agency/" target="_blank" >{intl.get('banner.agency_cold_startup.what_is_agency')}</a></p>
            {/* <p><a href="https://www.dsers.com/ecommerce-basics/erp/" target="_blank">{intl.get('banner.agency_cold_startup.what_is_erp')}</a></p> */}
            <p><a href="https://www.dsers.com/blog/workflow-with-dropshipping-agencies/" target="_blank">{intl.get('banner.agency_cold_startup.how_does_dsers_agency_works')}</a></p>
          </div>
        </div>
      );
    }

    tip = (
      <div className={styles.default_nodata}>
        <img src={`https://img.dsers.com/shopify/order/emptyOrder/${orderStatus}.png`}></img>
        <div className={styles.default_nodata_box}>
          <div className={styles.default_nodata_title}>{NO_DATA_CONFIG[orderStatus]?.title}</div>
          <div className={styles.default_nodata_detail} dangerouslySetInnerHTML={{__html:NO_DATA_CONFIG[orderStatus]?.detail}}></div>
        </div>
      </div>
    )

    return tip;
  };

  noDataRender = () => {
    const { orderType } = this.props;
    if(orderType === 'agency') return this.agencyOrderNoDataRender();
    return this.order_noData(orderType);
  };

  syncNoData = (
    <div className={styles.order_onData}>
      <h2>Synchronizing Orders....</h2>
      <p style={{ margin: 20 }}>
        <Icon
          type="loading-3-quarters"
          spin
          style={{ fontSize: 40, color: '#fe8f24' }}
        />
      </p>
      <ol>
        <li>We are Sync orders for last 7 days,</li>
        <li>if you want to sync more, please go to </li>
        <li>
          <a
            style={{ color: '#389aeb', textDecoration: 'underline' }}
            role="button"
            tab-index="0"
            onClick={e => {
              e.stopPropagation();
              this.props.goSetting();
            }}
          >
            Setting>Other>Sync Shopify orders
          </a>
          .
        </li>
      </ol>
    </div>
  );

  render() {
    const { selectedRowKeys, needTotalList } = this.state;
    const {
      data: { list, pagination },
      loading,
      columns,
      rowKey,
      showClean = true,
      showInfo = true,
      localeEmptyText = false,
      localeEmpty,
      orderStatus,
      isSync
    } = this.props;

    const paginationProps = {
      showSizeChanger: true,
      showQuickJumper: true,
      ...pagination
    };

    const { showRowSelection = true } = this.props;

    // <div className={styles.order_onData}>
    //   <dl>
    //     <dt>
    //       {/* <h2>To place orders automatically：</h2> */}
    //       <h2>Good job ! </h2>
    //       <ol>
    //         <li>All orders are placed on AliExpress</li>
    //         <li>Work harder and get more orders.</li>
    //         {/* <li>Click 'Link to Shopify store' button to link to a Shopify store.</li>
    //         <li><a onClick={this.LinkAliExpress}>Click here</a> to link to AliExpress account.</li>
    //         <li><a onClick={this.ApplyWhitelist}>Click here</a> to apply for an AliExpress white list account.</li>
    //         <li>If you finished 1~3,new coming orders from Shopify store will be shown here.</li> */}
    //       </ol>
    //       {/* <h3>Enjoy yourself by placing multiple orders by one click.</h3> */}
    //     </dt>
    //     {/* <dd>
    //       <img src={require('../../assets/order/Group8.png')}/>
    //     </dd> */}
    //   </dl>
    // </div>

    let rowSelection = {};
    if (showRowSelection) {
      rowSelection = {
        selectedRowKeys,
        onChange: this.handleRowSelectChange,
        getCheckboxProps: record => ({
          disabled: record.disabled
        })
      };
    } else {
      rowSelection = null;
    }
    return (
      <div className={styles.standardTable}>
        <div className={styles.tableAlert}>
          {showInfo ? (
            <Alert
              message={
                <Fragment>
                  <a style={{ fontWeight: 600 }}>{selectedRowKeys.length}</a>{' '}
                  items selected &nbsp;&nbsp;
                  {needTotalList.map(item => (
                    <span style={{ marginLeft: 8 }} key={item.dataIndex}>
                      {item.title}Total&nbsp;
                      <span style={{ fontWeight: 600 }}>
                        {item.render ? item.render(item.total) : item.total}
                      </span>
                    </span>
                  ))}
                  {showClean ? (
                    <Fragment>
                      <a
                        onClick={this.cleanSelectedKeys}
                        style={{ marginLeft: 24 }}
                      >
                        clean
                      </a>
                    </Fragment>
                  ) : (
                    ''
                  )}
                </Fragment>
              }
              type="info"
              showIcon
            />
          ) : (
            ''
          )}
        </div>
        {showRowSelection ? (
          <Table
            loading={loading}
            rowKey={rowKey || 'id'}
            dataSource={list}
            columns={columns}
            pagination={paginationProps}
            onChange={this.handleTableChange}
            {...this.props}
            // localeEmptyText
            // locale = {{emptyText: localeEmptyText && localeEmpty ? 'It takes about 10 minutes to sync your order data from Shopify store' : 'No data'}}
            locale={{ emptyText: isSync ? this.syncNoData : this.noDataRender }}
          />
        ) : (
          <Table
            loading={loading}
            rowKey={rowKey || 'id'}
            dataSource={list}
            columns={columns}
            pagination={paginationProps}
            {...this.props}
            // locale = {{emptyText: localeEmptyText && localeEmpty ? 'It takes about 10 minutes to sync your order data from Shopify store' : 'No data'}}
            locale={{ emptyText: isSync ? this.syncNoData : this.noDataRender }}
          />
        )}
      </div>
    );
  }
}

export default StandardTable;
