import React from 'react';
import { connect } from 'dva';
import {
  Button,
  Icon,
  Drawer,
  Tabs,
  Select,
  Modal,
  Input,
  Alert,
  Spin,
  Tooltip,
  message,
  Dropdown,
  Menu
} from 'antd';
import intl from 'react-intl-universal';

import Banner from './Banner';
import { Post } from 'utils/request';
import Basic from './Basic.js';
import Advance from './Advance.js';
import Bogo from './Bogo.js';
import Bundle from './Bundle.js';
import Standard from './Standard.jsx';
import Ellipsis from 'components/Ellipsis';
import ReplaceMyProdcutEdit from '../../routes/StoreManage/ReplaceProduct';
import ProductShipping from '../../routes/Setting/Dropshpper/Common/Shipping/ProductShipping';
import { replaceUrlToHttps } from 'utils/utils';
import { getImportProductShowData } from '../../routes/StoreManage/util';
import { dispatchEventForGuide } from '../../routes/BeginnerGuide/util';

import { debounce } from 'lodash';

import styles from './index.less';
import './mapping.css';

import { CommonDataCollect, webCustomCollect } from 'utils/utils';
import notice_icon from 'assets/mapping/notice.png'

import CheckSaveModal from 'components/CheckSaveModal/CheckSaveModal.jsx';
import { GuildeAction } from 'actions'
const { Option } = Select;
const { TabPane } = Tabs;

const operations = (
  <Select placeholder="Supplier" style={{ width: '6.25rem' }} size="small">
    <Option value="ae">AliExpress</Option>
    <Option value="dsers">Dsers</Option>
  </Select>
);

function getAeProductUrl(id) {
  return `https://www.aliexpress.com/item/${id}.html`;
}


@connect(({ loading, login,auth,user}) => ({
  userInfo: login.userInfo,
  loading: loading.models.productmy,
  inviteLoading: loading.effects['productmy/mappingInvite'],
  isOberlo: auth?.isOberloUser,
  plan: user?.plan
}))
export default class Marketplaces extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabKey: 'basic',
      saveLoading: false,
      showInviteModal: false,
      variants: [],
      bannerData: {},
      types: '',
      publicBtnDispath: false,
      isHidden:false,
      isVisibleModal:false,
      replaceStatus: false,
      aeDetails: {},
      changeDetails: {},
      changeAe: false,
      aeUrl:'',
      aeUrlError: false,
      aeLoading: false,
      replaceDrawer: false,
      newProductDetails: {},
      isHidden:false,
      isVisibleModal:false,
      saveBtnVisiable:true,
      replaceLoading:false,
      disReplaceProduct:true,
      currentMapType : 0,
      checkVariantStatus:undefined,
      checkVisibleModal:false,
      errorMapType:'basic',
      errorText:'standard',
      isClickSaveBtn:false,
      dropDownVisible:false,
      switchoverMapping: false,
      mappingLoading: false
    };
    this.aeDetailsImg='';
    this.aeUrl="";
    this.delayBouncedStatus = true;
  }

  componentWillMount() {

    const {
      originProductDataMarketplaces: {
        prod: { ext: { map_type = 0 } = {} }
      }
    } = this.props;
    let mapType = map_type;

    if ((this.state.types == 1 && mapType !== 5) || [0,6].includes(mapType)) {
      mapType = 1;
    }


    this.setState({
      currentMapType: map_type,
      checkVisibleModal:false
    });

    this.setState({
      tabKey: this.mapType[mapType]
    });

    this.props.dispatch({
      type: 'productmy/disbursementSchedule',
      payload: {
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            this.setState({
              types: d.data.type
            });
          }
        }
      }
    });
  }
  componentWillUnmount(){
    this.setState({
      checkVariantStatus:undefined
    })
  }

  componentWillReceiveProps(nextProps) {
    const { originProductDataMarketplaces } = this.props;

    if (!nextProps.marketplacesVisible || this.props.marketplacesVisible == nextProps.marketplacesVisible) {
      return;
    }

    const {
      originProductDataMarketplaces: {
        prod: { ext: { map_type = 1 } = {} }
      }
    } = nextProps;
    let mapType = map_type;

    if (
      ((this.state.types == 1 && mapType !== 5) || [0, 6].includes(mapType)) &&
      !(
        mapType === 4 &&
        this.props?.plan?.trial_7_days_mapping_bundle_status === 1
      ) && !(
        mapType === 2 &&
        this.props?.plan?.trial_7_days_mapping_advanced_status === 1
      )&&!(
        mapType === 3 &&
        this.props?.plan?.trial_7_days_mapping_bogo_status === 1
      )
    ) {
      mapType = 1;
    }


    this.setState({
      tabKey: this.mapType[mapType]
    });

    const productMapType = [0, 0, 0, 0, 0];

    productMapType[mapType - 1] = mapType ? 1 : 0;
console.log('+_+_+_+_+_+_+_+_+',mapType,productMapType);

    // Oberlo用户 卡片引导
   if (this.props.isOberlo) {
      window.EVENT.emit(GuildeAction.OblerloGuide_Card, `mapping-${Date.now()}`, true);
    }
    localStorage.setItem('productMapType', JSON.stringify(productMapType));
  }
  advanceHidden=false;
  basicHidden=false;
  bogoHidden=false;
  bundleHidden=false;
  standardHidden=false;
  inviteCode = '';
  isClickSaveBtn=false
  hasSave = false;
  map_type = '';
  mapType = {
    '1': 'basic',
    '2': 'advance',
    '3': 'bogo',
    '4': 'bundle',
    '5': 'standard',
  };
  mapType_p = {
    basic: 1,
    advance: 2,
    bogo: 3,
    bundle: 4,
    standard: 5
  };

  componentDidUpdate(prevProps) {
    const { originProductDataMarketplaces, disableBanner } = this.props
    if((prevProps.originProductDataMarketplaces !== originProductDataMarketplaces ||
      prevProps.originProductDataMarketplaces?.prod !== originProductDataMarketplaces?.prod)){
        this.getCount(originProductDataMarketplaces)
        this.setState({
          currentMapType : this.props.originProductDataMarketplaces?.prod?.ext.map_type,
          checkVisibleModal:false
        });
        this.deleteVariantIdMaps = [];
        window.SWITCH_MAP_TYPE = false
      }

    // 历史遗留问题
    if (
      !disableBanner &&
      originProductDataMarketplaces &&
      (prevProps.originProductDataMarketplaces !== originProductDataMarketplaces ||
        prevProps.originProductDataMarketplaces.prod !== originProductDataMarketplaces.prod)
    ) {
      // const storeId =
      //   originProductDataMarketplaces.storeId || originProductDataMarketplaces.prod.store_id;
      // if (
      //   storeId &&
      //   (originProductDataMarketplaces.prod.ext.map_type === 1 ||
      //     originProductDataMarketplaces.prod.ext.map_type === 2)
      // ) {
      //   this.fetchBannerData(storeId, originProductDataMarketplaces);
      // }
    }
  }

  // 获取该商品各个订单状态的数量
  getCount = async(product) => {
    if(localStorage.getItem('mapping_dot') == 'order'){
      this.setState({
        replaceLoading:false,
        disReplaceProduct: true
      })
      return
    }
    this.setState({
      replaceLoading:true
    })
    await Post('/ord/count',{data:{
      last: '',
      keywords:`${product?.prod?.detail?.id}`,
      search_type: 8,
      start_at: parseInt(new Date().getTime()/1000) - 10368000,
      end_at: parseInt(new Date().getTime()/1000),
      status: 2,
      fulfilled: 0,
      flags: [],
      source: 0,
      canceled: 3,  //3:筛选只有aecancel的
      sort: 0,
      skip: '',
      sel_fields: [
        '_id',
        'detail',
        'ext',
        'shopify_created_at',
        'store_id'
      ],
      key: '',
      country: '',
      untimeliness: false
    }}).then((res)=>{
      if(res){
        if (
          res.data?.pending == 0 &&
          res.data?.awaiting_order == 0 &&
          !res.data.canceled &&
          res.data?.failed == 0
        ) {
          this.setState({
            disReplaceProduct: false
          });
        } else {
          this.setState({
            disReplaceProduct: true
          });
        }
        this.setState({
          replaceLoading:false
        })
      }
    })
  };

  fetchBannerData = async (id, data) => {
    const params = {
      store_id: id,
      sp_product_id: [data.prod.detail.id]
    };

    try {
      const res = await Post('/prod/propool/check', {
        data: params
      });

      if (res && res.code === 2000 && res.data) {
        const [tmp] = Object.values(res.data);
        if (tmp) {
          this.setState({ bannerData: tmp });
          console.log('banner mapping =>>>>', tmp);
        }
      }
    } catch (error) {
      console.log('fetchBannerData error:', error);
    }
  };

  handleCheckClick = data => {
    const { dispatch } = this.props;

    dispatch({
      type: 'global/showOneClickMapping',
      payload: data
    });
  };

  closeCkeckModalHanlder =()=>{
    this.setState({
      isHidden:false,
      isVisibleModal:false,
      saveBtnVisiable:true
    },()=>{
      this.handleClose()
    })
  }

  saveAndNextHandler=()=>{

    this.basic?.handleSave();
    this.standard?.handleSave();
    this.advance?.handleSave();
    this.bogo?.handleSave();
    this.bundle?.handleSave();
    if(this.basic?.basicMappingRepeatErr){
      this.setState({
        checkVisibleModal:true,
        errorMapType:'basic',
        errorText:'basicRepeat',
        isVisibleModal:false
      })
      this.basic.basicMappingRepeatErr = false;
      return
    }
    if(this.basic?.basicMappingNullErr){
      this.setState({
        checkVisibleModal:true,
        errorMapType:'basic',
        errorText:'basicNull',
        isVisibleModal:false
      })
      this.basic.basicMappingNullErr = false;
      return
    }

    if(this.advance?.advanceMappingErr){
      this.setState({
        checkVisibleModal:true,
        errorMapType:'advance',
        errorText:'advance',
        isVisibleModal:false
      })
      this.advance.advanceMappingErr = false;
      return
    }
    this.handleSaveMapping()

    this.setState({
      isHidden:false,
      isVisibleModal:false
    },()=>{
      this.handleClose()
    })
  }

  onlycloseCkeckModalHanlder=()=>{
    this.setState({
      isVisibleModal:false
    })
  }

  changemodalHidden=(type)=>{
    this.setState({
      isHidden:true,
    },()=>{
      if(type == 'advance'){
        this.advanceHidden=true
      }else if(type == 'basic'){
        this.basicHidden=true
      }else if(type == 'bogo'){
        this.bogoHidden=true
      }else if(type == 'bundle'){
        this.bundleHidden=true
      }else if(type == 'standard'){
        this.standardHidden=true
      }
    })
  }

  hasSavedHandler=(type)=>{
    if(type == 'advance'){
      this.advanceHidden=false
    }else if(type == 'basic'){
      this.basicHidden=false
    }else if(type == 'bogo'){
      this.bogoHidden=false
    }else if(type == 'bundle'){
      this.bundleHidden=false
    }else if(type == 'standard'){
      this.standardHidden=false
    }
  }

  handleClose = debounce(() => {
    if(this.state.isHidden &&
       (this.advanceHidden ||
       this.basicHidden ||
       this.bogoHidden ||
       this.bundleHidden ||
       this.standardHidden)
       ){
      this.setState({
        isVisibleModal:true
      })
      return
    }
    if (Object.keys(this.state.bannerData).length > 0) {
      this.setState({ bannerData: {} });
    }

    this.basic = null;
    this.standard = null;
    this.advance = null;
    this.bogo = null;
    this.bundle = null;
    if (this.props.onClose) {
      this.props.onClose(this.hasSave);
    }

    this.props.marketplacesHideSupplier(this.hasSave);
    this.hasSave = false;
       // Oberlo 卡片引导
    if (this.props.isOberlo) {
      window.EVENT.emit(GuildeAction.OblerloGuide_Card, '', true);
    }
  }, 1000, {'leading': true,'trailing': false});

  changeTab = k => {
    if(k==='basic' && this.state.tabKey!==k){
      window.dtag.set({
        event: 'init',
        module: 'mapping',
        action: 'entering_the_basic_page',
      });
    }
    this.setState(
      {
        tabKey: k
      },
      () => {
        const productMapType = JSON.parse(localStorage.getItem('productMapType')) || [0, 0, 0, 0, 0];
        productMapType.forEach((value, index, array) => {
          if (value == 0 && this.mapType_p[this.state.tabKey] - 1 == index) {
            this.changeTabCloseSwitch(false);
          }
        });
      }
    );
  };

  replaceChange = () => {
    // 交互打点
    CommonDataCollect({
      user_id: this.props.userInfo.user_id,
      sale_platform: 'shopify',
      supply_platform: 'aliexpress',
      trans_from: 'myproduct click replace product button',
      action: 'click_replace_products_button',
      event_type: 'interactive'
    });

    this.setState({
      replaceStatus: true,
      dropDownVisible:false
    },() => {
      this.deleteReplaceProduct();
    })
  }

  // 切换 tab 关闭当前 switch 按钮
  changeTabCloseSwitch = visibleStatus => {
    const { tabKey } = this.state;
    if (tabKey === 'advance') {
      this.advance.switchTabCheck(visibleStatus);
    } else if (tabKey === 'bogo') {
      this.bogo.switchTabCheckBogo(visibleStatus);
    } else if (tabKey === 'bundle') {
      this.bundle.switchTabCheckBundle(visibleStatus);
    } else if (tabKey === 'basic') {
      this.basic.switchTabCheckBasic(visibleStatus);
    } else if (tabKey === 'standard') {
      this.standard.switchTabCheckStandard(visibleStatus);
    }
  };

  // 更改邀请码
  changeInviteCode = e => {
    this.inviteCode = e.target.value;
  };

  // 判断邀请码
  subInviteCode = () => {
    if (!this.inviteCode) {
      return;
    }
    this.props.dispatch({
      type: 'productmy/mappingInvite',
      payload: {
        data: {
          code: this.inviteCode,
          user_id: this.props.userInfo.user_id
        },
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            window.location.reload();
          }
        }
      }
    });
  };

  // 联系客服
  showCustomer = debounce(() => {
    this.setState({
      showInviteModal: false
    });
    this.props.marketplacesHideSupplier(this.hasSave);
    this.hasSave = false;
    if (window.FB) {
      FB.CustomerChat.showDialog();
    }
  }, 1000, {'leading': true,'trailing': false} );

  handleSave = () => {
    this.hasSave = true;
    switch (this.state.tabKey) {
      case 'basic':
        this.saveBaic();
        break;
      case 'advance':
        this.saveAdvance();
        break;
      case 'bogo':
        this.saveBogo();
        break;
      case 'bundle':
        this.saveBundle();
        break;
    }
  };

  onBasic = ref => {
    this.basic = ref;
  };

  saveBaic = () => {
    this.basic.handleSave();
  };

  onAdvance = ref => {
    this.advance = ref;
  };

  saveAdvance = () => {
    this.advance.handleSave();
  };

  onBogo = ref => {
    this.bogo = ref;
  };

  saveBogo = () => {
    this.bogo.handleSave();
  };

  onBundle = ref => {
    this.bundle = ref;
  };

  saveBundle = () => {
    this.bundle.handleSave();
  };

  onStandard = ref => {
    this.standard = ref;
  }

  saveStandard = () => {
    this.standard.handleSave();
  };

  // 设置保存loading状态
  setLoading = loading => {
    this.setState({
      saveLoading: loading
    });
  };

  setHasSave = flag => {
    this.hasSave = flag;
  };

  // 更新
  updataExt = data => {
    this.IndexExt = data;
  };

  setInvalidVariants = data => {
    this.setState({
      invalidVariants: data
    });
  };



  productOpenOnAe = (productDate)=>{
    if(productDate.product_id){
      window.open(
        `https://es.aliexpress.com/item/${productDate.product_id}.html`
      );
    }
  }

  publicBtnDispathShut = () => {
    this.setState({
      publicBtnDispath: true
    });
  };

  publicBtnDispathOpen = () => {
    this.setState({
      publicBtnDispath: false
    });
  };

  changeAeUrl = (e) => {
    this.setState({
      aeUrl: e.target.value
    }, () => {
      this.checkAeUrl(this.state.aeUrl)
    })
  }

  checkAeUrl = (url) => {
    let pattern = /(\d+)\.html/;
    if(!pattern.test(url)){
      this.setState({
        aeUrlError: true
      })
      return;
    }

    this.setState({
      aeUrlError: false,
      changeAe: true
    },() => {
      this.getAeDetails(url)
    })
  }

  showLoading = () => {
    this.setState({
      aeLoading: true
    })
  }

  /**
   * 请求ae详情
   * @param {} changeStatus
   */
  getAeDetails = (url) => {
    this.showLoading();
    this.aeUrl = url;
    this.props.dispatch({
      type: 'productmy/aeProductDetail',
      payload: {
        data: {
          url: url,
        },
        callback: d => {
          if(d.code == 2000){
            this.setState({
              aeLoading: false,
              aeDetails: d.data
            })
            this.aeDetailsImg = d.data.image_u_r_ls.split(';')[0];
          }else{
            this.setState({
              changeAe:false
            })
          }
        }
      }
    });
  }

  hideDetail = () => {
    this.setState({
      replaceDrawer: false
    });
    // this.getProdocutList();
  };

  hideReplaceModal = () => {
    this.setState({
      replaceStatus: false,
      aeUrl: '',
      aeUrlError: false,
      changeAe: false,
    },() => {
      this.deleteReplaceProduct();
    })
  }

  checkVariantStatusHandler = (v)=>{
    let flag
    if(v === undefined){
      flag = undefined
    }else if(v.length){
      flag = true
    }else{
      flag = false
    }
    this.setState({
      checkVariantStatus:flag
    })
  }

  getReplaceId = (id) => {
    this.props.getReplaceId(id);
  }

  openAeImport = () => {
    this.props.dispatch({
      type: 'productmy/getAeData',
      payload: {
        data: {
          replace_id: this.aeUrl
        },
        callback: (d) => {
          if(d.code = 2000){
            d.data.detail.variants.forEach(i=>{
              i.aeCost = i.price
            })
            this.setState({
              newProductDetails: d.data,
              replaceDrawer: true,
              replaceStatus: false,
            },() => {
              this.deleteReplaceProduct()
            })
          }
        }
      }
    });
  };

  deleteReplaceProduct = () => {
    this.setState({
      changeAe: false,
      aeUrl: '',
    })
  }

  changeMappingStatus = (type) =>{
    this.setState({
      currentMapType : type
    },()=>{
      this.handleSaveMapping()
    })
  }
  deleteVariantIdMaps = [] // 删除被删除的variant
  handleSaveMapping = (type) => {
    if (type === 'fromSaveButton') {
      this.isClickSaveBtn =true
    } else {
      this.isClickSaveBtn =false
    }
    const { originProductDataMarketplaces, setOriginProductData } = this.props;
    const { currentMapType,deleteVariantIdMaps } = this.state
    this.basic?.handleSave(this.state.tabKey ==='basic' ?type === 'fromSaveButton':false);
    this.standard?.handleSave();
    this.advance?.handleSave();
    this.bogo?.handleSave();
    this.bundle?.handleSave();
    let allExts = {}


    // allExts.basic = this.basic.basicExt
    if(this.basic?.basicMappingRepeatErr){
      window.SWITCH_MAP_TYPE = true;
      this.setState({
        checkVisibleModal:true,
        errorMapType:'basic',
        errorText:'basicRepeat'
      })
      this.basic.basicMappingRepeatErr = false;
      return
    }
    if(this.basic?.basicMappingNullErr){
      window.SWITCH_MAP_TYPE = true;
      this.setState({
        checkVisibleModal:true,
        errorMapType:'basic',
        errorText:'basicNull'
      })
      this.basic.basicMappingNullErr = false;
      return
    }

    if(this.advance?.advanceMappingErr){
      window.SWITCH_MAP_TYPE = true;
      this.setState({
        checkVisibleModal:true,
        errorMapType:'advance',
        errorText:'advance'
      })
      this.advance.advanceMappingErr = false;
      return
    }
    window.SWITCH_MAP_TYPE = false;
    if(originProductDataMarketplaces?.prod?.ext){
      allExts = {...originProductDataMarketplaces?.prod?.ext}
      allExts.map_type = currentMapType
    }
    if(this.basic){
      allExts.basic = this.basic?.basicExt?.basic;
      this.deleteVariantIdMaps=[...this.deleteVariantIdMaps,...this.basic?.state.deleteVariantIdMap]
      allExts.product_pool = this.basic?.basicExt?.product_pool
    }


    if(this.standard){
      this.deleteVariantIdMaps=[...this.deleteVariantIdMaps,...this.standard?.state.deleteVariantIdMap]
      allExts.standard = this.standard?.standardExt?.standard

    }

    if(this.advance){
      allExts.advance = this.advance?.advanceExt?.advance
      this.deleteVariantIdMaps=[...this.deleteVariantIdMaps,...this.advance?.state.deleteVariantIdMap]
    }

    if(this.bogo){
      allExts.bogo = this.bogo?.bogoExt?.bogo
      this.deleteVariantIdMaps=[...this.deleteVariantIdMaps,...this.bogo?.state.deleteVariantIdMap]
    }

    if(this.bundle){
      allExts.bundle = this.bundle?.bundleExt?.bundle
      this.deleteVariantIdMaps=[...this.deleteVariantIdMaps,...this.bundle?.state.deleteVariantIdMap]
    }

    allExts.del = originProductDataMarketplaces?.prod?.ext?.del?.filter(i=>{
      if(![...new Set(this.deleteVariantIdMaps)].includes(i.variant_id)){
        return i
      }
    });

    if(this.basic){
      allExts.del = this.basic?.basicExt?.del?.filter(i=>{
        if(![...new Set(this.deleteVariantIdMaps)].includes(i.variant_id)){
          return i
        }
      });
    }

    if(allExts.standard){
      Object.keys(allExts.standard).forEach(i=>{
        if(this.deleteVariantIdMaps.map(i=>i = i+'')?.includes(`${i}`)){
          delete allExts.standard[i]
        }
      })
    }

    if(allExts?.basic){
      allExts?.product_pool?.forEach(i=>{
        allExts.basic[i?.product_id_str]?.supply?.forEach((item,index)=>{
          if(this.deleteVariantIdMaps?.includes(item?.variant_id)){
            allExts.basic[i?.product_id_str].supply?.splice(index,1)
          }
        })
      })
    }

    this.delayBouncedStatus = true;

    let delayBounced = setTimeout(() => {
      if (
        originProductDataMarketplaces?.prod?.ext?.map_type === 6 &&
        [1, 2, 3, 4, 5].includes(allExts.map_type) &&
        this.delayBouncedStatus
      ) {
        this.setState({
          switchoverMapping: true
        });
      }
      clearTimeout(delayBounced);
      delayBounced = null;
    }, 500);


    this.setState({
      mappingLoading: true
    })

    this.props.dispatch({
      type: 'productmy/ext',
      payload: {
        data: {
          product_id: originProductDataMarketplaces.prod.id,
          ext: allExts,
          store_id:originProductDataMarketplaces.prod.store_id,
          ref:localStorage.getItem('mapping_dot'),
          v2:'true'
        },
        callback: d => {
          this.setLoading(false);
          if (d.code == 2000 || d.code == 2010) {
            message.success(intl.get('mapping.succeed'));
            this.setState({
              saveBtnVisiable:true
            })
            this.deleteVariantIdMaps = []
            webCustomCollect({
              action:"mapping_dot_1",
              custom_info:[
                {
                  name:localStorage.getItem('mapping_dot'),
                  value:'mappinged'
                }
              ]
            })
            this.hasSavedHandler('basic')
            this.hasSavedHandler('standard')
            this.hasSavedHandler('bogo')
            this.hasSavedHandler('advance')
            this.hasSavedHandler('bundle')
            if(window.location.pathname.substring(window.location.pathname.lastIndexOf("/")+1).match(/myproduct/)){
              window.IS_UPDATE_IMPORT_PRODUCT = true
              setTimeout(()=>{
                window.IS_UPDATE_IMPORT_PRODUCT = false
              },15*1000)
            }
          }

          this.setInvalidVariants(null);

          if (type === 'fromSaveButton') {
            dispatchEventForGuide({ type: 'mapping', payload: { category: d && (d.code == 2000 || d.code == 2010) ? 'MAPPING_SUCCESS' : 'MAPPING_FAILED' } });
          }

          const { switchoverMapping } = this.state
          if (switchoverMapping) {
            this.setState({
              switchoverMapping: false
            })

          }
            this.delayBouncedStatus = false;



          let obj = JSON.parse(JSON.stringify(originProductDataMarketplaces))
          obj.prod.ext = allExts;
          setOriginProductData(obj);

          this.setState({
            mappingLoading: false
          })

        }
      }
    });
  }

  showSaveBtn = () => {
    this.setState({
      saveBtnVisiable:false
    })
  }
  closeCkeckErrModalHanlder = ()=>{
    this.setState({
      checkVisibleModal:false
    })
  }

  toCheckHandler=()=>{
    this.setState({
      tabKey:this.state.errorMapType
    })
  }

  changeLoading=(type)=>{
    this.setState({
      mappingLoading:type
    })
  }

  render() {
    const {
      marketplacesHideSupplier,
      marketplacesVisible,
      originProductDataMarketplaces,
      fbdSupply,
      inviteLoading,
      productOpenOnShopify
    } = this.props;

    const {
      tabKey,
      showInviteModal,
      bannerData,
      types,
      publicBtnDispath,
      invalidVariants,
      isVisibleModal,
      replaceStatus,
      aeDetails,
      changeDetails,
      changeAe,
      aeUrl,
      aeUrlError,
      aeLoading,
      replaceDrawer,
      newProductDetails,
      replaceLoading,
      disReplaceProduct,
      checkVariantStatus,
      checkVisibleModal,
      errorMapType,
      errorText,
      switchoverMapping,
      mappingLoading,
    } = this.state;

    console.log(mappingLoading);

    const menu = (
      <Menu>
        <Menu.Item key="0" style={{padding:'0'}}>
            <Tooltip title={!this.state.replaceLoading && this.state.disReplaceProduct ? intl.getHTML('mapping.replace_product_tip') : ''}>
              <Button
                className={styles.replacePro}
                onClick={this.replaceChange}
                loading={this.state.replaceLoading}
                disabled={this.state.disReplaceProduct}
              >
                {intl.get('mapping.replace_product.btn_title')}
              </Button>
            </Tooltip>
       </Menu.Item>
      </Menu>
    )


    const originProductDataMarketplacesOriginalData = JSON.parse(JSON.stringify(originProductDataMarketplaces))

    return (
      <>
        <Drawer
          visible={marketplacesVisible}
          maskClosable
          width={868}
          onClose={!mappingLoading && this.handleClose}
          data-label="mapping-board"
          destroyOnClose
          className={styles.mappingDrawer}
          className="mapping"
        >

          <Spin size="large" spinning={mappingLoading} className={styles.location}>


          <CheckSaveModal
            type='notSave'
            saveAndNextHandler={this.saveAndNextHandler}
            closeCkeckModalHanlder={this.closeCkeckModalHanlder}
            onlycloseCkeckModalHanlder={this.onlycloseCkeckModalHanlder}
            visible={isVisibleModal}
          ></CheckSaveModal>
          <CheckSaveModal
            type='error'
            errorMapType={errorMapType}
            errorText={errorText}
            visible={checkVisibleModal}
            toCheckHandler={()=>{
              this.changeTab(errorMapType)
              this.setState({
                errorMapType:'basic',
                checkVisibleModal:false
              })
            }}
            closeCkeckErrModalHanlder={this.closeCkeckErrModalHanlder}
          ></CheckSaveModal>
          {bannerData && Object.keys(bannerData).length > 0 ? (
            <Banner data={bannerData} onCheckClick={this.handleCheckClick} />
          ) : null}
          <h3 className={styles.SupplierManagement}>
            {intl.get('setting.agency.marketplaces')}
          </h3>

          <p className={styles.mappingTip}>
            {intl.getHTML('setting.agency.marketplaceExplain')}
            <Tooltip title={
                <span>Click <a target='_blank' href="https://help.dsers.com/basic-mapping-advanced-mapping-bogo-bundle-introduction-for-shopify/">here</a> to know more about Mapping</span>
              }
            >
              <Icon
                type="question-circle"
                theme="outlined"
                style={{
                  marginLeft: 2,
                  transform: 'scale(.9)'
                }}
              />
            </Tooltip>
            {intl.getHTML('setting.agency.marketplaceExplain1')}
            <Tooltip
              title={
                <span>{intl.getHTML('mapping.description_tip')}</span>
              }
            >
              <Icon style={{cursor:'pointer'}} type="info-circle" />
            </Tooltip></p>
          <p className={styles.mappingTipImportant} style={{marginBottom: '1.5rem'}}>
            {/* <Alert
              showIcon={true}
              icon={<span><i class="material-icons" style={{color:'#FF8F00',marginTop:'-0.25rem',marginLeft:'-0.1875rem'}}>error_outline</i></span>}
              type="warning"
              className={styles.mappingAlert}
              message={intl.get('mapping.description_tip')}
            /> */}
          </p>
          {invalidVariants?.length ? (
            <p className={styles.mappingTipImportant}>
              The variant {invalidVariants.map(item => item.title).join(', ')}{' '}
              of the current product is a newly added variant on Shopify,please
              delete the original mapping and redo the mapping.
            </p>
          ) : null}
          {publicBtnDispath ? (
            <Alert
              message={intl.get(
                'order.order_product.shipping_dialog.overrun_warning'
              )}
              type="warning"
              showIcon
            />
          ) : null}
          <Tabs
            activeKey={tabKey}
            onChange={this.changeTab}
            tabPosition="top" animated={{inkBar: false, tabPane: false}}
          >
            <TabPane
              tab={intl.get('mapping.tab_btn_basic_mapping')}
              key="basic"
            >
              <Basic
                types={types}
                originProductData={originProductDataMarketplacesOriginalData}
                copyLink={productOpenOnShopify}
                onRef={this.onBasic}
                isClickSaveBtn={this.isClickSaveBtn}
                setLoading={this.setLoading}
                hideSupplier={marketplacesHideSupplier}
                setHasSave={this.setHasSave}
                fbdSupply={fbdSupply}
                publicBtnDispathShut={this.publicBtnDispathShut}
                publicBtnDispathOpen={this.publicBtnDispathOpen}
                publicBtnDispath={publicBtnDispath}
                setInvalidVariants={this.setInvalidVariants}
                changemodalHidden={this.changemodalHidden}
                hasSavedHandler={this.hasSavedHandler}
                showSaveBtn={this.showSaveBtn}
                changeMappingStatus={this.changeMappingStatus}
                checkVariantStatusHandler={this.checkVariantStatusHandler}
                checkVariantStatus={checkVariantStatus}
                switchoverMapping={switchoverMapping}
                changeLoading={this.changeLoading}
              />
            </TabPane>
            <TabPane
              tab="Standard"
              key="standard"
            >

              <Standard
                types={types}
                originProductData={originProductDataMarketplacesOriginalData}
                copyLink={productOpenOnShopify}
                onRef={this.onStandard}
                setLoading={this.setLoading}
                hideSupplier={marketplacesHideSupplier}
                setHasSave={this.setHasSave}
                fbdSupply={fbdSupply}
                publicBtnDispathShut={this.publicBtnDispathShut}
                publicBtnDispathOpen={this.publicBtnDispathOpen}
                publicBtnDispath={publicBtnDispath}
                setInvalidVariants={this.setInvalidVariants}
                changemodalHidden={this.changemodalHidden}
                hasSavedHandler={this.hasSavedHandler}
                showSaveBtn={this.showSaveBtn}
                changeMappingStatus={this.changeMappingStatus}
                checkVariantStatusHandler={this.checkVariantStatusHandler}
                checkVariantStatus={checkVariantStatus}
                switchoverMapping={switchoverMapping}
              />
            </TabPane>
            <TabPane
              tab={intl.get('mapping.tab_btn_advanced_mapping')}
              key="advance"
            >
              <Advance
                types={types}
                originProductData={originProductDataMarketplacesOriginalData}
                onRef={this.onAdvance}
                setLoading={this.setLoading}
                hideSupplier={marketplacesHideSupplier}
                setHasSave={this.setHasSave}
                fbdSupply={fbdSupply}
                copyLink={productOpenOnShopify}
                publicBtnDispath={publicBtnDispath}
                changemodalHidden={this.changemodalHidden}
                hasSavedHandler={this.hasSavedHandler}
                showSaveBtn={this.showSaveBtn}
                changeMappingStatus={this.changeMappingStatus}
                checkVariantStatusHandler={this.checkVariantStatusHandler}
                checkVariantStatus={checkVariantStatus}
                switchoverMapping={switchoverMapping}
              />
            </TabPane>
            <TabPane tab={intl.get('mapping.tab_btn_bogo')} key="bogo">
              <Bogo
                types={types}
                originProductData={originProductDataMarketplacesOriginalData}
                onRef={this.onBogo}
                setLoading={this.setLoading}
                hideSupplier={marketplacesHideSupplier}
                setHasSave={this.setHasSave}
                fbdSupply={fbdSupply}
                copyLink={productOpenOnShopify}
                publicBtnDispath={publicBtnDispath}
                changemodalHidden={this.changemodalHidden}
                hasSavedHandler={this.hasSavedHandler}
                showSaveBtn={this.showSaveBtn}
                changeMappingStatus={this.changeMappingStatus}
                checkVariantStatusHandler={this.checkVariantStatusHandler}
                checkVariantStatus={checkVariantStatus}
                switchoverMapping={switchoverMapping}
              />
            </TabPane>
            <TabPane tab={intl.get('mapping.tab_btn_bundle')} key="bundle">
              <Bundle
                types={types}
                originProductData={originProductDataMarketplacesOriginalData}
                onRef={this.onBundle}
                setLoading={this.setLoading}
                hideSupplier={marketplacesHideSupplier}
                setHasSave={this.setHasSave}
                fbdSupply={fbdSupply}
                copyLink={productOpenOnShopify}
                publicBtnDispath={publicBtnDispath}
                changemodalHidden={this.changemodalHidden}
                hasSavedHandler={this.hasSavedHandler}
                showSaveBtn={this.showSaveBtn}
                changeMappingStatus={this.changeMappingStatus}
                checkVariantStatusHandler={this.checkVariantStatusHandler}
                checkVariantStatus={checkVariantStatus}
                switchoverMapping={switchoverMapping}
              />
            </TabPane>
          </Tabs>
          </Spin>
          <div style={{'height':'3.125rem'}}></div>
          <div className={ styles.bottomSaveMapping }>
            <div className={styles.bottomMappingContent}>
            {/* <Dropdown overlay={menu} trigger={['click']} visible={this.state.dropDownVisible}>
              <a className="ant-dropdown-link" onClick={(e)=>{e.preventDefault();this.setState({dropDownVisible:!this.state.dropDownVisible})}}>
                MORE ACTIONS <Icon style={{transition:'0.2s',transform:this.state.dropDownVisible ? 'rotate(-180deg)' : null}} type={"down"} />
              </a>
            </Dropdown> */}
            </div>

            <Button
              data="dx"
              type="primary"
              disabled={this.state.saveBtnVisiable}
              className={styles.saveBtn}
              onClick={()=>this.handleSaveMapping('fromSaveButton')}
              data-label="mapping-submit"
            >
              {intl.get('public.save')}
            </Button>
          </div>
          {marketplacesVisible ? <ProductShipping /> : null}
        </Drawer>
        {showInviteModal ? (
          <Modal
            visible={showInviteModal}
            maskClosable={false}
            footer={null}
            className={styles.mappingInviteCode}
            closable={false}
          >
            <div className={styles.content}>
              <Icon type="question-circle" />
              <div className={styles.tip}>
                Please enter invitation code to activate the feature. If you
                don't have the code at the moment, please contact&nbsp;
                <a
                  href="https://www.messenger.com/t/DSersOfficial" dd="src"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={this.showCustomer}
                >
                  customer service
                </a>
                &nbsp;to get it.
              </div>
            </div>
            <div className={styles.inviteTool}>
              <Input
                placeholder="Input code"
                onChange={this.changeInviteCode}
              />
              <Button
                type="primary"
                onClick={this.subInviteCode}
                loading={inviteLoading}
              >
                {intl.get('public.ok')}
              </Button>
            </div>
          </Modal>
        ) : null}
        {replaceStatus &&(
          <Modal
            title={intl.get('mapping.replace_product.btn_title')}
            visible={true}
            destroyOnClose={true}
            footer={null}
            width={868}
            className={styles.replaceModal}
            closable={false}
            onCancel={this.hideReplaceModal}
          >
            <div className={styles.modalTip} dangerouslySetInnerHTML={{__html: intl.get('mapping.replace_product.modal_tip')}} />
            <div className={styles.replaceCon}>
              <div className={styles.replaceShopify}>
                <div className={styles.replaceTitle}>{intl.get('mapping.replace_product.shopify')}</div>
                <div className={styles.container}>
                  <img src={originProductDataMarketplaces.image} />
                  <div className={styles.aeDetails}>
                    <a
                      role="button"
                      tab-index="0"
                      onClick={() => {
                        this.productOpenOnShopify(originProductDataMarketplaces);
                      }}
                    >
                      <Ellipsis lines={3}>{originProductDataMarketplaces.title}</Ellipsis>
                    </a>
                  </div>
                </div>
              </div>
              <div style={{position: 'absolute'}} className={styles.replaceIcon}><i class="material-icons">arrow_back</i></div>
              <div className={styles.replaceAli}>
                <div className={styles.replaceTitle}>{intl.get('mapping.replace_product.ali_express')}</div>
                {changeAe
                ? (
                  <Spin indicator={<Icon type="loading" />} spinning={aeLoading} >
                    <div className={styles.container}>
                      <img src={this.aeDetailsImg} />
                      <div className={styles.aeDetails} style={{maxWidth: '16.125rem'}}>
                        <a
                          role="button"
                          tab-index="0"
                          onClick={() => {
                            this.productOpenOnAe(aeDetails);
                          }}
                        >
                          <Ellipsis lines={3}>{aeDetails.subject}</Ellipsis>
                        </a>
                        <i onClick={this.deleteReplaceProduct} class="material-icons">close</i>
                      </div>
                    </div>
                  </Spin>
                )
                : (
                  <div className={styles.container}>
                    <Input style={{height: '3rem'}} value={aeUrl} onChange={this.changeAeUrl} />
                  </div>
                )}
                  {aeUrlError && (<div className={styles.urlErrorText}>{intl.get('mapping.replace_product.url_error')}</div>)}
              </div>
            </div>
            <div className={changeAe ? styles.replaceBtn : styles.disabledBtn}>
              <Button onClick={() => {this.setState({replaceStatus: false},() => {this.deleteReplaceProduct()})}}>{intl.get('mapping.replace_product.cancle')}</Button>
              <Button
                onClick={this.openAeImport}
                disabled={!changeAe}
              >{intl.get('mapping.replace_product.btn_title')}</Button>
            </div>
          </Modal>
        )}
       {replaceDrawer &&(
        <Drawer
          style={{ borderRadius: 12 }}
          placement="right"
          className="souti"
          title={intl.get('import_list.edit_detail')}
          closable={true}
          onClose={() => {
            this.setState({
              replaceDrawer: false
            });
          }}
          visible={true}
          width={1008}
          destroyOnClose={true}
        >
          <ReplaceMyProdcutEdit
            pid={originProductDataMarketplaces.id}
            producteDetail={newProductDetails}
            onHide={this.hideDetail}
            originProductData={originProductDataMarketplaces}
            handleClose={this.handleClose}
            getReplaceId={this.getReplaceId}
            getReplaceTime={this.props.getReplaceTime}
            deleteReplaceProduct={this.deleteReplaceProduct}
            // onSubmit={this.updateMyProduct}
            // removeProduct={thisNaNpxoveProduct}
            // pageKey={this.pageKey}
          />
        </Drawer>)}

      </>
    );
  }
}

Marketplaces.defaultProps = {
  marketplacesHideSupplier: () => {},
  disableBanner: false
};
