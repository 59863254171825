/**
 * Content:  Oberlo 用户引导相关
 */

// 是否展示底部banner
export const OblerloGuide_Banner = 'OblerloGuide_Banner';

// 是否展示博客卡片
export const OblerloGuide_Card = 'OblerloGuide_Card';

// 是否显示引导弹窗
export const OblerloGuide_Pop = 'OblerloGuide_Pop'

// 是否展示跳转订阅前弹窗
export const TurnPlan_Pop = 'TurnPlan_Pop'

// 是否显示顶部提示
// export const  OblerloTop_Tip = 'OblerloTop_Tip'