import { Get, Post } from '../utils/request';

export async function getOrderStat(payload) {
  // models/order.js里用到这个函数了
  return Get('/stat/order', payload);
}

export async function getChart(payload) {
  return Post('/shop/new/statistics', payload);
}

export async function getGuidance(payload) {
  return Get('/leadpagestatus', payload);
}

export async function postGuidance(payload) {
  return Post('/leadpagestatus', payload);
}

export async function SupplyChain(payload = {}) {
  return Get('/supply/user/status', payload);
}
