
    export default 
    {
    "reset_password": {
        "title": "Resetta la password",
        "input_old_password": "Si prega di inserire la tua vecchia password!",
        "old_password": "Vecchia password",
        "new_password": "Nuova password",
        "input_confirm_password": "Si prega di confermare la nuova password.",
        "confirm_password": "Conferma la password",
        "reset_password": "Resetta la password",
        "wrong_password": "Password errata. Si prega di riconfermarla.",
        "astrict": "Si prega di inserire da 6 a 20 caratteri.",
        "old_password_error": "La password originale non è corretta"
    }
}