export default {
  product_edit: {
    repeated_tip: 'As cores e/ou tamanhos estão repetidos, por favor modifique',
    push_description: 'Click <a style="color:#3D5AFE" target="_blank" href="/app/settings?id=4">here</a> to set the content contained in the description when pushing the product.',
    picture: 'Imagens',
    specifications: 'SPECIFICATIONS',
    overview: 'OVERVIEW',
    compare_price: 'Comparar preço',
    delete_variant_tip: 'Você tem certeza que quer deletar esta variante',
    delete_selected_variant_tip:
      'Você tem certeza que quer deletar as variantes selecionadas?',
    new_compare_price: 'Novo preço para comprar',
    new_price: 'Novo preço',
    new_stock: 'Novo estoque',
    open_on_supplier: 'CLIQUE PARA SABER MAIS',
    from_other: 'de outro',
    seller_id: 'ID do vendedor',
    input_title: 'Por favor coloque o título',
    product_type: 'Tipo de produto',
    collection: 'Coleção',
    vendor: 'Vendedor',
    tags: 'Tags',
    no_edit:
      'Produtos que foram publicados no Shopify não podem ser editados diretamente.',
    select_variant: 'Escolher variante',
    none: 'Nenhum',
    description: 'Descrição',
    images: 'Imagens <i>({selectImages}/{allImages})</i>',
    shipping: 'Shipping info',
    shipping_info: 'You can check {platform} shipping cost information, estimated delivery time and tracking availability here by selecting a Ship to (and Ship from) country. Shipping information will NOT be pushed to Shopify when you publish. This is just to give you an estimate of shipping cost.',
    cannot_recover:
      'Você não pode recuperar a variante do produto selecionado, você deseja continuar?',
    download_pic: 'Clique para baixar todas as imagens',
    delete_pic_tip:
      'Do you want to delete the image(s)? The deleted image(s) will not be uploaded to the Media section in the Shopify product.',
    delete_picss_tip:'Do you want to delete all the images? No images will be uploaded to the Media section in the Shopify product.',
    back: 'Voltar',
    product: 'Produto',
    from_aliexpress: 'Do Aliexpress',
    from_tmall:'Do Tmall',
    title: 'título',
    variants: 'Variantes <i>({allvariants})</i>',
    all: 'Tudo',
    action: 'Ação',
    sku: 'Sku',
    cost: 'Custo',
    price: 'Preço',
    shopify_stock: 'Stock to Shopify',
    stock: 'Stock on <br /> fornecedor',
    replace_stock:'Stock on <br /> {platform}',
    stock_on_shopify: 'Stock on <br /> Shopify',
    change_price: 'Alterar preço',
    change_compare_at_price: 'Alterar preço de Comparação',
    remove_sku: 'Remover SKU(s)',
    change_stock: 'Modificar Estoque',
    option_repeated_tip: 'Cores e/ou tamanhos dos produtos são repetidos. Altere-os.',
    sku_repeated_tip: 'SKU need to be different for each variant. Please modify it.',
    option_name_invalid: 'O nome da opção não pode ficar em branco',
    option_value_invalid: 'O valor da opção não pode ficar em branco',
    option_name_repeat: 'O nome da opção não pode ser repetido',
    option_name_slash: 'O nome da opção não pode conter "/"',
    option_sku_invalid: 'O valor da SKU não pode ficar em branco',
    sku_count_invalid: 'Mantenha pelo menos uma SKU',
    overrun_warning: 'You exceeded the daily limitation to access the product information',
    change_image: 'Change image',
    organization: 'Organization',
    download_all_img: 'Download',
    delete_img:'Delete',
    img_des:'The pictures checked here will appear in the Media of Shopify products',
    not_select_main_img_des:'If you don’t select the cover picture here, the first picture you check here will be used as the cover picture for this Shopify product.',
    deleteImage: 'Delete Image',
    products:'Products',
    shipping_cost:'Custo de <br /> envio',
    uncheckedAlert:'Se você não selecionar uma imagem aqui, nenhuma imagem será carregada como “Mídia” do produto na Shopify.',
    try_again_collections_text:`We couldn't get your collections data because the request timeout, please click `,
    try_again_collections_fun:'Refresh',
    checkImages:'Imagens <i>({allImages})</i>',
    select_province_pleaseholder:'Selecione a cidade/província',
    ship_from_text:'Enviar de:',
    ship_to_text:'Enviar para:',
    product_edit_price_tip:'O símbolo da moeda variará de acordo com as configurações da sua loja Shopify após a publicação ser feita na Shopify. Defina manualmente aqui o preço/preço de comparação, se necessário.',
    shopify_stock_tip:'Você pode definir a quantidade de estoque que deseja enviar à Shopify aqui. Ela não será atualizada quando o estoque do fornecedor for alterado no {platform}.',
    aliexpress_stock_tip:`O DSers atualizará automaticamente o estoque do AliExpress para os produtos na lista de importação a cada duas horas. O estoque não pode ser modificado manualmente.`,
    tmall_stock_tip: "O DSers atualizará automaticamente o estoque do Tmall para os produtos na “Lista de importação”. O estoque exibido aqui não pode ser modificado manualmente.",
    product_stock_tip:'O DSers atualiza automaticamente o estoque dos produtos na página “Meus produtos”. O estoque exibido aqui não é modificado manualmente. Não atualizamos o estoque no mapeamento Avançado/BOGO/Pacote.',
    shopify_product_stock_tip:'O estoque exibido aqui é sincronizado com a Shopify e não é atualizado conforme as alterações do fornecedor, a menos que você ative a atualização automática no plano pago.',
    download_img_fail_tip:'Image download failed, please try again later',
  }
};
