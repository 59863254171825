import React, { useState, useEffect } from 'react';
import intl from 'react-intl-universal';
import { Select } from 'antd';

import { replaceUrlToHttps } from 'utils/utils';
import SetShipping from 'routes/Setting/Dropshpper/Common/Shipping/Shipping';
import { getImportHistory } from '../../../services/productmy';

import styles from './ShippingDetail.less';

function ShippingDetail({ id, data, ship_to }) {
  const [selectData, setSelectData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [productData, setProductData] = useState([]);
  const [selectValue, setSelectValue] = useState(undefined);
  // console.log(data, id)
  useEffect(() => {
    if (data && data.aliexpress_product_id) {
      setSelectData(data);
    }
  }, [data]);

  useEffect(() => {
    if (!id) {
      return;
    }
    setLoading(true);

    getImportHistory({
      data: { id }
    })
      .then(res => {
        if (res.code === 2000 && res.data) {
          let first = {}
          res?.data?.forEach(item=>{
            if(`${data?.ae_pid}` === `${item?.aliexpress_product_id}`){
              first = item
            }
          })
          if (first) {
            setSelectValue(first.id);
            setSelectData(first);
          }
          setProductData(res.data);
        }
        return null;
      })
      .finally(() => {
        setLoading(false);
      })
      .catch(err => {
        console.log('getImportHistory error: ', err);
      });
  }, [id]);

  function handleSelectChange(key) {
    setSelectValue(key);
    const value = productData.find(i => i.id === key);
    if (value) {
      setSelectData(value);
    }
  }

  return (
    <div className={styles.shippingTab}>
      <div className={styles.title}>
        {intl.get('product_edit.shipping_info',{
          platform:selectData?.supplier_type === 4 ? 'Tmall' : 'AliExpress'
        })}
      </div>
      <div className={styles.bot_t_scr}>
      {id ? (
        <div className={styles.selectWrap}>
          <Select
            suffixIcon={<img className="img_btn" src={require('../../../assets/newImportList/icon_expand_more-24px.png')}></img>}
            style={{ width: '100%' }}
            onChange={handleSelectChange}
            value={selectValue}
            optionLabelProp="label"
            placeholder="Choose a product"
            loading={loading}
            getPopupContainer={triggerNode => triggerNode.parentNode}
          >
            {productData.map(item => (
              <Select.Option key={item.id} value={item.id} label={item.title}>
                <div className={styles.productItem}>
                  <img src={replaceUrlToHttps(item.image_src)} alt="" />
                  <span>{item.title}</span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </div>
      ) : null}
      {selectData ? <SetShipping prod={selectData} productShipTo={ship_to} selectable={false} /> : null}
      </div>
    </div>
  );
}

export default ShippingDetail;
