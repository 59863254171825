export default {
  support: {
    title: 'Support',
    description:
      'Welcome to DSers support. We are here to help you boost your dropshipping business with heart and soul.',
    email: 'EMAIL',
    email_dec:
      'Envoyez nous un email avec vos questions ou vos idées! <br/> Nous répondrons au plus vite.',
    email_btn: 'Contact Email',
    live_chat: 'LIVE CHAT',
    live_chat_dec:
      'Envoyez nous un message en cas de problème. Nous sommes là <br /> pour vous parler.',
    live_chat_btn: 'Contactez-nous',
    facebook: 'FACEBOOK',
    facebook_dec:
      'Suivez notre page Facebook pour connaître les dernières nouvelles sur DSers et le dropshipping.',
    facebook_btn: 'Facebook',
    next_level: 'Passez votre business au niveau supérieur',
    try_now: 'Essayez maintenant sans risque.',
    get_started: 'Commencer',
    partnership: 'Partenariat'
  }
};
