
    export default 
    {
    "setting-supply": {
        "title": "Supply Service Entrust (SSE)",
        "content": "Quando attivi il Supply Service Entrust, DSers rimpiazzerà automaticamente il fornitore tracciato automaticamente di alcuni dei tuoi prodotti per ottimizzare i tuoi guadagni.I fornitori che ne prenderanno il posto forniranno una fornitura più stabile, prezzi bassi all'ingrosso, spedizione più veloce, e molto altro, in base al prodotto.La procedura di ottimizzazione verrà portata a termine da un team di professionisti secondo l'analisi di big data. <a href=\"https://www.blog.dserspro.com/post/introduction-to-supply-service-entrust\" target=\"_blank\"> scopri di più</a>",
        "activated": "SSE attivato",
        "activate": "Attiva",
        "sse_agreement": "Accordo di SSE",
        "feedback_title": "La tua candidatura è andata a buon fine!",
        "feedback_con": "L'account manager a te assegnato ti <br /> ricontatterà entro 1 giorno lavorativo",
        "notice_title": "Il Supply Service Entrust è online!",
        "supply_tab": "Fornitura di Massa",
        "modal_title": "Grazie per esserti candidato al Supply Service Entrust",
        "modal_intro": "Si prega di inserire la propria email di contatto. L'account manager a te assegnato ti ricontatterà entro 1 giorno lavorativo.",
        "modal_agreement": "Cliccando su Vai accetti l'accordo <a href=\"https://www.blog.dserspro.com/post/supply-service-entrust-agreement\" target=\"_blank\">SSE</a>"
    }
}