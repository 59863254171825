
    export default 
    {
    "combo": {
        "headline": "Gestisci l'iscrizione",
        "explain": "Gestisci l'iscrizione e configura un negozio di fatturazione predefinito qui. Clicca qui per controllare come gestire l'iscrizione. E se vuoi saperne di più sui Piani DSers, puoi cliccare qui.",
        "change": "Cambia piano",
        "changEcard": "Piano attuale:",
        "changEcardBu": "Cambia piano",
        "changEcardUrl": "Come cancellare l'iscrizione per un piano a pagamento?",
        "changEcardservice": "Contatta",
        "paymentcard": "Negozio di fatturazione predefinito",
        "paymentcardEcard": "Si prega di configurare un negozio come negozio di fatturazione predefinito. Se non viene configurato, il tuo account DSers verrà chiuso finché non lo configurerai.",
        "paymentcardBu": "Attiva"
    }
}