import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Button } from 'antd';

import styles from './AliShipping.less';
import useOnlineNotice from '../../hooks/useOnlineNotice';

const onlineDate = new Date(2021, 0, 18); // 上线日期

const modalRoot = document.getElementById('root');

function AliShipping({ lang }) {
  const [visible, closeNotice] = useOnlineNotice('ali_shipping', onlineDate);
  const [timing, setTiming] = useState(3);
  const timingRef = useRef(null);

  useEffect(() => {
    timingRef.current = setInterval(() => {
      setTiming(state => {
        if (state === 1) {
          clearInterval(timingRef.current);
          return 0;
        }
        return state - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timingRef.current);
    };
  }, []);

  if (!visible) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className={styles.notice}>
      <div className={styles.body}>
        <div className={styles.content}>
          {lang === 'fr-FR' ? (
            <>
              <h2>Dès le 18 Janvier 2021</h2>
              <div>
                Les méthodes de livraison disponibles vont être modifié.
                <br />
                Veuillez faire attention aux méthodes de livraison choisies
                quand vous placez des commandes pour éviter tout problème.
              </div>
              <div className={styles.ok}>
                <Button
                  disabled={timing !== 0}
                  onClick={() => closeNotice()}
                  type="primary"
                >
                  Compris
                  {timing === 0 ? '' : `(${timing})`}
                </Button>
              </div>
              <div>
                Cliquez{' '}
                <a
                  href="https://www.dsers.com/blog/aliexpress-shipping-methods-changes/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ici
                </a>{' '}
                pour en apprendre plus
              </div>
            </>
          ) : (
            <>
              <h2>Starting January 18, 2021</h2>
              <div>
                The shipping methods available are being modified.
                <br />
                Please pay attention to the shipping method selected when you
                are placing orders to avoid issues.
              </div>
              <div className={styles.ok}>
                <Button
                  disabled={timing !== 0}
                  onClick={() => closeNotice()}
                  type="primary"
                >
                  Got it {timing === 0 ? '' : `(${timing})`}
                </Button>
              </div>
              <div>
                Click{' '}
                <a
                  href="https://www.dsers.com/blog/aliexpress-shipping-methods-changes/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  here
                </a>{' '}
                to learn more
              </div>
            </>
          )}
        </div>
      </div>
    </div>,
    modalRoot
  );
}

export default AliShipping;
