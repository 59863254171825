import React, { Component } from 'react';
import { connect } from 'dva';
import { Link } from 'dva/router';
import Login from 'components/Login';
import { Form, Alert } from 'antd';
import className from 'classnames';
import intl from 'react-intl-universal';
import debounce from 'lodash/debounce';

import SendResetEmail from './SendResetEmail';

import styles from './Login.less';

// import checkPasswordStrength from './checkPasswordStrength';

const { UserName, Password, Submit, Captcha } = Login;

const FormItem = Form.Item;
@connect(({ login, loading }) => ({
  login,
  submitting:
    loading.effects['login/login'] || loading.effects['login/pureLogin'],
  step2Loading: loading.effects['login/googleStep2']
}))
@Form.create()
export default class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: 'bussiness',
      checked: true,
      username: '',
      password: '',
      step2Code: '',
      step2ErrorMsg: '',
      getRecaptchaTokenLoaging: false,
      showValidateCode: false,
      showSendResetEmail: false,
      visible: false,
      help: '',
      eyeVisible:false
    };
  }

  componentWillReceiveProps(nextprops){
    if(nextprops.subloading == 2){
      this.setState({
        getRecaptchaTokenLoaging : false
      })
    }
  }

  componentDidMount = () => {
    this.refreshCaptcha();
    this.setAntModalMaskStyle();
    this.getDefaultUserName();
  };

  getDefaultUserName = () => {
    if (location.search.includes('mail')) {
      this.setState({
        username: this.getQueryString('mail')
      });
    }
  };

  getDefaultMail = () => {
    let v;
    if (location.search.includes('mail')) {
      v = this.getQueryString('mail');
    }
    if (v == 'undefined') {
      v = undefined;
    }
    return v;
  };

  setAntModalMaskStyle = () => {
    const loginDialog = document.getElementsByClassName('loginDialog')[0];
    if (loginDialog) {
      loginDialog.previousElementSibling.id = 'loginDialogMask';
    }
  };

  onTabChange = type => {
    this.setState({ type });
  };

  // 图片验证码
  refreshCaptcha = () => {
    this.props.dispatch({
      type: 'login/captcha',
      payload: {
        data: {
          v: new Date().getTime()
        }
      }
    });
  };

  // eslint-disable-next-line
  handleSubmit = debounce((err, values) => {
    // 登陆
    const _this = this;
    const data = values;
    localStorage.removeItem('keyStatus');
    if (err) {
      return;
    }
    window.dataLayer.push({
      event: 'signCustomEvent',
      type: 'loginSubmitButton',
      payload: { email: values.email }
    });

    this.setState({
      getRecaptchaTokenLoaging: true
    });

    data.cap_key = _this.captcha_key;
    _this.subLogin(data, '');

    // 仅正式环境启用
    // if (window.grecaptcha && process.env.BUILD_TARGET === 'prod') {
    //   // eslint-disable-next-line
    //   window.grecaptcha
    //     .execute('6LfT_oUUAAAAAAbx8FzRMmXDJd53dHEDIR-P-HHF', {
    //       action: 'login'
    //     })
    //     .then(token => {
    //       _this.subLogin(data, token);
    //       return token;
    //     })
    //     .catch(e => {
    //       console.log('grecaptcha error:', e);
    //       _this.subLogin(data, ''); // 如果 grecaptcha 不可用也登录,解决部分用户无法登录的问题
    //       _this.setState({
    //         getRecaptchaTokenLoaging: false
    //       });
    //     });
    // } else {
    //   _this.subLogin(data, '');
    // }
  }, 500);

  subLogin = (data, token) => {
    // eslint-disable-next-line
    new Promise((resolve, reject) => {
      this.props.onSubmit({
        resolve,
        reject,
        data: {
          cap: data.cap ? data.cap : 'no value',
          cap_key: data.cap_key,
          email: data.email.trim(),
          password: data.password,
          google_response: token || ''
        },
        cancelMessage: true
      });
    })
      .catch(error => {
        // eslint-disable-next-line
        console.log('login error:', error);
      })
      .finally(() => {
        this.setState({
          getRecaptchaTokenLoaging: false
        });
      });
  };

  handleSubStop2 = () => {
    // google二次验证登陆
    const { step2Code } = this.state;
    if (step2Code.length != 6) {
      this.setState({
        step2ErrorMsg: 'Incorrect verification code'
      });
      return;
    }
    this.setState({
      step2ErrorMsg: ''
    });
    this.props.dispatch({
      type: 'login/googleStep2',
      payload: {
        data: {
          pass_code: step2Code,
          request_id: this.request_id
        },
        cancelMessage: true
      }
    });
  };

  renderMessage = content => {
    return (
      <div className={styles.error_container}>
        <div className={styles.error_icon}></div>
        <div style={{maxWidth:'400px'}}>{content}</div>
      </div>
    );
  };

  checkEveryWordIsSame = v => {
    if (!v) {
      return false;
    }
    const one = v.substring(0, 1);
    const len = v.length;
    const d = new Array(len).fill(one).join('');
    let flag = false;

    if (d == v && len > 5) {
      flag = true;
    }
    return flag;
  };

  getQueryString = name => {
    const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
    const hash = `?${location.search.split('?')[1]}`;
    const r = hash.substr(1).match(reg);
    if (r != null) {
      return unescape(r[2]);
    }
    return null;
  };

  changeUsername = e => {
    this.setState({
      username: e.target.value
    });
  };

  changePassword = e => {
    this.setState({
      password: e.target.value
    });
  };

  checkPassword = (rule, value, callback) => {
    // const status = checkPasswordStrength(value);
    // if (value) {
    //   if (status === 'Strong' || status === 'VeryStrong') {
    //     this.setState({ visible: false });
    //   } else {
    //     this.setState({ visible: true });
    //   }
    // } else {
    //   this.setState({ visible: false });
    // }

    if (!value) {
      callback(intl.get('login.empty'));
    } else if (value.length < 6) {
      // callback('error msg');
      // 暂时没有报错消息
      callback();
    } else {
      callback();
    }
  };

  changeStep2Code = e => {
    this.setState({
      step2Code: e.target.value
    });
  };

  setLoginData = d => {
    if (!d) {
      return;
    }

    if (d.code == 2000 && d.data.second_validate && this.carouselFlag) {
      this.carouselDom.next();
      this.setState({
        showValidateCode: true
      });
      this.request_id = d.data.request_id;
      this.carouselFlag = false;
    }
  };

  hideSendResetEmail = () => {
    this.setState({
      showSendResetEmail: false
    });
  };

  showSendResetEmailFn = () => {
    this.setState({
      showSendResetEmail: true
    });
  };

  carouselFlag = true;

  request_id = ''; // 需要二次验证的用户的request_id

  getErrorMsg = d => {
    if (!d) {
      return '';
    }

    let msg = '';

    const { code } = d;

    if (code === 5000) {
      msg = d.msg;
    }
    if (code === 4000) {
      msg = intl.get('login.error');
    }

    if(msg === 'user is migration') {
      return 'This account is being migrated and is expected to complete within 24 hours. Please be patient and wait until the migration is finished before accessing the system. Thank you for your understanding and support. If you encounter any other issues, please contact customer service.'
    }

    return msg;
  };

  handlEye = (status)=>{
    return ()=>{
      this.setState({
        eyeVisible: status
      })
    }
  }

  render() {
    const { login, submitting, redirectUrl, subloading } = this.props;

    const { captcha = {}, step2Data = {}, logindata } = login;

    const {
      type,
      username,
      password,
      step2Code,
      step2ErrorMsg,
      getRecaptchaTokenLoaging,
      showValidateCode,
      showSendResetEmail,
      eyeVisible
    } = this.state;

    const { cap = '', k = '' } = captcha.data || {};

    const showMessage = this.getErrorMsg(logindata);

    this.captcha_key = k;
    // this.setLoginData(logindata);

    // if ( login.status === 0 && !submitting ) {
    //   showMessage = login.msg || "Login failed";
    // }
    const { form } = this.props;
    const { getFieldDecorator } = form;
    return (
      <div className={styles.carouselWrap}>
        <div
          className={styles.carouselContent}
          style={{ transform: showValidateCode ? 'translateX(-488px)' : '' }}
        >
          <div className={styles.main}>
            <div className={styles.mainLogin}>
              <h1>{intl.get('login.title')}</h1>
              <Login
                defaultActiveKey={type}
                onSubmit={this.handleSubmit}
                onTabChange={this.onTabChange}
              >
                {showMessage !== '' && this.renderMessage(showMessage)}
                <div
                  className={className(styles.inputWraperNull, {
                    [styles.inputWraper]: username
                  })}
                >
                  <UserName
                    name="email"
                    autoComplete="new-password"
                    placeholder={intl.get('login.email')}
                    defaultValue={this.props.type == 'oberlo' ? this.props.shopEmail ? this.props.shopEmail : this.props.logoutEmail ? this.props.logoutEmail : '' : ''}
                    onChange={e => {
                      this.changeUsername(e);
                    }}
                  />
                  <span className={styles.placeholder}>
                    {intl.get('login.email')}
                  </span>
                </div>
                <div
                  style={{ margin: 0 }}
                  className={className(styles.inputWraperNull, {
                    [styles.inputWraper]: password
                  })}
                >
                  <FormItem>
                    {/* {getFieldDecorator('password', {
                      rules: [
                        {
                          required: true,
                          message: intl.get('login.empty')
                        }
                        // {
                        //   validator: this.checkPassword
                        // }
                      ]
                    })( */}
                      <Password
                        name="password"
                        autoComplete="new-password"
                        type = {eyeVisible ? 'text' : 'password'}
                        placeholder={intl.get('login.password')}
                        onChange={e => {
                          this.changePassword(e);
                        }}
                      />
                    {/* )} */}
                  </FormItem>
                  <span className={styles.placeholder}>
                    {intl.get('login.password')}
                  </span>
                  {eyeVisible ? <i onClick={this.handlEye(false)} className={`material-icons ${styles.eye}`}>visibility</i> : <i onClick={this.handlEye(true)} className={`material-icons ${styles.eye}`}>visibility_off</i>}
                </div>
                {logindata &&
                logindata.data &&
                logindata.data.capture === true ? (
                  <Captcha
                    name="cap"
                    placeholder={intl.get('login.cap_placeholder')}
                    captchaUrl={cap}
                    className={styles.captcha}
                    refreshCaptcha={this.refreshCaptcha}
                    style={{ height: '30px', marginBottom: '0' }}
                  />
                ) : null}
                <Submit
                  loading={submitting || getRecaptchaTokenLoaging}
                  style={this.props.isMobile ? 
                    {
                      height: 62,
                      width:'82vw',
                      marginTop: '64px',
                      marginBottom: '0',
                      boxShadow: '0 2px 4px 0 #D5D8D9',
                      letterSpacing: '0.09px',
                      fontWeight: '600',
                      fontSize: '14px',
                      borderRadius: '8px'
                    }
                    :
                    {
                      height: 45,
                      marginTop: '64px',
                      marginBottom: '0',
                      boxShadow: '0 2px 4px 0 #D5D8D9',
                      letterSpacing: '0.09px',
                      fontWeight: '600',
                      fontSize: '14px',
                      borderRadius: '8px'
                    }
                  }
                >
                  {intl.get('login.log_in')}
                </Submit>
                <div className={styles.extra}>
                  <div>
                    {intl.get('login.noHaveText')}
                   {
                     this.props.type == 'oberlo' ?  <Link
                     to={redirectUrl ? `/migrat-register${redirectUrl}` : '/migrat-register'}
                   >
                     {' '}
                     {intl.get('login.createAccount')}
                   </Link> :
                    <Link
                    to={redirectUrl ? `/register${redirectUrl}` : '/register'}
                  >
                    {' '}
                    {intl.get('login.createAccount')}
                  </Link>
                   }
                  </div>
                  <div>
                    <a
                      onClick={this.showSendResetEmailFn}
                      track-type="forgetPassword"
                      track-name="sign"
                    >
                      {intl.get('login.forgot_password')}
                    </a>
                  </div>
                </div>
                {/* <div className={styles.other}>
                  <p style={{ fontSize: 12, color: '#6D7BE1' }} />
                  <p className={styles.imgs} />
                </div> */}
              </Login>
            </div>
          </div>
        </div>

        {showSendResetEmail ? (
          <SendResetEmail
            showSendResetEmail={showSendResetEmail}
            hideSendResetEmail={this.hideSendResetEmail}
            type={this.props.type}
            redirect={redirectUrl}
          />
        ) : null}
      </div>
    );
  }
}
