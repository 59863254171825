import { Star } from 'components/SvgIcons';
import useTopBanner from 'hooks/useTopBanner';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { DSERS_IMG_HOST } from 'utils/constants';
import { CommonDataCollect } from 'utils/utils';

import styles from './TmallColdStartupBanner.less';

const allowPath = [
  '/home',
  '/order/list',
  '/order/agency/list',
  '/order/unmapped/list',
  '/order/tmall/list',
  '/storemanage/import',
  '/storemanage/myproduct'
];

const allowPathBrazil = [
  '/order/list',
  '/order/agency/list',
  '/order/unmapped/list',
  '/order/tmall/list'
];

const TmallColdStartupBanner = ({ path, lang, userId,BrazilBannerNum }) => {
  const [visible] = useTopBanner(path, allowPath);
  const [visibleBrazil] = useTopBanner(path, allowPathBrazil);
  const handleClickBanner = () => {
    CommonDataCollect({
      action: 'tmall_order_cold_startup_banner_click',
      event_type: 'tmall_order_cold_startup'
    });
  };
  if (!visible || !window.TMALL_USER) {
    return null;
  }
  if(BrazilBannerNum && visibleBrazil){
    return null;
  }
  return (
    <a
      href={`${window.location.origin}/app/find_suppliers`}
      // target="_blank"
      onClick={handleClickBanner}
      className={styles.bannerLink}
    >
      <div className={`${styles.container} ${lang}`}>
        <img
          className={styles.bannerTopImg}
          width="69"
          src={`${DSERS_IMG_HOST}/shopify/activity/tmall_cold_startup/221019/tmall_banner_img_3.png`}
        />
        <img
          className={styles.bannerTopImg2}
          width="258"
          src={`${DSERS_IMG_HOST}/shopify/activity/tmall_cold_startup/221019/tmall_banner_img_4.png`}
        />
        <img
          className={styles.bannerTopImg3}
          width="287"
          src={`${DSERS_IMG_HOST}/shopify/activity/tmall_cold_startup/221019/tmall_banner_img_2.png`}
        />
        <Star className={styles.star1} />
        <Star className={styles.star2} color="#FFD153" />
        <div>
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              {intl.get('banner.tmall_cold_startup.top_banner_title')}
            </div>
            <br />
            <div className={styles.title}>
              {intl.get('banner.tmall_cold_startup.top_banner_title_2')}
            </div>
            <div className={styles.titleLine}></div>
            <img
              className={styles.titleLeftImg}
              width="60"
              src={`${DSERS_IMG_HOST}/shopify/activity/tmall_cold_startup/221019/tmall_banner_img_1.png`}
            />
          </div>
          <div className={styles.desc}>
            <div>{intl.get('banner.tmall_cold_startup.popup_desc_1')}</div>
            <div>{intl.get('banner.tmall_cold_startup.popup_desc_2')}</div>
            {/* <img
              className={styles.buttonStar}
              width="14"
              src={`${DSERS_IMG_HOST}/shopify/activity/agency_cold_startup/221017/agency_startup_img_5.png`}
            /> */}
          </div>
        </div>
      </div>
    </a>
  );
};

TmallColdStartupBanner.allowPath = allowPath;

export default TmallColdStartupBanner;
