export default {
  agency: {
    // global
    Agency: 'Agência (Fornecedor Individual)',
    agency: 'agência',
    // setting
    supplier: 'Fornecedor',
    title: 'Gerenciamento de Agência (Fornecedor Individual)',
    subtitle:
      'Você pode gerenciar as informações do fornecedor aqui, fazer pedidos diretamente ao fornecedor e não mais comprar através de mercados de terceiros (como o AliExpress). Clique aqui para saber mais.',
    subtitle_1: 'Você pode gerenciar as informações do fornecedor aqui, fazer pedidos diretamente ao fornecedor e não mais comprar através de mercados de terceiros (como o AliExpress). Clique ',
    subtitle_2: '  para saber mais.',
    subtitle_3: ' aqui ',
    title_tip: 'Uma agência representa um fornecedor de produtos que possibilita que você processe pedidos sem um marketplace de terceiros, como o AliExpress. Assim, você pode efetuar pedidos diretamente junto às agências e elas enviarão os produtos aos seus clientes.',
    name_tip: 'O nome da sua agência que será usado e exibido ao mapear um produto ou processar um pedido.',
    erp_tip: 'Os representantes de ERPs, como Dianxiaomi, Mabang, ECCANG, entre outros, que oferecem gestão de estoque dos produtos, monitoramento de compras, distribuição logística e rastreamento de pacotes.',
    paypal_tip: `A conta do PayPal da sua agência. Após configurar esta conta do PayPal, você poderá iniciar os pagamentos diretamente com a agência, enquanto o DSers processa os pedidos.`,
    phone_tip: 'O número de telefone da sua agência que será usado pela sua agência para preencher pedidos por SMS.',
    name: 'Nome da agência',
    nameErr: 'O nome da agência não pode ficar em branco.',
    phone: 'Número de telefone',
    phoneErr: 'Somente números são aceitos',
    erp: 'ERP em uso',
    erpPlaceholder: 'Selecionar ERP',
    erpErr: 'Selecione um ERP.',
    paypal: 'Conta do PayPal',
    paymentMethod: 'Payment method',
    agencyContactEmail: 'Agency contact email',
    stripeAccount: 'Stripe account',
    agencyStripe: 'Agency Stripe',
    stripeNoAuth: 'No authorization, please contact the supplier for authorization.',
    agencyPaymentTip: `Your Agency's Payment methods. After setting up this payment method, you can initiate payments directly to the Agency while DSers process orders.`,
    agencyNoAuthModalTitle: 'Authorization not completed',
    agencyNoAuthModalContent: `Your supplier has not completed the Stripe account authorization, please contact your supplier to complete the Stripe account authorization. If you have more questions, please contact the DSers support team.`,
    agencyNoAuthModalGetSupport: 'GET SUPPORT',
    paypalPlaceholder: 'Conta do PayPal da agência',
    paypalErr: 'A conta do PayPal não pode ficar em branco.',
    paypalEmailErr: 'Insira um e-mail do PayPal válido',
    delError: 'O fornecedor foi mapeado e não pode ser excluído.',
    phonePlaceholder: 'Country',

    // setting > save text

    savetitle: "Adicionar uma agência (fornecedor individual)",
    savetext: "Após criar as informações da agência, o DSers enviará um e-mail para seu fornecedor ({email}).Entre em contato com seu fornecedor, que fará uma configuração simples para que você possa continuar a gerenciar o mapeamento de produtos, o cumprimento automático de pedidos e outras funções por meio do DSers.",
    deletetitle: "Supressão falhou.",
    deletetext: "Atualmente, você tem produtos mapeados para este fornecedor. Para evitar afetar produtos e pedidos existentes, limpe o mapeamento relacionado a este fornecedor antes de tentar novamente.",

    // my product
    Agencies: 'Agências <i>({ae_num})</i>',
    filterByAgency: 'Filtrar por agência',

    filterAgencyPlease: 'Acesse',
    filterAgencyAddInformation: 'para adicionar uma agência.',

    supplierManagement: 'Gestão de fornecedores',
    marketplaces: 'Marketplaces',
    agencyInformation: 'Informações da agência',
    selectAgency: 'Selecionar agência',
    refreshPage: 'Atualizar página',
    REFRESH: 'ATUALIZAR',
    mappingUpdating: 'Atualizando mapeamento',
    markeCardDescription:
      'Você pode mapear os produtos do fornecedor para os produtos da sua loja e, quando os produtos de venda são encomendados, pode fazer uma encomenda ao fornecedor correspondente com apenas um clique no DSers.',
    markeCardDescriptionNoTianMao:
      'Com marketplaces, você pode selecionar produtos do AliExpress para mapear os seus itens na Shopify.',
    agencyCardDescription:
      'A agência representa um único fornecedor fora dos marketplaces (como AliExpress). Você pode mapear os produtos da sua loja para a agência e fazer pedidos diretamente do seu ERP para cumprimento automático.',
    marketplaceExplain:
      'Mapeamento ',
    marketplaceExplain1:
    ' de fornecedores de marketplaces para os seus produtos na Shopify. Os detalhes dos produtos na Shopify (como título, descrição, imagens, variantes etc.) permanecem os mesmos após um fornecedor ser adicionado.',
    agencyExplain:
      'Você pode escolher o fornecedor desejado de acordo com as suas necessidades. Os detalhes dos produtos na Shopify (como título, descrição, imagens, variantes etc.) permanecem os mesmos após um fornecedor ser adicionado.',
    statusExplain: 'Ative para acionar o método de mapeamento por agência',
    selectAgentPrompt:
      'Selecione uma agência para mapear os seus produtos na Shopify. Se você ainda não adicionou uma agência, é necessário acessar',
    toSetting: 'Configurações',
    addAgency: 'para adicionar uma agência.',
    noSelectionPrompt:
      'Você ainda não adicionou nenhuma agência. Acesse “Configurações” para adicionar uma agência.',
    noSelectionButton: 'ACESSAR “CONFIGURAÇÕES” PARA ADICIONAR AGÊNCIAS',
    refreshPagePrompt:
      'Atualize a página para que possamos detectar se você adicionou uma agência com sucesso.',
    backendProcessesPrompt:
      'O método de mapeamento do produto foi alterado. Levaremos algum tempo para ajudar você a substituir o mapeamento dos pedidos relacionados ao produto.',
    TimeoutErr: 'Solicitação expirada, tente novamente mais tarde.'
  }
};
