export default {
  pricing: {
    title: 'Plus de Gains, à moindre Coût',
    desc: 'Plan gratuit disponible',
    tipSelectPlan: 'Veuillez choisir un plan pour continuer',
    Basic: 'Basique',
    BasicText: ' (Gratuit pour toujours)',
    Standard: 'Standard',
    StandardText: `jours d'essai gratuit`,
    No_card_AdvancedText: '',
    No_card_ProText: '',
    No_card_StandardText: '',
    Advanced: 'Avancé',
    AdvancedText: `jours d'essai gratuit`,
    Pro: 'Pro',
    ProText: ` jours d'essai gratuit`,
    Business: 'Business',
    Enterprise: 'Enterprise',
    // Admonth: '$19.9/mois',
    // Prmonth: '$49.9/mois',
    // Stmonth: '$1.99/mois',
    Admonth: '<span class="mount mounttop">$</span><span class="mountP">19.9</span><span class="mount">/mois</span>',
    Prmonth: '<span class="mount mounttop">$</span>49.9<span class="mount">/mois</span>',
    Stmonth: `<span class="mount mounttop">$</span>1.99<span class="mount">/mois</span>`,
    Enmonth:`<span class="mount mounttop">$</span>499<span class="mount">/mois</span>`,
    NewStmonth:`<span class="mount mounttop">$</span>9.9<span class="mount">/mois</span>`,
    // Adannually: '$15.9',
    // Prannually: '$39.9',
    // Stannually: '$1.99',
    Adannually: '<span class="mount mounttop">$<s class="deleter">19.9</s></span><span class="mountP">15.9</span><span class="mount">/mois</span>',
    Prannually: '<span class="mount mounttop">$<s class="deleter">49.9</s></span>39.9<span class="mount">/mois</span>',
    Stannually: `<span class="mount mounttop">$</span>1.99<span class="mount">/mois</span>`,
    Enannually:`<span class="mount mounttop">$<s class="deleter">499</s></span>399<span class="mount">/mois</span>`,
    NewStannually:`<span class="mount mounttop">$<s class="deleter">9.9</s></span>7.9<span class="mount">/mois</span>`,
    AdannuallyText:
      '/mois <br /> Facturé à $190.8 annuellement <br/> Vous économiser $48/an ',
    AdannuallyText_3:'Billed at $190.8 annually <br/> 2 months OFF !',
    PrannuallyText:
      '/mois <br /> Facturé à $478.8 annuellement <br/> Vous économiser $120/an ',
    PrannuallyText_3:' Billed at $478.8 annually <br /> 2 months OFF !',
    StannuallyText: '/mois <br /> Facturé à $23.88 annuellement  ',
    EnannuallyText:'Billed anually at $4,788 <br /> 2 months OFF !',
    NewStannuallyText:'Billed anually at $94.9 <br /> 2 months OFF !',
    Free: 'Gratuit',
    Monthlyfee: 'Prix mensuel',

    Annualfee: 'Frais annuel',
    Feature: 'Fonctions',
    enterprise_current_plan: 'Votre souscription actuelle est: {plan}',
    EnDescribe:'Contact us to get more information',
    // circulationData: [
    //   {
    //     Feature: 'Plateforme supportée',
    //     explain: 'DSers supporte Shopify et WooCommerce',
    //     Basic: 'Shopify ou WooCommerce uniquement par compte DSers',
    //     BasicText: '',
    //     Standard: 'Shopify ou WooCommerce uniquement par compte DSers',
    //     StandardText: '',
    //     AdvancedL: 'Shopify ou WooCommerce uniquement par compte DSers',
    //     AdvancedText: '',
    //     Pro: 'Shopify et WooCommerce dans le même compte DSers',
    //     ProText: ''
    //   },
    //   {
    //     Feature: 'Limites de boutique',
    //     explain:
    //       'Avec DSers vous pouvez gérer plusieurs boutiques dans un compte DSers',
    //     Basic: '3',
    //     BasicText: '',
    //     Standard: '5',
    //     StandardText: '',
    //     AdvancedL: '10',
    //     AdvancedText: '',
    //     Pro: '25',
    //     ProText: ''
    //   },
    //   {
    //     Feature: 'Nombre de Produits',
    //     explain: '',
    //     Basic: '3000',
    //     BasicText: '(3000 produits maximum par compte)',
    //     Standard: '7500',
    //     StandardText: '(7500 produits maximum par compte)',
    //     AdvancedL: '20000',
    //     AdvancedText: '(20000 produits maximum par compte)',
    //     Pro: '75000',
    //     ProText: '(75000 produits maximum par compte)'
    //   },
    //   {
    //     Feature: 'Nombre de Commandes',
    //     explain: '',
    //     Basic: 'Illimité',
    //     BasicText: '',
    //     Standard: 'Illimité',
    //     StandardText: '',
    //     AdvancedL: 'Illimité',
    //     AdvancedText: '',
    //     Pro: 'Illimité',
    //     ProText: ''
    //   },
    //   {
    //     Feature: 'Optimiseur de Fournisseur',
    //     explain:
    //       'Trouvez le meilleur fournisseur pour des produits spécifiques',
    //     Basic: 'ok',
    //     BasicText: '',
    //     Standard: 'ok',
    //     StandardText: '',
    //     AdvancedL: 'ok',
    //     AdvancedText: '',
    //     Pro: 'ok',
    //     ProText: '',
    //     tag: 'new'
    //   },
    //   {
    //     Feature: 'Synchronisation des numéros de suivi vers compte PayPal',
    //     explain: '',
    //     Basic: 'ok',
    //     BasicText: '',
    //     Standard: 'ok',
    //     StandardText: '',
    //     AdvancedL: 'ok',
    //     AdvancedText: '',
    //     Pro: 'ok',
    //     ProText: '',
    //     type: 'sync_tk_to_paypal'
    //   },
    //   {
    //     Feature: 'Extension Chrome DSers',
    //     explain: 'Ajoutez des produits AliExpress à DSers en un clic',
    //     Basic: 'ok',
    //     BasicText: '',
    //     Standard: 'ok',
    //     StandardText: '',
    //     AdvancedL: 'ok',
    //     AdvancedText: '',
    //     Pro: 'ok',
    //     ProText: ''
    //   },
    //   {
    //     Feature: 'Multi-langue',
    //     explain: 'Français, Anglais et Portugais sont disponibles',
    //     Basic: 'ok',
    //     BasicText: '',
    //     Standard: 'ok',
    //     StandardText: '',
    //     AdvancedL: 'ok',
    //     AdvancedText: '',
    //     Pro: 'ok',
    //     ProText: ''
    //   },
    //   {
    //     Feature: "Liste d'import",
    //     explain: 'Gérez les produits que vous importez dans DSers',
    //     Basic: 'ok',
    //     BasicText: '',
    //     Standard: 'ok',
    //     StandardText: '',
    //     AdvancedL: 'ok',
    //     AdvancedText: '',
    //     Pro: 'ok',
    //     ProText: ''
    //   },
    //   {
    //     Feature: 'Tarification automatisé',
    //     explain: "Décidez d'augmentations de prix fixes ou en pourcentage",
    //     Basic: 'ok',
    //     BasicText: '',
    //     Standard: 'ok',
    //     StandardText: '',
    //     AdvancedL: 'ok',
    //     AdvancedText: '',
    //     Pro: 'ok',
    //     ProText: ''
    //   },
    //   {
    //     Feature: "Notification d'inventaire",
    //     explain:
    //       'Soyez notifié des changements de stock de vos produits sur AliExpress',
    //     Basic: 'ok',
    //     BasicText: '',
    //     Standard: 'ok',
    //     StandardText: '',
    //     AdvancedL: 'ok',
    //     AdvancedText: '',
    //     Pro: 'ok',
    //     ProText: ''
    //   },
    //   {
    //     Feature: 'Commandes groupées',
    //     explain: 'Placez 100+ commandes groupées',
    //     Basic: 'ok',
    //     BasicText: '',
    //     Standard: 'ok',
    //     StandardText: '',
    //     AdvancedL: 'ok',
    //     AdvancedText: '',
    //     Pro: 'ok',
    //     ProText: ''
    //   },
    //   {
    //     Feature: 'Pré-sélection de méthode de livraison',
    //     explain: 'Définissez votre méthode de livraison favorite',
    //     Basic: 'ok',
    //     BasicText: '',
    //     Standard: 'ok',
    //     StandardText: '',
    //     AdvancedL: 'ok',
    //     AdvancedText: '',
    //     Pro: 'ok',
    //     ProText: ''
    //   },
    //   {
    //     Feature: 'Synchro automatique des commandes',
    //     explain: 'Synchronisation automatique du statut des commandes',
    //     Basic: 'ok',
    //     BasicText: '',
    //     Standard: 'ok',
    //     StandardText: '',
    //     AdvancedL: 'ok',
    //     AdvancedText: '',
    //     Pro: 'ok',
    //     ProText: ''
    //   },
    //   {
    //     Feature: 'Synchro automatique des numéros de suivi',
    //     explain:
    //       'Synchronisation automatique du numéro de suivi depuis AliExpress vers Shopify',
    //     Basic: 'ok',
    //     BasicText: '',
    //     Standard: 'ok',
    //     StandardText: '',
    //     AdvancedL: 'ok',
    //     AdvancedText: '',
    //     Pro: 'ok',
    //     ProText: ''
    //   },
    //   {
    //     Feature: 'Division de produit',
    //     explain: 'Divisez un produit en plusieurs produits différents',
    //     Basic: 'ok',
    //     BasicText: '',
    //     Standard: 'ok',
    //     StandardText: '',
    //     AdvancedL: 'ok',
    //     AdvancedText: '',
    //     Pro: 'ok',
    //     ProText: ''
    //   },
    //   {
    //     Feature: 'Cacher des produits',
    //     explain: 'Cacher les produits que vous ne voulez pas gérer avec DSers',
    //     Basic: 'ok',
    //     BasicText: '',
    //     Standard: 'ok',
    //     StandardText: '',
    //     AdvancedL: 'ok',
    //     AdvancedText: '',
    //     Pro: 'ok',
    //     ProText: ''
    //   },
    //   {
    //     Feature: 'Mapping Basique',
    //     explain: 'Mapper vos produits avec 2 fournisseurs maximum',
    //     Basic: 'ok',
    //     BasicText: '',
    //     Standard: 'ok',
    //     StandardText: '',
    //     AdvancedL: 'ok',
    //     AdvancedText: '',
    //     Pro: 'ok',
    //     ProText: ''
    //   },
    //   {
    //     Feature: "Mise à jour d'inventaire automatique",
    //     explain: '',
    //     Basic: '',
    //     BasicText: '',
    //     Standard: 'ok',
    //     StandardText: '',
    //     AdvancedL: 'ok',
    //     AdvancedText: '',
    //     Pro: 'ok',
    //     ProText: ''
    //   },
    //   {
    //     Feature: "Mise à jour d'inventaire automatique",
    //     explain: '',
    //     Basic: '',
    //     BasicText: '',
    //     Standard: 'ok',
    //     StandardText: '',
    //     AdvancedL: 'ok',
    //     AdvancedText: '',
    //     Pro: 'ok',
    //     ProText: ''
    //   },
    //   {
    //     Feature: 'Mapping Avancé',
    //     explain:
    //       'Mapper les variantes du produit en fonction du pays de destination',
    //     Basic: '',
    //     BasicText: '',
    //     Standard: '',
    //     StandardText: '',
    //     AdvancedL: 'ok',
    //     AdvancedText: '',
    //     Pro: 'ok',
    //     ProText: ''
    //   },
    //   {
    //     Feature: 'Advanced Price Rule',
    //     explain:
    //       'Peut calculer les prix des produits basés sur les marges de profit, coût de livraison, etc.',
    //     Basic: '',
    //     BasicText: '',
    //     Standard: '',
    //     StandardText: '',
    //     AdvancedL: 'ok',
    //     AdvancedText: '',
    //     Pro: 'ok',
    //     ProText: '',
    //     tag: 'new'
    //   },
    //   {
    //     Feature: 'Mapping BOGO/Bundle',
    //     explain: 'Créez des offres pour vos produits',
    //     Basic: '',
    //     BasicText: '',
    //     Standard: '',
    //     StandardText: '',
    //     AdvancedL: 'ok',
    //     AdvancedText: '',
    //     Pro: 'ok',
    //     ProText: ''
    //   },
    //   {
    //     Feature: 'Affilié',
    //     explain: 'Intégrez avec Admitad pour obetnir des commissions',
    //     Basic: '',
    //     Standard: '',
    //     StandardText: '',
    //     BasicText: '',
    //     AdvancedL: 'ok',
    //     AdvancedText: '',
    //     Pro: 'ok',
    //     ProText: ''
    //   }
    // ],
    circulationData: [
      {
        Feature: 'Service client',
        explain: 'Chat en direct 24h/24 et 7j/7 & emails',
        Basic: 'Disponible',
        BasicText: '',
        Standard: 'Disponible',
        StandardText: '',
        AdvancedL: '<span class="blone and">Disponible & Prioritaire</span>',
        AdvancedText: '',
        Pro: '<span class="blone and">Disponible & Prioritaire</span>',
        ProText: '',
        Enterprise:'<span class="blone and">Disponible & Prioritaire</span>',
        EnterpriseText:'',
        NewStandard:'Disponible',
        NewStandardText:'',
        CustomBasic:'Disponible'
      },
      {
        Feature: 'Traiter les commandes en groupe',
        explain: 'Traiter plusieurs commandes à la fois vers AliExpress',
        Basic: 'Disponible',
        BasicText: '',
        Standard: 'Disponible',
        StandardText: '',
        AdvancedL: '<span class="blone and">Disponible et rapide pour traiter plus de 100 commandes</span>',
        AdvancedText: '',
        Pro: '<span class="blone and">Disponible et rapide pour traiter plus de 100 commandes</span>',
        ProText: '',
        Enterprise:'<span class="blone and">Disponible et rapide pour traiter plus de 100 commandes</span>',
        EnterpriseText:'',
        NewStandard:'Disponible',
        NewStandardText:'',
        CustomBasic:'Disponible'
      },
      {
        Feature: 'Nombre de boutique',
        explain:
          'Gérez plusieurs boutiques avec un seul compte DSers',
        Basic: `<span class="blone">3</span>`,
        BasicText: '',
        Standard: `<span class="blone">5</span>`,
        StandardText: '',
        AdvancedL: `<span class="blone and">10</span>`,
        AdvancedText: '(Populaire pour les marchands vendant dans différents pays)',
        Pro: `<span class="blone and">25</span>`,
        ProText: '(Populaire pour les marchands vendant dans différentes niches & pays)',
        Enterprise:'<span class="blone and">50</span>',
        EnterpriseText:'',
        NewStandard:'<span class="blone">3</span>',
        NewStandardText:'',
        CustomBasic:'<span class="blone">3</span>'
      },
      {
        Feature: 'Nombre de produit',
        explain: '',
        Basic: `<span class="blone">3000 </span> /account`,
        BasicText: '',
        Standard: `<span class="blone">7500</span>  /account`,
        StandardText: '',
        AdvancedL: `<span class="blone and ">20000 </span> /account`,
        AdvancedText: '',
        Pro: `<span class="blone and ">75000 </span> /account`,
        ProText: '',
        Enterprise:'<span class="blone and ">100,000 </span> /account',
        EnterpriseText:'',
        NewStandard:'<span class="blone">3000 </span> /account',
        NewStandardText:'',
        CustomBasic:'<span class="blone">500 </span> /account'
      },
      {
        Feature: 'Adaptateur AliExpress Saver Shipping',
        explain: `Profitez de la méthode d'envoi la plus rentable sur AliExpress`,
        Basic: `<span class='line2'>—</span>`,
        BasicText: '',
        Standard: `<span class='line2'>—</span>`,
        StandardText: '',
        AdvancedL: `<span class="blone and ">Jusqu'à 10 produits</span>`,
        AdvancedText: '',
        Pro: `<span class="blone and ">Jusqu'à 20 produits</span>`,
        ProText: '',
        Enterprise:'<span class="blone and ">Produits illimités</span> ',
        EnterpriseText:'',
        NewStandard:`<span class='line2'>—</span>`,
        NewStandardText:'',
        CustomBasic:`<span class='line2'>—</span>`
      },
      {
        Feature: 'Change online products price',
        explain: '',
        Basic: `<span style="color:#FF8F00;font-weight:600">1time/30days</span>`,
        BasicText: '',
        Standard: `<span style="color:#FF8F00;font-weight:600">1time/30days</span>`,
        StandardText: '',
        AdvancedL: `<span style="color:#FF8F00;font-weight:600">4times/30days</span>`,
        AdvancedText: '',
        Pro: `<span style="color:#FF8F00;font-weight:600">(10 fois/30 jours)</span>`,
        ProText: '',
        Enterprise:'<span style="color:#FF8F00;font-weight:600">(10 fois/30 jours)</span>',
        EnterpriseText:'',
        NewStandard:`<span style="color:#FF8F00;font-weight:600">1time/30days</span>`,
        NewStandardText:'',
        CustomBasic:`<span style="color:#FF8F00;font-weight:600">1time/30days</span>`
      },
      {
        Feature: `Gestion de l'inventaire`,
        explain: '',
        Basic: `Notification uniquement`,
        BasicText: '',
        Standard: `<span style="color:#FF8F00;font-weight:600">Mise à jour automatique</span>`,
        StandardText: '',
        AdvancedL: `<span style="color:#FF8F00;font-weight:600">Mise à jour automatique</span>`,
        AdvancedText: '',
        Pro: `<span style="color:#FF8F00;font-weight:600">Mise à jour automatique</span>`,
        ProText: '',
        Enterprise:'<span style="color:#FF8F00;font-weight:600">Mise à jour automatique</span>',
        EnterpriseText:'',
        NewStandard:`<span style="color:#FF8F00;font-weight:600">Mise à jour automatique</span>`,
        NewStandardText:'',
        CustomBasic:`<span style="color:#FF8F00;font-weight:600">Mise à jour automatique</span>`
      },
      {
        Feature: 'Gestion des changements de coûts',
        explain: '',
        Basic: `Notification uniquement`,
        BasicText: '',
        Standard: `Notification uniquement`,
        StandardText: '',
        AdvancedL: `<span style="color:#FF8F00;font-weight:600">Mise à jour automatique</span>`,
        AdvancedText: '',
        Pro: `<span style="color:#FF8F00;font-weight:600">Mise à jour automatique</span>`,
        ProText: '',
        Enterprise:'<span style="color:#FF8F00;font-weight:600">Mise à jour automatique</span>',
        EnterpriseText:'',
        NewStandard:`Notification uniquement`,
        NewStandardText:'',
        CustomBasic:`<span style="color:#FF8F00;font-weight:600">Mise à jour automatique</span>`
      },
      {
        Feature: 'Règle de Prix Automatisée',
        explain: '',
        Basic: `Augmentation fixe ou en pourcentage uniquement`,
        BasicText: '',
        Standard: `Augmentation fixe ou en pourcentage uniquement`,
        StandardText: '',
        AdvancedL: `<span style="color:#FF8F00;font-weight:600">Règles basées sur les marges, les frais d'expédition, etc.</span>`,
        AdvancedText: '',
        Pro: `<span style="color:#FF8F00;font-weight:600">Règles basées sur les marges, les frais d'expédition, etc.</span>`,
        ProText: '',
        Enterprise:`<span style="color:#FF8F00;font-weight:600">Règles basées sur les marges, les frais d'expédition, etc.</span>`,
        EnterpriseText:'',
        NewStandard:'Augmentation fixe ou en pourcentage uniquement',
        NewStandardText:'',
        CustomBasic:`Augmentation fixe ou en pourcentage uniquement`
      },
      {
        Feature: 'Mapping des variantes',
        explain: 'Gérez autant de fournisseurs que vous le souhaitez sans rien changer sur votre boutique',
        Basic: `Jusqu'à 2 fournisseurs par produit sans mapping vers région ciblée.`,
        BasicText: '',
        Standard: `Jusqu'à 2 fournisseurs par produit sans mapping vers région ciblée.`,
        StandardText: '',
        AdvancedL: `<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Cartographiez chaque variante en fonction de la région ciblée</span>`,
        AdvancedText: '',
        Pro: `<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Cartographiez chaque variante en fonction de la région ciblée</span>`,
        ProText: '',
        Enterprise:'<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Cartographiez chaque variante en fonction de la région ciblée</span>',
        EnterpriseText:'',
        NewStandard:`Jusqu'à 2 fournisseurs par produit sans mapping vers région ciblée.`,
        NewStandardText:'',
        CustomBasic:`Jusqu'à 2 fournisseurs par produit sans mapping vers région ciblée.`
      },
      {
        Feature: 'Mapping BOGO/Bundle',
        explain: `Créez des offres pour vos produits pour plus de ventes`,
        Basic: "<span class='line2'>—</span>",
        BasicText: '',
        Standard: "<span class='line2'>—</span>",
        StandardText: '',
        AdvancedL: 'ok',
        AdvancedText: '',
        Pro: 'ok',
        ProText: '',
        Enterprise:'ok',
        EnterpriseText:'',
        NewStandard:"<span class='line2'>—</span>",
        NewStandardText:'',
        CustomBasic:"<span class='line2'>—</span>"
      },
      {
        Feature: 'Suivi de la commande',
        explain: ``,
        Basic: "<span class='line2'>—</span>",
        BasicText: '',
        Standard: "<span class='line2'>—</span>",
        StandardText: '',
        AdvancedL: 'ok',
        AdvancedText: '',
        Pro: 'ok',
        ProText: '',
        Enterprise:'ok',
        EnterpriseText:'',
        NewStandard:"<span class='line2'>—</span>",
        NewStandardText:'',
        CustomBasic:"<span class='line2'>—</span>"
      },
      {
        Feature: 'Compte Employé',
        explain: ``,
        Basic: "<span class='line2'>—</span>",
        BasicText: '',
        Standard: "<span class='line2'>—</span>",
        StandardText: '',
        AdvancedL: '<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">5</span>',
        AdvancedText: '',
        Pro: '<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Unlimited</span>',
        ProText: '',
        Enterprise:'<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Unlimited</span>',
        EnterpriseText:'',
        NewStandard:"<span class='line2'>—</span>",
        NewStandardText:'',
        CustomBasic:"<span class='line2'>—</span>"
      },
      {
        Feature: 'Affilié',
        explain: '',
        Basic: "<span class='line2'>—</span>",
        BasicText: '',
        Standard: "<span class='line2'>—</span>",
        StandardText: '',
        AdvancedL: 'ok',
        AdvancedText: '',
        Pro: 'ok',
        ProText: '',
        Enterprise:'ok',
        EnterpriseText:'',
        NewStandard:"<span class='line2'>—</span>",
        NewStandardText:'',
        CustomBasic:"<span class='line2'>—</span>"
      },
      {
        Feature:'Customisation',
        explain:'Personnalisez les fonctions DSers et augmentez les  volumes disponibles',
        Basic: "<span class='line2'>—</span>",
        BasicText: '',
        Standard: "<span class='line2'>—</span>",
        StandardText: '',
        AdvancedL: "<span class='line2'>—</span>",
        AdvancedText: '',
        Pro: "<span class='line2'>—</span>",
        ProText: '',
        Enterprise:`<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Des frais supplémentaires peuvent s'appliquer en fonction d'une demande spécifique ou du volume d'utilisation.</span>`,
        EnterpriseText:'',
        NewStandard:"<span class='line2'>—</span>",
        NewStandardText:'',
        CustomBasic:"<span class='line2'>—</span>"
      },
      {
        Feature: 'Envoie le coût du produit à Shopify',
        explain: '',
        Basic: `<span class='line2'>—</span>`,
        BasicText: '',
        Standard: `<span style="color:#FF8F00;font-weight:600">Lorsque le produit est envoyé, le coût des produits du fournisseur et les frais de livraison dans [Règle de prix avancée] sont renvoyés ensemble au coût par article de Shopify.</span>`,
        StandardText: '',
        AdvancedL: `<span style="color:#FF8F00;font-weight:600">Lorsque le produit est envoyé, le coût des produits du fournisseur et les frais de livraison dans [Règle de prix avancée] sont renvoyés ensemble au coût par article de Shopify.</span>`,
        AdvancedText: '',
        Pro: `<span style="color:#FF8F00;font-weight:600">Lorsque le produit est envoyé, le coût des produits du fournisseur et les frais de livraison dans [Règle de prix avancée] sont renvoyés ensemble au coût par article de Shopify.</span>`,
        ProText: '',
        Enterprise:`<span style="color:#FF8F00;font-weight:600">Lorsque le produit est envoyé, le coût des produits du fournisseur et les frais de livraison dans [Règle de prix avancée] sont renvoyés ensemble au coût par article de Shopify.</span>`,
        EnterpriseText:'',
        NewStandard:'Fixed or percentage increase only',
        NewStandardText:'',
        CustomBasic:`<span class='line2'>—</span>`
      },
      {
        Feature: 'Synchronize Shopify order customization information',
        explain: ``,
        Basic: "<span class='line2'>—</span>",
        BasicText: '',
        Standard: "<span class='line2'>—</span>",
        StandardText: '',
        AdvancedL: `<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Send the customization information from Shopify to suppliers along with the order.</span>`,
        AdvancedText: '',
        Pro: `<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Send the customization information from Shopify to suppliers along with the order.</span>`,
        ProText: '',
        Enterprise:`<span style="color:#FF8F00;font-weight:600;padding-left:10px;padding-right:10px">Send the customization information from Shopify to suppliers along with the order.</span>`,
        EnterpriseText:'',
        NewStandard:"<span class='line2'>—</span>",
        NewStandardText:'',
        CustomBasic:"<span class='line2'>—</span>"
      },
    ],
    pricingIncludesTitle:'FONCTIONS INCLUSES DANS TOUS LES PLANS',
    pricingIncludesData:[
      {
        "title":"Présélection de la méthode d'envoi",
        "desc":"Configure métodos de envio automáticos para pedidos no AliExpress com base em países, produtos ou condições."
      },
      {
        "title":"Correction d'adresse automatisée",
        "desc":"Résolution automatique des problèmes d'adresse pour réduire le travail manuel"
      },
      {
        "title":"Mises à jour automatisées du statut des commandes",
        "desc":"Tout est automatisé. Plus de synchronisation manuelle via l'extension Chrome"
      },
      {
        "title":"Synchronisez automatiquement les numéros de suivi avec Shopify.",
        "desc":"Synchronisez automatiquement les numéros de suivi depuis AliExpress et mettez-les à jour dans Shopify."
      },
      {
        "title":"Synchronisez automatiquement les numéros de suivi avec PayPal",
        "desc":"Améliorez votre réputation en envoyant les numéros de suivi via votre compte PayPal"
      },
      {
        "title":"Extension DSers Chrome",
        "desc":"Importateur de produits AliExpress et traitement des commandes groupées avec coupons reçus"
      },
      {
        "title":"Message automatisé aux fournisseurs",
        "desc":"Envoi automatique de messages pour vos fournisseurs selon vos besoins"
      },
      {
        "title":"Envoi des notes de personnalisation des clients aux fournisseurs",
        "desc":"Particulièrement populaire auprès des marchands exécutant des activités POD"
      },
      {
        "title":"Configurer le suivi dans l'e-mail de confirmation d'expédition",
        "desc":"Personnalisez les informations de suivi dans votre e-mail Shopify"
      },
      {
        "title":"Trouver des fournisseurs tendances ",
        "desc":"Liste de fournisseurs tendances et qualifiés sur AliExpress"
      },
      {
        "title":"Optimiseur de fournisseur",
        "desc":"Trouvez le meilleur fournisseur pour des produits spécifiques en un clic"
      },
      {
        "title":"Liste d'Import",
        "desc":"Gérez les produits que vous importez vers DSers et migrez facilement les produits entre vos magasins "
      },
      {
        "title":"Division de Produit",
        "desc":"Divisez un produit en plusieurs produits différents pour personnaliser vos listes de produits"
      },
      {
        "title":"Publier les produits sur votre boutique en groupe",
        "desc":"Publiez les produits AliExpress sélectionnés dans votre boutique après édition"
      },
      {
        "title":"Gestion de fuseau horaire et de devise",
        "desc":"Configurer le fuseau horaire et les devises pour votre boutique"
      }
    ],
    Unfinishedfront: `Vous n'avez pas terminé l'autorisation sur Shopify.<br />
    Veuillez cliquer`,
    Unfinishedcentre: 'Souscrire',
    Unfinishedqueen: 'pour continuer',
    Unfinished:
      'Autorisation sur Shopify non-terminée, veuillez clique sur Souscrire pour mettre à jour.',
    AlertText:
      'Veuillez changer de plan pour plus de fonction et possibilités. Apprenez-en plus sur les <a href="https://help.dsers.com/subscription-plans-presentation-for-shopify/" target="_blank" >plans DSers</a> .',

    pop_Up:
      'Veuillez sélectionner la boutique vous utiliserez pour payer pour DSers.',
    display: 'Recommandé',
    HomeBasicButton: 'Commencer',
    HomeAdvancedButton: 'Débuter essai gratuit',
    HomeProButton: 'Débuter essai gratuit',
    HomeStandardButton:"Débuter essai gratuit",
    Select: 'Choisir',
    Current: 'Plan actuel',
    Confirm: 'Confirmer',
    ConfirmCancel: 'Retour',
    Access: 'Accéder',
    AccessText:
      'Merci pour votre souscription. Veuillez accéder à votre compte DSers, profitez-en!',
    Monthly: 'MENSUEL',
    Annually: 'ANNUELLEMENT',
    working: "Merci d'avoir choisi DSers.",
    like: 'Vous avez aimer?',
    over: 'Votre essai gratuit de 14 jours est terminé.',
    confirm:
      'Veuillez cliquer le bouton ci-dessous pour confirmer la souscription annuelle sur Shopify.',
    Contnct: 'Contact',
    Subscribe: 'Souscrire',
    ChangePlan: 'Changer plan',
    selectShop:
      'Veuillez choisir ce que vous souhaitez garder dans le plan DSers actuel.',
    keepShop:
      'Veuillez choisir {quantity} boutiques maximum que vous souhaitez garder.',
    PlatformAndStores: 'Plateforme et boutiques',
    ShopifyPlatform: 'Plateforme Shopify',
    Woocommerceplatform: 'Plateforme WooCommerce',
    errText:
      "Veuillez choisir une boutique pour réaliser le paiement. Si vous n'avez pas une boutique disponible, veuillez vérifier le statu de la boutique sur Shopify.",
    errTextTwo:
      "The default billing store is unavailble. Please check the store's status on Shopify or change the default billing store on DSers.",
    billing: 'Boutique de facturation par défaut',
    help:
      'La Boutique de Facturation est la boutique utilisée pour payer votre souscription à DSers. Pour une meilleure expérience, veuillez éviter de changer de fréquemment de Boutique de Facturation',
    StoreManagement: 'Gestion des boutiques',
    StoreManagementText:
      "Si vous décidez de prendre un plan inférieur, DSers gardera vos boutiques désactivées ici afin que vous puissiez déconnecter les boutiques non voulues et activer d'autres boutiques en fonction de votre plan. Une fois déconnecté, vous ne pourrez pas ajouter plus de boutique que ne le permet votre plan.",
    ActiveStore: 'Boutique active',
    InactiveStore: 'Boutique inactive',
    Activate: 'Activer',
    Inactivated: 'Désactivé',
    modification:
      'Notre systême a détecté que vous avez changer votre boutique de facturation par défaut à <span style="color:#00BFFF" >{new}</span>, la souscription via la boutique <span style="color:#00BFFF">{old}</span> est maintenant expirée. Veuillez cliquer le bouton ci-dessous pour confirmer la boutique de facturation par défaut sur Shopify.',
    go_to_shopify: 'Aller sur Shopify',
    if_free_dec: `Si vous utiliser l'essai gratuit de Shopify ou une boutique de dévelopeur Shopify,
                  <br /> vous ne verrez pas la page ci-dessus, mais la page ci-dessous.
                  <br /> Si vous ne savez pas comment annuler la souscription actuelle,
                  <br /> rendez-vous dans Shopify et désinstaller puis réinstaller l'application DSers et sélectionner un plan`,
    change_annual_plan_notion:
      'Si vous voulez changer votre plan annuel actuel, veuillez contacter notre service client via <a href="https://www.messenger.com/t/DSersOfficial" dd="src" target="_blank" rel="noopener noreferrer">Facebook</a> ou <a href="mailto:support@dserspro.com">email</a> pour le remboursement du coût restant votre plan actuel',
    subscribe_tios: `La boutique de facturation par défaut est fermée ou désactivé. Rendez-vous dans
    <a href="/app/select"> Gérer souscription </a>
   pour changer et veuillez réessayer`,
    subscription_expired: `Votre souscription est terminée, veuillez la renouveller ou la changer pour continuer.`,
    subscription_network_tip:
    'If you encounter some network error or any other problems during the payment process, and the subscription is unsuccessful,please <a style="color:#00BFFF" href={jumpUrl}>click here</a> to retry. You can also contact us and we will deal with it for you as soon as possible.',
    subscription_network_tip_2:
      'Si vous rencontrez un problème de connexion ou tout autre problème pendant le paiement, et que votre souscription échoue, veuillez nous contacter et nous résoudrons le problème pour vous aussi vite que possible.',
    subscription_btn3:`Premier mois`,
    subscription_btn4:`GRATUIT!`,
    subscription_btn5:`aujourd'hui uniquement!`,
    subscription_btn6:`Passez au plan <span class="titlesd">Standard</span> maintenant et vous pourrez: <br/> Ajouter <span class="titlesd">2</span> boutiques en plus <br/> Avoir <span class="titlesd">4500</span> produits en plus <br/> Profiter de l'inventaire des produits synchronisé <br/> automatique.`,
    subscription_btn7:`CHANGEZ MAINTENANT`,
    subscription_btn8:`Garder le plan Basique`,
    subscription_btn9:`Passez au plan de paiement annuel`,
    subscription_btn10:`$120 EN MOINS!`,
    subscription_btn11:`Plan Mensuel`,
    subscription_btn12:`<span class="blon">49.9</span>/m`,
    subscription_btn13:`Plan Annuel`,
    subscription_btn14:`<span class="blon">478.8</span>/an`,
    subscription_btn15:`Coût mensuel: <span class="blon_d">$39.9</span>`,
    subscription_btn16:`14 jours essai gratuit`,
    subscription_btn17:`CHOISIR`,
    select_account:'Veuillez sélectionner les',
    select_account_2:'comptes employés que vous souhaitez conserver',
    custom_pro:'Custom Pro',
    Custom_EnterPrise:'Custom EnterPrise',
    //oberlo
    custom_basic:'Custom Basic',
    custom_to_basic:'Changer pour Plan Basic',
    tobasictext:'CONFIRM SWITCH',
    oCancel:'CANCEL',
    tobaiscnote:'Note',
    tobaiscdes:'Après être passé au plan Basic, vous aurez une limite de 3000 produits, mais vous ne profiterez plus de la synchronisation automatique des stocks et des prix, et vous ne pourrez plus revenir au plan Personnalisé actuel. Êtes-vous sûr de vouloir changer ?',
    "BasicTracking":"basic + tracking",
    "Basic500":"Basic+500",
    "Basic1000":"Basic+1000",
    "CustomBasicTracking":"Custom Basic+Tracking",
    "CustomBasic500":"Custom Basic+500",
    "CustomBasic1000":"Custom Basic+1000",
  }
};
