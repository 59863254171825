import React from 'react';

import intl from 'react-intl-universal';

import { Tooltip } from 'antd';

import styles from './index.less';

function RefreshDataPrompt({
  title = intl.get('setting.agency.mappingUpdating'),
  subTitle = intl.get('setting.agency.backendProcessesPrompt'),
  placement,
  visible,
  back = true,
  width = 600,
  id,
  children,
  ...restProps
}) {
  function text() {
    return (
      <>
        <div className={styles.title}>{title}</div>
        {subTitle ? <div className={styles.subTitle}>{subTitle}</div> : null}
      </>
    );
  }

  return (
    <>
      <Tooltip
        placement={placement}
        visible={visible}
        getPopupContainer={() => document.getElementById(id)}
        overlayClassName={styles.content}
        title={text()}
        overlayStyle={{ maxWidth: width }}
        {...restProps}
      >
        {children}
      </Tooltip>
      {back && visible ? <div className={styles.back} /> : null}
    </>
  );
}
export default RefreshDataPrompt;
