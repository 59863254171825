export default {
    "order": {
        "order_language": {
            "pay": "Bitte bezahlen Sie Ihre Bestellungen innerhalb von 24 Stunden, sonst werden sie von Tmall storniert."
        },
        "tab_tip": {
            "pending": "Ausstehende Bestellungen sind Bestellungen, für die die Zahlung von Ihren Kunden noch nicht in Shopify erfasst wurde. Sie können ausstehende Bestellungen auf DSers nicht bearbeiten, solange die Zahlung nicht erfasst wurde.Wenn eine Bestellung immer noch ausstehend ist, nachdem Sie die Zahlung erfasst haben, überprüfen Sie bitte<a\n\t\t\thref='https://help.dsers.com/pending-orders-introduction-for-shopify/'\n\t\t\ttarget=\"_blank\"\n\t\t\tdata-ga=\"Pending - here\"\n\t\t> hier</a>, um den Bestellstatus manuell zu aktualisieren. Bitte kontaktieren Sie uns, wenn dies immer wieder vorkommt.",
            "awaiting_order": "Bestellungen in Wartende Bestellung sind Bestellungen, für die die Zahlung Ihrer Kunden auf Shopify erfasst wurde und die darauf warten, an AliExpress weitergeleitet zu werden. Wenn Sie Ihre neuen Bestellungen hier nicht finden können, überprüfen Sie bitte die Registerkarten Ausstehend und Erfüllt. Wie Sie Bestellungen an AliExpress verarbeiten können, erfahren <a\n\t\t\thref='https://help.dsers.com/fulfill-multiple-orders-from-start-to-finish-for-shopify/'\n\t\t\ttarget=\"_blank\"\n\t\t\tdata-ga=\"Awaiting order - here\" >Sie hier</a>.",
            "awaiting_payment": "Bestellungen in Warten auf Zahlung sind Bestellungen, die bei AliExpress aufgegeben, aber noch nicht bezahlt wurden. Die AliExpress-Bestellnummer wird sofort generiert, wenn Sie die Bestellung aufgeben. Klicken Sie <a\n\t\t\thref='https://help.dsers.com/pay-multiple-orders-on-aliexpress-with-dsers-for-shopify/'\n\t\t\ttarget=\"_blank\"\n\t\t\tdata-ga=\"Awaiting payment - here1\"\n\t\t\t>hier</a>, um herauszufinden, wie Sie die Zahlung in großen Mengen vornehmen können. Sie können auch<a\n\t\t\thref='https://help.dsers.com/re-order-awaiting-payment-orders-for-shopify/'\n\t\t\ttarget=\"_blank\"\n\t\t\tdata-ga=\"Awaiting payment - here2\"\n\t\t> hier</a> nachlesen, wie Sie Ihre Bestellung stornieren und neu bestellen können. Wenn Sie feststellen, dass sich der Bestellstatus nach der Zahlung nicht ändert, überprüfen Sie bitte<a\n\t\t\thref='https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/'\n\t\t\ttarget=\"_blank\"\n\t\t\tdata-ga=\"Awaiting payment - here3\"\n\t\t> hier</a>.",
            "awaiting_shipment": "Bestellungen in Warten auf Versand wurden auf AliExpress bezahlt, aber noch nicht versandt. Wenn Sie feststellen, dass die Tracking-Nummer nach dem Versand nicht mehr synchronisiert wird, überprüfen Sie bitte<a\n\t\t\thref='https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/'\n\t\t\ttarget=\"_blank\"\n\t\t\tdata-ga=\"Awaiting shipment - here\"\n\t\t> hier</a>. Bitte fordern Sie <b>NICHT</b> die Erfüllung auf Shopify an und erfüllen Sie Bestellungen <b>NICHT</b> manuell auf Shopify oder DSers.",
            "fulfilled": "Bestellungen in Erfüllt sind Bestellungen, die von Ihrem AliExpress-Lieferanten versandt wurden und Bestellungen, die automatisch oder manuell erfüllt wurden. Sobald eine Bestellung erfüllt ist, werden wir den Bestellstatus oder den Produktpreis nicht mehr mit AliExpress synchronisieren. Klicken Sie <a\n\t\t\thref='https://help.dsers.com/re-order-fulfilled-orders-for-shopify/'\n\t\t\ttarget=\"_blank\"\n\t\t\tdata-ga=\"Fulfilled - here1\"\n\t\t\t>hier</a>, um zu erfahren, wie Sie mit Bestellungen umgehen, die versehentlich erfüllt wurden. Sie können auch<a\n\t\t\thref='https://help.dsers.com/fulfill-multiple-orders-from-start-to-finish-for-shopify/'\n\t\t\ttarget=\"_blank\"\n\t\t\tdata-ga=\"Fulfilled - here2\"\n\t\t> hier</a> nachlesen, wie DSers Bestellungen automatisch ausführt. Wenn Sie die letzte Tracking-Nummer von AliExpress-Bestellungen synchronisieren möchten, können Sie auf die Schaltfläche Tracking-Nummer der letzten 30 Tage synchronisieren klicken. Wir synchronisieren die letzte Tracking-Nummer der AliExpress-Bestellungen der letzten 30 Tage.",
            "canceled": "Bestellungen unter Storniert sind Bestellungen, die auf AliExpress oder Shopify (teilweise) storniert / (teilweise) erstattet werden. Sie können die stornierten Bestellungen bei AliExpress in großen Mengen bestellen, aber Sie können die stornierten oder erstatteten Bestellungen bei Shopify nicht bestellen. Wenn Sie feststellen, dass der Bestellstatus nach der Stornierung nicht aktualisiert wird, überprüfen Sie bitte<a\n\t\t\thref='https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/'\n\t\t\ttarget=\"_blank\"\n\t\t\tdata-ga=\"Canceled - here1\"\n\t\t> hier</a>. Außerdem erfahren Sie <a\n\t\t\thref='https://help.dsers.com/re-order-aliexpress-canceled-orders-for-shopify/'\n\t\t\ttarget=\"_blank\"\n\t\t\tdata-ga=\"Canceled - here1\"\n\t\t>hier</a>, wie DSers Ihnen bei Bestellungen helfen kann, die von AliExpress storniert werden.",
            "failed_orders": "Bestellungen in Fehlgeschlagene Bestellungen sind Bestellungen, die aufgrund von Fehlern oder Einschränkungen nicht erfolgreich an AliExpress übermittelt werden konnten. Überprüfen Sie die Kundeninformationen und beheben Sie die Fehler, so dass Sie die fehlgeschlagenen Bestellungen erneut aufgeben können. Es gibt einige Bestellungseinschränkungen und Regeln für einige Länder, bitte klicken Sie hier, um weitere Informationen zu erhalten.",
            "awaiting_fulfillment": "Aufträge in In Erwartung der Erfüllung sind nur teilweise erfüllt. Die Bestellungen werden später aktualisiert und auf die Registerkarte Erfüllt verschoben. Dann werden wir die Tracking-Nummer mit Shopify synchronisieren und Shopify veranlassen, nur eine E-Mail an Ihren Kunden zu senden. Klicken Sie auf<a\n\t\t\thref='https://help.dsers.com/awaiting-fulfillment-introduction-for-shopify/'\n\t\t\ttarget=\"_blank\"\n\t\t\tdata-ga=\"Awaiting fulfillment - here\"\n\t\t> hier</a> um weitere Informationen zu erhalten. Bitte fordern Sie <b>NICHT</b> die Erfüllung auf Shopify an und erfüllen Sie Bestellungen <b>NICHT</b> manuell auf Shopify oder DSers.",
            "fulfilledFirstChange": "Orders in \"Fulfilled\" are orders that have been shipped out by your AliExpress supplier and orders that were automatically or manually fulfilled.",
            "fulfilledFirstChange_tit": "Sobald eine Bestellung erfüllt ist, werden wir den Bestellstatus oder den Produktpreis nicht mehr mit AliExpress synchronisieren.Klicken Sie <a target=\"_blank\" href=\"https://help.dsers.com/re-order-fulfilled-orders-for-shopify/\">hier</a> aquí para ver cómo gestionar los pedidos que se completaron por error. <a target=\"_blank\" href=\"https://help.dsers.com/fulfill-multiple-orders-from-start-to-finish-for-shopify/\">hier</a> um zu erfahren, wie Sie mit Bestellungen umgehen, die versehentlich erfüllt wurden.Sie können auch hier nachlesen, wie DSers Bestellungen automatisch ausführt.",
            "fulfilledDays": "Sie können auch hier nachlesen, wie DSers Bestellungen automatisch ausführt.",
            "fulfilledDays_tit": "Wenn Sie die letzte Tracking-Nummer von AliExpress-Bestellungen synchronisieren möchten, können Sie auf die Schaltfläche Tracking-Nummer der letzten 30 Tage synchronisieren klicken. Wir synchronisieren die letzte Tracking-Nummer der AliExpress-Bestellungen der letzten 30 Tage.",
            "canceledChange": "Bestellungen unter \"Storniert\" sind Bestellungen, die auf AliExpress oder Shopify (teilweise) storniert / (teilweise) erstattet werden.",
            "canceledChange_tit": "You can bulk order the Canceled orders from AliExpress, but you cannot order the orders canceled of refunded on Shopify. If you find that the order status is not updating after cancellation, please check <a target=\"_blank\" href=\"https://help.dsers.com/order-status-automatic-syncing-introduction-for-shopify/\">here</a>. And you can also check <a target=\"_blank\" href=\"https://help.dsers.com/re-order-aliexpress-canceled-orders-for-shopify/\">here</a> to learn how DSers can help you with orders that keep getting canceled by AliExpress."
        },
        "alert_fulfilled_by_other": {
            "w1": "Bitte beachten Sie.",
            "w2": "Bitte prüfen Sie, ob bereits Bestellungen auf Plattformen wie Oberlo oder Dropified erfüllt sind."
        },
        "alert_sync_by_extend": {
            "w1": "Bitte beachten Sie: Wir können jetzt den AliExpress-Bestellstatus in",
            "w2": "in Echtzeit",
            "w3": "mit zwei Schritten!",
            "w4": "Klicken Sie hier",
            "w5": "um Details zu sehen."
        },
        "search_type": {
            "order_no": "Bestellnummer",
            "note": "Hinweis",
            "ali_order_no": "Ali Bestellnummer",
            "tm_order_no": "Tmall Order No",
            "product": "Produkt",
            "email": "E-Mail",
            "customer_name": "Name des Kunden",
            "tracking_no": "Verfolgungsnummer",
            "searchOrder": "Suchauftrag",
            agency_order_no: 'Agentur Bestellnummer',
        },
        "search_input_placeholder": "Sie können eine Kategorie auswählen, um genau zu suchen",
        "syncing": "Synchronisieren...",
        "sync_ali_order": "Ali Bestellung synchronisieren",
        "sync_via_api": "Sync über API (Sie können manuell synchronisieren von AliExpress {length} mehr Zeit(en) heute)",
        "sync_tn": "Synchronisierung der Tracking-Nummern der letzten 30 Tage（Sie können die Synchronisierung von AliExpress {length} Zeit(en) heute manuell durchführen）",
        "sync_tn_2": "Synchronisieren Sie die neueste Tracking-Nummer mit der Chrome-Erweiterung",
        "sync_via_extension": "Synchronisierung über DSers Chrome-Erweiterung",
        "syncing_ali": "Aufträge werden synchronisiert",
        "sync_ali_completed": "Synchronisierung der Aufträge abgeschlossen",
        "select_sync_range": "Wählen Sie den Datumsbereich für die Aktualisierung der Tracking-Nummern",
        "sync_task_count": "Anzahl der Aufträge, die synchronisiert werden müssen",
        "sync_except_time": "Ungefähr benötigte Zeit",
        "sync_ali_failed_result": {
            "title": "Die folgenden Situationen können den Fehler verursachen:",
            "reason1": "1. das AliExpress-Konto, bei dem Sie sich derzeit anmelden, stimmt nicht mit dem Konto überein, das mit DSers verknüpft ist.",
            "reason2": "2. Sie haben sich während der Synchronisierung von Ihrem AliExpress-Konto abgemeldet.",
            "reason3": "3. mit Ihrem Netzwerk ist ein Fehler aufgetreten.",
            "contact": "Wenn Sie die oben genannten Punkte überprüft haben und es trotzdem nicht klappt, können Sie <a rel=\"noopener noreferrer\" target=\"_blank\" href=\"https://www.messenger.com/t/DSersOfficial\" dd=\"src\">uns gerne kontaktieren.</a>"
        },
        "start_time": "Startzeit",
        "end_time": "Endzeit",
        "tab": {
            "title": "{key, plural, =1 {Anstehend} =2 {Wartende Bestellung} =3 {Wartende Zahlung} =4 {Wartende Sendung} =5 {Erfüllt} =6 {Storniert} =7 {Fehlgeschlagene Bestellungen} =9 {Wartende Erfüllung} }",
            "all_tip": "Der Gesamtbetrag der Bestellung ist möglicherweise nicht gleich der Summe aus Wartende Bestellung, Wartende Zahlung, Wartende Lieferung und Erfüllte Bestellung, da einige Bestellungen fehlschlagen können.",
            "canceled_tip": "Bestellungen, die entweder von AliExpress/Shopify oder manuell von Nutzern storniert wurden, werden hier aufgeführt. Die bei AliExpress stornierten Bestellungen können erneut bestellt werden."
        },
        "place_order": {
            "btn": "{key, plural, =1 {Bestellung an AliExpress senden} =4 {Bestellung erneut senden} }",
            "tm_btn": "{key, plural, =1 {In großen Mengen platzieren} =4 {Bestellung erneut senden} }",
            "tip": "Sie müssen das Produkt zuordnen, damit die DS-Kunden wissen, mit welchem Anbieter Sie arbeiten, bevor Sie eine Großbestellung aufgeben.<br /> <a\n\t\t\thref=\"https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/\"\n\t\t\ttarget=\"_blank\"\n\t\t>Lesen Sie diesen Artikel</a>",
            agency_btn: '{key, plural, =1 {Bestellung in großer Stückzahl aufgeben} =4 {Bestellung erneut senden} }',
        },
        "more_action": {
            "title": "Weitere Aktionen",
            "action_menu": "Bestellungen manuell erfüllen",
            "action_menu_tip": "Wird nicht an AliExpress übermittelt, sondern nur als erfüllt markiert.",
            "tip": "Wenn Sie einige Bestellungen NICHT mit DSers bearbeiten möchten, können Sie sie hier manuell als erfüllt markieren. Dies hat keinen Einfluss auf Ihren Bestellstatus in Shopify.",
            "tip_link": "Check this article"
        },
        "canceled_by": {
            "placeholder": "Canceled by",
            "shopify": "Shopify",
            "ae": "AliExpress",
            "tm": "Tmall",
            agency_in_dsers: 'You on DSers'
        },
        "fulfilled_by": {
            "placeholder": "Fulfilled by",
            "shopify": "Shopify",
            "ae": "DSers",
            "self": "Manually fulfilled"
        },
        "country": "Country",
        "make_payment": {
            "btn": "Make payment",
            "h3": "Machen Sie Ihre Zahlungen auf Aliexpress<br /> Official Website, um Ihre Aufträge abzuschließen.",
            "h4": "Was passiert bei überfälliger Zahlung?",
            "p1": "1. Ihre Bestellungen können storniert werden",
            "p2": "2. Ihr Konto könnte einen Zahlungsausfall erleben",
            "h5": "Bitte schließen Sie Ihre Zahlung so schnell wie möglich ab,<<br /> um Ihre Kunden zufrieden zu stellen!"
        },
        agency_orders: {
            upload_tn_title: 'Tracking-Nr. manuell hochladen',
            upload_tn_sub_title: 'Nach dem Hochladen der Sendungsverfolgung kehrt DSers zu Shopify zurück und markiert die entsprechende Bestellung mit dem Status "Erfüllt".',
            upload_file_tip: 'Klicken Sie hier, um den Anhang hochzuladen',
            uplaod_match_column: 'Bitte wählen Sie die entsprechenden Felder in der Excel-Datei aus. Wir werden sie automatisch für Sie abgleichen und dann hochladen.',
            upload_agency_order_id: 'Bestell-ID der Agentur',
            upload_product_id: 'Produkt-ID des Artikels',
            upload_sku_id: 'Artikel SKU ID',
            upload_shipping_carrier: 'Versanddienstleister',
            upload_custom_shipping_url: 'Benutzerdefinierte Tracking-URL',
            upload_tracking_number: 'Tracking No',
            upload_select_placeholder: 'Bitte auswählen',
            upload_confirm: 'Bestätigen',
            upload_payment_title: 'Angaben zur Importzahlung',
            upload_payment_sub_title: 'Laden Sie eine Excel-Datei mit den Einkaufsausgaben hoch. DSers wird sie für Sie aufzeichnen, was Ihnen bei der Auftragsverwaltung helfen wird.',
            upload_payment_currency: 'Einkaufswährung (standardmäßig USD)',
            upload_product_cost: 'Einkaufspreis',
            upload_shipping_cost: 'Kosten für den Versand',
            download_order_info: 'Exportieren von Auftragsinformationen',
            download_by_select: 'Es wurden {value} Aufträge ausgewählt',
            download_by_date: 'Aufträge nach Datum exportieren',
            download_title: 'Exportieren',
            download_as: 'Exportieren als',
            export_to_file_type_excel: 'Normale Exceldatei',
            download_btn: 'Aufträge exportieren',
            export_orders_action: 'Auftrags exportieren',
            import_payment_details: 'Angaben zur Importzahlung',
            import_tn_to_orders: 'Sendungsnummer manuell hochladen',
            mark_as_cancel: 'Bestellungen manuell stornieren',
            mark_as_cancel_title: 'Bestätigen Sie die Stornierung der Bestellung',
            mark_as_cancel_desc: 'Bitte beachten Sie, dass die Bestellung auf DSers als storniert markiert wird und dass der Bestellstatus nicht mit Shopify synchronisiert wird.',
            please_input_confirm: 'Bitte geben Sie BESTÄTIGEN ein.',
            mark_as_paid_title: 'Bestellung als bezahlt markieren',
            mark_as_paid_desc: 'Bitte vergewissern Sie sich, dass die Bestellung offline mit Ihrem Lieferanten abgewickelt wurde. Nachdem Sie die Bestellung manuell als bezahlt markiert haben, wird die Bestellung in den Status "In Erwartung der Lieferung" versetzt.',
            cancel_order_failed: 'Stornierung der Bestellung fehlgeschlagen, bitte versuchen Sie es später noch einmal.',
            place_confirm_title: 'Bestätigen Sie die Bestellinformationen',
            place_btn_tip: 'Klicken Sie auf die Schaltfläche "Bestellung in großer Stückzahl aufgeben" und DSers wird die Adressdaten für Ihre Bestellung überprüfen.',
            agency_canceled_tag: 'DSers Abgebrochen',
            from_agency: 'Agency',
            agency_order_number_full: 'Agency order number',
            export_failed: 'Der Export ist fehlgeschlagen, möglicherweise liegt ein Netzwerkfehler vor, bitte versuchen Sie es später noch einmal.',
            export_failed_empty: 'Der Export ist fehlgeschlagen, da es in dem von Ihnen gewählten Zeitraum keinen Auftrag gibt. Sie können einen anderen Zeitraum wählen und es erneut versuchen.',
            upload_success: 'Upload erfolgreich',
            upload_failed: 'Upload fehlgeschlagen',
            upload_failed_reason: 'Gründe für den fehlgeschlagenen Download',
            uploading: 'Hochladen',
            export_download_link_title: 'Sollte der Download nicht automatisch starten, klicken Sie bitte hier',
            mark_as_paid_failed: 'Die manuelle Kennzeichnung der Bestellung als bezahlt ist fehlgeschlagen, bitte versuchen Sie es später erneut.',
            exporting_orders: 'Exportieren',
            agency_fulfill_tip: 'Sind Sie sicher, dass Sie diese Aufträge manuell AUSFÜHREN wollen?',
            agency_rollback_title: 'Wenn Sie ausgewählte Bestellungen zurücksetzen, finden Sie die Bestellungen auf der Registerkarte \"Wartende Bestellung\". Die alten Agency-Bestellinformationen gehen nach der Rückgängigmachung verloren.',
            agency_edit_tracking_no_tip: 'After you successfully modify a Tracking No, you may resend the mail to your buyer according to your Settings, please be careful',
            agency_auth_status_processing: 'ERP-Verarbeitung',
            agency_auth_status_offline: 'Auftrag offline',
            agency_process_tag_tip: `Die Bestellinformationen wurden an das ERP-System gesendet, und die Erstellungszeit wird auf 2 Stunden geschätzt. Nach erfolgreicher Erstellung können Sie das Kaufangebot mitteilen und bezahlen. Wenn der Status länger nicht mehr aktualisiert wurde, können Sie versuchen, die Bestellung manuell zu bearbeiten.`,
            erp_payment_tip_title: 'Bitte bestätigen',
            erp_payment_tip_content: `Wir haben festgestellt, dass die bezahlte Bestellung einen Auftrag enthält, der im ERP erstellt werden muss. Bitte bestätigen Sie, dass vor der Zahlung über die gesamte zu bearbeitende Bestellmenge informiert wurde.`,
            erp_auth_type_filter: 'Typ',
            payment: {
                make_payment: 'Make Payment',
                make_payment_tip: 'Nehmen Sie Ihre Zahlungen vor, um Ihre Bestellungen abzuschließen. Bitte schließen Sie Ihre Zahlung so schnell wie möglich ab, um Ihre Kunden zufrieden zu stellen!',
                currency_diff_title: 'Vorsicht',
                currency_diff_content: 'Vergewissern Sie sich, dass die ausgewählten Bestellungen in der gleichen Währung für den gleichen Lieferanten vorliegen. Sie können die Währung ändern und die CSV-Datei erneut hochladen oder in Stapeln in verschiedenen Währungen zahlen.',
                currency_diff_btn: 'VERSTANDEN',
                payment_modal_title: 'Bitte bestätigen Sie die Bestell- und Agenturinformationen, bevor Sie bezahlen.',
                payment_1: "Due to restrictions from {type},  each order must be over $1.00. Please contact your supplier to modify.",

                edit_agency_info: 'Agenturinformationen bearbeiten',
                agency_info_name: 'Name der Agentur',
                agency_info_paypal: 'Agentur PayPal',
                agency_info_amount: 'Geschätzter Zahlungsbetrag',
                agency_info_amount_v2: 'Payment amount',
                agency_inpayment_tip: 'The payment is being paid, please complete the rest of the payment.',
                get_payment_detail_failed: 'Die Zahlung ist verfallen.',
            },
            order_confirm_erp_title: 'ERP',
            order_confirm_erp_tip_1: `Sie haben das ERP dieses Lieferanten gebunden. Nach erfolgreicher Bestellung werden wir die Bestellung direkt an Ihren Lieferanten senden.`,
            order_confirm_erp_tip_2: `Sie haben das ERP dieses Lieferanten nicht angebunden. Nachdem die Bestellung erfolgreich aufgegeben wurde, müssen Sie die Bestellung manuell exportieren und an Ihren Lieferanten senden.`
        },
        "order_title": {
            "order_no": "Bestellnummer",
            "date": "Datum",
            "country": "Land",
            "income": "Einkommen",
            "note": {
                "title": "Anmerkung",
                "add": "hinzufügen"
            },
            "customer_detail": "Kunde Detail",
            "title": "Bestellung filtern"
        },
        "edit_address": {
            "contact_name": "Name der Kontaktperson:",
            "tel": "Tel:",
            "email": "E-Mail:",
            "company": "Firma:",
            "cpf": "Cpf",
            "country": "Land:",
            "address": "Anschrift:",
            "province": "Bundesland:",
            "city": "Stadt:",
            "post_code": "Postleitzahl:",
            "clearance_info": "Persönliche Freigabe-ID",
            "alien_clearance_info": "Nummer des Reisepasses/Ausländerausweises:",
            "clearance_info_required": "Der eindeutige Code für die persönliche Freigabe besteht aus einer 12-stelligen Zahl, die mit P beginnt. Füllen Sie dieses Feld aus, wenn der Kunde ein gebürtiger Koreaner ist.",
            "clearance_info_tip": "Der eindeutige Code für die persönliche Freigabe besteht aus einer 12-stelligen Zahl, die mit P beginnt (z. B. P682151173203). Klicken Sie <a href=\"https://help.dsers.com/orders-to-korea-specifications-for-shopify/\" target=\"_blank\" rel=\"noopener noreferrer\" >hier</a>, um weitere Informationen zu erhalten.",
            "alien_clearance_info_required": "Bitte geben Sie die Nummer Ihres Reisepasses oder Ihrer Ausländerkarte ein. Füllen Sie dieses Feld aus, wenn der Kunde KEIN gebürtiger Koreaner ist.",
            "alien_clearance_info_tip": "Falsches Format. Geben Sie 1- 13 Ziffern oder Buchstaben ein. Klicken Sie <a href=\"https://help.dsers.com/orders-to-korea-specifications-for-shopify/\" target=\"_blank\" rel=\"noopener noreferrer\" >hier</a>, um weitere Informationen zu erhalten.",
            "rut_required": "Bitte geben Sie eine RUT-Nummer ein",
            "rut_incorrect": "Es scheint ein Fehler bei der RUT-Nummer vorzuliegen",
            "rut_tip": "Bitte lesen Sie unseren <a href=\"https://help.dsers.com/orders-to-chile-specifications-for-shopify/\" target=\"_blank\" rel=\"noopener noreferrer\" >Blog</a>, um mehr zu erfahren",
            "unknow_wrong": "Bei Ihren Kundeninformationen ist etwas falsch. Bitte überprüfen und überarbeiten Sie diese.",
            "enter_name": "Bitte geben Sie einen Kontaktnamen ein",
            "enter_between": "Bitte geben Sie zwischen {start} - {end} Zeichen ein",
            enter_write_tel: "Please fill in your real phone number here, enter between 5-16 numbers. Click <a href='https://help.dsers.com/fix-failed-orders-due-to-aliexpress-phone-number-regulations/' target='_blank'>here</a> to learn how to solve this problem",
            "korea_error": "Please enter {start} or {end} -digit phone number starting with 01 or 1 (e.g. 01012345678)",
            "italy_error": "Please enter {start} or {end} digits",
            "english_only": "Bitte geben Sie nur Englisch ein",
            "max128": "The sum of the contact name and company length cannot exceed 128 characters.",
            "only_num_hyphens_forward": "Please enter Numbers and/or hyphens '-' and/or forward slashes '/' only",
            "cpf_tip": "CPF might be wrong. If you checked it, please ignore this message. Please go to AliExpress to double check.",
            "enter_country": "Bitte geben Sie ein Land/Region ein",
            "enter_address": "Bitte geben Sie eine Adresse ein",
            "enter_address_length": "Please ensure that your address is not less than 5 characters.",
            "select_province": "Bitte wählen Sie ein Bundesland/Provinz/Region",
            "enter_city": "Bitte geben Sie eine Stadt ein",
            "city_not_match": "Die Stadt stimmt nicht überein. Bitte wählen Sie aus der Pulldown-Liste.",
            "enter_zip": "Bitte geben Sie eine Postleitzahl ein",
            "enter_zip_1234": "Bitte geben Sie eine {length}-stellige Postleitzahl ein, z. B. {ps}",
            "enter_zip_123456": "Bitte geben Sie eine Postleitzahl ein, z. B. {ps}",
            "enter_zip_special": "Bitte geben Sie Ihre Postleitzahl im vorgeschlagenen Format ein, z. B. {ps}",
            "enter_zip_confirm": "Für den internationalen Versand ist eine 7-stellige Postleitzahl erforderlich. Bei Bedarf können Sie dies bei Ihrem örtlichen Postamt erfragen.",
            "only_num_spaces_hyphens": "Bitte geben Sie nur lateinische Buchstaben/Zahlen/Leerzeichen/Bindestriche ein.",
            "france_phone_setting_tip": "Für Bestellungen, die nach Frankreich geschickt werden, sind 10-stellige Handynummern erforderlich, die mit 06 beginnen.",
            "france_phone_customers_tip": "Für Bestellungen nach Frankreich sind 10-stellige Handynummern erforderlich."
        },
        "order_product": {
            "title_unarchive": "Archivierung aufheben: Nachdem die Bestellung archiviert wurde, finden Sie die Bestellung auf der Registerkarte \"Offene Bestellungen\".",
            "variants": "Varianten:",
            "from": "Von",
            "from_aliexpress": "Aliexpress",
            "from_other": "Andere",
            "cost": "Kosten",
            "quantity": {
                "price": "Preis",
                "ok": "Ok",
                "max": "Maximal"
            },
            "dsers_shipping": "ePaket",
            "shipping_method": "Versandart",
            "shipping_dialog": {
                "company": "Versandunternehmen",
                "cost": "Versand Kosten",
                "time": "Geschätzte Lieferfrist",
                "tracking": "Verfolgungsinformationen",
                "price": "Kostenloser Versand",
                "days": "Tage",
                "available": "Verfügbar",
                "unavailable": "Nicht verfügbar",
                "ok": "Ok",
                "cancel": "Abbrechen",
                "setting_link": "Versandmethode im Einstellungsfenster einrichten",
                "overrun_warning": "Sie haben das tägliche Limit für den Zugriff auf AliExpress-Produktdaten überschritten, können aber weiterhin Produkte importieren und pushen",
                "async_frienght": "Update shipping method list",
                upcoming1: `Die Versandart kann nicht eingestellt werden, der Grund kann sein:`,
                upcoming2: `1) Das Mapping ist für dieses Produkt nicht eingestellt.`,
                upcoming3: `2) Der Lieferant kann nicht an den Bestimmungsort der Bestellung liefern. Bitte bestätigen Sie die Lieferadresse mit dem Benutzer oder verwenden Sie Bearbeiten", um zu einem Lieferanten zu wechseln, der die Lieferung an dieses Ziel unterstützt.`,
            },
            "shipping_cost_free": "kostenlos",
            "shipping_time": "Tage",
            "ali_order_no": "Ali Order No",
            "tmall_order_no": "Tmall Order No",
            "order_no": "Order No",
            "tracking_no": "Tracking No",
            "tracking_no_add": "add",
            "sync": "Sync",
            "ali_order_no_no_match": "Ich kann diese Bestellung zu dieser Ali-Bestellnummer nicht finden. Bitte überprüfen Sie dies.",
            "mapping": "Abbildung",
            "mapping_tip": "Klicken Sie auf Mapping, um die Lieferanten für dieses Produkt zu verwalten.",
            "customer_service": "Kundenbetreuung",
            "action": {
                "title": "Aktion",
                "message": "Nachricht",
                "edit": "Bearbeiten",
                "mark_as_fulfilled": "Als Erfüllt markieren",
                "bulk_edit": "Mehrfache Bearbeitung"
            },
            "fulfilled_by_other_tip": "Haben Sie Aufträge falsch ausgeführt? Wenn Sie die Bestellung über DSers abwickeln wollen, können Sie sie nicht manuell auf DSers oder Shopify erfüllen. Und Sie müssen die Funktion zur automatischen Erfüllung auf Shopify deaktivieren. Wenn Sie die Bestellungen rückgängig machen möchten, klicken Sie bitte auf den Messenger-Button, um Hilfe zu erhalten. Bitte schauen Sie <a\n\t\t\tstyle=\"color:#FF8F00\"\n\t\t\thref=\"https://help.dsers.com/re-order-fulfilled-orders-for-shopify/\"\n\t\t\ttarget=\"_blank\"\n\t\t\tdata-ga=\"wrongly fulfilled - here\"\n\t\t>hier</a> nach, um eine Schritt-für-Schritt-Anleitung zu erhalten.",
            "ae_order_error_tip": {
                "click": "Klicken Sie auf",
                "message_us": "Nachricht an uns",
                "why_see_this": "Warum sehe ich das?",
                "ae_not_available": "Dieses Produkt ist auf AliExpress nicht verfügbar. Bitte gehen Sie zu AliExpress und überprüfen Sie es.",
                "sku_not_found": "SKU wird nicht gefunden. Bitte klicken Sie",
                "sku_not_found_after": "um einen AliExpress Produktlink hinzuzufügen.",
                "no_shipped_country": "Dieses Produkt kann nicht in dieses Land verschickt werden. Klicken Sie&nbsp;",
                "no_shipped_country_after": "&nbsp;um ein AliExpress-Produkt zu ändern.",
                "freight_error": "Bitte geben Sie die Adressdaten der Bestellung ein, um die Versandart zu wählen",
                "set_shipping": {
                    "p1": "Wählen Sie eine",
                    "p2": "Versandart",
                    "p3": "um die Bestellfunktion zu aktivieren.",
                    "p4": "Versandmethode im Einstellungsfenster einrichten"
                },
                "add_ae_link": {
                    "p1": "AliExpress-Link erfolgreich hinzufügen! Wählen Sie eine",
                    "p2": "Versandart",
                    "p3": "um die Bestellfunktion zu aktivieren."
                },
                "add_ae_link_succ": "AliExpress-Link erfolgreich hinzufügen!",
                "delete_by_shopify": "Das/die Produkt(e) wurde(n) von Shopify gelöscht"
            }
        },
        "bind_product": {
            "ae_url": "AliExpress-URL",
            "ae_url_tip": "AliExpress Produkt-URL eingeben",
            "tmall_url_tip": "Tmall-Produkt-URL eingeben",
            "from_aliexpress": "Von AliExpress",
            "cost": "Kosten",
            "select_varint": "Korrent Varianten sind <b>{num}</b> Bitte wählen Sie die entsprechende Variante aus:",
            "overrun_warning": "Sie haben das Tageslimit für den Zugriff auf die Produktinformationen überschritten"
        },
        "order_foot": {
            "cost": "Produktkosten",
            "shipping": "Versand",
            "total": "Gesamtkosten",
            "pending_authorized": "Autorisiert",
            "pending_authorized_tip": "Diese Bestellung muss die Zahlung auf Shopify erfassen.",
            "shopify_deleted": "Shopify gelöscht",
            "is_shopify_delete": "Die Bestellung wurde in Shopify gelöscht. Wenn Sie die Bestellung noch bearbeiten möchten, erstellen Sie bitte eine neue ähnliche Bestellung auf Shopify.",
            "product_cost_tip": {
                "title": "Die Kostendifferenz kann aus verschiedenen Gründen entstanden sein:",
                "p1": "1. DSers zeigt nur die grundlegenden Standardkosten auf AliExpress. Aber die tatsächlichen Kosten variieren je nach Land, in das Sie verkaufen.",
                "p2": "2. DSers unterstützt im Moment keine verschiedenen Währungen, nur USD. Bitte stellen Sie sicher, dass die Währung auf DSers und AliExpress die gleiche ist, wenn Sie die Produktkosten vergleichen.",
                "p3": "3. DSers aktualisiert die Produktkosten für erfüllte oder ausstehende Bestellungen nicht. Sobald der Bestellstatus auf In Erwartung aktualisiert wird, werden die Produktkosten automatisch aktualisiert.",
                "p3_1": "Bitte beachten Sie, dass die Produktkosten alle 24 Stunden auf DSers aktualisiert werden.",
                "p4": "4. Taxes will be applied to orders shipping to some countries, like the USA and Australia.",
                "p_end": "Die korrekten Kosten für das Produkt werden angezeigt, nachdem Sie die Bestellung bei AliExpress aufgegeben haben."
            },
            "shipping_tip": "Die endgültigen Versandkosten, die hier angezeigt werden, sind die gleichen wie bei den AliExpress-Bestellungen und hängen von Ihren Lieferanten ab. Wenn ein Rabatt auf Ihre AliExpress-Bestellungen angewendet wird, wird der Rabattbetrag in die hier angezeigten Versandkosten eingerechnet.<a\n\t\t\thref=\"https://help.dsers.com/shipping-fees-on-dsers-for-shopify/\"\n\t\t\ttarget=\"_blank\"\n\t\t> Bitte lesen Sie den Blog für weitere Details.</a>",
            "order_again": "Erneut bestellen",
            "order": "Bestellung",
            "order_tip": "Sie müssen das Produkt zuordnen, damit die DSer wissen, mit welchem Anbieter Sie arbeiten, bevor Sie eine Großbestellung aufgeben. <a\n\t\t\thref=\"https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/\"\n\t\t\ttarget=\"_blank\"\n\t\t> Lesen Sie diesen Artikel</a>",
            "tax_title": "Tax",
            "other_title": "Other",
            "tax_tip": "If you want the tax displayed here, please use the \n\t\t\t<a\n\t\t\t\thref=\"https://www.dsers.com/dsers-chrome-extension/\"\n\t\t\t\ttarget=\"_blank\"\n\t\t\t>\n\t\t\t\tDSers Chrome Extension\n\t\t\t</a> to place the order.",
            "shipping_tip_ext": "The final shipping cost shown here will be the same as on the AliExpress orders and will depend on your suppliers.",
            "other_tip_ext": "When a discount applies to your AliExpress orders, the discount amount will be counted in here.",
            "order_displayed": "The order displayed price is not in USD. DSers converted to USD according to the exchange rate. This may be different from the actual price on AliExpress. DSers only displays it for information, it will not affect the amount to pay. Please check AliExpress to see the actual amount to pay"
        },
        "sync_order_alert": "DSers wird automatisch von Shopify und AliExpress synchronisiert, wenn Sie sich bei DSers anmelden. Die Synchronisierung wird 10 Minuten dauern. DSers wird nicht mehr automatisch alle 6h synchronisieren, um eine stabilere Synchronisation zu gewährleisten. Bitte stellen Sie sicher, dass Sie sich anmelden, wenn Sie den Bestellstatus synchronisieren möchten.",
        "cpf_alert": "Aufgrund neuer Vorschriften, müssen alle Bestellungen nach Brasilien die CPF-Nummer enthalten, sonst werden die Bestellungen nicht angenommen. DSers wird die CPF-Nummer auf Ihren Paketen erscheinen lassen. Sie können die CPF-Nummer Ihres Kunden in das Feld Unternehmen eintragen, in Kundendetails.",
        "time_zone_placeholder": "Zeitzone",
        "af_more_action": "Synchronisierung der Tracking-Nummer mit Shopify",
        "shopify_fulfilled": "Shopify erfüllt",
        "shopify_canceled": "Shopify storniert",
        "shopify_partially_fulfilled": "Shopify teilweise erfüllt",
        "shopify_partially_canceled": "Shopify teilweise storniert",
        "shopify_refunded": "Shopify zurückerstattet",
        "ali_canceled": "Ali storniert",
        "tm_canceled": 'Tmall canceled',
        "zero_tip": "Das Problem wurde durch die manuelle Beantragung der Erfüllung auf Shopify verursacht. Bitte führen Sie diese Bestellung manuell auf DSers, und rollen Sie die Bestellung zurück. Die Menge wird aktualisiert",
        "err_deleted_in_shopify": "Dieses Produkt wurde in Shopify gelöscht",
        "err_may_be_blacklisted": "Die Bestellung ist aus vier möglichen Gründen fehlgeschlagen.",
        "err_france": "Bitte ersetzen Sie Frankreich im Feld Land durch { Guadeloupe } aufgrund der neuen Bedingungen von AliExpress",
        "err_out_of_stock": "Das Produkt ist nicht mehr auf Lager. Bitte kontaktieren Sie Ihren Lieferanten oder wechseln Sie zu einem neuen Lieferanten.",
        awaiting_error_tip11: 'Aufgrund der Einkaufsbeschränkung des Hauptlieferanten wurde Ihre Bestellung unter Verwendung des Zweitlieferanten aufgegeben. Wenn Sie den Lieferanten ändern möchten, stornieren Sie bitte die Bestellung und ändern Sie den zugehörigen Lieferanten manuell auf der Registerkarte "Storniert".',
        awaiting_error_tip13: 'Ihre Bestellung wurde über den Zweitlieferanten aufgegeben, da der Hauptlieferant keinen Bestand hat. Wenn Sie den Lieferanten ändern möchten, stornieren Sie bitte die Bestellung und ändern Sie den zugehörigen Lieferanten manuell auf der Registerkarte "Storniert".',
        awaiting_error_tip14: 'Die Logistik des Hauptlieferanten unterstützt die Lieferung an den Zielort nicht, Ihre Bestellung wurde über den Zweitlieferanten aufgegeben. Wenn Sie den Lieferanten ändern möchten, stornieren Sie bitte die Bestellung und ändern Sie den zugehörigen Lieferanten manuell auf der Registerkarte "Storniert".',
        awaiting_error_tip15: 'Der Hauptlieferant hat Sie auf die schwarze Liste gesetzt, Ihre Bestellung wurde über den Zweitlieferanten aufgegeben. Wenn Sie den Lieferanten ändern möchten, stornieren Sie bitte die Bestellung und ändern Sie den zugehörigen Lieferanten manuell auf der Registerkarte "Storniert".',
        awaiting_error_tip21: 'Die SKU des Hauptlieferanten kann nicht verkauft werden, Ihre Bestellung wurde über den Zweitlieferanten aufgegeben. Wenn Sie den Lieferanten ändern möchten, stornieren Sie bitte die Bestellung und ändern Sie den zugehörigen Lieferanten manuell auf der Registerkarte "Storniert".',
        awaiting_error_tipOther: 'Es gibt ein Problem mit dem Hauptlieferanten und Ihre Bestellung wurde an den Zweitlieferanten vergeben.  Wenn Sie den Lieferanten ändern möchten, stornieren Sie bitte die Bestellung und ändern Sie den zugehörigen Lieferanten manuell auf der Registerkarte "Storniert".',
        tmall_error_code1: 'Das System ist ausgelastet, bitte versuchen Sie es später noch einmal oder klicken Sie, um den Kundendienst zu kontaktieren',
        tmall_error_code2: '',
        tmall_error_code3: 'Die Versandart für dieses Produkt ist ungültig, bitte versuchen Sie, die Bestellung erneut aufzugeben',
        tmall_error_code4: 'Die Kaufmenge erreicht nicht die Mindestabnahmemenge, klicken Sie, um den Kundendienst zu kontaktieren oder zu einem neuen Lieferanten zu wechseln.',
        tmall_error_code5: 'Dieses Produkt kann derzeit nicht über den PC gekauft werden. Klicken Sie, um den Kundenservice zu kontaktieren oder wechseln Sie zu einem anderen Anbieter.',
        tmall_error_code6: 'Der Lagerbestand dieses Produkts ist unzureichend. Klicken Sie hier, um den Kundendienst zu kontaktieren oder zu einem anderen Anbieter zu wechseln.',
        tmall_error_code7: 'Dieses Produkt ist in dieser Region vorübergehend nicht erhältlich. Klicken Sie hier, um den Kundendienst zu kontaktieren oder zu einem anderen Anbieter zu wechseln.',
        tmall_error_code8: 'Dieses Produkt ist vorübergehend nicht verfügbar, bitte versuchen Sie es erneut und geben Sie die Bestellung erneut auf',
        tmall_error_code9: 'Dieses Produkt ist ein aktives Produkt und die Veranstaltung hat noch nicht begonnen. Klicken Sie hier, um den Kundenservice zu kontaktieren oder zu einem anderen Anbieter zu wechseln.',
        tmall_error_code10: 'Die Bestellmenge überschreitet die Mindestabnahmemenge. Klicken Sie hier, um den Kundendienst zu kontaktieren oder zu einem anderen Lieferanten zu wechseln.',
        tmall_error_code11: 'Abzug des Produktbestands fehlgeschlagen, bitte versuchen Sie es erneut und geben Sie die Bestellung erneut auf',
        tmall_error_code12: 'Dieses Produkt ist vorübergehend nicht verfügbar. Klicken Sie, um den Kundenservice zu kontaktieren oder zu einem anderen Anbieter zu wechseln.',
        tmall_error_code13: 'Der Produktpreis konnte nicht ermittelt werden, bitte versuchen Sie es erneut und geben Sie die Bestellung erneut auf',
        tmall_error_code14: '',
        tmall_error_code15: 'Abgelaufene Gutscheine haben diese Bestellung fehlschlagen lassen. Bitte entfernen Sie die Gutscheine und versuchen Sie es erneut.',
        tmall_error_code16: 'Für dieses Produkt ist derzeit keine Versandart verfügbar. Klicken Sie hier, um den Kundenservice zu kontaktieren oder zu einem neuen Anbieter zu wechseln.',
        tmall_error_code17: '',
        "err_may_be_out_of_stock": "Ihr Lieferant hat das Produkt für diese Bestellung möglicherweise nicht mehr auf Lager. Bitte gehen Sie zu AliExpress und überprüfen Sie dies.",
        "err_limit": "Sie können nur { Limit } Stück(e) für dieses Produkt höchstens kaufen, bitte kontaktieren Sie den Lieferanten zu überprüfen oder ändern Sie den Lieferanten für dieses Produkt.",
        "err_limit_blur": "Sie können nur eine bestimmte Menge dieses Produkts kaufen. Bitte kontaktieren Sie den Lieferanten, um dies zu überprüfen, oder wechseln Sie den Lieferanten für dieses Produkt, um die Bestellung aufzugeben.",
        "err_not_available": "Das Produkt ist auf AliExpress nicht verfügbar, bitte wechseln Sie den Lieferanten für dieses Produkt.",
        "err_not_shipping": "The province selected in Shopify is set as a country on AliExpress, please manually change the destination in the Customer Detail section of DSers. If the product can't be shipped to the country of destination, we recommend you change the suppliers.",
        "err_blacklisted": "Ihr Lieferant hat Sie auf die schwarze Liste gesetzt. Bitte kontaktieren Sie Ihren Lieferanten oder suchen Sie einen anderen Lieferanten.",
        "err_un_mapped": "Das Produkt ist nicht oder nur teilweise zugeordnet, bitte ordnen Sie den Lieferanten auf DSers korrekt zu. <a href=\"https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/\" target=\"_blank\">Hier</a>erfahren Sie, wie das geht.",
        "err_shipping_to_china": "Bitte beachten Sie, dass AliExpress den Versand nach China nicht unterstützt.",
        "err_shipping_to_RU": "Aufgrund von Einschränkungen der AliExpress-Schnittstelle unterstützt DSers derzeit keinen Versand nach Russland. Bitte geben Sie die Bestellung(en) manuell auf AliExpress auf und tragen Sie die AliExpress-Bestellnummer in die Bestellung auf DSers ein.",
        "err_shipping_address": "Die Shopify-Adresse oder Ihre Bestellung stimmt möglicherweise nicht mit der AliExpress-Adresse überein. Die hier angezeigte Versandmethode ist diejenige, die im Zielland von AliExpress verwendet wird.",
        "ae_account_disabled": "Ihr AliExpress-Konto wurde von AliExpress gesperrt. Bitte erstellen Sie ein neues AliExpress-Konto und verknüpfen Sie es.",
        "err_extra_address": "Falsche oder fehlende Kundendaten, bitte überprüfen Sie die Fehlermeldung und korrigieren Sie sie entsprechend.",
        "err_add_address_ukraine": "(If the destination is now a war zone, you will not be able to place the order)",
        "qty_zero_tip": "Die Erfüllung der Bestellung wurde auf Shopify angefordert. Da die Funktion Request Fulfillment nicht mit der Art und Weise übereinstimmt, wie DSers Bestellungen verarbeitet, ändern Sie bitte die Bestellung anhand der Anleitung im Blog. Klicken Sie <a href=\"https://help.dsers.com/re-order-request-fulfillment-order-for-shopify/\" target=\"_blank\">hier</a>, um weitere Informationen zu erhalten",
        "ae_tip_IN_error": "At the moment, orders for India cannot be placed successfully. Please click  <a href=\"https://customerservice.aliexpress.com/knowledgeDetail?categoryId=21039549&hcAppId=1248&hcFromCode=Xn2Tk1tb7&hcSessionId=26-1248-70544a7b-e1ed-41af-8a2d-1fa5a625173e&knowledgeId=627712&spm=service_hall.24300488.searchresult.627712）\" target=\"_blank\">here</a> to get more information.",
        "ae_code_11_error": "Aufgrund von Problemen mit AliExpress kann der Gesamtwarenwert beim Kauf von Waren, die nach Frankreich verschickt werden sollen, 150 EUR nicht überschreiten. Bitte teilen Sie diese Bestellung in mehrere Bestellungen auf und versuchen Sie es erneut.",
        "ae_code_11_error_1": "Der Preis Ihrer Bestellung nach Frankreich übersteigt 150 Euro. Gemäß den Vorschriften müssen Sie die Mehrwertsteuerinformationen ausfüllen. Bitte klicken Sie <a href=\"/app/settings?tabChangeSta=4\">hier</a>, um zu den Einstellungen zu gelangen. Klicken <a href=\"https://help.dsers.com/orders-to-france-specifications-for-shopify/\" target=\"_blank\">Sie hier</a>, um weitere Informationen zu erhalten.",
        "ae_code_11_error_btn": "Abgelaufene Gutscheine haben diese Bestellung fehlschlagen lassen. Bitte entfernen Sie die Gutscheine und versuchen Sie es erneut.",
        "ae_code_11_error_btn_ae": `These possible reasons made this order fail:<br/>
        1. Expired coupons made this order fail. Please remove the coupons and try again.<br/> 
        2. Your AliExpress account was under risk control, please <a href="https://www.messenger.com/t/DSersOfficial" target="_blank" >connect</a> another AliExpress account to try again or contact our support.`,
        "ae_code_11_error_2": "Das System hat festgestellt, dass Ihr AliExpress-Konto keine normalen Bestellungen aufgeben kann. Bitte ändern Sie das verknüpfte AliExpress-Konto (klicken Sie <a href=\"/app/settings?tabChangeSta=8\">hier</a>, um es zu ändern).",
        "ae_code_11_error_3": "Bei Ihrer Bestellung ist ein unbekannter Fehler aufgetreten, bitte kontaktieren Sie den Kundenservice.",
        "err_sku_change": "Ihr Lieferant hat die SKU geändert. DSers aktualisiert die Produktinformationen alle 2 Stunden, bitte ändern Sie das Produkt und versuchen Sie es später noch einmal.",
        "supplier_account_disabled": "Lieferantenkonto deaktiviert",
        "err_ae_fake_failed": "Aufgrund der jüngsten Aktualisierung der AliExpress-Schnittstelle wurde diese Bestellung möglicherweise bereits erfolgreich aufgegeben. Bitte überprüfen Sie dies auf AliExpress, um Verluste zu vermeiden. Wenn die Bestellung erfolgreich war, geben Sie bitte die Bestellnummer manuell ein. Wenn die Bestellung fehlschlägt, geben Sie die Bestellung bitte erneut auf.",
        "currency_not_supported": "Währung nicht unterstützt",
        "cpt_tip": "Bitte geben Sie eine gültige 11-stellige CPF-Nummer ein",
        "coupon_expired": "Aktion oder Coupon abgelaufen",
        "duplicated_order": "Duplizierte Bestellung",
        "err_varients_change": "Eine Variante dieses Produkts hat sich geändert, bitte ordnen Sie das Produkt neu zu und geben Sie dann die Bestellung auf.",
        "err_code_21": "Please note that the order placement failed because the product's SKU has changed, please re-map and place the order again.",
        "place_order_timeout": "Die Bestellung wurde abgebrochen. Bitte gehen Sie zu AliExpress, um zu überprüfen, ob die Bestellung erfolgreich aufgegeben wurde. Wenn die Bestellung erfolgreich war, geben Sie bitte manuell die AliExpress-Bestellnummer ein. Wenn die Bestellung fehlschlägt, geben Sie die Bestellung bitte erneut auf.",
        "order_processing": "Auftragsabwicklung ...",
        "order_reault_failed": "Sie haben fehlgeschlagene Bestellungen! Bitte überprüfen Sie .",
        "order_reault_succ": "Bezahlen Sie auf AliExpress !",
        "order_reault_await_length": "Bestellungen in Bearbeitung",
        "order_reault_is_await_length": "Bestellungen in Bearbeitung",
        "order_reault_succ_length": "Erfolgreich aufgegeben",
        "order_reault_failed_length": "Bestellung fehlgeschlagen",
        "order_reault_makepayment": "Zahlung durchführen",
        "order_reault_checkfailed": "Gescheiterte Bestellung prüfen",
        "roll_back_btn": "Aufträge zurücknehmen",
        "roll_title": "Wenn Sie ausgewählte Bestellungen zurücksetzen, finden Sie die Bestellungen auf der Registerkarte \"Wartende Bestellung\". Die alten AliExpress-Bestellinformationen gehen nach der Rückgängigmachung verloren.",
        "roll_title_tm": 'Wenn Sie ausgewählte Bestellungen zurücksetzen, finden Sie die Bestellungen auf der Registerkarte "Wartende Bestellung". Die alten Tmall-Bestellinformationen gehen nach der Rückgängigmachung verloren.',
        "roll_desc": "Aufträge zurücknehmen",
        "place_order_confirm_tooltip": "Please first select a shipping method for all orders before you confirm.",
        "archive_title": "Archived orders",
        "archive_desc": "Once an order is archived, the order will disappear from the current tab. If you want to view the archived order, please go to the Archived orders page.",
        "global_place_order": "Bestellung",
        "global_place_order_tip": "Bestellung bei AliExpress aufgeben.",
        "confirm": {
            "tm_title": "Bitte überprüfen Sie die Versandinformationen, bevor Sie eine Bestellung bei Tmall aufgeben.",
            "title": "Bitte überprüfen Sie die Versandinformationen, bevor Sie eine Bestellung bei AliExpress aufgeben.",
            "title_succ": "Erfolgreich!",
            "title_fail": "Klicken Sie auf Überarbeiten, um fehlgeschlagene Bestellungen zu überprüfen.",
            "table_title": {
                "order": "Bestellung",
                "product": "Produkt",
                "income": "Einkommen",
                "product_cost": "Produkt Kosten",
                "country": "Land",
                "shipping_method": "Versandart",
                "shipping_fee": "Versandgebühr",
                "shipping_time": "Versanddauer",
                "feedback": "Rückmeldung"
            },
            "error_msg": {
                "err_101": "The product(s) was deleted from shopify",
                "err_0": "Click Mapping to add an AliExpress product link.",
                "ae_has_product_no": "This product is not available on AliExpress. Please go and check on AliExpress.",
                "child_cost_0": "SKU is not found. Please click Mapping to add an AliExpress product link.",
                "express_length_0": "This product can't be shipped to to this country. Click Mapping to change an AliExpress product.",
                "freight_math_info_no": "Choose a shipping method to active order feature.",
                "freight_math_info_no_bind": "Add AliExpress link successfully! Choose a shipping method to active order feature."
            },
            "item": "{ num, plural, =1 {Item} other {Items} }",
            "item_new": "Item(s)",
            "confirm": "Bestätigen Sie",
            "income_tip": "Der hier angegebene Betrag beinhaltet nicht den Bestellungsrabatt und den Coupon. Er kann sich vom tatsächlichen Wert unterscheiden.",
            "total_cost": "Gesamtkosten",
            "revise": "Überprüfen Sie",
            "make_payment": "Zahlung durchführen"
        },
        "fulfill_confirm": {
            "title": "Ausgewählte Bestellungen als erfüllt markieren (dies wird nicht mit Ihrem Shopify-Shop synchronisiert)",
            "table_title": {
                "order": "Bestellung",
                "product": "Produkt",
                "ae_order_number": "AliExpress-Bestellnummer",
                "feedback": "Rückmeldung"
            },
            "item": "Bestellungen",
            "mark_as_fulfilled": "Als Erfüllt markieren",
            "confirm_title": "Sind Sie sicher, dass Sie Aufträge manuell erfüllen können?",
            "confirm_dec": "Sind Sie sicher, dass Sie diese Bestellungen manuell ausfüllen können?<b> Bitte beachten Sie, dass DSers die Synchronisierung der Bestellinformationen für die auf AliExpress aufgegebenen Bestellungen einstellen wird.</b>",
            "continue": "Weiter",
            "checkTitle": "Markieren Sie diese Bestellung auch auf Shopify als erfüllt"
        },
        "bind_ae_dialog": {
            "title": "Link zu AliExpress, um mehrere Bestellungen aufzugeben",
            "shopify": "Shopify",
            "dsers": "DSers",
            "go_ae_tip": "Sie werden zu AliExpress weitergeleitet",
            "go_ae": "Verknüpfung AliExpress"
        },
        "message_dialog": {
            "title": "Hinterlassen Sie eine Nachricht an AliExpress-Verkäufer",
            "tm_title": "Hinterlassen Sie eine Nachricht an Tmall-Verkäufer",
            "desc": "AliExpress-Verkäufer erhalten diese Nachricht zusammen mit Ihren Bestellinformationen.",
            "tm_desc": "Tmall-Verkäufer erhalten diese Nachricht zusammen mit Ihren Bestellinformationen.",
            "placeholder": "Geben Sie maximal 1000 englische Zeichen ein (maximal 250 chinesische Zeichen)",
            "save": "Speichern Sie",
            title_new: 'Hinterlassen Sie eine Nachricht an Lieferanten',
            desc_new: 'Die Lieferanten erhalten diese Nachricht zusammen mit Ihren Bestellinformationen.',
        },
        "go_ae_pay_dialog": {
            "h3": "Machen Sie Ihre Zahlungen auf Aliexpress <br />Official Website, um Ihre Bestellungen abzuschließen.",
            "h4": "Was passiert bei überfälliger Zahlung?",
            "p1": "1. Ihre Bestellungen könnten storniert werden",
            "p2": "2. Ihr Konto könnte einen Zahlungsausfall erleiden",
            "h5": "Bitte schließen Sie Ihre Zahlung so schnell wie möglich ab,<br />um Ihre Kunden zufrieden zu stellen!",
            "no_show": "Dies nicht mehr anzeigen",
            "make_payment": "Zahlung vornehmen",
            mark_as_paid: 'Mark as paid'
        },
        "product_error_msg": {
            "can_not_shipped_this_country": "Das Produkt kann nicht in dieses Land verschickt werden. Fügen Sie einen anderen AliExpress-Link hinzu."
        },
        "options_different_tip": "Hinweis: Die Option Variante stimmt nicht überein, bitte überprüfen Sie dies.",
        "roll_back_confirm_title": "Sind Sie ganz sicher, dass Sie diese Aktion durchführen möchten?",
        "roll_back_confirm_tip": "Bitte geben Sie BESTÄTIGEN ein, um Bestellungen zurückzunehmen.",
        "roll_back_awaiting_shopify_hook_tip": "DSers führt die Shopify-Bestellung(en) jetzt nicht aus. Die Bestellung(en) werden in wenigen Sekunden in die Registerkarte Wartende Bestellung verschoben, bitte warten Sie.",
        "no_data": {
            "no_data": "Keine Daten",
            "title": "Ihre Suche kann aus einem der folgenden Gründe kein Ergebnis liefern:",
            "p4": "1. wenn Sie die Bestellung auf Shopify archiviert haben, überprüfen Sie bitte das Menü Archiviert in DSers, um die Bestellung zu finden",
            "p1": "2.die von Ihnen eingegebenen Informationen ungenau sind",
            "p2": "3. die Suche liegt nicht innerhalb des aktuell eingestellten Zeitraums",
            "p3": "4. die Suche kann ein verstecktes Produkt enthalten",
            Pendings: {
                title: 'Pending orders will be displayed here',
                detail: `Orders in the Pending tab are orders for which the payment from your customers has not yet been captured in Shopify.`
            },
            AwaitingOrders: {
                title: 'Orders awaiting to be placed will be displayed here',
                detail: `Orders in the Awaiting order tab are orders for which the payment from your customers has been captured on Shopify and are waiting to be processed to Tmall.`
            },
            AwaitingPayments: {
                title: 'Awaiting payment orders will be displayed here',
                detail: `Orders in the Awaiting payment tab are orders that have been placed on Tmall but not paid yet. `
            },
            AwaitingShipments: {
                title: 'Awaiting shipment orders will be displayed here ',
                detail: `Orders in the Awaiting shipment tab are orders that have been paid on Tmall but have not been shipped yet.`
            },
            AwaitingFulfillments: {
                title: 'Awaiting fulfillment orders will be displayed here',
                detail: 'Orders in the Awaiting fulfillment tab are orders only partially fulfilled.'
            },
            Fulfilleds: {
                title: 'Fulfilled orders will be displayed here',
                detail: `Orders in the Fulfilled tab are orders that have been shipped out by your Tmall supplier and orders that were automatically or manually fulfilled.`
            },
            Canceleds: {
                title: 'Canceled orders will be displayed here',
                detail: 'Orders in the Canceled tab are orders that have been (partially) canceled / (partially) refunded on Tmall or Shopify.'
            },
            FailedOrders: {
                title: 'Failed orders will be displayed here',
                detail: 'Orders in the Failed tab are orders which could not be placed to Tmall successfully due to errors or restrictions.'
            },
            "Pending": {
                "title": "Hier werden ausstehende Bestellungen angezeigt ",
                "detail": "Bestellungen im Reiter Ausstehend sind Bestellungen, für die die Zahlungen Ihrer Kunden noch nicht in Shopify verbucht wurden. Klicken Sie <a href={href} target=\"_blank\">hier</a>, um mehr über Bestellungen zu erfahren, die im Reiter Ausstehend angezeigt werden."
            },
            "AwaitingOrder": {
                "title": "Bestellungen, die sich in Bearbeitung befinden, werden hier angezeigt ",
                "detail": "Bestellungen im Reiter Ausstehende Bestellungen sind Bestellungen, für die die Zahlung Ihrer Kunden in Shopify verbucht wurde und die darauf warten, von AliExpress bearbeitet zu werden. Klicken Sie <a href={href} target=\"_blank\">hier</a>, um mehr über Bestellungen zu erfahren, die im Reiter Ausstehende Bestellungen angezeigt werden."
            },
            "AwaitingPayment": {
                "title": "Hier werden Bestellungen angezeigt, die auf eine Zahlung warten ",
                "detail": "Bestellungen auf dem Reiter Auf Zahlung warten sind Bestellungen, die auf AliExpress aufgegeben, aber noch nicht bezahlt wurden. Klicken Sie <a href={href} target=\"_blank\">hier</a>, um mehr über Bestellungen auf dem Reiter Auf Zahlung warten zu erfahren."
            },
            "AwaitingShipment": {
                "title": "Bestellungen, die auf den Versand warten, werden hier angezeigt ",
                "detail": "Bestellungen auf dem Reiter Auf Versand warten sind Bestellungen, die auf AliExpress bezahlt wurden, aber noch nicht versendet wurden. Klicken Sie <a href={href} target=\"_blank\">hier</a>, um weitere Informationen zu Bestellungen, die auf den Versand warten, zu erhalten."
            },
            "AwaitingFulfillment": {
                "title": "Befehle, die auf ihre Ausführung warten, werden hier angezeigt ",
                "detail": "Befehle auf dem Reiter Auf Ausführung warten sind teilweise ausgeführte Befehle. Klicken Sie <a href={href} target=\"_blank\">hier</a>, um mehr über die Befehle auf dem Reiter Auf Ausführung warten zu erfahren."
            },
            "Fulfilled": {
                "title": "Der Tab Ausgeführte Befehle erscheint hier ",
                "detail": "Bestellungen auf dem Tab Ausgeführt sind Bestellungen, die von Ihrem AliExpress-Lieferanten versendet wurden, sowie Bestellungen, die automatisch oder manuell ausgeführt wurden. Klicken Sie <a href={href} target=\"_blank\">hier</a>, um mehr über Bestellungen auf dem Reiter Ausgeführt zu erfahren."
            },
            "Canceled": {
                "title": "Stornierte Bestellungen werden hier angezeigt",
                "detail": "Bestellungen auf dem Reiter Storniert sind Bestellungen, die auf AliExpress oder Shopify (teilweise) storniert / (teilweise) erstattet wurden. Klicken Sie <a href={href} target=\"_blank\">hier</a>, um mehr über Bestellungen auf dem Reiter Storniert zu erfahren."
            },
            "FailedOrder": {
                "title": "Gescheiterte Bestellungen werden hier angezeigt",
                "detail": "Bestellungen auf dem Tab Gescheitert sind Bestellungen, die aufgrund von Fehlern oder Einschränkungen nicht an AliExpress gesendet werden konnten. Klicken Sie <a href={href} target=\"_blank\">hier</a>, um mehr über Bestellungen auf dem Reiter Gescheitert zu erfahren."
            }
        },
        agency_open_no_data: {
            Pending: {
                title: 'Pending orders will be displayed here',
                detail: `Orders in the Pending tab are orders for which the payment from your customers has not yet been captured in Shopify. `
            },
            AwaitingOrder: {
                title: 'Orders awaiting to be placed will be displayed here',
                detail: `Orders in the Awaiting order tab are orders for which the payment from your customers has been captured on Shopify and are waiting to be processed to agencies. `
            },
            AwaitingPayment: {
                title: 'Awaiting payment orders will be displayed here',
                detail: `Orders in the Awaiting payment tab are orders that you need to make payment to agencies.`
            },
            AwaitingShipment: {
                title: 'Awaiting shipment orders will be displayed here ',
                detail: `Orders in the Awaiting shipment tab are orders that paid to suppliers through DSers, or marked as paid manually and awaiting for shipment.`
            },
            AwaitingFulfillment: {
                title: 'Awaiting fulfillment orders will be displayed here',
                detail: 'Orders in the Awaiting fulfillment tab are orders only partially fulfilled.'
            },
            Fulfilled: {
                title: 'Fulfilled orders will be displayed here',
                detail: `Orders in the Fulfilled tab are orders that have been shipped out by your agencies and orders that were automatically or manually fulfilled. `
            },
            Canceled: {
                title: 'Canceled orders will be displayed here',
                detail: 'Orders in the Canceled tab are orders that have been (partially) canceled / (partially) refunded on Shopify or by agencies. '
            },
            FailedOrder: {
                title: 'Failed orders will be displayed here',
                detail: 'Orders in the Failed tab are orders which could not be placed to agencies successfully due to errors or restrictions.'
            }
        },
        "open_no_data": {
            "title": "Sie können Ihre erste Bestellung bei AliExpress nicht finden?",
            "p1": "2. Bitte überprüfen Sie die Registerkarte  Erledigt , um die Bestellung(en) zu finden;",
            "p2": "3. Wenn Sie die Bestellung(en) auf der Registerkarte Erledigt finden;",
            "p3": "4. Bitte gehen Sie zu Shopify - Einstellungen - Check-out und deaktivieren Sie die Funktion zur automatischen Erfüllung wie unten gezeigt;",
            "p4": "5. Bitte klicken Sie auf Speichern, um die Einstellungen zu aktualisieren;",
            "p5": "6. Gehen Sie auf die Registerkarte Erfüllt und klicken Sie auf die Schaltfläche Weitere Aktion, um eine ausgewählte erfüllte Bestellung zurückzunehmen.",
            "p6": "1. Wenn Sie die Bestellung auf Shopify archiviert haben, überprüfen Sie bitte das Menü Archiviert in DSers, um die Bestellung zu finden;",
            "Pending": "Klicken Sie <a href={href} target=\"_blank\">hier</a>, um mehr über Bestellungen zu erfahren, die im Reiter Ausstehend angezeigt werden.",
            "AwaitingOrder": "Klicken Sie <a href={href} target=\"_blank\">hier</a>, um mehr über Bestellungen zu erfahren, die im Reiter Ausstehende Bestellungen angezeigt werden.",
            "AwaitingPayment": "Klicken Sie <a href={href} target=\"_blank\">hier</a>, um mehr über Bestellungen auf dem Reiter Auf Zahlung warten zu erfahren.",
            "AwaitingShipment": "Klicken Sie <a href={href} target=\"_blank\">hier</a>, um weitere Informationen zu Bestellungen, die auf den Versand warten, zu erhalten.",
            "AwaitingFulfillment": "Klicken Sie <a href={href} target=\"_blank\">hier</a>, um mehr über die Befehle auf dem Reiter Auf Ausführung warten zu erfahren.",
            "Fulfilled": "Klicken Sie <a href={href} target=\"_blank\">hier</a>, um mehr über Bestellungen auf dem Reiter Ausgeführt zu erfahren.",
            "Canceled": "Klicken Sie <a href={href} target=\"_blank\">hier</a>, um mehr über Bestellungen auf dem Reiter Storniert zu erfahren.",
            "FailedOrder": "Klicken Sie <a href={href} target=\"_blank\">hier</a>, um mehr über Bestellungen auf dem Reiter Gescheitert zu erfahren."
        },
        "dsers_product_deleted": "Produkt auf DSers gelöscht, klicken Sie bitte <a href={href} target=\"_blank\" > hier</a>, um zu prüfen, wie die Bestellung auf DSers bearbeitet werden kann.",
        "variant_delete_tip": "Eine Variante wurde in Shopify gelöscht. Klicken Sie auf Mapping, um einen Lieferanten für die gelöschte Variante zuzuordnen.",
        "YANWEN_JYT_shipping_tip": "Die gewählte Versandart ist auf AliExpress nicht mehr verfügbar. Bitte prüfen Sie Ihren Lieferanten und wählen Sie eine andere Versandart.",
        "err_deleted_in_dsers": "Dieses Produkt wurde auf DSers gelöscht, bitte klicken Sie <a href={href} target=\"_blank\"> hier</a>, um zu prüfen, wie Sie die Bestellung auf DSers bearbeiten können",
        "pending_tip": "Bitte vergewissern Sie sich, dass Sie die Zahlung der Bestellung(en) auf Shopify erfasst haben. Wenn eine Bestellung noch in Pending ist, nachdem Sie die Zahlung erfasst haben, überprüfen Sie bitte",
        "pending_tip_end": "um den Bestellstatus manuell zu aktualisieren.",
        "ae_sync_not_done": "Eine Synchronisierung wird gerade durchgeführt und ist noch nicht abgeschlossen.",
        "ae_sync_more_then_5": "Sie können nicht mehr als 5 Mal pro Tag manuell synchronisieren.",
        "select_shipping_method_tip": "Bitte wählen Sie eine Versandart aus.",
        "ful_with_tracking": "Abgewickelt mit einer Trackingnummer",
        "ful_by_shopify": "Abgewickelt durch Shopify",
        "ful_manually": "Manuell über DSers abgewickelt",
        "partial_completion_of_order": "Ihr Lieferant hat mehrere Pakete für dieses Produkt verschickt. Wir werden die Bestellung erfüllen, sobald alle Kontrollnummern synchronisiert sind.",
        "ae_splited_order": "Die Bestellung ist derzeit in mehrere AliExpress-Bestellungen aufgeteilt. Wenn es einen Unterschied in den Bestellnummern gibt, überprüfen Sie diese bitte und ändern Sie sie manuell.",
        "freight_not_supported": "Dieses Land unterstützt die gewählte Versandart nicht, bitte wählen Sie eine andere. Klicken Sie <a target=\"_blank\" href=\"https://www.dsers.com/blog/aliexpress-shipping-methods-changes/\" rel=\"noopenner noreferrer\" >hier</a>, wenn Sie Hilfe benötigen.",
        "edit_sp_tooltip": "Sie haben die Bestellung bearbeitet, indem Sie auf Aktion - Bearbeiten geklickt haben Wenn Sie den Lieferanten dieser Bestellung ändern möchten, verwenden Sie bitte die Funktion Aktion - Bearbeiten.",
        "dsers_action_edit": "DSers Aktion Bearbeiten",
        "variant_delete_tag": "Shopify Gelöschte Variante",
        "go_ae_check_order": "Zu AliExpress gehen",
        "may_be_fake_failed_order1": "Ihre Bestellung",
        "may_be_fake_failed_order2": "wurde möglicherweise erfolgreich bei AliExpress aufgegeben. Um einen Verlust zu vermeiden, überprüfen Sie bitte auf AliExpress",
        "order_fulfill": "Auftragserfüllung fehlgeschlagen,",
        "token_authorize_info": "Das aktuelle Shop-Token ist abgelaufen,",
        "order_pending": "Die Bestellung wird gerade ausgeführt",
        "token_authorize_info_btn": "bitte klicken Sie auf die Einstellung, um die Bestellung erneut zu autorisieren",
        "order_pending_btn": "Bitte warten Sie auf das Ergebnis der Auftragsabwicklung",
        "archive": "Archivieren",
        "order_onHold_info": "Manually fulfill orders",
        "order_onHold_info_tip": "Synchronizing",
        "order_onHold_snync_errorMess": 'Your order is on hold in Shopify and cannot be automatically fulfilled. Please go to Shopify to change the on hold status and try again manually',
        "order_onHold_snync_tip": "Click this button to sync tracking numbers to your store and fulfill this order on Shopify",
        "test_order_tip": "This order is a test order, and the generated order number is a virtual order number",
        "conada_error": "Please enter your full name as it appears on your ID. Click <a target=\"_blank\" href=\"https://help.dsers.com/orders-to-canada-specifications-for-shopify/\">here</a> to learn how to solve this issue.",
        "cl_rut_errtip": "RUT might be wrong. If you checked it, please ignore this message. Please go to AliExpress to double check.",
        "cl_rut_errtipNo": "Please enter 2-12 digits or letters, such as 12123234-K",
        "rut_banner": {
            "title": "Orders to Chile RUT number requirements",
            "btn_text": "learn more"
        },
        "err_may_be_listed_5": "\n      1. Sie können nur eine bestimmte Menge dieses Produkts kaufen. Bitte kontaktieren Sie den Lieferanten, um dies zu überprüfen, oder wechseln Sie den Lieferanten für dieses Produkt, um die Bestellung aufzugeben.<br/>\n      2. The order will ship to France and the supplier's product value exceeds 150 EURO, please change the supplier or apply to be a Business Buyer on your Aliexpress account.<br/>\n      3. The supplier's product link is unavailable.\n      4.Die Bestellung enthält AliExpress-Lieferanten, die unterschiedliche Währungen für die Zahlung benötigen, bitte geben Sie die Artikel separat auf.\n      ",
        "err_address_yg": "It is recommended that you replace \"{country}\" with United Kingdom in the Country field. Fill in \"{countrys}\" in the Province field",
        "ae_code_11_error3": "The system detected that your AliExpress account cannot place orders normally, please change the linked AliExpress account (click <a href=\"/app/settings?tabChangeSta=14\">here</a> to change).",
        "ae_code_11_error4": "Bei Ihrer Bestellung ist ein unbekannter Fehler aufgetreten, bitte kontaktieren <a href=\"https://www.messenger.com/t/DSersOfficial\" dd=\"src\" target=\"_blank\">Sie den Kundenservice</a>.",
        ae_code_11_error4_extension: `Your order encountered an unknown error or you manually terminated the order, if you have any question, please <a href="https://www.messenger.com/t/DSersOfficial" dd="src" target="_blank">contact customer service</a>.`,
        "ae_code_14_error": "Die von Ihnen gewählte Versandart wird nicht unterstützt, bitte ändern Sie die Versandart",
        ae_code_27_error: `Due to AliExpress interface limitation, DSers doesn't support shipping to the destination at the moment. Please manually place the order(s) on AliExpress and fill in the AliExpress order number on the order on DSers.`,
        "order_reault_await_title": "Placing order(s)...",
        "order_reault_succ_title": "Order(s) placed",
        "order_reault_checkfailed1": "Nicht ausgeführte Bestellungen prüfen",
        "order_reault_fail_title": "Some order(s) failed",
        "sync_last_chan": "AE order sync",
        "sync_time_last_chan": "Last syncing time",
        "sync_time_next_chan": "Next syncing time",
        "flags": {
            "title": "Flags",
            "None": "None",
            "Grey": "Grey",
            "Blue": "Blue",
            "Green": "Green",
            "Yellow": "Yellow",
            "Red": "Red"
        },
        "shopify_partially_refunded": "Shopify teilweise zurückerstattet",
        "place_via_extension": {
            "caution": "Caution",
            "ae_account_not_match": "Wir haben festgestellt, dass das AliExpress-Konto, in dem Sie derzeit angemeldet sind, nicht mit dem mit DSers verknüpften Konto übereinstimmt. Um sicherzustellen, dass der Status Ihrer AliExpress-Bestellung mit DSers synchronisiert werden kann, verknüpfen Sie Ihr derzeit angemeldetes AliExpress-Konto mit DSers oder geben Sie eine Bestellung auf dem mit DSers verknüpften AliExpress-Konto auf.",
            "login_ae_account_disabled": "",
            "order_via_api_opt": "Order via API (Place the orders faster)",
            "order_via_ext_opt": "Order via Chrome Extension (Automatically use received coupons)",
            "ext_not_installed_title": "DSers Chrome Extension needs to be installed",
            "ext_not_installed_content": "Check if you have installed the DSers Chrome Extension installed and click Add DSers Chrome Extension below to go to the Chrome Web store page to install it, then return to this page to try again.",
            "add_dsers_ext": "Add DSers Chrome Extension",
            "order_via_api": "Order via API",
            "order_is_processing": "Ordering via extension, please do not close the page or the browser",
            "not_operate_title": "Do not perform the following operations",
            "not_operate_1": "Exit/switch DSers account;",
            "not_operate_2": "Close/uninstall DSers plug-in;",
            "not_operate_3": "Operate AliExpress shopping cart;",
            "not_operate_4": "Exit AliExpress account.",
            "failed_order_exsits": "The ordering process is over. Some may have failed. Please check on AliExpress to avoid any loss. If the order was successful, please fill in the order number manually. If the order fails, please place the order again.",
            "abort_task_btn": "Terminate all tasks",
            "abort_task_modal_title1": "Confirm to terminate all current order tasks?",
            "abort_task_modal_content1": "After confirming the termination of all orders, orders that have not been placed will appear in the Failed Order tab. You can order them again later.",
            "confirm_btn": "Confirm",
            "cancel_btn": "Cancel",
            "abort_task_modal_title2": "Order task terminated",
            "abort_task_modal_content2": "({value}) orders have been stopped, these orders will appear in the Failed Order tab",
            "abort_got_it": "Got it",
            "error_tip_ae_logout": "Your AliExpress account was logged out during the ordering process, please log in to your AliExpress account and try again while being logged in.",
            "error_tip_action_timeout": "Anfrage wurde abgebrochen, bitte versuchen Sie es erneut.",
            "error_tip_cart_error": "Hinzufügen zum Warenkorb fehlgeschlagen, bitte versuchen Sie es erneut.",
            "error_tip_unknown": "Bestellung fehlgeschlagen, bitte wenden Sie sich an den Kundendienst, um dieses Problem zu lösen.",
            "error_tip_manually_close_1": "Die Bestellung ist fehlgeschlagen, weil Sie die Bestellseite manuell geschlossen haben. Bitte versuchen Sie, die Bestellung erneut aufzugeben.",
            "error_tip_manually_abort": "You have manually aborted the order task.",
            "error_tip_manually_close_2": "Die Bestellseite wurde geschlossen oder die Registerkarte der Bestellseite wurde verschoben. Bitte versuchen Sie es erneut.",
            "on_hold_error": "Sie können die Bestellung nicht aufgeben, da die Bestellung in Shopify auf \"On hold\" gesetzt ist. Bitte ändern Sie den Bestellstatus in Shopify und geben Sie dann die Bestellung bei DSers auf.",
            "request_fulfill_error": "Die Erfüllung der Bestellung wurde auf Shopify angefordert. Die Funktion \"Erfüllung anfordern\" entspricht nicht der Art und Weise, wie DSers Bestellungen verarbeitet. Bitte ändern Sie die Bestellung entsprechend der Anleitung in diesem Blog. Klicken <a href=\"https://help.dsers.com/re-order-request-fulfillment-order-for-shopify/\">Sie hie</a>, um weitere Informationen zu erhalten",
            "shipping_error": "There is no shipping method applied to this order, please select manually. Or you can go to Settings--Shipping to set up your favorite shipping method, then DSers will select it automatically",
            "mapping_error": "Sie müssen die Produkte zuordnen, damit DSers weiß, mit welchem Lieferanten Sie zusammenarbeiten, bevor Sie eine Großbestellung aufgeben.",
            "shipping_error_set": "The shipping method you set based on the product in the settings is not supported by the supplier anymore, so the order cannot be placed without a default shipping method. You can modify the shipping method in the settings, or manually modify it in the order",
            "on_hold_error_item": "Sie können die Bestellung nicht aufgeben, da die Bestellung in Shopify auf \"On hold\" gesetzt ist. Bitte ändern Sie den Bestellstatus in Shopify und geben Sie dann die Bestellung bei DSers auf.",
            "request_fulfill_error_item": "Die Erfüllung der Bestellung wurde auf Shopify angefordert. Die Funktion \"Erfüllung anfordern\" entspricht nicht der Art und Weise, wie DSers Bestellungen verarbeitet. Bitte ändern Sie die Bestellung entsprechend der Anleitung in diesem Blog. Klicken <a href=\"https://help.dsers.com/re-order-request-fulfillment-order-for-shopify/\">Sie hie</a>, um weitere Informationen zu erhalten",
            "shipping_error_item": "Für diese Bestellung wurde keine Versandart ausgewählt. Bitte überprüfen Sie die <a href=\"/app/settings\">Versandeinstellungen </a> auf DSers oder die Verfügbarkeit von Versandmethoden auf AliExpress.",
            shipping_error_agency: 'There is no shipping method applied to this order, please select manually.',
            error_tip_product_captcha: 'Ihr Netzwerk ist abnormal, bitte überprüfen Sie es auf AliExpress und bestellen Sie erneut.',
        },
        "disappear_order_tip": "There is a problem with some of your orders, if you need to retrieve them, please <a class=\"ds-link\" target=\"_blank\" href=\"https://www.messenger.com/t/DSersOfficial\"\">contact us.</a>",
        "order_code_24_error": "This order failed due to a network error, please try to place the order again",
        "tmall_sku1_error": 'Die Produkt SKU hat sich geändert, bitte überprüfen Sie Ihre Mapping Details',
        "tmall_sku2_error": `Die ursprüngliche SKU ist nicht vorhanden, Sie müssen neu zuordnen oder <a href=\"{url}\" target=\"_blank\">den Lieferanten ändern</a>, sonst können Sie die Bestellung nicht erfolgreich aufgeben.`,
        tmall_sku3_error: `The original SKU does not exist or changed, you need to re-map or change the supplier, otherwise you can't place the order successfully.`,
        "note_title_y": "Notiz hinzufügen",
        "note_desc_y": "Notiz ist nur in DSers sichtbar",
        "note_tip_y": "Die Anzahl der Zeichen im Eingabefeld hat das Limit überschritten, bitte löschen Sie den Inhalt und speichern Sie.",
        "aeProcess": 'Process Orders',
        "aeProcessSub": `Wenn Sie die ausgewählten Bestellungen zurücksetzen, werden sie auf die Registerkarte "Wartende Bestellung" verschoben. Die alten AliExpress-Bestellinformationen gehen nach der Rückgängigmachung verloren.  <span style="color:red"> Bitte beachten Sie, dass diese Bestellungen in Shopify auf "nicht erfüllt" geändert werden. Hinweis: Aufgrund der Shopify-Richtlinien können wir nur Bestellungen aus den letzten zwei Monaten bearbeiten.</span>`,
        "confirm_modal_up": {
            "fulfill_title": "Aufträge manuell ausfüllen",
            "fulfill_confirm": "BESTÄTIGEN",
            "rollback_title": "Roll Back Orders",
            "rollback_input_confirm_value": "Sind Sie sicher, dass Sie diese Bestellungen manuell AUSFÜLLEN wollen?",
            "note_cancel": "CANCEL",
            "node_save": "SAVE",
            "fulfill_desc": "Sind Sie sicher, dass Sie diese Bestellungen manuell AUSFÜLLEN wollen? Bitte beachten Sie, dass DSers die Synchronisierung der Bestellinformationen für die Bestellungen, die auf AliExpress aufgegeben wurden, beendet.",
            "fulfill_desc_tm": "Are you sure you want to manually FULFILL these orders? Please note that DSers will stop syncing order information for the orders that have been placed on Tmall."
        },
        orderQuantityToolTip: {
            "title": "Es gibt noch {items} Artikel in den anderen Bestellmenüs",
            "aliexpress": "AliExpress-Bestellungen",
            "agency": "Agentur-Bestellungen",
            "unmapped": "Nicht kartierte Aufträge"
        },
        "aeCancelTip": `Aufgrund der neuen Regelung von AliExpress kann eine Bestellung, die eine gefälschte Tracking-Nummer verwendet, von AliExpress storniert werden. Bitte nehmen Sie diese Art von Bestellung in der Registerkarte "Erfüllt" manuell zurück.`,
        editActionTip: `Da Sie den Lieferanten der Bestellung geändert haben, wird der Artikel oder die Bestellung auf die Seite {platformType} Orders verschoben`,
        edit_order_confirm_tip_1: `Sie haben die Funktion "Bearbeiten" verwendet, um den Lieferantentyp des Artikels zu ändern. Bitte gehen Sie zu {platform} - Registerkarte "{tab}", um den Artikel anzuzeigen.`,
        edit_order_confirm_tip_2: `Sie haben die Funktion "Bearbeiten" verwendet, um den Lieferantentyp einiger Artikel zu ändern. Bitte gehen Sie zu {platform} - Registerkarte "{tab}", um den Artikel anzuzeigen.`,
        edit_order_confirm_tip_var: {
            aliexpress: 'AE',
            tmall: 'Tmall',
            both: 'AE / Tmall',
            awaiting_order: 'Warten auf Bestellung',
            canceled_order: 'Stornierte',
            failed_order: 'gescheiterte Bestellung',
        },
        orderPayment: {
            timeout: 'Zahlung fehlgeschlagen. Die Zahlung wurde abgebrochen, bitte führen Sie die Zahlung erneut durch.',
            cancel: 'Zahlung fehlgeschlagen. Ihre Zahlung wurde storniert, bitte leiten Sie die Zahlung erneut ein.',
            unknow: 'Die Zahlung ist fehlgeschlagen. Unbekannter Grund, bitte kontaktieren Sie den DSers-Support, um dieses Problem zu lösen.',
            checkDetail: 'Details prüfen',
            link: 'Link zur Zahlung',
            loading: 'Die Zahlung, die Sie um {time} begonnen haben, wird bezahlt.',
            success: 'Die Zahlung, die Sie um {time} begonnen haben, wurde erfolgreich durchgeführt.',
            error: 'The payment you started at {time} is failed.',
            pendingTip: 'Wenn Sie die Zahlung für diese Bestellung stornieren möchten, stornieren Sie die Zahlung bitte über den Zahlungslink.',
            reason: 'failed reason',
            buttonTip: 'Make your payments to complete your orders. Please complete your payment asap to satisfy your customers!',
            orderOneButtonTip: 'Zahlung vornehmen'
        },
        paymentModalTitle: 'Bitte überprüfen Sie die Bestellinformationen, bevor Sie bezahlen.',
        orderPageGuidance:'Die Bestellung wurde synchronisiert. Bitte gehen Sie zur Seite "Offene Bestellungen", um ausstehende Bestellungen zu bearbeiten.'
    }
}