
    export default 
    {
    "top": {
        "synchronizing_store": "Filiale synchronisieren...",
        "top_select_store": "Alle Filialen",
        "select_store": "Geschäft auswählen",
        "link_to_aliExpress": "Link zu AliExpress",
        "bind_ali_tip": "Klicken Sie hier für den Link zu AliExpress",
        "over_bind": "AliExpress-Konto auf der Whitelist",
        "over_bind_tip": "Sie haben Ihr AliExpress-Konto bereits an DSers gebunden und ein Upgrade auf die Whitelist vorgenommen",
        "apply_whitelist": "Whitelist beantragen",
        "timeout_tip": "Klicken Sie hier, um ein AliExpress-Whitelist-Konto zu erhalten",
        "link_shopify_tip": "Klicken Sie hier, um mehrere Shopify-Shops zu verknüpfen.",
        "link_to_more_stores": "Link zu weiteren Shops",
        "link_to_shopify_store": "Link zum Shopify-Shop",
        "bind_ae_title": "Re-authorize AliExpress",
        "bind_ae_content": "The AliExpress whitelisted account you have linked to DSers is about to expire. Please re-authorize AliExpress account. If you have any questions, please  <a href='https://www.messenger.com/t/DSersOfficial' target=\"_blank\" >contact us</a>",
        "bind_ae_expire": "Das AliExpress-Whitelist-Konto, das Sie an DSers gebunden haben, läuft in Kürze ab. Bitte binden Sie Ihr AliExpress-Konto erneut.",
        "whitelist_expire": "Das AliExpress-Whitelist-Konto, das Sie an DSers gebunden haben, läuft in Kürze ab. Bitte binden Sie Ihr AliExpress-Konto erneut.",
        "update_title": "Autorisierungs-Updates",
        "update_desc": "DSers hat festgestellt, dass Ihre folgenden Konten die Autorisierung für DSers aktualisieren müssen,<br /> da Sie sonst DSers nicht richtig nutzen können",
        "update_desc_is_white": "DSers hat festgestellt, dass Ihre unten aufgeführten Konten auf die <a href={href} target=\"_blank\" > AliExpress-Whitelist </a> gesetzt werden müssen. Bitte klicken Sie oben auf der Seite auf die Schaltfläche Whitelist anwenden, um die Autorisierung zu aktualisieren. Andernfalls können Sie DSers nicht richtig nutzen.",
        "update_btn": "aktualisieren.",
        "contact_by_email": "Wenn Sie Fragen haben, wenden Sie sich bitte an <span>support@dsers.com</span>.",
        "ae_account_linked": "AliExpress-Konto Verknüpft",
        "bind_ae_intro": "Nur noch ein Schritt bis zur Verwaltung Ihrer Produkte und Bestellungen mit DSers",
        "bind_ae_outdated_intro": "Die Autorisierung des AliExpress-Kontos läuft alle 3 Monate ab. Bitte klicken Sie auf Link zu AliExpress, um die Autorisierung zu erneuern.",
        "bindaeTip": "Aliexpress Bestellstatus nicht aktualisiert",
        "bindaeTipDetail": "DSers können den AliExpress-Bestellstatus nicht abrufen, weil Sie Ihr AliExpress-Konto nicht verknüpft haben. Bitte verknüpfen Sie Ihr AliExpress-Konto so bald wie möglich.",
        "binaeBtn": "LINK AIEXPRESS",
        "ChristmasTheme":'Tmall Thema',
        "RegularTheme":'Normales Thema'
    }
}