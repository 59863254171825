import React, { useState } from 'react';
import { connect } from 'dva';
import { Input, Modal, Form, message, Button } from 'antd';
import intl from 'react-intl-universal';

import { checkPassword } from '../../until';

import styles from './ResetPassword.less';

const changePasswordModule = ({ visible, dispatch, handleCancel, form }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [confirmDirty, setConfirmDirty] = useState();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [sendLoading, setSendLoading] = useState(false);
  const { getFieldDecorator } = form;
  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        // eslint-disable-next-line no-console
        console.log('Received values of form: ', values);
      }
    });
    const list = ['pwd', 'new_pwd', 'confirm'];
    const errorList = form.getFieldsError(list);
    // eslint-disable-next-line compat/compat
    const newErrorList = Object.values(errorList).filter(
      item => item !== undefined
    );
    if (newErrorList.length !== 0) {
      return;
    }
    const subValue = form.getFieldsValue();
    setSendLoading(true);
    dispatch({
      type: 'partner/ChangePassword',
      payload: {
        data: subValue,
        callback: d => {
          setSendLoading(false);
          if (d.code === 2000) {
            message.success(d.msg);
            handleCancel();
          }
        }
      }
    });
  };
  const handleConfirmBlur = e => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  };

  const compareToFirstPassword = (rule, value, callback) => {
    if (!value) {
      callback(intl.get('partner.reset_pass_word.new_pwd_value_no_tips'));
    } else if (value && value !== form.getFieldValue('new_pwd')) {
      callback(
        intl.get('partner.reset_pass_word.confirm_new_pwd_contrast_tips')
      );
    } else {
      callback();
    }
  };
  const handleCancelModule = () => {
    form.resetFields();
    handleCancel();
  };
  return (
    <Modal
      visible={visible}
      footer={null}
      width={600}
      maskClosable={false}
      className={styles.setPass_modal}
      onCancel={handleCancelModule}
    >
      <Form onSubmit={handleSubmit}>
        <h1 className={styles.title}>
          {intl.get('partner.reset_pass_word.title')}
        </h1>
        <Form.Item labelCol={{ span: 0 }} wrapperCol={{ span: 24 }}>
          {getFieldDecorator('pwd', {
            rules: [
              {
                validator: (rule, value, callback) => {
                  if (!value) {
                    // callback('Please input your Old password!');
                    callback(
                      intl.get('partner.reset_pass_word.old_pwd_value_no_tips')
                    );
                  } else {
                    callback();
                  }
                }
              }
            ]
          })(
            <Input.Password
              size="large"
              type="password"
              autoComplete="new-password"
              placeholder={intl.get(
                'partner.reset_pass_word.old_pwd_placeholder_tips'
              )}
              v
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('new_pwd', {
            rules: [
              {
                validator: (rule, value, callback) =>
                  checkPassword(rule, value, callback)
              }
            ]
          })(
            <Input.Password
              size="large"
              type="password"
              autoComplete="new-password"
              placeholder={intl.get(
                'partner.reset_pass_word.new_pwd_placeholder_tips'
              )}
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('confirm', {
            rules: [
              {
                validator: compareToFirstPassword
              }
            ]
          })(
            <Input.Password
              size="large"
              type="password"
              autoComplete="new-password"
              onBlur={handleConfirmBlur}
              placeholder={intl.get('partner.signUp.confirm_Password')}
            />
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={sendLoading}>
            {intl.get('partner.reset_pass_word.title')}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default connect()(Form.create()(changePasswordModule));
