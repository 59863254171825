export default {
  public: {
    facebook: 'Facebook',
    youtube: 'YouTube',
    cancel: 'Cancelar',
    yes: 'Sim',
    no: 'Não',
    ok: 'Ok',
    save: 'Salvar',
    newsave:'SAVE',
    submit: 'Enviar',
    search: 'Pesquisar',
    confirm: 'Confirmar',
    newconfirm: 'CONFIRM',
    apply: 'Escolher',
    log_out: 'Sair',
    reset_password: 'Redefinir senha',
    delete: 'Deletar',
    got_it: 'Entendido',
    check_more: 'Ver mais',
    more: 'Mais',
    page: 'página',
    access: 'Acessar',
    refresh: 'Atualizar',
    reconnect: 'Reconectar',
    on: 'Ligado',
    off: 'Desligado',
    here: 'aqui',
    Manage: 'Gerenciar assinatura',
    check_it: 'Verificar',
    dismiss: 'Dispensar',
    contact: 'Contato',
    contact_us: 'Contate-nos',
    updata_price:'Update the price of the products pushed to your Shopify store according to the pricing rules you currently set. The process may take some time. This can be used every 30 days.',
    please_enter:'Please enter CONFIRMAR to start the update',
    shopUnavailable:'This store is currently disconnected. Please reconnect it to use this feature',
    InterfaceTransfinite:'Update the price of the products pushed to your Shopify store according to the pricing rules you currently set. The process may take some time. This can be used every 30 days. Due to interface restrictions, the feature will be opened after 24:00, please wait patiently',
    synchronous:`The store's product prices are being updated`,
    useAgain:'Can be used again in',
    day:'days',
    jumpPrice:`Click 
      <a
      href="/app/pricing"
      target="_blank"
      rel="noopener noreferrer"
    >
      here 
    </a>
    to upgrade your plan to be able to manually sync more times`,
    pause:`Synchronization of the day complete,
    The remaining products will be automatically synced tomorrow`,
    goodsTooMuch:'Update the price of the products pushed to your Shopify store according to the pricing rules you currently set. The process may take some time. This can be used every 30 days. Since your store has more than 100,000 items, we will synchronize all your items in multiple days (up to 100,000 per day), please be patient.',
    exceeds:'As your product synchronization exceeds 100,000, this function will be enabled after 24:00, please wait patiently',
    noproduct:'The store has no product that can be updated',
    updateTitle:'Update product price',
    updateText:'Update the price of the products pushed to your Shopify store according to the pricing rules you currently set. The process may take some time. This can be used every 30 days.',
    start:'Start Update',
    unsave:'Unsaved changes',
    checkmodaltext:`The information you modified has not been saved yet. If you want to save, please click "SAVE", if you don't want to save your modifications, click "DISCARD"`,
    discard:'DISCARD',
    choose:"SELECT",
    gotodsers:'Go to DSers Homepage',
    oberlo_data_pending:'Data correction.',
    oberlo_data_success:'Data correction completed.',
    oberlo_data_fail:'Data verification Failed.',
    oberlo_data_try:'TENTAR NOVAMENTE',
    priceruleDis:'Please activate and confirm your <a href="/app/settings?=5" > Pricing Templates </a> settings in Pricing Rule and start to update afterwards.',
    padding_title:'Estamos corrigindo as informações de pedidos e produtos, aguarde',
    migrate_order_tip:'A sincronização do status de alguns pedidos falhou. É possível que a conta AliExpress usada para efetuar esses pedidos seja diferente da conta AliExpress vinculada atualmente. Altere a conta AliExpress vinculada ao DSers para atualizar o status dos pedidos. Em caso de dúvidas, entre em contato com a equipe de suporte DSers.',
    oberlo_product_error_tip:'Verificação de dados de produtos concluída, mas { num } produtos do AliExpress estavam indisponíveis e não foram importados ao DSers. Você precisará mapear manualmente os novos fornecedores. Acesse a aba “Produtos não mapeados” para visualizar.',
    add_report_user_tip:"Os dados estão sendo criados. O relatório será gerado em até 24 horas. Agradecemos pela sua paciência.",
    filter_confirm_des:'CONFIRMAR',
    filter_clear_des:'LIMPAR TODOS OS FILTROS',
    unsaveErr:'Unable to save changes',
    gocheck:'Go check',
    checkmodalerrtext1:'The reason you cannot save is ',
    checkmodalerrtext2:'To fix the issue, you need to re-map the part facing issues, or delete the mapping with problems.',
    checkmodaladvanceerrtext:'In Advanced Mapping, the Ship to selection cannot be repeated on the same variant, otherwise the mapping cannot be saved. ',
    checkmodalbasicrepeaterrtext:'In Basic Mapping, Options cannot be repeated, otherwise the mapping cannot be saved. ',
    checkmodalbasicnullerrtext:'In Basic mapping, you need to select the corresponding variant, otherwise the mapping cannot be saved. ',
    input_guide_text:'The Search or Import field, along with the Filter feature has been moved! You can now find them on the top right corner.',
    input_guide_btn:'Got it',
    importlist_card_guide_text:`We have improved the product card and some features can now be found in 'More Action' in the lower right corner`,
    product_card_guide_text:`We have improved the product card and the mapping function now has a floating display. Some features can now be found in 'More Action' in the lower right corner`,
    search_input_url_error_match_ae_tip:
      'A URL que você inseriu não é uma URL do AliExpress. Verifique e tente novamente.',
    search_input_url_error_match_tm_tip:
      'A URL que você inseriu não é uma URL do Tmall. Verifique e tente novamente.',
    search_input_url_invalid_tip:`A URL inserida é inválida. Verifique e tente novamente.`,
  }
};
