export default {
  banner: {
    title: 'APP Partenaire Officiel Dropshipping AliExpress',
    quick_orders:
      'DSers est la meilleure solution pour traiter toutes vos commande en quelques minutes et sans limites',
    get_started_placeholder: 'Entrez votre adresse email',
    get_started: 'Commencer',
    order_management: 'Gestion de commande',
    order_management_description: `DSers est capable de gérer et placer des Commandes Groupées.
                                   Traiter des centaines de commandes par jour en quelques minutes.
                                   Toutes les commandes sont synchronisées depuis Shopify automatiquement.
                                   Le dropshipper peut ensuite placer toutes ses commandes vers AliExpress avec UN bouton.
                                   Économiser des heures de votre temps!`,
    supplier_management: 'Gestion Fournisseur',
    supplier_management_description: `Définissez plusieurs fournisseurs pour vos produits.
                                      Le fournisseur secondaire sera utilisé UNIQUEMENT si le produit n'est pas disponible chez votre fournisseur par défaut (ex: rupture  de stock).
                                      Des millions de produits et des milliers de fournisseurs signifie un stock illimité sur AliExpress.
                                      Ne soyez jamais en rupture de stock! (Une nouvelle fonction bientôt disponible vous permettra de trouver le fournisseur le moins cher!).`,
    csv_bulk_orders: 'Commandes Groupées par CSV',
    csv_bulk_orders_description: `Profitez de DSers et placez des commandes groupées d'un coup peu importe la plateforme que vous utilisez.
                                  Créez et téléchargez la base de données de vos produits,
                                  Importez vos commandes à placer et placer les toutes en même temps! Aussi simple que ça.
                                  Gagnez plus de temps que jamais avec DSers.`,
    save_97_ordering_time: 'Gagnez 97% de temps sur le placement des commandes',
    how_does_work: {
      title: 'Comment fonctionne DSers pour les dropshippers?',
      easy: 'Facile à lancer, facile à maitriser.',
      multiple_store: 'Multiples boutiques',
      multiple_store_dec: 'Gérez plusieurs boutiques Shopify',
      supplier_management: 'Gestion Fournisseur',
      supplier_management_dec: 'Un produit avec plusieurs fournisseurs',
      multiple_orders: 'Commandes Groupées',
      multiple_orders_dec: 'Traitez des commandes groupées en un clic',
      auto_sync: 'Syncronisation Automatique',
      auto_sync_dec: 'Statut des commandes synchronisé depuis AliExpress'
    },
    feedback: {
      title: 'Vous nous faites confiance',
      feedback: 'Réaction de nos utilisateurs',
      like: 'J\'aime',
      comment: 'Commentaire',
      share: 'Partage',
      comments: 'Commentaires',
      tomas: 'Tomas',
      tomas_time: 'Hier à 4h19',
      tomas_dec:
        'Nous utilisons DSers pour commander sur AliExpress. On a rien trouvé de mieux, merci beaucoup!',
      simon: 'Simon',
      simon_time: '2 Nov 2018 à 11h09',
      simon_dec:
        'DSers est juste géniale! J\'en suis très content et ça me permet de gagner beaucoup de temps et d\'économiser. Rien de mieux que de placer toutes mes commandes en quelques minutes!',
      wilson: 'Wilson',
      wilson_time: '21 Jan 2019 à 21h22',
      wilson_dec:
        'Cet est tout simplement magique!! C\'est juste incroyable de voir les commandes groupées être placé comme ça! Fini le travail manuel!',
      jonathan: 'Jonathan',
      jonathan_time: '30 Mar 2019 à 17h48',
      jonathan_dec:
        'Très très content d\'avoir découvert ce site génial! Traité les commande en un jeu d\'enfant  depuis que j\'utilise DSers.'
    },
    features: {
      title:
        'DSers à toutes les fonctions nécessaire pour votre business dropshipping',
      dec: 'Fonctionnalités clés sur DSers',
      multiple_order_processing: 'Traitement de Commandes Groupées',
      multiple_order_processing_dec: 'Multiple order processing',
      variants_mapping: 'Mapping de Variantes',
      variants_mapping_dec:
        'Ajoutez facilement des variantes à vos produits en ajoutant des fournisseurs',
      shipment_setting: 'Réglage livraison',
      shipment_setting_dec:
        'Définissez vos méthodes de livraison par pays ou prix',
      aliExpress_whitelist: 'Liste Blanche AliExpress',
      aliExpress_whitelist_dec: 'Plus de limite de commandes',
      multiple_stores_manager: 'Gestion de plusieurs boutiques',
      multiple_stores_manager_dec:
        'Gérez plusieurs boutiques avec un seul compte',
      supplier_management: 'Gestion des fournisseurs',
      supplier_management_dec:
        'Ajoutez plusieurs fournisseurs pour un seul produit',
      product_importer: 'Import de produit',
      product_importer_dec: 'Importez des produits avec l\'extension Chrome',
      auto_messaging: 'Message Automatique',
      auto_messaging_dec:
        'Créez un message envoyé automatiquement aux fournisseurs AliExpress',
      email_template: 'Modèle d\'email',
      email_template_dec: 'Notification d\'envoi d\'email bientôt disponible',
      tracking: 'Suivi',
      tracking_dec: 'Information de suivi de livraison précise',
      price_monitor: 'Contrôle des prix',
      price_monitor_dec: 'Soyez notifié des changements de prix sur AliExpress',
      inventory_monitor: 'Contrôle d\'inventaire',
      inventory_monitor_dec:
        'Soyez notifié en cas de rupture de stock sur AliExpress'
    },
    easy_started: 'Complètement gratuit, <br /> et facile à prendre en main',
    partnership: 'Partenariat',
    advabced:'Avancé',
    content:'<span class={title}>20</span> <span class={title}>%</span> <span class={title}>EN MOINS</span>',
    will:"Si vous changez pour le plan Avancé maintenant, vous pourrez: <br/>",
    support:"Ajouter <span class={title}>5</span> boutiques additionelles, <br/> plus de <span class={title}>10,000+</span> produits en plus, <br/> profiter de nouvelles stratégies marketing",
    today:`SEULEMENT AUJOURD'HUI!`,
    btn1:'CHANGE NOW',
    btn2:'Je veux encore réfléchir',
    yes:'CHANGE NOW',
    no:'Je veux rester avec le plan Standard >>',
    btn3:'Ajouter 5 boutiques additionelles | plus de 10000 produit en plus <br/> | nprofiter de nouvelles stratégies marketing |',
    ioss: {
      contact_us: 'CONTACTEZ-NOUS',
      title:
        'Contact votre gestionnaire de compte fournisseur pour obtenir plus d’information sur l’IOSS',
      sub_title: `---- Message concernant la réforme fiscale de l'UE`,
      text: `L'Union Européenne commencera à mettre en œuvre de nouvelles réglementations sur la TVA à partir du 1er juillet 2021.
      <br/>Les activités de dropshipping peuvent fonctionner normalement et les clients finaux n'auront pas à payer de taxes supplémentaires.
      <br/>Si vous avez des inquiétudes au sujet de l'IOSS, d'un problème de taxe locale, d'un remboursement de taxe, n'hésitez pas à contacter votre gestionnaire de compte fournisseur (<a href="https://www.dsers.com/blog/eu-vat-reform/" target="_blank" rel="noopener noreferrer" >cliquez ici pour en savoir plus</a>).`,
      title2: 'Introduction à la nouvelle taxe européenne (IOSS)',
      text2: `Effets sur les activités de dropshipping dans les pays de l'UE à partir du 1er juillet 2021`
    },
    newUI: {
      title: 'Toute nouvelle interface, toute nouvelle expérience',
      title2: 'DSers lancer une nouvelle interface utilisateur début Septembre',
      learn: 'EN SAVOIR PLUS',
      newYearTitle: "Le Nouvel An Chinois Approche",
      newYearContent1: "le Nouvel An chinois arrive. Veuillez contacter vos fournisseurs pour vérifier leurs délais de livraison et leur période de repos!",
      newYearContent2: "Les fournisseurs de DSers se reposeront du 21 janvier 2023 au 23 janvier 2023",
    },
    bannerFor11_11:`Découvrez d'énormes promotions le 11 Novembre`,
    bannerButton:'EN SAVOIR PLUS',
    newFeatureUp:'Nouvelle fonction',
    newFeatureTitle:'[Nouvelle fonction]',
    newFeatureText:'Suivez les informations logistiques de votre commande, vous pouvez utiliser le suivi.',
    newSettingTitle:'[Nouveau réglage] ',
    newSettingText:'Nouvelle méthode de mapping : Mapping Standard',
    newSettingText1:`Remplacer Produit: sans modifier l'ID du produit Shopify, remplacez toutes les variantes de votre produit Shopify`,
    learnMore:'En savoir plus',
    gotIt:"Ok",
    appointmenttext:`Vous avez défini l'heure de démarrage de migration au [ {time} ], vous ne pourrez pas utiliser votre compte DSers lorsque la migration commencera`,
    appointmenttext1:`Vous avez défini l'heure de démarrage de migration au [ {time} ], et nous vous aiderons à migrer en 5 minutes. Vous ne pourrez pas utilser votre compte DSers pendant la migration, vous pouvez donc faire une pause.`,
    servererrtext:`<text>Nous effectuerons une maintenance du système</text> le 14 mars de 6h00 à<br /> 7h00 GMT.<br />`,
    servererrtext2:'<text>Merci pour votre compréhension.</text>',
    agency_cold_startup: {
      banner_title_1: 'Nouvelle fonction en ligne!',
      banner_title_2: `Gestion des commandes d'agence`,
      popup_desc_1: `Utilisez-vous différents outils pour traiter les commandes d’agents?`,
      popup_desc_2: `Essayez cette solution alternative de DSers pour éviter de basculer plusieurs boutiques Shopify, des comptes de traitement et des fichiers CSV.`,
      popup_desc_3: `Gagnez du temps pour vous concentrer sur le marketing dans ce Q4!`,
      learn_more_btn: 'En savoir plus',
      banner_desc: 'Essayez cette solution alternative de DSers pour éviter de basculer plusieurs boutiques Shopify, des comptes de traitement et des fichiers CSV.',
      order_awaiting_empty_after_title: 'Après',
      order_awaiting_empty_before_title: 'Avant',
      order_awaiting_empty_before: `Sans cette fonction, vous auriez besoin de plusieurs outils pour les commandes d'agent`,
      order_awaiting_empty_after: `Avec cette fonction, vous n'avez besoin que de DSers!`,
      order_awaiting_empty_link: `Cliquez <a data="text-link" style="color: blue" href="https://www.dsers.com/blog/product-update-dropship-with-agencies/" target="_blank" >ici</a> pour en savoir plus sur le traitement des commandes d'agence`,
      what_is_agency: `Qu'est-ce qu'une Agence?`,
      what_is_erp: `Qu'est-ce qu'un ERP?`,
      how_does_dsers_agency_works: 'Comment DSers simplifie votre relation avec les agences de dropshipping?',
    },
    tmall_cold_startup: {
      popup_title: 'Nouveau Marketplace',
      pupup_title_2: 'Tmall est en ligne! Allez-y!',
      popup_desc_1: `Des milliers de produits parmis les plus vendus viennent d'être ajoutés.`,
      popup_desc_2: 'Pourquoi attendre pour les importer dans votre boutique?',
      popup_btn: 'Parcourir les produits',
      top_banner_title: 'Nouveau Marketplace',
      top_banner_title_2: 'Tmall est en ligne! Allez-y!',
      find_supplier_banner_title: 'Meilleures ventes Q4 2022!',
      find_supplier_banner_subtitle: 'Les articles à la mode et rentables sont là!',
      find_supplier_banner_subtitle_2: 'Ajoutez-les à votre boutique!'
    }
  }
};
