import React, { PureComponent } from 'react';
import { Spin } from 'antd';

import { goToWoo } from '../../common/sysconfig.js';

export default class AEWooCallback extends PureComponent {
  componentDidMount() {
    const v = location.search;
    let url = `${goToWoo}h5/auth/aecallback`;
    if (v) {
      url += v;
    }
    window.location.href = url;
  }

  render() {
    return (
      <div
        style={{
          marginTop: 20,
          textAlign: 'center'
        }}
      >
        <Spin size="large" />
      </div>
    );
  }
}
