import React, { useEffect, useState } from 'react'

import { Modal, message } from 'antd'

import styles from './OpenModal.less'
import arrow from 'assets/supply/arrowOrigin.png';

import { webCustomCollect } from 'utils/utils';

import moment from 'moment';
import { Post } from 'utils/request';
import { useDispatch } from 'react-redux';

export default function OpenModal({ userId, bannerData, pushedList, modalVisible }) {

  const [visible, setVisible] = useState(true)
  const [finish,setFinish] = useState(false)

  useEffect(()=>{
    setVisible(true)
  },[modalVisible])

  const dispatch = useDispatch()
  let ae_index = bannerData?.ae_img?.indexOf(";")
  let ae_img = bannerData?.ae_img?.substring(0, ae_index)
  let dsers_index = bannerData?.dsers_img?.indexOf(";")
  let dsers_img = bannerData?.dsers_img?.substring(0, dsers_index)
  let shopify_img = bannerData?.shopify_product_img
  const dsersPrice = (Number(bannerData?.dsers_price) + Number(bannerData?.dsers_ship_price)).toFixed(2)
  const aePrice = (Number(bannerData?.ae_price) + Number(bannerData?.ae_ship_price)).toFixed(2)



  const closeModalhandler = () => {
    setVisible(false)
    localStorage.setItem('conversionModal',true)
    dispatch({
      type:"global/setConversionModal",
      payload:false
    })
  }

  const handleReplace = async () => {
    try {
      const res = await Post('/prod/up_bind', {
          data: [{
              store_id: bannerData?.store_id,
              sp_product_id: bannerData?.shopify_product_id,
              user_group:pushedList.user_group
          }]
      })
      if (res && res.code === 2000) {
          setFinish(true)
          setVisible(false)
          dispatch({
            type:"global/setConversionStatus",
            payload:true
          })
          message.success('SUCCESS').then(res=>{
            window.location.reload();
          })
      }
  } catch (error) {
      console.log('fetch error:', error)
  }
  }

  return (
    <Modal
      visible={visible}
      centered
      width={560}
      onCancel={closeModalhandler}
      bodyStyle={{
        width: '560px',
        height: '389px',
        paddingLeft: '8px',
        paddingTop: '0px',
        paddingRight:'21px'
      }}
      footer={null}
    // className={styles.modalCon}
    >
      <div className={styles.modalBody}>
        <div className={styles.left}>
          <div>Private letter</div>
          <div>
            <img src={pushedList?.sell_url} alt="" />
            <div className={styles.sellInfo}>
              <div>{pushedList?.salesperson}</div>
              <div title={pushedList?.email}>{pushedList?.email}</div>
            </div>
          </div>
        </div>
        <div className={styles.line}></div>
        <div className={styles.right}>
          <div className={styles.top}>Sales Notice</div>
          <div className={styles.box}>
            <div className={styles.boxTop}>A better alternative for you</div>
            <div className={styles.card}>
              <div>
                <img src={shopify_img} alt="" />
                <div className={styles.sub} title={bannerData?.ae_product_title}>{bannerData?.ae_product_title}</div>
                <div className={styles.price}>${aePrice}</div>
                <div className={styles.mask}></div>
              </div>
              <img src={arrow} alt="" />
              <div>
                <img src={shopify_img} alt="" />
                <div className={styles.sub} title={bannerData?.dsers_product_title}>{bannerData?.dsers_product_title}</div>
                <div className={styles.price}>${dsersPrice}</div>
              </div>
            </div>
            <div className={styles.bottom}>
              <div>Click the button to replace</div>
              <div onClick={!finish ? handleReplace : null} style={!finish ? null : {color:'rgba(0, 0, 0, 0.5)'}}>REPLACE</div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
