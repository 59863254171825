
    export default 
    {
    "support": {
        "title": "Soporte Técnico",
        "description": "Bienvenido(a) al soporte técnico de Dsers. Estamos aquí para ayudarte a impulsar tu negocio de (No hay sugerencias) con corazón y alma.",
        "email": "CORREO ELECTRÓNICO",
        "email_dec": "¡Envía un correo electrónico con tus preguntas o inspiraciones!<br/> Te responderemos lo antes posible.",
        "email_btn": "Soporte técnico por correo electrónico",
        "live_chat": "CHAT EN VIVO",
        "live_chat_dec": "Envíanos un mensaje para solucionar el problema. Estamos a tu <br/> disposición para iniciar un chat en vivo.",
        "live_chat_btn": "Envíanos un mensaje",
        "facebook": "FACEBOOK",
        "facebook_dec": "Sigue nuestra página de Facebook para obtener las últimas novedades sobre Dsers y (No hay sugerencias).",
        "facebook_btn": "Facebook",
        "next_level": "Lleva tu negocio al siguiente nivel",
        "try_now": "Pruébalo ahora sin ningún compromiso.",
        "get_started": "Comenzar",
        "partnership": "Sociedad"
    }
}