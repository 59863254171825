export default {
  common: {
    // 基础按钮
    learn_more: 'Savoir plus',
    submit: 'Envoyer',
    or: 'Ou',
    other: 'Autre',
    contact_us: 'Nous contacter',
    click_blog: `Cliquez <a href="{url}" target="_blank" rel="noopener noreferrer">ici</a> pour savoir comment régler ce problème.`,
    click_blog2:`Veuillez inscrire votre nom complet en anglais ou en français seulement et les séparer par un espace. Cliquez <a href="{url}" target="_blank">ici</a> pour savoir comment résoudre ce problème.`
  }
};
