export default {
  guide: {
    // 升级弹窗文案
    CommonGuide: [
      {
        type: 1,
        title: 'Upgrade-Plan',
        content:'Ihr aktueller Tarif ist der Basic-Tarif, bitte steigen Sie auf den Advanced-Tarif um, wenn Sie Zugang zu dieser Funktion, einer erweiterten Kartierung und einem 24-Stunden-Kundendienst haben möchten.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'UPGRADE',
        otherBtn: 'Abbrechen'
      },
      {
        type: 2,
        title: 'Upgrade-Plan',
        content:'Ihr aktueller Plan ist der Advanced Plan, bitte steigen Sie auf den Pro Plan um, wenn Sie Zugang zu dieser Funktion und der Verwaltung von mehr als 70.000 Produkten haben möchten.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'UPGRADE',
        otherBtn: 'Abbrechen'
      },
      {
        type: 3,
        title: 'Personalisierung',
        content:'Ihr aktueller Plan ist der höchste, wenn Sie die Limits erhöhen möchten, können wir Ihnen maßgeschneiderte Dienstleistungen anbieten.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Kontakt',
        otherBtn: 'Abbrechen'
      },
      {
        type: 4,
        title: 'Upgrade-Plan',
        content:'Ihr aktueller Tarif ist Standard, bitte steigen Sie auf den Advanced-Tarif um, wenn Sie Zugang zu dieser Funktion, einer erweiterten Kartierung und einem 24-Stunden-Kundendienst haben möchten.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'UPGRADE',
        otherBtn: 'Abbrechen'
      },
      {
        type: 5,
        title: 'Personalisierung',
        content:'Ihr aktueller Plan ist der höchste, wenn Sie die Limits erhöhen möchten, können wir Ihnen maßgeschneiderte Dienstleistungen anbieten.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Kontakt',
        otherBtn: 'Abbrechen'
      },
      {
        type: 6,
        title: 'Personalisierung',
        content:'Ihr aktueller Plan ist der höchste, wenn Sie die Limits erhöhen möchten, können wir Ihnen maßgeschneiderte Dienstleistungen anbieten.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Kontakt',
        otherBtn: 'Abbrechen'
      },
      {
        type: 7,
        title: 'Upgrade-Plan',
        content:'Ihr aktueller Plan ist der Advanced Plan, bitte steigen Sie auf den Pro Plan um, wenn Sie Zugang zu dieser Funktion und der Verwaltung von mehr als 70.000 Produkten haben möchten.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'UPGRADE',
        otherBtn: 'Abbrechen'
      },
      {
        type: 8,
        title: 'Upgrade-Plan',
        content:'Ihr aktueller Tarif ist Standard, bitte steigen Sie auf den Advanced-Tarif um, wenn Sie Zugang zu dieser Funktion, einer erweiterten Kartierung und einem 24-Stunden-Kundendienst haben möchten.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'UPGRADE',
        otherBtn: 'Abbrechen'
      },
      {
        type: 9,
        title: 'Personalisierung',
        content:'Ihr aktueller Plan ist der höchste, wenn Sie die Limits erhöhen möchten, können wir Ihnen maßgeschneiderte Dienstleistungen anbieten.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'Kontakt',
        otherBtn: 'Abbrechen'
      },
      {
        type: 10,
        title: 'Upgrade-Plan',
        content:'Ihr aktueller Tarif ist der Benutzerdefiniert Basic, bitte steigen Sie auf den Advanced-Tarif um, wenn Sie Zugang zu dieser Funktion, einer erweiterten Kartierung und einem 24-Stunden-Kundendienst haben möchten.',
        addContent:'(PS: If you have not reached the upper limit of your current plan, then there may be a situation where your Billing Store is unavailable. In that case, you need to activate the Billing Store first, and then upgrade the plan)',
        mainBtn: 'UPGRADE',
        otherBtn: 'Abbrechen'
      },
    ],
    // 开屏弹窗文案
    OBDialog: {
      bindAE: {
        top:
          'Wir freuen uns, dass Sie zu uns kommen! Wir haben erfolgreich alle Ihre Daten, Bestellungen, Produkte und Einstellungen erfolgreich migriert.',
        topTitle: 'Welcome to DSers!',
        centerTitle: 'Migration erfolgreich！',
        btn: 'GESCHAFFT'
      },
      noBindAE: {
        top:
          'Wir freuen uns, dass Sie zu uns kommen! Wir haben erfolgreich alle Ihre Daten, Bestellungen, Produkte und Einstellungen erfolgreich migriert.',
        centerTitle: 'Migration erfolgreich！',
        topTitle: 'Welcome to DSers!',
        bottom: [
          'Um DSers nutzen zu können, verknüpfen Sie bitte Ihr AliExpress-Konto.'
        ],
        btn: 'LINK ALIEXPRESS'
      }
    },
    //底部banner文案
    OBanberWords: [
      {
        // 绑定AE
        step: 1,
        title: 'Verknüpfen Sie Ihr AliExpress-Konto',
        text:
          "Wenn Sie Ihr AliExpress-Konto mit DSers verknüpfen, können Sie 100+ Bestellungen in Sekundenschnelle aufgeben! Klicken Sie <a target='_blank' href='https://help.dsers.com/link-your-shopify-store/'>hier</a> für weitere Details.",
        btn: 'LINK ALIEXPRESS'
      },
      {
        // 确认套餐
        step: 2,
        title: 'Behalten Sie Ihre Oberlo-Gewohnheiten ',
        textArr: [
          {
            type: 1,
            text:
              "Zugang zu allen Funktionen von Oberlo, und genießen Sie erweiterte Funktionen wie Multi-Store-Management.Klicken Sie <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>hier</a> für weitere Details."
          },
          {
            type: 2,
            text:
              "Zugang zu allen Oberlo-ähnlichen Funktionen, und genießen Sie erweiterte Funktionen wie erweiterte Kartierung und 24-Stunden-Kundenservice. Klicken Sie <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>hier</a> für weitere Details."
          },
          {
            type: 3,
            text:
              "Zugang zu allen Oberlo-ähnlichen Funktionen und mehr Ressourcen wie die Verwaltung von mehr als 70.000 Produkten. Klicken Sie <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>hier</a> für weitere Details."
          }
        ],
        btn: 'Activate Plan'
      },
      {
        // 安装插件
        step: 3,
        title: 'DSers Chrome-Erweiterung installieren',
        text:
          "Ein-Klick-Produktimport von AliExpress zu DSers, um Zeit zu sparen und neue Verkaufsschlager zu finden! Klicken Sie <a target='_blank' href='https://help.dsers.com/import-product-from-aliexpress-for-shopify/#Use DSers Chrome Extension'>hier</a> für weitere Details.",
        btn: 'Herunterladen'
      }
    ],
    // 确认弹窗文案
    ComfirmPop: [
      {
        kind: 'AE',
        title: 'Verknüpfen Sie Ihr AliExpress-Konto',
        content:
          "Wenn Sie Ihr AliExpress-Konto mit DSers verknüpfen, können Sie 100+ Bestellungen in Sekundenschnelle aufgeben! Klicken Sie <a target='_blank' href='https://help.dsers.com/link-your-shopify-store/'>hier</a> für weitere Details.",
        mainBtn: 'LINK ALIEXPRESS'
      },
      {
        kind: 'PLAN',
        title: 'Behalten Sie Ihre Oberlo-Gewohnheiten ',
        contentArr: [
          {
            type: 1,
            content:
              "Zugang zu allen Funktionen von Oberlo, und genießen Sie erweiterte Funktionen wie Multi-Store-Management.Klicken Sie <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>hier</a> für weitere Details."
          },
          {
            type: 2,
            content:
              "Zugang zu allen Oberlo-ähnlichen Funktionen, und genießen Sie erweiterte Funktionen wie erweiterte Kartierung und 24-Stunden-Kundenservice. Klicken Sie <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>hier</a> für weitere Details."
          },
          {
            type: 3,
            content:
              "Zugang zu allen Oberlo-ähnlichen Funktionen und mehr Ressourcen wie die Verwaltung von mehr als 70.000 Produkten. Klicken Sie <a target='_blank' href='https://help.dsers.com/subscription-plans-presentation-for-shopify/'>hier</a> für weitere Details."
          }
        ],
        errorBillStore:
          "Sie können den Plan nicht aktivieren, weil Ihr Abrechnungsshop nicht verfügbar ist. Bitte wechseln Sie den Abrechnungsshop auf der Seite DSers - <a target='_blank' href='https://www.dsers.com/app/select'>Abonnements</a> verwalten oder aktivieren Sie den Abrechnungsshop im ",
        errorBillStoreNotId: "Sie können den Plan nicht aktivieren, weil Ihr Abrechnungsshop nicht verfügbar ist. Bitte wechseln Sie den Abrechnungsshop auf der Seite DSers - <a target='_blank' href='https://www.dsers.com/app/select'>Abonnements</a> verwalten",
        errorBillName: 'Shopify-Admin',
        mainBtn: 'ACTIVATE PLAN'
      },
      {
        kind: 'CHROME',
        title: 'DSers Chrome-Erweiterung installieren',
        content:
          "Ein-Klick-Produktimport von AliExpress zu DSers, um Zeit zu sparen und neue Verkaufsschlager zu finden! Klicken Sie <a target='_blank' href='https://help.dsers.com/import-product-from-aliexpress-for-shopify/#Use DSers Chrome Extension'>hier</a> für weitere Details.",
        mainBtn: 'Herunterladen'
      }
    ],
    // 刷新弹窗文案
    RefreshPopText: {
      title: 'Seite aktualisieren',
      content:
        'Bitte aktualisieren Sie die Seite, damit wir feststellen können, ob Sie das Plugin erfolgreich installiert haben',
      btn: 'AKTUALISIEREN'
    },
    // 提示用户必要信息文案
    OberloTopTipOneBindAE:"Your data is being corrected, you can migrate other Oberlo accounts once the data correction is completed.",
    OberloTopTipOneNoBindAE:'Please link your AliExpress account to DSers so that we can correct your orders status, you can continue to migrate other Oberlo accounts once the correction is completed.',
    OberloTopTipTwo:"Please note that DSers displays all the Shopify orders, while Oberlo displayed only the orders that contained products created through Oberlo.</br>" +
    "DSers migriert Bestellungen aus den letzten zwei Monaten. Wenn Sie mehrere Aufträge synchronisieren möchten, <a style='color:#3E5BFE' href='https://www.messenger.com/t/DSersOfficial' target='_blank'>kontaktieren Sie uns </a>bitte."
  }
};
