
// 插件更新物流
export const UPDATE_FRIENGHT = 'UPDATE_FRIENGHT';

// 关闭Frenght弹层 
export const CLOSE_FRIENGHT_MODAL = 'CLOSE_FRIENGHT_MODAL';

// order页面未绑定AE的弹层
export const IS_BIND_AE_MODAL = 'IS_BIND_AE_MODAL';

// 订单页面状态打点
export const INTERACTION_DOT = 'INTERACTION_DOT';

// order頁面更新物流 
export const UPDATA_SHIPPING = 'UPDATA_SHIPPING'

// order取消事件
export const  CANCEL_SAVE= 'CANCEL_SAVE'

//tmall支付轮询
export const TMALL_PROCESS_START = 'START_PROCESS'

export const AE_BIND_MODAL = 'AE_BIND_MODAL'