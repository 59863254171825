import React, { memo, useState, useCallback, useRef } from 'react';
import { Input, Radio, Button, Spin, Checkbox } from 'antd';

import classnames from 'classnames';

import styles from './FilterSearch.less';

const FilterSearch = (props) => {
  const {
    dataSource = [], 
    gruopType = 'timeZone', 
    effectsAction = {
      onChangeTimeZone: () => {},
      onChangeCountry: () => {}
    },
    defaultValue
} = props;


  const [value, setValue] = useState('')
  const [data, setDate] = useState(dataSource)

  const ref = useRef(data)

  const countryList = JSON.parse(localStorage.getItem('countryList'))


  const titleize = (str)  => {
    return str.toLowerCase().replace(/(?:^|\s)\w/g, function(c) {
      return c.toUpperCase();
    });
  }

  const searchVal = useCallback(() => {
    
    if(gruopType == 'timeZone'){
      if(!value){
        setDate(ref.current)
        return
      }
      let list = ref.current.filter(item => {
        return item.label.includes(titleize(value))
      })
      setDate(list)
    }

    if(gruopType == 'country'){
      if(!value){
        setDate(ref.current)
        return
      }
      let list = ref.current.filter(item => {
        return item.Name.includes(titleize(value))
      })
      setDate(list)
    }

  },[value])

  const onChange = (e) => {
    switch(gruopType){
      case 'timeZone':
        effectsAction.onChangeTimeZone(e.target.value)
        break;
      case 'country':
        effectsAction.onChangeCountry(e.target.value)
        break;
      case 'flags':
        effectsAction.onChangeFlags(e)
        break;
      case 'orderType':
          effectsAction.onChangeOrderType(e)
          break;
      case 'fulfill':
          effectsAction.onChangeFuilfill(e)
          break;
    }
  }

  const getRender = () => {
    if(gruopType == 'timeZone'){
      return <>
        <Radio.Group onChange={onChange} defaultValue={defaultValue}>
          {
            data.map(item => {
              return <Radio key={item.value} value={item.value}>{item.label}</Radio>
            })
          }
        </Radio.Group>
      </>
    }
    if(gruopType == 'country'){
      return <>
        <Radio.Group onChange={onChange} defaultValue={defaultValue}>
          {
            data.map(item => {
              return <Radio key={item.Code} value={item.Code}>{item.Name}</Radio>
            })
          }
        </Radio.Group>
      </>
    }
    if(gruopType == 'flags'){
      return <>
        <Checkbox.Group style={{ width: '100%' }} onChange={onChange} defaultValue={defaultValue}>
          {
            data?.map(item => {
              return <Checkbox key={item.id} value={item.id}><img width={24} src={item.img}></img> {item.name}</Checkbox>
            })
          }
        </Checkbox.Group>
      </>
    }
    if(gruopType == 'orderType'){
      return <Radio.Group onChange={onChange} defaultValue={defaultValue}>
          {
            data.map(item => {
              return <Radio key={item.id} value={item.id}>{item.name}</Radio>
            })
          }
        </Radio.Group>
    }
    if(gruopType == 'fulfill'){
      return <Radio.Group onChange={onChange} defaultValue={defaultValue}>
          {
            data.map(item => {
              return <Radio key={item.id} value={item.id}>{item.title}</Radio>
            })
          }
        </Radio.Group>
    }

  }

  const getRenderInput = () => {
    if(gruopType == 'flags' || gruopType == 'orderType' || gruopType == 'fulfill'){
      return null;
    }
    return <>
      <Input placeholder='Text' allowClear onChange={(e) => {setValue(e.target.value.trim())}} />
      <div className={styles.searchBtn}>
        <Button type="primary" onClick={searchVal}>
          <i class="material-icons notranslate">search</i>
        </Button>
      </div>
    </>
  }

  return <>
    <div className={styles.InputBox}>
      {getRenderInput()}
    </div>
    <div className={classnames({
      [styles.SearchBox]:true,
      [styles.SearchBox_active]:gruopType == 'flags' || gruopType == 'orderType' || gruopType == 'fulfill' ? true: false
    })}>
      {getRender()}
    </div>
  </>
}

export default memo(FilterSearch);