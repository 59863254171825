import React, { Component, Fragment } from 'react';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';
import { Form, Input, Button, message } from 'antd';
import intl from 'react-intl-universal';

import Result from 'components/Result';
import { checkPassword } from './validator';

import styles from './RegisterResult.less';

const FormItem = Form.Item;

@connect(({ loading }) => ({
  submitting: loading.effects['login/resetPassword']
}))
@Form.create()
export default class RegisterResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 'succ',
      msg: ''
    };
  }

  componentDidMount() {}

  getMsg = msg => {
    let v = '';
    if (msg == 'time expired' || msg == 'password already changed') {
      v = 'The link has expired, please reapply.';
    } else {
      v = 'Failed to change password, please try again later or contact us.';
    }
    return v;
  };

  handleSubmit = e => {
    e.preventDefault();
    const userType = window.location.search?.split('@@')[0]?.split('?')[1]?.split('=')[1]
    const redirect = window.location.search?.split('@@')[1]
    let url = window.location.href.split('?')[0]
    let code = null;
    if(/reset-password\/(.*)\?refer=/g.test(window.location.href)){
      code = RegExp.$1
    }
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if (values.password !== values.password2) {
          message.error('Wrong password.Please confirm again.');
          return;
        }
        this.props.dispatch({
          type: 'login/resetPassword',
          payload: {
            data: {
              pass: values.password,
              code
            },
            callback: d => {
              if (d.code == 2000 || d.code == 2010) {
                message.success('Your password has been reset', 3, () => {
                  userType == 'oberlo' ? this.props.dispatch(routerRedux.push(`/migrat-login?${redirect}`)) : this.props.dispatch(routerRedux.push('/login'));
                });
              } else {
                this.setState({
                  status: 'failed',
                  msg: this.getMsg(d.msg)
                });
              }
            }
          }
        });
      }
    });
  };

  render() {
    const { status, msg } = this.state;
    const { form, submitting } = this.props;
    const { getFieldDecorator } = form;

    return (
      <>
        {status === 'loading' ? (
          <Result
            className={styles.registerResult}
            type="loading"
            title={
              <div className={styles.title} style={{ color: '#333' }}>
                {msg}
              </div>
            }
            style={{ marginTop: 56 }}
          />
        ) : status === 'succ' ? (
          <div className={styles.forgotpassword}>
            <h1>Reset Password</h1>
            <Form onSubmit={this.handleSubmit}>
              <FormItem>
                {getFieldDecorator('password', {
                  rules: [
                    {
                      validator: (rule, value, callback) => checkPassword(rule, value, callback)
                    }
                  ]
                })(
                  <Input.Password
                    size="large"
                    type="password"
                    autoComplete="new-password"
                    placeholder="new password"
                  />
                )}
              </FormItem>
              <FormItem style={{margin: 0}}>
                {getFieldDecorator('password2', {
                  rules: [
                    {
                      validator: (rule, value, callback) => checkPassword(rule, value, callback, form.getFieldValue('password'))
                    }
                  ]
                })(
                  <Input.Password
                    size="large"
                    type="password"
                    autoComplete="new-password"
                    placeholder="confirm new password"
                  />
                )}
              </FormItem>
              <FormItem>
               <div className={styles.button_submit}>
                <Button type="primary" htmlType="submit" size="large" loading={submitting}>
                    Reset
                  </Button>
               </div>
              </FormItem>
              <p className={styles.tip}>
                  If you have any question , please{' '}
                  <a href="mailto:support@dsers.com">Contact us</a>.
                </p>
            </Form>
          </div>
        ) : (
          <Result
            className={styles.registerResult}
            type="error"
            title={
              <div className={styles.title} style={{ color: '#333' }}>
                {msg}
              </div>
            }
            style={{ marginTop: 56 }}
          />
        )}
      </>
    );
  }
}
