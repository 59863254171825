import { Get, Post, Put } from '../utils/partnerRequest';
// 登录账号
export async function LoginPartnerAccount(payload) {
  return Post('/partner/client/login', payload);
}
// 注册账号
export async function RegisterPartnerAccount(payload) {
  return Post('/partner/client/register', payload);
}
// 发送重置密码邮件
export async function SendResetPasswordEmail(payload) {
  return Get('/partner/client/reset/email', payload);
}
// 重置密码
export async function ResetPassword(payload) {
  return Post('/partner/client/reset/pwd', payload);
}
// 发送修改邮箱邮件，获取code
export async function SendChangeEmailMsg(payload) {
  return Get('/partner/client/change/email/send', payload);
}
// 修改邮箱确认
export async function ChangeEmailConfirm(payload) {
  return Post('/partner/client/change/email/confirm', payload);
}
// 修改密码
export async function ChangePassword(payload) {
  return Post('/partner/client/change/pwd', payload);
}
// 获取DashBoard数据
export async function GetDashBoardData(payload) {
  return Get('/partner/client/dashboard/data', payload);
}
// 获取partner账户信息
export async function GetPartnerAccountInfo(payload) {
  return Get('/partner/client/account/info', payload);
}
// 更新partner账户数据
export async function UpdatePartnerAccount(payload) {
  return Put('/partner/client/update/account', payload);
}
// 获取国家列表数据
export async function GetCountryList(payload) {
  return Get('/partner/client/country/list', payload);
}
