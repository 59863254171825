
    export default 
    {
    "admitad": {
        "title": "¡Dsers Admitad está en vivo!",
        "p1": "La cooperación oficial entre Dsers y Admitad ya está en línea.",
        "p2": "Por favor, consulta el siguiente artículo para saber cómo configurar todo:",
        "p3": "https://www.blog.dserspro.com/post/how-to-use-admitad-cashback-on-dsers-pro",
        "p4": "Una vez hecho esto, consigue un nuevo código en la oferta WW de AliExpress.",
        "p5": "Cambia el código que estás usando actualmente y reemplázalo con el nuevo código.",
        "p6": "Nota: Los códigos que no estén afiliados a Dsers dejarán de utilizarse el próximo mes"
    }
}