import React, { useEffect,useState } from 'react'
import { GuildeAction } from 'actions';
import PlaceOne from './placeOne'
import PlaceTwo from './placeTwo'
import { connect } from 'dva'
import "./index.module.less"
const OberloTip = ({ isOberlo }) => {
  const [changePlaceOne, setChangePlaceOne] = useState('');
  const [changePlaceTwo, setChangePlaceTwo] = useState('');
  useEffect(() => {
    if (!isOberlo) { return; }
    !window.location.pathname.match(/supplier_optimizer|link-store/) ?
      changeSignState(window.location.pathname) :
      changeSignState('')
  }, [isOberlo])
  
  /**
   *  根据不同状态是否显示提示信息
   * @param {*} data 跳转路径 || '' 
   */
  const changeSignState = (data) => {
      // if(!['STEP_STAGE_','STEP_STAGE_COMPLETE'].includes(localStorage.getItem('setMigrationInfo'))){
      //    setChangePlaceOne(data)
      // }
      //  setChangePlaceOne('')
      let user_Key = JSON.parse(localStorage.getItem('store'))?.user_id;
        if (data.endsWith('/order/list') &&  
        !['STEP_STAGE_PRE','STEP_STAGE_QUEUE','STEP_STAGE_STEP1'].includes(localStorage.getItem('setMigrationInfo')) &&
        !localStorage.getItem(`${user_Key}_OBERLO_READ_ORIDER_TIP`)) {
          setChangePlaceTwo(data)
        } else {
          setChangePlaceTwo('')
        }
      
  }
  /**
   * 事件监听
   */
  useEffect(() => {
    window.EVENT.on(GuildeAction.OblerloTop_Tip, params => {
      changeSignState(params)
    });
  }, ['']);

   return (
    <>
       <PlaceOne
         update={changePlaceOne}
       /> 
       <PlaceTwo
         update={changePlaceTwo}
       />
    </>)
}
const mapStateToProps = ({ auth }) => ({
  isOberlo: auth?.isOberloUser,
});
export default connect(mapStateToProps)(OberloTip)