
    export default 
    {
    "product_edit": {
        "repeated_tip": "Los colores y/o tamaños del producto están repetidos, por favor, modifícalos",
        "picture": "Imagen",
        "compare_price": "Comparar <br/> el valor del precio",
        "delete_variant_tip": "¿Estás seguro de que quieres eliminar esta variante?",
        "delete_selected_variant_tip": "¿Estás seguro de que quieres eliminar las variantes seleccionadas?",
        "new_compare_price": "Nuevo precio para comparar",
        "new_price": "Nuevo precio",
        "new_stock": "Nuevo stock",
        "open_on_supplier": "HAZ CLIC PARA VER LOS DETALLES",
        "from_other": "de otro",
        "seller_id": "ID del vendedor",
        "input_title": "Por favor, ingresa el título",
        "product_type": "Tipo de producto",
        "collection": "Colección",
        "vendor": "Proveedor",
        "tags": "Etiquetas",
        "no_edit": "Los productos que se publican en Shopify no se pueden editar, por favor, edítalos en Shopify directamente.",
        "select_variant": "Seleccionar variante",
        "none": "Ninguno",
        "description": "Descripción",
        "images": "Imágenes <i>({selectImages}/{allImages})</i>",
        "shipping": "Información de envío",
        "shipping_info": "Puedes comprobar la información de los costes de envío de {platform}, el tiempo estimado de entrega y la disponibilidad de seguimiento aquí, seleccionando un país de destino (y de origen). La información de envío NO se enviará a Shopify cuando se publique. Esto es sólo para proporcionar una estimación de los gastos de envío.",
        "cannot_recover": "No se pueden recuperar las variantes de productos eliminadas, ¿deseas continuar?",
        "download_pic": "Haz clic aquí para descargar todas las imágenes",
        "delete_pic_tip": "Do you want to delete the image(s)? The deleted image(s) will not be uploaded to the Media section in the Shopify product.",
        "delete_picss_tip": "Do you want to delete all the images? No images will be uploaded to the Media section in the Shopify product.",
        "back": "Volver",
        "product": "Producto",
        "from_aliexpress": "de AliExpress",
        from_tmall:'de Tmall',
        "title": "Título",
        "variants": "Variantes <i>({allvariants})</i>",
        "all": "Todo",
        "action": "Acción",
        "sku": "SKU",
        "cost": "Coste",
        "price": "Valor del precio",
        "shopify_stock": "Stock en <br /> Shopify",
        "stock": "Stock en <br /> proveedor",
        replace_stock:'Stock en <br /> {platform}',
        "change_price": "Cambiar el precio",
        "change_compare_at_price": "Cambiar Comparar Precios",
        "remove_sku": "Eliminar SKU(s)",
        "change_stock": "Cambiar Stock",
        "option_repeated_tip": "Los colores y/o tamaños del producto están repetidos, por favor, modifícalos",
        "sku_repeated_tip": "El SKU debe ser diferente para cada variante. Por favor, modifícalo.",
        "option_name_invalid": "El nombre de la opción no puede estar vacío",
        "option_value_invalid": "El valor de la opción no puede estar vacío",
        "option_name_repeat": "El nombre de la opción no puede estar repetido",
        "option_name_slash": "El nombre de la opción no puede contener  / ",
        "option_sku_invalid": "El valor del SKU no puede estar vacío",
        "sku_count_invalid": "Por favor, mantén al menos un SKU",
        "overrun_warning": "Has superado el límite diario para acceder a la información del producto",
        "push_description": "Haz clic <a target=\"_blank\" href=\"/app/settings\">aquí para</a> establecer el contenido de la descripción al empujar el producto.",
        "specifications": "ESPECIFICACIONES",
        "overview": "VISTA GENERAL",
        "stock_on_shopify": "Stock en <br /> Shopify",
        "change_image": "CAMBIAR IMAGEN",
        "organization": "Organización",
        "download_all_img": "Descargar",
        "delete_img": "Delete",
        "img_des": "Las imágenes seleccionadas aquí aparecerán en los medios de comunicación de los productos de Shopify",
        "not_select_main_img_des": "If you don’t select the cover picture here, the first picture you check here will be used as the cover picture for this Shopify product.",
        "deleteImage": "Delete Image",
        "products": "Productos",
        "shipping_cost": "Gastos de <br /> envío",
        "uncheckedAlert": "Si no seleccionas ninguna imagen aquí, no se subirá ninguna imagen a Media en el producto de Shopify.",
        "try_again_collections_text": "We couldn't get your collections data because the request timeout, please click ",
        "try_again_collections_fun": "Refresh",
        "checkImages": "Imágenes <i>({allImages})</i>",
        "select_province_pleaseholder": "Por favor, selecciona la provincia/ciudad",
        "ship_from_text": "Enviar desde:",
        "ship_to_text": "Enviar a:",
        "product_edit_price_tip": "El símbolo de la moneda variará en función de la configuración de tu tienda de Shopify después de publicar en ella. Por favor, establece manualmente el valor del precio/comparación de precios aquí si lo necesitas.",
        "shopify_stock_tip": "Aquí puedes configurar la cantidad de stock que quieres empujar a Shopify. No se actualizará cuando el stock del proveedor cambie en {platform}.",
        "aliexpress_stock_tip": "DSers actualizará automáticamente el stock de AliExpress para los productos de la lista de importación cada 2 horas. El stock aquí no puede ser modificado manualmente",
        tmall_stock_tip: "DSers actualizará automáticamente el stock de Tmall para los productos de la lista de importación. El stock aquí no puede ser modificado manualmente.",
        product_stock_tip:'DSers actualizará automáticamente el stock de los proveedores para la página Mi producto. No puede ser modificado manualmente. No actualizaremos las existencias en el mapeo avanzado/BOGO/Paquete.',
        shopify_product_stock_tip:'El stock se sincroniza desde Shopify y no se actualiza segun las actualizaciones de los proveedores, a menos que este activa la actualización automática en el plan de pago.',
        download_img_fail_tip:'Image download failed, please try again later',
    }
}