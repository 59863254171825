import {
  checkExtVersion,
  getAELoginStatusV2,
  EXT_VERSION,
  getAELoginStatusV3
} from './placeOrderViaExt';
import { sleep } from './utils';

export const getAffiliateStatus = (localAEStatus, AEInfo) => {
  /**
   *  101: AE登录态可用，账号和授权账号一致
   *  102: AE登录态可用，账号和授权账号不一致
   *  103: AE登录态不可用，账号和授权账号不一致
   *  104: 没有安装插件不确定
   *  105: AE登录态不可用，账号和授权账号一致
   *  106: 插件版本不支持上报
   *  107: 插件未抓取到AE状态或抓取出错
   * */

  if (localAEStatus.status === 1) return 106;
  if (localAEStatus.status === 0) return 104;
  const boundAEInfo = AEInfo;
  const localEqualsBound =
    boundAEInfo?.user_nick === localAEStatus?.localAEAccountId;
  if ([403, 200].includes(localAEStatus.status)) {
    return localEqualsBound ? 101 : 102;
  }
  if (localAEStatus.status === 302) {
    return localEqualsBound ? 105 : 103;
  }
  return 107;
};
export const getAELoginStatus = async AEInfo => {
  const extVer = checkExtVersion(2, 8, 34);
  
  // status 0：未安装插件 1: 插件版本不支持上报 100：未知  200：登录态可用 302：登录态不可用 403: 账号被封 500：未知错误
  const result = { status: 100, localAEAccountId: '', affiliateStatus: 104 };
  if ([EXT_VERSION.HIGHER, EXT_VERSION.EQUAL].includes(extVer)) {
    const useV3LoginStatus = [EXT_VERSION.HIGHER, EXT_VERSION.EQUAL].includes(checkExtVersion(2, 8, 49));
    try {
      await Promise.race([
        !useV3LoginStatus
          ? getAELoginStatusV2().then(res => {
              if (res.data) {
                result.status = res.data.code;
                result.localAEAccountId = res.data.aeAccountId;
              }
            })
          : getAELoginStatusV3().then(res => {
              if(res.data) {
                result.localAEAccountId = res.data?.aeAccountInfo?.loginId || '';
                result.status = result.localAEAccountId ? 200 : 302;
                result.localAffPid = res.data?.trackData?.pid || '';
                console.log(res);
              }
          }),
        sleep(10)
      ]);
      if(result.status === 100) {
        result.status = 500;
        result.affiliateStatus = 107;
      }
    } catch (error) {
      result.status = 500;
      result.affiliateStatus = 107;
    }
  } else if (extVer === EXT_VERSION.LOWER) {
    result.status = 1;
  } else {
    result.status = 0;
  }
  result.affiliateStatus = getAffiliateStatus(result, AEInfo);
  console.log(result, 'local ae info');
  // this.props.dispatch({ type: 'global/updateLocalAEInfo', payload: result });
  return result;
};
