import React,{memo} from 'react'
import styles from './index.less'

const list = [
  {
    img:require('../../assets/new_login_ui/partner-ali-logo.png')
  },
  {
    img:require('../../assets/new_login_ui/partner-shopify-logo.svg')
  },
  {
    img:require('../../assets/new_login_ui/partner-woocommerce-logo.svg')
  },
  {
    img:require('../../assets/new_login_ui/partner-palpal-logo.svg')
  }
]


export default memo(()=>{
  return <>
      <div className={styles.planc}>
        {
          list.map((item,key) => {
            return <div className={styles.item}>
              <img src={item.img} />
            </div>
          })
        }
      </div>
    </>
})
