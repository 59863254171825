
    export default 
    {
    "common": {
        "learn_more": "Mehr erfahren",
        "submit": "einreichen",
        "or": "Oder",
        "other": "Andere",
        "contact_us": "Kontaktieren Sie uns",
        "click_blog": "Klicken <a href=\"{url}\" target=\"_blank\" rel=\"noopener noreferrer\">Sie hier</a>, um zu erfahren, wie Sie dieses Problem lösen können.",
        "click_blog2": "Please fill in your full name in English or French only and separate them with a space. Click <a href=\"{url}\" target=\"_blank\">here</a> to learn how to solve this issue."
    }
}