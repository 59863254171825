export default {
  register: {
    title: 'Create a DSers account', //  m
    email: 'Email',
    password: 'Password',
    get_started: 'CREATE ACCOUNT',

    input_your_email: 'Please enter email in correct format.',
    input_password: 'Please enter 6 to 20 characters.',

    wrong_email_format: 'wrong email format',
    password_tip:
      'Please use password with at least 6 characters. Do not use password which can be cracked easily.',
    contact_us: 'CONTACT US',
    hello_shopify: 'Hello, Shopify',
    install_dsers_to_shopify: 'Install DSers to Shopify',
    password_ok: 'Safety：High',
    password_warning: 'Safety：Middle',
    password_error: 'Safety：Low',
    privacy:
      'By clicking Create Account, you agree to our <a href= "/privacy" target="_blank">PRIVACY POLICY</a>', // m
    result: {
      resend_successed: 'resend successed',
      resend_failed: 'resend failed',
      title: 'Process bulk & multiple orders with DSers',
      your_account: 'Your account:',
      register_succ: 'has been successfully registered.',
      activation_email:
        'We have sent an activation email to your email <br /> address.Please check your email box and click the <br /> link to activate your account within 10mins.',
      check_email: 'Check Email',
      resend_email: 'Resend email',
      go_sign: 'Go to sign up or sign in',
      about_email:
        'Did not get any email ? Click <a href="https://help.dsers.com/confirmation-email-not-received-for-shopify/" target="_blank">here </a> to get more specific explanations!'
    },
    password_error_long: 'The password must be 8 - 16 characters long',
    password_error_format:
      'The password contains at least 1 uppercase letter, 1 lowercase letter and 1 number',
    password_not_match_twice: 'Passwords do not match twice',
    accountSuccess:'Finish registration',
    haveAccount:`Already have an account?`,
    signIn:`SIGN IN`
  }
};
