import React, { useEffect } from 'react';
import { connect } from 'dva';
import { Spin, Result, Icon } from 'antd';

const DetectServiceAbility = ({
  detectServiceActive,
  allServiceFail,
  children
}) => {
  useEffect(() => {
    if (allServiceFail) {
      // window.location.href = '/application/errPage.html';
      window.location.href = '/application/';
      //服务器升级页面
      // window.location.href = '/app/severErr.html';
    }
  }, [allServiceFail]);

  if (detectServiceActive || allServiceFail) {
    return (
      <Spin size="large" style={{ width: '100%', margin: '180px auto' }} />
    );
  }
  return children;
};

export default connect(({ login }) => ({
  detectServiceActive: login.detectServiceActive,
  allServiceFail: login.allServiceFail
}))(DetectServiceAbility);
