export default {
  agency: {
    // global
    Agency: 'Agence (fournisseur individuel)',
    agency: 'agence',
    // setting
    supplier: 'Fournisseur',
    title: "Gestion de l'agence (fournisseur individuel)",
    subtitle:
      "Vous pouvez gérer les informations du fournisseur ici, commander directement auprès du fournisseur, plutôt que de passer par un marché tiers tel que AliExpress pour les achats. Cliquez ici pour en savoir plus.",
    subtitle_1: `Vous pouvez gérer les informations du fournisseur ici, commander directement auprès du fournisseur, plutôt que de passer par un marché tiers tel que AliExpress pour les achats. Cliquez`,
    subtitle_2: ' pour en savoir plus.',
    subtitle_3: ` ici `,
    title_tip: `Une agence représente un fournisseur de produits qui vous permet d'exécuter des commandes sans un tiers tel qu'AliExpress. Concrètement, vous pouvez directement passer des commandes aux agences et elles expédieront la marchandise à vos clients.`,
    name_tip: 'Le nom de votre agence, qui sera utilisé et affiché lorsque vous mappez un produit ou traitez une commande.',
    erp_tip: 'Les représentants ERP, tels que Dianxiaomi, Mabang et ECCANG, etc., qui offrent la gestion des stocks de produits, le suivi des achats, la distribution logistique et le suivi des colis.',
    paypal_tip: `Le compte PayPal de votre agence. Après avoir configuré ce compte PayPal, vous pourrez initier des paiements directement à l'agence pendant que les DSers traitent les commandes`,
    phone_tip: 'Le numéro de téléphone de votre agence, qui sera utilisé pour demander votre agence à exécuter les commandes par SMS.',
    name: "Nom de l'agence",
    nameErr: "Le nom de l'agence ne doit pas être vide.",
    phone: 'Numéro de téléphone',
    phoneErr: 'Saisissez uniquement des chiffres',
    erp: 'ERP utilisé',
    erpPlaceholder: 'Sélectionner un ERP',
    erpErr: 'Veuillez sélectionner un ERP.',
    paypal: 'Compte PayPal',
    paymentMethod: 'Payment method',
    agencyContactEmail: 'Agency contact email',
    stripeAccount: 'Stripe account',
    agencyStripe: 'Agency Stripe',
    stripeNoAuth: 'No authorization, please contact the supplier for authorization.',
    agencyPaymentTip: `Your Agency's Payment methods. After setting up this payment method, you can initiate payments directly to the Agency while DSers process orders.`,
    agencyNoAuthModalTitle: 'Authorization not completed',
    agencyNoAuthModalContent: `Your supplier has not completed the Stripe account authorization, please contact your supplier to complete the Stripe account authorization. If you have more questions, please contact the DSers support team.`,
    agencyNoAuthModalGetSupport: 'GET SUPPORT',
    paypalPlaceholder: "Compte PayPal de l'agence",
    paypalErr: 'PayPal ne doit pas être vide.',
    paypalEmailErr: 'Saisissez une adresse e-mail PayPal correcte',
    delError: 'Le fournisseur a été répertorié et ne peut pas être supprimé.',
    phonePlaceholder: 'Country',

    // setting > save text
    savetitle: 'Ajouter une agence (fournisseur individuel)',
    savetext: `Après avoir créé les informations d'agence, DSers enverra un e-mail à votre fournisseur ({email}).Veuillez contacter votre fournisseur, qui effectuera une configuration simple pour que vous puissiez continuer à gérer la cartographie des produits, l'exécution automatique des commandes, et d'autres fonctionnalités via DSers.`,
    deletetitle: "Suppression échouée.",
    deletetext: "Vous avez actuellement des produits associés à ce fournisseur. Pour éviter d'affecter les produits et commandes existants, veuillez effacer la correspondance associée à ce fournisseur avant de réessayer.",
    // my product
    Agencies: 'Agences <i>({ae_num})</i>',
    filterByAgency: 'Filtrer par agence',

    filterAgencyPlease: 'Accédez aux',
    filterAgencyAddInformation: 'pour ajouter une agence.',

    supplierManagement: 'Gestion des fournisseurs',
    marketplaces: 'Places de marché',
    agencyInformation: "Informations sur l'agence",
    selectAgency: 'Sélectionner une agence',
    refreshPage: 'Rafraîchir la page',
    REFRESH: 'RAFRAÎCHIR',
    mappingUpdating: 'Mise à jour du mapping',
    markeCardDescription:
      'Vous pouvez mapper les produits du fournisseur pour les produits de votre magasin, et lorsque les produits en vente sont commandés, vous pouvez passer une commande avec le fournisseur correspondant en un seul clic sur DSers.',
    markeCardDescriptionNoTianMao:
      'Avec les places de marché, vous pouvez sélectionner des produits AliExpress pour répertorier vos articles Shopify.',
    agencyCardDescription:
      "Agency représente un fournisseur unique en dehors des places de marché (comme AliExpress). Vous pouvez mapper les produits de votre magasin à l'agence et commander directement à partir de leur ERP pour l'exécution automatique.",
    marketplaceExplain:
      "Mapping ",
    marketplaceExplain1:
    " des fournisseurs des places de marché pour vos produits Shopify. Les détails du produit de Shopify (tels que le titre, la description, les images, les variantes, etc.) restent les mêmes après l'ajout d'un fournisseur.",
    agencyExplain:
      "Vous pouvez choisir le fournisseur qui vous convient en fonction de vos besoins. Les détails du produit de Shopify (tels que le titre, la description, les images, les variantes, etc.) ne changent pas après l'ajout d'un fournisseur.",
    statusExplain:
      "Activez pour sélectionner la méthode de mapping de l'Agence",
    selectAgentPrompt:
      "Sélectionnez une agence avec laquelle vous souhaitez répertorier vos produits Shopify. Si vous n'avez pas ajouté d'agence, accédez aux",
    toSetting: 'Réglages',
    addAgency: 'pour en ajouter une.',
    noSelectionPrompt:
      "Vous n'avez pas ajouté d'agence, accédez aux paramètres pour ajouter une agence.",
    noSelectionButton: 'Allez dans Paramètres pour ajouter des agences',
    refreshPagePrompt:
      'Veuillez rafraîchir la page afin que nous puissions détecter si vous avez ajouté une agence avec succès.',
    backendProcessesPrompt:
      'La méthode de mapping du produit a été modifiée. Nous prendrons un certain temps pour vous aider à remplacer le mapping de la commande liée au produit.',
    TimeoutErr: 'La demande a expiré, veuillez réessayer plus tard.'
  }
};
