import useTopBanner from 'hooks/useTopBanner';
import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { DSERS_IMG_HOST } from 'utils/constants';
import { CommonDataCollect } from 'utils/utils';

import styles from './AgencyColdStartupBanner.less';

const allowPath = [
  '/home',
  '/order/list',
  '/order/agency/list',
  '/order/unmapped/list',
  '/order/tmall/list',
  '/storemanage/import',
  '/storemanage/myproduct'
];

const allowPathBrazil = [
  '/order/list',
  '/order/agency/list',
  '/order/unmapped/list',
  '/order/tmall/list'
];

const AgencyColdStartUpBanner = ({ path, lang, userId,BrazilBannerNum }) => {
  const [visible] = useTopBanner(path, allowPath);
  const [visibleBrazil] = useTopBanner(path, allowPathBrazil);
  const handleClickBanner = () => {
    CommonDataCollect({
      action: 'agency_order_cold_startup_banner_click',
      event_type: 'agency_order_cold_startup'
    });
  };
  if (!visible || !window.CAINIAO_USER) {
    return null;
  }
  if(BrazilBannerNum && visibleBrazil){
    return null;
  }
  return (
    <a
      href="https://www.dsers.com/blog/product-update-dropship-with-agencies/"
      target="_blank"
      onClick={handleClickBanner}
      className={styles.bannerLink}
    >
      <div className={`${styles.container} ${lang}`}>
        <div>
          <div className={styles.title}>&nbsp;{intl.get('banner.agency_cold_startup.banner_title_1')}&nbsp;</div>
          <br />
          <div className={styles.title}>
            &nbsp;{intl.get('banner.agency_cold_startup.banner_title_2')}&nbsp;
          </div>
          <div className={styles.desc}>
            {intl.get('banner.agency_cold_startup.banner_desc')}
            <img
              className={styles.buttonStar}
              width="14"
              src={`${DSERS_IMG_HOST}/shopify/activity/agency_cold_startup/221017/agency_startup_img_5.png`}
            />
          </div>
        </div>
        <img
          className={styles.bgImg}
          src={`${DSERS_IMG_HOST}/shopify/activity/agency_cold_startup/221017/agency_startup_img_2.png`}
          width="513"
        />
        <div className={styles.button}>
          {intl.get('banner.agency_cold_startup.learn_more_btn')}
          <img
            className={styles.buttonArrow}
            width="17"
            src={`${DSERS_IMG_HOST}/shopify/activity/agency_cold_startup/221017/agency_cold_startup_btn_arrow.png`}
          />
        </div>
      </div>
    </a>
  );
};

AgencyColdStartUpBanner.allowPath = allowPath;

export default AgencyColdStartUpBanner;
