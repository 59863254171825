import intl from 'react-intl-universal';

// 获取消息标题
export const getMsgByType = type => {
  let title = '';
  switch (type) {
    case 1:
      title = intl.get('notification.variants_out_zero_stock');
      break;
    case 2:
      title = intl.get('notification.variants_out_of_stock'); // 商品SKU变更,库存为零
      break;
    case 3:
      title = intl.get('notification.product_out_of_stock'); // 商品下线
      break;
    case 4:
      title = 'The product is available once again.'; // 商品重新上线  这个不处理 2019-11-25
      break;
    case 5:
      title = intl.get('notification.product_price_has_changed'); // 价格变更
      break;
    default:
      break;
  }
  return title;
};

// 获取ae商品变化，影响到几个shopify商品
export const getShopifyProdItems = item => {
  const result = [];

  if (item && item.shopify_prods) {
    // eslint-disable-next-line
    Object.values(item.shopify_prods).forEach(i => {
      i.forEach(ii => {
        if (ii.productid) result.push(ii);
      });
    });
  }

  return result;
};
export const getShopifyProdItemsForStore = (item,stores) => {
  const result = [];
  if (item && item.shopify_prods) {
      stores.forEach(item_2 =>{
        if(item.shopify_prods[item_2.id]){
          result.push({[item_2.shop_name]:item.shopify_prods[item_2.id]})
        }
      })
  }
  return result;
};
