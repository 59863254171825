import React, { memo } from 'react';
import moment from 'moment';
import classes from './Banner.less';
import { getBannerStatus } from './service';
import useRequest from './useRequest';
import useTopBanner from '../../hooks/useTopBanner';
import intl from 'react-intl-universal';
const lang = localStorage.getItem('lang');

moment.locale('en');

if (lang === 'fr-FR') {
  moment.locale('fr');
} else if (lang === 'pt-PT') {
  moment.locale('pt');
}else if (lang === 'de-DE') {
  moment.locale('de');
}else if (lang === 'es-ES') {
  moment.locale('es');
}else if (lang === 'it-IT') {
  moment.locale('it');
}

const paths = [
  '/home',
  '/report',
  '/supplier_optimizer',
  '/storemanage/import',
  '/storemanage/myproduct',
  '/order/list',
  '/order/tmall/list',
  '/order/agency/list',
  '/order/unmapped/list',
  '/order/archived',
  '/tracking',
  '/settings',
  '/csv'
];
export default memo(function Index({ path }) {
  const [visible] = useTopBanner(path, paths);
  if (!visible) {
    return null;
  }
  return <PayBanner />;
});
// 付费 Banner
function PayBanner() {
  const [data] = useRequest(getBannerStatus);
  if (data?.startTime && Number(data?.startTime) - 3600 * 24 * 14  < moment.utc().unix()){
    return (
        <div className={classes.beforeMigrationBanner} onClick={()=>{
          window.open('https://www.dsers.com/blog/dsers-new-platform-migration/')
        }}>
          <div className={classes.title}>
            {intl.get('beforeMigration.title')}
          </div>
          <div>
            {intl.get('beforeMigration.one')} {moment.unix(Number(data?.startTime)).utc().format('LL')}(UTC+0), {intl.get('beforeMigration.two')}.<br />
            {intl.get('beforeMigration.there')}.
          </div>
        </div>
    );
  }
  return null;
}
