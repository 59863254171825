export default {
  about: {
    title: 'Welcome',
    description: `At DSers, we believe everyone deserves a fast and efficient ordering method.
                  Efficiency and Innovation are our main goal and we strive in removing any technical barriers or difficulties that can prevent dropshippers from selling goods and closing orders.
                  We're excited to help you on your journey !`,
    our_story: 'Our Story',
    our_story_1: `DSers began in 2018. After experiencing multiple order management software,
                  we realized that the existing order management solutions processing speed was not enough to alleviate the repetitive work of Dropshipper.
                  Traditional order processing services are too complex, time consuming and costly to manage.`,
    our_story_2: 'We created the DSers.com website and considered the user\'s point of view. We want to provide a platform that doesn\'t require you to spend hours processing orders. We keep it simple so users can focus on improving their marketing and branding. The most important thing is - it is free. You can set up logistics price screening to reduce costs, manage your merchandise, or start batch processing your order.',
    our_story_3: 'After seeing an increase in demand for e-commerce solutions, we developed the only fully functional, commission-free online management platform that allows Dropshippers to manage their own orders and items on sale.',
    our_story_4: 'Today, we are proud to support independent individuals and teams around the world. Every Dropshipper should have a platform that simplifies things and frees hands to close orders, and we\'re happy to see you spend more time on increasing your turnover.',
    our_values: 'Our Values',
    our_values_1: 'Everyone should have an order management software that can really save time and cost. We believe that everyone should have a smooth workflow. If you can point and click, you can use our free and intuitive bulk order management software.',
    our_values_2: 'Our customers mean the world',
    our_values_3: 'At DSers, we strive to provide the products our customers need. A large part of our brainstorming process is to review our customer feedback to ensure that you are properly handled and ensure that we develop the new features that you need.',
    our_values_4: 'Internal manufacturing',
    our_values_5: 'We are personally committed to providing the best service. From customer support to product design and development, everything is provided by our professional (and cute) team.'
  }
};
