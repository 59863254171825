// 临时的一些文案

export default {
  chore: {
    banner: {
      content: 'Découvrez le chemin parcouru par vos colis!',
      learn_more: 'EN SAVOIR PLUS'
    }
  }
};
