
    export default 
    {
    "partner": {
        "public": {
            "change": "Cambiar",
            "cancel": "Cancelar",
            "save": "Guardar",
            "reset": "Restablecer"
        },
        "signIn": {
            "title": "Bienvenido(a) de nuevo",
            "log_in": "Iniciar sesión",
            "forgot_password": "¿OLVIDASTE LA CONTRASEÑA?",
            "no_account": "¿No tienes una cuenta?",
            "become_partner": "Hazte socio",
            "email": "Correo electrónico"
        },
        "signUp": {
            "title": "¡Hazte socio de Dsers !",
            "register": "Registrarse",
            "sign_in": "Iniciar sesión",
            "have_account": "¿Tienes una cuenta?",
            "email": "Correo electrónico de contacto",
            "email_format_tips": "Por favor, introduce una dirección de correo electrónico",
            "email_is_value_tips": "Por favor, introduce un correo electrónico de contacto",
            "email_placeholder_tips": "Utilizado para poder contactarte y crear la cuenta",
            "pwd": "Contraseña",
            "pwd_is_value_tips": "Por favor, escribe la contraseña",
            "pwd_placeholder_tips": "Por favor, ingresa una contraseña",
            "pwd_font_num_tips": "La contraseña debe tener entre 8 y 16 caracteres",
            "pwd_format_tips": "La contraseña debe contener al menos 1 letra mayúscula, 1 letra minúscula y 1 número",
            "confirm_Password": "Confirmar contraseña",
            "confirm_pwd_placeholder_tips": "Por favor, ingresa una contraseña de confirmación",
            "confirm_pwd_is_value_tips": "¡Por favor, confirma tu contraseña!",
            "confirm_pwd_contrast_tips": "¡Las dos contraseñas ingresadas no coinciden!",
            "name": "Nombre completo",
            "name_is_value_tips": "Por favor, escribir caracteres en inglés con una longitud de 1 a 100",
            "name_placeholder_tips": "Por favor, dinos tu nombre",
            "country": "País",
            "country_is_value_tips": "Por favor, selecciona un país principal",
            "country_placeholder_tips": "Por favor, selecciona tu país",
            "business_type": "Tipo de negocio",
            "business_type_is_value_tips": "Por favor, especifica el tipo de negocio",
            "business_type_placeholder_tips": "Por favor, selecciona tu tipo de negocio",
            "partner_url": "Tu sitio web/App/canal URL",
            "partner_url_is_value_tips": "Por favor, introduce una URL que empiece por HTTP o HTTPS",
            "partner_url_placeholder_tips": "Por favor, comparte el link de tu negocio",
            "partner_url_font_num_tips": "Demasiados caracteres, por favor, ingresa 1000 caracteres como máximo.",
            "partner_url_format_tips": "Por favor, introduce una URL que empiece por HTTP o HTTPS",
            "partner_id": "ID del Canal",
            "partner_id_tips": "Puedes añadir varios ID de canales aquí, y el nuevo ID del canal se generará de acuerdo con las reglas de la plataforma y no podrá ser modificado",
            "partner_id_is_value_tips": "Por favor, ingresa tu ID del Canal",
            "partner_id_is_value_format_tips": "El ID del canal no puede terminar con un número",
            "partner_id_placeholder_tips": "Se utiliza para crear tu link rastreable",
            "partner_id_font_num_tips": "Demasiados caracteres, por favor escribe 100 caracteres como máximo.",
            "paypal_email": "Dirección de correo electrónico de PayPal (opcional)",
            "paypal_email_placeholder_tips": "Puedes completarlo más tarde en la cuenta",
            "remark": "Cuéntanos más",
            "remark_placeholder_tips": "Haznos saber más sobre cómo quieres que trabajemos juntos",
            "remark_font_num_max_tips": "Demasiados caracteres, por favor, escribe 1000 caracteres como máximo.",
            "remark_font_num_min_tips": "No hay suficientes caracteres, por favor, escribe 200 caracteres como mínimo.",
            "remark_is_value_tips": "Cuéntanos más sobre ti"
        },
        "account": {
            "paypal_email": "Dirección de correo electrónico de PayPal",
            "paypal_email_placeholder_tips": "Por favor, indícanos tu dirección de correo electrónico de PayPal"
        },
        "home": {
            "title": "Panel de control",
            "commission_on_hold": "Comisión en espera",
            "commission_confirmed": "Comisión confirmada",
            "request_commission": "Solicitar comisión",
            "seven_days_tips": "Hemos recibido tu solicitud. El equipo la revisará y se pondrá en contacto contigo en 7 días laborables. Gracias por tu paciencia."
        },
        "Reset_email_module": {
            "title": "Nueva dirección de correo electrónico",
            "email_tips": "Por favor, introduce una dirección de correo electrónico.",
            "code_tips": "Código incorrecto. Verifícalo e intenta de nuevo.",
            "send_code": "Enviar código",
            "verify": "Verificar",
            "send_prompt": "Enviaremos un código de verificación a la nueva dirección de correo electrónico. Por favor, cópialo e introdúcelo a continuación para verificar tu cuenta",
            "code_title": "Introduce el código para verificar",
            "get_code": "Obtén el código de nuestro correo electrónico de verificación e introdúcelo aquí para verificarlo"
        },
        "reset_pass_word": {
            "title": "Restablecer contraseña",
            "new_pwd_placeholder_tips": "Nueva contraseña",
            "new_pwd_value_no_tips": "La contraseña no puede estar vacía",
            "old_pwd_placeholder_tips": "Contraseña anterior",
            "old_pwd_value_no_tips": "¡Por favor, introduce tu contraseña anterior!",
            "old_pwd_font_num_tips": "La contraseña debe tener entre 8 y 16 caracteres",
            "old_pwd_format_tips": "La contraseña debe contener al menos 1 letra mayúscula, 1 letra minúscula y 1 número",
            "confirm_new_pwd_value_no_tips": "La contraseña no puede estar vacía",
            "confirm_new_pwd_contrast_tips": "¡Las contraseñas no coinciden!"
        },
        "partner_layout": {
            "account": "Cuenta",
            "dashboard": "Panel de control"
        },
        "password_not_match_twice": "Las contraseñas no coinciden",
        "password_error_long": "La contraseña debe tener entre 8 y 16 caracteres",
        "password_error_format": "La contraseña debe contener al menos 1 letra mayúscula, 1 letra minúscula y 1 número"
    }
}