import { Get, Post, Put, Delete } from '../utils/request';
import { dsersBFFApiHost } from '../common/sysconfig';
import { WooCommerceAlert } from './global';

export async function AliexpressAuthCallback(payload) {
  return Get('/ali/auth/callback', payload);
}

// getCuntryAndTrack
export async function getCuntryAndTrack(payload = {}) {
  return Get('/setting/cuntryandtrack', payload);
}

// getCountry 获取AliExpress支持的国家
export async function getCountry(payload = {}) {
  return Get('/set/country', payload);
}

// getLogistics 获取AliExpress 支持的物流方式
export async function getLogistics(payload = {}) {
  return Get('/set/logistics_services', payload);
}

// getTMLogistics 获取TM 支持的物流方式
export async function getTMLogistics(payload = {}) {
  return Get('/set/logistics_services/tmall', payload);
}

// getTrackingCompany 获取Shopify 支持的TrackingCompany 信息
export async function getTrackingCompany(payload = {}) {
  return Get('/set/tracking_company', payload);
}

// 获取税收
export async function getTaxRevenue(payload = {}) {
  return Post('/set/product/get', payload);
}

// 设置税收
export async function setTaxRevenue(payload = {}) {
  return Post('/set/product/update', payload);
}

export async function getCity(payload = {}) {
  return Get('/set/prov', payload);
}

export async function getDefaultCountryAndTrack(payload = {}) {
  return Get('/set/shipping', payload);
}

export async function saveCountryAndTrack(payload = {}) {
  return Put('/set/shipping', payload);
}

export async function getGeneral(payload = {}) {
  return Put('/set/order/desc', payload);
}

export async function saveIoss(payload = {}) {
  return Post('/set/ioss', payload);
}

export async function getIoss(payload = {}) {
  return Get('/set/ioss', payload);
}

export async function getGetGeneral(payload = {}) {
  return Get('/set/order_setting', payload);
}

export async function postPhoneNo(payload = {}) {
  return Put('/set/order/phone', payload);
}

export async function saveCountryAdvanced(payload = {}) {
  return Post('/setting/advanced', payload);
}

export async function postShippingMethod(payload = {}) {
  return Put('/set/shipping_method', payload);
}

export async function settingNotification(payload = {}) {
  return Put('/set/notify', payload);
}

export async function getTrackingUrl(payload = {}) {
  return Get('/set/tracking_urls', payload);
}

export async function getTracking(payload = {}) {
  return Get('/set/tracking', payload);
}

export async function getTrackShipping(payload = {}) {
  return Get('/setting/tracking_company/list', payload);
}

export async function postTracking(payload = {}) {
  return Put('/set/tracking', payload);
}

export async function getOtherSta(payload = {}) {
  return Get('/setting/other/order_sync_status', payload);
}

export async function postOtherSta(payload = {}) {
  return Put('/set/order_no_sync', payload);
}

export async function getPricingList(payload = {}) {
  return Get('/set/pricing', payload);
}

export async function saveAssignCents(payload = {}) {
  return Put('/set/pricing_cents', payload);
}

export async function savePricingItem(payload = {}) {
  return Put('/set/pricing', payload);
}

export async function getUpdataStatus(payload = {}) {
  return Get('/set/sync_price', payload);
}

export async function upDataPricing(payload = {}) {
  return Post('/set/sync_price', payload);
}

export async function changePricingStatus(payload = {}) {
  // return Post("/setting/pricing/store", payload);
  return Post('/set/pricing', payload);
}

export async function getTransactionsRecord(payload = {}) {
  return Get('/supply/setting/transactions', payload);
}

export async function getBillsRecord(payload = {}) {
  return Get('/supply/setting/all_bills', payload);
}

export async function getPrimaryContact(payload = {}) {
  return Get('/supply/setting/address_info', payload);
}

export async function savePrimaryContact(payload = {}) {
  return Post('/supply/setting/primary_contact', payload);
}

export async function saveBillingInformation(payload = {}) {
  return Post('/supply/setting/billing', payload);
}

export async function getSupplier(payload = {}) {
  return Get('/supply/setting/verified_supplier', payload);
}

export async function postSupplier(payload = {}) {
  return Post('/supply/setting/verified_supplier', payload);
}

export async function getBalance(payload = {}) {
  return Get('/supply/setting/balance', payload);
}

export async function getGAuth(payload = {}) {
  return Get('/auth/opt', payload);
}

export async function getPaypalStatus(payload = {}) {
  return Get('/supply/setting/lastpayment', payload);
}

export async function getUseAddress(payload = {}) {
  return Get('/supply/setting/sample/address', payload);
}

export async function saveSampleAddress(payload = {}) {
  return Put('/supply/setting/sample/address', payload);
}

export async function setOtherNASta(payload = {}) {
  return Put('/set/order_is_na', payload);
}

export async function getShowSupplierTab(payload = {}) {
  return Get('/supply/user/status', payload);
}

export async function getShopifyStoreStatus(payload = {}) {
  return Get('/auth/shopify/status', payload);
}

export async function updateShopfiy(payload = {}) {
  return Get('/auth/shopify/bind', payload);
}

export async function unBindAli(payload = {}) {
  return Post('/auth/ali/status', payload);
}

export async function reconnectAli(payload = {}) {
  return Get('/auth/ali/rect', payload);
}

export async function updateUserName(payload = {}) {
  return Post('/users/change/name', payload);
}

export async function sendCode(payload = {}) {
  return Post('/users/email/send', payload);
}

export async function comfirmEmailChange(payload = {}) {
  return Post('/users/email/change', payload);
}

export async function getProductList(payload = {}) {
  return Get('/prod/ship/set', payload);
}

export async function getAeProductDetail(payload = {}) {
  return Get('/prod/ali', payload);
}

export async function getAeProductFreight(payload = {}) {
  return Post('/prod/freight', payload);
}

export function getIsTendaysDelivery(payload = {}) {
  return Get('/onedata_propool/product_pool/by_ids', payload);
}

export async function saveShip(payload = {}) {
  return Post('/prod/ship/set', payload);
}

export async function sendCashBackCode(payload = {}) {
  return Put('/set/cb/set', payload);
}

export async function setUserStatus(payload = {}) {
  WooCommerceAlert(payload?.data?.status || '00000000', 'dropshippersetting');
  return Post('/users/status', payload);
}

export async function getNotifyAutoSetting(payload = {}) {
  return Get('/notify/sync_available', payload);
}

export async function postNotifyAutoSetting(payload = {}) {
  return Post('/notify/sync_available', payload);
}

export async function admitadCheck(payload) {
  return Get('/admitad/code/check', payload);
}

export async function putSyncShopifyNotesStatus(payload = {}) {
  return Put('/set/order_notes_sync', payload);
}

export async function postSaveCurrency(payload = {}) {
  return Post('/currency/update', payload);
}

export async function putPricingCurrency(payload = {}) {
  return Put('/set/pricing/currency', payload);
}

export async function getPricingCurrency(payload = {}) {
  return Get('/set/pricing/currency', payload);
}

export async function postUpdateStore(payload = {}) {
  return Post('/currency/update_store_currency', payload);
}

export async function postTimeZone(payload = {}) {
  return Post('/set/time_zone', payload);
}

export async function putCancelFulfillment(payload = {}) {
  return Put('/fulfillment/set/cancel', payload);
}

export async function GetUserAllPhoneSet(payload = {}) {
  return Get('/set/order_setting/phone', payload);
}

export async function UpdateUserPhoneNumber(payload = {}) {
  return Put('/set/order_setting/phone', payload);
}

export async function DeleteUserPhoneNumberSet(payload = {}) {
  return Delete('/set/order_setting/phone', payload);
}

export async function getChildAccount(payload = {}) {
  return Get('/uaa/api/1.0/user/child_accounts/shopify', payload, true);
}
export async function getTrackingList(payload = {}) {
  return Get('/set/tracking_list', payload);
}
export async function postTrackingList(payload = {}) {
  return Post('/set/tracking_save', payload);
}

export  function getTMpayment(payload = {}) {
  return Get('/set/payment', payload);
}

export async function sendTMpayment(payload = {}) {
  return Post('/set/payment', {data:payload});
}

// 获取支付列表
export async function getPayList() {
  return Get(dsersBFFApiHost + '/dsers-pay-bff/v1/bill/list',{ isCustomApi: true})
}

// 获取 pdf/csv 链接
export async function getFileUrlLink(payload) {
  return Get(dsersBFFApiHost + '/dsers-pay-bff/v1/bill/file_url',{data:payload,isCustomApi: true})
}

// 获取 pdf/csv 链接 tracking Id
export async function getFileUrl(payload) {
  return Post(dsersBFFApiHost + '/dsers-pay-bff/v1/bill/download',{data:payload,isCustomApi: true})
}

// 根据 trackingId获取链接
export async function accordIdGetLink(payload) {
  return Get(dsersBFFApiHost + '/dsers-pay-bff/v1/bill/download_url',{data:payload,isCustomApi: true})
}

export async function getAgencySettings(payload) {
   return Get('/set/merchant/setting', payload);
}

export async function updateAgencyOrderGlobalMessage(payload) {
  return Put('/set/merchant/desc', payload);
}

export async function updateAgencyAutomaticPlaceStatus(payload) {
  return Post('/set/merchant/automatically/place', payload);
}