export default {
    onBoarding: {
        selectPlatfrom:"Seleccionar la plataforma",
        selectPlatfromTitle:'Por favor, selecciona la plataforma de la tienda que quieres vincular.',
        linkShopify:'Vincular una tienda Shopify',
        linkWoo:'Vincular una tienda WooCommerce',
        linkWix:'Vincular una tienda Wix',
        linkCsv:'Utilizar archivos CSV de otras plataformas',
        logout:'Cerrar Sesión',
        linkAeTitle:'Vincular a AliExpress'
    }
}