
    export default 
    {
    "admitad": {
        "title": "Admitad di DSers è live!",
        "p1": "La cooperazione ufficiale fra DSers e Admitad è ora online.",
        "p2": "Si prega di controllare il seguente articolo per configurare tutto: ",
        "p3": "https://www.blog.dserspro.com/post/how-to-use-admitad-cashback-on-dsers-pro",
        "p4": "Una volta fatto, ottieni un nuovo codice nell'offerta AliExpress WW.",
        "p5": "Cambia il codice che stai usando attualmente, e rimpiazzalo con il nuovo codice.",
        "p6": "Nota: i codici che non sono affiliati con DSers verranno interrotti il mese prossimo"
    }
}