export const confirmLanguage = {
  'en-US': 'confirm',
  'fr-FR': 'confirmer',
  'pt-PT': 'confirmar',
  'de-DE': 'bestätigen',
  'it-IT': 'confermare',
  'es-ES': 'confirmar'

};

export { default as currencySymbolMap } from './currencySymbolMap';

export const blogLanguage = {
  'en-US': 'https://www.blog.dserspro.com/',
  'fr-FR': 'https://www.blog.dserspro.com/accueil',
  'pt-PT': 'https://www.blog.dserspro.com/home-pt'
};

export const BASE_URL = '/api/v1';
export const BASE_OUT_URL = '/api';

// export const goToWoo =
//   process.env.BUILD_TARGET !== 'prod'
//     ? 'https://dev.dsers.com/woo/'
//     : 'https://www.dsers.com/woo/';


export const goToWoo =
  process.env.BUILD_TARGET !== 'prod'
    ? 'https://dev.dsers.com/woo/'
    : 'https://www.dsers.com/woo/';

const apiUrl =
  process.env.BUILD_TARGET !== 'prod'
    ? 'https://atc.dsers.com'
    : 'https://ac.dsers.com'; // 生产环境必须是 https://ac.dsers.com

export const wixApiHost =
  process.env.BUILD_TARGET !== 'prod'
    ? 'https://api-fat.dsers.com:30080'
    : 'https://api.dsers.com';

export const dsersBFFApiHost =
  process.env.BUILD_TARGET !== 'prod'
    ? 'https://api-gw-test.dsers.com'
    : 'https://api-gw.dsers.com';
export const mvpBffHost =
   process.env.BUILD_TARGET === 'prod'
     ? 'https://bff-api-gw.dsers.com'
     : 'https://bff-api-gw-test.dsers.com';

// 注意！！！ 因为会动态检测 api 服务可用性，因此不能通过赋值方式获取 api 地址，eg. const host = window.DSERS_API_CONFIG.HOST
// 唯一的方式: 通过object 点语法，i.e. window.DSERS_API_CONFIG.HOST
window.DSERS_API_CONFIG = {
  HOST: apiUrl
};
