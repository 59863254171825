export default {
  support: {
    title: 'Support',
    description:
      'Welcome to DSers support. We are here to help you boost your dropshipping business with heart and soul.',
    email: 'EMAIL',
    email_dec:
      'Drop an email with your questions or inspirations! <br/> We will answer asap.',
    email_btn: 'Email Support',
    live_chat: 'LIVE CHAT',
    live_chat_dec:
      'Message us for trouble shooting. We are right there <br /> to start a live chat.',
    live_chat_btn: 'Message Us',
    facebook: 'FACEBOOK',
    facebook_dec:
      'Follow our Facebook page to get the latest informations about DSers and dropshipping.',
    facebook_btn: 'Facebook',
    next_level: 'Take your business to the next level',
    try_now: 'Try it now risk free.',
    get_started: 'Get started',
    partnership: 'Partnership'
  }
};
