
    export default 
    {
    "guidance": {
        "welcome": "Bienvenido(a) a Dsers",
        "need_steps": "Sólo necesitas 4 pasos para estar listo",
        "start_tutorial": "Iniciar el tutorial",
        "before_starting": "Antes de empezar,<br /> por favor, asegúrate de DESACTIVAR la función de autoprocesamiento de Shopify.<br /> De lo contrario, no podrás procesar los pedidos en DSers.<br />Go para Shopify - Ajustes - Verifica para desactivarlo.",
        "previous": "Anterior",
        "next": "Siguiente",
        "start": "Comenzar",
        "link_to_more_stores": "Link a más tiendas",
        "bind_ali_over": "Cuenta AliExpress de lista blanca",
        "link_to_ae": "Link a AliExpress",
        "home": "Inicio",
        "my_product": "Mis productos",
        "setting": "Ajustes",
        "shipping": "Envíos",
        "orders": "Pedidos",
        "new_feature": "Nueva función",
        "notification": "Notificación",
        "blog": "BLOG",
        "step1": {
            "title": "En primer lugar, vincula Dsers a tu tienda de Shopify",
            "step1_dec": "Haz clic en el enlace para agregar más botones de las tiendas en la parte superior de la página.",
            "step2_dec": "Selecciona Shopify como plataforma y completa el nombre de la tienda para vincularla a Dsers."
        },
        "step2": {
            "title": "Luego, vincula Dsers a tu cuenta AliExpress.",
            "step1_dec": "Haz clic en el botón Vincular a AliExpress en la parte superior de la página.",
            "step3_dec": "Aplica a la lista blanca de AliExpress. Esto te dará privilegios y te permitirá gestionar pedidos masivos y reducir la tasa de fallos."
        },
        "step3": {
            "title": "A continuación, asigna los productos a un proveedor de AliExpress",
            "dec": "Haz clic en el botón situado a la derecha de la ficha de un producto para asignar los proveedores de AliExpress a tus productos de Shopify. Dsers sabrá cómo procesar tus pedidos a AliExpress después de eso.",
            "map_multiple": "Dsers te permite mapear múltiples proveedores según:",
            "map_from_to": "1. Enviar desde/hacia diferentes países",
            "advance": "Consultar detalles sobre <a\n\t\t\thref=\"https://help.dsers.com/advanced-mapping-for-shopify/\"\n\t\t\ttarget=\"_blank\"\n\t\t\t>el mapeo</a>  <a\n\t\t\tclass={foreignLink}\n\t\t\thref=\"https://help.dsers.com/advanced-mapping-for-shopify/\"\n\t\t\ttarget=\"_blank\"\n\t\t\t>\n\t\t\t</a>",
            "upsell_promotion": "2. Promoción de ventas incrementales",
            "bogo_bundle": "Consultar detalles sobre <a\n\t\t\thref=\"https://help.dsers.com/create-buy-one-get-one-offers-for-shopify/\"\n\t\t\ttarget=\"_blank\"\n\t\t\t>BOGO</a>   <a\n\t\t\tclass={foreignLink}\n\t\t\thref=\"https://help.dsers.com/create-buy-one-get-one-offers-for-shopify/\"\n\t\t\ttarget=\"_blank\"\n\t\t\t>\n\t\t\t</a> / \n\t\t\t<a\n\t\t\thref=\"https://help.dsers.com/create-bundles-of-products-for-shopify/\"\n\t\t\ttarget=\"_blank\"\n\t\t\t>PAQUETES del</a> <a\n\t\t\tclass={foreignLink}\n\t\t\thref=\"https://help.dsers.com/create-bundles-of-products-for-shopify/\"\n\t\t\ttarget=\"_blank\"\n\t\t\t>\n\t\t\t</a> mapeo."
        },
        "step4": {
            "title": "3. No olvides configurar el método de envío",
            "dec": "Para el último paso de la configuración, por favor, establece tu método de envío por defecto en Ajustes - Envío. Puedes configurarlo basándote en el país o en el  <a\n\t\t\thref=\"https://help.dsers.com/set-shipping-method-for-all-products-for-shopify/\"\n\t\t\ttarget=\"_blank\"\n\t\t \t >producto de AliExpress</a>\n\t\t\t  <a\n\t\t\t  class={foreignLink}\n\t\t\t  href=\"https://help.dsers.com/set-shipping-method-for-all-products-for-shopify/\"\n\t\t\t  target=\"_blank\"\n\t\t\t>\n\t\t\t</a> ."
        },
        "step5": {
            "title": "Por último, puedes hacer pedidos masivos",
            "dec": "Selecciona todos tus pedidos en la pestaña de Pedidos en espera y luego haz clic en el botón Realizar pedido a AliExpress",
            "tip": "Revisa los detalles del pedido y el método de envío. Y ya está."
        },
        "after": {
            "dec": "Después de realizar el pago, deja el resto a Dsers. <br />Dsers SINCRONIZARÁ AUTOMÁTICAMENTE para ti",
            "p1": "Sincronización automática del estado del pedido y del número de seguimiento desde AliExpress a Dsers cada 12 horas",
            "p2": "Sincronización automática de los números de seguimiento de Dsers a Shopify 1-2 horas después de la sincronización de AliExpress",
            "p3": "Activar automáticamente el correo de confirmación de envío de Shopify a tus clientes"
        },
        "actions": {
            "title": "Las acciones que se indican a continuación impedirán que Dsers procese o<br /> sincronice los pedidos. Por favor:",
            "do_not1": "NO proceses manualmente los pedidos en Dsers o Shopify si los vas a procesar con Dsers.",
            "do_not2": "NO actives la función de autoprocesamiento en Shopify.",
            "do_not3": "NO solicites el procesamiento de pedidos en Shopify."
        },
        "get_ebook": "Obtén el E-book gratuito o <br />contacta con el servicio de atención al cliente aquí",
        "check_again": "Consulta este tutorial de nuevo aquí",
        "confused": "Si todavía tienes dudas sobre<br /> cómo usar Dsers",
        "guidance_again": "Mira los videos del tutorial de nuevo",
        "order": "How to place multiple orders with DSers?",
        "import": "How to import products to DSers?",
        "total_sales": "Ventas totales",
        "shopify_sales": "Ventas en Shopify",
        "ali_orders": "Pedidos de Ali",
        "supplier_cost": "Coste del proveedor"
    }
}