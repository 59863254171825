import { routerRedux } from 'dva/router';
import qs from 'qs';

import {
  LoginPartnerAccount,
  RegisterPartnerAccount,
  SendResetPasswordEmail,
  ResetPassword,
  SendChangeEmailMsg,
  ChangeEmailConfirm,
  ChangePassword,
  GetDashBoardData,
  GetPartnerAccountInfo,
  UpdatePartnerAccount,
  GetCountryList
} from '../services/partner';

const path = (() => {
  const { href, origin } = window.location;
  return href.replace(origin, '');
})();

export default {
  namespace: 'partner',
  state: {
    loginData: null,
    showVerification: null,
    partnerLoginInfo: null,
    userInfo: {},
    loginInfo: false,
    submitting: false
  },
  subscriptions: {
    setup({ dispatch }) {
      const token = window.localStorage.getItem('partner-authority');
      // 如果存在 token，等待尝试自动登录，渲染一个 loading，避免加载 login page，造成闪烁
      if (token) {
        dispatch({ type: 'queryUserInfoLoading', payload: true }); // 自动登录时不让用户看到登录界面
      }

      // 自动登录
      // const autoLogin = () => {
      //   // 登录状态存留
      //   if (token) {
      //     dispatch({ type: 'updateUserInfo' });
      //   }
      // };
    }
  },
  effects: {
    // partner登录
    *partnerLogin({ payload: { resolve, reject, ...params } }, { call, put }) {
      yield put({
        type: 'setLoginInBtn',
        payload: true
      });
      try {
        const response = yield call(LoginPartnerAccount, params);
        if (response && response.code === 2000 && response.data.token) {
          // 登录成功后存入token
          localStorage.setItem('partner-authority', response.data.token);
          resolve();

          yield put({
            type: 'setLoginInfo',
            payload: response.data
          });
          yield put({
            type: 'setLoginInBtn',
            payload: false
          });
        } else {
          yield put({
            type: 'changeLoginStatus',
            payload: response
          });
          yield put({
            type: 'setLoginInBtn',
            payload: false
          });
        }
      } catch (error) {
        reject(error);
      }
    },
    // partner注册
    *partnerRegister({ payload }, { call, put }) {
      try {
        const response = yield call(RegisterPartnerAccount, payload);
        if (response && response.code === 2000) {
          localStorage.setItem('partner-authority', response.data.token);
          yield put({
            type: 'setLoginInfo',
            payload: response.data
          });

          // window.location.href = '/app/';
        } else {
          console.log('register error: ', response);
        }
      } catch (error) {
        console.log('register error: ', error);
      }
    },
    // 发送重置密码邮件
    *setPasswordEmail({ payload }, { call }) {
      yield call(SendResetPasswordEmail, payload);
    },
    *ResetPassword({ payload }, { call }) {
      yield call(ResetPassword, payload);
    },
    *ChangeEmailMsg({ payload }, { call }) {
      yield call(SendChangeEmailMsg, payload);
    },
    // 国家列表
    *GetCountryList({ payload }, { call }) {
      yield call(GetCountryList, payload);
    },
    // 获取全部金钱及可取金钱
    *GetDashBoardData({ payload }, { call }) {
      try{
         yield call(GetDashBoardData, payload);
      }catch(e) {

      }
     
    },
    // 获取partner用户详情
    *GetPartnerAccountInfo({ payload }, { call }) {
      yield call(GetPartnerAccountInfo, payload);
    },
    // 更新pantner账户数据
    *UpdatePartnerAccount({ payload }, { call }) {
      yield call(UpdatePartnerAccount, payload);
    },
    // 更新pantnering邮箱
    *SendChangeEmailMsg({ payload }, { call }) {
      yield call(SendChangeEmailMsg, payload);
    },
    // 修改邮箱确认
    *ChangeEmailConfirm({ payload }, { call, put }) {
      yield call(ChangeEmailConfirm, payload);
    },
    // 修改密码
    *ChangePassword({ payload }, { call, put }) {
      const response = yield call(ChangePassword, payload);
      if (response && response.code === 2000) {
        window.localStorage.removeItem('partner-store');
        window.localStorage.removeItem('partner-authority');
        yield put({
          type: 'resetPwd',
          payload: false
        });
        routerRedux.push('/partner/login');
      }
    },
    // eslint-disable-next-line require-yield
    *logout(_, { put }) {
      console.log(111)
      window.localStorage.removeItem('partner-store');
      window.localStorage.removeItem('partner-authority');
      window.location.href = '/partner';
    }
  },
  reducers: {
    // 登录错误提示
    changeLoginStatus(state, { payload }) {
      return {
        ...state,
        loginData: payload,
        status: payload.code,
        msg: payload.msg,
        showVerification: payload.data
      };
    },
    setLoginInBtn(state, { payload }) {
      return {
        ...state,
        submitting: payload
      };
    },
    setLoginInfo(state, { payload }) {
      return {
        ...state,
        partnerLoginInfo: payload,
        loginInfo: true
      };
    },
    setUserInfo(state, { payload }) {
      return {
        ...state,
        userInfo: payload
      };
    },
    queryUserInfoLoading(state, { payload }) {
      return {
        ...state,
        loginInfo: payload
      };
    },
    resetPwd(state, { payload }) {
      return {
        ...state,
        loginInfo: payload
      };
    }
  }
};
