
    export default 
    {
    "product_split": {
        "split": "Dividi",
        "title": "Split product",
        "split_by_option": "Dividi per opzione",
        "split_by_option_dec": "Select the option that will be split to another product",
        "option_tip": "Creeremo { length } prodotti, ogni prodotto conterrà solo { optionName } variant(s) ( {values} )",
        "or": "o",
        "split_by_variant": "Dividi per variante",
        "split_by_variant_dec": "Seleziona le varianti che saranno divise in un altro prodotto",
        "tip": "Le varianti selezionate saranno utilizzate er creare un nuovo prodotto e il prodotto attuale conserverà ancora tutte le varianti originali",
        "select_split_mothod": "Si prega di selezionare un metodo di divisione",
        "confirm_split": "Split Product",
        "confirm_split_desc": "Are you sure you want to split the product? It will be split into different products.",
        "product_not_found": "The product you entered was not found",
        "network_issue": "The operation failed due to network issue, please try again"
    }
}