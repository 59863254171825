import { Get, Post } from '../utils/request';
import { wixApiHost } from '../common/sysconfig';

export async function getShopifyRedirect(payload = {}) {
  return Get('/auth/shopify/rect', payload);
}

export async function getShopifyCallback(payload = {}) {
  return Get('/auth/shopify/cb', payload);
}

export async function aegetShopifyCallback(payload = {}) {
  return Get('/auth/ali/cb', payload);
}

export async function aegetcheck(payload = {}) {
  return Post('/auth/ali/cb', payload);
}

export async function subLogin(payload = {}) {
  return Post('/uaa/api/1.0/login', payload, true);
}

// export async function subLogin(payload = {}) {
//   return Post('/users/login', payload);
// }

export async function subUnbind(payload = {}) {
  return Get('/auth/shopify/unbind', payload);
}

export async function subRegister(payload = {}) {
  return Post('/users/signup', payload);
}

export async function createShopfiyStore(payload) {
  return Get('/auth/shopify/bind', payload);
}

export async function postCompletePassWord(payload = {}) {
  return Post('/users/reset_info', payload);
}

export async function postAuthWixStoreResult(payload = {}) {
  return Post(wixApiHost + '/aword/api/1.0/statistics/push', { ...payload, isCustomApi: true });
}

export async function getMigrationInfo(payload = {}){
  return Get("/mgnoberlo-core-api/dsers-api/migration/user/status",payload,true);
}

export async function getMigrationStoreInfo(payload = []){
  return Get("/mgnoberlo-core-api/dsers-api/migration/store/status",payload,true);
}

export async function getAllStoresList(payload = {}){
  return Get("/charge/store_list_all",payload);
}

export async function getNewPlatformProductUserInfo(payload = {}){
  return Get("/canary-release",payload);
}