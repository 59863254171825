export default {
  mapping: {
    title: 'Mapping Fournisseurs',
    description:
      'Les détails du produit sur Shopify (tel que le titre, description, images, variantes, etc.) resteront identiques après l\'ajout d\'un fournisseur.', //  d
    description_tip:
      "Veuillez noter: le mapping sera perdu lorsque vous éditer/supprimer/ajouter des options ou variantes sur Shopify.Si vous modifiez le nom d'une variante dans Shopify, nous ne modifierons pas le mapping dans DSers, alors assurez-vous que les variantes correspondent toujours aux nouveaux noms que vous avez définis.",
    tab_btn_basic_mapping: 'Basique',
    tab_btn_advanced_mapping: 'Avancé', // add
    tab_btn_bogo: 'BOGO', // add
    tab_btn_bundle: 'Bundle', // add
    tab_btn_verified: 'Verified', //  delete
    shopify_product: 'Produit Shopify',
    default_ali_product: 'Produit {platform} par Défaut',
    edit_shipping: 'Edit Shipping', // d
    set_shipping: 'Set Shipping', // d
    standProductNum: "Jusqu'à 5 produits AliExpress peuvent être importé",
    import_a_product: 'Import a product', // d
    substitute_ali_product: 'Produit {platform} Secondaire',
    switch_mapping: 'Inverser le fournisseur par défaut et le fournisseur secondaire',
    repeat_alert: 'Ce fournisseur est déjà défini comme fournisseur par défaut ou secondaire et ne peut pas être encore utilisé',
    clear_tip_alert: `Si vous changez l'article pour le définir comme fournisseur principal, le fournisseur secondaire sera vidé.`,
    forbidden_alert: `Le mapping de base ne peut être utilisé que pour le mapping avec le fournisseur d'une seule plate-forme de fournisseurs.`,
    second_product_tip:
      'Second option is an option to be used during auto order processing when some variants are not available for defualt option.', // d
    alternate_products: 'Produits alternatifs',
    alternate_products1: 'Add Products',
    import_placeholder: 'Importer URL',
    status: 'Statut', // add
    turn_status: 'Cliquez pour activer cette méthode de {method} mapping', // add
    close_all_mapping:
      'Voulez-vous désactiver toutes les méthodes de mapping? Votre produit ne pourra alors plus être placé vers AliExpress avec DSers.', // add
    open_new_mapping:
      'Voulez-vous activer le Mapping {switch}? Vos commandes seront traitées avec le Mapping {switch}.', // add
    variant: 'Variante', // add
    ship_to: 'Envoyé vers', // add
    supplier_variant: 'Fournisseur & Variante', // add
    import_sp_select_var: 'Importer fournisseur & choisir variante', // add
    import_ae_title: 'Importez un fournisseur en copiant le lien AliExpress ici', //  add
    import_tm_title: 'Importez un fournisseur en insérant un lien Tmall ici', //  add
    import_placeholder: 'Entrer l\'URL AliExpress du produit', // add
    clear: 'Vider', // add
    select_a_variant: 'Choisir une variante', // add
    import_ae_first: 'Importer d\'abord un fournisseur AliExpress', //  add
    import_tm_first: 'Importez un fournisseur Tmall au préalable', //  add
    submit: 'Soumettre', //  add
    getProduct_error: "Le produit entré n'a pas été trouvé",
    advanced_rule:
      'Choisissez les fournisseurs en fonction des pays de destination de vos commandes. Vous pouvez choisir jusqu\'à 10 fournisseurs par variante.', //  add
    bundle_rule:
      "Choisissez un fournisseur pour le produit de votre Bundle. Vous pouvez définir jusqu'à 10 fournisseurs par variante",
    supplier_product: 'Fournisseur Produit',
    more_action: 'Plus d\'action', //  add
    clear_all: 'Tout Vider', //  add
    advanced_more_action_copy: `Copier les SKUs aux autres variantes après avoir mappé la première. Vous pouvez la modifier si vous voulez la changer.`, //  add
    buy: 'ACHETER', //  add
    buy_desc: 'Mapper un fournisseur pour le produit principal que vous vendez', //  add
    buy_more_action_copy:
      'Copier les SKUs aux autres variantes après avoir mappé la première.', //  add
    x: 'X', //  add
    x_desc: 'Quantité du <br /> produit <br /> principal', //  add
    change_qty_to: 'Changer Qté à', //  add
    get: 'OBTENIR', //  add
    get_desc: 'Mapper un fournisseur pour le produit que vous souhaitez offrir', //  add
    get_more_action_copy: `Copier les SKUs aux autres variantes
                          CADEAUX après avoir mappé la première.
                         `, //  add
    y: 'Y', //  add
    y_desc: 'Quantité du <br /> produit cadeau', //  add
    bundle_products: 'Produit Bundle', //  add
    bundle_more_action_copy: `Copier les SKUs aux autres variantes après <br />
                              avoir mappé la première. Vous pouvez la <br />
                              modifier si vous voulez la changer.`, //  add
    // d
    import_btn_tip: `<span>
                      You can set up mapping as below :<br />
                      1. Paste an AliExpress link here.<br />
                      2. Click Import<br />
                      3. Set the product as default/second option<br />
                      4. Fill in ALL options and values to complete mapping<br />
                      <a
                        href="https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/"
                        target="_blank"
                      >
                        Check this article
                      </a>
                    </span>`,
    // d
    import: 'Importer',
    set: 'Définir',
    no_record_yet: 'No record yet', //  d
    ShopifyProduct:"Produit Shopify",
    verified: {
      //  d
      title: 'Product info',
      cost: 'Cost',
      shipping: 'Shipping',
      ePacket: 'ePacket',
      package_request: {
        title: 'Package request',
        option1: 'Bags（defualt)',
        option2: 'Box ($0.2／Package)',
        option3: 'Box with logo($0.2／Package)',
        option4: 'Box with card($0.2／Package)',
        option5: 'Box with logo and card($0.2／Package)'
      },
      upload_logo_file: 'Upload logo file',
      leave_a_meaage: 'Leave a message',
      go_to_setting: 'Go to Setting > Supplier to set up for the whole store',
      sample_on_way: 'Sample is on the way.',
      tracking_no: 'Tracking NO',
      request_sample: 'Request sample',
      save: 'Save',
      disable: 'Disable',
      enable: 'Enable',
      cancel: 'Cancel'
    }, //  d
    publish_first: 'Publish first then add suppliers', // d
    upload_img_tip: 'Image must smaller than 1MB!', // d
    verified_save: 'Saved succeed.', // d
    copy_link_tip: 'Replication fails, goods need to be pushed first', // d
    copy_succ: 'Successfully copied', // d
    copy_fail: 'copy failed', // d
    delete_default_option_tip:
      'Do you want to delete the default option? You can add it again in the record?',
    delete_second_option_tip:
      'Do you want to delete the second option? You can add it again in the record?',
    delete_history_tip:
      'Do you want to remove this product information from product history?', //  d
    yes: 'Oui',
    no: 'Non',
    ok: 'Ok', //  d
    cancel: 'Cancel', // d
    save_option_confirm: {
      // d
      set_shipping_tip: 'Please set up shipping method for substitute product.',
      save_option_two_tip:
        'Please pay attention that activating substitute product might cause extra shipping fee if you have two different suppliers supply for one order.'
    }, // d
    clear_all_shipping: 'Do you want to clear all configuration information?', // d
    save_shippinp_succ: 'Save Success.', // d
    setting_activated: 'Setting activated', // d
    country_shipping: {
      // d
      shipping_setting: 'Shipping Setting',
      ship_to: 'Ship to',
      clear_all: 'Clear All',
      search: 'Search',
      shipping_country: 'Shipping Company',
      shipping_cost: 'Shipping Cost',
      time: 'Estimated Delivery Time',
      tracking_information: 'Tracking information',
      free_shipping: 'Free Shipping',
      available: 'Available',
      unavailable: 'Unavailable',
      not_ship_this_country: 'Ce produit ne peut pas être livré vers ce pays.',
      select_ship_country: 'Veuillez sélectionner un pays de Destination.'
    }, // d
    product_whic: 'Voulez-vous que ce fournisseur soit:',
    default_option: 'Option par Défaut',
    second_option: 'Option Secondaire',
    mapped: 'Mappé',
    default: 'Défaut',
    succeed: 'Réussi',
    repeat_option:
      'Impossible de sauvegardé à cause de la répétition des options de variante',
    select_variant:
      'Veuillez choisir les variantes correspondantes, sinon vous ne pourrez pas sauvegarder le mapping.',
    delete_pool_product:
      'Si vous supprimez ce fournisseur de votre page de mapping, le mapping de votre produit sera supprimé',
    delete_mapping_product:
      `Voulez-vous supprimer la { num, plural, =0 { default } other {seconde} } option? Vous pourrez ajouter l'option à nouveau plus tard.`,
    delete_del_variant_tip:
      'Voulez-vous vraiment supprimer la ou les variantes sur DSers? Une fois supprimées, les commandes pour la ou les variantes ne pourront plus être traitées.',
    same_country_tip: 'Vous ne pouvez pas choisir encore ce pays pour cette variante',
    same_sku_tip: `Si vous voulez le même produit pour le produit principal et le produit offert,
                  veuillez changer la quantité pour le produit principal simplement au lieu de l'importer de nouveau dans la colonne du produit offert.`,
    import_same_sku_tip: 'Você não pode importar a mesma SKU duas vezes para uma variante.',
    Variant_delete_tip_one: 'Vous avez supprimé les variantes ci-dessous sur Shopify, veuillez:',
    Variant_delete_tip_two: '1.Refaire le mapping pour continuer de synchroniser le stock depuis AliExpress.',
    Variant_delete_tip_three: '2.Ajouter un founisseur aux variantes ci-dessous pour placer les commandes des variantes supprimées.',
    overrun_warning: `Vous avez dépassé la limite journalière d'accès aux données du produit`,
    standard: {
      product_name: 'Produit Shopify',
      product_min_name: 'Produit Fournisseur',
      product_title: 'Sélectionnez un fournisseur pour le produit dans le Mapping Standart',
      remove_con: "En cliquant 'CONFIRMER', le fournisseur sera retiré de la liste des fournisseurs disponibles pour ce produit et annulera le mapping des autres SKUs l'utilisant. Voulez-vous confirmer?",
      remove_title: "Retirer Fournisseur",
      remove_confirm: 'CONFIRMER',
    },
    replace_product: {
      btn_title: 'REMPLACER PRODUIT',
      modal_tip: "Les variantes du produit Shopify seront supprimées et remplacées par les variantes d'un nouveau produit. Vous pouvez modifier les détails du produit à l'étape suivante.",
      shopify: 'Produit Shopify',
      ali_express: 'Produit AliExpress',
      tm_express: 'Produit Tmall',
      price_rule: 'Appliquer les règles de prix',
      cancle: 'Annuler',
      url_error: 'Veuillez entrer une URL de produit AliExpress valide.',
      price_rule: 'Appliquer les règles de prix',
      keep_description: 'Conserver la description originale de Shopify',
      update_description: 'Mettre à jour la description',
      keep_image: "Conservez les images Shopify d'origine",
      new_image: "Choisir de nouvelles images",
      suucess: 'Remplacé avec succès',
      failed: 'Échec du remplacement',
      replace_placeholder:`Collez l'URL du produit ici`,
    },
    main_product:'Main Product',
    gift_product:'Gift Product',
    sku_lose_tip:'Le mappage a été perdu car votre fournisseur a modifié le SKU. Veuillez re-mapper vos produits en fonction du dernier SKU sur AliExpress ou changer de fournisseur.',
    replace_product_tip:`If you have any open orders with the old product that haven't been placed yet, please <a href='https://help.dsers.com/basic-mapping-advanced-mapping-bogo-bundle-introduction-for-shopify/' target='_blank'>map</a> or <a href='https://help.dsers.com/re-order-awaiting-payment-orders-for-shopify/#modify-canceled-order' target='_blank'>edit</a> the order with selected new variants that matches the desired product, then process orders {toAliExpress}. You can click REPLACE PRODUCT after.`,
    confirm_delete_all_variant_title:'Supprimer Variante(s)',
    confirm_delete_all_variant:'Voulez-vous vraiment supprimer la ou les variantes sur DSers? Une fois supprimées, les commandes pour la ou les variantes ne pourront plus être traitées.',
    delete_variant_collapse_tip:`Vous avez supprimé la ou les variantes ci-dessous sur Shopify. S'il vous plaît :
    <br />Ajoutez un fournisseur à la (aux) variante(s) ci-dessous pour traiter les commandes de la (des) variante(s) supprimée(s).`,
    delete_variant_collapse_supplier_tip:`Vous avez supprimé la ou les variantes ci-dessous sur Shopify. S'il vous plaît :
    <br />1. Refaites le mapping pour continuer à synchroniser le stock depuis fournisseur.
    <br />2. Ajoutez un fournisseur à la (aux) variante(s) ci-dessous pour traiter les commandes de la (des) variante(s) supprimée(s).`,
    delete_options_title:'Supprimer Option',
    delete_supplier_title:'Supprimer Fournisseur',
    open_new_mapping_title:'Changement de Mapping',
    close_all_mapping_title:'Désactiver le mapping',
    delete_supplier_description:'Si vous supprimez ce fournisseur de votre page de mapping, le mapping de votre produit sera supprimé.',
    offline_error_tip:'Le produit est hors-ligne',
    mapping_info_error_tip:'Les données du produit que vous mappez sont incorrectes, veuillez les re-mapper',
    Findsimilar:'Trouver un produit similaire',
    distoryImgTip:`L'image du produit actuel ne peut pas être reconnue, et la fonction d'optimisation des fournisseurs ne peut pas être utilisée pour le moment. Nous sommes désolés pour le désagrément causé.`
  }
};
