import React from 'react';
import { Route, Switch, Redirect } from 'dva/router';
import PartnerLayout from 'routes/Partner/component/partherRouter/partnerLayout';
import PartnerHome from 'routes/Partner/component/content/PartnerHome';
import PartnerAccount from 'routes/Partner/component/content/Account';

const partnerRouter = () => {
  return (
    <PartnerLayout>
      <Switch>
        <Route path="/partner/home" component={PartnerHome} />
        <Route path="/partner/account" component={PartnerAccount} />
        <Redirect to="/partner/home" />
      </Switch>
    </PartnerLayout>
  );
};
export default partnerRouter;
