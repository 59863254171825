import React from 'react';

import styles from './StatusTag.less';

const StatusTag = ({ data }) => {
  return (
    <div className={styles.tag}>
      {
        data.request === 1 ? <span className={styles.live}>Live</span> : null
      }
      {
        data.isNew ? <span className={styles.new}>NEW</span> : null
      }
      {/* {
        data.request === 1 ? <span className={styles.req}>Requested</span> : null
      } */}
    </div>
  );
}
 
export default StatusTag;
