
    export default 
    {
    "bargain": {
        "bargain": "Negozia",
        "counter_limit": "Puoi negoziare 10 volte ogni 24 ore.",
        "submission_tip": "Il tuo account manager personale ti risponderà entro 1 giorno lavorativo dall'invio",
        "banner_title": "NEGOZIA IL PREZZO DEL PRODOTTO",
        "banner_content": "Sei interessato a prezzi più bassi all'ingosso? <br/> Invia una richiesta tramite la funzionalità Negozia! Il tuo account manager personale ti risponderà entro 1 giorno lavorativo.",
        "not_store": "Prima di avviare una negoziazione, accertati di esserti collegato a un negozio valido.",
        "link_store": "Collegati al negozio",
        "product_name": "Nome prodotto",
        "supplier_price": "Prezzo del fornitore",
        "email_field": "La tua email di contatto (Ti risponderemo su questa email)",
        "email_field_p": "Si prega di inserire un indirizzo email usato spesso",
        "message_field": "Lasciaci un messaggio",
        "message_field_p": "Facci sapere di più sul tuo business",
        "price_field": "Prezzo da negoziare",
        "price_field_p": "Si prega di inserire un prezzo da negoziare che sia ragionevole",
        "submit": "Avvia una Negoziazione",
        "title": "Trova Fornitori",
        "sub_title": "Prodotti popolari",
        "intro": "Un'ampia selezione di prodotti con alto potenziale di vendita con prodotti di punta che forniscono una <a href=\"mailto:supply-support@dserspro.com\" target=\"_blank\">consegna</a> in 10 giorni! Contattaci se vuoi saperne di più.",
        "tag_bargain": "Negozia",
        "tag_time": "24 ore di tempo di lavorazione",
        "tag_delivery": "Consegna Garantita",
        "tag_24": "Supporto entro 24 ore",
        "tag_country": "Spedizione veloce ad alcune località",
        "tag_recommend": "Raccomandazioni di prodotti ad alto profitto",
        "btn_import": "Importa",
        "btn_bargain": "Negozia",
        "search_p": "Inserisci parole chiave per cercare",
        "feedback_title": "Hai avviato una negoziazione!",
        "feedback_con": "Il team di fornitura DSers di risponderà entro <br/>un giorno lavorativo. Grazie <br/>per la pazienza.",
        "copied": "Link copiato!",
        "link_copy": "Copia link",
        "m_title": "Prodotto importato!",
        "m_reminder": "Non mostrare più",
        "reminder_help": "Se spunti questa casella, questa finestra non apparirà la prossima volta che importerai un prodotto. Se vuoi editare un prodotto che hai importato, vai nella Lista Importazioni e fai click su Edita nella scheda del prodotto.",
        "m_con": "Il prodotto è stato importato con successo. Vai nella Lista Importazioni per controllare.",
        "m_btn_stay": "Rimani",
        "m_btn_go": "Vai nella Lista Importazioni per controllare",
        "m_fail_title": "L'importazione del prodotto è fallita!",
        "m_fail_con": "L'importazione del prodotto è fallita. Si prega di riprovare più tardi.",
        "m_fail_go": "Capito",
        "cost": "Prezzo",
        "piece": "Pezzo",
        "rating": "Punteggio",
        "product_rating": "Punteggio del prodotto",
        "evaluation": "Valutazione",
        "sold": "Ordini",
        "trendy": "Trendy",
        "found_cheaper": "L'abbiamo trovato ancora più conveniente!",
        "cheaper": "Trovalo ancora più conveniente",
        "reward": "Ricompensa AliExpress",
        "alternative": "Hai bisogno di un'alternativa?",
        "try_this_product": "Vuoi provare questo prodotto?",
        "powered_by": "Alimentato da",
        "mass_supply": "Fornitura di Massa DSers",
        "input_email": "Si prega di inserire un indirizzo email",
        "fill_max": "Si prega di inserire un massimo di {max} caratteri",
        "coming_soon": "Presto in arrivo…",
        "ten_days_delivery": "Consegna in 10 giorni",
        "delivery_tip": "10 giorni negli USA!Presto in arrivo consegna in 10 giorni in Brasile e in Francia!",
        "reward_tip": "Prodotti in fornitura di massa disponibili per una ricompensa in denaro",
        "tip_learn_more": "Scopri di più",
        "delivery_tip_url": "https://www.blog.dserspro.com/post/10-days-delivery-to-specific-countries-with-dsers",
        "reward_tip_url": "https://www.blog.dserspro.com/post/dsers-reward-season",
        "banner2": {
            "anchor": "testo di ancoraggio",
            "thank_you": "Grazie per aver selezionato il programma di Fornitura di Massa DSers!",
            "delete_product_tip": "Sembra che tu abbia cancellato il prodotto Shopify. <br /> Non possiamo aprire la pagina del tracciamento.",
            "copied_tip": "Il link al prodotto di Fornitura di Massa DSers è stato copiato, si prega di selezionare un prodotto Shopify da tracciare.",
            "go_mapping": "Vai al Tracciamento"
        },
        "banner_item": {
            "save_percent": "Risparmia il {} percento e stabilizza",
            "provided_by": "Fornito da",
            "current_sp": "Il tuo Attuale Fornitore",
            "unstable": "Costa di più ed è più instabile"
        },
        "found_cheaper_one": "Ne abbiamo trovato uno più conveniente!",
        "need_alternative": "Hai bisogno di un'alternativa?",
        "alternative_shipping": "Un'alternativa con una spedizione affidabile!",
        "alternative_stock": "Controlla un prodotto alternativo con uno stock stabile",
        "alternative_price": "Controlla un prodotto alternativo con un prezzo stabile",
        "one_click": {
            "title": "Vorresti provare il nostro fornitore selezionato?",
            "title_1": "Aumenta il tuo margine di profitto",
            "title_1_3": "Link al prezzo all'ingrosso VIP",
            "sub_title": "Cambia a un fornitore autorizzato affidabile con prezzi all'ingrosso con la Fornitura di Massa DSers",
            "sub_title_3": "Ottieni il tuo link VIP al prezzo all'ingrosso come altri grandi distributori",
            "sub_title_d": "Congratulazioni, hai raggiunto un alto volume di vendite",
            "intro_detail": "Candidati oggi stesso con il sottostante pulsante Scambia per goderti il prezzo all'ingrosso e un servizio affidabile.",
            "intro_detail_d": "Candidati al programma di Fornitura di Massa DSers per goderti un servizio affidabile e prezzi all'ingrosso oggi stesso",
            "intro1": "Prezzi all'ingrosso",
            "intro2": "Inventario stabile",
            "intro3": "Servizio di spedizioni affidabile",
            "intro4": "Servizio clienti dedicato",
            "intro5": "Aggiornamento della politica sulla privacy",
            "detail": "Clicca il pulsante Scambia per rimpiazzare automaticamente il fornitore del prodotto nei negozi Shopify sottostanti",
            "check": "Controlla il prodotto su AliExpress",
            "switch": "SCAMBIA",
            "switch_now": "SCAMBIA ORA",
            "apply_today": "CANDIDATI oggi per lo Scambio",
            "feedback_title": "Il tracciamento è stato rimpiazzato automaticamente",
            "feedback_subtitle": "Per qualsiasi domanda, <a href=\"mailto:supply-support@dserspro.com\" target=\"_blank\">contattaci</a>",
            "product_cost": "Costo del Prodotto",
            "shipping_cost": "Tariffa di Spedizione",
            "support_tip": "Contattaci per avere più informazioni e parlare con il tuo Account Manager: supply-support@dserspro.com",
            "support_tip_2": "La tua filiera verrà attivata dopo che avrai applicato SCAMBIA. Tutti gli ordini saranno processati come al solito. Il tuo account manager personale ti contatterà presto dalla email supply-support@dserspro.com",
            "support_tip_3": "Puoi candidarti per lo Scambio oggi, il tuo Supply Account Manager personale ti contatterà immediatamente. Oppure sentiti libero di parlare con il tuo Supply Account Manager personale in qualsiasi momento attraverso l'email supply-support@dserspro.com",
            "support_tip_4": "Candidati per lo Scambio oggi attraverso il pulsante Scambia, il tuo supply account manager (SAM) ti contatterà immediatamente. supply-support@dserspro.com ",
            "support_tip_d_1": "Candidati oggi o contattaci a supply-support@dserspro.com",
            "support_tip_d_2": "Scopri di più dal programma di fornitura di massa DSers",
            "support_tip_e": "Il tuo Supply Account Manager personale (SAM) ti contatterà subito dopo che ti sarai candidato. supply-support@dserspro.com",
            "supply_chain_title": "Costruisci la tua filiera",
            "supply_chain_1": "Il tuo Account Manager (AM) personale sarà assegnato completamente a te online",
            "supply_chain_2": "Un Fornitore Affidabile è selezionato per te, tramite l'analisi di big data (data studio) di AliExpress",
            "supply_chain_3": "Un nuovo link al prodotto è stato creato per un prezzo stabile/all'ingrosso",
            "benefits_a_1": "Risparmia ${number} passando a un prodotto verificato con prezzo all'ingrosso",
            "benefits_a_2": "Nuovo link prodotto Aliexpress creato appositamente per te",
            "benefits_a_3": "Il tuo Supply Account Manager personale (SAM) ti assisterà",
            "benefits_c_1": "Il Programma di Fornitura di Massa DSers si assume le responsabilità (ad esempio la politica del 100% di garanzia di restituzione)",
            "benefits_c_2": "Prezzo stabile all'ingrosso, inventario a sufficienza e spedizione veloce (spedizione Standard AliExpress) sono garantite",
            "benefits_c_3": "Il link VIP si considera creato da fornitori autorizzati sulla base di analisi di big data",
            "benefits_d_1": "Il centro di assistenza 3S fornisce servizio di assistenza professionale Standard, Stabile e Sicuro",
            "benefits_d_2": "Il servizio di assistenza ti permette di risparmiare ${number} su ampi volumi di ordini",
            "benefits_d_3": "È possibile negoziare una cooperazione a lungo termine con il tuo Account Manager personale ",
            "benefits_e_1": "Attiva il Fornitore Selezionato cliccando su CONFERMA per partecipare all'evento a premi",
            "benefits_e_2": "Usa il Fornitore Selezionato e completa più di {number1} ordini al giorno (o {number2} ordini in 30 giorni) per ottenere un premio di ${number3}",
            "benefits_e_3": "Il premio sarà erogato dopo 2 mesi dalla data di evasione degli ordini su AliExpress",
            "benefits_e_2_1": "Il Fornitore Selezionato sarà attivo per rimpiazzare il tuo fornitore attuale",
            "benefits_e_2_2": "Il premio sarà erogato con il Fornitore Selezionato e a completamento di più di {number1} ordini al giorno (o {number2} ordini in 30 giorni)",
            "benefits_e_2_3": "Il premio sarà erogato dopo 2 mesi dalla data di evasione degli ordini su AliExpress",
            "support_tip_e_2": "Il tuo Supply Account Manager (SAM) personale ti contatterà subito dopo che avrai effettuato la candidatura. supply-support@dserspro.com",
            "benefits_f_1": "Il Fornitore Selezionato sarà attivo per rimpiazzare il tuo fornitore attuale",
            "benefits_f_2": "Risparmierai ${num_1} per un mese ({num_2} per ogni ordine)",
            "support_tip_f": "Il tuo Supply Account Manager (SAM) personale ti contatterà subito dopo che avrai effettuato la candidatura. supply-support@dserspro.com",
            "supply_chain_example": "(ad esempio, nel caso qui sopra, risparmierai $ {number}/al mese)",
            "v5": {
                "title_1": "Wholesale Price",
                "subTitle_1": "Reliable supply service for you",
                "subTitle_2_1": "Your Account Manager is",
                "subTitle_2_2": "pleased to help you with",
                "contact": "CONTACT",
                "list_1_title": "RELIABILITY",
                "list_1_text": "Only selected manufacturers verified by AliExpress officially will be proposed",
                "list_2_title": "WHOLESALE PRICES",
                "list_2_text": "Wholesale and stable prices are promised for dropshipping businesses",
                "list_3_title": "FAST SHIPPING",
                "list_3_text": "AliExpress standard shipping method is highly suggested by the AliExpress team",
                "list_4_title": "POLICY",
                "list_4_text": "100% REFUND policy in case  of unsuccessful delivery",
                "miss": "Miss",
                "service_text_1": "Is senior account manager from DSers Mass Supply program.",
                "service_text_2": "She has been involved in supply service over 5 years. ",
                "service_text_3": "And she has the expertise to manage and give you access to multiple manufacturers according to your business needs.",
                "service_text_4": "Talk to her at",
                "banner_title": "Market trends analysis, 1st version",
                "check": "CHECK"
            }
        },
        "tag": {
            "reward": "Premio",
            "delivery": "Consegna in 10 giorni"
        },
        "filter": {
            "country_filter_title": "Fornitori popolari in stati specifici",
            "category_filter_title": "Categoria",
            "free_return": "Restituzione gratuita",
            "processing": "Lavorazione in 24 ore",
            "ship_from": "Spedisci da",
            "shop_import": "Import",
            "shop_orders": "Orders"
        },
        "sorter": {
            "default": "Predefinito",
            "new": "Più nuovo",
            "price": "Prezzo",
            "orders": "Ordini"
        },
        "btn_optimizer": "Ottimizzatore di Fornitura",
        "btn_optimizer_test": "Trova simili",
        "btn_optimizer_ab_test": "Più fornitori",
        "logo_text": "Selezione Premium DSers",
        "product": "Prodotto importato!",
        "ae_product_tip": "Controllo Qualità AliExpress",
        "dsers_product_tip": "Selezione Premium DSers",
        "search_empty_tip": "Spiacenti, la tua ricerca {keyword} non corrisponde ad alcun prodotto. Si prega di riprovare.",
        "product_button_tip": "Clicca Prodotto per vedere la lista di tutti i prodotti",
        "dont_show_again": "Non mostrare più",
        "bargain_disabled_by_order": "La funzionalità Negozia è disponibile solo per utenti con almeno 10 ordini evasi con un codice di spedizione.",
        "bargain_disabled_by_bargained": "Hai già negoziato il prezzo di questo prodotto oggi. Si prega di riprovare domani se si desidera rinegoziare.",
        "img_search_failed_tip": "La funzionalità di ricerca per immagine non può essere usata al momento a causa di un problema inaspettato dell'API AliExpress. Stiamo lavorando con il team di AliExpress per sistemare la situazione. Ci scusiamo per l'inconveniente e ti ringraziamo per la comprensione.",
        "img_search_success_tip_1": "La ricerca per immagine può mostrarti fino a 60 prodotti identici su AliExpress. Fai click",
        "here": "qui",
        "img_search_success_tip_2": "per uscire dalla pagina dei risultati della ricerca per immagini.",
        "install_ext_title": "installa il plugin DSers",
        "install_ext_description": "Basta un click per ottenere la migliore selezione fra milioni di prodotti AliExpress e risparmiare il tempo di importarli",
        "install_ext_description_test": "Ops, non riesci a trovarne di più? Installa l'estensione Chrome DSers oggi per trovare fornitori più qualificati",
        "refresh_page": "Ricarica la pagina",
        "install_ext_btn": "installa il plugin DSers",
        "select_mask": "Per favore seleziona almeno un negozio per promuovere il prodotto",
        "shipCost": {
            "reminder": "New feature online!",
            "text": "You are now able to import products with purchase price for different countries when you import with DSers Chrome Extension!\n\t\t\tDownload DSers <br/> Chrome extension to discover this feature",
            "learnmore": "LEARN MORE",
            "install": "INSTALL"
        },
        search_no_data_tip:`Nessun risultato, provare con altre parole chiave`,
    filter_no_data_tip:'Nessun risultato, cambiare il filtro',
    onlyFor:'For US & Brazil'
    }
}