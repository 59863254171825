
    export default 
    {
    "error_cashback_code": {
        "title": "Ihr Admitad Cashback wird gestoppt, bitte überprüfen Sie es.",
        "title_dec": "Wir haben festgestellt, dass Ihr Code nicht unter DSers zu finden ist.",
        "p1": "Wir haben festgestellt, dass Sie den Partnercode auf einem Nicht-DSers",
        "p2": "Kanal verwenden. Sie können mit dem aktuellen Code kein Cashback mehr erhalten.",
        "p3": "Bitte klicken Sie unten, um zu erfahren, was passiert ist und wie Sie damit umgehen können."
    }
}