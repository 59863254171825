import {
  getTimeZone,
  postBigUserSendEmail,
  getBigUserMsg,
  putDelBigUserMsg,
  fetchUnsupportedShipping,
  dataTrack,
  fetchVatData,
  fetchCurrencyState,
  fetchAgencyList,
  worldTelephoneAreaCode,
  getGuideInfo,
  fetchAgencyListFromMerchant
} from '../services/global';

import { getHomepageCards } from '../services/home';
import timezone from '../common/timezone';
import { getUserPlatform } from '../utils/utils';
import { AliExpressAuthStatus } from '../services/topBtn';

const delay = timeout => {
  return new Promise(resolve => {
    setTimeout(resolve, timeout);
  });
};

export default {
  namespace: 'global',

  state: {
    collapsed: false,
    notices: [],

    guideActive: false, // 新手引导是否开始

    // 一键 mapping
    oneClickMapping: {
      visible: false,
      extraData: null
    },
    // comment 状态
    comment: {
      visible: false
    },
    lang: '',
    storeCurrency: {},
    settingTimeZone: undefined, // 这个数据没意义，历史遗留问题
    timezoneRegion: undefined, // IANA date 标示 eg. Asia/Shanghai
    storeId: undefined, // 全局店铺 id
    storeIdWithOrder: undefined, // order 中的店铺 id
    aeCookie: undefined, // notLogin: 没有AE账号登陆  notMatch: 登录的 AE 账号不一致  none: 没有nick name 或者没有Chrome extension
    hasExtension: false,
    homeUnreadMessage: false,
    selectStoreIsDisabled: false,
    AEInfo: undefined,
    localAEInfo: {
      status: 100,
      affiliateStatus: 107,
      localAEAccountId: ''
    },
    unsupportedShipping: {}, // 不支持的物流列表
    showTime: false,
    isBanner: false,
    vat: {
      id: 0,
      company: 0
    },
    halfBanner: false, //展示一半广告的状态
    drawerBanner: {
      //展示全部广告的状态
      visible: false
    },
    currencyState: {},

    countDown: 30,
    countDownButtonDisabled: false,
    // 是否安装了chrome插件
    isInstallChromePlug: window.dsersExtVersion ? true : false,
    // 是否绑定了AE
    isBindAe: false,
    newShipOptionsStatus: false,
    appTotal: {},
    conversionModal: false,
    conversionFinish: true,
    actionGuide: false,
    agencyManagementData: [],
    agencyManagementDataMerchant: [],
    worldTelephoneAreaCode: [],
    worldTelephoneAreaCodeMap: {},
    TmallBoard:
      '我在跨境一件代发，包裹里请勿放铭牌优惠券等无关物品，期待长期合作。',
    themeStatus: localStorage.getItem('themeClose'),
    guideInfo: {
      empty: true,
      first: false,
      tag: "tag-user-n"
    },
    openOrdersMenuTooltip: JSON.parse(localStorage.getItem('openOrdersMenuTooltip')) ?? true,
  },

  effects: {
    *timezone({ payload }, { call, put }) {
      try {
        const response = yield call(getTimeZone, payload);
        if (!response) {
          return;
        }

        const target = timezone.find(i => i.label === response.data);

        yield put({
          type: 'setTimeZone',
          payload: {
            settingTimeZone: response.data,
            timezoneRegion: target && target.value
          }
        });
      } catch (error) {
        console.log('global/timezone error', error);
      }
    },
    *getHomeUnreadMessageStatus({ payload }, { call, put }) {
      const { status, stores } = payload;
      const platform = getUserPlatform(status, stores);
      const response = yield call(getHomepageCards, { platform });
      try {
        const latestMessage = JSON.parse(
          localStorage.getItem('latestHomeMessage')
        ) || { update_at: 0 };
        if (response.code === 2000) {
          const messages = response.data || [];
          const unreadMessage = messages.find(item => {
            const updateTime = +new Date(item.update_at);
            const latestTime = +new Date(latestMessage.update_at);
            return updateTime - latestTime > 0;
          });
          if (unreadMessage) {
            yield put({
              type: 'setHomeUnreadMessage',
              payload: true
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 检查绑定的 ae 状态
     * status 2 表示过期，1 表示绑定正常，3 表示解绑
     */
    *AEInfo({ payload }, { call, put }) {
      const res = yield call(AliExpressAuthStatus, payload);
      if (!res) {
        return;
      }

      yield put({
        type: 'setAEInfo',
        payload: res.data
      });
    },
    *bigUserSendEmail({ payload }, { call }) {
      yield call(postBigUserSendEmail, payload);
    },
    *hasBigUserMsg({ payload }, { call }) {
      yield call(getBigUserMsg, payload);
    },
    *delBigUserMsg({ payload }, { call }) {
      yield call(putDelBigUserMsg, payload);
    },
    *dataTrack({ payload }, { call }) {
      yield call(dataTrack, payload);
    },
    *getUnsupportedShipping({ payload }, { call, put }) {
      const res = yield call(fetchUnsupportedShipping, payload);
      if ([2000, 2010].includes(res?.code)) {
        yield put({
          type: 'setUnsupportedShipping',
          payload: res?.data
        });
      }
    },
    *getVat({ payload }, { call, put }) {
      const res = yield call(fetchVatData, payload);
      if (res.code === 2000) {
        yield put({
          type: 'setVatData',
          payload: res?.data
        });
      }
    },
    *bannerShow({ payload }, { call, put }) {
      yield put({
        type: 'setBanner',
        payload: payload.isBanner
      });
    },
    *getVat({ payload }, { call, put }) {
      const res = yield call(fetchVatData, payload);
      if (res.code === 2000) {
        yield put({
          type: 'setVatData',
          payload: res?.data
        });
      }
    },
    *currencyState({ payload }, { call, put }) {
      const res = yield call(fetchCurrencyState, payload);
      yield put({
        type: 'setCurrencyState',
        payload: res.data
      });
    },
    // 按钮禁用 & 30秒倒计时
    *countDown({ payload }, { call, put, select }) {
      let locCountDown = Number(localStorage.getItem('countDown') || 0);
      if (locCountDown === 0 && payload) {
        return;
      }
      yield put({
        type: 'setCountDownButtonDisabled',
        payload: true
      });

      let countDown;

      if (locCountDown) {
        countDown = locCountDown;
      } else {
        countDown = yield select(state => state.global.countDown);
      }

      while (countDown > 0) {
        let flag = countDown--;
        yield call(delay, 1000);
        localStorage.setItem('countDown', flag);
        yield put({
          type: 'setCountDown',
          payload: flag
        });
      }
      localStorage.setItem('countDown', 0);
      yield put({
        type: 'setCountDownButtonDisabled',
        payload: false
      });
      yield put({
        type: 'setCountDown',
        payload: 30
      });
    },
    *total({ payload }, { call, put }) {
      yield put({
        type: 'setAppTotal',
        payload: payload.data
      });
    },

    *getAgencyList({ payload }, { call, put }) {
      const result = yield call(fetchAgencyList, payload);
      if (result.code === 2000) {
        if (result?.data?.length) {
          yield put({
            type: 'setAgencyList',
            payload: result.data
          });
        } else {
          yield put({
            type: 'setAgencyList',
            payload: []
          });
        }
      }
    },

    *getAgencyListFromMerchant({ payload }, { call, put }) {
      const result = yield call(fetchAgencyListFromMerchant, payload);
      if (result.code === 2000) {
        if (result?.data?.length) {
          yield put({
            type: 'setAgencyListFromMerchant',
            payload:
              result.data?.map(i => {
                return {
                  id: i.agency_id,
                  agency_name: i.store_name,
                  pay_pal:
                    i.pay_info?.find?.(i => {
                      return i.pay_type === 2;
                    })?.pay_account || '',
                  stripe_account_email:
                    i.pay_info?.find?.(i => {
                      return i.pay_type === 1;
                    })?.pay_account || ''
                };
              }) || []
          });
        } else {
          yield put({
            type: 'setAgencyListFromMerchant',
            payload: []
          });
        }
      }
    },

    *getWorldTelephoneAreaCode({ payload }, { call, put }) {
      const result = yield call(worldTelephoneAreaCode, payload);
      if (result.code === 2000) {
        yield put({
          type: 'setWorldTelephoneAreaCode',
          payload: result.data
        });
      }
    },
    *getGuideInfo({ payload }, { call, put }) {
      const result = yield call(getGuideInfo, payload);
      if (result.code === 2000) {
        yield put({
          type: 'setGuideInfo',
          payload: result.data
        });
      }
    },
    *getOpenOrdersMenuTooltip({ payload }, { put }) {
      localStorage.setItem('openOrdersMenuTooltip',payload)
      yield put({ type:'setOpenOrdersMenuTooltip',payload})
    }
  },

  reducers: {
    // 倒计时时间
    setCountDown(state, action) {
      return {
        ...state,
        countDown: action.payload
      };
    },
    setAppTotal(state, action) {
      return {
        ...state,
        appTotal: action.payload
      };
    },
    // 倒计时按钮
    setCountDownButtonDisabled(state, action) {
      return {
        ...state,
        countDownButtonDisabled: action.payload
      };
    },

    setCurrencyState(state, action) {
      return {
        ...state,
        currencyState: action.payload
      };
    },
    setBanner(state, action) {
      return {
        ...state,
        isBanner: action.payload
      };
    },
    setVatData(state, action) {
      return {
        ...state,
        vat: action.payload
      };
    },
    setDownCouny(state, action) {
      return {
        ...state,
        showTime: action.payload
      };
    },
    setAgencyList(state, action) {
      return {
        ...state,
        agencyManagementData: action.payload
      };
    },
    setAgencyListFromMerchant(state, action) {
      return {
        ...state,
        agencyManagementDataMerchant: action.payload
      };
    },
    setWorldTelephoneAreaCode(state, action) {
      return {
        ...state,
        worldTelephoneAreaCode: action.payload,
        worldTelephoneAreaCodeMap: action?.payload?.reduce((prev, current) => {
          prev[current?.country_code] = current?.country_calling_code;
          return prev;
        }, {})
      };
    },
    changeLayoutCollapsed(state, { payload }) {
      return {
        ...state,
        collapsed: payload
      };
    },
    updateLang(state, { payload }) {
      return {
        ...state,
        lang: payload
      };
    },
    setCurrency(state, { payload }) {
      return {
        ...state,
        storeCurrency: payload
      };
    },
    setTimeZone(state, { payload }) {
      return {
        ...state,
        settingTimeZone: payload.settingTimeZone,
        timezoneRegion: payload.timezoneRegion
      };
    },
    updateStoreId(state, { payload }) {
      // 由于在 request 中把 storeId 封装进去了，这里总是更新
      localStorage.setItem('storeId', payload === 'all' ? '' : payload);
      return {
        ...state,
        storeId: payload
      };
    },
    updateOrderStoreId(state, { payload }) {
      localStorage.setItem('storeId', payload === 'all' ? '' : payload);
      return {
        ...state,
        storeIdWithOrder: payload
      };
    },
    updateAeCookie(state, { payload }) {
      return {
        ...state,
        aeCookie: payload
      };
    },
    updateLocalAEInfo(state, { payload }) {
      return {
        ...state,
        localAEInfo: payload
      };
    },
    updateExtensionResult(state, { payload }) {
      return {
        ...state,
        hasExtension: payload
      };
    },
    showOneClickMapping(state, { payload, callback }) {
      return {
        ...state,
        oneClickMapping: {
          extraData: { ...payload },
          visible: true,
          callback
        }
      };
    },
    closeOneClickMapping(state, { payload, callback }) {
      return {
        ...state,
        oneClickMapping: {
          extraData: { ...payload },
          visible: false,
          successCallback: callback
        }
      };
    },
    showComment(state) {
      return {
        ...state,
        comment: {
          visible: true
        }
      };
    },
    closeComment(state) {
      return {
        ...state,
        comment: {
          visible: false
        }
      };
    },
    setHomeUnreadMessage(state, { payload }) {
      return {
        ...state,
        homeUnreadMessage: payload
      };
    },
    changeSelectStoreIsDisabled(state, { payload }) {
      return {
        ...state,
        selectStoreIsDisabled: payload
      };
    },
    updateGuideStatus(state, { payload }) {
      return {
        ...state,
        guideActive: payload
      };
    },
    setAEInfo(state, { payload }) {
      window.IS_BIND_AE = payload?.status == 1 ? true : false;

      return {
        ...state,
        AEInfo: payload,
        isBindAe: payload?.status == 1 ? true : false
      };
    },
    setUnsupportedShipping(state, { payload }) {
      return {
        ...state,
        unsupportedShipping: payload || {}
      };
    },
    showHalfBanner(state) {
      return {
        ...state,
        halfBanner: true
      };
    },
    closeHalfBanner(state) {
      return {
        ...state,
        halfBanner: false
      };
    },
    drawerBannerVisible(state) {
      return {
        ...state,
        drawerBanner: {
          visible: true
        }
      };
    },
    drawerBannerNoVisible(state) {
      return {
        ...state,
        drawerBanner: {
          visible: false
        }
      };
    },
    drawerBannerIfVisible(state) {
      return {
        ...state,
        drawerBanner: {
          visible: !state.drawerBanner.visible
        }
      };
    },
    setNewShipOprionsStatus(state, { payload }) {
      return {
        ...state,
        newShipOptionsStatus: payload.status
      };
    },
    setConversionModal(state, { payload }) {
      return {
        ...state,
        conversionModal: payload
      };
    },
    setConversionStatus(state, { payload }) {
      return {
        ...state,
        conversionFinish: payload
      };
    },
    actionGuide(state) {
      return {
        ...state,
        actionGuide: true
      };
    },
    setThemeStatus(state, { payload }) {
      return {
        ...state,
        themeStatus: payload
      };
    },
    setGuideInfo(state, { payload }) {
      return {
        ...state,
        guideInfo: payload
      };
    },
    setOpenOrdersMenuTooltip(state, { payload }) {
      return {
        ...state,
        openOrdersMenuTooltip: payload
      };
    }
  },

  subscriptions: {
    setup({ history, dispatch }) {
      const lang = localStorage.getItem('lang') || 'en-US';
      dispatch({ type: 'updateLang', payload: lang });

      return history.listen(location => {
        if (typeof window.ga !== 'undefined') {
          if (location.pathname === '/') {
            return;
          }

          // eslint-disable-next-line
          const pagePath = location
            ? location.pathname + location.search + location.hash
            : undefined;
          setTimeout(() => {
            // https://developers.google.com/analytics/devguides/collection/analyticsjs/single-page-applications
            window.ga('set', 'page', location.pathname);
            window.ga('send', 'pageview');
          }, 32);
        }
      });
    }
  }
};
