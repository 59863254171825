export default {
  whyDisconnect: {
    need_help: `Besoin d'aide pour utiliser DSers?`,
    need_help_dec:
      `Contactez-nous si vous avez besoin d'aide. Nous vous répondrons rapidement!`,
    need_to_uninstall:
      'Si vous désintallez DSers, veuillez noter:',
    need_to_uninstall_dec:
      'Une fois que vous supprimez DSers de votre boutique, toutes les données de votre boutique seront supprimées de DSers, cela est irréversible.',
    leave_tip:
      'Pourquoi voulez-vous quitter DSers? Laissez-nous savoir pour que nous puissions nous améliorer.',
    tell_more: 'Veuillez nous en dire plus',
    select_reason: 'Choisir une raison…',
    not_satisfied_with_product: 'Je ne suis pas satisfait des fonctions de gestion des produits.',
    not_satisfied_with_order: 'Je ne suis pas satisfait des fonctions de gestion des commandes.',
    not_work: `L'application ne fonctionne pas bien.`,
    better_app: `J'ai trouvé une meilleure application.`,
    high_cost: 'Le coût de DSers est trop élevé.',
    test_store: `C'est une boutique de test.`,
    sold_store: `J'ai vendu cette boutique.`,
    closing_store: `Je ferme cette boutique.`,
    closing_business: `J'arrête le dropshipping.`,
    reinstall: 'Je réinstalle DSers.',
    other: 'Autre.',
    disconnect: 'Déconnecter',
    stay: 'Rester',
    contact: 'Contact',
    update: 'Mettre à jour',
    lose_feature: 'Si vous déconnectez, vous perdrez les privilèges suivants',
    which_module: 'Merci de nous faire savoir pour quelle raison vous nous quitter. Nous ferons de notre mieux pour nous améliorer!',
    suggestion_title: 'Vous avez des suggestions? Dîtes-le nous ci-dessous',
    other_platform_title: 'Vous changez de plateforme? Ou allez-vous?',
    stay_for_free: `Félicitations, veuillez laisser une adresse email où nous pouvons vous contacter.<br />Notre équipe vous contacter dans 1-2 jours pour activer votre accès d'un mois gratuit.`,
    btn_get_free: 'Obtenir accès gratuit',
    woo_free: 'DSers pour WooCommerce est complètement gratuit.',
    reason: {
      feature: {
        btn: 'Fonction',
        ali_account: 'Je n’ai pas pu lier mon compte AliExpress.',
        order_process: 'Je ne suis pas satisfait de la gestion des commandes.',
        product_edit: 'Je ne suis pas satisfait de la gestion des commandes.',
        import: 'Je ne suis pas satisfait de la fonction d’import des produits.',
        mapping: 'Je ne suis pas satisfait de la fonction mapping.'
      },
      price: {
        btn: 'Prix',
        expensive: 'DSers est trop cher'
      },
      other: {
        btn: 'Autre',
        reinstall: 'Il y a un problème avec la boutique, je réinstallerai plus tard',
        fullillment_center: 'Je veux travailler avec un centre de traitement / agent',
        close_store: 'Il y a un problème avec la boutique, je réinstallerai plus tard',
        test_store: `C’est une boutique de test`
      }
    },
    dsers_feature: {
      ali: 'L’unique partenaire official AliExpress sur Liste Blanche!',
      import: 'Import & Edition rapide et facile de produits AliExpress',
      order: 'Gestion de commandes multiples.<br />Placez des centaines de commande en 1 clic!',
      reward: 'Profitez du programme affilié Admitad pour gagner plus'
    },
    callback_title: 'Merci pour votre réponse! <br /> Nous espérons vous revoir bientôt!',
    failed_link_ae: `Je n'ai pas pu lier le compte AliExpress.`,
    failed_add_shopify: `Je n'ai pas pu ajouter ma boutique Shopify.`,
    not_satisfied_with_import: `Je ne suis pas satisfait des fonctions d'import des produits.`,
    not_satisfied_with_mapping: `Je ne suis pas satisfait des fonctions de mapping.`,
    not_satisfied_with_bulk_order: `Je ne suis pas satisfait des fonctions de commandes groupées.`,
    not_satisfied_with_setting: `Je ne suis pas satisfait des réglages possibles.`,
    closing_store_or_business: `Je ferme/vend la boutique / c'était un test / J'arrête le dropshipping.`
  }
};
