import React from 'react';
import { connect } from 'dva';
import cloneDeep from 'lodash/cloneDeep';

import {
  Popover,
  Button,
  Icon,
  message,
  Tooltip,
  InputNumber,
  Radio,
  Modal,
  Switch,
  Collapse
} from 'antd';

import qs from 'querystring';
import Ellipsis from 'components/Ellipsis';
import intl from 'react-intl-universal';
import className from 'classnames';
import ImportAliExpressMapping from './Import.js';
import Status from './Status.js';
import {
  deleteBogo,
  deleteAdvance,
  hasDeleteVariant,
  postMappingProductRef,
  getMappingAvaliableStatus
} from './util.js';
import { replaceUrlToHttps,sendNoviceGuidanceInfo, CommonDataCollect } from 'utils/utils';
import {getAePid} from 'routes/StoreManage/util'

import reactGaEvent from '../../utils/GAEvent';

import noticeIcon from 'assets/mapping/notice.png'
import searchIcon from 'assets/newImportList/icon-search.png';

import { webCustomCollect } from 'utils/utils'

import styles from './index.less';
import './mapping.css';
import { GuildeAction, LinkStoreAction } from 'actions'
const RadioGroup = Radio.Group;
const { confirm } = Modal;
const { Panel } = Collapse;

@connect(({ productmy, loading,user, login }) => ({
  productmy,
  loading: loading.models.productmy,
  saveLoading: loading.effects['productmy/ext'],
  noviceGuidanceInfo:user?.noviceGuidanceInfo,
  userInfo: login.userInfo,
  plan: user?.plan
}))
export default class Bundle extends React.PureComponent {
  cacheUrlFromDsers = {};

  constructor(props) {
    super(props);
    this.state = {
      bundle: {},
      showImport: false,
      moreActionChecked: undefined,
      showSave: false,
      select: [],
      BundleMapping: false,
      deleteVariantIdMap:[],
      visibleDeletedAllVariant:false,
      isDeleteAll:false
    };
  }

  componentWillMount() {
    const { types } = this.props;
    this.setState({
      BundleMapping: getMappingAvaliableStatus(types).affiliate
    });
    this.props.dispatch({
      type: 'productmy/LimitTheInterface',
      payload: {
        callback: d => {
          if (d.code == 2000 || d.code == 2010) {
            this.setState({
              BundleMapping: d.data.confirm_url?.affiliate
            });
          }
        }
      }
    });

    const {
      // types,
      originProductData,
      originProductData: {
        prod: {
          detail: { variants = [], id },
          ext: { bundle, map_type } = {}
        }
      }
    } = this.props;

    const deleteVariants = hasDeleteVariant(originProductData,'bundle');
    let mapType = map_type;
    console.log(this.props?.plan,'0000000000000');
    
    if ([1, 4].includes(types) && this.props?.plan?.trial_7_days_mapping_bundle_status !== 1) {
      mapType = 1;
    }

    this.variants = [...(variants.filter(i => !deleteVariants.find(d => d.id === i.id))), ...deleteVariants];
    this.map_type = mapType;
    this.save_map_type = mapType;
    this.shopifyProductId = id;

    this.setRemovedVariant(originProductData);

    const useBundle = this.setSupply(bundle);

    this.skuHasIsFbd(useBundle);

    this.setState({
      bundle: useBundle
    });
  }

  componentDidMount() {
    const {plan} = this.props
    if(plan?.trial_7_days_mapping_bundle_status == 1){
      this.setState({
        BundleMapping: true
      })
    }
    this.props.onRef(this);
  }

  maxSpLength = 10; // 一个varint最多可以设置多少个
  save_map_type = 0;
  map_type = 0;
  variants = [];
  importVariantId = '';
  importVariantSupplyIndex = 0;
  importProductId = '';
  variantIndex = 0;
  hasSave = false;
  currentDeleteVariantId = '';
  sp = '';

  // 有没有被删除的variant
  setRemovedVariant = originProductData => {
    const { variant } = originProductData;

    // console.log(this.variants,variant,'??????????????????????????????????');
    if (!variant) {
      return;
    }
    const flag = this.variants.some(item => item.id == variant.variant_id);

    flag == false &&
      this.variants.push({
        id: variant.variant_id,
        title: variant.Title,
        is_del: true
      });
  };

  showImportSupply = (
    variantId,
    spIndex,
    platform_product_id,
    i,
    sp
  ) => {
    if (this.state.BundleMapping) {
      this.importVariantId = variantId;
      this.importVariantSupplyIndex = spIndex;
      this.importProductId = platform_product_id;
      this.variantIndex = i;
      this.sp=sp;

      this.setState({
        showImport: true
      });
    } else {
      localStorage.setItem('passivity', 'true');
      //  跳转订阅 && 弹窗拦截
      CommonDataCollect({
        action: 'Bundle-M-top',
        event_type:'overlimit_pop'
      });
      // window.EVENT.emit(GuildeAction.TurnPlan_Pop, `Bundle-M&${Date.now()}`, true);
      //  self.location.href = '/app/pricing?select=1';

      const { plan } = this.props

      if([1,10, 4, 8,12,13,14,15,16,17].includes(plan?.type)){
        window.EVENT.emit(LinkStoreAction.CAM_MODAL, 'BundleMapping')
      }

    }
  };

  hideImportSupply = () => {
    this.setState({
      showImport: false
    });
  };

  // 设置的供应商
  skuHasIsFbd = data => {
    const { fbdSupply } = this.props;

    if (!fbdSupply[this.shopifyProductId]) {
      return;
    }

    const sd = fbdSupply[this.shopifyProductId];

    Object.entries(data).forEach(item => {
      const variantId = item[0];
      const variant_supply = item[1].variant_supply;
      const variant_supply_supply = variant_supply[0].supply;

      const sdList = sd[variantId];

      if (!sdList) {
        return;
      }

      variant_supply_supply.forEach(vs => {
        let flag = false;

        sdList.forEach(FBD => {
          if (
            FBD.origin_product_id == vs.platform_product_id &&
            FBD.origin_sku == vs.sku
          ) {
            flag = true;
          }
        });

        if (flag) {
          vs.isFBD = true;
        } else {
          vs.isFBD = false;
        }
      });
    });
  };

  // 设置供应商
  setSupply = (bundle = {}) => {
    this.variants.forEach(item => {
      const sp = bundle[item.id];
      if (sp) {
        this.hasSupply(sp.variant_supply, item);
      } else {
        bundle[item.id] = { variant_supply: [] };
        this.hasSupply(bundle[item.id].variant_supply, item);
      }
    });

    return bundle;
  };

  // 要不要添加新的一个供应商
  hasSupply = (variant_supply, variant) => {
    if (variant_supply.length == 0) {
      this.addSupply(variant_supply, variant);
      return;
    }

    let flag = true; // 用不用添加新的sp
    let len = 0;
    variant_supply[0].supply.forEach(item => {
      len += 1;
      if (item.sku == '' || item.quantity == 0) {
        flag = false;
      }
      item.variant_title = variant.title;
    });

    if (!flag) {
      return;
    }

    if (len >= this.maxSpLength) {
      return;
    }

    variant_supply[0].supply.push({
      platform_type: 2,
      platform_product_id: '',
      platform_sale_id: '',
      quantity: 0,
      sku: '',
      sku_desc: '',
      variant_id: variant.id,
      variant_title: variant.title,
      is_base: false,
      freights: [],
      sku_image: '',
      title: ''
    });
  };

  // 添加一个供应商
  addSupply = (variant_supply, variant) => {
    variant_supply.push({
      countries: ['GLOBAL'],
      supply: [
        {
          platform_type: 2,
          platform_product_id: '',
          platform_sale_id: '',
          quantity: 0,
          sku: '',
          sku_desc: '',
          variant_id: variant.id,
          variant_title: variant.title,
          is_base: false,
          freights: [],
          sku_image: '',
          title: ''
        }
      ]
    });
  };

  // 删除一个供应商
  deleteSupply = (variantId, spIndex, variantIndex) => {
    const { bundle, moreActionChecked } = this.state;

    this.variantIndex = variantIndex;

    bundle[variantId].variant_supply[0].supply.splice(spIndex, 1);

    const updataBundle = this.setSupply(bundle);

    this.setState({
      bundle: { ...updataBundle }
    });

    if (this.variantIndex == 0 && moreActionChecked) {
      this.setFirstToMore(updataBundle[variantId]);
    }
    this.showSaveBtn();
  };

  // 改变数量
  changeQty = (variantId, spIndex, v) => {
    const { bundle } = this.state;

    bundle[variantId].variant_supply[0].supply[spIndex].quantity = v;

    this.setState({
      bundle: { ...bundle }
    });

    this.showSaveBtn();
  };

  // 导入供应商
  importCallback = (data, refFromDsers, select) => {
    if (refFromDsers) {
      this.cacheUrlFromDsers[data.platform_product_id] = refFromDsers;
    }

    this.hideImportSupply();

    const { bundle, moreActionChecked } = this.state;
    const supply = bundle[this.importVariantId].variant_supply[0].supply;
    let hasReplace = false;
    
    supply.forEach((item, i) => {
      if (
        item.platform_product_id == data.platform_product_id &&
        item.sku == data.sku &&
        i != this.importVariantSupplyIndex
        ) {
          hasReplace = true;
        }
      });
      
      if (hasReplace) {
        message.error(intl.get('mapping.import_same_sku_tip'));
        return;
      }
      
      const sp = supply[this.importVariantSupplyIndex];
      Object.assign(sp, data);
      if (sp.quantity == 0) {
        sp.quantity = 1;
      }
      
      const updataBundle = this.setSupply(bundle);
      console.log(data, refFromDsers, select,bundle,updataBundle,'shayebushi1');
      
    this.setState({
      bundle: { ...updataBundle },
      select,
    });

    if (this.variantIndex == 0 && moreActionChecked) {
      this.setFirstToMore(updataBundle[this.importVariantId]);
    }

    this.showSaveBtn();
  };

  changeStatus = (v, save_map_type, t) => {
    this.map_type = save_map_type;
    this.save_map_type = save_map_type;
    // this.showSaveBtn();
    if (v) {
      this.props.changeMappingStatus(save_map_type);
      // this.handleSave();
      reactGaEvent({
        category: 'Mapping',
        action: 'open bundle'
      });
      return;
    }
    if (t == 1) {
      this.props.changeMappingStatus(save_map_type);
      reactGaEvent({
        category: 'Mapping',
        action: 'close bundle'
      });
      // this.handleSave();

    }
  };

  bundleExt = undefined

  // 点击保存
  handleSave = () => {
    const { bundle } = this.state;
    
    const copyBundle = cloneDeep(bundle);
    
    const {
      originProductData,
      originProductData: { prod: { ext,store_id },storeId },
      noviceGuidanceInfo
    } = this.props;

    const copyExt = cloneDeep(ext);
    const { bogo, advance } = copyExt;
    
    if(noviceGuidanceInfo.mapped == 0){
      sendNoviceGuidanceInfo({mapped : 1},()=>{
        this.props.dispatch({ type:'user/getNoviceGuidance'})
      })
    }

    if (bogo) {
      deleteBogo(bogo, this.variants);
      if (Object.values(bogo).length == 0) {
        delete copyExt.bogo;
      }
    }

    if (advance) {
      deleteAdvance(advance, this.variants);
      if (Object.values(advance).length == 0) {
        delete copyExt.advance;
      }
    }

    const shouldPostRef = {};

    this.variants.forEach(item => {
      const { variant_supply } = copyBundle[item.id];
      const supply = variant_supply[0].supply;

      for (let i = 0; i < supply.length; i++) {
        if (!supply[i].sku) {
          supply.splice(i, 1);
          i -= 1;
        } else {
          const aeProductId = supply[i].platform_product_id;
          if (this.cacheUrlFromDsers[aeProductId]) {
            shouldPostRef[aeProductId] = this.cacheUrlFromDsers[aeProductId];
          }
        }
      }
      if (supply.length == 0) {
        delete copyBundle[item.id];
      }
    });

    if (Object.values(copyBundle).length != 0) {
      copyExt.bundle = copyBundle;
    } else {
      delete copyExt.bundle;
    }

    copyExt.map_type = this.save_map_type;

    // this.props.setLoading(true);

    this.hasSave = true;
    this.props.setHasSave(this.hasSave);

    for(let i in copyExt){
      if(i !== 'bundle'){
        delete copyExt[i]
      }
    }

    this.bundleExt = copyExt

    // this.props.dispatch({
    //   type: 'productmy/ext',
    //   payload: {
    //     data: {
    //       product_id: originProductData.id,
    //       ext: copyExt,
    //       store_id,
    //       ref:localStorage.getItem('mapping_dot')
    //     },
    //     callback: d => {
    //       // this.props.setLoading(false);
    //       if (d.code == 2000 || d.code == 2010) {
    //         message.success(intl.get('mapping.succeed'));
    //         postMappingProductRef(shouldPostRef, 4, originProductData);
    //         webCustomCollect({
    //           action:"mapping_dot_1",
    //           custom_info:[
    //             {
    //               name:localStorage.getItem('mapping_dot'),
    //               value:'mappinged'
    //             }
    //           ]
    //         })
    //         this.props.hasSavedHandler('bundle')
    //         if(window.location.pathname.substring(window.location.pathname.lastIndexOf("/")+1).match(/myproduct/)){
    //           window.IS_UPDATE_IMPORT_PRODUCT = true
    //           setTimeout(()=>{
    //             window.IS_UPDATE_IMPORT_PRODUCT = false
    //           },15*1000)
    //         }
    //       }
    //     }
    //   }
    // });
  };

  // 清空mapping设置
  clearAll = () => {
    const { bundle } = this.state;

    this.variants.forEach(item => {
      const supply = (bundle[item.id].variant_supply[0].supply = []);
      supply.push({
        platform_type: 2,
        platform_product_id: '',
        platform_sale_id: '',
        quantity: 0,
        sku: '',
        sku_desc: '',
        variant_id: item.id,
        is_base: false,
        freights: [],
        sku_image: '',
        title: ''
      });
    });

    this.setState({
      bundle: { ...bundle }
    });

    this.showSaveBtn();
  };

  // 删除快捷操作选中状态
  handleRadio = e => {
    const { moreActionChecked } = this.state;

    if (moreActionChecked == e) {
      this.setState({
        moreActionChecked: undefined
      });
    }
  };

  // 设置more action的选中状态
  setMoreActionChecked = e => {
    const { bundle } = this.state;

    const sp = bundle[this.variants[0].id];

    // 交互打点
    CommonDataCollect({
      user_id: this.props.userInfo.user_id,
      sale_platform: 'shopify',
      supply_platform: 'aliexpress',
      trans_from: 'bundle mapping click copy button',
      action: 'click_bundle_copy_button',
      event_type: 'interactive'
    });

    this.setState(
      {
        moreActionChecked: e
      },
      () => {
        if (e) {
          this.setFirstToMore(sp);
        }
      }
    );

    this.showSaveBtn();
  };

  // 复制第一个variant到所有variant
  setFirstToMore = sampleSp => {
    const { bundle, moreActionChecked } = this.state;

    if (!moreActionChecked) {
      return;
    }

    this.variants.forEach((item, i) => {
      if (i == 0) {
        return;
      }
      const copySp = cloneDeep(sampleSp.variant_supply[0].supply);

      let sp = bundle[item.id].variant_supply[0].supply;

      const arr = [];

      sp.forEach(s => {
        arr.push(s.quantity);
      });

      sp = copySp;

      // sp.forEach((p, pi) => {
      //   if (p.sku) {
      //     p.quantity = arr[pi] || 1;
      //   }
      // });

      bundle[item.id].variant_supply[0].supply = sp;
      bundle[item.id].variant_supply[0].supply.forEach(it=>{
        it.variant_id= item.id;
        it.variant_title = item.title
      })
    });

    this.setState({
      bundle: { ...bundle }
    });
  };

  // more action内容
  getComponent = moreActionChecked => {
    return (
      <div className={styles.getTool}>
        <a
          className={styles.clearSp}
          role="button"
          tab-index="0"
          onClick={this.clearAll}
        >
          <Icon type="delete" />
          {intl.getHTML('mapping.clear_all')}
        </a>
        <RadioGroup
          value={moreActionChecked}
          onChange={this.setMoreActionChecked}
        >
          <Radio value="2" onClick={this.handleRadio}>
            {intl.getHTML('mapping.bundle_more_action_copy')}
          </Radio>
        </RadioGroup>
      </div>
    );
  };

  // 显示保存按钮
  showSaveBtn = () => {
    this.setState({
      showSave: true
    });
    this.props.showSaveBtn()
    this.props.changemodalHidden('bundle')
  };

  // 是否应用了dsers供应商
  getIsFBD = () => {
    const {
      fbdSupply,
      originProductData: { prod: { detail: { id } } }
    } = this.props;

    let flag = false;

    if (fbdSupply[id]) {
      flag = true;
    }

    return flag;
  };
  onBundleChild = ref => {
    this.bundles = ref;
  };
  switchTabCheckBundle = flag => {
    this.bundles.changeChecked(flag);
  };

  // 是否删除在shopify已经删除的variant
  deleteVariant = variantId => {
    this.currentDeleteVariantId = variantId;
    this.setState({
      visibleDeletedAllVariant:true,
      isDeleteAll:false
    })
  };

  // 删除在shopify已经删除的variant
  deleteVariantTrue = () => {
    const { bundle } = this.state;

    this.variants = this.variants.filter(
      item => item.id != this.currentDeleteVariantId
    );
    delete bundle[this.currentDeleteVariantId];

    this.setState({
      bundle: { ...bundle },
      deleteVariantIdMap:[...this.state.deleteVariantIdMap,this.currentDeleteVariantId],
      visibleDeletedAllVariant:false
    });
    this.showSaveBtn();
  };

  deleteAllDelVariant = (e)=>{
    
    const { bundle } = this.state;
    const del = []
    this.variants.forEach(item => {
        if(item.is_del){
         del.push(item.id)
         delete bundle[item.id]
        }
      }
    );

    this.variants = this.variants.filter(
      item => !del.includes(item.id)
    );

    this.setState({
      bundle: { ...bundle },
      deleteVariantIdMap:del,
      visibleDeletedAllVariant:false
    });
    this.showSaveBtn();
  }

  checkDeleteVariants = (v)=>{
    this.props.checkVariantStatusHandler(v)
  }

  getCollapseStatus = ()=>{
    if(this.props.checkVariantStatus === true){
      return ['1']
    }else if(this.props.checkVariantStatus === false){
      return []
    }else if(this.variants.filter(it => it.is_del).length > 4){
      return []
    }else{
      return ['1']
    }
  }

  openSupplyOptimizer=(prod,type)=>{
    if(type){
      return
    }
    if(!prod?.prod?.suppliers?.length && prod?.prod?.supplier_type === 2){
      window.dtag.set({
        event:'click',
        module:'Aliexpress',
        action:'Aliexpress_figure_search',
        custom_data:{
          'click_type': 'go_supplier_optimizer_mapping'
        }
      })
      const paramObj = {
        url: prod.image,
        ref: 'my_product',
        id: prod.ae_pid,
        store_id: localStorage.getItem('storeId')
      };
      const paramStr = qs.stringify(paramObj);
      const link = `/app/supplier_optimizer?${paramStr}`;
      window.open(link);
      return
    }
    if(((!prod?.prod?.suppliers?.length && window.TMALL_USER) || (prod?.prod?.suppliers?.includes(1) && window.TMALL_USER))){
      window.dtag.set({
        event:'click',
        module:'Aliexpress',
        action:'Aliexpress_figure_search',
        custom_data:{
          'click_type': 'go_supplier_optimizer_mapping'
        }
      })
      const paramObjTmall ={
        url:  prod.image,
        ref: 'import_list',
        supplierType:"unmap",
        store_id: localStorage.getItem('storeId')
      }
      const paramStrTmall = qs.stringify(paramObjTmall)
      window.open(`/app/supplier_optimizer?${paramStrTmall}`)
      return
    }
    if(prod?.prod?.suppliers?.includes(4)){
      window.dtag.set({
        event:'click',
        module:'tmall',
        action:'tmall_figure_search',
        custom_data:{
          'click_type': 'go_supplier_optimizer_mapping'
        }
      })
      const paramObjTmall ={
        url:`https://www.dsers.com/app/tmallproduct/${getAePid(prod.prod)}?appId=159831084`,
        ref: 'import_list',
        supplierType:"tmall",
        store_id: localStorage.getItem('storeId')
      }
      const paramStrTmall = qs.stringify(paramObjTmall)
      window.open(`/app/supplier_optimizer?${paramStrTmall}`)
      return
    }
      window.dtag.set({
        event:'click',
        module:'Aliexpress',
        action:'Aliexpress_figure_search',
        custom_data:{
          'click_type': 'go_supplier_optimizer_mapping'
        }
      })
    
    const paramObj = {
      url: prod.image,
      ref: 'my_product',
      id: prod.ae_pid,
      store_id: localStorage.getItem('storeId')
    };
    const paramStr = qs.stringify(paramObj);
    const link = `/app/supplier_optimizer?${paramStr}`;
    window.open(link);
  }

  render() {
    const {
      originProductData = {},
      copyLink,
      hideSupplier,
      publicBtnDispath,
      switchoverMapping
    } = this.props;

    const {
      bundle,
      showImport,
      moreActionChecked,
      showSave,
      BundleMapping
    } = this.state;
    // 从订单页打开mapping 禁用删除variant按钮
    const { deleteVariantButtonDisabled, orderVaraintId } = originProductData;
    const shopifyIsFBD = this.getIsFBD(); // 这个shopify商品有没有dsers供应商
    const useSupplierStyle = !originProductData.image.includes('https:') ?'not-allowed' :'pointer'
    return (
      <>
        <div className={styles.mappingSatusLine} id="bundleSwitchStatus">
          <Status
            Mapping={BundleMapping}
            self_type={4}
            map_type={this.map_type}
            changeStatus={this.changeStatus}
            isFBD={shopifyIsFBD}
            onRef={this.onBundleChild}
            btnDispath={publicBtnDispath}
            switchoverMapping={switchoverMapping}
            switchoverMappingId='bundleSwitchStatus'
          />
        </div>
        <div className={styles.bundle}>
          <div className={styles.productLine}>
            <div className={styles.product}>
              <p className="h3_line">{intl.get('mapping.ShopifyProduct')}</p>
              <div className="topIc">
                <Popover
                  placement="left"
                  trigger="hover"
                  overlayClassName={styles.zoomProductImg}
                  content={
                    <img src={replaceUrlToHttps(originProductData.image)} />
                  }
                >
                  {
                  originProductData.prod?.ext?.map_type === 6 ? (
                    <img src={replaceUrlToHttps(originProductData.image)} />
                  ) : (
                    <span className={styles.shopifyPic}>
                      <img src={replaceUrlToHttps(originProductData.image)} />
                      <Tooltip title={intl.get('mapping.Findsimilar')}>
                        <span className={styles.findSupplier} style={{cursor:useSupplierStyle}} onClick={()=>this.openSupplyOptimizer(originProductData,!originProductData.image.includes('https:'))}>
                          <i class="material-icons" style={{cursor:useSupplierStyle}}>search</i>
                        </span>
                      </Tooltip>
                    </span>
                  )}
                </Popover>
                <a
                  role="button"
                  tab-index="0"
                  className={styles.productLinkC}
                  onClick={e => copyLink(originProductData)}
                >
                  <Ellipsis lines={4}>{originProductData.title}</Ellipsis>
                </a>
              </div>
            </div>
            <div className={styles.bundleTool}>
              <p className="h3_line">
                {intl.getHTML('mapping.bundle_products')}
                <span
                  className="del-top"
                  onClick={() => {
                    this.clearAll();
                  }}
                >
                  <i class="material-icons">delete</i>
                </span>
              </p>
              <div className="topb">
                <p className="topIcK">{intl.get('mapping.bundle_rule')}</p>
                <p className="topjw">
                  {intl.getHTML('mapping.advanced_more_action_copy')}
                </p>
                <div className="switch">
                  <Switch
                    onChange={checked => {
                      this.setMoreActionChecked(checked);
                      this.handleRadio(checked);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="line_title">
            <p className="one" style={{ height: 48 }}>
              {intl.get('mapping.variant')}
            </p>
            <p className="two" style={{ height: 48, padding: 0 }}>
              Product
            </p>
            <p className="line_box2" style={{ height: 48, textAlign: 'right' }}>
              Quantity
            </p>
          </div>
          <div
            className={styles.variantWrap}
            style={{ height: 'calc( 100vh - 313px )' }}
          >
            <div>
              {this.variants
                .filter(it => !it.is_del)
                .map((variant, i) => {
                  if (!bundle[variant.id]) {
                    return null;
                  }
                  const isOrderVaraint = variant.id === orderVaraintId;
                  return (
                    <div
                      className={className(styles.variantLine, {
                        [styles.removeVariantLine]: variant.is_del
                      })}
                      key={variant.id}
                    >
                      <p
                        className={className(styles.variantName, {
                          [styles.orderVariant]: isOrderVaraint
                        })}
                      >
                        {variant.title}
                      </p>
                      <div className={styles.supplyBox}>
                        {bundle[variant.id].variant_supply[0].supply.map(
                          (sp, spIndex, supply) => {
                            return (
                              <div className={styles.supply} key={`${spIndex}`}>
                                <div className={styles.shipTo}>
                                  Product {spIndex + 1}
                                </div>
                                <div
                                  className={className(styles.supplierVariant, {
                                    [styles.fbdSku]: sp.isFBD
                                  })}
                                >
                                  {sp.sku_image ? (
                                    <div class="tag_vbox">
                                      <div
                                        onClick={() => {
                                          this.showImportSupply(
                                            variant.id,
                                            spIndex,
                                            sp.platform_product_id,
                                            i,
                                            sp
                                          );
                                        }}
                                      >
                                        <Popover
                                          placement="left"
                                          trigger="hover"
                                          content={
                                            <img
                                              src={replaceUrlToHttps(
                                                sp.sku_image
                                              )}
                                            />
                                          }
                                          overlayClassName={
                                            styles.zoomProductImg
                                          }
                                        >
                                          <img
                                            src={replaceUrlToHttps(
                                              sp.sku_image
                                            )}
                                            className={styles.skuImg}
                                          />
                                        </Popover>
                                        <span className="yexy_text">
                                          {sp.sku_desc}
                                        </span>
                                      </div>
                                      <span
                                        className="consw_icon"
                                        onClick={() => {
                                          this.deleteSupply(
                                            variant.id,
                                            spIndex,
                                            i
                                          );
                                        }}
                                      >
                                        <i class="material-icons">close</i>
                                      </span>
                                    </div>
                                  ) : (
                                    <Button
                                      disabled={sp.isFBD}
                                      size="small"
                                      onClick={() => {
                                        this.showImportSupply(
                                          variant.id,
                                          spIndex,
                                          sp.platform_product_id,
                                          i
                                        );
                                        const custom_info = [
                                          {
                                            name: 'mapping type',
                                            value: 'Bundle'
                                          }
                                        ];

                                        window.DSERS_CUSTOM_COLLECT({
                                          module: 'product mapping',
                                          action: 'click supplier button',
                                          custom_info
                                        });
                                      }}
                                    >
                                      <span className="btnAdd">
                                        <i class="material-icons">add</i>
                                      </span>
                                      {intl.getHTML(
                                        'mapping.import_sp_select_var'
                                      )}
                                    </Button>
                                  )}
                                </div>
                                <div className={styles.qty}>
                                  <InputNumber
                                    size="small"
                                    min={sp.sku ? 1 : 0}
                                    disabled={!sp.sku}
                                    value={sp.quantity}
                                    precision={0}
                                    onChange={v => {
                                      this.changeQty(variant.id, spIndex, v);
                                    }}
                                  />
                                </div>
                                {sp.isFBD ? (
                                  <a
                                    className={styles.fbd}
                                    href="/app/fulfillment-center"
                                  >
                                    FBD
                                  </a>
                                ) : null}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
            {this.variants.filter(it => it.is_del).length ? (
              <div className={styles.deletedVariantCon} style={{width:'100%'}}>
                <Collapse
                  activeKey={this.getCollapseStatus()}
                  onChange={this.checkDeleteVariants}
                  bordered={false}
                  expandIconPosition={'left'}
                >
                  <Panel
                    header={
                      <div className={styles.description}>
                        <span>Variants deleted at Shopify</span>
                        <Tooltip
                          title={intl.getHTML('mapping.delete_variant_collapse_supplier_tip')}
                        >
                          <img src={noticeIcon} alt="" />
                        </Tooltip>
                      </div>
                    }
                    key="1"
                    extra={
                      <i
                        class="material-icons"
                        onClick={e => {
                          e.stopPropagation();
                          this.setState({
                            isDeleteAll:true,
                            visibleDeletedAllVariant: true
                          });
                        }}
                      >
                        delete
                      </i>
                    }
                  >
                    {this.variants
                      .filter(it => it.is_del)
                      .map((variant, i) => {
                        if (!bundle[variant.id]) {
                          return null;
                        }
                        const isOrderVaraint = variant.id === orderVaraintId;
                        return (
                          <div
                            className={className(styles.variantLine, {
                              [styles.removeVariantLine]: variant.is_del
                            })}
                            key={variant.id}
                          >
                            <p
                              className={className(styles.variantName, {
                                [styles.orderVariant]: isOrderVaraint
                              })}
                            >
                              {variant.title}
                              {variant.is_del && (
                                <div className={styles.variantDeleteIcon}>
                                  Deleted
                                </div>
                              )}
                              {variant.is_del &&
                              !deleteVariantButtonDisabled ? (
                                <i
                                  class="material-icons"
                                  onClick={() => this.deleteVariant(variant.id)}
                                >
                                  delete
                                </i>
                              ) : null}
                            </p>
                            <div className={styles.supplyBox}>
                              {bundle[variant.id].variant_supply[0].supply.map(
                                (sp, spIndex, supply) => {
                                  return (
                                    <div
                                      className={styles.supply}
                                      key={`${spIndex}`}
                                    >
                                      <div className={styles.shipTo}>
                                        Product {spIndex + 1}
                                      </div>
                                      <div
                                        className={className(
                                          styles.supplierVariant,
                                          {
                                            [styles.fbdSku]: sp.isFBD
                                          }
                                        )}
                                      >
                                        {sp.sku_image ? (
                                          <div class="tag_vbox">
                                            <div
                                              onClick={() => {
                                                this.showImportSupply(
                                                  variant.id,
                                                  spIndex,
                                                  sp.platform_product_id,
                                                  i,
                                                  sp
                                                );
                                              }}
                                            >
                                              <Popover
                                                placement="left"
                                                trigger="hover"
                                                content={
                                                  <img
                                                    src={replaceUrlToHttps(
                                                      sp.sku_image
                                                    )}
                                                  />
                                                }
                                                overlayClassName={
                                                  styles.zoomProductImg
                                                }
                                              >
                                                <img
                                                  src={replaceUrlToHttps(
                                                    sp.sku_image
                                                  )}
                                                  className={styles.skuImg}
                                                />
                                              </Popover>
                                              <span className="yexy_text">
                                                {sp.sku_desc}
                                              </span>
                                            </div>
                                            <span
                                              className="consw_icon"
                                              onClick={() => {
                                                this.deleteSupply(
                                                  variant.id,
                                                  spIndex,
                                                  i
                                                );
                                              }}
                                            >
                                              <i class="material-icons">
                                                close
                                              </i>
                                            </span>
                                          </div>
                                        ) : (
                                          <Button
                                            disabled={sp.isFBD}
                                            size="small"
                                            onClick={() => {
                                              this.showImportSupply(
                                                variant.id,
                                                spIndex,
                                                sp.platform_product_id,
                                                i
                                              );
                                              const custom_info = [
                                                {
                                                  name: 'mapping type',
                                                  value: 'Bundle'
                                                }
                                              ];

                                              window.DSERS_CUSTOM_COLLECT({
                                                module: 'product mapping',
                                                action: 'click supplier button',
                                                custom_info
                                              });
                                            }}
                                          >
                                            <span className="btnAdd">
                                              <i class="material-icons">add</i>
                                            </span>
                                            {intl.getHTML(
                                              'mapping.import_sp_select_var'
                                            )}
                                          </Button>
                                        )}
                                      </div>
                                      <div className={styles.qty}>
                                        <InputNumber
                                          size="small"
                                          min={sp.sku ? 1 : 0}
                                          disabled={!sp.sku}
                                          value={sp.quantity}
                                          precision={0}
                                          onChange={v => {
                                            this.changeQty(
                                              variant.id,
                                              spIndex,
                                              v
                                            );
                                          }}
                                        />
                                      </div>
                                      {sp.isFBD ? (
                                        <a
                                          className={styles.fbd}
                                          href="/app/fulfillment-center"
                                        >
                                          FBD
                                        </a>
                                      ) : null}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        );
                      })}
                  </Panel>
                </Collapse>
              </div>
            ) : null}
          </div>
          {showImport && (
            <ImportAliExpressMapping
              visible={showImport}
              hideImportSupply={this.hideImportSupply}
              importCallback={this.importCallback}
              importProductId={this.importProductId}
              shopifyProductId={this.shopifyProductId}
              storeId={originProductData.prod.store_id}
              selectData={this.state.select || []}
              sp={this.sp}
            />
          )}
        </div>
        <Modal
          visible={this.state.visibleDeletedAllVariant}
          width={560}
          title={intl.getHTML('mapping.confirm_delete_all_variant_title')}
          className={styles.deleteTipModal}
          onCancel={() => {
            this.setState({
              visibleDeletedAllVariant: false
            });
          }}
          footer={
            <>
              <Button
                className={styles.btn}
                onClick={this.state.isDeleteAll ? this.deleteAllDelVariant : this.deleteVariantTrue}
              >
                {intl.getHTML('public.newconfirm')}
              </Button>
            </>
          }
        >
          <div>{intl.getHTML('mapping.confirm_delete_all_variant')}</div>
        </Modal>
      </>
    );
  }
}
