import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux';
import { connect } from 'dva';
import { useHistory } from "react-router-dom";
import styles from './DataMigration.less'
import logo from 'assets/svg/new_logo.png';
import setting from 'assets/migration/setting.png'
import ds from 'assets/migration/ds.png'
import shopify from 'assets/migration/shopify.png'
import messages from 'assets/migration/Message.png'
import { Button, Dropdown, Menu, message, Carousel, Icon, Tooltip } from 'antd';
import { Post, Get } from 'utils/request';
import intl from 'react-intl-universal';
import SelectLanguage from 'components/GlobalHeader/SelectLanguage';
import card from 'assets/guideCard.png'
import { CommonDataCollect, getOberloReport } from 'utils/utils';
import AwaitMigration from '../AwaitMigration/AwaitMigration'
import Appointment from './Appointment';

function DataMigration({ userInfo, migrationInfo, newUserInfo, dsers_oberlo_plan }) {


    const [migraType, setMigraType] = useState(1) // 1:预迁移 2:迁移中
    const [hasError, setHasError] = useState(false)
    const [errorType, setErrorType] = useState(1) //错误类型 1:代表店铺授权失败 2:断点续迁
    const [settingInfo, setSettingInfo] = useState() //setting模块状态
    const [orderInfo, setOrderInfo] = useState()    //order模块状态
    const [productInfo, setProductInfo] = useState() //product模块状态
    const [myProductInfo, setMyProductInfo] = useState() //myproduct模块状态
    const [planInfo, setPlanInfo] = useState() //plan模块状态
    const [startLoading, setStartLoading] = useState(false)
    const [showCard, setShowCard] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [requestId, setRequestId] = useState('')
    const [childAccount, setChildAccount] = useState(false) // ture:展示子账号等待页面
    const [loadingStatus, setLoadingStatus] = useState(false)
    const [timeDis, setTimeDis] = useState(false)
    const [selectTime, setSelectTime] = useState({  // 已选迁移时间和时区
        time: 'Start migration now',
        timezone: ''
    })

    const dispatch = useDispatch()
    const history = useHistory()
    let interval = useRef(null)

    const optionsData = intl.get('migration.optionstext')

    useEffect(() => {
        getMigraTionProcess()

        return function () {
            clearInterval(interval.current)
        }
    }, [])

    useEffect(() => {
        (async ()=>{
            const res = await Get('/mgnoberlo-core-api/dsers-api/migration/user/status',[],true);
            CommonDataCollect({
                action: 'enter_migrationpage',
                store_id: res?.dsers_store_id,
                custom_data: []
            })
        })()

        getStatus()
    }, [])

    useEffect(() => {
        if (newUserInfo.type == 2) {
            setChildAccount(true)
        }

        if (migrationInfo?.code == 500) {
            setRequestId(migrationInfo?.metadata?.trace_id)
            setHasError(true)
            setErrorMessage(intl.get('migration.dataerror'))
            return
        }

        if (migrationInfo?.step_stage == 'STEP_STAGE_PRE' || migrationInfo?.step_stage == 'STEP_STAGE_QUEUE') {
            setMigraType(1)
        } else if (migrationInfo?.step_stage == 'STEP_STAGE_STEP1') {
            setMigraType(2)
        } else if (migrationInfo?.step_stage == 'STEP_STAGE_' || migrationInfo?.step_stage == 'STEP_STAGE_STEP2') {
            clearInterval(interval.current)
            //将各模块置为完成状态
            setSettingInfo({ ...settingInfo, status: 'MIGRATION_STEP_STATUS_COMPLETE' }),
                setOrderInfo({ ...orderInfo, status: 'MIGRATION_STEP_STATUS_COMPLETE' })
            setProductInfo({ ...productInfo, status: 'MIGRATION_STEP_STATUS_COMPLETE' })
            setMyProductInfo({ ...myProductInfo, status: 'MIGRATION_STEP_STATUS_COMPLETE' })
            setPlanInfo({ ...planInfo, status: 'MIGRATION_STEP_STATUS_COMPLETE' })
            setTimeout(() => {
                window.location.href = '/app/'
            }, 6000)

        }

        migrationInfo?.step_stage == 'STEP_STAGE_STEP1' && migrationInfo.item_steps?.forEach(item => {
            switch (item.step) {
                case 'MIGRATION_STEP_SETTING':
                    return setSettingInfo(item)
                    break;
                case 'MIGRATION_STEP_ORDER':
                    return setOrderInfo(item)
                    break;
                case 'MIGRATION_STEP_PRODUCT':
                    return setProductInfo(item)
                    break;
                case 'MIGRATION_STEP_MY_PRODUCT':
                    return setMyProductInfo(item)
                    break;
                case 'MIGRATION_STEP_PLAN':
                    return setPlanInfo(item)
                    break;
            }
        })

        if (migrationInfo?.status == 'MIGRATION_STATUS_ERROR') {
            setHasError(true)
            setRequestId(migrationInfo?.metadata?.trace_id)
            if (migrationInfo?.err_code == 'SHOPIFY_UNAUTHORIZED') {
                setErrorType(1)
                setErrorMessage(intl.get('migration.shoperror'))
            } else if (migrationInfo?.err_code == 'OBERLO_ERROR') {
                setErrorType(2)
                setErrorMessage(intl.get('migration.oberloError'))
            } else {
                setErrorType(2)
                setErrorMessage(intl.get('migration.dataerror'))
            }
        } else if (migrationInfo?.status == 'MIGRATION_STATUS_STOP') {
            setRequestId(migrationInfo?.metadata?.trace_id)
            setHasError(true)
            setErrorMessage('')
            setErrorType(1)
        } else {
            setHasError(false)
            setErrorMessage('')
        }

    }, [migrationInfo])

    useEffect(()=>{
        if(migrationInfo?.step_stage == 'STEP_STAGE_STEP1'){
            (async ()=>{
                const res = await Get('/mgnoberlo-core-api/dsers-api/migration/user/status',[],true);
                CommonDataCollect({
                    action: 'migration_enterstep1',
                    store_id: res?.dsers_store_id,
                    shop_name: res?.shopify_domain,
                    custom_data: []
                })
            })()
        }
    },[migrationInfo.step_stage])

    /* 轮询迁移状态 */
    const getMigraTionProcess = () => {
        interval.current = setInterval(() => {
            dispatch({
                type: 'auth/migrationInfo'
            })
        }, 5000);
    }
    /* 页面判断拦截 */
    const getStatus = async () => {
        const res = await Get("/mgnoberlo-core-api/dsers-api/migration/user/status", {}, true)
        if (!res?.migrating_shopify_domain || res?.step_stage == 'STEP_STAGE_STEP2') { // 没有店铺域名或当前处于step2 跳转 交由basiclayout处理
            window.location.href = '/app/'
        }
        //当前账号不是新用户并且未选择偏好则跳走
        if (!res?.is_new_user && (res?.status == 'MIGRATION_STATUS_PRE_BINDACCOUNT' || res?.status == 'MIGRATION_STATUS_PRE_BINDSHOPIFY' || res?.status == 'MIGRATION_STATUS_PRE_REQUEST')) {
            window.location.href = '/app/'
        }
    }

    const menu = (
        <Menu>
            {
                migrationInfo?.step_stage == 'STEP_STAGE_PRE' ?
                    <Menu.Item>
                        <Tooltip placement='top' title={intl.get('migration.exitmigrationtext')}>
                            <div style={{ display: 'flex', alignItems: 'center' }} onClick={async () => {
                                const res = await Post('/mgnoberlo-core-api/dsers-api/pre-migration/leave', {
                                    data: {
                                        shopify_domain: migrationInfo?.migrating_shopify_domain
                                    }
                                }, true)
                                if (!res.code) {
                                    localStorage.removeItem('ifMigration')
                                    localStorage.removeItem('logoutEmail')
                                    window.localStorage.removeItem('store_is_available');
                                    window.localStorage.removeItem('EnterNewHomeTime');
                                    window.localStorage.removeItem('setMigrationInfo');
                                    window.localStorage.removeItem('noMigra');
                                    window.localStorage.removeItem('close_tip');
                                    window.location.href = '/app/'
                                }
                            }}>
                                <i className="material-icons" style={{ marginRight: '12px', fontSize: '21px' }}>sync_disabled</i>
                                <div style={{ display: 'flex', justifyContent: 'left' }}><span style={{ fontSize: '14px' }}>{intl.get('migration.exitmigration')}</span></div>
                                <Icon style={{ fontSize: "15px", marginLeft: '12px' }} type="question-circle" />
                            </div>
                        </Tooltip>
                    </Menu.Item> : null
            }
            <Menu.Item onClick={() => {
                dispatch({
                    type: 'login/logout'
                });
            }}>
                <div style={{ display: 'flex', justifyContent: "left" }}><i className="material-icons" style={{ marginRight: '12px', fontSize: '21px' }}>redo</i><span style={{ fontSize: '14px' }}>{intl.get('public.log_out')}</span></div>
            </Menu.Item>
        </Menu>
    )

    const handleClick = async () => {
        setStartLoading(true)
        !selectTime.time.start && setLoadingStatus(true)
        const use_res = await Get('/mgnoberlo-core-api/dsers-api/migration/user/status',[],true);
        if(use_res.migrating_shopify_domain){
            const res = await Post('/mgnoberlo-core-api/dsers-api/migration/start', {
                data: {
                    shopify_domain: use_res.migrating_shopify_domain,
                    booking_start_time: Number(selectTime.time.start),
                    booking_end_time: Number(selectTime.time.end),
                    booking_timezone: selectTime.timezone
                }
            }, true)
            //返回403代表店铺授权失败 跳shopify
            if (res.code == 403 || res.message == 'StartMigrationToDsersQueue: resp.StatusCode is 401, not ok') {
                const response = await Post("/mgnoberlo-core-api/dsers-api/migration/auth/shopify/bind", {
                    data: {
                        shop_name: migrationInfo?.migrating_shopify_domain.split('.myshopify.com')[0],
                        store_id: migrationInfo?.dsers_store_id
                    }
                }, true)
                window.location.href = response.redirect_url;
            } else if (res.code == 400) { //400代表正在有下单的商品
                message.error(intl.get('migration.havetask'),5)
                setStartLoading(false)
                setLoadingStatus(false)
            } else if (res.code == 500) { // 500 超时等未知错误
                message.error(<div style={{ float: 'right' }}>{intl.get('migration.timeout')} <Tooltip placement='top' title={`${intl.get('migration.requestid')}${res?.metadata?.trace_id}`}><Icon style={{ fontSize: "15px", marginLeft: '5px' }} type="question-circle" /></Tooltip></div>)
                setStartLoading(false)
                setLoadingStatus(false)
            } else if (res.code == 407) {
                setTimeDis(true)
                setStartLoading(false)
                setLoadingStatus(false)
            }else {
                window.localStorage.removeItem('migrate_success')
                CommonDataCollect({
                    action: 'migration_enterstep1',
                    store_id: JSON.parse(localStorage.getItem('migration_stores'))?.pop()?.dsers_store_id,
                    shop_name: JSON.parse(localStorage.getItem('migration_stores'))?.pop()?.shopify_domain,
                    custom_data: []
                })
    
                dispatch({
                    type: 'auth/migrationInfo'
                })
                setTimeout(() => {
                    setLoadingStatus(false)
                    setStartLoading(false)
                }, 5000)
                setTimeout(() => {
                    setShowCard(true)
                }, 5000)
            }
        }
    }

    const renderText = () => {
        if (settingInfo?.status == 'MIGRATION_STEP_STATUS_INPROCESS') {
            return 'setting'
        } else if (orderInfo?.status == 'MIGRATION_STEP_STATUS_INPROCESS') {
            return 'order'
        } else if (productInfo?.status == 'MIGRATION_STEP_STATUS_INPROCESS' || myProductInfo?.status == 'MIGRATION_STEP_STATUS_INPROCESS') {
            return 'product'
        } else if (planInfo?.status == 'MIGRATION_STEP_STATUS_INPROCESS') {
            return 'plan'
        }
    }
    /**
     * 各模块样式类名
     * @param {number} type  0:setting 1:order 2:product 3:plan
     * @returns 类名
     */
    const boxClassname = (type) => {
        if (type == 0) {
            switch (settingInfo?.status) {
                case 'MIGRATION_STEP_STATUS_WAIT':
                    return styles.boxitem_dis
                    break;
                case 'MIGRATION_STEP_STATUS_INPROCESS':
                    if (settingInfo?.err_msg !== 'ok') {
                        return styles.boxitem_error
                    } else {
                        return styles.boxitem
                    }
                    break;
                case 'MIGRATION_STEP_STATUS_COMPLETE':
                    if (settingInfo?.err_msg !== 'ok') {
                        return styles.boxitem_error
                    } else {
                        return styles.boxitem_finish
                    }
                    break;
            }
        }
        if (type == 1) {
            switch (orderInfo?.status) {
                case 'MIGRATION_STEP_STATUS_WAIT':
                    return styles.boxitem_dis
                    break;
                case 'MIGRATION_STEP_STATUS_INPROCESS':
                    if (orderInfo?.err_msg !== 'ok') {
                        return styles.boxitem_error
                    } else {
                        return styles.boxitem
                    }
                    break;
                case 'MIGRATION_STEP_STATUS_COMPLETE':
                    if (orderInfo?.err_msg !== 'ok') {
                        return styles.boxitem_error
                    } else {
                        return styles.boxitem_finish
                    }
                    break;
            }
        }
        if (type == 2) {
            switch (productInfo?.status) {
                case 'MIGRATION_STEP_STATUS_WAIT':
                    return styles.boxitem_dis
                    break;
                case 'MIGRATION_STEP_STATUS_INPROCESS':
                    if (productInfo?.err_msg !== 'ok') {
                        return styles.boxitem_error
                    } else {
                        return styles.boxitem
                    }
                    break;
                case 'MIGRATION_STEP_STATUS_COMPLETE':
                    if (productInfo?.err_msg !== 'ok' || myProductInfo?.err_msg !== 'ok') {
                        return styles.boxitem_error
                    } else {
                        if (myProductInfo?.status == 'MIGRATION_STEP_STATUS_COMPLETE') {
                            return styles.boxitem_finish
                        } else {
                            return styles.boxitem
                        }

                    }
                    break;
            }
        }
        if (type == 3) {
            switch (planInfo?.status) {
                case 'MIGRATION_STEP_STATUS_WAIT':
                    return styles.boxitem_dis
                    break;
                case 'MIGRATION_STEP_STATUS_INPROCESS':
                    if (planInfo?.err_msg !== 'ok') {
                        return styles.boxitem_error
                    } else {
                        return styles.boxitem
                    }
                    break;
                case 'MIGRATION_STEP_STATUS_COMPLETE':
                    if (planInfo?.err_msg !== 'ok') {
                        return styles.boxitem_error
                    } else {
                        return styles.boxitem_finish
                    }
                    break;
            }
        }

    }
    /**
     * 各模块icon展示
     * @param {number} type
     * @param {*} icon 默认icon
     * @param {*} iconMap icon映射关系
     * @returns icon图标
     */
    const boxIcon = (type, icon, iconMap) => {
        if (type == 0) {
            switch (settingInfo?.status) {
                case 'MIGRATION_STEP_STATUS_WAIT':
                    return icon
                    break;
                case 'MIGRATION_STEP_STATUS_INPROCESS':
                    if (settingInfo?.err_msg !== 'ok') {
                        return iconMap[1]
                    } else {
                        return icon
                    }
                    break;
                case 'MIGRATION_STEP_STATUS_COMPLETE':
                    if (settingInfo?.err_msg !== 'ok') {
                        return iconMap[1]
                    } else {
                        return iconMap[0]
                    }
                    break;
            }
        }
        if (type == 1) {
            switch (orderInfo?.status) {
                case 'MIGRATION_STEP_STATUS_WAIT':
                    return icon
                    break;
                case 'MIGRATION_STEP_STATUS_INPROCESS':
                    if (orderInfo?.err_msg !== 'ok') {
                        return iconMap[1]
                    } else {
                        return icon
                    }
                    break;
                case 'MIGRATION_STEP_STATUS_COMPLETE':
                    if (orderInfo?.err_msg !== 'ok') {
                        return iconMap[1]
                    } else {
                        return iconMap[0]
                    }
                    break;
            }
        }
        if (type == 2) {
            switch (productInfo?.status) {
                case 'MIGRATION_STEP_STATUS_WAIT':
                    return icon
                    break;
                case 'MIGRATION_STEP_STATUS_INPROCESS':
                    if (productInfo?.err_msg !== 'ok') {
                        return iconMap[1]
                    } else {
                        return icon
                    }
                    break;
                case 'MIGRATION_STEP_STATUS_COMPLETE':
                    if (productInfo?.err_msg !== 'ok' || myProductInfo?.err_msg !== 'ok') {
                        return iconMap[1]
                    } else {
                        if (myProductInfo?.status == 'MIGRATION_STEP_STATUS_COMPLETE') {
                            return iconMap[0]
                        }
                        return icon
                    }
                    break;
            }
        }
        if (type == 3) {
            switch (planInfo?.status) {
                case 'MIGRATION_STEP_STATUS_WAIT':
                    return icon
                    break;
                case 'MIGRATION_STEP_STATUS_INPROCESS':
                    if (planInfo?.err_msg !== 'ok') {
                        return iconMap[1]
                    } else {
                        return icon
                    }
                    break;
                case 'MIGRATION_STEP_STATUS_COMPLETE':
                    if (planInfo?.err_msg !== 'ok') {
                        return iconMap[1]
                    } else {
                        return iconMap[0]
                    }
                    break;
            }
        }
    }

    const backPreMigration = () => {
        clearInterval(interval.current)
        window.location.href = '/app/pre-migrate'
    }

    /**
     * 拿预约迁移子组件选择的状态
     * @param {object} item 
     * @param {string} timezone 
     */
    const handleSelectTime = (item, timezone) => {
        setSelectTime({
            time: item,
            timezone: timezone
        })
    }

    const renderButton = () => {
        if (migrationInfo?.step_stage == 'STEP_STAGE_PRE' || loadingStatus) {
            if (selectTime.time.constructor !== String) {
                return <Tooltip placement='topRight' title={intl.getHTML('migration.appointTip')} ><Button loading={startLoading} onClick={handleClick}><div className={styles.start} ><span>{intl.get('migration.appointConfirm')}</span>
                    <Icon type="question-circle" />
                </div></Button></Tooltip>
            } else {
                return <Button loading={startLoading} onClick={handleClick}><div className={styles.start} ><span>{intl.get('migration.data_button')}</span>
                </div></Button>
            }
        } else {
            return <Button disabled={true} ><div className={styles.start} >{migrationInfo?.step_stage == 'STEP_STAGE_QUEUE_RESERVATION' ? intl.get('migration.appointSuccess') : intl.get('migration.data_button_wait')}</div></Button>
        }
    }

    const StartMigration = () => {
        return (
            <div className={styles.middle}>
                <div className={styles.welcome}>{intl.get('migration.data_title')}</div>
                <div className={styles.text}>{intl.get('migration.data_title_2')}</div>
                <div className={styles.mid_icon}><div className={styles.mid_obl}><div style={{ width: '66px', height: '66px', border: '24px solid #2F01FD', borderRadius: '33px' }}></div></div><i class="material-icons" style={{ color: "#7216DD", fontSize: '44px' }}>chevron_right</i><i class="material-icons" style={{ color: "#AE497F", fontSize: '44px' }}>chevron_right</i><i class="material-icons" style={{ color: "#FF8F00", fontSize: '44px' }}>chevron_right</i><div className={styles.mid_dsers}><img src="https://img.dsers.com/images/logo.png" alt="" /></div></div>
                <div className={styles.appointment}>
                </div>
                {
                    renderButton()
                }
                <div className={styles.starttext}>{migrationInfo.step_stage == 'STEP_STAGE_PRE' || loadingStatus ? intl.get('migration.data_button_text', { time: `${migrationInfo.tag == 'small' ? 1 : 5}` }) : migrationInfo.step_stage !== 'STEP_STAGE_QUEUE_RESERVATION' && intl.get('migration.data_wait_text', { time: `${migrationInfo.tag == 'small' ? 1 : 5}` })}</div>
                {migrationInfo.step_stage == 'STEP_STAGE_PRE' && !migrationInfo.is_new_user ? <a onClick={backPreMigration}>{intl.get('migration.data_change')}</a> : null}
                {migrationInfo.step_stage == 'STEP_STAGE_QUEUE_RESERVATION' && !migrationInfo.is_new_user ? <a onClick={() => { window.location.href = '/app/' }}>Access to DSers</a> : null}
            </div>
        )
    }

    // 迁移中渲染函数
    const Migrating = () => {

        const contentStyle = {
            textAlign: 'center',
        }

        return (
            <>
                <div className={styles.middle_2}>
                    <div className={styles.midTitle}>{intl.get('migration.data_process_title')}</div>
                    <div className={styles.midText}>{intl.get('migration.data_process_text')}</div>
                    <div className={styles.midLine}><div></div><div></div></div>
                </div>
                <div className={styles.box}>
                    <div className={styles.mid_icon}><div className={styles.mid_obl}><div style={{ width: '33px', height: '33px', border: '12px solid #2F01FD', borderRadius: '16px' }}></div></div><i class="material-icons" style={{ color: "#7216DD", fontSize: '22px' }}>chevron_right</i><i class="material-icons" style={{ color: "#AE497F", fontSize: '22px' }}>chevron_right</i><i class="material-icons" style={{ color: "#FF8F00", fontSize: '22px' }}>chevron_right</i><div className={styles.mid_dsers}><img src="https://img.dsers.com/images/logo.png" alt="" /></div></div>
                    <div className={styles.processBox}>
                        {BoxItem(<img src={setting} alt="" />, intl.get('migration.data_setting'), 0)}
                        <div className={settingInfo?.status == 'MIGRATION_STEP_STATUS_INPROCESS' ? styles.arrow : styles.arrow_dis}><i class="material-icons" style={{ color: "#7216DD", fontSize: '22px' }}>chevron_right</i><i class="material-icons" style={{ color: "#AE497F", fontSize: '22px' }}>chevron_right</i><i class="material-icons" style={{ color: "#FF8F00", fontSize: '22px' }}>chevron_right</i></div>
                        {BoxItem(<i className="material-icons" style={{ fontSize: '37px', color: '#ffffff' }}>assignment</i>, intl.get('migration.data_order'), 1)}
                        <div className={orderInfo?.status == 'MIGRATION_STEP_STATUS_INPROCESS' ? styles.arrow : styles.arrow_dis}><i class="material-icons" style={{ color: "#7216DD", fontSize: '22px' }}>chevron_right</i><i class="material-icons" style={{ color: "#AE497F", fontSize: '22px' }}>chevron_right</i><i class="material-icons" style={{ color: "#FF8F00", fontSize: '22px' }}>chevron_right</i></div>
                        {BoxItem(<i className="material-icons" style={{ fontSize: '37px', color: '#ffffff' }}>local_mall</i>, intl.get('migration.data_product'), 2)}
                        <div className={productInfo?.status == 'MIGRATION_STEP_STATUS_INPROCESS' || myProductInfo?.status == 'MIGRATION_STEP_STATUS_INPROCESS' ? styles.arrow : styles.arrow_dis}><i class="material-icons" style={{ color: "#7216DD", fontSize: '22px' }}>chevron_right</i><i class="material-icons" style={{ color: "#AE497F", fontSize: '22px' }}>chevron_right</i><i class="material-icons" style={{ color: "#FF8F00", fontSize: '22px' }}>chevron_right</i></div>
                        {BoxItem(<i className="material-icons" style={{ fontSize: '37px', color: '#ffffff' }}>tune</i>, intl.get('migration.data_plan'), 3)}
                    </div>
                </div>
                <div className={styles.migrating}>
                    <Carousel autoplay dots={false} effect="fade" autoplaySpeed={3000}>
                        {optionsData[renderText()]?.map(item => {
                            return <div>
                                <h3 style={contentStyle}>{item}</h3>
                            </div>
                        })}
                    </Carousel>
                </div>
            </>
        )
    }
    /**
     * 控制每个模块的展示
     * @param {*} icon 模块图标
     * @param {*} text 模块文案
     * @param {*} type 模块枚举
     * @returns 
     */
    const BoxItem = (icon, text, type) => {

        const iconMap = [<i className="material-icons" style={{ fontSize: '37px', color: '#ffffff' }}>done</i>, <i className="material-icons" style={{ fontSize: '37px', color: '#ffffff' }}>close</i>]

        return (
            <div className={styles.outbox}>
                <div className={boxClassname(type)}>
                    <div className={styles.center}>
                        <div className={styles.blue}>
                        </div>
                    </div>
                </div>
                <div className={styles.boxicon}>{boxIcon(type, icon, iconMap)}</div>
                <div>{text}</div>
            </div>
        )

    }

    const closeCard = () => {
        setShowCard(false)
    }

    const handleContinue = async () => {
        if (errorMessage == 'Please ensure that your Shopify store is ready and has authorized DSers') {
            const response = await Post("/mgnoberlo-core-api/dsers-api/migration/auth/shopify/bind", {
                data: {
                    shop_name: migrationInfo?.migrating_shopify_domain.split('.myshopify.com')[0],
                    store_id: migrationInfo?.dsers_store_id
                }
            }, true)
            window.location.href = response.redirect_url;
        } else {
            setStartLoading(true)
            const use_res = await Get('/mgnoberlo-core-api/dsers-api/migration/user/status',[],true);
            if(use_res?.migrating_shopify_domain){
                const res = await Post('/mgnoberlo-core-api/dsers-api/migration/start', {
                    data: {
                        shopify_domain: use_res?.migrating_shopify_domain
                    }
                }, true)
                if (res.code == 403) {
                    const response = await Post("/mgnoberlo-core-api/dsers-api/migration/auth/shopify/bind", {
                        data: {
                            shop_name: migrationInfo?.migrating_shopify_domain.split('.myshopify.com')[0],
                            store_id: migrationInfo?.dsers_store_id
                        }
                    }, true)
                    window.location.href = response.redirect_url;
                } else {
                    dispatch({
                        type: 'auth/migrationInfo'
                    })
                    setStartLoading(false)
                }
            }
        }
    }

    const handleTryAgain = async () => {
        setStartLoading(true)
        const use_res = await Get('/mgnoberlo-core-api/dsers-api/migration/user/status',[],true);
        if(use_res?.migrating_shopify_domain){
            const res = await Post('/mgnoberlo-core-api/dsers-api/migration/start', {
                data: {
                    shopify_domain: use_res?.migrating_shopify_domain
                }
            }, true).then((data) => {
                dispatch({
                    type: 'auth/migrationInfo'
                })
                setStartLoading(false)
            })
        }
    }


    return (
        childAccount ? <AwaitMigration></AwaitMigration>
            :
            <>
                <div className={styles.container}>
                    <div className={styles.top}>
                        <img src={logo} alt="" />
                        <div className={styles.user}>
                            <div style={{ lineHeight: '64px' }}><SelectLanguage /></div>
                            <div></div>
                            <div><img src={shopify} alt="" />{JSON.parse(localStorage.getItem('migration_stores'))?.pop()?.shopify_domain}</div>
                            <div></div>
                            <div className={styles.dropimg}><img src={ds} alt="" /></div>
                            <div><Dropdown overlay={menu} trigger={['hover']}>
                                <span className={styles.drop}>
                                    <span>{userInfo.email}</span>
                                    <i style={{ position: 'relative', top: '2px', marginLeft: '10px' }} className="material-icons">arrow_drop_down</i>
                                </span>
                            </Dropdown></div>
                        </div>
                    </div>
                    <div className={styles.process}>
                        <div><i className="material-icons notranslate" style={{ color: '#FF8F00', fontSize: '30px' }}>
                            check_circle
                        </i><span>{intl.get('migration.progress_1')}</span></div>
                        <span className={styles.line}></span>
                        <div> <i className="material-icons notranslate" style={{ color: '#FF8F00', fontSize: '30px' }}>
                            check_circle
                        </i><span>{intl.get('migration.progress_2')}</span></div>
                        <span className={styles.line}></span>
                        <div> <span>3</span><span>{intl.get('migration.progress_3')}</span></div>
                    </div>
                    {errorMessage ? <div className={styles.migraerror}><i className="material-icons notranslate" style={{ color: "#F44436" }}>error</i> {errorMessage} {requestId && <Tooltip placement='top' title={`${intl.get('migration.requestid')}${requestId}`}><Icon style={{ fontSize: "15px", marginLeft: '5px' }} type="question-circle" /></Tooltip>} </div> : null}
                    {migraType == 1 ? StartMigration() : Migrating()}
                    {hasError ?
                        <div className={styles.contact}>
                            <Button onClick={() => { window.open('https://www.messenger.com/t/DSersOfficial') }}>CONTACT CUSTOMER SERVICE</Button>
                            {
                                errorType == 1 ? <Button onClick={handleContinue} loading={startLoading}>{intl.get('migration.shopcontinue')}</Button> : <Button loading={startLoading} onClick={handleTryAgain}>{intl.get('migration.datacontinue')}</Button>
                            }
                        </div> : null}
                    <a className={styles.bottom} style={{ marginTop: '48px' }} target="_blank" href="https://help.dsers.com/oberlo-migration/">{intl.get('migration.blog')}</a>
                    <div className={styles.end}>
                        <div><img src={messages} alt="" /><a href='https://www.messenger.com/t/DSersOfficial' target='_blank'>{intl.get('migration.contact_1')}</a></div>
                        <div></div>
                        <i className="material-icons">local_post_office</i><a href='mailto: support@dserspro.com'>{intl.get('migration.contact_2')}</a>
                    </div>
                </div>
                <div className={styles.modal} style={showCard ? null : { bottom: '-220px' }}>
                    <a target="_blank" href='https://help.dsers.com/oberlo-migration/'>Quick guide to get farmiliar to DSers by comparing to Oberlo features</a>
                    <img src={card} alt="" />
                    <Icon type='close' onClick={closeCard} />
                </div>
            </>
    )
}

export default connect(({ login, auth, user, topBtn }) => ({
    userInfo: login.userInfo,
    migrationInfo: auth.migrationInfo,
    newUserInfo: user.newUserInfo,
    dsers_oberlo_plan: topBtn.fromOberloPlan
}))(DataMigration)