import React from 'react';
import { Modal, Button, Row, Col } from 'antd';

import intl from 'react-intl-universal';

import styles from './index.less';

export default class AEAccountDisconnect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }

  componentWillMount() {
    if (
      localStorage.getItem('aliExpressAuthStatus') == 'true' ||
      localStorage.getItem('aliExpressAuthStatus') == 'null'
    ) {
      this.setState({
        visible: false
      });
    } else if (
      localStorage.getItem('aliExpressAuthStatus') == 'false' ||
      !localStorage.getItem('aliExpressAuthStatus')
    ) {
      this.setState({
        visible: true
      });
    }
  }

  handleCancelAlert = () => {
    localStorage.setItem('aliExpressAuthStatus1', 1);
    if (Number(localStorage.getItem('aliExpressAuthStatus1')) == 1) {
      this.setState({
        visible: false
      });
    }
    this.forceUpdate();
  };

  render() {
    return (
      <Modal
        visible={this.state.visible}
        footer={false}
        width={650}
        maskClosable={false}
        onCancel={this.handleCancelAlert}
      >
        <div
          style={{
            padding: '10px 20px'
          }}
        >
          <h3 className={styles.title}>
            {intl.getHTML('aeAccountDisconnect.title')}
          </h3>
          <Row type="flex" justify="end">
            <Col>
              <Button
                type="primary"
                size="large"
                onClick={this.handleCancelAlert}
              >
                {intl.get('public.ok')}
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}
