export default {
  agency: {
    // global
    Agency: 'Agenzia (fornitore individuale)',
    agency: 'agenzia',
    // setting
    supplier: 'Rivenditore',
    title: "Gestione dell'Agenzia (Fornitore Individuale)",
    subtitle:
      "Puoi gestire le informazioni del fornitore qui e ordinare direttamente dal fornitore senza più acquistare da un mercato di terze parti (come AliExpress). Clicca qui per ulteriori informazioni.",
    subtitle_1: `Puoi gestire le informazioni del fornitore qui e ordinare direttamente dal fornitore senza più acquistare da un mercato di terze parti (come AliExpress). Clicca`,
    subtitle_2: 'per ulteriori informazioni.',
    subtitle_3: ' qui ',
    title_tip: `Un'agenzia rappresenta un fornitore di prodotti che vi permette di completare gli ordini senza un marketplace di terze parti come AliExpress. In particolare, è possibile effettuare ordini direttamente alle agenzie, che provvederanno a spedire la merce ai tuoi clienti.`,
    name_tip: 'Il nome della tua agenzia, che verrà utilizzato e visualizzato quando si mappa un prodotto o si elabora un ordine.',
    erp_tip: `I rappresentanti dell'ERP, come Dianxiaomi, Mabang ed ECCANG, etc., offrono la gestione dell'inventario dei prodotti, il monitoraggio degli acquisti, la distribuzione logistica e il tracciamento dei pacchi.`,
    paypal_tip: `Il conto PayPal della tua agenzia. Dopo aver configurato questo conto PayPal, è possibile avviare i pagamenti direttamente all'Agenzia mentre i DSer elaborano gli ordini.`,
    phone_tip: 'Il numero di telefono della tua agenzia, che verrà utilizzato per spingere la tua agenzia a completare gli ordini via SMS.',
    name: "Nome dell'agenzia",
    nameErr: 'Il campo del nome non può restare vuoto.',
    phone: 'Numero di telefono',
    phoneErr: 'Possono essere digitate solo cifre.',
    erp: 'ERP in uso',
    erpPlaceholder: 'Seleziona ERP',
    erpErr: "Sei pregato di selezionare un'ERP.",
    paypal: 'Account PayPal',
    paymentMethod: 'Payment method',
    agencyContactEmail: 'Agency contact email',
    stripeAccount: 'Stripe account',
    agencyStripe: 'Agency Stripe',
    stripeNoAuth: 'No authorization, please contact the supplier for authorization.',
    agencyPaymentTip: `Your Agency's Payment methods. After setting up this payment method, you can initiate payments directly to the Agency while DSers process orders.`,
    agencyNoAuthModalTitle: 'Authorization not completed',
    agencyNoAuthModalContent: `Your supplier has not completed the Stripe account authorization, please contact your supplier to complete the Stripe account authorization. If you have more questions, please contact the DSers support team.`,
    agencyNoAuthModalGetSupport: 'GET SUPPORT',
    paypalPlaceholder: 'Account Agenzia PayPal',
    paypalErr: 'Il campo PayPal non può restare vuoto.',
    paypalEmailErr: "Inserisci un'e-mail PayPal corretta",
    delError: 'Il rivenditore è stato mappato e non può essere eliminato.',
    phonePlaceholder: 'Country',

    // setting > save text
    savetitle: "Aggiungi un'agenzia (fornitore individuale)",
    savetext:`Dopo aver creato le informazioni dell'agenzia, DSers invierà un'e-mail al tuo fornitore ({email}).Contatta il tuo fornitore, che eseguirà una configurazione semplice in modo che tu possa continuare a gestire la mappatura dei prodotti, l'adempimento automatico degli ordini e altre funzioni tramite DSers.`,
    deletetitle: "Eliminazione fallita.",
    deletetext: "Attualmente hai prodotti mappati su questo fornitore. Per evitare di influire sui prodotti e sugli ordini esistenti, cancellare la mappatura relativa a questo fornitore prima di riprovare.",
    // my product
    Agencies: 'Agenzie <i>({ae_num})</i>',
    filterByAgency: 'Filtra per agenzia',

    filterAgencyPlease: "Se non hai ancora aggiunto un'Agenzia, Vai in",
    filterAgencyAddInformation: 'per aggiungerla.',

    supplierManagement: 'Gestione rivenditore',
    marketplaces: 'Marketplace',
    agencyInformation: 'Informazioni Agenzia',
    selectAgency: 'Seleziona Agenzia',
    refreshPage: 'Aggiorna pagina',
    REFRESH: 'AGGIORNA',
    mappingUpdating: 'Aggiornamento della mappatura',
    markeCardDescription:
      'Puoi mappare i prodotti del fornitore per i prodotti del tuo negozio, e quando i prodotti in vendita vengono ordinati, puoi effettuare un ordine con il fornitore corrispondente con un solo clic su DSers.',
    markeCardDescriptionNoTianMao:
      'Con i marketplace, puoi selezionare i prodotti AliExpress per mappare i tuoi articoli di Shopify.',
    agencyCardDescription:
      "Agency rappresenta un singolo fornitore al di fuori dei marketplace (come AliExpress). Puoi mappare i prodotti del tuo negozio all'agenzia e ordinare direttamente dal loro ERP per l'adempimento automatico.",
    marketplaceExplain:
      'Mappatura ',
    marketplaceExplain1:
    'dei marketplace rivenditori per i tuoi prodotti Shopify. Le informazioni sul prodotto di Shopify (come titolo, descrizione, immagini, varianti, ecc.) rimangono le stesse anche dopo aver aggiunto un rivenditore.',
    agencyExplain:
      'Puoi scegliere il rivenditore che preferisci in base alle tue esigenze. Le informazioni sul prodotto di Shopify (come titolo, descrizione, immagini, varianti, ecc.) rimangono le stesse anche dopo aver aggiunto un rivenditore.',
    statusExplain: 'Attiva per utilizzare il metodo di mappatura Agenzie',
    selectAgentPrompt:
      "Seleziona un'Agenzia per usarla come mezzo di mappatura dei tuoi prodotti Shopify. Se non hai ancora aggiunto un'Agenzia, vai in ",
    toSetting: 'Impostazioni',
    addAgency: 'e aggiungila.',
    noSelectionPrompt:
      "Non hai ancora aggiunto un'Agenzia, vai in Impostazioni per aggiungerla.",
    noSelectionButton: 'VAI NELLE IMPOSTAZIONI PER AGGIUNGERE LE AGENZIE',
    refreshPagePrompt:
      "Aggiorna la pagina per verificare se l'Agenzia è stata aggiunta con successo.",
    backendProcessesPrompt:
      "Il metodo di mappatura del prodotto è stata modificata. Attendi mentre ti aiutiamo a sostituire la mappatura dell'ordine legata al prodotto.",
    TimeoutErr: 'Timeout richiesta, riprova più tardi.'
  }
};
