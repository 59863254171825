import React, { useEffect, useRef, useState } from 'react';

import classnames from 'classnames';

import intl from 'react-intl-universal';

import memoizeOne from 'memoize-one';

import {
  Drawer,
  Switch,
  Select,
  Button,
  Form,
  Collapse,
  Tooltip,
  Popover,
  Spin,
  Modal,
  message,
  Input,
  Table
} from 'antd';

import { connect } from 'dva';

import RefreshBounced from 'components/RefreshBounced';

import noticeIcon from 'assets/mapping/notice.png';

import CheckSaveModal from 'components/CheckSaveModal/CheckSaveModal';

import RefreshDataPrompt from '../../RefreshDataPrompt';

import reactGaEvent from '../../../utils/GAEvent';

import { hasDeleteVariant } from '../util';

import { debounce } from 'lodash';

import Status from '../Status';

import styles from './index.less';
import { Post } from 'utils/request';

const { Option } = Select;
const { Panel } = Collapse;

const BatchApplyOperation = React.memo(({ onApply }) => {
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const unmountedRef = useRef(false);
  useEffect(() => {
    return () => {
      unmountedRef.current = true;
    };
  }, []);
  const handleApply = async () => {
    if (loading) return;
    setLoading(true);

    const res = await Post('/merchant/agency', {
      data: { agency_id: parseInt(value) || 0 }
    });

    if (res.code === 2000 && res.data) {
      if (!unmountedRef.current) {
        onApply && onApply(value);
      }
    } else {
      setError(true);
    }

    setLoading(false);
  };
  return (
    <div className={styles.batchOperation}>
      <div className={styles.headline}>
        {/* {intl.get('setting.agency.selectAgency')} */}
        Enter Agency ID
      </div>
      <div className={styles.select}>
        <Input
          onChange={e => {
            setValue(e.target.value);
            setError(false);
          }}
          value={value}
          placeholder="Agency Id"
        />
        <div style={{ color: 'red' }}>{error ? 'Invalid Agency ID' : null}</div>
      </div>
      <div className={styles.button}>
        <Button type="primary" onClick={handleApply} disabled={!value}>
          APPLY ALL VARIANT
        </Button>
      </div>
    </div>
  );
});

@connect(({ login, global, loading }) => ({
  userInfo: login.userInfo,
  agencyManagementData: global.agencyManagementDataMerchant,
  saveButtonLoading: loading.effects['productmy/ext']
}))
@Form.create()
class Agency extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saveDisabled: true,
      isVisibleModal: false,
      isDeleteAll: false,
      visibleDeletedAllVariant: false,
      selectData: [],
      removedVariant: [],
      refreshBouncedVisible: false,
      needToRefreshData: false,
      switchoverMapping: false,
      popoverVisible: false,
      applyAllButtons: true,
      statusLoading: false,
      mappingLoading: false,
      applyInputValue: ''
    };
    this.save_map_type = 999;
    this.currentDeleteVariantId = '';
    this.batchSelectionOfContent = 0;
    this.hasSave = false;
    this.agencyVisible = false;

    this.delayBouncedStatus = true;
    this.delVariantId = [];

    this.platformType = {
      DIANXIAOMI: 6,
      MABANG: 7,
      ECCANG: 8,
      TONGTOOL: 9,
      LINGXING: 10,
      MANGO: 11,
      SUPERSELLER: 12,
      // 后端订单枚举的类型 前端不应以这个对齐
      // AGENCYOTHER: 13,
      OTHER: 13
    };

    this.validAgencyIdMap = {};
  }

  componentDidMount() {
    this.initialize();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    const { selectData } = this.state;

    if (!newProps.agencyVisible) {
      this.hasSave = false;
    }

    if (newProps.agencyVisible !== this.agencyVisible) {
      this.agencyVisible = newProps.agencyVisible;

      if (newProps.agencyVisible) {
        this.initialize();
      }
    }

    if (newProps.agencyManagementData.length !== selectData.length) {
      this.setState({
        selectData: newProps.agencyManagementData
      });

      newProps.agencyManagementData.forEach?.(i => {
        this.validAgencyIdMap[i.id] = i;
      });
    }
  }

  initialize = () => {
    const {
      form,
      originProductDataAgency,
      agencyManagementData,
      agencyVisible
    } = this.props;
    const { setFieldsValue } = form;
    const { prod } = originProductDataAgency;
    const {
      detail: { variants },
      ext: { agencies = {} }
    } = prod;
    // 展示被删除的variants
    const deleteVariants = hasDeleteVariant(originProductDataAgency);
    agencyManagementData.forEach?.(i => {
      this.validAgencyIdMap[i.id] = i;
    });
    this.setState(
      {
        removedVariant: deleteVariants,
        selectData: agencyManagementData,
        agencyVisible
      },
      () => {
        // 回显选中的mapping 数据 ant design table不知道有什么优化会延迟渲染，这里延迟设置form的值
        setTimeout(() => {
          const obj = {};

          let filterData = [];

          Object.keys(agencies)?.forEach(item => {
            variants?.forEach(ele => {
              if (agencies[item].variant_id === ele.id) {
                obj[
                  `${agencies[item].variant_id}^${agencies[
                    item
                  ].variant_title.replaceAll('.', '&#')}`
                ] = agencies[item].agency_id;
              }
            });

            // agencies mapping 中存在 variants里面没有就是被删除的mapping 取出来保存到filterData
            const id = agencies[item].variant_id;
            if (!variants.find(ele => ele.id === id)) {
              filterData.push(agencies[item]);
            }
          });

          // 为确保没有bug 这里对数组去重
          const res = new Map();
          filterData = filterData?.filter(
            item => !res.has(item.variant_id) && res.set(item.variant_id, 1)
          );

          // 生成被删除的variant 的对应关系
          filterData?.forEach(item => {
            obj[
              `delete^${item.variant_id}^${item.variant_title.replaceAll(
                '.',
                '&#'
              )}`
            ] = item.agency_id;
          });

          if (Object.keys(agencies)?.length) {
            setFieldsValue(obj);
          }
        }, 200);

        // 回显选中的mapping 数据 end
      }
    );
  };

  changeStatus = (v, save_map_type, t) => {
    this.save_map_type = save_map_type;
    this.setState({
      statusLoading: true
    });
    if (v) {
      reactGaEvent({
        category: 'Mapping',
        action: 'open advanced'
      });
    } else if (t == 1) {
      reactGaEvent({
        category: 'Mapping',
        action: 'close advanced'
      });
    }

    this.saveMapping();
  };

  createTableColumns = () => {
    const { popoverVisible } = this.state;
    const { form, originProductDataAgency } = this.props;
    const { getFieldDecorator } = form;
    const { orderVaraintId, prod } = originProductDataAgency;
    const {
      ext: { agencies = {} }
    } = prod;
    // console.log(agencies);
    return [
      {
        title: <div className={styles.left}>{intl.get('mapping.variant')}</div>,
        key: 'variantTitle',
        dataIndex: 'title',
        width: 345,
        render: (_, item) => {
          return (
            <div
              className={classnames(styles.left, {
                [styles.border]: orderVaraintId === item.id
              })}
            >
              {item.title}
            </div>
          );
        }
      },
      {
        title: (
          <Popover
            placement="bottom"
            content={<BatchApplyOperation onApply={this.useBatchSelection} />}
            trigger="click"
            destroyTooltipOnHide
            visible={popoverVisible}
            onVisibleChange={this.setPopoverVisible}
            getPopupContainer={triggerNode => triggerNode.parentNode}
          >
            {/* {intl.get('setting.agency.name')}{' '} */}
            <div
              style={{ display: 'flex', alignItems: 'center', minHeight: 48 }}
            >
              Agency Id <i class="material-icons">arrow_drop_down</i>
            </div>
          </Popover>
        ),
        key: 'agencyId',
        width: 240,
        render: (_, item) => {
          return (
            <Form.Item>
              {getFieldDecorator(
                `${item.id}^${item.title.replaceAll('.', '&#')}`,
                {
                  rules: [
                    {
                      validator: async (rules, value, callback) => {
                        // console.log(this.state.validAgencyIdMap, value);
                        if (this.validAgencyIdMap?.[value] || !value) {
                          callback();
                        } else {
                          callback(Promise.reject());
                        }
                      },
                      message: 'Invalid Agency Id'
                    }
                  ],
                  validateTrigger: 'off'
                }
              )(<Input placeholder="Agency Id" onChange={this.changeInput} />)}
            </Form.Item>
          );
        }
      },
      {
        title: 'Agency Name',
        key: 'agencyName',
        render: (_, item) => {
          const currentAgencyId = agencies?.[item?.id]?.agency_id;
          const inputAgencyId = form.getFieldValue(
            `${item.id}^${item.title.replaceAll('.', '&#')}`
          );
          const isChanged = currentAgencyId == inputAgencyId;
          return isChanged
            ? this.validAgencyIdMap[currentAgencyId]?.agency_name || ''
            : '';
        }
      }
    ];
  };

  createDeletedTableColumns = () => {
    const { form, originProductDataAgency } = this.props;
    const { getFieldDecorator } = form;
    const { orderVaraintId, prod } = originProductDataAgency;
    const {
      ext: { agencies = {} }
    } = prod;
    return [
      {
        title: null,
        key: 'variantTitle',
        dataIndex: 'title',
        width: 345,
        render: (_, item) => {
          return (
            <div
              className={classnames(styles.left, {
                [styles.border]: orderVaraintId === item.id
              })}
            >
              {item.title}
              <div>
                <span className={styles.deletedIcon}>Deleted</span>
                <i
                  class="material-icons"
                  onClick={() => {
                    this.currentDeleteVariantId = item.id;
                    this.setState({
                      isDeleteAll: false,
                      visibleDeletedAllVariant: true
                    });
                  }}
                >
                  delete
                </i>
              </div>
            </div>
          );
        }
      },
      {
        title: null,
        key: 'agencyId',
        width: 240,
        render: (_, item) => {
          return (
            <Form.Item>
              {getFieldDecorator(
                `delete^${item.id}^${item.title.replaceAll('.', '&#')}`,
                {
                  rules: [
                    {
                      validator: async (rules, value, callback) => {
                        if (this.validAgencyIdMap?.[value] || !value) {
                          callback();
                        } else {
                          callback(Promise.reject());
                        }
                      },
                      message: 'Invalid Agency Id, please '
                    }
                  ],
                  validateTrigger: 'off'
                }
              )(<Input placeholder="Agency Id" onChange={this.changeInput} />)}
            </Form.Item>
          );
        }
      },
      {
        title: 'Agency Name',
        key: 'agencyName',
        render: (_, item) => {
          const currentAgencyId = agencies?.[item?.id]?.agency_id;
          const inputAgencyId = form.getFieldValue(
            `${item.id}^${item.title.replaceAll('.', '&#')}`
          );
          const isChanged = currentAgencyId == inputAgencyId;
          return isChanged
            ? this.validAgencyIdMap[currentAgencyId]?.agency_name || ''
            : '';
        }
      }
    ];
  };

  // saveAndNextHandler
  saveMapping = async () => {
    const { form, agencyManagementData } = this.props;
    const { mappingLoading } = this.state;
    const { validAgencyIdMap } = this;
    if (mappingLoading) return;
    this.setState({ mappingLoading: true });

    const values = await form.getFieldsValue();
    // console.log(values);
    const needValidIds = [];
    Object.keys(values).forEach(item => {
      const data = item.split('^');

      // 正常mapping 生成后端需要的数据
      if (
        data.length === 2 &&
        values[item] &&
        !validAgencyIdMap[values[item]]
      ) {
        needValidIds.push(values[item]);
      }
    });

    await Promise.allSettled(
      Array.from(new Set(needValidIds)).map(i => {
        return Post('/merchant/agency', {
          data: { agency_id: parseInt(i) || 0 }
        }).then(res => {
          validAgencyIdMap[i] = res.code === 2000 && !!res.data;
        });
      })
    );

    // this.setState({ mappingLoading: false });
    // return;

    form.validateFields((err, values) => {
      if (err) {
        this.setState({ mappingLoading: false });
        return;
      }

      const { removedVariant } = this.state;
      const {
        originProductDataAgency,
        setOriginProductData,
        saveDisabled
      } = this.props;
      const { prod } = originProductDataAgency;
      const { ext } = prod;
      const { del = [] } = ext;

      let allExts = { ...ext };

      allExts.agencies = {};

      if (allExts?.standard) {
        Object.keys(allExts.standard).forEach(i => {
          if (this.delVariantId.map(i => (i = i + ''))?.includes(`${i}`)) {
            delete allExts.standard[i];
          }
        });
      }
      if (allExts?.basic) {
        allExts?.product_pool?.forEach(i => {
          allExts.basic[i?.product_id]?.supply?.forEach((item, index) => {
            if (
              this.delVariantId
                .map(it => (it = it + ''))
                ?.includes(`${item?.variant_id}`)
            ) {
              delete allExts.basic[i?.product_id].supply[index];
            }
          });
        });
      }

      Object.keys(values).forEach(item => {
        const data = item.split('^');

        // 正常mapping 生成后端需要的数据
        if (data.length === 2 && values[item]) {
          // let platformType = '';
          // agencyManagementData.find(element => {
          //   if (element.id === values[item]) {
          //     platformType = element;
          //   }
          // });

          let obj = {
            agency_id: Number(values[item]),
            variant_id: Number(data[0]),
            variant_title: data[1].replaceAll('&#', '.'),
            platform_type: 5
          };
          allExts.agencies[data[0]] = obj;
        }

        // 将原来的del 删除   del保存的是被删除的variant的Marketplaces mapping关系
        allExts.del = [];

        // 被删除的variant mapping
        // let filterData = [];
        if (data.length === 3) {
          removedVariant?.map((ele, index) => {
            // 将用户操作后的 del里面本来有的mapping保存到del中
            del?.forEach(element => {
              if (ele.id === element.variant_id) {
                allExts.del.push(element);
              }
            });

            // 这里将del里面没有的保存到 filterData 中
            // if (!del?.find(item => item.variant_id === ele.id)) {
            //   filterData.push(ele);
            // }
          });

          // 对filterData数组去重
          // const res = new Map();
          // filterData = filterData?.filter(
          //   item => !res.has(item.id) && res.set(item.id, 1)
          // );

          // 将新生成的被删除的variant保存到del中  这里给Marketplaces mapping类型使用
          // filterData.forEach(ele => {
          //   allExts.del.push({
          //     is_base: true,
          //     platform_type: 1,
          //     quantity: 1,
          //     variant_id: ele.id,
          //     variant_title: ele.title
          //   });
          // });

          //被删除的variant mapping  生成后端需要的数据
          if (values[item]) {
            // let platformType = '';
            // agencyManagementData?.find(element => {
            //   if (element.id === values[item]) {
            //     platformType = element;
            //   }
            // });
            let obj = {
              agency_id: Number(values[item]),
              variant_id: Number(data[1]),
              variant_title: data[2].replaceAll('&#', '.'),
              platform_type: 5
            };
            allExts.agencies[data[1]] = obj;
          }
        }
      });

      this.delayBouncedStatus = true;
      let delayBounced = null;
      if (this.save_map_type !== 999) {
        allExts.map_type = this.save_map_type;
        if (
          (originProductDataAgency.prod.ext.map_type !== 6 &&
            this.save_map_type === 6) ||
          (originProductDataAgency.prod.ext.map_type === 6 &&
            ![0, 6].includes(this.save_map_type))
        ) {
          delayBounced = setTimeout(() => {
            if (this.delayBouncedStatus) {
              this.setState({
                switchoverMapping: true
              });
            }

            clearTimeout(delayBounced);
            delayBounced = null;
          }, 500);
        }
      } else if (originProductDataAgency.prod.ext.map_type === 6) {
        // 暂时就不需要
        // let delayBounced = setTimeout(() => {
        //   if (this.delayBouncedStatus) {
        //     this.setState({
        //       needToRefreshData: true
        //     });
        //   }
        //   clearTimeout(delayBounced);
        //   delayBounced = null;
        //   this.delayBouncedStatus = true;
        // }, 500);
      }

      this.setState({
        mappingLoading: true
      });

      this.props.dispatch({
        type: 'productmy/ext',
        payload: {
          data: {
            product_id: originProductDataAgency.prod.id,
            ext: allExts,
            store_id:
              originProductDataAgency.prod.storeId ||
              originProductDataAgency.prod.store_id,
            ref: localStorage.getItem('mapping_dot')
          },
          callback: data => {
            if (data.code === 2010) {
              message.success('Succeed');

              const obj = JSON.parse(JSON.stringify(originProductDataAgency));
              obj.prod.ext = allExts;
              setOriginProductData(obj);
              this.setState({
                isVisibleModal: false,
                saveDisabled: true
              });
            }

            const { switchoverMapping, needToRefreshData } = this.state;
            clearTimeout(delayBounced);
            this.setState({
              switchoverMapping: false,
              needToRefreshData: false,
              statusLoading: false,
              mappingLoading: false
            });

            // if (switchoverMapping || needToRefreshData) {
            //   this.setState({
            //     switchoverMapping: false,
            //     needToRefreshData: false,
            //     statusLoading: false,
            //     mappingLoading: false
            //   });
            // } else {
            //   this.setState({
            //     statusLoading: false,
            //     mappingLoading: false,
            //   });
            // }

            this.props.dispatch({ type: 'global/getAgencyListFromMerchant' });

            this.delayBouncedStatus = false;

            this.hasSave = true;
          }
        }
      });
    });
  };

  changeInput = () => {
    this.setState({
      saveDisabled: false
    });
  };

  closeDrawer = debounce(
    () => {
      const { saveDisabled, isVisibleModal } = this.state;

      if (!saveDisabled) {
        this.setState({
          isVisibleModal: true
        });
      } else {
        const { agencyHideSupplier } = this.props;
        agencyHideSupplier(this.hasSave);
      }
    },
    1000,
    { leading: true, trailing: false }
  );

  onlycloseCkeckModalHanlder = () => {
    this.setState({
      isVisibleModal: false
    });
  };

  closeCkeckModalHanlder = debounce(
    () => {
      this.setState(
        {
          isVisibleModal: false,
          saveDisabled: true
        },
        () => {
          const { agencyHideSupplier } = this.props;
          agencyHideSupplier(this.hasSave);
        }
      );
    },
    1000,
    { leading: true, trailing: false }
  );

  deleteAllDelVariant = e => {
    const { removedVariant } = this.state;
    const del = removedVariant.map(item => item.id);
    this.delVariantId = del;
    this.setState({
      removedVariant: [],
      visibleDeletedAllVariant: false,
      isDeleteAll: false,
      saveDisabled: false
    });
  };

  deleteVariantTrue = () => {
    const { removedVariant } = this.state;
    const del = removedVariant.filter(
      item => item.id != this.currentDeleteVariantId
    );
    this.delVariantId = [...this.delVariantId, this.currentDeleteVariantId];
    this.setState({
      removedVariant: [...del],
      isDeleteAll: false,
      visibleDeletedAllVariant: false,
      saveDisabled: false
    });
  };

  batchSelectionOfContentFunc = e => {
    this.setState({
      applyAllButtons: false
    });
    this.batchSelectionOfContent = e;
  };

  useBatchSelection = (batchValue = '') => {
    const { form, originProductDataAgency } = this.props;
    const { setFieldsValue } = form;
    const { prod } = originProductDataAgency;
    const {
      detail: { variants }
    } = prod;

    let obj = {};

    variants.forEach(item => {
      obj[`${item.id}^${item.title.replaceAll('.', '&#')}`] = Number(
        batchValue
      );
    });

    setFieldsValue(obj);
    this.changeInput();
    this.setPopoverVisible(false);
  };

  refreshBounced = () => {
    this.setState({
      refreshBouncedVisible: true
    });
  };

  handleRefreshBounced = () => {
    this.setState({
      refreshBouncedVisible: false
    });
  };

  getNewAgencyManagementData = () => {
    const { dispatch } = this.props;
    dispatch({ type: 'global/getAgencyListFromMerchant' });
    this.handleRefreshBounced();
  };

  setPopoverVisible = val => {
    if (!val) {
      this.setState({
        applyInputValue: ''
      });
      this.batchSelectionOfContent = '';
    }

    this.setState({
      popoverVisible: val
    });
  };

  render() {
    const {
      saveDisabled,
      isVisibleModal,
      visibleDeletedAllVariant,
      selectData,
      removedVariant,
      refreshBouncedVisible,
      switchoverMapping,
      needToRefreshData,
      popoverVisible,
      statusLoading,
      mappingLoading
    } = this.state;

    const {
      agencyVisible,
      originProductDataAgency,
      form,
      saveButtonLoading,
      copyLink
    } = this.props;

    const { image, title, prod, orderVaraintId } = originProductDataAgency;

    const {
      detail: { variants, id },
      ext: { map_type, del = [] }
    } = prod;

    const { getFieldDecorator } = form;
    const columns = this.createTableColumns();
    const deletedColumns = this.createDeletedTableColumns();
    const batchOperation = (
      <div className={styles.batchOperation}>
        <div className={styles.headline}>
          {intl.get('setting.agency.selectAgency')}
        </div>
        <div className={styles.select}>
          {/* <Select
            placeholder="Select Agency"
            onChange={this.batchSelectionOfContentFunc}
            allowClear
            getPopupContainer={triggerNode => triggerNode.parentNode}
          >
            {selectData.map(item => (
              <Option value={item.id} key={item.id}>
                {item.agency_name}
              </Option>
            ))}
          </Select> */}
          <Input
            onChange={e => this.setState({ applyInputValue: e.target.value })}
            value={this.state.applyInputValue}
            placeholder="Agency Id"
          />
        </div>
        <div className={styles.button}>
          <Button
            type="primary"
            onClick={this.useBatchSelection}
            disabled={!this.state.applyInputValue}
          >
            APPLY ALL VARIANT
          </Button>
        </div>
      </div>
    );

    return (
      <Drawer
        visible={agencyVisible}
        maskClosable
        width={868}
        onClose={!mappingLoading && this.closeDrawer}
        className={styles.content}
        destroyOnClose
      >
        <Spin
          size="large"
          spinning={mappingLoading}
          className={styles.location}
        >
          <div className={styles.drawerHeader} id="agencySwitchStatus">
            <div className={styles.title}>
              {intl.get('setting.agency.Agency')}
            </div>
            <div className={styles.subtitle}>
              {intl.get('setting.agency.agencyExplain')}
            </div>
          </div>

          <Status
            Mapping
            self_type={6}
            map_type={map_type}
            changeStatus={this.changeStatus}
            switchoverMapping={switchoverMapping}
            switchoverMappingId="agencySwitchStatus"
            isFBD={false}
            btnDispath={false}
            loading={statusLoading}
          />

          <div className={styles.workingArea}>
            <div className={styles.header}>
              <div className={styles.left}>
                {intl.get('mapping.standard.product_name')}
              </div>
              <div className={styles.right}>
                {intl.get('setting.agency.agencyInformation')}
              </div>
            </div>
            <div className={styles.title}>
              <div className={styles.left}>
                <div className={styles.img}>
                  <img src={image} alt="commodity images" />
                </div>
                <div
                  className={styles.text}
                  onClick={() => copyLink(originProductDataAgency)}
                >
                  {title}
                </div>
              </div>
              <div className={styles.right}>
                {/* {intl.get('setting.agency.selectAgentPrompt')} */}
                {/* <a
                  href="/app/settings?id=14"
                  target="_blank"
                  onClick={this.refreshBounced}
                >
                  &nbsp;{intl.get('setting.agency.toSetting')}&nbsp;
                </a>
                {intl.get('setting.agency.addAgency')} */}
                Select an Agency to map your Shopify products with. Enter the
                Agency ID provided by your agency to map your products.
              </div>
            </div>
            <Form>
              <div className={styles.body}>
                <Table
                  rowKey={record => `${record.id}`}
                  columns={columns}
                  dataSource={variants}
                  pagination={false}
                  bordered={false}
                  className={classnames(styles.contentTable)}
                />
              </div>

              {/* removedVariant */}
              {removedVariant.length ? (
                <div style={{ marginTop: 8 }}>
                  <div
                    className={styles.deletedVariantCon}
                    style={{
                      width: '100%'
                    }}
                  >
                    {!selectData.length ? (
                      <div className={styles.shade} />
                    ) : null}

                    <Collapse
                      defaultActiveKey={removedVariant.length > 4 ? [] : [1]}
                      bordered={false}
                      expandIconPosition={'left'}
                    >
                      <Panel
                        header={
                          <div className={styles.description}>
                            <span>Variants deleted at Shopify</span>
                            <Tooltip
                              title={intl.getHTML(
                                'mapping.delete_variant_collapse_tip'
                              )}
                            >
                              <img src={noticeIcon} alt="" />
                            </Tooltip>
                          </div>
                        }
                        key="1"
                        extra={
                          <i
                            class="material-icons"
                            onClick={e => {
                              e.stopPropagation();
                              this.setState({
                                isDeleteAll: true,
                                visibleDeletedAllVariant: true
                              });
                            }}
                          >
                            delete
                          </i>
                        }
                      >
                        <div className={styles.select}>
                          <Table
                            className={classnames(
                              styles.contentTable,
                              styles.deletedTable
                            )}
                            bordered={false}
                            columns={deletedColumns}
                            dataSource={removedVariant}
                            pagination={false}
                          />
                        </div>
                      </Panel>
                    </Collapse>
                  </div>
                </div>
              ) : null}
            </Form>
            {/*  */}
          </div>

          <CheckSaveModal
            type="notSave"
            saveAndNextHandler={this.saveMapping}
            closeCkeckModalHanlder={this.closeCkeckModalHanlder}
            onlycloseCkeckModalHanlder={this.onlycloseCkeckModalHanlder}
            visible={isVisibleModal}
          />

          <Modal
            visible={visibleDeletedAllVariant}
            width={560}
            title={intl.getHTML('mapping.confirm_delete_all_variant_title')}
            className={styles.deleteTipModal}
            onCancel={() => {
              this.setState({
                visibleDeletedAllVariant: false
              });
            }}
            footer={
              <>
                <Button
                  className={styles.btn}
                  onClick={
                    this.state.isDeleteAll
                      ? this.deleteAllDelVariant
                      : this.deleteVariantTrue
                  }
                >
                  {intl.getHTML('public.newconfirm')}
                </Button>
              </>
            }
          >
            <div>{intl.getHTML('mapping.confirm_delete_all_variant')}</div>
          </Modal>

          <RefreshBounced
            visible={refreshBouncedVisible}
            handleRefresh={this.handleRefreshBounced}
            buttonEvent={this.getNewAgencyManagementData}
          />
        </Spin>
        <div
          className={styles.saveBtn}
          style={{
            position: 'absolute',
            right: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
            zIndex: 20
          }}
          id="agencySaveButton"
        >
          <RefreshDataPrompt
            placement="topRight"
            visible={needToRefreshData}
            id="agencySaveButton"
          >
            <Button
              type="primary"
              onClick={this.saveMapping}
              disabled={saveDisabled || saveButtonLoading}
              loading={saveButtonLoading || mappingLoading}
            >
              SAVE
            </Button>
          </RefreshDataPrompt>
        </div>
      </Drawer>
    );
  }
}

export default Agency;
