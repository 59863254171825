import React, { useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import { Button } from 'antd';

import styles from './NewListBanner.less';
import useTopBanner from '../../hooks/useTopBanner';
import bg from '../../assets/banner/bg_new.png';
import { newBannerList } from '../../utils/bannerWhiteList';

const allowPath = [
  '/home',
  '/report',
  '/order/list',
  '/storemanage/import',
  '/storemanage/myproduct'
];

const NewListBanner = ({ path, userId }) => {
  const [visible] = useTopBanner(path, allowPath);
  const isNewBannerUser = newBannerList.includes(userId);

  if (!visible || !isNewBannerUser) {
    return null;
  }
  return (
    <div className={`${styles.container}`}>
      <div className={styles.banner}>
        <div className={styles.content}>
          <div className={styles.text1}>
            {intl.get('bargain.one_click.v5.banner_title')}
          </div>
          <Button
            type="primary"
            className={styles.learnButton}
            onClick={() => {
              window.open('https://blog.dsers.com/blog/ecommerce-statistics-dropshipping/');
            }}
          >
            {intl.getHTML('banner.newUI.learn')}
          </Button>
        </div>
        <div className={styles.bannerBg}>
          <a
            href="https://blog.dsers.com/blog/ecommerce-statistics-dropshipping/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={bg} className={styles.bg1} />
          </a>
        </div>
      </div>
    </div>
  );
};

NewListBanner.allowPath = allowPath;

export default NewListBanner;
