import React, { useEffect,useState} from 'react'
import { connect,useDispatch } from 'dva';
import { Tooltip, Icon } from 'antd'

import { Get,Post } from 'utils/request';
import styles from './OberloDataStatus.less'

import syncproducts from 'assets/home/syncorder.png'
import closeicon from 'assets/close.png'
import successicon from 'assets/successicon.png'
import failicon from 'assets/failicon.png'

import intl from 'react-intl-universal';

const OberloDataStatus = ({plan_info,dispatch,path,isChildAcount,userInfo,lang,isBindAe,oberlouser,isoberlouser,OstoreInfo})=> {

  let interval_2 = null
  let interval_1 = null
  const [closeTip,setCloseTip] = useState(true);
  const [errorTip,setErrorTip] = useState('')

  const [nowTime, setNowTime] = useState(parseInt(new Date().getTime() / 1000));
  const [overTime, setOverTime] = useState(Number(OstoreInfo?.last_pre_complete_timestamp) +300);
  const [migrateProductOver, setMigrateProductOver] = useState(false);

  useEffect(() => {
    setCloseTip(!!JSON.parse(localStorage.getItem('close_tip')) || !!JSON.parse(localStorage.getItem('migrate_success')))     
    getMigraTionProcess()
    
    return function () {
        clearInterval(interval_2)
    }
  }, [OstoreInfo])

  useEffect(()=>{
    getMigraTionProductProcess();

    return function() {
      clearInterval(interval_1);
    };
  },[oberlouser,nowTime,oberlouser])

  const getMigraTionProductProcess = () => {
    // console.log(overTime - nowTime,'pathpathpathpathpathpathpath');
    if (overTime - nowTime > -1) {
      interval_1 = setInterval(() => {
        clearInterval(interval_1)
        setNowTime(nowTime + 1);
        if (overTime - nowTime <= 0) {
          setMigrateProductOver(true);
        } else  {
          setMigrateProductOver(false);
        }
      }, 1000);
    }else{
      setMigrateProductOver(true);
    }
  };

  const getMigraTionProcess = () => {
    if(oberlouser.migrating_shopify_domain || (OstoreInfo?.code == 2000 && !!OstoreInfo?.msg.match(/proxy failed/ig)?.[0])){
      interval_2 = setInterval(async () => {
        clearInterval(interval_2)
        dispatch({
          type: 'auth/migrationInfo'
        })
        dispatch({
          type:'auth/getMigrationStoreInfo',
        });
        if(isoberlouser){
          const migrationOverCheckAe = OstoreInfo?.store_stages[OstoreInfo?.store_stages.length - 1]?.step_stage == "STEP_STAGE_COMPLETE"
          const aeSpecial = OstoreInfo?.store_stages[OstoreInfo?.store_stages.length - 1]?.tips?.includes('TIP_REASON_ALIEXPRESS_UNAUTHORIZED')
          if((migrationOverCheckAe || aeSpecial) && !isBindAe){
            const checkAeRes = await Get('/auth/ali/status', {})
            if(checkAeRes?.data && checkAeRes.data.status !== 1){
              window.location.href = '/app/bind/ae'
            }
          }
        }
      }, 20000);
    }else{
      if(isoberlouser){
        dispatch({ type: 'global/AEInfo' });
        setTimeout(async ()=>{
          const migrationOverCheckAe = OstoreInfo?.store_stages[OstoreInfo?.store_stages.length - 1]?.step_stage == "STEP_STAGE_COMPLETE"
          const aeSpecial = OstoreInfo?.store_stages[OstoreInfo?.store_stages.length - 1]?.tips?.includes('TIP_REASON_ALIEXPRESS_UNAUTHORIZED')
          if((migrationOverCheckAe || aeSpecial) && !isBindAe){
            const checkAeRes = await Get('/auth/ali/status', {})
            if(checkAeRes?.data && checkAeRes.data.status !== 1){
              window.location.href = '/app/bind/ae'
            }
          }
        },1000)
      }
    }
  }

  const closeTiphandler = ()=>{
    setCloseTip(true)
    localStorage.setItem('close_tip',true)
    if(!oberlouser.migrating_shopify_domain){
      localStorage.setItem('migrate_success',true)
    }
  }

  const reTryHandler = async()=>{
    const res = await Get('/mgnoberlo-core-api/dsers-api/migration/user/status',[],true);
      if (res?.err_code == 'SHOPIFY_UNAUTHORIZED') {
        const response = await Post("/mgnoberlo-core-api/dsers-api/migration/auth/shopify/bind", {
          data: {
              shop_name: res?.migrating_shopify_domain.split('.myshopify.com')[0],
              store_id: res?.dsers_store_id
          }
        }, true)
        window.location.href = response.redirect_url;
      }else{
        if(res.migrating_shopify_domain){
          await Post('/mgnoberlo-core-api/dsers-api/migration/start',{
            data:{
              shopify_domain:res.migrating_shopify_domain
            }
          },true)
          dispatch({type: 'auth/migrationInfo'})
        }
      }
  }

  const callUsHandler = ()=>{
    window.open('https://www.messenger.com/t/DSersOfficial')
  }

  const showTipHandler = ()=>{
    const setMigrationInfo = localStorage.getItem('setMigrationInfo')
      if(!['STEP_STAGE_PRE','STEP_STAGE_QUEUE','STEP_STAGE_STEP1'].includes(setMigrationInfo)){
        if((isoberlouser && oberlouser.status != 'MIGRATION_STATUS_STOP' && oberlouser.is_migrating) || !migrateProductOver || (OstoreInfo?.code == 2000 && !!OstoreInfo?.msg.match(/proxy failed/ig)?.[0])){
          return <div style={{display:'none'}} className={styles.tippendingcontainer}>
          <img className={styles.loadings} src={syncproducts} alt="" />
          <span> {intl.getHTML('public.oberlo_data_pending')} </span>
          <Tooltip title={intl.get('public.padding_title')}>
            <span >
            <Icon className={styles.searchIcon} type="question-circle" />
            </span>
          </Tooltip>
          <img className={styles.close} onClick={closeTiphandler} src={closeicon} alt="" />
        </div>
        }else if(isoberlouser && oberlouser.status == 'MIGRATION_STATUS_STOP'){
          return <div style={{display:'none'}} className={styles.tipfailcontainer}>
          <img className={styles.loading} src={failicon} alt="" />
          <span> {intl.getHTML('public.oberlo_data_fail')} <i className={styles.try} onClick={reTryHandler}>{intl.getHTML('public.oberlo_data_try')}</i><i className={styles.try1} onClick={callUsHandler}>CONTACT SUPPORT</i> </span>
          <img className={styles.close} onClick={closeTiphandler} src={closeicon} alt="" />
        </div>
        }else if ( isoberlouser && !oberlouser.is_migrating && migrateProductOver){
          return <div style={{display:'none'}} className={styles.tipsuccesscontainer}>          
          <img className={styles.loading} src={successicon} alt="" />
          <span> {intl.getHTML('public.oberlo_data_success')} </span>
          <img className={styles.close} onClick={closeTiphandler} src={closeicon} alt="" />
        </div>
        }else {
          return null
        }
      }else{
        return null
      }
  }

  const getIsShowStep =()=>{
    if(migrateProductOver && !isBindAe){
      return false
    }else {
      if((isoberlouser || (OstoreInfo?.code == 2000 && !!OstoreInfo?.msg.match(/proxy failed/ig)?.[0])) && !closeTip){
        return true
      }else{
        return false
      }
    }
  }
  //!window.location.pathname?.match(/home/ig)?.[0] &&
  return (
    <>
      {getIsShowStep() ?
      showTipHandler()
       :null}
    </>
  )
}

export default connect(({ login, global,user,auth,topBtn }) => ({
  plan_info: user.plan,
  isChildAcount: user.newUserInfo.type, //判断是否是子账户
  userInfo: login.userInfo,
  lang: global.lang,
  isBindAe:global.isBindAe,
  oberlouser:auth?.migrationInfo,
  isoberlouser:auth?.isOberloUser,
  OstoreInfo:auth?.OstoreInfo
}))(React.memo(OberloDataStatus));