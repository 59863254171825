import React, { useState, useEffect, useRef, useCallback } from 'react';
import { message, Spin } from 'antd';
import { connect } from 'dva';
import { routerRedux } from 'dva/router';

import styles from './index.less';
import Point from './component/Point';
import BotMessage from './component/BotMessage';
import UserMessage from './component/UserMessage';
// import Loading from './component/Loading';

const BeginnerGuide = ({ dispatch, userInfo }) => {

  const importText = "We are ready to start the new user's guide. The system has detected that you are a new to Dropshipping. We suggest you start learning how to import products";
  const orderText = "We are ready to start the new user's guide. The system has detected that there are pending orders in your Shopify store. We recommend that you start learning the order processing" 
  const [status, setStatus] = useState('await');
  const timer = useRef(null);

  // 控制推荐颜色
  const [guideOrder, setGuideOrder] = useState(false);
  const [guideProduct, setGuideProduct] = useState(false);
  const [guideMainText, setGuideMainText] = useState(importText);
  useEffect(() => {
    window.DS_BEGINNER_GUIDE_ACTIVE = true; // 不用放 redux 中

    //删除选中店铺缓存
    localStorage.removeItem('pushCheckStoreId');

    timer.current = setTimeout(() => {
      setStatus('start');
    }, 3000);

    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  useEffect(() => {
    // "tutorial_type": 0  # 0: 无awaiting order; 1: 有awaiting order
    if (userInfo.tutorial_type === 0) {
      setGuideOrder(false);
      setGuideProduct(true);
      setGuideMainText(importText);
    }

    if (userInfo.tutorial_type === 1) {
      setGuideOrder(true);
      setGuideProduct(false);
      setGuideMainText(orderText);
    }
  }, [])

  const handleStart = useCallback((guideStatus) => {

    // 区分进入新手引导的import和order
    localStorage.setItem('un_scv_user_guide', guideStatus)

    window.dataLayer.push({
      category: 'tutorial',
      action: 'Start the tutorial'
    });
    // 默认走无商品无AO订单的引导流程
    let path = '/find_suppliers';

    dispatch({ type: 'global/updateGuideStatus', payload: true });
    dispatch(routerRedux.push(path));
  }, [dispatch, userInfo]);

  const handleQuit = () => {
    localStorage.setItem('un_scv_user_guide', false);
    dispatch({
      type: 'guidance/postGuidance',
      payload: {
        data: {
          status: 11000000
        },
        callback: (d) => {
          if(d.code === 2010){
            location.href = '/app/find_suppliers';
          }else{
            message.error(d.msg);
          }
        }
      }
    });
  }

  return (
    <div className={styles.guide}>
      <main>
        {status === 'await' ? (
          <div className={styles.awaitCon}>
            <BotMessage showAvatar>
              <p>
                Hello! We are about to start a journey to &nbsp;
                <b>help you place your first order to AliExpress.</b>
              </p>
              <div>
                We are searching for the best  <b>guide</b>  for you.
                Please kindly wait for a few seconds…
              </div>
            </BotMessage>
            <BotMessage >
              <div className={styles.loadingBox}>
                {/* <Loading className={styles.loading} /> */}
                <Spin />
              </div>
            </BotMessage>
          </div>
        ) : null}
        {status === 'start' ? (
          <div className={styles.awaitCon}>
            <BotMessage showAvatar>
              {guideMainText}
            </BotMessage>
            <UserMessage
              handleStart={() => handleStart('order')}
              guideColor={guideOrder}
            >
              <span
                // onClick={() => handleStart('order')}
                data-category="tutorial"
                data-action="Start the tutorial"
                role="button"
                tabIndex="-1"
              >
                Let's start the order processing
              </span>
            </UserMessage>
            <UserMessage
              handleStart={() => handleStart('import')}
              guideColor={guideProduct}
            >
              <span
                data-category="tutorial"
                data-action="Start the tutorial"
                role="button"
                tabIndex="-1"
              >
                Let's start importing products
              </span>
            </UserMessage>
            <UserMessage
              handleStart={handleQuit}
            >
              <span
                data-category="tutorial"
                data-action="Start the tutorial"
                role="button"
                tabIndex="-1"
              >
                I will explore freely and learn later
              </span>
            </UserMessage>
          </div>
        ) : null}
      </main>
    </div >
  );
};

export default connect(({ login }) => ({
  userInfo: login.userInfo
}))(BeginnerGuide);
