
    export default 
    {
    "register": {
        "title": "Crea un account Dsers",
        "email": "E-mail",
        "password": "Password",
        "get_started": "CREA UN ACCOUNT",
        "input_your_email": "Inserire un indirizzo e-mail nel formato corretto",
        "input_password": "Si prega di inserire da 6 a 20 caratteri.",
        "wrong_email_format": "Formato email errato",
        "password_tip": "Si prega di usare una password con almeno 6 caratteri. Non usare password che possano essere hackerate facilmente.",
        "contact_us": "Contattaci. ",
        "hello_shopify": "Ciao, Shopify",
        "install_dsers_to_shopify": "Installa DSers su Shopify",
        "password_ok": "Livello di sicurezza : Alto",
        "password_warning": "Livello di sicurezza : Medio",
        "password_error": "Livello di sicurezza : Basso",
        "privacy": "Cliccando su Crea un account, acconsenti alla nostra <a href=\"/privacy\" target=\"_blank\">INFORMATIVA SULLA PRIVACY</a>",
        "result": {
            "resend_successed": "Riinvio eseguito",
            "resend_failed": "Riinvio fallito",
            "title": "Processa ordini multipli e all'ingrosso con DSers",
            "your_account": "Il tuo account:",
            "register_succ": "è stato registrato con successo",
            "activation_email": "Abbiamo inviato un'email di attivazione al tuo indirizzo email. Si prega di <br /> controllare la tua inbox e cliccare <br /> il link per attivare il tuo account in 10 minuti.",
            "check_email": "Controlla l'email",
            "resend_email": "Riinvia l'email",
            "go_sign": "Vai a Sign Up o Sign In",
            "about_email": "Non ti è arrivata nessuna email? Clicca <a href=\"https://help.dsers.com/confirmation-email-not-received-for-shopify/\" target=\"_blank\">qui</a> per ottenere spiegazioni più specifiche!"
        },
        "password_error_long": "La password deve contenere 8-16 caratteri",
        "password_error_format": "La password deve contenere almeno 1 lettera maiuscola, 1 lettera minuscola e 1 numero",
        "password_not_match_twice": "Le due password non corrispondono",
        "accountSuccess": "Finish registration",
        "haveAccount": "Hai già un account?",
        "signIn": "ACCEDI"
    }
}