
    export default 
    {
    "reset_password": {
        "title": "Passwort zurücksetzen",
        "input_old_password": "Bitte geben Sie Ihr altes Passwort ein!",
        "old_password": "Altes Passwort",
        "new_password": "Neues Passwort",
        "input_confirm_password": "Bitte bestätigen Sie das neue Passwort.",
        "confirm_password": "Bestätigen Sie Ihr Passwort",
        "reset_password": "Passwort zurücksetzen",
        "wrong_password": "Falsches Passwort. Bitte bestätigen Sie es erneut.",
        "astrict": "Bitte geben Sie 6 bis 20 Zeichen ein.",
        "old_password_error": "Das ursprüngliche Passwort ist falsch"
    }
}