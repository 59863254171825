import getAdminRole from 'utils/getAdminRole';
// import getUserInfo from 'utils/getUserInfo';
import { couldInstallChromeExt } from 'utils/utils';

// window.FORCE_INSTALL_EXT = true;
// window.FORCE_INSTALL_EXT_REF = true;
// 强制装插件灰度测试
export const checkUserForInstallExt = () =>
  (window.FORCE_INSTALL_EXT || getAdminRole() === 'A') &&
  couldInstallChromeExt();

export const checkUserForInstallExtRef = () =>
  window.FORCE_INSTALL_EXT_Ref || getAdminRole() === 'B';

// 设置当前用户已完成装插件流程 这里保存在前端
export const setUserInstalledExt = userId => {
  if (!userId) {
    return;
  }
  let current;
  try {
    current = JSON.parse(localStorage.getItem('installExtFinished') || '[]');
    if (!current.push) {
      current = [];
    }
  } catch (error) {
    console.log(error);
    current = [];
  }
  current.push(userId);
  localStorage.setItem('installExtFinished', JSON.stringify(current));
  window.dataLayer.push({
    event: 'installExtFinished'
  });
};
// 检查用户是否完成装插件流程
export const getUserInstalledExt = userId => {
  if (!userId) {
    return false;
  }
  try {
    const current = JSON.parse(
      localStorage.getItem('installExtFinished') || '[]'
    );
    return current.includes(userId);
  } catch (error) {
    console.log(error);
    return false;
  }
};
