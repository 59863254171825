export default {
  product: {
    dsers_verified_banner:
      '<h2>Projet DSers Fournisseurs Ali <span>Vérifiés</span> </h2><p>Avec solution de paiement. Plus de carte de crédit bloquée!</p>', // d
    dsers_verified_banner_btn: 'EN SAVOIR PLUS', // d
    search_placeholder: 'Rechercher Mes Produits ici',
    search_btn: 'OK',
    tab_all: 'Tous <i>({all_num})</i>',
    tab_aliexpress: 'AliExpress <i>({ae_num})</i>', // m  v1 Unpushed
    tab_tmall: "Tmall <i>({tm_num})</i>",
    tab_other: 'Produits non-mappés <i>({map_num})</i>', // m  v1 Pushed
    tab_verified: 'Vérifié', // d
    btn_import_shopify_products: 'Importer Produits Shopify',
    btn_import_shopify_products_tip:
      'DSers synchronise uniquement les produits déjà commandés. Vous pouvez importer les autres produits  en cliquant sur le bouton Importer Produits Shopify',
    btn_import_shopify_products_tip_link: 'Voir le blog',
    select_source: {
      all: 'Tous',
      unPushed: 'Hors-Ligne', // m
      pushed: 'En Ligne' // m
    },
    push_btn: 'Envoyer sur Shopify',
    delete_btn: 'Supprimer',
    delete_selected_products: 'Supprimer produits sélectionnés',
    hide_selected_products: 'Cacher produits sélectionnés',
    selectAll: 'Tout Sélectionner',
    hide: 'Cacher',
    del: 'Supprimer',
    myProducts: 'Mes Produits',
    product: {
      remove_from_shopify: 'Supprimé sur Shopify',
      tip_deleted_on_Shopify: `Ce produit a été supprimé sur Shopify
                              Mais les commandes avec ce produit peuvent toujours être exécuté sur DSers.
                              Vous pouvez supprimer ce produit en cliquant sur l'icône de Corbeille si vous souhaitez le supprimer définitevement.`,
      hidden: 'Caché', // a
      tip_hidden: `Ce produit a été caché.
                  Vous ne verrez pas ce produit dans vos commandes et il ne sera pas envoyé sur AliExpress quand vous placez vos commandes.
                  Vous ne verrez cet objet dans aucune de vos commandes et il ne sera pas envoyé sur AliExpress quand vous placez vos commandes.`,
      verified_btn_apply: 'POSTULER', // d
      verified_btn_edit: 'ÉDITER', // d
      verified_btn_enable: 'ACTIVER', // d
      sample_on_way: 'Votre échantillon est en chemin', // d
      piece_tip:
        'Le coût est le prix sur la page AliExpress de votre fournisseur. Cela peut varier de celui que vous voyez sur AliExpress car DSers affiche les prix en ne prenant pas en compte le pays vers lequel vous envoyez le produit.',
      piece: 'pièce',
      processing: 'Traitement en 24h',
      cost: 'Coût',
      price: 'Prix',
      orders: 'commandes',
      stock: 'Stock',
      delete: 'Supprimer',
      check_details: 'Voir détails',
      edit_details: 'Éditer Produit',
      open_in_shopify: 'Ouvrir dans Shopify',
      publish_firsh: 'Publier d\'abord puis ouvrir dans Shopify',
      split: 'Split product', // a
      hidden_tip:
        'Cliquez pour ne plus caché ce produit. Vous verrez ce produit dans vos commandes.', //  add
      hidden_open_tip:
        'Cliquez pour Cacher ce produit et ne plus le traiter lorsque vous placez vos commandes.', //  add
      mapping_Page: 'Page de Mapping',
      mapping_tip: 'Publier d\'abord puis ajouter des fournisseurs', //  d
      push_to_shopify: 'Envoyer sur Shopify',
      import_to_store: 'Importer sur la boutique', // d
      no_out_stock: 'Variante en rupture de stock',
      multiple: 'Multiple'
    },
    manage_product: {
      title: 'Select product and set up shipping method',
      title1: "Import Shopify Products",
      nombre:`Si vous avez un grand nombre de produit à importer, vous pouvez <a href="https://www.messenger.com/t/DSersOfficial" dd="src" target="_blank">contacter le service</a> client pour qu'il vous aide.`,
      search_placeholder: 'Trouver produits…',
      no_data: 'Pas de donnée',
      syncing: 'Synchronisation',
      import_btn: 'Importer',
      select_max_tip: 'Vous ne pouvez importer que 10 produits à la fois maximum',
      import_max_tip: '10 produits maximum par import groupé à la fois',
      import_shopify_Copywriting: `Oops, il semblerait que le chargement ait pris trop de temps, veuillez réessayer`,
      bulk_import: `Si vous avez un grand nombre de produit à importer, vous pouvez <a href="https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2FDSersOfficial" target="_blank" rel="noopener noreferrer">contacter le service</a> client pour qu'il vous aide`
    },
    visible_succ: 'Opération réussie',
    already_imported:
      'Vous avez déjà importé ce produit, vous ne pouvez pas le reimporter',
    copy_first_pushed:
      'Échec d\'ouverture, le produit doit d\'abord être Envoyé / Publié sur Shopify',
    succ_copy: 'Copie réussie',
    copy_failed: 'Échec de copie',
    update_succ: 'Mise à jour réussie!',
    delete_tip: 'Êtes-vous sûr de vouloir supprimer ce produit?',
    delete_pushed_tip:
      '<span>Les produits supprimés <strong>NE PEUVENT PAS ÊTRE RÉCUPÉRÉS </strong>et seront <strong>SUPPRIMÉ DE VOTRE BOUTIQUE SHOPIFY.</strong> Voulez-vous continuer?</span>',
    delete_confirm:
      'Êtes-vous sûr de vouloir supprimer ce produit? Ceci est DÉFINITIF et SUPPRIMERA le(s) produit(s) de la boutique Shopify!', // m
    input_confirm_tip:
      'Veuillez écrire CONFIRMER pour supprimer le(s) produit(s)',
    open_mapping_tip: 'Publier d\'abord puis mapper',
    no_data: {
      add_shopify_store: {
        title: 'Ajouter une boutique Shopify',
        description: 'Ajouter une boutique Shopify à DSers en cliquant sur Lier à Shopify pour gérer vos produits.<br /> Mapper le produit avant de placer des commandes groupées'
      },
      is_sync:
        'Synchronisation des information de Shopify, veuillez attendre la fin de la synchronisation.',
      no_data: 'Lancez votre business avec DSers,<br/> cliquez',
      no_data_end: 'pour apprendre comment importer des produits depuis AliExpress vers Dsers !',
      has_store: {
        title: `DSers synchronisera uniquement les produits déjà commandés sur Shopify.<br />
                Mais pas d'inquiétudes, vous pouvez importer les produits que vous voulez voir sur DSers de votre liste de produit Shopify en cliquant sur<br />`,
        btn: 'Importer Produits Shopify'
      },
      no_product_tip_1:`
      1. Cliquez sur « Importer un produit Shopify » dans le coin supérieur droit pour importer des produits Shopify dans DSers`,
      no_product_tip_2:`2. Associez les produits importés afin de traiter les commandes plus rapidement`
    },
    visible_progress_title1:
      'Les produits sont visibles dans votre boutique Shopify.',
    visible_progress_title2: 'Commande en cours sur AliExpress...',
    show_supply_chain: {
      // d
      btn: 'LEARN MORE',
      description: `No More Credit Card Locked Up <br/>
                    Delivery 100% Guaranteed <br/>
                    Ship Out Within 3 Days <br/>
                    Real Time Customer Service <br/>`,
      title: 'DSers <i>Verified</i> Ali Suppliers Project'
    }, // d
    show_balance_no: {
      //  d
      btn: 'MAKE PAYMET',
      tip: 'Available DSers Balance',
      title: 'There is not available balance <br/> in your account'
    }, //  d
    show_address_no: {
      //  d
      btn: 'Go to Setting',
      tip:
        'Please set your address information and ensure that the balance is greater than 0.'
    }, //  d
    show_set_visible: {
      tip: 'Publier dans la Boutique également',
      continue_selling: 'Continue selling when out of stock',
      description:
        'Les produits seront envoyés vers Shopify et ne pourront plus être édité sur DSers. Mais vous pourrez toujours gérer les fournisseurs',
      cancel: 'Annuler',
      yes: 'Oui'
    },
    hide_prod_tip:
      'Êtes-vous sûr de vouloir cacher ce produit? Les commandes de ce produit ne s\'afficheront pas sur DSers et vous ne pourrez plus synchronisé les produits à DSers.',
    confirm_prod_tip: 'Entrez CONFIRMER pour cacher ce produit.',
    possible_cause: 'Raison possible:',
    // eslint-disable-next-line
    search_notice_for_migrate: `Veuilez ne pas coller d'URL ici. Si vous voulez importer des produits, rendez-vous dans la Liste d'Import`,
    // eslint-disable-next-line
    search_msg_for_migrate: `Rendez-vous dans la Liste d'Import pour importer des produits. Cliquez <a href="https://help.dsers.com/import-list-for-shopify/ " target="_blank"><strong>ici</strong></a> pour plus d'information.`,
    filter_push_title: 'Filtrer par envoyé',
    to_push_shopify: 'Déjà envoyé vers Shopify',
    no_push_shopify: 'Non envoyé vers Shopify',
    introduce_con1: 'Vous êtes sur le point de supprimer les produits sélectionnés de DSers, mais les produits resteront dans votre boutique Shopify.',
    introduce_con2: 'Vous êtes sur le point de supprimer les produits sélectionnés de DSers, les produits seront également supprimé de votre boutique Shopify',
    introduce_checkbox: 'Supprimer également ce produit de ma boutique Shopify.',
    introduce_ok: 'CONFIRMER',
    introduce_cancle: 'Annuler',
    cost_ible: 'Coût $ ——',
    loading: 'Remplacement',
    error: 'Échec du remplacement',
    success: 'Remplacé avec succès',
    collections:'Rechercher le titre complet de la collection. (insensible à la casse)',
    product_type:'Select or create a Shopify type',
    tags:'Select or create a Shopify tag',
    vendor:'Select or create vendor',
    filterByMapping:'Filter by Mapping',
    basic_mapping:'Basic',
    standard_mapping:'Standard',
    advance_mapping:'Advanced',
    bogo_mapping:'BOGO',
    bundle_mapping:'Bundle',
    filterByNotification:'Filtrer par notification',
    filterByHidden:'Filtrer par statut',
    filterByVariants:'Variantes en rupture',
    filterByGone:'Produits supprimé par le fournisseur',
    filterBySku:`L'UGS a changé sur AliExpress`,
    productOutOfStock:'Product out of stock',
    productNotFound:'Product not found',
    filterByHid:'Produits Cachés',
    filterByShopifyDelete:'Retiré de Shopify',
    is_main_product:'Produit original non divisé',
    updatePrice:`Lors de la vérification des données, nous avons constaté que le prix du produit que vous avez migré depuis Oberlo était à l'origine un prix non américain, mais en raison d'une erreur interne, le prix a été migré en tant que prix américain. Nous en sommes vraiment désolés.
    Afin de corriger cette erreur à temps et de ne pas causer de problèmes et de pertes à vos opérations ultérieures, nous fournissons désormais une fonctionnalité dédiée pour modifier les produits de votre compte au prix du pays de destination que vous souhaitez. (Seuls les articles avec des prix américains peuvent être modifiés, et chaque article ne peut être modifié qu'une seule fois. Si vous n'avez pas besoin de ce service, vous pouvez ignorer ce message. Cette fonctionnalité ne sera disponible que pendant un mois)`,
    update:"Actualiser Prix",
    chooseCountry:'Choisir un pays',
    chooseSub:'Sélectionnez le pays pour lequel vous souhaitez mettre à jour les prix',
    update_desc_wait:'produits sont mis à jour',
    update_desc_ok:'mises à jour réussies',
    update_desc_fail:'mises à jour échouées',
    error_one:`Votre produit est en cours de mise à jour, car quelqu'un d'autre accède à votre compte et l'opération de mise à jour a été effectuée. `,
    error_two_title:'Aucun des produits que vous avez sélectionnés ne peut être mis à jour. Raisons :',
    error_two_sub:`1. Le prix du produit n'est pas fixé pour les États-Unis. 2. Votre produit est en cours de mise à jour, car quelqu'un d'autre accède à votre compte et l'opération de mise à jour a été effectuée. 3. Le produit a déjà été mis à jour.`,
    selectCountry:'Choisir pays',
    price_for:'Prix pour',
    collection_title:'Collections',
    type_title:'Type',
    tags_title:'Tags',
    vendor_title:'Fournisseur',
    productLimitTitle:'Limite de produits Shopify',
    productLimitSub:'Le nombre de produits pouvant être importés dans Mes Produits.',
    aeLimitTitle:'Limite de produits du fournisseur',
    aeLimitSub:`Somme des produits importés dans Liste d'Importation et du nombre de produits dans la liste Mes Produits.`,
    productCostToShopify:'Envoyer le prix du produit à Shopify',
    productCostAndShippingCostToShopify:'Envoie le coût du produit à Shopify',
    productCostAe:'Envoyez le prix du produit fournisseur par article à Shopify lorsque vous activez le produit.',
    remove_all_imgs:'remove all images'
  }
};
