
    export default 
    {
    "banner": {
        "title": "APP Ufficiale dei Partner di Consegna Diretta AliExpress",
        "quick_orders": "DSers è lo strumento migliore per processare tutti i tuoi ordini nel giro di minuti, senza limitazioni (100+)",
        "get_started_placeholder": "Inserire indirizzo email",
        "get_started": "Inizia",
        "order_management": "Gestione ordini",
        "order_management_description": "DSers è in grado di gestire ordini multipli e all'ingrosso.Processa più di 100 ordini al giorno nel giro di minuti.Tutti gli ordini vengono sincronizzati da Shopify simultaneamente.Il distributore potrà poi effettuare l'ordine su AliExpress tramite l'utilizzo di UN pulsante.Risparmia ore di tempo!",
        "supplier_management": "Gestione fornitori",
        "supplier_management_description": "Configura fornitori multipli per articoli selezionati.Il fornitore sostitutivo si attiverà SOLO nel caso in cui il prodotto non sia disponibile presso il tuo fornitore predefinito (ad esempio se fosse esaurito). Milioni di prodotti significano centinaia di fornitori, e una fornitura infinita su AliExpress. Non restare mai senza! (Una nuova funzionalità che sta per arrivare ti permetterà di trovare automaticamente il fornitore più economico!).",
        "csv_bulk_orders": "Trasforma gli ordini all'ingrosso in un file CSV",
        "csv_bulk_orders_description": "Goditi DSers ed effettua molteplici ordini in una volta sola a prescindere dalla piattaforma di vendita che si sta usando.Crea e aggiorna il database dei tuoi prodotti, importa i tuoi ordini in attesa su DSers ed effettuali tutti nello stesso momento! Così, semplicemente.Risparmia più tempo che mai con DSers.",
        "save_97_ordering_time": "Risparmia il 97% del tempo di ordine",
        "how_does_work": {
            "title": "Come funziona DSers per il distributore?",
            "easy": "Facile da iniziare. Facile da padroneggiare.",
            "multiple_store": "Negozio multiplo",
            "multiple_store_dec": "Gestisci molteplici negozi Shopify",
            "supplier_management": "Gestione dei fornitori",
            "supplier_management_dec": "Un prodotto con molteplici fornitori",
            "multiple_orders": "Molteplici ordini",
            "multiple_orders_dec": "Processa molteplici ordini con un click",
            "auto_sync": "Sincronizzazione automatica",
            "auto_sync_dec": "Status dell'ordine sincronizzato da AliExpress"
        },
        "feedback": {
            "title": "Reputato affidabile da persone come te",
            "feedback": "Opinioni dai nostri utenti principali",
            "like": "Mi piace",
            "comment": "Commenta",
            "share": "Condividi",
            "comments": "Commenti",
            "tomas": "Tomas",
            "tomas_time": "Ieri alle 4:19 AM",
            "tomas_dec": "Stiamo usando DSers come strumento per ordinare su AliExpress. È forte. Grazie mille per questo.",
            "simon": "Simon",
            "simon_time": "2 Novembre 2018 alle 11:09 AM",
            "simon_dec": "Dsers è un'app grandiosa! Sono felice di usarlo perché mi farà risparmiare molto tempo e denaro. È un'esperienza davvero forte poter portare a termine centinaia di ordini in solo un paio di minuti.",
            "wilson": "Wilson",
            "wilson_time": "Ieri alle 9:22 PM",
            "wilson_dec": "Questa app è letteralmente un sogno!! È davvero FANTASTICO vedere che gli ordini all'ingrosso sono stati portati a termine correttamente nel giro di un minuto! Mi risparmia un sacco di lavoro.",
            "jonathan": "Jonathan",
            "jonathan_time": "alle 5:48 PM",
            "jonathan_dec": "Sono davvero felice di aver trovato il lavoro fantastico che è possibile fare dal vostro sito! Portare a termine gli ordini è diventato molto più facile da quando ho iniziato a usare DSers."
        },
        "features": {
            "title": "DSers fornisce tutte le funzionalità per aziende a consegna diretta",
            "dec": "Funzionalità chiave su DSers",
            "multiple_order_processing": "Processare molteplici ordini",
            "multiple_order_processing_dec": "Processare molteplici ordini",
            "variants_mapping": "Tracciamento delle varianti",
            "variants_mapping_dec": "Aggiungi facilmente varianti ai prodotti aggiungendo fornitori",
            "shipment_setting": "Impostazione di spedizione",
            "shipment_setting_dec": "Imposta il metodo di spedizione in base a Stati e tariffe",
            "aliExpress_whitelist": "Elenco dei contatti autorizzati AliExpress",
            "aliExpress_whitelist_dec": "Più nessuna limitazione di ordini",
            "multiple_stores_manager": "Gestione di molteplici negozi",
            "multiple_stores_manager_dec": "Gestisci molteplici negozi in un solo account",
            "supplier_management": "Gestione del fornitore",
            "supplier_management_dec": "Aggiungi molteplici fornitori a un prodotto",
            "product_importer": "Importatore del prodotto",
            "product_importer_dec": "Importa prodotti con l'estensione Chrome",
            "auto_messaging": "Messaggistica Automatica",
            "auto_messaging_dec": "Crea messaggi automatici per i venditori AliExpress",
            "email_template": "Template dell'email",
            "email_template_dec": "Email di notifica spedizione presto in arrivo",
            "tracking": "Tracciamento",
            "tracking_dec": "Tracciamento accurato delle informazioni di spedizione",
            "price_monitor": "Monitoraggio del prezzo",
            "price_monitor_dec": "Ottieni notifiche per i cambi di prezzo su AliExpress",
            "inventory_monitor": "Monitoraggio dell'inventario",
            "inventory_monitor_dec": "Sincronizza l'inventario dei prodotti da AliExpress"
        },
        "easy_started": "Completamente gratuito, facile da iniziare",
        "partnership": "Collaborazione",
        "advabced": "Avanzato",
        "content": "SCONTO DEL 20%",
        "will": "Se esegui l'upgrade ad Avanzato ora, potrai:",
        "support": "Supportare 5 negozi aggiuntivi, avere un limite di 10.000+ prodotti, e accesso a nuove strategie di marketing",
        "today": "SOLO PER OGGI!",
        "btn1": "ESEGUI ORA L'UPGRADE",
        "btn2": "Ci penserò",
        "yes": "ESEGUI ORA L'UPGRADE",
        "no": "Voglio tenere il mio piano Standard >>",
        "btn3": "Supportare 5 negozi aggiuntivi, avere un limite di 10.000+ prodotti | accesso a nuove strategie di marketing",
        "ioss": {
            "contact_us": "CONTATTACI",
            "title": "Contatta il tuo supply account manager per avere più informazioni su IOSS",
            "sub_title": "----- Notizia rilevante sulla riforma delle tasse dell'Unione Europea",
            "text": "L'Unione Europea inizierà a implementare nuove regolamentazioni sulle tasse sull'IVA a partire dal 1 luglio 2021. Le compagnie di distribuzione a consegna diretta possono proseguire normalmente e i clienti finali non dovranno pagare alcuna tassa addizionale. Se hai preoccupazioni riguardo IOSS, problemi di tasse locali, rimborsi di tasse, sentiti libero di contattare il tuo supply account manager (clicca qui per saperne di più).",
            "title2": "Introduzione della nuova tassa dell'Unione Europea (IOSS)",
            "text2": "Effetti su compagnie di distribuzione a consegna diretta in Stati dell'Unione Europea a partire dal 1 luglio 2021"
        },
        "newUI": {
            "title": "Brand new UI, brand new experience",
            "title2": "DSers will launch a new UI in early September",
            "learn": "LEARN MORE",
            newYearTitle: "Chinese New Year Holidays Notice",
            newYearContent1: "Chinese New Year is coming. Please contact your suppliers to check their delivery delays and resting period!",
            newYearContent2: "DSers suppliers will rest from 21st Jan 2023 to 23rd Jan 2023",
        },
        "bannerFor11_11": "Discover massive promotions on 11.11",
        "bannerButton": "LEARN MORE",
        "newFeatureUp": "New feature upgrade",
        "newFeatureTitle": "[New feature]",
        "newFeatureText": "To help you better manage your store and team and assign permissions, you can use the staff account feature",
        "newSettingTitle": "[New setting]",
        "newSettingText": "The Setting page has been upgraded, it is clearer and more convenient",
        "learnMore": "Learn more",
        "gotIt": "Got it",
        "appointmenttext": "Hai impostato l'ora di inizio della migrazione su [ {time} ], non sarai in grado di operare sul tuo account DSers quando la migrazione inizierà",
        "appointmenttext1": "Hai impostato l'ora di inizio della migrazione su [ {time} ], e ti aiuteremo a migrare in 5 minuti. Non sarai in grado di utilizzare il tuo account DSers durante la migrazione, quindi puoi prenderti una pausa.",
        agency_cold_startup: {
            banner_title_1: 'Nuova Funzione Online!',
            banner_title_2: `Gestione degli Ordini dell'Agenzia`,
            popup_desc_1: `Stai passando da uno strumento all'altro per l'evasione degli ordini delle agenzie?`,
            popup_desc_2: `Prova questa soluzione alternativa di DSers per evitare di dover passare da un negozio Shopify all'altro, da un account Mass Fulfill a un file CSV.`,
            popup_desc_3: `Concentrati sul marketing in questo Q4!.`,
            learn_more_btn: 'Per saperne di più',
            banner_desc: `Prova questa soluzione alternativa di DSers per evitare di dover passare da un negozio Shopify all'altro, da un account Mass Fulfill a un file CSV.`,
            order_awaiting_empty_after_title: 'Prima',
            order_awaiting_empty_before_title: 'Dopo',
            order_awaiting_empty_before: 'Senza questa funzione, ti servirebbero più strumenti per gli ordini delle agenzie',
            order_awaiting_empty_after: 'Grazie a questa funzione, ti basta DSers!',
            order_awaiting_empty_link: 'Clicca <a data="text-link" style="color: blue" href="https://www.dsers.com/blog/product-update-dropship-with-agencies/" target="_blank" >qui</a> per saperne di più su come elaborare gli ordini delle agenzie.',
            what_is_agency: `Cos'è un'agenzia?`,
            what_is_erp: `Cos'è un ERP?`,
            how_does_dsers_agency_works: `In che modo DSers semplifica il flusso di lavoro con le agenzie di Dropshipping?`,
          },
          tmall_cold_startup: {
            popup_title: 'Nuovo Marketplace',
            pupup_title_2: 'Tmall è online! Scoprilo!',
            popup_desc_1: `Migliaia di prodotti più venduti sono stati appena aggiunti all'elenco.`,
            popup_desc_2: 'Perché aspettare a importarli nel tuo negozio?',
            popup_btn: 'Guarda i prodotti',
            top_banner_title: 'Nuovo Marketplace',
            top_banner_title_2: 'Tmall è online! Scoprilo!',
            find_supplier_banner_title: 'I prodotti più venduti nel quarto trimestre del 2022!',
            find_supplier_banner_subtitle: 'Gli articoli di tendenza e remunerativi sono disponibili!',
            find_supplier_banner_subtitle_2: 'Aggiungili al tuo negozio!'
          }
    }
}