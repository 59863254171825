import React, { Component } from 'react';
import { connect } from 'dva';
import { Modal, Icon, Input, message, Spin, Collapse, Popover,Drawer,Button } from 'antd';

import className from 'classnames';
import intl from 'react-intl-universal';
import InfiniteScroll from 'react-infinite-scroller';

import { replaceUrlToHttps } from 'utils/utils';
import SetShipping from './Shipping';
import stylesS from './index.less';
import defaultImg from '../../../../../assets/default-image.jpg';
import tmalllogo from 'assets/tmall_logo.svg';
import ae from 'assets/icon-ae.jpg';

const { Search } = Input;
const { Panel } = Collapse;

@connect(({ dropshippersetting, loading }) => ({
  dropshippersetting,
  loading: loading.models.dropshippersetting,
  listLoading: loading.effects['dropshippersetting/productList'],
  saveLoading: loading.effects['dropshippersetting/saveShip']
}))
export default class ProductShipping extends Component {
  searchKey = '';

  cacheSearchKey = '';

  hasMore = true;

  limit = 20;

  current_page = 1;

  sets = [];

  cacheShip = {};

  supplier_type = ''

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      activeKey: [],
      searchValue: undefined
    };
  }

  componentDidUpdate(prevProps) {
    const { shippingMethodModal } = this.props.dropshippersetting;
    if (
      prevProps.dropshippersetting.shippingMethodModal !==
        shippingMethodModal &&
      shippingMethodModal.data
    ) {
      const value = shippingMethodModal.data.title;
      if (value) {
        // eslint-disable-next-line
        this.setState({ searchValue: value });
        this.searchProduct(value, undefined, true, shippingMethodModal.data);
      }
    }
  }

  // 隐藏商品搜索弹窗
  hideProductSearch = () => {
    this.sets = [];
    this.cacheShip = {};
    this.searchKey = '';
    this.cacheSearchKey = '';
    this.setState({
      searchValue:'',
      list: [],
      activeKey: []
    });

    this.props.dispatch({
      type: 'dropshippersetting/hideModal'
    });
  };

  // 搜索产品
  searchProduct = (v, _, open = false, data) => {
    this.searchKey = v;
    this.aePid = data?.product_id_str || data?.product_id;
    this.lastId = '';
    this.cacheSearchKey = '';
    this.supplier_type = data?.platform_type
    this.getList(open);
  };

  // 获取商品列表
  getList = openFirst => {

    console.log(this.searchKey)
    if(this.state.hasMore){
      this.current_page += 1
      this.setState({
        hasMore:false
      })
    }

    this.props.dispatch({
      type: 'dropshippersetting/productList',
      payload: {
        data: {
          limit: this.limit,
          // current_page: this.current_page,
          title: this.aePid ? '' : encodeURIComponent(this.searchKey),
          last_id: this.lastId,
          ae_pid: this.aePid,
          supplier_type:this.supplier_type,
          release_tmall:window.TMALL_USER ? true : false,
          v2:'true'
        },
        callback: d => {
          this.cacheSearchKey = this.searchKey;
          if (d.code == 2000) {
            const { prods = [], sets = [] } = d.data;

            this.setHasShip(d.data);
            this.sets = this.lastId ? [...this.sets, ...sets] : sets;
            this.setState({
              list: this.lastId ? [...this.state.list, ...prods] : prods,
              hasMore: d.data?.prods.length === this.limit, // 判断是否还有下一页
              activeKey: prods.length > 0 && openFirst ? [prods[0].id] : []
            });

            if (prods.length) {
              this.lastId = prods[prods.length - 1].id;
            }
          }
        }
      }
    });
  };

  // 设置商品有没有设置物流
  setHasShip = data => {
    const { prods = [], sets = [] } = data;

    if (!sets.length) {
      return;
    }

    prods.forEach(prod => {
      let flag = false;
      sets.forEach(ship => {
        if (
          ship.aliexpress_product_id == prod.aliexpress_product_id &&
          ship.settings.length
        ) {
          flag = true;
        }
      });

      if (flag) {
        prod.hasShip = true;
      }
    });
  };

  // 获取产品dom
  getProduct = prod => {
    return (
      <div className={stylesS.product}>
        <Popover
          placement="right"
          content={
            <img
              src={replaceUrlToHttps(prod.image_src) || defaultImg}
              style={{
                width: '260px',
                height: '260px',
                objectFit: 'contain'
              }}
            />
          }
          overlayClassName={stylesS.imgZoom}
        >
          <span style={{ position: 'relative' }}>
            <img src={replaceUrlToHttps(prod.image_src) || defaultImg} />
            <img
              style={{
                width: '14px',
                height: '14px',
                position: 'absolute',
                right: '0px',
                bottom: '0px'
              }}
              src={prod?.supplier_type === 4 ? tmalllogo : ae}
              alt=""
            />
          </span>
        </Popover>
        <p className={stylesS.productTitle}>{prod.title}</p>
        <Icon
          type="setting"
          className={className(stylesS.productTool, {
            [stylesS.hasShip]: prod.hasShip
          })}
        />
      </div>
    );
  };

  // 找到符合这个商品的物流设置
  findShip = (id, type)=> {
    let ship;
    this.sets.forEach(item => {
      if (item.aliexpress_product_id == id && item.supplier_type == type || 
        item.aliexpress_product_id_str == id && item.supplier_type == type ) {
        ship = item;
      }
    });

    return ship;
  };

  // 保存成功更新sets
  updateSets = ship => {
    const { list } = this.state;
    list.forEach(item => {
      if (
        item.aliexpress_product_id == ship.aliexpress_product_id &&
        ship.settings.length
      ) {
        item.hasShip = true;
      }
    });
    this.setState({
      list: [...list]
    });
  };

  // 把更改的物流缓存起来
  updateShip = ship => {
    this.cacheShip[ship.aliexpress_product_id] = ship;
    this.forceUpdate();
  };

  // 保存物流设置
  saveShip = () => {
    const saveShip = Object.values(this.cacheShip);
    saveShip.length && this.subSaveShip(saveShip, 0);
  };

  // 确认提交保存
  subSaveShip = (data, i) => {
    this.props.dispatch({
      type: 'dropshippersetting/saveShip',
      payload: {
        data: data[i],
        callback: d => {
          this.cacheSearchKey = this.searchKey;
          if (d.code == 2000 || d.code == 2010) {
            this.updateSets(data[i]);
            message.success(intl.get('setting.pricing.save_succ'));
          }

          if (data.length > i + 1) {
            this.subSaveShip(data, i + 1);
          }
        }
      }
    });
  };

  handleCollapseChange = value => {
    this.setState({ activeKey: value });
  };

  handleSearchChange = e => {
    this.setState({ searchValue: e.target.value });
  };

  render() {
    const {
      listLoading,
      saveLoading,
      dropshippersetting: { shippingMethodModal }
    } = this.props;
    const { list, hasMore, activeKey, searchValue } = this.state;
    return (
      <Drawer
        title={intl.get('product.manage_product.title')}
        maskClosable={true}
        visible={shippingMethodModal.visible}
        onClose={this.hideProductSearch}
        className={stylesS.modalWrap}
        width={868}
        okButtonProps={{ disabled: Object.values(this.cacheShip) === 0 }}
        confirmLoading={saveLoading}
        destroyOnClose
        zIndex={1002}
        footer={null}
      >
        <Search
          placeholder={intl.get('setting.shipping.product.modal_placeholder')}
          onSearch={this.searchProduct}
          autoFocus
          value={searchValue}
          onChange={this.handleSearchChange}
        />
        <div
          className={stylesS.productList}
          style={{ height: 'calc( 100vh - 248px )' }}
        >
          {listLoading ? (
            <div className={stylesS.productLoading}>
              <Spin size="large" />
            </div>
          ) : null}
          <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            useWindow={false}
            loadMore={this.getList}
            hasMore={!listLoading && hasMore}
          >
            <Collapse
              bordered={false}
              activeKey={activeKey}
              onChange={this.handleCollapseChange}
            >
              {list.map(item => {
                return (
                  <Panel
                    header={this.getProduct(item)}
                    key={item.id}
                    showArrow={false}
                  >
                    <SetShipping
                      prod={item}
                      updateShip={this.updateShip}
                      ship={this.findShip(item?.aliexpress_product_id_str || item?.aliexpress_product_id,item.supplier_type)}
                    />
                  </Panel>
                );
              })}
            </Collapse>
          </InfiniteScroll>
          {list.length ? null : (
            <div className={stylesS.noResult}>
              {intl.get('setting.shipping.product.tip')}
            </div>
          )}
        </div>
        <div className={stylesS.shippingBottom}>
          <Button data="bk" className={stylesS.cancelBtn} onClick={this.hideProductSearch}>{intl.get('public.cancel')}</Button>
          <Button  data="dx" className="ddddd" onClick={this.saveShip}>{intl.get('public.save')}</Button>
        </div>
      </Drawer>
    );
  }
}
