/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { connect } from 'dva';
import { Form, Input, Select, Button, message, Icon } from 'antd';
import DocumentTitle from 'react-document-title';
import intl from 'react-intl-universal';
// import className from 'classnames';
import { Link, routerRedux } from 'dva/router';

import { pageTitle, passwordReg, emailRegExp } from 'utils/constants';
import { businessType, checkPassword } from './until';

import styles from './SignUpPage.less';

const { Option } = Select;
const { TextArea } = Input;
@connect(({ dropshippersetting, partner }) => ({
  countries: dropshippersetting,
  loginInfo: partner.loginInfo
}))
@Form.create()
class SignUpPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      residences: [],
      btnType: true,
      btnLoading: false
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch({
      type: 'partner/GetCountryList',
      payload: {
        callback: d => {
          if (d.code === 2000) {
            const list = [];
            d.data.forEach(item => {
              list.push(<Option value={item.n}>{item.n}</Option>);
            });
            this.setState({
              residences: list
            });
          }
        }
      }
    });
  }

  shouldComponentUpdate(nextProps) {
    const { dispatch } = this.props;
    if (
      nextProps.location.pathname === '/partner/register' &&
      nextProps.loginInfo
    ) {
      dispatch(routerRedux.push('/partner/home'));
    }
    // 唯一用于控制组件重新渲染的生命周期，在这里return false可以阻止组件的更新
    // 主要用于性能优化
    return true;
  }

  changeChannelID = (rule, value, callback) => {
    if (value.length === 0) {
      callback();
    }
    const newValue = value.replace(/^\s+|\s+$/gm, '');
    if (/-\d+$/.test(newValue)) {
      callback(intl.get('partner.signUp.partner_id_is_value_format_tips'));
    }
    if (value.length > 0 && newValue === '') {
      callback(intl.get('partner.signUp.partner_id_is_value_tips'));
    }
    if (newValue.length >= 100) {
      callback(intl.get('partner.signUp.partner_id_font_num_tips'));
    }
  };

  // 提交
  handleSubmit = e => {
    const { form, dispatch } = this.props;
    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        // eslint-disable-next-line no-console
        console.log('Received values of form: ', values);
      }
    });
    this.setState({ btnLoading: true });
    const subvalue = form.getFieldsValue();
    dispatch({
      type: 'partner/partnerRegister',
      payload: {
        data: subvalue,
        callback: d => {
          this.setState({ btnLoading: false });
          if (d.code === 2000) {
            message.success(d.msg);
          }
        }
      }
    });
  };

  // 确认密码
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback(intl.get('partner.signUp.confirm_pwd_contrast_tips'));
    } else {
      callback();
    }
  };

  // 效验字数
  sizeNum = (rule, value, callback, max, min = 0) => {
    if (value.length === 0) {
      callback();
    }
    if (value.length !== 0 && min > 0 && value.length < min) {
      callback(intl.get('partner.signUp.remark_font_num_min_tips'));
    } else if (value.length > max) {
      callback(intl.get('partner.signUp.remark_font_num_max_tips'));
    }
  };

  // 效验名字长度
  changeName = (rule, value, callback) => {
    const valueLength = value.replace(/^\s+|\s+$/gm, '');
    if (valueLength.length > 0 && valueLength === '') {
      callback(intl.get('partner.signUp.name_is_value_tips'));
    } else if (valueLength.length > 100) {
      callback(intl.get('partner.signUp.name_is_value_tips'));
    } else {
      callback();
    }
  };

  // 效验链接格式
  channelUrl = (rule, value, callback) => {
    const Expression = /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~/])+$/;
    if (value.length >= 1000) {
      callback(intl.get('partner.signUp.partner_url_font_num_tips'));
    }
    // console.log(value.test(Expression));
    if (value.length > 0 && !Expression.test(value)) {
      callback(intl.get('partner.signUp.partner_url_format_tips'));
    }
    if (value.length === 0) {
      callback();
    }
  };

  // 判断当前必填项是否符合要求
  channgeFrom = () => {
    const { form } = this.props;
    const list = [
      // 'business_type',
      'password',
      'confirm',
      'name',
      'country',
      // 'business_type',
      'partner_url',
      // 'partner_id',
      // 'paypal_email',
      'remark',
      'email'
    ];
    // 判断当前from表单中是否有未符合标准的
    const errorList = form.getFieldsError(list);
    // 判断所有必填中是否全部填写
    const valueList = form.getFieldsValue(list);
    // valueList.partner_id = valueList?.partner_id?.replace(/^\s+|\s+$/gm, '');
    valueList.name = valueList?.name?.replace(/^\s+|\s+$/gm, '');
    // eslint-disable-next-line compat/compat
    const newErrorList = Object.values(errorList).filter(
      item => item !== undefined
    );
    // eslint-disable-next-line compat/compat
    const newValueList = Object.values(valueList).filter(
      item => item === undefined
    );
    if (newErrorList.length === 0 && newValueList.length === 0) {
      this.setState({
        btnType: false
      });
    } else {
      this.setState({
        btnType: true
      });
    }
  };

  handlePreviousPage = () => {
    window.location.href = '/partner';
  };

  render() {
    const { form } = this.props;
    const { getFieldDecorator } = form;
    const { residences, btnType, btnLoading } = this.state;
    const newBusinessType = [];
    businessType.forEach(item => {
      newBusinessType.push(<Option value={item.label}>{item.value}</Option>);
    });
    return (
      <DocumentTitle title={`Sign in - ${pageTitle}`}>
        <div className={styles.signUp}>
          <div className={styles.mantle}>
            <div className={styles.container}>
              <div className={styles.box}>
                <div className={styles.previous_page}>
                  <div onClick={this.handlePreviousPage}>
                    <Icon type="arrow-left" />
                  </div>
                </div>
                <div className={styles.boxCon}>
                  {/* <h1>{intl.get('partner.signUp.title')}</h1> */}
                  <Form onSubmit={this.handleSubmit} onChange={this.channgeFrom}>
                    <div className={styles.fromItem}>
                      {/* {' '} */}
                      <Form.Item label={intl.get('partner.signUp.email')}>
                        {getFieldDecorator('email', {
                          initialValue: '',
                          rules: [
                            {
                              pattern: emailRegExp,
                              message: intl.get(
                                'partner.signUp.email_format_tips'
                              )
                            },
                            {
                              required: true,
                              message: intl.get(
                                'partner.signUp.email_is_value_tips'
                              )
                            }
                          ]
                        })(
                          <Input
                            maxLength={500}
                            placeholder={intl.get(
                              'partner.signUp.email_placeholder_tips'
                            )}
                            autocomplete="new-password"
                          />
                        )}
                      </Form.Item>
                      <Form.Item label={intl.get('partner.signUp.pwd')}>
                        {getFieldDecorator('password', {
                          initialValue: '',
                          rules: [
                            {
                              required: true,
                              message: intl.get(
                                'partner.signUp.pwd_is_value_tips'
                              )
                            },
                            {
                              validator: (rule, value, callback) =>
                                checkPassword(rule, value, callback)
                            }
                          ]
                        })(
                          <Input.Password
                            onBlur={this.handleConfirmBlur}
                            placeholder={intl.get(
                              'partner.signUp.pwd_placeholder_tips'
                            )}
                            autocomplete="new-password"
                          />
                        )}
                      </Form.Item>
                      <Form.Item
                        label={intl.get('partner.signUp.confirm_Password')}
                      >
                        {getFieldDecorator('confirm', {
                          rules: [
                            {
                              required: true,
                              message: intl.get(
                                'partner.signUp.confirm_pwd_is_value_tips'
                              )
                            },
                            {
                              validator: this.compareToFirstPassword
                            }
                          ]
                        })(
                          <Input.Password
                            placeholder={intl.get(
                              'partner.signUp.confirm_pwd_placeholder_tips'
                            )}
                            onBlur={this.handleConfirmBlur}
                          />
                        )}
                      </Form.Item>
                      <Form.Item label={intl.get('partner.signUp.name')}>
                        {getFieldDecorator('name', {
                          rules: [
                            {
                              required: true,
                              message: intl.get(
                                'partner.signUp.name_is_value_tips'
                              )
                            },
                            {
                              validator: this.changeName
                            }
                          ]
                          // getValueFromEvent: event => {
                          //   return event.target.value.replace(/(^\s*)|(\s*$)/g, '');
                          // }
                        })(
                          <Input
                            placeholder={intl.get(
                              'partner.signUp.name_placeholder_tips'
                            )}
                          />
                        )}
                      </Form.Item>
                      <Form.Item label={intl.get('partner.signUp.country')}>
                        {getFieldDecorator('country', {
                          rules: [
                            {
                              required: true,
                              message: intl.get(
                                'partner.signUp.country_is_value_tips'
                              )
                            }
                          ]
                        })(
                          <Select
                            showSearch
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            placeholder={intl.get(
                              'partner.signUp.country_placeholder_tips'
                            )}
                          >
                            {residences}
                          </Select>
                        )}
                      </Form.Item>
                      {/* <Form.Item label={intl.get('partner.signUp.business_type')}>
                        {getFieldDecorator('business_type', {
                          rules: [
                            {
                              required: true,
                              message: intl.get(
                                'partner.signUp.business_type_is_value_tips'
                              )
                            }
                          ]
                        })(
                          <Select
                            placeholder={intl.get(
                              'partner.signUp.business_type_placeholder_tips'
                            )}
                          >
                            {newBusinessType}
                          </Select>
                        )}
                      </Form.Item> */}
                    </div>
                    <div className={styles.fromItem}>
                      <Form.Item label={intl.get('partner.signUp.partner_url')}>
                        {getFieldDecorator('partner_url', {
                          rules: [
                            {
                              required: true,
                              message: intl.get(
                                'partner.signUp.partner_url_is_value_tips'
                              )
                            },
                            {
                              validator: this.channelUrl
                            }
                          ]
                        })(
                          <Input
                            maxLength={1000}
                            placeholder={intl.get(
                              'partner.signUp.partner_url_placeholder_tips'
                            )}
                          />
                        )}
                      </Form.Item>
                      {/* <Form.Item label={intl.get('partner.signUp.partner_id')}>
                        {getFieldDecorator('partner_id', {
                          rules: [
                            {
                              required: true,
                              message: intl.get(
                                'partner.signUp.partner_id_is_value_tips'
                              )
                            },
                            {
                              validator: this.changeChannelID
                            }
                          ]
                          // getValueFromEvent: event => {
                          //   return event.target.value.replace(/(^\s*)|(\s*$)/g, '');
                          // }
                        })(
                          <Input
                            maxLength={100}
                            placeholder={intl.get(
                              'partner.signUp.partner_id_placeholder_tips'
                            )}
                          />
                        )}
                      </Form.Item> */}
                      <Form.Item label={intl.get('partner.signUp.paypal_email')}>
                        {getFieldDecorator('paypal_email', {
                          rules: [
                            {
                              pattern: emailRegExp,
                              message: intl.get(
                                'partner.signUp.email_format_tips'
                              )
                            }
                          ]
                        })(
                          <Input
                            maxLength={500}
                            placeholder={intl.get(
                              'partner.signUp.paypal_email_placeholder_tips'
                            )}
                          />
                        )}
                      </Form.Item>
                      <Form.Item label={intl.get('partner.signUp.remark')}>
                        {getFieldDecorator('remark', {
                          rules: [
                            {
                              required: true,
                              message: intl.get(
                                'partner.signUp.remark_placeholder_tips'
                              )
                            },
                            {
                              validator: (rule, value, callback) => {
                                this.sizeNum(rule, value, callback, 1000, 200);
                              }
                            }
                          ]
                        })(
                          <TextArea
                            maxLength={1000}
                            minLength={200}
                            rows={6}
                            placeholder={intl.get(
                              'partner.signUp.remark_is_value_tips'
                            )}
                          />
                        )}
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          disabled={btnType}
                          loading={btnLoading}
                        >
                          {intl.get('partner.signUp.register')}
                        </Button>
                        <div className={styles.jumpToLogin}>
                          {intl.get('partner.signUp.have_account')}
                          <Link to="/partner/login">
                            {intl.get('partner.signUp.sign_in')}
                          </Link>
                        </div>
                      </Form.Item>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DocumentTitle>
    );
  }
}

export default connect()(SignUpPage);
