
    export default 
    {
    "combo": {
        "headline": "Abonnement verwalten",
        "explain": "Verwalten Sie Ihr Abonnement und richten Sie hier einen Standard-Abrechnungsspeicher ein. Klicken Sie hier, um zu erfahren, wie Sie Ihr Abonnement verwalten können. Und wenn Sie mehr über den DSers Plan wissen wollen, klicken Sie bitte hier .",
        "change": "Plan ändern",
        "changEcard": "Aktueller Plan:",
        "changEcardBu": "Plan ändern",
        "changEcardUrl": "Wie kündige ich ein kostenpflichtiges Abonnement?",
        "changEcardservice": "Kontaktieren Sie",
        "paymentcard": "Standard-Fakturierungsshop",
        "paymentcardEcard": "Bitte legen Sie einen Shop als Standard-Abrechnungsshop fest. Wenn es nicht eingerichtet ist, wird das DSers-Konto geschlossen, bis Sie es eingerichtet haben.",
        "paymentcardBu": "Aktivieren "
    }
}