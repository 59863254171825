import React, { useEffect, useCallback, useState } from 'react';
import { Icon } from 'antd';
import intl from 'react-intl-universal';

import { replaceUrlToHttps } from 'utils/utils';
import styles from './Banner.less';

const duration = 24 * 60 * 60 * 1000; // 24 hours
// const duration = 60 * 1000; // 60 s

const updateStorage = (storeId, spId) => {
  let tmp = null;

  try {
    tmp = JSON.parse(localStorage.getItem('mapping_banner'));
  } catch (error) {
    console.log('updateStorage error:', tmp);
  }

  if (!tmp) {
    tmp = {
      [storeId]: {
        [spId]: Date.now()
      }
    };
  }

  if (tmp[storeId]) {
    tmp[storeId][spId] = Date.now();
  } else {
    tmp[storeId] = { [spId]: Date.now() };
  }

  window.localStorage.setItem('mapping_banner', JSON.stringify(tmp));
};

function Banner({ data, onCheckClick }) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    try {
      const tmp = JSON.parse(localStorage.getItem('mapping_banner'));
      if (!tmp) {
        setVisible(true);
        return;
      }

      // 不存在 store
      if (!tmp[data.store_id]) {
        setVisible(true);
        return;
      }

      // 存在 store, 但是没有 product id
      if (tmp[data.store_id] && !tmp[data.store_id][data.shopify_product_id]) {
        setVisible(true);
        return;
      }

      // 大于 24 hours
      if (Date.now() - tmp[data.store_id][data.shopify_product_id] > duration) {
        setVisible(true);
      }
    } catch (error) {
      console.log(error);
    }
  }, [data]);

  const handleClose = useCallback(() => {
    setVisible(false);

    updateStorage(data.store_id, data.shopify_product_id);

    // const test = {
    //   'bqa4lvt3p1t000aqpcmg': {
    //     1598515569349: 1598515569349,
    //     1598515569389: 1598515569349,
    //     1598515569329: 1598515569349
    //   },
    //   'bqa4lvt3p1t000aqpcmg44': {
    //     1598515569349: 1598515569349,
    //     1598515569389: 1598515569349,
    //     1598515569329: 1598515569349
    //   }
    // };
  }, [data]);

  const handleLinkClick = useCallback(() => {
    onCheckClick(data);
    updateStorage(data.store_id, data.shopify_product_id);
  }, [data]);

  if (!visible) {
    return null;
  }

  const link = data.dsers_url || '/find_suppliers';

  return (
    <div className={styles.banner}>
      <div className={styles.bLeft}>
        <div className={styles.row1}>{intl.get('bargain.powered_by')}</div>
        <div className={styles.row2}>{intl.get('bargain.mass_supply')}</div>
        <div className={styles.row3}>${data.dsers_price}</div>
        <a
          track-type="productBargain"
          track-name="bannerV2FromMapping"
        >
          <div className={styles.btn} onClick={handleLinkClick}>
            <span>Check product</span>
          </div>
        </a>
      </div>
      <div className={styles.goodsImg}>
        <img src={replaceUrlToHttps(data.dsers_img)} alt="" />
      </div>
      <div className={styles.bRight}>
        <div className={styles.rTitle}>
          {intl.get('bargain.try_this_product')}
        </div>
        <div className={styles.arrow}>
          <span className={styles.arrowCon}>
            SAVE {data.percent}&nbsp;
            <span role="img" aria-label="money icon">
              💰
            </span>
          </span>
        </div>
      </div>
      <div className={styles.close} onClick={handleClose}>
        <Icon type="close" />
      </div>
    </div>
  );
}

export default Banner;
