export default {
  paypal: {
    auto_sync_track_to_paypal:
      'Synchronisation automatique des numéros de suivi vers PayPal! Rendez-vous dans Réglages - Suivi pour en apprendre plus.',
    paypal_authorization_title: 'Autorisation PayPal',
    paypal_authorization_content: `Le système a détecté que le compte PayPal autorisé n'est pas le même que le compte lié à Shopify.<br/>Cela empêchera le Numéro de Suivi de synchroniser vers PayPal.Veuillez le changer<br/>Si vous avez déjà changé, veuillez ignorer ce rappel, cliquez dessous pour ne plus le voir`,
    paypal_change: 'Aller changer',
    disimiss: 'Disimiss',
    paypal_account: 'À propos du compte PayPal',
    paypal_reminder_content: `Le systême a détecté que l'autorisation du compte PayPal associé a expiré. Pour une meilleure expérience, veuillez ré-autoriser votre PayPal`,
    no_reminder: `Ne plus me le rappeler`,
    renew: 'Aller ré-autoriser',
    cancel: 'Cancel'
  }
};
