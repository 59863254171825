/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import { connect } from 'dva';
import { Form, Alert } from 'antd';
import Login from 'components/Login';
import { Link } from 'dva/router';
import className from 'classnames';
import intl from 'react-intl-universal';
import debounce from 'lodash/debounce';

import EmailPass from './component/content/EmailPass';

import styles from './login.less';

const FormItem = Form.Item;
const { UserName, PartnerPassword, Submit } = Login;
// eslint-disable-next-line @typescript-eslint/class-name-casing
@connect(({ partner }) => ({
  login: partner,
  submitting: partner.submitting
}))
@Form.create()
class PartnerLogin extends Component {
  // eslint-disable-next-line react/sort-comp
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      btnLoading: false
    };
  }

  // 获取输入的密码账号
  handleSubmit = debounce((err, values) => {
    // eslint-disable-next-line no-underscore-dangle
    const _this = this;
    this.setState({ btnLoading: true });
    const data = values;
    localStorage.removeItem('keyStatus')
    if (err) {
      return;
    }
    _this.subLogin(data, '');
  }, 500);

  // 登陆
  subLogin = data => {
    const { onSubmit } = this.props;
    // eslint-disable-next-line
    new Promise((resolve, reject) => {
      onSubmit({
        resolve,
        reject,
        data: {
          email: data.email.trim(),
          password: data.password
        },
        cancelMessage: true
      });
      this.setState({ btnLoading: false });
    });
  };

  // 获取账号
  changeUsername = e => {
    this.setState({
      username: e.target.value
    });
  };

  // 获取密码
  changePassword = e => {
    this.setState({
      password: e.target.value
    });
  };

  // 关闭发送邮件窗口
  hideSendResetEmail = () => {
    this.setState({
      showSendResetEmail: false
    });
  };

  // 重申密码
  showSendResetEmailFn = () => {
    this.setState({
      showSendResetEmail: true
    });
  };

  // 账号密码错误提示
  renderMessage = content => {
    return (
      <Alert
        style={{ marginBottom: 12, height: 34, lineHeight: '14px' }}
        message={content}
        type="error"
        showIcon
      />
    );
  };

  getErrorMsg = d => {
    if (!d) {
      return '';
    }
    let msg = '';

    const { data, code } = d;

    if (code === 2000 && data) {
      if (data.token === '') {
        msg = data.msg;
      }
    } else if (d.msg) {
      msg = d.msg;
    }
    return msg;
  };

  render() {
    const {
      username,
      password,
      showValidateCode,
      showSendResetEmail,
      btnLoading
    } = this.state;
    const { login, form, submitting } = this.props;

    const { loginData } = login;
    const showMessage = this.getErrorMsg(loginData);
    const { getFieldDecorator } = form;
    const isbtn = !(username && password);
    return (
      <div className={styles.carouselWrap}>
        <div
          className={styles.carouselContent}
          style={{ transform: showValidateCode ? 'translateX(-488px)' : '' }}
        >
          <div className={styles.main}>
            <div className={styles.mainLogin}>
              <h1>{intl.get('partner.signIn.title')}</h1>
              <Login onSubmit={this.handleSubmit}>
                {showMessage !== '' && this.renderMessage(showMessage)}
                <div
                  className={className(styles.inputWraperNull, {
                    [styles.inputWraper]: username
                  })}
                >
                  <UserName
                    name="email"
                    type="email"
                    autoComplete="new-password"
                    placeholder={intl.get('partner.signIn.email')}
                    onChange={e => {
                      this.changeUsername(e);
                    }}
                  />
                  <span className={styles.placeholder}>
                    {intl.get('partner.signIn.email')}
                  </span>
                </div>
                <div
                  className={className(styles.inputWraperNull, {
                    [styles.inputWraper]: password
                  })}
                >
                  <FormItem>
                    {getFieldDecorator('password', {
                      rules: [
                        // {
                        //   required: true,
                        //   message: intl.get('login.empty')
                        // }
                        // {
                        //   validator: this.checkPassword
                        // }
                      ]
                    })(
                      <PartnerPassword
                        name="password"
                        autoComplete="new-password"
                        placeholder={intl.get('partner.signUp.pwd')}
                        onChange={e => {
                          this.changePassword(e);
                        }}
                      />
                    )}
                  </FormItem>
                  <span className={styles.placeholder}>
                    {intl.get('partner.signUp.pwd')}
                  </span>
                </div>
                <Submit
                  style={{
                    height: 45,
                    marginTop: '0',
                    marginBottom: '0',
                    boxShadow: '0 2px 4px 0 #D5D8D9',
                    letterSpacing: '0.09px',
                    fontWeight: '600'
                  }}
                  disabled={isbtn}
                  loading={submitting || btnLoading}
                >
                  {intl.get('partner.signIn.log_in')}
                </Submit>
                <div className={styles.extra}>
                  <div>
                    <a
                      onClick={this.showSendResetEmailFn}
                      track-type="forgetPassword"
                      track-name="sign"
                    >
                      {intl.get('partner.signIn.forgot_password')}
                      {/* FORGOT PASSWORD? */}
                    </a>
                  </div>
                  {/* <div>
                    {intl.get('partner.signIn.no_account')}
                    <Link to="/partner/register">
                      {' '}
                      {intl.get('partner.signIn.become_partner')}
                    </Link>
                  </div> */}
                </div>
                <div className={styles.other}>
                  <p style={{ fontSize: 12, color: '#6D7BE1' }} />
                  <p className={styles.imgs} />
                </div>
              </Login>
            </div>
          </div>
        </div>
        <EmailPass
          showSendResetEmail={showSendResetEmail}
          hideSendResetEmail={this.hideSendResetEmail}
        />
      </div>
    );
  }
}

export default connect()(PartnerLogin);
