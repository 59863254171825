
    export default 
    {
    "h5_callback": {
        "bind_tip": "Sind Sie sicher, dass Sie den Shopify-Shop { shop_name } mit der E-Mail-Adresse { bind_email } an das DSers-Konto mit der E-Mail-Adresse { current_email } binden möchten?",
        "login_tip": "Bitte verbinden Sie sich erneut mit dem Shop",
        "bing_auth": "Ihre Autorisierung ist abgelaufen, bitte aktualisieren Sie die Seite, um die Autorisierung zu erneuern",
        "authAgin": "Ihre aktuelle Autorisierung ist abgelaufen. Bitte klicken Sie auf die Schaltfläche unten, um die Autorisierung zu aktualisieren und neu zu verknüpfen.",
        "auth_log": "Abmelden",
        "auth_entry": "Neu autorisieren",
        "bind_time_out": "Entschuldigung, die Verknüpfung des Shops wurde unterbrochen. Bitte klicken Sie auf die Schaltfläche unten, um die Verknüpfung erneut zu starten.",
        "relink": "Relink",
        "need_activate": "Der Shop war zuvor aufgrund der Planbegrenzung ausgeblendet. Bitte gehen Sie auf die Abonnement-Seite, um den entsprechenden Shop zu aktivieren.",
        "activate": "Aktivierung"
    }
}