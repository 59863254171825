import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import useTopBanner from '../../hooks/useTopBanner';
import styles from './AliShippingBanner.less';

function checkAdBanner(pathname) {
  let allow = false;
  const allowPath = [
    //'/storemanage/import',
    //'/storemanage/myproduct',
    //'/home',
    //'/order/list',
    //'/supplier_optimizer',
    // '/csv'
  ];

  // eslint-disable-next-line
  for (const i of allowPath) {
    if (pathname.indexOf(i) > -1) {
      allow = true;
      break;
    }
  }

  return allow;
}

function AliShippingBanner({ style, path, disableCheck, lang }) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (disableCheck) {
      setVisible(true);
      return;
    }

    setVisible(checkAdBanner(path));
  }, [path, disableCheck]);

  if (!visible) {
    return null;
  }

  return (
    <div className={styles.bannerLayout}>
      <div className={styles.banner} style={style}>
        {lang === 'fr-FR' ? (
          <>
            <div className={styles.left}>
              <div className={styles.header}>
                <span className={styles.title}>
                  AliExpress effectue des changements à propos des méthodes de
                  livraison disponibles.
                </span>
                <br />
                <span className={styles.title2}>
                  Faites attention aux méthodes de livraisons que vous
                  sélectionnez.
                </span>
              </div>
            </div>
            <a
              href="https://www.dsers.com/blog/aliexpress-shipping-methods-changes/"
              target="_blank"
              rel="noopener noreferrer"
              data-track-category="topBanner"
              data-track-action="ali-shipping"
            >
              <button type="button">En savoir plus</button>
            </a>
          </>
        ) : (
          <>
            <div className={styles.left}>
              <div className={styles.header}>
                <span className={styles.title}>
                  AliExpress is making changes to the shipping methods
                  available.
                </span>
                <br />
                <span className={styles.title2}>
                  Pay attention to the shipping methods you select to place
                  orders.
                </span>
              </div>
            </div>
            <a
              href="https://www.dsers.com/blog/aliexpress-shipping-methods-changes/"
              target="_blank"
              rel="noopener noreferrer"
              data-track-category="topBanner"
              data-track-action="ali-shipping"
            >
              <button type="button">Learn more</button>
            </a>
          </>
        )}
      </div>
    </div>
  );
}

export default AliShippingBanner;
