import React from 'react';
import { connect } from 'dva';
import { Modal, Form, Button, Input, message } from 'antd';

import intl from 'react-intl-universal';

import { emailRegExp } from 'utils/constants';

const btnStyle = { textAlign: 'center' };
const formStyle = { width: '70%', margin: '30px auto 0' };

// eslint-disable-next-line no-empty-pattern
@connect(({}) => ({}))
@Form.create()
export default class SendResetEmail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sendLoading: false,
      emailInvalid: false
    };
  }

  componentWillUnmount() {
    clearTimeout(this.timeId);
  }

  subEmail = () => {
    // 发送邮件
    const { form, dispatch } = this.props;
    form.validateFields({ force: true }, (err, values) => {
      if (!err) {
        this.setLoading();
        dispatch({
          type: 'partner/setPasswordEmail',
          payload: {
            data: values,
            callback: d => {
              if (d.code === 2000 || d.code === 2010) {
                this.timeId = setTimeout(() => {
                  message.success(intl.get('send_reset_email.send_succ'));
                  this.setState({ sendLoading: false });
                }, 5000);
              } else if (d.code === 4040) {
                this.setState(
                  {
                    emailInvalid: true,
                    sendLoading: false
                  },
                  () => {
                    form.validateFields({ force: true });
                  }
                );
              } else {
                this.setState({ sendLoading: false });
              }
            }
          }
        });
      }
    });
  };

  setLoading = () => {
    this.setState({
      sendLoading: true
    });
  };

  render() {
    const { form, showSendResetEmail, hideSendResetEmail } = this.props;
    const { getFieldDecorator } = form;
    const { sendLoading, emailInvalid } = this.state;
    return (
      <Modal
        title={intl.get('send_reset_email.title')}
        visible={showSendResetEmail}
        footer={null}
        onCancel={hideSendResetEmail}
      >
        <p>{intl.get('send_reset_email.tip')}</p>
        <Form style={formStyle}>
          <Form.Item>
            {getFieldDecorator('email', {
              rules: [
                {
                  pattern: emailRegExp,
                  message: intl.get('send_reset_email.email_not_valid')
                },
                {
                  required: true,
                  message: intl.get('send_reset_email.email_empty')
                },
                {
                  validator: (rule, value, callback) => {
                    if (emailInvalid) {
                      callback(intl.get('send_reset_email.email_unknown'));
                    } else {
                      callback();
                    }
                  }
                }
              ]
            })(
              <Input
                size="large"
                onChange={this.clearInvalMessage}
                placeholder={intl.get('send_reset_email.placeholder')}
              />
            )}
          </Form.Item>
        </Form>
        <p>
          {intl.get('send_reset_email.dec')}{' '}
          <a href="mailto:support@dsers.com">
            {intl.get('send_reset_email.contact_us')}
          </a>
          .
        </p>
        <p style={btnStyle}>
          <Button
            type="primary"
            onClick={this.subEmail}
            loading={sendLoading}
            size="large"
          >
            {intl.get('send_reset_email.email_instructions')}
          </Button>
        </p>
      </Modal>
    );
  }
}
