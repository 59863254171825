export default {
  paypal: {
    auto_sync_track_to_paypal:
      'Auto-sync the tracking numbers to PayPal! Go to Setting-Tracking to check.',
    paypal_authorization_title: 'PayPal authorization',
    paypal_authorization_content: 'The system has detected that your authorized PayPal account is not the same as the account bound to Shopify.<br/>This will cause the Tracking Number of your order to fail to sync to PayPal.Please change it<br/>If you already changed, please ignore the reminder, click below to no longer see this',
    paypal_change: 'Go to change',
    disimiss: 'Disimiss',
    paypal_account: 'About PayPal Account',
    paypal_reminder_content: 'The system detected that your associated PayPal account authorization has expired. For a better experience, please re-authorize your PayPal',
    no_reminder: `Don't remind me`,
    renew: 'GO TO RE-AUTHORIZE',
    cancel: 'Cancel'
  }
};
