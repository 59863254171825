import React from 'react';
import { OrderQuantityToolTip } from 'features/components'

/**
 * 
 * @param {*} data children数据
 * @param {number} type 平台类型  1:unmapping  2:ae 4:tmall 5:agency
 * @param {number} tabStatus tab状态 
 * @returns 
 */
export const PromptForWholeOrderComponent = ({ data, type = 2, tabStatus = 2 }) => {
  
  let Map = {
    1:0,
    2:0,
    4:0,
    5:0
  }

  let quantity = data.ext.lineitems.filter(dataItem => {
    return  dataItem?.platform_type != type && dataItem?.is_black_list !== 2 
  }).length;

  data.ext.lineitems.forEach(dataItem => {
    Object.keys(Map).forEach(mapItem => {
      if(dataItem?.is_black_list === 2){
        return
      }
      if(dataItem?.platform_type == mapItem){
        Map[mapItem] = ++Map[mapItem]
      }
    })
    if(dataItem.platform_type >= 5){
      if(dataItem?.is_black_list === 2){
        return
      }
      Map[5] = ++ Map[5]
    }
  });

  if(!window.CAINIAO_USER){
    Map[5] = 0
    let total = data.ext.lineitems.filter(dataItem => {
      return dataItem?.platform_type == 5 && dataItem?.is_black_list !== 2
    }).length
    quantity = quantity - total
  }

  if(!window.TMALL_USER){
    Map[4] = 0
    let total = data.ext.lineitems.filter(dataItem => {
      return dataItem?.platform_type == 4 && dataItem?.is_black_list !== 2
    }).length
    quantity = quantity - total
  }

  if(quantity<=0 || ( tabStatus != 7 ? data.showSave : false )){
    return null;
  }

  return <div><OrderQuantityToolTip Map={Map} quantity={quantity} type={type} data={data} /></div>
}