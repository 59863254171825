
    export default 
    {
    "paypal": {
        "auto_sync_track_to_paypal": "¡Sincroniza automáticamente los números de seguimiento con PayPal! Dirígete a Ajustes-Seguimiento para verificar.",
        "paypal_authorization_title": "Autorización de PayPal",
        "paypal_authorization_content": "El sistema ha detectado que tu cuenta de PayPal autorizada no es la misma que la cuenta vinculada a Shopify. <br/>Esto hará que el número de seguimiento de tu pedido no se sincronice con PayPal. Por favor,<br/> cámbiala. Si ya lo has hecho, por favor, ignora la advertencia. Haz clic abajo para no volver a ver este mensaje",
        "paypal_change": "Ir a cambiar",
        "disimiss": "Descartar",
        "paypal_account": "Acerca de la cuenta PayPal",
        "paypal_reminder_content": "El sistema ha detectado que la autorización de tu cuenta PayPal asociada ha caducado. Para una mejor experiencia, por favor, vuelve a autorizar tu cuenta PayPal",
        "no_reminder": "No recordar",
        "renew": "Ir a reautorizar",
        "cancel": "Cancelar"
    }
}