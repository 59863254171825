export default {
  about: {
    title: 'Bem vindo',
    description: `No DSers, acreditamos que todo mundo merece um método de pedido rápido e eficiente.
                  Eficiência e inovação são nosso principal objetivo e nos esforçamos para remover quaisquer barreiras ou dificuldades técnicas que possam impedir que os dropshippers vendam mercadorias e fechem pedidos.
                  Estamos animados em ajudá-lo em sua jornada!`,
    our_story: 'Nossa história',
    our_story_1: `DSers iniciou em 2018. Após experimentar diversos softwares de gerenciamento de pedidos,
                  nós percebemos que a velocidade das gestoras de pedidos existentes não era suficiente para aliviar o trabalho repetitivo do Dropshipper.
                  Traditional order processing services are too complex, time consuming and costly to manage.`,
    our_story_2: `Criamos o site DSers.com e consideramos o ponto de vista do usuário.
                  Queremos fornecer uma plataforma que não exija que você gaste horas processando pedidos.
                  Mantemos a simplicidade para que os usuários possam se concentrar em melhorar seu marketing e sua marca.
                  E o mais importante, totalmente grátis. Você pode configurar a triagem de preços de logística para reduzir custos,
                  gerenciar sua mercadoria ou iniciar o processamento em lote dos seus pedidos.`,
    our_story_3: `Após observar um aumento na demanda para soluções em e-commerce,
                  nós desenvolvemos a única plataforma totalmente funcional e gratúita,
                  que permite aos Dropshippers administrar seus pedidos e itens a venda.`,
    our_story_4: `Hoje, nós temos orgulho em apoiar indivíduos e equipes em todo o mundo.
                  Todo Dropshipper deve ter uma plataforma que simplifique as coisas e libere-os para fechar pedidos,
                  e estamos felizes em ver que você investe mais tempo aumentando o volume dos seus negócios.`,
    our_values: 'Nóssos Valores',
    our_values_1: `Todos devem ter um software de gerenciamento de pedidos que realmente consiga economizar tempo e custo.
                  Acreditamos que todos devem ter um fluxo de trabalho tranquilo.
                  Se você consegue clicar, consegue utilizar nosso software gratuíto para o gerenciamentos de pedidos em massa.`,
    our_values_2: 'Nossos clientes significam o Mundo',
    our_values_3: `Na DSers, nos esforçamos para fornecer os produtos que nossos clientes precisam.
                  Uma grande parte do nosso processo de Brainstorming foca em revisar o Feedback de nossos
                  clientes para garantir que você seja bem tratado e desenvolvamos novos recursos que você precisa.`,
    our_values_4: 'Manufatura Interna',
    our_values_5: `Estamos pessoalmente comprometidos em fornecer o melhor serviço. Do atendimento ao cliente ao Design e Desenvolvimento de produtos,
                  tudo é fornecido pela nossa equipe (fofinha) e profissional.`
  }
};
