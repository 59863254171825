export default {
  help_center: {
    help_center: {
      fixed_header: `Centre d'aide`,
      latest_news: 'Dernières nouvelles',
      load_more: 'Voir plus',
      online_chat: 'Chat en ligne sur Messenger',
      online_time: 'En ligne de 7h à minuit (UTC +8)',
      work_days: '7 jours sur 7',
      message_us: 'Contactez nous',
      email_report: 'Email: Rapporter un problème',
      email_report_desc: `Nous répondrons en <span style="font-weight: 700">moins d'une journée</span>.
        Si vous n'avez pas reçu de réponse de notre part, veuillez vérifier le dossier spam, et assurez-vous que dsers.com est dans votre liste blanche email.`,
      product_sourcing: 'Email: Approvisionnement de produit',
      product_sourcing_desc: `Nous répondrons en <span style="font-weight: 700">moins d'une journée</span>.
        Veuillez indiquer votre nom de compte Dsers, l'url de votre boutique,
        le lien du produit AliExpress comme référence, votre méthode de livraison voulue, et le volume de vente estimé sur 7 jours.`,
      blog_in_en: 'Blog in English',
      everything_about_ds: 'Tous savoir sur DSers',
      blog_in_fr: 'Blog en Français',
      coming_soon: 'Bientôt…',
      blog_in_pt: 'Blog em Português',
      youtube_channel: 'Chaine Youtube',
      check_ds_youtube: 'Voir la chaine Youtube DSers'
    },
    entrance: {
      get_support: `Besoin d'aide`,
      online_chat: 'Chat en ligne'
    },
    question_modal: {
      title: 'Comment pouvons-nous vous aider?',
      tell_tip: 'Veuillez nous indiquer quel type de problème vous rencontrez',
      tell_select_placeholer: 'Choisir dans la liste',
      our_suggestions: 'Voici nos suggestions',
      useful_tip: 'Voici quelques conseils pratiques:',
      suggesttions_default_tip: `Nous vous fournirons des conseils en fonction <br />du problème sélectionné.
        Si cela n'aide pas,<br /> vous pouvez obtenir de l'aide en ligne<br /> en clique le bouton ci-dessous.`,
      get_online_help: `Obtenir de l'aide en ligne`
    },
    detail: {
      by_ds_team: `par l'équipe Dsers`,
      ds_users: 'Cher marchand',
      so_much: `Merci d'avoir choisi Dsers. <br /> Nous espérons que vous passez une bonne journée.`,
      to_contact: `Si vous avez des questions, <br />n'hésitez pas à nous contacter.`,
      happy_with_ds: 'Bon drophipping avec DSers!',
      best_regards: `Sincèrement, <br />l'équipe DSers`
    },
    support_here: `Obtenir de l'aide en ligne ici`
  }
};
