import React from 'react';
import DocumentTitle from 'react-document-title';
import { connect } from 'dva';
import qs from 'qs';
import intl from 'react-intl-universal';

// import Register from './Register';
import ForgotPassword from './ForgotPassword'
import PartnerItem from '../../components/PartnerItem/index'
import Logo from '../../components/NewLogo'
import { pageTitle } from 'utils/constants';
import styles from './SignInPage.less';

const SignInPage = ({ dispatch }) => {

  return (
    <DocumentTitle title={`Sign up - ${pageTitle}`}>
      <div className={styles.signIn}>
        <div className={styles.top}>
          <a className={styles.logo} target="_blank" href='/'>
            <img src='https://img.dsers.com/images/logo.png' />
          </a>
        </div>
        <div className={styles.ResetcontainerBox}>
          <div className={styles.container}>
            <div className={styles.container_fix}>
              <div className={styles.start_n}></div>
              <div className={styles.start_t}></div>
              <div className={styles.text_box}>
                <p className={styles.text_title}>{intl.getHTML('login.text_title', {
                  titlek: styles.titlek
                })}</p>
                <p className={styles.text_desc}>{intl.getHTML('login.text_desc', {
                  titlek: styles.titlek
                })}</p>
              </div>
              <div className={styles.box}>
                <ForgotPassword />
              </div>
            </div>
            {/* <PartnerItem /> */}
          </div>
        </div>
      </div>
    </DocumentTitle>
  );
};

export default connect()(SignInPage);
