
    export default 
    {
    "product": {
        "dsers_verified_banner": "<h2>Dsers<span> Verificado</span>. Proyecto de Proveedores Ali </h2><p> con Soluciones de Pago. No más tarjetas de crédito bloqueadas</p>",
        "dsers_verified_banner_btn": "MÁS INFORMACIÓN",
        "search_placeholder": "Buscar Mi Producto aquí",
        "search_btn": "OK",
        "tab_all": "Todo <i>({all_num})</i>",
        "tab_aliexpress": "AliExpress <i>({ae_num})</i>",
        tab_tmall: "Tmall <i>({tm_num})</i>",
        "tab_other": "Productos no asignados <i>({map_num})</i>",
        "tab_verified": "Verificado",
        "btn_import_shopify_products": "Importar productos de Shopify",
        "btn_import_shopify_products_tip": "Actualmente, Dsers sólo sincroniza los productos pedidos en Shopify. Puedes importar otros productos haciendo clic en el botón Gestionar Producto en Dsers.",
        "btn_import_shopify_products_tip_link": "Ver este producto",
        "select_source": {
            "all": "Todo",
            "unPushed": "Sin empujar",
            "pushed": "Empujado"
        },
        "push_btn": "Empuje",
        "delete_btn": "Borrar",
        "delete_selected_products": "Borrar los productos seleccionados",
        "hide_selected_products": "Ocultar los productos seleccionados",
        "product": {
            "remove_from_shopify": "Eliminado de Shopify",
            "tip_deleted_on_Shopify": "Este producto ha sido eliminado en Shopify. Sin embargo, los pedidos relacionados con este producto aún pueden ser procesados en Dsers. Puedes eliminar este producto haciendo clic en el icono de la papelera si deseas eliminarlo definitivamente.",
            "hidden": "Oculto",
            "tip_hidden": "Este producto ha sido ocultado. No podrás ver este producto en tus pedidos y no se procesará a AliExpress al gestionar pedidos. Puedes hacer clic en el botón Ojo para dejar de ocultarlo.",
            "verified_btn_apply": "APLICAR",
            "verified_btn_edit": "EDITAR",
            "verified_btn_enable": "HABILITAR",
            "sample_on_way": "Tu producto de muestra está en camino",
            "piece_tip": "El coste es el precio que aparece en la página del proveedor de AliExpress. Este puede ser diferente del que estás viendo en AliExpress, ya que Dsers muestra el precio básico independientemente del país al que se envíe.",
            "piece": "pieza",
            "processing": "Procesamiento de 24 horas",
            "cost": "Coste",
            "price": "Precio",
            "orders": "pedidos",
            "stock": "Stock",
            "delete": "Borrar",
            "check_details": "Consultar detalles",
            "edit_details": "Editar producto",
            "open_in_shopify": "Abrir en Shopify",
            "publish_firsh": "Publicar primero y luego abrir en Shopify",
            "split": "Split product",
            "hidden_tip": "Haz clic para eliminar producto de la lista negra. Verás el producto en la página de pedidos.",
            "hidden_open_tip": "Haz clic para ocultar este producto y no procesarlo con tus pedidos.",
            "mapping_Page": "Página de mapeo",
            "mapping_tip": "Publicar primero y luego añadir proveedores",
            "push_to_shopify": "Empujar a Shopify",
            "import_to_store": "Importar a la tienda",
            "no_out_stock": "Variante fuera de stock",
            "multiple": "Múltiple",
            "download_all_img": "DOWNLOAD ALL"
        },
        "manage_product": {
            "title": "Select product and set up shipping method",
            "title1": "Import Shopify Products",
            "search_placeholder": "Buscar productos...",
            "no_data": "No hay datos",
            "syncing": "Sincronizando",
            "import_btn": "Importar",
            "select_max_tip": "Sólo puedes importar como máximo 10 productos a la vez",
            "import_max_tip": "Como máximo puedes importar 10 productos de forma masiva a la vez",
            "import_shopify_Copywriting": "¡Ups! Parece que la carga ha tardado demasiado, por favor inténtalo de nuevo",
            "bulk_import": "Si tienes que importar un gran número de artículos, puedes <a href=\"https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2FDSersOfficial\" target=\"_blank\" rel=\"noopener noreferrer\">contactar con el servicio</a> de atención al cliente y te ayudaremos",
            "nombre": "If you have a large number of items to import, you can <a href=\"https://www.messenger.com/t/DSersOfficial\" dd=\"src\" target=\"_blank\">contact customer service</a> and we will help you."
        },
        "visible_succ": "Operación exitosa",
        "already_imported": "Ya has importado este producto, no puedes volver a importarlo",
        "copy_first_pushed": "Lanzamiento fallido, el producto necesita ser empujado/publicado en Shopify primero",
        "succ_copy": "Copiado con éxito",
        "copy_failed": "Copia fallida",
        "update_succ": "¡Actualización exitosa!",
        "delete_tip": "¿Estás seguro(a) de que quieres eliminar este producto?",
        "delete_pushed_tip": "<span className={ styles.deleteConfirm }>Los productos eliminados<strong> NO PUEDEN SER RECUPERADOS </strong>y serán <strong>BORRADOS DE TU TIENDA SHOPIFY.</strong> ¿Deseas continuar?</span>",
        "delete_confirm": "¿Estás completamente seguro de querer realizar esta acción? ¡Es IRREVERSIBLE y tu(s) producto(s) será(n) borrado(s) de tu tienda de Shopify también!",
        "input_confirm_tip": "Por favor, presiona CONFIRMAR para eliminar.",
        "open_mapping_tip": "Publica primero y luego añade los proveedores",
        "no_data": {
            "add_shopify_store": {
                "title": "Añadir una tienda de Shopify",
                "description": "Añade una(s) tienda(s) Shopify a Dsers haciendo clic en Vincular a Shopify para gestionar tus productos. <br />Mapea un producto de AliExpress antes de realizar múltiples pedidos"
            },
            "is_sync": "Se está sincronizando la información de Shopify, por favor espera a que se complete la sincronización.",
            "no_data": "Comienza tu negocio con Dsers haciendo clic",
            "no_data_end": "¡consultar cómo importar productos de AliExpress a Dsers!",
            "has_store": {
                "title": "Dsers sólo mostrará y sincronizará automáticamente los productos pedidos en Shopify.<br /> Pero no te preocupes, aún puedes importar los productos que quieras mostrar en Dsers desde tu lista de productos de Shopify haciendo clic aquí<br />",
                "btn": "Gestionar producto"
            },
            no_product_tip_1:`1. Haz clic en "Importar Producto de Shopify" en la esquina superior derecha para importar productos de Shopify a DSers`,
            no_product_tip_2:`
            2. Asigna los productos importados para que puedas procesar los pedidos más rápidamente`
        },
        "visible_progress_title1": "Los productos son visibles en tu tienda de Shopify.",
        "visible_progress_title2": "Realizando pedido en AliExpress...",
        "show_supply_chain": {
            "btn": "MÁS INFORMACIÓN",
            "description": "No más tarjetas de <br/> crédito bloqueadas Entrega 100% garantizada <br/> Envío en 3 días <br/> Servicio de atención al cliente en tiempo real <br/>",
            "title": "Proyecto de Proveedores de Ali Verificados de Dsers"
        },
        "show_balance_no": {
            "btn": "EFECTUAR PAGO",
            "tip": "Saldo disponible de Dsers",
            "title": "No hay saldo disponible <br/> en tu cuenta"
        },
        "show_address_no": {
            "btn": "Ir a Ajustes",
            "tip": "Por favor, configura la información de tu dirección y asegúrate de que el saldo sea mayor que 0."
        },
        "show_set_visible": {
            "tip": "Publicar también en la tienda online",
            "continue_selling": "Seguir vendiendo cuando se agoten las existencias",
            "description": "Los productos serán empujados a la tienda de Shopify y ya no podrán ser editados en Dsers. Pero todavía podrás gestionar los proveedores",
            "cancel": "Cancelar",
            "yes": "Sí"
        },
        "hide_prod_tip": "¿Estás seguro de ocultar el producto? Los artículos del pedido relacionados con este producto no se mostrarán en Dsers y ya no podrás sincronizar los artículos ocultos con Dsers.",
        "confirm_prod_tip": "Por favor, presiona CONFIRMAR para ocultar el producto.",
        "possible_cause": "Posible causa:",
        "search_notice_for_migrate": "Por favor, no ingreses la uro aquí. Si deseas importar productos, dirígete a la lista de importación",
        "search_msg_for_migrate": "Por favor, dirígete a la lista de importación para importar productos. Haz clic <a href=\"https://help.dsers.com/import-list-for-shopify/\" target=\"_blank\"><strong>aquí para</strong></a> obtener más información.",
        "filter_push_title": "Filtrar por empuje",
        "to_push_shopify": "Ya empujado a Shopify",
        "no_push_shopify": "No se ha empujado a Shopify",
        "selectAll": "Selecciona todos",
        "hide": "Ocultar",
        "del": "Borrar",
        "myProducts": "Mis productos",
        "input_confirm_tip2": "Por favor, presiona CONFIRMAR para actualizar.",
        "introduce_con1": "You are about to delete the selected product(s) from DSers. But the products will be kept on Shopify.",
        "introduce_con2": "You are about to delete the selected product(s) from DSers. And the products will also be deleted from Shopify store.",
        "introduce_checkbox": "Delete this product from my Shopify store too.",
        "introduce_ok": "CONFIRMAR",
        "introduce_cancle": "Cancel",
        "cost_ible": "Cost $ ——",
        "loading": "Replacing",
        "error": "Failed to replace",
        "success": "Successfully replaced",
        "collections": "Search the full collection's title.(Case insensitive)",
        "product_type": "Seleccionar o crear uno Shopify tipo",
        "tags": "Seleccionar o crear uno Shopify etiqueta",
        "vendor": "Seleccionar o crear proveedor",
        "filterByMapping": "Filter by Mapping",
        "basic_mapping": "Basic",
        "standard_mapping": "Standard",
        "advance_mapping": "Advanced",
        "bogo_mapping": "BOGO",
        "bundle_mapping": "Bundle",
        "productOutOfStock": "Product out of stock",
        "productNotFound": "Product not found",
        "filterByNotification": "Filtrar por notificación",
        "filterByHidden": "Filtrar por estado",
        "filterByVariants": "Variants out of stock",
        "filterBySku": "El SKU ha cambiado en AliExpress",
        "filterByGone": "Productos retirados del proveedor",
        "filterByHid": "Productos ocultos",
        "filterByShopifyDelete": "Eliminado de Shopify",
        "is_main_product": "Original unsplit product",
        "updatePrice": "While checking the data, we found that the price of the product you migrated from Oberlo was originally a non-US price, but due to an internal error, the price was migrated as a US price. We are very sorry for this.\n    In order to correct this error in time and not cause trouble and loss to your subsequent operations, we now provide a dedicated feature to modify the products in your account to the price of the destination country you want. (Only items with U.S. prices can be modified, and each item can only be modified once. If you do not need this service, you can ignore this message. This feature will only be available for one month)",
        "update": "Update price",
        "chooseCountry": "Choose a country",
        "chooseSub": "Select the country for which you want to update prices",
        "update_desc_wait": "products are being updated",
        "update_desc_ok": "updates successful",
        "update_desc_fail": "updates failure",
        "error_one": "Your product is being updated, as your account is being accessed by someone else and the update operation has been performed",
        "error_two_title": "None of the products you selected can be updated. ",
        "error_two_sub": "1. The price of the product is not set for the United States. 2. Your product is being updated, as your account is being accessed by someone else and the update operation has been performed 3. The product has been updated already.",
        "selectCountry": "Select country",
        "price_for": "Precio para",
        "collection_title": "Cobros",
        "type_title": "Tipo",
        "tags_title": "Etiquetas",
        "vendor_title": "Proveedor",
        "productLimitTitle": "Límite de productos Shopify",
        "productLimitSub": "El número de productos que se pueden importar en Mis productos.",
        "aeLimitTitle": "Límite de productos del proveedor",
        "aeLimitSub": "Suma de los productos importados en la lista de importación y el número de productos en el mapeo de Mis productos.",
        "productCostToShopify": "Enviar coste del producto a Shopify",
        'productCostAndShippingCostToShopify':'Envíe el producto y el coste de envío a Shopify',
        "productCostAe": "Enviar el coste del producto proveedor por artículo a Shopify cuando se empuje el producto.",
        remove_all_imgs:'remove all images'
    }
}