
    export default 
    {
    "reward": {
        "title": "Temporada de recompensas Dsers",
        "intro": "Obtén más de $100 de recompensa al usar productos recomendados por el Programa Suministro Masivo de Dsers. Haz clic <a href=\"https://www.blog.dserspro.com/post/rules-of-dsers-reward-season\" target=\"_blank\" rel=\"noopener noreferrer\">aquí para</a> saber cómo ganar. Por favor, contacta con nuestro equipo de soporte si tienes alguna duda.",
        "dollar_100": "Obtén $100 y más desde el 15 de octubre hasta el 15 de enero.",
        "dollar_100_intro": "Para obtener la recompensa de $100.00,<br /> deberás realizar 1000 pedidos válidos durante el evento de los 100 días.<br /> El número se volverá verde cuando llegues a la meta.",
        "get_more_title": "Gana más haciendo más pedidos con productos en la página de Contraoferta de Productos, desde el 15 de octubre al 15 de enero",
        "total_title": "Cuánto has ganado en total durante el evento",
        "request_btn": "Solicitar recompensa",
        "requested_btn": "Solicitado",
        "requested_intro": "Este botón se podrá pulsar el 15 de febrero, UTC +8, si has ganado algo de dinero",
        "form_title": "Por favor, ingresa tu información a continuación, nuestro equipo se pondrá en contacto contigo pronto.",
        "feedback": "El equipo se pondrá en contacto contigo pronto.<br /> Gracias por tu paciencia."
    }
}