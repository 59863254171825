import React, { useEffect, useCallback, useState } from 'react';

import RecommendTag from './RecommendTag';

const duration = 24 * 60 * 60 * 1000; // 24 hours
// const duration = 60 * 1000; // 60 s

const updateStorage = (storeId, spId) => {
  let tmp = null;

  try {
    tmp = JSON.parse(localStorage.getItem('order_fail_banner'));
  } catch (error) {
    console.log('updateStorage error:', tmp);
  }

  if (!tmp) {
    tmp = {
      [storeId]: {
        [spId]: Date.now()
      }
    };
  }

  if (tmp[storeId]) {
    tmp[storeId][spId] = Date.now();
  } else {
    tmp[storeId] = { [spId]: Date.now() };
  }

  window.localStorage.setItem('order_fail_banner', JSON.stringify(tmp));
};

function EnableCloseTag({ spId, storeId, onLinkClick, ...props }) {
  const [visible, setVisible] = useState(true);

  // useEffect(() => {
  //   try {
  //     const tmp = JSON.parse(localStorage.getItem('order_fail_banner'));
  //     if (!tmp) {
  //       setVisible(true);
  //       return;
  //     }

  //     // 不存在 store
  //     if (!tmp[storeId]) {
  //       setVisible(true);
  //       return;
  //     }

  //     // 存在 store, 但是没有 product id
  //     if (tmp[storeId] && !tmp[storeId][spId]) {
  //       setVisible(true);
  //       return;
  //     }

  //     // 大于 24 hours
  //     if (Date.now() - tmp[storeId][spId] > duration) {
  //       setVisible(true);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [spId, storeId]);

  const handleClose = useCallback(() => {
    setVisible(false);
    // updateStorage(storeId, spId);
  }, []);

  const handleClick = useCallback(
    item => {
      onLinkClick(item);
      // updateStorage(storeId, spId);
    },
    // eslint-disable-next-line
    []
  );

  // if (!visible) {
  //   return null;
  // }

  return (
    <RecommendTag {...props} onClose={handleClose} onLinkClick={handleClick} />
  );
}

export default EnableCloseTag;
