import {
  getList,
  changeChecked,
  postCloseBottom,
  getModalList
} from '../services/fulfillmentcenter';

export default {
  namespace: 'fulfillmentcenter',

  state: {},

  effects: {
    *list({ payload }, { call }) {
      yield call(getList, payload);
    },
    *status({ payload }, { call }) {
      yield call(changeChecked, payload);
    },
    *modallist({ payload }, { call }) {
      yield call(getModalList, payload);
    },
    *closeBottom({ payload }, { call }) {
      yield call(postCloseBottom, payload);
    }
  },

  reducers: {}
};
