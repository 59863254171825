import { fetch } from 'dva';
export default class CollectSDK {

  static ListenChildNode = ['button', 'a'];

  //调试模式
  static debug = false;

  //任务队列
  static QUEUE = [];

  //版本
  static version = 3;

  //收集数据信息
  static Map = {
    "name": "page_view",
    "timestamp": parseInt(+new Date() / 1000),
    "module":'common',
    "params":[]
  }

  defaultUserProps = {
    first_visit_time:'',
    _clck:this.getCookie('_clck') || '',
    _da:localStorage.getItem('_da') || '',
    _ga:this.getCookie('_ga') || '',
  }

  static defaultData = {
    event_category:'web',
    event_type:'normalize',
    event_label:'collect',
    trace_id: '',
    log_create_time: 0,
    custom_data:{}
  }

  static defaultKey = [
    "data-event",
    "data-module",
    "data-action",
    "data-action-description",
  ]

  constructor(options) {

    //兼容老逻辑
    let firstTime = localStorage.getItem('first_visit_time');
    this.defaultUserProps.first_visit_time = Number(firstTime)

    if('app_info' in options){
      this.app_info = options.app_info

      //留个口子 方便扩展
      CollectSDK.defaultData.event_category = options.app_info.platform || 'web'
    }

    CollectSDK.version = options.version || CollectSDK.version

    //初始化用户
    if('user' in options){
      this.user = {
        ...options.user,
        ...this.defaultUserProps
      }
    }else{
      if(localStorage.getItem('userInfo')){
        try{
          this.user = {
            ...JSON.parse(localStorage.getItem('userInfo')),
            ...this.defaultUserProps
          }
        }catch(e){
          throw new Error('本地用户数据存储格式有误！')
        }
      }else{
        this.user = this.defaultUserProps
      }
    }

    //环境配置
    this.env = options.env || "development";

    //默认上报参数
    if('options' in options){
      this.config = options.options
    }else{
      //不需要默认上报 自定义指定
      this.config = null;
    }

    //信息调试
    if('debug' in options){
      CollectSDK.debug = options.debug
    }

    //初始化设备信息
    this.device = {
      category: this.getIsMobile() ? 'mobile' : 'pc',
      user_agent: navigator.userAgent,
      language: (navigator.language || navigator.browserLanguage).toLowerCase(),
      web_info: {
        browser: this.getBrowserType(),
        browser_version: this.getBrowserNameVersion()
      }
    };

    //初始化网页信息
    this.flushConfig()

    if('onPageShow' in options){
      if(typeof options.onPageShow === 'function'){
        this.onPageShow = options.onPageShow
      }else{
        throw new Error('onPageShow is must function')
      }
    }else{
      if(!this.config){
        throw new Error('如果不指定options选项, 必须设置 onPageShow 参数， 后续再实现onPageShow功能')
      }
    }

    //用户页面首次打开
    this.pageShow();
    //监听路由变动
    this.listenRouterPage();
    //监听用户点击行为
    this.listenClick();

  }

  //获取cookie
  getCookie(cookie_name) {
    var allcookies = document.cookie;
    var cookie_pos = allcookies.indexOf(cookie_name);

    if (cookie_pos != -1) {
      cookie_pos = cookie_pos + cookie_name.length + 1;
      var cookie_end = allcookies.indexOf(';', cookie_pos);

      if (cookie_end == -1) {
        cookie_end = allcookies.length;
      }
      var value = unescape(allcookies.substring(cookie_pos, cookie_end));
    }
    return value;
  };

  //获取浏览器类型
  getBrowserType() {

    var userAgent = navigator.userAgent;
    if(userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1){
      return 'Opera';
    }else if(userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1){
      return 'IE';
    }else if(userAgent.indexOf("Edge") > -1){
      return 'Edge';
    }else if(userAgent.indexOf("Firefox") > -1){
      return 'Firefox';
    }else if(userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1){
      return 'Safari';
    }else if(userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1){
      return 'Chrome';
    }else if(!!window.ActiveXObject || "ActiveXObject" in window){
      return 'IE>=11';
    }else{
      return 'Unkonwn';
    }
  }

  //获取浏览器版本号
  getBrowserNameVersion() {
    var Sys = {};
    var ua = navigator.userAgent.toLowerCase();
    var s;
    /* eslint-disable */
    (s = ua.match(/rv:([\d.]+)\) like gecko/)) ? Sys.ie = s[1] :
    (s = ua.match(/msie ([\d\.]+)/)) ? Sys.ie = s[1] :
    (s = ua.match(/edge\/([\d\.]+)/)) ? Sys.edge = s[1] :
    (s = ua.match(/firefox\/([\d\.]+)/)) ? Sys.firefox = s[1] :
    (s = ua.match(/(?:opera|opr).([\d\.]+)/)) ? Sys.opera = s[1] :
    (s = ua.match(/chrome\/([\d\.]+)/)) ? Sys.chrome = s[1] :
    (s = ua.match(/version\/([\d\.]+).*safari/)) ? Sys.safari = s[1] : 0;
    // 根据关系进行判断
    if (Sys.ie) return ('IE: ' + Sys.ie);
    if (Sys.edge) return ('EDGE: ' + Sys.edge);
    if (Sys.firefox) return ('Firefox: ' + Sys.firefox);
    if (Sys.chrome) return ('Chrome: ' + Sys.chrome);
    if (Sys.opera) return ('Opera: ' + Sys.opera);
    if (Sys.safari) return ('Safari: ' + Sys.safari);
    return 'Unkonwn';
  }

  //是否是移动端
  getIsMobile(){
    const userAgentInfo = navigator.userAgent;
    const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
    const getArr = Agents.filter(i => userAgentInfo.includes(i));
    return getArr.length ? true : false;
  }

  //监听点击行为
  listenClick() {
    document.addEventListener('click', (e) => {
      CollectSDK.ListenChildNode.forEach(childItem => {
        document.querySelectorAll(childItem).forEach(nodeTag => {
          if(['SPAN','BUTTON','DIV', 'I'].includes(e.target.tagName)){
            if(nodeTag?.contains(e.target)){
              this.getAttributeData(nodeTag)
            }
            return
          }
          if('A' === nodeTag.nodeName){
            if(nodeTag?.contains(e.target)){
              this.getAttributeData(nodeTag)
            }
            return
          }
        })
      })
    });
  }

  getFilterTag(htmlTag){
    let filterTag =  CollectSDK.defaultKey.filter(item => htmlTag.some(item2 => item == item2));
    if(htmlTag.includes('data-custom-data')){
      filterTag = [...filterTag,'data-custom-data']
    }
    return filterTag
  }

  getArrDifference = (arr1, arr2) => {
    return arr1.concat(arr2).filter((v, i, arr) => {
      return arr.indexOf(v) === arr.lastIndexOf(v);
    })
  };

  //获取自定义属性
  getAttributeData(nodeTag){

    let htmlTag = nodeTag.outerHTML.match(/data(-[a-z]+)+/g) || [];
    let cusArr = [];
    let paramsData = this.getFilterTag(htmlTag);
    let fetchFlag = false

    if(paramsData.includes('data-custom-data')){
      if(!this.getArrDifference([...CollectSDK.defaultKey, 'data-custom-data'],paramsData).length){
        fetchFlag = true
      }
    }else{
      if(!this.getArrDifference(CollectSDK.defaultKey,paramsData).length){
        fetchFlag = true
      }
    }

    if(!fetchFlag){
      return
    }

    if(paramsData){
      paramsData.forEach(tagKey => {
        let value = nodeTag.getAttribute(tagKey);
        let newKey = tagKey.replace('data-','');
        //需要排除掉了自定义属性
        if(newKey == 'event' || newKey == 'module' || newKey == 'custom-data'){
          //不需要自定义收集
          if(newKey == 'event'){
            CollectSDK.Map['name'] = value
          }
          if(newKey == 'module'){
            CollectSDK.Map[newKey] = value
          }
          if(newKey == 'custom-data'){
              let paramsValue = JSON.parse(value)
              for(let key in paramsValue){
                if(typeof paramsValue[key] == 'object'){
                  throw new Error(`
                    custom-data value 必须是string | number

                    例子：
                    正确：
                    var Map = {
                      a: 1,
                      b: 2
                    }
                    错误：
                    var Map = {
                      a: {
                        b: 2
                      }
                    }
                  `)
                  return
                }

                cusArr.push({
                  key: key,
                  value: paramsValue[key]
                })
              }
          }
          return
        }

        cusArr.push({
          key: newKey,
          value: value
        })
      })

      CollectSDK.Map['params'] = cusArr
    }


    if(CollectSDK.debug){
      console.log(this)
    }

    let { onPageShow, config, defaultUserProps, set, ...reset} = this

    //如果之前没有绑定 就不用上报了
    if(cusArr.length){
      if(!this.config){
        this.onPageShow(reset)
        return
      }
      this.report()
    }
  }

  flushConfig() {
    this.web_info = {
      hostname: window.location.hostname,
      page_referrer: document.referrer || '',
      page_location: window.location.href
    }

    this.defaultUserProps = {
      first_visit_time:'',
      _clck:this.getCookie('_clck') || '',
      _da:localStorage.getItem('_da') || '',
      _ga:this.getCookie('_ga') || '',
    }
  }

  //监听路由变动
  listenRouterPage() {
    let _self = this;
    let defaultPageView = {
      "name": "page_view",
      "timestamp": parseInt(+new Date() / 1000),
      "module":'common',
      "params":[]
    }

    const _pushState = window.history.pushState;
    window.history.pushState = function (...args) {

      setTimeout(() => {

        if(CollectSDK.debug){
          console.log('上报一些默认行为 类似pageShow',
            window.location.pathname,
          );
        }

        _self.flushConfig()

        if(!_self.config){
          let { onPageShow, config, defaultUserProps, set , ...reset } = _self

          _self.onPageShow({
            ...reset,
            event: defaultPageView
          })
          return
        }

        _self.report(defaultPageView)
      }, 300);
      return _pushState.apply(this, args);
    };
  }

  //监听首次打开页面
  pageShow() {
    let { onPageShow, config, set , ...reset } = this

    window.addEventListener('pageshow', () => {
      this.event = CollectSDK.Map

      if(!this.config){
        this.onPageShow(reset)
        return
      }

      this.report()
    });
  }

  findItem = (curArr,oldArr) => {
    return curArr.concat(oldArr).filter(function(v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v);
    });
  }

  //自定义数据传递
  set = (params) => {
    let { onPageShow, config, defaultUserProps, set , ...reset } = this

    let arrCus = []

    //暂留队列 往后方便扩展
    CollectSDK.QUEUE.push(params);

    //设置新值  后期改动的 ---- 不建议这么做
    let newParams = {
      module:params.module,
      name: params.event,
      timestamp: parseInt(+new Date() / 1000),
      params:[]
    }

    let { custom_data } = params;

    Object.keys(params).forEach(keyItem => {
      if(keyItem == 'event' || keyItem == 'module' || keyItem == 'custom_data'){
        return
      }
      newParams['params'].push({
        key:keyItem,
        value: params[keyItem]
      })
    })

    if(custom_data){
      for(let key in custom_data){
        if(typeof custom_data[key] == 'object'){
          throw new Error(`
            custom-data value 必须是string | number

            例子：
            正确：
            var Map = {
              a: 1,
              b: 2
            }
            错误：
            var Map = {
              a: {
                b: 2
              }
            }
          `)
          return
        }
        arrCus.push({
          key: key,
          value: custom_data[key]
        })
      }
      newParams['params'].push(...arrCus)
    }

    if(!this.config){
      this.onPageShow({
        ...reset,
        event:newParams
      })
      return
    }

    this.report(newParams)
  }

  //主动上报
  async report(params){

    try{
      //暂时缺少兼容性代码
      const { onPageShow, config, defaultUserProps, set , ...reset } = this
      const { body, baseUrl,  ...headers} = config

      if(!baseUrl){
        throw new Error()
        return
      }

      //补充字段
      let bodyParams = {}
      if(config?.body){
        bodyParams = Object.assign(reset, config?.body)
      }else{
        bodyParams = reset
      }

      if(params){
        bodyParams.event = params
      }

      // 每个接口字段都不一致， 只有能确定的几个字段
      let userInfo = {
        id:'',
        email:'',
        register_at:0,
        status:0,
        first_visit_time:Number(localStorage.getItem('first_visit_time')) || 0,
        _clck:this.getCookie('_clck') || '',
        _da:localStorage.getItem('_da') || '',
        _ga:this.getCookie('_ga') || '',
      }

      if(localStorage.getItem('newUserInfo')){
        try {
          let user = JSON.parse(localStorage.getItem('newUserInfo'));
          userInfo = {
            id: user.user_id,
            email: user.email,
            register_at:user.created_at || 0,
            status:user.status || 0,
            first_visit_time: Number(localStorage.getItem('first_visit_time')) || 0,
            _clck:this.getCookie('_clck') || '',
            _da:localStorage.getItem('_da') || '',
            _ga:this.getCookie('_ga') || '',
          }
        }catch(e){}
      }

      const data = {
        ...CollectSDK.defaultData,
        version:CollectSDK.version,
        custom_data: {
          ...bodyParams,
          user: {
            ...bodyParams.user,
            ...userInfo
          }
        }
      }
      if(typeof navigator.sendBeacon === 'function'){
        navigator.sendBeacon(baseUrl, JSON.stringify(data));
      }else{
        fetch(baseUrl, {
          ...headers,
          body: JSON.stringify(data)
        });
      }
      CollectSDK.Map['params'] = []
    }catch(e){
      throw new Error(`collect error: ${e}`)
    }
  }
}