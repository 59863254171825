export default {
  mapping: {
    title: 'Marketplace',
    description:
      'Os detalhes do produto do Shopify (como título, descrição, imagens, variantes, etc.) permanecem os mesmos após adicionar um fornecedor.',
    description_tip:
      'Atenção: o mapeamento será perdido quando você editar / excluir / adicionar opções ou variantes no Shopify.If you change the name of a variant in Shopify, we will not modify the mapping in DSers, so make sure the variants are still corresponding to the new names you set.',
    tab_btn_basic_mapping: 'Básico',
    tab_btn_advanced_mapping: 'avançado', // add
    tab_btn_bogo: 'Compre um e leve outro de graça', // add
    tab_btn_bundle: 'Pacote', // add
    tab_btn_verified: 'Verified', //  delete
    shopify_product: 'Produto Shopify',
    default_ali_product: 'Produto Padrão {platform}',
    edit_shipping: 'Edit Shipping', // d
    set_shipping: 'Set Shipping', // d
    import_a_product: 'Import a product', // d
    standProductNum: 'Up to 5 AliExpress products can be imported',
    substitute_ali_product: 'Produto Substituto {platform}',
    repeat_alert: 'This supplier is already set as default or substitute supplier and cannot be set again',
    clear_tip_alert: `Se você alterar o item para defini-lo como o fornecedor primário, o fornecedor secundário ficará vazio.`,
    forbidden_alert: `Se você alterar o item para defini-lo como o fornecedor primário, o fornecedor secundário ficará vazio.`,
    switch_mapping: 'Switch between Default and Substitute supplier',
    second_product_tip:
      'Second option is an option to be used during auto order processing when some variants are not available for defualt option.', // d
    alternate_products: 'Produtos alternativos',
    alternate_products1: 'Add Products',
    import_placeholder: 'Importar pela url',
    status: 'Status', // add
    turn_status: 'Clique para ativar o método de {method} mapeamento', // add
    close_all_mapping:
      'Deseja desativar todo o método de mapeamento? Isso tornará seu produto incapaz de ser processado no AliExpress via DSers.', // add
    open_new_mapping:
      'Deseja mudar para o mapeamento {switch}? Seus pedidos serão processados de acordo com o Mapeamento {switch}.', // add
    variant: 'Variante', // add
    ship_to: 'Enviar para', // add
    supplier_variant: 'Fornecedor e Variante', // add
    import_sp_select_var: 'Importar fornecedor & Selecionar variante', // add
    import_ae_title: 'Cole um link do AliExpress aqui para importar um fornecedor', //  add
    import_tm_title: 'Cole um link do Tmall aqui para importar um fornecedor', //  add
    import_placeholder: 'Colocar link de produto do AliExpress', // add
    clear: 'limpar', // add
    select_a_variant: 'Selecionar uma variante', // add
    import_ae_first: 'Importe um fornecedor do AliExpress primeiro', //  add
    import_tm_first: 'Importe um fornecedor do Tmall primeiro', //  add
    getProduct_error: 'The product you entered was not found',
    submit: 'Enviar', //  add
    advanced_rule:
      'Set up suppliers according to the destination countries of your order. You can set up at most 10 supplier to one variant.', //  add
    bundle_rule:
      'Set up a supplier for the product in your Bundle. You can set up to 10 suppliers for a variant', //  add
    supplier_product: 'Produto do Fornecedor',
    more_action: 'Mais ações', //  add
    clear_all: 'Limpar Tudo', //  add
    advanced_more_action_copy:
      'Copie o SKU para outras variantes depois de mapear a primeira. Você pode modificar se precisar de um diferente.', //  add
    buy: 'COMPRAR', //  add
    buy_desc:
      'Mapeie um fornecedor para o principal produto que você está vendendo', //  add
    buy_more_action_copy:
      'Copie o SKU para outras variantes depois  de mapear a primeira.', //  add
    x: 'X', //  add
    x_desc: 'Quantidade para o produto principal', //  add
    change_qty_to: 'Mudar quantidade para', //  add
    get: 'PEGAR', //  add
    get_desc:
      'Mapeie um fornecedor para o produto presente que você está oferecendo', //  add
    get_more_action_copy:
      'Copie o SKU para outras variantes de PRESENTE depois de mapear a primeira.', //  add
    y: 'Y', //  add
    y_desc: 'Quantidade <br /> para o <br /> produto <br /> presente', //  add
    bundle_products: 'Pacote de produtos', //  add
    bundle_more_action_copy:
      'Copie o SKU para outras variantes depois <br /> de mapear a primeira. Você pode modificar se <br /> precisar de um diferente.', //  add
    // d
    import_btn_tip: `<span>
                      You can set up mapping as below :<br />
                      1. Paste an AliExpress link here.<br />
                      2. Click Import<br />
                      3. Set the product as default/second option<br />
                      4. Fill in ALL options and values to complete mapping<br />
                      <a
                        href="https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/"
                        target="_blank"
                      >
                        Check this article
                      </a>
                    </span>`,
    // d
    import: 'Importar',
    set: 'pôr',
    no_record_yet: 'No record yet', //  d
    ShopifyProduct:"Produto Shopify",
    verified: {
      //  d
      title: 'Product info',
      cost: 'Cost',
      shipping: 'Shipping',
      ePacket: 'ePacket',
      package_request: {
        title: 'Package request',
        option1: 'Bags（defualt)',
        option2: 'Box ($0.2／Package)',
        option3: 'Box with logo($0.2／Package)',
        option4: 'Box with card($0.2／Package)',
        option5: 'Box with logo and card($0.2／Package)'
      },
      upload_logo_file: 'Upload logo file',
      leave_a_meaage: 'Leave a message',
      go_to_setting: 'Go to Setting > Supplier to set up for the whole store',
      sample_on_way: 'Sample is on the way.',
      tracking_no: 'Tracking NO',
      request_sample: 'Request sample',
      save: 'Save',
      disable: 'Disable',
      enable: 'Enable',
      cancel: 'Cancel'
    }, //  d
    publish_first: 'Publish first then add suppliers', // d
    upload_img_tip: 'Image must smaller than 1MB!', // d
    verified_save: 'Saved succeed.', // d
    copy_link_tip: 'Replication fails, goods need to be pushed first', // d
    copy_succ: 'Successfully copied', // d
    copy_fail: 'copy failed', // d
    delete_default_option_tip:
      'Do you want to delete the default option? You can add it again in the record?',
    delete_second_option_tip:
      'Do you want to delete the second option? You can add it again in the record?',
    delete_history_tip:
      'Do you want to remove this product information from product history?', //  d
    yes: 'Sim',
    no: 'Não',
    ok: 'Ok', //  d
    cancel: 'Cancel', // d
    save_option_confirm: {
      // d
      set_shipping_tip: 'Please set up shipping method for substitute product.',
      save_option_two_tip:
        'Please pay attention that activating substitute product might cause extra shipping fee if you have two different suppliers supply for one order.'
    }, // d
    clear_all_shipping: 'Do you want to clear all configuration information?', // d
    save_shippinp_succ: 'Save Success.', // d
    setting_activated: 'Setting activated', // d
    country_shipping: {
      // d
      shipping_setting: 'Shipping Setting',
      ship_to: 'Ship to',
      clear_all: 'Clear All',
      search: 'Search',
      shipping_country: 'Shipping Company',
      shipping_cost: 'Shipping Cost',
      time: 'Estimated Delivery Time',
      tracking_information: 'Tracking information',
      free_shipping: 'Free Shipping',
      available: 'Available',
      unavailable: 'Unavailable',
      not_ship_this_country: 'This product can not ship to this country.',
      select_ship_country: 'Please select the country you want to ship to.'
    }, // d
    product_whic: 'Você quer que este fornecedor seja:',
    default_option: 'Opção padrão',
    second_option: 'Opção secundária',
    mapped: 'Mapeado',
    default: 'Padrão',
    succeed: 'Com Sucesso',
    repeat_option: 'Você não pode salvar devido à repetição de variantes',
    select_variant:
      'Selecione as variantes correspondentes ou não conseguirá salvar o mapeamento',
    delete_pool_product:
      'Se você deletar este fornecedor da sua página de mapeamentos, o mapeamento deste produto será deletado',
    delete_mapping_product:
      'Deseja remover a { num, plural, =0 { opção } other {segunda} } padrão? Você pode adicioná-lo novamente nos registros',
    delete_del_variant_tip:
      'Are you sure you want to delete the variant(s) on DSers? Once deleted, orders for the variant(s) can no longer be processed.',
    same_country_tip: 'Você não pode selecionar esse país novamente para essa variante.',
    same_sku_tip:
      'Se você deseja definir o mesmo item como produto principal e brinde, altere a quantidade de produtos principais, ao invés de importá-los novamente na coluna de brindes.',
    import_same_sku_tip: 'You can\'t import the same SKU twice for one variant',
    Variant_delete_tip_one: 'You deleted the variant(s) below on Shopify. Please:',
    Variant_delete_tip_two: '1. Redo the mapping to continue syncing the stock from AliExpress.',
    Variant_delete_tip_three: '2. Add supplier to below variant(s) to process orders for the deleted variant(s).',
    overrun_warning: 'You exceeded the daily limitation to access the product information',
    standard: {
      product_name: 'Mapping Fournisseurs',
      product_min_name: 'Supplier Product',
      product_title:  'Set up a supplier for the product in your Standard Mapping',
      remove_con: "Clicking 'CONFIRM' will remove this supplier form the list of available supplier for this product and remove the existing mapping of other SKUs using it. Do you want to confirm?",
      remove_title: "Remove Supplier",
      remove_confirm: 'CONFIRM',
    },
    replace_product: {
      btn_title: 'REPLACE PRODUCT',
      modal_tip: "As variantes dos produtos da Shopify serão excluídas e substituídas pelas variantes de um novo produto. Você poderá editar os detalhes do produto na próxima etapa.",
      shopify: 'Shopify product',
      ali_express: 'AliExpress product',
      tm_express: 'Tmall product',
      price_rule: 'Apply Pricing Rule',
      cancle: 'CANCEL',
      url_error: 'Please input valid AliExpress product URL.',
      price_rule: 'Apply Pricing Rule',
      keep_description: 'Keep the original Shopify description',
      update_description: 'Update description',
      keep_image: 'Keep the original Shopify images',
      new_image: "Choose new images",
      suucess: 'Successfully replaced',
      failed: 'Failed to replace',
      replace_placeholder:`Cole a URL do produto aqui`,
    },
    main_product:'Main Product',
    gift_product:'Gift Product',
    sku_lose_tip:'The mapping was lost because your supplier changed the SKU. Please re-map your products according to the latest SKU on AliExpress or change supplier.',
    replace_product_tip:`If you have any open orders with the old product that haven't been placed yet, please <a href='https://help.dsers.com/basic-mapping-advanced-mapping-bogo-bundle-introduction-for-shopify/' target='_blank'>map</a> or <a href='https://help.dsers.com/re-order-awaiting-payment-orders-for-shopify/#modify-canceled-order' target='_blank'>edit</a> the order with selected new variants that matches the desired product, then process orders {toAliExpress}. You can click REPLACE PRODUCT after.`,confirm_delete_all_variant_title:'Delete Variant(s)',
    confirm_delete_all_variant:'Are you sure you want to delete the variant(s) on DSers? Once deleted, orders for the variant(s) can no longer be processed.',
    delete_variant_collapse_tip:`Você excluiu a(s) variante(s) abaixo na Shopify. Por favor:
    <br />Adicione um fornecedor à(s) variante(s) abaixo para processar os pedidos da(s) variante(s) excluídas.`,
    delete_variant_collapse_supplier_tip:`Você excluiu a(s) variante(s) abaixo na Shopify. Por favor:
    <br />1. Refaça o mapeamento para continuar sincronizando o estoque com o fornecedor.
    <br />2. Adicione um fornecedor à(s) variante(s) abaixo para processar os pedidos da(s) variante(s) excluídas.`,
    delete_options_title:'Delete Option',
    delete_supplier_title:'Delete Supplier',
    open_new_mapping_title:'Switch Mapping',
    close_all_mapping_title:'Deactivate Mapping',
    delete_supplier_description:'If you delete this supplier from your mapping page, the mapping of your product will be deleted.',
    offline_error_tip:'O produto está off-line',
    mapping_info_error_tip:'Ocorreu um problema com os dados do produto que você está mapeando. Refaça o mapeamento.',
    Findsimilar:'Encontre similares',
    distoryImgTip:'A imagem do produto atual não foi reconhecida e o recurso “Otimização de fornecedores” não pode ser usado no momento. Lamentamos a inconveniência causada.'
  }
};
