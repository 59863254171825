import { useRef, useCallback } from 'react';

export function useOnceCallback() {
    const triggerdRef = useRef(false)
    return useCallback((callback) => {
        if(triggerdRef.current) return;
        triggerdRef.current = true;
        if(typeof callback === 'function'){
            callback()
        }
    }, [])
}