export default {
  mapping: {
    title: 'Marketplace',
    description:
      'The product details from Shopify (such as title, description, images, variants, etc. ) remain the same after a supplier is added.',
    description_tip:
      'Please note: the mapping will be lost when you edit/delete/add options or variants on Shopify.If you change the name of a variant in Shopify, we will not modify the mapping in DSers, so make sure the variants are still corresponding to the new names you set.',
    tab_btn_basic_mapping: 'Basic',
    tab_btn_advanced_mapping: 'Advanced', // add
    tab_btn_bogo: 'BOGO', // add
    tab_btn_bundle: 'Bundle', // add
    tab_btn_verified: 'Verified', //  delete
    shopify_product: 'Shopify product',
    default_ali_product: 'Default {platform} Product',
    edit_shipping: 'Edit Shipping', // d
    set_shipping: 'Set Shipping', // d
    import_a_product: 'Import a product', // d
    substitute_ali_product: 'Substitute {platform} Product',
    standProductNum: 'Up to 5 AliExpress products can be imported',
    second_product_tip:
      'Second option is an option to be used during auto order processing when some variants are not available for defualt option.', // d
    alternate_products: 'Alternate Products',
    alternate_products1: 'Add Products',
    import_placeholder: 'Import url',
    status: 'Status', // add
    turn_status: 'Turn on to activate the {method} mapping method', // add
    close_all_mapping:
      'Do you want to deactivate all mapping methods? This will prevent your product from being processed by DSers to the corresponding supplier.', // add
    open_new_mapping:
      'Do you want to switch to {switch} Mapping? Your orders will be processed according to {switch} Mapping.', // add
    variant: 'Variant', // add
    ship_to: 'Ship to', // add
    supplier_variant: 'Supplier & Variant', // add
    import_sp_select_var: 'Import supplier & Select variant', // add
    import_ae_title: 'Import a supplier by pasting an AliExpress link here', //  add
    import_tm_title: 'Import a supplier by pasting a Tmall link here', //  add
    import_placeholder: 'Input aliexpress product url', // add
    clear: 'Clear', // add
    select_a_variant: 'Select a variant', // add
    import_ae_first: 'Import an AliExpress supplier first', //  add
    import_tm_first: 'Import a Tmall supplier first', //  add
    submit: 'Submit', //  add
    repeat_alert:
      'This supplier is already set as default or substitute supplier and cannot be set again.',
    clear_tip_alert:
      'If you switch the item to set it as the primary supplier, the secondary supplier will be emptied.',
    forbidden_alert:
      'Basic mapping can only be used to map with supplier from one supplier platform.',
    switch_mapping: 'Switch between Default and Substitute supplier',
    advanced_rule:
      'Set up suppliers according to the destination countries of your order. You can set up at most 10 supplier to one variant.', //  add
    bundle_rule:
      'Set up a supplier for the product in your Bundle. You can set up to 10 suppliers for a variant',
    supplier_product: 'Supplier Product',
    getProduct_error: 'The product you entered was not found',
    more_action: 'More action', //  add
    clear_all: 'Clear All', //  add
    advanced_more_action_copy:
      'Copy SKU to other variants after mapping the first one. You can modify if you need a different one.', //  add
    buy: 'BUY', //  add
    buy_desc: 'Map a supplier for the main product you are selling', //  add
    buy_more_action_copy:
      'Copy the SKU to other variants after mapping the first one.', //  add
    x: 'X', //  add
    x_desc: 'Quantity for main product', //  add
    change_qty_to: 'Change qty to', //  add
    get: 'GET', //  add
    get_desc: 'Map a supplier for the gift product you are offering', //  add
    get_more_action_copy:
      'Copy the SKU to other GIFT variants after mapping the first one.', //  add
    y: 'Y', //  add
    y_desc: 'Quantity for <br /> gift product', //  add
    bundle_products: 'Bundle products', //  add
    bundle_more_action_copy:
      'Copy SKU to other variants after mapping <br /> the first one. You can modify if you need a <br /> different one.', //  add
    // d
    ShopifyProduct: 'Shopify Product',
    import_btn_tip: `<span>
                      You can set up mapping as below :<br />
                      1. Paste an AliExpress link here.<br />
                      2. Click Import<br />
                      3. Set the product as default/second option<br />
                      4. Fill in ALL options and values to complete mapping<br />
                      <a
                        href="https://help.dsers.com/connect-aliexpress-suppliers-to-your-products-for-shopify/"
                        target="_blank"
                      >
                        Check this article
                      </a>
                    </span>`,
    // d
    import: 'Import',
    set: 'Set',
    no_record_yet: 'No record yet', //  d
    verified: {
      //  d
      title: 'Product info',
      cost: 'Cost',
      shipping: 'Shipping',
      ePacket: 'ePacket',
      package_request: {
        title: 'Package request',
        option1: 'Bags（defualt)',
        option2: 'Box ($0.2／Package)',
        option3: 'Box with logo($0.2／Package)',
        option4: 'Box with card($0.2／Package)',
        option5: 'Box with logo and card($0.2／Package)',
      },
      upload_logo_file: 'Upload logo file',
      leave_a_meaage: 'Leave a message',
      go_to_setting: 'Go to Setting > Supplier to set up for the whole store',
      sample_on_way: 'Sample is on the way.',
      tracking_no: 'Tracking NO',
      request_sample: 'Request sample',
      save: 'Save',
      disable: 'Disable',
      enable: 'Enable',
      cancel: 'Cancel',
    }, //  d
    publish_first: 'Publish first then add suppliers', // d
    upload_img_tip: 'Image must smaller than 1MB!', // d
    verified_save: 'Saved succeed.', // d
    copy_link_tip: 'Replication fails, goods need to be pushed first', // d
    copy_succ: 'Successfully copied', // d
    copy_fail: 'copy failed', // d
    delete_default_option_tip:
      'Do you want to delete the default option? You can add it again in the record?',
    delete_second_option_tip:
      'Do you want to delete the second option? You can add it again in the record?',
    delete_history_tip:
      'Do you want to remove this product information from product history?', //  d
    yes: 'Yes',
    no: 'No',
    ok: 'Ok', //  d
    cancel: 'Cancel', // d
    save_option_confirm: {
      // d
      set_shipping_tip: 'Please set up shipping method for substitute product.',
      save_option_two_tip:
        'Please pay attention that activating substitute product might cause extra shipping fee if you have two different suppliers supply for one order.',
    }, // d
    clear_all_shipping: 'Do you want to clear all configuration information?', // d
    save_shippinp_succ: 'Save Success.', // d
    setting_activated: 'Setting activated', // d
    country_shipping: {
      // d
      shipping_setting: 'Shipping Setting',
      ship_to: 'Ship to',
      clear_all: 'Clear All',
      search: 'Search',
      shipping_country: 'Shipping Company',
      shipping_cost: 'Shipping Cost',
      time: 'Estimated Delivery Time',
      tracking_information: 'Tracking information',
      free_shipping: 'Free Shipping',
      available: 'Available',
      unavailable: 'Unavailable',
      not_ship_this_country: 'This product can not ship to this country.',
      select_ship_country: 'Please select the country you want to ship to.',
    }, // d
    product_whic: 'Do you want this supplier to be:',
    default_option: 'Default option',
    second_option: 'Second option',
    mapped: 'Mapped',
    default: 'Default',
    to_be_set: 'To be set',
    succeed: 'Succeed',
    repeat_option:
      "You can't save it because of the repeating of variant options",
    select_variant:
      'Please select corresponding variants. Otherwise you can not save the mapping',
    delete_pool_product:
      'If you delete this supplier from your mapping page, the mapping of your product will be deleted',
    delete_mapping_product:
      'Do you want to delete the { num, plural, =0 { default } other {second} } option? You can add the option again later from the record',
    delete_del_variant_tip:
      'Are you sure you want to delete the variant(s) on DSers? Once deleted, orders for the variant(s) can no longer be processed.',
    same_country_tip: 'You can’t select this country again for this variant',
    same_sku_tip:
      'If you want to set up the same product for both main product and gift product, please change quantity for main products instead of importing it again for gift column.',
    import_same_sku_tip: "You can't import the same SKU twice for one variant",
    Variant_delete_tip_one:
      'You deleted the variant(s) below on Shopify. Please:',
    Variant_delete_tip_two:
      '1. Redo the mapping to continue syncing the stock from AliExpress.',
    Variant_delete_tip_three:
      '2. Add supplier to below variant(s) to process orders for the deleted variant(s).',
    overrun_warning:
      'You exceeded the daily limitation to access the product information',
    standard: {
      product_name: 'Shopify Product',
      product_min_name: 'Supplier Product',
      product_title:
        'Set up a supplier for the product in your Standard Mapping',
      remove_con:
        "Clicking 'CONFIRM' will remove this supplier form the list of available supplier for this product and remove the existing mapping of other SKUs using it. Do you want to confirm?",
      remove_title: 'Remove Supplier',
      remove_confirm: 'CONFIRM',
    },
    replace_product: {
      btn_title: 'REPLACE PRODUCT',
      modal_tip:
        "The Shopify product's variants will be deleted and replaced with variants of a new product. You can edit the product's details during next step.",
      shopify: 'Shopify product',
      ali_express: 'AliExpress product',
      tm_express: 'Tmall product',
      price_rule: 'Apply Pricing Rule',
      cancle: 'CANCEL',
      url_error: 'Please input valid AliExpress product URL.',
      price_rule: 'Apply Pricing Rule',
      keep_description: 'Keep the original Shopify description',
      update_description: 'Update description',
      keep_image: 'Keep the original Shopify images',
      new_image: 'Choose new images',
      suucess: 'Successfully replaced',
      failed: 'Failed to replace',
      replace_placeholder: 'Paste Product URL here',
    },
    main_product: 'Main Product',
    gift_product: 'Gift Product',
    sku_lose_tip:
      'The mapping was lost because your supplier changed the SKU. Please re-map your products according to the latest SKU on AliExpress or change supplier.',
    replace_product_tip:
      "If you have any open orders with the old product that haven't been placed yet, please <a href='https://help.dsers.com/basic-mapping-advanced-mapping-bogo-bundle-introduction-for-shopify/' target='_blank'>map</a> or <a href='https://help.dsers.com/re-order-awaiting-payment-orders-for-shopify/#modify-canceled-order' target='_blank'>edit</a> the order with selected new variants that matches the desired product, then process orders {toAliExpress}. You can click REPLACE PRODUCT after.",
    confirm_delete_all_variant_title: 'Delete Variant(s)',
    confirm_delete_all_variant:
      'Are you sure you want to delete the variant(s) on DSers? Once deleted, orders for the variant(s) can no longer be processed.',
    delete_variant_collapse_tip:
      'You deleted the variant(s) below on Shopify. Please:<br />Add supplier to below variant(s) to process orders for the deleted variant(s).',
    delete_variant_collapse_supplier_tip: `You deleted the variant(s) below on Shopify. Please:
    <br />1. Redo the mapping to continue syncing the stock from supplier.
    <br />2. Add supplier to below variant(s) to process orders for the deleted variant(s).`,
    delete_options_title: 'Delete Option',
    delete_supplier_title: 'Delete Supplier',
    open_new_mapping_title: 'Switch Mapping',
    close_all_mapping_title: 'Deactivate Mapping',
    delete_supplier_description:
      'If you delete this supplier from your mapping page, the mapping of your product will be deleted.',
    offline_error_tip: 'The product is offline',
    mapping_info_error_tip:
      'There is a problem with the product data you are mapping, please re-map',
    Findsimilar: 'Find similar',
    distoryImgTip:
      'The current product picture cannot be recognized, and the Supplier Optimizer feature here cannot be used for the moment. Sorry for the inconvenience caused.',
  },
};
