import React from 'react';
import intl from 'react-intl-universal';

import styles from './FreePeriodOver.less';
import useTopBanner from '../../hooks/useTopBanner';
import bannerEn from '../../assets/banner/pricingRule_en.png';
import bannerFr from '../../assets/banner/pricingRule_fr.png';

const allowPath = [
  '/home',
  '/order/list',
  '/storemanage/import',
  '/storemanage/myproduct',
  // '/newfeature'
  '/csv'
];

const ShopMasterBanner = ({ path, lang }) => {
  const [visible] = useTopBanner(path, allowPath);

  if (!visible) {
    return null;
  }

  const bannerImg = lang === 'fr-FR' ? bannerFr : bannerEn;

  return (
    <div className={styles.changeBanner}>
      <a
        href="https://help.dsers.com/advanced-pricing-rule-for-shopify/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={bannerImg} />
      </a>
    </div>
  );
};

export default ShopMasterBanner;
