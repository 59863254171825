import React from 'react';

import MessageCard from './MessageCard';
import styles from './UserMessage.less';

const UserMessage = ({ children, showAvatar = false, guideColor = false, handleStart }) => {
  return (
    <div className={styles.sessionRight}>
      <div className={styles.main} onClick={handleStart}>
        <MessageCard direction="right" guideColor={guideColor}>{children}</MessageCard>
      </div>
      <div className={styles.side}>
        <div>{showAvatar ? <span className={styles.avatar} /> : null}</div>
      </div>
    </div>
  );
};

export default UserMessage;
