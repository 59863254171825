export default {
  agency: {
    // global
    Agency: 'Agency（Individual Supplier）',
    agency: 'agency',
    // setting
    supplier: 'Supplier',
    title: 'Agency（Individual Supplier） Management',
    subtitle: 'You can manage supplier information here, place orders directly with suppliers, and no longer purchase through third-party markets (such as AliExpress). Click here to learn more.',
    subtitle_1: 'You can manage supplier information here, place orders directly with suppliers, and no longer purchase through third-party markets (such as AliExpress). Click ',
    subtitle_2: 'to learn more.',
    subtitle_3: ' here ',
    title_tip: 'An agency represents a product supplier that allows you to fulfill orders without a third-party marketplace such as AliExpress. Specifically, you can directly place orders to agencies and they will ship the goods to your customers.',
    name_tip: 'The name of your agency, which will be used and displayed when you map a product or process an order.',
    erp_tip: 'The ERP representatives, such as Dianxiaomi, Mabang and ECCANG, etc., which offer the product inventory management, procurement monitoring, logistics distribution and package tracking.',
    paypal_tip: `Your Agency's PayPal account. After setting up this PayPal account, you can initiate payments directly to the Agency while DSers process orders`,
    phone_tip: 'The phone number of your agency, which will be used to push your agency to fulfill orders via SMS.',
    name: 'Agency Name',
    nameErr: "Agency Name can't be empty.",
    phone: 'Phone Number',
    phoneErr: 'Only numbers can be entered',
    erp: 'ERP in use',
    erpPlaceholder: 'Select ERP',
    erpErr: 'Please select a ERP.',
    paypal: 'PayPal Account',
    paymentMethod: 'Payment method',
    agencyContactEmail: 'Agency contact email',
    stripeAccount: 'Stripe account',
    agencyStripe: 'Agency Stripe',
    stripeNoAuth: 'No authorization, please contact the supplier for authorization.',
    agencyPaymentTip: `Your Agency's Payment methods. After setting up this payment method, you can initiate payments directly to the Agency while DSers process orders.`,
    agencyNoAuthModalTitle: 'Authorization not completed',
    agencyNoAuthModalContent: `Your supplier has not completed the Stripe account authorization, please contact your supplier to complete the Stripe account authorization. If you have more questions, please contact the DSers support team.`,
    agencyNoAuthModalGetSupport: 'GET SUPPORT',
    paypalPlaceholder: 'Agency PayPal Account',
    paypalErr: "PayPal can't be empty.",
    paypalEmailErr: 'Please input a correct PayPal email',
    delError: 'The supplier has been mapped and cannot be deleted.',
    phonePlaceholder: 'Country',

    // setting > save text
    savetitle: 'Add An Agency(Individual supplier)',
    savetext: `After creating the agency information, DSers will send an email to your supplier ({email}).Please contact your supplier, who will do a simple configuration so that you can continue to manage product mapping, automatically fulfilling orders, and other features through DSers.`,
    deletetitle: "Deletion failed.",
    deletetext: "You currently have  products mapped to this supplier. To avoid affecting existing products and orders, please clear the Mapping  related to this supplier before trying again.",
    // my product
    Agencies: 'Agencies <i>({ae_num})</i>',
    filterByAgency: 'Filter by Agency',

    filterAgencyPlease: 'Please go to',
    filterAgencyAddInformation: 'to add an Agency',

    supplierManagement: 'Supplier Management',
    marketplaces: 'Marketplaces',
    agencyInformation: 'Agency Information',
    selectAgency: 'Select Agency',
    refreshPage: 'Refresh page',
    REFRESH: 'REFRESH',
    mappingUpdating: 'Mapping updating',
    markeCardDescription:
      "You can mapping supplier's products for your store's products, and when the sales end products are ordered, you can place an order with the corresponding supplier with just one click on DSers.",
    markeCardDescriptionNoTianMao:
      'With marketplaces, you can select AliExpress products to map your Shopify items',
    agencyCardDescription:
      "Agency represents a single supplier outside of marketplaces (such as AliExpress). You can map your store's products to the agency and order directly from their ERP for automatic fulfillment.",
    marketplaceExplain:
      'Mapping',
    marketplaceExplain1:
    ' from marketplaces supplier for your Shopify products. The product details from Shopify (such as title, description, images, variants, etc. ) remain the same after a supplier is added.',
    agencyExplain:
      'You can choose a desired supplier according to your needs. The product details from Shopify (such as title, description, images, variants, etc. ) remain the same after a supplier is added.',
    statusExplain: 'Turn on to activate the Agency mapping method',
    selectAgentPrompt:
      'Select an Agency to map your Shopify products with. If you did not add an Agency, you need to go to',
    toSetting: 'Settings',
    addAgency: 'to add an Agency.',
    noSelectionPrompt:
      'You have not added any Agency, please go to Settings to add an Agency.',
    noSelectionButton: 'GO TO SETTING TO ADD AGENCIES',
    refreshPagePrompt:
      'Please refresh the page so we can detect if you have successfully added an Agency.',
    backendProcessesPrompt:
      'The mapping method of the product has been changed. We will take some time to help you replace the mapping of the order related to the product.',
    TimeoutErr: 'Request timed out, please try again later.'

  }
};
