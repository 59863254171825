
    export default 
    {
    "menu": {
        "Switch": "Cambiar de plataforma",
        "blog": "BLOG",
        "facebook": "Facebook",
        "youtube": "YouTube",
        "email_support": "Asistencia por correo electrónico"
    }
}