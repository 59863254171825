import React, { useState, useEffect, useRef } from 'react';

import { Collapse } from 'antd';

const { Panel } = Collapse;

import Store from './Store';

import styles from './index.less';

function Organization(props) {
  const [selectStoreIds, setSelectStoreIds] = useState([]);
  const childRef = useRef();

  useEffect(() => {
    const ids = [];
    props.data.forEach(store => {
      ids.push(store.organizationValue ? store.id : undefined);
    });
    setSelectStoreIds(ids);
  }, [props.data]);

  function changeStore(storeId, i) {
    const ids = [...selectStoreIds];
    if (!storeId) {
      props.deleteStoreData(ids[i]);
    }
    ids[i] = storeId;
    setSelectStoreIds(ids);
  }

  const onCollapseChange = e => {
    if (e.length) {
      // childRef.current.changeStoreSS(props.data[e[0]].id);
    }
  };

  return (
    <div className={styles.box}>
      <Collapse
        defaultActiveKey={['0']}
        expandIconPosition="right"
        onChange={onCollapseChange}
      >
        {props.data.map((store, i) => {
          return (
            <Panel
              key={i}
              header={
                <div>
                  <i class="material-icons">storefront</i>
                  <span>{store.shop_name}</span>
                </div>
              }
            >
              <Store
                ref={childRef}
                key={store.id}
                indexByStoreId={store.id}
                allStore={props.data}
                selectStoreIds={selectStoreIds}
                changeHiddenStatus={props.changeHiddenStatus}
                changeStore={changeStore}
                changeCategory={props.changeCategory}
                i={i}
              />
            </Panel>
          );
        })}
      </Collapse>
    </div>
  );
}

export default Organization;
