export default {
  product_edit: {
    repeated_tip:
      'The product\'s colors and/or sizes are repeated, please change them',
    push_description: 'Click <a style="color:#3D5AFE" target="_blank" href="/app/settings?id=4">here</a> to set the content contained in the description when pushing the product.',
    specifications: 'SPECIFICATIONS',
    overview: 'OVERVIEW',
    picture: 'Picture',
    compare_price: 'Compare <br/> price value',
    delete_variant_tip: 'Are you sure you want to delete this variant?',
    delete_selected_variant_tip:
      'Are you sure you want to delete the selected variants?',
    new_compare_price: 'New compare price',
    new_price: 'New price',
    new_stock: 'New stock',
    open_on_supplier: 'CLICK FOR DETAILS',
    from_other: 'From other',
    seller_id: 'Seller ID',
    input_title: 'Please input title',
    product_type: 'Product type',
    collection: 'Collection',
    vendor: 'Vendor',
    tags: 'Tags',
    no_edit:
      'Products that are published to Shopify can\'t be edited, please edit on Shopify Directly.',
    select_variant: 'Select variant',
    none: 'None',
    description: 'Description',
    images: 'Images <i>({selectImages}/{allImages})</i>',
    shipping: 'Shipping info',
    shipping_info: 'You can check {platform} shipping cost information, estimated delivery time and tracking availability here by selecting a Ship to (and Ship from) country. Shipping information will NOT be pushed to Shopify when you publish. This is just to give you an estimate of shipping cost.',
    cannot_recover:
      'You cannot recover deleted product variants, do you wish to continue?',
    download_pic: 'Click here to download all the pictures',
    delete_pic_tip:
      'Do you want to delete the image(s)? The deleted image(s) will not be uploaded to the Media section in the Shopify product.',
    delete_picss_tip:'Do you want to delete all the images? No images will be uploaded to the Media section in the Shopify product.',
    back: 'Back',
    product: 'Product',
    from_aliexpress: 'From AliExpress',
    from_tmall:'From Tmall',
    title: 'Title',
    variants: 'Variants <i>({allvariants})</i>',
    all: 'All',
    action: 'Action',
    sku: 'Sku',
    cost: 'Cost',
    price: 'Price value',
    shopify_stock: 'Stock to <br /> Shopify',
    stock_on_shopify: 'Stock on <br /> Shopify',
    stock: 'Stock on <br /> suppliers',
    replace_stock:'Stock on <br /> {platform}',
    change_price: 'Change price',
    change_compare_at_price: 'Change Compare at Price',
    remove_sku: 'Remove SKU(s)',
    change_stock: 'Change Stock',
    option_repeated_tip: 'The product\'s colors and/or sizes are repeated, please change them',
    sku_repeated_tip: 'SKU need to be different for each variant. Please modify it.',
    option_name_invalid: 'The option name cannot be empty',
    option_value_invalid: 'The option value cannot be empty',
    option_name_repeat: 'The option name cannot be repeated',
    option_name_slash: 'The option name cannot contain " / "',
    option_sku_invalid: 'The SKU value cannot be empty',
    sku_count_invalid: 'Please keep at least one SKU',
    overrun_warning: 'You exceeded the daily limitation to access the product information',
    change_image: 'Change image',
    organization: 'Organization',
    download_all_img: 'Download',
    delete_img:'Delete',
    img_des:'The pictures checked here will appear in the Media of Shopify products',
    not_select_main_img_des:'If you don’t select the cover picture here, the first picture you check here will be used as the cover picture for this Shopify product.',
    deleteImage: 'Delete Image',
    products:'Products',
    shipping_cost:'Shipping <br /> cost',
    uncheckedAlert:'If you don’t select any images here, no images will be uploaded to Media in the Shopify product.',
    try_again_collections_text:`We couldn't get your collections data because the request timeout, please click `,
    try_again_collections_fun:'Refresh',
    checkImages:'Images <i>({allImages})</i>',
    select_province_pleaseholder:'Please select province/city',
    ship_from_text:'Ship from:',
    ship_to_text:'Ship to:',
    product_edit_price_tip:'The currency symbol will vary based on your Shopify store settings after publishing to Shopify. Please manually set price/compare price value here if you need.',
    shopify_stock_tip:'You can set up the amount of stock that you want to push to Shopify here. It will not update when supplier stock changed on {platform}.',
    aliexpress_stock_tip:'DSers will auto update AliExpress stock for products on import list every 2 hours. Stock here can not be modified manually',
    tmall_stock_tip: "DSers will auto update Tmall stock for products on import list. Stock here can not be modified manually.",
    product_stock_tip:'DSers will automatically update suppliers stock for products on My product page . Stock here can not be modified manually. We will not update stock for products in Advanced/BOGO/Bundle mapping.',
    shopify_product_stock_tip:'Stock here will only sync from Shopify and it will not be updated according to the suppliers product stock updates unless you activated Auto Update feature in paid plan.',
    download_img_fail_tip:'Image download failed, please try again later',
  }
};
