import React from 'react';
import { connect } from 'dva';
import { Popover } from 'antd';

import intl from 'react-intl-universal';
import className from 'classnames';
import round from 'lodash/round';
import { currencySymbolMap } from '../../../common/sysconfig';
import { replaceUrlToHttps } from 'utils/utils';

import defaultImg from '../../../assets/default-image.jpg';

import orderStyles from '../Order.less';

function Bogo(props) {
  const {
    child,
    child: { useSp = {} },
    getCanceledBy,
    moreStatus,
    status,
    index,
    listData,
    storeCurrency,
    listData: { storeId },
    bundle
  } = props;


  let currencyCode;
  let currencySymbol;
  let isNotUSD;
  let currencyRates;

  if (storeCurrency[storeId]) {
    currencyCode = storeCurrency[storeId].currency_code || 'USD';
    currencySymbol = currencySymbolMap[currencyCode];
    isNotUSD = currencyCode !== 'USD';
    currencyRates = storeCurrency[storeId].rates_value;
  }

  return (
    <div
      className={className(orderStyles.product, {
        [orderStyles.productNoBorder]: true
      })}
    >
      <div className={orderStyles.productWrap}>
        <Popover
          placement="leftTop"
          trigger="click"
          content={
            <img
              src={replaceUrlToHttps(useSp.sku_img || defaultImg)}
              style={{
                width: '260px',
                height: '260px',
                objectFit: 'contain'
              }}
            />
          }
          overlayClassName={orderStyles.popover}
        >
          <a className={orderStyles.productImg}>
            <img src={replaceUrlToHttps(useSp.sku_img || defaultImg)} />
            {bundle == 3 ? <div className="completed">Completed</div> : null}
          </a>
        </Popover>
        <div className={orderStyles.productInfo}>
          <span>{useSp.title}</span>
          <p>
            {intl.get('order.order_product.variants')}: <b>{useSp.variant_title}</b>
          </p>
          <p>
            {intl.get('order.order_product.from')} <i>AliExpress</i>
          </p>
        </div>
        <div className={orderStyles.productCost}>
          <p>
            {intl.get('order.order_product.cost')}:&nbsp;
            <span>
              <b>$ {useSp.cost}</b> x <b>{useSp.quantity}</b>
            </span>
          </p>
          {isNotUSD ? (
            <p className={orderStyles.productCurrencyCost}>
              {`${currencySymbol} ${round(useSp.cost * currencyRates, 2)} x ${useSp.quantity}`}
            </p>
          ) : null}
        </div>
      </div>
      <div>
        {getCanceledBy(child, status, 0, index, listData)}
        {moreStatus}
      </div>
    </div>
  );
}

export default connect(({ global }) => ({
  storeCurrency: global.storeCurrency
}))(Bogo);
