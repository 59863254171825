
    export default 
    {
    "partner": {
        "public": {
            "change": "Ändern",
            "cancel": "Abbrechen",
            "save": "Speichern",
            "reset": "Zurücksetzen"
        },
        "signIn": {
            "title": "Willkommen zurück",
            "log_in": "Einloggen",
            "forgot_password": "PASSWORT VERGESSEN?",
            "no_account": "Sie haben noch kein Konto?",
            "become_partner": "Partner werden",
            "email": "E-Mail"
        },
        "signUp": {
            "title": "Werden Sie DSers Partner!",
            "register": "Registrieren",
            "sign_in": "Anmelden",
            "have_account": "Haben Sie ein Konto?",
            "email": "Kontakt-E-Mail",
            "email_format_tips": "Bitte geben Sie eine E-Mail Adresse ein",
            "email_is_value_tips": "Bitte füllen Sie die Kontakt-E-Mail aus",
            "email_placeholder_tips": "Wird verwendet, um Sie zu kontaktieren und ein Konto zu erstellen",
            "pwd": "Kennwort",
            "pwd_is_value_tips": "Bitte geben Sie ein Passwort ein",
            "pwd_placeholder_tips": "Bitte geben Sie ein Passwort ein",
            "pwd_font_num_tips": "Das Passwort muss 8 - 16 Zeichen lang sein",
            "pwd_format_tips": "Das Passwort muss mindestens 1 Großbuchstaben, 1 Kleinbuchstaben und 1 Zahl enthalten",
            "confirm_Password": "Bestätigen Sie Ihr Passwort",
            "confirm_pwd_placeholder_tips": "Bitte geben Sie ein Passwort zur Bestätigung ein",
            "confirm_pwd_is_value_tips": "Bitte bestätigen Sie Ihr Passwort!",
            "confirm_pwd_contrast_tips": "Zwei Passwörter, die Sie eingeben, sind nicht identisch!",
            "name": "Vollständiger Name",
            "name_is_value_tips": "Bitte geben Sie englische Zeichen in 1-100 Länge ein",
            "name_placeholder_tips": "Bitte teilen Sie uns Ihren Namen mit",
            "country": "Land",
            "country_is_value_tips": "Bitte wählen Sie ein Hauptland",
            "country_placeholder_tips": "Bitte wählen Sie Ihr Land",
            "business_type": "Geschäftsart",
            "business_type_is_value_tips": "Bitte geben Sie die Geschäftsart an",
            "business_type_placeholder_tips": "Bitte wählen Sie Ihre Geschäftsart",
            "partner_url": "Ihre Website/Anwendung/Kanal-URL",
            "partner_url_is_value_tips": "Bitte geben Sie eine URL ein, die mit Http oder Https beginnt",
            "partner_url_placeholder_tips": "Bitte teilen Sie den Link zu Ihrem Unternehmen",
            "partner_url_font_num_tips": "Zu viele Zeichen, bitte geben Sie maximal 1000 Zeichen ein.",
            "partner_url_format_tips": "Bitte geben Sie eine URL ein, die mit Http oder Https beginnt.",
            "partner_id": "Kanal-ID",
            "partner_id_tips": "Sie können hier mehrere Kanal-IDs hinzufügen. Die neu erstellte Kanal-ID wird gemäß den Regeln der Plattform generiert und kann nicht geändert werden.",
            "partner_id_is_value_tips": "Bitte geben Sie Ihre Kanal-ID ein",
            "partner_id_is_value_format_tips": "Die Kanal-ID darf nicht mit einer Zahl enden.",
            "partner_id_placeholder_tips": "Wird verwendet, um Ihren Tracking-Link zu erstellen",
            "partner_id_font_num_tips": "Zu viele Zeichen, bitte geben Sie maximal 100 Zeichen ein.",
            "paypal_email": "PayPal-E-Mail-Adresse (optional)",
            "paypal_email_placeholder_tips": "Sie können diese später im Konto eingeben",
            "remark": "Erzählen Sie uns mehr",
            "remark_placeholder_tips": "Erzählen Sie uns mehr darüber, wie Sie zusammenarbeiten möchten",
            "remark_font_num_max_tips": "Zu viele Zeichen, bitte geben Sie maximal 1000 Zeichen ein.",
            "remark_font_num_min_tips": "Nicht genügend Zeichen, bitte geben Sie mindestens 200 Zeichen ein.",
            "remark_is_value_tips": "Bitte erzählen Sie uns mehr über Sie"
        },
        "account": {
            "paypal_email": "PayPal-E-Mail-Adresse",
            "paypal_email_placeholder_tips": "Bitte teilen Sie uns Ihre Paypal-E-Mail-Adresse mit"
        },
        "home": {
            "title": "Dashboard",
            "commission_on_hold": "Kommission in Wartestellung",
            "commission_confirmed": "Kommission bestätigt",
            "request_commission": "Provision anfordern",
            "seven_days_tips": "Wir haben Ihre Anfrage erhalten. Unser Team wird sie prüfen und sich innerhalb von 7 Arbeitstagen bei Ihnen melden. Wir danken Ihnen für Ihre Geduld."
        },
        "Reset_email_module": {
            "title": "Neue E-Mail Adresse",
            "email_tips": "Bitte geben Sie eine E-Mail-Adresse ein!",
            "code_tips": "Falscher Code. Bitte noch einmal überprüfen!",
            "send_code": "Code senden",
            "verify": "Überprüfen Sie",
            "send_prompt": "Wir senden Ihnen einen Verifizierungscode an die neue E-Mail-Adresse. Bitte kopieren Sie ihn und geben Sie ihn unten ein, um Ihre Mailbox zu verifizieren",
            "code_title": "Code zur Verifizierung eingeben",
            "get_code": "Holen Sie sich den Code aus unserer Verifizierungs-E-Mail und geben Sie ihn hier ein, um verifiziert zu werden"
        },
        "reset_pass_word": {
            "title": "Passwort zurücksetzen",
            "new_pwd_placeholder_tips": "Neues Passwort",
            "new_pwd_value_no_tips": "Das Passwort darf nicht leer sein",
            "old_pwd_placeholder_tips": "Altes Kennwort",
            "old_pwd_value_no_tips": "Bitte geben Sie Ihr altes Passwort ein!",
            "old_pwd_font_num_tips": "Das Passwort muss 8 - 16 Zeichen lang sein",
            "old_pwd_format_tips": "Das Passwort muss mindestens 1 Großbuchstaben, 1 Kleinbuchstaben und 1 Zahl enthalten",
            "confirm_new_pwd_value_no_tips": "Das Passwort darf nicht leer sein",
            "confirm_new_pwd_contrast_tips": "Passwörter dürfen nicht doppelt vorkommen!"
        },
        "partner_layout": {
            "account": "Konto",
            "dashboard": "Dashboard"
        },
        "password_not_match_twice": "Passwörter stimmen nicht zweimal überein",
        "password_error_long": "Das Passwort muss zwischen 8 und 16 Zeichen lang sein",
        "password_error_format": "Das Passwort enthält mindestens 1 Großbuchstaben, 1 Kleinbuchstaben und 1 Zahl"
    }
}