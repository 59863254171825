import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle
} from 'react';
import { Checkbox, Tooltip } from 'antd';

import styles from './ProductOptionsSelect.less';

import dropIcon from 'assets/product/drop_icon.png';
import { SSL_OP_DONT_INSERT_EMPTY_FRAGMENTS } from 'constants';

const ProductOptionsSelect = forwardRef((props, ref) => {
  const { title, options, index, getSelectedOptions } = props;

  const parentRef = useRef(null);
  const dropRef = useRef(null);
  const selectRef = useRef(null);
  const allRef = useRef([])

  const [openOptions, setOpenOptions] = useState(false);
  const [optionsWidth, setOptionsWidth] = useState('');
  const [allChecked, setAllChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [defaultHeight, setDefaultHeight] = useState();
  const [groupValue, setGroupValue] = useState([]);

  useImperativeHandle(ref, () => ({
    clearSelect: clearSelect
  }));

  useEffect(() => {
    if (options.length < 5) {
      setDefaultHeight(`${options.length * 32}px`);
    }
    setOptionsWidth(window.getComputedStyle(parentRef.current).width);
  }, [props.title, openOptions]);

  const onChange = v => {
    if(allRef.current.findIndex(i=>i===v) > -1){
      
      allRef.current = allRef.current.filter(i=>i !== v)
    }else{
      
      allRef.current  = [...allRef.current,v]
    }
    options.forEach(i=>{
      if(allRef.current.findIndex(it=>it === i.value) > -1){
        i.checked = true
      }else{
        i.checked = false
      }
    })
    // setGroupValue(v);
    
    let arr = [];
    allRef.current?.forEach(it => {
      arr.push({
        type: index,
        option: it,
        action: title
      });
    });
    getSelectedOptions({ name: title, type: index, options: arr });
  };

  const clearSelect = () => {
    allRef.current = [];
    options.forEach(i=>{
        i.checked = false
    })
  };
  useEffect(() => {
    const handleMouseDown = e => {
      if (selectRef.current.contains(e.target)) {
        setOpenOptions(!openOptions);
      } else {
        if (dropRef.current?.contains(e.target)) {
          setOpenOptions(true);
        } else {
          setOpenOptions(false);
        }
      }
    };

    document.addEventListener('click', handleMouseDown, false);
    return () => {
      document.removeEventListener('click', handleMouseDown);
    };
  }, [openOptions]);

  return (
    <div className={styles.selectContainer} ref={parentRef}>
      <div
        className={styles.optionsContainer}
        ref={dropRef}
        style={{
          height: openOptions ? (defaultHeight ? defaultHeight : '160px') : '0',
          width: optionsWidth
        }}
      >
        {/* <Checkbox.Group value={groupValue} onChange={onChange}> */}
          {options.map(item => (
            <Tooltip placement="right" title={item.label}>
              <Checkbox
                checked={item.checked}
                onChange={()=>onChange(item.value)}
                value={item.value}
              >
                {item.label}
              </Checkbox>
            </Tooltip>
          ))}
        {/* </Checkbox.Group> */}
      </div>
      <div className={styles.dropContainer} ref={selectRef}>
        <span className={styles.title}>{title}</span>
        <span>
          <img
            style={{
              transform: openOptions ? 'rotate(90deg)' : 'rotate(270deg)'
            }}
            src={dropIcon}
            alt=""
          />
        </span>
      </div>
    </div>
  );
});
export { ProductOptionsSelect };
