import React, { useState, useRef, forwardRef, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { connect } from 'dva';
import { Button, Radio, Dropdown, Menu, Row, Tooltip, Icon, Checkbox, Modal, message as mess, Spin } from 'antd';
import qs from 'qs';
import intl from 'react-intl-universal';

import styles from './PreMigration.less'

import logo from 'assets/svg/new_logo.png';
import ds from 'assets/migration/ds.png'
import shopify from 'assets/migration/shopify.png'
import message from 'assets/migration/Message.png'
import bannerimg from 'assets/banner/supplier/openarrow.png'
import checkdis from 'assets/migration/oberloCheck.png'

import Prepared from './PreparedContainer'
import SettingSelect from './SettingContainer'
import { Get, Post } from 'utils/request';
import SelectLanguage from 'components/GlobalHeader/SelectLanguage';
import { CommonDataCollect } from 'utils/utils';

const PreMigration = ({ migrationInfo, userInfo }) => {

    const [migraWay, setMigraWay] = useState(1) // 1:展示setting和product 2:只展示setting偏好
    const [showArrow, setShowArrow] = useState(true)
    const [select, setSelect] = useState(['cover', 'merge'])  //['cover']:oberlo覆盖dsers ['cover','merge']:合并
    const [ordersSelect, setOrdersSelect] = useState('PREFERENCES_VALUE_OBERLO') // PREFERENCES_VALUE_OBERLO:商品订单以oberlo为主 PREFERENCES_VALUE_DSERS:以dsers为主
    const [settingSelect, setSettingSelect] = useState('PREFERENCES_VALUE_OBERLO') // PREFERENCES_VALUE_OBERLO:setting以oberlo为主 PREFERENCES_VALUE_DSERS:以dsers为主
    const [loading, setLoading] = useState()
    const [modal, setModal] = useState(false)
    const [texttype, setTextType] = useState(1)
    const [spinloading, setSpinloading] = useState(true)


    const dispatch = useDispatch()

    useEffect(() => {
        (async () => {
            const res = await Get('/mgnoberlo-core-api/dsers-api/migration/user/status', [], true);
            CommonDataCollect({
                action: 'enter_selectpage',
                store_id: res?.dsers_store_id,
                custom_data: []
            })
        })()
        getStatus()


    }, [])

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (document.documentElement.scrollHeight - document.documentElement.scrollTop - document.documentElement.clientHeight <= 35) {
                setShowArrow(false)
            } else {
                setShowArrow(true)
            }
        })
    }, [])

    const getStatus = async () => {
        const res = await Get("/mgnoberlo-core-api/dsers-api/migration/user/status", {}, true)
        //shopify域名不存在 step_stage不是预迁移 新账号 预约状态 处于以上任何阶段都跳走
        if (!res?.migrating_shopify_domain || res?.step_stage !== 'STEP_STAGE_PRE' || res?.is_new_user || res?.step_stage == 'STEP_STAGE_QUEUE_RESERVATION') {
            window.location.href = '/app/'
            return
        }
        if (res?.is_new_store) {
            setMigraWay(2)
        } else {
            setMigraWay(1)
        }

        getPreMigraInfo(res.migrating_shopify_domain)
    }


    const getPreMigraInfo = async (shopdomain) => {
        const res = await Get(`/mgnoberlo-core-api/dsers-api/migration/preferences?shopify_domain=${shopdomain}`, {}, true)
        if (res.data_option !== 'PREFERENCES_VALUE_' || res.setting_option !== 'PREFERENCES_VALUE_') {
            if (res.data_option == 'PREFERENCES_VALUE_NEW' && res.setting_option == 'PREFERENCES_VALUE_REPLACE') {
                setSelect(['cover'])
                setModal(true)
                setTextType(1)
                setSpinloading(false)
            } else {
                setSelect(['cover', 'merge'])
                setOrdersSelect(res.data_option)
                setSettingSelect(res.setting_option)
                setSpinloading(false)
                if (res.data_option == 'PREFERENCES_VALUE_OBERLO' && res.setting_option == 'PREFERENCES_VALUE_OBERLO') {
                    setTextType(2)
                } else if (res.data_option == 'PREFERENCES_VALUE_DSERS' && res.setting_option == 'PREFERENCES_VALUE_DSERS') {
                    setTextType(3)
                } else if (res.data_option == 'PREFERENCES_VALUE_DSERS' && res.setting_option == 'PREFERENCES_VALUE_OBERLO') {
                    setTextType(4)
                } else if (res.data_option == 'PREFERENCES_VALUE_OBERLO' && res.setting_option == 'PREFERENCES_VALUE_DSERS') {
                    setTextType(5)
                }
            }
        } else {
            setTextType(2)
            setSpinloading(false)
        }
    }


    const handleOrders = (e) => {
        setOrdersSelect(e.target.value)
        if (e.target.value == 'PREFERENCES_VALUE_OBERLO' && settingSelect == 'PREFERENCES_VALUE_OBERLO') {
            setTextType(2)
        } else if (e.target.value == 'PREFERENCES_VALUE_DSERS' && settingSelect == 'PREFERENCES_VALUE_DSERS') {
            setTextType(3)
        } else if (e.target.value == 'PREFERENCES_VALUE_DSERS' && settingSelect == 'PREFERENCES_VALUE_OBERLO') {
            setTextType(4)
        } else {
            setTextType(5)
        }
    }

    const handleSetting = (e) => {
        setSettingSelect(e.target.value)
        if (e.target.value == 'PREFERENCES_VALUE_OBERLO' && ordersSelect == 'PREFERENCES_VALUE_OBERLO') {
            setTextType(2)
        } else if (e.target.value == 'PREFERENCES_VALUE_DSERS' && ordersSelect == 'PREFERENCES_VALUE_DSERS') {
            setTextType(3)
        } else if (e.target.value == 'PREFERENCES_VALUE_DSERS' && ordersSelect == 'PREFERENCES_VALUE_OBERLO') {
            setTextType(5)
        } else {
            setTextType(4)
        }
    }

    const handleSelect = (e) => {
        setSelect(e)
        if (e.includes('merge')) {
            setModal(false)
            if (ordersSelect == 'PREFERENCES_VALUE_OBERLO' && settingSelect == 'PREFERENCES_VALUE_OBERLO') {
                setTextType(2)
            } else if (ordersSelect == 'PREFERENCES_VALUE_DSERS' && settingSelect == 'PREFERENCES_VALUE_DSERS') {
                setTextType(3)
            } else if (ordersSelect == 'PREFERENCES_VALUE_DSERS' && settingSelect == 'PREFERENCES_VALUE_OBERLO') {
                setTextType(4)
            } else {
                setTextType(5)
            }

        } else {
            setModal(true)
            setTextType(1)
        }
    }

    const handleClick = async () => {
        let [orderData, settingData] = [0, 0]
        if (!select.includes('merge')) {
            orderData = 4
            settingData = 4
        } else {
            orderData = ordersSelect == 'PREFERENCES_VALUE_OBERLO' ? 1 : 2
            settingData = settingSelect == 'PREFERENCES_VALUE_OBERLO' ? 1 : 2
        }
        const response = await Get('/mgnoberlo-core-api/dsers-api/migration/user/status', [], true);
        CommonDataCollect({
            action: 'premigra_clickcontinue',
            order_data: orderData,
            setting_data: settingData,
            store_id: response?.dsers_store_id,
        })

        setLoading(true)
        let [selectData, selectSetting] = ['', '']
        if (!select.includes('merge')) {
            selectData = 'PREFERENCES_VALUE_NEW'
            selectSetting = 'PREFERENCES_VALUE_REPLACE'
        } else {
            selectData = ordersSelect
            selectSetting = settingSelect
        }

        const res = await Post('/mgnoberlo-core-api/dsers-api/migration/preferences', {
            data: {
                data_option: selectData,
                setting_option: selectSetting,
                shopify_domain: migrationInfo?.migrating_shopify_domain
            }
        }, true)
        if (!res.code) {
            setLoading(false)
            window.location.href = '/app/data-migrate'
        } else {
            mess.error({
                content: <div style={{ float: 'right' }}>{intl.get('migration.refresh')} <Tooltip placement='top' title={`${intl.get('migration.requestid')}${res?.metadata?.trace_id}`}><Icon style={{ fontSize: "15px", marginLeft: '5px' }} type="question-circle" /></Tooltip></div>,
                duration: 0,
            })
        }
    }


    const handleChange = (e) => {
        setSelect(e.target.value)
    }

    const handleClick_2 = async () => {
        let settingData = settingSelect == 'PREFERENCES_VALUE_OBERLO' ? 1 : 2
        const response = await Get('/mgnoberlo-core-api/dsers-api/migration/user/status', [], true);
        CommonDataCollect({
            action: 'premigra_clickcontinue',
            order_data: 0,
            setting_data: settingData,
            store_id: response?.dsers_store_id,
        })
        const res = await Post('/mgnoberlo-core-api/dsers-api/migration/preferences', {
            data: {
                setting_option: settingSelect,
                shopify_domain: migrationInfo?.migrating_shopify_domain
            }
        }, true)

        if (!res.code) {
            window.location.href = '/app/data-migrate'
        } else {
            mess.error({
                content: <div style={{ float: 'right' }}>{intl.get('migration.refresh')} <Tooltip placement='top' title={`${intl.get('migration.requestid')}${res?.metadata?.trace_id}`}><Icon style={{ fontSize: "15px", marginLeft: '5px' }} type="question-circle" /></Tooltip></div>,
                duration: 0,
            })
        }
    }

    const menu = (
        <Menu>
            <Menu.Item >
                <Tooltip placement='top' title={intl.get('migration.exitmigrationtext')}>
                    <div style={{ display: 'flex', alignItems: 'center' }} onClick={async () => {
                        const res = await Post('/mgnoberlo-core-api/dsers-api/pre-migration/leave', {
                            data: {
                                shopify_domain: migrationInfo?.migrating_shopify_domain
                            }
                        }, true)
                        if (!res.code) {
                            localStorage.removeItem('ifMigration')
                            localStorage.removeItem('logoutEmail')
                            window.localStorage.removeItem('store_is_available');
                            window.localStorage.removeItem('EnterNewHomeTime');
                            window.localStorage.removeItem('setMigrationInfo');
                            window.localStorage.removeItem('noMigra');
                            window.localStorage.removeItem('close_tip');
                            window.location.href = '/app/'
                        }
                    }}>
                        <i className="material-icons" style={{ marginRight: '12px', fontSize: '21px' }}>sync_disabled</i>
                        <div style={{ display: 'flex', justifyContent: 'left' }}><span style={{ fontSize: '14px' }}>{intl.get('migration.exitmigration')}</span></div>
                        <Icon style={{ fontSize: "15px", marginLeft: '12px' }} type="question-circle" />
                    </div>
                </Tooltip>
            </Menu.Item>
            <Menu.Item onClick={() => {
                dispatch({
                    type: 'login/logout'
                });
            }}>
                <div style={{ display: 'flex', justifyContent: "left" }}><i className="material-icons" style={{ marginRight: '12px', fontSize: '21px' }}>redo</i><span style={{ fontSize: '14px' }}>{intl.get('public.log_out')}</span></div>
            </Menu.Item>

        </Menu>
    )

    const renderText = (type) => {
        switch (type) {
            case 1:
                return intl.get('migration.cover')
                break;
            case 2:
                return intl.get('migration.oberlo_oberlo')
                break;
            case 3:
                return intl.get('migration.dsers_dsers')
                break;
            case 4:
                return intl.get('migration.dsers_oberlo')
                break;
            case 5:
                return intl.get('migration.oberlo_dsers')
                break
        }
    }

    return (
        <Spin spinning={spinloading} size='large' style={{ backgroundColor: '#ffffff', minHeight: '100vh', position: 'fixed' }}>
            <div className={styles.container}>
                <div className={styles.top}>
                    <img src={logo} alt="" />
                    <div className={styles.user}>
                        <div style={{ lineHeight: '64px' }}><SelectLanguage /></div>
                        <div></div>
                        <div><img src={shopify} alt="" />{migrationInfo?.migrating_shopify_domain}</div>
                        <div></div>
                        <div className={styles.dropimg}><img src={ds} alt="" /></div>
                        <div><Dropdown overlay={menu} trigger={['hover']}>
                            <span className={styles.drop}>
                                <span>{userInfo.email}</span>
                                <i style={{ position: 'relative', top: '2px', marginLeft: '10px' }} className="material-icons">arrow_drop_down</i>
                            </span>
                        </Dropdown></div>
                    </div>
                </div>
                <div className={styles.process}>
                    <div><i className="material-icons notranslate" style={{ color: '#FF8F00', fontSize: '30px' }}>
                        check_circle
                    </i><span>{intl.get('migration.progress_1')}</span></div>
                    <span className={styles.line}></span>
                    <div> <span>2</span><span>{intl.get('migration.progress_2')}</span></div>
                    <span className={styles.line}></span>
                    <div> <span>3</span><span>{intl.get('migration.progress_3')}</span></div>
                </div>
                <div className={styles.middle}>
                    <div className={styles.mid_title}>{intl.get('migration.title')}</div>
                    <div className={styles.mid_icon}><div className={styles.mid_obl}><div style={{ width: '38px', height: '38px', border: '14px solid #2F01FD', borderRadius: '19px' }}></div></div><i class="material-icons" style={{ color: "#7216DD", fontSize: '44px' }}>chevron_right</i><i class="material-icons" style={{ color: "#AE497F", fontSize: '44px' }}>chevron_right</i><i class="material-icons" style={{ color: "#FF8F00", fontSize: '44px' }}>chevron_right</i><div className={styles.mid_dsers}><img src="https://img.dsers.com/images/logo.png" alt="" /></div></div>
                </div>
                {
                    migraWay == 1 ?

                        <div className={styles.select}>
                            <Row>
                                <div className={styles.card} style={{ flexDirection: 'column' }}>
                                    <div className={styles.merge}>
                                        <div>{intl.get('migration.card_1_title')}
                                            <Tooltip
                                                title={intl.get('migration.tooltiptext')}
                                                placement="top"
                                            >
                                                <Icon style={{ fontSize: "15px", marginLeft: '13px' }} type="question-circle" />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className={styles.mergeLine}></div>
                                    <div className={styles.mergeselect}>
                                        <div className={styles.select_1}>
                                            <div>{intl.get('migration.question_1')}</div>
                                            <div>
                                                <Checkbox.Group onChange={handleSelect} value={select} style={{ display: 'flex' }}>
                                                    <div className={styles.checkdis}><i className="material-icons notranslate" style={{ color: '#FF8F00', fontSize: '24px', opacity: '0.5', marginRight: '5px' }}>check_box</i>{intl.get('migration.question_1_select1')}</div>
                                                    <Checkbox value='merge' >{intl.get('migration.question_1_select2')}</Checkbox>
                                                </Checkbox.Group>
                                            </div>
                                        </div>
                                        <div className={styles.select_2}>
                                            <div> {intl.get('migration.question_2')}</div>
                                            <div>
                                                <div>
                                                    <Checkbox.Group onChange={handleSelect} value={select} style={{ display: 'flex' }}>
                                                        <div className={styles.checkdis}><i className="material-icons notranslate" style={{ color: '#FF8F00', fontSize: '24px', opacity: '0.5', marginRight: '5px' }}>check_box</i>{intl.get('migration.question_1_select1')}</div>
                                                        <Checkbox value='merge' >{intl.get('migration.question_1_select2')}</Checkbox>
                                                    </Checkbox.Group>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Row>

                            <Row>
                                <div className={styles.card} style={{ flexDirection: 'column' }}>
                                    {modal ? <div className={styles.modal}></div> : null}
                                    <div className={styles.merge}>
                                        <div>{intl.get('migration.card_2_title')}
                                            <Tooltip
                                                title={intl.get('migration.tooltiptext_2')}
                                                placement="top"
                                            >
                                                <Icon style={{ fontSize: "15px", marginLeft: '13px' }} type="question-circle" />
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <div className={styles.mergeLine}></div>
                                    <div className={styles.mergeselect}>
                                        <div className={styles.select_1}>
                                            <div>{intl.get('migration.question_3')}
                                                <Tooltip
                                                    title={intl.get('migration.option_1_text')}
                                                    placement="top"
                                                >
                                                    <Icon style={{ fontSize: "15px", marginLeft: '5px' }} type="question-circle" />
                                                </Tooltip></div>
                                            <div>
                                                <Radio.Group onChange={handleOrders} value={ordersSelect} disabled={modal ? true : false}>
                                                    <Radio value='PREFERENCES_VALUE_OBERLO' style={{ marginRight: '246px' }}>Oberlo</Radio>
                                                    <Radio value='PREFERENCES_VALUE_DSERS'>DSers</Radio>
                                                </Radio.Group>
                                            </div>
                                        </div>
                                        <div className={styles.select_2}>
                                            <div> {intl.get('migration.question_4')}
                                                <Tooltip
                                                    title={intl.get('migration.option_2_text')}
                                                    placement="top"
                                                >
                                                    <Icon style={{ fontSize: "15px", marginLeft: '5px' }} type="question-circle" />
                                                </Tooltip>
                                            </div>
                                            <div>
                                                <Radio.Group onChange={handleSetting} value={settingSelect} disabled={modal ? true : false}>
                                                    <Radio value='PREFERENCES_VALUE_OBERLO' style={{ marginRight: '246px' }}>Oberlo</Radio>
                                                    <Radio value='PREFERENCES_VALUE_DSERS'>DSers</Radio>
                                                </Radio.Group>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                            <div className={styles.contentStyle}>
                                <div>{intl.get('migration.according')}</div>
                                <div>{renderText(texttype)}</div>
                            </div>
                            <div className={styles.button} onClick={handleClick}><Button loading={loading} >{intl.get('migration.button')}</Button></div>
                        </div>
                        :
                        <div className={styles.select} style={{ marginTop: '100px' }}>
                            <div className={styles.settingcard}>
                                <div>{intl.get('migration.setting_title')}
                                    <Tooltip
                                        title={intl.get('migration.setting_text')}
                                        placement="top"
                                    >
                                        <Icon style={{ fontSize: "15px", marginLeft: '5px' }} type="question-circle" />
                                    </Tooltip>
                                </div>
                                <Radio.Group onChange={handleSetting} value={settingSelect}>
                                    <Radio value='PREFERENCES_VALUE_DSERS'>{intl.get('migration.option_1_1')}</Radio>
                                    <Radio value='PREFERENCES_VALUE_OBERLO'>{intl.get('migration.option_1_2')}</Radio>
                                </Radio.Group>
                            </div>
                            <div className={styles.button} onClick={handleClick_2}><Button disabled={!settingSelect}>{intl.get('migration.button')}</Button></div>
                        </div>
                }
                <a className={styles.bottom} target='_blank' href="https://help.dsers.com/oberlo-migration/">{intl.get('migration.blog')}</a>
                <div className={styles.end}>
                    <div><img src={message} alt="" /><a href='https://www.messenger.com/t/DSersOfficial' target='_blank'>{intl.get('migration.contact_1')}</a></div>
                    <div></div>
                    <i className="material-icons">local_post_office</i> <a style={{ marginLeft: '5px' }} href='mailto: support@dserspro.com'>{intl.get('migration.contact_2')}</a>
                </div>
                {
                    showArrow && migraWay == 1 ?
                        <div className={styles.arrowDown}>
                            <img src={bannerimg} alt="" />
                        </div> : null
                }
            </div>
        </Spin>
    )
}

export default connect(({ login, auth }) => ({
    userInfo: login.userInfo,
    migrationInfo: auth.migrationInfo
}))(PreMigration)