// import { delay } from 'dva/saga';

import {
  postGuidance,
  // 获取店铺信息
  getInfo,
  getWooUrl,
  // 获取用户支付计划
  getPlan,
  // 首次创建支付计划
  setProject,
  // 修改计划
  modificationProject,
  // 修改门店
  modificationShop,
  // 激活订单
  activate,
  // 重新下单
  reorder,
  // 保留店铺
  KeepTheStore,
  // 检测店铺
  getShopifyStoreStatus,
  // 门店列表
  storeList,
  // 用户免费时间
  getFreeTime
} from '../services/guidance';

import { queryCurrent } from '../services/user';

export default {
  namespace: 'guidance',
  state: {},

  effects: {
    *postGuidance({ payload: { callback, ...restParams } }, { call, put }) {
      const guidanceRes = yield call(postGuidance, restParams);
      if (guidanceRes) {
        const res = yield call(queryCurrent);

        if (res && res.code === 2000 && res.data) {
          localStorage.setItem('store', JSON.stringify(res.data));
          if (
            res.data.stores &&
            res.data.stores[0] &&
            res.data.stores[0].id
          ) {
            localStorage.setItem('storeId', res.data.stores[0].id);
          }
          yield put({
            type: 'login/setUserInfo',
            payload: res.data
          });
        }
      }

      if (callback) {
        callback(guidanceRes);
      }
    },
    *finishGuide({ payload: { data, resolve, reject } }, { put, select }) {
      try {
        const { userInfo } = yield select(state => state.login);
        if (!(userInfo && userInfo.status)) {
          return;
        }

        let value = '';
        const str = status.toString();

        // 参考 src/routes/Guidance/Index.js
        if (str.length !== 8) {
          value = 11000000;
        } else {
          const tmp = str.split('').map(s => (s >= 1 ? '1' : '0'));
          tmp[1] = '1';
          value = Number(tmp.join(''));
        }

        yield put({
          type: 'postGuidance',
          payload: {
            data: {
              status: value
            },
            callback: res => {
              resolve(res);
            }
          }
        });
      } catch (error) {
        reject(error);
      }
    },
    *goWoo({ payload }, { call }) {
      yield call(getWooUrl, payload);
    },

    *getInfo({ payload }, { call }) {
      yield call(getInfo, payload);
    },

    // 获取用户支付计划

    *getPlan({ payload }, { call }) {
      yield call(getPlan, payload);
    },

    // 首次创建支付计划
    *setProject({ payload }, { call }) {
      yield call(setProject, payload);
    },

    // 修改计划
    *modificationProject({ payload }, { call }) {
      yield call(modificationProject, payload);
    },

    // 修改支付门店
    *modificationShop({ payload }, { call }) {
      yield call(modificationShop, payload);
    },

    // 激活订单
    *activate({ payload }, { call }) {
      yield call(activate, payload);
    },

    // 重新下单
    *reorder({ payload }, { call }) {
      yield call(reorder, payload);
    },

    // 保留店铺

    *KeepTheStore({ payload }, { call }) {
      yield call(KeepTheStore, payload);
    },
    // 检测店铺
    *shopifyStatus({ payload }, { call }) {
      yield call(getShopifyStoreStatus, payload);
    },

    // 门店列表
    *storeList({ payload }, { call }) {
      yield call(storeList, payload);
    },

    // *pageRendered({ payload }, { call, put }) {
    //   // 渲染完毕后再执行
    //   yield call(delay, 500);
    //   yield put({
    //     type: 'updateGuideStatus',
    //     payload
    //   });
    // }

    *getFreeTime({ payload }, { call }) {
      yield call(getFreeTime, payload);
     }
  },

  reducers: {}
};
