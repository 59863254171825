import React from 'react';
import { Icon, Popover, Tooltip } from 'antd';
import intl from 'react-intl-universal';

import styles from './SyncAliProcess.less';
import { sleep } from '../util';
import { sendRequestToExtension } from '../../../utils/fetchFromExtension';
import { getBaseLayoutPopupContainer } from 'utils/utils';

const initTask = {
  type: 0,
  total: 0,
  updated: 0,
  failed: 0,
  nochange: 0,
  status: 0,
  taskId: null
}

class SyncAliProcess extends React.PureComponent {

  cacheTask = {
    type: 0,
    total: 0,
    updated: 0,
    failed: 0,
    nochange: 0,
    status: 0,
    taskId: null
  }

  constructor() {
    super();
    this.state = {
      type: 0,
      total: 0,
      updated: 0,
      failed: 0,
      nochange: 0,
      status: 0,
      finished: 0,
      visible: false
    };
  }

  componentDidMount() {
    const initCheckTask = async () => {
      let localSyncTask = localStorage.getItem('dsers-sync-ae-task');
      try {
        localSyncTask = localSyncTask && JSON.parse(localSyncTask);
      } catch (error) {
        console.log(error);
      }
      if (
        localSyncTask &&
        [1, 2].includes(localSyncTask.type) &&
        localSyncTask.taskId
      ) {
        const taskInfo = await sendRequestToExtension(
          'syncAliOrderNumber',
          'getTaskProgress',
          { type: localSyncTask.type }
        );
        if (taskInfo.taskId && taskInfo.status === 1) {
          this.startRolling(localSyncTask.type, taskInfo.taskId);
          this.props.openSyncLoading && this.props.openSyncLoading();
        } else if (taskInfo.data?.taskId && taskInfo.data?.status === 1) {
          this.startRolling(localSyncTask.type, taskInfo.data.taskId);
          this.props.openSyncLoading && this.props.openSyncLoading();
        } else {
          localStorage.removeItem('dsers-sync-ae-task');
        }
      }
    };

    initCheckTask();
  }

  componentWillUnmount() {
    this.cacheTask.status = 0;
  }

  startRolling = (type, taskId) => {
    if (this.cacheTask.status) {
      return;
    }
    this.cacheTask = { ...initTask, taskId, status: 1 };
    localStorage.setItem(
      'dsers-sync-ae-task',
      JSON.stringify({ taskId, type })
    );
    this.setState({ ...this.cacheTask, visible: true });
    const runRolling = async () => {
      while (this.cacheTask.status) {
        // eslint-disable-next-line no-await-in-loop
        const taskInfo = await sendRequestToExtension(
          'syncAliOrderNumber',
          'getTaskProgress',
          { type }
        );

        if (!this.cacheTask.status) {
          this.props.closeSyncLoading && this.props.closeSyncLoading();
          break;
        }

        if (type === 2) {
          if (taskInfo.taskId) {
            this.setState({ ...taskInfo, type, visible: true });
            this.cacheTask = { ...this.cacheTask, ...taskInfo };
          } else {
            this.setState({ status: 0 });
            this.cacheTask.status = 0;
          }
        } else if (taskInfo.data && taskInfo.data.taskId) {
          this.setState({ ...taskInfo.data, type, visible: true });
          this.cacheTask = { ...this.cacheTask, ...taskInfo.data };
        } else {
          this.setState({ status: 0 });
          this.cacheTask.status = 0;
        }

        if (!this.cacheTask.status) {
          localStorage.removeItem('dsers-sync-ae-task');
          this.props.closeSyncLoading && this.props.closeSyncLoading();
        }

        // eslint-disable-next-line no-await-in-loop
        await sleep(1000);
      }
    };
    runRolling();
  };

  render() {
    const {
      visible,
      type,
      total,
      updated,
      failed,
      nochange,
      status,
      finished
    } = this.state;

    const persent = ((finished / total) * 100).toFixed(2);

    return (
      <div className={styles.syncContainer}>
        {visible ? (
          <div className={styles.processContainer}>
            {status === 1 ? (
              <div className={styles.process}>
                <div>{intl.get('order.syncing_ali')}…</div>
                <div className={styles.processOuter}>
                  <div className={styles.track}>
                    <div
                      style={{ width: `${persent}%` }}
                      className={styles.processInner}
                    >
                      <div className={styles.processNumb}>
                        <div className={styles.processCount}>
                          <span className={styles.arrow} />
                          <span>
                            {finished}/{total}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span>{isNaN(persent) ? '-' : persent}%</span>
                </div>
              </div>
            ) : (
              <div className={styles.syncFeedback}>
                <Icon type="check-circle" />
                <div className={styles.feedbackContent}>
                  <div>{intl.get('order.sync_ali_completed')}</div>
                  <div>
                    <span style={{ color: 'red' }}>{failed}</span> failed
                    <Tooltip
                      getPopupContainer={getBaseLayoutPopupContainer}
                      title={
                        <div>
                          <div>
                            {intl.get('order.sync_ali_failed_result.title')}
                          </div>
                          <div>
                            {intl.get('order.sync_ali_failed_result.reason1')}
                          </div>
                          <div>
                            {intl.get('order.sync_ali_failed_result.reason2')}
                          </div>
                          <div>
                            {intl.get('order.sync_ali_failed_result.reason3')}
                          </div>
                          <div>
                            {intl.getHTML(
                              'order.sync_ali_failed_result.contact'
                            )}
                          </div>
                        </div>
                      }
                    >
                      <Icon
                        style={{ fontSize: 12, marginLeft: 8 }}
                        type="question-circle"
                      />
                    </Tooltip>
                  </div>
                </div>
                <Icon
                  onClick={() => this.setState({ visible: false })}
                  style={{ cursor: 'pointer' }}
                  type="close"
                />
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

export default SyncAliProcess;
