import React from 'react'
import { Icon, Tooltip, Dropdown, Menu, Modal, Input, Button } from 'antd';
import intl from 'react-intl-universal';
import orderStyles from '../Order.less';
import './actions.css';

const ActionsLimeTime = ({ useSp,status,child }) => {

  if(!useSp.platform_order_id){
    return null
  }

  if(!useSp?.time){
    return null
  }

  //没有手动 自动同步过AE Order信息的订单则不显示 有时间字段则展示
  if(status == 5 && !useSp?.time?.LastSyncTime){
    return null
  }

  //cancel 的有俩种情况需考虑  一个是本组件 一个是内联样式
  // console.log('useSp', child)
  
  return <>
          <div
            className={orderStyles.productOverride}
          >
          <Dropdown
              overlayClassName="syncTime"
              trigger={['click']}
              getPopupContainer={triggerNode => triggerNode.parentNode || document.body}
              overlay={
                <Menu>
                  <Menu.Item >
                    <div className="syncTime">
                      <p className="oneTime">{intl.get('order.sync_last_chan')}</p>
                      <StartTime useSp={useSp} status={status} />
                      <EndTime useSp={useSp} status={status} />
                    </div>
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
            >
              <Tooltip title={intl.get('order.order_product.action.title')}>
                <span className="moreIcons">
                  <i class="material-icons notranslate">more_vert</i>
                </span>
              </Tooltip>
            </Dropdown>
    </div>
  </>
}


const StartTime = ({status, useSp}) => {
  return <>
    <p className="lastTime">{intl.get('order.sync_time_last_chan')}:</p>
    <p className="lastTime">{ useSp?.time?.LastSyncTime }</p>
  </>
}

const EndTime = ({status, useSp}) => {
  if(status == 5){
    return null
  }
  return <>
    <p className="lastTime">{intl.get('order.sync_time_next_chan')}:</p>
    <p className="lastTime">{ useSp?.time?.NextSyncTime }</p>
  </>
}

export default ActionsLimeTime