
    export default 
    {
    "notification": {
        "notifications": "Benachrichtigungen",
        "mark_all_as_read": "Alle als gelesen markieren",
        "tip": "Sie erhalten nur Benachrichtigungen für die Produkte, die Sie in der Vergangenheit bestellt haben. Benachrichtigungen über DSers werden 30 Tage lang angezeigt",
        "regarding_to_aliexpress_statement": "Bezüglich der Aliexpress-Anweisung:",
        "product_price_has_changed": "Produktkosten haben sich geändert",
        "product_out_of_stock": "Das AliExpress-Produkt ist nicht mehr auf Lager oder nicht mehr verfügbar",
        "order_canceled": "Die Bestellung muss storniert werden:",
        "order_no": "Bestellnummer:",
        "ali_no": "Ali Nr:",
        "income": "Einkommen：",
        "check": "Prüfen Sie",
        "view": "Siehe",
        "more": "Mehr",
        "no_data": "Ihre Benachrichtigungsliste ist leer",
        "no_data_tip": "Wir benachrichtigen Sie über Änderungen der Produktverfügbarkeit oder des Preises",
        "at": "unter",
        "not_available": "Nicht verfügbar",
        "sku": "SKU",
        "const_change": "Kosten ändern",
        "got_it": "OK, ich hab's.",
        "go_to_shopify": "Gehe zu Shopify",
        "stock": "Lagerbestand",
        "no_match": "Keine Übereinstimmung",
        "check_product": "Produkt prüfen",
        "copy_succ": "Erfolgreich kopiert",
        "copy_fail": "Kopie fehlgeschlagen",
        "ali_product": "Ali Produkt",
        "related_shopify_products": "Zugehörige(s) Produkt(e) im Shopify-Shop :",
        "price": "Preis",
        "compared_at_price": "Zum Preis vergleichen",
        "update_to_shopify": "Update für Shopify",
        "variants_out_of_stock": "AliExpress SKU hat sich geändert",
        "variants_not_of_stock": "Produkt nicht mehr verfügbar",
        "variants_out_zero_stock": "Variante(n) des AliExpress-Produkts nicht auf Lager oder nicht verfügbar",
        "related_shopify_products_length": "Shopify Produkt(e), die diesem AliExpress-Produkt zugeordnet sind",
        "view_price": "Preis anzeigen",
        "view_variant": "Variante anzeigen",
        "offline_product": "Sind Sie sicher, dass Sie dieses Produkt offline stellen möchten? Wenn Sie auf Bestätigen klicken, wird das Produkt in Ihrem Shopify-Shop nicht mehr sichtbar sein.",
        "store_not_available": "Der Shop ist nicht mehr verfügbar",
        "product_is_offline": "Produkt ist offline",
        "put_offline": "Wählen Sie ein Produkt, das Sie offline stellen möchten",
        "set_new_value": "Neuen Wert setzen",
        "multiply_by": "Multiplizieren mit",
        "enter_value": "Wert eingeben",
        "enter_multiplier": "Multiplikator eingeben",
        "change_all": "Alle ändern",
        "mapping_changed": "Sie haben die Standardzuordnung geändert, die Produktkosten haben sich geändert",
        "product_deleted": "Das Shopify-Produkt, das diesen Lieferanten verwendet, wurde gelöscht, es können keine detaillierten Informationen angezeigt werden.",
        "store_removed": "Der Shopify-Shop, zu dem dieses Produkt gehört, wurde von DSers getrennt, es können keine detaillierten Informationen angezeigt werden.",
        "focus_on_orders": {
            "p1": "Sie haben",
            "p2": "stornierte Bestellungen, bitte prüfen"
        },
        "view_canceled_orders": "Stornierte Bestellungen anzeigen",
        "noti_tit": "Benachrichtigung",
        "shopPrice": "Note that the currency shown here is the currency you set in DSers, not your Shopify currency",
        "modalTitle": "Delete Notification(s)",
        "modalText": "Are you sure you want to delete the notification(s)? It will be removed from your notification list.",
        "allNotification": "Alle Benachrichtigungen anzeigen",
        "notification_feature": "Benachrichtigungsfunktion",
        "filter_variant_out_of_stock": "Variante(n) nicht auf Lager",
        "cancelOrder": "Bestellung storniert",
        "clearFilter": "ALLE FILTER LÖSCHEN",
        "filterTitle": "Benachrichtigung filtern",
        "defaultSupplier": "Standard-Lieferant",
        "secondSupplier": "Ersatzlieferant",
        "mappingPlaceholder": "Wählen Sie ein Produkt für die Neuzuordnung",
        "copyTitle": "Titel kopieren",
        "copied": "Tiltle kopiert",
        "filter": "Filtern",
        "product_not_found": "Product not found",
        "product_out_of_stock_tip": "Product out of stock",
        get_product_err: `Error in obtaining product information, if you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`,
        get_product_err_2: `There was an error obtaining product information, please try again later. If you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`,
        no_support_mapping: `Because the mapping method of the product is not supported, the detailed information cannot be displayed. If you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`,
        mapping_trouble: `Detailed information cannot be displayed due to a problem with the SKU or mapping of the product. If you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`,
        product_sku_change: `Details cannot be displayed due to a change in the SKU of the item. If you want to know more, please <a target="_blank" style="color:#3d5afe" href="https://www.messenger.com/t/DSersOfficial">contact us</a>`
    }
}